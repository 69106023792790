import { useState, useEffect, useCallback, useRef } from 'react';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Container,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { ProductList } from './product-list';
import { useMounted } from '../../hooks/use-mounted';
import { Refresh as RefreshIcon } from '../../icons/refresh';
import { Search as SearchIcon } from '../../icons/search';
import { useDispatch, useSelector } from 'react-redux';
import { setFilters } from '../../redux/reducers/product-reducer';
import { ProductListFilters } from './product-list-filters';
import { getProducts, getExternal } from '../../api';
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader/loader';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

const tabs = [
  {
    label: 'Bimroom database',
    value: 'products'
  },
  {
    label: 'External sources',
    value: 'external'
  }
];

const sortOptions = [
  {
    label: 'Last update (newest)',
    value: 'latestDate|desc'
  },
  {
    label: 'Last update (oldest)',
    value: 'latestDate|asc'
  }
];

const applyFilters = (products, filters) => products.filter((product) => {
  if (filters.query) {
    let queryMatched = false;
    const properties = ['brandName', 'name'];

    properties.forEach((property) => {
      if (typeof(product[property]) !== "undefined") {
        if ((product[property]).toLowerCase().includes(filters.query.toLowerCase())) {
          queryMatched = true;
        }
      }

    });

    if (!queryMatched) {
      return false;
    }
  }

  if (filters.status?.length > 0) {
    const statusMatched = filters.status.includes(product.status);

    if (!statusMatched) {
      return false;
    }
  }

  return true;
});

const descendingComparator = (a, b, sortBy) => {
  // When compared to something undefined, always returns false.
  // This means that if a field does not exist from either element ('a' or 'b') the return will be 0.

  if (b[sortBy] < a[sortBy]) {
    return -1;
  }

  if (b[sortBy] > a[sortBy]) {
    return 1;
  }

  return 0;
};

const getComparator = (sortDir, sortBy) => (sortDir === 'desc'
  ? (a, b) => descendingComparator(a, b, sortBy)
  : (a, b) => -descendingComparator(a, b, sortBy));

const applySort = (products, sort) => {
  const [sortBy, sortDir] = sort.split('|');
  const comparator = getComparator(sortDir, sortBy);
  const stabilizedThis = products.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
        const newOrder = comparator(a[0], b[0]);

    if (newOrder !== 0) {
      return newOrder;
    }

        return a[1] - b[1];
  });

    return stabilizedThis.map((el) => el[0]);
};

const applyPagination = (products, page, rowsPerPage) => products.slice(page * rowsPerPage,
  page * rowsPerPage + rowsPerPage);

const Products = () => {
  const isMounted = useMounted();
  const queryRef = useRef(null);
  const [products, setProducts] = useState([]);
  const [external, setExternal] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [sort, setSort] = useState(sortOptions[0].value);
  const [refresh, setRefresh] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState('products');
  const filters = useSelector((store) => store.product.filters);
  const dispatch = useDispatch();
  const navigate = useNavigate();



  // useEffect(() => {
  //   gtm.push({ event: 'page_view' });
  // }, []);

  const getProductList = useCallback(async () => {
    try {
      setIsLoading(true);
      const productList = await getProducts();
      const externalList = await getExternal();

      console.log(productList)
      
      if (productList && externalList) {
        setIsLoading(false);
      }

      // const brandList = await getDemoBrands();

      if (isMounted()) {
        setProducts(productList);
        setExternal(externalList);
        // setBrands(brandList);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  useEffect(() => {
    getProductList();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refresh]);


  const handleRefresh = () => {
    setRefresh(refresh + 1)
  }

  // const handleQueryChange = (event) => {
  //   event.preventDefault();
  //   setFilters((prevState) => ({
  //     ...prevState,
  //     query: queryRef.current?.value
  //   }));
  // };

  const handleQueryChange = (event) => {
    event.preventDefault();
    
    const newQuery = (prevState) => ({
      ...prevState,
      query: queryRef.current?.value
    })
    dispatch(setFilters(newQuery(filters)));
  };

  const handleFiltersChange = (filters) => {
    // setFilters(filters);
    dispatch(setFilters(filters));
  };

  const handleSortChange = (event) => {
    setSort(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleTabsChange = (event, value) => {
    setCurrentTab(value)
  }

  const goToProductForm = () => {
    navigate("/products/form/v2")
  }

  // Usually query is done on backend with indexing solutions
  const filteredProducts = applyFilters(products, filters);
  const sortedProducts = applySort(filteredProducts, sort);
  const paginatedProducts = applyPagination(sortedProducts, page, rowsPerPage);

  const filteredExternal = applyFilters(external, filters);
  const sortedExternal = applySort(filteredExternal, sort);
  const paginatedExternal = applyPagination(sortedExternal, page, rowsPerPage);

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8
        }}
      >
        <Container maxWidth="xl">
          <Box sx={{ mb: 4 }}>
            <Grid
              container
              justifyContent="space-between"
              spacing={3}
            >
              <Grid item >
                <Typography variant="h4" color="neutral.900" sx={{display: "inline-block"}}>
                  All products
                </Typography>
                <Tooltip title="Refresh">
                  <IconButton
                    onClick={handleRefresh}
                    sx={{mb: 1, ml: 1}}
                  >
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                {/* <OpenDemo brands={brands} /> */}
                <Button variant="contained" onClick={goToProductForm}>Add product</Button>
              </Grid>
            </Grid>
          </Box>
          <Card>
            <Tabs
              indicatorColor="primary"
              onChange={handleTabsChange}
              scrollButtons="auto"
              textColor="primary"
              value={currentTab}
              variant="fullWidth"

            >
              {tabs.map((tab) => (
                <Tab
                  key={tab.value}
                  label={tab.label}
                  value={tab.value}
                />
              ))}
            </Tabs> 
            <Divider />
            <CustomTabPanel value={currentTab} index={"products"}>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexWrap: 'wrap',
                  // m: -1.5,
                  // p: 3
                  
                }}
              >
                <Box
                  component="form"
                  onSubmit={handleQueryChange}
                  sx={{
                    flexGrow: 1,
                    m: 1.5
                  }}
                >
                  <TextField
                    defaultValue=""
                    fullWidth
                    inputProps={{ ref: queryRef }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon fontSize="small" />
                        </InputAdornment>
                      )
                    }}
                    placeholder="Search products"
                  />
                </Box>
              </Box>
              <ProductListFilters filters={filters} onChange={handleFiltersChange} />
              {isLoading ?
                <Box sx={{height: 400}}>
                  <Loader />
                </Box> :
                <ProductList
                  //brands={brands}
                  products={paginatedProducts}
                  productsCount={filteredProducts.length}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowsPerPageChange}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  refresh={handleRefresh}
                />
              }
            </CustomTabPanel>
            <CustomTabPanel value={currentTab} index={"external"}>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexWrap: 'wrap',
                  // m: -1.5,
                  // p: 3
                }}
              >
                <Box
                  component="form"
                  onSubmit={handleQueryChange}
                  sx={{
                    flexGrow: 1,
                    m: 1.5
                  }}
                >
                  <TextField
                    defaultValue=""
                    fullWidth
                    inputProps={{ ref: queryRef }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon fontSize="small" />
                        </InputAdornment>
                      )
                    }}
                    placeholder="Search products"
                  />
                </Box>
              </Box>
              <ProductListFilters filters={filters} onChange={handleFiltersChange} />
              <ProductList
                // brands={brands}
                products={paginatedExternal}
                productsCount={filteredExternal.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                rowsPerPage={rowsPerPage}
                page={page}
                refresh={handleRefresh}
              />
            </CustomTabPanel>
          </Card>
        </Container>
      </Box>
    </>
  );
};

// ProductList.getLayout = (page) => (
//   <AuthGuard>
//     <DashboardLayout>
//       {page}
//     </DashboardLayout>
//   </AuthGuard>
// );

export default Products;