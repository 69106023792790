import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filters: {
    query: '',
    next: [],
    previous: [],
    status: [],
    priority: [],
  },
  filterItems: [],
  scrollPosition: {
    position: {
      y: 0,
      x: 0
    },
    page: 0,
    rowsPerPage: 50
  }
};

export const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {

    setFilters: (state, action) => {
      state.filters = action.payload;
    },

    setFilterItems: (state, action) => {
      state.filterItems = action.payload;
    },

    setScrollPosition: (state, action) => {
      state.scrollPosition = action.payload;
    },

    resetAllFilters: (state) => {
      state.filters = {
        query: '',
        next: [],
        previous: [],
        status: [],
        priority: []
      }
    },

    resetScrollPosition: (state) => {
      state.filters = {
        position: {
          y: 0,
          x: 0
        },
        page: 0,
        rowsPerPage: 50
      }
    }

    // setSearchState: (state, action) => {
    //   state.searchState = action.payload;
    // },

    // setPreviousSearch: (state, action) => {
    //   state.previousSearch = action.payload;
    // },

    // setBrand: (state, action) => {
    //   state.brand = action.payload;
    // },

    // setCartProduct: (state, action) => {
    //   state.cartProduct = action.payload;
    // },

    // setRatingAndReview: (state, action) => {
    //   state.productRatingAndReview = action.payload;
    // },
  },
});

// Action creators are generated for each case reducer function
export const {
  // setSearchState,
  // setNext,
  // setPrevious,
  // setStatus,
  // setPriority,
  setFilters,
  setFilterItems,
  setScrollPosition,
  resetAllFilters,
  resetScrollPosition
} = customerSlice.actions;

export default customerSlice.reducer;
