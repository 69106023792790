import { useEffect, useState } from 'react';
// import NextLink from 'next/link';
//import numeral from 'numeral';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import { ArrowRight as ArrowRightIcon } from '../../icons/arrow-right';
import { PencilAlt as PencilAltIcon } from '../../icons/pencil-alt';
// import { getInitials } from '../../../utils/get-initials';
import { Scrollbar } from '../../scrollbar';
import { getInitColorSchemeScript } from '@mui/system';
import { format } from 'date-fns';
import { AddEvent } from '../../add-event';
import { AddContact } from '../../add-contact';
import { SeverityPill } from '../../severity-pill';
import { useDispatch, useSelector } from 'react-redux';
import { setScrollPosition } from '../../redux/reducers/customer-reducer';

export const CustomerListTable = (props) => {
  const {
    customers,
    customersCount,
    onPageChange,
    onRowsPerPageChange,
    page,
    rowsPerPage,
    ...other
  } = props;
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const scrollPosition = useSelector((store) => store.customer.scrollPosition);

  // Reset selected customers when customers change
  useEffect(() => {
      if (selectedCustomers.length) {
        setSelectedCustomers([]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customers]);

  const handleSelectAllCustomers = (event) => {
    setSelectedCustomers(event.target.checked
      ? customers.map((customer) => customer.id)
      : []);
  };

  const handleSelectOneCustomer = (event, customerId) => {
    if (!selectedCustomers.includes(customerId)) {
      setSelectedCustomers((prevSelected) => [...prevSelected, customerId]);
    } else {
      setSelectedCustomers((prevSelected) => prevSelected.filter((id) => id !== customerId));
    }
  };

  const goToProduct = (id) => {
    //event.preventDefault();
    
    const newScrollPosition = (prevState) => ({
      ...prevState,
      position: {
        y: window.scrollY,
        x: 0
      }
    })

    console.log(newScrollPosition(scrollPosition));
    dispatch(setScrollPosition(newScrollPosition(scrollPosition)));

    navigate("/contacting/" + id);
  }

  const checkDate = (date) => {
    try {
      const newDate = format(date, 'd.M.yyyy');
      return (newDate);
    }
    catch(err) {
      return ("");
    }
  }

  const getColor = (status) => {

    if (status === "subscribed") {
      return "success.main";
    } else if (["contacted","subscription expired"].includes(status)) {
      return "warning.main";
    } else if (["not interested"].includes(status)) {
      return "error.main";
    } else {
      return "#000";
    }
  }

  const enableBulkActions = selectedCustomers.length > 0;
  const selectedSomeCustomers = selectedCustomers.length > 0
    && selectedCustomers.length < customers.length;
  const selectedAllCustomers = selectedCustomers.length === customers.length;

  return (
    <div {...other}>
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.mode === 'dark'
            ? 'neutral.800'
            : 'neutral.100',
          display: enableBulkActions ? 'block' : 'none',
          px: 2,
          py: 0.5
        }}
      >
        <Checkbox
          checked={selectedAllCustomers}
          indeterminate={selectedSomeCustomers}
          onChange={handleSelectAllCustomers}
        />
        <Button
          size="small"
          sx={{ ml: 2 }}
        >
          Delete
        </Button>
        <Button
          size="small"
          sx={{ ml: 2 }}
        >
          Edit
        </Button>
      </Box>
      <Scrollbar>
        <Table sx={{ minWidth: 700 }}>
          <TableHead sx={{ visibility: enableBulkActions ? 'collapse' : 'visible' }}>
            <TableRow>
              {/* <TableCell>
                State
              </TableCell> */}
              <TableCell>
                Company
              </TableCell>
              <TableCell>
                Contact (Default)
              </TableCell>
              <TableCell>
                Previous
              </TableCell>
              <TableCell>
                Next
              </TableCell>
              <TableCell width="110">
                Status/Latest
              </TableCell>
              <TableCell align="right">
                Quick add
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customers.map((customer) => {
              const isCustomerSelected = selectedCustomers.includes(customer.id);

              return (
                <TableRow
                  hover
                  key={customer.id}
                  selected={isCustomerSelected}
                  sx={{cursor: "pointer"}}
                >
                    {/* <TableCell onClick={() => goToProduct(customer.id)}>
                      <SeverityPill
                        //sx={{ borderTopLeftRadius: 12, borderTopRightRadius: 12 }}
                        sx={{borderTopLeftRadius: 12, borderBottomLeftRadius: 12,  minWidth: 10}}
                        color={(customer?.brochureSent)
                          ? 'success'
                          : 'error'}
                      >
                        { (customer?.brochureSent) ? 'B' : 'B' }
                      </ SeverityPill>
                      <SeverityPill
                        sx={{minWidth: 10}}
                        color={(customer?.demoSent)
                          ? 'success'
                          : 'error'}
                      >
                        { (customer?.demoSent) ? 'D' : 'D' }
                      </ SeverityPill>
                      <SeverityPill
                        //sx={{ borderBottomLeftRadius: 12, borderBottomRightRadius: 12}}
                        sx={{borderTopRightRadius: 12, borderBottomRightRadius: 12,  minWidth: 10}}
                        color={(customer?.contractSent)
                          ? 'success'
                          : 'error'}
                      >
                        { (customer?.contractSent) ? 'C' : 'C' }
                      </ SeverityPill>
                    </TableCell> */}
                  <TableCell onClick={() => goToProduct(customer.id)}>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex'
                      }}
                    >
                      <Box sx={{ ml: 0 }}>
                        <Link
                          color="inherit"
                          variant="subtitle2"
                        >
                          {customer?.companyName}
                        </Link>
                        <Typography
                          color="textSecondary"
                          variant="body2"
                        >
                          {customer?.slug}
                        </Typography>
                        <Typography
                          color="textSecondary"
                          variant="body2"
                        >
                          {customer?.country}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell onClick={() => goToProduct(customer.id)}>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex'
                      }}
                    >
                      <Box sx={{ ml: 0 }}>
                        <Typography
                          color="textSecondary"
                          variant="subtitle2"
                        >
                          {customer?.defaultContact?.title} {customer?.defaultContact?.name}
                        </Typography>
                        <Typography
                          color="textSecondary"
                          variant="body2"
                        >
                         {customer?.defaultContact?.email}
                        </Typography>
                        <Typography
                          color="textSecondary"
                          variant="body2"
                        >
                         {customer?.defaultContact?.phone}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell onClick={() => goToProduct(customer.id)}>
                    <Box sx={{ ml: 0 }}>
                        <Typography
                          color="textSecondary"
                          variant="subtitle2"
                        >
                          { checkDate(customer?.latestContact?.contactDate) }
                        </Typography>
                        <Typography
                          color="textSecondary"
                          variant="body2"
                        >
                         {customer?.latestContact?.contactType}
                        </Typography>
                      </Box>
                  </TableCell>
                  <TableCell onClick={() => goToProduct(customer.id)}>
                    <Box sx={{ ml: 0 }}>
                      <Typography
                        color="textSecondary"
                        variant="subtitle2"
                      >
                        { checkDate(customer?.nextDate) }
                        {/* { format(customer?.latestContact?.nextTaskDate, 'dd.MM.yyyy') } */}
                      </Typography>
                      <Typography
                        color="textSecondary"
                        variant="body2"
                      >
                        {customer?.nextTask}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell align="center" onClick={() => goToProduct(customer.id)}>
                    <Box fullWidth sx={{overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}}>
                      <SeverityPill
                        //sx={{ borderTopLeftRadius: 12, borderTopRightRadius: 12 }}
                        sx={{borderTopLeftRadius: 12, borderBottomLeftRadius: 12,  minWidth: 6}}
                        color={(customer?.brochureSent)
                          ? 'success'
                          : 'error'}
                      >
                        { (customer?.brochureSent) ? 'B' : 'B' }
                      </ SeverityPill>
                      <SeverityPill
                        sx={{minWidth: 6}}
                        color={(customer?.demoSent)
                          ? 'success'
                          : 'error'}
                      >
                        { (customer?.demoSent) ? 'D' : 'D' }
                      </ SeverityPill>
                      <SeverityPill
                        //sx={{ borderBottomLeftRadius: 12, borderBottomRightRadius: 12}}
                        align="center"
                        sx={{minWidth: 6}}
                        color={(customer?.contractSent)
                          ? 'success'
                          : 'error'}
                      >
                        { (customer?.contractSent) ? 'C' : 'C' }
                      </ SeverityPill>
                      <SeverityPill
                        //sx={{ borderBottomLeftRadius: 12, borderBottomRightRadius: 12}}
                        align="center"
                        sx={{borderTopRightRadius: 12, borderBottomRightRadius: 12,  minWidth: 6}}
                        color={(customer?.contractSigned)
                          ? 'success'
                          : 'error'}
                      >
                        { (customer?.contractSigned) ? 'S' : 'S' }
                      </ SeverityPill>
                    </Box>
                    {/* <Box sx={{mt:0.5}} fullWidth> */}
                      <Typography
                        //sx={{m: "auto"}}
                        color={getColor(customer?.status)}
                        variant="caption"
                      >
                        {customer?.status}
                      </Typography>
                    {/* </Box> */}
                  </TableCell>
                  <TableCell align="right">
                      {/* <IconButton component="a">
                        <PencilAltIcon fontSize="small" />
                      </IconButton> */}
                      <AddEvent type="new" companyid={customer?.id} refresh={props.refresh} />
                      <AddContact companyid={customer?.id} refresh={props.refresh} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Scrollbar>
      <TablePagination
        component="div"
        count={customersCount}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
      />
    </div>
  );
};

// CustomerListTable.propTypes = {
//   customers: PropTypes.array.isRequired,
//   customersCount: PropTypes.number.isRequired,
//   onPageChange: PropTypes.func.isRequired,
//   onRowsPerPageChange: PropTypes.func,
//   page: PropTypes.number.isRequired,
//   rowsPerPage: PropTypes.number.isRequired
// };
