import { Box, Button, Grid, Tab, Tabs, TextField, Typography, styled } from '@mui/material';
import {useEffect, useState } from 'react';
// import JsonEditor from 'react-json-editor-ui';
// import 'react-json-editor-ui/dist/react-json-editor-ui.cjs.development.css';
import './viewer-styling.css';

// onJsonChange(key, value, parent, data){
//   console.log(key, value, parent, data);
// }

const style = {
  backgroundColor: '#313133',
  color: 'white',
  borderRadius: '5px',
 // width: '100%'
};

function isJsonString(str) {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}

function syntaxHighlight(json) {
  if (!json) return ""; //no JSON from response

  json = json
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;");
  return json.replace(
    /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
    function (match) {
      var cls = "number";
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = "key";
        } else {
          cls = "string";
        }
      } else if (/true|false/.test(match)) {
        cls = "boolean";
      } else if (/null/.test(match)) {
        cls = "null";
      }
      return '<span class="' + cls + '">' + match + "</span>";
    }
  );
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {/* <Typography>{children}</Typography> */}
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const JsonViewerAndEditor = (props) => {
  const { 
    product,
    close
  } = props;
  const [editObject, setEditObject] = useState(product)
  const [jsonString, setJsonString ] = useState(JSON.stringify(product, undefined, 4))

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // useEffect(() => {
  //   setEditObject(product)
  // }, [product])

  return (
    <>
      {/* <JSONEditor data={product} onChange={onJsonChange} collapsible view="dual"/> */}
      <Box sx={style}>
        <Box>
          <Tabs sx={{  
            '& .MuiTabs-indicator': {
              backgroundColor: 'white'
            }
          }} 
          value={value} 
          variant="fullWidth" 
          onChange={handleChange} 
          aria-label="basic tabs example"
          >
            <Tab 
              sx={{
                '&.Mui-selected': {
                  color: 'white'
                }, 
                color: '#ccc'
              }} 
              label="Viewer" 
              {...a11yProps(0)} 
            />
            <Tab 
              sx={{
                '&.Mui-selected': {
                  color: 'white'
                }, 
                color: '#ccc'
              }} 
              label="Editor" 
              {...a11yProps(1)} 
            />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <div>
            <pre
              dangerouslySetInnerHTML={{
                __html: syntaxHighlight(JSON.stringify(editObject, undefined, 4))
              }}
            />
          </div>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Box sx={{m: 2}}>
            <TextField
              //id="outlined-textarea"
              //label="JSON"
              value={jsonString}
              multiline
              fullWidth
              variant="standard"
              //color="white"
              onChange={(event) => {
                setJsonString(event.target.value);
              }}
              sx={{ textarea: { paddingLeft: '4px', paddingRight: '8px', color: 'white', fontSize: '1em', lineHeight: "1.5", fontFamily: 'SFMono-Regular,Consolas,Liberation Mono,Menlo,Courier,monospace' } }}
            />
          </Box>
          <Button variant="text" sx={{my: 1, color: 'white'}} onClick={() => console.log(isJsonString(jsonString))}>Check product</Button>
        </CustomTabPanel>
      </Box>
    </>
  );
};
