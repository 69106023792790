import { useRef, useState } from 'react';
import { Tooltip, IconButton } from '@mui/material';
import { Plus } from './icons/plus';
import { EventModal } from './event-modal';



export const AddEvent = (props) => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Add Event">
        <IconButton
          onClick={handleOpen}
          // ref={anchorRef}
          // {...props}
          >
          <Plus />
        </IconButton>
      </Tooltip>
      <EventModal open={open} close={handleClose} companyid={props.companyid} refresh={props.refresh} />
    </>
  );
};
