import React, { useEffect } from "react";
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import { useMsal } from "@azure/msal-react";

// components
import ContactingPage from "../components/ContactingPage";
// import HeroSection from "../components/Home/HeroSection";
// import CategorySection from "../components/Home/CategorySection/CategorySection";
// import FeaturedObjects from "../components/Home/FeaturedObjects/FeaturedObjects";
// import ManufactureSection from "../components/Home/ManufactureSection/ManufactureSection";
// import Footer from "../components/Footer";
// import UnauthenticatedAccess from "../components/Home/UnauthenticatedAccess";

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

import { DashboardLayout } from "../components/dashboard-layout";


const Contacting = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const { accounts } = useMsal();
  const location = useLocation();

  const id = location.pathname.split("/contacting/");
  const customerId = id[1].split("/")[0];
  
  const navigate = useNavigate();

  return (
    <>
      <AuthenticatedTemplate>
        <DashboardLayout>
          <ContactingPage customerId={customerId}/>
        </DashboardLayout>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        {/* <UnauthenticatedAccess /> */}
      </UnauthenticatedTemplate>
    </>
  );
};

// Home.getLayout = (page) => (
//   <DashboardLayout>
//     {page}
//   </DashboardLayout>
// );

export default Contacting;