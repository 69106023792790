import { useCallback, useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Button,
  Chip,
  Container,
  Divider,
  Grid,
  Link,
  Tab,
  Tabs,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { customerApi } from '../../__fake-api__/customer-api';
import { DemoProducts } from './demo-products';
import { CompanyContacts } from './company-contacts';
import { useMounted } from '../../hooks/use-mounted';
import { ChevronDown as ChevronDownIcon } from '../../icons/chevron-down';
import { PencilAlt as PencilAltIcon } from '../../icons/pencil-alt';
import { Plus as PlusIcon } from '../../icons/plus';
import { CribSharp, PropaneSharp } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
//import { gtm } from './lib/gtm';
//import { getInitials } from './utils/get-initials';
import { EventModal } from '../../event-modal';
import { ContactModal } from '../../contact-modal';
import { getSingleDemo, getDemoProducts } from '../../api';
import {v4 as uuidv4} from 'uuid';
import { CustomerEditForm } from './customer-edit-form';
import { DemoImportModal } from '../../demo-import-modal';

const tabs = [
  { label: 'Company details', value: 'details' },
  { label: 'Products', value: 'products' }
];

const DemoPage = (props) => {
  const isMounted = useMounted();
  const [customer, setCustomer] = useState(null);
  const [newEvent, setNewEvent] = useState(false);
  const [showDemo, setShowDemo] = useState(false);
  //const [newContact, setNewContact] = useState(false);
  const [currentTab, setCurrentTab] = useState('details');
  const [contacts, setContacts] = useState([]);
  const [logs, setLogs] = useState([]);
  const [refreshLogs, setRefreshLogs] = useState(0);
  const [refreshContacts, setRefreshContacts] = useState(0);
  const [refresh, setRefresh] = useState(0);
  const [productRefresh, setProductRefresh] = useState(0);
  const [products, setProducts] = useState([]);
  
  const navigate = useNavigate();

  // const products = [
  //   {
  //     id: 1,
  //     name: "tuote1",
  //     url: "http://localhost:3000/demo/ad97a81c-8366-40bb-a7d1-e9286221e387",
  //     jsonFilename: "testi.json",
  //     imageFilenames: ["testi1.png", "testi1.jpg"]
  //   },
  //   {
  //     id: 2,
  //     name: "tuote2",
  //     url: "url.com/2",
  //     jsonFilename: "testi2.json",
  //     imageFilenames: ["testi2.png", "testi2.jpg"]
  //   },
  //   {
  //     id: 3,
  //     name: "tuote3",
  //     url: "url.com/3",
  //     jsonFilename: "testi3.json",
  //   }
  // ];

  // Get single customer details
  const getCustomer = useCallback(async () => {
    try {
      //const data = await customerApi.getCustomers();
      const data = await getSingleDemo(props.customerId);
      
      //const filtered = data?.filter(data => data.id === props.customerId);

      const firstCustomer = data[0];

      if (isMounted()) {
        //setCustomer(data);
        setCustomer(firstCustomer);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  useEffect(() => {
      getCustomer();
    }, [refresh]);

  const handleRefresh = () => {
    setRefresh(refresh + 1)
  }

  const getProducts = useCallback(async () => {
    try {
      //const data = await customerApi.getCustomers();
      const data = await getDemoProducts(props.customerId);
      
      //const filtered = data?.filter(data => data.id === props.customerId);
      //console.log(data);
      // const firstCustomer = data[0];

      if (isMounted()) {
        //setCustomer(data);
        setProducts(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  useEffect(() => {
      getProducts();
    }, [productRefresh]);

  const handleProductRefresh = () => {
    setProductRefresh(productRefresh + 1)
  }

  // // Get contact details
  // const getContactDetails = useCallback(async () => {
  //   try {
  //     const data = await getContacts(props.customerId);

  //     if (isMounted()) {
  //       setContacts(data);
  //     }
  //   } catch (err) {
  //     console.error(err);
  //   }
  // }, [isMounted]);

  // useEffect(() => {
  //   getContactDetails();
  // }, [refreshContacts]);

  
  const handleRefreshContacts = () => {
    setRefreshContacts(refreshContacts + 1)
  }

  // // GET Contacting logs
  // const getLogs = useCallback(async () => {
  //   try {
  //     //const data = await customerApi.getCustomerLogs();
  //     const data = await getContactingLog(props.customerId);
  //     //console.log(data);
  //     if (isMounted()) {
  //       setLogs(data);
  //     }
  //   } catch (err) {
  //     console.error(err);
  //   }
  // }, [isMounted]);

  // useEffect(() => {
  //   getLogs();
  // }, [refreshLogs]);


  const handleRefreshLogs = () => {
    setRefreshLogs(refreshLogs + 1)
  }


  const handleOpenNewEvent = () => {
    setNewEvent(true);
  };

  const handleCloseNewEvent = () => {
    setNewEvent(false);
  };

  const handleShowDemo = () => {
    setShowDemo(true);
  };

  const handleCloseDemo = () => {
    setShowDemo(false);
  };

  // const handleOpenNewContact = () => {
  //   setNewContact(true);
  // };

  // const handleCloseNewContact = () => {
  //   setNewContact(false);
  // };


  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  if (!customer) {
    return null;
  }

  return (
    <>
      {/* <Head>
        <title>
          Dashboard: Customer Details | Material Kit Pro
        </title>
      </Head> */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8
        }}
      >
        <Container>
          <div>
            <Box sx={{ mb: 4 }}>
              {/* <NextLink
                href="/dashboard/customers"
                passHref
              > */}
                <Link
                  color="textPrimary"
                  component="a"
                  onClick={() => navigate("/demo/")}
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    cursor: 'pointer'
                  }}
                >
                  <ArrowBackIcon
                    fontSize="small"
                    sx={{ mr: 1 }}
                  />
                  <Typography variant="subtitle2">
                    Demo Accounts
                  </Typography>
                </Link>
              {/* </NextLink> */}
            </Box>
            <Grid
              container
              justifyContent="space-between"
              spacing={3}
            >
              <Grid
                item
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  overflow: 'hidden'
                }}
              >
                <Avatar
                  src={customer.avatar}
                  sx={{
                    height: 64,
                    mr: 2,
                    width: 64
                  }}
                >
                  {/* {getInitials(customer.name)} */}
                  OK
                </Avatar>
                <div>
                  <Typography variant="h4" color="neutral.900">
                    {customer.companyName}
                  </Typography>
                  <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex'
                      }}
                  >
                    <Box>
                      <Typography
                        color="textSecondary"
                        variant="subtitle2"
                      >
                        Name: {customer?.defaultContact?.title} {customer?.defaultContact?.name}
                      </Typography>
                      <Typography
                        color="textSecondary"
                        variant="subtitle2"
                      >
                        Email: {customer?.defaultContact?.email}
                      </Typography>
                      <Typography
                        color="textSecondary"
                        variant="subtitle2"
                      >
                        Phone: {customer?.defaultContact?.phone}
                      </Typography>
                    </Box>
                  </Box>
                </div>
              </Grid>
              <Grid
                item
                sx={{ mt: 2, mb: -1, ml: -1 }}
              >
                <Button
                  component="a"
                  endIcon={(
                    <PlusIcon fontSize="small" />
                  )}
                  sx={{ m: 0.5, width: "170px" }}
                  variant="outlined"
                  onClick={handleShowDemo}
                >
                  Import products
                </Button>
                <DemoImportModal data={products} companyid={props.customerId} open={showDemo} close={handleCloseDemo} refresh={handleProductRefresh} />
              </Grid> 
           </Grid>
            <Tabs
              indicatorColor="primary"
              onChange={handleTabsChange}
              scrollButtons="auto"
              sx={{ mt: 3 }}
              textColor="primary"
              value={currentTab}
              variant="scrollable"
            >
              {tabs.map((tab) => (
                <Tab
                  key={tab.value}
                  label={tab.label}
                  value={tab.value}
                />
              ))}
            </Tabs>
          </div>
          <Divider />
          <Box sx={{ mt: 3 }}>
            {currentTab === 'details' && <CustomerEditForm contacts={contacts} companyid={props.customerId} refresh={handleRefreshContacts} />}
            {currentTab === 'products' && (
              <>
                <DemoProducts products={products} companyid={props.customerId} refresh={handleRefreshLogs} />
              </>
            )}
          </Box>
        </Container>
      </Box>
    </>
  );
};

// CustomerDetails.getLayout = (page) => (
//   <AuthGuard>
//     <DashboardLayout>
//       {page}
//     </DashboardLayout>
//   </AuthGuard>
// );

export default DemoPage;