import { useCallback, useEffect, useState } from 'react';
// import NextLink from 'next/link';
//import numeral from 'numeral';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
// import { getInitials } from '../../../utils/get-initials';
import { Scrollbar } from '../../scrollbar';
import { format } from 'date-fns';
import { GenericLibraryModal } from './generic-library-modal';
import { PencilAlt as PencilAltIcon } from '../../icons/pencil-alt';
import { useMounted } from '../../hooks/use-mounted';
import { OpenDemo } from './open-demo';


export const GenericLibraryList = (props) => {
  const {
    // brands,
    genericObjects,
    genericObjectsCount,
    onPageChange,
    onRowsPerPageChange,
    page,
    rowsPerPage,
    refresh,
    ...other
  } = props;
  const [selectedGenericObjects, setSelectedGenericObjects] = useState([]);

  const navigate = useNavigate();


  const handleSelectAllGenericObjects = (event) => {
    setSelectedGenericObjects(event.target.checked
      ? genericObjects.map((genericObject) => genericObject.id)
      : []);
  };

  const handleSelectOneCustomer = (event, genericObjectId) => {
    if (!selectedGenericObjects.includes(genericObjectId)) {
      setSelectedGenericObjects((prevSelected) => [...prevSelected, genericObjectId]);
    } else {
      setSelectedGenericObjects((prevSelected) => prevSelected.filter((id) => id !== genericObjectId));
    }
  };


  const enableBulkActions = selectedGenericObjects.length > 0;
  const selectedSomeGenericObjects = selectedGenericObjects.length > 0
    && selectedGenericObjects.length < genericObjects.length;
  const selectedAllGenericObjects = selectedGenericObjects.length === genericObjects.length;

  return (
    <div {...other}>
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.mode === 'dark'
            ? 'neutral.800'
            : 'neutral.100',
          display: enableBulkActions ? 'block' : 'none',
          px: 2,
          py: 0.5
        }}
      >
        <Checkbox
          checked={selectedAllGenericObjects}
          indeterminate={selectedSomeGenericObjects}
          onChange={handleSelectAllGenericObjects}
        />
        <Button
          size="small"
          sx={{ ml: 2 }}
        >
          Delete
        </Button>
        <Button
          size="small"
          sx={{ ml: 2 }}
        >
          Edit
        </Button>
      </Box>
      <Scrollbar>
        <Table sx={{ minWidth: 700 }}>
          <TableHead sx={{ visibility: enableBulkActions ? 'collapse' : 'visible' }}>
            <TableRow>
              <TableCell>
                Company Name
              </TableCell>
              <TableCell>
                Product Name
              </TableCell>
              <TableCell>
                Object ID
              </TableCell>
              <TableCell align="right">
                Actions
              </TableCell>
              {/* <TableCell align="right">
                Edit
              </TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {genericObjects.map((genericObject) => {
              // const isCustomerSelected = selectedGenericObjects.includes(genericObject.id);


              return (
                <TableRow
                  // hover
                  key={genericObject.id}
                  // selected={isCustomerSelected}
                  // sx={{cursor: "pointer"}}
                  sx={[
                    {
                      '&:hover': {
                        boxShadow: '0 0 2px rgba(33,33,33,.2); '
                      },
                    }
                  ]}
                >
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex'
                      }}
                    >
                      <Box>
                        <Typography
                          color="inherit"
                          variant="subtitle2"
                        >
                          {genericObject?.brandName}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell >
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex'
                      }}
                    >
                      <Box>
                        <Typography
                          color="inherit"
                          variant="body2"
                        >
                          {genericObject?.name}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box>
                        <Typography
                          color="textSecondary"
                          variant="subtitle"
                        >
                          { genericObject?.objectID }
                        </Typography>
                      </Box>
                  </TableCell>
                  <TableCell align="right">
                    {/* <OpenDemo edit={true} brands={brands} product={genericObject} refresh={refresh} /> */}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Scrollbar>
      <TablePagination
        component="div"
        count={genericObjectsCount}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
      />
      {/* <DemoLibraryModal product={product} open={open} close={handleCloseDemo} refresh={props.refresh} /> */}
    </div>
  );
};

// CustomerListTable.propTypes = {
//   genericObjects: PropTypes.array.isRequired,
//   genericObjectsCount: PropTypes.number.isRequired,
//   onPageChange: PropTypes.func.isRequired,
//   onRowsPerPageChange: PropTypes.func,
//   page: PropTypes.number.isRequired,
//   rowsPerPage: PropTypes.number.isRequired
// };
