import { useRef, useState } from 'react';
import { Button, Box, Grid, Divider, FormControl, InputLabel, Select, MenuItem, TextField, Typography, Tooltip, IconButton, Modal } from '@mui/material';
//import { Add } from '@mui/icons-material';
import { Plus as PlusIcon } from './icons/plus';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { useFormik } from 'formik';
//import { uuid } from 'uuidv4';
import {v4 as uuidv4} from 'uuid';
import { postContact, addCompany } from './api';
import { wait } from './utils/wait';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #313133',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
};

export const AddCompany = (props) => {
  const anchorRef = useRef(null);
  const { refresh } = props;
  const [open, setOpen] = useState(false);

  const formik = useFormik({
    initialValues: {
      id: uuidv4(),
      companyName: '',
      slug: '',
      country: '',
      modelling: '',
      defaultContact: {
        id: uuidv4(),
        title: '',
        name: '',
        role: '',
        country: '',
        email: '',
        directNumber: '',
        phone: '',
        default: true
      },
      latestContact: {
        id: uuidv4(),
        contactDate: Date.now(),
        contactType: 'Company added'
      },
      status: 'Not contacted',
      submit: null
    },
    validationSchema: Yup.object({
      id: Yup.string().max(255),
      companyName: Yup.string().max(255).required('Company name is required'),
      slug: Yup.string().max(255).required('Unique slug is required'),
      country: Yup.string().max(255),
      modelling: Yup.string().max(255),
      defaultContact: Yup.object().shape({
        id: Yup.string().max(255),
        title: Yup.string().max(50),
        name: Yup.string().max(255),
        role: Yup.string().max(255),
        country: Yup.string().max(255),
        email: Yup
          .string()
          .email('Must be a valid email')
          .max(255),
        directNumber: Yup.string().max(255),
        phone: Yup.string().max(20)
      }),
      status: Yup.string().max(255),
      default: Yup.bool()
    }),
    onSubmit: async (values, helpers) => {
      try {
        // NOTE: Make API request
        const responseMessage = await addCompany(values);
        
        if (values.defaultContact.name) {
          let tmp = values.defaultContact;
          tmp["companyId"] = values.id;
          await postContact(tmp);
        } 
        //close();
        console.log(responseMessage);
        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);
        toast.success('Contact updated!');
        await wait(500);
        helpers.resetForm();
        setNewIds();
        refresh();
        handleClose();
      } catch (err) {
        console.error(err);
        toast.error('Something went wrong!');
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    }
  });

  const setNewIds = () => {
    formik.setFieldValue('id', uuidv4());
    formik.setFieldValue('defaultContact.id', uuidv4());
    formik.setFieldValue('latestContact.id', uuidv4());
  }


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Add a company">
        <Button
          startIcon={<PlusIcon fontSize="small" />}
          variant="contained"
          onClick={handleOpen}
          ref={anchorRef}
          {...props}
        >
          Add company
        </Button>
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form
        onSubmit={formik.handleSubmit}
        // {...other}
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{mb: 2, width: "100%"}}>
              Add a new company
            </Typography>
            <Typography id="modal-modal-subtitle" variant="subtitle2" component="h2" sx={{mb: 1, width: "100%"}}>
              Company details
            </Typography>
            {/* <Divider sx={{mb: 1, width: "100%"}} /> */}
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="outlined-flexible"
                  label="Company name (e.g. AMCF AG)"
                  name="companyName"
                  //sx={{mb: 1, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.companyName}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="outlined-flexible"
                  label="Unique slug (no spaces - e.g. amcf-ag)"
                  name="slug"
                  //sx={{mb: 1, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.slug}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="simple-select-label">Modelling</InputLabel>
                  <Select
                    labelId="simple-select-label"
                    label="Modelling"
                    name="modelling"
                    value={formik.values.modelling}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  >
                    <MenuItem value={""}></MenuItem>
                    <MenuItem value={"Bimroom compatible BIM objects"}>Bimroom compatible BIM objects</MenuItem>
                    <MenuItem value={"BIM objects need remodelling"}>BIM objects need remodelling</MenuItem>
                    <MenuItem value={"Products not BIM modelled"}>Products not BIM modelled</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            {/* </Grid> 
            <Grid container spacing={1}> */}
              <Grid item xs={6}>
                <TextField
                  id="outlined-flexible"
                  label="Country (HQ)"
                  name="country"
                  sx={{mb: 1, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.country}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="outlined-flexible"
                  label="Market Area"
                  name="marketArea"
                  sx={{mb: 1}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.defaultContact.marketArea}
                />
              </Grid>
            </Grid>
            <Typography id="modal-modal-subtitle" variant="subtitle2" component="h2" sx={{mb: 1, width: "100%"}}>
              Contact person details
            </Typography>
            <Grid container spacing={1} sx={{mb: 2}}>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel id="simple-select-label">Title</InputLabel>
                  <Select
                    labelId="simple-select-label"
                    label="Title"
                    name="defaultContact.title"
                    value={formik.values.defaultContact.title}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  >
                    <MenuItem value={""}></MenuItem>
                    <MenuItem value={"Ms"}>Ms</MenuItem>
                    <MenuItem value={"Mrs"}>Mrs</MenuItem>
                    <MenuItem value={"Mr"}>Mr</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={9}>
                  <TextField
                  fullWidth
                  id="outlined-flexible"
                  label="Name"
                  name="defaultContact.name"
                  //sx={{mb: 1}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.defaultContact.name}
                />
              </Grid>
            {/* </Grid> 
            <Grid container spacing={1}> */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="outlined-flexible"
                  label="Role"
                  name="defaultContact.role"
                  
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.defaultContact.role}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="outlined-flexible"
                  label="Country"
                  name="defaultContact.country"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.defaultContact.country}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="outlined-flexible"
                  label="Email"
                  name="defaultContact.email"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.defaultContact.email}
                />
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label" >Direct/Info</InputLabel>
                  <Select
                    labelId="simple-select-label"
                    label="Direct/info"
                    name="defaultContact.directNumber"
                    value={formik.values.defaultContact.directNumber}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  >
                    <MenuItem value={""}></MenuItem>
                    <MenuItem value={"Direct"}>Direct number</MenuItem>
                    <MenuItem value={"Info desk"}>Info desk number</MenuItem>
                  </Select>
                </FormControl>
              </Grid> 
              <Grid item xs={8}>
                <TextField
                  fullWidth
                  id="outlined-flexible"
                  label="Phone"
                  name="defaultContact.phone"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.defaultContact.phone}
                />
              </Grid>
            </Grid>
          
            <IconButton 
              disabled={formik.isSubmitting}
              type="submit"
            >
              <PlusIcon />
              <Typography>Add company</Typography>
            </ IconButton>
          </Box>
        </form>
      </Modal>
      
    </>
  );
};
