import React, { useEffect } from "react";
import "./App.css";
// import "./Theme.css";
// import "bootstrap/dist/css/bootstrap.min.css";
//import "semantic-ui-css/semantic.min.css";

// packages
import { useMsal } from "@azure/msal-react";
import { Routes, Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

// components
import Home from "./pages/Home";
import Contacting from "./pages/Contacting";
import ContactingPage from "./pages/ContactingPage";
import CustomerPage from "./pages/CustomerPage";
import ContractPage from "./pages/ContractPage";
import DemoListPage from "./pages/DemoList";
import Demo from "./pages/DemoPage";
import Customers from "./pages/Customers";
import Subscriptions from "./pages/Subscriptions";
import Analytics from "./pages/Analytics";
import DemoProductsPage from "./pages/DemoProducts";
import DemoBrandsPage from "./pages/DemoBrands";
import Products from "./pages/Products";
import GenericPage from "./pages/GenericPage"
import QcCheckPage from "./pages/QcCheckPage";
import ProductFormPage from "./pages/ProductForm";
import ProductFormPageV2 from "./pages/ProductFormV2";
import { getAllBrands } from "./api";
import { setBrands } from './redux/reducers/user-reducer';


// apis
// import { getProjects, createUser, getUserDetail, getReviewAndRating, getReviews, getKey } from "./api";

function App() {
  const { instance, accounts } = useMsal();

  const dispatch = useDispatch();
  const location = useLocation();

  //const getLayout = Component.getLayout ?? ((page) => page);
  //const [key, setKey] = useState([]);


  useEffect(async () => {
    if (accounts[0]) {
      try {
        const response = await getAllBrands()
        if (response) {
          dispatch(setBrands(response));
        } else {
          dispatch(setBrands([]));
        }
        
      } catch (err) {
        dispatch(setBrands([]));
      }
    } else {
      dispatch(setBrands([]));
    }
  }, [accounts[0]]);

  // useEffect(async () => {
  //   if (accounts[0]) {
  //     try {
  //       let response = await createUser({
  //         id: accounts[0]?.localAccountId,
  //         name: accounts[0]?.name,
  //         email: accounts[0]?.username,
  //         cart: [],
  //         projects: [uuidv4()],
  //         //searchKey: "",
  //       });
  //       dispatch(setUser(response));
  //       if (response) {
  //         let projects = await getProjects({
  //           id: accounts[0]?.localAccountId
  //         });
  //         //console.log(projects);
  //         dispatch(setProjects(projects));
  //       }
  //       dispatch(setUserState(false));
  //     } catch (err) {
  //       dispatch(setUser({}));
  //       //dispatch(setUserState(false));
  //       dispatch(setProjects([]));
  //     }
  //   } else {
  //     dispatch(setUser({}));
  //     //dispatch(setUserState(false));
  //     dispatch(setProjects([]));
  //   }
  // }, [accounts[0]]);

  // useEffect(async () => {
  //   try {
  //     // let response = await getReviews();
  //     let response = await getReviewAndRating();
  //     dispatch(setRatingAndReview(response.data));
  //   } catch (err) {
  //     console.log("App page get product api error =>", err.message);
  //   }
  // }, []);

  // useEffect(() => {
  //   const temp = window?.location?.href?.split("/");
  //   const temp1 = temp[3]?.split("=");

  //   if (temp1?.length > 0 && temp1[0] === "#id_token") {
  //     instance.logoutRedirect().catch((e) => {
  //       console.error(e);
  //     });
  //   }
  // }, [location?.pathname]);

  // useEffect(() => {
  //   if (accounts[0]) {
  //     //const objectId = "user2";
  //     // NEW CODE
  //     getKey({ objectId: accounts[0]?.localAccountId })
  //       .then((data) => {
  //         //console.log(data);
  //         dispatch(setKey(data));
  //       })
  //       .catch((err) => console.log("product detail page error =>", err.message));
  //     //console.log(key);
  //   }
  // }, [accounts[0]]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/analytics" element={<Analytics />} />
        <Route path="/contacting" element={<Contacting />} />
        <Route path="/contacting/:id" element={<ContactingPage />} />
        <Route path="/clients" element={<Customers />} />
        <Route path="/clients/:id" element={<CustomerPage />} />
        <Route path="/demo/requests" element={<DemoListPage />} />
        <Route path="/demo/:id" element={<Demo />} />
        <Route path="/demo/products" element={<DemoProductsPage />} />
        <Route path="/demo/brands" element={<DemoBrandsPage />} />
        <Route path="/contracts" element={<ContractPage />} />
        <Route path="/subscriptions" element={<Subscriptions />} />
        <Route path="/products" element={<Products />} />
        <Route path="/products/v1/new" element={<ProductFormPage />} />
        <Route path="/products/form/v2" element={<ProductFormPageV2 />} />
        <Route path="/generic" element={<GenericPage />} />
        <Route path="/qc" element={<QcCheckPage />} />
      </Routes>
    </>
  );
}

export default App;
