import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import {
  Box,
  Button,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography
} from '@mui/material';
import { Duplicate as DuplicateIcon } from '../icons/duplicate';
import { X as XIcon } from '../icons/x';


export const FilesUploadedField = (props) => {
  const {
    data,
    onRemove,
    onRemoveAll,
    ...other
  } = props;

  // We did not add the remaining props to avoid component complexity
  // but you can simply add it if you need to.

  return (
    <div {...other}>
      <Box sx={{ mt: 2 }}>
        <List>
          {data.map((file) => (
            <ListItem
              key={file}
              sx={{
                border: 1,
                backgroundColor: '#90ee90',
                borderColor: 'divider',
                borderRadius: 1,
                '& + &': {
                  mt: 1
                }
              }}
            >
              <ListItemIcon>
                <DuplicateIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primary={file}
                primaryTypographyProps={{
                  color: 'textPrimary',
                  variant: 'subtitle2'
                }}
                // secondary={bytesToSize(file.size)}
              />
              <Tooltip title="Remove">
                <IconButton
                  edge="end"
                  onClick={() => onRemove?.(file)}
                >
                  <XIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </ListItem>
          ))}
        </List>
      </Box>
    </div>
  );
};

// FileDropzone.propTypes = {
//   accept: PropTypes.oneOfType([
//     PropTypes.string,
//     PropTypes.arrayOf(PropTypes.string.isRequired)
//   ]),
//   disabled: PropTypes.bool,
//   files: PropTypes.array,
//   getFilesFromEvent: PropTypes.func,
//   maxFiles: PropTypes.number,
//   maxSize: PropTypes.number,
//   minSize: PropTypes.number,
//   noClick: PropTypes.bool,
//   noDrag: PropTypes.bool,
//   noDragEventsBubbling: PropTypes.bool,
//   noKeyboard: PropTypes.bool,
//   onDrop: PropTypes.func,
//   onDropAccepted: PropTypes.func,
//   onDropRejected: PropTypes.func,
//   onFileDialogCancel: PropTypes.func,
//   onRemove: PropTypes.func,
//   onRemoveAll: PropTypes.func,
//   onUpload: PropTypes.func,
//   preventDropOnDocument: PropTypes.bool
// };
