import { useCallback, useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  Chip,
  Container,
  Divider,
  Grid,
  Link,
  Tab,
  Tabs,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { customerApi } from '../../__fake-api__/customer-api';
import { ContactingLogs } from './contacting-logs';
import { CompanyContacts } from './company-contacts';
import { useMounted } from '../../hooks/use-mounted';
import { ChevronDown as ChevronDownIcon } from '../../icons/chevron-down';
import { PencilAlt as PencilAltIcon } from '../../icons/pencil-alt';
import { Plus as PlusIcon } from '../../icons/plus';
import { CribSharp, PropaneSharp } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
//import { gtm } from './lib/gtm';
//import { getInitials } from './utils/get-initials';
import { EventModal } from '../../event-modal';
import { ContactModal } from '../../contact-modal';
import { DemoStatusModal } from '../../demo-status-modal';
import { getSingleProfile, updateContacting, getContacts, getContactingLog, addDemo, addContract } from '../../api';
import {v4 as uuidv4} from 'uuid';
import { ContractStatusModal } from '../../contract-status-modal';


const tabs = [
  { label: 'Contacting', value: 'contacting' },
  { label: 'Details', value: 'details' }
];

const ContactingPage = (props) => {
  const isMounted = useMounted();
  const [customer, setCustomer] = useState(null);
  const [newEvent, setNewEvent] = useState(false);
  const [newContact, setNewContact] = useState(false);
  const [demoStatus, setDemoStatus] = useState(false);
  const [contractStatus, setContractStatus] = useState(false);
  const [currentTab, setCurrentTab] = useState('contacting');
  const [contacts, setContacts] = useState([]);
  const [logs, setLogs] = useState([]);
  const [refreshLogs, setRefreshLogs] = useState(0);
  const [refreshContacts, setRefreshContacts] = useState(0);
  const [refresh, setRefresh] = useState(0);
  
  const navigate = useNavigate();


  // Get single customer details
  const getCustomer = useCallback(async () => {
    try {
      //const data = await customerApi.getCustomers();
      const data = await getSingleProfile(props.customerId);
      
      //const filtered = data?.filter(data => data.id === props.customerId);

      const firstCustomer = data[0];

      if (isMounted()) {
        //setCustomer(data);
        setCustomer(firstCustomer);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  useEffect(() => {
      getCustomer();
    }, [refresh]);

  const handleRefresh = () => {
    setRefresh(refresh + 1)
  }

  // Get contact details
  const getContactDetails = useCallback(async () => {
    try {
      const data = await getContacts(props.customerId);

      if (isMounted()) {
        setContacts(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  useEffect(() => {
    getContactDetails();
  }, [refreshContacts]);

  
  const handleRefreshContacts = () => {
    setRefreshContacts(refreshContacts + 1)
  }

  // GET Contacting logs
  const getLogs = useCallback(async () => {
    try {
      //const data = await customerApi.getCustomerLogs();
      const data = await getContactingLog(props.customerId);
      //console.log(data);
      if (isMounted()) {
        setLogs(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  useEffect(() => {
    getLogs();
  }, [refreshLogs]);


  const addNewDemo = async (customer) => {

    const payload = {
      id: uuidv4(),
      contactingId: customer?.id,
      clientId: customer?.clientId,
      companyName: customer?.companyName,
      addedDate: Date.now(),
      email: customer?.defaultContact?.email
    };

    let updatedPayload = customer;

    updatedPayload.demoId = payload.id;
    updatedPayload.demoCreatedDate = Date.now();
    updatedPayload.demoStatus = "Demo created";
    
    try {
      //const data = await customerApi.getCustomerLogs();
      const data = await addDemo(payload);
      const updatedData = await updateContacting(updatedPayload);
      //console.log(data);
      if (isMounted()) {
        // console.log(data);
        // console.log(updatedData);
        handleRefresh();
      }
    } catch (err) {
      console.error(err);
    }
  }

  const addNewContract = async (customer) => {

    const payload = {
      id: uuidv4(),
      contactingId: customer?.id,
      clientId: customer?.clientId,
      companyName: customer?.companyName,
      addedDate: Date.now(),
      name: customer?.defaultContact?.name,
      email: customer?.defaultContact?.email,
      phone: customer?.defaultContact?.phone
    };

    let updatedPayload = customer;

    updatedPayload.contractId = payload.id;
    updatedPayload.contractCreatedDate = Date.now();
    updatedPayload.contractStatus = "Contract created";
    
    try {
      //const data = await customerApi.getCustomerLogs();
      const data = await addContract(payload);
      const updatedData = await updateContacting(updatedPayload);
      //console.log(data);
      if (isMounted()) {
        console.log(data);
        console.log(updatedData);
        handleRefresh();
      }
    } catch (err) {
      console.error(err);
    }
  }

  const handleRefreshLogs = () => {
    setRefreshLogs(refreshLogs + 1)
  }


  const handleOpenNewEvent = () => {
    setNewEvent(true);
  };

  const handleCloseNewEvent = () => {
    setNewEvent(false);
  };

  const handleOpenNewContact = () => {
    setNewContact(true);
  };

  const handleCloseNewContact = () => {
    setNewContact(false);
  };

  const handleOpenDemoStatus = () => {
    setDemoStatus(true);
  };

  const handleCloseDemoStatus = () => {
    setDemoStatus(false);
  };

  const handleOpenContractStatus = () => {
    setContractStatus(true);
  };

  const handleCloseContractStatus = () => {
    setContractStatus(false);
  };

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  if (!customer) {
    return null;
  }

  return (
    <>
      {/* <Head>
        <title>
          Dashboard: Customer Details | Material Kit Pro
        </title>
      </Head> */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8
        }}
      >
        <Container>
          <div>
            <Box sx={{ mb: 4 }}>
              {/* <NextLink
                href="/dashboard/customers"
                passHref
              > */}
                <Link
                  color="textPrimary"
                  component="a"
                  onClick={() => navigate("/contacting/")}
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    cursor: 'pointer'
                  }}
                >
                  <ArrowBackIcon
                    fontSize="small"
                    sx={{ mr: 1 }}
                  />
                  <Typography variant="subtitle2">
                    Contact list
                  </Typography>
                </Link>
              {/* </NextLink> */}
            </Box>
            <Grid
              container
              justifyContent="space-between"
              spacing={3}
            >
              <Grid
                item
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  overflow: 'hidden'
                }}
              >
                <Avatar
                  src={customer.avatar}
                  sx={{
                    height: 64,
                    mr: 2,
                    width: 64
                  }}
                >
                  {/* {getInitials(customer.name)} */}
                  OK
                </Avatar>
                <div>
                  <Typography variant="h4" color="neutral.900">
                    {customer.companyName}
                  </Typography>
                  <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex'
                      }}
                  >
                    <Box>
                      <Typography
                        color="textSecondary"
                        variant="subtitle2"
                      >
                        Name: {customer?.defaultContact?.title} {customer?.defaultContact?.name}
                      </Typography>
                      <Typography
                        color="textSecondary"
                        variant="subtitle2"
                      >
                        Email: {customer?.defaultContact?.email}
                      </Typography>
                      <Typography
                        color="textSecondary"
                        variant="subtitle2"
                      >
                        Phone: {customer?.defaultContact?.phone}
                      </Typography>
                    </Box>
                  </Box>
                </div>
              </Grid>
              <Grid
                item
                sx={{ m: -1 }}
              > 
                {/* <Card sx={{m: 0.5}}>
                  <Table sx={{width: "350px" }}>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">
                          Demo
                        </TableCell >
                        <TableCell align="center">
                          Contract
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell align="center">
                          {customer?.demoStatus ? customer.demoStatus : "Status unknown" }
                        </TableCell>
                        <TableCell align="center">
                          {customer?.contractStatus ? customer.contractStatus : "Status unknown" }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">
                          {customer?.demoId
                            ? <Button
                                component="a"
                                // endIcon={(
                                //   <PlusIcon fontSize="small" />
                                // )}
                                sx={{ m: 0.5, maxWidth: "170px" }}
                                variant="outlined"
                              
                                onClick={handleOpenDemoStatus}
                              >
                                Open
                              </Button>
                            : <Button
                                component="a"
                                endIcon={(
                                  <PlusIcon fontSize="small" />
                                )}
                                sx={{ m: 0.5, maxWidth: "170px" }}
                                variant="contained"
                              
                                onClick={() => addNewDemo(customer)}
                              >
                                Request
                              </Button>
                          }
                        </TableCell>
                        <TableCell align="center">
                          {customer?.contractId
                          ? <Button
                              component="a"
                              sx={{ m: 0.5, maxWidth: "170px" }}
                              variant="outlined"
                            
                              onClick={handleOpenContractStatus}
                            >
                              Open
                            </Button>
                          : <Button
                              component="a"
                              endIcon={(
                                <PlusIcon fontSize="small" />
                              )}
                              sx={{ m: 0.5, maxWidth: "170px" }}
                              variant="contained"
                            
                              onClick={() => addNewContract(customer)}
                            >
                              Add
                            </Button>
                          }
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  <DemoStatusModal refresh={handleRefresh} open={demoStatus} close={handleCloseDemoStatus} demodata={customer} />
                  <ContractStatusModal refresh={handleRefresh} open={contractStatus} close={handleCloseContractStatus} contractdata={customer} />
                </Card> */}
              </Grid>
           </Grid>
            <Grid
                container
                justifyContent="space-between"
                spacing={3}
            >
              <Grid
                item
                sx={{ mt: 2, mb: -1, ml: -1 }}
              >
                <Button
                  component="a"
                  endIcon={(
                    <PlusIcon fontSize="small" />
                  )}
                  sx={{ m: 0.5, width: "170px" }}
                  variant="outlined"
                  onClick={handleOpenNewEvent}
                >
                  Add event
                </Button>
                <EventModal companyid={props.customerId} open={newEvent} close={handleCloseNewEvent} refresh={handleRefreshLogs} />
                <Button
                  component="a"
                  endIcon={(
                    <PlusIcon fontSize="small" />
                  )}
                  sx={{ m: 0.5, width: "170px" }}
                  variant="outlined"
                  onClick={handleOpenNewContact}
                >
                  Add contact
                </Button>
                <ContactModal companyid={props.customerId} open={newContact} close={handleCloseNewContact} refresh={handleRefreshContacts} />
              </Grid>
            </Grid>
            <Tabs
              indicatorColor="primary"
              onChange={handleTabsChange}
              scrollButtons="auto"
              sx={{ mt: 3 }}
              textColor="primary"
              value={currentTab}
              variant="scrollable"
            >
              {tabs.map((tab) => (
                <Tab
                  key={tab.value}
                  label={tab.label}
                  value={tab.value}
                />
              ))}
            </Tabs>
          </div>
          <Divider />
          <Box sx={{ mt: 3 }}>
            {currentTab === 'contacting' && <ContactingLogs logs={logs} companyid={props.customerId} refresh={handleRefreshLogs} />}
            {currentTab === 'details' && (
              <>
                <CompanyContacts contacts={contacts} companyid={props.customerId} refresh={handleRefreshContacts} />
              </>
            )}
          </Box>
        </Container>
      </Box>
    </>
  );
};

// CustomerDetails.getLayout = (page) => (
//   <AuthGuard>
//     <DashboardLayout>
//       {page}
//     </DashboardLayout>
//   </AuthGuard>
// );

export default ContactingPage;