import React from "react";
import loaderImg from "../../assets/loader/bimroom-loader-white.svg";
import "./loader.scss";
import { Box } from "@mui/material";

const Loader = (props) => {
  const { text } = props;

  return (
    <Box
      style={{
        // position: "block",
        // zIndex: "100",
        // top: "0",
        // left: "0",
        // right: "0",
        // bottom: "0",
        width: "100%",
        height: "100%",
        //background: "#175c83",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <img src={loaderImg} alt="" width={75} height={75} />
      {text ? (
              <p className="loading">{text}</p>
            ) : (
              <p className="loading">Loading</p>
            )}
    </Box>
  );
};

export default Loader;