import { useState } from 'react';
import { 
  FieldArray,
  FormikProvider,
  useFormik
} from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
  Autocomplete,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  createFilterOptions,
  FormControl,
  InputLabel,
  Select,
  FormLabel,
  Checkbox,
  Switch,
  FormGroup,
  FormControlLabel,
  styled,
  Chip,
} from '@mui/material';
//import { etimFeatures } from '../../../data/etim';
import { Plus, Plus as PlusIcon } from '../../../icons/plus';
import { useEffect } from 'react';
import { 
  getEtimFeatures, 
  getEtimValues 
} from '../../../api';
import { dataSchema } from '../Schema/data-schema';
import { ecoLabelsList  } from '../../../data/formOptions';
import { variantData } from '../Schema/variant-data';
import { FaCross } from 'react-icons/fa';
import { AiOutlineDeleteColumn } from 'react-icons/ai';
import { X } from '../../../icons/x';
import { v4 as uuidv4 } from "uuid";
import { ParameterGroup } from '../../parameter-group';
import { CustomParameterField } from '../../custom-parameter-field';
import VariantContainer from '../Variant/VariantContainer';
import { AttributeField } from '../AttributeField';
import { OutlinedBox } from '../../outlined-box';
import { ClassificationField } from '../ClassificationField';

// const VariantField = (props) => {
//   const { formik, index, variant } = props;
  
//   return (
//     <Collapsible title={`Variant ${index + 1}`} defaultExpanded={true} />
//   )
// }

// const AttributeField = (props) => {
//   const { formik, index, attribute } = props;
//   return (
//     <Grid container spacing={1}>
//       <Grid item md={5} xs={12}>
//         <TextField
//           fullWidth
//           label="Name"
//           name={`attributes.${index}.name`}
//           value={attribute.name}
//           onChange={formik.handleChange}
//           onBlur={formik.handleBlur}
//         />
//       </Grid>
//     </Grid>
//   )
// }

const TechnicalDetailsForm = (props) => {
  const { formik } = props;

  const addVariant = () => {
    const newVariant = variantData();
    newVariant["id"] = uuidv4();
    formik.setFieldValue("variants", [...formik.values.variants, newVariant]);
  }

  const addAttribute = () => {
    formik.setFieldValue("attributes", [...formik.values.attributes, {
      type: "custom", 
      name: "", 
      label: "New variable", 
      values: [], 
      table: [], 
      range: false,  
      unit: "", 
      min: "", 
      max: ""
    }]);
  }

  const addAttributeToVariant = (index) => {
    formik.setFieldValue(`variants[${index}].attributes`, [...formik.values.variants[index].attributes, {
      type: "custom", 
      name: "", 
      label: "New variable", 
      values: [], 
      table: [], 
      range: false,  
      unit: "", 
      min: "", 
      max: ""
    }]);
  }

  const handleDeleteVariable = (index) => {
    formik.setFieldValue("attributes", formik.values.attributes.filter((_, i) => i !== index));
  }

  const handleDuplicateVariable = (index) => {
    formik.setFieldValue("attributes", [...formik.values.attributes, {...formik.values.attributes[index]}]);
  }

  const handleDeleteVariableFromVariant = (variantIndex, attributeIndex) => {
    formik.setFieldValue(`variants[${variantIndex}].attributes`, formik.values.variants[variantIndex].attributes.filter((_, i) => i !== attributeIndex));
  }

  const handleDuplicateVariableFromVariant = (variantIndex, attributeIndex) => {
    formik.setFieldValue(`variants[${variantIndex}].attributes`, [...formik.values.variants[variantIndex].attributes, {...formik.values.variants[variantIndex].attributes[attributeIndex]}]);
  }

  const addClassificationToVariant = (index) => {
    formik.setFieldValue(`variants[${index}].classification`, [...formik.values.variants[index].classification, {
      type: "custom",
      label: "New classification",
      key: "custom",
      value: ""
    }]);
  }

  const removeClassification = (variantIndex, classIndex) => {
    formik.setFieldValue(`variants[${variantIndex}].classification`, formik.values.variants[variantIndex].classification.filter((_, i) => i !== classIndex));
  }

  return (
    <>
      <Card sx={{ mt: 2 }}>
        <CardContent>
          <Grid container spacing={4} sx={{mb: 1}}>
            <Grid item md={3} xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h6">Product-scoped attributes</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">These are the product scope attributes, indicating that all attributes listed herein are shared across the product and any potential variants listed below. <br/><br/> For instance, if the length is specified as 40 cm, it denotes that the product and all of its possible variants possess a length of 40 cm. <br /><br/> We recommend using our <strong>Built-in</strong> attribute type, also known as BON, as they ensure optimal functionality of our library filters, etc. If you cannot find the attribute you're looking for, you can use the <strong>Custom</strong> attribute type and define the attribute yourself.</Typography>
                </Grid>
              </Grid>
              
            </Grid>
            <Grid item md={9} xs={12}>
              <Grid container spacing={1}>
                {formik.values.attributes.length === 0 ? (
                  <Grid item xs={12}>
                    <Typography fullWidth variant="subtitle2" sx={{height: 50, textAlign: "center"}}>
                      No attributes
                    </Typography>
                  </Grid>
                ) : (
                  <FormikProvider value={formik}>
                    <FieldArray 
                      name="attributes" 
                      render={() => (
                        formik.values.attributes.map((attribute, index) => {
                          return (
                            <AttributeField 
                              key={"attribute." + index} 
                              formik={formik}
                              attribute={attribute}
                              index={index}
                              type={`attributes[${index}].type`}
                              name={`attributes[${index}].name`}
                              label={`attributes[${index}].label`}
                              value={`attributes[${index}].value`} // REMOVE
                              values={`attributes[${index}].values`}
                              table={`attributes[${index}].table`}
                              range={`attributes[${index}].range`}
                              unit={`attributes[${index}].unit`}
                              specification={`attributes[${index}].specification`}
                              min={`attributes[${index}].min`}
                              max={`attributes[${index}].max`}
                              handleDeleteVariable={handleDeleteVariable}
                              handleDuplicateVariable={handleDuplicateVariable}
                            />
                          )
                        })
                      )}
                    />
                  </FormikProvider>
                )}
                <Button variant="contained"  fullWidth onClick={addAttribute}>Add attribute</Button>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card sx={{ mt: 2 }}>
        <CardContent>
          <Grid container spacing={4} sx={{mb: 1}}>
            <Grid item md={3} xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h6">Variants & variant-scoped attributes</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">Sometimes products include multiple variants, each with unique attributes, such as dimensions or technical details. If you think the product you're adding has variants with significant differences, you should include them here. <br/> <br/> Under each variant, you can add variant-scoped attributes, which pertain only to the specific variant in question.</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={9} xs={12}>
              <Grid container spacing={1}>
                {formik.values.variants.length === 0 ? (
                  <Grid item xs={12}>
                    <Typography fullWidth variant="subtitle2" sx={{height: 50, textAlign: "center"}}>
                      No variants
                    </Typography>
                  </Grid>
                ) : (
                  formik.values.variants.map((variant, index) => (
                    // <Grid item xs={12}>
                      <VariantContainer key={"variant." + index} formik={formik} index={index} variant={variant}>
                      {/* <OutlinedBox label={"Attributes"}> */}
                        <Typography variant="h6" sx={{mb: 1, paddingX: 1}}>Attributes</Typography>
                        { formik.values.variants[index].attributes.length === 0 ? (
                          <Typography fullWidth variant="subtitle2" sx={{height: 50, textAlign: "center"}}>
                            No variant-scoped attributes added
                          </Typography>
                        ) : (
                          <FormikProvider value={formik}>
                            <FieldArray 
                              name={"variants[" + index + "].attributes"}
                              render={() => (
                                formik.values.variants[index].attributes.map((attribute, attributeIndex) => {
                                  return (
                                    <AttributeField 
                                      key={"attribute." + index + "." + attributeIndex} 
                                      formik={formik}
                                      attribute={attribute}
                                      index={attributeIndex}
                                      type={`variants[${index}].attributes[${attributeIndex}].type`}
                                      name={`variants[${index}].attributes[${attributeIndex}].name`}
                                      label={`variants[${index}].attributes[${attributeIndex}].label`}
                                      value={`variants[${index}].attributes[${attributeIndex}].value`} // REMOVE
                                      values={`variants[${index}].attributes[${attributeIndex}].values`}
                                      table={`variants[${index}].attributes[${attributeIndex}].table`}
                                      range={`variants[${index}].attributes[${attributeIndex}].range`}
                                      unit={`variants[${index}].attributes[${attributeIndex}].unit`}
                                      specification={`variants[${index}].attributes[${attributeIndex}].specification`}
                                      min={`variants[${index}].attributes[${attributeIndex}].min`}
                                      max={`variants[${index}].attributes[${attributeIndex}].max`}
                                      handleDeleteVariable={() => handleDeleteVariableFromVariant(index, attributeIndex)}
                                      handleDuplicateVariable={() => handleDuplicateVariableFromVariant(index, attributeIndex)}
                                    />
                                  )
                                })
                              )}
                            />
                          </FormikProvider>
                        )}
                        <Button sx={{padding: 1, mb: 2}} variant="contained" fullWidth onClick={() => addAttributeToVariant(index)}>Add attribute</Button>
                      {/* </OutlinedBox> */}
                      <Typography variant="h6" sx={{mb: 1, paddingX: 1}}>Classification</Typography>
                        {formik.values.variants[index].classification.length === 0 ? (
                          <Typography fullWidth variant="subtitle2" sx={{height: 50, textAlign: "center"}}>
                            No variant-scoped classification added
                          </Typography>
                        ) : (
                          <FormikProvider value={formik}>
                            <FieldArray 
                              name={"variants[" + index + "].classification"}
                              render={() => (
                                formik.values.variants[index].classification.map((classification, classIndex) => {
                                  return (
                                  <>
                                    <ClassificationField
                                      key={"classification." + index + "." + classIndex}
                                      formik={formik}
                                      index={classIndex}
                                      classification={classification}
                                      type={`variants[${index}].classification[${classIndex}].type`}
                                      name={`variants[${index}].classification[${classIndex}].key`}
                                      label={`variants[${index}].classification[${classIndex}].label`}
                                      value={`variants[${index}].classification[${classIndex}].value`}
                                      removeClassification={() => removeClassification(index, classIndex)}
                                      sticky={false}
                                    />
                                  </>
                                  )
                                })
                              )}
                            />
                          </FormikProvider>
                        )}
                        <Button sx={{padding: 1, mb: 2}} variant="contained" fullWidth onClick={() => addClassificationToVariant(index)}>Add classication</Button>
  
                      </VariantContainer> 
                    // </Grid>
                  ))
                )}

                {/* <Grid item xs={12}> */}
                  <Button variant="outlined" fullWidth onClick={addVariant}>Add variant</Button>
                {/* </Grid> */}
                
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {/* <Button onClick={() => console.log(formik.values)}>Print values</Button> */}
    </> 
  )
}

export default TechnicalDetailsForm;
