import { useState, useEffect, useCallback } from 'react';
import { format } from 'date-fns';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  IconButton,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  TextField
} from '@mui/material';
import { getContacting, updateContacting } from '../../api';
import { useMounted } from '../../hooks/use-mounted';



export const NotConnected = (props) => {
  const { mainProfileId, connected, refresh } = props;
  const isMounted = useMounted();
  
  const [contactingProfileList, setContactingProfileList ] = useState([]);
  const [selectedContactingProfile, setSelectedContactingProfile] = useState(null);

  const handleChange = (event, value) => {
    if (value) {
      setSelectedContactingProfile(value);
    } else {
      setSelectedContactingProfile(null);
    }
  }

  const getCustomers = useCallback(async () => {
    try {
      if (!connected) {
        const data = await getContacting();

        if (isMounted()) {
          setContactingProfileList(data);
        }
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  useEffect(() => {
      getCustomers();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

  const connectToContactingProfile = async (contactingProfileId) => {
    
    if (contactingProfileId && mainProfileId) {
      const payload = {
        id: contactingProfileId,
        mainProfileId: mainProfileId
      }
      const response = await updateContacting(payload);
      if (response) {
        refresh();
      }
    }
  }

  return (
    // <Card {...props}>
    <Card>
      <CardHeader
        title="Connect to a contacting profile"
      />
      <Divider />
        <Grid container sx={{p: 2}}>
          <Grid item xs={12} sx={{my: 2, display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Typography variant="subtitle2" >Not connected to a contacting profile - connect to:</Typography>
          </Grid>
          <Grid item xs={12} sx={{mb: 2, display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Autocomplete
              value={selectedContactingProfile}
              onChange={handleChange}
              id="controllable-states-demo"
              options={contactingProfileList}
              getOptionLabel={(option) => option.companyName}
              sx={{ width: 300 }}
              isOptionEqualToValue={(option) => option.companyName}
              renderInput={(params) => <TextField {...params} label="Contacting profile" />}
            />
          </Grid>
          <Grid item xs={12} sx={{mb: 2, display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Button onClick={() => connectToContactingProfile(selectedContactingProfile?.id)} variant="contained"><Typography variant="subtitle2">Connect</Typography></Button>
          </Grid>
        </Grid> 
    </Card>
  );
};
