import React, { useEffect, useRef, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  Checkbox,
  Grid,
  FormControl,
  FormGroup,
  FormLabel,
  Link,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
  Autocomplete,
  Box,
  InputLabel,
  FormHelperText,
  Input,
  OutlinedInput
} from '@mui/material';
import { 
  categoryOptions, 
  getCategoryIndex, 
  getSubcategoryIndex,
} from '../../../data/categories';
import { 
  etimClassification
} from '../../../data/etim';
import { 
  roomOptions, 
  usageOptions, 
} from '../../../data/formOptions';
import { 
  europeList, 
  africaList, 
  antarcticaList, 
  asiaList, 
  northAmericaList, 
  southAmericaList, 
  oceaniaList, 
  countryList
} from '../../../data/countries';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FormikProvider, FastField } from 'formik';
import { ContentState, Editor, EditorState, convertFromHTML} from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { useSelector } from 'react-redux';

const languageOptions = [
  {
    value: 'en',
    label: 'English',
  },
  {
    value: 'de',
    label: 'German',
  },
  {
    value: 'fr',
    label: 'French',
  },
  {
    value: 'it',
    label: 'Italian',
  },
  {
    value: 'es',
    label: 'Spanish',
  }
];

export default function GeneralForm(props) {
  const { formik, editingMode } = props;
  const [editorState, setEditorState] = useState(
    EditorState.createEmpty()
  );
  const editor = useRef(null);
  const brandOptions = useSelector((store) => store.user.brands);

  useEffect(() => {
    // html text to editor
      const blocksFromHTML = convertFromHTML(formik.values.description);
      //console.log(blocksFromHTML);

      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      )
      setEditorState( EditorState.createWithContent(state));
  }, [editingMode])
  
  useEffect(() => {
    //console.log(convertToRaw(editorState.getCurrentContent()));
    let html = stateToHTML(editorState.getCurrentContent());
    formik.setFieldValue('description', html);
  }, [editorState])

  function showSubcategory() {
    let length = categoryOptions[getCategoryIndex(formik.values.category)].subcategory.length;
    if (length === 1) {
      formik.values.subcategory1 = '';
      //formik.setFieldValue("subcategory1", "");
      return 'none';
    } else {
      return 'block';
    }
  }

  function showSubcategory2() {
    let length = categoryOptions[getCategoryIndex(formik.values.category)].subcategory[getSubcategoryIndex(formik.values.category,formik.values.subcategory1)].subcategory.length;
    if (length === 1) {
      formik.values.subcategory2 = '';
      //formik.setFieldValue("subcategory2", "");
      return 'none';
    } else {
      return 'block';
    }
  }

  function checkSubcategory(level, value) {
    if (level === 1) {
      let indexNumber = categoryOptions[getCategoryIndex(formik.values.category)].subcategory.findIndex(obj => obj.value === value);
      if (indexNumber > 0) {
        return formik.values.subcategory1;
      } else {
        formik.values.subcategory1 = '';
        //formik.setFieldValue("subcategory1", "");
        return formik.values.subcategory1;
      }
    } else if (level === 2) {
      let indexNumber = categoryOptions[getCategoryIndex(formik.values.category)].subcategory[getSubcategoryIndex(formik.values.category,formik.values.subcategory1)].subcategory.findIndex(obj => obj.value === value);
      if (indexNumber > 0) {
        return formik.values.subcategory2;
      } else {
        formik.values.subcategory2 = '';
        //formik.setFieldValue("subcategory2", "");
        return formik.values.subcategory2;
      }
    } else {
      return value;
    }
  }

  function getColor(value) {
    if (value > 0) {
      return "neutral.100"
    } else {
      return "#fff"
    }
  }

  function getLength(value) {
    switch(value) {
      case "europe":
        return formik.values.europe.length;

      case "africa":
        return formik.values.africa.length;

      case "asia":
        return formik.values.asia.length;

      case "northAmerica":
        return formik.values.northAmerica.length;

      case "southAmerica":
        return formik.values.southAmerica.length;

      case "antarctica":
        return formik.values.antarctica.length;

      case "oceania":
        return formik.values.oceania.length;  

      default:
        return;
    } 
  }

  const CountriesSelectedText = (props) => {
    const { continent } = props;
    const length = getLength(continent);
    if (length > 1) {
      return <Typography color="neutral.900" sx={{borderRadius: 1, backgroundColor: '#fff', px: 2, mr: 2}}>{length} countries selected</Typography>;
    } else if (length === 1) {
      return <Typography color="neutral.900" sx={{borderRadius: 1, backgroundColor: '#fff', px: 2, mr: 2}}>{length} country selected</Typography>;
    } else {
      return <></>
    }
  }

  const handleBrandChange = (event) => {
    if (event.target.value) {
      formik.setFieldValue("brandName", event.target.value)
      //formik.setFieldValue("brandName", event.target.value)

      const matchedBrand = brandOptions.find(obj => {
        return obj.brandName === event.target.value
      })
      
      if (matchedBrand) {
        formik.setFieldValue("brandId", matchedBrand?.brandId)
      }
      
    }
  }

//   function makeid(length) {
//     let result = '';
//     const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
//     const charactersLength = characters.length;
//     let counter = 0;
//     while (counter < length) {
//       result += characters.charAt(Math.floor(Math.random() * charactersLength));
//       counter += 1;
//     }
//     return result;
// }


  return (
    <> 
      <Card sx={{ mt: 2 }}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={3} xs={12}>
              <Typography variant="h6">Basic details</Typography>
            </Grid>
            <Grid item md={9} xs={12}>
              <TextField
                error={Boolean(formik.touched.name && formik.errors.name)}
                fullWidth
                helperText={formik.touched.name && formik.errors.name}
                label="Product Name"
                name="name"
                size="small"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
                required
              />
              <TextField
                error={Boolean(formik.touched.manufacturerProductId && formik.errors.manufacturerProductId)}
                fullWidth
                label="Unique product id"
                name="manufacturerProductId"
                size="small"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                sx={{ mt: 1 }}
                value={formik.values.manufacturerProductId}
                required
              />
              <TextField
                error={Boolean(formik.touched.brandName && formik.errors.brandName)}
                fullWidth
                select
                label="Brand"
                name="brandName"
                size="small"
                // onBlur={formik.handleBlur}
                // onChange={formik.handleChange}
                onChange={handleBrandChange}
                sx={{ mt: 1 }}
                value={formik.values.brandName}
              >
                {brandOptions.map((option) => (
                  <MenuItem key={option.brandId} value={option.brandName}>
                    {option.brandName}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                error={Boolean(formik.touched.brandId && formik.errors.brandId)}
                fullWidth
                label="Brand ID"
                name="brandId"
                size="small"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                sx={{ mt: 1 }}
                value={formik.values.brandId}
                disabled 
              />
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(formik.touched.model && formik.errors.model)}
                    fullWidth
                    helperText={formik.touched.model && formik.errors.model}
                    label="Model name"
                    name="model"
                    size="small"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    sx={{ mt: 1 }}
                    value={formik.values.model}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    // error={Boolean(formik.touched.gtin && formik.errors.gtin)}
                    fullWidth
                    // helperText={formik.touched.gtin && formik.errors.gtin}
                    label="GTIN"
                    name="gtin"
                    size="small"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    sx={{ mt: 1 }}
                    value={formik.values.gtin}
                  />
                </Grid>
              </Grid>
              

              <TextField
                error={Boolean(formik.touched.sku && formik.errors.sku)}
                fullWidth
                disabled
                label="SKU"
                name="sku"
                size="small"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                sx={{ mt: 1 }}
                value={formik.values.sku}
              />
              <Autocomplete
                fullWidth
                disablePortal
                id="country-combo-box"
                sx={{ mt: 1 }}
                options={countryList}
                size="small"
                //sx={{ width: 300 }}
                //isOptionEqualToValue={(option, value) => option.id === value.id}
                value={formik.values.countryOfManufacture}
                onChange={(e, value) => formik.setFieldValue("countryOfManufacture", value?.label)}
                renderInput={(params) => <TextField {...params} label="Country of Manufacture"  /> }
              />
              <Typography
                color="textSecondary"
                sx={{
                  mb: 1,
                  mt: 2,
                  
                }}
                variant="subtitle2"
              >
                Description
              </Typography>
              <Grid item>
                <Editor
                  id="description-text"
                  ref={editor}
                  editorState={editorState}
                  onChange={editorState => setEditorState(editorState)}
                />
              </Grid>
              <TextField
                error={Boolean(formik.touched.descriptionLang && formik.errors.descriptionLang)}
                fullWidth
                select
                label="Description language"
                name="descriptionLang"
                size="small"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                sx={{ my: 1 }}
                value={formik.values.descriptionLang}
              >
                {languageOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card sx={{ mt: 2 }}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={3} xs={12}>
              <Typography variant="h6">Category & Classification</Typography>
            </Grid>
            <Grid item md={9} xs={12}>
              <Typography
                color="textSecondary"
                sx={{
                  mb: 1,
                  //mt: 2,
                }}
                variant="subtitle2"
              >
                  Bimroom category
              </Typography>
              <TextField
                error={Boolean(
                  formik.touched.category && formik.errors.category
                )}
                fullWidth
                label="Category"
                name="category"
                size="small"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                select
                value={formik.values.category}
                required
              >
                {categoryOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                error={Boolean(
                  formik.touched.subcategory1 && formik.errors.subcategory1
                )}
                fullWidth
                label="Subcategory 1"
                name="subcategory1"
                size="small"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                sx={{ mt: 1, display: showSubcategory() }}
                select
                value={checkSubcategory(1, formik.values.subcategory1)}
              >
                {categoryOptions[
                  getCategoryIndex(formik.values.category)
                ].subcategory.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                error={Boolean(
                  formik.touched.subcategory2 && formik.errors.subcategory2
                )}
                fullWidth
                label="Subcategory 2"
                name="subcategory2"
                size="small"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                sx={{ mt: 1, display: showSubcategory2() }}
                select
                value={checkSubcategory(2, formik.values.subcategory2)}
              >
                {categoryOptions[
                  getCategoryIndex(formik.values.category)
                ].subcategory[
                  getSubcategoryIndex(formik.values.category, formik.values.subcategory1)
                ].subcategory.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <Typography
                color="textSecondary"
                sx={{
                  mb: 1,
                  mt: 2,
                }}
                variant="subtitle2"
              >
                Usage
            </Typography>
            <FormikProvider value={formik}>
              <FormControl fullWidth>
                <FormGroup
                  row={true}
                  sx={{
                    border: "1px solid",
                    borderColor: "divider",
                    borderRadius: "7px",
                    padding: "10px",
                    display: "block"
                  }}
                >
                  {usageOptions.map((opt) => (
                    <Tooltip title={opt.label} key={opt.value}>
                      {/* <FormLabel
                        style={{
                          marginRight: "5px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "inline-block",
                          whiteSpace: "nowrap",
                          width: "24%",
                          fontSize: "14px",
                        }}
                      >
                        <FastField
                          type="checkbox"
                          component={Checkbox}
                          name="usage"
                          key={opt.value}
                          value={opt.value}
                          label={opt.label}
                          style={{ marginRight: "5px", cursor: "pointer" }}
                        />
                        {opt.label}
                      </FormLabel> */}
                      <FormLabel
                        style={{
                          marginRight: "5px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "inline-block",
                          whiteSpace: "nowrap",
                          width: "19%",
                          fontSize: "14px",
                        }}
                      >
                        <FastField
                          type="checkbox"
                          name="usage"
                          key={opt.value}
                          value={opt.value}
                          label={opt.label}
                          style={{ marginRight: "5px", cursor: "pointer" }}
                        />
                        {opt.label}
                      </FormLabel>
                    </Tooltip>
                  ))}
                </FormGroup>
              </FormControl>
            </FormikProvider>
            <FormikProvider value={formik}>
              <Typography
                color="textSecondary"
                sx={{
                  mb: 1,
                  mt: 2,
                }}
                variant="subtitle2"
              >
                Room
              </Typography>
              <FormControl fullWidth>
                <FormGroup
                  row={true}
                  sx={{
                    border: "1px solid",
                    borderColor: "divider",
                    borderRadius: "7px",
                    padding: "10px",
                    display: "block"
                  }}
                >
                  {roomOptions.map((opt) => (
                    <Tooltip title={opt.label} key={opt.value}>
                      {/* <FormLabel
                        style={{
                          marginRight: "5px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "inline-block",
                          whiteSpace: "nowrap",
                          width: "24%",
                          fontSize: "14px",
                        }}
                      >
                        <FastField
                          type="checkbox"
                          component={Checkbox}
                          name="room"
                          key={opt.value}
                          value={opt.value}
                          label={opt.label}
                          style={{ marginRight: "5px", cursor: "pointer" }}
                        />
                        {opt.label}
                      </FormLabel> */}
                      <FormLabel
                        style={{
                          marginRight: "5px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "inline-block",
                          whiteSpace: "nowrap",
                          width: "19%",
                          fontSize: "14px",
                        }}
                      >
                        <FastField
                          //className="countryField"
                          type="checkbox"
                          //component={Checkbox}
                          name="room"
                          key={opt.value}
                          value={opt.value}
                          label={opt.label}
                          style={{ marginRight: "5px", cursor: "pointer" }}
                        />
                        {opt.label}
                      </FormLabel>
                    </Tooltip>
                  ))}
                </FormGroup>
              </FormControl>
            </FormikProvider>
            <Typography
                color="textSecondary"
                sx={{
                  mb: 1,
                  mt: 2,
                }}
                variant="subtitle2"
              >
                Classification
              </Typography>
              <TextField
                // error={Boolean(formik.touched.ifcClassif && formik.errors.gtin)}
                fullWidth
                // helperText={formik.touched.gtin && formik.errors.gtin}
                label="IFC classification"
                name="classification.ifcClassification"
                size="small"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                //sx={{ mt: 1 }}
                value={formik.values.classification.ifcClassification}
              />
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TextField
                    // error={Boolean(formik.touched.uniclass2015Code && formik.errors.uniclass2015Code)}
                    fullWidth
                    // helperText={formik.touched.uniclass2015Code && formik.errors.uniclass2015Code}
                    label="Uniclass 2015 code"
                    name="classification.uniclass2015Code"
                    size="small"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    sx={{ mt: 1 }}
                    value={formik.values.classification.uniclass2015Code}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    // error={Boolean(formik.touched.uniclass2015Desc && formik.errors.uniclass2015Desc)}
                    fullWidth
                    // helperText={formik.touched.uniclass2015Desc && formik.errors.uniclass2015Desc}
                    label="Uniclass 2015 description"
                    name="classification.uniclass2015Desc"
                    size="small"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    sx={{ mt: 1 }}
                    value={formik.values.classification.uniclass2015Desc}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TextField
                    // error={Boolean(formik.touched.omniClassNumber && formik.errors.omniClassNumber)}
                    fullWidth
                    // helperText={formik.touched.omniClassNumber && formik.errors.omniClassNumber}
                    label="Omniclass number"
                    name="classification.omniClassNumber"
                    size="small"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    sx={{ mt: 1 }}
                    value={formik.values.classification.omniClassNumber}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    // error={Boolean(formik.touched.omniClassTitle && formik.errors.omniClassTitle)}
                    fullWidth
                    // helperText={formik.touched.omniClassTitle && formik.errors.omniClassTitle}
                    label="Omniclass title"
                    name="classification.omniClassTitle"
                    size="small"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    sx={{ mt: 1 }}
                    value={formik.values.classification.omniClassTitle}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TextField
                    // error={Boolean(formik.touched.uniclass20Code && formik.errors.uniclass20Code)}
                    fullWidth
                    // helperText={formik.touched.uniclass20Code && formik.errors.uniclass20Code}
                    label="Uniclass 20 code"
                    name="classification.uniclass20Code"
                    size="small"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    sx={{ mt: 1 }}
                    value={formik.values.classification.uniclass20Code}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    // error={Boolean(formik.touched.uniclass20Desc && formik.errors.uniclass20Desc)}
                    fullWidth
                    // helperText={formik.touched.uniclass20Desc && formik.errors.uniclass20Desc}
                    label="Uniclass 20 title"
                    name="classification.uniclass20Desc"
                    size="small"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    sx={{ mt: 1 }}
                    value={formik.values.classification.uniclass20Desc}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TextField
                    // error={Boolean(formik.touched.nbsReferenceCode && formik.errors.nbsReferenceCode)}
                    fullWidth
                    // helperText={formik.touched.nbsReferenceCode && formik.errors.nbsReferenceCode}
                    label="NBS Reference code"
                    name="classification.nbsReferenceCode"
                    size="small"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    sx={{ mt: 1 }}
                    value={formik.values.classification.nbsReferenceCode}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    // error={Boolean(formik.touched.nbsReferenceDesc && formik.errors.nbsReferenceDesc)}
                    fullWidth
                    // helperText={formik.touched.nbsReferenceDesc && formik.errors.nbsReferenceDesc}
                    label="NBS Reference description"
                    name="classification.nbsReferenceDesc"
                    size="small"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    sx={{ mt: 1 }}
                    value={formik.values.classification.nbsReferenceDesc}
                  />
                </Grid>
              </Grid>
              <Typography
                color="textSecondary"
                sx={{
                  mb: 1,
                  mt: 2,
                }}
                variant="subtitle2"
              >
                  ETIM
              </Typography>
              <Autocomplete
                // disablePortal
                name="etim-category"
                options={etimClassification}
                fullWidth
                size="small"
                sx={{mt: 1}}
                //isOptionEqualToValue={(option, value) => option.id === value.id}
                value={formik.values.etimClassification}
                onChange={(e, value) => formik.setFieldValue("etimClassification", value)}
                renderInput={(params) => <TextField {...params} label="ETIM ART CLASS" />}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card sx={{ mt: 2 }}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={3} xs={12}>
              <Typography variant="h6">Market Area</Typography>
              <Typography color="textSecondary" variant="body2" sx={{ mt: 1 }}>
                Product availability
              </Typography>
            </Grid>
            <Grid item md={9} xs={12}>
              <Accordion sx={{backgroundColor: getColor(getLength("europe"))}}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={{ justifyContent: 'space-between' }}
                >
                  <Typography color="neutral.900">Europe</Typography>
                  <CountriesSelectedText continent="europe" />
                </AccordionSummary>
                <AccordionDetails>
                  <FormikProvider value={formik}>
                    <FormControl
                      component="fieldset"
                      style={{ display: "flex", marginBottom: "15px" }}
                    >

                      <FormGroup
                        row={true}
                        sx={{mb: 1}}
                      >
                        <Link
                          underline="none"
                          style={{
                            color: "neutral.400",
                            fontSize: "14px",
                            marginRight: "10px",
                            cursor: "pointer",
                          }}
                        >
                          <span
                            onClick={() =>
                              formik.setFieldValue(
                                "europe",
                                europeList.map((opt) => opt.value)
                              )
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            Select All
                          </span>
                        </Link>
                        <Link
                          underline="none"
                          style={{
                            color: "neutral.400",
                            fontSize: "14px",
                            marginRight: "10px",
                            cursor: "pointer",
                          }}
                        >
                          <span
                            onClick={() => formik.setFieldValue("europe", [])}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            Unselect All
                          </span>
                        </Link>
                      </FormGroup>
                      <FormGroup
                        row={true}
                        sx={{
                          border: "1px solid",
                          borderColor: "divider",
                          borderRadius: "7px",
                          padding: "10px",
                          backgroundColor: '#fff'
                          //display: showCountries(formik.values.availableEurope),
                        }}
                      >
                        {europeList.map((opt) => (
                          <Tooltip title={opt.label} key={opt.value}>
                            <FormLabel
                              style={{
                                marginRight: "5px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "inline-block",
                                whiteSpace: "nowrap",
                                width: "19%",
                                fontSize: "14px",
                              }}
                            >
                              <FastField
                                className="countryField"
                                type="checkbox"
                                // component={Checkbox}
                                name="europe"
                                key={opt.value}
                                value={opt.value}
                                label={opt.label}
                                style={{ transform: "scale(0.8)", cursor: "pointer" }}
                              />
                              {opt.label}
                            </FormLabel>
                          </Tooltip>
                        ))}
                      </FormGroup>
                    </FormControl>
                  </FormikProvider>
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{backgroundColor: getColor(getLength("africa"))}}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography color="neutral.900">Africa</Typography>
                  <CountriesSelectedText continent="africa" />
                </AccordionSummary>
                <AccordionDetails>
                  <FormikProvider value={formik}>
                    <FormControl
                      component="fieldset"
                      style={{ display: "flex", marginBottom: "15px" }}
                    >
                      <FormGroup
                        row={true}
                        sx={{mb: 1}}
                      >
                        <Link
                          underline="none"
                          style={{
                            color: "neutral.400",
                            fontSize: "14px",
                            marginRight: "10px",
                            cursor: "pointer",
                          }}
                        >
                          <span
                            onClick={() =>
                              formik.setFieldValue(
                                "africa",
                                africaList.map((opt) => opt.value)
                              )
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            Select All
                          </span>
                        </Link>
                        <Link
                          underline="none"
                          style={{
                            color: "neutral.400",
                            fontSize: "14px",
                            marginRight: "10px",
                            cursor: "pointer",
                          }}
                        >
                          <span
                            onClick={() => formik.setFieldValue("africa", [])}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            Unselect All
                          </span>
                        </Link>
                      </FormGroup>
                      <FormGroup
                        row={true}
                        sx={{
                          border: "1px solid",
                          borderColor: "divider",
                          borderRadius: "7px",
                          padding: "10px",
                          backgroundColor: '#fff'
                         // display: showCountries(formik.values.availableAfrica),
                        }}
                      >
                        {africaList.map((opt) => (
                          <Tooltip title={opt.label} key={opt.value}>
                            <FormLabel
                              style={{
                                marginRight: "5px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "inline-block",
                                whiteSpace: "nowrap",
                                width: "19%",
                                fontSize: "14px",
                              }}
                            >
                              <FastField
                                className="countryField"
                                type="checkbox"
                                //component={Checkbox}
                                name="africa"
                                key={opt.value}
                                value={opt.value}
                                label={opt.label}
                                style={{ marginRight: "5px", cursor: "pointer" }}
                              />
                              {opt.label}
                            </FormLabel>
                          </Tooltip>
                        ))}
                      </FormGroup>
                    </FormControl>
                  </FormikProvider>
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{backgroundColor: getColor(getLength("asia"))}}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Typography color="neutral.900">Asia</Typography>
                  <CountriesSelectedText continent="asia" />
                </AccordionSummary>
                <AccordionDetails>
                  <FormikProvider value={formik}>
                    <FormControl
                      component="fieldset"
                      style={{ display: "flex", marginBottom: "15px" }}
                    >
                      <FormGroup
                        row={true}
                        sx={{ mb: 1 }}
                      >
                        <Link
                          underline="none"
                          style={{
                            color: "neutral.400",
                            fontSize: "14px",
                            marginRight: "10px",
                            cursor: "pointer",
                          }}
                        >
                          <span
                            onClick={() =>
                              formik.setFieldValue(
                                "asia",
                                asiaList.map((opt) => opt.value)
                              )
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            Select All
                          </span>
                        </Link>
                        <Link
                          underline="none"
                          style={{
                            color: "neutral.400",
                            fontSize: "14px",
                            marginRight: "10px",
                            cursor: "pointer",
                          }}
                        >
                          <span
                            onClick={() => formik.setFieldValue("asia", [])}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            Unselect All
                          </span>
                        </Link>
                      </FormGroup>
                      <FormGroup
                        row={true}
                        sx={{
                          border: "1px solid",
                          borderColor: "divider",
                          borderRadius: "7px",
                          padding: "10px",
                        //  display: showCountries(formik.values.availableAsia),
                          backgroundColor: '#fff'
                        }}
                      >
                        {asiaList.map((opt) => (
                          <Tooltip title={opt.label} key={opt.value}>
                            <FormLabel
                              style={{
                                marginRight: "5px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "inline-block",
                                whiteSpace: "nowrap",
                                width: "19%",
                                fontSize: "14px",
                              }}
                            >
                              <FastField
                                className="countryField"
                                type="checkbox"
                                //component={Checkbox}
                                name="asia"
                                key={opt.value}
                                value={opt.value}
                                label={opt.label}
                                style={{ marginRight: "5px", cursor: "pointer" }}
                              />
                              {opt.label}
                            </FormLabel>
                          </Tooltip>
                        ))}
                      </FormGroup>
                    </FormControl>
                  </FormikProvider>
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{backgroundColor: getColor(getLength("northAmerica"))}}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4a-content"
                  id="panel4a-header"
                >
                  <Typography color="neutral.900">North America</Typography>
                  <CountriesSelectedText continent="northAmerica" />
                </AccordionSummary>
                <AccordionDetails>
                  <FormikProvider value={formik}>
                    <FormControl
                      component="fieldset"
                      style={{ display: "flex", marginBottom: "15px" }}
                    >
                      <FormGroup
                        row={true}
                        sx={{
                            mb: 1
                        }}
                      >
                        <Link
                          underline="none"
                          style={{
                            color: "neutral.400",
                            fontSize: "14px",
                            marginRight: "10px",
                            cursor: "pointer",
                          }}
                        >
                          <span
                            onClick={() =>
                              formik.setFieldValue(
                                "northAmerica",
                                northAmericaList.map((opt) => opt.value)
                              )
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            Select All
                          </span>
                        </Link>
                        <Link
                          underline="none"
                          style={{
                            color: "neutral.400",
                            fontSize: "14px",
                            marginRight: "10px",
                            cursor: "pointer",
                          }}
                        >
                          <span
                            onClick={() => formik.setFieldValue("northAmerica", [])}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            Unselect All
                          </span>
                        </Link>
                      </FormGroup>
                      <FormGroup
                        row={true}
                        sx={{
                          border: "1px solid",
                          borderColor: "divider",
                          borderRadius: "7px",
                          padding: "10px",
                          backgroundColor: '#fff'
                        }}
                      >
                        {northAmericaList.map((opt) => (
                          <Tooltip title={opt.label} key={opt.value}>
                            <FormLabel
                              style={{
                                marginRight: "5px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "inline-block",
                                whiteSpace: "nowrap",
                                width: "19%",
                                fontSize: "14px",
                              }}
                            >
                              <FastField
                                className="countryField"
                                type="checkbox"
                                //component={Checkbox}
                                name="northAmerica"
                                key={opt.value}
                                value={opt.value}
                                label={opt.label}
                                style={{ marginRight: "5px", cursor: "pointer" }}
                              />
                              {opt.label}
                            </FormLabel>
                          </Tooltip>
                        ))}
                      </FormGroup>
                    </FormControl>
                  </FormikProvider>
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{backgroundColor: getColor(getLength("southAmerica"))}}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel5a-content"
                  id="panel5a-header"
                >
                  <Typography color="neutral.900">South America</Typography>
                  <CountriesSelectedText continent="southAmerica" />
                </AccordionSummary>
                <AccordionDetails>
                  <FormikProvider value={formik}>
                    <FormControl
                      component="fieldset"
                      style={{ display: "flex", marginBottom: "15px" }}
                    >
                      <FormGroup
                        row={true}
                        sx={{
                          mb: 1
                        }}
                      >
                        <Link
                          underline="none"
                          style={{
                            color: "neutral.400",
                            fontSize: "14px",
                            marginRight: "10px",
                            cursor: "pointer",
                          }}
                        >
                          <span
                            onClick={() =>
                              formik.setFieldValue(
                                "southAmerica",
                                southAmericaList.map((opt) => opt.value)
                              )
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            Select All
                          </span>
                        </Link>
                        <Link
                          underline="none"
                          style={{
                            color: "neutral.400",
                            fontSize: "14px",
                            marginRight: "10px",
                            cursor: "pointer",
                          }}
                        >
                          <span
                            onClick={() => formik.setFieldValue("southAmerica", [])}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            Unselect All
                          </span>
                        </Link>
                      </FormGroup>
                      <FormGroup
                        row={true}
                        sx={{
                          border: "1px solid",
                          borderColor: "divider",
                          borderRadius: "7px",
                          padding: "10px",
                          backgroundColor: '#fff'
                        }}
                      >
                        {southAmericaList.map((opt) => (
                          <Tooltip title={opt.label} key={opt.value}>
                            <FormLabel
                              style={{
                                marginRight: "5px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "inline-block",
                                whiteSpace: "nowrap",
                                width: "19%",
                                fontSize: "14px",
                              }}
                            >
                              <FastField
                                className="countryField"
                                type="checkbox"
                               // component={Checkbox}
                                name="southAmerica"
                                key={opt.value}
                                value={opt.value}
                                label={opt.label}
                                style={{ marginRight: "5px", cursor: "pointer" }}
                              />
                              {opt.label}
                            </FormLabel>
                          </Tooltip>
                        ))}
                      </FormGroup>
                    </FormControl>
                  </FormikProvider>
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{backgroundColor: getColor(getLength("oceania"))}}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel6a-content"
                  id="panel6a-header"
                >
                  <Typography color="neutral.900">Oceania</Typography>
                  <CountriesSelectedText continent="oceania" />
                </AccordionSummary>
                <AccordionDetails>
                  <FormikProvider value={formik}>
                    <FormControl
                      component="fieldset"
                      style={{ display: "flex", marginBottom: "15px" }}
                    >
                      <FormGroup
                        row={true}
                        sx={{
                          mb: 1
                        }}
                      >
                        <Link
                          underline="none"
                          style={{
                            color: "neutral.400",
                            fontSize: "14px",
                            marginRight: "10px",
                            cursor: "pointer",
                          }}
                        >
                          <span
                            onClick={() =>
                              formik.setFieldValue(
                                "oceania",
                                oceaniaList.map((opt) => opt.value)
                              )
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            Select All
                          </span>
                        </Link>
                        <Link
                          underline="none"
                          style={{
                            color: "neutral.400",
                            fontSize: "14px",
                            marginRight: "10px",
                            cursor: "pointer",
                          }}
                        >
                          <span
                            onClick={() => formik.setFieldValue("oceania", [])}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            Unselect All
                          </span>
                        </Link>
                      </FormGroup>
                      <FormGroup
                        row={true}
                        sx={{
                          border: "1px solid",
                          borderColor: "divider",
                          borderRadius: "7px",
                          padding: "10px",
                         // display: showCountries(formik.values.availableOceania),
                          backgroundColor: '#fff'
                        }}
                      >
                        {oceaniaList.map((opt) => (
                          <Tooltip title={opt.label} key={opt.value}>
                            <FormLabel
                              style={{
                                marginRight: "5px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "inline-block",
                                whiteSpace: "nowrap",
                                width: "19%",
                                fontSize: "14px",
                              }}
                            >
                              <FastField
                                className="countryField"
                                type="checkbox"
                               // component={Checkbox}
                                name="oceania"
                                key={opt.value}
                                value={opt.value}
                                label={opt.label}
                                style={{ marginRight: "5px", cursor: "pointer" }}
                              />
                              {opt.label}
                            </FormLabel>
                          </Tooltip>
                        ))}
                      </FormGroup>
                    </FormControl>
                  </FormikProvider>
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{backgroundColor: getColor(getLength("antarctica"))}}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel6a-content"
                  id="panel6a-header"
                >
                  <Typography color="neutral.900">Antarctica</Typography>
                  <CountriesSelectedText continent="antarctica" />
                </AccordionSummary>
                <AccordionDetails>
                  <FormikProvider value={formik}>
                    <FormControl
                      component="fieldset"
                      style={{ display: "flex", marginBottom: "15px" }}
                    >
                      <FormGroup
                        row={true}
                        sx={{
                          mb: 1,
                        }}
                      >
                        <Link
                          underline="none"
                          style={{
                            color: "neutral.400",
                            fontSize: "14px",
                            marginRight: "10px",
                            cursor: "pointer",
                          }}
                        >
                          <span
                            onClick={() =>
                              formik.setFieldValue(
                                "antarctica",
                                antarcticaList.map((opt) => opt.value)
                              )
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            Select All
                          </span>
                        </Link>
                        <Link
                          underline="none"
                          style={{
                            color: "neutral.400",
                            fontSize: "14px",
                            marginRight: "10px",
                            cursor: "pointer",
                          }}
                        >
                          <span
                            onClick={() => formik.setFieldValue("antarctica", [])}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            Unselect All
                          </span>
                        </Link>
                      </FormGroup>
                      <FormGroup
                        row={true}
                        sx={{
                          border: "1px solid",
                          borderColor: "divider",
                          borderRadius: "7px",
                          padding: "10px",
                          // display: showCountries(formik.values.availableAntarctica),
                          backgroundColor: '#fff'
                        }}
                      >
                        {antarcticaList.map((opt) => (
                          <Tooltip title={opt.label} key={opt.value}>
                            <FormLabel
                              style={{
                                marginRight: "5px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "inline-block",
                                whiteSpace: "nowrap",
                                width: "19%",
                                fontSize: "14px",
                              }}
                            >
                              <FastField
                                className="countryField"
                                type="checkbox"
                                //component={Checkbox}
                                name="antarctica"
                                key={opt.value}
                                value={opt.value}
                                label={opt.label}
                                style={{ marginRight: "5px", cursor: "pointer" }}
                              />
                              {opt.label}
                            </FormLabel>
                          </Tooltip>
                        ))}
                      </FormGroup>
                    </FormControl>
                  </FormikProvider>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>  
  );
}
