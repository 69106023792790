import React, { useEffect, useState } from "react";
import { useSearchParams} from 'react-router-dom';

// components
import ProductForm from "../components/ProductForm/ProductForm";

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

import { DashboardLayout } from "../components/dashboard-layout";
import { useSelector } from "react-redux";
import UnauthenticatedAccess from "../components/UnauthenticatedAccess/UnauthenticatedAccess";
// import useDocumentTitle from "../useDocumentTitle";

const ProductFormPage = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [productId, setProductId] = useState("");
  const [mode, setMode] = useState("new");
  //useDocumentTitle('Bimroom CRM - Product form');

  useEffect(() => {
    const isModeSet = searchParams.get("mode");
    const isProductIdSet = searchParams.get("id");

    if (isModeSet) {
      setMode(isModeSet);
      setProductId(isProductIdSet);
    }

  }, [mode])

  return (
    <>
      <AuthenticatedTemplate>
        <DashboardLayout>
          <ProductForm /> 
        </DashboardLayout>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <UnauthenticatedAccess />
      </UnauthenticatedTemplate>
    </>
  );
};

// Home.getLayout = (page) => (
//   <DashboardLayout>
//     {page}
//   </DashboardLayout>
// );

export default ProductFormPage;