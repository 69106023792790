 const SERVER_URL = "https://crm.bimform.ch";
 //const SERVER_URL = "http://localhost:7071";
 const UPLOAD_URL = "https://bimformcrmapi.azurewebsites.net";


export const API = {

  // portal control
  addPortalBrand: SERVER_URL + "/api/add-portal-brand",
  addPortalUser: SERVER_URL + "/api/add-portal-user",
  addPortalDocument: SERVER_URL + "/api/add-portal-document",
  addPortalProfile: SERVER_URL + "/api/add-portal-profile",
  invitePortalUser: SERVER_URL + "/api/invite-portal-user",
  addPortalSubscription: SERVER_URL + "/api/add-portal-subscription",
  getMainProfiles: SERVER_URL + "/api/get-main-profiles",
  getMainProfile: SERVER_URL + "/api/get-main-profile",
  getBrand: SERVER_URL + "/api/get-brand",
  getBrands: SERVER_URL + "/api/get-brands",
  getAllBrands: SERVER_URL + "/api/get-all-brands",
  getBrandProducts: SERVER_URL + "/api/get-brand-products",
  getDocuments: SERVER_URL + "/api/get-documents",

  portalProduct: SERVER_URL + "/api/portal-product",
  portalProductV2: SERVER_URL + "/api/portal-product-v2",

  // contacting
  addCompany: SERVER_URL + "/api/contacting/post-contacting",
  getContacting: SERVER_URL + "/api/contacting/get-contacting",
  updateContacting: SERVER_URL + "/api/contacting/update-contacting",
  getSingleProfile: SERVER_URL + "/api/contacting/get-single-profile",
  getContactingId: SERVER_URL + "/api/contacting/get-contacting-id",
  
  /// contacting logs and contacts
  getContactingLog: SERVER_URL + "/api/contacting/get-contacting-log",
  postContactingLog: SERVER_URL + "/api/contacting/post-contacting-log",
  updateContactingLog: SERVER_URL + "/api/contacting/update-contacting-log",
  getContacts: SERVER_URL + "/api/contacting/get-contacts",
  postContact: SERVER_URL + "/api/contacting/post-contact",
  updateContact: SERVER_URL + "/api/contacting/update-contact",
  removeDefaults: SERVER_URL + "/api/contacting/remove-defaults",

  // demo accounts
  addDemo: SERVER_URL + "/api/post-demo-account",
  getDemos: SERVER_URL + "/api/get-demo-accounts",
  updateDemo: SERVER_URL + "/api/update-demo-account",
  getSingleDemo: SERVER_URL + "/api/get-single-demo",

  // crm demo products
  addDemoProduct: SERVER_URL + "/api/post-demo-product",
  getDemoProducts: SERVER_URL + "/api/get-demo-products",

  // demo products
  getDemoList: SERVER_URL + "/api/get-demo-list",
  //getDemoProducts: SERVER_URL + "/api/get-demo-products",
  getSingleDemoProduct: SERVER_URL + "/api/get-single-demo-product",
  getDemoBrands: SERVER_URL + "/api/get-demo-brands",
  newDemoProduct: SERVER_URL + "/api/new-demo-product",
  updateDemoProduct: SERVER_URL + "/api/update-demo-product",
  addDemoBrand: SERVER_URL + "/api/add-demo-brand",

  // products
  products: SERVER_URL + "/api/products",
  external: SERVER_URL + "/api/external",
  updateProduct: SERVER_URL + "/api/update-product",
  addProduct: SERVER_URL + "/api/add-product",

  // algolia
  saveToIndex: SERVER_URL + "/api/save-to-index",
  updateIndex: SERVER_URL + "/api/update-index",

  // contracts
  addContract: SERVER_URL + "/api/post-contract",
  getContracts: SERVER_URL + "/api/get-contracts",
  updateContract: SERVER_URL + "/api/update-contract",
  getSingleContract: SERVER_URL + "/api/get-single-contract",
  signContract: SERVER_URL + "/api/sign-contract",

  // clients and clients' products
  getClients: SERVER_URL + "/api/clients/get-clients",
  getSingleClient: SERVER_URL + "/api/clients/get-single-client",
  postClient: SERVER_URL + "/api/clients/post-client",
  updateClient: SERVER_URL + "/api/clients/update-client",
  getProducts: SERVER_URL + "/api/clients/get-products",
  getClientProducts: SERVER_URL + "/api/clients/get-client-products",
  getClientUsers: SERVER_URL + "/api/clients/get-client-users",
  getClientContracts: SERVER_URL + "/api/clients/get-client-contracts",


  // etim

  getEtimFeatures: SERVER_URL + "/api/get-etim-features",
  getEtimValues: SERVER_URL + "/api/get-etim-values",
  
  // subscriptions


  // file upload
  //upload: SERVER_URL + "/api/upload",
  upload: UPLOAD_URL + "/api/upload",
  uploadAsWebp: SERVER_URL + "/api/upload-to-webp",
};
