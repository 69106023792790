const items = [
    {
        name: "soundReductionIndex",
        label: "Sound reduction index",
        type: "Number",
        standardOptions: [
            "ISO 16283"
        ],
        unitOptions: [
            "dB"
        ],
        unitSystem: "default",
        menuOptions: [],
        minMaxOption: true,
        categories: [
            "doors",
            "windows",
            "construction",
            "hvac"
        ],
        parameterClass: "Acoustic",
        etimEquivalent: [
            "EF017317",
            "EF017405"
        ],
        description: "Level of sound insulation provided by the product."
    },
    {
        name: "colour",
        label: "Colour",
        type: "String",
        standardOptions: [],
        unitOptions: [],
        unitSystem: "default",
        menuOptions: [],
        minMaxOption: false,
        categories: [],
        parameterClass: "Appearance",
        etimEquivalent: [
            "EF000007",
            "EF004269",
            "EF007168"
        ],
        description: "Appearance colour(s) of the product."
    },
    {
        name: "certificates",
        label: "Certificates",
        type: "String",
        standardOptions: [],
        unitOptions: [],
        unitSystem: "default",
        menuOptions: [],
        minMaxOption: false,
        categories: [],
        parameterClass: "Approvals",
        etimEquivalent: [
            "EF010077",
            "EF010188"
        ],
        description: "Certificates of performance and approval received by the product."
    },
    {
        name: "testing",
        label: "Testing",
        type: "String",
        standardOptions: [
            "CE"
        ],
        unitOptions: [],
        unitSystem: "default",
        menuOptions: [],
        minMaxOption: false,
        categories: [],
        parameterClass: "Approvals",
        etimEquivalent: [
            "EF017100",
            "EF017101",
            "EF017102",
            "EF017103",
            "EF017104",
            "EF017105",
            "EF017109",
            "EF017768",
            "EF017769",
            "EF017770",
            "EF017771",
            "EF017772",
            "EF017773",
            "EFDE0067",
            "EFDE0068",
            "EFDE0069",
            "EFDE0070",
            "EFDE0071",
            "EFDE0099",
            "EF013102",
            "EF014931",
            "EF014932",
            "EF014933",
            "EF015548",
            "EF016858",
            "EF016985",
            "EF018138",
            "EFDE0001",
            "EFDE0007",
            "EFDE0055",
            "EFDE0075",
            "EFDE0076"
        ],
        description: "Proof of testing, such as the CE marking."
    },
    {
        name: "numberOfLeafs",
        label: "Number of Leafs",
        type: "Integer",
        standardOptions: [],
        unitOptions: [],
        unitSystem: "default",
        menuOptions: [],
        minMaxOption: true,
        categories: [
            "doors"
        ],
        parameterClass: "Construction",
        etimEquivalent: "EF013067",
        description: ""
    },
    {
        name: "muntins",
        label: "Muntins",
        type: "Menu",
        standardOptions: [],
        unitOptions: [],
        unitSystem: "default",
        menuOptions: [
            "Yes",
            "No",
            "Optional"
        ],
        minMaxOption: false,
        categories: [
            "doors",
            "windows"
        ],
        parameterClass: "Construction",
        etimEquivalent: [],
        description: ""
    },
    {
        name: "verticalMuntinsMax",
        label: "Vertical muntins max",
        type: "Integer",
        standardOptions: [],
        unitOptions: [],
        unitSystem: "default",
        menuOptions: [],
        minMaxOption: false,
        categories: [
            "doors",
            "windows"
        ],
        parameterClass: "Construction",
        etimEquivalent: [],
        description: ""
    },
    {
        name: "horizontalMuntinsMax",
        label: "Horizontal muntins max",
        type: "Integer",
        standardOptions: [],
        unitOptions: [],
        unitSystem: "default",
        menuOptions: [],
        minMaxOption: false,
        categories: [
            "doors",
            "windows"
        ],
        parameterClass: "Construction",
        etimEquivalent: [],
        description: ""
    },
    {
        name: "numberOfSeats",
        label: "Number of seats",
        type: "Integer",
        standardOptions: [],
        unitOptions: [],
        unitSystem: "default",
        menuOptions: [],
        minMaxOption: true,
        categories: [
            "furniture"
        ],
        parameterClass: "Construction",
        etimEquivalent: "EF024887",
        description: ""
    },
    {
        name: "extendable",
        label: "Extendable",
        type: "Menu",
        standardOptions: [],
        unitOptions: [],
        unitSystem: "default",
        menuOptions: [
            "Yes",
            "No"
        ],
        minMaxOption: false,
        categories: [
            "furniture"
        ],
        parameterClass: "Construction",
        etimEquivalent: "EF000329",
        description: ""
    },
    {
        name: "foldable",
        label: "Foldable",
        type: "Menu",
        standardOptions: [],
        unitOptions: [],
        unitSystem: "default",
        menuOptions: [
            "Yes",
            "No"
        ],
        minMaxOption: false,
        categories: [
            "furniture"
        ],
        parameterClass: "Construction",
        etimEquivalent: "EF000643",
        description: ""
    },
    {
        name: "stackable",
        label: "Stackable",
        type: "Menu",
        standardOptions: [],
        unitOptions: [],
        unitSystem: "default",
        menuOptions: [
            "Yes",
            "No"
        ],
        minMaxOption: false,
        categories: [
            "furniture"
        ],
        parameterClass: "Construction",
        etimEquivalent: [],
        description: ""
    },
    {
        name: "weatherproof",
        label: "Weatherproof",
        type: "Menu",
        standardOptions: [],
        unitOptions: [],
        unitSystem: "default",
        menuOptions: [
            "Yes",
            "No"
        ],
        minMaxOption: false,
        categories: [
            "furniture"
        ],
        parameterClass: "Construction",
        etimEquivalent: [],
        description: ""
    },
    {
        name: "numberOfSashes",
        label: "Number of sashes",
        type: "Integer",
        standardOptions: [],
        unitOptions: [],
        unitSystem: "default",
        menuOptions: [],
        minMaxOption: true,
        categories: [
            "windows"
        ],
        parameterClass: "Construction",
        etimEquivalent: [],
        description: ""
    },
    {
        name: "depthWithLidFullyOpen",
        label: "Depth with lid fully open",
        type: "Number",
        standardOptions: [],
        unitOptions: [
            "mm",
            "cm"
        ],
        unitSystem: "metric",
        menuOptions: [],
        minMaxOption: true,
        categories: [
            "electronics"
        ],
        parameterClass: "Dimensions",
        etimEquivalent: [],
        description: ""
    },
    {
        name: "daylightWidth",
        label: "Daylight Width",
        type: "Number",
        standardOptions: [],
        unitOptions: [
            "mm",
            "cm"
        ],
        unitSystem: "metric",
        menuOptions: [],
        minMaxOption: true,
        categories: [
            "doors",
            "windows"
        ],
        parameterClass: "Dimensions",
        etimEquivalent: [],
        description: "Width inside the frame."
    },
    {
        name: "daylightHeight",
        label: "Daylight Height",
        type: "Number",
        standardOptions: [],
        unitOptions: [
            "mm",
            "cm"
        ],
        unitSystem: "metric",
        menuOptions: [],
        minMaxOption: true,
        categories: [
            "doors",
            "windows"
        ],
        parameterClass: "Dimensions",
        etimEquivalent: [],
        description: "Height inside the frame."
    },
    {
        name: "frameWidth",
        label: "Frame Width",
        type: "Number",
        standardOptions: [],
        unitOptions: [
            "mm",
            "cm",
            "m"
        ],
        unitSystem: "metric",
        menuOptions: [],
        minMaxOption: true,
        categories: [
            "doors",
            "windows"
        ],
        parameterClass: "Dimensions",
        etimEquivalent: "EF011817",
        description: ""
    },
    {
        name: "frameHeight",
        label: "Frame Height",
        type: "Number",
        standardOptions: [],
        unitOptions: [
            "mm",
            "cm",
            "m"
        ],
        unitSystem: "metric",
        menuOptions: [],
        minMaxOption: true,
        categories: [
            "doors",
            "windows"
        ],
        parameterClass: "Dimensions",
        etimEquivalent: "EF011818",
        description: ""
    },
    {
        name: "leafThickness",
        label: "Leaf Thickness",
        type: "Number",
        standardOptions: [],
        unitOptions: [
            "mm",
            "cm"
        ],
        unitSystem: "metric",
        menuOptions: [],
        minMaxOption: true,
        categories: [
            "doors"
        ],
        parameterClass: "Dimensions",
        etimEquivalent: "EF011814",
        description: ""
    },
    {
        name: "depthWithLidFullyOpenImp",
        label: "Depth with lid fully open",
        type: "String",
        standardOptions: [],
        unitOptions: [
            "in",
            "ft"
        ],
        unitSystem: "imperial",
        menuOptions: [],
        minMaxOption: true,
        categories: [
            "electronics"
        ],
        parameterClass: "Dimensions",
        etimEquivalent: [],
        description: ""
    },
    {
        name: "daylightWidthImp",
        label: "Daylight Width",
        type: "String",
        standardOptions: [],
        unitOptions: [
            "in",
            "ft"
        ],
        unitSystem: "imperial",
        menuOptions: [],
        minMaxOption: true,
        categories: [
            "doors",
            "windows"
        ],
        parameterClass: "Dimensions",
        etimEquivalent: [],
        description: "Width inside the frame."
    },
    {
        name: "daylightHeightImp",
        label: "Daylight Height",
        type: "String",
        standardOptions: [],
        unitOptions: [
            "in",
            "ft"
        ],
        unitSystem: "imperial",
        menuOptions: [],
        minMaxOption: true,
        categories: [
            "doors",
            "windows"
        ],
        parameterClass: "Dimensions",
        etimEquivalent: [],
        description: "Height inside the frame."
    },
    {
        name: "frameWidthImp",
        label: "Frame Width",
        type: "String",
        standardOptions: [],
        unitOptions: [
            "in",
            "ft"
        ],
        unitSystem: "imperial",
        menuOptions: [],
        minMaxOption: true,
        categories: [
            "doors",
            "windows"
        ],
        parameterClass: "Dimensions",
        etimEquivalent: "EF011817",
        description: ""
    },
    {
        name: "frameHeightImp",
        label: "Frame Height",
        type: "String",
        standardOptions: [],
        unitOptions: [
            "in",
            "ft"
        ],
        unitSystem: "imperial",
        menuOptions: [],
        minMaxOption: true,
        categories: [
            "doors",
            "windows"
        ],
        parameterClass: "Dimensions",
        etimEquivalent: "EF011818",
        description: ""
    },
    {
        name: "leafThicknessImp",
        label: "Leaf Thickness",
        type: "String",
        standardOptions: [],
        unitOptions: [
            "in"
        ],
        unitSystem: "imperial",
        menuOptions: [],
        minMaxOption: true,
        categories: [
            "doors"
        ],
        parameterClass: "Dimensions",
        etimEquivalent: "EF011814",
        description: ""
    },
    {
        name: "nominalSizeDn",
        label: "Nominal size (DN)",
        type: "Number",
        standardOptions: [
            "ISO 6708"
        ],
        unitOptions: [
            "mm"
        ],
        unitSystem: "metric",
        menuOptions: [],
        minMaxOption: false,
        categories: [
            "hvac",
            "plumbing"
        ],
        parameterClass: "Dimensions",
        etimEquivalent: [],
        description: ""
    },
    {
        name: "nominalSizeNps",
        label: "Nominal size (NPS)",
        type: "Fraction",
        standardOptions: [],
        unitOptions: [
            "inches"
        ],
        unitSystem: "imperial",
        menuOptions: [],
        minMaxOption: false,
        categories: [],
        parameterClass: "",
        etimEquivalent: [],
        description: ""
    },
    {
        name: "length",
        label: "Length",
        type: "Number",
        standardOptions: [],
        unitOptions: [
            "mm",
            "cm",
            "m"
        ],
        unitSystem: "metric",
        menuOptions: [],
        minMaxOption: true,
        categories: [],
        parameterClass: "Dimensions",
        etimEquivalent: [
            "EF001438",
            "EF005483"
        ],
        description: ""
    },
    {
        name: "width",
        label: "Width",
        type: "Number",
        standardOptions: [],
        unitOptions: [
            "mm",
            "cm",
            "m"
        ],
        unitSystem: "metric",
        menuOptions: [],
        minMaxOption: true,
        categories: [],
        parameterClass: "Dimensions",
        etimEquivalent: [
            "EF000008",
            "EF005452"
        ],
        description: ""
    },
    {
        name: "height",
        label: "Height",
        type: "Number",
        standardOptions: [],
        unitOptions: [
            "mm",
            "cm",
            "m"
        ],
        unitSystem: "metric",
        menuOptions: [],
        minMaxOption: true,
        categories: [],
        parameterClass: "Dimensions",
        etimEquivalent: [
            "EF000040",
            "EF005454"
        ],
        description: ""
    },
    {
        name: "thickness",
        label: "Thickness",
        type: "Number",
        standardOptions: [],
        unitOptions: [
            "mm",
            "cm",
            "m"
        ],
        unitSystem: "metric",
        menuOptions: [],
        minMaxOption: true,
        categories: [],
        parameterClass: "Dimensions",
        etimEquivalent: "EF000125",
        description: ""
    },
    {
        name: "diameter",
        label: "Diameter",
        type: "Number",
        standardOptions: [],
        unitOptions: [
            "mm",
            "cm",
            "m"
        ],
        unitSystem: "metric",
        menuOptions: [],
        minMaxOption: true,
        categories: [],
        parameterClass: "Dimensions",
        etimEquivalent: "EF000551",
        description: ""
    },
    {
        name: "radius",
        label: "Radius",
        type: "Number",
        standardOptions: [],
        unitOptions: [
            "mm",
            "cm",
            "m"
        ],
        unitSystem: "metric",
        menuOptions: [],
        minMaxOption: true,
        categories: [],
        parameterClass: "Dimensions",
        etimEquivalent: "EF020621",
        description: ""
    },
    {
        name: "area",
        label: "Area",
        type: "Number",
        standardOptions: [],
        unitOptions: [
            "mm²",
            "cm²",
            "m²"
        ],
        unitSystem: "metric",
        menuOptions: [],
        minMaxOption: true,
        categories: [],
        parameterClass: "Dimensions",
        etimEquivalent: "EF002228",
        description: ""
    },
    {
        name: "volume",
        label: "Volume",
        type: "Number",
        standardOptions: [],
        unitOptions: [
            "cm³",
            "m³"
        ],
        unitSystem: "metric",
        menuOptions: [],
        minMaxOption: true,
        categories: [],
        parameterClass: "Dimensions",
        etimEquivalent: "EF000880",
        description: ""
    },
    {
        name: "depth",
        label: "Depth",
        type: "Number",
        standardOptions: [],
        unitOptions: [
            "mm",
            "cm",
            "m"
        ],
        unitSystem: "metric",
        menuOptions: [],
        minMaxOption: true,
        categories: [],
        parameterClass: "Dimensions",
        etimEquivalent: [
            "EF000049",
            "EF005457"
        ],
        description: ""
    },
    {
        name: "lengthImp",
        label: "Length",
        type: "String",
        standardOptions: [],
        unitOptions: [
            "ft",
            "in",
            "{ft}' {in}\""
        ],
        unitSystem: "imperial",
        menuOptions: [],
        minMaxOption: true,
        categories: [],
        parameterClass: "Dimensions",
        etimEquivalent: [
            "EF001438",
            "EF005483"
        ],
        description: ""
    },
    {
        name: "widthImp",
        label: "Width",
        type: "String",
        standardOptions: [],
        unitOptions: [
            "ft",
            "in",
            "{ft}' {in}\""
        ],
        unitSystem: "imperial",
        menuOptions: [],
        minMaxOption: true,
        categories: [],
        parameterClass: "Dimensions",
        etimEquivalent: [
            "EF000008",
            "EF005452"
        ],
        description: ""
    },
    {
        name: "heightImp",
        label: "Height",
        type: "String",
        standardOptions: [],
        unitOptions: [
            "ft",
            "in",
            "{ft}' {in}\""
        ],
        unitSystem: "imperial",
        menuOptions: [],
        minMaxOption: true,
        categories: [],
        parameterClass: "Dimensions",
        etimEquivalent: [
            "EF000040",
            "EF005454"
        ],
        description: ""
    },
    {
        name: "thicknessImp",
        label: "Thickness",
        type: "String",
        standardOptions: [],
        unitOptions: [
            "ft",
            "in",
            "{ft}' {in}\""
        ],
        unitSystem: "imperial",
        menuOptions: [],
        minMaxOption: true,
        categories: [],
        parameterClass: "Dimensions",
        etimEquivalent: "EF000125",
        description: ""
    },
    {
        name: "diameterImp",
        label: "Diameter",
        type: "String",
        standardOptions: [],
        unitOptions: [
            "ft",
            "in",
            "{ft}' {in}\""
        ],
        unitSystem: "imperial",
        menuOptions: [],
        minMaxOption: true,
        categories: [],
        parameterClass: "Dimensions",
        etimEquivalent: "EF000551",
        description: "Diameter of circular shaped products."
    },
    {
        name: "radiusImp",
        label: "Radius",
        type: "String",
        standardOptions: [],
        unitOptions: [
            "ft",
            "in",
            "{ft}' {in}\""
        ],
        unitSystem: "imperial",
        menuOptions: [],
        minMaxOption: true,
        categories: [],
        parameterClass: "Dimensions",
        etimEquivalent: "EF020621",
        description: ""
    },
    {
        name: "areaImp",
        label: "Area",
        type: "String",
        standardOptions: [],
        unitOptions: [
            "sq ft",
            "sq inch",
            "acre"
        ],
        unitSystem: "imperial",
        menuOptions: [],
        minMaxOption: true,
        categories: [],
        parameterClass: "Dimensions",
        etimEquivalent: "EF002228",
        description: ""
    },
    {
        name: "volumeImp",
        label: "Volume",
        type: "String",
        standardOptions: [],
        unitOptions: [
            "cu in",
            "cu ft",
            "cu yd"
        ],
        unitSystem: "imperial",
        menuOptions: [],
        minMaxOption: true,
        categories: [],
        parameterClass: "Dimensions",
        etimEquivalent: "EF000880",
        description: ""
    },
    {
        name: "depthImp",
        label: "Depth",
        type: "String",
        standardOptions: [],
        unitOptions: [
            "ft",
            "in",
            "{ft}' {in}\""
        ],
        unitSystem: "imperial",
        menuOptions: [],
        minMaxOption: true,
        categories: [],
        parameterClass: "Dimensions",
        etimEquivalent: [
            "EF000049",
            "EF005457"
        ],
        description: ""
    },
    {
        name: "frequency",
        label: "Frequency",
        type: "Number",
        standardOptions: [],
        unitOptions: [
            "Hz"
        ],
        unitSystem: "default",
        menuOptions: [],
        minMaxOption: true,
        categories: [],
        parameterClass: "Electrical",
        etimEquivalent: [
            "EF000034",
            "EF000416",
            "EF005455"
        ],
        description: ""
    },
    {
        name: "voltage",
        label: "Voltage",
        type: "Number",
        standardOptions: [],
        unitOptions: [
            "V"
        ],
        unitSystem: "default",
        menuOptions: [],
        minMaxOption: true,
        categories: [],
        parameterClass: "Electrical",
        etimEquivalent: [
            "EF000213",
            "EF002355"
        ],
        description: ""
    },
    {
        name: "fuse",
        label: "Fuse",
        type: "Number",
        standardOptions: [],
        unitOptions: [
            "A"
        ],
        unitSystem: "default",
        menuOptions: [],
        minMaxOption: true,
        categories: [],
        parameterClass: "Electrical",
        etimEquivalent: [
            "EF024947",
            "EF004149"
        ],
        description: "Size of circuit breaker in/for the product."
    },
    {
        name: "load",
        label: "Load",
        type: "Number",
        standardOptions: [],
        unitOptions: [
            "W"
        ],
        unitSystem: "default",
        menuOptions: [],
        minMaxOption: true,
        categories: [],
        parameterClass: "Electrical",
        etimEquivalent: [
            "EF000346"
        ],
        description: "Electric power consumption."
    },
    {
        name: "ratedPower",
        label: "Rated power",
        type: "Number",
        standardOptions: [],
        unitOptions: [
            "W"
        ],
        unitSystem: "default",
        menuOptions: [],
        minMaxOption: true,
        categories: [],
        parameterClass: "Electrical",
        etimEquivalent: [
            "EF007051"
        ],
        description: "Highest power input allowed to flow through the product."
    },
    {
        name: "connectionType",
        label: "Connection type",
        type: "String",
        standardOptions: [],
        unitOptions: [],
        unitSystem: "default",
        menuOptions: [],
        minMaxOption: false,
        categories: [],
        parameterClass: "Electrical",
        etimEquivalent: [
            "EF000443",
            "EF000124"
        ],
        description: "Type of connector, plug or wiring to connect product to power supply."
    },
    {
        name: "energyConsumptionInConvectionMode",
        label: "Energy consumption in convection mode",
        type: "Number",
        standardOptions: [],
        unitOptions: [
            "kWh",
            "BTU"
        ],
        unitSystem: "default",
        menuOptions: [],
        minMaxOption: false,
        categories: [
            "electronics"
        ],
        parameterClass: "Energy efficiency",
        etimEquivalent: "EF008748",
        description: ""
    },
    {
        name: "energyConsumptionInRecirculationMode",
        label: "Energy consumption in recirculation mode",
        type: "Number",
        standardOptions: [],
        unitOptions: [
            "kWh",
            "BTU"
        ],
        unitSystem: "default",
        menuOptions: [],
        minMaxOption: false,
        categories: [
            "electronics"
        ],
        parameterClass: "Energy efficiency",
        etimEquivalent: "EF008749",
        description: ""
    },
    {
        name: "energySource",
        label: "Energy Source",
        type: "String",
        standardOptions: [],
        unitOptions: [],
        unitSystem: "default",
        menuOptions: [],
        minMaxOption: false,
        categories: [
            "electronics",
            "hvac"
        ],
        parameterClass: "Energy efficiency",
        etimEquivalent: [
            "EF020093",
            "EF007135",
            "EF012554",
            "EF012555"
        ],
        description: ""
    },
    {
        name: "energyEfficiencyClass",
        label: "Energy efficiency class",
        type: "Menu",
        standardOptions: [
            "EU Directive 92/75/EC"
        ],
        unitOptions: [],
        unitSystem: "default",
        menuOptions: [
            "A+++",
            "A++",
            "A+",
            "A",
            "B",
            "C",
            "D",
            "E",
            "F",
            "G"
        ],
        minMaxOption: false,
        categories: [
            "electrical",
            "electronics",
            "hvac"
        ],
        parameterClass: "Energy efficiency",
        etimEquivalent: [
            "EF000758",
            "EF017471",
            "EF016322",
            "EF016321",
            "EF017009",
            "EF017012",
            "EF016992",
            "EF017139",
            "EF012512",
            "EF017013",
            "EF024955",
            "EF017011",
            "EF024956",
            "EF016382",
            "EF017014",
            "EF017010",
            "EF010855",
            "EF016994",
            "EF010856",
            "EF016995"
        ],
        description: ""
    },
    {
        name: "thermalTransmittance",
        label: "Thermal transmittance",
        type: "Number",
        standardOptions: [
            "ISO 10077"
        ],
        unitOptions: [
            "W/m²K",
            "BTU/(h·ft²·°F)"
        ],
        unitSystem: "default",
        menuOptions: [],
        minMaxOption: true,
        categories: [
            "doors",
            "windows"
        ],
        parameterClass: "Energy efficiency",
        etimEquivalent: "EF010083",
        description: ""
    },
    {
        name: "thermalTransmittance",
        label: "Thermal transmittance",
        type: "Number",
        standardOptions: [
            "ISO 6946",
            "ISO 10211"
        ],
        unitOptions: [
            "W/m²K",
            "BTU/(h·ft²·°F)"
        ],
        unitSystem: "default",
        menuOptions: [],
        minMaxOption: true,
        categories: [
            "construction - curtain-systems-and-facades",
            "construction"
        ],
        parameterClass: "Energy efficiency",
        etimEquivalent: "EF010083",
        description: ""
    },
    {
        name: "thermalTransmittance",
        label: "Thermal transmittance",
        type: "Number",
        standardOptions: [
            "ISO 13370"
        ],
        unitOptions: [
            "W/m²K",
            "BTU/(h·ft²·°F)"
        ],
        unitSystem: "default",
        menuOptions: [],
        minMaxOption: true,
        categories: [
            "construction"
        ],
        parameterClass: "Energy efficiency",
        etimEquivalent: [],
        description: ""
    },
    {
        name: "thermalTransmittance",
        label: "Thermal transmittance",
        type: "Number",
        standardOptions: [
            "ISO 6946"
        ],
        unitOptions: [
            "W/m²K",
            "BTU/(h·ft²·°F)"
        ],
        unitSystem: "default",
        menuOptions: [],
        minMaxOption: true,
        categories: [
            "construction"
        ],
        parameterClass: "Energy efficiency",
        etimEquivalent: [],
        description: ""
    },
    {
        name: "energyConsumption",
        label: "Energy consumption",
        type: "Number",
        standardOptions: [],
        unitOptions: [
            "kWh",
            "BTU"
        ],
        unitSystem: "default",
        menuOptions: [],
        minMaxOption: true,
        categories: [],
        parameterClass: "Energy efficiency",
        etimEquivalent: [
            "EF012349",
            "EF012350",
            "EF012335"
        ],
        description: ""
    },
    {
        name: "fireRating",
        label: "Fire rating",
        type: "String",
        standardOptions: [
            "EN 13501-2",
            "EN 1364 / 1634"
        ],
        unitOptions: [],
        unitSystem: "default",
        menuOptions: [],
        minMaxOption: true,
        categories: [
            "doors",
            "windows",
            "construction"
        ],
        parameterClass: "Fire properties",
        etimEquivalent: [
            "EF010081",
            "EF013906",
            "EF010405"
        ],
        description: "Passive fire protection characteristics of the product. Template REI ttt where R stands for load-bearing capacity, E for integrity, I for insulation and the t's for time in minutes that the product retains its aforementioned characteristics in case of fire. E.g. REI 120"
    },
    {
        name: "bulbType",
        label: "Bulb type",
        type: "String",
        standardOptions: [],
        unitOptions: [],
        unitSystem: "default",
        menuOptions: [],
        minMaxOption: false,
        categories: [
            "lighting"
        ],
        parameterClass: "Lighting features",
        etimEquivalent: "EF002423",
        description: "Type of light bulb, e.g. LED or incandescent."
    },
    {
        name: "luminousFlux",
        label: "Luminous flux",
        type: "Number",
        standardOptions: [],
        unitOptions: [
            "lm"
        ],
        unitSystem: "default",
        menuOptions: [],
        minMaxOption: true,
        categories: [
            "lighting"
        ],
        parameterClass: "Lighting features",
        etimEquivalent: [
            "EF000078",
            "EF011587"
        ],
        description: "Power of visible light produced by the light source."
    },
    {
        name: "luminousEfficacy",
        label: "Luminous efficacy",
        type: "Number",
        standardOptions: [],
        unitOptions: [
            "lm/W"
        ],
        unitSystem: "default",
        menuOptions: [],
        minMaxOption: true,
        categories: [
            "lighting"
        ],
        parameterClass: "Lighting features",
        etimEquivalent: "EF013777",
        description: "Ratio of luminous flux to power consumed."
    },
    {
        name: "colourTemperature",
        label: "Colour temperature",
        type: "Number",
        standardOptions: [],
        unitOptions: [
            "K"
        ],
        unitSystem: "default",
        menuOptions: [],
        minMaxOption: true,
        categories: [
            "lighting"
        ],
        parameterClass: "Lighting features",
        etimEquivalent: [
            "EF000103",
            "EF009346"
        ],
        description: ""
    },
    {
        name: "colourRenderingIndex",
        label: "Colour rendering index (CRI)",
        type: "Number",
        standardOptions: [],
        unitOptions: [],
        unitSystem: "default",
        menuOptions: [],
        minMaxOption: false,
        categories: [
            "lighting"
        ],
        parameterClass: "Lighting features",
        etimEquivalent: "EF000442",
        description: ""
    },
    {
        name: "colourOfLight",
        label: "Colour of light",
        type: "String",
        standardOptions: [],
        unitOptions: [],
        unitSystem: "default",
        menuOptions: [],
        minMaxOption: false,
        categories: [
            "lighting"
        ],
        parameterClass: "Lighting features",
        etimEquivalent: [
            "EF002630",
            "EF009350",
            "EF005958"
        ],
        description: ""
    },
    {
        name: "televisionLightingConsistencyIndex",
        label: "Television lighting consistency index (TLCI)",
        type: "Number",
        standardOptions: [],
        unitOptions: [],
        unitSystem: "default",
        menuOptions: [],
        minMaxOption: false,
        categories: [
            "lighting"
        ],
        parameterClass: "Lighting features",
        etimEquivalent: [],
        description: ""
    },
    {
        name: "dimmingRange",
        label: "Dimming range",
        type: "String",
        standardOptions: [],
        unitOptions: [
            "%"
        ],
        unitSystem: "default",
        menuOptions: [],
        minMaxOption: false,
        categories: [
            "lighting"
        ],
        parameterClass: "Lighting features",
        etimEquivalent: [],
        description: ""
    },
    {
        name: "averageRatedLife",
        label: "Average rated life",
        type: "Number",
        standardOptions: [],
        unitOptions: [
            "h"
        ],
        unitSystem: "default",
        menuOptions: [],
        minMaxOption: false,
        categories: [
            "lighting"
        ],
        parameterClass: "Lighting features",
        etimEquivalent: "EF008342",
        description: ""
    },
    {
        name: "material",
        label: "Material",
        type: "String",
        standardOptions: [],
        unitOptions: [],
        unitSystem: "default",
        menuOptions: [],
        minMaxOption: false,
        categories: [],
        parameterClass: "Materials",
        etimEquivalent: "EF002169",
        description: "Primary material of the product."
    },
    {
        name: "netVolume",
        label: "Net volume",
        type: "Number",
        standardOptions: [],
        unitOptions: [
            "m³"
        ],
        unitSystem: "metric",
        menuOptions: [],
        minMaxOption: true,
        categories: [],
        parameterClass: "Quantities",
        etimEquivalent: [
            "EF008002",
            "EF005129"
        ],
        description: ""
    },
    {
        name: "weight",
        label: "Weight",
        type: "Number",
        standardOptions: [],
        unitOptions: [
            "kg"
        ],
        unitSystem: "metric",
        menuOptions: [],
        minMaxOption: true,
        categories: [],
        parameterClass: "Quantities",
        etimEquivalent: "EF000167",
        description: ""
    },
    {
        name: "flowCoefficient",
        label: "Flow coefficient",
        type: "Number",
        standardOptions: [],
        unitOptions: [
            "m³/h"
        ],
        unitSystem: "metric",
        menuOptions: [],
        minMaxOption: false,
        categories: [
            "hvac",
            "plumbing"
        ],
        parameterClass: "Technical features",
        etimEquivalent: [],
        description: "Efficiency of fluid flow in the product."
    },
    {
        name: "flowCoefficientImp",
        label: "Flow coefficient",
        type: "String",
        standardOptions: [],
        unitOptions: [
            "cu ft/h"
        ],
        unitSystem: "imperial",
        menuOptions: [],
        minMaxOption: false,
        categories: [
            "hvac",
            "plumbing"
        ],
        parameterClass: "Technical features",
        etimEquivalent: [],
        description: "Efficiency of fluid flow in the product."
    },
    {
        name: "operatingTemperature",
        label: "Operating temperature",
        type: "Number",
        standardOptions: [],
        unitOptions: [
            "°C",
            "°F"
        ],
        unitSystem: "default",
        menuOptions: [],
        minMaxOption: true,
        categories: [
            "hvac",
            "plumbing"
        ],
        parameterClass: "Technical features",
        etimEquivalent: [
            "EF001742",
            "EF002393",
            "EF001310"
        ],
        description: ""
    },
    {
        name: "operatingPressure",
        label: "Operating pressure",
        type: "Number",
        standardOptions: [],
        unitOptions: [
            "kPa",
            "psi"
        ],
        unitSystem: "default",
        menuOptions: [],
        minMaxOption: true,
        categories: [
            "hvac",
            "plumbing"
        ],
        parameterClass: "Technical features",
        etimEquivalent: [
            "EF020449",
            "EF022016",
            "EF020088"
        ],
        description: ""
    },
    {
        name: "nominalPressure",
        label: "Nominal pressure (PN)",
        type: "Number",
        standardOptions: [
            "ISO 7268"
        ],
        unitOptions: [
            "bar"
        ],
        unitSystem: "default",
        menuOptions: [],
        minMaxOption: false,
        categories: [
            "hvac",
            "plumbing"
        ],
        parameterClass: "Technical features",
        etimEquivalent: [
            "EF009814",
            "EF009816",
            "EF009818"
        ],
        description: ""
    },
    {
        name: "protectionClass",
        label: "Protection class",
        type: "String",
        standardOptions: [
            "EN 60529"
        ],
        unitOptions: [
            "IP rating"
        ],
        unitSystem: "default",
        menuOptions: [],
        minMaxOption: false,
        categories: [
            "electrical",
            "electronics"
        ],
        parameterClass: "Technical features",
        etimEquivalent: "EF000004",
        description: "The product's ability to withstand surrounding conditions."
    },
    {
        name: "impactResistance",
        label: "Impact resistance",
        type: "String",
        standardOptions: [
            "EN 62262"
        ],
        unitOptions: [
            "IK rating"
        ],
        unitSystem: "default",
        menuOptions: [],
        minMaxOption: false,
        categories: [
            "electrical",
            "electronics"
        ],
        parameterClass: "Technical features",
        etimEquivalent: [],
        description: "The product's ability to withstand mechanical impacts."
    },
    {
        name: "installationType",
        label: "Installation type",
        type: "String",
        standardOptions: [],
        unitOptions: [],
        unitSystem: "default",
        menuOptions: [],
        minMaxOption: false,
        categories: [],
        parameterClass: "Technical features",
        etimEquivalent: [
            "EF021376",
            "EF000333"
        ],
        description: "Attachments, connections, adhesives, fasteners, frames etc. required to install the product."
    },
    {
        name: "permissibleHumidity",
        label: "Permissible humidity",
        type: "Number",
        standardOptions: [],
        unitOptions: [
            "%"
        ],
        unitSystem: "default",
        menuOptions: [],
        minMaxOption: true,
        categories: [],
        parameterClass: "Technical features",
        etimEquivalent: "EF014231",
        description: ""
    },
    {
        name: "resistanceToRepeatedOpeningAndClosing",
        label: "Resistance to repeated opening and closing",
        type: "Menu",
        standardOptions: [
            "EN 1191:2012"
        ],
        unitOptions: [],
        unitSystem: "default",
        menuOptions: [
            "Class 1",
            "Class 2",
            "Class 3",
            "Class 4"
        ],
        minMaxOption: true,
        categories: [
            "doors",
            "windows"
        ],
        parameterClass: "Technical features",
        etimEquivalent: [],
        description: ""
    },
    {
        name: "burglaryProtection",
        label: "Burglary protection",
        type: "Menu",
        standardOptions: [
            "EN 1627:2011"
        ],
        unitOptions: [],
        unitSystem: "default",
        menuOptions: [
            "RC1",
            "RC2",
            "RC3",
            "RC4",
            "RC5",
            "RC6"
        ],
        minMaxOption: true,
        categories: [
            "doors",
            "windows"
        ],
        parameterClass: "Technical features",
        etimEquivalent: "EF014768",
        description: ""
    },
    {
        name: "airPermeability",
        label: "Air permeability",
        type: "Menu",
        standardOptions: [
            "EN 12207"
        ],
        unitOptions: [],
        unitSystem: "default",
        menuOptions: [
            "Class 1",
            "Class 2",
            "Class 3",
            "Class 4"
        ],
        minMaxOption: true,
        categories: [
            "doors",
            "windows"
        ],
        parameterClass: "Technical features",
        etimEquivalent: [],
        description: ""
    },
    {
        name: "watertightness",
        label: "Watertightness",
        type: "Menu",
        standardOptions: [
            "EN 12208"
        ],
        unitOptions: [],
        unitSystem: "default",
        menuOptions: [
            "Class 1",
            "Class 2",
            "Class 3",
            "Class 4",
            "Class 5",
            "Class 6",
            "Class 7",
            "Class 8",
            "Class 9"
        ],
        minMaxOption: true,
        categories: [
            "doors",
            "windows"
        ],
        parameterClass: "Technical features",
        etimEquivalent: [
            "EF018266",
            "EF013135"
        ],
        description: ""
    },
    {
        name: "windResistance",
        label: "Wind resistance",
        type: "Menu",
        standardOptions: [
            "ISO 6612:2023"
        ],
        unitOptions: [],
        unitSystem: "default",
        menuOptions: [
            "Class 1",
            "Class 2",
            "Class 3",
            "Class 4",
            "Class 5"
        ],
        minMaxOption: true,
        categories: [
            "doors",
            "windows"
        ],
        parameterClass: "Technical features",
        etimEquivalent: "EF018267",
        description: ""
    },
    {
        name: "shearStrength",
        label: "Shear strength",
        type: "Number",
        standardOptions: [],
        unitOptions: [
            "kPa",
            "MPa",
            "psi"
        ],
        unitSystem: "default",
        menuOptions: [],
        minMaxOption: true,
        categories: [],
        parameterClass: "Technical features",
        etimEquivalent: "EF026334",
        description: ""
    },
    {
        name: "tensileStrength",
        label: "Tensile strength",
        type: "Number",
        standardOptions: [],
        unitOptions: [
            "kPa",
            "MPa",
            "psi"
        ],
        unitSystem: "default",
        menuOptions: [],
        minMaxOption: true,
        categories: [],
        parameterClass: "Technical features",
        etimEquivalent: "EF002782",
        description: ""
    },
    {
        name: "compressiveStrength",
        label: "Compressive strength",
        type: "Number",
        standardOptions: [],
        unitOptions: [
            "kPa",
            "MPa",
            "psi"
        ],
        unitSystem: "default",
        menuOptions: [],
        minMaxOption: true,
        categories: [],
        parameterClass: "Technical features",
        etimEquivalent: [
            "EF016861",
            "EF010447",
            "EF016103",
            "EF016911",
            "EF010454",
            "EF026345",
            "EF012727"
        ],
        description: ""
    },
    {
        name: "density",
        label: "Density",
        type: "Number",
        standardOptions: [],
        unitOptions: [
            "kg/m³",
            "lb/ft³"
        ],
        unitSystem: "default",
        menuOptions: [],
        minMaxOption: true,
        categories: [],
        parameterClass: "Quantities",
        etimEquivalent: [
            "EF020000",
            "EF026349"
        ],
        description: ""
    },
    {
        name: "current",
        label: "Current",
        type: "Number",
        standardOptions: [],
        unitOptions: [
            "A"
        ],
        unitSystem: "default",
        menuOptions: [],
        minMaxOption: true,
        categories: [],
        parameterClass: "Electrical",
        etimEquivalent: [],
        description: ""
    },
    {
        name: "thermalResistance",
        label: "Thermal resistance",
        type: "Number",
        standardOptions: [],
        unitOptions: [
            "m²K/W",
            "h·ft²·°F/BTU"
        ],
        unitSystem: "default",
        menuOptions: [],
        minMaxOption: true,
        categories: [],
        parameterClass: "Energy efficiency",
        etimEquivalent: "EF010245",
        description: ""
    },
    {
        name: "mechanicalStress",
        label: "Mechanical stress",
        type: "Number",
        standardOptions: [],
        unitOptions: [
            "kPa",
            "MPa",
            "psi"
        ],
        unitSystem: "metric",
        menuOptions: [],
        minMaxOption: true,
        categories: [],
        parameterClass: "Technical features",
        etimEquivalent: [],
        description: ""
    },
    {
        name: "carbonFootprint",
        label: "Carbon footprint",
        type: "Number",
        standardOptions: [],
        unitOptions: [
            "kg CO₂e"
        ],
        unitSystem: "metric",
        menuOptions: [],
        minMaxOption: true,
        categories: [],
        parameterClass: "Sustainability",
        etimEquivalent: [],
        description: "Total amount of emissions of carbon dioxide (CO₂) accumulated over the lifecycle stages of the product."
    },
    {
        name: "passiveHouseEfficiencyClass",
        label: "Passive House efficiency class",
        type: "Menu",
        standardOptions: [],
        unitOptions: [],
        unitSystem: "default",
        menuOptions: [
            "phA+",
            "phA",
            "phB",
            "phC"
        ],
        minMaxOption: false,
        categories: [],
        parameterClass: "Sustainability",
        etimEquivalent: [],
        description: ""
    },
    {
        name: "fireRatingMaterials",
        label: "Fire rating materials",
        type: "String",
        standardOptions: [
            "EN 13501-1"
        ],
        unitOptions: [],
        unitSystem: "default",
        menuOptions: [],
        minMaxOption: false,
        categories: [
            "construction"
        ],
        parameterClass: "Fire properties",
        etimEquivalent: "EF026338",
        description: "The product's fire behaviour with letters A1, A2 or B-F; Smoke development s1, s2 or s3; Formation of flaming particles or droplets d0, d1 or d2. E.g. A2-s1,d0"
    }
]

export default items;