import { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Modal, TextField, Tooltip, FormControl, InputLabel, Select, Stack, MenuItem, Paper, List, ListItem } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { Plus } from '../../icons/plus';
import dayjs, { Dayjs} from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import * as Yup from 'yup';
import { useFormik } from 'formik';
//import { uuid } from 'uuidv4';
import {v4 as uuidv4} from 'uuid';
import { updateContract, signContract } from '../../api';
import toast from 'react-hot-toast';
import { wait } from '../../utils/wait';
import { useMsal } from '@azure/msal-react';
import { format } from 'date-fns';
// import { PDFViewer, Document, Page, Text, View, StyleSheet } from '@react-18-pdf/renderer';



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',
  border: '1px solid #313133',
  borderRadius: '8px',
  boxShadow: 24,
  overflowY: "auto",
  maxHeight: "90%",
  //overflow:'scroll',
  p: 4,
};

// const styles = StyleSheet.create({
//   page: {
//     flexDirection: 'row',
//     backgroundColor: '#E4E4E4'
//   },
//   section: {
//     margin: 10,
//     padding: 10,
//     flexGrow: 1
//   }
// });

// // Create Document Component
// const MyDocument = () => (
//   <Document>
//     <Page size="A4" style={styles.page}>
//       <View style={styles.section}>
//         <Text>Section #1</Text>
//       </View>
//       <View style={styles.section}>
//         <Text>Section #2</Text>
//       </View>
//     </Page>
//   </Document>
// );

// const customListStyle = {
//   ol: {
//     counterReset: "item",
//     paddingLeft: "10px"
//   },
//   li: {
//     display: "block"
//   },
//   li:before {
//     content: 'counters(item, ".") " "',
//     counterIncrement: "item"
//   }
// }

export const ContractModal = (props) => {
  const { close, refresh, edited, contractdata, ...other } = props;
  // const [ demoStatus, setDemoStatus ] = useState([]);
  const {accounts} = useMsal();

  const formik = useFormik({
    initialValues: {
      id: contractdata?.id,
     // documentType: contractdata?.documentType || '',
      name: contractdata?.name || '',
      email: contractdata?.email || '',
      manufacturerName: contractdata?.companyName || '',
      manufacturerAddress: contractdata?.manufacturerAddress || '',
      submit: null
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      id: Yup.string().max(255),
      name: Yup.string().max(255),
      email: Yup.string().max(255).required('Email address is required'),
      manufacturerName: Yup.string().max(255).required('Company name is required'),
      manufacturerAddress: Yup.string().max(255),
      //contactDate: Yup.string().max(255),
    }),
    onSubmit: async (values, helpers) => {
      try {
        // NOTE: Make API request
       
        const contractData = {
          name: formik.values.name,
          email: formik.values.email,
          manufacturerName: formik.values.manufacturerName,
          manufacturerAddress: formik.values.manufacturerAddress
        }

        //console.log(contractData);
       
        try {
          const newContract = await signContract(contractData);
          //console.log(newContract);
          // await updateContacting(updatedContacting);
          // await postContactingLog(contactingLogData);
          //console.log(newContract);
          if (newContract) {
            
            const updateContractData = {
              id: contractdata.id,
              externalID: newContract?.id,
              status: newContract?.status,
              name: contractData?.name,
              email: contractData?.email,
              manufacturerName: contractData?.manufacturerName,
              manufacturerAddress: contractData?.manufacturerAddress         
            }
  
            await updateContract(updateContractData);
          }
        } catch (err) {
          console.error(err);
        }
        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);
        toast.success('Contract sent!');
        await wait(500);
        helpers.resetForm();
        refresh();

        close();
      } catch (err) {
        console.error(err);
        toast.error('Something went wrong!');
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    }
  });


  return (
    <>
      <Modal
        open={props.open}
        onClose={props.close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form
        onSubmit={formik.handleSubmit}
        // {...other}
        >
          {/* <Box sx={style}>
            <Typography variant="body2" sx={{mb: 2, width: "100%"}}>
              <Box sx={{fontWeight: 700}} display='inline'>This AGREEMENT</Box> is entered on {format(new Date(), "eeee',' MMMM d',' yyyy'.'")}
            </ Typography>
            <Typography variant="body2"  sx={{fontWeight: 700, mb: 1, width: "100%"}}>
              BETWEEN
            </ Typography>
            <List sx={{ listStyleType: 'decimal', pl: 4 }}>
              <ListItem sx={{ display: 'list-item', pt: 0, pb: 0 }}>
                <Typography variant="body2"  sx={{mb: 1, width: "100%"}}>
                {manufacturerName}, {manufacturerAddress} ("Licensor") and
                </Typography>
              </ListItem>
              <ListItem sx={{ display: 'list-item', pt: 0, pb: 0 }}>
                <Typography variant="body2"  sx={{mb: 1, width: "100%"}}>
                bimform AG, Werkstrasse 25, 8404 Winterthur, Switzerland ("Licensee").
                </Typography>
              </ListItem>
            </List>
            <Typography variant="body2"  sx={{fontWeight: 700, mb: 1, width: "100%"}}>
              WHEREAS
            </ Typography>
            <List sx={{ listStyleType: 'decimal', pl: 4 }}>
              <ListItem  sx={{ display: 'list-item', pt: 0, pb: 0 }} >
                <Typography  variant="body2"  sx={{mb: 1, width: "100%"}}>
                Licensor owns the copyright and title and all other related rights in and to certain BIM (Building Information Modelling) works, product data, product images, BIM objects and other files. (hereinafter "Material").
                </Typography>
              </ListItem>
              <ListItem  sx={{ display: 'list-item', pt: 0, pb: 0 }}>
                <Typography variant="body2" sx={{mb: 1, width: "100%"}}>
                Licensee owns and operates the www.bimroom.com Platform (hereinafter "Platform") and is engaged in the commercial distribution of BIM contents through the Platform.
                </Typography>
              </ListItem>
              <ListItem  sx={{ display: 'list-item', pt: 0, pb: 0 }}>
                <Typography variant="body2"  sx={{mb: 1, width: "100%"}}>
                Licensee desires to obtain the rights to incorporate portions of the Material into the Platform and to further sublicense the Material to certain End-Users.
                </Typography>
              </ListItem>
            </List>
            <Typography variant="body2" >
              <Box sx={{fontWeight: 700}} display='inline'>THE PARTIES</Box> agree as follows:
            </ Typography>            
            <List sx={{ listStyleType: 'decimal', pl: 4, pb: 0, pt: 0  }}>
              <ListItem  sx={{ display: 'list-item'}} >
                <Typography  variant="body2" sx={{fontWeight: 700, width: "100%"}}>
                  Rights Granted
                </Typography>
                <List sx={{ listStyleType: 'decimal', pl: 2 }}>
                  <ListItem  sx={{ display: 'list-item', pt: 0, pb: 0 }}>
                    <Typography variant="body2">
                    Licensor hereby grants to Licensee, its successors and assigns, a non-exclusive right, license and privilege worldwide (the "Territory") to:
                    </Typography>
                  </ListItem>
                  <List sx={{ listStyleType: 'decimal', pl: 4, pt: 0, pb: 0 }}>
                    <ListItem  sx={{ display: 'list-item', pt: 0, pb: 0 }}>
                      <Typography variant="body2">
                      incorporate the Materials into the Platform and reproduce, distribute, import and offer the Materials on the Platform throughout the Territory;
                      </Typography>
                    </ListItem>
                    <ListItem  sx={{ display: 'list-item', pt: 0, pb: 0 }}>
                      <Typography variant="body2">
                      model generic objects based on Licensor`s Material;
                      </Typography>
                    </ListItem>
                    <ListItem  sx={{ display: 'list-item', pt: 0, pb: 0 }}>
                      <Typography variant="body2">
                      utilize the Licensor`s Branding, Company Name and Logo to promote the Licensor through and by the means of publishing and making available the Material through the Platform on or in connection with the advertising, publicizing, marketing of the Licensor`s Branding and distribution of the Material; and
                      </Typography>
                    </ListItem>
                    <ListItem  sx={{ display: 'list-item', pt: 0, pb: 0 }}>
                      <Typography variant="body2">
                      publicly display and authorize others to download and sublicense the Material in connection with the advertising, publicizing, marketing of the Licensor`s Branding and distribution of the Material through the Platform.
                      </Typography>
                    </ListItem>
                  </List>
                </List>
              </ListItem>
              <ListItem  sx={{ display: 'list-item'}} >
                <Typography  variant="body2" sx={{fontWeight: 700, width: "100%"}}>
                  Licensor's Rights and Obligations
                </Typography>
                <List sx={{ listStyleType: 'decimal', pl: 2 }}>
                  <ListItem  sx={{ display: 'list-item', pt: 0, pb: 0 }}>
                    <Typography variant="body2">
                    Licensor warrants and represents that it owns all right, title and interest in and to the Material.
                    </Typography>
                  </ListItem>
                  <ListItem  sx={{ display: 'list-item', pt: 0, pb: 0 }}>
                    <Typography variant="body2">
                    Licensor reserves unto itself all rights of every kind and nature except those specifically granted to Licensee herein; provided, that Licensor shall not grant any rights to use the Material or any portion thereof for any other purposes without Licensee's written consent.
                    </Typography>
                  </ListItem>
                </List>
              </ListItem>
              <ListItem  sx={{ display: 'list-item'}} >
                <Typography  variant="body2" sx={{fontWeight: 700, width: "100%"}}>
                  Licensee's Rights and Obligations
                </Typography>
                <List sx={{ listStyleType: 'decimal', pl: 2 }}>
                  <ListItem  sx={{ display: 'list-item', pt: 0, pb: 0 }}>
                    <Typography variant="body2">
                    Licensee shall be solely responsible for providing all funding and technical expertise for the advertising, publicizing, marketing of the Licensor`s Branding and distribution of the Material.
                    </Typography>
                  </ListItem>
                </List>
              </ListItem>
              <ListItem  sx={{ display: 'list-item'}} >
                <Typography  variant="body2" sx={{fontWeight: 700, width: "100%"}}>
                  Intellectual Property Rights
                </Typography>
                <List sx={{ listStyleType: 'decimal', pl: 2 }}>
                  <ListItem  sx={{ display: 'list-item', pt: 0, pb: 0 }}>
                    <Typography variant="body2">
                    Any rights, including intellectual property rights, not expressly granted to the Licensee under this Agreement are reserved to the Licensor. The Licensee will not, directly or indirectly, use the Material in any way other then as permitted under this Agreement.
                    </Typography>
                  </ListItem>
                  <ListItem  sx={{ display: 'list-item', pt: 0, pb: 0 }}>
                    <Typography variant="body2">
                    The Licensee acknowledges the validity of the Material and agrees that the benefit of and goodwill associated with use of the Material by the Licensee will ensure entirely for the benefit of the Licensor.
                    </Typography>
                  </ListItem>
                  <ListItem  sx={{ display: 'list-item', pt: 0, pb: 0 }}>
                    <Typography variant="body2">
                    Should any right, title or interest in or to the Material or any part thereof or any copyright or trademark related thereto become vested in the Licensee, the Licensee will at the request of the Licensor unconditionally and without charge assign any such right, title or interest to the Licensor.
                    </Typography>
                  </ListItem>
                </List>
              </ListItem>
              <ListItem  sx={{ display: 'list-item'}} >
                <Typography  variant="body2" sx={{fontWeight: 700, width: "100%"}}>
                  Compensation
                </Typography>
                <List sx={{ listStyleType: 'decimal', pl: 2 }}>
                  <ListItem  sx={{ display: 'list-item', pt: 0, pb: 0 }}>
                    <Typography variant="body2">
                    The parties agree that, without prejudice to any other remedies at law or in equity that either party may have in respect of any breach of this Agreement, no amount shall be payable by way of royalties, reward or renumeration for the use of the Material. 
                    </Typography>
                  </ListItem>
                </List>
              </ListItem>
              <ListItem  sx={{ display: 'list-item'}} >
                <Typography  variant="body2" sx={{fontWeight: 700, width: "100%"}}>
                  Termination
                </Typography>
                <List sx={{ listStyleType: 'decimal', pl: 2 }}>
                  <ListItem  sx={{ display: 'list-item', pt: 0, pb: 0 }}>
                    <Typography variant="body2">
                      The Agreement may be terminated by a written agreement between the Parties.
                    </Typography>
                  </ListItem>
                  <ListItem  sx={{ display: 'list-item', pt: 0, pb: 0 }}>
                    <Typography variant="body2">
                      Either Party may terminate this agreement for any reason upon 30 days’ notice to the other Party.
                    </Typography>
                  </ListItem>
                  <ListItem  sx={{ display: 'list-item', pt: 0, pb: 0 }}>
                    <Typography variant="body2">
                    Without prejudice to the other provision of this Agreement, the Licensor may terminate this agreement, with immediate effect, by giving notice to the Licensee if the Licensee in the Territory or elsewhere: 
                    </Typography>
                  </ListItem>
                  <List sx={{ listStyleType: 'decimal', pl: 4, pt: 0, pb: 0 }}>
                    <ListItem  sx={{ display: 'list-item', pt: 0, pb: 0 }}>
                      <Typography variant="body2">
                      challenges, or assists third parties to challenge the Material or Licensor's rights in the Material or registrations of the Material;
                      </Typography>
                    </ListItem>
                    <ListItem  sx={{ display: 'list-item', pt: 0, pb: 0 }}>
                      <Typography variant="body2">
                      registers or attempts to register any trademarks or trade names that are identical or confusingly similar to the Material;
                      </Typography>
                    </ListItem>
                  </List>
                </List>
              </ListItem>
              <ListItem  sx={{ display: 'list-item'}} >
                <Typography  variant="body2" sx={{fontWeight: 700, width: "100%"}}>
                  Effects of Termination
                </Typography>
                <List sx={{ listStyleType: 'decimal', pl: 2 }}>
                  <ListItem  sx={{ display: 'list-item', pt: 0, pb: 0 }}>
                    <Typography variant="body2">
                      Upon the termination of this agreement, the Licensee shall immediately cease all use of the Material.
                    </Typography>
                  </ListItem>
                  <ListItem  sx={{ display: 'list-item', pt: 0, pb: 0 }}>
                    <Typography variant="body2">
                      Upon the termination of this agreement, the Licensee shall not in the Territory or elsewhere attack or challenge the validity, ownership, or enforceability of the Material or of any registrations for the Material, or the Licensor 's rights relating to the Material or in any such registrations.
                    </Typography>
                  </ListItem>
                </List>
              </ListItem>
              <ListItem  sx={{ display: 'list-item'}} >
                <Typography  variant="body2" sx={{fontWeight: 700, width: "100%"}}>
                  Notices
                </Typography>
                <List sx={{ listStyleType: 'decimal', pl: 2 }}>
                  <ListItem  sx={{ display: 'list-item', pt: 0, pb: 0 }}>
                    <Typography variant="body2">
                      All notices / communications shall be given to either party by email using the last known e-mail address. Such notice will be deemed received, the day of sending if the email is received in full on a business day and on the next business day if the email is sent on a weekend or public holiday.
                    </Typography>
                  </ListItem>
                  <ListItem  sx={{ display: 'list-item', pt: 0, pb: 0 }}>
                    <Typography variant="body2">
                      This clause does not apply to the service of any proceedings or other documents in any legal action or, where applicable, any arbitration or other method of dispute resolution.
                    </Typography>
                  </ListItem>
                </List>
              </ListItem>
              <ListItem  sx={{ display: 'list-item'}} >
                <Typography  variant="body2" sx={{fontWeight: 700, width: "100%"}}>
                  Entire agreement
                </Typography>
                <List sx={{ listStyleType: 'decimal', pl: 2 }}>
                  <ListItem  sx={{ display: 'list-item', pt: 0, pb: 0 }}>
                    <Typography variant="body2">
                      This agreement contains the entire agreement between the parties and supersedes all previous agreements and understandings between them, whether written or oral, relating to its subject matter.
                    </Typography>
                  </ListItem>
                  <ListItem  sx={{ display: 'list-item', pt: 0, pb: 0 }}>
                    <Typography variant="body2">
                      Each party agrees that it shall have no remedies in respect of any statement, representation, or warranty (whether made innocently or negligently) that is not set out in this agreement. Each party agrees that it shall have no claim for innocent or negligent misrepresentation or negligent misstatement based on any statement in this agreement.
                    </Typography>
                  </ListItem>
                </List>
              </ListItem>
              <ListItem  sx={{ display: 'list-item'}} >
                <Typography  variant="body2" sx={{fontWeight: 700, width: "100%"}}>
                  Jurisdiction and Disputes
                </Typography>
                <List sx={{ listStyleType: 'decimal', pl: 2 }}>
                  <ListItem  sx={{ display: 'list-item', pt: 0, pb: 0 }}>
                    <Typography variant="body2">
                      This agreement and any dispute or claim arising out of or in connection with it shall be governed by and construed in accordance with the law of Switzerland.
                    </Typography>
                  </ListItem>
                  <ListItem  sx={{ display: 'list-item', pt: 0, pb: 0 }}>
                    <Typography variant="body2">
                      The parties submit to the exclusive jurisdiction of the courts of Winterthur in relation to any dispute or claim arising out of or in connection with this agreement.
                    </Typography>
                  </ListItem>
                </List>
              </ListItem>
            </List>
            <Typography variant="body2" sx={{mb: 2, width: "100%"}}>
              <Box sx={{fontWeight: 700}} display='inline'>This AGREEMENT</Box> has been signed by the parties hereto and takes effect on the date stated at the beginning of it.
            </ Typography>
          </Box> */}
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{mb: 2, width: "100%"}}>
              Signer details
            </Typography>
            <TextField
              id="id"
              label="Contract ID"
              // multiline
              // minRows={4}
              sx={{mb: 2, width: "100%"}}
              name="id"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.id}
              disabled
            />

            <Tooltip title="Company name">
              <TextField
                id="manufacturerName"
                label="Company name"
                // multiline
                // minRows={4}
                sx={{mb: 2, width: "100%"}}
                name="manufacturerName"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.manufacturerName}
              />
            </Tooltip>
            <Tooltip title="Street, number, zip, town, country">
              <TextField
                id="manufacturerAddress"
                label="Company address "
                // multiline
                // minRows={4}
                sx={{mb: 2, width: "100%"}}
                //placeholder="Full address"
                name="manufacturerAddress"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.manufacturerAddress}
              />
            </Tooltip>
            <Tooltip title="Name of the person who will sign the document">
              <TextField
                id="name"
                label="Name"
                // multiline
                // minRows={4}
                sx={{mb: 2, width: "100%"}}
                name="name"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
              />
            </Tooltip>
            <Tooltip title="Document will be sent to this email address">
              <TextField
                id="email"
                label="Email"
                // multiline
                // minRows={4}
                sx={{mb: 2, width: "100%"}}
                //placeholder="Document will be sent to this email address"
                name="email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.email}
              />
            </Tooltip>
            <IconButton 
              disabled={formik.isSubmitting}
              type="submit"
            >
              <Typography>Send the document for signature</Typography>
            </ IconButton>
          </Box>
        </form>
      </Modal>
    </>
  );
};
