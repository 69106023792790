import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
// import NextLink from 'next/link';
// import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
// import { useTranslation } from 'react-i18next';
import { Box, Button, Chip, Divider, Drawer, Typography, useMediaQuery } from '@mui/material';
import { Calendar as CalendarIcon } from '../icons/calendar';
import { Cash as CashIcon } from '../icons/cash';
import { ChartBar as ChartBarIcon } from '../icons/chart-bar';
import { ChartPie as ChartPieIcon } from '../icons/chart-pie';
import { ChatAlt2 as ChatAlt2Icon } from '../icons/chat-alt2';
import { ClipboardList as ClipboardListIcon } from '../icons/clipboard-list';

import { Home as HomeIcon } from '../icons/home';
import { LockClosed as LockClosedIcon } from '../icons/lock-closed';
import { Mail as MailIcon } from '../icons/mail';
import { MailOpen as MailOpenIcon } from '../icons/mail-open';
import { Box as BoxIcon } from '../icons/box';
import { Newspaper as NewspaperIcon } from '../icons/newspaper';
import { OfficeBuilding, OfficeBuilding as OfficeBuildingIcon } from '../icons/office-building';
import { ReceiptTax as ReceiptTaxIcon } from '../icons/receipt-tax';
import { Selector as SelectorIcon } from '../icons/selector';
import { Share as ShareIcon } from '../icons/share';
import { ShoppingBag as ShoppingBagIcon } from '../icons/shopping-bag';
import { ShoppingCart as ShoppingCartIcon } from '../icons/shopping-cart';
import { Truck as TruckIcon } from '../icons/truck';
import { UserCircle as UserCircleIcon } from '../icons/user-circle';
import { Users as UsersIcon } from '../icons/users';
import { UserAdd as UserAddIcon } from '../icons/user-add';
import { XCircle as XCircleIcon } from '../icons/x-circle';
import { CreditCard as CreditCardIcon } from '../icons/credit-card';
import { DocumentText as DocumentTextIcon } from '../icons/document-text';
import { Logo } from './logo';
import { Scrollbar } from './scrollbar';
import { DashboardSidebarSection } from './dashboard-sidebar-section';
import { Gigs } from '../icons/gigs';
import { ProductIcon } from '../icons/product-icon';
import { Bimroom as BimroomIcon } from '../icons/bimroom';
import { Factory as FactoryIcon } from '../icons/factory';
//import { OrganizationPopover } from './organization-popover';

const getSections = () => [
  {
    title: "General",
    items: [
      {
        title: "Home",
        path: '/',
        icon: <HomeIcon fontSize="small" />
      },
      {
        title: "Analytics",
        path: '/analytics',
        icon: <ChartBarIcon fontSize="small" />
      },
      {
        title: "Contacts",
        path: '/contacting',
        icon: <MailIcon fontSize="small" />
      },
      {
        title: "Documents",
        path: '/contracts',
        icon: <DocumentTextIcon fontSize="small" />
      }
    ]
  },
  // {
  //   title: "Customer contacts",
  //   items: [
  //     {
  //       title: "Contacts",
  //       path: '/contacting',
  //       icon: <MailIcon fontSize="small" />
  //     },
  //     // {
  //     //   title: "Bimroom demos",
  //     //   path: '/demo',
  //     //   icon: <UserAddIcon fontSize="small" />
  //     // },
  //     {
  //       title: "Contracts",
  //       path: '/contracts',
  //       icon: <DocumentTextIcon fontSize="small" />
  //     }
  //   ]
  // },
  {
    title: "Bimroom",
    items: [
      {
        title: 'Demo',
        path: '/demo',
        icon: <UserAddIcon fontSize="small" />,
        children: [
          {
            title: 'Requested',
            path: '/demo/requests'
          },
          {
            title: 'Brands',
            path: '/demo/brands'
          },
          {
            title: 'Products',
            path: '/demo/products'
          },
          // {
          //   title: 'Add a demo product',
          //   path: '/add-demo'
          // }
        ]
      },
      {
        title: "Manufacturers",
        path: '/clients',
        icon: <FactoryIcon fontSize="small" />,
        children: [
          {
            title: 'Main profiles',
            path: '/clients'
          },
          {
            title: 'Brands',
            path: '/clients/brands'
          }
        ]
      },
      {
        title: "All products",
        path: '/products',
        icon: <BoxIcon fontSize="small" />,
        children: [
          {
            title: 'Products',
            path: '/products'
          },
          {
            title: 'Add new',
            path: '/products/form/v2'
          }
        ]
      },
      // {
      //   title: "All products",
      //   path: '/products',
      //   icon: <BoxIcon fontSize="small" />
      // },
      {
        title: "Bimroom collection",
        path: '/generic',
        icon: <BimroomIcon fontSize="small" />
      },
      {
        title: "Subscriptions",
        path: '/subscriptions',
        icon: <CreditCardIcon fontSize="small" />
      }
    ]
  },
  {
    title: "Modelling and consulting",
    items: [
      {
        title: "Gigs",
        path: '/gigs',
        icon: <Gigs fontSize="small" />
      }
    ]
  }
];

export const DashboardSidebar = (props) => {
  const { onClose, open } = props;
  const location = useLocation();
  // const router = useRouter();
  // const { t } = useTranslation();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
    noSsr: true
  });
  // const sections = useMemo(() => getSections(t), [t]);
  const sections = useMemo(() => getSections());
  const organizationsRef = useRef(null);
  const [openOrganizationsPopover, setOpenOrganizationsPopover] = useState(false);

  // const handlePathChange = () => {
  //   if (!router.isReady) {
  //     return;
  //   }

  //   if (open) {
  //     onClose?.();
  //   }
  // };

  // useEffect(handlePathChange,
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [router.isReady, router.asPath]);

  const handleOpenOrganizationsPopover = () => {
    setOpenOrganizationsPopover(true);
  };

  const handleCloseOrganizationsPopover = () => {
    setOpenOrganizationsPopover(false);
  };

  const content = (
    <>
      <Scrollbar
        sx={{
          height: '100%',
          '& .simplebar-content': {
            height: '100%'
          }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <div>
            <Box sx={{ 
              px: 3,
              py: 1
            }}>
              <Link
                href="/"
                //passHref
              >
                <a>
                  <Logo
                    sx={{
                      height: "auto",
                      width: "100%"
                    }}
                    variant="light"
                  />
                </a>
              </Link>
            </Box>
            {/* <Box sx={{ px: 2 }}>
              <Box
                onClick={handleOpenOrganizationsPopover}
                ref={organizationsRef}
                sx={{
                  alignItems: 'center',
                  backgroundColor: 'rgba(255, 255, 255, 0.04)',
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: 'space-between',
                  px: 3,
                  py: '11px',
                  borderRadius: 1
                }}
              >
                <div>
                  <Typography
                    color="inherit"
                    variant="subtitle1"
                  >
                    Acme Inc
                  </Typography>
                  <Typography
                    color="neutral.400"
                    variant="body2"
                  >
                    {"Your tier"}
                    {' '}
                    : Premium
                  </Typography>
                </div>
                <SelectorIcon
                  sx={{
                    color: 'neutral.500',
                    width: 14,
                    height: 14
                  }}
                />
              </Box>
            </Box> */}
          </div>
          {/* <Divider
            sx={{
              borderColor: '#2D3748',
              my: 1
            }}
          /> */}
          <Box sx={{ flexGrow: 1 }}>
            {sections.map((section) => (
              <DashboardSidebarSection
                key={section.title}
                path={location.pathname}
                sx={{
                  mt: 2,
                  '& + &': {
                    mt: 2
                  }
                }}
                {...section} />
            ))}
          </Box>
          {/* <Divider
            sx={{
              borderColor: '#2D3748'  // dark divider
            }}
          />
          <Box sx={{ p: 2 }}>
            <Typography
              color="neutral.100"
              variant="subtitle2"
            >
              {"Need help?"}
            </Typography>
            <Typography
              color="neutral.500"
              variant="body2"
            >
              {"Check Docs"}
            </Typography>
          </Box> */}
        </Box>
      </Scrollbar>
      {/* <OrganizationPopover
        anchorEl={organizationsRef.current}
        onClose={handleCloseOrganizationsPopover}
        open={openOrganizationsPopover}
      /> */}
    </>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'neutral.900',
            // borderRightColor: 'divider',
            // borderRightStyle: 'solid',
            // borderRightWidth: (theme) => theme.palette.mode === 'dark' ? 1 : 0,
            color: '#FFFFFF',
            width: 280,
            border: 'none'
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: 'neutral.900',
          color: '#FFFFFF',
          width: 280,
          border: 'none'
        }
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};
