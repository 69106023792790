import { useState, useEffect, useCallback } from 'react';
import { Box, Card, CardHeader, Grid, Typography, IconButton, Modal, TextField, FormControl, InputLabel, Select, Stack, MenuItem } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { Plus } from './icons/plus';
import dayjs, { Dayjs} from 'dayjs';
import { useMounted } from './hooks/use-mounted';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import * as Yup from 'yup';
import { useFormik } from 'formik';
//import { uuid } from 'uuidv4';
import {v4 as uuidv4} from 'uuid';
import { postContactingLog, updateContacting, updateContactingLog, getContacts } from './api';
import toast from 'react-hot-toast';
import { wait } from './utils/wait';


import { useMsal } from '@azure/msal-react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #313133',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
};

export const EventModal = (props) => {
  const { eventdata, open, close, refresh, edited, companyid, oldStatus,...other } = props;
  // const [ show, setShow ] = useState(false);
  const [ contacts, setContacts ] = useState([]);
  const { accounts } = useMsal();
  const isMounted = useMounted();
  //console.log(accounts);
  const formik = useFormik({
    initialValues: {
      id: eventdata?.id || uuidv4(),
      user: accounts[0]?.username || '',
      companyId: eventdata?.companyId || companyid,
      contactDetails: eventdata?.contactDetails || '',
      contactType: eventdata?.contactType || '',
      contactDate: eventdata?.contactDate || Date.now(),
      nextTaskDate: eventdata?.nextTaskDate || Date.now(),
      nextTask: eventdata?.nextTask || '',
      interest: eventdata?.interest || '',
      notes: eventdata?.notes || '',
      submit: null
    },
    validationSchema: Yup.object({
      id: Yup.string().max(255),
      user: Yup.string().max(255),
      companyId: Yup.string().max(255),
      contactType: Yup.string().max(50),
      //contactDate: Yup.string().max(255),
      nextTask: Yup.string().max(255),
      nextTaskDate: Yup.string().max(255),
      interest: Yup.string().max(50),
      phone: Yup.string().max(20),
      notes: Yup.string(),
    }),
    onSubmit: async (values, helpers) => {
      try {
        // NOTE: Make API request
        //
       let responseContactingLog = "";
       
        if (edited) {
          responseContactingLog = await updateContactingLog(values);
        } else {
          responseContactingLog = await postContactingLog(values);
        }

        // update contacting

        let payload = {
          id: formik.values.companyId,
          latestContact: {
            id: formik.values.id,
            contactDate: formik.values.contactDate,
            contactType: formik.values.contactType
          },
          latestDate: formik.values.contactDate,
          nextDate: formik.values.nextTaskDate,
          nextTask: formik.values.nextTask,
          notes: formik.values.notes
        }

        // if (formik.contactType === "Brochure sent") {
        //   payload.status = "Brochure sent";
        //   payload.brochureSent = true;
        // }


        switch (formik.values.contactType) {
          case 'Brochure sent':
            payload.status = "Brochure sent";
            payload.brochureSent = true;
            break;
          case 'Demo sent':
            payload.status = "Demo sent";
            payload.demoSent = true;
            break;
          case 'Contract sent':
            payload.status = "Contract sent";
            payload.contractSent = true;
            break;
          case 'On board':
            payload.status = "On board";
            payload.onBoard = true;
            break;  
          case 'Contacted by phone':
            payload.status = "Contacted";
            break;
          case 'Contacted by email':
            payload.status = "Contacted";
            break;   
        }

        const responseContacting = await updateContacting(payload);

        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);
        toast.success('Event updated!');
        await wait(500);
        helpers.resetForm();
        setNewIds();
        refresh();
        close();
      } catch (err) {
        console.error(err);
        toast.error('Something went wrong!');
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    }
  });

  const getCustomerContacts = useCallback(async () => {
    try {
      //const data = await customerApi.getCustomers();
      const data = await getContacts(props.companyid);
      // console.log(data);

      if (data) {
        const defaults = data.filter(function (el) {
          return el.default === true
        });
        if (!edited && defaults) {
          if (typeof(defaults[0]?.name) !== "undefined") {
            formik.setFieldValue('contactDetails', defaults[0].name);
          }
        }
      }

      if (isMounted()) {
        setContacts(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  useEffect(() => {
      if (open) {
        getCustomerContacts();
        //console.log("modal opened");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [open]);


  const getText = (edited) => {
    if (edited) {
      return "Edit event";
    } else {
      return "Add new event";
    }
  }

  const setNewIds = () => {
    formik.setFieldValue('id', uuidv4());
  }

  return (
    <>
      <Modal
        open={props.open}
        onClose={props.close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form
        onSubmit={formik.handleSubmit}
        // {...other}
        >
          
          <Box sx={style}>
            {/* <Card sx={style}>
              <CardHeader title={getText(edited)} /> */}
              <Typography id="modal-modal-title" variant="h6" component="h2" sx={{mb: 2, width: "100%"}}>
                {getText(edited)}
              </Typography>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Contact person</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="contactDetails"
                      name="contactDetails"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.contactDetails}
                      label="contactDetails"
                      //sx={{mb: 2}}
                    >
                      {contacts.map((contact) => (
                        <MenuItem 
                          key={contact.id}
                          value={contact.name}
                        >
                          {contact?.name}, {contact?.email}, {contact?.phone}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {/* <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="user"
                    label="User"
                    //multiline
                    minRows={4}
                    //sx={{mb: 2, width: "100%"}}
                    name="user"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.user}
                  />
                </Grid>
                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack spacing={3}>
                      <DatePicker
                        label="Contact date"
                            id="contactDate"
                            name="contactDate"
                            value={formik.values.contactDate}
                            onChange={(value) => {
                                formik.setFieldValue('contactDate', Date.parse(value));
                                }}
                          renderInput={(params) => <TextField {...params} />}
                      />
                    </ Stack>
                  </LocalizationProvider>
                </Grid> */}
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Contact type</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="contactType"
                      name="contactType"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.contactType}
                      label="Contact Type"
                      //sx={{mb: 2}}
                    >
                      {/* <MenuItem value={"Not contacted"}>Not contacted</MenuItem> */}
                      <MenuItem value={"Contacted by phone"}>Contacted by phone</MenuItem>
                      <MenuItem value={"Contacted by email"}>Contacted by email</MenuItem>
                      <MenuItem value={"Brochure sent"}>Brochure sent</MenuItem>
                      <MenuItem value={"Demo sent"}>Demo sent</MenuItem>
                      <MenuItem value={"Contract sent"}>Contract sent</MenuItem>
                      <MenuItem value={"On board"}>Mark on board</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Interest</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="interest"
                      name="interest"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.interest}
                      label="Interest"
                      //sx={{mb: 2}}
                    >
                      <MenuItem value={"No answer"}>No answer</MenuItem>
                      <MenuItem value={"Not interested"}>Not interested</MenuItem>
                      <MenuItem value={"Interested"}>Interested</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Next task</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="nextTask"
                      name="nextTask"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.nextTask}
                      label="Next task"
                      //sx={{mb: 2}}
                    >
                      <MenuItem value={"Video call"}>Video call</MenuItem>
                      <MenuItem value={"Wait for feedback"}>Wait for feedback</MenuItem>
                      <MenuItem value={"Contact again"}>Contact again</MenuItem>
                      <MenuItem value={"Send brochure"}>Send brochure</MenuItem>
                      <MenuItem value={"Send demo"}>Send demo</MenuItem>
                      <MenuItem value={"Send contract"}>Send contract</MenuItem>
                      <MenuItem value={"No follow-up"}>No follow-up</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack spacing={3}>
                      <DatePicker
                        label="Next task date"
                            id="nextTaskDate"
                            name="nextTaskDate"
                            value={formik.values.nextTaskDate}
                            onChange={(value) => {
                                formik.setFieldValue('nextTaskDate', Date.parse(value));
                                }}
                          renderInput={(params) => <TextField {...params} />}
                      />
                    </ Stack>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="notes"
                    label="Notes"
                    multiline
                    minRows={4}
                    //sx={{mb: 2, width: "100%"}}
                    name="notes"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.notes}
                  />
                </Grid>
              </Grid>
              <IconButton 
                disabled={formik.isSubmitting}
                type="submit"
              >
                <Plus />
                <Typography>Save</Typography>
              </ IconButton>
            {/* </Card> */}
          </Box>
        </form>
      </Modal>
    </>
  );
};
