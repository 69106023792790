import { Suspense, useEffect, useState } from 'react';
import { Button, Typography, Grid, MenuItem, FormControl, InputLabel, Select, Card, CardContent, TextField, Box } from '@mui/material';
//import { Add } from '@mui/icons-material';
import { Plus as PlusIcon } from '../../icons/plus';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { FieldArray, FormikProvider, useFormik } from 'formik';
//import { uuid } from 'uuidv4';
import {v4 as uuidv4} from 'uuid';
import { portalUpdateProduct, upload, uploadAsWebp } from '../../api';
import { FileDropzone } from '../file-dropzone';
import Image3D from './Image3D';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, Stage, Center, Environment } from "@react-three/drei";

function randomString(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}


export const FileUploader = (props) => {
  const { 
    product, 
    open, 
    close, 
    edit
  } = props;

  const [images, setImages] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [renders, setRenders] = useState([]);
  const [uploadedRenders, setUploadedRenders] = useState([]);
  const [glbs, setGlbs] = useState([]);
  const [uploadedGlbs, setUploadedGlbs] = useState([]);
  const [revitProcessed, setRevitProcessed] = useState([]);
  const [uploadedRevitProcessed, setUploadedRevitProcessed] = useState([]);
  const [revitFiles, setRevitFiles] = useState([]);
  const [uploadedRevitFiles, setUploadedRevitFiles] = useState([]);
  const [otherBimFormats, setOtherBimFormats] = useState([]);
  const [uploadedOtherBimFormats, setUploadedOtherBimFormats] = useState([]);

  const formik = useFormik({
    initialValues: {
      id: product?.id || uuidv4(),
      status: product?.status || "draft",
      images: product?.images || [],
      renders: product?.renders || [],
      glbs: product?.glbs || [],
      revitProcessed: product?.revitProcessed || [],
      revitFiles: product?.revitFiles || [],
      otherBimFormats: product?.otherBimFormats || []
    },
    //enableReinitialize: true,
    validationSchema: Yup.object({
      id: Yup.string().max(255),
      status: Yup.string().max(255)
    }),
    onSubmit: async (values, helpers) => {
      try {
        // NOTE: Make API request
        const newObject = Object.assign(product, values);
        console.log(newObject);

        const response = await portalUpdateProduct(newObject);
        if (response === 200) {
          helpers.setStatus({ success: true });
          helpers.setSubmitting(false);
          helpers.resetForm();
          toast.success('Product updated!');
          close();
        }
        

        // setNewIds();
        
        //refresh();
        
      } catch (err) {
        console.error(err);
        toast.error('Something went wrong!');
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    }
  });

  useEffect(() => {
    const ids = new Set(formik.values.images.map(d => d?.newName));
    const merged = [...formik.values.images, ...uploadedImages.filter(d => !ids.has(d.newName))];
    formik.setFieldValue("images", merged);
    const existingValues = uploadedImages.map(item => item.name);
    setImages(() => images.filter((item) =>!existingValues.includes(item.name)));
  }, [uploadedImages])

  useEffect(() => {
    const ids = new Set(formik.values.renders.map(d => d?.newName));
    const merged = [...formik.values.renders, ...uploadedRenders.filter(d => !ids.has(d.newName))];
    formik.setFieldValue("renders", merged);
    const existingValues = uploadedRenders.map(item => item.name);
    setRenders(() => renders.filter((item) =>!existingValues.includes(item.name)));
  }, [uploadedRenders])

  useEffect(() => {
    const ids = new Set(formik.values.glbs.map(d => d?.newName));
    const merged = [...formik.values.glbs, ...uploadedGlbs.filter(d => !ids.has(d.newName))];
    formik.setFieldValue("glbs", merged);
    const existingValues = uploadedGlbs.map(item => item.name);
    setGlbs(() => glbs.filter((item) =>!existingValues.includes(item.name)));
  }, [uploadedGlbs])

  useEffect(() => {
    const ids = new Set(formik.values.revitProcessed.map(d => d?.newName));
    const merged = [...formik.values.revitProcessed, ...uploadedRevitProcessed.filter(d => !ids.has(d.newName))];
    formik.setFieldValue("revitProcessed", merged);
    const existingValues = uploadedRevitProcessed.map(item => item.name);
    setRevitProcessed(() => revitProcessed.filter((item) =>!existingValues.includes(item.name)));
  }, [uploadedRevitProcessed])

  useEffect(() => {
    const ids = new Set(formik.values.revitFiles.map(d => d?.newName));
    const merged = [...formik.values.revitFiles, ...uploadedRevitFiles.filter(d => !ids.has(d.newName))];
    formik.setFieldValue("revitFiles", merged);
    const existingValues = uploadedRevitFiles.map(item => item.name);
    setRevitFiles(() => revitFiles.filter((item) =>!existingValues.includes(item.name)));
  }, [uploadedRevitFiles])

  useEffect(() => {
    const ids = new Set(formik.values.otherBimFormats.map(d => d?.newName));
    const merged = [...formik.values.otherBimFormats, ...uploadedOtherBimFormats.filter(d => !ids.has(d.newName))];
    formik.setFieldValue("otherBimFormats", merged);
    const existingValues = uploadedOtherBimFormats.map(item => item.name);
    setOtherBimFormats(() => otherBimFormats.filter((item) =>!existingValues.includes(item.name)));
  }, [uploadedOtherBimFormats])


  const uploadFileToBlob = async (file, id, folder) => {
    // const newFile = new File([file], id, {
    //   type: file?.type,
    // });
    const newFile = await file.arrayBuffer();

    const response = await upload(newFile, id, folder);
    return response;
  };

  const uploadFileAsWebpToBlob = async (file, id, folder) => {
    // const newFile = new File([file], id, {
    //   type: file?.type,
    // });
    const newFile = await file.arrayBuffer();

    const response = await uploadAsWebp(newFile, id, folder);
    return response;
  };

  const handleDrop = (newFiles) => {
    setRenders((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleRemove = (file) => {
    setRenders((prevFiles) => prevFiles.filter((_file) => _file.path !== file.path));
  };

  const handleRemoveAll = () => {
    setRenders([]);
  };

  const handleUpload = () => {
    renders.map((file) => {

      console.log(randomString(15) + ".webp")

      const newFile = {
        name: file?.name,
        size: file?.size,
        type: file?.type,
        lastModified: file?.lastModified,
        newName: randomString(15) + ".webp",
        params: {}
      };

      try {
        uploadFileToBlob(file, newFile?.newName, "renders").then(res => {
          if (res.status === 200) {
            setUploadedRenders((prevFiles) => [...prevFiles, newFile]);
          }
        });
        // uploadFileAsWebpToBlob(file, newFile?.newName, "renders").then(res => {
        //   if (res.status === 200) {
        //     setUploadedRenders((prevFiles) => [...prevFiles, newFile]);
        //   }
        // });
      } catch (error) {
        console.error(error);
      }

    });
  };

  const handleDropImages = (newFiles) => {
    setImages((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleRemoveImages = (file) => {
    setImages((prevFiles) => prevFiles.filter((_file) => _file.path !== file.path));
  };

  const handleRemoveAllImages = () => {
    setImages([]);
  };

  const handleUploadImages = () => {
    images.map((file) => {

      const newFile = {
        name: file?.name,
        size: file?.size,
        type: file?.type,
        lastModified: file?.lastModified,
        newName: uuidv4() + ".webp",
        params: {}
      };

      try {
        uploadFileToBlob(file, newFile?.newName, "image").then(res => {
          if (res.status === 200) {
            setUploadedImages((prevFiles) => [...prevFiles, newFile]);
          }
        });
        // uploadFileAsWebpToBlob(file, newFile?.newName, "image").then(res => {
        //   if (res.status === 200) {
        //     setUploadedImages((prevFiles) => [...prevFiles, newFile]);
        //   }
        // });
      } catch (error) {
        console.error(error);
      }

    });
  };

  const handleDropGlb = (newFiles) => {
    setGlbs((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleRemoveGlb = (file) => {
    setGlbs((prevFiles) => prevFiles.filter((_file) => _file.path !== file.path));
  };

  const handleRemoveAllGlb = () => {
    setGlbs([]);
  };

  const handleUploadGlb = () => {
    glbs.map((file) => {

      const newFile = {
        name: file?.name,
        size: file?.size,
        type: file?.type,
        lastModified: file?.lastModified,
        newName: uuidv4(),
        params: {}
      };

      try {
        uploadFileToBlob(file, newFile?.newName, "glbs").then(res => {
          if (res.status === 200) {
            setUploadedGlbs((prevFiles) => [...prevFiles, newFile]);
          }
        });
      } catch (error) {
        console.error(error);
      }

    });
  };

  const handleDropRevitProcessed = (newFiles) => {
    setRevitProcessed((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleRemoveRevitProcessed = (file) => {
    setRevitProcessed((prevFiles) => prevFiles.filter((_file) => _file.path !== file.path));
  };

  const handleRemoveAllRevitProcessed = () => {
    setRevitProcessed([]);
  };

  const handleUploadRevitProcessed = () => {
    revitProcessed.map((file) => {

      const newFile = {
        name: file?.name,
        size: file?.size,
        type: file?.type,
        lastModified: file?.lastModified,
        newName: uuidv4(),
        params: {
          bimroomId: "",
          revitVersion: ""
        }
      };
      // CHANGE TO REVIT PROCESSED
      try {
        uploadFileToBlob(file, newFile?.newName, "revit-processed").then(res => {
          if (res.status === 200) {
            setUploadedRevitProcessed((prevFiles) => [...prevFiles, newFile]);
          }
        });
      } catch (error) {
        console.error(error);
      }

    });
  };

  const handleDropRevitFiles = (newFiles) => {
    setRevitFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleRemoveRevitFiles = (file) => {
    setRevitFiles((prevFiles) => prevFiles.filter((_file) => _file.path !== file.path));
  };

  const handleRemoveAllRevitFiles = () => {
    setRevitFiles([]);
  };

  const handleUploadRevitFiles = () => {
    revitFiles.map((file) => {

      const newFile = {
        name: file?.name,
        size: file?.size,
        type: file?.type,
        lastModified: file?.lastModified,
        newName: uuidv4(),
        params: {
          bimroomId: "",
          revitVersion: ""
        }
      };
      // CHANGE TO REVIT FILES
      try {
        uploadFileToBlob(file, newFile?.newName, "rvt").then(res => {
          if (res.status === 200) {
            setUploadedRevitFiles((prevFiles) => [...prevFiles, newFile]);
          }
        });
      } catch (error) {
        console.error(error);
      }
    });
  };

  const handleDropOtherBimFormats = (newFiles) => {
    setOtherBimFormats((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleRemoveOtherBimFormats = (file) => {
    setOtherBimFormats((prevFiles) => prevFiles.filter((_file) => _file.path !== file.path));
  };

  const handleRemoveAllOtherBimFormats = () => {
    setOtherBimFormats([]);
  };

  const handleUploadOtherBimFormats = () => {
    otherBimFormats.map((file) => {

      const newFile = {
        name: file?.name,
        size: file?.size,
        type: file?.type,
        lastModified: file?.lastModified,
        newName: uuidv4(),
        params: {}
      };
      // CHANGE TO REVIT FILES
      try {
        uploadFileToBlob(file, newFile?.newName, "other").then(res => {
          if (res.status === 200) {
            setUploadedOtherBimFormats((prevFiles) => [...prevFiles, newFile]);
          }
        });
      } catch (error) {
        console.error(error);
      }
    });
  };

  const handleDeleteRenders = (name) => {
    let imageArray = formik.values.renders;
    const objIndex = imageArray.findIndex((obj => obj.newName == name));
    imageArray[objIndex].deleted = true;
    formik.setFieldValue("renders", imageArray)
  }

  const handleDeleteGlb = (name) => {
    let imageArray = formik.values.glbs;
    const objIndex = imageArray.findIndex((obj => obj.newName == name));
    imageArray[objIndex].deleted = true;
    formik.setFieldValue("glbs", imageArray)
  }

  const handleDeleteRevitProcessed = (name) => {
    let imageArray = formik.values.revitProcessed;
    const objIndex = imageArray.findIndex((obj => obj.newName == name));
    imageArray[objIndex].deleted = true;
    formik.setFieldValue("revitProcessed", imageArray)
  }

  const handleDeleteRevitFiles = (name) => {
    let imageArray = formik.values.revitFiles;
    const objIndex = imageArray.findIndex((obj => obj.newName == name));
    imageArray[objIndex].deleted = true;
    formik.setFieldValue("revitFiles", imageArray)
  }

  const handleDeleteOtherBimFormats = (name) => {
    let imageArray = formik.values.otherBimFormats;
    const objIndex = imageArray.findIndex((obj => obj.newName == name));
    imageArray[objIndex].deleted = true;
    formik.setFieldValue("otherBimFormats", imageArray)
  }

  const handleDeleteImages = (name) => {
    let imageArray = formik.values.images;
    const objIndex = imageArray.findIndex((obj => obj.newName == name));
    imageArray[objIndex].deleted = true;
    formik.setFieldValue("images", imageArray)
  }





  return (
    <>
      <form
      onSubmit={formik.handleSubmit}
      // {...other}
      >
          <Card sx={{mt: 2}}>
            <CardContent>
              <Grid
                container
                spacing={3}
                sx={{mt: 1}}
              >
                <Grid item md={3} xs={12}>
                  <Typography color="textSecondary" variant="subtitle2" sx={{ mt: 1 }}>
                    New status
                  </Typography>
                </Grid>
                <Grid item md={9} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                    <Select
                      labelId="simple-select-label"
                      label="Status"
                      name="status"
                      value={formik.values.status}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    >
                      {/* <MenuItem value={""}></MenuItem> */}
                      <MenuItem value={"published"}>Published</MenuItem>
                      <MenuItem value={"processed"}>Processed</MenuItem>
                      <MenuItem value={"pending"}>Pending</MenuItem>
                      <MenuItem value={"draft"}>Draft</MenuItem>
                      <MenuItem value={"deleted"}>Deleted</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card sx={{ mt: 2 }}>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={3} xs={12}>
                  <Typography variant="h6">Render images</Typography>
                  <Typography color="textSecondary" variant="subtitle2" sx={{ mt: 1 }}>
                    Accepted file types: png, jpg, jpeg, webp, tiff
                  </Typography>
                  <Typography color="textSecondary" variant="body2" sx={{ mt: 1 }}>
                    Upload renders.
                  </Typography>
                </Grid>
                <Grid item md={9} xs={12}>
                  <FileDropzone
                    accept={{
                      "image/png": [".png"],
                      "image/webp": [".webp"]
                    }}
                    files={renders}
                    onDrop={handleDrop}
                    onRemove={handleRemove}
                    onRemoveAll={handleRemoveAll}
                    onUpload={handleUpload}
                  />
                  <FormikProvider value={formik}>
                    <FieldArray 
                      name="renders" 
                      render={() => (
                        <div>
                          <Grid container spacing={1} sx={{mt: 1}}>
                          {formik.values.renders.map((p) => {
                            return (
                              !p.deleted && <Grid key={p.newName} item xs={4} >
                                <Grid container spacing={1}>
                                  <Grid item xs={9} >
                                    <Typography sx={{
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis"
                                    }} variant="subtitle2">{p.name}</Typography>
                                  </Grid>
                                  <Grid item xs={3}>
                                    <Button 
                                    sx={{py: 0}} 
                                    align="right" 
                                    size="small" 
                                    variant="text" 
                                    color="error"
                                    onClick={() => handleDeleteRenders(p?.newName)}
                                    >
                                      Delete
                                    </Button>
                                  </Grid>
                                </Grid>
                                <Box sx={{
                                  height: 150, 
                                  width: "100%",
                                  border: "1px solid #ccc", 
                                  borderRadius: 1,
                                  //p: 2,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center"
                                  }}>
                                    <Box sx={{
                                      p: 1, 
                                      height: "100%",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center"
                                    }}>                           
                                      <img style={{maxHeight: "100%", maxWidth: "100%"}} src={`https://cdn.bimroom.com/renders/${p?.newName}`} alt=""/>
                                    </Box>
                                </Box>
                              </Grid>
                            );
                          })}
                          </Grid>
                        </div>
                      )}
                    />
                  </FormikProvider>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card sx={{ mt: 2 }}>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={3} xs={12}>
                  <Typography variant="h6">Product images</Typography>
                  <Typography color="textSecondary" variant="subtitle2" sx={{ mt: 1 }}>
                    Accepted file types: png, jpg, jpeg, webp, tiff
                  </Typography>
                  <Typography color="textSecondary" variant="body2" sx={{ mt: 1 }}>
                    Upload product images.
                  </Typography>
                </Grid>
                <Grid item md={9} xs={12}>
                  <FileDropzone
                    accept={{
                      "image/png": [".png"],
                      "image/jpeg": [".jpg", ".jpeg"],
                      "image/webp": [".webp"],
                      "image/tiff": [".tiff"]
                    }}
                    files={images}
                    onDrop={handleDropImages}
                    onRemove={handleRemoveImages}
                    onRemoveAll={handleRemoveAllImages}
                    onUpload={handleUploadImages}
                  />
                  <FormikProvider value={formik}>
                    <FieldArray 
                      name="images" 
                      render={() => (
                        <div>
                          <Grid container spacing={1} sx={{mt: 1}}>
                          {formik.values.images.map((p) => {
                            return (
                              !p.deleted && <Grid key={p.newName} item xs={4} >
                                <Grid container spacing={1}>
                                  <Grid item xs={9} >
                                    <Typography sx={{
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis"
                                    }} variant="subtitle2">{p.name}</Typography>
                                  </Grid>
                                  <Grid item xs={3}>
                                    <Button 
                                    sx={{py: 0}} 
                                    align="right" 
                                    size="small" 
                                    variant="text" 
                                    color="error"
                                    onClick={() => handleDeleteImages(p?.newName)}
                                    >
                                      Delete
                                    </Button>
                                  </Grid>
                                </Grid>
                                <Box sx={{
                                  height: 150, 
                                  width: "100%",
                                  border: "1px solid #ccc", 
                                  borderRadius: 1,
                                  //p: 2,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center"
                                  }}>
                                    <Box sx={{
                                      p: 1, 
                                      height: "100%",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center"
                                    }}>                           
                                      <img style={{maxHeight: "100%", maxWidth: "100%"}} src={`https://cdn.bimroom.com/image/${p?.newName}`} alt=""/>
                                    </Box>
                                </Box>
                              </Grid>
                            );
                          })}
                          </Grid>
                        </div>
                      )}
                    />
                  </FormikProvider>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card sx={{ mt: 2 }}>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={3} xs={12}>
                  <Typography variant="h6">3D objects</Typography>
                  <Typography color="textSecondary" variant="subtitle2" sx={{ mt: 1 }}>
                    Accepted file types: glb, gltf
                  </Typography>
                  <Typography color="textSecondary" variant="body2" sx={{ mt: 1 }}>
                    Upload 3d objects.
                  </Typography>
                </Grid>
                <Grid item md={9} xs={12}>
                  <FileDropzone
                    // accept={{
                    //   "image/png": [".png"],
                    //   "image/jpeg": [".jpg", ".jpeg"],
                    //   "image/webp": [".webp"],
                    //   "image/tiff": [".tiff"]
                    // }}
                    files={glbs}
                    onDrop={handleDropGlb}
                    onRemove={handleRemoveGlb}
                    onRemoveAll={handleRemoveAllGlb}
                    onUpload={handleUploadGlb}
                  />
                  <FormikProvider value={formik}>
                    <FieldArray 
                      name="glbs" 
                      render={() => (
                        <div>
                          <Grid container spacing={1} sx={{mt: 1}}>
                          {formik.values.glbs.map((p) => {
                            return (
                              !p.deleted && <Grid key={p.newName} item xs={4} >
                                <Grid container spacing={1}>
                                  <Grid item xs={9} >
                                    <Typography sx={{
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis"
                                    }} variant="subtitle2">{p.name}</Typography>
                                  </Grid>
                                  <Grid item xs={3}>
                                    <Button 
                                    sx={{py: 0}} 
                                    align="right" 
                                    size="small" 
                                    variant="text" 
                                    color="error"
                                    onClick={() => handleDeleteGlb(p?.newName)}
                                    >
                                      Delete
                                    </Button>
                                  </Grid>
                                </Grid>
                                <Box sx={{
                                  height: 150, 
                                  width: "100%",
                                  border: "1px solid #ccc", 
                                  borderRadius: 1,
                                  //p: 2,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center"
                                  }}>
                                    <Box sx={{
                                      p: 1, 
                                      height: "100%",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center"
                                    }}>                           
                                      {/* <img style={{maxHeight: "100%", maxWidth: "100%"}} src={`https://cdn.bimroom.com/renders/${p?.newName}`} alt=""/> */}
                                      <Canvas camera={{ fov: 50 }}>
                                        <Suspense fallback={null}>
                                          <Center alignTop={false}>
                                            <Stage 
                                              environment={null} 
                                              intensity={0.4} 
                                              //environment="warehouse"
                                              //contactShadowOpacity={0.1} 
                                              contactShadow={false}
                                              shadowBias={-0.0015}
                                              //adjustCamera={true}
                                            >
                                              <Environment
                                                background={false} // Whether to affect scene.background
                                                files={'industrial_pipe_and_valve_02_2k.hdr'}
                                                path={'/assets/'}
                                              />
                                              <Image3D image={p?.newName} />
                                            </Stage>
                                          </Center>
                                        </Suspense>
                                        <OrbitControls autoRotate enableZoom={true} enablePan={true} />
                                      </Canvas>
                                    </Box>
                                </Box>
                              </Grid>
                            );
                          })}
                          </Grid>
                        </div>
                      )}
                    />
                  </FormikProvider>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card sx={{ mt: 2 }}>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={3} xs={12}>
                  <Typography variant="h6">Processed Revit files</Typography>
                  <Typography color="textSecondary" variant="subtitle2" sx={{ mt: 1 }}>
                    Accepted file type: rfa, rvt, rte
                  </Typography>
                  <Typography color="textSecondary" variant="body2" sx={{ mt: 1 }}>
                    Upload processed Revit files.
                  </Typography>
                </Grid>
                <Grid item md={9} xs={12}>
                  <FileDropzone
                    accept={{"": [".rfa", ".rvt", ".rte"]}}
                    files={revitProcessed}
                    onDrop={handleDropRevitProcessed}
                    onRemove={handleRemoveRevitProcessed}
                    onRemoveAll={handleRemoveAllRevitProcessed}
                    onUpload={handleUploadRevitProcessed}
                  />
                  <FormikProvider value={formik}>
                    <FieldArray name="revitProcessed">
                      {() => (
                        <div>
                          {formik.values.revitProcessed.map((p, index) => {
                            const name = `revitProcessed[${index}].name`;
                            const info = `revitProcessed[${index}].info`;
                            const revitVersion = `revitProcessed[${index}].params.revitVersion`;
                            const bimroomId = `revitProcessed[${index}].params.bimroomId`; 

                            return (
                              !p.deleted &&  <Box key={p.newName}>
                                <Grid container spacing={1}>
                                  <Grid item xs={4}>
                                    <TextField
                                      margin="dense"
                                      variant="outlined"
                                      label="File name"
                                      name={name}
                                      value={p.name}
                                      disabled
                                      fullWidth
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                    />
                                  </Grid>
                                  {/* <Grid item xs={6}>
                                    <TextField
                                      margin="dense"
                                      variant="outlined"
                                      label="Info"
                              
                                      name={info}
                                      value={p.info}
                                      fullWidth
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                    />
                                  </Grid> */}
                                  <Grid item xs={3}>
                                    <TextField
                                      margin="dense"
                                      variant="outlined"
                                      label="Revit version"
                              
                                      name={revitVersion}
                                      value={p?.params?.revitVersion}
                                      fullWidth
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                    />
                                  </Grid>
                                  <Grid item xs={3}>
                                    <TextField
                                      margin="dense"
                                      variant="outlined"
                                      label="Bimroom ID"
                              
                                      name={bimroomId}
                                      value={p?.params?.bimroomId}
                                      fullWidth
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                    />
                                  </Grid>
                                  <Grid item xs={2} sx={{
                                    display: "flex",
                                    alignContent: "center",
                                    justifyContent: "center"
                                  }}>
                                    <Button sx={{my: 1}} size="large" color="error" fullWidth onClick={() => handleDeleteRevitProcessed(p?.newName)}>Delete</Button>
                                  </Grid>
                                </Grid>
                              </Box>
                            );
                          })}
                        </div>
                      )}
                    </FieldArray>
                  </FormikProvider>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card sx={{ mt: 2 }}>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={3} xs={12}>
                  <Typography variant="h6">Revit files</Typography>
                  <Typography color="textSecondary" variant="subtitle2" sx={{ mt: 1 }}>
                    Accepted file types: rfa, rvt, rte
                  </Typography>
                  <Typography color="textSecondary" variant="body2" sx={{ mt: 1 }}>
                    Upload Revit files
                  </Typography>
                </Grid>
                <Grid item md={9} xs={12}>
                  <FileDropzone
                    accept={{"": [".rfa", ".rvt", ".rte"]}}
                    files={revitFiles}
                    onDrop={handleDropRevitFiles}
                    onRemove={handleRemoveRevitFiles}
                    onRemoveAll={handleRemoveAllRevitFiles}
                    onUpload={handleUploadRevitFiles}
                  />
                  <FormikProvider value={formik}>
                    <FieldArray name="revitFiles">
                      {() => (
                        <div>
                          {formik.values.revitFiles.map((p, index) => {
                            const name = `revitFiles[${index}].name`;
                            const info = `revitFiles[${index}].info`;

                            return (
                              !p.deleted &&  <Box key={p.newName}>
                                <Grid container spacing={1}>
                                  <Grid item xs={6}>
                                    <TextField
                                      margin="dense"
                                      variant="outlined"
                                      label="File name"
                                      name={name}
                                      value={p.name}
                                      disabled
                                      fullWidth
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                    />
                                  </Grid>
                                  <Grid item xs={4}>
                                    <TextField
                                      margin="dense"
                                      variant="outlined"
                                      label="Info"
                              
                                      name={info}
                                      value={p.info}
                                      fullWidth
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                    />
                                  </Grid> 
                                  {/* <Grid item xs={3}>
                                    <TextField
                                      margin="dense"
                                      variant="outlined"
                                      label="Revit version"
                              
                                      name={revitVersion}
                                      value={p?.params?.revitVersion}
                                      fullWidth
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                    />
                                  </Grid>
                                  <Grid item xs={3}>
                                    <TextField
                                      margin="dense"
                                      variant="outlined"
                                      label="Bimroom ID"
                              
                                      name={bimroomId}
                                      value={p?.params?.bimroomId}
                                      fullWidth
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                    />
                                  </Grid> */}
                                  <Grid item xs={2} sx={{
                                    display: "flex",
                                    alignContent: "center",
                                    justifyContent: "center"
                                  }}>
                                    <Button sx={{my: 1}} size="large" color="error" fullWidth onClick={() => handleDeleteRevitFiles(p?.newName)}>Delete</Button>
                                  </Grid>
                                </Grid>
                              </Box>
                            );
                          })}
                        </div>
                      )}
                    </FieldArray>
                  </FormikProvider>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card sx={{ mt: 2 }}>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={3} xs={12}>
                  <Typography variant="h6">Other BIM formats</Typography>
                  <Typography color="textSecondary" variant="body2" sx={{ mt: 1 }}>
                    Upload all other BIM formats.
                  </Typography>
                </Grid>
                <Grid item md={9} xs={12}>
                  <FileDropzone
                  // accept="image/*"
                    files={otherBimFormats}
                    onDrop={handleDropOtherBimFormats}
                    onRemove={handleRemoveOtherBimFormats}
                    onRemoveAll={handleRemoveAllOtherBimFormats}
                    onUpload={handleUploadOtherBimFormats}
                  />
                  <FormikProvider value={formik}>
                    <FieldArray name="otherBimFormats">
                      {() => (
                        <div>
                          {formik.values.otherBimFormats.map((p, index) => {
                            const name = `otherBimFormats[${index}].name`;
                            const info = `otherBimFormats[${index}].info`;

                            return (
                              !p.deleted && <Box key={p.newName}>
                                <Grid container spacing={1}>
                                  <Grid item xs={6}>
                                    <TextField
                                      margin="dense"
                                      variant="outlined"
                                      label="File name"
                                      name={name}
                                      value={p.name}
                                      disabled
                                      fullWidth
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                    />
                                  </Grid>
                                  <Grid item xs={4}>
                                    <TextField
                                      margin="dense"
                                      variant="outlined"
                                      label="Info"
                                      name={info}
                                      value={p.info}
                                      fullWidth
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                    />
                                  </Grid>
                                  <Grid item xs={2} sx={{
                                    display: "flex",
                                    alignContent: "center",
                                    justifyContent: "center"
                                  }}>
                                    <Button sx={{my: 1}} size="large" color="error" fullWidth onClick={() => handleDeleteOtherBimFormats(p?.newName)}>Delete</Button>
                                  </Grid>
                                </Grid>
                              </Box>
                            );
                          })}
                        </div>
                      )}
                    </FieldArray>
                  </FormikProvider>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          {/* <Card sx={{ mt: 2 }}>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={3} xs={12}>
                  <Typography variant="h6">Other files</Typography>
                  <Typography color="textSecondary" variant="body2" sx={{ mt: 1 }}>
                    Upload documentation and other files formats.
                  </Typography>
                </Grid>
                <Grid item md={9} xs={12}>
                  <FileDropzone
                    
                    files={documentation}
                    onDrop={handleDrop4}
                    onRemove={handleRemove4}
                    onRemoveAll={handleRemoveAll4}
                    onUpload={handleUpload4}
                  />
                  <FormikProvider value={formik}>
                    <FieldArray name="documentation">
                      {() => (
                        <div>
                          {formik.values.documentation.map((p, index) => {
                            const name = `documentation[${index}].name`;
                            const info = `documentation[${index}].info`;

                            return (
                              !p?.deleted && <Box key={p.newName}>
                                <Grid container spacing={1}>
                                  <Grid item xs={4}>
                                    <TextField
                                      margin="dense"
                                      variant="outlined"
                                      label="File name"
                                      name={name}
                                      value={p.name}
                                      disabled
                                      fullWidth
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                    />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <TextField
                                      margin="dense"
                                      variant="outlined"
                                      label="Info"
                              
                                      name={info}
                                      value={p.info}
                                      fullWidth
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                    />
                                  </Grid>
                                  <Grid item xs={2} sx={{
                                    display: "flex",
                                    alignContent: "center",
                                    justifyContent: "center"
                                  }}>
                                    <Button sx={{my: 1}} size="large" color="error" fullWidth onClick={() => handleDeleteDocumentation(p?.newName)}>Delete</Button>
                                  </Grid>
                                </Grid>
                              </Box>
                            );
                          })}
                        </div>
                      )}
                    </FieldArray>
                  </FormikProvider>
                </Grid>
              </Grid>
            </CardContent>
          </Card> */}
          <Button sx={{ mt: 2 }} variant="contained" startIcon={<PlusIcon />} type="submit">
            Update
          </Button>
      </form>
    </>
  );
};
