import { useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

export default function Image3D({ image }) {
  try {
    const gltf = useLoader(
      GLTFLoader,
      `https://cdn.bimroom.com/glbs/${image}`
    );
  
    return (
      <>
        <primitive object={gltf.scene} scale={0.8} />
      </>
    );
  } catch (error) {
    console.error(error);
    // Expected output: ReferenceError: nonExistentFunction is not defined
    // (Note: the exact output may be browser-dependent)
  }
  
}
