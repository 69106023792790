export const dataSchema = (params) => {
    const { formik, parameterClass, unitSystem } = params;

    const items = [
      {
        label: "Sound reduction index",
        name: "details.soundReductionIndex.value",
        type: "Number",
        multipleUnits: false,
        unitSystem: "default",
        unit: "dB",
        minMaxOption: true,
        // categories: ["doors", "windows","construction","hvac - ventilation"],
        categories: ["doors", "windows","construction","hvac"],
        standardOptions: ["ISO 16283"],
        etimEquivalent: ["EF017317","EF017405"],
        parameterClass: "Acoustic",
        description: "Level of sound insulation provided by the product.",
        value: formik.values.details.soundReductionIndex.value,
				values: formik.values.details.soundReductionIndex.values,
        values: formik.values.details.soundReductionIndex.values,
        standardValue: formik.values.details.soundReductionIndex.standard,
        standardName: "details.soundReductionIndex.standard",
        minValue: formik.values.details.soundReductionIndex.min,
        maxValue: formik.values.details.soundReductionIndex.max,
        minName: "details.soundReductionIndex.min",
        maxName: "details.soundReductionIndex.max",
        unitValue: formik.values.details.soundReductionIndex.unit,
        unitName: "details.soundReductionIndex.unit",
        rangeValue: formik.values.details.soundReductionIndex.range,
        rangeName: "details.soundReductionIndex.range"
      },
      {
        label: "Colour",
        name: "details.colour.value",
        type: "String",
        multipleUnits: false,
        unitSystem: "default",
        minMaxOption: false,
        etimEquivalent: ["EF000007","EF004269","EF007168"],
        parameterClass: "Appearance",
        description: "Appearance colour(s) of the product.",
        value: formik.values.details.colour.value,
				values: formik.values.details.colour.values,
        standardValue: formik.values.details.colour.standard,
        standardName: "details.colour.standard",
        standardOptions: []
      },
      {
        label: "Certificates",
        name: "details.certificates.value",
        type: "String",
        multipleUnits: false,
        unitSystem: "default",
        minMaxOption: false,
        etimEquivalent: ["EF010077","EF010188"],
        parameterClass: "Approvals",
        description: "Certificates of performance and approval received by the product.",
        value: formik.values.details.certificates.value,
				values: formik.values.details.certificates.values,
        standardValue: formik.values.details.certificates.standard,
        standardName: "details.certificates.standard",
        standardOptions: []
      },
      {
        label: "Testing",
        name: "details.testing.value",
        type: "String",
        multipleUnits: false,
        unitSystem: "default",
        minMaxOption: false,
        standardOptions: ["CE"],
        etimEquivalent: ["EF017100","EF017101","EF017102","EF017103","EF017104","EF017105","EF017109","EF017768","EF017769","EF017770","EF017771","EF017772","EF017773","EFDE0067","EFDE0068","EFDE0069","EFDE0070","EFDE0071","EFDE0099","EF013102","EF014931","EF014932","EF014933","EF015548","EF016858","EF016985","EF018138","EFDE0001","EFDE0007","EFDE0055","EFDE0075","EFDE0076"],
        parameterClass: "Approvals",
        description: "Proof of testing, such as the CE marking.",
        value: formik.values.details.testing.value,
				values: formik.values.details.testing.values,
        standardValue: formik.values.details.testing.standard,
        standardName: "details.testing.standard"
      },
      {
        label: "Number of Leafs",
        name: "details.numberOfLeafs.value",
        type: "Integer",
        multipleUnits: false,
        unitSystem: "default",
        minMaxOption: true,
        categories: ["doors"],
        etimEquivalent: "EF013067",
        parameterClass: "Construction",
        value: formik.values.details.numberOfLeafs.value,
				values: formik.values.details.numberOfLeafs.values,
        standardValue: formik.values.details.numberOfLeafs.standard,
        standardName: "details.numberOfLeafs.standard",
        standardOptions: [],
        minValue: formik.values.details.numberOfLeafs.min,
        maxValue: formik.values.details.numberOfLeafs.max,
        minName: "details.numberOfLeafs.min",
        maxName: "details.numberOfLeafs.max",
        rangeValue: formik.values.details.numberOfLeafs.range,
        rangeName: "details.numberOfLeafs.range"
      },
      {
        label: "Muntins",
        name: "details.muntins.value",
        type: "Menu",
        menuOptions: ["Yes","No","Optional"],
        multipleUnits: false,
        unitSystem: "default",
        minMaxOption: false,
        categories: ["doors", "windows"],
        parameterClass: "Construction",
        description: "Visual or structural subdivision of window panes with muntins.",
        value: formik.values.details.muntins.value,
				values: formik.values.details.muntins.values,
        standardValue: formik.values.details.muntins.standard,
        standardName: "details.muntins.standard",
        standardOptions: []
      },
      {
        label: "Vertical muntins max",
        name: "details.verticalMuntinsMax.value",
        type: "Integer",
        multipleUnits: false,
        unitSystem: "default",
        minMaxOption: false,
        categories: ["doors", "windows"],
        parameterClass: "Construction",
        value: formik.values.details.verticalMuntinsMax.value,
				values: formik.values.details.verticalMuntinsMax.values,
        standardValue: formik.values.details.verticalMuntinsMax.standard,
        standardName: "details.verticalMuntinsMax.standard",
        standardOptions: []
      },
      {
        label: "Horizontal muntins max",
        name: "details.horizontalMuntinsMax.value",
        type: "Integer",
        multipleUnits: false,
        unitSystem: "default",
        minMaxOption: false,
        categories: ["doors", "windows"],
        parameterClass: "Construction",
        value: formik.values.details.horizontalMuntinsMax.value,
				values: formik.values.details.horizontalMuntinsMax.values,
        standardValue: formik.values.details.horizontalMuntinsMax.standard,
        standardName: "details.horizontalMuntinsMax.standard",
        standardOptions: []
      },
      {
        label: "Number of seats",
        name: "details.numberOfSeats.value",
        type: "Integer",
        multipleUnits: false,
        unitSystem: "default",
        minMaxOption: true,
        categories: ["furniture"],
        etimEquivalent: "EF024887",
        parameterClass: "Construction",
        value: formik.values.details.numberOfSeats.value,
				values: formik.values.details.numberOfSeats.values,
        standardValue: formik.values.details.numberOfSeats.standard,
        standardName: "details.numberOfSeats.standard",
        standardOptions: [],
        minValue: formik.values.details.numberOfSeats.min,
        maxValue: formik.values.details.numberOfSeats.max,
        minName: "details.numberOfSeats.min",
        maxName: "details.numberOfSeats.max",
        rangeValue: formik.values.details.numberOfSeats.range,
        rangeName: "details.numberOfSeats.range"
      },
      {
        label: "Extendable",
        name: "details.extendable.value",
        type: "Menu",
        menuOptions: ["Yes","No"],
        multipleUnits: false,
        unitSystem: "default",
        minMaxOption: false,
        categories: ["furniture"],
        etimEquivalent: "EF000329",
        parameterClass: "Construction",
        value: formik.values.details.extendable.value,
				values: formik.values.details.extendable.values,
        standardValue: formik.values.details.extendable.standard,
        standardName: "details.extendable.standard",
        standardOptions: []
      },
      {
        label: "Foldable",
        name: "details.foldable.value",
        type: "Menu",
        menuOptions: ["Yes","No"],
        multipleUnits: false,
        unitSystem: "default",
        minMaxOption: false,
        categories: ["furniture"],
        etimEquivalent: "EF000643",
        parameterClass: "Construction",
        value: formik.values.details.foldable.value,
				values: formik.values.details.foldable.values,
        standardValue: formik.values.details.foldable.standard,
        standardName: "details.foldable.standard",
        standardOptions: []
      },
      {
        label: "Stackable",
        name: "details.stackable.value",
        type: "Menu",
        menuOptions: ["Yes","No"],
        multipleUnits: false,
        unitSystem: "default",
        minMaxOption: false,
        categories: ["furniture"],
        parameterClass: "Construction",
        value: formik.values.details.stackable.value,
				values: formik.values.details.stackable.values,
        standardValue: formik.values.details.stackable.standard,
        standardName: "details.stackable.standard",
        standardOptions: []
      },
      {
        label: "Weatherproof",
        name: "details.weatherproof.value",
        type: "Menu",
        menuOptions: ["Yes","No"],
        multipleUnits: false,
        unitSystem: "default",
        minMaxOption: false,
        categories: ["furniture"],
        parameterClass: "Construction",
        value: formik.values.details.weatherproof.value,
				values: formik.values.details.weatherproof.values,
        standardValue: formik.values.details.weatherproof.standard,
        standardName: "details.weatherproof.standard",
        standardOptions: []
      },
      {
        label: "Number of sashes",
        name: "details.numberOfSashes.value",
        type: "Integer",
        multipleUnits: false,
        unitSystem: "default",
        minMaxOption: true,
        categories: ["windows"],
        parameterClass: "Construction",
        description: "Number of separate window panes.",
        value: formik.values.details.numberOfSashes.value,
				values: formik.values.details.numberOfSashes.values,
        standardValue: formik.values.details.numberOfSashes.standard,
        standardName: "details.numberOfSashes.standard",
        standardOptions: [],
        minValue: formik.values.details.numberOfSashes.min,
        maxValue: formik.values.details.numberOfSashes.max,
        minName: "details.numberOfSashes.min",
        maxName: "details.numberOfSashes.max",
        rangeValue: formik.values.details.numberOfSashes.range,
        rangeName: "details.numberOfSashes.range"
      },
      {
        label: "Depth with lid fully open",
        name: "details.depthWithLidFullyOpen.value",
        type: "Number",
        multipleUnits: true,
        unitSystem: "metric",
        unitOptions: ["mm","cm"],
        minMaxOption: true,
        //categories: ["electronics - appliances"],
        categories: ["electronics"],
        parameterClass: "Dimensions",
        minValue: formik.values.details.depthWithLidFullyOpen.min,
        maxValue: formik.values.details.depthWithLidFullyOpen.max,
        minName: "details.depthWithLidFullyOpen.min",
        maxName: "details.depthWithLidFullyOpen.max",
        value: formik.values.details.depthWithLidFullyOpen.value,
				values: formik.values.details.depthWithLidFullyOpen.values,
        standardValue: formik.values.details.depthWithLidFullyOpen.standard,
        standardName: "details.depthWithLidFullyOpen.standard",
        standardOptions: [],
        unitValue: formik.values.details.depthWithLidFullyOpen.unit,
        unitName: "details.depthWithLidFullyOpen.unit",
        rangeValue: formik.values.details.depthWithLidFullyOpen.range,
        rangeName: "details.depthWithLidFullyOpen.range"
      },
      {
        label: "Daylight Width",
        name: "details.daylightWidth.value",
        type: "Number",
        multipleUnits: true,
        unitSystem: "metric",
        unitOptions: ["mm","cm"],
        minMaxOption: true,
        categories: ["doors", "windows"],
        parameterClass: "Dimensions",
        description: "Width inside the frame.",
        value: formik.values.details.daylightWidth.value,
				values: formik.values.details.daylightWidth.values,
        standardValue: formik.values.details.daylightWidth.standard,
        standardName: "details.daylightWidth.standard",
        standardOptions: [],
        minValue: formik.values.details.daylightWidth.min,
        maxValue: formik.values.details.daylightWidth.max,
        minName: "details.daylightWidth.min",
        maxName: "details.daylightWidth.max",
        unitValue: formik.values.details.daylightWidth.unit,
        unitName: "details.daylightWidth.unit",
        rangeValue: formik.values.details.daylightWidth.range,
        rangeName: "details.daylightWidth.range"
      },
      {
        label: "Daylight Height",
        name: "details.daylightHeight.value",
        type: "Number",
        multipleUnits: true,
        unitSystem: "metric",
        unitOptions: ["mm","cm"],
        minMaxOption: true,
        categories: ["doors", "windows"],
        parameterClass: "Dimensions",
        description: "Height inside the frame.",
        value: formik.values.details.daylightHeight.value,
				values: formik.values.details.daylightHeight.values,
        standardValue: formik.values.details.daylightHeight.standard,
        standardName: "details.daylightHeight.standard",
        standardOptions: [],
        minValue: formik.values.details.daylightHeight.min,
        maxValue: formik.values.details.daylightHeight.max,
        minName: "details.daylightHeight.min",
        maxName: "details.daylightHeight.max",
        unitValue: formik.values.details.daylightHeight.unit,
        unitName: "details.daylightHeight.unit",
        rangeValue: formik.values.details.daylightHeight.range,
        rangeName: "details.daylightHeight.range"
      },
      {
        label: "Frame Width",
        name: "details.frameWidth.value",
        type: "Number",
        multipleUnits: true,
        unitSystem: "metric",
        unitOptions: ["mm","cm","m"],
        minMaxOption: true,
        categories: ["doors", "windows"],
        etimEquivalent: "EF011817",
        parameterClass: "Dimensions",
        value: formik.values.details.frameWidth.value,
				values: formik.values.details.frameWidth.values,
        standardValue: formik.values.details.frameWidth.standard,
        standardName: "details.frameWidth.standard",
        standardOptions: [],
        minValue: formik.values.details.frameWidth.min,
        maxValue: formik.values.details.frameWidth.max,
        minName: "details.frameWidth.min",
        maxName: "details.frameWidth.max",
        unitValue: formik.values.details.frameWidth.unit,
        unitName: "details.frameWidth.unit",
        rangeValue: formik.values.details.frameWidth.range,
        rangeName: "details.frameWidth.range"
      },
      {
        label: "Frame Height",
        name: "details.frameHeight.value",
        type: "Number",
        multipleUnits: true,
        unitSystem: "metric",
        unitOptions: ["mm","cm","m"],
        minMaxOption: true,
        categories: ["doors", "windows"],
        etimEquivalent: "EF011818",
        parameterClass: "Dimensions",
        value: formik.values.details.frameHeight.value,
				values: formik.values.details.frameHeight.values,
        standardValue: formik.values.details.frameHeight.standard,
        standardName: "details.frameHeight.standard",
        standardOptions: [],
        minValue: formik.values.details.frameHeight.min,
        maxValue: formik.values.details.frameHeight.max,
        minName: "details.frameHeight.min",
        maxName: "details.frameHeight.max",
        unitValue: formik.values.details.frameHeight.unit,
        unitName: "details.frameHeight.unit",
        rangeValue: formik.values.details.frameHeight.range,
        rangeName: "details.frameHeight.range"
      },
      {
        label: "Leaf Thickness",
        name: "details.leafThickness.value",
        type: "Number",
        multipleUnits: true,
        unitSystem: "metric",
        unitOptions: ["mm","cm"],
        minMaxOption: true,
        categories: ["doors"],
        etimEquivalent: "EF011814",
        parameterClass: "Dimensions",
        value: formik.values.details.leafThickness.value,
				values: formik.values.details.leafThickness.values,
        standardValue: formik.values.details.leafThickness.standard,
        standardName: "details.leafThickness.standard",
        standardOptions: [],
        minValue: formik.values.details.leafThickness.min,
        maxValue: formik.values.details.leafThickness.max,
        minName: "details.leafThickness.min",
        maxName: "details.leafThickness.max",
        unitValue: formik.values.details.leafThickness.unit,
        unitName: "details.leafThickness.unit",
        rangeValue: formik.values.details.leafThickness.range,
        rangeName: "details.leafThickness.range"
      },
      {
        label: "Depth with lid fully open",
        name: "details.depthWithLidFullyOpenImp.value",
        type: "String",
        multipleUnits: true,
        unitSystem: "imperial",
        unitOptions: ["in","ft"],
        minMaxOption: true,
        //categories: ["electronics - appliances"],
        categories: ["electronics"],
        parameterClass: "Dimensions",
        minValue: formik.values.details.depthWithLidFullyOpenImp.min,
        maxValue: formik.values.details.depthWithLidFullyOpenImp.max,
        minName: "details.depthWithLidFullyOpenImp.min",
        maxName: "details.depthWithLidFullyOpenImp.max",
        value: formik.values.details.depthWithLidFullyOpenImp.value,
				values: formik.values.details.depthWithLidFullyOpenImp.values,
        standardValue: formik.values.details.depthWithLidFullyOpenImp.standard,
        standardName: "details.depthWithLidFullyOpenImp.standard",
        standardOptions: [],
        unitValue: formik.values.details.depthWithLidFullyOpenImp.unit,
        unitName: "details.depthWithLidFullyOpenImp.unit",
        rangeValue: formik.values.details.depthWithLidFullyOpenImp.range,
        rangeName: "details.depthWithLidFullyOpenImp.range"
      },
      {
        label: "Daylight Width",
        name: "details.daylightWidthImp.value",
        type: "String",
        multipleUnits: true,
        unitSystem: "imperial",
        unitOptions: ["in","ft"],
        minMaxOption: true,
        categories: ["doors", "windows"],
        parameterClass: "Dimensions",
        description: "Width inside the frame.",
        value: formik.values.details.daylightWidthImp.value,
				values: formik.values.details.daylightWidthImp.values,
        standardValue: formik.values.details.daylightWidthImp.standard,
        standardName: "details.daylightWidthImp.standard",
        standardOptions: [],
        minValue: formik.values.details.daylightWidthImp.min,
        maxValue: formik.values.details.daylightWidthImp.max,
        minName: "details.daylightWidthImp.min",
        maxName: "details.daylightWidthImp.max",
        unitValue: formik.values.details.daylightWidthImp.unit,
        unitName: "details.daylightWidthImp.unit",
        rangeValue: formik.values.details.daylightWidthImp.range,
        rangeName: "details.daylightWidthImp.range"
      },
      {
        label: "Daylight Height",
        name: "details.daylightHeightImp.value",
        type: "String",
        multipleUnits: true,
        unitSystem: "imperial",
        unitOptions: ["in","ft"],
        minMaxOption: true,
        categories: ["doors", "windows"],
        parameterClass: "Dimensions",
        description: "Height inside the frame.",
        value: formik.values.details.daylightHeightImp.value,
				values: formik.values.details.daylightHeightImp.values,
        standardValue: formik.values.details.daylightHeightImp.standard,
        standardName: "details.daylightHeightImp.standard",
        standardOptions: [],
        minValue: formik.values.details.daylightHeightImp.min,
        maxValue: formik.values.details.daylightHeightImp.max,
        minName: "details.daylightHeightImp.min",
        maxName: "details.daylightHeightImp.max",
        unitValue: formik.values.details.daylightHeightImp.unit,
        unitName: "details.daylightHeightImp.unit",
        rangeValue: formik.values.details.daylightHeightImp.range,
        rangeName: "details.daylightHeightImp.range"
      },
      {
        label: "Frame Width",
        name: "details.frameWidthImp.value",
        type: "String",
        multipleUnits: true,
        unitSystem: "imperial",
        unitOptions: ["in","ft"],
        minMaxOption: true,
        categories: ["doors", "windows"],
        etimEquivalent: "EF011817",
        parameterClass: "Dimensions",
        value: formik.values.details.frameWidthImp.value,
				values: formik.values.details.frameWidthImp.values,
        standardValue: formik.values.details.frameWidthImp.standard,
        standardName: "details.frameWidthImp.standard",
        standardOptions: [],
        minValue: formik.values.details.frameWidthImp.min,
        maxValue: formik.values.details.frameWidthImp.max,
        minName: "details.frameWidthImp.min",
        maxName: "details.frameWidthImp.max",
        unitValue: formik.values.details.frameWidthImp.unit,
        unitName: "details.frameWidthImp.unit",
        rangeValue: formik.values.details.frameWidthImp.range,
        rangeName: "details.frameWidthImp.range"
      },
      {
        label: "Frame Height",
        name: "details.frameHeightImp.value",
        type: "String",
        multipleUnits: true,
        unitSystem: "imperial",
        unitOptions: ["in","ft"],
        minMaxOption: true,
        categories: ["doors", "windows"],
        etimEquivalent: "EF011818",
        parameterClass: "Dimensions",
        value: formik.values.details.frameHeightImp.value,
				values: formik.values.details.frameHeightImp.values,
        standardValue: formik.values.details.frameHeightImp.standard,
        standardName: "details.frameHeightImp.standard",
        standardOptions: [],
        minValue: formik.values.details.frameHeightImp.min,
        maxValue: formik.values.details.frameHeightImp.max,
        minName: "details.frameHeightImp.min",
        maxName: "details.frameHeightImp.max",
        unitValue: formik.values.details.frameHeightImp.unit,
        unitName: "details.frameHeightImp.unit",
        rangeValue: formik.values.details.frameHeightImp.range,
        rangeName: "details.frameHeightImp.range"
      },
      {
        label: "Leaf Thickness",
        name: "details.leafThicknessImp.value",
        type: "String",
        multipleUnits: true,
        unitSystem: "imperial",
        unitOptions: ["in"],
        minMaxOption: true,
        categories: ["doors"],
        etimEquivalent: "EF011814",
        parameterClass: "Dimensions",
        value: formik.values.details.leafThicknessImp.value,
				values: formik.values.details.leafThicknessImp.values,
        standardValue: formik.values.details.leafThicknessImp.standard,
        standardName: "details.leafThicknessImp.standard",
        standardOptions: [],
        minValue: formik.values.details.leafThicknessImp.min,
        maxValue: formik.values.details.leafThicknessImp.max,
        minName: "details.leafThicknessImp.min",
        maxName: "details.leafThicknessImp.max",
        unitValue: formik.values.details.leafThicknessImp.unit,
        unitName: "details.leafThicknessImp.unit",
        rangeValue: formik.values.details.leafThicknessImp.range,
        rangeName: "details.leafThicknessImp.range"
      },
      
      {
        label: "Nominal size (DN)",
        name: "details.nominalSizeDn.value",
        type: "Number",
        multipleUnits: false,
        unitSystem: "metric",
        unit: "mm",
        minMaxOption: false,
        categories: ["hvac", "plumbing"],
        standardOptions: ["ISO 6708"],
        parameterClass: "Dimensions",
        value: formik.values.details.nominalSizeDn.value,
				values: formik.values.details.nominalSizeDn.values,
        standardValue: formik.values.details.nominalSizeDn.standard,
        standardName: "details.nominalSizeDn.standard",
        unitValue: formik.values.details.nominalSizeDn.unit,
        unitName: "details.nominalSizeDn.unit"
      },
      {
        label: "Nominal size (NPS)",
        name: "details.nominalSizeNps.value",
        type: "Fraction",
        multipleUnits: false,
        unitSystem: "imperial",
        unit: "inches",
        value: formik.values.details.nominalSizeNps.value,
				values: formik.values.details.nominalSizeNps.values,
        standardValue: formik.values.details.nominalSizeNps.standard,
        standardName: "details.nominalSizeNps.standard",
        standardOptions: [],
        unitValue: formik.values.details.nominalSizeNps.unit,
        unitName: "details.nominalSizeNps.unit"
      },
      {
        label: "Length",
        name: "details.length.value",
        type: "Number",
        multipleUnits: true,
        unitSystem: "metric",
        unitOptions: ["mm","cm","m"],
        minMaxOption: true,
        etimEquivalent: ["EF001438","EF005483"],
        parameterClass: "Dimensions",
        value: formik.values.details.length.value,
				values: formik.values.details.length.values,
        standardValue: formik.values.details.length.standard,
        standardName: "details.length.standard",
        standardOptions: [],
        minValue: formik.values.details.length.min,
        maxValue: formik.values.details.length.max,
        minName: "details.length.min",
        maxName: "details.length.max",
        unitValue: formik.values.details.length.unit,
        unitName: "details.length.unit",
        rangeValue: formik.values.details.length.range,
        rangeName: "details.length.range"
      },
      {
        label: "Width",
        name: "details.width.value",
        type: "Number",
        multipleUnits: true,
        unitSystem: "metric",
        unitOptions: ["mm","cm","m"],
        minMaxOption: true,
        etimEquivalent: ["EF000008","EF005452"],
        parameterClass: "Dimensions",
        value: formik.values.details.width.value,
				values: formik.values.details.width.values,
        standardValue: formik.values.details.width.standard,
        standardName: "details.width.standard",
        standardOptions: [],
        minValue: formik.values.details.width.min,
        maxValue: formik.values.details.width.max,
        minName: "details.width.min",
        maxName: "details.width.max",
        unitValue: formik.values.details.width.unit,
        unitName: "details.width.unit",
        rangeValue: formik.values.details.width.range,
        rangeName: "details.width.range"
      },
      {
        label: "Height",
        name: "details.height.value",
        type: "Number",
        multipleUnits: true,
        unitSystem: "metric",
        unitOptions: ["mm","cm","m"],
        minMaxOption: true,
        etimEquivalent: ["EF000040","EF005454"],
        parameterClass: "Dimensions",
        value: formik.values.details.height.value,
				values: formik.values.details.height.values,
        standardValue: formik.values.details.height.standard,
        standardName: "details.height.standard",
        standardOptions: [],
        minValue: formik.values.details.height.min,
        maxValue: formik.values.details.height.max,
        minName: "details.height.min",
        maxName: "details.height.max",
        unitValue: formik.values.details.height.unit,
        unitName: "details.height.unit",
        rangeValue: formik.values.details.height.range,
        rangeName: "details.height.range"
      },
      {
        label: "Thickness",
        name: "details.thickness.value",
        type: "Number",
        multipleUnits: true,
        unitSystem: "metric",
        unitOptions: ["mm","cm","m"],
        minMaxOption: true,
        etimEquivalent: "EF000125",
        parameterClass: "Dimensions",
        value: formik.values.details.thickness.value,
				values: formik.values.details.thickness.values,
        standardValue: formik.values.details.thickness.standard,
        standardName: "details.thickness.standard",
        standardOptions: [],
        minValue: formik.values.details.thickness.min,
        maxValue: formik.values.details.thickness.max,
        minName: "details.thickness.min",
        maxName: "details.thickness.max",
        unitValue: formik.values.details.thickness.unit,
        unitName: "details.thickness.unit",
        rangeValue: formik.values.details.thickness.range,
        rangeName: "details.thickness.range"
      },
      {
        label: "Diameter",
        name: "details.diameter.value",
        type: "Number",
        multipleUnits: true,
        unitSystem: "metric",
        unitOptions: ["mm","cm","m"],
        minMaxOption: true,
        etimEquivalent: "EF000551",
        parameterClass: "Dimensions",
        description: "Diameter of circular shaped products.",
        value: formik.values.details.diameter.value,
				values: formik.values.details.diameter.values,
        standardValue: formik.values.details.diameter.standard,
        standardName: "details.diameter.standard",
        standardOptions: [],
        minValue: formik.values.details.diameter.min,
        maxValue: formik.values.details.diameter.max,
        minName: "details.diameter.min",
        maxName: "details.diameter.max",
        unitValue: formik.values.details.diameter.unit,
        unitName: "details.diameter.unit",
        rangeValue: formik.values.details.diameter.range,
        rangeName: "details.diameter.range"
      },
      {
        label: "Radius",
        name: "details.radius.value",
        type: "Number",
        multipleUnits: true,
        unitSystem: "metric",
        unitOptions: ["mm","cm","m"],
        minMaxOption: true,
        etimEquivalent: "EF020621",
        parameterClass: "Dimensions",
        description: "Radius of circular shaped products.",
        value: formik.values.details.radius.value,
				values: formik.values.details.radius.values,
        standardValue: formik.values.details.radius.standard,
        standardName: "details.radius.standard",
        standardOptions: [],
        minValue: formik.values.details.radius.min,
        maxValue: formik.values.details.radius.max,
        minName: "details.radius.min",
        maxName: "details.radius.max",
        unitValue: formik.values.details.radius.unit,
        unitName: "details.radius.unit",
        rangeValue: formik.values.details.radius.range,
        rangeName: "details.radius.range"
      },
      {
        label: "Area",
        name: "details.area.value",
        type: "Number",
        multipleUnits: true,
        unitSystem: "metric",
        unitOptions: ["mm²","cm²","m²"],
        minMaxOption: true,
        etimEquivalent: "EF002228",
        parameterClass: "Dimensions",
        value: formik.values.details.area.value,
				values: formik.values.details.area.values,
        standardValue: formik.values.details.area.standard,
        standardName: "details.area.standard",
        standardOptions: [],
        minValue: formik.values.details.area.min,
        maxValue: formik.values.details.area.max,
        minName: "details.area.min",
        maxName: "details.area.max",
        unitValue: formik.values.details.area.unit,
        unitName: "details.area.unit",
        rangeValue: formik.values.details.area.range,
        rangeName: "details.area.range"
      },
      {
        label: "Volume",
        name: "details.volume.value",
        type: "Number",
        multipleUnits: true,
        unitSystem: "metric",
        unitOptions: ["cm³","m³"],
        minMaxOption: true,
        etimEquivalent: "EF000880",
        parameterClass: "Dimensions",
        value: formik.values.details.volume.value,
				values: formik.values.details.volume.values,
        standardValue: formik.values.details.volume.standard,
        standardName: "details.volume.standard",
        standardOptions: [],
        minValue: formik.values.details.volume.min,
        maxValue: formik.values.details.volume.max,
        minName: "details.volume.min",
        maxName: "details.volume.max",
        unitValue: formik.values.details.volume.unit,
        unitName: "details.volume.unit",
        rangeValue: formik.values.details.volume.range,
        rangeName: "details.volume.range"
      },
      {
        label: "Depth",
        name: "details.depth.value",
        type: "Number",
        multipleUnits: true,
        unitSystem: "metric",
        unitOptions: ["mm","cm","m"],
        minMaxOption: true,
        etimEquivalent: ["EF000049","EF005457"],
        parameterClass: "Dimensions",
        value: formik.values.details.depth.value,
				values: formik.values.details.depth.values,
        standardValue: formik.values.details.depth.standard,
        standardName: "details.depth.standard",
        standardOptions: [],
        minValue: formik.values.details.depth.min,
        maxValue: formik.values.details.depth.max,
        minName: "details.depth.min",
        maxName: "details.depth.max",
        unitValue: formik.values.details.depth.unit,
        unitName: "details.depth.unit",
        rangeValue: formik.values.details.depth.range,
        rangeName: "details.depth.range"
      },
      {
        label: "Length",
        name: "details.lengthImp.value",
        type: "String",
        multipleUnits: true,
        unitSystem: "imperial",
        unitOptions: ["ft", "in","{ft}' {in}\""],
        minMaxOption: true,
        etimEquivalent: ["EF001438","EF005483"],
        parameterClass: "Dimensions",
        value: formik.values.details.lengthImp.value,
				values: formik.values.details.lengthImp.values,
        standardValue: formik.values.details.lengthImp.standard,
        standardName: "details.lengthImp.standard",
        standardOptions: [],
        minValue: formik.values.details.lengthImp.min,
        maxValue: formik.values.details.lengthImp.max,
        minName: "details.lengthImp.min",
        maxName: "details.lengthImp.max",
        unitValue: formik.values.details.lengthImp.unit,
        unitName: "details.lengthImp.unit",
        rangeValue: formik.values.details.lengthImp.range,
        rangeName: "details.lengthImp.range"
      },
      {
        label: "Width",
        name: "details.widthImp.value",
        type: "String",
        multipleUnits: true,
        unitSystem: "imperial",
        unitOptions: ["ft", "in","{ft}' {in}\""],
        minMaxOption: true,
        etimEquivalent: ["EF000008","EF005452"],
        parameterClass: "Dimensions",
        value: formik.values.details.widthImp.value,
				values: formik.values.details.widthImp.values,
        standardValue: formik.values.details.widthImp.standard,
        standardName: "details.widthImp.standard",
        standardOptions: [],
        minValue: formik.values.details.widthImp.min,
        maxValue: formik.values.details.widthImp.max,
        minName: "details.widthImp.min",
        maxName: "details.widthImp.max",
        unitValue: formik.values.details.widthImp.unit,
        unitName: "details.widthImp.unit",
        rangeValue: formik.values.details.widthImp.range,
        rangeName: "details.widthImp.range"
      },
      {
        label: "Height",
        name: "details.heightImp.value",
        type: "String",
        multipleUnits: true,
        unitSystem: "imperial",
        unitOptions: ["ft", "in","{ft}' {in}\""],
        minMaxOption: true,
        etimEquivalent: ["EF000040","EF005454"],
        parameterClass: "Dimensions",
        value: formik.values.details.heightImp.value,
				values: formik.values.details.heightImp.values,
        standardValue: formik.values.details.heightImp.standard,
        standardName: "details.heightImp.standard",
        standardOptions: [],
        minValue: formik.values.details.heightImp.min,
        maxValue: formik.values.details.heightImp.max,
        minName: "details.heightImp.min",
        maxName: "details.heightImp.max",
        unitValue: formik.values.details.heightImp.unit,
        unitName: "details.heightImp.unit",
        rangeValue: formik.values.details.heightImp.range,
        rangeName: "details.heightImp.range"
      },
      {
        label: "Thickness",
        name: "details.thicknessImp.value",
        type: "String",
        multipleUnits: true,
        unitSystem: "imperial",
        unitOptions: ["ft", "in","{ft}' {in}\""],
        minMaxOption: true,
        etimEquivalent: "EF000125",
        parameterClass: "Dimensions",
        value: formik.values.details.thicknessImp.value,
				values: formik.values.details.thicknessImp.values,
        standardValue: formik.values.details.thicknessImp.standard,
        standardName: "details.thicknessImp.standard",
        standardOptions: [],
        minValue: formik.values.details.thicknessImp.min,
        maxValue: formik.values.details.thicknessImp.max,
        minName: "details.thicknessImp.min",
        maxName: "details.thicknessImp.max",
        unitValue: formik.values.details.thicknessImp.unit,
        unitName: "details.thicknessImp.unit",
        rangeValue: formik.values.details.thicknessImp.range,
        rangeName: "details.thicknessImp.range"
      },
      {
        label: "Diameter",
        name: "details.diameterImp.value",
        type: "String",
        multipleUnits: true,
        unitSystem: "imperial",
        unitOptions: ["ft", "in","{ft}' {in}\""],
        minMaxOption: true,
        etimEquivalent: "EF000551",
        parameterClass: "Dimensions",
        description: "Diameter of circular shaped products.",
        value: formik.values.details.diameterImp.value,
				values: formik.values.details.diameterImp.values,
        standardValue: formik.values.details.diameterImp.standard,
        standardName: "details.diameterImp.standard",
        standardOptions: [],
        minValue: formik.values.details.diameterImp.min,
        maxValue: formik.values.details.diameterImp.max,
        minName: "details.diameterImp.min",
        maxName: "details.diameterImp.max",
        unitValue: formik.values.details.diameterImp.unit,
        unitName: "details.diameterImp.unit",
        rangeValue: formik.values.details.diameterImp.range,
        rangeName: "details.diameterImp.range"
      },
      {
        label: "Radius",
        name: "details.radiusImp.value",
        type: "String",
        multipleUnits: true,
        unitSystem: "imperial",
        unitOptions: ["ft", "in","{ft}' {in}\""],
        minMaxOption: true,
        etimEquivalent: "EF020621",
        parameterClass: "Dimensions",
        description: "Radius of circular shaped products.",
        value: formik.values.details.radiusImp.value,
				values: formik.values.details.radiusImp.values,
        standardValue: formik.values.details.radiusImp.standard,
        standardName: "details.radiusImp.standard",
        standardOptions: [],
        minValue: formik.values.details.radiusImp.min,
        maxValue: formik.values.details.radiusImp.max,
        minName: "details.radiusImp.min",
        maxName: "details.radiusImp.max",
        unitValue: formik.values.details.radiusImp.unit,
        unitName: "details.radiusImp.unit",
        rangeValue: formik.values.details.radiusImp.range,
        rangeName: "details.radiusImp.range"
      },
      {
        label: "Area",
        name: "details.areaImp.value",
        type: "String",
        multipleUnits: true,
        unitSystem: "imperial",
        unitOptions: ["sq ft","sq inch", "acre"],
        minMaxOption: true,
        etimEquivalent: "EF002228",
        parameterClass: "Dimensions",
        value: formik.values.details.areaImp.value,
				values: formik.values.details.areaImp.values,
        standardValue: formik.values.details.areaImp.standard,
        standardName: "details.areaImp.standard",
        standardOptions: [],
        minValue: formik.values.details.areaImp.min,
        maxValue: formik.values.details.areaImp.max,
        minName: "details.areaImp.min",
        maxName: "details.areaImp.max",
        unitValue: formik.values.details.areaImp.unit,
        unitName: "details.areaImp.unit",
        rangeValue: formik.values.details.areaImp.range,
        rangeName: "details.areaImp.range"
      },
      {
        label: "Volume",
        name: "details.volumeImp.value",
        type: "String",
        multipleUnits: true,
        unitSystem: "imperial",
        unitOptions: ["cu in", "cu ft", "cu yd"],
        minMaxOption: true,
        etimEquivalent: "EF000880",
        parameterClass: "Dimensions",
        value: formik.values.details.volumeImp.value,
				values: formik.values.details.volumeImp.values,
        standardValue: formik.values.details.volumeImp.standard,
        standardName: "details.volumeImp.standard",
        standardOptions: [],
        minValue: formik.values.details.volumeImp.min,
        maxValue: formik.values.details.volumeImp.max,
        minName: "details.volumeImp.min",
        maxName: "details.volumeImp.max",
        unitValue: formik.values.details.volumeImp.unit,
        unitName: "details.volumeImp.unit",
        rangeValue: formik.values.details.volumeImp.range,
        rangeName: "details.volumeImp.range"
      },
      {
        label: "Depth",
        name: "details.depthImp.value",
        type: "String",
        multipleUnits: true,
        unitSystem: "imperial",
        unitOptions: ["ft", "in","{ft}' {in}\""],
        minMaxOption: true,
        etimEquivalent: ["EF000049","EF005457"],
        parameterClass: "Dimensions",
        value: formik.values.details.depthImp.value,
				values: formik.values.details.depthImp.values,
        standardValue: formik.values.details.depthImp.standard,
        standardName: "details.depthImp.standard",
        standardOptions: [],
        minValue: formik.values.details.depthImp.min,
        maxValue: formik.values.details.depthImp.max,
        minName: "details.depthImp.min",
        maxName: "details.depthImp.max",
        unitValue: formik.values.details.depthImp.unit,
        unitName: "details.depthImp.unit",
        rangeValue: formik.values.details.depthImp.range,
        rangeName: "details.depthImp.range"
      },
      {
        label: "Frequency",
        name: "details.frequency.value",
        type: "Number",
        multipleUnits: false,
        unitSystem: "default",
        unit: "Hz",
        minMaxOption: true,
        etimEquivalent: ["EF000034","EF000416","EF005455"],
        parameterClass: "Electrical",
        value: formik.values.details.frequency.value,
				values: formik.values.details.frequency.values,
        standardValue: formik.values.details.frequency.standard,
        standardName: "details.frequency.standard",
        standardOptions: [],
        minValue: formik.values.details.frequency.min,
        maxValue: formik.values.details.frequency.max,
        minName: "details.frequency.min",
        maxName: "details.frequency.max",
        unitValue: formik.values.details.frequency.unit,
        unitName: "details.frequency.unit",
        rangeValue: formik.values.details.frequency.range,
        rangeName: "details.frequency.range"
      },
      {
        label: "Voltage",
        name: "details.voltage.value",
        type: "Number",
        multipleUnits: false,
        unitSystem: "default",
        unit: "V",
        minMaxOption: true,
        etimEquivalent: ["EF000213","EF002355"],
        parameterClass: "Electrical",
        value: formik.values.details.voltage.value,
				values: formik.values.details.voltage.values,
        standardValue: formik.values.details.voltage.standard,
        standardName: "details.voltage.standard",
        standardOptions: [],
        minValue: formik.values.details.voltage.min,
        maxValue: formik.values.details.voltage.max,
        minName: "details.voltage.min",
        maxName: "details.voltage.max",
        unitValue: formik.values.details.voltage.unit,
        unitName: "details.voltage.unit",
        rangeValue: formik.values.details.voltage.range,
        rangeName: "details.voltage.range"
      },
      {
        label: "Fuse",
        name: "details.fuse.value",
        type: "Number",
        multipleUnits: false,
        unitSystem: "default",
        unit: "A",
        minMaxOption: true,
        etimEquivalent: ["EF024947","EF004149"],
        parameterClass: "Electrical",
        description: "Size of circuit breaker in/for the product.",
        value: formik.values.details.fuse.value,
				values: formik.values.details.fuse.values,
        standardValue: formik.values.details.fuse.standard,
        standardName: "details.fuse.standard",
        standardOptions: [],
        minValue: formik.values.details.fuse.min,
        maxValue: formik.values.details.fuse.max,
        minName: "details.fuse.min",
        maxName: "details.fuse.max",
        unitValue: formik.values.details.fuse.unit,
        unitName: "details.fuse.unit",
        rangeValue: formik.values.details.fuse.range,
        rangeName: "details.fuse.range"
      },
      {
        label: "Load",
        name: "details.load.value",
        type: "Number",
        multipleUnits: false,
        unitSystem: "default",
        unit: "W",
        minMaxOption: true,
        etimEquivalent: ["EF000346"],
        parameterClass: "Electrical",
        description: "Electric power consumption.",
        value: formik.values.details.load.value,
				values: formik.values.details.load.values,
        standardValue: formik.values.details.load.standard,
        standardName: "details.load.standard",
        standardOptions: [],
        minValue: formik.values.details.load.min,
        maxValue: formik.values.details.load.max,
        minName: "details.load.min",
        maxName: "details.load.max",
        unitValue: formik.values.details.load.unit,
        unitName: "details.load.unit",
        rangeValue: formik.values.details.load.range,
        rangeName: "details.load.range"
      },
      {
        label: "Rated power",
        name: "details.ratedPower.value",
        type: "Number",
        multipleUnits: false,
        unitSystem: "default",
        unit: "W",
        minMaxOption: true,
        etimEquivalent: ["EF007051"],
        parameterClass: "Electrical",
        description: "Highest power input allowed to flow through the product.",
        value: formik.values.details.ratedPower.value,
				values: formik.values.details.ratedPower.values,
        standardValue: formik.values.details.ratedPower.standard,
        standardName: "details.ratedPower.standard",
        standardOptions: [],
        minValue: formik.values.details.ratedPower.min,
        maxValue: formik.values.details.ratedPower.max,
        minName: "details.ratedPower.min",
        maxName: "details.ratedPower.max",
        unitValue: formik.values.details.ratedPower.unit,
        unitName: "details.ratedPower.unit",
        rangeValue: formik.values.details.ratedPower.range,
        rangeName: "details.ratedPower.range"
      },
      {
        label: "Connection type",
        name: "details.connectionType.value",
        type: "String",
        multipleUnits: false,
        unitSystem: "default",
        minMaxOption: false,
        etimEquivalent: ["EF000443","EF000124"],
        parameterClass: "Electrical",
        description: "Type of connector, plug or wiring to connect product to power supply.",
        value: formik.values.details.connectionType.value,
				values: formik.values.details.connectionType.values,
        standardValue: formik.values.details.connectionType.standard,
        standardName: "details.connectionType.standard",
        standardOptions: []
      },
      {
        label: "Energy consumption in convection mode",
        name: "details.energyConsumptionInConvectionMode.value",
        type: "Number",
        multipleUnits: true,
        unitSystem: "default",
        unitOptions: ["kWh", "BTU"],
        minMaxOption: false,
        // categories: ["electronics - appliances - ovens"],
        categories: ["electronics"],
        etimEquivalent: "EF008748",
        parameterClass: "Energy efficiency",
        value: formik.values.details.energyConsumptionInConvectionMode.value,
				values: formik.values.details.energyConsumptionInConvectionMode.values,
        standardValue: formik.values.details.energyConsumptionInConvectionMode.standard,
        standardName: "details.energyConsumptionInConvectionMode.standard",
        standardOptions: [],
        unitValue: formik.values.details.energyConsumptionInConvectionMode.unit,
        unitName: "details.energyConsumptionInConvectionMode.unit"
      },
      {
        label: "Energy consumption in recirculation mode",
        name: "details.energyConsumptionInRecirculationMode.value",
        type: "Number",
        multipleUnits: true,
        unitSystem: "default",
        unitOptions: ["kWh", "BTU"],
        minMaxOption: false,
       // categories: ["electronics - appliances - ovens"],
        categories: ["electronics"],
        etimEquivalent: "EF008749",
        parameterClass: "Energy efficiency",
        value: formik.values.details.energyConsumptionInRecirculationMode.value,
				values: formik.values.details.energyConsumptionInRecirculationMode.values,
        standardValue: formik.values.details.energyConsumptionInRecirculationMode.standard,
        standardName: "details.energyConsumptionInRecirculationMode.standard",
        standardOptions: [],
        unitValue: formik.values.details.energyConsumptionInRecirculationMode.unit,
        unitName: "details.energyConsumptionInRecirculationMode.unit"
      },
      {
        label: "Energy Source",
        name: "details.energySource.value",
        type: "String",
        multipleUnits: false,
        unitSystem: "default",
        minMaxOption: false,
        // categories: ["electronics - appliances", "hvac - heating"],
        categories: ["electronics", "hvac"],
        etimEquivalent: ["EF020093","EF007135","EF012554","EF012555"],
        parameterClass: "Energy efficiency",
        description: "Source of energy such as electricity, wood, etc.",
        value: formik.values.details.energySource.value,
				values: formik.values.details.energySource.values,
        standardValue: formik.values.details.energySource.standard,
        standardName: "details.energySource.standard",
        standardOptions: []
      },
      {
        label: "Energy efficiency class",
        name: "details.energyEfficiencyClass.value",
        type: "Menu",
        menuOptions: ["A+++","A++","A+","A","B","C","D","E","F","G"],
        multipleUnits: false,
        unitSystem: "default",
        minMaxOption: false,
        categories: ["electrical", "electronics", "hvac"],
        standardOptions: ["EU Directive 92/75/EC"],
        etimEquivalent: ["EF000758","EF017471","EF016322","EF016321","EF017009","EF017012","EF016992","EF017139","EF012512","EF017013","EF024955","EF017011","EF024956","EF016382","EF017014","EF017010","EF010855","EF016994","EF010856","EF016995"],
        parameterClass: "Energy efficiency",
        value: formik.values.details.energyEfficiencyClass.value,
				values: formik.values.details.energyEfficiencyClass.values,
        standardValue: formik.values.details.energyEfficiencyClass.standard,
        standardName: "details.energyEfficiencyClass.standard"
      },
      {
        label: "Thermal transmittance",
        name: "details.thermalTransmittance.value",
        type: "Number",
        multipleUnits: true,
        unitSystem: "default",
        unitOptions: ["W/m²K","BTU/(h·ft²·°F)"],
        minMaxOption: true,
        categories: ["doors", "windows"],
        standardOptions: ["ISO 10077"],
        etimEquivalent: "EF010083",
        parameterClass: "Energy efficiency",
        value: formik.values.details.thermalTransmittance.value,
				values: formik.values.details.thermalTransmittance.values,
        standardValue: formik.values.details.thermalTransmittance.standard,
        standardName: "details.thermalTransmittance.standard",
        minValue: formik.values.details.thermalTransmittance.min,
        maxValue: formik.values.details.thermalTransmittance.max,
        minName: "details.thermalTransmittance.min",
        maxName: "details.thermalTransmittance.max",
        unitValue: formik.values.details.thermalTransmittance.unit,
        unitName: "details.thermalTransmittance.unit",
        rangeValue: formik.values.details.thermalTransmittance.range,
        rangeName: "details.thermalTransmittance.range"
      },
      {
        label: "Thermal transmittance",
        name: "details.thermalTransmittance.value",
        type: "Number",
        multipleUnits: true,
        unitSystem: "default",
        unitOptions: ["W/m²K","BTU/(h·ft²·°F)"],
        minMaxOption: true,
        // categories: ["construction - curtain systems & facades","construction - walls"],
        categories: ["construction - curtain-systems-and-facades","construction"],
        standardOptions: ["ISO 6946","ISO 10211"],
        etimEquivalent: "EF010083",
        parameterClass: "Energy efficiency",
        value: formik.values.details.thermalTransmittance.value,
				values: formik.values.details.thermalTransmittance.values,
        standardValue: formik.values.details.thermalTransmittance.standard,
        standardName: "details.thermalTransmittance.standard",
        minValue: formik.values.details.thermalTransmittance.min,
        maxValue: formik.values.details.thermalTransmittance.max,
        minName: "details.thermalTransmittance.min",
        maxName: "details.thermalTransmittance.max",
        unitValue: formik.values.details.thermalTransmittance.unit,
        unitName: "details.thermalTransmittance.unit",
        rangeValue: formik.values.details.thermalTransmittance.range,
        rangeName: "details.thermalTransmittance.range"
      },
      {
        label: "Thermal transmittance",
        name: "details.thermalTransmittance.value",
        type: "Number",
        multipleUnits: true,
        unitSystem: "default",
        unitOptions: ["W/m²K","BTU/(h·ft²·°F)"],
        minMaxOption: true,
        // categories: ["construction - floors"],
        categories: ["construction"],
        standardOptions: ["ISO 13370"],
        parameterClass: "Energy efficiency",
        value: formik.values.details.thermalTransmittance.value,
				values: formik.values.details.thermalTransmittance.values,
        standardValue: formik.values.details.thermalTransmittance.standard,
        standardName: "details.thermalTransmittance.standard",
        minValue: formik.values.details.thermalTransmittance.min,
        maxValue: formik.values.details.thermalTransmittance.max,
        minName: "details.thermalTransmittance.min",
        maxName: "details.thermalTransmittance.max",
        unitValue: formik.values.details.thermalTransmittance.unit,
        unitName: "details.thermalTransmittance.unit",
        rangeValue: formik.values.details.thermalTransmittance.range,
        rangeName: "details.thermalTransmittance.range"
      },
      {
        label: "Thermal transmittance",
        name: "details.thermalTransmittance.value",
        type: "Number",
        multipleUnits: true,
        unitSystem: "default",
        unitOptions: ["W/m²K","BTU/(h·ft²·°F)"],
        minMaxOption: true,
        // categories: ["construction - roofs"],
        categories: ["construction"],
        standardOptions: ["ISO 6946"],
        parameterClass: "Energy efficiency",
        value: formik.values.details.thermalTransmittance.value,
				values: formik.values.details.thermalTransmittance.values,
        standardValue: formik.values.details.thermalTransmittance.standard,
        standardName: "details.thermalTransmittance.standard",
        minValue: formik.values.details.thermalTransmittance.min,
        maxValue: formik.values.details.thermalTransmittance.max,
        minName: "details.thermalTransmittance.min",
        maxName: "details.thermalTransmittance.max",
        unitValue: formik.values.details.thermalTransmittance.unit,
        unitName: "details.thermalTransmittance.unit",
        rangeValue: formik.values.details.thermalTransmittance.range,
        rangeName: "details.thermalTransmittance.range"
      },
      {
        label: "Energy consumption",
        name: "details.energyConsumption.value",
        type: "Number",
        multipleUnits: true,
        unitSystem: "default",
        unitOptions: ["kWh", "BTU"],
        minMaxOption: true,
        etimEquivalent: ["EF012349","EF012350","EF012335"],
        parameterClass: "Energy efficiency",
        value: formik.values.details.energyConsumption.value,
				values: formik.values.details.energyConsumption.values,
        standardValue: formik.values.details.energyConsumption.standard,
        standardName: "details.energyConsumption.standard",
        standardOptions: [],
        minValue: formik.values.details.energyConsumption.min,
        maxValue: formik.values.details.energyConsumption.max,
        minName: "details.energyConsumption.min",
        maxName: "details.energyConsumption.max",
        unitValue: formik.values.details.energyConsumption.unit,
        unitName: "details.energyConsumption.unit",
        rangeValue: formik.values.details.energyConsumption.range,
        rangeName: "details.energyConsumption.range"
      },
      {
        label: "Fire rating",
        name: "details.fireRating.value",
        type: "String",
        multipleUnits: false,
        unitSystem: "default",
        minMaxOption: true,
        categories: ["doors", "windows", "construction"],
        standardOptions: ["EN 13501-2","EN 1364 / 1634"],
        etimEquivalent: ["EF010081","EF013906","EF010405"],
        parameterClass: "Fire properties",
        description: "Passive fire protection characteristics of the product. Template REI ttt where R stands for load-bearing capacity, E for integrity, I for insulation and the t's for time in minutes that the product retains its aforementioned characteristics in case of fire. E.g. REI 120",
        value: formik.values.details.fireRating.value,
				values: formik.values.details.fireRating.values,
        standardValue: formik.values.details.fireRating.standard,
        standardName: "details.fireRating.standard",
        minValue: formik.values.details.fireRating.min,
        maxValue: formik.values.details.fireRating.max,
        minName: "details.fireRating.min",
        maxName: "details.fireRating.max",
        rangeValue: formik.values.details.fireRating.range,
        rangeName: "details.fireRating.range"
      },
      {
        label: "Bulb type",
        name: "details.bulbType.value",
        type: "String",
        multipleUnits: false,
        unitSystem: "default",
        minMaxOption: false,
        categories: ["lighting"],
        etimEquivalent: "EF002423",
        parameterClass: "Lighting features",
        description: "Type of light bulb, e.g. LED or incandescent.",
        value: formik.values.details.bulbType.value,
				values: formik.values.details.bulbType.values,
        standardValue: formik.values.details.bulbType.standard,
        standardName: "details.bulbType.standard",
        standardOptions: []
      },
      {
        label: "Luminous flux",
        name: "details.luminousFlux.value",
        type: "Number",
        multipleUnits: false,
        unitSystem: "default",
        unit: "lm",
        minMaxOption: true,
        categories: ["lighting"],
        etimEquivalent: ["EF000078","EF011587"],
        parameterClass: "Lighting features",
        description: "Power of visible light produced by the light source.",
        value: formik.values.details.luminousFlux.value,
				values: formik.values.details.luminousFlux.values,
        standardValue: formik.values.details.luminousFlux.standard,
        standardName: "details.luminousFlux.standard",
        standardOptions: [],
        minValue: formik.values.details.luminousFlux.min,
        maxValue: formik.values.details.luminousFlux.max,
        minName: "details.luminousFlux.min",
        maxName: "details.luminousFlux.max",
        unitValue: formik.values.details.luminousFlux.unit,
        unitName: "details.luminousFlux.unit",
        rangeValue: formik.values.details.luminousFlux.range,
        rangeName: "details.luminousFlux.range"
      },
      {
        label: "Luminous efficacy",
        name: "details.luminousEfficacy.value",
        type: "Number",
        multipleUnits: false,
        unitSystem: "default",
        unit: "lm/W",
        minMaxOption: true,
        categories: ["lighting"],
        etimEquivalent: "EF013777",
        parameterClass: "Lighting features",
        description: "Ratio of luminous flux to power consumed.",
        value: formik.values.details.luminousEfficacy.value,
				values: formik.values.details.luminousEfficacy.values,
        standardValue: formik.values.details.luminousEfficacy.standard,
        standardName: "details.luminousEfficacy.standard",
        standardOptions: [],
        minValue: formik.values.details.luminousEfficacy.min,
        maxValue: formik.values.details.luminousEfficacy.max,
        minName: "details.luminousEfficacy.min",
        maxName: "details.luminousEfficacy.max",
        unitValue: formik.values.details.luminousEfficacy.unit,
        unitName: "details.luminousEfficacy.unit",
        rangeValue: formik.values.details.luminousEfficacy.range,
        rangeName: "details.luminousEfficacy.range"
      },
      {
        label: "Colour temperature",
        name: "details.colourTemperature.value",
        type: "Number",
        multipleUnits: false,
        unitSystem: "default",
        unit: "K",
        minMaxOption: true,
        categories: ["lighting"],
        etimEquivalent: ["EF000103","EF009346"],
        parameterClass: "Lighting features",
        value: formik.values.details.colourTemperature.value,
				values: formik.values.details.colourTemperature.values,
        standardValue: formik.values.details.colourTemperature.standard,
        standardName: "details.colourTemperature.standard",
        standardOptions: [],
        minValue: formik.values.details.colourTemperature.min,
        maxValue: formik.values.details.colourTemperature.max,
        minName: "details.colourTemperature.min",
        maxName: "details.colourTemperature.max",
        unitValue: formik.values.details.colourTemperature.unit,
        unitName: "details.colourTemperature.unit",
        rangeValue: formik.values.details.colourTemperature.range,
        rangeName: "details.colourTemperature.range"
      },
      {
        label: "Colour rendering index (CRI)",
        name: "details.colourRenderingIndex.value",
        type: "Number",
        multipleUnits: false,
        unitSystem: "default",
        minMaxOption: false,
        categories: ["lighting"],
        etimEquivalent: "EF000442",
        parameterClass: "Lighting features",
        value: formik.values.details.colourRenderingIndex.value,
				values: formik.values.details.colourRenderingIndex.values,
        standardValue: formik.values.details.colourRenderingIndex.standard,
        standardName: "details.colourRenderingIndex.standard",
        standardOptions: []
      },
      {
        label: "Colour of light",
        name: "details.colourOfLight.value",
        type: "String",
        multipleUnits: false,
        unitSystem: "default",
        minMaxOption: false,
        categories: ["lighting"],
        etimEquivalent: ["EF002630","EF009350","EF005958"],
        parameterClass: "Lighting features",
        value: formik.values.details.colourOfLight.value,
				values: formik.values.details.colourOfLight.values,
        standardValue: formik.values.details.colourOfLight.standard,
        standardName: "details.colourOfLight.standard",
        standardOptions: []
      },
      {
        label: "Television lighting consistency index (TLCI)",
        name: "details.televisionLightingConsistencyIndex.value",
        type: "Number",
        multipleUnits: false,
        unitSystem: "default",
        minMaxOption: false,
        categories: ["lighting"],
        parameterClass: "Lighting features",
        value: formik.values.details.televisionLightingConsistencyIndex.value,
				values: formik.values.details.televisionLightingConsistencyIndex.values,
        standardValue: formik.values.details.televisionLightingConsistencyIndex.standard,
        standardName: "details.televisionLightingConsistencyIndex.standard",
        standardOptions: []
      },
      {
        label: "Dimming range",
        name: "details.dimmingRange.value",
        type: "String",
        multipleUnits: false,
        unitSystem: "default",
        unit: "%",
        minMaxOption: false,
        categories: ["lighting"],
        parameterClass: "Lighting features",
        value: formik.values.details.dimmingRange.value,
				values: formik.values.details.dimmingRange.values,
        standardValue: formik.values.details.dimmingRange.standard,
        standardName: "details.dimmingRange.standard",
        standardOptions: [],
        unitValue: formik.values.details.dimmingRange.unit,
        unitName: "details.dimmingRange.unit"
      },
      {
        label: "Average rated life",
        name: "details.averageRatedLife.value",
        type: "Number",
        multipleUnits: false,
        unitSystem: "default",
        unit: "h",
        minMaxOption: false,
        categories: ["lighting"],
        etimEquivalent: "EF008342",
        parameterClass: "Lighting features",
        value: formik.values.details.averageRatedLife.value,
				values: formik.values.details.averageRatedLife.values,
        standardValue: formik.values.details.averageRatedLife.standard,
        standardName: "details.averageRatedLife.standard",
        standardOptions: [],
        unitValue: formik.values.details.averageRatedLife.unit,
        unitName: "details.averageRatedLife.unit"
      },
      {
        label: "Material",
        name: "details.material.value",
        type: "String",
        multipleUnits: false,
        unitSystem: "default",
        minMaxOption: false,
        etimEquivalent: "EF002169",
        parameterClass: "Materials",
        description: "Primary material of the product.",
        value: formik.values.details.material.value,
				values: formik.values.details.material.values,
        standardValue: formik.values.details.material.standard,
        standardName: "details.material.standard",
        standardOptions: []
      },
      {
        label: "Net volume",
        name: "details.netVolume.value",
        type: "Number",
        multipleUnits: false,
        unitSystem: "metric",
        unit: "m³",
        minMaxOption: true,
        etimEquivalent: ["EF008002","EF005129"],
        parameterClass: "Quantities",
        value: formik.values.details.netVolume.value,
				values: formik.values.details.netVolume.values,
        standardValue: formik.values.details.netVolume.standard,
        standardName: "details.netVolume.standard",
        standardOptions: [],
        minValue: formik.values.details.netVolume.min,
        maxValue: formik.values.details.netVolume.max,
        minName: "details.netVolume.min",
        maxName: "details.netVolume.max",
        unitValue: formik.values.details.netVolume.unit,
        unitName: "details.netVolume.unit",
        rangeValue: formik.values.details.netVolume.range,
        rangeName: "details.netVolume.range"
      },
      {
        label: "Weight",
        name: "details.weight.value",
        type: "Number",
        multipleUnits: false,
        unitSystem: "metric",
        unit: "kg",
        minMaxOption: true,
        etimEquivalent: "EF000167",
        parameterClass: "Quantities",
        value: formik.values.details.weight.value,
				values: formik.values.details.weight.values,
        standardValue: formik.values.details.weight.standard,
        standardName: "details.weight.standard",
        standardOptions: [],
        minValue: formik.values.details.weight.min,
        maxValue: formik.values.details.weight.max,
        minName: "details.weight.min",
        maxName: "details.weight.max",
        unitValue: formik.values.details.weight.unit,
        unitName: "details.weight.unit",
        rangeValue: formik.values.details.weight.range,
        rangeName: "details.weight.range"
      },
      {
        label: "Flow coefficient",
        name: "details.flowCoefficient.value",
        type: "Number",
        multipleUnits: false,
        unitSystem: "metric",
        unit: "m³/h",
        minMaxOption: false,
        categories: ["hvac", "plumbing"],
        parameterClass: "Technical features",
        description: "Efficiency of fluid flow in the product.",
        value: formik.values.details.flowCoefficient.value,
				values: formik.values.details.flowCoefficient.values,
        standardValue: formik.values.details.flowCoefficient.standard,
        standardName: "details.flowCoefficient.standard",
        standardOptions: [],
        unitValue: formik.values.details.flowCoefficient.unit,
        unitName: "details.flowCoefficient.unit"
      },
      {
        label: "Flow coefficient",
        name: "details.flowCoefficientImp.value",
        type: "String",
        multipleUnits: false,
        unitSystem: "imperial",
        unit: "cu ft/h",
        minMaxOption: false,
        categories: ["hvac", "plumbing"],
        parameterClass: "Technical features",
        description: "Efficiency of fluid flow in the product.",
        value: formik.values.details.flowCoefficientImp.value,
				values: formik.values.details.flowCoefficientImp.values,
        standardValue: formik.values.details.flowCoefficientImp.standard,
        standardName: "details.flowCoefficientImp.standard",
        standardOptions: [],
        unitValue: formik.values.details.flowCoefficientImp.unit,
        unitName: "details.flowCoefficientImp.unit"
      },
      {
        label: "Operating temperature",
        name: "details.operatingTemperature.value",
        type: "Number",
        multipleUnits: true,
        unitSystem: "default",
        unitOptions: ["°C", "°F"],
        minMaxOption: true,
        categories: ["hvac", "plumbing"],
        etimEquivalent: ["EF001742","EF002393","EF001310"],
        parameterClass: "Technical features",
        value: formik.values.details.operatingTemperature.value,
				values: formik.values.details.operatingTemperature.values,
        standardValue: formik.values.details.operatingTemperature.standard,
        standardName: "details.operatingTemperature.standard",
        standardOptions: [],
        minValue: formik.values.details.operatingTemperature.min,
        maxValue: formik.values.details.operatingTemperature.max,
        minName: "details.operatingTemperature.min",
        maxName: "details.operatingTemperature.max",
        unitValue: formik.values.details.operatingTemperature.unit,
        unitName: "details.operatingTemperature.unit",
        rangeValue: formik.values.details.operatingTemperature.range,
        rangeName: "details.operatingTemperature.range"
      },
      {
        label: "Operating pressure",
        name: "details.operatingPressure.value",
        type: "Number",
        multipleUnits: true,
        unitSystem: "default",
        unitOptions: ["kPa", "psi"],
        minMaxOption: true,
        categories: ["hvac", "plumbing"],
        etimEquivalent: ["EF020449","EF022016","EF020088"],
        parameterClass: "Technical features",
        value: formik.values.details.operatingPressure.value,
				values: formik.values.details.operatingPressure.values,
        standardValue: formik.values.details.operatingPressure.standard,
        standardName: "details.operatingPressure.standard",
        standardOptions: [],
        minValue: formik.values.details.operatingPressure.min,
        maxValue: formik.values.details.operatingPressure.max,
        minName: "details.operatingPressure.min",
        maxName: "details.operatingPressure.max",
        unitValue: formik.values.details.operatingPressure.unit,
        unitName: "details.operatingPressure.unit",
        rangeValue: formik.values.details.operatingPressure.range,
        rangeName: "details.operatingPressure.range"
      },
      {
        label: "Nominal pressure (PN)",
        name: "details.nominalPressure.value",
        type: "Number",
        multipleUnits: false,
        unitSystem: "default",
        unit: "bar",
        minMaxOption: false,
        categories: ["hvac", "plumbing"],
        standardOptions: ["ISO 7268"],
        etimEquivalent: ["EF009814","EF009816","EF009818"],
        parameterClass: "Technical features",
        value: formik.values.details.nominalPressure.value,
				values: formik.values.details.nominalPressure.values,
        standardValue: formik.values.details.nominalPressure.standard,
        standardName: "details.nominalPressure.standard",
        unitValue: formik.values.details.nominalPressure.unit,
        unitName: "details.nominalPressure.unit"
      },
      {
        label: "Protection class",
        name: "details.protectionClass.value",
        type: "String",
        multipleUnits: false,
        unitSystem: "default",
        unit: "IP rating",
        minMaxOption: false,
        categories: ["electrical","electronics"],
        standardOptions: ["EN 60529"],
        etimEquivalent: "EF000004",
        parameterClass: "Technical features",
        description: "The product's ability to withstand surrounding conditions.",
        value: formik.values.details.protectionClass.value,
				values: formik.values.details.protectionClass.values,
        standardValue: formik.values.details.protectionClass.standard,
        standardName: "details.protectionClass.standard",
        unitValue: formik.values.details.protectionClass.unit,
        unitName: "details.protectionClass.unit"
      },
      {
        label: "Impact resistance",
        name: "details.impactResistance.value",
        type: "String",
        multipleUnits: false,
        unitSystem: "default",
        unit: "IK rating",
        minMaxOption: false,
        categories: ["electrical","electronics"],
        standardOptions: ["EN 62262"],
        parameterClass: "Technical features",
        description: "The product's ability to withstand mechanical impacts.",
        value: formik.values.details.impactResistance.value,
				values: formik.values.details.impactResistance.values,
        standardValue: formik.values.details.impactResistance.standard,
        standardName: "details.impactResistance.standard",
        unitValue: formik.values.details.impactResistance.unit,
        unitName: "details.impactResistance.unit"
      },
      {
        label: "Installation type",
        name: "details.installationType.value",
        type: "String",
        unitSystem: "default",
        multipleUnits: false,
        minMaxOption: false,
        etimEquivalent: ["EF021376","EF000333"],
        parameterClass: "Technical features",
        description: "Attachments, connections, adhesives, fasteners, frames etc. required to install the product.",
        value: formik.values.details.installationType.value,
				values: formik.values.details.installationType.values,
        standardValue: formik.values.details.installationType.standard,
        standardName: "details.installationType.standard",
        standardOptions: []
      },
      {
        label: "Permissible humidity",
        name: "details.permissibleHumidity.value",
        type: "Number",
        multipleUnits: false,
        unitSystem: "default",
        unit: "%",
        minMaxOption: true,
        etimEquivalent: "EF014231",
        parameterClass: "Technical features",
        value: formik.values.details.permissibleHumidity.value,
				values: formik.values.details.permissibleHumidity.values,
        standardValue: formik.values.details.permissibleHumidity.standard,
        standardName: "details.permissibleHumidity.standard",
        standardOptions: [],
        minValue: formik.values.details.permissibleHumidity.min,
        maxValue: formik.values.details.permissibleHumidity.max,
        minName: "details.permissibleHumidity.min",
        maxName: "details.permissibleHumidity.max",
        unitValue: formik.values.details.permissibleHumidity.unit,
        unitName: "details.permissibleHumidity.unit",
        rangeValue: formik.values.details.permissibleHumidity.range,
        rangeName: "details.permissibleHumidity.range"
      },
      {
        label: "Resistance to repeated opening and closing",
        name: "details.resistanceToRepeatedOpeningAndClosing.value",
        type: "Menu",
        menuOptions: ["Class 1","Class 2","Class 3","Class 4"],
        multipleUnits: false,
        unitSystem: "default",
        minMaxOption: true,
        categories: ["doors", "windows"],
        standardOptions: ["EN 1191:2012"],
        parameterClass: "Technical features",
        value: formik.values.details.resistanceToRepeatedOpeningAndClosing.value,
				values: formik.values.details.resistanceToRepeatedOpeningAndClosing.values,
        standardValue: formik.values.details.resistanceToRepeatedOpeningAndClosing.standard,
        standardName: "details.resistanceToRepeatedOpeningAndClosing.standard",
        minValue: formik.values.details.resistanceToRepeatedOpeningAndClosing.min,
        maxValue: formik.values.details.resistanceToRepeatedOpeningAndClosing.max,
        minName: "details.resistanceToRepeatedOpeningAndClosing.min",
        maxName: "details.resistanceToRepeatedOpeningAndClosing.max",
        rangeValue: formik.values.details.resistanceToRepeatedOpeningAndClosing.range,
        rangeName: "details.resistanceToRepeatedOpeningAndClosing.range"
      },
      {
        label: "Burglary protection",
        name: "details.burglaryProtection.value",
        type: "Menu",
        menuOptions: ["RC1","RC2","RC3","RC4","RC5","RC6"],
        multipleUnits: false,
        unitSystem: "default",
        minMaxOption: true,
        categories: ["doors", "windows"],
        standardOptions: ["EN 1627:2011"],
        etimEquivalent: "EF014768",
        parameterClass: "Technical features",
        value: formik.values.details.burglaryProtection.value,
				values: formik.values.details.burglaryProtection.values,
        standardValue: formik.values.details.burglaryProtection.standard,
        standardName: "details.burglaryProtection.standard",
        minValue: formik.values.details.burglaryProtection.min,
        maxValue: formik.values.details.burglaryProtection.max,
        minName: "details.burglaryProtection.min",
        maxName: "details.burglaryProtection.max",
        rangeValue: formik.values.details.burglaryProtection.range,
        rangeName: "details.burglaryProtection.range"
      },
      {
        label: "Air permeability",
        name: "details.airPermeability.value",
        type: "Menu",
        menuOptions: ["Class 1","Class 2","Class 3","Class 4"],
        multipleUnits: false,
        unitSystem: "default",
        minMaxOption: true,
        categories: ["doors", "windows"],
        standardOptions: ["EN 12207"],
        parameterClass: "Technical features",
        value: formik.values.details.airPermeability.value,
				values: formik.values.details.airPermeability.values,
        standardValue: formik.values.details.airPermeability.standard,
        standardName: "details.airPermeability.standard",
        minValue: formik.values.details.airPermeability.min,
        maxValue: formik.values.details.airPermeability.max,
        minName: "details.airPermeability.min",
        maxName: "details.airPermeability.max",
        rangeValue: formik.values.details.airPermeability.range,
        rangeName: "details.airPermeability.range"
      },
      {
        label: "Watertightness",
        name: "details.watertightness.value",
        type: "Menu",
        menuOptions: ["Class 1","Class 2","Class 3","Class 4","Class 5","Class 6","Class 7","Class 8","Class 9"],
        multipleUnits: false,
        unitSystem: "default",
        minMaxOption: true,
        categories: ["doors", "windows"],
        standardOptions: ["EN 12208"],
        etimEquivalent: ["EF018266","EF013135"],
        parameterClass: "Technical features",
        value: formik.values.details.watertightness.value,
				values: formik.values.details.watertightness.values,
        standardValue: formik.values.details.watertightness.standard,
        standardName: "details.watertightness.standard",
        minValue: formik.values.details.watertightness.min,
        maxValue: formik.values.details.watertightness.max,
        minName: "details.watertightness.min",
        maxName: "details.watertightness.max",
        rangeValue: formik.values.details.watertightness.range,
        rangeName: "details.watertightness.range"
      },
      {
        label: "Wind resistance",
        name: "details.windResistance.value",
        type: "Menu",
        menuOptions: ["Class 1","Class 2","Class 3","Class 4","Class 5"],
        multipleUnits: false,
        unitSystem: "default",
        minMaxOption: true,
        categories: ["doors", "windows"],
        standardOptions: ["ISO 6612:2023"],
        etimEquivalent: "EF018267",
        parameterClass: "Technical features",
        value: formik.values.details.windResistance.value,
				values: formik.values.details.windResistance.values,
        standardValue: formik.values.details.windResistance.standard,
        standardName: "details.windResistance.standard",
        minValue: formik.values.details.windResistance.min,
        maxValue: formik.values.details.windResistance.max,
        minName: "details.windResistance.min",
        maxName: "details.windResistance.max",
        rangeValue: formik.values.details.windResistance.range,
        rangeName: "details.windResistance.range"
      },
      {
        label: "Shear strength",
        name: "details.shearStrength.value",
        type: "Number",
        multipleUnits: true,
	      unitSystem: "default",
        unitOptions: ["kPa","MPa", "psi"],
        minMaxOption: true,
        etimEquivalent: "EF026334",
        parameterClass: "Technical features",
        value: formik.values.details.shearStrength.value,
				values: formik.values.details.shearStrength.values,
        standardValue: formik.values.details.shearStrength.standard,
        standardName: "details.shearStrength.standard",
        standardOptions: [],
        minValue: formik.values.details.shearStrength.min,
        maxValue: formik.values.details.shearStrength.max,
        minName: "details.shearStrength.min",
        maxName: "details.shearStrength.max",
        unitValue: formik.values.details.shearStrength.unit,
        unitName: "details.shearStrength.unit",
        rangeValue: formik.values.details.shearStrength.range,
        rangeName: "details.shearStrength.range"
      },
      {
        label: "Tensile strength",
        name: "details.tensileStrength.value",
        type: "Number",
        multipleUnits: true,
	      unitSystem: "default",
        unitOptions: ["kPa","MPa", "psi"],
        minMaxOption: true,
        etimEquivalent: "EF002782",
        parameterClass: "Technical features",
        value: formik.values.details.tensileStrength.value,
				values: formik.values.details.tensileStrength.values,
        standardValue: formik.values.details.tensileStrength.standard,
        standardName: "details.tensileStrength.standard",
        standardOptions: [],
        minValue: formik.values.details.tensileStrength.min,
        maxValue: formik.values.details.tensileStrength.max,
        minName: "details.tensileStrength.min",
        maxName: "details.tensileStrength.max",
        unitValue: formik.values.details.tensileStrength.unit,
        unitName: "details.tensileStrength.unit",
        rangeValue: formik.values.details.tensileStrength.range,
        rangeName: "details.tensileStrength.range"
      },
      {
        label: "Compressive strength",
        name: "details.compressiveStrength.value",
        type: "Number",
        multipleUnits: true,
	      unitSystem: "default",
        unitOptions: ["kPa","MPa", "psi"],
        minMaxOption: true,
        etimEquivalent: ["EF016861","EF010447","EF016103","EF016911","EF010454","EF026345","EF012727"],
        parameterClass: "Technical features",
        value: formik.values.details.compressiveStrength.value,
				values: formik.values.details.compressiveStrength.values,
        standardValue: formik.values.details.compressiveStrength.standard,
        standardName: "details.compressiveStrength.standard",
        standardOptions: [],
        minValue: formik.values.details.compressiveStrength.min,
        maxValue: formik.values.details.compressiveStrength.max,
        minName: "details.compressiveStrength.min",
        maxName: "details.compressiveStrength.max",
        unitValue: formik.values.details.compressiveStrength.unit,
        unitName: "details.compressiveStrength.unit",
        rangeValue: formik.values.details.compressiveStrength.range,
        rangeName: "details.compressiveStrength.range"
      },
      {
        label: "Density",
        name: "details.density.value",
        type: "Number",
        multipleUnits: true,
	      unitSystem: "default",
        unitOptions: ["kg/m³","lb/ft³"],
        minMaxOption: true,
        etimEquivalent: ["EF020000","EF026349"],
        parameterClass: "Quantities",
        value: formik.values.details.density.value,
				values: formik.values.details.density.values,
        standardValue: formik.values.details.density.standard,
        standardName: "details.density.standard",
        standardOptions: [],
        minValue: formik.values.details.density.min,
        maxValue: formik.values.details.density.max,
        minName: "details.density.min",
        maxName: "details.density.max",
        unitValue: formik.values.details.density.unit,
        unitName: "details.density.unit",
        rangeValue: formik.values.details.density.range,
        rangeName: "details.density.range"
      },
      {
        label: "Current",
        name: "details.current.value",
        type: "Number",
        multipleUnits: false,
        unitSystem: "default",
        unit: "A",
        minMaxOption: true,
        parameterClass: "Electrical",
        value: formik.values.details.current.value,
				values: formik.values.details.current.values,
        standardValue: formik.values.details.current.standard,
        standardName: "details.current.standard",
        standardOptions: [],
        minValue: formik.values.details.current.min,
        maxValue: formik.values.details.current.max,
        minName: "details.current.min",
        maxName: "details.current.max",
        unitValue: formik.values.details.current.unit,
        unitName: "details.current.unit",
        rangeValue: formik.values.details.current.range,
        rangeName: "details.current.range"
      },
      {
        label: "Thermal resistance",
        name: "details.thermalResistance.value",
        type: "Number",
        multipleUnits: true,
	      unitSystem: "default",
        unitOptions: ["m²K/W","h·ft²·°F/BTU"],
        minMaxOption: true,
        etimEquivalent: "EF010245",
        parameterClass: "Energy efficiency",
        value: formik.values.details.thermalResistance.value,
				values: formik.values.details.thermalResistance.values,
        standardValue: formik.values.details.thermalResistance.standard,
        standardName: "details.thermalResistance.standard",
        standardOptions: [],
        minValue: formik.values.details.thermalResistance.min,
        maxValue: formik.values.details.thermalResistance.max,
        minName: "details.thermalResistance.min",
        maxName: "details.thermalResistance.max",
        unitValue: formik.values.details.thermalResistance.unit,
        unitName: "details.thermalResistance.unit",
        rangeValue: formik.values.details.thermalResistance.range,
        rangeName: "details.thermalResistance.range"
      },
      {
        label: "Mechanical stress",
        name: "details.mechanicalStress.value",
        type: "Number",
        multipleUnits: true,
        unitSystem: "metric",
        unitOptions: ["kPa","MPa","psi"],
        minMaxOption: true,
        parameterClass: "Technical features",
        value: formik.values.details.mechanicalStress.value,
				values: formik.values.details.mechanicalStress.values,
        standardValue: formik.values.details.mechanicalStress.standard,
        standardName: "details.mechanicalStress.standard",
        standardOptions: [],
        minValue: formik.values.details.mechanicalStress.min,
        maxValue: formik.values.details.mechanicalStress.max,
        minName: "details.mechanicalStress.min",
        maxName: "details.mechanicalStress.max",
        unitValue: formik.values.details.mechanicalStress.unit,
        unitName: "details.mechanicalStress.unit",
        rangeValue: formik.values.details.mechanicalStress.range,
        rangeName: "details.mechanicalStress.range"
      },
      {
        label: "Carbon footprint",
        name: "details.carbonFootprint.value",
        type: "Number",
        multipleUnits: false,
        unitSystem: "metric",
        unit: "kg CO₂e",
        minMaxOption: true,
        parameterClass: "Sustainability",
        description: "Total amount of emissions of carbon dioxide (CO₂) accumulated over the lifecycle stages of the product.",
        value: formik.values.details.carbonFootprint.value,
				values: formik.values.details.carbonFootprint.values,
        standardValue: formik.values.details.carbonFootprint.standard,
        standardName: "details.carbonFootprint.standard",
        standardOptions: [],
        minValue: formik.values.details.carbonFootprint.min,
        maxValue: formik.values.details.carbonFootprint.max,
        minName: "details.carbonFootprint.min",
        maxName: "details.carbonFootprint.max",
        unitValue: formik.values.details.carbonFootprint.unit,
        unitName: "details.carbonFootprint.unit",
        rangeValue: formik.values.details.carbonFootprint.range,
        rangeName: "details.carbonFootprint.range"
      },
      {
        label: "Passive House efficiency class",
        name: "details.passiveHouseEfficiencyClass.value",
        type: "Menu",
        menuOptions: ["phA+","phA","phB","phC"],
        multipleUnits: false,
        unitSystem: "default",
        minMaxOption: false,
        parameterClass: "Sustainability",
        value: formik.values.details.passiveHouseEfficiencyClass.value,
				values: formik.values.details.passiveHouseEfficiencyClass.values,
        standardValue: formik.values.details.passiveHouseEfficiencyClass.standard,
        standardName: "details.passiveHouseEfficiencyClass.standard",
        standardOptions: []
      },
      {
        label: "Fire rating materials",
        name: "details.fireRatingMaterials.value",
        type: "String",
        multipleUnits: false,
        unitSystem: "default",
        minMaxOption: false,
        categories: ["construction"],
        standardOptions: ["EN 13501-1"],
        etimEquivalent: "EF026338",
        parameterClass: "Fire properties",
        description: "The product's fire behaviour with letters A1, A2 or B-F; Smoke development s1, s2 or s3; Formation of flaming particles or droplets d0, d1 or d2. E.g. A2-s1,d0",
        value: formik.values.details.fireRatingMaterials.value,
				values: formik.values.details.fireRatingMaterials.values,
        standardValue: formik.values.details.fireRatingMaterials.standard,
        standardName: "details.fireRatingMaterials.standard"
      }
    ]
  
    //const filtered = items.filter(item => item.class === class);
    const filtered = items.filter(item => ((item?.categories?.includes(formik.values.category) || typeof(item.categories) === "undefined") && item.parameterClass === parameterClass && (item.unitSystem === "default" | item.unitSystem === unitSystem)))
    //const output = filtered.concat(filteredCategorySpecific);

    return filtered;
  }