import { useState, useEffect } from 'react';
import { 
  Card, 
  CardContent,
  CardHeader,
  Grid, 
  Box, 
  Typography, 
  IconButton, 
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip, 
  FormControl, 
  InputLabel, 
  Select, 
  Stack, 
  MenuItem 
} from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { Plus } from './icons/plus';
import dayjs, { Dayjs} from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import * as Yup from 'yup';
import { useFormik } from 'formik';
//import { uuid } from 'uuidv4';
import {v4 as uuidv4} from 'uuid';
import { addDemoProduct, upload } from './api';
import toast from 'react-hot-toast';
import { wait } from './utils/wait';
import { FileDropzoneField } from './components/file-dropzone-field';
import { X as XIcon } from './icons/x';
import { Duplicate as DuplicateIcon } from './icons/duplicate';
import { FilesUploadedField } from './components/files-uploaded-field';
import axios from 'axios';



// const string_to_slug = (str) => {
//   str = str.replace(/^\s+|\s+$/g, ''); // trim
//   str = str.toLowerCase();

//   // remove accents, swap ñ for n, etc
//   var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
//   var to   = "aaaaeeeeiiiioooouuuunc------";
//   for (var i=0, l=from.length ; i<l ; i++) {
//       str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
//   }

//   str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
//       .replace(/\s+/g, '-') // collapse whitespace and replace by -
//       .replace(/-+/g, '-'); // collapse dashes

//   return str;
// }


export const DemoImportForm = (props) => {
  const { data, show, refresh, edited, companyid, ...other } = props;
  const [files, setFiles] = useState([]);
  const [jsonFile, setJsonFile] = useState([]);
  const [renderFile, setRenderFile] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);
  const [glbFiles, setGlbFiles] = useState([]);
  const [rfaFiles, setRfaFiles] = useState([]);
  const [showForm, setShowForm] = useState(false); 

  const setNewIds = () => {
    formik.setFieldValue('id', uuidv4());
  }
 
  const formik = useFormik({
    initialValues: {
      id: data?.id || uuidv4(),
      companyId: data?.id || companyid,
      status: "Preloaded",
      name: data?.name || "",
      slug: data?.slug || "",
      url: data?.url || "",
      jsonFilename: data?.jsonFilename || [],
      renderFilename: data?.renderFilename || [],
      imageFilenames: data?.imageFilenames || [],
      glbFilenames: data?.glbFilenames || [],
      rfaFilenames: data?.rfaFilenames || [],
      submit: null
    },
    validationSchema: Yup.object({
      id: Yup.string().max(255),
      user: Yup.string().max(255),
      companyId: Yup.string().max(255),
      name: Yup.string().max(255).required('Product name is required'),
      slug:  Yup.string().max(255),
      url: Yup.string().max(255),
      jsonFilename: Yup.array(),
      renderFilename: Yup.array(),
      imageFilenames: Yup.array(),
      glbFilenames: Yup.array(),
      rfaFilenames: Yup.array()
    }),
    onSubmit: async (values, helpers) => {
      try {
        // NOTE: Make API request
        //
       let responseMessage = "";
       
        // if (edited) {
        //   responseMessage = await updateContactingLog(values);
        // } else {
        //   responseMessage = await postDemoAccount(values);
        // }

        // formik.setFieldValue("slug",string_to_slug(formik.values.name));
        //console.log(string_to_slug(formik.values.name));

        uploadPendingFiles();

        await addDemoProduct(values);
        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);
        toast.success('Event updated!');
        await wait(500);
        helpers.resetForm();
        setNewIds();
        refresh();
        show();
      } catch (err) {
        console.error(err);
        toast.error('Something went wrong!');
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    }
  });

  const uploadPendingFiles = () => {
    // check json

    if (jsonFile.length > 0) {
      handleFileUpload();
    }

    // check render

    if (renderFile.length > 0) {
      handleFileUpload2();
    }

    // check product images

    if (imageFiles.length > 0) {
      handleFileUpload3();
    }

    // check glbs

    if (glbFiles.length > 0) {
      handleFileUpload4();
    }

    // check rfas

    if (rfaFiles.length > 0) {
      handleFileUpload5();
    }

  };

  const handleDrop = (newFiles) => {
    setJsonFile((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleRemove = (file) => {
    setJsonFile((prevFiles) => prevFiles.filter((_file) => _file.path !== file.path));
  };

  const handleRemoveAll = () => {
    setJsonFile([]);
  };

  const handleRemoveAdded = (file) => {
    let oldFiles = formik.values.jsonFilename;
    const fileRemoved = (prevFiles) => prevFiles.filter((_file) => _file.path !== file.path);
    const newFiles = fileRemoved(oldFiles);
    //console.log(newFiles);
    formik.setFieldValue("jsonFilename", newFiles);
  };

  const handleRemoveAddedAll = () => {
    formik.setFieldValue("jsonFilename", []);
  };


  const handleFileUpload = () => {
    jsonFile.map((file) => {
      let oldArray = formik.values.jsonFilename;
      oldArray.push(file);
      uploadFileToBlob(file);
      formik.setFieldValue("jsonFilename", oldArray);
    });
    setJsonFile([]);
  };

  const handleDrop2 = (newFiles) => {
    setRenderFile((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleRemove2 = (file) => {
    setRenderFile((prevFiles) => prevFiles.filter((_file) => _file.path !== file.path));
  };

  const handleRemoveAll2 = () => {
    setRenderFile([]);
  };

  const handleRemoveAdded2 = (file) => {
    let oldFiles = formik.values.renderFilename;
    const fileRemoved = (prevFiles) => prevFiles.filter((_file) => _file.path !== file.path);
    const newFiles = fileRemoved(oldFiles);
    //console.log(newFiles);
    formik.setFieldValue("renderFilename", newFiles);
  };

  const handleRemoveAddedAll2 = () => {
    formik.setFieldValue("renderFilename", []);
  };

  const handleFileUpload2 = () => {
    renderFile.map((file) => {
      let oldArray = formik.values.renderFilename;
      oldArray.push(file);
      uploadFileToBlob(file);
      formik.setFieldValue("renderFilename", oldArray);
    }

    // AZURE FILE SHARE API CALL HERE
    // sendFile(file);
    //formik.value
    //uploadFileToBlob(file)
    );
    setRenderFile([]);
  };

  const handleDrop3 = (newFiles) => {
    setImageFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleRemove3 = (file) => {
    setImageFiles((prevFiles) => prevFiles.filter((_file) => _file.path !== file.path));
  };

  const handleRemoveAll3 = () => {
    setImageFiles([]);
  };

  const handleRemoveAdded3 = (file) => {
    let oldFiles = formik.values.imageFilenames;
    const fileRemoved = (prevFiles) => prevFiles.filter((_file) => _file.path !== file.path);
    const newFiles = fileRemoved(oldFiles);
    //console.log(newFiles);
    formik.setFieldValue("imageFilenames", newFiles);
  };

  const handleRemoveAddedAll3 = () => {
    formik.setFieldValue("imageFilenames", []);
  };

  const handleFileUpload3 = () => {
    imageFiles.map((file) => {
      let oldArray = formik.values.imageFilenames;
      oldArray.push(file);
      uploadFileToBlob(file);
      formik.setFieldValue("imageFilenames", oldArray);
    }

    // AZURE FILE SHARE API CALL HERE
    // sendFile(file);
    //formik.value
    //uploadFileToBlob(file)
    );
    setImageFiles([]);
  };

  const handleDrop4 = (newFiles) => {
    setGlbFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleRemove4 = (file) => {
    setGlbFiles((prevFiles) => prevFiles.filter((_file) => _file.path !== file.path));
  };

  const handleRemoveAll4 = () => {
    setGlbFiles([]);
  };

  const handleRemoveAdded4 = (file) => {
    let oldFiles = formik.values.glbFilenames;
    const fileRemoved = (prevFiles) => prevFiles.filter((_file) => _file.path !== file.path);
    const newFiles = fileRemoved(oldFiles);
    //console.log(newFiles);
    formik.setFieldValue("glbFilenames", newFiles);
  };

  const handleRemoveAddedAll4 = () => {
    formik.setFieldValue("glbFilenames", []);
  };

  const handleFileUpload4 = () => {
    glbFiles.map((file) => {
      let oldArray = formik.values.glbFilenames;
      oldArray.push(file);
      uploadFileToBlob(file);
      formik.setFieldValue("glbFilenames", oldArray);
    }

    // AZURE FILE SHARE API CALL HERE
    // sendFile(file);
    //formik.value
    //uploadFileToBlob(file)
    );
    setGlbFiles([]);
  };

  const handleDrop5 = (newFiles) => {
    setRfaFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleRemove5 = (file) => {
    setRfaFiles((prevFiles) => prevFiles.filter((_file) => _file.path !== file.path));
  };

  const handleRemoveAll5 = () => {
    setRfaFiles([]);
  };

  const handleRemoveAdded5 = (file) => {
    let oldFiles = formik.values.rfaFilenames;
    const fileRemoved = (prevFiles) => prevFiles.filter((_file) => _file.path !== file.path);
    const newFiles = fileRemoved(oldFiles);
    //console.log(newFiles);
    formik.setFieldValue("rfaFilenames", newFiles);
  };

  const handleRemoveAddedAll5 = () => {
    formik.setFieldValue("rfaFilenames", []);
  };

  const handleFileUpload5 = () => {
    rfaFiles.map((file) => {
      let oldArray = formik.values.rfaFilenames;
      oldArray.push(file);
      uploadFileToBlob(file, "form-uploads");
      formik.setFieldValue("rfaFilenames", oldArray);
    }

    // AZURE FILE SHARE API CALL HERE
    // sendFile(file);
    //formik.value
    //uploadFileToBlob(file)
    );
    setRfaFiles([]);
  };

  const uploadFileToBlob = async (file, id, folder) => {
    // const newFile = new File([file], id, {
    //   type: file?.type,
    // });
    const newFile = await file.arrayBuffer();

    const response = await upload(newFile, id, folder);
    return response;
  };

  return (
    <>
      <form
      onSubmit={formik.handleSubmit}
      // {...other}
      >
        <Card>
          <CardHeader title="Add a product" sx={{pb: 0}} />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={4}  xs={12}>
                {/* <Typography variant="h6">Render</Typography> */}
                <Typography color="textSecondary" variant="subtitle2" sx={{ mt: 1 }}>
                  Product name
                </Typography>
              </Grid>
              <Grid item md={8}  xs={12}>
                <TextField
                  fullWidth
                  id="outlined-multiline-flexible"
                  label="Product name"
                  name="name"
                  //sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.name}
                />
              </Grid>
              <Grid item md={4}  xs={12}>
                {/* <Typography variant="h6">Render</Typography> */}
                <Typography color="textSecondary" variant="subtitle2" sx={{ mt: 1 }}>
                  External URL
                </Typography>
              </Grid>
              <Grid item md={8}  xs={12}>
                <TextField
                  fullWidth
                  id="outlined-multiline-flexible"
                  label="External URL"
                  name="url"
                  //sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.url}
                />
              </Grid>
              <Grid item md={4}  xs={12}>
                {/* <Typography variant="h6">JSON</Typography> */}
                <Typography color="textSecondary" variant="subtitle2" sx={{ mt: 1 }}>
                  Upload JSON
                </Typography>
              </Grid>
              <Grid item md={8} xs={12}>
                <FileDropzoneField
                  files={jsonFile}
                  onDrop={handleDrop}
                  onRemove={handleRemove}
                  onRemoveAll={handleRemoveAll}
                  onUpload={handleFileUpload}
                  maxFiles={1}
                />
                <FilesUploadedField data={formik.values.jsonFilename} onRemove={handleRemoveAdded} />
              </Grid>
              <Grid item md={4}  xs={12}>
                {/* <Typography variant="h6">Render</Typography> */}
                <Typography color="textSecondary" variant="subtitle2" sx={{ mt: 1 }}>
                  Upload render
                </Typography>
              </Grid>
              <Grid item md={8} xs={12}>
                <FileDropzoneField
                  files={renderFile}
                  onDrop={handleDrop2}
                  onRemove={handleRemove2}
                  onRemoveAll={handleRemoveAll2}
                  onUpload={handleFileUpload2}
                  maxFiles={1}
                />
                <FilesUploadedField data={formik.values.renderFilename} onRemove={handleRemoveAdded2} />
              </Grid>
              <Grid item md={4}  xs={12}>
                {/* <Typography variant="h6">Product images</Typography> */}
                <Typography color="textSecondary" variant="subtitle2" sx={{ mt: 1 }}>
                  Upload product images
                </Typography>
              </Grid>
              <Grid item md={8} xs={12}>
                <FileDropzoneField
                  files={imageFiles}
                  onDrop={handleDrop3}
                  onRemove={handleRemove3}
                  onRemoveAll={handleRemoveAll3}
                  onUpload={handleFileUpload3}
                />
                <FilesUploadedField data={formik.values.imageFilenames} onRemove={handleRemoveAdded3} />
              </Grid>
              <Grid item md={4}  xs={12}>
                {/* <Typography variant="h6">Product images</Typography> */}
                <Typography color="textSecondary" variant="subtitle2" sx={{ mt: 1 }}>
                  Upload glb/gltf files
                </Typography>
              </Grid>
              <Grid item md={8} xs={12}>
                <FileDropzoneField
                  files={glbFiles}
                  onDrop={handleDrop4}
                  onRemove={handleRemove4}
                  onRemoveAll={handleRemoveAll4}
                  onUpload={handleFileUpload4}
                />
                <FilesUploadedField data={formik.values.glbFilenames} onRemove={handleRemoveAdded4} />
              </Grid>
              <Grid item md={4}  xs={12}>
                {/* <Typography variant="h6">Product images</Typography> */}
                <Typography color="textSecondary" variant="subtitle2" sx={{ mt: 1 }}>
                  Upload rfa files
                </Typography>
              </Grid>
              <Grid item md={8} xs={12}>
                <FileDropzoneField
                  files={rfaFiles}
                  onDrop={handleDrop5}
                  onRemove={handleRemove5}
                  onRemoveAll={handleRemoveAll5}
                  onUpload={handleFileUpload5}
                />
                <FilesUploadedField data={formik.values.rfaFilenames} onRemove={handleRemoveAdded5} />
              </Grid>
              <Grid item xs={6}>
                <Box sx={{mt: 2}}>
                  <IconButton 
                    disabled={formik.isSubmitting}
                    type="submit"
                  >
                    <Plus />
                    <Typography>Save</Typography>
                  </IconButton>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{mt: 2}} align="right">
                  <IconButton 
                    onClick={show}
                    // type="submit"
                    color="error"
                  >
                    <Typography>Cancel</Typography>
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </form>
    </>
  );
};
