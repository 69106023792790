import { useState, useEffect, useCallback } from 'react';
import { format } from 'date-fns';
import {
  Button,
  Card,
  CardHeader,
  Divider,
  IconButton,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Grid,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import { customerApi } from '../../__fake-api__/customer-api';
import { useMounted } from '../../hooks/use-mounted';
import { MoreMenu } from '../../more-menu';
import { AddContact } from '../../add-contact';
import { Scrollbar } from '../../scrollbar';
import { Check } from '../../icons/check';
import { SeverityPill } from '../../severity-pill';
import { PencilAlt as PencilAltIcon } from '../../icons/pencil-alt';
import { ModifyContact } from '../../modify-contact';
import { Refresh as RefreshIcon} from '../../icons/refresh';
import { getBrand, getBrandProducts} from '../../api';
import { DataArraySharp } from '@mui/icons-material';
import { BrandEditForm } from './brand-edit-form';

const BrandSelector = (props) => {
  const { handleChange, brand, options } = props;
  
  return (
    <FormControl fullWidth sx={{mb: 2}}>
      <InputLabel id="brand-selector-label">Brand</InputLabel>
      <Select
        labelId="brand-selector-label"
        id="brand-selector"
        value={brand}
        label="Brand"
        onChange={handleChange}
      >
        {options?.map((option) => (
          <MenuItem key={option.brandId} value={option.brandId}>
            {option.brandName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}



export const ProductTab = (props) => {
  const isMounted = useMounted();
  const [ products, setProducts ] = useState([]);
  const { refresh, brands } = props;
  const [ selectedBrand, setSelectedBrand ] = useState("")
  const [ brandDetails, setBrandDetails ] = useState({});

  

  const handleBrandChange = (event, value) => {
    setSelectedBrand(event.target.value);
    getBrandDetails(event.target.value);
  }

  const getProducts = async (id) => {
    try {
      const data = await getBrandProducts(id);
      
      if (data.length > 0) {
        setProducts(data);
      }
    } catch (err) {
      console.error(err);
    }
  }

  const getBrandDetails = useCallback(async (id) => {
    try {
      const data = await getBrand(id);

      if (data.length > 0 && isMounted()) {
        setBrandDetails(data[0]);
        getProducts(data[0]?.brandId);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  useEffect(() => {
      if (selectedBrand !== "") {
        getBrandDetails(selectedBrand);
      } else if (selectedBrand === "" && brands.length > 0) {
        setSelectedBrand(brands[0]?.brandId)
        getBrandDetails(brands[0]?.brandId);
      }
    }, []);

  return (
    <>
      <BrandSelector handleChange={handleBrandChange} brand={selectedBrand} options={brands} />
      <Card>
        <CardHeader
          action={
          <div>            
            <Tooltip title="Refresh">
              <IconButton
                onClick={refresh}
              >
              <RefreshIcon />
            </IconButton>
          </Tooltip> 
          </div>
        }
          title="Brand details"
        />
        <Divider />
        <BrandEditForm brand={brandDetails} />
        <Divider />
        <CardHeader
        //   action={
        //   <div>            
        //     <Tooltip title="Refresh">
        //       <IconButton
        //         onClick={refresh}
        //       >
        //       <RefreshIcon />
        //     </IconButton>
        //   </Tooltip> 
        //   </div>
        // }
          title="Products"
        />
        <Divider /> 
        
        <Scrollbar>
          <Table sx={{ minWidth: 700 }}>
            <TableHead>
              <TableRow>
                {/* <TableCell>
                  Default
                </TableCell> */}
                <TableCell>
                  Name
                </TableCell>
                <TableCell>
                  Slug
                </TableCell>
                <TableCell>
                  Categories
                </TableCell>
                <TableCell>
                  Status
                </TableCell>
                <TableCell align="right">
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products.length > 0 ? products.map((data) => (
                <TableRow key={data.id}>
                  {/* <TableCell>
                    {getDefault(contact?.default)}
                  </TableCell> */}
                  <TableCell>
                    <Typography
                      color="textSecondary"
                      variant="subtitle2"
                    >
                      {data?.name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textSecondary"
                      variant="body2"
                    >
                      {data?.slug} 
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textSecondary"
                      variant="body2"
                    >
                      {data?.categories}
                    </Typography>
                  </TableCell>
                  <TableCell width="100">
                    <Typography
                      color="textSecondary"
                      variant="body2"
                    >
                      {data?.status}
                    </Typography>
                  </TableCell>
                  {/* <TableCell width="64">
                    <SeverityPill
                      color={(log.status >= 200 && log.status < 300)
                        ? 'success'
                        : 'error'}
                    >
                      {log.status}
                    </SeverityPill>
                  </TableCell> */}
                  <TableCell align="right">
                    {/* <ModifyContact companyid={props.companyid} contact={data} refresh={refresh} /> */}
                  </TableCell>
                </TableRow>
              )) : <TableRow>
                      <TableCell align="center" colSpan={6}>
                        <Typography variant="subtitle2">
                          No products
                        </Typography>
                      </TableCell>
                    </TableRow>
                  }
            </TableBody>
          </Table>
        </Scrollbar>
      </Card>
    </>
  );
};
