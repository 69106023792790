import React, { useEffect } from "react";
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useMsal } from "@azure/msal-react";

// components
import Contracts from "../components/Contracts";
// import HeroSection from "../components/Home/HeroSection";
// import CategorySection from "../components/Home/CategorySection/CategorySection";
// import FeaturedObjects from "../components/Home/FeaturedObjects/FeaturedObjects";
// import ManufactureSection from "../components/Home/ManufactureSection/ManufactureSection";
// import Footer from "../components/Footer";
// import UnauthenticatedAccess from "../components/Home/UnauthenticatedAccess";

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

import { DashboardLayout } from "../components/dashboard-layout";

const ContractPage = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const { accounts } = useMsal();
  
  const navigate = useNavigate();

  return (
    <>
      <AuthenticatedTemplate>
        <DashboardLayout>
          <Contracts />
        </DashboardLayout>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        {/* <UnauthenticatedAccess /> */}
      </UnauthenticatedTemplate>
    </>
  );
};

// Home.getLayout = (page) => (
//   <DashboardLayout>
//     {page}
//   </DashboardLayout>
// );

export default ContractPage;