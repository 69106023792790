import { useRef, useState } from 'react';
import { Tooltip, IconButton } from '@mui/material';
//import { Add } from '@mui/icons-material';
//import { Plus } from './icons/plus';
import { UserAdd } from './icons/user-add';
import { ContactModal } from './contact-modal';

export const AddContact = (props) => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  //console.log(props.type)

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Add a new contact">
        <IconButton
          onClick={handleOpen}
          // ref={anchorRef}
          // {...props}
          >
          <UserAdd />
        </IconButton>
      </Tooltip>
      <ContactModal open={open} close={handleClose} companyid={props.companyid} refresh={props.refresh}/>
      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add new contact
          </Typography>
        </Box>
      </Modal> */}
    </>
  );
};
