
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import { wait } from '../../utils/wait';

export const BrandEditForm = (props) => {
  const { brand, ...other } = props;
  const formik = useFormik({
    initialValues: {
      brandName: brand.brandName || '',
      brandSlug: brand.brandSlug || '',
      description: brand.description || '',
      contactPerson: {
        name: brand.contactPerson?.name || '',
        address: brand.contactPerson?.address || '',
        phoneNumber: brand.contactPerson?.phoneNumber || '',
        email: brand.contactPerson?.email || ''
      },
      publicContacts: brand.publicContacts || [],
      country: brand.country || ''
    },
    validationSchema: Yup.object({
      brandName: Yup.string().max(255),
      brandSlug: Yup.string().max(255),
      description: Yup.string(),
      contactPerson: Yup.object({
        name: Yup.string().max(255),
        address: Yup.string().max(255),
        phoneNumber: Yup.string().max(255),
        email: Yup.string().max(255),
      }),
      publicContacts: Yup.array(),
      country: Yup.string().max(255)
    }),
    enableReinitialize: true,
    onSubmit: async (values, helpers) => {
      try {
        // NOTE: Make API request
        await wait(500);
        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);
        toast.success('Customer updated!');
      } catch (err) {
        console.error(err);
        toast.error('Something went wrong!');
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    }
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      {...other}>
        {/* <Divider /> */}
        <Box sx={{p: 2}}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                error={Boolean(formik.touched.brandName && formik.errors.brandName)}
                fullWidth
                helperText={formik.touched.brandName && formik.errors.brandName}
                label="Full name"
                name="brandName"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                required
                value={formik.values.brandName}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                error={Boolean(formik.touched.brandSlug && formik.errors.brandSlug)}
                fullWidth
                helperText={formik.touched.brandSlug && formik.errors.brandSlug}
                label="Slug"
                name="brandSlug"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                required
                value={formik.values.brandSlug}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                error={Boolean(formik.touched.description && formik.errors.description)}
                fullWidth
                helperText={formik.touched.description && formik.errors.description}
                label="Description"
                name="description"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.description}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                error={Boolean(formik.touched.country && formik.errors.country)}
                fullWidth
                helperText={formik.touched.country && formik.errors.country}
                label="Country"
                name="country"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.country}
              />
            </Grid>
          </Grid>
          <Divider sx={{my: 2}} />
          
          <Grid
            container
            spacing={3}
          >
            <Grid item xs={12}>
              <Typography variant="subtitle2">Contact person</Typography>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                //error={Boolean(formik.touched.contactPerson.name && formik.errors.contactPerson.name)}
                fullWidth
                //helperText={formik.touched.contactPerson.name && formik.errors.contactPerson.name}
                label="Name"
                name="contactPerson.name"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.contactPerson?.name}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
               // error={Boolean(formik.touched.contactPerson.address && formik.errors.contactPerson.address)}
                fullWidth
               // helperText={formik.touched.contactPerson.address && formik.errors.contactPerson.address}
                label="Address"
                name="contactPerson.address"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.contactPerson.address}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
               // error={Boolean(formik.touched.contactPerson.phoneNumber && formik.errors.contactPerson.phoneNumber)}
                fullWidth
               // helperText={formik.touched.contactPerson.phoneNumber && formik.errors.contactPerson.phoneNumber}
                label="Phone number"
                name="contactPerson.phoneNumber"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.contactPerson.phoneNumber}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
             //   error={Boolean(formik.touched.contactPerson.email && formik.errors.contactPerson.email)}
                fullWidth
              //  helperText={formik.touched.contactPerson.email && formik.errors.contactPerson.email}
                label="Email address"
                name="contactPerson.email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.contactPerson.email}
              />
            </Grid>

          </Grid>
        </Box>  
    </form>
  );
};

// CustomerEditForm.propTypes = {
//     customer: PropTypes.object.isRequired
// };
