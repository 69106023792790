import React from "react";
import { useCollapse } from "react-collapsed";
import { IoIosArrowDown, IoIosArrowUp, IoIosCopy } from "react-icons/io";
import "./Variant.css";
import { AiFillDelete } from "react-icons/ai";
import { FaCopy, FaMinus, FaPlus, FaTrash } from "react-icons/fa";
import { Button, Grid, TextField } from "@mui/material";

const VariantContainer = (props) => {
  const config = {
    defaultExpanded: props.defaultExpanded || true,
    collapsedHeight: props.collapsedHeight || 0,
  };
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse(config);
  const { children, formik, variant, index } = props;

  const deleteItem = () => {
    formik.setFieldValue("variants", formik.values.variants.filter((_, i) => i !== index));
  }

  const duplicateItem = () => {
    formik.setFieldValue("variants", [...formik.values.variants, {...variant}]);
  }

  //console.log(variant);

  return (
    <div className="variant">
      <div className="variant-details">
        <div className="variant-name">
          <TextField
            //margin="dense"
            variant="outlined"
            label="Variant name"
            size="small"
            fullWidth
            name={`variants[${index}].variantName`}
            value={variant.variantName}
            onChange={formik.handleChange}
          />
        </div>
        
        <div className="variant-buttons">
          <div className="variant-icon" onClick={duplicateItem}>
            <FaCopy color="#175c83" />
          </div>
          <div className="variant-icon" onClick={deleteItem}>
            <FaTrash color="#175c83" />
          </div>
        </div>
        
      </div>
      <div className="variant-expanded-details">
        {isExpanded && (
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField 
                //margin="dense"
                variant="outlined"
                label="Variant name specification"
                size="small"
                fullWidth
                name={`variants[${index}].variantNameSpecification`}
                value={variant.variantNameSpecification}
                onChange={formik.handleChange}
              />
            </Grid>
          </Grid>
        )}
      </div>
      <div
        className={isExpanded ? "collapsible collapsible-active" : "collapsible"}
      >
        <div {...getCollapseProps()}>
          <div className="content">{children}</div>
        </div>
        <div className="section-header" {...getToggleProps()}>
          <div className="section-title">{isExpanded ? "Hide variant details" : "Expand variant details"}</div>

          <div className="section-buttons">
            <div className="section-icon">
              {isExpanded ? <FaMinus color="#175c83" /> : <FaPlus color="#175c83"/>}
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default VariantContainer;
