import { useState, useEffect, useCallback } from 'react';
import { format } from 'date-fns';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  IconButton,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip
} from '@mui/material';
import { customerApi } from '../../__fake-api__/customer-api';
import { useMounted } from '../../hooks/use-mounted';
import { MoreMenu } from '../../more-menu';
import { Scrollbar } from '../../scrollbar';
import { SeverityPill } from '../../severity-pill';
import { AddEvent } from '../../add-event';
import { ModifyEvent } from '../../modify-event';
import { Refresh as RefreshIcon } from '../../icons/refresh';




import { PencilAlt as PencilAltIcon } from '../../icons/pencil-alt';



export const DemoProducts = (props) => {
  const isMounted = useMounted();
  //const [logs, setLogs] = useState([]);
  const { refresh, products } = props;

  // const getLogs = useCallback(async () => {
  //   try {
  //     //const data = await customerApi.getCustomerLogs();
  //     const data = await getContactingLog(props.companyid);
  //     //console.log(data);
  //     if (isMounted()) {
  //       setLogs(data);
  //     }
  //   } catch (err) {
  //     console.error(err);
  //   }
  // }, [isMounted]);

  // useEffect(() => {
  //   getLogs();
  // }, []);

  return (
    // <Card {...props}>
    <Card>
      <CardHeader
        action={
          <div>
            <AddEvent type="new" companyid={props.companyid} refresh={refresh} />
            <Tooltip title="Refresh">
              <IconButton
                onClick={refresh}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip> 
          </div>}
        title="Products"
      />
      <Divider />
      <Scrollbar>
        <Table sx={{ minWidth: 700 }}>
          <TableHead>
            <TableRow>
              <TableCell>
                ID
              </TableCell>
              <TableCell>
                Name
              </TableCell>
              <TableCell>
                Slug
              </TableCell>
              <TableCell>
                Status
              </TableCell>
              <TableCell>
                Edit
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((product) => (
              <TableRow key={product.id}>
                <TableCell>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                  >
                  { product.id }
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                  >
                  { product.name }
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                      color="textSecondary"
                      variant="body2"
                    >
                    { product.slug }
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                      color="textSecondary"
                      variant="body2"
                    >
                    { product.status }
                  </Typography>
                </TableCell>
                <TableCell>
                  {/* <ModifyEvent eventdata={log} companyid={props.companyid} refresh={refresh}/> */}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
    </Card>
  );
};
