import React from 'react';
import { 
  Card, 
  CardContent, 
  Table, 
  TableRow, 
  TableCell, 
  Grid, 
  Typography, 
  TableBody,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableHead
} from '@mui/material';

import parse from 'html-react-parser';
import { VariantAccordion } from '../../variant-accordion';

const camelCaseToText = (text) => {
  const result = text.replace(/([A-Z])/g, " $1");
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
}

const dashedToText = (text) => {
  const result = text.replace(/-/g, ' ');;
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
}



export default function ReviewForm(props) {
  const { formik } = props;

  const generalKeys = [
    {
      name: "name", 
      label: "Product name", 
      type: "string"
    }, 
    {
      name: "brandName", 
      label: "Brand", 
      type: "string"
    }, 
    {
      name: "model", 
      label: "Model", 
      type: "string"
    }, 
    {
      name: "sku", 
      label: "SKU", 
      type: "string"
    }, 
    {
      name: "countryOfManufacture", 
      label: "Country of Manufacture", 
      type: "string"
    }, 
    {
      name: "description", 
      label: "Description", 
      type: "html" // can rich text be stored in a regular string?
    }
  ];

  const categoryKeys = [
    {
      name: "category", 
      label: "Category", 
      type: "category"
    }, 
    {
      name: "subcategory1", 
      label: "Subcategory 1", 
      type: "category"
    }, 
    {
      name: "subcategory2", 
      label: "Subcategory 2", 
      type: "category"
    }, 
    {
      name: "usage", 
      label: "Usage", 
      type: "array"
    }, 
    {
      name: "room", 
      label: "Room", 
      type: "array"
    }
  ];

  const marketAreaKeys = [
    {
      name: "europe", 
      label: "European countries", 
      type: "array"
    }, 
    {
      name: "africa", 
      label: "African countries", 
      type: "array"
    }, 
    {
      name: "asia", 
      label: "Asian countries", 
      type: "array"
    }, 
    {
      name: "northAmerica", 
      label: "North American countries", 
      type: "array"
    }, 
    {
      name: "southAmerica", 
      label: "South American countries", 
      type: "array"
    }, 
    {
      name: "antarctica", 
      label: "Antarctica", 
      type: "array"
    }, 
    {
      name: "Oceania", 
      label: "Oceanian countries", 
      type: "array"
    }
  ];


  return (
    
    <>
      <Card sx={{ mt: 2 }}>
        <CardContent>
          {/* <Typography component="h1" align="center" variant="h6" sx={{mb:2}}>Review product</Typography> */}
          <Grid container spacing={3}>
            <Grid item md={3} xs={12}>
              <Typography variant="h6">General</Typography>
            </Grid>
            <Grid item md={9} xs={12} >
              <Table>
                <TableBody>
                  {generalKeys.map((key) => {
                  
                    return (
                      <TableRow
                       // hover
                        key={key.name}
                      >
                        <TableCell sx={{width: "30%"}}>
                          <Typography
                            variant="subtitle2"
                          >
                            {key.label}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{width: "70%"}}>
                          <Typography
                            variant="body2"
                          >
                            {key.type === "html" ?  parse(`${formik.values[key.name]}`) : formik.values[key.name]}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody> 
              </Table> 
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card sx={{mt: 2}}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={3} xs={12}>
              <Typography variant="h6">Category</Typography>
            </Grid>
            <Grid item md={9} xs={12} >
              <Table>
                <TableBody>
                  {categoryKeys.map((key) => {
                    const object = formik.values[key.name];
                    //console.log(object);
                   // console.log(formik.values[key]);

                    return (
                      <TableRow
                       // hover
                        key={key.name}
                      >
                        <TableCell sx={{width: "30%"}}>
                          <Typography
                            variant="subtitle2"
                          >
                            {key.label}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{width: "70%"}}>
                          {key.type === "array" ? 
                            object.map((o, index) => 
                            <Typography
                              variant="body2"
                              key={index}
                            >
                              {dashedToText(o)}
                            </Typography>
                            ) : 
                            <Typography
                            variant="body2"   
                            >
                              {dashedToText(formik.values[key.name])}
                            </Typography>
                          }
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody> 
              </Table> 
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card sx={{mt: 2}}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid sx={{paddingBottom: 2}} item md={3} xs={12}>
              <Typography variant="h6">Technical details</Typography>
            </Grid>
            <Grid item md={9} xs={12} >
              <Table>
                <TableBody>
                  {Object.keys(formik.values.details).map((key, index) => {
                  const object = formik.values.details[key];
                  
                  return (
                      object.min !== "" || object.max !== "" || object.value !== "" || object.values.length > 0 ? 
                      <TableRow
                       // hover
                        key={index}
                      >
                        <TableCell sx={{width: "30%"}}>
                          <Typography
                            variant="subtitle2"
                          >
                            {camelCaseToText(key)}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{width: "70%"}}>
                          <Typography
                            variant="body2"
                          >
                            {object.range ? object.min + "-" + object.max + (object.unit !== "" && (" " + object.unit)) : object.values.map((value, index) => (index === 0 ? value : " " + value)) + (object.unit && (" " + object.unit))}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    : 
                    <></>
                    );
                  })}
                </TableBody> 
              </Table> 
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card sx={{mt: 2}}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item sx={{paddingBottom: 2}} md={3} xs={12}>
              <Typography variant="h6">Custom</Typography>
            </Grid>
            <Grid item md={9} xs={12} >
              <Table>
                <TableBody>
                  {formik.values.customDetails.map((object, index) => {

                  return (
                      object.min !== "" || object.max !== "" || object.value !== "" || object.values.length > 0 ? 
                        <TableRow
                        // hover
                          key={`custom-detail-${index}`}
                        >
                          <TableCell sx={{width: "30%"}}>
                            <Typography
                              variant="subtitle2"
                            >
                              {object.name}
                            </Typography>
                          </TableCell>
                          <TableCell sx={{width: "70%"}}>
                            <Typography
                              variant="body2"
                            >
                              {object.range ? object.min + "-" + object.max + (object.unit !== "" && (" " + object.unit)) : object.values.map((value, index) => (index === 0 ? value : " " + value)) + (object.unit && (" " + object.unit))}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      : 
                        <></>
                    );
                  })}
                </TableBody> 
              </Table> 
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card sx={{mt: 2}}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item sx={{paddingBottom: 2}} md={3} xs={12}>
              <Typography variant="h6">Product variants</Typography>
            </Grid>
            <Grid item md={9} xs={12} >
              <Table sx={{mb: 1}}>
                <TableBody>
                  <TableRow
                    // hover
                    key="variable-product-row"
                  >
                    <TableCell sx={{width: "30%"}}>
                      <Typography
                        variant="subtitle2"
                      >
                        Variable product
                      </Typography>
                    </TableCell>
                    <TableCell sx={{width: "70%"}}>
                      {formik.values.variableProduct && <Typography>Yes</Typography>}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              {formik.values.variants.length > 0 && <Typography sx={{m: 2}} variant="subtitle2">Variants:</Typography>}
              {formik.values.variants.map((product, index) => {
                return (
                  <VariantAccordion key={`variant-${index}`} product={product}/>
                )
              })}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card sx={{mt: 2}}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={3} xs={12}>
              <Typography variant="h6">Files</Typography>
            </Grid>
            <Grid item md={9} xs={12} >
              <Table>
                <TableBody>
                   <TableRow
                      // hover
                      key="image-files"
                    >
                      <TableCell>
                        <Typography
                            variant="subtitle2"
                        >
                          Images
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        {formik.values.images.map((key) => {
                          return (
                            <Typography variant="body2">{!key.deleted && key.name}</Typography>
                          )
                        })}
                      </TableCell>
                    </TableRow>
                    <TableRow
                      // hover
                      key="revit-files"
                    >
                      <TableCell>
                        <Typography
                            variant="subtitle2"
                        >
                          Revit files
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        {formik.values.revitFiles.map((key) => {
                          return (
                            <Typography variant="body2">{!key.deleted && key.name}</Typography>
                          )
                        })}
                      </TableCell>
                    </TableRow>
                    <TableRow
                      // hover
                      key="other-bim-files"
                    >
                      <TableCell>
                        <Typography
                            variant="subtitle2"
                        >
                          Other BIM formats
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        {formik.values.otherBimFormats.map((key) => {
                          return (
                            <Typography variant="body2">{!key.deleted && key.name}</Typography>
                          )
                        })}
                      </TableCell>
                    </TableRow>
                    <TableRow
                      // hover
                      key="documentation-files"
                    >
                      <TableCell>
                        <Typography
                            variant="subtitle2"
                        >
                          Documentation
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        {formik.values.documentation.map((key) => {
                          return (
                            <Typography variant="body2">{!key.deleted && key.name}</Typography>
                          )
                        })}
                      </TableCell>
                    </TableRow>
                </TableBody> 
              </Table> 
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
