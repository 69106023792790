import { useState, useEffect, useCallback, useRef } from 'react';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Container,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { customerApi } from '../../__fake-api__/customer-api';
import { CustomerListTable } from './customer-list-table';
import { useMounted } from '../../hooks/use-mounted';
import { Download as DownloadIcon } from '../../icons/download';
import { Plus as PlusIcon } from '../../icons/plus';
import { Refresh as RefreshIcon } from '../../icons/refresh';
import { Search as SearchIcon } from '../../icons/search';
import { Upload as UploadIcon } from '../../icons/upload';
import { AddCompany } from '../../add-company';
//import { gtm } from '../../../lib/gtm';
import { getContacting } from "../../api";
import { CustomerListFilters } from './customer-list-filters';
import { setFilters, setScrollPosition } from '../../redux/reducers/customer-reducer';
import { wait } from '../../utils/wait';
import Loader from '../Loader/loader';


const tabs = [
  {
    label: 'All',
    value: 'all'
  },
  {
    label: 'Accepts Marketing',
    value: 'hasAcceptedMarketing'
  },
  {
    label: 'Not contacted',
    value: 'Not contacted'
  }
];

const sortOptions = [
  {
    label: 'Last update (asc)',
    value: 'latestDate|asc'
  },
  {
    label: 'Last update (desc)',
    value: 'latestDate|desc'
  },
  {
    label: 'Next task (asc)',
    value: 'nextDate|asc'
  },
  {
    label: 'Next task (desc)',
    value: 'nextDate|desc'
  },
  {
    label: 'Priority (desc)',
    value: 'priority|desc'
  },
  {
    label: 'Priority (asc)',
    value: 'priority|asc'
  }
];

const statusOptions = [
  {
    label: 'Published',
    value: 'published'
  },
  {
    label: 'Draft',
    value: 'draft'
  },
  {
    label: 'Pending',
    value: 'pending'
  }
];

const applyFilters = (customers, filters) => customers.filter((customer) => {
  if (filters?.query) {
    let queryMatched = false;
    const properties = ['companyName'];

    properties.forEach((property) => {
      if ((customer[property]).toLowerCase().includes(filters.query.toLowerCase())) {
        queryMatched = true;
      }
    });

    if (!queryMatched) {
      return false;
    }
  }

  if (filters.status?.length > 0) {
    const statusMatched = filters.status.includes(customer.status);

    if (!statusMatched) {
      return false;
    }
  }

  if (filters.next?.length > 0) {
    const nextMatched = filters.next.includes(customer.nextTask);

    if (!nextMatched) {
      return false;
    }
  }

  if (filters.previous?.length > 0) {
    const previousMatched = filters.previous.includes(customer.latestContact?.contactType);

    if (!previousMatched) {
      return false;
    }
  }

  if (filters.priority?.length > 0) {
    const priorityMatched = filters.priority.includes(customer.priority);

    if (!priorityMatched) {
      return false;
    }
  }

  if (filters.hasAcceptedMarketing && !customer.hasAcceptedMarketing) {
    return false;
  }

  if (filters.isProspect && !customer.isProspect) {
    return false;
  }

  if (filters.isReturning && !customer.isReturning) {
    return false;
  }

  return true;
});

const descendingComparator = (a, b, sortBy) => {
  // When compared to something undefined, always returns false.
  // This means that if a field does not exist from either element ('a' or 'b') the return will be 0.

  if (typeof(b[sortBy]) === "undefined") {
    return -1;
  }

  if (typeof(a[sortBy]) === "undefined") {
    return 1;
  }

  if (b[sortBy] < a[sortBy]) {
    return -1;
  }

  if (b[sortBy] > a[sortBy]) {
    return 1;
  }

  return 0;
};

const getComparator = (sortDir, sortBy) => (sortDir === 'desc'
  ? (a, b) => descendingComparator(a, b, sortBy)
  : (a, b) => -descendingComparator(a, b, sortBy));

const applySort = (customers, sort) => {
  const [sortBy, sortDir] = sort.split('|');
  const comparator = getComparator(sortDir, sortBy);
  const stabilizedThis = customers.map((el, index) => [el, index]);
  //console.log(stabilizedThis);

  stabilizedThis.sort((a, b) => {
        const newOrder = comparator(a[0], b[0]);
        // console.log(a[0]);
        // console.log(b[0]);
        // console.log(newOrder);

    if (newOrder !== 0) {
      return newOrder;
    }

        return a[1] - b[1];
  });

    return stabilizedThis.map((el) => el[0]);
};

const applyPagination = (customers, page, rowsPerPage) => customers.slice(page * rowsPerPage,
  page * rowsPerPage + rowsPerPage);

const ContactingList = () => {
  const isMounted = useMounted();
  const queryRef = useRef(null);
  const [customers, setCustomers] = useState([]);
  // const [currentTab, setCurrentTab] = useState('all');
  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(50);
  const [sort, setSort] = useState(sortOptions[0].value);
  const [refresh, setRefresh] = useState(0);
  // const [filters, setFilters] = useState({
  //   query: '',
  //   // hasAcceptedMarketing: undefined,
  //   // isProspect: undefined,
  //   // isReturning: undefined,
  //   status: [],
  //   previous: [],
  //   priority: [],
  //   next: []
  // });
  const filters = useSelector((store) => store.customer.filters);
  const scrollPosition = useSelector((store) => store.customer.scrollPosition);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //   gtm.push({ event: 'page_view' });
  // }, []);

  useEffect(() => {
     const sort = localStorage.getItem("contacting-sort");

     if (sort) {
      setSort(sort)
     };
     
     if (scrollPosition.position.y > 0) {
      scrollToPrevious();
     }

  }, []);

  const scrollToPrevious = useCallback(async () => {
    try {
      await wait(300);
      
      // window.scrollTo(scrollPosition.position.x, scrollPosition.position.y);
      console.log(scrollPosition);
      
      window.scrollTo({
        top: scrollPosition.position.y,
        left: 0,
        // behavior: "smooth",
      })
      
      const newScrollPosition = (prevState) => ({
        ...prevState,
        position: {
          x: 0,
          y: 0
        }
      })
  
      dispatch(setScrollPosition(newScrollPosition(scrollPosition)));
        
      
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  const getCustomers = useCallback(async () => {
    try {
      setIsLoading(true);
      const data = await getContacting();
      
      if (data) {
        setIsLoading(false);
      }

      if (isMounted()) {
        setCustomers(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  useEffect(() => {
      getCustomers();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refresh]);

  const handleRefresh = () => {
    setRefresh(refresh + 1)
  }

  const handleFiltersChange = (filters) => {
    // setFilters(filters);
    dispatch(setFilters(filters));
  };

  // const handleTabsChange = (event, value) => {
  //   const updatedFilters = {
  //     ...filters,
  //     hasAcceptedMarketing: undefined,
  //     isProspect: undefined,
  //     isReturning: undefined
  //   };

  //   if (value !== 'all') {
  //     updatedFilters[value] = true;
  //   }

  //   setFilters(updatedFilters);
  //   setCurrentTab(value);
  // };

  const handleQueryChange = (event) => {
    event.preventDefault();
    
    const newQuery = (prevState) => ({
      ...prevState,
      query: queryRef.current?.value
    })
    dispatch(setFilters(newQuery(filters)));
  };

  const handleSortChange = (event) => {
    setSort(event.target.value);
    localStorage.setItem("contacting-sort", event.target.value)
  };

  // const handlePageChange = (event, newPage) => {
    
  //   setPage(newPage);
  // };

  const handlePageChange = (event, newPage) => {
    event.preventDefault();
    
    const newScrollPosition = (prevState) => ({
      ...prevState,
      page: newPage
    })

    console.log(newScrollPosition(scrollPosition));
    dispatch(setScrollPosition(newScrollPosition(scrollPosition)));
  };

  const handleRowsPerPageChange = (event) => {
    event.preventDefault();
    
    const newScrollPosition = (prevState) => ({
      ...prevState,
      rowsPerPage: parseInt(event.target.value, 10)
    })

    console.log(newScrollPosition(scrollPosition));
    dispatch(setScrollPosition(newScrollPosition(scrollPosition)));
  };

  // const handleRowsPerPageChange = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  // };

  // Usually query is done on backend with indexing solutions
  const filteredCustomers = applyFilters(customers, filters);
  const sortedCustomers = applySort(filteredCustomers, sort);
  const paginatedCustomers = applyPagination(sortedCustomers, scrollPosition.page, scrollPosition.rowsPerPage);

  return (
    <>
      {/* <Head>
        <title>
          Dashboard: Customer List | Material Kit Pro
        </title>
      </Head> */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8
        }}
      >
        <Container maxWidth="xl">
          <Box sx={{ mb: 4 }}>
            <Grid
              container
              justifyContent="space-between"
              spacing={3}
            >
              <Grid item >
                <Typography variant="h4" color="neutral.900" sx={{display: "inline-block"}}>
                  Customer contacts
                </Typography>
                <Tooltip title="Refresh">
                  <IconButton
                    onClick={handleRefresh}
                    sx={{mb: 1, ml: 1}}
                  >
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <AddCompany refresh={handleRefresh} />
              </Grid>
            </Grid>
          </Box>
          <Card>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexWrap: 'wrap',
                m: -1.5,
                p: 3
              }}
            >
              <Box
                component="form"
                onSubmit={handleQueryChange}
                sx={{
                  flexGrow: 1,
                  m: 1.5
                }}
              >
                <TextField
                  defaultValue=""
                  fullWidth
                  inputProps={{ ref: queryRef }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon fontSize="small" />
                      </InputAdornment>
                    )
                  }}
                  placeholder="Search companies"
                />
              </Box>
              <TextField
                label="Sort By"
                name="sort"
                onChange={handleSortChange}
                select
                SelectProps={{ native: true }}
                sx={{ m: 1.5 }}
                value={sort}
              >
                {sortOptions.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Box>
            <Divider />
            <Box>
              <CustomerListFilters filters={filters} onChange={handleFiltersChange} />
              {isLoading ?
                <Box sx={{height: 400}}>
                  <Loader />
                </Box> 
                  :
                <>
                  
                  <CustomerListTable
                    customers={paginatedCustomers}
                    customersCount={filteredCustomers.length}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                    rowsPerPage={scrollPosition.rowsPerPage}
                    page={scrollPosition.page}
                    refresh={handleRefresh}
                  />
                </>
              }
            </Box>
          </Card>
        </Container>
      </Box>
    </>
  );
};

// CustomerList.getLayout = (page) => (
//   <AuthGuard>
//     <DashboardLayout>
//       {page}
//     </DashboardLayout>
//   </AuthGuard>
// );

export default ContactingList;