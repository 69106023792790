import { configureStore } from "@reduxjs/toolkit";
import { userSlice, customerSlice, productSlice } from "../reducers";

export const store = configureStore({
  reducer: {
    customer: customerSlice,
    user: userSlice,
    product: productSlice
  },
});
