import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Chip, Divider, Input, Typography } from '@mui/material';
import { useUpdateEffect } from '../../hooks/use-update-effect';
import { Search as SearchIcon } from '../../icons/search';
import { MultiSelect } from '../multi-select';
import { setFilterItems } from '../../redux/reducers/product-reducer';


const statusOptions = [
  {
    label: 'Draft',
    value: 'draft'
  },
  {
    label: 'Pending',
    value: 'pending'
  },
  {
    label: 'Processed',
    value: 'processed'
  },
  {
    label: 'Published',
    value: 'published'
  }
];


// const stockOptions = [
//   {
//     label: 'All',
//     value: 'all'
//   },
//   {
//     label: 'Available',
//     value: 'available'
//   },
//   {
//     label: 'Out of Stock',
//     value: 'outOfStock'
//   }
// ];

export const ProductListFilters = (props) => {
  const { onChange, filters, ...other } = props;
  const [queryValue, setQueryValue] = useState('');
 // const [filterItems, setFilterItems] = useState([]);
  const filterItems = useSelector((store) => store.product.filterItems);
  const dispatch = useDispatch();


  useUpdateEffect(() => {
    const filters = {
      query: '',
      status: []
    };

    // setFilterItems(filters);

    
    // Transform the filter items in an object that can be used by the parent component to call the
    // serve with the updated filters
    filterItems.forEach((filterItem) => {
      //console.log(filterItem);
      switch (filterItem.field) {
        case 'query':
          // There will (or should) be only one filter item with field "name"
          // so we can set up it directly
          filters.query = filterItem.value;
          break;
        case 'status':
          filters.status.push(filterItem.value);
          break;
        default:
          break;
      }
    });

    //console.log(filters);

    onChange?.(filters);
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [filterItems]);

  const handleDelete = (filterItem) => {
    const removedItem = (prevState) => prevState.filter((_filterItem) => {
      return !(filterItem.field === _filterItem.field && filterItem.value === _filterItem.value);
    });

    dispatch(setFilterItems(removedItem(filterItems)));
  };



  const handleStatusChange = (values) => {
    const newItems = (prevState) => {
      const valuesFound = [];

      // First cleanup the previous filter items
      const newFilterItems = prevState.filter((filterItem) => {
        if (filterItem.field !== 'status') {
          return true;
        }

        const found = values.includes(filterItem.value);

        if (found) {
          valuesFound.push(filterItem.value);
        }

        return found;
      });

      // Nothing changed
      if (values.length === valuesFound.length) {
        return newFilterItems;
      }

      values.forEach((value) => {
        if (!valuesFound.includes(value)) {
          const option = statusOptions.find((option) => option.value === value);

          newFilterItems.push({
            label: 'Status',
            field: 'status',
            value,
            displayValue: option.label
          });
        }
      });

      return newFilterItems;
    };
    
    dispatch(setFilterItems(newItems(filterItems)));
  };

  // We memoize this part to prevent re-render issues

  //console.log(filterItems);

  const statusValueCheck = (items) => items
    .filter((filterItems) => filterItems.field === 'status')
    .map((filterItems) => filterItems.value);

  const statusValues = useMemo(() => statusValueCheck(filterItems), [filterItems]);


  return (
    <div {...other}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          p: 1
        }}
      >
        <MultiSelect
          label="Status"
          onChange={handleStatusChange}
          options={statusOptions}
          value={statusValues}
        />
      </Box>
      <Divider />
      {filterItems.length > 0
        ? (
          <><Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexWrap: 'wrap',
              p: 2
            }}
          >
            {filterItems.map((filterItem, i) => (
              <Chip
                key={i}
                label={(
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      '& span': {
                        fontWeight: 600
                      }
                    }}
                  >
                      <span>
                        {filterItem.label}
                      </span>
                    :
                    {' '}
                    {filterItem.displayValue || filterItem.value}
                  </Box>
                )}
                onDelete={() => handleDelete(filterItem)}
                sx={{ m: 1 }}
                variant="outlined"
              />
            ))}
          </Box>
          <Divider />
          </>
        )
        : (
          <>
          </>
        )}
    </div>
  );
};

// ProjectListFilters.propTypes = {
//   onChange: PropTypes.func
// };
