import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Chip, Divider, Input, Typography } from '@mui/material';
import { useUpdateEffect } from '../../hooks/use-update-effect';
import { Search as SearchIcon } from '../../icons/search';
import { MultiSelect } from '../multi-select';
import { setFilterItems } from '../../redux/reducers/customer-reducer';


const statusOptions = [
  {
    label: 'Not contacted',
    value: 'Not contacted'
  },
  {
    label: 'Contacted',
    value: 'Contacted'
  },
  {
    label: 'Brochure sent',
    value: 'Brochure sent'
  },
  {
    label: 'Demo sent',
    value: 'Demo sent'
  },
  {
    label: 'Contract sent',
    value: 'Contract sent'
  },
  // {
  //   label: 'Contract signed',
  //   value: 'Contract signed'
  // },
  {
    label: 'On board',
    value: 'On board'
  }
];

const nextOptions = [
  {
    label: 'Video call',
    value: 'Video call'
  },
  {
    label: 'Wait for feedback',
    value: 'Wait for feedback'
  },
  {
    label: 'Contact again',
    value: 'Contact again'
  },
  {
    label: 'Send brochure',
    value: 'Send brochure'
  },
  {
    label: 'Send demo',
    value: 'Send demo'
  },
  {
    label: 'Send contract',
    value: 'Send contract'
  },
  {
    label: 'No follow-up',
    value: 'No follow-up'
  }
];

const previousOptions = [
  {
    label: 'Company added',
    value: 'Company added'
  },
  {
    label: 'Contacted by phone',
    value: 'Contacted by phone'
  },
  {
    label: 'Contacted by email',
    value: 'Contacted by email'
  },
  {
    label: 'Demo sent',
    value: 'Demo sent'
  },
  {
    label: 'Contract sent',
    value: 'Contract sent'
  }
];

const priorityOptions = [
  {
    label: 'High',
    value: 3
  },
  {
    label: 'Mid',
    value: 2
  },
  {
    label: 'Low',
    value: 1
  }
];

// const stockOptions = [
//   {
//     label: 'All',
//     value: 'all'
//   },
//   {
//     label: 'Available',
//     value: 'available'
//   },
//   {
//     label: 'Out of Stock',
//     value: 'outOfStock'
//   }
// ];

export const CustomerListFilters = (props) => {
  const { onChange, filters, ...other } = props;
  const [queryValue, setQueryValue] = useState('');
  const filterItems = useSelector((store) => store.customer.filterItems);
  const dispatch = useDispatch();


  useUpdateEffect(() => {
    const filters = {
      query: '',
      status: [],
      previous: [],
      priority: [],
      next: []
    };

    // setFilterItems(filters);

    
    // Transform the filter items in an object that can be used by the parent component to call the
    // serve with the updated filters
    filterItems.forEach((filterItem) => {
      //console.log(filterItem);
      switch (filterItem.field) {
        case 'query':
          // There will (or should) be only one filter item with field "name"
          // so we can set up it directly
          filters.query = filterItem.value;
          break;
        case 'category':
          filters.category.push(filterItem.value);
          break;
        case 'status':
          filters.status.push(filterItem.value);
          break;
        case 'next':
          filters.next.push(filterItem.value);
          break;
        case 'previous':
          filters.previous.push(filterItem.value);
          break;
        case 'priority':
          filters.priority.push(filterItem.value);
          break;
        case 'inStock':
          // The value can be "available" or "outOfStock" and we transform it to a boolean
          filters.inStock = filterItem.value === 'available';
          break;
        default:
          break;
      }
    });

    //console.log(filters);

    onChange?.(filters);
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [filterItems]);

  const handleDelete = (filterItem) => {
    const removedItem = (prevState) => prevState.filter((_filterItem) => {
      return !(filterItem.field === _filterItem.field && filterItem.value === _filterItem.value);
    });

    dispatch(setFilterItems(removedItem(filterItems)));
  };

  // const handleQueryChange = (event) => {
  //   setQueryValue(event.target.value);
  // };

  // const handleQueryKeyup = (event) => {
  //   if (event.code === 'Enter' && queryValue) {
  //     // We only allow one chip for the name field

  //     const filterItem = filterItems.find((filterItem) => filterItem.field === 'name');

  //     if (filterItem) {
  //       setFilterItems((prevState => prevState.map((filterItem) => {
  //         if (filterItem.field === 'name') {
  //           return {
  //             ...filterItem,
  //             value: queryValue
  //           };
  //         }

  //         return filterItem;
  //       })));
  //     } else {
  //       setFilterItems((prevState) => [
  //         ...prevState,
  //         {
  //           label: 'Name',
  //           field: 'name',
  //           value: queryValue
  //         }
  //       ]);
  //     }

  //     setQueryValue('');
  //   }
  // };



  const handleStatusChange = (values) => {
    const newItems = (prevState) => {
      const valuesFound = [];

      // First cleanup the previous filter items
      const newFilterItems = prevState.filter((filterItem) => {
        if (filterItem.field !== 'status') {
          return true;
        }

        const found = values.includes(filterItem.value);

        if (found) {
          valuesFound.push(filterItem.value);
        }

        return found;
      });

      // Nothing changed
      if (values.length === valuesFound.length) {
        return newFilterItems;
      }

      values.forEach((value) => {
        if (!valuesFound.includes(value)) {
          const option = statusOptions.find((option) => option.value === value);

          newFilterItems.push({
            label: 'Status',
            field: 'status',
            value,
            displayValue: option.label
          });
        }
      });

      return newFilterItems;
    };
    
    dispatch(setFilterItems(newItems(filterItems)));
  };

  const handleNextChange = (values) => {
    const newItems = (prevState) => {
      const valuesFound = [];

      // First cleanup the previous filter items
      const newFilterItems = prevState.filter((filterItem) => {
        if (filterItem.field !== 'next') {
          return true;
        }

        const found = values.includes(filterItem.value);

        if (found) {
          valuesFound.push(filterItem.value);
        }

        return found;
      });

      // Nothing changed
      if (values.length === valuesFound.length) {
        return newFilterItems;
      }

      values.forEach((value) => {
        if (!valuesFound.includes(value)) {
          const option = nextOptions.find((option) => option.value === value);

          newFilterItems.push({
            label: 'Next',
            field: 'next',
            value,
            displayValue: option.label
          });
        }
      });

      return newFilterItems;
    }
    
    dispatch(setFilterItems(newItems(filterItems)));
  };

  const handlePreviousChange = (values) => {
    const newItems = (prevState) => {
      const valuesFound = [];

      // First cleanup the previous filter items
      const newFilterItems = prevState.filter((filterItem) => {
        if (filterItem.field !== 'previous') {
          return true;
        }

        const found = values.includes(filterItem.value);

        if (found) {
          valuesFound.push(filterItem.value);
        }

        return found;
      });

      // Nothing changed
      if (values.length === valuesFound.length) {
        return newFilterItems;
      }

      values.forEach((value) => {
        if (!valuesFound.includes(value)) {
          const option = previousOptions.find((option) => option.value === value);

          newFilterItems.push({
            label: 'Previous',
            field: 'previous',
            value,
            displayValue: option.label
          });
        }
      });

      return newFilterItems;
    }
    
    dispatch(setFilterItems(newItems(filterItems)));
  };

  const handlePriorityChange = (values) => {
    const newItems = (prevState) => {
      const valuesFound = [];

      // First cleanup the previous filter items
      const newFilterItems = prevState.filter((filterItem) => {
        if (filterItem.field !== 'priority') {
          return true;
        }

        const found = values.includes(filterItem.value);

        if (found) {
          valuesFound.push(filterItem.value);
        }

        return found;
      });

      // Nothing changed
      if (values.length === valuesFound.length) {
        return newFilterItems;
      }

      values.forEach((value) => {
        value = parseInt(value);
        //console.log(typeof(priorityOptions[0].value));
        if (!valuesFound.includes(value)) {
          const option = priorityOptions.find((option) => option.value === value);
         // console.log(option);
          newFilterItems.push({
            label: 'Priority',
            field: 'priority',
            value,
            displayValue: option?.label
          });
        }
      });

      return newFilterItems;
    }
    dispatch(setFilterItems(newItems(filterItems)));
  };

  // We memoize this part to prevent re-render issues

  //console.log(filterItems);

  const statusValueCheck = (items) => items
    .filter((filterItems) => filterItems.field === 'status')
    .map((filterItems) => filterItems.value);

  const statusValues = useMemo(() => statusValueCheck(filterItems), [filterItems]);

  const nextValueCheck = (items) => items
  .filter((filterItems) => filterItems.field === 'next')
  .map((filterItems) => filterItems.value);

  const nextValues = useMemo(() => nextValueCheck(filterItems), [filterItems]);

  const previousValueCheck = (items) => items
  .filter((filterItems) => filterItems.field === 'previous')
  .map((filterItems) => filterItems.value);

  const previousValues = useMemo(() => previousValueCheck(filterItems), [filterItems]);

  const priorityValueCheck = (items) => items
  .filter((filterItems) => filterItems.field === 'priority')
  .map((filterItems) => filterItems.value);

  const priorityValues = useMemo(() => priorityValueCheck(filterItems), [filterItems]); 

  return (
    <div {...other}>
      {/* <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          p: 2
        }}
      >
        <SearchIcon fontSize="small" />
        <Box
          sx={{
            flexGrow: 1,
            ml: 3
          }}
        >
          <Input
            disableUnderline
            fullWidth
            onChange={handleQueryChange}
            onKeyUp={handleQueryKeyup}
            placeholder="Search by product name"
            value={queryValue}
          />
        </Box>
      </Box> 
      <Divider /> */}
      
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          p: 1
        }}
      >
        <MultiSelect
          label="Status"
          onChange={handleStatusChange}
          options={statusOptions}
          value={statusValues}
        />
        <MultiSelect
          label="Next"
          onChange={handleNextChange}
          options={nextOptions}
          value={nextValues}
        />
        <MultiSelect
          label="Previous"
          onChange={handlePreviousChange}
          options={previousOptions}
          value={previousValues}
        />
        <MultiSelect
          label="Priority"
          onChange={handlePriorityChange}
          options={priorityOptions}
          value={priorityValues}
        />
      </Box>
      <Divider />
      {filterItems.length > 0
        ? (
          <><Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexWrap: 'wrap',
              p: 2
            }}
          >
            {filterItems.map((filterItem, i) => (
              <Chip
                key={i}
                label={(
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      '& span': {
                        fontWeight: 600
                      }
                    }}
                  >
                      <span>
                        {filterItem.label}
                      </span>
                    :
                    {' '}
                    {filterItem.displayValue || filterItem.value}
                  </Box>
                )}
                onDelete={() => handleDelete(filterItem)}
                sx={{ m: 1 }}
                variant="outlined"
              />
            ))}
          </Box>
          <Divider />
          </>
        )
        : (
          <>
          </>
        )}
    </div>
  );
};

// ProjectListFilters.propTypes = {
//   onChange: PropTypes.func
// };
