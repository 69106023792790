import { useState, useEffect, useCallback } from 'react';
import { format } from 'date-fns';
import {
  Button,
  Card,
  CardHeader,
  Divider,
  IconButton,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip
} from '@mui/material';
import { customerApi } from '../../__fake-api__/customer-api';
import { useMounted } from '../../hooks/use-mounted';
import { MoreMenu } from '../../more-menu';
import { AddContact } from '../../add-contact';
import { Scrollbar } from '../../scrollbar';
import { Check } from '../../icons/check';
import { SeverityPill } from '../../severity-pill';
import { PencilAlt as PencilAltIcon } from '../../icons/pencil-alt';
import { ModifyContact } from '../../modify-contact';
import { Refresh as RefreshIcon} from '../../icons/refresh';

export const CompanyContacts = (props) => {
  const isMounted = useMounted();
  const { refresh, contacts } = props;
  // const [contacts, setContacts] = useState([]);

  const getDefault = (value) => {
    if (value) {
      return <Check />
    } 
  }
  
  // const getContactDetails = useCallback(async () => {
  //   try {
  //     const data = await getContacts(props.companyid);

  //     if (isMounted()) {
  //       setContacts(data);
  //     }
  //   } catch (err) {
  //     console.error(err);
  //   }
  // }, [isMounted]);

  // useEffect(() => {
  //   getContactDetails();
  // }, [getContactDetails]);



  return (
    // <Card {...props}>
    <Card>
      <CardHeader
        action={
        <div>            
          <AddContact companyid={props.companyid} refresh={refresh} />
          <Tooltip title="Refresh">
            <IconButton
              onClick={refresh}
            >
            <RefreshIcon />
          </IconButton>
        </Tooltip> 
        </div>
      }
        title="Contacts"
      />
      <Divider />
      <Scrollbar>
        <Table sx={{ minWidth: 700 }}>
          <TableHead>
            <TableRow>
              <TableCell>
                Default
              </TableCell>
              <TableCell>
                Contact Name
              </TableCell>
              <TableCell>
                Role
              </TableCell>
              <TableCell>
                Country
              </TableCell>
              <TableCell>
                Email address
              </TableCell>
              <TableCell>
                Phone number
              </TableCell>
              <TableCell align="right">
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contacts.map((contact) => (
              <TableRow key={contact.id}>
                <TableCell>
                  {getDefault(contact?.default)}
                </TableCell>
                <TableCell>
                  <Typography
                    color="textSecondary"
                    variant="subtitle2"
                  >
                    {contact?.title} {contact?.name}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    color="textSecondary"
                    variant="subtitle2"
                  >
                    {contact?.role} 
                  </Typography>
                </TableCell>
                <TableCell>
                <Typography
                    color="textSecondary"
                    variant="body2"
                  >
                    {contact.country}
                  </Typography>
                </TableCell>
                <TableCell>
                <Typography
                    color="textSecondary"
                    variant="body2"
                  >
                    {contact.email}
                  </Typography>
                </TableCell>
                <TableCell width="100">
                  <Typography
                    color="textSecondary"
                    variant="body2"
                  >
                    {contact.phone}
                  </Typography>
                </TableCell>
                {/* <TableCell width="64">
                  <SeverityPill
                    color={(log.status >= 200 && log.status < 300)
                      ? 'success'
                      : 'error'}
                  >
                    {log.status}
                  </SeverityPill>
                </TableCell> */}

                <TableCell align="right">

                  <ModifyContact companyid={props.companyid} contact={contact} refresh={refresh} />
                  {/* <ModifyContact companyid={props.companyid} /> */}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
    </Card>
  );
};
