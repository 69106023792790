import { Box, Button, Chip, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { FieldArray, FormikProvider } from "formik";
import { Plus } from "../icons/plus";
import { OutlinedBox } from "./outlined-box";
import DeleteIcon from '@mui/icons-material/Delete';

export const CustomParameterField = (props) => {  
  const { 
    formik,
  } = props;

  const tableOptions = [
    undefined,
    "Acoustic",
    "Appearance",
    "Approvals",
    "Construction",
    "Dimensions",
    "Electrical",
    "Energy efficiency",
    "Fire properties",
    "Lighting features",
    "Materials",
    "Quantities",
    "Technical features",
    "Sustainability"
  ];

  const handleEnter = (e, arrayIndex) => {
    if (e.keyCode == 13) {
      const arr = [...formik.values.customDetails[arrayIndex].values];
      //console.log(arr)
      arr.push(e.target.value)
      
      const valuesName = `customDetails[${arrayIndex}].values`;
      const valueName = `customDetails[${arrayIndex}].value`;
      formik.setFieldValue(valuesName, arr)
      formik.setFieldValue(valueName, "")
    }
  }
  
  const handleBlur = (e, arrayIndex) => {
    // formik.handleBlur(e)
    console.log(arrayIndex)
    console.log(e.target.value)
    if (e.target.value !== "") {
      const arr = [...formik.values.customDetails[arrayIndex].values];
      //console.log(arr)
      arr.push(e.target.value)
      
      const valuesName = `customDetails[${arrayIndex}].values`;
      const valueName = `customDetails[${arrayIndex}].value`;
      formik.setFieldValue(valuesName, arr)
      formik.setFieldValue(valueName, "")
    }
  }

  const handleDeleteChip = (item, index, arrayIndex) => {
    // const array = values;
    // const new_array = array.splice(index, 1)
    // const values_name = name + "s";
    const arr = [...formik.values.customDetails[arrayIndex].values];
    arr.splice(index, 1)
    
    const variableName = `customDetails[${arrayIndex}].values`;
    formik.setFieldValue(variableName, arr)
    //console.log(formik.values.customDetails[arrayIndex].name)
  }

  const handleDeleteCustomVariable = (index) => {
    let array = formik.values.customDetails;
    array.splice(index, 1);
    formik.setFieldValue("customDetails", array)
  }

  const addVariable = () => {
    let output = formik.values.customDetails;
    
    const newVariable = {
      name: '',
      label: '',
      table: '',
      value: '',
      values: [],
      min: '',
      max: '',
      unit: '',
      standard: '', 
      range: false,
      multipleValues: false
    }

    output.push(newVariable);
    formik.setFieldValue("customDetails", output);
  }
  
  return (
    <>
      <FormikProvider value={formik}>
        <FieldArray 
          name="customDetails" 
          render={() => (
            <div>
              {formik.values.customDetails.map((p, index) => {
                const name = `customDetails[${index}].name`;
                const value = `customDetails[${index}].value`;
                const table = `customDetails[${index}].table`;
                const range = `customDetails[${index}].range`;
                const unit = `customDetails[${index}].unit`;
                const min = `customDetails[${index}].min`;
                const max = `customDetails[${index}].max`;
                
                return (
                  <Box key={index} sx={{mb: 1}}>
                    <OutlinedBox label={`Custom: ${p.name}`}>
                      <Grid container spacing={1}>
                        <Grid item xs={7}>
                          <Grid container spacing={1}>
                            <Grid item xs={4}>
                              <TextField
                                //margin="dense"
                                variant="outlined"
                                label="Parameter name"
                                size="small"
                                name={name}
                                value={p.name}
                                fullWidth
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                
                              />
                            </Grid>
                            <Grid item xs={5}>
                              {!p.range ? 
                                <Box>
                                  <TextField
                                    //margin="dense"
                                    variant="outlined"
                                    label="Value"
                                    size="small"
                                    name={value}
                                    value={p.value}
                                    fullWidth
                                    onKeyDown={(e) => handleEnter(e, index)}
                                    onChange={formik.handleChange}
                                    onBlur={(e) => handleBlur(e, index)}
                                  />                                      
                                </Box>
                                :
                                <Grid container spacing={1}>
                                  <Grid item xs={6}>
                                    <TextField
                                      //margin="dense"
                                      variant="outlined"
                                      label="Min. value"
                                      size="small"
                                      name={min}
                                      value={p.min}
                                      fullWidth
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      
                                    />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <TextField
                                      //margin="dense"
                                      variant="outlined"
                                      label="Max. value"
                                      size="small"
                                      name={max}
                                      value={p.max}
                                      fullWidth
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      
                                    />
                                  </Grid>
                                </Grid>
                              }
                            </Grid>
                            <Grid item xs={3}>
                              <TextField
                                //margin="dense"
                                variant="outlined"
                                label="Unit"
                                size="small"
                                name={unit}
                                value={p.unit}
                                fullWidth
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={5}>
                          <Grid container spacing={1}>
                            <Grid item xs={5}>
                              {!p.range ? 
                                <Button sx={{p: 0}} size="small" onClick={() => formik.setFieldValue(range, true)} fullWidth>
                                  <Typography variant="caption">
                                    <b>Values</b><br/>
                                    Switch to range
                                  </Typography>
                                </Button> : 
                                <Button sx={{p: 0}} size="medium" onClick={() => formik.setFieldValue(range, false)} fullWidth>
                                  <Typography variant="caption">
                                    <b>Range</b><br/>
                                    Switch to values
                                  </Typography>
                                </Button>
                              }
                            </Grid>
                            <Grid item xs={5}>
                              <FormControl fullWidth>
                                <InputLabel size="small" id="table-select-label">Table</InputLabel>
                                <Select
                                  size='small'
                                  value={p.table}
                                  name={table}
                                  label="Table"
                                  onChange={formik.handleChange}
                                >
                                  {tableOptions.map((key, index) => {
                                    return (
                                      <MenuItem key={index} value={key}>{key}</MenuItem>
                                    )
                                  })}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={2} sx={{
                              display: "flex",
                              alignContent: "center",
                              justifyContent: "center"
                            }}>
                              {/* <Button  size="small" color="error" fullWidth onClick={() => handleDeleteCustomVariable(index)}>X</Button> */}
                              <IconButton aria-label="delete" color="error" size="small" onClick={() => handleDeleteCustomVariable(index)}>
                                <DeleteIcon fontSize="inherit" />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Grid>
                        {!p.range && (p.values.length > 0 && <Grid item xs={12}>
                          <Grid container spacing={1}>
                            <Grid item>
                              <Typography variant="caption">Added values:</Typography>
                            </Grid>
                            <Grid item>
                              {p.values.map((value, idx) => (<Chip sx={{borderRadius: 1, mr: 0.5, mb: 0.5}} size="small" key={`main-chip-values-${idx}`} onDelete={()=>handleDeleteChip(value,idx,index)} label={`${value}${p.unit === "" ? "" : (" " + p.unit)}`}/>))}
                            </Grid>
                          </Grid>
                        </Grid>)}
                      </Grid>
                    </OutlinedBox>
                  </Box>
                );
              })}
            </div>
          )}
        />
      </FormikProvider>
      <Button
        sx={{
          mr: "auto",
        }}
        size="small"
        variant="outlined"
        onClick={addVariable}
        startIcon={<Plus />}
      >
        Add variable
      </Button>
    </>
  )
};