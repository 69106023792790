import { useRef, useState, useEffect } from 'react';
import { Box, Typography, Grid, Tooltip, IconButton, Modal, TextField, FormGroup, FormControlLabel, FormControl, InputLabel, Select, MenuItem, Checkbox, Switch, Divider } from '@mui/material';
//import { Add } from '@mui/icons-material';
import { Plus } from './icons/plus';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { useFormik } from 'formik';
//import { uuid } from 'uuidv4';
import {v4 as uuidv4} from 'uuid';
import { postContact, updateContact, updateContacting } from './api';
import { Refresh } from '@mui/icons-material';
import { wait } from './utils/wait';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #313133',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
};

export const ContactModal = (props) => {
  const { contact, refresh, close, edited, companyid, ...other } = props;
  const formik = useFormik({
    initialValues: {
      id: contact?.id || uuidv4(),
      companyId: contact?.companyId || companyid,
      title: contact?.title || '',
      role: contact?.role || '',
      name: contact?.name || '',
      country: contact?.country || '',
      email: contact?.email || '',
      directNumber: contact?.directNumber || '',
      phone: contact?.phone || '',
      default: contact?.default || true,
      submit: null
    },
    validationSchema: Yup.object({
      id: Yup.string().max(255),
      companyId: Yup.string().max(255),
      title: Yup.string().max(50),
      role: Yup.string().max(255),
      name: Yup.string().max(255),
      country: Yup.string().max(255),
      email: Yup
        .string()
        .email('Must be a valid email')
        .max(255),
      directNumber: Yup.string().max(255),
      phone: Yup.string().max(25),
      default: Yup.bool()
    }),
    onSubmit: async (values, helpers) => {
      try {
        // NOTE: Make API request
        //await wait(500);
       let responseMessage = "";
       
        if (edited) {
          responseMessage = await updateContact(values);
        } else {
          responseMessage = await postContact(values);
        }

        if (formik.values.default) {

          // const removeFrom = {
          //   id: formik.values.companyId
          // };
          // await wait(2000);

          // const responseDefaultRemoval = await removeDefaults(removeFrom);
          
          const payload = {
            id: formik.values.companyId,
            defaultContact: {
              id: formik.values.id,
              title: formik.values.title,
              name: formik.values.name,
              role: formik.values.role,
              country: formik.values.country,
              email: formik.values.email,
              directNumber: formik.values.directNumber, 
              phone: formik.values.phone,
              default: formik.values.default
            }
          }

          await updateContacting(payload);
        }

        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);
        toast.success('Contact updated!');
        await wait(500);
        helpers.resetForm();
        setNewIds();
        refresh();
        close();
      } catch (err) {
        console.error(err);
        toast.error('Something went wrong!');
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    }
  });
  

  const getText = (edited) => {
    if (edited) {
      return "Edit contact";
    } else {
      return "Add new contact";
    }
  }

  const setNewIds = () => {
    formik.setFieldValue('id', uuidv4());
  }


  return (
    <>
      <Modal
        open={props.open}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form
        onSubmit={formik.handleSubmit}
        // {...other}
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{mb: 2, width: "100%"}}>
              {getText(edited)}
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label" >Title</InputLabel>
                  <Select
                    labelId="simple-select-label"
                    label="Title"
                    name="title"
                    value={formik.values.title}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  >
                    <MenuItem value={""}></MenuItem>
                    <MenuItem value={"Ms"}>Ms</MenuItem>
                    <MenuItem value={"Mrs"}>Mrs</MenuItem>
                    <MenuItem value={"Mr"}>Mr</MenuItem>
                  </Select>
                </FormControl>
              </Grid> 
              {/* <TextField
                id="outlined-multiline-flexible"
                label="Company ID"
                name="companyId"
                sx={{mb: 2, width: "100%"}}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.companyId}
              /> */}
              <Grid item xs={9}>
                <TextField
                  fullWidth
                  id="outlined-multiline-flexible"
                  label="Name"
                  name="name"
                  //sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.name}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="outlined-multiline-flexible"
                  label="Role"
                  name="role"
                  //sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.role}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="outlined-multiline-flexible"
                  label="Country"
                  name="country"
                  //sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.country}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="outlined-multiline-flexible"
                  label="Email"
                  name="email"
                 // sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label" >Direct/Info</InputLabel>
                  <Select
                    labelId="simple-select-label"
                    label="Direct/info"
                    name="directNumber"
                    value={formik.values.directNumber}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  >
                    <MenuItem value={""}></MenuItem>
                    <MenuItem value={"Direct"}>Direct number</MenuItem>
                    <MenuItem value={"Info desk"}>Info desk number</MenuItem>
                  </Select>
                </FormControl>
              </Grid> 
              <Grid item xs={8}>
                <TextField
                  fullWidth
                  id="outlined-multiline-flexible"
                  label="Phone number"
                  name="phone"
                  //sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.phone}
                />
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'space-between',
                    mt: 3
                  }}
                >
                  <div>
                    <Typography
                      gutterBottom
                      variant="subtitle1"
                    >
                      Default contact
                    </Typography>
                  </div>
                  <Switch
                    checked={formik.values.default}
                    color="primary"
                    edge="start"
                    name="default"
                    onChange={formik.handleChange}
                    value={formik.values.default}
                  />
                </Box>
              </Grid>
            </Grid>
            {/* <FormGroup>
              <FormControlLabel control={<Checkbox
              sx={{mb: 2}}
              checked={checked}
              onChange={handleCheck}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            } label="Default" />
            </FormGroup> */}
            <Divider sx={{my: 1}}/>
            <IconButton 
              disabled={formik.isSubmitting}
              type="submit"
            >
              <Plus />
              <Typography>Save</Typography>
            </ IconButton>
          </Box>
        </form>
      </Modal>
    </>
  );
};
