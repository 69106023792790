import { useState, useEffect } from 'react';
import { 
  Card, 
  CardContent,
  CardHeader,
  Grid, 
  Box, 
  Typography, 
  IconButton, 
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip, 
  FormControl, 
  InputLabel, 
  Select, 
  Stack, 
  MenuItem 
} from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { Plus } from './icons/plus';
import dayjs, { Dayjs} from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import * as Yup from 'yup';
import { useFormik } from 'formik';
//import { uuid } from 'uuidv4';
import {v4 as uuidv4} from 'uuid';
import { postContactingLog, updateContactingLog, upload } from './api';
import toast from 'react-hot-toast';
import { wait } from './utils/wait';
import { FileDropzoneField } from './components/file-dropzone-field';
import { DemoImportForm } from './demo-import-form';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  border: '1px solid #313133',
  borderRadius: '8px',
  boxShadow: 24,
  overflowY: "auto",
  maxHeight: "90%",
  //overflow:'scroll',
  p: 4,
};

export const DemoImportModal = (props) => {
  const { data, close, refresh, edited, companyid, ...other } = props;
  const [showForm, setShowForm] = useState(false);


  const handleShowForm = () => {
    if (showForm) {
      setShowForm(false);
    } else {
      setShowForm(true);
    }
  }


  return (
    <>
      <Modal
        open={props.open}
        onClose={props.close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{mb: 2, width: "100%"}}>
            Import demo products
          </Typography>
          <Card sx={{mb: 2}}>
            <CardContent sx={{p: 0}}>
              <TableContainer style={{ maxHeight: 300, overflow: "auto" }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">
                        Name
                      </TableCell>
                      <TableCell align="center">
                        URL
                      </TableCell>
                      <TableCell align="center">
                        JSON
                      </TableCell>
                      <TableCell align="center">
                        Images
                      </TableCell>
                      <TableCell align="center">
                        Glb
                      </TableCell>
                      <TableCell align="center">
                        Edit
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((product) => (
                      <TableRow key={product.id}>
                        <TableCell align="center">
                          <Typography variant="caption">
                            {product?.name}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="caption">
                            {product?.url}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="caption">
                            {product?.jsonFilename?.length}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="caption">
                            {product?.imageFilenames?.length}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="caption">
                            {product?.glbFilenames?.length}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="caption">
                            
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>        
          <div>
            {showForm
            ? <DemoImportForm show={handleShowForm} refresh={refresh} edited={edited} companyid={companyid} />
            : <Tooltip title="Add Product">
                <IconButton
                  onClick={handleShowForm}
                  // ref={anchorRef}
                  // {...props}
                  >
                  <Plus />
                </IconButton>
              </Tooltip>
            }
          </div>
        </Box>
      </Modal>
    </>
  );
};
