import { useState, useEffect, useCallback } from 'react';
import { format } from 'date-fns';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  IconButton,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip
} from '@mui/material';
import { customerApi } from '../../__fake-api__/customer-api';
import { useMounted } from '../../hooks/use-mounted';
import { MoreMenu } from '../../more-menu';
import { Scrollbar } from '../../scrollbar';
import { SeverityPill } from '../../severity-pill';
import { AddEvent } from '../../add-event';
import { ModifyEvent } from '../../modify-event';
import { Refresh as RefreshIcon } from '../../icons/refresh';


import { PencilAlt as PencilAltIcon } from '../../icons/pencil-alt';



export const ContactingTab = (props) => {
  //const isMounted = useMounted();
  //const [logs, setLogs] = useState([]);
  const { refresh, logs } = props;
  
  // const getLogs = async () => {
  //   try {
  //     //const data = await customerApi.getCustomerLogs();
  //     if (id !== "") {
  //       const data = await getContactingLog(id);
  //       console.log(data);
  //       if (isMounted()) {
  //         setLogs(data);
  //       }
  //     }
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  // useEffect(() => {
  //   if (id !== "") {
  //     getLogs();
  //   }
  // }, [id]);

  return (
    // <Card {...props}>
    <Card>
      <CardHeader
        action={
          <div>
            <AddEvent type="new" companyid={props.companyid} refresh={refresh} />
            <Tooltip title="Refresh">
              <IconButton
                onClick={refresh}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip> 
          </div>}
        title="Logs"
      />
      <Divider />
      <Scrollbar>
        <Table sx={{ minWidth: 700 }}>
          <TableHead>
            <TableRow>
              <TableCell>
                User
              </TableCell>
              <TableCell>
                Contact Date
              </TableCell>
              <TableCell>
                Person contacted
              </TableCell>
              <TableCell>
                Contact Type
              </TableCell>
              <TableCell>
                Interest
              </TableCell>
              <TableCell>
                Notes
              </TableCell>
              <TableCell>
                Next
              </TableCell>
              <TableCell>
                Edit
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {logs.map((log) => (
              <TableRow key={log.id}>
                <TableCell>
                  {log.user}
                </TableCell>
                <TableCell>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                  >
                  { format(log.contactDate, 'd.M.yyyy') }
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                  >
                    { log.contactDetails }
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                  >
                    { log.contactType }
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                  >
                    { log.interest }
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                  >
                    { log.notes }
                  </Typography>
                </TableCell>
                <TableCell>
                  <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex'
                      }}
                    >
                      <Box sx={{ ml: 1 }}>
                        <Typography
                          color="textSecondary"
                          variant="subtitle2"
                        >
                          {log.nextTask}
                        </Typography>
                        <Typography
                          color="textSecondary"
                          variant="body2"
                        >
                         { format(log.nextTaskDate, 'd.M.yyyy') }
                        </Typography>
                      </Box>
                    </Box>
                </TableCell>
                <TableCell>
                  <ModifyEvent eventdata={log} companyid={props.companyid} refresh={refresh} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
    </Card>
  );
};
