import axios from "axios";
import { API } from "./api-routes";
//const qs = require('qs');


// Portal control

const addPortalBrand = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.addPortalBrand, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const addPortalUser = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.addPortalUser, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const addPortalDocument = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.addPortalDocument, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const addPortalSubscription = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.addPortalSubscription, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const addPortalProfile = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.addPortalProfile, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const invitePortalUser = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.invitePortalUser, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const getMainProfiles = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(API.getMainProfiles)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const getMainProfile = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.getMainProfile, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const getBrand = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API.getBrand + "/" + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const getBrands = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API.getBrands + "/" + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const getAllBrands = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(API.getAllBrands)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const getBrandProducts = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API.getBrandProducts + "/" + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const getDocuments = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API.getDocuments + "/" + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const portalAddProduct = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.addProduct, payload)
      .then((res) => {
        resolve(res.status);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const portalUpdateProduct = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.updateProduct, payload)
      .then((res) => {
        resolve(res.status);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const portalProduct = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.portalProduct, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const portalProductV2 = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.portalProductV2, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};


// CONTACTING


const addCompany = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.addCompany, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const updateContacting = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.updateContacting, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const getContacting = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(API.getContacting)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const getSingleProfile = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API.getSingleProfile + "/" + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const getContactingId = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API.getContactingId + "/" + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};


// CONTACTING LOGS AND CONTACTS


const getContactingLog = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API.getContactingLog + "/" + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const updateContactingLog = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.updateContactingLog, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const postContactingLog = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.postContactingLog, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};


const getContacts = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API.getContacts + "/" + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const postContact = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.postContact, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const updateContact = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.updateContact, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const removeDefaults = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.removeDefaults, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};


// DEMO ACCOUNTS


const addDemo = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.addDemo, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const getDemos = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(API.getDemos)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const updateDemo = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.updateDemo, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const getSingleDemo = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API.getSingleDemo + "/" + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};



// DEMO PRODUCTS

const addDemoProduct = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.addDemoProduct, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const getDemoProducts = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API.getDemoProducts + "/" + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const getDemoList = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(API.getDemoList)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const getSingleDemoProduct = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API.getSingleDemoProduct + "/" + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const getDemoBrands = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(API.getDemoBrands)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const newDemoProduct = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.newDemoProduct, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const addDemoBrand = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.addDemoBrand, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const updateDemoProduct = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.updateDemoProduct, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

// PRODUCTS

const getProducts = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(API.products)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const getExternal = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(API.external)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

// ALGOLIA

const saveToIndex = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.saveToIndex, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const updateIndex = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.updateIndex, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};


// CONTRACTS


const addContract = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.addContract, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const getContracts = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(API.getContracts)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const updateContract = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.updateContract, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const signContract = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API.signContract, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const getSingleContract = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API.getSingleContract + "/" + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};


// CLIENTS & CLIENTS'S PRODUCTS

const getClients = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(API.getClients)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const getSingleClient = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API.getSingleClient + "/" + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const getClientContracts = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API.getClientContracts + "/" + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const getClientUsers = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API.getClientUsers + "/" + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const getClientProducts = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API.getClientProducts + "/" + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

// SUBSCRIPTIONS


// ETIM

const getEtimFeatures = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API.getEtimFeatures + "/?id=" + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const getEtimValues = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API.getEtimValues + "/?id=" + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};


// UPLOAD & DOWNLOAD

// const upload = (file, folder) => {
//   const formData = new FormData();
//   formData.append("filename", file);
//   const query = API.upload + "?filename=" + file.name + "&folder=" + folder;
//   return new Promise((resolve, reject) => {
//     axios
//       .post(query, formData)
//       .then((res) => {
//         resolve(res);
//         console.log("File uploaded successfully!");
//       })
//       .catch((err) => {
//         reject(err.response.data);
//         console.log("File upload failed!");
//       });
//   });
// };

// function readFile(file) {
//   return new Response(file).arrayBuffer();
// }


const upload = (data, filename, folder) => {
    // const formData = new FormData();
    // formData.append("filename", file);
    // const reader = new FileReader();

    // reader.onload = () => {
    //   console.log(reader.result);
    // };

    // const data = reader.readAsArrayBuffer(file);

    const query = API.upload + "?filename=" + filename + "&folder=" + folder;
    return new Promise((resolve, reject) => {
      axios
        .post(query, data)
        .then((res) => {
          resolve(res);
          console.log("File uploaded successfully!");
        })
        .catch((err) => {
          reject(err.response.data);
          console.log("File upload failed!");
        });
    });
  };


const downloadAs = (url, name) => {
  axios.get(url, {
    headers: {
      "Content-Type": "application/octet-stream"
    },
    responseType: "blob"
  })
    .then(response => {
      const a = document.createElement("a");
      const url = window.URL.createObjectURL(response.data);
      a.href = url;
      a.download = name;
      a.click();
    })
    .catch(err => {
      console.log("error", err);
    });
};

const uploadAsWebp = (file, folder, contentType) => {
  const formData = new FormData();
  formData.append("filename", file);
  // const config = {
  //   headers: {
  //     'Content-Type': contentType
  //   }
  // }
  const query = API.uploadAsWebp + "?filename=" + file.name + "&folder=" + folder;
  return new Promise((resolve, reject) => {
    axios
      .post(query, formData)
      .then((res) => {
        //console.log(res);
        resolve(res);
        console.log("File uploaded successfully!");
      })
      .catch((err) => {
        reject(err.response.status);
        console.log("File upload failed!");
      });
  });
};


export {
  // Portal control
  addPortalBrand,
  addPortalUser,
  addPortalDocument,
  addPortalSubscription,
  addPortalProfile,
  invitePortalUser,
  getMainProfiles,
  getMainProfile,
  getAllBrands,
  getBrand,
  getBrands,
  getBrandProducts,
  getDocuments,
  portalAddProduct,
  portalUpdateProduct,

  portalProduct,
  portalProductV2,

  // CONTACTING
  addCompany,
  getContacting,
  updateContacting,
  getSingleProfile,
  getContactingId,

  // CONTACTING LOGS AND CONTACTS
  getContactingLog,
  updateContactingLog,
  postContactingLog,
  getContacts,
  postContact,
  updateContact,
  removeDefaults,

  // DEMO ACCOUNTS
  addDemo,
  getDemos,
  updateDemo,
  getSingleDemo,

  // DEMO PRODUCTS
  addDemoProduct,
  getDemoProducts,
  getDemoList,
  getSingleDemoProduct,
  getDemoBrands,
  newDemoProduct,
  addDemoBrand,
  updateDemoProduct,

  // PRODUCTS
  getProducts,
  getExternal,
  
  // ALGOLIA
  saveToIndex,
  updateIndex,

  // CONTRACTS
  addContract,
  getContracts,
  updateContract,
  getSingleContract,
  signContract,

  // CLIENTS & PRODUCTS
  getClients,
  getSingleClient,
  getClientProducts,
  getClientUsers,
  getClientContracts,

  // SUBSCRIPTIONS

  // ETIM
  getEtimFeatures,
  getEtimValues,

  // UPLOAD & DOWNLOAD
  upload,
  downloadAs,
  uploadAsWebp,
};
