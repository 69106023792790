import { useCallback, useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  Chip,
  Container,
  Divider,
  Grid,
  Link,
  Tab,
  Tabs,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useMounted } from '../../hooks/use-mounted';
import { ChevronDown as ChevronDownIcon } from '../../icons/chevron-down';
import { PencilAlt as PencilAltIcon } from '../../icons/pencil-alt';
import { Plus as PlusIcon } from '../../icons/plus';
import { CribSharp, PropaneSharp } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
//import { gtm } from './lib/gtm';
//import { getInitials } from './utils/get-initials';
import { EventModal } from '../../event-modal';
import { ContactModal } from '../../contact-modal';
import { DemoStatusModal } from '../../demo-status-modal';
import { getContactingLog, getMainProfile, getContactingId, getBrands, getDocuments } from '../../api';
import { v4 as uuidv4 } from 'uuid';
import { ContractStatusModal } from '../../contract-status-modal';

import { ContactingTab } from './contacting-tab';
import { NotConnected } from './not-connected';
import { ProductTab } from './product-tab';
import { ContractTab } from './contract-tab';


const tabs = [
  { label: 'Contacting', value: 'contacting' },
  { label: 'Contracts', value: 'contracts' },
  { label: 'Brands & Products', value: 'brands' }
];

const CustomerPage = (props) => {
  const isMounted = useMounted();
  const [customer, setCustomer] = useState(null);
  const [newEvent, setNewEvent] = useState(false);
  const [newContact, setNewContact] = useState(false);
  const [demoStatus, setDemoStatus] = useState(false);
  const [contractStatus, setContractStatus] = useState(false);
  const [currentTab, setCurrentTab] = useState('contacting');
  const [contacts, setContacts] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [products, setProducts] = useState([]);
  const [users, setUsers] = useState([]);
  const [logs, setLogs] = useState([]);
  const [brands, setBrands] = useState([]);
  const [connected, setConnected] = useState(true);
  const [contactingId, setContactingId] = useState("");
  const [refreshLogs, setRefreshLogs] = useState(0);
  const [refreshContracts, setRefreshContracts] = useState(0);
  const [refreshUsers, setRefreshUsers] = useState(0);
  const [refreshProducts, setRefreshProducts] = useState(0);
  const [refresh, setRefresh] = useState(0);
  
  const navigate = useNavigate();

  const getContacting = async () => {
    try {
      //const data = await customerApi.getCustomers();
      if (customer !== null) {
        const response = await getContactingId(customer?.id);
        //console.log(response);
        if (response.length > 0) {
          setContactingId(response[0].id);
          setConnected(true);
          //await getLogs(response[0].id)
        } else if (response.length === 0) {
          setConnected(false);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getLogs = async () => {
    try {
      //const data = await customerApi.getCustomerLogs();
      if (contactingId !== "") {
        const data = await getContactingLog(contactingId);
        setLogs(data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getBrandList = async () => {
    try {
      //const data = await customerApi.getCustomerLogs();
      if (customer !== null) {
        const data = await getBrands(customer?.id)
        setBrands(data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getDocumentList = async () => {
    try {
      //const data = await customerApi.getCustomerLogs();
      if (customer !== null) {
        const data = await getDocuments(customer?.id)
        setContracts(data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  // Get single customer details
  const getCustomer = useCallback(async () => {
    try {
      //const data = await customerApi.getCustomers();
      const data = await getMainProfile({id: props.id});

      if (data.length > 0 && isMounted()) {
        setCustomer(data[0]);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  useEffect(() => {
      getCustomer();
    }, [refresh]);

  useEffect(() => {
    getLogs();
  }, [contactingId]);

  useEffect(() => {
    getContacting();
    getBrandList();
    getDocumentList();
  }, [customer]);

  
  const handleRefresh = () => {
    setRefresh(refresh + 1)
  }

  const handleRefreshContracts = () => {
    setRefreshContracts(refreshContracts + 1)
  }

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  if (!customer) {
    return null;
  }

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8
        }}
      >
        <Container>
          <div>
            <Box sx={{ mb: 4 }}>
                <Link
                  color="textPrimary"
                  component="a"
                  onClick={() => navigate("/clients/")}
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    cursor: 'pointer'
                  }}
                >
                  <ArrowBackIcon
                    fontSize="small"
                    sx={{ mr: 1 }}
                  />
                  <Typography variant="subtitle2">
                    Client list
                  </Typography>
                </Link>
              {/* </NextLink> */}
            </Box>
            <Grid
              container
              justifyContent="space-between"
              spacing={3}
            >
              <Grid
                item
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  overflow: 'hidden'
                }}
              >
                {/* <Avatar
                  src={customer.avatar}
                  sx={{
                    height: 64,
                    mr: 2,
                    width: 64
                  }}
                >
                  OK
                </Avatar> */}
                <div>
                  <Typography variant="h4" color="neutral.900">
                    {customer.companyName}
                  </Typography>
                  <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex'
                      }}
                  >
                    {/* <Box>
                      <Typography
                        color="textSecondary"
                        variant="subtitle2"
                      >
                        Name: {customer?.defaultContact?.title} {customer?.defaultContact?.name}
                      </Typography>
                      <Typography
                        color="textSecondary"
                        variant="subtitle2"
                      >
                        Email: {customer?.defaultContact?.email}
                      </Typography>
                      <Typography
                        color="textSecondary"
                        variant="subtitle2"
                      >
                        Phone: {customer?.defaultContact?.phone}
                      </Typography>
                    </Box> */}
                  </Box>
                </div>
              </Grid>
              {/* <Grid
                item
                sx={{ mt: 2, mb: -1, ml: -1 }}
              >
                <Button
                  component="a"
                  endIcon={(
                    <PlusIcon fontSize="small" />
                  )}
                  sx={{ m: 0.5, width: "170px" }}
                  variant="outlined"
                  onClick={handleOpenNewEvent}
                >
                  Invite user
                </Button>
                <Button
                  component="a"
                  endIcon={(
                    <PlusIcon fontSize="small" />
                  )}
                  sx={{ m: 0.5, width: "170px" }}
                  variant="outlined"
                  onClick={handleOpenNewContact}
                >
                  Send contract
                </Button>
                <Button
                  component="a"
                  endIcon={(
                    <PlusIcon fontSize="small" />
                  )}
                  sx={{ m: 0.5, width: "170px" }}
                  variant="outlined"
                  onClick={handleOpenNewContact}
                >
                  Add products
                </Button>
              </Grid> */}
            </Grid>
            <Tabs
              indicatorColor="primary"
              onChange={handleTabsChange}
              scrollButtons="auto"
              sx={{ mt: 3 }}
              textColor="primary"
              value={currentTab}
              variant="scrollable"
            >
              {tabs.map((tab) => (
                <Tab
                  key={tab.value}
                  label={tab.label}
                  value={tab.value}
                />
              ))}
            </Tabs>
          </div>
          <Divider />
          <Box sx={{ mt: 3 }}>
            {currentTab === 'contacting' && (connected ? <ContactingTab logs={logs} refresh={handleRefresh} /> : <NotConnected mainProfileId={customer?.id} refresh={handleRefresh} connected={connected} />)}
            {currentTab === 'contracts' && <ContractTab contracts={contracts} companyid={props.customerId} refresh={handleRefreshContracts} />}
            {currentTab === 'brands' && <ProductTab brands={brands} refresh={handleRefresh} />}
          </Box>
        </Container>
      </Box>
    </>
  );
};

// CustomerDetails.getLayout = (page) => (
//   <AuthGuard>
//     <DashboardLayout>
//       {page}
//     </DashboardLayout>
//   </AuthGuard>
// );

export default CustomerPage;