import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

export const Logo = styled((props) => {
  const { variant, ...other } = props;

  //const color = variant === 'light' ? '#FEB449' : '#5A5A5C';
  const color = variant === 'light' ? '#FFF' : '#313133';

  return (
  //   <svg
  //     // width="84"
  //     // height="84"
  //     viewBox="0 0 400 200"
  //     xmlns="http://www.w3.org/2000/svg"
  //     {...other}>
  //     <path fill={color} d="M34.1,87.5c-6.5,0-12,2.5-15.6,7.1V67.5H5.6v45.8c0,15.9,10.6,26.6,26.5,26.6s26.9-11,26.9-26.7
  //       C59,96.3,46.5,87.5,34.1,87.5z M32.1,127.8c-8.2,0-13.7-5.6-13.7-14.1S24,99.6,32.1,99.6s13.8,5.8,13.8,14.1S40.3,127.8,32.1,127.8
  //       L32.1,127.8z"/>
  //     <polygon  fill={color} points="66.2,138.6 79,138.6 79,91.8 66.2,99.2 "/>
  //     <path fill={color} d="M142.9,87.5c-7.3,0-13.3,2.9-16.8,8.3c-3.7-5.3-9.6-8.3-16.8-8.3c-13.1,0-21,8.5-21,22.8v28.4h12.9v-28.4
  //       c0-7,3.2-10.6,9.3-10.6s9.3,3.8,9.3,10.6v28.4h12.9v-28.4c0-6.9,3.3-10.6,9.2-10.6c6.1,0,9.3,3.7,9.3,10.6v28.4h12.9v-28.4
  //       C163.8,96,156,87.5,142.9,87.5z"/>
  //     <path fill={color} d="M193.3,87.5c-13.9,0-21.9,8.1-21.9,22.3v28.9h12.9v-28.9c0-6.8,3-10.1,9-10.1l0,0h9.9V87.5h-10H193.3z"/>
  //     <path fill={color} d="M230.3,87.5c-15.5,0-26.8,11-26.8,26.2s11.3,26.2,26.8,26.2s26.8-11,26.8-26.2S245.8,87.5,230.3,87.5z
  //       M230.3,127.8c-8.1,0-13.8-5.8-13.8-14.1s5.7-14.1,13.8-14.1s13.7,5.7,13.7,14.1S238.5,127.8,230.3,127.8z"/>
  //     <path fill={color} d="M287.5,87.5c-15.5,0-26.8,11-26.8,26.2s11.3,26.2,26.8,26.2s26.8-11,26.8-26.2S303,87.5,287.5,87.5z
  //       M287.5,127.8c-8.1,0-13.8-5.8-13.8-14.1s5.7-14.1,13.8-14.1s13.7,5.7,13.7,14.1S295.6,127.8,287.5,127.8z"/>
  //     <path fill={color} d="M373.4,87.5c-7.3,0-13.3,2.9-16.8,8.3c-3.7-5.3-9.6-8.3-16.8-8.3c-13.1,0-21,8.5-21,22.8v28.4h12.9v-28.4
  //       c0-7,3.2-10.6,9.3-10.6s9.3,3.8,9.3,10.6v28.4h12.9v-28.4c0-6.9,3.3-10.6,9.2-10.6s9.3,3.7,9.3,10.6v28.4h12.9v-28.4
  //       C394.4,96,386.6,87.5,373.4,87.5z"/>
  //     <polygon fill={color} opacity="0.7" points="61.5,75.4 70.8,80.9 70.8,90.1 61.5,84.6 "/>
  //     <polygon fill={color} points="80.2,75.4 70.8,80.9 70.8,90.1 80.2,84.6 "/>
  //     <polygon fill={color} points="61.5,75.4 70.8,70 70.8,62.1 61.5,67.5 "/>
  //     <polygon fill={color} opacity="0.3" points="80.2,75.4 70.8,70 70.8,62.1 80.2,67.5 "/>
  //     <g>
  //       <path fill={color} d="M345.9,158.2c-0.4-1.1-1.1-1.9-2-2.6c-0.9-0.6-2.1-1-3.4-1c-0.9,0-1.6,0.1-2.4,0.4c-0.7,0.3-1.3,0.7-1.8,1.2
  //         c-0.5,0.5-0.9,1.1-1.2,1.9c-0.3,0.7-0.4,1.5-0.4,2.3c0,0.9,0.1,1.6,0.4,2.4c0.3,0.7,0.7,1.3,1.2,1.8c0.5,0.5,1.1,0.9,1.8,1.2
  //         c0.7,0.3,1.5,0.4,2.4,0.4c1.3,0,2.4-0.3,3.4-1c0.9-0.6,1.6-1.5,2-2.6h3.3c-0.2,1-0.6,1.9-1.1,2.7c-0.5,0.8-1.1,1.5-1.9,2.1
  //         s-1.6,1-2.6,1.3c-1,0.3-2,0.5-3.1,0.5c-1.3,0-2.6-0.2-3.7-0.7c-1.1-0.4-2.1-1.1-2.9-1.8c-0.8-0.8-1.4-1.7-1.9-2.8s-0.7-2.3-0.7-3.5
  //         c0-1.3,0.2-2.4,0.7-3.5c0.4-1.1,1.1-2,1.9-2.8s1.8-1.4,2.9-1.8c1.1-0.4,2.3-0.7,3.7-0.7c1.1,0,2.1,0.2,3.1,0.5
  //         c1,0.3,1.8,0.8,2.6,1.3s1.4,1.3,1.9,2.1c0.5,0.8,0.9,1.7,1.1,2.7H345.9z"/>
  //       <path fill={color} d="M363.1,159.2V159c0-1.5-0.4-2.7-1.1-3.3c-0.7-0.7-1.7-1-2.9-1c-1.2,0-2.2,0.3-2.9,1c-0.7,0.7-1.1,1.8-1.1,3.3v9.9h-3.2V159
  //         c0-1.2,0.2-2.3,0.5-3.2c0.4-0.9,0.8-1.7,1.5-2.3c0.6-0.6,1.4-1.1,2.3-1.4c0.9-0.3,1.9-0.4,3-0.4c1.1,0,2,0.1,2.9,0.4
  //         c0.9,0.3,1.6,0.7,2.3,1.4c0.6,0.6,1.1,1.4,1.5,2.3c0.4,0.9,0.5,2,0.5,3.3v0.2H363.1z"/>
  //       <path fill={color} d="M379.7,168.9v-9.7c0-1.5-0.4-2.7-1.1-3.4c-0.8-0.7-1.7-1.1-2.9-1.1c-1.2,0-2.1,0.4-2.9,1.1c-0.7,0.7-1.1,1.9-1.1,3.4v9.7
  //         h-3.2v-9.7c0-1.3,0.2-2.4,0.5-3.3c0.4-0.9,0.8-1.7,1.5-2.3c0.6-0.6,1.4-1.1,2.2-1.4c0.8-0.3,1.8-0.5,2.8-0.5c1.3,0,2.4,0.3,3.5,0.8
  //         c1,0.6,1.8,1.4,2.4,2.4h0.1c0.6-1.1,1.4-1.9,2.4-2.4c1-0.6,2.2-0.8,3.5-0.8c1,0,1.9,0.2,2.7,0.5c0.8,0.3,1.6,0.8,2.2,1.4
  //         c0.6,0.6,1.1,1.4,1.5,2.3c0.4,0.9,0.5,2.1,0.5,3.3v9.7H391v-9.7c0-1.6-0.4-2.7-1.1-3.4c-0.7-0.7-1.7-1.1-2.9-1.1
  //         c-1.2,0-2.1,0.4-2.9,1.1s-1.1,1.9-1.1,3.4v9.7H379.7z"/>
  //     </g>
  //   </svg>
    <svg xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1000 500">
    <g>
      <path fill={color} d="M356.2,328.6c-10.9,0-21.7,0-32.7,0c0-0.7,0-1.5,0-2.2c0-24.1,0-48.2,0-72.3c0-4.8-1.2-9.4-3.6-13.6
        c-2.7-4.7-6.6-7.7-11.8-9.2c-5.2-1.5-10.3-1.5-15.5-0.1c-8.8,2.3-13.3,8.6-14.9,17.1c-0.5,2.8-0.8,5.7-0.8,8.5
        c-0.1,23.1,0,46.3,0,69.4c0,0.7,0,1.5,0,2.3c-10.9,0-21.7,0-32.6,0c0-0.6-0.1-1.2-0.1-1.8c0-23-0.1-46,0-69
        c0.1-11.2,1.6-22.2,6.9-32.3c6.8-13.1,17.7-21,32-24.2c5.5-1.2,11.2-1.8,16.9-1.5c16.1,0.9,29.6,6.8,39.3,20.3
        c0.1,0.1,0.2,0.2,0.5,0.4c1.2-1.5,2.4-3.1,3.7-4.6c6.8-7.8,15.3-12.6,25.3-14.6c5.2-1,10.6-1.6,15.9-1.5c12,0.3,23.1,3.5,32.5,11.5
        c7.5,6.3,12.2,14.4,14.8,23.7c1.3,4.6,2.3,9.5,2.6,14.3c0.5,8.8,0.4,17.7,0.4,26.6c0.1,16.9,0,33.8,0,50.7c0,0.7,0,1.3,0,2.1
        c-10.8,0-21.4,0-32.3,0c0-0.7,0-1.4,0-2.1c0-23,0-45.9,0-68.9c0-5.6-0.7-11.2-3.3-16.3c-3.3-6.4-8.7-9.7-15.7-10.8
        c-4.4-0.6-8.8-0.4-13,0.9c-8.2,2.7-12.2,8.9-13.8,16.9c-0.5,2.7-0.7,5.4-0.8,8.2c-0.1,23.2,0,46.4,0,69.6
        C356.2,326.9,356.2,327.7,356.2,328.6z"/>
      <path fill={color} d="M808.4,328.6c-10.9,0-21.5,0-32.4,0c0-0.7,0-1.4,0-2c0-24.3-0.2-48.6,0.1-72.9c0.2-12.8,3.3-24.9,11.2-35.4
        c6.6-8.7,15.4-14,25.9-16.7c6-1.5,12.2-2.2,18.5-1.8c15.7,0.9,29.1,6.5,38.8,19.5c0.3,0.4,0.6,0.7,1,1.1c1.8-2.1,3.4-4.2,5.3-6.2
        c7.1-7.4,15.9-11.8,26-13.4c4.5-0.7,9.2-1.2,13.8-1.1c12.9,0.4,24.8,4,34.5,13.2c6.9,6.6,11.3,14.7,13.4,23.9
        c1.2,5,2.2,10.2,2.2,15.3c0.3,25.2,0.3,50.4,0.4,75.6c0,0.2-0.1,0.5-0.1,0.8c-10.8,0-21.6,0-32.6,0c0-0.7,0-1.4,0-2.1
        c0-24.1,0-48.1,0-72.2c0-5-1.2-9.9-3.8-14.3c-3.3-5.7-8.5-8.5-14.9-9.4c-4.5-0.7-8.9-0.5-13.2,0.9c-7.8,2.6-12,8.4-13.7,16.2
        c-0.7,3.3-1,6.6-1,10c-0.1,22.9,0,45.8,0,68.7c0,0.7,0,1.4,0,2.2c-10.8,0-21.5,0-32.4,0c0-0.7,0-1.4,0-2.1c0-23.3,0-46.5,0-69.8
        c0-4.7-0.7-9.3-2.5-13.7c-3.1-7.4-8.8-11.4-16.7-12.5c-4.4-0.6-8.8-0.4-13,1c-7.8,2.5-12,8.3-13.7,16c-0.7,3.3-1,6.7-1.1,10.1
        c-0.1,22.9,0,45.8,0,68.7C808.4,327,808.4,327.8,808.4,328.6z"/>
      <path fill={color} d="M35.6,149.5c11,0,21.7,0,32.7,0c0,11.3,0,22.6,0,33.8c0,11.3,0,22.5,0,34.1c0.5-0.4,0.8-0.6,1-0.9
        c7.9-9.2,17.9-14.5,29.9-16c20.3-2.6,38.1,3,52.9,17.2c8.6,8.2,13.8,18.5,16.4,30c1.6,6.8,2.2,13.7,1.8,20.7
        c-1,17.7-6.9,33.4-19.9,45.8c-8.6,8.2-18.9,13.3-30.5,15.9c-7.2,1.6-14.6,2.2-22,1.8c-14.4-0.9-27.7-5-39.1-14.2
        c-10.2-8.3-16.7-18.9-20.3-31.4c-1.6-5.8-2.6-11.6-2.7-17.6c-0.1-3.6-0.3-7.2-0.3-10.8c0-35.3,0-70.6,0-106
        C35.6,151.2,35.6,150.5,35.6,149.5z M68,265.9c0.3,3,0.5,6,1,8.9c1.9,9.5,6.7,17.2,15.3,22.1c8.6,4.9,17.8,5.9,27.3,3.7
        c10.8-2.5,18.5-8.8,22.9-18.9c3.3-7.6,4-15.5,2.4-23.6c-2.2-10.9-7.9-19.3-18-24.2c-6.6-3.2-13.6-4.1-21-3.2
        c-7.4,0.9-13.9,3.5-19.3,8.6C71.1,246.5,68.2,255.6,68,265.9z"/>
      <path fill={color} d="M673.6,265.9c-0.5,14.7-4.3,28.1-13,40c-9.2,12.4-21.5,20.1-36.4,23.9c-7.5,1.9-15.2,2.7-23,2.1
        c-19.6-1.3-36.5-8.7-49.1-24.2c-6.5-8-10.8-17.2-12.5-27.4c-0.9-5.3-1.6-10.8-1.5-16.1c0.7-29.6,18.5-54.5,49.2-62.2
        c7.1-1.8,14.3-2.6,21.5-2.2c17.8,0.9,33.7,6.7,46.4,19.7c8.7,8.8,14.2,19.4,16.5,31.6C672.6,256,673,261,673.6,265.9z M605.7,301.8
        c2.7-0.3,5.5-0.4,8.1-1c10.5-2.2,18.3-8,22.9-17.8c4-8.5,4.5-17.5,2.5-26.5c-2-9.4-7-16.9-15.6-21.7c-7-3.9-14.6-5-22.6-4.1
        c-7.7,0.9-14.4,3.6-19.9,9c-9.7,9.7-12.2,21.7-9.6,34.7C575.2,291.4,588.2,301.5,605.7,301.8z"/>
      <path fill={color} d="M528.8,216.3c0,9.7,0,19.1,0,28.8c-0.7,0-1.4,0-2.1,0c-10.4,0-20.8,0-31.2,0c-2.5,0-2.5,0-2.5,2.4c0,26.9,0,53.9,0,80.8
        c0,0.8,0,1.6,0,2.5c-9.7,0-19.3,0-29,0c0-28.5,0-57,0-85.7c-4.9,0-9.5,0-14.3,0c0-9.7,0-19.1,0-28.8c4.7,0,9.3,0,14.2,0
        c0-2,0-3.8,0-5.6c-0.1-9.7,2.4-18.7,7.5-26.8c8.2-13,20-20.8,35-23.6c3.9-0.7,8-0.7,12-0.8c6.1-0.1,12.2,0,18.5,0
        c0,9.7,0,19.2,0,29.1c-0.6,0-1.3,0-1.9,0c-6.6,0-13.2-0.1-19.8,0c-10.4,0.1-18.7,6.3-21.2,16c-0.9,3.4-0.7,7.1-0.9,10.7
        c0,0.3,0.7,0.9,1.1,1c0.9,0.2,1.8,0,2.7,0c9.9,0,19.7,0,29.6,0C527.2,216.3,527.9,216.3,528.8,216.3z"/>
      <path fill={color} d="M769.3,232.8c-9,0-17.9-0.1-26.7,0c-5.9,0.1-11.3,1.8-15.4,6.4c-3,3.4-4.5,7.5-5.1,11.9c-0.4,2.6-0.4,5.2-0.4,7.8
        c0,23.3,0,46.5,0,69.8c0,0.7,0,1.5,0,2.3c-10.9,0-21.7,0-32.7,0c0-0.7,0-1.3,0-2c0-24-0.1-48,0.1-72c0.1-9.8,1.7-19.3,6.1-28.2
        c6.4-13,17-20.7,30.8-24.4c6.1-1.6,12.3-2.2,18.6-2.2c8.2,0,16.4,0,24.7,0C769.3,212.5,769.3,222.6,769.3,232.8z"/>
      <path fill={color} d="M188.7,211c10.7,0,21.5,0,32.3,0c0,39.1,0,78.3,0,117.6c-10.7,0-21.3,0-32.3,0C188.7,289.5,188.7,250.5,188.7,211z"/>
      <path fill={color} d="M188.4,193.2c0-10.7,0-21.2,0.1-31.6c0-0.6,0.7-1.4,1.2-1.7c9.6-6,19.3-11.9,28.9-17.8c0.7-0.4,1.4-0.9,2.4-1.5
        c0,0.7,0,1.2,0,1.6c0,9.8,0,19.7,0,29.5c0,0.9,0,1.5-1,2.1c-10.3,6.3-20.6,12.6-30.9,18.9C189.1,192.8,188.9,192.9,188.4,193.2z"/>
    </g>
    <g>
      <path fill={color} d="M830.6,364.9c-1.1-3.1-3.1-5.3-5.6-7.3c-2.5-1.7-5.9-2.8-9.5-2.8c-2.5,0-4.5,0.3-6.7,1.1c-2,0.8-3.6,2-5,3.4
        c-1.4,1.4-2.5,3.1-3.4,5.3c-0.8,2-1.1,4.2-1.1,6.4c0,2.5,0.3,4.5,1.1,6.7c0.8,2,2,3.6,3.4,5c1.4,1.4,3.1,2.5,5,3.4
        c2,0.8,4.2,1.1,6.7,1.1c3.6,0,6.7-0.8,9.5-2.8c2.5-1.7,4.5-4.2,5.6-7.3h9.2c-0.6,2.8-1.7,5.3-3.1,7.5c-1.4,2.2-3.1,4.2-5.3,5.9
        c-2.2,1.7-4.5,2.8-7.3,3.6s-5.6,1.4-8.7,1.4c-3.6,0-7.3-0.6-10.3-2c-3.1-1.1-5.9-3.1-8.1-5c-2.2-2.2-3.9-4.8-5.3-7.8
        c-1.4-3.1-2-6.4-2-9.8c0-3.6,0.6-6.7,2-9.8c1.1-3.1,3.1-5.6,5.3-7.8c2.2-2.2,5-3.9,8.1-5c3.1-1.1,6.4-2,10.3-2
        c3.1,0,5.9,0.6,8.7,1.4s5,2.2,7.3,3.6s3.9,3.6,5.3,5.9c1.4,2.2,2.5,4.8,3.1,7.5L830.6,364.9L830.6,364.9z"/>
      <path fill={color} d="M878.7,367.7v-0.6c0-4.2-1.1-7.5-3.1-9.2c-2-2-4.8-2.8-8.1-2.8c-3.4,0-6.1,0.8-8.1,2.8c-2,2-3.1,5-3.1,9.2
        v27.7h-8.9v-27.7c0-3.4,0.6-6.4,1.4-8.9c1.1-2.5,2.2-4.8,4.2-6.4c1.7-1.7,3.9-3.1,6.4-3.9s5.3-1.1,8.4-1.1c3.1,0,5.6,0.3,8.1,1.1
        c2.5,0.8,4.5,2,6.4,3.9c1.7,1.7,3.1,3.9,4.2,6.4c1.1,2.5,1.4,5.6,1.4,9.2v0.6h-9.2V367.7z"/>
      <path fill={color} d="M925.1,394.8v-27.1c0-4.2-1.1-7.5-3.1-9.5c-2.2-2-4.8-3.1-8.1-3.1c-3.4,0-5.9,1.1-8.1,3.1
        c-2,2-3.1,5.3-3.1,9.5v27.1h-8.9v-27.1c0-3.6,0.6-6.7,1.4-9.2c1.1-2.5,2.2-4.8,4.2-6.4c1.7-1.7,3.9-3.1,6.1-3.9
        c2.2-0.8,5-1.4,7.8-1.4c3.6,0,6.7,0.8,9.8,2.2c2.8,1.7,5,3.9,6.7,6.7h0.3c1.7-3.1,3.9-5.3,6.7-6.7c2.8-1.7,6.1-2.2,9.8-2.2
        c2.8,0,5.3,0.6,7.5,1.4c2.2,0.8,4.5,2.2,6.1,3.9c1.7,1.7,3.1,3.9,4.2,6.4c1.1,2.5,1.4,5.9,1.4,9.2v27.1h-9.2v-27.1
        c0-4.5-1.1-7.5-3.1-9.5c-2-2-4.8-3.1-8.1-3.1c-3.4,0-5.9,1.1-8.1,3.1c-2.2,2-3.1,5.3-3.1,9.5v27.1H925.1z"/>
    </g>
    </svg>
   );

})``;


Logo.defaultProps = {
  variant: 'primary'
};

Logo.propTypes = {
  variant: PropTypes.oneOf(['light', 'primary'])
};
