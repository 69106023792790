import { Autocomplete, Box, Button, Chip, Divider, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, Tooltip, Typography, createFilterOptions } from "@mui/material";
import { FieldArray, FormikProvider } from "formik";
import { Plus } from "../../icons/plus";
import { OutlinedBox } from "./../outlined-box";
import InfoIcon from '@mui/icons-material/InfoOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect, useState } from "react";
import bonVariables from "./Schema/bon-variables"
import { FaCopy, FaTrash } from "react-icons/fa";

const typeOptions = [
  { key: "custom", label: "Custom" },
  { key: "bon", label: "Built-in" }
]

const classificationList = [
  { name: "uniclass2015Code", label: "Uniclass 2015 Code" },
  { name: "uniclass2015Desc", label: "Uniclass 2015 Description" },
  { name: "omniClassNumber", label: "OmniClass Number" },
  { name: "omniClassTitle", label: "OmniClass Title" },
  { name: "uniclass20Code", label: "Uniclass 2015 Code" },
  { name: "uniclass20Desc", label: "Uniclass 2015 Description" },
  { name: "nbsReferenceCode", label: "NBS Reference Code" },
  { name: "nbsReferenceDesc", label: "NBS Reference Description" },
  { name: "ifcClassification", label: "IFC Classification" }
]

export const ClassificationField = (props) => {
  const { 
    formik,
    sticky,
    index, 
    classification,
    type, 
    label, 
    name, 
    value, 
    removeClassification 
  } = props;


  return (
    <Grid container spacing={1} sx={{mb: 1}}>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <InputLabel>Type</InputLabel>
          <Select
            size='small'
            value={classification?.type}
            name={type}
            label="Type"
            onChange={formik.handleChange}
            //onChange={handleTypeChange}
          >
            {typeOptions.map((type, index) => {
              return (
                <MenuItem key={"type." + index} value={type.key}>{type.label}</MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        {classification.type === "bon" ?
          <FormControl fullWidth>
            <InputLabel>Variable</InputLabel>
            <Select
              size='small'
              value={classification?.key}
              name={name}
              label="Variable"
              onChange={formik.handleChange}
              //onChange={handleNameChange}
            >
              {classificationList.map((variable, index) => {
                return (
                  <MenuItem key={index} value={variable.name}>{variable.label}</MenuItem>
                )
              })}
            </Select>
          </FormControl>
           :
          <TextField
            fullWidth
            label="Classification"
            name={label}
            size="small"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={classification.label}
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#000000",
              },
            }}
            disabled={classification.key === "custom" ? false : true}
          />
        }
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          label="Value"
          name={value}
          size="small"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={classification.value}
        />
      </Grid>
      <Grid item xs={1}>
        <Button sx={{height: 1, maxWidth: "100%"}} disabled={sticky && classification.type === "bon" ? true : false} onClick={removeClassification}>
          <FaTrash />
        </Button>
      </Grid>
    </Grid>
  )
}