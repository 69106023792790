const etimClassification = [
  {
    classId: "EC000001",
    classGroup: "EG000017",
    artClassDesc: "Busbar terminal",
    artClassVersion: 5,
    label: "EC000001: Busbar terminal"
  },
  {
    classId: "EC000003",
    classGroup: "EG000020",
    artClassDesc: "Residual current circuit breaker (RCCB)",
    artClassVersion: 9,
    label: "EC000003: Residual current circuit breaker (RCCB)"
  },
  {
    classId: "EC000005",
    classGroup: "EG000047",
    artClassDesc: "Cable end sleeve",
    artClassVersion: 8,
    label: "EC000005: Cable end sleeve"
  },
  {
    classId: "EC000006",
    classGroup: "EG000005",
    artClassDesc: "Cover plate for installation units",
    artClassVersion: 7,
    label: "EC000006: Cover plate for installation units"
  },
  {
    classId: "EC000007",
    classGroup: "EG000013",
    artClassDesc: "Cover frame for domestic switching devices",
    artClassVersion: 9,
    label: "EC000007: Cover frame for domestic switching devices"
  },
  {
    classId: "EC000008",
    classGroup: "EG000015",
    artClassDesc: "Tap off unit for busbar trunk",
    artClassVersion: 5,
    label: "EC000008: Tap off unit for busbar trunk"
  },
  {
    classId: "EC000009",
    classGroup: "EG000049",
    artClassDesc: "Hammer set anchor",
    artClassVersion: 7,
    label: "EC000009: Hammer set anchor"
  },
  {
    classId: "EC000010",
    classGroup: "EG000017",
    artClassDesc: "Combination of contactors",
    artClassVersion: 9,
    label: "EC000010: Combination of contactors"
  },
  {
    classId: "EC000011",
    classGroup: "EG000013",
    artClassDesc: "Control element/cover plate for domestic switching devices",
    artClassVersion: 9,
    label: "EC000011: Control element/cover plate for domestic switching devices"
  },
  {
    classId: "EC000012",
    classGroup: "EG000007",
    artClassDesc: "Slotted cable trunking system",
    artClassVersion: 8,
    label: "EC000012: Slotted cable trunking system"
  },
  {
    classId: "EC000013",
    classGroup: "EG000013",
    artClassDesc: "Base plate for flush mounted installation",
    artClassVersion: 7,
    label: "EC000013: Base plate for flush mounted installation"
  },
  {
    classId: "EC000014",
    classGroup: "EG000028",
    artClassDesc: "Incandescent lamp tube-shaped",
    artClassVersion: 9,
    label: "EC000014: Incandescent lamp tube-shaped"
  },
  {
    classId: "EC000016",
    classGroup: "EG000014",
    artClassDesc: "Explosion proof CEE socket outlet (IEC 60309)",
    artClassVersion: 8,
    label: "EC000016: Explosion proof CEE socket outlet (IEC 60309)"
  },
  {
    classId: "EC000017",
    classGroup: "EG000014",
    artClassDesc: "Explosion proof CEE-plug/-coupler (IEC 60309)",
    artClassVersion: 8,
    label: "EC000017: Explosion proof CEE-plug/-coupler (IEC 60309)"
  },
  {
    classId: "EC000018",
    classGroup: "EG000013",
    artClassDesc: "Insert/cover for communication technology",
    artClassVersion: 9,
    label: "EC000018: Insert/cover for communication technology"
  },
  {
    classId: "EC000019",
    classGroup: "EG000001",
    artClassDesc: "Coaxial cable",
    artClassVersion: 8,
    label: "EC000019: Coaxial cable"
  },
  {
    classId: "EC000020",
    classGroup: "EG000013",
    artClassDesc: "Combination switch/wall socket outlet",
    artClassVersion: 9,
    label: "EC000020: Combination switch/wall socket outlet"
  },
  {
    classId: "EC000022",
    classGroup: "EG000004",
    artClassDesc: "Bracket for cable support system",
    artClassVersion: 7,
    label: "EC000022: Bracket for cable support system"
  },
  {
    classId: "EC000023",
    classGroup: "EG000006",
    artClassDesc: "Cover for wall duct/Installation column",
    artClassVersion: 8,
    label: "EC000023: Cover for wall duct/Installation column"
  },
  {
    classId: "EC000024",
    classGroup: "EG000005",
    artClassDesc: "Device installation insert for subfloor installation",
    artClassVersion: 8,
    label: "EC000024: Device installation insert for subfloor installation"
  },
  {
    classId: "EC000025",
    classGroup: "EG000013",
    artClassDesc: "Dimmer",
    artClassVersion: 9,
    label: "EC000025: Dimmer"
  },
  {
    classId: "EC000026",
    classGroup: "EG000020",
    artClassDesc: "Low Voltage HRC solid link",
    artClassVersion: 5,
    label: "EC000026: Low Voltage HRC solid link"
  },
  {
    classId: "EC000028",
    classGroup: "EG000049",
    artClassDesc: "Threaded rod",
    artClassVersion: 9,
    label: "EC000028: Threaded rod"
  },
  {
    classId: "EC000029",
    classGroup: "EG000013",
    artClassDesc: "Push button",
    artClassVersion: 9,
    label: "EC000029: Push button"
  },
  {
    classId: "EC000030",
    classGroup: "EG000026",
    artClassDesc: "End switch",
    artClassVersion: 8,
    label: "EC000030: End switch"
  },
  {
    classId: "EC000032",
    classGroup: "EG000009",
    artClassDesc: "Plug for cable screw gland",
    artClassVersion: 9,
    label: "EC000032: Plug for cable screw gland"
  },
  {
    classId: "EC000033",
    classGroup: "EG000049",
    artClassDesc: "Installation strap",
    artClassVersion: 6,
    label: "EC000033: Installation strap"
  },
  {
    classId: "EC000034",
    classGroup: "EG000001",
    artClassDesc: "Fibre optic cable",
    artClassVersion: 9,
    label: "EC000034: Fibre optic cable"
  },
  {
    classId: "EC000036",
    classGroup: "EG000020",
    artClassDesc: "Miniature fuse holder",
    artClassVersion: 7,
    label: "EC000036: Miniature fuse holder"
  },
  {
    classId: "EC000037",
    classGroup: "EG000028",
    artClassDesc: "Halogen metal halide lamp without reflector",
    artClassVersion: 9,
    label: "EC000037: Halogen metal halide lamp without reflector"
  },
  {
    classId: "EC000038",
    classGroup: "EG000028",
    artClassDesc: "High voltage halogen lamp",
    artClassVersion: 8,
    label: "EC000038: High voltage halogen lamp"
  },
  {
    classId: "EC000040",
    classGroup: "EG000049",
    artClassDesc: "Cavity fixing",
    artClassVersion: 7,
    label: "EC000040: Cavity fixing"
  },
  {
    classId: "EC000041",
    classGroup: "EG000017",
    artClassDesc: "Auxiliary contact block",
    artClassVersion: 7,
    label: "EC000041: Auxiliary contact block"
  },
  {
    classId: "EC000042",
    classGroup: "EG000020",
    artClassDesc: "Miniature circuit breaker (MCB)",
    artClassVersion: 9,
    label: "EC000042: Miniature circuit breaker (MCB)"
  },
  {
    classId: "EC000044",
    classGroup: "EG000013",
    artClassDesc: "Window blind switch",
    artClassVersion: 9,
    label: "EC000044: Window blind switch"
  },
  {
    classId: "EC000045",
    classGroup: "EG000028",
    artClassDesc: "Candle-shaped incandescent lamp",
    artClassVersion: 9,
    label: "EC000045: Candle-shaped incandescent lamp"
  },
  {
    classId: "EC000046",
    classGroup: "EG000049",
    artClassDesc: "Cable tie",
    artClassVersion: 8,
    label: "EC000046: Cable tie"
  },
  {
    classId: "EC000047",
    classGroup: "EG000004",
    artClassDesc: "Cable tray",
    artClassVersion: 8,
    label: "EC000047: Cable tray"
  },
  {
    classId: "EC000048",
    classGroup: "EG000049",
    artClassDesc: "Anchor bolt",
    artClassVersion: 8,
    label: "EC000048: Anchor bolt"
  },
  {
    classId: "EC000050",
    classGroup: "EG000049",
    artClassDesc: "Chain",
    artClassVersion: 8,
    label: "EC000050: Chain"
  },
  {
    classId: "EC000051",
    classGroup: "EG000028",
    artClassDesc: "Sphere-shaped incandescent lamp",
    artClassVersion: 9,
    label: "EC000051: Sphere-shaped incandescent lamp"
  },
  {
    classId: "EC000052",
    classGroup: "EG000006",
    artClassDesc: "Coupler for plinth skirting duct",
    artClassVersion: 8,
    label: "EC000052: Coupler for plinth skirting duct"
  },
  {
    classId: "EC000054",
    classGroup: "EG000028",
    artClassDesc: "Incandescent lamp with ring head",
    artClassVersion: 8,
    label: "EC000054: Incandescent lamp with ring head"
  },
  {
    classId: "EC000055",
    classGroup: "EG000020",
    artClassDesc: "Knife blade fuse",
    artClassVersion: 8,
    label: "EC000055: Knife blade fuse"
  },
  {
    classId: "EC000056",
    classGroup: "EG000028",
    artClassDesc: "Mercury vapour lamp",
    artClassVersion: 8,
    label: "EC000056: Mercury vapour lamp"
  },
  {
    classId: "EC000058",
    classGroup: "EG000023",
    artClassDesc: "Empty cabinet",
    artClassVersion: 8,
    label: "EC000058: Empty cabinet"
  },
  {
    classId: "EC000059",
    classGroup: "EG000006",
    artClassDesc: "Mini/maxi trunking",
    artClassVersion: 9,
    label: "EC000059: Mini/maxi trunking"
  },
  {
    classId: "EC000060",
    classGroup: "EG000013",
    artClassDesc: "Hood for light signalling unit",
    artClassVersion: 6,
    label: "EC000060: Hood for light signalling unit"
  },
  {
    classId: "EC000061",
    classGroup: "EG000030",
    artClassDesc: "Light pole",
    artClassVersion: 9,
    label: "EC000061: Light pole"
  },
  {
    classId: "EC000062",
    classGroup: "EG000027",
    artClassDesc: "Luminaire for streets and places",
    artClassVersion: 9,
    label: "EC000062: Luminaire for streets and places"
  },
  {
    classId: "EC000064",
    classGroup: "EG000013",
    artClassDesc: "Information light signal for domestic switching devices",
    artClassVersion: 9,
    label: "EC000064: Information light signal for domestic switching devices"
  },
  {
    classId: "EC000065",
    classGroup: "EG000001",
    artClassDesc: "Loudspeaker cable",
    artClassVersion: 7,
    label: "EC000065: Loudspeaker cable"
  },
  {
    classId: "EC000066",
    classGroup: "EG000017",
    artClassDesc: "Power contactor, AC switching",
    artClassVersion: 8,
    label: "EC000066: Power contactor, AC switching"
  },
  {
    classId: "EC000067",
    classGroup: "EG000020",
    artClassDesc: "Low Voltage HRC fuse base",
    artClassVersion: 6,
    label: "EC000067: Low Voltage HRC fuse base"
  },
  {
    classId: "EC000068",
    classGroup: "EG000015",
    artClassDesc: "Meter board",
    artClassVersion: 7,
    label: "EC000068: Meter board"
  },
  {
    classId: "EC000069",
    classGroup: "EG000044",
    artClassDesc: "Multimeter",
    artClassVersion: 9,
    label: "EC000069: Multimeter"
  },
  {
    classId: "EC000070",
    classGroup: "EG000041",
    artClassDesc: "Microphone",
    artClassVersion: 5,
    label: "EC000070: Microphone"
  },
  {
    classId: "EC000071",
    classGroup: "EG000015",
    artClassDesc: "Mounting system for busbar trunk",
    artClassVersion: 6,
    label: "EC000071: Mounting system for busbar trunk"
  },
  {
    classId: "EC000073",
    classGroup: "EG000006",
    artClassDesc: "Junction box for wall duct",
    artClassVersion: 6,
    label: "EC000073: Junction box for wall duct"
  },
  {
    classId: "EC000074",
    classGroup: "EG000017",
    artClassDesc: "Motor protection circuit-breaker",
    artClassVersion: 9,
    label: "EC000074: Motor protection circuit-breaker"
  },
  {
    classId: "EC000075",
    classGroup: "EG000027",
    artClassDesc: "Plug in (night) light",
    artClassVersion: 8,
    label: "EC000075: Plug in (night) light"
  },
  {
    classId: "EC000078",
    classGroup: "EG000028",
    artClassDesc: "Standard-shaped incandescent lamp",
    artClassVersion: 9,
    label: "EC000078: Standard-shaped incandescent lamp"
  },
  {
    classId: "EC000079",
    classGroup: "EG000006",
    artClassDesc: "Appliance box for skirting duct",
    artClassVersion: 8,
    label: "EC000079: Appliance box for skirting duct"
  },
  {
    classId: "EC000080",
    classGroup: "EG000013",
    artClassDesc: "Surface mounted box for flush-mounted switching equipment",
    artClassVersion: 9,
    label: "EC000080: Surface mounted box for flush-mounted switching equipment"
  },
  {
    classId: "EC000082",
    classGroup: "EG000004",
    artClassDesc: "Ceiling profile for cable support system",
    artClassVersion: 6,
    label: "EC000082: Ceiling profile for cable support system"
  },
  {
    classId: "EC000083",
    classGroup: "EG000006",
    artClassDesc: "Transition cover section for installation duct",
    artClassVersion: 6,
    label: "EC000083: Transition cover section for installation duct"
  },
  {
    classId: "EC000084",
    classGroup: "EG000047",
    artClassDesc: "Sleeve for compacted conductor",
    artClassVersion: 6,
    label: "EC000084: Sleeve for compacted conductor"
  },
  {
    classId: "EC000085",
    classGroup: "EG000020",
    artClassDesc: "D-system screw adapter",
    artClassVersion: 5,
    label: "EC000085: D-system screw adapter"
  },
  {
    classId: "EC000086",
    classGroup: "EG000014",
    artClassDesc: "Perilex socket outlet",
    artClassVersion: 6,
    label: "EC000086: Perilex socket outlet"
  },
  {
    classId: "EC000087",
    classGroup: "EG000028",
    artClassDesc: "Compact fluorescent lamp without integrated ballast",
    artClassVersion: 9,
    label: "EC000087: Compact fluorescent lamp without integrated ballast"
  },
  {
    classId: "EC000088",
    classGroup: "EG000006",
    artClassDesc: "Skirting duct complete",
    artClassVersion: 8,
    label: "EC000088: Skirting duct complete"
  },
  {
    classId: "EC000089",
    classGroup: "EG000049",
    artClassDesc: "All-purpose plug",
    artClassVersion: 6,
    label: "EC000089: All-purpose plug"
  },
  {
    classId: "EC000090",
    classGroup: "EG000013",
    artClassDesc: "Potential equalization socket outlet",
    artClassVersion: 7,
    label: "EC000090: Potential equalization socket outlet"
  },
  {
    classId: "EC000091",
    classGroup: "EG000015",
    artClassDesc: "Busbar trunk unit",
    artClassVersion: 7,
    label: "EC000091: Busbar trunk unit"
  },
  {
    classId: "EC000092",
    classGroup: "EG000049",
    artClassDesc: "Screw hook",
    artClassVersion: 7,
    label: "EC000092: Screw hook"
  },
  {
    classId: "EC000093",
    classGroup: "EG000020",
    artClassDesc: "D-system screw cap",
    artClassVersion: 5,
    label: "EC000093: D-system screw cap"
  },
  {
    classId: "EC000094",
    classGroup: "EG000020",
    artClassDesc: "D-system fuse base",
    artClassVersion: 6,
    label: "EC000094: D-system fuse base"
  },
  {
    classId: "EC000096",
    classGroup: "EG000049",
    artClassDesc: "Slide nut",
    artClassVersion: 7,
    label: "EC000096: Slide nut"
  },
  {
    classId: "EC000097",
    classGroup: "EG000049",
    artClassDesc: "Hammer-in plug",
    artClassVersion: 7,
    label: "EC000097: Hammer-in plug"
  },
  {
    classId: "EC000098",
    classGroup: "EG000049",
    artClassDesc: "Washer",
    artClassVersion: 8,
    label: "EC000098: Washer"
  },
  {
    classId: "EC000099",
    classGroup: "EG000020",
    artClassDesc: "D-system fuse link",
    artClassVersion: 7,
    label: "EC000099: D-system fuse link"
  },
  {
    classId: "EC000100",
    classGroup: "EG000015",
    artClassDesc: "Fuse enclosure",
    artClassVersion: 7,
    label: "EC000100: Fuse enclosure"
  },
  {
    classId: "EC000101",
    classGroup: "EG000030",
    artClassDesc: "Light-track",
    artClassVersion: 9,
    label: "EC000101: Light-track"
  },
  {
    classId: "EC000102",
    classGroup: "EG000028",
    artClassDesc: "Incandescent lamp with reflector",
    artClassVersion: 9,
    label: "EC000102: Incandescent lamp with reflector"
  },
  {
    classId: "EC000103",
    classGroup: "EG000049",
    artClassDesc: "Nail",
    artClassVersion: 8,
    label: "EC000103: Nail"
  },
  {
    classId: "EC000105",
    classGroup: "EG000038",
    artClassDesc: "Cordless telephone",
    artClassVersion: 6,
    label: "EC000105: Cordless telephone"
  },
  {
    classId: "EC000106",
    classGroup: "EG000017",
    artClassDesc: "Thermal overload relay",
    artClassVersion: 7,
    label: "EC000106: Thermal overload relay"
  },
  {
    classId: "EC000107",
    classGroup: "EG000013",
    artClassDesc: "Timer for domestic switching devices",
    artClassVersion: 9,
    label: "EC000107: Timer for domestic switching devices"
  },
  {
    classId: "EC000108",
    classGroup: "EG000028",
    artClassDesc: "Fluorescent lamp",
    artClassVersion: 8,
    label: "EC000108: Fluorescent lamp"
  },
  {
    classId: "EC000109",
    classGroup: "EG000027",
    artClassDesc: "Batten luminaire",
    artClassVersion: 9,
    label: "EC000109: Batten luminaire"
  },
  {
    classId: "EC000112",
    classGroup: "EG000049",
    artClassDesc: "Toggle fixing",
    artClassVersion: 7,
    label: "EC000112: Toggle fixing"
  },
  {
    classId: "EC000113",
    classGroup: "EG000006",
    artClassDesc: "Junction box for installation duct",
    artClassVersion: 6,
    label: "EC000113: Junction box for installation duct"
  },
  {
    classId: "EC000114",
    classGroup: "EG000005",
    artClassDesc: "Junction box for subfloor installation",
    artClassVersion: 6,
    label: "EC000114: Junction box for subfloor installation"
  },
  {
    classId: "EC000115",
    classGroup: "EG000048",
    artClassDesc: "Plaster compensation ring",
    artClassVersion: 5,
    label: "EC000115: Plaster compensation ring"
  },
  {
    classId: "EC000117",
    classGroup: "EG000041",
    artClassDesc: "HiFi Amplifier",
    artClassVersion: 6,
    label: "EC000117: HiFi Amplifier"
  },
  {
    classId: "EC000118",
    classGroup: "EG000005",
    artClassDesc: "Subfloor installation duct screed-covered",
    artClassVersion: 6,
    label: "EC000118: Subfloor installation duct screed-covered"
  },
  {
    classId: "EC000119",
    classGroup: "EG000005",
    artClassDesc: "Device cup/device carrier for subfloor system",
    artClassVersion: 7,
    label: "EC000119: Device cup/device carrier for subfloor system"
  },
  {
    classId: "EC000120",
    classGroup: "EG000005",
    artClassDesc: "On-floor duct base",
    artClassVersion: 5,
    label: "EC000120: On-floor duct base"
  },
  {
    classId: "EC000121",
    classGroup: "EG000005",
    artClassDesc: "Surface tank empty",
    artClassVersion: 7,
    label: "EC000121: Surface tank empty"
  },
  {
    classId: "EC000122",
    classGroup: "EG000015",
    artClassDesc: "Feed unit for busbar trunk",
    artClassVersion: 7,
    label: "EC000122: Feed unit for busbar trunk"
  },
  {
    classId: "EC000125",
    classGroup: "EG000013",
    artClassDesc: "Socket outlet",
    artClassVersion: 9,
    label: "EC000125: Socket outlet"
  },
  {
    classId: "EC000127",
    classGroup: "EG000010",
    artClassDesc: "Saddle clamp (pipe/cable)",
    artClassVersion: 8,
    label: "EC000127: Saddle clamp (pipe/cable)"
  },
  {
    classId: "EC000128",
    classGroup: "EG000047",
    artClassDesc: "Adhesive tape",
    artClassVersion: 9,
    label: "EC000128: Adhesive tape"
  },
  {
    classId: "EC000130",
    classGroup: "EG000035",
    artClassDesc: "Signal horn",
    artClassVersion: 7,
    label: "EC000130: Signal horn"
  },
  {
    classId: "EC000131",
    classGroup: "EG000035",
    artClassDesc: "Bell",
    artClassVersion: 9,
    label: "EC000131: Bell"
  },
  {
    classId: "EC000132",
    classGroup: "EG000036",
    artClassDesc: "Doorbell",
    artClassVersion: 8,
    label: "EC000132: Doorbell"
  },
  {
    classId: "EC000133",
    classGroup: "EG000013",
    artClassDesc: "Movement sensor/presence detector complete",
    artClassVersion: 9,
    label: "EC000133: Movement sensor/presence detector complete"
  },
  {
    classId: "EC000134",
    classGroup: "EG000036",
    artClassDesc: "Low voltage switch for doorbell",
    artClassVersion: 4,
    label: "EC000134: Low voltage switch for doorbell"
  },
  {
    classId: "EC000135",
    classGroup: "EG000036",
    artClassDesc: "Hospital call button (hanging)",
    artClassVersion: 6,
    label: "EC000135: Hospital call button (hanging)"
  },
  {
    classId: "EC000136",
    classGroup: "EG000036",
    artClassDesc: "Lamp holder for doorbell",
    artClassVersion: 4,
    label: "EC000136: Lamp holder for doorbell"
  },
  {
    classId: "EC000137",
    classGroup: "EG000035",
    artClassDesc: "Motor siren",
    artClassVersion: 6,
    label: "EC000137: Motor siren"
  },
  {
    classId: "EC000138",
    classGroup: "EG000013",
    artClassDesc: "Sensor element for movement sensor",
    artClassVersion: 9,
    label: "EC000138: Sensor element for movement sensor"
  },
  {
    classId: "EC000140",
    classGroup: "EG000014",
    artClassDesc: "CEE plug (IEC 60309)",
    artClassVersion: 8,
    label: "EC000140: CEE plug (IEC 60309)"
  },
  {
    classId: "EC000141",
    classGroup: "EG000013",
    artClassDesc: "Labelling material for domestic switching devices",
    artClassVersion: 8,
    label: "EC000141: Labelling material for domestic switching devices"
  },
  {
    classId: "EC000142",
    classGroup: "EG000052",
    artClassDesc: "Cable shears",
    artClassVersion: 6,
    label: "EC000142: Cable shears"
  },
  {
    classId: "EC000143",
    classGroup: "EG000050",
    artClassDesc: "Folding rule",
    artClassVersion: 7,
    label: "EC000143: Folding rule"
  },
  {
    classId: "EC000144",
    classGroup: "EG000050",
    artClassDesc: "Crosshead screwdriver",
    artClassVersion: 8,
    label: "EC000144: Crosshead screwdriver"
  },
  {
    classId: "EC000145",
    classGroup: "EG000050",
    artClassDesc: "Level",
    artClassVersion: 6,
    label: "EC000145: Level"
  },
  {
    classId: "EC000146",
    classGroup: "EG000050",
    artClassDesc: "Chisel",
    artClassVersion: 7,
    label: "EC000146: Chisel"
  },
  {
    classId: "EC000148",
    classGroup: "EG000050",
    artClassDesc: "Pipe cutter",
    artClassVersion: 7,
    label: "EC000148: Pipe cutter"
  },
  {
    classId: "EC000149",
    classGroup: "EG000050",
    artClassDesc: "Tool set",
    artClassVersion: 6,
    label: "EC000149: Tool set"
  },
  {
    classId: "EC000150",
    classGroup: "EG000050",
    artClassDesc: "Brush",
    artClassVersion: 7,
    label: "EC000150: Brush"
  },
  {
    classId: "EC000151",
    classGroup: "EG000050",
    artClassDesc: "Chalk line device",
    artClassVersion: 5,
    label: "EC000151: Chalk line device"
  },
  {
    classId: "EC000152",
    classGroup: "EG000050",
    artClassDesc: "Putty knife/spatula",
    artClassVersion: 7,
    label: "EC000152: Putty knife/spatula"
  },
  {
    classId: "EC000153",
    classGroup: "EG000050",
    artClassDesc: "Gypsum container",
    artClassVersion: 5,
    label: "EC000153: Gypsum container"
  },
  {
    classId: "EC000154",
    classGroup: "EG000050",
    artClassDesc: "Fuse screw key",
    artClassVersion: 5,
    label: "EC000154: Fuse screw key"
  },
  {
    classId: "EC000155",
    classGroup: "EG000052",
    artClassDesc: "Knife",
    artClassVersion: 8,
    label: "EC000155: Knife"
  },
  {
    classId: "EC000156",
    classGroup: "EG000050",
    artClassDesc: "Hole punch",
    artClassVersion: 8,
    label: "EC000156: Hole punch"
  },
  {
    classId: "EC000157",
    classGroup: "EG000050",
    artClassDesc: "Slide gauge",
    artClassVersion: 7,
    label: "EC000157: Slide gauge"
  },
  {
    classId: "EC000158",
    classGroup: "EG000050",
    artClassDesc: "Screwdriver for slot head screws",
    artClassVersion: 8,
    label: "EC000158: Screwdriver for slot head screws"
  },
  {
    classId: "EC000159",
    classGroup: "EG000050",
    artClassDesc: "Stabbing awl",
    artClassVersion: 6,
    label: "EC000159: Stabbing awl"
  },
  {
    classId: "EC000160",
    classGroup: "EG000050",
    artClassDesc: "Shears",
    artClassVersion: 8,
    label: "EC000160: Shears"
  },
  {
    classId: "EC000161",
    classGroup: "EG000050",
    artClassDesc: "Hammer",
    artClassVersion: 9,
    label: "EC000161: Hammer"
  },
  {
    classId: "EC000162",
    classGroup: "EG020002",
    artClassDesc: "Step drill",
    artClassVersion: 7,
    label: "EC000162: Step drill"
  },
  {
    classId: "EC000163",
    classGroup: "EG000052",
    artClassDesc: "Cable stripping tool",
    artClassVersion: 6,
    label: "EC000163: Cable stripping tool"
  },
  {
    classId: "EC000164",
    classGroup: "EG000050",
    artClassDesc: "Water pump pliers",
    artClassVersion: 8,
    label: "EC000164: Water pump pliers"
  },
  {
    classId: "EC000165",
    classGroup: "EG000050",
    artClassDesc: "Side cutter",
    artClassVersion: 7,
    label: "EC000165: Side cutter"
  },
  {
    classId: "EC000166",
    classGroup: "EG000050",
    artClassDesc: "Punch pliers",
    artClassVersion: 5,
    label: "EC000166: Punch pliers"
  },
  {
    classId: "EC000167",
    classGroup: "EG000050",
    artClassDesc: "Cable pulling system",
    artClassVersion: 9,
    label: "EC000167: Cable pulling system"
  },
  {
    classId: "EC000168",
    classGroup: "EG000052",
    artClassDesc: "Crimp tool cable lugs, cable end sleeves, screen connection",
    artClassVersion: 7,
    label: "EC000168: Crimp tool cable lugs, cable end sleeves, screen connection"
  },
  {
    classId: "EC000170",
    classGroup: "EG000050",
    artClassDesc: "Hand saw",
    artClassVersion: 8,
    label: "EC000170: Hand saw"
  },
  {
    classId: "EC000172",
    classGroup: "EG000051",
    artClassDesc: "Sabre saw (electric)",
    artClassVersion: 8,
    label: "EC000172: Sabre saw (electric)"
  },
  {
    classId: "EC000173",
    classGroup: "EG020002",
    artClassDesc: "Cutting/grinding disc",
    artClassVersion: 9,
    label: "EC000173: Cutting/grinding disc"
  },
  {
    classId: "EC000174",
    classGroup: "EG000050",
    artClassDesc: "Socket spanner",
    artClassVersion: 7,
    label: "EC000174: Socket spanner"
  },
  {
    classId: "EC000175",
    classGroup: "EG000015",
    artClassDesc: "Key for enclosures/cabinets",
    artClassVersion: 8,
    label: "EC000175: Key for enclosures/cabinets"
  },
  {
    classId: "EC000177",
    classGroup: "EG000051",
    artClassDesc: "Nibbler (electric)",
    artClassVersion: 8,
    label: "EC000177: Nibbler (electric)"
  },
  {
    classId: "EC000178",
    classGroup: "EG000051",
    artClassDesc: "Wall chaser (electric)",
    artClassVersion: 6,
    label: "EC000178: Wall chaser (electric)"
  },
  {
    classId: "EC000181",
    classGroup: "EG000050",
    artClassDesc: "Tool box/case",
    artClassVersion: 8,
    label: "EC000181: Tool box/case"
  },
  {
    classId: "EC000182",
    classGroup: "EG000050",
    artClassDesc: "Tweezers",
    artClassVersion: 7,
    label: "EC000182: Tweezers"
  },
  {
    classId: "EC000183",
    classGroup: "EG000061",
    artClassDesc: "Electric soldering iron",
    artClassVersion: 7,
    label: "EC000183: Electric soldering iron"
  },
  {
    classId: "EC000184",
    classGroup: "EG000061",
    artClassDesc: "Soldering tip",
    artClassVersion: 6,
    label: "EC000184: Soldering tip"
  },
  {
    classId: "EC000185",
    classGroup: "EG000061",
    artClassDesc: "Soft solder",
    artClassVersion: 7,
    label: "EC000185: Soft solder"
  },
  {
    classId: "EC000186",
    classGroup: "EG000013",
    artClassDesc: "Razor socket outlet",
    artClassVersion: 9,
    label: "EC000186: Razor socket outlet"
  },
  {
    classId: "EC000187",
    classGroup: "EG000050",
    artClassDesc: "Marker",
    artClassVersion: 7,
    label: "EC000187: Marker"
  },
  {
    classId: "EC000188",
    classGroup: "EG000062",
    artClassDesc: "Latching relay",
    artClassVersion: 7,
    label: "EC000188: Latching relay"
  },
  {
    classId: "EC000189",
    classGroup: "EG000013",
    artClassDesc: "Twilight switch",
    artClassVersion: 9,
    label: "EC000189: Twilight switch"
  },
  {
    classId: "EC000190",
    classGroup: "EG000006",
    artClassDesc: "Face plate for wall duct",
    artClassVersion: 6,
    label: "EC000190: Face plate for wall duct"
  },
  {
    classId: "EC000191",
    classGroup: "EG020002",
    artClassDesc: "Cable reel",
    artClassVersion: 8,
    label: "EC000191: Cable reel"
  },
  {
    classId: "EC000192",
    classGroup: "EG000024",
    artClassDesc: "PLC memory card",
    artClassVersion: 6,
    label: "EC000192: PLC memory card"
  },
  {
    classId: "EC000193",
    classGroup: "EG000013",
    artClassDesc: "Decoration element for domestic switching devices",
    artClassVersion: 8,
    label: "EC000193: Decoration element for domestic switching devices"
  },
  {
    classId: "EC000195",
    classGroup: "EG000035",
    artClassDesc: "Optical/acoustic signal device",
    artClassVersion: 8,
    label: "EC000195: Optical/acoustic signal device"
  },
  {
    classId: "EC000196",
    classGroup: "EG000017",
    artClassDesc: "Contactor relay",
    artClassVersion: 8,
    label: "EC000196: Contactor relay"
  },
  {
    classId: "EC000198",
    classGroup: "EG000017",
    artClassDesc: "Amplifier module for contactor",
    artClassVersion: 5,
    label: "EC000198: Amplifier module for contactor"
  },
  {
    classId: "EC000199",
    classGroup: "EG000013",
    artClassDesc: "Illumination insert for domestic switching devices",
    artClassVersion: 8,
    label: "EC000199: Illumination insert for domestic switching devices"
  },
  {
    classId: "EC000200",
    classGroup: "EG000017",
    artClassDesc: "Enclosure for control circuit devices",
    artClassVersion: 8,
    label: "EC000200: Enclosure for control circuit devices"
  },
  {
    classId: "EC000201",
    classGroup: "EG000017",
    artClassDesc: "Built-in panel for command devices",
    artClassVersion: 7,
    label: "EC000201: Built-in panel for command devices"
  },
  {
    classId: "EC000202",
    classGroup: "EG000017",
    artClassDesc: "Protective cover for command devices",
    artClassVersion: 7,
    label: "EC000202: Protective cover for command devices"
  },
  {
    classId: "EC000203",
    classGroup: "EG000017",
    artClassDesc: "Lamp transformer for control circuit devices",
    artClassVersion: 4,
    label: "EC000203: Lamp transformer for control circuit devices"
  },
  {
    classId: "EC000204",
    classGroup: "EG000017",
    artClassDesc: "Lamp holder block for control circuit devices",
    artClassVersion: 6,
    label: "EC000204: Lamp holder block for control circuit devices"
  },
  {
    classId: "EC000205",
    classGroup: "EG000013",
    artClassDesc: "Intelligent control element",
    artClassVersion: 7,
    label: "EC000205: Intelligent control element"
  },
  {
    classId: "EC000206",
    classGroup: "EG000060",
    artClassDesc: "Technical spray",
    artClassVersion: 8,
    label: "EC000206: Technical spray"
  },
  {
    classId: "EC000209",
    classGroup: "EG000056",
    artClassDesc: "Fire protection compound/binding/foam/sealant",
    artClassVersion: 8,
    label: "EC000209: Fire protection compound/binding/foam/sealant"
  },
  {
    classId: "EC000210",
    classGroup: "EG000056",
    artClassDesc: "Fire partitioning",
    artClassVersion: 8,
    label: "EC000210: Fire partitioning"
  },
  {
    classId: "EC000211",
    classGroup: "EG000023",
    artClassDesc: "Gland plate for enclosure/cabinet",
    artClassVersion: 8,
    label: "EC000211: Gland plate for enclosure/cabinet"
  },
  {
    classId: "EC000213",
    classGroup: "EG000015",
    artClassDesc: "Mounting plate for distribution board",
    artClassVersion: 5,
    label: "EC000213: Mounting plate for distribution board"
  },
  {
    classId: "EC000214",
    classGroup: "EG000023",
    artClassDesc: "Small distribution board",
    artClassVersion: 9,
    label: "EC000214: Small distribution board"
  },
  {
    classId: "EC000215",
    classGroup: "EG000017",
    artClassDesc: "Phase busbar",
    artClassVersion: 7,
    label: "EC000215: Phase busbar"
  },
  {
    classId: "EC000216",
    classGroup: "EG000017",
    artClassDesc: "Switch disconnector (low voltage)",
    artClassVersion: 8,
    label: "EC000216: Switch disconnector (low voltage)"
  },
  {
    classId: "EC000217",
    classGroup: "EG000047",
    artClassDesc: "Shrink tubing",
    artClassVersion: 9,
    label: "EC000217: Shrink tubing"
  },
  {
    classId: "EC000218",
    classGroup: "EG000047",
    artClassDesc: "Shrink end cap",
    artClassVersion: 7,
    label: "EC000218: Shrink end cap"
  },
  {
    classId: "EC000219",
    classGroup: "EG000047",
    artClassDesc: "Repair seal",
    artClassVersion: 8,
    label: "EC000219: Repair seal"
  },
  {
    classId: "EC000220",
    classGroup: "EG000047",
    artClassDesc: "Wall transit for cables and tubes",
    artClassVersion: 8,
    label: "EC000220: Wall transit for cables and tubes"
  },
  {
    classId: "EC000221",
    classGroup: "EG000017",
    artClassDesc: "Front element for push button",
    artClassVersion: 8,
    label: "EC000221: Front element for push button"
  },
  {
    classId: "EC000222",
    classGroup: "EG000017",
    artClassDesc: "Front element for selector switch",
    artClassVersion: 7,
    label: "EC000222: Front element for selector switch"
  },
  {
    classId: "EC000223",
    classGroup: "EG000017",
    artClassDesc: "Front element for indicator light",
    artClassVersion: 7,
    label: "EC000223: Front element for indicator light"
  },
  {
    classId: "EC000224",
    classGroup: "EG000017",
    artClassDesc: "7 segment LED driver",
    artClassVersion: 5,
    label: "EC000224: 7 segment LED driver"
  },
  {
    classId: "EC000225",
    classGroup: "EG000017",
    artClassDesc: "Control circuit devices combination in enclosure",
    artClassVersion: 8,
    label: "EC000225: Control circuit devices combination in enclosure"
  },
  {
    classId: "EC000228",
    classGroup: "EG000017",
    artClassDesc: "Power circuit-breaker for trafo/generator/installation protection",
    artClassVersion: 8,
    label: "EC000228: Power circuit-breaker for trafo/generator/installation protection"
  },
  {
    classId: "EC000229",
    classGroup: "EG000017",
    artClassDesc: "Handle for power circuit breaker",
    artClassVersion: 5,
    label: "EC000229: Handle for power circuit breaker"
  },
  {
    classId: "EC000230",
    classGroup: "EG000017",
    artClassDesc: "Door coupling handle for switchgear",
    artClassVersion: 5,
    label: "EC000230: Door coupling handle for switchgear"
  },
  {
    classId: "EC000231",
    classGroup: "EG000017",
    artClassDesc: "Foot-/palm switch complete",
    artClassVersion: 7,
    label: "EC000231: Foot-/palm switch complete"
  },
  {
    classId: "EC000232",
    classGroup: "EG000017",
    artClassDesc: "Optical module for signal tower",
    artClassVersion: 7,
    label: "EC000232: Optical module for signal tower"
  },
  {
    classId: "EC000233",
    classGroup: "EG000017",
    artClassDesc: "Empty enclosure for pendant control station",
    artClassVersion: 6,
    label: "EC000233: Empty enclosure for pendant control station"
  },
  {
    classId: "EC000234",
    classGroup: "EG000017",
    artClassDesc: "Pendant control station",
    artClassVersion: 7,
    label: "EC000234: Pendant control station"
  },
  {
    classId: "EC000235",
    classGroup: "EG000013",
    artClassDesc: "Power boost (for dimmer)",
    artClassVersion: 9,
    label: "EC000235: Power boost (for dimmer)"
  },
  {
    classId: "EC000236",
    classGroup: "EG000024",
    artClassDesc: "PLC CPU-module",
    artClassVersion: 9,
    label: "EC000236: PLC CPU-module"
  },
  {
    classId: "EC000237",
    classGroup: "EG000024",
    artClassDesc: "PLC connection cable",
    artClassVersion: 6,
    label: "EC000237: PLC connection cable"
  },
  {
    classId: "EC000238",
    classGroup: "EG000024",
    artClassDesc: "PLC simulation module",
    artClassVersion: 5,
    label: "EC000238: PLC simulation module"
  },
  {
    classId: "EC000239",
    classGroup: "EG000017",
    artClassDesc: "Current limiter",
    artClassVersion: 5,
    label: "EC000239: Current limiter"
  },
  {
    classId: "EC000240",
    classGroup: "EG000013",
    artClassDesc: "Cable entry",
    artClassVersion: 7,
    label: "EC000240: Cable entry"
  },
  {
    classId: "EC000241",
    classGroup: "EG000020",
    artClassDesc: "D-system protective cover",
    artClassVersion: 6,
    label: "EC000241: D-system protective cover"
  },
  {
    classId: "EC000242",
    classGroup: "EG000013",
    artClassDesc: "Plug with protective contact",
    artClassVersion: 9,
    label: "EC000242: Plug with protective contact"
  },
  {
    classId: "EC000243",
    classGroup: "EG000017",
    artClassDesc: "Pressure switch",
    artClassVersion: 6,
    label: "EC000243: Pressure switch"
  },
  {
    classId: "EC000244",
    classGroup: "EG000010",
    artClassDesc: "Span wire clamp",
    artClassVersion: 8,
    label: "EC000244: Span wire clamp"
  },
  {
    classId: "EC000245",
    classGroup: "EG000050",
    artClassDesc: "Cable reel dispenser",
    artClassVersion: 8,
    label: "EC000245: Cable reel dispenser"
  },
  {
    classId: "EC000246",
    classGroup: "EG000050",
    artClassDesc: "Accessories/spare parts for cable dispenser",
    artClassVersion: 8,
    label: "EC000246: Accessories/spare parts for cable dispenser"
  },
  {
    classId: "EC000247",
    classGroup: "EG000001",
    artClassDesc: "Spiralized cable",
    artClassVersion: 8,
    label: "EC000247: Spiralized cable"
  },
  {
    classId: "EC000248",
    classGroup: "EG000023",
    artClassDesc: "Unequipped meter cabinet",
    artClassVersion: 8,
    label: "EC000248: Unequipped meter cabinet"
  },
  {
    classId: "EC000249",
    classGroup: "EG000017",
    artClassDesc: "Fuse switch disconnector with housing",
    artClassVersion: 8,
    label: "EC000249: Fuse switch disconnector with housing"
  },
  {
    classId: "EC000250",
    classGroup: "EG000002",
    artClassDesc: "Cable protective covering hood",
    artClassVersion: 7,
    label: "EC000250: Cable protective covering hood"
  },
  {
    classId: "EC000253",
    classGroup: "EG000002",
    artClassDesc: "Cable protection tube for underground application",
    artClassVersion: 9,
    label: "EC000253: Cable protection tube for underground application"
  },
  {
    classId: "EC000256",
    classGroup: "EG000062",
    artClassDesc: "Speed controller",
    artClassVersion: 7,
    label: "EC000256: Speed controller"
  },
  {
    classId: "EC000257",
    classGroup: "EG000013",
    artClassDesc: "Transmitter/Remote control for domestic switching devices",
    artClassVersion: 8,
    label: "EC000257: Transmitter/Remote control for domestic switching devices"
  },
  {
    classId: "EC000258",
    classGroup: "EG000028",
    artClassDesc: "Low voltage halogen reflector lamp",
    artClassVersion: 9,
    label: "EC000258: Low voltage halogen reflector lamp"
  },
  {
    classId: "EC000259",
    classGroup: "EG000028",
    artClassDesc: "Low voltage halogen lamp without reflector",
    artClassVersion: 8,
    label: "EC000259: Low voltage halogen lamp without reflector"
  },
  {
    classId: "EC000260",
    classGroup: "EG000029",
    artClassDesc: "Starter for lighting",
    artClassVersion: 7,
    label: "EC000260: Starter for lighting"
  },
  {
    classId: "EC000261",
    classGroup: "EG000011",
    artClassDesc: "Enclosure/cabinet (empty)",
    artClassVersion: 9,
    label: "EC000261: Enclosure/cabinet (empty)"
  },
  {
    classId: "EC000262",
    classGroup: "EG000015",
    artClassDesc: "Meter cabinet equipped",
    artClassVersion: 7,
    label: "EC000262: Meter cabinet equipped"
  },
  {
    classId: "EC000263",
    classGroup: "EG000015",
    artClassDesc: "Meter panel",
    artClassVersion: 7,
    label: "EC000263: Meter panel"
  },
  {
    classId: "EC000264",
    classGroup: "EG000023",
    artClassDesc: "Panel for distribution board",
    artClassVersion: 7,
    label: "EC000264: Panel for distribution board"
  },
  {
    classId: "EC000266",
    classGroup: "EG000028",
    artClassDesc: "High voltage halogen reflector lamp",
    artClassVersion: 8,
    label: "EC000266: High voltage halogen reflector lamp"
  },
  {
    classId: "EC000268",
    classGroup: "EG000023",
    artClassDesc: "Cable entry cabinet",
    artClassVersion: 4,
    label: "EC000268: Cable entry cabinet"
  },
  {
    classId: "EC000269",
    classGroup: "EG000023",
    artClassDesc: "Cable connection box for entry cabinet",
    artClassVersion: 5,
    label: "EC000269: Cable connection box for entry cabinet"
  },
  {
    classId: "EC000271",
    classGroup: "EG000020",
    artClassDesc: "Miniature circuit breaker (MCB) plug model",
    artClassVersion: 5,
    label: "EC000271: Miniature circuit breaker (MCB) plug model"
  },
  {
    classId: "EC000272",
    classGroup: "EG000017",
    artClassDesc: "Indicator light complete",
    artClassVersion: 6,
    label: "EC000272: Indicator light complete"
  },
  {
    classId: "EC000273",
    classGroup: "EG000023",
    artClassDesc: "Cable tree",
    artClassVersion: 6,
    label: "EC000273: Cable tree"
  },
  {
    classId: "EC000274",
    classGroup: "EG000023",
    artClassDesc: "Connection cable tree for distribution boards",
    artClassVersion: 4,
    label: "EC000274: Connection cable tree for distribution boards"
  },
  {
    classId: "EC000275",
    classGroup: "EG000015",
    artClassDesc: "Main line branch terminal",
    artClassVersion: 7,
    label: "EC000275: Main line branch terminal"
  },
  {
    classId: "EC000276",
    classGroup: "EG000017",
    artClassDesc: "Distribution terminal block",
    artClassVersion: 5,
    label: "EC000276: Distribution terminal block"
  },
  {
    classId: "EC000277",
    classGroup: "EG000020",
    artClassDesc: "D0-system switch disconnector",
    artClassVersion: 5,
    label: "EC000277: D0-system switch disconnector"
  },
  {
    classId: "EC000278",
    classGroup: "EG000020",
    artClassDesc: "D0-system adapter sleeve",
    artClassVersion: 5,
    label: "EC000278: D0-system adapter sleeve"
  },
  {
    classId: "EC000279",
    classGroup: "EG000020",
    artClassDesc: "D-system ring adapter",
    artClassVersion: 5,
    label: "EC000279: D-system ring adapter"
  },
  {
    classId: "EC000282",
    classGroup: "EG000027",
    artClassDesc: "Tubelight system",
    artClassVersion: 9,
    label: "EC000282: Tubelight system"
  },
  {
    classId: "EC000293",
    classGroup: "EG000030",
    artClassDesc: "Support profile light-line system",
    artClassVersion: 7,
    label: "EC000293: Support profile light-line system"
  },
  {
    classId: "EC000297",
    classGroup: "EG000062",
    artClassDesc: "Staircase lighting timer",
    artClassVersion: 8,
    label: "EC000297: Staircase lighting timer"
  },
  {
    classId: "EC000300",
    classGroup: "EG000027",
    artClassDesc: "Floor luminaire",
    artClassVersion: 9,
    label: "EC000300: Floor luminaire"
  },
  {
    classId: "EC000301",
    classGroup: "EG000027",
    artClassDesc: "Luminaire bollard",
    artClassVersion: 9,
    label: "EC000301: Luminaire bollard"
  },
  {
    classId: "EC000302",
    classGroup: "EG000027",
    artClassDesc: "Table luminaire",
    artClassVersion: 9,
    label: "EC000302: Table luminaire"
  },
  {
    classId: "EC000305",
    classGroup: "EG000044",
    artClassDesc: "Measure-/test device for communication technology",
    artClassVersion: 7,
    label: "EC000305: Measure-/test device for communication technology"
  },
  {
    classId: "EC000306",
    classGroup: "EG000047",
    artClassDesc: "Low current connector",
    artClassVersion: 8,
    label: "EC000306: Low current connector"
  },
  {
    classId: "EC000309",
    classGroup: "EG000037",
    artClassDesc: "Module for active network component/network unit",
    artClassVersion: 7,
    label: "EC000309: Module for active network component/network unit"
  },
  {
    classId: "EC000310",
    classGroup: "EG000037",
    artClassDesc: "Interface converter",
    artClassVersion: 5,
    label: "EC000310: Interface converter"
  },
  {
    classId: "EC000311",
    classGroup: "EG000037",
    artClassDesc: "Telephone/modem connector",
    artClassVersion: 6,
    label: "EC000311: Telephone/modem connector"
  },
  {
    classId: "EC000312",
    classGroup: "EG000037",
    artClassDesc: "Data switch",
    artClassVersion: 5,
    label: "EC000312: Data switch"
  },
  {
    classId: "EC000313",
    classGroup: "EG000037",
    artClassDesc: "Communications technology adapter",
    artClassVersion: 7,
    label: "EC000313: Communications technology adapter"
  },
  {
    classId: "EC000317",
    classGroup: "EG000011",
    artClassDesc: "Document holder (enclosure/cabinet)",
    artClassVersion: 8,
    label: "EC000317: Document holder (enclosure/cabinet)"
  },
  {
    classId: "EC000320",
    classGroup: "EG000011",
    artClassDesc: "Ventilator (enclosure/cabinet)",
    artClassVersion: 8,
    label: "EC000320: Ventilator (enclosure/cabinet)"
  },
  {
    classId: "EC000321",
    classGroup: "EG000011",
    artClassDesc: "Luminaire (enclosure/cabinet)",
    artClassVersion: 8,
    label: "EC000321: Luminaire (enclosure/cabinet)"
  },
  {
    classId: "EC000322",
    classGroup: "EG000015",
    artClassDesc: "Cable guide for enclosures/cabinets",
    artClassVersion: 7,
    label: "EC000322: Cable guide for enclosures/cabinets"
  },
  {
    classId: "EC000327",
    classGroup: "EG000015",
    artClassDesc: "Lock system for enclosure/cabinet",
    artClassVersion: 7,
    label: "EC000327: Lock system for enclosure/cabinet"
  },
  {
    classId: "EC000329",
    classGroup: "EG000015",
    artClassDesc: "Earthing rail for distribution board",
    artClassVersion: 6,
    label: "EC000329: Earthing rail for distribution board"
  },
  {
    classId: "EC000330",
    classGroup: "EG000013",
    artClassDesc: "Socket outlet combination",
    artClassVersion: 9,
    label: "EC000330: Socket outlet combination"
  },
  {
    classId: "EC000331",
    classGroup: "EG000011",
    artClassDesc: "Ventilation plate (enclosure/cabinet)",
    artClassVersion: 7,
    label: "EC000331: Ventilation plate (enclosure/cabinet)"
  },
  {
    classId: "EC000338",
    classGroup: "EG000040",
    artClassDesc: "Clock",
    artClassVersion: 9,
    label: "EC000338: Clock"
  },
  {
    classId: "EC000339",
    classGroup: "EG000043",
    artClassDesc: "Trace heating cable",
    artClassVersion: 7,
    label: "EC000339: Trace heating cable"
  },
  {
    classId: "EC000340",
    classGroup: "EG000043",
    artClassDesc: "Finishing set heating cable",
    artClassVersion: 5,
    label: "EC000340: Finishing set heating cable"
  },
  {
    classId: "EC000341",
    classGroup: "EG000040",
    artClassDesc: "Vending machine",
    artClassVersion: 5,
    label: "EC000341: Vending machine"
  },
  {
    classId: "EC000342",
    classGroup: "EG000045",
    artClassDesc: "Maximum demand monitor for distribution board",
    artClassVersion: 5,
    label: "EC000342: Maximum demand monitor for distribution board"
  },
  {
    classId: "EC000343",
    classGroup: "EG000034",
    artClassDesc: "Garage door operator",
    artClassVersion: 6,
    label: "EC000343: Garage door operator"
  },
  {
    classId: "EC000344",
    classGroup: "EG000034",
    artClassDesc: "Strap motor",
    artClassVersion: 5,
    label: "EC000344: Strap motor"
  },
  {
    classId: "EC000345",
    classGroup: "EG000034",
    artClassDesc: "Tube mounted motor",
    artClassVersion: 4,
    label: "EC000345: Tube mounted motor"
  },
  {
    classId: "EC000346",
    classGroup: "EG000027",
    artClassDesc: "Building site lighting luminaire",
    artClassVersion: 9,
    label: "EC000346: Building site lighting luminaire"
  },
  {
    classId: "EC000347",
    classGroup: "EG000027",
    artClassDesc: "Hand luminaire",
    artClassVersion: 8,
    label: "EC000347: Hand luminaire"
  },
  {
    classId: "EC000348",
    classGroup: "EG000027",
    artClassDesc: "Machine and work bench luminaire",
    artClassVersion: 8,
    label: "EC000348: Machine and work bench luminaire"
  },
  {
    classId: "EC000349",
    classGroup: "EG000036",
    artClassDesc: "Functional module for door communication",
    artClassVersion: 8,
    label: "EC000349: Functional module for door communication"
  },
  {
    classId: "EC000350",
    classGroup: "EG000036",
    artClassDesc: "Mounting frame for door station",
    artClassVersion: 7,
    label: "EC000350: Mounting frame for door station"
  },
  {
    classId: "EC000352",
    classGroup: "EG000038",
    artClassDesc: "System telephone",
    artClassVersion: 5,
    label: "EC000352: System telephone"
  },
  {
    classId: "EC000354",
    classGroup: "EG000036",
    artClassDesc: "Mounting element for indoor station door communication",
    artClassVersion: 7,
    label: "EC000354: Mounting element for indoor station door communication"
  },
  {
    classId: "EC000355",
    classGroup: "EG000036",
    artClassDesc: "Door station door communication",
    artClassVersion: 8,
    label: "EC000355: Door station door communication"
  },
  {
    classId: "EC000356",
    classGroup: "EG000053",
    artClassDesc: "Battery (not rechargeable)",
    artClassVersion: 8,
    label: "EC000356: Battery (not rechargeable)"
  },
  {
    classId: "EC000357",
    classGroup: "EG000053",
    artClassDesc: "Rechargeable battery",
    artClassVersion: 9,
    label: "EC000357: Rechargeable battery"
  },
  {
    classId: "EC000359",
    classGroup: "EG000053",
    artClassDesc: "Universal charger",
    artClassVersion: 8,
    label: "EC000359: Universal charger"
  },
  {
    classId: "EC000362",
    classGroup: "EG000060",
    artClassDesc: "Labeling letters/numbers",
    artClassVersion: 7,
    label: "EC000362: Labeling letters/numbers"
  },
  {
    classId: "EC000363",
    classGroup: "EG000010",
    artClassDesc: "Cable clip",
    artClassVersion: 8,
    label: "EC000363: Cable clip"
  },
  {
    classId: "EC000364",
    classGroup: "EG000049",
    artClassDesc: "Screw anchor",
    artClassVersion: 6,
    label: "EC000364: Screw anchor"
  },
  {
    classId: "EC000365",
    classGroup: "EG000050",
    artClassDesc: "Punch",
    artClassVersion: 7,
    label: "EC000365: Punch"
  },
  {
    classId: "EC000367",
    classGroup: "EG020004",
    artClassDesc: "Electrical door opener",
    artClassVersion: 7,
    label: "EC000367: Electrical door opener"
  },
  {
    classId: "EC000368",
    classGroup: "EG000010",
    artClassDesc: "Tube clamp",
    artClassVersion: 9,
    label: "EC000368: Tube clamp"
  },
  {
    classId: "EC000370",
    classGroup: "EG000010",
    artClassDesc: "Cable guide",
    artClassVersion: 5,
    label: "EC000370: Cable guide"
  },
  {
    classId: "EC000371",
    classGroup: "EG000037",
    artClassDesc: "Telecommunication terminal strip",
    artClassVersion: 7,
    label: "EC000371: Telecommunication terminal strip"
  },
  {
    classId: "EC000372",
    classGroup: "EG000037",
    artClassDesc: "LSA-Plus mounting box",
    artClassVersion: 4,
    label: "EC000372: LSA-Plus mounting box"
  },
  {
    classId: "EC000373",
    classGroup: "EG000037",
    artClassDesc: "Additional device for telecommunication technology",
    artClassVersion: 7,
    label: "EC000373: Additional device for telecommunication technology"
  },
  {
    classId: "EC000374",
    classGroup: "EG000037",
    artClassDesc: "Distributor for telecommunication",
    artClassVersion: 8,
    label: "EC000374: Distributor for telecommunication"
  },
  {
    classId: "EC000375",
    classGroup: "EG000038",
    artClassDesc: "Telephone system",
    artClassVersion: 6,
    label: "EC000375: Telephone system"
  },
  {
    classId: "EC000376",
    classGroup: "EG000037",
    artClassDesc: "Mounting plate for fibre optic connection box",
    artClassVersion: 5,
    label: "EC000376: Mounting plate for fibre optic connection box"
  },
  {
    classId: "EC000379",
    classGroup: "EG000014",
    artClassDesc: "CEE socket outlet combination (IEC 60309)",
    artClassVersion: 9,
    label: "EC000379: CEE socket outlet combination (IEC 60309)"
  },
  {
    classId: "EC000380",
    classGroup: "EG000044",
    artClassDesc: "Clamp measuring instrument",
    artClassVersion: 8,
    label: "EC000380: Clamp measuring instrument"
  },
  {
    classId: "EC000381",
    classGroup: "EG000021",
    artClassDesc: "Lightning current arrester for power supply systems",
    artClassVersion: 9,
    label: "EC000381: Lightning current arrester for power supply systems"
  },
  {
    classId: "EC000382",
    classGroup: "EG000037",
    artClassDesc: "UPS",
    artClassVersion: 8,
    label: "EC000382: UPS"
  },
  {
    classId: "EC000385",
    classGroup: "EG000052",
    artClassDesc: "Special tool for telecommunication technology",
    artClassVersion: 7,
    label: "EC000385: Special tool for telecommunication technology"
  },
  {
    classId: "EC000386",
    classGroup: "EG000049",
    artClassDesc: "Mounting rail/-profile",
    artClassVersion: 8,
    label: "EC000386: Mounting rail/-profile"
  },
  {
    classId: "EC000387",
    classGroup: "EG000005",
    artClassDesc: "Separation plate for surface tank",
    artClassVersion: 5,
    label: "EC000387: Separation plate for surface tank"
  },
  {
    classId: "EC000390",
    classGroup: "EG000042",
    artClassDesc: "Boiling water unit",
    artClassVersion: 6,
    label: "EC000390: Boiling water unit"
  },
  {
    classId: "EC000392",
    classGroup: "EG000042",
    artClassDesc: "Instantaneous water heater",
    artClassVersion: 8,
    label: "EC000392: Instantaneous water heater"
  },
  {
    classId: "EC000393",
    classGroup: "EG000042",
    artClassDesc: "Hot water heat pump",
    artClassVersion: 9,
    label: "EC000393: Hot water heat pump"
  },
  {
    classId: "EC000395",
    classGroup: "EG000043",
    artClassDesc: "Storage heater",
    artClassVersion: 7,
    label: "EC000395: Storage heater"
  },
  {
    classId: "EC000396",
    classGroup: "EG000043",
    artClassDesc: "Heating element set for storage heater",
    artClassVersion: 5,
    label: "EC000396: Heating element set for storage heater"
  },
  {
    classId: "EC000397",
    classGroup: "EG000043",
    artClassDesc: "Housing for storage heater",
    artClassVersion: 5,
    label: "EC000397: Housing for storage heater"
  },
  {
    classId: "EC000398",
    classGroup: "EG000029",
    artClassDesc: "Distribution terminal for transformer",
    artClassVersion: 7,
    label: "EC000398: Distribution terminal for transformer"
  },
  {
    classId: "EC000399",
    classGroup: "EG000028",
    artClassDesc: "Indication- and signalling lamp",
    artClassVersion: 9,
    label: "EC000399: Indication- and signalling lamp"
  },
  {
    classId: "EC000400",
    classGroup: "EG000036",
    artClassDesc: "Door station set",
    artClassVersion: 8,
    label: "EC000400: Door station set"
  },
  {
    classId: "EC000401",
    classGroup: "EG000030",
    artClassDesc: "Lamp holder",
    artClassVersion: 8,
    label: "EC000401: Lamp holder"
  },
  {
    classId: "EC000405",
    classGroup: "EG000043",
    artClassDesc: "Electric surface heating",
    artClassVersion: 8,
    label: "EC000405: Electric surface heating"
  },
  {
    classId: "EC000406",
    classGroup: "EG000042",
    artClassDesc: "Thermal insulation",
    artClassVersion: 5,
    label: "EC000406: Thermal insulation"
  },
  {
    classId: "EC000408",
    classGroup: "EG000038",
    artClassDesc: "Battery for mobile and wireless telephone",
    artClassVersion: 6,
    label: "EC000408: Battery for mobile and wireless telephone"
  },
  {
    classId: "EC000409",
    classGroup: "EG000037",
    artClassDesc: "Booster",
    artClassVersion: 4,
    label: "EC000409: Booster"
  },
  {
    classId: "EC000411",
    classGroup: "EG000043",
    artClassDesc: "Charging controller",
    artClassVersion: 7,
    label: "EC000411: Charging controller"
  },
  {
    classId: "EC000412",
    classGroup: "EG000044",
    artClassDesc: "Lux meter",
    artClassVersion: 6,
    label: "EC000412: Lux meter"
  },
  {
    classId: "EC000414",
    classGroup: "EG000030",
    artClassDesc: "Built-in installation box luminaire",
    artClassVersion: 8,
    label: "EC000414: Built-in installation box luminaire"
  },
  {
    classId: "EC000415",
    classGroup: "EG020002",
    artClassDesc: "Hole saw",
    artClassVersion: 7,
    label: "EC000415: Hole saw"
  },
  {
    classId: "EC000416",
    classGroup: "EG000033",
    artClassDesc: "Terrestrial antenna",
    artClassVersion: 7,
    label: "EC000416: Terrestrial antenna"
  },
  {
    classId: "EC000417",
    classGroup: "EG000033",
    artClassDesc: "Head end station",
    artClassVersion: 4,
    label: "EC000417: Head end station"
  },
  {
    classId: "EC000418",
    classGroup: "EG000033",
    artClassDesc: "Compact head end station",
    artClassVersion: 5,
    label: "EC000418: Compact head end station"
  },
  {
    classId: "EC000419",
    classGroup: "EG000033",
    artClassDesc: "Module for head end station",
    artClassVersion: 5,
    label: "EC000419: Module for head end station"
  },
  {
    classId: "EC000420",
    classGroup: "EG000033",
    artClassDesc: "Diplexer/filter",
    artClassVersion: 5,
    label: "EC000420: Diplexer/filter"
  },
  {
    classId: "EC000421",
    classGroup: "EG000033",
    artClassDesc: "Tap-off and distributor",
    artClassVersion: 7,
    label: "EC000421: Tap-off and distributor"
  },
  {
    classId: "EC000422",
    classGroup: "EG000033",
    artClassDesc: "Multi switch for communication technology",
    artClassVersion: 5,
    label: "EC000422: Multi switch for communication technology"
  },
  {
    classId: "EC000423",
    classGroup: "EG000033",
    artClassDesc: "CATV-amplifier",
    artClassVersion: 6,
    label: "EC000423: CATV-amplifier"
  },
  {
    classId: "EC000424",
    classGroup: "EG000033",
    artClassDesc: "Receiver",
    artClassVersion: 7,
    label: "EC000424: Receiver"
  },
  {
    classId: "EC000425",
    classGroup: "EG000033",
    artClassDesc: "Feeding system/LNB",
    artClassVersion: 5,
    label: "EC000425: Feeding system/LNB"
  },
  {
    classId: "EC000426",
    classGroup: "EG000033",
    artClassDesc: "Satellite antenna",
    artClassVersion: 5,
    label: "EC000426: Satellite antenna"
  },
  {
    classId: "EC000427",
    classGroup: "EG000043",
    artClassDesc: "Finned-tube heater",
    artClassVersion: 7,
    label: "EC000427: Finned-tube heater"
  },
  {
    classId: "EC000429",
    classGroup: "EG000034",
    artClassDesc: "Pumping shaft-container",
    artClassVersion: 5,
    label: "EC000429: Pumping shaft-container"
  },
  {
    classId: "EC000430",
    classGroup: "EG000036",
    artClassDesc: "Recessed mounted box for doorbell",
    artClassVersion: 6,
    label: "EC000430: Recessed mounted box for doorbell"
  },
  {
    classId: "EC000432",
    classGroup: "EG000033",
    artClassDesc: "Level adjuster",
    artClassVersion: 5,
    label: "EC000432: Level adjuster"
  },
  {
    classId: "EC000433",
    classGroup: "EG000033",
    artClassDesc: "Antenna pole",
    artClassVersion: 5,
    label: "EC000433: Antenna pole"
  },
  {
    classId: "EC000434",
    classGroup: "EG000033",
    artClassDesc: "Antenna support bracket",
    artClassVersion: 4,
    label: "EC000434: Antenna support bracket"
  },
  {
    classId: "EC000435",
    classGroup: "EG000034",
    artClassDesc: "Sun-/twilight sensor for roller-blind drive",
    artClassVersion: 5,
    label: "EC000435: Sun-/twilight sensor for roller-blind drive"
  },
  {
    classId: "EC000436",
    classGroup: "EG000013",
    artClassDesc: "Radio receiver",
    artClassVersion: 9,
    label: "EC000436: Radio receiver"
  },
  {
    classId: "EC000437",
    classGroup: "EG000058",
    artClassDesc: "Housing for rectangular connectors",
    artClassVersion: 7,
    label: "EC000437: Housing for rectangular connectors"
  },
  {
    classId: "EC000438",
    classGroup: "EG000058",
    artClassDesc: "Contact insert for rectangular connectors",
    artClassVersion: 8,
    label: "EC000438: Contact insert for rectangular connectors"
  },
  {
    classId: "EC000439",
    classGroup: "EG000033",
    artClassDesc: "Antenna socket box",
    artClassVersion: 8,
    label: "EC000439: Antenna socket box"
  },
  {
    classId: "EC000440",
    classGroup: "EG000033",
    artClassDesc: "Satellite set",
    artClassVersion: 5,
    label: "EC000440: Satellite set"
  },
  {
    classId: "EC000441",
    classGroup: "EG000009",
    artClassDesc: "Cable gland",
    artClassVersion: 9,
    label: "EC000441: Cable gland"
  },
  {
    classId: "EC000443",
    classGroup: "EG000013",
    artClassDesc: "Device built-in switch and -push button",
    artClassVersion: 8,
    label: "EC000443: Device built-in switch and -push button"
  },
  {
    classId: "EC000444",
    classGroup: "EG000013",
    artClassDesc: "Insert for child protection",
    artClassVersion: 6,
    label: "EC000444: Insert for child protection"
  },
  {
    classId: "EC000445",
    classGroup: "EG000013",
    artClassDesc: "Cord switch/dimmer",
    artClassVersion: 9,
    label: "EC000445: Cord switch/dimmer"
  },
  {
    classId: "EC000446",
    classGroup: "EG000047",
    artClassDesc: "Push in terminal",
    artClassVersion: 8,
    label: "EC000446: Push in terminal"
  },
  {
    classId: "EC000448",
    classGroup: "EG000033",
    artClassDesc: "Terminal resistor",
    artClassVersion: 4,
    label: "EC000448: Terminal resistor"
  },
  {
    classId: "EC000449",
    classGroup: "EG000049",
    artClassDesc: "Mounting base and -element for cable ties",
    artClassVersion: 9,
    label: "EC000449: Mounting base and -element for cable ties"
  },
  {
    classId: "EC000450",
    classGroup: "EG000013",
    artClassDesc: "Appliance connection box",
    artClassVersion: 7,
    label: "EC000450: Appliance connection box"
  },
  {
    classId: "EC000451",
    classGroup: "EG000009",
    artClassDesc: "Cable plug sealing clamp",
    artClassVersion: 6,
    label: "EC000451: Cable plug sealing clamp"
  },
  {
    classId: "EC000452",
    classGroup: "EG000017",
    artClassDesc: "Motor protection plug",
    artClassVersion: 5,
    label: "EC000452: Motor protection plug"
  },
  {
    classId: "EC000453",
    classGroup: "EG000050",
    artClassDesc: "Cable tie tool",
    artClassVersion: 6,
    label: "EC000453: Cable tie tool"
  },
  {
    classId: "EC000454",
    classGroup: "EG000010",
    artClassDesc: "One-piece strut clamp",
    artClassVersion: 8,
    label: "EC000454: One-piece strut clamp"
  },
  {
    classId: "EC000455",
    classGroup: "EG000010",
    artClassDesc: "Counter shell for cable clamp",
    artClassVersion: 7,
    label: "EC000455: Counter shell for cable clamp"
  },
  {
    classId: "EC000456",
    classGroup: "EG000011",
    artClassDesc: "Front panel (enclosure/cabinet)",
    artClassVersion: 8,
    label: "EC000456: Front panel (enclosure/cabinet)"
  },
  {
    classId: "EC000461",
    classGroup: "EG000008",
    artClassDesc: "Cover plate for installation column",
    artClassVersion: 7,
    label: "EC000461: Cover plate for installation column"
  },
  {
    classId: "EC000462",
    classGroup: "EG000014",
    artClassDesc: "Perilex plug",
    artClassVersion: 6,
    label: "EC000462: Perilex plug"
  },
  {
    classId: "EC000464",
    classGroup: "EG000049",
    artClassDesc: "Fixing clamp",
    artClassVersion: 9,
    label: "EC000464: Fixing clamp"
  },
  {
    classId: "EC000466",
    classGroup: "EG000047",
    artClassDesc: "Heat-shrink crimp connection",
    artClassVersion: 7,
    label: "EC000466: Heat-shrink crimp connection"
  },
  {
    classId: "EC000467",
    classGroup: "EG000037",
    artClassDesc: "Balun",
    artClassVersion: 7,
    label: "EC000467: Balun"
  },
  {
    classId: "EC000468",
    classGroup: "EG000049",
    artClassDesc: "S-Hook",
    artClassVersion: 6,
    label: "EC000468: S-Hook"
  },
  {
    classId: "EC000469",
    classGroup: "EG000049",
    artClassDesc: "Chain connecting link",
    artClassVersion: 5,
    label: "EC000469: Chain connecting link"
  },
  {
    classId: "EC000470",
    classGroup: "EG000027",
    artClassDesc: "Fibre optic cable light system",
    artClassVersion: 9,
    label: "EC000470: Fibre optic cable light system"
  },
  {
    classId: "EC000471",
    classGroup: "EG000030",
    artClassDesc: "Emergency unit for luminaire",
    artClassVersion: 7,
    label: "EC000471: Emergency unit for luminaire"
  },
  {
    classId: "EC000472",
    classGroup: "EG000021",
    artClassDesc: "Basic element for surge protection device",
    artClassVersion: 6,
    label: "EC000472: Basic element for surge protection device"
  },
  {
    classId: "EC000473",
    classGroup: "EG000049",
    artClassDesc: "Hanger for trapezoidal ceiling",
    artClassVersion: 6,
    label: "EC000473: Hanger for trapezoidal ceiling"
  },
  {
    classId: "EC000474",
    classGroup: "EG000049",
    artClassDesc: "Screw clamp",
    artClassVersion: 7,
    label: "EC000474: Screw clamp"
  },
  {
    classId: "EC000475",
    classGroup: "EG000049",
    artClassDesc: "Protective cap/cover for mounting rail/- profile",
    artClassVersion: 8,
    label: "EC000475: Protective cap/cover for mounting rail/- profile"
  },
  {
    classId: "EC000478",
    classGroup: "EG000036",
    artClassDesc: "Letterbox",
    artClassVersion: 7,
    label: "EC000478: Letterbox"
  },
  {
    classId: "EC000479",
    classGroup: "EG000030",
    artClassDesc: "Pictogram for emergency luminaire",
    artClassVersion: 7,
    label: "EC000479: Pictogram for emergency luminaire"
  },
  {
    classId: "EC000481",
    classGroup: "EG000027",
    artClassDesc: "Orientation luminaire",
    artClassVersion: 9,
    label: "EC000481: Orientation luminaire"
  },
  {
    classId: "EC000482",
    classGroup: "EG000049",
    artClassDesc: "Fastening element for mounting rail/-profile",
    artClassVersion: 8,
    label: "EC000482: Fastening element for mounting rail/-profile"
  },
  {
    classId: "EC000483",
    classGroup: "EG000004",
    artClassDesc: "Coupler for support/profile rail",
    artClassVersion: 7,
    label: "EC000483: Coupler for support/profile rail"
  },
  {
    classId: "EC000484",
    classGroup: "EG000023",
    artClassDesc: "Energy distributor for construction site",
    artClassVersion: 8,
    label: "EC000484: Energy distributor for construction site"
  },
  {
    classId: "EC000485",
    classGroup: "EG000012",
    artClassDesc: "Neutral busbar",
    artClassVersion: 5,
    label: "EC000485: Neutral busbar"
  },
  {
    classId: "EC000486",
    classGroup: "EG000029",
    artClassDesc: "Electrical unit for gas discharge lamp",
    artClassVersion: 8,
    label: "EC000486: Electrical unit for gas discharge lamp"
  },
  {
    classId: "EC000488",
    classGroup: "EG000034",
    artClassDesc: "Octagonal steel shaft for tube motor",
    artClassVersion: 5,
    label: "EC000488: Octagonal steel shaft for tube motor"
  },
  {
    classId: "EC000489",
    classGroup: "EG000012",
    artClassDesc: "Cross-connector for terminal block",
    artClassVersion: 7,
    label: "EC000489: Cross-connector for terminal block"
  },
  {
    classId: "EC000490",
    classGroup: "EG000021",
    artClassDesc: "Accessories/spare parts for earthing and lightning",
    artClassVersion: 8,
    label: "EC000490: Accessories/spare parts for earthing and lightning"
  },
  {
    classId: "EC000491",
    classGroup: "EG000021",
    artClassDesc: "Earth terminal clamp",
    artClassVersion: 6,
    label: "EC000491: Earth terminal clamp"
  },
  {
    classId: "EC000492",
    classGroup: "EG000021",
    artClassDesc: "Earth rod/profiled earth rod",
    artClassVersion: 7,
    label: "EC000492: Earth rod/profiled earth rod"
  },
  {
    classId: "EC000493",
    classGroup: "EG000021",
    artClassDesc: "Earthing pipe clamp",
    artClassVersion: 8,
    label: "EC000493: Earthing pipe clamp"
  },
  {
    classId: "EC000494",
    classGroup: "EG000044",
    artClassDesc: "Voltage tester",
    artClassVersion: 9,
    label: "EC000494: Voltage tester"
  },
  {
    classId: "EC000495",
    classGroup: "EG000044",
    artClassDesc: "Continuity tester",
    artClassVersion: 5,
    label: "EC000495: Continuity tester"
  },
  {
    classId: "EC000496",
    classGroup: "EG000044",
    artClassDesc: "Pipe and cable locator/sorter",
    artClassVersion: 7,
    label: "EC000496: Pipe and cable locator/sorter"
  },
  {
    classId: "EC000497",
    classGroup: "EG000044",
    artClassDesc: "Multifunction installation tester",
    artClassVersion: 8,
    label: "EC000497: Multifunction installation tester"
  },
  {
    classId: "EC000498",
    classGroup: "EG000044",
    artClassDesc: "Portable appliance tester/PAT tester",
    artClassVersion: 9,
    label: "EC000498: Portable appliance tester/PAT tester"
  },
  {
    classId: "EC000499",
    classGroup: "EG000044",
    artClassDesc: "Insulation tester",
    artClassVersion: 8,
    label: "EC000499: Insulation tester"
  },
  {
    classId: "EC000500",
    classGroup: "EG000044",
    artClassDesc: "Cable length meter",
    artClassVersion: 6,
    label: "EC000500: Cable length meter"
  },
  {
    classId: "EC000501",
    classGroup: "EG000037",
    artClassDesc: "Network management software",
    artClassVersion: 4,
    label: "EC000501: Network management software"
  },
  {
    classId: "EC000502",
    classGroup: "EG000013",
    artClassDesc: "Light/energy pole",
    artClassVersion: 9,
    label: "EC000502: Light/energy pole"
  },
  {
    classId: "EC000503",
    classGroup: "EG000028",
    artClassDesc: "Globe-shaped incandescent lamp",
    artClassVersion: 9,
    label: "EC000503: Globe-shaped incandescent lamp"
  },
  {
    classId: "EC000504",
    classGroup: "EG000021",
    artClassDesc: "Round conductor/wire for lightning protection",
    artClassVersion: 8,
    label: "EC000504: Round conductor/wire for lightning protection"
  },
  {
    classId: "EC000505",
    classGroup: "EG000021",
    artClassDesc: "Air-termination equipment for lightning protection",
    artClassVersion: 7,
    label: "EC000505: Air-termination equipment for lightning protection"
  },
  {
    classId: "EC000506",
    classGroup: "EG000021",
    artClassDesc: "Lead-in earthing rod for lightning protection",
    artClassVersion: 6,
    label: "EC000506: Lead-in earthing rod for lightning protection"
  },
  {
    classId: "EC000507",
    classGroup: "EG000021",
    artClassDesc: "Roof conductor holder for lightning protection",
    artClassVersion: 8,
    label: "EC000507: Roof conductor holder for lightning protection"
  },
  {
    classId: "EC000508",
    classGroup: "EG000021",
    artClassDesc: "Conductor holder for lightning protection",
    artClassVersion: 9,
    label: "EC000508: Conductor holder for lightning protection"
  },
  {
    classId: "EC000509",
    classGroup: "EG000021",
    artClassDesc: "Rod holder for lightning protection",
    artClassVersion: 7,
    label: "EC000509: Rod holder for lightning protection"
  },
  {
    classId: "EC000510",
    classGroup: "EG000021",
    artClassDesc: "Protective/isolating spark gap for lightning protection",
    artClassVersion: 8,
    label: "EC000510: Protective/isolating spark gap for lightning protection"
  },
  {
    classId: "EC000511",
    classGroup: "EG000021",
    artClassDesc: "Tube earthing clamp for lightning protection",
    artClassVersion: 6,
    label: "EC000511: Tube earthing clamp for lightning protection"
  },
  {
    classId: "EC000512",
    classGroup: "EG000021",
    artClassDesc: "Disconnection clamp for lightning protection",
    artClassVersion: 8,
    label: "EC000512: Disconnection clamp for lightning protection"
  },
  {
    classId: "EC000513",
    classGroup: "EG000021",
    artClassDesc: "Connection clamp for lightning protection",
    artClassVersion: 8,
    label: "EC000513: Connection clamp for lightning protection"
  },
  {
    classId: "EC000514",
    classGroup: "EG000021",
    artClassDesc: "Connector for lightning protection",
    artClassVersion: 8,
    label: "EC000514: Connector for lightning protection"
  },
  {
    classId: "EC000515",
    classGroup: "EG000037",
    artClassDesc: "Network adapter",
    artClassVersion: 6,
    label: "EC000515: Network adapter"
  },
  {
    classId: "EC000516",
    classGroup: "EG000047",
    artClassDesc: "Round or flat plug/receptacle",
    artClassVersion: 9,
    label: "EC000516: Round or flat plug/receptacle"
  },
  {
    classId: "EC000519",
    classGroup: "EG000003",
    artClassDesc: "Terminal sleeve for protective hose",
    artClassVersion: 5,
    label: "EC000519: Terminal sleeve for protective hose"
  },
  {
    classId: "EC000522",
    classGroup: "EG000014",
    artClassDesc: "Explosion proof switch",
    artClassVersion: 6,
    label: "EC000522: Explosion proof switch"
  },
  {
    classId: "EC000523",
    classGroup: "EG000043",
    artClassDesc: "Convector (electric)",
    artClassVersion: 9,
    label: "EC000523: Convector (electric)"
  },
  {
    classId: "EC000524",
    classGroup: "EG000047",
    artClassDesc: "Assortment box for installation- and connection material",
    artClassVersion: 6,
    label: "EC000524: Assortment box for installation- and connection material"
  },
  {
    classId: "EC000525",
    classGroup: "EG000033",
    artClassDesc: "Satellite amplifier",
    artClassVersion: 4,
    label: "EC000525: Satellite amplifier"
  },
  {
    classId: "EC000526",
    classGroup: "EG000033",
    artClassDesc: "Terrestrial amplifier",
    artClassVersion: 4,
    label: "EC000526: Terrestrial amplifier"
  },
  {
    classId: "EC000528",
    classGroup: "EG000021",
    artClassDesc: "Earthing plate",
    artClassVersion: 7,
    label: "EC000528: Earthing plate"
  },
  {
    classId: "EC000529",
    classGroup: "EG000049",
    artClassDesc: "Adhesive",
    artClassVersion: 8,
    label: "EC000529: Adhesive"
  },
  {
    classId: "EC000530",
    classGroup: "EG000049",
    artClassDesc: "Solvent",
    artClassVersion: 5,
    label: "EC000530: Solvent"
  },
  {
    classId: "EC000533",
    classGroup: "EG000029",
    artClassDesc: "Lighting control system component",
    artClassVersion: 7,
    label: "EC000533: Lighting control system component"
  },
  {
    classId: "EC000534",
    classGroup: "EG000013",
    artClassDesc: "Automatic controller for light controlling system",
    artClassVersion: 9,
    label: "EC000534: Automatic controller for light controlling system"
  },
  {
    classId: "EC000535",
    classGroup: "EG000043",
    artClassDesc: "Radiant heat emitter",
    artClassVersion: 7,
    label: "EC000535: Radiant heat emitter"
  },
  {
    classId: "EC000536",
    classGroup: "EG000010",
    artClassDesc: "Cable clip bracket",
    artClassVersion: 6,
    label: "EC000536: Cable clip bracket"
  },
  {
    classId: "EC000537",
    classGroup: "EG000049",
    artClassDesc: "Nail disk",
    artClassVersion: 5,
    label: "EC000537: Nail disk"
  },
  {
    classId: "EC000539",
    classGroup: "EG000040",
    artClassDesc: "Blood pressure measuring instrument",
    artClassVersion: 5,
    label: "EC000539: Blood pressure measuring instrument"
  },
  {
    classId: "EC000540",
    classGroup: "EG000040",
    artClassDesc: "Massager",
    artClassVersion: 5,
    label: "EC000540: Massager"
  },
  {
    classId: "EC000541",
    classGroup: "EG000040",
    artClassDesc: "Steam cleaner",
    artClassVersion: 7,
    label: "EC000541: Steam cleaner"
  },
  {
    classId: "EC000542",
    classGroup: "EG000040",
    artClassDesc: "Vacuum cleaner (electric)",
    artClassVersion: 9,
    label: "EC000542: Vacuum cleaner (electric)"
  },
  {
    classId: "EC000543",
    classGroup: "EG000051",
    artClassDesc: "Blower vac (electrical)",
    artClassVersion: 9,
    label: "EC000543: Blower vac (electrical)"
  },
  {
    classId: "EC000544",
    classGroup: "EG000040",
    artClassDesc: "Pocket calculator",
    artClassVersion: 7,
    label: "EC000544: Pocket calculator"
  },
  {
    classId: "EC000545",
    classGroup: "EG000040",
    artClassDesc: "Tanning appliance",
    artClassVersion: 6,
    label: "EC000545: Tanning appliance"
  },
  {
    classId: "EC000546",
    classGroup: "EG000040",
    artClassDesc: "Hair dryer/hair styler",
    artClassVersion: 7,
    label: "EC000546: Hair dryer/hair styler"
  },
  {
    classId: "EC000548",
    classGroup: "EG000040",
    artClassDesc: "Flatiron",
    artClassVersion: 7,
    label: "EC000548: Flatiron"
  },
  {
    classId: "EC000551",
    classGroup: "EG000040",
    artClassDesc: "Electric shaver",
    artClassVersion: 7,
    label: "EC000551: Electric shaver"
  },
  {
    classId: "EC000552",
    classGroup: "EG000040",
    artClassDesc: "Body care appliance",
    artClassVersion: 6,
    label: "EC000552: Body care appliance"
  },
  {
    classId: "EC000553",
    classGroup: "EG000040",
    artClassDesc: "Hair trimmer",
    artClassVersion: 6,
    label: "EC000553: Hair trimmer"
  },
  {
    classId: "EC000554",
    classGroup: "EG000040",
    artClassDesc: "Wall hair dryer",
    artClassVersion: 6,
    label: "EC000554: Wall hair dryer"
  },
  {
    classId: "EC000555",
    classGroup: "EG000040",
    artClassDesc: "Personal scale",
    artClassVersion: 6,
    label: "EC000555: Personal scale"
  },
  {
    classId: "EC000556",
    classGroup: "EG000040",
    artClassDesc: "Electric blanket/pillow/foot warmer",
    artClassVersion: 6,
    label: "EC000556: Electric blanket/pillow/foot warmer"
  },
  {
    classId: "EC000557",
    classGroup: "EG000040",
    artClassDesc: "Fever thermometer",
    artClassVersion: 5,
    label: "EC000557: Fever thermometer"
  },
  {
    classId: "EC000558",
    classGroup: "EG000040",
    artClassDesc: "Oral care appliance",
    artClassVersion: 7,
    label: "EC000558: Oral care appliance"
  },
  {
    classId: "EC000560",
    classGroup: "EG000040",
    artClassDesc: "Mixer/blender",
    artClassVersion: 6,
    label: "EC000560: Mixer/blender"
  },
  {
    classId: "EC000561",
    classGroup: "EG000040",
    artClassDesc: "Can opener",
    artClassVersion: 6,
    label: "EC000561: Can opener"
  },
  {
    classId: "EC000562",
    classGroup: "EG000040",
    artClassDesc: "Knife sharpener",
    artClassVersion: 6,
    label: "EC000562: Knife sharpener"
  },
  {
    classId: "EC000563",
    classGroup: "EG000040",
    artClassDesc: "Baby food warmer",
    artClassVersion: 6,
    label: "EC000563: Baby food warmer"
  },
  {
    classId: "EC000564",
    classGroup: "EG000040",
    artClassDesc: "Kitchen scale",
    artClassVersion: 7,
    label: "EC000564: Kitchen scale"
  },
  {
    classId: "EC000565",
    classGroup: "EG000040",
    artClassDesc: "Slicing machine",
    artClassVersion: 7,
    label: "EC000565: Slicing machine"
  },
  {
    classId: "EC000566",
    classGroup: "EG000040",
    artClassDesc: "Electric knife",
    artClassVersion: 6,
    label: "EC000566: Electric knife"
  },
  {
    classId: "EC000567",
    classGroup: "EG000040",
    artClassDesc: "Food dehydrator",
    artClassVersion: 6,
    label: "EC000567: Food dehydrator"
  },
  {
    classId: "EC000568",
    classGroup: "EG000040",
    artClassDesc: "Squeezer/juicer",
    artClassVersion: 6,
    label: "EC000568: Squeezer/juicer"
  },
  {
    classId: "EC000569",
    classGroup: "EG000040",
    artClassDesc: "Ice cream maker",
    artClassVersion: 6,
    label: "EC000569: Ice cream maker"
  },
  {
    classId: "EC000570",
    classGroup: "EG000040",
    artClassDesc: "Chopper",
    artClassVersion: 6,
    label: "EC000570: Chopper"
  },
  {
    classId: "EC000571",
    classGroup: "EG000040",
    artClassDesc: "Kitchen machine",
    artClassVersion: 7,
    label: "EC000571: Kitchen machine"
  },
  {
    classId: "EC000572",
    classGroup: "EG000040",
    artClassDesc: "Vacuum sealer",
    artClassVersion: 8,
    label: "EC000572: Vacuum sealer"
  },
  {
    classId: "EC000574",
    classGroup: "EG000040",
    artClassDesc: "Raclette set",
    artClassVersion: 6,
    label: "EC000574: Raclette set"
  },
  {
    classId: "EC000575",
    classGroup: "EG000040",
    artClassDesc: "Grill",
    artClassVersion: 6,
    label: "EC000575: Grill"
  },
  {
    classId: "EC000576",
    classGroup: "EG000040",
    artClassDesc: "Fondue/wok",
    artClassVersion: 6,
    label: "EC000576: Fondue/wok"
  },
  {
    classId: "EC000577",
    classGroup: "EG000040",
    artClassDesc: "Egg boiler",
    artClassVersion: 6,
    label: "EC000577: Egg boiler"
  },
  {
    classId: "EC000578",
    classGroup: "EG000040",
    artClassDesc: "Espresso machine",
    artClassVersion: 6,
    label: "EC000578: Espresso machine"
  },
  {
    classId: "EC000579",
    classGroup: "EG000040",
    artClassDesc: "Steam oven",
    artClassVersion: 6,
    label: "EC000579: Steam oven"
  },
  {
    classId: "EC000580",
    classGroup: "EG000040",
    artClassDesc: "Yogurt maker",
    artClassVersion: 6,
    label: "EC000580: Yogurt maker"
  },
  {
    classId: "EC000581",
    classGroup: "EG000040",
    artClassDesc: "Kitchen timer",
    artClassVersion: 6,
    label: "EC000581: Kitchen timer"
  },
  {
    classId: "EC000582",
    classGroup: "EG000040",
    artClassDesc: "Table baking oven/-grill",
    artClassVersion: 7,
    label: "EC000582: Table baking oven/-grill"
  },
  {
    classId: "EC000583",
    classGroup: "EG000040",
    artClassDesc: "Immersion heater (electric)",
    artClassVersion: 7,
    label: "EC000583: Immersion heater (electric)"
  },
  {
    classId: "EC000584",
    classGroup: "EG000040",
    artClassDesc: "Warming tray",
    artClassVersion: 6,
    label: "EC000584: Warming tray"
  },
  {
    classId: "EC000585",
    classGroup: "EG000040",
    artClassDesc: "Coffee mill",
    artClassVersion: 7,
    label: "EC000585: Coffee mill"
  },
  {
    classId: "EC000586",
    classGroup: "EG000040",
    artClassDesc: "Automatic preserving cooker",
    artClassVersion: 6,
    label: "EC000586: Automatic preserving cooker"
  },
  {
    classId: "EC000587",
    classGroup: "EG000040",
    artClassDesc: "Water cooker",
    artClassVersion: 9,
    label: "EC000587: Water cooker"
  },
  {
    classId: "EC000588",
    classGroup: "EG000040",
    artClassDesc: "Toaster",
    artClassVersion: 6,
    label: "EC000588: Toaster"
  },
  {
    classId: "EC000589",
    classGroup: "EG000040",
    artClassDesc: "Deep fryer",
    artClassVersion: 6,
    label: "EC000589: Deep fryer"
  },
  {
    classId: "EC000590",
    classGroup: "EG000040",
    artClassDesc: "Waffle maker",
    artClassVersion: 6,
    label: "EC000590: Waffle maker"
  },
  {
    classId: "EC000591",
    classGroup: "EG000040",
    artClassDesc: "Rotary ironer",
    artClassVersion: 7,
    label: "EC000591: Rotary ironer"
  },
  {
    classId: "EC000592",
    classGroup: "EG000040",
    artClassDesc: "Facial sauna",
    artClassVersion: 6,
    label: "EC000592: Facial sauna"
  },
  {
    classId: "EC000593",
    classGroup: "EG000043",
    artClassDesc: "Radiator (electric)",
    artClassVersion: 8,
    label: "EC000593: Radiator (electric)"
  },
  {
    classId: "EC000594",
    classGroup: "EG000040",
    artClassDesc: "Bread machine",
    artClassVersion: 6,
    label: "EC000594: Bread machine"
  },
  {
    classId: "EC000595",
    classGroup: "EG000040",
    artClassDesc: "Sterilizer",
    artClassVersion: 7,
    label: "EC000595: Sterilizer"
  },
  {
    classId: "EC000596",
    classGroup: "EG000040",
    artClassDesc: "Alarm clock",
    artClassVersion: 6,
    label: "EC000596: Alarm clock"
  },
  {
    classId: "EC000598",
    classGroup: "EG000040",
    artClassDesc: "Coffee maker",
    artClassVersion: 6,
    label: "EC000598: Coffee maker"
  },
  {
    classId: "EC000599",
    classGroup: "EG000024",
    artClassDesc: "PLC system power supply",
    artClassVersion: 5,
    label: "EC000599: PLC system power supply"
  },
  {
    classId: "EC000600",
    classGroup: "EG000044",
    artClassDesc: "Lamp tester",
    artClassVersion: 7,
    label: "EC000600: Lamp tester"
  },
  {
    classId: "EC000601",
    classGroup: "EG000044",
    artClassDesc: "Measuring instrument for temperature and climate",
    artClassVersion: 7,
    label: "EC000601: Measuring instrument for temperature and climate"
  },
  {
    classId: "EC000602",
    classGroup: "EG000032",
    artClassDesc: "Data rail for bus system",
    artClassVersion: 8,
    label: "EC000602: Data rail for bus system"
  },
  {
    classId: "EC000603",
    classGroup: "EG000032",
    artClassDesc: "Data rail coupler for bus system",
    artClassVersion: 8,
    label: "EC000603: Data rail coupler for bus system"
  },
  {
    classId: "EC000606",
    classGroup: "EG000043",
    artClassDesc: "Air moisturizer/air refresher",
    artClassVersion: 8,
    label: "EC000606: Air moisturizer/air refresher"
  },
  {
    classId: "EC000607",
    classGroup: "EG000040",
    artClassDesc: "Epilator",
    artClassVersion: 7,
    label: "EC000607: Epilator"
  },
  {
    classId: "EC000608",
    classGroup: "EG000038",
    artClassDesc: "Labelling tape",
    artClassVersion: 9,
    label: "EC000608: Labelling tape"
  },
  {
    classId: "EC000609",
    classGroup: "EG000040",
    artClassDesc: "Floor scrubbing machine",
    artClassVersion: 7,
    label: "EC000609: Floor scrubbing machine"
  },
  {
    classId: "EC000610",
    classGroup: "EG000050",
    artClassDesc: "Label maker",
    artClassVersion: 6,
    label: "EC000610: Label maker"
  },
  {
    classId: "EC000613",
    classGroup: "EG000021",
    artClassDesc: "Decoupling coil for internal lightning protection",
    artClassVersion: 6,
    label: "EC000613: Decoupling coil for internal lightning protection"
  },
  {
    classId: "EC000615",
    classGroup: "EG000013",
    artClassDesc: "Venetian blind control unit",
    artClassVersion: 8,
    label: "EC000615: Venetian blind control unit"
  },
  {
    classId: "EC000616",
    classGroup: "EG000050",
    artClassDesc: "Rung ladder",
    artClassVersion: 9,
    label: "EC000616: Rung ladder"
  },
  {
    classId: "EC000617",
    classGroup: "EG000017",
    artClassDesc: "Trip block for power circuit-breaker",
    artClassVersion: 6,
    label: "EC000617: Trip block for power circuit-breaker"
  },
  {
    classId: "EC000618",
    classGroup: "EG000040",
    artClassDesc: "Meat grinder",
    artClassVersion: 6,
    label: "EC000618: Meat grinder"
  },
  {
    classId: "EC000619",
    classGroup: "EG000050",
    artClassDesc: "Butt chisels",
    artClassVersion: 5,
    label: "EC000619: Butt chisels"
  },
  {
    classId: "EC000620",
    classGroup: "EG000049",
    artClassDesc: "Screw assortment box",
    artClassVersion: 5,
    label: "EC000620: Screw assortment box"
  },
  {
    classId: "EC000621",
    classGroup: "EG000017",
    artClassDesc: "Legend plate for control circuit devices",
    artClassVersion: 5,
    label: "EC000621: Legend plate for control circuit devices"
  },
  {
    classId: "EC000623",
    classGroup: "EG020002",
    artClassDesc: "Glue stick for glue gun",
    artClassVersion: 7,
    label: "EC000623: Glue stick for glue gun"
  },
  {
    classId: "EC000624",
    classGroup: "EG000017",
    artClassDesc: "Text plate for command devices",
    artClassVersion: 6,
    label: "EC000624: Text plate for command devices"
  },
  {
    classId: "EC000625",
    classGroup: "EG000041",
    artClassDesc: "Car audio",
    artClassVersion: 6,
    label: "EC000625: Car audio"
  },
  {
    classId: "EC000628",
    classGroup: "EG000039",
    artClassDesc: "Refrigerator",
    artClassVersion: 9,
    label: "EC000628: Refrigerator"
  },
  {
    classId: "EC000629",
    classGroup: "EG000039",
    artClassDesc: "Fridge/freezer combination",
    artClassVersion: 9,
    label: "EC000629: Fridge/freezer combination"
  },
  {
    classId: "EC000630",
    classGroup: "EG000039",
    artClassDesc: "Freezer",
    artClassVersion: 9,
    label: "EC000630: Freezer"
  },
  {
    classId: "EC000631",
    classGroup: "EG000040",
    artClassDesc: "Curler set",
    artClassVersion: 6,
    label: "EC000631: Curler set"
  },
  {
    classId: "EC000632",
    classGroup: "EG000017",
    artClassDesc: "Control switch, Joystick",
    artClassVersion: 7,
    label: "EC000632: Control switch, Joystick"
  },
  {
    classId: "EC000633",
    classGroup: "EG000039",
    artClassDesc: "Dishwasher",
    artClassVersion: 9,
    label: "EC000633: Dishwasher"
  },
  {
    classId: "EC000634",
    classGroup: "EG000032",
    artClassDesc: "Bus coupler for bus system",
    artClassVersion: 8,
    label: "EC000634: Bus coupler for bus system"
  },
  {
    classId: "EC000635",
    classGroup: "EG000032",
    artClassDesc: "Connection-/branching terminal for bus system",
    artClassVersion: 8,
    label: "EC000635: Connection-/branching terminal for bus system"
  },
  {
    classId: "EC000636",
    classGroup: "EG000013",
    artClassDesc: "Electronic insert for domestic switching devices",
    artClassVersion: 9,
    label: "EC000636: Electronic insert for domestic switching devices"
  },
  {
    classId: "EC000637",
    classGroup: "EG000040",
    artClassDesc: "Ironing table",
    artClassVersion: 6,
    label: "EC000637: Ironing table"
  },
  {
    classId: "EC000638",
    classGroup: "EG000039",
    artClassDesc: "Washing machine",
    artClassVersion: 9,
    label: "EC000638: Washing machine"
  },
  {
    classId: "EC000639",
    classGroup: "EG000039",
    artClassDesc: "Tumble dryer",
    artClassVersion: 9,
    label: "EC000639: Tumble dryer"
  },
  {
    classId: "EC000640",
    classGroup: "EG000017",
    artClassDesc: "Soft starter",
    artClassVersion: 7,
    label: "EC000640: Soft starter"
  },
  {
    classId: "EC000641",
    classGroup: "EG000050",
    artClassDesc: "Measuring laser",
    artClassVersion: 7,
    label: "EC000641: Measuring laser"
  },
  {
    classId: "EC000642",
    classGroup: "EG000038",
    artClassDesc: "Answering machine",
    artClassVersion: 4,
    label: "EC000642: Answering machine"
  },
  {
    classId: "EC000643",
    classGroup: "EG000038",
    artClassDesc: "Fax",
    artClassVersion: 5,
    label: "EC000643: Fax"
  },
  {
    classId: "EC000644",
    classGroup: "EG000041",
    artClassDesc: "Portable CD player",
    artClassVersion: 5,
    label: "EC000644: Portable CD player"
  },
  {
    classId: "EC000645",
    classGroup: "EG000041",
    artClassDesc: "CD player/recorder",
    artClassVersion: 6,
    label: "EC000645: CD player/recorder"
  },
  {
    classId: "EC000647",
    classGroup: "EG000041",
    artClassDesc: "Radio",
    artClassVersion: 8,
    label: "EC000647: Radio"
  },
  {
    classId: "EC000648",
    classGroup: "EG000041",
    artClassDesc: "Radio recorder",
    artClassVersion: 8,
    label: "EC000648: Radio recorder"
  },
  {
    classId: "EC000651",
    classGroup: "EG000041",
    artClassDesc: "Car loudspeaker",
    artClassVersion: 4,
    label: "EC000651: Car loudspeaker"
  },
  {
    classId: "EC000652",
    classGroup: "EG000041",
    artClassDesc: "Loudspeaker box",
    artClassVersion: 9,
    label: "EC000652: Loudspeaker box"
  },
  {
    classId: "EC000653",
    classGroup: "EG000038",
    artClassDesc: "Dictaphone",
    artClassVersion: 6,
    label: "EC000653: Dictaphone"
  },
  {
    classId: "EC000654",
    classGroup: "EG000041",
    artClassDesc: "Camcorder",
    artClassVersion: 6,
    label: "EC000654: Camcorder"
  },
  {
    classId: "EC000655",
    classGroup: "EG000039",
    artClassDesc: "Cooker hood",
    artClassVersion: 8,
    label: "EC000655: Cooker hood"
  },
  {
    classId: "EC000656",
    classGroup: "EG000039",
    artClassDesc: "Spin-dryer",
    artClassVersion: 5,
    label: "EC000656: Spin-dryer"
  },
  {
    classId: "EC000658",
    classGroup: "EG000039",
    artClassDesc: "Accessories/spare parts for cooker hood",
    artClassVersion: 9,
    label: "EC000658: Accessories/spare parts for cooker hood"
  },
  {
    classId: "EC000661",
    classGroup: "EG000039",
    artClassDesc: "Microwave oven",
    artClassVersion: 8,
    label: "EC000661: Microwave oven"
  },
  {
    classId: "EC000662",
    classGroup: "EG000039",
    artClassDesc: "Cooker",
    artClassVersion: 8,
    label: "EC000662: Cooker"
  },
  {
    classId: "EC000663",
    classGroup: "EG000041",
    artClassDesc: "Headphone",
    artClassVersion: 7,
    label: "EC000663: Headphone"
  },
  {
    classId: "EC000664",
    classGroup: "EG000039",
    artClassDesc: "Hob",
    artClassVersion: 8,
    label: "EC000664: Hob"
  },
  {
    classId: "EC000665",
    classGroup: "EG000039",
    artClassDesc: "Exchange hotplate",
    artClassVersion: 5,
    label: "EC000665: Exchange hotplate"
  },
  {
    classId: "EC000666",
    classGroup: "EG000041",
    artClassDesc: "HiFi/TV furniture",
    artClassVersion: 6,
    label: "EC000666: HiFi/TV furniture"
  },
  {
    classId: "EC000667",
    classGroup: "EG000041",
    artClassDesc: "Audio mixer",
    artClassVersion: 7,
    label: "EC000667: Audio mixer"
  },
  {
    classId: "EC000668",
    classGroup: "EG000041",
    artClassDesc: "HiFi-Receiver",
    artClassVersion: 7,
    label: "EC000668: HiFi-Receiver"
  },
  {
    classId: "EC000669",
    classGroup: "EG000041",
    artClassDesc: "HiFi-Tuner",
    artClassVersion: 6,
    label: "EC000669: HiFi-Tuner"
  },
  {
    classId: "EC000671",
    classGroup: "EG000047",
    artClassDesc: "Current collector",
    artClassVersion: 5,
    label: "EC000671: Current collector"
  },
  {
    classId: "EC000672",
    classGroup: "EG000032",
    artClassDesc: "Touch sensor for bus system",
    artClassVersion: 9,
    label: "EC000672: Touch sensor for bus system"
  },
  {
    classId: "EC000673",
    classGroup: "EG000032",
    artClassDesc: "Area/line coupler for bus system",
    artClassVersion: 8,
    label: "EC000673: Area/line coupler for bus system"
  },
  {
    classId: "EC000674",
    classGroup: "EG000032",
    artClassDesc: "Interface for bus system",
    artClassVersion: 9,
    label: "EC000674: Interface for bus system"
  },
  {
    classId: "EC000675",
    classGroup: "EG000032",
    artClassDesc: "Power supply for bus system",
    artClassVersion: 9,
    label: "EC000675: Power supply for bus system"
  },
  {
    classId: "EC000676",
    classGroup: "EG000032",
    artClassDesc: "Logic component for bus system",
    artClassVersion: 8,
    label: "EC000676: Logic component for bus system"
  },
  {
    classId: "EC000677",
    classGroup: "EG000032",
    artClassDesc: "Display for bus system",
    artClassVersion: 8,
    label: "EC000677: Display for bus system"
  },
  {
    classId: "EC000678",
    classGroup: "EG000041",
    artClassDesc: "Remote control",
    artClassVersion: 7,
    label: "EC000678: Remote control"
  },
  {
    classId: "EC000679",
    classGroup: "EG000040",
    artClassDesc: "Popcorn machine",
    artClassVersion: 6,
    label: "EC000679: Popcorn machine"
  },
  {
    classId: "EC000680",
    classGroup: "EG000041",
    artClassDesc: "DVD player/recorder",
    artClassVersion: 8,
    label: "EC000680: DVD player/recorder"
  },
  {
    classId: "EC000681",
    classGroup: "EG000041",
    artClassDesc: "HiFi-set",
    artClassVersion: 7,
    label: "EC000681: HiFi-set"
  },
  {
    classId: "EC000682",
    classGroup: "EG000041",
    artClassDesc: "Loudspeaker set",
    artClassVersion: 6,
    label: "EC000682: Loudspeaker set"
  },
  {
    classId: "EC000683",
    classGroup: "EG000017",
    artClassDesc: "Surge protection module",
    artClassVersion: 5,
    label: "EC000683: Surge protection module"
  },
  {
    classId: "EC000684",
    classGroup: "EG000041",
    artClassDesc: "Record player",
    artClassVersion: 5,
    label: "EC000684: Record player"
  },
  {
    classId: "EC000688",
    classGroup: "EG000032",
    artClassDesc: "Binary input for bus system",
    artClassVersion: 9,
    label: "EC000688: Binary input for bus system"
  },
  {
    classId: "EC000689",
    classGroup: "EG000032",
    artClassDesc: "Time switch for bus system",
    artClassVersion: 9,
    label: "EC000689: Time switch for bus system"
  },
  {
    classId: "EC000690",
    classGroup: "EG000041",
    artClassDesc: "Video projector",
    artClassVersion: 7,
    label: "EC000690: Video projector"
  },
  {
    classId: "EC000691",
    classGroup: "EG000040",
    artClassDesc: "Insect repellent device",
    artClassVersion: 4,
    label: "EC000691: Insect repellent device"
  },
  {
    classId: "EC000692",
    classGroup: "EG000039",
    artClassDesc: "Heating element (baking oven)",
    artClassVersion: 5,
    label: "EC000692: Heating element (baking oven)"
  },
  {
    classId: "EC000693",
    classGroup: "EG000039",
    artClassDesc: "Mini kitchen",
    artClassVersion: 7,
    label: "EC000693: Mini kitchen"
  },
  {
    classId: "EC000694",
    classGroup: "EG000027",
    artClassDesc: "Hand floodlight",
    artClassVersion: 8,
    label: "EC000694: Hand floodlight"
  },
  {
    classId: "EC000696",
    classGroup: "EG000028",
    artClassDesc: "Vehicle lamp",
    artClassVersion: 8,
    label: "EC000696: Vehicle lamp"
  },
  {
    classId: "EC000698",
    classGroup: "EG000032",
    artClassDesc: "Repeater for bus system",
    artClassVersion: 8,
    label: "EC000698: Repeater for bus system"
  },
  {
    classId: "EC000699",
    classGroup: "EG000032",
    artClassDesc: "Central device for bus system",
    artClassVersion: 8,
    label: "EC000699: Central device for bus system"
  },
  {
    classId: "EC000700",
    classGroup: "EG000032",
    artClassDesc: "Band suppressor/choke for bus system",
    artClassVersion: 8,
    label: "EC000700: Band suppressor/choke for bus system"
  },
  {
    classId: "EC000701",
    classGroup: "EG000034",
    artClassDesc: "Window opener",
    artClassVersion: 5,
    label: "EC000701: Window opener"
  },
  {
    classId: "EC000703",
    classGroup: "EG000036",
    artClassDesc: "Communication column",
    artClassVersion: 5,
    label: "EC000703: Communication column"
  },
  {
    classId: "EC000704",
    classGroup: "EG020002",
    artClassDesc: "Core drill bit",
    artClassVersion: 8,
    label: "EC000704: Core drill bit"
  },
  {
    classId: "EC000705",
    classGroup: "EG000044",
    artClassDesc: "Measuring instrument set",
    artClassVersion: 6,
    label: "EC000705: Measuring instrument set"
  },
  {
    classId: "EC000706",
    classGroup: "EG000006",
    artClassDesc: "Console for wall duct",
    artClassVersion: 5,
    label: "EC000706: Console for wall duct"
  },
  {
    classId: "EC000707",
    classGroup: "EG000039",
    artClassDesc: "Cool/freezer box, portable",
    artClassVersion: 7,
    label: "EC000707: Cool/freezer box, portable"
  },
  {
    classId: "EC000709",
    classGroup: "EG000036",
    artClassDesc: "Socket for letterbox",
    artClassVersion: 5,
    label: "EC000709: Socket for letterbox"
  },
  {
    classId: "EC000712",
    classGroup: "EG000017",
    artClassDesc: "Empty enclosure for switchgear",
    artClassVersion: 8,
    label: "EC000712: Empty enclosure for switchgear"
  },
  {
    classId: "EC000713",
    classGroup: "EG000015",
    artClassDesc: "Busbar coupler",
    artClassVersion: 4,
    label: "EC000713: Busbar coupler"
  },
  {
    classId: "EC000717",
    classGroup: "EG000037",
    artClassDesc: "Plug for telecommunication terminal strip",
    artClassVersion: 5,
    label: "EC000717: Plug for telecommunication terminal strip"
  },
  {
    classId: "EC000718",
    classGroup: "EG000037",
    artClassDesc: "Wire-guide for LSA connection strip",
    artClassVersion: 4,
    label: "EC000718: Wire-guide for LSA connection strip"
  },
  {
    classId: "EC000719",
    classGroup: "EG000037",
    artClassDesc: "Labelling cap for connection strips",
    artClassVersion: 5,
    label: "EC000719: Labelling cap for connection strips"
  },
  {
    classId: "EC000720",
    classGroup: "EG000037",
    artClassDesc: "Labelling plate for telecommunication terminal strips",
    artClassVersion: 6,
    label: "EC000720: Labelling plate for telecommunication terminal strips"
  },
  {
    classId: "EC000721",
    classGroup: "EG000011",
    artClassDesc: "Base/base element (enclosure/cabinet)",
    artClassVersion: 7,
    label: "EC000721: Base/base element (enclosure/cabinet)"
  },
  {
    classId: "EC000722",
    classGroup: "EG000006",
    artClassDesc: "On-floor installation duct",
    artClassVersion: 8,
    label: "EC000722: On-floor installation duct"
  },
  {
    classId: "EC000723",
    classGroup: "EG000037",
    artClassDesc: "Dust shield for plug connections",
    artClassVersion: 7,
    label: "EC000723: Dust shield for plug connections"
  },
  {
    classId: "EC000725",
    classGroup: "EG000041",
    artClassDesc: "Cleaning cassette/CD",
    artClassVersion: 5,
    label: "EC000725: Cleaning cassette/CD"
  },
  {
    classId: "EC000726",
    classGroup: "EG000007",
    artClassDesc: "End plate for slotted cable trunking system",
    artClassVersion: 7,
    label: "EC000726: End plate for slotted cable trunking system"
  },
  {
    classId: "EC000727",
    classGroup: "EG000007",
    artClassDesc: "Cover for slotted cable trunking system",
    artClassVersion: 8,
    label: "EC000727: Cover for slotted cable trunking system"
  },
  {
    classId: "EC000728",
    classGroup: "EG000006",
    artClassDesc: "Cover for installation duct",
    artClassVersion: 7,
    label: "EC000728: Cover for installation duct"
  },
  {
    classId: "EC000730",
    classGroup: "EG000037",
    artClassDesc: "Discharge tube for overvoltage protection magazine",
    artClassVersion: 5,
    label: "EC000730: Discharge tube for overvoltage protection magazine"
  },
  {
    classId: "EC000731",
    classGroup: "EG000037",
    artClassDesc: "Network hub",
    artClassVersion: 5,
    label: "EC000731: Network hub"
  },
  {
    classId: "EC000732",
    classGroup: "EG000037",
    artClassDesc: "Network repeater",
    artClassVersion: 5,
    label: "EC000732: Network repeater"
  },
  {
    classId: "EC000734",
    classGroup: "EG000037",
    artClassDesc: "Network switch",
    artClassVersion: 9,
    label: "EC000734: Network switch"
  },
  {
    classId: "EC000735",
    classGroup: "EG000037",
    artClassDesc: "Print server",
    artClassVersion: 5,
    label: "EC000735: Print server"
  },
  {
    classId: "EC000736",
    classGroup: "EG000041",
    artClassDesc: "Analogue memory medium",
    artClassVersion: 5,
    label: "EC000736: Analogue memory medium"
  },
  {
    classId: "EC000737",
    classGroup: "EG000011",
    artClassDesc: "Heating (enclosure/cabinet)",
    artClassVersion: 7,
    label: "EC000737: Heating (enclosure/cabinet)"
  },
  {
    classId: "EC000740",
    classGroup: "EG000037",
    artClassDesc: "Modem",
    artClassVersion: 7,
    label: "EC000740: Modem"
  },
  {
    classId: "EC000743",
    classGroup: "EG000015",
    artClassDesc: "Insert for lock system",
    artClassVersion: 6,
    label: "EC000743: Insert for lock system"
  },
  {
    classId: "EC000744",
    classGroup: "EG000011",
    artClassDesc: "Top-/floor element (enclosure/cabinet)",
    artClassVersion: 8,
    label: "EC000744: Top-/floor element (enclosure/cabinet)"
  },
  {
    classId: "EC000747",
    classGroup: "EG000011",
    artClassDesc: "Door/operating panel (enclosure/cabinet)",
    artClassVersion: 7,
    label: "EC000747: Door/operating panel (enclosure/cabinet)"
  },
  {
    classId: "EC000748",
    classGroup: "EG000037",
    artClassDesc: "Pigtail",
    artClassVersion: 7,
    label: "EC000748: Pigtail"
  },
  {
    classId: "EC000749",
    classGroup: "EG000005",
    artClassDesc: "Bend for subfloor installation ducting systems",
    artClassVersion: 7,
    label: "EC000749: Bend for subfloor installation ducting systems"
  },
  {
    classId: "EC000750",
    classGroup: "EG000005",
    artClassDesc: "Coupler for subfloor installation duct",
    artClassVersion: 7,
    label: "EC000750: Coupler for subfloor installation duct"
  },
  {
    classId: "EC000751",
    classGroup: "EG000028",
    artClassDesc: "Induction lamp",
    artClassVersion: 8,
    label: "EC000751: Induction lamp"
  },
  {
    classId: "EC000752",
    classGroup: "EG000037",
    artClassDesc: "Fibre optic coupler",
    artClassVersion: 9,
    label: "EC000752: Fibre optic coupler"
  },
  {
    classId: "EC000753",
    classGroup: "EG000037",
    artClassDesc: "Fibre optic cable splitter",
    artClassVersion: 5,
    label: "EC000753: Fibre optic cable splitter"
  },
  {
    classId: "EC000755",
    classGroup: "EG000049",
    artClassDesc: "Anchor sleeve",
    artClassVersion: 8,
    label: "EC000755: Anchor sleeve"
  },
  {
    classId: "EC000757",
    classGroup: "EG000032",
    artClassDesc: "Software for bus system",
    artClassVersion: 8,
    label: "EC000757: Software for bus system"
  },
  {
    classId: "EC000758",
    classGroup: "EG000027",
    artClassDesc: "In-ground luminaire",
    artClassVersion: 9,
    label: "EC000758: In-ground luminaire"
  },
  {
    classId: "EC000759",
    classGroup: "EG000037",
    artClassDesc: "Splice holder",
    artClassVersion: 5,
    label: "EC000759: Splice holder"
  },
  {
    classId: "EC000760",
    classGroup: "EG000050",
    artClassDesc: "Hole cutter for plastics",
    artClassVersion: 5,
    label: "EC000760: Hole cutter for plastics"
  },
  {
    classId: "EC000761",
    classGroup: "EG000012",
    artClassDesc: "Labelling for terminal block",
    artClassVersion: 6,
    label: "EC000761: Labelling for terminal block"
  },
  {
    classId: "EC000762",
    classGroup: "EG000037",
    artClassDesc: "Splice cassette",
    artClassVersion: 5,
    label: "EC000762: Splice cassette"
  },
  {
    classId: "EC000763",
    classGroup: "EG000033",
    artClassDesc: "Line equalizer (receiver technology)",
    artClassVersion: 5,
    label: "EC000763: Line equalizer (receiver technology)"
  },
  {
    classId: "EC000766",
    classGroup: "EG000037",
    artClassDesc: "Coding for data communication technology",
    artClassVersion: 6,
    label: "EC000766: Coding for data communication technology"
  },
  {
    classId: "EC000768",
    classGroup: "EG000037",
    artClassDesc: "Multiplexer",
    artClassVersion: 4,
    label: "EC000768: Multiplexer"
  },
  {
    classId: "EC000769",
    classGroup: "EG000038",
    artClassDesc: "Module for telephone system",
    artClassVersion: 4,
    label: "EC000769: Module for telephone system"
  },
  {
    classId: "EC000770",
    classGroup: "EG000023",
    artClassDesc: "Separation plate for meter-/distribution boards",
    artClassVersion: 5,
    label: "EC000770: Separation plate for meter-/distribution boards"
  },
  {
    classId: "EC000771",
    classGroup: "EG000023",
    artClassDesc: "Cover strip (modular) for meter-/distribution board",
    artClassVersion: 7,
    label: "EC000771: Cover strip (modular) for meter-/distribution board"
  },
  {
    classId: "EC000772",
    classGroup: "EG000040",
    artClassDesc: "Master clock",
    artClassVersion: 8,
    label: "EC000772: Master clock"
  },
  {
    classId: "EC000773",
    classGroup: "EG000027",
    artClassDesc: "Explosion proof hand floodlight",
    artClassVersion: 9,
    label: "EC000773: Explosion proof hand floodlight"
  },
  {
    classId: "EC000774",
    classGroup: "EG000020",
    artClassDesc: "Miniature fuse assortment",
    artClassVersion: 6,
    label: "EC000774: Miniature fuse assortment"
  },
  {
    classId: "EC000775",
    classGroup: "EG000023",
    artClassDesc: "Cover for distribution board",
    artClassVersion: 7,
    label: "EC000775: Cover for distribution board"
  },
  {
    classId: "EC000776",
    classGroup: "EG000040",
    artClassDesc: "Pan",
    artClassVersion: 5,
    label: "EC000776: Pan"
  },
  {
    classId: "EC000777",
    classGroup: "EG000040",
    artClassDesc: "Baby bottle",
    artClassVersion: 4,
    label: "EC000777: Baby bottle"
  },
  {
    classId: "EC000778",
    classGroup: "EG000038",
    artClassDesc: "Telephone digital/ISDN with cord",
    artClassVersion: 7,
    label: "EC000778: Telephone digital/ISDN with cord"
  },
  {
    classId: "EC000779",
    classGroup: "EG000036",
    artClassDesc: "Indoor station door communication",
    artClassVersion: 9,
    label: "EC000779: Indoor station door communication"
  },
  {
    classId: "EC000781",
    classGroup: "EG000003",
    artClassDesc: "Sealing for hose screw connection",
    artClassVersion: 6,
    label: "EC000781: Sealing for hose screw connection"
  },
  {
    classId: "EC000782",
    classGroup: "EG000032",
    artClassDesc: "Access control unit for bus system",
    artClassVersion: 9,
    label: "EC000782: Access control unit for bus system"
  },
  {
    classId: "EC000783",
    classGroup: "EG000032",
    artClassDesc: "Signalling and operation panel for bus system",
    artClassVersion: 8,
    label: "EC000783: Signalling and operation panel for bus system"
  },
  {
    classId: "EC000784",
    classGroup: "EG000011",
    artClassDesc: "Desk system (enclosure/cabinet)",
    artClassVersion: 7,
    label: "EC000784: Desk system (enclosure/cabinet)"
  },
  {
    classId: "EC000785",
    classGroup: "EG000006",
    artClassDesc: "Connector for wall duct",
    artClassVersion: 7,
    label: "EC000785: Connector for wall duct"
  },
  {
    classId: "EC000786",
    classGroup: "EG000006",
    artClassDesc: "Cover for plinth skirting duct",
    artClassVersion: 8,
    label: "EC000786: Cover for plinth skirting duct"
  },
  {
    classId: "EC000787",
    classGroup: "EG000038",
    artClassDesc: "Handset for cordless telephone",
    artClassVersion: 7,
    label: "EC000787: Handset for cordless telephone"
  },
  {
    classId: "EC000788",
    classGroup: "EG000038",
    artClassDesc: "Charger for cordless telephone",
    artClassVersion: 5,
    label: "EC000788: Charger for cordless telephone"
  },
  {
    classId: "EC000789",
    classGroup: "EG000038",
    artClassDesc: "Headset",
    artClassVersion: 6,
    label: "EC000789: Headset"
  },
  {
    classId: "EC000792",
    classGroup: "EG000032",
    artClassDesc: "Actuating drive for bus system",
    artClassVersion: 8,
    label: "EC000792: Actuating drive for bus system"
  },
  {
    classId: "EC000794",
    classGroup: "EG000032",
    artClassDesc: "Analogue input for bus system",
    artClassVersion: 8,
    label: "EC000794: Analogue input for bus system"
  },
  {
    classId: "EC000795",
    classGroup: "EG000008",
    artClassDesc: "Extension module for installation column",
    artClassVersion: 7,
    label: "EC000795: Extension module for installation column"
  },
  {
    classId: "EC000796",
    classGroup: "EG000058",
    artClassDesc: "Contact for industrial connectors",
    artClassVersion: 8,
    label: "EC000796: Contact for industrial connectors"
  },
  {
    classId: "EC000797",
    classGroup: "EG000037",
    artClassDesc: "USB-Hub",
    artClassVersion: 4,
    label: "EC000797: USB-Hub"
  },
  {
    classId: "EC000798",
    classGroup: "EG000044",
    artClassDesc: "Test pin",
    artClassVersion: 5,
    label: "EC000798: Test pin"
  },
  {
    classId: "EC000799",
    classGroup: "EG000003",
    artClassDesc: "Screw gland holder",
    artClassVersion: 5,
    label: "EC000799: Screw gland holder"
  },
  {
    classId: "EC000800",
    classGroup: "EG000006",
    artClassDesc: "Cover strip for wall duct",
    artClassVersion: 7,
    label: "EC000800: Cover strip for wall duct"
  },
  {
    classId: "EC000801",
    classGroup: "EG000050",
    artClassDesc: "Torx screwdriver",
    artClassVersion: 7,
    label: "EC000801: Torx screwdriver"
  },
  {
    classId: "EC000804",
    classGroup: "EG000033",
    artClassDesc: "Audio/video modulator",
    artClassVersion: 4,
    label: "EC000804: Audio/video modulator"
  },
  {
    classId: "EC000806",
    classGroup: "EG000040",
    artClassDesc: "Rasp (battery/electric)",
    artClassVersion: 6,
    label: "EC000806: Rasp (battery/electric)"
  },
  {
    classId: "EC000807",
    classGroup: "EG000041",
    artClassDesc: "Navigation system",
    artClassVersion: 5,
    label: "EC000807: Navigation system"
  },
  {
    classId: "EC000808",
    classGroup: "EG000041",
    artClassDesc: "Portable MP3 player",
    artClassVersion: 6,
    label: "EC000808: Portable MP3 player"
  },
  {
    classId: "EC000809",
    classGroup: "EG000024",
    artClassDesc: "Programming- and engineering software",
    artClassVersion: 8,
    label: "EC000809: Programming- and engineering software"
  },
  {
    classId: "EC000810",
    classGroup: "EG000024",
    artClassDesc: "PLC mounting rack",
    artClassVersion: 7,
    label: "EC000810: PLC mounting rack"
  },
  {
    classId: "EC000811",
    classGroup: "EG000043",
    artClassDesc: "Electrical chimney fire",
    artClassVersion: 7,
    label: "EC000811: Electrical chimney fire"
  },
  {
    classId: "EC000812",
    classGroup: "EG000043",
    artClassDesc: "Ceiling heating foil",
    artClassVersion: 5,
    label: "EC000812: Ceiling heating foil"
  },
  {
    classId: "EC000813",
    classGroup: "EG000043",
    artClassDesc: "Natural stone heating",
    artClassVersion: 5,
    label: "EC000813: Natural stone heating"
  },
  {
    classId: "EC000814",
    classGroup: "EG000009",
    artClassDesc: "Threaded bush",
    artClassVersion: 8,
    label: "EC000814: Threaded bush"
  },
  {
    classId: "EC000815",
    classGroup: "EG000033",
    artClassDesc: "Car antenna",
    artClassVersion: 4,
    label: "EC000815: Car antenna"
  },
  {
    classId: "EC000816",
    classGroup: "EG000037",
    artClassDesc: "WLAN access point",
    artClassVersion: 8,
    label: "EC000816: WLAN access point"
  },
  {
    classId: "EC000817",
    classGroup: "EG000037",
    artClassDesc: "WLAN network adapter",
    artClassVersion: 6,
    label: "EC000817: WLAN network adapter"
  },
  {
    classId: "EC000818",
    classGroup: "EG000050",
    artClassDesc: "Hexagonal key",
    artClassVersion: 7,
    label: "EC000818: Hexagonal key"
  },
  {
    classId: "EC000819",
    classGroup: "EG000028",
    artClassDesc: "Compact fluorescent lamp with integrated ballast",
    artClassVersion: 8,
    label: "EC000819: Compact fluorescent lamp with integrated ballast"
  },
  {
    classId: "EC000820",
    classGroup: "EG000028",
    artClassDesc: "Traffic signalling lamp",
    artClassVersion: 8,
    label: "EC000820: Traffic signalling lamp"
  },
  {
    classId: "EC000821",
    classGroup: "EG000028",
    artClassDesc: "High pressure sodium-vapour lamp",
    artClassVersion: 9,
    label: "EC000821: High pressure sodium-vapour lamp"
  },
  {
    classId: "EC000822",
    classGroup: "EG000028",
    artClassDesc: "Low pressure sodium-vapour lamp",
    artClassVersion: 8,
    label: "EC000822: Low pressure sodium-vapour lamp"
  },
  {
    classId: "EC000823",
    classGroup: "EG000028",
    artClassDesc: "Halogen metal halide reflector lamp",
    artClassVersion: 9,
    label: "EC000823: Halogen metal halide reflector lamp"
  },
  {
    classId: "EC000833",
    classGroup: "EG000050",
    artClassDesc: "Flat nose pliers",
    artClassVersion: 7,
    label: "EC000833: Flat nose pliers"
  },
  {
    classId: "EC000834",
    classGroup: "EG000050",
    artClassDesc: "Round nose pliers",
    artClassVersion: 7,
    label: "EC000834: Round nose pliers"
  },
  {
    classId: "EC000835",
    classGroup: "EG000050",
    artClassDesc: "Telephone pliers",
    artClassVersion: 8,
    label: "EC000835: Telephone pliers"
  },
  {
    classId: "EC000836",
    classGroup: "EG000050",
    artClassDesc: "Combination pliers",
    artClassVersion: 8,
    label: "EC000836: Combination pliers"
  },
  {
    classId: "EC000837",
    classGroup: "EG000001",
    artClassDesc: "Power cable >= 1 kV, for moving application",
    artClassVersion: 9,
    label: "EC000837: Power cable >= 1 kV, for moving application"
  },
  {
    classId: "EC000838",
    classGroup: "EG000001",
    artClassDesc: "Thermocouple cable",
    artClassVersion: 7,
    label: "EC000838: Thermocouple cable"
  },
  {
    classId: "EC000839",
    classGroup: "EG000021",
    artClassDesc: "Braid wire",
    artClassVersion: 8,
    label: "EC000839: Braid wire"
  },
  {
    classId: "EC000842",
    classGroup: "EG000050",
    artClassDesc: "Pipe wrench",
    artClassVersion: 8,
    label: "EC000842: Pipe wrench"
  },
  {
    classId: "EC000843",
    classGroup: "EG000035",
    artClassDesc: "Alarm signal",
    artClassVersion: 6,
    label: "EC000843: Alarm signal"
  },
  {
    classId: "EC000844",
    classGroup: "EG000035",
    artClassDesc: "Buzzer",
    artClassVersion: 6,
    label: "EC000844: Buzzer"
  },
  {
    classId: "EC000849",
    classGroup: "EG000006",
    artClassDesc: "Outer corner for skirting duct",
    artClassVersion: 7,
    label: "EC000849: Outer corner for skirting duct"
  },
  {
    classId: "EC000850",
    classGroup: "EG000006",
    artClassDesc: "End piece for skirting duct",
    artClassVersion: 7,
    label: "EC000850: End piece for skirting duct"
  },
  {
    classId: "EC000851",
    classGroup: "EG000006",
    artClassDesc: "Inner corner for skirting duct",
    artClassVersion: 7,
    label: "EC000851: Inner corner for skirting duct"
  },
  {
    classId: "EC000852",
    classGroup: "EG000006",
    artClassDesc: "Flat bend for skirting duct",
    artClassVersion: 8,
    label: "EC000852: Flat bend for skirting duct"
  },
  {
    classId: "EC000853",
    classGroup: "EG000004",
    artClassDesc: "Mesh cable tray",
    artClassVersion: 7,
    label: "EC000853: Mesh cable tray"
  },
  {
    classId: "EC000854",
    classGroup: "EG000004",
    artClassDesc: "Cable ladder",
    artClassVersion: 8,
    label: "EC000854: Cable ladder"
  },
  {
    classId: "EC000855",
    classGroup: "EG000011",
    artClassDesc: "Air conditioner (enclosure/cabinet)",
    artClassVersion: 7,
    label: "EC000855: Air conditioner (enclosure/cabinet)"
  },
  {
    classId: "EC000856",
    classGroup: "EG000004",
    artClassDesc: "Corner add-on piece for cable ladder",
    artClassVersion: 7,
    label: "EC000856: Corner add-on piece for cable ladder"
  },
  {
    classId: "EC000857",
    classGroup: "EG000004",
    artClassDesc: "Corner plate for cable ladder",
    artClassVersion: 7,
    label: "EC000857: Corner plate for cable ladder"
  },
  {
    classId: "EC000858",
    classGroup: "EG000004",
    artClassDesc: "Drop-out plate for cable ladder",
    artClassVersion: 7,
    label: "EC000858: Drop-out plate for cable ladder"
  },
  {
    classId: "EC000861",
    classGroup: "EG000004",
    artClassDesc: "Corner add-on piece for cable tray",
    artClassVersion: 7,
    label: "EC000861: Corner add-on piece for cable tray"
  },
  {
    classId: "EC000862",
    classGroup: "EG000006",
    artClassDesc: "Inner corner for installation duct",
    artClassVersion: 7,
    label: "EC000862: Inner corner for installation duct"
  },
  {
    classId: "EC000863",
    classGroup: "EG000006",
    artClassDesc: "Outer corner for installation duct",
    artClassVersion: 7,
    label: "EC000863: Outer corner for installation duct"
  },
  {
    classId: "EC000864",
    classGroup: "EG000006",
    artClassDesc: "Flat bend for installation duct",
    artClassVersion: 8,
    label: "EC000864: Flat bend for installation duct"
  },
  {
    classId: "EC000865",
    classGroup: "EG000006",
    artClassDesc: "Tee for installation duct",
    artClassVersion: 6,
    label: "EC000865: Tee for installation duct"
  },
  {
    classId: "EC000866",
    classGroup: "EG000006",
    artClassDesc: "End cap for installation duct",
    artClassVersion: 7,
    label: "EC000866: End cap for installation duct"
  },
  {
    classId: "EC000867",
    classGroup: "EG000006",
    artClassDesc: "Inner corner for wall duct",
    artClassVersion: 6,
    label: "EC000867: Inner corner for wall duct"
  },
  {
    classId: "EC000872",
    classGroup: "EG000006",
    artClassDesc: "Outer corner for wall duct",
    artClassVersion: 6,
    label: "EC000872: Outer corner for wall duct"
  },
  {
    classId: "EC000873",
    classGroup: "EG000006",
    artClassDesc: "End piece for wall duct",
    artClassVersion: 8,
    label: "EC000873: End piece for wall duct"
  },
  {
    classId: "EC000874",
    classGroup: "EG000006",
    artClassDesc: "Joint clip for wall duct",
    artClassVersion: 7,
    label: "EC000874: Joint clip for wall duct"
  },
  {
    classId: "EC000876",
    classGroup: "EG000006",
    artClassDesc: "Flat bend for wall duct",
    artClassVersion: 9,
    label: "EC000876: Flat bend for wall duct"
  },
  {
    classId: "EC000877",
    classGroup: "EG000006",
    artClassDesc: "Cover for flat bend wall duct",
    artClassVersion: 7,
    label: "EC000877: Cover for flat bend wall duct"
  },
  {
    classId: "EC000878",
    classGroup: "EG000006",
    artClassDesc: "Cross piece for wall duct",
    artClassVersion: 6,
    label: "EC000878: Cross piece for wall duct"
  },
  {
    classId: "EC000879",
    classGroup: "EG000009",
    artClassDesc: "Cable entry sleeve",
    artClassVersion: 9,
    label: "EC000879: Cable entry sleeve"
  },
  {
    classId: "EC000880",
    classGroup: "EG000050",
    artClassDesc: "Seal",
    artClassVersion: 6,
    label: "EC000880: Seal"
  },
  {
    classId: "EC000881",
    classGroup: "EG000050",
    artClassDesc: "Seal wire",
    artClassVersion: 4,
    label: "EC000881: Seal wire"
  },
  {
    classId: "EC000882",
    classGroup: "EG000004",
    artClassDesc: "Profile support arm",
    artClassVersion: 6,
    label: "EC000882: Profile support arm"
  },
  {
    classId: "EC000884",
    classGroup: "EG000004",
    artClassDesc: "Connection for support arm",
    artClassVersion: 5,
    label: "EC000884: Connection for support arm"
  },
  {
    classId: "EC000886",
    classGroup: "EG000012",
    artClassDesc: "Endplate and partition plate for terminal block",
    artClassVersion: 4,
    label: "EC000886: Endplate and partition plate for terminal block"
  },
  {
    classId: "EC000889",
    classGroup: "EG000049",
    artClassDesc: "Tapping screw",
    artClassVersion: 7,
    label: "EC000889: Tapping screw"
  },
  {
    classId: "EC000891",
    classGroup: "EG000049",
    artClassDesc: "Thread cutting screw",
    artClassVersion: 8,
    label: "EC000891: Thread cutting screw"
  },
  {
    classId: "EC000893",
    classGroup: "EG000005",
    artClassDesc: "Service box for subfloor installation",
    artClassVersion: 5,
    label: "EC000893: Service box for subfloor installation"
  },
  {
    classId: "EC000894",
    classGroup: "EG000005",
    artClassDesc: "Cover for subfloor installation duct flush floor open",
    artClassVersion: 6,
    label: "EC000894: Cover for subfloor installation duct flush floor open"
  },
  {
    classId: "EC000895",
    classGroup: "EG000005",
    artClassDesc: "Equipped surface tank",
    artClassVersion: 7,
    label: "EC000895: Equipped surface tank"
  },
  {
    classId: "EC000896",
    classGroup: "EG000004",
    artClassDesc: "Ceiling bracket for cable support system",
    artClassVersion: 7,
    label: "EC000896: Ceiling bracket for cable support system"
  },
  {
    classId: "EC000897",
    classGroup: "EG000012",
    artClassDesc: "Feed-through terminal block",
    artClassVersion: 9,
    label: "EC000897: Feed-through terminal block"
  },
  {
    classId: "EC000898",
    classGroup: "EG000012",
    artClassDesc: "Indicator terminal block",
    artClassVersion: 6,
    label: "EC000898: Indicator terminal block"
  },
  {
    classId: "EC000899",
    classGroup: "EG000012",
    artClassDesc: "Fuse terminal block",
    artClassVersion: 8,
    label: "EC000899: Fuse terminal block"
  },
  {
    classId: "EC000900",
    classGroup: "EG000012",
    artClassDesc: "Sensor/actuator terminal block",
    artClassVersion: 7,
    label: "EC000900: Sensor/actuator terminal block"
  },
  {
    classId: "EC000901",
    classGroup: "EG000012",
    artClassDesc: "Ground terminal block",
    artClassVersion: 7,
    label: "EC000901: Ground terminal block"
  },
  {
    classId: "EC000902",
    classGroup: "EG000012",
    artClassDesc: "(Knife) disconnect terminal block",
    artClassVersion: 7,
    label: "EC000902: (Knife) disconnect terminal block"
  },
  {
    classId: "EC000903",
    classGroup: "EG000012",
    artClassDesc: "Component terminal block",
    artClassVersion: 7,
    label: "EC000903: Component terminal block"
  },
  {
    classId: "EC000904",
    classGroup: "EG000012",
    artClassDesc: "Thermal couple terminal block",
    artClassVersion: 6,
    label: "EC000904: Thermal couple terminal block"
  },
  {
    classId: "EC000905",
    classGroup: "EG000020",
    artClassDesc: "Earth leakage circuit breaker",
    artClassVersion: 9,
    label: "EC000905: Earth leakage circuit breaker"
  },
  {
    classId: "EC000906",
    classGroup: "EG000004",
    artClassDesc: "90° connection for cable ladder to cable ladder",
    artClassVersion: 7,
    label: "EC000906: 90° connection for cable ladder to cable ladder"
  },
  {
    classId: "EC000907",
    classGroup: "EG000004",
    artClassDesc: "Side profile for vertical cable ladder",
    artClassVersion: 7,
    label: "EC000907: Side profile for vertical cable ladder"
  },
  {
    classId: "EC000908",
    classGroup: "EG000004",
    artClassDesc: "Bottom end plate for cable tray",
    artClassVersion: 7,
    label: "EC000908: Bottom end plate for cable tray"
  },
  {
    classId: "EC000909",
    classGroup: "EG000006",
    artClassDesc: "Acoustic baffle for wall duct",
    artClassVersion: 6,
    label: "EC000909: Acoustic baffle for wall duct"
  },
  {
    classId: "EC000910",
    classGroup: "EG000047",
    artClassDesc: "End terminal",
    artClassVersion: 6,
    label: "EC000910: End terminal"
  },
  {
    classId: "EC000911",
    classGroup: "EG000017",
    artClassDesc: "Voltmeter selector switch",
    artClassVersion: 6,
    label: "EC000911: Voltmeter selector switch"
  },
  {
    classId: "EC000912",
    classGroup: "EG000017",
    artClassDesc: "Amp meter switch",
    artClassVersion: 6,
    label: "EC000912: Amp meter switch"
  },
  {
    classId: "EC000916",
    classGroup: "EG000017",
    artClassDesc: "Switch operating shaft",
    artClassVersion: 5,
    label: "EC000916: Switch operating shaft"
  },
  {
    classId: "EC000926",
    classGroup: "EG000032",
    artClassDesc: "Physical sensor for bus system",
    artClassVersion: 9,
    label: "EC000926: Physical sensor for bus system"
  },
  {
    classId: "EC000927",
    classGroup: "EG000032",
    artClassDesc: "Brightness sensor for bus system",
    artClassVersion: 8,
    label: "EC000927: Brightness sensor for bus system"
  },
  {
    classId: "EC000928",
    classGroup: "EG000013",
    artClassDesc: "Infrared receiver",
    artClassVersion: 7,
    label: "EC000928: Infrared receiver"
  },
  {
    classId: "EC000936",
    classGroup: "EG000002",
    artClassDesc: "Bend for installation tubes",
    artClassVersion: 9,
    label: "EC000936: Bend for installation tubes"
  },
  {
    classId: "EC000937",
    classGroup: "EG000002",
    artClassDesc: "Terminal sleeve for installation tubes",
    artClassVersion: 9,
    label: "EC000937: Terminal sleeve for installation tubes"
  },
  {
    classId: "EC000938",
    classGroup: "EG000009",
    artClassDesc: "Enlargement/reducing ring",
    artClassVersion: 9,
    label: "EC000938: Enlargement/reducing ring"
  },
  {
    classId: "EC000939",
    classGroup: "EG000002",
    artClassDesc: "Coupler for installation tubes",
    artClassVersion: 9,
    label: "EC000939: Coupler for installation tubes"
  },
  {
    classId: "EC000940",
    classGroup: "EG000009",
    artClassDesc: "Locknut for cable screw gland",
    artClassVersion: 8,
    label: "EC000940: Locknut for cable screw gland"
  },
  {
    classId: "EC000941",
    classGroup: "EG000021",
    artClassDesc: "Surge protection device for power supply systems",
    artClassVersion: 9,
    label: "EC000941: Surge protection device for power supply systems"
  },
  {
    classId: "EC000942",
    classGroup: "EG000021",
    artClassDesc: "Surge protection device for terminal equipment",
    artClassVersion: 9,
    label: "EC000942: Surge protection device for terminal equipment"
  },
  {
    classId: "EC000943",
    classGroup: "EG000021",
    artClassDesc: "Surge protection device for data networks/MCR-technology",
    artClassVersion: 9,
    label: "EC000943: Surge protection device for data networks/MCR-technology"
  },
  {
    classId: "EC000945",
    classGroup: "EG020002",
    artClassDesc: "Drill",
    artClassVersion: 8,
    label: "EC000945: Drill"
  },
  {
    classId: "EC000981",
    classGroup: "EG000027",
    artClassDesc: "Explosion proof emergency and security luminaire",
    artClassVersion: 9,
    label: "EC000981: Explosion proof emergency and security luminaire"
  },
  {
    classId: "EC000986",
    classGroup: "EG000027",
    artClassDesc: "Electrical unit for light-line system",
    artClassVersion: 9,
    label: "EC000986: Electrical unit for light-line system"
  },
  {
    classId: "EC000987",
    classGroup: "EG000027",
    artClassDesc: "Flashlight",
    artClassVersion: 9,
    label: "EC000987: Flashlight"
  },
  {
    classId: "EC000988",
    classGroup: "EG000027",
    artClassDesc: "Explosion proof pocket torch",
    artClassVersion: 9,
    label: "EC000988: Explosion proof pocket torch"
  },
  {
    classId: "EC000989",
    classGroup: "EG000027",
    artClassDesc: "Explosion proof hand luminaire",
    artClassVersion: 9,
    label: "EC000989: Explosion proof hand luminaire"
  },
  {
    classId: "EC000993",
    classGroup: "EG000001",
    artClassDesc: "Single core single insulated wire",
    artClassVersion: 8,
    label: "EC000993: Single core single insulated wire"
  },
  {
    classId: "EC000996",
    classGroup: "EG000028",
    artClassDesc: "LED-module",
    artClassVersion: 9,
    label: "EC000996: LED-module"
  },
  {
    classId: "EC001000",
    classGroup: "EG000004",
    artClassDesc: "Separation plate for cable support system",
    artClassVersion: 7,
    label: "EC001000: Separation plate for cable support system"
  },
  {
    classId: "EC001001",
    classGroup: "EG000004",
    artClassDesc: "Mounting clamp for separation plate cable support system",
    artClassVersion: 8,
    label: "EC001001: Mounting clamp for separation plate cable support system"
  },
  {
    classId: "EC001002",
    classGroup: "EG000004",
    artClassDesc: "Clamp for cover cable support system",
    artClassVersion: 7,
    label: "EC001002: Clamp for cover cable support system"
  },
  {
    classId: "EC001003",
    classGroup: "EG000004",
    artClassDesc: "Wall- and ceiling bracket for cable support system",
    artClassVersion: 7,
    label: "EC001003: Wall- and ceiling bracket for cable support system"
  },
  {
    classId: "EC001004",
    classGroup: "EG000004",
    artClassDesc: "Mounting plate for cable support system",
    artClassVersion: 7,
    label: "EC001004: Mounting plate for cable support system"
  },
  {
    classId: "EC001005",
    classGroup: "EG000004",
    artClassDesc: "Mounting material for cable support system",
    artClassVersion: 8,
    label: "EC001005: Mounting material for cable support system"
  },
  {
    classId: "EC001006",
    classGroup: "EG000004",
    artClassDesc: "Add-on tee for cable tray",
    artClassVersion: 7,
    label: "EC001006: Add-on tee for cable tray"
  },
  {
    classId: "EC001007",
    classGroup: "EG000004",
    artClassDesc: "Reducing piece for cable tray",
    artClassVersion: 7,
    label: "EC001007: Reducing piece for cable tray"
  },
  {
    classId: "EC001009",
    classGroup: "EG000006",
    artClassDesc: "Separation plate for wall duct",
    artClassVersion: 7,
    label: "EC001009: Separation plate for wall duct"
  },
  {
    classId: "EC001010",
    classGroup: "EG000004",
    artClassDesc: "Add-on tee for cable ladder",
    artClassVersion: 7,
    label: "EC001010: Add-on tee for cable ladder"
  },
  {
    classId: "EC001011",
    classGroup: "EG000004",
    artClassDesc: "Reducing piece for cable ladder",
    artClassVersion: 7,
    label: "EC001011: Reducing piece for cable ladder"
  },
  {
    classId: "EC001012",
    classGroup: "EG000006",
    artClassDesc: "Cover for corner for wall duct",
    artClassVersion: 6,
    label: "EC001012: Cover for corner for wall duct"
  },
  {
    classId: "EC001013",
    classGroup: "EG000005",
    artClassDesc: "Separation plate for installation duct flush floor",
    artClassVersion: 4,
    label: "EC001013: Separation plate for installation duct flush floor"
  },
  {
    classId: "EC001014",
    classGroup: "EG000005",
    artClassDesc: "End piece for subfloor installation duct",
    artClassVersion: 5,
    label: "EC001014: End piece for subfloor installation duct"
  },
  {
    classId: "EC001015",
    classGroup: "EG000006",
    artClassDesc: "Separation plate for installation duct",
    artClassVersion: 6,
    label: "EC001015: Separation plate for installation duct"
  },
  {
    classId: "EC001016",
    classGroup: "EG000006",
    artClassDesc: "Coupler for installation duct",
    artClassVersion: 6,
    label: "EC001016: Coupler for installation duct"
  },
  {
    classId: "EC001017",
    classGroup: "EG000006",
    artClassDesc: "Cross piece for installation duct",
    artClassVersion: 6,
    label: "EC001017: Cross piece for installation duct"
  },
  {
    classId: "EC001018",
    classGroup: "EG000007",
    artClassDesc: "Wire clamp for slotted cable trunking system",
    artClassVersion: 7,
    label: "EC001018: Wire clamp for slotted cable trunking system"
  },
  {
    classId: "EC001019",
    classGroup: "EG000028",
    artClassDesc: "Single LED",
    artClassVersion: 8,
    label: "EC001019: Single LED"
  },
  {
    classId: "EC001020",
    classGroup: "EG000017",
    artClassDesc: "Adapter for command devices",
    artClassVersion: 6,
    label: "EC001020: Adapter for command devices"
  },
  {
    classId: "EC001021",
    classGroup: "EG000017",
    artClassDesc: "Residual current release for power circuit breaker",
    artClassVersion: 4,
    label: "EC001021: Residual current release for power circuit breaker"
  },
  {
    classId: "EC001022",
    classGroup: "EG000017",
    artClassDesc: "Under voltage coil",
    artClassVersion: 4,
    label: "EC001022: Under voltage coil"
  },
  {
    classId: "EC001023",
    classGroup: "EG000017",
    artClassDesc: "Shunt release (for power circuit breaker)",
    artClassVersion: 6,
    label: "EC001023: Shunt release (for power circuit breaker)"
  },
  {
    classId: "EC001024",
    classGroup: "EG000017",
    artClassDesc: "DIN-rail adapter",
    artClassVersion: 7,
    label: "EC001024: DIN-rail adapter"
  },
  {
    classId: "EC001025",
    classGroup: "EG000017",
    artClassDesc: "Trip indicator",
    artClassVersion: 5,
    label: "EC001025: Trip indicator"
  },
  {
    classId: "EC001026",
    classGroup: "EG000017",
    artClassDesc: "Acoustic indicator",
    artClassVersion: 6,
    label: "EC001026: Acoustic indicator"
  },
  {
    classId: "EC001027",
    classGroup: "EG000017",
    artClassDesc: "Potentiometer for command devices",
    artClassVersion: 7,
    label: "EC001027: Potentiometer for command devices"
  },
  {
    classId: "EC001028",
    classGroup: "EG000017",
    artClassDesc: "Push button, complete",
    artClassVersion: 7,
    label: "EC001028: Push button, complete"
  },
  {
    classId: "EC001029",
    classGroup: "EG000017",
    artClassDesc: "Selector switch, complete",
    artClassVersion: 8,
    label: "EC001029: Selector switch, complete"
  },
  {
    classId: "EC001030",
    classGroup: "EG000017",
    artClassDesc: "Motor operator for power circuit-breaker",
    artClassVersion: 5,
    label: "EC001030: Motor operator for power circuit-breaker"
  },
  {
    classId: "EC001031",
    classGroup: "EG000011",
    artClassDesc: "Electronic enclosure",
    artClassVersion: 7,
    label: "EC001031: Electronic enclosure"
  },
  {
    classId: "EC001032",
    classGroup: "EG000017",
    artClassDesc: "Text plate holder for command devices",
    artClassVersion: 6,
    label: "EC001032: Text plate holder for command devices"
  },
  {
    classId: "EC001034",
    classGroup: "EG000032",
    artClassDesc: "Phase coupler for bus system",
    artClassVersion: 8,
    label: "EC001034: Phase coupler for bus system"
  },
  {
    classId: "EC001035",
    classGroup: "EG000013",
    artClassDesc: "Movement sensor basic element",
    artClassVersion: 8,
    label: "EC001035: Movement sensor basic element"
  },
  {
    classId: "EC001036",
    classGroup: "EG000013",
    artClassDesc: "Three-stage switch",
    artClassVersion: 8,
    label: "EC001036: Three-stage switch"
  },
  {
    classId: "EC001037",
    classGroup: "EG000017",
    artClassDesc: "Motor starter/motor starter combination",
    artClassVersion: 9,
    label: "EC001037: Motor starter/motor starter combination"
  },
  {
    classId: "EC001038",
    classGroup: "EG000017",
    artClassDesc: "Front element for mushroom push-button",
    artClassVersion: 7,
    label: "EC001038: Front element for mushroom push-button"
  },
  {
    classId: "EC001040",
    classGroup: "EG000017",
    artClassDesc: "Fuse switch disconnector",
    artClassVersion: 7,
    label: "EC001040: Fuse switch disconnector"
  },
  {
    classId: "EC001041",
    classGroup: "EG000012",
    artClassDesc: "End bracket for terminal block",
    artClassVersion: 6,
    label: "EC001041: End bracket for terminal block"
  },
  {
    classId: "EC001042",
    classGroup: "EG000017",
    artClassDesc: "Signal tower complete",
    artClassVersion: 7,
    label: "EC001042: Signal tower complete"
  },
  {
    classId: "EC001043",
    classGroup: "EG000017",
    artClassDesc: "Coil for relay",
    artClassVersion: 4,
    label: "EC001043: Coil for relay"
  },
  {
    classId: "EC001044",
    classGroup: "EG000017",
    artClassDesc: "Mechanic interlock for switch",
    artClassVersion: 5,
    label: "EC001044: Mechanic interlock for switch"
  },
  {
    classId: "EC001045",
    classGroup: "EG000017",
    artClassDesc: "Documentation",
    artClassVersion: 6,
    label: "EC001045: Documentation"
  },
  {
    classId: "EC001046",
    classGroup: "EG000017",
    artClassDesc: "In-line fuse switch disconnector base",
    artClassVersion: 6,
    label: "EC001046: In-line fuse switch disconnector base"
  },
  {
    classId: "EC001047",
    classGroup: "EG000020",
    artClassDesc: "Selective main line circuit breaker",
    artClassVersion: 6,
    label: "EC001047: Selective main line circuit breaker"
  },
  {
    classId: "EC001048",
    classGroup: "EG000047",
    artClassDesc: "Reducing sleeve for copper DIN cable lugs and connectors",
    artClassVersion: 5,
    label: "EC001048: Reducing sleeve for copper DIN cable lugs and connectors"
  },
  {
    classId: "EC001049",
    classGroup: "EG000047",
    artClassDesc: "Assortment box with heat-shrink tubing segments",
    artClassVersion: 4,
    label: "EC001049: Assortment box with heat-shrink tubing segments"
  },
  {
    classId: "EC001050",
    classGroup: "EG000047",
    artClassDesc: "Crimp cable lug for copper conductors",
    artClassVersion: 7,
    label: "EC001050: Crimp cable lug for copper conductors"
  },
  {
    classId: "EC001051",
    classGroup: "EG000047",
    artClassDesc: "Tubular cable lug for copper conductors",
    artClassVersion: 8,
    label: "EC001051: Tubular cable lug for copper conductors"
  },
  {
    classId: "EC001052",
    classGroup: "EG000047",
    artClassDesc: "Solderless copper terminals for copper conductors",
    artClassVersion: 8,
    label: "EC001052: Solderless copper terminals for copper conductors"
  },
  {
    classId: "EC001053",
    classGroup: "EG000047",
    artClassDesc: "Crimp cable lug for aluminium conductors",
    artClassVersion: 7,
    label: "EC001053: Crimp cable lug for aluminium conductors"
  },
  {
    classId: "EC001054",
    classGroup: "EG000047",
    artClassDesc: "Screw cable lug",
    artClassVersion: 8,
    label: "EC001054: Screw cable lug"
  },
  {
    classId: "EC001056",
    classGroup: "EG000028",
    artClassDesc: "Mixed light lamp",
    artClassVersion: 9,
    label: "EC001056: Mixed light lamp"
  },
  {
    classId: "EC001057",
    classGroup: "EG000028",
    artClassDesc: "Studio-, projection- and photo lamp",
    artClassVersion: 9,
    label: "EC001057: Studio-, projection- and photo lamp"
  },
  {
    classId: "EC001058",
    classGroup: "EG000027",
    artClassDesc: "Tunnel luminaire",
    artClassVersion: 8,
    label: "EC001058: Tunnel luminaire"
  },
  {
    classId: "EC001059",
    classGroup: "EG000047",
    artClassDesc: "Crimp splices for copper conductor",
    artClassVersion: 7,
    label: "EC001059: Crimp splices for copper conductor"
  },
  {
    classId: "EC001060",
    classGroup: "EG000047",
    artClassDesc: "Crimp splices for aluminium conductor",
    artClassVersion: 7,
    label: "EC001060: Crimp splices for aluminium conductor"
  },
  {
    classId: "EC001062",
    classGroup: "EG000047",
    artClassDesc: "Branch terminal",
    artClassVersion: 7,
    label: "EC001062: Branch terminal"
  },
  {
    classId: "EC001063",
    classGroup: "EG000047",
    artClassDesc: "Connector to screw",
    artClassVersion: 8,
    label: "EC001063: Connector to screw"
  },
  {
    classId: "EC001064",
    classGroup: "EG000047",
    artClassDesc: "Cable branch screw clamp ring",
    artClassVersion: 6,
    label: "EC001064: Cable branch screw clamp ring"
  },
  {
    classId: "EC001068",
    classGroup: "EG000041",
    artClassDesc: "Digital memory medium",
    artClassVersion: 7,
    label: "EC001068: Digital memory medium"
  },
  {
    classId: "EC001070",
    classGroup: "EG000041",
    artClassDesc: "Digital photo camera",
    artClassVersion: 6,
    label: "EC001070: Digital photo camera"
  },
  {
    classId: "EC001071",
    classGroup: "EG000041",
    artClassDesc: "Portable DVD player/recorder",
    artClassVersion: 7,
    label: "EC001071: Portable DVD player/recorder"
  },
  {
    classId: "EC001072",
    classGroup: "EG000017",
    artClassDesc: "Hood/lens for circuit control devices",
    artClassVersion: 7,
    label: "EC001072: Hood/lens for circuit control devices"
  },
  {
    classId: "EC001079",
    classGroup: "EG000017",
    artClassDesc: "Capacitor contactor",
    artClassVersion: 6,
    label: "EC001079: Capacitor contactor"
  },
  {
    classId: "EC001080",
    classGroup: "EG000017",
    artClassDesc: "Electronic overload relay",
    artClassVersion: 7,
    label: "EC001080: Electronic overload relay"
  },
  {
    classId: "EC001081",
    classGroup: "EG000033",
    artClassDesc: "Room antenna",
    artClassVersion: 6,
    label: "EC001081: Room antenna"
  },
  {
    classId: "EC001082",
    classGroup: "EG000033",
    artClassDesc: "Antenna mounting material",
    artClassVersion: 5,
    label: "EC001082: Antenna mounting material"
  },
  {
    classId: "EC001083",
    classGroup: "EG000033",
    artClassDesc: "Antenna measuring instrument",
    artClassVersion: 5,
    label: "EC001083: Antenna measuring instrument"
  },
  {
    classId: "EC001085",
    classGroup: "EG000050",
    artClassDesc: "Operating tool for hole punch",
    artClassVersion: 5,
    label: "EC001085: Operating tool for hole punch"
  },
  {
    classId: "EC001086",
    classGroup: "EG000061",
    artClassDesc: "Shelf for soldering iron",
    artClassVersion: 5,
    label: "EC001086: Shelf for soldering iron"
  },
  {
    classId: "EC001087",
    classGroup: "EG000061",
    artClassDesc: "Soldering iron (battery)",
    artClassVersion: 6,
    label: "EC001087: Soldering iron (battery)"
  },
  {
    classId: "EC001088",
    classGroup: "EG000061",
    artClassDesc: "Soldering station",
    artClassVersion: 6,
    label: "EC001088: Soldering station"
  },
  {
    classId: "EC001089",
    classGroup: "EG000036",
    artClassDesc: "Power supply door communication",
    artClassVersion: 8,
    label: "EC001089: Power supply door communication"
  },
  {
    classId: "EC001092",
    classGroup: "EG000034",
    artClassDesc: "Swing gate operator",
    artClassVersion: 7,
    label: "EC001092: Swing gate operator"
  },
  {
    classId: "EC001093",
    classGroup: "EG000034",
    artClassDesc: "Sliding gate operator",
    artClassVersion: 6,
    label: "EC001093: Sliding gate operator"
  },
  {
    classId: "EC001094",
    classGroup: "EG000032",
    artClassDesc: "Dimming actuator for bus system",
    artClassVersion: 9,
    label: "EC001094: Dimming actuator for bus system"
  },
  {
    classId: "EC001095",
    classGroup: "EG000032",
    artClassDesc: "Light control unit for bus system",
    artClassVersion: 9,
    label: "EC001095: Light control unit for bus system"
  },
  {
    classId: "EC001096",
    classGroup: "EG000032",
    artClassDesc: "Analogue actuator for bus system",
    artClassVersion: 8,
    label: "EC001096: Analogue actuator for bus system"
  },
  {
    classId: "EC001097",
    classGroup: "EG000032",
    artClassDesc: "Switch actuator for bus system",
    artClassVersion: 9,
    label: "EC001097: Switch actuator for bus system"
  },
  {
    classId: "EC001099",
    classGroup: "EG000032",
    artClassDesc: "System interface/media gateway for bus system",
    artClassVersion: 9,
    label: "EC001099: System interface/media gateway for bus system"
  },
  {
    classId: "EC001100",
    classGroup: "EG000032",
    artClassDesc: "Sensor control for bus system",
    artClassVersion: 8,
    label: "EC001100: Sensor control for bus system"
  },
  {
    classId: "EC001101",
    classGroup: "EG000032",
    artClassDesc: "Room temperature controller for bus system",
    artClassVersion: 9,
    label: "EC001101: Room temperature controller for bus system"
  },
  {
    classId: "EC001102",
    classGroup: "EG000032",
    artClassDesc: "Energy management for bus system",
    artClassVersion: 9,
    label: "EC001102: Energy management for bus system"
  },
  {
    classId: "EC001103",
    classGroup: "EG000032",
    artClassDesc: "Measurement device for bus system",
    artClassVersion: 8,
    label: "EC001103: Measurement device for bus system"
  },
  {
    classId: "EC001104",
    classGroup: "EG000036",
    artClassDesc: "External camera door communication",
    artClassVersion: 8,
    label: "EC001104: External camera door communication"
  },
  {
    classId: "EC001105",
    classGroup: "EG000017",
    artClassDesc: "Off-load switch",
    artClassVersion: 6,
    label: "EC001105: Off-load switch"
  },
  {
    classId: "EC001112",
    classGroup: "EG000043",
    artClassDesc: "Solar compact installation",
    artClassVersion: 7,
    label: "EC001112: Solar compact installation"
  },
  {
    classId: "EC001115",
    classGroup: "EG000043",
    artClassDesc: "Cover for storage heater",
    artClassVersion: 6,
    label: "EC001115: Cover for storage heater"
  },
  {
    classId: "EC001118",
    classGroup: "EG000042",
    artClassDesc: "Electric heating element for hot water tank",
    artClassVersion: 7,
    label: "EC001118: Electric heating element for hot water tank"
  },
  {
    classId: "EC001121",
    classGroup: "EG000037",
    artClassDesc: "Modular connector",
    artClassVersion: 7,
    label: "EC001121: Modular connector"
  },
  {
    classId: "EC001122",
    classGroup: "EG000037",
    artClassDesc: "Fibre optic connector",
    artClassVersion: 9,
    label: "EC001122: Fibre optic connector"
  },
  {
    classId: "EC001123",
    classGroup: "EG000037",
    artClassDesc: "Splice protection",
    artClassVersion: 5,
    label: "EC001123: Splice protection"
  },
  {
    classId: "EC001124",
    classGroup: "EG000037",
    artClassDesc: "Fibre optic splicer",
    artClassVersion: 5,
    label: "EC001124: Fibre optic splicer"
  },
  {
    classId: "EC001125",
    classGroup: "EG000037",
    artClassDesc: "Fibre optic shears",
    artClassVersion: 5,
    label: "EC001125: Fibre optic shears"
  },
  {
    classId: "EC001126",
    classGroup: "EG000037",
    artClassDesc: "Fibre optic cleaver",
    artClassVersion: 5,
    label: "EC001126: Fibre optic cleaver"
  },
  {
    classId: "EC001128",
    classGroup: "EG000037",
    artClassDesc: "Patch panel copper (twisted pair)",
    artClassVersion: 7,
    label: "EC001128: Patch panel copper (twisted pair)"
  },
  {
    classId: "EC001129",
    classGroup: "EG000037",
    artClassDesc: "Insert module for fibre optic patch panel",
    artClassVersion: 6,
    label: "EC001129: Insert module for fibre optic patch panel"
  },
  {
    classId: "EC001130",
    classGroup: "EG000037",
    artClassDesc: "Patch panel fibre optic",
    artClassVersion: 8,
    label: "EC001130: Patch panel fibre optic"
  },
  {
    classId: "EC001131",
    classGroup: "EG000037",
    artClassDesc: "Coax connector",
    artClassVersion: 6,
    label: "EC001131: Coax connector"
  },
  {
    classId: "EC001132",
    classGroup: "EG000037",
    artClassDesc: "D-Sub connector",
    artClassVersion: 5,
    label: "EC001132: D-Sub connector"
  },
  {
    classId: "EC001133",
    classGroup: "EG000037",
    artClassDesc: "D-Sub hood",
    artClassVersion: 5,
    label: "EC001133: D-Sub hood"
  },
  {
    classId: "EC001134",
    classGroup: "EG000037",
    artClassDesc: "Modular coupler",
    artClassVersion: 6,
    label: "EC001134: Modular coupler"
  },
  {
    classId: "EC001135",
    classGroup: "EG000037",
    artClassDesc: "Coax coupler",
    artClassVersion: 5,
    label: "EC001135: Coax coupler"
  },
  {
    classId: "EC001136",
    classGroup: "EG000037",
    artClassDesc: "D-Sub coupler",
    artClassVersion: 4,
    label: "EC001136: D-Sub coupler"
  },
  {
    classId: "EC001139",
    classGroup: "EG000001",
    artClassDesc: "Power cable for overhead lines",
    artClassVersion: 8,
    label: "EC001139: Power cable for overhead lines"
  },
  {
    classId: "EC001141",
    classGroup: "EG000034",
    artClassDesc: "Ventilator for in-house bathrooms and kitchens",
    artClassVersion: 7,
    label: "EC001141: Ventilator for in-house bathrooms and kitchens"
  },
  {
    classId: "EC001142",
    classGroup: "EG000034",
    artClassDesc: "Window ventilator",
    artClassVersion: 6,
    label: "EC001142: Window ventilator"
  },
  {
    classId: "EC001143",
    classGroup: "EG000034",
    artClassDesc: "Equipment ventilator",
    artClassVersion: 6,
    label: "EC001143: Equipment ventilator"
  },
  {
    classId: "EC001144",
    classGroup: "EG000034",
    artClassDesc: "Small-room ventilator",
    artClassVersion: 7,
    label: "EC001144: Small-room ventilator"
  },
  {
    classId: "EC001146",
    classGroup: "EG000034",
    artClassDesc: "Ventilator enclosure for in-house bathrooms and kitchens",
    artClassVersion: 5,
    label: "EC001146: Ventilator enclosure for in-house bathrooms and kitchens"
  },
  {
    classId: "EC001147",
    classGroup: "EG000034",
    artClassDesc: "Ventilator for mounting in the roof",
    artClassVersion: 6,
    label: "EC001147: Ventilator for mounting in the roof"
  },
  {
    classId: "EC001148",
    classGroup: "EG000034",
    artClassDesc: "Duct mounted ventilator",
    artClassVersion: 6,
    label: "EC001148: Duct mounted ventilator"
  },
  {
    classId: "EC001151",
    classGroup: "EG000034",
    artClassDesc: "Industrial wall ventilator",
    artClassVersion: 7,
    label: "EC001151: Industrial wall ventilator"
  },
  {
    classId: "EC001152",
    classGroup: "EG000017",
    artClassDesc: "Connection module for signal tower",
    artClassVersion: 6,
    label: "EC001152: Connection module for signal tower"
  },
  {
    classId: "EC001153",
    classGroup: "EG000017",
    artClassDesc: "Wall bracket for signal tower",
    artClassVersion: 5,
    label: "EC001153: Wall bracket for signal tower"
  },
  {
    classId: "EC001154",
    classGroup: "EG000017",
    artClassDesc: "Stand for signal tower without tube",
    artClassVersion: 5,
    label: "EC001154: Stand for signal tower without tube"
  },
  {
    classId: "EC001155",
    classGroup: "EG000017",
    artClassDesc: "Tube for signal tower",
    artClassVersion: 4,
    label: "EC001155: Tube for signal tower"
  },
  {
    classId: "EC001156",
    classGroup: "EG000061",
    artClassDesc: "Desoldering station",
    artClassVersion: 6,
    label: "EC001156: Desoldering station"
  },
  {
    classId: "EC001157",
    classGroup: "EG000061",
    artClassDesc: "Spare heating element for soldering irons and - baths",
    artClassVersion: 5,
    label: "EC001157: Spare heating element for soldering irons and - baths"
  },
  {
    classId: "EC001158",
    classGroup: "EG000061",
    artClassDesc: "Soldering flux",
    artClassVersion: 7,
    label: "EC001158: Soldering flux"
  },
  {
    classId: "EC001159",
    classGroup: "EG000061",
    artClassDesc: "Flux remover",
    artClassVersion: 6,
    label: "EC001159: Flux remover"
  },
  {
    classId: "EC001166",
    classGroup: "EG000015",
    artClassDesc: "Busbar support",
    artClassVersion: 4,
    label: "EC001166: Busbar support"
  },
  {
    classId: "EC001167",
    classGroup: "EG000015",
    artClassDesc: "Hinge for distribution systems",
    artClassVersion: 5,
    label: "EC001167: Hinge for distribution systems"
  },
  {
    classId: "EC001168",
    classGroup: "EG000047",
    artClassDesc: "Cable resin",
    artClassVersion: 7,
    label: "EC001168: Cable resin"
  },
  {
    classId: "EC001169",
    classGroup: "EG000047",
    artClassDesc: "Straight-through joint",
    artClassVersion: 8,
    label: "EC001169: Straight-through joint"
  },
  {
    classId: "EC001170",
    classGroup: "EG000047",
    artClassDesc: "Branch-splice joint (set)",
    artClassVersion: 7,
    label: "EC001170: Branch-splice joint (set)"
  },
  {
    classId: "EC001172",
    classGroup: "EG000003",
    artClassDesc: "Coupler for corrugated plastic hoses",
    artClassVersion: 6,
    label: "EC001172: Coupler for corrugated plastic hoses"
  },
  {
    classId: "EC001173",
    classGroup: "EG000002",
    artClassDesc: "Metal installation tube",
    artClassVersion: 9,
    label: "EC001173: Metal installation tube"
  },
  {
    classId: "EC001174",
    classGroup: "EG000002",
    artClassDesc: "Plastic installation tube",
    artClassVersion: 9,
    label: "EC001174: Plastic installation tube"
  },
  {
    classId: "EC001175",
    classGroup: "EG000003",
    artClassDesc: "Corrugated plastic hose",
    artClassVersion: 9,
    label: "EC001175: Corrugated plastic hose"
  },
  {
    classId: "EC001176",
    classGroup: "EG000003",
    artClassDesc: "Screw connection for corrugated plastic hose",
    artClassVersion: 8,
    label: "EC001176: Screw connection for corrugated plastic hose"
  },
  {
    classId: "EC001177",
    classGroup: "EG000003",
    artClassDesc: "Protective plastic hose",
    artClassVersion: 8,
    label: "EC001177: Protective plastic hose"
  },
  {
    classId: "EC001178",
    classGroup: "EG000003",
    artClassDesc: "Screw connection for protective plastic hose",
    artClassVersion: 7,
    label: "EC001178: Screw connection for protective plastic hose"
  },
  {
    classId: "EC001179",
    classGroup: "EG000003",
    artClassDesc: "Protective metallic hose",
    artClassVersion: 8,
    label: "EC001179: Protective metallic hose"
  },
  {
    classId: "EC001180",
    classGroup: "EG000003",
    artClassDesc: "Screw connection for protective metallic hose",
    artClassVersion: 9,
    label: "EC001180: Screw connection for protective metallic hose"
  },
  {
    classId: "EC001181",
    classGroup: "EG015910",
    artClassDesc: "Sealing ring, flat",
    artClassVersion: 8,
    label: "EC001181: Sealing ring, flat"
  },
  {
    classId: "EC001182",
    classGroup: "EG000003",
    artClassDesc: "Braided hose",
    artClassVersion: 8,
    label: "EC001182: Braided hose"
  },
  {
    classId: "EC001184",
    classGroup: "EG020002",
    artClassDesc: "Battery charger for electric tools",
    artClassVersion: 7,
    label: "EC001184: Battery charger for electric tools"
  },
  {
    classId: "EC001185",
    classGroup: "EG020023",
    artClassDesc: "Hydraulic drive unit",
    artClassVersion: 6,
    label: "EC001185: Hydraulic drive unit"
  },
  {
    classId: "EC001186",
    classGroup: "EG000052",
    artClassDesc: "Multi purpose equipment (pressing/cutting/piercing)",
    artClassVersion: 5,
    label: "EC001186: Multi purpose equipment (pressing/cutting/piercing)"
  },
  {
    classId: "EC001187",
    classGroup: "EG020002",
    artClassDesc: "Power adapter for battery tools",
    artClassVersion: 6,
    label: "EC001187: Power adapter for battery tools"
  },
  {
    classId: "EC001188",
    classGroup: "EG000052",
    artClassDesc: "Safety shearing equipment",
    artClassVersion: 4,
    label: "EC001188: Safety shearing equipment"
  },
  {
    classId: "EC001189",
    classGroup: "EG000044",
    artClassDesc: "Phase sequence indicator",
    artClassVersion: 4,
    label: "EC001189: Phase sequence indicator"
  },
  {
    classId: "EC001190",
    classGroup: "EG000044",
    artClassDesc: "Measuring instrument for environmental parameters",
    artClassVersion: 6,
    label: "EC001190: Measuring instrument for environmental parameters"
  },
  {
    classId: "EC001191",
    classGroup: "EG000044",
    artClassDesc: "Portable RPM meter",
    artClassVersion: 6,
    label: "EC001191: Portable RPM meter"
  },
  {
    classId: "EC001192",
    classGroup: "EG000044",
    artClassDesc: "Tester for safety of machinery and electrical equipment",
    artClassVersion: 6,
    label: "EC001192: Tester for safety of machinery and electrical equipment"
  },
  {
    classId: "EC001193",
    classGroup: "EG000044",
    artClassDesc: "Leakage current measuring instrument",
    artClassVersion: 6,
    label: "EC001193: Leakage current measuring instrument"
  },
  {
    classId: "EC001194",
    classGroup: "EG000044",
    artClassDesc: "Power quality analyser",
    artClassVersion: 6,
    label: "EC001194: Power quality analyser"
  },
  {
    classId: "EC001195",
    classGroup: "EG000044",
    artClassDesc: "Accessories/spare parts for test and measurement instruments (electrical)",
    artClassVersion: 8,
    label: "EC001195: Accessories/spare parts for test and measurement instruments (electrical)"
  },
  {
    classId: "EC001196",
    classGroup: "EG000044",
    artClassDesc: "Medical electrical equipment tester",
    artClassVersion: 6,
    label: "EC001196: Medical electrical equipment tester"
  },
  {
    classId: "EC001197",
    classGroup: "EG000051",
    artClassDesc: "Belt sander (electric)",
    artClassVersion: 7,
    label: "EC001197: Belt sander (electric)"
  },
  {
    classId: "EC001198",
    classGroup: "EG000050",
    artClassDesc: "Cable ring dispenser",
    artClassVersion: 6,
    label: "EC001198: Cable ring dispenser"
  },
  {
    classId: "EC001199",
    classGroup: "EG020002",
    artClassDesc: "Battery for electric tools",
    artClassVersion: 7,
    label: "EC001199: Battery for electric tools"
  },
  {
    classId: "EC001200",
    classGroup: "EG000052",
    artClassDesc: "Force and pressure test equipment for pressing tools",
    artClassVersion: 4,
    label: "EC001200: Force and pressure test equipment for pressing tools"
  },
  {
    classId: "EC001201",
    classGroup: "EG000052",
    artClassDesc: "Control equipment for hydraulic aggregates",
    artClassVersion: 4,
    label: "EC001201: Control equipment for hydraulic aggregates"
  },
  {
    classId: "EC001202",
    classGroup: "EG000052",
    artClassDesc: "Hydraulic connecting part",
    artClassVersion: 5,
    label: "EC001202: Hydraulic connecting part"
  },
  {
    classId: "EC001203",
    classGroup: "EG000050",
    artClassDesc: "Transport trolley",
    artClassVersion: 7,
    label: "EC001203: Transport trolley"
  },
  {
    classId: "EC001204",
    classGroup: "EG000039",
    artClassDesc: "Washer-dryer",
    artClassVersion: 9,
    label: "EC001204: Washer-dryer"
  },
  {
    classId: "EC001206",
    classGroup: "EG000039",
    artClassDesc: "Control panel for recessed mounted hob",
    artClassVersion: 6,
    label: "EC001206: Control panel for recessed mounted hob"
  },
  {
    classId: "EC001207",
    classGroup: "EG000040",
    artClassDesc: "Ice cube maker",
    artClassVersion: 8,
    label: "EC001207: Ice cube maker"
  },
  {
    classId: "EC001208",
    classGroup: "EG000040",
    artClassDesc: "Cappuccino creamer",
    artClassVersion: 7,
    label: "EC001208: Cappuccino creamer"
  },
  {
    classId: "EC001210",
    classGroup: "EG000040",
    artClassDesc: "Water filter",
    artClassVersion: 6,
    label: "EC001210: Water filter"
  },
  {
    classId: "EC001211",
    classGroup: "EG000040",
    artClassDesc: "Drinks maker",
    artClassVersion: 7,
    label: "EC001211: Drinks maker"
  },
  {
    classId: "EC001212",
    classGroup: "EG000043",
    artClassDesc: "Hand dryer",
    artClassVersion: 7,
    label: "EC001212: Hand dryer"
  },
  {
    classId: "EC001216",
    classGroup: "EG000042",
    artClassDesc: "KNX module building technology",
    artClassVersion: 6,
    label: "EC001216: KNX module building technology"
  },
  {
    classId: "EC001220",
    classGroup: "EG000042",
    artClassDesc: "Connection/tube mounting kit",
    artClassVersion: 5,
    label: "EC001220: Connection/tube mounting kit"
  },
  {
    classId: "EC001231",
    classGroup: "EG000043",
    artClassDesc: "Mounting aid building technology",
    artClassVersion: 7,
    label: "EC001231: Mounting aid building technology"
  },
  {
    classId: "EC001236",
    classGroup: "EG000043",
    artClassDesc: "Solar mounting frame",
    artClassVersion: 7,
    label: "EC001236: Solar mounting frame"
  },
  {
    classId: "EC001238",
    classGroup: "EG000043",
    artClassDesc: "Solar frame attachment",
    artClassVersion: 6,
    label: "EC001238: Solar frame attachment"
  },
  {
    classId: "EC001239",
    classGroup: "EG000043",
    artClassDesc: "Connection tube for solar collector system",
    artClassVersion: 7,
    label: "EC001239: Connection tube for solar collector system"
  },
  {
    classId: "EC001240",
    classGroup: "EG000043",
    artClassDesc: "Solar compensator/- corrugated hose",
    artClassVersion: 7,
    label: "EC001240: Solar compensator/- corrugated hose"
  },
  {
    classId: "EC001243",
    classGroup: "EG000043",
    artClassDesc: "Heat-transfer fluid",
    artClassVersion: 7,
    label: "EC001243: Heat-transfer fluid"
  },
  {
    classId: "EC001249",
    classGroup: "EG000043",
    artClassDesc: "Cover/casing for outdoor units",
    artClassVersion: 6,
    label: "EC001249: Cover/casing for outdoor units"
  },
  {
    classId: "EC001250",
    classGroup: "EG000043",
    artClassDesc: "Groundwater mounting set for heat pump",
    artClassVersion: 8,
    label: "EC001250: Groundwater mounting set for heat pump"
  },
  {
    classId: "EC001251",
    classGroup: "EG000043",
    artClassDesc: "Groundwater distributor for heat pump",
    artClassVersion: 6,
    label: "EC001251: Groundwater distributor for heat pump"
  },
  {
    classId: "EC001257",
    classGroup: "EG000012",
    artClassDesc: "Neutral disconnect terminal block",
    artClassVersion: 8,
    label: "EC001257: Neutral disconnect terminal block"
  },
  {
    classId: "EC001258",
    classGroup: "EG000017",
    artClassDesc: "Foot switch",
    artClassVersion: 7,
    label: "EC001258: Foot switch"
  },
  {
    classId: "EC001259",
    classGroup: "EG000017",
    artClassDesc: "Stand for signal tower with tube",
    artClassVersion: 5,
    label: "EC001259: Stand for signal tower with tube"
  },
  {
    classId: "EC001260",
    classGroup: "EG000017",
    artClassDesc: "Two-hand control device",
    artClassVersion: 4,
    label: "EC001260: Two-hand control device"
  },
  {
    classId: "EC001261",
    classGroup: "EG000017",
    artClassDesc: "Acoustic module for signal tower",
    artClassVersion: 6,
    label: "EC001261: Acoustic module for signal tower"
  },
  {
    classId: "EC001262",
    classGroup: "EG000037",
    artClassDesc: "Patch cord copper (twisted pair)",
    artClassVersion: 9,
    label: "EC001262: Patch cord copper (twisted pair)"
  },
  {
    classId: "EC001263",
    classGroup: "EG000037",
    artClassDesc: "Fibre optic patch cord",
    artClassVersion: 9,
    label: "EC001263: Fibre optic patch cord"
  },
  {
    classId: "EC001264",
    classGroup: "EG000037",
    artClassDesc: "Data communication connection box copper (twisted pair)",
    artClassVersion: 9,
    label: "EC001264: Data communication connection box copper (twisted pair)"
  },
  {
    classId: "EC001265",
    classGroup: "EG000061",
    artClassDesc: "Soldering iron set (battery)",
    artClassVersion: 6,
    label: "EC001265: Soldering iron set (battery)"
  },
  {
    classId: "EC001266",
    classGroup: "EG000061",
    artClassDesc: "Desoldering iron",
    artClassVersion: 4,
    label: "EC001266: Desoldering iron"
  },
  {
    classId: "EC001267",
    classGroup: "EG000061",
    artClassDesc: "Desoldering lace",
    artClassVersion: 5,
    label: "EC001267: Desoldering lace"
  },
  {
    classId: "EC001268",
    classGroup: "EG000061",
    artClassDesc: "Desoldering tweezers",
    artClassVersion: 5,
    label: "EC001268: Desoldering tweezers"
  },
  {
    classId: "EC001269",
    classGroup: "EG000061",
    artClassDesc: "Desoldering pump",
    artClassVersion: 7,
    label: "EC001269: Desoldering pump"
  },
  {
    classId: "EC001270",
    classGroup: "EG000061",
    artClassDesc: "Desoldering tip",
    artClassVersion: 5,
    label: "EC001270: Desoldering tip"
  },
  {
    classId: "EC001271",
    classGroup: "EG000061",
    artClassDesc: "Gas soldering iron",
    artClassVersion: 5,
    label: "EC001271: Gas soldering iron"
  },
  {
    classId: "EC001272",
    classGroup: "EG000061",
    artClassDesc: "Gas soldering set",
    artClassVersion: 6,
    label: "EC001272: Gas soldering set"
  },
  {
    classId: "EC001273",
    classGroup: "EG000061",
    artClassDesc: "Soldering bath",
    artClassVersion: 5,
    label: "EC001273: Soldering bath"
  },
  {
    classId: "EC001274",
    classGroup: "EG000061",
    artClassDesc: "Industrial air exctraction device",
    artClassVersion: 6,
    label: "EC001274: Industrial air exctraction device"
  },
  {
    classId: "EC001276",
    classGroup: "EG000061",
    artClassDesc: "Soldering set",
    artClassVersion: 4,
    label: "EC001276: Soldering set"
  },
  {
    classId: "EC001277",
    classGroup: "EG000061",
    artClassDesc: "Soldering tip holder",
    artClassVersion: 5,
    label: "EC001277: Soldering tip holder"
  },
  {
    classId: "EC001278",
    classGroup: "EG000061",
    artClassDesc: "Cleaning sponge for soldering tip",
    artClassVersion: 5,
    label: "EC001278: Cleaning sponge for soldering tip"
  },
  {
    classId: "EC001279",
    classGroup: "EG000061",
    artClassDesc: "Temperature controller for soldering bath",
    artClassVersion: 5,
    label: "EC001279: Temperature controller for soldering bath"
  },
  {
    classId: "EC001280",
    classGroup: "EG000061",
    artClassDesc: "Vacuum pipette for SMD-elements",
    artClassVersion: 4,
    label: "EC001280: Vacuum pipette for SMD-elements"
  },
  {
    classId: "EC001281",
    classGroup: "EG000061",
    artClassDesc: "Spare tip for desoldering pump",
    artClassVersion: 5,
    label: "EC001281: Spare tip for desoldering pump"
  },
  {
    classId: "EC001282",
    classGroup: "EG000052",
    artClassDesc: "Insert for crimp tool cable lugs, cable end sleeves, screen connection",
    artClassVersion: 6,
    label: "EC001282: Insert for crimp tool cable lugs, cable end sleeves, screen connection"
  },
  {
    classId: "EC001283",
    classGroup: "EG000012",
    artClassDesc: "Panel feed-through terminal block",
    artClassVersion: 5,
    label: "EC001283: Panel feed-through terminal block"
  },
  {
    classId: "EC001284",
    classGroup: "EG000012",
    artClassDesc: "Single- and multi-pole terminal strip",
    artClassVersion: 7,
    label: "EC001284: Single- and multi-pole terminal strip"
  },
  {
    classId: "EC001285",
    classGroup: "EG000015",
    artClassDesc: "Mounting rail",
    artClassVersion: 6,
    label: "EC001285: Mounting rail"
  },
  {
    classId: "EC001286",
    classGroup: "EG000062",
    artClassDesc: "Auxiliary device for distribution board devices",
    artClassVersion: 9,
    label: "EC001286: Auxiliary device for distribution board devices"
  },
  {
    classId: "EC001287",
    classGroup: "EG000062",
    artClassDesc: "Locking device for switches",
    artClassVersion: 5,
    label: "EC001287: Locking device for switches"
  },
  {
    classId: "EC001288",
    classGroup: "EG000060",
    artClassDesc: "Labelling material",
    artClassVersion: 8,
    label: "EC001288: Labelling material"
  },
  {
    classId: "EC001289",
    classGroup: "EG000041",
    artClassDesc: "Equalizer",
    artClassVersion: 4,
    label: "EC001289: Equalizer"
  },
  {
    classId: "EC001290",
    classGroup: "EG000041",
    artClassDesc: "Car amplifier",
    artClassVersion: 5,
    label: "EC001290: Car amplifier"
  },
  {
    classId: "EC001295",
    classGroup: "EG000043",
    artClassDesc: "Electrical kit for storage heater",
    artClassVersion: 6,
    label: "EC001295: Electrical kit for storage heater"
  },
  {
    classId: "EC001317",
    classGroup: "EG000014",
    artClassDesc: "Panel-mounted CEE socket outlet (IEC 60309)",
    artClassVersion: 9,
    label: "EC001317: Panel-mounted CEE socket outlet (IEC 60309)"
  },
  {
    classId: "EC001319",
    classGroup: "EG000014",
    artClassDesc: "CEE appliance inlet (IEC 60309)",
    artClassVersion: 8,
    label: "EC001319: CEE appliance inlet (IEC 60309)"
  },
  {
    classId: "EC001320",
    classGroup: "EG000014",
    artClassDesc: "CEE coupling (IEC 60309)",
    artClassVersion: 8,
    label: "EC001320: CEE coupling (IEC 60309)"
  },
  {
    classId: "EC001321",
    classGroup: "EG000014",
    artClassDesc: "CEE socket outlet (IEC 60309)",
    artClassVersion: 8,
    label: "EC001321: CEE socket outlet (IEC 60309)"
  },
  {
    classId: "EC001322",
    classGroup: "EG000014",
    artClassDesc: "Round socket/plug for high currents",
    artClassVersion: 6,
    label: "EC001322: Round socket/plug for high currents"
  },
  {
    classId: "EC001324",
    classGroup: "EG000014",
    artClassDesc: "CEE socket outlet, disconnectable, with fuse (IEC 60309)",
    artClassVersion: 8,
    label: "EC001324: CEE socket outlet, disconnectable, with fuse (IEC 60309)"
  },
  {
    classId: "EC001325",
    classGroup: "EG000013",
    artClassDesc: "Panel mounted socket outlet with protective contact",
    artClassVersion: 9,
    label: "EC001325: Panel mounted socket outlet with protective contact"
  },
  {
    classId: "EC001326",
    classGroup: "EG000013",
    artClassDesc: "Plug with protective contact for devices",
    artClassVersion: 9,
    label: "EC001326: Plug with protective contact for devices"
  },
  {
    classId: "EC001327",
    classGroup: "EG000013",
    artClassDesc: "Coupler with protective contact",
    artClassVersion: 9,
    label: "EC001327: Coupler with protective contact"
  },
  {
    classId: "EC001329",
    classGroup: "EG000012",
    artClassDesc: "Multi level installation terminal block",
    artClassVersion: 8,
    label: "EC001329: Multi level installation terminal block"
  },
  {
    classId: "EC001330",
    classGroup: "EG000032",
    artClassDesc: "Shutting actuator for bus system",
    artClassVersion: 9,
    label: "EC001330: Shutting actuator for bus system"
  },
  {
    classId: "EC001332",
    classGroup: "EG000035",
    artClassDesc: "Flashing luminaire",
    artClassVersion: 6,
    label: "EC001332: Flashing luminaire"
  },
  {
    classId: "EC001333",
    classGroup: "EG000035",
    artClassDesc: "Strobe luminaire",
    artClassVersion: 7,
    label: "EC001333: Strobe luminaire"
  },
  {
    classId: "EC001334",
    classGroup: "EG000035",
    artClassDesc: "Continuous luminaire",
    artClassVersion: 6,
    label: "EC001334: Continuous luminaire"
  },
  {
    classId: "EC001335",
    classGroup: "EG000035",
    artClassDesc: "Explosion proof strobe luminaire",
    artClassVersion: 7,
    label: "EC001335: Explosion proof strobe luminaire"
  },
  {
    classId: "EC001336",
    classGroup: "EG000035",
    artClassDesc: "Explosion proof electronic buzzer",
    artClassVersion: 6,
    label: "EC001336: Explosion proof electronic buzzer"
  },
  {
    classId: "EC001337",
    classGroup: "EG000035",
    artClassDesc: "Explosion proof flashing alarm luminaire",
    artClassVersion: 6,
    label: "EC001337: Explosion proof flashing alarm luminaire"
  },
  {
    classId: "EC001338",
    classGroup: "EG000035",
    artClassDesc: "Explosion proof signal horn",
    artClassVersion: 6,
    label: "EC001338: Explosion proof signal horn"
  },
  {
    classId: "EC001339",
    classGroup: "EG000035",
    artClassDesc: "Explosion proof warning light pole",
    artClassVersion: 6,
    label: "EC001339: Explosion proof warning light pole"
  },
  {
    classId: "EC001340",
    classGroup: "EG000035",
    artClassDesc: "Monitored LED indicator luminaire",
    artClassVersion: 5,
    label: "EC001340: Monitored LED indicator luminaire"
  },
  {
    classId: "EC001341",
    classGroup: "EG000035",
    artClassDesc: "Rotating beacon alarm luminaire",
    artClassVersion: 9,
    label: "EC001341: Rotating beacon alarm luminaire"
  },
  {
    classId: "EC001342",
    classGroup: "EG000034",
    artClassDesc: "Explosion proof fan",
    artClassVersion: 8,
    label: "EC001342: Explosion proof fan"
  },
  {
    classId: "EC001349",
    classGroup: "EG000036",
    artClassDesc: "Additional device for door communication",
    artClassVersion: 9,
    label: "EC001349: Additional device for door communication"
  },
  {
    classId: "EC001351",
    classGroup: "EG000036",
    artClassDesc: "Push button panel door communication",
    artClassVersion: 8,
    label: "EC001351: Push button panel door communication"
  },
  {
    classId: "EC001352",
    classGroup: "EG000036",
    artClassDesc: "Accessories/spare parts for door communication",
    artClassVersion: 8,
    label: "EC001352: Accessories/spare parts for door communication"
  },
  {
    classId: "EC001353",
    classGroup: "EG000035",
    artClassDesc: "Traffic light",
    artClassVersion: 6,
    label: "EC001353: Traffic light"
  },
  {
    classId: "EC001354",
    classGroup: "EG000035",
    artClassDesc: "Electronic sound generator",
    artClassVersion: 5,
    label: "EC001354: Electronic sound generator"
  },
  {
    classId: "EC001355",
    classGroup: "EG000035",
    artClassDesc: "Explosion proof traffic light",
    artClassVersion: 5,
    label: "EC001355: Explosion proof traffic light"
  },
  {
    classId: "EC001356",
    classGroup: "EG000035",
    artClassDesc: "Explosion proof signal generator",
    artClassVersion: 5,
    label: "EC001356: Explosion proof signal generator"
  },
  {
    classId: "EC001357",
    classGroup: "EG000035",
    artClassDesc: "Explosion proof alarm bell",
    artClassVersion: 6,
    label: "EC001357: Explosion proof alarm bell"
  },
  {
    classId: "EC001358",
    classGroup: "EG000035",
    artClassDesc: "Radio transmitter",
    artClassVersion: 7,
    label: "EC001358: Radio transmitter"
  },
  {
    classId: "EC001359",
    classGroup: "EG000036",
    artClassDesc: "Monitor door communication",
    artClassVersion: 8,
    label: "EC001359: Monitor door communication"
  },
  {
    classId: "EC001361",
    classGroup: "EG000032",
    artClassDesc: "PC-programming set for time switch for bus system",
    artClassVersion: 7,
    label: "EC001361: PC-programming set for time switch for bus system"
  },
  {
    classId: "EC001362",
    classGroup: "EG020002",
    artClassDesc: "Universal hole cutter and components",
    artClassVersion: 6,
    label: "EC001362: Universal hole cutter and components"
  },
  {
    classId: "EC001364",
    classGroup: "EG000051",
    artClassDesc: "Sheet metal shears (electric)",
    artClassVersion: 6,
    label: "EC001364: Sheet metal shears (electric)"
  },
  {
    classId: "EC001365",
    classGroup: "EG000051",
    artClassDesc: "Riveting tool (battery)",
    artClassVersion: 6,
    label: "EC001365: Riveting tool (battery)"
  },
  {
    classId: "EC001366",
    classGroup: "EG000051",
    artClassDesc: "Rotary and demolition hammer (electric)",
    artClassVersion: 8,
    label: "EC001366: Rotary and demolition hammer (electric)"
  },
  {
    classId: "EC001367",
    classGroup: "EG000051",
    artClassDesc: "Rotary hammer (battery)",
    artClassVersion: 6,
    label: "EC001367: Rotary hammer (battery)"
  },
  {
    classId: "EC001368",
    classGroup: "EG000051",
    artClassDesc: "Rotary hammer (electric)",
    artClassVersion: 6,
    label: "EC001368: Rotary hammer (electric)"
  },
  {
    classId: "EC001369",
    classGroup: "EG000051",
    artClassDesc: "Drilling machine (battery)",
    artClassVersion: 6,
    label: "EC001369: Drilling machine (battery)"
  },
  {
    classId: "EC001370",
    classGroup: "EG000051",
    artClassDesc: "Drilling machine (electric)",
    artClassVersion: 7,
    label: "EC001370: Drilling machine (electric)"
  },
  {
    classId: "EC001371",
    classGroup: "EG000051",
    artClassDesc: "Drilling machine/screwdriver (battery)",
    artClassVersion: 7,
    label: "EC001371: Drilling machine/screwdriver (battery)"
  },
  {
    classId: "EC001372",
    classGroup: "EG000051",
    artClassDesc: "Drilling machine/screwdriver (electric)",
    artClassVersion: 7,
    label: "EC001372: Drilling machine/screwdriver (electric)"
  },
  {
    classId: "EC001373",
    classGroup: "EG000051",
    artClassDesc: "Core drilling machine (electric)",
    artClassVersion: 7,
    label: "EC001373: Core drilling machine (electric)"
  },
  {
    classId: "EC001374",
    classGroup: "EG000051",
    artClassDesc: "Triangular base-plate sander (electric)",
    artClassVersion: 7,
    label: "EC001374: Triangular base-plate sander (electric)"
  },
  {
    classId: "EC001375",
    classGroup: "EG000051",
    artClassDesc: "Random orbital disc sander (battery)",
    artClassVersion: 7,
    label: "EC001375: Random orbital disc sander (battery)"
  },
  {
    classId: "EC001377",
    classGroup: "EG000051",
    artClassDesc: "All purpose saw (battery)",
    artClassVersion: 6,
    label: "EC001377: All purpose saw (battery)"
  },
  {
    classId: "EC001378",
    classGroup: "EG000051",
    artClassDesc: "All purpose saw (electric)",
    artClassVersion: 5,
    label: "EC001378: All purpose saw (electric)"
  },
  {
    classId: "EC001379",
    classGroup: "EG000051",
    artClassDesc: "Threading machine (electric)",
    artClassVersion: 7,
    label: "EC001379: Threading machine (electric)"
  },
  {
    classId: "EC001380",
    classGroup: "EG000051",
    artClassDesc: "Hand circular saw (battery)",
    artClassVersion: 7,
    label: "EC001380: Hand circular saw (battery)"
  },
  {
    classId: "EC001381",
    classGroup: "EG000051",
    artClassDesc: "Hand circular saw (electric)",
    artClassVersion: 7,
    label: "EC001381: Hand circular saw (electric)"
  },
  {
    classId: "EC001382",
    classGroup: "EG000051",
    artClassDesc: "Hot glue gun (electric)",
    artClassVersion: 5,
    label: "EC001382: Hot glue gun (electric)"
  },
  {
    classId: "EC001383",
    classGroup: "EG000051",
    artClassDesc: "Hot air gun (electric)",
    artClassVersion: 8,
    label: "EC001383: Hot air gun (electric)"
  },
  {
    classId: "EC001384",
    classGroup: "EG000051",
    artClassDesc: "Planer (battery)",
    artClassVersion: 7,
    label: "EC001384: Planer (battery)"
  },
  {
    classId: "EC001385",
    classGroup: "EG000051",
    artClassDesc: "Planer (electric)",
    artClassVersion: 7,
    label: "EC001385: Planer (electric)"
  },
  {
    classId: "EC001386",
    classGroup: "EG000051",
    artClassDesc: "Chop saw (battery)",
    artClassVersion: 5,
    label: "EC001386: Chop saw (battery)"
  },
  {
    classId: "EC001387",
    classGroup: "EG000051",
    artClassDesc: "Kit gun (battery)",
    artClassVersion: 6,
    label: "EC001387: Kit gun (battery)"
  },
  {
    classId: "EC001388",
    classGroup: "EG000051",
    artClassDesc: "Chipping hammer (electric)",
    artClassVersion: 6,
    label: "EC001388: Chipping hammer (electric)"
  },
  {
    classId: "EC001390",
    classGroup: "EG000051",
    artClassDesc: "Wet and dry vacuum cleaner (electric)",
    artClassVersion: 7,
    label: "EC001390: Wet and dry vacuum cleaner (electric)"
  },
  {
    classId: "EC001391",
    classGroup: "EG000051",
    artClassDesc: "Hammer drill (battery)",
    artClassVersion: 7,
    label: "EC001391: Hammer drill (battery)"
  },
  {
    classId: "EC001392",
    classGroup: "EG000051",
    artClassDesc: "Hammer drill (electric)",
    artClassVersion: 7,
    label: "EC001392: Hammer drill (electric)"
  },
  {
    classId: "EC001393",
    classGroup: "EG000051",
    artClassDesc: "Impact screw driver (battery)",
    artClassVersion: 7,
    label: "EC001393: Impact screw driver (battery)"
  },
  {
    classId: "EC001394",
    classGroup: "EG000051",
    artClassDesc: "Impact screw driver (electric)",
    artClassVersion: 8,
    label: "EC001394: Impact screw driver (electric)"
  },
  {
    classId: "EC001395",
    classGroup: "EG000051",
    artClassDesc: "Screw driver (battery)",
    artClassVersion: 6,
    label: "EC001395: Screw driver (battery)"
  },
  {
    classId: "EC001396",
    classGroup: "EG000051",
    artClassDesc: "Screw driver (electric)",
    artClassVersion: 5,
    label: "EC001396: Screw driver (electric)"
  },
  {
    classId: "EC001397",
    classGroup: "EG000051",
    artClassDesc: "Sander (battery)",
    artClassVersion: 7,
    label: "EC001397: Sander (battery)"
  },
  {
    classId: "EC001398",
    classGroup: "EG000051",
    artClassDesc: "Sander (electric)",
    artClassVersion: 7,
    label: "EC001398: Sander (electric)"
  },
  {
    classId: "EC001399",
    classGroup: "EG000051",
    artClassDesc: "Jig saw (electric)",
    artClassVersion: 8,
    label: "EC001399: Jig saw (electric)"
  },
  {
    classId: "EC001400",
    classGroup: "EG000051",
    artClassDesc: "Stapler/nailer (battery)",
    artClassVersion: 8,
    label: "EC001400: Stapler/nailer (battery)"
  },
  {
    classId: "EC001401",
    classGroup: "EG000051",
    artClassDesc: "Angle drill machine (battery)",
    artClassVersion: 6,
    label: "EC001401: Angle drill machine (battery)"
  },
  {
    classId: "EC001402",
    classGroup: "EG000051",
    artClassDesc: "Angle drill machine (electric)",
    artClassVersion: 7,
    label: "EC001402: Angle drill machine (electric)"
  },
  {
    classId: "EC001403",
    classGroup: "EG000051",
    artClassDesc: "Angle drill machine/screw driver (battery)",
    artClassVersion: 5,
    label: "EC001403: Angle drill machine/screw driver (battery)"
  },
  {
    classId: "EC001404",
    classGroup: "EG000051",
    artClassDesc: "Angle grinder (electric)",
    artClassVersion: 8,
    label: "EC001404: Angle grinder (electric)"
  },
  {
    classId: "EC001405",
    classGroup: "EG000051",
    artClassDesc: "Jig saw (battery)",
    artClassVersion: 7,
    label: "EC001405: Jig saw (battery)"
  },
  {
    classId: "EC001408",
    classGroup: "EG000037",
    artClassDesc: "PC cable",
    artClassVersion: 6,
    label: "EC001408: PC cable"
  },
  {
    classId: "EC001409",
    classGroup: "EG000037",
    artClassDesc: "Data communication connection box fibre optic",
    artClassVersion: 8,
    label: "EC001409: Data communication connection box fibre optic"
  },
  {
    classId: "EC001410",
    classGroup: "EG000062",
    artClassDesc: "DCF-antenna for time switches",
    artClassVersion: 4,
    label: "EC001410: DCF-antenna for time switches"
  },
  {
    classId: "EC001411",
    classGroup: "EG000062",
    artClassDesc: "Surface mounting set for modular rail mounted devices",
    artClassVersion: 5,
    label: "EC001411: Surface mounting set for modular rail mounted devices"
  },
  {
    classId: "EC001412",
    classGroup: "EG000024",
    artClassDesc: "Graphic panel",
    artClassVersion: 9,
    label: "EC001412: Graphic panel"
  },
  {
    classId: "EC001413",
    classGroup: "EG000024",
    artClassDesc: "Industrial PC",
    artClassVersion: 9,
    label: "EC001413: Industrial PC"
  },
  {
    classId: "EC001414",
    classGroup: "EG000024",
    artClassDesc: "Panel PC",
    artClassVersion: 9,
    label: "EC001414: Panel PC"
  },
  {
    classId: "EC001415",
    classGroup: "EG000024",
    artClassDesc: "Push button panel",
    artClassVersion: 6,
    label: "EC001415: Push button panel"
  },
  {
    classId: "EC001416",
    classGroup: "EG000024",
    artClassDesc: "Visualization software",
    artClassVersion: 7,
    label: "EC001416: Visualization software"
  },
  {
    classId: "EC001417",
    classGroup: "EG000024",
    artClassDesc: "Logic module",
    artClassVersion: 9,
    label: "EC001417: Logic module"
  },
  {
    classId: "EC001418",
    classGroup: "EG000024",
    artClassDesc: "PLC programming device",
    artClassVersion: 4,
    label: "EC001418: PLC programming device"
  },
  {
    classId: "EC001419",
    classGroup: "EG000024",
    artClassDesc: "PLC digital I/O-module",
    artClassVersion: 8,
    label: "EC001419: PLC digital I/O-module"
  },
  {
    classId: "EC001420",
    classGroup: "EG000024",
    artClassDesc: "PLC analogue I/O-module",
    artClassVersion: 8,
    label: "EC001420: PLC analogue I/O-module"
  },
  {
    classId: "EC001421",
    classGroup: "EG000024",
    artClassDesc: "PLC analogue/digital I/O-module",
    artClassVersion: 7,
    label: "EC001421: PLC analogue/digital I/O-module"
  },
  {
    classId: "EC001422",
    classGroup: "EG000024",
    artClassDesc: "PLC function/technology module",
    artClassVersion: 7,
    label: "EC001422: PLC function/technology module"
  },
  {
    classId: "EC001423",
    classGroup: "EG000024",
    artClassDesc: "PLC communication module",
    artClassVersion: 8,
    label: "EC001423: PLC communication module"
  },
  {
    classId: "EC001426",
    classGroup: "EG000024",
    artClassDesc: "Text panel",
    artClassVersion: 9,
    label: "EC001426: Text panel"
  },
  {
    classId: "EC001427",
    classGroup: "EG000024",
    artClassDesc: "Mobile panel",
    artClassVersion: 9,
    label: "EC001427: Mobile panel"
  },
  {
    classId: "EC001428",
    classGroup: "EG000024",
    artClassDesc: "Recessed mounted monitor",
    artClassVersion: 6,
    label: "EC001428: Recessed mounted monitor"
  },
  {
    classId: "EC001429",
    classGroup: "EG000038",
    artClassDesc: "Desktop monitor",
    artClassVersion: 7,
    label: "EC001429: Desktop monitor"
  },
  {
    classId: "EC001435",
    classGroup: "EG000024",
    artClassDesc: "Automation software, others",
    artClassVersion: 8,
    label: "EC001435: Automation software, others"
  },
  {
    classId: "EC001436",
    classGroup: "EG000019",
    artClassDesc: "Axle break protection relay",
    artClassVersion: 7,
    label: "EC001436: Axle break protection relay"
  },
  {
    classId: "EC001437",
    classGroup: "EG000019",
    artClassDesc: "Switching relay",
    artClassVersion: 7,
    label: "EC001437: Switching relay"
  },
  {
    classId: "EC001438",
    classGroup: "EG000019",
    artClassDesc: "Voltage monitoring relay",
    artClassVersion: 5,
    label: "EC001438: Voltage monitoring relay"
  },
  {
    classId: "EC001439",
    classGroup: "EG000019",
    artClassDesc: "Timer relay",
    artClassVersion: 6,
    label: "EC001439: Timer relay"
  },
  {
    classId: "EC001440",
    classGroup: "EG000019",
    artClassDesc: "Current monitoring relay",
    artClassVersion: 6,
    label: "EC001440: Current monitoring relay"
  },
  {
    classId: "EC001441",
    classGroup: "EG000019",
    artClassDesc: "Phase monitoring relay",
    artClassVersion: 5,
    label: "EC001441: Phase monitoring relay"
  },
  {
    classId: "EC001442",
    classGroup: "EG000019",
    artClassDesc: "Frequency monitoring relay",
    artClassVersion: 5,
    label: "EC001442: Frequency monitoring relay"
  },
  {
    classId: "EC001443",
    classGroup: "EG000019",
    artClassDesc: "Effective power (cos phi) monitoring relay",
    artClassVersion: 6,
    label: "EC001443: Effective power (cos phi) monitoring relay"
  },
  {
    classId: "EC001444",
    classGroup: "EG000019",
    artClassDesc: "Insulation and earth fault monitoring relay",
    artClassVersion: 5,
    label: "EC001444: Insulation and earth fault monitoring relay"
  },
  {
    classId: "EC001445",
    classGroup: "EG000019",
    artClassDesc: "Residual current monitoring relay",
    artClassVersion: 5,
    label: "EC001445: Residual current monitoring relay"
  },
  {
    classId: "EC001446",
    classGroup: "EG000019",
    artClassDesc: "Temperature monitoring relay",
    artClassVersion: 6,
    label: "EC001446: Temperature monitoring relay"
  },
  {
    classId: "EC001447",
    classGroup: "EG000019",
    artClassDesc: "(Fill) level monitoring relay",
    artClassVersion: 6,
    label: "EC001447: (Fill) level monitoring relay"
  },
  {
    classId: "EC001448",
    classGroup: "EG000019",
    artClassDesc: "Speed-/standstill monitoring relay",
    artClassVersion: 7,
    label: "EC001448: Speed-/standstill monitoring relay"
  },
  {
    classId: "EC001449",
    classGroup: "EG000019",
    artClassDesc: "Device for monitoring of safety-related circuits",
    artClassVersion: 9,
    label: "EC001449: Device for monitoring of safety-related circuits"
  },
  {
    classId: "EC001451",
    classGroup: "EG000019",
    artClassDesc: "Overrun relay",
    artClassVersion: 8,
    label: "EC001451: Overrun relay"
  },
  {
    classId: "EC001452",
    classGroup: "EG000019",
    artClassDesc: "Two-hand control relay",
    artClassVersion: 8,
    label: "EC001452: Two-hand control relay"
  },
  {
    classId: "EC001456",
    classGroup: "EG000019",
    artClassDesc: "Relay socket",
    artClassVersion: 4,
    label: "EC001456: Relay socket"
  },
  {
    classId: "EC001457",
    classGroup: "EG000021",
    artClassDesc: "Combined arrester for power supply systems",
    artClassVersion: 9,
    label: "EC001457: Combined arrester for power supply systems"
  },
  {
    classId: "EC001458",
    classGroup: "EG000003",
    artClassDesc: "Fastening angle for hose fitting",
    artClassVersion: 4,
    label: "EC001458: Fastening angle for hose fitting"
  },
  {
    classId: "EC001462",
    classGroup: "EG000043",
    artClassDesc: "House ventilation set",
    artClassVersion: 5,
    label: "EC001462: House ventilation set"
  },
  {
    classId: "EC001465",
    classGroup: "EG000021",
    artClassDesc: "Connection clamp for earth rods",
    artClassVersion: 7,
    label: "EC001465: Connection clamp for earth rods"
  },
  {
    classId: "EC001466",
    classGroup: "EG000021",
    artClassDesc: "Lightning current arrester for data networks/MCR-technology",
    artClassVersion: 8,
    label: "EC001466: Lightning current arrester for data networks/MCR-technology"
  },
  {
    classId: "EC001467",
    classGroup: "EG000037",
    artClassDesc: "Media converter",
    artClassVersion: 8,
    label: "EC001467: Media converter"
  },
  {
    classId: "EC001468",
    classGroup: "EG000021",
    artClassDesc: "Flat strip for lightning protection",
    artClassVersion: 6,
    label: "EC001468: Flat strip for lightning protection"
  },
  {
    classId: "EC001469",
    classGroup: "EG000003",
    artClassDesc: "Protective hose adapter",
    artClassVersion: 8,
    label: "EC001469: Protective hose adapter"
  },
  {
    classId: "EC001470",
    classGroup: "EG000021",
    artClassDesc: "Equipotential bonding bar",
    artClassVersion: 7,
    label: "EC001470: Equipotential bonding bar"
  },
  {
    classId: "EC001471",
    classGroup: "EG000021",
    artClassDesc: "Metal cable",
    artClassVersion: 7,
    label: "EC001471: Metal cable"
  },
  {
    classId: "EC001473",
    classGroup: "EG000021",
    artClassDesc: "Surge protection device for power supply and information technology",
    artClassVersion: 8,
    label: "EC001473: Surge protection device for power supply and information technology"
  },
  {
    classId: "EC001474",
    classGroup: "EG000037",
    artClassDesc: "Fibre optic duct",
    artClassVersion: 8,
    label: "EC001474: Fibre optic duct"
  },
  {
    classId: "EC001475",
    classGroup: "EG000037",
    artClassDesc: "Fibre optic duct coupler",
    artClassVersion: 6,
    label: "EC001475: Fibre optic duct coupler"
  },
  {
    classId: "EC001476",
    classGroup: "EG000037",
    artClassDesc: "Optical fibre",
    artClassVersion: 7,
    label: "EC001476: Optical fibre"
  },
  {
    classId: "EC001478",
    classGroup: "EG000037",
    artClassDesc: "Network router",
    artClassVersion: 6,
    label: "EC001478: Network router"
  },
  {
    classId: "EC001479",
    classGroup: "EG000021",
    artClassDesc: "Fixed earthing terminal",
    artClassVersion: 8,
    label: "EC001479: Fixed earthing terminal"
  },
  {
    classId: "EC001483",
    classGroup: "EG000026",
    artClassDesc: "Drive head for position switches/hinge switches",
    artClassVersion: 4,
    label: "EC001483: Drive head for position switches/hinge switches"
  },
  {
    classId: "EC001485",
    classGroup: "EG000026",
    artClassDesc: "NAMUR switching amplifier",
    artClassVersion: 7,
    label: "EC001485: NAMUR switching amplifier"
  },
  {
    classId: "EC001486",
    classGroup: "EG000026",
    artClassDesc: "Rotary encoder",
    artClassVersion: 6,
    label: "EC001486: Rotary encoder"
  },
  {
    classId: "EC001487",
    classGroup: "EG000026",
    artClassDesc: "Actuator for position switch with separate actuator",
    artClassVersion: 5,
    label: "EC001487: Actuator for position switch with separate actuator"
  },
  {
    classId: "EC001488",
    classGroup: "EG000054",
    artClassDesc: "Distributor for danger detection system",
    artClassVersion: 6,
    label: "EC001488: Distributor for danger detection system"
  },
  {
    classId: "EC001489",
    classGroup: "EG000054",
    artClassDesc: "Base for fire detector",
    artClassVersion: 7,
    label: "EC001489: Base for fire detector"
  },
  {
    classId: "EC001491",
    classGroup: "EG000002",
    artClassDesc: "Closure plug for installation tube",
    artClassVersion: 8,
    label: "EC001491: Closure plug for installation tube"
  },
  {
    classId: "EC001492",
    classGroup: "EG000002",
    artClassDesc: "Coupler for cable protection tube for underground application",
    artClassVersion: 8,
    label: "EC001492: Coupler for cable protection tube for underground application"
  },
  {
    classId: "EC001493",
    classGroup: "EG000002",
    artClassDesc: "Bend for cable protection tubes for underground application",
    artClassVersion: 8,
    label: "EC001493: Bend for cable protection tubes for underground application"
  },
  {
    classId: "EC001494",
    classGroup: "EG000002",
    artClassDesc: "Spacer for installation tubes/cable protection tubes",
    artClassVersion: 6,
    label: "EC001494: Spacer for installation tubes/cable protection tubes"
  },
  {
    classId: "EC001496",
    classGroup: "EG000060",
    artClassDesc: "Warning tape",
    artClassVersion: 8,
    label: "EC001496: Warning tape"
  },
  {
    classId: "EC001497",
    classGroup: "EG000029",
    artClassDesc: "Ballast",
    artClassVersion: 9,
    label: "EC001497: Ballast"
  },
  {
    classId: "EC001498",
    classGroup: "EG000040",
    artClassDesc: "Value coin for vending machine",
    artClassVersion: 4,
    label: "EC001498: Value coin for vending machine"
  },
  {
    classId: "EC001499",
    classGroup: "EG000050",
    artClassDesc: "Colour powder",
    artClassVersion: 6,
    label: "EC001499: Colour powder"
  },
  {
    classId: "EC001500",
    classGroup: "EG000029",
    artClassDesc: "Transformer for low voltage light system/low voltage halogen lamp",
    artClassVersion: 9,
    label: "EC001500: Transformer for low voltage light system/low voltage halogen lamp"
  },
  {
    classId: "EC001503",
    classGroup: "EG000021",
    artClassDesc: "Earthing set",
    artClassVersion: 7,
    label: "EC001503: Earthing set"
  },
  {
    classId: "EC001504",
    classGroup: "EG000019",
    artClassDesc: "Optocoupler",
    artClassVersion: 6,
    label: "EC001504: Optocoupler"
  },
  {
    classId: "EC001506",
    classGroup: "EG000045",
    artClassDesc: "Kilowatt-hour meter",
    artClassVersion: 9,
    label: "EC001506: Kilowatt-hour meter"
  },
  {
    classId: "EC001509",
    classGroup: "EG000050",
    artClassDesc: "Replacement blade",
    artClassVersion: 7,
    label: "EC001509: Replacement blade"
  },
  {
    classId: "EC001510",
    classGroup: "EG000029",
    artClassDesc: "Power supply for discharge lamps",
    artClassVersion: 8,
    label: "EC001510: Power supply for discharge lamps"
  },
  {
    classId: "EC001511",
    classGroup: "EG000054",
    artClassDesc: "Camera for surveillance system",
    artClassVersion: 8,
    label: "EC001511: Camera for surveillance system"
  },
  {
    classId: "EC001512",
    classGroup: "EG000043",
    artClassDesc: "Fragrance cartridge",
    artClassVersion: 6,
    label: "EC001512: Fragrance cartridge"
  },
  {
    classId: "EC001513",
    classGroup: "EG000013",
    artClassDesc: "Plug adapter",
    artClassVersion: 7,
    label: "EC001513: Plug adapter"
  },
  {
    classId: "EC001514",
    classGroup: "EG000051",
    artClassDesc: "Random orbital disc sander (electric)",
    artClassVersion: 7,
    label: "EC001514: Random orbital disc sander (electric)"
  },
  {
    classId: "EC001516",
    classGroup: "EG000017",
    artClassDesc: "Capacitor",
    artClassVersion: 6,
    label: "EC001516: Capacitor"
  },
  {
    classId: "EC001517",
    classGroup: "EG000040",
    artClassDesc: "Baby phone",
    artClassVersion: 5,
    label: "EC001517: Baby phone"
  },
  {
    classId: "EC001519",
    classGroup: "EG000062",
    artClassDesc: "Tappets for time switch",
    artClassVersion: 6,
    label: "EC001519: Tappets for time switch"
  },
  {
    classId: "EC001521",
    classGroup: "EG000036",
    artClassDesc: "Door sliding contact",
    artClassVersion: 5,
    label: "EC001521: Door sliding contact"
  },
  {
    classId: "EC001522",
    classGroup: "EG000017",
    artClassDesc: "Busbar",
    artClassVersion: 5,
    label: "EC001522: Busbar"
  },
  {
    classId: "EC001523",
    classGroup: "EG000054",
    artClassDesc: "Monitor for surveillance system",
    artClassVersion: 6,
    label: "EC001523: Monitor for surveillance system"
  },
  {
    classId: "EC001524",
    classGroup: "EG000054",
    artClassDesc: "Video switch for surveillance system",
    artClassVersion: 5,
    label: "EC001524: Video switch for surveillance system"
  },
  {
    classId: "EC001525",
    classGroup: "EG000054",
    artClassDesc: "Central alarm panel for intrusion detection system",
    artClassVersion: 6,
    label: "EC001525: Central alarm panel for intrusion detection system"
  },
  {
    classId: "EC001526",
    classGroup: "EG000054",
    artClassDesc: "Switchgear for danger detection system",
    artClassVersion: 7,
    label: "EC001526: Switchgear for danger detection system"
  },
  {
    classId: "EC001527",
    classGroup: "EG000054",
    artClassDesc: "Video surveillance system",
    artClassVersion: 8,
    label: "EC001527: Video surveillance system"
  },
  {
    classId: "EC001530",
    classGroup: "EG000047",
    artClassDesc: "Cable coding system",
    artClassVersion: 8,
    label: "EC001530: Cable coding system"
  },
  {
    classId: "EC001531",
    classGroup: "EG000017",
    artClassDesc: "Busbar adapter",
    artClassVersion: 6,
    label: "EC001531: Busbar adapter"
  },
  {
    classId: "EC001532",
    classGroup: "EG000038",
    artClassDesc: "Accessories/spare parts for telephone system",
    artClassVersion: 8,
    label: "EC001532: Accessories/spare parts for telephone system"
  },
  {
    classId: "EC001535",
    classGroup: "EG000004",
    artClassDesc: "Bend for cable ladder",
    artClassVersion: 8,
    label: "EC001535: Bend for cable ladder"
  },
  {
    classId: "EC001540",
    classGroup: "EG000004",
    artClassDesc: "Tee for cable ladder",
    artClassVersion: 8,
    label: "EC001540: Tee for cable ladder"
  },
  {
    classId: "EC001542",
    classGroup: "EG000004",
    artClassDesc: "Cross piece for cable tray",
    artClassVersion: 8,
    label: "EC001542: Cross piece for cable tray"
  },
  {
    classId: "EC001544",
    classGroup: "EG000004",
    artClassDesc: "Tee for cable tray",
    artClassVersion: 8,
    label: "EC001544: Tee for cable tray"
  },
  {
    classId: "EC001545",
    classGroup: "EG000062",
    artClassDesc: "Main switch for distribution board",
    artClassVersion: 7,
    label: "EC001545: Main switch for distribution board"
  },
  {
    classId: "EC001546",
    classGroup: "EG000062",
    artClassDesc: "Push button for distribution board",
    artClassVersion: 7,
    label: "EC001546: Push button for distribution board"
  },
  {
    classId: "EC001547",
    classGroup: "EG000062",
    artClassDesc: "Bell transformer",
    artClassVersion: 6,
    label: "EC001547: Bell transformer"
  },
  {
    classId: "EC001548",
    classGroup: "EG000041",
    artClassDesc: "Audio-/video support bracket",
    artClassVersion: 7,
    label: "EC001548: Audio-/video support bracket"
  },
  {
    classId: "EC001551",
    classGroup: "EG000039",
    artClassDesc: "Accessories/spare parts for refrigerator/freezer",
    artClassVersion: 8,
    label: "EC001551: Accessories/spare parts for refrigerator/freezer"
  },
  {
    classId: "EC001553",
    classGroup: "EG000039",
    artClassDesc: "Filter for cooker hood",
    artClassVersion: 7,
    label: "EC001553: Filter for cooker hood"
  },
  {
    classId: "EC001559",
    classGroup: "EG000004",
    artClassDesc: "Cross piece for cable ladder",
    artClassVersion: 8,
    label: "EC001559: Cross piece for cable ladder"
  },
  {
    classId: "EC001567",
    classGroup: "EG000049",
    artClassDesc: "Head plate for mounting rail/-profile",
    artClassVersion: 8,
    label: "EC001567: Head plate for mounting rail/-profile"
  },
  {
    classId: "EC001569",
    classGroup: "EG000004",
    artClassDesc: "Cover for corner add-on piece for cable support system",
    artClassVersion: 8,
    label: "EC001569: Cover for corner add-on piece for cable support system"
  },
  {
    classId: "EC001570",
    classGroup: "EG000004",
    artClassDesc: "Cover coupler for cable support system",
    artClassVersion: 7,
    label: "EC001570: Cover coupler for cable support system"
  },
  {
    classId: "EC001573",
    classGroup: "EG000004",
    artClassDesc: "End piece for cable ladder",
    artClassVersion: 7,
    label: "EC001573: End piece for cable ladder"
  },
  {
    classId: "EC001574",
    classGroup: "EG000004",
    artClassDesc: "End piece for cable tray",
    artClassVersion: 7,
    label: "EC001574: End piece for cable tray"
  },
  {
    classId: "EC001576",
    classGroup: "EG000053",
    artClassDesc: "Power cord",
    artClassVersion: 8,
    label: "EC001576: Power cord"
  },
  {
    classId: "EC001579",
    classGroup: "EG000001",
    artClassDesc: "Hybrid cable",
    artClassVersion: 7,
    label: "EC001579: Hybrid cable"
  },
  {
    classId: "EC001580",
    classGroup: "EG000013",
    artClassDesc: "Accessories/spare parts for movement sensor",
    artClassVersion: 9,
    label: "EC001580: Accessories/spare parts for movement sensor"
  },
  {
    classId: "EC001581",
    classGroup: "EG000032",
    artClassDesc: "Basic module for bus system",
    artClassVersion: 8,
    label: "EC001581: Basic module for bus system"
  },
  {
    classId: "EC001582",
    classGroup: "EG000032",
    artClassDesc: "Movement sensor for bus system",
    artClassVersion: 9,
    label: "EC001582: Movement sensor for bus system"
  },
  {
    classId: "EC001583",
    classGroup: "EG000032",
    artClassDesc: "Accessories/spare parts for bus system",
    artClassVersion: 8,
    label: "EC001583: Accessories/spare parts for bus system"
  },
  {
    classId: "EC001584",
    classGroup: "EG000032",
    artClassDesc: "I/O device for bus system",
    artClassVersion: 8,
    label: "EC001584: I/O device for bus system"
  },
  {
    classId: "EC001585",
    classGroup: "EG000032",
    artClassDesc: "Multi operating device for bus system",
    artClassVersion: 8,
    label: "EC001585: Multi operating device for bus system"
  },
  {
    classId: "EC001586",
    classGroup: "EG000032",
    artClassDesc: "Heating actuator for bus system",
    artClassVersion: 8,
    label: "EC001586: Heating actuator for bus system"
  },
  {
    classId: "EC001587",
    classGroup: "EG000032",
    artClassDesc: "Hand-/wall sensor for bus system",
    artClassVersion: 9,
    label: "EC001587: Hand-/wall sensor for bus system"
  },
  {
    classId: "EC001588",
    classGroup: "EG000013",
    artClassDesc: "Electronic switch",
    artClassVersion: 9,
    label: "EC001588: Electronic switch"
  },
  {
    classId: "EC001590",
    classGroup: "EG000013",
    artClassDesc: "Installation switch",
    artClassVersion: 9,
    label: "EC001590: Installation switch"
  },
  {
    classId: "EC001591",
    classGroup: "EG000013",
    artClassDesc: "Sensor for venetian blind/time switches",
    artClassVersion: 7,
    label: "EC001591: Sensor for venetian blind/time switches"
  },
  {
    classId: "EC001593",
    classGroup: "EG000013",
    artClassDesc: "Isolator relay venetian blind",
    artClassVersion: 6,
    label: "EC001593: Isolator relay venetian blind"
  },
  {
    classId: "EC001594",
    classGroup: "EG000013",
    artClassDesc: "Time switch clock for domestic switching devices",
    artClassVersion: 9,
    label: "EC001594: Time switch clock for domestic switching devices"
  },
  {
    classId: "EC001596",
    classGroup: "EG000024",
    artClassDesc: "Fieldbus, decentr. periphery - analogue I/O module",
    artClassVersion: 9,
    label: "EC001596: Fieldbus, decentr. periphery - analogue I/O module"
  },
  {
    classId: "EC001597",
    classGroup: "EG000024",
    artClassDesc: "Fieldbus, decentr. periphery - analogue/digital I/O module",
    artClassVersion: 9,
    label: "EC001597: Fieldbus, decentr. periphery - analogue/digital I/O module"
  },
  {
    classId: "EC001598",
    classGroup: "EG000024",
    artClassDesc: "Fieldbus, decentr. periphery - mounting frame",
    artClassVersion: 7,
    label: "EC001598: Fieldbus, decentr. periphery - mounting frame"
  },
  {
    classId: "EC001599",
    classGroup: "EG000024",
    artClassDesc: "Fieldbus, decentr. periphery - digital I/O module",
    artClassVersion: 9,
    label: "EC001599: Fieldbus, decentr. periphery - digital I/O module"
  },
  {
    classId: "EC001600",
    classGroup: "EG000024",
    artClassDesc: "Fieldbus, decentr. periphery - power supply/segment module",
    artClassVersion: 9,
    label: "EC001600: Fieldbus, decentr. periphery - power supply/segment module"
  },
  {
    classId: "EC001601",
    classGroup: "EG000024",
    artClassDesc: "Fieldbus, decentr. periphery - function-/technology module",
    artClassVersion: 9,
    label: "EC001601: Fieldbus, decentr. periphery - function-/technology module"
  },
  {
    classId: "EC001603",
    classGroup: "EG000024",
    artClassDesc: "Fieldbus, decentr. periphery - basic appliance",
    artClassVersion: 8,
    label: "EC001603: Fieldbus, decentr. periphery - basic appliance"
  },
  {
    classId: "EC001604",
    classGroup: "EG000024",
    artClassDesc: "Fieldbus, decentr. periphery - communication module",
    artClassVersion: 8,
    label: "EC001604: Fieldbus, decentr. periphery - communication module"
  },
  {
    classId: "EC001605",
    classGroup: "EG000024",
    artClassDesc: "Fieldbus, decentr. periphery - power module, motor switch",
    artClassVersion: 9,
    label: "EC001605: Fieldbus, decentr. periphery - power module, motor switch"
  },
  {
    classId: "EC001625",
    classGroup: "EG000021",
    artClassDesc: "Combined arrester for data networks/MCR-technology",
    artClassVersion: 7,
    label: "EC001625: Combined arrester for data networks/MCR-technology"
  },
  {
    classId: "EC001626",
    classGroup: "EG000021",
    artClassDesc: "Base for air termination systems lightning protection",
    artClassVersion: 8,
    label: "EC001626: Base for air termination systems lightning protection"
  },
  {
    classId: "EC001627",
    classGroup: "EG000021",
    artClassDesc: "Remote signalling module for lightning current-/surge arrester",
    artClassVersion: 6,
    label: "EC001627: Remote signalling module for lightning current-/surge arrester"
  },
  {
    classId: "EC001628",
    classGroup: "EG000021",
    artClassDesc: "Driving-in tool for earth rods",
    artClassVersion: 7,
    label: "EC001628: Driving-in tool for earth rods"
  },
  {
    classId: "EC001629",
    classGroup: "EG000041",
    artClassDesc: "Objective for camera/projector",
    artClassVersion: 6,
    label: "EC001629: Objective for camera/projector"
  },
  {
    classId: "EC001630",
    classGroup: "EG000041",
    artClassDesc: "Television",
    artClassVersion: 9,
    label: "EC001630: Television"
  },
  {
    classId: "EC001634",
    classGroup: "EG000041",
    artClassDesc: "Audio/video transmitting system",
    artClassVersion: 6,
    label: "EC001634: Audio/video transmitting system"
  },
  {
    classId: "EC001635",
    classGroup: "EG000041",
    artClassDesc: "Accessories/spare parts for camcorder",
    artClassVersion: 7,
    label: "EC001635: Accessories/spare parts for camcorder"
  },
  {
    classId: "EC001636",
    classGroup: "EG000041",
    artClassDesc: "Accessories/spare parts for car audio",
    artClassVersion: 7,
    label: "EC001636: Accessories/spare parts for car audio"
  },
  {
    classId: "EC001637",
    classGroup: "EG000041",
    artClassDesc: "Accessories/spare parts for digital photo camera",
    artClassVersion: 8,
    label: "EC001637: Accessories/spare parts for digital photo camera"
  },
  {
    classId: "EC001638",
    classGroup: "EG000020",
    artClassDesc: "D0-system protective cover",
    artClassVersion: 5,
    label: "EC001638: D0-system protective cover"
  },
  {
    classId: "EC001641",
    classGroup: "EG000045",
    artClassDesc: "Hour meter",
    artClassVersion: 6,
    label: "EC001641: Hour meter"
  },
  {
    classId: "EC001642",
    classGroup: "EG000020",
    artClassDesc: "D0-system fuse link",
    artClassVersion: 6,
    label: "EC001642: D0-system fuse link"
  },
  {
    classId: "EC001643",
    classGroup: "EG000020",
    artClassDesc: "D0-system screw cap",
    artClassVersion: 5,
    label: "EC001643: D0-system screw cap"
  },
  {
    classId: "EC001644",
    classGroup: "EG000020",
    artClassDesc: "D0-system fuse base",
    artClassVersion: 5,
    label: "EC001644: D0-system fuse base"
  },
  {
    classId: "EC001645",
    classGroup: "EG000062",
    artClassDesc: "Twilight switch for distribution board",
    artClassVersion: 6,
    label: "EC001645: Twilight switch for distribution board"
  },
  {
    classId: "EC001651",
    classGroup: "EG000062",
    artClassDesc: "Mains disconnection relay",
    artClassVersion: 5,
    label: "EC001651: Mains disconnection relay"
  },
  {
    classId: "EC001652",
    classGroup: "EG000062",
    artClassDesc: "Installation relay",
    artClassVersion: 5,
    label: "EC001652: Installation relay"
  },
  {
    classId: "EC001653",
    classGroup: "EG000062",
    artClassDesc: "Installation contactor for distribution board",
    artClassVersion: 8,
    label: "EC001653: Installation contactor for distribution board"
  },
  {
    classId: "EC001655",
    classGroup: "EG000062",
    artClassDesc: "Load shedding relay for distribution board",
    artClassVersion: 5,
    label: "EC001655: Load shedding relay for distribution board"
  },
  {
    classId: "EC001657",
    classGroup: "EG000062",
    artClassDesc: "Indicator light for distribution board",
    artClassVersion: 5,
    label: "EC001657: Indicator light for distribution board"
  },
  {
    classId: "EC001659",
    classGroup: "EG000020",
    artClassDesc: "Accessories/spare parts for Low Voltage HRC fuse",
    artClassVersion: 7,
    label: "EC001659: Accessories/spare parts for Low Voltage HRC fuse"
  },
  {
    classId: "EC001660",
    classGroup: "EG000020",
    artClassDesc: "Accessories/spare parts for Low Voltage HRC fuse bases",
    artClassVersion: 7,
    label: "EC001660: Accessories/spare parts for Low Voltage HRC fuse bases"
  },
  {
    classId: "EC001663",
    classGroup: "EG000062",
    artClassDesc: "Socket outlet for distribution board",
    artClassVersion: 7,
    label: "EC001663: Socket outlet for distribution board"
  },
  {
    classId: "EC001664",
    classGroup: "EG000013",
    artClassDesc: "Socket switch clock",
    artClassVersion: 8,
    label: "EC001664: Socket switch clock"
  },
  {
    classId: "EC001666",
    classGroup: "EG000062",
    artClassDesc: "Temperature controller for distribution board",
    artClassVersion: 6,
    label: "EC001666: Temperature controller for distribution board"
  },
  {
    classId: "EC001667",
    classGroup: "EG000062",
    artClassDesc: "Under voltage monitoring relay for distribution board",
    artClassVersion: 4,
    label: "EC001667: Under voltage monitoring relay for distribution board"
  },
  {
    classId: "EC001668",
    classGroup: "EG000062",
    artClassDesc: "Alarm unit for distribution board",
    artClassVersion: 4,
    label: "EC001668: Alarm unit for distribution board"
  },
  {
    classId: "EC001669",
    classGroup: "EG000062",
    artClassDesc: "Timer relay for distribution board",
    artClassVersion: 5,
    label: "EC001669: Timer relay for distribution board"
  },
  {
    classId: "EC001670",
    classGroup: "EG000062",
    artClassDesc: "Central control system for buildings",
    artClassVersion: 6,
    label: "EC001670: Central control system for buildings"
  },
  {
    classId: "EC001671",
    classGroup: "EG000037",
    artClassDesc: "Cable sharing adapter",
    artClassVersion: 6,
    label: "EC001671: Cable sharing adapter"
  },
  {
    classId: "EC001673",
    classGroup: "EG000038",
    artClassDesc: "Accessories/spare parts for fix telephone",
    artClassVersion: 7,
    label: "EC001673: Accessories/spare parts for fix telephone"
  },
  {
    classId: "EC001674",
    classGroup: "EG000038",
    artClassDesc: "Accessories/spare parts for cordless phone",
    artClassVersion: 7,
    label: "EC001674: Accessories/spare parts for cordless phone"
  },
  {
    classId: "EC001675",
    classGroup: "EG000038",
    artClassDesc: "Accessories/spare parts for fax/printer/MFC",
    artClassVersion: 6,
    label: "EC001675: Accessories/spare parts for fax/printer/MFC"
  },
  {
    classId: "EC001677",
    classGroup: "EG000037",
    artClassDesc: "ISDN distributor",
    artClassVersion: 4,
    label: "EC001677: ISDN distributor"
  },
  {
    classId: "EC001679",
    classGroup: "EG000037",
    artClassDesc: "Cable bending restrictor",
    artClassVersion: 6,
    label: "EC001679: Cable bending restrictor"
  },
  {
    classId: "EC001681",
    classGroup: "EG000037",
    artClassDesc: "Telecommunications patch cord",
    artClassVersion: 6,
    label: "EC001681: Telecommunications patch cord"
  },
  {
    classId: "EC001682",
    classGroup: "EG000037",
    artClassDesc: "Coax patch cord",
    artClassVersion: 7,
    label: "EC001682: Coax patch cord"
  },
  {
    classId: "EC001684",
    classGroup: "EG000038",
    artClassDesc: "Printer/scanner/copier",
    artClassVersion: 7,
    label: "EC001684: Printer/scanner/copier"
  },
  {
    classId: "EC001685",
    classGroup: "EG000037",
    artClassDesc: "Microscope for glass fibre",
    artClassVersion: 7,
    label: "EC001685: Microscope for glass fibre"
  },
  {
    classId: "EC001686",
    classGroup: "EG000037",
    artClassDesc: "Microscope adapter for glass fibre",
    artClassVersion: 4,
    label: "EC001686: Microscope adapter for glass fibre"
  },
  {
    classId: "EC001687",
    classGroup: "EG000037",
    artClassDesc: "Furnace for glass fibre",
    artClassVersion: 5,
    label: "EC001687: Furnace for glass fibre"
  },
  {
    classId: "EC001688",
    classGroup: "EG000037",
    artClassDesc: "Fibre optic polishing mould",
    artClassVersion: 5,
    label: "EC001688: Fibre optic polishing mould"
  },
  {
    classId: "EC001689",
    classGroup: "EG000037",
    artClassDesc: "Fibre optic polishing emery cloth",
    artClassVersion: 5,
    label: "EC001689: Fibre optic polishing emery cloth"
  },
  {
    classId: "EC001690",
    classGroup: "EG000037",
    artClassDesc: "Fibre optic polishing machine",
    artClassVersion: 6,
    label: "EC001690: Fibre optic polishing machine"
  },
  {
    classId: "EC001691",
    classGroup: "EG000038",
    artClassDesc: "Analogue telephone with cord",
    artClassVersion: 5,
    label: "EC001691: Analogue telephone with cord"
  },
  {
    classId: "EC001692",
    classGroup: "EG000038",
    artClassDesc: "Keyboard",
    artClassVersion: 6,
    label: "EC001692: Keyboard"
  },
  {
    classId: "EC001694",
    classGroup: "EG000038",
    artClassDesc: "Fax/printer/all-in-one supplies",
    artClassVersion: 6,
    label: "EC001694: Fax/printer/all-in-one supplies"
  },
  {
    classId: "EC001695",
    classGroup: "EG000038",
    artClassDesc: "VoIP gateway",
    artClassVersion: 5,
    label: "EC001695: VoIP gateway"
  },
  {
    classId: "EC001696",
    classGroup: "EG000038",
    artClassDesc: "VoIP telephone",
    artClassVersion: 4,
    label: "EC001696: VoIP telephone"
  },
  {
    classId: "EC001697",
    classGroup: "EG000037",
    artClassDesc: "Forerun-/trail fibre (fibre optic measuring)",
    artClassVersion: 6,
    label: "EC001697: Forerun-/trail fibre (fibre optic measuring)"
  },
  {
    classId: "EC001698",
    classGroup: "EG000037",
    artClassDesc: "Wireless antenna",
    artClassVersion: 7,
    label: "EC001698: Wireless antenna"
  },
  {
    classId: "EC001699",
    classGroup: "EG000037",
    artClassDesc: "Fibre optic polishing mat",
    artClassVersion: 5,
    label: "EC001699: Fibre optic polishing mat"
  },
  {
    classId: "EC001716",
    classGroup: "EG000027",
    artClassDesc: "High bay luminaire",
    artClassVersion: 9,
    label: "EC001716: High bay luminaire"
  },
  {
    classId: "EC001743",
    classGroup: "EG000027",
    artClassDesc: "Pendant luminaire",
    artClassVersion: 9,
    label: "EC001743: Pendant luminaire"
  },
  {
    classId: "EC001744",
    classGroup: "EG000027",
    artClassDesc: "Downlight/spot/floodlight",
    artClassVersion: 9,
    label: "EC001744: Downlight/spot/floodlight"
  },
  {
    classId: "EC001746",
    classGroup: "EG000055",
    artClassDesc: "Photovoltaics module",
    artClassVersion: 9,
    label: "EC001746: Photovoltaics module"
  },
  {
    classId: "EC001747",
    classGroup: "EG000055",
    artClassDesc: "DC/AC grid inverter",
    artClassVersion: 9,
    label: "EC001747: DC/AC grid inverter"
  },
  {
    classId: "EC001748",
    classGroup: "EG000055",
    artClassDesc: "Photovoltaics charge controller",
    artClassVersion: 6,
    label: "EC001748: Photovoltaics charge controller"
  },
  {
    classId: "EC001749",
    classGroup: "EG000055",
    artClassDesc: "Battery inverter",
    artClassVersion: 7,
    label: "EC001749: Battery inverter"
  },
  {
    classId: "EC001750",
    classGroup: "EG000055",
    artClassDesc: "Photovoltaics complete set",
    artClassVersion: 8,
    label: "EC001750: Photovoltaics complete set"
  },
  {
    classId: "EC001751",
    classGroup: "EG000055",
    artClassDesc: "Photovoltaics roof-/facade fastener",
    artClassVersion: 7,
    label: "EC001751: Photovoltaics roof-/facade fastener"
  },
  {
    classId: "EC001752",
    classGroup: "EG000055",
    artClassDesc: "Photovoltaics mounting system",
    artClassVersion: 8,
    label: "EC001752: Photovoltaics mounting system"
  },
  {
    classId: "EC001753",
    classGroup: "EG000055",
    artClassDesc: "Photovoltaics connecting and mounting element",
    artClassVersion: 8,
    label: "EC001753: Photovoltaics connecting and mounting element"
  },
  {
    classId: "EC001754",
    classGroup: "EG000055",
    artClassDesc: "Solar mounting profile",
    artClassVersion: 8,
    label: "EC001754: Solar mounting profile"
  },
  {
    classId: "EC001755",
    classGroup: "EG000055",
    artClassDesc: "Photovoltaics large display",
    artClassVersion: 7,
    label: "EC001755: Photovoltaics large display"
  },
  {
    classId: "EC001756",
    classGroup: "EG000055",
    artClassDesc: "Photovoltaics data logger",
    artClassVersion: 9,
    label: "EC001756: Photovoltaics data logger"
  },
  {
    classId: "EC001757",
    classGroup: "EG000055",
    artClassDesc: "Solar-battery",
    artClassVersion: 8,
    label: "EC001757: Solar-battery"
  },
  {
    classId: "EC001758",
    classGroup: "EG000054",
    artClassDesc: "Glass break/vibration detector",
    artClassVersion: 6,
    label: "EC001758: Glass break/vibration detector"
  },
  {
    classId: "EC001759",
    classGroup: "EG000054",
    artClassDesc: "Detector for locking surveillance",
    artClassVersion: 6,
    label: "EC001759: Detector for locking surveillance"
  },
  {
    classId: "EC001761",
    classGroup: "EG000054",
    artClassDesc: "Opening detector for danger detection system",
    artClassVersion: 6,
    label: "EC001761: Opening detector for danger detection system"
  },
  {
    classId: "EC001762",
    classGroup: "EG000054",
    artClassDesc: "Movement detector for danger detection system",
    artClassVersion: 6,
    label: "EC001762: Movement detector for danger detection system"
  },
  {
    classId: "EC001763",
    classGroup: "EG000054",
    artClassDesc: "Fire detector",
    artClassVersion: 9,
    label: "EC001763: Fire detector"
  },
  {
    classId: "EC001764",
    classGroup: "EG000054",
    artClassDesc: "Technical detector for danger detection system",
    artClassVersion: 7,
    label: "EC001764: Technical detector for danger detection system"
  },
  {
    classId: "EC001765",
    classGroup: "EG000054",
    artClassDesc: "Manual call point for danger detection system",
    artClassVersion: 8,
    label: "EC001765: Manual call point for danger detection system"
  },
  {
    classId: "EC001766",
    classGroup: "EG000054",
    artClassDesc: "Alarm transfer device",
    artClassVersion: 6,
    label: "EC001766: Alarm transfer device"
  },
  {
    classId: "EC001767",
    classGroup: "EG000054",
    artClassDesc: "Control and display device for danger detection system",
    artClassVersion: 7,
    label: "EC001767: Control and display device for danger detection system"
  },
  {
    classId: "EC001768",
    classGroup: "EG000054",
    artClassDesc: "Door locking central station",
    artClassVersion: 5,
    label: "EC001768: Door locking central station"
  },
  {
    classId: "EC001769",
    classGroup: "EG000054",
    artClassDesc: "Holding magnet for door locking system",
    artClassVersion: 8,
    label: "EC001769: Holding magnet for door locking system"
  },
  {
    classId: "EC001770",
    classGroup: "EG000054",
    artClassDesc: "Access control system",
    artClassVersion: 8,
    label: "EC001770: Access control system"
  },
  {
    classId: "EC001771",
    classGroup: "EG000054",
    artClassDesc: "Electronic data medium for switchgear for danger detection system",
    artClassVersion: 7,
    label: "EC001771: Electronic data medium for switchgear for danger detection system"
  },
  {
    classId: "EC001772",
    classGroup: "EG000054",
    artClassDesc: "Matrix memory for video surveillance system",
    artClassVersion: 6,
    label: "EC001772: Matrix memory for video surveillance system"
  },
  {
    classId: "EC001773",
    classGroup: "EG000054",
    artClassDesc: "Protective housing for surveillance camera",
    artClassVersion: 6,
    label: "EC001773: Protective housing for surveillance camera"
  },
  {
    classId: "EC001774",
    classGroup: "EG000054",
    artClassDesc: "Signal converter for surveillance system",
    artClassVersion: 7,
    label: "EC001774: Signal converter for surveillance system"
  },
  {
    classId: "EC001775",
    classGroup: "EG000054",
    artClassDesc: "Control panel for escape route",
    artClassVersion: 6,
    label: "EC001775: Control panel for escape route"
  },
  {
    classId: "EC001776",
    classGroup: "EG000054",
    artClassDesc: "Lens for surveillance camera",
    artClassVersion: 6,
    label: "EC001776: Lens for surveillance camera"
  },
  {
    classId: "EC001777",
    classGroup: "EG000060",
    artClassDesc: "Warning/signing plate",
    artClassVersion: 8,
    label: "EC001777: Warning/signing plate"
  },
  {
    classId: "EC001781",
    classGroup: "EG000041",
    artClassDesc: "Power supply consumer electronics",
    artClassVersion: 9,
    label: "EC001781: Power supply consumer electronics"
  },
  {
    classId: "EC001790",
    classGroup: "EG000048",
    artClassDesc: "Sealing insert for built-in installation box",
    artClassVersion: 4,
    label: "EC001790: Sealing insert for built-in installation box"
  },
  {
    classId: "EC001793",
    classGroup: "EG000048",
    artClassDesc: "Separation plate for junction box",
    artClassVersion: 4,
    label: "EC001793: Separation plate for junction box"
  },
  {
    classId: "EC001794",
    classGroup: "EG000048",
    artClassDesc: "Equipment carrier for insulated walls",
    artClassVersion: 4,
    label: "EC001794: Equipment carrier for insulated walls"
  },
  {
    classId: "EC001795",
    classGroup: "EG000048",
    artClassDesc: "Wall-ceiling transition for concrete construction",
    artClassVersion: 5,
    label: "EC001795: Wall-ceiling transition for concrete construction"
  },
  {
    classId: "EC001796",
    classGroup: "EG000048",
    artClassDesc: "Junction box for ceiling luminaire",
    artClassVersion: 6,
    label: "EC001796: Junction box for ceiling luminaire"
  },
  {
    classId: "EC001817",
    classGroup: "EG000026",
    artClassDesc: "Colour sensor",
    artClassVersion: 7,
    label: "EC001817: Colour sensor"
  },
  {
    classId: "EC001818",
    classGroup: "EG000026",
    artClassDesc: "Inductive distance sensor",
    artClassVersion: 6,
    label: "EC001818: Inductive distance sensor"
  },
  {
    classId: "EC001820",
    classGroup: "EG000026",
    artClassDesc: "Contrast sensor",
    artClassVersion: 6,
    label: "EC001820: Contrast sensor"
  },
  {
    classId: "EC001821",
    classGroup: "EG000026",
    artClassDesc: "Diffuse reflective photoelectric sensor",
    artClassVersion: 6,
    label: "EC001821: Diffuse reflective photoelectric sensor"
  },
  {
    classId: "EC001822",
    classGroup: "EG000026",
    artClassDesc: "Luminescence sensor",
    artClassVersion: 6,
    label: "EC001822: Luminescence sensor"
  },
  {
    classId: "EC001824",
    classGroup: "EG000026",
    artClassDesc: "Multilayer test sensor",
    artClassVersion: 6,
    label: "EC001824: Multilayer test sensor"
  },
  {
    classId: "EC001825",
    classGroup: "EG000026",
    artClassDesc: "Optical distance sensor",
    artClassVersion: 6,
    label: "EC001825: Optical distance sensor"
  },
  {
    classId: "EC001829",
    classGroup: "EG000026",
    artClassDesc: "Position switch modular",
    artClassVersion: 7,
    label: "EC001829: Position switch modular"
  },
  {
    classId: "EC001831",
    classGroup: "EG000026",
    artClassDesc: "Single beam light curtain",
    artClassVersion: 6,
    label: "EC001831: Single beam light curtain"
  },
  {
    classId: "EC001832",
    classGroup: "EG000026",
    artClassDesc: "Multiple beam light curtain",
    artClassVersion: 4,
    label: "EC001832: Multiple beam light curtain"
  },
  {
    classId: "EC001846",
    classGroup: "EG000026",
    artClassDesc: "Ultrasonic distance sensor",
    artClassVersion: 6,
    label: "EC001846: Ultrasonic distance sensor"
  },
  {
    classId: "EC001847",
    classGroup: "EG000026",
    artClassDesc: "Ultrasonic single beam reflex light curtain",
    artClassVersion: 6,
    label: "EC001847: Ultrasonic single beam reflex light curtain"
  },
  {
    classId: "EC001848",
    classGroup: "EG000026",
    artClassDesc: "Ultrasonic reflex light curtain",
    artClassVersion: 6,
    label: "EC001848: Ultrasonic reflex light curtain"
  },
  {
    classId: "EC001849",
    classGroup: "EG000026",
    artClassDesc: "Ultrasonic proximity switch",
    artClassVersion: 7,
    label: "EC001849: Ultrasonic proximity switch"
  },
  {
    classId: "EC001851",
    classGroup: "EG000034",
    artClassDesc: "Electric motor",
    artClassVersion: 7,
    label: "EC001851: Electric motor"
  },
  {
    classId: "EC001852",
    classGroup: "EG000026",
    artClassDesc: "Way sensor",
    artClassVersion: 4,
    label: "EC001852: Way sensor"
  },
  {
    classId: "EC001855",
    classGroup: "EG000026",
    artClassDesc: "Sensor-actuator patch cord",
    artClassVersion: 8,
    label: "EC001855: Sensor-actuator patch cord"
  },
  {
    classId: "EC001857",
    classGroup: "EG000017",
    artClassDesc: "Frequency converter =< 1 kV",
    artClassVersion: 8,
    label: "EC001857: Frequency converter =< 1 kV"
  },
  {
    classId: "EC001858",
    classGroup: "EG000039",
    artClassDesc: "Wine fridge",
    artClassVersion: 8,
    label: "EC001858: Wine fridge"
  },
  {
    classId: "EC001859",
    classGroup: "EG000039",
    artClassDesc: "Baking oven",
    artClassVersion: 9,
    label: "EC001859: Baking oven"
  },
  {
    classId: "EC001860",
    classGroup: "EG000039",
    artClassDesc: "Heater for cutlery",
    artClassVersion: 6,
    label: "EC001860: Heater for cutlery"
  },
  {
    classId: "EC001868",
    classGroup: "EG000043",
    artClassDesc: "Hoover outlet",
    artClassVersion: 4,
    label: "EC001868: Hoover outlet"
  },
  {
    classId: "EC001873",
    classGroup: "EG000039",
    artClassDesc: "Built-under mounting set for domestic appliances",
    artClassVersion: 4,
    label: "EC001873: Built-under mounting set for domestic appliances"
  },
  {
    classId: "EC001874",
    classGroup: "EG000039",
    artClassDesc: "Stacking kit wash-/dry column",
    artClassVersion: 4,
    label: "EC001874: Stacking kit wash-/dry column"
  },
  {
    classId: "EC001875",
    classGroup: "EG000039",
    artClassDesc: "Enclosure/cabinet for built-in domestic appliances",
    artClassVersion: 5,
    label: "EC001875: Enclosure/cabinet for built-in domestic appliances"
  },
  {
    classId: "EC001876",
    classGroup: "EG000039",
    artClassDesc: "Built-under base for domestic appliances",
    artClassVersion: 6,
    label: "EC001876: Built-under base for domestic appliances"
  },
  {
    classId: "EC001877",
    classGroup: "EG017960",
    artClassDesc: "Sink cabinet",
    artClassVersion: 6,
    label: "EC001877: Sink cabinet"
  },
  {
    classId: "EC001878",
    classGroup: "EG000039",
    artClassDesc: "Portable cabinet for built-in domestic appliances",
    artClassVersion: 5,
    label: "EC001878: Portable cabinet for built-in domestic appliances"
  },
  {
    classId: "EC001879",
    classGroup: "EG000039",
    artClassDesc: "Accessories/spare parts for cooking device/baking device",
    artClassVersion: 8,
    label: "EC001879: Accessories/spare parts for cooking device/baking device"
  },
  {
    classId: "EC001880",
    classGroup: "EG000039",
    artClassDesc: "Storage basket for freezer",
    artClassVersion: 5,
    label: "EC001880: Storage basket for freezer"
  },
  {
    classId: "EC001881",
    classGroup: "EG000039",
    artClassDesc: "Accessories/spare parts for dishwasher, washing and drying",
    artClassVersion: 7,
    label: "EC001881: Accessories/spare parts for dishwasher, washing and drying"
  },
  {
    classId: "EC001882",
    classGroup: "EG000039",
    artClassDesc: "Waste separation system",
    artClassVersion: 6,
    label: "EC001882: Waste separation system"
  },
  {
    classId: "EC001883",
    classGroup: "EG000043",
    artClassDesc: "Stone set for storage heater",
    artClassVersion: 6,
    label: "EC001883: Stone set for storage heater"
  },
  {
    classId: "EC001884",
    classGroup: "EG000043",
    artClassDesc: "Sauna furnace",
    artClassVersion: 4,
    label: "EC001884: Sauna furnace"
  },
  {
    classId: "EC001885",
    classGroup: "EG000043",
    artClassDesc: "Accessories/spare parts for sauna furnace",
    artClassVersion: 6,
    label: "EC001885: Accessories/spare parts for sauna furnace"
  },
  {
    classId: "EC001889",
    classGroup: "EG000044",
    artClassDesc: "Oscilloscope",
    artClassVersion: 8,
    label: "EC001889: Oscilloscope"
  },
  {
    classId: "EC001890",
    classGroup: "EG000044",
    artClassDesc: "Process calibrator",
    artClassVersion: 6,
    label: "EC001890: Process calibrator"
  },
  {
    classId: "EC001891",
    classGroup: "EG000051",
    artClassDesc: "Sabre saw (battery)",
    artClassVersion: 7,
    label: "EC001891: Sabre saw (battery)"
  },
  {
    classId: "EC001892",
    classGroup: "EG000015",
    artClassDesc: "Distributor assembly with protection against contact",
    artClassVersion: 4,
    label: "EC001892: Distributor assembly with protection against contact"
  },
  {
    classId: "EC001893",
    classGroup: "EG000015",
    artClassDesc: "Distributor assembly for device surface mounting",
    artClassVersion: 4,
    label: "EC001893: Distributor assembly for device surface mounting"
  },
  {
    classId: "EC001894",
    classGroup: "EG000015",
    artClassDesc: "Distributor assembly terminal blocks",
    artClassVersion: 4,
    label: "EC001894: Distributor assembly terminal blocks"
  },
  {
    classId: "EC001896",
    classGroup: "EG000015",
    artClassDesc: "Distributor assembly busbars",
    artClassVersion: 5,
    label: "EC001896: Distributor assembly busbars"
  },
  {
    classId: "EC001897",
    classGroup: "EG000015",
    artClassDesc: "Distributor assembly switch",
    artClassVersion: 4,
    label: "EC001897: Distributor assembly switch"
  },
  {
    classId: "EC001898",
    classGroup: "EG000015",
    artClassDesc: "Wiring band",
    artClassVersion: 5,
    label: "EC001898: Wiring band"
  },
  {
    classId: "EC001899",
    classGroup: "EG000060",
    artClassDesc: "Touch-up stick/spray",
    artClassVersion: 8,
    label: "EC001899: Touch-up stick/spray"
  },
  {
    classId: "EC001900",
    classGroup: "EG000015",
    artClassDesc: "Busbar system for distribution boards",
    artClassVersion: 6,
    label: "EC001900: Busbar system for distribution boards"
  },
  {
    classId: "EC001901",
    classGroup: "EG000005",
    artClassDesc: "Subfloor installation duct flush floor open",
    artClassVersion: 7,
    label: "EC001901: Subfloor installation duct flush floor open"
  },
  {
    classId: "EC001902",
    classGroup: "EG000005",
    artClassDesc: "Subfloor installation duct flush floor closed",
    artClassVersion: 6,
    label: "EC001902: Subfloor installation duct flush floor closed"
  },
  {
    classId: "EC001903",
    classGroup: "EG000005",
    artClassDesc: "Junction box insert for subfloor installation",
    artClassVersion: 6,
    label: "EC001903: Junction box insert for subfloor installation"
  },
  {
    classId: "EC001905",
    classGroup: "EG000005",
    artClassDesc: "Duct outlet for subfloor system",
    artClassVersion: 6,
    label: "EC001905: Duct outlet for subfloor system"
  },
  {
    classId: "EC001906",
    classGroup: "EG000005",
    artClassDesc: "Mounting cover for subfloor draw-in box",
    artClassVersion: 5,
    label: "EC001906: Mounting cover for subfloor draw-in box"
  },
  {
    classId: "EC001907",
    classGroup: "EG000005",
    artClassDesc: "Stacking frame for subfloor box",
    artClassVersion: 5,
    label: "EC001907: Stacking frame for subfloor box"
  },
  {
    classId: "EC001908",
    classGroup: "EG000005",
    artClassDesc: "Cassette frame with cassette",
    artClassVersion: 5,
    label: "EC001908: Cassette frame with cassette"
  },
  {
    classId: "EC001912",
    classGroup: "EG000005",
    artClassDesc: "Cover for duct outlet",
    artClassVersion: 4,
    label: "EC001912: Cover for duct outlet"
  },
  {
    classId: "EC001913",
    classGroup: "EG000005",
    artClassDesc: "Blind plate for duct exit",
    artClassVersion: 5,
    label: "EC001913: Blind plate for duct exit"
  },
  {
    classId: "EC001914",
    classGroup: "EG000005",
    artClassDesc: "Tube cable outlet",
    artClassVersion: 5,
    label: "EC001914: Tube cable outlet"
  },
  {
    classId: "EC001915",
    classGroup: "EG000005",
    artClassDesc: "Outlet box for subfloor installation",
    artClassVersion: 5,
    label: "EC001915: Outlet box for subfloor installation"
  },
  {
    classId: "EC001916",
    classGroup: "EG000005",
    artClassDesc: "Raised floor distribution system",
    artClassVersion: 4,
    label: "EC001916: Raised floor distribution system"
  },
  {
    classId: "EC001918",
    classGroup: "EG000005",
    artClassDesc: "Pre-wired mounting box power current",
    artClassVersion: 7,
    label: "EC001918: Pre-wired mounting box power current"
  },
  {
    classId: "EC001920",
    classGroup: "EG000005",
    artClassDesc: "Partition piece for mounting box",
    artClassVersion: 5,
    label: "EC001920: Partition piece for mounting box"
  },
  {
    classId: "EC001922",
    classGroup: "EG000009",
    artClassDesc: "Strain relief for box/housing",
    artClassVersion: 6,
    label: "EC001922: Strain relief for box/housing"
  },
  {
    classId: "EC001923",
    classGroup: "EG000005",
    artClassDesc: "Slot in cable extension for installation units",
    artClassVersion: 5,
    label: "EC001923: Slot in cable extension for installation units"
  },
  {
    classId: "EC001925",
    classGroup: "EG000005",
    artClassDesc: "Extension unit for subfloor installation duct flush floor open",
    artClassVersion: 6,
    label: "EC001925: Extension unit for subfloor installation duct flush floor open"
  },
  {
    classId: "EC001926",
    classGroup: "EG000005",
    artClassDesc: "Junction unit for subfloor installation duct flush floor open",
    artClassVersion: 5,
    label: "EC001926: Junction unit for subfloor installation duct flush floor open"
  },
  {
    classId: "EC001929",
    classGroup: "EG000005",
    artClassDesc: "Support strip for cover for subfloor installation ducting systems",
    artClassVersion: 6,
    label: "EC001929: Support strip for cover for subfloor installation ducting systems"
  },
  {
    classId: "EC001931",
    classGroup: "EG000005",
    artClassDesc: "Floor guide profile flush for subfloor installation duct flush floor",
    artClassVersion: 7,
    label: "EC001931: Floor guide profile flush for subfloor installation duct flush floor"
  },
  {
    classId: "EC001932",
    classGroup: "EG000005",
    artClassDesc: "Cover joint seal for subfloor installation duct flush floor",
    artClassVersion: 6,
    label: "EC001932: Cover joint seal for subfloor installation duct flush floor"
  },
  {
    classId: "EC001935",
    classGroup: "EG000005",
    artClassDesc: "Earth conductor clamp for subfloor installation ducting",
    artClassVersion: 6,
    label: "EC001935: Earth conductor clamp for subfloor installation ducting"
  },
  {
    classId: "EC001936",
    classGroup: "EG000006",
    artClassDesc: "Cable clip for installation duct",
    artClassVersion: 6,
    label: "EC001936: Cable clip for installation duct"
  },
  {
    classId: "EC001937",
    classGroup: "EG000006",
    artClassDesc: "Cable protection for installation duct",
    artClassVersion: 7,
    label: "EC001937: Cable protection for installation duct"
  },
  {
    classId: "EC001938",
    classGroup: "EG000006",
    artClassDesc: "Wall duct",
    artClassVersion: 8,
    label: "EC001938: Wall duct"
  },
  {
    classId: "EC001939",
    classGroup: "EG000006",
    artClassDesc: "Tee for wall duct",
    artClassVersion: 6,
    label: "EC001939: Tee for wall duct"
  },
  {
    classId: "EC001940",
    classGroup: "EG000006",
    artClassDesc: "Transition piece for skirting duct",
    artClassVersion: 7,
    label: "EC001940: Transition piece for skirting duct"
  },
  {
    classId: "EC001941",
    classGroup: "EG000008",
    artClassDesc: "Installation column",
    artClassVersion: 8,
    label: "EC001941: Installation column"
  },
  {
    classId: "EC001942",
    classGroup: "EG000006",
    artClassDesc: "Corner duct",
    artClassVersion: 6,
    label: "EC001942: Corner duct"
  },
  {
    classId: "EC001943",
    classGroup: "EG000006",
    artClassDesc: "Inner corner for corner duct",
    artClassVersion: 6,
    label: "EC001943: Inner corner for corner duct"
  },
  {
    classId: "EC001946",
    classGroup: "EG000006",
    artClassDesc: "End piece for corner duct",
    artClassVersion: 6,
    label: "EC001946: End piece for corner duct"
  },
  {
    classId: "EC001947",
    classGroup: "EG000006",
    artClassDesc: "Tee for corner duct",
    artClassVersion: 6,
    label: "EC001947: Tee for corner duct"
  },
  {
    classId: "EC001948",
    classGroup: "EG000006",
    artClassDesc: "Transition piece for corner duct/trunking",
    artClassVersion: 6,
    label: "EC001948: Transition piece for corner duct/trunking"
  },
  {
    classId: "EC001949",
    classGroup: "EG000004",
    artClassDesc: "Bend for mesh cable tray",
    artClassVersion: 7,
    label: "EC001949: Bend for mesh cable tray"
  },
  {
    classId: "EC001950",
    classGroup: "EG000004",
    artClassDesc: "Insert plate for cable ladder",
    artClassVersion: 7,
    label: "EC001950: Insert plate for cable ladder"
  },
  {
    classId: "EC001951",
    classGroup: "EG000027",
    artClassDesc: "Low voltage cable system",
    artClassVersion: 8,
    label: "EC001951: Low voltage cable system"
  },
  {
    classId: "EC001952",
    classGroup: "EG000027",
    artClassDesc: "Explosion proof luminaire fixed mounting",
    artClassVersion: 9,
    label: "EC001952: Explosion proof luminaire fixed mounting"
  },
  {
    classId: "EC001955",
    classGroup: "EG000027",
    artClassDesc: "Sign luminaire",
    artClassVersion: 8,
    label: "EC001955: Sign luminaire"
  },
  {
    classId: "EC001957",
    classGroup: "EG000027",
    artClassDesc: "Emergency luminaire",
    artClassVersion: 9,
    label: "EC001957: Emergency luminaire"
  },
  {
    classId: "EC001958",
    classGroup: "EG000028",
    artClassDesc: "Airport lighting lamp",
    artClassVersion: 7,
    label: "EC001958: Airport lighting lamp"
  },
  {
    classId: "EC001959",
    classGroup: "EG000028",
    artClassDesc: "LED-lamp/Multi-LED",
    artClassVersion: 9,
    label: "EC001959: LED-lamp/Multi-LED"
  },
  {
    classId: "EC001960",
    classGroup: "EG000028",
    artClassDesc: "Neon lamp",
    artClassVersion: 7,
    label: "EC001960: Neon lamp"
  },
  {
    classId: "EC001961",
    classGroup: "EG000029",
    artClassDesc: "Ignition transformer",
    artClassVersion: 6,
    label: "EC001961: Ignition transformer"
  },
  {
    classId: "EC002005",
    classGroup: "EG000030",
    artClassDesc: "Test device for emergency power luminaires",
    artClassVersion: 5,
    label: "EC002005: Test device for emergency power luminaires"
  },
  {
    classId: "EC002006",
    classGroup: "EG000011",
    artClassDesc: "Front panel/patch panel empty (enclosure/cabinet)",
    artClassVersion: 5,
    label: "EC002006: Front panel/patch panel empty (enclosure/cabinet)"
  },
  {
    classId: "EC002009",
    classGroup: "EG000011",
    artClassDesc: "Condensate drain (enclosure/cabinet)",
    artClassVersion: 5,
    label: "EC002009: Condensate drain (enclosure/cabinet)"
  },
  {
    classId: "EC002018",
    classGroup: "EG000012",
    artClassDesc: "Component plug terminal block",
    artClassVersion: 5,
    label: "EC002018: Component plug terminal block"
  },
  {
    classId: "EC002019",
    classGroup: "EG000017",
    artClassDesc: "Connection vane/phase spreader",
    artClassVersion: 4,
    label: "EC002019: Connection vane/phase spreader"
  },
  {
    classId: "EC002020",
    classGroup: "EG000012",
    artClassDesc: "Shield connection clamp",
    artClassVersion: 6,
    label: "EC002020: Shield connection clamp"
  },
  {
    classId: "EC002021",
    classGroup: "EG000012",
    artClassDesc: "Terminal block connector",
    artClassVersion: 6,
    label: "EC002021: Terminal block connector"
  },
  {
    classId: "EC002023",
    classGroup: "EG000017",
    artClassDesc: "Blind cover for command devices",
    artClassVersion: 5,
    label: "EC002023: Blind cover for command devices"
  },
  {
    classId: "EC002024",
    classGroup: "EG000017",
    artClassDesc: "Accessories/spare parts for command devices",
    artClassVersion: 7,
    label: "EC002024: Accessories/spare parts for command devices"
  },
  {
    classId: "EC002025",
    classGroup: "EG000017",
    artClassDesc: "Accessories/spare parts for frequency controller",
    artClassVersion: 7,
    label: "EC002025: Accessories/spare parts for frequency controller"
  },
  {
    classId: "EC002026",
    classGroup: "EG000017",
    artClassDesc: "Accessories/spare parts for emergency stop pull cord switch",
    artClassVersion: 7,
    label: "EC002026: Accessories/spare parts for emergency stop pull cord switch"
  },
  {
    classId: "EC002027",
    classGroup: "EG000017",
    artClassDesc: "Accessories/spare parts for overload protection device",
    artClassVersion: 6,
    label: "EC002027: Accessories/spare parts for overload protection device"
  },
  {
    classId: "EC002029",
    classGroup: "EG000017",
    artClassDesc: "Foot and palm switch head element",
    artClassVersion: 4,
    label: "EC002029: Foot and palm switch head element"
  },
  {
    classId: "EC002031",
    classGroup: "EG000017",
    artClassDesc: "Foot and palm switch enclosure base",
    artClassVersion: 6,
    label: "EC002031: Foot and palm switch enclosure base"
  },
  {
    classId: "EC002032",
    classGroup: "EG000017",
    artClassDesc: "Special tool for control circuit devices",
    artClassVersion: 6,
    label: "EC002032: Special tool for control circuit devices"
  },
  {
    classId: "EC002033",
    classGroup: "EG000017",
    artClassDesc: "Emergency stop pull cord switch",
    artClassVersion: 6,
    label: "EC002033: Emergency stop pull cord switch"
  },
  {
    classId: "EC002034",
    classGroup: "EG000017",
    artClassDesc: "Emergency stop complete",
    artClassVersion: 8,
    label: "EC002034: Emergency stop complete"
  },
  {
    classId: "EC002035",
    classGroup: "EG000017",
    artClassDesc: "Phase separation plate for power circuit breaker",
    artClassVersion: 5,
    label: "EC002035: Phase separation plate for power circuit breaker"
  },
  {
    classId: "EC002036",
    classGroup: "EG000017",
    artClassDesc: "Front bezel pushbutton",
    artClassVersion: 5,
    label: "EC002036: Front bezel pushbutton"
  },
  {
    classId: "EC002037",
    classGroup: "EG000017",
    artClassDesc: "Variable-voltage transformer",
    artClassVersion: 6,
    label: "EC002037: Variable-voltage transformer"
  },
  {
    classId: "EC002040",
    classGroup: "EG000017",
    artClassDesc: "Protective cap for command devices",
    artClassVersion: 6,
    label: "EC002040: Protective cap for command devices"
  },
  {
    classId: "EC002043",
    classGroup: "EG000017",
    artClassDesc: "Chassis part power circuit breaker",
    artClassVersion: 4,
    label: "EC002043: Chassis part power circuit breaker"
  },
  {
    classId: "EC002045",
    classGroup: "EG000017",
    artClassDesc: "Voltage stabilizer",
    artClassVersion: 7,
    label: "EC002045: Voltage stabilizer"
  },
  {
    classId: "EC002046",
    classGroup: "EG000017",
    artClassDesc: "Voltage measuring transformer",
    artClassVersion: 7,
    label: "EC002046: Voltage measuring transformer"
  },
  {
    classId: "EC002048",
    classGroup: "EG000017",
    artClassDesc: "Current transformer",
    artClassVersion: 8,
    label: "EC002048: Current transformer"
  },
  {
    classId: "EC002049",
    classGroup: "EG000017",
    artClassDesc: "Modification set for power circuit breaker",
    artClassVersion: 4,
    label: "EC002049: Modification set for power circuit breaker"
  },
  {
    classId: "EC002050",
    classGroup: "EG000017",
    artClassDesc: "Wiring set for power circuit breaker",
    artClassVersion: 5,
    label: "EC002050: Wiring set for power circuit breaker"
  },
  {
    classId: "EC002051",
    classGroup: "EG000017",
    artClassDesc: "Padlock barrier for switch",
    artClassVersion: 6,
    label: "EC002051: Padlock barrier for switch"
  },
  {
    classId: "EC002052",
    classGroup: "EG000017",
    artClassDesc: "Time delay under voltage coil",
    artClassVersion: 4,
    label: "EC002052: Time delay under voltage coil"
  },
  {
    classId: "EC002053",
    classGroup: "EG000019",
    artClassDesc: "Power direction relay",
    artClassVersion: 5,
    label: "EC002053: Power direction relay"
  },
  {
    classId: "EC002055",
    classGroup: "EG000019",
    artClassDesc: "Solid state relay",
    artClassVersion: 8,
    label: "EC002055: Solid state relay"
  },
  {
    classId: "EC002056",
    classGroup: "EG000019",
    artClassDesc: "Cooling element for solid state relay",
    artClassVersion: 4,
    label: "EC002056: Cooling element for solid state relay"
  },
  {
    classId: "EC002059",
    classGroup: "EG000019",
    artClassDesc: "Synchronisation relay",
    artClassVersion: 5,
    label: "EC002059: Synchronisation relay"
  },
  {
    classId: "EC002060",
    classGroup: "EG000019",
    artClassDesc: "Timer block",
    artClassVersion: 5,
    label: "EC002060: Timer block"
  },
  {
    classId: "EC002063",
    classGroup: "EG000043",
    artClassDesc: "Accessories/spare parts for heating cables",
    artClassVersion: 7,
    label: "EC002063: Accessories/spare parts for heating cables"
  },
  {
    classId: "EC002065",
    classGroup: "EG000037",
    artClassDesc: "End cap for fibre optic duct",
    artClassVersion: 6,
    label: "EC002065: End cap for fibre optic duct"
  },
  {
    classId: "EC002066",
    classGroup: "EG000037",
    artClassDesc: "Accessories/spare parts for splice cassette",
    artClassVersion: 6,
    label: "EC002066: Accessories/spare parts for splice cassette"
  },
  {
    classId: "EC002067",
    classGroup: "EG000037",
    artClassDesc: "Fibre optic distribution box",
    artClassVersion: 6,
    label: "EC002067: Fibre optic distribution box"
  },
  {
    classId: "EC002076",
    classGroup: "EG000041",
    artClassDesc: "Microphone rod",
    artClassVersion: 4,
    label: "EC002076: Microphone rod"
  },
  {
    classId: "EC002077",
    classGroup: "EG000041",
    artClassDesc: "Tripod microphone",
    artClassVersion: 4,
    label: "EC002077: Tripod microphone"
  },
  {
    classId: "EC002078",
    classGroup: "EG000044",
    artClassDesc: "Earth resistance meter",
    artClassVersion: 7,
    label: "EC002078: Earth resistance meter"
  },
  {
    classId: "EC002079",
    classGroup: "EG000044",
    artClassDesc: "Earth leakage switch tester",
    artClassVersion: 5,
    label: "EC002079: Earth leakage switch tester"
  },
  {
    classId: "EC002080",
    classGroup: "EG000044",
    artClassDesc: "Cable fault locator",
    artClassVersion: 5,
    label: "EC002080: Cable fault locator"
  },
  {
    classId: "EC002081",
    classGroup: "EG000044",
    artClassDesc: "Temperature probe",
    artClassVersion: 5,
    label: "EC002081: Temperature probe"
  },
  {
    classId: "EC002082",
    classGroup: "EG000046",
    artClassDesc: "Dust-coat",
    artClassVersion: 6,
    label: "EC002082: Dust-coat"
  },
  {
    classId: "EC002083",
    classGroup: "EG000046",
    artClassDesc: "Combi vest",
    artClassVersion: 8,
    label: "EC002083: Combi vest"
  },
  {
    classId: "EC002084",
    classGroup: "EG000046",
    artClassDesc: "Breathing mask",
    artClassVersion: 7,
    label: "EC002084: Breathing mask"
  },
  {
    classId: "EC002085",
    classGroup: "EG000046",
    artClassDesc: "Working trousers",
    artClassVersion: 7,
    label: "EC002085: Working trousers"
  },
  {
    classId: "EC002086",
    classGroup: "EG000046",
    artClassDesc: "Aid for electrical engineering",
    artClassVersion: 5,
    label: "EC002086: Aid for electrical engineering"
  },
  {
    classId: "EC002087",
    classGroup: "EG000046",
    artClassDesc: "Earplug",
    artClassVersion: 7,
    label: "EC002087: Earplug"
  },
  {
    classId: "EC002088",
    classGroup: "EG000046",
    artClassDesc: "Shirt",
    artClassVersion: 7,
    label: "EC002088: Shirt"
  },
  {
    classId: "EC002089",
    classGroup: "EG000046",
    artClassDesc: "Heat resisting blanket",
    artClassVersion: 6,
    label: "EC002089: Heat resisting blanket"
  },
  {
    classId: "EC002090",
    classGroup: "EG000046",
    artClassDesc: "Electrical insulation mat/basin",
    artClassVersion: 8,
    label: "EC002090: Electrical insulation mat/basin"
  },
  {
    classId: "EC002091",
    classGroup: "EG000046",
    artClassDesc: "Jacket/coat",
    artClassVersion: 6,
    label: "EC002091: Jacket/coat"
  },
  {
    classId: "EC002092",
    classGroup: "EG000046",
    artClassDesc: "Ear muff",
    artClassVersion: 8,
    label: "EC002092: Ear muff"
  },
  {
    classId: "EC002093",
    classGroup: "EG000046",
    artClassDesc: "Leg/knee protection",
    artClassVersion: 8,
    label: "EC002093: Leg/knee protection"
  },
  {
    classId: "EC002095",
    classGroup: "EG000046",
    artClassDesc: "Overall/safety suit",
    artClassVersion: 8,
    label: "EC002095: Overall/safety suit"
  },
  {
    classId: "EC002097",
    classGroup: "EG000046",
    artClassDesc: "Pullover",
    artClassVersion: 7,
    label: "EC002097: Pullover"
  },
  {
    classId: "EC002098",
    classGroup: "EG000046",
    artClassDesc: "Protective glasses",
    artClassVersion: 7,
    label: "EC002098: Protective glasses"
  },
  {
    classId: "EC002099",
    classGroup: "EG000046",
    artClassDesc: "Protective glove",
    artClassVersion: 9,
    label: "EC002099: Protective glove"
  },
  {
    classId: "EC002100",
    classGroup: "EG000046",
    artClassDesc: "Protective helmet",
    artClassVersion: 8,
    label: "EC002100: Protective helmet"
  },
  {
    classId: "EC002103",
    classGroup: "EG000046",
    artClassDesc: "Facial shield",
    artClassVersion: 9,
    label: "EC002103: Facial shield"
  },
  {
    classId: "EC002104",
    classGroup: "EG000046",
    artClassDesc: "Footwear",
    artClassVersion: 7,
    label: "EC002104: Footwear"
  },
  {
    classId: "EC002107",
    classGroup: "EG000046",
    artClassDesc: "Dust-mask",
    artClassVersion: 8,
    label: "EC002107: Dust-mask"
  },
  {
    classId: "EC002108",
    classGroup: "EG000046",
    artClassDesc: "Inner glove",
    artClassVersion: 4,
    label: "EC002108: Inner glove"
  },
  {
    classId: "EC002109",
    classGroup: "EG000050",
    artClassDesc: "Wire stripper pliers",
    artClassVersion: 7,
    label: "EC002109: Wire stripper pliers"
  },
  {
    classId: "EC002110",
    classGroup: "EG000050",
    artClassDesc: "Pulley puller",
    artClassVersion: 6,
    label: "EC002110: Pulley puller"
  },
  {
    classId: "EC002111",
    classGroup: "EG000050",
    artClassDesc: "Square",
    artClassVersion: 6,
    label: "EC002111: Square"
  },
  {
    classId: "EC002112",
    classGroup: "EG000050",
    artClassDesc: "Axe",
    artClassVersion: 6,
    label: "EC002112: Axe"
  },
  {
    classId: "EC002113",
    classGroup: "EG000050",
    artClassDesc: "Drawing cover",
    artClassVersion: 4,
    label: "EC002113: Drawing cover"
  },
  {
    classId: "EC002118",
    classGroup: "EG000050",
    artClassDesc: "Bit holder",
    artClassVersion: 6,
    label: "EC002118: Bit holder"
  },
  {
    classId: "EC002119",
    classGroup: "EG000050",
    artClassDesc: "Bit screwdriver",
    artClassVersion: 7,
    label: "EC002119: Bit screwdriver"
  },
  {
    classId: "EC002120",
    classGroup: "EG000050",
    artClassDesc: "Bit set",
    artClassVersion: 9,
    label: "EC002120: Bit set"
  },
  {
    classId: "EC002121",
    classGroup: "EG000050",
    artClassDesc: "Hole punch set",
    artClassVersion: 7,
    label: "EC002121: Hole punch set"
  },
  {
    classId: "EC002122",
    classGroup: "EG000050",
    artClassDesc: "Snips",
    artClassVersion: 7,
    label: "EC002122: Snips"
  },
  {
    classId: "EC002123",
    classGroup: "EG000050",
    artClassDesc: "Lead pencil",
    artClassVersion: 5,
    label: "EC002123: Lead pencil"
  },
  {
    classId: "EC002124",
    classGroup: "EG000050",
    artClassDesc: "Draper hand riveter",
    artClassVersion: 5,
    label: "EC002124: Draper hand riveter"
  },
  {
    classId: "EC002125",
    classGroup: "EG000050",
    artClassDesc: "Drill holder",
    artClassVersion: 6,
    label: "EC002125: Drill holder"
  },
  {
    classId: "EC002126",
    classGroup: "EG020002",
    artClassDesc: "Accessories/spare parts for drill holder",
    artClassVersion: 6,
    label: "EC002126: Accessories/spare parts for drill holder"
  },
  {
    classId: "EC002127",
    classGroup: "EG000050",
    artClassDesc: "Bellows for drill hole",
    artClassVersion: 5,
    label: "EC002127: Bellows for drill hole"
  },
  {
    classId: "EC002128",
    classGroup: "EG000050",
    artClassDesc: "Mounting/drill template installation boxes",
    artClassVersion: 6,
    label: "EC002128: Mounting/drill template installation boxes"
  },
  {
    classId: "EC002129",
    classGroup: "EG000050",
    artClassDesc: "Crowbar",
    artClassVersion: 8,
    label: "EC002129: Crowbar"
  },
  {
    classId: "EC002130",
    classGroup: "EG000050",
    artClassDesc: "Spanner",
    artClassVersion: 7,
    label: "EC002130: Spanner"
  },
  {
    classId: "EC002131",
    classGroup: "EG000050",
    artClassDesc: "Wire brush",
    artClassVersion: 7,
    label: "EC002131: Wire brush"
  },
  {
    classId: "EC002132",
    classGroup: "EG000050",
    artClassDesc: "Momentum wrench",
    artClassVersion: 6,
    label: "EC002132: Momentum wrench"
  },
  {
    classId: "EC002133",
    classGroup: "EG000050",
    artClassDesc: "Tommy bar for box spanner",
    artClassVersion: 7,
    label: "EC002133: Tommy bar for box spanner"
  },
  {
    classId: "EC002134",
    classGroup: "EG000050",
    artClassDesc: "Drift punch",
    artClassVersion: 6,
    label: "EC002134: Drift punch"
  },
  {
    classId: "EC002135",
    classGroup: "EG000050",
    artClassDesc: "Tamp tool for anchor sleeve",
    artClassVersion: 5,
    label: "EC002135: Tamp tool for anchor sleeve"
  },
  {
    classId: "EC002136",
    classGroup: "EG000050",
    artClassDesc: "Ground drill",
    artClassVersion: 4,
    label: "EC002136: Ground drill"
  },
  {
    classId: "EC002137",
    classGroup: "EG000050",
    artClassDesc: "Scribe tip",
    artClassVersion: 5,
    label: "EC002137: Scribe tip"
  },
  {
    classId: "EC002138",
    classGroup: "EG000050",
    artClassDesc: "Paint roller",
    artClassVersion: 7,
    label: "EC002138: Paint roller"
  },
  {
    classId: "EC002140",
    classGroup: "EG000050",
    artClassDesc: "File/rasp",
    artClassVersion: 8,
    label: "EC002140: File/rasp"
  },
  {
    classId: "EC002141",
    classGroup: "EG000050",
    artClassDesc: "File handle",
    artClassVersion: 7,
    label: "EC002141: File handle"
  },
  {
    classId: "EC002142",
    classGroup: "EG000050",
    artClassDesc: "Nail gun (gas driven)",
    artClassVersion: 8,
    label: "EC002142: Nail gun (gas driven)"
  },
  {
    classId: "EC002143",
    classGroup: "EG000050",
    artClassDesc: "Angle saw case",
    artClassVersion: 5,
    label: "EC002143: Angle saw case"
  },
  {
    classId: "EC002144",
    classGroup: "EG000050",
    artClassDesc: "Angle clamp",
    artClassVersion: 5,
    label: "EC002144: Angle clamp"
  },
  {
    classId: "EC002145",
    classGroup: "EG000050",
    artClassDesc: "Angle gauge",
    artClassVersion: 6,
    label: "EC002145: Angle gauge"
  },
  {
    classId: "EC002146",
    classGroup: "EG000050",
    artClassDesc: "Scaffold",
    artClassVersion: 6,
    label: "EC002146: Scaffold"
  },
  {
    classId: "EC002148",
    classGroup: "EG000050",
    artClassDesc: "Grip pliers",
    artClassVersion: 7,
    label: "EC002148: Grip pliers"
  },
  {
    classId: "EC002149",
    classGroup: "EG000050",
    artClassDesc: "Nibbler (hand)",
    artClassVersion: 6,
    label: "EC002149: Nibbler (hand)"
  },
  {
    classId: "EC002150",
    classGroup: "EG000050",
    artClassDesc: "Hand broom",
    artClassVersion: 6,
    label: "EC002150: Hand broom"
  },
  {
    classId: "EC002151",
    classGroup: "EG000050",
    artClassDesc: "Handle for socket spanner",
    artClassVersion: 8,
    label: "EC002151: Handle for socket spanner"
  },
  {
    classId: "EC002152",
    classGroup: "EG000050",
    artClassDesc: "Handsaw blade",
    artClassVersion: 6,
    label: "EC002152: Handsaw blade"
  },
  {
    classId: "EC002153",
    classGroup: "EG000050",
    artClassDesc: "Hoist sling",
    artClassVersion: 7,
    label: "EC002153: Hoist sling"
  },
  {
    classId: "EC002154",
    classGroup: "EG000050",
    artClassDesc: "Hexagonal screwdriver",
    artClassVersion: 7,
    label: "EC002154: Hexagonal screwdriver"
  },
  {
    classId: "EC002155",
    classGroup: "EG000050",
    artClassDesc: "Tapping drill",
    artClassVersion: 7,
    label: "EC002155: Tapping drill"
  },
  {
    classId: "EC002156",
    classGroup: "EG000050",
    artClassDesc: "Deburrer",
    artClassVersion: 6,
    label: "EC002156: Deburrer"
  },
  {
    classId: "EC002157",
    classGroup: "EG000050",
    artClassDesc: "Socket spanner for in-hexagonal",
    artClassVersion: 7,
    label: "EC002157: Socket spanner for in-hexagonal"
  },
  {
    classId: "EC002158",
    classGroup: "EG000050",
    artClassDesc: "Robertson screwdriver",
    artClassVersion: 6,
    label: "EC002158: Robertson screwdriver"
  },
  {
    classId: "EC002159",
    classGroup: "EG000050",
    artClassDesc: "Inspection mirror",
    artClassVersion: 5,
    label: "EC002159: Inspection mirror"
  },
  {
    classId: "EC002160",
    classGroup: "EG000050",
    artClassDesc: "Cable pulling pump",
    artClassVersion: 4,
    label: "EC002160: Cable pulling pump"
  },
  {
    classId: "EC002161",
    classGroup: "EG000050",
    artClassDesc: "Cable stocking",
    artClassVersion: 8,
    label: "EC002161: Cable stocking"
  },
  {
    classId: "EC002162",
    classGroup: "EG000050",
    artClassDesc: "Universal joint for socket spanner",
    artClassVersion: 8,
    label: "EC002162: Universal joint for socket spanner"
  },
  {
    classId: "EC002163",
    classGroup: "EG000050",
    artClassDesc: "Caulking gun",
    artClassVersion: 7,
    label: "EC002163: Caulking gun"
  },
  {
    classId: "EC002164",
    classGroup: "EG000050",
    artClassDesc: "Dust pan",
    artClassVersion: 6,
    label: "EC002164: Dust pan"
  },
  {
    classId: "EC002165",
    classGroup: "EG000050",
    artClassDesc: "Folding trestle",
    artClassVersion: 6,
    label: "EC002165: Folding trestle"
  },
  {
    classId: "EC002166",
    classGroup: "EG000050",
    artClassDesc: "Pincer",
    artClassVersion: 6,
    label: "EC002166: Pincer"
  },
  {
    classId: "EC002167",
    classGroup: "EG000050",
    artClassDesc: "Centre punch",
    artClassVersion: 8,
    label: "EC002167: Centre punch"
  },
  {
    classId: "EC002168",
    classGroup: "EG000050",
    artClassDesc: "Pickaxe",
    artClassVersion: 6,
    label: "EC002168: Pickaxe"
  },
  {
    classId: "EC002169",
    classGroup: "EG000050",
    artClassDesc: "Socket spanner for crosshead screws",
    artClassVersion: 7,
    label: "EC002169: Socket spanner for crosshead screws"
  },
  {
    classId: "EC002170",
    classGroup: "EG000050",
    artClassDesc: "Speed brace for socket spanner",
    artClassVersion: 6,
    label: "EC002170: Speed brace for socket spanner"
  },
  {
    classId: "EC002171",
    classGroup: "EG000050",
    artClassDesc: "Varnish stripper",
    artClassVersion: 5,
    label: "EC002171: Varnish stripper"
  },
  {
    classId: "EC002172",
    classGroup: "EG000050",
    artClassDesc: "Lamp extractor",
    artClassVersion: 4,
    label: "EC002172: Lamp extractor"
  },
  {
    classId: "EC002173",
    classGroup: "EG000050",
    artClassDesc: "Machine vice",
    artClassVersion: 6,
    label: "EC002173: Machine vice"
  },
  {
    classId: "EC002174",
    classGroup: "EG000050",
    artClassDesc: "Measuring tape",
    artClassVersion: 7,
    label: "EC002174: Measuring tape"
  },
  {
    classId: "EC002175",
    classGroup: "EG000050",
    artClassDesc: "Cross spanner",
    artClassVersion: 6,
    label: "EC002175: Cross spanner"
  },
  {
    classId: "EC002176",
    classGroup: "EG000050",
    artClassDesc: "Combination spanner",
    artClassVersion: 8,
    label: "EC002176: Combination spanner"
  },
  {
    classId: "EC002177",
    classGroup: "EG000050",
    artClassDesc: "Brick trowel",
    artClassVersion: 6,
    label: "EC002177: Brick trowel"
  },
  {
    classId: "EC002178",
    classGroup: "EG000050",
    artClassDesc: "Ruler",
    artClassVersion: 7,
    label: "EC002178: Ruler"
  },
  {
    classId: "EC002179",
    classGroup: "EG000050",
    artClassDesc: "Measuring wheel",
    artClassVersion: 6,
    label: "EC002179: Measuring wheel"
  },
  {
    classId: "EC002180",
    classGroup: "EG000050",
    artClassDesc: "Fencing pliers",
    artClassVersion: 5,
    label: "EC002180: Fencing pliers"
  },
  {
    classId: "EC002181",
    classGroup: "EG000050",
    artClassDesc: "NH-fuse extraction handle",
    artClassVersion: 4,
    label: "EC002181: NH-fuse extraction handle"
  },
  {
    classId: "EC002182",
    classGroup: "EG000050",
    artClassDesc: "Oil canister",
    artClassVersion: 6,
    label: "EC002182: Oil canister"
  },
  {
    classId: "EC002183",
    classGroup: "EG000050",
    artClassDesc: "Pipe vice",
    artClassVersion: 6,
    label: "EC002183: Pipe vice"
  },
  {
    classId: "EC002184",
    classGroup: "EG000050",
    artClassDesc: "Sealing pliers",
    artClassVersion: 5,
    label: "EC002184: Sealing pliers"
  },
  {
    classId: "EC002185",
    classGroup: "EG000050",
    artClassDesc: "Cleaning cloth",
    artClassVersion: 8,
    label: "EC002185: Cleaning cloth"
  },
  {
    classId: "EC002186",
    classGroup: "EG000050",
    artClassDesc: "Adapter for socket spanner",
    artClassVersion: 7,
    label: "EC002186: Adapter for socket spanner"
  },
  {
    classId: "EC002187",
    classGroup: "EG000050",
    artClassDesc: "Scribe",
    artClassVersion: 6,
    label: "EC002187: Scribe"
  },
  {
    classId: "EC002188",
    classGroup: "EG000050",
    artClassDesc: "Ring spanner",
    artClassVersion: 8,
    label: "EC002188: Ring spanner"
  },
  {
    classId: "EC002189",
    classGroup: "EG000050",
    artClassDesc: "Pipe spring",
    artClassVersion: 6,
    label: "EC002189: Pipe spring"
  },
  {
    classId: "EC002190",
    classGroup: "EG000050",
    artClassDesc: "Pipe shears",
    artClassVersion: 6,
    label: "EC002190: Pipe shears"
  },
  {
    classId: "EC002191",
    classGroup: "EG000050",
    artClassDesc: "Tubular box spanner",
    artClassVersion: 7,
    label: "EC002191: Tubular box spanner"
  },
  {
    classId: "EC002192",
    classGroup: "EG000050",
    artClassDesc: "Adjustable spanner",
    artClassVersion: 7,
    label: "EC002192: Adjustable spanner"
  },
  {
    classId: "EC002194",
    classGroup: "EG000050",
    artClassDesc: "Shovel",
    artClassVersion: 5,
    label: "EC002194: Shovel"
  },
  {
    classId: "EC002195",
    classGroup: "EG000050",
    artClassDesc: "Hand protector for chisel",
    artClassVersion: 6,
    label: "EC002195: Hand protector for chisel"
  },
  {
    classId: "EC002196",
    classGroup: "EG000050",
    artClassDesc: "Sand paper/emery cloth",
    artClassVersion: 8,
    label: "EC002196: Sand paper/emery cloth"
  },
  {
    classId: "EC002197",
    classGroup: "EG000050",
    artClassDesc: "Socket spanner for slot head screws",
    artClassVersion: 6,
    label: "EC002197: Socket spanner for slot head screws"
  },
  {
    classId: "EC002198",
    classGroup: "EG000050",
    artClassDesc: "Thread cutting die",
    artClassVersion: 8,
    label: "EC002198: Thread cutting die"
  },
  {
    classId: "EC002199",
    classGroup: "EG000050",
    artClassDesc: "Bench-vice",
    artClassVersion: 7,
    label: "EC002199: Bench-vice"
  },
  {
    classId: "EC002200",
    classGroup: "EG000050",
    artClassDesc: "Glue clamp",
    artClassVersion: 6,
    label: "EC002200: Glue clamp"
  },
  {
    classId: "EC002201",
    classGroup: "EG000050",
    artClassDesc: "Wheelbarrow",
    artClassVersion: 5,
    label: "EC002201: Wheelbarrow"
  },
  {
    classId: "EC002202",
    classGroup: "EG000050",
    artClassDesc: "Vice plate for bench-vice",
    artClassVersion: 6,
    label: "EC002202: Vice plate for bench-vice"
  },
  {
    classId: "EC002203",
    classGroup: "EG000050",
    artClassDesc: "Socket for hexagonal nuts",
    artClassVersion: 7,
    label: "EC002203: Socket for hexagonal nuts"
  },
  {
    classId: "EC002204",
    classGroup: "EG000050",
    artClassDesc: "Winch",
    artClassVersion: 8,
    label: "EC002204: Winch"
  },
  {
    classId: "EC002205",
    classGroup: "EG000050",
    artClassDesc: "Hoist",
    artClassVersion: 9,
    label: "EC002205: Hoist"
  },
  {
    classId: "EC002206",
    classGroup: "EG000050",
    artClassDesc: "Hoist chain",
    artClassVersion: 8,
    label: "EC002206: Hoist chain"
  },
  {
    classId: "EC002207",
    classGroup: "EG000050",
    artClassDesc: "Plumb bob",
    artClassVersion: 5,
    label: "EC002207: Plumb bob"
  },
  {
    classId: "EC002208",
    classGroup: "EG000050",
    artClassDesc: "Spade",
    artClassVersion: 5,
    label: "EC002208: Spade"
  },
  {
    classId: "EC002209",
    classGroup: "EG000050",
    artClassDesc: "Socket spanner set",
    artClassVersion: 7,
    label: "EC002209: Socket spanner set"
  },
  {
    classId: "EC002210",
    classGroup: "EG000050",
    artClassDesc: "Hexagonal key set",
    artClassVersion: 7,
    label: "EC002210: Hexagonal key set"
  },
  {
    classId: "EC002211",
    classGroup: "EG000050",
    artClassDesc: "Siphon pliers",
    artClassVersion: 5,
    label: "EC002211: Siphon pliers"
  },
  {
    classId: "EC002212",
    classGroup: "EG000050",
    artClassDesc: "Plate shear",
    artClassVersion: 4,
    label: "EC002212: Plate shear"
  },
  {
    classId: "EC002213",
    classGroup: "EG000050",
    artClassDesc: "Socket spanner for Torx screws",
    artClassVersion: 7,
    label: "EC002213: Socket spanner for Torx screws"
  },
  {
    classId: "EC002214",
    classGroup: "EG000050",
    artClassDesc: "Torx wrench",
    artClassVersion: 7,
    label: "EC002214: Torx wrench"
  },
  {
    classId: "EC002215",
    classGroup: "EG000050",
    artClassDesc: "Funnel",
    artClassVersion: 6,
    label: "EC002215: Funnel"
  },
  {
    classId: "EC002216",
    classGroup: "EG000050",
    artClassDesc: "Stepladder",
    artClassVersion: 7,
    label: "EC002216: Stepladder"
  },
  {
    classId: "EC002217",
    classGroup: "EG000050",
    artClassDesc: "Hoist block/pulley",
    artClassVersion: 6,
    label: "EC002217: Hoist block/pulley"
  },
  {
    classId: "EC002218",
    classGroup: "EG000050",
    artClassDesc: "Ratchet",
    artClassVersion: 7,
    label: "EC002218: Ratchet"
  },
  {
    classId: "EC002219",
    classGroup: "EG000050",
    artClassDesc: "Extension piece for screw bit",
    artClassVersion: 7,
    label: "EC002219: Extension piece for screw bit"
  },
  {
    classId: "EC002220",
    classGroup: "EG000050",
    artClassDesc: "Extension bar for socket spanners, spanners and ratchets",
    artClassVersion: 7,
    label: "EC002220: Extension bar for socket spanners, spanners and ratchets"
  },
  {
    classId: "EC002221",
    classGroup: "EG000050",
    artClassDesc: "Padlock",
    artClassVersion: 6,
    label: "EC002221: Padlock"
  },
  {
    classId: "EC002222",
    classGroup: "EG000050",
    artClassDesc: "End cutting pliers",
    artClassVersion: 7,
    label: "EC002222: End cutting pliers"
  },
  {
    classId: "EC002223",
    classGroup: "EG000050",
    artClassDesc: "Washstand pliers",
    artClassVersion: 6,
    label: "EC002223: Washstand pliers"
  },
  {
    classId: "EC002224",
    classGroup: "EG000050",
    artClassDesc: "Workbench",
    artClassVersion: 7,
    label: "EC002224: Workbench"
  },
  {
    classId: "EC002225",
    classGroup: "EG000050",
    artClassDesc: "Tool belt",
    artClassVersion: 5,
    label: "EC002225: Tool belt"
  },
  {
    classId: "EC002227",
    classGroup: "EG000050",
    artClassDesc: "Tap wrench",
    artClassVersion: 7,
    label: "EC002227: Tap wrench"
  },
  {
    classId: "EC002228",
    classGroup: "EG000050",
    artClassDesc: "Circlip pliers",
    artClassVersion: 6,
    label: "EC002228: Circlip pliers"
  },
  {
    classId: "EC002229",
    classGroup: "EG000050",
    artClassDesc: "Compasses",
    artClassVersion: 6,
    label: "EC002229: Compasses"
  },
  {
    classId: "EC002230",
    classGroup: "EG020002",
    artClassDesc: "Adapter SDS-drill",
    artClassVersion: 8,
    label: "EC002230: Adapter SDS-drill"
  },
  {
    classId: "EC002231",
    classGroup: "EG020002",
    artClassDesc: "Drill chuck adaptor for core drill bit",
    artClassVersion: 8,
    label: "EC002231: Drill chuck adaptor for core drill bit"
  },
  {
    classId: "EC002232",
    classGroup: "EG020002",
    artClassDesc: "Drill chuck adaptor for hole saw",
    artClassVersion: 7,
    label: "EC002232: Drill chuck adaptor for hole saw"
  },
  {
    classId: "EC002234",
    classGroup: "EG020002",
    artClassDesc: "Rotating steel brush",
    artClassVersion: 8,
    label: "EC002234: Rotating steel brush"
  },
  {
    classId: "EC002235",
    classGroup: "EG020002",
    artClassDesc: "Wall chase",
    artClassVersion: 8,
    label: "EC002235: Wall chase"
  },
  {
    classId: "EC002237",
    classGroup: "EG020002",
    artClassDesc: "Circular saw blade",
    artClassVersion: 7,
    label: "EC002237: Circular saw blade"
  },
  {
    classId: "EC002238",
    classGroup: "EG000061",
    artClassDesc: "Soldering gun",
    artClassVersion: 5,
    label: "EC002238: Soldering gun"
  },
  {
    classId: "EC002239",
    classGroup: "EG020002",
    artClassDesc: "Sabre saw blade",
    artClassVersion: 8,
    label: "EC002239: Sabre saw blade"
  },
  {
    classId: "EC002240",
    classGroup: "EG000051",
    artClassDesc: "Punching machine",
    artClassVersion: 5,
    label: "EC002240: Punching machine"
  },
  {
    classId: "EC002241",
    classGroup: "EG020002",
    artClassDesc: "Drill stand",
    artClassVersion: 7,
    label: "EC002241: Drill stand"
  },
  {
    classId: "EC002242",
    classGroup: "EG020002",
    artClassDesc: "Jig saw blade",
    artClassVersion: 8,
    label: "EC002242: Jig saw blade"
  },
  {
    classId: "EC002243",
    classGroup: "EG000051",
    artClassDesc: "Table drilling machine",
    artClassVersion: 6,
    label: "EC002243: Table drilling machine"
  },
  {
    classId: "EC002244",
    classGroup: "EG000051",
    artClassDesc: "Table circular saw machine, semi-stationary (electric)",
    artClassVersion: 6,
    label: "EC002244: Table circular saw machine, semi-stationary (electric)"
  },
  {
    classId: "EC002245",
    classGroup: "EG000051",
    artClassDesc: "Bench grinder",
    artClassVersion: 6,
    label: "EC002245: Bench grinder"
  },
  {
    classId: "EC002246",
    classGroup: "EG020002",
    artClassDesc: "Centre drill",
    artClassVersion: 6,
    label: "EC002246: Centre drill"
  },
  {
    classId: "EC002247",
    classGroup: "EG000013",
    artClassDesc: "Handicapped person element for domestic switching devices",
    artClassVersion: 8,
    label: "EC002247: Handicapped person element for domestic switching devices"
  },
  {
    classId: "EC002248",
    classGroup: "EG000013",
    artClassDesc: "Mounting for labelling material",
    artClassVersion: 5,
    label: "EC002248: Mounting for labelling material"
  },
  {
    classId: "EC002249",
    classGroup: "EG000013",
    artClassDesc: "Infrared switch",
    artClassVersion: 6,
    label: "EC002249: Infrared switch"
  },
  {
    classId: "EC002250",
    classGroup: "EG000013",
    artClassDesc: "Transition adapter for domestic switching devices",
    artClassVersion: 8,
    label: "EC002250: Transition adapter for domestic switching devices"
  },
  {
    classId: "EC002251",
    classGroup: "EG000013",
    artClassDesc: "Central-element clinic-installation",
    artClassVersion: 6,
    label: "EC002251: Central-element clinic-installation"
  },
  {
    classId: "EC002252",
    classGroup: "EG000032",
    artClassDesc: "Circuit board for bus system",
    artClassVersion: 4,
    label: "EC002252: Circuit board for bus system"
  },
  {
    classId: "EC002253",
    classGroup: "EG000032",
    artClassDesc: "Touch rocker for bus system",
    artClassVersion: 9,
    label: "EC002253: Touch rocker for bus system"
  },
  {
    classId: "EC002254",
    classGroup: "EG000047",
    artClassDesc: "Cable insulation hose",
    artClassVersion: 7,
    label: "EC002254: Cable insulation hose"
  },
  {
    classId: "EC002255",
    classGroup: "EG000044",
    artClassDesc: "Test plug terminal block",
    artClassVersion: 5,
    label: "EC002255: Test plug terminal block"
  },
  {
    classId: "EC002257",
    classGroup: "EG000002",
    artClassDesc: "Connection bend for installation tubes",
    artClassVersion: 6,
    label: "EC002257: Connection bend for installation tubes"
  },
  {
    classId: "EC002258",
    classGroup: "EG000002",
    artClassDesc: "Ground mounting for cable protection tubes for underground application",
    artClassVersion: 5,
    label: "EC002258: Ground mounting for cable protection tubes for underground application"
  },
  {
    classId: "EC002259",
    classGroup: "EG000002",
    artClassDesc: "Cable exit for cable protection tubes for underground application",
    artClassVersion: 6,
    label: "EC002259: Cable exit for cable protection tubes for underground application"
  },
  {
    classId: "EC002260",
    classGroup: "EG000002",
    artClassDesc: "Mounting wedge for installation tubes",
    artClassVersion: 5,
    label: "EC002260: Mounting wedge for installation tubes"
  },
  {
    classId: "EC002261",
    classGroup: "EG000002",
    artClassDesc: "Mounting coupler for cable protection tubes for underground application",
    artClassVersion: 5,
    label: "EC002261: Mounting coupler for cable protection tubes for underground application"
  },
  {
    classId: "EC002262",
    classGroup: "EG000002",
    artClassDesc: "Mounting plate for installation tubes/cable protection tubes",
    artClassVersion: 6,
    label: "EC002262: Mounting plate for installation tubes/cable protection tubes"
  },
  {
    classId: "EC002264",
    classGroup: "EG000002",
    artClassDesc: "Reducing sleeve for installation tubes",
    artClassVersion: 6,
    label: "EC002264: Reducing sleeve for installation tubes"
  },
  {
    classId: "EC002265",
    classGroup: "EG000002",
    artClassDesc: "End cap for cable protection tube for underground application",
    artClassVersion: 8,
    label: "EC002265: End cap for cable protection tube for underground application"
  },
  {
    classId: "EC002266",
    classGroup: "EG000002",
    artClassDesc: "Tee for cable protection tubes for underground application",
    artClassVersion: 7,
    label: "EC002266: Tee for cable protection tubes for underground application"
  },
  {
    classId: "EC002267",
    classGroup: "EG000002",
    artClassDesc: "Strain relief for cable protection tubes for underground application",
    artClassVersion: 6,
    label: "EC002267: Strain relief for cable protection tubes for underground application"
  },
  {
    classId: "EC002268",
    classGroup: "EG000015",
    artClassDesc: "Cover cap for rail terminal bar",
    artClassVersion: 4,
    label: "EC002268: Cover cap for rail terminal bar"
  },
  {
    classId: "EC002269",
    classGroup: "EG000015",
    artClassDesc: "Accessories/spare parts for tap off unit busbar trunk",
    artClassVersion: 7,
    label: "EC002269: Accessories/spare parts for tap off unit busbar trunk"
  },
  {
    classId: "EC002270",
    classGroup: "EG000015",
    artClassDesc: "Accessories/spare parts for busbars",
    artClassVersion: 8,
    label: "EC002270: Accessories/spare parts for busbars"
  },
  {
    classId: "EC002271",
    classGroup: "EG000015",
    artClassDesc: "Accessories/spare parts for busbar trunks",
    artClassVersion: 7,
    label: "EC002271: Accessories/spare parts for busbar trunks"
  },
  {
    classId: "EC002272",
    classGroup: "EG000015",
    artClassDesc: "Accessories/spare parts for feed unit busbar trunks",
    artClassVersion: 7,
    label: "EC002272: Accessories/spare parts for feed unit busbar trunks"
  },
  {
    classId: "EC002273",
    classGroup: "EG000015",
    artClassDesc: "Phase barrier plate",
    artClassVersion: 4,
    label: "EC002273: Phase barrier plate"
  },
  {
    classId: "EC002274",
    classGroup: "EG000015",
    artClassDesc: "Busbar housing",
    artClassVersion: 7,
    label: "EC002274: Busbar housing"
  },
  {
    classId: "EC002275",
    classGroup: "EG000015",
    artClassDesc: "Distributor assembly D-system fuse systems",
    artClassVersion: 5,
    label: "EC002275: Distributor assembly D-system fuse systems"
  },
  {
    classId: "EC002276",
    classGroup: "EG000015",
    artClassDesc: "Distributor assembly RCCB",
    artClassVersion: 4,
    label: "EC002276: Distributor assembly RCCB"
  },
  {
    classId: "EC002277",
    classGroup: "EG000015",
    artClassDesc: "Switch fuse enclosure",
    artClassVersion: 4,
    label: "EC002277: Switch fuse enclosure"
  },
  {
    classId: "EC002278",
    classGroup: "EG000015",
    artClassDesc: "Distributor assembly miniature circuit breaker (MCB)",
    artClassVersion: 4,
    label: "EC002278: Distributor assembly miniature circuit breaker (MCB)"
  },
  {
    classId: "EC002280",
    classGroup: "EG000015",
    artClassDesc: "Distributor assembly with main switch",
    artClassVersion: 4,
    label: "EC002280: Distributor assembly with main switch"
  },
  {
    classId: "EC002281",
    classGroup: "EG000015",
    artClassDesc: "Distributor assembly with combination RCCB/MCB",
    artClassVersion: 4,
    label: "EC002281: Distributor assembly with combination RCCB/MCB"
  },
  {
    classId: "EC002283",
    classGroup: "EG000015",
    artClassDesc: "Distributor assembly with power circuit-breaker",
    artClassVersion: 5,
    label: "EC002283: Distributor assembly with power circuit-breaker"
  },
  {
    classId: "EC002284",
    classGroup: "EG000015",
    artClassDesc: "Distributor assembly with zero voltage switch",
    artClassVersion: 4,
    label: "EC002284: Distributor assembly with zero voltage switch"
  },
  {
    classId: "EC002286",
    classGroup: "EG000015",
    artClassDesc: "Distributor assembly Low Voltage HRC fuse systems",
    artClassVersion: 4,
    label: "EC002286: Distributor assembly Low Voltage HRC fuse systems"
  },
  {
    classId: "EC002287",
    classGroup: "EG000023",
    artClassDesc: "Accessories/spare parts for small distribution board",
    artClassVersion: 8,
    label: "EC002287: Accessories/spare parts for small distribution board"
  },
  {
    classId: "EC002288",
    classGroup: "EG000023",
    artClassDesc: "Small distribution board equipped",
    artClassVersion: 7,
    label: "EC002288: Small distribution board equipped"
  },
  {
    classId: "EC002289",
    classGroup: "EG000015",
    artClassDesc: "Quick release fastener for housing covers",
    artClassVersion: 4,
    label: "EC002289: Quick release fastener for housing covers"
  },
  {
    classId: "EC002290",
    classGroup: "EG000021",
    artClassDesc: "C-clamp for round wire",
    artClassVersion: 6,
    label: "EC002290: C-clamp for round wire"
  },
  {
    classId: "EC002291",
    classGroup: "EG000021",
    artClassDesc: "Earthing strap clamp",
    artClassVersion: 6,
    label: "EC002291: Earthing strap clamp"
  },
  {
    classId: "EC002292",
    classGroup: "EG000062",
    artClassDesc: "Ampere meter for installation",
    artClassVersion: 6,
    label: "EC002292: Ampere meter for installation"
  },
  {
    classId: "EC002293",
    classGroup: "EG000062",
    artClassDesc: "Var meter for installation",
    artClassVersion: 5,
    label: "EC002293: Var meter for installation"
  },
  {
    classId: "EC002294",
    classGroup: "EG000062",
    artClassDesc: "Built-in rotational speed meter",
    artClassVersion: 5,
    label: "EC002294: Built-in rotational speed meter"
  },
  {
    classId: "EC002295",
    classGroup: "EG000062",
    artClassDesc: "Accessories/spare parts for measuring instruments for installation",
    artClassVersion: 7,
    label: "EC002295: Accessories/spare parts for measuring instruments for installation"
  },
  {
    classId: "EC002296",
    classGroup: "EG000062",
    artClassDesc: "Accessories/spare parts for time switches",
    artClassVersion: 7,
    label: "EC002296: Accessories/spare parts for time switches"
  },
  {
    classId: "EC002297",
    classGroup: "EG000020",
    artClassDesc: "Residual current circuit breaker (RCCB) module",
    artClassVersion: 8,
    label: "EC002297: Residual current circuit breaker (RCCB) module"
  },
  {
    classId: "EC002298",
    classGroup: "EG000020",
    artClassDesc: "Blade fuse",
    artClassVersion: 6,
    label: "EC002298: Blade fuse"
  },
  {
    classId: "EC002299",
    classGroup: "EG000062",
    artClassDesc: "Frequency meter for installation",
    artClassVersion: 5,
    label: "EC002299: Frequency meter for installation"
  },
  {
    classId: "EC002300",
    classGroup: "EG000062",
    artClassDesc: "Impulse meter for installation",
    artClassVersion: 6,
    label: "EC002300: Impulse meter for installation"
  },
  {
    classId: "EC002301",
    classGroup: "EG000062",
    artClassDesc: "Multifunction measuring instrument",
    artClassVersion: 6,
    label: "EC002301: Multifunction measuring instrument"
  },
  {
    classId: "EC002302",
    classGroup: "EG000062",
    artClassDesc: "Measuring scale",
    artClassVersion: 6,
    label: "EC002302: Measuring scale"
  },
  {
    classId: "EC002303",
    classGroup: "EG000062",
    artClassDesc: "Shunt",
    artClassVersion: 4,
    label: "EC002303: Shunt"
  },
  {
    classId: "EC002304",
    classGroup: "EG000062",
    artClassDesc: "Analogous time switch for distribution board",
    artClassVersion: 6,
    label: "EC002304: Analogous time switch for distribution board"
  },
  {
    classId: "EC002305",
    classGroup: "EG000062",
    artClassDesc: "Digital time switch for distribution board",
    artClassVersion: 7,
    label: "EC002305: Digital time switch for distribution board"
  },
  {
    classId: "EC002306",
    classGroup: "EG000062",
    artClassDesc: "Voltmeter for installation",
    artClassVersion: 5,
    label: "EC002306: Voltmeter for installation"
  },
  {
    classId: "EC002307",
    classGroup: "EG000062",
    artClassDesc: "Resistor",
    artClassVersion: 4,
    label: "EC002307: Resistor"
  },
  {
    classId: "EC002308",
    classGroup: "EG000062",
    artClassDesc: "Effective power converter meter for installation",
    artClassVersion: 6,
    label: "EC002308: Effective power converter meter for installation"
  },
  {
    classId: "EC002309",
    classGroup: "EG000058",
    artClassDesc: "Adapter plate industrial connectors",
    artClassVersion: 6,
    label: "EC002309: Adapter plate industrial connectors"
  },
  {
    classId: "EC002310",
    classGroup: "EG000058",
    artClassDesc: "Fixing element for industrial connectors",
    artClassVersion: 6,
    label: "EC002310: Fixing element for industrial connectors"
  },
  {
    classId: "EC002311",
    classGroup: "EG000058",
    artClassDesc: "Coding element for industrial connectors",
    artClassVersion: 7,
    label: "EC002311: Coding element for industrial connectors"
  },
  {
    classId: "EC002312",
    classGroup: "EG000058",
    artClassDesc: "Module carrier frame for industrial connectors",
    artClassVersion: 6,
    label: "EC002312: Module carrier frame for industrial connectors"
  },
  {
    classId: "EC002313",
    classGroup: "EG000014",
    artClassDesc: "Protective cover for CEE plugs (IEC 60309)",
    artClassVersion: 6,
    label: "EC002313: Protective cover for CEE plugs (IEC 60309)"
  },
  {
    classId: "EC002314",
    classGroup: "EG000058",
    artClassDesc: "Cap for industrial connectors",
    artClassVersion: 8,
    label: "EC002314: Cap for industrial connectors"
  },
  {
    classId: "EC002315",
    classGroup: "EG000014",
    artClassDesc: "Padlock guard for CEE plugs (IEC 60309)",
    artClassVersion: 5,
    label: "EC002315: Padlock guard for CEE plugs (IEC 60309)"
  },
  {
    classId: "EC002316",
    classGroup: "EG000009",
    artClassDesc: "Sealing disc for cable screw gland",
    artClassVersion: 5,
    label: "EC002316: Sealing disc for cable screw gland"
  },
  {
    classId: "EC002317",
    classGroup: "EG000009",
    artClassDesc: "Earthing lug for cable screw gland",
    artClassVersion: 5,
    label: "EC002317: Earthing lug for cable screw gland"
  },
  {
    classId: "EC002318",
    classGroup: "EG000009",
    artClassDesc: "Cut-out sealing ring for cable inlet",
    artClassVersion: 5,
    label: "EC002318: Cut-out sealing ring for cable inlet"
  },
  {
    classId: "EC002319",
    classGroup: "EG000047",
    artClassDesc: "Twist-on wire connector",
    artClassVersion: 7,
    label: "EC002319: Twist-on wire connector"
  },
  {
    classId: "EC002320",
    classGroup: "EG000010",
    artClassDesc: "Pressure saddle clamp (cables/conduits)",
    artClassVersion: 6,
    label: "EC002320: Pressure saddle clamp (cables/conduits)"
  },
  {
    classId: "EC002321",
    classGroup: "EG000010",
    artClassDesc: "Wall mounting plate for tube clamps",
    artClassVersion: 7,
    label: "EC002321: Wall mounting plate for tube clamps"
  },
  {
    classId: "EC002326",
    classGroup: "EG000048",
    artClassDesc: "Cable plug for cable-/installation tube entry",
    artClassVersion: 7,
    label: "EC002326: Cable plug for cable-/installation tube entry"
  },
  {
    classId: "EC002327",
    classGroup: "EG000030",
    artClassDesc: "End piece for illumination cable",
    artClassVersion: 6,
    label: "EC002327: End piece for illumination cable"
  },
  {
    classId: "EC002328",
    classGroup: "EG000048",
    artClassDesc: "Accessories/spare parts for junction boxes/cases for mounting in the wall",
    artClassVersion: 9,
    label: "EC002328: Accessories/spare parts for junction boxes/cases for mounting in the wall"
  },
  {
    classId: "EC002334",
    classGroup: "EG000049",
    artClassDesc: "Spacer sleeve",
    artClassVersion: 8,
    label: "EC002334: Spacer sleeve"
  },
  {
    classId: "EC002336",
    classGroup: "EG000049",
    artClassDesc: "Anchor rod",
    artClassVersion: 5,
    label: "EC002336: Anchor rod"
  },
  {
    classId: "EC002337",
    classGroup: "EG000049",
    artClassDesc: "Anti-theft screw",
    artClassVersion: 7,
    label: "EC002337: Anti-theft screw"
  },
  {
    classId: "EC002339",
    classGroup: "EG000049",
    artClassDesc: "Blind rivet",
    artClassVersion: 7,
    label: "EC002339: Blind rivet"
  },
  {
    classId: "EC002340",
    classGroup: "EG000049",
    artClassDesc: "Blind rivet nut",
    artClassVersion: 7,
    label: "EC002340: Blind rivet nut"
  },
  {
    classId: "EC002343",
    classGroup: "EG000049",
    artClassDesc: "Rivet nut",
    artClassVersion: 6,
    label: "EC002343: Rivet nut"
  },
  {
    classId: "EC002344",
    classGroup: "EG000049",
    artClassDesc: "Speed nut",
    artClassVersion: 6,
    label: "EC002344: Speed nut"
  },
  {
    classId: "EC002345",
    classGroup: "EG000049",
    artClassDesc: "Lock washer",
    artClassVersion: 7,
    label: "EC002345: Lock washer"
  },
  {
    classId: "EC002347",
    classGroup: "EG000049",
    artClassDesc: "Wing nut",
    artClassVersion: 8,
    label: "EC002347: Wing nut"
  },
  {
    classId: "EC002348",
    classGroup: "EG000049",
    artClassDesc: "Gas cartridge for nail gun",
    artClassVersion: 5,
    label: "EC002348: Gas cartridge for nail gun"
  },
  {
    classId: "EC002349",
    classGroup: "EG000049",
    artClassDesc: "Stud bolt",
    artClassVersion: 8,
    label: "EC002349: Stud bolt"
  },
  {
    classId: "EC002350",
    classGroup: "EG000049",
    artClassDesc: "Threaded sleeve",
    artClassVersion: 4,
    label: "EC002350: Threaded sleeve"
  },
  {
    classId: "EC002351",
    classGroup: "EG000049",
    artClassDesc: "Threaded pipe",
    artClassVersion: 5,
    label: "EC002351: Threaded pipe"
  },
  {
    classId: "EC002352",
    classGroup: "EG000049",
    artClassDesc: "Hook nail",
    artClassVersion: 5,
    label: "EC002352: Hook nail"
  },
  {
    classId: "EC002353",
    classGroup: "EG000049",
    artClassDesc: "Round head rivet",
    artClassVersion: 5,
    label: "EC002353: Round head rivet"
  },
  {
    classId: "EC002354",
    classGroup: "EG000049",
    artClassDesc: "T-head bolt",
    artClassVersion: 7,
    label: "EC002354: T-head bolt"
  },
  {
    classId: "EC002357",
    classGroup: "EG000049",
    artClassDesc: "Support insulator",
    artClassVersion: 6,
    label: "EC002357: Support insulator"
  },
  {
    classId: "EC002358",
    classGroup: "EG000049",
    artClassDesc: "Square caged nut",
    artClassVersion: 5,
    label: "EC002358: Square caged nut"
  },
  {
    classId: "EC002359",
    classGroup: "EG000049",
    artClassDesc: "Tommy screw",
    artClassVersion: 7,
    label: "EC002359: Tommy screw"
  },
  {
    classId: "EC002360",
    classGroup: "EG000049",
    artClassDesc: "Long nut",
    artClassVersion: 7,
    label: "EC002360: Long nut"
  },
  {
    classId: "EC002361",
    classGroup: "EG000049",
    artClassDesc: "L-hook screw",
    artClassVersion: 6,
    label: "EC002361: L-hook screw"
  },
  {
    classId: "EC002362",
    classGroup: "EG000048",
    artClassDesc: "Mounting cramp for junction boxes",
    artClassVersion: 5,
    label: "EC002362: Mounting cramp for junction boxes"
  },
  {
    classId: "EC002363",
    classGroup: "EG000049",
    artClassDesc: "Resin capsule for adhesive anchor",
    artClassVersion: 7,
    label: "EC002363: Resin capsule for adhesive anchor"
  },
  {
    classId: "EC002364",
    classGroup: "EG000049",
    artClassDesc: "Nail for nail gun",
    artClassVersion: 7,
    label: "EC002364: Nail for nail gun"
  },
  {
    classId: "EC002365",
    classGroup: "EG000049",
    artClassDesc: "Safety screw",
    artClassVersion: 6,
    label: "EC002365: Safety screw"
  },
  {
    classId: "EC002366",
    classGroup: "EG000049",
    artClassDesc: "Eye nut",
    artClassVersion: 7,
    label: "EC002366: Eye nut"
  },
  {
    classId: "EC002367",
    classGroup: "EG000049",
    artClassDesc: "Eye bolt",
    artClassVersion: 9,
    label: "EC002367: Eye bolt"
  },
  {
    classId: "EC002368",
    classGroup: "EG000049",
    artClassDesc: "Eye screw (with wood thread)",
    artClassVersion: 7,
    label: "EC002368: Eye screw (with wood thread)"
  },
  {
    classId: "EC002369",
    classGroup: "EG000049",
    artClassDesc: "Stirrup bolt J-form",
    artClassVersion: 5,
    label: "EC002369: Stirrup bolt J-form"
  },
  {
    classId: "EC002370",
    classGroup: "EG000049",
    artClassDesc: "Stirrup bolt U-form",
    artClassVersion: 7,
    label: "EC002370: Stirrup bolt U-form"
  },
  {
    classId: "EC002371",
    classGroup: "EG000049",
    artClassDesc: "Shackle",
    artClassVersion: 6,
    label: "EC002371: Shackle"
  },
  {
    classId: "EC002372",
    classGroup: "EG000049",
    artClassDesc: "Swing hook",
    artClassVersion: 7,
    label: "EC002372: Swing hook"
  },
  {
    classId: "EC002373",
    classGroup: "EG000049",
    artClassDesc: "Wheel nipple for luminaire",
    artClassVersion: 4,
    label: "EC002373: Wheel nipple for luminaire"
  },
  {
    classId: "EC002374",
    classGroup: "EG000049",
    artClassDesc: "Screw for compressed air screw driver",
    artClassVersion: 5,
    label: "EC002374: Screw for compressed air screw driver"
  },
  {
    classId: "EC002375",
    classGroup: "EG000049",
    artClassDesc: "Screw/nut cap",
    artClassVersion: 6,
    label: "EC002375: Screw/nut cap"
  },
  {
    classId: "EC002376",
    classGroup: "EG000049",
    artClassDesc: "Cup washer",
    artClassVersion: 7,
    label: "EC002376: Cup washer"
  },
  {
    classId: "EC002377",
    classGroup: "EG000049",
    artClassDesc: "Expanding rivet",
    artClassVersion: 6,
    label: "EC002377: Expanding rivet"
  },
  {
    classId: "EC002379",
    classGroup: "EG000049",
    artClassDesc: "High performance hook",
    artClassVersion: 5,
    label: "EC002379: High performance hook"
  },
  {
    classId: "EC002381",
    classGroup: "EG000049",
    artClassDesc: "Tab washer",
    artClassVersion: 4,
    label: "EC002381: Tab washer"
  },
  {
    classId: "EC002383",
    classGroup: "EG000049",
    artClassDesc: "Retaining ring",
    artClassVersion: 7,
    label: "EC002383: Retaining ring"
  },
  {
    classId: "EC002384",
    classGroup: "EG000049",
    artClassDesc: "Spring pin",
    artClassVersion: 6,
    label: "EC002384: Spring pin"
  },
  {
    classId: "EC002385",
    classGroup: "EG000049",
    artClassDesc: "Wood/timber screw",
    artClassVersion: 9,
    label: "EC002385: Wood/timber screw"
  },
  {
    classId: "EC002388",
    classGroup: "EG000049",
    artClassDesc: "Foundation bolt",
    artClassVersion: 6,
    label: "EC002388: Foundation bolt"
  },
  {
    classId: "EC002389",
    classGroup: "EG000049",
    artClassDesc: "Adjusting ring",
    artClassVersion: 6,
    label: "EC002389: Adjusting ring"
  },
  {
    classId: "EC002390",
    classGroup: "EG000049",
    artClassDesc: "Set screw",
    artClassVersion: 8,
    label: "EC002390: Set screw"
  },
  {
    classId: "EC002391",
    classGroup: "EG000049",
    artClassDesc: "Stud",
    artClassVersion: 6,
    label: "EC002391: Stud"
  },
  {
    classId: "EC002392",
    classGroup: "EG000049",
    artClassDesc: "Stud screw",
    artClassVersion: 7,
    label: "EC002392: Stud screw"
  },
  {
    classId: "EC002393",
    classGroup: "EG000049",
    artClassDesc: "Adhesive anchor",
    artClassVersion: 7,
    label: "EC002393: Adhesive anchor"
  },
  {
    classId: "EC002394",
    classGroup: "EG000049",
    artClassDesc: "Square taper washers",
    artClassVersion: 5,
    label: "EC002394: Square taper washers"
  },
  {
    classId: "EC002397",
    classGroup: "EG000004",
    artClassDesc: "Tee funnel for cable tray",
    artClassVersion: 7,
    label: "EC002397: Tee funnel for cable tray"
  },
  {
    classId: "EC002400",
    classGroup: "EG000004",
    artClassDesc: "Bend for cable tray",
    artClassVersion: 9,
    label: "EC002400: Bend for cable tray"
  },
  {
    classId: "EC002401",
    classGroup: "EG000004",
    artClassDesc: "Add-on tee cover for cable support system",
    artClassVersion: 8,
    label: "EC002401: Add-on tee cover for cable support system"
  },
  {
    classId: "EC002402",
    classGroup: "EG000004",
    artClassDesc: "Bend cover for cable support system",
    artClassVersion: 8,
    label: "EC002402: Bend cover for cable support system"
  },
  {
    classId: "EC002403",
    classGroup: "EG000004",
    artClassDesc: "Cover for cable support system",
    artClassVersion: 8,
    label: "EC002403: Cover for cable support system"
  },
  {
    classId: "EC002404",
    classGroup: "EG000004",
    artClassDesc: "Cover cross piece for cable support system",
    artClassVersion: 8,
    label: "EC002404: Cover cross piece for cable support system"
  },
  {
    classId: "EC002405",
    classGroup: "EG000004",
    artClassDesc: "Reduction piece cover for cable support system",
    artClassVersion: 8,
    label: "EC002405: Reduction piece cover for cable support system"
  },
  {
    classId: "EC002406",
    classGroup: "EG000004",
    artClassDesc: "Tee cover for cable support system",
    artClassVersion: 8,
    label: "EC002406: Tee cover for cable support system"
  },
  {
    classId: "EC002407",
    classGroup: "EG000004",
    artClassDesc: "Accessories/spare parts for installation rail current/data cable",
    artClassVersion: 6,
    label: "EC002407: Accessories/spare parts for installation rail current/data cable"
  },
  {
    classId: "EC002408",
    classGroup: "EG000004",
    artClassDesc: "Installation rail current/data cable",
    artClassVersion: 4,
    label: "EC002408: Installation rail current/data cable"
  },
  {
    classId: "EC002409",
    classGroup: "EG000004",
    artClassDesc: "Profile column for cable ladder",
    artClassVersion: 7,
    label: "EC002409: Profile column for cable ladder"
  },
  {
    classId: "EC002410",
    classGroup: "EG000004",
    artClassDesc: "Tube-/cable bracket for cable support system",
    artClassVersion: 7,
    label: "EC002410: Tube-/cable bracket for cable support system"
  },
  {
    classId: "EC002411",
    classGroup: "EG000004",
    artClassDesc: "Rung for cable ladder",
    artClassVersion: 7,
    label: "EC002411: Rung for cable ladder"
  },
  {
    classId: "EC002413",
    classGroup: "EG000004",
    artClassDesc: "Connector for cable support system",
    artClassVersion: 8,
    label: "EC002413: Connector for cable support system"
  },
  {
    classId: "EC002414",
    classGroup: "EG000004",
    artClassDesc: "Cable guide chain",
    artClassVersion: 7,
    label: "EC002414: Cable guide chain"
  },
  {
    classId: "EC002415",
    classGroup: "EG000004",
    artClassDesc: "Wall mounting for bracket cable support system",
    artClassVersion: 7,
    label: "EC002415: Wall mounting for bracket cable support system"
  },
  {
    classId: "EC002416",
    classGroup: "EG000005",
    artClassDesc: "Cover on-floor duct",
    artClassVersion: 6,
    label: "EC002416: Cover on-floor duct"
  },
  {
    classId: "EC002417",
    classGroup: "EG000005",
    artClassDesc: "Insulating plate mounting cover subfloor duct box",
    artClassVersion: 8,
    label: "EC002417: Insulating plate mounting cover subfloor duct box"
  },
  {
    classId: "EC002418",
    classGroup: "EG000005",
    artClassDesc: "Form dummy for subfloor installation",
    artClassVersion: 5,
    label: "EC002418: Form dummy for subfloor installation"
  },
  {
    classId: "EC002419",
    classGroup: "EG000005",
    artClassDesc: "Accessories/spare parts for surface tank",
    artClassVersion: 8,
    label: "EC002419: Accessories/spare parts for surface tank"
  },
  {
    classId: "EC002420",
    classGroup: "EG000005",
    artClassDesc: "Accessories/spare parts for subfloor installation ducting systems",
    artClassVersion: 9,
    label: "EC002420: Accessories/spare parts for subfloor installation ducting systems"
  },
  {
    classId: "EC002421",
    classGroup: "EG000005",
    artClassDesc: "Height adjustment ring/-frame for subfloor installation duct",
    artClassVersion: 7,
    label: "EC002421: Height adjustment ring/-frame for subfloor installation duct"
  },
  {
    classId: "EC002422",
    classGroup: "EG000005",
    artClassDesc: "Mounting socket for surface tank",
    artClassVersion: 8,
    label: "EC002422: Mounting socket for surface tank"
  },
  {
    classId: "EC002423",
    classGroup: "EG000005",
    artClassDesc: "Tube entry for subfloor installation duct box",
    artClassVersion: 6,
    label: "EC002423: Tube entry for subfloor installation duct box"
  },
  {
    classId: "EC002424",
    classGroup: "EG000006",
    artClassDesc: "Cover frame for domestic switching devices skirting duct",
    artClassVersion: 7,
    label: "EC002424: Cover frame for domestic switching devices skirting duct"
  },
  {
    classId: "EC002425",
    classGroup: "EG000006",
    artClassDesc: "Cover for tube-/duct inlet skirting duct",
    artClassVersion: 6,
    label: "EC002425: Cover for tube-/duct inlet skirting duct"
  },
  {
    classId: "EC002426",
    classGroup: "EG000006",
    artClassDesc: "Screening profile for skirting duct",
    artClassVersion: 5,
    label: "EC002426: Screening profile for skirting duct"
  },
  {
    classId: "EC002427",
    classGroup: "EG000006",
    artClassDesc: "Add-on tee for installation duct",
    artClassVersion: 6,
    label: "EC002427: Add-on tee for installation duct"
  },
  {
    classId: "EC002428",
    classGroup: "EG000006",
    artClassDesc: "Surface mounted junction box for installation duct",
    artClassVersion: 6,
    label: "EC002428: Surface mounted junction box for installation duct"
  },
  {
    classId: "EC002429",
    classGroup: "EG000006",
    artClassDesc: "Surface mounting plate for installation duct",
    artClassVersion: 6,
    label: "EC002429: Surface mounting plate for installation duct"
  },
  {
    classId: "EC002430",
    classGroup: "EG000006",
    artClassDesc: "Fin for wall duct",
    artClassVersion: 7,
    label: "EC002430: Fin for wall duct"
  },
  {
    classId: "EC002431",
    classGroup: "EG000006",
    artClassDesc: "Fin support for wall duct",
    artClassVersion: 8,
    label: "EC002431: Fin support for wall duct"
  },
  {
    classId: "EC002432",
    classGroup: "EG000006",
    artClassDesc: "Cable cover holder for skirting duct",
    artClassVersion: 5,
    label: "EC002432: Cable cover holder for skirting duct"
  },
  {
    classId: "EC002434",
    classGroup: "EG000006",
    artClassDesc: "Cable clip for wall duct",
    artClassVersion: 5,
    label: "EC002434: Cable clip for wall duct"
  },
  {
    classId: "EC002435",
    classGroup: "EG000006",
    artClassDesc: "Cable clip for skirting duct",
    artClassVersion: 5,
    label: "EC002435: Cable clip for skirting duct"
  },
  {
    classId: "EC002436",
    classGroup: "EG000006",
    artClassDesc: "Cable junction piece for skirting duct",
    artClassVersion: 6,
    label: "EC002436: Cable junction piece for skirting duct"
  },
  {
    classId: "EC002437",
    classGroup: "EG000006",
    artClassDesc: "Joint clip for cover wall duct",
    artClassVersion: 6,
    label: "EC002437: Joint clip for cover wall duct"
  },
  {
    classId: "EC002438",
    classGroup: "EG000006",
    artClassDesc: "Potential equalization for wall duct",
    artClassVersion: 5,
    label: "EC002438: Potential equalization for wall duct"
  },
  {
    classId: "EC002439",
    classGroup: "EG000006",
    artClassDesc: "Installation tube/installation duct entry for skirting duct",
    artClassVersion: 7,
    label: "EC002439: Installation tube/installation duct entry for skirting duct"
  },
  {
    classId: "EC002440",
    classGroup: "EG000006",
    artClassDesc: "Saw kernel for wall duct",
    artClassVersion: 4,
    label: "EC002440: Saw kernel for wall duct"
  },
  {
    classId: "EC002441",
    classGroup: "EG000006",
    artClassDesc: "Support clamp for wall duct",
    artClassVersion: 6,
    label: "EC002441: Support clamp for wall duct"
  },
  {
    classId: "EC002442",
    classGroup: "EG000006",
    artClassDesc: "Mounting for separation plate installation duct",
    artClassVersion: 5,
    label: "EC002442: Mounting for separation plate installation duct"
  },
  {
    classId: "EC002444",
    classGroup: "EG000006",
    artClassDesc: "Transition piece for installation tube to installation duct",
    artClassVersion: 6,
    label: "EC002444: Transition piece for installation tube to installation duct"
  },
  {
    classId: "EC002445",
    classGroup: "EG000006",
    artClassDesc: "Universal inner-/outer corner for wall duct",
    artClassVersion: 7,
    label: "EC002445: Universal inner-/outer corner for wall duct"
  },
  {
    classId: "EC002446",
    classGroup: "EG000006",
    artClassDesc: "Trunking base for skirting duct",
    artClassVersion: 7,
    label: "EC002446: Trunking base for skirting duct"
  },
  {
    classId: "EC002447",
    classGroup: "EG000006",
    artClassDesc: "Wall fastening clip for installation duct",
    artClassVersion: 6,
    label: "EC002447: Wall fastening clip for installation duct"
  },
  {
    classId: "EC002448",
    classGroup: "EG000006",
    artClassDesc: "Transition piece wall to ceiling for installation duct",
    artClassVersion: 7,
    label: "EC002448: Transition piece wall to ceiling for installation duct"
  },
  {
    classId: "EC002449",
    classGroup: "EG000006",
    artClassDesc: "Wall connection blind for wall duct",
    artClassVersion: 6,
    label: "EC002449: Wall connection blind for wall duct"
  },
  {
    classId: "EC002451",
    classGroup: "EG000008",
    artClassDesc: "Cover for extension module installation column",
    artClassVersion: 7,
    label: "EC002451: Cover for extension module installation column"
  },
  {
    classId: "EC002452",
    classGroup: "EG000008",
    artClassDesc: "Ceiling connection for installation column",
    artClassVersion: 7,
    label: "EC002452: Ceiling connection for installation column"
  },
  {
    classId: "EC002454",
    classGroup: "EG000008",
    artClassDesc: "Pedestal for installation column",
    artClassVersion: 7,
    label: "EC002454: Pedestal for installation column"
  },
  {
    classId: "EC002455",
    classGroup: "EG000054",
    artClassDesc: "Gas detector",
    artClassVersion: 8,
    label: "EC002455: Gas detector"
  },
  {
    classId: "EC002456",
    classGroup: "EG000054",
    artClassDesc: "Glass plate for non-automatic detector for danger detection system",
    artClassVersion: 7,
    label: "EC002456: Glass plate for non-automatic detector for danger detection system"
  },
  {
    classId: "EC002457",
    classGroup: "EG000037",
    artClassDesc: "Mechanical connection sleeve for telecommunications cable",
    artClassVersion: 4,
    label: "EC002457: Mechanical connection sleeve for telecommunications cable"
  },
  {
    classId: "EC002458",
    classGroup: "EG000047",
    artClassDesc: "Pressure spring",
    artClassVersion: 5,
    label: "EC002458: Pressure spring"
  },
  {
    classId: "EC002459",
    classGroup: "EG000047",
    artClassDesc: "Cable sheath repair kit",
    artClassVersion: 5,
    label: "EC002459: Cable sheath repair kit"
  },
  {
    classId: "EC002460",
    classGroup: "EG000013",
    artClassDesc: "Socket outlet/plug with protective contact",
    artClassVersion: 9,
    label: "EC002460: Socket outlet/plug with protective contact"
  },
  {
    classId: "EC002461",
    classGroup: "EG000013",
    artClassDesc: "Accessories/spare parts for socket outlets/plugs with protective contact",
    artClassVersion: 8,
    label: "EC002461: Accessories/spare parts for socket outlets/plugs with protective contact"
  },
  {
    classId: "EC002464",
    classGroup: "EG000047",
    artClassDesc: "Spreader cap",
    artClassVersion: 7,
    label: "EC002464: Spreader cap"
  },
  {
    classId: "EC002465",
    classGroup: "EG000047",
    artClassDesc: "Accessories/spare parts for cable end sleeves",
    artClassVersion: 7,
    label: "EC002465: Accessories/spare parts for cable end sleeves"
  },
  {
    classId: "EC002466",
    classGroup: "EG000032",
    artClassDesc: "Touch sensor connector for bus system",
    artClassVersion: 8,
    label: "EC002466: Touch sensor connector for bus system"
  },
  {
    classId: "EC002467",
    classGroup: "EG000026",
    artClassDesc: "Reflector for photoelectric sensor",
    artClassVersion: 6,
    label: "EC002467: Reflector for photoelectric sensor"
  },
  {
    classId: "EC002468",
    classGroup: "EG000054",
    artClassDesc: "Video switch",
    artClassVersion: 5,
    label: "EC002468: Video switch"
  },
  {
    classId: "EC002469",
    classGroup: "EG000005",
    artClassDesc: "Accessories/spare parts for on-floor duct",
    artClassVersion: 7,
    label: "EC002469: Accessories/spare parts for on-floor duct"
  },
  {
    classId: "EC002470",
    classGroup: "EG000002",
    artClassDesc: "Mounting clamp for cable protection tubes for underground application",
    artClassVersion: 6,
    label: "EC002470: Mounting clamp for cable protection tubes for underground application"
  },
  {
    classId: "EC002472",
    classGroup: "EG000030",
    artClassDesc: "Accessories/spare parts for luminaire mounting box",
    artClassVersion: 8,
    label: "EC002472: Accessories/spare parts for luminaire mounting box"
  },
  {
    classId: "EC002475",
    classGroup: "EG000026",
    artClassDesc: "Current value transformer",
    artClassVersion: 5,
    label: "EC002475: Current value transformer"
  },
  {
    classId: "EC002476",
    classGroup: "EG000026",
    artClassDesc: "Power value transformer",
    artClassVersion: 6,
    label: "EC002476: Power value transformer"
  },
  {
    classId: "EC002477",
    classGroup: "EG000026",
    artClassDesc: "Voltage value transformer",
    artClassVersion: 5,
    label: "EC002477: Voltage value transformer"
  },
  {
    classId: "EC002478",
    classGroup: "EG000026",
    artClassDesc: "Reactive power transformer",
    artClassVersion: 6,
    label: "EC002478: Reactive power transformer"
  },
  {
    classId: "EC002479",
    classGroup: "EG000026",
    artClassDesc: "Effective power (cos phi) value transformer",
    artClassVersion: 5,
    label: "EC002479: Effective power (cos phi) value transformer"
  },
  {
    classId: "EC002480",
    classGroup: "EG000014",
    artClassDesc: "Perilex coupler",
    artClassVersion: 5,
    label: "EC002480: Perilex coupler"
  },
  {
    classId: "EC002482",
    classGroup: "EG000020",
    artClassDesc: "D0-system screw adapter",
    artClassVersion: 6,
    label: "EC002482: D0-system screw adapter"
  },
  {
    classId: "EC002483",
    classGroup: "EG000017",
    artClassDesc: "Switch spool for power circuit breaker",
    artClassVersion: 4,
    label: "EC002483: Switch spool for power circuit breaker"
  },
  {
    classId: "EC002484",
    classGroup: "EG000013",
    artClassDesc: "Sound system for domestic switching devices",
    artClassVersion: 9,
    label: "EC002484: Sound system for domestic switching devices"
  },
  {
    classId: "EC002485",
    classGroup: "EG000017",
    artClassDesc: "Three-phase control transformer",
    artClassVersion: 7,
    label: "EC002485: Three-phase control transformer"
  },
  {
    classId: "EC002486",
    classGroup: "EG000017",
    artClassDesc: "One-phase control transformer",
    artClassVersion: 7,
    label: "EC002486: One-phase control transformer"
  },
  {
    classId: "EC002487",
    classGroup: "EG000038",
    artClassDesc: "Explosion proof telephone",
    artClassVersion: 6,
    label: "EC002487: Explosion proof telephone"
  },
  {
    classId: "EC002488",
    classGroup: "EG000038",
    artClassDesc: "Signal generator for explosion proof telephone",
    artClassVersion: 6,
    label: "EC002488: Signal generator for explosion proof telephone"
  },
  {
    classId: "EC002489",
    classGroup: "EG000038",
    artClassDesc: "Weatherproof telephone",
    artClassVersion: 6,
    label: "EC002489: Weatherproof telephone"
  },
  {
    classId: "EC002490",
    classGroup: "EG000038",
    artClassDesc: "Signal generator for weatherproof telephone",
    artClassVersion: 6,
    label: "EC002490: Signal generator for weatherproof telephone"
  },
  {
    classId: "EC002491",
    classGroup: "EG000038",
    artClassDesc: "Telephone hood",
    artClassVersion: 4,
    label: "EC002491: Telephone hood"
  },
  {
    classId: "EC002492",
    classGroup: "EG000035",
    artClassDesc: "Explosion proof loudspeaker",
    artClassVersion: 5,
    label: "EC002492: Explosion proof loudspeaker"
  },
  {
    classId: "EC002493",
    classGroup: "EG000035",
    artClassDesc: "Explosion proof indicator light",
    artClassVersion: 6,
    label: "EC002493: Explosion proof indicator light"
  },
  {
    classId: "EC002494",
    classGroup: "EG000035",
    artClassDesc: "Signal device with LED-multicolourlamp",
    artClassVersion: 6,
    label: "EC002494: Signal device with LED-multicolourlamp"
  },
  {
    classId: "EC002495",
    classGroup: "EG000021",
    artClassDesc: "Measurement device for surge protection",
    artClassVersion: 6,
    label: "EC002495: Measurement device for surge protection"
  },
  {
    classId: "EC002496",
    classGroup: "EG000021",
    artClassDesc: "Accessories/spare parts for surge protection power supply systems",
    artClassVersion: 8,
    label: "EC002496: Accessories/spare parts for surge protection power supply systems"
  },
  {
    classId: "EC002497",
    classGroup: "EG000021",
    artClassDesc: "Accessories/spare parts for surge protection data networks/MCR-technology",
    artClassVersion: 8,
    label: "EC002497: Accessories/spare parts for surge protection data networks/MCR-technology"
  },
  {
    classId: "EC002498",
    classGroup: "EG000017",
    artClassDesc: "Accessories/spare parts for low-voltage switch technology",
    artClassVersion: 8,
    label: "EC002498: Accessories/spare parts for low-voltage switch technology"
  },
  {
    classId: "EC002499",
    classGroup: "EG000011",
    artClassDesc: "Network/server enclosure",
    artClassVersion: 7,
    label: "EC002499: Network/server enclosure"
  },
  {
    classId: "EC002500",
    classGroup: "EG000011",
    artClassDesc: "Network cabinet",
    artClassVersion: 7,
    label: "EC002500: Network cabinet"
  },
  {
    classId: "EC002501",
    classGroup: "EG000011",
    artClassDesc: "Telecommunications cabinet",
    artClassVersion: 7,
    label: "EC002501: Telecommunications cabinet"
  },
  {
    classId: "EC002502",
    classGroup: "EG000011",
    artClassDesc: "Enclosure/housing for PC, monitor or periphery",
    artClassVersion: 7,
    label: "EC002502: Enclosure/housing for PC, monitor or periphery"
  },
  {
    classId: "EC002503",
    classGroup: "EG000011",
    artClassDesc: "Explosion proof enclosure/housing",
    artClassVersion: 7,
    label: "EC002503: Explosion proof enclosure/housing"
  },
  {
    classId: "EC002504",
    classGroup: "EG000011",
    artClassDesc: "Command panel",
    artClassVersion: 6,
    label: "EC002504: Command panel"
  },
  {
    classId: "EC002505",
    classGroup: "EG000011",
    artClassDesc: "Kiosk system",
    artClassVersion: 6,
    label: "EC002505: Kiosk system"
  },
  {
    classId: "EC002506",
    classGroup: "EG000011",
    artClassDesc: "Cabling rack (electro/IT)",
    artClassVersion: 5,
    label: "EC002506: Cabling rack (electro/IT)"
  },
  {
    classId: "EC002507",
    classGroup: "EG000011",
    artClassDesc: "Laboratory rack (electro/IT)",
    artClassVersion: 6,
    label: "EC002507: Laboratory rack (electro/IT)"
  },
  {
    classId: "EC002508",
    classGroup: "EG000011",
    artClassDesc: "Laboratory trolley (electro/IT)",
    artClassVersion: 5,
    label: "EC002508: Laboratory trolley (electro/IT)"
  },
  {
    classId: "EC002509",
    classGroup: "EG000011",
    artClassDesc: "Climate controlled enclosure/cabinet",
    artClassVersion: 5,
    label: "EC002509: Climate controlled enclosure/cabinet"
  },
  {
    classId: "EC002510",
    classGroup: "EG000011",
    artClassDesc: "Climate control door (enclosure/cabinet)",
    artClassVersion: 5,
    label: "EC002510: Climate control door (enclosure/cabinet)"
  },
  {
    classId: "EC002511",
    classGroup: "EG000011",
    artClassDesc: "Climate control side panel (enclosure/cabinet)",
    artClassVersion: 5,
    label: "EC002511: Climate control side panel (enclosure/cabinet)"
  },
  {
    classId: "EC002513",
    classGroup: "EG000011",
    artClassDesc: "Filter (enclosure/cabinet climate control)",
    artClassVersion: 7,
    label: "EC002513: Filter (enclosure/cabinet climate control)"
  },
  {
    classId: "EC002514",
    classGroup: "EG000011",
    artClassDesc: "Air/air heat exchanger (enclosure/cabinet climate control)",
    artClassVersion: 5,
    label: "EC002514: Air/air heat exchanger (enclosure/cabinet climate control)"
  },
  {
    classId: "EC002515",
    classGroup: "EG000011",
    artClassDesc: "Air/water heat exchanger (enclosure/cabinet climate control)",
    artClassVersion: 5,
    label: "EC002515: Air/water heat exchanger (enclosure/cabinet climate control)"
  },
  {
    classId: "EC002516",
    classGroup: "EG000011",
    artClassDesc: "Liquid cooling unit (enclosure/cabinet)",
    artClassVersion: 5,
    label: "EC002516: Liquid cooling unit (enclosure/cabinet)"
  },
  {
    classId: "EC002517",
    classGroup: "EG000011",
    artClassDesc: "Case for component installation with modular spacing 19 inch",
    artClassVersion: 6,
    label: "EC002517: Case for component installation with modular spacing 19 inch"
  },
  {
    classId: "EC002518",
    classGroup: "EG000011",
    artClassDesc: "Profile (enclosure/cabinet)",
    artClassVersion: 5,
    label: "EC002518: Profile (enclosure/cabinet)"
  },
  {
    classId: "EC002519",
    classGroup: "EG000011",
    artClassDesc: "Front plate for unit with modular spacing 19 inch",
    artClassVersion: 6,
    label: "EC002519: Front plate for unit with modular spacing 19 inch"
  },
  {
    classId: "EC002520",
    classGroup: "EG000011",
    artClassDesc: "Plug-in unit for modular spacing 19 inch",
    artClassVersion: 7,
    label: "EC002520: Plug-in unit for modular spacing 19 inch"
  },
  {
    classId: "EC002522",
    classGroup: "EG000011",
    artClassDesc: "Drive unit for modular spacing 19 inch",
    artClassVersion: 6,
    label: "EC002522: Drive unit for modular spacing 19 inch"
  },
  {
    classId: "EC002523",
    classGroup: "EG000011",
    artClassDesc: "Mounting part for unit with modular spacing 19 inch",
    artClassVersion: 5,
    label: "EC002523: Mounting part for unit with modular spacing 19 inch"
  },
  {
    classId: "EC002524",
    classGroup: "EG000011",
    artClassDesc: "Side-/back panel (enclosure/cabinet)",
    artClassVersion: 5,
    label: "EC002524: Side-/back panel (enclosure/cabinet)"
  },
  {
    classId: "EC002525",
    classGroup: "EG000011",
    artClassDesc: "Divider panel (enclosure/cabinet)",
    artClassVersion: 6,
    label: "EC002525: Divider panel (enclosure/cabinet)"
  },
  {
    classId: "EC002527",
    classGroup: "EG000011",
    artClassDesc: "Window (enclosure/cabinet)",
    artClassVersion: 7,
    label: "EC002527: Window (enclosure/cabinet)"
  },
  {
    classId: "EC002528",
    classGroup: "EG000011",
    artClassDesc: "Spacer frame (enclosure/cabinet)",
    artClassVersion: 5,
    label: "EC002528: Spacer frame (enclosure/cabinet)"
  },
  {
    classId: "EC002529",
    classGroup: "EG000011",
    artClassDesc: "Display panel (enclosure/cabinet)",
    artClassVersion: 5,
    label: "EC002529: Display panel (enclosure/cabinet)"
  },
  {
    classId: "EC002530",
    classGroup: "EG000011",
    artClassDesc: "Top mounting module (enclosure/cabinet)",
    artClassVersion: 6,
    label: "EC002530: Top mounting module (enclosure/cabinet)"
  },
  {
    classId: "EC002534",
    classGroup: "EG000011",
    artClassDesc: "Column (enclosure/cabinet)",
    artClassVersion: 6,
    label: "EC002534: Column (enclosure/cabinet)"
  },
  {
    classId: "EC002535",
    classGroup: "EG000011",
    artClassDesc: "Accessories/spare parts for column (enclosure/cabinet)",
    artClassVersion: 6,
    label: "EC002535: Accessories/spare parts for column (enclosure/cabinet)"
  },
  {
    classId: "EC002537",
    classGroup: "EG000024",
    artClassDesc: "Application software for automation",
    artClassVersion: 9,
    label: "EC002537: Application software for automation"
  },
  {
    classId: "EC002538",
    classGroup: "EG000024",
    artClassDesc: "Configurable control component",
    artClassVersion: 8,
    label: "EC002538: Configurable control component"
  },
  {
    classId: "EC002540",
    classGroup: "EG000017",
    artClassDesc: "DC-power supply",
    artClassVersion: 8,
    label: "EC002540: DC-power supply"
  },
  {
    classId: "EC002541",
    classGroup: "EG000017",
    artClassDesc: "AC-power supply",
    artClassVersion: 8,
    label: "EC002541: AC-power supply"
  },
  {
    classId: "EC002542",
    classGroup: "EG000017",
    artClassDesc: "AC/DC-power supply",
    artClassVersion: 8,
    label: "EC002542: AC/DC-power supply"
  },
  {
    classId: "EC002544",
    classGroup: "EG000026",
    artClassDesc: "Magnetic proximity switch",
    artClassVersion: 8,
    label: "EC002544: Magnetic proximity switch"
  },
  {
    classId: "EC002549",
    classGroup: "EG000026",
    artClassDesc: "Light curtain",
    artClassVersion: 6,
    label: "EC002549: Light curtain"
  },
  {
    classId: "EC002550",
    classGroup: "EG000026",
    artClassDesc: "Laser scanner",
    artClassVersion: 4,
    label: "EC002550: Laser scanner"
  },
  {
    classId: "EC002552",
    classGroup: "EG000017",
    artClassDesc: "Power contactor, DC switching",
    artClassVersion: 6,
    label: "EC002552: Power contactor, DC switching"
  },
  {
    classId: "EC002555",
    classGroup: "EG000012",
    artClassDesc: "Test plug for terminal blocks",
    artClassVersion: 5,
    label: "EC002555: Test plug for terminal blocks"
  },
  {
    classId: "EC002556",
    classGroup: "EG000030",
    artClassDesc: "Electrical accessories/spare parts for luminaires",
    artClassVersion: 9,
    label: "EC002556: Electrical accessories/spare parts for luminaires"
  },
  {
    classId: "EC002557",
    classGroup: "EG000030",
    artClassDesc: "Mechanical accessories/spare parts for luminaires",
    artClassVersion: 8,
    label: "EC002557: Mechanical accessories/spare parts for luminaires"
  },
  {
    classId: "EC002558",
    classGroup: "EG000030",
    artClassDesc: "Light technical accessories/spare parts for luminaires",
    artClassVersion: 9,
    label: "EC002558: Light technical accessories/spare parts for luminaires"
  },
  {
    classId: "EC002559",
    classGroup: "EG000030",
    artClassDesc: "Accessories/spare parts for light pole",
    artClassVersion: 8,
    label: "EC002559: Accessories/spare parts for light pole"
  },
  {
    classId: "EC002560",
    classGroup: "EG000057",
    artClassDesc: "Plug-in connector for plug-in building installation",
    artClassVersion: 7,
    label: "EC002560: Plug-in connector for plug-in building installation"
  },
  {
    classId: "EC002561",
    classGroup: "EG000015",
    artClassDesc: "Direction change for rail distributor",
    artClassVersion: 6,
    label: "EC002561: Direction change for rail distributor"
  },
  {
    classId: "EC002562",
    classGroup: "EG000015",
    artClassDesc: "Busbar trunk unit for riser cable",
    artClassVersion: 6,
    label: "EC002562: Busbar trunk unit for riser cable"
  },
  {
    classId: "EC002563",
    classGroup: "EG000017",
    artClassDesc: "Coil for low-voltage",
    artClassVersion: 7,
    label: "EC002563: Coil for low-voltage"
  },
  {
    classId: "EC002564",
    classGroup: "EG000017",
    artClassDesc: "Filter for low-voltage",
    artClassVersion: 7,
    label: "EC002564: Filter for low-voltage"
  },
  {
    classId: "EC002565",
    classGroup: "EG000017",
    artClassDesc: "Filter circuit coil for low-voltage",
    artClassVersion: 6,
    label: "EC002565: Filter circuit coil for low-voltage"
  },
  {
    classId: "EC002566",
    classGroup: "EG000057",
    artClassDesc: "Device connection for plug-in building installation",
    artClassVersion: 6,
    label: "EC002566: Device connection for plug-in building installation"
  },
  {
    classId: "EC002567",
    classGroup: "EG000057",
    artClassDesc: "Compact distributor for plug-in building installation",
    artClassVersion: 6,
    label: "EC002567: Compact distributor for plug-in building installation"
  },
  {
    classId: "EC002568",
    classGroup: "EG000017",
    artClassDesc: "Relay for thermistor protection (PTC)",
    artClassVersion: 6,
    label: "EC002568: Relay for thermistor protection (PTC)"
  },
  {
    classId: "EC002571",
    classGroup: "EG000026",
    artClassDesc: "Agreement switch",
    artClassVersion: 7,
    label: "EC002571: Agreement switch"
  },
  {
    classId: "EC002572",
    classGroup: "EG000017",
    artClassDesc: "Motor management device",
    artClassVersion: 8,
    label: "EC002572: Motor management device"
  },
  {
    classId: "EC002574",
    classGroup: "EG000023",
    artClassDesc: "Built-in field for telecommunications",
    artClassVersion: 5,
    label: "EC002574: Built-in field for telecommunications"
  },
  {
    classId: "EC002575",
    classGroup: "EG000015",
    artClassDesc: "Distributor assembly for serial built-in device",
    artClassVersion: 4,
    label: "EC002575: Distributor assembly for serial built-in device"
  },
  {
    classId: "EC002577",
    classGroup: "EG000006",
    artClassDesc: "Flat bend for on-floor installation duct",
    artClassVersion: 6,
    label: "EC002577: Flat bend for on-floor installation duct"
  },
  {
    classId: "EC002578",
    classGroup: "EG000006",
    artClassDesc: "Coupler for on-floor installation duct",
    artClassVersion: 5,
    label: "EC002578: Coupler for on-floor installation duct"
  },
  {
    classId: "EC002580",
    classGroup: "EG000019",
    artClassDesc: "Flow monitoring equipment",
    artClassVersion: 7,
    label: "EC002580: Flow monitoring equipment"
  },
  {
    classId: "EC002581",
    classGroup: "EG000024",
    artClassDesc: "PLC device set",
    artClassVersion: 5,
    label: "EC002581: PLC device set"
  },
  {
    classId: "EC002582",
    classGroup: "EG000024",
    artClassDesc: "Function block/library",
    artClassVersion: 4,
    label: "EC002582: Function block/library"
  },
  {
    classId: "EC002583",
    classGroup: "EG000024",
    artClassDesc: "Fieldbus, decentr. periphery - power supply module",
    artClassVersion: 4,
    label: "EC002583: Fieldbus, decentr. periphery - power supply module"
  },
  {
    classId: "EC002584",
    classGroup: "EG000024",
    artClassDesc: "Accessories/spare parts for controls",
    artClassVersion: 6,
    label: "EC002584: Accessories/spare parts for controls"
  },
  {
    classId: "EC002585",
    classGroup: "EG000026",
    artClassDesc: "Passive sensor-actuator interface (with cable)",
    artClassVersion: 7,
    label: "EC002585: Passive sensor-actuator interface (with cable)"
  },
  {
    classId: "EC002586",
    classGroup: "EG000019",
    artClassDesc: "Accessories/spare parts for switching relay",
    artClassVersion: 6,
    label: "EC002586: Accessories/spare parts for switching relay"
  },
  {
    classId: "EC002587",
    classGroup: "EG000057",
    artClassDesc: "Patch cord for plug-in building installation",
    artClassVersion: 7,
    label: "EC002587: Patch cord for plug-in building installation"
  },
  {
    classId: "EC002588",
    classGroup: "EG000057",
    artClassDesc: "Flat cable adapter for plug-in building installation",
    artClassVersion: 7,
    label: "EC002588: Flat cable adapter for plug-in building installation"
  },
  {
    classId: "EC002590",
    classGroup: "EG000037",
    artClassDesc: "Communication connection box",
    artClassVersion: 7,
    label: "EC002590: Communication connection box"
  },
  {
    classId: "EC002591",
    classGroup: "EG000026",
    artClassDesc: "Hinge switch",
    artClassVersion: 7,
    label: "EC002591: Hinge switch"
  },
  {
    classId: "EC002592",
    classGroup: "EG000026",
    artClassDesc: "Position switch with separate actuator",
    artClassVersion: 7,
    label: "EC002592: Position switch with separate actuator"
  },
  {
    classId: "EC002593",
    classGroup: "EG000026",
    artClassDesc: "Position switch with guard locking",
    artClassVersion: 7,
    label: "EC002593: Position switch with guard locking"
  },
  {
    classId: "EC002594",
    classGroup: "EG000026",
    artClassDesc: "Accessories/spare parts for position switches",
    artClassVersion: 7,
    label: "EC002594: Accessories/spare parts for position switches"
  },
  {
    classId: "EC002595",
    classGroup: "EG000005",
    artClassDesc: "Separation plate for on-floor duct",
    artClassVersion: 4,
    label: "EC002595: Separation plate for on-floor duct"
  },
  {
    classId: "EC002596",
    classGroup: "EG000006",
    artClassDesc: "Flexible cable guiding system",
    artClassVersion: 6,
    label: "EC002596: Flexible cable guiding system"
  },
  {
    classId: "EC002597",
    classGroup: "EG000037",
    artClassDesc: "Data communication connection box copper (twisted pair) industry",
    artClassVersion: 7,
    label: "EC002597: Data communication connection box copper (twisted pair) industry"
  },
  {
    classId: "EC002598",
    classGroup: "EG000037",
    artClassDesc: "Data communication connection box fibre optic industry",
    artClassVersion: 7,
    label: "EC002598: Data communication connection box fibre optic industry"
  },
  {
    classId: "EC002599",
    classGroup: "EG000037",
    artClassDesc: "Patch cord copper (twisted pair) industry",
    artClassVersion: 9,
    label: "EC002599: Patch cord copper (twisted pair) industry"
  },
  {
    classId: "EC002600",
    classGroup: "EG000048",
    artClassDesc: "Box/housing for surface mounting on the wall/ceiling",
    artClassVersion: 8,
    label: "EC002600: Box/housing for surface mounting on the wall/ceiling"
  },
  {
    classId: "EC002601",
    classGroup: "EG000048",
    artClassDesc: "Box/housing for built-in mounting in the wall/ceiling",
    artClassVersion: 9,
    label: "EC002601: Box/housing for built-in mounting in the wall/ceiling"
  },
  {
    classId: "EC002602",
    classGroup: "EG000005",
    artClassDesc: "Cable outlet for subfloor system",
    artClassVersion: 5,
    label: "EC002602: Cable outlet for subfloor system"
  },
  {
    classId: "EC002603",
    classGroup: "EG000005",
    artClassDesc: "Levelling cassette for duct mounting, raised and subfloor installation",
    artClassVersion: 7,
    label: "EC002603: Levelling cassette for duct mounting, raised and subfloor installation"
  },
  {
    classId: "EC002604",
    classGroup: "EG000003",
    artClassDesc: "Cable bundle hose",
    artClassVersion: 8,
    label: "EC002604: Cable bundle hose"
  },
  {
    classId: "EC002605",
    classGroup: "EG000037",
    artClassDesc: "Multifunctional connection box",
    artClassVersion: 6,
    label: "EC002605: Multifunctional connection box"
  },
  {
    classId: "EC002606",
    classGroup: "EG000037",
    artClassDesc: "Insert for multifunctional connection box",
    artClassVersion: 5,
    label: "EC002606: Insert for multifunctional connection box"
  },
  {
    classId: "EC002607",
    classGroup: "EG000037",
    artClassDesc: "Patch cord fibre optic industry",
    artClassVersion: 9,
    label: "EC002607: Patch cord fibre optic industry"
  },
  {
    classId: "EC002608",
    classGroup: "EG000037",
    artClassDesc: "Accessories/spare parts for modular connection system",
    artClassVersion: 6,
    label: "EC002608: Accessories/spare parts for modular connection system"
  },
  {
    classId: "EC002609",
    classGroup: "EG000037",
    artClassDesc: "Accessories/spare parts for optic fibre technology",
    artClassVersion: 8,
    label: "EC002609: Accessories/spare parts for optic fibre technology"
  },
  {
    classId: "EC002610",
    classGroup: "EG000037",
    artClassDesc: "Accessories/spare parts for communication technology",
    artClassVersion: 7,
    label: "EC002610: Accessories/spare parts for communication technology"
  },
  {
    classId: "EC002611",
    classGroup: "EG000017",
    artClassDesc: "Control switch",
    artClassVersion: 8,
    label: "EC002611: Control switch"
  },
  {
    classId: "EC002613",
    classGroup: "EG000017",
    artClassDesc: "Extension module for motor management device",
    artClassVersion: 8,
    label: "EC002613: Extension module for motor management device"
  },
  {
    classId: "EC002614",
    classGroup: "EG000017",
    artClassDesc: "Operator panel for Motor management device",
    artClassVersion: 7,
    label: "EC002614: Operator panel for Motor management device"
  },
  {
    classId: "EC002615",
    classGroup: "EG000017",
    artClassDesc: "Accessories/spare parts for electronic motor control and protection device",
    artClassVersion: 5,
    label: "EC002615: Accessories/spare parts for electronic motor control and protection device"
  },
  {
    classId: "EC002616",
    classGroup: "EG000026",
    artClassDesc: "Temperature switch",
    artClassVersion: 7,
    label: "EC002616: Temperature switch"
  },
  {
    classId: "EC002617",
    classGroup: "EG000011",
    artClassDesc: "Cover/infill panel/identification strip (enclosure/cabinet)",
    artClassVersion: 7,
    label: "EC002617: Cover/infill panel/identification strip (enclosure/cabinet)"
  },
  {
    classId: "EC002620",
    classGroup: "EG000011",
    artClassDesc: "Components for installation (enclosure/cabinet)",
    artClassVersion: 8,
    label: "EC002620: Components for installation (enclosure/cabinet)"
  },
  {
    classId: "EC002621",
    classGroup: "EG000011",
    artClassDesc: "Components for transport (enclosure/cabinet)",
    artClassVersion: 6,
    label: "EC002621: Components for transport (enclosure/cabinet)"
  },
  {
    classId: "EC002622",
    classGroup: "EG000011",
    artClassDesc: "Components for arrangement/baying system (enclosure/cabinet)",
    artClassVersion: 7,
    label: "EC002622: Components for arrangement/baying system (enclosure/cabinet)"
  },
  {
    classId: "EC002623",
    classGroup: "EG000011",
    artClassDesc: "Component for earthing/equipotential bonding (enclosure/cabinet)",
    artClassVersion: 8,
    label: "EC002623: Component for earthing/equipotential bonding (enclosure/cabinet)"
  },
  {
    classId: "EC002624",
    classGroup: "EG000011",
    artClassDesc: "Components for wiring, cable entry, cable fixing (enclosure/cabinet)",
    artClassVersion: 6,
    label: "EC002624: Components for wiring, cable entry, cable fixing (enclosure/cabinet)"
  },
  {
    classId: "EC002625",
    classGroup: "EG000011",
    artClassDesc: "Mounting accessories (enclosure/cabinet)",
    artClassVersion: 9,
    label: "EC002625: Mounting accessories (enclosure/cabinet)"
  },
  {
    classId: "EC002626",
    classGroup: "EG000011",
    artClassDesc: "Components for door (enclosure/cabinet)",
    artClassVersion: 6,
    label: "EC002626: Components for door (enclosure/cabinet)"
  },
  {
    classId: "EC002627",
    classGroup: "EG000011",
    artClassDesc: "Monitoring system (enclosure/cabinet)",
    artClassVersion: 7,
    label: "EC002627: Monitoring system (enclosure/cabinet)"
  },
  {
    classId: "EC002631",
    classGroup: "EG000024",
    artClassDesc: "Slot PLC (PC-based controls)",
    artClassVersion: 6,
    label: "EC002631: Slot PLC (PC-based controls)"
  },
  {
    classId: "EC002632",
    classGroup: "EG000024",
    artClassDesc: "Complete system (PC-based controls)",
    artClassVersion: 5,
    label: "EC002632: Complete system (PC-based controls)"
  },
  {
    classId: "EC002633",
    classGroup: "EG000024",
    artClassDesc: "Soft PLC (PC-based controls)",
    artClassVersion: 8,
    label: "EC002633: Soft PLC (PC-based controls)"
  },
  {
    classId: "EC002634",
    classGroup: "EG000024",
    artClassDesc: "Fieldbus, decentr. periphery - pneumatics module",
    artClassVersion: 9,
    label: "EC002634: Fieldbus, decentr. periphery - pneumatics module"
  },
  {
    classId: "EC002635",
    classGroup: "EG000058",
    artClassDesc: "Circular connector for field assembly (industrial connector)",
    artClassVersion: 8,
    label: "EC002635: Circular connector for field assembly (industrial connector)"
  },
  {
    classId: "EC002636",
    classGroup: "EG000058",
    artClassDesc: "Rectangular connector, set (industrial connector)",
    artClassVersion: 7,
    label: "EC002636: Rectangular connector, set (industrial connector)"
  },
  {
    classId: "EC002637",
    classGroup: "EG000058",
    artClassDesc: "Printed circuit board connector",
    artClassVersion: 7,
    label: "EC002637: Printed circuit board connector"
  },
  {
    classId: "EC002638",
    classGroup: "EG000058",
    artClassDesc: "Cable connector",
    artClassVersion: 7,
    label: "EC002638: Cable connector"
  },
  {
    classId: "EC002639",
    classGroup: "EG000058",
    artClassDesc: "RF connector",
    artClassVersion: 7,
    label: "EC002639: RF connector"
  },
  {
    classId: "EC002640",
    classGroup: "EG000058",
    artClassDesc: "I/O connector",
    artClassVersion: 5,
    label: "EC002640: I/O connector"
  },
  {
    classId: "EC002641",
    classGroup: "EG000058",
    artClassDesc: "Modular connector (industrial connector)",
    artClassVersion: 6,
    label: "EC002641: Modular connector (industrial connector)"
  },
  {
    classId: "EC002642",
    classGroup: "EG000058",
    artClassDesc: "Chipcard connector",
    artClassVersion: 7,
    label: "EC002642: Chipcard connector"
  },
  {
    classId: "EC002643",
    classGroup: "EG000058",
    artClassDesc: "Printed circuit board terminal",
    artClassVersion: 8,
    label: "EC002643: Printed circuit board terminal"
  },
  {
    classId: "EC002648",
    classGroup: "EG000054",
    artClassDesc: "Accessories/spare parts for fire detector",
    artClassVersion: 8,
    label: "EC002648: Accessories/spare parts for fire detector"
  },
  {
    classId: "EC002649",
    classGroup: "EG000013",
    artClassDesc: "Accessories/spare parts for domestic switching devices",
    artClassVersion: 7,
    label: "EC002649: Accessories/spare parts for domestic switching devices"
  },
  {
    classId: "EC002650",
    classGroup: "EG000047",
    artClassDesc: "Connection for screened wires",
    artClassVersion: 6,
    label: "EC002650: Connection for screened wires"
  },
  {
    classId: "EC002651",
    classGroup: "EG000026",
    artClassDesc: "Optical fibre sensor/optical fibre amplifier",
    artClassVersion: 7,
    label: "EC002651: Optical fibre sensor/optical fibre amplifier"
  },
  {
    classId: "EC002653",
    classGroup: "EG000026",
    artClassDesc: "Isolation amplifier",
    artClassVersion: 9,
    label: "EC002653: Isolation amplifier"
  },
  {
    classId: "EC002654",
    classGroup: "EG000026",
    artClassDesc: "Limit signal transmitter",
    artClassVersion: 9,
    label: "EC002654: Limit signal transmitter"
  },
  {
    classId: "EC002655",
    classGroup: "EG000048",
    artClassDesc: "Cover for box/housing for surface mounting on the wall/ceiling",
    artClassVersion: 8,
    label: "EC002655: Cover for box/housing for surface mounting on the wall/ceiling"
  },
  {
    classId: "EC002656",
    classGroup: "EG000048",
    artClassDesc: "Cover for box/housing for built-in mounting in the wall/ceiling",
    artClassVersion: 6,
    label: "EC002656: Cover for box/housing for built-in mounting in the wall/ceiling"
  },
  {
    classId: "EC002657",
    classGroup: "EG000056",
    artClassDesc: "Fire-resistant duct",
    artClassVersion: 7,
    label: "EC002657: Fire-resistant duct"
  },
  {
    classId: "EC002658",
    classGroup: "EG000056",
    artClassDesc: "Outer corner for fire-resistant duct",
    artClassVersion: 6,
    label: "EC002658: Outer corner for fire-resistant duct"
  },
  {
    classId: "EC002659",
    classGroup: "EG000056",
    artClassDesc: "End piece for fire-resistant duct",
    artClassVersion: 6,
    label: "EC002659: End piece for fire-resistant duct"
  },
  {
    classId: "EC002660",
    classGroup: "EG000056",
    artClassDesc: "Flat bend for fire-resistant duct",
    artClassVersion: 6,
    label: "EC002660: Flat bend for fire-resistant duct"
  },
  {
    classId: "EC002661",
    classGroup: "EG000056",
    artClassDesc: "Inner corner for fire-resistant duct",
    artClassVersion: 6,
    label: "EC002661: Inner corner for fire-resistant duct"
  },
  {
    classId: "EC002662",
    classGroup: "EG000056",
    artClassDesc: "Cable clip for fire-resistant duct",
    artClassVersion: 4,
    label: "EC002662: Cable clip for fire-resistant duct"
  },
  {
    classId: "EC002663",
    classGroup: "EG000056",
    artClassDesc: "Cross piece for fire-resistant duct",
    artClassVersion: 6,
    label: "EC002663: Cross piece for fire-resistant duct"
  },
  {
    classId: "EC002664",
    classGroup: "EG000056",
    artClassDesc: "Coupler for fire-resistant duct",
    artClassVersion: 6,
    label: "EC002664: Coupler for fire-resistant duct"
  },
  {
    classId: "EC002666",
    classGroup: "EG000056",
    artClassDesc: "Separation plate for fire-resistant duct",
    artClassVersion: 4,
    label: "EC002666: Separation plate for fire-resistant duct"
  },
  {
    classId: "EC002667",
    classGroup: "EG000056",
    artClassDesc: "Tee for fire-resistant duct",
    artClassVersion: 6,
    label: "EC002667: Tee for fire-resistant duct"
  },
  {
    classId: "EC002670",
    classGroup: "EG000056",
    artClassDesc: "Accessories/spare parts for fire-resistant duct",
    artClassVersion: 5,
    label: "EC002670: Accessories/spare parts for fire-resistant duct"
  },
  {
    classId: "EC002671",
    classGroup: "EG000056",
    artClassDesc: "Accessories/spare parts for fire partitioning",
    artClassVersion: 7,
    label: "EC002671: Accessories/spare parts for fire partitioning"
  },
  {
    classId: "EC002673",
    classGroup: "EG000021",
    artClassDesc: "Distance holder for lightning protection",
    artClassVersion: 5,
    label: "EC002673: Distance holder for lightning protection"
  },
  {
    classId: "EC002674",
    classGroup: "EG000021",
    artClassDesc: "Set for earthing/lightning protection",
    artClassVersion: 5,
    label: "EC002674: Set for earthing/lightning protection"
  },
  {
    classId: "EC002675",
    classGroup: "EG000021",
    artClassDesc: "Set for internal lightning protection/surge protection",
    artClassVersion: 5,
    label: "EC002675: Set for internal lightning protection/surge protection"
  },
  {
    classId: "EC002676",
    classGroup: "EG000021",
    artClassDesc: "Surge protection device medium voltage 1-36 kV",
    artClassVersion: 6,
    label: "EC002676: Surge protection device medium voltage 1-36 kV"
  },
  {
    classId: "EC002677",
    classGroup: "EG000021",
    artClassDesc: "Surge protection device medium voltage >36 kV",
    artClassVersion: 4,
    label: "EC002677: Surge protection device medium voltage >36 kV"
  },
  {
    classId: "EC002678",
    classGroup: "EG000030",
    artClassDesc: "Emergency lighting power supply unit",
    artClassVersion: 6,
    label: "EC002678: Emergency lighting power supply unit"
  },
  {
    classId: "EC002679",
    classGroup: "EG000040",
    artClassDesc: "Accessories/spare parts for small domestic appliances",
    artClassVersion: 8,
    label: "EC002679: Accessories/spare parts for small domestic appliances"
  },
  {
    classId: "EC002680",
    classGroup: "EG000040",
    artClassDesc: "Accessories/spare parts for vacuum cleaner",
    artClassVersion: 6,
    label: "EC002680: Accessories/spare parts for vacuum cleaner"
  },
  {
    classId: "EC002683",
    classGroup: "EG000040",
    artClassDesc: "Accessories/spare parts for kitchen machine",
    artClassVersion: 5,
    label: "EC002683: Accessories/spare parts for kitchen machine"
  },
  {
    classId: "EC002684",
    classGroup: "EG000040",
    artClassDesc: "Cooking pot",
    artClassVersion: 6,
    label: "EC002684: Cooking pot"
  },
  {
    classId: "EC002685",
    classGroup: "EG000054",
    artClassDesc: "Central fire alarm control panel",
    artClassVersion: 6,
    label: "EC002685: Central fire alarm control panel"
  },
  {
    classId: "EC002686",
    classGroup: "EG000054",
    artClassDesc: "Smoke extraction installation",
    artClassVersion: 5,
    label: "EC002686: Smoke extraction installation"
  },
  {
    classId: "EC002687",
    classGroup: "EG000054",
    artClassDesc: "Expansion module for danger detection systems",
    artClassVersion: 6,
    label: "EC002687: Expansion module for danger detection systems"
  },
  {
    classId: "EC002688",
    classGroup: "EG020002",
    artClassDesc: "Accessories/spare parts for hot air gun",
    artClassVersion: 8,
    label: "EC002688: Accessories/spare parts for hot air gun"
  },
  {
    classId: "EC002689",
    classGroup: "EG000050",
    artClassDesc: "Bolt cutting pliers",
    artClassVersion: 7,
    label: "EC002689: Bolt cutting pliers"
  },
  {
    classId: "EC002690",
    classGroup: "EG000050",
    artClassDesc: "Draw bolt for hole punch",
    artClassVersion: 6,
    label: "EC002690: Draw bolt for hole punch"
  },
  {
    classId: "EC002691",
    classGroup: "EG000050",
    artClassDesc: "Accessories/spare parts for hole punch",
    artClassVersion: 5,
    label: "EC002691: Accessories/spare parts for hole punch"
  },
  {
    classId: "EC002693",
    classGroup: "EG000020",
    artClassDesc: "Residual current circuit breaker (RCCB) with auxiliary device",
    artClassVersion: 7,
    label: "EC002693: Residual current circuit breaker (RCCB) with auxiliary device"
  },
  {
    classId: "EC002694",
    classGroup: "EG000020",
    artClassDesc: "Miniature circuit breaker (MCB) with auxiliary device",
    artClassVersion: 8,
    label: "EC002694: Miniature circuit breaker (MCB) with auxiliary device"
  },
  {
    classId: "EC002695",
    classGroup: "EG000020",
    artClassDesc: "Earth leakage circuit breaker with auxiliary device",
    artClassVersion: 6,
    label: "EC002695: Earth leakage circuit breaker with auxiliary device"
  },
  {
    classId: "EC002696",
    classGroup: "EG000038",
    artClassDesc: "Accessories/spare parts for weather- and explosion proof telephones",
    artClassVersion: 7,
    label: "EC002696: Accessories/spare parts for weather- and explosion proof telephones"
  },
  {
    classId: "EC002697",
    classGroup: "EG000037",
    artClassDesc: "System patchpanel copper (twisted pair)",
    artClassVersion: 6,
    label: "EC002697: System patchpanel copper (twisted pair)"
  },
  {
    classId: "EC002698",
    classGroup: "EG000037",
    artClassDesc: "System patchcord copper (twisted pair)",
    artClassVersion: 7,
    label: "EC002698: System patchcord copper (twisted pair)"
  },
  {
    classId: "EC002699",
    classGroup: "EG000037",
    artClassDesc: "System patchpanel optic fibre",
    artClassVersion: 7,
    label: "EC002699: System patchpanel optic fibre"
  },
  {
    classId: "EC002700",
    classGroup: "EG000037",
    artClassDesc: "System patchcord optic fibre",
    artClassVersion: 9,
    label: "EC002700: System patchcord optic fibre"
  },
  {
    classId: "EC002701",
    classGroup: "EG000041",
    artClassDesc: "Blu-ray-player/-recorder",
    artClassVersion: 7,
    label: "EC002701: Blu-ray-player/-recorder"
  },
  {
    classId: "EC002702",
    classGroup: "EG000006",
    artClassDesc: "Joint clip for installation duct",
    artClassVersion: 5,
    label: "EC002702: Joint clip for installation duct"
  },
  {
    classId: "EC002703",
    classGroup: "EG000040",
    artClassDesc: "Vacuum cleaner for central hovering installation",
    artClassVersion: 5,
    label: "EC002703: Vacuum cleaner for central hovering installation"
  },
  {
    classId: "EC002704",
    classGroup: "EG000020",
    artClassDesc: "Cylindrical fuse",
    artClassVersion: 7,
    label: "EC002704: Cylindrical fuse"
  },
  {
    classId: "EC002705",
    classGroup: "EG000020",
    artClassDesc: "Holder for cylindrical fuse",
    artClassVersion: 6,
    label: "EC002705: Holder for cylindrical fuse"
  },
  {
    classId: "EC002706",
    classGroup: "EG000027",
    artClassDesc: "Light ribbon-/hose/-strip",
    artClassVersion: 9,
    label: "EC002706: Light ribbon-/hose/-strip"
  },
  {
    classId: "EC002707",
    classGroup: "EG000030",
    artClassDesc: "Accessories/spare parts for light ribbon-/hose/-strip",
    artClassVersion: 8,
    label: "EC002707: Accessories/spare parts for light ribbon-/hose/-strip"
  },
  {
    classId: "EC002708",
    classGroup: "EG000028",
    artClassDesc: "Lamp for medical applications",
    artClassVersion: 7,
    label: "EC002708: Lamp for medical applications"
  },
  {
    classId: "EC002709",
    classGroup: "EG000030",
    artClassDesc: "Cover for fluorescent lamp",
    artClassVersion: 7,
    label: "EC002709: Cover for fluorescent lamp"
  },
  {
    classId: "EC002710",
    classGroup: "EG000029",
    artClassDesc: "LED driver",
    artClassVersion: 9,
    label: "EC002710: LED driver"
  },
  {
    classId: "EC002711",
    classGroup: "EG000029",
    artClassDesc: "Filter coil for power factor correction",
    artClassVersion: 5,
    label: "EC002711: Filter coil for power factor correction"
  },
  {
    classId: "EC002712",
    classGroup: "EG000029",
    artClassDesc: "Accessories/spare parts for ballast",
    artClassVersion: 7,
    label: "EC002712: Accessories/spare parts for ballast"
  },
  {
    classId: "EC002713",
    classGroup: "EG000048",
    artClassDesc: "Terminal box",
    artClassVersion: 8,
    label: "EC002713: Terminal box"
  },
  {
    classId: "EC002714",
    classGroup: "EG000026",
    artClassDesc: "Inductive proximity switch",
    artClassVersion: 8,
    label: "EC002714: Inductive proximity switch"
  },
  {
    classId: "EC002715",
    classGroup: "EG000026",
    artClassDesc: "Capacitive proximity switch",
    artClassVersion: 8,
    label: "EC002715: Capacitive proximity switch"
  },
  {
    classId: "EC002716",
    classGroup: "EG000026",
    artClassDesc: "Through beam photoelectric sensor",
    artClassVersion: 6,
    label: "EC002716: Through beam photoelectric sensor"
  },
  {
    classId: "EC002717",
    classGroup: "EG000026",
    artClassDesc: "Retroreflective photoelectric sensor",
    artClassVersion: 6,
    label: "EC002717: Retroreflective photoelectric sensor"
  },
  {
    classId: "EC002719",
    classGroup: "EG000026",
    artClassDesc: "Light scanner with background masking",
    artClassVersion: 6,
    label: "EC002719: Light scanner with background masking"
  },
  {
    classId: "EC002720",
    classGroup: "EG000026",
    artClassDesc: "Fork light barrier",
    artClassVersion: 6,
    label: "EC002720: Fork light barrier"
  },
  {
    classId: "EC002721",
    classGroup: "EG000026",
    artClassDesc: "Optical frame sensor",
    artClassVersion: 6,
    label: "EC002721: Optical frame sensor"
  },
  {
    classId: "EC002722",
    classGroup: "EG000049",
    artClassDesc: "Jointing compound",
    artClassVersion: 9,
    label: "EC002722: Jointing compound"
  },
  {
    classId: "EC002734",
    classGroup: "EG000005",
    artClassDesc: "Floor tile for cable floor system",
    artClassVersion: 1,
    label: "EC002734: Floor tile for cable floor system"
  },
  {
    classId: "EC002735",
    classGroup: "EG000005",
    artClassDesc: "Support for cable floor system",
    artClassVersion: 3,
    label: "EC002735: Support for cable floor system"
  },
  {
    classId: "EC002736",
    classGroup: "EG000005",
    artClassDesc: "Skirt finishes for cable floor system",
    artClassVersion: 1,
    label: "EC002736: Skirt finishes for cable floor system"
  },
  {
    classId: "EC002737",
    classGroup: "EG000005",
    artClassDesc: "Accessories/spare parts for cable floor system",
    artClassVersion: 4,
    label: "EC002737: Accessories/spare parts for cable floor system"
  },
  {
    classId: "EC002738",
    classGroup: "EG000005",
    artClassDesc: "Connecting component for cable floor system",
    artClassVersion: 1,
    label: "EC002738: Connecting component for cable floor system"
  },
  {
    classId: "EC002739",
    classGroup: "EG000005",
    artClassDesc: "Distribution box for cable floor system",
    artClassVersion: 2,
    label: "EC002739: Distribution box for cable floor system"
  },
  {
    classId: "EC002744",
    classGroup: "EG000044",
    artClassDesc: "Calibration measuring instrument",
    artClassVersion: 3,
    label: "EC002744: Calibration measuring instrument"
  },
  {
    classId: "EC002749",
    classGroup: "EG000055",
    artClassDesc: "Accessories/spare parts for photovoltaics data logger",
    artClassVersion: 4,
    label: "EC002749: Accessories/spare parts for photovoltaics data logger"
  },
  {
    classId: "EC002750",
    classGroup: "EG000035",
    artClassDesc: "Accessories/spare parts for optic and acoustic signalling equipment",
    artClassVersion: 5,
    label: "EC002750: Accessories/spare parts for optic and acoustic signalling equipment"
  },
  {
    classId: "EC002753",
    classGroup: "EG000038",
    artClassDesc: "Mobile phone",
    artClassVersion: 3,
    label: "EC002753: Mobile phone"
  },
  {
    classId: "EC002754",
    classGroup: "EG000038",
    artClassDesc: "Accessories/spare parts for headphone/head-set",
    artClassVersion: 3,
    label: "EC002754: Accessories/spare parts for headphone/head-set"
  },
  {
    classId: "EC002756",
    classGroup: "EG000040",
    artClassDesc: "Hotstone massage appliance",
    artClassVersion: 3,
    label: "EC002756: Hotstone massage appliance"
  },
  {
    classId: "EC002757",
    classGroup: "EG000047",
    artClassDesc: "Lubricant",
    artClassVersion: 4,
    label: "EC002757: Lubricant"
  },
  {
    classId: "EC002758",
    classGroup: "EG000017",
    artClassDesc: "Energy management controller",
    artClassVersion: 3,
    label: "EC002758: Energy management controller"
  },
  {
    classId: "EC002759",
    classGroup: "EG000013",
    artClassDesc: "Desk connecting unit",
    artClassVersion: 4,
    label: "EC002759: Desk connecting unit"
  },
  {
    classId: "EC002760",
    classGroup: "EG000023",
    artClassDesc: "Wiring set for small distribution board",
    artClassVersion: 1,
    label: "EC002760: Wiring set for small distribution board"
  },
  {
    classId: "EC002761",
    classGroup: "EG000027",
    artClassDesc: "Festive lighting",
    artClassVersion: 5,
    label: "EC002761: Festive lighting"
  },
  {
    classId: "EC002762",
    classGroup: "EG000013",
    artClassDesc: "Power distribution unit (PDU)",
    artClassVersion: 4,
    label: "EC002762: Power distribution unit (PDU)"
  },
  {
    classId: "EC002767",
    classGroup: "EG000005",
    artClassDesc: "Subfloor tank",
    artClassVersion: 5,
    label: "EC002767: Subfloor tank"
  },
  {
    classId: "EC002768",
    classGroup: "EG000030",
    artClassDesc: "Luminaire hoist",
    artClassVersion: 3,
    label: "EC002768: Luminaire hoist"
  },
  {
    classId: "EC002770",
    classGroup: "EG000051",
    artClassDesc: "Garden chopper (electric)",
    artClassVersion: 4,
    label: "EC002770: Garden chopper (electric)"
  },
  {
    classId: "EC002771",
    classGroup: "EG000051",
    artClassDesc: "Garden scissors (battery)",
    artClassVersion: 2,
    label: "EC002771: Garden scissors (battery)"
  },
  {
    classId: "EC002772",
    classGroup: "EG000051",
    artClassDesc: "Garden scissors (electric)",
    artClassVersion: 2,
    label: "EC002772: Garden scissors (electric)"
  },
  {
    classId: "EC002773",
    classGroup: "EG000051",
    artClassDesc: "Lawn shears (battery)",
    artClassVersion: 2,
    label: "EC002773: Lawn shears (battery)"
  },
  {
    classId: "EC002775",
    classGroup: "EG000051",
    artClassDesc: "Hedge trimmer (battery)",
    artClassVersion: 3,
    label: "EC002775: Hedge trimmer (battery)"
  },
  {
    classId: "EC002776",
    classGroup: "EG000051",
    artClassDesc: "Hedge trimmer (electric)",
    artClassVersion: 3,
    label: "EC002776: Hedge trimmer (electric)"
  },
  {
    classId: "EC002778",
    classGroup: "EG000052",
    artClassDesc: "Stripping and crimping machine",
    artClassVersion: 1,
    label: "EC002778: Stripping and crimping machine"
  },
  {
    classId: "EC002779",
    classGroup: "EG000017",
    artClassDesc: "Extruded profile modular housing",
    artClassVersion: 3,
    label: "EC002779: Extruded profile modular housing"
  },
  {
    classId: "EC002780",
    classGroup: "EG000024",
    artClassDesc: "Interface module",
    artClassVersion: 1,
    label: "EC002780: Interface module"
  },
  {
    classId: "EC002781",
    classGroup: "EG000059",
    artClassDesc: "Voltage detector",
    artClassVersion: 3,
    label: "EC002781: Voltage detector"
  },
  {
    classId: "EC002782",
    classGroup: "EG000051",
    artClassDesc: "Chain saw (battery)",
    artClassVersion: 4,
    label: "EC002782: Chain saw (battery)"
  },
  {
    classId: "EC002783",
    classGroup: "EG000059",
    artClassDesc: "Accessories/spare parts for safety equipment",
    artClassVersion: 4,
    label: "EC002783: Accessories/spare parts for safety equipment"
  },
  {
    classId: "EC002784",
    classGroup: "EG000059",
    artClassDesc: "Phase comparator",
    artClassVersion: 2,
    label: "EC002784: Phase comparator"
  },
  {
    classId: "EC002785",
    classGroup: "EG000059",
    artClassDesc: "Voltage detection system",
    artClassVersion: 3,
    label: "EC002785: Voltage detection system"
  },
  {
    classId: "EC002786",
    classGroup: "EG000059",
    artClassDesc: "Operating rod",
    artClassVersion: 4,
    label: "EC002786: Operating rod"
  },
  {
    classId: "EC002787",
    classGroup: "EG000059",
    artClassDesc: "Insulating protective shutter",
    artClassVersion: 2,
    label: "EC002787: Insulating protective shutter"
  },
  {
    classId: "EC002788",
    classGroup: "EG000059",
    artClassDesc: "Earthing and short-circuiting device",
    artClassVersion: 4,
    label: "EC002788: Earthing and short-circuiting device"
  },
  {
    classId: "EC002790",
    classGroup: "EG000059",
    artClassDesc: "Connection element for earthing and short circuit device",
    artClassVersion: 3,
    label: "EC002790: Connection element for earthing and short circuit device"
  },
  {
    classId: "EC002791",
    classGroup: "EG000051",
    artClassDesc: "Chain saw (electric)",
    artClassVersion: 3,
    label: "EC002791: Chain saw (electric)"
  },
  {
    classId: "EC002792",
    classGroup: "EG000051",
    artClassDesc: "Chain saw (combustion engine)",
    artClassVersion: 2,
    label: "EC002792: Chain saw (combustion engine)"
  },
  {
    classId: "EC002793",
    classGroup: "EG000059",
    artClassDesc: "Fixed ball point for earthing and short circuit device",
    artClassVersion: 4,
    label: "EC002793: Fixed ball point for earthing and short circuit device"
  },
  {
    classId: "EC002794",
    classGroup: "EG000059",
    artClassDesc: "Single-pole earthing and discharge device",
    artClassVersion: 4,
    label: "EC002794: Single-pole earthing and discharge device"
  },
  {
    classId: "EC002795",
    classGroup: "EG000059",
    artClassDesc: "Cleaning kit for electrical devices",
    artClassVersion: 1,
    label: "EC002795: Cleaning kit for electrical devices"
  },
  {
    classId: "EC002796",
    classGroup: "EG000051",
    artClassDesc: "Mower (battery)",
    artClassVersion: 4,
    label: "EC002796: Mower (battery)"
  },
  {
    classId: "EC002797",
    classGroup: "EG000059",
    artClassDesc: "Refilling device for end closures",
    artClassVersion: 1,
    label: "EC002797: Refilling device for end closures"
  },
  {
    classId: "EC002798",
    classGroup: "EG000046",
    artClassDesc: "Cover drape (safety at work)",
    artClassVersion: 2,
    label: "EC002798: Cover drape (safety at work)"
  },
  {
    classId: "EC002799",
    classGroup: "EG000051",
    artClassDesc: "Mower (electric)",
    artClassVersion: 3,
    label: "EC002799: Mower (electric)"
  },
  {
    classId: "EC002800",
    classGroup: "EG000051",
    artClassDesc: "Mower (combustion engine)",
    artClassVersion: 1,
    label: "EC002800: Mower (combustion engine)"
  },
  {
    classId: "EC002801",
    classGroup: "EG000051",
    artClassDesc: "Lawn trimmer (battery)",
    artClassVersion: 3,
    label: "EC002801: Lawn trimmer (battery)"
  },
  {
    classId: "EC002802",
    classGroup: "EG000051",
    artClassDesc: "Lawn trimmer (electric)",
    artClassVersion: 3,
    label: "EC002802: Lawn trimmer (electric)"
  },
  {
    classId: "EC002803",
    classGroup: "EG000051",
    artClassDesc: "Lawn scarifier/aerator (battery)",
    artClassVersion: 3,
    label: "EC002803: Lawn scarifier/aerator (battery)"
  },
  {
    classId: "EC002804",
    classGroup: "EG000051",
    artClassDesc: "Lawn scarifier/aerator (electric)",
    artClassVersion: 5,
    label: "EC002804: Lawn scarifier/aerator (electric)"
  },
  {
    classId: "EC002805",
    classGroup: "EG020002",
    artClassDesc: "Accessories/spare parts for garden chopper",
    artClassVersion: 3,
    label: "EC002805: Accessories/spare parts for garden chopper"
  },
  {
    classId: "EC002806",
    classGroup: "EG020002",
    artClassDesc: "Accessories/spare parts for scissors/shears",
    artClassVersion: 3,
    label: "EC002806: Accessories/spare parts for scissors/shears"
  },
  {
    classId: "EC002808",
    classGroup: "EG000052",
    artClassDesc: "Stretching pliers for stretch sleeves",
    artClassVersion: 3,
    label: "EC002808: Stretching pliers for stretch sleeves"
  },
  {
    classId: "EC002809",
    classGroup: "EG000021",
    artClassDesc: "Earthing strip preassembled",
    artClassVersion: 5,
    label: "EC002809: Earthing strip preassembled"
  },
  {
    classId: "EC002810",
    classGroup: "EG020002",
    artClassDesc: "Accessories/spare parts for lawn trimmer",
    artClassVersion: 3,
    label: "EC002810: Accessories/spare parts for lawn trimmer"
  },
  {
    classId: "EC002811",
    classGroup: "EG020002",
    artClassDesc: "Accessories/spare parts for mower",
    artClassVersion: 3,
    label: "EC002811: Accessories/spare parts for mower"
  },
  {
    classId: "EC002812",
    classGroup: "EG020002",
    artClassDesc: "Accessories/spare parts for lawn scarifier/aerator",
    artClassVersion: 3,
    label: "EC002812: Accessories/spare parts for lawn scarifier/aerator"
  },
  {
    classId: "EC002813",
    classGroup: "EG000051",
    artClassDesc: "Plunge router (electric)",
    artClassVersion: 5,
    label: "EC002813: Plunge router (electric)"
  },
  {
    classId: "EC002814",
    classGroup: "EG000051",
    artClassDesc: "Chop saw, semi-stationary (electric)",
    artClassVersion: 5,
    label: "EC002814: Chop saw, semi-stationary (electric)"
  },
  {
    classId: "EC002815",
    classGroup: "EG000051",
    artClassDesc: "Table-, chop- and bevel saw, semi-stationary (electric)",
    artClassVersion: 4,
    label: "EC002815: Table-, chop- and bevel saw, semi-stationary (electric)"
  },
  {
    classId: "EC002816",
    classGroup: "EG000051",
    artClassDesc: "Straight grinder (battery)",
    artClassVersion: 4,
    label: "EC002816: Straight grinder (battery)"
  },
  {
    classId: "EC002817",
    classGroup: "EG000051",
    artClassDesc: "Straight grinder (electric)",
    artClassVersion: 4,
    label: "EC002817: Straight grinder (electric)"
  },
  {
    classId: "EC002818",
    classGroup: "EG000051",
    artClassDesc: "Tile cutter (electric)",
    artClassVersion: 3,
    label: "EC002818: Tile cutter (electric)"
  },
  {
    classId: "EC002819",
    classGroup: "EG000051",
    artClassDesc: "Plunge router (battery)",
    artClassVersion: 4,
    label: "EC002819: Plunge router (battery)"
  },
  {
    classId: "EC002820",
    classGroup: "EG000051",
    artClassDesc: "Concrete vibrator (battery)",
    artClassVersion: 2,
    label: "EC002820: Concrete vibrator (battery)"
  },
  {
    classId: "EC002821",
    classGroup: "EG000051",
    artClassDesc: "Concrete vibrator (electric)",
    artClassVersion: 2,
    label: "EC002821: Concrete vibrator (electric)"
  },
  {
    classId: "EC002823",
    classGroup: "EG000051",
    artClassDesc: "Power generator",
    artClassVersion: 4,
    label: "EC002823: Power generator"
  },
  {
    classId: "EC002824",
    classGroup: "EG000038",
    artClassDesc: "Handheld transceiver",
    artClassVersion: 4,
    label: "EC002824: Handheld transceiver"
  },
  {
    classId: "EC002828",
    classGroup: "EG000030",
    artClassDesc: "Pillar for bollard",
    artClassVersion: 5,
    label: "EC002828: Pillar for bollard"
  },
  {
    classId: "EC002830",
    classGroup: "EG000046",
    artClassDesc: "Suspenders",
    artClassVersion: 2,
    label: "EC002830: Suspenders"
  },
  {
    classId: "EC002831",
    classGroup: "EG000046",
    artClassDesc: "Overshoe",
    artClassVersion: 4,
    label: "EC002831: Overshoe"
  },
  {
    classId: "EC002832",
    classGroup: "EG000046",
    artClassDesc: "Disposable glove",
    artClassVersion: 5,
    label: "EC002832: Disposable glove"
  },
  {
    classId: "EC002833",
    classGroup: "EG020002",
    artClassDesc: "Machine chisel",
    artClassVersion: 3,
    label: "EC002833: Machine chisel"
  },
  {
    classId: "EC002834",
    classGroup: "EG000041",
    artClassDesc: "Docking station",
    artClassVersion: 3,
    label: "EC002834: Docking station"
  },
  {
    classId: "EC002835",
    classGroup: "EG000004",
    artClassDesc: "Tee for mesh cable tray",
    artClassVersion: 5,
    label: "EC002835: Tee for mesh cable tray"
  },
  {
    classId: "EC002836",
    classGroup: "EG000004",
    artClassDesc: "Angle attachment for cable channel",
    artClassVersion: 5,
    label: "EC002836: Angle attachment for cable channel"
  },
  {
    classId: "EC002837",
    classGroup: "EG000047",
    artClassDesc: "Accessories/spare parts for cable resin",
    artClassVersion: 4,
    label: "EC002837: Accessories/spare parts for cable resin"
  },
  {
    classId: "EC002838",
    classGroup: "EG000014",
    artClassDesc: "Accessories/spare parts for CEE plugs and sockets (IEC 60309)",
    artClassVersion: 4,
    label: "EC002838: Accessories/spare parts for CEE plugs and sockets (IEC 60309)"
  },
  {
    classId: "EC002839",
    classGroup: "EG000053",
    artClassDesc: "Accessories/spare parts for battery/charger",
    artClassVersion: 4,
    label: "EC002839: Accessories/spare parts for battery/charger"
  },
  {
    classId: "EC002840",
    classGroup: "EG000054",
    artClassDesc: "Carbon monoxide detector",
    artClassVersion: 3,
    label: "EC002840: Carbon monoxide detector"
  },
  {
    classId: "EC002842",
    classGroup: "EG000054",
    artClassDesc: "Explosion proof fire detector",
    artClassVersion: 1,
    label: "EC002842: Explosion proof fire detector"
  },
  {
    classId: "EC002843",
    classGroup: "EG000038",
    artClassDesc: "Weatherproof intercom station",
    artClassVersion: 2,
    label: "EC002843: Weatherproof intercom station"
  },
  {
    classId: "EC002844",
    classGroup: "EG000013",
    artClassDesc: "Energy data gateway",
    artClassVersion: 3,
    label: "EC002844: Energy data gateway"
  },
  {
    classId: "EC002845",
    classGroup: "EG000013",
    artClassDesc: "Energy consumption indication",
    artClassVersion: 3,
    label: "EC002845: Energy consumption indication"
  },
  {
    classId: "EC002846",
    classGroup: "EG000013",
    artClassDesc: "Multi combination switch/push button/socket outlet",
    artClassVersion: 4,
    label: "EC002846: Multi combination switch/push button/socket outlet"
  },
  {
    classId: "EC002847",
    classGroup: "EG000040",
    artClassDesc: "Accessories/spare parts for body care appliances",
    artClassVersion: 2,
    label: "EC002847: Accessories/spare parts for body care appliances"
  },
  {
    classId: "EC002848",
    classGroup: "EG000012",
    artClassDesc: "Accessories/spare parts for terminals",
    artClassVersion: 2,
    label: "EC002848: Accessories/spare parts for terminals"
  },
  {
    classId: "EC002849",
    classGroup: "EG000034",
    artClassDesc: "Accessories/spare parts for gate-/roller-blind drive",
    artClassVersion: 4,
    label: "EC002849: Accessories/spare parts for gate-/roller-blind drive"
  },
  {
    classId: "EC002850",
    classGroup: "EG000037",
    artClassDesc: "Accessories/spare parts for UPS",
    artClassVersion: 4,
    label: "EC002850: Accessories/spare parts for UPS"
  },
  {
    classId: "EC002851",
    classGroup: "EG000013",
    artClassDesc: "Hollow wall installation box with premounted switching devices",
    artClassVersion: 5,
    label: "EC002851: Hollow wall installation box with premounted switching devices"
  },
  {
    classId: "EC002852",
    classGroup: "EG000038",
    artClassDesc: "Typewriter",
    artClassVersion: 1,
    label: "EC002852: Typewriter"
  },
  {
    classId: "EC002853",
    classGroup: "EG000038",
    artClassDesc: "Document shredder",
    artClassVersion: 2,
    label: "EC002853: Document shredder"
  },
  {
    classId: "EC002854",
    classGroup: "EG000038",
    artClassDesc: "Laminating device",
    artClassVersion: 3,
    label: "EC002854: Laminating device"
  },
  {
    classId: "EC002855",
    classGroup: "EG000038",
    artClassDesc: "Cash register",
    artClassVersion: 1,
    label: "EC002855: Cash register"
  },
  {
    classId: "EC002856",
    classGroup: "EG000038",
    artClassDesc: "Punching/binding device",
    artClassVersion: 2,
    label: "EC002856: Punching/binding device"
  },
  {
    classId: "EC002857",
    classGroup: "EG000021",
    artClassDesc: "Earthing mat",
    artClassVersion: 2,
    label: "EC002857: Earthing mat"
  },
  {
    classId: "EC002858",
    classGroup: "EG000043",
    artClassDesc: "Protection grille finned tube heater",
    artClassVersion: 2,
    label: "EC002858: Protection grille finned tube heater"
  },
  {
    classId: "EC002859",
    classGroup: "EG000043",
    artClassDesc: "Mirror heating",
    artClassVersion: 1,
    label: "EC002859: Mirror heating"
  },
  {
    classId: "EC002860",
    classGroup: "EG000051",
    artClassDesc: "Air compressor",
    artClassVersion: 3,
    label: "EC002860: Air compressor"
  },
  {
    classId: "EC002862",
    classGroup: "EG020002",
    artClassDesc: "Accessories and tools compressed air",
    artClassVersion: 4,
    label: "EC002862: Accessories and tools compressed air"
  },
  {
    classId: "EC002863",
    classGroup: "EG000057",
    artClassDesc: "Accessories/spare parts for plug-in building installation",
    artClassVersion: 2,
    label: "EC002863: Accessories/spare parts for plug-in building installation"
  },
  {
    classId: "EC002868",
    classGroup: "EG000050",
    artClassDesc: "Torque multiplier",
    artClassVersion: 2,
    label: "EC002868: Torque multiplier"
  },
  {
    classId: "EC002870",
    classGroup: "EG000010",
    artClassDesc: "Cable block",
    artClassVersion: 1,
    label: "EC002870: Cable block"
  },
  {
    classId: "EC002871",
    classGroup: "EG000041",
    artClassDesc: "Accessories/spare parts for sound systems",
    artClassVersion: 3,
    label: "EC002871: Accessories/spare parts for sound systems"
  },
  {
    classId: "EC002872",
    classGroup: "EG020002",
    artClassDesc: "Fuel tank (for power generator)",
    artClassVersion: 3,
    label: "EC002872: Fuel tank (for power generator)"
  },
  {
    classId: "EC002874",
    classGroup: "EG000021",
    artClassDesc: "Earth inspection pit",
    artClassVersion: 2,
    label: "EC002874: Earth inspection pit"
  },
  {
    classId: "EC002876",
    classGroup: "EG000041",
    artClassDesc: "Wireless microphone receiver",
    artClassVersion: 2,
    label: "EC002876: Wireless microphone receiver"
  },
  {
    classId: "EC002877",
    classGroup: "EG000034",
    artClassDesc: "Bathroom fan heater light combination",
    artClassVersion: 2,
    label: "EC002877: Bathroom fan heater light combination"
  },
  {
    classId: "EC002878",
    classGroup: "EG000012",
    artClassDesc: "Diode module",
    artClassVersion: 1,
    label: "EC002878: Diode module"
  },
  {
    classId: "EC002883",
    classGroup: "EG000014",
    artClassDesc: "Charging device E-Mobility",
    artClassVersion: 3,
    label: "EC002883: Charging device E-Mobility"
  },
  {
    classId: "EC002884",
    classGroup: "EG000014",
    artClassDesc: "Accessories/spare parts for E-Mobility",
    artClassVersion: 3,
    label: "EC002884: Accessories/spare parts for E-Mobility"
  },
  {
    classId: "EC002885",
    classGroup: "EG000049",
    artClassDesc: "Anti-theft nut",
    artClassVersion: 3,
    label: "EC002885: Anti-theft nut"
  },
  {
    classId: "EC002886",
    classGroup: "EG000026",
    artClassDesc: "Accessories/spare parts for agreement switch",
    artClassVersion: 2,
    label: "EC002886: Accessories/spare parts for agreement switch"
  },
  {
    classId: "EC002887",
    classGroup: "EG000026",
    artClassDesc: "Pressure sensitive protective device",
    artClassVersion: 3,
    label: "EC002887: Pressure sensitive protective device"
  },
  {
    classId: "EC002888",
    classGroup: "EG000026",
    artClassDesc: "Accessories/spare parts for pressure sensitive protective device",
    artClassVersion: 2,
    label: "EC002888: Accessories/spare parts for pressure sensitive protective device"
  },
  {
    classId: "EC002889",
    classGroup: "EG000014",
    artClassDesc: "Charging controller E-Mobility",
    artClassVersion: 3,
    label: "EC002889: Charging controller E-Mobility"
  },
  {
    classId: "EC002890",
    classGroup: "EG000014",
    artClassDesc: "Central control unit E-Mobility",
    artClassVersion: 2,
    label: "EC002890: Central control unit E-Mobility"
  },
  {
    classId: "EC002892",
    classGroup: "EG000027",
    artClassDesc: "Ceiling-/wall luminaire",
    artClassVersion: 4,
    label: "EC002892: Ceiling-/wall luminaire"
  },
  {
    classId: "EC002895",
    classGroup: "EG000050",
    artClassDesc: "Tool cabinet",
    artClassVersion: 3,
    label: "EC002895: Tool cabinet"
  },
  {
    classId: "EC002897",
    classGroup: "EG000014",
    artClassDesc: "Charging cable E-Mobility",
    artClassVersion: 3,
    label: "EC002897: Charging cable E-Mobility"
  },
  {
    classId: "EC002898",
    classGroup: "EG000014",
    artClassDesc: "Charging connector E-Mobility",
    artClassVersion: 3,
    label: "EC002898: Charging connector E-Mobility"
  },
  {
    classId: "EC002899",
    classGroup: "EG000014",
    artClassDesc: "Measuring and testing instrument E-Mobility",
    artClassVersion: 2,
    label: "EC002899: Measuring and testing instrument E-Mobility"
  },
  {
    classId: "EC002900",
    classGroup: "EG000050",
    artClassDesc: "Sand paper/emery cloth set",
    artClassVersion: 3,
    label: "EC002900: Sand paper/emery cloth set"
  },
  {
    classId: "EC002901",
    classGroup: "EG000051",
    artClassDesc: "Right angle grinder (battery)",
    artClassVersion: 4,
    label: "EC002901: Right angle grinder (battery)"
  },
  {
    classId: "EC002902",
    classGroup: "EG000026",
    artClassDesc: "Traffic detection cable",
    artClassVersion: 3,
    label: "EC002902: Traffic detection cable"
  },
  {
    classId: "EC002903",
    classGroup: "EG020004",
    artClassDesc: "Accessories/spare parts for electrical door opener",
    artClassVersion: 3,
    label: "EC002903: Accessories/spare parts for electrical door opener"
  },
  {
    classId: "EC002904",
    classGroup: "EG000002",
    artClassDesc: "Plastic installation tube, prewired",
    artClassVersion: 4,
    label: "EC002904: Plastic installation tube, prewired"
  },
  {
    classId: "EC002906",
    classGroup: "EG000013",
    artClassDesc: "Multi insert/cover for data and communication connection technology",
    artClassVersion: 3,
    label: "EC002906: Multi insert/cover for data and communication connection technology"
  },
  {
    classId: "EC002907",
    classGroup: "EG000006",
    artClassDesc: "Accessories/spare parts for wall duct",
    artClassVersion: 4,
    label: "EC002907: Accessories/spare parts for wall duct"
  },
  {
    classId: "EC002908",
    classGroup: "EG000014",
    artClassDesc: "Contact material according to Dutch specific standard",
    artClassVersion: 3,
    label: "EC002908: Contact material according to Dutch specific standard"
  },
  {
    classId: "EC002910",
    classGroup: "EG000049",
    artClassDesc: "Concrete screw",
    artClassVersion: 4,
    label: "EC002910: Concrete screw"
  },
  {
    classId: "EC002911",
    classGroup: "EG000017",
    artClassDesc: "Arc detection and mitigation system",
    artClassVersion: 3,
    label: "EC002911: Arc detection and mitigation system"
  },
  {
    classId: "EC002912",
    classGroup: "EG000059",
    artClassDesc: "Wire pulling grip",
    artClassVersion: 2,
    label: "EC002912: Wire pulling grip"
  },
  {
    classId: "EC002915",
    classGroup: "EG000051",
    artClassDesc: "Band saw (battery)",
    artClassVersion: 3,
    label: "EC002915: Band saw (battery)"
  },
  {
    classId: "EC002916",
    classGroup: "EG000051",
    artClassDesc: "Hand band saw (electric)",
    artClassVersion: 3,
    label: "EC002916: Hand band saw (electric)"
  },
  {
    classId: "EC002917",
    classGroup: "EG020002",
    artClassDesc: "Band saw blade",
    artClassVersion: 3,
    label: "EC002917: Band saw blade"
  },
  {
    classId: "EC002918",
    classGroup: "EG000026",
    artClassDesc: "Frequency value transformer",
    artClassVersion: 1,
    label: "EC002918: Frequency value transformer"
  },
  {
    classId: "EC002919",
    classGroup: "EG000026",
    artClassDesc: "Temperature transformer",
    artClassVersion: 2,
    label: "EC002919: Temperature transformer"
  },
  {
    classId: "EC002920",
    classGroup: "EG000041",
    artClassDesc: "Megaphone",
    artClassVersion: 1,
    label: "EC002920: Megaphone"
  },
  {
    classId: "EC002922",
    classGroup: "EG000002",
    artClassDesc: "Tee/cross for installation tubes",
    artClassVersion: 4,
    label: "EC002922: Tee/cross for installation tubes"
  },
  {
    classId: "EC002924",
    classGroup: "EG000029",
    artClassDesc: "Controller for luminaires",
    artClassVersion: 3,
    label: "EC002924: Controller for luminaires"
  },
  {
    classId: "EC002925",
    classGroup: "EG000026",
    artClassDesc: "Sensor actuator adapter",
    artClassVersion: 2,
    label: "EC002925: Sensor actuator adapter"
  },
  {
    classId: "EC002927",
    classGroup: "EG000055",
    artClassDesc: "Wind generator",
    artClassVersion: 3,
    label: "EC002927: Wind generator"
  },
  {
    classId: "EC002928",
    classGroup: "EG000055",
    artClassDesc: "Accessories/spare parts for renewable energy generation",
    artClassVersion: 4,
    label: "EC002928: Accessories/spare parts for renewable energy generation"
  },
  {
    classId: "EC002929",
    classGroup: "EG000051",
    artClassDesc: "Oscillator (electric)",
    artClassVersion: 4,
    label: "EC002929: Oscillator (electric)"
  },
  {
    classId: "EC002930",
    classGroup: "EG000051",
    artClassDesc: "Oscillator (battery)",
    artClassVersion: 4,
    label: "EC002930: Oscillator (battery)"
  },
  {
    classId: "EC002931",
    classGroup: "EG000051",
    artClassDesc: "Power tool set",
    artClassVersion: 3,
    label: "EC002931: Power tool set"
  },
  {
    classId: "EC002932",
    classGroup: "EG000039",
    artClassDesc: "Stove guard",
    artClassVersion: 4,
    label: "EC002932: Stove guard"
  },
  {
    classId: "EC002934",
    classGroup: "EG000003",
    artClassDesc: "Accessories/spare parts for cable guide chain",
    artClassVersion: 2,
    label: "EC002934: Accessories/spare parts for cable guide chain"
  },
  {
    classId: "EC002935",
    classGroup: "EG000004",
    artClassDesc: "Accessories/spare parts for cable carrying system",
    artClassVersion: 2,
    label: "EC002935: Accessories/spare parts for cable carrying system"
  },
  {
    classId: "EC002936",
    classGroup: "EG000020",
    artClassDesc: "Accessories/spare parts for electrical fuses/fuse holders",
    artClassVersion: 2,
    label: "EC002936: Accessories/spare parts for electrical fuses/fuse holders"
  },
  {
    classId: "EC002937",
    classGroup: "EG000041",
    artClassDesc: "AV patch cord",
    artClassVersion: 4,
    label: "EC002937: AV patch cord"
  },
  {
    classId: "EC002939",
    classGroup: "EG000047",
    artClassDesc: "Accessories/spare parts for installation and connection material",
    artClassVersion: 3,
    label: "EC002939: Accessories/spare parts for installation and connection material"
  },
  {
    classId: "EC002940",
    classGroup: "EG000046",
    artClassDesc: "Accessories/spare parts for workwear",
    artClassVersion: 4,
    label: "EC002940: Accessories/spare parts for workwear"
  },
  {
    classId: "EC002941",
    classGroup: "EG000046",
    artClassDesc: "Filter for breathing mask",
    artClassVersion: 3,
    label: "EC002941: Filter for breathing mask"
  },
  {
    classId: "EC002942",
    classGroup: "EG000054",
    artClassDesc: "Accessories/spare parts for danger detection system",
    artClassVersion: 4,
    label: "EC002942: Accessories/spare parts for danger detection system"
  },
  {
    classId: "EC002943",
    classGroup: "EG000058",
    artClassDesc: "Accessories/spare parts for industrial connectors",
    artClassVersion: 3,
    label: "EC002943: Accessories/spare parts for industrial connectors"
  },
  {
    classId: "EC002945",
    classGroup: "EG000041",
    artClassDesc: "Tablet-Computer",
    artClassVersion: 3,
    label: "EC002945: Tablet-Computer"
  },
  {
    classId: "EC002947",
    classGroup: "EG000033",
    artClassDesc: "HFC-Component",
    artClassVersion: 1,
    label: "EC002947: HFC-Component"
  },
  {
    classId: "EC002948",
    classGroup: "EG000013",
    artClassDesc: "Holder for modular domestic switching devices",
    artClassVersion: 3,
    label: "EC002948: Holder for modular domestic switching devices"
  },
  {
    classId: "EC002949",
    classGroup: "EG000013",
    artClassDesc: "USB power supply",
    artClassVersion: 4,
    label: "EC002949: USB power supply"
  },
  {
    classId: "EC002955",
    classGroup: "EG000029",
    artClassDesc: "Accessories/spare parts for LED drivers and modules",
    artClassVersion: 2,
    label: "EC002955: Accessories/spare parts for LED drivers and modules"
  },
  {
    classId: "EC002959",
    classGroup: "EG000017",
    artClassDesc: "Vibration control unit",
    artClassVersion: 2,
    label: "EC002959: Vibration control unit"
  },
  {
    classId: "EC002960",
    classGroup: "EG000017",
    artClassDesc: "Extension module for vibration control unit",
    artClassVersion: 2,
    label: "EC002960: Extension module for vibration control unit"
  },
  {
    classId: "EC002961",
    classGroup: "EG000017",
    artClassDesc: "Sensor for vibration control unit",
    artClassVersion: 2,
    label: "EC002961: Sensor for vibration control unit"
  },
  {
    classId: "EC002962",
    classGroup: "EG000028",
    artClassDesc: "UV lamp",
    artClassVersion: 3,
    label: "EC002962: UV lamp"
  },
  {
    classId: "EC002963",
    classGroup: "EG000028",
    artClassDesc: "IR lamp",
    artClassVersion: 1,
    label: "EC002963: IR lamp"
  },
  {
    classId: "EC002964",
    classGroup: "EG000059",
    artClassDesc: "Arc fault protection system",
    artClassVersion: 1,
    label: "EC002964: Arc fault protection system"
  },
  {
    classId: "EC002965",
    classGroup: "EG000021",
    artClassDesc: "Lightning current measuring system",
    artClassVersion: 1,
    label: "EC002965: Lightning current measuring system"
  },
  {
    classId: "EC002967",
    classGroup: "EG000051",
    artClassDesc: "Hot air gun (gas-powered)",
    artClassVersion: 1,
    label: "EC002967: Hot air gun (gas-powered)"
  },
  {
    classId: "EC002969",
    classGroup: "EG000013",
    artClassDesc: "Indication element clinic-installation",
    artClassVersion: 2,
    label: "EC002969: Indication element clinic-installation"
  },
  {
    classId: "EC002970",
    classGroup: "EG000013",
    artClassDesc: "Terminal clinic-installation",
    artClassVersion: 2,
    label: "EC002970: Terminal clinic-installation"
  },
  {
    classId: "EC002971",
    classGroup: "EG000013",
    artClassDesc: "System device clinic-installation",
    artClassVersion: 2,
    label: "EC002971: System device clinic-installation"
  },
  {
    classId: "EC002972",
    classGroup: "EG000013",
    artClassDesc: "Call-/control unit clinic-installation",
    artClassVersion: 4,
    label: "EC002972: Call-/control unit clinic-installation"
  },
  {
    classId: "EC002973",
    classGroup: "EG000038",
    artClassDesc: "Weatherproof loudspeaker",
    artClassVersion: 2,
    label: "EC002973: Weatherproof loudspeaker"
  },
  {
    classId: "EC002975",
    classGroup: "EG000035",
    artClassDesc: "Signalling light",
    artClassVersion: 3,
    label: "EC002975: Signalling light"
  },
  {
    classId: "EC002976",
    classGroup: "EG020001",
    artClassDesc: "Animal guard for electrical insulators/bushings",
    artClassVersion: 2,
    label: "EC002976: Animal guard for electrical insulators/bushings"
  },
  {
    classId: "EC002977",
    classGroup: "EG000044",
    artClassDesc: "Locator for cables and markers",
    artClassVersion: 3,
    label: "EC002977: Locator for cables and markers"
  },
  {
    classId: "EC002978",
    classGroup: "EG000044",
    artClassDesc: "Electronic cable marker",
    artClassVersion: 3,
    label: "EC002978: Electronic cable marker"
  },
  {
    classId: "EC002979",
    classGroup: "EG000037",
    artClassDesc: "Overvoltage protection magazine",
    artClassVersion: 1,
    label: "EC002979: Overvoltage protection magazine"
  },
  {
    classId: "EC002980",
    classGroup: "EG000008",
    artClassDesc: "Accessories/spare parts for installation column",
    artClassVersion: 4,
    label: "EC002980: Accessories/spare parts for installation column"
  },
  {
    classId: "EC002982",
    classGroup: "EG000017",
    artClassDesc: "Industrial heating control system",
    artClassVersion: 3,
    label: "EC002982: Industrial heating control system"
  },
  {
    classId: "EC002983",
    classGroup: "EG000017",
    artClassDesc: "Module rack for industrial heating control systems",
    artClassVersion: 3,
    label: "EC002983: Module rack for industrial heating control systems"
  },
  {
    classId: "EC002984",
    classGroup: "EG000017",
    artClassDesc: "Digital module for industrial heating control systems",
    artClassVersion: 1,
    label: "EC002984: Digital module for industrial heating control systems"
  },
  {
    classId: "EC002985",
    classGroup: "EG000017",
    artClassDesc: "Analogue module for industrial heating control systems",
    artClassVersion: 2,
    label: "EC002985: Analogue module for industrial heating control systems"
  },
  {
    classId: "EC002986",
    classGroup: "EG000034",
    artClassDesc: "Door control system",
    artClassVersion: 2,
    label: "EC002986: Door control system"
  },
  {
    classId: "EC002987",
    classGroup: "EG000034",
    artClassDesc: "Gear motor for door control systems",
    artClassVersion: 1,
    label: "EC002987: Gear motor for door control systems"
  },
  {
    classId: "EC002988",
    classGroup: "EG000051",
    artClassDesc: "Band sawing machine (electric)",
    artClassVersion: 2,
    label: "EC002988: Band sawing machine (electric)"
  },
  {
    classId: "EC002989",
    classGroup: "EG000026",
    artClassDesc: "Differential pressure transformer",
    artClassVersion: 3,
    label: "EC002989: Differential pressure transformer"
  },
  {
    classId: "EC002990",
    classGroup: "EG000026",
    artClassDesc: "Pressure-/absolute pressure transformer",
    artClassVersion: 2,
    label: "EC002990: Pressure-/absolute pressure transformer"
  },
  {
    classId: "EC002991",
    classGroup: "EG000026",
    artClassDesc: "Magnetic-inductive flow meter",
    artClassVersion: 2,
    label: "EC002991: Magnetic-inductive flow meter"
  },
  {
    classId: "EC002992",
    classGroup: "EG000026",
    artClassDesc: "Coriolis-mass flow meter",
    artClassVersion: 1,
    label: "EC002992: Coriolis-mass flow meter"
  },
  {
    classId: "EC002993",
    classGroup: "EG000026",
    artClassDesc: "Capacitive level meter",
    artClassVersion: 2,
    label: "EC002993: Capacitive level meter"
  },
  {
    classId: "EC002994",
    classGroup: "EG000026",
    artClassDesc: "Temperature process measuring system",
    artClassVersion: 1,
    label: "EC002994: Temperature process measuring system"
  },
  {
    classId: "EC002995",
    classGroup: "EG000026",
    artClassDesc: "Microwave level meter",
    artClassVersion: 2,
    label: "EC002995: Microwave level meter"
  },
  {
    classId: "EC002996",
    classGroup: "EG000026",
    artClassDesc: "RFID transponder",
    artClassVersion: 3,
    label: "EC002996: RFID transponder"
  },
  {
    classId: "EC002997",
    classGroup: "EG000026",
    artClassDesc: "Accessories/spare parts for RFID",
    artClassVersion: 3,
    label: "EC002997: Accessories/spare parts for RFID"
  },
  {
    classId: "EC002998",
    classGroup: "EG000026",
    artClassDesc: "RFID reader",
    artClassVersion: 1,
    label: "EC002998: RFID reader"
  },
  {
    classId: "EC002999",
    classGroup: "EG000026",
    artClassDesc: "Matrix code reader",
    artClassVersion: 3,
    label: "EC002999: Matrix code reader"
  },
  {
    classId: "EC003000",
    classGroup: "EG000046",
    artClassDesc: "Socks",
    artClassVersion: 4,
    label: "EC003000: Socks"
  },
  {
    classId: "EC003001",
    classGroup: "EG000026",
    artClassDesc: "Ultrasonic level meter",
    artClassVersion: 2,
    label: "EC003001: Ultrasonic level meter"
  },
  {
    classId: "EC003002",
    classGroup: "EG000021",
    artClassDesc: "Potential equalization connector",
    artClassVersion: 3,
    label: "EC003002: Potential equalization connector"
  },
  {
    classId: "EC003003",
    classGroup: "EG000051",
    artClassDesc: "Stapler/nailer (electric)",
    artClassVersion: 3,
    label: "EC003003: Stapler/nailer (electric)"
  },
  {
    classId: "EC003004",
    classGroup: "EG000051",
    artClassDesc: "Belt file (electric)",
    artClassVersion: 3,
    label: "EC003004: Belt file (electric)"
  },
  {
    classId: "EC003005",
    classGroup: "EG000051",
    artClassDesc: "Belt file (battery)",
    artClassVersion: 4,
    label: "EC003005: Belt file (battery)"
  },
  {
    classId: "EC003006",
    classGroup: "EG000051",
    artClassDesc: "Hot glue gun (battery)",
    artClassVersion: 4,
    label: "EC003006: Hot glue gun (battery)"
  },
  {
    classId: "EC003007",
    classGroup: "EG000050",
    artClassDesc: "Accessories/spare parts for cable pulling system",
    artClassVersion: 3,
    label: "EC003007: Accessories/spare parts for cable pulling system"
  },
  {
    classId: "EC003008",
    classGroup: "EG000050",
    artClassDesc: "Accessories/spare parts for ladder/scaffold",
    artClassVersion: 2,
    label: "EC003008: Accessories/spare parts for ladder/scaffold"
  },
  {
    classId: "EC003009",
    classGroup: "EG000050",
    artClassDesc: "Accessories/spare parts for measuring tools",
    artClassVersion: 2,
    label: "EC003009: Accessories/spare parts for measuring tools"
  },
  {
    classId: "EC003010",
    classGroup: "EG000050",
    artClassDesc: "Accessories/spare parts for threading tool",
    artClassVersion: 3,
    label: "EC003010: Accessories/spare parts for threading tool"
  },
  {
    classId: "EC003011",
    classGroup: "EG020002",
    artClassDesc: "Accessories/spare parts for hole saws and drills",
    artClassVersion: 3,
    label: "EC003011: Accessories/spare parts for hole saws and drills"
  },
  {
    classId: "EC003012",
    classGroup: "EG000051",
    artClassDesc: "Biscuit joiner (electric)",
    artClassVersion: 3,
    label: "EC003012: Biscuit joiner (electric)"
  },
  {
    classId: "EC003013",
    classGroup: "EG000049",
    artClassDesc: "Bar/corrugated dowel",
    artClassVersion: 2,
    label: "EC003013: Bar/corrugated dowel"
  },
  {
    classId: "EC003014",
    classGroup: "EG000049",
    artClassDesc: "Biscuit joint",
    artClassVersion: 1,
    label: "EC003014: Biscuit joint"
  },
  {
    classId: "EC003015",
    classGroup: "EG000026",
    artClassDesc: "Accessories/spare parts for industrial sensors",
    artClassVersion: 4,
    label: "EC003015: Accessories/spare parts for industrial sensors"
  },
  {
    classId: "EC003016",
    classGroup: "EG020002",
    artClassDesc: "Blade for oscillator",
    artClassVersion: 3,
    label: "EC003016: Blade for oscillator"
  },
  {
    classId: "EC003017",
    classGroup: "EG020002",
    artClassDesc: "Accessories/spare parts for blower vac",
    artClassVersion: 4,
    label: "EC003017: Accessories/spare parts for blower vac"
  },
  {
    classId: "EC003018",
    classGroup: "EG000043",
    artClassDesc: "Heating for satellite antenna",
    artClassVersion: 2,
    label: "EC003018: Heating for satellite antenna"
  },
  {
    classId: "EC003019",
    classGroup: "EG000050",
    artClassDesc: "Accessories/spare parts for jointing compound",
    artClassVersion: 4,
    label: "EC003019: Accessories/spare parts for jointing compound"
  },
  {
    classId: "EC003020",
    classGroup: "EG000049",
    artClassDesc: "Split-/safe pin",
    artClassVersion: 2,
    label: "EC003020: Split-/safe pin"
  },
  {
    classId: "EC003021",
    classGroup: "EG014000",
    artClassDesc: "Day shape",
    artClassVersion: 2,
    label: "EC003021: Day shape"
  },
  {
    classId: "EC003022",
    classGroup: "EG000049",
    artClassDesc: "Seismic Bracing System",
    artClassVersion: 1,
    label: "EC003022: Seismic Bracing System"
  },
  {
    classId: "EC003023",
    classGroup: "EG015540",
    artClassDesc: "Fitting with 1 connection",
    artClassVersion: 3,
    label: "EC003023: Fitting with 1 connection"
  },
  {
    classId: "EC003024",
    classGroup: "EG015540",
    artClassDesc: "Fitting with 2 connections",
    artClassVersion: 3,
    label: "EC003024: Fitting with 2 connections"
  },
  {
    classId: "EC003025",
    classGroup: "EG015540",
    artClassDesc: "Fitting with 3 connections",
    artClassVersion: 3,
    label: "EC003025: Fitting with 3 connections"
  },
  {
    classId: "EC003026",
    classGroup: "EG015540",
    artClassDesc: "Fitting with 4 connections",
    artClassVersion: 3,
    label: "EC003026: Fitting with 4 connections"
  },
  {
    classId: "EC003027",
    classGroup: "EG020450",
    artClassDesc: "Concrete bed infiltration gully",
    artClassVersion: 2,
    label: "EC003027: Concrete bed infiltration gully"
  },
  {
    classId: "EC003028",
    classGroup: "EG020450",
    artClassDesc: "Infiltration gully",
    artClassVersion: 4,
    label: "EC003028: Infiltration gully"
  },
  {
    classId: "EC003030",
    classGroup: "EG020450",
    artClassDesc: "Gully top (vehicular and pedestrian areas)",
    artClassVersion: 4,
    label: "EC003030: Gully top (vehicular and pedestrian areas)"
  },
  {
    classId: "EC003031",
    classGroup: "EG020450",
    artClassDesc: "Road gully",
    artClassVersion: 4,
    label: "EC003031: Road gully"
  },
  {
    classId: "EC003032",
    classGroup: "EG015130",
    artClassDesc: "End cap for channel drain",
    artClassVersion: 4,
    label: "EC003032: End cap for channel drain"
  },
  {
    classId: "EC003033",
    classGroup: "EG015130",
    artClassDesc: "Grating for channel drain",
    artClassVersion: 4,
    label: "EC003033: Grating for channel drain"
  },
  {
    classId: "EC003034",
    classGroup: "EG015130",
    artClassDesc: "Foul air trap for channel drain",
    artClassVersion: 4,
    label: "EC003034: Foul air trap for channel drain"
  },
  {
    classId: "EC003036",
    classGroup: "EG020450",
    artClassDesc: "Pit-hole cover",
    artClassVersion: 4,
    label: "EC003036: Pit-hole cover"
  },
  {
    classId: "EC003037",
    classGroup: "EG015130",
    artClassDesc: "Sump unit for channel drain",
    artClassVersion: 4,
    label: "EC003037: Sump unit for channel drain"
  },
  {
    classId: "EC003038",
    classGroup: "EG015830",
    artClassDesc: "Water meter bracket",
    artClassVersion: 3,
    label: "EC003038: Water meter bracket"
  },
  {
    classId: "EC003039",
    classGroup: "EG015810",
    artClassDesc: "Gas meter bracket",
    artClassVersion: 2,
    label: "EC003039: Gas meter bracket"
  },
  {
    classId: "EC003040",
    classGroup: "EG015510",
    artClassDesc: "Mounting-/connection set for fire hose reel",
    artClassVersion: 2,
    label: "EC003040: Mounting-/connection set for fire hose reel"
  },
  {
    classId: "EC003041",
    classGroup: "EG020450",
    artClassDesc: "Outlet module base",
    artClassVersion: 2,
    label: "EC003041: Outlet module base"
  },
  {
    classId: "EC003042",
    classGroup: "EG020450",
    artClassDesc: "Concrete manhole",
    artClassVersion: 3,
    label: "EC003042: Concrete manhole"
  },
  {
    classId: "EC003043",
    classGroup: "EG020450",
    artClassDesc: "Culvert",
    artClassVersion: 3,
    label: "EC003043: Culvert"
  },
  {
    classId: "EC003044",
    classGroup: "EG020450",
    artClassDesc: "Concrete pipe",
    artClassVersion: 3,
    label: "EC003044: Concrete pipe"
  },
  {
    classId: "EC003045",
    classGroup: "EG015710",
    artClassDesc: "Top cover radiator",
    artClassVersion: 3,
    label: "EC003045: Top cover radiator"
  },
  {
    classId: "EC003046",
    classGroup: "EG015710",
    artClassDesc: "Side cover radiator",
    artClassVersion: 3,
    label: "EC003046: Side cover radiator"
  },
  {
    classId: "EC003047",
    classGroup: "EG020150",
    artClassDesc: "Beam joint (single)",
    artClassVersion: 4,
    label: "EC003047: Beam joint (single)"
  },
  {
    classId: "EC003050",
    classGroup: "EG020150",
    artClassDesc: "Roofing torch",
    artClassVersion: 4,
    label: "EC003050: Roofing torch"
  },
  {
    classId: "EC003051",
    classGroup: "EG020150",
    artClassDesc: "Trimmer joint",
    artClassVersion: 3,
    label: "EC003051: Trimmer joint"
  },
  {
    classId: "EC003053",
    classGroup: "EG020400",
    artClassDesc: "Door board",
    artClassVersion: 4,
    label: "EC003053: Door board"
  },
  {
    classId: "EC003054",
    classGroup: "EG020400",
    artClassDesc: "Wood (round)",
    artClassVersion: 4,
    label: "EC003054: Wood (round)"
  },
  {
    classId: "EC003055",
    classGroup: "EG020400",
    artClassDesc: "Framework indoor application",
    artClassVersion: 4,
    label: "EC003055: Framework indoor application"
  },
  {
    classId: "EC003056",
    classGroup: "EG020400",
    artClassDesc: "Multiplex",
    artClassVersion: 4,
    label: "EC003056: Multiplex"
  },
  {
    classId: "EC003057",
    classGroup: "EG020400",
    artClassDesc: "OSB board",
    artClassVersion: 4,
    label: "EC003057: OSB board"
  },
  {
    classId: "EC003058",
    classGroup: "EG020400",
    artClassDesc: "Wood fibre board",
    artClassVersion: 4,
    label: "EC003058: Wood fibre board"
  },
  {
    classId: "EC003059",
    classGroup: "EG020450",
    artClassDesc: "Kerbstone, corner",
    artClassVersion: 3,
    label: "EC003059: Kerbstone, corner"
  },
  {
    classId: "EC003060",
    classGroup: "EG020450",
    artClassDesc: "Kerbstone, corner (non fading)",
    artClassVersion: 3,
    label: "EC003060: Kerbstone, corner (non fading)"
  },
  {
    classId: "EC003061",
    classGroup: "EG020450",
    artClassDesc: "Kerbstone, point",
    artClassVersion: 3,
    label: "EC003061: Kerbstone, point"
  },
  {
    classId: "EC003062",
    classGroup: "EG020450",
    artClassDesc: "Concrete tile machine package",
    artClassVersion: 4,
    label: "EC003062: Concrete tile machine package"
  },
  {
    classId: "EC003064",
    classGroup: "EG020550",
    artClassDesc: "Internal wall brick",
    artClassVersion: 4,
    label: "EC003064: Internal wall brick"
  },
  {
    classId: "EC003065",
    classGroup: "EG020450",
    artClassDesc: "Bishop cap",
    artClassVersion: 3,
    label: "EC003065: Bishop cap"
  },
  {
    classId: "EC003066",
    classGroup: "EG020450",
    artClassDesc: "Driveway kerbstone",
    artClassVersion: 4,
    label: "EC003066: Driveway kerbstone"
  },
  {
    classId: "EC003067",
    classGroup: "EG020450",
    artClassDesc: "Twill stone",
    artClassVersion: 4,
    label: "EC003067: Twill stone"
  },
  {
    classId: "EC003068",
    classGroup: "EG020550",
    artClassDesc: "Lintel",
    artClassVersion: 4,
    label: "EC003068: Lintel"
  },
  {
    classId: "EC003069",
    classGroup: "EG020450",
    artClassDesc: "Street gutter",
    artClassVersion: 2,
    label: "EC003069: Street gutter"
  },
  {
    classId: "EC003070",
    classGroup: "EG020550",
    artClassDesc: "Monolithic brick",
    artClassVersion: 4,
    label: "EC003070: Monolithic brick"
  },
  {
    classId: "EC003072",
    classGroup: "EG020550",
    artClassDesc: "Window-sill brick",
    artClassVersion: 3,
    label: "EC003072: Window-sill brick"
  },
  {
    classId: "EC003073",
    classGroup: "EG020550",
    artClassDesc: "Accessories/spare parts for window-sill brick",
    artClassVersion: 2,
    label: "EC003073: Accessories/spare parts for window-sill brick"
  },
  {
    classId: "EC003074",
    classGroup: "EG020550",
    artClassDesc: "Brick slip",
    artClassVersion: 4,
    label: "EC003074: Brick slip"
  },
  {
    classId: "EC003075",
    classGroup: "EG020450",
    artClassDesc: "Paving-stone machine package",
    artClassVersion: 3,
    label: "EC003075: Paving-stone machine package"
  },
  {
    classId: "EC003076",
    classGroup: "EG020450",
    artClassDesc: "Paving-stone",
    artClassVersion: 3,
    label: "EC003076: Paving-stone"
  },
  {
    classId: "EC003078",
    classGroup: "EG020400",
    artClassDesc: "Glued wooden panel",
    artClassVersion: 4,
    label: "EC003078: Glued wooden panel"
  },
  {
    classId: "EC003079",
    classGroup: "EG020400",
    artClassDesc: "Interior door frame",
    artClassVersion: 4,
    label: "EC003079: Interior door frame"
  },
  {
    classId: "EC003080",
    classGroup: "EG020400",
    artClassDesc: "Interior door (wood)",
    artClassVersion: 4,
    label: "EC003080: Interior door (wood)"
  },
  {
    classId: "EC003081",
    classGroup: "EG020400",
    artClassDesc: "Exterior door (wood)",
    artClassVersion: 4,
    label: "EC003081: Exterior door (wood)"
  },
  {
    classId: "EC003082",
    classGroup: "EG000014",
    artClassDesc: "Plug/socket for truck/trailer",
    artClassVersion: 2,
    label: "EC003082: Plug/socket for truck/trailer"
  },
  {
    classId: "EC003083",
    classGroup: "EG000014",
    artClassDesc: "Accessories/spare parts for plug/socket for truck/trailer",
    artClassVersion: 2,
    label: "EC003083: Accessories/spare parts for plug/socket for truck/trailer"
  },
  {
    classId: "EC003084",
    classGroup: "EG015610",
    artClassDesc: "Six-way control valve",
    artClassVersion: 3,
    label: "EC003084: Six-way control valve"
  },
  {
    classId: "EC003085",
    classGroup: "EG017210",
    artClassDesc: "Mounting material toilet seat",
    artClassVersion: 3,
    label: "EC003085: Mounting material toilet seat"
  },
  {
    classId: "EC003086",
    classGroup: "EG015630",
    artClassDesc: "Electric actuator with damper blade round air duct",
    artClassVersion: 2,
    label: "EC003086: Electric actuator with damper blade round air duct"
  },
  {
    classId: "EC003087",
    classGroup: "EG015540",
    artClassDesc: "Square fitting with 2 connections",
    artClassVersion: 3,
    label: "EC003087: Square fitting with 2 connections"
  },
  {
    classId: "EC003088",
    classGroup: "EG020100",
    artClassDesc: "Mortice door lock",
    artClassVersion: 4,
    label: "EC003088: Mortice door lock"
  },
  {
    classId: "EC003089",
    classGroup: "EG020100",
    artClassDesc: "Door/window rim lock",
    artClassVersion: 3,
    label: "EC003089: Door/window rim lock"
  },
  {
    classId: "EC003090",
    classGroup: "EG020100",
    artClassDesc: "Telescopic window stay",
    artClassVersion: 4,
    label: "EC003090: Telescopic window stay"
  },
  {
    classId: "EC003091",
    classGroup: "EG020100",
    artClassDesc: "Combi window stay",
    artClassVersion: 4,
    label: "EC003091: Combi window stay"
  },
  {
    classId: "EC003092",
    classGroup: "EG020100",
    artClassDesc: "Additional rim lock",
    artClassVersion: 4,
    label: "EC003092: Additional rim lock"
  },
  {
    classId: "EC003093",
    classGroup: "EG020100",
    artClassDesc: "Additional mortice window lock",
    artClassVersion: 4,
    label: "EC003093: Additional mortice window lock"
  },
  {
    classId: "EC003094",
    classGroup: "EG020100",
    artClassDesc: "Additional mortice door lock",
    artClassVersion: 4,
    label: "EC003094: Additional mortice door lock"
  },
  {
    classId: "EC003095",
    classGroup: "EG020100",
    artClassDesc: "Door rosette",
    artClassVersion: 3,
    label: "EC003095: Door rosette"
  },
  {
    classId: "EC003096",
    classGroup: "EG020100",
    artClassDesc: "Door handle plate",
    artClassVersion: 3,
    label: "EC003096: Door handle plate"
  },
  {
    classId: "EC003097",
    classGroup: "EG015540",
    artClassDesc: "Fitting with 5 connections",
    artClassVersion: 3,
    label: "EC003097: Fitting with 5 connections"
  },
  {
    classId: "EC003099",
    classGroup: "EG015540",
    artClassDesc: "Wall plate",
    artClassVersion: 3,
    label: "EC003099: Wall plate"
  },
  {
    classId: "EC003101",
    classGroup: "EG020500",
    artClassDesc: "Insulating flakes - pearls - foam",
    artClassVersion: 4,
    label: "EC003101: Insulating flakes - pearls - foam"
  },
  {
    classId: "EC003102",
    classGroup: "EG020500",
    artClassDesc: "Accessories/spare parts for insulating material",
    artClassVersion: 4,
    label: "EC003102: Accessories/spare parts for insulating material"
  },
  {
    classId: "EC003103",
    classGroup: "EG020400",
    artClassDesc: "Cant strip",
    artClassVersion: 4,
    label: "EC003103: Cant strip"
  },
  {
    classId: "EC003104",
    classGroup: "EG020550",
    artClassDesc: "Wall coping",
    artClassVersion: 4,
    label: "EC003104: Wall coping"
  },
  {
    classId: "EC003105",
    classGroup: "EG020550",
    artClassDesc: "Storey panel",
    artClassVersion: 3,
    label: "EC003105: Storey panel"
  },
  {
    classId: "EC003106",
    classGroup: "EG020300",
    artClassDesc: "Roof element pitched roof",
    artClassVersion: 3,
    label: "EC003106: Roof element pitched roof"
  },
  {
    classId: "EC003107",
    classGroup: "EG020350",
    artClassDesc: "Facade plate",
    artClassVersion: 4,
    label: "EC003107: Facade plate"
  },
  {
    classId: "EC003110",
    classGroup: "EG015530",
    artClassDesc: "Zinc pipe",
    artClassVersion: 2,
    label: "EC003110: Zinc pipe"
  },
  {
    classId: "EC003111",
    classGroup: "EG020100",
    artClassDesc: "Balcony skylight",
    artClassVersion: 3,
    label: "EC003111: Balcony skylight"
  },
  {
    classId: "EC003112",
    classGroup: "EG020100",
    artClassDesc: "Skylight",
    artClassVersion: 4,
    label: "EC003112: Skylight"
  },
  {
    classId: "EC003113",
    classGroup: "EG020100",
    artClassDesc: "Daylight system",
    artClassVersion: 4,
    label: "EC003113: Daylight system"
  },
  {
    classId: "EC003114",
    classGroup: "EG020100",
    artClassDesc: "Gutter piece for daylight system",
    artClassVersion: 3,
    label: "EC003114: Gutter piece for daylight system"
  },
  {
    classId: "EC003115",
    classGroup: "EG020100",
    artClassDesc: "Combi gable window",
    artClassVersion: 3,
    label: "EC003115: Combi gable window"
  },
  {
    classId: "EC003116",
    classGroup: "EG015110",
    artClassDesc: "Anti climbing bracket",
    artClassVersion: 2,
    label: "EC003116: Anti climbing bracket"
  },
  {
    classId: "EC003117",
    classGroup: "EG020100",
    artClassDesc: "Letter plate",
    artClassVersion: 4,
    label: "EC003117: Letter plate"
  },
  {
    classId: "EC003118",
    classGroup: "EG020100",
    artClassDesc: "Strike plate/strike box/lock hook",
    artClassVersion: 4,
    label: "EC003118: Strike plate/strike box/lock hook"
  },
  {
    classId: "EC003119",
    classGroup: "EG020100",
    artClassDesc: "Safety door strike box/lock hook",
    artClassVersion: 4,
    label: "EC003119: Safety door strike box/lock hook"
  },
  {
    classId: "EC003120",
    classGroup: "EG020100",
    artClassDesc: "Lift-off hinge",
    artClassVersion: 4,
    label: "EC003120: Lift-off hinge"
  },
  {
    classId: "EC003121",
    classGroup: "EG020004",
    artClassDesc: "Door hinge, complete",
    artClassVersion: 4,
    label: "EC003121: Door hinge, complete"
  },
  {
    classId: "EC003122",
    classGroup: "EG020100",
    artClassDesc: "Door security lock",
    artClassVersion: 4,
    label: "EC003122: Door security lock"
  },
  {
    classId: "EC003123",
    classGroup: "EG020150",
    artClassDesc: "Mounting clip",
    artClassVersion: 4,
    label: "EC003123: Mounting clip"
  },
  {
    classId: "EC003124",
    classGroup: "EG020150",
    artClassDesc: "Pressure sprayer",
    artClassVersion: 4,
    label: "EC003124: Pressure sprayer"
  },
  {
    classId: "EC003126",
    classGroup: "EG020150",
    artClassDesc: "Plaster knife",
    artClassVersion: 4,
    label: "EC003126: Plaster knife"
  },
  {
    classId: "EC003127",
    classGroup: "EG020150",
    artClassDesc: "Burin",
    artClassVersion: 3,
    label: "EC003127: Burin"
  },
  {
    classId: "EC003128",
    classGroup: "EG020150",
    artClassDesc: "Plaster hawk",
    artClassVersion: 2,
    label: "EC003128: Plaster hawk"
  },
  {
    classId: "EC003129",
    classGroup: "EG020150",
    artClassDesc: "Joint brush",
    artClassVersion: 3,
    label: "EC003129: Joint brush"
  },
  {
    classId: "EC003130",
    classGroup: "EG020150",
    artClassDesc: "Tuck-pointing trowel",
    artClassVersion: 3,
    label: "EC003130: Tuck-pointing trowel"
  },
  {
    classId: "EC003131",
    classGroup: "EG020150",
    artClassDesc: "Vibratory plate",
    artClassVersion: 3,
    label: "EC003131: Vibratory plate"
  },
  {
    classId: "EC003132",
    classGroup: "EG020150",
    artClassDesc: "Sand sieve",
    artClassVersion: 4,
    label: "EC003132: Sand sieve"
  },
  {
    classId: "EC003133",
    classGroup: "EG020150",
    artClassDesc: "Block splitter",
    artClassVersion: 4,
    label: "EC003133: Block splitter"
  },
  {
    classId: "EC003134",
    classGroup: "EG020150",
    artClassDesc: "Brick carrier",
    artClassVersion: 3,
    label: "EC003134: Brick carrier"
  },
  {
    classId: "EC003135",
    classGroup: "EG020150",
    artClassDesc: "Plaster spraying machine",
    artClassVersion: 4,
    label: "EC003135: Plaster spraying machine"
  },
  {
    classId: "EC003136",
    classGroup: "EG020150",
    artClassDesc: "Sanding plate",
    artClassVersion: 3,
    label: "EC003136: Sanding plate"
  },
  {
    classId: "EC003138",
    classGroup: "EG020150",
    artClassDesc: "Battens joint",
    artClassVersion: 4,
    label: "EC003138: Battens joint"
  },
  {
    classId: "EC003139",
    classGroup: "EG020150",
    artClassDesc: "Waste sack",
    artClassVersion: 4,
    label: "EC003139: Waste sack"
  },
  {
    classId: "EC003140",
    classGroup: "EG020150",
    artClassDesc: "Masonry cord-block",
    artClassVersion: 4,
    label: "EC003140: Masonry cord-block"
  },
  {
    classId: "EC003141",
    classGroup: "EG020650",
    artClassDesc: "Gravel/sand",
    artClassVersion: 3,
    label: "EC003141: Gravel/sand"
  },
  {
    classId: "EC003142",
    classGroup: "EG000020",
    artClassDesc: "Plug fuse",
    artClassVersion: 1,
    label: "EC003142: Plug fuse"
  },
  {
    classId: "EC003143",
    classGroup: "EG020100",
    artClassDesc: "Gypsum board",
    artClassVersion: 4,
    label: "EC003143: Gypsum board"
  },
  {
    classId: "EC003149",
    classGroup: "EG020550",
    artClassDesc: "Under gable tile",
    artClassVersion: 3,
    label: "EC003149: Under gable tile"
  },
  {
    classId: "EC003150",
    classGroup: "EG020550",
    artClassDesc: "Roof tile",
    artClassVersion: 4,
    label: "EC003150: Roof tile"
  },
  {
    classId: "EC003154",
    classGroup: "EG020100",
    artClassDesc: "Reinforcement fabric",
    artClassVersion: 4,
    label: "EC003154: Reinforcement fabric"
  },
  {
    classId: "EC003156",
    classGroup: "EG020550",
    artClassDesc: "Dry ridge",
    artClassVersion: 3,
    label: "EC003156: Dry ridge"
  },
  {
    classId: "EC003157",
    classGroup: "EG020100",
    artClassDesc: "Plaster profile",
    artClassVersion: 4,
    label: "EC003157: Plaster profile"
  },
  {
    classId: "EC003158",
    classGroup: "EG000049",
    artClassDesc: "Two-piece bracket square",
    artClassVersion: 2,
    label: "EC003158: Two-piece bracket square"
  },
  {
    classId: "EC003159",
    classGroup: "EG015530",
    artClassDesc: "Accessories/spare parts for rainwater pipe",
    artClassVersion: 2,
    label: "EC003159: Accessories/spare parts for rainwater pipe"
  },
  {
    classId: "EC003160",
    classGroup: "EG000049",
    artClassDesc: "Brick in bracket square",
    artClassVersion: 2,
    label: "EC003160: Brick in bracket square"
  },
  {
    classId: "EC003161",
    classGroup: "EG020400",
    artClassDesc: "Glazing bead",
    artClassVersion: 4,
    label: "EC003161: Glazing bead"
  },
  {
    classId: "EC003162",
    classGroup: "EG020100",
    artClassDesc: "Stucco net",
    artClassVersion: 3,
    label: "EC003162: Stucco net"
  },
  {
    classId: "EC003163",
    classGroup: "EG020100",
    artClassDesc: "Separation strip stucco ceiling",
    artClassVersion: 3,
    label: "EC003163: Separation strip stucco ceiling"
  },
  {
    classId: "EC003164",
    classGroup: "EG000041",
    artClassDesc: "A/V-Connector",
    artClassVersion: 3,
    label: "EC003164: A/V-Connector"
  },
  {
    classId: "EC003165",
    classGroup: "EG019710",
    artClassDesc: "C-profile, steel",
    artClassVersion: 3,
    label: "EC003165: C-profile, steel"
  },
  {
    classId: "EC003166",
    classGroup: "EG020100",
    artClassDesc: "Wallpaper",
    artClassVersion: 4,
    label: "EC003166: Wallpaper"
  },
  {
    classId: "EC003167",
    classGroup: "EG020400",
    artClassDesc: "Wall and facade cladding wood",
    artClassVersion: 4,
    label: "EC003167: Wall and facade cladding wood"
  },
  {
    classId: "EC003168",
    classGroup: "EG020100",
    artClassDesc: "Door/window ajar holder",
    artClassVersion: 3,
    label: "EC003168: Door/window ajar holder"
  },
  {
    classId: "EC003169",
    classGroup: "EG020100",
    artClassDesc: "Door handle",
    artClassVersion: 4,
    label: "EC003169: Door handle"
  },
  {
    classId: "EC003170",
    classGroup: "EG020100",
    artClassDesc: "Door knob",
    artClassVersion: 4,
    label: "EC003170: Door knob"
  },
  {
    classId: "EC003172",
    classGroup: "EG020100",
    artClassDesc: "Door closer",
    artClassVersion: 4,
    label: "EC003172: Door closer"
  },
  {
    classId: "EC003173",
    classGroup: "EG020100",
    artClassDesc: "Accessories/spare parts for door closer",
    artClassVersion: 3,
    label: "EC003173: Accessories/spare parts for door closer"
  },
  {
    classId: "EC003174",
    classGroup: "EG020100",
    artClassDesc: "Safety door handle plate",
    artClassVersion: 4,
    label: "EC003174: Safety door handle plate"
  },
  {
    classId: "EC003175",
    classGroup: "EG020100",
    artClassDesc: "Safety rosette",
    artClassVersion: 3,
    label: "EC003175: Safety rosette"
  },
  {
    classId: "EC003176",
    classGroup: "EG020100",
    artClassDesc: "Cylinder for lock",
    artClassVersion: 2,
    label: "EC003176: Cylinder for lock"
  },
  {
    classId: "EC003177",
    classGroup: "EG020300",
    artClassDesc: "Roof sheet",
    artClassVersion: 4,
    label: "EC003177: Roof sheet"
  },
  {
    classId: "EC003178",
    classGroup: "EG020300",
    artClassDesc: "Roof edging strip",
    artClassVersion: 4,
    label: "EC003178: Roof edging strip"
  },
  {
    classId: "EC003179",
    classGroup: "EG020650",
    artClassDesc: "Concrete mortar",
    artClassVersion: 4,
    label: "EC003179: Concrete mortar"
  },
  {
    classId: "EC003181",
    classGroup: "EG020650",
    artClassDesc: "Floor mortar",
    artClassVersion: 4,
    label: "EC003181: Floor mortar"
  },
  {
    classId: "EC003182",
    classGroup: "EG020650",
    artClassDesc: "Brickwork/pointing mortar",
    artClassVersion: 4,
    label: "EC003182: Brickwork/pointing mortar"
  },
  {
    classId: "EC003183",
    classGroup: "EG020200",
    artClassDesc: "Rebar net",
    artClassVersion: 4,
    label: "EC003183: Rebar net"
  },
  {
    classId: "EC003184",
    classGroup: "EG020200",
    artClassDesc: "Reinforcing steel bar",
    artClassVersion: 3,
    label: "EC003184: Reinforcing steel bar"
  },
  {
    classId: "EC003185",
    classGroup: "EG020250",
    artClassDesc: "Sealing tape",
    artClassVersion: 3,
    label: "EC003185: Sealing tape"
  },
  {
    classId: "EC003186",
    classGroup: "EG020004",
    artClassDesc: "Safety door fittings, complete",
    artClassVersion: 2,
    label: "EC003186: Safety door fittings, complete"
  },
  {
    classId: "EC003187",
    classGroup: "EG020400",
    artClassDesc: "Threshold",
    artClassVersion: 4,
    label: "EC003187: Threshold"
  },
  {
    classId: "EC003188",
    classGroup: "EG020100",
    artClassDesc: "Glass",
    artClassVersion: 3,
    label: "EC003188: Glass"
  },
  {
    classId: "EC003189",
    classGroup: "EG020250",
    artClassDesc: "Stone glue",
    artClassVersion: 4,
    label: "EC003189: Stone glue"
  },
  {
    classId: "EC003190",
    classGroup: "EG020600",
    artClassDesc: "Dovetail decking sheet",
    artClassVersion: 3,
    label: "EC003190: Dovetail decking sheet"
  },
  {
    classId: "EC003191",
    classGroup: "EG020200",
    artClassDesc: "Supporting beam for rebar",
    artClassVersion: 3,
    label: "EC003191: Supporting beam for rebar"
  },
  {
    classId: "EC003192",
    classGroup: "EG020200",
    artClassDesc: "Masonry reinforcement",
    artClassVersion: 3,
    label: "EC003192: Masonry reinforcement"
  },
  {
    classId: "EC003193",
    classGroup: "EG020200",
    artClassDesc: "Spacer for structural steel",
    artClassVersion: 4,
    label: "EC003193: Spacer for structural steel"
  },
  {
    classId: "EC003194",
    classGroup: "EG020600",
    artClassDesc: "Hollow core slab",
    artClassVersion: 2,
    label: "EC003194: Hollow core slab"
  },
  {
    classId: "EC003196",
    classGroup: "EG020150",
    artClassDesc: "Foamband",
    artClassVersion: 3,
    label: "EC003196: Foamband"
  },
  {
    classId: "EC003197",
    classGroup: "EG020150",
    artClassDesc: "Sanding gauze",
    artClassVersion: 4,
    label: "EC003197: Sanding gauze"
  },
  {
    classId: "EC003198",
    classGroup: "EG020150",
    artClassDesc: "Groove raker",
    artClassVersion: 3,
    label: "EC003198: Groove raker"
  },
  {
    classId: "EC003200",
    classGroup: "EG020150",
    artClassDesc: "Wall tie",
    artClassVersion: 3,
    label: "EC003200: Wall tie"
  },
  {
    classId: "EC003201",
    classGroup: "EG020400",
    artClassDesc: "Fibre cement board",
    artClassVersion: 4,
    label: "EC003201: Fibre cement board"
  },
  {
    classId: "EC003203",
    classGroup: "EG020150",
    artClassDesc: "Tripod",
    artClassVersion: 3,
    label: "EC003203: Tripod"
  },
  {
    classId: "EC003204",
    classGroup: "EG020250",
    artClassDesc: "Mounting glue",
    artClassVersion: 3,
    label: "EC003204: Mounting glue"
  },
  {
    classId: "EC003205",
    classGroup: "EG020250",
    artClassDesc: "Universal contact adhesive",
    artClassVersion: 3,
    label: "EC003205: Universal contact adhesive"
  },
  {
    classId: "EC003206",
    classGroup: "EG020250",
    artClassDesc: "Plastic glue",
    artClassVersion: 4,
    label: "EC003206: Plastic glue"
  },
  {
    classId: "EC003207",
    classGroup: "EG020250",
    artClassDesc: "Construction glue",
    artClassVersion: 4,
    label: "EC003207: Construction glue"
  },
  {
    classId: "EC003208",
    classGroup: "EG020550",
    artClassDesc: "Eave",
    artClassVersion: 4,
    label: "EC003208: Eave"
  },
  {
    classId: "EC003209",
    classGroup: "EG020550",
    artClassDesc: "Dry ventilating ridge",
    artClassVersion: 4,
    label: "EC003209: Dry ventilating ridge"
  },
  {
    classId: "EC003210",
    classGroup: "EG020550",
    artClassDesc: "Roof fixation",
    artClassVersion: 3,
    label: "EC003210: Roof fixation"
  },
  {
    classId: "EC003211",
    classGroup: "EG020550",
    artClassDesc: "Corner and valley rafter",
    artClassVersion: 3,
    label: "EC003211: Corner and valley rafter"
  },
  {
    classId: "EC003213",
    classGroup: "EG020300",
    artClassDesc: "Accessories/spare parts for corrugated sheet",
    artClassVersion: 3,
    label: "EC003213: Accessories/spare parts for corrugated sheet"
  },
  {
    classId: "EC003214",
    classGroup: "EG020150",
    artClassDesc: "Wedge",
    artClassVersion: 3,
    label: "EC003214: Wedge"
  },
  {
    classId: "EC003215",
    classGroup: "EG020150",
    artClassDesc: "Profile tong",
    artClassVersion: 3,
    label: "EC003215: Profile tong"
  },
  {
    classId: "EC003216",
    classGroup: "EG020100",
    artClassDesc: "Joint filler",
    artClassVersion: 4,
    label: "EC003216: Joint filler"
  },
  {
    classId: "EC003217",
    classGroup: "EG020150",
    artClassDesc: "Paint roller grate",
    artClassVersion: 3,
    label: "EC003217: Paint roller grate"
  },
  {
    classId: "EC003218",
    classGroup: "EG020150",
    artClassDesc: "Paint tray",
    artClassVersion: 3,
    label: "EC003218: Paint tray"
  },
  {
    classId: "EC003219",
    classGroup: "EG020150",
    artClassDesc: "Joint knife spacer sleeve",
    artClassVersion: 4,
    label: "EC003219: Joint knife spacer sleeve"
  },
  {
    classId: "EC003220",
    classGroup: "EG020150",
    artClassDesc: "Shoring prop",
    artClassVersion: 2,
    label: "EC003220: Shoring prop"
  },
  {
    classId: "EC003221",
    classGroup: "EG020150",
    artClassDesc: "Screeder board",
    artClassVersion: 3,
    label: "EC003221: Screeder board"
  },
  {
    classId: "EC003222",
    classGroup: "EG020100",
    artClassDesc: "Espagnolette lock",
    artClassVersion: 3,
    label: "EC003222: Espagnolette lock"
  },
  {
    classId: "EC003223",
    classGroup: "EG020100",
    artClassDesc: "Espagnolette bolt",
    artClassVersion: 3,
    label: "EC003223: Espagnolette bolt"
  },
  {
    classId: "EC003225",
    classGroup: "EG020600",
    artClassDesc: "Filling element system floor",
    artClassVersion: 3,
    label: "EC003225: Filling element system floor"
  },
  {
    classId: "EC003226",
    classGroup: "EG020600",
    artClassDesc: "Floor joist system floor",
    artClassVersion: 3,
    label: "EC003226: Floor joist system floor"
  },
  {
    classId: "EC003228",
    classGroup: "EG020100",
    artClassDesc: "Insect screen",
    artClassVersion: 3,
    label: "EC003228: Insect screen"
  },
  {
    classId: "EC003229",
    classGroup: "EG020100",
    artClassDesc: "Roller shutter",
    artClassVersion: 4,
    label: "EC003229: Roller shutter"
  },
  {
    classId: "EC003230",
    classGroup: "EG020550",
    artClassDesc: "Roof connection",
    artClassVersion: 3,
    label: "EC003230: Roof connection"
  },
  {
    classId: "EC003232",
    classGroup: "EG020450",
    artClassDesc: "Bond stone",
    artClassVersion: 2,
    label: "EC003232: Bond stone"
  },
  {
    classId: "EC003233",
    classGroup: "EG020450",
    artClassDesc: "Kerbstone driveway transition",
    artClassVersion: 4,
    label: "EC003233: Kerbstone driveway transition"
  },
  {
    classId: "EC003234",
    classGroup: "EG020450",
    artClassDesc: "Kerbstone, straight (non fading)",
    artClassVersion: 3,
    label: "EC003234: Kerbstone, straight (non fading)"
  },
  {
    classId: "EC003235",
    classGroup: "EG020450",
    artClassDesc: "Kerbstone, straight (with fading)",
    artClassVersion: 3,
    label: "EC003235: Kerbstone, straight (with fading)"
  },
  {
    classId: "EC003236",
    classGroup: "EG020450",
    artClassDesc: "Kerbstone angle (non fading)",
    artClassVersion: 3,
    label: "EC003236: Kerbstone angle (non fading)"
  },
  {
    classId: "EC003237",
    classGroup: "EG020450",
    artClassDesc: "Kerbstone angle (with fading)",
    artClassVersion: 3,
    label: "EC003237: Kerbstone angle (with fading)"
  },
  {
    classId: "EC003238",
    classGroup: "EG020600",
    artClassDesc: "Foundation formwork",
    artClassVersion: 4,
    label: "EC003238: Foundation formwork"
  },
  {
    classId: "EC003239",
    classGroup: "EG020650",
    artClassDesc: "Cement",
    artClassVersion: 4,
    label: "EC003239: Cement"
  },
  {
    classId: "EC003240",
    classGroup: "EG019760",
    artClassDesc: "Feed-in bend meter box floor plate",
    artClassVersion: 3,
    label: "EC003240: Feed-in bend meter box floor plate"
  },
  {
    classId: "EC003241",
    classGroup: "EG019760",
    artClassDesc: "Meter box floor plate",
    artClassVersion: 2,
    label: "EC003241: Meter box floor plate"
  },
  {
    classId: "EC003242",
    classGroup: "EG015570",
    artClassDesc: "Rubber compensator",
    artClassVersion: 3,
    label: "EC003242: Rubber compensator"
  },
  {
    classId: "EC003243",
    classGroup: "EG015570",
    artClassDesc: "Metal compensator",
    artClassVersion: 3,
    label: "EC003243: Metal compensator"
  },
  {
    classId: "EC003244",
    classGroup: "EG017620",
    artClassDesc: "Tap jet regulator",
    artClassVersion: 3,
    label: "EC003244: Tap jet regulator"
  },
  {
    classId: "EC003245",
    classGroup: "EG000026",
    artClassDesc: "Robotic arm",
    artClassVersion: 2,
    label: "EC003245: Robotic arm"
  },
  {
    classId: "EC003246",
    classGroup: "EG017910",
    artClassDesc: "Shower floor",
    artClassVersion: 3,
    label: "EC003246: Shower floor"
  },
  {
    classId: "EC003247",
    classGroup: "EG015560",
    artClassDesc: "Floor connection for flue gas/air supply",
    artClassVersion: 2,
    label: "EC003247: Floor connection for flue gas/air supply"
  },
  {
    classId: "EC003248",
    classGroup: "EG000001",
    artClassDesc: "Power cable < 1 kV, for permanent installation",
    artClassVersion: 3,
    label: "EC003248: Power cable < 1 kV, for permanent installation"
  },
  {
    classId: "EC003249",
    classGroup: "EG000001",
    artClassDesc: "Data and communication cable",
    artClassVersion: 3,
    label: "EC003249: Data and communication cable"
  },
  {
    classId: "EC003250",
    classGroup: "EG000001",
    artClassDesc: "Power cable < 1 kV, for moving application",
    artClassVersion: 3,
    label: "EC003250: Power cable < 1 kV, for moving application"
  },
  {
    classId: "EC003251",
    classGroup: "EG000001",
    artClassDesc: "Power cable >= 1 kV, for permanent installation",
    artClassVersion: 3,
    label: "EC003251: Power cable >= 1 kV, for permanent installation"
  },
  {
    classId: "EC003253",
    classGroup: "EG015210",
    artClassDesc: "Pellet stove",
    artClassVersion: 1,
    label: "EC003253: Pellet stove"
  },
  {
    classId: "EC003254",
    classGroup: "EG015140",
    artClassDesc: "Insulation cover for air-/dirt separator",
    artClassVersion: 3,
    label: "EC003254: Insulation cover for air-/dirt separator"
  },
  {
    classId: "EC003255",
    classGroup: "EG015530",
    artClassDesc: "Label holder",
    artClassVersion: 3,
    label: "EC003255: Label holder"
  },
  {
    classId: "EC003257",
    classGroup: "EG012000",
    artClassDesc: "Dish brush",
    artClassVersion: 2,
    label: "EC003257: Dish brush"
  },
  {
    classId: "EC003261",
    classGroup: "EG015610",
    artClassDesc: "Sluice valve",
    artClassVersion: 2,
    label: "EC003261: Sluice valve"
  },
  {
    classId: "EC003262",
    classGroup: "EG015710",
    artClassDesc: "Casing convector radiator",
    artClassVersion: 2,
    label: "EC003262: Casing convector radiator"
  },
  {
    classId: "EC003263",
    classGroup: "EG015840",
    artClassDesc: "Smart thermostat",
    artClassVersion: 2,
    label: "EC003263: Smart thermostat"
  },
  {
    classId: "EC003264",
    classGroup: "EG020450",
    artClassDesc: "Telescopic tube synthetic pit",
    artClassVersion: 1,
    label: "EC003264: Telescopic tube synthetic pit"
  },
  {
    classId: "EC003265",
    classGroup: "EG020450",
    artClassDesc: "Plastic pit bottom",
    artClassVersion: 3,
    label: "EC003265: Plastic pit bottom"
  },
  {
    classId: "EC003266",
    classGroup: "EG020450",
    artClassDesc: "Plastic pit",
    artClassVersion: 3,
    label: "EC003266: Plastic pit"
  },
  {
    classId: "EC003267",
    classGroup: "EG020450",
    artClassDesc: "Plastic pit shaft",
    artClassVersion: 2,
    label: "EC003267: Plastic pit shaft"
  },
  {
    classId: "EC003268",
    classGroup: "EG015610",
    artClassDesc: "Underground service valve",
    artClassVersion: 3,
    label: "EC003268: Underground service valve"
  },
  {
    classId: "EC003269",
    classGroup: "EG014000",
    artClassDesc: "Pipe end plate",
    artClassVersion: 2,
    label: "EC003269: Pipe end plate"
  },
  {
    classId: "EC003270",
    classGroup: "EG015610",
    artClassDesc: "Six-way control valve with electric servomotor",
    artClassVersion: 3,
    label: "EC003270: Six-way control valve with electric servomotor"
  },
  {
    classId: "EC003271",
    classGroup: "EG015630",
    artClassDesc: "Fire damper actuator",
    artClassVersion: 2,
    label: "EC003271: Fire damper actuator"
  },
  {
    classId: "EC003272",
    classGroup: "EG000049",
    artClassDesc: "Wing screw",
    artClassVersion: 2,
    label: "EC003272: Wing screw"
  },
  {
    classId: "EC003274",
    classGroup: "EG015610",
    artClassDesc: "Electronic pressure-independent 2-way control valve with energy monitoring",
    artClassVersion: 3,
    label: "EC003274: Electronic pressure-independent 2-way control valve with energy monitoring"
  },
  {
    classId: "EC003278",
    classGroup: "EG000051",
    artClassDesc: "Nibbler (battery)",
    artClassVersion: 3,
    label: "EC003278: Nibbler (battery)"
  },
  {
    classId: "EC003279",
    classGroup: "EG000051",
    artClassDesc: "Universal scissors (battery)",
    artClassVersion: 2,
    label: "EC003279: Universal scissors (battery)"
  },
  {
    classId: "EC003281",
    classGroup: "EG000051",
    artClassDesc: "Precision drill/grinder (electric)",
    artClassVersion: 2,
    label: "EC003281: Precision drill/grinder (electric)"
  },
  {
    classId: "EC003283",
    classGroup: "EG000051",
    artClassDesc: "Rotary and demolition hammer (battery)",
    artClassVersion: 2,
    label: "EC003283: Rotary and demolition hammer (battery)"
  },
  {
    classId: "EC003285",
    classGroup: "EG000051",
    artClassDesc: "Threaded rod cutter (battery)",
    artClassVersion: 1,
    label: "EC003285: Threaded rod cutter (battery)"
  },
  {
    classId: "EC003287",
    classGroup: "EG000051",
    artClassDesc: "Groove cutter (battery)",
    artClassVersion: 3,
    label: "EC003287: Groove cutter (battery)"
  },
  {
    classId: "EC003288",
    classGroup: "EG000051",
    artClassDesc: "Sheet metal shears (battery)",
    artClassVersion: 3,
    label: "EC003288: Sheet metal shears (battery)"
  },
  {
    classId: "EC003289",
    classGroup: "EG000051",
    artClassDesc: "Blower vac (battery)",
    artClassVersion: 2,
    label: "EC003289: Blower vac (battery)"
  },
  {
    classId: "EC003290",
    classGroup: "EG000051",
    artClassDesc: "Air pump (battery)",
    artClassVersion: 1,
    label: "EC003290: Air pump (battery)"
  },
  {
    classId: "EC003292",
    classGroup: "EG000051",
    artClassDesc: "Needle scaler (battery)",
    artClassVersion: 1,
    label: "EC003292: Needle scaler (battery)"
  },
  {
    classId: "EC003298",
    classGroup: "EG000051",
    artClassDesc: "Angle wrench (battery)",
    artClassVersion: 2,
    label: "EC003298: Angle wrench (battery)"
  },
  {
    classId: "EC003299",
    classGroup: "EG020002",
    artClassDesc: "Accessories/spare parts for inspection camera",
    artClassVersion: 2,
    label: "EC003299: Accessories/spare parts for inspection camera"
  },
  {
    classId: "EC003302",
    classGroup: "EG000051",
    artClassDesc: "Heating device for work pieces (electrical)",
    artClassVersion: 1,
    label: "EC003302: Heating device for work pieces (electrical)"
  },
  {
    classId: "EC003303",
    classGroup: "EG000051",
    artClassDesc: "Brush grinder (electric)",
    artClassVersion: 2,
    label: "EC003303: Brush grinder (electric)"
  },
  {
    classId: "EC003304",
    classGroup: "EG000051",
    artClassDesc: "Diamond saw (electric)",
    artClassVersion: 1,
    label: "EC003304: Diamond saw (electric)"
  },
  {
    classId: "EC003305",
    classGroup: "EG000051",
    artClassDesc: "Dowel machine (electric)",
    artClassVersion: 1,
    label: "EC003305: Dowel machine (electric)"
  },
  {
    classId: "EC003307",
    classGroup: "EG000051",
    artClassDesc: "Fold seam locker (electric)",
    artClassVersion: 2,
    label: "EC003307: Fold seam locker (electric)"
  },
  {
    classId: "EC003308",
    classGroup: "EG020002",
    artClassDesc: "Accessory set for precision drill/grinder",
    artClassVersion: 2,
    label: "EC003308: Accessory set for precision drill/grinder"
  },
  {
    classId: "EC003309",
    classGroup: "EG000051",
    artClassDesc: "Precision drill/grinder set (electric)",
    artClassVersion: 2,
    label: "EC003309: Precision drill/grinder set (electric)"
  },
  {
    classId: "EC003311",
    classGroup: "EG000051",
    artClassDesc: "Flathead grinder (electric)",
    artClassVersion: 2,
    label: "EC003311: Flathead grinder (electric)"
  },
  {
    classId: "EC003312",
    classGroup: "EG000051",
    artClassDesc: "Jointing press (electric)",
    artClassVersion: 2,
    label: "EC003312: Jointing press (electric)"
  },
  {
    classId: "EC003315",
    classGroup: "EG000051",
    artClassDesc: "Threaded rod cutter (electric)",
    artClassVersion: 1,
    label: "EC003315: Threaded rod cutter (electric)"
  },
  {
    classId: "EC003317",
    classGroup: "EG000051",
    artClassDesc: "Groove cutter (electric)",
    artClassVersion: 2,
    label: "EC003317: Groove cutter (electric)"
  },
  {
    classId: "EC003318",
    classGroup: "EG000051",
    artClassDesc: "Fillet grinder (electric)",
    artClassVersion: 2,
    label: "EC003318: Fillet grinder (electric)"
  },
  {
    classId: "EC003321",
    classGroup: "EG000051",
    artClassDesc: "Chain mortiser (electric)",
    artClassVersion: 1,
    label: "EC003321: Chain mortiser (electric)"
  },
  {
    classId: "EC003322",
    classGroup: "EG000051",
    artClassDesc: "Putty router (electric)",
    artClassVersion: 2,
    label: "EC003322: Putty router (electric)"
  },
  {
    classId: "EC003324",
    classGroup: "EG000051",
    artClassDesc: "Varnish milling cutter (electric)",
    artClassVersion: 1,
    label: "EC003324: Varnish milling cutter (electric)"
  },
  {
    classId: "EC003325",
    classGroup: "EG000051",
    artClassDesc: "Linear grinder (electric)",
    artClassVersion: 1,
    label: "EC003325: Linear grinder (electric)"
  },
  {
    classId: "EC003326",
    classGroup: "EG000051",
    artClassDesc: "Magazine screwdriver (electric)",
    artClassVersion: 2,
    label: "EC003326: Magazine screwdriver (electric)"
  },
  {
    classId: "EC003327",
    classGroup: "EG000051",
    artClassDesc: "Multifunctional grinder (electric)",
    artClassVersion: 2,
    label: "EC003327: Multifunctional grinder (electric)"
  },
  {
    classId: "EC003328",
    classGroup: "EG000051",
    artClassDesc: "Needle scaler (electric)",
    artClassVersion: 2,
    label: "EC003328: Needle scaler (electric)"
  },
  {
    classId: "EC003330",
    classGroup: "EG000051",
    artClassDesc: "Wet grinder (electric)",
    artClassVersion: 2,
    label: "EC003330: Wet grinder (electric)"
  },
  {
    classId: "EC003332",
    classGroup: "EG000051",
    artClassDesc: "Polisher (electric)",
    artClassVersion: 2,
    label: "EC003332: Polisher (electric)"
  },
  {
    classId: "EC003334",
    classGroup: "EG000051",
    artClassDesc: "Pipe belt grinder (electric)",
    artClassVersion: 1,
    label: "EC003334: Pipe belt grinder (electric)"
  },
  {
    classId: "EC003335",
    classGroup: "EG000051",
    artClassDesc: "Renovation milling cutter (electric)",
    artClassVersion: 2,
    label: "EC003335: Renovation milling cutter (electric)"
  },
  {
    classId: "EC003337",
    classGroup: "EG000051",
    artClassDesc: "Scraper (electric)",
    artClassVersion: 2,
    label: "EC003337: Scraper (electric)"
  },
  {
    classId: "EC003347",
    classGroup: "EG000051",
    artClassDesc: "Pipe beveller (electric)",
    artClassVersion: 2,
    label: "EC003347: Pipe beveller (electric)"
  },
  {
    classId: "EC003348",
    classGroup: "EG000051",
    artClassDesc: "Rope saw (electric)",
    artClassVersion: 1,
    label: "EC003348: Rope saw (electric)"
  },
  {
    classId: "EC003349",
    classGroup: "EG000051",
    artClassDesc: "Spray gun (electric)",
    artClassVersion: 1,
    label: "EC003349: Spray gun (electric)"
  },
  {
    classId: "EC003351",
    classGroup: "EG000051",
    artClassDesc: "Wallpaper stripper (electric)",
    artClassVersion: 2,
    label: "EC003351: Wallpaper stripper (electric)"
  },
  {
    classId: "EC003352",
    classGroup: "EG000051",
    artClassDesc: "Carpet remover (electric)",
    artClassVersion: 1,
    label: "EC003352: Carpet remover (electric)"
  },
  {
    classId: "EC003354",
    classGroup: "EG000051",
    artClassDesc: "Stair stringer milling machine (electric)",
    artClassVersion: 1,
    label: "EC003354: Stair stringer milling machine (electric)"
  },
  {
    classId: "EC003362",
    classGroup: "EG000051",
    artClassDesc: "Precision drill/grinder (battery)",
    artClassVersion: 3,
    label: "EC003362: Precision drill/grinder (battery)"
  },
  {
    classId: "EC003363",
    classGroup: "EG000051",
    artClassDesc: "Precision drill/grinder set (battery)",
    artClassVersion: 2,
    label: "EC003363: Precision drill/grinder set (battery)"
  },
  {
    classId: "EC003367",
    classGroup: "EG020000",
    artClassDesc: "Spring scale",
    artClassVersion: 2,
    label: "EC003367: Spring scale"
  },
  {
    classId: "EC003368",
    classGroup: "EG015610",
    artClassDesc: "Penstock",
    artClassVersion: 1,
    label: "EC003368: Penstock"
  },
  {
    classId: "EC003369",
    classGroup: "EG020000",
    artClassDesc: "Pack hook",
    artClassVersion: 2,
    label: "EC003369: Pack hook"
  },
  {
    classId: "EC003372",
    classGroup: "EG015610",
    artClassDesc: "Flap valve",
    artClassVersion: 2,
    label: "EC003372: Flap valve"
  },
  {
    classId: "EC003373",
    classGroup: "EG020000",
    artClassDesc: "Strapping device",
    artClassVersion: 3,
    label: "EC003373: Strapping device"
  },
  {
    classId: "EC003374",
    classGroup: "EG020000",
    artClassDesc: "Window cleaner (battery)",
    artClassVersion: 2,
    label: "EC003374: Window cleaner (battery)"
  },
  {
    classId: "EC003375",
    classGroup: "EG020000",
    artClassDesc: "Ride-on vacuum sweeping machine",
    artClassVersion: 2,
    label: "EC003375: Ride-on vacuum sweeping machine"
  },
  {
    classId: "EC003378",
    classGroup: "EG020000",
    artClassDesc: "Weeder (gas powered)",
    artClassVersion: 1,
    label: "EC003378: Weeder (gas powered)"
  },
  {
    classId: "EC003379",
    classGroup: "EG020000",
    artClassDesc: "Nut Splitter",
    artClassVersion: 2,
    label: "EC003379: Nut Splitter"
  },
  {
    classId: "EC003380",
    classGroup: "EG020000",
    artClassDesc: "Concrete tamper (hand)",
    artClassVersion: 2,
    label: "EC003380: Concrete tamper (hand)"
  },
  {
    classId: "EC003382",
    classGroup: "EG020000",
    artClassDesc: "Loop tie twisting tool",
    artClassVersion: 2,
    label: "EC003382: Loop tie twisting tool"
  },
  {
    classId: "EC003384",
    classGroup: "EG020000",
    artClassDesc: "Post hole digger",
    artClassVersion: 1,
    label: "EC003384: Post hole digger"
  },
  {
    classId: "EC003385",
    classGroup: "EG020000",
    artClassDesc: "Hedge trimmer (hand)",
    artClassVersion: 1,
    label: "EC003385: Hedge trimmer (hand)"
  },
  {
    classId: "EC003386",
    classGroup: "EG020010",
    artClassDesc: "Rake",
    artClassVersion: 2,
    label: "EC003386: Rake"
  },
  {
    classId: "EC003387",
    classGroup: "EG020000",
    artClassDesc: "Sickle",
    artClassVersion: 2,
    label: "EC003387: Sickle"
  },
  {
    classId: "EC003388",
    classGroup: "EG020000",
    artClassDesc: "Carry handle",
    artClassVersion: 1,
    label: "EC003388: Carry handle"
  },
  {
    classId: "EC003389",
    classGroup: "EG020000",
    artClassDesc: "Hand saw set",
    artClassVersion: 1,
    label: "EC003389: Hand saw set"
  },
  {
    classId: "EC003391",
    classGroup: "EG015610",
    artClassDesc: "Water mist sprinkler",
    artClassVersion: 2,
    label: "EC003391: Water mist sprinkler"
  },
  {
    classId: "EC003393",
    classGroup: "EG020000",
    artClassDesc: "Laths lifter",
    artClassVersion: 1,
    label: "EC003393: Laths lifter"
  },
  {
    classId: "EC003398",
    classGroup: "EG020000",
    artClassDesc: "Laminate cutter",
    artClassVersion: 1,
    label: "EC003398: Laminate cutter"
  },
  {
    classId: "EC003401",
    classGroup: "EG020000",
    artClassDesc: "Wash basin wrench",
    artClassVersion: 2,
    label: "EC003401: Wash basin wrench"
  },
  {
    classId: "EC003403",
    classGroup: "EG020000",
    artClassDesc: "Scraper/trowel set",
    artClassVersion: 1,
    label: "EC003403: Scraper/trowel set"
  },
  {
    classId: "EC003405",
    classGroup: "EG020000",
    artClassDesc: "(De)magnetizing device",
    artClassVersion: 1,
    label: "EC003405: (De)magnetizing device"
  },
  {
    classId: "EC003406",
    classGroup: "EG020000",
    artClassDesc: "Bolt block",
    artClassVersion: 2,
    label: "EC003406: Bolt block"
  },
  {
    classId: "EC003407",
    classGroup: "EG020000",
    artClassDesc: "Accessories/spare parts for vice",
    artClassVersion: 2,
    label: "EC003407: Accessories/spare parts for vice"
  },
  {
    classId: "EC003409",
    classGroup: "EG020000",
    artClassDesc: "Brush set",
    artClassVersion: 1,
    label: "EC003409: Brush set"
  },
  {
    classId: "EC003412",
    classGroup: "EG019510",
    artClassDesc: "Tiling cord",
    artClassVersion: 3,
    label: "EC003412: Tiling cord"
  },
  {
    classId: "EC003413",
    classGroup: "EG020000",
    artClassDesc: "Sponge",
    artClassVersion: 3,
    label: "EC003413: Sponge"
  },
  {
    classId: "EC003414",
    classGroup: "EG020000",
    artClassDesc: "Plate lifting bracket",
    artClassVersion: 1,
    label: "EC003414: Plate lifting bracket"
  },
  {
    classId: "EC003415",
    classGroup: "EG020000",
    artClassDesc: "Tiler breaking pliers",
    artClassVersion: 1,
    label: "EC003415: Tiler breaking pliers"
  },
  {
    classId: "EC003419",
    classGroup: "EG020000",
    artClassDesc: "Paper pliers",
    artClassVersion: 1,
    label: "EC003419: Paper pliers"
  },
  {
    classId: "EC003420",
    classGroup: "EG020000",
    artClassDesc: "Notching pliers",
    artClassVersion: 2,
    label: "EC003420: Notching pliers"
  },
  {
    classId: "EC003421",
    classGroup: "EG020000",
    artClassDesc: "Steel wire mesh cutter",
    artClassVersion: 3,
    label: "EC003421: Steel wire mesh cutter"
  },
  {
    classId: "EC003423",
    classGroup: "EG020000",
    artClassDesc: "Strike plate edger",
    artClassVersion: 1,
    label: "EC003423: Strike plate edger"
  },
  {
    classId: "EC003424",
    classGroup: "EG020000",
    artClassDesc: "Material moisture meter",
    artClassVersion: 2,
    label: "EC003424: Material moisture meter"
  },
  {
    classId: "EC003425",
    classGroup: "EG020000",
    artClassDesc: "Telescopic measuring rod",
    artClassVersion: 1,
    label: "EC003425: Telescopic measuring rod"
  },
  {
    classId: "EC003427",
    classGroup: "EG020000",
    artClassDesc: "Flame spray powder",
    artClassVersion: 2,
    label: "EC003427: Flame spray powder"
  },
  {
    classId: "EC003432",
    classGroup: "EG020000",
    artClassDesc: "Soldering water bottle",
    artClassVersion: 1,
    label: "EC003432: Soldering water bottle"
  },
  {
    classId: "EC003433",
    classGroup: "EG020000",
    artClassDesc: "Soldering water brush",
    artClassVersion: 2,
    label: "EC003433: Soldering water brush"
  },
  {
    classId: "EC003435",
    classGroup: "EG020000",
    artClassDesc: "Forstner bit",
    artClassVersion: 1,
    label: "EC003435: Forstner bit"
  },
  {
    classId: "EC003436",
    classGroup: "EG020000",
    artClassDesc: "Ice drill",
    artClassVersion: 1,
    label: "EC003436: Ice drill"
  },
  {
    classId: "EC003437",
    classGroup: "EG020000",
    artClassDesc: "Drilling dust collector",
    artClassVersion: 1,
    label: "EC003437: Drilling dust collector"
  },
  {
    classId: "EC003441",
    classGroup: "EG020000",
    artClassDesc: "Chisel set for drill hammer",
    artClassVersion: 1,
    label: "EC003441: Chisel set for drill hammer"
  },
  {
    classId: "EC003442",
    classGroup: "EG020000",
    artClassDesc: "Mixing head",
    artClassVersion: 2,
    label: "EC003442: Mixing head"
  },
  {
    classId: "EC003446",
    classGroup: "EG020000",
    artClassDesc: "Saw blade assortment",
    artClassVersion: 2,
    label: "EC003446: Saw blade assortment"
  },
  {
    classId: "EC003447",
    classGroup: "EG020000",
    artClassDesc: "Chain for chainsaw",
    artClassVersion: 2,
    label: "EC003447: Chain for chainsaw"
  },
  {
    classId: "EC003449",
    classGroup: "EG020000",
    artClassDesc: "Hand grinder set",
    artClassVersion: 1,
    label: "EC003449: Hand grinder set"
  },
  {
    classId: "EC003452",
    classGroup: "EG020000",
    artClassDesc: "Cutting disc set",
    artClassVersion: 2,
    label: "EC003452: Cutting disc set"
  },
  {
    classId: "EC003453",
    classGroup: "EG020000",
    artClassDesc: "Counterbore",
    artClassVersion: 3,
    label: "EC003453: Counterbore"
  },
  {
    classId: "EC003455",
    classGroup: "EG020000",
    artClassDesc: "System accessories for colour spray system (battery/electric/compressed air)",
    artClassVersion: 3,
    label: "EC003455: System accessories for colour spray system (battery/electric/compressed air)"
  },
  {
    classId: "EC003456",
    classGroup: "EG020000",
    artClassDesc: "System accessories for milling (battery/electric)",
    artClassVersion: 3,
    label: "EC003456: System accessories for milling (battery/electric)"
  },
  {
    classId: "EC003457",
    classGroup: "EG020000",
    artClassDesc: "System accessories for planing (battery/electric)",
    artClassVersion: 2,
    label: "EC003457: System accessories for planing (battery/electric)"
  },
  {
    classId: "EC003458",
    classGroup: "EG020000",
    artClassDesc: "System accessories for nibbling/cutting (battery/electric/compressed air)",
    artClassVersion: 3,
    label: "EC003458: System accessories for nibbling/cutting (battery/electric/compressed air)"
  },
  {
    classId: "EC003459",
    classGroup: "EG020000",
    artClassDesc: "System accessories for oscillator",
    artClassVersion: 3,
    label: "EC003459: System accessories for oscillator"
  },
  {
    classId: "EC003460",
    classGroup: "EG020000",
    artClassDesc: "System accessories for sawing (battery/electric/compressed air)",
    artClassVersion: 3,
    label: "EC003460: System accessories for sawing (battery/electric/compressed air)"
  },
  {
    classId: "EC003461",
    classGroup: "EG020000",
    artClassDesc: "System accessories for scraping (battery/electric/compressed air)",
    artClassVersion: 3,
    label: "EC003461: System accessories for scraping (battery/electric/compressed air)"
  },
  {
    classId: "EC003462",
    classGroup: "EG020000",
    artClassDesc: "System accessories for grinding/polishing (battery/electric/compressed air)",
    artClassVersion: 3,
    label: "EC003462: System accessories for grinding/polishing (battery/electric/compressed air)"
  },
  {
    classId: "EC003463",
    classGroup: "EG020000",
    artClassDesc: "System accessories for wallpaper stripping (battery/electric)",
    artClassVersion: 2,
    label: "EC003463: System accessories for wallpaper stripping (battery/electric)"
  },
  {
    classId: "EC003465",
    classGroup: "EG020000",
    artClassDesc: "Drilling machine (compressed air)",
    artClassVersion: 3,
    label: "EC003465: Drilling machine (compressed air)"
  },
  {
    classId: "EC003466",
    classGroup: "EG020000",
    artClassDesc: "Hammer (compressed air)",
    artClassVersion: 2,
    label: "EC003466: Hammer (compressed air)"
  },
  {
    classId: "EC003467",
    classGroup: "EG020000",
    artClassDesc: "Needle scaler (compressed air)",
    artClassVersion: 2,
    label: "EC003467: Needle scaler (compressed air)"
  },
  {
    classId: "EC003468",
    classGroup: "EG020000",
    artClassDesc: "Oscillator (compressed air)",
    artClassVersion: 3,
    label: "EC003468: Oscillator (compressed air)"
  },
  {
    classId: "EC003469",
    classGroup: "EG020000",
    artClassDesc: "Jig saw (compressed air)",
    artClassVersion: 2,
    label: "EC003469: Jig saw (compressed air)"
  },
  {
    classId: "EC003470",
    classGroup: "EG020000",
    artClassDesc: "Sheet nibbler (compressed air)",
    artClassVersion: 3,
    label: "EC003470: Sheet nibbler (compressed air)"
  },
  {
    classId: "EC003471",
    classGroup: "EG020000",
    artClassDesc: "Belt sander (compressed air)",
    artClassVersion: 3,
    label: "EC003471: Belt sander (compressed air)"
  },
  {
    classId: "EC003472",
    classGroup: "EG020000",
    artClassDesc: "Random orbital disc grinder (compressed air)",
    artClassVersion: 2,
    label: "EC003472: Random orbital disc grinder (compressed air)"
  },
  {
    classId: "EC003473",
    classGroup: "EG020000",
    artClassDesc: "Belt file (compressed air)",
    artClassVersion: 2,
    label: "EC003473: Belt file (compressed air)"
  },
  {
    classId: "EC003474",
    classGroup: "EG020000",
    artClassDesc: "Oscillating grinder (compressed air)",
    artClassVersion: 2,
    label: "EC003474: Oscillating grinder (compressed air)"
  },
  {
    classId: "EC003475",
    classGroup: "EG020000",
    artClassDesc: "Straight grinder (compressed air)",
    artClassVersion: 2,
    label: "EC003475: Straight grinder (compressed air)"
  },
  {
    classId: "EC003476",
    classGroup: "EG020000",
    artClassDesc: "Angle grinder (compressed air)",
    artClassVersion: 2,
    label: "EC003476: Angle grinder (compressed air)"
  },
  {
    classId: "EC003477",
    classGroup: "EG020000",
    artClassDesc: "Angle die grinder (compressed air)",
    artClassVersion: 2,
    label: "EC003477: Angle die grinder (compressed air)"
  },
  {
    classId: "EC003479",
    classGroup: "EG020000",
    artClassDesc: "Impact screwdriver (compressed air)",
    artClassVersion: 3,
    label: "EC003479: Impact screwdriver (compressed air)"
  },
  {
    classId: "EC003480",
    classGroup: "EG020000",
    artClassDesc: "Tool holder assortment",
    artClassVersion: 2,
    label: "EC003480: Tool holder assortment"
  },
  {
    classId: "EC003481",
    classGroup: "EG017110",
    artClassDesc: "Rainwater system",
    artClassVersion: 2,
    label: "EC003481: Rainwater system"
  },
  {
    classId: "EC003482",
    classGroup: "EG015130",
    artClassDesc: "Beehive grate",
    artClassVersion: 3,
    label: "EC003482: Beehive grate"
  },
  {
    classId: "EC003484",
    classGroup: "EG015120",
    artClassDesc: "Sewage tank",
    artClassVersion: 2,
    label: "EC003484: Sewage tank"
  },
  {
    classId: "EC003485",
    classGroup: "EG015140",
    artClassDesc: "Phosphorus trap/filter",
    artClassVersion: 2,
    label: "EC003485: Phosphorus trap/filter"
  },
  {
    classId: "EC003486",
    classGroup: "EG015140",
    artClassDesc: "Sequencing batch reactor",
    artClassVersion: 2,
    label: "EC003486: Sequencing batch reactor"
  },
  {
    classId: "EC003487",
    classGroup: "EG015140",
    artClassDesc: "UV box",
    artClassVersion: 2,
    label: "EC003487: UV box"
  },
  {
    classId: "EC003488",
    classGroup: "EG015140",
    artClassDesc: "Wastewater filter",
    artClassVersion: 2,
    label: "EC003488: Wastewater filter"
  },
  {
    classId: "EC003490",
    classGroup: "EG015130",
    artClassDesc: "Base manhole chamber",
    artClassVersion: 3,
    label: "EC003490: Base manhole chamber"
  },
  {
    classId: "EC003492",
    classGroup: "EG015130",
    artClassDesc: "Manhole chamber riser/reduction",
    artClassVersion: 3,
    label: "EC003492: Manhole chamber riser/reduction"
  },
  {
    classId: "EC003496",
    classGroup: "EG020450",
    artClassDesc: "Accessories/spare parts for infiltration unit",
    artClassVersion: 3,
    label: "EC003496: Accessories/spare parts for infiltration unit"
  },
  {
    classId: "EC003497",
    classGroup: "EG020450",
    artClassDesc: "Accessories/spare parts for infiltration gully",
    artClassVersion: 3,
    label: "EC003497: Accessories/spare parts for infiltration gully"
  },
  {
    classId: "EC003498",
    classGroup: "EG018110",
    artClassDesc: "PCM element",
    artClassVersion: 2,
    label: "EC003498: PCM element"
  },
  {
    classId: "EC003499",
    classGroup: "EG000050",
    artClassDesc: "Tile cutter (hand model)",
    artClassVersion: 2,
    label: "EC003499: Tile cutter (hand model)"
  },
  {
    classId: "EC003501",
    classGroup: "EG020200",
    artClassDesc: "Rebar splicing system",
    artClassVersion: 2,
    label: "EC003501: Rebar splicing system"
  },
  {
    classId: "EC003502",
    classGroup: "EG000041",
    artClassDesc: "Accessories/spare parts for navigation systems/tablets/mobile phones",
    artClassVersion: 3,
    label: "EC003502: Accessories/spare parts for navigation systems/tablets/mobile phones"
  },
  {
    classId: "EC003504",
    classGroup: "EG000041",
    artClassDesc: "Projection screen",
    artClassVersion: 1,
    label: "EC003504: Projection screen"
  },
  {
    classId: "EC003505",
    classGroup: "EG000041",
    artClassDesc: "Accessories/spare parts for loudspeaker",
    artClassVersion: 2,
    label: "EC003505: Accessories/spare parts for loudspeaker"
  },
  {
    classId: "EC003507",
    classGroup: "EG000020",
    artClassDesc: "Medium/high voltage fuse",
    artClassVersion: 3,
    label: "EC003507: Medium/high voltage fuse"
  },
  {
    classId: "EC003508",
    classGroup: "EG020001",
    artClassDesc: "Utility pole",
    artClassVersion: 3,
    label: "EC003508: Utility pole"
  },
  {
    classId: "EC003509",
    classGroup: "EG020001",
    artClassDesc: "High voltage disconnector",
    artClassVersion: 1,
    label: "EC003509: High voltage disconnector"
  },
  {
    classId: "EC003510",
    classGroup: "EG000004",
    artClassDesc: "Cable suspension hook",
    artClassVersion: 3,
    label: "EC003510: Cable suspension hook"
  },
  {
    classId: "EC003511",
    classGroup: "EG020001",
    artClassDesc: "Accessories/spare parts for high voltage disconnector",
    artClassVersion: 2,
    label: "EC003511: Accessories/spare parts for high voltage disconnector"
  },
  {
    classId: "EC003512",
    classGroup: "EG020001",
    artClassDesc: "Accessories/spare parts for utility pole",
    artClassVersion: 3,
    label: "EC003512: Accessories/spare parts for utility pole"
  },
  {
    classId: "EC003513",
    classGroup: "EG020001",
    artClassDesc: "Accessories/spare parts for stay wire",
    artClassVersion: 3,
    label: "EC003513: Accessories/spare parts for stay wire"
  },
  {
    classId: "EC003514",
    classGroup: "EG020001",
    artClassDesc: "High voltage insulator",
    artClassVersion: 1,
    label: "EC003514: High voltage insulator"
  },
  {
    classId: "EC003515",
    classGroup: "EG020001",
    artClassDesc: "Cross arm for utility pole",
    artClassVersion: 3,
    label: "EC003515: Cross arm for utility pole"
  },
  {
    classId: "EC003516",
    classGroup: "EG020001",
    artClassDesc: "Accessories/spare parts for cross arm for utility pole",
    artClassVersion: 3,
    label: "EC003516: Accessories/spare parts for cross arm for utility pole"
  },
  {
    classId: "EC003517",
    classGroup: "EG000023",
    artClassDesc: "Cable distribution cabinet",
    artClassVersion: 1,
    label: "EC003517: Cable distribution cabinet"
  },
  {
    classId: "EC003520",
    classGroup: "EG020001",
    artClassDesc: "Cable termination for medium and high voltage cables",
    artClassVersion: 3,
    label: "EC003520: Cable termination for medium and high voltage cables"
  },
  {
    classId: "EC003521",
    classGroup: "EG020001",
    artClassDesc: "Plug termination for medium and high voltage cables",
    artClassVersion: 2,
    label: "EC003521: Plug termination for medium and high voltage cables"
  },
  {
    classId: "EC003522",
    classGroup: "EG020001",
    artClassDesc: "Distribution transformer",
    artClassVersion: 2,
    label: "EC003522: Distribution transformer"
  },
  {
    classId: "EC003524",
    classGroup: "EG000023",
    artClassDesc: "Marking pole",
    artClassVersion: 3,
    label: "EC003524: Marking pole"
  },
  {
    classId: "EC003527",
    classGroup: "EG017110",
    artClassDesc: "Dry toilet",
    artClassVersion: 2,
    label: "EC003527: Dry toilet"
  },
  {
    classId: "EC003528",
    classGroup: "EG017210",
    artClassDesc: "Bath rail",
    artClassVersion: 3,
    label: "EC003528: Bath rail"
  },
  {
    classId: "EC003529",
    classGroup: "EG000036",
    artClassDesc: "Discussion unit",
    artClassVersion: 2,
    label: "EC003529: Discussion unit"
  },
  {
    classId: "EC003530",
    classGroup: "EG000044",
    artClassDesc: "Thermal camera",
    artClassVersion: 3,
    label: "EC003530: Thermal camera"
  },
  {
    classId: "EC003531",
    classGroup: "EG015710",
    artClassDesc: "Convector pit complete without grid",
    artClassVersion: 3,
    label: "EC003531: Convector pit complete without grid"
  },
  {
    classId: "EC003532",
    classGroup: "EG020001",
    artClassDesc: "Accessories/spare parts for distribution transformer",
    artClassVersion: 2,
    label: "EC003532: Accessories/spare parts for distribution transformer"
  },
  {
    classId: "EC003534",
    classGroup: "EG013000",
    artClassDesc: "Exterior door (complete with frame)",
    artClassVersion: 3,
    label: "EC003534: Exterior door (complete with frame)"
  },
  {
    classId: "EC003535",
    classGroup: "EG013000",
    artClassDesc: "Interior door (complete with frame)",
    artClassVersion: 2,
    label: "EC003535: Interior door (complete with frame)"
  },
  {
    classId: "EC003536",
    classGroup: "EG013000",
    artClassDesc: "Window (complete with frame)",
    artClassVersion: 3,
    label: "EC003536: Window (complete with frame)"
  },
  {
    classId: "EC003537",
    classGroup: "EG000017",
    artClassDesc: "Wireless control station",
    artClassVersion: 1,
    label: "EC003537: Wireless control station"
  },
  {
    classId: "EC003538",
    classGroup: "EG000020",
    artClassDesc: "Device circuit breaker",
    artClassVersion: 1,
    label: "EC003538: Device circuit breaker"
  },
  {
    classId: "EC003539",
    classGroup: "EG015710",
    artClassDesc: "Column radiator",
    artClassVersion: 3,
    label: "EC003539: Column radiator"
  },
  {
    classId: "EC003540",
    classGroup: "EG015410",
    artClassDesc: "Frame and door for group distributor housing",
    artClassVersion: 2,
    label: "EC003540: Frame and door for group distributor housing"
  },
  {
    classId: "EC003541",
    classGroup: "EG015410",
    artClassDesc: "Frame for group distributor housing",
    artClassVersion: 2,
    label: "EC003541: Frame for group distributor housing"
  },
  {
    classId: "EC003542",
    classGroup: "EG015410",
    artClassDesc: "Door for group distributor housing",
    artClassVersion: 2,
    label: "EC003542: Door for group distributor housing"
  },
  {
    classId: "EC003543",
    classGroup: "EG020700",
    artClassDesc: "Escalator",
    artClassVersion: 3,
    label: "EC003543: Escalator"
  },
  {
    classId: "EC003544",
    classGroup: "EG020700",
    artClassDesc: "Elevator",
    artClassVersion: 3,
    label: "EC003544: Elevator"
  },
  {
    classId: "EC003545",
    classGroup: "EG000013",
    artClassDesc: "Plug/coupler without protective contact",
    artClassVersion: 3,
    label: "EC003545: Plug/coupler without protective contact"
  },
  {
    classId: "EC003546",
    classGroup: "EG000013",
    artClassDesc: "Appliance connector",
    artClassVersion: 2,
    label: "EC003546: Appliance connector"
  },
  {
    classId: "EC003548",
    classGroup: "EG012000",
    artClassDesc: "Accessories/spare parts for first aid",
    artClassVersion: 3,
    label: "EC003548: Accessories/spare parts for first aid"
  },
  {
    classId: "EC003549",
    classGroup: "EG012000",
    artClassDesc: "Packaging material",
    artClassVersion: 2,
    label: "EC003549: Packaging material"
  },
  {
    classId: "EC003550",
    classGroup: "EG012000",
    artClassDesc: "Spill containment for oil/chemicals",
    artClassVersion: 2,
    label: "EC003550: Spill containment for oil/chemicals"
  },
  {
    classId: "EC003551",
    classGroup: "EG012000",
    artClassDesc: "Water barrier",
    artClassVersion: 2,
    label: "EC003551: Water barrier"
  },
  {
    classId: "EC003552",
    classGroup: "EG000037",
    artClassDesc: "PoE injector/splitter",
    artClassVersion: 2,
    label: "EC003552: PoE injector/splitter"
  },
  {
    classId: "EC003553",
    classGroup: "EG000026",
    artClassDesc: "Robot controller",
    artClassVersion: 1,
    label: "EC003553: Robot controller"
  },
  {
    classId: "EC003554",
    classGroup: "EG000026",
    artClassDesc: "Accessories/spare parts for robotic arm",
    artClassVersion: 2,
    label: "EC003554: Accessories/spare parts for robotic arm"
  },
  {
    classId: "EC003555",
    classGroup: "EG000010",
    artClassDesc: "Exothermic welding mould",
    artClassVersion: 2,
    label: "EC003555: Exothermic welding mould"
  },
  {
    classId: "EC003556",
    classGroup: "EG000058",
    artClassDesc: "Housing for circular connectors",
    artClassVersion: 1,
    label: "EC003556: Housing for circular connectors"
  },
  {
    classId: "EC003557",
    classGroup: "EG000058",
    artClassDesc: "Contact insert for circular connectors",
    artClassVersion: 1,
    label: "EC003557: Contact insert for circular connectors"
  },
  {
    classId: "EC003558",
    classGroup: "EG000026",
    artClassDesc: "Passive sensor-actuator interface (without cable)",
    artClassVersion: 1,
    label: "EC003558: Passive sensor-actuator interface (without cable)"
  },
  {
    classId: "EC003559",
    classGroup: "EG015710",
    artClassDesc: "Dynamic power booster",
    artClassVersion: 2,
    label: "EC003559: Dynamic power booster"
  },
  {
    classId: "EC003560",
    classGroup: "EG000010",
    artClassDesc: "Exothermic welding powder",
    artClassVersion: 1,
    label: "EC003560: Exothermic welding powder"
  },
  {
    classId: "EC003561",
    classGroup: "EG000010",
    artClassDesc: "Accessories/spare parts for exothermic welding",
    artClassVersion: 2,
    label: "EC003561: Accessories/spare parts for exothermic welding"
  },
  {
    classId: "EC003562",
    classGroup: "EG000036",
    artClassDesc: "Controller for public address system",
    artClassVersion: 1,
    label: "EC003562: Controller for public address system"
  },
  {
    classId: "EC003563",
    classGroup: "EG000059",
    artClassDesc: "Earthing cartridge",
    artClassVersion: 1,
    label: "EC003563: Earthing cartridge"
  },
  {
    classId: "EC003564",
    classGroup: "EG000059",
    artClassDesc: "Short-circuiting bar",
    artClassVersion: 1,
    label: "EC003564: Short-circuiting bar"
  },
  {
    classId: "EC003565",
    classGroup: "EG000036",
    artClassDesc: "Call station for public address system",
    artClassVersion: 1,
    label: "EC003565: Call station for public address system"
  },
  {
    classId: "EC003566",
    classGroup: "EG000036",
    artClassDesc: "Amplifier for public address system",
    artClassVersion: 1,
    label: "EC003566: Amplifier for public address system"
  },
  {
    classId: "EC003567",
    classGroup: "EG000043",
    artClassDesc: "Accessories/spare parts for heating mat",
    artClassVersion: 2,
    label: "EC003567: Accessories/spare parts for heating mat"
  },
  {
    classId: "EC003568",
    classGroup: "EG000058",
    artClassDesc: "Circular connector, mounting (PCB)",
    artClassVersion: 1,
    label: "EC003568: Circular connector, mounting (PCB)"
  },
  {
    classId: "EC003569",
    classGroup: "EG000058",
    artClassDesc: "Circular connector, mounting (without cable)",
    artClassVersion: 1,
    label: "EC003569: Circular connector, mounting (without cable)"
  },
  {
    classId: "EC003570",
    classGroup: "EG000058",
    artClassDesc: "Circular connector, mounting (with cable)",
    artClassVersion: 1,
    label: "EC003570: Circular connector, mounting (with cable)"
  },
  {
    classId: "EC003571",
    classGroup: "EG000040",
    artClassDesc: "Automatic tea maker",
    artClassVersion: 2,
    label: "EC003571: Automatic tea maker"
  },
  {
    classId: "EC003572",
    classGroup: "EG000040",
    artClassDesc: "Ironing system",
    artClassVersion: 2,
    label: "EC003572: Ironing system"
  },
  {
    classId: "EC003573",
    classGroup: "EG000046",
    artClassDesc: "Skirt",
    artClassVersion: 2,
    label: "EC003573: Skirt"
  },
  {
    classId: "EC003575",
    classGroup: "EG000034",
    artClassDesc: "Tollgate",
    artClassVersion: 2,
    label: "EC003575: Tollgate"
  },
  {
    classId: "EC003576",
    classGroup: "EG000046",
    artClassDesc: "Dress",
    artClassVersion: 2,
    label: "EC003576: Dress"
  },
  {
    classId: "EC003577",
    classGroup: "EG000046",
    artClassDesc: "Headgear",
    artClassVersion: 2,
    label: "EC003577: Headgear"
  },
  {
    classId: "EC003578",
    classGroup: "EG000046",
    artClassDesc: "Back protection",
    artClassVersion: 2,
    label: "EC003578: Back protection"
  },
  {
    classId: "EC003579",
    classGroup: "EG000046",
    artClassDesc: "Safety mirror",
    artClassVersion: 2,
    label: "EC003579: Safety mirror"
  },
  {
    classId: "EC003580",
    classGroup: "EG000046",
    artClassDesc: "Underwear",
    artClassVersion: 2,
    label: "EC003580: Underwear"
  },
  {
    classId: "EC003581",
    classGroup: "EG000046",
    artClassDesc: "Self Contained Breathing Apparatus (SCBA)",
    artClassVersion: 2,
    label: "EC003581: Self Contained Breathing Apparatus (SCBA)"
  },
  {
    classId: "EC003582",
    classGroup: "EG000046",
    artClassDesc: "Garment set",
    artClassVersion: 2,
    label: "EC003582: Garment set"
  },
  {
    classId: "EC003583",
    classGroup: "EG000034",
    artClassDesc: "Accessories/spare parts for electric motor",
    artClassVersion: 2,
    label: "EC003583: Accessories/spare parts for electric motor"
  },
  {
    classId: "EC003584",
    classGroup: "EG000046",
    artClassDesc: "Personal care products",
    artClassVersion: 3,
    label: "EC003584: Personal care products"
  },
  {
    classId: "EC003585",
    classGroup: "EG012000",
    artClassDesc: "Beverage",
    artClassVersion: 2,
    label: "EC003585: Beverage"
  },
  {
    classId: "EC003586",
    classGroup: "EG000046",
    artClassDesc: "Powered Air Purifying Respirator (PAPR)",
    artClassVersion: 2,
    label: "EC003586: Powered Air Purifying Respirator (PAPR)"
  },
  {
    classId: "EC003587",
    classGroup: "EG000046",
    artClassDesc: "Paper towel",
    artClassVersion: 2,
    label: "EC003587: Paper towel"
  },
  {
    classId: "EC003588",
    classGroup: "EG000046",
    artClassDesc: "Traffic management/road signage",
    artClassVersion: 3,
    label: "EC003588: Traffic management/road signage"
  },
  {
    classId: "EC003589",
    classGroup: "EG000006",
    artClassDesc: "Cable protection mat",
    artClassVersion: 1,
    label: "EC003589: Cable protection mat"
  },
  {
    classId: "EC003590",
    classGroup: "EG000050",
    artClassDesc: "Micrometer",
    artClassVersion: 2,
    label: "EC003590: Micrometer"
  },
  {
    classId: "EC003591",
    classGroup: "EG000050",
    artClassDesc: "Dial gauge",
    artClassVersion: 2,
    label: "EC003591: Dial gauge"
  },
  {
    classId: "EC003592",
    classGroup: "EG000050",
    artClassDesc: "Temperature marker",
    artClassVersion: 2,
    label: "EC003592: Temperature marker"
  },
  {
    classId: "EC003593",
    classGroup: "EG000050",
    artClassDesc: "Pitchfork",
    artClassVersion: 2,
    label: "EC003593: Pitchfork"
  },
  {
    classId: "EC003594",
    classGroup: "EG000051",
    artClassDesc: "Roll groover",
    artClassVersion: 2,
    label: "EC003594: Roll groover"
  },
  {
    classId: "EC003595",
    classGroup: "EG000044",
    artClassDesc: "Material testing device",
    artClassVersion: 1,
    label: "EC003595: Material testing device"
  },
  {
    classId: "EC003596",
    classGroup: "EG000044",
    artClassDesc: "Industry-related tester/meter",
    artClassVersion: 2,
    label: "EC003596: Industry-related tester/meter"
  },
  {
    classId: "EC003597",
    classGroup: "EG000041",
    artClassDesc: "Digital photo frame",
    artClassVersion: 1,
    label: "EC003597: Digital photo frame"
  },
  {
    classId: "EC003598",
    classGroup: "EG000041",
    artClassDesc: "Accessories/spare parts for consumer electronics",
    artClassVersion: 3,
    label: "EC003598: Accessories/spare parts for consumer electronics"
  },
  {
    classId: "EC003599",
    classGroup: "EG000054",
    artClassDesc: "Accessories/spare parts for camera (surveillance system)",
    artClassVersion: 3,
    label: "EC003599: Accessories/spare parts for camera (surveillance system)"
  },
  {
    classId: "EC003600",
    classGroup: "EG000036",
    artClassDesc: "Application software for conference system",
    artClassVersion: 2,
    label: "EC003600: Application software for conference system"
  },
  {
    classId: "EC003601",
    classGroup: "EG000036",
    artClassDesc: "Interpreter desk",
    artClassVersion: 1,
    label: "EC003601: Interpreter desk"
  },
  {
    classId: "EC003602",
    classGroup: "EG000036",
    artClassDesc: "Controller for conference system",
    artClassVersion: 1,
    label: "EC003602: Controller for conference system"
  },
  {
    classId: "EC003603",
    classGroup: "EG000036",
    artClassDesc: "Intercom",
    artClassVersion: 2,
    label: "EC003603: Intercom"
  },
  {
    classId: "EC003604",
    classGroup: "EG000036",
    artClassDesc: "Accessories/spare parts for conference system",
    artClassVersion: 2,
    label: "EC003604: Accessories/spare parts for conference system"
  },
  {
    classId: "EC003605",
    classGroup: "EG000006",
    artClassDesc: "Wall duct system, wall integrated",
    artClassVersion: 3,
    label: "EC003605: Wall duct system, wall integrated"
  },
  {
    classId: "EC003607",
    classGroup: "EG015410",
    artClassDesc: "Group distributor housing watertight",
    artClassVersion: 3,
    label: "EC003607: Group distributor housing watertight"
  },
  {
    classId: "EC003608",
    classGroup: "EG000009",
    artClassDesc: "Cable gland shroud",
    artClassVersion: 1,
    label: "EC003608: Cable gland shroud"
  },
  {
    classId: "EC003609",
    classGroup: "EG000060",
    artClassDesc: "Non-slip mat",
    artClassVersion: 1,
    label: "EC003609: Non-slip mat"
  },
  {
    classId: "EC003611",
    classGroup: "EG000020",
    artClassDesc: "Expulsion fuse-link, medium voltage",
    artClassVersion: 2,
    label: "EC003611: Expulsion fuse-link, medium voltage"
  },
  {
    classId: "EC003612",
    classGroup: "EG000020",
    artClassDesc: "Automatic sectionalising link (ASL)",
    artClassVersion: 1,
    label: "EC003612: Automatic sectionalising link (ASL)"
  },
  {
    classId: "EC003613",
    classGroup: "EG000050",
    artClassDesc: "Nail gun (powder actuated)",
    artClassVersion: 3,
    label: "EC003613: Nail gun (powder actuated)"
  },
  {
    classId: "EC003614",
    classGroup: "EG000036",
    artClassDesc: "Audio interface for conference/public address system",
    artClassVersion: 1,
    label: "EC003614: Audio interface for conference/public address system"
  },
  {
    classId: "EC003615",
    classGroup: "EG000049",
    artClassDesc: "Threaded nail",
    artClassVersion: 2,
    label: "EC003615: Threaded nail"
  },
  {
    classId: "EC003616",
    classGroup: "EG000049",
    artClassDesc: "Assortment box empty",
    artClassVersion: 2,
    label: "EC003616: Assortment box empty"
  },
  {
    classId: "EC003617",
    classGroup: "EG000036",
    artClassDesc: "Router for public address system",
    artClassVersion: 1,
    label: "EC003617: Router for public address system"
  },
  {
    classId: "EC003618",
    classGroup: "EG000002",
    artClassDesc: "Form part for installation tubes",
    artClassVersion: 2,
    label: "EC003618: Form part for installation tubes"
  },
  {
    classId: "EC003619",
    classGroup: "EG000026",
    artClassDesc: "Sensor for liquid analysis",
    artClassVersion: 2,
    label: "EC003619: Sensor for liquid analysis"
  },
  {
    classId: "EC003620",
    classGroup: "EG000050",
    artClassDesc: "Busbar tool",
    artClassVersion: 3,
    label: "EC003620: Busbar tool"
  },
  {
    classId: "EC003621",
    classGroup: "EG000021",
    artClassDesc: "Voltage controlled short-circuiting device",
    artClassVersion: 1,
    label: "EC003621: Voltage controlled short-circuiting device"
  },
  {
    classId: "EC003623",
    classGroup: "EG000026",
    artClassDesc: "Valve connector (field assembly)",
    artClassVersion: 1,
    label: "EC003623: Valve connector (field assembly)"
  },
  {
    classId: "EC003624",
    classGroup: "EG000043",
    artClassDesc: "Towel drying radiator (electric)",
    artClassVersion: 3,
    label: "EC003624: Towel drying radiator (electric)"
  },
  {
    classId: "EC003625",
    classGroup: "EG000037",
    artClassDesc: "Firewall appliance",
    artClassVersion: 1,
    label: "EC003625: Firewall appliance"
  },
  {
    classId: "EC003626",
    classGroup: "EG000037",
    artClassDesc: "Accessories/spare parts for firewall appliances",
    artClassVersion: 2,
    label: "EC003626: Accessories/spare parts for firewall appliances"
  },
  {
    classId: "EC003627",
    classGroup: "EG000037",
    artClassDesc: "License for data and telecommunication",
    artClassVersion: 1,
    label: "EC003627: License for data and telecommunication"
  },
  {
    classId: "EC003628",
    classGroup: "EG000037",
    artClassDesc: "Transceiver (fibre optic/copper)",
    artClassVersion: 1,
    label: "EC003628: Transceiver (fibre optic/copper)"
  },
  {
    classId: "EC003629",
    classGroup: "EG000037",
    artClassDesc: "WLAN controller",
    artClassVersion: 2,
    label: "EC003629: WLAN controller"
  },
  {
    classId: "EC003631",
    classGroup: "EG000036",
    artClassDesc: "Video conference system",
    artClassVersion: 2,
    label: "EC003631: Video conference system"
  },
  {
    classId: "EC003635",
    classGroup: "EG000044",
    artClassDesc: "Machine vibration meter",
    artClassVersion: 1,
    label: "EC003635: Machine vibration meter"
  },
  {
    classId: "EC003636",
    classGroup: "EG015130",
    artClassDesc: "Accessories/spare parts for floor drain",
    artClassVersion: 2,
    label: "EC003636: Accessories/spare parts for floor drain"
  },
  {
    classId: "EC003637",
    classGroup: "EG020003",
    artClassDesc: "Steel rod (rectangular/flat)",
    artClassVersion: 2,
    label: "EC003637: Steel rod (rectangular/flat)"
  },
  {
    classId: "EC003638",
    classGroup: "EG000049",
    artClassDesc: "Horizontal support cable system",
    artClassVersion: 2,
    label: "EC003638: Horizontal support cable system"
  },
  {
    classId: "EC003640",
    classGroup: "EG000030",
    artClassDesc: "Lamp shade",
    artClassVersion: 1,
    label: "EC003640: Lamp shade"
  },
  {
    classId: "EC003641",
    classGroup: "EG000043",
    artClassDesc: "Radiant panel (electric)",
    artClassVersion: 2,
    label: "EC003641: Radiant panel (electric)"
  },
  {
    classId: "EC003642",
    classGroup: "EG000046",
    artClassDesc: "T-Shirt",
    artClassVersion: 1,
    label: "EC003642: T-Shirt"
  },
  {
    classId: "EC003643",
    classGroup: "EG000046",
    artClassDesc: "Polo shirt",
    artClassVersion: 2,
    label: "EC003643: Polo shirt"
  },
  {
    classId: "EC003644",
    classGroup: "EG015710",
    artClassDesc: "Front cover radiator",
    artClassVersion: 1,
    label: "EC003644: Front cover radiator"
  },
  {
    classId: "EC003645",
    classGroup: "EG020000",
    artClassDesc: "Counterbore set",
    artClassVersion: 1,
    label: "EC003645: Counterbore set"
  },
  {
    classId: "EC003647",
    classGroup: "EG000061",
    artClassDesc: "Welding curtain",
    artClassVersion: 1,
    label: "EC003647: Welding curtain"
  },
  {
    classId: "EC003648",
    classGroup: "EG020003",
    artClassDesc: "Steel rod (round)",
    artClassVersion: 2,
    label: "EC003648: Steel rod (round)"
  },
  {
    classId: "EC003649",
    classGroup: "EG020003",
    artClassDesc: "Round profile pipe, steel",
    artClassVersion: 2,
    label: "EC003649: Round profile pipe, steel"
  },
  {
    classId: "EC003650",
    classGroup: "EG020003",
    artClassDesc: "Steel strip",
    artClassVersion: 2,
    label: "EC003650: Steel strip"
  },
  {
    classId: "EC003652",
    classGroup: "EG000055",
    artClassDesc: "Fastener for photovoltaics inverter",
    artClassVersion: 1,
    label: "EC003652: Fastener for photovoltaics inverter"
  },
  {
    classId: "EC003653",
    classGroup: "EG000055",
    artClassDesc: "Photovoltaics installation tool",
    artClassVersion: 1,
    label: "EC003653: Photovoltaics installation tool"
  },
  {
    classId: "EC003655",
    classGroup: "EG000055",
    artClassDesc: "Accessories/spare parts for photovoltaics ballast",
    artClassVersion: 1,
    label: "EC003655: Accessories/spare parts for photovoltaics ballast"
  },
  {
    classId: "EC003656",
    classGroup: "EG017210",
    artClassDesc: "Folding seat",
    artClassVersion: 1,
    label: "EC003656: Folding seat"
  },
  {
    classId: "EC003657",
    classGroup: "EG000053",
    artClassDesc: "Battery isolator",
    artClassVersion: 1,
    label: "EC003657: Battery isolator"
  },
  {
    classId: "EC003658",
    classGroup: "EG017210",
    artClassDesc: "Accessories/spare parts for toilet seat",
    artClassVersion: 1,
    label: "EC003658: Accessories/spare parts for toilet seat"
  },
  {
    classId: "EC003660",
    classGroup: "EG000060",
    artClassDesc: "Expansion joint profile",
    artClassVersion: 1,
    label: "EC003660: Expansion joint profile"
  },
  {
    classId: "EC003661",
    classGroup: "EG020004",
    artClassDesc: "Decorative sleeve for drill-in hinges",
    artClassVersion: 2,
    label: "EC003661: Decorative sleeve for drill-in hinges"
  },
  {
    classId: "EC003662",
    classGroup: "EG020004",
    artClassDesc: "Release button for doors",
    artClassVersion: 2,
    label: "EC003662: Release button for doors"
  },
  {
    classId: "EC003663",
    classGroup: "EG020250",
    artClassDesc: "Paint",
    artClassVersion: 2,
    label: "EC003663: Paint"
  },
  {
    classId: "EC003664",
    classGroup: "EG020004",
    artClassDesc: "Automatic drop down seal for doors",
    artClassVersion: 2,
    label: "EC003664: Automatic drop down seal for doors"
  },
  {
    classId: "EC003666",
    classGroup: "EG020004",
    artClassDesc: "Floor spring",
    artClassVersion: 2,
    label: "EC003666: Floor spring"
  },
  {
    classId: "EC003668",
    classGroup: "EG020004",
    artClassDesc: "Closing unit for overhead door closer",
    artClassVersion: 2,
    label: "EC003668: Closing unit for overhead door closer"
  },
  {
    classId: "EC003669",
    classGroup: "EG020004",
    artClassDesc: "Smoke switch control unit",
    artClassVersion: 2,
    label: "EC003669: Smoke switch control unit"
  },
  {
    classId: "EC003670",
    classGroup: "EG020004",
    artClassDesc: "Door coordinator",
    artClassVersion: 1,
    label: "EC003670: Door coordinator"
  },
  {
    classId: "EC003671",
    classGroup: "EG020004",
    artClassDesc: "Housing/cover cap for door closer",
    artClassVersion: 2,
    label: "EC003671: Housing/cover cap for door closer"
  },
  {
    classId: "EC003672",
    classGroup: "EG020004",
    artClassDesc: "Cover plate for floor spring",
    artClassVersion: 2,
    label: "EC003672: Cover plate for floor spring"
  },
  {
    classId: "EC003674",
    classGroup: "EG020004",
    artClassDesc: "Pivot bearing for door closer",
    artClassVersion: 2,
    label: "EC003674: Pivot bearing for door closer"
  },
  {
    classId: "EC003675",
    classGroup: "EG020004",
    artClassDesc: "Anchor plate for holding magnet",
    artClassVersion: 2,
    label: "EC003675: Anchor plate for holding magnet"
  },
  {
    classId: "EC003676",
    classGroup: "EG020004",
    artClassDesc: "Integrated door closer guide rail",
    artClassVersion: 2,
    label: "EC003676: Integrated door closer guide rail"
  },
  {
    classId: "EC003677",
    classGroup: "EG020004",
    artClassDesc: "Carry bar",
    artClassVersion: 2,
    label: "EC003677: Carry bar"
  },
  {
    classId: "EC003679",
    classGroup: "EG020004",
    artClassDesc: "Guide rail for floor spring",
    artClassVersion: 2,
    label: "EC003679: Guide rail for floor spring"
  },
  {
    classId: "EC003680",
    classGroup: "EG020004",
    artClassDesc: "Mounting rail for floor spring",
    artClassVersion: 2,
    label: "EC003680: Mounting rail for floor spring"
  },
  {
    classId: "EC003681",
    classGroup: "EG020004",
    artClassDesc: "Pivot hinge for floor spring",
    artClassVersion: 2,
    label: "EC003681: Pivot hinge for floor spring"
  },
  {
    classId: "EC003682",
    classGroup: "EG020004",
    artClassDesc: "Cement box for floor spring",
    artClassVersion: 2,
    label: "EC003682: Cement box for floor spring"
  },
  {
    classId: "EC003683",
    classGroup: "EG020004",
    artClassDesc: "Sliding piece for overhead door closer",
    artClassVersion: 2,
    label: "EC003683: Sliding piece for overhead door closer"
  },
  {
    classId: "EC003684",
    classGroup: "EG020004",
    artClassDesc: "Door lever/swing arm for floor spring",
    artClassVersion: 2,
    label: "EC003684: Door lever/swing arm for floor spring"
  },
  {
    classId: "EC003685",
    classGroup: "EG020004",
    artClassDesc: "Cover panel for floor spring",
    artClassVersion: 2,
    label: "EC003685: Cover panel for floor spring"
  },
  {
    classId: "EC003687",
    classGroup: "EG020004",
    artClassDesc: "Sill seal for doors",
    artClassVersion: 2,
    label: "EC003687: Sill seal for doors"
  },
  {
    classId: "EC003689",
    classGroup: "EG017970",
    artClassDesc: "Tap and pipe connection element for surface mounting",
    artClassVersion: 2,
    label: "EC003689: Tap and pipe connection element for surface mounting"
  },
  {
    classId: "EC003691",
    classGroup: "EG000010",
    artClassDesc: "Protection profile for tubes",
    artClassVersion: 2,
    label: "EC003691: Protection profile for tubes"
  },
  {
    classId: "EC003692",
    classGroup: "EG020004",
    artClassDesc: "Door handle set",
    artClassVersion: 2,
    label: "EC003692: Door handle set"
  },
  {
    classId: "EC003694",
    classGroup: "EG020004",
    artClassDesc: "Cover cap for door hinge",
    artClassVersion: 2,
    label: "EC003694: Cover cap for door hinge"
  },
  {
    classId: "EC003696",
    classGroup: "EG020004",
    artClassDesc: "Multi point lock",
    artClassVersion: 1,
    label: "EC003696: Multi point lock"
  },
  {
    classId: "EC003697",
    classGroup: "EG020004",
    artClassDesc: "Case lock with handle set",
    artClassVersion: 1,
    label: "EC003697: Case lock with handle set"
  },
  {
    classId: "EC003701",
    classGroup: "EG020004",
    artClassDesc: "Lock case",
    artClassVersion: 1,
    label: "EC003701: Lock case"
  },
  {
    classId: "EC003703",
    classGroup: "EG020004",
    artClassDesc: "Shoot bolt lock",
    artClassVersion: 1,
    label: "EC003703: Shoot bolt lock"
  },
  {
    classId: "EC003705",
    classGroup: "EG020004",
    artClassDesc: "Receiving element for door hinge",
    artClassVersion: 2,
    label: "EC003705: Receiving element for door hinge"
  },
  {
    classId: "EC003706",
    classGroup: "EG020004",
    artClassDesc: "Mounting aid for door hinge",
    artClassVersion: 2,
    label: "EC003706: Mounting aid for door hinge"
  },
  {
    classId: "EC003707",
    classGroup: "EG020004",
    artClassDesc: "Mounting plate for door hinge",
    artClassVersion: 2,
    label: "EC003707: Mounting plate for door hinge"
  },
  {
    classId: "EC003708",
    classGroup: "EG020004",
    artClassDesc: "Sliding door operator",
    artClassVersion: 2,
    label: "EC003708: Sliding door operator"
  },
  {
    classId: "EC003709",
    classGroup: "EG020004",
    artClassDesc: "End cover for running rail",
    artClassVersion: 2,
    label: "EC003709: End cover for running rail"
  },
  {
    classId: "EC003710",
    classGroup: "EG020004",
    artClassDesc: "Cover profile for sliding door",
    artClassVersion: 2,
    label: "EC003710: Cover profile for sliding door"
  },
  {
    classId: "EC003711",
    classGroup: "EG020004",
    artClassDesc: "Mounting profile for sliding door",
    artClassVersion: 2,
    label: "EC003711: Mounting profile for sliding door"
  },
  {
    classId: "EC003712",
    classGroup: "EG020004",
    artClassDesc: "Buffer/stop for sliding door/sliding gate",
    artClassVersion: 2,
    label: "EC003712: Buffer/stop for sliding door/sliding gate"
  },
  {
    classId: "EC003714",
    classGroup: "EG020004",
    artClassDesc: "Floor guide for sliding door",
    artClassVersion: 2,
    label: "EC003714: Floor guide for sliding door"
  },
  {
    classId: "EC003716",
    classGroup: "EG020004",
    artClassDesc: "Soft closing system for sliding door",
    artClassVersion: 2,
    label: "EC003716: Soft closing system for sliding door"
  },
  {
    classId: "EC003718",
    classGroup: "EG020004",
    artClassDesc: "Cover angle/cover plate for door hinge",
    artClassVersion: 2,
    label: "EC003718: Cover angle/cover plate for door hinge"
  },
  {
    classId: "EC003719",
    classGroup: "EG020004",
    artClassDesc: "Screw-on pocket for door hinge",
    artClassVersion: 2,
    label: "EC003719: Screw-on pocket for door hinge"
  },
  {
    classId: "EC003720",
    classGroup: "EG020004",
    artClassDesc: "Clamping block for door hinge",
    artClassVersion: 2,
    label: "EC003720: Clamping block for door hinge"
  },
  {
    classId: "EC003721",
    classGroup: "EG020004",
    artClassDesc: "Clamping nut for door hinge",
    artClassVersion: 2,
    label: "EC003721: Clamping nut for door hinge"
  },
  {
    classId: "EC003722",
    classGroup: "EG020004",
    artClassDesc: "Double action spring hinge",
    artClassVersion: 2,
    label: "EC003722: Double action spring hinge"
  },
  {
    classId: "EC003724",
    classGroup: "EG020004",
    artClassDesc: "Frame part/wing part for door hinge",
    artClassVersion: 2,
    label: "EC003724: Frame part/wing part for door hinge"
  },
  {
    classId: "EC003725",
    classGroup: "EG020004",
    artClassDesc: "Casting compound for door closer",
    artClassVersion: 2,
    label: "EC003725: Casting compound for door closer"
  },
  {
    classId: "EC003726",
    classGroup: "EG020004",
    artClassDesc: "Integrated door closer complete-set",
    artClassVersion: 2,
    label: "EC003726: Integrated door closer complete-set"
  },
  {
    classId: "EC003727",
    classGroup: "EG020004",
    artClassDesc: "Temperature detector for door closer",
    artClassVersion: 1,
    label: "EC003727: Temperature detector for door closer"
  },
  {
    classId: "EC003728",
    classGroup: "EG000015",
    artClassDesc: "Distributor for electrical distribution column (multi-storey building)",
    artClassVersion: 2,
    label: "EC003728: Distributor for electrical distribution column (multi-storey building)"
  },
  {
    classId: "EC003729",
    classGroup: "EG000015",
    artClassDesc: "Accessories/spare parts for protection board for electrical distribution column",
    artClassVersion: 2,
    label: "EC003729: Accessories/spare parts for protection board for electrical distribution column"
  },
  {
    classId: "EC003730",
    classGroup: "EG020004",
    artClassDesc: "Spindle converter for door handle",
    artClassVersion: 2,
    label: "EC003730: Spindle converter for door handle"
  },
  {
    classId: "EC003731",
    classGroup: "EG020004",
    artClassDesc: "Square spindle for doors/windows",
    artClassVersion: 2,
    label: "EC003731: Square spindle for doors/windows"
  },
  {
    classId: "EC003734",
    classGroup: "EG000015",
    artClassDesc: "Protection board for electrical distribution column (multi-storey building)",
    artClassVersion: 2,
    label: "EC003734: Protection board for electrical distribution column (multi-storey building)"
  },
  {
    classId: "EC003735",
    classGroup: "EG020004",
    artClassDesc: "Glass door seal",
    artClassVersion: 2,
    label: "EC003735: Glass door seal"
  },
  {
    classId: "EC003736",
    classGroup: "EG020004",
    artClassDesc: "Main entrance door threshold",
    artClassVersion: 2,
    label: "EC003736: Main entrance door threshold"
  },
  {
    classId: "EC003737",
    classGroup: "EG020004",
    artClassDesc: "Main entrance door threshold cover",
    artClassVersion: 2,
    label: "EC003737: Main entrance door threshold cover"
  },
  {
    classId: "EC003738",
    classGroup: "EG020004",
    artClassDesc: "Main entrance door threshold filling piece",
    artClassVersion: 2,
    label: "EC003738: Main entrance door threshold filling piece"
  },
  {
    classId: "EC003739",
    classGroup: "EG020004",
    artClassDesc: "Threshold bracket",
    artClassVersion: 2,
    label: "EC003739: Threshold bracket"
  },
  {
    classId: "EC003740",
    classGroup: "EG020004",
    artClassDesc: "Main entrance door threshold additional profile",
    artClassVersion: 2,
    label: "EC003740: Main entrance door threshold additional profile"
  },
  {
    classId: "EC003741",
    classGroup: "EG018410",
    artClassDesc: "Angle damping rod",
    artClassVersion: 2,
    label: "EC003741: Angle damping rod"
  },
  {
    classId: "EC003742",
    classGroup: "EG020004",
    artClassDesc: "Electronic door cylinder",
    artClassVersion: 2,
    label: "EC003742: Electronic door cylinder"
  },
  {
    classId: "EC003743",
    classGroup: "EG020004",
    artClassDesc: "Slider for furniture sliding door",
    artClassVersion: 2,
    label: "EC003743: Slider for furniture sliding door"
  },
  {
    classId: "EC003744",
    classGroup: "EG020004",
    artClassDesc: "Runner for furniture sliding door",
    artClassVersion: 2,
    label: "EC003744: Runner for furniture sliding door"
  },
  {
    classId: "EC003745",
    classGroup: "EG020004",
    artClassDesc: "Sliding door fitting for furniture",
    artClassVersion: 2,
    label: "EC003745: Sliding door fitting for furniture"
  },
  {
    classId: "EC003746",
    classGroup: "EG020004",
    artClassDesc: "Ventilation grill for furniture/doors",
    artClassVersion: 2,
    label: "EC003746: Ventilation grill for furniture/doors"
  },
  {
    classId: "EC003748",
    classGroup: "EG020004",
    artClassDesc: "Door guard for emergency exit technology",
    artClassVersion: 2,
    label: "EC003748: Door guard for emergency exit technology"
  },
  {
    classId: "EC003749",
    classGroup: "EG018410",
    artClassDesc: "Damping rod",
    artClassVersion: 2,
    label: "EC003749: Damping rod"
  },
  {
    classId: "EC003750",
    classGroup: "EG020004",
    artClassDesc: "Fastening socket for sliding gate",
    artClassVersion: 2,
    label: "EC003750: Fastening socket for sliding gate"
  },
  {
    classId: "EC003751",
    classGroup: "EG020004",
    artClassDesc: "Fastening angle for sliding gate",
    artClassVersion: 2,
    label: "EC003751: Fastening angle for sliding gate"
  },
  {
    classId: "EC003752",
    classGroup: "EG020004",
    artClassDesc: "Floor bolt for sliding gate",
    artClassVersion: 2,
    label: "EC003752: Floor bolt for sliding gate"
  },
  {
    classId: "EC003753",
    classGroup: "EG020004",
    artClassDesc: "Guide slider for sliding gate",
    artClassVersion: 2,
    label: "EC003753: Guide slider for sliding gate"
  },
  {
    classId: "EC003754",
    classGroup: "EG020004",
    artClassDesc: "Guide roller for sliding gate",
    artClassVersion: 2,
    label: "EC003754: Guide roller for sliding gate"
  },
  {
    classId: "EC003755",
    classGroup: "EG020004",
    artClassDesc: "Guide rail for sliding gate",
    artClassVersion: 2,
    label: "EC003755: Guide rail for sliding gate"
  },
  {
    classId: "EC003756",
    classGroup: "EG020004",
    artClassDesc: "Guide rail bend for sliding gate",
    artClassVersion: 2,
    label: "EC003756: Guide rail bend for sliding gate"
  },
  {
    classId: "EC003757",
    classGroup: "EG020004",
    artClassDesc: "Running tube for sliding gate",
    artClassVersion: 2,
    label: "EC003757: Running tube for sliding gate"
  },
  {
    classId: "EC003758",
    classGroup: "EG020004",
    artClassDesc: "Running rail for sliding gate",
    artClassVersion: 1,
    label: "EC003758: Running rail for sliding gate"
  },
  {
    classId: "EC003759",
    classGroup: "EG020004",
    artClassDesc: "Running rail bend for sliding gate",
    artClassVersion: 2,
    label: "EC003759: Running rail bend for sliding gate"
  },
  {
    classId: "EC003761",
    classGroup: "EG020004",
    artClassDesc: "Rolling apparatus for sliding gate",
    artClassVersion: 2,
    label: "EC003761: Rolling apparatus for sliding gate"
  },
  {
    classId: "EC003762",
    classGroup: "EG020004",
    artClassDesc: "Handle for sliding gate",
    artClassVersion: 2,
    label: "EC003762: Handle for sliding gate"
  },
  {
    classId: "EC003763",
    classGroup: "EG020004",
    artClassDesc: "Roller for sliding gate",
    artClassVersion: 2,
    label: "EC003763: Roller for sliding gate"
  },
  {
    classId: "EC003764",
    classGroup: "EG020004",
    artClassDesc: "Support angle and plate for sliding gate",
    artClassVersion: 2,
    label: "EC003764: Support angle and plate for sliding gate"
  },
  {
    classId: "EC003765",
    classGroup: "EG020004",
    artClassDesc: "Connecting sleeve for sliding gate",
    artClassVersion: 2,
    label: "EC003765: Connecting sleeve for sliding gate"
  },
  {
    classId: "EC003766",
    classGroup: "EG020004",
    artClassDesc: "Reinforcing soffit bracket for sliding gate",
    artClassVersion: 2,
    label: "EC003766: Reinforcing soffit bracket for sliding gate"
  },
  {
    classId: "EC003767",
    classGroup: "EG020004",
    artClassDesc: "Flush pull handle",
    artClassVersion: 2,
    label: "EC003767: Flush pull handle"
  },
  {
    classId: "EC003768",
    classGroup: "EG020004",
    artClassDesc: "Sliding door system",
    artClassVersion: 2,
    label: "EC003768: Sliding door system"
  },
  {
    classId: "EC003769",
    classGroup: "EG020004",
    artClassDesc: "Rail for sliding door",
    artClassVersion: 2,
    label: "EC003769: Rail for sliding door"
  },
  {
    classId: "EC003770",
    classGroup: "EG020004",
    artClassDesc: "Centre strike patch",
    artClassVersion: 2,
    label: "EC003770: Centre strike patch"
  },
  {
    classId: "EC003774",
    classGroup: "EG020004",
    artClassDesc: "Round bar/square bar for lock",
    artClassVersion: 2,
    label: "EC003774: Round bar/square bar for lock"
  },
  {
    classId: "EC003776",
    classGroup: "EG020004",
    artClassDesc: "Safety door fitting mounting set",
    artClassVersion: 2,
    label: "EC003776: Safety door fitting mounting set"
  },
  {
    classId: "EC003777",
    classGroup: "EG020004",
    artClassDesc: "Safety fitting escutcheon with backplate",
    artClassVersion: 2,
    label: "EC003777: Safety fitting escutcheon with backplate"
  },
  {
    classId: "EC003778",
    classGroup: "EG015230",
    artClassDesc: "Air cooler (refrigeration technology)",
    artClassVersion: 1,
    label: "EC003778: Air cooler (refrigeration technology)"
  },
  {
    classId: "EC003780",
    classGroup: "EG020004",
    artClassDesc: "Additional window locking device",
    artClassVersion: 1,
    label: "EC003780: Additional window locking device"
  },
  {
    classId: "EC003781",
    classGroup: "EG020004",
    artClassDesc: "Hinge-side lock",
    artClassVersion: 2,
    label: "EC003781: Hinge-side lock"
  },
  {
    classId: "EC003783",
    classGroup: "EG020004",
    artClassDesc: "Accessories/spare parts for door bar lock",
    artClassVersion: 2,
    label: "EC003783: Accessories/spare parts for door bar lock"
  },
  {
    classId: "EC003784",
    classGroup: "EG020004",
    artClassDesc: "Bolt lock",
    artClassVersion: 2,
    label: "EC003784: Bolt lock"
  },
  {
    classId: "EC003785",
    classGroup: "EG020004",
    artClassDesc: "Lockblocking key",
    artClassVersion: 2,
    label: "EC003785: Lockblocking key"
  },
  {
    classId: "EC003786",
    classGroup: "EG020004",
    artClassDesc: "Door chain",
    artClassVersion: 2,
    label: "EC003786: Door chain"
  },
  {
    classId: "EC003787",
    classGroup: "EG020004",
    artClassDesc: "Door viewer",
    artClassVersion: 2,
    label: "EC003787: Door viewer"
  },
  {
    classId: "EC003788",
    classGroup: "EG020004",
    artClassDesc: "Cover cap for door viewer",
    artClassVersion: 2,
    label: "EC003788: Cover cap for door viewer"
  },
  {
    classId: "EC003789",
    classGroup: "EG020004",
    artClassDesc: "Escutcheon cover for door viewer",
    artClassVersion: 2,
    label: "EC003789: Escutcheon cover for door viewer"
  },
  {
    classId: "EC003790",
    classGroup: "EG020004",
    artClassDesc: "Extension for door viewer",
    artClassVersion: 2,
    label: "EC003790: Extension for door viewer"
  },
  {
    classId: "EC003791",
    classGroup: "EG020004",
    artClassDesc: "Hasp",
    artClassVersion: 2,
    label: "EC003791: Hasp"
  },
  {
    classId: "EC003792",
    classGroup: "EG020004",
    artClassDesc: "Spacer sleeve for push handle",
    artClassVersion: 2,
    label: "EC003792: Spacer sleeve for push handle"
  },
  {
    classId: "EC003793",
    classGroup: "EG020004",
    artClassDesc: "Handle tube for push handle",
    artClassVersion: 2,
    label: "EC003793: Handle tube for push handle"
  },
  {
    classId: "EC003794",
    classGroup: "EG020004",
    artClassDesc: "Support for push handle",
    artClassVersion: 2,
    label: "EC003794: Support for push handle"
  },
  {
    classId: "EC003795",
    classGroup: "EG020004",
    artClassDesc: "Decorative end for push handle",
    artClassVersion: 2,
    label: "EC003795: Decorative end for push handle"
  },
  {
    classId: "EC003796",
    classGroup: "EG020004",
    artClassDesc: "Fixing set for push handle",
    artClassVersion: 2,
    label: "EC003796: Fixing set for push handle"
  },
  {
    classId: "EC003797",
    classGroup: "EG020004",
    artClassDesc: "Push handle (single)",
    artClassVersion: 2,
    label: "EC003797: Push handle (single)"
  },
  {
    classId: "EC003798",
    classGroup: "EG015910",
    artClassDesc: "Gasket plate (prefabricated)",
    artClassVersion: 1,
    label: "EC003798: Gasket plate (prefabricated)"
  },
  {
    classId: "EC003801",
    classGroup: "EG020004",
    artClassDesc: "Door/gate operating locking bolt",
    artClassVersion: 2,
    label: "EC003801: Door/gate operating locking bolt"
  },
  {
    classId: "EC003802",
    classGroup: "EG020004",
    artClassDesc: "Partition wall bracket",
    artClassVersion: 2,
    label: "EC003802: Partition wall bracket"
  },
  {
    classId: "EC003803",
    classGroup: "EG020004",
    artClassDesc: "Door buffer support",
    artClassVersion: 2,
    label: "EC003803: Door buffer support"
  },
  {
    classId: "EC003804",
    classGroup: "EG020004",
    artClassDesc: "Axis extension for door closer",
    artClassVersion: 2,
    label: "EC003804: Axis extension for door closer"
  },
  {
    classId: "EC003806",
    classGroup: "EG020004",
    artClassDesc: "Closing unit for integrated door closer",
    artClassVersion: 2,
    label: "EC003806: Closing unit for integrated door closer"
  },
  {
    classId: "EC003807",
    classGroup: "EG020004",
    artClassDesc: "Mounting plate for overhead door closer",
    artClassVersion: 2,
    label: "EC003807: Mounting plate for overhead door closer"
  },
  {
    classId: "EC003808",
    classGroup: "EG020004",
    artClassDesc: "Link arm/guide rail for overhead door closer",
    artClassVersion: 2,
    label: "EC003808: Link arm/guide rail for overhead door closer"
  },
  {
    classId: "EC003809",
    classGroup: "EG020004",
    artClassDesc: "Glass saddle plate",
    artClassVersion: 2,
    label: "EC003809: Glass saddle plate"
  },
  {
    classId: "EC003810",
    classGroup: "EG020004",
    artClassDesc: "Overhead door closer complete-set",
    artClassVersion: 2,
    label: "EC003810: Overhead door closer complete-set"
  },
  {
    classId: "EC003811",
    classGroup: "EG020004",
    artClassDesc: "Interlocking hold-open unit",
    artClassVersion: 2,
    label: "EC003811: Interlocking hold-open unit"
  },
  {
    classId: "EC003812",
    classGroup: "EG020004",
    artClassDesc: "Opening restrictor for door closer",
    artClassVersion: 2,
    label: "EC003812: Opening restrictor for door closer"
  },
  {
    classId: "EC003815",
    classGroup: "EG020004",
    artClassDesc: "Adjustment fitting for doors",
    artClassVersion: 2,
    label: "EC003815: Adjustment fitting for doors"
  },
  {
    classId: "EC003816",
    classGroup: "EG020004",
    artClassDesc: "Floor socket",
    artClassVersion: 2,
    label: "EC003816: Floor socket"
  },
  {
    classId: "EC003818",
    classGroup: "EG019710",
    artClassDesc: "Finger protection for doors",
    artClassVersion: 2,
    label: "EC003818: Finger protection for doors"
  },
  {
    classId: "EC003820",
    classGroup: "EG020004",
    artClassDesc: "House number/letter",
    artClassVersion: 2,
    label: "EC003820: House number/letter"
  },
  {
    classId: "EC003821",
    classGroup: "EG020004",
    artClassDesc: "Domestic animal flap",
    artClassVersion: 2,
    label: "EC003821: Domestic animal flap"
  },
  {
    classId: "EC003824",
    classGroup: "EG020004",
    artClassDesc: "Kicking plate for doors",
    artClassVersion: 2,
    label: "EC003824: Kicking plate for doors"
  },
  {
    classId: "EC003825",
    classGroup: "EG020004",
    artClassDesc: "Door holder",
    artClassVersion: 2,
    label: "EC003825: Door holder"
  },
  {
    classId: "EC003826",
    classGroup: "EG020004",
    artClassDesc: "Door knocker",
    artClassVersion: 2,
    label: "EC003826: Door knocker"
  },
  {
    classId: "EC003827",
    classGroup: "EG020004",
    artClassDesc: "Emergency exit door cover",
    artClassVersion: 2,
    label: "EC003827: Emergency exit door cover"
  },
  {
    classId: "EC003828",
    classGroup: "EG020004",
    artClassDesc: "Control terminal for emergency exit doors",
    artClassVersion: 2,
    label: "EC003828: Control terminal for emergency exit doors"
  },
  {
    classId: "EC003829",
    classGroup: "EG020004",
    artClassDesc: "Mounting plate for door guard",
    artClassVersion: 2,
    label: "EC003829: Mounting plate for door guard"
  },
  {
    classId: "EC003830",
    classGroup: "EG000046",
    artClassDesc: "Underwear set",
    artClassVersion: 1,
    label: "EC003830: Underwear set"
  },
  {
    classId: "EC003832",
    classGroup: "EG020004",
    artClassDesc: "Door opening restrictor",
    artClassVersion: 2,
    label: "EC003832: Door opening restrictor"
  },
  {
    classId: "EC003833",
    classGroup: "EG020004",
    artClassDesc: "Screw-on attachment for door opener",
    artClassVersion: 2,
    label: "EC003833: Screw-on attachment for door opener"
  },
  {
    classId: "EC003834",
    classGroup: "EG020004",
    artClassDesc: "Dummy component without strike plate for door opener",
    artClassVersion: 2,
    label: "EC003834: Dummy component without strike plate for door opener"
  },
  {
    classId: "EC003835",
    classGroup: "EG020004",
    artClassDesc: "Strike plate for door opener",
    artClassVersion: 2,
    label: "EC003835: Strike plate for door opener"
  },
  {
    classId: "EC003836",
    classGroup: "EG020004",
    artClassDesc: "Power supply for door opener",
    artClassVersion: 1,
    label: "EC003836: Power supply for door opener"
  },
  {
    classId: "EC003839",
    classGroup: "EG020004",
    artClassDesc: "Gate fitting",
    artClassVersion: 2,
    label: "EC003839: Gate fitting"
  },
  {
    classId: "EC003841",
    classGroup: "EG020004",
    artClassDesc: "Supporting element for sliding doors/sliding gates",
    artClassVersion: 2,
    label: "EC003841: Supporting element for sliding doors/sliding gates"
  },
  {
    classId: "EC003842",
    classGroup: "EG015230",
    artClassDesc: "Condenser (refrigeration technology)",
    artClassVersion: 2,
    label: "EC003842: Condenser (refrigeration technology)"
  },
  {
    classId: "EC003843",
    classGroup: "EG015230",
    artClassDesc: "Compressor (refrigeration technology)",
    artClassVersion: 2,
    label: "EC003843: Compressor (refrigeration technology)"
  },
  {
    classId: "EC003844",
    classGroup: "EG000015",
    artClassDesc: "Residential technical trunking pole unit (multi-storey building)",
    artClassVersion: 1,
    label: "EC003844: Residential technical trunking pole unit (multi-storey building)"
  },
  {
    classId: "EC003845",
    classGroup: "EG000037",
    artClassDesc: "Communication board (multi-storey building)",
    artClassVersion: 1,
    label: "EC003845: Communication board (multi-storey building)"
  },
  {
    classId: "EC003846",
    classGroup: "EG000037",
    artClassDesc: "Housing for communication board (multi-storey building)",
    artClassVersion: 1,
    label: "EC003846: Housing for communication board (multi-storey building)"
  },
  {
    classId: "EC003847",
    classGroup: "EG000023",
    artClassDesc: "Domestic connection cabinet (multi-storey building)",
    artClassVersion: 1,
    label: "EC003847: Domestic connection cabinet (multi-storey building)"
  },
  {
    classId: "EC003848",
    classGroup: "EG015260",
    artClassDesc: "Open expansion vessel",
    artClassVersion: 2,
    label: "EC003848: Open expansion vessel"
  },
  {
    classId: "EC003850",
    classGroup: "EG000037",
    artClassDesc: "Accessories/spare parts for WLAN controller",
    artClassVersion: 2,
    label: "EC003850: Accessories/spare parts for WLAN controller"
  },
  {
    classId: "EC003851",
    classGroup: "EG000054",
    artClassDesc: "Video management software",
    artClassVersion: 1,
    label: "EC003851: Video management software"
  },
  {
    classId: "EC003853",
    classGroup: "EG015550",
    artClassDesc: "Concentric communal flue system",
    artClassVersion: 1,
    label: "EC003853: Concentric communal flue system"
  },
  {
    classId: "EC003854",
    classGroup: "EG020004",
    artClassDesc: "Interlock controller",
    artClassVersion: 2,
    label: "EC003854: Interlock controller"
  },
  {
    classId: "EC003855",
    classGroup: "EG000049",
    artClassDesc: "Dowel fastening nail",
    artClassVersion: 2,
    label: "EC003855: Dowel fastening nail"
  },
  {
    classId: "EC003856",
    classGroup: "EG015230",
    artClassDesc: "Refrigeration unit air cooled (refrigeration technology)",
    artClassVersion: 1,
    label: "EC003856: Refrigeration unit air cooled (refrigeration technology)"
  },
  {
    classId: "EC003857",
    classGroup: "EG000055",
    artClassDesc: "Photovoltaic DC Stringbox",
    artClassVersion: 2,
    label: "EC003857: Photovoltaic DC Stringbox"
  },
  {
    classId: "EC003858",
    classGroup: "EG020000",
    artClassDesc: "Working stool",
    artClassVersion: 1,
    label: "EC003858: Working stool"
  },
  {
    classId: "EC003860",
    classGroup: "EG000041",
    artClassDesc: "Teleconverter",
    artClassVersion: 1,
    label: "EC003860: Teleconverter"
  },
  {
    classId: "EC003861",
    classGroup: "EG000049",
    artClassDesc: "Bolt",
    artClassVersion: 2,
    label: "EC003861: Bolt"
  },
  {
    classId: "EC003862",
    classGroup: "EG000051",
    artClassDesc: "Tacker (compressed air)",
    artClassVersion: 2,
    label: "EC003862: Tacker (compressed air)"
  },
  {
    classId: "EC003863",
    classGroup: "EG000049",
    artClassDesc: "Nut",
    artClassVersion: 2,
    label: "EC003863: Nut"
  },
  {
    classId: "EC003864",
    classGroup: "EG000050",
    artClassDesc: "Bit",
    artClassVersion: 1,
    label: "EC003864: Bit"
  },
  {
    classId: "EC003865",
    classGroup: "EG020005",
    artClassDesc: "Agitator",
    artClassVersion: 1,
    label: "EC003865: Agitator"
  },
  {
    classId: "EC003866",
    classGroup: "EG020005",
    artClassDesc: "Chemical dosing system",
    artClassVersion: 2,
    label: "EC003866: Chemical dosing system"
  },
  {
    classId: "EC003867",
    classGroup: "EG017550",
    artClassDesc: "Waste fitting",
    artClassVersion: 2,
    label: "EC003867: Waste fitting"
  },
  {
    classId: "EC003868",
    classGroup: "EG017550",
    artClassDesc: "Shower waste cover",
    artClassVersion: 2,
    label: "EC003868: Shower waste cover"
  },
  {
    classId: "EC003869",
    classGroup: "EG000006",
    artClassDesc: "Accessories/spare parts for flexible cable guiding system",
    artClassVersion: 1,
    label: "EC003869: Accessories/spare parts for flexible cable guiding system"
  },
  {
    classId: "EC003870",
    classGroup: "EG000037",
    artClassDesc: "Planar Lightwave Circuit (PLC) splitter",
    artClassVersion: 2,
    label: "EC003870: Planar Lightwave Circuit (PLC) splitter"
  },
  {
    classId: "EC003871",
    classGroup: "EG000014",
    artClassDesc: "Outlet box for car preheating",
    artClassVersion: 2,
    label: "EC003871: Outlet box for car preheating"
  },
  {
    classId: "EC003872",
    classGroup: "EG015210",
    artClassDesc: "Boiler, solid fuel heated",
    artClassVersion: 1,
    label: "EC003872: Boiler, solid fuel heated"
  },
  {
    classId: "EC003873",
    classGroup: "EG000044",
    artClassDesc: "Camera probe for inspection camera",
    artClassVersion: 1,
    label: "EC003873: Camera probe for inspection camera"
  },
  {
    classId: "EC003875",
    classGroup: "EG020004",
    artClassDesc: "Tube flush bolt (automatic)",
    artClassVersion: 1,
    label: "EC003875: Tube flush bolt (automatic)"
  },
  {
    classId: "EC003876",
    classGroup: "EG020004",
    artClassDesc: "Panic push bar",
    artClassVersion: 1,
    label: "EC003876: Panic push bar"
  },
  {
    classId: "EC003877",
    classGroup: "EG015710",
    artClassDesc: "Radiator casing",
    artClassVersion: 2,
    label: "EC003877: Radiator casing"
  },
  {
    classId: "EC003878",
    classGroup: "EG015540",
    artClassDesc: "Sealing ring with anchoring",
    artClassVersion: 1,
    label: "EC003878: Sealing ring with anchoring"
  },
  {
    classId: "EC003879",
    classGroup: "EG000050",
    artClassDesc: "Tacker for pipe anchor clips",
    artClassVersion: 1,
    label: "EC003879: Tacker for pipe anchor clips"
  },
  {
    classId: "EC003880",
    classGroup: "EG020400",
    artClassDesc: "Glulam",
    artClassVersion: 2,
    label: "EC003880: Glulam"
  },
  {
    classId: "EC003881",
    classGroup: "EG000038",
    artClassDesc: "Office stapler",
    artClassVersion: 2,
    label: "EC003881: Office stapler"
  },
  {
    classId: "EC003882",
    classGroup: "EG019910",
    artClassDesc: "V belt pulley",
    artClassVersion: 1,
    label: "EC003882: V belt pulley"
  },
  {
    classId: "EC003883",
    classGroup: "EG015130",
    artClassDesc: "Frame for shower drain grid",
    artClassVersion: 1,
    label: "EC003883: Frame for shower drain grid"
  },
  {
    classId: "EC003884",
    classGroup: "EG000050",
    artClassDesc: "Thread cutting set",
    artClassVersion: 2,
    label: "EC003884: Thread cutting set"
  },
  {
    classId: "EC003885",
    classGroup: "EG015410",
    artClassDesc: "Water distributor",
    artClassVersion: 2,
    label: "EC003885: Water distributor"
  },
  {
    classId: "EC003886",
    classGroup: "EG015410",
    artClassDesc: "Accessories/spare parts for group distributor housing watertight",
    artClassVersion: 1,
    label: "EC003886: Accessories/spare parts for group distributor housing watertight"
  },
  {
    classId: "EC003887",
    classGroup: "EG000050",
    artClassDesc: "Pipe coil cart/uncoiler",
    artClassVersion: 2,
    label: "EC003887: Pipe coil cart/uncoiler"
  },
  {
    classId: "EC003888",
    classGroup: "EG000060",
    artClassDesc: "Tile adjuster",
    artClassVersion: 1,
    label: "EC003888: Tile adjuster"
  },
  {
    classId: "EC003889",
    classGroup: "EG000060",
    artClassDesc: "End cap for protective tube for plastic pipe system",
    artClassVersion: 1,
    label: "EC003889: End cap for protective tube for plastic pipe system"
  },
  {
    classId: "EC003890",
    classGroup: "EG000014",
    artClassDesc: "Adapter for car power socket",
    artClassVersion: 1,
    label: "EC003890: Adapter for car power socket"
  },
  {
    classId: "EC003891",
    classGroup: "EG015540",
    artClassDesc: "Flexible fitting with 2 connections",
    artClassVersion: 2,
    label: "EC003891: Flexible fitting with 2 connections"
  },
  {
    classId: "EC003892",
    classGroup: "EG015540",
    artClassDesc: "Pipe-in-pipe junction box for wall mounting",
    artClassVersion: 2,
    label: "EC003892: Pipe-in-pipe junction box for wall mounting"
  },
  {
    classId: "EC003893",
    classGroup: "EG000053",
    artClassDesc: "Battery (Lithium)",
    artClassVersion: 1,
    label: "EC003893: Battery (Lithium)"
  },
  {
    classId: "EC003894",
    classGroup: "EG020006",
    artClassDesc: "Footrest",
    artClassVersion: 1,
    label: "EC003894: Footrest"
  },
  {
    classId: "EC003895",
    classGroup: "EG020004",
    artClassDesc: "Rod for door and gate drive bar",
    artClassVersion: 2,
    label: "EC003895: Rod for door and gate drive bar"
  },
  {
    classId: "EC003896",
    classGroup: "EG000046",
    artClassDesc: "Care product for shoes/clothes",
    artClassVersion: 1,
    label: "EC003896: Care product for shoes/clothes"
  },
  {
    classId: "EC003897",
    classGroup: "EG015910",
    artClassDesc: "Sealing ring set",
    artClassVersion: 1,
    label: "EC003897: Sealing ring set"
  },
  {
    classId: "EC003898",
    classGroup: "EG000049",
    artClassDesc: "Dowel/plug set",
    artClassVersion: 2,
    label: "EC003898: Dowel/plug set"
  },
  {
    classId: "EC003899",
    classGroup: "EG020400",
    artClassDesc: "Knothole plug",
    artClassVersion: 2,
    label: "EC003899: Knothole plug"
  },
  {
    classId: "EC003900",
    classGroup: "EG019710",
    artClassDesc: "End profile flooring",
    artClassVersion: 2,
    label: "EC003900: End profile flooring"
  },
  {
    classId: "EC003901",
    classGroup: "EG000046",
    artClassDesc: "Scarf/neckerchief",
    artClassVersion: 1,
    label: "EC003901: Scarf/neckerchief"
  },
  {
    classId: "EC003902",
    classGroup: "EG000046",
    artClassDesc: "Cape",
    artClassVersion: 1,
    label: "EC003902: Cape"
  },
  {
    classId: "EC003903",
    classGroup: "EG020004",
    artClassDesc: "Accessories/spare parts for emergency exit route security",
    artClassVersion: 2,
    label: "EC003903: Accessories/spare parts for emergency exit route security"
  },
  {
    classId: "EC003904",
    classGroup: "EG020450",
    artClassDesc: "Road marking paint",
    artClassVersion: 2,
    label: "EC003904: Road marking paint"
  },
  {
    classId: "EC003906",
    classGroup: "EG020250",
    artClassDesc: "Wood care product",
    artClassVersion: 2,
    label: "EC003906: Wood care product"
  },
  {
    classId: "EC003907",
    classGroup: "EG012000",
    artClassDesc: "Covering sheet/fleece",
    artClassVersion: 2,
    label: "EC003907: Covering sheet/fleece"
  },
  {
    classId: "EC003908",
    classGroup: "EG020150",
    artClassDesc: "Dust protection door",
    artClassVersion: 2,
    label: "EC003908: Dust protection door"
  },
  {
    classId: "EC003909",
    classGroup: "EG010220",
    artClassDesc: "Cleaning set",
    artClassVersion: 1,
    label: "EC003909: Cleaning set"
  },
  {
    classId: "EC003910",
    classGroup: "EG017960",
    artClassDesc: "Cable bushing for furnitures",
    artClassVersion: 2,
    label: "EC003910: Cable bushing for furnitures"
  },
  {
    classId: "EC003911",
    classGroup: "EG020004",
    artClassDesc: "Warded lock insert for profile cylinder lock",
    artClassVersion: 2,
    label: "EC003911: Warded lock insert for profile cylinder lock"
  },
  {
    classId: "EC003912",
    classGroup: "EG020009",
    artClassDesc: "Jagged strip",
    artClassVersion: 2,
    label: "EC003912: Jagged strip"
  },
  {
    classId: "EC003913",
    classGroup: "EG020009",
    artClassDesc: "Grille locking device",
    artClassVersion: 2,
    label: "EC003913: Grille locking device"
  },
  {
    classId: "EC003914",
    classGroup: "EG020004",
    artClassDesc: "Pneumatic door opener",
    artClassVersion: 2,
    label: "EC003914: Pneumatic door opener"
  },
  {
    classId: "EC003915",
    classGroup: "EG020006",
    artClassDesc: "Paper-/foil dispenser",
    artClassVersion: 2,
    label: "EC003915: Paper-/foil dispenser"
  },
  {
    classId: "EC003916",
    classGroup: "EG020000",
    artClassDesc: "Sweeping machine, hand operated (battery)",
    artClassVersion: 2,
    label: "EC003916: Sweeping machine, hand operated (battery)"
  },
  {
    classId: "EC003917",
    classGroup: "EG020000",
    artClassDesc: "Accessories/spare parts for sweeping machine",
    artClassVersion: 2,
    label: "EC003917: Accessories/spare parts for sweeping machine"
  },
  {
    classId: "EC003918",
    classGroup: "EG020000",
    artClassDesc: "Sweeping machine, hand operated (electric)",
    artClassVersion: 2,
    label: "EC003918: Sweeping machine, hand operated (electric)"
  },
  {
    classId: "EC003919",
    classGroup: "EG020000",
    artClassDesc: "Pre-wash device (automotive)",
    artClassVersion: 2,
    label: "EC003919: Pre-wash device (automotive)"
  },
  {
    classId: "EC003920",
    classGroup: "EG020000",
    artClassDesc: "Dry ice pelletizer",
    artClassVersion: 2,
    label: "EC003920: Dry ice pelletizer"
  },
  {
    classId: "EC003921",
    classGroup: "EG015920",
    artClassDesc: "Thrust bearing for transport wheels",
    artClassVersion: 2,
    label: "EC003921: Thrust bearing for transport wheels"
  },
  {
    classId: "EC003922",
    classGroup: "EG020002",
    artClassDesc: "Milling cutter set",
    artClassVersion: 2,
    label: "EC003922: Milling cutter set"
  },
  {
    classId: "EC003923",
    classGroup: "EG020002",
    artClassDesc: "Accessories/spare parts for milling cutter",
    artClassVersion: 2,
    label: "EC003923: Accessories/spare parts for milling cutter"
  },
  {
    classId: "EC003925",
    classGroup: "EG020019",
    artClassDesc: "Lathe tool",
    artClassVersion: 2,
    label: "EC003925: Lathe tool"
  },
  {
    classId: "EC003926",
    classGroup: "EG000050",
    artClassDesc: "Slide hammer",
    artClassVersion: 2,
    label: "EC003926: Slide hammer"
  },
  {
    classId: "EC003927",
    classGroup: "EG017910",
    artClassDesc: "Waterproof covering for bathroom",
    artClassVersion: 2,
    label: "EC003927: Waterproof covering for bathroom"
  },
  {
    classId: "EC003928",
    classGroup: "EG000036",
    artClassDesc: "AV control device",
    artClassVersion: 2,
    label: "EC003928: AV control device"
  },
  {
    classId: "EC003929",
    classGroup: "EG000049",
    artClassDesc: "Headed grooved pin",
    artClassVersion: 1,
    label: "EC003929: Headed grooved pin"
  },
  {
    classId: "EC003932",
    classGroup: "EG000050",
    artClassDesc: "Tube water level",
    artClassVersion: 2,
    label: "EC003932: Tube water level"
  },
  {
    classId: "EC003933",
    classGroup: "EG020006",
    artClassDesc: "Office/laboratory scale",
    artClassVersion: 1,
    label: "EC003933: Office/laboratory scale"
  },
  {
    classId: "EC003934",
    classGroup: "EG020006",
    artClassDesc: "Display panel",
    artClassVersion: 2,
    label: "EC003934: Display panel"
  },
  {
    classId: "EC003935",
    classGroup: "EG020006",
    artClassDesc: "Machine toggle clamp",
    artClassVersion: 2,
    label: "EC003935: Machine toggle clamp"
  },
  {
    classId: "EC003936",
    classGroup: "EG000038",
    artClassDesc: "Comb for punching/binding device",
    artClassVersion: 2,
    label: "EC003936: Comb for punching/binding device"
  },
  {
    classId: "EC003937",
    classGroup: "EG000038",
    artClassDesc: "Money bill tester",
    artClassVersion: 2,
    label: "EC003937: Money bill tester"
  },
  {
    classId: "EC003938",
    classGroup: "EG000038",
    artClassDesc: "Laminator cartridge for laminating device",
    artClassVersion: 2,
    label: "EC003938: Laminator cartridge for laminating device"
  },
  {
    classId: "EC003939",
    classGroup: "EG000038",
    artClassDesc: "Foil bag for laminating device",
    artClassVersion: 2,
    label: "EC003939: Foil bag for laminating device"
  },
  {
    classId: "EC003940",
    classGroup: "EG000028",
    artClassDesc: "Vehicle lamp set",
    artClassVersion: 2,
    label: "EC003940: Vehicle lamp set"
  },
  {
    classId: "EC003941",
    classGroup: "EG019480",
    artClassDesc: "Vehicle lock",
    artClassVersion: 1,
    label: "EC003941: Vehicle lock"
  },
  {
    classId: "EC003942",
    classGroup: "EG020010",
    artClassDesc: "Watering can",
    artClassVersion: 2,
    label: "EC003942: Watering can"
  },
  {
    classId: "EC003943",
    classGroup: "EG000051",
    artClassDesc: "Wood splitter (electric)",
    artClassVersion: 2,
    label: "EC003943: Wood splitter (electric)"
  },
  {
    classId: "EC003944",
    classGroup: "EG020010",
    artClassDesc: "Metal wire",
    artClassVersion: 2,
    label: "EC003944: Metal wire"
  },
  {
    classId: "EC003946",
    classGroup: "EG020000",
    artClassDesc: "Accessories/spare parts for pallets",
    artClassVersion: 2,
    label: "EC003946: Accessories/spare parts for pallets"
  },
  {
    classId: "EC003947",
    classGroup: "EG020000",
    artClassDesc: "Packing container",
    artClassVersion: 2,
    label: "EC003947: Packing container"
  },
  {
    classId: "EC003948",
    classGroup: "EG020000",
    artClassDesc: "Stacking corner",
    artClassVersion: 2,
    label: "EC003948: Stacking corner"
  },
  {
    classId: "EC003949",
    classGroup: "EG000051",
    artClassDesc: "Grease gun (battery)",
    artClassVersion: 1,
    label: "EC003949: Grease gun (battery)"
  },
  {
    classId: "EC003950",
    classGroup: "EG000051",
    artClassDesc: "Triangular base-plate grinder (battery)",
    artClassVersion: 1,
    label: "EC003950: Triangular base-plate grinder (battery)"
  },
  {
    classId: "EC003951",
    classGroup: "EG000051",
    artClassDesc: "Belt sander (battery)",
    artClassVersion: 2,
    label: "EC003951: Belt sander (battery)"
  },
  {
    classId: "EC003952",
    classGroup: "EG000051",
    artClassDesc: "Paint roller (electric)",
    artClassVersion: 2,
    label: "EC003952: Paint roller (electric)"
  },
  {
    classId: "EC003953",
    classGroup: "EG000051",
    artClassDesc: "Edgebanding device (electric)",
    artClassVersion: 2,
    label: "EC003953: Edgebanding device (electric)"
  },
  {
    classId: "EC003955",
    classGroup: "EG000051",
    artClassDesc: "Long reach sander (electric)",
    artClassVersion: 2,
    label: "EC003955: Long reach sander (electric)"
  },
  {
    classId: "EC003956",
    classGroup: "EG000050",
    artClassDesc: "Air pump (hand/foot)",
    artClassVersion: 1,
    label: "EC003956: Air pump (hand/foot)"
  },
  {
    classId: "EC003957",
    classGroup: "EG000050",
    artClassDesc: "Lever bar",
    artClassVersion: 2,
    label: "EC003957: Lever bar"
  },
  {
    classId: "EC003958",
    classGroup: "EG020010",
    artClassDesc: "Mower (hand)",
    artClassVersion: 2,
    label: "EC003958: Mower (hand)"
  },
  {
    classId: "EC003959",
    classGroup: "EG020010",
    artClassDesc: "Tree pruner",
    artClassVersion: 2,
    label: "EC003959: Tree pruner"
  },
  {
    classId: "EC003960",
    classGroup: "EG000050",
    artClassDesc: "Drawknife",
    artClassVersion: 1,
    label: "EC003960: Drawknife"
  },
  {
    classId: "EC003962",
    classGroup: "EG010140",
    artClassDesc: "Guillotine table shear",
    artClassVersion: 2,
    label: "EC003962: Guillotine table shear"
  },
  {
    classId: "EC003963",
    classGroup: "EG020010",
    artClassDesc: "Garden scissors (hand)",
    artClassVersion: 2,
    label: "EC003963: Garden scissors (hand)"
  },
  {
    classId: "EC003964",
    classGroup: "EG000050",
    artClassDesc: "Knife set",
    artClassVersion: 2,
    label: "EC003964: Knife set"
  },
  {
    classId: "EC003965",
    classGroup: "EG000044",
    artClassDesc: "Protractor",
    artClassVersion: 1,
    label: "EC003965: Protractor"
  },
  {
    classId: "EC003966",
    classGroup: "EG020010",
    artClassDesc: "Garden well",
    artClassVersion: 2,
    label: "EC003966: Garden well"
  },
  {
    classId: "EC003967",
    classGroup: "EG020010",
    artClassDesc: "Garden door/gate",
    artClassVersion: 2,
    label: "EC003967: Garden door/gate"
  },
  {
    classId: "EC003968",
    classGroup: "EG020010",
    artClassDesc: "Ground anchor sleeve",
    artClassVersion: 2,
    label: "EC003968: Ground anchor sleeve"
  },
  {
    classId: "EC003969",
    classGroup: "EG020010",
    artClassDesc: "Fence fitting",
    artClassVersion: 2,
    label: "EC003969: Fence fitting"
  },
  {
    classId: "EC003970",
    classGroup: "EG020010",
    artClassDesc: "Fence post",
    artClassVersion: 2,
    label: "EC003970: Fence post"
  },
  {
    classId: "EC003971",
    classGroup: "EG020010",
    artClassDesc: "Fence",
    artClassVersion: 2,
    label: "EC003971: Fence"
  },
  {
    classId: "EC003972",
    classGroup: "EG020010",
    artClassDesc: "Strut for fence post",
    artClassVersion: 2,
    label: "EC003972: Strut for fence post"
  },
  {
    classId: "EC003973",
    classGroup: "EG020011",
    artClassDesc: "Microscope",
    artClassVersion: 2,
    label: "EC003973: Microscope"
  },
  {
    classId: "EC003974",
    classGroup: "EG020011",
    artClassDesc: "Accessories/spare parts for microscope",
    artClassVersion: 2,
    label: "EC003974: Accessories/spare parts for microscope"
  },
  {
    classId: "EC003975",
    classGroup: "EG020011",
    artClassDesc: "Scale scoop",
    artClassVersion: 2,
    label: "EC003975: Scale scoop"
  },
  {
    classId: "EC003976",
    classGroup: "EG020011",
    artClassDesc: "Hand sprayer",
    artClassVersion: 2,
    label: "EC003976: Hand sprayer"
  },
  {
    classId: "EC003977",
    classGroup: "EG020010",
    artClassDesc: "Bicycle rack",
    artClassVersion: 2,
    label: "EC003977: Bicycle rack"
  },
  {
    classId: "EC003979",
    classGroup: "EG020010",
    artClassDesc: "Accessories/spare parts for flagpole",
    artClassVersion: 2,
    label: "EC003979: Accessories/spare parts for flagpole"
  },
  {
    classId: "EC003980",
    classGroup: "EG020010",
    artClassDesc: "Cycle storage",
    artClassVersion: 2,
    label: "EC003980: Cycle storage"
  },
  {
    classId: "EC003981",
    classGroup: "EG020010",
    artClassDesc: "Door scraper",
    artClassVersion: 2,
    label: "EC003981: Door scraper"
  },
  {
    classId: "EC003982",
    classGroup: "EG020006",
    artClassDesc: "Paper block",
    artClassVersion: 2,
    label: "EC003982: Paper block"
  },
  {
    classId: "EC003983",
    classGroup: "EG020006",
    artClassDesc: "Flip chart",
    artClassVersion: 2,
    label: "EC003983: Flip chart"
  },
  {
    classId: "EC003984",
    classGroup: "EG010340",
    artClassDesc: "Connecting hose for compressed air tool",
    artClassVersion: 2,
    label: "EC003984: Connecting hose for compressed air tool"
  },
  {
    classId: "EC003985",
    classGroup: "EG020006",
    artClassDesc: "Valuable box",
    artClassVersion: 2,
    label: "EC003985: Valuable box"
  },
  {
    classId: "EC003986",
    classGroup: "EG020006",
    artClassDesc: "Accessories/spare parts for valuable boxes/safes",
    artClassVersion: 2,
    label: "EC003986: Accessories/spare parts for valuable boxes/safes"
  },
  {
    classId: "EC003988",
    classGroup: "EG000050",
    artClassDesc: "Cartridge spanner",
    artClassVersion: 2,
    label: "EC003988: Cartridge spanner"
  },
  {
    classId: "EC003989",
    classGroup: "EG000050",
    artClassDesc: "Oil service wrench set",
    artClassVersion: 2,
    label: "EC003989: Oil service wrench set"
  },
  {
    classId: "EC003990",
    classGroup: "EG000050",
    artClassDesc: "Roller wrench",
    artClassVersion: 2,
    label: "EC003990: Roller wrench"
  },
  {
    classId: "EC003991",
    classGroup: "EG000050",
    artClassDesc: "Spanner/socket wrench",
    artClassVersion: 2,
    label: "EC003991: Spanner/socket wrench"
  },
  {
    classId: "EC003993",
    classGroup: "EG000050",
    artClassDesc: "Lug wrench",
    artClassVersion: 2,
    label: "EC003993: Lug wrench"
  },
  {
    classId: "EC003994",
    classGroup: "EG010110",
    artClassDesc: "ISO tolerance key",
    artClassVersion: 1,
    label: "EC003994: ISO tolerance key"
  },
  {
    classId: "EC003995",
    classGroup: "EG017960",
    artClassDesc: "Room divider",
    artClassVersion: 2,
    label: "EC003995: Room divider"
  },
  {
    classId: "EC003996",
    classGroup: "EG020000",
    artClassDesc: "Plunge reservoir (industry)",
    artClassVersion: 2,
    label: "EC003996: Plunge reservoir (industry)"
  },
  {
    classId: "EC003997",
    classGroup: "EG020000",
    artClassDesc: "Heavy duty scale",
    artClassVersion: 2,
    label: "EC003997: Heavy duty scale"
  },
  {
    classId: "EC003998",
    classGroup: "EG020000",
    artClassDesc: "Accessories/spare parts for scales",
    artClassVersion: 2,
    label: "EC003998: Accessories/spare parts for scales"
  },
  {
    classId: "EC003999",
    classGroup: "EG000051",
    artClassDesc: "Escalator cleaning device (electric)",
    artClassVersion: 2,
    label: "EC003999: Escalator cleaning device (electric)"
  },
  {
    classId: "EC004000",
    classGroup: "EG000051",
    artClassDesc: "Stair cleaning device (electric)",
    artClassVersion: 2,
    label: "EC004000: Stair cleaning device (electric)"
  },
  {
    classId: "EC004001",
    classGroup: "EG020000",
    artClassDesc: "Ride-on floor scrubber",
    artClassVersion: 2,
    label: "EC004001: Ride-on floor scrubber"
  },
  {
    classId: "EC004002",
    classGroup: "EG020000",
    artClassDesc: "Hand-held floor scrubber (battery)",
    artClassVersion: 2,
    label: "EC004002: Hand-held floor scrubber (battery)"
  },
  {
    classId: "EC004003",
    classGroup: "EG020000",
    artClassDesc: "Hand-held scrubbing vacuum cleaner (electric)",
    artClassVersion: 2,
    label: "EC004003: Hand-held scrubbing vacuum cleaner (electric)"
  },
  {
    classId: "EC004004",
    classGroup: "EG020000",
    artClassDesc: "Pallet",
    artClassVersion: 2,
    label: "EC004004: Pallet"
  },
  {
    classId: "EC004006",
    classGroup: "EG000051",
    artClassDesc: "Decalcifying pump (electric)",
    artClassVersion: 2,
    label: "EC004006: Decalcifying pump (electric)"
  },
  {
    classId: "EC004007",
    classGroup: "EG000051",
    artClassDesc: "Wet and dry vacuum cleaner (battery)",
    artClassVersion: 2,
    label: "EC004007: Wet and dry vacuum cleaner (battery)"
  },
  {
    classId: "EC004008",
    classGroup: "EG020000",
    artClassDesc: "Lathe",
    artClassVersion: 2,
    label: "EC004008: Lathe"
  },
  {
    classId: "EC004009",
    classGroup: "EG020000",
    artClassDesc: "Accessories/spare parts for waste bins",
    artClassVersion: 2,
    label: "EC004009: Accessories/spare parts for waste bins"
  },
  {
    classId: "EC004010",
    classGroup: "EG000050",
    artClassDesc: "Concrete spreader",
    artClassVersion: 2,
    label: "EC004010: Concrete spreader"
  },
  {
    classId: "EC004011",
    classGroup: "EG020000",
    artClassDesc: "Perforated backboard for workshop",
    artClassVersion: 2,
    label: "EC004011: Perforated backboard for workshop"
  },
  {
    classId: "EC004012",
    classGroup: "EG000050",
    artClassDesc: "Stickleback rasp",
    artClassVersion: 2,
    label: "EC004012: Stickleback rasp"
  },
  {
    classId: "EC004013",
    classGroup: "EG000050",
    artClassDesc: "Mitre saw (hand)",
    artClassVersion: 2,
    label: "EC004013: Mitre saw (hand)"
  },
  {
    classId: "EC004014",
    classGroup: "EG000050",
    artClassDesc: "Fret saw table",
    artClassVersion: 2,
    label: "EC004014: Fret saw table"
  },
  {
    classId: "EC004015",
    classGroup: "EG000050",
    artClassDesc: "Screw saw",
    artClassVersion: 2,
    label: "EC004015: Screw saw"
  },
  {
    classId: "EC004016",
    classGroup: "EG020002",
    artClassDesc: "Chainsaw bar",
    artClassVersion: 1,
    label: "EC004016: Chainsaw bar"
  },
  {
    classId: "EC004017",
    classGroup: "EG000051",
    artClassDesc: "Chainsaw sharpening device (electric)",
    artClassVersion: 2,
    label: "EC004017: Chainsaw sharpening device (electric)"
  },
  {
    classId: "EC004018",
    classGroup: "EG000051",
    artClassDesc: "Scroll saw (electric)",
    artClassVersion: 2,
    label: "EC004018: Scroll saw (electric)"
  },
  {
    classId: "EC004019",
    classGroup: "EG000051",
    artClassDesc: "Circular sawing machine, stationary (electric)",
    artClassVersion: 2,
    label: "EC004019: Circular sawing machine, stationary (electric)"
  },
  {
    classId: "EC004020",
    classGroup: "EG000051",
    artClassDesc: "Veneer sawing machine (electric)",
    artClassVersion: 2,
    label: "EC004020: Veneer sawing machine (electric)"
  },
  {
    classId: "EC004021",
    classGroup: "EG000051",
    artClassDesc: "Glazing bead sawing machine (electric)",
    artClassVersion: 2,
    label: "EC004021: Glazing bead sawing machine (electric)"
  },
  {
    classId: "EC004023",
    classGroup: "EG000051",
    artClassDesc: "Radial arm saw (electric)",
    artClassVersion: 2,
    label: "EC004023: Radial arm saw (electric)"
  },
  {
    classId: "EC004024",
    classGroup: "EG000051",
    artClassDesc: "Panel dividing sawing machine (electric)",
    artClassVersion: 2,
    label: "EC004024: Panel dividing sawing machine (electric)"
  },
  {
    classId: "EC004025",
    classGroup: "EG000051",
    artClassDesc: "Longitudinal circular saw (electric)",
    artClassVersion: 2,
    label: "EC004025: Longitudinal circular saw (electric)"
  },
  {
    classId: "EC004026",
    classGroup: "EG000051",
    artClassDesc: "Undercut chop saw machine (electric)",
    artClassVersion: 2,
    label: "EC004026: Undercut chop saw machine (electric)"
  },
  {
    classId: "EC004027",
    classGroup: "EG000051",
    artClassDesc: "Multi-blade saw (electric)",
    artClassVersion: 2,
    label: "EC004027: Multi-blade saw (electric)"
  },
  {
    classId: "EC004028",
    classGroup: "EG020002",
    artClassDesc: "Handle for power tools",
    artClassVersion: 1,
    label: "EC004028: Handle for power tools"
  },
  {
    classId: "EC004029",
    classGroup: "EG020002",
    artClassDesc: "Filter for power tools",
    artClassVersion: 1,
    label: "EC004029: Filter for power tools"
  },
  {
    classId: "EC004030",
    classGroup: "EG020002",
    artClassDesc: "Dryer for compressed air system",
    artClassVersion: 2,
    label: "EC004030: Dryer for compressed air system"
  },
  {
    classId: "EC004031",
    classGroup: "EG020002",
    artClassDesc: "Condensate separator (compressed air)",
    artClassVersion: 2,
    label: "EC004031: Condensate separator (compressed air)"
  },
  {
    classId: "EC004033",
    classGroup: "EG020002",
    artClassDesc: "Compressed air filter",
    artClassVersion: 2,
    label: "EC004033: Compressed air filter"
  },
  {
    classId: "EC004034",
    classGroup: "EG000051",
    artClassDesc: "File (compressed air)",
    artClassVersion: 2,
    label: "EC004034: File (compressed air)"
  },
  {
    classId: "EC004035",
    classGroup: "EG020002",
    artClassDesc: "Grease gun (compressed air)",
    artClassVersion: 2,
    label: "EC004035: Grease gun (compressed air)"
  },
  {
    classId: "EC004036",
    classGroup: "EG000051",
    artClassDesc: "Sand blasting gun (compressed air)",
    artClassVersion: 1,
    label: "EC004036: Sand blasting gun (compressed air)"
  },
  {
    classId: "EC004037",
    classGroup: "EG000051",
    artClassDesc: "Rivet tool (compressed air)",
    artClassVersion: 2,
    label: "EC004037: Rivet tool (compressed air)"
  },
  {
    classId: "EC004038",
    classGroup: "EG000051",
    artClassDesc: "Surface-/thickness planing machine",
    artClassVersion: 2,
    label: "EC004038: Surface-/thickness planing machine"
  },
  {
    classId: "EC004039",
    classGroup: "EG000043",
    artClassDesc: "Infrared radiation panel",
    artClassVersion: 2,
    label: "EC004039: Infrared radiation panel"
  },
  {
    classId: "EC004040",
    classGroup: "EG020400",
    artClassDesc: "Parquet floor board",
    artClassVersion: 2,
    label: "EC004040: Parquet floor board"
  },
  {
    classId: "EC004041",
    classGroup: "EG020400",
    artClassDesc: "Laminate floor board",
    artClassVersion: 2,
    label: "EC004041: Laminate floor board"
  },
  {
    classId: "EC004042",
    classGroup: "EG017960",
    artClassDesc: "Furniture connecting fitting",
    artClassVersion: 2,
    label: "EC004042: Furniture connecting fitting"
  },
  {
    classId: "EC004043",
    classGroup: "EG020012",
    artClassDesc: "Foot leveling screw",
    artClassVersion: 2,
    label: "EC004043: Foot leveling screw"
  },
  {
    classId: "EC004044",
    classGroup: "EG015560",
    artClassDesc: "Pass piece air duct",
    artClassVersion: 1,
    label: "EC004044: Pass piece air duct"
  },
  {
    classId: "EC004047",
    classGroup: "EG000051",
    artClassDesc: "Pipe beveller (compressed air)",
    artClassVersion: 2,
    label: "EC004047: Pipe beveller (compressed air)"
  },
  {
    classId: "EC004048",
    classGroup: "EG000051",
    artClassDesc: "Punch- and flanging tool (compressed air)",
    artClassVersion: 2,
    label: "EC004048: Punch- and flanging tool (compressed air)"
  },
  {
    classId: "EC004049",
    classGroup: "EG000051",
    artClassDesc: "Spot weld cutter (compressed air)",
    artClassVersion: 2,
    label: "EC004049: Spot weld cutter (compressed air)"
  },
  {
    classId: "EC004050",
    classGroup: "EG000051",
    artClassDesc: "Groove cutter (compressed air)",
    artClassVersion: 2,
    label: "EC004050: Groove cutter (compressed air)"
  },
  {
    classId: "EC004051",
    classGroup: "EG000051",
    artClassDesc: "Tire inflation gun (compressed air)",
    artClassVersion: 2,
    label: "EC004051: Tire inflation gun (compressed air)"
  },
  {
    classId: "EC004052",
    classGroup: "EG000051",
    artClassDesc: "Kit gun (compressed air)",
    artClassVersion: 2,
    label: "EC004052: Kit gun (compressed air)"
  },
  {
    classId: "EC004053",
    classGroup: "EG000051",
    artClassDesc: "Washing gun (compressed air)",
    artClassVersion: 2,
    label: "EC004053: Washing gun (compressed air)"
  },
  {
    classId: "EC004054",
    classGroup: "EG020014",
    artClassDesc: "Spray gun (compressed air)",
    artClassVersion: 2,
    label: "EC004054: Spray gun (compressed air)"
  },
  {
    classId: "EC004055",
    classGroup: "EG000051",
    artClassDesc: "Air blowgun (compressed air)",
    artClassVersion: 1,
    label: "EC004055: Air blowgun (compressed air)"
  },
  {
    classId: "EC004057",
    classGroup: "EG020002",
    artClassDesc: "Accessories/spare parts for drill stand",
    artClassVersion: 2,
    label: "EC004057: Accessories/spare parts for drill stand"
  },
  {
    classId: "EC004058",
    classGroup: "EG000050",
    artClassDesc: "Magnetic bowl",
    artClassVersion: 1,
    label: "EC004058: Magnetic bowl"
  },
  {
    classId: "EC004059",
    classGroup: "EG000050",
    artClassDesc: "Load lifting magnet",
    artClassVersion: 2,
    label: "EC004059: Load lifting magnet"
  },
  {
    classId: "EC004060",
    classGroup: "EG000050",
    artClassDesc: "Sorting magnet",
    artClassVersion: 2,
    label: "EC004060: Sorting magnet"
  },
  {
    classId: "EC004061",
    classGroup: "EG020002",
    artClassDesc: "Machine shoe",
    artClassVersion: 2,
    label: "EC004061: Machine shoe"
  },
  {
    classId: "EC004062",
    classGroup: "EG015120",
    artClassDesc: "Climbing aid for poles/trees",
    artClassVersion: 1,
    label: "EC004062: Climbing aid for poles/trees"
  },
  {
    classId: "EC004063",
    classGroup: "EG015120",
    artClassDesc: "Accessories/spare parts for storage/transport box",
    artClassVersion: 2,
    label: "EC004063: Accessories/spare parts for storage/transport box"
  },
  {
    classId: "EC004064",
    classGroup: "EG010340",
    artClassDesc: "Shelf rack",
    artClassVersion: 1,
    label: "EC004064: Shelf rack"
  },
  {
    classId: "EC004065",
    classGroup: "EG015120",
    artClassDesc: "Dispensing device for liquids",
    artClassVersion: 1,
    label: "EC004065: Dispensing device for liquids"
  },
  {
    classId: "EC004066",
    classGroup: "EG010340",
    artClassDesc: "Pallet rack",
    artClassVersion: 2,
    label: "EC004066: Pallet rack"
  },
  {
    classId: "EC004067",
    classGroup: "EG010340",
    artClassDesc: "Cable drum rack",
    artClassVersion: 2,
    label: "EC004067: Cable drum rack"
  },
  {
    classId: "EC004068",
    classGroup: "EG010340",
    artClassDesc: "Cantilever rack",
    artClassVersion: 2,
    label: "EC004068: Cantilever rack"
  },
  {
    classId: "EC004069",
    classGroup: "EG010340",
    artClassDesc: "Storage bins rack",
    artClassVersion: 2,
    label: "EC004069: Storage bins rack"
  },
  {
    classId: "EC004070",
    classGroup: "EG010340",
    artClassDesc: "Profile storage rack",
    artClassVersion: 2,
    label: "EC004070: Profile storage rack"
  },
  {
    classId: "EC004071",
    classGroup: "EG010340",
    artClassDesc: "Panel rack",
    artClassVersion: 2,
    label: "EC004071: Panel rack"
  },
  {
    classId: "EC004072",
    classGroup: "EG010340",
    artClassDesc: "Frame (rack element)",
    artClassVersion: 2,
    label: "EC004072: Frame (rack element)"
  },
  {
    classId: "EC004073",
    classGroup: "EG010340",
    artClassDesc: "Ram protection (for rack element)",
    artClassVersion: 2,
    label: "EC004073: Ram protection (for rack element)"
  },
  {
    classId: "EC004074",
    classGroup: "EG010340",
    artClassDesc: "Base/base element (rack element)",
    artClassVersion: 2,
    label: "EC004074: Base/base element (rack element)"
  },
  {
    classId: "EC004075",
    classGroup: "EG010340",
    artClassDesc: "Side wall/partition (rack element)",
    artClassVersion: 2,
    label: "EC004075: Side wall/partition (rack element)"
  },
  {
    classId: "EC004076",
    classGroup: "EG010340",
    artClassDesc: "Bulk material basin (rack element)",
    artClassVersion: 2,
    label: "EC004076: Bulk material basin (rack element)"
  },
  {
    classId: "EC004077",
    classGroup: "EG010340",
    artClassDesc: "Door (rack element)",
    artClassVersion: 2,
    label: "EC004077: Door (rack element)"
  },
  {
    classId: "EC004078",
    classGroup: "EG010340",
    artClassDesc: "Depth support bar (rack element)",
    artClassVersion: 2,
    label: "EC004078: Depth support bar (rack element)"
  },
  {
    classId: "EC004079",
    classGroup: "EG010340",
    artClassDesc: "Base/foot (rack element)",
    artClassVersion: 2,
    label: "EC004079: Base/foot (rack element)"
  },
  {
    classId: "EC004080",
    classGroup: "EG010340",
    artClassDesc: "Suspension rail (rack element)",
    artClassVersion: 2,
    label: "EC004080: Suspension rail (rack element)"
  },
  {
    classId: "EC004081",
    classGroup: "EG010340",
    artClassDesc: "Drawer (rack element)",
    artClassVersion: 2,
    label: "EC004081: Drawer (rack element)"
  },
  {
    classId: "EC004082",
    classGroup: "EG010340",
    artClassDesc: "Shelf (rack element)",
    artClassVersion: 2,
    label: "EC004082: Shelf (rack element)"
  },
  {
    classId: "EC004083",
    classGroup: "EG010340",
    artClassDesc: "Rack pull-out shelf",
    artClassVersion: 2,
    label: "EC004083: Rack pull-out shelf"
  },
  {
    classId: "EC004084",
    classGroup: "EG010340",
    artClassDesc: "Cantilever (rack element)",
    artClassVersion: 2,
    label: "EC004084: Cantilever (rack element)"
  },
  {
    classId: "EC004085",
    classGroup: "EG020400",
    artClassDesc: "Solid wood floor board",
    artClassVersion: 2,
    label: "EC004085: Solid wood floor board"
  },
  {
    classId: "EC004086",
    classGroup: "EG020010",
    artClassDesc: "Garden hose nozzle",
    artClassVersion: 2,
    label: "EC004086: Garden hose nozzle"
  },
  {
    classId: "EC004087",
    classGroup: "EG015520",
    artClassDesc: "Hose clamp strip",
    artClassVersion: 1,
    label: "EC004087: Hose clamp strip"
  },
  {
    classId: "EC004088",
    classGroup: "EG020014",
    artClassDesc: "Sand blasting coupling (compressed air)",
    artClassVersion: 2,
    label: "EC004088: Sand blasting coupling (compressed air)"
  },
  {
    classId: "EC004089",
    classGroup: "EG015410",
    artClassDesc: "Hydraulic control station",
    artClassVersion: 2,
    label: "EC004089: Hydraulic control station"
  },
  {
    classId: "EC004090",
    classGroup: "EG015510",
    artClassDesc: "Flexible connecting hose (plastic/silicone/rubber core)",
    artClassVersion: 2,
    label: "EC004090: Flexible connecting hose (plastic/silicone/rubber core)"
  },
  {
    classId: "EC004091",
    classGroup: "EG015540",
    artClassDesc: "Flexible fitting with 3 connections",
    artClassVersion: 2,
    label: "EC004091: Flexible fitting with 3 connections"
  },
  {
    classId: "EC004092",
    classGroup: "EG015560",
    artClassDesc: "Air restrictor ring",
    artClassVersion: 1,
    label: "EC004092: Air restrictor ring"
  },
  {
    classId: "EC004093",
    classGroup: "EG000050",
    artClassDesc: "Earth nail extractor",
    artClassVersion: 2,
    label: "EC004093: Earth nail extractor"
  },
  {
    classId: "EC004094",
    classGroup: "EG000050",
    artClassDesc: "Felling lever",
    artClassVersion: 1,
    label: "EC004094: Felling lever"
  },
  {
    classId: "EC004095",
    classGroup: "EG000050",
    artClassDesc: "Garden joint scraper",
    artClassVersion: 2,
    label: "EC004095: Garden joint scraper"
  },
  {
    classId: "EC004096",
    classGroup: "EG000050",
    artClassDesc: "Garden trowel",
    artClassVersion: 2,
    label: "EC004096: Garden trowel"
  },
  {
    classId: "EC004097",
    classGroup: "EG000050",
    artClassDesc: "Bark peeler",
    artClassVersion: 2,
    label: "EC004097: Bark peeler"
  },
  {
    classId: "EC004098",
    classGroup: "EG000050",
    artClassDesc: "Scythe",
    artClassVersion: 2,
    label: "EC004098: Scythe"
  },
  {
    classId: "EC004099",
    classGroup: "EG000050",
    artClassDesc: "Weed puller",
    artClassVersion: 2,
    label: "EC004099: Weed puller"
  },
  {
    classId: "EC004100",
    classGroup: "EG000050",
    artClassDesc: "Vacuum lifter",
    artClassVersion: 2,
    label: "EC004100: Vacuum lifter"
  },
  {
    classId: "EC004101",
    classGroup: "EG000050",
    artClassDesc: "Toggle press (hand)",
    artClassVersion: 2,
    label: "EC004101: Toggle press (hand)"
  },
  {
    classId: "EC004102",
    classGroup: "EG000050",
    artClassDesc: "Accessories/spare parts for grease guns",
    artClassVersion: 1,
    label: "EC004102: Accessories/spare parts for grease guns"
  },
  {
    classId: "EC004103",
    classGroup: "EG010340",
    artClassDesc: "Element for storage partition",
    artClassVersion: 2,
    label: "EC004103: Element for storage partition"
  },
  {
    classId: "EC004104",
    classGroup: "EG010340",
    artClassDesc: "Post for storage partition",
    artClassVersion: 2,
    label: "EC004104: Post for storage partition"
  },
  {
    classId: "EC004105",
    classGroup: "EG010340",
    artClassDesc: "Door for storage partition",
    artClassVersion: 2,
    label: "EC004105: Door for storage partition"
  },
  {
    classId: "EC004106",
    classGroup: "EG010340",
    artClassDesc: "Glue dispenser",
    artClassVersion: 2,
    label: "EC004106: Glue dispenser"
  },
  {
    classId: "EC004107",
    classGroup: "EG020015",
    artClassDesc: "Accessories/spare parts for stairs and railings",
    artClassVersion: 2,
    label: "EC004107: Accessories/spare parts for stairs and railings"
  },
  {
    classId: "EC004108",
    classGroup: "EG020015",
    artClassDesc: "Stair nosing",
    artClassVersion: 2,
    label: "EC004108: Stair nosing"
  },
  {
    classId: "EC004109",
    classGroup: "EG020015",
    artClassDesc: "Stair rod",
    artClassVersion: 2,
    label: "EC004109: Stair rod"
  },
  {
    classId: "EC004110",
    classGroup: "EG010340",
    artClassDesc: "Industrial stairway",
    artClassVersion: 2,
    label: "EC004110: Industrial stairway"
  },
  {
    classId: "EC004111",
    classGroup: "EG020004",
    artClassDesc: "Cylinder/mortice key",
    artClassVersion: 2,
    label: "EC004111: Cylinder/mortice key"
  },
  {
    classId: "EC004113",
    classGroup: "EG020004",
    artClassDesc: "Accessories/spare parts for keys",
    artClassVersion: 2,
    label: "EC004113: Accessories/spare parts for keys"
  },
  {
    classId: "EC004114",
    classGroup: "EG015120",
    artClassDesc: "Accessories/spare parts for storage drums",
    artClassVersion: 2,
    label: "EC004114: Accessories/spare parts for storage drums"
  },
  {
    classId: "EC004115",
    classGroup: "EG000060",
    artClassDesc: "Curing agent",
    artClassVersion: 1,
    label: "EC004115: Curing agent"
  },
  {
    classId: "EC004116",
    classGroup: "EG000050",
    artClassDesc: "Pressure roller",
    artClassVersion: 2,
    label: "EC004116: Pressure roller"
  },
  {
    classId: "EC004117",
    classGroup: "EG000049",
    artClassDesc: "Adhesive pad",
    artClassVersion: 2,
    label: "EC004117: Adhesive pad"
  },
  {
    classId: "EC004118",
    classGroup: "EG010340",
    artClassDesc: "Electricity/compressed air distributor",
    artClassVersion: 2,
    label: "EC004118: Electricity/compressed air distributor"
  },
  {
    classId: "EC004119",
    classGroup: "EG010340",
    artClassDesc: "Carpenter's bench",
    artClassVersion: 1,
    label: "EC004119: Carpenter's bench"
  },
  {
    classId: "EC004120",
    classGroup: "EG010340",
    artClassDesc: "Mounting/gripping magnet",
    artClassVersion: 2,
    label: "EC004120: Mounting/gripping magnet"
  },
  {
    classId: "EC004122",
    classGroup: "EG000020",
    artClassDesc: "Strip fuse",
    artClassVersion: 1,
    label: "EC004122: Strip fuse"
  },
  {
    classId: "EC004123",
    classGroup: "EG000040",
    artClassDesc: "Water dispenser (electric)",
    artClassVersion: 2,
    label: "EC004123: Water dispenser (electric)"
  },
  {
    classId: "EC004124",
    classGroup: "EG000040",
    artClassDesc: "Accessories/spare parts for water dispenser (electric)",
    artClassVersion: 2,
    label: "EC004124: Accessories/spare parts for water dispenser (electric)"
  },
  {
    classId: "EC004125",
    classGroup: "EG010340",
    artClassDesc: "Sanitary container",
    artClassVersion: 2,
    label: "EC004125: Sanitary container"
  },
  {
    classId: "EC004126",
    classGroup: "EG010340",
    artClassDesc: "Large garbage container",
    artClassVersion: 1,
    label: "EC004126: Large garbage container"
  },
  {
    classId: "EC004127",
    classGroup: "EG010340",
    artClassDesc: "Waste bag holder",
    artClassVersion: 2,
    label: "EC004127: Waste bag holder"
  },
  {
    classId: "EC004128",
    classGroup: "EG010340",
    artClassDesc: "Skip container",
    artClassVersion: 2,
    label: "EC004128: Skip container"
  },
  {
    classId: "EC004129",
    classGroup: "EG015120",
    artClassDesc: "Hinged floor container",
    artClassVersion: 2,
    label: "EC004129: Hinged floor container"
  },
  {
    classId: "EC004130",
    classGroup: "EG017960",
    artClassDesc: "Office drawer block",
    artClassVersion: 1,
    label: "EC004130: Office drawer block"
  },
  {
    classId: "EC004131",
    classGroup: "EG010340",
    artClassDesc: "Storage container for hazardous material",
    artClassVersion: 2,
    label: "EC004131: Storage container for hazardous material"
  },
  {
    classId: "EC004132",
    classGroup: "EG020016",
    artClassDesc: "Rubble chute",
    artClassVersion: 1,
    label: "EC004132: Rubble chute"
  },
  {
    classId: "EC004133",
    classGroup: "EG020016",
    artClassDesc: "Accessories/spare parts for rubble chute",
    artClassVersion: 1,
    label: "EC004133: Accessories/spare parts for rubble chute"
  },
  {
    classId: "EC004134",
    classGroup: "EG015120",
    artClassDesc: "Drum lifting device",
    artClassVersion: 1,
    label: "EC004134: Drum lifting device"
  },
  {
    classId: "EC004135",
    classGroup: "EG015240",
    artClassDesc: "Ventilation valve adapter (air grid connection plenum)",
    artClassVersion: 2,
    label: "EC004135: Ventilation valve adapter (air grid connection plenum)"
  },
  {
    classId: "EC004136",
    classGroup: "EG015530",
    artClassDesc: "Clay pipe",
    artClassVersion: 1,
    label: "EC004136: Clay pipe"
  },
  {
    classId: "EC004137",
    classGroup: "EG015280",
    artClassDesc: "Accessories/spare parts for fireplace",
    artClassVersion: 2,
    label: "EC004137: Accessories/spare parts for fireplace"
  },
  {
    classId: "EC004138",
    classGroup: "EG015610",
    artClassDesc: "Bypass valve water softener",
    artClassVersion: 2,
    label: "EC004138: Bypass valve water softener"
  },
  {
    classId: "EC004139",
    classGroup: "EG000060",
    artClassDesc: "Distilled water",
    artClassVersion: 1,
    label: "EC004139: Distilled water"
  },
  {
    classId: "EC004140",
    classGroup: "EG000060",
    artClassDesc: "Liquid for vehicles",
    artClassVersion: 2,
    label: "EC004140: Liquid for vehicles"
  },
  {
    classId: "EC004141",
    classGroup: "EG020024",
    artClassDesc: "Jumper cable",
    artClassVersion: 2,
    label: "EC004141: Jumper cable"
  },
  {
    classId: "EC004142",
    classGroup: "EG000044",
    artClassDesc: "Adapter for test and measurement instruments",
    artClassVersion: 2,
    label: "EC004142: Adapter for test and measurement instruments"
  },
  {
    classId: "EC004143",
    classGroup: "EG020100",
    artClassDesc: "Belt winder for roller shutter",
    artClassVersion: 2,
    label: "EC004143: Belt winder for roller shutter"
  },
  {
    classId: "EC004144",
    classGroup: "EG019710",
    artClassDesc: "Transition-/adjustment profile",
    artClassVersion: 2,
    label: "EC004144: Transition-/adjustment profile"
  },
  {
    classId: "EC004146",
    classGroup: "EG020250",
    artClassDesc: "Abrasive",
    artClassVersion: 2,
    label: "EC004146: Abrasive"
  },
  {
    classId: "EC004148",
    classGroup: "EG020014",
    artClassDesc: "Sand blasting device",
    artClassVersion: 2,
    label: "EC004148: Sand blasting device"
  },
  {
    classId: "EC004149",
    classGroup: "EG000040",
    artClassDesc: "Carpet cleaning device (electric)",
    artClassVersion: 2,
    label: "EC004149: Carpet cleaning device (electric)"
  },
  {
    classId: "EC004150",
    classGroup: "EG000040",
    artClassDesc: "Accessories/spare parts for carpet cleaning device (electric)",
    artClassVersion: 2,
    label: "EC004150: Accessories/spare parts for carpet cleaning device (electric)"
  },
  {
    classId: "EC004152",
    classGroup: "EG000040",
    artClassDesc: "Ultrasonic cleaning device",
    artClassVersion: 2,
    label: "EC004152: Ultrasonic cleaning device"
  },
  {
    classId: "EC004153",
    classGroup: "EG020000",
    artClassDesc: "Dry ice blasting device",
    artClassVersion: 2,
    label: "EC004153: Dry ice blasting device"
  },
  {
    classId: "EC004154",
    classGroup: "EG020000",
    artClassDesc: "Accessories/spare parts for dry ice blasting device",
    artClassVersion: 2,
    label: "EC004154: Accessories/spare parts for dry ice blasting device"
  },
  {
    classId: "EC004155",
    classGroup: "EG020000",
    artClassDesc: "Self service vacuum cleaner (electric)",
    artClassVersion: 2,
    label: "EC004155: Self service vacuum cleaner (electric)"
  },
  {
    classId: "EC004156",
    classGroup: "EG000060",
    artClassDesc: "End collar for protective tube for plastic pipe system",
    artClassVersion: 1,
    label: "EC004156: End collar for protective tube for plastic pipe system"
  },
  {
    classId: "EC004157",
    classGroup: "EG020016",
    artClassDesc: "Storage and transport frame for traffic management/road signage",
    artClassVersion: 2,
    label: "EC004157: Storage and transport frame for traffic management/road signage"
  },
  {
    classId: "EC004158",
    classGroup: "EG000050",
    artClassDesc: "Carry strap",
    artClassVersion: 1,
    label: "EC004158: Carry strap"
  },
  {
    classId: "EC004159",
    classGroup: "EG000046",
    artClassDesc: "Self-defense product",
    artClassVersion: 2,
    label: "EC004159: Self-defense product"
  },
  {
    classId: "EC004161",
    classGroup: "EG010340",
    artClassDesc: "Furniture/equipment first aid",
    artClassVersion: 1,
    label: "EC004161: Furniture/equipment first aid"
  },
  {
    classId: "EC004162",
    classGroup: "EG000034",
    artClassDesc: "Accessories/spare parts for electrical gate operator",
    artClassVersion: 1,
    label: "EC004162: Accessories/spare parts for electrical gate operator"
  },
  {
    classId: "EC004164",
    classGroup: "EG015510",
    artClassDesc: "Accessories/spare parts for coolant hose for lathe/miller",
    artClassVersion: 2,
    label: "EC004164: Accessories/spare parts for coolant hose for lathe/miller"
  },
  {
    classId: "EC004166",
    classGroup: "EG015510",
    artClassDesc: "Garden hose quick connector",
    artClassVersion: 2,
    label: "EC004166: Garden hose quick connector"
  },
  {
    classId: "EC004167",
    classGroup: "EG020006",
    artClassDesc: "Lever cutter",
    artClassVersion: 2,
    label: "EC004167: Lever cutter"
  },
  {
    classId: "EC004168",
    classGroup: "EG020006",
    artClassDesc: "Office punch",
    artClassVersion: 2,
    label: "EC004168: Office punch"
  },
  {
    classId: "EC004169",
    classGroup: "EG020006",
    artClassDesc: "Ring binder",
    artClassVersion: 2,
    label: "EC004169: Ring binder"
  },
  {
    classId: "EC004170",
    classGroup: "EG020006",
    artClassDesc: "Magnetic wall strip",
    artClassVersion: 2,
    label: "EC004170: Magnetic wall strip"
  },
  {
    classId: "EC004171",
    classGroup: "EG020006",
    artClassDesc: "Thumbtack/push pin",
    artClassVersion: 2,
    label: "EC004171: Thumbtack/push pin"
  },
  {
    classId: "EC004172",
    classGroup: "EG020006",
    artClassDesc: "File folder",
    artClassVersion: 2,
    label: "EC004172: File folder"
  },
  {
    classId: "EC004173",
    classGroup: "EG020006",
    artClassDesc: "Pencil set",
    artClassVersion: 2,
    label: "EC004173: Pencil set"
  },
  {
    classId: "EC004174",
    classGroup: "EG020006",
    artClassDesc: "Brochure holder",
    artClassVersion: 2,
    label: "EC004174: Brochure holder"
  },
  {
    classId: "EC004175",
    classGroup: "EG020006",
    artClassDesc: "Divider sheet",
    artClassVersion: 2,
    label: "EC004175: Divider sheet"
  },
  {
    classId: "EC004176",
    classGroup: "EG020006",
    artClassDesc: "Swivelling arm system office",
    artClassVersion: 2,
    label: "EC004176: Swivelling arm system office"
  },
  {
    classId: "EC004177",
    classGroup: "EG020010",
    artClassDesc: "Accessories/spare parts for garden watering",
    artClassVersion: 2,
    label: "EC004177: Accessories/spare parts for garden watering"
  },
  {
    classId: "EC004178",
    classGroup: "EG020000",
    artClassDesc: "Drum pump (electric)",
    artClassVersion: 2,
    label: "EC004178: Drum pump (electric)"
  },
  {
    classId: "EC004179",
    classGroup: "EG020010",
    artClassDesc: "Garden watering set",
    artClassVersion: 2,
    label: "EC004179: Garden watering set"
  },
  {
    classId: "EC004180",
    classGroup: "EG020010",
    artClassDesc: "Tube well",
    artClassVersion: 2,
    label: "EC004180: Tube well"
  },
  {
    classId: "EC004181",
    classGroup: "EG015560",
    artClassDesc: "Air distribution box",
    artClassVersion: 2,
    label: "EC004181: Air distribution box"
  },
  {
    classId: "EC004182",
    classGroup: "EG000051",
    artClassDesc: "Drive unit for garden tools",
    artClassVersion: 2,
    label: "EC004182: Drive unit for garden tools"
  },
  {
    classId: "EC004183",
    classGroup: "EG000051",
    artClassDesc: "Garden tiller (electric)",
    artClassVersion: 2,
    label: "EC004183: Garden tiller (electric)"
  },
  {
    classId: "EC004184",
    classGroup: "EG000049",
    artClassDesc: "Cross dowel",
    artClassVersion: 2,
    label: "EC004184: Cross dowel"
  },
  {
    classId: "EC004186",
    classGroup: "EG000049",
    artClassDesc: "Insulation dowel",
    artClassVersion: 2,
    label: "EC004186: Insulation dowel"
  },
  {
    classId: "EC004187",
    classGroup: "EG010110",
    artClassDesc: "Calibration foil",
    artClassVersion: 1,
    label: "EC004187: Calibration foil"
  },
  {
    classId: "EC004188",
    classGroup: "EG015210",
    artClassDesc: "Oil nozzle",
    artClassVersion: 2,
    label: "EC004188: Oil nozzle"
  },
  {
    classId: "EC004189",
    classGroup: "EG000044",
    artClassDesc: "Electrochemical measuring instrument",
    artClassVersion: 1,
    label: "EC004189: Electrochemical measuring instrument"
  },
  {
    classId: "EC004191",
    classGroup: "EG015910",
    artClassDesc: "Window-sill seal",
    artClassVersion: 2,
    label: "EC004191: Window-sill seal"
  },
  {
    classId: "EC004192",
    classGroup: "EG015910",
    artClassDesc: "Gasket for doors/windows",
    artClassVersion: 2,
    label: "EC004192: Gasket for doors/windows"
  },
  {
    classId: "EC004195",
    classGroup: "EG020008",
    artClassDesc: "Window shutter hinge",
    artClassVersion: 2,
    label: "EC004195: Window shutter hinge"
  },
  {
    classId: "EC004196",
    classGroup: "EG020008",
    artClassDesc: "Handle for windows/door windows",
    artClassVersion: 2,
    label: "EC004196: Handle for windows/door windows"
  },
  {
    classId: "EC004198",
    classGroup: "EG020008",
    artClassDesc: "Gear for windows/door windows",
    artClassVersion: 2,
    label: "EC004198: Gear for windows/door windows"
  },
  {
    classId: "EC004199",
    classGroup: "EG020008",
    artClassDesc: "Accessories/spare parts for windows/door windows",
    artClassVersion: 2,
    label: "EC004199: Accessories/spare parts for windows/door windows"
  },
  {
    classId: "EC004200",
    classGroup: "EG020008",
    artClassDesc: "Ground sill for windows/door windows",
    artClassVersion: 2,
    label: "EC004200: Ground sill for windows/door windows"
  },
  {
    classId: "EC004201",
    classGroup: "EG020008",
    artClassDesc: "Scissors for windows/door windows",
    artClassVersion: 2,
    label: "EC004201: Scissors for windows/door windows"
  },
  {
    classId: "EC004202",
    classGroup: "EG020008",
    artClassDesc: "Integrated window ventilator",
    artClassVersion: 2,
    label: "EC004202: Integrated window ventilator"
  },
  {
    classId: "EC004205",
    classGroup: "EG020012",
    artClassDesc: "Bed connection fitting",
    artClassVersion: 2,
    label: "EC004205: Bed connection fitting"
  },
  {
    classId: "EC004206",
    classGroup: "EG020012",
    artClassDesc: "Drive-in nut",
    artClassVersion: 2,
    label: "EC004206: Drive-in nut"
  },
  {
    classId: "EC004207",
    classGroup: "EG020012",
    artClassDesc: "Bed adjustment fitting",
    artClassVersion: 2,
    label: "EC004207: Bed adjustment fitting"
  },
  {
    classId: "EC004209",
    classGroup: "EG020012",
    artClassDesc: "Shelf support rail",
    artClassVersion: 2,
    label: "EC004209: Shelf support rail"
  },
  {
    classId: "EC004210",
    classGroup: "EG020012",
    artClassDesc: "Furniture console",
    artClassVersion: 2,
    label: "EC004210: Furniture console"
  },
  {
    classId: "EC004211",
    classGroup: "EG020012",
    artClassDesc: "Stop strip for furniture locks",
    artClassVersion: 2,
    label: "EC004211: Stop strip for furniture locks"
  },
  {
    classId: "EC004212",
    classGroup: "EG019710",
    artClassDesc: "Wall jointing profile",
    artClassVersion: 2,
    label: "EC004212: Wall jointing profile"
  },
  {
    classId: "EC004213",
    classGroup: "EG020012",
    artClassDesc: "Flat angle corner brace",
    artClassVersion: 2,
    label: "EC004213: Flat angle corner brace"
  },
  {
    classId: "EC004214",
    classGroup: "EG020012",
    artClassDesc: "Flat brace",
    artClassVersion: 2,
    label: "EC004214: Flat brace"
  },
  {
    classId: "EC004215",
    classGroup: "EG000044",
    artClassDesc: "Low frequency function generator",
    artClassVersion: 1,
    label: "EC004215: Low frequency function generator"
  },
  {
    classId: "EC004217",
    classGroup: "EG015260",
    artClassDesc: "Wind driven ventilation",
    artClassVersion: 2,
    label: "EC004217: Wind driven ventilation"
  },
  {
    classId: "EC004218",
    classGroup: "EG020012",
    artClassDesc: "Special screw for furnitures",
    artClassVersion: 2,
    label: "EC004218: Special screw for furnitures"
  },
  {
    classId: "EC004220",
    classGroup: "EG020012",
    artClassDesc: "Accessories/spare parts for sliding door fitting (furniture)",
    artClassVersion: 2,
    label: "EC004220: Accessories/spare parts for sliding door fitting (furniture)"
  },
  {
    classId: "EC004221",
    classGroup: "EG017960",
    artClassDesc: "Furniture interior equipment",
    artClassVersion: 2,
    label: "EC004221: Furniture interior equipment"
  },
  {
    classId: "EC004223",
    classGroup: "EG020012",
    artClassDesc: "Gallery rail element for furniture",
    artClassVersion: 2,
    label: "EC004223: Gallery rail element for furniture"
  },
  {
    classId: "EC004224",
    classGroup: "EG019480",
    artClassDesc: "Accessories/spare parts for locks",
    artClassVersion: 2,
    label: "EC004224: Accessories/spare parts for locks"
  },
  {
    classId: "EC004227",
    classGroup: "EG019480",
    artClassDesc: "Gate lock",
    artClassVersion: 2,
    label: "EC004227: Gate lock"
  },
  {
    classId: "EC004228",
    classGroup: "EG020012",
    artClassDesc: "Furniture castor",
    artClassVersion: 2,
    label: "EC004228: Furniture castor"
  },
  {
    classId: "EC004229",
    classGroup: "EG017960",
    artClassDesc: "Furniture/table frame",
    artClassVersion: 2,
    label: "EC004229: Furniture/table frame"
  },
  {
    classId: "EC004230",
    classGroup: "EG020012",
    artClassDesc: "Furniture slider",
    artClassVersion: 2,
    label: "EC004230: Furniture slider"
  },
  {
    classId: "EC004231",
    classGroup: "EG020012",
    artClassDesc: "Furniture foot",
    artClassVersion: 2,
    label: "EC004231: Furniture foot"
  },
  {
    classId: "EC004232",
    classGroup: "EG020012",
    artClassDesc: "Stop damper for furnitures",
    artClassVersion: 2,
    label: "EC004232: Stop damper for furnitures"
  },
  {
    classId: "EC004233",
    classGroup: "EG020012",
    artClassDesc: "Furniture base mounting element",
    artClassVersion: 2,
    label: "EC004233: Furniture base mounting element"
  },
  {
    classId: "EC004239",
    classGroup: "EG020004",
    artClassDesc: "Accessories/spare parts for door and gate fittings",
    artClassVersion: 2,
    label: "EC004239: Accessories/spare parts for door and gate fittings"
  },
  {
    classId: "EC004240",
    classGroup: "EG000026",
    artClassDesc: "Sensor (HVAC)",
    artClassVersion: 2,
    label: "EC004240: Sensor (HVAC)"
  },
  {
    classId: "EC004241",
    classGroup: "EG010340",
    artClassDesc: "Lock for boxes/cases",
    artClassVersion: 1,
    label: "EC004241: Lock for boxes/cases"
  },
  {
    classId: "EC004242",
    classGroup: "EG020008",
    artClassDesc: "Window lift-out lock",
    artClassVersion: 2,
    label: "EC004242: Window lift-out lock"
  },
  {
    classId: "EC004243",
    classGroup: "EG020004",
    artClassDesc: "Accessories/spare parts for door cylinder",
    artClassVersion: 2,
    label: "EC004243: Accessories/spare parts for door cylinder"
  },
  {
    classId: "EC004244",
    classGroup: "EG020004",
    artClassDesc: "Software for locking system management",
    artClassVersion: 1,
    label: "EC004244: Software for locking system management"
  },
  {
    classId: "EC004245",
    classGroup: "EG010340",
    artClassDesc: "Lifting equipment rack",
    artClassVersion: 2,
    label: "EC004245: Lifting equipment rack"
  },
  {
    classId: "EC004246",
    classGroup: "EG020006",
    artClassDesc: "Paper tray (office)",
    artClassVersion: 2,
    label: "EC004246: Paper tray (office)"
  },
  {
    classId: "EC004247",
    classGroup: "EG020016",
    artClassDesc: "Construction site door",
    artClassVersion: 2,
    label: "EC004247: Construction site door"
  },
  {
    classId: "EC004248",
    classGroup: "EG020000",
    artClassDesc: "Lifting sling",
    artClassVersion: 1,
    label: "EC004248: Lifting sling"
  },
  {
    classId: "EC004250",
    classGroup: "EG020000",
    artClassDesc: "Crane hook traverse",
    artClassVersion: 2,
    label: "EC004250: Crane hook traverse"
  },
  {
    classId: "EC004253",
    classGroup: "EG015630",
    artClassDesc: "Volume flow control insert for air distributor/outlet",
    artClassVersion: 1,
    label: "EC004253: Volume flow control insert for air distributor/outlet"
  },
  {
    classId: "EC004258",
    classGroup: "EG017910",
    artClassDesc: "Accessories/spare parts for bath support",
    artClassVersion: 1,
    label: "EC004258: Accessories/spare parts for bath support"
  },
  {
    classId: "EC004259",
    classGroup: "EG010320",
    artClassDesc: "Accessories/spare parts for lifting devices",
    artClassVersion: 2,
    label: "EC004259: Accessories/spare parts for lifting devices"
  },
  {
    classId: "EC004260",
    classGroup: "EG000050",
    artClassDesc: "Hand pump (hydraulic)",
    artClassVersion: 2,
    label: "EC004260: Hand pump (hydraulic)"
  },
  {
    classId: "EC004262",
    classGroup: "EG015710",
    artClassDesc: "Convector pit inlay frame",
    artClassVersion: 1,
    label: "EC004262: Convector pit inlay frame"
  },
  {
    classId: "EC004265",
    classGroup: "EG000032",
    artClassDesc: "Controller for home automation systems",
    artClassVersion: 2,
    label: "EC004265: Controller for home automation systems"
  },
  {
    classId: "EC004266",
    classGroup: "EG000048",
    artClassDesc: "Plug protection box",
    artClassVersion: 1,
    label: "EC004266: Plug protection box"
  },
  {
    classId: "EC004269",
    classGroup: "EG015610",
    artClassDesc: "Hygiene-flushing box/flushing device",
    artClassVersion: 1,
    label: "EC004269: Hygiene-flushing box/flushing device"
  },
  {
    classId: "EC004270",
    classGroup: "EG015610",
    artClassDesc: "Drinking water cooler/circulator",
    artClassVersion: 1,
    label: "EC004270: Drinking water cooler/circulator"
  },
  {
    classId: "EC004272",
    classGroup: "EG020002",
    artClassDesc: "Accessories/spare parts for gas powered tools",
    artClassVersion: 1,
    label: "EC004272: Accessories/spare parts for gas powered tools"
  },
  {
    classId: "EC004273",
    classGroup: "EG000050",
    artClassDesc: "Tapping drill set",
    artClassVersion: 2,
    label: "EC004273: Tapping drill set"
  },
  {
    classId: "EC004275",
    classGroup: "EG019480",
    artClassDesc: "Lever pick",
    artClassVersion: 2,
    label: "EC004275: Lever pick"
  },
  {
    classId: "EC004276",
    classGroup: "EG000050",
    artClassDesc: "Wood chisel set",
    artClassVersion: 1,
    label: "EC004276: Wood chisel set"
  },
  {
    classId: "EC004277",
    classGroup: "EG020000",
    artClassDesc: "Drawing template",
    artClassVersion: 2,
    label: "EC004277: Drawing template"
  },
  {
    classId: "EC004278",
    classGroup: "EG000050",
    artClassDesc: "Magnetic holder",
    artClassVersion: 2,
    label: "EC004278: Magnetic holder"
  },
  {
    classId: "EC004280",
    classGroup: "EG000050",
    artClassDesc: "Accessories/spare parts for stamping tool",
    artClassVersion: 1,
    label: "EC004280: Accessories/spare parts for stamping tool"
  },
  {
    classId: "EC004281",
    classGroup: "EG020000",
    artClassDesc: "Paint mixing machine",
    artClassVersion: 2,
    label: "EC004281: Paint mixing machine"
  },
  {
    classId: "EC004282",
    classGroup: "EG000051",
    artClassDesc: "Melting tool (electric)",
    artClassVersion: 2,
    label: "EC004282: Melting tool (electric)"
  },
  {
    classId: "EC004283",
    classGroup: "EG000050",
    artClassDesc: "Wire twisting pliers",
    artClassVersion: 2,
    label: "EC004283: Wire twisting pliers"
  },
  {
    classId: "EC004284",
    classGroup: "EG000050",
    artClassDesc: "Special screwdriver",
    artClassVersion: 1,
    label: "EC004284: Special screwdriver"
  },
  {
    classId: "EC004285",
    classGroup: "EG000050",
    artClassDesc: "Outside square screwdriver",
    artClassVersion: 1,
    label: "EC004285: Outside square screwdriver"
  },
  {
    classId: "EC004288",
    classGroup: "EG000050",
    artClassDesc: "Pickaroon",
    artClassVersion: 2,
    label: "EC004288: Pickaroon"
  },
  {
    classId: "EC004289",
    classGroup: "EG000040",
    artClassDesc: "Sous-vide cooker",
    artClassVersion: 1,
    label: "EC004289: Sous-vide cooker"
  },
  {
    classId: "EC004290",
    classGroup: "EG000023",
    artClassDesc: "Terminal for electricity and water",
    artClassVersion: 2,
    label: "EC004290: Terminal for electricity and water"
  },
  {
    classId: "EC004291",
    classGroup: "EG000023",
    artClassDesc: "Lightpole box",
    artClassVersion: 2,
    label: "EC004291: Lightpole box"
  },
  {
    classId: "EC004293",
    classGroup: "EG020018",
    artClassDesc: "Collet chuck",
    artClassVersion: 2,
    label: "EC004293: Collet chuck"
  },
  {
    classId: "EC004294",
    classGroup: "EG020018",
    artClassDesc: "Spring clamp",
    artClassVersion: 2,
    label: "EC004294: Spring clamp"
  },
  {
    classId: "EC004295",
    classGroup: "EG020018",
    artClassDesc: "Clamping claw",
    artClassVersion: 2,
    label: "EC004295: Clamping claw"
  },
  {
    classId: "EC004296",
    classGroup: "EG020018",
    artClassDesc: "Accessories/spare parts for clamping tools",
    artClassVersion: 2,
    label: "EC004296: Accessories/spare parts for clamping tools"
  },
  {
    classId: "EC004297",
    classGroup: "EG020018",
    artClassDesc: "Clamping jaw",
    artClassVersion: 2,
    label: "EC004297: Clamping jaw"
  },
  {
    classId: "EC004298",
    classGroup: "EG020018",
    artClassDesc: "Clamping sleeve",
    artClassVersion: 2,
    label: "EC004298: Clamping sleeve"
  },
  {
    classId: "EC004299",
    classGroup: "EG020018",
    artClassDesc: "Clamp set",
    artClassVersion: 1,
    label: "EC004299: Clamp set"
  },
  {
    classId: "EC004300",
    classGroup: "EG020018",
    artClassDesc: "Magnetic chucking system",
    artClassVersion: 2,
    label: "EC004300: Magnetic chucking system"
  },
  {
    classId: "EC004301",
    classGroup: "EG000051",
    artClassDesc: "Deburring machine",
    artClassVersion: 2,
    label: "EC004301: Deburring machine"
  },
  {
    classId: "EC004302",
    classGroup: "EG000051",
    artClassDesc: "Milling machine, stationary",
    artClassVersion: 2,
    label: "EC004302: Milling machine, stationary"
  },
  {
    classId: "EC004303",
    classGroup: "EG000051",
    artClassDesc: "Round bending machine, stationary",
    artClassVersion: 2,
    label: "EC004303: Round bending machine, stationary"
  },
  {
    classId: "EC004304",
    classGroup: "EG000050",
    artClassDesc: "Smoothing trowel",
    artClassVersion: 2,
    label: "EC004304: Smoothing trowel"
  },
  {
    classId: "EC004305",
    classGroup: "EG000050",
    artClassDesc: "Reamer",
    artClassVersion: 2,
    label: "EC004305: Reamer"
  },
  {
    classId: "EC004307",
    classGroup: "EG020002",
    artClassDesc: "Grinding wheel",
    artClassVersion: 2,
    label: "EC004307: Grinding wheel"
  },
  {
    classId: "EC004308",
    classGroup: "EG017910",
    artClassDesc: "Accessories/spare parts for shower floor",
    artClassVersion: 1,
    label: "EC004308: Accessories/spare parts for shower floor"
  },
  {
    classId: "EC004309",
    classGroup: "EG000017",
    artClassDesc: "Vibration sensor",
    artClassVersion: 1,
    label: "EC004309: Vibration sensor"
  },
  {
    classId: "EC004310",
    classGroup: "EG000044",
    artClassDesc: "Ultrasonic leak detector",
    artClassVersion: 2,
    label: "EC004310: Ultrasonic leak detector"
  },
  {
    classId: "EC004311",
    classGroup: "EG000050",
    artClassDesc: "Mounting tool for door/window frame",
    artClassVersion: 2,
    label: "EC004311: Mounting tool for door/window frame"
  },
  {
    classId: "EC004312",
    classGroup: "EG000050",
    artClassDesc: "Gauge block",
    artClassVersion: 2,
    label: "EC004312: Gauge block"
  },
  {
    classId: "EC004313",
    classGroup: "EG020002",
    artClassDesc: "Power feeder for circular saws",
    artClassVersion: 2,
    label: "EC004313: Power feeder for circular saws"
  },
  {
    classId: "EC004314",
    classGroup: "EG020002",
    artClassDesc: "Accessories/spare parts for pipe working tool",
    artClassVersion: 2,
    label: "EC004314: Accessories/spare parts for pipe working tool"
  },
  {
    classId: "EC004316",
    classGroup: "EG000051",
    artClassDesc: "Jointing press (compressed air)",
    artClassVersion: 2,
    label: "EC004316: Jointing press (compressed air)"
  },
  {
    classId: "EC004317",
    classGroup: "EG000050",
    artClassDesc: "Micrometer set",
    artClassVersion: 2,
    label: "EC004317: Micrometer set"
  },
  {
    classId: "EC004318",
    classGroup: "EG000051",
    artClassDesc: "Fold seam locker (compressed air)",
    artClassVersion: 2,
    label: "EC004318: Fold seam locker (compressed air)"
  },
  {
    classId: "EC004319",
    classGroup: "EG000050",
    artClassDesc: "Laying tool for parquet/laminate floor",
    artClassVersion: 2,
    label: "EC004319: Laying tool for parquet/laminate floor"
  },
  {
    classId: "EC004320",
    classGroup: "EG000050",
    artClassDesc: "Seaming tool",
    artClassVersion: 2,
    label: "EC004320: Seaming tool"
  },
  {
    classId: "EC004321",
    classGroup: "EG020018",
    artClassDesc: "Collet chuck set",
    artClassVersion: 1,
    label: "EC004321: Collet chuck set"
  },
  {
    classId: "EC004322",
    classGroup: "EG000050",
    artClassDesc: "Machine expansive bit",
    artClassVersion: 1,
    label: "EC004322: Machine expansive bit"
  },
  {
    classId: "EC004323",
    classGroup: "EG020002",
    artClassDesc: "Flat drill",
    artClassVersion: 1,
    label: "EC004323: Flat drill"
  },
  {
    classId: "EC004324",
    classGroup: "EG020002",
    artClassDesc: "Step drill set",
    artClassVersion: 2,
    label: "EC004324: Step drill set"
  },
  {
    classId: "EC004325",
    classGroup: "EG020002",
    artClassDesc: "Core drill bit set",
    artClassVersion: 2,
    label: "EC004325: Core drill bit set"
  },
  {
    classId: "EC004326",
    classGroup: "EG020002",
    artClassDesc: "Polishing set",
    artClassVersion: 1,
    label: "EC004326: Polishing set"
  },
  {
    classId: "EC004327",
    classGroup: "EG020002",
    artClassDesc: "Support plate for grinding tool",
    artClassVersion: 2,
    label: "EC004327: Support plate for grinding tool"
  },
  {
    classId: "EC004328",
    classGroup: "EG020002",
    artClassDesc: "Accessories/spare parts for countersinking tools",
    artClassVersion: 1,
    label: "EC004328: Accessories/spare parts for countersinking tools"
  },
  {
    classId: "EC004329",
    classGroup: "EG020019",
    artClassDesc: "Accessories/spare parts for turning tools",
    artClassVersion: 1,
    label: "EC004329: Accessories/spare parts for turning tools"
  },
  {
    classId: "EC004331",
    classGroup: "EG020019",
    artClassDesc: "Facing-/boring head",
    artClassVersion: 1,
    label: "EC004331: Facing-/boring head"
  },
  {
    classId: "EC004332",
    classGroup: "EG020019",
    artClassDesc: "Accessories/spare parts for facing/boring head",
    artClassVersion: 1,
    label: "EC004332: Accessories/spare parts for facing/boring head"
  },
  {
    classId: "EC004333",
    classGroup: "EG020019",
    artClassDesc: "Knurling tool",
    artClassVersion: 1,
    label: "EC004333: Knurling tool"
  },
  {
    classId: "EC004334",
    classGroup: "EG020019",
    artClassDesc: "Accessories/spare parts for knurling tool",
    artClassVersion: 1,
    label: "EC004334: Accessories/spare parts for knurling tool"
  },
  {
    classId: "EC004335",
    classGroup: "EG020020",
    artClassDesc: "Welding magnet",
    artClassVersion: 1,
    label: "EC004335: Welding magnet"
  },
  {
    classId: "EC004336",
    classGroup: "EG020021",
    artClassDesc: "Straightedge",
    artClassVersion: 1,
    label: "EC004336: Straightedge"
  },
  {
    classId: "EC004337",
    classGroup: "EG020021",
    artClassDesc: "Special measuring angle",
    artClassVersion: 1,
    label: "EC004337: Special measuring angle"
  },
  {
    classId: "EC004338",
    classGroup: "EG020021",
    artClassDesc: "Tool preset system",
    artClassVersion: 1,
    label: "EC004338: Tool preset system"
  },
  {
    classId: "EC004339",
    classGroup: "EG020021",
    artClassDesc: "Accessories/spare parts for tool preset system",
    artClassVersion: 1,
    label: "EC004339: Accessories/spare parts for tool preset system"
  },
  {
    classId: "EC004340",
    classGroup: "EG020021",
    artClassDesc: "Centering tool",
    artClassVersion: 1,
    label: "EC004340: Centering tool"
  },
  {
    classId: "EC004341",
    classGroup: "EG000061",
    artClassDesc: "Accessories/spare parts for soldering and welding",
    artClassVersion: 1,
    label: "EC004341: Accessories/spare parts for soldering and welding"
  },
  {
    classId: "EC004342",
    classGroup: "EG000061",
    artClassDesc: "Welding cable, assembled",
    artClassVersion: 2,
    label: "EC004342: Welding cable, assembled"
  },
  {
    classId: "EC004343",
    classGroup: "EG000050",
    artClassDesc: "Bordering pliers for welders",
    artClassVersion: 1,
    label: "EC004343: Bordering pliers for welders"
  },
  {
    classId: "EC004345",
    classGroup: "EG000061",
    artClassDesc: "Welding electrode cap",
    artClassVersion: 1,
    label: "EC004345: Welding electrode cap"
  },
  {
    classId: "EC004347",
    classGroup: "EG000050",
    artClassDesc: "Accessories/spare parts for painting tools",
    artClassVersion: 1,
    label: "EC004347: Accessories/spare parts for painting tools"
  },
  {
    classId: "EC004348",
    classGroup: "EG000050",
    artClassDesc: "Painting tool set",
    artClassVersion: 1,
    label: "EC004348: Painting tool set"
  },
  {
    classId: "EC004349",
    classGroup: "EG000050",
    artClassDesc: "Laying tool for plaster/tiles",
    artClassVersion: 1,
    label: "EC004349: Laying tool for plaster/tiles"
  },
  {
    classId: "EC004350",
    classGroup: "EG000049",
    artClassDesc: "Joint claw for laminate/parquet",
    artClassVersion: 1,
    label: "EC004350: Joint claw for laminate/parquet"
  },
  {
    classId: "EC004353",
    classGroup: "EG000050",
    artClassDesc: "Corner trowel",
    artClassVersion: 1,
    label: "EC004353: Corner trowel"
  },
  {
    classId: "EC004354",
    classGroup: "EG020019",
    artClassDesc: "Indexable insert",
    artClassVersion: 1,
    label: "EC004354: Indexable insert"
  },
  {
    classId: "EC004355",
    classGroup: "EG020019",
    artClassDesc: "Holder for indexable inserts",
    artClassVersion: 1,
    label: "EC004355: Holder for indexable inserts"
  },
  {
    classId: "EC004356",
    classGroup: "EG020019",
    artClassDesc: "Indexable drill shank",
    artClassVersion: 2,
    label: "EC004356: Indexable drill shank"
  },
  {
    classId: "EC004358",
    classGroup: "EG020022",
    artClassDesc: "Drilling out tool",
    artClassVersion: 1,
    label: "EC004358: Drilling out tool"
  },
  {
    classId: "EC004359",
    classGroup: "EG020022",
    artClassDesc: "Drill file rasp",
    artClassVersion: 1,
    label: "EC004359: Drill file rasp"
  },
  {
    classId: "EC004360",
    classGroup: "EG020002",
    artClassDesc: "Special attachment for drilling machines",
    artClassVersion: 1,
    label: "EC004360: Special attachment for drilling machines"
  },
  {
    classId: "EC004361",
    classGroup: "EG015510",
    artClassDesc: "Fire hose reel cabinet (with reel and hose)",
    artClassVersion: 2,
    label: "EC004361: Fire hose reel cabinet (with reel and hose)"
  },
  {
    classId: "EC004363",
    classGroup: "EG000050",
    artClassDesc: "Workpiece holder",
    artClassVersion: 2,
    label: "EC004363: Workpiece holder"
  },
  {
    classId: "EC004365",
    classGroup: "EG000061",
    artClassDesc: "Plasma cutting machine",
    artClassVersion: 1,
    label: "EC004365: Plasma cutting machine"
  },
  {
    classId: "EC004366",
    classGroup: "EG000061",
    artClassDesc: "Flame cutting torch machine",
    artClassVersion: 1,
    label: "EC004366: Flame cutting torch machine"
  },
  {
    classId: "EC004367",
    classGroup: "EG020002",
    artClassDesc: "Base station and accessories for industrial screw system",
    artClassVersion: 1,
    label: "EC004367: Base station and accessories for industrial screw system"
  },
  {
    classId: "EC004368",
    classGroup: "EG020002",
    artClassDesc: "Tool holder for tooling machines",
    artClassVersion: 1,
    label: "EC004368: Tool holder for tooling machines"
  },
  {
    classId: "EC004369",
    classGroup: "EG020002",
    artClassDesc: "Accessories/spare parts for tool holders",
    artClassVersion: 2,
    label: "EC004369: Accessories/spare parts for tool holders"
  },
  {
    classId: "EC004371",
    classGroup: "EG000006",
    artClassDesc: "Steel trunking length",
    artClassVersion: 1,
    label: "EC004371: Steel trunking length"
  },
  {
    classId: "EC004372",
    classGroup: "EG000006",
    artClassDesc: "Steel trunking bend",
    artClassVersion: 2,
    label: "EC004372: Steel trunking bend"
  },
  {
    classId: "EC004373",
    classGroup: "EG000006",
    artClassDesc: "Steel trunking reducer",
    artClassVersion: 1,
    label: "EC004373: Steel trunking reducer"
  },
  {
    classId: "EC004375",
    classGroup: "EG020020",
    artClassDesc: "Magnetic base",
    artClassVersion: 1,
    label: "EC004375: Magnetic base"
  },
  {
    classId: "EC004376",
    classGroup: "EG020018",
    artClassDesc: "Quick-change insert for clamping devices",
    artClassVersion: 1,
    label: "EC004376: Quick-change insert for clamping devices"
  },
  {
    classId: "EC004378",
    classGroup: "EG000050",
    artClassDesc: "Sparkplug pliers",
    artClassVersion: 1,
    label: "EC004378: Sparkplug pliers"
  },
  {
    classId: "EC004379",
    classGroup: "EG000050",
    artClassDesc: "Blacksmith pliers",
    artClassVersion: 1,
    label: "EC004379: Blacksmith pliers"
  },
  {
    classId: "EC004380",
    classGroup: "EG000050",
    artClassDesc: "Log tongs",
    artClassVersion: 1,
    label: "EC004380: Log tongs"
  },
  {
    classId: "EC004381",
    classGroup: "EG000050",
    artClassDesc: "Glass pliers",
    artClassVersion: 1,
    label: "EC004381: Glass pliers"
  },
  {
    classId: "EC004382",
    classGroup: "EG000050",
    artClassDesc: "Hog ring pliers",
    artClassVersion: 1,
    label: "EC004382: Hog ring pliers"
  },
  {
    classId: "EC004383",
    classGroup: "EG020000",
    artClassDesc: "Magnifying glass",
    artClassVersion: 1,
    label: "EC004383: Magnifying glass"
  },
  {
    classId: "EC004384",
    classGroup: "EG015830",
    artClassDesc: "Range pole",
    artClassVersion: 2,
    label: "EC004384: Range pole"
  },
  {
    classId: "EC004385",
    classGroup: "EG020021",
    artClassDesc: "Edge-finder",
    artClassVersion: 1,
    label: "EC004385: Edge-finder"
  },
  {
    classId: "EC004386",
    classGroup: "EG020021",
    artClassDesc: "Height gauge",
    artClassVersion: 1,
    label: "EC004386: Height gauge"
  },
  {
    classId: "EC004387",
    classGroup: "EG020021",
    artClassDesc: "Length measuring probe",
    artClassVersion: 1,
    label: "EC004387: Length measuring probe"
  },
  {
    classId: "EC004390",
    classGroup: "EG000060",
    artClassDesc: "Thread measuring tool",
    artClassVersion: 1,
    label: "EC004390: Thread measuring tool"
  },
  {
    classId: "EC004391",
    classGroup: "EG000060",
    artClassDesc: "Gauge for material treatment",
    artClassVersion: 1,
    label: "EC004391: Gauge for material treatment"
  },
  {
    classId: "EC004392",
    classGroup: "EG020021",
    artClassDesc: "Marking gauge",
    artClassVersion: 1,
    label: "EC004392: Marking gauge"
  },
  {
    classId: "EC004393",
    classGroup: "EG020002",
    artClassDesc: "Lathe chuck",
    artClassVersion: 1,
    label: "EC004393: Lathe chuck"
  },
  {
    classId: "EC004394",
    classGroup: "EG020002",
    artClassDesc: "Accessories/spare parts for lathe chuck",
    artClassVersion: 1,
    label: "EC004394: Accessories/spare parts for lathe chuck"
  },
  {
    classId: "EC004396",
    classGroup: "EG020018",
    artClassDesc: "Zero-point clamping system",
    artClassVersion: 1,
    label: "EC004396: Zero-point clamping system"
  },
  {
    classId: "EC004397",
    classGroup: "EG020018",
    artClassDesc: "Adapter for machine clamping tools",
    artClassVersion: 1,
    label: "EC004397: Adapter for machine clamping tools"
  },
  {
    classId: "EC004398",
    classGroup: "EG010340",
    artClassDesc: "Accessories/spare parts for wheels and tyres",
    artClassVersion: 1,
    label: "EC004398: Accessories/spare parts for wheels and tyres"
  },
  {
    classId: "EC004399",
    classGroup: "EG010340",
    artClassDesc: "Grit spreader",
    artClassVersion: 1,
    label: "EC004399: Grit spreader"
  },
  {
    classId: "EC004400",
    classGroup: "EG010340",
    artClassDesc: "Attachment for forklift trucks",
    artClassVersion: 1,
    label: "EC004400: Attachment for forklift trucks"
  },
  {
    classId: "EC004401",
    classGroup: "EG020023",
    artClassDesc: "Hydraulic vehicle lifter",
    artClassVersion: 2,
    label: "EC004401: Hydraulic vehicle lifter"
  },
  {
    classId: "EC004403",
    classGroup: "EG000049",
    artClassDesc: "Webbing belt",
    artClassVersion: 1,
    label: "EC004403: Webbing belt"
  },
  {
    classId: "EC004404",
    classGroup: "EG020012",
    artClassDesc: "Screw-in insert nut",
    artClassVersion: 1,
    label: "EC004404: Screw-in insert nut"
  },
  {
    classId: "EC004405",
    classGroup: "EG019710",
    artClassDesc: "Joint sealing band",
    artClassVersion: 2,
    label: "EC004405: Joint sealing band"
  },
  {
    classId: "EC004406",
    classGroup: "EG000049",
    artClassDesc: "Accessories/spare parts for webbing belts",
    artClassVersion: 1,
    label: "EC004406: Accessories/spare parts for webbing belts"
  },
  {
    classId: "EC004407",
    classGroup: "EG020100",
    artClassDesc: "Weep hole vent",
    artClassVersion: 2,
    label: "EC004407: Weep hole vent"
  },
  {
    classId: "EC004408",
    classGroup: "EG015240",
    artClassDesc: "Subfloor vent duct",
    artClassVersion: 2,
    label: "EC004408: Subfloor vent duct"
  },
  {
    classId: "EC004409",
    classGroup: "EG020150",
    artClassDesc: "Masonry protection profile",
    artClassVersion: 2,
    label: "EC004409: Masonry protection profile"
  },
  {
    classId: "EC004410",
    classGroup: "EG000049",
    artClassDesc: "Mounting board for floor heating",
    artClassVersion: 1,
    label: "EC004410: Mounting board for floor heating"
  },
  {
    classId: "EC004412",
    classGroup: "EG020000",
    artClassDesc: "Bottle for liquids",
    artClassVersion: 1,
    label: "EC004412: Bottle for liquids"
  },
  {
    classId: "EC004413",
    classGroup: "EG020006",
    artClassDesc: "Cleaning trolley",
    artClassVersion: 1,
    label: "EC004413: Cleaning trolley"
  },
  {
    classId: "EC004414",
    classGroup: "EG020000",
    artClassDesc: "Carrier roller",
    artClassVersion: 2,
    label: "EC004414: Carrier roller"
  },
  {
    classId: "EC004415",
    classGroup: "EG020000",
    artClassDesc: "Ball caster",
    artClassVersion: 1,
    label: "EC004415: Ball caster"
  },
  {
    classId: "EC004416",
    classGroup: "EG020006",
    artClassDesc: "Ball caster table",
    artClassVersion: 1,
    label: "EC004416: Ball caster table"
  },
  {
    classId: "EC004417",
    classGroup: "EG020006",
    artClassDesc: "Wheel conveyor",
    artClassVersion: 1,
    label: "EC004417: Wheel conveyor"
  },
  {
    classId: "EC004418",
    classGroup: "EG020006",
    artClassDesc: "Roller conveyor",
    artClassVersion: 1,
    label: "EC004418: Roller conveyor"
  },
  {
    classId: "EC004419",
    classGroup: "EG020006",
    artClassDesc: "Accessories/spare parts for cargo conveyor",
    artClassVersion: 1,
    label: "EC004419: Accessories/spare parts for cargo conveyor"
  },
  {
    classId: "EC004420",
    classGroup: "EG020000",
    artClassDesc: "Accessories/spare parts for transport trolley",
    artClassVersion: 1,
    label: "EC004420: Accessories/spare parts for transport trolley"
  },
  {
    classId: "EC004421",
    classGroup: "EG020000",
    artClassDesc: "Forklift truck",
    artClassVersion: 1,
    label: "EC004421: Forklift truck"
  },
  {
    classId: "EC004422",
    classGroup: "EG020012",
    artClassDesc: "Accessories/spare parts for furniture foot",
    artClassVersion: 1,
    label: "EC004422: Accessories/spare parts for furniture foot"
  },
  {
    classId: "EC004423",
    classGroup: "EG017960",
    artClassDesc: "Drawer element",
    artClassVersion: 1,
    label: "EC004423: Drawer element"
  },
  {
    classId: "EC004424",
    classGroup: "EG017960",
    artClassDesc: "Accessories/spare parts for drawer",
    artClassVersion: 2,
    label: "EC004424: Accessories/spare parts for drawer"
  },
  {
    classId: "EC004425",
    classGroup: "EG010340",
    artClassDesc: "Bridging step",
    artClassVersion: 1,
    label: "EC004425: Bridging step"
  },
  {
    classId: "EC004426",
    classGroup: "EG020006",
    artClassDesc: "Paperclip",
    artClassVersion: 1,
    label: "EC004426: Paperclip"
  },
  {
    classId: "EC004427",
    classGroup: "EG020006",
    artClassDesc: "Mobile filling station",
    artClassVersion: 1,
    label: "EC004427: Mobile filling station"
  },
  {
    classId: "EC004428",
    classGroup: "EG020006",
    artClassDesc: "Loading ramp",
    artClassVersion: 1,
    label: "EC004428: Loading ramp"
  },
  {
    classId: "EC004429",
    classGroup: "EG020006",
    artClassDesc: "Accessories/spare parts for filling station",
    artClassVersion: 1,
    label: "EC004429: Accessories/spare parts for filling station"
  },
  {
    classId: "EC004430",
    classGroup: "EG017960",
    artClassDesc: "Showcase",
    artClassVersion: 1,
    label: "EC004430: Showcase"
  },
  {
    classId: "EC004431",
    classGroup: "EG000050",
    artClassDesc: "Marking device",
    artClassVersion: 2,
    label: "EC004431: Marking device"
  },
  {
    classId: "EC004432",
    classGroup: "EG017960",
    artClassDesc: "Accessories/spare parts for room divider",
    artClassVersion: 1,
    label: "EC004432: Accessories/spare parts for room divider"
  },
  {
    classId: "EC004433",
    classGroup: "EG012000",
    artClassDesc: "Curtain",
    artClassVersion: 1,
    label: "EC004433: Curtain"
  },
  {
    classId: "EC004434",
    classGroup: "EG020006",
    artClassDesc: "Book support",
    artClassVersion: 1,
    label: "EC004434: Book support"
  },
  {
    classId: "EC004435",
    classGroup: "EG017960",
    artClassDesc: "Component for expansion (rack element)",
    artClassVersion: 1,
    label: "EC004435: Component for expansion (rack element)"
  },
  {
    classId: "EC004436",
    classGroup: "EG017960",
    artClassDesc: "Component for installation (rack element)",
    artClassVersion: 1,
    label: "EC004436: Component for installation (rack element)"
  },
  {
    classId: "EC004437",
    classGroup: "EG017960",
    artClassDesc: "Desk",
    artClassVersion: 1,
    label: "EC004437: Desk"
  },
  {
    classId: "EC004438",
    classGroup: "EG020006",
    artClassDesc: "Table trolley",
    artClassVersion: 2,
    label: "EC004438: Table trolley"
  },
  {
    classId: "EC004439",
    classGroup: "EG000049",
    artClassDesc: "Nail assortment box",
    artClassVersion: 2,
    label: "EC004439: Nail assortment box"
  },
  {
    classId: "EC004440",
    classGroup: "EG000049",
    artClassDesc: "Timber connector",
    artClassVersion: 1,
    label: "EC004440: Timber connector"
  },
  {
    classId: "EC004441",
    classGroup: "EG000049",
    artClassDesc: "Taper pin",
    artClassVersion: 1,
    label: "EC004441: Taper pin"
  },
  {
    classId: "EC004442",
    classGroup: "EG000050",
    artClassDesc: "Installation strap dispenser",
    artClassVersion: 1,
    label: "EC004442: Installation strap dispenser"
  },
  {
    classId: "EC004443",
    classGroup: "EG000049",
    artClassDesc: "Cramp iron",
    artClassVersion: 2,
    label: "EC004443: Cramp iron"
  },
  {
    classId: "EC004444",
    classGroup: "EG000049",
    artClassDesc: "Angle bracket",
    artClassVersion: 2,
    label: "EC004444: Angle bracket"
  },
  {
    classId: "EC004445",
    classGroup: "EG000050",
    artClassDesc: "Dowel centre set",
    artClassVersion: 1,
    label: "EC004445: Dowel centre set"
  },
  {
    classId: "EC004446",
    classGroup: "EG020150",
    artClassDesc: "Gerber connector",
    artClassVersion: 1,
    label: "EC004446: Gerber connector"
  },
  {
    classId: "EC004447",
    classGroup: "EG020150",
    artClassDesc: "Knag angle",
    artClassVersion: 1,
    label: "EC004447: Knag angle"
  },
  {
    classId: "EC004449",
    classGroup: "EG020150",
    artClassDesc: "Post foot",
    artClassVersion: 2,
    label: "EC004449: Post foot"
  },
  {
    classId: "EC004450",
    classGroup: "EG020150",
    artClassDesc: "Plaster hook",
    artClassVersion: 1,
    label: "EC004450: Plaster hook"
  },
  {
    classId: "EC004451",
    classGroup: "EG000049",
    artClassDesc: "Metal ring",
    artClassVersion: 1,
    label: "EC004451: Metal ring"
  },
  {
    classId: "EC004452",
    classGroup: "EG015140",
    artClassDesc: "Hydrocyclone filter",
    artClassVersion: 1,
    label: "EC004452: Hydrocyclone filter"
  },
  {
    classId: "EC004453",
    classGroup: "EG017960",
    artClassDesc: "Shoe rack",
    artClassVersion: 1,
    label: "EC004453: Shoe rack"
  },
  {
    classId: "EC004456",
    classGroup: "EG015140",
    artClassDesc: "Polyphosphate dispenser",
    artClassVersion: 1,
    label: "EC004456: Polyphosphate dispenser"
  },
  {
    classId: "EC004464",
    classGroup: "EG015510",
    artClassDesc: "Temporary rainwater downpipe",
    artClassVersion: 1,
    label: "EC004464: Temporary rainwater downpipe"
  },
  {
    classId: "EC004466",
    classGroup: "EG019510",
    artClassDesc: "Tile levelling plier",
    artClassVersion: 1,
    label: "EC004466: Tile levelling plier"
  },
  {
    classId: "EC004467",
    classGroup: "EG015540",
    artClassDesc: "Soil pipe manifold",
    artClassVersion: 1,
    label: "EC004467: Soil pipe manifold"
  },
  {
    classId: "EC004468",
    classGroup: "EG017960",
    artClassDesc: "Clothes hook",
    artClassVersion: 2,
    label: "EC004468: Clothes hook"
  },
  {
    classId: "EC004469",
    classGroup: "EG000014",
    artClassDesc: "Accessories/spare parts for terminal for electricity and water",
    artClassVersion: 2,
    label: "EC004469: Accessories/spare parts for terminal for electricity and water"
  },
  {
    classId: "EC004471",
    classGroup: "EG017960",
    artClassDesc: "Laundry drop system",
    artClassVersion: 1,
    label: "EC004471: Laundry drop system"
  },
  {
    classId: "EC004472",
    classGroup: "EG000043",
    artClassDesc: "Accessories/spare parts for heat emitter",
    artClassVersion: 2,
    label: "EC004472: Accessories/spare parts for heat emitter"
  },
  {
    classId: "EC004473",
    classGroup: "EG010210",
    artClassDesc: "Sanding grit block",
    artClassVersion: 2,
    label: "EC004473: Sanding grit block"
  },
  {
    classId: "EC004474",
    classGroup: "EG020001",
    artClassDesc: "Control cabinet for the utility sector",
    artClassVersion: 1,
    label: "EC004474: Control cabinet for the utility sector"
  },
  {
    classId: "EC004475",
    classGroup: "EG020001",
    artClassDesc: "Accessories/spare parts for control cabinet for the utility sector",
    artClassVersion: 1,
    label: "EC004475: Accessories/spare parts for control cabinet for the utility sector"
  },
  {
    classId: "EC004476",
    classGroup: "EG020001",
    artClassDesc: "Spark gap for overvoltage protection",
    artClassVersion: 1,
    label: "EC004476: Spark gap for overvoltage protection"
  },
  {
    classId: "EC004477",
    classGroup: "EG020001",
    artClassDesc: "Overvoltage protection central point <1kV",
    artClassVersion: 1,
    label: "EC004477: Overvoltage protection central point <1kV"
  },
  {
    classId: "EC004478",
    classGroup: "EG000050",
    artClassDesc: "Snow shovel",
    artClassVersion: 2,
    label: "EC004478: Snow shovel"
  },
  {
    classId: "EC004479",
    classGroup: "EG000044",
    artClassDesc: "Tester for rotating machines",
    artClassVersion: 1,
    label: "EC004479: Tester for rotating machines"
  },
  {
    classId: "EC004480",
    classGroup: "EG015710",
    artClassDesc: "Fan-assisted radiator",
    artClassVersion: 2,
    label: "EC004480: Fan-assisted radiator"
  },
  {
    classId: "EC004482",
    classGroup: "EG015280",
    artClassDesc: "Control unit for electrical radiators",
    artClassVersion: 1,
    label: "EC004482: Control unit for electrical radiators"
  },
  {
    classId: "EC004483",
    classGroup: "EG015610",
    artClassDesc: "Break tank station",
    artClassVersion: 2,
    label: "EC004483: Break tank station"
  },
  {
    classId: "EC004484",
    classGroup: "EG015530",
    artClassDesc: "Pre-insulated metal pipe",
    artClassVersion: 1,
    label: "EC004484: Pre-insulated metal pipe"
  },
  {
    classId: "EC004485",
    classGroup: "EG015830",
    artClassDesc: "Water meter",
    artClassVersion: 2,
    label: "EC004485: Water meter"
  },
  {
    classId: "EC004486",
    classGroup: "EG000046",
    artClassDesc: "Laces",
    artClassVersion: 1,
    label: "EC004486: Laces"
  },
  {
    classId: "EC004487",
    classGroup: "EG000046",
    artClassDesc: "Insoles",
    artClassVersion: 2,
    label: "EC004487: Insoles"
  },
  {
    classId: "EC004489",
    classGroup: "EG000044",
    artClassDesc: "Power quality recorder",
    artClassVersion: 2,
    label: "EC004489: Power quality recorder"
  },
  {
    classId: "EC004490",
    classGroup: "EG017640",
    artClassDesc: "Instantaneous electric shower",
    artClassVersion: 1,
    label: "EC004490: Instantaneous electric shower"
  },
  {
    classId: "EC004491",
    classGroup: "EG000006",
    artClassDesc: "Steel trunking tee",
    artClassVersion: 2,
    label: "EC004491: Steel trunking tee"
  },
  {
    classId: "EC004492",
    classGroup: "EG017110",
    artClassDesc: "Accessories/spare parts for bath tub/whirl system",
    artClassVersion: 2,
    label: "EC004492: Accessories/spare parts for bath tub/whirl system"
  },
  {
    classId: "EC004493",
    classGroup: "EG000006",
    artClassDesc: "Steel trunking crossover",
    artClassVersion: 1,
    label: "EC004493: Steel trunking crossover"
  },
  {
    classId: "EC004494",
    classGroup: "EG000006",
    artClassDesc: "Steel trunking change face unit",
    artClassVersion: 1,
    label: "EC004494: Steel trunking change face unit"
  },
  {
    classId: "EC004495",
    classGroup: "EG015120",
    artClassDesc: "Accessories/spare parts for storage tank, pressureless",
    artClassVersion: 2,
    label: "EC004495: Accessories/spare parts for storage tank, pressureless"
  },
  {
    classId: "EC004496",
    classGroup: "EG000006",
    artClassDesc: "Steel trunking bell mouth fitting",
    artClassVersion: 1,
    label: "EC004496: Steel trunking bell mouth fitting"
  },
  {
    classId: "EC004497",
    classGroup: "EG000006",
    artClassDesc: "Steel trunking end cap",
    artClassVersion: 1,
    label: "EC004497: Steel trunking end cap"
  },
  {
    classId: "EC004498",
    classGroup: "EG000006",
    artClassDesc: "Steel trunking connector",
    artClassVersion: 1,
    label: "EC004498: Steel trunking connector"
  },
  {
    classId: "EC004499",
    classGroup: "EG019710",
    artClassDesc: "Steel stud profile",
    artClassVersion: 2,
    label: "EC004499: Steel stud profile"
  },
  {
    classId: "EC004500",
    classGroup: "EG020150",
    artClassDesc: "Plastic foil nail",
    artClassVersion: 2,
    label: "EC004500: Plastic foil nail"
  },
  {
    classId: "EC004501",
    classGroup: "EG015610",
    artClassDesc: "Backflow preventer",
    artClassVersion: 2,
    label: "EC004501: Backflow preventer"
  },
  {
    classId: "EC004502",
    classGroup: "EG015610",
    artClassDesc: "Accessories/spare parts for bypass valve water softener",
    artClassVersion: 1,
    label: "EC004502: Accessories/spare parts for bypass valve water softener"
  },
  {
    classId: "EC004503",
    classGroup: "EG015810",
    artClassDesc: "Temperature safety relief valve",
    artClassVersion: 1,
    label: "EC004503: Temperature safety relief valve"
  },
  {
    classId: "EC004504",
    classGroup: "EG017130",
    artClassDesc: "Double wall/built-in element washbasin with flush-mounted element",
    artClassVersion: 2,
    label: "EC004504: Double wall/built-in element washbasin with flush-mounted element"
  },
  {
    classId: "EC004505",
    classGroup: "EG000040",
    artClassDesc: "Food processor with cooking function",
    artClassVersion: 1,
    label: "EC004505: Food processor with cooking function"
  },
  {
    classId: "EC004507",
    classGroup: "EG000055",
    artClassDesc: "Photovoltaics roof tile",
    artClassVersion: 2,
    label: "EC004507: Photovoltaics roof tile"
  },
  {
    classId: "EC004508",
    classGroup: "EG000010",
    artClassDesc: "Acoustic partitioning box for cables",
    artClassVersion: 1,
    label: "EC004508: Acoustic partitioning box for cables"
  },
  {
    classId: "EC004511",
    classGroup: "EG015140",
    artClassDesc: "Fuel oil filter",
    artClassVersion: 2,
    label: "EC004511: Fuel oil filter"
  },
  {
    classId: "EC004512",
    classGroup: "EG015140",
    artClassDesc: "Tap water filter (built-in)",
    artClassVersion: 2,
    label: "EC004512: Tap water filter (built-in)"
  },
  {
    classId: "EC004513",
    classGroup: "EG020550",
    artClassDesc: "Roof finial",
    artClassVersion: 1,
    label: "EC004513: Roof finial"
  },
  {
    classId: "EC004515",
    classGroup: "EG015610",
    artClassDesc: "Accessories/spare parts for district heat transfer station",
    artClassVersion: 2,
    label: "EC004515: Accessories/spare parts for district heat transfer station"
  },
  {
    classId: "EC004516",
    classGroup: "EG020550",
    artClassDesc: "Dry verge for gable/barge",
    artClassVersion: 1,
    label: "EC004516: Dry verge for gable/barge"
  },
  {
    classId: "EC004517",
    classGroup: "EG015130",
    artClassDesc: "Intermediate section for floor drain",
    artClassVersion: 2,
    label: "EC004517: Intermediate section for floor drain"
  },
  {
    classId: "EC004518",
    classGroup: "EG000013",
    artClassDesc: "Fused connection unit",
    artClassVersion: 2,
    label: "EC004518: Fused connection unit"
  },
  {
    classId: "EC004519",
    classGroup: "EG015530",
    artClassDesc: "Feed-through pipe (marine/offshore)",
    artClassVersion: 2,
    label: "EC004519: Feed-through pipe (marine/offshore)"
  },
  {
    classId: "EC004524",
    classGroup: "EG015610",
    artClassDesc: "Thermal shut-off device (gas)",
    artClassVersion: 2,
    label: "EC004524: Thermal shut-off device (gas)"
  },
  {
    classId: "EC004525",
    classGroup: "EG015610",
    artClassDesc: "Gas flow monitor",
    artClassVersion: 2,
    label: "EC004525: Gas flow monitor"
  },
  {
    classId: "EC004527",
    classGroup: "EG020002",
    artClassDesc: "Accessories/spare parts for core drill bit",
    artClassVersion: 1,
    label: "EC004527: Accessories/spare parts for core drill bit"
  },
  {
    classId: "EC004528",
    classGroup: "EG015610",
    artClassDesc: "Gas meter connecting piece",
    artClassVersion: 2,
    label: "EC004528: Gas meter connecting piece"
  },
  {
    classId: "EC004529",
    classGroup: "EG015610",
    artClassDesc: "Gas socket",
    artClassVersion: 2,
    label: "EC004529: Gas socket"
  },
  {
    classId: "EC004530",
    classGroup: "EG015510",
    artClassDesc: "Gas hose (push connection)",
    artClassVersion: 2,
    label: "EC004530: Gas hose (push connection)"
  },
  {
    classId: "EC004531",
    classGroup: "EG000010",
    artClassDesc: "Warning mesh (cables and underground application)",
    artClassVersion: 2,
    label: "EC004531: Warning mesh (cables and underground application)"
  },
  {
    classId: "EC004532",
    classGroup: "EG015130",
    artClassDesc: "Underground connection pit for cables/pipes",
    artClassVersion: 1,
    label: "EC004532: Underground connection pit for cables/pipes"
  },
  {
    classId: "EC004534",
    classGroup: "EG000043",
    artClassDesc: "Dual towel drying radiator",
    artClassVersion: 2,
    label: "EC004534: Dual towel drying radiator"
  },
  {
    classId: "EC004535",
    classGroup: "EG020002",
    artClassDesc: "Carbide burrs",
    artClassVersion: 1,
    label: "EC004535: Carbide burrs"
  },
  {
    classId: "EC004536",
    classGroup: "EG000043",
    artClassDesc: "Accessories/spare parts for solar compact installation",
    artClassVersion: 2,
    label: "EC004536: Accessories/spare parts for solar compact installation"
  },
  {
    classId: "EC004537",
    classGroup: "EG020002",
    artClassDesc: "Carbide burrs set",
    artClassVersion: 1,
    label: "EC004537: Carbide burrs set"
  },
  {
    classId: "EC004538",
    classGroup: "EG000006",
    artClassDesc: "Accessories/spare parts for steel trunking",
    artClassVersion: 1,
    label: "EC004538: Accessories/spare parts for steel trunking"
  },
  {
    classId: "EC004539",
    classGroup: "EG015210",
    artClassDesc: "Heat pump air ventilation system with hot tap water production",
    artClassVersion: 2,
    label: "EC004539: Heat pump air ventilation system with hot tap water production"
  },
  {
    classId: "EC004540",
    classGroup: "EG000043",
    artClassDesc: "Liquid mix vessel with filling pump",
    artClassVersion: 2,
    label: "EC004540: Liquid mix vessel with filling pump"
  },
  {
    classId: "EC004541",
    classGroup: "EG000039",
    artClassDesc: "Drying cabinet",
    artClassVersion: 2,
    label: "EC004541: Drying cabinet"
  },
  {
    classId: "EC004542",
    classGroup: "EG017130",
    artClassDesc: "Double wall/built-in element wall-hung closet/bidet/washbasin",
    artClassVersion: 2,
    label: "EC004542: Double wall/built-in element wall-hung closet/bidet/washbasin"
  },
  {
    classId: "EC004543",
    classGroup: "EG000006",
    artClassDesc: "Connection set for wall duct",
    artClassVersion: 1,
    label: "EC004543: Connection set for wall duct"
  },
  {
    classId: "EC004544",
    classGroup: "EG017610",
    artClassDesc: "Water tap system",
    artClassVersion: 2,
    label: "EC004544: Water tap system"
  },
  {
    classId: "EC004545",
    classGroup: "EG017630",
    artClassDesc: "Accessories/spare parts for water tap systems",
    artClassVersion: 1,
    label: "EC004545: Accessories/spare parts for water tap systems"
  },
  {
    classId: "EC004546",
    classGroup: "EG000010",
    artClassDesc: "Drain pipe support for concrete floor",
    artClassVersion: 1,
    label: "EC004546: Drain pipe support for concrete floor"
  },
  {
    classId: "EC004547",
    classGroup: "EG015540",
    artClassDesc: "Plug stop for fitting",
    artClassVersion: 1,
    label: "EC004547: Plug stop for fitting"
  },
  {
    classId: "EC004548",
    classGroup: "EG017140",
    artClassDesc: "Accessories/spare parts for flush actuator flushing system",
    artClassVersion: 2,
    label: "EC004548: Accessories/spare parts for flush actuator flushing system"
  },
  {
    classId: "EC004552",
    classGroup: "EG000019",
    artClassDesc: "Residual current monitoring relay with transformer",
    artClassVersion: 1,
    label: "EC004552: Residual current monitoring relay with transformer"
  },
  {
    classId: "EC004555",
    classGroup: "EG015260",
    artClassDesc: "Accessories/spare parts for hydraulic control station",
    artClassVersion: 2,
    label: "EC004555: Accessories/spare parts for hydraulic control station"
  },
  {
    classId: "EC004556",
    classGroup: "EG000020",
    artClassDesc: "HRC fuse",
    artClassVersion: 2,
    label: "EC004556: HRC fuse"
  },
  {
    classId: "EC004558",
    classGroup: "EG015610",
    artClassDesc: "Low water safety",
    artClassVersion: 2,
    label: "EC004558: Low water safety"
  },
  {
    classId: "EC004559",
    classGroup: "EG015640",
    artClassDesc: "Accessories/spare parts for low water safety",
    artClassVersion: 1,
    label: "EC004559: Accessories/spare parts for low water safety"
  },
  {
    classId: "EC004560",
    classGroup: "EG015540",
    artClassDesc: "Built-in piece with connection flange for tap water treatment devices",
    artClassVersion: 1,
    label: "EC004560: Built-in piece with connection flange for tap water treatment devices"
  },
  {
    classId: "EC004563",
    classGroup: "EG015640",
    artClassDesc: "Accessories/spare parts for temperature safety relief valve",
    artClassVersion: 1,
    label: "EC004563: Accessories/spare parts for temperature safety relief valve"
  },
  {
    classId: "EC004565",
    classGroup: "EG015140",
    artClassDesc: "Accessories/spare parts for air-/dirt separator",
    artClassVersion: 2,
    label: "EC004565: Accessories/spare parts for air-/dirt separator"
  },
  {
    classId: "EC004566",
    classGroup: "EG015260",
    artClassDesc: "Hydrophore",
    artClassVersion: 1,
    label: "EC004566: Hydrophore"
  },
  {
    classId: "EC004568",
    classGroup: "EG000046",
    artClassDesc: "Electrical insulating glove",
    artClassVersion: 2,
    label: "EC004568: Electrical insulating glove"
  },
  {
    classId: "EC004570",
    classGroup: "EG015640",
    artClassDesc: "Accessories/spare parts for backflow preventer",
    artClassVersion: 2,
    label: "EC004570: Accessories/spare parts for backflow preventer"
  },
  {
    classId: "EC004571",
    classGroup: "EG015140",
    artClassDesc: "Accessories/spare parts for water softener",
    artClassVersion: 2,
    label: "EC004571: Accessories/spare parts for water softener"
  },
  {
    classId: "EC004572",
    classGroup: "EG015260",
    artClassDesc: "Accessories/spare parts for central heating refill installation",
    artClassVersion: 1,
    label: "EC004572: Accessories/spare parts for central heating refill installation"
  },
  {
    classId: "EC004573",
    classGroup: "EG015540",
    artClassDesc: "Accessories/spare parts for pipe-in-pipe system",
    artClassVersion: 2,
    label: "EC004573: Accessories/spare parts for pipe-in-pipe system"
  },
  {
    classId: "EC004574",
    classGroup: "EG000046",
    artClassDesc: "Electrical insulating footwear",
    artClassVersion: 2,
    label: "EC004574: Electrical insulating footwear"
  },
  {
    classId: "EC004575",
    classGroup: "EG000046",
    artClassDesc: "Electrically insulating overshoe",
    artClassVersion: 2,
    label: "EC004575: Electrically insulating overshoe"
  },
  {
    classId: "EC004577",
    classGroup: "EG015260",
    artClassDesc: "Split-case pump",
    artClassVersion: 2,
    label: "EC004577: Split-case pump"
  },
  {
    classId: "EC004578",
    classGroup: "EG015610",
    artClassDesc: "Thermal insulation for valve",
    artClassVersion: 2,
    label: "EC004578: Thermal insulation for valve"
  },
  {
    classId: "EC004580",
    classGroup: "EG017210",
    artClassDesc: "Accessories/spare parts for wall rail adapted sanitary fittings",
    artClassVersion: 1,
    label: "EC004580: Accessories/spare parts for wall rail adapted sanitary fittings"
  },
  {
    classId: "EC004581",
    classGroup: "EG000046",
    artClassDesc: "Boundary post",
    artClassVersion: 2,
    label: "EC004581: Boundary post"
  },
  {
    classId: "EC004582",
    classGroup: "EG000017",
    artClassDesc: "Low voltage dry type transformer",
    artClassVersion: 1,
    label: "EC004582: Low voltage dry type transformer"
  },
  {
    classId: "EC004584",
    classGroup: "EG000050",
    artClassDesc: "Insulating runged ladder",
    artClassVersion: 1,
    label: "EC004584: Insulating runged ladder"
  },
  {
    classId: "EC004585",
    classGroup: "EG000050",
    artClassDesc: "Insulating stepladder",
    artClassVersion: 2,
    label: "EC004585: Insulating stepladder"
  },
  {
    classId: "EC004587",
    classGroup: "EG000011",
    artClassDesc: "Thermostat/hygrostat (enclosure/cabinet)",
    artClassVersion: 2,
    label: "EC004587: Thermostat/hygrostat (enclosure/cabinet)"
  },
  {
    classId: "EC004588",
    classGroup: "EG000010",
    artClassDesc: "Cable cleat",
    artClassVersion: 1,
    label: "EC004588: Cable cleat"
  },
  {
    classId: "EC004589",
    classGroup: "EG000017",
    artClassDesc: "Mains protection device",
    artClassVersion: 2,
    label: "EC004589: Mains protection device"
  },
  {
    classId: "EC004591",
    classGroup: "EG000015",
    artClassDesc: "Installation clamp for meters",
    artClassVersion: 1,
    label: "EC004591: Installation clamp for meters"
  },
  {
    classId: "EC004592",
    classGroup: "EG000015",
    artClassDesc: "Accessories/spare parts for installation clamp for meters",
    artClassVersion: 1,
    label: "EC004592: Accessories/spare parts for installation clamp for meters"
  },
  {
    classId: "EC004593",
    classGroup: "EG000055",
    artClassDesc: "Energy storage system",
    artClassVersion: 1,
    label: "EC004593: Energy storage system"
  },
  {
    classId: "EC004594",
    classGroup: "EG017610",
    artClassDesc: "Auxiliary connection valve",
    artClassVersion: 2,
    label: "EC004594: Auxiliary connection valve"
  },
  {
    classId: "EC004595",
    classGroup: "EG017640",
    artClassDesc: "Accessories/spare parts for shower column/panel",
    artClassVersion: 1,
    label: "EC004595: Accessories/spare parts for shower column/panel"
  },
  {
    classId: "EC004596",
    classGroup: "EG015610",
    artClassDesc: "Gas meter valve",
    artClassVersion: 2,
    label: "EC004596: Gas meter valve"
  },
  {
    classId: "EC004597",
    classGroup: "EG000054",
    artClassDesc: "Fog machine",
    artClassVersion: 1,
    label: "EC004597: Fog machine"
  },
  {
    classId: "EC004598",
    classGroup: "EG000055",
    artClassDesc: "PVT (photovoltaic thermal) panel",
    artClassVersion: 1,
    label: "EC004598: PVT (photovoltaic thermal) panel"
  },
  {
    classId: "EC004600",
    classGroup: "EG020004",
    artClassDesc: "Band gate hinge",
    artClassVersion: 1,
    label: "EC004600: Band gate hinge"
  },
  {
    classId: "EC004601",
    classGroup: "EG020004",
    artClassDesc: "Gudgeon gate hinge",
    artClassVersion: 1,
    label: "EC004601: Gudgeon gate hinge"
  },
  {
    classId: "EC004602",
    classGroup: "EG015540",
    artClassDesc: "Accessories/spare parts for broaching saddle",
    artClassVersion: 1,
    label: "EC004602: Accessories/spare parts for broaching saddle"
  },
  {
    classId: "EC004603",
    classGroup: "EG015610",
    artClassDesc: "Four-way sluice valve",
    artClassVersion: 1,
    label: "EC004603: Four-way sluice valve"
  },
  {
    classId: "EC004604",
    classGroup: "EG020450",
    artClassDesc: "Lid/grid for vehicular and pedestrian areas",
    artClassVersion: 1,
    label: "EC004604: Lid/grid for vehicular and pedestrian areas"
  },
  {
    classId: "EC004607",
    classGroup: "EG015230",
    artClassDesc: "Fixed air conditioner (monobloc)",
    artClassVersion: 1,
    label: "EC004607: Fixed air conditioner (monobloc)"
  },
  {
    classId: "EC004609",
    classGroup: "EG018110",
    artClassDesc: "Fitting insulation",
    artClassVersion: 1,
    label: "EC004609: Fitting insulation"
  },
  {
    classId: "EC004610",
    classGroup: "EG015560",
    artClassDesc: "Wall/roof feed-through rectangular air duct",
    artClassVersion: 1,
    label: "EC004610: Wall/roof feed-through rectangular air duct"
  },
  {
    classId: "EC004611",
    classGroup: "EG000026",
    artClassDesc: "Remote pulse reader",
    artClassVersion: 1,
    label: "EC004611: Remote pulse reader"
  },
  {
    classId: "EC004612",
    classGroup: "EG017210",
    artClassDesc: "Bath mat",
    artClassVersion: 1,
    label: "EC004612: Bath mat"
  },
  {
    classId: "EC004613",
    classGroup: "EG000044",
    artClassDesc: "Acoustic camera",
    artClassVersion: 1,
    label: "EC004613: Acoustic camera"
  },
  {
    classId: "EC004617",
    classGroup: "EG017960",
    artClassDesc: "Drawer front",
    artClassVersion: 1,
    label: "EC004617: Drawer front"
  },
  {
    classId: "EC004620",
    classGroup: "EG015810",
    artClassDesc: "Electronic volume converter with temperature and pressure compensation",
    artClassVersion: 1,
    label: "EC004620: Electronic volume converter with temperature and pressure compensation"
  },
  {
    classId: "EC004621",
    classGroup: "EG015810",
    artClassDesc: "Accessories/spare parts for electronic volume conversion device (EVCD)",
    artClassVersion: 1,
    label: "EC004621: Accessories/spare parts for electronic volume conversion device (EVCD)"
  },
  {
    classId: "EC004622",
    classGroup: "EG017960",
    artClassDesc: "Furniture board",
    artClassVersion: 1,
    label: "EC004622: Furniture board"
  },
  {
    classId: "EC004623",
    classGroup: "EG017960",
    artClassDesc: "Accessories/spare parts for furniture",
    artClassVersion: 1,
    label: "EC004623: Accessories/spare parts for furniture"
  },
  {
    classId: "EC004624",
    classGroup: "EG017960",
    artClassDesc: "Relax lounger",
    artClassVersion: 1,
    label: "EC004624: Relax lounger"
  },
  {
    classId: "EC004625",
    classGroup: "EG017210",
    artClassDesc: "Bath pillow",
    artClassVersion: 1,
    label: "EC004625: Bath pillow"
  },
  {
    classId: "EC004629",
    classGroup: "EG015540",
    artClassDesc: "Leak protection sealing sleeve",
    artClassVersion: 1,
    label: "EC004629: Leak protection sealing sleeve"
  },
  {
    classId: "EC004630",
    classGroup: "EG017910",
    artClassDesc: "Accessories/spare parts for waterproof covering for bathroom",
    artClassVersion: 1,
    label: "EC004630: Accessories/spare parts for waterproof covering for bathroom"
  },
  {
    classId: "EC004631",
    classGroup: "EG017110",
    artClassDesc: "Accessories/spare parts for kitchen sink",
    artClassVersion: 1,
    label: "EC004631: Accessories/spare parts for kitchen sink"
  },
  {
    classId: "EC004632",
    classGroup: "EG000039",
    artClassDesc: "Leakage indication tray",
    artClassVersion: 1,
    label: "EC004632: Leakage indication tray"
  },
  {
    classId: "EC004633",
    classGroup: "EG019510",
    artClassDesc: "Floor carrier",
    artClassVersion: 1,
    label: "EC004633: Floor carrier"
  },
  {
    classId: "EC004634",
    classGroup: "EG000060",
    artClassDesc: "Accessories/spare parts for leakage indication tray",
    artClassVersion: 1,
    label: "EC004634: Accessories/spare parts for leakage indication tray"
  },
  {
    classId: "EC004635",
    classGroup: "EG000020",
    artClassDesc: "Medium/high voltage fuse base",
    artClassVersion: 1,
    label: "EC004635: Medium/high voltage fuse base"
  },
  {
    classId: "EC004637",
    classGroup: "EG015910",
    artClassDesc: "Fibre flange gasket",
    artClassVersion: 1,
    label: "EC004637: Fibre flange gasket"
  },
  {
    classId: "EC004639",
    classGroup: "EG000049",
    artClassDesc: "Coil spring",
    artClassVersion: 1,
    label: "EC004639: Coil spring"
  },
  {
    classId: "EC004640",
    classGroup: "EG000044",
    artClassDesc: "Solar irradiance meter",
    artClassVersion: 1,
    label: "EC004640: Solar irradiance meter"
  },
  {
    classId: "EC004641",
    classGroup: "EG015260",
    artClassDesc: "Diffuser for clarifiers",
    artClassVersion: 1,
    label: "EC004641: Diffuser for clarifiers"
  },
  {
    classId: "EC004642",
    classGroup: "EG017960",
    artClassDesc: "Accessories/spare parts for washbasin top cabinet",
    artClassVersion: 1,
    label: "EC004642: Accessories/spare parts for washbasin top cabinet"
  },
  {
    classId: "EC004643",
    classGroup: "EG017210",
    artClassDesc: "Accessories/spare parts for module sanitary accessories",
    artClassVersion: 1,
    label: "EC004643: Accessories/spare parts for module sanitary accessories"
  },
  {
    classId: "EC004644",
    classGroup: "EG017210",
    artClassDesc: "Shower toilet seat (manual)",
    artClassVersion: 1,
    label: "EC004644: Shower toilet seat (manual)"
  },
  {
    classId: "EC004645",
    classGroup: "EG000017",
    artClassDesc: "Smart sensor",
    artClassVersion: 1,
    label: "EC004645: Smart sensor"
  },
  {
    classId: "EC004646",
    classGroup: "EG017110",
    artClassDesc: "Accessories/spare parts for (wellness) shower unit",
    artClassVersion: 1,
    label: "EC004646: Accessories/spare parts for (wellness) shower unit"
  },
  {
    classId: "EC004649",
    classGroup: "EG000057",
    artClassDesc: "Pre-wired installation box",
    artClassVersion: 1,
    label: "EC004649: Pre-wired installation box"
  },
  {
    classId: "EC004650",
    classGroup: "EG000049",
    artClassDesc: "Compression spring",
    artClassVersion: 1,
    label: "EC004650: Compression spring"
  },
  {
    classId: "EC004652",
    classGroup: "EG000044",
    artClassDesc: "Photovoltaic testing device",
    artClassVersion: 1,
    label: "EC004652: Photovoltaic testing device"
  },
  {
    classId: "EC004653",
    classGroup: "EG017130",
    artClassDesc: "Double wall/built-in element with wall-hung closet and flushing reservoir",
    artClassVersion: 1,
    label: "EC004653: Double wall/built-in element with wall-hung closet and flushing reservoir"
  },
  {
    classId: "EC004654",
    classGroup: "EG015540",
    artClassDesc: "Fitting for pre-insulated plastic pipe",
    artClassVersion: 1,
    label: "EC004654: Fitting for pre-insulated plastic pipe"
  },
  {
    classId: "EC004655",
    classGroup: "EG015220",
    artClassDesc: "Defrost heater element for heat recovery ventilation unit",
    artClassVersion: 1,
    label: "EC004655: Defrost heater element for heat recovery ventilation unit"
  },
  {
    classId: "EC004656",
    classGroup: "EG015540",
    artClassDesc: "Square fitting with 3 connections",
    artClassVersion: 1,
    label: "EC004656: Square fitting with 3 connections"
  },
  {
    classId: "EC004657",
    classGroup: "EG000051",
    artClassDesc: "Flushing compressor",
    artClassVersion: 1,
    label: "EC004657: Flushing compressor"
  },
  {
    classId: "EC004658",
    classGroup: "EG000050",
    artClassDesc: "Accessories/spare parts for manual cleaning device",
    artClassVersion: 1,
    label: "EC004658: Accessories/spare parts for manual cleaning device"
  },
  {
    classId: "EC004660",
    classGroup: "EG010320",
    artClassDesc: "Crane hook",
    artClassVersion: 1,
    label: "EC004660: Crane hook"
  },
  {
    classId: "EC004661",
    classGroup: "EG020001",
    artClassDesc: "Line fixing for power cable for overhead lines",
    artClassVersion: 1,
    label: "EC004661: Line fixing for power cable for overhead lines"
  },
  {
    classId: "EC004662",
    classGroup: "EG000049",
    artClassDesc: "Roof-, facade, sandwich panel mounting screw",
    artClassVersion: 1,
    label: "EC004662: Roof-, facade, sandwich panel mounting screw"
  },
  {
    classId: "EC004663",
    classGroup: "EG015130",
    artClassDesc: "Rat blocker for drains",
    artClassVersion: 1,
    label: "EC004663: Rat blocker for drains"
  },
  {
    classId: "EC004664",
    classGroup: "EG000002",
    artClassDesc: "Accessories/spare parts for pipes/hoses for cable installation",
    artClassVersion: 1,
    label: "EC004664: Accessories/spare parts for pipes/hoses for cable installation"
  },
  {
    classId: "EC004665",
    classGroup: "EG000040",
    artClassDesc: "Manual shaver",
    artClassVersion: 1,
    label: "EC004665: Manual shaver"
  },
  {
    classId: "EC004666",
    classGroup: "EG000041",
    artClassDesc: "Weather station",
    artClassVersion: 1,
    label: "EC004666: Weather station"
  },
  {
    classId: "EC004667",
    classGroup: "EG000017",
    artClassDesc: "Discrete electronic component",
    artClassVersion: 1,
    label: "EC004667: Discrete electronic component"
  },
  {
    classId: "EC004668",
    classGroup: "EG020006",
    artClassDesc: "Clipboard",
    artClassVersion: 1,
    label: "EC004668: Clipboard"
  },
  {
    classId: "EC004669",
    classGroup: "EG020006",
    artClassDesc: "Punched pocket/cut flush folder",
    artClassVersion: 1,
    label: "EC004669: Punched pocket/cut flush folder"
  },
  {
    classId: "EC004671",
    classGroup: "EG000019",
    artClassDesc: "Alarm annunciator",
    artClassVersion: 1,
    label: "EC004671: Alarm annunciator"
  },
  {
    classId: "EC004672",
    classGroup: "EG000026",
    artClassDesc: "Machine vision system (MVS)",
    artClassVersion: 1,
    label: "EC004672: Machine vision system (MVS)"
  },
  {
    classId: "EC004673",
    classGroup: "EG000026",
    artClassDesc: "Force/weighing sensor",
    artClassVersion: 1,
    label: "EC004673: Force/weighing sensor"
  },
  {
    classId: "EC004675",
    classGroup: "EG015620",
    artClassDesc: "Vacuum suction cup",
    artClassVersion: 1,
    label: "EC004675: Vacuum suction cup"
  },
  {
    classId: "EC004676",
    classGroup: "EG015640",
    artClassDesc: "Valve lockout device",
    artClassVersion: 1,
    label: "EC004676: Valve lockout device"
  },
  {
    classId: "EC004677",
    classGroup: "EG000059",
    artClassDesc: "Lockout/tagout kits",
    artClassVersion: 1,
    label: "EC004677: Lockout/tagout kits"
  },
  {
    classId: "EC004678",
    classGroup: "EG000034",
    artClassDesc: "Turnstile",
    artClassVersion: 1,
    label: "EC004678: Turnstile"
  },
  {
    classId: "EC004679",
    classGroup: "EG020100",
    artClassDesc: "Rendering and plastering mortar",
    artClassVersion: 1,
    label: "EC004679: Rendering and plastering mortar"
  },
  {
    classId: "EC004681",
    classGroup: "EG000050",
    artClassDesc: "Duster",
    artClassVersion: 1,
    label: "EC004681: Duster"
  },
  {
    classId: "EC004682",
    classGroup: "EG000050",
    artClassDesc: "Mop wringer",
    artClassVersion: 1,
    label: "EC004682: Mop wringer"
  },
  {
    classId: "EC004684",
    classGroup: "EG015530",
    artClassDesc: "Surface heating/cooling register (water-supplied)",
    artClassVersion: 1,
    label: "EC004684: Surface heating/cooling register (water-supplied)"
  },
  {
    classId: "EC004685",
    classGroup: "EG020023",
    artClassDesc: "Rescue cutter/spreader",
    artClassVersion: 1,
    label: "EC004685: Rescue cutter/spreader"
  },
  {
    classId: "EC004686",
    classGroup: "EG000040",
    artClassDesc: "Vacuum cleaner (battery)",
    artClassVersion: 1,
    label: "EC004686: Vacuum cleaner (battery)"
  },
  {
    classId: "EC004687",
    classGroup: "EG000051",
    artClassDesc: "Hot air gun (battery)",
    artClassVersion: 1,
    label: "EC004687: Hot air gun (battery)"
  },
  {
    classId: "EC004688",
    classGroup: "EG015560",
    artClassDesc: "Non-return valve flue gas",
    artClassVersion: 1,
    label: "EC004688: Non-return valve flue gas"
  },
  {
    classId: "EC004689",
    classGroup: "EG000010",
    artClassDesc: "Accessories/spare parts for installation material for cables and tubes",
    artClassVersion: 1,
    label: "EC004689: Accessories/spare parts for installation material for cables and tubes"
  },
  {
    classId: "EC004691",
    classGroup: "EG000050",
    artClassDesc: "Tap wrench extension",
    artClassVersion: 1,
    label: "EC004691: Tap wrench extension"
  },
  {
    classId: "EC004692",
    classGroup: "EG000026",
    artClassDesc: "Universal signal converter",
    artClassVersion: 1,
    label: "EC004692: Universal signal converter"
  },
  {
    classId: "EC004693",
    classGroup: "EG020006",
    artClassDesc: "Sharpener",
    artClassVersion: 1,
    label: "EC004693: Sharpener"
  },
  {
    classId: "EC004694",
    classGroup: "EG000049",
    artClassDesc: "Nail sleeve",
    artClassVersion: 1,
    label: "EC004694: Nail sleeve"
  },
  {
    classId: "EC004695",
    classGroup: "EG015560",
    artClassDesc: "Air supply grid for concentric flue gas/air supply system",
    artClassVersion: 1,
    label: "EC004695: Air supply grid for concentric flue gas/air supply system"
  },
  {
    classId: "EC004699",
    classGroup: "EG015560",
    artClassDesc: "Flue gas siphon",
    artClassVersion: 1,
    label: "EC004699: Flue gas siphon"
  },
  {
    classId: "EC004702",
    classGroup: "EG015560",
    artClassDesc: "Chimney extension attachment",
    artClassVersion: 1,
    label: "EC004702: Chimney extension attachment"
  },
  {
    classId: "EC004703",
    classGroup: "EG020006",
    artClassDesc: "Correction supplies",
    artClassVersion: 1,
    label: "EC004703: Correction supplies"
  },
  {
    classId: "EC004704",
    classGroup: "EG020001",
    artClassDesc: "Arcing device",
    artClassVersion: 1,
    label: "EC004704: Arcing device"
  },
  {
    classId: "EC004705",
    classGroup: "EG015610",
    artClassDesc: "Accessories/spare parts for blow-off cock",
    artClassVersion: 1,
    label: "EC004705: Accessories/spare parts for blow-off cock"
  },
  {
    classId: "EC004707",
    classGroup: "EG015610",
    artClassDesc: "Accessories/spare parts for break tank station",
    artClassVersion: 1,
    label: "EC004707: Accessories/spare parts for break tank station"
  },
  {
    classId: "EC004708",
    classGroup: "EG017610",
    artClassDesc: "Accessories/spare parts for outdoor faucet",
    artClassVersion: 1,
    label: "EC004708: Accessories/spare parts for outdoor faucet"
  },
  {
    classId: "EC004709",
    classGroup: "EG015610",
    artClassDesc: "Accessories/spare parts for drinking water cooler/circulator",
    artClassVersion: 1,
    label: "EC004709: Accessories/spare parts for drinking water cooler/circulator"
  },
  {
    classId: "EC004710",
    classGroup: "EG015610",
    artClassDesc: "Accessories/spare parts for hygiene-flushing box/flushing device",
    artClassVersion: 1,
    label: "EC004710: Accessories/spare parts for hygiene-flushing box/flushing device"
  },
  {
    classId: "EC004711",
    classGroup: "EG015810",
    artClassDesc: "Accessories/spare parts for pressure reducing valve",
    artClassVersion: 1,
    label: "EC004711: Accessories/spare parts for pressure reducing valve"
  },
  {
    classId: "EC004712",
    classGroup: "EG000054",
    artClassDesc: "Combined access control/intercom system",
    artClassVersion: 1,
    label: "EC004712: Combined access control/intercom system"
  },
  {
    classId: "EC004716",
    classGroup: "EG015610",
    artClassDesc: "Temperature sensor fitting",
    artClassVersion: 1,
    label: "EC004716: Temperature sensor fitting"
  },
  {
    classId: "EC004717",
    classGroup: "EG000038",
    artClassDesc: "Computer mouse",
    artClassVersion: 1,
    label: "EC004717: Computer mouse"
  },
  {
    classId: "EC004718",
    classGroup: "EG000038",
    artClassDesc: "Laptop",
    artClassVersion: 1,
    label: "EC004718: Laptop"
  },
  {
    classId: "EC004719",
    classGroup: "EG000043",
    artClassDesc: "Wood burning stove",
    artClassVersion: 1,
    label: "EC004719: Wood burning stove"
  },
  {
    classId: "EC004720",
    classGroup: "EG015220",
    artClassDesc: "Accessories/spare parts for heat recovery unit for residential construction",
    artClassVersion: 1,
    label: "EC004720: Accessories/spare parts for heat recovery unit for residential construction"
  },
  {
    classId: "EC004721",
    classGroup: "EG015560",
    artClassDesc: "Support rail for flue gas system",
    artClassVersion: 1,
    label: "EC004721: Support rail for flue gas system"
  },
  {
    classId: "EC004722",
    classGroup: "EG019720",
    artClassDesc: "Sound absorbing panel",
    artClassVersion: 1,
    label: "EC004722: Sound absorbing panel"
  },
  {
    classId: "EC004723",
    classGroup: "EG020006",
    artClassDesc: "Desk pad",
    artClassVersion: 1,
    label: "EC004723: Desk pad"
  },
  {
    classId: "EC004724",
    classGroup: "EG020006",
    artClassDesc: "Mouse/keyboard/wrist rest pad",
    artClassVersion: 1,
    label: "EC004724: Mouse/keyboard/wrist rest pad"
  },
  {
    classId: "EC004726",
    classGroup: "EG020006",
    artClassDesc: "Desk organizer",
    artClassVersion: 1,
    label: "EC004726: Desk organizer"
  },
  {
    classId: "EC004729",
    classGroup: "EG017610",
    artClassDesc: "Inlet fitting flushing system",
    artClassVersion: 1,
    label: "EC004729: Inlet fitting flushing system"
  },
  {
    classId: "EC004731",
    classGroup: "EG020025",
    artClassDesc: "Roof walkway",
    artClassVersion: 1,
    label: "EC004731: Roof walkway"
  },
  {
    classId: "EC004732",
    classGroup: "EG020100",
    artClassDesc: "Primer and bonding agent",
    artClassVersion: 1,
    label: "EC004732: Primer and bonding agent"
  },
  {
    classId: "EC004733",
    classGroup: "EG000044",
    artClassDesc: "Solution for liquid analysis sensor",
    artClassVersion: 1,
    label: "EC004733: Solution for liquid analysis sensor"
  },
  {
    classId: "EC004734",
    classGroup: "EG020500",
    artClassDesc: "Wind barrier foil/plate",
    artClassVersion: 1,
    label: "EC004734: Wind barrier foil/plate"
  },
  {
    classId: "EC004735",
    classGroup: "EG000054",
    artClassDesc: "Security illuminator",
    artClassVersion: 1,
    label: "EC004735: Security illuminator"
  },
  {
    classId: "EC004736",
    classGroup: "EG020000",
    artClassDesc: "Pallet box/frame",
    artClassVersion: 1,
    label: "EC004736: Pallet box/frame"
  },
  {
    classId: "EC004737",
    classGroup: "EG020000",
    artClassDesc: "Sweeping machine, hand operated (manual)",
    artClassVersion: 1,
    label: "EC004737: Sweeping machine, hand operated (manual)"
  },
  {
    classId: "EC004738",
    classGroup: "EG020024",
    artClassDesc: "Trailer hitch",
    artClassVersion: 1,
    label: "EC004738: Trailer hitch"
  },
  {
    classId: "EC004739",
    classGroup: "EG020024",
    artClassDesc: "Vehicle cable assembly",
    artClassVersion: 1,
    label: "EC004739: Vehicle cable assembly"
  },
  {
    classId: "EC004741",
    classGroup: "EG020024",
    artClassDesc: "Seat cover",
    artClassVersion: 1,
    label: "EC004741: Seat cover"
  },
  {
    classId: "EC004742",
    classGroup: "EG000050",
    artClassDesc: "Accessories/spare parts for label maker",
    artClassVersion: 1,
    label: "EC004742: Accessories/spare parts for label maker"
  },
  {
    classId: "EC004743",
    classGroup: "EG000014",
    artClassDesc: "Accessories/spare parts for CEE socket outlet combination (IEC 60309)",
    artClassVersion: 1,
    label: "EC004743: Accessories/spare parts for CEE socket outlet combination (IEC 60309)"
  },
  {
    classId: "EC004744",
    classGroup: "EG000044",
    artClassDesc: "Ratiometer",
    artClassVersion: 1,
    label: "EC004744: Ratiometer"
  },
  {
    classId: "EC004745",
    classGroup: "EG000044",
    artClassDesc: "Laboratory power supply",
    artClassVersion: 1,
    label: "EC004745: Laboratory power supply"
  },
  {
    classId: "EC004746",
    classGroup: "EG000037",
    artClassDesc: "Fibre optic attenuator",
    artClassVersion: 1,
    label: "EC004746: Fibre optic attenuator"
  },
  {
    classId: "EC004747",
    classGroup: "EG000051",
    artClassDesc: "Agitator (battery)",
    artClassVersion: 1,
    label: "EC004747: Agitator (battery)"
  },
  {
    classId: "EC004748",
    classGroup: "EG015120",
    artClassDesc: "Drum pump (manual)",
    artClassVersion: 1,
    label: "EC004748: Drum pump (manual)"
  },
  {
    classId: "EC004749",
    classGroup: "EG015120",
    artClassDesc: "Drum pump (compressed air)",
    artClassVersion: 1,
    label: "EC004749: Drum pump (compressed air)"
  },
  {
    classId: "EC004750",
    classGroup: "EG010320",
    artClassDesc: "Balancer",
    artClassVersion: 1,
    label: "EC004750: Balancer"
  },
  {
    classId: "EC004751",
    classGroup: "EG010320",
    artClassDesc: "Beam clamp",
    artClassVersion: 1,
    label: "EC004751: Beam clamp"
  },
  {
    classId: "EC004752",
    classGroup: "EG020020",
    artClassDesc: "Magnet",
    artClassVersion: 1,
    label: "EC004752: Magnet"
  },
  {
    classId: "EC004753",
    classGroup: "EG017130",
    artClassDesc: "Wall box for built-in mixer",
    artClassVersion: 1,
    label: "EC004753: Wall box for built-in mixer"
  },
  {
    classId: "EC004754",
    classGroup: "EG000040",
    artClassDesc: "Insecticide/pesticide",
    artClassVersion: 1,
    label: "EC004754: Insecticide/pesticide"
  },
  {
    classId: "EC004755",
    classGroup: "EG020018",
    artClassDesc: "Gripper (pneumatic/hydraulic)",
    artClassVersion: 1,
    label: "EC004755: Gripper (pneumatic/hydraulic)"
  },
  {
    classId: "EC004756",
    classGroup: "EG020006",
    artClassDesc: "Pencil eraser",
    artClassVersion: 1,
    label: "EC004756: Pencil eraser"
  },
  {
    classId: "EC004759",
    classGroup: "EG019760",
    artClassDesc: "Foundation transit set",
    artClassVersion: 1,
    label: "EC004759: Foundation transit set"
  },
  {
    classId: "EC004760",
    classGroup: "EG000051",
    artClassDesc: "Polisher (battery)",
    artClassVersion: 1,
    label: "EC004760: Polisher (battery)"
  },
  {
    classId: "EC004761",
    classGroup: "EG000014",
    artClassDesc: "Plug according to ANSI/NEMA WD-6",
    artClassVersion: 1,
    label: "EC004761: Plug according to ANSI/NEMA WD-6"
  },
  {
    classId: "EC004762",
    classGroup: "EG020025",
    artClassDesc: "Fastening element for roof safety",
    artClassVersion: 1,
    label: "EC004762: Fastening element for roof safety"
  },
  {
    classId: "EC004763",
    classGroup: "EG020008",
    artClassDesc: "Window-sill cover",
    artClassVersion: 1,
    label: "EC004763: Window-sill cover"
  },
  {
    classId: "EC004764",
    classGroup: "EG017960",
    artClassDesc: "Accessories/spare parts for wardrobe",
    artClassVersion: 1,
    label: "EC004764: Accessories/spare parts for wardrobe"
  },
  {
    classId: "EC004765",
    classGroup: "EG000014",
    artClassDesc: "Plug according to AS/NZS 3123",
    artClassVersion: 1,
    label: "EC004765: Plug according to AS/NZS 3123"
  },
  {
    classId: "EC004766",
    classGroup: "EG020650",
    artClassDesc: "Waterproofing mortar",
    artClassVersion: 1,
    label: "EC004766: Waterproofing mortar"
  },
  {
    classId: "EC004767",
    classGroup: "EG012000",
    artClassDesc: "Wall panel profile",
    artClassVersion: 1,
    label: "EC004767: Wall panel profile"
  },
  {
    classId: "EC004768",
    classGroup: "EG020700",
    artClassDesc: "Windbreak",
    artClassVersion: 1,
    label: "EC004768: Windbreak"
  },
  {
    classId: "EC004769",
    classGroup: "EG017610",
    artClassDesc: "Accessories/spare parts for central mixing valve, hot tap water",
    artClassVersion: 1,
    label: "EC004769: Accessories/spare parts for central mixing valve, hot tap water"
  },
  {
    classId: "EC004770",
    classGroup: "EG015810",
    artClassDesc: "Accessories/spare parts for safety valve",
    artClassVersion: 1,
    label: "EC004770: Accessories/spare parts for safety valve"
  },
  {
    classId: "EC004771",
    classGroup: "EG017920",
    artClassDesc: "Modular bathroom",
    artClassVersion: 1,
    label: "EC004771: Modular bathroom"
  },
  {
    classId: "EC004772",
    classGroup: "EG015810",
    artClassDesc: "Accessories/spare parts for hot water boiler connection group",
    artClassVersion: 1,
    label: "EC004772: Accessories/spare parts for hot water boiler connection group"
  },
  {
    classId: "EC004773",
    classGroup: "EG020025",
    artClassDesc: "Fixed mounted ladder",
    artClassVersion: 1,
    label: "EC004773: Fixed mounted ladder"
  },
  {
    classId: "EC004774",
    classGroup: "EG020025",
    artClassDesc: "Roof snow guard",
    artClassVersion: 1,
    label: "EC004774: Roof snow guard"
  },
  {
    classId: "EC004775",
    classGroup: "EG020025",
    artClassDesc: "Roof ridge/eaves rail",
    artClassVersion: 1,
    label: "EC004775: Roof ridge/eaves rail"
  },
  {
    classId: "EC004777",
    classGroup: "EG020400",
    artClassDesc: "Fibre reinforced plate",
    artClassVersion: 1,
    label: "EC004777: Fibre reinforced plate"
  },
  {
    classId: "EC004780",
    classGroup: "EG015560",
    artClassDesc: "Extension for flue gas roof duct (concentric)",
    artClassVersion: 1,
    label: "EC004780: Extension for flue gas roof duct (concentric)"
  },
  {
    classId: "EC004781",
    classGroup: "EG000059",
    artClassDesc: "Voltage indicator (low voltage)",
    artClassVersion: 1,
    label: "EC004781: Voltage indicator (low voltage)"
  },
  {
    classId: "EC004782",
    classGroup: "EG000056",
    artClassDesc: "Cladding for fire-resistant duct",
    artClassVersion: 1,
    label: "EC004782: Cladding for fire-resistant duct"
  },
  {
    classId: "EC004784",
    classGroup: "EG020025",
    artClassDesc: "Accessories/spare parts for roof safety",
    artClassVersion: 1,
    label: "EC004784: Accessories/spare parts for roof safety"
  },
  {
    classId: "EC004785",
    classGroup: "EG020400",
    artClassDesc: "Weather bar",
    artClassVersion: 1,
    label: "EC004785: Weather bar"
  },
  {
    classId: "EC004786",
    classGroup: "EG017610",
    artClassDesc: "Shut-off valve combination",
    artClassVersion: 1,
    label: "EC004786: Shut-off valve combination"
  },
  {
    classId: "EC004787",
    classGroup: "EG000014",
    artClassDesc: "Coupler according to ANSI/NEMA WD-6",
    artClassVersion: 1,
    label: "EC004787: Coupler according to ANSI/NEMA WD-6"
  },
  {
    classId: "EC004788",
    classGroup: "EG000053",
    artClassDesc: "Inductive charger for portable devices",
    artClassVersion: 1,
    label: "EC004788: Inductive charger for portable devices"
  },
  {
    classId: "EC004789",
    classGroup: "EG000014",
    artClassDesc: "Coupling according to AS/NZS 3123",
    artClassVersion: 1,
    label: "EC004789: Coupling according to AS/NZS 3123"
  },
  {
    classId: "EC004790",
    classGroup: "EG020400",
    artClassDesc: "Chipboard",
    artClassVersion: 1,
    label: "EC004790: Chipboard"
  },
  {
    classId: "EC004791",
    classGroup: "EG000014",
    artClassDesc: "Socket outlet according to ANSI/NEMA WD-6",
    artClassVersion: 1,
    label: "EC004791: Socket outlet according to ANSI/NEMA WD-6"
  },
  {
    classId: "EC004792",
    classGroup: "EG000014",
    artClassDesc: "Appliance inlet according to AS/NZS 3123",
    artClassVersion: 1,
    label: "EC004792: Appliance inlet according to AS/NZS 3123"
  },
  {
    classId: "EC004793",
    classGroup: "EG000014",
    artClassDesc: "Socket outlet according to AS/NZS 3123",
    artClassVersion: 1,
    label: "EC004793: Socket outlet according to AS/NZS 3123"
  },
  {
    classId: "EC004795",
    classGroup: "EG000044",
    artClassDesc: "Battery tester",
    artClassVersion: 1,
    label: "EC004795: Battery tester"
  },
  {
    classId: "EC004796",
    classGroup: "EG000044",
    artClassDesc: "Pressure calibration reference module",
    artClassVersion: 1,
    label: "EC004796: Pressure calibration reference module"
  },
  {
    classId: "EC004799",
    classGroup: "EG000054",
    artClassDesc: "CO2 monitor",
    artClassVersion: 1,
    label: "EC004799: CO2 monitor"
  },
  {
    classId: "EC004803",
    classGroup: "EG000005",
    artClassDesc: "Levelling set for subfloor systems",
    artClassVersion: 1,
    label: "EC004803: Levelling set for subfloor systems"
  },
  {
    classId: "EC004804",
    classGroup: "EG000040",
    artClassDesc: "Milk cooler for espresso machine",
    artClassVersion: 1,
    label: "EC004804: Milk cooler for espresso machine"
  },
  {
    classId: "EC004805",
    classGroup: "EG010210",
    artClassDesc: "Accessories/spare parts for cutting/grinding disc",
    artClassVersion: 1,
    label: "EC004805: Accessories/spare parts for cutting/grinding disc"
  },
  {
    classId: "EC004806",
    classGroup: "EG000039",
    artClassDesc: "Pizza oven",
    artClassVersion: 1,
    label: "EC004806: Pizza oven"
  },
  {
    classId: "EC004807",
    classGroup: "EG000026",
    artClassDesc: "Safety radar",
    artClassVersion: 1,
    label: "EC004807: Safety radar"
  },
  {
    classId: "EC004808",
    classGroup: "EG020008",
    artClassDesc: "Window hinge",
    artClassVersion: 1,
    label: "EC004808: Window hinge"
  },
  {
    classId: "EC004811",
    classGroup: "EG015550",
    artClassDesc: "Exhaust gas kit single wall",
    artClassVersion: 1,
    label: "EC004811: Exhaust gas kit single wall"
  },
  {
    classId: "EC004812",
    classGroup: "EG015550",
    artClassDesc: "Exhaust gas kit double walled",
    artClassVersion: 1,
    label: "EC004812: Exhaust gas kit double walled"
  },
  {
    classId: "EC004813",
    classGroup: "EG015550",
    artClassDesc: "Exhaust gas kit concentric",
    artClassVersion: 1,
    label: "EC004813: Exhaust gas kit concentric"
  },
  {
    classId: "EC004815",
    classGroup: "EG019510",
    artClassDesc: "Decoupling membrane",
    artClassVersion: 1,
    label: "EC004815: Decoupling membrane"
  },
  {
    classId: "EC004818",
    classGroup: "EG000059",
    artClassDesc: "Voltage indicator (medium voltage)",
    artClassVersion: 1,
    label: "EC004818: Voltage indicator (medium voltage)"
  },
  {
    classId: "EC004819",
    classGroup: "EG020004",
    artClassDesc: "Rail for swing door drive",
    artClassVersion: 1,
    label: "EC004819: Rail for swing door drive"
  },
  {
    classId: "EC004821",
    classGroup: "EG000061",
    artClassDesc: "Plasma torch",
    artClassVersion: 1,
    label: "EC004821: Plasma torch"
  },
  {
    classId: "EC004822",
    classGroup: "EG020001",
    artClassDesc: "Medium voltage secondary switchgear",
    artClassVersion: 1,
    label: "EC004822: Medium voltage secondary switchgear"
  },
  {
    classId: "EC004823",
    classGroup: "EG020001",
    artClassDesc: "Medium voltage indoor vacuum contactor",
    artClassVersion: 1,
    label: "EC004823: Medium voltage indoor vacuum contactor"
  },
  {
    classId: "EC004824",
    classGroup: "EG000014",
    artClassDesc: "Mounting post for E-Mobility",
    artClassVersion: 1,
    label: "EC004824: Mounting post for E-Mobility"
  },
  {
    classId: "EC004825",
    classGroup: "EG015610",
    artClassDesc: "Accessories/spare parts for shut down valve (tap water)",
    artClassVersion: 1,
    label: "EC004825: Accessories/spare parts for shut down valve (tap water)"
  },
  {
    classId: "EC004826",
    classGroup: "EG000019",
    artClassDesc: "Protection relay test switch",
    artClassVersion: 1,
    label: "EC004826: Protection relay test switch"
  },
  {
    classId: "EC004827",
    classGroup: "EG015210",
    artClassDesc: "Heat pump (air/air, monoblock) for heat recovery unit",
    artClassVersion: 1,
    label: "EC004827: Heat pump (air/air, monoblock) for heat recovery unit"
  },
  {
    classId: "EC004828",
    classGroup: "EG010330",
    artClassDesc: "Mobile light tower",
    artClassVersion: 1,
    label: "EC004828: Mobile light tower"
  },
  {
    classId: "EC004829",
    classGroup: "EG000050",
    artClassDesc: "Tube deformation tester",
    artClassVersion: 1,
    label: "EC004829: Tube deformation tester"
  },
  {
    classId: "EC004830",
    classGroup: "EG020001",
    artClassDesc: "Medium voltage indoor switch disconnector",
    artClassVersion: 1,
    label: "EC004830: Medium voltage indoor switch disconnector"
  },
  {
    classId: "EC004831",
    classGroup: "EG020001",
    artClassDesc: "Medium voltage indoor current/voltage sensor",
    artClassVersion: 1,
    label: "EC004831: Medium voltage indoor current/voltage sensor"
  },
  {
    classId: "EC004832",
    classGroup: "EG000061",
    artClassDesc: "Plasma cutter, portable",
    artClassVersion: 1,
    label: "EC004832: Plasma cutter, portable"
  },
  {
    classId: "EC004834",
    classGroup: "EG020004",
    artClassDesc: "Swing door drive",
    artClassVersion: 1,
    label: "EC004834: Swing door drive"
  },
  {
    classId: "EC004835",
    classGroup: "EG020008",
    artClassDesc: "Interface module for window drives",
    artClassVersion: 1,
    label: "EC004835: Interface module for window drives"
  },
  {
    classId: "EC004836",
    classGroup: "EG020008",
    artClassDesc: "Opening/locking system for windows/door windows",
    artClassVersion: 1,
    label: "EC004836: Opening/locking system for windows/door windows"
  },
  {
    classId: "EC004837",
    classGroup: "EG020008",
    artClassDesc: "Catch and cleaning protection for fanlight opener",
    artClassVersion: 1,
    label: "EC004837: Catch and cleaning protection for fanlight opener"
  },
  {
    classId: "EC004838",
    classGroup: "EG020008",
    artClassDesc: "Synchronising unit for window opening drives",
    artClassVersion: 1,
    label: "EC004838: Synchronising unit for window opening drives"
  },
  {
    classId: "EC004839",
    classGroup: "EG020008",
    artClassDesc: "Turn-/tilt limiter for windows/door windows",
    artClassVersion: 1,
    label: "EC004839: Turn-/tilt limiter for windows/door windows"
  },
  {
    classId: "EC004840",
    classGroup: "EG020008",
    artClassDesc: "Drive for windows/door windows",
    artClassVersion: 1,
    label: "EC004840: Drive for windows/door windows"
  },
  {
    classId: "EC004842",
    classGroup: "EG020650",
    artClassDesc: "Mortar additive",
    artClassVersion: 1,
    label: "EC004842: Mortar additive"
  },
  {
    classId: "EC004843",
    classGroup: "EG020350",
    artClassDesc: "Facade design element",
    artClassVersion: 1,
    label: "EC004843: Facade design element"
  },
  {
    classId: "EC004844",
    classGroup: "EG000060",
    artClassDesc: "Storage/transport reel",
    artClassVersion: 1,
    label: "EC004844: Storage/transport reel"
  },
  {
    classId: "EC010000",
    classGroup: "EG000049",
    artClassDesc: "Wall guide plate for plastic pipes",
    artClassVersion: 5,
    label: "EC010000: Wall guide plate for plastic pipes"
  },
  {
    classId: "EC010001",
    classGroup: "EG000049",
    artClassDesc: "Fixing cap, foundation bracket",
    artClassVersion: 4,
    label: "EC010001: Fixing cap, foundation bracket"
  },
  {
    classId: "EC010002",
    classGroup: "EG015610",
    artClassDesc: "Radiator insert",
    artClassVersion: 5,
    label: "EC010002: Radiator insert"
  },
  {
    classId: "EC010003",
    classGroup: "EG015840",
    artClassDesc: "Immersion thermostat",
    artClassVersion: 6,
    label: "EC010003: Immersion thermostat"
  },
  {
    classId: "EC010004",
    classGroup: "EG015260",
    artClassDesc: "Domestic fan",
    artClassVersion: 8,
    label: "EC010004: Domestic fan"
  },
  {
    classId: "EC010005",
    classGroup: "EG015240",
    artClassDesc: "Floor air grille, rectangular front",
    artClassVersion: 3,
    label: "EC010005: Floor air grille, rectangular front"
  },
  {
    classId: "EC010006",
    classGroup: "EG015210",
    artClassDesc: "Oil preburner",
    artClassVersion: 4,
    label: "EC010006: Oil preburner"
  },
  {
    classId: "EC010007",
    classGroup: "EG015240",
    artClassDesc: "Electric air curtain",
    artClassVersion: 6,
    label: "EC010007: Electric air curtain"
  },
  {
    classId: "EC010008",
    classGroup: "EG015810",
    artClassDesc: "Float-controlled trap",
    artClassVersion: 7,
    label: "EC010008: Float-controlled trap"
  },
  {
    classId: "EC010009",
    classGroup: "EG015580",
    artClassDesc: "Flue cover",
    artClassVersion: 5,
    label: "EC010009: Flue cover"
  },
  {
    classId: "EC010010",
    classGroup: "EG015560",
    artClassDesc: "Kitchen extraction box",
    artClassVersion: 1,
    label: "EC010010: Kitchen extraction box"
  },
  {
    classId: "EC010011",
    classGroup: "EG017210",
    artClassDesc: "Bath lift",
    artClassVersion: 3,
    label: "EC010011: Bath lift"
  },
  {
    classId: "EC010012",
    classGroup: "EG000049",
    artClassDesc: "Foundation bracket",
    artClassVersion: 4,
    label: "EC010012: Foundation bracket"
  },
  {
    classId: "EC010013",
    classGroup: "EG017960",
    artClassDesc: "Bathroom vanity",
    artClassVersion: 5,
    label: "EC010013: Bathroom vanity"
  },
  {
    classId: "EC010014",
    classGroup: "EG015810",
    artClassDesc: "Constant flow rate controller",
    artClassVersion: 8,
    label: "EC010014: Constant flow rate controller"
  },
  {
    classId: "EC010015",
    classGroup: "EG015560",
    artClassDesc: "Single-walled flue gas attachment, 3 connections",
    artClassVersion: 7,
    label: "EC010015: Single-walled flue gas attachment, 3 connections"
  },
  {
    classId: "EC010016",
    classGroup: "EG000049",
    artClassDesc: "Ground spike, foundation bracket",
    artClassVersion: 4,
    label: "EC010016: Ground spike, foundation bracket"
  },
  {
    classId: "EC010017",
    classGroup: "EG017210",
    artClassDesc: "Front support",
    artClassVersion: 3,
    label: "EC010017: Front support"
  },
  {
    classId: "EC010018",
    classGroup: "EG015840",
    artClassDesc: "Fancoil thermostat",
    artClassVersion: 3,
    label: "EC010018: Fancoil thermostat"
  },
  {
    classId: "EC010020",
    classGroup: "EG015520",
    artClassDesc: "Storz reduced coupling",
    artClassVersion: 2,
    label: "EC010020: Storz reduced coupling"
  },
  {
    classId: "EC010021",
    classGroup: "EG015610",
    artClassDesc: "Accessories/spare parts for alarm valve",
    artClassVersion: 5,
    label: "EC010021: Accessories/spare parts for alarm valve"
  },
  {
    classId: "EC010022",
    classGroup: "EG015810",
    artClassDesc: "Deaerator/aerator (combined)",
    artClassVersion: 8,
    label: "EC010022: Deaerator/aerator (combined)"
  },
  {
    classId: "EC010023",
    classGroup: "EG017610",
    artClassDesc: "Mechanical urinal flusher",
    artClassVersion: 7,
    label: "EC010023: Mechanical urinal flusher"
  },
  {
    classId: "EC010024",
    classGroup: "EG015610",
    artClassDesc: "Three-way plug valve",
    artClassVersion: 7,
    label: "EC010024: Three-way plug valve"
  },
  {
    classId: "EC010025",
    classGroup: "EG017210",
    artClassDesc: "Shower/toilet seat",
    artClassVersion: 4,
    label: "EC010025: Shower/toilet seat"
  },
  {
    classId: "EC010026",
    classGroup: "EG000049",
    artClassDesc: "Mounting set, ribbed fixing strap",
    artClassVersion: 4,
    label: "EC010026: Mounting set, ribbed fixing strap"
  },
  {
    classId: "EC010027",
    classGroup: "EG015560",
    artClassDesc: "T-piece double-walled round air duct",
    artClassVersion: 8,
    label: "EC010027: T-piece double-walled round air duct"
  },
  {
    classId: "EC010029",
    classGroup: "EG017210",
    artClassDesc: "Washbasin extension",
    artClassVersion: 3,
    label: "EC010029: Washbasin extension"
  },
  {
    classId: "EC010030",
    classGroup: "EG015610",
    artClassDesc: "Pneumatic controlled three-way control valve",
    artClassVersion: 7,
    label: "EC010030: Pneumatic controlled three-way control valve"
  },
  {
    classId: "EC010032",
    classGroup: "EG015530",
    artClassDesc: "Thin-walled steel pipe",
    artClassVersion: 6,
    label: "EC010032: Thin-walled steel pipe"
  },
  {
    classId: "EC010033",
    classGroup: "EG015560",
    artClassDesc: "Plastic slide plate, feed-through tile",
    artClassVersion: 5,
    label: "EC010033: Plastic slide plate, feed-through tile"
  },
  {
    classId: "EC010034",
    classGroup: "EG017920",
    artClassDesc: "Corner entrance shower enclosure rectangular",
    artClassVersion: 5,
    label: "EC010034: Corner entrance shower enclosure rectangular"
  },
  {
    classId: "EC010036",
    classGroup: "EG015110",
    artClassDesc: "Outlet clamping piece roof gutter",
    artClassVersion: 5,
    label: "EC010036: Outlet clamping piece roof gutter"
  },
  {
    classId: "EC010037",
    classGroup: "EG000026",
    artClassDesc: "Flue temperature sensor, active",
    artClassVersion: 6,
    label: "EC010037: Flue temperature sensor, active"
  },
  {
    classId: "EC010038",
    classGroup: "EG017920",
    artClassDesc: "Round shower wall",
    artClassVersion: 5,
    label: "EC010038: Round shower wall"
  },
  {
    classId: "EC010039",
    classGroup: "EG017610",
    artClassDesc: "Laboratory tap",
    artClassVersion: 5,
    label: "EC010039: Laboratory tap"
  },
  {
    classId: "EC010040",
    classGroup: "EG000049",
    artClassDesc: "Sliding piece",
    artClassVersion: 6,
    label: "EC010040: Sliding piece"
  },
  {
    classId: "EC010041",
    classGroup: "EG015620",
    artClassDesc: "Spindle extension for valve",
    artClassVersion: 2,
    label: "EC010041: Spindle extension for valve"
  },
  {
    classId: "EC010042",
    classGroup: "EG017550",
    artClassDesc: "Triple trap",
    artClassVersion: 4,
    label: "EC010042: Triple trap"
  },
  {
    classId: "EC010043",
    classGroup: "EG017550",
    artClassDesc: "Flush pipe extension pipe",
    artClassVersion: 4,
    label: "EC010043: Flush pipe extension pipe"
  },
  {
    classId: "EC010045",
    classGroup: "EG015210",
    artClassDesc: "Solar heater tank",
    artClassVersion: 5,
    label: "EC010045: Solar heater tank"
  },
  {
    classId: "EC010046",
    classGroup: "EG017920",
    artClassDesc: "Pentagonal shower wall",
    artClassVersion: 5,
    label: "EC010046: Pentagonal shower wall"
  },
  {
    classId: "EC010047",
    classGroup: "EG015610",
    artClassDesc: "De-aeration valve (manual)",
    artClassVersion: 6,
    label: "EC010047: De-aeration valve (manual)"
  },
  {
    classId: "EC010048",
    classGroup: "EG015610",
    artClassDesc: "Accessories/spare parts for radiator fittings",
    artClassVersion: 7,
    label: "EC010048: Accessories/spare parts for radiator fittings"
  },
  {
    classId: "EC010049",
    classGroup: "EG000049",
    artClassDesc: "Two-piece bracket round",
    artClassVersion: 7,
    label: "EC010049: Two-piece bracket round"
  },
  {
    classId: "EC010050",
    classGroup: "EG017210",
    artClassDesc: "Wall rail, adapted sanitary fittings",
    artClassVersion: 3,
    label: "EC010050: Wall rail, adapted sanitary fittings"
  },
  {
    classId: "EC010051",
    classGroup: "EG015560",
    artClassDesc: "Cross piece, round air duct",
    artClassVersion: 5,
    label: "EC010051: Cross piece, round air duct"
  },
  {
    classId: "EC010052",
    classGroup: "EG015640",
    artClassDesc: "Operation key (inner square) for valve/faucet",
    artClassVersion: 5,
    label: "EC010052: Operation key (inner square) for valve/faucet"
  },
  {
    classId: "EC010054",
    classGroup: "EG015110",
    artClassDesc: "Connection piece roof gutter",
    artClassVersion: 5,
    label: "EC010054: Connection piece roof gutter"
  },
  {
    classId: "EC010055",
    classGroup: "EG015260",
    artClassDesc: "Submersible faeces pump",
    artClassVersion: 8,
    label: "EC010055: Submersible faeces pump"
  },
  {
    classId: "EC010056",
    classGroup: "EG015560",
    artClassDesc: "Bend rectangular air duct",
    artClassVersion: 5,
    label: "EC010056: Bend rectangular air duct"
  },
  {
    classId: "EC010057",
    classGroup: "EG015840",
    artClassDesc: "Zone controller",
    artClassVersion: 3,
    label: "EC010057: Zone controller"
  },
  {
    classId: "EC010058",
    classGroup: "EG015610",
    artClassDesc: "Shut down valve (tap water)",
    artClassVersion: 6,
    label: "EC010058: Shut down valve (tap water)"
  },
  {
    classId: "EC010059",
    classGroup: "EG015830",
    artClassDesc: "Glass thermometer",
    artClassVersion: 8,
    label: "EC010059: Glass thermometer"
  },
  {
    classId: "EC010061",
    classGroup: "EG015710",
    artClassDesc: "Mechanical accessories, ventilator convector",
    artClassVersion: 3,
    label: "EC010061: Mechanical accessories, ventilator convector"
  },
  {
    classId: "EC010062",
    classGroup: "EG017110",
    artClassDesc: "Height adjustment system toilet",
    artClassVersion: 4,
    label: "EC010062: Height adjustment system toilet"
  },
  {
    classId: "EC010063",
    classGroup: "EG017140",
    artClassDesc: "Actuator plate for flushing system",
    artClassVersion: 6,
    label: "EC010063: Actuator plate for flushing system"
  },
  {
    classId: "EC010064",
    classGroup: "EG015610",
    artClassDesc: "Thermostatic control head for valve",
    artClassVersion: 7,
    label: "EC010064: Thermostatic control head for valve"
  },
  {
    classId: "EC010066",
    classGroup: "EG015580",
    artClassDesc: "Roof vent cover",
    artClassVersion: 6,
    label: "EC010066: Roof vent cover"
  },
  {
    classId: "EC010067",
    classGroup: "EG015240",
    artClassDesc: "Air curtain without heat exchanger",
    artClassVersion: 5,
    label: "EC010067: Air curtain without heat exchanger"
  },
  {
    classId: "EC010068",
    classGroup: "EG017920",
    artClassDesc: "Accessories/spare parts for shower wall",
    artClassVersion: 5,
    label: "EC010068: Accessories/spare parts for shower wall"
  },
  {
    classId: "EC010069",
    classGroup: "EG015510",
    artClassDesc: "Plastic hose, insulated with spiral",
    artClassVersion: 5,
    label: "EC010069: Plastic hose, insulated with spiral"
  },
  {
    classId: "EC010070",
    classGroup: "EG015530",
    artClassDesc: "Profiled pipe, aluminium",
    artClassVersion: 6,
    label: "EC010070: Profiled pipe, aluminium"
  },
  {
    classId: "EC010071",
    classGroup: "EG015610",
    artClassDesc: "Fill and drain valve",
    artClassVersion: 6,
    label: "EC010071: Fill and drain valve"
  },
  {
    classId: "EC010072",
    classGroup: "EG015810",
    artClassDesc: "Thermostatic de-aerator (steam)",
    artClassVersion: 6,
    label: "EC010072: Thermostatic de-aerator (steam)"
  },
  {
    classId: "EC010074",
    classGroup: "EG000049",
    artClassDesc: "Waste stack bracket set",
    artClassVersion: 5,
    label: "EC010074: Waste stack bracket set"
  },
  {
    classId: "EC010075",
    classGroup: "EG015610",
    artClassDesc: "Four-way plug valve",
    artClassVersion: 7,
    label: "EC010075: Four-way plug valve"
  },
  {
    classId: "EC010076",
    classGroup: "EG015260",
    artClassDesc: "Norm block pump with base plate",
    artClassVersion: 7,
    label: "EC010076: Norm block pump with base plate"
  },
  {
    classId: "EC010077",
    classGroup: "EG015540",
    artClassDesc: "Bottom H-block",
    artClassVersion: 8,
    label: "EC010077: Bottom H-block"
  },
  {
    classId: "EC010078",
    classGroup: "EG017210",
    artClassDesc: "Toilet support",
    artClassVersion: 6,
    label: "EC010078: Toilet support"
  },
  {
    classId: "EC010079",
    classGroup: "EG000006",
    artClassDesc: "Accessories/spare parts for pipe duct",
    artClassVersion: 5,
    label: "EC010079: Accessories/spare parts for pipe duct"
  },
  {
    classId: "EC010080",
    classGroup: "EG015140",
    artClassDesc: "Bucket filter, single",
    artClassVersion: 7,
    label: "EC010080: Bucket filter, single"
  },
  {
    classId: "EC010081",
    classGroup: "EG020250",
    artClassDesc: "Cleaning product",
    artClassVersion: 5,
    label: "EC010081: Cleaning product"
  },
  {
    classId: "EC010082",
    classGroup: "EG015210",
    artClassDesc: "Electric boiler",
    artClassVersion: 4,
    label: "EC010082: Electric boiler"
  },
  {
    classId: "EC010083",
    classGroup: "EG017210",
    artClassDesc: "Recess for ashtray holder",
    artClassVersion: 1,
    label: "EC010083: Recess for ashtray holder"
  },
  {
    classId: "EC010085",
    classGroup: "EG015520",
    artClassDesc: "Storz blind cap",
    artClassVersion: 2,
    label: "EC010085: Storz blind cap"
  },
  {
    classId: "EC010087",
    classGroup: "EG019490",
    artClassDesc: "Radiator window-sill support",
    artClassVersion: 4,
    label: "EC010087: Radiator window-sill support"
  },
  {
    classId: "EC010089",
    classGroup: "EG017130",
    artClassDesc: "Double wall/built-in element bath/shower mixer",
    artClassVersion: 6,
    label: "EC010089: Double wall/built-in element bath/shower mixer"
  },
  {
    classId: "EC010090",
    classGroup: "EG015610",
    artClassDesc: "Wall built-in control set for floor heating",
    artClassVersion: 5,
    label: "EC010090: Wall built-in control set for floor heating"
  },
  {
    classId: "EC010092",
    classGroup: "EG017210",
    artClassDesc: "Accessories/spare parts for shower stretcher/dressing table",
    artClassVersion: 4,
    label: "EC010092: Accessories/spare parts for shower stretcher/dressing table"
  },
  {
    classId: "EC010093",
    classGroup: "EG015560",
    artClassDesc: "Pot embedding duct",
    artClassVersion: 5,
    label: "EC010093: Pot embedding duct"
  },
  {
    classId: "EC010094",
    classGroup: "EG000049",
    artClassDesc: "Double pipe bracket",
    artClassVersion: 6,
    label: "EC010094: Double pipe bracket"
  },
  {
    classId: "EC010097",
    classGroup: "EG019490",
    artClassDesc: "Radiator spacer",
    artClassVersion: 5,
    label: "EC010097: Radiator spacer"
  },
  {
    classId: "EC010098",
    classGroup: "EG015580",
    artClassDesc: "Roof upstand prefab chimney single family house",
    artClassVersion: 5,
    label: "EC010098: Roof upstand prefab chimney single family house"
  },
  {
    classId: "EC010099",
    classGroup: "EG015240",
    artClassDesc: "Gauze grille",
    artClassVersion: 4,
    label: "EC010099: Gauze grille"
  },
  {
    classId: "EC010100",
    classGroup: "EG017210",
    artClassDesc: "Dressing table",
    artClassVersion: 4,
    label: "EC010100: Dressing table"
  },
  {
    classId: "EC010101",
    classGroup: "EG015560",
    artClassDesc: "Concentric flue gas/air supply form piece, 3 connections",
    artClassVersion: 6,
    label: "EC010101: Concentric flue gas/air supply form piece, 3 connections"
  },
  {
    classId: "EC010102",
    classGroup: "EG015610",
    artClassDesc: "Four-way ball valve",
    artClassVersion: 7,
    label: "EC010102: Four-way ball valve"
  },
  {
    classId: "EC010103",
    classGroup: "EG015560",
    artClassDesc: "Outlet nozzle, round air duct",
    artClassVersion: 5,
    label: "EC010103: Outlet nozzle, round air duct"
  },
  {
    classId: "EC010104",
    classGroup: "EG015610",
    artClassDesc: "Radiator connection combination with valve and bottom block",
    artClassVersion: 7,
    label: "EC010104: Radiator connection combination with valve and bottom block"
  },
  {
    classId: "EC010105",
    classGroup: "EG015110",
    artClassDesc: "Wreath piece",
    artClassVersion: 3,
    label: "EC010105: Wreath piece"
  },
  {
    classId: "EC010106",
    classGroup: "EG000026",
    artClassDesc: "Liquid level sensor",
    artClassVersion: 8,
    label: "EC010106: Liquid level sensor"
  },
  {
    classId: "EC010107",
    classGroup: "EG015620",
    artClassDesc: "Accessories/spare parts for membrane valve",
    artClassVersion: 5,
    label: "EC010107: Accessories/spare parts for membrane valve"
  },
  {
    classId: "EC010108",
    classGroup: "EG015540",
    artClassDesc: "Mounting console for wall plate",
    artClassVersion: 3,
    label: "EC010108: Mounting console for wall plate"
  },
  {
    classId: "EC010109",
    classGroup: "EG017640",
    artClassDesc: "Sliding rod combination",
    artClassVersion: 6,
    label: "EC010109: Sliding rod combination"
  },
  {
    classId: "EC010110",
    classGroup: "EG015560",
    artClassDesc: "Flue gas roof duct",
    artClassVersion: 7,
    label: "EC010110: Flue gas roof duct"
  },
  {
    classId: "EC010111",
    classGroup: "EG015840",
    artClassDesc: "Accessories/spare parts for pressure difference switch",
    artClassVersion: 4,
    label: "EC010111: Accessories/spare parts for pressure difference switch"
  },
  {
    classId: "EC010113",
    classGroup: "EG000049",
    artClassDesc: "Floor support for pipe bracket",
    artClassVersion: 3,
    label: "EC010113: Floor support for pipe bracket"
  },
  {
    classId: "EC010114",
    classGroup: "EG015560",
    artClassDesc: "Branch piece rectangular air duct",
    artClassVersion: 4,
    label: "EC010114: Branch piece rectangular air duct"
  },
  {
    classId: "EC010115",
    classGroup: "EG015560",
    artClassDesc: "Feed-through roof tile",
    artClassVersion: 8,
    label: "EC010115: Feed-through roof tile"
  },
  {
    classId: "EC010116",
    classGroup: "EG015560",
    artClassDesc: "Round side connection, embedding duct",
    artClassVersion: 3,
    label: "EC010116: Round side connection, embedding duct"
  },
  {
    classId: "EC010117",
    classGroup: "EG015810",
    artClassDesc: "De-aerator (automatic)",
    artClassVersion: 7,
    label: "EC010117: De-aerator (automatic)"
  },
  {
    classId: "EC010118",
    classGroup: "EG015110",
    artClassDesc: "Separation strip roof gutter",
    artClassVersion: 3,
    label: "EC010118: Separation strip roof gutter"
  },
  {
    classId: "EC010119",
    classGroup: "EG015560",
    artClassDesc: "Feed-through plate",
    artClassVersion: 5,
    label: "EC010119: Feed-through plate"
  },
  {
    classId: "EC010120",
    classGroup: "EG015610",
    artClassDesc: "Globe valve",
    artClassVersion: 8,
    label: "EC010120: Globe valve"
  },
  {
    classId: "EC010121",
    classGroup: "EG015560",
    artClassDesc: "Bend round air duct",
    artClassVersion: 7,
    label: "EC010121: Bend round air duct"
  },
  {
    classId: "EC010122",
    classGroup: "EG015810",
    artClassDesc: "Pressure reducing valve",
    artClassVersion: 9,
    label: "EC010122: Pressure reducing valve"
  },
  {
    classId: "EC010123",
    classGroup: "EG015810",
    artClassDesc: "Accessories/spare parts for measuring device for flow and pressure controllers",
    artClassVersion: 4,
    label: "EC010123: Accessories/spare parts for measuring device for flow and pressure controllers"
  },
  {
    classId: "EC010124",
    classGroup: "EG017210",
    artClassDesc: "Recess for waste bin",
    artClassVersion: 2,
    label: "EC010124: Recess for waste bin"
  },
  {
    classId: "EC010125",
    classGroup: "EG015840",
    artClassDesc: "Security switchboard for smoke detectors",
    artClassVersion: 3,
    label: "EC010125: Security switchboard for smoke detectors"
  },
  {
    classId: "EC010126",
    classGroup: "EG017110",
    artClassDesc: "Bidet",
    artClassVersion: 6,
    label: "EC010126: Bidet"
  },
  {
    classId: "EC010128",
    classGroup: "EG017920",
    artClassDesc: "Side for bath and shower",
    artClassVersion: 8,
    label: "EC010128: Side for bath and shower"
  },
  {
    classId: "EC010129",
    classGroup: "EG017550",
    artClassDesc: "Double trap",
    artClassVersion: 4,
    label: "EC010129: Double trap"
  },
  {
    classId: "EC010130",
    classGroup: "EG019710",
    artClassDesc: "Concrete tie for embedding rail",
    artClassVersion: 5,
    label: "EC010130: Concrete tie for embedding rail"
  },
  {
    classId: "EC010131",
    classGroup: "EG015810",
    artClassDesc: "Aerator (pressureless system)",
    artClassVersion: 5,
    label: "EC010131: Aerator (pressureless system)"
  },
  {
    classId: "EC010132",
    classGroup: "EG017640",
    artClassDesc: "Hand shower holder",
    artClassVersion: 5,
    label: "EC010132: Hand shower holder"
  },
  {
    classId: "EC010133",
    classGroup: "EG015560",
    artClassDesc: "VEWI valve",
    artClassVersion: 3,
    label: "EC010133: VEWI valve"
  },
  {
    classId: "EC010134",
    classGroup: "EG015110",
    artClassDesc: "Separation partition roof gutter",
    artClassVersion: 2,
    label: "EC010134: Separation partition roof gutter"
  },
  {
    classId: "EC010135",
    classGroup: "EG015560",
    artClassDesc: "Lip seal, flue gas pipe",
    artClassVersion: 3,
    label: "EC010135: Lip seal, flue gas pipe"
  },
  {
    classId: "EC010136",
    classGroup: "EG015610",
    artClassDesc: "Radiator stop valve",
    artClassVersion: 9,
    label: "EC010136: Radiator stop valve"
  },
  {
    classId: "EC010137",
    classGroup: "EG015550",
    artClassDesc: "Rectangular air duct",
    artClassVersion: 6,
    label: "EC010137: Rectangular air duct"
  },
  {
    classId: "EC010138",
    classGroup: "EG015220",
    artClassDesc: "Central heating plate changer",
    artClassVersion: 5,
    label: "EC010138: Central heating plate changer"
  },
  {
    classId: "EC010140",
    classGroup: "EG017640",
    artClassDesc: "Sliding rod",
    artClassVersion: 7,
    label: "EC010140: Sliding rod"
  },
  {
    classId: "EC010141",
    classGroup: "EG017550",
    artClassDesc: "Flush pipe bend",
    artClassVersion: 7,
    label: "EC010141: Flush pipe bend"
  },
  {
    classId: "EC010142",
    classGroup: "EG017140",
    artClassDesc: "Flushing reservoir float valve",
    artClassVersion: 4,
    label: "EC010142: Flushing reservoir float valve"
  },
  {
    classId: "EC010143",
    classGroup: "EG015110",
    artClassDesc: "Leaf catcher roof discharge",
    artClassVersion: 4,
    label: "EC010143: Leaf catcher roof discharge"
  },
  {
    classId: "EC010144",
    classGroup: "EG017110",
    artClassDesc: "Washbasin leg",
    artClassVersion: 4,
    label: "EC010144: Washbasin leg"
  },
  {
    classId: "EC010145",
    classGroup: "EG017130",
    artClassDesc: "Double wall/built-in element for wall-hung toilet with flushing reservoir",
    artClassVersion: 7,
    label: "EC010145: Double wall/built-in element for wall-hung toilet with flushing reservoir"
  },
  {
    classId: "EC010146",
    classGroup: "EG017210",
    artClassDesc: "Glass/toothbrush holder",
    artClassVersion: 6,
    label: "EC010146: Glass/toothbrush holder"
  },
  {
    classId: "EC010147",
    classGroup: "EG015560",
    artClassDesc: "Slanting corner, embedding duct",
    artClassVersion: 4,
    label: "EC010147: Slanting corner, embedding duct"
  },
  {
    classId: "EC010150",
    classGroup: "EG000049",
    artClassDesc: "Rail beam clamp",
    artClassVersion: 5,
    label: "EC010150: Rail beam clamp"
  },
  {
    classId: "EC010151",
    classGroup: "EG015140",
    artClassDesc: "Pipeline strainer",
    artClassVersion: 7,
    label: "EC010151: Pipeline strainer"
  },
  {
    classId: "EC010152",
    classGroup: "EG015710",
    artClassDesc: "Convector radiator",
    artClassVersion: 6,
    label: "EC010152: Convector radiator"
  },
  {
    classId: "EC010153",
    classGroup: "EG000049",
    artClassDesc: "Carrying plate",
    artClassVersion: 6,
    label: "EC010153: Carrying plate"
  },
  {
    classId: "EC010156",
    classGroup: "EG015240",
    artClassDesc: "Perforated grille",
    artClassVersion: 5,
    label: "EC010156: Perforated grille"
  },
  {
    classId: "EC010159",
    classGroup: "EG000049",
    artClassDesc: "Inlay strap",
    artClassVersion: 4,
    label: "EC010159: Inlay strap"
  },
  {
    classId: "EC010160",
    classGroup: "EG015610",
    artClassDesc: "Accessories/spare parts for thermostatic control valve, hot tap water",
    artClassVersion: 6,
    label: "EC010160: Accessories/spare parts for thermostatic control valve, hot tap water"
  },
  {
    classId: "EC010161",
    classGroup: "EG017640",
    artClassDesc: "Hand shower",
    artClassVersion: 6,
    label: "EC010161: Hand shower"
  },
  {
    classId: "EC010162",
    classGroup: "EG015540",
    artClassDesc: "Wall plate on bracket",
    artClassVersion: 6,
    label: "EC010162: Wall plate on bracket"
  },
  {
    classId: "EC010163",
    classGroup: "EG015610",
    artClassDesc: "Gate valve",
    artClassVersion: 7,
    label: "EC010163: Gate valve"
  },
  {
    classId: "EC010164",
    classGroup: "EG015810",
    artClassDesc: "Accessories/spare parts for check valve",
    artClassVersion: 5,
    label: "EC010164: Accessories/spare parts for check valve"
  },
  {
    classId: "EC010165",
    classGroup: "EG017110",
    artClassDesc: "Shower toilet",
    artClassVersion: 4,
    label: "EC010165: Shower toilet"
  },
  {
    classId: "EC010166",
    classGroup: "EG015880",
    artClassDesc: "Pressure gauge console",
    artClassVersion: 5,
    label: "EC010166: Pressure gauge console"
  },
  {
    classId: "EC010167",
    classGroup: "EG015560",
    artClassDesc: "Loose flange rectangular air duct",
    artClassVersion: 4,
    label: "EC010167: Loose flange rectangular air duct"
  },
  {
    classId: "EC010168",
    classGroup: "EG015170",
    artClassDesc: "Plenum for air handling unit",
    artClassVersion: 2,
    label: "EC010168: Plenum for air handling unit"
  },
  {
    classId: "EC010170",
    classGroup: "EG015540",
    artClassDesc: "Compression ring reducing set",
    artClassVersion: 3,
    label: "EC010170: Compression ring reducing set"
  },
  {
    classId: "EC010171",
    classGroup: "EG015610",
    artClassDesc: "Pneumatic controlled two-way control valve",
    artClassVersion: 7,
    label: "EC010171: Pneumatic controlled two-way control valve"
  },
  {
    classId: "EC010172",
    classGroup: "EG017110",
    artClassDesc: "Squatting toilet",
    artClassVersion: 6,
    label: "EC010172: Squatting toilet"
  },
  {
    classId: "EC010175",
    classGroup: "EG017110",
    artClassDesc: "Toilet with faeces grinder",
    artClassVersion: 7,
    label: "EC010175: Toilet with faeces grinder"
  },
  {
    classId: "EC010177",
    classGroup: "EG017970",
    artClassDesc: "Washing machine element for construction wall",
    artClassVersion: 4,
    label: "EC010177: Washing machine element for construction wall"
  },
  {
    classId: "EC010178",
    classGroup: "EG015580",
    artClassDesc: "Prefab chimney, single-family home",
    artClassVersion: 4,
    label: "EC010178: Prefab chimney, single-family home"
  },
  {
    classId: "EC010179",
    classGroup: "EG017920",
    artClassDesc: "Bath screen",
    artClassVersion: 7,
    label: "EC010179: Bath screen"
  },
  {
    classId: "EC010180",
    classGroup: "EG015610",
    artClassDesc: "Venturi insert for central heating",
    artClassVersion: 4,
    label: "EC010180: Venturi insert for central heating"
  },
  {
    classId: "EC010181",
    classGroup: "EG015560",
    artClassDesc: "Cover rosette, round air duct",
    artClassVersion: 5,
    label: "EC010181: Cover rosette, round air duct"
  },
  {
    classId: "EC010182",
    classGroup: "EG019510",
    artClassDesc: "Natural stone tile",
    artClassVersion: 3,
    label: "EC010182: Natural stone tile"
  },
  {
    classId: "EC010183",
    classGroup: "EG015560",
    artClassDesc: "Hearth slide",
    artClassVersion: 5,
    label: "EC010183: Hearth slide"
  },
  {
    classId: "EC010184",
    classGroup: "EG015210",
    artClassDesc: "Storage tank (potable water system)",
    artClassVersion: 5,
    label: "EC010184: Storage tank (potable water system)"
  },
  {
    classId: "EC010186",
    classGroup: "EG015560",
    artClassDesc: "T-Y-piece round air duct",
    artClassVersion: 7,
    label: "EC010186: T-Y-piece round air duct"
  },
  {
    classId: "EC010187",
    classGroup: "EG017210",
    artClassDesc: "Adjustment system washbasin",
    artClassVersion: 3,
    label: "EC010187: Adjustment system washbasin"
  },
  {
    classId: "EC010188",
    classGroup: "EG015560",
    artClassDesc: "Round saddle piece for air duct",
    artClassVersion: 8,
    label: "EC010188: Round saddle piece for air duct"
  },
  {
    classId: "EC010189",
    classGroup: "EG015840",
    artClassDesc: "Water detection switching",
    artClassVersion: 7,
    label: "EC010189: Water detection switching"
  },
  {
    classId: "EC010190",
    classGroup: "EG015560",
    artClassDesc: "Chimney cover",
    artClassVersion: 2,
    label: "EC010190: Chimney cover"
  },
  {
    classId: "EC010191",
    classGroup: "EG015560",
    artClassDesc: "Chimney bracket construction",
    artClassVersion: 3,
    label: "EC010191: Chimney bracket construction"
  },
  {
    classId: "EC010192",
    classGroup: "EG017630",
    artClassDesc: "Tap coupling for top",
    artClassVersion: 3,
    label: "EC010192: Tap coupling for top"
  },
  {
    classId: "EC010193",
    classGroup: "EG015620",
    artClassDesc: "Thermal servomotor",
    artClassVersion: 6,
    label: "EC010193: Thermal servomotor"
  },
  {
    classId: "EC010194",
    classGroup: "EG017210",
    artClassDesc: "Accessories/spare parts for shower/toilet chair",
    artClassVersion: 4,
    label: "EC010194: Accessories/spare parts for shower/toilet chair"
  },
  {
    classId: "EC010195",
    classGroup: "EG017550",
    artClassDesc: "P-, S-, bottle siphon",
    artClassVersion: 6,
    label: "EC010195: P-, S-, bottle siphon"
  },
  {
    classId: "EC010196",
    classGroup: "EG019710",
    artClassDesc: "Construction plate for mounting rail",
    artClassVersion: 5,
    label: "EC010196: Construction plate for mounting rail"
  },
  {
    classId: "EC010197",
    classGroup: "EG019490",
    artClassDesc: "Radiator wall console",
    artClassVersion: 5,
    label: "EC010197: Radiator wall console"
  },
  {
    classId: "EC010198",
    classGroup: "EG015210",
    artClassDesc: "Boiler with preburner",
    artClassVersion: 5,
    label: "EC010198: Boiler with preburner"
  },
  {
    classId: "EC010199",
    classGroup: "EG015140",
    artClassDesc: "Bucket filter, double",
    artClassVersion: 8,
    label: "EC010199: Bucket filter, double"
  },
  {
    classId: "EC010200",
    classGroup: "EG015610",
    artClassDesc: "Float valve",
    artClassVersion: 7,
    label: "EC010200: Float valve"
  },
  {
    classId: "EC010201",
    classGroup: "EG015610",
    artClassDesc: "Alarm valve",
    artClassVersion: 4,
    label: "EC010201: Alarm valve"
  },
  {
    classId: "EC010202",
    classGroup: "EG015210",
    artClassDesc: "Extension module for air heater, indirect",
    artClassVersion: 1,
    label: "EC010202: Extension module for air heater, indirect"
  },
  {
    classId: "EC010203",
    classGroup: "EG015560",
    artClassDesc: "Offset bend rectangular air duct",
    artClassVersion: 5,
    label: "EC010203: Offset bend rectangular air duct"
  },
  {
    classId: "EC010204",
    classGroup: "EG017210",
    artClassDesc: "Accessories/spare parts for shower toilet",
    artClassVersion: 5,
    label: "EC010204: Accessories/spare parts for shower toilet"
  },
  {
    classId: "EC010205",
    classGroup: "EG015630",
    artClassDesc: "One-bladed control valve, oval air duct",
    artClassVersion: 2,
    label: "EC010205: One-bladed control valve, oval air duct"
  },
  {
    classId: "EC010206",
    classGroup: "EG015610",
    artClassDesc: "Sprinkler for domestic use",
    artClassVersion: 4,
    label: "EC010206: Sprinkler for domestic use"
  },
  {
    classId: "EC010207",
    classGroup: "EG017550",
    artClassDesc: "Built-in box for urinal",
    artClassVersion: 5,
    label: "EC010207: Built-in box for urinal"
  },
  {
    classId: "EC010208",
    classGroup: "EG015630",
    artClassDesc: "Air volume and pressure controller, round",
    artClassVersion: 3,
    label: "EC010208: Air volume and pressure controller, round"
  },
  {
    classId: "EC010209",
    classGroup: "EG019490",
    artClassDesc: "Radiator stand console",
    artClassVersion: 5,
    label: "EC010209: Radiator stand console"
  },
  {
    classId: "EC010210",
    classGroup: "EG017610",
    artClassDesc: "Electronic urinal flusher",
    artClassVersion: 7,
    label: "EC010210: Electronic urinal flusher"
  },
  {
    classId: "EC010211",
    classGroup: "EG017210",
    artClassDesc: "Accessories/spare parts for toilet raiser",
    artClassVersion: 4,
    label: "EC010211: Accessories/spare parts for toilet raiser"
  },
  {
    classId: "EC010213",
    classGroup: "EG019490",
    artClassDesc: "Convector console",
    artClassVersion: 5,
    label: "EC010213: Convector console"
  },
  {
    classId: "EC010214",
    classGroup: "EG015610",
    artClassDesc: "Three-way needle valve",
    artClassVersion: 7,
    label: "EC010214: Three-way needle valve"
  },
  {
    classId: "EC010216",
    classGroup: "EG015850",
    artClassDesc: "Fixed-application controller",
    artClassVersion: 7,
    label: "EC010216: Fixed-application controller"
  },
  {
    classId: "EC010217",
    classGroup: "EG000049",
    artClassDesc: "Inlay plate for fixed-point construction",
    artClassVersion: 4,
    label: "EC010217: Inlay plate for fixed-point construction"
  },
  {
    classId: "EC010218",
    classGroup: "EG015710",
    artClassDesc: "Convector element",
    artClassVersion: 5,
    label: "EC010218: Convector element"
  },
  {
    classId: "EC010220",
    classGroup: "EG000049",
    artClassDesc: "Cold-bridge-free bracket",
    artClassVersion: 6,
    label: "EC010220: Cold-bridge-free bracket"
  },
  {
    classId: "EC010221",
    classGroup: "EG015140",
    artClassDesc: "Attachment for separator/mud trap",
    artClassVersion: 5,
    label: "EC010221: Attachment for separator/mud trap"
  },
  {
    classId: "EC010223",
    classGroup: "EG000026",
    artClassDesc: "Immersion temperature sensor, active",
    artClassVersion: 7,
    label: "EC010223: Immersion temperature sensor, active"
  },
  {
    classId: "EC010225",
    classGroup: "EG015530",
    artClassDesc: "Multi-layered pipe, smooth",
    artClassVersion: 6,
    label: "EC010225: Multi-layered pipe, smooth"
  },
  {
    classId: "EC010226",
    classGroup: "EG015520",
    artClassDesc: "Storz coupling/thread",
    artClassVersion: 6,
    label: "EC010226: Storz coupling/thread"
  },
  {
    classId: "EC010227",
    classGroup: "EG015210",
    artClassDesc: "Mounting frame for HVAC",
    artClassVersion: 3,
    label: "EC010227: Mounting frame for HVAC"
  },
  {
    classId: "EC010229",
    classGroup: "EG015540",
    artClassDesc: "One-point bottom connecting block",
    artClassVersion: 6,
    label: "EC010229: One-point bottom connecting block"
  },
  {
    classId: "EC010230",
    classGroup: "EG015240",
    artClassDesc: "Overflow grille",
    artClassVersion: 7,
    label: "EC010230: Overflow grille"
  },
  {
    classId: "EC010231",
    classGroup: "EG015210",
    artClassDesc: "Boiler, gas heated",
    artClassVersion: 7,
    label: "EC010231: Boiler, gas heated"
  },
  {
    classId: "EC010232",
    classGroup: "EG015210",
    artClassDesc: "Wall-mounted gas boiler",
    artClassVersion: 6,
    label: "EC010232: Wall-mounted gas boiler"
  },
  {
    classId: "EC010233",
    classGroup: "EG017210",
    artClassDesc: "Insert for soap holder",
    artClassVersion: 4,
    label: "EC010233: Insert for soap holder"
  },
  {
    classId: "EC010235",
    classGroup: "EG017110",
    artClassDesc: "Urinal",
    artClassVersion: 7,
    label: "EC010235: Urinal"
  },
  {
    classId: "EC010236",
    classGroup: "EG000060",
    artClassDesc: "Surface desinfectant fluid",
    artClassVersion: 4,
    label: "EC010236: Surface desinfectant fluid"
  },
  {
    classId: "EC010237",
    classGroup: "EG015560",
    artClassDesc: "Thimble front plate",
    artClassVersion: 1,
    label: "EC010237: Thimble front plate"
  },
  {
    classId: "EC010238",
    classGroup: "EG015610",
    artClassDesc: "Needle valve",
    artClassVersion: 6,
    label: "EC010238: Needle valve"
  },
  {
    classId: "EC010256",
    classGroup: "EG015540",
    artClassDesc: "Flanged bush",
    artClassVersion: 6,
    label: "EC010256: Flanged bush"
  },
  {
    classId: "EC010258",
    classGroup: "EG018110",
    artClassDesc: "Pipe insulation",
    artClassVersion: 5,
    label: "EC010258: Pipe insulation"
  },
  {
    classId: "EC010259",
    classGroup: "EG015810",
    artClassDesc: "Hot water boiler connection group",
    artClassVersion: 5,
    label: "EC010259: Hot water boiler connection group"
  },
  {
    classId: "EC010260",
    classGroup: "EG015140",
    artClassDesc: "Air-/dirt separator",
    artClassVersion: 6,
    label: "EC010260: Air-/dirt separator"
  },
  {
    classId: "EC010261",
    classGroup: "EG015610",
    artClassDesc: "Solenoid valve",
    artClassVersion: 6,
    label: "EC010261: Solenoid valve"
  },
  {
    classId: "EC010262",
    classGroup: "EG015610",
    artClassDesc: "Pressure gauge valve",
    artClassVersion: 6,
    label: "EC010262: Pressure gauge valve"
  },
  {
    classId: "EC010263",
    classGroup: "EG015510",
    artClassDesc: "Flat roll-up fire hose with coupling",
    artClassVersion: 2,
    label: "EC010263: Flat roll-up fire hose with coupling"
  },
  {
    classId: "EC010264",
    classGroup: "EG015510",
    artClassDesc: "Fire hose reel",
    artClassVersion: 5,
    label: "EC010264: Fire hose reel"
  },
  {
    classId: "EC010265",
    classGroup: "EG015510",
    artClassDesc: "Filler hose set for boiler",
    artClassVersion: 3,
    label: "EC010265: Filler hose set for boiler"
  },
  {
    classId: "EC010266",
    classGroup: "EG020003",
    artClassDesc: "Rectangular profile, steel",
    artClassVersion: 5,
    label: "EC010266: Rectangular profile, steel"
  },
  {
    classId: "EC010267",
    classGroup: "EG015260",
    artClassDesc: "Dosing pump",
    artClassVersion: 6,
    label: "EC010267: Dosing pump"
  },
  {
    classId: "EC010268",
    classGroup: "EG015260",
    artClassDesc: "Hand vane pump",
    artClassVersion: 5,
    label: "EC010268: Hand vane pump"
  },
  {
    classId: "EC010269",
    classGroup: "EG015260",
    artClassDesc: "Automatic expansion unit",
    artClassVersion: 4,
    label: "EC010269: Automatic expansion unit"
  },
  {
    classId: "EC010271",
    classGroup: "EG015140",
    artClassDesc: "Sewer trap",
    artClassVersion: 4,
    label: "EC010271: Sewer trap"
  },
  {
    classId: "EC010273",
    classGroup: "EG015210",
    artClassDesc: "Radiant device, directly fired",
    artClassVersion: 3,
    label: "EC010273: Radiant device, directly fired"
  },
  {
    classId: "EC010274",
    classGroup: "EG015240",
    artClassDesc: "Nozzle grille",
    artClassVersion: 2,
    label: "EC010274: Nozzle grille"
  },
  {
    classId: "EC010275",
    classGroup: "EG015240",
    artClassDesc: "External louvre grille",
    artClassVersion: 5,
    label: "EC010275: External louvre grille"
  },
  {
    classId: "EC010276",
    classGroup: "EG000049",
    artClassDesc: "Pipe pendulum suspension",
    artClassVersion: 2,
    label: "EC010276: Pipe pendulum suspension"
  },
  {
    classId: "EC010277",
    classGroup: "EG000049",
    artClassDesc: "Ball hinge",
    artClassVersion: 4,
    label: "EC010277: Ball hinge"
  },
  {
    classId: "EC010278",
    classGroup: "EG015110",
    artClassDesc: "Rain water roof outlet",
    artClassVersion: 6,
    label: "EC010278: Rain water roof outlet"
  },
  {
    classId: "EC010279",
    classGroup: "EG015110",
    artClassDesc: "Roof discharge funnel negative pressure system",
    artClassVersion: 3,
    label: "EC010279: Roof discharge funnel negative pressure system"
  },
  {
    classId: "EC010280",
    classGroup: "EG015110",
    artClassDesc: "Roof gutter",
    artClassVersion: 3,
    label: "EC010280: Roof gutter"
  },
  {
    classId: "EC010281",
    classGroup: "EG015110",
    artClassDesc: "Separation slide roof gutter",
    artClassVersion: 2,
    label: "EC010281: Separation slide roof gutter"
  },
  {
    classId: "EC010282",
    classGroup: "EG015110",
    artClassDesc: "Roof gutter decoration",
    artClassVersion: 2,
    label: "EC010282: Roof gutter decoration"
  },
  {
    classId: "EC010283",
    classGroup: "EG015120",
    artClassDesc: "Solar heater discharge tank",
    artClassVersion: 2,
    label: "EC010283: Solar heater discharge tank"
  },
  {
    classId: "EC010284",
    classGroup: "EG015130",
    artClassDesc: "Floor drain with 1 connection",
    artClassVersion: 6,
    label: "EC010284: Floor drain with 1 connection"
  },
  {
    classId: "EC010285",
    classGroup: "EG015130",
    artClassDesc: "Drain grid (domestic areas)",
    artClassVersion: 4,
    label: "EC010285: Drain grid (domestic areas)"
  },
  {
    classId: "EC010286",
    classGroup: "EG015130",
    artClassDesc: "Drain bucket",
    artClassVersion: 5,
    label: "EC010286: Drain bucket"
  },
  {
    classId: "EC010291",
    classGroup: "EG015210",
    artClassDesc: "Mobile air heater (gas/oil)",
    artClassVersion: 3,
    label: "EC010291: Mobile air heater (gas/oil)"
  },
  {
    classId: "EC010292",
    classGroup: "EG015410",
    artClassDesc: "Floor heating control unit",
    artClassVersion: 5,
    label: "EC010292: Floor heating control unit"
  },
  {
    classId: "EC010293",
    classGroup: "EG015510",
    artClassDesc: "Fire hose reel cabinet (without reel and hose)",
    artClassVersion: 4,
    label: "EC010293: Fire hose reel cabinet (without reel and hose)"
  },
  {
    classId: "EC010296",
    classGroup: "EG015540",
    artClassDesc: "Insulation coupling",
    artClassVersion: 4,
    label: "EC010296: Insulation coupling"
  },
  {
    classId: "EC010297",
    classGroup: "EG015810",
    artClassDesc: "Gas shortage valve",
    artClassVersion: 2,
    label: "EC010297: Gas shortage valve"
  },
  {
    classId: "EC010298",
    classGroup: "EG015610",
    artClassDesc: "Manifold valve",
    artClassVersion: 6,
    label: "EC010298: Manifold valve"
  },
  {
    classId: "EC010299",
    classGroup: "EG015610",
    artClassDesc: "Parent sprinkler",
    artClassVersion: 3,
    label: "EC010299: Parent sprinkler"
  },
  {
    classId: "EC010300",
    classGroup: "EG015810",
    artClassDesc: "Gas pressure control valve",
    artClassVersion: 4,
    label: "EC010300: Gas pressure control valve"
  },
  {
    classId: "EC010301",
    classGroup: "EG015810",
    artClassDesc: "Expansion tank connection group",
    artClassVersion: 4,
    label: "EC010301: Expansion tank connection group"
  },
  {
    classId: "EC010302",
    classGroup: "EG015610",
    artClassDesc: "Sprinkler rosette",
    artClassVersion: 4,
    label: "EC010302: Sprinkler rosette"
  },
  {
    classId: "EC010303",
    classGroup: "EG015610",
    artClassDesc: "Three-way solenoid valve",
    artClassVersion: 6,
    label: "EC010303: Three-way solenoid valve"
  },
  {
    classId: "EC010304",
    classGroup: "EG015610",
    artClassDesc: "Four-way solenoid valve",
    artClassVersion: 6,
    label: "EC010304: Four-way solenoid valve"
  },
  {
    classId: "EC010313",
    classGroup: "EG015540",
    artClassDesc: "Support bush",
    artClassVersion: 4,
    label: "EC010313: Support bush"
  },
  {
    classId: "EC010315",
    classGroup: "EG015810",
    artClassDesc: "Pressure controller, medium-controlled",
    artClassVersion: 5,
    label: "EC010315: Pressure controller, medium-controlled"
  },
  {
    classId: "EC010317",
    classGroup: "EG015540",
    artClassDesc: "Bend with mounting support",
    artClassVersion: 2,
    label: "EC010317: Bend with mounting support"
  },
  {
    classId: "EC010319",
    classGroup: "EG015540",
    artClassDesc: "Transition cap, sleeveless cast iron",
    artClassVersion: 2,
    label: "EC010319: Transition cap, sleeveless cast iron"
  },
  {
    classId: "EC010320",
    classGroup: "EG015540",
    artClassDesc: "Welding shoe",
    artClassVersion: 2,
    label: "EC010320: Welding shoe"
  },
  {
    classId: "EC010321",
    classGroup: "EG015540",
    artClassDesc: "Welding saddle",
    artClassVersion: 3,
    label: "EC010321: Welding saddle"
  },
  {
    classId: "EC010323",
    classGroup: "EG015540",
    artClassDesc: "Inspection access piece",
    artClassVersion: 4,
    label: "EC010323: Inspection access piece"
  },
  {
    classId: "EC010325",
    classGroup: "EG015260",
    artClassDesc: "Fire-extinguishing unit",
    artClassVersion: 5,
    label: "EC010325: Fire-extinguishing unit"
  },
  {
    classId: "EC010326",
    classGroup: "EG015260",
    artClassDesc: "Self-priming horizontal pump",
    artClassVersion: 6,
    label: "EC010326: Self-priming horizontal pump"
  },
  {
    classId: "EC010327",
    classGroup: "EG015260",
    artClassDesc: "Pump control system",
    artClassVersion: 5,
    label: "EC010327: Pump control system"
  },
  {
    classId: "EC010338",
    classGroup: "EG015540",
    artClassDesc: "Fixed flange",
    artClassVersion: 6,
    label: "EC010338: Fixed flange"
  },
  {
    classId: "EC010339",
    classGroup: "EG015530",
    artClassDesc: "Plastic pipe, ribbed",
    artClassVersion: 5,
    label: "EC010339: Plastic pipe, ribbed"
  },
  {
    classId: "EC010341",
    classGroup: "EG017910",
    artClassDesc: "Bath and shower tray panel",
    artClassVersion: 6,
    label: "EC010341: Bath and shower tray panel"
  },
  {
    classId: "EC010342",
    classGroup: "EG017910",
    artClassDesc: "Bath and shower tray skirting",
    artClassVersion: 5,
    label: "EC010342: Bath and shower tray skirting"
  },
  {
    classId: "EC010343",
    classGroup: "EG015260",
    artClassDesc: "Well pump",
    artClassVersion: 5,
    label: "EC010343: Well pump"
  },
  {
    classId: "EC010345",
    classGroup: "EG015140",
    artClassDesc: "Grease collection air filter",
    artClassVersion: 1,
    label: "EC010345: Grease collection air filter"
  },
  {
    classId: "EC010346",
    classGroup: "EG018310",
    artClassDesc: "Sound absorber rectangular air duct",
    artClassVersion: 5,
    label: "EC010346: Sound absorber rectangular air duct"
  },
  {
    classId: "EC010347",
    classGroup: "EG015240",
    artClassDesc: "Grid grille",
    artClassVersion: 1,
    label: "EC010347: Grid grille"
  },
  {
    classId: "EC010348",
    classGroup: "EG015240",
    artClassDesc: "Finned grille",
    artClassVersion: 2,
    label: "EC010348: Finned grille"
  },
  {
    classId: "EC010349",
    classGroup: "EG015240",
    artClassDesc: "Prison grille",
    artClassVersion: 1,
    label: "EC010349: Prison grille"
  },
  {
    classId: "EC010350",
    classGroup: "EG015210",
    artClassDesc: "Solar collector",
    artClassVersion: 4,
    label: "EC010350: Solar collector"
  },
  {
    classId: "EC010351",
    classGroup: "EG015630",
    artClassDesc: "Louver for duct installation",
    artClassVersion: 4,
    label: "EC010351: Louver for duct installation"
  },
  {
    classId: "EC010352",
    classGroup: "EG015630",
    artClassDesc: "Fire damper, rectangular",
    artClassVersion: 3,
    label: "EC010352: Fire damper, rectangular"
  },
  {
    classId: "EC010353",
    classGroup: "EG015630",
    artClassDesc: "Valve register",
    artClassVersion: 2,
    label: "EC010353: Valve register"
  },
  {
    classId: "EC010354",
    classGroup: "EG015630",
    artClassDesc: "Round fire damper",
    artClassVersion: 2,
    label: "EC010354: Round fire damper"
  },
  {
    classId: "EC010355",
    classGroup: "EG015710",
    artClassDesc: "Evaporating tray",
    artClassVersion: 2,
    label: "EC010355: Evaporating tray"
  },
  {
    classId: "EC010356",
    classGroup: "EG015240",
    artClassDesc: "Sliding grille",
    artClassVersion: 2,
    label: "EC010356: Sliding grille"
  },
  {
    classId: "EC010357",
    classGroup: "EG015140",
    artClassDesc: "Self-cleaning filter",
    artClassVersion: 5,
    label: "EC010357: Self-cleaning filter"
  },
  {
    classId: "EC010358",
    classGroup: "EG015140",
    artClassDesc: "Filter element for pipeline strainer",
    artClassVersion: 3,
    label: "EC010358: Filter element for pipeline strainer"
  },
  {
    classId: "EC010359",
    classGroup: "EG015140",
    artClassDesc: "Filter element for bucket filter",
    artClassVersion: 2,
    label: "EC010359: Filter element for bucket filter"
  },
  {
    classId: "EC010360",
    classGroup: "EG015140",
    artClassDesc: "Temporary installation filter",
    artClassVersion: 4,
    label: "EC010360: Temporary installation filter"
  },
  {
    classId: "EC010362",
    classGroup: "EG015540",
    artClassDesc: "Double wall toilet bend",
    artClassVersion: 4,
    label: "EC010362: Double wall toilet bend"
  },
  {
    classId: "EC010367",
    classGroup: "EG015540",
    artClassDesc: "Fitting with 6 connections",
    artClassVersion: 4,
    label: "EC010367: Fitting with 6 connections"
  },
  {
    classId: "EC010368",
    classGroup: "EG015540",
    artClassDesc: "Fall pipe T-piece",
    artClassVersion: 3,
    label: "EC010368: Fall pipe T-piece"
  },
  {
    classId: "EC010369",
    classGroup: "EG017970",
    artClassDesc: "Mounting corner support sanitary",
    artClassVersion: 2,
    label: "EC010369: Mounting corner support sanitary"
  },
  {
    classId: "EC010370",
    classGroup: "EG017970",
    artClassDesc: "Distance holder sanitary",
    artClassVersion: 2,
    label: "EC010370: Distance holder sanitary"
  },
  {
    classId: "EC010371",
    classGroup: "EG017970",
    artClassDesc: "Connection strip sanitary",
    artClassVersion: 2,
    label: "EC010371: Connection strip sanitary"
  },
  {
    classId: "EC010372",
    classGroup: "EG017970",
    artClassDesc: "Reinforcement free standing wall",
    artClassVersion: 3,
    label: "EC010372: Reinforcement free standing wall"
  },
  {
    classId: "EC010373",
    classGroup: "EG017970",
    artClassDesc: "Fixation clip for threaded rod",
    artClassVersion: 2,
    label: "EC010373: Fixation clip for threaded rod"
  },
  {
    classId: "EC010374",
    classGroup: "EG015540",
    artClassDesc: "Wall plate insulation",
    artClassVersion: 4,
    label: "EC010374: Wall plate insulation"
  },
  {
    classId: "EC010377",
    classGroup: "EG017630",
    artClassDesc: "S-coupling",
    artClassVersion: 7,
    label: "EC010377: S-coupling"
  },
  {
    classId: "EC010378",
    classGroup: "EG000044",
    artClassDesc: "Portable gas detector",
    artClassVersion: 4,
    label: "EC010378: Portable gas detector"
  },
  {
    classId: "EC010379",
    classGroup: "EG015810",
    artClassDesc: "Gas leakage checking device",
    artClassVersion: 2,
    label: "EC010379: Gas leakage checking device"
  },
  {
    classId: "EC010381",
    classGroup: "EG015410",
    artClassDesc: "Accessories/spare parts for floor heating",
    artClassVersion: 4,
    label: "EC010381: Accessories/spare parts for floor heating"
  },
  {
    classId: "EC010383",
    classGroup: "EG015410",
    artClassDesc: "Accessories/spare parts for water distributor",
    artClassVersion: 5,
    label: "EC010383: Accessories/spare parts for water distributor"
  },
  {
    classId: "EC010384",
    classGroup: "EG015630",
    artClassDesc: "Installation frame for fire damper",
    artClassVersion: 1,
    label: "EC010384: Installation frame for fire damper"
  },
  {
    classId: "EC010385",
    classGroup: "EG015630",
    artClassDesc: "Insulation package, fire damper wall structure",
    artClassVersion: 1,
    label: "EC010385: Insulation package, fire damper wall structure"
  },
  {
    classId: "EC010386",
    classGroup: "EG015260",
    artClassDesc: "Hand plunger pump",
    artClassVersion: 3,
    label: "EC010386: Hand plunger pump"
  },
  {
    classId: "EC010387",
    classGroup: "EG015640",
    artClassDesc: "Surface box (street)",
    artClassVersion: 4,
    label: "EC010387: Surface box (street)"
  },
  {
    classId: "EC010388",
    classGroup: "EG015640",
    artClassDesc: "Tap wrench for street pot",
    artClassVersion: 3,
    label: "EC010388: Tap wrench for street pot"
  },
  {
    classId: "EC010389",
    classGroup: "EG015830",
    artClassDesc: "Gauge body",
    artClassVersion: 3,
    label: "EC010389: Gauge body"
  },
  {
    classId: "EC010390",
    classGroup: "EG015280",
    artClassDesc: "Gas line",
    artClassVersion: 4,
    label: "EC010390: Gas line"
  },
  {
    classId: "EC010391",
    classGroup: "EG015110",
    artClassDesc: "Top-piece for roof gully",
    artClassVersion: 3,
    label: "EC010391: Top-piece for roof gully"
  },
  {
    classId: "EC010392",
    classGroup: "EG015110",
    artClassDesc: "Reinforcement plate UV-funnel",
    artClassVersion: 2,
    label: "EC010392: Reinforcement plate UV-funnel"
  },
  {
    classId: "EC010393",
    classGroup: "EG015110",
    artClassDesc: "Fixation plate UV-funnel",
    artClassVersion: 2,
    label: "EC010393: Fixation plate UV-funnel"
  },
  {
    classId: "EC010394",
    classGroup: "EG015110",
    artClassDesc: "UV-funnel condensation isolation",
    artClassVersion: 2,
    label: "EC010394: UV-funnel condensation isolation"
  },
  {
    classId: "EC010395",
    classGroup: "EG015110",
    artClassDesc: "O-ring set UV-funnel",
    artClassVersion: 1,
    label: "EC010395: O-ring set UV-funnel"
  },
  {
    classId: "EC010396",
    classGroup: "EG015110",
    artClassDesc: "Clamping flange set UV-funnel",
    artClassVersion: 2,
    label: "EC010396: Clamping flange set UV-funnel"
  },
  {
    classId: "EC010397",
    classGroup: "EG015110",
    artClassDesc: "Plastic foil ring UV-funnel",
    artClassVersion: 2,
    label: "EC010397: Plastic foil ring UV-funnel"
  },
  {
    classId: "EC010398",
    classGroup: "EG015110",
    artClassDesc: "Roof flashing, universal",
    artClassVersion: 3,
    label: "EC010398: Roof flashing, universal"
  },
  {
    classId: "EC010399",
    classGroup: "EG015110",
    artClassDesc: "Welding/soldered flange set for UV funnel",
    artClassVersion: 4,
    label: "EC010399: Welding/soldered flange set for UV funnel"
  },
  {
    classId: "EC010400",
    classGroup: "EG015110",
    artClassDesc: "Emergency overflow (side outlet)",
    artClassVersion: 4,
    label: "EC010400: Emergency overflow (side outlet)"
  },
  {
    classId: "EC010401",
    classGroup: "EG015610",
    artClassDesc: "Hose valve",
    artClassVersion: 6,
    label: "EC010401: Hose valve"
  },
  {
    classId: "EC010402",
    classGroup: "EG015570",
    artClassDesc: "PTFE compensator with flanges",
    artClassVersion: 2,
    label: "EC010402: PTFE compensator with flanges"
  },
  {
    classId: "EC010404",
    classGroup: "EG015520",
    artClassDesc: "Akzo coupling/hose connector",
    artClassVersion: 1,
    label: "EC010404: Akzo coupling/hose connector"
  },
  {
    classId: "EC010405",
    classGroup: "EG015520",
    artClassDesc: "Cam and groove coupling/hose connector",
    artClassVersion: 4,
    label: "EC010405: Cam and groove coupling/hose connector"
  },
  {
    classId: "EC010406",
    classGroup: "EG015520",
    artClassDesc: "Clamp coupling/thread",
    artClassVersion: 2,
    label: "EC010406: Clamp coupling/thread"
  },
  {
    classId: "EC010407",
    classGroup: "EG015520",
    artClassDesc: "Guillemin coupling/thread",
    artClassVersion: 2,
    label: "EC010407: Guillemin coupling/thread"
  },
  {
    classId: "EC010408",
    classGroup: "EG015520",
    artClassDesc: "Guillemin coupling, composite hose",
    artClassVersion: 2,
    label: "EC010408: Guillemin coupling, composite hose"
  },
  {
    classId: "EC010409",
    classGroup: "EG015520",
    artClassDesc: "Threaded coupling composite hose",
    artClassVersion: 3,
    label: "EC010409: Threaded coupling composite hose"
  },
  {
    classId: "EC010410",
    classGroup: "EG015520",
    artClassDesc: "Swivel coupling",
    artClassVersion: 2,
    label: "EC010410: Swivel coupling"
  },
  {
    classId: "EC010411",
    classGroup: "EG015520",
    artClassDesc: "Claw coupling/thread",
    artClassVersion: 4,
    label: "EC010411: Claw coupling/thread"
  },
  {
    classId: "EC010412",
    classGroup: "EG015520",
    artClassDesc: "Quick coupling/hose connector",
    artClassVersion: 4,
    label: "EC010412: Quick coupling/hose connector"
  },
  {
    classId: "EC010413",
    classGroup: "EG015120",
    artClassDesc: "Storage tank, pressureless",
    artClassVersion: 2,
    label: "EC010413: Storage tank, pressureless"
  },
  {
    classId: "EC010414",
    classGroup: "EG015910",
    artClassDesc: "Fabric oil catcher",
    artClassVersion: 3,
    label: "EC010414: Fabric oil catcher"
  },
  {
    classId: "EC010415",
    classGroup: "EG015910",
    artClassDesc: "Shaft sleeve for oil catcher",
    artClassVersion: 1,
    label: "EC010415: Shaft sleeve for oil catcher"
  },
  {
    classId: "EC010416",
    classGroup: "EG015910",
    artClassDesc: "Shaft seal end cap",
    artClassVersion: 3,
    label: "EC010416: Shaft seal end cap"
  },
  {
    classId: "EC010417",
    classGroup: "EG015910",
    artClassDesc: "Rubber oil seal",
    artClassVersion: 5,
    label: "EC010417: Rubber oil seal"
  },
  {
    classId: "EC010418",
    classGroup: "EG015910",
    artClassDesc: "PTFE lip for PTFE lip seal",
    artClassVersion: 2,
    label: "EC010418: PTFE lip for PTFE lip seal"
  },
  {
    classId: "EC010419",
    classGroup: "EG015910",
    artClassDesc: "PTFE lip seal",
    artClassVersion: 2,
    label: "EC010419: PTFE lip seal"
  },
  {
    classId: "EC010420",
    classGroup: "EG015910",
    artClassDesc: "Rodar seal",
    artClassVersion: 2,
    label: "EC010420: Rodar seal"
  },
  {
    classId: "EC010421",
    classGroup: "EG015910",
    artClassDesc: "Spiral spring for oil catcher",
    artClassVersion: 1,
    label: "EC010421: Spiral spring for oil catcher"
  },
  {
    classId: "EC010422",
    classGroup: "EG015910",
    artClassDesc: "V-ring seal",
    artClassVersion: 2,
    label: "EC010422: V-ring seal"
  },
  {
    classId: "EC010423",
    classGroup: "EG015910",
    artClassDesc: "Cathodic flange-sealing set DIN",
    artClassVersion: 5,
    label: "EC010423: Cathodic flange-sealing set DIN"
  },
  {
    classId: "EC010424",
    classGroup: "EG015910",
    artClassDesc: "Flange-protection cover/cap",
    artClassVersion: 3,
    label: "EC010424: Flange-protection cover/cap"
  },
  {
    classId: "EC010425",
    classGroup: "EG015910",
    artClassDesc: "Bonded seal",
    artClassVersion: 3,
    label: "EC010425: Bonded seal"
  },
  {
    classId: "EC010426",
    classGroup: "EG015910",
    artClassDesc: "Support ring for O ring seal",
    artClassVersion: 4,
    label: "EC010426: Support ring for O ring seal"
  },
  {
    classId: "EC010427",
    classGroup: "EG015910",
    artClassDesc: "Metal O ring seal",
    artClassVersion: 4,
    label: "EC010427: Metal O ring seal"
  },
  {
    classId: "EC010428",
    classGroup: "EG015910",
    artClassDesc: "Pressure ring, mechanical seal",
    artClassVersion: 2,
    label: "EC010428: Pressure ring, mechanical seal"
  },
  {
    classId: "EC010429",
    classGroup: "EG015910",
    artClassDesc: "Spring mechanical seal",
    artClassVersion: 2,
    label: "EC010429: Spring mechanical seal"
  },
  {
    classId: "EC010430",
    classGroup: "EG015910",
    artClassDesc: "Mechanical seal, two piece",
    artClassVersion: 3,
    label: "EC010430: Mechanical seal, two piece"
  },
  {
    classId: "EC010431",
    classGroup: "EG015910",
    artClassDesc: "Sliding ring, mechanical seal",
    artClassVersion: 2,
    label: "EC010431: Sliding ring, mechanical seal"
  },
  {
    classId: "EC010432",
    classGroup: "EG015910",
    artClassDesc: "Ancillary seal, mechanical seal",
    artClassVersion: 3,
    label: "EC010432: Ancillary seal, mechanical seal"
  },
  {
    classId: "EC010433",
    classGroup: "EG015910",
    artClassDesc: "Rotor, mechanical seal",
    artClassVersion: 3,
    label: "EC010433: Rotor, mechanical seal"
  },
  {
    classId: "EC010434",
    classGroup: "EG015910",
    artClassDesc: "Stator, mechanical seal",
    artClassVersion: 3,
    label: "EC010434: Stator, mechanical seal"
  },
  {
    classId: "EC010435",
    classGroup: "EG015910",
    artClassDesc: "Gland gasket",
    artClassVersion: 4,
    label: "EC010435: Gland gasket"
  },
  {
    classId: "EC010436",
    classGroup: "EG015910",
    artClassDesc: "Countersliding ring, mechanical seal",
    artClassVersion: 2,
    label: "EC010436: Countersliding ring, mechanical seal"
  },
  {
    classId: "EC010437",
    classGroup: "EG015510",
    artClassDesc: "Swivelling arm for hose reel",
    artClassVersion: 2,
    label: "EC010437: Swivelling arm for hose reel"
  },
  {
    classId: "EC010438",
    classGroup: "EG015510",
    artClassDesc: "Machine hose reel",
    artClassVersion: 1,
    label: "EC010438: Machine hose reel"
  },
  {
    classId: "EC010439",
    classGroup: "EG015510",
    artClassDesc: "Hose reel, single hose",
    artClassVersion: 4,
    label: "EC010439: Hose reel, single hose"
  },
  {
    classId: "EC010440",
    classGroup: "EG015510",
    artClassDesc: "Hose reel, double hose",
    artClassVersion: 3,
    label: "EC010440: Hose reel, double hose"
  },
  {
    classId: "EC010441",
    classGroup: "EG015510",
    artClassDesc: "Coolant hose for lathe/miller",
    artClassVersion: 3,
    label: "EC010441: Coolant hose for lathe/miller"
  },
  {
    classId: "EC010442",
    classGroup: "EG015510",
    artClassDesc: "Composite hose",
    artClassVersion: 5,
    label: "EC010442: Composite hose"
  },
  {
    classId: "EC010443",
    classGroup: "EG015510",
    artClassDesc: "Corrugated metal hose",
    artClassVersion: 4,
    label: "EC010443: Corrugated metal hose"
  },
  {
    classId: "EC010444",
    classGroup: "EG015510",
    artClassDesc: "Wound metal hose",
    artClassVersion: 4,
    label: "EC010444: Wound metal hose"
  },
  {
    classId: "EC010445",
    classGroup: "EG015510",
    artClassDesc: "AWG hose",
    artClassVersion: 2,
    label: "EC010445: AWG hose"
  },
  {
    classId: "EC010446",
    classGroup: "EG015520",
    artClassDesc: "1- and 2- part hose clamp",
    artClassVersion: 2,
    label: "EC010446: 1- and 2- part hose clamp"
  },
  {
    classId: "EC010447",
    classGroup: "EG015520",
    artClassDesc: "Twisted ring for metal hose",
    artClassVersion: 1,
    label: "EC010447: Twisted ring for metal hose"
  },
  {
    classId: "EC010448",
    classGroup: "EG015930",
    artClassDesc: "Plastic ball",
    artClassVersion: 4,
    label: "EC010448: Plastic ball"
  },
  {
    classId: "EC010449",
    classGroup: "EG018110",
    artClassDesc: "Thermal insulation cloth",
    artClassVersion: 4,
    label: "EC010449: Thermal insulation cloth"
  },
  {
    classId: "EC010450",
    classGroup: "EG018110",
    artClassDesc: "Thermal insulation cord",
    artClassVersion: 4,
    label: "EC010450: Thermal insulation cord"
  },
  {
    classId: "EC010451",
    classGroup: "EG017610",
    artClassDesc: "Eye/face shower",
    artClassVersion: 5,
    label: "EC010451: Eye/face shower"
  },
  {
    classId: "EC010452",
    classGroup: "EG017610",
    artClassDesc: "Emergency eye shower combination",
    artClassVersion: 4,
    label: "EC010452: Emergency eye shower combination"
  },
  {
    classId: "EC010453",
    classGroup: "EG017910",
    artClassDesc: "Bath support",
    artClassVersion: 6,
    label: "EC010453: Bath support"
  },
  {
    classId: "EC010454",
    classGroup: "EG017910",
    artClassDesc: "Bath legs/frame",
    artClassVersion: 6,
    label: "EC010454: Bath legs/frame"
  },
  {
    classId: "EC010455",
    classGroup: "EG017910",
    artClassDesc: "Shower tray support",
    artClassVersion: 5,
    label: "EC010455: Shower tray support"
  },
  {
    classId: "EC010456",
    classGroup: "EG017910",
    artClassDesc: "Shower tray legs/frame",
    artClassVersion: 4,
    label: "EC010456: Shower tray legs/frame"
  },
  {
    classId: "EC010457",
    classGroup: "EG017210",
    artClassDesc: "Shower stretcher",
    artClassVersion: 3,
    label: "EC010457: Shower stretcher"
  },
  {
    classId: "EC010458",
    classGroup: "EG017210",
    artClassDesc: "Toilet seat heightener",
    artClassVersion: 4,
    label: "EC010458: Toilet seat heightener"
  },
  {
    classId: "EC010459",
    classGroup: "EG017960",
    artClassDesc: "Washbasin top",
    artClassVersion: 4,
    label: "EC010459: Washbasin top"
  },
  {
    classId: "EC010460",
    classGroup: "EG017960",
    artClassDesc: "Worktop/vanity top",
    artClassVersion: 5,
    label: "EC010460: Worktop/vanity top"
  },
  {
    classId: "EC010461",
    classGroup: "EG015830",
    artClassDesc: "Pipe clip thermometer",
    artClassVersion: 3,
    label: "EC010461: Pipe clip thermometer"
  },
  {
    classId: "EC010462",
    classGroup: "EG015610",
    artClassDesc: "Underground hydrant",
    artClassVersion: 4,
    label: "EC010462: Underground hydrant"
  },
  {
    classId: "EC010463",
    classGroup: "EG015260",
    artClassDesc: "Hand membrane pump",
    artClassVersion: 5,
    label: "EC010463: Hand membrane pump"
  },
  {
    classId: "EC010464",
    classGroup: "EG015840",
    artClassDesc: "Gas-leakage check, gas concentration type",
    artClassVersion: 2,
    label: "EC010464: Gas-leakage check, gas concentration type"
  },
  {
    classId: "EC010465",
    classGroup: "EG015840",
    artClassDesc: "Gas-leakage check, overpressure principle",
    artClassVersion: 2,
    label: "EC010465: Gas-leakage check, overpressure principle"
  },
  {
    classId: "EC010466",
    classGroup: "EG015280",
    artClassDesc: "Gas block",
    artClassVersion: 3,
    label: "EC010466: Gas block"
  },
  {
    classId: "EC010467",
    classGroup: "EG015280",
    artClassDesc: "Thermocouple for burner",
    artClassVersion: 4,
    label: "EC010467: Thermocouple for burner"
  },
  {
    classId: "EC010471",
    classGroup: "EG015610",
    artClassDesc: "Double pressure gauge valve",
    artClassVersion: 5,
    label: "EC010471: Double pressure gauge valve"
  },
  {
    classId: "EC010472",
    classGroup: "EG015610",
    artClassDesc: "Attachment for underground hydrant",
    artClassVersion: 5,
    label: "EC010472: Attachment for underground hydrant"
  },
  {
    classId: "EC010473",
    classGroup: "EG015640",
    artClassDesc: "Manhole cover hook",
    artClassVersion: 2,
    label: "EC010473: Manhole cover hook"
  },
  {
    classId: "EC010475",
    classGroup: "EG015280",
    artClassDesc: "Air supply connecting piece",
    artClassVersion: 1,
    label: "EC010475: Air supply connecting piece"
  },
  {
    classId: "EC010476",
    classGroup: "EG015280",
    artClassDesc: "Suspension bracket for wall-mounted boiler",
    artClassVersion: 3,
    label: "EC010476: Suspension bracket for wall-mounted boiler"
  },
  {
    classId: "EC010477",
    classGroup: "EG015280",
    artClassDesc: "Fuel adjustment set",
    artClassVersion: 1,
    label: "EC010477: Fuel adjustment set"
  },
  {
    classId: "EC010478",
    classGroup: "EG017550",
    artClassDesc: "Built-in siphon",
    artClassVersion: 3,
    label: "EC010478: Built-in siphon"
  },
  {
    classId: "EC010480",
    classGroup: "EG017550",
    artClassDesc: "Urinal flush pipe",
    artClassVersion: 5,
    label: "EC010480: Urinal flush pipe"
  },
  {
    classId: "EC010481",
    classGroup: "EG015260",
    artClassDesc: "Pressure expansion vessel console",
    artClassVersion: 2,
    label: "EC010481: Pressure expansion vessel console"
  },
  {
    classId: "EC010482",
    classGroup: "EG017550",
    artClassDesc: "Funnel for trap",
    artClassVersion: 3,
    label: "EC010482: Funnel for trap"
  },
  {
    classId: "EC010483",
    classGroup: "EG017550",
    artClassDesc: "Siphon for bath/shower tray",
    artClassVersion: 4,
    label: "EC010483: Siphon for bath/shower tray"
  },
  {
    classId: "EC010484",
    classGroup: "EG015560",
    artClassDesc: "Fall pipe for flue gas removal",
    artClassVersion: 1,
    label: "EC010484: Fall pipe for flue gas removal"
  },
  {
    classId: "EC010485",
    classGroup: "EG015580",
    artClassDesc: "Bird cover",
    artClassVersion: 1,
    label: "EC010485: Bird cover"
  },
  {
    classId: "EC010486",
    classGroup: "EG017550",
    artClassDesc: "Connection set wall-hung toilet",
    artClassVersion: 4,
    label: "EC010486: Connection set wall-hung toilet"
  },
  {
    classId: "EC010487",
    classGroup: "EG017550",
    artClassDesc: "Built-in flush pipe",
    artClassVersion: 2,
    label: "EC010487: Built-in flush pipe"
  },
  {
    classId: "EC010488",
    classGroup: "EG015540",
    artClassDesc: "Tank feed-through nipple",
    artClassVersion: 5,
    label: "EC010488: Tank feed-through nipple"
  },
  {
    classId: "EC010489",
    classGroup: "EG015540",
    artClassDesc: "Combined slide cap",
    artClassVersion: 4,
    label: "EC010489: Combined slide cap"
  },
  {
    classId: "EC010490",
    classGroup: "EG015640",
    artClassDesc: "Floor column for slide valve",
    artClassVersion: 2,
    label: "EC010490: Floor column for slide valve"
  },
  {
    classId: "EC010491",
    classGroup: "EG017920",
    artClassDesc: "Shower unit",
    artClassVersion: 6,
    label: "EC010491: Shower unit"
  },
  {
    classId: "EC010492",
    classGroup: "EG017920",
    artClassDesc: "Shower curtain",
    artClassVersion: 1,
    label: "EC010492: Shower curtain"
  },
  {
    classId: "EC010493",
    classGroup: "EG017920",
    artClassDesc: "Wellness shower unit",
    artClassVersion: 5,
    label: "EC010493: Wellness shower unit"
  },
  {
    classId: "EC010495",
    classGroup: "EG017960",
    artClassDesc: "Laundry-basket",
    artClassVersion: 2,
    label: "EC010495: Laundry-basket"
  },
  {
    classId: "EC010496",
    classGroup: "EG017960",
    artClassDesc: "Handle for furniture",
    artClassVersion: 3,
    label: "EC010496: Handle for furniture"
  },
  {
    classId: "EC010497",
    classGroup: "EG015220",
    artClassDesc: "Flue gas heat exchanger",
    artClassVersion: 4,
    label: "EC010497: Flue gas heat exchanger"
  },
  {
    classId: "EC010499",
    classGroup: "EG017550",
    artClassDesc: "Lip seal",
    artClassVersion: 2,
    label: "EC010499: Lip seal"
  },
  {
    classId: "EC010500",
    classGroup: "EG017550",
    artClassDesc: "Wall connection accessory, floor closet",
    artClassVersion: 3,
    label: "EC010500: Wall connection accessory, floor closet"
  },
  {
    classId: "EC010501",
    classGroup: "EG017550",
    artClassDesc: "Urinal siphon",
    artClassVersion: 2,
    label: "EC010501: Urinal siphon"
  },
  {
    classId: "EC010502",
    classGroup: "EG015880",
    artClassDesc: "Immersion tube",
    artClassVersion: 5,
    label: "EC010502: Immersion tube"
  },
  {
    classId: "EC010503",
    classGroup: "EG015880",
    artClassDesc: "Duct flange for sensor",
    artClassVersion: 2,
    label: "EC010503: Duct flange for sensor"
  },
  {
    classId: "EC010505",
    classGroup: "EG015880",
    artClassDesc: "Duct measuring point connection set",
    artClassVersion: 1,
    label: "EC010505: Duct measuring point connection set"
  },
  {
    classId: "EC010506",
    classGroup: "EG015880",
    artClassDesc: "Mast for weather station",
    artClassVersion: 3,
    label: "EC010506: Mast for weather station"
  },
  {
    classId: "EC010507",
    classGroup: "EG015880",
    artClassDesc: "Sensor cabin",
    artClassVersion: 1,
    label: "EC010507: Sensor cabin"
  },
  {
    classId: "EC010508",
    classGroup: "EG015880",
    artClassDesc: "Connection for pressure switch",
    artClassVersion: 2,
    label: "EC010508: Connection for pressure switch"
  },
  {
    classId: "EC010509",
    classGroup: "EG015880",
    artClassDesc: "Capillary set for pressure switch",
    artClassVersion: 1,
    label: "EC010509: Capillary set for pressure switch"
  },
  {
    classId: "EC010510",
    classGroup: "EG015880",
    artClassDesc: "Vane for flow switch",
    artClassVersion: 2,
    label: "EC010510: Vane for flow switch"
  },
  {
    classId: "EC010511",
    classGroup: "EG000049",
    artClassDesc: "Grommet for suspension sling",
    artClassVersion: 2,
    label: "EC010511: Grommet for suspension sling"
  },
  {
    classId: "EC010515",
    classGroup: "EG015130",
    artClassDesc: "Floor drain mounting standard",
    artClassVersion: 3,
    label: "EC010515: Floor drain mounting standard"
  },
  {
    classId: "EC010516",
    classGroup: "EG015130",
    artClassDesc: "Top and extension piece for floor drain",
    artClassVersion: 4,
    label: "EC010516: Top and extension piece for floor drain"
  },
  {
    classId: "EC010517",
    classGroup: "EG015130",
    artClassDesc: "Water barrier edge for floor drain",
    artClassVersion: 4,
    label: "EC010517: Water barrier edge for floor drain"
  },
  {
    classId: "EC010518",
    classGroup: "EG017550",
    artClassDesc: "Flush pipe connection set",
    artClassVersion: 3,
    label: "EC010518: Flush pipe connection set"
  },
  {
    classId: "EC010519",
    classGroup: "EG000061",
    artClassDesc: "Electro-welding fixed-point inlay",
    artClassVersion: 5,
    label: "EC010519: Electro-welding fixed-point inlay"
  },
  {
    classId: "EC010520",
    classGroup: "EG015810",
    artClassDesc: "Bursting disc",
    artClassVersion: 4,
    label: "EC010520: Bursting disc"
  },
  {
    classId: "EC010522",
    classGroup: "EG015540",
    artClassDesc: "Set of fixed flanges with accessories",
    artClassVersion: 6,
    label: "EC010522: Set of fixed flanges with accessories"
  },
  {
    classId: "EC010524",
    classGroup: "EG015540",
    artClassDesc: "Repair wall plate",
    artClassVersion: 3,
    label: "EC010524: Repair wall plate"
  },
  {
    classId: "EC010525",
    classGroup: "EG015880",
    artClassDesc: "Pressure gauge drain pipe",
    artClassVersion: 6,
    label: "EC010525: Pressure gauge drain pipe"
  },
  {
    classId: "EC010527",
    classGroup: "EG018110",
    artClassDesc: "Bracket mantle",
    artClassVersion: 2,
    label: "EC010527: Bracket mantle"
  },
  {
    classId: "EC010528",
    classGroup: "EG015620",
    artClassDesc: "Pneumatic servomotor air fittings",
    artClassVersion: 3,
    label: "EC010528: Pneumatic servomotor air fittings"
  },
  {
    classId: "EC010529",
    classGroup: "EG015620",
    artClassDesc: "Worm gearbox for valves",
    artClassVersion: 6,
    label: "EC010529: Worm gearbox for valves"
  },
  {
    classId: "EC010530",
    classGroup: "EG015610",
    artClassDesc: "Assembled alarm valve",
    artClassVersion: 3,
    label: "EC010530: Assembled alarm valve"
  },
  {
    classId: "EC010531",
    classGroup: "EG015540",
    artClassDesc: "Rainwater pipe outlet",
    artClassVersion: 2,
    label: "EC010531: Rainwater pipe outlet"
  },
  {
    classId: "EC010532",
    classGroup: "EG015540",
    artClassDesc: "Water butt valve piece",
    artClassVersion: 4,
    label: "EC010532: Water butt valve piece"
  },
  {
    classId: "EC010534",
    classGroup: "EG015210",
    artClassDesc: "Mobile electric air heater",
    artClassVersion: 5,
    label: "EC010534: Mobile electric air heater"
  },
  {
    classId: "EC010536",
    classGroup: "EG015280",
    artClassDesc: "Solar boiler mounting bracket",
    artClassVersion: 3,
    label: "EC010536: Solar boiler mounting bracket"
  },
  {
    classId: "EC010538",
    classGroup: "EG015280",
    artClassDesc: "Accessories/spare parts for solar collector",
    artClassVersion: 4,
    label: "EC010538: Accessories/spare parts for solar collector"
  },
  {
    classId: "EC010539",
    classGroup: "EG015110",
    artClassDesc: "Headboard roof gutter",
    artClassVersion: 3,
    label: "EC010539: Headboard roof gutter"
  },
  {
    classId: "EC010540",
    classGroup: "EG015840",
    artClassDesc: "Vertical level switch",
    artClassVersion: 6,
    label: "EC010540: Vertical level switch"
  },
  {
    classId: "EC010541",
    classGroup: "EG015880",
    artClassDesc: "Pressure gauge connection pipe",
    artClassVersion: 4,
    label: "EC010541: Pressure gauge connection pipe"
  },
  {
    classId: "EC010544",
    classGroup: "EG017610",
    artClassDesc: "Electronic control system for shower",
    artClassVersion: 6,
    label: "EC010544: Electronic control system for shower"
  },
  {
    classId: "EC010546",
    classGroup: "EG017610",
    artClassDesc: "Mechanical control system for shower",
    artClassVersion: 6,
    label: "EC010546: Mechanical control system for shower"
  },
  {
    classId: "EC010549",
    classGroup: "EG017210",
    artClassDesc: "Soap dispenser",
    artClassVersion: 6,
    label: "EC010549: Soap dispenser"
  },
  {
    classId: "EC010550",
    classGroup: "EG017210",
    artClassDesc: "Mirror",
    artClassVersion: 5,
    label: "EC010550: Mirror"
  },
  {
    classId: "EC010551",
    classGroup: "EG017210",
    artClassDesc: "Towel holder",
    artClassVersion: 6,
    label: "EC010551: Towel holder"
  },
  {
    classId: "EC010552",
    classGroup: "EG017210",
    artClassDesc: "Towel hook",
    artClassVersion: 6,
    label: "EC010552: Towel hook"
  },
  {
    classId: "EC010553",
    classGroup: "EG017210",
    artClassDesc: "Toilet roll holder",
    artClassVersion: 6,
    label: "EC010553: Toilet roll holder"
  },
  {
    classId: "EC010554",
    classGroup: "EG017210",
    artClassDesc: "Spare toilet roll holder",
    artClassVersion: 6,
    label: "EC010554: Spare toilet roll holder"
  },
  {
    classId: "EC010555",
    classGroup: "EG017210",
    artClassDesc: "Lavatory brush set",
    artClassVersion: 5,
    label: "EC010555: Lavatory brush set"
  },
  {
    classId: "EC010556",
    classGroup: "EG017210",
    artClassDesc: "Toothbrush holder",
    artClassVersion: 4,
    label: "EC010556: Toothbrush holder"
  },
  {
    classId: "EC010557",
    classGroup: "EG017210",
    artClassDesc: "Soap holder",
    artClassVersion: 6,
    label: "EC010557: Soap holder"
  },
  {
    classId: "EC010559",
    classGroup: "EG015170",
    artClassDesc: "Air treatment cabinet",
    artClassVersion: 3,
    label: "EC010559: Air treatment cabinet"
  },
  {
    classId: "EC010561",
    classGroup: "EG017550",
    artClassDesc: "Bath drainage combination",
    artClassVersion: 4,
    label: "EC010561: Bath drainage combination"
  },
  {
    classId: "EC010562",
    classGroup: "EG015260",
    artClassDesc: "Expansion vessel bracket",
    artClassVersion: 2,
    label: "EC010562: Expansion vessel bracket"
  },
  {
    classId: "EC010563",
    classGroup: "EG015540",
    artClassDesc: "Self-sealing coupling",
    artClassVersion: 3,
    label: "EC010563: Self-sealing coupling"
  },
  {
    classId: "EC010565",
    classGroup: "EG015110",
    artClassDesc: "Rainwater pipe spout",
    artClassVersion: 4,
    label: "EC010565: Rainwater pipe spout"
  },
  {
    classId: "EC010566",
    classGroup: "EG015580",
    artClassDesc: "Upstand for roof mounted fan",
    artClassVersion: 3,
    label: "EC010566: Upstand for roof mounted fan"
  },
  {
    classId: "EC010567",
    classGroup: "EG020300",
    artClassDesc: "Roofing material",
    artClassVersion: 4,
    label: "EC010567: Roofing material"
  },
  {
    classId: "EC010568",
    classGroup: "EG015130",
    artClassDesc: "Floor drain with several connections",
    artClassVersion: 5,
    label: "EC010568: Floor drain with several connections"
  },
  {
    classId: "EC010569",
    classGroup: "EG015140",
    artClassDesc: "Sludge extractor pit",
    artClassVersion: 3,
    label: "EC010569: Sludge extractor pit"
  },
  {
    classId: "EC010570",
    classGroup: "EG015110",
    artClassDesc: "Roof gutter thin grate",
    artClassVersion: 4,
    label: "EC010570: Roof gutter thin grate"
  },
  {
    classId: "EC010571",
    classGroup: "EG015260",
    artClassDesc: "Coupling vessel",
    artClassVersion: 4,
    label: "EC010571: Coupling vessel"
  },
  {
    classId: "EC010572",
    classGroup: "EG015260",
    artClassDesc: "Central heating function server",
    artClassVersion: 2,
    label: "EC010572: Central heating function server"
  },
  {
    classId: "EC010573",
    classGroup: "EG015530",
    artClassDesc: "Profiled pipe, stainless steel",
    artClassVersion: 5,
    label: "EC010573: Profiled pipe, stainless steel"
  },
  {
    classId: "EC010574",
    classGroup: "EG015810",
    artClassDesc: "Frost protection element, rainwater pipe",
    artClassVersion: 3,
    label: "EC010574: Frost protection element, rainwater pipe"
  },
  {
    classId: "EC010575",
    classGroup: "EG017210",
    artClassDesc: "Bath seat",
    artClassVersion: 4,
    label: "EC010575: Bath seat"
  },
  {
    classId: "EC010576",
    classGroup: "EG015880",
    artClassDesc: "Pressure gauge cooling element",
    artClassVersion: 4,
    label: "EC010576: Pressure gauge cooling element"
  },
  {
    classId: "EC010577",
    classGroup: "EG015880",
    artClassDesc: "Pressure gauge shock absorber",
    artClassVersion: 4,
    label: "EC010577: Pressure gauge shock absorber"
  },
  {
    classId: "EC010578",
    classGroup: "EG015880",
    artClassDesc: "Pressure gauge overpressure safety device",
    artClassVersion: 4,
    label: "EC010578: Pressure gauge overpressure safety device"
  },
  {
    classId: "EC010579",
    classGroup: "EG015140",
    artClassDesc: "Water separator, steam and compressed air",
    artClassVersion: 6,
    label: "EC010579: Water separator, steam and compressed air"
  },
  {
    classId: "EC010580",
    classGroup: "EG015140",
    artClassDesc: "Cone bag filter round air duct",
    artClassVersion: 3,
    label: "EC010580: Cone bag filter round air duct"
  },
  {
    classId: "EC010581",
    classGroup: "EG015140",
    artClassDesc: "Cone bag filter housing round air duct",
    artClassVersion: 2,
    label: "EC010581: Cone bag filter housing round air duct"
  },
  {
    classId: "EC010582",
    classGroup: "EG015830",
    artClassDesc: "Electromagnetic liquid flow meter",
    artClassVersion: 6,
    label: "EC010582: Electromagnetic liquid flow meter"
  },
  {
    classId: "EC010583",
    classGroup: "EG015910",
    artClassDesc: "Plastic O ring seal",
    artClassVersion: 4,
    label: "EC010583: Plastic O ring seal"
  },
  {
    classId: "EC010584",
    classGroup: "EG015910",
    artClassDesc: "Rubber O-ring seal",
    artClassVersion: 6,
    label: "EC010584: Rubber O-ring seal"
  },
  {
    classId: "EC010585",
    classGroup: "EG015910",
    artClassDesc: "Quadring seal",
    artClassVersion: 3,
    label: "EC010585: Quadring seal"
  },
  {
    classId: "EC010586",
    classGroup: "EG015830",
    artClassDesc: "Liquid flow meter",
    artClassVersion: 5,
    label: "EC010586: Liquid flow meter"
  },
  {
    classId: "EC010587",
    classGroup: "EG015830",
    artClassDesc: "Air flow meter",
    artClassVersion: 4,
    label: "EC010587: Air flow meter"
  },
  {
    classId: "EC010588",
    classGroup: "EG015620",
    artClassDesc: "Pneumatic release cylinder",
    artClassVersion: 1,
    label: "EC010588: Pneumatic release cylinder"
  },
  {
    classId: "EC010589",
    classGroup: "EG015620",
    artClassDesc: "Release magnet",
    artClassVersion: 4,
    label: "EC010589: Release magnet"
  },
  {
    classId: "EC010590",
    classGroup: "EG015610",
    artClassDesc: "Compressed air valve",
    artClassVersion: 5,
    label: "EC010590: Compressed air valve"
  },
  {
    classId: "EC010591",
    classGroup: "EG015540",
    artClassDesc: "Loose plate flange",
    artClassVersion: 5,
    label: "EC010591: Loose plate flange"
  },
  {
    classId: "EC010592",
    classGroup: "EG015540",
    artClassDesc: "Coupling nut",
    artClassVersion: 5,
    label: "EC010592: Coupling nut"
  },
  {
    classId: "EC010593",
    classGroup: "EG017210",
    artClassDesc: "Paper dispenser",
    artClassVersion: 6,
    label: "EC010593: Paper dispenser"
  },
  {
    classId: "EC010595",
    classGroup: "EG015210",
    artClassDesc: "Solar heater tank with integrated central heating",
    artClassVersion: 7,
    label: "EC010595: Solar heater tank with integrated central heating"
  },
  {
    classId: "EC010596",
    classGroup: "EG015810",
    artClassDesc: "Wafer type check valve",
    artClassVersion: 8,
    label: "EC010596: Wafer type check valve"
  },
  {
    classId: "EC010597",
    classGroup: "EG015610",
    artClassDesc: "Blow-off valve",
    artClassVersion: 7,
    label: "EC010597: Blow-off valve"
  },
  {
    classId: "EC010598",
    classGroup: "EG015110",
    artClassDesc: "Corner profile roof gutter",
    artClassVersion: 3,
    label: "EC010598: Corner profile roof gutter"
  },
  {
    classId: "EC010599",
    classGroup: "EG019760",
    artClassDesc: "Feed-through attachment",
    artClassVersion: 4,
    label: "EC010599: Feed-through attachment"
  },
  {
    classId: "EC010600",
    classGroup: "EG015260",
    artClassDesc: "Water hammer damper",
    artClassVersion: 2,
    label: "EC010600: Water hammer damper"
  },
  {
    classId: "EC010605",
    classGroup: "EG000049",
    artClassDesc: "One-strap bracket, oval air duct",
    artClassVersion: 2,
    label: "EC010605: One-strap bracket, oval air duct"
  },
  {
    classId: "EC010607",
    classGroup: "EG015610",
    artClassDesc: "Blow-off cock",
    artClassVersion: 4,
    label: "EC010607: Blow-off cock"
  },
  {
    classId: "EC010608",
    classGroup: "EG015910",
    artClassDesc: "Sheathed O ring seal",
    artClassVersion: 4,
    label: "EC010608: Sheathed O ring seal"
  },
  {
    classId: "EC010609",
    classGroup: "EG015910",
    artClassDesc: "Hydraulic sleeve seal",
    artClassVersion: 4,
    label: "EC010609: Hydraulic sleeve seal"
  },
  {
    classId: "EC010610",
    classGroup: "EG015910",
    artClassDesc: "Hydraulic sealing set, roof shaped",
    artClassVersion: 3,
    label: "EC010610: Hydraulic sealing set, roof shaped"
  },
  {
    classId: "EC010611",
    classGroup: "EG015910",
    artClassDesc: "Hydraulic wiper seal",
    artClassVersion: 3,
    label: "EC010611: Hydraulic wiper seal"
  },
  {
    classId: "EC010612",
    classGroup: "EG015910",
    artClassDesc: "O-ring activated seal",
    artClassVersion: 4,
    label: "EC010612: O-ring activated seal"
  },
  {
    classId: "EC010613",
    classGroup: "EG015910",
    artClassDesc: "Spring-activated seal",
    artClassVersion: 4,
    label: "EC010613: Spring-activated seal"
  },
  {
    classId: "EC010614",
    classGroup: "EG015910",
    artClassDesc: "Sleeve for hydraulic sealing set, roof shaped",
    artClassVersion: 4,
    label: "EC010614: Sleeve for hydraulic sealing set, roof shaped"
  },
  {
    classId: "EC010615",
    classGroup: "EG015910",
    artClassDesc: "Support ring for hydraulic sealing set, roof shaped",
    artClassVersion: 4,
    label: "EC010615: Support ring for hydraulic sealing set, roof shaped"
  },
  {
    classId: "EC010616",
    classGroup: "EG015910",
    artClassDesc: "Pressure ring for hydraulic sealing set, roof shaped",
    artClassVersion: 4,
    label: "EC010616: Pressure ring for hydraulic sealing set, roof shaped"
  },
  {
    classId: "EC010617",
    classGroup: "EG015910",
    artClassDesc: "Hydraulic guide ring (seal)",
    artClassVersion: 4,
    label: "EC010617: Hydraulic guide ring (seal)"
  },
  {
    classId: "EC010618",
    classGroup: "EG015910",
    artClassDesc: "Hydraulic guide strip seal",
    artClassVersion: 3,
    label: "EC010618: Hydraulic guide strip seal"
  },
  {
    classId: "EC010622",
    classGroup: "EG000060",
    artClassDesc: "Wire protection cap",
    artClassVersion: 3,
    label: "EC010622: Wire protection cap"
  },
  {
    classId: "EC010623",
    classGroup: "EG015120",
    artClassDesc: "Pressureless tank coupling set",
    artClassVersion: 3,
    label: "EC010623: Pressureless tank coupling set"
  },
  {
    classId: "EC010625",
    classGroup: "EG019760",
    artClassDesc: "Wall duct link",
    artClassVersion: 1,
    label: "EC010625: Wall duct link"
  },
  {
    classId: "EC010626",
    classGroup: "EG015540",
    artClassDesc: "Insert part of coupling",
    artClassVersion: 4,
    label: "EC010626: Insert part of coupling"
  },
  {
    classId: "EC010628",
    classGroup: "EG015540",
    artClassDesc: "Wall plate filler disc",
    artClassVersion: 2,
    label: "EC010628: Wall plate filler disc"
  },
  {
    classId: "EC010630",
    classGroup: "EG015240",
    artClassDesc: "Slot diffuser",
    artClassVersion: 2,
    label: "EC010630: Slot diffuser"
  },
  {
    classId: "EC010631",
    classGroup: "EG015240",
    artClassDesc: "Trough grille",
    artClassVersion: 1,
    label: "EC010631: Trough grille"
  },
  {
    classId: "EC010632",
    classGroup: "EG015910",
    artClassDesc: "Graphite tape",
    artClassVersion: 1,
    label: "EC010632: Graphite tape"
  },
  {
    classId: "EC010633",
    classGroup: "EG015240",
    artClassDesc: "Displacement grille",
    artClassVersion: 5,
    label: "EC010633: Displacement grille"
  },
  {
    classId: "EC010634",
    classGroup: "EG015240",
    artClassDesc: "Floor air grille, round front",
    artClassVersion: 2,
    label: "EC010634: Floor air grille, round front"
  },
  {
    classId: "EC010635",
    classGroup: "EG015910",
    artClassDesc: "Gland gasket ring",
    artClassVersion: 3,
    label: "EC010635: Gland gasket ring"
  },
  {
    classId: "EC010636",
    classGroup: "EG015910",
    artClassDesc: "Gland gasket cord",
    artClassVersion: 2,
    label: "EC010636: Gland gasket cord"
  },
  {
    classId: "EC010637",
    classGroup: "EG019710",
    artClassDesc: "Rubber T-section",
    artClassVersion: 3,
    label: "EC010637: Rubber T-section"
  },
  {
    classId: "EC010638",
    classGroup: "EG019710",
    artClassDesc: "Rubber U-section",
    artClassVersion: 3,
    label: "EC010638: Rubber U-section"
  },
  {
    classId: "EC010639",
    classGroup: "EG019710",
    artClassDesc: "Rubber bulb section",
    artClassVersion: 3,
    label: "EC010639: Rubber bulb section"
  },
  {
    classId: "EC010640",
    classGroup: "EG019710",
    artClassDesc: "Clamping strip section",
    artClassVersion: 2,
    label: "EC010640: Clamping strip section"
  },
  {
    classId: "EC010641",
    classGroup: "EG019710",
    artClassDesc: "Rubber multi-faced/trapezium section",
    artClassVersion: 3,
    label: "EC010641: Rubber multi-faced/trapezium section"
  },
  {
    classId: "EC010642",
    classGroup: "EG019710",
    artClassDesc: "Rubber rectangular section",
    artClassVersion: 5,
    label: "EC010642: Rubber rectangular section"
  },
  {
    classId: "EC010643",
    classGroup: "EG019710",
    artClassDesc: "Rubber cord",
    artClassVersion: 3,
    label: "EC010643: Rubber cord"
  },
  {
    classId: "EC010644",
    classGroup: "EG019710",
    artClassDesc: "Rubber bumping section",
    artClassVersion: 2,
    label: "EC010644: Rubber bumping section"
  },
  {
    classId: "EC010645",
    classGroup: "EG019710",
    artClassDesc: "Rubber glass carrier clamping section",
    artClassVersion: 3,
    label: "EC010645: Rubber glass carrier clamping section"
  },
  {
    classId: "EC010646",
    classGroup: "EG019710",
    artClassDesc: "Rubber key for glass carrier section",
    artClassVersion: 4,
    label: "EC010646: Rubber key for glass carrier section"
  },
  {
    classId: "EC010647",
    classGroup: "EG019710",
    artClassDesc: "Rubber corner section",
    artClassVersion: 3,
    label: "EC010647: Rubber corner section"
  },
  {
    classId: "EC010648",
    classGroup: "EG019710",
    artClassDesc: "Rubber corner guard section",
    artClassVersion: 3,
    label: "EC010648: Rubber corner guard section"
  },
  {
    classId: "EC010649",
    classGroup: "EG019710",
    artClassDesc: "Cellular rubber strip",
    artClassVersion: 4,
    label: "EC010649: Cellular rubber strip"
  },
  {
    classId: "EC010650",
    classGroup: "EG015910",
    artClassDesc: "Washer for PTFE lip seal",
    artClassVersion: 3,
    label: "EC010650: Washer for PTFE lip seal"
  },
  {
    classId: "EC010651",
    classGroup: "EG019730",
    artClassDesc: "Rubber rod",
    artClassVersion: 3,
    label: "EC010651: Rubber rod"
  },
  {
    classId: "EC010652",
    classGroup: "EG000050",
    artClassDesc: "Gasket puller",
    artClassVersion: 4,
    label: "EC010652: Gasket puller"
  },
  {
    classId: "EC010653",
    classGroup: "EG010210",
    artClassDesc: "Gasket puller point",
    artClassVersion: 3,
    label: "EC010653: Gasket puller point"
  },
  {
    classId: "EC010658",
    classGroup: "EG000050",
    artClassDesc: "Gasket remover",
    artClassVersion: 4,
    label: "EC010658: Gasket remover"
  },
  {
    classId: "EC010663",
    classGroup: "EG015910",
    artClassDesc: "Assortment case sanitary sealing materials",
    artClassVersion: 3,
    label: "EC010663: Assortment case sanitary sealing materials"
  },
  {
    classId: "EC010665",
    classGroup: "EG015910",
    artClassDesc: "Cartridge, mechanical seal",
    artClassVersion: 3,
    label: "EC010665: Cartridge, mechanical seal"
  },
  {
    classId: "EC010666",
    classGroup: "EG015910",
    artClassDesc: "Cartridge, mechanical seal (double)",
    artClassVersion: 4,
    label: "EC010666: Cartridge, mechanical seal (double)"
  },
  {
    classId: "EC010667",
    classGroup: "EG015910",
    artClassDesc: "Spindle sealing set",
    artClassVersion: 1,
    label: "EC010667: Spindle sealing set"
  },
  {
    classId: "EC010668",
    classGroup: "EG018110",
    artClassDesc: "Thermal insulation tape",
    artClassVersion: 5,
    label: "EC010668: Thermal insulation tape"
  },
  {
    classId: "EC010669",
    classGroup: "EG000060",
    artClassDesc: "Expanded PTFE sealing strip",
    artClassVersion: 3,
    label: "EC010669: Expanded PTFE sealing strip"
  },
  {
    classId: "EC010670",
    classGroup: "EG000060",
    artClassDesc: "PTFE sealing tape",
    artClassVersion: 4,
    label: "EC010670: PTFE sealing tape"
  },
  {
    classId: "EC010672",
    classGroup: "EG000060",
    artClassDesc: "Plastic foil",
    artClassVersion: 5,
    label: "EC010672: Plastic foil"
  },
  {
    classId: "EC010673",
    classGroup: "EG000061",
    artClassDesc: "Plastic welding wire",
    artClassVersion: 5,
    label: "EC010673: Plastic welding wire"
  },
  {
    classId: "EC010674",
    classGroup: "EG015910",
    artClassDesc: "Rubber flange gasket (not normed)",
    artClassVersion: 5,
    label: "EC010674: Rubber flange gasket (not normed)"
  },
  {
    classId: "EC010676",
    classGroup: "EG015910",
    artClassDesc: "Rubber flange gasket (DIN)",
    artClassVersion: 6,
    label: "EC010676: Rubber flange gasket (DIN)"
  },
  {
    classId: "EC010678",
    classGroup: "EG015910",
    artClassDesc: "PTFE flange gasket (ANSI)",
    artClassVersion: 4,
    label: "EC010678: PTFE flange gasket (ANSI)"
  },
  {
    classId: "EC010680",
    classGroup: "EG015910",
    artClassDesc: "Rubber flange gasket (ANSI)",
    artClassVersion: 5,
    label: "EC010680: Rubber flange gasket (ANSI)"
  },
  {
    classId: "EC010681",
    classGroup: "EG019710",
    artClassDesc: "Plastic angle profile",
    artClassVersion: 5,
    label: "EC010681: Plastic angle profile"
  },
  {
    classId: "EC010682",
    classGroup: "EG019710",
    artClassDesc: "C-profile, plastic",
    artClassVersion: 4,
    label: "EC010682: C-profile, plastic"
  },
  {
    classId: "EC010683",
    classGroup: "EG019710",
    artClassDesc: "U-profile, plastic",
    artClassVersion: 3,
    label: "EC010683: U-profile, plastic"
  },
  {
    classId: "EC010684",
    classGroup: "EG019710",
    artClassDesc: "I-profile, plastic",
    artClassVersion: 3,
    label: "EC010684: I-profile, plastic"
  },
  {
    classId: "EC010685",
    classGroup: "EG019710",
    artClassDesc: "H-profile, plastic",
    artClassVersion: 3,
    label: "EC010685: H-profile, plastic"
  },
  {
    classId: "EC010686",
    classGroup: "EG019710",
    artClassDesc: "Rectangular profile, plastic",
    artClassVersion: 3,
    label: "EC010686: Rectangular profile, plastic"
  },
  {
    classId: "EC010687",
    classGroup: "EG019720",
    artClassDesc: "Cellular and sponge rubber plate",
    artClassVersion: 3,
    label: "EC010687: Cellular and sponge rubber plate"
  },
  {
    classId: "EC010688",
    classGroup: "EG015910",
    artClassDesc: "Fibre sealing plate",
    artClassVersion: 4,
    label: "EC010688: Fibre sealing plate"
  },
  {
    classId: "EC010689",
    classGroup: "EG019720",
    artClassDesc: "Graphite plate",
    artClassVersion: 4,
    label: "EC010689: Graphite plate"
  },
  {
    classId: "EC010690",
    classGroup: "EG019720",
    artClassDesc: "Cork rubber plate",
    artClassVersion: 3,
    label: "EC010690: Cork rubber plate"
  },
  {
    classId: "EC010691",
    classGroup: "EG019720",
    artClassDesc: "Plastic plate",
    artClassVersion: 5,
    label: "EC010691: Plastic plate"
  },
  {
    classId: "EC010692",
    classGroup: "EG019720",
    artClassDesc: "Wool felt plate",
    artClassVersion: 2,
    label: "EC010692: Wool felt plate"
  },
  {
    classId: "EC010693",
    classGroup: "EG019720",
    artClassDesc: "Aluminium plate",
    artClassVersion: 5,
    label: "EC010693: Aluminium plate"
  },
  {
    classId: "EC010694",
    classGroup: "EG019720",
    artClassDesc: "Brass plate",
    artClassVersion: 5,
    label: "EC010694: Brass plate"
  },
  {
    classId: "EC010695",
    classGroup: "EG019720",
    artClassDesc: "Copper",
    artClassVersion: 5,
    label: "EC010695: Copper"
  },
  {
    classId: "EC010696",
    classGroup: "EG020003",
    artClassDesc: "Steel plate",
    artClassVersion: 6,
    label: "EC010696: Steel plate"
  },
  {
    classId: "EC010697",
    classGroup: "EG019720",
    artClassDesc: "Stainless steel plate",
    artClassVersion: 5,
    label: "EC010697: Stainless steel plate"
  },
  {
    classId: "EC010698",
    classGroup: "EG019720",
    artClassDesc: "Nickel plate",
    artClassVersion: 3,
    label: "EC010698: Nickel plate"
  },
  {
    classId: "EC010699",
    classGroup: "EG019720",
    artClassDesc: "Steel alloy plate",
    artClassVersion: 3,
    label: "EC010699: Steel alloy plate"
  },
  {
    classId: "EC010700",
    classGroup: "EG019720",
    artClassDesc: "PTFE sealing plate",
    artClassVersion: 4,
    label: "EC010700: PTFE sealing plate"
  },
  {
    classId: "EC010701",
    classGroup: "EG019730",
    artClassDesc: "Round plastic rod",
    artClassVersion: 6,
    label: "EC010701: Round plastic rod"
  },
  {
    classId: "EC010702",
    classGroup: "EG019730",
    artClassDesc: "Rectangular plastic rod",
    artClassVersion: 6,
    label: "EC010702: Rectangular plastic rod"
  },
  {
    classId: "EC010703",
    classGroup: "EG019730",
    artClassDesc: "Hexagonal plastic rod",
    artClassVersion: 6,
    label: "EC010703: Hexagonal plastic rod"
  },
  {
    classId: "EC010704",
    classGroup: "EG015910",
    artClassDesc: "Graphite flange gasket (DIN)",
    artClassVersion: 6,
    label: "EC010704: Graphite flange gasket (DIN)"
  },
  {
    classId: "EC010705",
    classGroup: "EG015910",
    artClassDesc: "Comb profile flange gasket (DIN)",
    artClassVersion: 5,
    label: "EC010705: Comb profile flange gasket (DIN)"
  },
  {
    classId: "EC010706",
    classGroup: "EG015910",
    artClassDesc: "Envelope flange gasket (DIN)",
    artClassVersion: 5,
    label: "EC010706: Envelope flange gasket (DIN)"
  },
  {
    classId: "EC010707",
    classGroup: "EG015910",
    artClassDesc: "Oval manhole flange gasket",
    artClassVersion: 3,
    label: "EC010707: Oval manhole flange gasket"
  },
  {
    classId: "EC010708",
    classGroup: "EG015910",
    artClassDesc: "PTFE flange gasket (DIN)",
    artClassVersion: 6,
    label: "EC010708: PTFE flange gasket (DIN)"
  },
  {
    classId: "EC010709",
    classGroup: "EG015910",
    artClassDesc: "RTJ flange gasket (Ring Type Joint)",
    artClassVersion: 3,
    label: "EC010709: RTJ flange gasket (Ring Type Joint)"
  },
  {
    classId: "EC010710",
    classGroup: "EG015910",
    artClassDesc: "PTFE flange gasket (not normed)",
    artClassVersion: 5,
    label: "EC010710: PTFE flange gasket (not normed)"
  },
  {
    classId: "EC010711",
    classGroup: "EG015910",
    artClassDesc: "Graphite flange gasket (ANSI)",
    artClassVersion: 5,
    label: "EC010711: Graphite flange gasket (ANSI)"
  },
  {
    classId: "EC010712",
    classGroup: "EG015910",
    artClassDesc: "Graphite flange gasket (not normed)",
    artClassVersion: 5,
    label: "EC010712: Graphite flange gasket (not normed)"
  },
  {
    classId: "EC010713",
    classGroup: "EG015910",
    artClassDesc: "Comb profile flange gasket (ANSI)",
    artClassVersion: 4,
    label: "EC010713: Comb profile flange gasket (ANSI)"
  },
  {
    classId: "EC010714",
    classGroup: "EG015910",
    artClassDesc: "Comb profile flange gasket (not normed)",
    artClassVersion: 4,
    label: "EC010714: Comb profile flange gasket (not normed)"
  },
  {
    classId: "EC010715",
    classGroup: "EG015910",
    artClassDesc: "Envelope flange gasket (not normed)",
    artClassVersion: 3,
    label: "EC010715: Envelope flange gasket (not normed)"
  },
  {
    classId: "EC010716",
    classGroup: "EG015910",
    artClassDesc: "Envelope flange gasket (ANSI)",
    artClassVersion: 4,
    label: "EC010716: Envelope flange gasket (ANSI)"
  },
  {
    classId: "EC010717",
    classGroup: "EG015910",
    artClassDesc: "Cathodic flange gasket, ANSI",
    artClassVersion: 3,
    label: "EC010717: Cathodic flange gasket, ANSI"
  },
  {
    classId: "EC010718",
    classGroup: "EG019720",
    artClassDesc: "Oil paper",
    artClassVersion: 2,
    label: "EC010718: Oil paper"
  },
  {
    classId: "EC010719",
    classGroup: "EG015910",
    artClassDesc: "Flange-protection strip",
    artClassVersion: 3,
    label: "EC010719: Flange-protection strip"
  },
  {
    classId: "EC010720",
    classGroup: "EG015930",
    artClassDesc: "Spring-activated chain tensioner",
    artClassVersion: 3,
    label: "EC010720: Spring-activated chain tensioner"
  },
  {
    classId: "EC010721",
    classGroup: "EG015910",
    artClassDesc: "Liquid gasket",
    artClassVersion: 4,
    label: "EC010721: Liquid gasket"
  },
  {
    classId: "EC010722",
    classGroup: "EG015930",
    artClassDesc: "Spring-activated belt tensioner",
    artClassVersion: 3,
    label: "EC010722: Spring-activated belt tensioner"
  },
  {
    classId: "EC010723",
    classGroup: "EG015920",
    artClassDesc: "Transport wheels",
    artClassVersion: 2,
    label: "EC010723: Transport wheels"
  },
  {
    classId: "EC010724",
    classGroup: "EG015920",
    artClassDesc: "Pneumatic tyre transport wheel",
    artClassVersion: 3,
    label: "EC010724: Pneumatic tyre transport wheel"
  },
  {
    classId: "EC010725",
    classGroup: "EG015920",
    artClassDesc: "Ceramic wire guide",
    artClassVersion: 2,
    label: "EC010725: Ceramic wire guide"
  },
  {
    classId: "EC010726",
    classGroup: "EG015920",
    artClassDesc: "Roller chain slide profile",
    artClassVersion: 3,
    label: "EC010726: Roller chain slide profile"
  },
  {
    classId: "EC010727",
    classGroup: "EG015920",
    artClassDesc: "Sprocket chain slide profile",
    artClassVersion: 4,
    label: "EC010727: Sprocket chain slide profile"
  },
  {
    classId: "EC010728",
    classGroup: "EG015920",
    artClassDesc: "V-belt slide profile",
    artClassVersion: 3,
    label: "EC010728: V-belt slide profile"
  },
  {
    classId: "EC010729",
    classGroup: "EG015920",
    artClassDesc: "Circular belt slide profile",
    artClassVersion: 3,
    label: "EC010729: Circular belt slide profile"
  },
  {
    classId: "EC010730",
    classGroup: "EG015920",
    artClassDesc: "Flat belt slide profile",
    artClassVersion: 3,
    label: "EC010730: Flat belt slide profile"
  },
  {
    classId: "EC010731",
    classGroup: "EG015920",
    artClassDesc: "Product line slide profile, plastic",
    artClassVersion: 4,
    label: "EC010731: Product line slide profile, plastic"
  },
  {
    classId: "EC010732",
    classGroup: "EG015260",
    artClassDesc: "Block pump",
    artClassVersion: 6,
    label: "EC010732: Block pump"
  },
  {
    classId: "EC010733",
    classGroup: "EG015260",
    artClassDesc: "Submersible wastewater pump",
    artClassVersion: 6,
    label: "EC010733: Submersible wastewater pump"
  },
  {
    classId: "EC010734",
    classGroup: "EG015510",
    artClassDesc: "Flat roll-up hose",
    artClassVersion: 4,
    label: "EC010734: Flat roll-up hose"
  },
  {
    classId: "EC010735",
    classGroup: "EG015520",
    artClassDesc: "Dry-break coupling/thread",
    artClassVersion: 4,
    label: "EC010735: Dry-break coupling/thread"
  },
  {
    classId: "EC010736",
    classGroup: "EG015520",
    artClassDesc: "Insert stop",
    artClassVersion: 2,
    label: "EC010736: Insert stop"
  },
  {
    classId: "EC010737",
    classGroup: "EG015260",
    artClassDesc: "Pressurisation unit",
    artClassVersion: 6,
    label: "EC010737: Pressurisation unit"
  },
  {
    classId: "EC010738",
    classGroup: "EG015540",
    artClassDesc: "Flare/flare coupling",
    artClassVersion: 3,
    label: "EC010738: Flare/flare coupling"
  },
  {
    classId: "EC010739",
    classGroup: "EG015510",
    artClassDesc: "Plastic hose with inlay",
    artClassVersion: 5,
    label: "EC010739: Plastic hose with inlay"
  },
  {
    classId: "EC010740",
    classGroup: "EG015510",
    artClassDesc: "Spiral hose",
    artClassVersion: 4,
    label: "EC010740: Spiral hose"
  },
  {
    classId: "EC010741",
    classGroup: "EG015510",
    artClassDesc: "Spiral hose protection",
    artClassVersion: 2,
    label: "EC010741: Spiral hose protection"
  },
  {
    classId: "EC010742",
    classGroup: "EG015510",
    artClassDesc: "Rubber twin hose",
    artClassVersion: 4,
    label: "EC010742: Rubber twin hose"
  },
  {
    classId: "EC010745",
    classGroup: "EG015510",
    artClassDesc: "Hose guide",
    artClassVersion: 2,
    label: "EC010745: Hose guide"
  },
  {
    classId: "EC010746",
    classGroup: "EG015510",
    artClassDesc: "Protection links for hose",
    artClassVersion: 2,
    label: "EC010746: Protection links for hose"
  },
  {
    classId: "EC010747",
    classGroup: "EG015520",
    artClassDesc: "Sealing for storz coupling",
    artClassVersion: 3,
    label: "EC010747: Sealing for storz coupling"
  },
  {
    classId: "EC010748",
    classGroup: "EG015520",
    artClassDesc: "Coupling/fitting wrench",
    artClassVersion: 3,
    label: "EC010748: Coupling/fitting wrench"
  },
  {
    classId: "EC010749",
    classGroup: "EG015520",
    artClassDesc: "Guillemin coupling/hose connector",
    artClassVersion: 2,
    label: "EC010749: Guillemin coupling/hose connector"
  },
  {
    classId: "EC010750",
    classGroup: "EG015520",
    artClassDesc: "Guillemin reduced coupling",
    artClassVersion: 2,
    label: "EC010750: Guillemin reduced coupling"
  },
  {
    classId: "EC010751",
    classGroup: "EG015520",
    artClassDesc: "Guillemin plug",
    artClassVersion: 2,
    label: "EC010751: Guillemin plug"
  },
  {
    classId: "EC010752",
    classGroup: "EG015520",
    artClassDesc: "Guillemin coupling/weld",
    artClassVersion: 1,
    label: "EC010752: Guillemin coupling/weld"
  },
  {
    classId: "EC010753",
    classGroup: "EG015520",
    artClassDesc: "Sealing for guillemin coupling",
    artClassVersion: 2,
    label: "EC010753: Sealing for guillemin coupling"
  },
  {
    classId: "EC010754",
    classGroup: "EG015520",
    artClassDesc: "DSP coupling/thread",
    artClassVersion: 3,
    label: "EC010754: DSP coupling/thread"
  },
  {
    classId: "EC010755",
    classGroup: "EG015520",
    artClassDesc: "DSP coupling/hose connector",
    artClassVersion: 1,
    label: "EC010755: DSP coupling/hose connector"
  },
  {
    classId: "EC010756",
    classGroup: "EG015520",
    artClassDesc: "DSP reduced coupling",
    artClassVersion: 1,
    label: "EC010756: DSP reduced coupling"
  },
  {
    classId: "EC010757",
    classGroup: "EG015520",
    artClassDesc: "DSP plug",
    artClassVersion: 2,
    label: "EC010757: DSP plug"
  },
  {
    classId: "EC010758",
    classGroup: "EG015520",
    artClassDesc: "Sealing for DSP coupling",
    artClassVersion: 1,
    label: "EC010758: Sealing for DSP coupling"
  },
  {
    classId: "EC010759",
    classGroup: "EG015520",
    artClassDesc: "Sealing for claw coupling",
    artClassVersion: 3,
    label: "EC010759: Sealing for claw coupling"
  },
  {
    classId: "EC010760",
    classGroup: "EG015520",
    artClassDesc: "Insert coupling, 2 connections",
    artClassVersion: 2,
    label: "EC010760: Insert coupling, 2 connections"
  },
  {
    classId: "EC010761",
    classGroup: "EG015520",
    artClassDesc: "Insert coupling, 3 connections",
    artClassVersion: 1,
    label: "EC010761: Insert coupling, 3 connections"
  },
  {
    classId: "EC010762",
    classGroup: "EG015520",
    artClassDesc: "Insert coupling/thread",
    artClassVersion: 2,
    label: "EC010762: Insert coupling/thread"
  },
  {
    classId: "EC010763",
    classGroup: "EG015520",
    artClassDesc: "Insert coupling, T and L/thread",
    artClassVersion: 2,
    label: "EC010763: Insert coupling, T and L/thread"
  },
  {
    classId: "EC010764",
    classGroup: "EG015520",
    artClassDesc: "Clamp coupling/flange",
    artClassVersion: 3,
    label: "EC010764: Clamp coupling/flange"
  },
  {
    classId: "EC010765",
    classGroup: "EG015520",
    artClassDesc: "Cap/plug for dry-break coupling",
    artClassVersion: 1,
    label: "EC010765: Cap/plug for dry-break coupling"
  },
  {
    classId: "EC010766",
    classGroup: "EG015520",
    artClassDesc: "Sealing for dry-break coupling",
    artClassVersion: 2,
    label: "EC010766: Sealing for dry-break coupling"
  },
  {
    classId: "EC010767",
    classGroup: "EG015520",
    artClassDesc: "Clamp coupling/weld",
    artClassVersion: 1,
    label: "EC010767: Clamp coupling/weld"
  },
  {
    classId: "EC010768",
    classGroup: "EG015520",
    artClassDesc: "Swivel joint for hose reel",
    artClassVersion: 2,
    label: "EC010768: Swivel joint for hose reel"
  },
  {
    classId: "EC010769",
    classGroup: "EG015520",
    artClassDesc: "Hose compression coupling (2 connections)",
    artClassVersion: 3,
    label: "EC010769: Hose compression coupling (2 connections)"
  },
  {
    classId: "EC010770",
    classGroup: "EG015520",
    artClassDesc: "Clamping disc for hose",
    artClassVersion: 4,
    label: "EC010770: Clamping disc for hose"
  },
  {
    classId: "EC010771",
    classGroup: "EG015520",
    artClassDesc: "Ear clamp for hose",
    artClassVersion: 1,
    label: "EC010771: Ear clamp for hose"
  },
  {
    classId: "EC010772",
    classGroup: "EG015520",
    artClassDesc: "Worm screw clamp for hose",
    artClassVersion: 5,
    label: "EC010772: Worm screw clamp for hose"
  },
  {
    classId: "EC010773",
    classGroup: "EG015520",
    artClassDesc: "Hose connector",
    artClassVersion: 2,
    label: "EC010773: Hose connector"
  },
  {
    classId: "EC010774",
    classGroup: "EG015520",
    artClassDesc: "Valve quick coupling stop",
    artClassVersion: 2,
    label: "EC010774: Valve quick coupling stop"
  },
  {
    classId: "EC010775",
    classGroup: "EG015520",
    artClassDesc: "Flanged bush for hose coupling",
    artClassVersion: 1,
    label: "EC010775: Flanged bush for hose coupling"
  },
  {
    classId: "EC010776",
    classGroup: "EG015520",
    artClassDesc: "Akzo stop",
    artClassVersion: 1,
    label: "EC010776: Akzo stop"
  },
  {
    classId: "EC010777",
    classGroup: "EG015520",
    artClassDesc: "Akzo push-on flange",
    artClassVersion: 1,
    label: "EC010777: Akzo push-on flange"
  },
  {
    classId: "EC010778",
    classGroup: "EG015520",
    artClassDesc: "Sealing for Akzo coupling",
    artClassVersion: 1,
    label: "EC010778: Sealing for Akzo coupling"
  },
  {
    classId: "EC010779",
    classGroup: "EG015520",
    artClassDesc: "Compression coupling for hose (3 connections)",
    artClassVersion: 2,
    label: "EC010779: Compression coupling for hose (3 connections)"
  },
  {
    classId: "EC010781",
    classGroup: "EG015520",
    artClassDesc: "Cam and groove coupling/thread",
    artClassVersion: 5,
    label: "EC010781: Cam and groove coupling/thread"
  },
  {
    classId: "EC010782",
    classGroup: "EG015520",
    artClassDesc: "Cam and groove coupling/weld",
    artClassVersion: 3,
    label: "EC010782: Cam and groove coupling/weld"
  },
  {
    classId: "EC010783",
    classGroup: "EG015520",
    artClassDesc: "Cam and groove coupling/flange",
    artClassVersion: 4,
    label: "EC010783: Cam and groove coupling/flange"
  },
  {
    classId: "EC010784",
    classGroup: "EG015520",
    artClassDesc: "Cam and groove plug/cap",
    artClassVersion: 5,
    label: "EC010784: Cam and groove plug/cap"
  },
  {
    classId: "EC010785",
    classGroup: "EG015240",
    artClassDesc: "Grille with absolute filter housing",
    artClassVersion: 1,
    label: "EC010785: Grille with absolute filter housing"
  },
  {
    classId: "EC010786",
    classGroup: "EG015130",
    artClassDesc: "Drain housing",
    artClassVersion: 3,
    label: "EC010786: Drain housing"
  },
  {
    classId: "EC010787",
    classGroup: "EG015630",
    artClassDesc: "Mechanical constant volume controller, round",
    artClassVersion: 2,
    label: "EC010787: Mechanical constant volume controller, round"
  },
  {
    classId: "EC010788",
    classGroup: "EG015630",
    artClassDesc: "Mechanical constant volume controller, rectangular",
    artClassVersion: 2,
    label: "EC010788: Mechanical constant volume controller, rectangular"
  },
  {
    classId: "EC010789",
    classGroup: "EG015630",
    artClassDesc: "Air volume and pressure controller, rectangular",
    artClassVersion: 3,
    label: "EC010789: Air volume and pressure controller, rectangular"
  },
  {
    classId: "EC010790",
    classGroup: "EG015580",
    artClassDesc: "Airtight inspection hatch",
    artClassVersion: 2,
    label: "EC010790: Airtight inspection hatch"
  },
  {
    classId: "EC010791",
    classGroup: "EG015580",
    artClassDesc: "Airtight inspection door",
    artClassVersion: 1,
    label: "EC010791: Airtight inspection door"
  },
  {
    classId: "EC010792",
    classGroup: "EG015520",
    artClassDesc: "Seal for cam and groove coupling",
    artClassVersion: 3,
    label: "EC010792: Seal for cam and groove coupling"
  },
  {
    classId: "EC010793",
    classGroup: "EG015520",
    artClassDesc: "Tanker coupling/hose connector",
    artClassVersion: 3,
    label: "EC010793: Tanker coupling/hose connector"
  },
  {
    classId: "EC010794",
    classGroup: "EG015520",
    artClassDesc: "Tanker coupling/thread",
    artClassVersion: 4,
    label: "EC010794: Tanker coupling/thread"
  },
  {
    classId: "EC010795",
    classGroup: "EG015520",
    artClassDesc: "Tanker plug/cap",
    artClassVersion: 6,
    label: "EC010795: Tanker plug/cap"
  },
  {
    classId: "EC010796",
    classGroup: "EG015520",
    artClassDesc: "Sealing, TW coupling",
    artClassVersion: 4,
    label: "EC010796: Sealing, TW coupling"
  },
  {
    classId: "EC010797",
    classGroup: "EG015520",
    artClassDesc: "Sealing, clamp coupling",
    artClassVersion: 3,
    label: "EC010797: Sealing, clamp coupling"
  },
  {
    classId: "EC010803",
    classGroup: "EG015540",
    artClassDesc: "Hydraulic nipple, 2 connections",
    artClassVersion: 3,
    label: "EC010803: Hydraulic nipple, 2 connections"
  },
  {
    classId: "EC010804",
    classGroup: "EG015540",
    artClassDesc: "Hydraulic threaded T-piece",
    artClassVersion: 2,
    label: "EC010804: Hydraulic threaded T-piece"
  },
  {
    classId: "EC010805",
    classGroup: "EG015540",
    artClassDesc: "Hydraulic threaded cross piece",
    artClassVersion: 2,
    label: "EC010805: Hydraulic threaded cross piece"
  },
  {
    classId: "EC010806",
    classGroup: "EG015540",
    artClassDesc: "Hydraulic threaded plug",
    artClassVersion: 3,
    label: "EC010806: Hydraulic threaded plug"
  },
  {
    classId: "EC010807",
    classGroup: "EG015540",
    artClassDesc: "Hydraulic cutting ring stop",
    artClassVersion: 4,
    label: "EC010807: Hydraulic cutting ring stop"
  },
  {
    classId: "EC010808",
    classGroup: "EG015540",
    artClassDesc: "Hydraulic cutting ring fitting with 2 connections",
    artClassVersion: 5,
    label: "EC010808: Hydraulic cutting ring fitting with 2 connections"
  },
  {
    classId: "EC010809",
    classGroup: "EG015540",
    artClassDesc: "Hydraulic cutting ring T-piece",
    artClassVersion: 4,
    label: "EC010809: Hydraulic cutting ring T-piece"
  },
  {
    classId: "EC010810",
    classGroup: "EG015540",
    artClassDesc: "Hydraulic cutting ring cross-connector",
    artClassVersion: 3,
    label: "EC010810: Hydraulic cutting ring cross-connector"
  },
  {
    classId: "EC010811",
    classGroup: "EG015540",
    artClassDesc: "Hydraulic cutting ring screw coupling",
    artClassVersion: 5,
    label: "EC010811: Hydraulic cutting ring screw coupling"
  },
  {
    classId: "EC010812",
    classGroup: "EG015540",
    artClassDesc: "Hydraulic cutting ring, banjo coupling",
    artClassVersion: 2,
    label: "EC010812: Hydraulic cutting ring, banjo coupling"
  },
  {
    classId: "EC010813",
    classGroup: "EG015540",
    artClassDesc: "Cutting ring",
    artClassVersion: 2,
    label: "EC010813: Cutting ring"
  },
  {
    classId: "EC010814",
    classGroup: "EG015540",
    artClassDesc: "Flare T-piece",
    artClassVersion: 3,
    label: "EC010814: Flare T-piece"
  },
  {
    classId: "EC010815",
    classGroup: "EG015540",
    artClassDesc: "Flare cross-connector",
    artClassVersion: 2,
    label: "EC010815: Flare cross-connector"
  },
  {
    classId: "EC010816",
    classGroup: "EG015540",
    artClassDesc: "Flare/threaded coupling",
    artClassVersion: 3,
    label: "EC010816: Flare/threaded coupling"
  },
  {
    classId: "EC010817",
    classGroup: "EG000049",
    artClassDesc: "Clamping slide, ribbed fixing strap",
    artClassVersion: 4,
    label: "EC010817: Clamping slide, ribbed fixing strap"
  },
  {
    classId: "EC010818",
    classGroup: "EG015520",
    artClassDesc: "Tanker coupling clamp ring",
    artClassVersion: 2,
    label: "EC010818: Tanker coupling clamp ring"
  },
  {
    classId: "EC010819",
    classGroup: "EG015520",
    artClassDesc: "Tanker coupling crown ring",
    artClassVersion: 5,
    label: "EC010819: Tanker coupling crown ring"
  },
  {
    classId: "EC010820",
    classGroup: "EG015520",
    artClassDesc: "Cam and groove handle",
    artClassVersion: 3,
    label: "EC010820: Cam and groove handle"
  },
  {
    classId: "EC010821",
    classGroup: "EG015520",
    artClassDesc: "Cam and groove pin",
    artClassVersion: 4,
    label: "EC010821: Cam and groove pin"
  },
  {
    classId: "EC010822",
    classGroup: "EG015520",
    artClassDesc: "Cam and groove ring",
    artClassVersion: 6,
    label: "EC010822: Cam and groove ring"
  },
  {
    classId: "EC010823",
    classGroup: "EG015570",
    artClassDesc: "Vacuum support ring for compensator",
    artClassVersion: 2,
    label: "EC010823: Vacuum support ring for compensator"
  },
  {
    classId: "EC010824",
    classGroup: "EG015570",
    artClassDesc: "Bolt/washer set for compensator",
    artClassVersion: 3,
    label: "EC010824: Bolt/washer set for compensator"
  },
  {
    classId: "EC010825",
    classGroup: "EG015610",
    artClassDesc: "Syrup drain tap",
    artClassVersion: 4,
    label: "EC010825: Syrup drain tap"
  },
  {
    classId: "EC010826",
    classGroup: "EG015610",
    artClassDesc: "Industrial drain tap",
    artClassVersion: 5,
    label: "EC010826: Industrial drain tap"
  },
  {
    classId: "EC010827",
    classGroup: "EG015110",
    artClassDesc: "Emergency overflow (vertical)",
    artClassVersion: 4,
    label: "EC010827: Emergency overflow (vertical)"
  },
  {
    classId: "EC010828",
    classGroup: "EG017630",
    artClassDesc: "Mounting plate for bath tap",
    artClassVersion: 3,
    label: "EC010828: Mounting plate for bath tap"
  },
  {
    classId: "EC010829",
    classGroup: "EG018410",
    artClassDesc: "Solid vibration isolator hanger",
    artClassVersion: 3,
    label: "EC010829: Solid vibration isolator hanger"
  },
  {
    classId: "EC010831",
    classGroup: "EG017630",
    artClassDesc: "Standing coupling",
    artClassVersion: 2,
    label: "EC010831: Standing coupling"
  },
  {
    classId: "EC010832",
    classGroup: "EG017630",
    artClassDesc: "Tap hole cover plug",
    artClassVersion: 4,
    label: "EC010832: Tap hole cover plug"
  },
  {
    classId: "EC010834",
    classGroup: "EG015580",
    artClassDesc: "Top section",
    artClassVersion: 3,
    label: "EC010834: Top section"
  },
  {
    classId: "EC010836",
    classGroup: "EG015880",
    artClassDesc: "Display assembly set",
    artClassVersion: 2,
    label: "EC010836: Display assembly set"
  },
  {
    classId: "EC010837",
    classGroup: "EG015880",
    artClassDesc: "Cable for control apparatus with connector",
    artClassVersion: 3,
    label: "EC010837: Cable for control apparatus with connector"
  },
  {
    classId: "EC010838",
    classGroup: "EG015510",
    artClassDesc: "Hoist band for hose",
    artClassVersion: 3,
    label: "EC010838: Hoist band for hose"
  },
  {
    classId: "EC010839",
    classGroup: "EG015520",
    artClassDesc: "Tri Clamp coupling",
    artClassVersion: 1,
    label: "EC010839: Tri Clamp coupling"
  },
  {
    classId: "EC010840",
    classGroup: "EG015520",
    artClassDesc: "Sealing for Tri Clamp",
    artClassVersion: 1,
    label: "EC010840: Sealing for Tri Clamp"
  },
  {
    classId: "EC010841",
    classGroup: "EG015520",
    artClassDesc: "Pointed piece, dairy coupling/hose connector",
    artClassVersion: 1,
    label: "EC010841: Pointed piece, dairy coupling/hose connector"
  },
  {
    classId: "EC010842",
    classGroup: "EG015520",
    artClassDesc: "Cam and groove handle/pin/ring",
    artClassVersion: 2,
    label: "EC010842: Cam and groove handle/pin/ring"
  },
  {
    classId: "EC010843",
    classGroup: "EG015520",
    artClassDesc: "Dry-break coupling/flange",
    artClassVersion: 4,
    label: "EC010843: Dry-break coupling/flange"
  },
  {
    classId: "EC010844",
    classGroup: "EG015520",
    artClassDesc: "Tri Clamp clamp",
    artClassVersion: 3,
    label: "EC010844: Tri Clamp clamp"
  },
  {
    classId: "EC010845",
    classGroup: "EG000049",
    artClassDesc: "Fixed-point attachment",
    artClassVersion: 2,
    label: "EC010845: Fixed-point attachment"
  },
  {
    classId: "EC010846",
    classGroup: "EG000049",
    artClassDesc: "Prop",
    artClassVersion: 2,
    label: "EC010846: Prop"
  },
  {
    classId: "EC010847",
    classGroup: "EG019710",
    artClassDesc: "Tile strip for mounting rail",
    artClassVersion: 4,
    label: "EC010847: Tile strip for mounting rail"
  },
  {
    classId: "EC010848",
    classGroup: "EG017550",
    artClassDesc: "Bath outlet-inlet combination",
    artClassVersion: 5,
    label: "EC010848: Bath outlet-inlet combination"
  },
  {
    classId: "EC010849",
    classGroup: "EG017910",
    artClassDesc: "Sound insulation set for wastewater reception devices",
    artClassVersion: 2,
    label: "EC010849: Sound insulation set for wastewater reception devices"
  },
  {
    classId: "EC010850",
    classGroup: "EG017550",
    artClassDesc: "Drain booster",
    artClassVersion: 3,
    label: "EC010850: Drain booster"
  },
  {
    classId: "EC010851",
    classGroup: "EG015530",
    artClassDesc: "Drainage-/infiltration pipe",
    artClassVersion: 5,
    label: "EC010851: Drainage-/infiltration pipe"
  },
  {
    classId: "EC010852",
    classGroup: "EG015620",
    artClassDesc: "Spindle extension",
    artClassVersion: 2,
    label: "EC010852: Spindle extension"
  },
  {
    classId: "EC010853",
    classGroup: "EG015240",
    artClassDesc: "Tube grille",
    artClassVersion: 2,
    label: "EC010853: Tube grille"
  },
  {
    classId: "EC010855",
    classGroup: "EG015630",
    artClassDesc: "Air volume measuring station, round",
    artClassVersion: 1,
    label: "EC010855: Air volume measuring station, round"
  },
  {
    classId: "EC010856",
    classGroup: "EG015630",
    artClassDesc: "Dual-channel air mixing unit",
    artClassVersion: 2,
    label: "EC010856: Dual-channel air mixing unit"
  },
  {
    classId: "EC010857",
    classGroup: "EG015630",
    artClassDesc: "Air volume measuring station, rectangular",
    artClassVersion: 1,
    label: "EC010857: Air volume measuring station, rectangular"
  },
  {
    classId: "EC010858",
    classGroup: "EG015710",
    artClassDesc: "Accessories/spare parts for radiator",
    artClassVersion: 5,
    label: "EC010858: Accessories/spare parts for radiator"
  },
  {
    classId: "EC010859",
    classGroup: "EG015510",
    artClassDesc: "Flexible aluminium tube",
    artClassVersion: 4,
    label: "EC010859: Flexible aluminium tube"
  },
  {
    classId: "EC010860",
    classGroup: "EG015260",
    artClassDesc: "Wastewater pump unit",
    artClassVersion: 8,
    label: "EC010860: Wastewater pump unit"
  },
  {
    classId: "EC010861",
    classGroup: "EG015880",
    artClassDesc: "Heat-conducting paste",
    artClassVersion: 2,
    label: "EC010861: Heat-conducting paste"
  },
  {
    classId: "EC010862",
    classGroup: "EG017610",
    artClassDesc: "Bath mixing tap",
    artClassVersion: 6,
    label: "EC010862: Bath mixing tap"
  },
  {
    classId: "EC010864",
    classGroup: "EG017120",
    artClassDesc: "Surface-mounted flushing reservoir",
    artClassVersion: 6,
    label: "EC010864: Surface-mounted flushing reservoir"
  },
  {
    classId: "EC010865",
    classGroup: "EG015540",
    artClassDesc: "End lid for sleeveless cast iron",
    artClassVersion: 4,
    label: "EC010865: End lid for sleeveless cast iron"
  },
  {
    classId: "EC010866",
    classGroup: "EG015610",
    artClassDesc: "Radiator foot valve",
    artClassVersion: 7,
    label: "EC010866: Radiator foot valve"
  },
  {
    classId: "EC010867",
    classGroup: "EG000049",
    artClassDesc: "Passing plate for floor heating",
    artClassVersion: 4,
    label: "EC010867: Passing plate for floor heating"
  },
  {
    classId: "EC010868",
    classGroup: "EG015210",
    artClassDesc: "Gas-fired water heater",
    artClassVersion: 6,
    label: "EC010868: Gas-fired water heater"
  },
  {
    classId: "EC010869",
    classGroup: "EG015210",
    artClassDesc: "Hot tap water heater, indirectly fired",
    artClassVersion: 3,
    label: "EC010869: Hot tap water heater, indirectly fired"
  },
  {
    classId: "EC010870",
    classGroup: "EG015560",
    artClassDesc: "Concentric flue gas/air supply form piece, 2 connections",
    artClassVersion: 6,
    label: "EC010870: Concentric flue gas/air supply form piece, 2 connections"
  },
  {
    classId: "EC010871",
    classGroup: "EG015210",
    artClassDesc: "Boiler for preburner",
    artClassVersion: 4,
    label: "EC010871: Boiler for preburner"
  },
  {
    classId: "EC010872",
    classGroup: "EG017210",
    artClassDesc: "Shower seat",
    artClassVersion: 5,
    label: "EC010872: Shower seat"
  },
  {
    classId: "EC010875",
    classGroup: "EG017610",
    artClassDesc: "Toilet tap",
    artClassVersion: 6,
    label: "EC010875: Toilet tap"
  },
  {
    classId: "EC010876",
    classGroup: "EG015560",
    artClassDesc: "Heat exchanger roof duct",
    artClassVersion: 4,
    label: "EC010876: Heat exchanger roof duct"
  },
  {
    classId: "EC010877",
    classGroup: "EG015560",
    artClassDesc: "Outlet nozzle, rectangular air duct, 45°",
    artClassVersion: 3,
    label: "EC010877: Outlet nozzle, rectangular air duct, 45°"
  },
  {
    classId: "EC010878",
    classGroup: "EG015640",
    artClassDesc: "Valve built-in installation set",
    artClassVersion: 4,
    label: "EC010878: Valve built-in installation set"
  },
  {
    classId: "EC010879",
    classGroup: "EG020500",
    artClassDesc: "Insulation blanket/-board",
    artClassVersion: 8,
    label: "EC010879: Insulation blanket/-board"
  },
  {
    classId: "EC010880",
    classGroup: "EG017110",
    artClassDesc: "High/low bath",
    artClassVersion: 4,
    label: "EC010880: High/low bath"
  },
  {
    classId: "EC010881",
    classGroup: "EG000049",
    artClassDesc: "Suspension element, carrying pipe section",
    artClassVersion: 4,
    label: "EC010881: Suspension element, carrying pipe section"
  },
  {
    classId: "EC010882",
    classGroup: "EG015530",
    artClassDesc: "Plastic pipe smooth",
    artClassVersion: 7,
    label: "EC010882: Plastic pipe smooth"
  },
  {
    classId: "EC010883",
    classGroup: "EG015260",
    artClassDesc: "Wall mounted fan (built-up)",
    artClassVersion: 5,
    label: "EC010883: Wall mounted fan (built-up)"
  },
  {
    classId: "EC010884",
    classGroup: "EG017210",
    artClassDesc: "Washing-glove holder",
    artClassVersion: 5,
    label: "EC010884: Washing-glove holder"
  },
  {
    classId: "EC010885",
    classGroup: "EG015110",
    artClassDesc: "Outlet funnel",
    artClassVersion: 4,
    label: "EC010885: Outlet funnel"
  },
  {
    classId: "EC010886",
    classGroup: "EG015560",
    artClassDesc: "Single-walled flue gas attachment, 1 connection",
    artClassVersion: 7,
    label: "EC010886: Single-walled flue gas attachment, 1 connection"
  },
  {
    classId: "EC010887",
    classGroup: "EG000026",
    artClassDesc: "Accessories/spare parts for pressure difference sensor",
    artClassVersion: 5,
    label: "EC010887: Accessories/spare parts for pressure difference sensor"
  },
  {
    classId: "EC010888",
    classGroup: "EG017610",
    artClassDesc: "Bidet mixing tap",
    artClassVersion: 7,
    label: "EC010888: Bidet mixing tap"
  },
  {
    classId: "EC010890",
    classGroup: "EG017140",
    artClassDesc: "Pneumatic lifting system flushing reservoir",
    artClassVersion: 2,
    label: "EC010890: Pneumatic lifting system flushing reservoir"
  },
  {
    classId: "EC010891",
    classGroup: "EG019490",
    artClassDesc: "Radiator mounting set",
    artClassVersion: 5,
    label: "EC010891: Radiator mounting set"
  },
  {
    classId: "EC010892",
    classGroup: "EG017920",
    artClassDesc: "Shower wall walk-in",
    artClassVersion: 5,
    label: "EC010892: Shower wall walk-in"
  },
  {
    classId: "EC010894",
    classGroup: "EG015610",
    artClassDesc: "Accessories/spare parts for sprinklers",
    artClassVersion: 6,
    label: "EC010894: Accessories/spare parts for sprinklers"
  },
  {
    classId: "EC010895",
    classGroup: "EG019710",
    artClassDesc: "Embedding rail",
    artClassVersion: 6,
    label: "EC010895: Embedding rail"
  },
  {
    classId: "EC010896",
    classGroup: "EG017640",
    artClassDesc: "Riser for hose on shower tap",
    artClassVersion: 2,
    label: "EC010896: Riser for hose on shower tap"
  },
  {
    classId: "EC010899",
    classGroup: "EG015550",
    artClassDesc: "Concentric flue gas/air supply pipe",
    artClassVersion: 7,
    label: "EC010899: Concentric flue gas/air supply pipe"
  },
  {
    classId: "EC010901",
    classGroup: "EG017210",
    artClassDesc: "Wall closet extension",
    artClassVersion: 3,
    label: "EC010901: Wall closet extension"
  },
  {
    classId: "EC010903",
    classGroup: "EG017210",
    artClassDesc: "Glass for glass holder",
    artClassVersion: 3,
    label: "EC010903: Glass for glass holder"
  },
  {
    classId: "EC010904",
    classGroup: "EG000049",
    artClassDesc: "Suspension eye bracket",
    artClassVersion: 4,
    label: "EC010904: Suspension eye bracket"
  },
  {
    classId: "EC010905",
    classGroup: "EG017640",
    artClassDesc: "Shower connecting piece",
    artClassVersion: 6,
    label: "EC010905: Shower connecting piece"
  },
  {
    classId: "EC010906",
    classGroup: "EG015560",
    artClassDesc: "Duct lead-through plate",
    artClassVersion: 4,
    label: "EC010906: Duct lead-through plate"
  },
  {
    classId: "EC010907",
    classGroup: "EG017210",
    artClassDesc: "Recess for tissue holder",
    artClassVersion: 2,
    label: "EC010907: Recess for tissue holder"
  },
  {
    classId: "EC010908",
    classGroup: "EG015810",
    artClassDesc: "Weight-loaded pressure relief valve",
    artClassVersion: 7,
    label: "EC010908: Weight-loaded pressure relief valve"
  },
  {
    classId: "EC010909",
    classGroup: "EG000026",
    artClassDesc: "Immersion temperature sensor, passive",
    artClassVersion: 6,
    label: "EC010909: Immersion temperature sensor, passive"
  },
  {
    classId: "EC010910",
    classGroup: "EG015610",
    artClassDesc: "Butterfly valve",
    artClassVersion: 7,
    label: "EC010910: Butterfly valve"
  },
  {
    classId: "EC010911",
    classGroup: "EG015240",
    artClassDesc: "Anemostat, round front",
    artClassVersion: 2,
    label: "EC010911: Anemostat, round front"
  },
  {
    classId: "EC010913",
    classGroup: "EG015710",
    artClassDesc: "Convector pit empty",
    artClassVersion: 3,
    label: "EC010913: Convector pit empty"
  },
  {
    classId: "EC010914",
    classGroup: "EG017210",
    artClassDesc: "Counterplate, adapted system",
    artClassVersion: 2,
    label: "EC010914: Counterplate, adapted system"
  },
  {
    classId: "EC010915",
    classGroup: "EG015170",
    artClassDesc: "Adapter for air handling unit",
    artClassVersion: 2,
    label: "EC010915: Adapter for air handling unit"
  },
  {
    classId: "EC010917",
    classGroup: "EG015610",
    artClassDesc: "Plug valve",
    artClassVersion: 7,
    label: "EC010917: Plug valve"
  },
  {
    classId: "EC010918",
    classGroup: "EG015560",
    artClassDesc: "Sleeve, embedding duct",
    artClassVersion: 3,
    label: "EC010918: Sleeve, embedding duct"
  },
  {
    classId: "EC010919",
    classGroup: "EG015560",
    artClassDesc: "Bend, oval air duct",
    artClassVersion: 6,
    label: "EC010919: Bend, oval air duct"
  },
  {
    classId: "EC010920",
    classGroup: "EG000026",
    artClassDesc: "Light intensity sensor",
    artClassVersion: 7,
    label: "EC010920: Light intensity sensor"
  },
  {
    classId: "EC010921",
    classGroup: "EG000049",
    artClassDesc: "Air duct attachment",
    artClassVersion: 6,
    label: "EC010921: Air duct attachment"
  },
  {
    classId: "EC010923",
    classGroup: "EG015560",
    artClassDesc: "Heat exchanger connecting box, flat roof",
    artClassVersion: 2,
    label: "EC010923: Heat exchanger connecting box, flat roof"
  },
  {
    classId: "EC010924",
    classGroup: "EG015560",
    artClassDesc: "Transition piece rectangular/round air duct",
    artClassVersion: 4,
    label: "EC010924: Transition piece rectangular/round air duct"
  },
  {
    classId: "EC010925",
    classGroup: "EG015240",
    artClassDesc: "Cover for ventilation valve",
    artClassVersion: 2,
    label: "EC010925: Cover for ventilation valve"
  },
  {
    classId: "EC010926",
    classGroup: "EG000049",
    artClassDesc: "Base plate for bracket attachment",
    artClassVersion: 4,
    label: "EC010926: Base plate for bracket attachment"
  },
  {
    classId: "EC010927",
    classGroup: "EG015840",
    artClassDesc: "Room thermostat",
    artClassVersion: 7,
    label: "EC010927: Room thermostat"
  },
  {
    classId: "EC010928",
    classGroup: "EG019710",
    artClassDesc: "Side-corner joint for mounting rail",
    artClassVersion: 4,
    label: "EC010928: Side-corner joint for mounting rail"
  },
  {
    classId: "EC010929",
    classGroup: "EG017130",
    artClassDesc: "Double wall/built-in element armrest",
    artClassVersion: 6,
    label: "EC010929: Double wall/built-in element armrest"
  },
  {
    classId: "EC010930",
    classGroup: "EG015260",
    artClassDesc: "Wall mounted fan (built-in)",
    artClassVersion: 4,
    label: "EC010930: Wall mounted fan (built-in)"
  },
  {
    classId: "EC010933",
    classGroup: "EG000049",
    artClassDesc: "Rail console",
    artClassVersion: 4,
    label: "EC010933: Rail console"
  },
  {
    classId: "EC010935",
    classGroup: "EG015560",
    artClassDesc: "Reducer air duct",
    artClassVersion: 7,
    label: "EC010935: Reducer air duct"
  },
  {
    classId: "EC010937",
    classGroup: "EG015560",
    artClassDesc: "Sliding piece, embedding duct",
    artClassVersion: 3,
    label: "EC010937: Sliding piece, embedding duct"
  },
  {
    classId: "EC010938",
    classGroup: "EG017610",
    artClassDesc: "Self-closing tap/valve (mechanical)",
    artClassVersion: 6,
    label: "EC010938: Self-closing tap/valve (mechanical)"
  },
  {
    classId: "EC010939",
    classGroup: "EG019490",
    artClassDesc: "Double radiator wall console",
    artClassVersion: 5,
    label: "EC010939: Double radiator wall console"
  },
  {
    classId: "EC010940",
    classGroup: "EG015610",
    artClassDesc: "Storage sprinkler",
    artClassVersion: 5,
    label: "EC010940: Storage sprinkler"
  },
  {
    classId: "EC010941",
    classGroup: "EG000049",
    artClassDesc: "Fixed-point corner support",
    artClassVersion: 5,
    label: "EC010941: Fixed-point corner support"
  },
  {
    classId: "EC010942",
    classGroup: "EG017610",
    artClassDesc: "Automatic draining system for shower hose",
    artClassVersion: 4,
    label: "EC010942: Automatic draining system for shower hose"
  },
  {
    classId: "EC010943",
    classGroup: "EG015570",
    artClassDesc: "Plastic expansion sleeve",
    artClassVersion: 5,
    label: "EC010943: Plastic expansion sleeve"
  },
  {
    classId: "EC010944",
    classGroup: "EG000026",
    artClassDesc: "Air flow sensor",
    artClassVersion: 5,
    label: "EC010944: Air flow sensor"
  },
  {
    classId: "EC010946",
    classGroup: "EG015810",
    artClassDesc: "Thermostatic steam trap",
    artClassVersion: 7,
    label: "EC010946: Thermostatic steam trap"
  },
  {
    classId: "EC010947",
    classGroup: "EG017210",
    artClassDesc: "Trap protector, adapted sanitary fittings",
    artClassVersion: 3,
    label: "EC010947: Trap protector, adapted sanitary fittings"
  },
  {
    classId: "EC010948",
    classGroup: "EG017210",
    artClassDesc: "Backrest",
    artClassVersion: 5,
    label: "EC010948: Backrest"
  },
  {
    classId: "EC010950",
    classGroup: "EG015530",
    artClassDesc: "Stainless steel pipe",
    artClassVersion: 6,
    label: "EC010950: Stainless steel pipe"
  },
  {
    classId: "EC010951",
    classGroup: "EG017970",
    artClassDesc: "Profiled tube clip",
    artClassVersion: 3,
    label: "EC010951: Profiled tube clip"
  },
  {
    classId: "EC010954",
    classGroup: "EG015560",
    artClassDesc: "Single-walled flue gas attachment, 2 connections",
    artClassVersion: 9,
    label: "EC010954: Single-walled flue gas attachment, 2 connections"
  },
  {
    classId: "EC010955",
    classGroup: "EG015210",
    artClassDesc: "Combined boiler for detachable burner",
    artClassVersion: 6,
    label: "EC010955: Combined boiler for detachable burner"
  },
  {
    classId: "EC010957",
    classGroup: "EG015850",
    artClassDesc: "Free programmable controller extension",
    artClassVersion: 4,
    label: "EC010957: Free programmable controller extension"
  },
  {
    classId: "EC010958",
    classGroup: "EG015260",
    artClassDesc: "Membrane pressure expansion vessel",
    artClassVersion: 5,
    label: "EC010958: Membrane pressure expansion vessel"
  },
  {
    classId: "EC010959",
    classGroup: "EG015810",
    artClassDesc: "Vacuum valve (pressurized system)",
    artClassVersion: 8,
    label: "EC010959: Vacuum valve (pressurized system)"
  },
  {
    classId: "EC010961",
    classGroup: "EG015850",
    artClassDesc: "Application for freely programmable controller",
    artClassVersion: 3,
    label: "EC010961: Application for freely programmable controller"
  },
  {
    classId: "EC010962",
    classGroup: "EG017920",
    artClassDesc: "Shower door",
    artClassVersion: 6,
    label: "EC010962: Shower door"
  },
  {
    classId: "EC010963",
    classGroup: "EG000049",
    artClassDesc: "Foundation hook",
    artClassVersion: 5,
    label: "EC010963: Foundation hook"
  },
  {
    classId: "EC010964",
    classGroup: "EG017550",
    artClassDesc: "Siphon tube",
    artClassVersion: 5,
    label: "EC010964: Siphon tube"
  },
  {
    classId: "EC010965",
    classGroup: "EG015920",
    artClassDesc: "Transport wheel fork",
    artClassVersion: 5,
    label: "EC010965: Transport wheel fork"
  },
  {
    classId: "EC010967",
    classGroup: "EG017970",
    artClassDesc: "Tap fastening element for construction wall",
    artClassVersion: 3,
    label: "EC010967: Tap fastening element for construction wall"
  },
  {
    classId: "EC010968",
    classGroup: "EG015620",
    artClassDesc: "Programming unit for servomotor/controller",
    artClassVersion: 1,
    label: "EC010968: Programming unit for servomotor/controller"
  },
  {
    classId: "EC010969",
    classGroup: "EG000049",
    artClassDesc: "Hook-and-loop fastener",
    artClassVersion: 7,
    label: "EC010969: Hook-and-loop fastener"
  },
  {
    classId: "EC010971",
    classGroup: "EG015210",
    artClassDesc: "Accessories/spare parts for hot tap water heater",
    artClassVersion: 5,
    label: "EC010971: Accessories/spare parts for hot tap water heater"
  },
  {
    classId: "EC010972",
    classGroup: "EG017210",
    artClassDesc: "Recess for toilet brush fittings",
    artClassVersion: 2,
    label: "EC010972: Recess for toilet brush fittings"
  },
  {
    classId: "EC010973",
    classGroup: "EG017210",
    artClassDesc: "Height adjustment system shower seat",
    artClassVersion: 3,
    label: "EC010973: Height adjustment system shower seat"
  },
  {
    classId: "EC010974",
    classGroup: "EG000049",
    artClassDesc: "Cap bracket",
    artClassVersion: 5,
    label: "EC010974: Cap bracket"
  },
  {
    classId: "EC010977",
    classGroup: "EG015560",
    artClassDesc: "Chimney double wall bracket",
    artClassVersion: 2,
    label: "EC010977: Chimney double wall bracket"
  },
  {
    classId: "EC010979",
    classGroup: "EG017130",
    artClassDesc: "Double wall/built-in element urinal without flushing apparatus",
    artClassVersion: 3,
    label: "EC010979: Double wall/built-in element urinal without flushing apparatus"
  },
  {
    classId: "EC010980",
    classGroup: "EG015260",
    artClassDesc: "Built-in circulation pump",
    artClassVersion: 7,
    label: "EC010980: Built-in circulation pump"
  },
  {
    classId: "EC010982",
    classGroup: "EG015810",
    artClassDesc: "Bell float-controlled trap",
    artClassVersion: 7,
    label: "EC010982: Bell float-controlled trap"
  },
  {
    classId: "EC010983",
    classGroup: "EG015560",
    artClassDesc: "Flue gas/air supply collector",
    artClassVersion: 7,
    label: "EC010983: Flue gas/air supply collector"
  },
  {
    classId: "EC010984",
    classGroup: "EG017210",
    artClassDesc: "Washbasin bracket",
    artClassVersion: 4,
    label: "EC010984: Washbasin bracket"
  },
  {
    classId: "EC010985",
    classGroup: "EG015610",
    artClassDesc: "Drain valve (radiator and tap water)",
    artClassVersion: 7,
    label: "EC010985: Drain valve (radiator and tap water)"
  },
  {
    classId: "EC010989",
    classGroup: "EG015510",
    artClassDesc: "Plastic twin hose",
    artClassVersion: 6,
    label: "EC010989: Plastic twin hose"
  },
  {
    classId: "EC010990",
    classGroup: "EG015580",
    artClassDesc: "Vent cross cowl",
    artClassVersion: 4,
    label: "EC010990: Vent cross cowl"
  },
  {
    classId: "EC010991",
    classGroup: "EG015560",
    artClassDesc: "Double-walled flue gas attachment, 1 connection",
    artClassVersion: 7,
    label: "EC010991: Double-walled flue gas attachment, 1 connection"
  },
  {
    classId: "EC010992",
    classGroup: "EG015560",
    artClassDesc: "Chimney spacer construction",
    artClassVersion: 2,
    label: "EC010992: Chimney spacer construction"
  },
  {
    classId: "EC010993",
    classGroup: "EG015560",
    artClassDesc: "Chimney sound absorber",
    artClassVersion: 4,
    label: "EC010993: Chimney sound absorber"
  },
  {
    classId: "EC010994",
    classGroup: "EG015260",
    artClassDesc: "Centrifugal fan",
    artClassVersion: 4,
    label: "EC010994: Centrifugal fan"
  },
  {
    classId: "EC010995",
    classGroup: "EG000049",
    artClassDesc: "T-model beam clamp",
    artClassVersion: 4,
    label: "EC010995: T-model beam clamp"
  },
  {
    classId: "EC010996",
    classGroup: "EG000049",
    artClassDesc: "Connecting bracket for sling",
    artClassVersion: 3,
    label: "EC010996: Connecting bracket for sling"
  },
  {
    classId: "EC010997",
    classGroup: "EG017210",
    artClassDesc: "Shelf carrier",
    artClassVersion: 4,
    label: "EC010997: Shelf carrier"
  },
  {
    classId: "EC010999",
    classGroup: "EG015210",
    artClassDesc: "Gas preburner",
    artClassVersion: 5,
    label: "EC010999: Gas preburner"
  },
  {
    classId: "EC011000",
    classGroup: "EG017970",
    artClassDesc: "System profile sanitary",
    artClassVersion: 3,
    label: "EC011000: System profile sanitary"
  },
  {
    classId: "EC011001",
    classGroup: "EG017920",
    artClassDesc: "Urinal partition",
    artClassVersion: 4,
    label: "EC011001: Urinal partition"
  },
  {
    classId: "EC011003",
    classGroup: "EG017210",
    artClassDesc: "Wall grab handle/rail",
    artClassVersion: 8,
    label: "EC011003: Wall grab handle/rail"
  },
  {
    classId: "EC011004",
    classGroup: "EG017210",
    artClassDesc: "Recess for shelf holder",
    artClassVersion: 2,
    label: "EC011004: Recess for shelf holder"
  },
  {
    classId: "EC011005",
    classGroup: "EG000049",
    artClassDesc: "Stud for brackets",
    artClassVersion: 5,
    label: "EC011005: Stud for brackets"
  },
  {
    classId: "EC011007",
    classGroup: "EG015910",
    artClassDesc: "Spiral-wound flange gasket (not normed)",
    artClassVersion: 5,
    label: "EC011007: Spiral-wound flange gasket (not normed)"
  },
  {
    classId: "EC011008",
    classGroup: "EG015910",
    artClassDesc: "Spiral-wound flange gasket (ANSI)",
    artClassVersion: 6,
    label: "EC011008: Spiral-wound flange gasket (ANSI)"
  },
  {
    classId: "EC011009",
    classGroup: "EG015260",
    artClassDesc: "Faeces pump with standard motor",
    artClassVersion: 7,
    label: "EC011009: Faeces pump with standard motor"
  },
  {
    classId: "EC011010",
    classGroup: "EG015540",
    artClassDesc: "Radiator coupling",
    artClassVersion: 6,
    label: "EC011010: Radiator coupling"
  },
  {
    classId: "EC011011",
    classGroup: "EG015260",
    artClassDesc: "Ventilator for mounting on the roof",
    artClassVersion: 7,
    label: "EC011011: Ventilator for mounting on the roof"
  },
  {
    classId: "EC011012",
    classGroup: "EG015260",
    artClassDesc: "Circulating fan",
    artClassVersion: 6,
    label: "EC011012: Circulating fan"
  },
  {
    classId: "EC011013",
    classGroup: "EG015260",
    artClassDesc: "Duct fan",
    artClassVersion: 6,
    label: "EC011013: Duct fan"
  },
  {
    classId: "EC011014",
    classGroup: "EG015540",
    artClassDesc: "Broaching saddle",
    artClassVersion: 6,
    label: "EC011014: Broaching saddle"
  },
  {
    classId: "EC011015",
    classGroup: "EG015610",
    artClassDesc: "Gas valve",
    artClassVersion: 6,
    label: "EC011015: Gas valve"
  },
  {
    classId: "EC011016",
    classGroup: "EG015560",
    artClassDesc: "Push-on profile, rectangular air duct",
    artClassVersion: 2,
    label: "EC011016: Push-on profile, rectangular air duct"
  },
  {
    classId: "EC011017",
    classGroup: "EG015630",
    artClassDesc: "One-bladed air control valve, rectangular",
    artClassVersion: 4,
    label: "EC011017: One-bladed air control valve, rectangular"
  },
  {
    classId: "EC011018",
    classGroup: "EG015630",
    artClassDesc: "Back-draught shutter for roof mounted fan",
    artClassVersion: 4,
    label: "EC011018: Back-draught shutter for roof mounted fan"
  },
  {
    classId: "EC011019",
    classGroup: "EG018310",
    artClassDesc: "Sound absorber round air duct",
    artClassVersion: 7,
    label: "EC011019: Sound absorber round air duct"
  },
  {
    classId: "EC011020",
    classGroup: "EG015510",
    artClassDesc: "Plastic hose, uninsulated with spiral",
    artClassVersion: 9,
    label: "EC011020: Plastic hose, uninsulated with spiral"
  },
  {
    classId: "EC011021",
    classGroup: "EG015520",
    artClassDesc: "Storz coupling/hose connector",
    artClassVersion: 4,
    label: "EC011021: Storz coupling/hose connector"
  },
  {
    classId: "EC011022",
    classGroup: "EG015710",
    artClassDesc: "Panel radiator",
    artClassVersion: 6,
    label: "EC011022: Panel radiator"
  },
  {
    classId: "EC011023",
    classGroup: "EG015710",
    artClassDesc: "Tube radiator",
    artClassVersion: 6,
    label: "EC011023: Tube radiator"
  },
  {
    classId: "EC011024",
    classGroup: "EG015710",
    artClassDesc: "Convector pit complete",
    artClassVersion: 5,
    label: "EC011024: Convector pit complete"
  },
  {
    classId: "EC011025",
    classGroup: "EG017210",
    artClassDesc: "Armrest toilet support",
    artClassVersion: 5,
    label: "EC011025: Armrest toilet support"
  },
  {
    classId: "EC011026",
    classGroup: "EG017210",
    artClassDesc: "Auxiliary support leg set disabled sanitary",
    artClassVersion: 4,
    label: "EC011026: Auxiliary support leg set disabled sanitary"
  },
  {
    classId: "EC011027",
    classGroup: "EG017210",
    artClassDesc: "Shower stool",
    artClassVersion: 5,
    label: "EC011027: Shower stool"
  },
  {
    classId: "EC011028",
    classGroup: "EG017210",
    artClassDesc: "Grab handle/rail disabled sanitary",
    artClassVersion: 7,
    label: "EC011028: Grab handle/rail disabled sanitary"
  },
  {
    classId: "EC011029",
    classGroup: "EG015580",
    artClassDesc: "Spherical wire grid cover",
    artClassVersion: 4,
    label: "EC011029: Spherical wire grid cover"
  },
  {
    classId: "EC011030",
    classGroup: "EG015210",
    artClassDesc: "Air heater, indirectly fired",
    artClassVersion: 4,
    label: "EC011030: Air heater, indirectly fired"
  },
  {
    classId: "EC011031",
    classGroup: "EG017210",
    artClassDesc: "Swinging/ceiling support",
    artClassVersion: 4,
    label: "EC011031: Swinging/ceiling support"
  },
  {
    classId: "EC011032",
    classGroup: "EG017210",
    artClassDesc: "Floor stand disabled sanitary",
    artClassVersion: 4,
    label: "EC011032: Floor stand disabled sanitary"
  },
  {
    classId: "EC011033",
    classGroup: "EG019890",
    artClassDesc: "Refrigerant",
    artClassVersion: 5,
    label: "EC011033: Refrigerant"
  },
  {
    classId: "EC011034",
    classGroup: "EG015710",
    artClassDesc: "Fan coil unit",
    artClassVersion: 7,
    label: "EC011034: Fan coil unit"
  },
  {
    classId: "EC011035",
    classGroup: "EG015710",
    artClassDesc: "Electrical accessories, ventilator convector",
    artClassVersion: 4,
    label: "EC011035: Electrical accessories, ventilator convector"
  },
  {
    classId: "EC011036",
    classGroup: "EG017210",
    artClassDesc: "Shower toilet seat (electronic)",
    artClassVersion: 4,
    label: "EC011036: Shower toilet seat (electronic)"
  },
  {
    classId: "EC011037",
    classGroup: "EG017210",
    artClassDesc: "Height adjustment system toilet support",
    artClassVersion: 3,
    label: "EC011037: Height adjustment system toilet support"
  },
  {
    classId: "EC011038",
    classGroup: "EG017210",
    artClassDesc: "Accessories/spare parts for bath lift",
    artClassVersion: 4,
    label: "EC011038: Accessories/spare parts for bath lift"
  },
  {
    classId: "EC011039",
    classGroup: "EG017210",
    artClassDesc: "Accessories/spare parts for toilet support/handle",
    artClassVersion: 4,
    label: "EC011039: Accessories/spare parts for toilet support/handle"
  },
  {
    classId: "EC011040",
    classGroup: "EG015260",
    artClassDesc: "Mechanical accessories/spare parts for pumps",
    artClassVersion: 6,
    label: "EC011040: Mechanical accessories/spare parts for pumps"
  },
  {
    classId: "EC011041",
    classGroup: "EG015540",
    artClassDesc: "Compression ring",
    artClassVersion: 6,
    label: "EC011041: Compression ring"
  },
  {
    classId: "EC011042",
    classGroup: "EG015540",
    artClassDesc: "Compression ring set",
    artClassVersion: 5,
    label: "EC011042: Compression ring set"
  },
  {
    classId: "EC011043",
    classGroup: "EG000044",
    artClassDesc: "Connecting nipple pressure gauge",
    artClassVersion: 6,
    label: "EC011043: Connecting nipple pressure gauge"
  },
  {
    classId: "EC011044",
    classGroup: "EG000061",
    artClassDesc: "Earth clamp electric welding",
    artClassVersion: 4,
    label: "EC011044: Earth clamp electric welding"
  },
  {
    classId: "EC011045",
    classGroup: "EG000044",
    artClassDesc: "Battery acid tester",
    artClassVersion: 5,
    label: "EC011045: Battery acid tester"
  },
  {
    classId: "EC011047",
    classGroup: "EG000044",
    artClassDesc: "Compression trial pump",
    artClassVersion: 6,
    label: "EC011047: Compression trial pump"
  },
  {
    classId: "EC011048",
    classGroup: "EG000044",
    artClassDesc: "Compression trial plug",
    artClassVersion: 3,
    label: "EC011048: Compression trial plug"
  },
  {
    classId: "EC011049",
    classGroup: "EG000044",
    artClassDesc: "Terminal plug",
    artClassVersion: 4,
    label: "EC011049: Terminal plug"
  },
  {
    classId: "EC011050",
    classGroup: "EG000044",
    artClassDesc: "Antifreeze meter",
    artClassVersion: 5,
    label: "EC011050: Antifreeze meter"
  },
  {
    classId: "EC011052",
    classGroup: "EG010220",
    artClassDesc: "Broom",
    artClassVersion: 5,
    label: "EC011052: Broom"
  },
  {
    classId: "EC011053",
    classGroup: "EG010220",
    artClassDesc: "Stick",
    artClassVersion: 3,
    label: "EC011053: Stick"
  },
  {
    classId: "EC011054",
    classGroup: "EG010340",
    artClassDesc: "Fire blanket",
    artClassVersion: 3,
    label: "EC011054: Fire blanket"
  },
  {
    classId: "EC011055",
    classGroup: "EG010340",
    artClassDesc: "Fire extinguisher",
    artClassVersion: 6,
    label: "EC011055: Fire extinguisher"
  },
  {
    classId: "EC011056",
    classGroup: "EG000061",
    artClassDesc: "Nozzle cleaner set",
    artClassVersion: 6,
    label: "EC011056: Nozzle cleaner set"
  },
  {
    classId: "EC011057",
    classGroup: "EG000061",
    artClassDesc: "Burner handle",
    artClassVersion: 4,
    label: "EC011057: Burner handle"
  },
  {
    classId: "EC011058",
    classGroup: "EG000061",
    artClassDesc: "Burner head",
    artClassVersion: 4,
    label: "EC011058: Burner head"
  },
  {
    classId: "EC011059",
    classGroup: "EG000061",
    artClassDesc: "Burner extension piece",
    artClassVersion: 3,
    label: "EC011059: Burner extension piece"
  },
  {
    classId: "EC011060",
    classGroup: "EG000050",
    artClassDesc: "Bending block",
    artClassVersion: 3,
    label: "EC011060: Bending block"
  },
  {
    classId: "EC011061",
    classGroup: "EG000050",
    artClassDesc: "Pipe bender",
    artClassVersion: 5,
    label: "EC011061: Pipe bender"
  },
  {
    classId: "EC011062",
    classGroup: "EG000050",
    artClassDesc: "Press pliers for push fittings",
    artClassVersion: 5,
    label: "EC011062: Press pliers for push fittings"
  },
  {
    classId: "EC011065",
    classGroup: "EG010320",
    artClassDesc: "Lifting jack",
    artClassVersion: 5,
    label: "EC011065: Lifting jack"
  },
  {
    classId: "EC011067",
    classGroup: "EG000050",
    artClassDesc: "Die head",
    artClassVersion: 7,
    label: "EC011067: Die head"
  },
  {
    classId: "EC011068",
    classGroup: "EG000050",
    artClassDesc: "Thread cutter",
    artClassVersion: 7,
    label: "EC011068: Thread cutter"
  },
  {
    classId: "EC011069",
    classGroup: "EG000044",
    artClassDesc: "Differential pressure meter",
    artClassVersion: 6,
    label: "EC011069: Differential pressure meter"
  },
  {
    classId: "EC011071",
    classGroup: "EG000061",
    artClassDesc: "Electrode holder",
    artClassVersion: 2,
    label: "EC011071: Electrode holder"
  },
  {
    classId: "EC011072",
    classGroup: "EG010220",
    artClassDesc: "Bucket",
    artClassVersion: 4,
    label: "EC011072: Bucket"
  },
  {
    classId: "EC011073",
    classGroup: "EG000050",
    artClassDesc: "Tube expander head",
    artClassVersion: 4,
    label: "EC011073: Tube expander head"
  },
  {
    classId: "EC011074",
    classGroup: "EG000050",
    artClassDesc: "Tube expander pliers",
    artClassVersion: 4,
    label: "EC011074: Tube expander pliers"
  },
  {
    classId: "EC011075",
    classGroup: "EG000044",
    artClassDesc: "Expansion tank tester",
    artClassVersion: 3,
    label: "EC011075: Expansion tank tester"
  },
  {
    classId: "EC011076",
    classGroup: "EG010330",
    artClassDesc: "Flanging machine (tube)",
    artClassVersion: 6,
    label: "EC011076: Flanging machine (tube)"
  },
  {
    classId: "EC011078",
    classGroup: "EG000061",
    artClassDesc: "Gas lighter",
    artClassVersion: 5,
    label: "EC011078: Gas lighter"
  },
  {
    classId: "EC011079",
    classGroup: "EG000061",
    artClassDesc: "Gas bottle cart",
    artClassVersion: 4,
    label: "EC011079: Gas bottle cart"
  },
  {
    classId: "EC011081",
    classGroup: "EG000044",
    artClassDesc: "Gas leakage meter",
    artClassVersion: 3,
    label: "EC011081: Gas leakage meter"
  },
  {
    classId: "EC011082",
    classGroup: "EG000044",
    artClassDesc: "Water heater adjustment meter",
    artClassVersion: 3,
    label: "EC011082: Water heater adjustment meter"
  },
  {
    classId: "EC011083",
    classGroup: "EG000044",
    artClassDesc: "Sound intensity meter",
    artClassVersion: 4,
    label: "EC011083: Sound intensity meter"
  },
  {
    classId: "EC011084",
    classGroup: "EG000050",
    artClassDesc: "Cam pliers",
    artClassVersion: 7,
    label: "EC011084: Cam pliers"
  },
  {
    classId: "EC011085",
    classGroup: "EG000044",
    artClassDesc: "Hand suction set flue gas analyser",
    artClassVersion: 3,
    label: "EC011085: Hand suction set flue gas analyser"
  },
  {
    classId: "EC011086",
    classGroup: "EG000061",
    artClassDesc: "Hard solder",
    artClassVersion: 5,
    label: "EC011086: Hard solder"
  },
  {
    classId: "EC011087",
    classGroup: "EG010320",
    artClassDesc: "Lifting table",
    artClassVersion: 3,
    label: "EC011087: Lifting table"
  },
  {
    classId: "EC011088",
    classGroup: "EG000050",
    artClassDesc: "Angle bender",
    artClassVersion: 4,
    label: "EC011088: Angle bender"
  },
  {
    classId: "EC011089",
    classGroup: "EG010220",
    artClassDesc: "Boiler/fitting brush",
    artClassVersion: 3,
    label: "EC011089: Boiler/fitting brush"
  },
  {
    classId: "EC011090",
    classGroup: "EG000050",
    artClassDesc: "Tap seat grinder",
    artClassVersion: 3,
    label: "EC011090: Tap seat grinder"
  },
  {
    classId: "EC011091",
    classGroup: "EG010320",
    artClassDesc: "Ladder lift",
    artClassVersion: 4,
    label: "EC011091: Ladder lift"
  },
  {
    classId: "EC011092",
    classGroup: "EG000061",
    artClassDesc: "Welding/cutting torch set",
    artClassVersion: 5,
    label: "EC011092: Welding/cutting torch set"
  },
  {
    classId: "EC011094",
    classGroup: "EG000061",
    artClassDesc: "Welding goggles",
    artClassVersion: 5,
    label: "EC011094: Welding goggles"
  },
  {
    classId: "EC011096",
    classGroup: "EG000061",
    artClassDesc: "Welding wire",
    artClassVersion: 5,
    label: "EC011096: Welding wire"
  },
  {
    classId: "EC011097",
    classGroup: "EG000061",
    artClassDesc: "Welding electrode",
    artClassVersion: 4,
    label: "EC011097: Welding electrode"
  },
  {
    classId: "EC011098",
    classGroup: "EG000061",
    artClassDesc: "Welding helmet/welding mask",
    artClassVersion: 6,
    label: "EC011098: Welding helmet/welding mask"
  },
  {
    classId: "EC011101",
    classGroup: "EG000061",
    artClassDesc: "Welding torch",
    artClassVersion: 5,
    label: "EC011101: Welding torch"
  },
  {
    classId: "EC011103",
    classGroup: "EG000061",
    artClassDesc: "Welding mirror",
    artClassVersion: 3,
    label: "EC011103: Welding mirror"
  },
  {
    classId: "EC011104",
    classGroup: "EG000061",
    artClassDesc: "Welding transformer",
    artClassVersion: 6,
    label: "EC011104: Welding transformer"
  },
  {
    classId: "EC011105",
    classGroup: "EG000061",
    artClassDesc: "Welding torch end piece",
    artClassVersion: 4,
    label: "EC011105: Welding torch end piece"
  },
  {
    classId: "EC011106",
    classGroup: "EG000050",
    artClassDesc: "Inspection camera (battery)",
    artClassVersion: 5,
    label: "EC011106: Inspection camera (battery)"
  },
  {
    classId: "EC011108",
    classGroup: "EG010320",
    artClassDesc: "Hoist trolley",
    artClassVersion: 4,
    label: "EC011108: Hoist trolley"
  },
  {
    classId: "EC011109",
    classGroup: "EG000044",
    artClassDesc: "Air discharge meter",
    artClassVersion: 5,
    label: "EC011109: Air discharge meter"
  },
  {
    classId: "EC011110",
    classGroup: "EG000044",
    artClassDesc: "Air velocity meter",
    artClassVersion: 3,
    label: "EC011110: Air velocity meter"
  },
  {
    classId: "EC011111",
    classGroup: "EG000044",
    artClassDesc: "Air humidity meter",
    artClassVersion: 2,
    label: "EC011111: Air humidity meter"
  },
  {
    classId: "EC011112",
    classGroup: "EG000061",
    artClassDesc: "Manometer protective cap",
    artClassVersion: 5,
    label: "EC011112: Manometer protective cap"
  },
  {
    classId: "EC011114",
    classGroup: "EG000044",
    artClassDesc: "Measuring head air velocity meter",
    artClassVersion: 5,
    label: "EC011114: Measuring head air velocity meter"
  },
  {
    classId: "EC011115",
    classGroup: "EG010330",
    artClassDesc: "Defrost transformer",
    artClassVersion: 2,
    label: "EC011115: Defrost transformer"
  },
  {
    classId: "EC011116",
    classGroup: "EG000050",
    artClassDesc: "Air bleed key",
    artClassVersion: 8,
    label: "EC011116: Air bleed key"
  },
  {
    classId: "EC011117",
    classGroup: "EG000050",
    artClassDesc: "Gasket cutter",
    artClassVersion: 5,
    label: "EC011117: Gasket cutter"
  },
  {
    classId: "EC011118",
    classGroup: "EG000051",
    artClassDesc: "Pipe freezing device",
    artClassVersion: 4,
    label: "EC011118: Pipe freezing device"
  },
  {
    classId: "EC011119",
    classGroup: "EG000050",
    artClassDesc: "Pipe bending machine",
    artClassVersion: 4,
    label: "EC011119: Pipe bending machine"
  },
  {
    classId: "EC011121",
    classGroup: "EG010330",
    artClassDesc: "Pipe support",
    artClassVersion: 3,
    label: "EC011121: Pipe support"
  },
  {
    classId: "EC011122",
    classGroup: "EG000044",
    artClassDesc: "Pitot tube",
    artClassVersion: 3,
    label: "EC011122: Pitot tube"
  },
  {
    classId: "EC011123",
    classGroup: "EG000061",
    artClassDesc: "Propane bottle",
    artClassVersion: 6,
    label: "EC011123: Propane bottle"
  },
  {
    classId: "EC011124",
    classGroup: "EG000050",
    artClassDesc: "Die head ratchet",
    artClassVersion: 4,
    label: "EC011124: Die head ratchet"
  },
  {
    classId: "EC011125",
    classGroup: "EG000061",
    artClassDesc: "Pressure reducing valve welding- and soldering tools",
    artClassVersion: 4,
    label: "EC011125: Pressure reducing valve welding- and soldering tools"
  },
  {
    classId: "EC011126",
    classGroup: "EG000044",
    artClassDesc: "Flue gas analyser",
    artClassVersion: 4,
    label: "EC011126: Flue gas analyser"
  },
  {
    classId: "EC011127",
    classGroup: "EG000044",
    artClassDesc: "Flue gas probe",
    artClassVersion: 4,
    label: "EC011127: Flue gas probe"
  },
  {
    classId: "EC011128",
    classGroup: "EG010220",
    artClassDesc: "Cleaning brush",
    artClassVersion: 6,
    label: "EC011128: Cleaning brush"
  },
  {
    classId: "EC011129",
    classGroup: "EG010330",
    artClassDesc: "Letter/number punch set",
    artClassVersion: 4,
    label: "EC011129: Letter/number punch set"
  },
  {
    classId: "EC011130",
    classGroup: "EG000060",
    artClassDesc: "Cutting-/cooling liquid",
    artClassVersion: 5,
    label: "EC011130: Cutting-/cooling liquid"
  },
  {
    classId: "EC011131",
    classGroup: "EG000061",
    artClassDesc: "Cutting torch",
    artClassVersion: 6,
    label: "EC011131: Cutting torch"
  },
  {
    classId: "EC011132",
    classGroup: "EG000061",
    artClassDesc: "Cutting torch end piece",
    artClassVersion: 5,
    label: "EC011132: Cutting torch end piece"
  },
  {
    classId: "EC011133",
    classGroup: "EG000050",
    artClassDesc: "Die plate holder",
    artClassVersion: 5,
    label: "EC011133: Die plate holder"
  },
  {
    classId: "EC011134",
    classGroup: "EG000061",
    artClassDesc: "Soldering tongs",
    artClassVersion: 4,
    label: "EC011134: Soldering tongs"
  },
  {
    classId: "EC011135",
    classGroup: "EG000049",
    artClassDesc: "Steel wire clamp",
    artClassVersion: 5,
    label: "EC011135: Steel wire clamp"
  },
  {
    classId: "EC011136",
    classGroup: "EG000061",
    artClassDesc: "Welding cable plug",
    artClassVersion: 2,
    label: "EC011136: Welding cable plug"
  },
  {
    classId: "EC011139",
    classGroup: "EG010210",
    artClassDesc: "Testgas fire alarm installation",
    artClassVersion: 5,
    label: "EC011139: Testgas fire alarm installation"
  },
  {
    classId: "EC011141",
    classGroup: "EG010220",
    artClassDesc: "Rope",
    artClassVersion: 5,
    label: "EC011141: Rope"
  },
  {
    classId: "EC011143",
    classGroup: "EG000050",
    artClassDesc: "Extractor head",
    artClassVersion: 4,
    label: "EC011143: Extractor head"
  },
  {
    classId: "EC011144",
    classGroup: "EG000050",
    artClassDesc: "Extractor set",
    artClassVersion: 4,
    label: "EC011144: Extractor set"
  },
  {
    classId: "EC011146",
    classGroup: "EG000046",
    artClassDesc: "Safety line",
    artClassVersion: 5,
    label: "EC011146: Safety line"
  },
  {
    classId: "EC011147",
    classGroup: "EG010340",
    artClassDesc: "First aid kit",
    artClassVersion: 5,
    label: "EC011147: First aid kit"
  },
  {
    classId: "EC011148",
    classGroup: "EG000044",
    artClassDesc: "Extension rod for anemometer",
    artClassVersion: 3,
    label: "EC011148: Extension rod for anemometer"
  },
  {
    classId: "EC011149",
    classGroup: "EG000044",
    artClassDesc: "Detection instrument for tube obstructions",
    artClassVersion: 3,
    label: "EC011149: Detection instrument for tube obstructions"
  },
  {
    classId: "EC011150",
    classGroup: "EG000061",
    artClassDesc: "Flame extinguisher",
    artClassVersion: 3,
    label: "EC011150: Flame extinguisher"
  },
  {
    classId: "EC011151",
    classGroup: "EG000061",
    artClassDesc: "Filling nipple gas bottle",
    artClassVersion: 4,
    label: "EC011151: Filling nipple gas bottle"
  },
  {
    classId: "EC011153",
    classGroup: "EG000061",
    artClassDesc: "Wind guard welding torch",
    artClassVersion: 5,
    label: "EC011153: Wind guard welding torch"
  },
  {
    classId: "EC011154",
    classGroup: "EG000049",
    artClassDesc: "Safety strip, C-model beam clamp",
    artClassVersion: 3,
    label: "EC011154: Safety strip, C-model beam clamp"
  },
  {
    classId: "EC011155",
    classGroup: "EG015830",
    artClassDesc: "Level gauge tubular, upper and bottom part",
    artClassVersion: 6,
    label: "EC011155: Level gauge tubular, upper and bottom part"
  },
  {
    classId: "EC011156",
    classGroup: "EG015830",
    artClassDesc: "Level gauge tubular, bottom part",
    artClassVersion: 6,
    label: "EC011156: Level gauge tubular, bottom part"
  },
  {
    classId: "EC011157",
    classGroup: "EG015830",
    artClassDesc: "Level gauge tubular, upper part",
    artClassVersion: 6,
    label: "EC011157: Level gauge tubular, upper part"
  },
  {
    classId: "EC011158",
    classGroup: "EG015830",
    artClassDesc: "Level gauge tubular, intermediate part",
    artClassVersion: 6,
    label: "EC011158: Level gauge tubular, intermediate part"
  },
  {
    classId: "EC011159",
    classGroup: "EG015830",
    artClassDesc: "Level gauge glass tubular",
    artClassVersion: 6,
    label: "EC011159: Level gauge glass tubular"
  },
  {
    classId: "EC011160",
    classGroup: "EG015830",
    artClassDesc: "Gauge, magnetic",
    artClassVersion: 5,
    label: "EC011160: Gauge, magnetic"
  },
  {
    classId: "EC011161",
    classGroup: "EG015830",
    artClassDesc: "Viewing/checking device",
    artClassVersion: 5,
    label: "EC011161: Viewing/checking device"
  },
  {
    classId: "EC011162",
    classGroup: "EG015830",
    artClassDesc: "Level gauge, flat",
    artClassVersion: 7,
    label: "EC011162: Level gauge, flat"
  },
  {
    classId: "EC011163",
    classGroup: "EG017210",
    artClassDesc: "Shelf",
    artClassVersion: 7,
    label: "EC011163: Shelf"
  },
  {
    classId: "EC011164",
    classGroup: "EG017210",
    artClassDesc: "Mirror clamp",
    artClassVersion: 4,
    label: "EC011164: Mirror clamp"
  },
  {
    classId: "EC011165",
    classGroup: "EG015830",
    artClassDesc: "Bimetal thermometer",
    artClassVersion: 6,
    label: "EC011165: Bimetal thermometer"
  },
  {
    classId: "EC011166",
    classGroup: "EG015830",
    artClassDesc: "Mano-thermometer",
    artClassVersion: 5,
    label: "EC011166: Mano-thermometer"
  },
  {
    classId: "EC011167",
    classGroup: "EG015830",
    artClassDesc: "Pressure difference gauge",
    artClassVersion: 5,
    label: "EC011167: Pressure difference gauge"
  },
  {
    classId: "EC011168",
    classGroup: "EG015830",
    artClassDesc: "Membrane pressure gauge",
    artClassVersion: 5,
    label: "EC011168: Membrane pressure gauge"
  },
  {
    classId: "EC011169",
    classGroup: "EG015830",
    artClassDesc: "Diaphragm drum pressure gauge",
    artClassVersion: 6,
    label: "EC011169: Diaphragm drum pressure gauge"
  },
  {
    classId: "EC011170",
    classGroup: "EG015830",
    artClassDesc: "Bourdon tube pressure gauge",
    artClassVersion: 7,
    label: "EC011170: Bourdon tube pressure gauge"
  },
  {
    classId: "EC011171",
    classGroup: "EG015840",
    artClassDesc: "Outdoor thermostat",
    artClassVersion: 4,
    label: "EC011171: Outdoor thermostat"
  },
  {
    classId: "EC011172",
    classGroup: "EG015840",
    artClassDesc: "Duct thermostat",
    artClassVersion: 4,
    label: "EC011172: Duct thermostat"
  },
  {
    classId: "EC011173",
    classGroup: "EG015840",
    artClassDesc: "Contact thermostat",
    artClassVersion: 5,
    label: "EC011173: Contact thermostat"
  },
  {
    classId: "EC011174",
    classGroup: "EG015840",
    artClassDesc: "Frost protection thermostat (air)",
    artClassVersion: 4,
    label: "EC011174: Frost protection thermostat (air)"
  },
  {
    classId: "EC011175",
    classGroup: "EG015840",
    artClassDesc: "Room hygrostat",
    artClassVersion: 4,
    label: "EC011175: Room hygrostat"
  },
  {
    classId: "EC011176",
    classGroup: "EG015840",
    artClassDesc: "Duct hygrostat",
    artClassVersion: 4,
    label: "EC011176: Duct hygrostat"
  },
  {
    classId: "EC011177",
    classGroup: "EG015840",
    artClassDesc: "Air flow switch",
    artClassVersion: 4,
    label: "EC011177: Air flow switch"
  },
  {
    classId: "EC011178",
    classGroup: "EG015840",
    artClassDesc: "Float switch",
    artClassVersion: 5,
    label: "EC011178: Float switch"
  },
  {
    classId: "EC011179",
    classGroup: "EG015840",
    artClassDesc: "Pressure differential switch",
    artClassVersion: 4,
    label: "EC011179: Pressure differential switch"
  },
  {
    classId: "EC011180",
    classGroup: "EG015520",
    artClassDesc: "Claw coupling/hose connector",
    artClassVersion: 5,
    label: "EC011180: Claw coupling/hose connector"
  },
  {
    classId: "EC011181",
    classGroup: "EG015520",
    artClassDesc: "Claw coupling blind cap",
    artClassVersion: 4,
    label: "EC011181: Claw coupling blind cap"
  },
  {
    classId: "EC011182",
    classGroup: "EG015520",
    artClassDesc: "Press sleeve for hose",
    artClassVersion: 4,
    label: "EC011182: Press sleeve for hose"
  },
  {
    classId: "EC011183",
    classGroup: "EG015520",
    artClassDesc: "Hose connector/flange coupling",
    artClassVersion: 5,
    label: "EC011183: Hose connector/flange coupling"
  },
  {
    classId: "EC011184",
    classGroup: "EG015520",
    artClassDesc: "Compression coupling for hose/thread",
    artClassVersion: 3,
    label: "EC011184: Compression coupling for hose/thread"
  },
  {
    classId: "EC011185",
    classGroup: "EG015520",
    artClassDesc: "Compression coupling, hose T and L/thread",
    artClassVersion: 3,
    label: "EC011185: Compression coupling, hose T and L/thread"
  },
  {
    classId: "EC011186",
    classGroup: "EG015520",
    artClassDesc: "Banjo compression coupling for hose",
    artClassVersion: 3,
    label: "EC011186: Banjo compression coupling for hose"
  },
  {
    classId: "EC011187",
    classGroup: "EG015520",
    artClassDesc: "Quick coupling/thread",
    artClassVersion: 5,
    label: "EC011187: Quick coupling/thread"
  },
  {
    classId: "EC011188",
    classGroup: "EG017210",
    artClassDesc: "Ashtray holder",
    artClassVersion: 5,
    label: "EC011188: Ashtray holder"
  },
  {
    classId: "EC011189",
    classGroup: "EG017210",
    artClassDesc: "Garbage bucket",
    artClassVersion: 5,
    label: "EC011189: Garbage bucket"
  },
  {
    classId: "EC011190",
    classGroup: "EG017210",
    artClassDesc: "Basket",
    artClassVersion: 5,
    label: "EC011190: Basket"
  },
  {
    classId: "EC011191",
    classGroup: "EG017210",
    artClassDesc: "Accent colour element",
    artClassVersion: 2,
    label: "EC011191: Accent colour element"
  },
  {
    classId: "EC011192",
    classGroup: "EG017210",
    artClassDesc: "Lavatory brush for set",
    artClassVersion: 5,
    label: "EC011192: Lavatory brush for set"
  },
  {
    classId: "EC011193",
    classGroup: "EG017210",
    artClassDesc: "Laundry line",
    artClassVersion: 2,
    label: "EC011193: Laundry line"
  },
  {
    classId: "EC011194",
    classGroup: "EG015840",
    artClassDesc: "Flow switch, liquid",
    artClassVersion: 7,
    label: "EC011194: Flow switch, liquid"
  },
  {
    classId: "EC011195",
    classGroup: "EG015840",
    artClassDesc: "Built-in flow switch, liquid",
    artClassVersion: 5,
    label: "EC011195: Built-in flow switch, liquid"
  },
  {
    classId: "EC011196",
    classGroup: "EG017210",
    artClassDesc: "Toilet seat",
    artClassVersion: 6,
    label: "EC011196: Toilet seat"
  },
  {
    classId: "EC011197",
    classGroup: "EG015620",
    artClassDesc: "Pneumatic servomotor for valves",
    artClassVersion: 6,
    label: "EC011197: Pneumatic servomotor for valves"
  },
  {
    classId: "EC011198",
    classGroup: "EG015240",
    artClassDesc: "Ceiling induction unit",
    artClassVersion: 3,
    label: "EC011198: Ceiling induction unit"
  },
  {
    classId: "EC011199",
    classGroup: "EG015240",
    artClassDesc: "Façade induction unit",
    artClassVersion: 3,
    label: "EC011199: Façade induction unit"
  },
  {
    classId: "EC011200",
    classGroup: "EG015240",
    artClassDesc: "Passive ceiling cooling convector",
    artClassVersion: 3,
    label: "EC011200: Passive ceiling cooling convector"
  },
  {
    classId: "EC011205",
    classGroup: "EG015840",
    artClassDesc: "Level switch for steam boiler",
    artClassVersion: 6,
    label: "EC011205: Level switch for steam boiler"
  },
  {
    classId: "EC011206",
    classGroup: "EG015840",
    artClassDesc: "Electronic level switch",
    artClassVersion: 7,
    label: "EC011206: Electronic level switch"
  },
  {
    classId: "EC011215",
    classGroup: "EG015510",
    artClassDesc: "Braiding for hose",
    artClassVersion: 3,
    label: "EC011215: Braiding for hose"
  },
  {
    classId: "EC011217",
    classGroup: "EG015550",
    artClassDesc: "Oval air duct",
    artClassVersion: 5,
    label: "EC011217: Oval air duct"
  },
  {
    classId: "EC011219",
    classGroup: "EG015560",
    artClassDesc: "Cover, round air duct",
    artClassVersion: 5,
    label: "EC011219: Cover, round air duct"
  },
  {
    classId: "EC011220",
    classGroup: "EG015560",
    artClassDesc: "Connecting piece round air duct",
    artClassVersion: 6,
    label: "EC011220: Connecting piece round air duct"
  },
  {
    classId: "EC011221",
    classGroup: "EG015560",
    artClassDesc: "Inspection bend, round air duct",
    artClassVersion: 4,
    label: "EC011221: Inspection bend, round air duct"
  },
  {
    classId: "EC011222",
    classGroup: "EG015560",
    artClassDesc: "Outlet bend, round air duct",
    artClassVersion: 4,
    label: "EC011222: Outlet bend, round air duct"
  },
  {
    classId: "EC011223",
    classGroup: "EG015560",
    artClassDesc: "Cover, double-walled round air duct",
    artClassVersion: 5,
    label: "EC011223: Cover, double-walled round air duct"
  },
  {
    classId: "EC011224",
    classGroup: "EG015560",
    artClassDesc: "Connection piece double-walled round air duct",
    artClassVersion: 5,
    label: "EC011224: Connection piece double-walled round air duct"
  },
  {
    classId: "EC011225",
    classGroup: "EG015280",
    artClassDesc: "Automatic burner",
    artClassVersion: 3,
    label: "EC011225: Automatic burner"
  },
  {
    classId: "EC011228",
    classGroup: "EG000026",
    artClassDesc: "Frost temperature sensor, passive",
    artClassVersion: 5,
    label: "EC011228: Frost temperature sensor, passive"
  },
  {
    classId: "EC011229",
    classGroup: "EG000026",
    artClassDesc: "Flue temperature sensor, passive",
    artClassVersion: 5,
    label: "EC011229: Flue temperature sensor, passive"
  },
  {
    classId: "EC011230",
    classGroup: "EG015850",
    artClassDesc: "Signal converter, passive",
    artClassVersion: 5,
    label: "EC011230: Signal converter, passive"
  },
  {
    classId: "EC011231",
    classGroup: "EG015850",
    artClassDesc: "Signal converter, active",
    artClassVersion: 4,
    label: "EC011231: Signal converter, active"
  },
  {
    classId: "EC011232",
    classGroup: "EG015240",
    artClassDesc: "Sound-insulating grille",
    artClassVersion: 1,
    label: "EC011232: Sound-insulating grille"
  },
  {
    classId: "EC011233",
    classGroup: "EG015520",
    artClassDesc: "Hose connector/threaded coupling",
    artClassVersion: 7,
    label: "EC011233: Hose connector/threaded coupling"
  },
  {
    classId: "EC011234",
    classGroup: "EG015140",
    artClassDesc: "Roll filter",
    artClassVersion: 6,
    label: "EC011234: Roll filter"
  },
  {
    classId: "EC011235",
    classGroup: "EG015140",
    artClassDesc: "Cellular filter",
    artClassVersion: 6,
    label: "EC011235: Cellular filter"
  },
  {
    classId: "EC011236",
    classGroup: "EG015140",
    artClassDesc: "Round air filter",
    artClassVersion: 5,
    label: "EC011236: Round air filter"
  },
  {
    classId: "EC011237",
    classGroup: "EG015140",
    artClassDesc: "Frame for round air filter",
    artClassVersion: 2,
    label: "EC011237: Frame for round air filter"
  },
  {
    classId: "EC011238",
    classGroup: "EG015140",
    artClassDesc: "Cartridge air filter",
    artClassVersion: 6,
    label: "EC011238: Cartridge air filter"
  },
  {
    classId: "EC011239",
    classGroup: "EG015140",
    artClassDesc: "Roll air filter unit",
    artClassVersion: 2,
    label: "EC011239: Roll air filter unit"
  },
  {
    classId: "EC011240",
    classGroup: "EG015140",
    artClassDesc: "Air filter frame",
    artClassVersion: 2,
    label: "EC011240: Air filter frame"
  },
  {
    classId: "EC011241",
    classGroup: "EG015140",
    artClassDesc: "Air filter duct housing",
    artClassVersion: 5,
    label: "EC011241: Air filter duct housing"
  },
  {
    classId: "EC011242",
    classGroup: "EG015560",
    artClassDesc: "Wall/roof feed-through round air duct",
    artClassVersion: 4,
    label: "EC011242: Wall/roof feed-through round air duct"
  },
  {
    classId: "EC011244",
    classGroup: "EG015560",
    artClassDesc: "Tensioning ring, round air duct",
    artClassVersion: 4,
    label: "EC011244: Tensioning ring, round air duct"
  },
  {
    classId: "EC011245",
    classGroup: "EG018310",
    artClassDesc: "Sound-absorbing bend, round air duct",
    artClassVersion: 5,
    label: "EC011245: Sound-absorbing bend, round air duct"
  },
  {
    classId: "EC011246",
    classGroup: "EG018310",
    artClassDesc: "Sound-absorbing roof duct round air duct",
    artClassVersion: 5,
    label: "EC011246: Sound-absorbing roof duct round air duct"
  },
  {
    classId: "EC011247",
    classGroup: "EG018310",
    artClassDesc: "Sound absorber, oval air duct",
    artClassVersion: 6,
    label: "EC011247: Sound absorber, oval air duct"
  },
  {
    classId: "EC011248",
    classGroup: "EG015520",
    artClassDesc: "Flanged ring for metal hose",
    artClassVersion: 3,
    label: "EC011248: Flanged ring for metal hose"
  },
  {
    classId: "EC011249",
    classGroup: "EG015140",
    artClassDesc: "Air filter unit",
    artClassVersion: 3,
    label: "EC011249: Air filter unit"
  },
  {
    classId: "EC011252",
    classGroup: "EG015580",
    artClassDesc: "Cowl for roof mounted fan",
    artClassVersion: 3,
    label: "EC011252: Cowl for roof mounted fan"
  },
  {
    classId: "EC011253",
    classGroup: "EG015580",
    artClassDesc: "Roof sleeve for roof mounted fan",
    artClassVersion: 2,
    label: "EC011253: Roof sleeve for roof mounted fan"
  },
  {
    classId: "EC011254",
    classGroup: "EG015580",
    artClassDesc: "Acoustic silencer for roof mounted fan",
    artClassVersion: 2,
    label: "EC011254: Acoustic silencer for roof mounted fan"
  },
  {
    classId: "EC011255",
    classGroup: "EG015560",
    artClassDesc: "Flange ring, round duct",
    artClassVersion: 2,
    label: "EC011255: Flange ring, round duct"
  },
  {
    classId: "EC011257",
    classGroup: "EG015560",
    artClassDesc: "Insulation, cover ring, round air duct",
    artClassVersion: 4,
    label: "EC011257: Insulation, cover ring, round air duct"
  },
  {
    classId: "EC011258",
    classGroup: "EG015560",
    artClassDesc: "Loose flange, round air duct",
    artClassVersion: 3,
    label: "EC011258: Loose flange, round air duct"
  },
  {
    classId: "EC011259",
    classGroup: "EG015560",
    artClassDesc: "Suction cone round air duct",
    artClassVersion: 4,
    label: "EC011259: Suction cone round air duct"
  },
  {
    classId: "EC011260",
    classGroup: "EG015560",
    artClassDesc: "Inspection piece, round air duct",
    artClassVersion: 3,
    label: "EC011260: Inspection piece, round air duct"
  },
  {
    classId: "EC011261",
    classGroup: "EG015560",
    artClassDesc: "Mounting ring, hose/valve",
    artClassVersion: 2,
    label: "EC011261: Mounting ring, hose/valve"
  },
  {
    classId: "EC011262",
    classGroup: "EG015560",
    artClassDesc: "Telescopic pipe, round air duct",
    artClassVersion: 4,
    label: "EC011262: Telescopic pipe, round air duct"
  },
  {
    classId: "EC011264",
    classGroup: "EG015560",
    artClassDesc: "Connecting piece, oval air duct",
    artClassVersion: 5,
    label: "EC011264: Connecting piece, oval air duct"
  },
  {
    classId: "EC011265",
    classGroup: "EG015560",
    artClassDesc: "Cover, oval air duct",
    artClassVersion: 4,
    label: "EC011265: Cover, oval air duct"
  },
  {
    classId: "EC011266",
    classGroup: "EG015560",
    artClassDesc: "Oval saddle for air duct",
    artClassVersion: 3,
    label: "EC011266: Oval saddle for air duct"
  },
  {
    classId: "EC011267",
    classGroup: "EG015580",
    artClassDesc: "Ventilation cover, round air duct",
    artClassVersion: 5,
    label: "EC011267: Ventilation cover, round air duct"
  },
  {
    classId: "EC011268",
    classGroup: "EG015630",
    artClassDesc: "Control valve, round air duct",
    artClassVersion: 5,
    label: "EC011268: Control valve, round air duct"
  },
  {
    classId: "EC011269",
    classGroup: "EG015520",
    artClassDesc: "Clamp coupling/hose connector",
    artClassVersion: 3,
    label: "EC011269: Clamp coupling/hose connector"
  },
  {
    classId: "EC011270",
    classGroup: "EG019720",
    artClassDesc: "Solid rubber plate",
    artClassVersion: 6,
    label: "EC011270: Solid rubber plate"
  },
  {
    classId: "EC011271",
    classGroup: "EG015580",
    artClassDesc: "Roof sleeve upstand for roof mounted fan",
    artClassVersion: 3,
    label: "EC011271: Roof sleeve upstand for roof mounted fan"
  },
  {
    classId: "EC011272",
    classGroup: "EG015580",
    artClassDesc: "Flange plate for roof mounted fan",
    artClassVersion: 1,
    label: "EC011272: Flange plate for roof mounted fan"
  },
  {
    classId: "EC011273",
    classGroup: "EG015570",
    artClassDesc: "Flexible duct connector",
    artClassVersion: 3,
    label: "EC011273: Flexible duct connector"
  },
  {
    classId: "EC011274",
    classGroup: "EG015580",
    artClassDesc: "Set-up piece for roof mounted fan",
    artClassVersion: 2,
    label: "EC011274: Set-up piece for roof mounted fan"
  },
  {
    classId: "EC011275",
    classGroup: "EG015630",
    artClassDesc: "Diaphragm valve (round)",
    artClassVersion: 3,
    label: "EC011275: Diaphragm valve (round)"
  },
  {
    classId: "EC011276",
    classGroup: "EG015630",
    artClassDesc: "Smoke valve double-walled round air duct",
    artClassVersion: 5,
    label: "EC011276: Smoke valve double-walled round air duct"
  },
  {
    classId: "EC011277",
    classGroup: "EG015630",
    artClassDesc: "Smoke valve air duct",
    artClassVersion: 3,
    label: "EC011277: Smoke valve air duct"
  },
  {
    classId: "EC011278",
    classGroup: "EG015630",
    artClassDesc: "Control valve, double-walled air duct",
    artClassVersion: 5,
    label: "EC011278: Control valve, double-walled air duct"
  },
  {
    classId: "EC011279",
    classGroup: "EG015630",
    artClassDesc: "Slide valve, round air duct",
    artClassVersion: 4,
    label: "EC011279: Slide valve, round air duct"
  },
  {
    classId: "EC011280",
    classGroup: "EG015630",
    artClassDesc: "Three-way valve, round air duct",
    artClassVersion: 4,
    label: "EC011280: Three-way valve, round air duct"
  },
  {
    classId: "EC011281",
    classGroup: "EG015810",
    artClassDesc: "Non-return valve, round air duct",
    artClassVersion: 3,
    label: "EC011281: Non-return valve, round air duct"
  },
  {
    classId: "EC011282",
    classGroup: "EG015810",
    artClassDesc: "Non-return valve double-walled round air duct",
    artClassVersion: 6,
    label: "EC011282: Non-return valve double-walled round air duct"
  },
  {
    classId: "EC011283",
    classGroup: "EG015240",
    artClassDesc: "Sand separation air grille",
    artClassVersion: 3,
    label: "EC011283: Sand separation air grille"
  },
  {
    classId: "EC011284",
    classGroup: "EG015240",
    artClassDesc: "Nozzle grille in frame",
    artClassVersion: 3,
    label: "EC011284: Nozzle grille in frame"
  },
  {
    classId: "EC011285",
    classGroup: "EG015220",
    artClassDesc: "Heat recovery unit for residential construction",
    artClassVersion: 5,
    label: "EC011285: Heat recovery unit for residential construction"
  },
  {
    classId: "EC011286",
    classGroup: "EG015220",
    artClassDesc: "Heat recovery unit",
    artClassVersion: 7,
    label: "EC011286: Heat recovery unit"
  },
  {
    classId: "EC011287",
    classGroup: "EG015580",
    artClassDesc: "Inspection hatch for air duct",
    artClassVersion: 2,
    label: "EC011287: Inspection hatch for air duct"
  },
  {
    classId: "EC011288",
    classGroup: "EG015540",
    artClassDesc: "Two-point bottom connecting block",
    artClassVersion: 5,
    label: "EC011288: Two-point bottom connecting block"
  },
  {
    classId: "EC011289",
    classGroup: "EG017110",
    artClassDesc: "Toilet bowl/pan",
    artClassVersion: 6,
    label: "EC011289: Toilet bowl/pan"
  },
  {
    classId: "EC011290",
    classGroup: "EG015620",
    artClassDesc: "Electric servomotor",
    artClassVersion: 9,
    label: "EC011290: Electric servomotor"
  },
  {
    classId: "EC011291",
    classGroup: "EG015240",
    artClassDesc: "Punched plate grille",
    artClassVersion: 5,
    label: "EC011291: Punched plate grille"
  },
  {
    classId: "EC011292",
    classGroup: "EG015580",
    artClassDesc: "Adapter upstand for flat roof",
    artClassVersion: 4,
    label: "EC011292: Adapter upstand for flat roof"
  },
  {
    classId: "EC011293",
    classGroup: "EG015560",
    artClassDesc: "Heat exchanger ventilation cover",
    artClassVersion: 2,
    label: "EC011293: Heat exchanger ventilation cover"
  },
  {
    classId: "EC011294",
    classGroup: "EG015580",
    artClassDesc: "Set-up piece roof upstand prefab chimney single family house",
    artClassVersion: 4,
    label: "EC011294: Set-up piece roof upstand prefab chimney single family house"
  },
  {
    classId: "EC011295",
    classGroup: "EG015610",
    artClassDesc: "Thermostatic control element for valve with remote sensor/operation",
    artClassVersion: 3,
    label: "EC011295: Thermostatic control element for valve with remote sensor/operation"
  },
  {
    classId: "EC011296",
    classGroup: "EG015610",
    artClassDesc: "Radiator connection combination for decor radiator",
    artClassVersion: 5,
    label: "EC011296: Radiator connection combination for decor radiator"
  },
  {
    classId: "EC011297",
    classGroup: "EG015510",
    artClassDesc: "PTFE hose",
    artClassVersion: 5,
    label: "EC011297: PTFE hose"
  },
  {
    classId: "EC011298",
    classGroup: "EG015280",
    artClassDesc: "Connection set for heat generator",
    artClassVersion: 5,
    label: "EC011298: Connection set for heat generator"
  },
  {
    classId: "EC011299",
    classGroup: "EG015550",
    artClassDesc: "Single-walled flue gas pipe",
    artClassVersion: 7,
    label: "EC011299: Single-walled flue gas pipe"
  },
  {
    classId: "EC011300",
    classGroup: "EG017210",
    artClassDesc: "Bucket grid",
    artClassVersion: 4,
    label: "EC011300: Bucket grid"
  },
  {
    classId: "EC011301",
    classGroup: "EG015240",
    artClassDesc: "Hot water air curtain",
    artClassVersion: 5,
    label: "EC011301: Hot water air curtain"
  },
  {
    classId: "EC011302",
    classGroup: "EG017120",
    artClassDesc: "Built-in flushing reservoir without frame",
    artClassVersion: 6,
    label: "EC011302: Built-in flushing reservoir without frame"
  },
  {
    classId: "EC011303",
    classGroup: "EG015530",
    artClassDesc: "Cast-iron pipe",
    artClassVersion: 5,
    label: "EC011303: Cast-iron pipe"
  },
  {
    classId: "EC011304",
    classGroup: "EG000050",
    artClassDesc: "Step spanner",
    artClassVersion: 8,
    label: "EC011304: Step spanner"
  },
  {
    classId: "EC011305",
    classGroup: "EG015640",
    artClassDesc: "Mobile electric spindle driver",
    artClassVersion: 3,
    label: "EC011305: Mobile electric spindle driver"
  },
  {
    classId: "EC011306",
    classGroup: "EG017210",
    artClassDesc: "Loose components, handle for adapted sanitary fittings",
    artClassVersion: 4,
    label: "EC011306: Loose components, handle for adapted sanitary fittings"
  },
  {
    classId: "EC011307",
    classGroup: "EG015620",
    artClassDesc: "Accessories/spare parts for servomotor",
    artClassVersion: 6,
    label: "EC011307: Accessories/spare parts for servomotor"
  },
  {
    classId: "EC011308",
    classGroup: "EG015840",
    artClassDesc: "Accessories/spare parts for room thermostat/clock thermostat",
    artClassVersion: 5,
    label: "EC011308: Accessories/spare parts for room thermostat/clock thermostat"
  },
  {
    classId: "EC011309",
    classGroup: "EG015840",
    artClassDesc: "Room clock thermostat",
    artClassVersion: 6,
    label: "EC011309: Room clock thermostat"
  },
  {
    classId: "EC011310",
    classGroup: "EG015610",
    artClassDesc: "Three-way control valve",
    artClassVersion: 9,
    label: "EC011310: Three-way control valve"
  },
  {
    classId: "EC011311",
    classGroup: "EG015810",
    artClassDesc: "Safety valve",
    artClassVersion: 9,
    label: "EC011311: Safety valve"
  },
  {
    classId: "EC011312",
    classGroup: "EG015810",
    artClassDesc: "Pressure-compensated control valve",
    artClassVersion: 6,
    label: "EC011312: Pressure-compensated control valve"
  },
  {
    classId: "EC011313",
    classGroup: "EG015840",
    artClassDesc: "Accessories/spare parts for zone controller",
    artClassVersion: 4,
    label: "EC011313: Accessories/spare parts for zone controller"
  },
  {
    classId: "EC011314",
    classGroup: "EG015510",
    artClassDesc: "Rubber hose",
    artClassVersion: 9,
    label: "EC011314: Rubber hose"
  },
  {
    classId: "EC011315",
    classGroup: "EG017110",
    artClassDesc: "Kitchen sink",
    artClassVersion: 7,
    label: "EC011315: Kitchen sink"
  },
  {
    classId: "EC011316",
    classGroup: "EG017110",
    artClassDesc: "Bowl part toilet combination",
    artClassVersion: 4,
    label: "EC011316: Bowl part toilet combination"
  },
  {
    classId: "EC011317",
    classGroup: "EG017110",
    artClassDesc: "Wash fountain",
    artClassVersion: 6,
    label: "EC011317: Wash fountain"
  },
  {
    classId: "EC011318",
    classGroup: "EG017110",
    artClassDesc: "Toilet combination",
    artClassVersion: 7,
    label: "EC011318: Toilet combination"
  },
  {
    classId: "EC011319",
    classGroup: "EG015810",
    artClassDesc: "Accessories/spare parts for flow and pressure controller",
    artClassVersion: 4,
    label: "EC011319: Accessories/spare parts for flow and pressure controller"
  },
  {
    classId: "EC011320",
    classGroup: "EG015810",
    artClassDesc: "Measuring device for flow and pressure controllers",
    artClassVersion: 4,
    label: "EC011320: Measuring device for flow and pressure controllers"
  },
  {
    classId: "EC011321",
    classGroup: "EG000049",
    artClassDesc: "Fastening strip, insulating plate, floor heating",
    artClassVersion: 4,
    label: "EC011321: Fastening strip, insulating plate, floor heating"
  },
  {
    classId: "EC011322",
    classGroup: "EG000049",
    artClassDesc: "Anchor clip floor mounted pipe",
    artClassVersion: 3,
    label: "EC011322: Anchor clip floor mounted pipe"
  },
  {
    classId: "EC011323",
    classGroup: "EG000049",
    artClassDesc: "Heat-conducting plate, floor heating",
    artClassVersion: 3,
    label: "EC011323: Heat-conducting plate, floor heating"
  },
  {
    classId: "EC011325",
    classGroup: "EG015260",
    artClassDesc: "Central heating refill installation",
    artClassVersion: 3,
    label: "EC011325: Central heating refill installation"
  },
  {
    classId: "EC011327",
    classGroup: "EG017610",
    artClassDesc: "Built-in mixing tap",
    artClassVersion: 5,
    label: "EC011327: Built-in mixing tap"
  },
  {
    classId: "EC011328",
    classGroup: "EG017610",
    artClassDesc: "Washbasin mixing tap",
    artClassVersion: 6,
    label: "EC011328: Washbasin mixing tap"
  },
  {
    classId: "EC011329",
    classGroup: "EG017610",
    artClassDesc: "Shower mixing tap",
    artClassVersion: 6,
    label: "EC011329: Shower mixing tap"
  },
  {
    classId: "EC011330",
    classGroup: "EG015210",
    artClassDesc: "Electric air heater, suspended",
    artClassVersion: 3,
    label: "EC011330: Electric air heater, suspended"
  },
  {
    classId: "EC011331",
    classGroup: "EG017110",
    artClassDesc: "Siphon cover",
    artClassVersion: 6,
    label: "EC011331: Siphon cover"
  },
  {
    classId: "EC011332",
    classGroup: "EG017110",
    artClassDesc: "Slophopper",
    artClassVersion: 5,
    label: "EC011332: Slophopper"
  },
  {
    classId: "EC011333",
    classGroup: "EG015260",
    artClassDesc: "Inline circulation pump",
    artClassVersion: 7,
    label: "EC011333: Inline circulation pump"
  },
  {
    classId: "EC011334",
    classGroup: "EG015260",
    artClassDesc: "Multistage centrifugal pump",
    artClassVersion: 7,
    label: "EC011334: Multistage centrifugal pump"
  },
  {
    classId: "EC011335",
    classGroup: "EG019710",
    artClassDesc: "Rail joint for mounting rail",
    artClassVersion: 7,
    label: "EC011335: Rail joint for mounting rail"
  },
  {
    classId: "EC011336",
    classGroup: "EG017130",
    artClassDesc: "Double wall/built-in element wall-hung toilet with flush valve",
    artClassVersion: 5,
    label: "EC011336: Double wall/built-in element wall-hung toilet with flush valve"
  },
  {
    classId: "EC011337",
    classGroup: "EG017130",
    artClassDesc: "Double wall/built-in element urinal with flushing reservoir",
    artClassVersion: 3,
    label: "EC011337: Double wall/built-in element urinal with flushing reservoir"
  },
  {
    classId: "EC011338",
    classGroup: "EG017130",
    artClassDesc: "Accessories/spare parts for double wall/built-in element sanitary",
    artClassVersion: 5,
    label: "EC011338: Accessories/spare parts for double wall/built-in element sanitary"
  },
  {
    classId: "EC011339",
    classGroup: "EG017140",
    artClassDesc: "Accessories/spare parts for flushing reservoir",
    artClassVersion: 4,
    label: "EC011339: Accessories/spare parts for flushing reservoir"
  },
  {
    classId: "EC011340",
    classGroup: "EG019510",
    artClassDesc: "Ceramic tile",
    artClassVersion: 4,
    label: "EC011340: Ceramic tile"
  },
  {
    classId: "EC011341",
    classGroup: "EG019510",
    artClassDesc: "Mosaic tile",
    artClassVersion: 4,
    label: "EC011341: Mosaic tile"
  },
  {
    classId: "EC011342",
    classGroup: "EG017550",
    artClassDesc: "Toilet bowl discharge connecting piece",
    artClassVersion: 4,
    label: "EC011342: Toilet bowl discharge connecting piece"
  },
  {
    classId: "EC011343",
    classGroup: "EG015610",
    artClassDesc: "Ball valve",
    artClassVersion: 8,
    label: "EC011343: Ball valve"
  },
  {
    classId: "EC011344",
    classGroup: "EG017610",
    artClassDesc: "Shut-off valve for sanitary devices",
    artClassVersion: 6,
    label: "EC011344: Shut-off valve for sanitary devices"
  },
  {
    classId: "EC011345",
    classGroup: "EG017610",
    artClassDesc: "Stop valve with knee control",
    artClassVersion: 6,
    label: "EC011345: Stop valve with knee control"
  },
  {
    classId: "EC011346",
    classGroup: "EG017630",
    artClassDesc: "Kitchen hand shower",
    artClassVersion: 5,
    label: "EC011346: Kitchen hand shower"
  },
  {
    classId: "EC011347",
    classGroup: "EG019890",
    artClassDesc: "Technical gas",
    artClassVersion: 5,
    label: "EC011347: Technical gas"
  },
  {
    classId: "EC011348",
    classGroup: "EG015510",
    artClassDesc: "Plastic hose without inlay",
    artClassVersion: 7,
    label: "EC011348: Plastic hose without inlay"
  },
  {
    classId: "EC011349",
    classGroup: "EG015880",
    artClassDesc: "Accessories/spare parts for HVAC sensors",
    artClassVersion: 7,
    label: "EC011349: Accessories/spare parts for HVAC sensors"
  },
  {
    classId: "EC011350",
    classGroup: "EG015110",
    artClassDesc: "Roof drain funnel, side outlet",
    artClassVersion: 6,
    label: "EC011350: Roof drain funnel, side outlet"
  },
  {
    classId: "EC011353",
    classGroup: "EG015540",
    artClassDesc: "Pipe trap 0, 90, 180 degrees",
    artClassVersion: 6,
    label: "EC011353: Pipe trap 0, 90, 180 degrees"
  },
  {
    classId: "EC011355",
    classGroup: "EG000049",
    artClassDesc: "Fastening mat for floor heating",
    artClassVersion: 4,
    label: "EC011355: Fastening mat for floor heating"
  },
  {
    classId: "EC011356",
    classGroup: "EG015510",
    artClassDesc: "Hose saddle",
    artClassVersion: 6,
    label: "EC011356: Hose saddle"
  },
  {
    classId: "EC011357",
    classGroup: "EG015140",
    artClassDesc: "Flat air filter",
    artClassVersion: 6,
    label: "EC011357: Flat air filter"
  },
  {
    classId: "EC011358",
    classGroup: "EG015110",
    artClassDesc: "Roof gutter outlet",
    artClassVersion: 3,
    label: "EC011358: Roof gutter outlet"
  },
  {
    classId: "EC011359",
    classGroup: "EG015240",
    artClassDesc: "Vortex grille",
    artClassVersion: 4,
    label: "EC011359: Vortex grille"
  },
  {
    classId: "EC011360",
    classGroup: "EG015240",
    artClassDesc: "Lamella grille",
    artClassVersion: 5,
    label: "EC011360: Lamella grille"
  },
  {
    classId: "EC011361",
    classGroup: "EG015550",
    artClassDesc: "Flexible flue gas pipe",
    artClassVersion: 7,
    label: "EC011361: Flexible flue gas pipe"
  },
  {
    classId: "EC011362",
    classGroup: "EG015560",
    artClassDesc: "Cover rectangular air duct",
    artClassVersion: 5,
    label: "EC011362: Cover rectangular air duct"
  },
  {
    classId: "EC011363",
    classGroup: "EG015560",
    artClassDesc: "Niche pipe",
    artClassVersion: 5,
    label: "EC011363: Niche pipe"
  },
  {
    classId: "EC011364",
    classGroup: "EG015560",
    artClassDesc: "Double-walled flue gas attachment, 3 connections",
    artClassVersion: 7,
    label: "EC011364: Double-walled flue gas attachment, 3 connections"
  },
  {
    classId: "EC011365",
    classGroup: "EG015560",
    artClassDesc: "Coupling piece, embedding duct",
    artClassVersion: 3,
    label: "EC011365: Coupling piece, embedding duct"
  },
  {
    classId: "EC011366",
    classGroup: "EG000049",
    artClassDesc: "Fastening wedge, carrying pipe section",
    artClassVersion: 4,
    label: "EC011366: Fastening wedge, carrying pipe section"
  },
  {
    classId: "EC011367",
    classGroup: "EG015850",
    artClassDesc: "VAV-controller",
    artClassVersion: 5,
    label: "EC011367: VAV-controller"
  },
  {
    classId: "EC011368",
    classGroup: "EG017130",
    artClassDesc: "Double wall/built-in element bidet",
    artClassVersion: 7,
    label: "EC011368: Double wall/built-in element bidet"
  },
  {
    classId: "EC011369",
    classGroup: "EG017130",
    artClassDesc: "Double wall/built-in element washbasin",
    artClassVersion: 6,
    label: "EC011369: Double wall/built-in element washbasin"
  },
  {
    classId: "EC011370",
    classGroup: "EG017130",
    artClassDesc: "Double wall/built-in element for urinal with flush valve",
    artClassVersion: 7,
    label: "EC011370: Double wall/built-in element for urinal with flush valve"
  },
  {
    classId: "EC011371",
    classGroup: "EG015610",
    artClassDesc: "Spray sprinkler",
    artClassVersion: 5,
    label: "EC011371: Spray sprinkler"
  },
  {
    classId: "EC011372",
    classGroup: "EG015610",
    artClassDesc: "Wall sprinkler",
    artClassVersion: 5,
    label: "EC011372: Wall sprinkler"
  },
  {
    classId: "EC011373",
    classGroup: "EG015610",
    artClassDesc: "Dry sprinkler",
    artClassVersion: 5,
    label: "EC011373: Dry sprinkler"
  },
  {
    classId: "EC011374",
    classGroup: "EG017610",
    artClassDesc: "Central mixing valve, hot tap water",
    artClassVersion: 6,
    label: "EC011374: Central mixing valve, hot tap water"
  },
  {
    classId: "EC011375",
    classGroup: "EG017610",
    artClassDesc: "Water tap",
    artClassVersion: 6,
    label: "EC011375: Water tap"
  },
  {
    classId: "EC011376",
    classGroup: "EG015610",
    artClassDesc: "Open and closed sprinklers",
    artClassVersion: 4,
    label: "EC011376: Open and closed sprinklers"
  },
  {
    classId: "EC011377",
    classGroup: "EG015610",
    artClassDesc: "Double sprinkler with alert",
    artClassVersion: 5,
    label: "EC011377: Double sprinkler with alert"
  },
  {
    classId: "EC011378",
    classGroup: "EG017610",
    artClassDesc: "Outdoor faucet",
    artClassVersion: 7,
    label: "EC011378: Outdoor faucet"
  },
  {
    classId: "EC011379",
    classGroup: "EG017620",
    artClassDesc: "Insert for tap",
    artClassVersion: 4,
    label: "EC011379: Insert for tap"
  },
  {
    classId: "EC011380",
    classGroup: "EG017620",
    artClassDesc: "Control element for sanitary tap",
    artClassVersion: 7,
    label: "EC011380: Control element for sanitary tap"
  },
  {
    classId: "EC011381",
    classGroup: "EG017960",
    artClassDesc: "Bathroom furniture set",
    artClassVersion: 3,
    label: "EC011381: Bathroom furniture set"
  },
  {
    classId: "EC011382",
    classGroup: "EG017960",
    artClassDesc: "Washbasin lower cupboard",
    artClassVersion: 5,
    label: "EC011382: Washbasin lower cupboard"
  },
  {
    classId: "EC011383",
    classGroup: "EG017640",
    artClassDesc: "Shower head",
    artClassVersion: 6,
    label: "EC011383: Shower head"
  },
  {
    classId: "EC011384",
    classGroup: "EG017960",
    artClassDesc: "Washbasin top cabinet",
    artClassVersion: 6,
    label: "EC011384: Washbasin top cabinet"
  },
  {
    classId: "EC011385",
    classGroup: "EG015560",
    artClassDesc: "Concentric flue gas/air supply form piece, 1 connection",
    artClassVersion: 6,
    label: "EC011385: Concentric flue gas/air supply form piece, 1 connection"
  },
  {
    classId: "EC011386",
    classGroup: "EG015530",
    artClassDesc: "Steel pipe",
    artClassVersion: 7,
    label: "EC011386: Steel pipe"
  },
  {
    classId: "EC011387",
    classGroup: "EG015610",
    artClassDesc: "Aboveground hydrant",
    artClassVersion: 3,
    label: "EC011387: Aboveground hydrant"
  },
  {
    classId: "EC011388",
    classGroup: "EG015560",
    artClassDesc: "Storm collar, round duct",
    artClassVersion: 3,
    label: "EC011388: Storm collar, round duct"
  },
  {
    classId: "EC011389",
    classGroup: "EG015530",
    artClassDesc: "Rainwater pipe square",
    artClassVersion: 5,
    label: "EC011389: Rainwater pipe square"
  },
  {
    classId: "EC011390",
    classGroup: "EG015210",
    artClassDesc: "Standing combination boiler, integrated burner",
    artClassVersion: 6,
    label: "EC011390: Standing combination boiler, integrated burner"
  },
  {
    classId: "EC011391",
    classGroup: "EG015210",
    artClassDesc: "Hot tap water boiler electric",
    artClassVersion: 7,
    label: "EC011391: Hot tap water boiler electric"
  },
  {
    classId: "EC011392",
    classGroup: "EG015210",
    artClassDesc: "Hot tap water boiler indirectly heated",
    artClassVersion: 7,
    label: "EC011392: Hot tap water boiler indirectly heated"
  },
  {
    classId: "EC011393",
    classGroup: "EG015210",
    artClassDesc: "Boiler, indirectly fired, with control system",
    artClassVersion: 4,
    label: "EC011393: Boiler, indirectly fired, with control system"
  },
  {
    classId: "EC011394",
    classGroup: "EG015210",
    artClassDesc: "Hot tap water heater, indirectly fired with control system",
    artClassVersion: 4,
    label: "EC011394: Hot tap water heater, indirectly fired with control system"
  },
  {
    classId: "EC011395",
    classGroup: "EG015210",
    artClassDesc: "Air heater, directly fired, suspended",
    artClassVersion: 4,
    label: "EC011395: Air heater, directly fired, suspended"
  },
  {
    classId: "EC011396",
    classGroup: "EG015210",
    artClassDesc: "Wall-mounted gas combination boiler",
    artClassVersion: 7,
    label: "EC011396: Wall-mounted gas combination boiler"
  },
  {
    classId: "EC011397",
    classGroup: "EG015210",
    artClassDesc: "Standing boiler with integrated burner",
    artClassVersion: 6,
    label: "EC011397: Standing boiler with integrated burner"
  },
  {
    classId: "EC011398",
    classGroup: "EG015110",
    artClassDesc: "Roof discharge funnel, bottom outlet",
    artClassVersion: 7,
    label: "EC011398: Roof discharge funnel, bottom outlet"
  },
  {
    classId: "EC011399",
    classGroup: "EG015510",
    artClassDesc: "Flexible connecting hose (metal)",
    artClassVersion: 5,
    label: "EC011399: Flexible connecting hose (metal)"
  },
  {
    classId: "EC011400",
    classGroup: "EG015120",
    artClassDesc: "Intermediate pre-expansion vessel",
    artClassVersion: 5,
    label: "EC011400: Intermediate pre-expansion vessel"
  },
  {
    classId: "EC011401",
    classGroup: "EG015620",
    artClassDesc: "Accessories/spare parts for electric two-way control valve",
    artClassVersion: 4,
    label: "EC011401: Accessories/spare parts for electric two-way control valve"
  },
  {
    classId: "EC011402",
    classGroup: "EG015560",
    artClassDesc: "Accessories/spare parts for kitchen extraction box",
    artClassVersion: 4,
    label: "EC011402: Accessories/spare parts for kitchen extraction box"
  },
  {
    classId: "EC011403",
    classGroup: "EG015220",
    artClassDesc: "Insulation tray for plate exchanger",
    artClassVersion: 3,
    label: "EC011403: Insulation tray for plate exchanger"
  },
  {
    classId: "EC011406",
    classGroup: "EG015630",
    artClassDesc: "Façade louver",
    artClassVersion: 5,
    label: "EC011406: Façade louver"
  },
  {
    classId: "EC011407",
    classGroup: "EG015620",
    artClassDesc: "Console for servomotor",
    artClassVersion: 2,
    label: "EC011407: Console for servomotor"
  },
  {
    classId: "EC011408",
    classGroup: "EG015610",
    artClassDesc: "Electrically controlled two-way control valve",
    artClassVersion: 8,
    label: "EC011408: Electrically controlled two-way control valve"
  },
  {
    classId: "EC011409",
    classGroup: "EG000049",
    artClassDesc: "One-piece bracket",
    artClassVersion: 7,
    label: "EC011409: One-piece bracket"
  },
  {
    classId: "EC011413",
    classGroup: "EG015560",
    artClassDesc: "Double-walled flue gas attachment, 2 connections",
    artClassVersion: 7,
    label: "EC011413: Double-walled flue gas attachment, 2 connections"
  },
  {
    classId: "EC011414",
    classGroup: "EG017610",
    artClassDesc: "Extension, urinal control unit",
    artClassVersion: 4,
    label: "EC011414: Extension, urinal control unit"
  },
  {
    classId: "EC011415",
    classGroup: "EG000049",
    artClassDesc: "Suspension eye, suspension strap",
    artClassVersion: 4,
    label: "EC011415: Suspension eye, suspension strap"
  },
  {
    classId: "EC011416",
    classGroup: "EG017610",
    artClassDesc: "Diverter tap",
    artClassVersion: 6,
    label: "EC011416: Diverter tap"
  },
  {
    classId: "EC011418",
    classGroup: "EG015610",
    artClassDesc: "Fire fighting valve",
    artClassVersion: 6,
    label: "EC011418: Fire fighting valve"
  },
  {
    classId: "EC011419",
    classGroup: "EG000026",
    artClassDesc: "Liquid flow sensor",
    artClassVersion: 5,
    label: "EC011419: Liquid flow sensor"
  },
  {
    classId: "EC011421",
    classGroup: "EG017620",
    artClassDesc: "Outlet for sanitary taps",
    artClassVersion: 6,
    label: "EC011421: Outlet for sanitary taps"
  },
  {
    classId: "EC011422",
    classGroup: "EG015920",
    artClassDesc: "Mounted transport wheel",
    artClassVersion: 5,
    label: "EC011422: Mounted transport wheel"
  },
  {
    classId: "EC011423",
    classGroup: "EG015240",
    artClassDesc: "Cooker hood central exhaust system",
    artClassVersion: 7,
    label: "EC011423: Cooker hood central exhaust system"
  },
  {
    classId: "EC011424",
    classGroup: "EG017610",
    artClassDesc: "Wall plate mixing tap",
    artClassVersion: 7,
    label: "EC011424: Wall plate mixing tap"
  },
  {
    classId: "EC011425",
    classGroup: "EG015560",
    artClassDesc: "Outlet bend, rectangular air duct",
    artClassVersion: 4,
    label: "EC011425: Outlet bend, rectangular air duct"
  },
  {
    classId: "EC011426",
    classGroup: "EG015610",
    artClassDesc: "Membrane valve",
    artClassVersion: 7,
    label: "EC011426: Membrane valve"
  },
  {
    classId: "EC011427",
    classGroup: "EG017110",
    artClassDesc: "Stoma Washbasin",
    artClassVersion: 1,
    label: "EC011427: Stoma Washbasin"
  },
  {
    classId: "EC011428",
    classGroup: "EG017210",
    artClassDesc: "Corner mounting piece for toilet support",
    artClassVersion: 3,
    label: "EC011428: Corner mounting piece for toilet support"
  },
  {
    classId: "EC011430",
    classGroup: "EG017110",
    artClassDesc: "Slop sink",
    artClassVersion: 7,
    label: "EC011430: Slop sink"
  },
  {
    classId: "EC011431",
    classGroup: "EG017610",
    artClassDesc: "Kitchen mixing tap",
    artClassVersion: 7,
    label: "EC011431: Kitchen mixing tap"
  },
  {
    classId: "EC011432",
    classGroup: "EG015110",
    artClassDesc: "Corner piece roof gutter",
    artClassVersion: 4,
    label: "EC011432: Corner piece roof gutter"
  },
  {
    classId: "EC011433",
    classGroup: "EG015540",
    artClassDesc: "Bracket piece, sleeveless cast iron",
    artClassVersion: 4,
    label: "EC011433: Bracket piece, sleeveless cast iron"
  },
  {
    classId: "EC011434",
    classGroup: "EG017970",
    artClassDesc: "Boarding sanitary installation wall system",
    artClassVersion: 4,
    label: "EC011434: Boarding sanitary installation wall system"
  },
  {
    classId: "EC011435",
    classGroup: "EG015610",
    artClassDesc: "Two way control valve",
    artClassVersion: 9,
    label: "EC011435: Two way control valve"
  },
  {
    classId: "EC011436",
    classGroup: "EG015850",
    artClassDesc: "Accessories/spare parts for domestic control set",
    artClassVersion: 5,
    label: "EC011436: Accessories/spare parts for domestic control set"
  },
  {
    classId: "EC011438",
    classGroup: "EG015850",
    artClassDesc: "Base plate for freely programmable controller",
    artClassVersion: 3,
    label: "EC011438: Base plate for freely programmable controller"
  },
  {
    classId: "EC011439",
    classGroup: "EG015560",
    artClassDesc: "Roof duct, round air duct",
    artClassVersion: 6,
    label: "EC011439: Roof duct, round air duct"
  },
  {
    classId: "EC011440",
    classGroup: "EG000006",
    artClassDesc: "Pipe duct",
    artClassVersion: 4,
    label: "EC011440: Pipe duct"
  },
  {
    classId: "EC011441",
    classGroup: "EG015530",
    artClassDesc: "Aluminium pipe",
    artClassVersion: 7,
    label: "EC011441: Aluminium pipe"
  },
  {
    classId: "EC011442",
    classGroup: "EG019710",
    artClassDesc: "Z-joint for mounting rail",
    artClassVersion: 4,
    label: "EC011442: Z-joint for mounting rail"
  },
  {
    classId: "EC011443",
    classGroup: "EG017110",
    artClassDesc: "Shower tray",
    artClassVersion: 8,
    label: "EC011443: Shower tray"
  },
  {
    classId: "EC011444",
    classGroup: "EG015550",
    artClassDesc: "Round air duct",
    artClassVersion: 9,
    label: "EC011444: Round air duct"
  },
  {
    classId: "EC011445",
    classGroup: "EG017110",
    artClassDesc: "Washbasin column",
    artClassVersion: 6,
    label: "EC011445: Washbasin column"
  },
  {
    classId: "EC011446",
    classGroup: "EG015610",
    artClassDesc: "Piston valve",
    artClassVersion: 7,
    label: "EC011446: Piston valve"
  },
  {
    classId: "EC011447",
    classGroup: "EG000049",
    artClassDesc: "Roof gutter bracket",
    artClassVersion: 5,
    label: "EC011447: Roof gutter bracket"
  },
  {
    classId: "EC011449",
    classGroup: "EG015410",
    artClassDesc: "Group distributor housing",
    artClassVersion: 3,
    label: "EC011449: Group distributor housing"
  },
  {
    classId: "EC011450",
    classGroup: "EG015240",
    artClassDesc: "Air curtain for cold application",
    artClassVersion: 5,
    label: "EC011450: Air curtain for cold application"
  },
  {
    classId: "EC011453",
    classGroup: "EG015810",
    artClassDesc: "Bimetal steam trap",
    artClassVersion: 7,
    label: "EC011453: Bimetal steam trap"
  },
  {
    classId: "EC011455",
    classGroup: "EG015560",
    artClassDesc: "T-piece rectangular air duct (90 degrees)",
    artClassVersion: 4,
    label: "EC011455: T-piece rectangular air duct (90 degrees)"
  },
  {
    classId: "EC011457",
    classGroup: "EG015810",
    artClassDesc: "Foot valve",
    artClassVersion: 7,
    label: "EC011457: Foot valve"
  },
  {
    classId: "EC011458",
    classGroup: "EG017970",
    artClassDesc: "Washbasin element for construction wall",
    artClassVersion: 4,
    label: "EC011458: Washbasin element for construction wall"
  },
  {
    classId: "EC011459",
    classGroup: "EG015210",
    artClassDesc: "Solar heater tank with collector",
    artClassVersion: 5,
    label: "EC011459: Solar heater tank with collector"
  },
  {
    classId: "EC011461",
    classGroup: "EG000049",
    artClassDesc: "Clamping strip, foundation bracket",
    artClassVersion: 4,
    label: "EC011461: Clamping strip, foundation bracket"
  },
  {
    classId: "EC011462",
    classGroup: "EG015610",
    artClassDesc: "Knife gate valve",
    artClassVersion: 7,
    label: "EC011462: Knife gate valve"
  },
  {
    classId: "EC011463",
    classGroup: "EG015610",
    artClassDesc: "Regulating valve",
    artClassVersion: 9,
    label: "EC011463: Regulating valve"
  },
  {
    classId: "EC011464",
    classGroup: "EG000026",
    artClassDesc: "Wind velocity sensor",
    artClassVersion: 6,
    label: "EC011464: Wind velocity sensor"
  },
  {
    classId: "EC011465",
    classGroup: "EG017210",
    artClassDesc: "Cover plate, height-adjustment system",
    artClassVersion: 3,
    label: "EC011465: Cover plate, height-adjustment system"
  },
  {
    classId: "EC011466",
    classGroup: "EG017970",
    artClassDesc: "Profile connection accessory sanitary",
    artClassVersion: 4,
    label: "EC011466: Profile connection accessory sanitary"
  },
  {
    classId: "EC011467",
    classGroup: "EG015610",
    artClassDesc: "Three-way screw valve",
    artClassVersion: 7,
    label: "EC011467: Three-way screw valve"
  },
  {
    classId: "EC011468",
    classGroup: "EG000026",
    artClassDesc: "Pressure differential sensor",
    artClassVersion: 7,
    label: "EC011468: Pressure differential sensor"
  },
  {
    classId: "EC011469",
    classGroup: "EG015810",
    artClassDesc: "Check valve",
    artClassVersion: 8,
    label: "EC011469: Check valve"
  },
  {
    classId: "EC011470",
    classGroup: "EG019510",
    artClassDesc: "Glass brick",
    artClassVersion: 2,
    label: "EC011470: Glass brick"
  },
  {
    classId: "EC011471",
    classGroup: "EG015210",
    artClassDesc: "Combined boiler with detachable burner",
    artClassVersion: 5,
    label: "EC011471: Combined boiler with detachable burner"
  },
  {
    classId: "EC011472",
    classGroup: "EG015560",
    artClassDesc: "Flat flange, round air duct",
    artClassVersion: 5,
    label: "EC011472: Flat flange, round air duct"
  },
  {
    classId: "EC011474",
    classGroup: "EG015560",
    artClassDesc: "Flanged sleeve, embedding duct",
    artClassVersion: 3,
    label: "EC011474: Flanged sleeve, embedding duct"
  },
  {
    classId: "EC011475",
    classGroup: "EG000050",
    artClassDesc: "Pull coupling for plastic tube",
    artClassVersion: 4,
    label: "EC011475: Pull coupling for plastic tube"
  },
  {
    classId: "EC011476",
    classGroup: "EG017550",
    artClassDesc: "Toilet flush pipe seal",
    artClassVersion: 3,
    label: "EC011476: Toilet flush pipe seal"
  },
  {
    classId: "EC011477",
    classGroup: "EG015510",
    artClassDesc: "Aluminium laminate hose, uninsulated with spiral",
    artClassVersion: 4,
    label: "EC011477: Aluminium laminate hose, uninsulated with spiral"
  },
  {
    classId: "EC011478",
    classGroup: "EG000026",
    artClassDesc: "Pressure transmitter",
    artClassVersion: 8,
    label: "EC011478: Pressure transmitter"
  },
  {
    classId: "EC011479",
    classGroup: "EG015210",
    artClassDesc: "Accessories/spare parts for air heaters",
    artClassVersion: 5,
    label: "EC011479: Accessories/spare parts for air heaters"
  },
  {
    classId: "EC011480",
    classGroup: "EG015850",
    artClassDesc: "Domestic control set",
    artClassVersion: 3,
    label: "EC011480: Domestic control set"
  },
  {
    classId: "EC011482",
    classGroup: "EG015110",
    artClassDesc: "Outlet intermediate piece roof gutter",
    artClassVersion: 2,
    label: "EC011482: Outlet intermediate piece roof gutter"
  },
  {
    classId: "EC011483",
    classGroup: "EG015110",
    artClassDesc: "Rain water discharge cistern",
    artClassVersion: 5,
    label: "EC011483: Rain water discharge cistern"
  },
  {
    classId: "EC011484",
    classGroup: "EG017210",
    artClassDesc: "Bottle for soap dispenser",
    artClassVersion: 2,
    label: "EC011484: Bottle for soap dispenser"
  },
  {
    classId: "EC011485",
    classGroup: "EG015240",
    artClassDesc: "Accessories/spare parts for air curtain",
    artClassVersion: 5,
    label: "EC011485: Accessories/spare parts for air curtain"
  },
  {
    classId: "EC011486",
    classGroup: "EG000026",
    artClassDesc: "Wind direction sensor",
    artClassVersion: 6,
    label: "EC011486: Wind direction sensor"
  },
  {
    classId: "EC011487",
    classGroup: "EG015850",
    artClassDesc: "Free programmable controller",
    artClassVersion: 7,
    label: "EC011487: Free programmable controller"
  },
  {
    classId: "EC011488",
    classGroup: "EG000060",
    artClassDesc: "Protective tube for plastic pipe system",
    artClassVersion: 4,
    label: "EC011488: Protective tube for plastic pipe system"
  },
  {
    classId: "EC011490",
    classGroup: "EG015810",
    artClassDesc: "Pressure difference overflow controller",
    artClassVersion: 8,
    label: "EC011490: Pressure difference overflow controller"
  },
  {
    classId: "EC011491",
    classGroup: "EG015260",
    artClassDesc: "Electrical accessories/spare parts for pumps",
    artClassVersion: 6,
    label: "EC011491: Electrical accessories/spare parts for pumps"
  },
  {
    classId: "EC011492",
    classGroup: "EG017210",
    artClassDesc: "Bath shelf",
    artClassVersion: 6,
    label: "EC011492: Bath shelf"
  },
  {
    classId: "EC011493",
    classGroup: "EG017210",
    artClassDesc: "Shower curtain rail",
    artClassVersion: 4,
    label: "EC011493: Shower curtain rail"
  },
  {
    classId: "EC011494",
    classGroup: "EG015210",
    artClassDesc: "Accessories/spare parts for boilers",
    artClassVersion: 5,
    label: "EC011494: Accessories/spare parts for boilers"
  },
  {
    classId: "EC011495",
    classGroup: "EG015560",
    artClassDesc: "Rectangular side connection, embedding duct",
    artClassVersion: 4,
    label: "EC011495: Rectangular side connection, embedding duct"
  },
  {
    classId: "EC011496",
    classGroup: "EG015620",
    artClassDesc: "Accessories/spare parts for butterfly valve",
    artClassVersion: 6,
    label: "EC011496: Accessories/spare parts for butterfly valve"
  },
  {
    classId: "EC011497",
    classGroup: "EG015240",
    artClassDesc: "Ventilation valve",
    artClassVersion: 6,
    label: "EC011497: Ventilation valve"
  },
  {
    classId: "EC011500",
    classGroup: "EG015580",
    artClassDesc: "Rain cover",
    artClassVersion: 3,
    label: "EC011500: Rain cover"
  },
  {
    classId: "EC011501",
    classGroup: "EG000026",
    artClassDesc: "Frost temperature sensor, active",
    artClassVersion: 6,
    label: "EC011501: Frost temperature sensor, active"
  },
  {
    classId: "EC011502",
    classGroup: "EG015810",
    artClassDesc: "Combined measuring and control valve",
    artClassVersion: 6,
    label: "EC011502: Combined measuring and control valve"
  },
  {
    classId: "EC011503",
    classGroup: "EG015810",
    artClassDesc: "Spring-loaded pressure relief valve",
    artClassVersion: 8,
    label: "EC011503: Spring-loaded pressure relief valve"
  },
  {
    classId: "EC011504",
    classGroup: "EG017210",
    artClassDesc: "Glass/soap holder",
    artClassVersion: 6,
    label: "EC011504: Glass/soap holder"
  },
  {
    classId: "EC011505",
    classGroup: "EG017130",
    artClassDesc: "Double wall/built-in element slop sink",
    artClassVersion: 5,
    label: "EC011505: Double wall/built-in element slop sink"
  },
  {
    classId: "EC011508",
    classGroup: "EG015540",
    artClassDesc: "Clamp strip coupling",
    artClassVersion: 6,
    label: "EC011508: Clamp strip coupling"
  },
  {
    classId: "EC011510",
    classGroup: "EG000026",
    artClassDesc: "Room temperature sensor, active",
    artClassVersion: 6,
    label: "EC011510: Room temperature sensor, active"
  },
  {
    classId: "EC011511",
    classGroup: "EG017210",
    artClassDesc: "Collection tray/dish",
    artClassVersion: 2,
    label: "EC011511: Collection tray/dish"
  },
  {
    classId: "EC011512",
    classGroup: "EG015560",
    artClassDesc: "Cover rosette, flue gas/air supply pipe",
    artClassVersion: 5,
    label: "EC011512: Cover rosette, flue gas/air supply pipe"
  },
  {
    classId: "EC011513",
    classGroup: "EG017210",
    artClassDesc: "Glass holder",
    artClassVersion: 6,
    label: "EC011513: Glass holder"
  },
  {
    classId: "EC011514",
    classGroup: "EG017130",
    artClassDesc: "Boarding set for double wall/built-in element",
    artClassVersion: 3,
    label: "EC011514: Boarding set for double wall/built-in element"
  },
  {
    classId: "EC011516",
    classGroup: "EG015610",
    artClassDesc: "Thermostatic flow control valve, hot tap water",
    artClassVersion: 4,
    label: "EC011516: Thermostatic flow control valve, hot tap water"
  },
  {
    classId: "EC011518",
    classGroup: "EG015560",
    artClassDesc: "T-Y-piece embedding duct",
    artClassVersion: 4,
    label: "EC011518: T-Y-piece embedding duct"
  },
  {
    classId: "EC011519",
    classGroup: "EG017140",
    artClassDesc: "Electric lifting system flushing reservoir",
    artClassVersion: 2,
    label: "EC011519: Electric lifting system flushing reservoir"
  },
  {
    classId: "EC011522",
    classGroup: "EG019710",
    artClassDesc: "U-joint for mounting rail",
    artClassVersion: 5,
    label: "EC011522: U-joint for mounting rail"
  },
  {
    classId: "EC011523",
    classGroup: "EG017140",
    artClassDesc: "Electric control flushing system",
    artClassVersion: 4,
    label: "EC011523: Electric control flushing system"
  },
  {
    classId: "EC011524",
    classGroup: "EG019490",
    artClassDesc: "Cover plate for vertical radiator base console",
    artClassVersion: 3,
    label: "EC011524: Cover plate for vertical radiator base console"
  },
  {
    classId: "EC011525",
    classGroup: "EG000049",
    artClassDesc: "Connecting element, carrying pipe section",
    artClassVersion: 4,
    label: "EC011525: Connecting element, carrying pipe section"
  },
  {
    classId: "EC011526",
    classGroup: "EG017210",
    artClassDesc: "Adjusted toilet seat (disabled sanitary)",
    artClassVersion: 4,
    label: "EC011526: Adjusted toilet seat (disabled sanitary)"
  },
  {
    classId: "EC011527",
    classGroup: "EG015550",
    artClassDesc: "Double-walled flue gas pipe",
    artClassVersion: 7,
    label: "EC011527: Double-walled flue gas pipe"
  },
  {
    classId: "EC011528",
    classGroup: "EG015810",
    artClassDesc: "Vacuum breaker for steam",
    artClassVersion: 7,
    label: "EC011528: Vacuum breaker for steam"
  },
  {
    classId: "EC011529",
    classGroup: "EG015910",
    artClassDesc: "Spiral-wound flange gasket (DIN)",
    artClassVersion: 6,
    label: "EC011529: Spiral-wound flange gasket (DIN)"
  },
  {
    classId: "EC011531",
    classGroup: "EG015560",
    artClassDesc: "Chimney support",
    artClassVersion: 2,
    label: "EC011531: Chimney support"
  },
  {
    classId: "EC011533",
    classGroup: "EG015530",
    artClassDesc: "Copper pipe",
    artClassVersion: 9,
    label: "EC011533: Copper pipe"
  },
  {
    classId: "EC011534",
    classGroup: "EG015260",
    artClassDesc: "Cabinet fan",
    artClassVersion: 4,
    label: "EC011534: Cabinet fan"
  },
  {
    classId: "EC011535",
    classGroup: "EG017640",
    artClassDesc: "Shower hose",
    artClassVersion: 7,
    label: "EC011535: Shower hose"
  },
  {
    classId: "EC011537",
    classGroup: "EG017140",
    artClassDesc: "Push-button surface-mounted flushing reservoir",
    artClassVersion: 5,
    label: "EC011537: Push-button surface-mounted flushing reservoir"
  },
  {
    classId: "EC011538",
    classGroup: "EG015560",
    artClassDesc: "Chimney wind support",
    artClassVersion: 2,
    label: "EC011538: Chimney wind support"
  },
  {
    classId: "EC011539",
    classGroup: "EG017620",
    artClassDesc: "Tap-raising ring",
    artClassVersion: 6,
    label: "EC011539: Tap-raising ring"
  },
  {
    classId: "EC011540",
    classGroup: "EG015610",
    artClassDesc: "Three-way ball valve",
    artClassVersion: 8,
    label: "EC011540: Three-way ball valve"
  },
  {
    classId: "EC011541",
    classGroup: "EG015850",
    artClassDesc: "Display/operator panel",
    artClassVersion: 6,
    label: "EC011541: Display/operator panel"
  },
  {
    classId: "EC011542",
    classGroup: "EG015560",
    artClassDesc: "Chimney renovation set",
    artClassVersion: 3,
    label: "EC011542: Chimney renovation set"
  },
  {
    classId: "EC011543",
    classGroup: "EG015540",
    artClassDesc: "Radiator valve pipe bend",
    artClassVersion: 3,
    label: "EC011543: Radiator valve pipe bend"
  },
  {
    classId: "EC011544",
    classGroup: "EG015560",
    artClassDesc: "Flue gas wall duct",
    artClassVersion: 6,
    label: "EC011544: Flue gas wall duct"
  },
  {
    classId: "EC011545",
    classGroup: "EG015240",
    artClassDesc: "Anemostat, rectangular front",
    artClassVersion: 5,
    label: "EC011545: Anemostat, rectangular front"
  },
  {
    classId: "EC011546",
    classGroup: "EG017620",
    artClassDesc: "Tap rosette",
    artClassVersion: 6,
    label: "EC011546: Tap rosette"
  },
  {
    classId: "EC011547",
    classGroup: "EG015210",
    artClassDesc: "Combined preburner",
    artClassVersion: 3,
    label: "EC011547: Combined preburner"
  },
  {
    classId: "EC011548",
    classGroup: "EG017140",
    artClassDesc: "Pneumatic control flushing system",
    artClassVersion: 5,
    label: "EC011548: Pneumatic control flushing system"
  },
  {
    classId: "EC011549",
    classGroup: "EG015810",
    artClassDesc: "Pressure difference controller",
    artClassVersion: 6,
    label: "EC011549: Pressure difference controller"
  },
  {
    classId: "EC011550",
    classGroup: "EG017110",
    artClassDesc: "Washbasin",
    artClassVersion: 7,
    label: "EC011550: Washbasin"
  },
  {
    classId: "EC011551",
    classGroup: "EG015560",
    artClassDesc: "Roof boarding bracket",
    artClassVersion: 2,
    label: "EC011551: Roof boarding bracket"
  },
  {
    classId: "EC011552",
    classGroup: "EG015560",
    artClassDesc: "Grid shoe for air duct",
    artClassVersion: 5,
    label: "EC011552: Grid shoe for air duct"
  },
  {
    classId: "EC011553",
    classGroup: "EG019760",
    artClassDesc: "Fire sleeve for pipe",
    artClassVersion: 4,
    label: "EC011553: Fire sleeve for pipe"
  },
  {
    classId: "EC011554",
    classGroup: "EG015810",
    artClassDesc: "Thermodynamic stream trap",
    artClassVersion: 7,
    label: "EC011554: Thermodynamic stream trap"
  },
  {
    classId: "EC011555",
    classGroup: "EG000049",
    artClassDesc: "Rail console set",
    artClassVersion: 4,
    label: "EC011555: Rail console set"
  },
  {
    classId: "EC011556",
    classGroup: "EG015510",
    artClassDesc: "Aluminium laminate hose, insulated with spiral",
    artClassVersion: 5,
    label: "EC011556: Aluminium laminate hose, insulated with spiral"
  },
  {
    classId: "EC011557",
    classGroup: "EG000049",
    artClassDesc: "Mounting head, ribbed fixing strap",
    artClassVersion: 5,
    label: "EC011557: Mounting head, ribbed fixing strap"
  },
  {
    classId: "EC011558",
    classGroup: "EG017110",
    artClassDesc: "Wash-tub",
    artClassVersion: 3,
    label: "EC011558: Wash-tub"
  },
  {
    classId: "EC011559",
    classGroup: "EG019490",
    artClassDesc: "Convector base",
    artClassVersion: 3,
    label: "EC011559: Convector base"
  },
  {
    classId: "EC011560",
    classGroup: "EG000060",
    artClassDesc: "Pipe rosette",
    artClassVersion: 7,
    label: "EC011560: Pipe rosette"
  },
  {
    classId: "EC011561",
    classGroup: "EG000049",
    artClassDesc: "Clamping slide, foundation bracket",
    artClassVersion: 5,
    label: "EC011561: Clamping slide, foundation bracket"
  },
  {
    classId: "EC011564",
    classGroup: "EG015260",
    artClassDesc: "Thyristor power controller",
    artClassVersion: 3,
    label: "EC011564: Thyristor power controller"
  },
  {
    classId: "EC011565",
    classGroup: "EG017610",
    artClassDesc: "Mechanical toilet flusher",
    artClassVersion: 6,
    label: "EC011565: Mechanical toilet flusher"
  },
  {
    classId: "EC011566",
    classGroup: "EG017640",
    artClassDesc: "Body shower (emergency shower)",
    artClassVersion: 5,
    label: "EC011566: Body shower (emergency shower)"
  },
  {
    classId: "EC011567",
    classGroup: "EG015620",
    artClassDesc: "Accessories/spare parts for control valve",
    artClassVersion: 5,
    label: "EC011567: Accessories/spare parts for control valve"
  },
  {
    classId: "EC011568",
    classGroup: "EG000026",
    artClassDesc: "Sun sensor, active",
    artClassVersion: 4,
    label: "EC011568: Sun sensor, active"
  },
  {
    classId: "EC011571",
    classGroup: "EG000049",
    artClassDesc: "Accessories/spare parts for universal suspension system",
    artClassVersion: 4,
    label: "EC011571: Accessories/spare parts for universal suspension system"
  },
  {
    classId: "EC011572",
    classGroup: "EG000026",
    artClassDesc: "Window temperature sensor",
    artClassVersion: 3,
    label: "EC011572: Window temperature sensor"
  },
  {
    classId: "EC011573",
    classGroup: "EG015230",
    artClassDesc: "Air-conditioning split system – single split, complete",
    artClassVersion: 5,
    label: "EC011573: Air-conditioning split system – single split, complete"
  },
  {
    classId: "EC011579",
    classGroup: "EG015230",
    artClassDesc: "Air-conditioning split system – multi-split, complete",
    artClassVersion: 5,
    label: "EC011579: Air-conditioning split system – multi-split, complete"
  },
  {
    classId: "EC011580",
    classGroup: "EG015230",
    artClassDesc: "Air-conditioning split system – indoor unit (evaporator)",
    artClassVersion: 6,
    label: "EC011580: Air-conditioning split system – indoor unit (evaporator)"
  },
  {
    classId: "EC011583",
    classGroup: "EG015230",
    artClassDesc: "Air-conditioning split system – single-split condensing unit, air-cooled",
    artClassVersion: 5,
    label: "EC011583: Air-conditioning split system – single-split condensing unit, air-cooled"
  },
  {
    classId: "EC011584",
    classGroup: "EG015230",
    artClassDesc: "Air-conditioning system – multi-split condensing unit, air-cooled",
    artClassVersion: 4,
    label: "EC011584: Air-conditioning system – multi-split condensing unit, air-cooled"
  },
  {
    classId: "EC011587",
    classGroup: "EG015230",
    artClassDesc: "Air-conditioning split system – single-split condensing unit, water-cooled",
    artClassVersion: 6,
    label: "EC011587: Air-conditioning split system – single-split condensing unit, water-cooled"
  },
  {
    classId: "EC011590",
    classGroup: "EG000049",
    artClassDesc: "Universal suspension system",
    artClassVersion: 3,
    label: "EC011590: Universal suspension system"
  },
  {
    classId: "EC011594",
    classGroup: "EG017610",
    artClassDesc: "Electronic toilet flusher",
    artClassVersion: 5,
    label: "EC011594: Electronic toilet flusher"
  },
  {
    classId: "EC011595",
    classGroup: "EG017210",
    artClassDesc: "Accessories/spare parts for shower curtain",
    artClassVersion: 3,
    label: "EC011595: Accessories/spare parts for shower curtain"
  },
  {
    classId: "EC011596",
    classGroup: "EG017210",
    artClassDesc: "Handle combination system, adapted sanitary fittings",
    artClassVersion: 2,
    label: "EC011596: Handle combination system, adapted sanitary fittings"
  },
  {
    classId: "EC011597",
    classGroup: "EG017550",
    artClassDesc: "Inlet-/drain set disabled sanitary",
    artClassVersion: 3,
    label: "EC011597: Inlet-/drain set disabled sanitary"
  },
  {
    classId: "EC011598",
    classGroup: "EG019910",
    artClassDesc: "V-snare",
    artClassVersion: 3,
    label: "EC011598: V-snare"
  },
  {
    classId: "EC011599",
    classGroup: "EG015210",
    artClassDesc: "Duct heater, electric",
    artClassVersion: 6,
    label: "EC011599: Duct heater, electric"
  },
  {
    classId: "EC011600",
    classGroup: "EG015210",
    artClassDesc: "Duct heater indirectly heated",
    artClassVersion: 5,
    label: "EC011600: Duct heater indirectly heated"
  },
  {
    classId: "EC011601",
    classGroup: "EG015210",
    artClassDesc: "Accessories/spare parts for duct heater",
    artClassVersion: 3,
    label: "EC011601: Accessories/spare parts for duct heater"
  },
  {
    classId: "EC011602",
    classGroup: "EG015230",
    artClassDesc: "Air-conditioning conduit set",
    artClassVersion: 4,
    label: "EC011602: Air-conditioning conduit set"
  },
  {
    classId: "EC011603",
    classGroup: "EG015230",
    artClassDesc: "Accessories/spare parts for airconditioning",
    artClassVersion: 4,
    label: "EC011603: Accessories/spare parts for airconditioning"
  },
  {
    classId: "EC011604",
    classGroup: "EG015230",
    artClassDesc: "Portable air conditioner",
    artClassVersion: 4,
    label: "EC011604: Portable air conditioner"
  },
  {
    classId: "EC011605",
    classGroup: "EG015230",
    artClassDesc: "Window air conditioner",
    artClassVersion: 5,
    label: "EC011605: Window air conditioner"
  },
  {
    classId: "EC011606",
    classGroup: "EG015260",
    artClassDesc: "Accessories/spare parts for ventilator",
    artClassVersion: 6,
    label: "EC011606: Accessories/spare parts for ventilator"
  },
  {
    classId: "EC011607",
    classGroup: "EG018110",
    artClassDesc: "Accessories/spare parts for thermal pipe insulation",
    artClassVersion: 3,
    label: "EC011607: Accessories/spare parts for thermal pipe insulation"
  },
  {
    classId: "EC011608",
    classGroup: "EG015400",
    artClassDesc: "Open central heating/cooling distributor",
    artClassVersion: 4,
    label: "EC011608: Open central heating/cooling distributor"
  },
  {
    classId: "EC011609",
    classGroup: "EG017110",
    artClassDesc: "Bath",
    artClassVersion: 5,
    label: "EC011609: Bath"
  },
  {
    classId: "EC011610",
    classGroup: "EG017110",
    artClassDesc: "System bath",
    artClassVersion: 6,
    label: "EC011610: System bath"
  },
  {
    classId: "EC011612",
    classGroup: "EG017640",
    artClassDesc: "Accessories/spare parts for emergency shower",
    artClassVersion: 3,
    label: "EC011612: Accessories/spare parts for emergency shower"
  },
  {
    classId: "EC011613",
    classGroup: "EG017640",
    artClassDesc: "Shower column/panel",
    artClassVersion: 5,
    label: "EC011613: Shower column/panel"
  },
  {
    classId: "EC011614",
    classGroup: "EG017610",
    artClassDesc: "Large kitchen mixing tap",
    artClassVersion: 5,
    label: "EC011614: Large kitchen mixing tap"
  },
  {
    classId: "EC011615",
    classGroup: "EG017610",
    artClassDesc: "Large kitchen tap",
    artClassVersion: 5,
    label: "EC011615: Large kitchen tap"
  },
  {
    classId: "EC011616",
    classGroup: "EG017610",
    artClassDesc: "Accessories/spare parts for sanitary taps",
    artClassVersion: 3,
    label: "EC011616: Accessories/spare parts for sanitary taps"
  },
  {
    classId: "EC011617",
    classGroup: "EG015260",
    artClassDesc: "Tail pump",
    artClassVersion: 4,
    label: "EC011617: Tail pump"
  },
  {
    classId: "EC011618",
    classGroup: "EG015230",
    artClassDesc: "Cooling unit for heat recovery unit",
    artClassVersion: 4,
    label: "EC011618: Cooling unit for heat recovery unit"
  },
  {
    classId: "EC011619",
    classGroup: "EG015610",
    artClassDesc: "Accessories/spare parts for needle valve",
    artClassVersion: 3,
    label: "EC011619: Accessories/spare parts for needle valve"
  },
  {
    classId: "EC011620",
    classGroup: "EG015610",
    artClassDesc: "Bottom valve",
    artClassVersion: 4,
    label: "EC011620: Bottom valve"
  },
  {
    classId: "EC011621",
    classGroup: "EG017210",
    artClassDesc: "Toilet lift",
    artClassVersion: 2,
    label: "EC011621: Toilet lift"
  },
  {
    classId: "EC011622",
    classGroup: "EG017210",
    artClassDesc: "Accessories/spare parts for toilet lift",
    artClassVersion: 3,
    label: "EC011622: Accessories/spare parts for toilet lift"
  },
  {
    classId: "EC011623",
    classGroup: "EG015620",
    artClassDesc: "Hydraulic actuator for valve",
    artClassVersion: 3,
    label: "EC011623: Hydraulic actuator for valve"
  },
  {
    classId: "EC011624",
    classGroup: "EG000026",
    artClassDesc: "Conductivity sensor",
    artClassVersion: 4,
    label: "EC011624: Conductivity sensor"
  },
  {
    classId: "EC011625",
    classGroup: "EG015620",
    artClassDesc: "Chain wheel",
    artClassVersion: 1,
    label: "EC011625: Chain wheel"
  },
  {
    classId: "EC011627",
    classGroup: "EG015610",
    artClassDesc: "Line blind valve",
    artClassVersion: 4,
    label: "EC011627: Line blind valve"
  },
  {
    classId: "EC011628",
    classGroup: "EG015170",
    artClassDesc: "Humidifier (steam)",
    artClassVersion: 2,
    label: "EC011628: Humidifier (steam)"
  },
  {
    classId: "EC011629",
    classGroup: "EG015620",
    artClassDesc: "Build-on set for valve",
    artClassVersion: 2,
    label: "EC011629: Build-on set for valve"
  },
  {
    classId: "EC011630",
    classGroup: "EG015810",
    artClassDesc: "Condensate pump steam operated",
    artClassVersion: 3,
    label: "EC011630: Condensate pump steam operated"
  },
  {
    classId: "EC011631",
    classGroup: "EG019720",
    artClassDesc: "Mica plate",
    artClassVersion: 4,
    label: "EC011631: Mica plate"
  },
  {
    classId: "EC011632",
    classGroup: "EG015620",
    artClassDesc: "Pneumatic cylinder",
    artClassVersion: 3,
    label: "EC011632: Pneumatic cylinder"
  },
  {
    classId: "EC011633",
    classGroup: "EG015850",
    artClassDesc: "Blowdown controller",
    artClassVersion: 3,
    label: "EC011633: Blowdown controller"
  },
  {
    classId: "EC011635",
    classGroup: "EG015610",
    artClassDesc: "Steam water mixer",
    artClassVersion: 4,
    label: "EC011635: Steam water mixer"
  },
  {
    classId: "EC011636",
    classGroup: "EG014000",
    artClassDesc: "Deck fill cap",
    artClassVersion: 4,
    label: "EC011636: Deck fill cap"
  },
  {
    classId: "EC011637",
    classGroup: "EG014000",
    artClassDesc: "Manhole cover",
    artClassVersion: 3,
    label: "EC011637: Manhole cover"
  },
  {
    classId: "EC011638",
    classGroup: "EG015910",
    artClassDesc: "Mica flange gasket",
    artClassVersion: 3,
    label: "EC011638: Mica flange gasket"
  },
  {
    classId: "EC011639",
    classGroup: "EG016760",
    artClassDesc: "Signal automaton shipping",
    artClassVersion: 1,
    label: "EC011639: Signal automaton shipping"
  },
  {
    classId: "EC011640",
    classGroup: "EG015830",
    artClassDesc: "Inclinometer",
    artClassVersion: 4,
    label: "EC011640: Inclinometer"
  },
  {
    classId: "EC011641",
    classGroup: "EG016740",
    artClassDesc: "Sound reception system shipping",
    artClassVersion: 1,
    label: "EC011641: Sound reception system shipping"
  },
  {
    classId: "EC011642",
    classGroup: "EG015620",
    artClassDesc: "Steam injector",
    artClassVersion: 3,
    label: "EC011642: Steam injector"
  },
  {
    classId: "EC011643",
    classGroup: "EG014000",
    artClassDesc: "Accessories/spare parts for deck cap/pipe passage",
    artClassVersion: 4,
    label: "EC011643: Accessories/spare parts for deck cap/pipe passage"
  },
  {
    classId: "EC011644",
    classGroup: "EG015560",
    artClassDesc: "Boiler connection element for heat recovery unit for single family house",
    artClassVersion: 1,
    label: "EC011644: Boiler connection element for heat recovery unit for single family house"
  },
  {
    classId: "EC011645",
    classGroup: "EG015560",
    artClassDesc: "Concentrical connection set flue gas/air supply",
    artClassVersion: 4,
    label: "EC011645: Concentrical connection set flue gas/air supply"
  },
  {
    classId: "EC011646",
    classGroup: "EG000044",
    artClassDesc: "Accessories/spare parts for flue gas analyser",
    artClassVersion: 3,
    label: "EC011646: Accessories/spare parts for flue gas analyser"
  },
  {
    classId: "EC011647",
    classGroup: "EG015610",
    artClassDesc: "Accessories/spare parts for bottom valve",
    artClassVersion: 3,
    label: "EC011647: Accessories/spare parts for bottom valve"
  },
  {
    classId: "EC011648",
    classGroup: "EG015610",
    artClassDesc: "Accessories/spare parts for fire fighting valve",
    artClassVersion: 3,
    label: "EC011648: Accessories/spare parts for fire fighting valve"
  },
  {
    classId: "EC011649",
    classGroup: "EG015810",
    artClassDesc: "Accessories/spare parts for steam trap",
    artClassVersion: 3,
    label: "EC011649: Accessories/spare parts for steam trap"
  },
  {
    classId: "EC011650",
    classGroup: "EG000026",
    artClassDesc: "Accessories/spare parts for pressure sensor",
    artClassVersion: 4,
    label: "EC011650: Accessories/spare parts for pressure sensor"
  },
  {
    classId: "EC011652",
    classGroup: "EG015140",
    artClassDesc: "Accessories/spare parts for liquids filter",
    artClassVersion: 5,
    label: "EC011652: Accessories/spare parts for liquids filter"
  },
  {
    classId: "EC011653",
    classGroup: "EG016740",
    artClassDesc: "Accessories/spare parts for sound reception system shipping",
    artClassVersion: 3,
    label: "EC011653: Accessories/spare parts for sound reception system shipping"
  },
  {
    classId: "EC011654",
    classGroup: "EG015620",
    artClassDesc: "Accessories/spare parts for hydraulic actuator for valve",
    artClassVersion: 3,
    label: "EC011654: Accessories/spare parts for hydraulic actuator for valve"
  },
  {
    classId: "EC011655",
    classGroup: "EG015830",
    artClassDesc: "Accessories/spare parts for inclinometer",
    artClassVersion: 3,
    label: "EC011655: Accessories/spare parts for inclinometer"
  },
  {
    classId: "EC011656",
    classGroup: "EG015610",
    artClassDesc: "Accessories/spare parts for regulating valve",
    artClassVersion: 3,
    label: "EC011656: Accessories/spare parts for regulating valve"
  },
  {
    classId: "EC011657",
    classGroup: "EG015610",
    artClassDesc: "Accessories/spare parts for globe valve",
    artClassVersion: 5,
    label: "EC011657: Accessories/spare parts for globe valve"
  },
  {
    classId: "EC011658",
    classGroup: "EG015520",
    artClassDesc: "Accessories/spare parts for clamp coupling",
    artClassVersion: 3,
    label: "EC011658: Accessories/spare parts for clamp coupling"
  },
  {
    classId: "EC011659",
    classGroup: "EG015610",
    artClassDesc: "Accessories/spare parts for ball valve",
    artClassVersion: 4,
    label: "EC011659: Accessories/spare parts for ball valve"
  },
  {
    classId: "EC011660",
    classGroup: "EG015170",
    artClassDesc: "Accessories/spare parts for humidifier",
    artClassVersion: 4,
    label: "EC011660: Accessories/spare parts for humidifier"
  },
  {
    classId: "EC011661",
    classGroup: "EG015610",
    artClassDesc: "Accessories/spare parts for solenoid valve",
    artClassVersion: 4,
    label: "EC011661: Accessories/spare parts for solenoid valve"
  },
  {
    classId: "EC011662",
    classGroup: "EG015830",
    artClassDesc: "Accessories/spare parts for pressure gauge",
    artClassVersion: 4,
    label: "EC011662: Accessories/spare parts for pressure gauge"
  },
  {
    classId: "EC011663",
    classGroup: "EG015610",
    artClassDesc: "Accessories/spare parts for manifold valve",
    artClassVersion: 4,
    label: "EC011663: Accessories/spare parts for manifold valve"
  },
  {
    classId: "EC011665",
    classGroup: "EG000026",
    artClassDesc: "Accessories/spare parts for liquid level sensor",
    artClassVersion: 4,
    label: "EC011665: Accessories/spare parts for liquid level sensor"
  },
  {
    classId: "EC011666",
    classGroup: "EG015840",
    artClassDesc: "Accessories/spare parts for level switch",
    artClassVersion: 4,
    label: "EC011666: Accessories/spare parts for level switch"
  },
  {
    classId: "EC011667",
    classGroup: "EG015810",
    artClassDesc: "Accessories/spare parts for air vent",
    artClassVersion: 4,
    label: "EC011667: Accessories/spare parts for air vent"
  },
  {
    classId: "EC011668",
    classGroup: "EG015540",
    artClassDesc: "Accessories/spare parts for fittings",
    artClassVersion: 3,
    label: "EC011668: Accessories/spare parts for fittings"
  },
  {
    classId: "EC011669",
    classGroup: "EG015830",
    artClassDesc: "Accessories/spare parts for gauge",
    artClassVersion: 4,
    label: "EC011669: Accessories/spare parts for gauge"
  },
  {
    classId: "EC011670",
    classGroup: "EG015610",
    artClassDesc: "Accessories/spare parts for compressed air valve",
    artClassVersion: 3,
    label: "EC011670: Accessories/spare parts for compressed air valve"
  },
  {
    classId: "EC011671",
    classGroup: "EG015610",
    artClassDesc: "Accessories/spare parts for plate slide valve",
    artClassVersion: 4,
    label: "EC011671: Accessories/spare parts for plate slide valve"
  },
  {
    classId: "EC011672",
    classGroup: "EG015610",
    artClassDesc: "Accessories/spare parts for plug valve",
    artClassVersion: 3,
    label: "EC011672: Accessories/spare parts for plug valve"
  },
  {
    classId: "EC011673",
    classGroup: "EG015610",
    artClassDesc: "Accessories/spare parts for piston valve",
    artClassVersion: 3,
    label: "EC011673: Accessories/spare parts for piston valve"
  },
  {
    classId: "EC011674",
    classGroup: "EG015620",
    artClassDesc: "Accessories/spare parts for pneumatic servomotor for valves",
    artClassVersion: 4,
    label: "EC011674: Accessories/spare parts for pneumatic servomotor for valves"
  },
  {
    classId: "EC011675",
    classGroup: "EG015870",
    artClassDesc: "Accessories/spare parts for pressure reduction valve",
    artClassVersion: 4,
    label: "EC011675: Accessories/spare parts for pressure reduction valve"
  },
  {
    classId: "EC011676",
    classGroup: "EG015620",
    artClassDesc: "Accessories/spare parts for gate valve",
    artClassVersion: 4,
    label: "EC011676: Accessories/spare parts for gate valve"
  },
  {
    classId: "EC011677",
    classGroup: "EG016760",
    artClassDesc: "Accessories/spare parts for signal automaton shipping",
    artClassVersion: 3,
    label: "EC011677: Accessories/spare parts for signal automaton shipping"
  },
  {
    classId: "EC011678",
    classGroup: "EG016760",
    artClassDesc: "Accessories/spare parts for signal horn shipping",
    artClassVersion: 3,
    label: "EC011678: Accessories/spare parts for signal horn shipping"
  },
  {
    classId: "EC011679",
    classGroup: "EG015620",
    artClassDesc: "Accessories/spare parts for blow-off valve",
    artClassVersion: 3,
    label: "EC011679: Accessories/spare parts for blow-off valve"
  },
  {
    classId: "EC011680",
    classGroup: "EG015620",
    artClassDesc: "Accessories/spare parts for steam/water mixer",
    artClassVersion: 3,
    label: "EC011680: Accessories/spare parts for steam/water mixer"
  },
  {
    classId: "EC011681",
    classGroup: "EG015830",
    artClassDesc: "Accessories/spare parts for thermometer",
    artClassVersion: 4,
    label: "EC011681: Accessories/spare parts for thermometer"
  },
  {
    classId: "EC011682",
    classGroup: "EG015830",
    artClassDesc: "Accessories/spare parts for liquid flow meter",
    artClassVersion: 5,
    label: "EC011682: Accessories/spare parts for liquid flow meter"
  },
  {
    classId: "EC011683",
    classGroup: "EG015620",
    artClassDesc: "Accessories/spare parts for float valve",
    artClassVersion: 3,
    label: "EC011683: Accessories/spare parts for float valve"
  },
  {
    classId: "EC011684",
    classGroup: "EG015870",
    artClassDesc: "Accessories/spare parts for foot valve",
    artClassVersion: 3,
    label: "EC011684: Accessories/spare parts for foot valve"
  },
  {
    classId: "EC011685",
    classGroup: "EG015520",
    artClassDesc: "Accessories/spare parts for storz coupling",
    artClassVersion: 4,
    label: "EC011685: Accessories/spare parts for storz coupling"
  },
  {
    classId: "EC011686",
    classGroup: "EG015220",
    artClassDesc: "Accessories/spare parts for central heating plate changer",
    artClassVersion: 3,
    label: "EC011686: Accessories/spare parts for central heating plate changer"
  },
  {
    classId: "EC011687",
    classGroup: "EG015840",
    artClassDesc: "Accessories/spare parts for watertight switching",
    artClassVersion: 3,
    label: "EC011687: Accessories/spare parts for watertight switching"
  },
  {
    classId: "EC011688",
    classGroup: "EG015620",
    artClassDesc: "Accessories/spare parts for worm gearbox for valves",
    artClassVersion: 4,
    label: "EC011688: Accessories/spare parts for worm gearbox for valves"
  },
  {
    classId: "EC011689",
    classGroup: "EG015910",
    artClassDesc: "Accessories/spare parts for seal",
    artClassVersion: 3,
    label: "EC011689: Accessories/spare parts for seal"
  },
  {
    classId: "EC011690",
    classGroup: "EG015870",
    artClassDesc: "Accessories/spare parts for condensate pump steam operated",
    artClassVersion: 3,
    label: "EC011690: Accessories/spare parts for condensate pump steam operated"
  },
  {
    classId: "EC011691",
    classGroup: "EG015520",
    artClassDesc: "Accessories/spare parts for guillemin coupling",
    artClassVersion: 4,
    label: "EC011691: Accessories/spare parts for guillemin coupling"
  },
  {
    classId: "EC011692",
    classGroup: "EG015880",
    artClassDesc: "Accessories/spare parts for viewing-/checking device",
    artClassVersion: 4,
    label: "EC011692: Accessories/spare parts for viewing-/checking device"
  },
  {
    classId: "EC011693",
    classGroup: "EG015620",
    artClassDesc: "Accessories/spare parts for line blind valve",
    artClassVersion: 3,
    label: "EC011693: Accessories/spare parts for line blind valve"
  },
  {
    classId: "EC011694",
    classGroup: "EG015870",
    artClassDesc: "Accessories/spare parts for safety device",
    artClassVersion: 5,
    label: "EC011694: Accessories/spare parts for safety device"
  },
  {
    classId: "EC011695",
    classGroup: "EG015620",
    artClassDesc: "Grease box",
    artClassVersion: 2,
    label: "EC011695: Grease box"
  },
  {
    classId: "EC011696",
    classGroup: "EG018410",
    artClassDesc: "Vibration damper",
    artClassVersion: 3,
    label: "EC011696: Vibration damper"
  },
  {
    classId: "EC011698",
    classGroup: "EG015210",
    artClassDesc: "Hot tap water heater, directly fired with control system",
    artClassVersion: 4,
    label: "EC011698: Hot tap water heater, directly fired with control system"
  },
  {
    classId: "EC011699",
    classGroup: "EG016760",
    artClassDesc: "Signal horn shipping",
    artClassVersion: 3,
    label: "EC011699: Signal horn shipping"
  },
  {
    classId: "EC011700",
    classGroup: "EG015540",
    artClassDesc: "Guide bend for piping system",
    artClassVersion: 4,
    label: "EC011700: Guide bend for piping system"
  },
  {
    classId: "EC011701",
    classGroup: "EG015530",
    artClassDesc: "Flexible metal connecting pipe (extractable)",
    artClassVersion: 1,
    label: "EC011701: Flexible metal connecting pipe (extractable)"
  },
  {
    classId: "EC011704",
    classGroup: "EG015530",
    artClassDesc: "Pre-insulated plastic pipe",
    artClassVersion: 5,
    label: "EC011704: Pre-insulated plastic pipe"
  },
  {
    classId: "EC011705",
    classGroup: "EG015230",
    artClassDesc: "Duct cooler cold water",
    artClassVersion: 4,
    label: "EC011705: Duct cooler cold water"
  },
  {
    classId: "EC011706",
    classGroup: "EG017210",
    artClassDesc: "Pictogram sanitary room",
    artClassVersion: 4,
    label: "EC011706: Pictogram sanitary room"
  },
  {
    classId: "EC011707",
    classGroup: "EG019510",
    artClassDesc: "Tile adhesive",
    artClassVersion: 4,
    label: "EC011707: Tile adhesive"
  },
  {
    classId: "EC011708",
    classGroup: "EG019510",
    artClassDesc: "Tile profile",
    artClassVersion: 4,
    label: "EC011708: Tile profile"
  },
  {
    classId: "EC011709",
    classGroup: "EG019510",
    artClassDesc: "Tile grout",
    artClassVersion: 5,
    label: "EC011709: Tile grout"
  },
  {
    classId: "EC011710",
    classGroup: "EG015220",
    artClassDesc: "Shower heat (recovery) exchanger",
    artClassVersion: 2,
    label: "EC011710: Shower heat (recovery) exchanger"
  },
  {
    classId: "EC011711",
    classGroup: "EG015530",
    artClassDesc: "Accessories/spare parts for pre-insulated plastic pipe",
    artClassVersion: 4,
    label: "EC011711: Accessories/spare parts for pre-insulated plastic pipe"
  },
  {
    classId: "EC011712",
    classGroup: "EG015260",
    artClassDesc: "Condensate water pump",
    artClassVersion: 3,
    label: "EC011712: Condensate water pump"
  },
  {
    classId: "EC011713",
    classGroup: "EG015810",
    artClassDesc: "Accessories/spare parts for pressure difference overflow controller",
    artClassVersion: 3,
    label: "EC011713: Accessories/spare parts for pressure difference overflow controller"
  },
  {
    classId: "EC011714",
    classGroup: "EG018410",
    artClassDesc: "Shock buffer",
    artClassVersion: 2,
    label: "EC011714: Shock buffer"
  },
  {
    classId: "EC011715",
    classGroup: "EG018410",
    artClassDesc: "Vibration absorber mat",
    artClassVersion: 2,
    label: "EC011715: Vibration absorber mat"
  },
  {
    classId: "EC011716",
    classGroup: "EG015560",
    artClassDesc: "Flue top",
    artClassVersion: 3,
    label: "EC011716: Flue top"
  },
  {
    classId: "EC011717",
    classGroup: "EG015540",
    artClassDesc: "Pass piece",
    artClassVersion: 4,
    label: "EC011717: Pass piece"
  },
  {
    classId: "EC011718",
    classGroup: "EG015810",
    artClassDesc: "Gas consumption meter",
    artClassVersion: 3,
    label: "EC011718: Gas consumption meter"
  },
  {
    classId: "EC011719",
    classGroup: "EG015210",
    artClassDesc: "Gas hearth/gas fire",
    artClassVersion: 5,
    label: "EC011719: Gas hearth/gas fire"
  },
  {
    classId: "EC011720",
    classGroup: "EG015130",
    artClassDesc: "Shower drain",
    artClassVersion: 4,
    label: "EC011720: Shower drain"
  },
  {
    classId: "EC011721",
    classGroup: "EG014000",
    artClassDesc: "Bin aerator",
    artClassVersion: 1,
    label: "EC011721: Bin aerator"
  },
  {
    classId: "EC011722",
    classGroup: "EG014000",
    artClassDesc: "Accessories/spare parts for bin aerator",
    artClassVersion: 2,
    label: "EC011722: Accessories/spare parts for bin aerator"
  },
  {
    classId: "EC011723",
    classGroup: "EG015840",
    artClassDesc: "Accessories/spare parts for pressure switch",
    artClassVersion: 3,
    label: "EC011723: Accessories/spare parts for pressure switch"
  },
  {
    classId: "EC011724",
    classGroup: "EG015520",
    artClassDesc: "Accessories/spare parts for dairy coupling",
    artClassVersion: 3,
    label: "EC011724: Accessories/spare parts for dairy coupling"
  },
  {
    classId: "EC011725",
    classGroup: "EG015830",
    artClassDesc: "Pressure-spring thermometer",
    artClassVersion: 3,
    label: "EC011725: Pressure-spring thermometer"
  },
  {
    classId: "EC011726",
    classGroup: "EG015130",
    artClassDesc: "Shower drain grid",
    artClassVersion: 4,
    label: "EC011726: Shower drain grid"
  },
  {
    classId: "EC011727",
    classGroup: "EG015170",
    artClassDesc: "Dehumidifier",
    artClassVersion: 4,
    label: "EC011727: Dehumidifier"
  },
  {
    classId: "EC011728",
    classGroup: "EG015280",
    artClassDesc: "Accessories/spare parts for central gas heaters",
    artClassVersion: 3,
    label: "EC011728: Accessories/spare parts for central gas heaters"
  },
  {
    classId: "EC011729",
    classGroup: "EG000060",
    artClassDesc: "Lead replacement",
    artClassVersion: 5,
    label: "EC011729: Lead replacement"
  },
  {
    classId: "EC011730",
    classGroup: "EG015560",
    artClassDesc: "Accessories/spare parts for heat recovery",
    artClassVersion: 5,
    label: "EC011730: Accessories/spare parts for heat recovery"
  },
  {
    classId: "EC011732",
    classGroup: "EG015140",
    artClassDesc: "Water softener",
    artClassVersion: 4,
    label: "EC011732: Water softener"
  },
  {
    classId: "EC011733",
    classGroup: "EG000049",
    artClassDesc: "Screw mounting set",
    artClassVersion: 3,
    label: "EC011733: Screw mounting set"
  },
  {
    classId: "EC011734",
    classGroup: "EG017910",
    artClassDesc: "Exhaust bowl for urinal",
    artClassVersion: 2,
    label: "EC011734: Exhaust bowl for urinal"
  },
  {
    classId: "EC011735",
    classGroup: "EG017550",
    artClassDesc: "Transition ring drainage",
    artClassVersion: 4,
    label: "EC011735: Transition ring drainage"
  },
  {
    classId: "EC011736",
    classGroup: "EG015220",
    artClassDesc: "Tube bundles heat exchanger",
    artClassVersion: 4,
    label: "EC011736: Tube bundles heat exchanger"
  },
  {
    classId: "EC011737",
    classGroup: "EG015130",
    artClassDesc: "Channel drain",
    artClassVersion: 4,
    label: "EC011737: Channel drain"
  },
  {
    classId: "EC011738",
    classGroup: "EG015130",
    artClassDesc: "Accessories/spare parts for channel drain",
    artClassVersion: 4,
    label: "EC011738: Accessories/spare parts for channel drain"
  },
  {
    classId: "EC011739",
    classGroup: "EG000039",
    artClassDesc: "Flusher disinfector",
    artClassVersion: 4,
    label: "EC011739: Flusher disinfector"
  },
  {
    classId: "EC011740",
    classGroup: "EG015810",
    artClassDesc: "Ventilation pot",
    artClassVersion: 3,
    label: "EC011740: Ventilation pot"
  },
  {
    classId: "EC011742",
    classGroup: "EG015710",
    artClassDesc: "Radiation panel indirectly heated",
    artClassVersion: 3,
    label: "EC011742: Radiation panel indirectly heated"
  },
  {
    classId: "EC011743",
    classGroup: "EG019720",
    artClassDesc: "Zinc",
    artClassVersion: 4,
    label: "EC011743: Zinc"
  },
  {
    classId: "EC011744",
    classGroup: "EG020300",
    artClassDesc: "Lead",
    artClassVersion: 3,
    label: "EC011744: Lead"
  },
  {
    classId: "EC011745",
    classGroup: "EG017550",
    artClassDesc: "Waste plug",
    artClassVersion: 4,
    label: "EC011745: Waste plug"
  },
  {
    classId: "EC011746",
    classGroup: "EG017910",
    artClassDesc: "Bath inspection hatch",
    artClassVersion: 2,
    label: "EC011746: Bath inspection hatch"
  },
  {
    classId: "EC011748",
    classGroup: "EG017910",
    artClassDesc: "Backsplash",
    artClassVersion: 2,
    label: "EC011748: Backsplash"
  },
  {
    classId: "EC011749",
    classGroup: "EG015240",
    artClassDesc: "Gas fired air curtain",
    artClassVersion: 3,
    label: "EC011749: Gas fired air curtain"
  },
  {
    classId: "EC011750",
    classGroup: "EG015640",
    artClassDesc: "Wall plate for outside tap",
    artClassVersion: 3,
    label: "EC011750: Wall plate for outside tap"
  },
  {
    classId: "EC011751",
    classGroup: "EG017910",
    artClassDesc: "Urinal screen",
    artClassVersion: 2,
    label: "EC011751: Urinal screen"
  },
  {
    classId: "EC011752",
    classGroup: "EG015580",
    artClassDesc: "Dome skylight",
    artClassVersion: 3,
    label: "EC011752: Dome skylight"
  },
  {
    classId: "EC011753",
    classGroup: "EG015580",
    artClassDesc: "Roof upstand for dome light",
    artClassVersion: 2,
    label: "EC011753: Roof upstand for dome light"
  },
  {
    classId: "EC011754",
    classGroup: "EG015810",
    artClassDesc: "Heat meter",
    artClassVersion: 4,
    label: "EC011754: Heat meter"
  },
  {
    classId: "EC011755",
    classGroup: "EG015280",
    artClassDesc: "Cascade package",
    artClassVersion: 2,
    label: "EC011755: Cascade package"
  },
  {
    classId: "EC011756",
    classGroup: "EG015530",
    artClassDesc: "Cunifer pipe",
    artClassVersion: 3,
    label: "EC011756: Cunifer pipe"
  },
  {
    classId: "EC011758",
    classGroup: "EG015140",
    artClassDesc: "Chalk separator",
    artClassVersion: 2,
    label: "EC011758: Chalk separator"
  },
  {
    classId: "EC011759",
    classGroup: "EG015260",
    artClassDesc: "Control unit expansion automat",
    artClassVersion: 1,
    label: "EC011759: Control unit expansion automat"
  },
  {
    classId: "EC011760",
    classGroup: "EG010220",
    artClassDesc: "Pull support for flexible waste gas pipe",
    artClassVersion: 3,
    label: "EC011760: Pull support for flexible waste gas pipe"
  },
  {
    classId: "EC011761",
    classGroup: "EG015810",
    artClassDesc: "Dispense valve for pipe mounting",
    artClassVersion: 2,
    label: "EC011761: Dispense valve for pipe mounting"
  },
  {
    classId: "EC011762",
    classGroup: "EG010210",
    artClassDesc: "Leak sealant heating system",
    artClassVersion: 3,
    label: "EC011762: Leak sealant heating system"
  },
  {
    classId: "EC011763",
    classGroup: "EG015210",
    artClassDesc: "Log fire",
    artClassVersion: 3,
    label: "EC011763: Log fire"
  },
  {
    classId: "EC011764",
    classGroup: "EG015280",
    artClassDesc: "Mounting frame wall-mounted gas heater",
    artClassVersion: 2,
    label: "EC011764: Mounting frame wall-mounted gas heater"
  },
  {
    classId: "EC011765",
    classGroup: "EG015810",
    artClassDesc: "Funnel overflow valve",
    artClassVersion: 2,
    label: "EC011765: Funnel overflow valve"
  },
  {
    classId: "EC011766",
    classGroup: "EG015240",
    artClassDesc: "Textile air distribution system",
    artClassVersion: 2,
    label: "EC011766: Textile air distribution system"
  },
  {
    classId: "EC011767",
    classGroup: "EG018110",
    artClassDesc: "Insulation board, floor heating",
    artClassVersion: 4,
    label: "EC011767: Insulation board, floor heating"
  },
  {
    classId: "EC011768",
    classGroup: "EG000060",
    artClassDesc: "Border insulation floor heating",
    artClassVersion: 3,
    label: "EC011768: Border insulation floor heating"
  },
  {
    classId: "EC011772",
    classGroup: "EG015530",
    artClassDesc: "Capillary tube mat",
    artClassVersion: 3,
    label: "EC011772: Capillary tube mat"
  },
  {
    classId: "EC011773",
    classGroup: "EG015530",
    artClassDesc: "Accessories/spare parts for capillary tube matt",
    artClassVersion: 3,
    label: "EC011773: Accessories/spare parts for capillary tube matt"
  },
  {
    classId: "EC011774",
    classGroup: "EG019720",
    artClassDesc: "Finishing panel heating/cooling",
    artClassVersion: 4,
    label: "EC011774: Finishing panel heating/cooling"
  },
  {
    classId: "EC011776",
    classGroup: "EG015230",
    artClassDesc: "Roof mounting bar outdoor unit airconditioning",
    artClassVersion: 2,
    label: "EC011776: Roof mounting bar outdoor unit airconditioning"
  },
  {
    classId: "EC011777",
    classGroup: "EG015170",
    artClassDesc: "Plenum for air conditioner",
    artClassVersion: 4,
    label: "EC011777: Plenum for air conditioner"
  },
  {
    classId: "EC011783",
    classGroup: "EG020300",
    artClassDesc: "Bituminous band",
    artClassVersion: 2,
    label: "EC011783: Bituminous band"
  },
  {
    classId: "EC011784",
    classGroup: "EG019610",
    artClassDesc: "Bituminous roof shingle",
    artClassVersion: 3,
    label: "EC011784: Bituminous roof shingle"
  },
  {
    classId: "EC011790",
    classGroup: "EG015210",
    artClassDesc: "Passive house unit",
    artClassVersion: 2,
    label: "EC011790: Passive house unit"
  },
  {
    classId: "EC011791",
    classGroup: "EG015130",
    artClassDesc: "Sewage inspection pit",
    artClassVersion: 3,
    label: "EC011791: Sewage inspection pit"
  },
  {
    classId: "EC011793",
    classGroup: "EG015230",
    artClassDesc: "Duct cooler direct expansion (DX)",
    artClassVersion: 3,
    label: "EC011793: Duct cooler direct expansion (DX)"
  },
  {
    classId: "EC011794",
    classGroup: "EG015210",
    artClassDesc: "Heat pump (water/water)",
    artClassVersion: 4,
    label: "EC011794: Heat pump (water/water)"
  },
  {
    classId: "EC011796",
    classGroup: "EG017210",
    artClassDesc: "Urinal cover",
    artClassVersion: 3,
    label: "EC011796: Urinal cover"
  },
  {
    classId: "EC011797",
    classGroup: "EG000060",
    artClassDesc: "Lubrication and maintenance medium",
    artClassVersion: 4,
    label: "EC011797: Lubrication and maintenance medium"
  },
  {
    classId: "EC011798",
    classGroup: "EG000050",
    artClassDesc: "Water puller",
    artClassVersion: 3,
    label: "EC011798: Water puller"
  },
  {
    classId: "EC011799",
    classGroup: "EG017210",
    artClassDesc: "Bidet seat",
    artClassVersion: 3,
    label: "EC011799: Bidet seat"
  },
  {
    classId: "EC011800",
    classGroup: "EG015210",
    artClassDesc: "Micro CHP boiler",
    artClassVersion: 3,
    label: "EC011800: Micro CHP boiler"
  },
  {
    classId: "EC011801",
    classGroup: "EG017210",
    artClassDesc: "Module sanitary accessories",
    artClassVersion: 3,
    label: "EC011801: Module sanitary accessories"
  },
  {
    classId: "EC011802",
    classGroup: "EG017210",
    artClassDesc: "Set sanitary accessories",
    artClassVersion: 4,
    label: "EC011802: Set sanitary accessories"
  },
  {
    classId: "EC011803",
    classGroup: "EG014000",
    artClassDesc: "Window wiper motor",
    artClassVersion: 3,
    label: "EC011803: Window wiper motor"
  },
  {
    classId: "EC011804",
    classGroup: "EG014000",
    artClassDesc: "Window wiper arm",
    artClassVersion: 4,
    label: "EC011804: Window wiper arm"
  },
  {
    classId: "EC011805",
    classGroup: "EG014000",
    artClassDesc: "Life raft",
    artClassVersion: 2,
    label: "EC011805: Life raft"
  },
  {
    classId: "EC011806",
    classGroup: "EG015140",
    artClassDesc: "Fuel filter single (water separator)",
    artClassVersion: 3,
    label: "EC011806: Fuel filter single (water separator)"
  },
  {
    classId: "EC011807",
    classGroup: "EG014000",
    artClassDesc: "Window wiper blade",
    artClassVersion: 3,
    label: "EC011807: Window wiper blade"
  },
  {
    classId: "EC011808",
    classGroup: "EG010220",
    artClassDesc: "Tool trolley",
    artClassVersion: 3,
    label: "EC011808: Tool trolley"
  },
  {
    classId: "EC011809",
    classGroup: "EG000050",
    artClassDesc: "Insert ring for friction ratchet",
    artClassVersion: 3,
    label: "EC011809: Insert ring for friction ratchet"
  },
  {
    classId: "EC011810",
    classGroup: "EG000050",
    artClassDesc: "Friction ratchet",
    artClassVersion: 4,
    label: "EC011810: Friction ratchet"
  },
  {
    classId: "EC011811",
    classGroup: "EG000050",
    artClassDesc: "Hook spanner",
    artClassVersion: 2,
    label: "EC011811: Hook spanner"
  },
  {
    classId: "EC011813",
    classGroup: "EG000050",
    artClassDesc: "Accessories/spare parts for screwdrivers",
    artClassVersion: 3,
    label: "EC011813: Accessories/spare parts for screwdrivers"
  },
  {
    classId: "EC011814",
    classGroup: "EG000050",
    artClassDesc: "Accessories/spare parts for momentum tools",
    artClassVersion: 3,
    label: "EC011814: Accessories/spare parts for momentum tools"
  },
  {
    classId: "EC011815",
    classGroup: "EG000050",
    artClassDesc: "Screwdriver set",
    artClassVersion: 3,
    label: "EC011815: Screwdriver set"
  },
  {
    classId: "EC011816",
    classGroup: "EG000050",
    artClassDesc: "Spanner set",
    artClassVersion: 4,
    label: "EC011816: Spanner set"
  },
  {
    classId: "EC011817",
    classGroup: "EG000050",
    artClassDesc: "Pliers set",
    artClassVersion: 2,
    label: "EC011817: Pliers set"
  },
  {
    classId: "EC011818",
    classGroup: "EG000050",
    artClassDesc: "Swivel head spanner",
    artClassVersion: 4,
    label: "EC011818: Swivel head spanner"
  },
  {
    classId: "EC011819",
    classGroup: "EG020000",
    artClassDesc: "Accessories/spare parts for toolbox, chest, trolley and workbench",
    artClassVersion: 3,
    label: "EC011819: Accessories/spare parts for toolbox, chest, trolley and workbench"
  },
  {
    classId: "EC011820",
    classGroup: "EG000050",
    artClassDesc: "Accessories/spare parts for spanner",
    artClassVersion: 4,
    label: "EC011820: Accessories/spare parts for spanner"
  },
  {
    classId: "EC011822",
    classGroup: "EG000050",
    artClassDesc: "Accessories/spare parts for pliers and clamps",
    artClassVersion: 3,
    label: "EC011822: Accessories/spare parts for pliers and clamps"
  },
  {
    classId: "EC011823",
    classGroup: "EG000050",
    artClassDesc: "Accessories/spare parts for cutting tools",
    artClassVersion: 3,
    label: "EC011823: Accessories/spare parts for cutting tools"
  },
  {
    classId: "EC011824",
    classGroup: "EG000050",
    artClassDesc: "Accessories/spare parts for bending tools",
    artClassVersion: 3,
    label: "EC011824: Accessories/spare parts for bending tools"
  },
  {
    classId: "EC011825",
    classGroup: "EG010330",
    artClassDesc: "Accessories/spare parts for flanging tools",
    artClassVersion: 4,
    label: "EC011825: Accessories/spare parts for flanging tools"
  },
  {
    classId: "EC011826",
    classGroup: "EG000050",
    artClassDesc: "Stone chisel and drift punch set",
    artClassVersion: 3,
    label: "EC011826: Stone chisel and drift punch set"
  },
  {
    classId: "EC011827",
    classGroup: "EG000050",
    artClassDesc: "Fitting for momentum tools",
    artClassVersion: 3,
    label: "EC011827: Fitting for momentum tools"
  },
  {
    classId: "EC011829",
    classGroup: "EG000050",
    artClassDesc: "Hammerhead",
    artClassVersion: 3,
    label: "EC011829: Hammerhead"
  },
  {
    classId: "EC011830",
    classGroup: "EG000050",
    artClassDesc: "Handle for hammer",
    artClassVersion: 3,
    label: "EC011830: Handle for hammer"
  },
  {
    classId: "EC011831",
    classGroup: "EG000050",
    artClassDesc: "Accessories/spare parts for hammer, chisel and punch",
    artClassVersion: 3,
    label: "EC011831: Accessories/spare parts for hammer, chisel and punch"
  },
  {
    classId: "EC011833",
    classGroup: "EG010330",
    artClassDesc: "Accessories/spare parts for puller",
    artClassVersion: 3,
    label: "EC011833: Accessories/spare parts for puller"
  },
  {
    classId: "EC011834",
    classGroup: "EG010330",
    artClassDesc: "Puller/slidehammer set",
    artClassVersion: 2,
    label: "EC011834: Puller/slidehammer set"
  },
  {
    classId: "EC011835",
    classGroup: "EG010220",
    artClassDesc: "Pipe cleaning tool",
    artClassVersion: 3,
    label: "EC011835: Pipe cleaning tool"
  },
  {
    classId: "EC011836",
    classGroup: "EG000050",
    artClassDesc: "Stud screw wrench",
    artClassVersion: 3,
    label: "EC011836: Stud screw wrench"
  },
  {
    classId: "EC011837",
    classGroup: "EG000050",
    artClassDesc: "Pocket knife/multifunctional tool",
    artClassVersion: 3,
    label: "EC011837: Pocket knife/multifunctional tool"
  },
  {
    classId: "EC011838",
    classGroup: "EG000050",
    artClassDesc: "File/rasp set",
    artClassVersion: 3,
    label: "EC011838: File/rasp set"
  },
  {
    classId: "EC011839",
    classGroup: "EG010210",
    artClassDesc: "Countersink",
    artClassVersion: 4,
    label: "EC011839: Countersink"
  },
  {
    classId: "EC011840",
    classGroup: "EG010210",
    artClassDesc: "Countersink set",
    artClassVersion: 2,
    label: "EC011840: Countersink set"
  },
  {
    classId: "EC011841",
    classGroup: "EG000050",
    artClassDesc: "Bolt extractor",
    artClassVersion: 3,
    label: "EC011841: Bolt extractor"
  },
  {
    classId: "EC011842",
    classGroup: "EG010210",
    artClassDesc: "Drill set",
    artClassVersion: 3,
    label: "EC011842: Drill set"
  },
  {
    classId: "EC011843",
    classGroup: "EG014000",
    artClassDesc: "Window wiper nozzle",
    artClassVersion: 2,
    label: "EC011843: Window wiper nozzle"
  },
  {
    classId: "EC011844",
    classGroup: "EG014000",
    artClassDesc: "Window wiper control",
    artClassVersion: 2,
    label: "EC011844: Window wiper control"
  },
  {
    classId: "EC011845",
    classGroup: "EG014000",
    artClassDesc: "Fitting for window wiper",
    artClassVersion: 1,
    label: "EC011845: Fitting for window wiper"
  },
  {
    classId: "EC011846",
    classGroup: "EG014000",
    artClassDesc: "Accessories/spare parts for window wiper system",
    artClassVersion: 4,
    label: "EC011846: Accessories/spare parts for window wiper system"
  },
  {
    classId: "EC011847",
    classGroup: "EG015260",
    artClassDesc: "Electric diaphragm pump",
    artClassVersion: 3,
    label: "EC011847: Electric diaphragm pump"
  },
  {
    classId: "EC011849",
    classGroup: "EG015140",
    artClassDesc: "Fuel filter duplex (water separator)",
    artClassVersion: 3,
    label: "EC011849: Fuel filter duplex (water separator)"
  },
  {
    classId: "EC011850",
    classGroup: "EG015520",
    artClassDesc: "Hose nozzle",
    artClassVersion: 4,
    label: "EC011850: Hose nozzle"
  },
  {
    classId: "EC011851",
    classGroup: "EG000050",
    artClassDesc: "Feeler gauge",
    artClassVersion: 2,
    label: "EC011851: Feeler gauge"
  },
  {
    classId: "EC011852",
    classGroup: "EG015610",
    artClassDesc: "Electrically controlled three-way control valve",
    artClassVersion: 4,
    label: "EC011852: Electrically controlled three-way control valve"
  },
  {
    classId: "EC011853",
    classGroup: "EG015140",
    artClassDesc: "UV water purification filter",
    artClassVersion: 4,
    label: "EC011853: UV water purification filter"
  },
  {
    classId: "EC011854",
    classGroup: "EG017140",
    artClassDesc: "Control flushing system for drinking water",
    artClassVersion: 2,
    label: "EC011854: Control flushing system for drinking water"
  },
  {
    classId: "EC011855",
    classGroup: "EG000049",
    artClassDesc: "Staple",
    artClassVersion: 3,
    label: "EC011855: Staple"
  },
  {
    classId: "EC011857",
    classGroup: "EG014000",
    artClassDesc: "Life jacket",
    artClassVersion: 2,
    label: "EC011857: Life jacket"
  },
  {
    classId: "EC011858",
    classGroup: "EG014000",
    artClassDesc: "Life saving suit",
    artClassVersion: 2,
    label: "EC011858: Life saving suit"
  },
  {
    classId: "EC011859",
    classGroup: "EG014000",
    artClassDesc: "Rescue boat",
    artClassVersion: 3,
    label: "EC011859: Rescue boat"
  },
  {
    classId: "EC011860",
    classGroup: "EG020002",
    artClassDesc: "Milling cutter",
    artClassVersion: 4,
    label: "EC011860: Milling cutter"
  },
  {
    classId: "EC011861",
    classGroup: "EG014000",
    artClassDesc: "Propeller protection",
    artClassVersion: 2,
    label: "EC011861: Propeller protection"
  },
  {
    classId: "EC011862",
    classGroup: "EG014000",
    artClassDesc: "Outboard engine",
    artClassVersion: 2,
    label: "EC011862: Outboard engine"
  },
  {
    classId: "EC011863",
    classGroup: "EG014000",
    artClassDesc: "Navigation light",
    artClassVersion: 2,
    label: "EC011863: Navigation light"
  },
  {
    classId: "EC011865",
    classGroup: "EG014000",
    artClassDesc: "Motor control cable",
    artClassVersion: 2,
    label: "EC011865: Motor control cable"
  },
  {
    classId: "EC011866",
    classGroup: "EG014000",
    artClassDesc: "Accessories/spare parts for motor control cable",
    artClassVersion: 3,
    label: "EC011866: Accessories/spare parts for motor control cable"
  },
  {
    classId: "EC011867",
    classGroup: "EG014000",
    artClassDesc: "Lifebuoy",
    artClassVersion: 3,
    label: "EC011867: Lifebuoy"
  },
  {
    classId: "EC011868",
    classGroup: "EG014000",
    artClassDesc: "Accessories/spare parts for life saving equipment",
    artClassVersion: 4,
    label: "EC011868: Accessories/spare parts for life saving equipment"
  },
  {
    classId: "EC011869",
    classGroup: "EG014000",
    artClassDesc: "Oxygen bottle",
    artClassVersion: 3,
    label: "EC011869: Oxygen bottle"
  },
  {
    classId: "EC011870",
    classGroup: "EG014000",
    artClassDesc: "Emergency beacon",
    artClassVersion: 3,
    label: "EC011870: Emergency beacon"
  },
  {
    classId: "EC011872",
    classGroup: "EG015830",
    artClassDesc: "Base station for portable gas detector",
    artClassVersion: 2,
    label: "EC011872: Base station for portable gas detector"
  },
  {
    classId: "EC011873",
    classGroup: "EG020003",
    artClassDesc: "Steel angle profile",
    artClassVersion: 4,
    label: "EC011873: Steel angle profile"
  },
  {
    classId: "EC011874",
    classGroup: "EG019730",
    artClassDesc: "Hexagonal metal rod",
    artClassVersion: 4,
    label: "EC011874: Hexagonal metal rod"
  },
  {
    classId: "EC011875",
    classGroup: "EG019730",
    artClassDesc: "Round metal rod",
    artClassVersion: 4,
    label: "EC011875: Round metal rod"
  },
  {
    classId: "EC011876",
    classGroup: "EG019730",
    artClassDesc: "Rectangular metal rod",
    artClassVersion: 4,
    label: "EC011876: Rectangular metal rod"
  },
  {
    classId: "EC011877",
    classGroup: "EG015280",
    artClassDesc: "Fireplace surround",
    artClassVersion: 2,
    label: "EC011877: Fireplace surround"
  },
  {
    classId: "EC011878",
    classGroup: "EG000053",
    artClassDesc: "Battery (Lead)",
    artClassVersion: 4,
    label: "EC011878: Battery (Lead)"
  },
  {
    classId: "EC011879",
    classGroup: "EG000060",
    artClassDesc: "Geotextile",
    artClassVersion: 3,
    label: "EC011879: Geotextile"
  },
  {
    classId: "EC011880",
    classGroup: "EG019720",
    artClassDesc: "Concrete plate",
    artClassVersion: 3,
    label: "EC011880: Concrete plate"
  },
  {
    classId: "EC011881",
    classGroup: "EG015530",
    artClassDesc: "Infiltration unit",
    artClassVersion: 4,
    label: "EC011881: Infiltration unit"
  },
  {
    classId: "EC011882",
    classGroup: "EG014000",
    artClassDesc: "Stern tube passage",
    artClassVersion: 2,
    label: "EC011882: Stern tube passage"
  },
  {
    classId: "EC011885",
    classGroup: "EG019710",
    artClassDesc: "Bulb flat profile, steel",
    artClassVersion: 4,
    label: "EC011885: Bulb flat profile, steel"
  },
  {
    classId: "EC011886",
    classGroup: "EG014000",
    artClassDesc: "Rudder blade",
    artClassVersion: 2,
    label: "EC011886: Rudder blade"
  },
  {
    classId: "EC011887",
    classGroup: "EG014000",
    artClassDesc: "Rudder stock",
    artClassVersion: 2,
    label: "EC011887: Rudder stock"
  },
  {
    classId: "EC011888",
    classGroup: "EG014000",
    artClassDesc: "Rudder trunk",
    artClassVersion: 1,
    label: "EC011888: Rudder trunk"
  },
  {
    classId: "EC011889",
    classGroup: "EG014000",
    artClassDesc: "Rudder stock with blade",
    artClassVersion: 2,
    label: "EC011889: Rudder stock with blade"
  },
  {
    classId: "EC011890",
    classGroup: "EG020003",
    artClassDesc: "T-profile, steel",
    artClassVersion: 4,
    label: "EC011890: T-profile, steel"
  },
  {
    classId: "EC011891",
    classGroup: "EG019710",
    artClassDesc: "T-profile, aluminium",
    artClassVersion: 4,
    label: "EC011891: T-profile, aluminium"
  },
  {
    classId: "EC011892",
    classGroup: "EG019710",
    artClassDesc: "Stainless steel T-profile",
    artClassVersion: 3,
    label: "EC011892: Stainless steel T-profile"
  },
  {
    classId: "EC011893",
    classGroup: "EG019710",
    artClassDesc: "U-profile, steel",
    artClassVersion: 4,
    label: "EC011893: U-profile, steel"
  },
  {
    classId: "EC011894",
    classGroup: "EG019710",
    artClassDesc: "U-profile, aluminium",
    artClassVersion: 4,
    label: "EC011894: U-profile, aluminium"
  },
  {
    classId: "EC011895",
    classGroup: "EG019710",
    artClassDesc: "Stainless steel U-profile",
    artClassVersion: 3,
    label: "EC011895: Stainless steel U-profile"
  },
  {
    classId: "EC011896",
    classGroup: "EG014000",
    artClassDesc: "Exhaust pipe passage",
    artClassVersion: 2,
    label: "EC011896: Exhaust pipe passage"
  },
  {
    classId: "EC011897",
    classGroup: "EG014000",
    artClassDesc: "Deck pipe passage",
    artClassVersion: 1,
    label: "EC011897: Deck pipe passage"
  },
  {
    classId: "EC011898",
    classGroup: "EG015540",
    artClassDesc: "Grease nipple",
    artClassVersion: 4,
    label: "EC011898: Grease nipple"
  },
  {
    classId: "EC011902",
    classGroup: "EG010210",
    artClassDesc: "Absorption grain",
    artClassVersion: 4,
    label: "EC011902: Absorption grain"
  },
  {
    classId: "EC011904",
    classGroup: "EG000050",
    artClassDesc: "Tyre lever",
    artClassVersion: 4,
    label: "EC011904: Tyre lever"
  },
  {
    classId: "EC011908",
    classGroup: "EG000050",
    artClassDesc: "Brake spring pliers",
    artClassVersion: 2,
    label: "EC011908: Brake spring pliers"
  },
  {
    classId: "EC011909",
    classGroup: "EG000050",
    artClassDesc: "Balance weight pliers",
    artClassVersion: 3,
    label: "EC011909: Balance weight pliers"
  },
  {
    classId: "EC011910",
    classGroup: "EG000050",
    artClassDesc: "Door spring plier",
    artClassVersion: 3,
    label: "EC011910: Door spring plier"
  },
  {
    classId: "EC011911",
    classGroup: "EG000050",
    artClassDesc: "Valve spring tool",
    artClassVersion: 3,
    label: "EC011911: Valve spring tool"
  },
  {
    classId: "EC011912",
    classGroup: "EG000050",
    artClassDesc: "Piston ring plier",
    artClassVersion: 2,
    label: "EC011912: Piston ring plier"
  },
  {
    classId: "EC011913",
    classGroup: "EG000050",
    artClassDesc: "Special grip pliers/connector pliers",
    artClassVersion: 4,
    label: "EC011913: Special grip pliers/connector pliers"
  },
  {
    classId: "EC011914",
    classGroup: "EG000050",
    artClassDesc: "Offsetting pliers with punch",
    artClassVersion: 4,
    label: "EC011914: Offsetting pliers with punch"
  },
  {
    classId: "EC011916",
    classGroup: "EG000050",
    artClassDesc: "Hose clip plier",
    artClassVersion: 4,
    label: "EC011916: Hose clip plier"
  },
  {
    classId: "EC011917",
    classGroup: "EG000050",
    artClassDesc: "Saw set master plier",
    artClassVersion: 4,
    label: "EC011917: Saw set master plier"
  },
  {
    classId: "EC011918",
    classGroup: "EG000050",
    artClassDesc: "Flanging tool",
    artClassVersion: 3,
    label: "EC011918: Flanging tool"
  },
  {
    classId: "EC011919",
    classGroup: "EG000050",
    artClassDesc: "Pliers wrench",
    artClassVersion: 4,
    label: "EC011919: Pliers wrench"
  },
  {
    classId: "EC011920",
    classGroup: "EG000050",
    artClassDesc: "Plane",
    artClassVersion: 3,
    label: "EC011920: Plane"
  },
  {
    classId: "EC011923",
    classGroup: "EG000050",
    artClassDesc: "Accessories/spare parts for plane",
    artClassVersion: 3,
    label: "EC011923: Accessories/spare parts for plane"
  },
  {
    classId: "EC011925",
    classGroup: "EG019720",
    artClassDesc: "Sandwich panel",
    artClassVersion: 4,
    label: "EC011925: Sandwich panel"
  },
  {
    classId: "EC011926",
    classGroup: "EG000050",
    artClassDesc: "Scraper",
    artClassVersion: 2,
    label: "EC011926: Scraper"
  },
  {
    classId: "EC011927",
    classGroup: "EG000050",
    artClassDesc: "Handheld tacker",
    artClassVersion: 2,
    label: "EC011927: Handheld tacker"
  },
  {
    classId: "EC011929",
    classGroup: "EG000050",
    artClassDesc: "Staple remover",
    artClassVersion: 3,
    label: "EC011929: Staple remover"
  },
  {
    classId: "EC011930",
    classGroup: "EG000050",
    artClassDesc: "V-block",
    artClassVersion: 4,
    label: "EC011930: V-block"
  },
  {
    classId: "EC011932",
    classGroup: "EG019710",
    artClassDesc: "Bulb flat profile, aluminium",
    artClassVersion: 4,
    label: "EC011932: Bulb flat profile, aluminium"
  },
  {
    classId: "EC011933",
    classGroup: "EG000050",
    artClassDesc: "Drill bit sharpening machine",
    artClassVersion: 3,
    label: "EC011933: Drill bit sharpening machine"
  },
  {
    classId: "EC011934",
    classGroup: "EG000050",
    artClassDesc: "Sprinkler reamer",
    artClassVersion: 3,
    label: "EC011934: Sprinkler reamer"
  },
  {
    classId: "EC011935",
    classGroup: "EG000050",
    artClassDesc: "Wrench with T-bar",
    artClassVersion: 2,
    label: "EC011935: Wrench with T-bar"
  },
  {
    classId: "EC011936",
    classGroup: "EG010210",
    artClassDesc: "Grinding stone",
    artClassVersion: 4,
    label: "EC011936: Grinding stone"
  },
  {
    classId: "EC011937",
    classGroup: "EG010210",
    artClassDesc: "Burr",
    artClassVersion: 4,
    label: "EC011937: Burr"
  },
  {
    classId: "EC011938",
    classGroup: "EG010210",
    artClassDesc: "Flap wheel",
    artClassVersion: 4,
    label: "EC011938: Flap wheel"
  },
  {
    classId: "EC011939",
    classGroup: "EG000050",
    artClassDesc: "Sanding paper block/board",
    artClassVersion: 4,
    label: "EC011939: Sanding paper block/board"
  },
  {
    classId: "EC011940",
    classGroup: "EG010210",
    artClassDesc: "Sponge scourer",
    artClassVersion: 2,
    label: "EC011940: Sponge scourer"
  },
  {
    classId: "EC011941",
    classGroup: "EG000050",
    artClassDesc: "Belt holder",
    artClassVersion: 3,
    label: "EC011941: Belt holder"
  },
  {
    classId: "EC011942",
    classGroup: "EG000050",
    artClassDesc: "Grinding wheel dresser",
    artClassVersion: 3,
    label: "EC011942: Grinding wheel dresser"
  },
  {
    classId: "EC011943",
    classGroup: "EG000050",
    artClassDesc: "Accessories/spare parts for grinding/sanding tools",
    artClassVersion: 4,
    label: "EC011943: Accessories/spare parts for grinding/sanding tools"
  },
  {
    classId: "EC011944",
    classGroup: "EG010210",
    artClassDesc: "Steel wool",
    artClassVersion: 1,
    label: "EC011944: Steel wool"
  },
  {
    classId: "EC011947",
    classGroup: "EG015830",
    artClassDesc: "Tire pressure gauge",
    artClassVersion: 1,
    label: "EC011947: Tire pressure gauge"
  },
  {
    classId: "EC011948",
    classGroup: "EG019510",
    artClassDesc: "Accessories/spare parts for tiles",
    artClassVersion: 3,
    label: "EC011948: Accessories/spare parts for tiles"
  },
  {
    classId: "EC011949",
    classGroup: "EG000050",
    artClassDesc: "Window scraper",
    artClassVersion: 4,
    label: "EC011949: Window scraper"
  },
  {
    classId: "EC011950",
    classGroup: "EG000050",
    artClassDesc: "Glass cutter",
    artClassVersion: 3,
    label: "EC011950: Glass cutter"
  },
  {
    classId: "EC011951",
    classGroup: "EG000050",
    artClassDesc: "Wall covering applicator",
    artClassVersion: 4,
    label: "EC011951: Wall covering applicator"
  },
  {
    classId: "EC011952",
    classGroup: "EG000051",
    artClassDesc: "Agitator (electric)",
    artClassVersion: 4,
    label: "EC011952: Agitator (electric)"
  },
  {
    classId: "EC011953",
    classGroup: "EG000050",
    artClassDesc: "Anvil",
    artClassVersion: 3,
    label: "EC011953: Anvil"
  },
  {
    classId: "EC011955",
    classGroup: "EG000044",
    artClassDesc: "Motor mechanics stethoscope",
    artClassVersion: 2,
    label: "EC011955: Motor mechanics stethoscope"
  },
  {
    classId: "EC011956",
    classGroup: "EG000050",
    artClassDesc: "Automotive special tool",
    artClassVersion: 2,
    label: "EC011956: Automotive special tool"
  },
  {
    classId: "EC011958",
    classGroup: "EG014000",
    artClassDesc: "Battery box",
    artClassVersion: 4,
    label: "EC011958: Battery box"
  },
  {
    classId: "EC011959",
    classGroup: "EG000051",
    artClassDesc: "Concrete mixing machine (electric)",
    artClassVersion: 4,
    label: "EC011959: Concrete mixing machine (electric)"
  },
  {
    classId: "EC011960",
    classGroup: "EG000053",
    artClassDesc: "Battery charger",
    artClassVersion: 4,
    label: "EC011960: Battery charger"
  },
  {
    classId: "EC011961",
    classGroup: "EG000060",
    artClassDesc: "Display stand",
    artClassVersion: 4,
    label: "EC011961: Display stand"
  },
  {
    classId: "EC011962",
    classGroup: "EG000060",
    artClassDesc: "Jerrycan",
    artClassVersion: 3,
    label: "EC011962: Jerrycan"
  },
  {
    classId: "EC011963",
    classGroup: "EG000060",
    artClassDesc: "Accessories/spare parts for jerrycan",
    artClassVersion: 3,
    label: "EC011963: Accessories/spare parts for jerrycan"
  },
  {
    classId: "EC011964",
    classGroup: "EG000060",
    artClassDesc: "Measuring cup",
    artClassVersion: 3,
    label: "EC011964: Measuring cup"
  },
  {
    classId: "EC011966",
    classGroup: "EG000049",
    artClassDesc: "Stud (quarter turn fastener)",
    artClassVersion: 3,
    label: "EC011966: Stud (quarter turn fastener)"
  },
  {
    classId: "EC011968",
    classGroup: "EG000049",
    artClassDesc: "Gear wheel",
    artClassVersion: 3,
    label: "EC011968: Gear wheel"
  },
  {
    classId: "EC011969",
    classGroup: "EG000049",
    artClassDesc: "Key (wedge)",
    artClassVersion: 3,
    label: "EC011969: Key (wedge)"
  },
  {
    classId: "EC011970",
    classGroup: "EG000049",
    artClassDesc: "Knurled knob",
    artClassVersion: 3,
    label: "EC011970: Knurled knob"
  },
  {
    classId: "EC011971",
    classGroup: "EG000049",
    artClassDesc: "Cardan joint",
    artClassVersion: 3,
    label: "EC011971: Cardan joint"
  },
  {
    classId: "EC011972",
    classGroup: "EG000049",
    artClassDesc: "Fork joint",
    artClassVersion: 4,
    label: "EC011972: Fork joint"
  },
  {
    classId: "EC011973",
    classGroup: "EG000049",
    artClassDesc: "Toggle clamp",
    artClassVersion: 3,
    label: "EC011973: Toggle clamp"
  },
  {
    classId: "EC011974",
    classGroup: "EG000049",
    artClassDesc: "Accessories/spare parts for toggle clamp",
    artClassVersion: 4,
    label: "EC011974: Accessories/spare parts for toggle clamp"
  },
  {
    classId: "EC011975",
    classGroup: "EG000049",
    artClassDesc: "Locking bar guide",
    artClassVersion: 3,
    label: "EC011975: Locking bar guide"
  },
  {
    classId: "EC011976",
    classGroup: "EG000049",
    artClassDesc: "Locking bar for pawl latch",
    artClassVersion: 4,
    label: "EC011976: Locking bar for pawl latch"
  },
  {
    classId: "EC011977",
    classGroup: "EG000049",
    artClassDesc: "Retaining spring",
    artClassVersion: 4,
    label: "EC011977: Retaining spring"
  },
  {
    classId: "EC011978",
    classGroup: "EG000049",
    artClassDesc: "Spring plate (quarter turn fastener)",
    artClassVersion: 3,
    label: "EC011978: Spring plate (quarter turn fastener)"
  },
  {
    classId: "EC011979",
    classGroup: "EG000049",
    artClassDesc: "Receptacle clip (quarter turn fastener)",
    artClassVersion: 2,
    label: "EC011979: Receptacle clip (quarter turn fastener)"
  },
  {
    classId: "EC011980",
    classGroup: "EG020003",
    artClassDesc: "H/I-profile, steel",
    artClassVersion: 4,
    label: "EC011980: H/I-profile, steel"
  },
  {
    classId: "EC011981",
    classGroup: "EG000050",
    artClassDesc: "Extractor set for screws/bolts",
    artClassVersion: 3,
    label: "EC011981: Extractor set for screws/bolts"
  },
  {
    classId: "EC011982",
    classGroup: "EG000061",
    artClassDesc: "GMAW welding torch",
    artClassVersion: 3,
    label: "EC011982: GMAW welding torch"
  },
  {
    classId: "EC011983",
    classGroup: "EG000061",
    artClassDesc: "GTAW welding torch",
    artClassVersion: 3,
    label: "EC011983: GTAW welding torch"
  },
  {
    classId: "EC011984",
    classGroup: "EG000061",
    artClassDesc: "Accessories/spare parts for welding equipment",
    artClassVersion: 3,
    label: "EC011984: Accessories/spare parts for welding equipment"
  },
  {
    classId: "EC011985",
    classGroup: "EG014000",
    artClassDesc: "Accessories/spare parts for voice-/data recorder",
    artClassVersion: 3,
    label: "EC011985: Accessories/spare parts for voice-/data recorder"
  },
  {
    classId: "EC011986",
    classGroup: "EG000038",
    artClassDesc: "Accessories/spare parts for handheld transceiver",
    artClassVersion: 2,
    label: "EC011986: Accessories/spare parts for handheld transceiver"
  },
  {
    classId: "EC011987",
    classGroup: "EG014000",
    artClassDesc: "Locating beacon for voyage data recorder",
    artClassVersion: 2,
    label: "EC011987: Locating beacon for voyage data recorder"
  },
  {
    classId: "EC011988",
    classGroup: "EG014000",
    artClassDesc: "GPS receiver",
    artClassVersion: 3,
    label: "EC011988: GPS receiver"
  },
  {
    classId: "EC011989",
    classGroup: "EG014000",
    artClassDesc: "Marine cable passage",
    artClassVersion: 2,
    label: "EC011989: Marine cable passage"
  },
  {
    classId: "EC011990",
    classGroup: "EG014000",
    artClassDesc: "Frame for marine cable passage",
    artClassVersion: 2,
    label: "EC011990: Frame for marine cable passage"
  },
  {
    classId: "EC011991",
    classGroup: "EG014000",
    artClassDesc: "Ship door",
    artClassVersion: 2,
    label: "EC011991: Ship door"
  },
  {
    classId: "EC011992",
    classGroup: "EG014000",
    artClassDesc: "Door frame for ship door",
    artClassVersion: 2,
    label: "EC011992: Door frame for ship door"
  },
  {
    classId: "EC011993",
    classGroup: "EG014000",
    artClassDesc: "Water injection piece for exhaust",
    artClassVersion: 2,
    label: "EC011993: Water injection piece for exhaust"
  },
  {
    classId: "EC011994",
    classGroup: "EG014000",
    artClassDesc: "Flexible coupling for powertrain",
    artClassVersion: 3,
    label: "EC011994: Flexible coupling for powertrain"
  },
  {
    classId: "EC011995",
    classGroup: "EG010210",
    artClassDesc: "Burr set",
    artClassVersion: 3,
    label: "EC011995: Burr set"
  },
  {
    classId: "EC011996",
    classGroup: "EG000049",
    artClassDesc: "Spring plunger",
    artClassVersion: 4,
    label: "EC011996: Spring plunger"
  },
  {
    classId: "EC011997",
    classGroup: "EG010330",
    artClassDesc: "Saw-horse",
    artClassVersion: 4,
    label: "EC011997: Saw-horse"
  },
  {
    classId: "EC011998",
    classGroup: "EG000049",
    artClassDesc: "Multiple bar connector",
    artClassVersion: 2,
    label: "EC011998: Multiple bar connector"
  },
  {
    classId: "EC011999",
    classGroup: "EG000049",
    artClassDesc: "Tolerance compensator (quarter turn fastener)",
    artClassVersion: 2,
    label: "EC011999: Tolerance compensator (quarter turn fastener)"
  },
  {
    classId: "EC012000",
    classGroup: "EG020004",
    artClassDesc: "Hinge",
    artClassVersion: 4,
    label: "EC012000: Hinge"
  },
  {
    classId: "EC012001",
    classGroup: "EG019480",
    artClassDesc: "Accessories/spare parts for hinge",
    artClassVersion: 3,
    label: "EC012001: Accessories/spare parts for hinge"
  },
  {
    classId: "EC012002",
    classGroup: "EG000049",
    artClassDesc: "Assortment box fastening material",
    artClassVersion: 4,
    label: "EC012002: Assortment box fastening material"
  },
  {
    classId: "EC012003",
    classGroup: "EG000049",
    artClassDesc: "Ball joint",
    artClassVersion: 4,
    label: "EC012003: Ball joint"
  },
  {
    classId: "EC012004",
    classGroup: "EG000060",
    artClassDesc: "Primer paint",
    artClassVersion: 4,
    label: "EC012004: Primer paint"
  },
  {
    classId: "EC012005",
    classGroup: "EG014000",
    artClassDesc: "Propeller shaft",
    artClassVersion: 2,
    label: "EC012005: Propeller shaft"
  },
  {
    classId: "EC012006",
    classGroup: "EG014000",
    artClassDesc: "Flange for propeller shaft",
    artClassVersion: 2,
    label: "EC012006: Flange for propeller shaft"
  },
  {
    classId: "EC012007",
    classGroup: "EG014000",
    artClassDesc: "Sealing for propeller shaft",
    artClassVersion: 3,
    label: "EC012007: Sealing for propeller shaft"
  },
  {
    classId: "EC012008",
    classGroup: "EG014000",
    artClassDesc: "Rope guard for stern tube",
    artClassVersion: 2,
    label: "EC012008: Rope guard for stern tube"
  },
  {
    classId: "EC012009",
    classGroup: "EG014000",
    artClassDesc: "Strut for stern tube",
    artClassVersion: 2,
    label: "EC012009: Strut for stern tube"
  },
  {
    classId: "EC012011",
    classGroup: "EG014000",
    artClassDesc: "Stern tube",
    artClassVersion: 3,
    label: "EC012011: Stern tube"
  },
  {
    classId: "EC012012",
    classGroup: "EG014000",
    artClassDesc: "Toggle plate",
    artClassVersion: 3,
    label: "EC012012: Toggle plate"
  },
  {
    classId: "EC012013",
    classGroup: "EG000060",
    artClassDesc: "Locker for keys",
    artClassVersion: 3,
    label: "EC012013: Locker for keys"
  },
  {
    classId: "EC012014",
    classGroup: "EG014000",
    artClassDesc: "Accessories/spare parts for propeller, propeller shaft and stern tube",
    artClassVersion: 3,
    label: "EC012014: Accessories/spare parts for propeller, propeller shaft and stern tube"
  },
  {
    classId: "EC012015",
    classGroup: "EG000046",
    artClassDesc: "Eye wash bottle",
    artClassVersion: 3,
    label: "EC012015: Eye wash bottle"
  },
  {
    classId: "EC012016",
    classGroup: "EG000046",
    artClassDesc: "Holder for eye wash bottle",
    artClassVersion: 3,
    label: "EC012016: Holder for eye wash bottle"
  },
  {
    classId: "EC012017",
    classGroup: "EG000046",
    artClassDesc: "Accessories/spare parts for personal protection",
    artClassVersion: 4,
    label: "EC012017: Accessories/spare parts for personal protection"
  },
  {
    classId: "EC012019",
    classGroup: "EG014000",
    artClassDesc: "Cover plate for exhaust",
    artClassVersion: 3,
    label: "EC012019: Cover plate for exhaust"
  },
  {
    classId: "EC012020",
    classGroup: "EG014000",
    artClassDesc: "Marine propeller",
    artClassVersion: 2,
    label: "EC012020: Marine propeller"
  },
  {
    classId: "EC012021",
    classGroup: "EG019710",
    artClassDesc: "Angle profile, stainless steel",
    artClassVersion: 4,
    label: "EC012021: Angle profile, stainless steel"
  },
  {
    classId: "EC012022",
    classGroup: "EG019710",
    artClassDesc: "Angle profile, aluminium",
    artClassVersion: 4,
    label: "EC012022: Angle profile, aluminium"
  },
  {
    classId: "EC012023",
    classGroup: "EG012000",
    artClassDesc: "Roll-down curtain",
    artClassVersion: 2,
    label: "EC012023: Roll-down curtain"
  },
  {
    classId: "EC012024",
    classGroup: "EG012000",
    artClassDesc: "Curtain rail",
    artClassVersion: 3,
    label: "EC012024: Curtain rail"
  },
  {
    classId: "EC012025",
    classGroup: "EG014000",
    artClassDesc: "Accessories/spare parts for ship window",
    artClassVersion: 3,
    label: "EC012025: Accessories/spare parts for ship window"
  },
  {
    classId: "EC012026",
    classGroup: "EG014000",
    artClassDesc: "Escape hatch",
    artClassVersion: 3,
    label: "EC012026: Escape hatch"
  },
  {
    classId: "EC012027",
    classGroup: "EG000050",
    artClassDesc: "Locking strip insert tool",
    artClassVersion: 2,
    label: "EC012027: Locking strip insert tool"
  },
  {
    classId: "EC012028",
    classGroup: "EG014000",
    artClassDesc: "Panel for ship panelling",
    artClassVersion: 3,
    label: "EC012028: Panel for ship panelling"
  },
  {
    classId: "EC012029",
    classGroup: "EG015910",
    artClassDesc: "Rubber dry glazing seal",
    artClassVersion: 3,
    label: "EC012029: Rubber dry glazing seal"
  },
  {
    classId: "EC012030",
    classGroup: "EG019710",
    artClassDesc: "Plastic string for rubber key glass carrier section",
    artClassVersion: 4,
    label: "EC012030: Plastic string for rubber key glass carrier section"
  },
  {
    classId: "EC012031",
    classGroup: "EG015260",
    artClassDesc: "Gear pump",
    artClassVersion: 4,
    label: "EC012031: Gear pump"
  },
  {
    classId: "EC012032",
    classGroup: "EG014000",
    artClassDesc: "Chain stopper",
    artClassVersion: 3,
    label: "EC012032: Chain stopper"
  },
  {
    classId: "EC012033",
    classGroup: "EG014000",
    artClassDesc: "Hook for chain stopper",
    artClassVersion: 2,
    label: "EC012033: Hook for chain stopper"
  },
  {
    classId: "EC012034",
    classGroup: "EG015210",
    artClassDesc: "Heat pump (air/water) monobloc type",
    artClassVersion: 4,
    label: "EC012034: Heat pump (air/water) monobloc type"
  },
  {
    classId: "EC012035",
    classGroup: "EG015210",
    artClassDesc: "Heat pump (air/water) split type",
    artClassVersion: 4,
    label: "EC012035: Heat pump (air/water) split type"
  },
  {
    classId: "EC012036",
    classGroup: "EG010220",
    artClassDesc: "Grease gun",
    artClassVersion: 3,
    label: "EC012036: Grease gun"
  },
  {
    classId: "EC012037",
    classGroup: "EG000046",
    artClassDesc: "Fall arrest device",
    artClassVersion: 3,
    label: "EC012037: Fall arrest device"
  },
  {
    classId: "EC012038",
    classGroup: "EG000046",
    artClassDesc: "Safety harness",
    artClassVersion: 3,
    label: "EC012038: Safety harness"
  },
  {
    classId: "EC012039",
    classGroup: "EG000046",
    artClassDesc: "Throw line",
    artClassVersion: 3,
    label: "EC012039: Throw line"
  },
  {
    classId: "EC012040",
    classGroup: "EG000046",
    artClassDesc: "Accessories/spare parts for fall protection",
    artClassVersion: 3,
    label: "EC012040: Accessories/spare parts for fall protection"
  },
  {
    classId: "EC012041",
    classGroup: "EG000046",
    artClassDesc: "Line clamp",
    artClassVersion: 3,
    label: "EC012041: Line clamp"
  },
  {
    classId: "EC012044",
    classGroup: "EG000046",
    artClassDesc: "Fall protection set",
    artClassVersion: 3,
    label: "EC012044: Fall protection set"
  },
  {
    classId: "EC012045",
    classGroup: "EG000046",
    artClassDesc: "Protective cap",
    artClassVersion: 4,
    label: "EC012045: Protective cap"
  },
  {
    classId: "EC012046",
    classGroup: "EG000052",
    artClassDesc: "Pressing jaw/collar for press fittings",
    artClassVersion: 4,
    label: "EC012046: Pressing jaw/collar for press fittings"
  },
  {
    classId: "EC012049",
    classGroup: "EG000046",
    artClassDesc: "Personal protection set",
    artClassVersion: 3,
    label: "EC012049: Personal protection set"
  },
  {
    classId: "EC012050",
    classGroup: "EG000046",
    artClassDesc: "Air hood",
    artClassVersion: 4,
    label: "EC012050: Air hood"
  },
  {
    classId: "EC012052",
    classGroup: "EG014000",
    artClassDesc: "Accessories/spare parts for drip tray",
    artClassVersion: 4,
    label: "EC012052: Accessories/spare parts for drip tray"
  },
  {
    classId: "EC012053",
    classGroup: "EG014000",
    artClassDesc: "Accessories/spare parts for hawse pipe",
    artClassVersion: 3,
    label: "EC012053: Accessories/spare parts for hawse pipe"
  },
  {
    classId: "EC012054",
    classGroup: "EG014000",
    artClassDesc: "Accessories/spare parts for marine toilet/urinal",
    artClassVersion: 3,
    label: "EC012054: Accessories/spare parts for marine toilet/urinal"
  },
  {
    classId: "EC012055",
    classGroup: "EG014000",
    artClassDesc: "Accessories/spare parts for powertrain",
    artClassVersion: 3,
    label: "EC012055: Accessories/spare parts for powertrain"
  },
  {
    classId: "EC012056",
    classGroup: "EG014000",
    artClassDesc: "Accessories/spare parts for rudder",
    artClassVersion: 3,
    label: "EC012056: Accessories/spare parts for rudder"
  },
  {
    classId: "EC012057",
    classGroup: "EG014000",
    artClassDesc: "Support for exhaust silencer",
    artClassVersion: 3,
    label: "EC012057: Support for exhaust silencer"
  },
  {
    classId: "EC012058",
    classGroup: "EG014000",
    artClassDesc: "Support for searchlight",
    artClassVersion: 3,
    label: "EC012058: Support for searchlight"
  },
  {
    classId: "EC012059",
    classGroup: "EG014000",
    artClassDesc: "Ship bollard",
    artClassVersion: 4,
    label: "EC012059: Ship bollard"
  },
  {
    classId: "EC012060",
    classGroup: "EG014000",
    artClassDesc: "Drip tray",
    artClassVersion: 2,
    label: "EC012060: Drip tray"
  },
  {
    classId: "EC012061",
    classGroup: "EG014000",
    artClassDesc: "Eye plate",
    artClassVersion: 4,
    label: "EC012061: Eye plate"
  },
  {
    classId: "EC012062",
    classGroup: "EG010220",
    artClassDesc: "Accessories/spare parts for gun",
    artClassVersion: 3,
    label: "EC012062: Accessories/spare parts for gun"
  },
  {
    classId: "EC012063",
    classGroup: "EG014000",
    artClassDesc: "Handhole cover",
    artClassVersion: 2,
    label: "EC012063: Handhole cover"
  },
  {
    classId: "EC012064",
    classGroup: "EG014000",
    artClassDesc: "Knife for rope cutter",
    artClassVersion: 2,
    label: "EC012064: Knife for rope cutter"
  },
  {
    classId: "EC012065",
    classGroup: "EG014000",
    artClassDesc: "Marine foot support/handgrip",
    artClassVersion: 2,
    label: "EC012065: Marine foot support/handgrip"
  },
  {
    classId: "EC012066",
    classGroup: "EG014000",
    artClassDesc: "Marine tank",
    artClassVersion: 2,
    label: "EC012066: Marine tank"
  },
  {
    classId: "EC012067",
    classGroup: "EG014000",
    artClassDesc: "Step for mast/antenna",
    artClassVersion: 2,
    label: "EC012067: Step for mast/antenna"
  },
  {
    classId: "EC012068",
    classGroup: "EG015140",
    artClassDesc: "Oil-/water separator static",
    artClassVersion: 3,
    label: "EC012068: Oil-/water separator static"
  },
  {
    classId: "EC012069",
    classGroup: "EG014000",
    artClassDesc: "Quick release device for chain",
    artClassVersion: 2,
    label: "EC012069: Quick release device for chain"
  },
  {
    classId: "EC012070",
    classGroup: "EG014000",
    artClassDesc: "Sealing for rudder stock",
    artClassVersion: 2,
    label: "EC012070: Sealing for rudder stock"
  },
  {
    classId: "EC012071",
    classGroup: "EG014000",
    artClassDesc: "Rudder stop",
    artClassVersion: 2,
    label: "EC012071: Rudder stop"
  },
  {
    classId: "EC012073",
    classGroup: "EG014000",
    artClassDesc: "Binoculars",
    artClassVersion: 3,
    label: "EC012073: Binoculars"
  },
  {
    classId: "EC012074",
    classGroup: "EG000049",
    artClassDesc: "Turnbuckle",
    artClassVersion: 4,
    label: "EC012074: Turnbuckle"
  },
  {
    classId: "EC012075",
    classGroup: "EG017960",
    artClassDesc: "Hatstand",
    artClassVersion: 3,
    label: "EC012075: Hatstand"
  },
  {
    classId: "EC012076",
    classGroup: "EG000050",
    artClassDesc: "Lifting clamp",
    artClassVersion: 2,
    label: "EC012076: Lifting clamp"
  },
  {
    classId: "EC012077",
    classGroup: "EG000060",
    artClassDesc: "Accessories/spare parts for display stand",
    artClassVersion: 4,
    label: "EC012077: Accessories/spare parts for display stand"
  },
  {
    classId: "EC012078",
    classGroup: "EG000049",
    artClassDesc: "Machine handle",
    artClassVersion: 4,
    label: "EC012078: Machine handle"
  },
  {
    classId: "EC012079",
    classGroup: "EG010210",
    artClassDesc: "Accessories/spare parts for grinding stone",
    artClassVersion: 3,
    label: "EC012079: Accessories/spare parts for grinding stone"
  },
  {
    classId: "EC012080",
    classGroup: "EG015260",
    artClassDesc: "Jet pump",
    artClassVersion: 3,
    label: "EC012080: Jet pump"
  },
  {
    classId: "EC012081",
    classGroup: "EG014000",
    artClassDesc: "Hand flare",
    artClassVersion: 2,
    label: "EC012081: Hand flare"
  },
  {
    classId: "EC012082",
    classGroup: "EG014000",
    artClassDesc: "Parachute signal",
    artClassVersion: 4,
    label: "EC012082: Parachute signal"
  },
  {
    classId: "EC012083",
    classGroup: "EG014000",
    artClassDesc: "Smoke signal",
    artClassVersion: 3,
    label: "EC012083: Smoke signal"
  },
  {
    classId: "EC012084",
    classGroup: "EG014000",
    artClassDesc: "Accessories/spare parts for escape hatch",
    artClassVersion: 3,
    label: "EC012084: Accessories/spare parts for escape hatch"
  },
  {
    classId: "EC012085",
    classGroup: "EG014000",
    artClassDesc: "Exhaust silencer",
    artClassVersion: 3,
    label: "EC012085: Exhaust silencer"
  },
  {
    classId: "EC012086",
    classGroup: "EG010220",
    artClassDesc: "Filling nozzle",
    artClassVersion: 3,
    label: "EC012086: Filling nozzle"
  },
  {
    classId: "EC012087",
    classGroup: "EG015140",
    artClassDesc: "Magnetic fuel filter",
    artClassVersion: 2,
    label: "EC012087: Magnetic fuel filter"
  },
  {
    classId: "EC012088",
    classGroup: "EG014000",
    artClassDesc: "Flag",
    artClassVersion: 4,
    label: "EC012088: Flag"
  },
  {
    classId: "EC012090",
    classGroup: "EG012000",
    artClassDesc: "Set for kitchen inventory",
    artClassVersion: 2,
    label: "EC012090: Set for kitchen inventory"
  },
  {
    classId: "EC012091",
    classGroup: "EG012000",
    artClassDesc: "Cutlery tray",
    artClassVersion: 3,
    label: "EC012091: Cutlery tray"
  },
  {
    classId: "EC012092",
    classGroup: "EG012000",
    artClassDesc: "Mug holder",
    artClassVersion: 2,
    label: "EC012092: Mug holder"
  },
  {
    classId: "EC012093",
    classGroup: "EG014000",
    artClassDesc: "Ship anchor",
    artClassVersion: 4,
    label: "EC012093: Ship anchor"
  },
  {
    classId: "EC012094",
    classGroup: "EG014000",
    artClassDesc: "Anchor rope",
    artClassVersion: 3,
    label: "EC012094: Anchor rope"
  },
  {
    classId: "EC012095",
    classGroup: "EG014000",
    artClassDesc: "Anchor chain",
    artClassVersion: 3,
    label: "EC012095: Anchor chain"
  },
  {
    classId: "EC012096",
    classGroup: "EG000049",
    artClassDesc: "Gas spring",
    artClassVersion: 4,
    label: "EC012096: Gas spring"
  },
  {
    classId: "EC012098",
    classGroup: "EG015170",
    artClassDesc: "Mist eliminator",
    artClassVersion: 2,
    label: "EC012098: Mist eliminator"
  },
  {
    classId: "EC012099",
    classGroup: "EG014000",
    artClassDesc: "Marine coating",
    artClassVersion: 3,
    label: "EC012099: Marine coating"
  },
  {
    classId: "EC012101",
    classGroup: "EG014000",
    artClassDesc: "Accessories/spare parts for text plate",
    artClassVersion: 4,
    label: "EC012101: Accessories/spare parts for text plate"
  },
  {
    classId: "EC012102",
    classGroup: "EG000049",
    artClassDesc: "Accessories/spare parts for furniture fittings",
    artClassVersion: 4,
    label: "EC012102: Accessories/spare parts for furniture fittings"
  },
  {
    classId: "EC012103",
    classGroup: "EG014000",
    artClassDesc: "Seaworthy welding hinge",
    artClassVersion: 3,
    label: "EC012103: Seaworthy welding hinge"
  },
  {
    classId: "EC012104",
    classGroup: "EG000049",
    artClassDesc: "Pelican hook",
    artClassVersion: 4,
    label: "EC012104: Pelican hook"
  },
  {
    classId: "EC012105",
    classGroup: "EG014000",
    artClassDesc: "Marine inspection hatch",
    artClassVersion: 2,
    label: "EC012105: Marine inspection hatch"
  },
  {
    classId: "EC012106",
    classGroup: "EG019730",
    artClassDesc: "Wire netting",
    artClassVersion: 4,
    label: "EC012106: Wire netting"
  },
  {
    classId: "EC012107",
    classGroup: "EG017630",
    artClassDesc: "Chain holder",
    artClassVersion: 1,
    label: "EC012107: Chain holder"
  },
  {
    classId: "EC012108",
    classGroup: "EG014000",
    artClassDesc: "Marine thinner/cleaner",
    artClassVersion: 2,
    label: "EC012108: Marine thinner/cleaner"
  },
  {
    classId: "EC012109",
    classGroup: "EG012000",
    artClassDesc: "Tea towel",
    artClassVersion: 2,
    label: "EC012109: Tea towel"
  },
  {
    classId: "EC012110",
    classGroup: "EG014000",
    artClassDesc: "Kitchen towel",
    artClassVersion: 2,
    label: "EC012110: Kitchen towel"
  },
  {
    classId: "EC012111",
    classGroup: "EG014000",
    artClassDesc: "Accessories/spare parts for binoculars",
    artClassVersion: 3,
    label: "EC012111: Accessories/spare parts for binoculars"
  },
  {
    classId: "EC012113",
    classGroup: "EG000049",
    artClassDesc: "Safety hook",
    artClassVersion: 4,
    label: "EC012113: Safety hook"
  },
  {
    classId: "EC012114",
    classGroup: "EG014000",
    artClassDesc: "Lifebuoy light",
    artClassVersion: 2,
    label: "EC012114: Lifebuoy light"
  },
  {
    classId: "EC012115",
    classGroup: "EG014000",
    artClassDesc: "Man overboard rescue system",
    artClassVersion: 2,
    label: "EC012115: Man overboard rescue system"
  },
  {
    classId: "EC012116",
    classGroup: "EG014000",
    artClassDesc: "Line thrower",
    artClassVersion: 3,
    label: "EC012116: Line thrower"
  },
  {
    classId: "EC012117",
    classGroup: "EG014000",
    artClassDesc: "Seaworthy push button lock for furniture",
    artClassVersion: 3,
    label: "EC012117: Seaworthy push button lock for furniture"
  },
  {
    classId: "EC012118",
    classGroup: "EG019480",
    artClassDesc: "Door catch",
    artClassVersion: 4,
    label: "EC012118: Door catch"
  },
  {
    classId: "EC012119",
    classGroup: "EG014000",
    artClassDesc: "Seaworthy mortice lock",
    artClassVersion: 4,
    label: "EC012119: Seaworthy mortice lock"
  },
  {
    classId: "EC012120",
    classGroup: "EG012000",
    artClassDesc: "Mattress",
    artClassVersion: 2,
    label: "EC012120: Mattress"
  },
  {
    classId: "EC012121",
    classGroup: "EG014000",
    artClassDesc: "Ship clock/meteorological instrument",
    artClassVersion: 3,
    label: "EC012121: Ship clock/meteorological instrument"
  },
  {
    classId: "EC012122",
    classGroup: "EG000061",
    artClassDesc: "Anti-spatter/chipping lens",
    artClassVersion: 4,
    label: "EC012122: Anti-spatter/chipping lens"
  },
  {
    classId: "EC012123",
    classGroup: "EG000040",
    artClassDesc: "Whiteboard",
    artClassVersion: 4,
    label: "EC012123: Whiteboard"
  },
  {
    classId: "EC012124",
    classGroup: "EG000040",
    artClassDesc: "Accessories/spare parts for whiteboard",
    artClassVersion: 4,
    label: "EC012124: Accessories/spare parts for whiteboard"
  },
  {
    classId: "EC012125",
    classGroup: "EG012000",
    artClassDesc: "Placemat",
    artClassVersion: 2,
    label: "EC012125: Placemat"
  },
  {
    classId: "EC012126",
    classGroup: "EG019720",
    artClassDesc: "Mesh grating",
    artClassVersion: 4,
    label: "EC012126: Mesh grating"
  },
  {
    classId: "EC012127",
    classGroup: "EG014000",
    artClassDesc: "Porthole",
    artClassVersion: 3,
    label: "EC012127: Porthole"
  },
  {
    classId: "EC012128",
    classGroup: "EG000046",
    artClassDesc: "Arm protection",
    artClassVersion: 3,
    label: "EC012128: Arm protection"
  },
  {
    classId: "EC012129",
    classGroup: "EG014000",
    artClassDesc: "Bearing",
    artClassVersion: 4,
    label: "EC012129: Bearing"
  },
  {
    classId: "EC012130",
    classGroup: "EG017960",
    artClassDesc: "Drawer runner",
    artClassVersion: 4,
    label: "EC012130: Drawer runner"
  },
  {
    classId: "EC012133",
    classGroup: "EG012000",
    artClassDesc: "Safe",
    artClassVersion: 2,
    label: "EC012133: Safe"
  },
  {
    classId: "EC012134",
    classGroup: "EG014000",
    artClassDesc: "Container for fire control plan",
    artClassVersion: 4,
    label: "EC012134: Container for fire control plan"
  },
  {
    classId: "EC012136",
    classGroup: "EG014000",
    artClassDesc: "Searchlight",
    artClassVersion: 3,
    label: "EC012136: Searchlight"
  },
  {
    classId: "EC012137",
    classGroup: "EG020012",
    artClassDesc: "Shelf support",
    artClassVersion: 4,
    label: "EC012137: Shelf support"
  },
  {
    classId: "EC012138",
    classGroup: "EG014000",
    artClassDesc: "Seaworthy pull fastener",
    artClassVersion: 4,
    label: "EC012138: Seaworthy pull fastener"
  },
  {
    classId: "EC012140",
    classGroup: "EG014000",
    artClassDesc: "Seaworthy door/hatch hinge",
    artClassVersion: 4,
    label: "EC012140: Seaworthy door/hatch hinge"
  },
  {
    classId: "EC012141",
    classGroup: "EG014000",
    artClassDesc: "Seaworthy door toggle",
    artClassVersion: 4,
    label: "EC012141: Seaworthy door toggle"
  },
  {
    classId: "EC012142",
    classGroup: "EG017210",
    artClassDesc: "Door stop",
    artClassVersion: 4,
    label: "EC012142: Door stop"
  },
  {
    classId: "EC012143",
    classGroup: "EG014000",
    artClassDesc: "Seaworthy hatch toggle",
    artClassVersion: 3,
    label: "EC012143: Seaworthy hatch toggle"
  },
  {
    classId: "EC012144",
    classGroup: "EG014000",
    artClassDesc: "Seaworthy rim lock",
    artClassVersion: 4,
    label: "EC012144: Seaworthy rim lock"
  },
  {
    classId: "EC012145",
    classGroup: "EG014000",
    artClassDesc: "Seaworthy hatch fastener",
    artClassVersion: 4,
    label: "EC012145: Seaworthy hatch fastener"
  },
  {
    classId: "EC012146",
    classGroup: "EG014000",
    artClassDesc: "Seaworthy slide lock",
    artClassVersion: 4,
    label: "EC012146: Seaworthy slide lock"
  },
  {
    classId: "EC012147",
    classGroup: "EG019480",
    artClassDesc: "Weather-/cabin hook",
    artClassVersion: 4,
    label: "EC012147: Weather-/cabin hook"
  },
  {
    classId: "EC012150",
    classGroup: "EG014000",
    artClassDesc: "Accessories/spare parts for seaworthy hinges and locks",
    artClassVersion: 4,
    label: "EC012150: Accessories/spare parts for seaworthy hinges and locks"
  },
  {
    classId: "EC012152",
    classGroup: "EG017640",
    artClassDesc: "Toilet shower",
    artClassVersion: 4,
    label: "EC012152: Toilet shower"
  },
  {
    classId: "EC012153",
    classGroup: "EG000049",
    artClassDesc: "Thread insert",
    artClassVersion: 3,
    label: "EC012153: Thread insert"
  },
  {
    classId: "EC012155",
    classGroup: "EG014000",
    artClassDesc: "Towing hook",
    artClassVersion: 3,
    label: "EC012155: Towing hook"
  },
  {
    classId: "EC012156",
    classGroup: "EG000035",
    artClassDesc: "Ship bell",
    artClassVersion: 2,
    label: "EC012156: Ship bell"
  },
  {
    classId: "EC012157",
    classGroup: "EG014000",
    artClassDesc: "Accessories/spare parts for flexible coupling for powertrain",
    artClassVersion: 3,
    label: "EC012157: Accessories/spare parts for flexible coupling for powertrain"
  },
  {
    classId: "EC012158",
    classGroup: "EG000050",
    artClassDesc: "Triangle",
    artClassVersion: 3,
    label: "EC012158: Triangle"
  },
  {
    classId: "EC012159",
    classGroup: "EG019720",
    artClassDesc: "Accessories/spare parts for mesh grating",
    artClassVersion: 3,
    label: "EC012159: Accessories/spare parts for mesh grating"
  },
  {
    classId: "EC012160",
    classGroup: "EG014000",
    artClassDesc: "Cleat",
    artClassVersion: 4,
    label: "EC012160: Cleat"
  },
  {
    classId: "EC012161",
    classGroup: "EG017960",
    artClassDesc: "Clothes hanger",
    artClassVersion: 4,
    label: "EC012161: Clothes hanger"
  },
  {
    classId: "EC012163",
    classGroup: "EG000046",
    artClassDesc: "Apron",
    artClassVersion: 3,
    label: "EC012163: Apron"
  },
  {
    classId: "EC012164",
    classGroup: "EG014000",
    artClassDesc: "Accessories/spare parts for exhaust",
    artClassVersion: 3,
    label: "EC012164: Accessories/spare parts for exhaust"
  },
  {
    classId: "EC012165",
    classGroup: "EG014000",
    artClassDesc: "Accessories/spare parts for marine tank",
    artClassVersion: 3,
    label: "EC012165: Accessories/spare parts for marine tank"
  },
  {
    classId: "EC012167",
    classGroup: "EG010340",
    artClassDesc: "Snap frame",
    artClassVersion: 3,
    label: "EC012167: Snap frame"
  },
  {
    classId: "EC012168",
    classGroup: "EG012000",
    artClassDesc: "Floor mat",
    artClassVersion: 4,
    label: "EC012168: Floor mat"
  },
  {
    classId: "EC012169",
    classGroup: "EG020005",
    artClassDesc: "Anode",
    artClassVersion: 3,
    label: "EC012169: Anode"
  },
  {
    classId: "EC012171",
    classGroup: "EG014000",
    artClassDesc: "Accessories/spare parts for anode",
    artClassVersion: 4,
    label: "EC012171: Accessories/spare parts for anode"
  },
  {
    classId: "EC012172",
    classGroup: "EG000051",
    artClassDesc: "High-pressure washer",
    artClassVersion: 3,
    label: "EC012172: High-pressure washer"
  },
  {
    classId: "EC012173",
    classGroup: "EG000049",
    artClassDesc: "Accessories/spare parts for turnbuckle",
    artClassVersion: 4,
    label: "EC012173: Accessories/spare parts for turnbuckle"
  },
  {
    classId: "EC012174",
    classGroup: "EG000049",
    artClassDesc: "Securing strap/chain",
    artClassVersion: 4,
    label: "EC012174: Securing strap/chain"
  },
  {
    classId: "EC012175",
    classGroup: "EG020002",
    artClassDesc: "Accessories/spare parts for cleaning device",
    artClassVersion: 4,
    label: "EC012175: Accessories/spare parts for cleaning device"
  },
  {
    classId: "EC012176",
    classGroup: "EG000041",
    artClassDesc: "Accessories/spare parts for television",
    artClassVersion: 3,
    label: "EC012176: Accessories/spare parts for television"
  },
  {
    classId: "EC012177",
    classGroup: "EG015110",
    artClassDesc: "Gravel catcher roof discharge",
    artClassVersion: 2,
    label: "EC012177: Gravel catcher roof discharge"
  },
  {
    classId: "EC012178",
    classGroup: "EG000061",
    artClassDesc: "Welding filter",
    artClassVersion: 4,
    label: "EC012178: Welding filter"
  },
  {
    classId: "EC012179",
    classGroup: "EG014000",
    artClassDesc: "Accessories/spare parts for anchor and chain",
    artClassVersion: 3,
    label: "EC012179: Accessories/spare parts for anchor and chain"
  },
  {
    classId: "EC012180",
    classGroup: "EG010340",
    artClassDesc: "Aerosol fire extinguishing unit",
    artClassVersion: 2,
    label: "EC012180: Aerosol fire extinguishing unit"
  },
  {
    classId: "EC012181",
    classGroup: "EG010340",
    artClassDesc: "Accessories/spare parts for fire fighting",
    artClassVersion: 4,
    label: "EC012181: Accessories/spare parts for fire fighting"
  },
  {
    classId: "EC012184",
    classGroup: "EG010340",
    artClassDesc: "Cabinet for fire extinguisher",
    artClassVersion: 4,
    label: "EC012184: Cabinet for fire extinguisher"
  },
  {
    classId: "EC012185",
    classGroup: "EG010340",
    artClassDesc: "Cabinet for fire fighting equipment",
    artClassVersion: 4,
    label: "EC012185: Cabinet for fire fighting equipment"
  },
  {
    classId: "EC012186",
    classGroup: "EG014000",
    artClassDesc: "Cabinet for life saving equipment",
    artClassVersion: 3,
    label: "EC012186: Cabinet for life saving equipment"
  },
  {
    classId: "EC012187",
    classGroup: "EG015120",
    artClassDesc: "Pressured air vessel",
    artClassVersion: 3,
    label: "EC012187: Pressured air vessel"
  },
  {
    classId: "EC012188",
    classGroup: "EG014000",
    artClassDesc: "Dorade box",
    artClassVersion: 2,
    label: "EC012188: Dorade box"
  },
  {
    classId: "EC012189",
    classGroup: "EG014000",
    artClassDesc: "Accessories/spare parts for chain-, wire-, rope guiding",
    artClassVersion: 3,
    label: "EC012189: Accessories/spare parts for chain-, wire-, rope guiding"
  },
  {
    classId: "EC012191",
    classGroup: "EG014000",
    artClassDesc: "Accessories/spare parts for navigation light",
    artClassVersion: 3,
    label: "EC012191: Accessories/spare parts for navigation light"
  },
  {
    classId: "EC012192",
    classGroup: "EG014000",
    artClassDesc: "Ashtray",
    artClassVersion: 2,
    label: "EC012192: Ashtray"
  },
  {
    classId: "EC012193",
    classGroup: "EG000035",
    artClassDesc: "Hanger for ship bell",
    artClassVersion: 2,
    label: "EC012193: Hanger for ship bell"
  },
  {
    classId: "EC012194",
    classGroup: "EG014000",
    artClassDesc: "Shammy",
    artClassVersion: 2,
    label: "EC012194: Shammy"
  },
  {
    classId: "EC012196",
    classGroup: "EG000053",
    artClassDesc: "Combination battery charger/inverter",
    artClassVersion: 2,
    label: "EC012196: Combination battery charger/inverter"
  },
  {
    classId: "EC012197",
    classGroup: "EG000050",
    artClassDesc: "Boat hook",
    artClassVersion: 3,
    label: "EC012197: Boat hook"
  },
  {
    classId: "EC012199",
    classGroup: "EG014000",
    artClassDesc: "Control unit autopilot",
    artClassVersion: 2,
    label: "EC012199: Control unit autopilot"
  },
  {
    classId: "EC012200",
    classGroup: "EG015140",
    artClassDesc: "Oil-/water water separator dynamic",
    artClassVersion: 4,
    label: "EC012200: Oil-/water water separator dynamic"
  },
  {
    classId: "EC012201",
    classGroup: "EG014000",
    artClassDesc: "Book/map",
    artClassVersion: 2,
    label: "EC012201: Book/map"
  },
  {
    classId: "EC012202",
    classGroup: "EG014000",
    artClassDesc: "Bow/stern thruster tunnel",
    artClassVersion: 2,
    label: "EC012202: Bow/stern thruster tunnel"
  },
  {
    classId: "EC012203",
    classGroup: "EG014000",
    artClassDesc: "Bow/stern thruster unit",
    artClassVersion: 2,
    label: "EC012203: Bow/stern thruster unit"
  },
  {
    classId: "EC012204",
    classGroup: "EG017210",
    artClassDesc: "Buffer for toilet seat",
    artClassVersion: 4,
    label: "EC012204: Buffer for toilet seat"
  },
  {
    classId: "EC012205",
    classGroup: "EG000061",
    artClassDesc: "Bimetallic welding joint",
    artClassVersion: 3,
    label: "EC012205: Bimetallic welding joint"
  },
  {
    classId: "EC012207",
    classGroup: "EG010320",
    artClassDesc: "Capstan",
    artClassVersion: 3,
    label: "EC012207: Capstan"
  },
  {
    classId: "EC012208",
    classGroup: "EG000049",
    artClassDesc: "Ceiling hanger",
    artClassVersion: 2,
    label: "EC012208: Ceiling hanger"
  },
  {
    classId: "EC012209",
    classGroup: "EG019720",
    artClassDesc: "Ceiling plate",
    artClassVersion: 4,
    label: "EC012209: Ceiling plate"
  },
  {
    classId: "EC012210",
    classGroup: "EG014000",
    artClassDesc: "Helmsman seat",
    artClassVersion: 3,
    label: "EC012210: Helmsman seat"
  },
  {
    classId: "EC012211",
    classGroup: "EG014000",
    artClassDesc: "Undercarriage for helmsman seat",
    artClassVersion: 2,
    label: "EC012211: Undercarriage for helmsman seat"
  },
  {
    classId: "EC012212",
    classGroup: "EG014000",
    artClassDesc: "Accessories/spare parts for helmsman seat",
    artClassVersion: 3,
    label: "EC012212: Accessories/spare parts for helmsman seat"
  },
  {
    classId: "EC012213",
    classGroup: "EG014000",
    artClassDesc: "Office chair",
    artClassVersion: 3,
    label: "EC012213: Office chair"
  },
  {
    classId: "EC012214",
    classGroup: "EG014000",
    artClassDesc: "Chart table",
    artClassVersion: 3,
    label: "EC012214: Chart table"
  },
  {
    classId: "EC012215",
    classGroup: "EG014000",
    artClassDesc: "Table leg",
    artClassVersion: 3,
    label: "EC012215: Table leg"
  },
  {
    classId: "EC012216",
    classGroup: "EG014000",
    artClassDesc: "Table top",
    artClassVersion: 3,
    label: "EC012216: Table top"
  },
  {
    classId: "EC012217",
    classGroup: "EG014000",
    artClassDesc: "Accessories/spare parts for boat hook",
    artClassVersion: 3,
    label: "EC012217: Accessories/spare parts for boat hook"
  },
  {
    classId: "EC012218",
    classGroup: "EG014000",
    artClassDesc: "Cylindrical fender",
    artClassVersion: 2,
    label: "EC012218: Cylindrical fender"
  },
  {
    classId: "EC012220",
    classGroup: "EG014000",
    artClassDesc: "D-fender",
    artClassVersion: 2,
    label: "EC012220: D-fender"
  },
  {
    classId: "EC012221",
    classGroup: "EG014000",
    artClassDesc: "Delta fender",
    artClassVersion: 2,
    label: "EC012221: Delta fender"
  },
  {
    classId: "EC012222",
    classGroup: "EG014000",
    artClassDesc: "Square fender",
    artClassVersion: 2,
    label: "EC012222: Square fender"
  },
  {
    classId: "EC012223",
    classGroup: "EG014000",
    artClassDesc: "Wing fender",
    artClassVersion: 2,
    label: "EC012223: Wing fender"
  },
  {
    classId: "EC012224",
    classGroup: "EG014000",
    artClassDesc: "Keyhole fender",
    artClassVersion: 2,
    label: "EC012224: Keyhole fender"
  },
  {
    classId: "EC012225",
    classGroup: "EG014000",
    artClassDesc: "M-fender",
    artClassVersion: 2,
    label: "EC012225: M-fender"
  },
  {
    classId: "EC012226",
    classGroup: "EG014000",
    artClassDesc: "W-fender",
    artClassVersion: 2,
    label: "EC012226: W-fender"
  },
  {
    classId: "EC012227",
    classGroup: "EG014000",
    artClassDesc: "Rectangle fender",
    artClassVersion: 2,
    label: "EC012227: Rectangle fender"
  },
  {
    classId: "EC012228",
    classGroup: "EG014000",
    artClassDesc: "Composite fender",
    artClassVersion: 2,
    label: "EC012228: Composite fender"
  },
  {
    classId: "EC012229",
    classGroup: "EG014000",
    artClassDesc: "Arch/V-fender",
    artClassVersion: 2,
    label: "EC012229: Arch/V-fender"
  },
  {
    classId: "EC012230",
    classGroup: "EG014000",
    artClassDesc: "Element fender",
    artClassVersion: 2,
    label: "EC012230: Element fender"
  },
  {
    classId: "EC012231",
    classGroup: "EG014000",
    artClassDesc: "Cone fender",
    artClassVersion: 2,
    label: "EC012231: Cone fender"
  },
  {
    classId: "EC012232",
    classGroup: "EG014000",
    artClassDesc: "Cell fender",
    artClassVersion: 2,
    label: "EC012232: Cell fender"
  },
  {
    classId: "EC012233",
    classGroup: "EG014000",
    artClassDesc: "Pneumatic fender",
    artClassVersion: 3,
    label: "EC012233: Pneumatic fender"
  },
  {
    classId: "EC012234",
    classGroup: "EG014000",
    artClassDesc: "Foam fender",
    artClassVersion: 2,
    label: "EC012234: Foam fender"
  },
  {
    classId: "EC012235",
    classGroup: "EG014000",
    artClassDesc: "Roller/wheel fender",
    artClassVersion: 2,
    label: "EC012235: Roller/wheel fender"
  },
  {
    classId: "EC012236",
    classGroup: "EG014000",
    artClassDesc: "Tyre fender",
    artClassVersion: 3,
    label: "EC012236: Tyre fender"
  },
  {
    classId: "EC012237",
    classGroup: "EG014000",
    artClassDesc: "Buffer block fender",
    artClassVersion: 2,
    label: "EC012237: Buffer block fender"
  },
  {
    classId: "EC012238",
    classGroup: "EG014000",
    artClassDesc: "Sliding fender",
    artClassVersion: 2,
    label: "EC012238: Sliding fender"
  },
  {
    classId: "EC012239",
    classGroup: "EG014000",
    artClassDesc: "Accessories/spare parts for fender",
    artClassVersion: 3,
    label: "EC012239: Accessories/spare parts for fender"
  },
  {
    classId: "EC012240",
    classGroup: "EG014000",
    artClassDesc: "Clear view screen",
    artClassVersion: 3,
    label: "EC012240: Clear view screen"
  },
  {
    classId: "EC012241",
    classGroup: "EG014000",
    artClassDesc: "VHF radio",
    artClassVersion: 2,
    label: "EC012241: VHF radio"
  },
  {
    classId: "EC012242",
    classGroup: "EG014000",
    artClassDesc: "Accessories/spare parts for VHF radio",
    artClassVersion: 3,
    label: "EC012242: Accessories/spare parts for VHF radio"
  },
  {
    classId: "EC012243",
    classGroup: "EG014000",
    artClassDesc: "Mobile VHF radio",
    artClassVersion: 2,
    label: "EC012243: Mobile VHF radio"
  },
  {
    classId: "EC012244",
    classGroup: "EG014000",
    artClassDesc: "Short wave transmitter",
    artClassVersion: 2,
    label: "EC012244: Short wave transmitter"
  },
  {
    classId: "EC012245",
    classGroup: "EG014000",
    artClassDesc: "Accessories/spare parts for short wave transmitter",
    artClassVersion: 3,
    label: "EC012245: Accessories/spare parts for short wave transmitter"
  },
  {
    classId: "EC012246",
    classGroup: "EG014000",
    artClassDesc: "Navtex receiver",
    artClassVersion: 2,
    label: "EC012246: Navtex receiver"
  },
  {
    classId: "EC012247",
    classGroup: "EG014000",
    artClassDesc: "Compass",
    artClassVersion: 2,
    label: "EC012247: Compass"
  },
  {
    classId: "EC012248",
    classGroup: "EG014000",
    artClassDesc: "Accessories/spare parts for compass",
    artClassVersion: 3,
    label: "EC012248: Accessories/spare parts for compass"
  },
  {
    classId: "EC012249",
    classGroup: "EG014000",
    artClassDesc: "Coolant",
    artClassVersion: 3,
    label: "EC012249: Coolant"
  },
  {
    classId: "EC012250",
    classGroup: "EG014000",
    artClassDesc: "Corrosion inhibitor",
    artClassVersion: 2,
    label: "EC012250: Corrosion inhibitor"
  },
  {
    classId: "EC012251",
    classGroup: "EG010320",
    artClassDesc: "Crane",
    artClassVersion: 3,
    label: "EC012251: Crane"
  },
  {
    classId: "EC012252",
    classGroup: "EG010320",
    artClassDesc: "Accessories/spare parts for crane",
    artClassVersion: 4,
    label: "EC012252: Accessories/spare parts for crane"
  },
  {
    classId: "EC012253",
    classGroup: "EG014000",
    artClassDesc: "Hydraulic cylinder",
    artClassVersion: 4,
    label: "EC012253: Hydraulic cylinder"
  },
  {
    classId: "EC012255",
    classGroup: "EG014000",
    artClassDesc: "Goose neck air outlet",
    artClassVersion: 3,
    label: "EC012255: Goose neck air outlet"
  },
  {
    classId: "EC012257",
    classGroup: "EG017960",
    artClassDesc: "Sofa",
    artClassVersion: 3,
    label: "EC012257: Sofa"
  },
  {
    classId: "EC012258",
    classGroup: "EG014000",
    artClassDesc: "Drawer",
    artClassVersion: 2,
    label: "EC012258: Drawer"
  },
  {
    classId: "EC012259",
    classGroup: "EG014000",
    artClassDesc: "Wardrobe rod",
    artClassVersion: 3,
    label: "EC012259: Wardrobe rod"
  },
  {
    classId: "EC012260",
    classGroup: "EG014000",
    artClassDesc: "Handrail system",
    artClassVersion: 3,
    label: "EC012260: Handrail system"
  },
  {
    classId: "EC012261",
    classGroup: "EG014000",
    artClassDesc: "Support for wardrobe rod",
    artClassVersion: 3,
    label: "EC012261: Support for wardrobe rod"
  },
  {
    classId: "EC012262",
    classGroup: "EG014000",
    artClassDesc: "Spray boom",
    artClassVersion: 3,
    label: "EC012262: Spray boom"
  },
  {
    classId: "EC012263",
    classGroup: "EG014000",
    artClassDesc: "Echo sounder",
    artClassVersion: 2,
    label: "EC012263: Echo sounder"
  },
  {
    classId: "EC012265",
    classGroup: "EG014000",
    artClassDesc: "Central closing system for ship door",
    artClassVersion: 2,
    label: "EC012265: Central closing system for ship door"
  },
  {
    classId: "EC012266",
    classGroup: "EG015210",
    artClassDesc: "Accessories/spare parts for heat pump",
    artClassVersion: 4,
    label: "EC012266: Accessories/spare parts for heat pump"
  },
  {
    classId: "EC012267",
    classGroup: "EG014000",
    artClassDesc: "Fire monitor",
    artClassVersion: 2,
    label: "EC012267: Fire monitor"
  },
  {
    classId: "EC012268",
    classGroup: "EG014000",
    artClassDesc: "Nozzle/branch pipe for fire monitor",
    artClassVersion: 2,
    label: "EC012268: Nozzle/branch pipe for fire monitor"
  },
  {
    classId: "EC012269",
    classGroup: "EG000060",
    artClassDesc: "Filler/putty",
    artClassVersion: 2,
    label: "EC012269: Filler/putty"
  },
  {
    classId: "EC012270",
    classGroup: "EG014000",
    artClassDesc: "Filling neck",
    artClassVersion: 3,
    label: "EC012270: Filling neck"
  },
  {
    classId: "EC012271",
    classGroup: "EG014000",
    artClassDesc: "Rubber flooring",
    artClassVersion: 3,
    label: "EC012271: Rubber flooring"
  },
  {
    classId: "EC012272",
    classGroup: "EG014000",
    artClassDesc: "Plastic flooring",
    artClassVersion: 4,
    label: "EC012272: Plastic flooring"
  },
  {
    classId: "EC012273",
    classGroup: "EG014000",
    artClassDesc: "Screed flooring",
    artClassVersion: 3,
    label: "EC012273: Screed flooring"
  },
  {
    classId: "EC012274",
    classGroup: "EG010320",
    artClassDesc: "Lifting lug",
    artClassVersion: 4,
    label: "EC012274: Lifting lug"
  },
  {
    classId: "EC012275",
    classGroup: "EG015610",
    artClassDesc: "Foam water mixer",
    artClassVersion: 2,
    label: "EC012275: Foam water mixer"
  },
  {
    classId: "EC012276",
    classGroup: "EG015530",
    artClassDesc: "Protection cap plastic drain pipe",
    artClassVersion: 4,
    label: "EC012276: Protection cap plastic drain pipe"
  },
  {
    classId: "EC012279",
    classGroup: "EG014000",
    artClassDesc: "Gangway",
    artClassVersion: 2,
    label: "EC012279: Gangway"
  },
  {
    classId: "EC012280",
    classGroup: "EG014000",
    artClassDesc: "Ajar hook",
    artClassVersion: 3,
    label: "EC012280: Ajar hook"
  },
  {
    classId: "EC012281",
    classGroup: "EG012000",
    artClassDesc: "Fitted sheet",
    artClassVersion: 2,
    label: "EC012281: Fitted sheet"
  },
  {
    classId: "EC012282",
    classGroup: "EG012000",
    artClassDesc: "Edging",
    artClassVersion: 2,
    label: "EC012282: Edging"
  },
  {
    classId: "EC012283",
    classGroup: "EG012000",
    artClassDesc: "Accessories/spare parts for bench/chair",
    artClassVersion: 2,
    label: "EC012283: Accessories/spare parts for bench/chair"
  },
  {
    classId: "EC012284",
    classGroup: "EG014000",
    artClassDesc: "Floor hatch",
    artClassVersion: 2,
    label: "EC012284: Floor hatch"
  },
  {
    classId: "EC012286",
    classGroup: "EG017960",
    artClassDesc: "Cabinet",
    artClassVersion: 3,
    label: "EC012286: Cabinet"
  },
  {
    classId: "EC012287",
    classGroup: "EG017960",
    artClassDesc: "Bed",
    artClassVersion: 4,
    label: "EC012287: Bed"
  },
  {
    classId: "EC012288",
    classGroup: "EG012000",
    artClassDesc: "Wall shelf",
    artClassVersion: 2,
    label: "EC012288: Wall shelf"
  },
  {
    classId: "EC012289",
    classGroup: "EG012000",
    artClassDesc: "Storage/transport box",
    artClassVersion: 3,
    label: "EC012289: Storage/transport box"
  },
  {
    classId: "EC012291",
    classGroup: "EG019480",
    artClassDesc: "Table flap stay",
    artClassVersion: 2,
    label: "EC012291: Table flap stay"
  },
  {
    classId: "EC012293",
    classGroup: "EG014000",
    artClassDesc: "Trapezoid fender",
    artClassVersion: 2,
    label: "EC012293: Trapezoid fender"
  },
  {
    classId: "EC012294",
    classGroup: "EG020012",
    artClassDesc: "Cup hinge (furniture)",
    artClassVersion: 4,
    label: "EC012294: Cup hinge (furniture)"
  },
  {
    classId: "EC012295",
    classGroup: "EG019710",
    artClassDesc: "Z-profile, aluminium",
    artClassVersion: 4,
    label: "EC012295: Z-profile, aluminium"
  },
  {
    classId: "EC012296",
    classGroup: "EG014000",
    artClassDesc: "GPS navigator/chart plotter",
    artClassVersion: 2,
    label: "EC012296: GPS navigator/chart plotter"
  },
  {
    classId: "EC012297",
    classGroup: "EG012000",
    artClassDesc: "Ceiling profile",
    artClassVersion: 4,
    label: "EC012297: Ceiling profile"
  },
  {
    classId: "EC012298",
    classGroup: "EG012000",
    artClassDesc: "Brush strip",
    artClassVersion: 4,
    label: "EC012298: Brush strip"
  },
  {
    classId: "EC012299",
    classGroup: "EG014000",
    artClassDesc: "Propeller nozzle",
    artClassVersion: 3,
    label: "EC012299: Propeller nozzle"
  },
  {
    classId: "EC012300",
    classGroup: "EG014000",
    artClassDesc: "Seaworthy profile cylinder lock",
    artClassVersion: 4,
    label: "EC012300: Seaworthy profile cylinder lock"
  },
  {
    classId: "EC012301",
    classGroup: "EG014000",
    artClassDesc: "Combustion engine",
    artClassVersion: 4,
    label: "EC012301: Combustion engine"
  },
  {
    classId: "EC012303",
    classGroup: "EG014000",
    artClassDesc: "Radar system",
    artClassVersion: 3,
    label: "EC012303: Radar system"
  },
  {
    classId: "EC012304",
    classGroup: "EG014000",
    artClassDesc: "Ship window",
    artClassVersion: 3,
    label: "EC012304: Ship window"
  },
  {
    classId: "EC012305",
    classGroup: "EG014000",
    artClassDesc: "Bunker sampler",
    artClassVersion: 2,
    label: "EC012305: Bunker sampler"
  },
  {
    classId: "EC012306",
    classGroup: "EG014000",
    artClassDesc: "Vibration damper base mounting",
    artClassVersion: 3,
    label: "EC012306: Vibration damper base mounting"
  },
  {
    classId: "EC012307",
    classGroup: "EG020012",
    artClassDesc: "Concealed hinge (furniture)",
    artClassVersion: 4,
    label: "EC012307: Concealed hinge (furniture)"
  },
  {
    classId: "EC012308",
    classGroup: "EG014000",
    artClassDesc: "Accessories/spare parts for bearing",
    artClassVersion: 3,
    label: "EC012308: Accessories/spare parts for bearing"
  },
  {
    classId: "EC012309",
    classGroup: "EG014000",
    artClassDesc: "Passenger seat",
    artClassVersion: 2,
    label: "EC012309: Passenger seat"
  },
  {
    classId: "EC012310",
    classGroup: "EG015830",
    artClassDesc: "Sounding tape",
    artClassVersion: 2,
    label: "EC012310: Sounding tape"
  },
  {
    classId: "EC012311",
    classGroup: "EG014000",
    artClassDesc: "Accessories/spare parts for echo sounder",
    artClassVersion: 2,
    label: "EC012311: Accessories/spare parts for echo sounder"
  },
  {
    classId: "EC012312",
    classGroup: "EG010220",
    artClassDesc: "Mop",
    artClassVersion: 3,
    label: "EC012312: Mop"
  },
  {
    classId: "EC012313",
    classGroup: "EG012000",
    artClassDesc: "Storage drum",
    artClassVersion: 3,
    label: "EC012313: Storage drum"
  },
  {
    classId: "EC012314",
    classGroup: "EG012000",
    artClassDesc: "Corner protector cap",
    artClassVersion: 3,
    label: "EC012314: Corner protector cap"
  },
  {
    classId: "EC012315",
    classGroup: "EG014000",
    artClassDesc: "Accessories/spare parts for GPS",
    artClassVersion: 3,
    label: "EC012315: Accessories/spare parts for GPS"
  },
  {
    classId: "EC012316",
    classGroup: "EG019710",
    artClassDesc: "I-profile, aluminium",
    artClassVersion: 4,
    label: "EC012316: I-profile, aluminium"
  },
  {
    classId: "EC012318",
    classGroup: "EG015610",
    artClassDesc: "Flame arrestor",
    artClassVersion: 1,
    label: "EC012318: Flame arrestor"
  },
  {
    classId: "EC012320",
    classGroup: "EG014000",
    artClassDesc: "Accessories/spare parts for radar system",
    artClassVersion: 2,
    label: "EC012320: Accessories/spare parts for radar system"
  },
  {
    classId: "EC012321",
    classGroup: "EG012000",
    artClassDesc: "Accessories/spare parts for bed",
    artClassVersion: 2,
    label: "EC012321: Accessories/spare parts for bed"
  },
  {
    classId: "EC012322",
    classGroup: "EG012000",
    artClassDesc: "Drinking vessel",
    artClassVersion: 3,
    label: "EC012322: Drinking vessel"
  },
  {
    classId: "EC012323",
    classGroup: "EG012000",
    artClassDesc: "Bottle opener",
    artClassVersion: 2,
    label: "EC012323: Bottle opener"
  },
  {
    classId: "EC012324",
    classGroup: "EG012000",
    artClassDesc: "Plate/bowl (dishware)",
    artClassVersion: 3,
    label: "EC012324: Plate/bowl (dishware)"
  },
  {
    classId: "EC012325",
    classGroup: "EG012000",
    artClassDesc: "Cutlery",
    artClassVersion: 4,
    label: "EC012325: Cutlery"
  },
  {
    classId: "EC012326",
    classGroup: "EG012000",
    artClassDesc: "Cook and bakeware",
    artClassVersion: 4,
    label: "EC012326: Cook and bakeware"
  },
  {
    classId: "EC012327",
    classGroup: "EG012000",
    artClassDesc: "Cutting board",
    artClassVersion: 2,
    label: "EC012327: Cutting board"
  },
  {
    classId: "EC012328",
    classGroup: "EG012000",
    artClassDesc: "Bin bag",
    artClassVersion: 3,
    label: "EC012328: Bin bag"
  },
  {
    classId: "EC012329",
    classGroup: "EG012000",
    artClassDesc: "Vacuum flask",
    artClassVersion: 2,
    label: "EC012329: Vacuum flask"
  },
  {
    classId: "EC012330",
    classGroup: "EG012000",
    artClassDesc: "Oven glove",
    artClassVersion: 2,
    label: "EC012330: Oven glove"
  },
  {
    classId: "EC012331",
    classGroup: "EG012000",
    artClassDesc: "Pillow",
    artClassVersion: 2,
    label: "EC012331: Pillow"
  },
  {
    classId: "EC012332",
    classGroup: "EG014000",
    artClassDesc: "Antifreeze",
    artClassVersion: 4,
    label: "EC012332: Antifreeze"
  },
  {
    classId: "EC012333",
    classGroup: "EG015710",
    artClassDesc: "Grid for convector",
    artClassVersion: 3,
    label: "EC012333: Grid for convector"
  },
  {
    classId: "EC012335",
    classGroup: "EG014000",
    artClassDesc: "Accessories/spare parts for AIS",
    artClassVersion: 2,
    label: "EC012335: Accessories/spare parts for AIS"
  },
  {
    classId: "EC012336",
    classGroup: "EG014000",
    artClassDesc: "Alternating current inverter",
    artClassVersion: 3,
    label: "EC012336: Alternating current inverter"
  },
  {
    classId: "EC012337",
    classGroup: "EG015550",
    artClassDesc: "Lead frame air duct",
    artClassVersion: 2,
    label: "EC012337: Lead frame air duct"
  },
  {
    classId: "EC012339",
    classGroup: "EG015120",
    artClassDesc: "Storage tank (heating/cooling system)",
    artClassVersion: 4,
    label: "EC012339: Storage tank (heating/cooling system)"
  },
  {
    classId: "EC012340",
    classGroup: "EG015260",
    artClassDesc: "Accessories/spare parts for pressure expansion vessel",
    artClassVersion: 3,
    label: "EC012340: Accessories/spare parts for pressure expansion vessel"
  },
  {
    classId: "EC012341",
    classGroup: "EG015260",
    artClassDesc: "Accessories/spare parts for automatic expansion unit",
    artClassVersion: 3,
    label: "EC012341: Accessories/spare parts for automatic expansion unit"
  },
  {
    classId: "EC012342",
    classGroup: "EG015260",
    artClassDesc: "Gas sensor automatic expansion unit",
    artClassVersion: 1,
    label: "EC012342: Gas sensor automatic expansion unit"
  },
  {
    classId: "EC012343",
    classGroup: "EG015210",
    artClassDesc: "Floor stand for storage tank",
    artClassVersion: 2,
    label: "EC012343: Floor stand for storage tank"
  },
  {
    classId: "EC012345",
    classGroup: "EG014000",
    artClassDesc: "Test kit for liquids",
    artClassVersion: 3,
    label: "EC012345: Test kit for liquids"
  },
  {
    classId: "EC012346",
    classGroup: "EG015410",
    artClassDesc: "Delivery station collective heating",
    artClassVersion: 4,
    label: "EC012346: Delivery station collective heating"
  },
  {
    classId: "EC012347",
    classGroup: "EG020400",
    artClassDesc: "Wood (rectangular)",
    artClassVersion: 4,
    label: "EC012347: Wood (rectangular)"
  },
  {
    classId: "EC012348",
    classGroup: "EG020550",
    artClassDesc: "Brick",
    artClassVersion: 4,
    label: "EC012348: Brick"
  },
  {
    classId: "EC012349",
    classGroup: "EG020450",
    artClassDesc: "Concrete tile",
    artClassVersion: 4,
    label: "EC012349: Concrete tile"
  },
  {
    classId: "EC012351",
    classGroup: "EG015130",
    artClassDesc: "Accessories/spare parts for shower drain",
    artClassVersion: 2,
    label: "EC012351: Accessories/spare parts for shower drain"
  },
  {
    classId: "EC012352",
    classGroup: "EG012000",
    artClassDesc: "Corkscrew",
    artClassVersion: 2,
    label: "EC012352: Corkscrew"
  },
  {
    classId: "EC012353",
    classGroup: "EG017210",
    artClassDesc: "Accessories/spare parts for bathroom accessories",
    artClassVersion: 4,
    label: "EC012353: Accessories/spare parts for bathroom accessories"
  },
  {
    classId: "EC012354",
    classGroup: "EG017910",
    artClassDesc: "Shower floor support",
    artClassVersion: 3,
    label: "EC012354: Shower floor support"
  },
  {
    classId: "EC012355",
    classGroup: "EG014000",
    artClassDesc: "Propeller shaft bracket",
    artClassVersion: 2,
    label: "EC012355: Propeller shaft bracket"
  },
  {
    classId: "EC012357",
    classGroup: "EG000017",
    artClassDesc: "AC transfer system",
    artClassVersion: 3,
    label: "EC012357: AC transfer system"
  },
  {
    classId: "EC012359",
    classGroup: "EG020150",
    artClassDesc: "Mortar tubs",
    artClassVersion: 3,
    label: "EC012359: Mortar tubs"
  },
  {
    classId: "EC012360",
    classGroup: "EG000060",
    artClassDesc: "Load safety net",
    artClassVersion: 3,
    label: "EC012360: Load safety net"
  },
  {
    classId: "EC012361",
    classGroup: "EG020150",
    artClassDesc: "Masking paper",
    artClassVersion: 4,
    label: "EC012361: Masking paper"
  },
  {
    classId: "EC012362",
    classGroup: "EG020150",
    artClassDesc: "Adhesive tape dispenser",
    artClassVersion: 3,
    label: "EC012362: Adhesive tape dispenser"
  },
  {
    classId: "EC012363",
    classGroup: "EG020150",
    artClassDesc: "Scraping-knife",
    artClassVersion: 4,
    label: "EC012363: Scraping-knife"
  },
  {
    classId: "EC012364",
    classGroup: "EG020150",
    artClassDesc: "Beam joint (double)",
    artClassVersion: 4,
    label: "EC012364: Beam joint (double)"
  },
  {
    classId: "EC012365",
    classGroup: "EG019730",
    artClassDesc: "Half-round metal rod",
    artClassVersion: 3,
    label: "EC012365: Half-round metal rod"
  },
  {
    classId: "EC012366",
    classGroup: "EG019730",
    artClassDesc: "Half-round plastic rod",
    artClassVersion: 3,
    label: "EC012366: Half-round plastic rod"
  },
  {
    classId: "EC012371",
    classGroup: "EG014000",
    artClassDesc: "Exhaust flexible suspension",
    artClassVersion: 2,
    label: "EC012371: Exhaust flexible suspension"
  },
  {
    classId: "EC012372",
    classGroup: "EG015280",
    artClassDesc: "Accessories/spare parts for gas hearth/gas fire",
    artClassVersion: 2,
    label: "EC012372: Accessories/spare parts for gas hearth/gas fire"
  },
  {
    classId: "EC012373",
    classGroup: "EG015610",
    artClassDesc: "Orifice",
    artClassVersion: 2,
    label: "EC012373: Orifice"
  }
];

const etimValues = [
  {
    valueId: "EV000005",
    valueDesc: "Thread holding",
    label: "EV000005: Thread holding"
  },
  {
    valueId: "EV000010",
    valueDesc: "Desoldering iron",
    label: "EV000010: Desoldering iron"
  },
  {
    valueId: "EV000011",
    valueDesc: "Cartridge with dispense needle",
    label: "EV000011: Cartridge with dispense needle"
  },
  {
    valueId: "EV000012",
    valueDesc: "Dial",
    label: "EV000012: Dial"
  },
  {
    valueId: "EV000013",
    valueDesc: "RJ45 8(8)",
    label: "EV000013: RJ45 8(8)"
  },
  {
    valueId: "EV000014",
    valueDesc: "RJ45 8(4)",
    label: "EV000014: RJ45 8(4)"
  },
  {
    valueId: "EV000015",
    valueDesc: "RJ11 6(4)",
    label: "EV000015: RJ11 6(4)"
  },
  {
    valueId: "EV000016",
    valueDesc: "Continuous",
    label: "EV000016: Continuous"
  },
  {
    valueId: "EV000017",
    valueDesc: "4-5",
    label: "EV000017: 4-5"
  },
  {
    valueId: "EV000021",
    valueDesc: "F3000",
    label: "EV000021: F3000"
  },
  {
    valueId: "EV000027",
    valueDesc: "With rail",
    label: "EV000027: With rail"
  },
  {
    valueId: "EV000028",
    valueDesc: "Universal",
    label: "EV000028: Universal"
  },
  {
    valueId: "EV000030",
    valueDesc: "Inner lying",
    label: "EV000030: Inner lying"
  },
  {
    valueId: "EV000031",
    valueDesc: "Spanned",
    label: "EV000031: Spanned"
  },
  {
    valueId: "EV000034",
    valueDesc: "Cover one-sided bevelled",
    label: "EV000034: Cover one-sided bevelled"
  },
  {
    valueId: "EV000035",
    valueDesc: "Cover double-sided bevelled",
    label: "EV000035: Cover double-sided bevelled"
  },
  {
    valueId: "EV000037",
    valueDesc: "Angular",
    label: "EV000037: Angular"
  },
  {
    valueId: "EV000038",
    valueDesc: "Moulded hood",
    label: "EV000038: Moulded hood"
  },
  {
    valueId: "EV000042",
    valueDesc: "Structured",
    label: "EV000042: Structured"
  },
  {
    valueId: "EV000063",
    valueDesc: "Centronics",
    label: "EV000063: Centronics"
  },
  {
    valueId: "EV000066",
    valueDesc: "NH00",
    label: "EV000066: NH00"
  },
  {
    valueId: "EV000067",
    valueDesc: "1-pole",
    label: "EV000067: 1-pole"
  },
  {
    valueId: "EV000069",
    valueDesc: "3",
    label: "EV000069: 3"
  },
  {
    valueId: "EV000070",
    valueDesc: "4",
    label: "EV000070: 4"
  },
  {
    valueId: "EV000072",
    valueDesc: "Aluminium",
    label: "EV000072: Aluminium"
  },
  {
    valueId: "EV000073",
    valueDesc: "Asymmetric",
    label: "EV000073: Asymmetric"
  },
  {
    valueId: "EV000074",
    valueDesc: "B",
    label: "EV000074: B"
  },
  {
    valueId: "EV000076",
    valueDesc: "B15d",
    label: "EV000076: B15d"
  },
  {
    valueId: "EV000077",
    valueDesc: "B22d",
    label: "EV000077: B22d"
  },
  {
    valueId: "EV000078",
    valueDesc: "Basic element",
    label: "EV000078: Basic element"
  },
  {
    valueId: "EV000079",
    valueDesc: "Concrete",
    label: "EV000079: Concrete"
  },
  {
    valueId: "EV000080",
    valueDesc: "Blue",
    label: "EV000080: Blue"
  },
  {
    valueId: "EV000081",
    valueDesc: "Blind",
    label: "EV000081: Blind"
  },
  {
    valueId: "EV000082",
    valueDesc: "Bronze",
    label: "EV000082: Bronze"
  },
  {
    valueId: "EV000083",
    valueDesc: "Brown",
    label: "EV000083: Brown"
  },
  {
    valueId: "EV000084",
    valueDesc: "C",
    label: "EV000084: C"
  },
  {
    valueId: "EV000085",
    valueDesc: "Capacitive",
    label: "EV000085: Capacitive"
  },
  {
    valueId: "EV000086",
    valueDesc: "Complete",
    label: "EV000086: Complete"
  },
  {
    valueId: "EV000087",
    valueDesc: "D01",
    label: "EV000087: D01"
  },
  {
    valueId: "EV000088",
    valueDesc: "D02",
    label: "EV000088: D02"
  },
  {
    valueId: "EV000089",
    valueDesc: "D03",
    label: "EV000089: D03"
  },
  {
    valueId: "EV000090",
    valueDesc: "Direct",
    label: "EV000090: Direct"
  },
  {
    valueId: "EV000092",
    valueDesc: "Turn button",
    label: "EV000092: Turn button"
  },
  {
    valueId: "EV000093",
    valueDesc: "3-fold",
    label: "EV000093: 3-fold"
  },
  {
    valueId: "EV000096",
    valueDesc: "E10",
    label: "EV000096: E10"
  },
  {
    valueId: "EV000097",
    valueDesc: "E14",
    label: "EV000097: E14"
  },
  {
    valueId: "EV000098",
    valueDesc: "E27",
    label: "EV000098: E27"
  },
  {
    valueId: "EV000099",
    valueDesc: "E40",
    label: "EV000099: E40"
  },
  {
    valueId: "EV000101",
    valueDesc: "Electronic",
    label: "EV000101: Electronic"
  },
  {
    valueId: "EV000103",
    valueDesc: "1-fold",
    label: "EV000103: 1-fold"
  },
  {
    valueId: "EV000104",
    valueDesc: "Fa4",
    label: "EV000104: Fa4"
  },
  {
    valueId: "EV000106",
    valueDesc: "Flange",
    label: "EV000106: Flange"
  },
  {
    valueId: "EV000107",
    valueDesc: "Flood",
    label: "EV000107: Flood"
  },
  {
    valueId: "EV000108",
    valueDesc: "G12",
    label: "EV000108: G12"
  },
  {
    valueId: "EV000109",
    valueDesc: "G4",
    label: "EV000109: G4"
  },
  {
    valueId: "EV000110",
    valueDesc: "G53",
    label: "EV000110: G53"
  },
  {
    valueId: "EV000111",
    valueDesc: "Galvanized, yellow chromium plated",
    label: "EV000111: Galvanized, yellow chromium plated"
  },
  {
    valueId: "EV000112",
    valueDesc: "Anodised",
    label: "EV000112: Anodised"
  },
  {
    valueId: "EV000113",
    valueDesc: "Lacquered",
    label: "EV000113: Lacquered"
  },
  {
    valueId: "EV000115",
    valueDesc: "Screwed",
    label: "EV000115: Screwed"
  },
  {
    valueId: "EV000116",
    valueDesc: "Closed",
    label: "EV000116: Closed"
  },
  {
    valueId: "EV000117",
    valueDesc: "Cast iron",
    label: "EV000117: Cast iron"
  },
  {
    valueId: "EV000118",
    valueDesc: "Smooth",
    label: "EV000118: Smooth"
  },
  {
    valueId: "EV000119",
    valueDesc: "Gold",
    label: "EV000119: Gold"
  },
  {
    valueId: "EV000120",
    valueDesc: "Green/yellow",
    label: "EV000120: Green/yellow"
  },
  {
    valueId: "EV000121",
    valueDesc: "GU5.3",
    label: "EV000121: GU5.3"
  },
  {
    valueId: "EV000122",
    valueDesc: "GX16d",
    label: "EV000122: GX16d"
  },
  {
    valueId: "EV000123",
    valueDesc: "GX5.3",
    label: "EV000123: GX5.3"
  },
  {
    valueId: "EV000124",
    valueDesc: "GY6.35",
    label: "EV000124: GY6.35"
  },
  {
    valueId: "EV000125",
    valueDesc: "GZ4",
    label: "EV000125: GZ4"
  },
  {
    valueId: "EV000127",
    valueDesc: "Clear",
    label: "EV000127: Clear"
  },
  {
    valueId: "EV000128",
    valueDesc: "Hollow wall",
    label: "EV000128: Hollow wall"
  },
  {
    valueId: "EV000130",
    valueDesc: "Horizontal and vertical",
    label: "EV000130: Horizontal and vertical"
  },
  {
    valueId: "EV000131",
    valueDesc: "Wood",
    label: "EV000131: Wood"
  },
  {
    valueId: "EV000132",
    valueDesc: "I-profile",
    label: "EV000132: I-profile"
  },
  {
    valueId: "EV000133",
    valueDesc: "Iron",
    label: "EV000133: Iron"
  },
  {
    valueId: "EV000134",
    valueDesc: "Built-in",
    label: "EV000134: Built-in"
  },
  {
    valueId: "EV000135",
    valueDesc: "Inductive",
    label: "EV000135: Inductive"
  },
  {
    valueId: "EV000136",
    valueDesc: "Cable",
    label: "EV000136: Cable"
  },
  {
    valueId: "EV000137",
    valueDesc: "Clamp",
    label: "EV000137: Clamp"
  },
  {
    valueId: "EV000138",
    valueDesc: "Copper",
    label: "EV000138: Copper"
  },
  {
    valueId: "EV000139",
    valueDesc: "Plastic",
    label: "EV000139: Plastic"
  },
  {
    valueId: "EV000140",
    valueDesc: "Plastic-coated",
    label: "EV000140: Plastic-coated"
  },
  {
    valueId: "EV000141",
    valueDesc: "L",
    label: "EV000141: L"
  },
  {
    valueId: "EV000142",
    valueDesc: "Lamella",
    label: "EV000142: Lamella"
  },
  {
    valueId: "EV000143",
    valueDesc: "Light",
    label: "EV000143: Light"
  },
  {
    valueId: "EV000144",
    valueDesc: "Lead",
    label: "EV000144: Lead"
  },
  {
    valueId: "EV000145",
    valueDesc: "Normally open contact",
    label: "EV000145: Normally open contact"
  },
  {
    valueId: "EV000146",
    valueDesc: "Matt",
    label: "EV000146: Matt"
  },
  {
    valueId: "EV000147",
    valueDesc: "Mechanical",
    label: "EV000147: Mechanical"
  },
  {
    valueId: "EV000148",
    valueDesc: "Multiple",
    label: "EV000148: Multiple"
  },
  {
    valueId: "EV000149",
    valueDesc: "Brass",
    label: "EV000149: Brass"
  },
  {
    valueId: "EV000152",
    valueDesc: "Open",
    label: "EV000152: Open"
  },
  {
    valueId: "EV000153",
    valueDesc: "Ceiling bracket",
    label: "EV000153: Ceiling bracket"
  },
  {
    valueId: "EV000154",
    valueDesc: "Other",
    label: "EV000154: Other"
  },
  {
    valueId: "EV000155",
    valueDesc: "Parallel",
    label: "EV000155: Parallel"
  },
  {
    valueId: "EV000157",
    valueDesc: "Polyethylene (PE)",
    label: "EV000157: Polyethylene (PE)"
  },
  {
    valueId: "EV000158",
    valueDesc: "Pin",
    label: "EV000158: Pin"
  },
  {
    valueId: "EV000159",
    valueDesc: "PG12",
    label: "EV000159: PG12"
  },
  {
    valueId: "EV000160",
    valueDesc: "Steel plate",
    label: "EV000160: Steel plate"
  },
  {
    valueId: "EV000161",
    valueDesc: "Countersunk head",
    label: "EV000161: Countersunk head"
  },
  {
    valueId: "EV000162",
    valueDesc: "Porcelain",
    label: "EV000162: Porcelain"
  },
  {
    valueId: "EV000163",
    valueDesc: "Polyvinyl chloride (PVC)",
    label: "EV000163: Polyvinyl chloride (PVC)"
  },
  {
    valueId: "EV000164",
    valueDesc: "Straight",
    label: "EV000164: Straight"
  },
  {
    valueId: "EV000165",
    valueDesc: "Ring",
    label: "EV000165: Ring"
  },
  {
    valueId: "EV000166",
    valueDesc: "Stainless steel",
    label: "EV000166: Stainless steel"
  },
  {
    valueId: "EV000167",
    valueDesc: "Round",
    label: "EV000167: Round"
  },
  {
    valueId: "EV000168",
    valueDesc: "R7s",
    label: "EV000168: R7s"
  },
  {
    valueId: "EV000169",
    valueDesc: "Rubber",
    label: "EV000169: Rubber"
  },
  {
    valueId: "EV000170",
    valueDesc: "RX7s",
    label: "EV000170: RX7s"
  },
  {
    valueId: "EV000171",
    valueDesc: "RX7s-24",
    label: "EV000171: RX7s-24"
  },
  {
    valueId: "EV000172",
    valueDesc: "Switch",
    label: "EV000172: Switch"
  },
  {
    valueId: "EV000173",
    valueDesc: "Screw",
    label: "EV000173: Screw"
  },
  {
    valueId: "EV000174",
    valueDesc: "Light signalling unit",
    label: "EV000174: Light signalling unit"
  },
  {
    valueId: "EV000175",
    valueDesc: "Key",
    label: "EV000175: Key"
  },
  {
    valueId: "EV000176",
    valueDesc: "Fast (F)",
    label: "EV000176: Fast (F)"
  },
  {
    valueId: "EV000177",
    valueDesc: "Mirror",
    label: "EV000177: Mirror"
  },
  {
    valueId: "EV000178",
    valueDesc: "Spot",
    label: "EV000178: Spot"
  },
  {
    valueId: "EV000179",
    valueDesc: "Steel",
    label: "EV000179: Steel"
  },
  {
    valueId: "EV000181",
    valueDesc: "Symmetric",
    label: "EV000181: Symmetric"
  },
  {
    valueId: "EV000182",
    valueDesc: "Telephone",
    label: "EV000182: Telephone"
  },
  {
    valueId: "EV000183",
    valueDesc: "Time-lag (T)",
    label: "EV000183: Time-lag (T)"
  },
  {
    valueId: "EV000185",
    valueDesc: "Two-part",
    label: "EV000185: Two-part"
  },
  {
    valueId: "EV000186",
    valueDesc: "2-fold",
    label: "EV000186: 2-fold"
  },
  {
    valueId: "EV000187",
    valueDesc: "Rigid",
    label: "EV000187: Rigid"
  },
  {
    valueId: "EV000188",
    valueDesc: "Breaker contact",
    label: "EV000188: Breaker contact"
  },
  {
    valueId: "EV000189",
    valueDesc: "Chromium-plated",
    label: "EV000189: Chromium-plated"
  },
  {
    valueId: "EV000190",
    valueDesc: "Control light",
    label: "EV000190: Control light"
  },
  {
    valueId: "EV000191",
    valueDesc: "Nickel-plated",
    label: "EV000191: Nickel-plated"
  },
  {
    valueId: "EV000193",
    valueDesc: "Galvanized",
    label: "EV000193: Galvanized"
  },
  {
    valueId: "EV000194",
    valueDesc: "Countersunk",
    label: "EV000194: Countersunk"
  },
  {
    valueId: "EV000195",
    valueDesc: "Quadrangular",
    label: "EV000195: Quadrangular"
  },
  {
    valueId: "EV000196",
    valueDesc: "4-fold",
    label: "EV000196: 4-fold"
  },
  {
    valueId: "EV000197",
    valueDesc: "Fork",
    label: "EV000197: Fork"
  },
  {
    valueId: "EV000198",
    valueDesc: "Wall console",
    label: "EV000198: Wall console"
  },
  {
    valueId: "EV000199",
    valueDesc: "Duct installation",
    label: "EV000199: Duct installation"
  },
  {
    valueId: "EV000200",
    valueDesc: "Rocker",
    label: "EV000200: Rocker"
  },
  {
    valueId: "EV000201",
    valueDesc: "Change-over contact",
    label: "EV000201: Change-over contact"
  },
  {
    valueId: "EV000202",
    valueDesc: "White",
    label: "EV000202: White"
  },
  {
    valueId: "EV000203",
    valueDesc: "Hexagon",
    label: "EV000203: Hexagon"
  },
  {
    valueId: "EV000204",
    valueDesc: "Wall bracket",
    label: "EV000204: Wall bracket"
  },
  {
    valueId: "EV000205",
    valueDesc: "Silver",
    label: "EV000205: Silver"
  },
  {
    valueId: "EV000206",
    valueDesc: "Black",
    label: "EV000206: Black"
  },
  {
    valueId: "EV000207",
    valueDesc: "Colour",
    label: "EV000207: Colour"
  },
  {
    valueId: "EV000208",
    valueDesc: "Ethernet",
    label: "EV000208: Ethernet"
  },
  {
    valueId: "EV000209",
    valueDesc: "DIN",
    label: "EV000209: DIN"
  },
  {
    valueId: "EV000210",
    valueDesc: "Fixed",
    label: "EV000210: Fixed"
  },
  {
    valueId: "EV000211",
    valueDesc: "IEC",
    label: "EV000211: IEC"
  },
  {
    valueId: "EV000212",
    valueDesc: "Twinax",
    label: "EV000212: Twinax"
  },
  {
    valueId: "EV000213",
    valueDesc: "Centre",
    label: "EV000213: Centre"
  },
  {
    valueId: "EV000214",
    valueDesc: "Cross (X)",
    label: "EV000214: Cross (X)"
  },
  {
    valueId: "EV000215",
    valueDesc: "Ceiling",
    label: "EV000215: Ceiling"
  },
  {
    valueId: "EV000216",
    valueDesc: "Coupling",
    label: "EV000216: Coupling"
  },
  {
    valueId: "EV000217",
    valueDesc: "Plug",
    label: "EV000217: Plug"
  },
  {
    valueId: "EV000218",
    valueDesc: "Bus",
    label: "EV000218: Bus"
  },
  {
    valueId: "EV000219",
    valueDesc: "Glass",
    label: "EV000219: Glass"
  },
  {
    valueId: "EV000220",
    valueDesc: "Rectangular",
    label: "EV000220: Rectangular"
  },
  {
    valueId: "EV000221",
    valueDesc: "Chain",
    label: "EV000221: Chain"
  },
  {
    valueId: "EV000222",
    valueDesc: "Single-sided",
    label: "EV000222: Single-sided"
  },
  {
    valueId: "EV000223",
    valueDesc: "Double-sided",
    label: "EV000223: Double-sided"
  },
  {
    valueId: "EV000224",
    valueDesc: "DII",
    label: "EV000224: DII"
  },
  {
    valueId: "EV000225",
    valueDesc: "DIII",
    label: "EV000225: DIII"
  },
  {
    valueId: "EV000226",
    valueDesc: "DIV",
    label: "EV000226: DIV"
  },
  {
    valueId: "EV000227",
    valueDesc: "DV",
    label: "EV000227: DV"
  },
  {
    valueId: "EV000228",
    valueDesc: "Curved",
    label: "EV000228: Curved"
  },
  {
    valueId: "EV000229",
    valueDesc: "NDZ",
    label: "EV000229: NDZ"
  },
  {
    valueId: "EV000230",
    valueDesc: "Loose",
    label: "EV000230: Loose"
  },
  {
    valueId: "EV000231",
    valueDesc: "With film hinge",
    label: "EV000231: With film hinge"
  },
  {
    valueId: "EV000232",
    valueDesc: "Slotted",
    label: "EV000232: Slotted"
  },
  {
    valueId: "EV000233",
    valueDesc: "Red",
    label: "EV000233: Red"
  },
  {
    valueId: "EV000234",
    valueDesc: "Yellow",
    label: "EV000234: Yellow"
  },
  {
    valueId: "EV000235",
    valueDesc: "Green",
    label: "EV000235: Green"
  },
  {
    valueId: "EV000236",
    valueDesc: "Orange",
    label: "EV000236: Orange"
  },
  {
    valueId: "EV000237",
    valueDesc: "Clip",
    label: "EV000237: Clip"
  },
  {
    valueId: "EV000238",
    valueDesc: "Wall socket",
    label: "EV000238: Wall socket"
  },
  {
    valueId: "EV000239",
    valueDesc: "Pull cord",
    label: "EV000239: Pull cord"
  },
  {
    valueId: "EV000240",
    valueDesc: "Incandescent lamp",
    label: "EV000240: Incandescent lamp"
  },
  {
    valueId: "EV000241",
    valueDesc: "Modular",
    label: "EV000241: Modular"
  },
  {
    valueId: "EV000242",
    valueDesc: "DIN rail",
    label: "EV000242: DIN rail"
  },
  {
    valueId: "EV000243",
    valueDesc: "Manual",
    label: "EV000243: Manual"
  },
  {
    valueId: "EV000244",
    valueDesc: "Automatic",
    label: "EV000244: Automatic"
  },
  {
    valueId: "EV000245",
    valueDesc: "Contactless",
    label: "EV000245: Contactless"
  },
  {
    valueId: "EV000246",
    valueDesc: "Spring-rod",
    label: "EV000246: Spring-rod"
  },
  {
    valueId: "EV000247",
    valueDesc: "K",
    label: "EV000247: K"
  },
  {
    valueId: "EV000248",
    valueDesc: "Bimetal",
    label: "EV000248: Bimetal"
  },
  {
    valueId: "EV000250",
    valueDesc: "Lengthwise",
    label: "EV000250: Lengthwise"
  },
  {
    valueId: "EV000251",
    valueDesc: "Turn/push button",
    label: "EV000251: Turn/push button"
  },
  {
    valueId: "EV000252",
    valueDesc: "Potential equalization socket",
    label: "EV000252: Potential equalization socket"
  },
  {
    valueId: "EV000253",
    valueDesc: "Sendzimir galvanized",
    label: "EV000253: Sendzimir galvanized"
  },
  {
    valueId: "EV000254",
    valueDesc: "Hot-dip galvanized",
    label: "EV000254: Hot-dip galvanized"
  },
  {
    valueId: "EV000258",
    valueDesc: "Pink",
    label: "EV000258: Pink"
  },
  {
    valueId: "EV000260",
    valueDesc: "Sliding knob",
    label: "EV000260: Sliding knob"
  },
  {
    valueId: "EV000261",
    valueDesc: "ADo",
    label: "EV000261: ADo"
  },
  {
    valueId: "EV000262",
    valueDesc: "ST",
    label: "EV000262: ST"
  },
  {
    valueId: "EV000263",
    valueDesc: "BNC",
    label: "EV000263: BNC"
  },
  {
    valueId: "EV000264",
    valueDesc: "GU4",
    label: "EV000264: GU4"
  },
  {
    valueId: "EV000265",
    valueDesc: "GY4",
    label: "EV000265: GY4"
  },
  {
    valueId: "EV000266",
    valueDesc: "BA15d",
    label: "EV000266: BA15d"
  },
  {
    valueId: "EV000270",
    valueDesc: "Grey",
    label: "EV000270: Grey"
  },
  {
    valueId: "EV000274",
    valueDesc: "Teardrop",
    label: "EV000274: Teardrop"
  },
  {
    valueId: "EV000278",
    valueDesc: "Reflector",
    label: "EV000278: Reflector"
  },
  {
    valueId: "EV000279",
    valueDesc: "Beige",
    label: "EV000279: Beige"
  },
  {
    valueId: "EV000280",
    valueDesc: "Mushroom",
    label: "EV000280: Mushroom"
  },
  {
    valueId: "EV000281",
    valueDesc: "Ellipsoid",
    label: "EV000281: Ellipsoid"
  },
  {
    valueId: "EV000283",
    valueDesc: "Continuously galvanized",
    label: "EV000283: Continuously galvanized"
  },
  {
    valueId: "EV000290",
    valueDesc: "Platinum",
    label: "EV000290: Platinum"
  },
  {
    valueId: "EV000291",
    valueDesc: "Chrome",
    label: "EV000291: Chrome"
  },
  {
    valueId: "EV000294",
    valueDesc: "Stainless steel",
    label: "EV000294: Stainless steel"
  },
  {
    valueId: "EV000300",
    valueDesc: "Titanium",
    label: "EV000300: Titanium"
  },
  {
    valueId: "EV000302",
    valueDesc: "Medium time-lag",
    label: "EV000302: Medium time-lag"
  },
  {
    valueId: "EV000303",
    valueDesc: "Acrylic glass",
    label: "EV000303: Acrylic glass"
  },
  {
    valueId: "EV000304",
    valueDesc: "Gilt",
    label: "EV000304: Gilt"
  },
  {
    valueId: "EV000309",
    valueDesc: "Anthracite",
    label: "EV000309: Anthracite"
  },
  {
    valueId: "EV000316",
    valueDesc: "One-part rocker",
    label: "EV000316: One-part rocker"
  },
  {
    valueId: "EV000317",
    valueDesc: "One-part button",
    label: "EV000317: One-part button"
  },
  {
    valueId: "EV000318",
    valueDesc: "Infrared receiver",
    label: "EV000318: Infrared receiver"
  },
  {
    valueId: "EV000319",
    valueDesc: "Central cover plate",
    label: "EV000319: Central cover plate"
  },
  {
    valueId: "EV000324",
    valueDesc: "Aluminium die-cast",
    label: "EV000324: Aluminium die-cast"
  },
  {
    valueId: "EV000327",
    valueDesc: "Opal",
    label: "EV000327: Opal"
  },
  {
    valueId: "EV000332",
    valueDesc: "Electronic ballast standard",
    label: "EV000332: Electronic ballast standard"
  },
  {
    valueId: "EV000334",
    valueDesc: "F",
    label: "EV000334: F"
  },
  {
    valueId: "EV000335",
    valueDesc: "Angle",
    label: "EV000335: Angle"
  },
  {
    valueId: "EV000337",
    valueDesc: "GX24d-2",
    label: "EV000337: GX24d-2"
  },
  {
    valueId: "EV000338",
    valueDesc: "GX24d-3",
    label: "EV000338: GX24d-3"
  },
  {
    valueId: "EV000339",
    valueDesc: "G23",
    label: "EV000339: G23"
  },
  {
    valueId: "EV000340",
    valueDesc: "2G11",
    label: "EV000340: 2G11"
  },
  {
    valueId: "EV000341",
    valueDesc: "2G10",
    label: "EV000341: 2G10"
  },
  {
    valueId: "EV000342",
    valueDesc: "2G7",
    label: "EV000342: 2G7"
  },
  {
    valueId: "EV000351",
    valueDesc: "Case",
    label: "EV000351: Case"
  },
  {
    valueId: "EV000352",
    valueDesc: "G24d-1",
    label: "EV000352: G24d-1"
  },
  {
    valueId: "EV000353",
    valueDesc: "G24d-2",
    label: "EV000353: G24d-2"
  },
  {
    valueId: "EV000354",
    valueDesc: "G24d-3",
    label: "EV000354: G24d-3"
  },
  {
    valueId: "EV000358",
    valueDesc: "External",
    label: "EV000358: External"
  },
  {
    valueId: "EV000361",
    valueDesc: "Crystal",
    label: "EV000361: Crystal"
  },
  {
    valueId: "EV000362",
    valueDesc: "Tubular shape",
    label: "EV000362: Tubular shape"
  },
  {
    valueId: "EV000371",
    valueDesc: "PG12-2",
    label: "EV000371: PG12-2"
  },
  {
    valueId: "EV000372",
    valueDesc: "BA9s",
    label: "EV000372: BA9s"
  },
  {
    valueId: "EV000373",
    valueDesc: "P13.5s",
    label: "EV000373: P13.5s"
  },
  {
    valueId: "EV000374",
    valueDesc: "S14s",
    label: "EV000374: S14s"
  },
  {
    valueId: "EV000375",
    valueDesc: "S14d",
    label: "EV000375: S14d"
  },
  {
    valueId: "EV000376",
    valueDesc: "Blind cap",
    label: "EV000376: Blind cap"
  },
  {
    valueId: "EV000377",
    valueDesc: "Loudspeaker connection",
    label: "EV000377: Loudspeaker connection"
  },
  {
    valueId: "EV000378",
    valueDesc: "TAE",
    label: "EV000378: TAE"
  },
  {
    valueId: "EV000379",
    valueDesc: "D-Sub 25-pole",
    label: "EV000379: D-Sub 25-pole"
  },
  {
    valueId: "EV000380",
    valueDesc: "BNC/TNC",
    label: "EV000380: BNC/TNC"
  },
  {
    valueId: "EV000381",
    valueDesc: "Socket outlet",
    label: "EV000381: Socket outlet"
  },
  {
    valueId: "EV000382",
    valueDesc: "Square",
    label: "EV000382: Square"
  },
  {
    valueId: "EV000383",
    valueDesc: "Flush-mounted",
    label: "EV000383: Flush-mounted"
  },
  {
    valueId: "EV000384",
    valueDesc: "Surface mounted",
    label: "EV000384: Surface mounted"
  },
  {
    valueId: "EV000388",
    valueDesc: "Light metal",
    label: "EV000388: Light metal"
  },
  {
    valueId: "EV000389",
    valueDesc: "Battery",
    label: "EV000389: Battery"
  },
  {
    valueId: "EV000390",
    valueDesc: "Electromechanical",
    label: "EV000390: Electromechanical"
  },
  {
    valueId: "EV000391",
    valueDesc: "Aluminium/plastic",
    label: "EV000391: Aluminium/plastic"
  },
  {
    valueId: "EV000392",
    valueDesc: "Red cast iron",
    label: "EV000392: Red cast iron"
  },
  {
    valueId: "EV000396",
    valueDesc: "Oval",
    label: "EV000396: Oval"
  },
  {
    valueId: "EV000397",
    valueDesc: "Off switch",
    label: "EV000397: Off switch"
  },
  {
    valueId: "EV000398",
    valueDesc: "Reverser",
    label: "EV000398: Reverser"
  },
  {
    valueId: "EV000399",
    valueDesc: "Acoustic",
    label: "EV000399: Acoustic"
  },
  {
    valueId: "EV000401",
    valueDesc: "3P+N+E",
    label: "EV000401: 3P+N+E"
  },
  {
    valueId: "EV000402",
    valueDesc: "Polyamide (PA)",
    label: "EV000402: Polyamide (PA)"
  },
  {
    valueId: "EV000403",
    valueDesc: "Thermoplastic",
    label: "EV000403: Thermoplastic"
  },
  {
    valueId: "EV000405",
    valueDesc: "Bottom mounting",
    label: "EV000405: Bottom mounting"
  },
  {
    valueId: "EV000407",
    valueDesc: "Push button",
    label: "EV000407: Push button"
  },
  {
    valueId: "EV000408",
    valueDesc: "Cable screw gland",
    label: "EV000408: Cable screw gland"
  },
  {
    valueId: "EV000409",
    valueDesc: "Angled",
    label: "EV000409: Angled"
  },
  {
    valueId: "EV000410",
    valueDesc: "Device plug",
    label: "EV000410: Device plug"
  },
  {
    valueId: "EV000411",
    valueDesc: "Switch/push button",
    label: "EV000411: Switch/push button"
  },
  {
    valueId: "EV000412",
    valueDesc: "Indication plate",
    label: "EV000412: Indication plate"
  },
  {
    valueId: "EV000413",
    valueDesc: "D",
    label: "EV000413: D"
  },
  {
    valueId: "EV000414",
    valueDesc: "Plug-in connection",
    label: "EV000414: Plug-in connection"
  },
  {
    valueId: "EV000415",
    valueDesc: "Screw connection",
    label: "EV000415: Screw connection"
  },
  {
    valueId: "EV000416",
    valueDesc: "Earth leakage switch",
    label: "EV000416: Earth leakage switch"
  },
  {
    valueId: "EV000420",
    valueDesc: "Plug-in lamp holder",
    label: "EV000420: Plug-in lamp holder"
  },
  {
    valueId: "EV000421",
    valueDesc: "Toggle",
    label: "EV000421: Toggle"
  },
  {
    valueId: "EV000423",
    valueDesc: "Infrared",
    label: "EV000423: Infrared"
  },
  {
    valueId: "EV000426",
    valueDesc: "Single",
    label: "EV000426: Single"
  },
  {
    valueId: "EV000427",
    valueDesc: "Phillips",
    label: "EV000427: Phillips"
  },
  {
    valueId: "EV000428",
    valueDesc: "Optical",
    label: "EV000428: Optical"
  },
  {
    valueId: "EV000430",
    valueDesc: "Pointed",
    label: "EV000430: Pointed"
  },
  {
    valueId: "EV000432",
    valueDesc: "LED",
    label: "EV000432: LED"
  },
  {
    valueId: "EV000433",
    valueDesc: "LCD",
    label: "EV000433: LCD"
  },
  {
    valueId: "EV000434",
    valueDesc: "Sheet metal",
    label: "EV000434: Sheet metal"
  },
  {
    valueId: "EV000442",
    valueDesc: "Bag",
    label: "EV000442: Bag"
  },
  {
    valueId: "EV000444",
    valueDesc: "Toothed",
    label: "EV000444: Toothed"
  },
  {
    valueId: "EV000445",
    valueDesc: "Sliding joint",
    label: "EV000445: Sliding joint"
  },
  {
    valueId: "EV000446",
    valueDesc: "Installation tube",
    label: "EV000446: Installation tube"
  },
  {
    valueId: "EV000447",
    valueDesc: "Subfloor duct",
    label: "EV000447: Subfloor duct"
  },
  {
    valueId: "EV000448",
    valueDesc: "Hydraulic",
    label: "EV000448: Hydraulic"
  },
  {
    valueId: "EV000449",
    valueDesc: "Battery (rechargeable)",
    label: "EV000449: Battery (rechargeable)"
  },
  {
    valueId: "EV000450",
    valueDesc: "Sleeve",
    label: "EV000450: Sleeve"
  },
  {
    valueId: "EV000452",
    valueDesc: "Natural stone",
    label: "EV000452: Natural stone"
  },
  {
    valueId: "EV000454",
    valueDesc: "Zinc die-cast",
    label: "EV000454: Zinc die-cast"
  },
  {
    valueId: "EV000457",
    valueDesc: "Empty",
    label: "EV000457: Empty"
  },
  {
    valueId: "EV000458",
    valueDesc: "LSA Plus",
    label: "EV000458: LSA Plus"
  },
  {
    valueId: "EV000459",
    valueDesc: "230 V",
    label: "EV000459: 230 V"
  },
  {
    valueId: "EV000460",
    valueDesc: "AC",
    label: "EV000460: AC"
  },
  {
    valueId: "EV000461",
    valueDesc: "DC",
    label: "EV000461: DC"
  },
  {
    valueId: "EV000462",
    valueDesc: "1 make contact (NO)",
    label: "EV000462: 1 make contact (NO)"
  },
  {
    valueId: "EV000463",
    valueDesc: "2 make contacts (NO)",
    label: "EV000463: 2 make contacts (NO)"
  },
  {
    valueId: "EV000464",
    valueDesc: "1 change-over contact",
    label: "EV000464: 1 change-over contact"
  },
  {
    valueId: "EV000465",
    valueDesc: "2 change-over contacts",
    label: "EV000465: 2 change-over contacts"
  },
  {
    valueId: "EV000466",
    valueDesc: "Built-in light sensor",
    label: "EV000466: Built-in light sensor"
  },
  {
    valueId: "EV000467",
    valueDesc: "Separate light sensor",
    label: "EV000467: Separate light sensor"
  },
  {
    valueId: "EV000468",
    valueDesc: "Separate light sensor/cable",
    label: "EV000468: Separate light sensor/cable"
  },
  {
    valueId: "EV000472",
    valueDesc: "Socket outlet for wall duct, 2-fold",
    label: "EV000472: Socket outlet for wall duct, 2-fold"
  },
  {
    valueId: "EV000473",
    valueDesc: "Socket outlet for wall duct, 3-fold",
    label: "EV000473: Socket outlet for wall duct, 3-fold"
  },
  {
    valueId: "EV000474",
    valueDesc: "Built-in device with framework",
    label: "EV000474: Built-in device with framework"
  },
  {
    valueId: "EV000475",
    valueDesc: "Devices with central plate",
    label: "EV000475: Devices with central plate"
  },
  {
    valueId: "EV000476",
    valueDesc: "60x60 mm",
    label: "EV000476: 60x60 mm"
  },
  {
    valueId: "EV000483",
    valueDesc: "2 breaker contacts (NC)",
    label: "EV000483: 2 breaker contacts (NC)"
  },
  {
    valueId: "EV000484",
    valueDesc: "Motor protective circuit-breaker",
    label: "EV000484: Motor protective circuit-breaker"
  },
  {
    valueId: "EV000486",
    valueDesc: "Day",
    label: "EV000486: Day"
  },
  {
    valueId: "EV000487",
    valueDesc: "Week",
    label: "EV000487: Week"
  },
  {
    valueId: "EV000488",
    valueDesc: "Day/week",
    label: "EV000488: Day/week"
  },
  {
    valueId: "EV000489",
    valueDesc: "Quartz",
    label: "EV000489: Quartz"
  },
  {
    valueId: "EV000490",
    valueDesc: "1 breaker contact (NC)",
    label: "EV000490: 1 breaker contact (NC)"
  },
  {
    valueId: "EV000491",
    valueDesc: "Without cable",
    label: "EV000491: Without cable"
  },
  {
    valueId: "EV000494",
    valueDesc: "None",
    label: "EV000494: None"
  },
  {
    valueId: "EV000498",
    valueDesc: "CEE devices 60 mm mounting distance",
    label: "EV000498: CEE devices 60 mm mounting distance"
  },
  {
    valueId: "EV000499",
    valueDesc: "1-pole switch",
    label: "EV000499: 1-pole switch"
  },
  {
    valueId: "EV000500",
    valueDesc: "2-pole switch",
    label: "EV000500: 2-pole switch"
  },
  {
    valueId: "EV000502",
    valueDesc: "1-pole push button",
    label: "EV000502: 1-pole push button"
  },
  {
    valueId: "EV000503",
    valueDesc: "2-pole push button",
    label: "EV000503: 2-pole push button"
  },
  {
    valueId: "EV000506",
    valueDesc: "RAM",
    label: "EV000506: RAM"
  },
  {
    valueId: "EV000507",
    valueDesc: "EPROM",
    label: "EV000507: EPROM"
  },
  {
    valueId: "EV000508",
    valueDesc: "EEPROM",
    label: "EV000508: EEPROM"
  },
  {
    valueId: "EV000509",
    valueDesc: "AC/DC",
    label: "EV000509: AC/DC"
  },
  {
    valueId: "EV000510",
    valueDesc: "PNP",
    label: "EV000510: PNP"
  },
  {
    valueId: "EV000511",
    valueDesc: "Relay",
    label: "EV000511: Relay"
  },
  {
    valueId: "EV000512",
    valueDesc: "Cylindrical",
    label: "EV000512: Cylindrical"
  },
  {
    valueId: "EV000516",
    valueDesc: "Fixing clamp",
    label: "EV000516: Fixing clamp"
  },
  {
    valueId: "EV000517",
    valueDesc: "Eye hook",
    label: "EV000517: Eye hook"
  },
  {
    valueId: "EV000518",
    valueDesc: "Double hook",
    label: "EV000518: Double hook"
  },
  {
    valueId: "EV000526",
    valueDesc: "Distributor board mounting",
    label: "EV000526: Distributor board mounting"
  },
  {
    valueId: "EV000527",
    valueDesc: "Plunger",
    label: "EV000527: Plunger"
  },
  {
    valueId: "EV000528",
    valueDesc: "Roller cam",
    label: "EV000528: Roller cam"
  },
  {
    valueId: "EV000529",
    valueDesc: "Roller lever",
    label: "EV000529: Roller lever"
  },
  {
    valueId: "EV000530",
    valueDesc: "Rotary lever",
    label: "EV000530: Rotary lever"
  },
  {
    valueId: "EV000531",
    valueDesc: "Actuating rod",
    label: "EV000531: Actuating rod"
  },
  {
    valueId: "EV000533",
    valueDesc: "Quick-break switch",
    label: "EV000533: Quick-break switch"
  },
  {
    valueId: "EV000534",
    valueDesc: "Square roller lever",
    label: "EV000534: Square roller lever"
  },
  {
    valueId: "EV000535",
    valueDesc: "Flash light",
    label: "EV000535: Flash light"
  },
  {
    valueId: "EV000536",
    valueDesc: "Blinker light",
    label: "EV000536: Blinker light"
  },
  {
    valueId: "EV000537",
    valueDesc: "Continuous light",
    label: "EV000537: Continuous light"
  },
  {
    valueId: "EV000543",
    valueDesc: "Varistor (voltage-sensitive resistor)",
    label: "EV000543: Varistor (voltage-sensitive resistor)"
  },
  {
    valueId: "EV000544",
    valueDesc: "Clip-on",
    label: "EV000544: Clip-on"
  },
  {
    valueId: "EV000545",
    valueDesc: "Contacts",
    label: "EV000545: Contacts"
  },
  {
    valueId: "EV000546",
    valueDesc: "Printed circuit boards",
    label: "EV000546: Printed circuit boards"
  },
  {
    valueId: "EV000547",
    valueDesc: "Plastic surfaces",
    label: "EV000547: Plastic surfaces"
  },
  {
    valueId: "EV000548",
    valueDesc: "Electrical systems",
    label: "EV000548: Electrical systems"
  },
  {
    valueId: "EV000549",
    valueDesc: "Precision mechanics",
    label: "EV000549: Precision mechanics"
  },
  {
    valueId: "EV000550",
    valueDesc: "Corrosion protection",
    label: "EV000550: Corrosion protection"
  },
  {
    valueId: "EV000551",
    valueDesc: "Mechanical locking",
    label: "EV000551: Mechanical locking"
  },
  {
    valueId: "EV000552",
    valueDesc: "Continuously variable",
    label: "EV000552: Continuously variable"
  },
  {
    valueId: "EV000554",
    valueDesc: "Push button",
    label: "EV000554: Push button"
  },
  {
    valueId: "EV000555",
    valueDesc: "Symbol 'I'",
    label: "EV000555: Symbol 'I'"
  },
  {
    valueId: "EV000556",
    valueDesc: "Symbol '0'",
    label: "EV000556: Symbol '0'"
  },
  {
    valueId: "EV000558",
    valueDesc: "Left/right",
    label: "EV000558: Left/right"
  },
  {
    valueId: "EV000560",
    valueDesc: "T-shape",
    label: "EV000560: T-shape"
  },
  {
    valueId: "EV000561",
    valueDesc: "Metal/plastic",
    label: "EV000561: Metal/plastic"
  },
  {
    valueId: "EV000562",
    valueDesc: "Stop",
    label: "EV000562: Stop"
  },
  {
    valueId: "EV000563",
    valueDesc: "Ceiling built-in",
    label: "EV000563: Ceiling built-in"
  },
  {
    valueId: "EV000566",
    valueDesc: "Intermediate mounting",
    label: "EV000566: Intermediate mounting"
  },
  {
    valueId: "EV000567",
    valueDesc: "Suspension cable",
    label: "EV000567: Suspension cable"
  },
  {
    valueId: "EV000568",
    valueDesc: "D-system",
    label: "EV000568: D-system"
  },
  {
    valueId: "EV000569",
    valueDesc: "D0-system",
    label: "EV000569: D0-system"
  },
  {
    valueId: "EV000570",
    valueDesc: "NH",
    label: "EV000570: NH"
  },
  {
    valueId: "EV000572",
    valueDesc: "Ceramics",
    label: "EV000572: Ceramics"
  },
  {
    valueId: "EV000575",
    valueDesc: "Swivelling",
    label: "EV000575: Swivelling"
  },
  {
    valueId: "EV000582",
    valueDesc: "I",
    label: "EV000582: I"
  },
  {
    valueId: "EV000583",
    valueDesc: "II",
    label: "EV000583: II"
  },
  {
    valueId: "EV000586",
    valueDesc: "Polycarbonate (PC)",
    label: "EV000586: Polycarbonate (PC)"
  },
  {
    valueId: "EV000591",
    valueDesc: "PG12-1",
    label: "EV000591: PG12-1"
  },
  {
    valueId: "EV000592",
    valueDesc: "Radio frequency (RF)",
    label: "EV000592: Radio frequency (RF)"
  },
  {
    valueId: "EV000593",
    valueDesc: "Continuous tone",
    label: "EV000593: Continuous tone"
  },
  {
    valueId: "EV000598",
    valueDesc: "Slider with 2 inlets",
    label: "EV000598: Slider with 2 inlets"
  },
  {
    valueId: "EV000599",
    valueDesc: "1",
    label: "EV000599: 1"
  },
  {
    valueId: "EV000600",
    valueDesc: "2",
    label: "EV000600: 2"
  },
  {
    valueId: "EV000601",
    valueDesc: "0",
    label: "EV000601: 0"
  },
  {
    valueId: "EV000602",
    valueDesc: "Symbol 'II'",
    label: "EV000602: Symbol 'II'"
  },
  {
    valueId: "EV000603",
    valueDesc: "Wall built-in",
    label: "EV000603: Wall built-in"
  },
  {
    valueId: "EV000604",
    valueDesc: "Aeration",
    label: "EV000604: Aeration"
  },
  {
    valueId: "EV000605",
    valueDesc: "De-aeration",
    label: "EV000605: De-aeration"
  },
  {
    valueId: "EV000607",
    valueDesc: "Roof shape",
    label: "EV000607: Roof shape"
  },
  {
    valueId: "EV000612",
    valueDesc: "Rotating current",
    label: "EV000612: Rotating current"
  },
  {
    valueId: "EV000613",
    valueDesc: "Self-acting",
    label: "EV000613: Self-acting"
  },
  {
    valueId: "EV000614",
    valueDesc: "Mounting plate",
    label: "EV000614: Mounting plate"
  },
  {
    valueId: "EV000615",
    valueDesc: "Thermostat",
    label: "EV000615: Thermostat"
  },
  {
    valueId: "EV000616",
    valueDesc: "Candle",
    label: "EV000616: Candle"
  },
  {
    valueId: "EV000621",
    valueDesc: "With clamps",
    label: "EV000621: With clamps"
  },
  {
    valueId: "EV000622",
    valueDesc: "With bus bar",
    label: "EV000622: With bus bar"
  },
  {
    valueId: "EV000623",
    valueDesc: "1-story",
    label: "EV000623: 1-story"
  },
  {
    valueId: "EV000624",
    valueDesc: "2-story",
    label: "EV000624: 2-story"
  },
  {
    valueId: "EV000625",
    valueDesc: "Narrow",
    label: "EV000625: Narrow"
  },
  {
    valueId: "EV000627",
    valueDesc: "W2x4.6d",
    label: "EV000627: W2x4.6d"
  },
  {
    valueId: "EV000629",
    valueDesc: "Busbar",
    label: "EV000629: Busbar"
  },
  {
    valueId: "EV000634",
    valueDesc: "Pictogram",
    label: "EV000634: Pictogram"
  },
  {
    valueId: "EV000635",
    valueDesc: "For 1-story support plates",
    label: "EV000635: For 1-story support plates"
  },
  {
    valueId: "EV000636",
    valueDesc: "For 2-story support plates",
    label: "EV000636: For 2-story support plates"
  },
  {
    valueId: "EV000637",
    valueDesc: "1-rows",
    label: "EV000637: 1-rows"
  },
  {
    valueId: "EV000638",
    valueDesc: "2-rows",
    label: "EV000638: 2-rows"
  },
  {
    valueId: "EV000639",
    valueDesc: "3-rows",
    label: "EV000639: 3-rows"
  },
  {
    valueId: "EV000640",
    valueDesc: "2- and 4-rows",
    label: "EV000640: 2- and 4-rows"
  },
  {
    valueId: "EV000641",
    valueDesc: "3xbrown / 1xblue",
    label: "EV000641: 3xbrown / 1xblue"
  },
  {
    valueId: "EV000642",
    valueDesc: "3xbrown / 1xgreen/yellow",
    label: "EV000642: 3xbrown / 1xgreen/yellow"
  },
  {
    valueId: "EV000643",
    valueDesc: "3xbrown / 1xblue / 1xgreen/yellow",
    label: "EV000643: 3xbrown / 1xblue / 1xgreen/yellow"
  },
  {
    valueId: "EV000644",
    valueDesc: "Atop",
    label: "EV000644: Atop"
  },
  {
    valueId: "EV000645",
    valueDesc: "Bottom",
    label: "EV000645: Bottom"
  },
  {
    valueId: "EV000646",
    valueDesc: "Miniature circuit breaker (MCB)",
    label: "EV000646: Miniature circuit breaker (MCB)"
  },
  {
    valueId: "EV000647",
    valueDesc: "E",
    label: "EV000647: E"
  },
  {
    valueId: "EV000648",
    valueDesc: "Two-way switch",
    label: "EV000648: Two-way switch"
  },
  {
    valueId: "EV000649",
    valueDesc: "With bus bar 3-pole",
    label: "EV000649: With bus bar 3-pole"
  },
  {
    valueId: "EV000650",
    valueDesc: "With bus bar 2x3-pole",
    label: "EV000650: With bus bar 2x3-pole"
  },
  {
    valueId: "EV000653",
    valueDesc: "GX4",
    label: "EV000653: GX4"
  },
  {
    valueId: "EV000654",
    valueDesc: "UV filter",
    label: "EV000654: UV filter"
  },
  {
    valueId: "EV000657",
    valueDesc: "High-gloss",
    label: "EV000657: High-gloss"
  },
  {
    valueId: "EV000659",
    valueDesc: "G13",
    label: "EV000659: G13"
  },
  {
    valueId: "EV000669",
    valueDesc: "Semi round",
    label: "EV000669: Semi round"
  },
  {
    valueId: "EV000675",
    valueDesc: "Corner connector",
    label: "EV000675: Corner connector"
  },
  {
    valueId: "EV000676",
    valueDesc: "T-connector",
    label: "EV000676: T-connector"
  },
  {
    valueId: "EV000677",
    valueDesc: "Cross connector",
    label: "EV000677: Cross connector"
  },
  {
    valueId: "EV000680",
    valueDesc: "Toroidal core transformer",
    label: "EV000680: Toroidal core transformer"
  },
  {
    valueId: "EV000681",
    valueDesc: "Electronic transformer",
    label: "EV000681: Electronic transformer"
  },
  {
    valueId: "EV000682",
    valueDesc: "Distance piece",
    label: "EV000682: Distance piece"
  },
  {
    valueId: "EV000683",
    valueDesc: "G11",
    label: "EV000683: G11"
  },
  {
    valueId: "EV000685",
    valueDesc: "Variable",
    label: "EV000685: Variable"
  },
  {
    valueId: "EV000686",
    valueDesc: "5x2.5 mm²",
    label: "EV000686: 5x2.5 mm²"
  },
  {
    valueId: "EV000692",
    valueDesc: "End cap",
    label: "EV000692: End cap"
  },
  {
    valueId: "EV000693",
    valueDesc: "Knot chain",
    label: "EV000693: Knot chain"
  },
  {
    valueId: "EV000703",
    valueDesc: "Fabric",
    label: "EV000703: Fabric"
  },
  {
    valueId: "EV000705",
    valueDesc: "Metal/wood",
    label: "EV000705: Metal/wood"
  },
  {
    valueId: "EV000706",
    valueDesc: "Two-sided",
    label: "EV000706: Two-sided"
  },
  {
    valueId: "EV000711",
    valueDesc: "Black/white",
    label: "EV000711: Black/white"
  },
  {
    valueId: "EV000716",
    valueDesc: "Marble",
    label: "EV000716: Marble"
  },
  {
    valueId: "EV000717",
    valueDesc: "Screw mounting",
    label: "EV000717: Screw mounting"
  },
  {
    valueId: "EV000719",
    valueDesc: "Without cover",
    label: "EV000719: Without cover"
  },
  {
    valueId: "EV000724",
    valueDesc: "Floor",
    label: "EV000724: Floor"
  },
  {
    valueId: "EV000725",
    valueDesc: "Ground spike",
    label: "EV000725: Ground spike"
  },
  {
    valueId: "EV000726",
    valueDesc: "Supporting bracket",
    label: "EV000726: Supporting bracket"
  },
  {
    valueId: "EV000728",
    valueDesc: "N-Connector",
    label: "EV000728: N-Connector"
  },
  {
    valueId: "EV000731",
    valueDesc: "RJ45",
    label: "EV000731: RJ45"
  },
  {
    valueId: "EV000732",
    valueDesc: "RJ12 6(6)",
    label: "EV000732: RJ12 6(6)"
  },
  {
    valueId: "EV000733",
    valueDesc: "D-Sub 9-pole",
    label: "EV000733: D-Sub 9-pole"
  },
  {
    valueId: "EV000734",
    valueDesc: "D-Sub 15-pole",
    label: "EV000734: D-Sub 15-pole"
  },
  {
    valueId: "EV000735",
    valueDesc: "Centronics 35-pole",
    label: "EV000735: Centronics 35-pole"
  },
  {
    valueId: "EV000736",
    valueDesc: "Diode, 5-pole",
    label: "EV000736: Diode, 5-pole"
  },
  {
    valueId: "EV000737",
    valueDesc: "Mini DIN 6 pole",
    label: "EV000737: Mini DIN 6 pole"
  },
  {
    valueId: "EV000740",
    valueDesc: "IBM data plug",
    label: "EV000740: IBM data plug"
  },
  {
    valueId: "EV000741",
    valueDesc: "SC",
    label: "EV000741: SC"
  },
  {
    valueId: "EV000742",
    valueDesc: "Cable eye",
    label: "EV000742: Cable eye"
  },
  {
    valueId: "EV000743",
    valueDesc: "LED/LCD",
    label: "EV000743: LED/LCD"
  },
  {
    valueId: "EV000751",
    valueDesc: "FDDI",
    label: "EV000751: FDDI"
  },
  {
    valueId: "EV000754",
    valueDesc: "D-Sub bus 9-pole",
    label: "EV000754: D-Sub bus 9-pole"
  },
  {
    valueId: "EV000756",
    valueDesc: "D-Sub bus 15-pole",
    label: "EV000756: D-Sub bus 15-pole"
  },
  {
    valueId: "EV000758",
    valueDesc: "D-Sub bus 25-pole",
    label: "EV000758: D-Sub bus 25-pole"
  },
  {
    valueId: "EV000759",
    valueDesc: "D-Sub plug 25-pole",
    label: "EV000759: D-Sub plug 25-pole"
  },
  {
    valueId: "EV000763",
    valueDesc: "F-Connector",
    label: "EV000763: F-Connector"
  },
  {
    valueId: "EV000764",
    valueDesc: "Solder",
    label: "EV000764: Solder"
  },
  {
    valueId: "EV000765",
    valueDesc: "Crimping",
    label: "EV000765: Crimping"
  },
  {
    valueId: "EV000766",
    valueDesc: "Screwing",
    label: "EV000766: Screwing"
  },
  {
    valueId: "EV000767",
    valueDesc: "Angle plug",
    label: "EV000767: Angle plug"
  },
  {
    valueId: "EV000768",
    valueDesc: "21-pole Scart",
    label: "EV000768: 21-pole Scart"
  },
  {
    valueId: "EV000770",
    valueDesc: "HD-D-Sub 15-pole",
    label: "EV000770: HD-D-Sub 15-pole"
  },
  {
    valueId: "EV000771",
    valueDesc: "DIN 5-pole",
    label: "EV000771: DIN 5-pole"
  },
  {
    valueId: "EV000775",
    valueDesc: "Rear panel",
    label: "EV000775: Rear panel"
  },
  {
    valueId: "EV000776",
    valueDesc: "Roof",
    label: "EV000776: Roof"
  },
  {
    valueId: "EV000777",
    valueDesc: "482.6 mm (19 inch) mounting",
    label: "EV000777: 482.6 mm (19 inch) mounting"
  },
  {
    valueId: "EV000778",
    valueDesc: "A4 (ISO 216)",
    label: "EV000778: A4 (ISO 216)"
  },
  {
    valueId: "EV000779",
    valueDesc: "A5 (ISO 216)",
    label: "EV000779: A5 (ISO 216)"
  },
  {
    valueId: "EV000785",
    valueDesc: "Rail base",
    label: "EV000785: Rail base"
  },
  {
    valueId: "EV000788",
    valueDesc: "Mounting rail",
    label: "EV000788: Mounting rail"
  },
  {
    valueId: "EV000792",
    valueDesc: "Fibre optic cable guide",
    label: "EV000792: Fibre optic cable guide"
  },
  {
    valueId: "EV000793",
    valueDesc: "Cable bracket",
    label: "EV000793: Cable bracket"
  },
  {
    valueId: "EV000794",
    valueDesc: "Dehumidification",
    label: "EV000794: Dehumidification"
  },
  {
    valueId: "EV000814",
    valueDesc: "G5",
    label: "EV000814: G5"
  },
  {
    valueId: "EV000816",
    valueDesc: "AS4",
    label: "EV000816: AS4"
  },
  {
    valueId: "EV000818",
    valueDesc: "Banana plug",
    label: "EV000818: Banana plug"
  },
  {
    valueId: "EV000819",
    valueDesc: "Polyester (PET)",
    label: "EV000819: Polyester (PET)"
  },
  {
    valueId: "EV000823",
    valueDesc: "Protective tube reflector",
    label: "EV000823: Protective tube reflector"
  },
  {
    valueId: "EV000827",
    valueDesc: "Copper braiding",
    label: "EV000827: Copper braiding"
  },
  {
    valueId: "EV000833",
    valueDesc: "Sub-clock",
    label: "EV000833: Sub-clock"
  },
  {
    valueId: "EV000834",
    valueDesc: "Analogue, arabic figures",
    label: "EV000834: Analogue, arabic figures"
  },
  {
    valueId: "EV000835",
    valueDesc: "Analogue, bars",
    label: "EV000835: Analogue, bars"
  },
  {
    valueId: "EV000836",
    valueDesc: "Gutter/roof",
    label: "EV000836: Gutter/roof"
  },
  {
    valueId: "EV000837",
    valueDesc: "Pipe heating",
    label: "EV000837: Pipe heating"
  },
  {
    valueId: "EV000840",
    valueDesc: "Heat shrinking technique",
    label: "EV000840: Heat shrinking technique"
  },
  {
    valueId: "EV000841",
    valueDesc: "Dry adhesive technology",
    label: "EV000841: Dry adhesive technology"
  },
  {
    valueId: "EV000842",
    valueDesc: "Heating cable/connection cable",
    label: "EV000842: Heating cable/connection cable"
  },
  {
    valueId: "EV000843",
    valueDesc: "Heating cable/heating cable",
    label: "EV000843: Heating cable/heating cable"
  },
  {
    valueId: "EV000844",
    valueDesc: "Heating cable end piece",
    label: "EV000844: Heating cable end piece"
  },
  {
    valueId: "EV000845",
    valueDesc: "T-branch",
    label: "EV000845: T-branch"
  },
  {
    valueId: "EV000846",
    valueDesc: "Heating strip/housing",
    label: "EV000846: Heating strip/housing"
  },
  {
    valueId: "EV000847",
    valueDesc: "Equipped with closing",
    label: "EV000847: Equipped with closing"
  },
  {
    valueId: "EV000848",
    valueDesc: "Provided with 3 connection sets",
    label: "EV000848: Provided with 3 connection sets"
  },
  {
    valueId: "EV000849",
    valueDesc: "Without accessories",
    label: "EV000849: Without accessories"
  },
  {
    valueId: "EV000850",
    valueDesc: "Value coin",
    label: "EV000850: Value coin"
  },
  {
    valueId: "EV000851",
    valueDesc: "Time-dependant",
    label: "EV000851: Time-dependant"
  },
  {
    valueId: "EV000852",
    valueDesc: "Consumption dependent",
    label: "EV000852: Consumption dependent"
  },
  {
    valueId: "EV000853",
    valueDesc: "Microprocessor",
    label: "EV000853: Microprocessor"
  },
  {
    valueId: "EV000854",
    valueDesc: "3 digit display",
    label: "EV000854: 3 digit display"
  },
  {
    valueId: "EV000855",
    valueDesc: "230/400 V",
    label: "EV000855: 230/400 V"
  },
  {
    valueId: "EV000857",
    valueDesc: "Adhesive magnet",
    label: "EV000857: Adhesive magnet"
  },
  {
    valueId: "EV000858",
    valueDesc: "Screw-on plate",
    label: "EV000858: Screw-on plate"
  },
  {
    valueId: "EV000859",
    valueDesc: "Table clamp",
    label: "EV000859: Table clamp"
  },
  {
    valueId: "EV000863",
    valueDesc: "G2",
    label: "EV000863: G2"
  },
  {
    valueId: "EV000866",
    valueDesc: "Diode",
    label: "EV000866: Diode"
  },
  {
    valueId: "EV000867",
    valueDesc: "Outdoor station",
    label: "EV000867: Outdoor station"
  },
  {
    valueId: "EV000868",
    valueDesc: "Wall mounting",
    label: "EV000868: Wall mounting"
  },
  {
    valueId: "EV000869",
    valueDesc: "Wall model",
    label: "EV000869: Wall model"
  },
  {
    valueId: "EV000870",
    valueDesc: "Free standing model",
    label: "EV000870: Free standing model"
  },
  {
    valueId: "EV000873",
    valueDesc: "Alkali manganese",
    label: "EV000873: Alkali manganese"
  },
  {
    valueId: "EV000874",
    valueDesc: "Zinc/coal",
    label: "EV000874: Zinc/coal"
  },
  {
    valueId: "EV000875",
    valueDesc: "Mono",
    label: "EV000875: Mono"
  },
  {
    valueId: "EV000876",
    valueDesc: "Baby",
    label: "EV000876: Baby"
  },
  {
    valueId: "EV000877",
    valueDesc: "Mignon",
    label: "EV000877: Mignon"
  },
  {
    valueId: "EV000878",
    valueDesc: "Micro",
    label: "EV000878: Micro"
  },
  {
    valueId: "EV000880",
    valueDesc: "Flat",
    label: "EV000880: Flat"
  },
  {
    valueId: "EV000881",
    valueDesc: "Duplex",
    label: "EV000881: Duplex"
  },
  {
    valueId: "EV000882",
    valueDesc: "Flat-Pack",
    label: "EV000882: Flat-Pack"
  },
  {
    valueId: "EV000883",
    valueDesc: "Halogen lamp",
    label: "EV000883: Halogen lamp"
  },
  {
    valueId: "EV000884",
    valueDesc: "Alkali manganese/zinc",
    label: "EV000884: Alkali manganese/zinc"
  },
  {
    valueId: "EV000885",
    valueDesc: "Lithium/manganese dioxide",
    label: "EV000885: Lithium/manganese dioxide"
  },
  {
    valueId: "EV000886",
    valueDesc: "Mercury oxide/zinc",
    label: "EV000886: Mercury oxide/zinc"
  },
  {
    valueId: "EV000887",
    valueDesc: "Pocket calculator",
    label: "EV000887: Pocket calculator"
  },
  {
    valueId: "EV000888",
    valueDesc: "Mounting angle",
    label: "EV000888: Mounting angle"
  },
  {
    valueId: "EV000891",
    valueDesc: "SC duplex",
    label: "EV000891: SC duplex"
  },
  {
    valueId: "EV000892",
    valueDesc: "Connection terminal",
    label: "EV000892: Connection terminal"
  },
  {
    valueId: "EV000896",
    valueDesc: "A",
    label: "EV000896: A"
  },
  {
    valueId: "EV000897",
    valueDesc: "With thread tap",
    label: "EV000897: With thread tap"
  },
  {
    valueId: "EV000898",
    valueDesc: "With ceiling hooks",
    label: "EV000898: With ceiling hooks"
  },
  {
    valueId: "EV000899",
    valueDesc: "Bare",
    label: "EV000899: Bare"
  },
  {
    valueId: "EV000900",
    valueDesc: "Large head",
    label: "EV000900: Large head"
  },
  {
    valueId: "EV000901",
    valueDesc: "Rounded head",
    label: "EV000901: Rounded head"
  },
  {
    valueId: "EV000905",
    valueDesc: "Video",
    label: "EV000905: Video"
  },
  {
    valueId: "EV000906",
    valueDesc: "Sealing flange",
    label: "EV000906: Sealing flange"
  },
  {
    valueId: "EV000907",
    valueDesc: "Conduit inlet",
    label: "EV000907: Conduit inlet"
  },
  {
    valueId: "EV000909",
    valueDesc: "Standard door opener",
    label: "EV000909: Standard door opener"
  },
  {
    valueId: "EV000910",
    valueDesc: "Escape door opener",
    label: "EV000910: Escape door opener"
  },
  {
    valueId: "EV000911",
    valueDesc: "Door code opener",
    label: "EV000911: Door code opener"
  },
  {
    valueId: "EV000913",
    valueDesc: "Silver oxide/zinc",
    label: "EV000913: Silver oxide/zinc"
  },
  {
    valueId: "EV000914",
    valueDesc: "Metal",
    label: "EV000914: Metal"
  },
  {
    valueId: "EV000915",
    valueDesc: "Without indication",
    label: "EV000915: Without indication"
  },
  {
    valueId: "EV000916",
    valueDesc: "Manual testing device",
    label: "EV000916: Manual testing device"
  },
  {
    valueId: "EV000917",
    valueDesc: "CATV testing device (Cable TV)",
    label: "EV000917: CATV testing device (Cable TV)"
  },
  {
    valueId: "EV000918",
    valueDesc: "Attenuation measuring device",
    label: "EV000918: Attenuation measuring device"
  },
  {
    valueId: "EV000919",
    valueDesc: "Reflection meter",
    label: "EV000919: Reflection meter"
  },
  {
    valueId: "EV000920",
    valueDesc: "PC-cable tester",
    label: "EV000920: PC-cable tester"
  },
  {
    valueId: "EV000921",
    valueDesc: "LAN Function testing device",
    label: "EV000921: LAN Function testing device"
  },
  {
    valueId: "EV000930",
    valueDesc: "Inner corner",
    label: "EV000930: Inner corner"
  },
  {
    valueId: "EV000931",
    valueDesc: "Outer corner",
    label: "EV000931: Outer corner"
  },
  {
    valueId: "EV000933",
    valueDesc: "T-piece",
    label: "EV000933: T-piece"
  },
  {
    valueId: "EV000934",
    valueDesc: "Polystyrene (PS)",
    label: "EV000934: Polystyrene (PS)"
  },
  {
    valueId: "EV000935",
    valueDesc: "Separating partition",
    label: "EV000935: Separating partition"
  },
  {
    valueId: "EV000937",
    valueDesc: "Inside",
    label: "EV000937: Inside"
  },
  {
    valueId: "EV000940",
    valueDesc: "Interface testing device",
    label: "EV000940: Interface testing device"
  },
  {
    valueId: "EV000941",
    valueDesc: "DIN rail (top hat rail)",
    label: "EV000941: DIN rail (top hat rail)"
  },
  {
    valueId: "EV000943",
    valueDesc: "Series switch",
    label: "EV000943: Series switch"
  },
  {
    valueId: "EV000944",
    valueDesc: "Dimmer",
    label: "EV000944: Dimmer"
  },
  {
    valueId: "EV000945",
    valueDesc: "Surface mounting",
    label: "EV000945: Surface mounting"
  },
  {
    valueId: "EV000947",
    valueDesc: "Connection strip",
    label: "EV000947: Connection strip"
  },
  {
    valueId: "EV000948",
    valueDesc: "Earthing strip",
    label: "EV000948: Earthing strip"
  },
  {
    valueId: "EV000949",
    valueDesc: "Separating strip",
    label: "EV000949: Separating strip"
  },
  {
    valueId: "EV000950",
    valueDesc: "Solder/solder",
    label: "EV000950: Solder/solder"
  },
  {
    valueId: "EV000953",
    valueDesc: "Screw/solder",
    label: "EV000953: Screw/solder"
  },
  {
    valueId: "EV000954",
    valueDesc: "Screw/screw",
    label: "EV000954: Screw/screw"
  },
  {
    valueId: "EV000955",
    valueDesc: "Screw/solder - screw/solder",
    label: "EV000955: Screw/solder - screw/solder"
  },
  {
    valueId: "EV000957",
    valueDesc: "Earthing rail",
    label: "EV000957: Earthing rail"
  },
  {
    valueId: "EV000958",
    valueDesc: "Audio module",
    label: "EV000958: Audio module"
  },
  {
    valueId: "EV000959",
    valueDesc: "Charge module",
    label: "EV000959: Charge module"
  },
  {
    valueId: "EV000960",
    valueDesc: "Automatic change over switch",
    label: "EV000960: Automatic change over switch"
  },
  {
    valueId: "EV000961",
    valueDesc: "Tariff time switch",
    label: "EV000961: Tariff time switch"
  },
  {
    valueId: "EV000962",
    valueDesc: "Tele control head-set",
    label: "EV000962: Tele control head-set"
  },
  {
    valueId: "EV000963",
    valueDesc: "Locking device",
    label: "EV000963: Locking device"
  },
  {
    valueId: "EV000964",
    valueDesc: "Power-current switch",
    label: "EV000964: Power-current switch"
  },
  {
    valueId: "EV000966",
    valueDesc: "Telephone ringer side-unit",
    label: "EV000966: Telephone ringer side-unit"
  },
  {
    valueId: "EV000971",
    valueDesc: "96 mm",
    label: "EV000971: 96 mm"
  },
  {
    valueId: "EV000972",
    valueDesc: "170 mm",
    label: "EV000972: 170 mm"
  },
  {
    valueId: "EV000973",
    valueDesc: "96/170 mm",
    label: "EV000973: 96/170 mm"
  },
  {
    valueId: "EV000975",
    valueDesc: "Pay phone",
    label: "EV000975: Pay phone"
  },
  {
    valueId: "EV000977",
    valueDesc: "Wall",
    label: "EV000977: Wall"
  },
  {
    valueId: "EV000978",
    valueDesc: "Buzzer",
    label: "EV000978: Buzzer"
  },
  {
    valueId: "EV000979",
    valueDesc: "Manual/automatic",
    label: "EV000979: Manual/automatic"
  },
  {
    valueId: "EV000984",
    valueDesc: "Angle bus",
    label: "EV000984: Angle bus"
  },
  {
    valueId: "EV000987",
    valueDesc: "Single push button",
    label: "EV000987: Single push button"
  },
  {
    valueId: "EV000989",
    valueDesc: "D-Sub/Centronics 25-pole",
    label: "EV000989: D-Sub/Centronics 25-pole"
  },
  {
    valueId: "EV000990",
    valueDesc: "Surface mounted device plug",
    label: "EV000990: Surface mounted device plug"
  },
  {
    valueId: "EV000991",
    valueDesc: "Cabling panel",
    label: "EV000991: Cabling panel"
  },
  {
    valueId: "EV000994",
    valueDesc: "Analogue/digital",
    label: "EV000994: Analogue/digital"
  },
  {
    valueId: "EV000995",
    valueDesc: "Flexible",
    label: "EV000995: Flexible"
  },
  {
    valueId: "EV001005",
    valueDesc: "Built-in installation box",
    label: "EV001005: Built-in installation box"
  },
  {
    valueId: "EV001007",
    valueDesc: "Optical/acoustic",
    label: "EV001007: Optical/acoustic"
  },
  {
    valueId: "EV001008",
    valueDesc: "Screwed terminal",
    label: "EV001008: Screwed terminal"
  },
  {
    valueId: "EV001009",
    valueDesc: "ISDN",
    label: "EV001009: ISDN"
  },
  {
    valueId: "EV001011",
    valueDesc: "Plastic, metallized",
    label: "EV001011: Plastic, metallized"
  },
  {
    valueId: "EV001012",
    valueDesc: "Cover",
    label: "EV001012: Cover"
  },
  {
    valueId: "EV001014",
    valueDesc: "Corner joint",
    label: "EV001014: Corner joint"
  },
  {
    valueId: "EV001023",
    valueDesc: "Plug/plug",
    label: "EV001023: Plug/plug"
  },
  {
    valueId: "EV001024",
    valueDesc: "Plug/bus",
    label: "EV001024: Plug/bus"
  },
  {
    valueId: "EV001025",
    valueDesc: "Bus/bus",
    label: "EV001025: Bus/bus"
  },
  {
    valueId: "EV001026",
    valueDesc: "IIC",
    label: "EV001026: IIC"
  },
  {
    valueId: "EV001027",
    valueDesc: "Switch module",
    label: "EV001027: Switch module"
  },
  {
    valueId: "EV001035",
    valueDesc: "1/N/PE/230 V",
    label: "EV001035: 1/N/PE/230 V"
  },
  {
    valueId: "EV001037",
    valueDesc: "3/N/PE/400 V",
    label: "EV001037: 3/N/PE/400 V"
  },
  {
    valueId: "EV001038",
    valueDesc: "3 handles",
    label: "EV001038: 3 handles"
  },
  {
    valueId: "EV001039",
    valueDesc: "Plastic-covered",
    label: "EV001039: Plastic-covered"
  },
  {
    valueId: "EV001041",
    valueDesc: "Wall storage tank",
    label: "EV001041: Wall storage tank"
  },
  {
    valueId: "EV001043",
    valueDesc: "Vertical storage tank",
    label: "EV001043: Vertical storage tank"
  },
  {
    valueId: "EV001045",
    valueDesc: "Built-under",
    label: "EV001045: Built-under"
  },
  {
    valueId: "EV001046",
    valueDesc: "Over sink",
    label: "EV001046: Over sink"
  },
  {
    valueId: "EV001049",
    valueDesc: "2 steps",
    label: "EV001049: 2 steps"
  },
  {
    valueId: "EV001050",
    valueDesc: "3 steps",
    label: "EV001050: 3 steps"
  },
  {
    valueId: "EV001051",
    valueDesc: "4 steps",
    label: "EV001051: 4 steps"
  },
  {
    valueId: "EV001052",
    valueDesc: "Bare wire",
    label: "EV001052: Bare wire"
  },
  {
    valueId: "EV001053",
    valueDesc: "Tube heating element",
    label: "EV001053: Tube heating element"
  },
  {
    valueId: "EV001054",
    valueDesc: "Thermal",
    label: "EV001054: Thermal"
  },
  {
    valueId: "EV001057",
    valueDesc: "Thermostatic tap",
    label: "EV001057: Thermostatic tap"
  },
  {
    valueId: "EV001060",
    valueDesc: "Washbasin",
    label: "EV001060: Washbasin"
  },
  {
    valueId: "EV001062",
    valueDesc: "Kitchen",
    label: "EV001062: Kitchen"
  },
  {
    valueId: "EV001063",
    valueDesc: "Shower",
    label: "EV001063: Shower"
  },
  {
    valueId: "EV001067",
    valueDesc: "Standard accumulator",
    label: "EV001067: Standard accumulator"
  },
  {
    valueId: "EV001068",
    valueDesc: "Flat storage",
    label: "EV001068: Flat storage"
  },
  {
    valueId: "EV001069",
    valueDesc: "Baseboard storage heating",
    label: "EV001069: Baseboard storage heating"
  },
  {
    valueId: "EV001070",
    valueDesc: "High storage",
    label: "EV001070: High storage"
  },
  {
    valueId: "EV001071",
    valueDesc: "Kitchen accumulator",
    label: "EV001071: Kitchen accumulator"
  },
  {
    valueId: "EV001072",
    valueDesc: "1/N/PE~230 V, 3/N/PE~400 V",
    label: "EV001072: 1/N/PE~230 V, 3/N/PE~400 V"
  },
  {
    valueId: "EV001078",
    valueDesc: "Sledge hammer",
    label: "EV001078: Sledge hammer"
  },
  {
    valueId: "EV001079",
    valueDesc: "Telephone cable shears",
    label: "EV001079: Telephone cable shears"
  },
  {
    valueId: "EV001080",
    valueDesc: "Electrician's shears",
    label: "EV001080: Electrician's shears"
  },
  {
    valueId: "EV001081",
    valueDesc: "Clamping console",
    label: "EV001081: Clamping console"
  },
  {
    valueId: "EV001082",
    valueDesc: "Over sink/under sink",
    label: "EV001082: Over sink/under sink"
  },
  {
    valueId: "EV001085",
    valueDesc: "Clamp piece",
    label: "EV001085: Clamp piece"
  },
  {
    valueId: "EV001093",
    valueDesc: "Silicone",
    label: "EV001093: Silicone"
  },
  {
    valueId: "EV001099",
    valueDesc: "Label removal",
    label: "EV001099: Label removal"
  },
  {
    valueId: "EV001100",
    valueDesc: "Tight",
    label: "EV001100: Tight"
  },
  {
    valueId: "EV001101",
    valueDesc: "Polyethylene, foamed (foam PE)",
    label: "EV001101: Polyethylene, foamed (foam PE)"
  },
  {
    valueId: "EV001106",
    valueDesc: "Cable end sleeve",
    label: "EV001106: Cable end sleeve"
  },
  {
    valueId: "EV001108",
    valueDesc: "Polyolefin",
    label: "EV001108: Polyolefin"
  },
  {
    valueId: "EV001109",
    valueDesc: "Fc2",
    label: "EV001109: Fc2"
  },
  {
    valueId: "EV001112",
    valueDesc: "Metal halide lamp",
    label: "EV001112: Metal halide lamp"
  },
  {
    valueId: "EV001113",
    valueDesc: "High pressure sodium-vapour lamp",
    label: "EV001113: High pressure sodium-vapour lamp"
  },
  {
    valueId: "EV001114",
    valueDesc: "Metal halide/sodium lamp",
    label: "EV001114: Metal halide/sodium lamp"
  },
  {
    valueId: "EV001116",
    valueDesc: "M13 x 1.0",
    label: "EV001116: M13 x 1.0"
  },
  {
    valueId: "EV001117",
    valueDesc: "M10 x 1.0",
    label: "EV001117: M10 x 1.0"
  },
  {
    valueId: "EV001118",
    valueDesc: "End piece",
    label: "EV001118: End piece"
  },
  {
    valueId: "EV001119",
    valueDesc: "Edison lamp holder",
    label: "EV001119: Edison lamp holder"
  },
  {
    valueId: "EV001120",
    valueDesc: "Reduction lamp holder",
    label: "EV001120: Reduction lamp holder"
  },
  {
    valueId: "EV001121",
    valueDesc: "Hanging lamp holder",
    label: "EV001121: Hanging lamp holder"
  },
  {
    valueId: "EV001122",
    valueDesc: "Ceiling lamp holder",
    label: "EV001122: Ceiling lamp holder"
  },
  {
    valueId: "EV001123",
    valueDesc: "Illumination lamp holder",
    label: "EV001123: Illumination lamp holder"
  },
  {
    valueId: "EV001131",
    valueDesc: "Solar vertical storage tank",
    label: "EV001131: Solar vertical storage tank"
  },
  {
    valueId: "EV001133",
    valueDesc: "Floor sensor",
    label: "EV001133: Floor sensor"
  },
  {
    valueId: "EV001141",
    valueDesc: "Nickel cadmium",
    label: "EV001141: Nickel cadmium"
  },
  {
    valueId: "EV001143",
    valueDesc: "Serial",
    label: "EV001143: Serial"
  },
  {
    valueId: "EV001144",
    valueDesc: "Forward control",
    label: "EV001144: Forward control"
  },
  {
    valueId: "EV001145",
    valueDesc: "Backward controller",
    label: "EV001145: Backward controller"
  },
  {
    valueId: "EV001146",
    valueDesc: "Forward-/backward control",
    label: "EV001146: Forward-/backward control"
  },
  {
    valueId: "EV001147",
    valueDesc: "Time control device",
    label: "EV001147: Time control device"
  },
  {
    valueId: "EV001148",
    valueDesc: "Group controller",
    label: "EV001148: Group controller"
  },
  {
    valueId: "EV001149",
    valueDesc: "Display",
    label: "EV001149: Display"
  },
  {
    valueId: "EV001158",
    valueDesc: "Drilling template",
    label: "EV001158: Drilling template"
  },
  {
    valueId: "EV001159",
    valueDesc: "FM",
    label: "EV001159: FM"
  },
  {
    valueId: "EV001160",
    valueDesc: "UHF",
    label: "EV001160: UHF"
  },
  {
    valueId: "EV001161",
    valueDesc: "VHF",
    label: "EV001161: VHF"
  },
  {
    valueId: "EV001162",
    valueDesc: "Receiver diplexer",
    label: "EV001162: Receiver diplexer"
  },
  {
    valueId: "EV001163",
    valueDesc: "F-bus",
    label: "EV001163: F-bus"
  },
  {
    valueId: "EV001164",
    valueDesc: "Splitter",
    label: "EV001164: Splitter"
  },
  {
    valueId: "EV001165",
    valueDesc: "Distributor",
    label: "EV001165: Distributor"
  },
  {
    valueId: "EV001167",
    valueDesc: "Repeater amplifier",
    label: "EV001167: Repeater amplifier"
  },
  {
    valueId: "EV001168",
    valueDesc: "Feed-in amplifier",
    label: "EV001168: Feed-in amplifier"
  },
  {
    valueId: "EV001169",
    valueDesc: "Amplifier substation",
    label: "EV001169: Amplifier substation"
  },
  {
    valueId: "EV001170",
    valueDesc: "In-house distribution amplifier",
    label: "EV001170: In-house distribution amplifier"
  },
  {
    valueId: "EV001171",
    valueDesc: "Distribution amplifier",
    label: "EV001171: Distribution amplifier"
  },
  {
    valueId: "EV001172",
    valueDesc: "Line amplifier",
    label: "EV001172: Line amplifier"
  },
  {
    valueId: "EV001173",
    valueDesc: "Return path amplifier",
    label: "EV001173: Return path amplifier"
  },
  {
    valueId: "EV001174",
    valueDesc: "DiSEqC",
    label: "EV001174: DiSEqC"
  },
  {
    valueId: "EV001175",
    valueDesc: "Single LNB",
    label: "EV001175: Single LNB"
  },
  {
    valueId: "EV001176",
    valueDesc: "Twin LNB",
    label: "EV001176: Twin LNB"
  },
  {
    valueId: "EV001177",
    valueDesc: "Quatro LNB",
    label: "EV001177: Quatro LNB"
  },
  {
    valueId: "EV001183",
    valueDesc: "Heat pump",
    label: "EV001183: Heat pump"
  },
  {
    valueId: "EV001184",
    valueDesc: "Single cable system",
    label: "EV001184: Single cable system"
  },
  {
    valueId: "EV001186",
    valueDesc: "Active",
    label: "EV001186: Active"
  },
  {
    valueId: "EV001187",
    valueDesc: "Passive",
    label: "EV001187: Passive"
  },
  {
    valueId: "EV001188",
    valueDesc: "0/22 kHz",
    label: "EV001188: 0/22 kHz"
  },
  {
    valueId: "EV001192",
    valueDesc: "Multi-band amplifier",
    label: "EV001192: Multi-band amplifier"
  },
  {
    valueId: "EV001196",
    valueDesc: "Active/passive",
    label: "EV001196: Active/passive"
  },
  {
    valueId: "EV001198",
    valueDesc: "Above floor shaft",
    label: "EV001198: Above floor shaft"
  },
  {
    valueId: "EV001199",
    valueDesc: "Subfloor shaft",
    label: "EV001199: Subfloor shaft"
  },
  {
    valueId: "EV001203",
    valueDesc: "Active return path module",
    label: "EV001203: Active return path module"
  },
  {
    valueId: "EV001205",
    valueDesc: "ESB",
    label: "EV001205: ESB"
  },
  {
    valueId: "EV001206",
    valueDesc: "Double flange plug",
    label: "EV001206: Double flange plug"
  },
  {
    valueId: "EV001208",
    valueDesc: "Telescopic mast",
    label: "EV001208: Telescopic mast"
  },
  {
    valueId: "EV001209",
    valueDesc: "Plug-in mast",
    label: "EV001209: Plug-in mast"
  },
  {
    valueId: "EV001210",
    valueDesc: "Mast pipe",
    label: "EV001210: Mast pipe"
  },
  {
    valueId: "EV001211",
    valueDesc: "Stand pipe holder",
    label: "EV001211: Stand pipe holder"
  },
  {
    valueId: "EV001212",
    valueDesc: "Wall mount",
    label: "EV001212: Wall mount"
  },
  {
    valueId: "EV001214",
    valueDesc: "Rotational speed controller",
    label: "EV001214: Rotational speed controller"
  },
  {
    valueId: "EV001216",
    valueDesc: "Wall-/conduit mounting",
    label: "EV001216: Wall-/conduit mounting"
  },
  {
    valueId: "EV001222",
    valueDesc: "Coaxial (IEC)",
    label: "EV001222: Coaxial (IEC)"
  },
  {
    valueId: "EV001223",
    valueDesc: "Remote sensor",
    label: "EV001223: Remote sensor"
  },
  {
    valueId: "EV001224",
    valueDesc: "Sideways",
    label: "EV001224: Sideways"
  },
  {
    valueId: "EV001225",
    valueDesc: "Bus insert",
    label: "EV001225: Bus insert"
  },
  {
    valueId: "EV001226",
    valueDesc: "Pin insert",
    label: "EV001226: Pin insert"
  },
  {
    valueId: "EV001232",
    valueDesc: "Loop-through socket",
    label: "EV001232: Loop-through socket"
  },
  {
    valueId: "EV001235",
    valueDesc: "Temperature controller",
    label: "EV001235: Temperature controller"
  },
  {
    valueId: "EV001236",
    valueDesc: "Basic device for multi switch system",
    label: "EV001236: Basic device for multi switch system"
  },
  {
    valueId: "EV001239",
    valueDesc: "FM amplifier",
    label: "EV001239: FM amplifier"
  },
  {
    valueId: "EV001241",
    valueDesc: "Light sensor",
    label: "EV001241: Light sensor"
  },
  {
    valueId: "EV001242",
    valueDesc: "Presence sensor",
    label: "EV001242: Presence sensor"
  },
  {
    valueId: "EV001246",
    valueDesc: "Test point adapter",
    label: "EV001246: Test point adapter"
  },
  {
    valueId: "EV001247",
    valueDesc: "Surface mounted/recessed mounted",
    label: "EV001247: Surface mounted/recessed mounted"
  },
  {
    valueId: "EV001248",
    valueDesc: "Push button, normally open",
    label: "EV001248: Push button, normally open"
  },
  {
    valueId: "EV001249",
    valueDesc: "2x1-pole",
    label: "EV001249: 2x1-pole"
  },
  {
    valueId: "EV001251",
    valueDesc: "Soldering connection",
    label: "EV001251: Soldering connection"
  },
  {
    valueId: "EV001252",
    valueDesc: "Rocker lever",
    label: "EV001252: Rocker lever"
  },
  {
    valueId: "EV001255",
    valueDesc: "Insert socket outlet protection",
    label: "EV001255: Insert socket outlet protection"
  },
  {
    valueId: "EV001256",
    valueDesc: "Self adhesive socket outlet protection",
    label: "EV001256: Self adhesive socket outlet protection"
  },
  {
    valueId: "EV001258",
    valueDesc: "End switch",
    label: "EV001258: End switch"
  },
  {
    valueId: "EV001264",
    valueDesc: "D-Sub 36-pole",
    label: "EV001264: D-Sub 36-pole"
  },
  {
    valueId: "EV001269",
    valueDesc: "Data processing technology",
    label: "EV001269: Data processing technology"
  },
  {
    valueId: "EV001270",
    valueDesc: "Glue and screw",
    label: "EV001270: Glue and screw"
  },
  {
    valueId: "EV001273",
    valueDesc: "Coaxial cable testing device",
    label: "EV001273: Coaxial cable testing device"
  },
  {
    valueId: "EV001275",
    valueDesc: "Patch cable tester",
    label: "EV001275: Patch cable tester"
  },
  {
    valueId: "EV001280",
    valueDesc: "Thermal release",
    label: "EV001280: Thermal release"
  },
  {
    valueId: "EV001281",
    valueDesc: "Thermal/magnetic release",
    label: "EV001281: Thermal/magnetic release"
  },
  {
    valueId: "EV001282",
    valueDesc: "Push button switch",
    label: "EV001282: Push button switch"
  },
  {
    valueId: "EV001283",
    valueDesc: "Rotary toggle",
    label: "EV001283: Rotary toggle"
  },
  {
    valueId: "EV001284",
    valueDesc: "Mounting set",
    label: "EV001284: Mounting set"
  },
  {
    valueId: "EV001292",
    valueDesc: "Bushing",
    label: "EV001292: Bushing"
  },
  {
    valueId: "EV001293",
    valueDesc: "Partially isolated",
    label: "EV001293: Partially isolated"
  },
  {
    valueId: "EV001294",
    valueDesc: "Fully isolated",
    label: "EV001294: Fully isolated"
  },
  {
    valueId: "EV001296",
    valueDesc: "Connector (male)",
    label: "EV001296: Connector (male)"
  },
  {
    valueId: "EV001297",
    valueDesc: "Distribution terminal block",
    label: "EV001297: Distribution terminal block"
  },
  {
    valueId: "EV001299",
    valueDesc: "Cable duct",
    label: "EV001299: Cable duct"
  },
  {
    valueId: "EV001302",
    valueDesc: "Vertical",
    label: "EV001302: Vertical"
  },
  {
    valueId: "EV001303",
    valueDesc: "Full rubber",
    label: "EV001303: Full rubber"
  },
  {
    valueId: "EV001304",
    valueDesc: "Duroplast",
    label: "EV001304: Duroplast"
  },
  {
    valueId: "EV001305",
    valueDesc: "Pressed material",
    label: "EV001305: Pressed material"
  },
  {
    valueId: "EV001308",
    valueDesc: "Insulation displacement connector",
    label: "EV001308: Insulation displacement connector"
  },
  {
    valueId: "EV001309",
    valueDesc: "Vertically sideways",
    label: "EV001309: Vertically sideways"
  },
  {
    valueId: "EV001328",
    valueDesc: "Link chain",
    label: "EV001328: Link chain"
  },
  {
    valueId: "EV001339",
    valueDesc: "Hanger for trapezoidal ceiling (for chain suspension)",
    label: "EV001339: Hanger for trapezoidal ceiling (for chain suspension)"
  },
  {
    valueId: "EV001340",
    valueDesc: "Hanger for trapezoidal ceiling (trapezoidal shape)",
    label: "EV001340: Hanger for trapezoidal ceiling (trapezoidal shape)"
  },
  {
    valueId: "EV001345",
    valueDesc: "F-plug",
    label: "EV001345: F-plug"
  },
  {
    valueId: "EV001348",
    valueDesc: "Mast base",
    label: "EV001348: Mast base"
  },
  {
    valueId: "EV001350",
    valueDesc: "VHF/UHF",
    label: "EV001350: VHF/UHF"
  },
  {
    valueId: "EV001354",
    valueDesc: "Double profile",
    label: "EV001354: Double profile"
  },
  {
    valueId: "EV001355",
    valueDesc: "Back perforated",
    label: "EV001355: Back perforated"
  },
  {
    valueId: "EV001356",
    valueDesc: "3 sides punched",
    label: "EV001356: 3 sides punched"
  },
  {
    valueId: "EV001358",
    valueDesc: "Exit",
    label: "EV001358: Exit"
  },
  {
    valueId: "EV001360",
    valueDesc: "Push button",
    label: "EV001360: Push button"
  },
  {
    valueId: "EV001362",
    valueDesc: "Smoke detector",
    label: "EV001362: Smoke detector"
  },
  {
    valueId: "EV001363",
    valueDesc: "Manual T-Switch/X-Switch",
    label: "EV001363: Manual T-Switch/X-Switch"
  },
  {
    valueId: "EV001364",
    valueDesc: "Manual X-Switch",
    label: "EV001364: Manual X-Switch"
  },
  {
    valueId: "EV001365",
    valueDesc: "Manual T-Switch",
    label: "EV001365: Manual T-Switch"
  },
  {
    valueId: "EV001366",
    valueDesc: "Automatic/manual T-Switch",
    label: "EV001366: Automatic/manual T-Switch"
  },
  {
    valueId: "EV001367",
    valueDesc: "Automatic T-Switch",
    label: "EV001367: Automatic T-Switch"
  },
  {
    valueId: "EV001368",
    valueDesc: "Flat coupler",
    label: "EV001368: Flat coupler"
  },
  {
    valueId: "EV001369",
    valueDesc: "Interior coupler",
    label: "EV001369: Interior coupler"
  },
  {
    valueId: "EV001372",
    valueDesc: "Monitor/keyboard T-switch",
    label: "EV001372: Monitor/keyboard T-switch"
  },
  {
    valueId: "EV001375",
    valueDesc: "Test receiver",
    label: "EV001375: Test receiver"
  },
  {
    valueId: "EV001376",
    valueDesc: "Level tester",
    label: "EV001376: Level tester"
  },
  {
    valueId: "EV001377",
    valueDesc: "LCD/monitor",
    label: "EV001377: LCD/monitor"
  },
  {
    valueId: "EV001378",
    valueDesc: "LED/LCD/analogue",
    label: "EV001378: LED/LCD/analogue"
  },
  {
    valueId: "EV001381",
    valueDesc: "Chromated",
    label: "EV001381: Chromated"
  },
  {
    valueId: "EV001383",
    valueDesc: "Quick-lock",
    label: "EV001383: Quick-lock"
  },
  {
    valueId: "EV001385",
    valueDesc: "Group distributor cabinet",
    label: "EV001385: Group distributor cabinet"
  },
  {
    valueId: "EV001386",
    valueDesc: "Distributor cabinet",
    label: "EV001386: Distributor cabinet"
  },
  {
    valueId: "EV001391",
    valueDesc: "For cast-in-place concrete",
    label: "EV001391: For cast-in-place concrete"
  },
  {
    valueId: "EV001392",
    valueDesc: "For slab ceiling",
    label: "EV001392: For slab ceiling"
  },
  {
    valueId: "EV001394",
    valueDesc: "Surface mounted lamp holder",
    label: "EV001394: Surface mounted lamp holder"
  },
  {
    valueId: "EV001395",
    valueDesc: "Through-feed lamp holder",
    label: "EV001395: Through-feed lamp holder"
  },
  {
    valueId: "EV001396",
    valueDesc: "Covering cap",
    label: "EV001396: Covering cap"
  },
  {
    valueId: "EV001398",
    valueDesc: "Mounting kit",
    label: "EV001398: Mounting kit"
  },
  {
    valueId: "EV001399",
    valueDesc: "Universal anchor plug",
    label: "EV001399: Universal anchor plug"
  },
  {
    valueId: "EV001409",
    valueDesc: "G3/8 A",
    label: "EV001409: G3/8 A"
  },
  {
    valueId: "EV001412",
    valueDesc: "Cylinder",
    label: "EV001412: Cylinder"
  },
  {
    valueId: "EV001421",
    valueDesc: "N",
    label: "EV001421: N"
  },
  {
    valueId: "EV001426",
    valueDesc: "Transport cart",
    label: "EV001426: Transport cart"
  },
  {
    valueId: "EV001429",
    valueDesc: "Round/flat conductor",
    label: "EV001429: Round/flat conductor"
  },
  {
    valueId: "EV001430",
    valueDesc: "Impact head",
    label: "EV001430: Impact head"
  },
  {
    valueId: "EV001431",
    valueDesc: "Impact pin",
    label: "EV001431: Impact pin"
  },
  {
    valueId: "EV001432",
    valueDesc: "Malleable cast iron",
    label: "EV001432: Malleable cast iron"
  },
  {
    valueId: "EV001433",
    valueDesc: "Earthing lug",
    label: "EV001433: Earthing lug"
  },
  {
    valueId: "EV001434",
    valueDesc: "Equipped with connection lug",
    label: "EV001434: Equipped with connection lug"
  },
  {
    valueId: "EV001435",
    valueDesc: "With steel strip",
    label: "EV001435: With steel strip"
  },
  {
    valueId: "EV001436",
    valueDesc: "With tappet/bore hole",
    label: "EV001436: With tappet/bore hole"
  },
  {
    valueId: "EV001442",
    valueDesc: "GU10",
    label: "EV001442: GU10"
  },
  {
    valueId: "EV001444",
    valueDesc: "Earth rod",
    label: "EV001444: Earth rod"
  },
  {
    valueId: "EV001452",
    valueDesc: "HP open View/Unix",
    label: "EV001452: HP open View/Unix"
  },
  {
    valueId: "EV001473",
    valueDesc: "Crimp technology",
    label: "EV001473: Crimp technology"
  },
  {
    valueId: "EV001475",
    valueDesc: "In line power supply",
    label: "EV001475: In line power supply"
  },
  {
    valueId: "EV001478",
    valueDesc: "Handle",
    label: "EV001478: Handle"
  },
  {
    valueId: "EV001479",
    valueDesc: "Telephone/fax/modem switch",
    label: "EV001479: Telephone/fax/modem switch"
  },
  {
    valueId: "EV001480",
    valueDesc: "Front ring",
    label: "EV001480: Front ring"
  },
  {
    valueId: "EV001481",
    valueDesc: "Tinned",
    label: "EV001481: Tinned"
  },
  {
    valueId: "EV001483",
    valueDesc: "Petrolatum",
    label: "EV001483: Petrolatum"
  },
  {
    valueId: "EV001485",
    valueDesc: "Self-adhesive",
    label: "EV001485: Self-adhesive"
  },
  {
    valueId: "EV001486",
    valueDesc: "Sandwich cable guide",
    label: "EV001486: Sandwich cable guide"
  },
  {
    valueId: "EV001490",
    valueDesc: "Card telephone",
    label: "EV001490: Card telephone"
  },
  {
    valueId: "EV001494",
    valueDesc: "G10q",
    label: "EV001494: G10q"
  },
  {
    valueId: "EV001495",
    valueDesc: "2G13",
    label: "EV001495: 2G13"
  },
  {
    valueId: "EV001496",
    valueDesc: "W4.3",
    label: "EV001496: W4.3"
  },
  {
    valueId: "EV001497",
    valueDesc: "Fa6",
    label: "EV001497: Fa6"
  },
  {
    valueId: "EV001498",
    valueDesc: "With grip clamp",
    label: "EV001498: With grip clamp"
  },
  {
    valueId: "EV001499",
    valueDesc: "With screw clamp",
    label: "EV001499: With screw clamp"
  },
  {
    valueId: "EV001506",
    valueDesc: "Flat roof",
    label: "EV001506: Flat roof"
  },
  {
    valueId: "EV001514",
    valueDesc: "Round conductor",
    label: "EV001514: Round conductor"
  },
  {
    valueId: "EV001522",
    valueDesc: "T-/cross-/parallel connector",
    label: "EV001522: T-/cross-/parallel connector"
  },
  {
    valueId: "EV001523",
    valueDesc: "Parallel connector",
    label: "EV001523: Parallel connector"
  },
  {
    valueId: "EV001524",
    valueDesc: "Sleeve connection",
    label: "EV001524: Sleeve connection"
  },
  {
    valueId: "EV001526",
    valueDesc: "Clamp screw",
    label: "EV001526: Clamp screw"
  },
  {
    valueId: "EV001527",
    valueDesc: "Hexagon screw",
    label: "EV001527: Hexagon screw"
  },
  {
    valueId: "EV001528",
    valueDesc: "Flat round screw",
    label: "EV001528: Flat round screw"
  },
  {
    valueId: "EV001529",
    valueDesc: "Transistor",
    label: "EV001529: Transistor"
  },
  {
    valueId: "EV001530",
    valueDesc: "LCD/acoustic",
    label: "EV001530: LCD/acoustic"
  },
  {
    valueId: "EV001531",
    valueDesc: "With snap clamp",
    label: "EV001531: With snap clamp"
  },
  {
    valueId: "EV001535",
    valueDesc: "Yellow/black",
    label: "EV001535: Yellow/black"
  },
  {
    valueId: "EV001543",
    valueDesc: "G5.3-4.8",
    label: "EV001543: G5.3-4.8"
  },
  {
    valueId: "EV001546",
    valueDesc: "GX6.35",
    label: "EV001546: GX6.35"
  },
  {
    valueId: "EV001549",
    valueDesc: "Integrated",
    label: "EV001549: Integrated"
  },
  {
    valueId: "EV001550",
    valueDesc: "Extendable",
    label: "EV001550: Extendable"
  },
  {
    valueId: "EV001552",
    valueDesc: "RS-232",
    label: "EV001552: RS-232"
  },
  {
    valueId: "EV001555",
    valueDesc: "ISA",
    label: "EV001555: ISA"
  },
  {
    valueId: "EV001556",
    valueDesc: "PCI",
    label: "EV001556: PCI"
  },
  {
    valueId: "EV001557",
    valueDesc: "PCMCIA",
    label: "EV001557: PCMCIA"
  },
  {
    valueId: "EV001560",
    valueDesc: "Turquoise",
    label: "EV001560: Turquoise"
  },
  {
    valueId: "EV001561",
    valueDesc: "Equipped with closing and clamps",
    label: "EV001561: Equipped with closing and clamps"
  },
  {
    valueId: "EV001562",
    valueDesc: "GZ6.35",
    label: "EV001562: GZ6.35"
  },
  {
    valueId: "EV001563",
    valueDesc: "Socket outlet with protective contact CEE 7/3 (type F)",
    label: "EV001563: Socket outlet with protective contact CEE 7/3 (type F)"
  },
  {
    valueId: "EV001572",
    valueDesc: "Freestanding-/wall model",
    label: "EV001572: Freestanding-/wall model"
  },
  {
    valueId: "EV001580",
    valueDesc: "Assembly aid",
    label: "EV001580: Assembly aid"
  },
  {
    valueId: "EV001582",
    valueDesc: "Measuring instruments",
    label: "EV001582: Measuring instruments"
  },
  {
    valueId: "EV001583",
    valueDesc: "Electrical modules",
    label: "EV001583: Electrical modules"
  },
  {
    valueId: "EV001584",
    valueDesc: "Electronics/mechanics",
    label: "EV001584: Electronics/mechanics"
  },
  {
    valueId: "EV001585",
    valueDesc: "0/14/18 V",
    label: "EV001585: 0/14/18 V"
  },
  {
    valueId: "EV001587",
    valueDesc: "Carton (box)",
    label: "EV001587: Carton (box)"
  },
  {
    valueId: "EV001588",
    valueDesc: "MSV4",
    label: "EV001588: MSV4"
  },
  {
    valueId: "EV001592",
    valueDesc: "SAT IF amplifier",
    label: "EV001592: SAT IF amplifier"
  },
  {
    valueId: "EV001593",
    valueDesc: "Plasterboard plug",
    label: "EV001593: Plasterboard plug"
  },
  {
    valueId: "EV001598",
    valueDesc: "Fireproof partition box",
    label: "EV001598: Fireproof partition box"
  },
  {
    valueId: "EV001602",
    valueDesc: "CEE devices 70 mm mounting distance",
    label: "EV001602: CEE devices 70 mm mounting distance"
  },
  {
    valueId: "EV001604",
    valueDesc: "7",
    label: "EV001604: 7"
  },
  {
    valueId: "EV001607",
    valueDesc: "Long-slot",
    label: "EV001607: Long-slot"
  },
  {
    valueId: "EV001608",
    valueDesc: "Polypropylene (PP)",
    label: "EV001608: Polypropylene (PP)"
  },
  {
    valueId: "EV001609",
    valueDesc: "IR sensor",
    label: "EV001609: IR sensor"
  },
  {
    valueId: "EV001610",
    valueDesc: "Multi-sensor",
    label: "EV001610: Multi-sensor"
  },
  {
    valueId: "EV001620",
    valueDesc: "Flat conductor",
    label: "EV001620: Flat conductor"
  },
  {
    valueId: "EV001621",
    valueDesc: "Clamp connector",
    label: "EV001621: Clamp connector"
  },
  {
    valueId: "EV001622",
    valueDesc: "Double clamp screw",
    label: "EV001622: Double clamp screw"
  },
  {
    valueId: "EV001623",
    valueDesc: "Signal amplifier",
    label: "EV001623: Signal amplifier"
  },
  {
    valueId: "EV001624",
    valueDesc: "Signal converter",
    label: "EV001624: Signal converter"
  },
  {
    valueId: "EV001625",
    valueDesc: "Mini constant",
    label: "EV001625: Mini constant"
  },
  {
    valueId: "EV001626",
    valueDesc: "Controller unit",
    label: "EV001626: Controller unit"
  },
  {
    valueId: "EV001627",
    valueDesc: "Tracer dimmer/IR-controller",
    label: "EV001627: Tracer dimmer/IR-controller"
  },
  {
    valueId: "EV001628",
    valueDesc: "Coupling plug",
    label: "EV001628: Coupling plug"
  },
  {
    valueId: "EV001633",
    valueDesc: "Wall radiator",
    label: "EV001633: Wall radiator"
  },
  {
    valueId: "EV001634",
    valueDesc: "Free standing radiator",
    label: "EV001634: Free standing radiator"
  },
  {
    valueId: "EV001635",
    valueDesc: "Lamp holder for in-wall installation cable",
    label: "EV001635: Lamp holder for in-wall installation cable"
  },
  {
    valueId: "EV001636",
    valueDesc: "Small lamp holder",
    label: "EV001636: Small lamp holder"
  },
  {
    valueId: "EV001640",
    valueDesc: "Ceiling-/wall emitter",
    label: "EV001640: Ceiling-/wall emitter"
  },
  {
    valueId: "EV001641",
    valueDesc: "GR10",
    label: "EV001641: GR10"
  },
  {
    valueId: "EV001642",
    valueDesc: "GR8",
    label: "EV001642: GR8"
  },
  {
    valueId: "EV001646",
    valueDesc: "Z-element",
    label: "EV001646: Z-element"
  },
  {
    valueId: "EV001648",
    valueDesc: "Blood pressure measurement",
    label: "EV001648: Blood pressure measurement"
  },
  {
    valueId: "EV001649",
    valueDesc: "Blood pressure/pulse measurement",
    label: "EV001649: Blood pressure/pulse measurement"
  },
  {
    valueId: "EV001650",
    valueDesc: "Upper arm",
    label: "EV001650: Upper arm"
  },
  {
    valueId: "EV001651",
    valueDesc: "Wrist",
    label: "EV001651: Wrist"
  },
  {
    valueId: "EV001653",
    valueDesc: "Foot massage bath",
    label: "EV001653: Foot massage bath"
  },
  {
    valueId: "EV001656",
    valueDesc: "Steam iron",
    label: "EV001656: Steam iron"
  },
  {
    valueId: "EV001658",
    valueDesc: "Stick vacuum cleaner",
    label: "EV001658: Stick vacuum cleaner"
  },
  {
    valueId: "EV001659",
    valueDesc: "Canister-cylinder cleaner",
    label: "EV001659: Canister-cylinder cleaner"
  },
  {
    valueId: "EV001661",
    valueDesc: "Solar operation",
    label: "EV001661: Solar operation"
  },
  {
    valueId: "EV001663",
    valueDesc: "4 lines",
    label: "EV001663: 4 lines"
  },
  {
    valueId: "EV001664",
    valueDesc: "8 digits",
    label: "EV001664: 8 digits"
  },
  {
    valueId: "EV001665",
    valueDesc: "10 digits",
    label: "EV001665: 10 digits"
  },
  {
    valueId: "EV001666",
    valueDesc: "Wall-/desk type model",
    label: "EV001666: Wall-/desk type model"
  },
  {
    valueId: "EV001667",
    valueDesc: "Facial tanner",
    label: "EV001667: Facial tanner"
  },
  {
    valueId: "EV001668",
    valueDesc: "Upper body tanner",
    label: "EV001668: Upper body tanner"
  },
  {
    valueId: "EV001669",
    valueDesc: "Total body tanner",
    label: "EV001669: Total body tanner"
  },
  {
    valueId: "EV001671",
    valueDesc: "Sun lamp",
    label: "EV001671: Sun lamp"
  },
  {
    valueId: "EV001672",
    valueDesc: "Handheld hair dryer",
    label: "EV001672: Handheld hair dryer"
  },
  {
    valueId: "EV001673",
    valueDesc: "Travel hair dryer",
    label: "EV001673: Travel hair dryer"
  },
  {
    valueId: "EV001674",
    valueDesc: "Curl brush",
    label: "EV001674: Curl brush"
  },
  {
    valueId: "EV001675",
    valueDesc: "Curl staff",
    label: "EV001675: Curl staff"
  },
  {
    valueId: "EV001676",
    valueDesc: "Hair styler",
    label: "EV001676: Hair styler"
  },
  {
    valueId: "EV001677",
    valueDesc: "Drying hood",
    label: "EV001677: Drying hood"
  },
  {
    valueId: "EV001679",
    valueDesc: "Desktop device",
    label: "EV001679: Desktop device"
  },
  {
    valueId: "EV001684",
    valueDesc: "Mechanical/electronic",
    label: "EV001684: Mechanical/electronic"
  },
  {
    valueId: "EV001691",
    valueDesc: "Low weight flat-iron",
    label: "EV001691: Low weight flat-iron"
  },
  {
    valueId: "EV001692",
    valueDesc: "Travel iron",
    label: "EV001692: Travel iron"
  },
  {
    valueId: "EV001693",
    valueDesc: "Steam brush",
    label: "EV001693: Steam brush"
  },
  {
    valueId: "EV001694",
    valueDesc: "Steam generator",
    label: "EV001694: Steam generator"
  },
  {
    valueId: "EV001696",
    valueDesc: "Aluminium sole",
    label: "EV001696: Aluminium sole"
  },
  {
    valueId: "EV001697",
    valueDesc: "Ceramic sole",
    label: "EV001697: Ceramic sole"
  },
  {
    valueId: "EV001699",
    valueDesc: "Stainless steel sole",
    label: "EV001699: Stainless steel sole"
  },
  {
    valueId: "EV001704",
    valueDesc: "Dry shaver",
    label: "EV001704: Dry shaver"
  },
  {
    valueId: "EV001705",
    valueDesc: "Wet-/dry shaver",
    label: "EV001705: Wet-/dry shaver"
  },
  {
    valueId: "EV001706",
    valueDesc: "Battery (rechargeable)/mains",
    label: "EV001706: Battery (rechargeable)/mains"
  },
  {
    valueId: "EV001707",
    valueDesc: "Dry lady-shave",
    label: "EV001707: Dry lady-shave"
  },
  {
    valueId: "EV001708",
    valueDesc: "Wet-/dry lady-shave",
    label: "EV001708: Wet-/dry lady-shave"
  },
  {
    valueId: "EV001709",
    valueDesc: "Razor foil",
    label: "EV001709: Razor foil"
  },
  {
    valueId: "EV001710",
    valueDesc: "Double razor foil",
    label: "EV001710: Double razor foil"
  },
  {
    valueId: "EV001711",
    valueDesc: "2 razor heads",
    label: "EV001711: 2 razor heads"
  },
  {
    valueId: "EV001712",
    valueDesc: "3 razor heads",
    label: "EV001712: 3 razor heads"
  },
  {
    valueId: "EV001714",
    valueDesc: "Manicure set",
    label: "EV001714: Manicure set"
  },
  {
    valueId: "EV001715",
    valueDesc: "Pedicure set",
    label: "EV001715: Pedicure set"
  },
  {
    valueId: "EV001719",
    valueDesc: "Haircutter",
    label: "EV001719: Haircutter"
  },
  {
    valueId: "EV001720",
    valueDesc: "Beard trimmer",
    label: "EV001720: Beard trimmer"
  },
  {
    valueId: "EV001721",
    valueDesc: "Beard styler",
    label: "EV001721: Beard styler"
  },
  {
    valueId: "EV001722",
    valueDesc: "Nose hair trimmer",
    label: "EV001722: Nose hair trimmer"
  },
  {
    valueId: "EV001730",
    valueDesc: "Infrared proximity switch",
    label: "EV001730: Infrared proximity switch"
  },
  {
    valueId: "EV001731",
    valueDesc: "Time switch",
    label: "EV001731: Time switch"
  },
  {
    valueId: "EV001732",
    valueDesc: "Thermal under blanket",
    label: "EV001732: Thermal under blanket"
  },
  {
    valueId: "EV001733",
    valueDesc: "Thermal over blanket",
    label: "EV001733: Thermal over blanket"
  },
  {
    valueId: "EV001734",
    valueDesc: "Electric pad",
    label: "EV001734: Electric pad"
  },
  {
    valueId: "EV001735",
    valueDesc: "Heated carpet",
    label: "EV001735: Heated carpet"
  },
  {
    valueId: "EV001736",
    valueDesc: "Foot warmer",
    label: "EV001736: Foot warmer"
  },
  {
    valueId: "EV001741",
    valueDesc: "With DIN rails (top hat rails)",
    label: "EV001741: With DIN rails (top hat rails)"
  },
  {
    valueId: "EV001744",
    valueDesc: "Toothbrush + mouth shower",
    label: "EV001744: Toothbrush + mouth shower"
  },
  {
    valueId: "EV001745",
    valueDesc: "Children toothbrush",
    label: "EV001745: Children toothbrush"
  },
  {
    valueId: "EV001746",
    valueDesc: "Toothbrush",
    label: "EV001746: Toothbrush"
  },
  {
    valueId: "EV001747",
    valueDesc: "Mouth shower",
    label: "EV001747: Mouth shower"
  },
  {
    valueId: "EV001752",
    valueDesc: "Textile",
    label: "EV001752: Textile"
  },
  {
    valueId: "EV001753",
    valueDesc: "Coated",
    label: "EV001753: Coated"
  },
  {
    valueId: "EV001754",
    valueDesc: "Back-/neck thermal pillow",
    label: "EV001754: Back-/neck thermal pillow"
  },
  {
    valueId: "EV001755",
    valueDesc: "Surface mounted/partially recessed mounted",
    label: "EV001755: Surface mounted/partially recessed mounted"
  },
  {
    valueId: "EV001761",
    valueDesc: "28",
    label: "EV001761: 28"
  },
  {
    valueId: "EV001762",
    valueDesc: "25",
    label: "EV001762: 25"
  },
  {
    valueId: "EV001773",
    valueDesc: "Hand mixer",
    label: "EV001773: Hand mixer"
  },
  {
    valueId: "EV001774",
    valueDesc: "Standing blender",
    label: "EV001774: Standing blender"
  },
  {
    valueId: "EV001775",
    valueDesc: "Hand blender",
    label: "EV001775: Hand blender"
  },
  {
    valueId: "EV001776",
    valueDesc: "5 steps",
    label: "EV001776: 5 steps"
  },
  {
    valueId: "EV001777",
    valueDesc: "1- and 2-rows",
    label: "EV001777: 1- and 2-rows"
  },
  {
    valueId: "EV001780",
    valueDesc: "Hand tin opener",
    label: "EV001780: Hand tin opener"
  },
  {
    valueId: "EV001781",
    valueDesc: "Standing tin opener",
    label: "EV001781: Standing tin opener"
  },
  {
    valueId: "EV001782",
    valueDesc: "Knife sharpener",
    label: "EV001782: Knife sharpener"
  },
  {
    valueId: "EV001783",
    valueDesc: "Knife-/shears blade sharpener",
    label: "EV001783: Knife-/shears blade sharpener"
  },
  {
    valueId: "EV001784",
    valueDesc: "Universal sharpener",
    label: "EV001784: Universal sharpener"
  },
  {
    valueId: "EV001785",
    valueDesc: "Table scales",
    label: "EV001785: Table scales"
  },
  {
    valueId: "EV001786",
    valueDesc: "Wall scales",
    label: "EV001786: Wall scales"
  },
  {
    valueId: "EV001787",
    valueDesc: "Citrus press",
    label: "EV001787: Citrus press"
  },
  {
    valueId: "EV001788",
    valueDesc: "Spin juicer",
    label: "EV001788: Spin juicer"
  },
  {
    valueId: "EV001790",
    valueDesc: "Screwable",
    label: "EV001790: Screwable"
  },
  {
    valueId: "EV001791",
    valueDesc: "Characters 0 and 1",
    label: "EV001791: Characters 0 and 1"
  },
  {
    valueId: "EV001792",
    valueDesc: "Plug/connector",
    label: "EV001792: Plug/connector"
  },
  {
    valueId: "EV001796",
    valueDesc: "Non-stick coated",
    label: "EV001796: Non-stick coated"
  },
  {
    valueId: "EV001797",
    valueDesc: "Table grill",
    label: "EV001797: Table grill"
  },
  {
    valueId: "EV001798",
    valueDesc: "Grill station",
    label: "EV001798: Grill station"
  },
  {
    valueId: "EV001799",
    valueDesc: "Contact grill",
    label: "EV001799: Contact grill"
  },
  {
    valueId: "EV001800",
    valueDesc: "Coated grill plate",
    label: "EV001800: Coated grill plate"
  },
  {
    valueId: "EV001801",
    valueDesc: "Coated grill grid",
    label: "EV001801: Coated grill grid"
  },
  {
    valueId: "EV001802",
    valueDesc: "Grill grid aluminium die-cast",
    label: "EV001802: Grill grid aluminium die-cast"
  },
  {
    valueId: "EV001803",
    valueDesc: "Chrome plated grill grid",
    label: "EV001803: Chrome plated grill grid"
  },
  {
    valueId: "EV001806",
    valueDesc: "Espresso machine",
    label: "EV001806: Espresso machine"
  },
  {
    valueId: "EV001807",
    valueDesc: "Espresso/cappuccino",
    label: "EV001807: Espresso/cappuccino"
  },
  {
    valueId: "EV001808",
    valueDesc: "Coffee/espresso/cappuccino",
    label: "EV001808: Coffee/espresso/cappuccino"
  },
  {
    valueId: "EV001809",
    valueDesc: "Steam pressure system",
    label: "EV001809: Steam pressure system"
  },
  {
    valueId: "EV001810",
    valueDesc: "Thermo block system",
    label: "EV001810: Thermo block system"
  },
  {
    valueId: "EV001811",
    valueDesc: "Pumping system",
    label: "EV001811: Pumping system"
  },
  {
    valueId: "EV001812",
    valueDesc: "Baking oven",
    label: "EV001812: Baking oven"
  },
  {
    valueId: "EV001813",
    valueDesc: "Baking grill",
    label: "EV001813: Baking grill"
  },
  {
    valueId: "EV001814",
    valueDesc: "Top heat/bottom heat",
    label: "EV001814: Top heat/bottom heat"
  },
  {
    valueId: "EV001815",
    valueDesc: "Top heat/bottom heat/grill",
    label: "EV001815: Top heat/bottom heat/grill"
  },
  {
    valueId: "EV001816",
    valueDesc: "Fan heating/grill",
    label: "EV001816: Fan heating/grill"
  },
  {
    valueId: "EV001817",
    valueDesc: "Household immersion heater",
    label: "EV001817: Household immersion heater"
  },
  {
    valueId: "EV001818",
    valueDesc: "Travel immersion heater",
    label: "EV001818: Travel immersion heater"
  },
  {
    valueId: "EV001820",
    valueDesc: "Hinged mast",
    label: "EV001820: Hinged mast"
  },
  {
    valueId: "EV001821",
    valueDesc: "Warming tray",
    label: "EV001821: Warming tray"
  },
  {
    valueId: "EV001822",
    valueDesc: "Tea light",
    label: "EV001822: Tea light"
  },
  {
    valueId: "EV001823",
    valueDesc: "1 step",
    label: "EV001823: 1 step"
  },
  {
    valueId: "EV001831",
    valueDesc: "Coffee mill",
    label: "EV001831: Coffee mill"
  },
  {
    valueId: "EV001832",
    valueDesc: "Espresso grinder",
    label: "EV001832: Espresso grinder"
  },
  {
    valueId: "EV001834",
    valueDesc: "Stone-mill system",
    label: "EV001834: Stone-mill system"
  },
  {
    valueId: "EV001835",
    valueDesc: "Fly cutter",
    label: "EV001835: Fly cutter"
  },
  {
    valueId: "EV001837",
    valueDesc: "Coax",
    label: "EV001837: Coax"
  },
  {
    valueId: "EV001838",
    valueDesc: "Automatic preserving cooker",
    label: "EV001838: Automatic preserving cooker"
  },
  {
    valueId: "EV001839",
    valueDesc: "Electric soup pan",
    label: "EV001839: Electric soup pan"
  },
  {
    valueId: "EV001843",
    valueDesc: "2-slice toaster",
    label: "EV001843: 2-slice toaster"
  },
  {
    valueId: "EV001844",
    valueDesc: "Long slot toaster",
    label: "EV001844: Long slot toaster"
  },
  {
    valueId: "EV001845",
    valueDesc: "4-slice toaster",
    label: "EV001845: 4-slice toaster"
  },
  {
    valueId: "EV001846",
    valueDesc: "Permanent grease filter",
    label: "EV001846: Permanent grease filter"
  },
  {
    valueId: "EV001847",
    valueDesc: "Charcoal filter",
    label: "EV001847: Charcoal filter"
  },
  {
    valueId: "EV001852",
    valueDesc: "Mounting base",
    label: "EV001852: Mounting base"
  },
  {
    valueId: "EV001853",
    valueDesc: "Waffle maker",
    label: "EV001853: Waffle maker"
  },
  {
    valueId: "EV001854",
    valueDesc: "Croissant maker",
    label: "EV001854: Croissant maker"
  },
  {
    valueId: "EV001856",
    valueDesc: "Glass jug",
    label: "EV001856: Glass jug"
  },
  {
    valueId: "EV001859",
    valueDesc: "Water filter",
    label: "EV001859: Water filter"
  },
  {
    valueId: "EV001861",
    valueDesc: "Floor standing rotary iron",
    label: "EV001861: Floor standing rotary iron"
  },
  {
    valueId: "EV001864",
    valueDesc: "By movement",
    label: "EV001864: By movement"
  },
  {
    valueId: "EV001867",
    valueDesc: "Coffee maker",
    label: "EV001867: Coffee maker"
  },
  {
    valueId: "EV001868",
    valueDesc: "Double coffee maker",
    label: "EV001868: Double coffee maker"
  },
  {
    valueId: "EV001869",
    valueDesc: "Coffee-/tea maker",
    label: "EV001869: Coffee-/tea maker"
  },
  {
    valueId: "EV001871",
    valueDesc: "Coffee maker/water cooker",
    label: "EV001871: Coffee maker/water cooker"
  },
  {
    valueId: "EV001878",
    valueDesc: "Zinc/air",
    label: "EV001878: Zinc/air"
  },
  {
    valueId: "EV001881",
    valueDesc: "Satellite signal meter",
    label: "EV001881: Satellite signal meter"
  },
  {
    valueId: "EV001891",
    valueDesc: "Espresso/coffee",
    label: "EV001891: Espresso/coffee"
  },
  {
    valueId: "EV001893",
    valueDesc: "With bus bar 4-pole",
    label: "EV001893: With bus bar 4-pole"
  },
  {
    valueId: "EV001901",
    valueDesc: "Chrome sole",
    label: "EV001901: Chrome sole"
  },
  {
    valueId: "EV001910",
    valueDesc: "Foldable rotary iron",
    label: "EV001910: Foldable rotary iron"
  },
  {
    valueId: "EV001911",
    valueDesc: "Steam rotary iron",
    label: "EV001911: Steam rotary iron"
  },
  {
    valueId: "EV001915",
    valueDesc: "G6.35",
    label: "EV001915: G6.35"
  },
  {
    valueId: "EV001917",
    valueDesc: "Various symbols",
    label: "EV001917: Various symbols"
  },
  {
    valueId: "EV001919",
    valueDesc: "Heating cartridge system",
    label: "EV001919: Heating cartridge system"
  },
  {
    valueId: "EV001922",
    valueDesc: "Rail support",
    label: "EV001922: Rail support"
  },
  {
    valueId: "EV001923",
    valueDesc: "Coffee-/espresso grinder",
    label: "EV001923: Coffee-/espresso grinder"
  },
  {
    valueId: "EV001925",
    valueDesc: "Application framework",
    label: "EV001925: Application framework"
  },
  {
    valueId: "EV001926",
    valueDesc: "Sandwich toaster",
    label: "EV001926: Sandwich toaster"
  },
  {
    valueId: "EV001927",
    valueDesc: "Air humidifier",
    label: "EV001927: Air humidifier"
  },
  {
    valueId: "EV001929",
    valueDesc: "Air cleaner",
    label: "EV001929: Air cleaner"
  },
  {
    valueId: "EV001930",
    valueDesc: "Air cleaner-/humidifier",
    label: "EV001930: Air cleaner-/humidifier"
  },
  {
    valueId: "EV001933",
    valueDesc: "ISDN installation testing device",
    label: "EV001933: ISDN installation testing device"
  },
  {
    valueId: "EV001949",
    valueDesc: "Safety lock opener",
    label: "EV001949: Safety lock opener"
  },
  {
    valueId: "EV001951",
    valueDesc: "Battery/mains",
    label: "EV001951: Battery/mains"
  },
  {
    valueId: "EV001956",
    valueDesc: "Face massager",
    label: "EV001956: Face massager"
  },
  {
    valueId: "EV001958",
    valueDesc: "Turning wheel",
    label: "EV001958: Turning wheel"
  },
  {
    valueId: "EV001959",
    valueDesc: "Embossing",
    label: "EV001959: Embossing"
  },
  {
    valueId: "EV001960",
    valueDesc: "Thermal printer",
    label: "EV001960: Thermal printer"
  },
  {
    valueId: "EV001961",
    valueDesc: "1-2",
    label: "EV001961: 1-2"
  },
  {
    valueId: "EV001971",
    valueDesc: "Inside/outside",
    label: "EV001971: Inside/outside"
  },
  {
    valueId: "EV001983",
    valueDesc: "TNC",
    label: "EV001983: TNC"
  },
  {
    valueId: "EV001986",
    valueDesc: "Food mill",
    label: "EV001986: Food mill"
  },
  {
    valueId: "EV001987",
    valueDesc: "Hair straightener",
    label: "EV001987: Hair straightener"
  },
  {
    valueId: "EV001989",
    valueDesc: "Micro filter",
    label: "EV001989: Micro filter"
  },
  {
    valueId: "EV002003",
    valueDesc: "Motor safety filter",
    label: "EV002003: Motor safety filter"
  },
  {
    valueId: "EV002010",
    valueDesc: "Mobile end-distributor",
    label: "EV002010: Mobile end-distributor"
  },
  {
    valueId: "EV002024",
    valueDesc: "Crepes maker",
    label: "EV002024: Crepes maker"
  },
  {
    valueId: "EV002025",
    valueDesc: "Donut maker",
    label: "EV002025: Donut maker"
  },
  {
    valueId: "EV002029",
    valueDesc: "Enamelled",
    label: "EV002029: Enamelled"
  },
  {
    valueId: "EV002030",
    valueDesc: "Toaster/mini oven",
    label: "EV002030: Toaster/mini oven"
  },
  {
    valueId: "EV002032",
    valueDesc: "Discharge lamp",
    label: "EV002032: Discharge lamp"
  },
  {
    valueId: "EV002035",
    valueDesc: "Connection lug",
    label: "EV002035: Connection lug"
  },
  {
    valueId: "EV002037",
    valueDesc: "Module",
    label: "EV002037: Module"
  },
  {
    valueId: "EV002038",
    valueDesc: "Automatic grill",
    label: "EV002038: Automatic grill"
  },
  {
    valueId: "EV002039",
    valueDesc: "Grill",
    label: "EV002039: Grill"
  },
  {
    valueId: "EV002047",
    valueDesc: "Grease/odour filter",
    label: "EV002047: Grease/odour filter"
  },
  {
    valueId: "EV002049",
    valueDesc: "Portable black and white TV",
    label: "EV002049: Portable black and white TV"
  },
  {
    valueId: "EV002051",
    valueDesc: "4:3",
    label: "EV002051: 4:3"
  },
  {
    valueId: "EV002052",
    valueDesc: "16:9",
    label: "EV002052: 16:9"
  },
  {
    valueId: "EV002058",
    valueDesc: "KS screw",
    label: "EV002058: KS screw"
  },
  {
    valueId: "EV002063",
    valueDesc: "Viewing window",
    label: "EV002063: Viewing window"
  },
  {
    valueId: "EV002064",
    valueDesc: "Desk console",
    label: "EV002064: Desk console"
  },
  {
    valueId: "EV002065",
    valueDesc: "S",
    label: "EV002065: S"
  },
  {
    valueId: "EV002068",
    valueDesc: "Snap mounting",
    label: "EV002068: Snap mounting"
  },
  {
    valueId: "EV002069",
    valueDesc: "Z-profile",
    label: "EV002069: Z-profile"
  },
  {
    valueId: "EV002071",
    valueDesc: "Symbol 'Unlock'",
    label: "EV002071: Symbol 'Unlock'"
  },
  {
    valueId: "EV002072",
    valueDesc: "Symbol '+'",
    label: "EV002072: Symbol '+'"
  },
  {
    valueId: "EV002073",
    valueDesc: "Symbol '-'",
    label: "EV002073: Symbol '-'"
  },
  {
    valueId: "EV002074",
    valueDesc: "Symbol 'Arrow'",
    label: "EV002074: Symbol 'Arrow'"
  },
  {
    valueId: "EV002079",
    valueDesc: "A3 (ISO 216)",
    label: "EV002079: A3 (ISO 216)"
  },
  {
    valueId: "EV002080",
    valueDesc: "Start",
    label: "EV002080: Start"
  },
  {
    valueId: "EV002082",
    valueDesc: "Lift",
    label: "EV002082: Lift"
  },
  {
    valueId: "EV002086",
    valueDesc: "Charge-/switch module",
    label: "EV002086: Charge-/switch module"
  },
  {
    valueId: "EV002092",
    valueDesc: "Code",
    label: "EV002092: Code"
  },
  {
    valueId: "EV002095",
    valueDesc: "VHS",
    label: "EV002095: VHS"
  },
  {
    valueId: "EV002096",
    valueDesc: "S-VHS",
    label: "EV002096: S-VHS"
  },
  {
    valueId: "EV002101",
    valueDesc: "Available",
    label: "EV002101: Available"
  },
  {
    valueId: "EV002104",
    valueDesc: "Built-in device",
    label: "EV002104: Built-in device"
  },
  {
    valueId: "EV002108",
    valueDesc: "Instantaneous water heater",
    label: "EV002108: Instantaneous water heater"
  },
  {
    valueId: "EV002110",
    valueDesc: "Protective hose",
    label: "EV002110: Protective hose"
  },
  {
    valueId: "EV002111",
    valueDesc: "Holder for protective hose",
    label: "EV002111: Holder for protective hose"
  },
  {
    valueId: "EV002112",
    valueDesc: "S-VHS/VHS",
    label: "EV002112: S-VHS/VHS"
  },
  {
    valueId: "EV002113",
    valueDesc: "Built-in accessories",
    label: "EV002113: Built-in accessories"
  },
  {
    valueId: "EV002114",
    valueDesc: "Support bracket",
    label: "EV002114: Support bracket"
  },
  {
    valueId: "EV002121",
    valueDesc: "TFE module",
    label: "EV002121: TFE module"
  },
  {
    valueId: "EV002124",
    valueDesc: "Standing blender with kitchen scales",
    label: "EV002124: Standing blender with kitchen scales"
  },
  {
    valueId: "EV002125",
    valueDesc: "Glow lamp",
    label: "EV002125: Glow lamp"
  },
  {
    valueId: "EV002126",
    valueDesc: "Triac",
    label: "EV002126: Triac"
  },
  {
    valueId: "EV002127",
    valueDesc: "Front load",
    label: "EV002127: Front load"
  },
  {
    valueId: "EV002128",
    valueDesc: "Top load",
    label: "EV002128: Top load"
  },
  {
    valueId: "EV002133",
    valueDesc: "Cable guard rail",
    label: "EV002133: Cable guard rail"
  },
  {
    valueId: "EV002134",
    valueDesc: "Ceiling radiator",
    label: "EV002134: Ceiling radiator"
  },
  {
    valueId: "EV002138",
    valueDesc: "Control tableau",
    label: "EV002138: Control tableau"
  },
  {
    valueId: "EV002144",
    valueDesc: "Corner piece",
    label: "EV002144: Corner piece"
  },
  {
    valueId: "EV002148",
    valueDesc: "Handgrip",
    label: "EV002148: Handgrip"
  },
  {
    valueId: "EV002153",
    valueDesc: "Compact",
    label: "EV002153: Compact"
  },
  {
    valueId: "EV002154",
    valueDesc: "Flame",
    label: "EV002154: Flame"
  },
  {
    valueId: "EV002156",
    valueDesc: "Wall/floor",
    label: "EV002156: Wall/floor"
  },
  {
    valueId: "EV002157",
    valueDesc: "Wall/ceiling",
    label: "EV002157: Wall/ceiling"
  },
  {
    valueId: "EV002158",
    valueDesc: "Wall/ceiling/suspension",
    label: "EV002158: Wall/ceiling/suspension"
  },
  {
    valueId: "EV002160",
    valueDesc: "Busbar system",
    label: "EV002160: Busbar system"
  },
  {
    valueId: "EV002162",
    valueDesc: "Micro cassette",
    label: "EV002162: Micro cassette"
  },
  {
    valueId: "EV002166",
    valueDesc: "Standard paper",
    label: "EV002166: Standard paper"
  },
  {
    valueId: "EV002168",
    valueDesc: "Compact cassette (analogue)",
    label: "EV002168: Compact cassette (analogue)"
  },
  {
    valueId: "EV002171",
    valueDesc: "VHS-C",
    label: "EV002171: VHS-C"
  },
  {
    valueId: "EV002172",
    valueDesc: "S-VHS-C",
    label: "EV002172: S-VHS-C"
  },
  {
    valueId: "EV002177",
    valueDesc: "Coaxial",
    label: "EV002177: Coaxial"
  },
  {
    valueId: "EV002178",
    valueDesc: "Optical and coaxial",
    label: "EV002178: Optical and coaxial"
  },
  {
    valueId: "EV002180",
    valueDesc: "Radio",
    label: "EV002180: Radio"
  },
  {
    valueId: "EV002184",
    valueDesc: "Radio/cassette",
    label: "EV002184: Radio/cassette"
  },
  {
    valueId: "EV002185",
    valueDesc: "Radio/CD/cassette",
    label: "EV002185: Radio/CD/cassette"
  },
  {
    valueId: "EV002192",
    valueDesc: "2-way system",
    label: "EV002192: 2-way system"
  },
  {
    valueId: "EV002193",
    valueDesc: "3-way system",
    label: "EV002193: 3-way system"
  },
  {
    valueId: "EV002198",
    valueDesc: "Radio/CD",
    label: "EV002198: Radio/CD"
  },
  {
    valueId: "EV002201",
    valueDesc: "Perforated plate",
    label: "EV002201: Perforated plate"
  },
  {
    valueId: "EV002202",
    valueDesc: "Capacitor microphone",
    label: "EV002202: Capacitor microphone"
  },
  {
    valueId: "EV002203",
    valueDesc: "Dynamic microphone",
    label: "EV002203: Dynamic microphone"
  },
  {
    valueId: "EV002204",
    valueDesc: "Cardioid",
    label: "EV002204: Cardioid"
  },
  {
    valueId: "EV002205",
    valueDesc: "Round/globe",
    label: "EV002205: Round/globe"
  },
  {
    valueId: "EV002208",
    valueDesc: "Air extraction",
    label: "EV002208: Air extraction"
  },
  {
    valueId: "EV002217",
    valueDesc: "Inversion fitting",
    label: "EV002217: Inversion fitting"
  },
  {
    valueId: "EV002218",
    valueDesc: "Inversion fitting with flex pipe",
    label: "EV002218: Inversion fitting with flex pipe"
  },
  {
    valueId: "EV002219",
    valueDesc: "Inversion fitting with pipe support",
    label: "EV002219: Inversion fitting with pipe support"
  },
  {
    valueId: "EV002226",
    valueDesc: "Nonwoven fabric",
    label: "EV002226: Nonwoven fabric"
  },
  {
    valueId: "EV002229",
    valueDesc: "Acrylic",
    label: "EV002229: Acrylic"
  },
  {
    valueId: "EV002231",
    valueDesc: "Carbon filter",
    label: "EV002231: Carbon filter"
  },
  {
    valueId: "EV002232",
    valueDesc: "Vapour filter",
    label: "EV002232: Vapour filter"
  },
  {
    valueId: "EV002233",
    valueDesc: "3 lines",
    label: "EV002233: 3 lines"
  },
  {
    valueId: "EV002234",
    valueDesc: "12 digits",
    label: "EV002234: 12 digits"
  },
  {
    valueId: "EV002235",
    valueDesc: "Mains power supply",
    label: "EV002235: Mains power supply"
  },
  {
    valueId: "EV002238",
    valueDesc: "Equipment panel",
    label: "EV002238: Equipment panel"
  },
  {
    valueId: "EV002239",
    valueDesc: "Loudspeaker mixing panel",
    label: "EV002239: Loudspeaker mixing panel"
  },
  {
    valueId: "EV002242",
    valueDesc: "Glass ceramic",
    label: "EV002242: Glass ceramic"
  },
  {
    valueId: "EV002243",
    valueDesc: "Steel enamelled",
    label: "EV002243: Steel enamelled"
  },
  {
    valueId: "EV002253",
    valueDesc: "Catalytic",
    label: "EV002253: Catalytic"
  },
  {
    valueId: "EV002254",
    valueDesc: "Pyrolytic",
    label: "EV002254: Pyrolytic"
  },
  {
    valueId: "EV002257",
    valueDesc: "Standard hotplate",
    label: "EV002257: Standard hotplate"
  },
  {
    valueId: "EV002258",
    valueDesc: "High-speed hotplate",
    label: "EV002258: High-speed hotplate"
  },
  {
    valueId: "EV002259",
    valueDesc: "Automatic hot plate",
    label: "EV002259: Automatic hot plate"
  },
  {
    valueId: "EV002260",
    valueDesc: "Capillary tube controller",
    label: "EV002260: Capillary tube controller"
  },
  {
    valueId: "EV002269",
    valueDesc: "Television/video table",
    label: "EV002269: Television/video table"
  },
  {
    valueId: "EV002270",
    valueDesc: "HiFi rack",
    label: "EV002270: HiFi rack"
  },
  {
    valueId: "EV002277",
    valueDesc: "Built-in lamp holder",
    label: "EV002277: Built-in lamp holder"
  },
  {
    valueId: "EV002279",
    valueDesc: "Line amplifier",
    label: "EV002279: Line amplifier"
  },
  {
    valueId: "EV002280",
    valueDesc: "Pre-amplifier",
    label: "EV002280: Pre-amplifier"
  },
  {
    valueId: "EV002282",
    valueDesc: "Combination amplifier",
    label: "EV002282: Combination amplifier"
  },
  {
    valueId: "EV002290",
    valueDesc: "Adapter kit",
    label: "EV002290: Adapter kit"
  },
  {
    valueId: "EV002294",
    valueDesc: "HiFi system",
    label: "EV002294: HiFi system"
  },
  {
    valueId: "EV002295",
    valueDesc: "HiFi Midi-system",
    label: "EV002295: HiFi Midi-system"
  },
  {
    valueId: "EV002296",
    valueDesc: "HiFi Mini-system",
    label: "EV002296: HiFi Mini-system"
  },
  {
    valueId: "EV002297",
    valueDesc: "HiFi Microsystem",
    label: "EV002297: HiFi Microsystem"
  },
  {
    valueId: "EV002302",
    valueDesc: "Surround system",
    label: "EV002302: Surround system"
  },
  {
    valueId: "EV002303",
    valueDesc: "HiFi loudspeaker system",
    label: "EV002303: HiFi loudspeaker system"
  },
  {
    valueId: "EV002305",
    valueDesc: "Fax selector",
    label: "EV002305: Fax selector"
  },
  {
    valueId: "EV002307",
    valueDesc: "S0-Modul",
    label: "EV002307: S0-Modul"
  },
  {
    valueId: "EV002308",
    valueDesc: "ISDN phases testing device",
    label: "EV002308: ISDN phases testing device"
  },
  {
    valueId: "EV002309",
    valueDesc: "S-Bus tester",
    label: "EV002309: S-Bus tester"
  },
  {
    valueId: "EV002310",
    valueDesc: "LED/LCD/acoustic",
    label: "EV002310: LED/LCD/acoustic"
  },
  {
    valueId: "EV002311",
    valueDesc: "24 digits",
    label: "EV002311: 24 digits"
  },
  {
    valueId: "EV002313",
    valueDesc: "LSA Profile",
    label: "EV002313: LSA Profile"
  },
  {
    valueId: "EV002314",
    valueDesc: "Horizontal",
    label: "EV002314: Horizontal"
  },
  {
    valueId: "EV002315",
    valueDesc: "Under frame",
    label: "EV002315: Under frame"
  },
  {
    valueId: "EV002320",
    valueDesc: "Fully-automatic",
    label: "EV002320: Fully-automatic"
  },
  {
    valueId: "EV002321",
    valueDesc: "Programmable pocket calculator",
    label: "EV002321: Programmable pocket calculator"
  },
  {
    valueId: "EV002325",
    valueDesc: "Desk calculator",
    label: "EV002325: Desk calculator"
  },
  {
    valueId: "EV002326",
    valueDesc: "Energized electrode",
    label: "EV002326: Energized electrode"
  },
  {
    valueId: "EV002327",
    valueDesc: "Active substance tips",
    label: "EV002327: Active substance tips"
  },
  {
    valueId: "EV002328",
    valueDesc: "Sound pressure",
    label: "EV002328: Sound pressure"
  },
  {
    valueId: "EV002333",
    valueDesc: "Adhesive foil",
    label: "EV002333: Adhesive foil"
  },
  {
    valueId: "EV002335",
    valueDesc: "Semi automatic",
    label: "EV002335: Semi automatic"
  },
  {
    valueId: "EV002338",
    valueDesc: "Evaporation",
    label: "EV002338: Evaporation"
  },
  {
    valueId: "EV002341",
    valueDesc: "CEE built-in installation box",
    label: "EV002341: CEE built-in installation box"
  },
  {
    valueId: "EV002360",
    valueDesc: "Clamping/screw mounting",
    label: "EV002360: Clamping/screw mounting"
  },
  {
    valueId: "EV002392",
    valueDesc: "5",
    label: "EV002392: 5"
  },
  {
    valueId: "EV002393",
    valueDesc: "6",
    label: "EV002393: 6"
  },
  {
    valueId: "EV002394",
    valueDesc: "9",
    label: "EV002394: 9"
  },
  {
    valueId: "EV002400",
    valueDesc: "Plug-in mounting",
    label: "EV002400: Plug-in mounting"
  },
  {
    valueId: "EV002401",
    valueDesc: "Ceramics/metal",
    label: "EV002401: Ceramics/metal"
  },
  {
    valueId: "EV002406",
    valueDesc: "Bus connection terminal",
    label: "EV002406: Bus connection terminal"
  },
  {
    valueId: "EV002407",
    valueDesc: "Group push button",
    label: "EV002407: Group push button"
  },
  {
    valueId: "EV002409",
    valueDesc: "Single/double-sided",
    label: "EV002409: Single/double-sided"
  },
  {
    valueId: "EV002411",
    valueDesc: "H1",
    label: "EV002411: H1"
  },
  {
    valueId: "EV002412",
    valueDesc: "H3",
    label: "EV002412: H3"
  },
  {
    valueId: "EV002413",
    valueDesc: "P14.5s",
    label: "EV002413: P14.5s"
  },
  {
    valueId: "EV002414",
    valueDesc: "PK22s",
    label: "EV002414: PK22s"
  },
  {
    valueId: "EV002415",
    valueDesc: "GZ10",
    label: "EV002415: GZ10"
  },
  {
    valueId: "EV002416",
    valueDesc: "Direct/indirect",
    label: "EV002416: Direct/indirect"
  },
  {
    valueId: "EV002421",
    valueDesc: "Application segment",
    label: "EV002421: Application segment"
  },
  {
    valueId: "EV002422",
    valueDesc: "Reversing pushbutton",
    label: "EV002422: Reversing pushbutton"
  },
  {
    valueId: "EV002437",
    valueDesc: "Conical",
    label: "EV002437: Conical"
  },
  {
    valueId: "EV002439",
    valueDesc: "Cosmetic mirror",
    label: "EV002439: Cosmetic mirror"
  },
  {
    valueId: "EV002440",
    valueDesc: "Clothes shaver",
    label: "EV002440: Clothes shaver"
  },
  {
    valueId: "EV002445",
    valueDesc: "Grease deflector",
    label: "EV002445: Grease deflector"
  },
  {
    valueId: "EV002446",
    valueDesc: "1 razor head",
    label: "EV002446: 1 razor head"
  },
  {
    valueId: "EV002451",
    valueDesc: "Mains/Battery/Accumulator",
    label: "EV002451: Mains/Battery/Accumulator"
  },
  {
    valueId: "EV002453",
    valueDesc: "Circuit breaker installation unit, 4-fold",
    label: "EV002453: Circuit breaker installation unit, 4-fold"
  },
  {
    valueId: "EV002456",
    valueDesc: "Haircutting kit",
    label: "EV002456: Haircutting kit"
  },
  {
    valueId: "EV002457",
    valueDesc: "Foot massager",
    label: "EV002457: Foot massager"
  },
  {
    valueId: "EV002458",
    valueDesc: "Chrome-nickel steel",
    label: "EV002458: Chrome-nickel steel"
  },
  {
    valueId: "EV002487",
    valueDesc: "Metallic sole",
    label: "EV002487: Metallic sole"
  },
  {
    valueId: "EV002489",
    valueDesc: "Impulse insert",
    label: "EV002489: Impulse insert"
  },
  {
    valueId: "EV002517",
    valueDesc: "Automatic grill with 2 hotplates",
    label: "EV002517: Automatic grill with 2 hotplates"
  },
  {
    valueId: "EV002518",
    valueDesc: "Extension module",
    label: "EV002518: Extension module"
  },
  {
    valueId: "EV002519",
    valueDesc: "Main module",
    label: "EV002519: Main module"
  },
  {
    valueId: "EV002521",
    valueDesc: "Steel nickel plated grill grid",
    label: "EV002521: Steel nickel plated grill grid"
  },
  {
    valueId: "EV002524",
    valueDesc: "All-purpose countersunk screw",
    label: "EV002524: All-purpose countersunk screw"
  },
  {
    valueId: "EV002525",
    valueDesc: "Semi round head all-purpose screws",
    label: "EV002525: Semi round head all-purpose screws"
  },
  {
    valueId: "EV002526",
    valueDesc: "Semi round head wood screws",
    label: "EV002526: Semi round head wood screws"
  },
  {
    valueId: "EV002528",
    valueDesc: "Distance holder",
    label: "EV002528: Distance holder"
  },
  {
    valueId: "EV002531",
    valueDesc: "Corrosion protection tape 50 mm",
    label: "EV002531: Corrosion protection tape 50 mm"
  },
  {
    valueId: "EV002532",
    valueDesc: "Corrosion protection tape 100 mm",
    label: "EV002532: Corrosion protection tape 100 mm"
  },
  {
    valueId: "EV002535",
    valueDesc: "Construction/installation",
    label: "EV002535: Construction/installation"
  },
  {
    valueId: "EV002536",
    valueDesc: "Cable pulling system",
    label: "EV002536: Cable pulling system"
  },
  {
    valueId: "EV002537",
    valueDesc: "5x6 mm²",
    label: "EV002537: 5x6 mm²"
  },
  {
    valueId: "EV002539",
    valueDesc: "Cleaning/degreasing",
    label: "EV002539: Cleaning/degreasing"
  },
  {
    valueId: "EV002540",
    valueDesc: "Cleaning",
    label: "EV002540: Cleaning"
  },
  {
    valueId: "EV002541",
    valueDesc: "Dissolve glue",
    label: "EV002541: Dissolve glue"
  },
  {
    valueId: "EV002542",
    valueDesc: "Insulation",
    label: "EV002542: Insulation"
  },
  {
    valueId: "EV002543",
    valueDesc: "Greasing",
    label: "EV002543: Greasing"
  },
  {
    valueId: "EV002544",
    valueDesc: "Protection/lubrication",
    label: "EV002544: Protection/lubrication"
  },
  {
    valueId: "EV002546",
    valueDesc: "Refrigeration",
    label: "EV002546: Refrigeration"
  },
  {
    valueId: "EV002547",
    valueDesc: "PC-work stations",
    label: "EV002547: PC-work stations"
  },
  {
    valueId: "EV002548",
    valueDesc: "Galvanized surfaces",
    label: "EV002548: Galvanized surfaces"
  },
  {
    valueId: "EV002549",
    valueDesc: "Electrical connections",
    label: "EV002549: Electrical connections"
  },
  {
    valueId: "EV002550",
    valueDesc: "Contact grease",
    label: "EV002550: Contact grease"
  },
  {
    valueId: "EV002551",
    valueDesc: "Slide contacts",
    label: "EV002551: Slide contacts"
  },
  {
    valueId: "EV002555",
    valueDesc: "Foam pistol",
    label: "EV002555: Foam pistol"
  },
  {
    valueId: "EV002556",
    valueDesc: "Not possible",
    label: "EV002556: Not possible"
  },
  {
    valueId: "EV002557",
    valueDesc: "Base station",
    label: "EV002557: Base station"
  },
  {
    valueId: "EV002561",
    valueDesc: "Light control system set",
    label: "EV002561: Light control system set"
  },
  {
    valueId: "EV002563",
    valueDesc: "TV",
    label: "EV002563: TV"
  },
  {
    valueId: "EV002568",
    valueDesc: "Plastic box",
    label: "EV002568: Plastic box"
  },
  {
    valueId: "EV002569",
    valueDesc: "Metal box",
    label: "EV002569: Metal box"
  },
  {
    valueId: "EV002570",
    valueDesc: "Fluorescent lamp",
    label: "EV002570: Fluorescent lamp"
  },
  {
    valueId: "EV002571",
    valueDesc: "RCA",
    label: "EV002571: RCA"
  },
  {
    valueId: "EV002572",
    valueDesc: "Jack plug",
    label: "EV002572: Jack plug"
  },
  {
    valueId: "EV002573",
    valueDesc: "Scart",
    label: "EV002573: Scart"
  },
  {
    valueId: "EV002577",
    valueDesc: "LED/acoustic",
    label: "EV002577: LED/acoustic"
  },
  {
    valueId: "EV002579",
    valueDesc: "Wall-mounted distributor",
    label: "EV002579: Wall-mounted distributor"
  },
  {
    valueId: "EV002585",
    valueDesc: "Light controller/light sensor",
    label: "EV002585: Light controller/light sensor"
  },
  {
    valueId: "EV002591",
    valueDesc: "Interception tip",
    label: "EV002591: Interception tip"
  },
  {
    valueId: "EV002592",
    valueDesc: "Interception rod",
    label: "EV002592: Interception rod"
  },
  {
    valueId: "EV002599",
    valueDesc: "Gutter",
    label: "EV002599: Gutter"
  },
  {
    valueId: "EV002600",
    valueDesc: "Clamp trestle",
    label: "EV002600: Clamp trestle"
  },
  {
    valueId: "EV002601",
    valueDesc: "Clamping shoe",
    label: "EV002601: Clamping shoe"
  },
  {
    valueId: "EV002604",
    valueDesc: "Wall text plate",
    label: "EV002604: Wall text plate"
  },
  {
    valueId: "EV002606",
    valueDesc: "Fire protection plate",
    label: "EV002606: Fire protection plate"
  },
  {
    valueId: "EV002607",
    valueDesc: "Sealing plate",
    label: "EV002607: Sealing plate"
  },
  {
    valueId: "EV002610",
    valueDesc: "Wind sensor",
    label: "EV002610: Wind sensor"
  },
  {
    valueId: "EV002618",
    valueDesc: "12 V DC",
    label: "EV002618: 12 V DC"
  },
  {
    valueId: "EV002619",
    valueDesc: "Plaster spatula",
    label: "EV002619: Plaster spatula"
  },
  {
    valueId: "EV002620",
    valueDesc: "Palette-knife",
    label: "EV002620: Palette-knife"
  },
  {
    valueId: "EV002621",
    valueDesc: "Push fit plug",
    label: "EV002621: Push fit plug"
  },
  {
    valueId: "EV002622",
    valueDesc: "Push button, normally closed",
    label: "EV002622: Push button, normally closed"
  },
  {
    valueId: "EV002623",
    valueDesc: "Roll",
    label: "EV002623: Roll"
  },
  {
    valueId: "EV002626",
    valueDesc: "Free standing distributor",
    label: "EV002626: Free standing distributor"
  },
  {
    valueId: "EV002627",
    valueDesc: "Distributor enclosure",
    label: "EV002627: Distributor enclosure"
  },
  {
    valueId: "EV002633",
    valueDesc: "Permanent filter",
    label: "EV002633: Permanent filter"
  },
  {
    valueId: "EV002636",
    valueDesc: "1 to 0",
    label: "EV002636: 1 to 0"
  },
  {
    valueId: "EV002637",
    valueDesc: "A to L",
    label: "EV002637: A to L"
  },
  {
    valueId: "EV002638",
    valueDesc: "1 to 100",
    label: "EV002638: 1 to 100"
  },
  {
    valueId: "EV002639",
    valueDesc: "2x 1 to 0",
    label: "EV002639: 2x 1 to 0"
  },
  {
    valueId: "EV002642",
    valueDesc: "1 to 10",
    label: "EV002642: 1 to 10"
  },
  {
    valueId: "EV002646",
    valueDesc: "Single",
    label: "EV002646: Single"
  },
  {
    valueId: "EV002649",
    valueDesc: "Distributor box",
    label: "EV002649: Distributor box"
  },
  {
    valueId: "EV002651",
    valueDesc: "ISDN testing device",
    label: "EV002651: ISDN testing device"
  },
  {
    valueId: "EV002656",
    valueDesc: "Electronic light controller",
    label: "EV002656: Electronic light controller"
  },
  {
    valueId: "EV002657",
    valueDesc: "Twisted Pair cable tester",
    label: "EV002657: Twisted Pair cable tester"
  },
  {
    valueId: "EV002658",
    valueDesc: "Cat-6 certification set",
    label: "EV002658: Cat-6 certification set"
  },
  {
    valueId: "EV002659",
    valueDesc: "Fibre optic certification set",
    label: "EV002659: Fibre optic certification set"
  },
  {
    valueId: "EV002660",
    valueDesc: "Imprinting software",
    label: "EV002660: Imprinting software"
  },
  {
    valueId: "EV002670",
    valueDesc: "S-VHS-C/VHS-C",
    label: "EV002670: S-VHS-C/VHS-C"
  },
  {
    valueId: "EV002672",
    valueDesc: "Video recorder",
    label: "EV002672: Video recorder"
  },
  {
    valueId: "EV002679",
    valueDesc: "E2000",
    label: "EV002679: E2000"
  },
  {
    valueId: "EV002683",
    valueDesc: "Connection tester",
    label: "EV002683: Connection tester"
  },
  {
    valueId: "EV002685",
    valueDesc: "8x20, 230 V (F-shape)",
    label: "EV002685: 8x20, 230 V (F-shape)"
  },
  {
    valueId: "EV002686",
    valueDesc: "8x8, 230 V (G-shape)",
    label: "EV002686: 8x8, 230 V (G-shape)"
  },
  {
    valueId: "EV002687",
    valueDesc: "6x8, 230 V (H-shape)",
    label: "EV002687: 6x8, 230 V (H-shape)"
  },
  {
    valueId: "EV002689",
    valueDesc: "Mini Disc",
    label: "EV002689: Mini Disc"
  },
  {
    valueId: "EV002690",
    valueDesc: "6x8, 230 V (H-shape, 3-pole)",
    label: "EV002690: 6x8, 230 V (H-shape, 3-pole)"
  },
  {
    valueId: "EV002692",
    valueDesc: "Nickel metal hydride",
    label: "EV002692: Nickel metal hydride"
  },
  {
    valueId: "EV002694",
    valueDesc: "PC-CD-recordable",
    label: "EV002694: PC-CD-recordable"
  },
  {
    valueId: "EV002695",
    valueDesc: "Audio CD-recordable",
    label: "EV002695: Audio CD-recordable"
  },
  {
    valueId: "EV002696",
    valueDesc: "PC-CD-rewritable",
    label: "EV002696: PC-CD-rewritable"
  },
  {
    valueId: "EV002699",
    valueDesc: "8x13, 230 V, 3-pole",
    label: "EV002699: 8x13, 230 V, 3-pole"
  },
  {
    valueId: "EV002700",
    valueDesc: "8",
    label: "EV002700: 8"
  },
  {
    valueId: "EV002701",
    valueDesc: "Circuit breaker installation unit, 9-fold",
    label: "EV002701: Circuit breaker installation unit, 9-fold"
  },
  {
    valueId: "EV002704",
    valueDesc: "Converter",
    label: "EV002704: Converter"
  },
  {
    valueId: "EV002708",
    valueDesc: "PVC-U",
    label: "EV002708: PVC-U"
  },
  {
    valueId: "EV002719",
    valueDesc: "5x4 mm²",
    label: "EV002719: 5x4 mm²"
  },
  {
    valueId: "EV002720",
    valueDesc: "Double bit",
    label: "EV002720: Double bit"
  },
  {
    valueId: "EV002721",
    valueDesc: "Three-square",
    label: "EV002721: Three-square"
  },
  {
    valueId: "EV002723",
    valueDesc: "Modular Jack",
    label: "EV002723: Modular Jack"
  },
  {
    valueId: "EV002724",
    valueDesc: "Fibre optic assembly",
    label: "EV002724: Fibre optic assembly"
  },
  {
    valueId: "EV002726",
    valueDesc: "BNC plug",
    label: "EV002726: BNC plug"
  },
  {
    valueId: "EV002727",
    valueDesc: "Protective contact CEE 7/3 / CEE 7/4 (type F)",
    label: "EV002727: Protective contact CEE 7/3 / CEE 7/4 (type F)"
  },
  {
    valueId: "EV002729",
    valueDesc: "CEE",
    label: "EV002729: CEE"
  },
  {
    valueId: "EV002731",
    valueDesc: "Fibre optic laser generator",
    label: "EV002731: Fibre optic laser generator"
  },
  {
    valueId: "EV002732",
    valueDesc: "MT-RJ",
    label: "EV002732: MT-RJ"
  },
  {
    valueId: "EV002749",
    valueDesc: "Separate",
    label: "EV002749: Separate"
  },
  {
    valueId: "EV002750",
    valueDesc: "Removable",
    label: "EV002750: Removable"
  },
  {
    valueId: "EV002755",
    valueDesc: "Steel plate/plastic",
    label: "EV002755: Steel plate/plastic"
  },
  {
    valueId: "EV002761",
    valueDesc: "Dimming actuator",
    label: "EV002761: Dimming actuator"
  },
  {
    valueId: "EV002763",
    valueDesc: "Double LC display",
    label: "EV002763: Double LC display"
  },
  {
    valueId: "EV002764",
    valueDesc: "1 digit LED",
    label: "EV002764: 1 digit LED"
  },
  {
    valueId: "EV002768",
    valueDesc: "R20",
    label: "EV002768: R20"
  },
  {
    valueId: "EV002769",
    valueDesc: "R14",
    label: "EV002769: R14"
  },
  {
    valueId: "EV002770",
    valueDesc: "R6",
    label: "EV002770: R6"
  },
  {
    valueId: "EV002771",
    valueDesc: "2R10",
    label: "EV002771: 2R10"
  },
  {
    valueId: "EV002772",
    valueDesc: "3 R 12",
    label: "EV002772: 3 R 12"
  },
  {
    valueId: "EV002773",
    valueDesc: "6 F 22",
    label: "EV002773: 6 F 22"
  },
  {
    valueId: "EV002774",
    valueDesc: "LR 20",
    label: "EV002774: LR 20"
  },
  {
    valueId: "EV002775",
    valueDesc: "LR 14",
    label: "EV002775: LR 14"
  },
  {
    valueId: "EV002776",
    valueDesc: "LR 6",
    label: "EV002776: LR 6"
  },
  {
    valueId: "EV002777",
    valueDesc: "LR 03",
    label: "EV002777: LR 03"
  },
  {
    valueId: "EV002778",
    valueDesc: "6 LR 61",
    label: "EV002778: 6 LR 61"
  },
  {
    valueId: "EV002779",
    valueDesc: "4 R 25",
    label: "EV002779: 4 R 25"
  },
  {
    valueId: "EV002780",
    valueDesc: "6 F 100",
    label: "EV002780: 6 F 100"
  },
  {
    valueId: "EV002781",
    valueDesc: "4 LR 61",
    label: "EV002781: 4 LR 61"
  },
  {
    valueId: "EV002782",
    valueDesc: "4 R 25/2",
    label: "EV002782: 4 R 25/2"
  },
  {
    valueId: "EV002783",
    valueDesc: "LR 1",
    label: "EV002783: LR 1"
  },
  {
    valueId: "EV002784",
    valueDesc: "R20 P",
    label: "EV002784: R20 P"
  },
  {
    valueId: "EV002785",
    valueDesc: "R14 P",
    label: "EV002785: R14 P"
  },
  {
    valueId: "EV002786",
    valueDesc: "R6P",
    label: "EV002786: R6P"
  },
  {
    valueId: "EV002787",
    valueDesc: "6 F 22 P",
    label: "EV002787: 6 F 22 P"
  },
  {
    valueId: "EV002788",
    valueDesc: "3 R 12 P",
    label: "EV002788: 3 R 12 P"
  },
  {
    valueId: "EV002789",
    valueDesc: "2R10 P",
    label: "EV002789: 2R10 P"
  },
  {
    valueId: "EV002790",
    valueDesc: "R20 S",
    label: "EV002790: R20 S"
  },
  {
    valueId: "EV002791",
    valueDesc: "R14 S",
    label: "EV002791: R14 S"
  },
  {
    valueId: "EV002792",
    valueDesc: "R6S",
    label: "EV002792: R6S"
  },
  {
    valueId: "EV002793",
    valueDesc: "6 F 22 S",
    label: "EV002793: 6 F 22 S"
  },
  {
    valueId: "EV002794",
    valueDesc: "3 R 12 S",
    label: "EV002794: 3 R 12 S"
  },
  {
    valueId: "EV002795",
    valueDesc: "R6NM",
    label: "EV002795: R6NM"
  },
  {
    valueId: "EV002796",
    valueDesc: "R03NM",
    label: "EV002796: R03NM"
  },
  {
    valueId: "EV002797",
    valueDesc: "7 R 22 NM",
    label: "EV002797: 7 R 22 NM"
  },
  {
    valueId: "EV002798",
    valueDesc: "CR 2430",
    label: "EV002798: CR 2430"
  },
  {
    valueId: "EV002799",
    valueDesc: "LR 54",
    label: "EV002799: LR 54"
  },
  {
    valueId: "EV002800",
    valueDesc: "SR 41",
    label: "EV002800: SR 41"
  },
  {
    valueId: "EV002801",
    valueDesc: "SR 59",
    label: "EV002801: SR 59"
  },
  {
    valueId: "EV002802",
    valueDesc: "SR 43",
    label: "EV002802: SR 43"
  },
  {
    valueId: "EV002803",
    valueDesc: "SR 58",
    label: "EV002803: SR 58"
  },
  {
    valueId: "EV002804",
    valueDesc: "SR 55",
    label: "EV002804: SR 55"
  },
  {
    valueId: "EV002805",
    valueDesc: "SR 44",
    label: "EV002805: SR 44"
  },
  {
    valueId: "EV002806",
    valueDesc: "SR 54",
    label: "EV002806: SR 54"
  },
  {
    valueId: "EV002807",
    valueDesc: "SR 48",
    label: "EV002807: SR 48"
  },
  {
    valueId: "EV002808",
    valueDesc: "SR 42",
    label: "EV002808: SR 42"
  },
  {
    valueId: "EV002809",
    valueDesc: "SR 57",
    label: "EV002809: SR 57"
  },
  {
    valueId: "EV002810",
    valueDesc: "SR 60",
    label: "EV002810: SR 60"
  },
  {
    valueId: "EV002811",
    valueDesc: "4 SR 44",
    label: "EV002811: 4 SR 44"
  },
  {
    valueId: "EV002812",
    valueDesc: "LR 55",
    label: "EV002812: LR 55"
  },
  {
    valueId: "EV002813",
    valueDesc: "LR 43",
    label: "EV002813: LR 43"
  },
  {
    valueId: "EV002814",
    valueDesc: "LR 44",
    label: "EV002814: LR 44"
  },
  {
    valueId: "EV002815",
    valueDesc: "CR 1620",
    label: "EV002815: CR 1620"
  },
  {
    valueId: "EV002816",
    valueDesc: "CR 2025",
    label: "EV002816: CR 2025"
  },
  {
    valueId: "EV002817",
    valueDesc: "CR 2032",
    label: "EV002817: CR 2032"
  },
  {
    valueId: "EV002818",
    valueDesc: "4 LR 44",
    label: "EV002818: 4 LR 44"
  },
  {
    valueId: "EV002819",
    valueDesc: "SR 45",
    label: "EV002819: SR 45"
  },
  {
    valueId: "EV002820",
    valueDesc: "SR 66",
    label: "EV002820: SR 66"
  },
  {
    valueId: "EV002821",
    valueDesc: "CR 2016",
    label: "EV002821: CR 2016"
  },
  {
    valueId: "EV002822",
    valueDesc: "CR 12600",
    label: "EV002822: CR 12600"
  },
  {
    valueId: "EV002823",
    valueDesc: "2 CR 5",
    label: "EV002823: 2 CR 5"
  },
  {
    valueId: "EV002824",
    valueDesc: "CR-P 2",
    label: "EV002824: CR-P 2"
  },
  {
    valueId: "EV002825",
    valueDesc: "CR 17345",
    label: "EV002825: CR 17345"
  },
  {
    valueId: "EV002826",
    valueDesc: "SR 67",
    label: "EV002826: SR 67"
  },
  {
    valueId: "EV002827",
    valueDesc: "SR 62",
    label: "EV002827: SR 62"
  },
  {
    valueId: "EV002828",
    valueDesc: "SR 65",
    label: "EV002828: SR 65"
  },
  {
    valueId: "EV002829",
    valueDesc: "SR 64",
    label: "EV002829: SR 64"
  },
  {
    valueId: "EV002830",
    valueDesc: "SR 69",
    label: "EV002830: SR 69"
  },
  {
    valueId: "EV002831",
    valueDesc: "D/UM-1",
    label: "EV002831: D/UM-1"
  },
  {
    valueId: "EV002832",
    valueDesc: "C/UM-2",
    label: "EV002832: C/UM-2"
  },
  {
    valueId: "EV002833",
    valueDesc: "AA/UM-3",
    label: "EV002833: AA/UM-3"
  },
  {
    valueId: "EV002834",
    valueDesc: "D/AM 1",
    label: "EV002834: D/AM 1"
  },
  {
    valueId: "EV002835",
    valueDesc: "C/AM 2",
    label: "EV002835: C/AM 2"
  },
  {
    valueId: "EV002836",
    valueDesc: "AA/AM 3",
    label: "EV002836: AA/AM 3"
  },
  {
    valueId: "EV002837",
    valueDesc: "AAA/AM 4",
    label: "EV002837: AAA/AM 4"
  },
  {
    valueId: "EV002838",
    valueDesc: "6 AM 6",
    label: "EV002838: 6 AM 6"
  },
  {
    valueId: "EV002839",
    valueDesc: "J/7 K 67",
    label: "EV002839: J/7 K 67"
  },
  {
    valueId: "EV002840",
    valueDesc: "G 10",
    label: "EV002840: G 10"
  },
  {
    valueId: "EV002841",
    valueDesc: "LR 9",
    label: "EV002841: LR 9"
  },
  {
    valueId: "EV002842",
    valueDesc: "RW 37/SR 41 SW",
    label: "EV002842: RW 37/SR 41 SW"
  },
  {
    valueId: "EV002843",
    valueDesc: "RW 411/SR 726 W",
    label: "EV002843: RW 411/SR 726 W"
  },
  {
    valueId: "EV002844",
    valueDesc: "RW 311/SR 726 SW",
    label: "EV002844: RW 311/SR 726 SW"
  },
  {
    valueId: "EV002845",
    valueDesc: "RW 34/SR 43 SW",
    label: "EV002845: RW 34/SR 43 SW"
  },
  {
    valueId: "EV002846",
    valueDesc: "RW 310/SR 721 SW",
    label: "EV002846: RW 310/SR 721 SW"
  },
  {
    valueId: "EV002847",
    valueDesc: "RW 30/SR 1120 SW",
    label: "EV002847: RW 30/SR 1120 SW"
  },
  {
    valueId: "EV002848",
    valueDesc: "RW 42/SR 44 SW",
    label: "EV002848: RW 42/SR 44 SW"
  },
  {
    valueId: "EV002849",
    valueDesc: "RW 39/SR 1130 SW",
    label: "EV002849: RW 39/SR 1130 SW"
  },
  {
    valueId: "EV002850",
    valueDesc: "RW 49/SR 1130 W",
    label: "EV002850: RW 49/SR 1130 W"
  },
  {
    valueId: "EV002851",
    valueDesc: "RW 48/SR 754 W",
    label: "EV002851: RW 48/SR 754 W"
  },
  {
    valueId: "EV002852",
    valueDesc: "RW 47/SR 41 W",
    label: "EV002852: RW 47/SR 41 W"
  },
  {
    valueId: "EV002853",
    valueDesc: "RW 44/SR 43 W",
    label: "EV002853: RW 44/SR 43 W"
  },
  {
    valueId: "EV002854",
    valueDesc: "RW 40/SR 1120 W",
    label: "EV002854: RW 40/SR 1120 W"
  },
  {
    valueId: "EV002855",
    valueDesc: "RW 32/SR 44 W",
    label: "EV002855: RW 32/SR 44 W"
  },
  {
    valueId: "EV002856",
    valueDesc: "RW 36",
    label: "EV002856: RW 36"
  },
  {
    valueId: "EV002857",
    valueDesc: "RW 313/SR 927 SW",
    label: "EV002857: RW 313/SR 927 SW"
  },
  {
    valueId: "EV002858",
    valueDesc: "RW 320/SR 621 SW",
    label: "EV002858: RW 320/SR 621 SW"
  },
  {
    valueId: "EV002859",
    valueDesc: "4 G-13",
    label: "EV002859: 4 G-13"
  },
  {
    valueId: "EV002860",
    valueDesc: "G 8",
    label: "EV002860: G 8"
  },
  {
    valueId: "EV002863",
    valueDesc: "RW 33/SR 936 SW",
    label: "EV002863: RW 33/SR 936 SW"
  },
  {
    valueId: "EV002864",
    valueDesc: "RW 329/SR 626 SW",
    label: "EV002864: RW 329/SR 626 SW"
  },
  {
    valueId: "EV002865",
    valueDesc: "RW 413/SR 927 SW",
    label: "EV002865: RW 413/SR 927 SW"
  },
  {
    valueId: "EV002866",
    valueDesc: "RW 38/SR 754 SW",
    label: "EV002866: RW 38/SR 754 SW"
  },
  {
    valueId: "EV002867",
    valueDesc: "RW 316/SR 716 SW",
    label: "EV002867: RW 316/SR 716 SW"
  },
  {
    valueId: "EV002868",
    valueDesc: "SR 516 SW/RW 326",
    label: "EV002868: SR 516 SW/RW 326"
  },
  {
    valueId: "EV002869",
    valueDesc: "RW 321/SR 616 SW",
    label: "EV002869: RW 321/SR 616 SW"
  },
  {
    valueId: "EV002870",
    valueDesc: "SR 527 SW/RW 335",
    label: "EV002870: SR 527 SW/RW 335"
  },
  {
    valueId: "EV002871",
    valueDesc: "RW 315/SR 920 SW",
    label: "EV002871: RW 315/SR 920 SW"
  },
  {
    valueId: "EV002872",
    valueDesc: "SR 68",
    label: "EV002872: SR 68"
  },
  {
    valueId: "EV002873",
    valueDesc: "RW 317/SR 916 SW",
    label: "EV002873: RW 317/SR 916 SW"
  },
  {
    valueId: "EV002874",
    valueDesc: "RW 327/SR 521 SW",
    label: "EV002874: RW 327/SR 521 SW"
  },
  {
    valueId: "EV002875",
    valueDesc: "SR 63",
    label: "EV002875: SR 63"
  },
  {
    valueId: "EV002876",
    valueDesc: "CR 1220",
    label: "EV002876: CR 1220"
  },
  {
    valueId: "EV002877",
    valueDesc: "CR 1616",
    label: "EV002877: CR 1616"
  },
  {
    valueId: "EV002878",
    valueDesc: "PR 48",
    label: "EV002878: PR 48"
  },
  {
    valueId: "EV002879",
    valueDesc: "CR 2320",
    label: "EV002879: CR 2320"
  },
  {
    valueId: "EV002880",
    valueDesc: "CR 2450",
    label: "EV002880: CR 2450"
  },
  {
    valueId: "EV002881",
    valueDesc: "PR 41",
    label: "EV002881: PR 41"
  },
  {
    valueId: "EV002882",
    valueDesc: "RW 415/SR 920 W",
    label: "EV002882: RW 415/SR 920 W"
  },
  {
    valueId: "EV002883",
    valueDesc: "PR 44",
    label: "EV002883: PR 44"
  },
  {
    valueId: "EV002884",
    valueDesc: "PR 70",
    label: "EV002884: PR 70"
  },
  {
    valueId: "EV002888",
    valueDesc: "Steam curl brush",
    label: "EV002888: Steam curl brush"
  },
  {
    valueId: "EV002889",
    valueDesc: "Steam curling tong",
    label: "EV002889: Steam curling tong"
  },
  {
    valueId: "EV002895",
    valueDesc: "Multi mode/single mode",
    label: "EV002895: Multi mode/single mode"
  },
  {
    valueId: "EV002897",
    valueDesc: "Spiral plug",
    label: "EV002897: Spiral plug"
  },
  {
    valueId: "EV002898",
    valueDesc: "Expanding plug",
    label: "EV002898: Expanding plug"
  },
  {
    valueId: "EV002903",
    valueDesc: "T-profile",
    label: "EV002903: T-profile"
  },
  {
    valueId: "EV002905",
    valueDesc: "Air circulation operation",
    label: "EV002905: Air circulation operation"
  },
  {
    valueId: "EV002917",
    valueDesc: "Lamp holder with NSS bus contact",
    label: "EV002917: Lamp holder with NSS bus contact"
  },
  {
    valueId: "EV002918",
    valueDesc: "Connection element and lamp holder",
    label: "EV002918: Connection element and lamp holder"
  },
  {
    valueId: "EV002929",
    valueDesc: "Token ring",
    label: "EV002929: Token ring"
  },
  {
    valueId: "EV002933",
    valueDesc: "Reflection meter OTDR 850 nm",
    label: "EV002933: Reflection meter OTDR 850 nm"
  },
  {
    valueId: "EV002934",
    valueDesc: "Reflection meter OTDR 1300/850 nm",
    label: "EV002934: Reflection meter OTDR 1300/850 nm"
  },
  {
    valueId: "EV002935",
    valueDesc: "Cat-5 certification set",
    label: "EV002935: Cat-5 certification set"
  },
  {
    valueId: "EV002936",
    valueDesc: "Thermo-splice protection",
    label: "EV002936: Thermo-splice protection"
  },
  {
    valueId: "EV002937",
    valueDesc: "Crimp splice protection",
    label: "EV002937: Crimp splice protection"
  },
  {
    valueId: "EV002939",
    valueDesc: "Unprinted",
    label: "EV002939: Unprinted"
  },
  {
    valueId: "EV002947",
    valueDesc: "6 mm",
    label: "EV002947: 6 mm"
  },
  {
    valueId: "EV002948",
    valueDesc: "AS 400",
    label: "EV002948: AS 400"
  },
  {
    valueId: "EV002960",
    valueDesc: "19 mm",
    label: "EV002960: 19 mm"
  },
  {
    valueId: "EV002962",
    valueDesc: "50 mm",
    label: "EV002962: 50 mm"
  },
  {
    valueId: "EV002964",
    valueDesc: "BNC, 50 Ohm",
    label: "EV002964: BNC, 50 Ohm"
  },
  {
    valueId: "EV002965",
    valueDesc: "BNC, 75 Ohm",
    label: "EV002965: BNC, 75 Ohm"
  },
  {
    valueId: "EV002968",
    valueDesc: "IBM 3270",
    label: "EV002968: IBM 3270"
  },
  {
    valueId: "EV002969",
    valueDesc: "BNC, 93 Ohm",
    label: "EV002969: BNC, 93 Ohm"
  },
  {
    valueId: "EV002971",
    valueDesc: "XLR",
    label: "EV002971: XLR"
  },
  {
    valueId: "EV002973",
    valueDesc: "Duct slider",
    label: "EV002973: Duct slider"
  },
  {
    valueId: "EV002974",
    valueDesc: "Plant light",
    label: "EV002974: Plant light"
  },
  {
    valueId: "EV002979",
    valueDesc: "Steel wire grid",
    label: "EV002979: Steel wire grid"
  },
  {
    valueId: "EV002980",
    valueDesc: "Fibre silicate plate",
    label: "EV002980: Fibre silicate plate"
  },
  {
    valueId: "EV002982",
    valueDesc: "Closed with prestamping",
    label: "EV002982: Closed with prestamping"
  },
  {
    valueId: "EV002993",
    valueDesc: "a/b-module",
    label: "EV002993: a/b-module"
  },
  {
    valueId: "EV002994",
    valueDesc: "Power failure module",
    label: "EV002994: Power failure module"
  },
  {
    valueId: "EV002996",
    valueDesc: "Answering machine module",
    label: "EV002996: Answering machine module"
  },
  {
    valueId: "EV002997",
    valueDesc: "Remote control device",
    label: "EV002997: Remote control device"
  },
  {
    valueId: "EV002998",
    valueDesc: "2 wings",
    label: "EV002998: 2 wings"
  },
  {
    valueId: "EV003005",
    valueDesc: "Empty housing",
    label: "EV003005: Empty housing"
  },
  {
    valueId: "EV003024",
    valueDesc: "Cover disc",
    label: "EV003024: Cover disc"
  },
  {
    valueId: "EV003025",
    valueDesc: "Radiator valve",
    label: "EV003025: Radiator valve"
  },
  {
    valueId: "EV003029",
    valueDesc: "Fondue",
    label: "EV003029: Fondue"
  },
  {
    valueId: "EV003030",
    valueDesc: "Wok",
    label: "EV003030: Wok"
  },
  {
    valueId: "EV003031",
    valueDesc: "Fondue/wok",
    label: "EV003031: Fondue/wok"
  },
  {
    valueId: "EV003032",
    valueDesc: "G8.5",
    label: "EV003032: G8.5"
  },
  {
    valueId: "EV003033",
    valueDesc: "Floor console",
    label: "EV003033: Floor console"
  },
  {
    valueId: "EV003041",
    valueDesc: "Circuit breaker installation unit, 3-fold",
    label: "EV003041: Circuit breaker installation unit, 3-fold"
  },
  {
    valueId: "EV003046",
    valueDesc: "Glue",
    label: "EV003046: Glue"
  },
  {
    valueId: "EV003052",
    valueDesc: "1-way system",
    label: "EV003052: 1-way system"
  },
  {
    valueId: "EV003057",
    valueDesc: "Galvanic copper plated",
    label: "EV003057: Galvanic copper plated"
  },
  {
    valueId: "EV003058",
    valueDesc: "Corrugated sheet roof",
    label: "EV003058: Corrugated sheet roof"
  },
  {
    valueId: "EV003074",
    valueDesc: "Symbol 'Tip'",
    label: "EV003074: Symbol 'Tip'"
  },
  {
    valueId: "EV003079",
    valueDesc: "Power-cut-out switch",
    label: "EV003079: Power-cut-out switch"
  },
  {
    valueId: "EV003086",
    valueDesc: "Exterior coupler",
    label: "EV003086: Exterior coupler"
  },
  {
    valueId: "EV003088",
    valueDesc: "Lithium",
    label: "EV003088: Lithium"
  },
  {
    valueId: "EV003089",
    valueDesc: "CR 123 A",
    label: "EV003089: CR 123 A"
  },
  {
    valueId: "EV003090",
    valueDesc: "CR 1/3 N",
    label: "EV003090: CR 1/3 N"
  },
  {
    valueId: "EV003091",
    valueDesc: "Wide Flood",
    label: "EV003091: Wide Flood"
  },
  {
    valueId: "EV003092",
    valueDesc: "4 mm",
    label: "EV003092: 4 mm"
  },
  {
    valueId: "EV003095",
    valueDesc: "With bus bar 5-pole",
    label: "EV003095: With bus bar 5-pole"
  },
  {
    valueId: "EV003099",
    valueDesc: "Round connector M 12",
    label: "EV003099: Round connector M 12"
  },
  {
    valueId: "EV003102",
    valueDesc: "Rotating toaster",
    label: "EV003102: Rotating toaster"
  },
  {
    valueId: "EV003105",
    valueDesc: "a/b-/System telephone module",
    label: "EV003105: a/b-/System telephone module"
  },
  {
    valueId: "EV003106",
    valueDesc: "Multi device connection",
    label: "EV003106: Multi device connection"
  },
  {
    valueId: "EV003107",
    valueDesc: "Multi device-/system connection",
    label: "EV003107: Multi device-/system connection"
  },
  {
    valueId: "EV003111",
    valueDesc: "System port",
    label: "EV003111: System port"
  },
  {
    valueId: "EV003112",
    valueDesc: "S0-Port",
    label: "EV003112: S0-Port"
  },
  {
    valueId: "EV003114",
    valueDesc: "Natural gas",
    label: "EV003114: Natural gas"
  },
  {
    valueId: "EV003115",
    valueDesc: "Propane gas",
    label: "EV003115: Propane gas"
  },
  {
    valueId: "EV003126",
    valueDesc: "Toaster/sandwich toaster",
    label: "EV003126: Toaster/sandwich toaster"
  },
  {
    valueId: "EV003127",
    valueDesc: "Electronic label maker",
    label: "EV003127: Electronic label maker"
  },
  {
    valueId: "EV003128",
    valueDesc: "Electronic cable labeller",
    label: "EV003128: Electronic cable labeller"
  },
  {
    valueId: "EV003129",
    valueDesc: "CD labelling system",
    label: "EV003129: CD labelling system"
  },
  {
    valueId: "EV003130",
    valueDesc: "Printer dependent",
    label: "EV003130: Printer dependent"
  },
  {
    valueId: "EV003131",
    valueDesc: "10x3 mm",
    label: "EV003131: 10x3 mm"
  },
  {
    valueId: "EV003136",
    valueDesc: "Solder/plug-in",
    label: "EV003136: Solder/plug-in"
  },
  {
    valueId: "EV003137",
    valueDesc: "Adjustable",
    label: "EV003137: Adjustable"
  },
  {
    valueId: "EV003138",
    valueDesc: "Surface mounted/recessed mounted/partially recessed mounted",
    label: "EV003138: Surface mounted/recessed mounted/partially recessed mounted"
  },
  {
    valueId: "EV003152",
    valueDesc: "Signalling panel",
    label: "EV003152: Signalling panel"
  },
  {
    valueId: "EV003155",
    valueDesc: "Series push button",
    label: "EV003155: Series push button"
  },
  {
    valueId: "EV003156",
    valueDesc: "Middle part",
    label: "EV003156: Middle part"
  },
  {
    valueId: "EV003157",
    valueDesc: "Top cover",
    label: "EV003157: Top cover"
  },
  {
    valueId: "EV003158",
    valueDesc: "Base unit",
    label: "EV003158: Base unit"
  },
  {
    valueId: "EV003160",
    valueDesc: "Leakage sensor",
    label: "EV003160: Leakage sensor"
  },
  {
    valueId: "EV003164",
    valueDesc: "Trunk line (local loop) module",
    label: "EV003164: Trunk line (local loop) module"
  },
  {
    valueId: "EV003165",
    valueDesc: "Duct installation unit",
    label: "EV003165: Duct installation unit"
  },
  {
    valueId: "EV003169",
    valueDesc: "50x50 mm",
    label: "EV003169: 50x50 mm"
  },
  {
    valueId: "EV003170",
    valueDesc: "Duct outlet socket/switch",
    label: "EV003170: Duct outlet socket/switch"
  },
  {
    valueId: "EV003171",
    valueDesc: "Data-/telecommunications duct socket outlet",
    label: "EV003171: Data-/telecommunications duct socket outlet"
  },
  {
    valueId: "EV003175",
    valueDesc: "Name-plate with cover plate",
    label: "EV003175: Name-plate with cover plate"
  },
  {
    valueId: "EV003176",
    valueDesc: "Roll baker",
    label: "EV003176: Roll baker"
  },
  {
    valueId: "EV003182",
    valueDesc: "Headset",
    label: "EV003182: Headset"
  },
  {
    valueId: "EV003183",
    valueDesc: "Over-the-ear headset",
    label: "EV003183: Over-the-ear headset"
  },
  {
    valueId: "EV003184",
    valueDesc: "Pancake maker",
    label: "EV003184: Pancake maker"
  },
  {
    valueId: "EV003186",
    valueDesc: "Duct fire partitioning",
    label: "EV003186: Duct fire partitioning"
  },
  {
    valueId: "EV003187",
    valueDesc: "Wall duct",
    label: "EV003187: Wall duct"
  },
  {
    valueId: "EV003188",
    valueDesc: "Hotel card",
    label: "EV003188: Hotel card"
  },
  {
    valueId: "EV003189",
    valueDesc: "2x socket outlet with protective contact CEE 7/3 (type F)",
    label: "EV003189: 2x socket outlet with protective contact CEE 7/3 (type F)"
  },
  {
    valueId: "EV003190",
    valueDesc: "4x socket outlet with protective contact CEE 7/3 (type F)",
    label: "EV003190: 4x socket outlet with protective contact CEE 7/3 (type F)"
  },
  {
    valueId: "EV003191",
    valueDesc: "1x change over switch",
    label: "EV003191: 1x change over switch"
  },
  {
    valueId: "EV003192",
    valueDesc: "1x series switch",
    label: "EV003192: 1x series switch"
  },
  {
    valueId: "EV003196",
    valueDesc: "2x socket outlet w/protective contact CEE 7/3 (type F) + overvoltage protection",
    label: "EV003196: 2x socket outlet w/protective contact CEE 7/3 (type F) + overvoltage protection"
  },
  {
    valueId: "EV003197",
    valueDesc: "2x RJ45 8(4)",
    label: "EV003197: 2x RJ45 8(4)"
  },
  {
    valueId: "EV003198",
    valueDesc: "2x TAE 2x6 NF",
    label: "EV003198: 2x TAE 2x6 NF"
  },
  {
    valueId: "EV003199",
    valueDesc: "2x antenna connection",
    label: "EV003199: 2x antenna connection"
  },
  {
    valueId: "EV003200",
    valueDesc: "Steel sliding-sole",
    label: "EV003200: Steel sliding-sole"
  },
  {
    valueId: "EV003202",
    valueDesc: "Built-in housing",
    label: "EV003202: Built-in housing"
  },
  {
    valueId: "EV003211",
    valueDesc: "Lance",
    label: "EV003211: Lance"
  },
  {
    valueId: "EV003217",
    valueDesc: "4-fold crown",
    label: "EV003217: 4-fold crown"
  },
  {
    valueId: "EV003218",
    valueDesc: "Waffle",
    label: "EV003218: Waffle"
  },
  {
    valueId: "EV003220",
    valueDesc: "30° point",
    label: "EV003220: 30° point"
  },
  {
    valueId: "EV003224",
    valueDesc: "Flat head",
    label: "EV003224: Flat head"
  },
  {
    valueId: "EV003225",
    valueDesc: "Polyoxymethylene (POM)",
    label: "EV003225: Polyoxymethylene (POM)"
  },
  {
    valueId: "EV003230",
    valueDesc: "Single room temperature regulator",
    label: "EV003230: Single room temperature regulator"
  },
  {
    valueId: "EV003231",
    valueDesc: "G22",
    label: "EV003231: G22"
  },
  {
    valueId: "EV003240",
    valueDesc: "Media coupler",
    label: "EV003240: Media coupler"
  },
  {
    valueId: "EV003241",
    valueDesc: "Banana bus",
    label: "EV003241: Banana bus"
  },
  {
    valueId: "EV003243",
    valueDesc: "Signalling group terminal",
    label: "EV003243: Signalling group terminal"
  },
  {
    valueId: "EV003251",
    valueDesc: "Grounding block double",
    label: "EV003251: Grounding block double"
  },
  {
    valueId: "EV003253",
    valueDesc: "Double shielded",
    label: "EV003253: Double shielded"
  },
  {
    valueId: "EV003258",
    valueDesc: "Torx",
    label: "EV003258: Torx"
  },
  {
    valueId: "EV003264",
    valueDesc: "ESCON",
    label: "EV003264: ESCON"
  },
  {
    valueId: "EV003267",
    valueDesc: "Corner",
    label: "EV003267: Corner"
  },
  {
    valueId: "EV003268",
    valueDesc: "Compact fluorescent lamp",
    label: "EV003268: Compact fluorescent lamp"
  },
  {
    valueId: "EV003269",
    valueDesc: "C2.4W",
    label: "EV003269: C2.4W"
  },
  {
    valueId: "EV003270",
    valueDesc: "EP10",
    label: "EV003270: EP10"
  },
  {
    valueId: "EV003271",
    valueDesc: "C0.6W",
    label: "EV003271: C0.6W"
  },
  {
    valueId: "EV003272",
    valueDesc: "Hose",
    label: "EV003272: Hose"
  },
  {
    valueId: "EV003274",
    valueDesc: "Screw lamp holder",
    label: "EV003274: Screw lamp holder"
  },
  {
    valueId: "EV003279",
    valueDesc: "Earthing block",
    label: "EV003279: Earthing block"
  },
  {
    valueId: "EV003281",
    valueDesc: "Wet-/dry shaver/epilator",
    label: "EV003281: Wet-/dry shaver/epilator"
  },
  {
    valueId: "EV003295",
    valueDesc: "M",
    label: "EV003295: M"
  },
  {
    valueId: "EV003296",
    valueDesc: "XL",
    label: "EV003296: XL"
  },
  {
    valueId: "EV003297",
    valueDesc: "XXL",
    label: "EV003297: XXL"
  },
  {
    valueId: "EV003301",
    valueDesc: "50",
    label: "EV003301: 50"
  },
  {
    valueId: "EV003323",
    valueDesc: "Headband",
    label: "EV003323: Headband"
  },
  {
    valueId: "EV003332",
    valueDesc: "4-pole",
    label: "EV003332: 4-pole"
  },
  {
    valueId: "EV003334",
    valueDesc: "Clamp/busbar",
    label: "EV003334: Clamp/busbar"
  },
  {
    valueId: "EV003336",
    valueDesc: "S-profile",
    label: "EV003336: S-profile"
  },
  {
    valueId: "EV003341",
    valueDesc: "GZX4",
    label: "EV003341: GZX4"
  },
  {
    valueId: "EV003346",
    valueDesc: "X511",
    label: "EV003346: X511"
  },
  {
    valueId: "EV003347",
    valueDesc: "H4",
    label: "EV003347: H4"
  },
  {
    valueId: "EV003348",
    valueDesc: "P43t",
    label: "EV003348: P43t"
  },
  {
    valueId: "EV003352",
    valueDesc: "4 R 25 P",
    label: "EV003352: 4 R 25 P"
  },
  {
    valueId: "EV003353",
    valueDesc: "Combi",
    label: "EV003353: Combi"
  },
  {
    valueId: "EV003354",
    valueDesc: "Silver oxide",
    label: "EV003354: Silver oxide"
  },
  {
    valueId: "EV003355",
    valueDesc: "CR 2",
    label: "EV003355: CR 2"
  },
  {
    valueId: "EV003356",
    valueDesc: "SR 731 SW",
    label: "EV003356: SR 731 SW"
  },
  {
    valueId: "EV003357",
    valueDesc: "SR 626",
    label: "EV003357: SR 626"
  },
  {
    valueId: "EV003358",
    valueDesc: "SR 936",
    label: "EV003358: SR 936"
  },
  {
    valueId: "EV003359",
    valueDesc: "8 LR 932",
    label: "EV003359: 8 LR 932"
  },
  {
    valueId: "EV003365",
    valueDesc: "G3",
    label: "EV003365: G3"
  },
  {
    valueId: "EV003369",
    valueDesc: "Emergency lighting lamp holder",
    label: "EV003369: Emergency lighting lamp holder"
  },
  {
    valueId: "EV003371",
    valueDesc: "Smart Media Card",
    label: "EV003371: Smart Media Card"
  },
  {
    valueId: "EV003373",
    valueDesc: "Pull switch lamp holder",
    label: "EV003373: Pull switch lamp holder"
  },
  {
    valueId: "EV003374",
    valueDesc: "Double lamp holder",
    label: "EV003374: Double lamp holder"
  },
  {
    valueId: "EV003383",
    valueDesc: "Closing cap",
    label: "EV003383: Closing cap"
  },
  {
    valueId: "EV003384",
    valueDesc: "Support angle",
    label: "EV003384: Support angle"
  },
  {
    valueId: "EV003385",
    valueDesc: "Signalling and operating panel",
    label: "EV003385: Signalling and operating panel"
  },
  {
    valueId: "EV003387",
    valueDesc: "R 03",
    label: "EV003387: R 03"
  },
  {
    valueId: "EV003388",
    valueDesc: "BA20s",
    label: "EV003388: BA20s"
  },
  {
    valueId: "EV003389",
    valueDesc: "Earthing connection",
    label: "EV003389: Earthing connection"
  },
  {
    valueId: "EV003391",
    valueDesc: "Roof antenna",
    label: "EV003391: Roof antenna"
  },
  {
    valueId: "EV003392",
    valueDesc: "GY9.5",
    label: "EV003392: GY9.5"
  },
  {
    valueId: "EV003393",
    valueDesc: "GX9.5",
    label: "EV003393: GX9.5"
  },
  {
    valueId: "EV003394",
    valueDesc: "GY16",
    label: "EV003394: GY16"
  },
  {
    valueId: "EV003395",
    valueDesc: "G38",
    label: "EV003395: G38"
  },
  {
    valueId: "EV003402",
    valueDesc: "Cold adhesion method",
    label: "EV003402: Cold adhesion method"
  },
  {
    valueId: "EV003404",
    valueDesc: "Chain hoist door opener",
    label: "EV003404: Chain hoist door opener"
  },
  {
    valueId: "EV003405",
    valueDesc: "Tube mounting",
    label: "EV003405: Tube mounting"
  },
  {
    valueId: "EV003407",
    valueDesc: "Symbol 'III'",
    label: "EV003407: Symbol 'III'"
  },
  {
    valueId: "EV003408",
    valueDesc: "T-distributor",
    label: "EV003408: T-distributor"
  },
  {
    valueId: "EV003411",
    valueDesc: "HS3",
    label: "EV003411: HS3"
  },
  {
    valueId: "EV003412",
    valueDesc: "PX13.5s",
    label: "EV003412: PX13.5s"
  },
  {
    valueId: "EV003413",
    valueDesc: "Tubing kit",
    label: "EV003413: Tubing kit"
  },
  {
    valueId: "EV003416",
    valueDesc: "Plug - protective housing",
    label: "EV003416: Plug - protective housing"
  },
  {
    valueId: "EV003420",
    valueDesc: "60",
    label: "EV003420: 60"
  },
  {
    valueId: "EV003422",
    valueDesc: "90",
    label: "EV003422: 90"
  },
  {
    valueId: "EV003427",
    valueDesc: "110",
    label: "EV003427: 110"
  },
  {
    valueId: "EV003428",
    valueDesc: "24",
    label: "EV003428: 24"
  },
  {
    valueId: "EV003429",
    valueDesc: "26",
    label: "EV003429: 26"
  },
  {
    valueId: "EV003430",
    valueDesc: "27",
    label: "EV003430: 27"
  },
  {
    valueId: "EV003432",
    valueDesc: "XXXL",
    label: "EV003432: XXXL"
  },
  {
    valueId: "EV003434",
    valueDesc: "NPN",
    label: "EV003434: NPN"
  },
  {
    valueId: "EV003436",
    valueDesc: "Clamping bracket",
    label: "EV003436: Clamping bracket"
  },
  {
    valueId: "EV003440",
    valueDesc: "BA7s",
    label: "EV003440: BA7s"
  },
  {
    valueId: "EV003446",
    valueDesc: "Soapstone",
    label: "EV003446: Soapstone"
  },
  {
    valueId: "EV003450",
    valueDesc: "Cable tie",
    label: "EV003450: Cable tie"
  },
  {
    valueId: "EV003454",
    valueDesc: "Galvanic/electrolytic zinc plated",
    label: "EV003454: Galvanic/electrolytic zinc plated"
  },
  {
    valueId: "EV003458",
    valueDesc: "Caoutchouc",
    label: "EV003458: Caoutchouc"
  },
  {
    valueId: "EV003459",
    valueDesc: "Stand-by starting automatic",
    label: "EV003459: Stand-by starting automatic"
  },
  {
    valueId: "EV003463",
    valueDesc: "Fax switch",
    label: "EV003463: Fax switch"
  },
  {
    valueId: "EV003479",
    valueDesc: "Bridging lip",
    label: "EV003479: Bridging lip"
  },
  {
    valueId: "EV003481",
    valueDesc: "Retractable antenna",
    label: "EV003481: Retractable antenna"
  },
  {
    valueId: "EV003482",
    valueDesc: "Window clip-mount antenna",
    label: "EV003482: Window clip-mount antenna"
  },
  {
    valueId: "EV003483",
    valueDesc: "Automatic antenna",
    label: "EV003483: Automatic antenna"
  },
  {
    valueId: "EV003484",
    valueDesc: "Television antenna",
    label: "EV003484: Television antenna"
  },
  {
    valueId: "EV003485",
    valueDesc: "Rear mount antenna",
    label: "EV003485: Rear mount antenna"
  },
  {
    valueId: "EV003495",
    valueDesc: "Permanent metal filter",
    label: "EV003495: Permanent metal filter"
  },
  {
    valueId: "EV003496",
    valueDesc: "With round duct flag",
    label: "EV003496: With round duct flag"
  },
  {
    valueId: "EV003498",
    valueDesc: "2 sides punched",
    label: "EV003498: 2 sides punched"
  },
  {
    valueId: "EV003501",
    valueDesc: "3/8 inch",
    label: "EV003501: 3/8 inch"
  },
  {
    valueId: "EV003502",
    valueDesc: "3/4 inch",
    label: "EV003502: 3/4 inch"
  },
  {
    valueId: "EV003503",
    valueDesc: "1 inch",
    label: "EV003503: 1 inch"
  },
  {
    valueId: "EV003504",
    valueDesc: "1 1/4 inch",
    label: "EV003504: 1 1/4 inch"
  },
  {
    valueId: "EV003508",
    valueDesc: "Bitumen",
    label: "EV003508: Bitumen"
  },
  {
    valueId: "EV003509",
    valueDesc: "Rust solution",
    label: "EV003509: Rust solution"
  },
  {
    valueId: "EV003510",
    valueDesc: "Fluid temperature monitoring",
    label: "EV003510: Fluid temperature monitoring"
  },
  {
    valueId: "EV003511",
    valueDesc: "Brass/copper",
    label: "EV003511: Brass/copper"
  },
  {
    valueId: "EV003518",
    valueDesc: "Module rack",
    label: "EV003518: Module rack"
  },
  {
    valueId: "EV003519",
    valueDesc: "Freestanding model suitable for built-in",
    label: "EV003519: Freestanding model suitable for built-in"
  },
  {
    valueId: "EV003520",
    valueDesc: "Duct shears",
    label: "EV003520: Duct shears"
  },
  {
    valueId: "EV003525",
    valueDesc: "IEEE 802.11",
    label: "EV003525: IEEE 802.11"
  },
  {
    valueId: "EV003530",
    valueDesc: "Z",
    label: "EV003530: Z"
  },
  {
    valueId: "EV003547",
    valueDesc: "Plastic bag",
    label: "EV003547: Plastic bag"
  },
  {
    valueId: "EV003566",
    valueDesc: "R-SMA",
    label: "EV003566: R-SMA"
  },
  {
    valueId: "EV003570",
    valueDesc: "Mini OTDR 1310/1550 nm",
    label: "EV003570: Mini OTDR 1310/1550 nm"
  },
  {
    valueId: "EV003571",
    valueDesc: "Touch screen",
    label: "EV003571: Touch screen"
  },
  {
    valueId: "EV003572",
    valueDesc: "Mini OTDR 850/1300 nm",
    label: "EV003572: Mini OTDR 850/1300 nm"
  },
  {
    valueId: "EV003573",
    valueDesc: "OTDR, basic device",
    label: "EV003573: OTDR, basic device"
  },
  {
    valueId: "EV003577",
    valueDesc: "Fa8",
    label: "EV003577: Fa8"
  },
  {
    valueId: "EV003578",
    valueDesc: "R17d",
    label: "EV003578: R17d"
  },
  {
    valueId: "EV003579",
    valueDesc: "R18s",
    label: "EV003579: R18s"
  },
  {
    valueId: "EV003580",
    valueDesc: "90-100",
    label: "EV003580: 90-100"
  },
  {
    valueId: "EV003581",
    valueDesc: "80-89",
    label: "EV003581: 80-89"
  },
  {
    valueId: "EV003582",
    valueDesc: "70-79",
    label: "EV003582: 70-79"
  },
  {
    valueId: "EV003583",
    valueDesc: "60-69",
    label: "EV003583: 60-69"
  },
  {
    valueId: "EV003584",
    valueDesc: "40-59",
    label: "EV003584: 40-59"
  },
  {
    valueId: "EV003585",
    valueDesc: "20-39",
    label: "EV003585: 20-39"
  },
  {
    valueId: "EV003586",
    valueDesc: "PG12-3",
    label: "EV003586: PG12-3"
  },
  {
    valueId: "EV003587",
    valueDesc: "G5.3",
    label: "EV003587: G5.3"
  },
  {
    valueId: "EV003588",
    valueDesc: "G24",
    label: "EV003588: G24"
  },
  {
    valueId: "EV003589",
    valueDesc: "GY5.3",
    label: "EV003589: GY5.3"
  },
  {
    valueId: "EV003591",
    valueDesc: "Tube, single-ended",
    label: "EV003591: Tube, single-ended"
  },
  {
    valueId: "EV003592",
    valueDesc: "E40-POS",
    label: "EV003592: E40-POS"
  },
  {
    valueId: "EV003593",
    valueDesc: "KX10s",
    label: "EV003593: KX10s"
  },
  {
    valueId: "EV003594",
    valueDesc: "KY10s",
    label: "EV003594: KY10s"
  },
  {
    valueId: "EV003595",
    valueDesc: "X515",
    label: "EV003595: X515"
  },
  {
    valueId: "EV003596",
    valueDesc: "B22d3",
    label: "EV003596: B22d3"
  },
  {
    valueId: "EV003597",
    valueDesc: "Medium Flood",
    label: "EV003597: Medium Flood"
  },
  {
    valueId: "EV003598",
    valueDesc: "E12",
    label: "EV003598: E12"
  },
  {
    valueId: "EV003599",
    valueDesc: "BA20d",
    label: "EV003599: BA20d"
  },
  {
    valueId: "EV003600",
    valueDesc: "S15s",
    label: "EV003600: S15s"
  },
  {
    valueId: "EV003601",
    valueDesc: "Twisted",
    label: "EV003601: Twisted"
  },
  {
    valueId: "EV003602",
    valueDesc: "Double",
    label: "EV003602: Double"
  },
  {
    valueId: "EV003606",
    valueDesc: "Foil",
    label: "EV003606: Foil"
  },
  {
    valueId: "EV003607",
    valueDesc: "Braiding",
    label: "EV003607: Braiding"
  },
  {
    valueId: "EV003608",
    valueDesc: "Pozidriv PZ",
    label: "EV003608: Pozidriv PZ"
  },
  {
    valueId: "EV003609",
    valueDesc: "Purple",
    label: "EV003609: Purple"
  },
  {
    valueId: "EV003613",
    valueDesc: "Loudspeaker",
    label: "EV003613: Loudspeaker"
  },
  {
    valueId: "EV003615",
    valueDesc: "Primed",
    label: "EV003615: Primed"
  },
  {
    valueId: "EV003618",
    valueDesc: "Low",
    label: "EV003618: Low"
  },
  {
    valueId: "EV003619",
    valueDesc: "High",
    label: "EV003619: High"
  },
  {
    valueId: "EV003622",
    valueDesc: "Positions",
    label: "EV003622: Positions"
  },
  {
    valueId: "EV003624",
    valueDesc: "Venetian blind",
    label: "EV003624: Venetian blind"
  },
  {
    valueId: "EV003627",
    valueDesc: "Push button/touch control button",
    label: "EV003627: Push button/touch control button"
  },
  {
    valueId: "EV003629",
    valueDesc: "SAT",
    label: "EV003629: SAT"
  },
  {
    valueId: "EV003630",
    valueDesc: "Bore hole",
    label: "EV003630: Bore hole"
  },
  {
    valueId: "EV003634",
    valueDesc: "Round/square",
    label: "EV003634: Round/square"
  },
  {
    valueId: "EV003635",
    valueDesc: "Plastic sheathed",
    label: "EV003635: Plastic sheathed"
  },
  {
    valueId: "EV003638",
    valueDesc: "Nail",
    label: "EV003638: Nail"
  },
  {
    valueId: "EV003639",
    valueDesc: "Nail screw",
    label: "EV003639: Nail screw"
  },
  {
    valueId: "EV003642",
    valueDesc: "Tube shape (closed)",
    label: "EV003642: Tube shape (closed)"
  },
  {
    valueId: "EV003643",
    valueDesc: "Profile (open)",
    label: "EV003643: Profile (open)"
  },
  {
    valueId: "EV003644",
    valueDesc: "For plug-in",
    label: "EV003644: For plug-in"
  },
  {
    valueId: "EV003645",
    valueDesc: "Drive in type",
    label: "EV003645: Drive in type"
  },
  {
    valueId: "EV003649",
    valueDesc: "45°",
    label: "EV003649: 45°"
  },
  {
    valueId: "EV003650",
    valueDesc: "90°",
    label: "EV003650: 90°"
  },
  {
    valueId: "EV003651",
    valueDesc: "Post top",
    label: "EV003651: Post top"
  },
  {
    valueId: "EV003652",
    valueDesc: "Floor standing",
    label: "EV003652: Floor standing"
  },
  {
    valueId: "EV003653",
    valueDesc: "Ball cylinder",
    label: "EV003653: Ball cylinder"
  },
  {
    valueId: "EV003654",
    valueDesc: "Domed head",
    label: "EV003654: Domed head"
  },
  {
    valueId: "EV003656",
    valueDesc: "Inner square",
    label: "EV003656: Inner square"
  },
  {
    valueId: "EV003660",
    valueDesc: "Bus connection",
    label: "EV003660: Bus connection"
  },
  {
    valueId: "EV003661",
    valueDesc: "Red-leaded",
    label: "EV003661: Red-leaded"
  },
  {
    valueId: "EV003662",
    valueDesc: "Surface tank",
    label: "EV003662: Surface tank"
  },
  {
    valueId: "EV003669",
    valueDesc: "Mini circuit breaker",
    label: "EV003669: Mini circuit breaker"
  },
  {
    valueId: "EV003670",
    valueDesc: "Screw fuse",
    label: "EV003670: Screw fuse"
  },
  {
    valueId: "EV003671",
    valueDesc: "Electrical",
    label: "EV003671: Electrical"
  },
  {
    valueId: "EV003672",
    valueDesc: "Pneumatic",
    label: "EV003672: Pneumatic"
  },
  {
    valueId: "EV003673",
    valueDesc: "Stone",
    label: "EV003673: Stone"
  },
  {
    valueId: "EV003674",
    valueDesc: "PTT",
    label: "EV003674: PTT"
  },
  {
    valueId: "EV003679",
    valueDesc: "Y-branch",
    label: "EV003679: Y-branch"
  },
  {
    valueId: "EV003680",
    valueDesc: "Through branch",
    label: "EV003680: Through branch"
  },
  {
    valueId: "EV003681",
    valueDesc: "Double branch",
    label: "EV003681: Double branch"
  },
  {
    valueId: "EV003682",
    valueDesc: "Illuminated (on)",
    label: "EV003682: Illuminated (on)"
  },
  {
    valueId: "EV003683",
    valueDesc: "Control (off position)",
    label: "EV003683: Control (off position)"
  },
  {
    valueId: "EV003685",
    valueDesc: "End",
    label: "EV003685: End"
  },
  {
    valueId: "EV003688",
    valueDesc: "Numbers",
    label: "EV003688: Numbers"
  },
  {
    valueId: "EV003691",
    valueDesc: "Pipe",
    label: "EV003691: Pipe"
  },
  {
    valueId: "EV003692",
    valueDesc: "Partial door",
    label: "EV003692: Partial door"
  },
  {
    valueId: "EV003693",
    valueDesc: "Inner door",
    label: "EV003693: Inner door"
  },
  {
    valueId: "EV003695",
    valueDesc: "PG",
    label: "EV003695: PG"
  },
  {
    valueId: "EV003697",
    valueDesc: "T-handle",
    label: "EV003697: T-handle"
  },
  {
    valueId: "EV003698",
    valueDesc: "Out folding crank",
    label: "EV003698: Out folding crank"
  },
  {
    valueId: "EV003699",
    valueDesc: "Paper",
    label: "EV003699: Paper"
  },
  {
    valueId: "EV003701",
    valueDesc: "Bayonet",
    label: "EV003701: Bayonet"
  },
  {
    valueId: "EV003702",
    valueDesc: "Cable/tube",
    label: "EV003702: Cable/tube"
  },
  {
    valueId: "EV003709",
    valueDesc: "Single locking lever",
    label: "EV003709: Single locking lever"
  },
  {
    valueId: "EV003710",
    valueDesc: "Double locking lever",
    label: "EV003710: Double locking lever"
  },
  {
    valueId: "EV003711",
    valueDesc: "Central clamp",
    label: "EV003711: Central clamp"
  },
  {
    valueId: "EV003712",
    valueDesc: "Metric",
    label: "EV003712: Metric"
  },
  {
    valueId: "EV003713",
    valueDesc: "Gas-thread",
    label: "EV003713: Gas-thread"
  },
  {
    valueId: "EV003714",
    valueDesc: "NPT",
    label: "EV003714: NPT"
  },
  {
    valueId: "EV003715",
    valueDesc: "Silver-plated",
    label: "EV003715: Silver-plated"
  },
  {
    valueId: "EV003716",
    valueDesc: "Diaphragm",
    label: "EV003716: Diaphragm"
  },
  {
    valueId: "EV003717",
    valueDesc: "Rubber ring",
    label: "EV003717: Rubber ring"
  },
  {
    valueId: "EV003718",
    valueDesc: "Cut-out ring",
    label: "EV003718: Cut-out ring"
  },
  {
    valueId: "EV003719",
    valueDesc: "Membrane",
    label: "EV003719: Membrane"
  },
  {
    valueId: "EV003720",
    valueDesc: "Knife blade fuse (NH)",
    label: "EV003720: Knife blade fuse (NH)"
  },
  {
    valueId: "EV003721",
    valueDesc: "Central conduit box",
    label: "EV003721: Central conduit box"
  },
  {
    valueId: "EV003725",
    valueDesc: "Brush",
    label: "EV003725: Brush"
  },
  {
    valueId: "EV003726",
    valueDesc: "RS-422",
    label: "EV003726: RS-422"
  },
  {
    valueId: "EV003727",
    valueDesc: "RS-485",
    label: "EV003727: RS-485"
  },
  {
    valueId: "EV003729",
    valueDesc: "Connector",
    label: "EV003729: Connector"
  },
  {
    valueId: "EV003730",
    valueDesc: "Thread end",
    label: "EV003730: Thread end"
  },
  {
    valueId: "EV003733",
    valueDesc: "Double-lobe clamp",
    label: "EV003733: Double-lobe clamp"
  },
  {
    valueId: "EV003734",
    valueDesc: "Single-lobe clamp",
    label: "EV003734: Single-lobe clamp"
  },
  {
    valueId: "EV003736",
    valueDesc: "Angular profile",
    label: "EV003736: Angular profile"
  },
  {
    valueId: "EV003737",
    valueDesc: "U-profile",
    label: "EV003737: U-profile"
  },
  {
    valueId: "EV003742",
    valueDesc: "With internal thread",
    label: "EV003742: With internal thread"
  },
  {
    valueId: "EV003743",
    valueDesc: "C45 (steel)",
    label: "EV003743: C45 (steel)"
  },
  {
    valueId: "EV003744",
    valueDesc: "AG3 (aluminium)",
    label: "EV003744: AG3 (aluminium)"
  },
  {
    valueId: "EV003745",
    valueDesc: "AG5 (aluminium)",
    label: "EV003745: AG5 (aluminium)"
  },
  {
    valueId: "EV003747",
    valueDesc: "Insert (push-fitting)",
    label: "EV003747: Insert (push-fitting)"
  },
  {
    valueId: "EV003748",
    valueDesc: "Impact",
    label: "EV003748: Impact"
  },
  {
    valueId: "EV003749",
    valueDesc: "Axle shaft",
    label: "EV003749: Axle shaft"
  },
  {
    valueId: "EV003750",
    valueDesc: "Straight ladder",
    label: "EV003750: Straight ladder"
  },
  {
    valueId: "EV003751",
    valueDesc: "Scaling ladder",
    label: "EV003751: Scaling ladder"
  },
  {
    valueId: "EV003752",
    valueDesc: "Telescopic ladder",
    label: "EV003752: Telescopic ladder"
  },
  {
    valueId: "EV003753",
    valueDesc: "Hinged ladder",
    label: "EV003753: Hinged ladder"
  },
  {
    valueId: "EV003772",
    valueDesc: "Induction lamp",
    label: "EV003772: Induction lamp"
  },
  {
    valueId: "EV003773",
    valueDesc: "RX7",
    label: "EV003773: RX7"
  },
  {
    valueId: "EV003774",
    valueDesc: "PGX12-2",
    label: "EV003774: PGX12-2"
  },
  {
    valueId: "EV003775",
    valueDesc: "Indirect",
    label: "EV003775: Indirect"
  },
  {
    valueId: "EV003781",
    valueDesc: "Sticker",
    label: "EV003781: Sticker"
  },
  {
    valueId: "EV003782",
    valueDesc: "Insert sheet/plate",
    label: "EV003782: Insert sheet/plate"
  },
  {
    valueId: "EV003783",
    valueDesc: "Acrylic plate",
    label: "EV003783: Acrylic plate"
  },
  {
    valueId: "EV003784",
    valueDesc: "Escape route",
    label: "EV003784: Escape route"
  },
  {
    valueId: "EV003788",
    valueDesc: "Side entry",
    label: "EV003788: Side entry"
  },
  {
    valueId: "EV003789",
    valueDesc: "Post top/side entry",
    label: "EV003789: Post top/side entry"
  },
  {
    valueId: "EV003790",
    valueDesc: "Central",
    label: "EV003790: Central"
  },
  {
    valueId: "EV003791",
    valueDesc: "Peripheral",
    label: "EV003791: Peripheral"
  },
  {
    valueId: "EV003792",
    valueDesc: "Surface mounting/recessed mounting",
    label: "EV003792: Surface mounting/recessed mounting"
  },
  {
    valueId: "EV003793",
    valueDesc: "Line lighting system",
    label: "EV003793: Line lighting system"
  },
  {
    valueId: "EV003796",
    valueDesc: "Junction",
    label: "EV003796: Junction"
  },
  {
    valueId: "EV003817",
    valueDesc: "S-shaped",
    label: "EV003817: S-shaped"
  },
  {
    valueId: "EV003821",
    valueDesc: "Stripe",
    label: "EV003821: Stripe"
  },
  {
    valueId: "EV003822",
    valueDesc: "Multi-coloured",
    label: "EV003822: Multi-coloured"
  },
  {
    valueId: "EV003823",
    valueDesc: "H7",
    label: "EV003823: H7"
  },
  {
    valueId: "EV003824",
    valueDesc: "H8",
    label: "EV003824: H8"
  },
  {
    valueId: "EV003825",
    valueDesc: "H9",
    label: "EV003825: H9"
  },
  {
    valueId: "EV003826",
    valueDesc: "H10",
    label: "EV003826: H10"
  },
  {
    valueId: "EV003827",
    valueDesc: "H11",
    label: "EV003827: H11"
  },
  {
    valueId: "EV003828",
    valueDesc: "D1R",
    label: "EV003828: D1R"
  },
  {
    valueId: "EV003829",
    valueDesc: "D1S",
    label: "EV003829: D1S"
  },
  {
    valueId: "EV003830",
    valueDesc: "D2R",
    label: "EV003830: D2R"
  },
  {
    valueId: "EV003831",
    valueDesc: "D2S",
    label: "EV003831: D2S"
  },
  {
    valueId: "EV003832",
    valueDesc: "SV7-8",
    label: "EV003832: SV7-8"
  },
  {
    valueId: "EV003833",
    valueDesc: "SV8.5-8",
    label: "EV003833: SV8.5-8"
  },
  {
    valueId: "EV003834",
    valueDesc: "W2x4.5d",
    label: "EV003834: W2x4.5d"
  },
  {
    valueId: "EV003836",
    valueDesc: "W2.5x16d",
    label: "EV003836: W2.5x16d"
  },
  {
    valueId: "EV003837",
    valueDesc: "W2.5x16q",
    label: "EV003837: W2.5x16q"
  },
  {
    valueId: "EV003838",
    valueDesc: "W4.3x8.5",
    label: "EV003838: W4.3x8.5"
  },
  {
    valueId: "EV003839",
    valueDesc: "BAY9s",
    label: "EV003839: BAY9s"
  },
  {
    valueId: "EV003840",
    valueDesc: "BAX9s",
    label: "EV003840: BAX9s"
  },
  {
    valueId: "EV003841",
    valueDesc: "B8.4-12.5",
    label: "EV003841: B8.4-12.5"
  },
  {
    valueId: "EV003842",
    valueDesc: "B8.5d",
    label: "EV003842: B8.5d"
  },
  {
    valueId: "EV003843",
    valueDesc: "B8.3d",
    label: "EV003843: B8.3d"
  },
  {
    valueId: "EV003844",
    valueDesc: "B8.7d",
    label: "EV003844: B8.7d"
  },
  {
    valueId: "EV003845",
    valueDesc: "B10d-14.5",
    label: "EV003845: B10d-14.5"
  },
  {
    valueId: "EV003846",
    valueDesc: "BAU15s",
    label: "EV003846: BAU15s"
  },
  {
    valueId: "EV003847",
    valueDesc: "BAY15d",
    label: "EV003847: BAY15d"
  },
  {
    valueId: "EV003848",
    valueDesc: "BAZ15d",
    label: "EV003848: BAZ15d"
  },
  {
    valueId: "EV003849",
    valueDesc: "P26s",
    label: "EV003849: P26s"
  },
  {
    valueId: "EV003850",
    valueDesc: "PX14.5s",
    label: "EV003850: PX14.5s"
  },
  {
    valueId: "EV003851",
    valueDesc: "PX26d",
    label: "EV003851: PX26d"
  },
  {
    valueId: "EV003853",
    valueDesc: "PGJ19",
    label: "EV003853: PGJ19"
  },
  {
    valueId: "EV003854",
    valueDesc: "PGJ19-1",
    label: "EV003854: PGJ19-1"
  },
  {
    valueId: "EV003855",
    valueDesc: "PGJ19-2",
    label: "EV003855: PGJ19-2"
  },
  {
    valueId: "EV003856",
    valueDesc: "PGJ19-5",
    label: "EV003856: PGJ19-5"
  },
  {
    valueId: "EV003857",
    valueDesc: "PY20d",
    label: "EV003857: PY20d"
  },
  {
    valueId: "EV003858",
    valueDesc: "PX29t",
    label: "EV003858: PX29t"
  },
  {
    valueId: "EV003859",
    valueDesc: "PX43t",
    label: "EV003859: PX43t"
  },
  {
    valueId: "EV003860",
    valueDesc: "P20d",
    label: "EV003860: P20d"
  },
  {
    valueId: "EV003861",
    valueDesc: "P22d",
    label: "EV003861: P22d"
  },
  {
    valueId: "EV003862",
    valueDesc: "P29t",
    label: "EV003862: P29t"
  },
  {
    valueId: "EV003863",
    valueDesc: "P32d-2",
    label: "EV003863: P32d-2"
  },
  {
    valueId: "EV003864",
    valueDesc: "P32d-3",
    label: "EV003864: P32d-3"
  },
  {
    valueId: "EV003865",
    valueDesc: "PU43t",
    label: "EV003865: PU43t"
  },
  {
    valueId: "EV003866",
    valueDesc: "P45t",
    label: "EV003866: P45t"
  },
  {
    valueId: "EV003867",
    valueDesc: "L-profile",
    label: "EV003867: L-profile"
  },
  {
    valueId: "EV003868",
    valueDesc: "Slide-in bracket",
    label: "EV003868: Slide-in bracket"
  },
  {
    valueId: "EV003869",
    valueDesc: "Claw",
    label: "EV003869: Claw"
  },
  {
    valueId: "EV003870",
    valueDesc: "Face out bracket (C-bracket)",
    label: "EV003870: Face out bracket (C-bracket)"
  },
  {
    valueId: "EV003873",
    valueDesc: "Right-angled",
    label: "EV003873: Right-angled"
  },
  {
    valueId: "EV003874",
    valueDesc: "Ethylene-vinyl acetate (EVA)",
    label: "EV003874: Ethylene-vinyl acetate (EVA)"
  },
  {
    valueId: "EV003876",
    valueDesc: "Solid PE (HD-PE)",
    label: "EV003876: Solid PE (HD-PE)"
  },
  {
    valueId: "EV003878",
    valueDesc: "Ethylene tetrafluoroethylene (ETFE)",
    label: "EV003878: Ethylene tetrafluoroethylene (ETFE)"
  },
  {
    valueId: "EV003879",
    valueDesc: "Polytetrafluoroethylene (PTFE)",
    label: "EV003879: Polytetrafluoroethylene (PTFE)"
  },
  {
    valueId: "EV003880",
    valueDesc: "Fluorinated ethylene propylene (FEP)",
    label: "EV003880: Fluorinated ethylene propylene (FEP)"
  },
  {
    valueId: "EV003881",
    valueDesc: "PA",
    label: "EV003881: PA"
  },
  {
    valueId: "EV003883",
    valueDesc: "Grooves",
    label: "EV003883: Grooves"
  },
  {
    valueId: "EV003886",
    valueDesc: "Fork shape",
    label: "EV003886: Fork shape"
  },
  {
    valueId: "EV003887",
    valueDesc: "Pin shape",
    label: "EV003887: Pin shape"
  },
  {
    valueId: "EV003888",
    valueDesc: "Nickel",
    label: "EV003888: Nickel"
  },
  {
    valueId: "EV003889",
    valueDesc: "Hook shape",
    label: "EV003889: Hook shape"
  },
  {
    valueId: "EV003890",
    valueDesc: "Special shape",
    label: "EV003890: Special shape"
  },
  {
    valueId: "EV003893",
    valueDesc: "Up to 15 kV",
    label: "EV003893: Up to 15 kV"
  },
  {
    valueId: "EV003896",
    valueDesc: "Up to 10 kV",
    label: "EV003896: Up to 10 kV"
  },
  {
    valueId: "EV003900",
    valueDesc: "End connector",
    label: "EV003900: End connector"
  },
  {
    valueId: "EV003906",
    valueDesc: "Thread",
    label: "EV003906: Thread"
  },
  {
    valueId: "EV003907",
    valueDesc: "Plug-in",
    label: "EV003907: Plug-in"
  },
  {
    valueId: "EV003909",
    valueDesc: "Pliable corrugated",
    label: "EV003909: Pliable corrugated"
  },
  {
    valueId: "EV003916",
    valueDesc: "Pliable",
    label: "EV003916: Pliable"
  },
  {
    valueId: "EV003919",
    valueDesc: "Clean room",
    label: "EV003919: Clean room"
  },
  {
    valueId: "EV003920",
    valueDesc: "Cooling chamber",
    label: "EV003920: Cooling chamber"
  },
  {
    valueId: "EV003921",
    valueDesc: "Aquarium/seawater",
    label: "EV003921: Aquarium/seawater"
  },
  {
    valueId: "EV003922",
    valueDesc: "Aquarium/fresh water",
    label: "EV003922: Aquarium/fresh water"
  },
  {
    valueId: "EV003923",
    valueDesc: "Terrarium",
    label: "EV003923: Terrarium"
  },
  {
    valueId: "EV003924",
    valueDesc: "Food",
    label: "EV003924: Food"
  },
  {
    valueId: "EV003925",
    valueDesc: "Ex-proof environment",
    label: "EV003925: Ex-proof environment"
  },
  {
    valueId: "EV003927",
    valueDesc: "Hardening",
    label: "EV003927: Hardening"
  },
  {
    valueId: "EV003929",
    valueDesc: "ESX",
    label: "EV003929: ESX"
  },
  {
    valueId: "EV003930",
    valueDesc: "BAB",
    label: "EV003930: BAB"
  },
  {
    valueId: "EV003931",
    valueDesc: "FRB",
    label: "EV003931: FRB"
  },
  {
    valueId: "EV003932",
    valueDesc: "FMW",
    label: "EV003932: FMW"
  },
  {
    valueId: "EV003933",
    valueDesc: "EXT",
    label: "EV003933: EXT"
  },
  {
    valueId: "EV003934",
    valueDesc: "EXZ",
    label: "EV003934: EXZ"
  },
  {
    valueId: "EV003935",
    valueDesc: "EXN",
    label: "EV003935: EXN"
  },
  {
    valueId: "EV003936",
    valueDesc: "FNV",
    label: "EV003936: FNV"
  },
  {
    valueId: "EV003937",
    valueDesc: "BBF",
    label: "EV003937: BBF"
  },
  {
    valueId: "EV003938",
    valueDesc: "FTB",
    label: "EV003938: FTB"
  },
  {
    valueId: "EV003939",
    valueDesc: "FTD",
    label: "EV003939: FTD"
  },
  {
    valueId: "EV003940",
    valueDesc: "FTH",
    label: "EV003940: FTH"
  },
  {
    valueId: "EV003941",
    valueDesc: "FTE",
    label: "EV003941: FTE"
  },
  {
    valueId: "EV003942",
    valueDesc: "GBA",
    label: "EV003942: GBA"
  },
  {
    valueId: "EV003943",
    valueDesc: "GBD",
    label: "EV003943: GBD"
  },
  {
    valueId: "EV003944",
    valueDesc: "GBE",
    label: "EV003944: GBE"
  },
  {
    valueId: "EV003945",
    valueDesc: "GBF",
    label: "EV003945: GBF"
  },
  {
    valueId: "EV003946",
    valueDesc: "GBB",
    label: "EV003946: GBB"
  },
  {
    valueId: "EV003947",
    valueDesc: "GBC",
    label: "EV003947: GBC"
  },
  {
    valueId: "EV003948",
    valueDesc: "GBG",
    label: "EV003948: GBG"
  },
  {
    valueId: "EV003949",
    valueDesc: "GBH",
    label: "EV003949: GBH"
  },
  {
    valueId: "EV003950",
    valueDesc: "GBJ",
    label: "EV003950: GBJ"
  },
  {
    valueId: "EV003951",
    valueDesc: "GBK",
    label: "EV003951: GBK"
  },
  {
    valueId: "EV003952",
    valueDesc: "Aluminium plated glass",
    label: "EV003952: Aluminium plated glass"
  },
  {
    valueId: "EV003954",
    valueDesc: "Up to 30 kV",
    label: "EV003954: Up to 30 kV"
  },
  {
    valueId: "EV003955",
    valueDesc: "Lighting for plants",
    label: "EV003955: Lighting for plants"
  },
  {
    valueId: "EV003956",
    valueDesc: "Ring shape",
    label: "EV003956: Ring shape"
  },
  {
    valueId: "EV003957",
    valueDesc: "Special angle",
    label: "EV003957: Special angle"
  },
  {
    valueId: "EV003958",
    valueDesc: "Reversing contactor",
    label: "EV003958: Reversing contactor"
  },
  {
    valueId: "EV003959",
    valueDesc: "Star-delta contactor",
    label: "EV003959: Star-delta contactor"
  },
  {
    valueId: "EV003963",
    valueDesc: "PCB connection",
    label: "EV003963: PCB connection"
  },
  {
    valueId: "EV003964",
    valueDesc: "Front fastening",
    label: "EV003964: Front fastening"
  },
  {
    valueId: "EV003965",
    valueDesc: "Side mounting",
    label: "EV003965: Side mounting"
  },
  {
    valueId: "EV003966",
    valueDesc: "Integrable",
    label: "EV003966: Integrable"
  },
  {
    valueId: "EV003967",
    valueDesc: "Adjustable roller lever",
    label: "EV003967: Adjustable roller lever"
  },
  {
    valueId: "EV003968",
    valueDesc: "Flat plug-in connection",
    label: "EV003968: Flat plug-in connection"
  },
  {
    valueId: "EV003969",
    valueDesc: "Separate positioning",
    label: "EV003969: Separate positioning"
  },
  {
    valueId: "EV003970",
    valueDesc: "Direct attachment",
    label: "EV003970: Direct attachment"
  },
  {
    valueId: "EV003972",
    valueDesc: "Wedge Base W 2x4.6d",
    label: "EV003972: Wedge Base W 2x4.6d"
  },
  {
    valueId: "EV003973",
    valueDesc: "Neon lamp",
    label: "EV003973: Neon lamp"
  },
  {
    valueId: "EV003974",
    valueDesc: "Pull-release",
    label: "EV003974: Pull-release"
  },
  {
    valueId: "EV003975",
    valueDesc: "Turn-release",
    label: "EV003975: Turn-release"
  },
  {
    valueId: "EV003976",
    valueDesc: "Key-release",
    label: "EV003976: Key-release"
  },
  {
    valueId: "EV003977",
    valueDesc: "Delayed",
    label: "EV003977: Delayed"
  },
  {
    valueId: "EV003978",
    valueDesc: "Undelayed",
    label: "EV003978: Undelayed"
  },
  {
    valueId: "EV003979",
    valueDesc: "RC-element",
    label: "EV003979: RC-element"
  },
  {
    valueId: "EV003980",
    valueDesc: "Front installation",
    label: "EV003980: Front installation"
  },
  {
    valueId: "EV003982",
    valueDesc: "Magnetic drive",
    label: "EV003982: Magnetic drive"
  },
  {
    valueId: "EV003983",
    valueDesc: "Motor drive",
    label: "EV003983: Motor drive"
  },
  {
    valueId: "EV003984",
    valueDesc: "Without button plate",
    label: "EV003984: Without button plate"
  },
  {
    valueId: "EV003985",
    valueDesc: "Surface mounted housing",
    label: "EV003985: Surface mounted housing"
  },
  {
    valueId: "EV003987",
    valueDesc: "Insulation displacement connection",
    label: "EV003987: Insulation displacement connection"
  },
  {
    valueId: "EV003991",
    valueDesc: "NH000",
    label: "EV003991: NH000"
  },
  {
    valueId: "EV003993",
    valueDesc: "NH1",
    label: "EV003993: NH1"
  },
  {
    valueId: "EV003994",
    valueDesc: "NH2",
    label: "EV003994: NH2"
  },
  {
    valueId: "EV003995",
    valueDesc: "S27",
    label: "EV003995: S27"
  },
  {
    valueId: "EV003996",
    valueDesc: "S33",
    label: "EV003996: S33"
  },
  {
    valueId: "EV004002",
    valueDesc: "Reversing starter",
    label: "EV004002: Reversing starter"
  },
  {
    valueId: "EV004003",
    valueDesc: "Direct online starter (DOL)",
    label: "EV004003: Direct online starter (DOL)"
  },
  {
    valueId: "EV004004",
    valueDesc: "Star-delta starter",
    label: "EV004004: Star-delta starter"
  },
  {
    valueId: "EV004005",
    valueDesc: "NH3",
    label: "EV004005: NH3"
  },
  {
    valueId: "EV004006",
    valueDesc: "NH4a",
    label: "EV004006: NH4a"
  },
  {
    valueId: "EV004009",
    valueDesc: "UP",
    label: "EV004009: UP"
  },
  {
    valueId: "EV004016",
    valueDesc: "Gland nut",
    label: "EV004016: Gland nut"
  },
  {
    valueId: "EV004017",
    valueDesc: "ISO 12",
    label: "EV004017: ISO 12"
  },
  {
    valueId: "EV004018",
    valueDesc: "ISO 16",
    label: "EV004018: ISO 16"
  },
  {
    valueId: "EV004019",
    valueDesc: "ISO 20",
    label: "EV004019: ISO 20"
  },
  {
    valueId: "EV004020",
    valueDesc: "ISO 25",
    label: "EV004020: ISO 25"
  },
  {
    valueId: "EV004021",
    valueDesc: "ISO 32",
    label: "EV004021: ISO 32"
  },
  {
    valueId: "EV004022",
    valueDesc: "ISO 40",
    label: "EV004022: ISO 40"
  },
  {
    valueId: "EV004023",
    valueDesc: "ISO 50",
    label: "EV004023: ISO 50"
  },
  {
    valueId: "EV004024",
    valueDesc: "ISO 63",
    label: "EV004024: ISO 63"
  },
  {
    valueId: "EV004030",
    valueDesc: "Ratchet model mechanical",
    label: "EV004030: Ratchet model mechanical"
  },
  {
    valueId: "EV004031",
    valueDesc: "Hydraulic cutter head",
    label: "EV004031: Hydraulic cutter head"
  },
  {
    valueId: "EV004032",
    valueDesc: "Hydraulic cutter head with foot pump",
    label: "EV004032: Hydraulic cutter head with foot pump"
  },
  {
    valueId: "EV004033",
    valueDesc: "Hydraulic cutter head with electrical pump",
    label: "EV004033: Hydraulic cutter head with electrical pump"
  },
  {
    valueId: "EV004034",
    valueDesc: "Mechanic one hand",
    label: "EV004034: Mechanic one hand"
  },
  {
    valueId: "EV004035",
    valueDesc: "Mechanic two hands",
    label: "EV004035: Mechanic two hands"
  },
  {
    valueId: "EV004036",
    valueDesc: "Funnel",
    label: "EV004036: Funnel"
  },
  {
    valueId: "EV004037",
    valueDesc: "Spiral spring",
    label: "EV004037: Spiral spring"
  },
  {
    valueId: "EV004038",
    valueDesc: "Support clamp",
    label: "EV004038: Support clamp"
  },
  {
    valueId: "EV004039",
    valueDesc: "Door side panel",
    label: "EV004039: Door side panel"
  },
  {
    valueId: "EV004040",
    valueDesc: "Triangle",
    label: "EV004040: Triangle"
  },
  {
    valueId: "EV004041",
    valueDesc: "Rectangle",
    label: "EV004041: Rectangle"
  },
  {
    valueId: "EV004043",
    valueDesc: "Control module",
    label: "EV004043: Control module"
  },
  {
    valueId: "EV004044",
    valueDesc: "Connecting-/branching terminal",
    label: "EV004044: Connecting-/branching terminal"
  },
  {
    valueId: "EV004045",
    valueDesc: "USB",
    label: "EV004045: USB"
  },
  {
    valueId: "EV004046",
    valueDesc: "Equipment mounted sensor",
    label: "EV004046: Equipment mounted sensor"
  },
  {
    valueId: "EV004048",
    valueDesc: "Movement sensor",
    label: "EV004048: Movement sensor"
  },
  {
    valueId: "EV004049",
    valueDesc: "Humidity sensor",
    label: "EV004049: Humidity sensor"
  },
  {
    valueId: "EV004050",
    valueDesc: "Presence detector",
    label: "EV004050: Presence detector"
  },
  {
    valueId: "EV004051",
    valueDesc: "Visualization",
    label: "EV004051: Visualization"
  },
  {
    valueId: "EV004053",
    valueDesc: "Product data bank",
    label: "EV004053: Product data bank"
  },
  {
    valueId: "EV004054",
    valueDesc: "Application software",
    label: "EV004054: Application software"
  },
  {
    valueId: "EV004055",
    valueDesc: "Planning-/put into operation software",
    label: "EV004055: Planning-/put into operation software"
  },
  {
    valueId: "EV004056",
    valueDesc: "German",
    label: "EV004056: German"
  },
  {
    valueId: "EV004057",
    valueDesc: "English",
    label: "EV004057: English"
  },
  {
    valueId: "EV004058",
    valueDesc: "Multi-lingual",
    label: "EV004058: Multi-lingual"
  },
  {
    valueId: "EV004060",
    valueDesc: "IR-interface",
    label: "EV004060: IR-interface"
  },
  {
    valueId: "EV004063",
    valueDesc: "LAN-systems interface",
    label: "EV004063: LAN-systems interface"
  },
  {
    valueId: "EV004064",
    valueDesc: "Telecommunications interface",
    label: "EV004064: Telecommunications interface"
  },
  {
    valueId: "EV004066",
    valueDesc: "Light system interface/DALI",
    label: "EV004066: Light system interface/DALI"
  },
  {
    valueId: "EV004067",
    valueDesc: "Energy meter",
    label: "EV004067: Energy meter"
  },
  {
    valueId: "EV004068",
    valueDesc: "Max. monitor",
    label: "EV004068: Max. monitor"
  },
  {
    valueId: "EV004069",
    valueDesc: "Convertible",
    label: "EV004069: Convertible"
  },
  {
    valueId: "EV004070",
    valueDesc: "Current module",
    label: "EV004070: Current module"
  },
  {
    valueId: "EV004094",
    valueDesc: "Short thumb-grip",
    label: "EV004094: Short thumb-grip"
  },
  {
    valueId: "EV004095",
    valueDesc: "Long turning handle",
    label: "EV004095: Long turning handle"
  },
  {
    valueId: "EV004097",
    valueDesc: "TWIN end socket",
    label: "EV004097: TWIN end socket"
  },
  {
    valueId: "EV004098",
    valueDesc: "End socket",
    label: "EV004098: End socket"
  },
  {
    valueId: "EV004099",
    valueDesc: "Magnet mount antenna",
    label: "EV004099: Magnet mount antenna"
  },
  {
    valueId: "EV004100",
    valueDesc: "Glass mount antenna",
    label: "EV004100: Glass mount antenna"
  },
  {
    valueId: "EV004101",
    valueDesc: "Bumper antenna",
    label: "EV004101: Bumper antenna"
  },
  {
    valueId: "EV004102",
    valueDesc: "Arbour antenna",
    label: "EV004102: Arbour antenna"
  },
  {
    valueId: "EV004103",
    valueDesc: "Fix",
    label: "EV004103: Fix"
  },
  {
    valueId: "EV004104",
    valueDesc: "23 mm",
    label: "EV004104: 23 mm"
  },
  {
    valueId: "EV004105",
    valueDesc: "40 mm",
    label: "EV004105: 40 mm"
  },
  {
    valueId: "EV004106",
    valueDesc: "VHF/UHF/FM",
    label: "EV004106: VHF/UHF/FM"
  },
  {
    valueId: "EV004107",
    valueDesc: "Plug",
    label: "EV004107: Plug"
  },
  {
    valueId: "EV004113",
    valueDesc: "5E",
    label: "EV004113: 5E"
  },
  {
    valueId: "EV004115",
    valueDesc: "Crimp",
    label: "EV004115: Crimp"
  },
  {
    valueId: "EV004116",
    valueDesc: "Single mode",
    label: "EV004116: Single mode"
  },
  {
    valueId: "EV004117",
    valueDesc: "Multi mode",
    label: "EV004117: Multi mode"
  },
  {
    valueId: "EV004118",
    valueDesc: "FC",
    label: "EV004118: FC"
  },
  {
    valueId: "EV004119",
    valueDesc: "LC",
    label: "EV004119: LC"
  },
  {
    valueId: "EV004120",
    valueDesc: "VF-45",
    label: "EV004120: VF-45"
  },
  {
    valueId: "EV004121",
    valueDesc: "MT/MPO",
    label: "EV004121: MT/MPO"
  },
  {
    valueId: "EV004122",
    valueDesc: "Epoxy",
    label: "EV004122: Epoxy"
  },
  {
    valueId: "EV004124",
    valueDesc: "Fusion splice",
    label: "EV004124: Fusion splice"
  },
  {
    valueId: "EV004125",
    valueDesc: "Mechanical splice",
    label: "EV004125: Mechanical splice"
  },
  {
    valueId: "EV004126",
    valueDesc: "Via the core",
    label: "EV004126: Via the core"
  },
  {
    valueId: "EV004127",
    valueDesc: "Via the cladding",
    label: "EV004127: Via the cladding"
  },
  {
    valueId: "EV004128",
    valueDesc: "CATV",
    label: "EV004128: CATV"
  },
  {
    valueId: "EV004135",
    valueDesc: "Mini-UHF",
    label: "EV004135: Mini-UHF"
  },
  {
    valueId: "EV004136",
    valueDesc: "FAKRA",
    label: "EV004136: FAKRA"
  },
  {
    valueId: "EV004137",
    valueDesc: "SMB",
    label: "EV004137: SMB"
  },
  {
    valueId: "EV004138",
    valueDesc: "MCX",
    label: "EV004138: MCX"
  },
  {
    valueId: "EV004139",
    valueDesc: "MMCX",
    label: "EV004139: MMCX"
  },
  {
    valueId: "EV004140",
    valueDesc: "FME",
    label: "EV004140: FME"
  },
  {
    valueId: "EV004141",
    valueDesc: "7/16",
    label: "EV004141: 7/16"
  },
  {
    valueId: "EV004142",
    valueDesc: "SMA",
    label: "EV004142: SMA"
  },
  {
    valueId: "EV004143",
    valueDesc: "Male",
    label: "EV004143: Male"
  },
  {
    valueId: "EV004144",
    valueDesc: "Female",
    label: "EV004144: Female"
  },
  {
    valueId: "EV004145",
    valueDesc: "Crimp/crimp",
    label: "EV004145: Crimp/crimp"
  },
  {
    valueId: "EV004146",
    valueDesc: "Solder/crimp",
    label: "EV004146: Solder/crimp"
  },
  {
    valueId: "EV004147",
    valueDesc: "Crimp/solder",
    label: "EV004147: Crimp/solder"
  },
  {
    valueId: "EV004148",
    valueDesc: "RG 58",
    label: "EV004148: RG 58"
  },
  {
    valueId: "EV004149",
    valueDesc: "RG 59",
    label: "EV004149: RG 59"
  },
  {
    valueId: "EV004150",
    valueDesc: "RG 62",
    label: "EV004150: RG 62"
  },
  {
    valueId: "EV004151",
    valueDesc: "Snapable",
    label: "EV004151: Snapable"
  },
  {
    valueId: "EV004152",
    valueDesc: "2x RJ45",
    label: "EV004152: 2x RJ45"
  },
  {
    valueId: "EV004153",
    valueDesc: "2x RJ12",
    label: "EV004153: 2x RJ12"
  },
  {
    valueId: "EV004154",
    valueDesc: "2x RJ11",
    label: "EV004154: 2x RJ11"
  },
  {
    valueId: "EV004155",
    valueDesc: "2x RJ10",
    label: "EV004155: 2x RJ10"
  },
  {
    valueId: "EV004156",
    valueDesc: "2x RJ9",
    label: "EV004156: 2x RJ9"
  },
  {
    valueId: "EV004157",
    valueDesc: "RJ45/RJ12",
    label: "EV004157: RJ45/RJ12"
  },
  {
    valueId: "EV004158",
    valueDesc: "RJ12/RJ10",
    label: "EV004158: RJ12/RJ10"
  },
  {
    valueId: "EV004159",
    valueDesc: "Insulated mounting",
    label: "EV004159: Insulated mounting"
  },
  {
    valueId: "EV004163",
    valueDesc: "Pulse tone",
    label: "EV004163: Pulse tone"
  },
  {
    valueId: "EV004165",
    valueDesc: "Slow-action switch",
    label: "EV004165: Slow-action switch"
  },
  {
    valueId: "EV004167",
    valueDesc: "PROFIBUS",
    label: "EV004167: PROFIBUS"
  },
  {
    valueId: "EV004168",
    valueDesc: "Adjustable rotary lever",
    label: "EV004168: Adjustable rotary lever"
  },
  {
    valueId: "EV004173",
    valueDesc: "Bus (jack)",
    label: "EV004173: Bus (jack)"
  },
  {
    valueId: "EV004175",
    valueDesc: "No",
    label: "EV004175: No"
  },
  {
    valueId: "EV004176",
    valueDesc: "According to EN 50265-2-1",
    label: "EV004176: According to EN 50265-2-1"
  },
  {
    valueId: "EV004178",
    valueDesc: "E30",
    label: "EV004178: E30"
  },
  {
    valueId: "EV004179",
    valueDesc: "E60",
    label: "EV004179: E60"
  },
  {
    valueId: "EV004180",
    valueDesc: "E90",
    label: "EV004180: E90"
  },
  {
    valueId: "EV004181",
    valueDesc: "Rings",
    label: "EV004181: Rings"
  },
  {
    valueId: "EV004183",
    valueDesc: "Triple",
    label: "EV004183: Triple"
  },
  {
    valueId: "EV004184",
    valueDesc: "Quad",
    label: "EV004184: Quad"
  },
  {
    valueId: "EV004190",
    valueDesc: "Polyurethane (PUR)",
    label: "EV004190: Polyurethane (PUR)"
  },
  {
    valueId: "EV004191",
    valueDesc: "Rubber - ethylene propylene rubber (EPR)",
    label: "EV004191: Rubber - ethylene propylene rubber (EPR)"
  },
  {
    valueId: "EV004192",
    valueDesc: "Rubber - ethylene-vinyl acetate (EVA)",
    label: "EV004192: Rubber - ethylene-vinyl acetate (EVA)"
  },
  {
    valueId: "EV004193",
    valueDesc: "Rubber - silicone rubber (SIR)",
    label: "EV004193: Rubber - silicone rubber (SIR)"
  },
  {
    valueId: "EV004196",
    valueDesc: "Corrugated metal",
    label: "EV004196: Corrugated metal"
  },
  {
    valueId: "EV004198",
    valueDesc: "Chloroprene rubber (CR)",
    label: "EV004198: Chloroprene rubber (CR)"
  },
  {
    valueId: "EV004199",
    valueDesc: "Class 1 = solid",
    label: "EV004199: Class 1 = solid"
  },
  {
    valueId: "EV004200",
    valueDesc: "Class 2 = stranded",
    label: "EV004200: Class 2 = stranded"
  },
  {
    valueId: "EV004201",
    valueDesc: "Class 5 = flexible",
    label: "EV004201: Class 5 = flexible"
  },
  {
    valueId: "EV004202",
    valueDesc: "Class 6 = very flexible",
    label: "EV004202: Class 6 = very flexible"
  },
  {
    valueId: "EV004203",
    valueDesc: "Sector-shaped",
    label: "EV004203: Sector-shaped"
  },
  {
    valueId: "EV004204",
    valueDesc: "NiFe",
    label: "EV004204: NiFe"
  },
  {
    valueId: "EV004205",
    valueDesc: "Aeration and de-aeration",
    label: "EV004205: Aeration and de-aeration"
  },
  {
    valueId: "EV004214",
    valueDesc: "Window",
    label: "EV004214: Window"
  },
  {
    valueId: "EV004215",
    valueDesc: "Duct",
    label: "EV004215: Duct"
  },
  {
    valueId: "EV004216",
    valueDesc: "Door",
    label: "EV004216: Door"
  },
  {
    valueId: "EV004217",
    valueDesc: "G1",
    label: "EV004217: G1"
  },
  {
    valueId: "EV004218",
    valueDesc: "F5",
    label: "EV004218: F5"
  },
  {
    valueId: "EV004219",
    valueDesc: "F6",
    label: "EV004219: F6"
  },
  {
    valueId: "EV004220",
    valueDesc: "F7",
    label: "EV004220: F7"
  },
  {
    valueId: "EV004221",
    valueDesc: "F8",
    label: "EV004221: F8"
  },
  {
    valueId: "EV004222",
    valueDesc: "F9",
    label: "EV004222: F9"
  },
  {
    valueId: "EV004223",
    valueDesc: "Dust filter",
    label: "EV004223: Dust filter"
  },
  {
    valueId: "EV004226",
    valueDesc: "Outside",
    label: "EV004226: Outside"
  },
  {
    valueId: "EV004228",
    valueDesc: "Foot mounting",
    label: "EV004228: Foot mounting"
  },
  {
    valueId: "EV004229",
    valueDesc: "Base mounting",
    label: "EV004229: Base mounting"
  },
  {
    valueId: "EV004230",
    valueDesc: "Continuous soldering tip",
    label: "EV004230: Continuous soldering tip"
  },
  {
    valueId: "EV004231",
    valueDesc: "Cartridge with dispense brush",
    label: "EV004231: Cartridge with dispense brush"
  },
  {
    valueId: "EV004232",
    valueDesc: "Can",
    label: "EV004232: Can"
  },
  {
    valueId: "EV004234",
    valueDesc: "Lap-screen",
    label: "EV004234: Lap-screen"
  },
  {
    valueId: "EV004235",
    valueDesc: "PE/air",
    label: "EV004235: PE/air"
  },
  {
    valueId: "EV004236",
    valueDesc: "Loose tube",
    label: "EV004236: Loose tube"
  },
  {
    valueId: "EV004241",
    valueDesc: "Simplex",
    label: "EV004241: Simplex"
  },
  {
    valueId: "EV004243",
    valueDesc: "Strip",
    label: "EV004243: Strip"
  },
  {
    valueId: "EV004244",
    valueDesc: "FE 180",
    label: "EV004244: FE 180"
  },
  {
    valueId: "EV004245",
    valueDesc: "Hemp",
    label: "EV004245: Hemp"
  },
  {
    valueId: "EV004246",
    valueDesc: "Aramid fibre",
    label: "EV004246: Aramid fibre"
  },
  {
    valueId: "EV004247",
    valueDesc: "Al/PE/PA",
    label: "EV004247: Al/PE/PA"
  },
  {
    valueId: "EV004248",
    valueDesc: "NiCr",
    label: "EV004248: NiCr"
  },
  {
    valueId: "EV004252",
    valueDesc: "Unfilled polyether-urethane resin",
    label: "EV004252: Unfilled polyether-urethane resin"
  },
  {
    valueId: "EV004253",
    valueDesc: "Filled polyether-urethane resin",
    label: "EV004253: Filled polyether-urethane resin"
  },
  {
    valueId: "EV004254",
    valueDesc: "Unfilled epoxy resin",
    label: "EV004254: Unfilled epoxy resin"
  },
  {
    valueId: "EV004255",
    valueDesc: "Filled epoxy resin",
    label: "EV004255: Filled epoxy resin"
  },
  {
    valueId: "EV004256",
    valueDesc: "Unfilled polyurethane resin",
    label: "EV004256: Unfilled polyurethane resin"
  },
  {
    valueId: "EV004257",
    valueDesc: "Filled polyurethane resin",
    label: "EV004257: Filled polyurethane resin"
  },
  {
    valueId: "EV004258",
    valueDesc: "0.6/1 kV",
    label: "EV004258: 0.6/1 kV"
  },
  {
    valueId: "EV004260",
    valueDesc: "3.6/6 kV",
    label: "EV004260: 3.6/6 kV"
  },
  {
    valueId: "EV004261",
    valueDesc: "6/10 kV",
    label: "EV004261: 6/10 kV"
  },
  {
    valueId: "EV004262",
    valueDesc: "12/20 kV",
    label: "EV004262: 12/20 kV"
  },
  {
    valueId: "EV004263",
    valueDesc: "18/30 kV",
    label: "EV004263: 18/30 kV"
  },
  {
    valueId: "EV004265",
    valueDesc: "Transition",
    label: "EV004265: Transition"
  },
  {
    valueId: "EV004266",
    valueDesc: "Cast resin",
    label: "EV004266: Cast resin"
  },
  {
    valueId: "EV004267",
    valueDesc: "Heat-shrink",
    label: "EV004267: Heat-shrink"
  },
  {
    valueId: "EV004268",
    valueDesc: "Cold-shrink",
    label: "EV004268: Cold-shrink"
  },
  {
    valueId: "EV004270",
    valueDesc: "Glass fibre texture",
    label: "EV004270: Glass fibre texture"
  },
  {
    valueId: "EV004272",
    valueDesc: "Carbon-paper",
    label: "EV004272: Carbon-paper"
  },
  {
    valueId: "EV004273",
    valueDesc: "Crepe paper",
    label: "EV004273: Crepe paper"
  },
  {
    valueId: "EV004274",
    valueDesc: "Foam material",
    label: "EV004274: Foam material"
  },
  {
    valueId: "EV004275",
    valueDesc: "Magnet",
    label: "EV004275: Magnet"
  },
  {
    valueId: "EV004276",
    valueDesc: "Texture",
    label: "EV004276: Texture"
  },
  {
    valueId: "EV004277",
    valueDesc: "Sector-shaped 90°",
    label: "EV004277: Sector-shaped 90°"
  },
  {
    valueId: "EV004278",
    valueDesc: "Sector-shaped 120°",
    label: "EV004278: Sector-shaped 120°"
  },
  {
    valueId: "EV004279",
    valueDesc: "Not isolated",
    label: "EV004279: Not isolated"
  },
  {
    valueId: "EV004283",
    valueDesc: "Round 4 mm",
    label: "EV004283: Round 4 mm"
  },
  {
    valueId: "EV004284",
    valueDesc: "Round 5 mm",
    label: "EV004284: Round 5 mm"
  },
  {
    valueId: "EV004285",
    valueDesc: "Flat 2.8x0.5 mm",
    label: "EV004285: Flat 2.8x0.5 mm"
  },
  {
    valueId: "EV004286",
    valueDesc: "Flat 2.8x0.8 mm",
    label: "EV004286: Flat 2.8x0.8 mm"
  },
  {
    valueId: "EV004287",
    valueDesc: "Flat 4.8x0.5 mm",
    label: "EV004287: Flat 4.8x0.5 mm"
  },
  {
    valueId: "EV004288",
    valueDesc: "Flat 4.8x0.8 mm",
    label: "EV004288: Flat 4.8x0.8 mm"
  },
  {
    valueId: "EV004289",
    valueDesc: "Flat 6.3x0.8 mm",
    label: "EV004289: Flat 6.3x0.8 mm"
  },
  {
    valueId: "EV004290",
    valueDesc: "Flat 7.7x0.8 mm",
    label: "EV004290: Flat 7.7x0.8 mm"
  },
  {
    valueId: "EV004291",
    valueDesc: "Flat 9.5x1.2 mm",
    label: "EV004291: Flat 9.5x1.2 mm"
  },
  {
    valueId: "EV004296",
    valueDesc: "Insulated cable connections",
    label: "EV004296: Insulated cable connections"
  },
  {
    valueId: "EV004314",
    valueDesc: "M10",
    label: "EV004314: M10"
  },
  {
    valueId: "EV004315",
    valueDesc: "M12",
    label: "EV004315: M12"
  },
  {
    valueId: "EV004351",
    valueDesc: "Up to 230/380 V",
    label: "EV004351: Up to 230/380 V"
  },
  {
    valueId: "EV004361",
    valueDesc: "Press fit",
    label: "EV004361: Press fit"
  },
  {
    valueId: "EV004362",
    valueDesc: "Screwed connection",
    label: "EV004362: Screwed connection"
  },
  {
    valueId: "EV004370",
    valueDesc: "Symbol 'light'",
    label: "EV004370: Symbol 'light'"
  },
  {
    valueId: "EV004371",
    valueDesc: "Symbol 'bell'",
    label: "EV004371: Symbol 'bell'"
  },
  {
    valueId: "EV004372",
    valueDesc: "Symbol 'key/door'",
    label: "EV004372: Symbol 'key/door'"
  },
  {
    valueId: "EV004377",
    valueDesc: "D-sub-miniature",
    label: "EV004377: D-sub-miniature"
  },
  {
    valueId: "EV004378",
    valueDesc: "UAE/IAE (ISDN)",
    label: "EV004378: UAE/IAE (ISDN)"
  },
  {
    valueId: "EV004382",
    valueDesc: "Safety power supply (green)",
    label: "EV004382: Safety power supply (green)"
  },
  {
    valueId: "EV004383",
    valueDesc: "Supplementary safety power supply (orange)",
    label: "EV004383: Supplementary safety power supply (orange)"
  },
  {
    valueId: "EV004384",
    valueDesc: "Additional safety power supply (red)",
    label: "EV004384: Additional safety power supply (red)"
  },
  {
    valueId: "EV004388",
    valueDesc: "Full cover plate",
    label: "EV004388: Full cover plate"
  },
  {
    valueId: "EV004389",
    valueDesc: "Plug-in end socket",
    label: "EV004389: Plug-in end socket"
  },
  {
    valueId: "EV004392",
    valueDesc: "Control of electrical users",
    label: "EV004392: Control of electrical users"
  },
  {
    valueId: "EV004398",
    valueDesc: "Chip card",
    label: "EV004398: Chip card"
  },
  {
    valueId: "EV004399",
    valueDesc: "Tronic",
    label: "EV004399: Tronic"
  },
  {
    valueId: "EV004401",
    valueDesc: "MOSFET",
    label: "EV004401: MOSFET"
  },
  {
    valueId: "EV004403",
    valueDesc: "Direct/transformer",
    label: "EV004403: Direct/transformer"
  },
  {
    valueId: "EV004407",
    valueDesc: "Hotel card system",
    label: "EV004407: Hotel card system"
  },
  {
    valueId: "EV004409",
    valueDesc: "(Alarm) clock",
    label: "EV004409: (Alarm) clock"
  },
  {
    valueId: "EV004410",
    valueDesc: "Thermometer",
    label: "EV004410: Thermometer"
  },
  {
    valueId: "EV004411",
    valueDesc: "Top control unit",
    label: "EV004411: Top control unit"
  },
  {
    valueId: "EV004412",
    valueDesc: "Ceiling mounted receiver",
    label: "EV004412: Ceiling mounted receiver"
  },
  {
    valueId: "EV004413",
    valueDesc: "Basic element with control top unit",
    label: "EV004413: Basic element with control top unit"
  },
  {
    valueId: "EV004414",
    valueDesc: "Y-piece",
    label: "EV004414: Y-piece"
  },
  {
    valueId: "EV004415",
    valueDesc: "Steel (mild steel rolled)",
    label: "EV004415: Steel (mild steel rolled)"
  },
  {
    valueId: "EV004416",
    valueDesc: "Steel (strip steel rolled)",
    label: "EV004416: Steel (strip steel rolled)"
  },
  {
    valueId: "EV004419",
    valueDesc: "Heavy (class 4, 1250 N)",
    label: "EV004419: Heavy (class 4, 1250 N)"
  },
  {
    valueId: "EV004420",
    valueDesc: "Extra heavy (class 5, 4000 N)",
    label: "EV004420: Extra heavy (class 5, 4000 N)"
  },
  {
    valueId: "EV004423",
    valueDesc: "Extra light (class 1, 125 N)",
    label: "EV004423: Extra light (class 1, 125 N)"
  },
  {
    valueId: "EV004424",
    valueDesc: "Light (class 2, 320 N)",
    label: "EV004424: Light (class 2, 320 N)"
  },
  {
    valueId: "EV004425",
    valueDesc: "Medium (class 3, 750 N)",
    label: "EV004425: Medium (class 3, 750 N)"
  },
  {
    valueId: "EV004427",
    valueDesc: "Polyamide 6",
    label: "EV004427: Polyamide 6"
  },
  {
    valueId: "EV004428",
    valueDesc: "Polyamide 12",
    label: "EV004428: Polyamide 12"
  },
  {
    valueId: "EV004429",
    valueDesc: "V0",
    label: "EV004429: V0"
  },
  {
    valueId: "EV004430",
    valueDesc: "V1",
    label: "EV004430: V1"
  },
  {
    valueId: "EV004431",
    valueDesc: "V2",
    label: "EV004431: V2"
  },
  {
    valueId: "EV004432",
    valueDesc: "HB",
    label: "EV004432: HB"
  },
  {
    valueId: "EV004433",
    valueDesc: "Not sheathed",
    label: "EV004433: Not sheathed"
  },
  {
    valueId: "EV004434",
    valueDesc: "Steel braided",
    label: "EV004434: Steel braided"
  },
  {
    valueId: "EV004435",
    valueDesc: "Stainless steel braiding",
    label: "EV004435: Stainless steel braiding"
  },
  {
    valueId: "EV004436",
    valueDesc: "Pliable, self recovering",
    label: "EV004436: Pliable, self recovering"
  },
  {
    valueId: "EV004440",
    valueDesc: "No thread",
    label: "EV004440: No thread"
  },
  {
    valueId: "EV004441",
    valueDesc: "External thread",
    label: "EV004441: External thread"
  },
  {
    valueId: "EV004442",
    valueDesc: "Internal thread",
    label: "EV004442: Internal thread"
  },
  {
    valueId: "EV004443",
    valueDesc: "Snap connection",
    label: "EV004443: Snap connection"
  },
  {
    valueId: "EV004457",
    valueDesc: "PG11",
    label: "EV004457: PG11"
  },
  {
    valueId: "EV004458",
    valueDesc: "PG16",
    label: "EV004458: PG16"
  },
  {
    valueId: "EV004459",
    valueDesc: "PG21",
    label: "EV004459: PG21"
  },
  {
    valueId: "EV004460",
    valueDesc: "PG29",
    label: "EV004460: PG29"
  },
  {
    valueId: "EV004461",
    valueDesc: "PG36",
    label: "EV004461: PG36"
  },
  {
    valueId: "EV004462",
    valueDesc: "PG42",
    label: "EV004462: PG42"
  },
  {
    valueId: "EV004463",
    valueDesc: "PG48",
    label: "EV004463: PG48"
  },
  {
    valueId: "EV004464",
    valueDesc: "PG7",
    label: "EV004464: PG7"
  },
  {
    valueId: "EV004465",
    valueDesc: "PG9",
    label: "EV004465: PG9"
  },
  {
    valueId: "EV004466",
    valueDesc: "PG13.5",
    label: "EV004466: PG13.5"
  },
  {
    valueId: "EV004498",
    valueDesc: "No sealing",
    label: "EV004498: No sealing"
  },
  {
    valueId: "EV004499",
    valueDesc: "Thermoplastic elastomer (TPE)",
    label: "EV004499: Thermoplastic elastomer (TPE)"
  },
  {
    valueId: "EV004500",
    valueDesc: "Without coil",
    label: "EV004500: Without coil"
  },
  {
    valueId: "EV004501",
    valueDesc: "PVC-sheathed spring steel",
    label: "EV004501: PVC-sheathed spring steel"
  },
  {
    valueId: "EV004502",
    valueDesc: "Plastic-sheathed spring steel",
    label: "EV004502: Plastic-sheathed spring steel"
  },
  {
    valueId: "EV004503",
    valueDesc: "Helix of rigid plastic",
    label: "EV004503: Helix of rigid plastic"
  },
  {
    valueId: "EV004504",
    valueDesc: "Very flexible",
    label: "EV004504: Very flexible"
  },
  {
    valueId: "EV004505",
    valueDesc: "Extremely flexible",
    label: "EV004505: Extremely flexible"
  },
  {
    valueId: "EV004510",
    valueDesc: "Stainless steel wire",
    label: "EV004510: Stainless steel wire"
  },
  {
    valueId: "EV004513",
    valueDesc: "Polyvinyl chloride (PVC), oil resistant",
    label: "EV004513: Polyvinyl chloride (PVC), oil resistant"
  },
  {
    valueId: "EV004514",
    valueDesc: "Polyvinyl chloride (PVC), flame retardant",
    label: "EV004514: Polyvinyl chloride (PVC), flame retardant"
  },
  {
    valueId: "EV004515",
    valueDesc: "Special fibre sealing",
    label: "EV004515: Special fibre sealing"
  },
  {
    valueId: "EV004516",
    valueDesc: "Not specified",
    label: "EV004516: Not specified"
  },
  {
    valueId: "EV004517",
    valueDesc: "With conducting characteristics",
    label: "EV004517: With conducting characteristics"
  },
  {
    valueId: "EV004518",
    valueDesc: "With electrical insulating characteristics",
    label: "EV004518: With electrical insulating characteristics"
  },
  {
    valueId: "EV004519",
    valueDesc: "With conducting- and insulating characteristics",
    label: "EV004519: With conducting- and insulating characteristics"
  },
  {
    valueId: "EV004522",
    valueDesc: "O-ring",
    label: "EV004522: O-ring"
  },
  {
    valueId: "EV004523",
    valueDesc: "Flat sealing ring",
    label: "EV004523: Flat sealing ring"
  },
  {
    valueId: "EV004524",
    valueDesc: "PET (Polyethylene Terephthalate)",
    label: "EV004524: PET (Polyethylene Terephthalate)"
  },
  {
    valueId: "EV004525",
    valueDesc: "Through bore hole",
    label: "EV004525: Through bore hole"
  },
  {
    valueId: "EV004526",
    valueDesc: "Copolymer, cross-linked (XPE)",
    label: "EV004526: Copolymer, cross-linked (XPE)"
  },
  {
    valueId: "EV004527",
    valueDesc: "Foil + braiding",
    label: "EV004527: Foil + braiding"
  },
  {
    valueId: "EV004528",
    valueDesc: "Double spatula",
    label: "EV004528: Double spatula"
  },
  {
    valueId: "EV004529",
    valueDesc: "Multifunctional shears",
    label: "EV004529: Multifunctional shears"
  },
  {
    valueId: "EV004530",
    valueDesc: "Smith hammer",
    label: "EV004530: Smith hammer"
  },
  {
    valueId: "EV004531",
    valueDesc: "Grooved joint",
    label: "EV004531: Grooved joint"
  },
  {
    valueId: "EV004532",
    valueDesc: "Power diagonal cutting nipper",
    label: "EV004532: Power diagonal cutting nipper"
  },
  {
    valueId: "EV004533",
    valueDesc: "Arbour clamping",
    label: "EV004533: Arbour clamping"
  },
  {
    valueId: "EV004534",
    valueDesc: "Notching",
    label: "EV004534: Notching"
  },
  {
    valueId: "EV004535",
    valueDesc: "Trapezoid compression",
    label: "EV004535: Trapezoid compression"
  },
  {
    valueId: "EV004536",
    valueDesc: "Square compression",
    label: "EV004536: Square compression"
  },
  {
    valueId: "EV004537",
    valueDesc: "Roll compression (shrinking)",
    label: "EV004537: Roll compression (shrinking)"
  },
  {
    valueId: "EV004538",
    valueDesc: "Four mandrel compression",
    label: "EV004538: Four mandrel compression"
  },
  {
    valueId: "EV004539",
    valueDesc: "Universal (exchangeable inserts)",
    label: "EV004539: Universal (exchangeable inserts)"
  },
  {
    valueId: "EV004540",
    valueDesc: "Pipe cable lug and connectors",
    label: "EV004540: Pipe cable lug and connectors"
  },
  {
    valueId: "EV004541",
    valueDesc: "Cable compression lugs and connectors",
    label: "EV004541: Cable compression lugs and connectors"
  },
  {
    valueId: "EV004547",
    valueDesc: "Hand hydraulic",
    label: "EV004547: Hand hydraulic"
  },
  {
    valueId: "EV004548",
    valueDesc: "Accumulator-hydraulic",
    label: "EV004548: Accumulator-hydraulic"
  },
  {
    valueId: "EV004549",
    valueDesc: "Hydraulic compression head",
    label: "EV004549: Hydraulic compression head"
  },
  {
    valueId: "EV004559",
    valueDesc: "Blade saw",
    label: "EV004559: Blade saw"
  },
  {
    valueId: "EV004560",
    valueDesc: "Hack saw",
    label: "EV004560: Hack saw"
  },
  {
    valueId: "EV004561",
    valueDesc: "SDS-max",
    label: "EV004561: SDS-max"
  },
  {
    valueId: "EV004562",
    valueDesc: "SDS-plus",
    label: "EV004562: SDS-plus"
  },
  {
    valueId: "EV004563",
    valueDesc: "Hexagon holder",
    label: "EV004563: Hexagon holder"
  },
  {
    valueId: "EV004564",
    valueDesc: "Cylindrical plug-in axle",
    label: "EV004564: Cylindrical plug-in axle"
  },
  {
    valueId: "EV004568",
    valueDesc: "Multi mode 50/125",
    label: "EV004568: Multi mode 50/125"
  },
  {
    valueId: "EV004569",
    valueDesc: "Multi mode 62.5/125",
    label: "EV004569: Multi mode 62.5/125"
  },
  {
    valueId: "EV004570",
    valueDesc: "NiAl",
    label: "EV004570: NiAl"
  },
  {
    valueId: "EV004575",
    valueDesc: "Graphic",
    label: "EV004575: Graphic"
  },
  {
    valueId: "EV004579",
    valueDesc: "Counter",
    label: "EV004579: Counter"
  },
  {
    valueId: "EV004588",
    valueDesc: "Pt100",
    label: "EV004588: Pt100"
  },
  {
    valueId: "EV004589",
    valueDesc: "Folding ladder",
    label: "EV004589: Folding ladder"
  },
  {
    valueId: "EV004590",
    valueDesc: "V-shape",
    label: "EV004590: V-shape"
  },
  {
    valueId: "EV004591",
    valueDesc: "1/2 inch",
    label: "EV004591: 1/2 inch"
  },
  {
    valueId: "EV004593",
    valueDesc: "1 1/2 inch",
    label: "EV004593: 1 1/2 inch"
  },
  {
    valueId: "EV004594",
    valueDesc: "2 inch",
    label: "EV004594: 2 inch"
  },
  {
    valueId: "EV004595",
    valueDesc: "3 inch",
    label: "EV004595: 3 inch"
  },
  {
    valueId: "EV004596",
    valueDesc: "Hydraulic foot pump",
    label: "EV004596: Hydraulic foot pump"
  },
  {
    valueId: "EV004597",
    valueDesc: "Pneumatic pressure transducer",
    label: "EV004597: Pneumatic pressure transducer"
  },
  {
    valueId: "EV004599",
    valueDesc: "Hydraulic universal head",
    label: "EV004599: Hydraulic universal head"
  },
  {
    valueId: "EV004607",
    valueDesc: "With current clamp meter/clamp adapter",
    label: "EV004607: With current clamp meter/clamp adapter"
  },
  {
    valueId: "EV004608",
    valueDesc: "Direct current measuring",
    label: "EV004608: Direct current measuring"
  },
  {
    valueId: "EV004610",
    valueDesc: "2 mm",
    label: "EV004610: 2 mm"
  },
  {
    valueId: "EV004611",
    valueDesc: "2 mm safety plug",
    label: "EV004611: 2 mm safety plug"
  },
  {
    valueId: "EV004612",
    valueDesc: "4 mm safety plug",
    label: "EV004612: 4 mm safety plug"
  },
  {
    valueId: "EV004613",
    valueDesc: "6 mm safety plug",
    label: "EV004613: 6 mm safety plug"
  },
  {
    valueId: "EV004618",
    valueDesc: "Max. 50 V AC/120 V DC",
    label: "EV004618: Max. 50 V AC/120 V DC"
  },
  {
    valueId: "EV004620",
    valueDesc: "Double mounting bracket",
    label: "EV004620: Double mounting bracket"
  },
  {
    valueId: "EV004621",
    valueDesc: "Under-roof mast",
    label: "EV004621: Under-roof mast"
  },
  {
    valueId: "EV004622",
    valueDesc: "SAT-analogue-TV (PAL)",
    label: "EV004622: SAT-analogue-TV (PAL)"
  },
  {
    valueId: "EV004623",
    valueDesc: "SAT-digital-TV (QPSK, DVB-S)",
    label: "EV004623: SAT-digital-TV (QPSK, DVB-S)"
  },
  {
    valueId: "EV004624",
    valueDesc: "Terrestrial-analogue TV (PAL)",
    label: "EV004624: Terrestrial-analogue TV (PAL)"
  },
  {
    valueId: "EV004625",
    valueDesc: "Audio/Video (AV)",
    label: "EV004625: Audio/Video (AV)"
  },
  {
    valueId: "EV004643",
    valueDesc: "Force and pressure test equipment",
    label: "EV004643: Force and pressure test equipment"
  },
  {
    valueId: "EV004644",
    valueDesc: "Force transducer",
    label: "EV004644: Force transducer"
  },
  {
    valueId: "EV004645",
    valueDesc: "Universal measuring device",
    label: "EV004645: Universal measuring device"
  },
  {
    valueId: "EV004646",
    valueDesc: "Two-hand safety control",
    label: "EV004646: Two-hand safety control"
  },
  {
    valueId: "EV004647",
    valueDesc: "Foot switch",
    label: "EV004647: Foot switch"
  },
  {
    valueId: "EV004648",
    valueDesc: "Hydraulic hose",
    label: "EV004648: Hydraulic hose"
  },
  {
    valueId: "EV004649",
    valueDesc: "Coupling sleeve",
    label: "EV004649: Coupling sleeve"
  },
  {
    valueId: "EV004650",
    valueDesc: "Double nipple",
    label: "EV004650: Double nipple"
  },
  {
    valueId: "EV004651",
    valueDesc: "Video-camera microphone",
    label: "EV004651: Video-camera microphone"
  },
  {
    valueId: "EV004653",
    valueDesc: "Under cabinet kitchen radio",
    label: "EV004653: Under cabinet kitchen radio"
  },
  {
    valueId: "EV004655",
    valueDesc: "Plasma",
    label: "EV004655: Plasma"
  },
  {
    valueId: "EV004656",
    valueDesc: "Stereo",
    label: "EV004656: Stereo"
  },
  {
    valueId: "EV004661",
    valueDesc: "Conventional",
    label: "EV004661: Conventional"
  },
  {
    valueId: "EV004663",
    valueDesc: "Multimedia card",
    label: "EV004663: Multimedia card"
  },
  {
    valueId: "EV004664",
    valueDesc: "SD card",
    label: "EV004664: SD card"
  },
  {
    valueId: "EV004665",
    valueDesc: "Memory stick",
    label: "EV004665: Memory stick"
  },
  {
    valueId: "EV004666",
    valueDesc: "Flash card",
    label: "EV004666: Flash card"
  },
  {
    valueId: "EV004667",
    valueDesc: "MICROMV",
    label: "EV004667: MICROMV"
  },
  {
    valueId: "EV004668",
    valueDesc: "MiniDV",
    label: "EV004668: MiniDV"
  },
  {
    valueId: "EV004669",
    valueDesc: "Digital8",
    label: "EV004669: Digital8"
  },
  {
    valueId: "EV004672",
    valueDesc: "With cable",
    label: "EV004672: With cable"
  },
  {
    valueId: "EV004674",
    valueDesc: "Mixing console",
    label: "EV004674: Mixing console"
  },
  {
    valueId: "EV004675",
    valueDesc: "Home cinema system",
    label: "EV004675: Home cinema system"
  },
  {
    valueId: "EV004680",
    valueDesc: "CD",
    label: "EV004680: CD"
  },
  {
    valueId: "EV004681",
    valueDesc: "DVD",
    label: "EV004681: DVD"
  },
  {
    valueId: "EV004682",
    valueDesc: "Cassette",
    label: "EV004682: Cassette"
  },
  {
    valueId: "EV004683",
    valueDesc: "Hi8",
    label: "EV004683: Hi8"
  },
  {
    valueId: "EV004689",
    valueDesc: "Monitor",
    label: "EV004689: Monitor"
  },
  {
    valueId: "EV004694",
    valueDesc: "Digital videocassette (DV)",
    label: "EV004694: Digital videocassette (DV)"
  },
  {
    valueId: "EV004698",
    valueDesc: "Suitable for built-under mounting",
    label: "EV004698: Suitable for built-under mounting"
  },
  {
    valueId: "EV004699",
    valueDesc: "Fully integrable",
    label: "EV004699: Fully integrable"
  },
  {
    valueId: "EV004701",
    valueDesc: "G",
    label: "EV004701: G"
  },
  {
    valueId: "EV004702",
    valueDesc: "Without time preselection",
    label: "EV004702: Without time preselection"
  },
  {
    valueId: "EV004703",
    valueDesc: "One preselected time",
    label: "EV004703: One preselected time"
  },
  {
    valueId: "EV004704",
    valueDesc: "Exhaust air",
    label: "EV004704: Exhaust air"
  },
  {
    valueId: "EV004705",
    valueDesc: "Condensation",
    label: "EV004705: Condensation"
  },
  {
    valueId: "EV004706",
    valueDesc: "Microwave solo",
    label: "EV004706: Microwave solo"
  },
  {
    valueId: "EV004707",
    valueDesc: "Microwave + grill",
    label: "EV004707: Microwave + grill"
  },
  {
    valueId: "EV004708",
    valueDesc: "Microwave combination",
    label: "EV004708: Microwave combination"
  },
  {
    valueId: "EV004709",
    valueDesc: "Suitable for built-in",
    label: "EV004709: Suitable for built-in"
  },
  {
    valueId: "EV004710",
    valueDesc: "Built-in (integrated)",
    label: "EV004710: Built-in (integrated)"
  },
  {
    valueId: "EV004711",
    valueDesc: "Yes",
    label: "EV004711: Yes"
  },
  {
    valueId: "EV004712",
    valueDesc: "Switch-off function",
    label: "EV004712: Switch-off function"
  },
  {
    valueId: "EV004714",
    valueDesc: "1-door",
    label: "EV004714: 1-door"
  },
  {
    valueId: "EV004715",
    valueDesc: "2-doors",
    label: "EV004715: 2-doors"
  },
  {
    valueId: "EV004716",
    valueDesc: "3-doors",
    label: "EV004716: 3-doors"
  },
  {
    valueId: "EV004717",
    valueDesc: "4-doors",
    label: "EV004717: 4-doors"
  },
  {
    valueId: "EV004718",
    valueDesc: "Side-by-side",
    label: "EV004718: Side-by-side"
  },
  {
    valueId: "EV004722",
    valueDesc: "Free-standing",
    label: "EV004722: Free-standing"
  },
  {
    valueId: "EV004725",
    valueDesc: "Possible with accessories",
    label: "EV004725: Possible with accessories"
  },
  {
    valueId: "EV004726",
    valueDesc: "Possible and included",
    label: "EV004726: Possible and included"
  },
  {
    valueId: "EV004727",
    valueDesc: "T",
    label: "EV004727: T"
  },
  {
    valueId: "EV004731",
    valueDesc: "Left",
    label: "EV004731: Left"
  },
  {
    valueId: "EV004732",
    valueDesc: "Right",
    label: "EV004732: Right"
  },
  {
    valueId: "EV004733",
    valueDesc: "Sliding connection",
    label: "EV004733: Sliding connection"
  },
  {
    valueId: "EV004734",
    valueDesc: "Fixed mounted with hinge hole",
    label: "EV004734: Fixed mounted with hinge hole"
  },
  {
    valueId: "EV004735",
    valueDesc: "12 V",
    label: "EV004735: 12 V"
  },
  {
    valueId: "EV004736",
    valueDesc: "24 V",
    label: "EV004736: 24 V"
  },
  {
    valueId: "EV004737",
    valueDesc: "Gas",
    label: "EV004737: Gas"
  },
  {
    valueId: "EV004738",
    valueDesc: "Visible",
    label: "EV004738: Visible"
  },
  {
    valueId: "EV004739",
    valueDesc: "Audible",
    label: "EV004739: Audible"
  },
  {
    valueId: "EV004740",
    valueDesc: "Both visible and audible",
    label: "EV004740: Both visible and audible"
  },
  {
    valueId: "EV004741",
    valueDesc: "No signal",
    label: "EV004741: No signal"
  },
  {
    valueId: "EV004742",
    valueDesc: "Fridge",
    label: "EV004742: Fridge"
  },
  {
    valueId: "EV004743",
    valueDesc: "Freezer",
    label: "EV004743: Freezer"
  },
  {
    valueId: "EV004745",
    valueDesc: "Fully extendible",
    label: "EV004745: Fully extendible"
  },
  {
    valueId: "EV004746",
    valueDesc: "Cooking cart",
    label: "EV004746: Cooking cart"
  },
  {
    valueId: "EV004747",
    valueDesc: "Telescopic shelf",
    label: "EV004747: Telescopic shelf"
  },
  {
    valueId: "EV004748",
    valueDesc: "Swing door",
    label: "EV004748: Swing door"
  },
  {
    valueId: "EV004749",
    valueDesc: "Glass front",
    label: "EV004749: Glass front"
  },
  {
    valueId: "EV004750",
    valueDesc: "7-step",
    label: "EV004750: 7-step"
  },
  {
    valueId: "EV004751",
    valueDesc: "12-step",
    label: "EV004751: 12-step"
  },
  {
    valueId: "EV004753",
    valueDesc: "Free standing grill",
    label: "EV004753: Free standing grill"
  },
  {
    valueId: "EV004754",
    valueDesc: "Grill table",
    label: "EV004754: Grill table"
  },
  {
    valueId: "EV004755",
    valueDesc: "Lithium battery",
    label: "EV004755: Lithium battery"
  },
  {
    valueId: "EV004757",
    valueDesc: "Non-stick",
    label: "EV004757: Non-stick"
  },
  {
    valueId: "EV004758",
    valueDesc: "Hygienic filter",
    label: "EV004758: Hygienic filter"
  },
  {
    valueId: "EV004759",
    valueDesc: "Turbo",
    label: "EV004759: Turbo"
  },
  {
    valueId: "EV004762",
    valueDesc: "Concealed",
    label: "EV004762: Concealed"
  },
  {
    valueId: "EV004763",
    valueDesc: "Storage heater",
    label: "EV004763: Storage heater"
  },
  {
    valueId: "EV004764",
    valueDesc: "Peripheral ventilation heater",
    label: "EV004764: Peripheral ventilation heater"
  },
  {
    valueId: "EV004765",
    valueDesc: "Ventilation-storage heater",
    label: "EV004765: Ventilation-storage heater"
  },
  {
    valueId: "EV004768",
    valueDesc: "Wireless remote control",
    label: "EV004768: Wireless remote control"
  },
  {
    valueId: "EV004769",
    valueDesc: "Wired remote control",
    label: "EV004769: Wired remote control"
  },
  {
    valueId: "EV004774",
    valueDesc: "2 steps manual",
    label: "EV004774: 2 steps manual"
  },
  {
    valueId: "EV004775",
    valueDesc: "2 steps automatic",
    label: "EV004775: 2 steps automatic"
  },
  {
    valueId: "EV004776",
    valueDesc: "Pressure switch-off",
    label: "EV004776: Pressure switch-off"
  },
  {
    valueId: "EV004777",
    valueDesc: "2 handles",
    label: "EV004777: 2 handles"
  },
  {
    valueId: "EV004778",
    valueDesc: "1 lever",
    label: "EV004778: 1 lever"
  },
  {
    valueId: "EV004779",
    valueDesc: "Integrated tap",
    label: "EV004779: Integrated tap"
  },
  {
    valueId: "EV004780",
    valueDesc: "PU foam",
    label: "EV004780: PU foam"
  },
  {
    valueId: "EV004782",
    valueDesc: "Soft foam",
    label: "EV004782: Soft foam"
  },
  {
    valueId: "EV004784",
    valueDesc: "Hot water heat exchanger",
    label: "EV004784: Hot water heat exchanger"
  },
  {
    valueId: "EV004790",
    valueDesc: "Water tap",
    label: "EV004790: Water tap"
  },
  {
    valueId: "EV004795",
    valueDesc: "Hot water tank",
    label: "EV004795: Hot water tank"
  },
  {
    valueId: "EV004796",
    valueDesc: "Bidet",
    label: "EV004796: Bidet"
  },
  {
    valueId: "EV004801",
    valueDesc: "Surface mounted battery",
    label: "EV004801: Surface mounted battery"
  },
  {
    valueId: "EV004804",
    valueDesc: "Hot water-free standing storage tank",
    label: "EV004804: Hot water-free standing storage tank"
  },
  {
    valueId: "EV004805",
    valueDesc: "Vertical heating boiler",
    label: "EV004805: Vertical heating boiler"
  },
  {
    valueId: "EV004806",
    valueDesc: "Hanging boiler",
    label: "EV004806: Hanging boiler"
  },
  {
    valueId: "EV004807",
    valueDesc: "Combi accumulator",
    label: "EV004807: Combi accumulator"
  },
  {
    valueId: "EV004808",
    valueDesc: "Universal storage",
    label: "EV004808: Universal storage"
  },
  {
    valueId: "EV004810",
    valueDesc: "Hard foam",
    label: "EV004810: Hard foam"
  },
  {
    valueId: "EV004811",
    valueDesc: "Moulded parts",
    label: "EV004811: Moulded parts"
  },
  {
    valueId: "EV004812",
    valueDesc: "Electro central heating",
    label: "EV004812: Electro central heating"
  },
  {
    valueId: "EV004814",
    valueDesc: "Spread controller",
    label: "EV004814: Spread controller"
  },
  {
    valueId: "EV004815",
    valueDesc: "Universal controller",
    label: "EV004815: Universal controller"
  },
  {
    valueId: "EV004816",
    valueDesc: "Central controller",
    label: "EV004816: Central controller"
  },
  {
    valueId: "EV004825",
    valueDesc: "Heating foil",
    label: "EV004825: Heating foil"
  },
  {
    valueId: "EV004827",
    valueDesc: "Floor storage heater",
    label: "EV004827: Floor storage heater"
  },
  {
    valueId: "EV004828",
    valueDesc: "Sewed",
    label: "EV004828: Sewed"
  },
  {
    valueId: "EV004837",
    valueDesc: "Cross-counter-flow unit",
    label: "EV004837: Cross-counter-flow unit"
  },
  {
    valueId: "EV004838",
    valueDesc: "Outside air",
    label: "EV004838: Outside air"
  },
  {
    valueId: "EV004846",
    valueDesc: "Cradle radiator",
    label: "EV004846: Cradle radiator"
  },
  {
    valueId: "EV004847",
    valueDesc: "PCB (Printed Circuit Board)",
    label: "EV004847: PCB (Printed Circuit Board)"
  },
  {
    valueId: "EV004848",
    valueDesc: "Heating cable",
    label: "EV004848: Heating cable"
  },
  {
    valueId: "EV004849",
    valueDesc: "Horizontal/vertical",
    label: "EV004849: Horizontal/vertical"
  },
  {
    valueId: "EV004850",
    valueDesc: "Charging time >8h",
    label: "EV004850: Charging time >8h"
  },
  {
    valueId: "EV004851",
    valueDesc: "Charging time <8h",
    label: "EV004851: Charging time <8h"
  },
  {
    valueId: "EV004852",
    valueDesc: "Heating circuit",
    label: "EV004852: Heating circuit"
  },
  {
    valueId: "EV004854",
    valueDesc: "Tube collector",
    label: "EV004854: Tube collector"
  },
  {
    valueId: "EV004855",
    valueDesc: "Flat collector",
    label: "EV004855: Flat collector"
  },
  {
    valueId: "EV004859",
    valueDesc: "Integrated into roof plane",
    label: "EV004859: Integrated into roof plane"
  },
  {
    valueId: "EV004863",
    valueDesc: "Approach electronics",
    label: "EV004863: Approach electronics"
  },
  {
    valueId: "EV004870",
    valueDesc: "Water",
    label: "EV004870: Water"
  },
  {
    valueId: "EV004877",
    valueDesc: "Split",
    label: "EV004877: Split"
  },
  {
    valueId: "EV004879",
    valueDesc: "Hot gas bypass",
    label: "EV004879: Hot gas bypass"
  },
  {
    valueId: "EV004883",
    valueDesc: "Remote controller",
    label: "EV004883: Remote controller"
  },
  {
    valueId: "EV004884",
    valueDesc: "Temperature sensor",
    label: "EV004884: Temperature sensor"
  },
  {
    valueId: "EV004889",
    valueDesc: "Climate installation",
    label: "EV004889: Climate installation"
  },
  {
    valueId: "EV004891",
    valueDesc: "Solar systems",
    label: "EV004891: Solar systems"
  },
  {
    valueId: "EV004892",
    valueDesc: "Heat pump systems",
    label: "EV004892: Heat pump systems"
  },
  {
    valueId: "EV004894",
    valueDesc: "Ventilation systems",
    label: "EV004894: Ventilation systems"
  },
  {
    valueId: "EV004896",
    valueDesc: "Buffer storage tank",
    label: "EV004896: Buffer storage tank"
  },
  {
    valueId: "EV004897",
    valueDesc: "Brine distributor",
    label: "EV004897: Brine distributor"
  },
  {
    valueId: "EV004898",
    valueDesc: "Brine mounting kit",
    label: "EV004898: Brine mounting kit"
  },
  {
    valueId: "EV004899",
    valueDesc: "Installation kit",
    label: "EV004899: Installation kit"
  },
  {
    valueId: "EV004900",
    valueDesc: "Hot water preparation",
    label: "EV004900: Hot water preparation"
  },
  {
    valueId: "EV004901",
    valueDesc: "Partial load range",
    label: "EV004901: Partial load range"
  },
  {
    valueId: "EV004902",
    valueDesc: "Electrical reheating",
    label: "EV004902: Electrical reheating"
  },
  {
    valueId: "EV004903",
    valueDesc: "Hydraulic instantaneous water heater",
    label: "EV004903: Hydraulic instantaneous water heater"
  },
  {
    valueId: "EV004904",
    valueDesc: "Electronic instantaneous water heater",
    label: "EV004904: Electronic instantaneous water heater"
  },
  {
    valueId: "EV004905",
    valueDesc: "Connection kit",
    label: "EV004905: Connection kit"
  },
  {
    valueId: "EV004906",
    valueDesc: "Mounting kit hot water preparation",
    label: "EV004906: Mounting kit hot water preparation"
  },
  {
    valueId: "EV004907",
    valueDesc: "Mounting kit electro reheating",
    label: "EV004907: Mounting kit electro reheating"
  },
  {
    valueId: "EV004909",
    valueDesc: "Air conditioner",
    label: "EV004909: Air conditioner"
  },
  {
    valueId: "EV004910",
    valueDesc: "Air heater system",
    label: "EV004910: Air heater system"
  },
  {
    valueId: "EV004917",
    valueDesc: "Repair aid",
    label: "EV004917: Repair aid"
  },
  {
    valueId: "EV004919",
    valueDesc: "Flat-/tube collector",
    label: "EV004919: Flat-/tube collector"
  },
  {
    valueId: "EV004920",
    valueDesc: "Mineral wool",
    label: "EV004920: Mineral wool"
  },
  {
    valueId: "EV004922",
    valueDesc: "2-way valve",
    label: "EV004922: 2-way valve"
  },
  {
    valueId: "EV004924",
    valueDesc: "Sound-muffling cover",
    label: "EV004924: Sound-muffling cover"
  },
  {
    valueId: "EV004925",
    valueDesc: "Fundament sound-absorption",
    label: "EV004925: Fundament sound-absorption"
  },
  {
    valueId: "EV004926",
    valueDesc: "Wall-sound insulation",
    label: "EV004926: Wall-sound insulation"
  },
  {
    valueId: "EV004932",
    valueDesc: "Flange mounting set",
    label: "EV004932: Flange mounting set"
  },
  {
    valueId: "EV004935",
    valueDesc: "FRA",
    label: "EV004935: FRA"
  },
  {
    valueId: "EV004936",
    valueDesc: "Softone",
    label: "EV004936: Softone"
  },
  {
    valueId: "EV004937",
    valueDesc: "Double-envelope",
    label: "EV004937: Double-envelope"
  },
  {
    valueId: "EV004938",
    valueDesc: "Tube, double-ended",
    label: "EV004938: Tube, double-ended"
  },
  {
    valueId: "EV004939",
    valueDesc: "s15",
    label: "EV004939: s15"
  },
  {
    valueId: "EV004940",
    valueDesc: "s30",
    label: "EV004940: s30"
  },
  {
    valueId: "EV004941",
    valueDesc: "S45",
    label: "EV004941: S45"
  },
  {
    valueId: "EV004942",
    valueDesc: "S90",
    label: "EV004942: S90"
  },
  {
    valueId: "EV004943",
    valueDesc: "S105",
    label: "EV004943: S105"
  },
  {
    valueId: "EV004944",
    valueDesc: "S120",
    label: "EV004944: S120"
  },
  {
    valueId: "EV004945",
    valueDesc: "S135",
    label: "EV004945: S135"
  },
  {
    valueId: "EV004946",
    valueDesc: "h15",
    label: "EV004946: h15"
  },
  {
    valueId: "EV004947",
    valueDesc: "h30",
    label: "EV004947: h30"
  },
  {
    valueId: "EV004948",
    valueDesc: "h45",
    label: "EV004948: h45"
  },
  {
    valueId: "EV004949",
    valueDesc: "h120",
    label: "EV004949: h120"
  },
  {
    valueId: "EV004950",
    valueDesc: "h135",
    label: "EV004950: h135"
  },
  {
    valueId: "EV004951",
    valueDesc: "se90",
    label: "EV004951: se90"
  },
  {
    valueId: "EV004952",
    valueDesc: "se30",
    label: "EV004952: se30"
  },
  {
    valueId: "EV004953",
    valueDesc: "P-90+45",
    label: "EV004953: P-90+45"
  },
  {
    valueId: "EV004954",
    valueDesc: "P4",
    label: "EV004954: P4"
  },
  {
    valueId: "EV004955",
    valueDesc: "P20",
    label: "EV004955: P20"
  },
  {
    valueId: "EV004956",
    valueDesc: "Random",
    label: "EV004956: Random"
  },
  {
    valueId: "EV004957",
    valueDesc: "Pear-shape",
    label: "EV004957: Pear-shape"
  },
  {
    valueId: "EV004959",
    valueDesc: "Insects",
    label: "EV004959: Insects"
  },
  {
    valueId: "EV004960",
    valueDesc: "Croco",
    label: "EV004960: Croco"
  },
  {
    valueId: "EV004961",
    valueDesc: "Wind gust",
    label: "EV004961: Wind gust"
  },
  {
    valueId: "EV004962",
    valueDesc: "Top end",
    label: "EV004962: Top end"
  },
  {
    valueId: "EV004963",
    valueDesc: "S5.7",
    label: "EV004963: S5.7"
  },
  {
    valueId: "EV004967",
    valueDesc: "General",
    label: "EV004967: General"
  },
  {
    valueId: "EV004969",
    valueDesc: "W2.1x9.5d",
    label: "EV004969: W2.1x9.5d"
  },
  {
    valueId: "EV004970",
    valueDesc: "Lens-shape",
    label: "EV004970: Lens-shape"
  },
  {
    valueId: "EV004971",
    valueDesc: "Sphere-shape",
    label: "EV004971: Sphere-shape"
  },
  {
    valueId: "EV004972",
    valueDesc: "Olive-shape",
    label: "EV004972: Olive-shape"
  },
  {
    valueId: "EV004974",
    valueDesc: "G3.9",
    label: "EV004974: G3.9"
  },
  {
    valueId: "EV004975",
    valueDesc: "GX7.9",
    label: "EV004975: GX7.9"
  },
  {
    valueId: "EV004976",
    valueDesc: "G9.5",
    label: "EV004976: G9.5"
  },
  {
    valueId: "EV004977",
    valueDesc: "GZ9.5",
    label: "EV004977: GZ9.5"
  },
  {
    valueId: "EV004978",
    valueDesc: "GY9.6",
    label: "EV004978: GY9.6"
  },
  {
    valueId: "EV004979",
    valueDesc: "P28s",
    label: "EV004979: P28s"
  },
  {
    valueId: "EV004981",
    valueDesc: "P15",
    label: "EV004981: P15"
  },
  {
    valueId: "EV004982",
    valueDesc: "S130",
    label: "EV004982: S130"
  },
  {
    valueId: "EV004983",
    valueDesc: "BDM",
    label: "EV004983: BDM"
  },
  {
    valueId: "EV004984",
    valueDesc: "BRL/BCD",
    label: "EV004984: BRL/BCD"
  },
  {
    valueId: "EV004985",
    valueDesc: "BTL",
    label: "EV004985: BTL"
  },
  {
    valueId: "EV004986",
    valueDesc: "BTP",
    label: "EV004986: BTP"
  },
  {
    valueId: "EV004987",
    valueDesc: "BTR",
    label: "EV004987: BTR"
  },
  {
    valueId: "EV004988",
    valueDesc: "BVM",
    label: "EV004988: BVM"
  },
  {
    valueId: "EV004989",
    valueDesc: "CYX",
    label: "EV004989: CYX"
  },
  {
    valueId: "EV004990",
    valueDesc: "DDS",
    label: "EV004990: DDS"
  },
  {
    valueId: "EV004991",
    valueDesc: "DED",
    label: "EV004991: DED"
  },
  {
    valueId: "EV004992",
    valueDesc: "DNF",
    label: "EV004992: DNF"
  },
  {
    valueId: "EV004993",
    valueDesc: "DWZ",
    label: "EV004993: DWZ"
  },
  {
    valueId: "EV004994",
    valueDesc: "DXX",
    label: "EV004994: DXX"
  },
  {
    valueId: "EV004995",
    valueDesc: "DZA",
    label: "EV004995: DZA"
  },
  {
    valueId: "EV004996",
    valueDesc: "DZJ",
    label: "EV004996: DZJ"
  },
  {
    valueId: "EV004997",
    valueDesc: "EFM",
    label: "EV004997: EFM"
  },
  {
    valueId: "EV004998",
    valueDesc: "EFP",
    label: "EV004998: EFP"
  },
  {
    valueId: "EV004999",
    valueDesc: "EGR",
    label: "EV004999: EGR"
  },
  {
    valueId: "EV005000",
    valueDesc: "EGY",
    label: "EV005000: EGY"
  },
  {
    valueId: "EV005001",
    valueDesc: "EHA",
    label: "EV005001: EHA"
  },
  {
    valueId: "EV005002",
    valueDesc: "EHJ",
    label: "EV005002: EHJ"
  },
  {
    valueId: "EV005003",
    valueDesc: "EHE/ETA",
    label: "EV005003: EHE/ETA"
  },
  {
    valueId: "EV005004",
    valueDesc: "EJA",
    label: "EV005004: EJA"
  },
  {
    valueId: "EV005005",
    valueDesc: "EJL",
    label: "EV005005: EJL"
  },
  {
    valueId: "EV005006",
    valueDesc: "EJM",
    label: "EV005006: EJM"
  },
  {
    valueId: "EV005007",
    valueDesc: "EKE",
    label: "EV005007: EKE"
  },
  {
    valueId: "EV005008",
    valueDesc: "EKM",
    label: "EV005008: EKM"
  },
  {
    valueId: "EV005009",
    valueDesc: "ELC",
    label: "EV005009: ELC"
  },
  {
    valueId: "EV005010",
    valueDesc: "ELC/FA",
    label: "EV005010: ELC/FA"
  },
  {
    valueId: "EV005011",
    valueDesc: "ELD/EJN",
    label: "EV005011: ELD/EJN"
  },
  {
    valueId: "EV005012",
    valueDesc: "ELH",
    label: "EV005012: ELH"
  },
  {
    valueId: "EV005013",
    valueDesc: "EME",
    label: "EV005013: EME"
  },
  {
    valueId: "EV005014",
    valueDesc: "EMG",
    label: "EV005014: EMG"
  },
  {
    valueId: "EV005015",
    valueDesc: "ENH",
    label: "EV005015: ENH"
  },
  {
    valueId: "EV005016",
    valueDesc: "ENX",
    label: "EV005016: ENX"
  },
  {
    valueId: "EV005017",
    valueDesc: "EPZ/DJT",
    label: "EV005017: EPZ/DJT"
  },
  {
    valueId: "EV005018",
    valueDesc: "ERV",
    label: "EV005018: ERV"
  },
  {
    valueId: "EV005019",
    valueDesc: "ESA/FDH",
    label: "EV005019: ESA/FDH"
  },
  {
    valueId: "EV005020",
    valueDesc: "EVB/BRJ",
    label: "EV005020: EVB/BRJ"
  },
  {
    valueId: "EV005021",
    valueDesc: "EVC/FGX",
    label: "EV005021: EVC/FGX"
  },
  {
    valueId: "EV005022",
    valueDesc: "EVD",
    label: "EV005022: EVD"
  },
  {
    valueId: "EV005023",
    valueDesc: "EVM",
    label: "EV005023: EVM"
  },
  {
    valueId: "EV005024",
    valueDesc: "EVX/GX",
    label: "EV005024: EVX/GX"
  },
  {
    valueId: "EV005025",
    valueDesc: "EWF",
    label: "EV005025: EWF"
  },
  {
    valueId: "EV005026",
    valueDesc: "EXJ",
    label: "EV005026: EXJ"
  },
  {
    valueId: "EV005027",
    valueDesc: "EXR",
    label: "EV005027: EXR"
  },
  {
    valueId: "EV005028",
    valueDesc: "EXW",
    label: "EV005028: EXW"
  },
  {
    valueId: "EV005029",
    valueDesc: "EYB",
    label: "EV005029: EYB"
  },
  {
    valueId: "EV005030",
    valueDesc: "FAD",
    label: "EV005030: FAD"
  },
  {
    valueId: "EV005031",
    valueDesc: "FCM",
    label: "EV005031: FCM"
  },
  {
    valueId: "EV005032",
    valueDesc: "FCR",
    label: "EV005032: FCR"
  },
  {
    valueId: "EV005033",
    valueDesc: "FDS/DZE",
    label: "EV005033: FDS/DZE"
  },
  {
    valueId: "EV005034",
    valueDesc: "FDT",
    label: "EV005034: FDT"
  },
  {
    valueId: "EV005035",
    valueDesc: "FEL",
    label: "EV005035: FEL"
  },
  {
    valueId: "EV005036",
    valueDesc: "FGP/EMG",
    label: "EV005036: FGP/EMG"
  },
  {
    valueId: "EV005037",
    valueDesc: "FHM",
    label: "EV005037: FHM"
  },
  {
    valueId: "EV005038",
    valueDesc: "FHS",
    label: "EV005038: FHS"
  },
  {
    valueId: "EV005039",
    valueDesc: "FHX",
    label: "EV005039: FHX"
  },
  {
    valueId: "EV005040",
    valueDesc: "FJX",
    label: "EV005040: FJX"
  },
  {
    valueId: "EV005041",
    valueDesc: "FKB",
    label: "EV005041: FKB"
  },
  {
    valueId: "EV005042",
    valueDesc: "FKK/FKP",
    label: "EV005042: FKK/FKP"
  },
  {
    valueId: "EV005043",
    valueDesc: "FKY",
    label: "EV005043: FKY"
  },
  {
    valueId: "EV005044",
    valueDesc: "FKZ",
    label: "EV005044: FKZ"
  },
  {
    valueId: "EV005045",
    valueDesc: "FLK",
    label: "EV005045: FLK"
  },
  {
    valueId: "EV005046",
    valueDesc: "FLKW",
    label: "EV005046: FLKW"
  },
  {
    valueId: "EV005047",
    valueDesc: "FLT",
    label: "EV005047: FLT"
  },
  {
    valueId: "EV005048",
    valueDesc: "FNT",
    label: "EV005048: FNT"
  },
  {
    valueId: "EV005049",
    valueDesc: "FTL",
    label: "EV005049: FTL"
  },
  {
    valueId: "EV005050",
    valueDesc: "FTM",
    label: "EV005050: FTM"
  },
  {
    valueId: "EV005051",
    valueDesc: "FRH",
    label: "EV005051: FRH"
  },
  {
    valueId: "EV005052",
    valueDesc: "FRJ",
    label: "EV005052: FRJ"
  },
  {
    valueId: "EV005053",
    valueDesc: "FRL",
    label: "EV005053: FRL"
  },
  {
    valueId: "EV005054",
    valueDesc: "FSK",
    label: "EV005054: FSK"
  },
  {
    valueId: "EV005055",
    valueDesc: "FSL",
    label: "EV005055: FSL"
  },
  {
    valueId: "EV005056",
    valueDesc: "FVA",
    label: "EV005056: FVA"
  },
  {
    valueId: "EV005057",
    valueDesc: "FVB",
    label: "EV005057: FVB"
  },
  {
    valueId: "EV005058",
    valueDesc: "FVC",
    label: "EV005058: FVC"
  },
  {
    valueId: "EV005059",
    valueDesc: "FWP",
    label: "EV005059: FWP"
  },
  {
    valueId: "EV005060",
    valueDesc: "FWR",
    label: "EV005060: FWR"
  },
  {
    valueId: "EV005061",
    valueDesc: "FKD",
    label: "EV005061: FKD"
  },
  {
    valueId: "EV005062",
    valueDesc: "FKH",
    label: "EV005062: FKH"
  },
  {
    valueId: "EV005063",
    valueDesc: "FKJ",
    label: "EV005063: FKJ"
  },
  {
    valueId: "EV005064",
    valueDesc: "FWS",
    label: "EV005064: FWS"
  },
  {
    valueId: "EV005065",
    valueDesc: "FWT",
    label: "EV005065: FWT"
  },
  {
    valueId: "EV005066",
    valueDesc: "FXL",
    label: "EV005066: FXL"
  },
  {
    valueId: "EV005067",
    valueDesc: "GAB",
    label: "EV005067: GAB"
  },
  {
    valueId: "EV005068",
    valueDesc: "GAC",
    label: "EV005068: GAC"
  },
  {
    valueId: "EV005069",
    valueDesc: "GAD",
    label: "EV005069: GAD"
  },
  {
    valueId: "EV005070",
    valueDesc: "GCV/GVH",
    label: "EV005070: GCV/GVH"
  },
  {
    valueId: "EV005071",
    valueDesc: "GCW/GCJ",
    label: "EV005071: GCW/GCJ"
  },
  {
    valueId: "EV005072",
    valueDesc: "GCK/GCT",
    label: "EV005072: GCK/GCT"
  },
  {
    valueId: "EV005073",
    valueDesc: "GCL/GCS",
    label: "EV005073: GCL/GCS"
  },
  {
    valueId: "EV005074",
    valueDesc: "GED",
    label: "EV005074: GED"
  },
  {
    valueId: "EV005075",
    valueDesc: "GJT",
    label: "EV005075: GJT"
  },
  {
    valueId: "EV005076",
    valueDesc: "GKV",
    label: "EV005076: GKV"
  },
  {
    valueId: "EV005077",
    valueDesc: "GLA",
    label: "EV005077: GLA"
  },
  {
    valueId: "EV005078",
    valueDesc: "GLB",
    label: "EV005078: GLB"
  },
  {
    valueId: "EV005079",
    valueDesc: "Biplane",
    label: "EV005079: Biplane"
  },
  {
    valueId: "EV005080",
    valueDesc: "M-shape",
    label: "EV005080: M-shape"
  },
  {
    valueId: "EV005081",
    valueDesc: "Double filament",
    label: "EV005081: Double filament"
  },
  {
    valueId: "EV005082",
    valueDesc: "Mono-plane",
    label: "EV005082: Mono-plane"
  },
  {
    valueId: "EV005083",
    valueDesc: "Single filament",
    label: "EV005083: Single filament"
  },
  {
    valueId: "EV005084",
    valueDesc: "Halogen metal",
    label: "EV005084: Halogen metal"
  },
  {
    valueId: "EV005085",
    valueDesc: "Cool beam reflector",
    label: "EV005085: Cool beam reflector"
  },
  {
    valueId: "EV005087",
    valueDesc: "BA15s",
    label: "EV005087: BA15s"
  },
  {
    valueId: "EV005088",
    valueDesc: "W2.5x9.5d",
    label: "EV005088: W2.5x9.5d"
  },
  {
    valueId: "EV005089",
    valueDesc: "C21W",
    label: "EV005089: C21W"
  },
  {
    valueId: "EV005090",
    valueDesc: "C5W",
    label: "EV005090: C5W"
  },
  {
    valueId: "EV005091",
    valueDesc: "H6W",
    label: "EV005091: H6W"
  },
  {
    valueId: "EV005092",
    valueDesc: "H12",
    label: "EV005092: H12"
  },
  {
    valueId: "EV005093",
    valueDesc: "H21W",
    label: "EV005093: H21W"
  },
  {
    valueId: "EV005094",
    valueDesc: "HB3",
    label: "EV005094: HB3"
  },
  {
    valueId: "EV005095",
    valueDesc: "HB3A",
    label: "EV005095: HB3A"
  },
  {
    valueId: "EV005096",
    valueDesc: "HB4",
    label: "EV005096: HB4"
  },
  {
    valueId: "EV005097",
    valueDesc: "HB4A",
    label: "EV005097: HB4A"
  },
  {
    valueId: "EV005098",
    valueDesc: "HS1",
    label: "EV005098: HS1"
  },
  {
    valueId: "EV005099",
    valueDesc: "HS2",
    label: "EV005099: HS2"
  },
  {
    valueId: "EV005100",
    valueDesc: "R2",
    label: "EV005100: R2"
  },
  {
    valueId: "EV005101",
    valueDesc: "P21/4W",
    label: "EV005101: P21/4W"
  },
  {
    valueId: "EV005102",
    valueDesc: "P21/5W",
    label: "EV005102: P21/5W"
  },
  {
    valueId: "EV005103",
    valueDesc: "P21W",
    label: "EV005103: P21W"
  },
  {
    valueId: "EV005104",
    valueDesc: "P27W",
    label: "EV005104: P27W"
  },
  {
    valueId: "EV005105",
    valueDesc: "PY21W",
    label: "EV005105: PY21W"
  },
  {
    valueId: "EV005106",
    valueDesc: "PY27/7W",
    label: "EV005106: PY27/7W"
  },
  {
    valueId: "EV005107",
    valueDesc: "R5W",
    label: "EV005107: R5W"
  },
  {
    valueId: "EV005108",
    valueDesc: "R10W",
    label: "EV005108: R10W"
  },
  {
    valueId: "EV005109",
    valueDesc: "S1",
    label: "EV005109: S1"
  },
  {
    valueId: "EV005110",
    valueDesc: "S2",
    label: "EV005110: S2"
  },
  {
    valueId: "EV005111",
    valueDesc: "S3",
    label: "EV005111: S3"
  },
  {
    valueId: "EV005112",
    valueDesc: "T4W",
    label: "EV005112: T4W"
  },
  {
    valueId: "EV005113",
    valueDesc: "W2.3W",
    label: "EV005113: W2.3W"
  },
  {
    valueId: "EV005114",
    valueDesc: "W3W",
    label: "EV005114: W3W"
  },
  {
    valueId: "EV005115",
    valueDesc: "W5W",
    label: "EV005115: W5W"
  },
  {
    valueId: "EV005116",
    valueDesc: "W16W",
    label: "EV005116: W16W"
  },
  {
    valueId: "EV005117",
    valueDesc: "W21W",
    label: "EV005117: W21W"
  },
  {
    valueId: "EV005118",
    valueDesc: "W21/5W",
    label: "EV005118: W21/5W"
  },
  {
    valueId: "EV005119",
    valueDesc: "WP21W",
    label: "EV005119: WP21W"
  },
  {
    valueId: "EV005120",
    valueDesc: "WPY21W",
    label: "EV005120: WPY21W"
  },
  {
    valueId: "EV005121",
    valueDesc: "WY2.3W",
    label: "EV005121: WY2.3W"
  },
  {
    valueId: "EV005122",
    valueDesc: "WY5W",
    label: "EV005122: WY5W"
  },
  {
    valueId: "EV005123",
    valueDesc: "Without ECE-category",
    label: "EV005123: Without ECE-category"
  },
  {
    valueId: "EV005124",
    valueDesc: "P27/7W",
    label: "EV005124: P27/7W"
  },
  {
    valueId: "EV005134",
    valueDesc: "Only ON",
    label: "EV005134: Only ON"
  },
  {
    valueId: "EV005135",
    valueDesc: "On or off",
    label: "EV005135: On or off"
  },
  {
    valueId: "EV005136",
    valueDesc: "Without hood",
    label: "EV005136: Without hood"
  },
  {
    valueId: "EV005137",
    valueDesc: "Transport - hoist",
    label: "EV005137: Transport - hoist"
  },
  {
    valueId: "EV005150",
    valueDesc: "ADR",
    label: "EV005150: ADR"
  },
  {
    valueId: "EV005151",
    valueDesc: "Antenna/satellite technique",
    label: "EV005151: Antenna/satellite technique"
  },
  {
    valueId: "EV005152",
    valueDesc: "1 BNC Video",
    label: "EV005152: 1 BNC Video"
  },
  {
    valueId: "EV005153",
    valueDesc: "1 BNC Audio",
    label: "EV005153: 1 BNC Audio"
  },
  {
    valueId: "EV005154",
    valueDesc: "1 F-Connector",
    label: "EV005154: 1 F-Connector"
  },
  {
    valueId: "EV005155",
    valueDesc: "1 RCA Audio",
    label: "EV005155: 1 RCA Audio"
  },
  {
    valueId: "EV005156",
    valueDesc: "2 BNC Video",
    label: "EV005156: 2 BNC Video"
  },
  {
    valueId: "EV005157",
    valueDesc: "2 BNC Audio",
    label: "EV005157: 2 BNC Audio"
  },
  {
    valueId: "EV005158",
    valueDesc: "1 HF",
    label: "EV005158: 1 HF"
  },
  {
    valueId: "EV005160",
    valueDesc: "IBM AS400",
    label: "EV005160: IBM AS400"
  },
  {
    valueId: "EV005165",
    valueDesc: "1-2 / 3-6",
    label: "EV005165: 1-2 / 3-6"
  },
  {
    valueId: "EV005166",
    valueDesc: "3-6 / 4-5",
    label: "EV005166: 3-6 / 4-5"
  },
  {
    valueId: "EV005167",
    valueDesc: "1-6 / 5-9",
    label: "EV005167: 1-6 / 5-9"
  },
  {
    valueId: "EV005168",
    valueDesc: "LC-Duplex",
    label: "EV005168: LC-Duplex"
  },
  {
    valueId: "EV005169",
    valueDesc: "E2000 compact",
    label: "EV005169: E2000 compact"
  },
  {
    valueId: "EV005170",
    valueDesc: "FC/PC",
    label: "EV005170: FC/PC"
  },
  {
    valueId: "EV005171",
    valueDesc: "Conductor pigtail",
    label: "EV005171: Conductor pigtail"
  },
  {
    valueId: "EV005172",
    valueDesc: "Cable pigtail",
    label: "EV005172: Cable pigtail"
  },
  {
    valueId: "EV005175",
    valueDesc: "Put-on",
    label: "EV005175: Put-on"
  },
  {
    valueId: "EV005180",
    valueDesc: "RJ10 4(4)",
    label: "EV005180: RJ10 4(4)"
  },
  {
    valueId: "EV005181",
    valueDesc: "RJ9 4(2)",
    label: "EV005181: RJ9 4(2)"
  },
  {
    valueId: "EV005185",
    valueDesc: "1:1",
    label: "EV005185: 1:1"
  },
  {
    valueId: "EV005186",
    valueDesc: "Crossed",
    label: "EV005186: Crossed"
  },
  {
    valueId: "EV005187",
    valueDesc: "Moulded-on",
    label: "EV005187: Moulded-on"
  },
  {
    valueId: "EV005188",
    valueDesc: "4x2",
    label: "EV005188: 4x2"
  },
  {
    valueId: "EV005189",
    valueDesc: "2x2",
    label: "EV005189: 2x2"
  },
  {
    valueId: "EV005190",
    valueDesc: "1x4",
    label: "EV005190: 1x4"
  },
  {
    valueId: "EV005191",
    valueDesc: "2x4",
    label: "EV005191: 2x4"
  },
  {
    valueId: "EV005196",
    valueDesc: "GLC",
    label: "EV005196: GLC"
  },
  {
    valueId: "EV005197",
    valueDesc: "Piezo",
    label: "EV005197: Piezo"
  },
  {
    valueId: "EV005198",
    valueDesc: "Flintstone",
    label: "EV005198: Flintstone"
  },
  {
    valueId: "EV005199",
    valueDesc: "Soldering iron direct",
    label: "EV005199: Soldering iron direct"
  },
  {
    valueId: "EV005200",
    valueDesc: "Workstation",
    label: "EV005200: Workstation"
  },
  {
    valueId: "EV005201",
    valueDesc: "Viscose",
    label: "EV005201: Viscose"
  },
  {
    valueId: "EV005202",
    valueDesc: "Steel wool",
    label: "EV005202: Steel wool"
  },
  {
    valueId: "EV005205",
    valueDesc: "Touch operated lock",
    label: "EV005205: Touch operated lock"
  },
  {
    valueId: "EV005206",
    valueDesc: "Key lock",
    label: "EV005206: Key lock"
  },
  {
    valueId: "EV005208",
    valueDesc: "Chisel shaped",
    label: "EV005208: Chisel shaped"
  },
  {
    valueId: "EV005210",
    valueDesc: "Measuring adapter",
    label: "EV005210: Measuring adapter"
  },
  {
    valueId: "EV005217",
    valueDesc: "Screens",
    label: "EV005217: Screens"
  },
  {
    valueId: "EV005218",
    valueDesc: "Glass surfaces",
    label: "EV005218: Glass surfaces"
  },
  {
    valueId: "EV005219",
    valueDesc: "Printer mechanics",
    label: "EV005219: Printer mechanics"
  },
  {
    valueId: "EV005222",
    valueDesc: "Tubes",
    label: "EV005222: Tubes"
  },
  {
    valueId: "EV005223",
    valueDesc: "Support",
    label: "EV005223: Support"
  },
  {
    valueId: "EV005224",
    valueDesc: "Welds",
    label: "EV005224: Welds"
  },
  {
    valueId: "EV005238",
    valueDesc: "Insulated tube cable lug and connector",
    label: "EV005238: Insulated tube cable lug and connector"
  },
  {
    valueId: "EV005240",
    valueDesc: "Cutting insert",
    label: "EV005240: Cutting insert"
  },
  {
    valueId: "EV005271",
    valueDesc: "Inclined",
    label: "EV005271: Inclined"
  },
  {
    valueId: "EV005272",
    valueDesc: "DIN rail (top hat rail) 35/15 mm",
    label: "EV005272: DIN rail (top hat rail) 35/15 mm"
  },
  {
    valueId: "EV005273",
    valueDesc: "DIN rail (top hat rail) 35/7.5 mm",
    label: "EV005273: DIN rail (top hat rail) 35/7.5 mm"
  },
  {
    valueId: "EV005274",
    valueDesc: "G-rail",
    label: "EV005274: G-rail"
  },
  {
    valueId: "EV005276",
    valueDesc: "Direct mounting",
    label: "EV005276: Direct mounting"
  },
  {
    valueId: "EV005277",
    valueDesc: "Special profile",
    label: "EV005277: Special profile"
  },
  {
    valueId: "EV005279",
    valueDesc: "5V",
    label: "EV005279: 5V"
  },
  {
    valueId: "EV005280",
    valueDesc: "Pluggable",
    label: "EV005280: Pluggable"
  },
  {
    valueId: "EV005282",
    valueDesc: "Cu/CuNi44",
    label: "EV005282: Cu/CuNi44"
  },
  {
    valueId: "EV005283",
    valueDesc: "Fe/CuNi44",
    label: "EV005283: Fe/CuNi44"
  },
  {
    valueId: "EV005284",
    valueDesc: "NiCr/CuNi44",
    label: "EV005284: NiCr/CuNi44"
  },
  {
    valueId: "EV005285",
    valueDesc: "NiCrSi/NiSi",
    label: "EV005285: NiCrSi/NiSi"
  },
  {
    valueId: "EV005286",
    valueDesc: "NiCr/Ni",
    label: "EV005286: NiCr/Ni"
  },
  {
    valueId: "EV005287",
    valueDesc: "E-Cu/A-Cu",
    label: "EV005287: E-Cu/A-Cu"
  },
  {
    valueId: "EV005288",
    valueDesc: "S-Cu/E-Cu",
    label: "EV005288: S-Cu/E-Cu"
  },
  {
    valueId: "EV005289",
    valueDesc: "Sliding",
    label: "EV005289: Sliding"
  },
  {
    valueId: "EV005290",
    valueDesc: "3x10 mm",
    label: "EV005290: 3x10 mm"
  },
  {
    valueId: "EV005291",
    valueDesc: "6x6 mm",
    label: "EV005291: 6x6 mm"
  },
  {
    valueId: "EV005292",
    valueDesc: "5x20 mm",
    label: "EV005292: 5x20 mm"
  },
  {
    valueId: "EV005293",
    valueDesc: "Bichromated",
    label: "EV005293: Bichromated"
  },
  {
    valueId: "EV005294",
    valueDesc: "Round hole",
    label: "EV005294: Round hole"
  },
  {
    valueId: "EV005296",
    valueDesc: "1/N~230 V",
    label: "EV005296: 1/N~230 V"
  },
  {
    valueId: "EV005298",
    valueDesc: "5x25 mm",
    label: "EV005298: 5x25 mm"
  },
  {
    valueId: "EV005299",
    valueDesc: "6.3x32 mm",
    label: "EV005299: 6.3x32 mm"
  },
  {
    valueId: "EV005300",
    valueDesc: "Cs",
    label: "EV005300: Cs"
  },
  {
    valueId: "EV005307",
    valueDesc: "aM (switchgear protection)",
    label: "EV005307: aM (switchgear protection)"
  },
  {
    valueId: "EV005308",
    valueDesc: "gR (general purpose semiconductor protection)",
    label: "EV005308: gR (general purpose semiconductor protection)"
  },
  {
    valueId: "EV005309",
    valueDesc: "aR (accompanied semiconductor protection)",
    label: "EV005309: aR (accompanied semiconductor protection)"
  },
  {
    valueId: "EV005310",
    valueDesc: "NHC00",
    label: "EV005310: NHC00"
  },
  {
    valueId: "EV005311",
    valueDesc: "000",
    label: "EV005311: 000"
  },
  {
    valueId: "EV005321",
    valueDesc: "DI",
    label: "EV005321: DI"
  },
  {
    valueId: "EV005323",
    valueDesc: "Sorted",
    label: "EV005323: Sorted"
  },
  {
    valueId: "EV005336",
    valueDesc: "With inscription",
    label: "EV005336: With inscription"
  },
  {
    valueId: "EV005349",
    valueDesc: "Complementary mounting set",
    label: "EV005349: Complementary mounting set"
  },
  {
    valueId: "EV005350",
    valueDesc: "Round duct",
    label: "EV005350: Round duct"
  },
  {
    valueId: "EV005352",
    valueDesc: "Metal housing",
    label: "EV005352: Metal housing"
  },
  {
    valueId: "EV005353",
    valueDesc: "Plastic housing",
    label: "EV005353: Plastic housing"
  },
  {
    valueId: "EV005355",
    valueDesc: "Ceiling mounting",
    label: "EV005355: Ceiling mounting"
  },
  {
    valueId: "EV005357",
    valueDesc: "Floor mounting",
    label: "EV005357: Floor mounting"
  },
  {
    valueId: "EV005366",
    valueDesc: "Pitched roof",
    label: "EV005366: Pitched roof"
  },
  {
    valueId: "EV005371",
    valueDesc: "1/N/PE~230 V",
    label: "EV005371: 1/N/PE~230 V"
  },
  {
    valueId: "EV005372",
    valueDesc: "DN 15",
    label: "EV005372: DN 15"
  },
  {
    valueId: "EV005373",
    valueDesc: "DN 20",
    label: "EV005373: DN 20"
  },
  {
    valueId: "EV005374",
    valueDesc: "DN 25",
    label: "EV005374: DN 25"
  },
  {
    valueId: "EV005375",
    valueDesc: "DN 30",
    label: "EV005375: DN 30"
  },
  {
    valueId: "EV005376",
    valueDesc: "Ex-proof",
    label: "EV005376: Ex-proof"
  },
  {
    valueId: "EV005377",
    valueDesc: "3/4 inch external thread",
    label: "EV005377: 3/4 inch external thread"
  },
  {
    valueId: "EV005382",
    valueDesc: "UV",
    label: "EV005382: UV"
  },
  {
    valueId: "EV005383",
    valueDesc: "PSFc20-6",
    label: "EV005383: PSFc20-6"
  },
  {
    valueId: "EV005385",
    valueDesc: "2G11 (4-pins)",
    label: "EV005385: 2G11 (4-pins)"
  },
  {
    valueId: "EV005387",
    valueDesc: "G23 (2-pins)",
    label: "EV005387: G23 (2-pins)"
  },
  {
    valueId: "EV005403",
    valueDesc: "GY6.35-15",
    label: "EV005403: GY6.35-15"
  },
  {
    valueId: "EV005404",
    valueDesc: "K23s",
    label: "EV005404: K23s"
  },
  {
    valueId: "EV005405",
    valueDesc: "KX23d",
    label: "EV005405: KX23d"
  },
  {
    valueId: "EV005406",
    valueDesc: "Ring mirror",
    label: "EV005406: Ring mirror"
  },
  {
    valueId: "EV005410",
    valueDesc: "2-pole",
    label: "EV005410: 2-pole"
  },
  {
    valueId: "EV005416",
    valueDesc: "Equipment mounted socket outlet",
    label: "EV005416: Equipment mounted socket outlet"
  },
  {
    valueId: "EV005418",
    valueDesc: "Combination",
    label: "EV005418: Combination"
  },
  {
    valueId: "EV005420",
    valueDesc: "400 V",
    label: "EV005420: 400 V"
  },
  {
    valueId: "EV005434",
    valueDesc: "Screw less terminal",
    label: "EV005434: Screw less terminal"
  },
  {
    valueId: "EV005435",
    valueDesc: "Anti-kink sleeve",
    label: "EV005435: Anti-kink sleeve"
  },
  {
    valueId: "EV005444",
    valueDesc: "75x75 mm",
    label: "EV005444: 75x75 mm"
  },
  {
    valueId: "EV005454",
    valueDesc: "130x130 mm",
    label: "EV005454: 130x130 mm"
  },
  {
    valueId: "EV005455",
    valueDesc: "CEE 16 A",
    label: "EV005455: CEE 16 A"
  },
  {
    valueId: "EV005456",
    valueDesc: "CEE 32 A",
    label: "EV005456: CEE 32 A"
  },
  {
    valueId: "EV005469",
    valueDesc: "Built-up device plug",
    label: "EV005469: Built-up device plug"
  },
  {
    valueId: "EV005470",
    valueDesc: "Switchable wall socket",
    label: "EV005470: Switchable wall socket"
  },
  {
    valueId: "EV005473",
    valueDesc: "Without on/off switch",
    label: "EV005473: Without on/off switch"
  },
  {
    valueId: "EV005474",
    valueDesc: "With on/off switch, without locking",
    label: "EV005474: With on/off switch, without locking"
  },
  {
    valueId: "EV005475",
    valueDesc: "Electrical locking",
    label: "EV005475: Electrical locking"
  },
  {
    valueId: "EV005476",
    valueDesc: "Electronic locking",
    label: "EV005476: Electronic locking"
  },
  {
    valueId: "EV005477",
    valueDesc: "Auxiliary contacts",
    label: "EV005477: Auxiliary contacts"
  },
  {
    valueId: "EV005478",
    valueDesc: "Special switch",
    label: "EV005478: Special switch"
  },
  {
    valueId: "EV005479",
    valueDesc: "Mixed",
    label: "EV005479: Mixed"
  },
  {
    valueId: "EV005482",
    valueDesc: "Resistor",
    label: "EV005482: Resistor"
  },
  {
    valueId: "EV005483",
    valueDesc: "Suppressor diode",
    label: "EV005483: Suppressor diode"
  },
  {
    valueId: "EV005484",
    valueDesc: "Gas tube conductor",
    label: "EV005484: Gas tube conductor"
  },
  {
    valueId: "EV005485",
    valueDesc: "Free configurable",
    label: "EV005485: Free configurable"
  },
  {
    valueId: "EV005489",
    valueDesc: "Screwless",
    label: "EV005489: Screwless"
  },
  {
    valueId: "EV005490",
    valueDesc: "Transverse connector",
    label: "EV005490: Transverse connector"
  },
  {
    valueId: "EV005491",
    valueDesc: "Box connector",
    label: "EV005491: Box connector"
  },
  {
    valueId: "EV005492",
    valueDesc: "Vertical connector",
    label: "EV005492: Vertical connector"
  },
  {
    valueId: "EV005493",
    valueDesc: "Cable bridge",
    label: "EV005493: Cable bridge"
  },
  {
    valueId: "EV005494",
    valueDesc: "Push-in type jumper bar",
    label: "EV005494: Push-in type jumper bar"
  },
  {
    valueId: "EV005495",
    valueDesc: "Wire commoning chain",
    label: "EV005495: Wire commoning chain"
  },
  {
    valueId: "EV005496",
    valueDesc: "Separating bridger",
    label: "EV005496: Separating bridger"
  },
  {
    valueId: "EV005497",
    valueDesc: "Switching bridge",
    label: "EV005497: Switching bridge"
  },
  {
    valueId: "EV005498",
    valueDesc: "Reducing connector",
    label: "EV005498: Reducing connector"
  },
  {
    valueId: "EV005499",
    valueDesc: "Blade fuse",
    label: "EV005499: Blade fuse"
  },
  {
    valueId: "EV005500",
    valueDesc: "G-fuse 5x25 mm",
    label: "EV005500: G-fuse 5x25 mm"
  },
  {
    valueId: "EV005501",
    valueDesc: "G-fuse 5x20 mm",
    label: "EV005501: G-fuse 5x20 mm"
  },
  {
    valueId: "EV005502",
    valueDesc: "G-fuse 5x30 mm",
    label: "EV005502: G-fuse 5x30 mm"
  },
  {
    valueId: "EV005503",
    valueDesc: "DIN rail (top hat rail) 35 mm",
    label: "EV005503: DIN rail (top hat rail) 35 mm"
  },
  {
    valueId: "EV005504",
    valueDesc: "DIN rail (top hat rail) 15 mm",
    label: "EV005504: DIN rail (top hat rail) 15 mm"
  },
  {
    valueId: "EV005505",
    valueDesc: "DIN rail (top hat rail) mounting rail 75 mm",
    label: "EV005505: DIN rail (top hat rail) mounting rail 75 mm"
  },
  {
    valueId: "EV005506",
    valueDesc: "15x6 mm",
    label: "EV005506: 15x6 mm"
  },
  {
    valueId: "EV005514",
    valueDesc: "Bus system",
    label: "EV005514: Bus system"
  },
  {
    valueId: "EV005515",
    valueDesc: "1+n system",
    label: "EV005515: 1+n system"
  },
  {
    valueId: "EV005516",
    valueDesc: "Multi-wire system",
    label: "EV005516: Multi-wire system"
  },
  {
    valueId: "EV005517",
    valueDesc: "SN",
    label: "EV005517: SN"
  },
  {
    valueId: "EV005518",
    valueDesc: "Standing",
    label: "EV005518: Standing"
  },
  {
    valueId: "EV005519",
    valueDesc: "Wall lead-through",
    label: "EV005519: Wall lead-through"
  },
  {
    valueId: "EV005523",
    valueDesc: "Plug-in",
    label: "EV005523: Plug-in"
  },
  {
    valueId: "EV005528",
    valueDesc: "Switch (connect)",
    label: "EV005528: Switch (connect)"
  },
  {
    valueId: "EV005538",
    valueDesc: "DECT-module",
    label: "EV005538: DECT-module"
  },
  {
    valueId: "EV005539",
    valueDesc: "UPO-module",
    label: "EV005539: UPO-module"
  },
  {
    valueId: "EV005540",
    valueDesc: "Fast beat",
    label: "EV005540: Fast beat"
  },
  {
    valueId: "EV005541",
    valueDesc: "Slow beat",
    label: "EV005541: Slow beat"
  },
  {
    valueId: "EV005542",
    valueDesc: "Single beat",
    label: "EV005542: Single beat"
  },
  {
    valueId: "EV005543",
    valueDesc: "Alternating",
    label: "EV005543: Alternating"
  },
  {
    valueId: "EV005544",
    valueDesc: "2-wire",
    label: "EV005544: 2-wire"
  },
  {
    valueId: "EV005551",
    valueDesc: "Tunnel",
    label: "EV005551: Tunnel"
  },
  {
    valueId: "EV005556",
    valueDesc: "Radio antenna",
    label: "EV005556: Radio antenna"
  },
  {
    valueId: "EV005557",
    valueDesc: "Radio-/television antenna",
    label: "EV005557: Radio-/television antenna"
  },
  {
    valueId: "EV005558",
    valueDesc: "One-wing, left and right",
    label: "EV005558: One-wing, left and right"
  },
  {
    valueId: "EV005559",
    valueDesc: "One-wing, left only",
    label: "EV005559: One-wing, left only"
  },
  {
    valueId: "EV005560",
    valueDesc: "One-wing, right only",
    label: "EV005560: One-wing, right only"
  },
  {
    valueId: "EV005561",
    valueDesc: "Gate stop",
    label: "EV005561: Gate stop"
  },
  {
    valueId: "EV005562",
    valueDesc: "Internal end switch",
    label: "EV005562: Internal end switch"
  },
  {
    valueId: "EV005565",
    valueDesc: "SAT audio sub carriers",
    label: "EV005565: SAT audio sub carriers"
  },
  {
    valueId: "EV005566",
    valueDesc: "Cable-digital-TV (QAM, DVB-C)",
    label: "EV005566: Cable-digital-TV (QAM, DVB-C)"
  },
  {
    valueId: "EV005567",
    valueDesc: "Green/colourless",
    label: "EV005567: Green/colourless"
  },
  {
    valueId: "EV005568",
    valueDesc: "Xenon",
    label: "EV005568: Xenon"
  },
  {
    valueId: "EV005569",
    valueDesc: "Terrestrial-digital TV (COFDM, DVB-T)",
    label: "EV005569: Terrestrial-digital TV (COFDM, DVB-T)"
  },
  {
    valueId: "EV005570",
    valueDesc: "Horn",
    label: "EV005570: Horn"
  },
  {
    valueId: "EV005571",
    valueDesc: "Sound generator",
    label: "EV005571: Sound generator"
  },
  {
    valueId: "EV005572",
    valueDesc: "Digital",
    label: "EV005572: Digital"
  },
  {
    valueId: "EV005573",
    valueDesc: "Offset",
    label: "EV005573: Offset"
  },
  {
    valueId: "EV005574",
    valueDesc: "Twin universal",
    label: "EV005574: Twin universal"
  },
  {
    valueId: "EV005575",
    valueDesc: "Single universal",
    label: "EV005575: Single universal"
  },
  {
    valueId: "EV005576",
    valueDesc: "1 RCA Video",
    label: "EV005576: 1 RCA Video"
  },
  {
    valueId: "EV005577",
    valueDesc: "Halogen",
    label: "EV005577: Halogen"
  },
  {
    valueId: "EV005578",
    valueDesc: "Fresnel lens",
    label: "EV005578: Fresnel lens"
  },
  {
    valueId: "EV005579",
    valueDesc: "Glass optics",
    label: "EV005579: Glass optics"
  },
  {
    valueId: "EV005585",
    valueDesc: "Distribute",
    label: "EV005585: Distribute"
  },
  {
    valueId: "EV005586",
    valueDesc: "Convert",
    label: "EV005586: Convert"
  },
  {
    valueId: "EV005587",
    valueDesc: "Expand",
    label: "EV005587: Expand"
  },
  {
    valueId: "EV005588",
    valueDesc: "Signalling",
    label: "EV005588: Signalling"
  },
  {
    valueId: "EV005589",
    valueDesc: "Controlling",
    label: "EV005589: Controlling"
  },
  {
    valueId: "EV005593",
    valueDesc: "AM",
    label: "EV005593: AM"
  },
  {
    valueId: "EV005594",
    valueDesc: "Chamfered",
    label: "EV005594: Chamfered"
  },
  {
    valueId: "EV005596",
    valueDesc: "Pencil point",
    label: "EV005596: Pencil point"
  },
  {
    valueId: "EV005597",
    valueDesc: "Side",
    label: "EV005597: Side"
  },
  {
    valueId: "EV005600",
    valueDesc: "Adapter set for covers",
    label: "EV005600: Adapter set for covers"
  },
  {
    valueId: "EV005603",
    valueDesc: "1x16A3p230V",
    label: "EV005603: 1x16A3p230V"
  },
  {
    valueId: "EV005604",
    valueDesc: "2x16A3p230V",
    label: "EV005604: 2x16A3p230V"
  },
  {
    valueId: "EV005605",
    valueDesc: "3x16A3p230V",
    label: "EV005605: 3x16A3p230V"
  },
  {
    valueId: "EV005606",
    valueDesc: ">4x16A3p230V",
    label: "EV005606: >4x16A3p230V"
  },
  {
    valueId: "EV005607",
    valueDesc: "1x16A5p400V",
    label: "EV005607: 1x16A5p400V"
  },
  {
    valueId: "EV005608",
    valueDesc: "2x16A5p400V",
    label: "EV005608: 2x16A5p400V"
  },
  {
    valueId: "EV005609",
    valueDesc: "3x16A5p400V",
    label: "EV005609: 3x16A5p400V"
  },
  {
    valueId: "EV005610",
    valueDesc: "4x16A5p400V",
    label: "EV005610: 4x16A5p400V"
  },
  {
    valueId: "EV005611",
    valueDesc: ">4x16A5p400V",
    label: "EV005611: >4x16A5p400V"
  },
  {
    valueId: "EV005612",
    valueDesc: "1x16A xp xxxV",
    label: "EV005612: 1x16A xp xxxV"
  },
  {
    valueId: "EV005613",
    valueDesc: "2x16A xp xxxV",
    label: "EV005613: 2x16A xp xxxV"
  },
  {
    valueId: "EV005614",
    valueDesc: "1x32A5p400V",
    label: "EV005614: 1x32A5p400V"
  },
  {
    valueId: "EV005615",
    valueDesc: "2x32A5p400V",
    label: "EV005615: 2x32A5p400V"
  },
  {
    valueId: "EV005616",
    valueDesc: "3x32A5p400V",
    label: "EV005616: 3x32A5p400V"
  },
  {
    valueId: "EV005617",
    valueDesc: "4x32A5p400V",
    label: "EV005617: 4x32A5p400V"
  },
  {
    valueId: "EV005618",
    valueDesc: ">4x32A5p400V",
    label: "EV005618: >4x32A5p400V"
  },
  {
    valueId: "EV005619",
    valueDesc: "1x32A xp xxxV",
    label: "EV005619: 1x32A xp xxxV"
  },
  {
    valueId: "EV005620",
    valueDesc: "2x32A xp xxxV",
    label: "EV005620: 2x32A xp xxxV"
  },
  {
    valueId: "EV005621",
    valueDesc: "3x32A xp xxV",
    label: "EV005621: 3x32A xp xxV"
  },
  {
    valueId: "EV005622",
    valueDesc: "4x32A xp xxxV",
    label: "EV005622: 4x32A xp xxxV"
  },
  {
    valueId: "EV005623",
    valueDesc: "1 cable (branch line)",
    label: "EV005623: 1 cable (branch line)"
  },
  {
    valueId: "EV005624",
    valueDesc: "2 cables (ring line)",
    label: "EV005624: 2 cables (ring line)"
  },
  {
    valueId: "EV005625",
    valueDesc: "Built-up plug",
    label: "EV005625: Built-up plug"
  },
  {
    valueId: "EV005626",
    valueDesc: "Feeder cable with plug",
    label: "EV005626: Feeder cable with plug"
  },
  {
    valueId: "EV005627",
    valueDesc: "Portable",
    label: "EV005627: Portable"
  },
  {
    valueId: "EV005628",
    valueDesc: "Hangable",
    label: "EV005628: Hangable"
  },
  {
    valueId: "EV005629",
    valueDesc: "Subfloor",
    label: "EV005629: Subfloor"
  },
  {
    valueId: "EV005630",
    valueDesc: "Energy bollard",
    label: "EV005630: Energy bollard"
  },
  {
    valueId: "EV005633",
    valueDesc: "Roof feed-through",
    label: "EV005633: Roof feed-through"
  },
  {
    valueId: "EV005634",
    valueDesc: "Sealing for roof feed-through",
    label: "EV005634: Sealing for roof feed-through"
  },
  {
    valueId: "EV005635",
    valueDesc: "Mast clamp",
    label: "EV005635: Mast clamp"
  },
  {
    valueId: "EV005636",
    valueDesc: "Mast covering cap",
    label: "EV005636: Mast covering cap"
  },
  {
    valueId: "EV005637",
    valueDesc: "Mast mounting set",
    label: "EV005637: Mast mounting set"
  },
  {
    valueId: "EV005638",
    valueDesc: "Sealing plug",
    label: "EV005638: Sealing plug"
  },
  {
    valueId: "EV005639",
    valueDesc: "Mast spacing support",
    label: "EV005639: Mast spacing support"
  },
  {
    valueId: "EV005641",
    valueDesc: "Analogue",
    label: "EV005641: Analogue"
  },
  {
    valueId: "EV005642",
    valueDesc: "Pointer",
    label: "EV005642: Pointer"
  },
  {
    valueId: "EV005643",
    valueDesc: "Forehead",
    label: "EV005643: Forehead"
  },
  {
    valueId: "EV005644",
    valueDesc: "Ear",
    label: "EV005644: Ear"
  },
  {
    valueId: "EV005647",
    valueDesc: "Décolletage",
    label: "EV005647: Décolletage"
  },
  {
    valueId: "EV005650",
    valueDesc: "Gypsum fibre board",
    label: "EV005650: Gypsum fibre board"
  },
  {
    valueId: "EV005656",
    valueDesc: "Spline shank",
    label: "EV005656: Spline shank"
  },
  {
    valueId: "EV005657",
    valueDesc: "SDS-max socket",
    label: "EV005657: SDS-max socket"
  },
  {
    valueId: "EV005658",
    valueDesc: "SDS-plus socket",
    label: "EV005658: SDS-plus socket"
  },
  {
    valueId: "EV005659",
    valueDesc: "SDS-top socket",
    label: "EV005659: SDS-top socket"
  },
  {
    valueId: "EV005668",
    valueDesc: "Square shaft",
    label: "EV005668: Square shaft"
  },
  {
    valueId: "EV005678",
    valueDesc: ">4x32A xp xxxV",
    label: "EV005678: >4x32A xp xxxV"
  },
  {
    valueId: "EV005679",
    valueDesc: "1x63A5p400V",
    label: "EV005679: 1x63A5p400V"
  },
  {
    valueId: "EV005680",
    valueDesc: "2x63A5p400V",
    label: "EV005680: 2x63A5p400V"
  },
  {
    valueId: "EV005681",
    valueDesc: "3x63A5p400V",
    label: "EV005681: 3x63A5p400V"
  },
  {
    valueId: "EV005682",
    valueDesc: "4x63A5p400V",
    label: "EV005682: 4x63A5p400V"
  },
  {
    valueId: "EV005683",
    valueDesc: ">4x63A5p400V",
    label: "EV005683: >4x63A5p400V"
  },
  {
    valueId: "EV005684",
    valueDesc: "1x63A xp xxxV",
    label: "EV005684: 1x63A xp xxxV"
  },
  {
    valueId: "EV005685",
    valueDesc: "2x63A xp xxxV",
    label: "EV005685: 2x63A xp xxxV"
  },
  {
    valueId: "EV005686",
    valueDesc: "3x63A xp xxxV",
    label: "EV005686: 3x63A xp xxxV"
  },
  {
    valueId: "EV005687",
    valueDesc: "4x63A xp xxxV",
    label: "EV005687: 4x63A xp xxxV"
  },
  {
    valueId: "EV005688",
    valueDesc: ">4x63A xp xxxV",
    label: "EV005688: >4x63A xp xxxV"
  },
  {
    valueId: "EV005689",
    valueDesc: "1x125A5p400V",
    label: "EV005689: 1x125A5p400V"
  },
  {
    valueId: "EV005690",
    valueDesc: "2x125A5p400V",
    label: "EV005690: 2x125A5p400V"
  },
  {
    valueId: "EV005691",
    valueDesc: "3x125A5p400V",
    label: "EV005691: 3x125A5p400V"
  },
  {
    valueId: "EV005692",
    valueDesc: "4x125A5p400V",
    label: "EV005692: 4x125A5p400V"
  },
  {
    valueId: "EV005693",
    valueDesc: ">4x125A5p400V",
    label: "EV005693: >4x125A5p400V"
  },
  {
    valueId: "EV005694",
    valueDesc: "1x125A xp xxxV",
    label: "EV005694: 1x125A xp xxxV"
  },
  {
    valueId: "EV005695",
    valueDesc: "2x125A xp xxxV",
    label: "EV005695: 2x125A xp xxxV"
  },
  {
    valueId: "EV005696",
    valueDesc: "3x125A xp xxxV",
    label: "EV005696: 3x125A xp xxxV"
  },
  {
    valueId: "EV005697",
    valueDesc: "4x125A xp xxxV",
    label: "EV005697: 4x125A xp xxxV"
  },
  {
    valueId: "EV005698",
    valueDesc: "4x16A3p230V",
    label: "EV005698: 4x16A3p230V"
  },
  {
    valueId: "EV005699",
    valueDesc: ">4x125A xp xxxV",
    label: "EV005699: >4x125A xp xxxV"
  },
  {
    valueId: "EV005700",
    valueDesc: "Overcurrent switch",
    label: "EV005700: Overcurrent switch"
  },
  {
    valueId: "EV005701",
    valueDesc: "Overcurrent switch/D0-system",
    label: "EV005701: Overcurrent switch/D0-system"
  },
  {
    valueId: "EV005703",
    valueDesc: "Earth leakage switch with IFN 30 mA (standard)",
    label: "EV005703: Earth leakage switch with IFN 30 mA (standard)"
  },
  {
    valueId: "EV005704",
    valueDesc: "Earth leakage switch with IFN 300 mA",
    label: "EV005704: Earth leakage switch with IFN 300 mA"
  },
  {
    valueId: "EV005705",
    valueDesc: "Earth leakage switch with IFN 500 mA",
    label: "EV005705: Earth leakage switch with IFN 500 mA"
  },
  {
    valueId: "EV005707",
    valueDesc: "12 - 24 V AC/DC",
    label: "EV005707: 12 - 24 V AC/DC"
  },
  {
    valueId: "EV005708",
    valueDesc: "8 - 18 V DC",
    label: "EV005708: 8 - 18 V DC"
  },
  {
    valueId: "EV005711",
    valueDesc: "Via digital time switch",
    label: "EV005711: Via digital time switch"
  },
  {
    valueId: "EV005712",
    valueDesc: "External power adaptor required",
    label: "EV005712: External power adaptor required"
  },
  {
    valueId: "EV005713",
    valueDesc: "External power adaptor not required",
    label: "EV005713: External power adaptor not required"
  },
  {
    valueId: "EV005714",
    valueDesc: "Electronic with switching-off pre-warning",
    label: "EV005714: Electronic with switching-off pre-warning"
  },
  {
    valueId: "EV005718",
    valueDesc: "3 and 4 wire configuration",
    label: "EV005718: 3 and 4 wire configuration"
  },
  {
    valueId: "EV005719",
    valueDesc: "3 wire configuration",
    label: "EV005719: 3 wire configuration"
  },
  {
    valueId: "EV005720",
    valueDesc: "4 wire configuration",
    label: "EV005720: 4 wire configuration"
  },
  {
    valueId: "EV005721",
    valueDesc: "Flickering",
    label: "EV005721: Flickering"
  },
  {
    valueId: "EV005722",
    valueDesc: "Dimming up to 50 %",
    label: "EV005722: Dimming up to 50 %"
  },
  {
    valueId: "EV005723",
    valueDesc: "Dimming up to 0 %",
    label: "EV005723: Dimming up to 0 %"
  },
  {
    valueId: "EV005724",
    valueDesc: "Flickering and dimming",
    label: "EV005724: Flickering and dimming"
  },
  {
    valueId: "EV005725",
    valueDesc: "Repetitive",
    label: "EV005725: Repetitive"
  },
  {
    valueId: "EV005726",
    valueDesc: "Repetitive + continuous light",
    label: "EV005726: Repetitive + continuous light"
  },
  {
    valueId: "EV005727",
    valueDesc: "Repetitive + continuous light + time extension",
    label: "EV005727: Repetitive + continuous light + time extension"
  },
  {
    valueId: "EV005728",
    valueDesc: "Electronic switch",
    label: "EV005728: Electronic switch"
  },
  {
    valueId: "EV005729",
    valueDesc: "Mechanical switch",
    label: "EV005729: Mechanical switch"
  },
  {
    valueId: "EV005730",
    valueDesc: "Electronic series switch",
    label: "EV005730: Electronic series switch"
  },
  {
    valueId: "EV005731",
    valueDesc: "Mechanical series switch",
    label: "EV005731: Mechanical series switch"
  },
  {
    valueId: "EV005732",
    valueDesc: "Electronic group switch",
    label: "EV005732: Electronic group switch"
  },
  {
    valueId: "EV005733",
    valueDesc: "Mechanical group switch",
    label: "EV005733: Mechanical group switch"
  },
  {
    valueId: "EV005734",
    valueDesc: "Electronic for centralized control",
    label: "EV005734: Electronic for centralized control"
  },
  {
    valueId: "EV005735",
    valueDesc: "Mechanical for centralized control",
    label: "EV005735: Mechanical for centralized control"
  },
  {
    valueId: "EV005736",
    valueDesc: "Electronic with release delay",
    label: "EV005736: Electronic with release delay"
  },
  {
    valueId: "EV005741",
    valueDesc: "PLC - extension",
    label: "EV005741: PLC - extension"
  },
  {
    valueId: "EV005742",
    valueDesc: "PLC - PC",
    label: "EV005742: PLC - PC"
  },
  {
    valueId: "EV005743",
    valueDesc: "PLC programming device",
    label: "EV005743: PLC programming device"
  },
  {
    valueId: "EV005744",
    valueDesc: "PLC - PLC",
    label: "EV005744: PLC - PLC"
  },
  {
    valueId: "EV005745",
    valueDesc: "PLC - other devices",
    label: "EV005745: PLC - other devices"
  },
  {
    valueId: "EV005746",
    valueDesc: "Optional",
    label: "EV005746: Optional"
  },
  {
    valueId: "EV005747",
    valueDesc: "Not available",
    label: "EV005747: Not available"
  },
  {
    valueId: "EV005752",
    valueDesc: "Spring clamp connection",
    label: "EV005752: Spring clamp connection"
  },
  {
    valueId: "EV005753",
    valueDesc: "Screw-/spring clamp connection",
    label: "EV005753: Screw-/spring clamp connection"
  },
  {
    valueId: "EV005754",
    valueDesc: "Wrapped connection",
    label: "EV005754: Wrapped connection"
  },
  {
    valueId: "EV005755",
    valueDesc: "Clip connection",
    label: "EV005755: Clip connection"
  },
  {
    valueId: "EV005756",
    valueDesc: "Soldering flag connection",
    label: "EV005756: Soldering flag connection"
  },
  {
    valueId: "EV005757",
    valueDesc: "Insulation piercing connection",
    label: "EV005757: Insulation piercing connection"
  },
  {
    valueId: "EV005758",
    valueDesc: "Welding connection",
    label: "EV005758: Welding connection"
  },
  {
    valueId: "EV005759",
    valueDesc: "TFT",
    label: "EV005759: TFT"
  },
  {
    valueId: "EV005760",
    valueDesc: "STN",
    label: "EV005760: STN"
  },
  {
    valueId: "EV005762",
    valueDesc: "Box",
    label: "EV005762: Box"
  },
  {
    valueId: "EV005763",
    valueDesc: "Rack",
    label: "EV005763: Rack"
  },
  {
    valueId: "EV005764",
    valueDesc: "Tower",
    label: "EV005764: Tower"
  },
  {
    valueId: "EV005765",
    valueDesc: "Desktop",
    label: "EV005765: Desktop"
  },
  {
    valueId: "EV005769",
    valueDesc: "Intel Pentium 3",
    label: "EV005769: Intel Pentium 3"
  },
  {
    valueId: "EV005770",
    valueDesc: "Intel Pentium 4",
    label: "EV005770: Intel Pentium 4"
  },
  {
    valueId: "EV005771",
    valueDesc: "Athlon XP",
    label: "EV005771: Athlon XP"
  },
  {
    valueId: "EV005772",
    valueDesc: "Cyrix",
    label: "EV005772: Cyrix"
  },
  {
    valueId: "EV005773",
    valueDesc: "Windows 9x",
    label: "EV005773: Windows 9x"
  },
  {
    valueId: "EV005774",
    valueDesc: "Windows NT",
    label: "EV005774: Windows NT"
  },
  {
    valueId: "EV005775",
    valueDesc: "Embedded Windows NT",
    label: "EV005775: Embedded Windows NT"
  },
  {
    valueId: "EV005776",
    valueDesc: "Embedded Windows XP",
    label: "EV005776: Embedded Windows XP"
  },
  {
    valueId: "EV005777",
    valueDesc: "Windows 2000",
    label: "EV005777: Windows 2000"
  },
  {
    valueId: "EV005778",
    valueDesc: "Windows XP",
    label: "EV005778: Windows XP"
  },
  {
    valueId: "EV005779",
    valueDesc: "Windows ME",
    label: "EV005779: Windows ME"
  },
  {
    valueId: "EV005780",
    valueDesc: "Windows CE",
    label: "EV005780: Windows CE"
  },
  {
    valueId: "EV005781",
    valueDesc: "Linux",
    label: "EV005781: Linux"
  },
  {
    valueId: "EV005782",
    valueDesc: "SMATV-system",
    label: "EV005782: SMATV-system"
  },
  {
    valueId: "EV005783",
    valueDesc: "Portable device",
    label: "EV005783: Portable device"
  },
  {
    valueId: "EV005784",
    valueDesc: "Notebook",
    label: "EV005784: Notebook"
  },
  {
    valueId: "EV005785",
    valueDesc: "Line oriented",
    label: "EV005785: Line oriented"
  },
  {
    valueId: "EV005786",
    valueDesc: "Graphic oriented",
    label: "EV005786: Graphic oriented"
  },
  {
    valueId: "EV005787",
    valueDesc: "Not required",
    label: "EV005787: Not required"
  },
  {
    valueId: "EV005788",
    valueDesc: "Enclosed",
    label: "EV005788: Enclosed"
  },
  {
    valueId: "EV005789",
    valueDesc: "0/14/18 V 22 kHz",
    label: "EV005789: 0/14/18 V 22 kHz"
  },
  {
    valueId: "EV005790",
    valueDesc: "0/14/18 V DiSEqC",
    label: "EV005790: 0/14/18 V DiSEqC"
  },
  {
    valueId: "EV005791",
    valueDesc: "Premiere",
    label: "EV005791: Premiere"
  },
  {
    valueId: "EV005792",
    valueDesc: "Viaccess",
    label: "EV005792: Viaccess"
  },
  {
    valueId: "EV005793",
    valueDesc: "Irdeto",
    label: "EV005793: Irdeto"
  },
  {
    valueId: "EV005794",
    valueDesc: "PC interface",
    label: "EV005794: PC interface"
  },
  {
    valueId: "EV005799",
    valueDesc: "Module rack extension",
    label: "EV005799: Module rack extension"
  },
  {
    valueId: "EV005800",
    valueDesc: "Broadband amplifier",
    label: "EV005800: Broadband amplifier"
  },
  {
    valueId: "EV005803",
    valueDesc: "OPC server",
    label: "EV005803: OPC server"
  },
  {
    valueId: "EV005804",
    valueDesc: "Active X",
    label: "EV005804: Active X"
  },
  {
    valueId: "EV005805",
    valueDesc: "PCI+",
    label: "EV005805: PCI+"
  },
  {
    valueId: "EV005806",
    valueDesc: "14/18 V, DiSEqC, 0/22 kHz",
    label: "EV005806: 14/18 V, DiSEqC, 0/22 kHz"
  },
  {
    valueId: "EV005807",
    valueDesc: "0/12 V",
    label: "EV005807: 0/12 V"
  },
  {
    valueId: "EV005808",
    valueDesc: "Vacuum fluorescence",
    label: "EV005808: Vacuum fluorescence"
  },
  {
    valueId: "EV005809",
    valueDesc: "LCD with background illumination",
    label: "EV005809: LCD with background illumination"
  },
  {
    valueId: "EV005810",
    valueDesc: "LCD without background illumination",
    label: "EV005810: LCD without background illumination"
  },
  {
    valueId: "EV005811",
    valueDesc: "Sat range diplexer/filter",
    label: "EV005811: Sat range diplexer/filter"
  },
  {
    valueId: "EV005812",
    valueDesc: "Feed-in diplexer SAT/terrestrial",
    label: "EV005812: Feed-in diplexer SAT/terrestrial"
  },
  {
    valueId: "EV005813",
    valueDesc: "Remote feed diplexer",
    label: "EV005813: Remote feed diplexer"
  },
  {
    valueId: "EV005814",
    valueDesc: "Trap filter",
    label: "EV005814: Trap filter"
  },
  {
    valueId: "EV005815",
    valueDesc: "CRT",
    label: "EV005815: CRT"
  },
  {
    valueId: "EV005816",
    valueDesc: "1-channel",
    label: "EV005816: 1-channel"
  },
  {
    valueId: "EV005817",
    valueDesc: "2-channel",
    label: "EV005817: 2-channel"
  },
  {
    valueId: "EV005818",
    valueDesc: "3-channel",
    label: "EV005818: 3-channel"
  },
  {
    valueId: "EV005819",
    valueDesc: "Polarized",
    label: "EV005819: Polarized"
  },
  {
    valueId: "EV005820",
    valueDesc: "Polarity free",
    label: "EV005820: Polarity free"
  },
  {
    valueId: "EV005821",
    valueDesc: "Mono stable",
    label: "EV005821: Mono stable"
  },
  {
    valueId: "EV005822",
    valueDesc: "Bistable",
    label: "EV005822: Bistable"
  },
  {
    valueId: "EV005823",
    valueDesc: "Remanence",
    label: "EV005823: Remanence"
  },
  {
    valueId: "EV005824",
    valueDesc: "Single contact",
    label: "EV005824: Single contact"
  },
  {
    valueId: "EV005825",
    valueDesc: "Double contact",
    label: "EV005825: Double contact"
  },
  {
    valueId: "EV005827",
    valueDesc: "For AC-current system",
    label: "EV005827: For AC-current system"
  },
  {
    valueId: "EV005828",
    valueDesc: "For three-phase supply system",
    label: "EV005828: For three-phase supply system"
  },
  {
    valueId: "EV005829",
    valueDesc: "For DC-current system",
    label: "EV005829: For DC-current system"
  },
  {
    valueId: "EV005830",
    valueDesc: "For mixed system",
    label: "EV005830: For mixed system"
  },
  {
    valueId: "EV005834",
    valueDesc: "Type III A",
    label: "EV005834: Type III A"
  },
  {
    valueId: "EV005835",
    valueDesc: "Type III B",
    label: "EV005835: Type III B"
  },
  {
    valueId: "EV005836",
    valueDesc: "Type III C",
    label: "EV005836: Type III C"
  },
  {
    valueId: "EV005843",
    valueDesc: "IBM cabling system",
    label: "EV005843: IBM cabling system"
  },
  {
    valueId: "EV005854",
    valueDesc: "Hinged lid",
    label: "EV005854: Hinged lid"
  },
  {
    valueId: "EV005856",
    valueDesc: "20x5 mm",
    label: "EV005856: 20x5 mm"
  },
  {
    valueId: "EV005858",
    valueDesc: "DIN rail (top hat rail) 32 mm",
    label: "EV005858: DIN rail (top hat rail) 32 mm"
  },
  {
    valueId: "EV005864",
    valueDesc: "U",
    label: "EV005864: U"
  },
  {
    valueId: "EV005865",
    valueDesc: "Rod",
    label: "EV005865: Rod"
  },
  {
    valueId: "EV005866",
    valueDesc: "Standard",
    label: "EV005866: Standard"
  },
  {
    valueId: "EV005869",
    valueDesc: "Shutter",
    label: "EV005869: Shutter"
  },
  {
    valueId: "EV005887",
    valueDesc: "Without protective contact",
    label: "EV005887: Without protective contact"
  },
  {
    valueId: "EV005891",
    valueDesc: "Earthing pin",
    label: "EV005891: Earthing pin"
  },
  {
    valueId: "EV005897",
    valueDesc: "M12 x 1.5",
    label: "EV005897: M12 x 1.5"
  },
  {
    valueId: "EV005898",
    valueDesc: "M16 x 1.5",
    label: "EV005898: M16 x 1.5"
  },
  {
    valueId: "EV005899",
    valueDesc: "M20 x 1.5",
    label: "EV005899: M20 x 1.5"
  },
  {
    valueId: "EV005900",
    valueDesc: "M25 x 1.5",
    label: "EV005900: M25 x 1.5"
  },
  {
    valueId: "EV005901",
    valueDesc: "M32 x 1.5",
    label: "EV005901: M32 x 1.5"
  },
  {
    valueId: "EV005902",
    valueDesc: "M40 x 1.5",
    label: "EV005902: M40 x 1.5"
  },
  {
    valueId: "EV005903",
    valueDesc: "M50 x 1.5",
    label: "EV005903: M50 x 1.5"
  },
  {
    valueId: "EV005908",
    valueDesc: "Polyamide, glass fibre reinforced",
    label: "EV005908: Polyamide, glass fibre reinforced"
  },
  {
    valueId: "EV005911",
    valueDesc: "With thread",
    label: "EV005911: With thread"
  },
  {
    valueId: "EV005912",
    valueDesc: "Telco 50-pole",
    label: "EV005912: Telco 50-pole"
  },
  {
    valueId: "EV005913",
    valueDesc: "Polyvinyl chloride (PVC), oil durable",
    label: "EV005913: Polyvinyl chloride (PVC), oil durable"
  },
  {
    valueId: "EV005915",
    valueDesc: "45°-Bend",
    label: "EV005915: 45°-Bend"
  },
  {
    valueId: "EV005916",
    valueDesc: "90°-Bend",
    label: "EV005916: 90°-Bend"
  },
  {
    valueId: "EV005917",
    valueDesc: "45°-elbow",
    label: "EV005917: 45°-elbow"
  },
  {
    valueId: "EV005918",
    valueDesc: "90°-elbow",
    label: "EV005918: 90°-elbow"
  },
  {
    valueId: "EV005920",
    valueDesc: "Fibre optic conductor",
    label: "EV005920: Fibre optic conductor"
  },
  {
    valueId: "EV005922",
    valueDesc: "DN 32",
    label: "EV005922: DN 32"
  },
  {
    valueId: "EV005923",
    valueDesc: "Supply-/exhaust air set for central air supply",
    label: "EV005923: Supply-/exhaust air set for central air supply"
  },
  {
    valueId: "EV005924",
    valueDesc: "Supply-/exhaust air set for decentralized air supply",
    label: "EV005924: Supply-/exhaust air set for decentralized air supply"
  },
  {
    valueId: "EV005925",
    valueDesc: "Exhaust air set",
    label: "EV005925: Exhaust air set"
  },
  {
    valueId: "EV005926",
    valueDesc: "Exhaust-/open air set",
    label: "EV005926: Exhaust-/open air set"
  },
  {
    valueId: "EV005953",
    valueDesc: "10 mm",
    label: "EV005953: 10 mm"
  },
  {
    valueId: "EV005965",
    valueDesc: "Armouring",
    label: "EV005965: Armouring"
  },
  {
    valueId: "EV005968",
    valueDesc: "Metallic",
    label: "EV005968: Metallic"
  },
  {
    valueId: "EV005986",
    valueDesc: "Rotating axis",
    label: "EV005986: Rotating axis"
  },
  {
    valueId: "EV005987",
    valueDesc: "Without actuator",
    label: "EV005987: Without actuator"
  },
  {
    valueId: "EV005991",
    valueDesc: "Incremental encoder",
    label: "EV005991: Incremental encoder"
  },
  {
    valueId: "EV005993",
    valueDesc: "A+B",
    label: "EV005993: A+B"
  },
  {
    valueId: "EV005994",
    valueDesc: "A+B+0",
    label: "EV005994: A+B+0"
  },
  {
    valueId: "EV006006",
    valueDesc: "Desk type",
    label: "EV006006: Desk type"
  },
  {
    valueId: "EV006007",
    valueDesc: "Wall duct installation",
    label: "EV006007: Wall duct installation"
  },
  {
    valueId: "EV006008",
    valueDesc: "CuNi",
    label: "EV006008: CuNi"
  },
  {
    valueId: "EV006010",
    valueDesc: "Printer heads",
    label: "EV006010: Printer heads"
  },
  {
    valueId: "EV006011",
    valueDesc: "Anti-static",
    label: "EV006011: Anti-static"
  },
  {
    valueId: "EV006013",
    valueDesc: "Field/field",
    label: "EV006013: Field/field"
  },
  {
    valueId: "EV006014",
    valueDesc: "Cabinet/cabinet",
    label: "EV006014: Cabinet/cabinet"
  },
  {
    valueId: "EV006015",
    valueDesc: "Housing/housing",
    label: "EV006015: Housing/housing"
  },
  {
    valueId: "EV006016",
    valueDesc: "Audio-CD-RW",
    label: "EV006016: Audio-CD-RW"
  },
  {
    valueId: "EV006018",
    valueDesc: "Subwoofer",
    label: "EV006018: Subwoofer"
  },
  {
    valueId: "EV006020",
    valueDesc: "Cassette recorder",
    label: "EV006020: Cassette recorder"
  },
  {
    valueId: "EV006028",
    valueDesc: "Pendant",
    label: "EV006028: Pendant"
  },
  {
    valueId: "EV006032",
    valueDesc: "Floor heating",
    label: "EV006032: Floor heating"
  },
  {
    valueId: "EV006034",
    valueDesc: "Untreated",
    label: "EV006034: Untreated"
  },
  {
    valueId: "EV006035",
    valueDesc: "Countersunk rounded head",
    label: "EV006035: Countersunk rounded head"
  },
  {
    valueId: "EV006036",
    valueDesc: "Set",
    label: "EV006036: Set"
  },
  {
    valueId: "EV006037",
    valueDesc: "Downwards",
    label: "EV006037: Downwards"
  },
  {
    valueId: "EV006039",
    valueDesc: "Coaxial antenna socket outlet",
    label: "EV006039: Coaxial antenna socket outlet"
  },
  {
    valueId: "EV006040",
    valueDesc: "Connection cabinet",
    label: "EV006040: Connection cabinet"
  },
  {
    valueId: "EV006041",
    valueDesc: "Connection-/distributor cabinet",
    label: "EV006041: Connection-/distributor cabinet"
  },
  {
    valueId: "EV006043",
    valueDesc: "T1",
    label: "EV006043: T1"
  },
  {
    valueId: "EV006050",
    valueDesc: "1 change over switch",
    label: "EV006050: 1 change over switch"
  },
  {
    valueId: "EV006051",
    valueDesc: "2 change over switches",
    label: "EV006051: 2 change over switches"
  },
  {
    valueId: "EV006052",
    valueDesc: "1 daily/weekly contact",
    label: "EV006052: 1 daily/weekly contact"
  },
  {
    valueId: "EV006066",
    valueDesc: "Trunk line (local loop) extension",
    label: "EV006066: Trunk line (local loop) extension"
  },
  {
    valueId: "EV006067",
    valueDesc: "Audio recorder",
    label: "EV006067: Audio recorder"
  },
  {
    valueId: "EV006070",
    valueDesc: "Point-to-point connection module",
    label: "EV006070: Point-to-point connection module"
  },
  {
    valueId: "EV006073",
    valueDesc: "Mobile module",
    label: "EV006073: Mobile module"
  },
  {
    valueId: "EV006074",
    valueDesc: "Substation extension",
    label: "EV006074: Substation extension"
  },
  {
    valueId: "EV006077",
    valueDesc: "Software package",
    label: "EV006077: Software package"
  },
  {
    valueId: "EV006078",
    valueDesc: "Substation change over switch",
    label: "EV006078: Substation change over switch"
  },
  {
    valueId: "EV006079",
    valueDesc: "System display",
    label: "EV006079: System display"
  },
  {
    valueId: "EV006084",
    valueDesc: "Dialling device",
    label: "EV006084: Dialling device"
  },
  {
    valueId: "EV006085",
    valueDesc: "Installation module",
    label: "EV006085: Installation module"
  },
  {
    valueId: "EV006087",
    valueDesc: "In branch box",
    label: "EV006087: In branch box"
  },
  {
    valueId: "EV006088",
    valueDesc: "In junction box",
    label: "EV006088: In junction box"
  },
  {
    valueId: "EV006093",
    valueDesc: "Vibration detector",
    label: "EV006093: Vibration detector"
  },
  {
    valueId: "EV006099",
    valueDesc: "Granulate",
    label: "EV006099: Granulate"
  },
  {
    valueId: "EV006100",
    valueDesc: "ABC-keyboard",
    label: "EV006100: ABC-keyboard"
  },
  {
    valueId: "EV006103",
    valueDesc: "Leather",
    label: "EV006103: Leather"
  },
  {
    valueId: "EV006104",
    valueDesc: "Leather/metal",
    label: "EV006104: Leather/metal"
  },
  {
    valueId: "EV006110",
    valueDesc: "Cubic",
    label: "EV006110: Cubic"
  },
  {
    valueId: "EV006114",
    valueDesc: "PNP/NPN",
    label: "EV006114: PNP/NPN"
  },
  {
    valueId: "EV006116",
    valueDesc: "Magnetic",
    label: "EV006116: Magnetic"
  },
  {
    valueId: "EV006120",
    valueDesc: "Wire",
    label: "EV006120: Wire"
  },
  {
    valueId: "EV006121",
    valueDesc: "Stripper/skinner",
    label: "EV006121: Stripper/skinner"
  },
  {
    valueId: "EV006123",
    valueDesc: "Concrete building",
    label: "EV006123: Concrete building"
  },
  {
    valueId: "EV006124",
    valueDesc: "Tube",
    label: "EV006124: Tube"
  },
  {
    valueId: "EV006125",
    valueDesc: "Coins",
    label: "EV006125: Coins"
  },
  {
    valueId: "EV006128",
    valueDesc: "Steel varnished",
    label: "EV006128: Steel varnished"
  },
  {
    valueId: "EV006133",
    valueDesc: "Sand-proof",
    label: "EV006133: Sand-proof"
  },
  {
    valueId: "EV006134",
    valueDesc: "Watertight",
    label: "EV006134: Watertight"
  },
  {
    valueId: "EV006135",
    valueDesc: "Twofold",
    label: "EV006135: Twofold"
  },
  {
    valueId: "EV006136",
    valueDesc: "Fourfold",
    label: "EV006136: Fourfold"
  },
  {
    valueId: "EV006137",
    valueDesc: "Six fold",
    label: "EV006137: Six fold"
  },
  {
    valueId: "EV006138",
    valueDesc: "With terminal strip",
    label: "EV006138: With terminal strip"
  },
  {
    valueId: "EV006139",
    valueDesc: "With solder eyelets",
    label: "EV006139: With solder eyelets"
  },
  {
    valueId: "EV006140",
    valueDesc: "With cutting-clamp strip",
    label: "EV006140: With cutting-clamp strip"
  },
  {
    valueId: "EV006143",
    valueDesc: "Pliable corrugated with synthetic sheath",
    label: "EV006143: Pliable corrugated with synthetic sheath"
  },
  {
    valueId: "EV006146",
    valueDesc: "Slot",
    label: "EV006146: Slot"
  },
  {
    valueId: "EV006147",
    valueDesc: "EPR",
    label: "EV006147: EPR"
  },
  {
    valueId: "EV006166",
    valueDesc: "Parallel branch",
    label: "EV006166: Parallel branch"
  },
  {
    valueId: "EV006167",
    valueDesc: "III",
    label: "EV006167: III"
  },
  {
    valueId: "EV006170",
    valueDesc: "Phase cut-on",
    label: "EV006170: Phase cut-on"
  },
  {
    valueId: "EV006171",
    valueDesc: "Phase cut-off",
    label: "EV006171: Phase cut-off"
  },
  {
    valueId: "EV006179",
    valueDesc: "Conductor bar",
    label: "EV006179: Conductor bar"
  },
  {
    valueId: "EV006183",
    valueDesc: "Safety transformer",
    label: "EV006183: Safety transformer"
  },
  {
    valueId: "EV006185",
    valueDesc: "1-phase electronic adapter transformer",
    label: "EV006185: 1-phase electronic adapter transformer"
  },
  {
    valueId: "EV006186",
    valueDesc: "3-phase electronic adapter transformer",
    label: "EV006186: 3-phase electronic adapter transformer"
  },
  {
    valueId: "EV006189",
    valueDesc: "Phase cut-off/cut-on",
    label: "EV006189: Phase cut-off/cut-on"
  },
  {
    valueId: "EV006192",
    valueDesc: "1 mounting rail",
    label: "EV006192: 1 mounting rail"
  },
  {
    valueId: "EV006193",
    valueDesc: "2 mounting rails",
    label: "EV006193: 2 mounting rails"
  },
  {
    valueId: "EV006194",
    valueDesc: "3 conductors AWG 12",
    label: "EV006194: 3 conductors AWG 12"
  },
  {
    valueId: "EV006195",
    valueDesc: "Round conductor/copper band",
    label: "EV006195: Round conductor/copper band"
  },
  {
    valueId: "EV006196",
    valueDesc: "Round conductor/laminated copper rail",
    label: "EV006196: Round conductor/laminated copper rail"
  },
  {
    valueId: "EV006197",
    valueDesc: "Laminated copper rail",
    label: "EV006197: Laminated copper rail"
  },
  {
    valueId: "EV006198",
    valueDesc: "3 conductors AWG 10",
    label: "EV006198: 3 conductors AWG 10"
  },
  {
    valueId: "EV006199",
    valueDesc: "3 conductors AWG 10/3 clamps 10 mm²",
    label: "EV006199: 3 conductors AWG 10/3 clamps 10 mm²"
  },
  {
    valueId: "EV006200",
    valueDesc: "Electronics cabinet",
    label: "EV006200: Electronics cabinet"
  },
  {
    valueId: "EV006201",
    valueDesc: "Shelf",
    label: "EV006201: Shelf"
  },
  {
    valueId: "EV006202",
    valueDesc: "Swing frame",
    label: "EV006202: Swing frame"
  },
  {
    valueId: "EV006203",
    valueDesc: "Connection system",
    label: "EV006203: Connection system"
  },
  {
    valueId: "EV006204",
    valueDesc: "Network cabinet",
    label: "EV006204: Network cabinet"
  },
  {
    valueId: "EV006237",
    valueDesc: "CTG G",
    label: "EV006237: CTG G"
  },
  {
    valueId: "EV006238",
    valueDesc: "UNEF",
    label: "EV006238: UNEF"
  },
  {
    valueId: "EV006239",
    valueDesc: "1/4 inch",
    label: "EV006239: 1/4 inch"
  },
  {
    valueId: "EV006240",
    valueDesc: "5/16 inch",
    label: "EV006240: 5/16 inch"
  },
  {
    valueId: "EV006242",
    valueDesc: "1 3/4 inch",
    label: "EV006242: 1 3/4 inch"
  },
  {
    valueId: "EV006243",
    valueDesc: "1 3/8 inch",
    label: "EV006243: 1 3/8 inch"
  },
  {
    valueId: "EV006244",
    valueDesc: "1 7/16 inch",
    label: "EV006244: 1 7/16 inch"
  },
  {
    valueId: "EV006245",
    valueDesc: "15/16 inch",
    label: "EV006245: 15/16 inch"
  },
  {
    valueId: "EV006246",
    valueDesc: "5/8 inch",
    label: "EV006246: 5/8 inch"
  },
  {
    valueId: "EV006247",
    valueDesc: "7/8 inch",
    label: "EV006247: 7/8 inch"
  },
  {
    valueId: "EV006249",
    valueDesc: "IEEE 802.11 b",
    label: "EV006249: IEEE 802.11 b"
  },
  {
    valueId: "EV006251",
    valueDesc: "Bluetooth",
    label: "EV006251: Bluetooth"
  },
  {
    valueId: "EV006253",
    valueDesc: "Push cart",
    label: "EV006253: Push cart"
  },
  {
    valueId: "EV006254",
    valueDesc: "Roll board",
    label: "EV006254: Roll board"
  },
  {
    valueId: "EV006255",
    valueDesc: "C-profile",
    label: "EV006255: C-profile"
  },
  {
    valueId: "EV006259",
    valueDesc: "Control switch",
    label: "EV006259: Control switch"
  },
  {
    valueId: "EV006260",
    valueDesc: "Venetian blind switch",
    label: "EV006260: Venetian blind switch"
  },
  {
    valueId: "EV006262",
    valueDesc: "Standard bell ringing transformer",
    label: "EV006262: Standard bell ringing transformer"
  },
  {
    valueId: "EV006263",
    valueDesc: "Mains transformer",
    label: "EV006263: Mains transformer"
  },
  {
    valueId: "EV006264",
    valueDesc: "Parallel capacitor for L-lamp",
    label: "EV006264: Parallel capacitor for L-lamp"
  },
  {
    valueId: "EV006265",
    valueDesc: "Series capacitor for L-lamp",
    label: "EV006265: Series capacitor for L-lamp"
  },
  {
    valueId: "EV006266",
    valueDesc: "Motor operating capacitor",
    label: "EV006266: Motor operating capacitor"
  },
  {
    valueId: "EV006270",
    valueDesc: "Iron core transformer",
    label: "EV006270: Iron core transformer"
  },
  {
    valueId: "EV006272",
    valueDesc: "Key switch",
    label: "EV006272: Key switch"
  },
  {
    valueId: "EV006273",
    valueDesc: "100 g",
    label: "EV006273: 100 g"
  },
  {
    valueId: "EV006274",
    valueDesc: "200 g",
    label: "EV006274: 200 g"
  },
  {
    valueId: "EV006275",
    valueDesc: "500 g",
    label: "EV006275: 500 g"
  },
  {
    valueId: "EV006276",
    valueDesc: "1 kg",
    label: "EV006276: 1 kg"
  },
  {
    valueId: "EV006278",
    valueDesc: "Cable lug",
    label: "EV006278: Cable lug"
  },
  {
    valueId: "EV006280",
    valueDesc: "Europlug (CEE 7/16)",
    label: "EV006280: Europlug (CEE 7/16)"
  },
  {
    valueId: "EV006283",
    valueDesc: "Euro-plug, female (CEE 7/16)",
    label: "EV006283: Euro-plug, female (CEE 7/16)"
  },
  {
    valueId: "EV006287",
    valueDesc: "Coupler with protective contact CEE 7/3 (type F)",
    label: "EV006287: Coupler with protective contact CEE 7/3 (type F)"
  },
  {
    valueId: "EV006289",
    valueDesc: "Bundle",
    label: "EV006289: Bundle"
  },
  {
    valueId: "EV006290",
    valueDesc: "Colour + rings",
    label: "EV006290: Colour + rings"
  },
  {
    valueId: "EV006291",
    valueDesc: "Foamed polypropylene",
    label: "EV006291: Foamed polypropylene"
  },
  {
    valueId: "EV006292",
    valueDesc: "Rubber - chlorinated/chlorosulfonated polyethylene (CPE/CSP)",
    label: "EV006292: Rubber - chlorinated/chlorosulfonated polyethylene (CPE/CSP)"
  },
  {
    valueId: "EV006293",
    valueDesc: "Rubber - Hard grade Ethylene Propylene Rubber (HEPR)",
    label: "EV006293: Rubber - Hard grade Ethylene Propylene Rubber (HEPR)"
  },
  {
    valueId: "EV006294",
    valueDesc: "Class D = flexible",
    label: "EV006294: Class D = flexible"
  },
  {
    valueId: "EV006295",
    valueDesc: "Class E = very flexible",
    label: "EV006295: Class E = very flexible"
  },
  {
    valueId: "EV006296",
    valueDesc: "Semi tight",
    label: "EV006296: Semi tight"
  },
  {
    valueId: "EV006297",
    valueDesc: "Layers",
    label: "EV006297: Layers"
  },
  {
    valueId: "EV006301",
    valueDesc: "Mica + crosslinked polyethylene (XLPE)",
    label: "EV006301: Mica + crosslinked polyethylene (XLPE)"
  },
  {
    valueId: "EV006303",
    valueDesc: "According to IEC/EN 60332-3-22",
    label: "EV006303: According to IEC/EN 60332-3-22"
  },
  {
    valueId: "EV006304",
    valueDesc: "Pairs",
    label: "EV006304: Pairs"
  },
  {
    valueId: "EV006313",
    valueDesc: "Crosslinked polyethylene (XLPE)",
    label: "EV006313: Crosslinked polyethylene (XLPE)"
  },
  {
    valueId: "EV006316",
    valueDesc: "Al/PE",
    label: "EV006316: Al/PE"
  },
  {
    valueId: "EV006317",
    valueDesc: "1 make contact (NO), 1 breaker contact (NC)",
    label: "EV006317: 1 make contact (NO), 1 breaker contact (NC)"
  },
  {
    valueId: "EV006318",
    valueDesc: "10x2.5 mm²",
    label: "EV006318: 10x2.5 mm²"
  },
  {
    valueId: "EV006319",
    valueDesc: "12x2.5 mm²",
    label: "EV006319: 12x2.5 mm²"
  },
  {
    valueId: "EV006320",
    valueDesc: "13x2.5 mm²",
    label: "EV006320: 13x2.5 mm²"
  },
  {
    valueId: "EV006321",
    valueDesc: "15x2.5 mm²",
    label: "EV006321: 15x2.5 mm²"
  },
  {
    valueId: "EV006325",
    valueDesc: "3-pole",
    label: "EV006325: 3-pole"
  },
  {
    valueId: "EV006326",
    valueDesc: "3x1-pole",
    label: "EV006326: 3x1-pole"
  },
  {
    valueId: "EV006328",
    valueDesc: "4x1-pole",
    label: "EV006328: 4x1-pole"
  },
  {
    valueId: "EV006333",
    valueDesc: "Acoustic sensor",
    label: "EV006333: Acoustic sensor"
  },
  {
    valueId: "EV006335",
    valueDesc: "American National Standard",
    label: "EV006335: American National Standard"
  },
  {
    valueId: "EV006336",
    valueDesc: "AMP-ACO",
    label: "EV006336: AMP-ACO"
  },
  {
    valueId: "EV006337",
    valueDesc: "Surface mounted box",
    label: "EV006337: Surface mounted box"
  },
  {
    valueId: "EV006338",
    valueDesc: "Surface mounted housing presence indicator",
    label: "EV006338: Surface mounted housing presence indicator"
  },
  {
    valueId: "EV006339",
    valueDesc: "Surface mounted frame",
    label: "EV006339: Surface mounted frame"
  },
  {
    valueId: "EV006340",
    valueDesc: "Surface mounted frame with loose bottom plate",
    label: "EV006340: Surface mounted frame with loose bottom plate"
  },
  {
    valueId: "EV006342",
    valueDesc: "Off switch 2x1-pole",
    label: "EV006342: Off switch 2x1-pole"
  },
  {
    valueId: "EV006343",
    valueDesc: "Off switch 3x1-pole",
    label: "EV006343: Off switch 3x1-pole"
  },
  {
    valueId: "EV006345",
    valueDesc: "Basic element with central cover plate",
    label: "EV006345: Basic element with central cover plate"
  },
  {
    valueId: "EV006346",
    valueDesc: "Basic element with adapter plate",
    label: "EV006346: Basic element with adapter plate"
  },
  {
    valueId: "EV006347",
    valueDesc: "Basic element with housing top",
    label: "EV006347: Basic element with housing top"
  },
  {
    valueId: "EV006348",
    valueDesc: "Basic element with complete housing",
    label: "EV006348: Basic element with complete housing"
  },
  {
    valueId: "EV006349",
    valueDesc: "Basic element with full cover plate",
    label: "EV006349: Basic element with full cover plate"
  },
  {
    valueId: "EV006350",
    valueDesc: "Control end-piece",
    label: "EV006350: Control end-piece"
  },
  {
    valueId: "EV006351",
    valueDesc: "Control element",
    label: "EV006351: Control element"
  },
  {
    valueId: "EV006352",
    valueDesc: "Mounting with claw",
    label: "EV006352: Mounting with claw"
  },
  {
    valueId: "EV006353",
    valueDesc: "Mounting with claw and screw",
    label: "EV006353: Mounting with claw and screw"
  },
  {
    valueId: "EV006354",
    valueDesc: "Mounting with screw",
    label: "EV006354: Mounting with screw"
  },
  {
    valueId: "EV006356",
    valueDesc: "Lighting cap flat",
    label: "EV006356: Lighting cap flat"
  },
  {
    valueId: "EV006357",
    valueDesc: "Lighting bonnet high",
    label: "EV006357: Lighting bonnet high"
  },
  {
    valueId: "EV006358",
    valueDesc: "Imprintable sheet",
    label: "EV006358: Imprintable sheet"
  },
  {
    valueId: "EV006359",
    valueDesc: "Imprintable label snap-in in intermediate ring",
    label: "EV006359: Imprintable label snap-in in intermediate ring"
  },
  {
    valueId: "EV006360",
    valueDesc: "Imprinting aid",
    label: "EV006360: Imprinting aid"
  },
  {
    valueId: "EV006361",
    valueDesc: "Biometric",
    label: "EV006361: Biometric"
  },
  {
    valueId: "EV006362",
    valueDesc: "Aperture",
    label: "EV006362: Aperture"
  },
  {
    valueId: "EV006363",
    valueDesc: "Bluetooth interface",
    label: "EV006363: Bluetooth interface"
  },
  {
    valueId: "EV006364",
    valueDesc: "Bottom plate for wall sender",
    label: "EV006364: Bottom plate for wall sender"
  },
  {
    valueId: "EV006365",
    valueDesc: "British Standard",
    label: "EV006365: British Standard"
  },
  {
    valueId: "EV006366",
    valueDesc: "CO2-Sensor",
    label: "EV006366: CO2-Sensor"
  },
  {
    valueId: "EV006367",
    valueDesc: "Code lock",
    label: "EV006367: Code lock"
  },
  {
    valueId: "EV006369",
    valueDesc: "Data",
    label: "EV006369: Data"
  },
  {
    valueId: "EV006370",
    valueDesc: "Data rail cover",
    label: "EV006370: Data rail cover"
  },
  {
    valueId: "EV006371",
    valueDesc: "Continuance",
    label: "EV006371: Continuance"
  },
  {
    valueId: "EV006372",
    valueDesc: "Ceiling-/installation bracket",
    label: "EV006372: Ceiling-/installation bracket"
  },
  {
    valueId: "EV006373",
    valueDesc: "Dimmer incl. transformer",
    label: "EV006373: Dimmer incl. transformer"
  },
  {
    valueId: "EV006374",
    valueDesc: "3-pole switch",
    label: "EV006374: 3-pole switch"
  },
  {
    valueId: "EV006375",
    valueDesc: "Three-part rocker",
    label: "EV006375: Three-part rocker"
  },
  {
    valueId: "EV006376",
    valueDesc: "Dupline",
    label: "EV006376: Dupline"
  },
  {
    valueId: "EV006378",
    valueDesc: "Corner adapter",
    label: "EV006378: Corner adapter"
  },
  {
    valueId: "EV006379",
    valueDesc: "EDP (Electronic data processing)",
    label: "EV006379: EDP (Electronic data processing)"
  },
  {
    valueId: "EV006380",
    valueDesc: "One",
    label: "EV006380: One"
  },
  {
    valueId: "EV006382",
    valueDesc: "Miniature fuse",
    label: "EV006382: Miniature fuse"
  },
  {
    valueId: "EV006383",
    valueDesc: "Remote control",
    label: "EV006383: Remote control"
  },
  {
    valueId: "EV006385",
    valueDesc: "Blade terminal",
    label: "EV006385: Blade terminal"
  },
  {
    valueId: "EV006386",
    valueDesc: "Radio module",
    label: "EV006386: Radio module"
  },
  {
    valueId: "EV006387",
    valueDesc: "Radio interface manufacturer specific",
    label: "EV006387: Radio interface manufacturer specific"
  },
  {
    valueId: "EV006388",
    valueDesc: "For data connection housing",
    label: "EV006388: For data connection housing"
  },
  {
    valueId: "EV006392",
    valueDesc: "For spacer ring",
    label: "EV006392: For spacer ring"
  },
  {
    valueId: "EV006394",
    valueDesc: "Housing base",
    label: "EV006394: Housing base"
  },
  {
    valueId: "EV006395",
    valueDesc: "Glossy",
    label: "EV006395: Glossy"
  },
  {
    valueId: "EV006396",
    valueDesc: "Glass-breakage sensor",
    label: "EV006396: Glass-breakage sensor"
  },
  {
    valueId: "EV006398",
    valueDesc: "Manual transmitter",
    label: "EV006398: Manual transmitter"
  },
  {
    valueId: "EV006399",
    valueDesc: "Manual transmitter for movement sensor",
    label: "EV006399: Manual transmitter for movement sensor"
  },
  {
    valueId: "EV006400",
    valueDesc: "Brightness sensor",
    label: "EV006400: Brightness sensor"
  },
  {
    valueId: "EV006401",
    valueDesc: "HNA",
    label: "EV006401: HNA"
  },
  {
    valueId: "EV006402",
    valueDesc: "IHC",
    label: "EV006402: IHC"
  },
  {
    valueId: "EV006403",
    valueDesc: "Inductive load",
    label: "EV006403: Inductive load"
  },
  {
    valueId: "EV006404",
    valueDesc: "IP00",
    label: "EV006404: IP00"
  },
  {
    valueId: "EV006405",
    valueDesc: "IP20",
    label: "EV006405: IP20"
  },
  {
    valueId: "EV006406",
    valueDesc: "IP21",
    label: "EV006406: IP21"
  },
  {
    valueId: "EV006407",
    valueDesc: "IP23",
    label: "EV006407: IP23"
  },
  {
    valueId: "EV006408",
    valueDesc: "IP24",
    label: "EV006408: IP24"
  },
  {
    valueId: "EV006409",
    valueDesc: "IP2X",
    label: "EV006409: IP2X"
  },
  {
    valueId: "EV006410",
    valueDesc: "IP30",
    label: "EV006410: IP30"
  },
  {
    valueId: "EV006411",
    valueDesc: "IP31",
    label: "EV006411: IP31"
  },
  {
    valueId: "EV006412",
    valueDesc: "IP32",
    label: "EV006412: IP32"
  },
  {
    valueId: "EV006413",
    valueDesc: "IP34",
    label: "EV006413: IP34"
  },
  {
    valueId: "EV006414",
    valueDesc: "IP3X",
    label: "EV006414: IP3X"
  },
  {
    valueId: "EV006415",
    valueDesc: "IP40",
    label: "EV006415: IP40"
  },
  {
    valueId: "EV006416",
    valueDesc: "IP41",
    label: "EV006416: IP41"
  },
  {
    valueId: "EV006417",
    valueDesc: "IP42",
    label: "EV006417: IP42"
  },
  {
    valueId: "EV006418",
    valueDesc: "IP44",
    label: "EV006418: IP44"
  },
  {
    valueId: "EV006419",
    valueDesc: "IP54",
    label: "EV006419: IP54"
  },
  {
    valueId: "EV006420",
    valueDesc: "IP55",
    label: "EV006420: IP55"
  },
  {
    valueId: "EV006421",
    valueDesc: "IP65",
    label: "EV006421: IP65"
  },
  {
    valueId: "EV006422",
    valueDesc: "IP66",
    label: "EV006422: IP66"
  },
  {
    valueId: "EV006423",
    valueDesc: "IP67",
    label: "EV006423: IP67"
  },
  {
    valueId: "EV006425",
    valueDesc: "Capacitive load",
    label: "EV006425: Capacitive load"
  },
  {
    valueId: "EV006426",
    valueDesc: "No special power supply",
    label: "EV006426: No special power supply"
  },
  {
    valueId: "EV006428",
    valueDesc: "Clamp mounting",
    label: "EV006428: Clamp mounting"
  },
  {
    valueId: "EV006429",
    valueDesc: "Combination switch/button",
    label: "EV006429: Combination switch/button"
  },
  {
    valueId: "EV006430",
    valueDesc: "Complete housing",
    label: "EV006430: Complete housing"
  },
  {
    valueId: "EV006431",
    valueDesc: "Complete device",
    label: "EV006431: Complete device"
  },
  {
    valueId: "EV006432",
    valueDesc: "Indicator light",
    label: "EV006432: Indicator light"
  },
  {
    valueId: "EV006435",
    valueDesc: "Intermediate switch",
    label: "EV006435: Intermediate switch"
  },
  {
    valueId: "EV006436",
    valueDesc: "Laser",
    label: "EV006436: Laser"
  },
  {
    valueId: "EV006437",
    valueDesc: "Print panel-solder connection",
    label: "EV006437: Print panel-solder connection"
  },
  {
    valueId: "EV006438",
    valueDesc: "Mast fastening",
    label: "EV006438: Mast fastening"
  },
  {
    valueId: "EV006439",
    valueDesc: "Several",
    label: "EV006439: Several"
  },
  {
    valueId: "EV006440",
    valueDesc: "Several closing devices",
    label: "EV006440: Several closing devices"
  },
  {
    valueId: "EV006441",
    valueDesc: "Multiple interface",
    label: "EV006441: Multiple interface"
  },
  {
    valueId: "EV006442",
    valueDesc: "Multiple sensor",
    label: "EV006442: Multiple sensor"
  },
  {
    valueId: "EV006451",
    valueDesc: "With indication plate",
    label: "EV006451: With indication plate"
  },
  {
    valueId: "EV006455",
    valueDesc: "Mounting housing",
    label: "EV006455: Mounting housing"
  },
  {
    valueId: "EV006456",
    valueDesc: "Mounting frame",
    label: "EV006456: Mounting frame"
  },
  {
    valueId: "EV006457",
    valueDesc: "NEMA",
    label: "EV006457: NEMA"
  },
  {
    valueId: "EV006458",
    valueDesc: "Rain sensor",
    label: "EV006458: Rain sensor"
  },
  {
    valueId: "EV006462",
    valueDesc: "Resistive",
    label: "EV006462: Resistive"
  },
  {
    valueId: "EV006463",
    valueDesc: "Resistive load",
    label: "EV006463: Resistive load"
  },
  {
    valueId: "EV006465",
    valueDesc: "Without indication field",
    label: "EV006465: Without indication field"
  },
  {
    valueId: "EV006466",
    valueDesc: "Orientation",
    label: "EV006466: Orientation"
  },
  {
    valueId: "EV006467",
    valueDesc: "PHC",
    label: "EV006467: PHC"
  },
  {
    valueId: "EV006471",
    valueDesc: "DRA (DIN-rail adapter)",
    label: "EV006471: DRA (DIN-rail adapter)"
  },
  {
    valueId: "EV006473",
    valueDesc: "Relay and HVAC-relay",
    label: "EV006473: Relay and HVAC-relay"
  },
  {
    valueId: "EV006476",
    valueDesc: "Template",
    label: "EV006476: Template"
  },
  {
    valueId: "EV006477",
    valueDesc: "Switch/dimmer",
    label: "EV006477: Switch/dimmer"
  },
  {
    valueId: "EV006478",
    valueDesc: "Circuit 0 - 1- 1+2 - 1+2+3",
    label: "EV006478: Circuit 0 - 1- 1+2 - 1+2+3"
  },
  {
    valueId: "EV006479",
    valueDesc: "Circuit 0 - 1 - 2 - 3",
    label: "EV006479: Circuit 0 - 1 - 2 - 3"
  },
  {
    valueId: "EV006480",
    valueDesc: "Circuit 2 - 1 -3",
    label: "EV006480: Circuit 2 - 1 -3"
  },
  {
    valueId: "EV006481",
    valueDesc: "Slide",
    label: "EV006481: Slide"
  },
  {
    valueId: "EV006482",
    valueDesc: "With pull relief and suspension",
    label: "EV006482: With pull relief and suspension"
  },
  {
    valueId: "EV006483",
    valueDesc: "Normally open contact with feedback contacts",
    label: "EV006483: Normally open contact with feedback contacts"
  },
  {
    valueId: "EV006485",
    valueDesc: "Ethernet interface",
    label: "EV006485: Ethernet interface"
  },
  {
    valueId: "EV006486",
    valueDesc: "Protective cover",
    label: "EV006486: Protective cover"
  },
  {
    valueId: "EV006487",
    valueDesc: "Transmitter insert",
    label: "EV006487: Transmitter insert"
  },
  {
    valueId: "EV006493",
    valueDesc: "Memory medium",
    label: "EV006493: Memory medium"
  },
  {
    valueId: "EV006494",
    valueDesc: "Push-in clamp",
    label: "EV006494: Push-in clamp"
  },
  {
    valueId: "EV006495",
    valueDesc: "Level switch",
    label: "EV006495: Level switch"
  },
  {
    valueId: "EV006496",
    valueDesc: "SV",
    label: "EV006496: SV"
  },
  {
    valueId: "EV006497",
    valueDesc: "Symbol '1'",
    label: "EV006497: Symbol '1'"
  },
  {
    valueId: "EV006498",
    valueDesc: "Symbol 'rotational speed'",
    label: "EV006498: Symbol 'rotational speed'"
  },
  {
    valueId: "EV006499",
    valueDesc: "Symbol '3-level switch'",
    label: "EV006499: Symbol '3-level switch'"
  },
  {
    valueId: "EV006500",
    valueDesc: "Symbol 'arrows'",
    label: "EV006500: Symbol 'arrows'"
  },
  {
    valueId: "EV006502",
    valueDesc: "System-insert",
    label: "EV006502: System-insert"
  },
  {
    valueId: "EV006506",
    valueDesc: "Button",
    label: "EV006506: Button"
  },
  {
    valueId: "EV006507",
    valueDesc: "Button, alternating",
    label: "EV006507: Button, alternating"
  },
  {
    valueId: "EV006508",
    valueDesc: "Tracer push button",
    label: "EV006508: Tracer push button"
  },
  {
    valueId: "EV006509",
    valueDesc: "1-pole switch and push switch",
    label: "EV006509: 1-pole switch and push switch"
  },
  {
    valueId: "EV006510",
    valueDesc: "2-pole switch and push switch",
    label: "EV006510: 2-pole switch and push switch"
  },
  {
    valueId: "EV006511",
    valueDesc: "TCP/IP",
    label: "EV006511: TCP/IP"
  },
  {
    valueId: "EV006512",
    valueDesc: "TERKO",
    label: "EV006512: TERKO"
  },
  {
    valueId: "EV006513",
    valueDesc: "Inkjet",
    label: "EV006513: Inkjet"
  },
  {
    valueId: "EV006514",
    valueDesc: "Inkjet and laser",
    label: "EV006514: Inkjet and laser"
  },
  {
    valueId: "EV006515",
    valueDesc: "Supporting plate",
    label: "EV006515: Supporting plate"
  },
  {
    valueId: "EV006516",
    valueDesc: "Foot controller",
    label: "EV006516: Foot controller"
  },
  {
    valueId: "EV006518",
    valueDesc: "UIP",
    label: "EV006518: UIP"
  },
  {
    valueId: "EV006519",
    valueDesc: "Ultrasonic",
    label: "EV006519: Ultrasonic"
  },
  {
    valueId: "EV006523",
    valueDesc: "Vending aid/display",
    label: "EV006523: Vending aid/display"
  },
  {
    valueId: "EV006524",
    valueDesc: "Extended pipe in-lead",
    label: "EV006524: Extended pipe in-lead"
  },
  {
    valueId: "EV006527",
    valueDesc: "Wall-mounted transmitter",
    label: "EV006527: Wall-mounted transmitter"
  },
  {
    valueId: "EV006528",
    valueDesc: "Wall-mounted transmitter complete",
    label: "EV006528: Wall-mounted transmitter complete"
  },
  {
    valueId: "EV006529",
    valueDesc: "Wall-mounted transmitter insert",
    label: "EV006529: Wall-mounted transmitter insert"
  },
  {
    valueId: "EV006530",
    valueDesc: "Alternating-/alternating switch",
    label: "EV006530: Alternating-/alternating switch"
  },
  {
    valueId: "EV006531",
    valueDesc: "Rocker/button",
    label: "EV006531: Rocker/button"
  },
  {
    valueId: "EV006532",
    valueDesc: "Rocker with mouth rod guiding",
    label: "EV006532: Rocker with mouth rod guiding"
  },
  {
    valueId: "EV006534",
    valueDesc: "WSV",
    label: "EV006534: WSV"
  },
  {
    valueId: "EV006536",
    valueDesc: "Decorative square",
    label: "EV006536: Decorative square"
  },
  {
    valueId: "EV006537",
    valueDesc: "Decorative ring",
    label: "EV006537: Decorative ring"
  },
  {
    valueId: "EV006538",
    valueDesc: "ZSV",
    label: "EV006538: ZSV"
  },
  {
    valueId: "EV006539",
    valueDesc: "Support antenna",
    label: "EV006539: Support antenna"
  },
  {
    valueId: "EV006540",
    valueDesc: "Two-part rocker",
    label: "EV006540: Two-part rocker"
  },
  {
    valueId: "EV006541",
    valueDesc: "Adapter plug",
    label: "EV006541: Adapter plug"
  },
  {
    valueId: "EV006547",
    valueDesc: "Multi mode 200/300",
    label: "EV006547: Multi mode 200/300"
  },
  {
    valueId: "EV006548",
    valueDesc: "Multi mode 980/1000",
    label: "EV006548: Multi mode 980/1000"
  },
  {
    valueId: "EV006549",
    valueDesc: "0 V ... 10 V",
    label: "EV006549: 0 V ... 10 V"
  },
  {
    valueId: "EV006550",
    valueDesc: "0 V ... 10 V / 0 mA ... 20 mA",
    label: "EV006550: 0 V ... 10 V / 0 mA ... 20 mA"
  },
  {
    valueId: "EV006551",
    valueDesc: "0 mA ... 20 mA",
    label: "EV006551: 0 mA ... 20 mA"
  },
  {
    valueId: "EV006552",
    valueDesc: "0 mA ... 20 mA / 4 mA ... 20 mA",
    label: "EV006552: 0 mA ... 20 mA / 4 mA ... 20 mA"
  },
  {
    valueId: "EV006553",
    valueDesc: "-10V ... +10 V",
    label: "EV006553: -10V ... +10 V"
  },
  {
    valueId: "EV006554",
    valueDesc: "4 mA ... 20 mA",
    label: "EV006554: 4 mA ... 20 mA"
  },
  {
    valueId: "EV006555",
    valueDesc: "Active tristimulus method",
    label: "EV006555: Active tristimulus method"
  },
  {
    valueId: "EV006557",
    valueDesc: "Anticoincidence",
    label: "EV006557: Anticoincidence"
  },
  {
    valueId: "EV006558",
    valueDesc: "AS-Interface",
    label: "EV006558: AS-Interface"
  },
  {
    valueId: "EV006559",
    valueDesc: "AS-Interface Safety at Work",
    label: "EV006559: AS-Interface Safety at Work"
  },
  {
    valueId: "EV006561",
    valueDesc: "ATEX gas-ex-protection, Cat. 1G",
    label: "EV006561: ATEX gas-ex-protection, Cat. 1G"
  },
  {
    valueId: "EV006562",
    valueDesc: "ATEX gas-ex-protection, Cat. 2G",
    label: "EV006562: ATEX gas-ex-protection, Cat. 2G"
  },
  {
    valueId: "EV006563",
    valueDesc: "ATEX gas-ex-protection, Cat. 3G",
    label: "EV006563: ATEX gas-ex-protection, Cat. 3G"
  },
  {
    valueId: "EV006564",
    valueDesc: "ATEX dust-ex-protection, Cat. 1D",
    label: "EV006564: ATEX dust-ex-protection, Cat. 1D"
  },
  {
    valueId: "EV006565",
    valueDesc: "ATEX dust-ex-protection, Cat. 2D",
    label: "EV006565: ATEX dust-ex-protection, Cat. 2D"
  },
  {
    valueId: "EV006566",
    valueDesc: "ATEX dust-ex-protection, Cat. 3D",
    label: "EV006566: ATEX dust-ex-protection, Cat. 3D"
  },
  {
    valueId: "EV006567",
    valueDesc: "Bit parallel",
    label: "EV006567: Bit parallel"
  },
  {
    valueId: "EV006569",
    valueDesc: "Blue light",
    label: "EV006569: Blue light"
  },
  {
    valueId: "EV006573",
    valueDesc: "Bus cap",
    label: "EV006573: Bus cap"
  },
  {
    valueId: "EV006574",
    valueDesc: "CANOpen",
    label: "EV006574: CANOpen"
  },
  {
    valueId: "EV006575",
    valueDesc: "CuSn",
    label: "EV006575: CuSn"
  },
  {
    valueId: "EV006576",
    valueDesc: "CuZn",
    label: "EV006576: CuZn"
  },
  {
    valueId: "EV006577",
    valueDesc: "Roof plunger",
    label: "EV006577: Roof plunger"
  },
  {
    valueId: "EV006578",
    valueDesc: "DeviceNet",
    label: "EV006578: DeviceNet"
  },
  {
    valueId: "EV006579",
    valueDesc: "DeviceNet Safety",
    label: "EV006579: DeviceNet Safety"
  },
  {
    valueId: "EV006580",
    valueDesc: "Double-layer",
    label: "EV006580: Double-layer"
  },
  {
    valueId: "EV006581",
    valueDesc: "Rotary current asynchronous motor",
    label: "EV006581: Rotary current asynchronous motor"
  },
  {
    valueId: "EV006582",
    valueDesc: "Rotary current synchronous motor",
    label: "EV006582: Rotary current synchronous motor"
  },
  {
    valueId: "EV006584",
    valueDesc: "Dark switching",
    label: "EV006584: Dark switching"
  },
  {
    valueId: "EV006589",
    valueDesc: "One-way system",
    label: "EV006589: One-way system"
  },
  {
    valueId: "EV006590",
    valueDesc: "Single cores",
    label: "EV006590: Single cores"
  },
  {
    valueId: "EV006591",
    valueDesc: "Receiver",
    label: "EV006591: Receiver"
  },
  {
    valueId: "EV006598",
    valueDesc: "Direct current motor",
    label: "EV006598: Direct current motor"
  },
  {
    valueId: "EV006599",
    valueDesc: "Green light",
    label: "EV006599: Green light"
  },
  {
    valueId: "EV006601",
    valueDesc: "Light-/dark switching",
    label: "EV006601: Light-/dark switching"
  },
  {
    valueId: "EV006602",
    valueDesc: "Light switching",
    label: "EV006602: Light switching"
  },
  {
    valueId: "EV006603",
    valueDesc: "High pressure gas discharge lamp",
    label: "EV006603: High pressure gas discharge lamp"
  },
  {
    valueId: "EV006604",
    valueDesc: "Infrared light",
    label: "EV006604: Infrared light"
  },
  {
    valueId: "EV006605",
    valueDesc: "INTERBUS",
    label: "EV006605: INTERBUS"
  },
  {
    valueId: "EV006606",
    valueDesc: "IP10",
    label: "EV006606: IP10"
  },
  {
    valueId: "EV006607",
    valueDesc: "IP12",
    label: "EV006607: IP12"
  },
  {
    valueId: "EV006608",
    valueDesc: "IP22",
    label: "EV006608: IP22"
  },
  {
    valueId: "EV006609",
    valueDesc: "IP33",
    label: "EV006609: IP33"
  },
  {
    valueId: "EV006610",
    valueDesc: "IP43",
    label: "EV006610: IP43"
  },
  {
    valueId: "EV006611",
    valueDesc: "IP45",
    label: "EV006611: IP45"
  },
  {
    valueId: "EV006612",
    valueDesc: "IP50",
    label: "EV006612: IP50"
  },
  {
    valueId: "EV006613",
    valueDesc: "IP52",
    label: "EV006613: IP52"
  },
  {
    valueId: "EV006614",
    valueDesc: "IP53",
    label: "EV006614: IP53"
  },
  {
    valueId: "EV006615",
    valueDesc: "IP56",
    label: "EV006615: IP56"
  },
  {
    valueId: "EV006616",
    valueDesc: "IP57",
    label: "EV006616: IP57"
  },
  {
    valueId: "EV006617",
    valueDesc: "IP58",
    label: "EV006617: IP58"
  },
  {
    valueId: "EV006618",
    valueDesc: "IP64",
    label: "EV006618: IP64"
  },
  {
    valueId: "EV006619",
    valueDesc: "IP68",
    label: "EV006619: IP68"
  },
  {
    valueId: "EV006620",
    valueDesc: "IPX0",
    label: "EV006620: IPX0"
  },
  {
    valueId: "EV006621",
    valueDesc: "IPX4",
    label: "EV006621: IPX4"
  },
  {
    valueId: "EV006622",
    valueDesc: "IPX7",
    label: "EV006622: IPX7"
  },
  {
    valueId: "EV006623",
    valueDesc: "Cable axial",
    label: "EV006623: Cable axial"
  },
  {
    valueId: "EV006624",
    valueDesc: "Cable with connector",
    label: "EV006624: Cable with connector"
  },
  {
    valueId: "EV006625",
    valueDesc: "Cable radial",
    label: "EV006625: Cable radial"
  },
  {
    valueId: "EV006626",
    valueDesc: "Class 1",
    label: "EV006626: Class 1"
  },
  {
    valueId: "EV006627",
    valueDesc: "Class 2",
    label: "EV006627: Class 2"
  },
  {
    valueId: "EV006628",
    valueDesc: "Class 3",
    label: "EV006628: Class 3"
  },
  {
    valueId: "EV006629",
    valueDesc: "Capacitor motor",
    label: "EV006629: Capacitor motor"
  },
  {
    valueId: "EV006630",
    valueDesc: "Spherical plunger",
    label: "EV006630: Spherical plunger"
  },
  {
    valueId: "EV006631",
    valueDesc: "Laser diode, infrared light",
    label: "EV006631: Laser diode, infrared light"
  },
  {
    valueId: "EV006632",
    valueDesc: "Laser diode, red light",
    label: "EV006632: Laser diode, red light"
  },
  {
    valueId: "EV006633",
    valueDesc: "Light delay time",
    label: "EV006633: Light delay time"
  },
  {
    valueId: "EV006634",
    valueDesc: "Linear motor",
    label: "EV006634: Linear motor"
  },
  {
    valueId: "EV006636",
    valueDesc: "Magnetostrictional",
    label: "EV006636: Magnetostrictional"
  },
  {
    valueId: "EV006637",
    valueDesc: "Manual adjustment",
    label: "EV006637: Manual adjustment"
  },
  {
    valueId: "EV006638",
    valueDesc: "Multi-core",
    label: "EV006638: Multi-core"
  },
  {
    valueId: "EV006639",
    valueDesc: "Multi-coloured LED",
    label: "EV006639: Multi-coloured LED"
  },
  {
    valueId: "EV006640",
    valueDesc: "Multilayer",
    label: "EV006640: Multilayer"
  },
  {
    valueId: "EV006642",
    valueDesc: "Metallic Target",
    label: "EV006642: Metallic Target"
  },
  {
    valueId: "EV006643",
    valueDesc: "NAMUR",
    label: "EV006643: NAMUR"
  },
  {
    valueId: "EV006645",
    valueDesc: "Not flat",
    label: "EV006645: Not flat"
  },
  {
    valueId: "EV006646",
    valueDesc: "Low-pressure gas discharge lamp",
    label: "EV006646: Low-pressure gas discharge lamp"
  },
  {
    valueId: "EV006648",
    valueDesc: "NPN/NPN",
    label: "EV006648: NPN/NPN"
  },
  {
    valueId: "EV006649",
    valueDesc: "Breaker contact (NC)",
    label: "EV006649: Breaker contact (NC)"
  },
  {
    valueId: "EV006650",
    valueDesc: "Optocoupler",
    label: "EV006650: Optocoupler"
  },
  {
    valueId: "EV006652",
    valueDesc: "Parameterization",
    label: "EV006652: Parameterization"
  },
  {
    valueId: "EV006653",
    valueDesc: "Passive tristimulus method",
    label: "EV006653: Passive tristimulus method"
  },
  {
    valueId: "EV006654",
    valueDesc: "PNP/PNP",
    label: "EV006654: PNP/PNP"
  },
  {
    valueId: "EV006655",
    valueDesc: "Potentiometric",
    label: "EV006655: Potentiometric"
  },
  {
    valueId: "EV006658",
    valueDesc: "Profile",
    label: "EV006658: Profile"
  },
  {
    valueId: "EV006659",
    valueDesc: "PROFIsafe",
    label: "EV006659: PROFIsafe"
  },
  {
    valueId: "EV006660",
    valueDesc: "Programmable/configurable",
    label: "EV006660: Programmable/configurable"
  },
  {
    valueId: "EV006661",
    valueDesc: "PTE/FEP",
    label: "EV006661: PTE/FEP"
  },
  {
    valueId: "EV006662",
    valueDesc: "PUR vulcanized",
    label: "EV006662: PUR vulcanized"
  },
  {
    valueId: "EV006663",
    valueDesc: "Quartz glass",
    label: "EV006663: Quartz glass"
  },
  {
    valueId: "EV006664",
    valueDesc: "Quasi-flat",
    label: "EV006664: Quasi-flat"
  },
  {
    valueId: "EV006666",
    valueDesc: "Reflection system",
    label: "EV006666: Reflection system"
  },
  {
    valueId: "EV006667",
    valueDesc: "Relay contact",
    label: "EV006667: Relay contact"
  },
  {
    valueId: "EV006668",
    valueDesc: "Polarized red light",
    label: "EV006668: Polarized red light"
  },
  {
    valueId: "EV006669",
    valueDesc: "Polarity free red light",
    label: "EV006669: Polarity free red light"
  },
  {
    valueId: "EV006673",
    valueDesc: "S 232",
    label: "EV006673: S 232"
  },
  {
    valueId: "EV006674",
    valueDesc: "SafetyBUS p",
    label: "EV006674: SafetyBUS p"
  },
  {
    valueId: "EV006675",
    valueDesc: "Normally open contact (NO)",
    label: "EV006675: Normally open contact (NO)"
  },
  {
    valueId: "EV006676",
    valueDesc: "Stepping motor",
    label: "EV006676: Stepping motor"
  },
  {
    valueId: "EV006677",
    valueDesc: "Safety class 0",
    label: "EV006677: Safety class 0"
  },
  {
    valueId: "EV006678",
    valueDesc: "Safety class 1",
    label: "EV006678: Safety class 1"
  },
  {
    valueId: "EV006679",
    valueDesc: "Safety class 2",
    label: "EV006679: Safety class 2"
  },
  {
    valueId: "EV006680",
    valueDesc: "Safety class 3",
    label: "EV006680: Safety class 3"
  },
  {
    valueId: "EV006681",
    valueDesc: "Transmitter",
    label: "EV006681: Transmitter"
  },
  {
    valueId: "EV006682",
    valueDesc: "Transmitter and receiver",
    label: "EV006682: Transmitter and receiver"
  },
  {
    valueId: "EV006683",
    valueDesc: "Transmitter and receiver separated",
    label: "EV006683: Transmitter and receiver separated"
  },
  {
    valueId: "EV006684",
    valueDesc: "Transmitter and receiver in one housing",
    label: "EV006684: Transmitter and receiver in one housing"
  },
  {
    valueId: "EV006686",
    valueDesc: "Sine-shaped",
    label: "EV006686: Sine-shaped"
  },
  {
    valueId: "EV006688",
    valueDesc: "Special construction",
    label: "EV006688: Special construction"
  },
  {
    valueId: "EV006689",
    valueDesc: "Split pole motor",
    label: "EV006689: Split pole motor"
  },
  {
    valueId: "EV006691",
    valueDesc: "SSD",
    label: "EV006691: SSD"
  },
  {
    valueId: "EV006692",
    valueDesc: "SSI",
    label: "EV006692: SSI"
  },
  {
    valueId: "EV006693",
    valueDesc: "Axial plug",
    label: "EV006693: Axial plug"
  },
  {
    valueId: "EV006698",
    valueDesc: "Radial plug",
    label: "EV006698: Radial plug"
  },
  {
    valueId: "EV006699",
    valueDesc: "Connector 1/2 inch",
    label: "EV006699: Connector 1/2 inch"
  },
  {
    valueId: "EV006700",
    valueDesc: "Connector 3/4 inch",
    label: "EV006700: Connector 3/4 inch"
  },
  {
    valueId: "EV006701",
    valueDesc: "Connector 7/8 inch",
    label: "EV006701: Connector 7/8 inch"
  },
  {
    valueId: "EV006702",
    valueDesc: "Connector M12",
    label: "EV006702: Connector M12"
  },
  {
    valueId: "EV006703",
    valueDesc: "Connector M8",
    label: "EV006703: Connector M8"
  },
  {
    valueId: "EV006707",
    valueDesc: "Teach-In",
    label: "EV006707: Teach-In"
  },
  {
    valueId: "EV006709",
    valueDesc: "Transponder",
    label: "EV006709: Transponder"
  },
  {
    valueId: "EV006711",
    valueDesc: "Triangulation",
    label: "EV006711: Triangulation"
  },
  {
    valueId: "EV006713",
    valueDesc: "Universal motor",
    label: "EV006713: Universal motor"
  },
  {
    valueId: "EV006714",
    valueDesc: "UV LED",
    label: "EV006714: UV LED"
  },
  {
    valueId: "EV006715",
    valueDesc: "Valve connector",
    label: "EV006715: Valve connector"
  },
  {
    valueId: "EV006719",
    valueDesc: "Change-over contact (NO/NC)",
    label: "EV006719: Change-over contact (NO/NC)"
  },
  {
    valueId: "EV006720",
    valueDesc: "White light",
    label: "EV006720: White light"
  },
  {
    valueId: "EV006721",
    valueDesc: "Vaulted cam",
    label: "EV006721: Vaulted cam"
  },
  {
    valueId: "EV006723",
    valueDesc: "Zinc",
    label: "EV006723: Zinc"
  },
  {
    valueId: "EV006724",
    valueDesc: "Forced contact opening",
    label: "EV006724: Forced contact opening"
  },
  {
    valueId: "EV006726",
    valueDesc: "Cylinder plain",
    label: "EV006726: Cylinder plain"
  },
  {
    valueId: "EV006727",
    valueDesc: "Cylinder, screw-thread",
    label: "EV006727: Cylinder, screw-thread"
  },
  {
    valueId: "EV006730",
    valueDesc: "Ethylene-propylene diene monomer rubber (EPDM)",
    label: "EV006730: Ethylene-propylene diene monomer rubber (EPDM)"
  },
  {
    valueId: "EV006731",
    valueDesc: "Galvanic tinned",
    label: "EV006731: Galvanic tinned"
  },
  {
    valueId: "EV006738",
    valueDesc: "N(A)2XY",
    label: "EV006738: N(A)2XY"
  },
  {
    valueId: "EV006739",
    valueDesc: "N(A)2YY",
    label: "EV006739: N(A)2YY"
  },
  {
    valueId: "EV006740",
    valueDesc: "N(A)YCWY",
    label: "EV006740: N(A)YCWY"
  },
  {
    valueId: "EV006741",
    valueDesc: "N(A)YY",
    label: "EV006741: N(A)YY"
  },
  {
    valueId: "EV006742",
    valueDesc: "Eightfold",
    label: "EV006742: Eightfold"
  },
  {
    valueId: "EV006747",
    valueDesc: "Polished",
    label: "EV006747: Polished"
  },
  {
    valueId: "EV006749",
    valueDesc: "Single module length",
    label: "EV006749: Single module length"
  },
  {
    valueId: "EV006751",
    valueDesc: "2G10 (4-pins)",
    label: "EV006751: 2G10 (4-pins)"
  },
  {
    valueId: "EV006752",
    valueDesc: "2G7 (4-pins)",
    label: "EV006752: 2G7 (4-pins)"
  },
  {
    valueId: "EV006753",
    valueDesc: "2GX13",
    label: "EV006753: 2GX13"
  },
  {
    valueId: "EV006754",
    valueDesc: "Double module length",
    label: "EV006754: Double module length"
  },
  {
    valueId: "EV006756",
    valueDesc: "Triple module length",
    label: "EV006756: Triple module length"
  },
  {
    valueId: "EV006763",
    valueDesc: "Dimming/screen",
    label: "EV006763: Dimming/screen"
  },
  {
    valueId: "EV006766",
    valueDesc: "Luminaire cover",
    label: "EV006766: Luminaire cover"
  },
  {
    valueId: "EV006771",
    valueDesc: "Amber",
    label: "EV006771: Amber"
  },
  {
    valueId: "EV006772",
    valueDesc: "Balance weight",
    label: "EV006772: Balance weight"
  },
  {
    valueId: "EV006780",
    valueDesc: "Decor element",
    label: "EV006780: Decor element"
  },
  {
    valueId: "EV006781",
    valueDesc: "Through-wiring",
    label: "EV006781: Through-wiring"
  },
  {
    valueId: "EV006784",
    valueDesc: "Power supply system",
    label: "EV006784: Power supply system"
  },
  {
    valueId: "EV006788",
    valueDesc: "Electronic ballast 1-10 V",
    label: "EV006788: Electronic ballast 1-10 V"
  },
  {
    valueId: "EV006789",
    valueDesc: "Electronic ballast multiwatt",
    label: "EV006789: Electronic ballast multiwatt"
  },
  {
    valueId: "EV006792",
    valueDesc: "Colour filter",
    label: "EV006792: Colour filter"
  },
  {
    valueId: "EV006793",
    valueDesc: "Filter",
    label: "EV006793: Filter"
  },
  {
    valueId: "EV006795",
    valueDesc: "G9",
    label: "EV006795: G9"
  },
  {
    valueId: "EV006796",
    valueDesc: "Perforated",
    label: "EV006796: Perforated"
  },
  {
    valueId: "EV006797",
    valueDesc: "Glass clear",
    label: "EV006797: Glass clear"
  },
  {
    valueId: "EV006798",
    valueDesc: "Glass opal",
    label: "EV006798: Glass opal"
  },
  {
    valueId: "EV006799",
    valueDesc: "Glass structured",
    label: "EV006799: Glass structured"
  },
  {
    valueId: "EV006800",
    valueDesc: "Glass transparent",
    label: "EV006800: Glass transparent"
  },
  {
    valueId: "EV006802",
    valueDesc: "GX24q-5 (4-pins)",
    label: "EV006802: GX24q-5 (4-pins)"
  },
  {
    valueId: "EV006803",
    valueDesc: "GX24q-6 (4-pins)",
    label: "EV006803: GX24q-6 (4-pins)"
  },
  {
    valueId: "EV006806",
    valueDesc: "HV halogen lamp",
    label: "EV006806: HV halogen lamp"
  },
  {
    valueId: "EV006807",
    valueDesc: "IK01",
    label: "EV006807: IK01"
  },
  {
    valueId: "EV006808",
    valueDesc: "IK02",
    label: "EV006808: IK02"
  },
  {
    valueId: "EV006809",
    valueDesc: "IK03",
    label: "EV006809: IK03"
  },
  {
    valueId: "EV006810",
    valueDesc: "IK04",
    label: "EV006810: IK04"
  },
  {
    valueId: "EV006811",
    valueDesc: "IK05",
    label: "EV006811: IK05"
  },
  {
    valueId: "EV006812",
    valueDesc: "IK06",
    label: "EV006812: IK06"
  },
  {
    valueId: "EV006813",
    valueDesc: "IK07",
    label: "EV006813: IK07"
  },
  {
    valueId: "EV006814",
    valueDesc: "IK08",
    label: "EV006814: IK08"
  },
  {
    valueId: "EV006815",
    valueDesc: "IK09",
    label: "EV006815: IK09"
  },
  {
    valueId: "EV006820",
    valueDesc: "Plastic, clear",
    label: "EV006820: Plastic, clear"
  },
  {
    valueId: "EV006821",
    valueDesc: "Plastic, opal",
    label: "EV006821: Plastic, opal"
  },
  {
    valueId: "EV006822",
    valueDesc: "Plastic, structured",
    label: "EV006822: Plastic, structured"
  },
  {
    valueId: "EV006823",
    valueDesc: "Plastic, transparent",
    label: "EV006823: Plastic, transparent"
  },
  {
    valueId: "EV006826",
    valueDesc: "Coupler/connector flexible",
    label: "EV006826: Coupler/connector flexible"
  },
  {
    valueId: "EV006827",
    valueDesc: "Coupler/connector straight",
    label: "EV006827: Coupler/connector straight"
  },
  {
    valueId: "EV006829",
    valueDesc: "Coupler/connector L-shape",
    label: "EV006829: Coupler/connector L-shape"
  },
  {
    valueId: "EV006830",
    valueDesc: "Coupler/connector T-shape",
    label: "EV006830: Coupler/connector T-shape"
  },
  {
    valueId: "EV006831",
    valueDesc: "Food products filter",
    label: "EV006831: Food products filter"
  },
  {
    valueId: "EV006833",
    valueDesc: "Empty tube",
    label: "EV006833: Empty tube"
  },
  {
    valueId: "EV006834",
    valueDesc: "Luminaire connection adapter",
    label: "EV006834: Luminaire connection adapter"
  },
  {
    valueId: "EV006835",
    valueDesc: "Fluorescent lamp D=16 mm",
    label: "EV006835: Fluorescent lamp D=16 mm"
  },
  {
    valueId: "EV006836",
    valueDesc: "Fluorescent lamp D=26 mm",
    label: "EV006836: Fluorescent lamp D=26 mm"
  },
  {
    valueId: "EV006837",
    valueDesc: "Fluorescent lamp D=38 mm",
    label: "EV006837: Fluorescent lamp D=38 mm"
  },
  {
    valueId: "EV006838",
    valueDesc: "Fluorescent lamp D=7 mm",
    label: "EV006838: Fluorescent lamp D=7 mm"
  },
  {
    valueId: "EV006839",
    valueDesc: "Fluorescent lamp ring-shaped",
    label: "EV006839: Fluorescent lamp ring-shaped"
  },
  {
    valueId: "EV006840",
    valueDesc: "Lens",
    label: "EV006840: Lens"
  },
  {
    valueId: "EV006841",
    valueDesc: "Magnifier luminaire",
    label: "EV006841: Magnifier luminaire"
  },
  {
    valueId: "EV006842",
    valueDesc: "Magnetic transformer",
    label: "EV006842: Magnetic transformer"
  },
  {
    valueId: "EV006845",
    valueDesc: "Mixed light lamp",
    label: "EV006845: Mixed light lamp"
  },
  {
    valueId: "EV006849",
    valueDesc: "Low pressure sodium-vapour lamp",
    label: "EV006849: Low pressure sodium-vapour lamp"
  },
  {
    valueId: "EV006850",
    valueDesc: "LV halogen lamp",
    label: "EV006850: LV halogen lamp"
  },
  {
    valueId: "EV006852",
    valueDesc: "P13.5",
    label: "EV006852: P13.5"
  },
  {
    valueId: "EV006853",
    valueDesc: "P30",
    label: "EV006853: P30"
  },
  {
    valueId: "EV006854",
    valueDesc: "P45",
    label: "EV006854: P45"
  },
  {
    valueId: "EV006855",
    valueDesc: "P60",
    label: "EV006855: P60"
  },
  {
    valueId: "EV006857",
    valueDesc: "PAR reflector",
    label: "EV006857: PAR reflector"
  },
  {
    valueId: "EV006858",
    valueDesc: "Pendant adaptor",
    label: "EV006858: Pendant adaptor"
  },
  {
    valueId: "EV006859",
    valueDesc: "Mercury vapour lamp",
    label: "EV006859: Mercury vapour lamp"
  },
  {
    valueId: "EV006860",
    valueDesc: "Grid",
    label: "EV006860: Grid"
  },
  {
    valueId: "EV006863",
    valueDesc: "RGB",
    label: "EV006863: RGB"
  },
  {
    valueId: "EV006864",
    valueDesc: "R-butt/reflector lamp",
    label: "EV006864: R-butt/reflector lamp"
  },
  {
    valueId: "EV006865",
    valueDesc: "Tube light complete",
    label: "EV006865: Tube light complete"
  },
  {
    valueId: "EV006867",
    valueDesc: "Screen",
    label: "EV006867: Screen"
  },
  {
    valueId: "EV006868",
    valueDesc: "Screw ring",
    label: "EV006868: Screw ring"
  },
  {
    valueId: "EV006869",
    valueDesc: "Desk luminaire",
    label: "EV006869: Desk luminaire"
  },
  {
    valueId: "EV006870",
    valueDesc: "Protective basket",
    label: "EV006870: Protective basket"
  },
  {
    valueId: "EV006871",
    valueDesc: "Protective tube",
    label: "EV006871: Protective tube"
  },
  {
    valueId: "EV006873",
    valueDesc: "Stand (base)",
    label: "EV006873: Stand (base)"
  },
  {
    valueId: "EV006874",
    valueDesc: "Socket outlet element",
    label: "EV006874: Socket outlet element"
  },
  {
    valueId: "EV006877",
    valueDesc: "Symmetric/asymmetric",
    label: "EV006877: Symmetric/asymmetric"
  },
  {
    valueId: "EV006878",
    valueDesc: "T4.6",
    label: "EV006878: T4.6"
  },
  {
    valueId: "EV006879",
    valueDesc: "T5.5K",
    label: "EV006879: T5.5K"
  },
  {
    valueId: "EV006880",
    valueDesc: "T6.8",
    label: "EV006880: T6.8"
  },
  {
    valueId: "EV006887",
    valueDesc: "Closure",
    label: "EV006887: Closure"
  },
  {
    valueId: "EV006889",
    valueDesc: "Living room luminaire",
    label: "EV006889: Living room luminaire"
  },
  {
    valueId: "EV006892",
    valueDesc: "2 1/2 inch",
    label: "EV006892: 2 1/2 inch"
  },
  {
    valueId: "EV006893",
    valueDesc: "3 1/2 inch",
    label: "EV006893: 3 1/2 inch"
  },
  {
    valueId: "EV006894",
    valueDesc: "4 inch",
    label: "EV006894: 4 inch"
  },
  {
    valueId: "EV006901",
    valueDesc: "Continuously galvanized and coated",
    label: "EV006901: Continuously galvanized and coated"
  },
  {
    valueId: "EV006902",
    valueDesc: "Galvanic/electrolytic zinc plated and coated",
    label: "EV006902: Galvanic/electrolytic zinc plated and coated"
  },
  {
    valueId: "EV006903",
    valueDesc: "Galvanic/electrolytic zinc plated and hostaflon coated",
    label: "EV006903: Galvanic/electrolytic zinc plated and hostaflon coated"
  },
  {
    valueId: "EV006904",
    valueDesc: "GRP (Glass fibre reinforced plastic)",
    label: "EV006904: GRP (Glass fibre reinforced plastic)"
  },
  {
    valueId: "EV006905",
    valueDesc: "Wet",
    label: "EV006905: Wet"
  },
  {
    valueId: "EV006906",
    valueDesc: "Wet varnished",
    label: "EV006906: Wet varnished"
  },
  {
    valueId: "EV006908",
    valueDesc: "Stainless steel 316 (V4A)",
    label: "EV006908: Stainless steel 316 (V4A)"
  },
  {
    valueId: "EV006909",
    valueDesc: "Stainless steel 304 (V2A)",
    label: "EV006909: Stainless steel 304 (V2A)"
  },
  {
    valueId: "EV006911",
    valueDesc: "System independent",
    label: "EV006911: System independent"
  },
  {
    valueId: "EV006912",
    valueDesc: "Hot-dip galvanized and coated",
    label: "EV006912: Hot-dip galvanized and coated"
  },
  {
    valueId: "EV006913",
    valueDesc: "Base unit and cover",
    label: "EV006913: Base unit and cover"
  },
  {
    valueId: "EV006914",
    valueDesc: "Copper-plated",
    label: "EV006914: Copper-plated"
  },
  {
    valueId: "EV006916",
    valueDesc: "0.01 µA",
    label: "EV006916: 0.01 µA"
  },
  {
    valueId: "EV006917",
    valueDesc: "0.01 mV",
    label: "EV006917: 0.01 mV"
  },
  {
    valueId: "EV006918",
    valueDesc: "0.01 Ohm",
    label: "EV006918: 0.01 Ohm"
  },
  {
    valueId: "EV006919",
    valueDesc: "0.01 Ix",
    label: "EV006919: 0.01 Ix"
  },
  {
    valueId: "EV006920",
    valueDesc: "0.1 µA",
    label: "EV006920: 0.1 µA"
  },
  {
    valueId: "EV006921",
    valueDesc: "0.1 mV",
    label: "EV006921: 0.1 mV"
  },
  {
    valueId: "EV006922",
    valueDesc: "0.1 Ohm",
    label: "EV006922: 0.1 Ohm"
  },
  {
    valueId: "EV006923",
    valueDesc: "0.1 Ix",
    label: "EV006923: 0.1 Ix"
  },
  {
    valueId: "EV006924",
    valueDesc: "0/5 V",
    label: "EV006924: 0/5 V"
  },
  {
    valueId: "EV006926",
    valueDesc: "1 µA",
    label: "EV006926: 1 µA"
  },
  {
    valueId: "EV006927",
    valueDesc: "1 1/4 inch external",
    label: "EV006927: 1 1/4 inch external"
  },
  {
    valueId: "EV006929",
    valueDesc: "1 mV",
    label: "EV006929: 1 mV"
  },
  {
    valueId: "EV006930",
    valueDesc: "1 Ohm",
    label: "EV006930: 1 Ohm"
  },
  {
    valueId: "EV006931",
    valueDesc: "1 modular spacing",
    label: "EV006931: 1 modular spacing"
  },
  {
    valueId: "EV006932",
    valueDesc: "1 inch external",
    label: "EV006932: 1 inch external"
  },
  {
    valueId: "EV006934",
    valueDesc: "1/2 inch external square",
    label: "EV006934: 1/2 inch external square"
  },
  {
    valueId: "EV006935",
    valueDesc: "1/2x20 inch UNF",
    label: "EV006935: 1/2x20 inch UNF"
  },
  {
    valueId: "EV006937",
    valueDesc: "1/4 inch external square",
    label: "EV006937: 1/4 inch external square"
  },
  {
    valueId: "EV006938",
    valueDesc: "1/4 inch internal hexagon",
    label: "EV006938: 1/4 inch internal hexagon"
  },
  {
    valueId: "EV006939",
    valueDesc: "10 µA",
    label: "EV006939: 10 µA"
  },
  {
    valueId: "EV006940",
    valueDesc: "10 Mbit",
    label: "EV006940: 10 Mbit"
  },
  {
    valueId: "EV006941",
    valueDesc: "10 mV",
    label: "EV006941: 10 mV"
  },
  {
    valueId: "EV006942",
    valueDesc: "254 mm (10 inch) mounting",
    label: "EV006942: 254 mm (10 inch) mounting"
  },
  {
    valueId: "EV006943",
    valueDesc: "10/100 BT",
    label: "EV006943: 10/100 BT"
  },
  {
    valueId: "EV006944",
    valueDesc: "10/100 Mbit",
    label: "EV006944: 10/100 Mbit"
  },
  {
    valueId: "EV006945",
    valueDesc: "10/100/1000 Mbit",
    label: "EV006945: 10/100/1000 Mbit"
  },
  {
    valueId: "EV006946",
    valueDesc: "100 µA",
    label: "EV006946: 100 µA"
  },
  {
    valueId: "EV006947",
    valueDesc: "100 mV",
    label: "EV006947: 100 mV"
  },
  {
    valueId: "EV006951",
    valueDesc: "10 Ix",
    label: "EV006951: 10 Ix"
  },
  {
    valueId: "EV006965",
    valueDesc: "16 mm",
    label: "EV006965: 16 mm"
  },
  {
    valueId: "EV006966",
    valueDesc: "19 mm internal hexagon",
    label: "EV006966: 19 mm internal hexagon"
  },
  {
    valueId: "EV006967",
    valueDesc: "482.6 mm (19 inch)",
    label: "EV006967: 482.6 mm (19 inch)"
  },
  {
    valueId: "EV006968",
    valueDesc: "482.6 mm (19 inch) housing",
    label: "EV006968: 482.6 mm (19 inch) housing"
  },
  {
    valueId: "EV006969",
    valueDesc: "482.6 mm (19 inch) device",
    label: "EV006969: 482.6 mm (19 inch) device"
  },
  {
    valueId: "EV006970",
    valueDesc: "1-fold and 2-fold",
    label: "EV006970: 1-fold and 2-fold"
  },
  {
    valueId: "EV006971",
    valueDesc: "1-fold and 3-fold",
    label: "EV006971: 1-fold and 3-fold"
  },
  {
    valueId: "EV006972",
    valueDesc: "1-fold with overvoltage protection",
    label: "EV006972: 1-fold with overvoltage protection"
  },
  {
    valueId: "EV006973",
    valueDesc: "1 Ix",
    label: "EV006973: 1 Ix"
  },
  {
    valueId: "EV006974",
    valueDesc: "2 modular spacing",
    label: "EV006974: 2 modular spacing"
  },
  {
    valueId: "EV006975",
    valueDesc: "2x 1-fold",
    label: "EV006975: 2x 1-fold"
  },
  {
    valueId: "EV006976",
    valueDesc: "2x 2-fold",
    label: "EV006976: 2x 2-fold"
  },
  {
    valueId: "EV006977",
    valueDesc: "2x TAE F",
    label: "EV006977: 2x TAE F"
  },
  {
    valueId: "EV006978",
    valueDesc: "2nd antenna",
    label: "EV006978: 2nd antenna"
  },
  {
    valueId: "EV006982",
    valueDesc: "20 mm",
    label: "EV006982: 20 mm"
  },
  {
    valueId: "EV006983",
    valueDesc: "21 mm internal hexagon",
    label: "EV006983: 21 mm internal hexagon"
  },
  {
    valueId: "EV006986",
    valueDesc: "25 mm",
    label: "EV006986: 25 mm"
  },
  {
    valueId: "EV006987",
    valueDesc: "2-fold and 1-fold with overvoltage protection",
    label: "EV006987: 2-fold and 1-fold with overvoltage protection"
  },
  {
    valueId: "EV006988",
    valueDesc: "2-fold and 2-fold with overvoltage protection",
    label: "EV006988: 2-fold and 2-fold with overvoltage protection"
  },
  {
    valueId: "EV006989",
    valueDesc: "2-fold with overvoltage protection",
    label: "EV006989: 2-fold with overvoltage protection"
  },
  {
    valueId: "EV006990",
    valueDesc: "2-fold-RCA",
    label: "EV006990: 2-fold-RCA"
  },
  {
    valueId: "EV006996",
    valueDesc: "2x RJ11 6(4)",
    label: "EV006996: 2x RJ11 6(4)"
  },
  {
    valueId: "EV006997",
    valueDesc: "2x RJ12 6(6)",
    label: "EV006997: 2x RJ12 6(6)"
  },
  {
    valueId: "EV006999",
    valueDesc: "2x RJ45 8(8)",
    label: "EV006999: 2x RJ45 8(8)"
  },
  {
    valueId: "EV007000",
    valueDesc: "3 modular spacing",
    label: "EV007000: 3 modular spacing"
  },
  {
    valueId: "EV007001",
    valueDesc: "3/4 inch external square",
    label: "EV007001: 3/4 inch external square"
  },
  {
    valueId: "EV007002",
    valueDesc: "3/8 inch external square",
    label: "EV007002: 3/8 inch external square"
  },
  {
    valueId: "EV007003",
    valueDesc: "3/8x24 inch UNF",
    label: "EV007003: 3/8x24 inch UNF"
  },
  {
    valueId: "EV007006",
    valueDesc: "32 mm",
    label: "EV007006: 32 mm"
  },
  {
    valueId: "EV007009",
    valueDesc: "3N, 400/230V",
    label: "EV007009: 3N, 400/230V"
  },
  {
    valueId: "EV007010",
    valueDesc: "3-phase",
    label: "EV007010: 3-phase"
  },
  {
    valueId: "EV007011",
    valueDesc: "4 modular spacing",
    label: "EV007011: 4 modular spacing"
  },
  {
    valueId: "EV007014",
    valueDesc: "40-bit WEP",
    label: "EV007014: 40-bit WEP"
  },
  {
    valueId: "EV007018",
    valueDesc: "5 modular spacing",
    label: "EV007018: 5 modular spacing"
  },
  {
    valueId: "EV007019",
    valueDesc: "5/8x16 inch UNF",
    label: "EV007019: 5/8x16 inch UNF"
  },
  {
    valueId: "EV007022",
    valueDesc: "55 mm",
    label: "EV007022: 55 mm"
  },
  {
    valueId: "EV007024",
    valueDesc: "5-pole",
    label: "EV007024: 5-pole"
  },
  {
    valueId: "EV007026",
    valueDesc: "6 modular spacing",
    label: "EV007026: 6 modular spacing"
  },
  {
    valueId: "EV007029",
    valueDesc: "64/128-bit WEP",
    label: "EV007029: 64/128-bit WEP"
  },
  {
    valueId: "EV007030",
    valueDesc: "640x480",
    label: "EV007030: 640x480"
  },
  {
    valueId: "EV007032",
    valueDesc: "7 mm",
    label: "EV007032: 7 mm"
  },
  {
    valueId: "EV007033",
    valueDesc: "7 modular spacing",
    label: "EV007033: 7 modular spacing"
  },
  {
    valueId: "EV007034",
    valueDesc: "7/16 (DIN)",
    label: "EV007034: 7/16 (DIN)"
  },
  {
    valueId: "EV007035",
    valueDesc: "7/16 Connector",
    label: "EV007035: 7/16 Connector"
  },
  {
    valueId: "EV007038",
    valueDesc: "72.5 mm",
    label: "EV007038: 72.5 mm"
  },
  {
    valueId: "EV007040",
    valueDesc: "8 modular spacing",
    label: "EV007040: 8 modular spacing"
  },
  {
    valueId: "EV007051",
    valueDesc: "Single mode 9/125",
    label: "EV007051: Single mode 9/125"
  },
  {
    valueId: "EV007053",
    valueDesc: "90 mm",
    label: "EV007053: 90 mm"
  },
  {
    valueId: "EV007054",
    valueDesc: "95 mm",
    label: "EV007054: 95 mm"
  },
  {
    valueId: "EV007055",
    valueDesc: "A+",
    label: "EV007055: A+"
  },
  {
    valueId: "EV007056",
    valueDesc: "A++",
    label: "EV007056: A++"
  },
  {
    valueId: "EV007070",
    valueDesc: "Removable work top",
    label: "EV007070: Removable work top"
  },
  {
    valueId: "EV007071",
    valueDesc: "Trimmable (cut away)",
    label: "EV007071: Trimmable (cut away)"
  },
  {
    valueId: "EV007072",
    valueDesc: "Distance ring",
    label: "EV007072: Distance ring"
  },
  {
    valueId: "EV007075",
    valueDesc: "AC 3 processor",
    label: "EV007075: AC 3 processor"
  },
  {
    valueId: "EV007076",
    valueDesc: "Adapter",
    label: "EV007076: Adapter"
  },
  {
    valueId: "EV007078",
    valueDesc: "Additive operating and release delay",
    label: "EV007078: Additive operating and release delay"
  },
  {
    valueId: "EV007079",
    valueDesc: "Additive operating delay",
    label: "EV007079: Additive operating delay"
  },
  {
    valueId: "EV007080",
    valueDesc: "ADo 4",
    label: "EV007080: ADo 4"
  },
  {
    valueId: "EV007081",
    valueDesc: "ADo 8",
    label: "EV007081: ADo 8"
  },
  {
    valueId: "EV007082",
    valueDesc: "Maple",
    label: "EV007082: Maple"
  },
  {
    valueId: "EV007083",
    valueDesc: "AI",
    label: "EV007083: AI"
  },
  {
    valueId: "EV007084",
    valueDesc: "Active glass-breakage detector",
    label: "EV007084: Active glass-breakage detector"
  },
  {
    valueId: "EV007085",
    valueDesc: "Acoustic and optical",
    label: "EV007085: Acoustic and optical"
  },
  {
    valueId: "EV007086",
    valueDesc: "Acoustic glass-breakage detector",
    label: "EV007086: Acoustic glass-breakage detector"
  },
  {
    valueId: "EV007088",
    valueDesc: "Aluminium/copper",
    label: "EV007088: Aluminium/copper"
  },
  {
    valueId: "EV007090",
    valueDesc: "Aluminium case",
    label: "EV007090: Aluminium case"
  },
  {
    valueId: "EV007091",
    valueDesc: "Aluminium alloy",
    label: "EV007091: Aluminium alloy"
  },
  {
    valueId: "EV007092",
    valueDesc: "Analogue with single function",
    label: "EV007092: Analogue with single function"
  },
  {
    valueId: "EV007093",
    valueDesc: "Analogue with multi function",
    label: "EV007093: Analogue with multi function"
  },
  {
    valueId: "EV007095",
    valueDesc: "Moulded-on push fit plug",
    label: "EV007095: Moulded-on push fit plug"
  },
  {
    valueId: "EV007096",
    valueDesc: "Connection sleeve",
    label: "EV007096: Connection sleeve"
  },
  {
    valueId: "EV007097",
    valueDesc: "Terminal bracket",
    label: "EV007097: Terminal bracket"
  },
  {
    valueId: "EV007098",
    valueDesc: "Operating and release delay",
    label: "EV007098: Operating and release delay"
  },
  {
    valueId: "EV007099",
    valueDesc: "Operating delay",
    label: "EV007099: Operating delay"
  },
  {
    valueId: "EV007100",
    valueDesc: "Antenna connection",
    label: "EV007100: Antenna connection"
  },
  {
    valueId: "EV007101",
    valueDesc: "Antenna cable",
    label: "EV007101: Antenna cable"
  },
  {
    valueId: "EV007102",
    valueDesc: "Antenna coax plug",
    label: "EV007102: Antenna coax plug"
  },
  {
    valueId: "EV007103",
    valueDesc: "Wrist watch",
    label: "EV007103: Wrist watch"
  },
  {
    valueId: "EV007105",
    valueDesc: "Astro clock",
    label: "EV007105: Astro clock"
  },
  {
    valueId: "EV007106",
    valueDesc: "Atlas Copco",
    label: "EV007106: Atlas Copco"
  },
  {
    valueId: "EV007107",
    valueDesc: "On basic element",
    label: "EV007107: On basic element"
  },
  {
    valueId: "EV007108",
    valueDesc: "Surface mounting fix",
    label: "EV007108: Surface mounting fix"
  },
  {
    valueId: "EV007109",
    valueDesc: "Surface mounting modular",
    label: "EV007109: Surface mounting modular"
  },
  {
    valueId: "EV007113",
    valueDesc: "Surface and panel mounting",
    label: "EV007113: Surface and panel mounting"
  },
  {
    valueId: "EV007114",
    valueDesc: "Snap on",
    label: "EV007114: Snap on"
  },
  {
    valueId: "EV007115",
    valueDesc: "Snapped on",
    label: "EV007115: Snapped on"
  },
  {
    valueId: "EV007116",
    valueDesc: "Screw-on",
    label: "EV007116: Screw-on"
  },
  {
    valueId: "EV007118",
    valueDesc: "Table top",
    label: "EV007118: Table top"
  },
  {
    valueId: "EV007119",
    valueDesc: "Aluminium/stainless steel 304 (V2A)",
    label: "EV007119: Aluminium/stainless steel 304 (V2A)"
  },
  {
    valueId: "EV007120",
    valueDesc: "Correction blind",
    label: "EV007120: Correction blind"
  },
  {
    valueId: "EV007122",
    valueDesc: "Off switch with control lamp",
    label: "EV007122: Off switch with control lamp"
  },
  {
    valueId: "EV007123",
    valueDesc: "Passing break contact",
    label: "EV007123: Passing break contact"
  },
  {
    valueId: "EV007124",
    valueDesc: "Outdoor positioning",
    label: "EV007124: Outdoor positioning"
  },
  {
    valueId: "EV007125",
    valueDesc: "Pull-out",
    label: "EV007125: Pull-out"
  },
  {
    valueId: "EV007127",
    valueDesc: "B10",
    label: "EV007127: B10"
  },
  {
    valueId: "EV007128",
    valueDesc: "B12",
    label: "EV007128: B12"
  },
  {
    valueId: "EV007129",
    valueDesc: "B16",
    label: "EV007129: B16"
  },
  {
    valueId: "EV007130",
    valueDesc: "Telescopic guide rail",
    label: "EV007130: Telescopic guide rail"
  },
  {
    valueId: "EV007131",
    valueDesc: "Baking plate",
    label: "EV007131: Baking plate"
  },
  {
    valueId: "EV007132",
    valueDesc: "Basis",
    label: "EV007132: Basis"
  },
  {
    valueId: "EV007133",
    valueDesc: "Basis/terminals",
    label: "EV007133: Basis/terminals"
  },
  {
    valueId: "EV007134",
    valueDesc: "Cotton",
    label: "EV007134: Cotton"
  },
  {
    valueId: "EV007135",
    valueDesc: "Shape of cup",
    label: "EV007135: Shape of cup"
  },
  {
    valueId: "EV007137",
    valueDesc: "Mounting lug",
    label: "EV007137: Mounting lug"
  },
  {
    valueId: "EV007138",
    valueDesc: "Mounting angle bracket",
    label: "EV007138: Mounting angle bracket"
  },
  {
    valueId: "EV007139",
    valueDesc: "Petrol engine",
    label: "EV007139: Petrol engine"
  },
  {
    valueId: "EV007140",
    valueDesc: "Cutlery basket",
    label: "EV007140: Cutlery basket"
  },
  {
    valueId: "EV007142",
    valueDesc: "Beaver tail",
    label: "EV007142: Beaver tail"
  },
  {
    valueId: "EV007143",
    valueDesc: "Up to 40 mm",
    label: "EV007143: Up to 40 mm"
  },
  {
    valueId: "EV007144",
    valueDesc: "Blue tempered",
    label: "EV007144: Blue tempered"
  },
  {
    valueId: "EV007146",
    valueDesc: "Lead link element",
    label: "EV007146: Lead link element"
  },
  {
    valueId: "EV007147",
    valueDesc: "Blind plug",
    label: "EV007147: Blind plug"
  },
  {
    valueId: "EV007148",
    valueDesc: "Strobe shoe adapter",
    label: "EV007148: Strobe shoe adapter"
  },
  {
    valueId: "EV007152",
    valueDesc: "Bottom perforation",
    label: "EV007152: Bottom perforation"
  },
  {
    valueId: "EV007153",
    valueDesc: "Bend horizontal",
    label: "EV007153: Bend horizontal"
  },
  {
    valueId: "EV007154",
    valueDesc: "Bend vertical falling",
    label: "EV007154: Bend vertical falling"
  },
  {
    valueId: "EV007155",
    valueDesc: "Bend vertical rising",
    label: "EV007155: Bend vertical rising"
  },
  {
    valueId: "EV007156",
    valueDesc: "Bore hole with M thread",
    label: "EV007156: Bore hole with M thread"
  },
  {
    valueId: "EV007157",
    valueDesc: "Bosch",
    label: "EV007157: Bosch"
  },
  {
    valueId: "EV007159",
    valueDesc: "Fire protection foam",
    label: "EV007159: Fire protection foam"
  },
  {
    valueId: "EV007160",
    valueDesc: "Beech",
    label: "EV007160: Beech"
  },
  {
    valueId: "EV007163",
    valueDesc: "Camcorder",
    label: "EV007163: Camcorder"
  },
  {
    valueId: "EV007164",
    valueDesc: "Cardbus",
    label: "EV007164: Cardbus"
  },
  {
    valueId: "EV007166",
    valueDesc: "Cardbus Card",
    label: "EV007166: Cardbus Card"
  },
  {
    valueId: "EV007167",
    valueDesc: "CAT I",
    label: "EV007167: CAT I"
  },
  {
    valueId: "EV007168",
    valueDesc: "CAT II",
    label: "EV007168: CAT II"
  },
  {
    valueId: "EV007169",
    valueDesc: "CAT III",
    label: "EV007169: CAT III"
  },
  {
    valueId: "EV007170",
    valueDesc: "CAT IV",
    label: "EV007170: CAT IV"
  },
  {
    valueId: "EV007172",
    valueDesc: "CEE 60x60 mm",
    label: "EV007172: CEE 60x60 mm"
  },
  {
    valueId: "EV007173",
    valueDesc: "CEE 70x70 mm",
    label: "EV007173: CEE 70x70 mm"
  },
  {
    valueId: "EV007174",
    valueDesc: "Cobra",
    label: "EV007174: Cobra"
  },
  {
    valueId: "EV007175",
    valueDesc: "Compact Flash",
    label: "EV007175: Compact Flash"
  },
  {
    valueId: "EV007176",
    valueDesc: "Computer Associates",
    label: "EV007176: Computer Associates"
  },
  {
    valueId: "EV007177",
    valueDesc: "Connector/cable adapter",
    label: "EV007177: Connector/cable adapter"
  },
  {
    valueId: "EV007178",
    valueDesc: "CT 1+",
    label: "EV007178: CT 1+"
  },
  {
    valueId: "EV007179",
    valueDesc: "D II",
    label: "EV007179: D II"
  },
  {
    valueId: "EV007180",
    valueDesc: "D III",
    label: "EV007180: D III"
  },
  {
    valueId: "EV007182",
    valueDesc: "Roof surface pitched",
    label: "EV007182: Roof surface pitched"
  },
  {
    valueId: "EV007183",
    valueDesc: "Gutter clamp",
    label: "EV007183: Gutter clamp"
  },
  {
    valueId: "EV007185",
    valueDesc: "Data net connection",
    label: "EV007185: Data net connection"
  },
  {
    valueId: "EV007186",
    valueDesc: "PC interface and satellite/air/cable",
    label: "EV007186: PC interface and satellite/air/cable"
  },
  {
    valueId: "EV007187",
    valueDesc: "Data medium",
    label: "EV007187: Data medium"
  },
  {
    valueId: "EV007188",
    valueDesc: "DCF 77 radio controlled",
    label: "EV007188: DCF 77 radio controlled"
  },
  {
    valueId: "EV007190",
    valueDesc: "Cover clamp",
    label: "EV007190: Cover clamp"
  },
  {
    valueId: "EV007192",
    valueDesc: "DECT",
    label: "EV007192: DECT"
  },
  {
    valueId: "EV007193",
    valueDesc: "DECT/GAP",
    label: "EV007193: DECT/GAP"
  },
  {
    valueId: "EV007194",
    valueDesc: "DECT-basis",
    label: "EV007194: DECT-basis"
  },
  {
    valueId: "EV007195",
    valueDesc: "DECT-basis station",
    label: "EV007195: DECT-basis station"
  },
  {
    valueId: "EV007196",
    valueDesc: "DECT-repeater",
    label: "EV007196: DECT-repeater"
  },
  {
    valueId: "EV007197",
    valueDesc: "DECT-beam antenna",
    label: "EV007197: DECT-beam antenna"
  },
  {
    valueId: "EV007198",
    valueDesc: "Decor-correction blind",
    label: "EV007198: Decor-correction blind"
  },
  {
    valueId: "EV007199",
    valueDesc: "Decor frame",
    label: "EV007199: Decor frame"
  },
  {
    valueId: "EV007200",
    valueDesc: "Zinc/aluminium flake coating",
    label: "EV007200: Zinc/aluminium flake coating"
  },
  {
    valueId: "EV007201",
    valueDesc: "Diamond",
    label: "EV007201: Diamond"
  },
  {
    valueId: "EV007202",
    valueDesc: "Digital with single function",
    label: "EV007202: Digital with single function"
  },
  {
    valueId: "EV007203",
    valueDesc: "Digital with multi function",
    label: "EV007203: Digital with multi function"
  },
  {
    valueId: "EV007207",
    valueDesc: "DIN-strips",
    label: "EV007207: DIN-strips"
  },
  {
    valueId: "EV007208",
    valueDesc: "Double wall bracket",
    label: "EV007208: Double wall bracket"
  },
  {
    valueId: "EV007209",
    valueDesc: "Double floor",
    label: "EV007209: Double floor"
  },
  {
    valueId: "EV007210",
    valueDesc: "Double floor outlet",
    label: "EV007210: Double floor outlet"
  },
  {
    valueId: "EV007212",
    valueDesc: "Double nozzle",
    label: "EV007212: Double nozzle"
  },
  {
    valueId: "EV007213",
    valueDesc: "Double door",
    label: "EV007213: Double door"
  },
  {
    valueId: "EV007214",
    valueDesc: "Double shell",
    label: "EV007214: Double shell"
  },
  {
    valueId: "EV007215",
    valueDesc: "Double bracket",
    label: "EV007215: Double bracket"
  },
  {
    valueId: "EV007216",
    valueDesc: "Spagnolet",
    label: "EV007216: Spagnolet"
  },
  {
    valueId: "EV007217",
    valueDesc: "Print foil",
    label: "EV007217: Print foil"
  },
  {
    valueId: "EV007218",
    valueDesc: "Print head",
    label: "EV007218: Print head"
  },
  {
    valueId: "EV007224",
    valueDesc: "Fragrances",
    label: "EV007224: Fragrances"
  },
  {
    valueId: "EV007225",
    valueDesc: "Thin-film",
    label: "EV007225: Thin-film"
  },
  {
    valueId: "EV007226",
    valueDesc: "Duplex printing unit",
    label: "EV007226: Duplex printing unit"
  },
  {
    valueId: "EV007231",
    valueDesc: "Through-feed joint",
    label: "EV007231: Through-feed joint"
  },
  {
    valueId: "EV007232",
    valueDesc: "DVD+R",
    label: "EV007232: DVD+R"
  },
  {
    valueId: "EV007233",
    valueDesc: "DVD+RW",
    label: "EV007233: DVD+RW"
  },
  {
    valueId: "EV007234",
    valueDesc: "DVD-R",
    label: "EV007234: DVD-R"
  },
  {
    valueId: "EV007235",
    valueDesc: "DVD-RAM",
    label: "EV007235: DVD-RAM"
  },
  {
    valueId: "EV007236",
    valueDesc: "DVD-RW",
    label: "EV007236: DVD-RW"
  },
  {
    valueId: "EV007237",
    valueDesc: "Corner duct",
    label: "EV007237: Corner duct"
  },
  {
    valueId: "EV007239",
    valueDesc: "Oak",
    label: "EV007239: Oak"
  },
  {
    valueId: "EV007240",
    valueDesc: "Bucket",
    label: "EV007240: Bucket"
  },
  {
    valueId: "EV007242",
    valueDesc: "Installation mounting set",
    label: "EV007242: Installation mounting set"
  },
  {
    valueId: "EV007243",
    valueDesc: "Installation scale",
    label: "EV007243: Installation scale"
  },
  {
    valueId: "EV007245",
    valueDesc: "Single shielded",
    label: "EV007245: Single shielded"
  },
  {
    valueId: "EV007246",
    valueDesc: "Singular profile",
    label: "EV007246: Singular profile"
  },
  {
    valueId: "EV007247",
    valueDesc: "Built-in sensor",
    label: "EV007247: Built-in sensor"
  },
  {
    valueId: "EV007248",
    valueDesc: "Hang in",
    label: "EV007248: Hang in"
  },
  {
    valueId: "EV007249",
    valueDesc: "Hang in and screw tight",
    label: "EV007249: Hang in and screw tight"
  },
  {
    valueId: "EV007251",
    valueDesc: "Inserting",
    label: "EV007251: Inserting"
  },
  {
    valueId: "EV007252",
    valueDesc: "Snap in",
    label: "EV007252: Snap in"
  },
  {
    valueId: "EV007253",
    valueDesc: "Insert",
    label: "EV007253: Insert"
  },
  {
    valueId: "EV007254",
    valueDesc: "On/off-switch wiping relay",
    label: "EV007254: On/off-switch wiping relay"
  },
  {
    valueId: "EV007255",
    valueDesc: "On-switch wiping relay",
    label: "EV007255: On-switch wiping relay"
  },
  {
    valueId: "EV007256",
    valueDesc: "Single-line",
    label: "EV007256: Single-line"
  },
  {
    valueId: "EV007257",
    valueDesc: "Single bucket",
    label: "EV007257: Single bucket"
  },
  {
    valueId: "EV007258",
    valueDesc: "Single device",
    label: "EV007258: Single device"
  },
  {
    valueId: "EV007259",
    valueDesc: "Electronic beam/analogue",
    label: "EV007259: Electronic beam/analogue"
  },
  {
    valueId: "EV007260",
    valueDesc: "Electronic with multi function",
    label: "EV007260: Electronic with multi function"
  },
  {
    valueId: "EV007261",
    valueDesc: "EMS",
    label: "EV007261: EMS"
  },
  {
    valueId: "EV007262",
    valueDesc: "Energy technology",
    label: "EV007262: Energy technology"
  },
  {
    valueId: "EV007264",
    valueDesc: "Natural gas and propane gas",
    label: "EV007264: Natural gas and propane gas"
  },
  {
    valueId: "EV007267",
    valueDesc: "Screed-covered",
    label: "EV007267: Screed-covered"
  },
  {
    valueId: "EV007271",
    valueDesc: "External motor",
    label: "EV007271: External motor"
  },
  {
    valueId: "EV007272",
    valueDesc: "External device",
    label: "EV007272: External device"
  },
  {
    valueId: "EV007273",
    valueDesc: "F05-PCF",
    label: "EV007273: F05-PCF"
  },
  {
    valueId: "EV007274",
    valueDesc: "F05-POF",
    label: "EV007274: F05-POF"
  },
  {
    valueId: "EV007275",
    valueDesc: "F07-PCF",
    label: "EV007275: F07-PCF"
  },
  {
    valueId: "EV007276",
    valueDesc: "Rebate clamp",
    label: "EV007276: Rebate clamp"
  },
  {
    valueId: "EV007277",
    valueDesc: "Mushroom-shaped interceptor",
    label: "EV007277: Mushroom-shaped interceptor"
  },
  {
    valueId: "EV007278",
    valueDesc: "Interception rod chamfered",
    label: "EV007278: Interception rod chamfered"
  },
  {
    valueId: "EV007279",
    valueDesc: "Interception rod with connection flange",
    label: "EV007279: Interception rod with connection flange"
  },
  {
    valueId: "EV007280",
    valueDesc: "Interception rod with thread",
    label: "EV007280: Interception rod with thread"
  },
  {
    valueId: "EV007281",
    valueDesc: "Interception rod with tripod",
    label: "EV007281: Interception rod with tripod"
  },
  {
    valueId: "EV007282",
    valueDesc: "Interception rod tapered",
    label: "EV007282: Interception rod tapered"
  },
  {
    valueId: "EV007283",
    valueDesc: "Coloured ribbon",
    label: "EV007283: Coloured ribbon"
  },
  {
    valueId: "EV007284",
    valueDesc: "Hue control",
    label: "EV007284: Hue control"
  },
  {
    valueId: "EV007285",
    valueDesc: "Colour/digital",
    label: "EV007285: Colour/digital"
  },
  {
    valueId: "EV007286",
    valueDesc: "Colour cartridge",
    label: "EV007286: Colour cartridge"
  },
  {
    valueId: "EV007287",
    valueDesc: "Facade",
    label: "EV007287: Facade"
  },
  {
    valueId: "EV007288",
    valueDesc: "Spring cover",
    label: "EV007288: Spring cover"
  },
  {
    valueId: "EV007289",
    valueDesc: "Spring steel",
    label: "EV007289: Spring steel"
  },
  {
    valueId: "EV007290",
    valueDesc: "Television set",
    label: "EV007290: Television set"
  },
  {
    valueId: "EV007291",
    valueDesc: "Finished fire partition",
    label: "EV007291: Finished fire partition"
  },
  {
    valueId: "EV007292",
    valueDesc: "Hard disc",
    label: "EV007292: Hard disc"
  },
  {
    valueId: "EV007293",
    valueDesc: "Grease pan",
    label: "EV007293: Grease pan"
  },
  {
    valueId: "EV007296",
    valueDesc: "Fire brigade alarm (red)",
    label: "EV007296: Fire brigade alarm (red)"
  },
  {
    valueId: "EV007297",
    valueDesc: "Fibre silicate",
    label: "EV007297: Fibre silicate"
  },
  {
    valueId: "EV007298",
    valueDesc: "Filter set",
    label: "EV007298: Filter set"
  },
  {
    valueId: "EV007299",
    valueDesc: "Ridge/hip",
    label: "EV007299: Ridge/hip"
  },
  {
    valueId: "EV007300",
    valueDesc: "Surface",
    label: "EV007300: Surface"
  },
  {
    valueId: "EV007302",
    valueDesc: "Flat cable",
    label: "EV007302: Flat cable"
  },
  {
    valueId: "EV007308",
    valueDesc: "Button head",
    label: "EV007308: Button head"
  },
  {
    valueId: "EV007309",
    valueDesc: "Button head with square",
    label: "EV007309: Button head with square"
  },
  {
    valueId: "EV007310",
    valueDesc: "Bottle cage",
    label: "EV007310: Bottle cage"
  },
  {
    valueId: "EV007311",
    valueDesc: "Photo camera",
    label: "EV007311: Photo camera"
  },
  {
    valueId: "EV007312",
    valueDesc: "F-Quick",
    label: "EV007312: F-Quick"
  },
  {
    valueId: "EV007313",
    valueDesc: "Frequency",
    label: "EV007313: Frequency"
  },
  {
    valueId: "EV007314",
    valueDesc: "Frequency shunt",
    label: "EV007314: Frequency shunt"
  },
  {
    valueId: "EV007315",
    valueDesc: "Front blind",
    label: "EV007315: Front blind"
  },
  {
    valueId: "EV007318",
    valueDesc: "Panel mounting 72x72 mm",
    label: "EV007318: Panel mounting 72x72 mm"
  },
  {
    valueId: "EV007322",
    valueDesc: "FSMA",
    label: "EV007322: FSMA"
  },
  {
    valueId: "EV007323",
    valueDesc: "FSMA-POF",
    label: "EV007323: FSMA-POF"
  },
  {
    valueId: "EV007324",
    valueDesc: "FTZ-123 D12",
    label: "EV007324: FTZ-123 D12"
  },
  {
    valueId: "EV007326",
    valueDesc: "For device cups",
    label: "EV007326: For device cups"
  },
  {
    valueId: "EV007327",
    valueDesc: "For equipment inset",
    label: "EV007327: For equipment inset"
  },
  {
    valueId: "EV007329",
    valueDesc: "GBIC",
    label: "EV007329: GBIC"
  },
  {
    valueId: "EV007330",
    valueDesc: "Counter shell",
    label: "EV007330: Counter shell"
  },
  {
    valueId: "EV007331",
    valueDesc: "Gel",
    label: "EV007331: Gel"
  },
  {
    valueId: "EV007332",
    valueDesc: "Vertical adjustable bend element",
    label: "EV007332: Vertical adjustable bend element"
  },
  {
    valueId: "EV007333",
    valueDesc: "Vertical adjustable bend complete",
    label: "EV007333: Vertical adjustable bend complete"
  },
  {
    valueId: "EV007336",
    valueDesc: "Device",
    label: "EV007336: Device"
  },
  {
    valueId: "EV007337",
    valueDesc: "Device cup",
    label: "EV007337: Device cup"
  },
  {
    valueId: "EV007339",
    valueDesc: "Wall ducts",
    label: "EV007339: Wall ducts"
  },
  {
    valueId: "EV007340",
    valueDesc: "Sound filter",
    label: "EV007340: Sound filter"
  },
  {
    valueId: "EV007341",
    valueDesc: "Apart",
    label: "EV007341: Apart"
  },
  {
    valueId: "EV007343",
    valueDesc: "Thread connection",
    label: "EV007343: Thread connection"
  },
  {
    valueId: "EV007344",
    valueDesc: "Thread-forming",
    label: "EV007344: Thread-forming"
  },
  {
    valueId: "EV007345",
    valueDesc: "Thread-cutting",
    label: "EV007345: Thread-cutting"
  },
  {
    valueId: "EV007346",
    valueDesc: "Thread-cutting screws",
    label: "EV007346: Thread-cutting screws"
  },
  {
    valueId: "EV007349",
    valueDesc: "Glass holder",
    label: "EV007349: Glass holder"
  },
  {
    valueId: "EV007350",
    valueDesc: "Glass fibre",
    label: "EV007350: Glass fibre"
  },
  {
    valueId: "EV007351",
    valueDesc: "Glass fibre/aluminium",
    label: "EV007351: Glass fibre/aluminium"
  },
  {
    valueId: "EV007352",
    valueDesc: "Gold/nickel",
    label: "EV007352: Gold/nickel"
  },
  {
    valueId: "EV007353",
    valueDesc: "Gold/rhodium/nickel",
    label: "EV007353: Gold/rhodium/nickel"
  },
  {
    valueId: "EV007355",
    valueDesc: "Large spline shaft intake",
    label: "EV007355: Large spline shaft intake"
  },
  {
    valueId: "EV007356",
    valueDesc: "Basic module",
    label: "EV007356: Basic module"
  },
  {
    valueId: "EV007357",
    valueDesc: "Group switch",
    label: "EV007357: Group switch"
  },
  {
    valueId: "EV007359",
    valueDesc: "Belt clip",
    label: "EV007359: Belt clip"
  },
  {
    valueId: "EV007360",
    valueDesc: "H05RR-F",
    label: "EV007360: H05RR-F"
  },
  {
    valueId: "EV007361",
    valueDesc: "H05VV-F",
    label: "EV007361: H05VV-F"
  },
  {
    valueId: "EV007362",
    valueDesc: "H07BQ-F",
    label: "EV007362: H07BQ-F"
  },
  {
    valueId: "EV007363",
    valueDesc: "H07RN-F",
    label: "EV007363: H07RN-F"
  },
  {
    valueId: "EV007365",
    valueDesc: "Hook blade",
    label: "EV007365: Hook blade"
  },
  {
    valueId: "EV007366",
    valueDesc: "Half hard",
    label: "EV007366: Half hard"
  },
  {
    valueId: "EV007367",
    valueDesc: "Retaining collar",
    label: "EV007367: Retaining collar"
  },
  {
    valueId: "EV007368",
    valueDesc: "Hammer inserts",
    label: "EV007368: Hammer inserts"
  },
  {
    valueId: "EV007369",
    valueDesc: "Hammer guiding rider",
    label: "EV007369: Hammer guiding rider"
  },
  {
    valueId: "EV007370",
    valueDesc: "Hammer stage",
    label: "EV007370: Hammer stage"
  },
  {
    valueId: "EV007371",
    valueDesc: "Hanging",
    label: "EV007371: Hanging"
  },
  {
    valueId: "EV007373",
    valueDesc: "Hard rubber (ebonite)",
    label: "EV007373: Hard rubber (ebonite)"
  },
  {
    valueId: "EV007374",
    valueDesc: "Hard metal",
    label: "EV007374: Hard metal"
  },
  {
    valueId: "EV007378",
    valueDesc: "House alarm (blue)",
    label: "EV007378: House alarm (blue)"
  },
  {
    valueId: "EV007381",
    valueDesc: "Hilti",
    label: "EV007381: Hilti"
  },
  {
    valueId: "EV007383",
    valueDesc: "Hollow floor",
    label: "EV007383: Hollow floor"
  },
  {
    valueId: "EV007384",
    valueDesc: "Hollow floor outlet",
    label: "EV007384: Hollow floor outlet"
  },
  {
    valueId: "EV007386",
    valueDesc: "Hollow ceiling",
    label: "EV007386: Hollow ceiling"
  },
  {
    valueId: "EV007387",
    valueDesc: "Hollow head",
    label: "EV007387: Hollow head"
  },
  {
    valueId: "EV007388",
    valueDesc: "HomeRF",
    label: "EV007388: HomeRF"
  },
  {
    valueId: "EV007389",
    valueDesc: "Handset",
    label: "EV007389: Handset"
  },
  {
    valueId: "EV007392",
    valueDesc: "HP open View/Windows",
    label: "EV007392: HP open View/Windows"
  },
  {
    valueId: "EV007393",
    valueDesc: "DIN (top hat) rail/combination-rail/C-rail",
    label: "EV007393: DIN (top hat) rail/combination-rail/C-rail"
  },
  {
    valueId: "EV007394",
    valueDesc: "DIN rail (top hat rail)/G-rail TH35/G32",
    label: "EV007394: DIN rail (top hat rail)/G-rail TH35/G32"
  },
  {
    valueId: "EV007395",
    valueDesc: "Hydraulic compression head with aggregate",
    label: "EV007395: Hydraulic compression head with aggregate"
  },
  {
    valueId: "EV007396",
    valueDesc: "Hydrolytic",
    label: "EV007396: Hydrolytic"
  },
  {
    valueId: "EV007397",
    valueDesc: "Hygrometer for sauna",
    label: "EV007397: Hygrometer for sauna"
  },
  {
    valueId: "EV007398",
    valueDesc: "IBM NetView",
    label: "EV007398: IBM NetView"
  },
  {
    valueId: "EV007400",
    valueDesc: "IBM-LAN-Connector",
    label: "EV007400: IBM-LAN-Connector"
  },
  {
    valueId: "EV007404",
    valueDesc: "IEEE 802.11 a",
    label: "EV007404: IEEE 802.11 a"
  },
  {
    valueId: "EV007405",
    valueDesc: "IEEE 802.11 g",
    label: "EV007405: IEEE 802.11 g"
  },
  {
    valueId: "EV007406",
    valueDesc: "IEEE 802.11 h",
    label: "EV007406: IEEE 802.11 h"
  },
  {
    valueId: "EV007407",
    valueDesc: "In housing",
    label: "EV007407: In housing"
  },
  {
    valueId: "EV007408",
    valueDesc: "In concrete",
    label: "EV007408: In concrete"
  },
  {
    valueId: "EV007409",
    valueDesc: "Pulse former",
    label: "EV007409: Pulse former"
  },
  {
    valueId: "EV007410",
    valueDesc: "Impulse controlled operating delay",
    label: "EV007410: Impulse controlled operating delay"
  },
  {
    valueId: "EV007411",
    valueDesc: "Impulse day clock",
    label: "EV007411: Impulse day clock"
  },
  {
    valueId: "EV007412",
    valueDesc: "Impulse clock",
    label: "EV007412: Impulse clock"
  },
  {
    valueId: "EV007413",
    valueDesc: "Impulse week clock",
    label: "EV007413: Impulse week clock"
  },
  {
    valueId: "EV007414",
    valueDesc: "In plaster",
    label: "EV007414: In plaster"
  },
  {
    valueId: "EV007415",
    valueDesc: "Induction",
    label: "EV007415: Induction"
  },
  {
    valueId: "EV007416",
    valueDesc: "Information technology",
    label: "EV007416: Information technology"
  },
  {
    valueId: "EV007417",
    valueDesc: "Infrared/microwave (Dual)",
    label: "EV007417: Infrared/microwave (Dual)"
  },
  {
    valueId: "EV007419",
    valueDesc: "Indoor positioning",
    label: "EV007419: Indoor positioning"
  },
  {
    valueId: "EV007420",
    valueDesc: "Internal hexagon socket 1 1/8 inch",
    label: "EV007420: Internal hexagon socket 1 1/8 inch"
  },
  {
    valueId: "EV007421",
    valueDesc: "Internal hexagon",
    label: "EV007421: Internal hexagon"
  },
  {
    valueId: "EV007422",
    valueDesc: "Decorative (isle hood)",
    label: "EV007422: Decorative (isle hood)"
  },
  {
    valueId: "EV007425",
    valueDesc: "Integrated connector",
    label: "EV007425: Integrated connector"
  },
  {
    valueId: "EV007427",
    valueDesc: "Ionisation",
    label: "EV007427: Ionisation"
  },
  {
    valueId: "EV007430",
    valueDesc: "ISO",
    label: "EV007430: ISO"
  },
  {
    valueId: "EV007431",
    valueDesc: "Thermos flask",
    label: "EV007431: Thermos flask"
  },
  {
    valueId: "EV007432",
    valueDesc: "Insulated",
    label: "EV007432: Insulated"
  },
  {
    valueId: "EV007433",
    valueDesc: "IT",
    label: "EV007433: IT"
  },
  {
    valueId: "EV007434",
    valueDesc: "Year",
    label: "EV007434: Year"
  },
  {
    valueId: "EV007435",
    valueDesc: "Year clock",
    label: "EV007435: Year clock"
  },
  {
    valueId: "EV007437",
    valueDesc: "Cable tray",
    label: "EV007437: Cable tray"
  },
  {
    valueId: "EV007439",
    valueDesc: "Cold",
    label: "EV007439: Cold"
  },
  {
    valueId: "EV007440",
    valueDesc: "Coldness",
    label: "EV007440: Coldness"
  },
  {
    valueId: "EV007442",
    valueDesc: "Calcium silicate",
    label: "EV007442: Calcium silicate"
  },
  {
    valueId: "EV007443",
    valueDesc: "Duct outlet",
    label: "EV007443: Duct outlet"
  },
  {
    valueId: "EV007444",
    valueDesc: "Duct joint cover",
    label: "EV007444: Duct joint cover"
  },
  {
    valueId: "EV007446",
    valueDesc: "Duct system screed-covered",
    label: "EV007446: Duct system screed-covered"
  },
  {
    valueId: "EV007447",
    valueDesc: "Kango socket",
    label: "EV007447: Kango socket"
  },
  {
    valueId: "EV007448",
    valueDesc: "Card",
    label: "EV007448: Card"
  },
  {
    valueId: "EV007449",
    valueDesc: "Cartridge",
    label: "EV007449: Cartridge"
  },
  {
    valueId: "EV007451",
    valueDesc: "Without open listening",
    label: "EV007451: Without open listening"
  },
  {
    valueId: "EV007452",
    valueDesc: "Fire protection pad",
    label: "EV007452: Fire protection pad"
  },
  {
    valueId: "EV007453",
    valueDesc: "Flap",
    label: "EV007453: Flap"
  },
  {
    valueId: "EV007454",
    valueDesc: "Class B",
    label: "EV007454: Class B"
  },
  {
    valueId: "EV007455",
    valueDesc: "Class C",
    label: "EV007455: Class C"
  },
  {
    valueId: "EV007456",
    valueDesc: "Class L",
    label: "EV007456: Class L"
  },
  {
    valueId: "EV007457",
    valueDesc: "Adhesive",
    label: "EV007457: Adhesive"
  },
  {
    valueId: "EV007458",
    valueDesc: "Self-adhesive and bottom perforation",
    label: "EV007458: Self-adhesive and bottom perforation"
  },
  {
    valueId: "EV007459",
    valueDesc: "Glue/burs",
    label: "EV007459: Glue/burs"
  },
  {
    valueId: "EV007461",
    valueDesc: "Clampable",
    label: "EV007461: Clampable"
  },
  {
    valueId: "EV007462",
    valueDesc: "Clamp block",
    label: "EV007462: Clamp block"
  },
  {
    valueId: "EV007463",
    valueDesc: "Clamping",
    label: "EV007463: Clamping"
  },
  {
    valueId: "EV007465",
    valueDesc: "Burs and brace",
    label: "EV007465: Burs and brace"
  },
  {
    valueId: "EV007466",
    valueDesc: "Burs",
    label: "EV007466: Burs"
  },
  {
    valueId: "EV007467",
    valueDesc: "Burs/clamp",
    label: "EV007467: Burs/clamp"
  },
  {
    valueId: "EV007468",
    valueDesc: "Climate measurement",
    label: "EV007468: Climate measurement"
  },
  {
    valueId: "EV007469",
    valueDesc: "Coax type",
    label: "EV007469: Coax type"
  },
  {
    valueId: "EV007471",
    valueDesc: "Combination fuse status indicator",
    label: "EV007471: Combination fuse status indicator"
  },
  {
    valueId: "EV007472",
    valueDesc: "Combi grate",
    label: "EV007472: Combi grate"
  },
  {
    valueId: "EV007473",
    valueDesc: "Construction clamp",
    label: "EV007473: Construction clamp"
  },
  {
    valueId: "EV007474",
    valueDesc: "Convectors",
    label: "EV007474: Convectors"
  },
  {
    valueId: "EV007475",
    valueDesc: "Basket for glasses/cups",
    label: "EV007475: Basket for glasses/cups"
  },
  {
    valueId: "EV007476",
    valueDesc: "Seismic detector",
    label: "EV007476: Seismic detector"
  },
  {
    valueId: "EV007477",
    valueDesc: "Crossing",
    label: "EV007477: Crossing"
  },
  {
    valueId: "EV007478",
    valueDesc: "Fridge/freezer",
    label: "EV007478: Fridge/freezer"
  },
  {
    valueId: "EV007479",
    valueDesc: "Plastic, sheathed",
    label: "EV007479: Plastic, sheathed"
  },
  {
    valueId: "EV007480",
    valueDesc: "Copper sheathed",
    label: "EV007480: Copper sheathed"
  },
  {
    valueId: "EV007481",
    valueDesc: "Copper/plastic",
    label: "EV007481: Copper/plastic"
  },
  {
    valueId: "EV007482",
    valueDesc: "Copper/stainless steel 304 (V2A)",
    label: "EV007482: Copper/stainless steel 304 (V2A)"
  },
  {
    valueId: "EV007483",
    valueDesc: "Copper/stainless steel 316 (V4A)",
    label: "EV007483: Copper/stainless steel 316 (V4A)"
  },
  {
    valueId: "EV007484",
    valueDesc: "Copper/red bronze",
    label: "EV007484: Copper/red bronze"
  },
  {
    valueId: "EV007485",
    valueDesc: "Copper/steel",
    label: "EV007485: Copper/steel"
  },
  {
    valueId: "EV007487",
    valueDesc: "LAN connection",
    label: "EV007487: LAN connection"
  },
  {
    valueId: "EV007488",
    valueDesc: "Length- and angle joint",
    label: "EV007488: Length- and angle joint"
  },
  {
    valueId: "EV007489",
    valueDesc: "Lengthwise cover",
    label: "EV007489: Lengthwise cover"
  },
  {
    valueId: "EV007490",
    valueDesc: "Longitudinal joint",
    label: "EV007490: Longitudinal joint"
  },
  {
    valueId: "EV007491",
    valueDesc: "LAN module",
    label: "EV007491: LAN module"
  },
  {
    valueId: "EV007492",
    valueDesc: "Lip",
    label: "EV007492: Lip"
  },
  {
    valueId: "EV007493",
    valueDesc: "Claw hammer",
    label: "EV007493: Claw hammer"
  },
  {
    valueId: "EV007494",
    valueDesc: "Loudspeaker cover grill",
    label: "EV007494: Loudspeaker cover grill"
  },
  {
    valueId: "EV007495",
    valueDesc: "LCD viewfinder",
    label: "EV007495: LCD viewfinder"
  },
  {
    valueId: "EV007496",
    valueDesc: "Empty chassis",
    label: "EV007496: Empty chassis"
  },
  {
    valueId: "EV007498",
    valueDesc: "PCB-module",
    label: "EV007498: PCB-module"
  },
  {
    valueId: "EV007499",
    valueDesc: "Mini/maxi trunking",
    label: "EV007499: Mini/maxi trunking"
  },
  {
    valueId: "EV007500",
    valueDesc: "Flashlight",
    label: "EV007500: Flashlight"
  },
  {
    valueId: "EV007503",
    valueDesc: "Line-interactive",
    label: "EV007503: Line-interactive"
  },
  {
    valueId: "EV007505",
    valueDesc: "Left, interchangeable",
    label: "EV007505: Left, interchangeable"
  },
  {
    valueId: "EV007507",
    valueDesc: "Lithium-ions",
    label: "EV007507: Lithium-ions"
  },
  {
    valueId: "EV007508",
    valueDesc: "Hole-/slot punching",
    label: "EV007508: Hole-/slot punching"
  },
  {
    valueId: "EV007509",
    valueDesc: "LON bus",
    label: "EV007509: LON bus"
  },
  {
    valueId: "EV007510",
    valueDesc: "LSA",
    label: "EV007510: LSA"
  },
  {
    valueId: "EV007511",
    valueDesc: "LSA 2, 1-pole",
    label: "EV007511: LSA 2, 1-pole"
  },
  {
    valueId: "EV007512",
    valueDesc: "LSA 2, 2-pole",
    label: "EV007512: LSA 2, 2-pole"
  },
  {
    valueId: "EV007513",
    valueDesc: "LSA-plus plug-in",
    label: "EV007513: LSA-plus plug-in"
  },
  {
    valueId: "EV007514",
    valueDesc: "Fibre optic coupling",
    label: "EV007514: Fibre optic coupling"
  },
  {
    valueId: "EV007515",
    valueDesc: "Fibre optic types",
    label: "EV007515: Fibre optic types"
  },
  {
    valueId: "EV007517",
    valueDesc: "M14",
    label: "EV007517: M14"
  },
  {
    valueId: "EV007518",
    valueDesc: "M18 x 2.5",
    label: "EV007518: M18 x 2.5"
  },
  {
    valueId: "EV007519",
    valueDesc: "Chisel hammer",
    label: "EV007519: Chisel hammer"
  },
  {
    valueId: "EV007520",
    valueDesc: "M-bus (wired)",
    label: "EV007520: M-bus (wired)"
  },
  {
    valueId: "EV007521",
    valueDesc: "Mechanical analogue",
    label: "EV007521: Mechanical analogue"
  },
  {
    valueId: "EV007523",
    valueDesc: "Multiple cassette",
    label: "EV007523: Multiple cassette"
  },
  {
    valueId: "EV007525",
    valueDesc: "Multiple lines",
    label: "EV007525: Multiple lines"
  },
  {
    valueId: "EV007526",
    valueDesc: "Multi purpose connecting clamp",
    label: "EV007526: Multi purpose connecting clamp"
  },
  {
    valueId: "EV007527",
    valueDesc: "Metal roof",
    label: "EV007527: Metal roof"
  },
  {
    valueId: "EV007528",
    valueDesc: "Metal fabric",
    label: "EV007528: Metal fabric"
  },
  {
    valueId: "EV007530",
    valueDesc: "Microwave",
    label: "EV007530: Microwave"
  },
  {
    valueId: "EV007531",
    valueDesc: "Mini GBIC",
    label: "EV007531: Mini GBIC"
  },
  {
    valueId: "EV007532",
    valueDesc: "Mini PCI",
    label: "EV007532: Mini PCI"
  },
  {
    valueId: "EV007534",
    valueDesc: "Minutes/hours",
    label: "EV007534: Minutes/hours"
  },
  {
    valueId: "EV007536",
    valueDesc: "Mixing tube",
    label: "EV007536: Mixing tube"
  },
  {
    valueId: "EV007537",
    valueDesc: "With covering cap",
    label: "EV007537: With covering cap"
  },
  {
    valueId: "EV007538",
    valueDesc: "With plug",
    label: "EV007538: With plug"
  },
  {
    valueId: "EV007544",
    valueDesc: "With hammer-drive pin",
    label: "EV007544: With hammer-drive pin"
  },
  {
    valueId: "EV007547",
    valueDesc: "With pointed wood screw thread",
    label: "EV007547: With pointed wood screw thread"
  },
  {
    valueId: "EV007548",
    valueDesc: "With plug-in socket",
    label: "EV007548: With plug-in socket"
  },
  {
    valueId: "EV007549",
    valueDesc: "With and without battery",
    label: "EV007549: With and without battery"
  },
  {
    valueId: "EV007550",
    valueDesc: "With central disc",
    label: "EV007550: With central disc"
  },
  {
    valueId: "EV007551",
    valueDesc: "Included connector",
    label: "EV007551: Included connector"
  },
  {
    valueId: "EV007553",
    valueDesc: "Centre fuse status indicator",
    label: "EV007553: Centre fuse status indicator"
  },
  {
    valueId: "EV007556",
    valueDesc: "MK1",
    label: "EV007556: MK1"
  },
  {
    valueId: "EV007557",
    valueDesc: "MK2",
    label: "EV007557: MK2"
  },
  {
    valueId: "EV007558",
    valueDesc: "MK3",
    label: "EV007558: MK3"
  },
  {
    valueId: "EV007559",
    valueDesc: "mm",
    label: "EV007559: mm"
  },
  {
    valueId: "EV007560",
    valueDesc: "MMS",
    label: "EV007560: MMS"
  },
  {
    valueId: "EV007562",
    valueDesc: "Modular fire partition",
    label: "EV007562: Modular fire partition"
  },
  {
    valueId: "EV007563",
    valueDesc: "Monochrome/digital",
    label: "EV007563: Monochrome/digital"
  },
  {
    valueId: "EV007564",
    valueDesc: "Mono crystalline",
    label: "EV007564: Mono crystalline"
  },
  {
    valueId: "EV007565",
    valueDesc: "Fireproof mortar partition",
    label: "EV007565: Fireproof mortar partition"
  },
  {
    valueId: "EV007566",
    valueDesc: "Motor in housing",
    label: "EV007566: Motor in housing"
  },
  {
    valueId: "EV007567",
    valueDesc: "Motor in chimney",
    label: "EV007567: Motor in chimney"
  },
  {
    valueId: "EV007569",
    valueDesc: "Waste sorting system",
    label: "EV007569: Waste sorting system"
  },
  {
    valueId: "EV007570",
    valueDesc: "Needle",
    label: "EV007570: Needle"
  },
  {
    valueId: "EV007571",
    valueDesc: "Nail strip",
    label: "EV007571: Nail strip"
  },
  {
    valueId: "EV007572",
    valueDesc: "Nailing",
    label: "EV007572: Nailing"
  },
  {
    valueId: "EV007574",
    valueDesc: "Mains voltage",
    label: "EV007574: Mains voltage"
  },
  {
    valueId: "EV007575",
    valueDesc: "Mains synchronous",
    label: "EV007575: Mains synchronous"
  },
  {
    valueId: "EV007576",
    valueDesc: "Power adaptor",
    label: "EV007576: Power adaptor"
  },
  {
    valueId: "EV007577",
    valueDesc: "Power adaptor/Power over LAN",
    label: "EV007577: Power adaptor/Power over LAN"
  },
  {
    valueId: "EV007578",
    valueDesc: "NH-covering cap",
    label: "EV007578: NH-covering cap"
  },
  {
    valueId: "EV007579",
    valueDesc: "NH-contact cover",
    label: "EV007579: NH-contact cover"
  },
  {
    valueId: "EV007580",
    valueDesc: "NH-contact cover for slider parts",
    label: "EV007580: NH-contact cover for slider parts"
  },
  {
    valueId: "EV007581",
    valueDesc: "NH-safety cover",
    label: "EV007581: NH-safety cover"
  },
  {
    valueId: "EV007582",
    valueDesc: "NH signal indicator",
    label: "EV007582: NH signal indicator"
  },
  {
    valueId: "EV007583",
    valueDesc: "NiCd",
    label: "EV007583: NiCd"
  },
  {
    valueId: "EV007584",
    valueDesc: "NiCd/NiMh",
    label: "EV007584: NiCd/NiMh"
  },
  {
    valueId: "EV007585",
    valueDesc: "NiCd/NiMh/Lithium ions",
    label: "EV007585: NiCd/NiMh/Lithium ions"
  },
  {
    valueId: "EV007586",
    valueDesc: "Non removable worktop",
    label: "EV007586: Non removable worktop"
  },
  {
    valueId: "EV007587",
    valueDesc: "Not washable synthetic fabric",
    label: "EV007587: Not washable synthetic fabric"
  },
  {
    valueId: "EV007588",
    valueDesc: "NiMH",
    label: "EV007588: NiMH"
  },
  {
    valueId: "EV007589",
    valueDesc: "Emergency call transmitter",
    label: "EV007589: Emergency call transmitter"
  },
  {
    valueId: "EV007590",
    valueDesc: "N-ST",
    label: "EV007590: N-ST"
  },
  {
    valueId: "EV007591",
    valueDesc: "Only clamp",
    label: "EV007591: Only clamp"
  },
  {
    valueId: "EV007592",
    valueDesc: "Only mould",
    label: "EV007592: Only mould"
  },
  {
    valueId: "EV007593",
    valueDesc: "Only punch",
    label: "EV007593: Only punch"
  },
  {
    valueId: "EV007594",
    valueDesc: "Top/bottom",
    label: "EV007594: Top/bottom"
  },
  {
    valueId: "EV007595",
    valueDesc: "Joint cover for cover",
    label: "EV007595: Joint cover for cover"
  },
  {
    valueId: "EV007596",
    valueDesc: "Octo LNB",
    label: "EV007596: Octo LNB"
  },
  {
    valueId: "EV007597",
    valueDesc: "Offline",
    label: "EV007597: Offline"
  },
  {
    valueId: "EV007598",
    valueDesc: "Without covering cap",
    label: "EV007598: Without covering cap"
  },
  {
    valueId: "EV007599",
    valueDesc: "Without worktop",
    label: "EV007599: Without worktop"
  },
  {
    valueId: "EV007600",
    valueDesc: "From test object (without battery)",
    label: "EV007600: From test object (without battery)"
  },
  {
    valueId: "EV007601",
    valueDesc: "Without motor",
    label: "EV007601: Without motor"
  },
  {
    valueId: "EV007603",
    valueDesc: "Without start time preselection",
    label: "EV007603: Without start time preselection"
  },
  {
    valueId: "EV007605",
    valueDesc: "Without connector",
    label: "EV007605: Without connector"
  },
  {
    valueId: "EV007606",
    valueDesc: "Ear plug",
    label: "EV007606: Ear plug"
  },
  {
    valueId: "EV007607",
    valueDesc: "Online",
    label: "EV007607: Online"
  },
  {
    valueId: "EV007608",
    valueDesc: "Oval pressing",
    label: "EV007608: Oval pressing"
  },
  {
    valueId: "EV007609",
    valueDesc: "Paper roll",
    label: "EV007609: Paper roll"
  },
  {
    valueId: "EV007610",
    valueDesc: "Paper feed",
    label: "EV007610: Paper feed"
  },
  {
    valueId: "EV007611",
    valueDesc: "Passive infrared",
    label: "EV007611: Passive infrared"
  },
  {
    valueId: "EV007612",
    valueDesc: "Passive infrared/ultrasonic (dual)",
    label: "EV007612: Passive infrared/ultrasonic (dual)"
  },
  {
    valueId: "EV007613",
    valueDesc: "Passive glass breaking detector",
    label: "EV007613: Passive glass breaking detector"
  },
  {
    valueId: "EV007615",
    valueDesc: "PC-Card",
    label: "EV007615: PC-Card"
  },
  {
    valueId: "EV007616",
    valueDesc: "PC-card",
    label: "EV007616: PC-card"
  },
  {
    valueId: "EV007617",
    valueDesc: "Pendant bar",
    label: "EV007617: Pendant bar"
  },
  {
    valueId: "EV007618",
    valueDesc: "Pantile",
    label: "EV007618: Pantile"
  },
  {
    valueId: "EV007619",
    valueDesc: "Fireproof plate partition",
    label: "EV007619: Fireproof plate partition"
  },
  {
    valueId: "EV007620",
    valueDesc: "Graphic plotter",
    label: "EV007620: Graphic plotter"
  },
  {
    valueId: "EV007621",
    valueDesc: "PlusMinus",
    label: "EV007621: PlusMinus"
  },
  {
    valueId: "EV007623",
    valueDesc: "Polyamide/stainless steel",
    label: "EV007623: Polyamide/stainless steel"
  },
  {
    valueId: "EV007624",
    valueDesc: "Poly crystalline",
    label: "EV007624: Poly crystalline"
  },
  {
    valueId: "EV007626",
    valueDesc: "Potential free change-over contact",
    label: "EV007626: Potential free change-over contact"
  },
  {
    valueId: "EV007627",
    valueDesc: "Power over Ethernet (PoE)",
    label: "EV007627: Power over Ethernet (PoE)"
  },
  {
    valueId: "EV007629",
    valueDesc: "Profile rail",
    label: "EV007629: Profile rail"
  },
  {
    valueId: "EV007630",
    valueDesc: "Projector",
    label: "EV007630: Projector"
  },
  {
    valueId: "EV007631",
    valueDesc: "PS 2",
    label: "EV007631: PS 2"
  },
  {
    valueId: "EV007633",
    valueDesc: "Without cover frame",
    label: "EV007633: Without cover frame"
  },
  {
    valueId: "EV007634",
    valueDesc: "Knurled tappet",
    label: "EV007634: Knurled tappet"
  },
  {
    valueId: "EV007635",
    valueDesc: "Snap",
    label: "EV007635: Snap"
  },
  {
    valueId: "EV007636",
    valueDesc: "Grid size 45",
    label: "EV007636: Grid size 45"
  },
  {
    valueId: "EV007646",
    valueDesc: "Right, interchangeable",
    label: "EV007646: Right, interchangeable"
  },
  {
    valueId: "EV007647",
    valueDesc: "Reducing to the left",
    label: "EV007647: Reducing to the left"
  },
  {
    valueId: "EV007648",
    valueDesc: "Reducing to the right",
    label: "EV007648: Reducing to the right"
  },
  {
    valueId: "EV007649",
    valueDesc: "Reducing symmetrical",
    label: "EV007649: Reducing symmetrical"
  },
  {
    valueId: "EV007650",
    valueDesc: "Rain pipe",
    label: "EV007650: Rain pipe"
  },
  {
    valueId: "EV007651",
    valueDesc: "Repeater",
    label: "EV007651: Repeater"
  },
  {
    valueId: "EV007652",
    valueDesc: "RG 11",
    label: "EV007652: RG 11"
  },
  {
    valueId: "EV007653",
    valueDesc: "RG 174",
    label: "EV007653: RG 174"
  },
  {
    valueId: "EV007654",
    valueDesc: "RG 213",
    label: "EV007654: RG 213"
  },
  {
    valueId: "EV007655",
    valueDesc: "RG 214",
    label: "EV007655: RG 214"
  },
  {
    valueId: "EV007656",
    valueDesc: "RG 223",
    label: "EV007656: RG 223"
  },
  {
    valueId: "EV007659",
    valueDesc: "RG 6",
    label: "EV007659: RG 6"
  },
  {
    valueId: "EV007661",
    valueDesc: "RG 71",
    label: "EV007661: RG 71"
  },
  {
    valueId: "EV007662",
    valueDesc: "RG 8",
    label: "EV007662: RG 8"
  },
  {
    valueId: "EV007663",
    valueDesc: "Rhodium",
    label: "EV007663: Rhodium"
  },
  {
    valueId: "EV007664",
    valueDesc: "Rhodium/nickel",
    label: "EV007664: Rhodium/nickel"
  },
  {
    valueId: "EV007672",
    valueDesc: "RJ45-bus",
    label: "EV007672: RJ45-bus"
  },
  {
    valueId: "EV007673",
    valueDesc: "RJ-plug",
    label: "EV007673: RJ-plug"
  },
  {
    valueId: "EV007674",
    valueDesc: "RJ-type",
    label: "EV007674: RJ-type"
  },
  {
    valueId: "EV007675",
    valueDesc: "Pipe/cable",
    label: "EV007675: Pipe/cable"
  },
  {
    valueId: "EV007677",
    valueDesc: "Stainless steel 304 (V2A)/synthetic",
    label: "EV007677: Stainless steel 304 (V2A)/synthetic"
  },
  {
    valueId: "EV007680",
    valueDesc: "R-TNC",
    label: "EV007680: R-TNC"
  },
  {
    valueId: "EV007681",
    valueDesc: "Release-delay",
    label: "EV007681: Release-delay"
  },
  {
    valueId: "EV007682",
    valueDesc: "Round 10 mm",
    label: "EV007682: Round 10 mm"
  },
  {
    valueId: "EV007683",
    valueDesc: "Tubular cable",
    label: "EV007683: Tubular cable"
  },
  {
    valueId: "EV007684",
    valueDesc: "Round head",
    label: "EV007684: Round head"
  },
  {
    valueId: "EV007699",
    valueDesc: "Round conductor Rd 8-10 / flat conductor Fl 30-40",
    label: "EV007699: Round conductor Rd 8-10 / flat conductor Fl 30-40"
  },
  {
    valueId: "EV007704",
    valueDesc: "Satellite/air/cable",
    label: "EV007704: Satellite/air/cable"
  },
  {
    valueId: "EV007705",
    valueDesc: "Sauna control device",
    label: "EV007705: Sauna control device"
  },
  {
    valueId: "EV007707",
    valueDesc: "Switch strip",
    label: "EV007707: Switch strip"
  },
  {
    valueId: "EV007708",
    valueDesc: "Foam blocks",
    label: "EV007708: Foam blocks"
  },
  {
    valueId: "EV007709",
    valueDesc: "Foam stopper",
    label: "EV007709: Foam stopper"
  },
  {
    valueId: "EV007710",
    valueDesc: "Slate",
    label: "EV007710: Slate"
  },
  {
    valueId: "EV007711",
    valueDesc: "Rail fastening",
    label: "EV007711: Rail fastening"
  },
  {
    valueId: "EV007712",
    valueDesc: "Shingle",
    label: "EV007712: Shingle"
  },
  {
    valueId: "EV007713",
    valueDesc: "Key hanger",
    label: "EV007713: Key hanger"
  },
  {
    valueId: "EV007714",
    valueDesc: "Snow catcher clamp",
    label: "EV007714: Snow catcher clamp"
  },
  {
    valueId: "EV007715",
    valueDesc: "Quick release",
    label: "EV007715: Quick release"
  },
  {
    valueId: "EV007716",
    valueDesc: "Ladle",
    label: "EV007716: Ladle"
  },
  {
    valueId: "EV007717",
    valueDesc: "Screw cover",
    label: "EV007717: Screw cover"
  },
  {
    valueId: "EV007718",
    valueDesc: "Screw or spread out",
    label: "EV007718: Screw or spread out"
  },
  {
    valueId: "EV007719",
    valueDesc: "Screw less connector",
    label: "EV007719: Screw less connector"
  },
  {
    valueId: "EV007720",
    valueDesc: "Screw slot",
    label: "EV007720: Screw slot"
  },
  {
    valueId: "EV007721",
    valueDesc: "Screwing set",
    label: "EV007721: Screwing set"
  },
  {
    valueId: "EV007722",
    valueDesc: "Screw connector",
    label: "EV007722: Screw connector"
  },
  {
    valueId: "EV007723",
    valueDesc: "Protective contact CEE 7/3 (type F) with overvoltage protection",
    label: "EV007723: Protective contact CEE 7/3 (type F) with overvoltage protection"
  },
  {
    valueId: "EV007724",
    valueDesc: "Protective strip",
    label: "EV007724: Protective strip"
  },
  {
    valueId: "EV007726",
    valueDesc: "Socket outlet with safety contact",
    label: "EV007726: Socket outlet with safety contact"
  },
  {
    valueId: "EV007727",
    valueDesc: "Protective bag",
    label: "EV007727: Protective bag"
  },
  {
    valueId: "EV007728",
    valueDesc: "Protective bag with belt clip",
    label: "EV007728: Protective bag with belt clip"
  },
  {
    valueId: "EV007729",
    valueDesc: "SC-compatible",
    label: "EV007729: SC-compatible"
  },
  {
    valueId: "EV007730",
    valueDesc: "Hexagon with Plus Minus",
    label: "EV007730: Hexagon with Plus Minus"
  },
  {
    valueId: "EV007732",
    valueDesc: "Seconds/minutes",
    label: "EV007732: Seconds/minutes"
  },
  {
    valueId: "EV007733",
    valueDesc: "Seconds/hours",
    label: "EV007733: Seconds/hours"
  },
  {
    valueId: "EV007737",
    valueDesc: "SFF-compatible",
    label: "EV007737: SFF-compatible"
  },
  {
    valueId: "EV007738",
    valueDesc: "Signal controller",
    label: "EV007738: Signal controller"
  },
  {
    valueId: "EV007739",
    valueDesc: "Signal signalling insert",
    label: "EV007739: Signal signalling insert"
  },
  {
    valueId: "EV007741",
    valueDesc: "Sine",
    label: "EV007741: Sine"
  },
  {
    valueId: "EV007742",
    valueDesc: "SMS",
    label: "EV007742: SMS"
  },
  {
    valueId: "EV007743",
    valueDesc: "SN-ST",
    label: "EV007743: SN-ST"
  },
  {
    valueId: "EV007744",
    valueDesc: "SN-T",
    label: "EV007744: SN-T"
  },
  {
    valueId: "EV007745",
    valueDesc: "Base",
    label: "EV007745: Base"
  },
  {
    valueId: "EV007746",
    valueDesc: "Plinth plate",
    label: "EV007746: Plinth plate"
  },
  {
    valueId: "EV007748",
    valueDesc: "Skirting duct",
    label: "EV007748: Skirting duct"
  },
  {
    valueId: "EV007749",
    valueDesc: "Special telephone",
    label: "EV007749: Special telephone"
  },
  {
    valueId: "EV007751",
    valueDesc: "Stretchable",
    label: "EV007751: Stretchable"
  },
  {
    valueId: "EV007752",
    valueDesc: "Stretch",
    label: "EV007752: Stretch"
  },
  {
    valueId: "EV007754",
    valueDesc: "Memory expansion",
    label: "EV007754: Memory expansion"
  },
  {
    valueId: "EV007756",
    valueDesc: "Speak/ring",
    label: "EV007756: Speak/ring"
  },
  {
    valueId: "EV007757",
    valueDesc: "Spread",
    label: "EV007757: Spread"
  },
  {
    valueId: "EV007758",
    valueDesc: "Die-casting part",
    label: "EV007758: Die-casting part"
  },
  {
    valueId: "EV007759",
    valueDesc: "ST/SC-Duplex",
    label: "EV007759: ST/SC-Duplex"
  },
  {
    valueId: "EV007762",
    valueDesc: "Thermo galvanized steel/plastic",
    label: "EV007762: Thermo galvanized steel/plastic"
  },
  {
    valueId: "EV007763",
    valueDesc: "Steel/plastic",
    label: "EV007763: Steel/plastic"
  },
  {
    valueId: "EV007764",
    valueDesc: "Cast steel",
    label: "EV007764: Cast steel"
  },
  {
    valueId: "EV007765",
    valueDesc: "Standpipe",
    label: "EV007765: Standpipe"
  },
  {
    valueId: "EV007767",
    valueDesc: "Rod clamp",
    label: "EV007767: Rod clamp"
  },
  {
    valueId: "EV007769",
    valueDesc: "Start + Stop",
    label: "EV007769: Start + Stop"
  },
  {
    valueId: "EV007770",
    valueDesc: "Tripod",
    label: "EV007770: Tripod"
  },
  {
    valueId: "EV007771",
    valueDesc: "ST-Duplex",
    label: "EV007771: ST-Duplex"
  },
  {
    valueId: "EV007772",
    valueDesc: "Plug-in cover",
    label: "EV007772: Plug-in cover"
  },
  {
    valueId: "EV007773",
    valueDesc: "Socket outlet strip",
    label: "EV007773: Socket outlet strip"
  },
  {
    valueId: "EV007774",
    valueDesc: "Plug-in system",
    label: "EV007774: Plug-in system"
  },
  {
    valueId: "EV007775",
    valueDesc: "Rim perforated",
    label: "EV007775: Rim perforated"
  },
  {
    valueId: "EV007777",
    valueDesc: "In-wall installation cable",
    label: "EV007777: In-wall installation cable"
  },
  {
    valueId: "EV007778",
    valueDesc: "Seamed joint zinc",
    label: "EV007778: Seamed joint zinc"
  },
  {
    valueId: "EV007780",
    valueDesc: "Stones",
    label: "EV007780: Stones"
  },
  {
    valueId: "EV007781",
    valueDesc: "Profile console",
    label: "EV007781: Profile console"
  },
  {
    valueId: "EV007782",
    valueDesc: "Top fuse status indicator",
    label: "EV007782: Top fuse status indicator"
  },
  {
    valueId: "EV007783",
    valueDesc: "ST-compatible",
    label: "EV007783: ST-compatible"
  },
  {
    valueId: "EV007784",
    valueDesc: "ST-POF",
    label: "EV007784: ST-POF"
  },
  {
    valueId: "EV007785",
    valueDesc: "Distance",
    label: "EV007785: Distance"
  },
  {
    valueId: "EV007787",
    valueDesc: "Current surge switch with delay on de-energization",
    label: "EV007787: Current surge switch with delay on de-energization"
  },
  {
    valueId: "EV007789",
    valueDesc: "Trimmable step membrane",
    label: "EV007789: Trimmable step membrane"
  },
  {
    valueId: "EV007790",
    valueDesc: "Hour",
    label: "EV007790: Hour"
  },
  {
    valueId: "EV007791",
    valueDesc: "Hour-day clock",
    label: "EV007791: Hour-day clock"
  },
  {
    valueId: "EV007792",
    valueDesc: "Hour clock",
    label: "EV007792: Hour clock"
  },
  {
    valueId: "EV007793",
    valueDesc: "D-Sub",
    label: "EV007793: D-Sub"
  },
  {
    valueId: "EV007794",
    valueDesc: "HD-D-Sub",
    label: "EV007794: HD-D-Sub"
  },
  {
    valueId: "EV007795",
    valueDesc: "SUN NET manager/UNIX",
    label: "EV007795: SUN NET manager/UNIX"
  },
  {
    valueId: "EV007796",
    valueDesc: "Super fast (FF)",
    label: "EV007796: Super fast (FF)"
  },
  {
    valueId: "EV007797",
    valueDesc: "Super slow (TT)",
    label: "EV007797: Super slow (TT)"
  },
  {
    valueId: "EV007799",
    valueDesc: "System 71",
    label: "EV007799: System 71"
  },
  {
    valueId: "EV007810",
    valueDesc: "TAE F/RJ",
    label: "EV007810: TAE F/RJ"
  },
  {
    valueId: "EV007811",
    valueDesc: "TAE N",
    label: "EV007811: TAE N"
  },
  {
    valueId: "EV007812",
    valueDesc: "TAE NFF",
    label: "EV007812: TAE NFF"
  },
  {
    valueId: "EV007813",
    valueDesc: "TAE NFF/RJ11",
    label: "EV007813: TAE NFF/RJ11"
  },
  {
    valueId: "EV007814",
    valueDesc: "TAE NFN",
    label: "EV007814: TAE NFN"
  },
  {
    valueId: "EV007815",
    valueDesc: "TAE NFN/RJ11",
    label: "EV007815: TAE NFN/RJ11"
  },
  {
    valueId: "EV007816",
    valueDesc: "TAE F",
    label: "EV007816: TAE F"
  },
  {
    valueId: "EV007818",
    valueDesc: "TAE N/F/N",
    label: "EV007818: TAE N/F/N"
  },
  {
    valueId: "EV007819",
    valueDesc: "TAE-connector",
    label: "EV007819: TAE-connector"
  },
  {
    valueId: "EV007820",
    valueDesc: "Day-/day clock",
    label: "EV007820: Day-/day clock"
  },
  {
    valueId: "EV007821",
    valueDesc: "Day-/week clock",
    label: "EV007821: Day-/week clock"
  },
  {
    valueId: "EV007823",
    valueDesc: "Day clock",
    label: "EV007823: Day clock"
  },
  {
    valueId: "EV007824",
    valueDesc: "Timer starting with impulse",
    label: "EV007824: Timer starting with impulse"
  },
  {
    valueId: "EV007825",
    valueDesc: "Timer starting with pause",
    label: "EV007825: Timer starting with pause"
  },
  {
    valueId: "EV007828",
    valueDesc: "Separable",
    label: "EV007828: Separable"
  },
  {
    valueId: "EV007829",
    valueDesc: "Partially electronic",
    label: "EV007829: Partially electronic"
  },
  {
    valueId: "EV007830",
    valueDesc: "Telephone connection",
    label: "EV007830: Telephone connection"
  },
  {
    valueId: "EV007831",
    valueDesc: "Telescope",
    label: "EV007831: Telescope"
  },
  {
    valueId: "EV007832",
    valueDesc: "Plate holder",
    label: "EV007832: Plate holder"
  },
  {
    valueId: "EV007833",
    valueDesc: "Temperature meter",
    label: "EV007833: Temperature meter"
  },
  {
    valueId: "EV007836",
    valueDesc: "Thermal colour tape",
    label: "EV007836: Thermal colour tape"
  },
  {
    valueId: "EV007837",
    valueDesc: "Thermo sensitive",
    label: "EV007837: Thermo sensitive"
  },
  {
    valueId: "EV007838",
    valueDesc: "Thermo transfer",
    label: "EV007838: Thermo transfer"
  },
  {
    valueId: "EV007839",
    valueDesc: "Thermo transfer paper",
    label: "EV007839: Thermo transfer paper"
  },
  {
    valueId: "EV007840",
    valueDesc: "Inkjet cartridge",
    label: "EV007840: Inkjet cartridge"
  },
  {
    valueId: "EV007841",
    valueDesc: "Table",
    label: "EV007841: Table"
  },
  {
    valueId: "EV007843",
    valueDesc: "Desk type device/wall mounted",
    label: "EV007843: Desk type device/wall mounted"
  },
  {
    valueId: "EV007849",
    valueDesc: "TN-S",
    label: "EV007849: TN-S"
  },
  {
    valueId: "EV007851",
    valueDesc: "Toner",
    label: "EV007851: Toner"
  },
  {
    valueId: "EV007852",
    valueDesc: "Toner/drum",
    label: "EV007852: Toner/drum"
  },
  {
    valueId: "EV007853",
    valueDesc: "Toner cartridge",
    label: "EV007853: Toner cartridge"
  },
  {
    valueId: "EV007854",
    valueDesc: "Carrying handle",
    label: "EV007854: Carrying handle"
  },
  {
    valueId: "EV007855",
    valueDesc: "Support shape",
    label: "EV007855: Support shape"
  },
  {
    valueId: "EV007856",
    valueDesc: "Supporting ring",
    label: "EV007856: Supporting ring"
  },
  {
    valueId: "EV007861",
    valueDesc: "Carrier bag",
    label: "EV007861: Carrier bag"
  },
  {
    valueId: "EV007862",
    valueDesc: "Tear plate",
    label: "EV007862: Tear plate"
  },
  {
    valueId: "EV007863",
    valueDesc: "Trapezoid",
    label: "EV007863: Trapezoid"
  },
  {
    valueId: "EV007864",
    valueDesc: "Trapezoidal fastening",
    label: "EV007864: Trapezoidal fastening"
  },
  {
    valueId: "EV007865",
    valueDesc: "Trapezoidal plate",
    label: "EV007865: Trapezoidal plate"
  },
  {
    valueId: "EV007866",
    valueDesc: "Trapezoidal blade",
    label: "EV007866: Trapezoidal blade"
  },
  {
    valueId: "EV007869",
    valueDesc: "Dry",
    label: "EV007869: Dry"
  },
  {
    valueId: "EV007870",
    valueDesc: "Drum",
    label: "EV007870: Drum"
  },
  {
    valueId: "EV007871",
    valueDesc: "T-piece horizontal",
    label: "EV007871: T-piece horizontal"
  },
  {
    valueId: "EV007872",
    valueDesc: "T-piece vertical falling along",
    label: "EV007872: T-piece vertical falling along"
  },
  {
    valueId: "EV007873",
    valueDesc: "T-piece vertical falling crossed",
    label: "EV007873: T-piece vertical falling crossed"
  },
  {
    valueId: "EV007878",
    valueDesc: "Tube cable outlet for floor duct",
    label: "EV007878: Tube cable outlet for floor duct"
  },
  {
    valueId: "EV007879",
    valueDesc: "Tunnel branch pipe",
    label: "EV007879: Tunnel branch pipe"
  },
  {
    valueId: "EV007880",
    valueDesc: "Door interface",
    label: "EV007880: Door interface"
  },
  {
    valueId: "EV007881",
    valueDesc: "Door diversion duct",
    label: "EV007881: Door diversion duct"
  },
  {
    valueId: "EV007885",
    valueDesc: "Twinax-connector",
    label: "EV007885: Twinax-connector"
  },
  {
    valueId: "EV007886",
    valueDesc: "Twinax-cable",
    label: "EV007886: Twinax-cable"
  },
  {
    valueId: "EV007887",
    valueDesc: "Type 1",
    label: "EV007887: Type 1"
  },
  {
    valueId: "EV007888",
    valueDesc: "Type 1 + 2",
    label: "EV007888: Type 1 + 2"
  },
  {
    valueId: "EV007889",
    valueDesc: "Daisywheel/ball",
    label: "EV007889: Daisywheel/ball"
  },
  {
    valueId: "EV007891",
    valueDesc: ">40 mm",
    label: "EV007891: >40 mm"
  },
  {
    valueId: "EV007892",
    valueDesc: "Fader",
    label: "EV007892: Fader"
  },
  {
    valueId: "EV007893",
    valueDesc: "UHF connector",
    label: "EV007893: UHF connector"
  },
  {
    valueId: "EV007894",
    valueDesc: "Modifiable",
    label: "EV007894: Modifiable"
  },
  {
    valueId: "EV007895",
    valueDesc: "Unix",
    label: "EV007895: Unix"
  },
  {
    valueId: "EV007897",
    valueDesc: "Built-under mounting set",
    label: "EV007897: Built-under mounting set"
  },
  {
    valueId: "EV007898",
    valueDesc: "Subfloor outlet box",
    label: "EV007898: Subfloor outlet box"
  },
  {
    valueId: "EV007900",
    valueDesc: "Subfloor installation duct flush floor open",
    label: "EV007900: Subfloor installation duct flush floor open"
  },
  {
    valueId: "EV007901",
    valueDesc: "Girder",
    label: "EV007901: Girder"
  },
  {
    valueId: "EV007903",
    valueDesc: "Base unit cover",
    label: "EV007903: Base unit cover"
  },
  {
    valueId: "EV007904",
    valueDesc: "Joint cover base unit",
    label: "EV007904: Joint cover base unit"
  },
  {
    valueId: "EV007905",
    valueDesc: "Underwater housing",
    label: "EV007905: Underwater housing"
  },
  {
    valueId: "EV007907",
    valueDesc: "UPO-port",
    label: "EV007907: UPO-port"
  },
  {
    valueId: "EV007908",
    valueDesc: "U-profile double-sided",
    label: "EV007908: U-profile double-sided"
  },
  {
    valueId: "EV007909",
    valueDesc: "USB-A",
    label: "EV007909: USB-A"
  },
  {
    valueId: "EV007910",
    valueDesc: "USB-B",
    label: "EV007910: USB-B"
  },
  {
    valueId: "EV007914",
    valueDesc: "Ventilation-lag switch",
    label: "EV007914: Ventilation-lag switch"
  },
  {
    valueId: "EV007915",
    valueDesc: "Connector",
    label: "EV007915: Connector"
  },
  {
    valueId: "EV007916",
    valueDesc: "Screw tight",
    label: "EV007916: Screw tight"
  },
  {
    valueId: "EV007917",
    valueDesc: "Distributor case",
    label: "EV007917: Distributor case"
  },
  {
    valueId: "EV007918",
    valueDesc: "Distribution strip",
    label: "EV007918: Distribution strip"
  },
  {
    valueId: "EV007919",
    valueDesc: "Iron wire, galvanized",
    label: "EV007919: Iron wire, galvanized"
  },
  {
    valueId: "EV007922",
    valueDesc: "Square branch pipe",
    label: "EV007922: Square branch pipe"
  },
  {
    valueId: "EV007923",
    valueDesc: "Curtain",
    label: "EV007923: Curtain"
  },
  {
    valueId: "EV007924",
    valueDesc: "Pre-mounted in housing",
    label: "EV007924: Pre-mounted in housing"
  },
  {
    valueId: "EV007926",
    valueDesc: "Front side",
    label: "EV007926: Front side"
  },
  {
    valueId: "EV007927",
    valueDesc: "Front and top",
    label: "EV007927: Front and top"
  },
  {
    valueId: "EV007928",
    valueDesc: "Wacker",
    label: "EV007928: Wacker"
  },
  {
    valueId: "EV007929",
    valueDesc: "Dialling/remote control device",
    label: "EV007929: Dialling/remote control device"
  },
  {
    valueId: "EV007930",
    valueDesc: "Wall- and profile console",
    label: "EV007930: Wall- and profile console"
  },
  {
    valueId: "EV007931",
    valueDesc: "Wall-/support console",
    label: "EV007931: Wall-/support console"
  },
  {
    valueId: "EV007932",
    valueDesc: "Wall connecting angle",
    label: "EV007932: Wall connecting angle"
  },
  {
    valueId: "EV007933",
    valueDesc: "Wall chimney",
    label: "EV007933: Wall chimney"
  },
  {
    valueId: "EV007934",
    valueDesc: "Warm",
    label: "EV007934: Warm"
  },
  {
    valueId: "EV007935",
    valueDesc: "Washable synthetic fabric",
    label: "EV007935: Washable synthetic fabric"
  },
  {
    valueId: "EV007936",
    valueDesc: "Interchangeable",
    label: "EV007936: Interchangeable"
  },
  {
    valueId: "EV007937",
    valueDesc: "Soft",
    label: "EV007937: Soft"
  },
  {
    valueId: "EV007938",
    valueDesc: "Fireproof partition (soft)",
    label: "EV007938: Fireproof partition (soft)"
  },
  {
    valueId: "EV007942",
    valueDesc: "Corrugated asbestos cement",
    label: "EV007942: Corrugated asbestos cement"
  },
  {
    valueId: "EV007944",
    valueDesc: "Windows",
    label: "EV007944: Windows"
  },
  {
    valueId: "EV007948",
    valueDesc: "Week clock",
    label: "EV007948: Week clock"
  },
  {
    valueId: "EV007953",
    valueDesc: "Zinc/nickel",
    label: "EV007953: Zinc/nickel"
  },
  {
    valueId: "EV007954",
    valueDesc: "Zinc/nickel alloy",
    label: "EV007954: Zinc/nickel alloy"
  },
  {
    valueId: "EV007955",
    valueDesc: "Zinc/steel galvanized",
    label: "EV007955: Zinc/steel galvanized"
  },
  {
    valueId: "EV007957",
    valueDesc: "To hang in",
    label: "EV007957: To hang in"
  },
  {
    valueId: "EV007958",
    valueDesc: "Screw model",
    label: "EV007958: Screw model"
  },
  {
    valueId: "EV007959",
    valueDesc: "Support bell",
    label: "EV007959: Support bell"
  },
  {
    valueId: "EV007960",
    valueDesc: "Two",
    label: "EV007960: Two"
  },
  {
    valueId: "EV007964",
    valueDesc: "Adapter plug with socket outlet",
    label: "EV007964: Adapter plug with socket outlet"
  },
  {
    valueId: "EV007965",
    valueDesc: "Cheese head",
    label: "EV007965: Cheese head"
  },
  {
    valueId: "EV007966",
    valueDesc: "Enamelled sole",
    label: "EV007966: Enamelled sole"
  },
  {
    valueId: "EV007967",
    valueDesc: "Plastic-coated sole",
    label: "EV007967: Plastic-coated sole"
  },
  {
    valueId: "EV007968",
    valueDesc: "Completely electronic",
    label: "EV007968: Completely electronic"
  },
  {
    valueId: "EV007970",
    valueDesc: "3xgrey / 1xblue / 1xgreen/yellow",
    label: "EV007970: 3xgrey / 1xblue / 1xgreen/yellow"
  },
  {
    valueId: "EV007971",
    valueDesc: "Sand-cast",
    label: "EV007971: Sand-cast"
  },
  {
    valueId: "EV007973",
    valueDesc: "Internal toothing",
    label: "EV007973: Internal toothing"
  },
  {
    valueId: "EV007974",
    valueDesc: "External toothing",
    label: "EV007974: External toothing"
  },
  {
    valueId: "EV007975",
    valueDesc: "Hook-and-loop fastener tape",
    label: "EV007975: Hook-and-loop fastener tape"
  },
  {
    valueId: "EV007976",
    valueDesc: "Knotted tie",
    label: "EV007976: Knotted tie"
  },
  {
    valueId: "EV007977",
    valueDesc: "Pen light",
    label: "EV007977: Pen light"
  },
  {
    valueId: "EV007978",
    valueDesc: "Button cell",
    label: "EV007978: Button cell"
  },
  {
    valueId: "EV007979",
    valueDesc: "Built-in battery",
    label: "EV007979: Built-in battery"
  },
  {
    valueId: "EV007981",
    valueDesc: "Two-part button",
    label: "EV007981: Two-part button"
  },
  {
    valueId: "EV007983",
    valueDesc: "0 - 10 V",
    label: "EV007983: 0 - 10 V"
  },
  {
    valueId: "EV007984",
    valueDesc: "0 - 20 mA",
    label: "EV007984: 0 - 20 mA"
  },
  {
    valueId: "EV007985",
    valueDesc: "10",
    label: "EV007985: 10"
  },
  {
    valueId: "EV007986",
    valueDesc: "10 inch",
    label: "EV007986: 10 inch"
  },
  {
    valueId: "EV007987",
    valueDesc: "100 %",
    label: "EV007987: 100 %"
  },
  {
    valueId: "EV007988",
    valueDesc: "100 m",
    label: "EV007988: 100 m"
  },
  {
    valueId: "EV007989",
    valueDesc: "1000 m",
    label: "EV007989: 1000 m"
  },
  {
    valueId: "EV007990",
    valueDesc: "12",
    label: "EV007990: 12"
  },
  {
    valueId: "EV007993",
    valueDesc: "16",
    label: "EV007993: 16"
  },
  {
    valueId: "EV007995",
    valueDesc: "431.8 mm (17 inch)",
    label: "EV007995: 431.8 mm (17 inch)"
  },
  {
    valueId: "EV007996",
    valueDesc: "16/19 mm",
    label: "EV007996: 16/19 mm"
  },
  {
    valueId: "EV007999",
    valueDesc: "20",
    label: "EV007999: 20"
  },
  {
    valueId: "EV008000",
    valueDesc: "20 min",
    label: "EV008000: 20 min"
  },
  {
    valueId: "EV008002",
    valueDesc: "200 m",
    label: "EV008002: 200 m"
  },
  {
    valueId: "EV008003",
    valueDesc: "2000 m",
    label: "EV008003: 2000 m"
  },
  {
    valueId: "EV008004",
    valueDesc: "533.4 mm (21 inch)",
    label: "EV008004: 533.4 mm (21 inch)"
  },
  {
    valueId: "EV008005",
    valueDesc: "584.2 mm (23 inch)",
    label: "EV008005: 584.2 mm (23 inch)"
  },
  {
    valueId: "EV008006",
    valueDesc: "609.6 mm (24 inch)",
    label: "EV008006: 609.6 mm (24 inch)"
  },
  {
    valueId: "EV008007",
    valueDesc: "30",
    label: "EV008007: 30"
  },
  {
    valueId: "EV008008",
    valueDesc: "300 m",
    label: "EV008008: 300 m"
  },
  {
    valueId: "EV008009",
    valueDesc: "32",
    label: "EV008009: 32"
  },
  {
    valueId: "EV008011",
    valueDesc: "4 - 20 mA",
    label: "EV008011: 4 - 20 mA"
  },
  {
    valueId: "EV008013",
    valueDesc: "50 Hz",
    label: "EV008013: 50 Hz"
  },
  {
    valueId: "EV008015",
    valueDesc: "50/60 Hz",
    label: "EV008015: 50/60 Hz"
  },
  {
    valueId: "EV008016",
    valueDesc: "500 m",
    label: "EV008016: 500 m"
  },
  {
    valueId: "EV008017",
    valueDesc: "60 Hz",
    label: "EV008017: 60 Hz"
  },
  {
    valueId: "EV008019",
    valueDesc: "75°",
    label: "EV008019: 75°"
  },
  {
    valueId: "EV008022",
    valueDesc: "Alkaline",
    label: "EV008022: Alkaline"
  },
  {
    valueId: "EV008023",
    valueDesc: "Aluminium foil",
    label: "EV008023: Aluminium foil"
  },
  {
    valueId: "EV008024",
    valueDesc: "B10d",
    label: "EV008024: B10d"
  },
  {
    valueId: "EV008028",
    valueDesc: "Bracket",
    label: "EV008028: Bracket"
  },
  {
    valueId: "EV008029",
    valueDesc: "Internal- and external thread",
    label: "EV008029: Internal- and external thread"
  },
  {
    valueId: "EV008031",
    valueDesc: "Concise",
    label: "EV008031: Concise"
  },
  {
    valueId: "EV008034",
    valueDesc: "BX10d",
    label: "EV008034: BX10d"
  },
  {
    valueId: "EV008039",
    valueDesc: "Pressure",
    label: "EV008039: Pressure"
  },
  {
    valueId: "EV008042",
    valueDesc: "Laminated",
    label: "EV008042: Laminated"
  },
  {
    valueId: "EV008043",
    valueDesc: "Profiled",
    label: "EV008043: Profiled"
  },
  {
    valueId: "EV008045",
    valueDesc: "Groove",
    label: "EV008045: Groove"
  },
  {
    valueId: "EV008050",
    valueDesc: "Heavy",
    label: "EV008050: Heavy"
  },
  {
    valueId: "EV008051",
    valueDesc: "Weak",
    label: "EV008051: Weak"
  },
  {
    valueId: "EV008057",
    valueDesc: "Smooth",
    label: "EV008057: Smooth"
  },
  {
    valueId: "EV008058",
    valueDesc: "1.25d",
    label: "EV008058: 1.25d"
  },
  {
    valueId: "EV008059",
    valueDesc: "1d",
    label: "EV008059: 1d"
  },
  {
    valueId: "EV008063",
    valueDesc: "AA",
    label: "EV008063: AA"
  },
  {
    valueId: "EV008064",
    valueDesc: "AAA",
    label: "EV008064: AAA"
  },
  {
    valueId: "EV008066",
    valueDesc: "Acetylene",
    label: "EV008066: Acetylene"
  },
  {
    valueId: "EV008069",
    valueDesc: "Adapter plate",
    label: "EV008069: Adapter plate"
  },
  {
    valueId: "EV008071",
    valueDesc: "Cover ring key switch",
    label: "EV008071: Cover ring key switch"
  },
  {
    valueId: "EV008072",
    valueDesc: "Sealing",
    label: "EV008072: Sealing"
  },
  {
    valueId: "EV008074",
    valueDesc: "Ampere",
    label: "EV008074: Ampere"
  },
  {
    valueId: "EV008075",
    valueDesc: "Antenna",
    label: "EV008075: Antenna"
  },
  {
    valueId: "EV008077",
    valueDesc: "Contact protection end piece",
    label: "EV008077: Contact protection end piece"
  },
  {
    valueId: "EV008078",
    valueDesc: "Contact protection rail",
    label: "EV008078: Contact protection rail"
  },
  {
    valueId: "EV008079",
    valueDesc: "Contact protection support",
    label: "EV008079: Contact protection support"
  },
  {
    valueId: "EV008080",
    valueDesc: "Bevel",
    label: "EV008080: Bevel"
  },
  {
    valueId: "EV008083",
    valueDesc: "AMP CO",
    label: "EV008083: AMP CO"
  },
  {
    valueId: "EV008084",
    valueDesc: "One and a half",
    label: "EV008084: One and a half"
  },
  {
    valueId: "EV008085",
    valueDesc: "Ball pen",
    label: "EV008085: Ball pen"
  },
  {
    valueId: "EV008086",
    valueDesc: "Astronomical disc",
    label: "EV008086: Astronomical disc"
  },
  {
    valueId: "EV008087",
    valueDesc: "BA5s",
    label: "EV008087: BA5s"
  },
  {
    valueId: "EV008088",
    valueDesc: "Oxygen",
    label: "EV008088: Oxygen"
  },
  {
    valueId: "EV008090",
    valueDesc: "IPX/SPX",
    label: "EV008090: IPX/SPX"
  },
  {
    valueId: "EV008092",
    valueDesc: "Mast",
    label: "EV008092: Mast"
  },
  {
    valueId: "EV008093",
    valueDesc: "Potentiometer",
    label: "EV008093: Potentiometer"
  },
  {
    valueId: "EV008094",
    valueDesc: "Series",
    label: "EV008094: Series"
  },
  {
    valueId: "EV008095",
    valueDesc: "Pull",
    label: "EV008095: Pull"
  },
  {
    valueId: "EV008096",
    valueDesc: "Anti twisting nut",
    label: "EV008096: Anti twisting nut"
  },
  {
    valueId: "EV008098",
    valueDesc: "Protective door",
    label: "EV008098: Protective door"
  },
  {
    valueId: "EV008101",
    valueDesc: "Mounting nut",
    label: "EV008101: Mounting nut"
  },
  {
    valueId: "EV008102",
    valueDesc: "Internal hexagon with pin",
    label: "EV008102: Internal hexagon with pin"
  },
  {
    valueId: "EV008103",
    valueDesc: "Bottom adapter",
    label: "EV008103: Bottom adapter"
  },
  {
    valueId: "EV008106",
    valueDesc: "Pipe shears",
    label: "EV008106: Pipe shears"
  },
  {
    valueId: "EV008107",
    valueDesc: "External plunger",
    label: "EV008107: External plunger"
  },
  {
    valueId: "EV008108",
    valueDesc: "Inner plunger",
    label: "EV008108: Inner plunger"
  },
  {
    valueId: "EV008110",
    valueDesc: "Butane gas",
    label: "EV008110: Butane gas"
  },
  {
    valueId: "EV008114",
    valueDesc: "Central installation box/built-in installation box",
    label: "EV008114: Central installation box/built-in installation box"
  },
  {
    valueId: "EV008116",
    valueDesc: "Housing mounting",
    label: "EV008116: Housing mounting"
  },
  {
    valueId: "EV008118",
    valueDesc: "Cylinder with knurl",
    label: "EV008118: Cylinder with knurl"
  },
  {
    valueId: "EV008119",
    valueDesc: "Cylinder hexagon",
    label: "EV008119: Cylinder hexagon"
  },
  {
    valueId: "EV008123",
    valueDesc: "Coding plate",
    label: "EV008123: Coding plate"
  },
  {
    valueId: "EV008124",
    valueDesc: "Combination double socket outlet",
    label: "EV008124: Combination double socket outlet"
  },
  {
    valueId: "EV008125",
    valueDesc: "Communication module",
    label: "EV008125: Communication module"
  },
  {
    valueId: "EV008126",
    valueDesc: "Capacitor block",
    label: "EV008126: Capacitor block"
  },
  {
    valueId: "EV008127",
    valueDesc: "Connector remote control",
    label: "EV008127: Connector remote control"
  },
  {
    valueId: "EV008128",
    valueDesc: "Connector transition",
    label: "EV008128: Connector transition"
  },
  {
    valueId: "EV008129",
    valueDesc: "Connector mounting",
    label: "EV008129: Connector mounting"
  },
  {
    valueId: "EV008130",
    valueDesc: "Constantan alloy",
    label: "EV008130: Constantan alloy"
  },
  {
    valueId: "EV008132",
    valueDesc: "Contact element puller",
    label: "EV008132: Contact element puller"
  },
  {
    valueId: "EV008133",
    valueDesc: "Contact set",
    label: "EV008133: Contact set"
  },
  {
    valueId: "EV008134",
    valueDesc: "Contact amplifying bracket",
    label: "EV008134: Contact amplifying bracket"
  },
  {
    valueId: "EV008135",
    valueDesc: "Continuous-/flashing light",
    label: "EV008135: Continuous-/flashing light"
  },
  {
    valueId: "EV008140",
    valueDesc: "Cream",
    label: "EV008140: Cream"
  },
  {
    valueId: "EV008144",
    valueDesc: "DD",
    label: "EV008144: DD"
  },
  {
    valueId: "EV008145",
    valueDesc: "Cover with hook",
    label: "EV008145: Cover with hook"
  },
  {
    valueId: "EV008146",
    valueDesc: "Door contact",
    label: "EV008146: Door contact"
  },
  {
    valueId: "EV008147",
    valueDesc: "DIN-rail-adapter",
    label: "EV008147: DIN-rail-adapter"
  },
  {
    valueId: "EV008148",
    valueDesc: "Diode block",
    label: "EV008148: Diode block"
  },
  {
    valueId: "EV008149",
    valueDesc: "Diode element",
    label: "EV008149: Diode element"
  },
  {
    valueId: "EV008151",
    valueDesc: "Continuous C-profile",
    label: "EV008151: Continuous C-profile"
  },
  {
    valueId: "EV008152",
    valueDesc: "Continuous DIN-rail",
    label: "EV008152: Continuous DIN-rail"
  },
  {
    valueId: "EV008153",
    valueDesc: "Continuous other profile",
    label: "EV008153: Continuous other profile"
  },
  {
    valueId: "EV008155",
    valueDesc: "Feed-through block",
    label: "EV008155: Feed-through block"
  },
  {
    valueId: "EV008156",
    valueDesc: "Single-coloured",
    label: "EV008156: Single-coloured"
  },
  {
    valueId: "EV008157",
    valueDesc: "Eccentric",
    label: "EV008157: Eccentric"
  },
  {
    valueId: "EV008158",
    valueDesc: "Hole",
    label: "EV008158: Hole"
  },
  {
    valueId: "EV008160",
    valueDesc: "Sensor",
    label: "EV008160: Sensor"
  },
  {
    valueId: "EV008166",
    valueDesc: "Sisal",
    label: "EV008166: Sisal"
  },
  {
    valueId: "EV008169",
    valueDesc: "Pull contact",
    label: "EV008169: Pull contact"
  },
  {
    valueId: "EV008171",
    valueDesc: "Pull cable",
    label: "EV008171: Pull cable"
  },
  {
    valueId: "EV008172",
    valueDesc: "Foot",
    label: "EV008172: Foot"
  },
  {
    valueId: "EV008176",
    valueDesc: "Volt",
    label: "EV008176: Volt"
  },
  {
    valueId: "EV008181",
    valueDesc: "Hexagon with pressed ring",
    label: "EV008181: Hexagon with pressed ring"
  },
  {
    valueId: "EV008193",
    valueDesc: "Watt",
    label: "EV008193: Watt"
  },
  {
    valueId: "EV008197",
    valueDesc: "04 (steel)",
    label: "EV008197: 04 (steel)"
  },
  {
    valueId: "EV008198",
    valueDesc: "10 (steel)",
    label: "EV008198: 10 (steel)"
  },
  {
    valueId: "EV008199",
    valueDesc: "10.9 (steel)",
    label: "EV008199: 10.9 (steel)"
  },
  {
    valueId: "EV008201",
    valueDesc: "12.9 (steel)",
    label: "EV008201: 12.9 (steel)"
  },
  {
    valueId: "EV008202",
    valueDesc: "14H (steel)",
    label: "EV008202: 14H (steel)"
  },
  {
    valueId: "EV008203",
    valueDesc: "3.6 (steel)",
    label: "EV008203: 3.6 (steel)"
  },
  {
    valueId: "EV008205",
    valueDesc: "4.6/5.6 (steel)",
    label: "EV008205: 4.6/5.6 (steel)"
  },
  {
    valueId: "EV008206",
    valueDesc: "4.8 (steel)",
    label: "EV008206: 4.8 (steel)"
  },
  {
    valueId: "EV008207",
    valueDesc: "4/5 (steel)",
    label: "EV008207: 4/5 (steel)"
  },
  {
    valueId: "EV008208",
    valueDesc: "45H (steel)",
    label: "EV008208: 45H (steel)"
  },
  {
    valueId: "EV008209",
    valueDesc: "5 (steel)",
    label: "EV008209: 5 (steel)"
  },
  {
    valueId: "EV008210",
    valueDesc: "5.6 (steel)",
    label: "EV008210: 5.6 (steel)"
  },
  {
    valueId: "EV008211",
    valueDesc: "5.8 (steel)",
    label: "EV008211: 5.8 (steel)"
  },
  {
    valueId: "EV008212",
    valueDesc: "5.2 (steel)",
    label: "EV008212: 5.2 (steel)"
  },
  {
    valueId: "EV008213",
    valueDesc: "6 (steel)",
    label: "EV008213: 6 (steel)"
  },
  {
    valueId: "EV008214",
    valueDesc: "6/8 (steel)",
    label: "EV008214: 6/8 (steel)"
  },
  {
    valueId: "EV008215",
    valueDesc: "8 (steel)",
    label: "EV008215: 8 (steel)"
  },
  {
    valueId: "EV008216",
    valueDesc: "8.8 (steel)",
    label: "EV008216: 8.8 (steel)"
  },
  {
    valueId: "EV008217",
    valueDesc: "8.8/10.9 (steel)",
    label: "EV008217: 8.8/10.9 (steel)"
  },
  {
    valueId: "EV008218",
    valueDesc: "8/10 (steel)",
    label: "EV008218: 8/10 (steel)"
  },
  {
    valueId: "EV008220",
    valueDesc: "A193 B7/A194 2H (steel)",
    label: "EV008220: A193 B7/A194 2H (steel)"
  },
  {
    valueId: "EV008221",
    valueDesc: "A320 L7/A194-4 (steel)",
    label: "EV008221: A320 L7/A194-4 (steel)"
  },
  {
    valueId: "EV008222",
    valueDesc: "C15 (steel)",
    label: "EV008222: C15 (steel)"
  },
  {
    valueId: "EV008228",
    valueDesc: "Triangle/delta",
    label: "EV008228: Triangle/delta"
  },
  {
    valueId: "EV008230",
    valueDesc: "C-profile double-sided",
    label: "EV008230: C-profile double-sided"
  },
  {
    valueId: "EV008231",
    valueDesc: "Cross",
    label: "EV008231: Cross"
  },
  {
    valueId: "EV008233",
    valueDesc: "1 lip",
    label: "EV008233: 1 lip"
  },
  {
    valueId: "EV008235",
    valueDesc: "EX 10",
    label: "EV008235: EX 10"
  },
  {
    valueId: "EV008237",
    valueDesc: "Fixing groove",
    label: "EV008237: Fixing groove"
  },
  {
    valueId: "EV008238",
    valueDesc: "Phosphor bronze",
    label: "EV008238: Phosphor bronze"
  },
  {
    valueId: "EV008239",
    valueDesc: "Holdall",
    label: "EV008239: Holdall"
  },
  {
    valueId: "EV008240",
    valueDesc: "Front",
    label: "EV008240: Front"
  },
  {
    valueId: "EV008241",
    valueDesc: "Front mounting",
    label: "EV008241: Front mounting"
  },
  {
    valueId: "EV008242",
    valueDesc: "Waved",
    label: "EV008242: Waved"
  },
  {
    valueId: "EV008243",
    valueDesc: "Conductive",
    label: "EV008243: Conductive"
  },
  {
    valueId: "EV008244",
    valueDesc: "Equal",
    label: "EV008244: Equal"
  },
  {
    valueId: "EV008247",
    valueDesc: "Closed internal toothing",
    label: "EV008247: Closed internal toothing"
  },
  {
    valueId: "EV008248",
    valueDesc: "Closed external toothing",
    label: "EV008248: Closed external toothing"
  },
  {
    valueId: "EV008249",
    valueDesc: "Bulged",
    label: "EV008249: Bulged"
  },
  {
    valueId: "EV008252",
    valueDesc: "Hand",
    label: "EV008252: Hand"
  },
  {
    valueId: "EV008253",
    valueDesc: "Hand tap",
    label: "EV008253: Hand tap"
  },
  {
    valueId: "EV008254",
    valueDesc: "Hard",
    label: "EV008254: Hard"
  },
  {
    valueId: "EV008263",
    valueDesc: "Externally",
    label: "EV008263: Externally"
  },
  {
    valueId: "EV008264",
    valueDesc: "Internally",
    label: "EV008264: Internally"
  },
  {
    valueId: "EV008266",
    valueDesc: "Joint box",
    label: "EV008266: Joint box"
  },
  {
    valueId: "EV008269",
    valueDesc: "Cone point",
    label: "EV008269: Cone point"
  },
  {
    valueId: "EV008270",
    valueDesc: "Tool box",
    label: "EV008270: Tool box"
  },
  {
    valueId: "EV008271",
    valueDesc: "Clamping ring",
    label: "EV008271: Clamping ring"
  },
  {
    valueId: "EV008272",
    valueDesc: "Clamp spring",
    label: "EV008272: Clamp spring"
  },
  {
    valueId: "EV008273",
    valueDesc: "Snapper mounting",
    label: "EV008273: Snapper mounting"
  },
  {
    valueId: "EV008275",
    valueDesc: "Cobalt zinced",
    label: "EV008275: Cobalt zinced"
  },
  {
    valueId: "EV008280",
    valueDesc: "Ball head",
    label: "EV008280: Ball head"
  },
  {
    valueId: "EV008281",
    valueDesc: "Bowl",
    label: "EV008281: Bowl"
  },
  {
    valueId: "EV008288",
    valueDesc: "Crosshead with pin",
    label: "EV008288: Crosshead with pin"
  },
  {
    valueId: "EV008291",
    valueDesc: "Cross piece",
    label: "EV008291: Cross piece"
  },
  {
    valueId: "EV008295",
    valueDesc: "Deep countersunk head",
    label: "EV008295: Deep countersunk head"
  },
  {
    valueId: "EV008296",
    valueDesc: "Countersunk hexagon",
    label: "EV008296: Countersunk hexagon"
  },
  {
    valueId: "EV008302",
    valueDesc: "Letters",
    label: "EV008302: Letters"
  },
  {
    valueId: "EV008303",
    valueDesc: "Left-handed",
    label: "EV008303: Left-handed"
  },
  {
    valueId: "EV008304",
    valueDesc: "Right-handed",
    label: "EV008304: Right-handed"
  },
  {
    valueId: "EV008307",
    valueDesc: "LPG",
    label: "EV008307: LPG"
  },
  {
    valueId: "EV008308",
    valueDesc: "LR61",
    label: "EV008308: LR61"
  },
  {
    valueId: "EV008309",
    valueDesc: "Air/air",
    label: "EV008309: Air/air"
  },
  {
    valueId: "EV008310",
    valueDesc: "Air/water",
    label: "EV008310: Air/water"
  },
  {
    valueId: "EV008314",
    valueDesc: "M8",
    label: "EV008314: M8"
  },
  {
    valueId: "EV008315",
    valueDesc: "Machine tap",
    label: "EV008315: Machine tap"
  },
  {
    valueId: "EV008317",
    valueDesc: "Massive",
    label: "EV008317: Massive"
  },
  {
    valueId: "EV008319",
    valueDesc: "Knife",
    label: "EV008319: Knife"
  },
  {
    valueId: "EV008323",
    valueDesc: "Medium",
    label: "EV008323: Medium"
  },
  {
    valueId: "EV008325",
    valueDesc: "Nut",
    label: "EV008325: Nut"
  },
  {
    valueId: "EV008326",
    valueDesc: "Nuts",
    label: "EV008326: Nuts"
  },
  {
    valueId: "EV008328",
    valueDesc: "Mounting cover",
    label: "EV008328: Mounting cover"
  },
  {
    valueId: "EV008330",
    valueDesc: "Nails",
    label: "EV008330: Nails"
  },
  {
    valueId: "EV008331",
    valueDesc: "Narrow beam",
    label: "EV008331: Narrow beam"
  },
  {
    valueId: "EV008333",
    valueDesc: "Normal",
    label: "EV008333: Normal"
  },
  {
    valueId: "EV008337",
    valueDesc: "Oil",
    label: "EV008337: Oil"
  },
  {
    valueId: "EV008340",
    valueDesc: "One way",
    label: "EV008340: One way"
  },
  {
    valueId: "EV008341",
    valueDesc: "Uneven",
    label: "EV008341: Uneven"
  },
  {
    valueId: "EV008343",
    valueDesc: "P40 (aluminium)",
    label: "EV008343: P40 (aluminium)"
  },
  {
    valueId: "EV008344",
    valueDesc: "P60 (aluminium)",
    label: "EV008344: P60 (aluminium)"
  },
  {
    valueId: "EV008345",
    valueDesc: "P65 (aluminium)",
    label: "EV008345: P65 (aluminium)"
  },
  {
    valueId: "EV008348",
    valueDesc: "Prism",
    label: "EV008348: Prism"
  },
  {
    valueId: "EV008349",
    valueDesc: "Polyester resin",
    label: "EV008349: Polyester resin"
  },
  {
    valueId: "EV008350",
    valueDesc: "PS (synthetic)",
    label: "EV008350: PS (synthetic)"
  },
  {
    valueId: "EV008351",
    valueDesc: "Pneumatic/hydraulic",
    label: "EV008351: Pneumatic/hydraulic"
  },
  {
    valueId: "EV008354",
    valueDesc: "Perilex",
    label: "EV008354: Perilex"
  },
  {
    valueId: "EV008355",
    valueDesc: "Multi-purpose ladder",
    label: "EV008355: Multi-purpose ladder"
  },
  {
    valueId: "EV008357",
    valueDesc: "RJ",
    label: "EV008357: RJ"
  },
  {
    valueId: "EV008358",
    valueDesc: "Quiescent current",
    label: "EV008358: Quiescent current"
  },
  {
    valueId: "EV008361",
    valueDesc: "SQ45 / SQ45",
    label: "EV008361: SQ45 / SQ45"
  },
  {
    valueId: "EV008362",
    valueDesc: "SQ50 / RD50",
    label: "EV008362: SQ50 / RD50"
  },
  {
    valueId: "EV008363",
    valueDesc: "SQ50 / SQ50",
    label: "EV008363: SQ50 / SQ50"
  },
  {
    valueId: "EV008364",
    valueDesc: "Flat ends",
    label: "EV008364: Flat ends"
  },
  {
    valueId: "EV008368",
    valueDesc: "Brush head",
    label: "EV008368: Brush head"
  },
  {
    valueId: "EV008373",
    valueDesc: "Facing brickwork",
    label: "EV008373: Facing brickwork"
  },
  {
    valueId: "EV008374",
    valueDesc: "L-hook",
    label: "EV008374: L-hook"
  },
  {
    valueId: "EV008376",
    valueDesc: "Eye screw",
    label: "EV008376: Eye screw"
  },
  {
    valueId: "EV008377",
    valueDesc: "Extending ladder",
    label: "EV008377: Extending ladder"
  },
  {
    valueId: "EV008378",
    valueDesc: "D-system fuse link",
    label: "EV008378: D-system fuse link"
  },
  {
    valueId: "EV008379",
    valueDesc: "Snap lock",
    label: "EV008379: Snap lock"
  },
  {
    valueId: "EV008380",
    valueDesc: "Hexagon die nut",
    label: "EV008380: Hexagon die nut"
  },
  {
    valueId: "EV008381",
    valueDesc: "Round die",
    label: "EV008381: Round die"
  },
  {
    valueId: "EV008383",
    valueDesc: "Splash proof",
    label: "EV008383: Splash proof"
  },
  {
    valueId: "EV008384",
    valueDesc: "Steel nail",
    label: "EV008384: Steel nail"
  },
  {
    valueId: "EV008385",
    valueDesc: "Strain relief",
    label: "EV008385: Strain relief"
  },
  {
    valueId: "EV008387",
    valueDesc: "Tri-wing",
    label: "EV008387: Tri-wing"
  },
  {
    valueId: "EV008388",
    valueDesc: "Thermo maximal",
    label: "EV008388: Thermo maximal"
  },
  {
    valueId: "EV008389",
    valueDesc: "Thermo differential",
    label: "EV008389: Thermo differential"
  },
  {
    valueId: "EV008391",
    valueDesc: "Temperature",
    label: "EV008391: Temperature"
  },
  {
    valueId: "EV008399",
    valueDesc: "2 holes",
    label: "EV008399: 2 holes"
  },
  {
    valueId: "EV008400",
    valueDesc: "2 lips",
    label: "EV008400: 2 lips"
  },
  {
    valueId: "EV008403",
    valueDesc: "Floor (bottom)",
    label: "EV008403: Floor (bottom)"
  },
  {
    valueId: "EV008404",
    valueDesc: "Raised",
    label: "EV008404: Raised"
  },
  {
    valueId: "EV008409",
    valueDesc: "Very strong",
    label: "EV008409: Very strong"
  },
  {
    valueId: "EV008412",
    valueDesc: "Soft iron",
    label: "EV008412: Soft iron"
  },
  {
    valueId: "EV008416",
    valueDesc: "Reduction",
    label: "EV008416: Reduction"
  },
  {
    valueId: "EV008419",
    valueDesc: "Spring",
    label: "EV008419: Spring"
  },
  {
    valueId: "EV008420",
    valueDesc: "T-star with pin (Torx TR)",
    label: "EV008420: T-star with pin (Torx TR)"
  },
  {
    valueId: "EV008424",
    valueDesc: "Cordage",
    label: "EV008424: Cordage"
  },
  {
    valueId: "EV008425",
    valueDesc: "Telex/ADo",
    label: "EV008425: Telex/ADo"
  },
  {
    valueId: "EV008427",
    valueDesc: "Stud",
    label: "EV008427: Stud"
  },
  {
    valueId: "EV008428",
    valueDesc: "Strong",
    label: "EV008428: Strong"
  },
  {
    valueId: "EV008429",
    valueDesc: "Strongest",
    label: "EV008429: Strongest"
  },
  {
    valueId: "EV008430",
    valueDesc: "Stainless steel 304 (1.4301)",
    label: "EV008430: Stainless steel 304 (1.4301)"
  },
  {
    valueId: "EV008431",
    valueDesc: "Stainless steel 316 (1.4401)",
    label: "EV008431: Stainless steel 316 (1.4401)"
  },
  {
    valueId: "EV008432",
    valueDesc: "All round",
    label: "EV008432: All round"
  },
  {
    valueId: "EV008436",
    valueDesc: "Rail",
    label: "EV008436: Rail"
  },
  {
    valueId: "EV008439",
    valueDesc: "R1/N",
    label: "EV008439: R1/N"
  },
  {
    valueId: "EV008440",
    valueDesc: "R10",
    label: "EV008440: R10"
  },
  {
    valueId: "EV008441",
    valueDesc: "R14/C",
    label: "EV008441: R14/C"
  },
  {
    valueId: "EV008442",
    valueDesc: "R20/D",
    label: "EV008442: R20/D"
  },
  {
    valueId: "EV008444",
    valueDesc: "Print mounting",
    label: "EV008444: Print mounting"
  },
  {
    valueId: "EV008446",
    valueDesc: "Flat",
    label: "EV008446: Flat"
  },
  {
    valueId: "EV008447",
    valueDesc: "Plate material",
    label: "EV008447: Plate material"
  },
  {
    valueId: "EV008449",
    valueDesc: "Pan head",
    label: "EV008449: Pan head"
  },
  {
    valueId: "EV008450",
    valueDesc: "Suspend",
    label: "EV008450: Suspend"
  },
  {
    valueId: "EV008452",
    valueDesc: "Layed on",
    label: "EV008452: Layed on"
  },
  {
    valueId: "EV008453",
    valueDesc: "Operating delayed",
    label: "EV008453: Operating delayed"
  },
  {
    valueId: "EV008454",
    valueDesc: "Time-delay dropped out",
    label: "EV008454: Time-delay dropped out"
  },
  {
    valueId: "EV008455",
    valueDesc: "Open internal toothing",
    label: "EV008455: Open internal toothing"
  },
  {
    valueId: "EV008456",
    valueDesc: "Open external toothing",
    label: "EV008456: Open external toothing"
  },
  {
    valueId: "EV008457",
    valueDesc: "On cable tray",
    label: "EV008457: On cable tray"
  },
  {
    valueId: "EV008458",
    valueDesc: "Tote tray",
    label: "EV008458: Tote tray"
  },
  {
    valueId: "EV008459",
    valueDesc: "Mixing amplifier",
    label: "EV008459: Mixing amplifier"
  },
  {
    valueId: "EV008460",
    valueDesc: "Hand driven generator",
    label: "EV008460: Hand driven generator"
  },
  {
    valueId: "EV008461",
    valueDesc: "Cup point",
    label: "EV008461: Cup point"
  },
  {
    valueId: "EV008463",
    valueDesc: "Epoxy acrylic resin",
    label: "EV008463: Epoxy acrylic resin"
  },
  {
    valueId: "EV008464",
    valueDesc: "Single nozzle",
    label: "EV008464: Single nozzle"
  },
  {
    valueId: "EV008467",
    valueDesc: "Stainless steel A1",
    label: "EV008467: Stainless steel A1"
  },
  {
    valueId: "EV008468",
    valueDesc: "Stainless steel A193 B8/gr8",
    label: "EV008468: Stainless steel A193 B8/gr8"
  },
  {
    valueId: "EV008469",
    valueDesc: "Stainless steel A193 B8m/gr8m",
    label: "EV008469: Stainless steel A193 B8m/gr8m"
  },
  {
    valueId: "EV008470",
    valueDesc: "Stainless steel 304 (V2A-70)",
    label: "EV008470: Stainless steel 304 (V2A-70)"
  },
  {
    valueId: "EV008473",
    valueDesc: "Moving coil",
    label: "EV008473: Moving coil"
  },
  {
    valueId: "EV008474",
    valueDesc: "Rotatable",
    label: "EV008474: Rotatable"
  },
  {
    valueId: "EV008475",
    valueDesc: "Double toothing",
    label: "EV008475: Double toothing"
  },
  {
    valueId: "EV008479",
    valueDesc: "Block",
    label: "EV008479: Block"
  },
  {
    valueId: "EV008481",
    valueDesc: "Working current",
    label: "EV008481: Working current"
  },
  {
    valueId: "EV008487",
    valueDesc: "1/4 inch (8)",
    label: "EV008487: 1/4 inch (8)"
  },
  {
    valueId: "EV008488",
    valueDesc: "1/8 inch",
    label: "EV008488: 1/8 inch"
  },
  {
    valueId: "EV008489",
    valueDesc: "2 3/4 inch",
    label: "EV008489: 2 3/4 inch"
  },
  {
    valueId: "EV008492",
    valueDesc: "2x internal thread",
    label: "EV008492: 2x internal thread"
  },
  {
    valueId: "EV008493",
    valueDesc: "2x external thread",
    label: "EV008493: 2x external thread"
  },
  {
    valueId: "EV008494",
    valueDesc: "Warm white <3300 K",
    label: "EV008494: Warm white <3300 K"
  },
  {
    valueId: "EV008496",
    valueDesc: "For hinged lid",
    label: "EV008496: For hinged lid"
  },
  {
    valueId: "EV008497",
    valueDesc: "Float",
    label: "EV008497: Float"
  },
  {
    valueId: "EV008498",
    valueDesc: "Spark cap",
    label: "EV008498: Spark cap"
  },
  {
    valueId: "EV008500",
    valueDesc: "Valve A",
    label: "EV008500: Valve A"
  },
  {
    valueId: "EV008501",
    valueDesc: "Valve B",
    label: "EV008501: Valve B"
  },
  {
    valueId: "EV008502",
    valueDesc: "Valve BI",
    label: "EV008502: Valve BI"
  },
  {
    valueId: "EV008503",
    valueDesc: "Valve C",
    label: "EV008503: Valve C"
  },
  {
    valueId: "EV008510",
    valueDesc: "Connecting bridge",
    label: "EV008510: Connecting bridge"
  },
  {
    valueId: "EV008511",
    valueDesc: "Connecting cable",
    label: "EV008511: Connecting cable"
  },
  {
    valueId: "EV008515",
    valueDesc: "T1 3/4 MG",
    label: "EV008515: T1 3/4 MG"
  },
  {
    valueId: "EV008516",
    valueDesc: "T4.5",
    label: "EV008516: T4.5"
  },
  {
    valueId: "EV008518",
    valueDesc: "T5.5",
    label: "EV008518: T5.5"
  },
  {
    valueId: "EV008521",
    valueDesc: "Single core",
    label: "EV008521: Single core"
  },
  {
    valueId: "EV008522",
    valueDesc: "Dual core",
    label: "EV008522: Dual core"
  },
  {
    valueId: "EV008524",
    valueDesc: "Single phase",
    label: "EV008524: Single phase"
  },
  {
    valueId: "EV008530",
    valueDesc: "Regulating combination",
    label: "EV008530: Regulating combination"
  },
  {
    valueId: "EV008538",
    valueDesc: "Control panel",
    label: "EV008538: Control panel"
  },
  {
    valueId: "EV008539",
    valueDesc: "Protective collar",
    label: "EV008539: Protective collar"
  },
  {
    valueId: "EV008540",
    valueDesc: "Blocking equipment",
    label: "EV008540: Blocking equipment"
  },
  {
    valueId: "EV008541",
    valueDesc: "Mounting set cooker group",
    label: "EV008541: Mounting set cooker group"
  },
  {
    valueId: "EV008542",
    valueDesc: "Mounting set power current group",
    label: "EV008542: Mounting set power current group"
  },
  {
    valueId: "EV008543",
    valueDesc: "Contour plug (CEE 7/17)",
    label: "EV008543: Contour plug (CEE 7/17)"
  },
  {
    valueId: "EV008545",
    valueDesc: "DY",
    label: "EV008545: DY"
  },
  {
    valueId: "EV008546",
    valueDesc: "YD",
    label: "EV008546: YD"
  },
  {
    valueId: "EV008547",
    valueDesc: "YY",
    label: "EV008547: YY"
  },
  {
    valueId: "EV008548",
    valueDesc: "Extension part",
    label: "EV008548: Extension part"
  },
  {
    valueId: "EV008551",
    valueDesc: "GX10",
    label: "EV008551: GX10"
  },
  {
    valueId: "EV008552",
    valueDesc: "Front installation set",
    label: "EV008552: Front installation set"
  },
  {
    valueId: "EV008553",
    valueDesc: "Programmable",
    label: "EV008553: Programmable"
  },
  {
    valueId: "EV008555",
    valueDesc: "Reducing ring",
    label: "EV008555: Reducing ring"
  },
  {
    valueId: "EV008556",
    valueDesc: "Breaking resistance",
    label: "EV008556: Breaking resistance"
  },
  {
    valueId: "EV008557",
    valueDesc: "Breaking unit",
    label: "EV008557: Breaking unit"
  },
  {
    valueId: "EV008558",
    valueDesc: "Straightening wrench",
    label: "EV008558: Straightening wrench"
  },
  {
    valueId: "EV008559",
    valueDesc: "Reset contact",
    label: "EV008559: Reset contact"
  },
  {
    valueId: "EV008561",
    valueDesc: "Front ring for flat mounting",
    label: "EV008561: Front ring for flat mounting"
  },
  {
    valueId: "EV008563",
    valueDesc: "Hammer head profile",
    label: "EV008563: Hammer head profile"
  },
  {
    valueId: "EV008565",
    valueDesc: "Symbol 'I-0'",
    label: "EV008565: Symbol 'I-0'"
  },
  {
    valueId: "EV008566",
    valueDesc: "Symbol 'I-0-II'",
    label: "EV008566: Symbol 'I-0-II'"
  },
  {
    valueId: "EV008572",
    valueDesc: "K23d",
    label: "EV008572: K23d"
  },
  {
    valueId: "EV008573",
    valueDesc: "Cable mounting",
    label: "EV008573: Cable mounting"
  },
  {
    valueId: "EV008574",
    valueDesc: "Cable clip",
    label: "EV008574: Cable clip"
  },
  {
    valueId: "EV008576",
    valueDesc: "Cable support",
    label: "EV008576: Cable support"
  },
  {
    valueId: "EV008578",
    valueDesc: "Room luminaire",
    label: "EV008578: Room luminaire"
  },
  {
    valueId: "EV008579",
    valueDesc: "Room luminaire/buzzer",
    label: "EV008579: Room luminaire/buzzer"
  },
  {
    valueId: "EV008580",
    valueDesc: "Clamp with wire",
    label: "EV008580: Clamp with wire"
  },
  {
    valueId: "EV008581",
    valueDesc: "Daylight white >5300 K",
    label: "EV008581: Daylight white >5300 K"
  },
  {
    valueId: "EV008583",
    valueDesc: "Lamp extractor",
    label: "EV008583: Lamp extractor"
  },
  {
    valueId: "EV008584",
    valueDesc: "LED puller",
    label: "EV008584: LED puller"
  },
  {
    valueId: "EV008585",
    valueDesc: "Lens puller",
    label: "EV008585: Lens puller"
  },
  {
    valueId: "EV008589",
    valueDesc: "M5",
    label: "EV008589: M5"
  },
  {
    valueId: "EV008590",
    valueDesc: "Male (plug)",
    label: "EV008590: Male (plug)"
  },
  {
    valueId: "EV008591",
    valueDesc: "Mounting foot",
    label: "EV008591: Mounting foot"
  },
  {
    valueId: "EV008593",
    valueDesc: "Network adapter",
    label: "EV008593: Network adapter"
  },
  {
    valueId: "EV008595",
    valueDesc: "NH0",
    label: "EV008595: NH0"
  },
  {
    valueId: "EV008596",
    valueDesc: "NH4",
    label: "EV008596: NH4"
  },
  {
    valueId: "EV008597",
    valueDesc: "OM1",
    label: "EV008597: OM1"
  },
  {
    valueId: "EV008598",
    valueDesc: "OM2",
    label: "EV008598: OM2"
  },
  {
    valueId: "EV008599",
    valueDesc: "OM3",
    label: "EV008599: OM3"
  },
  {
    valueId: "EV008601",
    valueDesc: "OS1",
    label: "EV008601: OS1"
  },
  {
    valueId: "EV008602",
    valueDesc: "Fitted ring",
    label: "EV008602: Fitted ring"
  },
  {
    valueId: "EV008605",
    valueDesc: "Potentiometer holder",
    label: "EV008605: Potentiometer holder"
  },
  {
    valueId: "EV008607",
    valueDesc: "PK30d",
    label: "EV008607: PK30d"
  },
  {
    valueId: "EV008610",
    valueDesc: "Mounting set pull cord",
    label: "EV008610: Mounting set pull cord"
  },
  {
    valueId: "EV008611",
    valueDesc: "Mounting plate push button",
    label: "EV008611: Mounting plate push button"
  },
  {
    valueId: "EV008612",
    valueDesc: "Mounting wrench",
    label: "EV008612: Mounting wrench"
  },
  {
    valueId: "EV008613",
    valueDesc: "Neutral white 3300-5300 K",
    label: "EV008613: Neutral white 3300-5300 K"
  },
  {
    valueId: "EV008614",
    valueDesc: "Telephone/ISDN",
    label: "EV008614: Telephone/ISDN"
  },
  {
    valueId: "EV008617",
    valueDesc: "Twisted Pair",
    label: "EV008617: Twisted Pair"
  },
  {
    valueId: "EV008618",
    valueDesc: "Zener-diode",
    label: "EV008618: Zener-diode"
  },
  {
    valueId: "EV008621",
    valueDesc: "Base side set",
    label: "EV008621: Base side set"
  },
  {
    valueId: "EV008622",
    valueDesc: "Base intermediate plate",
    label: "EV008622: Base intermediate plate"
  },
  {
    valueId: "EV008624",
    valueDesc: "Star profile",
    label: "EV008624: Star profile"
  },
  {
    valueId: "EV008626",
    valueDesc: "Base complete",
    label: "EV008626: Base complete"
  },
  {
    valueId: "EV008630",
    valueDesc: "F/FTP",
    label: "EV008630: F/FTP"
  },
  {
    valueId: "EV008632",
    valueDesc: "Female (bus)",
    label: "EV008632: Female (bus)"
  },
  {
    valueId: "EV008633",
    valueDesc: "Power current connection",
    label: "EV008633: Power current connection"
  },
  {
    valueId: "EV008642",
    valueDesc: "Inlet plate",
    label: "EV008642: Inlet plate"
  },
  {
    valueId: "EV008643",
    valueDesc: "Isolation cap phase busbar",
    label: "EV008643: Isolation cap phase busbar"
  },
  {
    valueId: "EV008644",
    valueDesc: "Cap for EMC-filter",
    label: "EV008644: Cap for EMC-filter"
  },
  {
    valueId: "EV008645",
    valueDesc: "Pulley",
    label: "EV008645: Pulley"
  },
  {
    valueId: "EV008648",
    valueDesc: "Coupler hinging",
    label: "EV008648: Coupler hinging"
  },
  {
    valueId: "EV008653",
    valueDesc: "Lamp test",
    label: "EV008653: Lamp test"
  },
  {
    valueId: "EV008654",
    valueDesc: "LED and diode",
    label: "EV008654: LED and diode"
  },
  {
    valueId: "EV008658",
    valueDesc: "Surface mounting set",
    label: "EV008658: Surface mounting set"
  },
  {
    valueId: "EV008659",
    valueDesc: "Fitted screw",
    label: "EV008659: Fitted screw"
  },
  {
    valueId: "EV008662",
    valueDesc: "Complete set",
    label: "EV008662: Complete set"
  },
  {
    valueId: "EV008663",
    valueDesc: "SF/FTP",
    label: "EV008663: SF/FTP"
  },
  {
    valueId: "EV008664",
    valueDesc: "U/FTP",
    label: "EV008664: U/FTP"
  },
  {
    valueId: "EV008665",
    valueDesc: "S/UTP",
    label: "EV008665: S/UTP"
  },
  {
    valueId: "EV008667",
    valueDesc: "U/UTP",
    label: "EV008667: U/UTP"
  },
  {
    valueId: "EV008669",
    valueDesc: "Software tool",
    label: "EV008669: Software tool"
  },
  {
    valueId: "EV008675",
    valueDesc: "Counter support star profile",
    label: "EV008675: Counter support star profile"
  },
  {
    valueId: "EV008676",
    valueDesc: "Contact block dummy",
    label: "EV008676: Contact block dummy"
  },
  {
    valueId: "EV008677",
    valueDesc: "Suitable for data link",
    label: "EV008677: Suitable for data link"
  },
  {
    valueId: "EV008679",
    valueDesc: "Miniature-/earth leakage circuit breaker",
    label: "EV008679: Miniature-/earth leakage circuit breaker"
  },
  {
    valueId: "EV008680",
    valueDesc: "Flashing element",
    label: "EV008680: Flashing element"
  },
  {
    valueId: "EV008682",
    valueDesc: "Coupling piece for protection switch disconnector",
    label: "EV008682: Coupling piece for protection switch disconnector"
  },
  {
    valueId: "EV008683",
    valueDesc: "Loose strip",
    label: "EV008683: Loose strip"
  },
  {
    valueId: "EV008686",
    valueDesc: "Midget flanged",
    label: "EV008686: Midget flanged"
  },
  {
    valueId: "EV008687",
    valueDesc: "Midget grooved",
    label: "EV008687: Midget grooved"
  },
  {
    valueId: "EV008692",
    valueDesc: "Pulse tone/continuous tone",
    label: "EV008692: Pulse tone/continuous tone"
  },
  {
    valueId: "EV008693",
    valueDesc: "Filler strip",
    label: "EV008693: Filler strip"
  },
  {
    valueId: "EV008698",
    valueDesc: "Perforated profile",
    label: "EV008698: Perforated profile"
  },
  {
    valueId: "EV008699",
    valueDesc: "Imperforated profile",
    label: "EV008699: Imperforated profile"
  },
  {
    valueId: "EV008700",
    valueDesc: "R/C element",
    label: "EV008700: R/C element"
  },
  {
    valueId: "EV008702",
    valueDesc: "Signalling set",
    label: "EV008702: Signalling set"
  },
  {
    valueId: "EV008703",
    valueDesc: "Reactance coil",
    label: "EV008703: Reactance coil"
  },
  {
    valueId: "EV008706",
    valueDesc: "Base stabilization profile",
    label: "EV008706: Base stabilization profile"
  },
  {
    valueId: "EV008707",
    valueDesc: "Base front/back",
    label: "EV008707: Base front/back"
  },
  {
    valueId: "EV008712",
    valueDesc: "Strengthening plate for push-button",
    label: "EV008712: Strengthening plate for push-button"
  },
  {
    valueId: "EV008714",
    valueDesc: "For surface tank",
    label: "EV008714: For surface tank"
  },
  {
    valueId: "EV008715",
    valueDesc: "Resistor block",
    label: "EV008715: Resistor block"
  },
  {
    valueId: "EV008716",
    valueDesc: "Round flashing light",
    label: "EV008716: Round flashing light"
  },
  {
    valueId: "EV008721",
    valueDesc: "Bottom side",
    label: "EV008721: Bottom side"
  },
  {
    valueId: "EV008722",
    valueDesc: "Body massage",
    label: "EV008722: Body massage"
  },
  {
    valueId: "EV008723",
    valueDesc: "Pin-on",
    label: "EV008723: Pin-on"
  },
  {
    valueId: "EV008724",
    valueDesc: "Head microphone",
    label: "EV008724: Head microphone"
  },
  {
    valueId: "EV008725",
    valueDesc: "Supercardioid",
    label: "EV008725: Supercardioid"
  },
  {
    valueId: "EV008726",
    valueDesc: "Hypercardioid",
    label: "EV008726: Hypercardioid"
  },
  {
    valueId: "EV008727",
    valueDesc: "8 (Bidirectional)",
    label: "EV008727: 8 (Bidirectional)"
  },
  {
    valueId: "EV008728",
    valueDesc: "Power amplifier",
    label: "EV008728: Power amplifier"
  },
  {
    valueId: "EV008729",
    valueDesc: "Heating board",
    label: "EV008729: Heating board"
  },
  {
    valueId: "EV008730",
    valueDesc: "Free-hanging",
    label: "EV008730: Free-hanging"
  },
  {
    valueId: "EV008732",
    valueDesc: "Bypass",
    label: "EV008732: Bypass"
  },
  {
    valueId: "EV008734",
    valueDesc: "Flexible pipe",
    label: "EV008734: Flexible pipe"
  },
  {
    valueId: "EV008735",
    valueDesc: "Reduction/enlargement piece",
    label: "EV008735: Reduction/enlargement piece"
  },
  {
    valueId: "EV008738",
    valueDesc: "00",
    label: "EV008738: 00"
  },
  {
    valueId: "EV008740",
    valueDesc: "Sickle-shaped",
    label: "EV008740: Sickle-shaped"
  },
  {
    valueId: "EV008741",
    valueDesc: "Rubber hammer",
    label: "EV008741: Rubber hammer"
  },
  {
    valueId: "EV008742",
    valueDesc: "Plastic hammer",
    label: "EV008742: Plastic hammer"
  },
  {
    valueId: "EV008743",
    valueDesc: "Wooden hammer",
    label: "EV008743: Wooden hammer"
  },
  {
    valueId: "EV008744",
    valueDesc: "Round compression",
    label: "EV008744: Round compression"
  },
  {
    valueId: "EV008746",
    valueDesc: "Cutting",
    label: "EV008746: Cutting"
  },
  {
    valueId: "EV008747",
    valueDesc: "Cable shears",
    label: "EV008747: Cable shears"
  },
  {
    valueId: "EV008748",
    valueDesc: "Cable knife",
    label: "EV008748: Cable knife"
  },
  {
    valueId: "EV008749",
    valueDesc: "Universal knife",
    label: "EV008749: Universal knife"
  },
  {
    valueId: "EV008750",
    valueDesc: "Bar-shaped lamp",
    label: "EV008750: Bar-shaped lamp"
  },
  {
    valueId: "EV008751",
    valueDesc: "Indoor station",
    label: "EV008751: Indoor station"
  },
  {
    valueId: "EV008754",
    valueDesc: "Wall-/ceiling clamp trapezoid profile",
    label: "EV008754: Wall-/ceiling clamp trapezoid profile"
  },
  {
    valueId: "EV008755",
    valueDesc: "Face out bracket overhead",
    label: "EV008755: Face out bracket overhead"
  },
  {
    valueId: "EV008756",
    valueDesc: "Flat profile",
    label: "EV008756: Flat profile"
  },
  {
    valueId: "EV008757",
    valueDesc: "Ascending",
    label: "EV008757: Ascending"
  },
  {
    valueId: "EV008758",
    valueDesc: "Falling",
    label: "EV008758: Falling"
  },
  {
    valueId: "EV008759",
    valueDesc: "Rising/descending",
    label: "EV008759: Rising/descending"
  },
  {
    valueId: "EV008760",
    valueDesc: "Adjusting clamp",
    label: "EV008760: Adjusting clamp"
  },
  {
    valueId: "EV008761",
    valueDesc: "Sealing for subfloor installation duct box",
    label: "EV008761: Sealing for subfloor installation duct box"
  },
  {
    valueId: "EV008763",
    valueDesc: "Cable harness",
    label: "EV008763: Cable harness"
  },
  {
    valueId: "EV008764",
    valueDesc: "Side profile",
    label: "EV008764: Side profile"
  },
  {
    valueId: "EV008765",
    valueDesc: "Duct connector",
    label: "EV008765: Duct connector"
  },
  {
    valueId: "EV008766",
    valueDesc: "Flange clamp",
    label: "EV008766: Flange clamp"
  },
  {
    valueId: "EV008772",
    valueDesc: "Multi-coloured/blended",
    label: "EV008772: Multi-coloured/blended"
  },
  {
    valueId: "EV008780",
    valueDesc: "Fluorescent lamp circular D=16 mm",
    label: "EV008780: Fluorescent lamp circular D=16 mm"
  },
  {
    valueId: "EV008781",
    valueDesc: "Fluorescent lamp circular D=32 mm",
    label: "EV008781: Fluorescent lamp circular D=32 mm"
  },
  {
    valueId: "EV008782",
    valueDesc: "Fluorescent lamp U-shaped",
    label: "EV008782: Fluorescent lamp U-shaped"
  },
  {
    valueId: "EV008783",
    valueDesc: "Steel wire",
    label: "EV008783: Steel wire"
  },
  {
    valueId: "EV008784",
    valueDesc: "IK10",
    label: "EV008784: IK10"
  },
  {
    valueId: "EV008785",
    valueDesc: "Quadruple module length",
    label: "EV008785: Quadruple module length"
  },
  {
    valueId: "EV008786",
    valueDesc: "Zamac",
    label: "EV008786: Zamac"
  },
  {
    valueId: "EV008790",
    valueDesc: "Installation frame",
    label: "EV008790: Installation frame"
  },
  {
    valueId: "EV008806",
    valueDesc: "Coupling piece",
    label: "EV008806: Coupling piece"
  },
  {
    valueId: "EV008807",
    valueDesc: "Elevation ring",
    label: "EV008807: Elevation ring"
  },
  {
    valueId: "EV008809",
    valueDesc: "Built-in set",
    label: "EV008809: Built-in set"
  },
  {
    valueId: "EV008810",
    valueDesc: "One fold",
    label: "EV008810: One fold"
  },
  {
    valueId: "EV008811",
    valueDesc: "Mounting pin",
    label: "EV008811: Mounting pin"
  },
  {
    valueId: "EV008814",
    valueDesc: "For 2-core cables",
    label: "EV008814: For 2-core cables"
  },
  {
    valueId: "EV008815",
    valueDesc: "For 3-core cables",
    label: "EV008815: For 3-core cables"
  },
  {
    valueId: "EV008816",
    valueDesc: "For 4-core cables",
    label: "EV008816: For 4-core cables"
  },
  {
    valueId: "EV008817",
    valueDesc: "For 5-core cables",
    label: "EV008817: For 5-core cables"
  },
  {
    valueId: "EV008821",
    valueDesc: "VovaNet",
    label: "EV008821: VovaNet"
  },
  {
    valueId: "EV008822",
    valueDesc: "LON-Works",
    label: "EV008822: LON-Works"
  },
  {
    valueId: "EV008824",
    valueDesc: "WAP",
    label: "EV008824: WAP"
  },
  {
    valueId: "EV008825",
    valueDesc: "BACnet",
    label: "EV008825: BACnet"
  },
  {
    valueId: "EV008826",
    valueDesc: "Modbus",
    label: "EV008826: Modbus"
  },
  {
    valueId: "EV008827",
    valueDesc: "N2",
    label: "EV008827: N2"
  },
  {
    valueId: "EV008828",
    valueDesc: "Turn",
    label: "EV008828: Turn"
  },
  {
    valueId: "EV008829",
    valueDesc: "Bandy",
    label: "EV008829: Bandy"
  },
  {
    valueId: "EV008830",
    valueDesc: "Long dog point",
    label: "EV008830: Long dog point"
  },
  {
    valueId: "EV008832",
    valueDesc: "Flat oval",
    label: "EV008832: Flat oval"
  },
  {
    valueId: "EV008833",
    valueDesc: "Key ring",
    label: "EV008833: Key ring"
  },
  {
    valueId: "EV008834",
    valueDesc: "Plastic base",
    label: "EV008834: Plastic base"
  },
  {
    valueId: "EV008835",
    valueDesc: "Hinged handle holder",
    label: "EV008835: Hinged handle holder"
  },
  {
    valueId: "EV008836",
    valueDesc: "Natural hair",
    label: "EV008836: Natural hair"
  },
  {
    valueId: "EV008837",
    valueDesc: "Full face mask",
    label: "EV008837: Full face mask"
  },
  {
    valueId: "EV008838",
    valueDesc: "Half face mask",
    label: "EV008838: Half face mask"
  },
  {
    valueId: "EV008842",
    valueDesc: "Bell transformer connection block",
    label: "EV008842: Bell transformer connection block"
  },
  {
    valueId: "EV008843",
    valueDesc: "Red/green",
    label: "EV008843: Red/green"
  },
  {
    valueId: "EV008848",
    valueDesc: "Hexagon slotted",
    label: "EV008848: Hexagon slotted"
  },
  {
    valueId: "EV008849",
    valueDesc: "Phillips slotted",
    label: "EV008849: Phillips slotted"
  },
  {
    valueId: "EV008850",
    valueDesc: "Semi round head",
    label: "EV008850: Semi round head"
  },
  {
    valueId: "EV008851",
    valueDesc: "Round profile",
    label: "EV008851: Round profile"
  },
  {
    valueId: "EV008852",
    valueDesc: "Symbols",
    label: "EV008852: Symbols"
  },
  {
    valueId: "EV008853",
    valueDesc: "Grinding",
    label: "EV008853: Grinding"
  },
  {
    valueId: "EV008854",
    valueDesc: "X-branch",
    label: "EV008854: X-branch"
  },
  {
    valueId: "EV008855",
    valueDesc: "Terminal box",
    label: "EV008855: Terminal box"
  },
  {
    valueId: "EV008856",
    valueDesc: "Aluminium duct tape",
    label: "EV008856: Aluminium duct tape"
  },
  {
    valueId: "EV008857",
    valueDesc: "Textile duct tape",
    label: "EV008857: Textile duct tape"
  },
  {
    valueId: "EV008859",
    valueDesc: "Clap cap",
    label: "EV008859: Clap cap"
  },
  {
    valueId: "EV008860",
    valueDesc: "Pull cord side",
    label: "EV008860: Pull cord side"
  },
  {
    valueId: "EV008861",
    valueDesc: "Pull cord front",
    label: "EV008861: Pull cord front"
  },
  {
    valueId: "EV008862",
    valueDesc: "Gland nut connection",
    label: "EV008862: Gland nut connection"
  },
  {
    valueId: "EV008863",
    valueDesc: "Snap socket",
    label: "EV008863: Snap socket"
  },
  {
    valueId: "EV008864",
    valueDesc: "Bolt connection",
    label: "EV008864: Bolt connection"
  },
  {
    valueId: "EV008865",
    valueDesc: "3x16A xp xxxV",
    label: "EV008865: 3x16A xp xxxV"
  },
  {
    valueId: "EV008866",
    valueDesc: "4x16A xp xxxV",
    label: "EV008866: 4x16A xp xxxV"
  },
  {
    valueId: "EV008867",
    valueDesc: ">4x16A xp xxxV",
    label: "EV008867: >4x16A xp xxxV"
  },
  {
    valueId: "EV008869",
    valueDesc: "Screw head plate",
    label: "EV008869: Screw head plate"
  },
  {
    valueId: "EV008870",
    valueDesc: "Clamp head plate",
    label: "EV008870: Clamp head plate"
  },
  {
    valueId: "EV008871",
    valueDesc: "Nikobus",
    label: "EV008871: Nikobus"
  },
  {
    valueId: "EV008872",
    valueDesc: "Marker strip",
    label: "EV008872: Marker strip"
  },
  {
    valueId: "EV008873",
    valueDesc: "Marking fork",
    label: "EV008873: Marking fork"
  },
  {
    valueId: "EV008876",
    valueDesc: "110x110 mm",
    label: "EV008876: 110x110 mm"
  },
  {
    valueId: "EV008877",
    valueDesc: "Moulded-on push fit plug (double)",
    label: "EV008877: Moulded-on push fit plug (double)"
  },
  {
    valueId: "EV008878",
    valueDesc: "Screw hole",
    label: "EV008878: Screw hole"
  },
  {
    valueId: "EV008879",
    valueDesc: "Hexagonal",
    label: "EV008879: Hexagonal"
  },
  {
    valueId: "EV008880",
    valueDesc: "Glass fuse",
    label: "EV008880: Glass fuse"
  },
  {
    valueId: "EV008881",
    valueDesc: "Ceramic fuse",
    label: "EV008881: Ceramic fuse"
  },
  {
    valueId: "EV008882",
    valueDesc: "Cover for mounting with screws",
    label: "EV008882: Cover for mounting with screws"
  },
  {
    valueId: "EV008889",
    valueDesc: "Test button",
    label: "EV008889: Test button"
  },
  {
    valueId: "EV008892",
    valueDesc: "Protective housing",
    label: "EV008892: Protective housing"
  },
  {
    valueId: "EV008894",
    valueDesc: "Toggle handle",
    label: "EV008894: Toggle handle"
  },
  {
    valueId: "EV008895",
    valueDesc: "Powder coating",
    label: "EV008895: Powder coating"
  },
  {
    valueId: "EV008896",
    valueDesc: "FFP1",
    label: "EV008896: FFP1"
  },
  {
    valueId: "EV008897",
    valueDesc: "FFP2",
    label: "EV008897: FFP2"
  },
  {
    valueId: "EV008898",
    valueDesc: "FFP3",
    label: "EV008898: FFP3"
  },
  {
    valueId: "EV008899",
    valueDesc: "Exchangeable filter",
    label: "EV008899: Exchangeable filter"
  },
  {
    valueId: "EV008902",
    valueDesc: "With twilight switch delayed",
    label: "EV008902: With twilight switch delayed"
  },
  {
    valueId: "EV008903",
    valueDesc: "With adjustable delay switch",
    label: "EV008903: With adjustable delay switch"
  },
  {
    valueId: "EV008904",
    valueDesc: "With electric louver",
    label: "EV008904: With electric louver"
  },
  {
    valueId: "EV008905",
    valueDesc: "With moisture- and earth circuit",
    label: "EV008905: With moisture- and earth circuit"
  },
  {
    valueId: "EV008906",
    valueDesc: "With base load circuit",
    label: "EV008906: With base load circuit"
  },
  {
    valueId: "EV008907",
    valueDesc: "With interval control",
    label: "EV008907: With interval control"
  },
  {
    valueId: "EV008908",
    valueDesc: "With automatic louver",
    label: "EV008908: With automatic louver"
  },
  {
    valueId: "EV008909",
    valueDesc: "With delay switch",
    label: "EV008909: With delay switch"
  },
  {
    valueId: "EV008910",
    valueDesc: "With delay switch and electric louvre",
    label: "EV008910: With delay switch and electric louvre"
  },
  {
    valueId: "EV008911",
    valueDesc: "With delay switch and automatic louvre",
    label: "EV008911: With delay switch and automatic louvre"
  },
  {
    valueId: "EV008912",
    valueDesc: "With safety low voltage",
    label: "EV008912: With safety low voltage"
  },
  {
    valueId: "EV008913",
    valueDesc: "With pull switch",
    label: "EV008913: With pull switch"
  },
  {
    valueId: "EV008914",
    valueDesc: "100x200 mm",
    label: "EV008914: 100x200 mm"
  },
  {
    valueId: "EV008915",
    valueDesc: "2-components",
    label: "EV008915: 2-components"
  },
  {
    valueId: "EV008916",
    valueDesc: "Bolting",
    label: "EV008916: Bolting"
  },
  {
    valueId: "EV008917",
    valueDesc: "Knurled screw",
    label: "EV008917: Knurled screw"
  },
  {
    valueId: "EV008918",
    valueDesc: "1+N/PE",
    label: "EV008918: 1+N/PE"
  },
  {
    valueId: "EV008919",
    valueDesc: "3+N/PE",
    label: "EV008919: 3+N/PE"
  },
  {
    valueId: "EV008921",
    valueDesc: "Earth rod/circular conductor",
    label: "EV008921: Earth rod/circular conductor"
  },
  {
    valueId: "EV008923",
    valueDesc: "FLASH EPROM",
    label: "EV008923: FLASH EPROM"
  },
  {
    valueId: "EV008925",
    valueDesc: "IP65/IP67",
    label: "EV008925: IP65/IP67"
  },
  {
    valueId: "EV008926",
    valueDesc: "Pentium M",
    label: "EV008926: Pentium M"
  },
  {
    valueId: "EV008927",
    valueDesc: "VIA C3",
    label: "EV008927: VIA C3"
  },
  {
    valueId: "EV008928",
    valueDesc: "VIA EDEN",
    label: "EV008928: VIA EDEN"
  },
  {
    valueId: "EV008930",
    valueDesc: "Direct attachment/single positioning",
    label: "EV008930: Direct attachment/single positioning"
  },
  {
    valueId: "EV008931",
    valueDesc: "CLASS 10",
    label: "EV008931: CLASS 10"
  },
  {
    valueId: "EV008932",
    valueDesc: "CLASS 20",
    label: "EV008932: CLASS 20"
  },
  {
    valueId: "EV008934",
    valueDesc: "Back side",
    label: "EV008934: Back side"
  },
  {
    valueId: "EV008937",
    valueDesc: "With cover",
    label: "EV008937: With cover"
  },
  {
    valueId: "EV008938",
    valueDesc: "With door",
    label: "EV008938: With door"
  },
  {
    valueId: "EV008939",
    valueDesc: "ISDN/Token ring/telephone",
    label: "EV008939: ISDN/Token ring/telephone"
  },
  {
    valueId: "EV008941",
    valueDesc: "Pipe in-lead",
    label: "EV008941: Pipe in-lead"
  },
  {
    valueId: "EV008943",
    valueDesc: "Cover",
    label: "EV008943: Cover"
  },
  {
    valueId: "EV008944",
    valueDesc: "Number",
    label: "EV008944: Number"
  },
  {
    valueId: "EV008945",
    valueDesc: "Small letter",
    label: "EV008945: Small letter"
  },
  {
    valueId: "EV008946",
    valueDesc: "Capital letter",
    label: "EV008946: Capital letter"
  },
  {
    valueId: "EV008947",
    valueDesc: "Digits assortment",
    label: "EV008947: Digits assortment"
  },
  {
    valueId: "EV008948",
    valueDesc: "Letter assortment",
    label: "EV008948: Letter assortment"
  },
  {
    valueId: "EV008961",
    valueDesc: "Iso-set",
    label: "EV008961: Iso-set"
  },
  {
    valueId: "EV008967",
    valueDesc: "Flat shield",
    label: "EV008967: Flat shield"
  },
  {
    valueId: "EV008968",
    valueDesc: "Built-under device",
    label: "EV008968: Built-under device"
  },
  {
    valueId: "EV008969",
    valueDesc: "Touch control button",
    label: "EV008969: Touch control button"
  },
  {
    valueId: "EV008975",
    valueDesc: "G-fuse 6.3x32 mm",
    label: "EV008975: G-fuse 6.3x32 mm"
  },
  {
    valueId: "EV008976",
    valueDesc: "Connection rail",
    label: "EV008976: Connection rail"
  },
  {
    valueId: "EV008977",
    valueDesc: "Cable clamp",
    label: "EV008977: Cable clamp"
  },
  {
    valueId: "EV008978",
    valueDesc: "NH0, NH1",
    label: "EV008978: NH0, NH1"
  },
  {
    valueId: "EV008979",
    valueDesc: "NH000, NH00",
    label: "EV008979: NH000, NH00"
  },
  {
    valueId: "EV008980",
    valueDesc: "NH1, NH2",
    label: "EV008980: NH1, NH2"
  },
  {
    valueId: "EV008981",
    valueDesc: "NH2, NH3",
    label: "EV008981: NH2, NH3"
  },
  {
    valueId: "EV008982",
    valueDesc: "Clamp bracket",
    label: "EV008982: Clamp bracket"
  },
  {
    valueId: "EV008984",
    valueDesc: "Sandal",
    label: "EV008984: Sandal"
  },
  {
    valueId: "EV008986",
    valueDesc: "Felt pen",
    label: "EV008986: Felt pen"
  },
  {
    valueId: "EV008987",
    valueDesc: "Blackboard chalk",
    label: "EV008987: Blackboard chalk"
  },
  {
    valueId: "EV008989",
    valueDesc: "Face cleaning device",
    label: "EV008989: Face cleaning device"
  },
  {
    valueId: "EV008990",
    valueDesc: "Hair colouring device",
    label: "EV008990: Hair colouring device"
  },
  {
    valueId: "EV008991",
    valueDesc: "Personal care set",
    label: "EV008991: Personal care set"
  },
  {
    valueId: "EV008999",
    valueDesc: "Conax",
    label: "EV008999: Conax"
  },
  {
    valueId: "EV009000",
    valueDesc: "Cryptoworks",
    label: "EV009000: Cryptoworks"
  },
  {
    valueId: "EV009001",
    valueDesc: "Twilight sensor",
    label: "EV009001: Twilight sensor"
  },
  {
    valueId: "EV009002",
    valueDesc: "Antenna technology",
    label: "EV009002: Antenna technology"
  },
  {
    valueId: "EV009003",
    valueDesc: "1x data box",
    label: "EV009003: 1x data box"
  },
  {
    valueId: "EV009004",
    valueDesc: "Click",
    label: "EV009004: Click"
  },
  {
    valueId: "EV009005",
    valueDesc: "Reset push button",
    label: "EV009005: Reset push button"
  },
  {
    valueId: "EV009006",
    valueDesc: "Reset-input",
    label: "EV009006: Reset-input"
  },
  {
    valueId: "EV009007",
    valueDesc: "Reset push button/-input",
    label: "EV009007: Reset push button/-input"
  },
  {
    valueId: "EV009008",
    valueDesc: "Not included",
    label: "EV009008: Not included"
  },
  {
    valueId: "EV009009",
    valueDesc: "Quartz controlled",
    label: "EV009009: Quartz controlled"
  },
  {
    valueId: "EV009010",
    valueDesc: "Roller shutter-/Venetian shutter module",
    label: "EV009010: Roller shutter-/Venetian shutter module"
  },
  {
    valueId: "EV009011",
    valueDesc: "Sensor module",
    label: "EV009011: Sensor module"
  },
  {
    valueId: "EV009012",
    valueDesc: "Clock module",
    label: "EV009012: Clock module"
  },
  {
    valueId: "EV009013",
    valueDesc: "Weather sensors",
    label: "EV009013: Weather sensors"
  },
  {
    valueId: "EV009014",
    valueDesc: "<50 V DC grey",
    label: "EV009014: <50 V DC grey"
  },
  {
    valueId: "EV009015",
    valueDesc: ">250 V DC grey",
    label: "EV009015: >250 V DC grey"
  },
  {
    valueId: "EV009016",
    valueDesc: ">50-250 V DC grey",
    label: "EV009016: >50-250 V DC grey"
  },
  {
    valueId: "EV009017",
    valueDesc: ">50 V grey",
    label: "EV009017: >50 V grey"
  },
  {
    valueId: "EV009018",
    valueDesc: "110 V (50+60 Hz) yellow",
    label: "EV009018: 110 V (50+60 Hz) yellow"
  },
  {
    valueId: "EV009019",
    valueDesc: "20-25 V (50+60 Hz) violet",
    label: "EV009019: 20-25 V (50+60 Hz) violet"
  },
  {
    valueId: "EV009020",
    valueDesc: "20-25 V / 40-50 V (>400-500 Hz) green",
    label: "EV009020: 20-25 V / 40-50 V (>400-500 Hz) green"
  },
  {
    valueId: "EV009021",
    valueDesc: "20-25 V / 40-50 V (100-200 Hz) green",
    label: "EV009021: 20-25 V / 40-50 V (100-200 Hz) green"
  },
  {
    valueId: "EV009022",
    valueDesc: "20-25 V / 40-50 V (300 Hz) green",
    label: "EV009022: 20-25 V / 40-50 V (300 Hz) green"
  },
  {
    valueId: "EV009023",
    valueDesc: "20-25 V / 40-50 V (400 Hz) green",
    label: "EV009023: 20-25 V / 40-50 V (400 Hz) green"
  },
  {
    valueId: "EV009024",
    valueDesc: "230 V (50+60 Hz) blue",
    label: "EV009024: 230 V (50+60 Hz) blue"
  },
  {
    valueId: "EV009025",
    valueDesc: "400-440 V (50+60 Hz) red",
    label: "EV009025: 400-440 V (50+60 Hz) red"
  },
  {
    valueId: "EV009026",
    valueDesc: "400 V (50+60 Hz) red",
    label: "EV009026: 400 V (50+60 Hz) red"
  },
  {
    valueId: "EV009027",
    valueDesc: "40-50 V (50+60 Hz) white",
    label: "EV009027: 40-50 V (50+60 Hz) white"
  },
  {
    valueId: "EV009028",
    valueDesc: "500 V (50+60 Hz) black",
    label: "EV009028: 500 V (50+60 Hz) black"
  },
  {
    valueId: "EV009029",
    valueDesc: "50-500 V (>300-500 Hz) green",
    label: "EV009029: 50-500 V (>300-500 Hz) green"
  },
  {
    valueId: "EV009030",
    valueDesc: "50-500 V (100-300 Hz) green",
    label: "EV009030: 50-500 V (100-300 Hz) green"
  },
  {
    valueId: "EV009031",
    valueDesc: "600-690 V (50+60 Hz) black",
    label: "EV009031: 600-690 V (50+60 Hz) black"
  },
  {
    valueId: "EV009032",
    valueDesc: "After isolating transformer",
    label: "EV009032: After isolating transformer"
  },
  {
    valueId: "EV009033",
    valueDesc: "External mounting",
    label: "EV009033: External mounting"
  },
  {
    valueId: "EV009034",
    valueDesc: "Inner lying mounting",
    label: "EV009034: Inner lying mounting"
  },
  {
    valueId: "EV009037",
    valueDesc: "70x70 mm",
    label: "EV009037: 70x70 mm"
  },
  {
    valueId: "EV009041",
    valueDesc: "Socket outlet 1-position",
    label: "EV009041: Socket outlet 1-position"
  },
  {
    valueId: "EV009042",
    valueDesc: "Socket outlet 2-position",
    label: "EV009042: Socket outlet 2-position"
  },
  {
    valueId: "EV009043",
    valueDesc: "Installation bus",
    label: "EV009043: Installation bus"
  },
  {
    valueId: "EV009044",
    valueDesc: "Scanable symbol/barrier free",
    label: "EV009044: Scanable symbol/barrier free"
  },
  {
    valueId: "EV009047",
    valueDesc: "Satinized",
    label: "EV009047: Satinized"
  },
  {
    valueId: "EV009048",
    valueDesc: "Cover plate",
    label: "EV009048: Cover plate"
  },
  {
    valueId: "EV009049",
    valueDesc: "Central cover plate for intermediate ring",
    label: "EV009049: Central cover plate for intermediate ring"
  },
  {
    valueId: "EV009051",
    valueDesc: "Central component",
    label: "EV009051: Central component"
  },
  {
    valueId: "EV009052",
    valueDesc: "Amplifier",
    label: "EV009052: Amplifier"
  },
  {
    valueId: "EV009053",
    valueDesc: "Voltage supply unit",
    label: "EV009053: Voltage supply unit"
  },
  {
    valueId: "EV009054",
    valueDesc: "Control unit",
    label: "EV009054: Control unit"
  },
  {
    valueId: "EV009055",
    valueDesc: "Voice recording",
    label: "EV009055: Voice recording"
  },
  {
    valueId: "EV009059",
    valueDesc: "VoIP",
    label: "EV009059: VoIP"
  },
  {
    valueId: "EV009060",
    valueDesc: "Pipe mounting",
    label: "EV009060: Pipe mounting"
  },
  {
    valueId: "EV009061",
    valueDesc: "L, N, PE",
    label: "EV009061: L, N, PE"
  },
  {
    valueId: "EV009062",
    valueDesc: "1, 2, 3",
    label: "EV009062: 1, 2, 3"
  },
  {
    valueId: "EV009063",
    valueDesc: "2, N, PE, 1",
    label: "EV009063: 2, N, PE, 1"
  },
  {
    valueId: "EV009064",
    valueDesc: "3, N, PE, 2, 1",
    label: "EV009064: 3, N, PE, 2, 1"
  },
  {
    valueId: "EV009065",
    valueDesc: "L, N, PE, -, +",
    label: "EV009065: L, N, PE, -, +"
  },
  {
    valueId: "EV009066",
    valueDesc: "3, 2, 1, N, PE, L",
    label: "EV009066: 3, 2, 1, N, PE, L"
  },
  {
    valueId: "EV009067",
    valueDesc: "1+, 2-",
    label: "EV009067: 1+, 2-"
  },
  {
    valueId: "EV009068",
    valueDesc: "1+, 2-, N, PE, L",
    label: "EV009068: 1+, 2-, N, PE, L"
  },
  {
    valueId: "EV009069",
    valueDesc: "1+, 2-, 2, N, PE, L",
    label: "EV009069: 1+, 2-, 2, N, PE, L"
  },
  {
    valueId: "EV009071",
    valueDesc: "1, 2",
    label: "EV009071: 1, 2"
  },
  {
    valueId: "EV009072",
    valueDesc: "L, N",
    label: "EV009072: L, N"
  },
  {
    valueId: "EV009073",
    valueDesc: "1, 2, PE",
    label: "EV009073: 1, 2, PE"
  },
  {
    valueId: "EV009074",
    valueDesc: "1, 2, 3, PE",
    label: "EV009074: 1, 2, 3, PE"
  },
  {
    valueId: "EV009075",
    valueDesc: "1, 2, 3, 4, 5",
    label: "EV009075: 1, 2, 3, 4, 5"
  },
  {
    valueId: "EV009076",
    valueDesc: "L, N, PE, 1, 2",
    label: "EV009076: L, N, PE, 1, 2"
  },
  {
    valueId: "EV009081",
    valueDesc: "Black/green",
    label: "EV009081: Black/green"
  },
  {
    valueId: "EV009082",
    valueDesc: "Blue/black",
    label: "EV009082: Blue/black"
  },
  {
    valueId: "EV009084",
    valueDesc: "Built-in part/plug-in connection",
    label: "EV009084: Built-in part/plug-in connection"
  },
  {
    valueId: "EV009085",
    valueDesc: "Snap-in/screw-in device",
    label: "EV009085: Snap-in/screw-in device"
  },
  {
    valueId: "EV009086",
    valueDesc: "M2",
    label: "EV009086: M2"
  },
  {
    valueId: "EV009087",
    valueDesc: "M2.5",
    label: "EV009087: M2.5"
  },
  {
    valueId: "EV009088",
    valueDesc: "M3",
    label: "EV009088: M3"
  },
  {
    valueId: "EV009089",
    valueDesc: "M3.5",
    label: "EV009089: M3.5"
  },
  {
    valueId: "EV009090",
    valueDesc: "M4",
    label: "EV009090: M4"
  },
  {
    valueId: "EV009091",
    valueDesc: "M6",
    label: "EV009091: M6"
  },
  {
    valueId: "EV009092",
    valueDesc: "M16",
    label: "EV009092: M16"
  },
  {
    valueId: "EV009093",
    valueDesc: "M20",
    label: "EV009093: M20"
  },
  {
    valueId: "EV009094",
    valueDesc: "M25",
    label: "EV009094: M25"
  },
  {
    valueId: "EV009095",
    valueDesc: "M32",
    label: "EV009095: M32"
  },
  {
    valueId: "EV009097",
    valueDesc: "65",
    label: "EV009097: 65"
  },
  {
    valueId: "EV009103",
    valueDesc: ">12",
    label: "EV009103: >12"
  },
  {
    valueId: "EV009104",
    valueDesc: "0°/180° (horizontal)",
    label: "EV009104: 0°/180° (horizontal)"
  },
  {
    valueId: "EV009105",
    valueDesc: "125x115 mm",
    label: "EV009105: 125x115 mm"
  },
  {
    valueId: "EV009106",
    valueDesc: "125x125 mm",
    label: "EV009106: 125x125 mm"
  },
  {
    valueId: "EV009107",
    valueDesc: "135° (upwards inclined)",
    label: "EV009107: 135° (upwards inclined)"
  },
  {
    valueId: "EV009108",
    valueDesc: "180° (horizontal)",
    label: "EV009108: 180° (horizontal)"
  },
  {
    valueId: "EV009109",
    valueDesc: "482.6 mm (19 inch) mounting bracket",
    label: "EV009109: 482.6 mm (19 inch) mounting bracket"
  },
  {
    valueId: "EV009110",
    valueDesc: "482.6 mm (19 inch) mounting angle bracket",
    label: "EV009110: 482.6 mm (19 inch) mounting angle bracket"
  },
  {
    valueId: "EV009111",
    valueDesc: "482.6 mm (19 inch) mounting set",
    label: "EV009111: 482.6 mm (19 inch) mounting set"
  },
  {
    valueId: "EV009112",
    valueDesc: "482.6 mm (19 inch) profile",
    label: "EV009112: 482.6 mm (19 inch) profile"
  },
  {
    valueId: "EV009113",
    valueDesc: "482.6 mm (19 inch) profile set",
    label: "EV009113: 482.6 mm (19 inch) profile set"
  },
  {
    valueId: "EV009114",
    valueDesc: "482.6 mm (19 inch) angle profile",
    label: "EV009114: 482.6 mm (19 inch) angle profile"
  },
  {
    valueId: "EV009115",
    valueDesc: "482.6 mm (19 inch) angle set",
    label: "EV009115: 482.6 mm (19 inch) angle set"
  },
  {
    valueId: "EV009117",
    valueDesc: "482.6 mm (19 inch) model",
    label: "EV009117: 482.6 mm (19 inch) model"
  },
  {
    valueId: "EV009118",
    valueDesc: "225° (downwards inclined)",
    label: "EV009118: 225° (downwards inclined)"
  },
  {
    valueId: "EV009119",
    valueDesc: "270° (downwards vertical)",
    label: "EV009119: 270° (downwards vertical)"
  },
  {
    valueId: "EV009121",
    valueDesc: "30x30 mm",
    label: "EV009121: 30x30 mm"
  },
  {
    valueId: "EV009122",
    valueDesc: "30x45 mm",
    label: "EV009122: 30x45 mm"
  },
  {
    valueId: "EV009123",
    valueDesc: "3-phase current converter set",
    label: "EV009123: 3-phase current converter set"
  },
  {
    valueId: "EV009124",
    valueDesc: "4th pole",
    label: "EV009124: 4th pole"
  },
  {
    valueId: "EV009125",
    valueDesc: "45°/135° (downwards inclined)",
    label: "EV009125: 45°/135° (downwards inclined)"
  },
  {
    valueId: "EV009126",
    valueDesc: "48x48 mm",
    label: "EV009126: 48x48 mm"
  },
  {
    valueId: "EV009127",
    valueDesc: "64x64 mm",
    label: "EV009127: 64x64 mm"
  },
  {
    valueId: "EV009128",
    valueDesc: "65x65 mm",
    label: "EV009128: 65x65 mm"
  },
  {
    valueId: "EV009129",
    valueDesc: "66X66 mm",
    label: "EV009129: 66X66 mm"
  },
  {
    valueId: "EV009130",
    valueDesc: "67x67 mm",
    label: "EV009130: 67x67 mm"
  },
  {
    valueId: "EV009131",
    valueDesc: "72x72 mm",
    label: "EV009131: 72x72 mm"
  },
  {
    valueId: "EV009132",
    valueDesc: "88x88 mm",
    label: "EV009132: 88x88 mm"
  },
  {
    valueId: "EV009133",
    valueDesc: "90° (upwards vertical)",
    label: "EV009133: 90° (upwards vertical)"
  },
  {
    valueId: "EV009134",
    valueDesc: "90°/270° (BUS)",
    label: "EV009134: 90°/270° (BUS)"
  },
  {
    valueId: "EV009135",
    valueDesc: "90x90 mm",
    label: "EV009135: 90x90 mm"
  },
  {
    valueId: "EV009136",
    valueDesc: "96x96 mm",
    label: "EV009136: 96x96 mm"
  },
  {
    valueId: "EV009138",
    valueDesc: "Leakage resistance",
    label: "EV009138: Leakage resistance"
  },
  {
    valueId: "EV009139",
    valueDesc: "Terminal panel",
    label: "EV009139: Terminal panel"
  },
  {
    valueId: "EV009140",
    valueDesc: "Adapter plug",
    label: "EV009140: Adapter plug"
  },
  {
    valueId: "EV009141",
    valueDesc: "Adaptation for wiring systems",
    label: "EV009141: Adaptation for wiring systems"
  },
  {
    valueId: "EV009143",
    valueDesc: "Addressing plug",
    label: "EV009143: Addressing plug"
  },
  {
    valueId: "EV009144",
    valueDesc: "Analogue module",
    label: "EV009144: Analogue module"
  },
  {
    valueId: "EV009145",
    valueDesc: "Housing extension",
    label: "EV009145: Housing extension"
  },
  {
    valueId: "EV009146",
    valueDesc: "Stop door",
    label: "EV009146: Stop door"
  },
  {
    valueId: "EV009147",
    valueDesc: "Connecting contact",
    label: "EV009147: Connecting contact"
  },
  {
    valueId: "EV009148",
    valueDesc: "Connection technique",
    label: "EV009148: Connection technique"
  },
  {
    valueId: "EV009150",
    valueDesc: "Operating current principle",
    label: "EV009150: Operating current principle"
  },
  {
    valueId: "EV009151",
    valueDesc: "Asymmetrically divided double wing door",
    label: "EV009151: Asymmetrically divided double wing door"
  },
  {
    valueId: "EV009152",
    valueDesc: "Surface mounted device",
    label: "EV009152: Surface mounted device"
  },
  {
    valueId: "EV009153",
    valueDesc: "Top mounting and integrable",
    label: "EV009153: Top mounting and integrable"
  },
  {
    valueId: "EV009154",
    valueDesc: "AUI",
    label: "EV009154: AUI"
  },
  {
    valueId: "EV009155",
    valueDesc: "Switch off time delay",
    label: "EV009155: Switch off time delay"
  },
  {
    valueId: "EV009156",
    valueDesc: "Axial (adhesive tape for axial inlet)",
    label: "EV009156: Axial (adhesive tape for axial inlet)"
  },
  {
    valueId: "EV009157",
    valueDesc: "Axial screw connection",
    label: "EV009157: Axial screw connection"
  },
  {
    valueId: "EV009158",
    valueDesc: "Bar graph",
    label: "EV009158: Bar graph"
  },
  {
    valueId: "EV009160",
    valueDesc: "Baseband (frequency)",
    label: "EV009160: Baseband (frequency)"
  },
  {
    valueId: "EV009161",
    valueDesc: "Base controller",
    label: "EV009161: Base controller"
  },
  {
    valueId: "EV009162",
    valueDesc: "Battery/accumulator",
    label: "EV009162: Battery/accumulator"
  },
  {
    valueId: "EV009163",
    valueDesc: "Fastening according to EN 50047",
    label: "EV009163: Fastening according to EN 50047"
  },
  {
    valueId: "EV009164",
    valueDesc: "Fastening profile",
    label: "EV009164: Fastening profile"
  },
  {
    valueId: "EV009165",
    valueDesc: "Fastening parts/-set",
    label: "EV009165: Fastening parts/-set"
  },
  {
    valueId: "EV009166",
    valueDesc: "Shock protection cover",
    label: "EV009166: Shock protection cover"
  },
  {
    valueId: "EV009167",
    valueDesc: "Beryllium copper",
    label: "EV009167: Beryllium copper"
  },
  {
    valueId: "EV009168",
    valueDesc: "Imprintable label",
    label: "EV009168: Imprintable label"
  },
  {
    valueId: "EV009169",
    valueDesc: "Labelling strip/-sign",
    label: "EV009169: Labelling strip/-sign"
  },
  {
    valueId: "EV009170",
    valueDesc: "Actuator with horizontal mounting",
    label: "EV009170: Actuator with horizontal mounting"
  },
  {
    valueId: "EV009171",
    valueDesc: "Actuator with vertical mounting",
    label: "EV009171: Actuator with vertical mounting"
  },
  {
    valueId: "EV009172",
    valueDesc: "Pouch",
    label: "EV009172: Pouch"
  },
  {
    valueId: "EV009173",
    valueDesc: "Blind cover frame",
    label: "EV009173: Blind cover frame"
  },
  {
    valueId: "EV009176",
    valueDesc: "Blister girdle (for SMD)",
    label: "EV009176: Blister girdle (for SMD)"
  },
  {
    valueId: "EV009177",
    valueDesc: "Floor fastening",
    label: "EV009177: Floor fastening"
  },
  {
    valueId: "EV009178",
    valueDesc: "Floor plate",
    label: "EV009178: Floor plate"
  },
  {
    valueId: "EV009179",
    valueDesc: "Bolt",
    label: "EV009179: Bolt"
  },
  {
    valueId: "EV009181",
    valueDesc: "Celeron M",
    label: "EV009181: Celeron M"
  },
  {
    valueId: "EV009182",
    valueDesc: "Coding profile",
    label: "EV009182: Coding profile"
  },
  {
    valueId: "EV009183",
    valueDesc: "Coding switch",
    label: "EV009183: Coding switch"
  },
  {
    valueId: "EV009184",
    valueDesc: "Coding part",
    label: "EV009184: Coding part"
  },
  {
    valueId: "EV009185",
    valueDesc: "Comfort controller",
    label: "EV009185: Comfort controller"
  },
  {
    valueId: "EV009186",
    valueDesc: "Crimp connection",
    label: "EV009186: Crimp connection"
  },
  {
    valueId: "EV009187",
    valueDesc: "Roof structure",
    label: "EV009187: Roof structure"
  },
  {
    valueId: "EV009188",
    valueDesc: "Dahlander switch",
    label: "EV009188: Dahlander switch"
  },
  {
    valueId: "EV009189",
    valueDesc: "Continuous tone/pulse tone",
    label: "EV009189: Continuous tone/pulse tone"
  },
  {
    valueId: "EV009190",
    valueDesc: "Cover with LED",
    label: "EV009190: Cover with LED"
  },
  {
    valueId: "EV009191",
    valueDesc: "Cover without LED",
    label: "EV009191: Cover without LED"
  },
  {
    valueId: "EV009192",
    valueDesc: "Cover grip",
    label: "EV009192: Cover grip"
  },
  {
    valueId: "EV009194",
    valueDesc: "Digital module",
    label: "EV009194: Digital module"
  },
  {
    valueId: "EV009195",
    valueDesc: "DIMM",
    label: "EV009195: DIMM"
  },
  {
    valueId: "EV009196",
    valueDesc: "Direct connection",
    label: "EV009196: Direct connection"
  },
  {
    valueId: "EV009197",
    valueDesc: "Direct circuit",
    label: "EV009197: Direct circuit"
  },
  {
    valueId: "EV009198",
    valueDesc: "Direct converter",
    label: "EV009198: Direct converter"
  },
  {
    valueId: "EV009199",
    valueDesc: "Wireless",
    label: "EV009199: Wireless"
  },
  {
    valueId: "EV009200",
    valueDesc: "D-RAM",
    label: "EV009200: D-RAM"
  },
  {
    valueId: "EV009203",
    valueDesc: "Through-feed",
    label: "EV009203: Through-feed"
  },
  {
    valueId: "EV009204",
    valueDesc: "Through-feed current converter",
    label: "EV009204: Through-feed current converter"
  },
  {
    valueId: "EV009205",
    valueDesc: "Through-feed technique",
    label: "EV009205: Through-feed technique"
  },
  {
    valueId: "EV009207",
    valueDesc: "One- and two-channel",
    label: "EV009207: One- and two-channel"
  },
  {
    valueId: "EV009208",
    valueDesc: "On/Off switch",
    label: "EV009208: On/Off switch"
  },
  {
    valueId: "EV009210",
    valueDesc: "Built-in device slide-in technique (withdrawable)",
    label: "EV009210: Built-in device slide-in technique (withdrawable)"
  },
  {
    valueId: "EV009211",
    valueDesc: "Built-in device fixed built-in technique",
    label: "EV009211: Built-in device fixed built-in technique"
  },
  {
    valueId: "EV009212",
    valueDesc: "Built-in device plug-in technique",
    label: "EV009212: Built-in device plug-in technique"
  },
  {
    valueId: "EV009213",
    valueDesc: "Inserted",
    label: "EV009213: Inserted"
  },
  {
    valueId: "EV009214",
    valueDesc: "Press-in connection",
    label: "EV009214: Press-in connection"
  },
  {
    valueId: "EV009215",
    valueDesc: "Press-in",
    label: "EV009215: Press-in"
  },
  {
    valueId: "EV009216",
    valueDesc: "Engage (snap)",
    label: "EV009216: Engage (snap)"
  },
  {
    valueId: "EV009217",
    valueDesc: "Screwable fitting",
    label: "EV009217: Screwable fitting"
  },
  {
    valueId: "EV009218",
    valueDesc: "Slide-in technique",
    label: "EV009218: Slide-in technique"
  },
  {
    valueId: "EV009219",
    valueDesc: "Single-sided (adhesive tape for radial inlet)",
    label: "EV009219: Single-sided (adhesive tape for radial inlet)"
  },
  {
    valueId: "EV009222",
    valueDesc: "Adjustable",
    label: "EV009222: Adjustable"
  },
  {
    valueId: "EV009223",
    valueDesc: "One-tariff",
    label: "EV009223: One-tariff"
  },
  {
    valueId: "EV009224",
    valueDesc: "Electronics module",
    label: "EV009224: Electronics module"
  },
  {
    valueId: "EV009227",
    valueDesc: "EMC-interference suppression module",
    label: "EV009227: EMC-interference suppression module"
  },
  {
    valueId: "EV009228",
    valueDesc: "End plate",
    label: "EV009228: End plate"
  },
  {
    valueId: "EV009229",
    valueDesc: "Earth fault module",
    label: "EV009229: Earth fault module"
  },
  {
    valueId: "EV009232",
    valueDesc: "Expansion device",
    label: "EV009232: Expansion device"
  },
  {
    valueId: "EV009233",
    valueDesc: "Extreme high frequency 30 - 300 GHz",
    label: "EV009233: Extreme high frequency 30 - 300 GHz"
  },
  {
    valueId: "EV009234",
    valueDesc: "Compartment door",
    label: "EV009234: Compartment door"
  },
  {
    valueId: "EV009235",
    valueDesc: "Spring connection",
    label: "EV009235: Spring connection"
  },
  {
    valueId: "EV009236",
    valueDesc: "Field device",
    label: "EV009236: Field device"
  },
  {
    valueId: "EV009237",
    valueDesc: "Remote potentiometer",
    label: "EV009237: Remote potentiometer"
  },
  {
    valueId: "EV009238",
    valueDesc: "Fixed connector",
    label: "EV009238: Fixed connector"
  },
  {
    valueId: "EV009240",
    valueDesc: "Flat ribbon cable",
    label: "EV009240: Flat ribbon cable"
  },
  {
    valueId: "EV009241",
    valueDesc: "Flat rail",
    label: "EV009241: Flat rail"
  },
  {
    valueId: "EV009242",
    valueDesc: "Flange connection",
    label: "EV009242: Flange connection"
  },
  {
    valueId: "EV009243",
    valueDesc: "FLASH",
    label: "EV009243: FLASH"
  },
  {
    valueId: "EV009244",
    valueDesc: "Flexible PCB-connector",
    label: "EV009244: Flexible PCB-connector"
  },
  {
    valueId: "EV009245",
    valueDesc: "Shape A2/A3",
    label: "EV009245: Shape A2/A3"
  },
  {
    valueId: "EV009246",
    valueDesc: "Shape A4",
    label: "EV009246: Shape A4"
  },
  {
    valueId: "EV009247",
    valueDesc: "Shape B1-B3",
    label: "EV009247: Shape B1-B3"
  },
  {
    valueId: "EV009248",
    valueDesc: "Shape C1-C3",
    label: "EV009248: Shape C1-C3"
  },
  {
    valueId: "EV009249",
    valueDesc: "Free connector",
    label: "EV009249: Free connector"
  },
  {
    valueId: "EV009250",
    valueDesc: "Free wheeling diode",
    label: "EV009250: Free wheeling diode"
  },
  {
    valueId: "EV009251",
    valueDesc: "Front plate",
    label: "EV009251: Front plate"
  },
  {
    valueId: "EV009252",
    valueDesc: "FS 10",
    label: "EV009252: FS 10"
  },
  {
    valueId: "EV009253",
    valueDesc: "FS 5",
    label: "EV009253: FS 5"
  },
  {
    valueId: "EV009254",
    valueDesc: "G 1/2 inch",
    label: "EV009254: G 1/2 inch"
  },
  {
    valueId: "EV009256",
    valueDesc: "Gas compression spring",
    label: "EV009256: Gas compression spring"
  },
  {
    valueId: "EV009257",
    valueDesc: "Housing flange",
    label: "EV009257: Housing flange"
  },
  {
    valueId: "EV009258",
    valueDesc: "Housing shape",
    label: "EV009258: Housing shape"
  },
  {
    valueId: "EV009259",
    valueDesc: "Device bottom",
    label: "EV009259: Device bottom"
  },
  {
    valueId: "EV009260",
    valueDesc: "Device labeling system",
    label: "EV009260: Device labeling system"
  },
  {
    valueId: "EV009262",
    valueDesc: "Scaffold",
    label: "EV009262: Scaffold"
  },
  {
    valueId: "EV009263",
    valueDesc: "Screened 2-wire conductor",
    label: "EV009263: Screened 2-wire conductor"
  },
  {
    valueId: "EV009266",
    valueDesc: "Glass fibre optic",
    label: "EV009266: Glass fibre optic"
  },
  {
    valueId: "EV009267",
    valueDesc: "Incandescent lamp/LED",
    label: "EV009267: Incandescent lamp/LED"
  },
  {
    valueId: "EV009268",
    valueDesc: "Basic device",
    label: "EV009268: Basic device"
  },
  {
    valueId: "EV009270",
    valueDesc: "Rubberized",
    label: "EV009270: Rubberized"
  },
  {
    valueId: "EV009271",
    valueDesc: "H",
    label: "EV009271: H"
  },
  {
    valueId: "EV009272",
    valueDesc: "Semiconductor",
    label: "EV009272: Semiconductor"
  },
  {
    valueId: "EV009273",
    valueDesc: "Retaining bracket",
    label: "EV009273: Retaining bracket"
  },
  {
    valueId: "EV009277",
    valueDesc: "HBF",
    label: "EV009277: HBF"
  },
  {
    valueId: "EV009278",
    valueDesc: "HBF1",
    label: "EV009278: HBF1"
  },
  {
    valueId: "EV009279",
    valueDesc: "HBF2",
    label: "EV009279: HBF2"
  },
  {
    valueId: "EV009280",
    valueDesc: "Tack",
    label: "EV009280: Tack"
  },
  {
    valueId: "EV009281",
    valueDesc: "Hermaphrodite",
    label: "EV009281: Hermaphrodite"
  },
  {
    valueId: "EV009282",
    valueDesc: "Auxiliary conductor plug and socket device",
    label: "EV009282: Auxiliary conductor plug and socket device"
  },
  {
    valueId: "EV009283",
    valueDesc: "High frequency 3 - 30 MHz",
    label: "EV009283: High frequency 3 - 30 MHz"
  },
  {
    valueId: "EV009284",
    valueDesc: "Hollow shaft",
    label: "EV009284: Hollow shaft"
  },
  {
    valueId: "EV009285",
    valueDesc: "Audible frequency approx. 0 - 20 kHz",
    label: "EV009285: Audible frequency approx. 0 - 20 kHz"
  },
  {
    valueId: "EV009286",
    valueDesc: "In the field",
    label: "EV009286: In the field"
  },
  {
    valueId: "EV009287",
    valueDesc: "INTERBUS-Safety",
    label: "EV009287: INTERBUS-Safety"
  },
  {
    valueId: "EV009288",
    valueDesc: "IP66/IP67",
    label: "EV009288: IP66/IP67"
  },
  {
    valueId: "EV009289",
    valueDesc: "IP67K",
    label: "EV009289: IP67K"
  },
  {
    valueId: "EV009290",
    valueDesc: "IP69K",
    label: "EV009290: IP69K"
  },
  {
    valueId: "EV009291",
    valueDesc: "Insulating plate",
    label: "EV009291: Insulating plate"
  },
  {
    valueId: "EV009292",
    valueDesc: "I converter",
    label: "EV009292: I converter"
  },
  {
    valueId: "EV009293",
    valueDesc: "I converter, machine-commutated",
    label: "EV009293: I converter, machine-commutated"
  },
  {
    valueId: "EV009294",
    valueDesc: "I converter, self-commutated",
    label: "EV009294: I converter, self-commutated"
  },
  {
    valueId: "EV009295",
    valueDesc: "IV",
    label: "EV009295: IV"
  },
  {
    valueId: "EV009296",
    valueDesc: "Cable to cable",
    label: "EV009296: Cable to cable"
  },
  {
    valueId: "EV009297",
    valueDesc: "Cable entry",
    label: "EV009297: Cable entry"
  },
  {
    valueId: "EV009298",
    valueDesc: "Cable entry metrical",
    label: "EV009298: Cable entry metrical"
  },
  {
    valueId: "EV009299",
    valueDesc: "Cable entry PG",
    label: "EV009299: Cable entry PG"
  },
  {
    valueId: "EV009300",
    valueDesc: "Cable space",
    label: "EV009300: Cable space"
  },
  {
    valueId: "EV009301",
    valueDesc: "Cable rebuilding current converter",
    label: "EV009301: Cable rebuilding current converter"
  },
  {
    valueId: "EV009302",
    valueDesc: "Cable trough",
    label: "EV009302: Cable trough"
  },
  {
    valueId: "EV009303",
    valueDesc: "Jumper comb",
    label: "EV009303: Jumper comb"
  },
  {
    valueId: "EV009304",
    valueDesc: "Capillary tube",
    label: "EV009304: Capillary tube"
  },
  {
    valueId: "EV009305",
    valueDesc: "Carton (for SMD)",
    label: "EV009305: Carton (for SMD)"
  },
  {
    valueId: "EV009308",
    valueDesc: "Clip",
    label: "EV009308: Clip"
  },
  {
    valueId: "EV009309",
    valueDesc: "Coding pin",
    label: "EV009309: Coding pin"
  },
  {
    valueId: "EV009310",
    valueDesc: "Communication and measuring function",
    label: "EV009310: Communication and measuring function"
  },
  {
    valueId: "EV009311",
    valueDesc: "Complete device in housing",
    label: "EV009311: Complete device in housing"
  },
  {
    valueId: "EV009312",
    valueDesc: "Components for lace up technique",
    label: "EV009312: Components for lace up technique"
  },
  {
    valueId: "EV009313",
    valueDesc: "Configuration dependant",
    label: "EV009313: Configuration dependant"
  },
  {
    valueId: "EV009315",
    valueDesc: "Contact",
    label: "EV009315: Contact"
  },
  {
    valueId: "EV009316",
    valueDesc: "Copper-iron-phosphor",
    label: "EV009316: Copper-iron-phosphor"
  },
  {
    valueId: "EV009317",
    valueDesc: "Copper-nickel-silicon",
    label: "EV009317: Copper-nickel-silicon"
  },
  {
    valueId: "EV009318",
    valueDesc: "Copper-nickel-zinc",
    label: "EV009318: Copper-nickel-zinc"
  },
  {
    valueId: "EV009319",
    valueDesc: "Cupola lever",
    label: "EV009319: Cupola lever"
  },
  {
    valueId: "EV009320",
    valueDesc: "Coupling box for plug-in connection",
    label: "EV009320: Coupling box for plug-in connection"
  },
  {
    valueId: "EV009321",
    valueDesc: "Coupling housing",
    label: "EV009321: Coupling housing"
  },
  {
    valueId: "EV009322",
    valueDesc: "LCP",
    label: "EV009322: LCP"
  },
  {
    valueId: "EV009323",
    valueDesc: "LED + diode + reverse polarity protection diode",
    label: "EV009323: LED + diode + reverse polarity protection diode"
  },
  {
    valueId: "EV009325",
    valueDesc: "Printed circuit board to cable",
    label: "EV009325: Printed circuit board to cable"
  },
  {
    valueId: "EV009326",
    valueDesc: "Printed circuit board to printed circuit board",
    label: "EV009326: Printed circuit board to printed circuit board"
  },
  {
    valueId: "EV009327",
    valueDesc: "PCB fastening",
    label: "EV009327: PCB fastening"
  },
  {
    valueId: "EV009328",
    valueDesc: "PCB-plug-in bridge",
    label: "EV009328: PCB-plug-in bridge"
  },
  {
    valueId: "EV009329",
    valueDesc: "L-shaped",
    label: "EV009329: L-shaped"
  },
  {
    valueId: "EV009330",
    valueDesc: "Arc explosion chamber (arc chute)",
    label: "EV009330: Arc explosion chamber (arc chute)"
  },
  {
    valueId: "EV009331",
    valueDesc: "M23 horizontal",
    label: "EV009331: M23 horizontal"
  },
  {
    valueId: "EV009332",
    valueDesc: "M23 vertical",
    label: "EV009332: M23 vertical"
  },
  {
    valueId: "EV009333",
    valueDesc: "M8-Snap-In",
    label: "EV009333: M8-Snap-In"
  },
  {
    valueId: "EV009335",
    valueDesc: "Ground strap",
    label: "EV009335: Ground strap"
  },
  {
    valueId: "EV009337",
    valueDesc: "Micro controller",
    label: "EV009337: Micro controller"
  },
  {
    valueId: "EV009338",
    valueDesc: "With permanent frame",
    label: "EV009338: With permanent frame"
  },
  {
    valueId: "EV009339",
    valueDesc: "With holder",
    label: "EV009339: With holder"
  },
  {
    valueId: "EV009340",
    valueDesc: "With cable preassembled",
    label: "EV009340: With cable preassembled"
  },
  {
    valueId: "EV009341",
    valueDesc: "With swing frame",
    label: "EV009341: With swing frame"
  },
  {
    valueId: "EV009342",
    valueDesc: "Centre frequency 300 - 3000 kHz",
    label: "EV009342: Centre frequency 300 - 3000 kHz"
  },
  {
    valueId: "EV009343",
    valueDesc: "Monitor frame",
    label: "EV009343: Monitor frame"
  },
  {
    valueId: "EV009345",
    valueDesc: "Mounting clip for DIN rail (top hat rail) mounting",
    label: "EV009345: Mounting clip for DIN rail (top hat rail) mounting"
  },
  {
    valueId: "EV009346",
    valueDesc: "Mounting flange",
    label: "EV009346: Mounting flange"
  },
  {
    valueId: "EV009347",
    valueDesc: "Mounting material",
    label: "EV009347: Mounting material"
  },
  {
    valueId: "EV009348",
    valueDesc: "Mounting bar",
    label: "EV009348: Mounting bar"
  },
  {
    valueId: "EV009349",
    valueDesc: "Mounting tool",
    label: "EV009349: Mounting tool"
  },
  {
    valueId: "EV009350",
    valueDesc: "Line filter",
    label: "EV009350: Line filter"
  },
  {
    valueId: "EV009351",
    valueDesc: "Neutral terminal",
    label: "EV009351: Neutral terminal"
  },
  {
    valueId: "EV009352",
    valueDesc: "NH00, NH1",
    label: "EV009352: NH00, NH1"
  },
  {
    valueId: "EV009354",
    valueDesc: "NiCr-NI",
    label: "EV009354: NiCr-NI"
  },
  {
    valueId: "EV009355",
    valueDesc: "Low frequency 30 - 300 kHz",
    label: "EV009355: Low frequency 30 - 300 kHz"
  },
  {
    valueId: "EV009356",
    valueDesc: "Riveting",
    label: "EV009356: Riveting"
  },
  {
    valueId: "EV009357",
    valueDesc: "NTC",
    label: "EV009357: NTC"
  },
  {
    valueId: "EV009358",
    valueDesc: "NVRAM",
    label: "EV009358: NVRAM"
  },
  {
    valueId: "EV009359",
    valueDesc: "Surface mounting",
    label: "EV009359: Surface mounting"
  },
  {
    valueId: "EV009360",
    valueDesc: "Normally closed contact and normally open contact",
    label: "EV009360: Normally closed contact and normally open contact"
  },
  {
    valueId: "EV009361",
    valueDesc: "Without integral bearing",
    label: "EV009361: Without integral bearing"
  },
  {
    valueId: "EV009362",
    valueDesc: "Without contacts",
    label: "EV009362: Without contacts"
  },
  {
    valueId: "EV009364",
    valueDesc: "Palladium",
    label: "EV009364: Palladium"
  },
  {
    valueId: "EV009365",
    valueDesc: "Parallel wiring",
    label: "EV009365: Parallel wiring"
  },
  {
    valueId: "EV009366",
    valueDesc: "Parametrise cable",
    label: "EV009366: Parametrise cable"
  },
  {
    valueId: "EV009367",
    valueDesc: "Passband",
    label: "EV009367: Passband"
  },
  {
    valueId: "EV009368",
    valueDesc: "PBT",
    label: "EV009368: PBT"
  },
  {
    valueId: "EV009369",
    valueDesc: "PCF fibre optic",
    label: "EV009369: PCF fibre optic"
  },
  {
    valueId: "EV009370",
    valueDesc: "PC cable",
    label: "EV009370: PC cable"
  },
  {
    valueId: "EV009372",
    valueDesc: "Pole switch",
    label: "EV009372: Pole switch"
  },
  {
    valueId: "EV009373",
    valueDesc: "Polymer fibre optic",
    label: "EV009373: Polymer fibre optic"
  },
  {
    valueId: "EV009374",
    valueDesc: "PROFINET",
    label: "EV009374: PROFINET"
  },
  {
    valueId: "EV009375",
    valueDesc: "Test device",
    label: "EV009375: Test device"
  },
  {
    valueId: "EV009377",
    valueDesc: "Push Pull",
    label: "EV009377: Push Pull"
  },
  {
    valueId: "EV009378",
    valueDesc: "Cuboid",
    label: "EV009378: Cuboid"
  },
  {
    valueId: "EV009379",
    valueDesc: "R 1/2 inch",
    label: "EV009379: R 1/2 inch"
  },
  {
    valueId: "EV009380",
    valueDesc: "R 1/4 inch",
    label: "EV009380: R 1/4 inch"
  },
  {
    valueId: "EV009381",
    valueDesc: "R 3/8 inch",
    label: "EV009381: R 3/8 inch"
  },
  {
    valueId: "EV009382",
    valueDesc: "Radio frequency 3 kHz - 300 GHz",
    label: "EV009382: Radio frequency 3 kHz - 300 GHz"
  },
  {
    valueId: "EV009383",
    valueDesc: "Radius actuator left",
    label: "EV009383: Radius actuator left"
  },
  {
    valueId: "EV009384",
    valueDesc: "Radius actuator left/right",
    label: "EV009384: Radius actuator left/right"
  },
  {
    valueId: "EV009385",
    valueDesc: "Radius actuator right",
    label: "EV009385: Radius actuator right"
  },
  {
    valueId: "EV009386",
    valueDesc: "Frame clamp",
    label: "EV009386: Frame clamp"
  },
  {
    valueId: "EV009387",
    valueDesc: "Edge connector",
    label: "EV009387: Edge connector"
  },
  {
    valueId: "EV009388",
    valueDesc: "Grid profile",
    label: "EV009388: Grid profile"
  },
  {
    valueId: "EV009389",
    valueDesc: "Reflection tape",
    label: "EV009389: Reflection tape"
  },
  {
    valueId: "EV009390",
    valueDesc: "Reset device",
    label: "EV009390: Reset device"
  },
  {
    valueId: "EV009391",
    valueDesc: "Ring cable connection",
    label: "EV009391: Ring cable connection"
  },
  {
    valueId: "EV009392",
    valueDesc: "RISC",
    label: "EV009392: RISC"
  },
  {
    valueId: "EV009393",
    valueDesc: "Pipe connection",
    label: "EV009393: Pipe connection"
  },
  {
    valueId: "EV009394",
    valueDesc: "Pipe stick current converter",
    label: "EV009394: Pipe stick current converter"
  },
  {
    valueId: "EV009396",
    valueDesc: "Quiescent current principle",
    label: "EV009396: Quiescent current principle"
  },
  {
    valueId: "EV009397",
    valueDesc: "Set with several colours",
    label: "EV009397: Set with several colours"
  },
  {
    valueId: "EV009399",
    valueDesc: "Switch element",
    label: "EV009399: Switch element"
  },
  {
    valueId: "EV009400",
    valueDesc: "Wiring diagram compartment",
    label: "EV009400: Wiring diagram compartment"
  },
  {
    valueId: "EV009401",
    valueDesc: "Wiring diagram holder",
    label: "EV009401: Wiring diagram holder"
  },
  {
    valueId: "EV009402",
    valueDesc: "Wiring diagram desk",
    label: "EV009402: Wiring diagram desk"
  },
  {
    valueId: "EV009403",
    valueDesc: "Wiring diagram pocket",
    label: "EV009403: Wiring diagram pocket"
  },
  {
    valueId: "EV009404",
    valueDesc: "Switching cycle counter",
    label: "EV009404: Switching cycle counter"
  },
  {
    valueId: "EV009405",
    valueDesc: "Switching piece",
    label: "EV009405: Switching piece"
  },
  {
    valueId: "EV009407",
    valueDesc: "Slide bolt profile",
    label: "EV009407: Slide bolt profile"
  },
  {
    valueId: "EV009408",
    valueDesc: "Rail-/direct mounting",
    label: "EV009408: Rail-/direct mounting"
  },
  {
    valueId: "EV009409",
    valueDesc: "Rail connection",
    label: "EV009409: Rail connection"
  },
  {
    valueId: "EV009410",
    valueDesc: "Slow-action switch with overlapping",
    label: "EV009410: Slow-action switch with overlapping"
  },
  {
    valueId: "EV009411",
    valueDesc: "Slow-action switch and quick-break switch",
    label: "EV009411: Slow-action switch and quick-break switch"
  },
  {
    valueId: "EV009412",
    valueDesc: "Lock adapter",
    label: "EV009412: Lock adapter"
  },
  {
    valueId: "EV009413",
    valueDesc: "Lock door",
    label: "EV009413: Lock door"
  },
  {
    valueId: "EV009414",
    valueDesc: "Key actuation",
    label: "EV009414: Key actuation"
  },
  {
    valueId: "EV009415",
    valueDesc: "Snap mounting support rail",
    label: "EV009415: Snap mounting support rail"
  },
  {
    valueId: "EV009416",
    valueDesc: "Fast change device",
    label: "EV009416: Fast change device"
  },
  {
    valueId: "EV009417",
    valueDesc: "Enclosure thread strip",
    label: "EV009417: Enclosure thread strip"
  },
  {
    valueId: "EV009418",
    valueDesc: "Protection cap",
    label: "EV009418: Protection cap"
  },
  {
    valueId: "EV009419",
    valueDesc: "Single-phase current converter",
    label: "EV009419: Single-phase current converter"
  },
  {
    valueId: "EV009420",
    valueDesc: "Welding",
    label: "EV009420: Welding"
  },
  {
    valueId: "EV009421",
    valueDesc: "Swivel drive",
    label: "EV009421: Swivel drive"
  },
  {
    valueId: "EV009422",
    valueDesc: "Swing frame detent",
    label: "EV009422: Swing frame detent"
  },
  {
    valueId: "EV009423",
    valueDesc: "Safe disconnection",
    label: "EV009423: Safe disconnection"
  },
  {
    valueId: "EV009424",
    valueDesc: "Fuses",
    label: "EV009424: Fuses"
  },
  {
    valueId: "EV009425",
    valueDesc: "Protection switch separator",
    label: "EV009425: Protection switch separator"
  },
  {
    valueId: "EV009426",
    valueDesc: "Fuse switch disconnector",
    label: "EV009426: Fuse switch disconnector"
  },
  {
    valueId: "EV009427",
    valueDesc: "Fuse base",
    label: "EV009427: Fuse base"
  },
  {
    valueId: "EV009428",
    valueDesc: "SMD-connection",
    label: "EV009428: SMD-connection"
  },
  {
    valueId: "EV009429",
    valueDesc: "Socket case",
    label: "EV009429: Socket case"
  },
  {
    valueId: "EV009430",
    valueDesc: "Other copper alloys",
    label: "EV009430: Other copper alloys"
  },
  {
    valueId: "EV009431",
    valueDesc: "Other not-copper alloys",
    label: "EV009431: Other not-copper alloys"
  },
  {
    valueId: "EV009432",
    valueDesc: "Voltage detector",
    label: "EV009432: Voltage detector"
  },
  {
    valueId: "EV009433",
    valueDesc: "Storage module",
    label: "EV009433: Storage module"
  },
  {
    valueId: "EV009434",
    valueDesc: "Sensor probe",
    label: "EV009434: Sensor probe"
  },
  {
    valueId: "EV009435",
    valueDesc: "Standard actuator",
    label: "EV009435: Standard actuator"
  },
  {
    valueId: "EV009436",
    valueDesc: "Foot for worktop",
    label: "EV009436: Foot for worktop"
  },
  {
    valueId: "EV009438",
    valueDesc: "Dust protection cover",
    label: "EV009438: Dust protection cover"
  },
  {
    valueId: "EV009439",
    valueDesc: "Plug insert technique",
    label: "EV009439: Plug insert technique"
  },
  {
    valueId: "EV009440",
    valueDesc: "Plug case",
    label: "EV009440: Plug case"
  },
  {
    valueId: "EV009441",
    valueDesc: "Plug set",
    label: "EV009441: Plug set"
  },
  {
    valueId: "EV009442",
    valueDesc: "Push-in clamp connection",
    label: "EV009442: Push-in clamp connection"
  },
  {
    valueId: "EV009443",
    valueDesc: "Connector bracket",
    label: "EV009443: Connector bracket"
  },
  {
    valueId: "EV009446",
    valueDesc: "Connector M14",
    label: "EV009446: Connector M14"
  },
  {
    valueId: "EV009448",
    valueDesc: "Plug-in connection others",
    label: "EV009448: Plug-in connection others"
  },
  {
    valueId: "EV009449",
    valueDesc: "Star-delta switch",
    label: "EV009449: Star-delta switch"
  },
  {
    valueId: "EV009450",
    valueDesc: "Star-delta switching",
    label: "EV009450: Star-delta switching"
  },
  {
    valueId: "EV009451",
    valueDesc: "Star-point bridges",
    label: "EV009451: Star-point bridges"
  },
  {
    valueId: "EV009452",
    valueDesc: "Control cable connection",
    label: "EV009452: Control cable connection"
  },
  {
    valueId: "EV009453",
    valueDesc: "Control board",
    label: "EV009453: Control board"
  },
  {
    valueId: "EV009454",
    valueDesc: "Summation current transformer",
    label: "EV009454: Summation current transformer"
  },
  {
    valueId: "EV009455",
    valueDesc: "Super high frequency 3 - 30 GHz",
    label: "EV009455: Super high frequency 3 - 30 GHz"
  },
  {
    valueId: "EV009456",
    valueDesc: "Symmetrically divided double wing door",
    label: "EV009456: Symmetrically divided double wing door"
  },
  {
    valueId: "EV009457",
    valueDesc: "Systemic",
    label: "EV009457: Systemic"
  },
  {
    valueId: "EV009459",
    valueDesc: "Hinged keyboard drawer",
    label: "EV009459: Hinged keyboard drawer"
  },
  {
    valueId: "EV009460",
    valueDesc: "Telescopic rail",
    label: "EV009460: Telescopic rail"
  },
  {
    valueId: "EV009461",
    valueDesc: "Temperature module",
    label: "EV009461: Temperature module"
  },
  {
    valueId: "EV009462",
    valueDesc: "Thermomagnetic",
    label: "EV009462: Thermomagnetic"
  },
  {
    valueId: "EV009463",
    valueDesc: "THR connection",
    label: "EV009463: THR connection"
  },
  {
    valueId: "EV009464",
    valueDesc: "Depth bracket",
    label: "EV009464: Depth bracket"
  },
  {
    valueId: "EV009466",
    valueDesc: "Component for supporting bracket",
    label: "EV009466: Component for supporting bracket"
  },
  {
    valueId: "EV009467",
    valueDesc: "Supporting plate for monitor",
    label: "EV009467: Supporting plate for monitor"
  },
  {
    valueId: "EV009468",
    valueDesc: "Support rail",
    label: "EV009468: Support rail"
  },
  {
    valueId: "EV009469",
    valueDesc: "Transport lug",
    label: "EV009469: Transport lug"
  },
  {
    valueId: "EV009470",
    valueDesc: "Feed roll",
    label: "EV009470: Feed roll"
  },
  {
    valueId: "EV009471",
    valueDesc: "Transport bracket",
    label: "EV009471: Transport bracket"
  },
  {
    valueId: "EV009472",
    valueDesc: "T-rail",
    label: "EV009472: T-rail"
  },
  {
    valueId: "EV009473",
    valueDesc: "Grommet",
    label: "EV009473: Grommet"
  },
  {
    valueId: "EV009474",
    valueDesc: "Door adapter",
    label: "EV009474: Door adapter"
  },
  {
    valueId: "EV009475",
    valueDesc: "Door detent",
    label: "EV009475: Door detent"
  },
  {
    valueId: "EV009476",
    valueDesc: "Door stopper",
    label: "EV009476: Door stopper"
  },
  {
    valueId: "EV009477",
    valueDesc: "Door coupling rotary drive",
    label: "EV009477: Door coupling rotary drive"
  },
  {
    valueId: "EV009478",
    valueDesc: "Bridgeover",
    label: "EV009478: Bridgeover"
  },
  {
    valueId: "EV009479",
    valueDesc: "Ultra high frequency 300 - 3000 MHz",
    label: "EV009479: Ultra high frequency 300 - 3000 MHz"
  },
  {
    valueId: "EV009480",
    valueDesc: "Converter without intermediate circuit",
    label: "EV009480: Converter without intermediate circuit"
  },
  {
    valueId: "EV009481",
    valueDesc: "Uncoated",
    label: "EV009481: Uncoated"
  },
  {
    valueId: "EV009483",
    valueDesc: "Universal actuator",
    label: "EV009483: Universal actuator"
  },
  {
    valueId: "EV009484",
    valueDesc: "U converter",
    label: "EV009484: U converter"
  },
  {
    valueId: "EV009485",
    valueDesc: "Varistor + LED",
    label: "EV009485: Varistor + LED"
  },
  {
    valueId: "EV009486",
    valueDesc: "Connector/partition piece",
    label: "EV009486: Connector/partition piece"
  },
  {
    valueId: "EV009487",
    valueDesc: "Coupling set, top",
    label: "EV009487: Coupling set, top"
  },
  {
    valueId: "EV009488",
    valueDesc: "Coupling set, side",
    label: "EV009488: Coupling set, side"
  },
  {
    valueId: "EV009489",
    valueDesc: "Coupling set, bottom",
    label: "EV009489: Coupling set, bottom"
  },
  {
    valueId: "EV009490",
    valueDesc: "Coupling piece, top",
    label: "EV009490: Coupling piece, top"
  },
  {
    valueId: "EV009491",
    valueDesc: "Coupling piece, side",
    label: "EV009491: Coupling piece, side"
  },
  {
    valueId: "EV009492",
    valueDesc: "Coupling piece, bottom",
    label: "EV009492: Coupling piece, bottom"
  },
  {
    valueId: "EV009493",
    valueDesc: "Wiring set",
    label: "EV009493: Wiring set"
  },
  {
    valueId: "EV009494",
    valueDesc: "Crank",
    label: "EV009494: Crank"
  },
  {
    valueId: "EV009497",
    valueDesc: "Pinned",
    label: "EV009497: Pinned"
  },
  {
    valueId: "EV009498",
    valueDesc: "Very high frequency 30 - 300 MHz",
    label: "EV009498: Very high frequency 30 - 300 MHz"
  },
  {
    valueId: "EV009499",
    valueDesc: "Very low frequency 3 - 30 kHz",
    label: "EV009499: Very low frequency 3 - 30 kHz"
  },
  {
    valueId: "EV009500",
    valueDesc: "Video frequency circa 0 - 20 MHz",
    label: "EV009500: Video frequency circa 0 - 20 MHz"
  },
  {
    valueId: "EV009501",
    valueDesc: "Full shaft",
    label: "EV009501: Full shaft"
  },
  {
    valueId: "EV009503",
    valueDesc: "Front and rear",
    label: "EV009503: Front and rear"
  },
  {
    valueId: "EV009504",
    valueDesc: "VTM0",
    label: "EV009504: VTM0"
  },
  {
    valueId: "EV009505",
    valueDesc: "VTM1",
    label: "EV009505: VTM1"
  },
  {
    valueId: "EV009506",
    valueDesc: "VTM2",
    label: "EV009506: VTM2"
  },
  {
    valueId: "EV009507",
    valueDesc: "W2 x 4.6",
    label: "EV009507: W2 x 4.6"
  },
  {
    valueId: "EV009508",
    valueDesc: "Wall surface mounted",
    label: "EV009508: Wall surface mounted"
  },
  {
    valueId: "EV009510",
    valueDesc: "Wedge-Base",
    label: "EV009510: Wedge-Base"
  },
  {
    valueId: "EV009511",
    valueDesc: "Reversing Dahlander switch",
    label: "EV009511: Reversing Dahlander switch"
  },
  {
    valueId: "EV009512",
    valueDesc: "Reversing switch",
    label: "EV009512: Reversing switch"
  },
  {
    valueId: "EV009513",
    valueDesc: "Reversing switching",
    label: "EV009513: Reversing switching"
  },
  {
    valueId: "EV009514",
    valueDesc: "Turnaround star-delta switch",
    label: "EV009514: Turnaround star-delta switch"
  },
  {
    valueId: "EV009515",
    valueDesc: "Wound primary current converter",
    label: "EV009515: Wound primary current converter"
  },
  {
    valueId: "EV009516",
    valueDesc: "Windows Server 2003",
    label: "EV009516: Windows Server 2003"
  },
  {
    valueId: "EV009517",
    valueDesc: "Windows Vista",
    label: "EV009517: Windows Vista"
  },
  {
    valueId: "EV009519",
    valueDesc: "Corner set",
    label: "EV009519: Corner set"
  },
  {
    valueId: "EV009521",
    valueDesc: "Y",
    label: "EV009521: Y"
  },
  {
    valueId: "EV009522",
    valueDesc: "Time module delayed release",
    label: "EV009522: Time module delayed release"
  },
  {
    valueId: "EV009523",
    valueDesc: "Time module on delay",
    label: "EV009523: Time module on delay"
  },
  {
    valueId: "EV009524",
    valueDesc: "Time module on/off wiper",
    label: "EV009524: Time module on/off wiper"
  },
  {
    valueId: "EV009525",
    valueDesc: "Time module multifunction",
    label: "EV009525: Time module multifunction"
  },
  {
    valueId: "EV009526",
    valueDesc: "Tin",
    label: "EV009526: Tin"
  },
  {
    valueId: "EV009528",
    valueDesc: "Two-tariff",
    label: "EV009528: Two-tariff"
  },
  {
    valueId: "EV009529",
    valueDesc: "Intermediate frequency range",
    label: "EV009529: Intermediate frequency range"
  },
  {
    valueId: "EV009534",
    valueDesc: "2:1",
    label: "EV009534: 2:1"
  },
  {
    valueId: "EV009535",
    valueDesc: "3:1",
    label: "EV009535: 3:1"
  },
  {
    valueId: "EV009536",
    valueDesc: "4:1",
    label: "EV009536: 4:1"
  },
  {
    valueId: "EV009537",
    valueDesc: "Elastomer (PES)",
    label: "EV009537: Elastomer (PES)"
  },
  {
    valueId: "EV009539",
    valueDesc: "Crosslinked polyolefin (XLPO)",
    label: "EV009539: Crosslinked polyolefin (XLPO)"
  },
  {
    valueId: "EV009541",
    valueDesc: "1/16 inch",
    label: "EV009541: 1/16 inch"
  },
  {
    valueId: "EV009542",
    valueDesc: "10 Gigabit Ethernet",
    label: "EV009542: 10 Gigabit Ethernet"
  },
  {
    valueId: "EV009543",
    valueDesc: "100 V",
    label: "EV009543: 100 V"
  },
  {
    valueId: "EV009545",
    valueDesc: "1 - 10 V",
    label: "EV009545: 1 - 10 V"
  },
  {
    valueId: "EV009546",
    valueDesc: "12/230 V",
    label: "EV009546: 12/230 V"
  },
  {
    valueId: "EV009548",
    valueDesc: "14 V / 18 V",
    label: "EV009548: 14 V / 18 V"
  },
  {
    valueId: "EV009549",
    valueDesc: "14 V / 18 V / 22 kHz",
    label: "EV009549: 14 V / 18 V / 22 kHz"
  },
  {
    valueId: "EV009551",
    valueDesc: "16 Ohm",
    label: "EV009551: 16 Ohm"
  },
  {
    valueId: "EV009552",
    valueDesc: "180°",
    label: "EV009552: 180°"
  },
  {
    valueId: "EV009554",
    valueDesc: "1-component",
    label: "EV009554: 1-component"
  },
  {
    valueId: "EV009555",
    valueDesc: "1-part",
    label: "EV009555: 1-part"
  },
  {
    valueId: "EV009557",
    valueDesc: "20 mm round",
    label: "EV009557: 20 mm round"
  },
  {
    valueId: "EV009558",
    valueDesc: "20/25 mm",
    label: "EV009558: 20/25 mm"
  },
  {
    valueId: "EV009559",
    valueDesc: "20mA (Current Loop)",
    label: "EV009559: 20mA (Current Loop)"
  },
  {
    valueId: "EV009562",
    valueDesc: "24/230 V",
    label: "EV009562: 24/230 V"
  },
  {
    valueId: "EV009566",
    valueDesc: "2-part",
    label: "EV009566: 2-part"
  },
  {
    valueId: "EV009568",
    valueDesc: "3/16 inch",
    label: "EV009568: 3/16 inch"
  },
  {
    valueId: "EV009569",
    valueDesc: "3/32 inch",
    label: "EV009569: 3/32 inch"
  },
  {
    valueId: "EV009571",
    valueDesc: "3/64 inch",
    label: "EV009571: 3/64 inch"
  },
  {
    valueId: "EV009574",
    valueDesc: "45 mm",
    label: "EV009574: 45 mm"
  },
  {
    valueId: "EV009575",
    valueDesc: "5 V from receiver",
    label: "EV009575: 5 V from receiver"
  },
  {
    valueId: "EV009576",
    valueDesc: "5 - 30 MHz",
    label: "EV009576: 5 - 30 MHz"
  },
  {
    valueId: "EV009578",
    valueDesc: "5 - 65 MHz",
    label: "EV009578: 5 - 65 MHz"
  },
  {
    valueId: "EV009579",
    valueDesc: "60 mm",
    label: "EV009579: 60 mm"
  },
  {
    valueId: "EV009580",
    valueDesc: "60 V",
    label: "EV009580: 60 V"
  },
  {
    valueId: "EV009581",
    valueDesc: "60/67.5 mm",
    label: "EV009581: 60/67.5 mm"
  },
  {
    valueId: "EV009582",
    valueDesc: "67.5 mm",
    label: "EV009582: 67.5 mm"
  },
  {
    valueId: "EV009583",
    valueDesc: "6A (IEC)",
    label: "EV009583: 6A (IEC)"
  },
  {
    valueId: "EV009584",
    valueDesc: "7 segments",
    label: "EV009584: 7 segments"
  },
  {
    valueId: "EV009585",
    valueDesc: "7A (IEC)",
    label: "EV009585: 7A (IEC)"
  },
  {
    valueId: "EV009586",
    valueDesc: "7x2.5 mm²",
    label: "EV009586: 7x2.5 mm²"
  },
  {
    valueId: "EV009587",
    valueDesc: "8 Ohm",
    label: "EV009587: 8 Ohm"
  },
  {
    valueId: "EV009588",
    valueDesc: "83.5 mm",
    label: "EV009588: 83.5 mm"
  },
  {
    valueId: "EV009592",
    valueDesc: "Cover plate with signing plate",
    label: "EV009592: Cover plate with signing plate"
  },
  {
    valueId: "EV009593",
    valueDesc: "Branch",
    label: "EV009593: Branch"
  },
  {
    valueId: "EV009594",
    valueDesc: "Adapter for draw bolt",
    label: "EV009594: Adapter for draw bolt"
  },
  {
    valueId: "EV009595",
    valueDesc: "Cable end sleeves",
    label: "EV009595: Cable end sleeves"
  },
  {
    valueId: "EV009598",
    valueDesc: "ADo/VDo",
    label: "EV009598: ADo/VDo"
  },
  {
    valueId: "EV009599",
    valueDesc: "Active/passive/switchable",
    label: "EV009599: Active/passive/switchable"
  },
  {
    valueId: "EV009600",
    valueDesc: "Alcohol-dispenser",
    label: "EV009600: Alcohol-dispenser"
  },
  {
    valueId: "EV009601",
    valueDesc: "Slicing machine",
    label: "EV009601: Slicing machine"
  },
  {
    valueId: "EV009602",
    valueDesc: "Alphanumeric",
    label: "EV009602: Alphanumeric"
  },
  {
    valueId: "EV009605",
    valueDesc: "Adjacent to bundle",
    label: "EV009605: Adjacent to bundle"
  },
  {
    valueId: "EV009606",
    valueDesc: "At testing device",
    label: "EV009606: At testing device"
  },
  {
    valueId: "EV009607",
    valueDesc: "Ammonia",
    label: "EV009607: Ammonia"
  },
  {
    valueId: "EV009610",
    valueDesc: "Lace up set",
    label: "EV009610: Lace up set"
  },
  {
    valueId: "EV009611",
    valueDesc: "Connection element",
    label: "EV009611: Connection element"
  },
  {
    valueId: "EV009612",
    valueDesc: "Connection cable",
    label: "EV009612: Connection cable"
  },
  {
    valueId: "EV009613",
    valueDesc: "Connection set",
    label: "EV009613: Connection set"
  },
  {
    valueId: "EV009614",
    valueDesc: "Shunt release (for power circuit breaker)",
    label: "EV009614: Shunt release (for power circuit breaker)"
  },
  {
    valueId: "EV009615",
    valueDesc: "Locking disc",
    label: "EV009615: Locking disc"
  },
  {
    valueId: "EV009616",
    valueDesc: "At distance",
    label: "EV009616: At distance"
  },
  {
    valueId: "EV009617",
    valueDesc: "At earthing clamp block",
    label: "EV009617: At earthing clamp block"
  },
  {
    valueId: "EV009618",
    valueDesc: "Suspension bracket",
    label: "EV009618: Suspension bracket"
  },
  {
    valueId: "EV009619",
    valueDesc: "Supporting profile",
    label: "EV009619: Supporting profile"
  },
  {
    valueId: "EV009620",
    valueDesc: "Overlay",
    label: "EV009620: Overlay"
  },
  {
    valueId: "EV009621",
    valueDesc: "Top-piece",
    label: "EV009621: Top-piece"
  },
  {
    valueId: "EV009622",
    valueDesc: "Output module",
    label: "EV009622: Output module"
  },
  {
    valueId: "EV009623",
    valueDesc: "Read-out possible",
    label: "EV009623: Read-out possible"
  },
  {
    valueId: "EV009624",
    valueDesc: "Off switch 1-pole",
    label: "EV009624: Off switch 1-pole"
  },
  {
    valueId: "EV009625",
    valueDesc: "Outer side ribbed, inner side grooved",
    label: "EV009625: Outer side ribbed, inner side grooved"
  },
  {
    valueId: "EV009626",
    valueDesc: "Outer side ribbed, inner side plain",
    label: "EV009626: Outer side ribbed, inner side plain"
  },
  {
    valueId: "EV009627",
    valueDesc: "Outer side plain, inner side ribbed",
    label: "EV009627: Outer side plain, inner side ribbed"
  },
  {
    valueId: "EV009628",
    valueDesc: "Outer - and inner side ribbed",
    label: "EV009628: Outer - and inner side ribbed"
  },
  {
    valueId: "EV009629",
    valueDesc: "Outer - and inner side plain",
    label: "EV009629: Outer - and inner side plain"
  },
  {
    valueId: "EV009630",
    valueDesc: "Exterior area",
    label: "EV009630: Exterior area"
  },
  {
    valueId: "EV009631",
    valueDesc: "Outer sleeve",
    label: "EV009631: Outer sleeve"
  },
  {
    valueId: "EV009632",
    valueDesc: "Outdoor cable",
    label: "EV009632: Outdoor cable"
  },
  {
    valueId: "EV009633",
    valueDesc: "Exterior wall",
    label: "EV009633: Exterior wall"
  },
  {
    valueId: "EV009634",
    valueDesc: "Circuit breaker installation unit, 2 x 3-fold",
    label: "EV009634: Circuit breaker installation unit, 2 x 3-fold"
  },
  {
    valueId: "EV009635",
    valueDesc: "Automatic self test",
    label: "EV009635: Automatic self test"
  },
  {
    valueId: "EV009636",
    valueDesc: "Automatic cut off",
    label: "EV009636: Automatic cut off"
  },
  {
    valueId: "EV009637",
    valueDesc: "Bayonet latch",
    label: "EV009637: Bayonet latch"
  },
  {
    valueId: "EV009638",
    valueDesc: "Ball latch",
    label: "EV009638: Ball latch"
  },
  {
    valueId: "EV009639",
    valueDesc: "Ribbon cable (fibre optic)",
    label: "EV009639: Ribbon cable (fibre optic)"
  },
  {
    valueId: "EV009640",
    valueDesc: "Continuously galvanized zinc-aluminium",
    label: "EV009640: Continuously galvanized zinc-aluminium"
  },
  {
    valueId: "EV009641",
    valueDesc: "Base module",
    label: "EV009641: Base module"
  },
  {
    valueId: "EV009642",
    valueDesc: "Construction protective cover",
    label: "EV009642: Construction protective cover"
  },
  {
    valueId: "EV009643",
    valueDesc: "Control element with cover frame",
    label: "EV009643: Control element with cover frame"
  },
  {
    valueId: "EV009644",
    valueDesc: "Fastening ring/-nut",
    label: "EV009644: Fastening ring/-nut"
  },
  {
    valueId: "EV009646",
    valueDesc: "Power adaptor included",
    label: "EV009646: Power adaptor included"
  },
  {
    valueId: "EV009647",
    valueDesc: "Manual/documentation set/permit",
    label: "EV009647: Manual/documentation set/permit"
  },
  {
    valueId: "EV009648",
    valueDesc: "Already assembled",
    label: "EV009648: Already assembled"
  },
  {
    valueId: "EV009649",
    valueDesc: "Standby switching",
    label: "EV009649: Standby switching"
  },
  {
    valueId: "EV009650",
    valueDesc: "Label shield",
    label: "EV009650: Label shield"
  },
  {
    valueId: "EV009651",
    valueDesc: "Indication element",
    label: "EV009651: Indication element"
  },
  {
    valueId: "EV009652",
    valueDesc: "Up to 35 kV",
    label: "EV009652: Up to 35 kV"
  },
  {
    valueId: "EV009653",
    valueDesc: "Up to 600 V",
    label: "EV009653: Up to 600 V"
  },
  {
    valueId: "EV009654",
    valueDesc: "Blind cover",
    label: "EV009654: Blind cover"
  },
  {
    valueId: "EV009655",
    valueDesc: "Blind module",
    label: "EV009655: Blind module"
  },
  {
    valueId: "EV009656",
    valueDesc: "Block magazine",
    label: "EV009656: Block magazine"
  },
  {
    valueId: "EV009657",
    valueDesc: "Blu-ray",
    label: "EV009657: Blu-ray"
  },
  {
    valueId: "EV009658",
    valueDesc: "Carpeting rim profile",
    label: "EV009658: Carpeting rim profile"
  },
  {
    valueId: "EV009659",
    valueDesc: "Bottom plate",
    label: "EV009659: Bottom plate"
  },
  {
    valueId: "EV009660",
    valueDesc: "Fire-proof paint",
    label: "EV009660: Fire-proof paint"
  },
  {
    valueId: "EV009661",
    valueDesc: "Fire-proof cement",
    label: "EV009661: Fire-proof cement"
  },
  {
    valueId: "EV009662",
    valueDesc: "Fire-proof spackling compound",
    label: "EV009662: Fire-proof spackling compound"
  },
  {
    valueId: "EV009663",
    valueDesc: "Tanning appliance",
    label: "EV009663: Tanning appliance"
  },
  {
    valueId: "EV009664",
    valueDesc: "Breakout",
    label: "EV009664: Breakout"
  },
  {
    valueId: "EV009665",
    valueDesc: "Wide angle beam reflector",
    label: "EV009665: Wide angle beam reflector"
  },
  {
    valueId: "EV009666",
    valueDesc: "Bridge",
    label: "EV009666: Bridge"
  },
  {
    valueId: "EV009667",
    valueDesc: "Ironing device",
    label: "EV009667: Ironing device"
  },
  {
    valueId: "EV009668",
    valueDesc: "Ironing machine",
    label: "EV009668: Ironing machine"
  },
  {
    valueId: "EV009670",
    valueDesc: "Bus-module",
    label: "EV009670: Bus-module"
  },
  {
    valueId: "EV009671",
    valueDesc: "CEE socket outlet 3-pole 16 A",
    label: "EV009671: CEE socket outlet 3-pole 16 A"
  },
  {
    valueId: "EV009672",
    valueDesc: "CEE socket outlet 3-pole and 5-pole 16 A",
    label: "EV009672: CEE socket outlet 3-pole and 5-pole 16 A"
  },
  {
    valueId: "EV009673",
    valueDesc: "CEE socket outlet 5-pole 16 A",
    label: "EV009673: CEE socket outlet 5-pole 16 A"
  },
  {
    valueId: "EV009674",
    valueDesc: "CEE socket outlet 5-pole 32 A",
    label: "EV009674: CEE socket outlet 5-pole 32 A"
  },
  {
    valueId: "EV009675",
    valueDesc: "CEE socket outlet 5-pole 63 A",
    label: "EV009675: CEE socket outlet 5-pole 63 A"
  },
  {
    valueId: "EV009676",
    valueDesc: "CEE socket outlets 5-pole 125 A",
    label: "EV009676: CEE socket outlets 5-pole 125 A"
  },
  {
    valueId: "EV009677",
    valueDesc: "C-shape",
    label: "EV009677: C-shape"
  },
  {
    valueId: "EV009679",
    valueDesc: "CSN-Norm",
    label: "EV009679: CSN-Norm"
  },
  {
    valueId: "EV009680",
    valueDesc: "Roof truss holder",
    label: "EV009680: Roof truss holder"
  },
  {
    valueId: "EV009681",
    valueDesc: "DALI",
    label: "EV009681: DALI"
  },
  {
    valueId: "EV009682",
    valueDesc: "Steam oven",
    label: "EV009682: Steam oven"
  },
  {
    valueId: "EV009683",
    valueDesc: "Continuous circuit/emergency circuit",
    label: "EV009683: Continuous circuit/emergency circuit"
  },
  {
    valueId: "EV009684",
    valueDesc: "Cover with hook and clamp",
    label: "EV009684: Cover with hook and clamp"
  },
  {
    valueId: "EV009685",
    valueDesc: "Cover with socket outlet",
    label: "EV009685: Cover with socket outlet"
  },
  {
    valueId: "EV009686",
    valueDesc: "Cover contact",
    label: "EV009686: Cover contact"
  },
  {
    valueId: "EV009687",
    valueDesc: "Cover switch",
    label: "EV009687: Cover switch"
  },
  {
    valueId: "EV009688",
    valueDesc: "Ceiling surface mounting",
    label: "EV009688: Ceiling surface mounting"
  },
  {
    valueId: "EV009689",
    valueDesc: "Decor blind",
    label: "EV009689: Decor blind"
  },
  {
    valueId: "EV009690",
    valueDesc: "Decor-enabled",
    label: "EV009690: Decor-enabled"
  },
  {
    valueId: "EV009691",
    valueDesc: "Design adapter",
    label: "EV009691: Design adapter"
  },
  {
    valueId: "EV009692",
    valueDesc: "Sterilizer",
    label: "EV009692: Sterilizer"
  },
  {
    valueId: "EV009693",
    valueDesc: "Peripheral (individual battery)",
    label: "EV009693: Peripheral (individual battery)"
  },
  {
    valueId: "EV009694",
    valueDesc: "DIBt",
    label: "EV009694: DIBt"
  },
  {
    valueId: "EV009695",
    valueDesc: "Seal membrane",
    label: "EV009695: Seal membrane"
  },
  {
    valueId: "EV009696",
    valueDesc: "Sealing ring",
    label: "EV009696: Sealing ring"
  },
  {
    valueId: "EV009697",
    valueDesc: "Sealing set",
    label: "EV009697: Sealing set"
  },
  {
    valueId: "EV009698",
    valueDesc: "Sealing strip",
    label: "EV009698: Sealing strip"
  },
  {
    valueId: "EV009699",
    valueDesc: "Thick-walled",
    label: "EV009699: Thick-walled"
  },
  {
    valueId: "EV009700",
    valueDesc: "Diffusor",
    label: "EV009700: Diffusor"
  },
  {
    valueId: "EV009701",
    valueDesc: "DIN-version",
    label: "EV009701: DIN-version"
  },
  {
    valueId: "EV009703",
    valueDesc: "Distance frame",
    label: "EV009703: Distance frame"
  },
  {
    valueId: "EV009704",
    valueDesc: "Double lock head",
    label: "EV009704: Double lock head"
  },
  {
    valueId: "EV009705",
    valueDesc: "Food dehydrator",
    label: "EV009705: Food dehydrator"
  },
  {
    valueId: "EV009706",
    valueDesc: "Turn handle",
    label: "EV009706: Turn handle"
  },
  {
    valueId: "EV009709",
    valueDesc: "Three-sided",
    label: "EV009709: Three-sided"
  },
  {
    valueId: "EV009710",
    valueDesc: "D-Sub plug 15-pole",
    label: "EV009710: D-Sub plug 15-pole"
  },
  {
    valueId: "EV009711",
    valueDesc: "D-Sub plug 9-pole",
    label: "EV009711: D-Sub plug 9-pole"
  },
  {
    valueId: "EV009712",
    valueDesc: "Dowel",
    label: "EV009712: Dowel"
  },
  {
    valueId: "EV009713",
    valueDesc: "Thin-walled",
    label: "EV009713: Thin-walled"
  },
  {
    valueId: "EV009714",
    valueDesc: "Through-wiring clamp",
    label: "EV009714: Through-wiring clamp"
  },
  {
    valueId: "EV009715",
    valueDesc: "Nozzle",
    label: "EV009715: Nozzle"
  },
  {
    valueId: "EV009716",
    valueDesc: "Nozzles",
    label: "EV009716: Nozzles"
  },
  {
    valueId: "EV009717",
    valueDesc: "E 2000",
    label: "EV009717: E 2000"
  },
  {
    valueId: "EV009722",
    valueDesc: "Electronic residential meter",
    label: "EV009722: Electronic residential meter"
  },
  {
    valueId: "EV009723",
    valueDesc: "KNX",
    label: "EV009723: KNX"
  },
  {
    valueId: "EV009725",
    valueDesc: "Built-in flange",
    label: "EV009725: Built-in flange"
  },
  {
    valueId: "EV009726",
    valueDesc: "Built-in installation",
    label: "EV009726: Built-in installation"
  },
  {
    valueId: "EV009727",
    valueDesc: "Built-in fuse",
    label: "EV009727: Built-in fuse"
  },
  {
    valueId: "EV009728",
    valueDesc: "Inlet piece",
    label: "EV009728: Inlet piece"
  },
  {
    valueId: "EV009730",
    valueDesc: "Single-conductor connector",
    label: "EV009730: Single-conductor connector"
  },
  {
    valueId: "EV009731",
    valueDesc: "Ice cream mixer",
    label: "EV009731: Ice cream mixer"
  },
  {
    valueId: "EV009732",
    valueDesc: "Ice cream maker",
    label: "EV009732: Ice cream maker"
  },
  {
    valueId: "EV009733",
    valueDesc: "Electro-hydraulic",
    label: "EV009733: Electro-hydraulic"
  },
  {
    valueId: "EV009734",
    valueDesc: "Electro-hydraulic pump",
    label: "EV009734: Electro-hydraulic pump"
  },
  {
    valueId: "EV009735",
    valueDesc: "Electric knife",
    label: "EV009735: Electric knife"
  },
  {
    valueId: "EV009736",
    valueDesc: "End-feed",
    label: "EV009736: End-feed"
  },
  {
    valueId: "EV009737",
    valueDesc: "Endless tape",
    label: "EV009737: Endless tape"
  },
  {
    valueId: "EV009738",
    valueDesc: "Energy technology/Information technology",
    label: "EV009738: Energy technology/Information technology"
  },
  {
    valueId: "EV009740",
    valueDesc: "Discharge lamps",
    label: "EV009740: Discharge lamps"
  },
  {
    valueId: "EV009741",
    valueDesc: "According to UL94 HB",
    label: "EV009741: According to UL94 HB"
  },
  {
    valueId: "EV009742",
    valueDesc: "According to UL94 V0",
    label: "EV009742: According to UL94 V0"
  },
  {
    valueId: "EV009743",
    valueDesc: "According to UL94 V2",
    label: "EV009743: According to UL94 V2"
  },
  {
    valueId: "EV009745",
    valueDesc: "Earthing frame",
    label: "EV009745: Earthing frame"
  },
  {
    valueId: "EV009746",
    valueDesc: "Earthing frame set",
    label: "EV009746: Earthing frame set"
  },
  {
    valueId: "EV009749",
    valueDesc: "Cable bushing",
    label: "EV009749: Cable bushing"
  },
  {
    valueId: "EV009751",
    valueDesc: "Spare fuse",
    label: "EV009751: Spare fuse"
  },
  {
    valueId: "EV009753",
    valueDesc: "Expansion",
    label: "EV009753: Expansion"
  },
  {
    valueId: "EV009754",
    valueDesc: "Expansion kit",
    label: "EV009754: Expansion kit"
  },
  {
    valueId: "EV009756",
    valueDesc: "Adhesive label",
    label: "EV009756: Adhesive label"
  },
  {
    valueId: "EV009759",
    valueDesc: "Ex-Zone 0",
    label: "EV009759: Ex-Zone 0"
  },
  {
    valueId: "EV009760",
    valueDesc: "Ex-Zone 1",
    label: "EV009760: Ex-Zone 1"
  },
  {
    valueId: "EV009761",
    valueDesc: "Ex-Zone 2",
    label: "EV009761: Ex-Zone 2"
  },
  {
    valueId: "EV009762",
    valueDesc: "Ex-Zone 20",
    label: "EV009762: Ex-Zone 20"
  },
  {
    valueId: "EV009763",
    valueDesc: "Ex-Zone 21",
    label: "EV009763: Ex-Zone 21"
  },
  {
    valueId: "EV009764",
    valueDesc: "Ex-Zone 22",
    label: "EV009764: Ex-Zone 22"
  },
  {
    valueId: "EV009765",
    valueDesc: "F/UTP",
    label: "EV009765: F/UTP"
  },
  {
    valueId: "EV009766",
    valueDesc: "Fibre left-over box",
    label: "EV009766: Fibre left-over box"
  },
  {
    valueId: "EV009767",
    valueDesc: "Fast Ethernet",
    label: "EV009767: Fast Ethernet"
  },
  {
    valueId: "EV009768",
    valueDesc: "Fault-signal switch",
    label: "EV009768: Fault-signal switch"
  },
  {
    valueId: "EV009770",
    valueDesc: "Window frame",
    label: "EV009770: Window frame"
  },
  {
    valueId: "EV009771",
    valueDesc: "Remote drive",
    label: "EV009771: Remote drive"
  },
  {
    valueId: "EV009772",
    valueDesc: "Remote release",
    label: "EV009772: Remote release"
  },
  {
    valueId: "EV009773",
    valueDesc: "Remote turn-on switch",
    label: "EV009773: Remote turn-on switch"
  },
  {
    valueId: "EV009774",
    valueDesc: "Earth leakage circuit breaker",
    label: "EV009774: Earth leakage circuit breaker"
  },
  {
    valueId: "EV009775",
    valueDesc: "Earth leakage circuit breaker and miniature circuit breaker (MCB)",
    label: "EV009775: Earth leakage circuit breaker and miniature circuit breaker (MCB)"
  },
  {
    valueId: "EV009776",
    valueDesc: "Clinical thermometer",
    label: "EV009776: Clinical thermometer"
  },
  {
    valueId: "EV009777",
    valueDesc: "F-compression connector",
    label: "EV009777: F-compression connector"
  },
  {
    valueId: "EV009778",
    valueDesc: "Flat pin bushing",
    label: "EV009778: Flat pin bushing"
  },
  {
    valueId: "EV009779",
    valueDesc: "Meat grinder",
    label: "EV009779: Meat grinder"
  },
  {
    valueId: "EV009780",
    valueDesc: "Vacuum sealer",
    label: "EV009780: Vacuum sealer"
  },
  {
    valueId: "EV009781",
    valueDesc: "Form hole punch",
    label: "EV009781: Form hole punch"
  },
  {
    valueId: "EV009782",
    valueDesc: "Photo paper",
    label: "EV009782: Photo paper"
  },
  {
    valueId: "EV009783",
    valueDesc: "Hands free kit",
    label: "EV009783: Hands free kit"
  },
  {
    valueId: "EV009784",
    valueDesc: "Deep fryer",
    label: "EV009784: Deep fryer"
  },
  {
    valueId: "EV009785",
    valueDesc: "Fruitpress",
    label: "EV009785: Fruitpress"
  },
  {
    valueId: "EV009786",
    valueDesc: "Guide rail",
    label: "EV009786: Guide rail"
  },
  {
    valueId: "EV009787",
    valueDesc: "For surface mounted tank",
    label: "EV009787: For surface mounted tank"
  },
  {
    valueId: "EV009788",
    valueDesc: "For built-in of installation devices",
    label: "EV009788: For built-in of installation devices"
  },
  {
    valueId: "EV009789",
    valueDesc: "For cassette",
    label: "EV009789: For cassette"
  },
  {
    valueId: "EV009790",
    valueDesc: "For cable outlet",
    label: "EV009790: For cable outlet"
  },
  {
    valueId: "EV009791",
    valueDesc: "Foot pump",
    label: "EV009791: Foot pump"
  },
  {
    valueId: "EV009792",
    valueDesc: "Galvanic/electrolytic zinc plated zinc-nickel",
    label: "EV009792: Galvanic/electrolytic zinc plated zinc-nickel"
  },
  {
    valueId: "EV009793",
    valueDesc: "Gas discharge conductor",
    label: "EV009793: Gas discharge conductor"
  },
  {
    valueId: "EV009794",
    valueDesc: "Gas cartridge",
    label: "EV009794: Gas cartridge"
  },
  {
    valueId: "EV009795",
    valueDesc: "Housing",
    label: "EV009795: Housing"
  },
  {
    valueId: "EV009796",
    valueDesc: "Gel cast",
    label: "EV009796: Gel cast"
  },
  {
    valueId: "EV009797",
    valueDesc: "Vegetable slicer",
    label: "EV009797: Vegetable slicer"
  },
  {
    valueId: "EV009798",
    valueDesc: "Built-in installation box (device box)",
    label: "EV009798: Built-in installation box (device box)"
  },
  {
    valueId: "EV009799",
    valueDesc: "Device connection box (round/square)",
    label: "EV009799: Device connection box (round/square)"
  },
  {
    valueId: "EV009800",
    valueDesc: "Drinks maker",
    label: "EV009800: Drinks maker"
  },
  {
    valueId: "EV009801",
    valueDesc: "Corn mill",
    label: "EV009801: Corn mill"
  },
  {
    valueId: "EV009802",
    valueDesc: "Thread and closing membrane",
    label: "EV009802: Thread and closing membrane"
  },
  {
    valueId: "EV009803",
    valueDesc: "Thread and prestamping",
    label: "EV009803: Thread and prestamping"
  },
  {
    valueId: "EV009804",
    valueDesc: "Stud bolt",
    label: "EV009804: Stud bolt"
  },
  {
    valueId: "EV009805",
    valueDesc: "Threaded rod adapter",
    label: "EV009805: Threaded rod adapter"
  },
  {
    valueId: "EV009806",
    valueDesc: "G-shape",
    label: "EV009806: G-shape"
  },
  {
    valueId: "EV009807",
    valueDesc: "GG45",
    label: "EV009807: GG45"
  },
  {
    valueId: "EV009808",
    valueDesc: "Gigabit Ethernet",
    label: "EV009808: Gigabit Ethernet"
  },
  {
    valueId: "EV009810",
    valueDesc: "Glass fibre enforced light concrete",
    label: "EV009810: Glass fibre enforced light concrete"
  },
  {
    valueId: "EV009811",
    valueDesc: "Fibre optic connection sleeve",
    label: "EV009811: Fibre optic connection sleeve"
  },
  {
    valueId: "EV009812",
    valueDesc: "Glass fibre reflector",
    label: "EV009812: Glass fibre reflector"
  },
  {
    valueId: "EV009814",
    valueDesc: "Glass fibre pin",
    label: "EV009814: Glass fibre pin"
  },
  {
    valueId: "EV009815",
    valueDesc: "Granite",
    label: "EV009815: Granite"
  },
  {
    valueId: "EV009817",
    valueDesc: "Large (65 l and larger)",
    label: "EV009817: Large (65 l and larger)"
  },
  {
    valueId: "EV009818",
    valueDesc: "Base plate",
    label: "EV009818: Base plate"
  },
  {
    valueId: "EV009819",
    valueDesc: "GSM/UMTS",
    label: "EV009819: GSM/UMTS"
  },
  {
    valueId: "EV009820",
    valueDesc: "Cast iron cooker plate",
    label: "EV009820: Cast iron cooker plate"
  },
  {
    valueId: "EV009824",
    valueDesc: "Half built-in",
    label: "EV009824: Half built-in"
  },
  {
    valueId: "EV009825",
    valueDesc: "Manual actuation",
    label: "EV009825: Manual actuation"
  },
  {
    valueId: "EV009826",
    valueDesc: "Hand coder",
    label: "EV009826: Hand coder"
  },
  {
    valueId: "EV009827",
    valueDesc: "Hand pump",
    label: "EV009827: Hand pump"
  },
  {
    valueId: "EV009828",
    valueDesc: "Booklet shape",
    label: "EV009828: Booklet shape"
  },
  {
    valueId: "EV009829",
    valueDesc: "Electric blanket/electric pillow",
    label: "EV009829: Electric blanket/electric pillow"
  },
  {
    valueId: "EV009830",
    valueDesc: "Shirt ironing system",
    label: "EV009830: Shirt ironing system"
  },
  {
    valueId: "EV009832",
    valueDesc: "Auxiliary switch",
    label: "EV009832: Auxiliary switch"
  },
  {
    valueId: "EV009833",
    valueDesc: "Auxiliary switch/fault-signal switch",
    label: "EV009833: Auxiliary switch/fault-signal switch"
  },
  {
    valueId: "EV009834",
    valueDesc: "High current cable lug and connector",
    label: "EV009834: High current cable lug and connector"
  },
  {
    valueId: "EV009835",
    valueDesc: "Hollow form",
    label: "EV009835: Hollow form"
  },
  {
    valueId: "EV009837",
    valueDesc: "IEC 61076-2-101-A1 M12-D",
    label: "EV009837: IEC 61076-2-101-A1 M12-D"
  },
  {
    valueId: "EV009838",
    valueDesc: "IEC 61076-3-106 variant 1",
    label: "EV009838: IEC 61076-3-106 variant 1"
  },
  {
    valueId: "EV009839",
    valueDesc: "IEC 61076-3-106 variant 4",
    label: "EV009839: IEC 61076-3-106 variant 4"
  },
  {
    valueId: "EV009840",
    valueDesc: "IEC 61076-3-106 variant 5",
    label: "EV009840: IEC 61076-3-106 variant 5"
  },
  {
    valueId: "EV009841",
    valueDesc: "IEC 61076-3-106 variant 6",
    label: "EV009841: IEC 61076-3-106 variant 6"
  },
  {
    valueId: "EV009842",
    valueDesc: "IEC-PAS 61076-3-117 variant 14",
    label: "EV009842: IEC-PAS 61076-3-117 variant 14"
  },
  {
    valueId: "EV009843",
    valueDesc: "Interior area",
    label: "EV009843: Interior area"
  },
  {
    valueId: "EV009844",
    valueDesc: "Internal sleeve",
    label: "EV009844: Internal sleeve"
  },
  {
    valueId: "EV009845",
    valueDesc: "Inner lying analogue",
    label: "EV009845: Inner lying analogue"
  },
  {
    valueId: "EV009846",
    valueDesc: "Inner lying digital",
    label: "EV009846: Inner lying digital"
  },
  {
    valueId: "EV009847",
    valueDesc: "Insect repellent device",
    label: "EV009847: Insect repellent device"
  },
  {
    valueId: "EV009848",
    valueDesc: "IP2XC",
    label: "EV009848: IP2XC"
  },
  {
    valueId: "EV009849",
    valueDesc: "IP-module",
    label: "EV009849: IP-module"
  },
  {
    valueId: "EV009850",
    valueDesc: "IPX1",
    label: "EV009850: IPX1"
  },
  {
    valueId: "EV009851",
    valueDesc: "IPX3",
    label: "EV009851: IPX3"
  },
  {
    valueId: "EV009852",
    valueDesc: "IPX5",
    label: "EV009852: IPX5"
  },
  {
    valueId: "EV009853",
    valueDesc: "Yogurt maker",
    label: "EV009853: Yogurt maker"
  },
  {
    valueId: "EV009854",
    valueDesc: "Cable reservoir",
    label: "EV009854: Cable reservoir"
  },
  {
    valueId: "EV009855",
    valueDesc: "Cable lugs and connectors",
    label: "EV009855: Cable lugs and connectors"
  },
  {
    valueId: "EV009856",
    valueDesc: "Cable lugs and -connectors DIN model",
    label: "EV009856: Cable lugs and -connectors DIN model"
  },
  {
    valueId: "EV009857",
    valueDesc: "Cable lugs and -connectors normal model",
    label: "EV009857: Cable lugs and -connectors normal model"
  },
  {
    valueId: "EV009858",
    valueDesc: "Cable junction box for light pole",
    label: "EV009858: Cable junction box for light pole"
  },
  {
    valueId: "EV009859",
    valueDesc: "Limestone",
    label: "EV009859: Limestone"
  },
  {
    valueId: "EV009860",
    valueDesc: "Cold zone",
    label: "EV009860: Cold zone"
  },
  {
    valueId: "EV009861",
    valueDesc: "Calcium silicate plate",
    label: "EV009861: Calcium silicate plate"
  },
  {
    valueId: "EV009862",
    valueDesc: "Edge clip",
    label: "EV009862: Edge clip"
  },
  {
    valueId: "EV009863",
    valueDesc: "Edge protection tape",
    label: "EV009863: Edge protection tape"
  },
  {
    valueId: "EV009864",
    valueDesc: "Carbonate rock",
    label: "EV009864: Carbonate rock"
  },
  {
    valueId: "EV009865",
    valueDesc: "Card shape",
    label: "EV009865: Card shape"
  },
  {
    valueId: "EV009866",
    valueDesc: "Card lock",
    label: "EV009866: Card lock"
  },
  {
    valueId: "EV009867",
    valueDesc: "Chain suspension",
    label: "EV009867: Chain suspension"
  },
  {
    valueId: "EV009869",
    valueDesc: "Small (12 l - less than 35 l)",
    label: "EV009869: Small (12 l - less than 35 l)"
  },
  {
    valueId: "EV009870",
    valueDesc: "Hook-and-loop fastener",
    label: "EV009870: Hook-and-loop fastener"
  },
  {
    valueId: "EV009871",
    valueDesc: "Razor blade block",
    label: "EV009871: Razor blade block"
  },
  {
    valueId: "EV009872",
    valueDesc: "Dough hook",
    label: "EV009872: Dough hook"
  },
  {
    valueId: "EV009873",
    valueDesc: "Complete (with draw bolt)",
    label: "EV009873: Complete (with draw bolt)"
  },
  {
    valueId: "EV009874",
    valueDesc: "Contact pin",
    label: "EV009874: Contact pin"
  },
  {
    valueId: "EV009875",
    valueDesc: "Contact clip",
    label: "EV009875: Contact clip"
  },
  {
    valueId: "EV009876",
    valueDesc: "Locknut",
    label: "EV009876: Locknut"
  },
  {
    valueId: "EV009877",
    valueDesc: "Headphone",
    label: "EV009877: Headphone"
  },
  {
    valueId: "EV009878",
    valueDesc: "Cross box",
    label: "EV009878: Cross box"
  },
  {
    valueId: "EV009880",
    valueDesc: "Ball bearing nut",
    label: "EV009880: Ball bearing nut"
  },
  {
    valueId: "EV009881",
    valueDesc: "Cooling",
    label: "EV009881: Cooling"
  },
  {
    valueId: "EV009883",
    valueDesc: "Plastic with metal pigments",
    label: "EV009883: Plastic with metal pigments"
  },
  {
    valueId: "EV009884",
    valueDesc: "Plastic lip/-cam",
    label: "EV009884: Plastic lip/-cam"
  },
  {
    valueId: "EV009885",
    valueDesc: "Coupler/connector bended",
    label: "EV009885: Coupler/connector bended"
  },
  {
    valueId: "EV009887",
    valueDesc: "Coupler/connector X-shape",
    label: "EV009887: Coupler/connector X-shape"
  },
  {
    valueId: "EV009888",
    valueDesc: "Coupler/connector Y-shape",
    label: "EV009888: Coupler/connector Y-shape"
  },
  {
    valueId: "EV009889",
    valueDesc: "Short",
    label: "EV009889: Short"
  },
  {
    valueId: "EV009890",
    valueDesc: "Insulated varnish wire-cable lugs and connectors",
    label: "EV009890: Insulated varnish wire-cable lugs and connectors"
  },
  {
    valueId: "EV009891",
    valueDesc: "Insulated varnish wire connector",
    label: "EV009891: Insulated varnish wire connector"
  },
  {
    valueId: "EV009892",
    valueDesc: "Lamella foot",
    label: "EV009892: Lamella foot"
  },
  {
    valueId: "EV009893",
    valueDesc: "LAN",
    label: "EV009893: LAN"
  },
  {
    valueId: "EV009894",
    valueDesc: "Long",
    label: "EV009894: Long"
  },
  {
    valueId: "EV009895",
    valueDesc: "LED-illumination",
    label: "EV009895: LED-illumination"
  },
  {
    valueId: "EV009896",
    valueDesc: "LED-module",
    label: "EV009896: LED-module"
  },
  {
    valueId: "EV009897",
    valueDesc: "Cable outlet",
    label: "EV009897: Cable outlet"
  },
  {
    valueId: "EV009899",
    valueDesc: "Elbow box",
    label: "EV009899: Elbow box"
  },
  {
    valueId: "EV009900",
    valueDesc: "Piercing pinch",
    label: "EV009900: Piercing pinch"
  },
  {
    valueId: "EV009901",
    valueDesc: "Soldering flag",
    label: "EV009901: Soldering flag"
  },
  {
    valueId: "EV009902",
    valueDesc: "Soldering reflector",
    label: "EV009902: Soldering reflector"
  },
  {
    valueId: "EV009903",
    valueDesc: "Air cooling",
    label: "EV009903: Air cooling"
  },
  {
    valueId: "EV009904",
    valueDesc: "Ventilation element",
    label: "EV009904: Ventilation element"
  },
  {
    valueId: "EV009905",
    valueDesc: "Magnetic heads",
    label: "EV009905: Magnetic heads"
  },
  {
    valueId: "EV009906",
    valueDesc: "Manual clipping",
    label: "EV009906: Manual clipping"
  },
  {
    valueId: "EV009907",
    valueDesc: "Massager",
    label: "EV009907: Massager"
  },
  {
    valueId: "EV009908",
    valueDesc: "Matrix",
    label: "EV009908: Matrix"
  },
  {
    valueId: "EV009909",
    valueDesc: "Matrix and punch",
    label: "EV009909: Matrix and punch"
  },
  {
    valueId: "EV009910",
    valueDesc: "Signalling group module",
    label: "EV009910: Signalling group module"
  },
  {
    valueId: "EV009913",
    valueDesc: "Methane",
    label: "EV009913: Methane"
  },
  {
    valueId: "EV009915",
    valueDesc: "Mineral fibres plate",
    label: "EV009915: Mineral fibres plate"
  },
  {
    valueId: "EV009916",
    valueDesc: "With notch",
    label: "EV009916: With notch"
  },
  {
    valueId: "EV009917",
    valueDesc: "With blind cover",
    label: "EV009917: With blind cover"
  },
  {
    valueId: "EV009918",
    valueDesc: "With handle",
    label: "EV009918: With handle"
  },
  {
    valueId: "EV009919",
    valueDesc: "With cable outlet",
    label: "EV009919: With cable outlet"
  },
  {
    valueId: "EV009920",
    valueDesc: "With cam clamps",
    label: "EV009920: With cam clamps"
  },
  {
    valueId: "EV009921",
    valueDesc: "With hinge",
    label: "EV009921: With hinge"
  },
  {
    valueId: "EV009922",
    valueDesc: "With screen",
    label: "EV009922: With screen"
  },
  {
    valueId: "EV009924",
    valueDesc: "With screws",
    label: "EV009924: With screws"
  },
  {
    valueId: "EV009925",
    valueDesc: "With bearer ring/bearer bracket",
    label: "EV009925: With bearer ring/bearer bracket"
  },
  {
    valueId: "EV009926",
    valueDesc: "With tube cable outlet for floor duct",
    label: "EV009926: With tube cable outlet for floor duct"
  },
  {
    valueId: "EV009928",
    valueDesc: "Medium (35 l - less than 65 l)",
    label: "EV009928: Medium (35 l - less than 65 l)"
  },
  {
    valueId: "EV009930",
    valueDesc: "Medium-walled",
    label: "EV009930: Medium-walled"
  },
  {
    valueId: "EV009932",
    valueDesc: "Mixer",
    label: "EV009932: Mixer"
  },
  {
    valueId: "EV009933",
    valueDesc: "Modem box",
    label: "EV009933: Modem box"
  },
  {
    valueId: "EV009934",
    valueDesc: "Module frame",
    label: "EV009934: Module frame"
  },
  {
    valueId: "EV009935",
    valueDesc: "Monochrome",
    label: "EV009935: Monochrome"
  },
  {
    valueId: "EV009936",
    valueDesc: "Mounting bracket",
    label: "EV009936: Mounting bracket"
  },
  {
    valueId: "EV009937",
    valueDesc: "Mounting clip",
    label: "EV009937: Mounting clip"
  },
  {
    valueId: "EV009938",
    valueDesc: "Mounting holder",
    label: "EV009938: Mounting holder"
  },
  {
    valueId: "EV009939",
    valueDesc: "Mounting template",
    label: "EV009939: Mounting template"
  },
  {
    valueId: "EV009940",
    valueDesc: "MPO-F",
    label: "EV009940: MPO-F"
  },
  {
    valueId: "EV009941",
    valueDesc: "MPO-M",
    label: "EV009941: MPO-M"
  },
  {
    valueId: "EV009942",
    valueDesc: "MRJ21",
    label: "EV009942: MRJ21"
  },
  {
    valueId: "EV009943",
    valueDesc: "MTP-F",
    label: "EV009943: MTP-F"
  },
  {
    valueId: "EV009944",
    valueDesc: "MTP-M",
    label: "EV009944: MTP-M"
  },
  {
    valueId: "EV009945",
    valueDesc: "MU",
    label: "EV009945: MU"
  },
  {
    valueId: "EV009946",
    valueDesc: "Multi function",
    label: "EV009946: Multi function"
  },
  {
    valueId: "EV009947",
    valueDesc: "All-in-one device",
    label: "EV009947: All-in-one device"
  },
  {
    valueId: "EV009948",
    valueDesc: "According to IEC/EN 60332-1-2",
    label: "EV009948: According to IEC/EN 60332-1-2"
  },
  {
    valueId: "EV009950",
    valueDesc: "Wedge set",
    label: "EV009950: Wedge set"
  },
  {
    valueId: "EV009951",
    valueDesc: "Nano coated",
    label: "EV009951: Nano coated"
  },
  {
    valueId: "EV009953",
    valueDesc: "Not isolated cable connections",
    label: "EV009953: Not isolated cable connections"
  },
  {
    valueId: "EV009955",
    valueDesc: "Standard version",
    label: "EV009955: Standard version"
  },
  {
    valueId: "EV009956",
    valueDesc: "NTSC",
    label: "EV009956: NTSC"
  },
  {
    valueId: "EV009957",
    valueDesc: "Surface sealing",
    label: "EV009957: Surface sealing"
  },
  {
    valueId: "EV009958",
    valueDesc: "Resistive users",
    label: "EV009958: Resistive users"
  },
  {
    valueId: "EV009959",
    valueDesc: "Resistive users/discharge lamps",
    label: "EV009959: Resistive users/discharge lamps"
  },
  {
    valueId: "EV009960",
    valueDesc: "Without profiling",
    label: "EV009960: Without profiling"
  },
  {
    valueId: "EV009961",
    valueDesc: "Without screen",
    label: "EV009961: Without screen"
  },
  {
    valueId: "EV009962",
    valueDesc: "Without toothing",
    label: "EV009962: Without toothing"
  },
  {
    valueId: "EV009963",
    valueDesc: "Olefin",
    label: "EV009963: Olefin"
  },
  {
    valueId: "EV009968",
    valueDesc: "Power adaptor optional",
    label: "EV009968: Power adaptor optional"
  },
  {
    valueId: "EV009969",
    valueDesc: "PAL",
    label: "EV009969: PAL"
  },
  {
    valueId: "EV009970",
    valueDesc: "Passivated",
    label: "EV009970: Passivated"
  },
  {
    valueId: "EV009971",
    valueDesc: "PCF",
    label: "EV009971: PCF"
  },
  {
    valueId: "EV009972",
    valueDesc: "Care products",
    label: "EV009972: Care products"
  },
  {
    valueId: "EV009973",
    valueDesc: "Phase busbar",
    label: "EV009973: Phase busbar"
  },
  {
    valueId: "EV009975",
    valueDesc: "PLCC type",
    label: "EV009975: PLCC type"
  },
  {
    valueId: "EV009976",
    valueDesc: "Seal",
    label: "EV009976: Seal"
  },
  {
    valueId: "EV009978",
    valueDesc: "Pedestal luminaire",
    label: "EV009978: Pedestal luminaire"
  },
  {
    valueId: "EV009979",
    valueDesc: "POF",
    label: "EV009979: POF"
  },
  {
    valueId: "EV009980",
    valueDesc: "Polish set",
    label: "EV009980: Polish set"
  },
  {
    valueId: "EV009981",
    valueDesc: "Bollard luminaire",
    label: "EV009981: Bollard luminaire"
  },
  {
    valueId: "EV009982",
    valueDesc: "Polyamide flame retardant",
    label: "EV009982: Polyamide flame retardant"
  },
  {
    valueId: "EV009984",
    valueDesc: "Polyamide/polyethylene (PA/PE)",
    label: "EV009984: Polyamide/polyethylene (PA/PE)"
  },
  {
    valueId: "EV009985",
    valueDesc: "Polyester (PET)",
    label: "EV009985: Polyester (PET)"
  },
  {
    valueId: "EV009986",
    valueDesc: "Polyester, glass fibre reinforced",
    label: "EV009986: Polyester, glass fibre reinforced"
  },
  {
    valueId: "EV009987",
    valueDesc: "Polyester/copper",
    label: "EV009987: Polyester/copper"
  },
  {
    valueId: "EV009988",
    valueDesc: "Polyethylene fibreglass-reinforced",
    label: "EV009988: Polyethylene fibreglass-reinforced"
  },
  {
    valueId: "EV009991",
    valueDesc: "Potential equalization",
    label: "EV009991: Potential equalization"
  },
  {
    valueId: "EV009992",
    valueDesc: "Potential equalization set",
    label: "EV009992: Potential equalization set"
  },
  {
    valueId: "EV009998",
    valueDesc: "Test adapter",
    label: "EV009998: Test adapter"
  },
  {
    valueId: "EV009999",
    valueDesc: "Test/measuring case",
    label: "EV009999: Test/measuring case"
  },
  {
    valueId: "EV010001",
    valueDesc: "PUR-sheathed spring steel",
    label: "EV010001: PUR-sheathed spring steel"
  },
  {
    valueId: "EV010002",
    valueDesc: "Quad LNB",
    label: "EV010002: Quad LNB"
  },
  {
    valueId: "EV010004",
    valueDesc: "Shunt pen",
    label: "EV010004: Shunt pen"
  },
  {
    valueId: "EV010006",
    valueDesc: "Snap mounting (engagement)",
    label: "EV010006: Snap mounting (engagement)"
  },
  {
    valueId: "EV010007",
    valueDesc: "Ratchet",
    label: "EV010007: Ratchet"
  },
  {
    valueId: "EV010011",
    valueDesc: "Reduction piece",
    label: "EV010011: Reduction piece"
  },
  {
    valueId: "EV010012",
    valueDesc: "Reducing nose",
    label: "EV010012: Reducing nose"
  },
  {
    valueId: "EV010014",
    valueDesc: "Cleaning under present voltage",
    label: "EV010014: Cleaning under present voltage"
  },
  {
    valueId: "EV010015",
    valueDesc: "Detergent",
    label: "EV010015: Detergent"
  },
  {
    valueId: "EV010016",
    valueDesc: "Cleaning set",
    label: "EV010016: Cleaning set"
  },
  {
    valueId: "EV010017",
    valueDesc: "Cleansing tissue",
    label: "EV010017: Cleansing tissue"
  },
  {
    valueId: "EV010018",
    valueDesc: "Relay module",
    label: "EV010018: Relay module"
  },
  {
    valueId: "EV010019",
    valueDesc: "RJ12 6(4)",
    label: "EV010019: RJ12 6(4)"
  },
  {
    valueId: "EV010020",
    valueDesc: "Pipe pendulum suspension",
    label: "EV010020: Pipe pendulum suspension"
  },
  {
    valueId: "EV010021",
    valueDesc: "Pipe socket",
    label: "EV010021: Pipe socket"
  },
  {
    valueId: "EV010022",
    valueDesc: "Roller shape",
    label: "EV010022: Roller shape"
  },
  {
    valueId: "EV010023",
    valueDesc: "Roller shutter box",
    label: "EV010023: Roller shutter box"
  },
  {
    valueId: "EV010024",
    valueDesc: "Roll compression",
    label: "EV010024: Roll compression"
  },
  {
    valueId: "EV010025",
    valueDesc: "Rustproof and acidproof steel",
    label: "EV010025: Rustproof and acidproof steel"
  },
  {
    valueId: "EV010028",
    valueDesc: "Stainless steel others",
    label: "EV010028: Stainless steel others"
  },
  {
    valueId: "EV010029",
    valueDesc: "Rotating",
    label: "EV010029: Rotating"
  },
  {
    valueId: "EV010030",
    valueDesc: "Mixer/blender",
    label: "EV010030: Mixer/blender"
  },
  {
    valueId: "EV010031",
    valueDesc: "Mixing bowl",
    label: "EV010031: Mixing bowl"
  },
  {
    valueId: "EV010035",
    valueDesc: "Round hole punch",
    label: "EV010035: Round hole punch"
  },
  {
    valueId: "EV010036",
    valueDesc: "Round bar",
    label: "EV010036: Round bar"
  },
  {
    valueId: "EV010037",
    valueDesc: "S/FTP",
    label: "EV010037: S/FTP"
  },
  {
    valueId: "EV010038",
    valueDesc: "Squeezer/juicer",
    label: "EV010038: Squeezer/juicer"
  },
  {
    valueId: "EV010039",
    valueDesc: "Juice centrifuge",
    label: "EV010039: Juice centrifuge"
  },
  {
    valueId: "EV010040",
    valueDesc: "Deflection nozzle",
    label: "EV010040: Deflection nozzle"
  },
  {
    valueId: "EV010041",
    valueDesc: "Formwork adapter",
    label: "EV010041: Formwork adapter"
  },
  {
    valueId: "EV010042",
    valueDesc: "Hinged",
    label: "EV010042: Hinged"
  },
  {
    valueId: "EV010044",
    valueDesc: "Disc",
    label: "EV010044: Disc"
  },
  {
    valueId: "EV010045",
    valueDesc: "Razor foil and razor blade block",
    label: "EV010045: Razor foil and razor blade block"
  },
  {
    valueId: "EV010046",
    valueDesc: "Razor head",
    label: "EV010046: Razor head"
  },
  {
    valueId: "EV010047",
    valueDesc: "Screen connector",
    label: "EV010047: Screen connector"
  },
  {
    valueId: "EV010050",
    valueDesc: "Slot grid",
    label: "EV010050: Slot grid"
  },
  {
    valueId: "EV010052",
    valueDesc: "Cutting unit",
    label: "EV010052: Cutting unit"
  },
  {
    valueId: "EV010054",
    valueDesc: "Interface KNX Powernet",
    label: "EV010054: Interface KNX Powernet"
  },
  {
    valueId: "EV010055",
    valueDesc: "Interface KNX/LON",
    label: "EV010055: Interface KNX/LON"
  },
  {
    valueId: "EV010056",
    valueDesc: "Interface module",
    label: "EV010056: Interface module"
  },
  {
    valueId: "EV010057",
    valueDesc: "Cord switch",
    label: "EV010057: Cord switch"
  },
  {
    valueId: "EV010058",
    valueDesc: "Screw/snap in",
    label: "EV010058: Screw/snap in"
  },
  {
    valueId: "EV010059",
    valueDesc: "Without screws",
    label: "EV010059: Without screws"
  },
  {
    valueId: "EV010061",
    valueDesc: "Shrink tape",
    label: "EV010061: Shrink tape"
  },
  {
    valueId: "EV010062",
    valueDesc: "Shrink cast",
    label: "EV010062: Shrink cast"
  },
  {
    valueId: "EV010063",
    valueDesc: "Socket outlet with protective contact CEE 7/3 (type F) 16 A",
    label: "EV010063: Socket outlet with protective contact CEE 7/3 (type F) 16 A"
  },
  {
    valueId: "EV010064",
    valueDesc: "Socket outlet w/protective contact CEE 7/3 (F) 16 A + CEE socket outlet 5p 16 A",
    label: "EV010064: Socket outlet w/protective contact CEE 7/3 (F) 16 A + CEE socket outlet 5p 16 A"
  },
  {
    valueId: "EV010065",
    valueDesc: "Socket outlet w/protective contact CEE 7/3 (F) 16 A + CEE socket outlet 3p 16 A",
    label: "EV010065: Socket outlet w/protective contact CEE 7/3 (F) 16 A + CEE socket outlet 3p 16 A"
  },
  {
    valueId: "EV010066",
    valueDesc: "Auxiliary switch/relay",
    label: "EV010066: Auxiliary switch/relay"
  },
  {
    valueId: "EV010067",
    valueDesc: "Welded stut socket",
    label: "EV010067: Welded stut socket"
  },
  {
    valueId: "EV010068",
    valueDesc: "Swiss norm type 12",
    label: "EV010068: Swiss norm type 12"
  },
  {
    valueId: "EV010069",
    valueDesc: "Swiss norm type 13",
    label: "EV010069: Swiss norm type 13"
  },
  {
    valueId: "EV010070",
    valueDesc: "SC-RJ",
    label: "EV010070: SC-RJ"
  },
  {
    valueId: "EV010074",
    valueDesc: "Selftest, automatic reclosing and auxiliary switch",
    label: "EV010074: Selftest, automatic reclosing and auxiliary switch"
  },
  {
    valueId: "EV010075",
    valueDesc: "Semi-tight fibre",
    label: "EV010075: Semi-tight fibre"
  },
  {
    valueId: "EV010076",
    valueDesc: "SF/UTP",
    label: "EV010076: SF/UTP"
  },
  {
    valueId: "EV010077",
    valueDesc: "Safety glass",
    label: "EV010077: Safety glass"
  },
  {
    valueId: "EV010078",
    valueDesc: "Safety catch",
    label: "EV010078: Safety catch"
  },
  {
    valueId: "EV010079",
    valueDesc: "Visible analogue",
    label: "EV010079: Visible analogue"
  },
  {
    valueId: "EV010080",
    valueDesc: "Visible digital",
    label: "EV010080: Visible digital"
  },
  {
    valueId: "EV010081",
    valueDesc: "Signalling cover",
    label: "EV010081: Signalling cover"
  },
  {
    valueId: "EV010082",
    valueDesc: "Signalling switch",
    label: "EV010082: Signalling switch"
  },
  {
    valueId: "EV010084",
    valueDesc: "SMD-desoldering tweezers",
    label: "EV010084: SMD-desoldering tweezers"
  },
  {
    valueId: "EV010086",
    valueDesc: "Energy-saving lamp",
    label: "EV010086: Energy-saving lamp"
  },
  {
    valueId: "EV010087",
    valueDesc: "Rafter holder",
    label: "EV010087: Rafter holder"
  },
  {
    valueId: "EV010088",
    valueDesc: "Spiral cord",
    label: "EV010088: Spiral cord"
  },
  {
    valueId: "EV010089",
    valueDesc: "Split band amplifier",
    label: "EV010089: Split band amplifier"
  },
  {
    valueId: "EV010090",
    valueDesc: "Split anchor",
    label: "EV010090: Split anchor"
  },
  {
    valueId: "EV010091",
    valueDesc: "Expanding rivet",
    label: "EV010091: Expanding rivet"
  },
  {
    valueId: "EV010092",
    valueDesc: "With shortbread cookies attachment",
    label: "EV010092: With shortbread cookies attachment"
  },
  {
    valueId: "EV010094",
    valueDesc: "Die-cast steel",
    label: "EV010094: Die-cast steel"
  },
  {
    valueId: "EV010095",
    valueDesc: "Steel nose",
    label: "EV010095: Steel nose"
  },
  {
    valueId: "EV010096",
    valueDesc: "Steel armor cord",
    label: "EV010096: Steel armor cord"
  },
  {
    valueId: "EV010097",
    valueDesc: "Standard domestic switching device",
    label: "EV010097: Standard domestic switching device"
  },
  {
    valueId: "EV010099",
    valueDesc: "Vacuum cleaner bag",
    label: "EV010099: Vacuum cleaner bag"
  },
  {
    valueId: "EV010100",
    valueDesc: "Plug-in anchor/plug-in cam",
    label: "EV010100: Plug-in anchor/plug-in cam"
  },
  {
    valueId: "EV010101",
    valueDesc: "Socket outlet 3-pole 10 A",
    label: "EV010101: Socket outlet 3-pole 10 A"
  },
  {
    valueId: "EV010102",
    valueDesc: "Socket outlet 3-pole 10 A and CEE-Socket outlet 5-pole 16 A",
    label: "EV010102: Socket outlet 3-pole 10 A and CEE-Socket outlet 5-pole 16 A"
  },
  {
    valueId: "EV010103",
    valueDesc: "Socket outlet 3-pole 13 A, Type 13",
    label: "EV010103: Socket outlet 3-pole 13 A, Type 13"
  },
  {
    valueId: "EV010104",
    valueDesc: "Socket outlet 3-pole 16 A",
    label: "EV010104: Socket outlet 3-pole 16 A"
  },
  {
    valueId: "EV010105",
    valueDesc: "Socket outlet 3-pole 16 A, Type 23",
    label: "EV010105: Socket outlet 3-pole 16 A, Type 23"
  },
  {
    valueId: "EV010106",
    valueDesc: "Socket outlet 3-pole 16 A, Type 23 and CEE-Socket outlet 3-pole 16 A",
    label: "EV010106: Socket outlet 3-pole 16 A, Type 23 and CEE-Socket outlet 3-pole 16 A"
  },
  {
    valueId: "EV010107",
    valueDesc: "Socket outlet 3-pole 16 A, Type 23 and CEE-Socket outlet 3-pole 32 A",
    label: "EV010107: Socket outlet 3-pole 16 A, Type 23 and CEE-Socket outlet 3-pole 32 A"
  },
  {
    valueId: "EV010108",
    valueDesc: "Socket outlet 5-pole 10 A, Type 15",
    label: "EV010108: Socket outlet 5-pole 10 A, Type 15"
  },
  {
    valueId: "EV010109",
    valueDesc: "Socket outlet 5-pole 15 A, Type 15",
    label: "EV010109: Socket outlet 5-pole 15 A, Type 15"
  },
  {
    valueId: "EV010110",
    valueDesc: "Socket outlet 5-pole 16 A, Type 25",
    label: "EV010110: Socket outlet 5-pole 16 A, Type 25"
  },
  {
    valueId: "EV010111",
    valueDesc: "Socket outlet 5-pole 16 A, Type 25 and CEE-Socket outlet 3-pole 16 A",
    label: "EV010111: Socket outlet 5-pole 16 A, Type 25 and CEE-Socket outlet 3-pole 16 A"
  },
  {
    valueId: "EV010112",
    valueDesc: "Socket outlet 5-pole 16 A, Type 25 and CEE-Socket outlet 3-pole 32 A",
    label: "EV010112: Socket outlet 5-pole 16 A, Type 25 and CEE-Socket outlet 3-pole 32 A"
  },
  {
    valueId: "EV010113",
    valueDesc: "Socket outlet BS1363 13 A",
    label: "EV010113: Socket outlet BS1363 13 A"
  },
  {
    valueId: "EV010115",
    valueDesc: "Plug/clamp",
    label: "EV010115: Plug/clamp"
  },
  {
    valueId: "EV010116",
    valueDesc: "Plug holder",
    label: "EV010116: Plug holder"
  },
  {
    valueId: "EV010117",
    valueDesc: "Stele",
    label: "EV010117: Stele"
  },
  {
    valueId: "EV010118",
    valueDesc: "Pin connection clamp",
    label: "EV010118: Pin connection clamp"
  },
  {
    valueId: "EV010119",
    valueDesc: "Joint clip bar",
    label: "EV010119: Joint clip bar"
  },
  {
    valueId: "EV010120",
    valueDesc: "Percussion connector",
    label: "EV010120: Percussion connector"
  },
  {
    valueId: "EV010121",
    valueDesc: "TAE 2x6/6 NFF",
    label: "EV010121: TAE 2x6/6 NFF"
  },
  {
    valueId: "EV010122",
    valueDesc: "TAE 3x6 NFN",
    label: "EV010122: TAE 3x6 NFN"
  },
  {
    valueId: "EV010123",
    valueDesc: "TAE 6 NF",
    label: "EV010123: TAE 6 NF"
  },
  {
    valueId: "EV010124",
    valueDesc: "Key extension",
    label: "EV010124: Key extension"
  },
  {
    valueId: "EV010125",
    valueDesc: "TDO",
    label: "EV010125: TDO"
  },
  {
    valueId: "EV010126",
    valueDesc: "Tebis TX",
    label: "EV010126: Tebis TX"
  },
  {
    valueId: "EV010127",
    valueDesc: "Teddy-plush",
    label: "EV010127: Teddy-plush"
  },
  {
    valueId: "EV010128",
    valueDesc: "Carpet protection frame",
    label: "EV010128: Carpet protection frame"
  },
  {
    valueId: "EV010129",
    valueDesc: "Tera",
    label: "EV010129: Tera"
  },
  {
    valueId: "EV010131",
    valueDesc: "TFT-displays",
    label: "EV010131: TFT-displays"
  },
  {
    valueId: "EV010132",
    valueDesc: "Table holder",
    label: "EV010132: Table holder"
  },
  {
    valueId: "EV010133",
    valueDesc: "Titanium coated",
    label: "EV010133: Titanium coated"
  },
  {
    valueId: "EV010134",
    valueDesc: "T-box",
    label: "EV010134: T-box"
  },
  {
    valueId: "EV010135",
    valueDesc: "Toaster",
    label: "EV010135: Toaster"
  },
  {
    valueId: "EV010136",
    valueDesc: "Supporting ring angular",
    label: "EV010136: Supporting ring angular"
  },
  {
    valueId: "EV010137",
    valueDesc: "Supporting ring round",
    label: "EV010137: Supporting ring round"
  },
  {
    valueId: "EV010138",
    valueDesc: "Separating plug",
    label: "EV010138: Separating plug"
  },
  {
    valueId: "EV010139",
    valueDesc: "Dry mortar",
    label: "EV010139: Dry mortar"
  },
  {
    valueId: "EV010140",
    valueDesc: "Trolley",
    label: "EV010140: Trolley"
  },
  {
    valueId: "EV010141",
    valueDesc: "TTL level",
    label: "EV010141: TTL level"
  },
  {
    valueId: "EV010142",
    valueDesc: "UAE/TAE 8(6)/6 NF",
    label: "EV010142: UAE/TAE 8(6)/6 NF"
  },
  {
    valueId: "EV010143",
    valueDesc: "Via interface",
    label: "EV010143: Via interface"
  },
  {
    valueId: "EV010144",
    valueDesc: "Safety module for monitoring (central feeded system)",
    label: "EV010144: Safety module for monitoring (central feeded system)"
  },
  {
    valueId: "EV010145",
    valueDesc: "Monitoring unit",
    label: "EV010145: Monitoring unit"
  },
  {
    valueId: "EV010146",
    valueDesc: "Monitoring unit with automatic reclosing",
    label: "EV010146: Monitoring unit with automatic reclosing"
  },
  {
    valueId: "EV010147",
    valueDesc: "U-shape",
    label: "EV010147: U-shape"
  },
  {
    valueId: "EV010148",
    valueDesc: "UL model",
    label: "EV010148: UL model"
  },
  {
    valueId: "EV010149",
    valueDesc: "Flanging",
    label: "EV010149: Flanging"
  },
  {
    valueId: "EV010150",
    valueDesc: "Sheathed",
    label: "EV010150: Sheathed"
  },
  {
    valueId: "EV010151",
    valueDesc: "Universal front piece",
    label: "EV010151: Universal front piece"
  },
  {
    valueId: "EV010152",
    valueDesc: "Universal cable",
    label: "EV010152: Universal cable"
  },
  {
    valueId: "EV010153",
    valueDesc: "Subfloor box surface mounting",
    label: "EV010153: Subfloor box surface mounting"
  },
  {
    valueId: "EV010154",
    valueDesc: "Under frame for telephone hood",
    label: "EV010154: Under frame for telephone hood"
  },
  {
    valueId: "EV010155",
    valueDesc: "Under voltage coil",
    label: "EV010155: Under voltage coil"
  },
  {
    valueId: "EV010156",
    valueDesc: "VDo 2x4pole",
    label: "EV010156: VDo 2x4pole"
  },
  {
    valueId: "EV010157",
    valueDesc: "VDo 4 pole",
    label: "EV010157: VDo 4 pole"
  },
  {
    valueId: "EV010158",
    valueDesc: "VdS",
    label: "EV010158: VdS"
  },
  {
    valueId: "EV010159",
    valueDesc: "Cable junction box",
    label: "EV010159: Cable junction box"
  },
  {
    valueId: "EV010160",
    valueDesc: "Consumables",
    label: "EV010160: Consumables"
  },
  {
    valueId: "EV010161",
    valueDesc: "Extension claw",
    label: "EV010161: Extension claw"
  },
  {
    valueId: "EV010162",
    valueDesc: "Interlocking",
    label: "EV010162: Interlocking"
  },
  {
    valueId: "EV010163",
    valueDesc: "Various applications",
    label: "EV010163: Various applications"
  },
  {
    valueId: "EV010164",
    valueDesc: "Muff slider",
    label: "EV010164: Muff slider"
  },
  {
    valueId: "EV010165",
    valueDesc: "Muff slider 2-fold",
    label: "EV010165: Muff slider 2-fold"
  },
  {
    valueId: "EV010166",
    valueDesc: "Stranded loose tube",
    label: "EV010166: Stranded loose tube"
  },
  {
    valueId: "EV010167",
    valueDesc: "Vertically descending",
    label: "EV010167: Vertically descending"
  },
  {
    valueId: "EV010168",
    valueDesc: "Vertically rising",
    label: "EV010168: Vertically rising"
  },
  {
    valueId: "EV010170",
    valueDesc: "Fluorelastomer rubber (FKM)",
    label: "EV010170: Fluorelastomer rubber (FKM)"
  },
  {
    valueId: "EV010171",
    valueDesc: "Distant from bundle",
    label: "EV010171: Distant from bundle"
  },
  {
    valueId: "EV010172",
    valueDesc: "Preembossing",
    label: "EV010172: Preembossing"
  },
  {
    valueId: "EV010173",
    valueDesc: "Wall-/ceiling mounting",
    label: "EV010173: Wall-/ceiling mounting"
  },
  {
    valueId: "EV010174",
    valueDesc: "Wall connection collar",
    label: "EV010174: Wall connection collar"
  },
  {
    valueId: "EV010175",
    valueDesc: "Bulkhead fitting",
    label: "EV010175: Bulkhead fitting"
  },
  {
    valueId: "EV010176",
    valueDesc: "Water repellent material",
    label: "EV010176: Water repellent material"
  },
  {
    valueId: "EV010177",
    valueDesc: "Water cooling",
    label: "EV010177: Water cooling"
  },
  {
    valueId: "EV010178",
    valueDesc: "Two-way switch with control lamp",
    label: "EV010178: Two-way switch with control lamp"
  },
  {
    valueId: "EV010180",
    valueDesc: "Weather station",
    label: "EV010180: Weather station"
  },
  {
    valueId: "EV010181",
    valueDesc: "Curler",
    label: "EV010181: Curler"
  },
  {
    valueId: "EV010182",
    valueDesc: "Combination lock",
    label: "EV010182: Combination lock"
  },
  {
    valueId: "EV010183",
    valueDesc: "Dental floss",
    label: "EV010183: Dental floss"
  },
  {
    valueId: "EV010184",
    valueDesc: "Central (central battery)",
    label: "EV010184: Central (central battery)"
  },
  {
    valueId: "EV010185",
    valueDesc: "Centre mounting",
    label: "EV010185: Centre mounting"
  },
  {
    valueId: "EV010186",
    valueDesc: "Central tube",
    label: "EV010186: Central tube"
  },
  {
    valueId: "EV010187",
    valueDesc: "Chopper",
    label: "EV010187: Chopper"
  },
  {
    valueId: "EV010189",
    valueDesc: "Extraction proof",
    label: "EV010189: Extraction proof"
  },
  {
    valueId: "EV010190",
    valueDesc: "Tongue cleaner",
    label: "EV010190: Tongue cleaner"
  },
  {
    valueId: "EV010191",
    valueDesc: "2-component polyurethane resin",
    label: "EV010191: 2-component polyurethane resin"
  },
  {
    valueId: "EV010192",
    valueDesc: "Second receiver",
    label: "EV010192: Second receiver"
  },
  {
    valueId: "EV010193",
    valueDesc: "Twin cable end sleeve",
    label: "EV010193: Twin cable end sleeve"
  },
  {
    valueId: "EV010194",
    valueDesc: "CEE plug (IEC 60309)",
    label: "EV010194: CEE plug (IEC 60309)"
  },
  {
    valueId: "EV010195",
    valueDesc: "CEE coupling (IEC 60309)",
    label: "EV010195: CEE coupling (IEC 60309)"
  },
  {
    valueId: "EV010200",
    valueDesc: "IP25",
    label: "EV010200: IP25"
  },
  {
    valueId: "EV010201",
    valueDesc: "IP13B",
    label: "EV010201: IP13B"
  },
  {
    valueId: "EV010204",
    valueDesc: "Immersion probe",
    label: "EV010204: Immersion probe"
  },
  {
    valueId: "EV010205",
    valueDesc: "Contact sensor",
    label: "EV010205: Contact sensor"
  },
  {
    valueId: "EV010206",
    valueDesc: "Wall inlet/sweep nozzle",
    label: "EV010206: Wall inlet/sweep nozzle"
  },
  {
    valueId: "EV010207",
    valueDesc: "Pipe system",
    label: "EV010207: Pipe system"
  },
  {
    valueId: "EV010214",
    valueDesc: "OP1",
    label: "EV010214: OP1"
  },
  {
    valueId: "EV010215",
    valueDesc: "OP2",
    label: "EV010215: OP2"
  },
  {
    valueId: "EV010216",
    valueDesc: "Spiral",
    label: "EV010216: Spiral"
  },
  {
    valueId: "EV010217",
    valueDesc: "Amnioscope",
    label: "EV010217: Amnioscope"
  },
  {
    valueId: "EV010218",
    valueDesc: "Anal speculum",
    label: "EV010218: Anal speculum"
  },
  {
    valueId: "EV010219",
    valueDesc: "Bronchoscope",
    label: "EV010219: Bronchoscope"
  },
  {
    valueId: "EV010220",
    valueDesc: "Colorimeter",
    label: "EV010220: Colorimeter"
  },
  {
    valueId: "EV010221",
    valueDesc: "Endoscope",
    label: "EV010221: Endoscope"
  },
  {
    valueId: "EV010222",
    valueDesc: "Headlight",
    label: "EV010222: Headlight"
  },
  {
    valueId: "EV010223",
    valueDesc: "Laryngoscope",
    label: "EV010223: Laryngoscope"
  },
  {
    valueId: "EV010225",
    valueDesc: "Microscope",
    label: "EV010225: Microscope"
  },
  {
    valueId: "EV010226",
    valueDesc: "OR-luminaire",
    label: "EV010226: OR-luminaire"
  },
  {
    valueId: "EV010227",
    valueDesc: "Ophthalmoscope",
    label: "EV010227: Ophthalmoscope"
  },
  {
    valueId: "EV010228",
    valueDesc: "Otoscope",
    label: "EV010228: Otoscope"
  },
  {
    valueId: "EV010229",
    valueDesc: "Proctoscope",
    label: "EV010229: Proctoscope"
  },
  {
    valueId: "EV010230",
    valueDesc: "Rectoscope",
    label: "EV010230: Rectoscope"
  },
  {
    valueId: "EV010231",
    valueDesc: "Retinoscope",
    label: "EV010231: Retinoscope"
  },
  {
    valueId: "EV010232",
    valueDesc: "Welded",
    label: "EV010232: Welded"
  },
  {
    valueId: "EV010234",
    valueDesc: "Blind rivetted",
    label: "EV010234: Blind rivetted"
  },
  {
    valueId: "EV010236",
    valueDesc: "Suspended",
    label: "EV010236: Suspended"
  },
  {
    valueId: "EV010237",
    valueDesc: "482.6 mm (19 inch) mounting rack",
    label: "EV010237: 482.6 mm (19 inch) mounting rack"
  },
  {
    valueId: "EV010238",
    valueDesc: "Drawer",
    label: "EV010238: Drawer"
  },
  {
    valueId: "EV010239",
    valueDesc: "Swing frame mounting",
    label: "EV010239: Swing frame mounting"
  },
  {
    valueId: "EV010240",
    valueDesc: "Adapter for lamps",
    label: "EV010240: Adapter for lamps"
  },
  {
    valueId: "EV010241",
    valueDesc: "Hold-up trigger device",
    label: "EV010241: Hold-up trigger device"
  },
  {
    valueId: "EV010242",
    valueDesc: "Axle lead-through",
    label: "EV010242: Axle lead-through"
  },
  {
    valueId: "EV010243",
    valueDesc: "Coil",
    label: "EV010243: Coil"
  },
  {
    valueId: "EV010244",
    valueDesc: "Current transformer",
    label: "EV010244: Current transformer"
  },
  {
    valueId: "EV010245",
    valueDesc: "Triangular",
    label: "EV010245: Triangular"
  },
  {
    valueId: "EV010246",
    valueDesc: "Square hole",
    label: "EV010246: Square hole"
  },
  {
    valueId: "EV010247",
    valueDesc: "Immune against magnetic DC- and AC-field",
    label: "EV010247: Immune against magnetic DC- and AC-field"
  },
  {
    valueId: "EV010248",
    valueDesc: "Immune against magnetic DC-field",
    label: "EV010248: Immune against magnetic DC-field"
  },
  {
    valueId: "EV010249",
    valueDesc: "Immune against magnetic AC-field",
    label: "EV010249: Immune against magnetic AC-field"
  },
  {
    valueId: "EV010250",
    valueDesc: "Stainless steel 304 (V2A), coppered",
    label: "EV010250: Stainless steel 304 (V2A), coppered"
  },
  {
    valueId: "EV010252",
    valueDesc: "Thermo galvanized steel/die-cast zinc",
    label: "EV010252: Thermo galvanized steel/die-cast zinc"
  },
  {
    valueId: "EV010253",
    valueDesc: "Thermo galvanized steel/stainless die-cast zinc",
    label: "EV010253: Thermo galvanized steel/stainless die-cast zinc"
  },
  {
    valueId: "EV010265",
    valueDesc: "Up to 30 mm",
    label: "EV010265: Up to 30 mm"
  },
  {
    valueId: "EV010266",
    valueDesc: "Connecting vane",
    label: "EV010266: Connecting vane"
  },
  {
    valueId: "EV010267",
    valueDesc: "Copper plated aluminium plate",
    label: "EV010267: Copper plated aluminium plate"
  },
  {
    valueId: "EV010268",
    valueDesc: "Copper plated aluminium sleeves",
    label: "EV010268: Copper plated aluminium sleeves"
  },
  {
    valueId: "EV010269",
    valueDesc: "Bushing for roofs, walls and earthing electrodes",
    label: "EV010269: Bushing for roofs, walls and earthing electrodes"
  },
  {
    valueId: "EV010270",
    valueDesc: "Expansion strap",
    label: "EV010270: Expansion strap"
  },
  {
    valueId: "EV010271",
    valueDesc: "Expansion piece",
    label: "EV010271: Expansion piece"
  },
  {
    valueId: "EV010272",
    valueDesc: "Wire dresser device",
    label: "EV010272: Wire dresser device"
  },
  {
    valueId: "EV010273",
    valueDesc: "Earthing pipe",
    label: "EV010273: Earthing pipe"
  },
  {
    valueId: "EV010274",
    valueDesc: "Inspection door",
    label: "EV010274: Inspection door"
  },
  {
    valueId: "EV010275",
    valueDesc: "Support",
    label: "EV010275: Support"
  },
  {
    valueId: "EV010276",
    valueDesc: "Support pipe",
    label: "EV010276: Support pipe"
  },
  {
    valueId: "EV010277",
    valueDesc: "Inspection housing",
    label: "EV010277: Inspection housing"
  },
  {
    valueId: "EV010278",
    valueDesc: "Bridgeover tape",
    label: "EV010278: Bridgeover tape"
  },
  {
    valueId: "EV010279",
    valueDesc: "Bridgeover cord",
    label: "EV010279: Bridgeover cord"
  },
  {
    valueId: "EV010280",
    valueDesc: "Pipeline",
    label: "EV010280: Pipeline"
  },
  {
    valueId: "EV010281",
    valueDesc: "Fastenings",
    label: "EV010281: Fastenings"
  },
  {
    valueId: "EV010282",
    valueDesc: "Cherry tree",
    label: "EV010282: Cherry tree"
  },
  {
    valueId: "EV010283",
    valueDesc: "Decorative foil",
    label: "EV010283: Decorative foil"
  },
  {
    valueId: "EV010284",
    valueDesc: "Background fade-out",
    label: "EV010284: Background fade-out"
  },
  {
    valueId: "EV010285",
    valueDesc: "Foreground fade-out",
    label: "EV010285: Foreground fade-out"
  },
  {
    valueId: "EV010286",
    valueDesc: "10x38 mm",
    label: "EV010286: 10x38 mm"
  },
  {
    valueId: "EV010287",
    valueDesc: "14x51 mm",
    label: "EV010287: 14x51 mm"
  },
  {
    valueId: "EV010288",
    valueDesc: "22x58 mm",
    label: "EV010288: 22x58 mm"
  },
  {
    valueId: "EV010289",
    valueDesc: "27x60 mm",
    label: "EV010289: 27x60 mm"
  },
  {
    valueId: "EV010290",
    valueDesc: "4-pins",
    label: "EV010290: 4-pins"
  },
  {
    valueId: "EV010291",
    valueDesc: "6.35x32 mm",
    label: "EV010291: 6.35x32 mm"
  },
  {
    valueId: "EV010302",
    valueDesc: "Connection cable with Europlug",
    label: "EV010302: Connection cable with Europlug"
  },
  {
    valueId: "EV010303",
    valueDesc: "Connection cable systemplug",
    label: "EV010303: Connection cable systemplug"
  },
  {
    valueId: "EV010304",
    valueDesc: "Switch off",
    label: "EV010304: Switch off"
  },
  {
    valueId: "EV010305",
    valueDesc: "B12.3d",
    label: "EV010305: B12.3d"
  },
  {
    valueId: "EV010306",
    valueDesc: "B24s",
    label: "EV010306: B24s"
  },
  {
    valueId: "EV010307",
    valueDesc: "B8.4d",
    label: "EV010307: B8.4d"
  },
  {
    valueId: "EV010308",
    valueDesc: "BA15d/17",
    label: "EV010308: BA15d/17"
  },
  {
    valueId: "EV010309",
    valueDesc: "BA15d/19",
    label: "EV010309: BA15d/19"
  },
  {
    valueId: "EV010310",
    valueDesc: "BA15s/19",
    label: "EV010310: BA15s/19"
  },
  {
    valueId: "EV010311",
    valueDesc: "BA21d",
    label: "EV010311: BA21d"
  },
  {
    valueId: "EV010312",
    valueDesc: "BA21d-3",
    label: "EV010312: BA21d-3"
  },
  {
    valueId: "EV010313",
    valueDesc: "BA21s",
    label: "EV010313: BA21s"
  },
  {
    valueId: "EV010314",
    valueDesc: "BA22d",
    label: "EV010314: BA22d"
  },
  {
    valueId: "EV010315",
    valueDesc: "BA9d",
    label: "EV010315: BA9d"
  },
  {
    valueId: "EV010316",
    valueDesc: "BA9s/13",
    label: "EV010316: BA9s/13"
  },
  {
    valueId: "EV010317",
    valueDesc: "BAX",
    label: "EV010317: BAX"
  },
  {
    valueId: "EV010318",
    valueDesc: "BAX10s",
    label: "EV010318: BAX10s"
  },
  {
    valueId: "EV010319",
    valueDesc: "BAX10y",
    label: "EV010319: BAX10y"
  },
  {
    valueId: "EV010320",
    valueDesc: "BAX15d",
    label: "EV010320: BAX15d"
  },
  {
    valueId: "EV010321",
    valueDesc: "BAX8.4d",
    label: "EV010321: BAX8.4d"
  },
  {
    valueId: "EV010322",
    valueDesc: "BAX10d",
    label: "EV010322: BAX10d"
  },
  {
    valueId: "EV010323",
    valueDesc: "Bi-pin",
    label: "EV010323: Bi-pin"
  },
  {
    valueId: "EV010325",
    valueDesc: "BX22d",
    label: "EV010325: BX22d"
  },
  {
    valueId: "EV010326",
    valueDesc: "BX22d/32",
    label: "EV010326: BX22d/32"
  },
  {
    valueId: "EV010327",
    valueDesc: "BX8.4d",
    label: "EV010327: BX8.4d"
  },
  {
    valueId: "EV010328",
    valueDesc: "BX8.5d",
    label: "EV010328: BX8.5d"
  },
  {
    valueId: "EV010333",
    valueDesc: "DIN-rail/screw",
    label: "EV010333: DIN-rail/screw"
  },
  {
    valueId: "EV010334",
    valueDesc: "Direct connection luminaire",
    label: "EV010334: Direct connection luminaire"
  },
  {
    valueId: "EV010335",
    valueDesc: "Power current -/alternating current distributor",
    label: "EV010335: Power current -/alternating current distributor"
  },
  {
    valueId: "EV010336",
    valueDesc: "Dynamic",
    label: "EV010336: Dynamic"
  },
  {
    valueId: "EV010337",
    valueDesc: "E10/14",
    label: "EV010337: E10/14"
  },
  {
    valueId: "EV010338",
    valueDesc: "E11",
    label: "EV010338: E11"
  },
  {
    valueId: "EV010339",
    valueDesc: "E14/17",
    label: "EV010339: E14/17"
  },
  {
    valueId: "EV010340",
    valueDesc: "E16",
    label: "EV010340: E16"
  },
  {
    valueId: "EV010341",
    valueDesc: "E17",
    label: "EV010341: E17"
  },
  {
    valueId: "EV010342",
    valueDesc: "E19",
    label: "EV010342: E19"
  },
  {
    valueId: "EV010343",
    valueDesc: "E26",
    label: "EV010343: E26"
  },
  {
    valueId: "EV010344",
    valueDesc: "E5",
    label: "EV010344: E5"
  },
  {
    valueId: "EV010345",
    valueDesc: "E5/8",
    label: "EV010345: E5/8"
  },
  {
    valueId: "EV010346",
    valueDesc: "EBS",
    label: "EV010346: EBS"
  },
  {
    valueId: "EV010347",
    valueDesc: "EBS-L",
    label: "EV010347: EBS-L"
  },
  {
    valueId: "EV010348",
    valueDesc: "ENL",
    label: "EV010348: ENL"
  },
  {
    valueId: "EV010349",
    valueDesc: "EYC",
    label: "EV010349: EYC"
  },
  {
    valueId: "EV010350",
    valueDesc: "EYF",
    label: "EV010350: EYF"
  },
  {
    valueId: "EV010351",
    valueDesc: "FB-2Y",
    label: "EV010351: FB-2Y"
  },
  {
    valueId: "EV010352",
    valueDesc: "Festoon",
    label: "EV010352: Festoon"
  },
  {
    valueId: "EV010353",
    valueDesc: "FMT",
    label: "EV010353: FMT"
  },
  {
    valueId: "EV010354",
    valueDesc: "FMV",
    label: "EV010354: FMV"
  },
  {
    valueId: "EV010355",
    valueDesc: "FPF",
    label: "EV010355: FPF"
  },
  {
    valueId: "EV010356",
    valueDesc: "Free conductor end",
    label: "EV010356: Free conductor end"
  },
  {
    valueId: "EV010357",
    valueDesc: "FTA",
    label: "EV010357: FTA"
  },
  {
    valueId: "EV010358",
    valueDesc: "FTC",
    label: "EV010358: FTC"
  },
  {
    valueId: "EV010359",
    valueDesc: "FTF",
    label: "EV010359: FTF"
  },
  {
    valueId: "EV010360",
    valueDesc: "G16",
    label: "EV010360: G16"
  },
  {
    valueId: "EV010361",
    valueDesc: "G17t",
    label: "EV010361: G17t"
  },
  {
    valueId: "EV010362",
    valueDesc: "G8",
    label: "EV010362: G8"
  },
  {
    valueId: "EV010363",
    valueDesc: "GAX",
    label: "EV010363: GAX"
  },
  {
    valueId: "EV010364",
    valueDesc: "Encapsulated",
    label: "EV010364: Encapsulated"
  },
  {
    valueId: "EV010365",
    valueDesc: "Clicked",
    label: "EV010365: Clicked"
  },
  {
    valueId: "EV010366",
    valueDesc: "Device connection cable",
    label: "EV010366: Device connection cable"
  },
  {
    valueId: "EV010368",
    valueDesc: "H05V2V2-F marinacable",
    label: "EV010368: H05V2V2-F marinacable"
  },
  {
    valueId: "EV010369",
    valueDesc: "H05VV-F / L03VV",
    label: "EV010369: H05VV-F / L03VV"
  },
  {
    valueId: "EV010370",
    valueDesc: "H05Z1Z1-F",
    label: "EV010370: H05Z1Z1-F"
  },
  {
    valueId: "EV010371",
    valueDesc: "Impregnated",
    label: "EV010371: Impregnated"
  },
  {
    valueId: "EV010372",
    valueDesc: "No thread connection",
    label: "EV010372: No thread connection"
  },
  {
    valueId: "EV010375",
    valueDesc: "Combi distributor block",
    label: "EV010375: Combi distributor block"
  },
  {
    valueId: "EV010377",
    valueDesc: "L03 VV-U",
    label: "EV010377: L03 VV-U"
  },
  {
    valueId: "EV010378",
    valueDesc: "L03 Z1Z1-U",
    label: "EV010378: L03 Z1Z1-U"
  },
  {
    valueId: "EV010379",
    valueDesc: "L1",
    label: "EV010379: L1"
  },
  {
    valueId: "EV010380",
    valueDesc: "L1-L3",
    label: "EV010380: L1-L3"
  },
  {
    valueId: "EV010381",
    valueDesc: "L2",
    label: "EV010381: L2"
  },
  {
    valueId: "EV010382",
    valueDesc: "L3",
    label: "EV010382: L3"
  },
  {
    valueId: "EV010383",
    valueDesc: "Lens meter",
    label: "EV010383: Lens meter"
  },
  {
    valueId: "EV010384",
    valueDesc: "Litz wire 50x0.25",
    label: "EV010384: Litz wire 50x0.25"
  },
  {
    valueId: "EV010385",
    valueDesc: "LiYY",
    label: "EV010385: LiYY"
  },
  {
    valueId: "EV010387",
    valueDesc: "MF",
    label: "EV010387: MF"
  },
  {
    valueId: "EV010388",
    valueDesc: "MFHZ",
    label: "EV010388: MFHZ"
  },
  {
    valueId: "EV010389",
    valueDesc: "MFJF",
    label: "EV010389: MFJF"
  },
  {
    valueId: "EV010390",
    valueDesc: "MFSL",
    label: "EV010390: MFSL"
  },
  {
    valueId: "EV010391",
    valueDesc: "With insert clip",
    label: "EV010391: With insert clip"
  },
  {
    valueId: "EV010392",
    valueDesc: "With edge clip",
    label: "EV010392: With edge clip"
  },
  {
    valueId: "EV010393",
    valueDesc: "With wedge socket",
    label: "EV010393: With wedge socket"
  },
  {
    valueId: "EV010394",
    valueDesc: "With lamella foot",
    label: "EV010394: With lamella foot"
  },
  {
    valueId: "EV010395",
    valueDesc: "With welded stut socket",
    label: "EV010395: With welded stut socket"
  },
  {
    valueId: "EV010396",
    valueDesc: "With expanding rivet",
    label: "EV010396: With expanding rivet"
  },
  {
    valueId: "EV010397",
    valueDesc: "With plug bolt/expanding bolt",
    label: "EV010397: With plug bolt/expanding bolt"
  },
  {
    valueId: "EV010398",
    valueDesc: "With push fit plug",
    label: "EV010398: With push fit plug"
  },
  {
    valueId: "EV010399",
    valueDesc: "N2GMH2G",
    label: "EV010399: N2GMH2G"
  },
  {
    valueId: "EV010400",
    valueDesc: "Neo-wedge",
    label: "EV010400: Neo-wedge"
  },
  {
    valueId: "EV010401",
    valueDesc: "Power cord",
    label: "EV010401: Power cord"
  },
  {
    valueId: "EV010403",
    valueDesc: "Mains plug",
    label: "EV010403: Mains plug"
  },
  {
    valueId: "EV010404",
    valueDesc: "Without (PCB mounting)",
    label: "EV010404: Without (PCB mounting)"
  },
  {
    valueId: "EV010405",
    valueDesc: "Without adapter piece",
    label: "EV010405: Without adapter piece"
  },
  {
    valueId: "EV010406",
    valueDesc: "Oilflex classic 100",
    label: "EV010406: Oilflex classic 100"
  },
  {
    valueId: "EV010407",
    valueDesc: "Oilflex classic 110",
    label: "EV010407: Oilflex classic 110"
  },
  {
    valueId: "EV010408",
    valueDesc: "P15d",
    label: "EV010408: P15d"
  },
  {
    valueId: "EV010409",
    valueDesc: "P21d",
    label: "EV010409: P21d"
  },
  {
    valueId: "EV010411",
    valueDesc: "P22s",
    label: "EV010411: P22s"
  },
  {
    valueId: "EV010412",
    valueDesc: "P25d",
    label: "EV010412: P25d"
  },
  {
    valueId: "EV010413",
    valueDesc: "P25d/4",
    label: "EV010413: P25d/4"
  },
  {
    valueId: "EV010414",
    valueDesc: "P25s",
    label: "EV010414: P25s"
  },
  {
    valueId: "EV010415",
    valueDesc: "P26",
    label: "EV010415: P26"
  },
  {
    valueId: "EV010416",
    valueDesc: "P30d",
    label: "EV010416: P30d"
  },
  {
    valueId: "EV010417",
    valueDesc: "P30s",
    label: "EV010417: P30s"
  },
  {
    valueId: "EV010418",
    valueDesc: "P36d",
    label: "EV010418: P36d"
  },
  {
    valueId: "EV010419",
    valueDesc: "P36S",
    label: "EV010419: P36S"
  },
  {
    valueId: "EV010420",
    valueDesc: "P36T",
    label: "EV010420: P36T"
  },
  {
    valueId: "EV010421",
    valueDesc: "P40s",
    label: "EV010421: P40s"
  },
  {
    valueId: "EV010422",
    valueDesc: "P44S",
    label: "EV010422: P44S"
  },
  {
    valueId: "EV010424",
    valueDesc: "P47D",
    label: "EV010424: P47D"
  },
  {
    valueId: "EV010425",
    valueDesc: "PG13",
    label: "EV010425: PG13"
  },
  {
    valueId: "EV010426",
    valueDesc: "PG16x20",
    label: "EV010426: PG16x20"
  },
  {
    valueId: "EV010427",
    valueDesc: "PG22",
    label: "EV010427: PG22"
  },
  {
    valueId: "EV010428",
    valueDesc: "PGJ13",
    label: "EV010428: PGJ13"
  },
  {
    valueId: "EV010431",
    valueDesc: "PKX22S",
    label: "EV010431: PKX22S"
  },
  {
    valueId: "EV010432",
    valueDesc: "PKX30d",
    label: "EV010432: PKX30d"
  },
  {
    valueId: "EV010433",
    valueDesc: "Potential distributor",
    label: "EV010433: Potential distributor"
  },
  {
    valueId: "EV010434",
    valueDesc: "PSB",
    label: "EV010434: PSB"
  },
  {
    valueId: "EV010435",
    valueDesc: "PX15d",
    label: "EV010435: PX15d"
  },
  {
    valueId: "EV010436",
    valueDesc: "PX15d/3",
    label: "EV010436: PX15d/3"
  },
  {
    valueId: "EV010437",
    valueDesc: "PX29",
    label: "EV010437: PX29"
  },
  {
    valueId: "EV010438",
    valueDesc: "PY16-1.25",
    label: "EV010438: PY16-1.25"
  },
  {
    valueId: "EV010439",
    valueDesc: "PY28d",
    label: "EV010439: PY28d"
  },
  {
    valueId: "EV010440",
    valueDesc: "Rigid loop",
    label: "EV010440: Rigid loop"
  },
  {
    valueId: "EV010441",
    valueDesc: "S05HH-F",
    label: "EV010441: S05HH-F"
  },
  {
    valueId: "EV010442",
    valueDesc: "S05Z1Z1-F",
    label: "EV010442: S05Z1Z1-F"
  },
  {
    valueId: "EV010443",
    valueDesc: "S05Z1Z1-F / L03Z1Z1",
    label: "EV010443: S05Z1Z1-F / L03Z1Z1"
  },
  {
    valueId: "EV010444",
    valueDesc: "S25",
    label: "EV010444: S25"
  },
  {
    valueId: "EV010445",
    valueDesc: "S6",
    label: "EV010445: S6"
  },
  {
    valueId: "EV010446",
    valueDesc: "S7",
    label: "EV010446: S7"
  },
  {
    valueId: "EV010447",
    valueDesc: "S8.5",
    label: "EV010447: S8.5"
  },
  {
    valueId: "EV010448",
    valueDesc: "Screw and rivet",
    label: "EV010448: Screw and rivet"
  },
  {
    valueId: "EV010450",
    valueDesc: "Series circuit",
    label: "EV010450: Series circuit"
  },
  {
    valueId: "EV010451",
    valueDesc: "SF6",
    label: "EV010451: SF6"
  },
  {
    valueId: "EV010452",
    valueDesc: "Ag/Sn20",
    label: "EV010452: Ag/Sn20"
  },
  {
    valueId: "EV010454",
    valueDesc: "SP-base",
    label: "EV010454: SP-base"
  },
  {
    valueId: "EV010455",
    valueDesc: "Static",
    label: "EV010455: Static"
  },
  {
    valueId: "EV010456",
    valueDesc: "Control",
    label: "EV010456: Control"
  },
  {
    valueId: "EV010457",
    valueDesc: "SX15s",
    label: "EV010457: SX15s"
  },
  {
    valueId: "EV010458",
    valueDesc: "System plug",
    label: "EV010458: System plug"
  },
  {
    valueId: "EV010459",
    valueDesc: "T10",
    label: "EV010459: T10"
  },
  {
    valueId: "EV010460",
    valueDesc: "T2.25",
    label: "EV010460: T2.25"
  },
  {
    valueId: "EV010461",
    valueDesc: "T3",
    label: "EV010461: T3"
  },
  {
    valueId: "EV010462",
    valueDesc: "T3.8",
    label: "EV010462: T3.8"
  },
  {
    valueId: "EV010463",
    valueDesc: "T4",
    label: "EV010463: T4"
  },
  {
    valueId: "EV010464",
    valueDesc: "T4.2",
    label: "EV010464: T4.2"
  },
  {
    valueId: "EV010465",
    valueDesc: "T4.7",
    label: "EV010465: T4.7"
  },
  {
    valueId: "EV010466",
    valueDesc: "T6.5F",
    label: "EV010466: T6.5F"
  },
  {
    valueId: "EV010467",
    valueDesc: "T6.8G",
    label: "EV010467: T6.8G"
  },
  {
    valueId: "EV010468",
    valueDesc: "T6.8K",
    label: "EV010468: T6.8K"
  },
  {
    valueId: "EV010469",
    valueDesc: "Tracer switching",
    label: "EV010469: Tracer switching"
  },
  {
    valueId: "EV010472",
    valueDesc: "Ultrasonic compressed wire ends",
    label: "EV010472: Ultrasonic compressed wire ends"
  },
  {
    valueId: "EV010473",
    valueDesc: "Conversion kit",
    label: "EV010473: Conversion kit"
  },
  {
    valueId: "EV010474",
    valueDesc: "Unpreassembled connection cable",
    label: "EV010474: Unpreassembled connection cable"
  },
  {
    valueId: "EV010475",
    valueDesc: "U-inter-coupling",
    label: "EV010475: U-inter-coupling"
  },
  {
    valueId: "EV010476",
    valueDesc: "Extension cable",
    label: "EV010476: Extension cable"
  },
  {
    valueId: "EV010477",
    valueDesc: "Fully revited",
    label: "EV010477: Fully revited"
  },
  {
    valueId: "EV010478",
    valueDesc: "WE",
    label: "EV010478: WE"
  },
  {
    valueId: "EV010479",
    valueDesc: "Two-way switching",
    label: "EV010479: Two-way switching"
  },
  {
    valueId: "EV010480",
    valueDesc: "X513",
    label: "EV010480: X513"
  },
  {
    valueId: "EV010481",
    valueDesc: "X514",
    label: "EV010481: X514"
  },
  {
    valueId: "EV010482",
    valueDesc: "X514SP",
    label: "EV010482: X514SP"
  },
  {
    valueId: "EV010483",
    valueDesc: "Intermediate coupling",
    label: "EV010483: Intermediate coupling"
  },
  {
    valueId: "EV010484",
    valueDesc: "Spiral hose",
    label: "EV010484: Spiral hose"
  },
  {
    valueId: "EV010485",
    valueDesc: "Zipper hose",
    label: "EV010485: Zipper hose"
  },
  {
    valueId: "EV010486",
    valueDesc: "2-fold telescopic guide rail",
    label: "EV010486: 2-fold telescopic guide rail"
  },
  {
    valueId: "EV010487",
    valueDesc: "3-fold telescopic guide rail",
    label: "EV010487: 3-fold telescopic guide rail"
  },
  {
    valueId: "EV010489",
    valueDesc: "Blind plate",
    label: "EV010489: Blind plate"
  },
  {
    valueId: "EV010490",
    valueDesc: "GX53",
    label: "EV010490: GX53"
  },
  {
    valueId: "EV010491",
    valueDesc: "With distance holder",
    label: "EV010491: With distance holder"
  },
  {
    valueId: "EV010492",
    valueDesc: "Earthing pliers",
    label: "EV010492: Earthing pliers"
  },
  {
    valueId: "EV010493",
    valueDesc: "Long shell",
    label: "EV010493: Long shell"
  },
  {
    valueId: "EV010494",
    valueDesc: "XS",
    label: "EV010494: XS"
  },
  {
    valueId: "EV010495",
    valueDesc: "Selectable",
    label: "EV010495: Selectable"
  },
  {
    valueId: "EV010496",
    valueDesc: "5x30 mm",
    label: "EV010496: 5x30 mm"
  },
  {
    valueId: "EV010497",
    valueDesc: "Swiss norm type 15",
    label: "EV010497: Swiss norm type 15"
  },
  {
    valueId: "EV010498",
    valueDesc: "Swiss norm type 23",
    label: "EV010498: Swiss norm type 23"
  },
  {
    valueId: "EV010499",
    valueDesc: "Swiss norm type 25",
    label: "EV010499: Swiss norm type 25"
  },
  {
    valueId: "EV010500",
    valueDesc: "Swiss norm type 30",
    label: "EV010500: Swiss norm type 30"
  },
  {
    valueId: "EV010501",
    valueDesc: "Swiss norm type 34",
    label: "EV010501: Swiss norm type 34"
  },
  {
    valueId: "EV010502",
    valueDesc: "Australian standard AS/NZS 3112 (type I)",
    label: "EV010502: Australian standard AS/NZS 3112 (type I)"
  },
  {
    valueId: "EV010503",
    valueDesc: "4x16A2p42V",
    label: "EV010503: 4x16A2p42V"
  },
  {
    valueId: "EV010504",
    valueDesc: "4x16A2p24V",
    label: "EV010504: 4x16A2p24V"
  },
  {
    valueId: "EV010505",
    valueDesc: "1x16A2p42V",
    label: "EV010505: 1x16A2p42V"
  },
  {
    valueId: "EV010506",
    valueDesc: "1x16A2p24V",
    label: "EV010506: 1x16A2p24V"
  },
  {
    valueId: "EV010507",
    valueDesc: "2x16A2p42V",
    label: "EV010507: 2x16A2p42V"
  },
  {
    valueId: "EV010508",
    valueDesc: "2x16A2p24V",
    label: "EV010508: 2x16A2p24V"
  },
  {
    valueId: "EV010509",
    valueDesc: "3x16A2p42V",
    label: "EV010509: 3x16A2p42V"
  },
  {
    valueId: "EV010510",
    valueDesc: "3x16A2p24V",
    label: "EV010510: 3x16A2p24V"
  },
  {
    valueId: "EV010511",
    valueDesc: "800x600",
    label: "EV010511: 800x600"
  },
  {
    valueId: "EV010514",
    valueDesc: "Chart paper",
    label: "EV010514: Chart paper"
  },
  {
    valueId: "EV010515",
    valueDesc: "Clamp cover",
    label: "EV010515: Clamp cover"
  },
  {
    valueId: "EV010516",
    valueDesc: "Measured value memory",
    label: "EV010516: Measured value memory"
  },
  {
    valueId: "EV010517",
    valueDesc: "Buffer battery",
    label: "EV010517: Buffer battery"
  },
  {
    valueId: "EV010518",
    valueDesc: "Reset key",
    label: "EV010518: Reset key"
  },
  {
    valueId: "EV010519",
    valueDesc: "10 modular spacing",
    label: "EV010519: 10 modular spacing"
  },
  {
    valueId: "EV010521",
    valueDesc: "Profile cylinder",
    label: "EV010521: Profile cylinder"
  },
  {
    valueId: "EV010522",
    valueDesc: "Knife cassette",
    label: "EV010522: Knife cassette"
  },
  {
    valueId: "EV010523",
    valueDesc: "Lifting cart",
    label: "EV010523: Lifting cart"
  },
  {
    valueId: "EV010524",
    valueDesc: "Vinylester resin",
    label: "EV010524: Vinylester resin"
  },
  {
    valueId: "EV010525",
    valueDesc: "Door handle",
    label: "EV010525: Door handle"
  },
  {
    valueId: "EV010528",
    valueDesc: "Basic tile",
    label: "EV010528: Basic tile"
  },
  {
    valueId: "EV010529",
    valueDesc: "Cable outlet tile",
    label: "EV010529: Cable outlet tile"
  },
  {
    valueId: "EV010530",
    valueDesc: "Earthing tile",
    label: "EV010530: Earthing tile"
  },
  {
    valueId: "EV010531",
    valueDesc: "Accessory support tile",
    label: "EV010531: Accessory support tile"
  },
  {
    valueId: "EV010532",
    valueDesc: "Built-in tile for cover set",
    label: "EV010532: Built-in tile for cover set"
  },
  {
    valueId: "EV010533",
    valueDesc: "B2",
    label: "EV010533: B2"
  },
  {
    valueId: "EV010534",
    valueDesc: "Stop end for plenum space",
    label: "EV010534: Stop end for plenum space"
  },
  {
    valueId: "EV010535",
    valueDesc: "Outer corner for plenum space",
    label: "EV010535: Outer corner for plenum space"
  },
  {
    valueId: "EV010536",
    valueDesc: "Ramp",
    label: "EV010536: Ramp"
  },
  {
    valueId: "EV010537",
    valueDesc: "Fixing bracket surface tank",
    label: "EV010537: Fixing bracket surface tank"
  },
  {
    valueId: "EV010538",
    valueDesc: "Partially submerged unit square",
    label: "EV010538: Partially submerged unit square"
  },
  {
    valueId: "EV010539",
    valueDesc: "Fully submerged unit square",
    label: "EV010539: Fully submerged unit square"
  },
  {
    valueId: "EV010540",
    valueDesc: "Fully submerged unit universal data",
    label: "EV010540: Fully submerged unit universal data"
  },
  {
    valueId: "EV010544",
    valueDesc: "Multimeter",
    label: "EV010544: Multimeter"
  },
  {
    valueId: "EV010545",
    valueDesc: "Installation tester",
    label: "EV010545: Installation tester"
  },
  {
    valueId: "EV010546",
    valueDesc: "Insulation tester",
    label: "EV010546: Insulation tester"
  },
  {
    valueId: "EV010547",
    valueDesc: "Clamp meter",
    label: "EV010547: Clamp meter"
  },
  {
    valueId: "EV010548",
    valueDesc: "Earth ground tester",
    label: "EV010548: Earth ground tester"
  },
  {
    valueId: "EV010549",
    valueDesc: "NEN 3140 tester",
    label: "EV010549: NEN 3140 tester"
  },
  {
    valueId: "EV010550",
    valueDesc: "High voltage tester",
    label: "EV010550: High voltage tester"
  },
  {
    valueId: "EV010551",
    valueDesc: "Machine tester",
    label: "EV010551: Machine tester"
  },
  {
    valueId: "EV010552",
    valueDesc: "Voltage tester",
    label: "EV010552: Voltage tester"
  },
  {
    valueId: "EV010553",
    valueDesc: "Power analyser",
    label: "EV010553: Power analyser"
  },
  {
    valueId: "EV010554",
    valueDesc: "Micro-ohmmeter",
    label: "EV010554: Micro-ohmmeter"
  },
  {
    valueId: "EV010555",
    valueDesc: "Calibrator",
    label: "EV010555: Calibrator"
  },
  {
    valueId: "EV010556",
    valueDesc: "Oscilloscope",
    label: "EV010556: Oscilloscope"
  },
  {
    valueId: "EV010558",
    valueDesc: "Signal Horn 1",
    label: "EV010558: Signal Horn 1"
  },
  {
    valueId: "EV010559",
    valueDesc: "Signal Horn 1 and 2",
    label: "EV010559: Signal Horn 1 and 2"
  },
  {
    valueId: "EV010560",
    valueDesc: "Signal Horn 1 or 2",
    label: "EV010560: Signal Horn 1 or 2"
  },
  {
    valueId: "EV010561",
    valueDesc: "34 a, b, d",
    label: "EV010561: 34 a, b, d"
  },
  {
    valueId: "EV010562",
    valueDesc: "35 a, b, c, e, g",
    label: "EV010562: 35 a, b, c, e, g"
  },
  {
    valueId: "EV010563",
    valueDesc: "24 V DC, 115 V AC, 230 V AC",
    label: "EV010563: 24 V DC, 115 V AC, 230 V AC"
  },
  {
    valueId: "EV010564",
    valueDesc: "According to IEC/EN 60332-3-24",
    label: "EV010564: According to IEC/EN 60332-3-24"
  },
  {
    valueId: "EV010565",
    valueDesc: "Bundles and layers",
    label: "EV010565: Bundles and layers"
  },
  {
    valueId: "EV010569",
    valueDesc: "Studs",
    label: "EV010569: Studs"
  },
  {
    valueId: "EV010572",
    valueDesc: "Welding chipping hammer",
    label: "EV010572: Welding chipping hammer"
  },
  {
    valueId: "EV010580",
    valueDesc: "Leakage detection",
    label: "EV010580: Leakage detection"
  },
  {
    valueId: "EV010584",
    valueDesc: "Junction box",
    label: "EV010584: Junction box"
  },
  {
    valueId: "EV010589",
    valueDesc: "30°",
    label: "EV010589: 30°"
  },
  {
    valueId: "EV010591",
    valueDesc: "35°",
    label: "EV010591: 35°"
  },
  {
    valueId: "EV010592",
    valueDesc: "CEE 125 A",
    label: "EV010592: CEE 125 A"
  },
  {
    valueId: "EV010593",
    valueDesc: "CEE 63 A",
    label: "EV010593: CEE 63 A"
  },
  {
    valueId: "EV010594",
    valueDesc: "M40",
    label: "EV010594: M40"
  },
  {
    valueId: "EV010595",
    valueDesc: "0, 1, 2",
    label: "EV010595: 0, 1, 2"
  },
  {
    valueId: "EV010597",
    valueDesc: "20, 21, 22",
    label: "EV010597: 20, 21, 22"
  },
  {
    valueId: "EV010598",
    valueDesc: "21, 22",
    label: "EV010598: 21, 22"
  },
  {
    valueId: "EV010601",
    valueDesc: "RJ12",
    label: "EV010601: RJ12"
  },
  {
    valueId: "EV010602",
    valueDesc: "RJ11",
    label: "EV010602: RJ11"
  },
  {
    valueId: "EV010604",
    valueDesc: "254 mm (10 inch)",
    label: "EV010604: 254 mm (10 inch)"
  },
  {
    valueId: "EV010605",
    valueDesc: "Candybar (standard)",
    label: "EV010605: Candybar (standard)"
  },
  {
    valueId: "EV010606",
    valueDesc: "Clamshell phone",
    label: "EV010606: Clamshell phone"
  },
  {
    valueId: "EV010607",
    valueDesc: "Slider phone",
    label: "EV010607: Slider phone"
  },
  {
    valueId: "EV010608",
    valueDesc: "Swivel phone",
    label: "EV010608: Swivel phone"
  },
  {
    valueId: "EV010609",
    valueDesc: "Ear-cushions",
    label: "EV010609: Ear-cushions"
  },
  {
    valueId: "EV010610",
    valueDesc: "Hookswitch",
    label: "EV010610: Hookswitch"
  },
  {
    valueId: "EV010611",
    valueDesc: "Adapter cable",
    label: "EV010611: Adapter cable"
  },
  {
    valueId: "EV010621",
    valueDesc: "Connection plug board",
    label: "EV010621: Connection plug board"
  },
  {
    valueId: "EV010622",
    valueDesc: "Circular connector",
    label: "EV010622: Circular connector"
  },
  {
    valueId: "EV010624",
    valueDesc: "Camera case",
    label: "EV010624: Camera case"
  },
  {
    valueId: "EV010627",
    valueDesc: "Fluid",
    label: "EV010627: Fluid"
  },
  {
    valueId: "EV010628",
    valueDesc: "FE 90",
    label: "EV010628: FE 90"
  },
  {
    valueId: "EV010630",
    valueDesc: "Energy saving gas-discharge lamp",
    label: "EV010630: Energy saving gas-discharge lamp"
  },
  {
    valueId: "EV010631",
    valueDesc: "Power quality",
    label: "EV010631: Power quality"
  },
  {
    valueId: "EV010632",
    valueDesc: "Energy saving motor",
    label: "EV010632: Energy saving motor"
  },
  {
    valueId: "EV010633",
    valueDesc: "Easy-zip",
    label: "EV010633: Easy-zip"
  },
  {
    valueId: "EV010634",
    valueDesc: "Push in",
    label: "EV010634: Push in"
  },
  {
    valueId: "EV010635",
    valueDesc: "X830R",
    label: "EV010635: X830R"
  },
  {
    valueId: "EV010636",
    valueDesc: "X528",
    label: "EV010636: X528"
  },
  {
    valueId: "EV010637",
    valueDesc: "PGZ12",
    label: "EV010637: PGZ12"
  },
  {
    valueId: "EV010638",
    valueDesc: "PGJ5",
    label: "EV010638: PGJ5"
  },
  {
    valueId: "EV010639",
    valueDesc: "h5",
    label: "EV010639: h5"
  },
  {
    valueId: "EV010641",
    valueDesc: "MG 5.7s/9",
    label: "EV010641: MG 5.7s/9"
  },
  {
    valueId: "EV010642",
    valueDesc: "2G8-1",
    label: "EV010642: 2G8-1"
  },
  {
    valueId: "EV010643",
    valueDesc: "GR8q-2",
    label: "EV010643: GR8q-2"
  },
  {
    valueId: "EV010644",
    valueDesc: "AB9s",
    label: "EV010644: AB9s"
  },
  {
    valueId: "EV010646",
    valueDesc: "Fluorescent lamp D=26/38 mm",
    label: "EV010646: Fluorescent lamp D=26/38 mm"
  },
  {
    valueId: "EV010647",
    valueDesc: "PG11-connector",
    label: "EV010647: PG11-connector"
  },
  {
    valueId: "EV010648",
    valueDesc: "HR03",
    label: "EV010648: HR03"
  },
  {
    valueId: "EV010649",
    valueDesc: "HR14",
    label: "EV010649: HR14"
  },
  {
    valueId: "EV010650",
    valueDesc: "HR20",
    label: "EV010650: HR20"
  },
  {
    valueId: "EV010651",
    valueDesc: "HR22",
    label: "EV010651: HR22"
  },
  {
    valueId: "EV010652",
    valueDesc: "HR6",
    label: "EV010652: HR6"
  },
  {
    valueId: "EV010653",
    valueDesc: "KR03",
    label: "EV010653: KR03"
  },
  {
    valueId: "EV010654",
    valueDesc: "KR1",
    label: "EV010654: KR1"
  },
  {
    valueId: "EV010655",
    valueDesc: "KR14",
    label: "EV010655: KR14"
  },
  {
    valueId: "EV010656",
    valueDesc: "KR20",
    label: "EV010656: KR20"
  },
  {
    valueId: "EV010657",
    valueDesc: "KR21",
    label: "EV010657: KR21"
  },
  {
    valueId: "EV010658",
    valueDesc: "KR6",
    label: "EV010658: KR6"
  },
  {
    valueId: "EV010659",
    valueDesc: "R 20",
    label: "EV010659: R 20"
  },
  {
    valueId: "EV010660",
    valueDesc: "R 3",
    label: "EV010660: R 3"
  },
  {
    valueId: "EV010661",
    valueDesc: "R 6",
    label: "EV010661: R 6"
  },
  {
    valueId: "EV010662",
    valueDesc: "TT83",
    label: "EV010662: TT83"
  },
  {
    valueId: "EV010663",
    valueDesc: "TT89",
    label: "EV010663: TT89"
  },
  {
    valueId: "EV010664",
    valueDesc: "2x TT83",
    label: "EV010664: 2x TT83"
  },
  {
    valueId: "EV010665",
    valueDesc: "TT87",
    label: "EV010665: TT87"
  },
  {
    valueId: "EV010666",
    valueDesc: "0.001 mV",
    label: "EV010666: 0.001 mV"
  },
  {
    valueId: "EV010667",
    valueDesc: "Polyamide 11",
    label: "EV010667: Polyamide 11"
  },
  {
    valueId: "EV010672",
    valueDesc: "Sucupira",
    label: "EV010672: Sucupira"
  },
  {
    valueId: "EV010673",
    valueDesc: "Illumination cable set",
    label: "EV010673: Illumination cable set"
  },
  {
    valueId: "EV010674",
    valueDesc: "Curtain light",
    label: "EV010674: Curtain light"
  },
  {
    valueId: "EV010675",
    valueDesc: "Chainlight",
    label: "EV010675: Chainlight"
  },
  {
    valueId: "EV010676",
    valueDesc: "Candlestick",
    label: "EV010676: Candlestick"
  },
  {
    valueId: "EV010677",
    valueDesc: "Cable connection strip",
    label: "EV010677: Cable connection strip"
  },
  {
    valueId: "EV010679",
    valueDesc: "Rubber profile",
    label: "EV010679: Rubber profile"
  },
  {
    valueId: "EV010681",
    valueDesc: "B+",
    label: "EV010681: B+"
  },
  {
    valueId: "EV010682",
    valueDesc: "Network technology",
    label: "EV010682: Network technology"
  },
  {
    valueId: "EV010683",
    valueDesc: "Local",
    label: "EV010683: Local"
  },
  {
    valueId: "EV010685",
    valueDesc: "C20",
    label: "EV010685: C20"
  },
  {
    valueId: "EV010686",
    valueDesc: "C14",
    label: "EV010686: C14"
  },
  {
    valueId: "EV010688",
    valueDesc: "Tunnel terminal",
    label: "EV010688: Tunnel terminal"
  },
  {
    valueId: "EV010689",
    valueDesc: "Backside screw connection",
    label: "EV010689: Backside screw connection"
  },
  {
    valueId: "EV010690",
    valueDesc: "Repair switch",
    label: "EV010690: Repair switch"
  },
  {
    valueId: "EV010692",
    valueDesc: "0 + 1",
    label: "EV010692: 0 + 1"
  },
  {
    valueId: "EV010693",
    valueDesc: "1-channel or 1- and 2-channel",
    label: "EV010693: 1-channel or 1- and 2-channel"
  },
  {
    valueId: "EV010694",
    valueDesc: "Attachable current transformer",
    label: "EV010694: Attachable current transformer"
  },
  {
    valueId: "EV010695",
    valueDesc: "IP05",
    label: "EV010695: IP05"
  },
  {
    valueId: "EV010696",
    valueDesc: "Dry reed contact",
    label: "EV010696: Dry reed contact"
  },
  {
    valueId: "EV010697",
    valueDesc: "Neoprene/leather",
    label: "EV010697: Neoprene/leather"
  },
  {
    valueId: "EV010698",
    valueDesc: "2 make contacts (NO), 2 breaker contacts (NC)",
    label: "EV010698: 2 make contacts (NO), 2 breaker contacts (NC)"
  },
  {
    valueId: "EV010699",
    valueDesc: "Swiss norm type 13a",
    label: "EV010699: Swiss norm type 13a"
  },
  {
    valueId: "EV010700",
    valueDesc: "Swiss norm type 15a",
    label: "EV010700: Swiss norm type 15a"
  },
  {
    valueId: "EV010701",
    valueDesc: "Swiss norm type 15/25",
    label: "EV010701: Swiss norm type 15/25"
  },
  {
    valueId: "EV010702",
    valueDesc: "Swiss norm type 13/23",
    label: "EV010702: Swiss norm type 13/23"
  },
  {
    valueId: "EV010704",
    valueDesc: "Waltz",
    label: "EV010704: Waltz"
  },
  {
    valueId: "EV010705",
    valueDesc: "Spiral cutter",
    label: "EV010705: Spiral cutter"
  },
  {
    valueId: "EV010706",
    valueDesc: "Radio control/infrared",
    label: "EV010706: Radio control/infrared"
  },
  {
    valueId: "EV010707",
    valueDesc: "Skin and crimp",
    label: "EV010707: Skin and crimp"
  },
  {
    valueId: "EV010708",
    valueDesc: "Cut to length",
    label: "EV010708: Cut to length"
  },
  {
    valueId: "EV010709",
    valueDesc: "Skin",
    label: "EV010709: Skin"
  },
  {
    valueId: "EV010711",
    valueDesc: "PCB terminal",
    label: "EV010711: PCB terminal"
  },
  {
    valueId: "EV010714",
    valueDesc: "Interior and outdoor installations",
    label: "EV010714: Interior and outdoor installations"
  },
  {
    valueId: "EV010715",
    valueDesc: "Interior installations",
    label: "EV010715: Interior installations"
  },
  {
    valueId: "EV010716",
    valueDesc: "Switchgear and power cable for overhead lines",
    label: "EV010716: Switchgear and power cable for overhead lines"
  },
  {
    valueId: "EV010717",
    valueDesc: "Power cable for overhead lines",
    label: "EV010717: Power cable for overhead lines"
  },
  {
    valueId: "EV010718",
    valueDesc: "LRM",
    label: "EV010718: LRM"
  },
  {
    valueId: "EV010719",
    valueDesc: "Voltage display device",
    label: "EV010719: Voltage display device"
  },
  {
    valueId: "EV010720",
    valueDesc: "Testing device 230 V for voltage indication device",
    label: "EV010720: Testing device 230 V for voltage indication device"
  },
  {
    valueId: "EV010721",
    valueDesc: "Measurement adapters HR - LRM",
    label: "EV010721: Measurement adapters HR - LRM"
  },
  {
    valueId: "EV010722",
    valueDesc: "Interface test device",
    label: "EV010722: Interface test device"
  },
  {
    valueId: "EV010724",
    valueDesc: "Spindle with cross pin",
    label: "EV010724: Spindle with cross pin"
  },
  {
    valueId: "EV010725",
    valueDesc: "Spindle with hexagon",
    label: "EV010725: Spindle with hexagon"
  },
  {
    valueId: "EV010726",
    valueDesc: "Insulating rod",
    label: "EV010726: Insulating rod"
  },
  {
    valueId: "EV010727",
    valueDesc: "Shift rod",
    label: "EV010727: Shift rod"
  },
  {
    valueId: "EV010728",
    valueDesc: "Fuse tongs",
    label: "EV010728: Fuse tongs"
  },
  {
    valueId: "EV010729",
    valueDesc: "Saving rod",
    label: "EV010729: Saving rod"
  },
  {
    valueId: "EV010730",
    valueDesc: "Hard PVC",
    label: "EV010730: Hard PVC"
  },
  {
    valueId: "EV010731",
    valueDesc: "A4",
    label: "EV010731: A4"
  },
  {
    valueId: "EV010732",
    valueDesc: "A1",
    label: "EV010732: A1"
  },
  {
    valueId: "EV010733",
    valueDesc: "A2",
    label: "EV010733: A2"
  },
  {
    valueId: "EV010734",
    valueDesc: "A3",
    label: "EV010734: A3"
  },
  {
    valueId: "EV010735",
    valueDesc: "Spherical head bonnet 20 mm",
    label: "EV010735: Spherical head bonnet 20 mm"
  },
  {
    valueId: "EV010736",
    valueDesc: "Spherical head bonnet 25 mm",
    label: "EV010736: Spherical head bonnet 25 mm"
  },
  {
    valueId: "EV010737",
    valueDesc: "Universal clamp 20/25 mm",
    label: "EV010737: Universal clamp 20/25 mm"
  },
  {
    valueId: "EV010738",
    valueDesc: "Universal clamp 25/30 mm",
    label: "EV010738: Universal clamp 25/30 mm"
  },
  {
    valueId: "EV010739",
    valueDesc: "Multimedia end box",
    label: "EV010739: Multimedia end box"
  },
  {
    valueId: "EV010740",
    valueDesc: "Petrol",
    label: "EV010740: Petrol"
  },
  {
    valueId: "EV010741",
    valueDesc: "Mixture",
    label: "EV010741: Mixture"
  },
  {
    valueId: "EV010742",
    valueDesc: "For circular conductor",
    label: "EV010742: For circular conductor"
  },
  {
    valueId: "EV010743",
    valueDesc: "With thread bolt",
    label: "EV010743: With thread bolt"
  },
  {
    valueId: "EV010744",
    valueDesc: "With connector lug",
    label: "EV010744: With connector lug"
  },
  {
    valueId: "EV010745",
    valueDesc: "Weldable",
    label: "EV010745: Weldable"
  },
  {
    valueId: "EV010746",
    valueDesc: "Fixed ball point 20 mm",
    label: "EV010746: Fixed ball point 20 mm"
  },
  {
    valueId: "EV010747",
    valueDesc: "Fixed ball point 25 mm",
    label: "EV010747: Fixed ball point 25 mm"
  },
  {
    valueId: "EV010748",
    valueDesc: "Radial groove 16 mm",
    label: "EV010748: Radial groove 16 mm"
  },
  {
    valueId: "EV010749",
    valueDesc: "HR",
    label: "EV010749: HR"
  },
  {
    valueId: "EV010750",
    valueDesc: "Fixed ball point 30 mm",
    label: "EV010750: Fixed ball point 30 mm"
  },
  {
    valueId: "EV010751",
    valueDesc: "T-connection",
    label: "EV010751: T-connection"
  },
  {
    valueId: "EV010754",
    valueDesc: "Dry cleaning set",
    label: "EV010754: Dry cleaning set"
  },
  {
    valueId: "EV010755",
    valueDesc: "Wet cleaning set",
    label: "EV010755: Wet cleaning set"
  },
  {
    valueId: "EV010756",
    valueDesc: "Dry and wet cleaning set",
    label: "EV010756: Dry and wet cleaning set"
  },
  {
    valueId: "EV010757",
    valueDesc: "Up to 36 kV",
    label: "EV010757: Up to 36 kV"
  },
  {
    valueId: "EV010758",
    valueDesc: "Up to 1000 V",
    label: "EV010758: Up to 1000 V"
  },
  {
    valueId: "EV010759",
    valueDesc: "Crystal clear",
    label: "EV010759: Crystal clear"
  },
  {
    valueId: "EV010760",
    valueDesc: "Plastic knife",
    label: "EV010760: Plastic knife"
  },
  {
    valueId: "EV010761",
    valueDesc: "Sickle knife",
    label: "EV010761: Sickle knife"
  },
  {
    valueId: "EV010762",
    valueDesc: "Spindle knife",
    label: "EV010762: Spindle knife"
  },
  {
    valueId: "EV010763",
    valueDesc: "3x2.5 mm²",
    label: "EV010763: 3x2.5 mm²"
  },
  {
    valueId: "EV010764",
    valueDesc: "Without clamp",
    label: "EV010764: Without clamp"
  },
  {
    valueId: "EV010769",
    valueDesc: "OS2",
    label: "EV010769: OS2"
  },
  {
    valueId: "EV010771",
    valueDesc: "Diesel",
    label: "EV010771: Diesel"
  },
  {
    valueId: "EV010774",
    valueDesc: "2GX53",
    label: "EV010774: 2GX53"
  },
  {
    valueId: "EV010775",
    valueDesc: "Vertical cutting blades",
    label: "EV010775: Vertical cutting blades"
  },
  {
    valueId: "EV010776",
    valueDesc: "Aerator springs",
    label: "EV010776: Aerator springs"
  },
  {
    valueId: "EV010777",
    valueDesc: "Combination device",
    label: "EV010777: Combination device"
  },
  {
    valueId: "EV010778",
    valueDesc: "Covering hood",
    label: "EV010778: Covering hood"
  },
  {
    valueId: "EV010779",
    valueDesc: "Collection container",
    label: "EV010779: Collection container"
  },
  {
    valueId: "EV010780",
    valueDesc: "Spare blade",
    label: "EV010780: Spare blade"
  },
  {
    valueId: "EV010782",
    valueDesc: "Telescopic handle",
    label: "EV010782: Telescopic handle"
  },
  {
    valueId: "EV010783",
    valueDesc: "Shearing blade",
    label: "EV010783: Shearing blade"
  },
  {
    valueId: "EV010784",
    valueDesc: "Shrub shear blade",
    label: "EV010784: Shrub shear blade"
  },
  {
    valueId: "EV010785",
    valueDesc: "KM",
    label: "EV010785: KM"
  },
  {
    valueId: "EV010786",
    valueDesc: "Wheel set",
    label: "EV010786: Wheel set"
  },
  {
    valueId: "EV010787",
    valueDesc: "Spool",
    label: "EV010787: Spool"
  },
  {
    valueId: "EV010788",
    valueDesc: "Thread for trimmer",
    label: "EV010788: Thread for trimmer"
  },
  {
    valueId: "EV010789",
    valueDesc: "Grass catcher box",
    label: "EV010789: Grass catcher box"
  },
  {
    valueId: "EV010790",
    valueDesc: "Under blade",
    label: "EV010790: Under blade"
  },
  {
    valueId: "EV010791",
    valueDesc: "Spare claw",
    label: "EV010791: Spare claw"
  },
  {
    valueId: "EV010792",
    valueDesc: "Claw carrier",
    label: "EV010792: Claw carrier"
  },
  {
    valueId: "EV010793",
    valueDesc: "Spring steel teeth",
    label: "EV010793: Spring steel teeth"
  },
  {
    valueId: "EV010794",
    valueDesc: "Blade guard",
    label: "EV010794: Blade guard"
  },
  {
    valueId: "EV010795",
    valueDesc: "Care quiver",
    label: "EV010795: Care quiver"
  },
  {
    valueId: "EV010796",
    valueDesc: "Care spray",
    label: "EV010796: Care spray"
  },
  {
    valueId: "EV010802",
    valueDesc: "PTO shaft",
    label: "EV010802: PTO shaft"
  },
  {
    valueId: "EV010804",
    valueDesc: "Small spline shaft",
    label: "EV010804: Small spline shaft"
  },
  {
    valueId: "EV010805",
    valueDesc: "Hexagon 10 mm",
    label: "EV010805: Hexagon 10 mm"
  },
  {
    valueId: "EV010806",
    valueDesc: "Hexagon 13 mm",
    label: "EV010806: Hexagon 13 mm"
  },
  {
    valueId: "EV010807",
    valueDesc: "Hexagon 1/2 inch",
    label: "EV010807: Hexagon 1/2 inch"
  },
  {
    valueId: "EV010808",
    valueDesc: "Hexagon 1/4 inch",
    label: "EV010808: Hexagon 1/4 inch"
  },
  {
    valueId: "EV010809",
    valueDesc: "Hexagon 3/4 inch",
    label: "EV010809: Hexagon 3/4 inch"
  },
  {
    valueId: "EV010810",
    valueDesc: "Hexagon 3/8 inch",
    label: "EV010810: Hexagon 3/8 inch"
  },
  {
    valueId: "EV010811",
    valueDesc: "M18",
    label: "EV010811: M18"
  },
  {
    valueId: "EV010812",
    valueDesc: "Galvanic zinc coated",
    label: "EV010812: Galvanic zinc coated"
  },
  {
    valueId: "EV010813",
    valueDesc: "Galvanic zinc-iron coated",
    label: "EV010813: Galvanic zinc-iron coated"
  },
  {
    valueId: "EV010814",
    valueDesc: "Galvanic zinc-nickel coated",
    label: "EV010814: Galvanic zinc-nickel coated"
  },
  {
    valueId: "EV010816",
    valueDesc: "Bronzed",
    label: "EV010816: Bronzed"
  },
  {
    valueId: "EV010817",
    valueDesc: "Duplex coated",
    label: "EV010817: Duplex coated"
  },
  {
    valueId: "EV010818",
    valueDesc: "A+++",
    label: "EV010818: A+++"
  },
  {
    valueId: "EV010819",
    valueDesc: "Arched",
    label: "EV010819: Arched"
  },
  {
    valueId: "EV010820",
    valueDesc: "Latex",
    label: "EV010820: Latex"
  },
  {
    valueId: "EV010822",
    valueDesc: "Flat chisel",
    label: "EV010822: Flat chisel"
  },
  {
    valueId: "EV010824",
    valueDesc: "Spade chisel",
    label: "EV010824: Spade chisel"
  },
  {
    valueId: "EV010825",
    valueDesc: "Pointed chisel",
    label: "EV010825: Pointed chisel"
  },
  {
    valueId: "EV010827",
    valueDesc: "Key-type",
    label: "EV010827: Key-type"
  },
  {
    valueId: "EV010834",
    valueDesc: "Energy saving",
    label: "EV010834: Energy saving"
  },
  {
    valueId: "EV010846",
    valueDesc: "Slide (before mounting)",
    label: "EV010846: Slide (before mounting)"
  },
  {
    valueId: "EV010848",
    valueDesc: "Mounting cover data connector",
    label: "EV010848: Mounting cover data connector"
  },
  {
    valueId: "EV010849",
    valueDesc: "Junction box insert for subfloor installation",
    label: "EV010849: Junction box insert for subfloor installation"
  },
  {
    valueId: "EV010858",
    valueDesc: "Clamp set",
    label: "EV010858: Clamp set"
  },
  {
    valueId: "EV010863",
    valueDesc: "6A (TIA)",
    label: "EV010863: 6A (TIA)"
  },
  {
    valueId: "EV010865",
    valueDesc: "7A (TIA)",
    label: "EV010865: 7A (TIA)"
  },
  {
    valueId: "EV010866",
    valueDesc: "OM4",
    label: "EV010866: OM4"
  },
  {
    valueId: "EV010868",
    valueDesc: "Distance band",
    label: "EV010868: Distance band"
  },
  {
    valueId: "EV010869",
    valueDesc: "Rough fibre strip",
    label: "EV010869: Rough fibre strip"
  },
  {
    valueId: "EV010870",
    valueDesc: "Pressure bandage",
    label: "EV010870: Pressure bandage"
  },
  {
    valueId: "EV010871",
    valueDesc: "Inlet valve",
    label: "EV010871: Inlet valve"
  },
  {
    valueId: "EV010872",
    valueDesc: "Injection valve",
    label: "EV010872: Injection valve"
  },
  {
    valueId: "EV010873",
    valueDesc: "Distance triangle",
    label: "EV010873: Distance triangle"
  },
  {
    valueId: "EV010874",
    valueDesc: "Cable centering",
    label: "EV010874: Cable centering"
  },
  {
    valueId: "EV010876",
    valueDesc: "Plug adapter",
    label: "EV010876: Plug adapter"
  },
  {
    valueId: "EV010877",
    valueDesc: "Phase converter adapter",
    label: "EV010877: Phase converter adapter"
  },
  {
    valueId: "EV010878",
    valueDesc: "Contact parts",
    label: "EV010878: Contact parts"
  },
  {
    valueId: "EV010879",
    valueDesc: "Intermediate flange",
    label: "EV010879: Intermediate flange"
  },
  {
    valueId: "EV010880",
    valueDesc: "Suspension",
    label: "EV010880: Suspension"
  },
  {
    valueId: "EV010881",
    valueDesc: "CEE all amperages",
    label: "EV010881: CEE all amperages"
  },
  {
    valueId: "EV010882",
    valueDesc: "Battery terminal",
    label: "EV010882: Battery terminal"
  },
  {
    valueId: "EV010883",
    valueDesc: "Battery clamp",
    label: "EV010883: Battery clamp"
  },
  {
    valueId: "EV010885",
    valueDesc: "Actuator",
    label: "EV010885: Actuator"
  },
  {
    valueId: "EV010886",
    valueDesc: "S-Video",
    label: "EV010886: S-Video"
  },
  {
    valueId: "EV010887",
    valueDesc: "USB/jack plug",
    label: "EV010887: USB/jack plug"
  },
  {
    valueId: "EV010888",
    valueDesc: "HDMI",
    label: "EV010888: HDMI"
  },
  {
    valueId: "EV010889",
    valueDesc: "RCA/S-Video",
    label: "EV010889: RCA/S-Video"
  },
  {
    valueId: "EV010891",
    valueDesc: "Linoleum",
    label: "EV010891: Linoleum"
  },
  {
    valueId: "EV010893",
    valueDesc: "Pause button",
    label: "EV010893: Pause button"
  },
  {
    valueId: "EV010894",
    valueDesc: "Bottom and side",
    label: "EV010894: Bottom and side"
  },
  {
    valueId: "EV010895",
    valueDesc: "Locator switch",
    label: "EV010895: Locator switch"
  },
  {
    valueId: "EV010896",
    valueDesc: "GU6.5",
    label: "EV010896: GU6.5"
  },
  {
    valueId: "EV010897",
    valueDesc: "GY22",
    label: "EV010897: GY22"
  },
  {
    valueId: "EV010898",
    valueDesc: "K12s-36",
    label: "EV010898: K12s-36"
  },
  {
    valueId: "EV010899",
    valueDesc: "PGZ18",
    label: "EV010899: PGZ18"
  },
  {
    valueId: "EV010900",
    valueDesc: "(P)SFC",
    label: "EV010900: (P)SFC"
  },
  {
    valueId: "EV010901",
    valueDesc: "GZ5.3",
    label: "EV010901: GZ5.3"
  },
  {
    valueId: "EV010902",
    valueDesc: "GR14q-1",
    label: "EV010902: GR14q-1"
  },
  {
    valueId: "EV010903",
    valueDesc: "GRY10q-3",
    label: "EV010903: GRY10q-3"
  },
  {
    valueId: "EV010904",
    valueDesc: "GY38",
    label: "EV010904: GY38"
  },
  {
    valueId: "EV010905",
    valueDesc: "GZX9.5",
    label: "EV010905: GZX9.5"
  },
  {
    valueId: "EV010906",
    valueDesc: "GZY9.5",
    label: "EV010906: GZY9.5"
  },
  {
    valueId: "EV010910",
    valueDesc: "Cool",
    label: "EV010910: Cool"
  },
  {
    valueId: "EV010911",
    valueDesc: "GX8.5",
    label: "EV010911: GX8.5"
  },
  {
    valueId: "EV010913",
    valueDesc: "IIA",
    label: "EV010913: IIA"
  },
  {
    valueId: "EV010914",
    valueDesc: "IIB",
    label: "EV010914: IIB"
  },
  {
    valueId: "EV010916",
    valueDesc: "Roller cam straight",
    label: "EV010916: Roller cam straight"
  },
  {
    valueId: "EV010917",
    valueDesc: "Roller cam crossed",
    label: "EV010917: Roller cam crossed"
  },
  {
    valueId: "EV010918",
    valueDesc: "Outdoor installations",
    label: "EV010918: Outdoor installations"
  },
  {
    valueId: "EV010919",
    valueDesc: "Current",
    label: "EV010919: Current"
  },
  {
    valueId: "EV010920",
    valueDesc: "Heat",
    label: "EV010920: Heat"
  },
  {
    valueId: "EV010921",
    valueDesc: "GSM",
    label: "EV010921: GSM"
  },
  {
    valueId: "EV010922",
    valueDesc: "Powerline",
    label: "EV010922: Powerline"
  },
  {
    valueId: "EV010923",
    valueDesc: "Wireless M-Bus (wM-Bus)",
    label: "EV010923: Wireless M-Bus (wM-Bus)"
  },
  {
    valueId: "EV010924",
    valueDesc: "KNX radio control",
    label: "EV010924: KNX radio control"
  },
  {
    valueId: "EV010925",
    valueDesc: "Series switch + Swiss norm type 13",
    label: "EV010925: Series switch + Swiss norm type 13"
  },
  {
    valueId: "EV010926",
    valueDesc: "Series switch + Swiss norm type 23",
    label: "EV010926: Series switch + Swiss norm type 23"
  },
  {
    valueId: "EV010927",
    valueDesc: "Two-way switch + Swiss norm type 13",
    label: "EV010927: Two-way switch + Swiss norm type 13"
  },
  {
    valueId: "EV010928",
    valueDesc: "Two-way switch + Swiss norm type 23",
    label: "EV010928: Two-way switch + Swiss norm type 23"
  },
  {
    valueId: "EV010929",
    valueDesc: "Water connection set",
    label: "EV010929: Water connection set"
  },
  {
    valueId: "EV010930",
    valueDesc: "Sensor adapter",
    label: "EV010930: Sensor adapter"
  },
  {
    valueId: "EV010931",
    valueDesc: "Sensor interface",
    label: "EV010931: Sensor interface"
  },
  {
    valueId: "EV010932",
    valueDesc: "Suction hose",
    label: "EV010932: Suction hose"
  },
  {
    valueId: "EV010933",
    valueDesc: "Heated mangle",
    label: "EV010933: Heated mangle"
  },
  {
    valueId: "EV010934",
    valueDesc: "Cold mangle",
    label: "EV010934: Cold mangle"
  },
  {
    valueId: "EV010935",
    valueDesc: "Door release (green)",
    label: "EV010935: Door release (green)"
  },
  {
    valueId: "EV010936",
    valueDesc: "Extinction (yellow)",
    label: "EV010936: Extinction (yellow)"
  },
  {
    valueId: "EV010937",
    valueDesc: "Electrodes for voltage detector",
    label: "EV010937: Electrodes for voltage detector"
  },
  {
    valueId: "EV010938",
    valueDesc: "Holding device",
    label: "EV010938: Holding device"
  },
  {
    valueId: "EV010939",
    valueDesc: "Handle stick/handle stick extension",
    label: "EV010939: Handle stick/handle stick extension"
  },
  {
    valueId: "EV010940",
    valueDesc: "Test rods for voltage detector",
    label: "EV010940: Test rods for voltage detector"
  },
  {
    valueId: "EV010941",
    valueDesc: "Test probes for voltage detector",
    label: "EV010941: Test probes for voltage detector"
  },
  {
    valueId: "EV010942",
    valueDesc: "Direct measurement",
    label: "EV010942: Direct measurement"
  },
  {
    valueId: "EV010943",
    valueDesc: "Measurement transformer",
    label: "EV010943: Measurement transformer"
  },
  {
    valueId: "EV010944",
    valueDesc: "Effective power",
    label: "EV010944: Effective power"
  },
  {
    valueId: "EV010945",
    valueDesc: "Blind power",
    label: "EV010945: Blind power"
  },
  {
    valueId: "EV010946",
    valueDesc: "Effective power and blind power",
    label: "EV010946: Effective power and blind power"
  },
  {
    valueId: "EV010947",
    valueDesc: "Multi-tariff",
    label: "EV010947: Multi-tariff"
  },
  {
    valueId: "EV010948",
    valueDesc: "Internal",
    label: "EV010948: Internal"
  },
  {
    valueId: "EV010949",
    valueDesc: "Internal/external",
    label: "EV010949: Internal/external"
  },
  {
    valueId: "EV010950",
    valueDesc: "Measuring Instruments Directive",
    label: "EV010950: Measuring Instruments Directive"
  },
  {
    valueId: "EV010951",
    valueDesc: "Domestic",
    label: "EV010951: Domestic"
  },
  {
    valueId: "EV010952",
    valueDesc: "S0",
    label: "EV010952: S0"
  },
  {
    valueId: "EV010953",
    valueDesc: "D0",
    label: "EV010953: D0"
  },
  {
    valueId: "EV010954",
    valueDesc: "EN 62056-21",
    label: "EV010954: EN 62056-21"
  },
  {
    valueId: "EV010955",
    valueDesc: "Blind terminal",
    label: "EV010955: Blind terminal"
  },
  {
    valueId: "EV010956",
    valueDesc: "Insulating sleeve",
    label: "EV010956: Insulating sleeve"
  },
  {
    valueId: "EV010957",
    valueDesc: "Insulating bridge",
    label: "EV010957: Insulating bridge"
  },
  {
    valueId: "EV010958",
    valueDesc: "Short circuit plug",
    label: "EV010958: Short circuit plug"
  },
  {
    valueId: "EV010959",
    valueDesc: "Test plug",
    label: "EV010959: Test plug"
  },
  {
    valueId: "EV010960",
    valueDesc: "Test plug socket",
    label: "EV010960: Test plug socket"
  },
  {
    valueId: "EV010961",
    valueDesc: "Reducing plug",
    label: "EV010961: Reducing plug"
  },
  {
    valueId: "EV010962",
    valueDesc: "Washer",
    label: "EV010962: Washer"
  },
  {
    valueId: "EV010964",
    valueDesc: "Three conductor",
    label: "EV010964: Three conductor"
  },
  {
    valueId: "EV010965",
    valueDesc: "Four conductor",
    label: "EV010965: Four conductor"
  },
  {
    valueId: "EV010966",
    valueDesc: "Purchase/supply",
    label: "EV010966: Purchase/supply"
  },
  {
    valueId: "EV010967",
    valueDesc: "Plug-in technique",
    label: "EV010967: Plug-in technique"
  },
  {
    valueId: "EV010968",
    valueDesc: "8.5 mm (1/3 inch)",
    label: "EV010968: 8.5 mm (1/3 inch)"
  },
  {
    valueId: "EV010969",
    valueDesc: "12.7 mm (1/2 inch)",
    label: "EV010969: 12.7 mm (1/2 inch)"
  },
  {
    valueId: "EV010970",
    valueDesc: "19.1 mm (3/4 inch)",
    label: "EV010970: 19.1 mm (3/4 inch)"
  },
  {
    valueId: "EV010976",
    valueDesc: "UMTS",
    label: "EV010976: UMTS"
  },
  {
    valueId: "EV010977",
    valueDesc: "16:10",
    label: "EV010977: 16:10"
  },
  {
    valueId: "EV010978",
    valueDesc: "Level a",
    label: "EV010978: Level a"
  },
  {
    valueId: "EV010979",
    valueDesc: "Level b",
    label: "EV010979: Level b"
  },
  {
    valueId: "EV010980",
    valueDesc: "Level c",
    label: "EV010980: Level c"
  },
  {
    valueId: "EV010981",
    valueDesc: "Level d",
    label: "EV010981: Level d"
  },
  {
    valueId: "EV010982",
    valueDesc: "Level e",
    label: "EV010982: Level e"
  },
  {
    valueId: "EV010983",
    valueDesc: "Decor",
    label: "EV010983: Decor"
  },
  {
    valueId: "EV010984",
    valueDesc: "GU9",
    label: "EV010984: GU9"
  },
  {
    valueId: "EV010985",
    valueDesc: "GY29.3",
    label: "EV010985: GY29.3"
  },
  {
    valueId: "EV010986",
    valueDesc: "Candle/tea light",
    label: "EV010986: Candle/tea light"
  },
  {
    valueId: "EV010987",
    valueDesc: "Bulb luminaire",
    label: "EV010987: Bulb luminaire"
  },
  {
    valueId: "EV010988",
    valueDesc: "Lampion",
    label: "EV010988: Lampion"
  },
  {
    valueId: "EV010989",
    valueDesc: "Guide arm",
    label: "EV010989: Guide arm"
  },
  {
    valueId: "EV010990",
    valueDesc: "Emergency release",
    label: "EV010990: Emergency release"
  },
  {
    valueId: "EV010991",
    valueDesc: "Slotted iron strip",
    label: "EV010991: Slotted iron strip"
  },
  {
    valueId: "EV010992",
    valueDesc: "Claw bearing",
    label: "EV010992: Claw bearing"
  },
  {
    valueId: "EV010993",
    valueDesc: "Converter module",
    label: "EV010993: Converter module"
  },
  {
    valueId: "EV010994",
    valueDesc: "Redundancy module",
    label: "EV010994: Redundancy module"
  },
  {
    valueId: "EV010995",
    valueDesc: "Network interface",
    label: "EV010995: Network interface"
  },
  {
    valueId: "EV010996",
    valueDesc: "SNMP/web adapter",
    label: "EV010996: SNMP/web adapter"
  },
  {
    valueId: "EV010997",
    valueDesc: "Cable set",
    label: "EV010997: Cable set"
  },
  {
    valueId: "EV010999",
    valueDesc: "For plug",
    label: "EV010999: For plug"
  },
  {
    valueId: "EV011000",
    valueDesc: "Combustion engine",
    label: "EV011000: Combustion engine"
  },
  {
    valueId: "EV011001",
    valueDesc: "Pulsed",
    label: "EV011001: Pulsed"
  },
  {
    valueId: "EV011002",
    valueDesc: "DVI",
    label: "EV011002: DVI"
  },
  {
    valueId: "EV011003",
    valueDesc: "Windows 7",
    label: "EV011003: Windows 7"
  },
  {
    valueId: "EV011004",
    valueDesc: "DMX",
    label: "EV011004: DMX"
  },
  {
    valueId: "EV011005",
    valueDesc: "GPRS",
    label: "EV011005: GPRS"
  },
  {
    valueId: "EV011007",
    valueDesc: "RF",
    label: "EV011007: RF"
  },
  {
    valueId: "EV011008",
    valueDesc: "LineSwitch",
    label: "EV011008: LineSwitch"
  },
  {
    valueId: "EV011009",
    valueDesc: "Sine Wave Reduction",
    label: "EV011009: Sine Wave Reduction"
  },
  {
    valueId: "EV011012",
    valueDesc: "Zigbee",
    label: "EV011012: Zigbee"
  },
  {
    valueId: "EV011013",
    valueDesc: "Metal halide lamp/High pressure sodium-vapour lamp",
    label: "EV011013: Metal halide lamp/High pressure sodium-vapour lamp"
  },
  {
    valueId: "EV011014",
    valueDesc: "Not dimmable",
    label: "EV011014: Not dimmable"
  },
  {
    valueId: "EV011019",
    valueDesc: "Sealing",
    label: "EV011019: Sealing"
  },
  {
    valueId: "EV011021",
    valueDesc: "Not suitable",
    label: "EV011021: Not suitable"
  },
  {
    valueId: "EV011022",
    valueDesc: "Attachment",
    label: "EV011022: Attachment"
  },
  {
    valueId: "EV011023",
    valueDesc: "Intel Core i7",
    label: "EV011023: Intel Core i7"
  },
  {
    valueId: "EV011024",
    valueDesc: "Intel Core i5",
    label: "EV011024: Intel Core i5"
  },
  {
    valueId: "EV011025",
    valueDesc: "Intel Atom",
    label: "EV011025: Intel Atom"
  },
  {
    valueId: "EV011027",
    valueDesc: "SQ70 / SQ70",
    label: "EV011027: SQ70 / SQ70"
  },
  {
    valueId: "EV011028",
    valueDesc: "SQ64 / RD54",
    label: "EV011028: SQ64 / RD54"
  },
  {
    valueId: "EV011029",
    valueDesc: "Purchase",
    label: "EV011029: Purchase"
  },
  {
    valueId: "EV011030",
    valueDesc: "Acrylic-based",
    label: "EV011030: Acrylic-based"
  },
  {
    valueId: "EV011031",
    valueDesc: "Silicone-based",
    label: "EV011031: Silicone-based"
  },
  {
    valueId: "EV011033",
    valueDesc: "Typebars",
    label: "EV011033: Typebars"
  },
  {
    valueId: "EV011034",
    valueDesc: "Type cylinder",
    label: "EV011034: Type cylinder"
  },
  {
    valueId: "EV011035",
    valueDesc: "Daisy wheel",
    label: "EV011035: Daisy wheel"
  },
  {
    valueId: "EV011036",
    valueDesc: "Snippets",
    label: "EV011036: Snippets"
  },
  {
    valueId: "EV011037",
    valueDesc: "Journal roll",
    label: "EV011037: Journal roll"
  },
  {
    valueId: "EV011038",
    valueDesc: "E-Journal",
    label: "EV011038: E-Journal"
  },
  {
    valueId: "EV011039",
    valueDesc: "Mouse shelf",
    label: "EV011039: Mouse shelf"
  },
  {
    valueId: "EV011040",
    valueDesc: "Sliding rail",
    label: "EV011040: Sliding rail"
  },
  {
    valueId: "EV011041",
    valueDesc: "Hinge",
    label: "EV011041: Hinge"
  },
  {
    valueId: "EV011042",
    valueDesc: "Miniature fuse 10.3x38 mm",
    label: "EV011042: Miniature fuse 10.3x38 mm"
  },
  {
    valueId: "EV011043",
    valueDesc: "Nebulizer",
    label: "EV011043: Nebulizer"
  },
  {
    valueId: "EV011045",
    valueDesc: "Inflating valve",
    label: "EV011045: Inflating valve"
  },
  {
    valueId: "EV011047",
    valueDesc: "Connection at separate chassis part",
    label: "EV011047: Connection at separate chassis part"
  },
  {
    valueId: "EV011048",
    valueDesc: "Bulkhead",
    label: "EV011048: Bulkhead"
  },
  {
    valueId: "EV011053",
    valueDesc: "1.06/HSP1",
    label: "EV011053: 1.06/HSP1"
  },
  {
    valueId: "EV011054",
    valueDesc: "1.06/HSP2",
    label: "EV011054: 1.06/HSP2"
  },
  {
    valueId: "EV011055",
    valueDesc: "1.06/HSP3",
    label: "EV011055: 1.06/HSP3"
  },
  {
    valueId: "EV011056",
    valueDesc: "Microphone connection cable",
    label: "EV011056: Microphone connection cable"
  },
  {
    valueId: "EV011057",
    valueDesc: "Speaker connection cable",
    label: "EV011057: Speaker connection cable"
  },
  {
    valueId: "EV011060",
    valueDesc: "Rack mounting kit",
    label: "EV011060: Rack mounting kit"
  },
  {
    valueId: "EV011064",
    valueDesc: "Paper shears",
    label: "EV011064: Paper shears"
  },
  {
    valueId: "EV011065",
    valueDesc: "Leather shears",
    label: "EV011065: Leather shears"
  },
  {
    valueId: "EV011069",
    valueDesc: "11/16 inch",
    label: "EV011069: 11/16 inch"
  },
  {
    valueId: "EV011070",
    valueDesc: "13/16 inch",
    label: "EV011070: 13/16 inch"
  },
  {
    valueId: "EV011071",
    valueDesc: "19/32 inch",
    label: "EV011071: 19/32 inch"
  },
  {
    valueId: "EV011072",
    valueDesc: "Right-angled grip",
    label: "EV011072: Right-angled grip"
  },
  {
    valueId: "EV011073",
    valueDesc: "1 11/16 inch",
    label: "EV011073: 1 11/16 inch"
  },
  {
    valueId: "EV011074",
    valueDesc: "1 1/18 inch",
    label: "EV011074: 1 1/18 inch"
  },
  {
    valueId: "EV011077",
    valueDesc: "11/32 inch",
    label: "EV011077: 11/32 inch"
  },
  {
    valueId: "EV011078",
    valueDesc: "2 13/16 inch",
    label: "EV011078: 2 13/16 inch"
  },
  {
    valueId: "EV011079",
    valueDesc: "2 15/16 inch",
    label: "EV011079: 2 15/16 inch"
  },
  {
    valueId: "EV011080",
    valueDesc: "2 3/16 inch",
    label: "EV011080: 2 3/16 inch"
  },
  {
    valueId: "EV011081",
    valueDesc: "2 5/8 inch",
    label: "EV011081: 2 5/8 inch"
  },
  {
    valueId: "EV011082",
    valueDesc: "2 7/16 inch",
    label: "EV011082: 2 7/16 inch"
  },
  {
    valueId: "EV011083",
    valueDesc: "25/32 inch",
    label: "EV011083: 25/32 inch"
  },
  {
    valueId: "EV011084",
    valueDesc: "9/32 inch",
    label: "EV011084: 9/32 inch"
  },
  {
    valueId: "EV011095",
    valueDesc: "9x12 mm",
    label: "EV011095: 9x12 mm"
  },
  {
    valueId: "EV011096",
    valueDesc: "14x18 mm",
    label: "EV011096: 14x18 mm"
  },
  {
    valueId: "EV011097",
    valueDesc: "5/32 inch",
    label: "EV011097: 5/32 inch"
  },
  {
    valueId: "EV011098",
    valueDesc: "5/64 inch",
    label: "EV011098: 5/64 inch"
  },
  {
    valueId: "EV011099",
    valueDesc: "7/64 inch",
    label: "EV011099: 7/64 inch"
  },
  {
    valueId: "EV011100",
    valueDesc: "9/64 inch",
    label: "EV011100: 9/64 inch"
  },
  {
    valueId: "EV011101",
    valueDesc: "3 7/8 inch",
    label: "EV011101: 3 7/8 inch"
  },
  {
    valueId: "EV011102",
    valueDesc: "4 1/8 inch",
    label: "EV011102: 4 1/8 inch"
  },
  {
    valueId: "EV011103",
    valueDesc: "3 3/4 inch",
    label: "EV011103: 3 3/4 inch"
  },
  {
    valueId: "EV011104",
    valueDesc: "Square (male)",
    label: "EV011104: Square (male)"
  },
  {
    valueId: "EV011105",
    valueDesc: "Square (female)",
    label: "EV011105: Square (female)"
  },
  {
    valueId: "EV011106",
    valueDesc: "Hexagonal (male)",
    label: "EV011106: Hexagonal (male)"
  },
  {
    valueId: "EV011107",
    valueDesc: "Hexagonal (female)",
    label: "EV011107: Hexagonal (female)"
  },
  {
    valueId: "EV011108",
    valueDesc: "BSF",
    label: "EV011108: BSF"
  },
  {
    valueId: "EV011109",
    valueDesc: "BSPF",
    label: "EV011109: BSPF"
  },
  {
    valueId: "EV011110",
    valueDesc: "BSPT",
    label: "EV011110: BSPT"
  },
  {
    valueId: "EV011111",
    valueDesc: "BSW",
    label: "EV011111: BSW"
  },
  {
    valueId: "EV011112",
    valueDesc: "UNC",
    label: "EV011112: UNC"
  },
  {
    valueId: "EV011113",
    valueDesc: "UNF",
    label: "EV011113: UNF"
  },
  {
    valueId: "EV011114",
    valueDesc: "Tr",
    label: "EV011114: Tr"
  },
  {
    valueId: "EV011115",
    valueDesc: "NPTF",
    label: "EV011115: NPTF"
  },
  {
    valueId: "EV011116",
    valueDesc: "Rd",
    label: "EV011116: Rd"
  },
  {
    valueId: "EV011117",
    valueDesc: "NPSN",
    label: "EV011117: NPSN"
  },
  {
    valueId: "EV011131",
    valueDesc: "Belt",
    label: "EV011131: Belt"
  },
  {
    valueId: "EV011132",
    valueDesc: "Acetate",
    label: "EV011132: Acetate"
  },
  {
    valueId: "EV011133",
    valueDesc: "Tempered glass",
    label: "EV011133: Tempered glass"
  },
  {
    valueId: "EV011136",
    valueDesc: "XXXS",
    label: "EV011136: XXXS"
  },
  {
    valueId: "EV011137",
    valueDesc: "CR 1216",
    label: "EV011137: CR 1216"
  },
  {
    valueId: "EV011138",
    valueDesc: "CR 1632",
    label: "EV011138: CR 1632"
  },
  {
    valueId: "EV011146",
    valueDesc: "Mode 1",
    label: "EV011146: Mode 1"
  },
  {
    valueId: "EV011147",
    valueDesc: "Mode 2",
    label: "EV011147: Mode 2"
  },
  {
    valueId: "EV011148",
    valueDesc: "Mode 3",
    label: "EV011148: Mode 3"
  },
  {
    valueId: "EV011149",
    valueDesc: "Mode 4",
    label: "EV011149: Mode 4"
  },
  {
    valueId: "EV011153",
    valueDesc: "Mounting post",
    label: "EV011153: Mounting post"
  },
  {
    valueId: "EV011159",
    valueDesc: "Cu2/Cu3 (brass)",
    label: "EV011159: Cu2/Cu3 (brass)"
  },
  {
    valueId: "EV011160",
    valueDesc: "Bronze (Rg 12)",
    label: "EV011160: Bronze (Rg 12)"
  },
  {
    valueId: "EV011161",
    valueDesc: "Spring cotter",
    label: "EV011161: Spring cotter"
  },
  {
    valueId: "EV011162",
    valueDesc: "Clevis",
    label: "EV011162: Clevis"
  },
  {
    valueId: "EV011163",
    valueDesc: "Safety closure",
    label: "EV011163: Safety closure"
  },
  {
    valueId: "EV011164",
    valueDesc: "Mineral oil-based",
    label: "EV011164: Mineral oil-based"
  },
  {
    valueId: "EV011165",
    valueDesc: "Water glass-based",
    label: "EV011165: Water glass-based"
  },
  {
    valueId: "EV011167",
    valueDesc: "Welding materials",
    label: "EV011167: Welding materials"
  },
  {
    valueId: "EV011168",
    valueDesc: "Kit",
    label: "EV011168: Kit"
  },
  {
    valueId: "EV011169",
    valueDesc: "Fluid pressure systems",
    label: "EV011169: Fluid pressure systems"
  },
  {
    valueId: "EV011170",
    valueDesc: "Gas and compressed air systems",
    label: "EV011170: Gas and compressed air systems"
  },
  {
    valueId: "EV011171",
    valueDesc: "Metal surfaces",
    label: "EV011171: Metal surfaces"
  },
  {
    valueId: "EV011173",
    valueDesc: "Finish",
    label: "EV011173: Finish"
  },
  {
    valueId: "EV011174",
    valueDesc: "Anti-spat",
    label: "EV011174: Anti-spat"
  },
  {
    valueId: "EV011175",
    valueDesc: "Crack research",
    label: "EV011175: Crack research"
  },
  {
    valueId: "EV011176",
    valueDesc: "Tin/copper",
    label: "EV011176: Tin/copper"
  },
  {
    valueId: "EV011177",
    valueDesc: "Tin/copper/silver",
    label: "EV011177: Tin/copper/silver"
  },
  {
    valueId: "EV011178",
    valueDesc: "Tin/lead",
    label: "EV011178: Tin/lead"
  },
  {
    valueId: "EV011179",
    valueDesc: "Tin/silver",
    label: "EV011179: Tin/silver"
  },
  {
    valueId: "EV011180",
    valueDesc: "4.6 (steel)",
    label: "EV011180: 4.6 (steel)"
  },
  {
    valueId: "EV011181",
    valueDesc: "Corrosion resistant",
    label: "EV011181: Corrosion resistant"
  },
  {
    valueId: "EV011184",
    valueDesc: "Conical plug-in axle",
    label: "EV011184: Conical plug-in axle"
  },
  {
    valueId: "EV011189",
    valueDesc: "Paint sprayer under",
    label: "EV011189: Paint sprayer under"
  },
  {
    valueId: "EV011190",
    valueDesc: "Paint sprayer above",
    label: "EV011190: Paint sprayer above"
  },
  {
    valueId: "EV011192",
    valueDesc: "Sand blasting pistol",
    label: "EV011192: Sand blasting pistol"
  },
  {
    valueId: "EV011198",
    valueDesc: "Power cutter",
    label: "EV011198: Power cutter"
  },
  {
    valueId: "EV011201",
    valueDesc: "Chain hoist",
    label: "EV011201: Chain hoist"
  },
  {
    valueId: "EV011203",
    valueDesc: "Dowel pin puller",
    label: "EV011203: Dowel pin puller"
  },
  {
    valueId: "EV011210",
    valueDesc: "Strikes",
    label: "EV011210: Strikes"
  },
  {
    valueId: "EV011215",
    valueDesc: "Bridge handle",
    label: "EV011215: Bridge handle"
  },
  {
    valueId: "EV011216",
    valueDesc: "Gear lever handle",
    label: "EV011216: Gear lever handle"
  },
  {
    valueId: "EV011218",
    valueDesc: "Bottom part",
    label: "EV011218: Bottom part"
  },
  {
    valueId: "EV011219",
    valueDesc: "Anti-sabotage option",
    label: "EV011219: Anti-sabotage option"
  },
  {
    valueId: "EV011220",
    valueDesc: "Protective sleeve",
    label: "EV011220: Protective sleeve"
  },
  {
    valueId: "EV011222",
    valueDesc: "Bumper",
    label: "EV011222: Bumper"
  },
  {
    valueId: "EV011223",
    valueDesc: "Contact edge",
    label: "EV011223: Contact edge"
  },
  {
    valueId: "EV011224",
    valueDesc: "Safety mat",
    label: "EV011224: Safety mat"
  },
  {
    valueId: "EV011225",
    valueDesc: "Connection plug with resistor",
    label: "EV011225: Connection plug with resistor"
  },
  {
    valueId: "EV011226",
    valueDesc: "Gluing set",
    label: "EV011226: Gluing set"
  },
  {
    valueId: "EV011229",
    valueDesc: "Charging socket outlet",
    label: "EV011229: Charging socket outlet"
  },
  {
    valueId: "EV011232",
    valueDesc: "Water drain hose",
    label: "EV011232: Water drain hose"
  },
  {
    valueId: "EV011236",
    valueDesc: "GSM antenna",
    label: "EV011236: GSM antenna"
  },
  {
    valueId: "EV011239",
    valueDesc: "Outside hexagon",
    label: "EV011239: Outside hexagon"
  },
  {
    valueId: "EV011240",
    valueDesc: "CLASS 10 A",
    label: "EV011240: CLASS 10 A"
  },
  {
    valueId: "EV011241",
    valueDesc: "Earth leakage switch with IFN 10 mA",
    label: "EV011241: Earth leakage switch with IFN 10 mA"
  },
  {
    valueId: "EV011242",
    valueDesc: "Copolyester",
    label: "EV011242: Copolyester"
  },
  {
    valueId: "EV011243",
    valueDesc: "For protruding Torx screw",
    label: "EV011243: For protruding Torx screw"
  },
  {
    valueId: "EV011244",
    valueDesc: "For recessed Torx screws",
    label: "EV011244: For recessed Torx screws"
  },
  {
    valueId: "EV011368",
    valueDesc: "1000 µA",
    label: "EV011368: 1000 µA"
  },
  {
    valueId: "EV011369",
    valueDesc: "0.001 °C",
    label: "EV011369: 0.001 °C"
  },
  {
    valueId: "EV011370",
    valueDesc: "0.01 °C",
    label: "EV011370: 0.01 °C"
  },
  {
    valueId: "EV011371",
    valueDesc: "0.1 °C",
    label: "EV011371: 0.1 °C"
  },
  {
    valueId: "EV011372",
    valueDesc: "1 °C",
    label: "EV011372: 1 °C"
  },
  {
    valueId: "EV011373",
    valueDesc: "0.00001 µF",
    label: "EV011373: 0.00001 µF"
  },
  {
    valueId: "EV011374",
    valueDesc: "0.0001 µF",
    label: "EV011374: 0.0001 µF"
  },
  {
    valueId: "EV011375",
    valueDesc: "0.001 µF",
    label: "EV011375: 0.001 µF"
  },
  {
    valueId: "EV011376",
    valueDesc: "0.01 µF",
    label: "EV011376: 0.01 µF"
  },
  {
    valueId: "EV011377",
    valueDesc: "0.1 µF",
    label: "EV011377: 0.1 µF"
  },
  {
    valueId: "EV011378",
    valueDesc: "1 µF",
    label: "EV011378: 1 µF"
  },
  {
    valueId: "EV011379",
    valueDesc: "0.001 Hz",
    label: "EV011379: 0.001 Hz"
  },
  {
    valueId: "EV011380",
    valueDesc: "0.01 Hz",
    label: "EV011380: 0.01 Hz"
  },
  {
    valueId: "EV011381",
    valueDesc: "0.1 Hz",
    label: "EV011381: 0.1 Hz"
  },
  {
    valueId: "EV011382",
    valueDesc: "1 Hz",
    label: "EV011382: 1 Hz"
  },
  {
    valueId: "EV011383",
    valueDesc: "NBR (nitrile rubber)/cotton",
    label: "EV011383: NBR (nitrile rubber)/cotton"
  },
  {
    valueId: "EV011384",
    valueDesc: "Latex/cotton",
    label: "EV011384: Latex/cotton"
  },
  {
    valueId: "EV011385",
    valueDesc: "Polyester/cotton/latex",
    label: "EV011385: Polyester/cotton/latex"
  },
  {
    valueId: "EV011386",
    valueDesc: "Acrylic/cotton",
    label: "EV011386: Acrylic/cotton"
  },
  {
    valueId: "EV011387",
    valueDesc: "Aramid fibre/elastomer",
    label: "EV011387: Aramid fibre/elastomer"
  },
  {
    valueId: "EV011388",
    valueDesc: "Polyester/EVOH",
    label: "EV011388: Polyester/EVOH"
  },
  {
    valueId: "EV011389",
    valueDesc: "4-point",
    label: "EV011389: 4-point"
  },
  {
    valueId: "EV011390",
    valueDesc: "6-point",
    label: "EV011390: 6-point"
  },
  {
    valueId: "EV011391",
    valueDesc: "Pin lock",
    label: "EV011391: Pin lock"
  },
  {
    valueId: "EV011394",
    valueDesc: "180",
    label: "EV011394: 180"
  },
  {
    valueId: "EV011395",
    valueDesc: "220",
    label: "EV011395: 220"
  },
  {
    valueId: "EV011396",
    valueDesc: "240",
    label: "EV011396: 240"
  },
  {
    valueId: "EV011397",
    valueDesc: "260",
    label: "EV011397: 260"
  },
  {
    valueId: "EV011398",
    valueDesc: "280",
    label: "EV011398: 280"
  },
  {
    valueId: "EV011399",
    valueDesc: "320",
    label: "EV011399: 320"
  },
  {
    valueId: "EV011400",
    valueDesc: "340",
    label: "EV011400: 340"
  },
  {
    valueId: "EV011401",
    valueDesc: "400",
    label: "EV011401: 400"
  },
  {
    valueId: "EV011402",
    valueDesc: "450",
    label: "EV011402: 450"
  },
  {
    valueId: "EV011403",
    valueDesc: "500",
    label: "EV011403: 500"
  },
  {
    valueId: "EV011404",
    valueDesc: "550",
    label: "EV011404: 550"
  },
  {
    valueId: "EV011405",
    valueDesc: "600",
    label: "EV011405: 600"
  },
  {
    valueId: "EV011406",
    valueDesc: "650",
    label: "EV011406: 650"
  },
  {
    valueId: "EV011407",
    valueDesc: "700",
    label: "EV011407: 700"
  },
  {
    valueId: "EV011408",
    valueDesc: "800",
    label: "EV011408: 800"
  },
  {
    valueId: "EV011409",
    valueDesc: "900",
    label: "EV011409: 900"
  },
  {
    valueId: "EV011410",
    valueDesc: "380",
    label: "EV011410: 380"
  },
  {
    valueId: "EV011411",
    valueDesc: "Cellulose propionate",
    label: "EV011411: Cellulose propionate"
  },
  {
    valueId: "EV011412",
    valueDesc: "Polyethylene terephthalate glycol (PETG)",
    label: "EV011412: Polyethylene terephthalate glycol (PETG)"
  },
  {
    valueId: "EV011413",
    valueDesc: "Cabinet",
    label: "EV011413: Cabinet"
  },
  {
    valueId: "EV011414",
    valueDesc: "200-250 V (50+60 Hz) blue",
    label: "EV011414: 200-250 V (50+60 Hz) blue"
  },
  {
    valueId: "EV011415",
    valueDesc: "380-415 V (50+60 Hz) red",
    label: "EV011415: 380-415 V (50+60 Hz) red"
  },
  {
    valueId: "EV011416",
    valueDesc: "Lead acid",
    label: "EV011416: Lead acid"
  },
  {
    valueId: "EV011417",
    valueDesc: "5-fold",
    label: "EV011417: 5-fold"
  },
  {
    valueId: "EV011418",
    valueDesc: "Suitable for snap-in connector (manufacturer specific)",
    label: "EV011418: Suitable for snap-in connector (manufacturer specific)"
  },
  {
    valueId: "EV011419",
    valueDesc: "On central conduit box",
    label: "EV011419: On central conduit box"
  },
  {
    valueId: "EV011420",
    valueDesc: "Connector connection",
    label: "EV011420: Connector connection"
  },
  {
    valueId: "EV011422",
    valueDesc: "Six-fold module length",
    label: "EV011422: Six-fold module length"
  },
  {
    valueId: "EV011423",
    valueDesc: "IK00",
    label: "EV011423: IK00"
  },
  {
    valueId: "EV011424",
    valueDesc: "Anchor winch",
    label: "EV011424: Anchor winch"
  },
  {
    valueId: "EV011425",
    valueDesc: "Tow/anchor winch",
    label: "EV011425: Tow/anchor winch"
  },
  {
    valueId: "EV011426",
    valueDesc: "Fishing winch",
    label: "EV011426: Fishing winch"
  },
  {
    valueId: "EV011427",
    valueDesc: "Self tailing winch",
    label: "EV011427: Self tailing winch"
  },
  {
    valueId: "EV011428",
    valueDesc: "Wire/rope winch",
    label: "EV011428: Wire/rope winch"
  },
  {
    valueId: "EV011429",
    valueDesc: "h10",
    label: "EV011429: h10"
  },
  {
    valueId: "EV011430",
    valueDesc: "P10",
    label: "EV011430: P10"
  },
  {
    valueId: "EV011431",
    valueDesc: "Dichroid plated glass",
    label: "EV011431: Dichroid plated glass"
  },
  {
    valueId: "EV011432",
    valueDesc: "E39",
    label: "EV011432: E39"
  },
  {
    valueId: "EV011435",
    valueDesc: "High frequency",
    label: "EV011435: High frequency"
  },
  {
    valueId: "EV011436",
    valueDesc: "IM 1001 (B3)",
    label: "EV011436: IM 1001 (B3)"
  },
  {
    valueId: "EV011437",
    valueDesc: "IM 1011 (V5)",
    label: "EV011437: IM 1011 (V5)"
  },
  {
    valueId: "EV011438",
    valueDesc: "IM 1031 (V6)",
    label: "EV011438: IM 1031 (V6)"
  },
  {
    valueId: "EV011439",
    valueDesc: "IM 1051 (B6)",
    label: "EV011439: IM 1051 (B6)"
  },
  {
    valueId: "EV011440",
    valueDesc: "IM 1061 (B7)",
    label: "EV011440: IM 1061 (B7)"
  },
  {
    valueId: "EV011441",
    valueDesc: "IM 1071 (B8)",
    label: "EV011441: IM 1071 (B8)"
  },
  {
    valueId: "EV011442",
    valueDesc: "IM 2001 (B35)",
    label: "EV011442: IM 2001 (B35)"
  },
  {
    valueId: "EV011443",
    valueDesc: "IM 2011 (V15)",
    label: "EV011443: IM 2011 (V15)"
  },
  {
    valueId: "EV011444",
    valueDesc: "IM 2031 (V36)",
    label: "EV011444: IM 2031 (V36)"
  },
  {
    valueId: "EV011445",
    valueDesc: "IM 2051",
    label: "EV011445: IM 2051"
  },
  {
    valueId: "EV011446",
    valueDesc: "IM 2061",
    label: "EV011446: IM 2061"
  },
  {
    valueId: "EV011447",
    valueDesc: "IM 2071",
    label: "EV011447: IM 2071"
  },
  {
    valueId: "EV011448",
    valueDesc: "IM 2101 (B34)",
    label: "EV011448: IM 2101 (B34)"
  },
  {
    valueId: "EV011449",
    valueDesc: "IM 2111 (V15)",
    label: "EV011449: IM 2111 (V15)"
  },
  {
    valueId: "EV011450",
    valueDesc: "IM 2131 (V36)",
    label: "EV011450: IM 2131 (V36)"
  },
  {
    valueId: "EV011451",
    valueDesc: "IM 2151",
    label: "EV011451: IM 2151"
  },
  {
    valueId: "EV011452",
    valueDesc: "IM 2161",
    label: "EV011452: IM 2161"
  },
  {
    valueId: "EV011453",
    valueDesc: "IM 2171",
    label: "EV011453: IM 2171"
  },
  {
    valueId: "EV011454",
    valueDesc: "IM 3001 (B5)",
    label: "EV011454: IM 3001 (B5)"
  },
  {
    valueId: "EV011455",
    valueDesc: "IM 3011 (V1)",
    label: "EV011455: IM 3011 (V1)"
  },
  {
    valueId: "EV011456",
    valueDesc: "IM 3031 (V3)",
    label: "EV011456: IM 3031 (V3)"
  },
  {
    valueId: "EV011457",
    valueDesc: "IM 3601 (B14)",
    label: "EV011457: IM 3601 (B14)"
  },
  {
    valueId: "EV011458",
    valueDesc: "IM 3611 (V18)",
    label: "EV011458: IM 3611 (V18)"
  },
  {
    valueId: "EV011459",
    valueDesc: "IM 3631 (V19)",
    label: "EV011459: IM 3631 (V19)"
  },
  {
    valueId: "EV011460",
    valueDesc: "IE1",
    label: "EV011460: IE1"
  },
  {
    valueId: "EV011461",
    valueDesc: "IE2",
    label: "EV011461: IE2"
  },
  {
    valueId: "EV011462",
    valueDesc: "IE3",
    label: "EV011462: IE3"
  },
  {
    valueId: "EV011463",
    valueDesc: "S4",
    label: "EV011463: S4"
  },
  {
    valueId: "EV011464",
    valueDesc: "S5",
    label: "EV011464: S5"
  },
  {
    valueId: "EV011465",
    valueDesc: "S9",
    label: "EV011465: S9"
  },
  {
    valueId: "EV011466",
    valueDesc: "Ex d",
    label: "EV011466: Ex d"
  },
  {
    valueId: "EV011467",
    valueDesc: "Ex e",
    label: "EV011467: Ex e"
  },
  {
    valueId: "EV011468",
    valueDesc: "Ex ia",
    label: "EV011468: Ex ia"
  },
  {
    valueId: "EV011469",
    valueDesc: "Ex ib",
    label: "EV011469: Ex ib"
  },
  {
    valueId: "EV011470",
    valueDesc: "Ex m",
    label: "EV011470: Ex m"
  },
  {
    valueId: "EV011471",
    valueDesc: "Ex n",
    label: "EV011471: Ex n"
  },
  {
    valueId: "EV011472",
    valueDesc: "Ex o",
    label: "EV011472: Ex o"
  },
  {
    valueId: "EV011473",
    valueDesc: "Ex p",
    label: "EV011473: Ex p"
  },
  {
    valueId: "EV011474",
    valueDesc: "Ex q",
    label: "EV011474: Ex q"
  },
  {
    valueId: "EV011475",
    valueDesc: "Parallel key",
    label: "EV011475: Parallel key"
  },
  {
    valueId: "EV011476",
    valueDesc: "Shrink",
    label: "EV011476: Shrink"
  },
  {
    valueId: "EV011477",
    valueDesc: "Epoxy hybrid",
    label: "EV011477: Epoxy hybrid"
  },
  {
    valueId: "EV011478",
    valueDesc: "Epoxy anhydride",
    label: "EV011478: Epoxy anhydride"
  },
  {
    valueId: "EV011479",
    valueDesc: "Catalysed epoxy",
    label: "EV011479: Catalysed epoxy"
  },
  {
    valueId: "EV011480",
    valueDesc: "Polyesterimide",
    label: "EV011480: Polyesterimide"
  },
  {
    valueId: "EV011481",
    valueDesc: "GX12-1",
    label: "EV011481: GX12-1"
  },
  {
    valueId: "EV011482",
    valueDesc: "Cable connection, cable not included",
    label: "EV011482: Cable connection, cable not included"
  },
  {
    valueId: "EV011483",
    valueDesc: "Rope ladder",
    label: "EV011483: Rope ladder"
  },
  {
    valueId: "EV011484",
    valueDesc: "Ametal",
    label: "EV011484: Ametal"
  },
  {
    valueId: "EV011485",
    valueDesc: "Glue spatula",
    label: "EV011485: Glue spatula"
  },
  {
    valueId: "EV011486",
    valueDesc: "Insulation cover",
    label: "EV011486: Insulation cover"
  },
  {
    valueId: "EV011491",
    valueDesc: "Weather-dependent control",
    label: "EV011491: Weather-dependent control"
  },
  {
    valueId: "EV011492",
    valueDesc: "Left and centre",
    label: "EV011492: Left and centre"
  },
  {
    valueId: "EV011493",
    valueDesc: "Right and centre",
    label: "EV011493: Right and centre"
  },
  {
    valueId: "EV011494",
    valueDesc: "Free-standing/mobile",
    label: "EV011494: Free-standing/mobile"
  },
  {
    valueId: "EV011495",
    valueDesc: "Incable-Controlbox (ICCB)",
    label: "EV011495: Incable-Controlbox (ICCB)"
  },
  {
    valueId: "EV011496",
    valueDesc: "RFID card",
    label: "EV011496: RFID card"
  },
  {
    valueId: "EV011498",
    valueDesc: "AC single phase",
    label: "EV011498: AC single phase"
  },
  {
    valueId: "EV011499",
    valueDesc: "AC 3-phase",
    label: "EV011499: AC 3-phase"
  },
  {
    valueId: "EV011500",
    valueDesc: "Type 1 CCS",
    label: "EV011500: Type 1 CCS"
  },
  {
    valueId: "EV011501",
    valueDesc: "Type 2",
    label: "EV011501: Type 2"
  },
  {
    valueId: "EV011502",
    valueDesc: "Type 2 CCS",
    label: "EV011502: Type 2 CCS"
  },
  {
    valueId: "EV011503",
    valueDesc: "GB AC",
    label: "EV011503: GB AC"
  },
  {
    valueId: "EV011504",
    valueDesc: "GB DC",
    label: "EV011504: GB DC"
  },
  {
    valueId: "EV011505",
    valueDesc: "CHAdeMO",
    label: "EV011505: CHAdeMO"
  },
  {
    valueId: "EV011506",
    valueDesc: "Protective contact plug",
    label: "EV011506: Protective contact plug"
  },
  {
    valueId: "EV011507",
    valueDesc: "Spiralized",
    label: "EV011507: Spiralized"
  },
  {
    valueId: "EV011508",
    valueDesc: "Vehicle inlet",
    label: "EV011508: Vehicle inlet"
  },
  {
    valueId: "EV011509",
    valueDesc: "Charging connector",
    label: "EV011509: Charging connector"
  },
  {
    valueId: "EV011510",
    valueDesc: "Charging plug",
    label: "EV011510: Charging plug"
  },
  {
    valueId: "EV011511",
    valueDesc: "Protective contact",
    label: "EV011511: Protective contact"
  },
  {
    valueId: "EV011512",
    valueDesc: "Type 3",
    label: "EV011512: Type 3"
  },
  {
    valueId: "EV011514",
    valueDesc: "LED exchangeable",
    label: "EV011514: LED exchangeable"
  },
  {
    valueId: "EV011515",
    valueDesc: "LED not exchangeable",
    label: "EV011515: LED not exchangeable"
  },
  {
    valueId: "EV011516",
    valueDesc: "GX24d-1",
    label: "EV011516: GX24d-1"
  },
  {
    valueId: "EV011519",
    valueDesc: "G24q-1",
    label: "EV011519: G24q-1"
  },
  {
    valueId: "EV011520",
    valueDesc: "G24q-2",
    label: "EV011520: G24q-2"
  },
  {
    valueId: "EV011521",
    valueDesc: "G24q-3",
    label: "EV011521: G24q-3"
  },
  {
    valueId: "EV011522",
    valueDesc: "G24q-4",
    label: "EV011522: G24q-4"
  },
  {
    valueId: "EV011523",
    valueDesc: "GX24q-1",
    label: "EV011523: GX24q-1"
  },
  {
    valueId: "EV011524",
    valueDesc: "GX24q-2",
    label: "EV011524: GX24q-2"
  },
  {
    valueId: "EV011525",
    valueDesc: "GX24q-3",
    label: "EV011525: GX24q-3"
  },
  {
    valueId: "EV011526",
    valueDesc: "GX24q-4",
    label: "EV011526: GX24q-4"
  },
  {
    valueId: "EV011528",
    valueDesc: "Low-loss ballast inductive",
    label: "EV011528: Low-loss ballast inductive"
  },
  {
    valueId: "EV011529",
    valueDesc: "Low-loss ballast compensated",
    label: "EV011529: Low-loss ballast compensated"
  },
  {
    valueId: "EV011530",
    valueDesc: "LED operating device current-controlled",
    label: "EV011530: LED operating device current-controlled"
  },
  {
    valueId: "EV011531",
    valueDesc: "LED operating device voltage-controlled",
    label: "EV011531: LED operating device voltage-controlled"
  },
  {
    valueId: "EV011533",
    valueDesc: "Double asymmetric",
    label: "EV011533: Double asymmetric"
  },
  {
    valueId: "EV011534",
    valueDesc: "Wide beam 41-80°",
    label: "EV011534: Wide beam 41-80°"
  },
  {
    valueId: "EV011535",
    valueDesc: "Narrow beam 11 - 20°",
    label: "EV011535: Narrow beam 11 - 20°"
  },
  {
    valueId: "EV011536",
    valueDesc: "Medium beam 21-40°",
    label: "EV011536: Medium beam 21-40°"
  },
  {
    valueId: "EV011537",
    valueDesc: "Extreme narrow beam 5-10°",
    label: "EV011537: Extreme narrow beam 5-10°"
  },
  {
    valueId: "EV011538",
    valueDesc: "Extreme wide beam >80°",
    label: "EV011538: Extreme wide beam >80°"
  },
  {
    valueId: "EV011539",
    valueDesc: "Open beam/diffuse",
    label: "EV011539: Open beam/diffuse"
  },
  {
    valueId: "EV011540",
    valueDesc: "Parallel beam <5°",
    label: "EV011540: Parallel beam <5°"
  },
  {
    valueId: "EV011541",
    valueDesc: "> IK10",
    label: "EV011541: > IK10"
  },
  {
    valueId: "EV011542",
    valueDesc: "650 °C - 30 s",
    label: "EV011542: 650 °C - 30 s"
  },
  {
    valueId: "EV011543",
    valueDesc: "RGBW",
    label: "EV011543: RGBW"
  },
  {
    valueId: "EV011544",
    valueDesc: "Rotating and swivelling",
    label: "EV011544: Rotating and swivelling"
  },
  {
    valueId: "EV011545",
    valueDesc: "Not adjustable",
    label: "EV011545: Not adjustable"
  },
  {
    valueId: "EV011546",
    valueDesc: "GX22",
    label: "EV011546: GX22"
  },
  {
    valueId: "EV011547",
    valueDesc: "Composite material",
    label: "EV011547: Composite material"
  },
  {
    valueId: "EV011549",
    valueDesc: "Group control",
    label: "EV011549: Group control"
  },
  {
    valueId: "EV011550",
    valueDesc: "Network control",
    label: "EV011550: Network control"
  },
  {
    valueId: "EV011551",
    valueDesc: "Stand-alone control",
    label: "EV011551: Stand-alone control"
  },
  {
    valueId: "EV011552",
    valueDesc: "1/2 inch external thread",
    label: "EV011552: 1/2 inch external thread"
  },
  {
    valueId: "EV011554",
    valueDesc: "3/4 inch internal thread",
    label: "EV011554: 3/4 inch internal thread"
  },
  {
    valueId: "EV011557",
    valueDesc: "Remote signalling",
    label: "EV011557: Remote signalling"
  },
  {
    valueId: "EV011558",
    valueDesc: "Pressure control",
    label: "EV011558: Pressure control"
  },
  {
    valueId: "EV011559",
    valueDesc: "External readout module",
    label: "EV011559: External readout module"
  },
  {
    valueId: "EV011560",
    valueDesc: "SD card reader",
    label: "EV011560: SD card reader"
  },
  {
    valueId: "EV011561",
    valueDesc: "Signal output module Analogue",
    label: "EV011561: Signal output module Analogue"
  },
  {
    valueId: "EV011562",
    valueDesc: "Membrane breakage sensor",
    label: "EV011562: Membrane breakage sensor"
  },
  {
    valueId: "EV011563",
    valueDesc: "60°",
    label: "EV011563: 60°"
  },
  {
    valueId: "EV011564",
    valueDesc: "PGJX50",
    label: "EV011564: PGJX50"
  },
  {
    valueId: "EV011569",
    valueDesc: "Sanding set",
    label: "EV011569: Sanding set"
  },
  {
    valueId: "EV011571",
    valueDesc: "Sealing tape",
    label: "EV011571: Sealing tape"
  },
  {
    valueId: "EV011572",
    valueDesc: "Sealing paste",
    label: "EV011572: Sealing paste"
  },
  {
    valueId: "EV011573",
    valueDesc: "Sealing cloth",
    label: "EV011573: Sealing cloth"
  },
  {
    valueId: "EV011575",
    valueDesc: "Seaworthy",
    label: "EV011575: Seaworthy"
  },
  {
    valueId: "EV011576",
    valueDesc: "Hygiene bags",
    label: "EV011576: Hygiene bags"
  },
  {
    valueId: "EV011577",
    valueDesc: "Soap",
    label: "EV011577: Soap"
  },
  {
    valueId: "EV011578",
    valueDesc: "Screw cap",
    label: "EV011578: Screw cap"
  },
  {
    valueId: "EV011579",
    valueDesc: "Hairdryer socket",
    label: "EV011579: Hairdryer socket"
  },
  {
    valueId: "EV011580",
    valueDesc: "Wi-Fi",
    label: "EV011580: Wi-Fi"
  },
  {
    valueId: "EV011581",
    valueDesc: "Text",
    label: "EV011581: Text"
  },
  {
    valueId: "EV011582",
    valueDesc: "Speech",
    label: "EV011582: Speech"
  },
  {
    valueId: "EV011583",
    valueDesc: "Composite",
    label: "EV011583: Composite"
  },
  {
    valueId: "EV011585",
    valueDesc: "Escutcheon plate for mounting without release handle",
    label: "EV011585: Escutcheon plate for mounting without release handle"
  },
  {
    valueId: "EV011586",
    valueDesc: "Manual unlock seal-label",
    label: "EV011586: Manual unlock seal-label"
  },
  {
    valueId: "EV011587",
    valueDesc: "PC plate for mounting on mesh door",
    label: "EV011587: PC plate for mounting on mesh door"
  },
  {
    valueId: "EV011588",
    valueDesc: "Anchor plate",
    label: "EV011588: Anchor plate"
  },
  {
    valueId: "EV011589",
    valueDesc: "Cellular rubber filling",
    label: "EV011589: Cellular rubber filling"
  },
  {
    valueId: "EV011590",
    valueDesc: "Tongue",
    label: "EV011590: Tongue"
  },
  {
    valueId: "EV011591",
    valueDesc: "IEEE 802.11 b/g",
    label: "EV011591: IEEE 802.11 b/g"
  },
  {
    valueId: "EV011592",
    valueDesc: "IEEE 802.11 b/g/n",
    label: "EV011592: IEEE 802.11 b/g/n"
  },
  {
    valueId: "EV011593",
    valueDesc: "IEEE 802.11 n",
    label: "EV011593: IEEE 802.11 n"
  },
  {
    valueId: "EV011594",
    valueDesc: "Cap for breaking resistance",
    label: "EV011594: Cap for breaking resistance"
  },
  {
    valueId: "EV011595",
    valueDesc: "Protection hood connection terminals",
    label: "EV011595: Protection hood connection terminals"
  },
  {
    valueId: "EV011596",
    valueDesc: "Encoder input module",
    label: "EV011596: Encoder input module"
  },
  {
    valueId: "EV011597",
    valueDesc: "68x68 mm",
    label: "EV011597: 68x68 mm"
  },
  {
    valueId: "EV011598",
    valueDesc: "104x104 mm",
    label: "EV011598: 104x104 mm"
  },
  {
    valueId: "EV011599",
    valueDesc: "Label space",
    label: "EV011599: Label space"
  },
  {
    valueId: "EV011600",
    valueDesc: "Control and orientation",
    label: "EV011600: Control and orientation"
  },
  {
    valueId: "EV011601",
    valueDesc: "Symbol 'stop'",
    label: "EV011601: Symbol 'stop'"
  },
  {
    valueId: "EV011602",
    valueDesc: "Symbol 'socket outlet'",
    label: "EV011602: Symbol 'socket outlet'"
  },
  {
    valueId: "EV011603",
    valueDesc: "Space sensor",
    label: "EV011603: Space sensor"
  },
  {
    valueId: "EV011604",
    valueDesc: "Black/white/red",
    label: "EV011604: Black/white/red"
  },
  {
    valueId: "EV011606",
    valueDesc: "Automatically controlled on dT",
    label: "EV011606: Automatically controlled on dT"
  },
  {
    valueId: "EV011608",
    valueDesc: "Time indication",
    label: "EV011608: Time indication"
  },
  {
    valueId: "EV011609",
    valueDesc: "Docking station",
    label: "EV011609: Docking station"
  },
  {
    valueId: "EV011610",
    valueDesc: "Loudness control",
    label: "EV011610: Loudness control"
  },
  {
    valueId: "EV011611",
    valueDesc: "Multi function element",
    label: "EV011611: Multi function element"
  },
  {
    valueId: "EV011612",
    valueDesc: "eNet",
    label: "EV011612: eNet"
  },
  {
    valueId: "EV011614",
    valueDesc: "Coupling (female connector)",
    label: "EV011614: Coupling (female connector)"
  },
  {
    valueId: "EV011615",
    valueDesc: "Bend rigid",
    label: "EV011615: Bend rigid"
  },
  {
    valueId: "EV011616",
    valueDesc: "4XL",
    label: "EV011616: 4XL"
  },
  {
    valueId: "EV011617",
    valueDesc: "5XL",
    label: "EV011617: 5XL"
  },
  {
    valueId: "EV011618",
    valueDesc: "6XL",
    label: "EV011618: 6XL"
  },
  {
    valueId: "EV011621",
    valueDesc: "Flat 5.2x0.8 mm",
    label: "EV011621: Flat 5.2x0.8 mm"
  },
  {
    valueId: "EV011622",
    valueDesc: "Flat 9.8x1.1 mm",
    label: "EV011622: Flat 9.8x1.1 mm"
  },
  {
    valueId: "EV011625",
    valueDesc: "Separation wedges",
    label: "EV011625: Separation wedges"
  },
  {
    valueId: "EV011627",
    valueDesc: "Pressure cast",
    label: "EV011627: Pressure cast"
  },
  {
    valueId: "EV011628",
    valueDesc: "Wall openings",
    label: "EV011628: Wall openings"
  },
  {
    valueId: "EV011630",
    valueDesc: "Core holes/tubes",
    label: "EV011630: Core holes/tubes"
  },
  {
    valueId: "EV011631",
    valueDesc: "Network",
    label: "EV011631: Network"
  },
  {
    valueId: "EV011632",
    valueDesc: "Y-adapter",
    label: "EV011632: Y-adapter"
  },
  {
    valueId: "EV011633",
    valueDesc: "Male/female",
    label: "EV011633: Male/female"
  },
  {
    valueId: "EV011634",
    valueDesc: "Laces",
    label: "EV011634: Laces"
  },
  {
    valueId: "EV011635",
    valueDesc: "Zipper",
    label: "EV011635: Zipper"
  },
  {
    valueId: "EV011636",
    valueDesc: "Floor mounting grid",
    label: "EV011636: Floor mounting grid"
  },
  {
    valueId: "EV011639",
    valueDesc: "S19",
    label: "EV011639: S19"
  },
  {
    valueId: "EV011640",
    valueDesc: "Gr10q-3",
    label: "EV011640: Gr10q-3"
  },
  {
    valueId: "EV011641",
    valueDesc: "6.8 (steel)",
    label: "EV011641: 6.8 (steel)"
  },
  {
    valueId: "EV011642",
    valueDesc: "gPV (photovoltaic protection)",
    label: "EV011642: gPV (photovoltaic protection)"
  },
  {
    valueId: "EV011643",
    valueDesc: "gB (Mining protection)",
    label: "EV011643: gB (Mining protection)"
  },
  {
    valueId: "EV011644",
    valueDesc: "gTr (Transformer protection)",
    label: "EV011644: gTr (Transformer protection)"
  },
  {
    valueId: "EV011645",
    valueDesc: "8.5x23 mm",
    label: "EV011645: 8.5x23 mm"
  },
  {
    valueId: "EV011646",
    valueDesc: "17.5x63.5 mm",
    label: "EV011646: 17.5x63.5 mm"
  },
  {
    valueId: "EV011647",
    valueDesc: "10x85 mm",
    label: "EV011647: 10x85 mm"
  },
  {
    valueId: "EV011648",
    valueDesc: "11x79 mm",
    label: "EV011648: 11x79 mm"
  },
  {
    valueId: "EV011649",
    valueDesc: "8.5x31.5 mm",
    label: "EV011649: 8.5x31.5 mm"
  },
  {
    valueId: "EV011650",
    valueDesc: "8x40 mm",
    label: "EV011650: 8x40 mm"
  },
  {
    valueId: "EV011651",
    valueDesc: "Italian type P11",
    label: "EV011651: Italian type P11"
  },
  {
    valueId: "EV011652",
    valueDesc: "Italian type P17",
    label: "EV011652: Italian type P17"
  },
  {
    valueId: "EV011653",
    valueDesc: "Italian type P30",
    label: "EV011653: Italian type P30"
  },
  {
    valueId: "EV011654",
    valueDesc: "Italian type Bipasso",
    label: "EV011654: Italian type Bipasso"
  },
  {
    valueId: "EV011660",
    valueDesc: "Guide bushing",
    label: "EV011660: Guide bushing"
  },
  {
    valueId: "EV011677",
    valueDesc: "Cylindrical fuse",
    label: "EV011677: Cylindrical fuse"
  },
  {
    valueId: "EV011678",
    valueDesc: "Darrieus rotor",
    label: "EV011678: Darrieus rotor"
  },
  {
    valueId: "EV011679",
    valueDesc: "Downwind rotor",
    label: "EV011679: Downwind rotor"
  },
  {
    valueId: "EV011680",
    valueDesc: "Upwind rotor",
    label: "EV011680: Upwind rotor"
  },
  {
    valueId: "EV011681",
    valueDesc: "Photovoltaic",
    label: "EV011681: Photovoltaic"
  },
  {
    valueId: "EV011682",
    valueDesc: "Solar thermal energy",
    label: "EV011682: Solar thermal energy"
  },
  {
    valueId: "EV011683",
    valueDesc: "Wind energy",
    label: "EV011683: Wind energy"
  },
  {
    valueId: "EV011690",
    valueDesc: "Reversing pole-switch",
    label: "EV011690: Reversing pole-switch"
  },
  {
    valueId: "EV011698",
    valueDesc: "Dots",
    label: "EV011698: Dots"
  },
  {
    valueId: "EV011699",
    valueDesc: "Cable protector plate",
    label: "EV011699: Cable protector plate"
  },
  {
    valueId: "EV011700",
    valueDesc: "Pedestal (in-ground mounting)",
    label: "EV011700: Pedestal (in-ground mounting)"
  },
  {
    valueId: "EV011701",
    valueDesc: "EX39",
    label: "EV011701: EX39"
  },
  {
    valueId: "EV011702",
    valueDesc: "PGZX18",
    label: "EV011702: PGZX18"
  },
  {
    valueId: "EV011703",
    valueDesc: "Decorative image",
    label: "EV011703: Decorative image"
  },
  {
    valueId: "EV011704",
    valueDesc: "High-temperature environment",
    label: "EV011704: High-temperature environment"
  },
  {
    valueId: "EV011705",
    valueDesc: "DDL",
    label: "EV011705: DDL"
  },
  {
    valueId: "EV011706",
    valueDesc: "EFN",
    label: "EV011706: EFN"
  },
  {
    valueId: "EV011707",
    valueDesc: "EFR",
    label: "EV011707: EFR"
  },
  {
    valueId: "EV011708",
    valueDesc: "EFP/5H",
    label: "EV011708: EFP/5H"
  },
  {
    valueId: "EV011709",
    valueDesc: "EHJ-X",
    label: "EV011709: EHJ-X"
  },
  {
    valueId: "EV011710",
    valueDesc: "ELC/5H",
    label: "EV011710: ELC/5H"
  },
  {
    valueId: "EV011711",
    valueDesc: "ELC/10H",
    label: "EV011711: ELC/10H"
  },
  {
    valueId: "EV011712",
    valueDesc: "ELD",
    label: "EV011712: ELD"
  },
  {
    valueId: "EV011713",
    valueDesc: "EVC",
    label: "EV011713: EVC"
  },
  {
    valueId: "EV011714",
    valueDesc: "EVD-X",
    label: "EV011714: EVD-X"
  },
  {
    valueId: "EV011715",
    valueDesc: "FCS",
    label: "EV011715: FCS"
  },
  {
    valueId: "EV011716",
    valueDesc: "FLW",
    label: "EV011716: FLW"
  },
  {
    valueId: "EV011717",
    valueDesc: "JCR",
    label: "EV011717: JCR"
  },
  {
    valueId: "EV011718",
    valueDesc: "Magnet, uncoded",
    label: "EV011718: Magnet, uncoded"
  },
  {
    valueId: "EV011719",
    valueDesc: "Magnet, coded",
    label: "EV011719: Magnet, coded"
  },
  {
    valueId: "EV011721",
    valueDesc: "GX23-2",
    label: "EV011721: GX23-2"
  },
  {
    valueId: "EV011722",
    valueDesc: "GX32d-2",
    label: "EV011722: GX32d-2"
  },
  {
    valueId: "EV011723",
    valueDesc: "GX32d-3",
    label: "EV011723: GX32d-3"
  },
  {
    valueId: "EV011724",
    valueDesc: "2GX7",
    label: "EV011724: 2GX7"
  },
  {
    valueId: "EV011725",
    valueDesc: "GX10q-4",
    label: "EV011725: GX10q-4"
  },
  {
    valueId: "EV011726",
    valueDesc: "G23-2",
    label: "EV011726: G23-2"
  },
  {
    valueId: "EV011727",
    valueDesc: "GU24",
    label: "EV011727: GU24"
  },
  {
    valueId: "EV011730",
    valueDesc: "Half moon",
    label: "EV011730: Half moon"
  },
  {
    valueId: "EV011731",
    valueDesc: "Class 4",
    label: "EV011731: Class 4"
  },
  {
    valueId: "EV011732",
    valueDesc: "Class 5",
    label: "EV011732: Class 5"
  },
  {
    valueId: "EV011734",
    valueDesc: "Helmet attached",
    label: "EV011734: Helmet attached"
  },
  {
    valueId: "EV011736",
    valueDesc: "Under the chin",
    label: "EV011736: Under the chin"
  },
  {
    valueId: "EV011737",
    valueDesc: "Wall collar",
    label: "EV011737: Wall collar"
  },
  {
    valueId: "EV011738",
    valueDesc: "Fuse cartridge",
    label: "EV011738: Fuse cartridge"
  },
  {
    valueId: "EV011739",
    valueDesc: "Toslink",
    label: "EV011739: Toslink"
  },
  {
    valueId: "EV011740",
    valueDesc: "3.5 mm stereo jack",
    label: "EV011740: 3.5 mm stereo jack"
  },
  {
    valueId: "EV011741",
    valueDesc: "2.5 mm stereo jack",
    label: "EV011741: 2.5 mm stereo jack"
  },
  {
    valueId: "EV011742",
    valueDesc: "6.35 mm stereo jack",
    label: "EV011742: 6.35 mm stereo jack"
  },
  {
    valueId: "EV011744",
    valueDesc: "DIN-connection",
    label: "EV011744: DIN-connection"
  },
  {
    valueId: "EV011745",
    valueDesc: "Diode connector",
    label: "EV011745: Diode connector"
  },
  {
    valueId: "EV011746",
    valueDesc: "Magnetic-inductive",
    label: "EV011746: Magnetic-inductive"
  },
  {
    valueId: "EV011747",
    valueDesc: "Variable area flow",
    label: "EV011747: Variable area flow"
  },
  {
    valueId: "EV011748",
    valueDesc: "Stagnation pressure",
    label: "EV011748: Stagnation pressure"
  },
  {
    valueId: "EV011749",
    valueDesc: "Coriolis-mass",
    label: "EV011749: Coriolis-mass"
  },
  {
    valueId: "EV011750",
    valueDesc: "Sealing insert (star shape)",
    label: "EV011750: Sealing insert (star shape)"
  },
  {
    valueId: "EV011752",
    valueDesc: "Hammer loop",
    label: "EV011752: Hammer loop"
  },
  {
    valueId: "EV011754",
    valueDesc: "Filter pad",
    label: "EV011754: Filter pad"
  },
  {
    valueId: "EV011755",
    valueDesc: "Spare glass",
    label: "EV011755: Spare glass"
  },
  {
    valueId: "EV011756",
    valueDesc: "De-mounting tool",
    label: "EV011756: De-mounting tool"
  },
  {
    valueId: "EV011758",
    valueDesc: "Levelling base",
    label: "EV011758: Levelling base"
  },
  {
    valueId: "EV011761",
    valueDesc: "Grinding plate",
    label: "EV011761: Grinding plate"
  },
  {
    valueId: "EV011767",
    valueDesc: "Android",
    label: "EV011767: Android"
  },
  {
    valueId: "EV011768",
    valueDesc: "iOS",
    label: "EV011768: iOS"
  },
  {
    valueId: "EV011769",
    valueDesc: "Mini-USB",
    label: "EV011769: Mini-USB"
  },
  {
    valueId: "EV011770",
    valueDesc: "Micro-USB",
    label: "EV011770: Micro-USB"
  },
  {
    valueId: "EV011771",
    valueDesc: "Mini HDMI (Type C)",
    label: "EV011771: Mini HDMI (Type C)"
  },
  {
    valueId: "EV011772",
    valueDesc: "Micro HDMI (Type D)",
    label: "EV011772: Micro HDMI (Type D)"
  },
  {
    valueId: "EV011773",
    valueDesc: "Ear protection",
    label: "EV011773: Ear protection"
  },
  {
    valueId: "EV011774",
    valueDesc: "Helmet/head protection",
    label: "EV011774: Helmet/head protection"
  },
  {
    valueId: "EV011775",
    valueDesc: "Respiratory protection",
    label: "EV011775: Respiratory protection"
  },
  {
    valueId: "EV011776",
    valueDesc: "Face/eye protection",
    label: "EV011776: Face/eye protection"
  },
  {
    valueId: "EV011777",
    valueDesc: "Foot and leg protection",
    label: "EV011777: Foot and leg protection"
  },
  {
    valueId: "EV011778",
    valueDesc: "Arm and hand protection",
    label: "EV011778: Arm and hand protection"
  },
  {
    valueId: "EV011779",
    valueDesc: "Wire transition conduit",
    label: "EV011779: Wire transition conduit"
  },
  {
    valueId: "EV011781",
    valueDesc: "Frame part",
    label: "EV011781: Frame part"
  },
  {
    valueId: "EV011782",
    valueDesc: "Door part",
    label: "EV011782: Door part"
  },
  {
    valueId: "EV011783",
    valueDesc: "Screened cables",
    label: "EV011783: Screened cables"
  },
  {
    valueId: "EV011784",
    valueDesc: "Overhead line connection",
    label: "EV011784: Overhead line connection"
  },
  {
    valueId: "EV011785",
    valueDesc: "Earthing conductor",
    label: "EV011785: Earthing conductor"
  },
  {
    valueId: "EV011786",
    valueDesc: "Straightening tool",
    label: "EV011786: Straightening tool"
  },
  {
    valueId: "EV011787",
    valueDesc: "Dowel for rigid foam plates",
    label: "EV011787: Dowel for rigid foam plates"
  },
  {
    valueId: "EV011788",
    valueDesc: "Heat-shrink tubing",
    label: "EV011788: Heat-shrink tubing"
  },
  {
    valueId: "EV011789",
    valueDesc: "Rivet",
    label: "EV011789: Rivet"
  },
  {
    valueId: "EV011790",
    valueDesc: "Bracket clamp",
    label: "EV011790: Bracket clamp"
  },
  {
    valueId: "EV011791",
    valueDesc: "Connection plate",
    label: "EV011791: Connection plate"
  },
  {
    valueId: "EV011792",
    valueDesc: "Clamping bell",
    label: "EV011792: Clamping bell"
  },
  {
    valueId: "EV011793",
    valueDesc: "Eave support",
    label: "EV011793: Eave support"
  },
  {
    valueId: "EV011794",
    valueDesc: "Grip head",
    label: "EV011794: Grip head"
  },
  {
    valueId: "EV011795",
    valueDesc: "Sealing gaiter",
    label: "EV011795: Sealing gaiter"
  },
  {
    valueId: "EV011796",
    valueDesc: "Saddle roof",
    label: "EV011796: Saddle roof"
  },
  {
    valueId: "EV011797",
    valueDesc: "Peaked roof",
    label: "EV011797: Peaked roof"
  },
  {
    valueId: "EV011798",
    valueDesc: "Stainless steel 316 (V4A), coppered",
    label: "EV011798: Stainless steel 316 (V4A), coppered"
  },
  {
    valueId: "EV011799",
    valueDesc: "Round conductor Rd 8-10 / flat conductor Fl 20-40",
    label: "EV011799: Round conductor Rd 8-10 / flat conductor Fl 20-40"
  },
  {
    valueId: "EV011800",
    valueDesc: "Connection component",
    label: "EV011800: Connection component"
  },
  {
    valueId: "EV011802",
    valueDesc: "Separating module",
    label: "EV011802: Separating module"
  },
  {
    valueId: "EV011803",
    valueDesc: "Anchor cage",
    label: "EV011803: Anchor cage"
  },
  {
    valueId: "EV011804",
    valueDesc: "Connection bracket",
    label: "EV011804: Connection bracket"
  },
  {
    valueId: "EV011805",
    valueDesc: "Spring ring",
    label: "EV011805: Spring ring"
  },
  {
    valueId: "EV011806",
    valueDesc: "Pressure plate",
    label: "EV011806: Pressure plate"
  },
  {
    valueId: "EV011807",
    valueDesc: "Flat washer",
    label: "EV011807: Flat washer"
  },
  {
    valueId: "EV011808",
    valueDesc: "Insert hook",
    label: "EV011808: Insert hook"
  },
  {
    valueId: "EV011809",
    valueDesc: "Earthing handle",
    label: "EV011809: Earthing handle"
  },
  {
    valueId: "EV011810",
    valueDesc: "Test rods for phase comparator",
    label: "EV011810: Test rods for phase comparator"
  },
  {
    valueId: "EV011811",
    valueDesc: "Switching stick head",
    label: "EV011811: Switching stick head"
  },
  {
    valueId: "EV011812",
    valueDesc: "Assembly support",
    label: "EV011812: Assembly support"
  },
  {
    valueId: "EV011813",
    valueDesc: "Contacting aid for operating head",
    label: "EV011813: Contacting aid for operating head"
  },
  {
    valueId: "EV011814",
    valueDesc: "Spare parts for cleaning set",
    label: "EV011814: Spare parts for cleaning set"
  },
  {
    valueId: "EV011815",
    valueDesc: "Insulating part",
    label: "EV011815: Insulating part"
  },
  {
    valueId: "EV011817",
    valueDesc: "Display device",
    label: "EV011817: Display device"
  },
  {
    valueId: "EV011818",
    valueDesc: "Test prod",
    label: "EV011818: Test prod"
  },
  {
    valueId: "EV011819",
    valueDesc: "Terminal part",
    label: "EV011819: Terminal part"
  },
  {
    valueId: "EV011820",
    valueDesc: "Electrode",
    label: "EV011820: Electrode"
  },
  {
    valueId: "EV011821",
    valueDesc: "Fall protection",
    label: "EV011821: Fall protection"
  },
  {
    valueId: "EV011822",
    valueDesc: "Spare parts for refilling device",
    label: "EV011822: Spare parts for refilling device"
  },
  {
    valueId: "EV011823",
    valueDesc: "Protective clothes",
    label: "EV011823: Protective clothes"
  },
  {
    valueId: "EV011824",
    valueDesc: "Fixed clamping point for busbars",
    label: "EV011824: Fixed clamping point for busbars"
  },
  {
    valueId: "EV011825",
    valueDesc: "NH1-3",
    label: "EV011825: NH1-3"
  },
  {
    valueId: "EV011826",
    valueDesc: "E33",
    label: "EV011826: E33"
  },
  {
    valueId: "EV011827",
    valueDesc: "Camera",
    label: "EV011827: Camera"
  },
  {
    valueId: "EV011830",
    valueDesc: "Plastic glass",
    label: "EV011830: Plastic glass"
  },
  {
    valueId: "EV011831",
    valueDesc: "Oxidised",
    label: "EV011831: Oxidised"
  },
  {
    valueId: "EV011832",
    valueDesc: "Modular device for domestic switching devices",
    label: "EV011832: Modular device for domestic switching devices"
  },
  {
    valueId: "EV011834",
    valueDesc: "Apple 30-pin (till 4th generation)",
    label: "EV011834: Apple 30-pin (till 4th generation)"
  },
  {
    valueId: "EV011835",
    valueDesc: "Apple Lightning (from 5th generation)",
    label: "EV011835: Apple Lightning (from 5th generation)"
  },
  {
    valueId: "EV011836",
    valueDesc: "Breakout cable",
    label: "EV011836: Breakout cable"
  },
  {
    valueId: "EV011837",
    valueDesc: "Assembly kit",
    label: "EV011837: Assembly kit"
  },
  {
    valueId: "EV011838",
    valueDesc: "Individual part",
    label: "EV011838: Individual part"
  },
  {
    valueId: "EV011839",
    valueDesc: "Top side front",
    label: "EV011839: Top side front"
  },
  {
    valueId: "EV011840",
    valueDesc: "Above rear",
    label: "EV011840: Above rear"
  },
  {
    valueId: "EV011841",
    valueDesc: "Above centre",
    label: "EV011841: Above centre"
  },
  {
    valueId: "EV011842",
    valueDesc: "Centre central",
    label: "EV011842: Centre central"
  },
  {
    valueId: "EV011843",
    valueDesc: "Centre rear",
    label: "EV011843: Centre rear"
  },
  {
    valueId: "EV011844",
    valueDesc: "Bottom centre",
    label: "EV011844: Bottom centre"
  },
  {
    valueId: "EV011845",
    valueDesc: "Bottom front",
    label: "EV011845: Bottom front"
  },
  {
    valueId: "EV011846",
    valueDesc: "Bottom rear",
    label: "EV011846: Bottom rear"
  },
  {
    valueId: "EV011854",
    valueDesc: "Mounting accessory",
    label: "EV011854: Mounting accessory"
  },
  {
    valueId: "EV011858",
    valueDesc: "Control device",
    label: "EV011858: Control device"
  },
  {
    valueId: "EV011859",
    valueDesc: "IP51",
    label: "EV011859: IP51"
  },
  {
    valueId: "EV011870",
    valueDesc: "Vibration acceleration",
    label: "EV011870: Vibration acceleration"
  },
  {
    valueId: "EV011871",
    valueDesc: "Piezo-quartz recorder with integrated evaluation electronics",
    label: "EV011871: Piezo-quartz recorder with integrated evaluation electronics"
  },
  {
    valueId: "EV011872",
    valueDesc: "Piezo-quartz recorder",
    label: "EV011872: Piezo-quartz recorder"
  },
  {
    valueId: "EV011873",
    valueDesc: "Multiplexer of analogue signals",
    label: "EV011873: Multiplexer of analogue signals"
  },
  {
    valueId: "EV011874",
    valueDesc: "Piezo-electrical sensor",
    label: "EV011874: Piezo-electrical sensor"
  },
  {
    valueId: "EV011875",
    valueDesc: "Micro-electromechanically sensor",
    label: "EV011875: Micro-electromechanically sensor"
  },
  {
    valueId: "EV011876",
    valueDesc: "IEPE",
    label: "EV011876: IEPE"
  },
  {
    valueId: "EV011877",
    valueDesc: "MEMS",
    label: "EV011877: MEMS"
  },
  {
    valueId: "EV011878",
    valueDesc: "Via basic device",
    label: "EV011878: Via basic device"
  },
  {
    valueId: "EV011879",
    valueDesc: "GR10q",
    label: "EV011879: GR10q"
  },
  {
    valueId: "EV011880",
    valueDesc: "Swimming pool",
    label: "EV011880: Swimming pool"
  },
  {
    valueId: "EV011881",
    valueDesc: "UV-A",
    label: "EV011881: UV-A"
  },
  {
    valueId: "EV011882",
    valueDesc: "UV-B",
    label: "EV011882: UV-B"
  },
  {
    valueId: "EV011883",
    valueDesc: "UV-C",
    label: "EV011883: UV-C"
  },
  {
    valueId: "EV011885",
    valueDesc: "SE (2-pins)",
    label: "EV011885: SE (2-pins)"
  },
  {
    valueId: "EV011886",
    valueDesc: "SE (4-pins)",
    label: "EV011886: SE (4-pins)"
  },
  {
    valueId: "EV011887",
    valueDesc: "DE (4-pins)",
    label: "EV011887: DE (4-pins)"
  },
  {
    valueId: "EV011888",
    valueDesc: "C10.5L",
    label: "EV011888: C10.5L"
  },
  {
    valueId: "EV011889",
    valueDesc: "C10.5s",
    label: "EV011889: C10.5s"
  },
  {
    valueId: "EV011890",
    valueDesc: "C14X",
    label: "EV011890: C14X"
  },
  {
    valueId: "EV011891",
    valueDesc: "C22",
    label: "EV011891: C22"
  },
  {
    valueId: "EV011892",
    valueDesc: "CU (socket)",
    label: "EV011892: CU (socket)"
  },
  {
    valueId: "EV011894",
    valueDesc: "G10.2q",
    label: "EV011894: G10.2q"
  },
  {
    valueId: "EV011895",
    valueDesc: "G17x10",
    label: "EV011895: G17x10"
  },
  {
    valueId: "EV011896",
    valueDesc: "G5.4x17q",
    label: "EV011896: G5.4x17q"
  },
  {
    valueId: "EV011897",
    valueDesc: "GX23",
    label: "EV011897: GX23"
  },
  {
    valueId: "EV011898",
    valueDesc: "Reflector E19.5",
    label: "EV011898: Reflector E19.5"
  },
  {
    valueId: "EV011899",
    valueDesc: "Reflector E21.9",
    label: "EV011899: Reflector E21.9"
  },
  {
    valueId: "EV011900",
    valueDesc: "RX15sp",
    label: "EV011900: RX15sp"
  },
  {
    valueId: "EV011901",
    valueDesc: "Single Pin",
    label: "EV011901: Single Pin"
  },
  {
    valueId: "EV011902",
    valueDesc: "SK32",
    label: "EV011902: SK32"
  },
  {
    valueId: "EV011906",
    valueDesc: "Compact Power Lamp (CPL)",
    label: "EV011906: Compact Power Lamp (CPL)"
  },
  {
    valueId: "EV011907",
    valueDesc: "BA10s",
    label: "EV011907: BA10s"
  },
  {
    valueId: "EV011908",
    valueDesc: "MG",
    label: "EV011908: MG"
  },
  {
    valueId: "EV011922",
    valueDesc: "Optical accessory",
    label: "EV011922: Optical accessory"
  },
  {
    valueId: "EV011923",
    valueDesc: "Jacket",
    label: "EV011923: Jacket"
  },
  {
    valueId: "EV011924",
    valueDesc: "LR",
    label: "EV011924: LR"
  },
  {
    valueId: "EV011925",
    valueDesc: "Fundament",
    label: "EV011925: Fundament"
  },
  {
    valueId: "EV011930",
    valueDesc: "Shield terminal",
    label: "EV011930: Shield terminal"
  },
  {
    valueId: "EV011934",
    valueDesc: "free@home",
    label: "EV011934: free@home"
  },
  {
    valueId: "EV011935",
    valueDesc: "Gender changer",
    label: "EV011935: Gender changer"
  },
  {
    valueId: "EV011937",
    valueDesc: "With accessory unit",
    label: "EV011937: With accessory unit"
  },
  {
    valueId: "EV011938",
    valueDesc: "One-way communication",
    label: "EV011938: One-way communication"
  },
  {
    valueId: "EV011939",
    valueDesc: "Two-way communication",
    label: "EV011939: Two-way communication"
  },
  {
    valueId: "EV011940",
    valueDesc: "Call",
    label: "EV011940: Call"
  },
  {
    valueId: "EV011941",
    valueDesc: "Presence",
    label: "EV011941: Presence"
  },
  {
    valueId: "EV011942",
    valueDesc: "Emergency call",
    label: "EV011942: Emergency call"
  },
  {
    valueId: "EV011943",
    valueDesc: "Bed call",
    label: "EV011943: Bed call"
  },
  {
    valueId: "EV011944",
    valueDesc: "Toilet call",
    label: "EV011944: Toilet call"
  },
  {
    valueId: "EV011945",
    valueDesc: "Gateway",
    label: "EV011945: Gateway"
  },
  {
    valueId: "EV011946",
    valueDesc: "Area coupler",
    label: "EV011946: Area coupler"
  },
  {
    valueId: "EV011947",
    valueDesc: "Quit call",
    label: "EV011947: Quit call"
  },
  {
    valueId: "EV011948",
    valueDesc: "Activate call",
    label: "EV011948: Activate call"
  },
  {
    valueId: "EV011949",
    valueDesc: "Activate/quit call",
    label: "EV011949: Activate/quit call"
  },
  {
    valueId: "EV011950",
    valueDesc: "Pear push button",
    label: "EV011950: Pear push button"
  },
  {
    valueId: "EV011951",
    valueDesc: "Diagnostic device",
    label: "EV011951: Diagnostic device"
  },
  {
    valueId: "EV011953",
    valueDesc: "Universal and LED Retrofit",
    label: "EV011953: Universal and LED Retrofit"
  },
  {
    valueId: "EV011954",
    valueDesc: "Energy and weather station",
    label: "EV011954: Energy and weather station"
  },
  {
    valueId: "EV011955",
    valueDesc: "Adapter for electronic residential meter",
    label: "EV011955: Adapter for electronic residential meter"
  },
  {
    valueId: "EV011956",
    valueDesc: "LAN adapter",
    label: "EV011956: LAN adapter"
  },
  {
    valueId: "EV011957",
    valueDesc: "Energy adapter",
    label: "EV011957: Energy adapter"
  },
  {
    valueId: "EV011958",
    valueDesc: "Power supply unit",
    label: "EV011958: Power supply unit"
  },
  {
    valueId: "EV011959",
    valueDesc: "Power meter",
    label: "EV011959: Power meter"
  },
  {
    valueId: "EV011960",
    valueDesc: "Gas meter",
    label: "EV011960: Gas meter"
  },
  {
    valueId: "EV011961",
    valueDesc: "Ambient air sensor CO2",
    label: "EV011961: Ambient air sensor CO2"
  },
  {
    valueId: "EV011963",
    valueDesc: "Both sides open",
    label: "EV011963: Both sides open"
  },
  {
    valueId: "EV011964",
    valueDesc: "One side open",
    label: "EV011964: One side open"
  },
  {
    valueId: "EV011965",
    valueDesc: "Brass-plated",
    label: "EV011965: Brass-plated"
  },
  {
    valueId: "EV011967",
    valueDesc: "Screw connector with shear-off head",
    label: "EV011967: Screw connector with shear-off head"
  },
  {
    valueId: "EV011968",
    valueDesc: "Press-/crimp connector",
    label: "EV011968: Press-/crimp connector"
  },
  {
    valueId: "EV011973",
    valueDesc: "M63 x 1.5",
    label: "EV011973: M63 x 1.5"
  },
  {
    valueId: "EV011974",
    valueDesc: "Three conductor/four conductor",
    label: "EV011974: Three conductor/four conductor"
  },
  {
    valueId: "EV011976",
    valueDesc: "Without locking",
    label: "EV011976: Without locking"
  },
  {
    valueId: "EV011978",
    valueDesc: "Insulation piercing connector IPC",
    label: "EV011978: Insulation piercing connector IPC"
  },
  {
    valueId: "EV011979",
    valueDesc: "Integrated in plug-in connector",
    label: "EV011979: Integrated in plug-in connector"
  },
  {
    valueId: "EV011980",
    valueDesc: "L, N, PE, D1, D2",
    label: "EV011980: L, N, PE, D1, D2"
  },
  {
    valueId: "EV011981",
    valueDesc: "With separate adapter",
    label: "EV011981: With separate adapter"
  },
  {
    valueId: "EV011982",
    valueDesc: "H03VV-F",
    label: "EV011982: H03VV-F"
  },
  {
    valueId: "EV011983",
    valueDesc: "H03V2V2-F",
    label: "EV011983: H03V2V2-F"
  },
  {
    valueId: "EV011984",
    valueDesc: "Partial stripped",
    label: "EV011984: Partial stripped"
  },
  {
    valueId: "EV011985",
    valueDesc: "Skinned",
    label: "EV011985: Skinned"
  },
  {
    valueId: "EV011986",
    valueDesc: "Switching distributor",
    label: "EV011986: Switching distributor"
  },
  {
    valueId: "EV011987",
    valueDesc: "IEEE 802.11 i",
    label: "EV011987: IEEE 802.11 i"
  },
  {
    valueId: "EV011988",
    valueDesc: "WPA",
    label: "EV011988: WPA"
  },
  {
    valueId: "EV011989",
    valueDesc: "WPA2",
    label: "EV011989: WPA2"
  },
  {
    valueId: "EV011990",
    valueDesc: "WEP",
    label: "EV011990: WEP"
  },
  {
    valueId: "EV011991",
    valueDesc: "IEEE 802.11 a/c",
    label: "EV011991: IEEE 802.11 a/c"
  },
  {
    valueId: "EV011992",
    valueDesc: "IEEE 802.11 a/d",
    label: "EV011992: IEEE 802.11 a/d"
  },
  {
    valueId: "EV011993",
    valueDesc: "2.4 GHz",
    label: "EV011993: 2.4 GHz"
  },
  {
    valueId: "EV011994",
    valueDesc: "5 GHz",
    label: "EV011994: 5 GHz"
  },
  {
    valueId: "EV011995",
    valueDesc: "2.4 GHz and 5 GHz",
    label: "EV011995: 2.4 GHz and 5 GHz"
  },
  {
    valueId: "EV011996",
    valueDesc: "2.4 GHz or 5 GHz",
    label: "EV011996: 2.4 GHz or 5 GHz"
  },
  {
    valueId: "EV011997",
    valueDesc: "Pole mounting",
    label: "EV011997: Pole mounting"
  },
  {
    valueId: "EV011998",
    valueDesc: "IPv4",
    label: "EV011998: IPv4"
  },
  {
    valueId: "EV011999",
    valueDesc: "IPv6",
    label: "EV011999: IPv6"
  },
  {
    valueId: "EV012000",
    valueDesc: "IPv4 and IPv6",
    label: "EV012000: IPv4 and IPv6"
  },
  {
    valueId: "EV012001",
    valueDesc: "802.3 af (PoE)",
    label: "EV012001: 802.3 af (PoE)"
  },
  {
    valueId: "EV012002",
    valueDesc: "802.3 at (PoE+)",
    label: "EV012002: 802.3 at (PoE+)"
  },
  {
    valueId: "EV012003",
    valueDesc: "Via a/b-port",
    label: "EV012003: Via a/b-port"
  },
  {
    valueId: "EV012004",
    valueDesc: "Via LAN (IP)",
    label: "EV012004: Via LAN (IP)"
  },
  {
    valueId: "EV012005",
    valueDesc: "Fixed connection",
    label: "EV012005: Fixed connection"
  },
  {
    valueId: "EV012010",
    valueDesc: "Band loop",
    label: "EV012010: Band loop"
  },
  {
    valueId: "EV012011",
    valueDesc: "10 mm (3/8 inch)",
    label: "EV012011: 10 mm (3/8 inch)"
  },
  {
    valueId: "EV012012",
    valueDesc: "12.5 mm (1/2 inch)",
    label: "EV012012: 12.5 mm (1/2 inch)"
  },
  {
    valueId: "EV012013",
    valueDesc: "20 mm (3/4 inch)",
    label: "EV012013: 20 mm (3/4 inch)"
  },
  {
    valueId: "EV012014",
    valueDesc: "25 mm (1 inch)",
    label: "EV012014: 25 mm (1 inch)"
  },
  {
    valueId: "EV012015",
    valueDesc: "6.3 mm (1/4 inch)",
    label: "EV012015: 6.3 mm (1/4 inch)"
  },
  {
    valueId: "EV012016",
    valueDesc: "EU10 (Ceramic bar)",
    label: "EV012016: EU10 (Ceramic bar)"
  },
  {
    valueId: "EV012017",
    valueDesc: "K-FIT",
    label: "EV012017: K-FIT"
  },
  {
    valueId: "EV012018",
    valueDesc: "GX58",
    label: "EV012018: GX58"
  },
  {
    valueId: "EV012019",
    valueDesc: "GZZ9.5",
    label: "EV012019: GZZ9.5"
  },
  {
    valueId: "EV012020",
    valueDesc: "ID3000",
    label: "EV012020: ID3000"
  },
  {
    valueId: "EV012021",
    valueDesc: "SID",
    label: "EV012021: SID"
  },
  {
    valueId: "EV012022",
    valueDesc: "Heat Sink",
    label: "EV012022: Heat Sink"
  },
  {
    valueId: "EV012023",
    valueDesc: "PGJ41",
    label: "EV012023: PGJ41"
  },
  {
    valueId: "EV012024",
    valueDesc: "PGJX28",
    label: "EV012024: PGJX28"
  },
  {
    valueId: "EV012025",
    valueDesc: "PGJX36",
    label: "EV012025: PGJX36"
  },
  {
    valueId: "EV012026",
    valueDesc: "SFC10-4",
    label: "EV012026: SFC10-4"
  },
  {
    valueId: "EV012027",
    valueDesc: "SFC-11",
    label: "EV012027: SFC-11"
  },
  {
    valueId: "EV012028",
    valueDesc: "SFC15.5-6",
    label: "EV012028: SFC15.5-6"
  },
  {
    valueId: "EV012029",
    valueDesc: "Side contacts",
    label: "EV012029: Side contacts"
  },
  {
    valueId: "EV012030",
    valueDesc: "h90",
    label: "EV012030: h90"
  },
  {
    valueId: "EV012031",
    valueDesc: "Electrode Rod-Coil",
    label: "EV012031: Electrode Rod-Coil"
  },
  {
    valueId: "EV012032",
    valueDesc: "Cable cleaning set",
    label: "EV012032: Cable cleaning set"
  },
  {
    valueId: "EV012033",
    valueDesc: "Orange/black",
    label: "EV012033: Orange/black"
  },
  {
    valueId: "EV012034",
    valueDesc: "Wastewater",
    label: "EV012034: Wastewater"
  },
  {
    valueId: "EV012035",
    valueDesc: "Noise insulation",
    label: "EV012035: Noise insulation"
  },
  {
    valueId: "EV012036",
    valueDesc: "Fire protection",
    label: "EV012036: Fire protection"
  },
  {
    valueId: "EV012037",
    valueDesc: "Radiation protection",
    label: "EV012037: Radiation protection"
  },
  {
    valueId: "EV012038",
    valueDesc: "Back vacuum cleaner",
    label: "EV012038: Back vacuum cleaner"
  },
  {
    valueId: "EV012039",
    valueDesc: "Hip vacuum cleaner",
    label: "EV012039: Hip vacuum cleaner"
  },
  {
    valueId: "EV012040",
    valueDesc: "Evaluation unit",
    label: "EV012040: Evaluation unit"
  },
  {
    valueId: "EV012041",
    valueDesc: "Frosted",
    label: "EV012041: Frosted"
  },
  {
    valueId: "EV012042",
    valueDesc: "PC/ABS",
    label: "EV012042: PC/ABS"
  },
  {
    valueId: "EV012043",
    valueDesc: "Counter plate for end piece",
    label: "EV012043: Counter plate for end piece"
  },
  {
    valueId: "EV012044",
    valueDesc: "Counter plate for outer corner",
    label: "EV012044: Counter plate for outer corner"
  },
  {
    valueId: "EV012045",
    valueDesc: "Counter plate for flat bend",
    label: "EV012045: Counter plate for flat bend"
  },
  {
    valueId: "EV012046",
    valueDesc: "Counter plate for inner corner",
    label: "EV012046: Counter plate for inner corner"
  },
  {
    valueId: "EV012047",
    valueDesc: "Counter plate for cross piece",
    label: "EV012047: Counter plate for cross piece"
  },
  {
    valueId: "EV012048",
    valueDesc: "Counter plate for coupler",
    label: "EV012048: Counter plate for coupler"
  },
  {
    valueId: "EV012049",
    valueDesc: "Counter plate for tee",
    label: "EV012049: Counter plate for tee"
  },
  {
    valueId: "EV012050",
    valueDesc: "For distance mounting",
    label: "EV012050: For distance mounting"
  },
  {
    valueId: "EV012051",
    valueDesc: "Collision protector",
    label: "EV012051: Collision protector"
  },
  {
    valueId: "EV012052",
    valueDesc: "Slot nut",
    label: "EV012052: Slot nut"
  },
  {
    valueId: "EV012053",
    valueDesc: "Adapter stabilizer",
    label: "EV012053: Adapter stabilizer"
  },
  {
    valueId: "EV012054",
    valueDesc: "Rubber base",
    label: "EV012054: Rubber base"
  },
  {
    valueId: "EV012055",
    valueDesc: "Half-wave control",
    label: "EV012055: Half-wave control"
  },
  {
    valueId: "EV012058",
    valueDesc: "Sub frame",
    label: "EV012058: Sub frame"
  },
  {
    valueId: "EV012059",
    valueDesc: "Semiconductor output",
    label: "EV012059: Semiconductor output"
  },
  {
    valueId: "EV012060",
    valueDesc: "Relay output",
    label: "EV012060: Relay output"
  },
  {
    valueId: "EV012061",
    valueDesc: "Current and voltage recording module",
    label: "EV012061: Current and voltage recording module"
  },
  {
    valueId: "EV012062",
    valueDesc: "Voltage recording module",
    label: "EV012062: Voltage recording module"
  },
  {
    valueId: "EV012063",
    valueDesc: "Current recording module",
    label: "EV012063: Current recording module"
  },
  {
    valueId: "EV012064",
    valueDesc: "Decouple module",
    label: "EV012064: Decouple module"
  },
  {
    valueId: "EV012065",
    valueDesc: "Typical +/-1 K",
    label: "EV012065: Typical +/-1 K"
  },
  {
    valueId: "EV012066",
    valueDesc: "Ventilator module for industrial heating control systems",
    label: "EV012066: Ventilator module for industrial heating control systems"
  },
  {
    valueId: "EV012067",
    valueDesc: "Service tool",
    label: "EV012067: Service tool"
  },
  {
    valueId: "EV012068",
    valueDesc: "Software kit",
    label: "EV012068: Software kit"
  },
  {
    valueId: "EV012069",
    valueDesc: "Deflector unit",
    label: "EV012069: Deflector unit"
  },
  {
    valueId: "EV012070",
    valueDesc: "Door clutch holder",
    label: "EV012070: Door clutch holder"
  },
  {
    valueId: "EV012071",
    valueDesc: "Rubber-metal anti-vibration mount",
    label: "EV012071: Rubber-metal anti-vibration mount"
  },
  {
    valueId: "EV012072",
    valueDesc: "Toothed belt",
    label: "EV012072: Toothed belt"
  },
  {
    valueId: "EV012073",
    valueDesc: "Mounting rail holder",
    label: "EV012073: Mounting rail holder"
  },
  {
    valueId: "EV012074",
    valueDesc: "Threaded rod set",
    label: "EV012074: Threaded rod set"
  },
  {
    valueId: "EV012075",
    valueDesc: "Signing plate",
    label: "EV012075: Signing plate"
  },
  {
    valueId: "EV012076",
    valueDesc: "Tension band",
    label: "EV012076: Tension band"
  },
  {
    valueId: "EV012077",
    valueDesc: "Insulator",
    label: "EV012077: Insulator"
  },
  {
    valueId: "EV012078",
    valueDesc: "Event counter",
    label: "EV012078: Event counter"
  },
  {
    valueId: "EV012079",
    valueDesc: "With fibre optic cable",
    label: "EV012079: With fibre optic cable"
  },
  {
    valueId: "EV012081",
    valueDesc: "1 x 1 inch",
    label: "EV012081: 1 x 1 inch"
  },
  {
    valueId: "EV012082",
    valueDesc: "2 x 1 inch",
    label: "EV012082: 2 x 1 inch"
  },
  {
    valueId: "EV012083",
    valueDesc: "1 x 2 inch",
    label: "EV012083: 1 x 2 inch"
  },
  {
    valueId: "EV012084",
    valueDesc: "2 x 2 inch",
    label: "EV012084: 2 x 2 inch"
  },
  {
    valueId: "EV012085",
    valueDesc: "ISO 15693",
    label: "EV012085: ISO 15693"
  },
  {
    valueId: "EV012086",
    valueDesc: "ISO 18000-3",
    label: "EV012086: ISO 18000-3"
  },
  {
    valueId: "EV012087",
    valueDesc: "ISO 18000-6C",
    label: "EV012087: ISO 18000-6C"
  },
  {
    valueId: "EV012088",
    valueDesc: "EPCglobal Class-1 Gen 2",
    label: "EV012088: EPCglobal Class-1 Gen 2"
  },
  {
    valueId: "EV012089",
    valueDesc: "FRAM",
    label: "EV012089: FRAM"
  },
  {
    valueId: "EV012090",
    valueDesc: "FRAM/EEPROM",
    label: "EV012090: FRAM/EEPROM"
  },
  {
    valueId: "EV012091",
    valueDesc: "IP60",
    label: "EV012091: IP60"
  },
  {
    valueId: "EV012093",
    valueDesc: "Documentation",
    label: "EV012093: Documentation"
  },
  {
    valueId: "EV012094",
    valueDesc: "Quick-change holder",
    label: "EV012094: Quick-change holder"
  },
  {
    valueId: "EV012098",
    valueDesc: "Hose connection",
    label: "EV012098: Hose connection"
  },
  {
    valueId: "EV012175",
    valueDesc: "R 3/4 inch",
    label: "EV012175: R 3/4 inch"
  },
  {
    valueId: "EV012176",
    valueDesc: "R 1 inch",
    label: "EV012176: R 1 inch"
  },
  {
    valueId: "EV012194",
    valueDesc: "Compact version, sensor integrated",
    label: "EV012194: Compact version, sensor integrated"
  },
  {
    valueId: "EV012195",
    valueDesc: "Separated version, sensor separated",
    label: "EV012195: Separated version, sensor separated"
  },
  {
    valueId: "EV012196",
    valueDesc: "Separated version, transmitter separated",
    label: "EV012196: Separated version, transmitter separated"
  },
  {
    valueId: "EV012197",
    valueDesc: "Compact version, transmitter integrated",
    label: "EV012197: Compact version, transmitter integrated"
  },
  {
    valueId: "EV012198",
    valueDesc: "Two chamber housing",
    label: "EV012198: Two chamber housing"
  },
  {
    valueId: "EV012199",
    valueDesc: "Single chamber housing",
    label: "EV012199: Single chamber housing"
  },
  {
    valueId: "EV012200",
    valueDesc: "24 inch",
    label: "EV012200: 24 inch"
  },
  {
    valueId: "EV012234",
    valueDesc: "With flange cover",
    label: "EV012234: With flange cover"
  },
  {
    valueId: "EV012235",
    valueDesc: "With hinged cover, low",
    label: "EV012235: With hinged cover, low"
  },
  {
    valueId: "EV012236",
    valueDesc: "With hinged cover, high",
    label: "EV012236: With hinged cover, high"
  },
  {
    valueId: "EV012237",
    valueDesc: "With screw cap",
    label: "EV012237: With screw cap"
  },
  {
    valueId: "EV012239",
    valueDesc: "Slated roof",
    label: "EV012239: Slated roof"
  },
  {
    valueId: "EV012249",
    valueDesc: "Hollow wall set",
    label: "EV012249: Hollow wall set"
  },
  {
    valueId: "EV012250",
    valueDesc: "Text/symbols",
    label: "EV012250: Text/symbols"
  },
  {
    valueId: "EV012251",
    valueDesc: "Robot vacuum cleaner",
    label: "EV012251: Robot vacuum cleaner"
  },
  {
    valueId: "EV012252",
    valueDesc: "Table vacuum cleaner",
    label: "EV012252: Table vacuum cleaner"
  },
  {
    valueId: "EV012254",
    valueDesc: "For earth leakage switch type A, F",
    label: "EV012254: For earth leakage switch type A, F"
  },
  {
    valueId: "EV012255",
    valueDesc: "For earth leakage switch type A, F and type B, B+",
    label: "EV012255: For earth leakage switch type A, F and type B, B+"
  },
  {
    valueId: "EV012256",
    valueDesc: "0.0001 mV",
    label: "EV012256: 0.0001 mV"
  },
  {
    valueId: "EV012257",
    valueDesc: "0.001 µA",
    label: "EV012257: 0.001 µA"
  },
  {
    valueId: "EV012258",
    valueDesc: "0.0001 µA",
    label: "EV012258: 0.0001 µA"
  },
  {
    valueId: "EV012259",
    valueDesc: "0.0001 Ohm",
    label: "EV012259: 0.0001 Ohm"
  },
  {
    valueId: "EV012260",
    valueDesc: "0.001 Ohm",
    label: "EV012260: 0.001 Ohm"
  },
  {
    valueId: "EV012261",
    valueDesc: "0.001 mA",
    label: "EV012261: 0.001 mA"
  },
  {
    valueId: "EV012262",
    valueDesc: "0.01 mA",
    label: "EV012262: 0.01 mA"
  },
  {
    valueId: "EV012263",
    valueDesc: "0.1 mA",
    label: "EV012263: 0.1 mA"
  },
  {
    valueId: "EV012264",
    valueDesc: "Shower floor carrier",
    label: "EV012264: Shower floor carrier"
  },
  {
    valueId: "EV012265",
    valueDesc: "Elevation element",
    label: "EV012265: Elevation element"
  },
  {
    valueId: "EV012269",
    valueDesc: "Press ring seal",
    label: "EV012269: Press ring seal"
  },
  {
    valueId: "EV012272",
    valueDesc: "Fire protection switch",
    label: "EV012272: Fire protection switch"
  },
  {
    valueId: "EV012273",
    valueDesc: "Connection piece for switch operating shaft",
    label: "EV012273: Connection piece for switch operating shaft"
  },
  {
    valueId: "EV012274",
    valueDesc: "Floor installation",
    label: "EV012274: Floor installation"
  },
  {
    valueId: "EV012275",
    valueDesc: "Segment saw blade",
    label: "EV012275: Segment saw blade"
  },
  {
    valueId: "EV012276",
    valueDesc: "Plunge-cutting saw blade",
    label: "EV012276: Plunge-cutting saw blade"
  },
  {
    valueId: "EV012277",
    valueDesc: "Collection bag",
    label: "EV012277: Collection bag"
  },
  {
    valueId: "EV012279",
    valueDesc: "Omnidirectional",
    label: "EV012279: Omnidirectional"
  },
  {
    valueId: "EV012280",
    valueDesc: "Holder/table top holder remote control",
    label: "EV012280: Holder/table top holder remote control"
  },
  {
    valueId: "EV012281",
    valueDesc: "Church pew heating",
    label: "EV012281: Church pew heating"
  },
  {
    valueId: "EV012282",
    valueDesc: "Round/sector-shaped",
    label: "EV012282: Round/sector-shaped"
  },
  {
    valueId: "EV012283",
    valueDesc: "Mixing nozzle",
    label: "EV012283: Mixing nozzle"
  },
  {
    valueId: "EV012284",
    valueDesc: "Cartridge nozzle",
    label: "EV012284: Cartridge nozzle"
  },
  {
    valueId: "EV012285",
    valueDesc: "Working platform",
    label: "EV012285: Working platform"
  },
  {
    valueId: "EV012286",
    valueDesc: "Ladder foot",
    label: "EV012286: Ladder foot"
  },
  {
    valueId: "EV012287",
    valueDesc: "Ridge throw-over bow",
    label: "EV012287: Ridge throw-over bow"
  },
  {
    valueId: "EV012288",
    valueDesc: "Orange/yellow",
    label: "EV012288: Orange/yellow"
  },
  {
    valueId: "EV012289",
    valueDesc: "Windows 8",
    label: "EV012289: Windows 8"
  },
  {
    valueId: "EV012291",
    valueDesc: "B22s",
    label: "EV012291: B22s"
  },
  {
    valueId: "EV012293",
    valueDesc: "Straight pipe",
    label: "EV012293: Straight pipe"
  },
  {
    valueId: "EV012294",
    valueDesc: "Electronic ballast dimmable",
    label: "EV012294: Electronic ballast dimmable"
  },
  {
    valueId: "EV012299",
    valueDesc: "Connecting piece fin for wall duct",
    label: "EV012299: Connecting piece fin for wall duct"
  },
  {
    valueId: "EV012302",
    valueDesc: "Cu-DHP (CW024A)",
    label: "EV012302: Cu-DHP (CW024A)"
  },
  {
    valueId: "EV012303",
    valueDesc: "CuZn39Pb2 (CW612N)",
    label: "EV012303: CuZn39Pb2 (CW612N)"
  },
  {
    valueId: "EV012304",
    valueDesc: "Manilla",
    label: "EV012304: Manilla"
  },
  {
    valueId: "EV012305",
    valueDesc: "Floating ball",
    label: "EV012305: Floating ball"
  },
  {
    valueId: "EV012306",
    valueDesc: "Air bubble",
    label: "EV012306: Air bubble"
  },
  {
    valueId: "EV012307",
    valueDesc: "Horseshoe",
    label: "EV012307: Horseshoe"
  },
  {
    valueId: "EV012308",
    valueDesc: "Lead (wet cell, battery empty)",
    label: "EV012308: Lead (wet cell, battery empty)"
  },
  {
    valueId: "EV012309",
    valueDesc: "Lead (wet cell, battery filled)",
    label: "EV012309: Lead (wet cell, battery filled)"
  },
  {
    valueId: "EV012310",
    valueDesc: "Floatation suit",
    label: "EV012310: Floatation suit"
  },
  {
    valueId: "EV012311",
    valueDesc: "Pennant flag",
    label: "EV012311: Pennant flag"
  },
  {
    valueId: "EV012313",
    valueDesc: "Pipe and fastening profile",
    label: "EV012313: Pipe and fastening profile"
  },
  {
    valueId: "EV012323",
    valueDesc: "Casing",
    label: "EV012323: Casing"
  },
  {
    valueId: "EV012325",
    valueDesc: "Steel/brass",
    label: "EV012325: Steel/brass"
  },
  {
    valueId: "EV012331",
    valueDesc: "Cross piece 45°",
    label: "EV012331: Cross piece 45°"
  },
  {
    valueId: "EV012332",
    valueDesc: "Right-angled double T-piece 90°",
    label: "EV012332: Right-angled double T-piece 90°"
  },
  {
    valueId: "EV012333",
    valueDesc: "Right-angled double T-piece 45°",
    label: "EV012333: Right-angled double T-piece 45°"
  },
  {
    valueId: "EV012334",
    valueDesc: "#12",
    label: "EV012334: #12"
  },
  {
    valueId: "EV012335",
    valueDesc: "#18",
    label: "EV012335: #18"
  },
  {
    valueId: "EV012336",
    valueDesc: "#36",
    label: "EV012336: #36"
  },
  {
    valueId: "EV012337",
    valueDesc: "#48",
    label: "EV012337: #48"
  },
  {
    valueId: "EV012338",
    valueDesc: "Woven",
    label: "EV012338: Woven"
  },
  {
    valueId: "EV012339",
    valueDesc: "Non-woven",
    label: "EV012339: Non-woven"
  },
  {
    valueId: "EV012340",
    valueDesc: "Street drain",
    label: "EV012340: Street drain"
  },
  {
    valueId: "EV012341",
    valueDesc: "Kerb gully",
    label: "EV012341: Kerb gully"
  },
  {
    valueId: "EV012343",
    valueDesc: "Curved saucer drain gully",
    label: "EV012343: Curved saucer drain gully"
  },
  {
    valueId: "EV012344",
    valueDesc: "Closed (blind)",
    label: "EV012344: Closed (blind)"
  },
  {
    valueId: "EV012345",
    valueDesc: "Fan shape",
    label: "EV012345: Fan shape"
  },
  {
    valueId: "EV012346",
    valueDesc: "Top and side",
    label: "EV012346: Top and side"
  },
  {
    valueId: "EV012347",
    valueDesc: "PVC-A",
    label: "EV012347: PVC-A"
  },
  {
    valueId: "EV012348",
    valueDesc: "Polymer concrete",
    label: "EV012348: Polymer concrete"
  },
  {
    valueId: "EV012349",
    valueDesc: "PE-PP",
    label: "EV012349: PE-PP"
  },
  {
    valueId: "EV012350",
    valueDesc: "Slot grille",
    label: "EV012350: Slot grille"
  },
  {
    valueId: "EV012351",
    valueDesc: "Mesh grille",
    label: "EV012351: Mesh grille"
  },
  {
    valueId: "EV012352",
    valueDesc: "Hole grille",
    label: "EV012352: Hole grille"
  },
  {
    valueId: "EV012354",
    valueDesc: "Protective bracket",
    label: "EV012354: Protective bracket"
  },
  {
    valueId: "EV012355",
    valueDesc: "Drawn",
    label: "EV012355: Drawn"
  },
  {
    valueId: "EV012356",
    valueDesc: "Mirrored",
    label: "EV012356: Mirrored"
  },
  {
    valueId: "EV012357",
    valueDesc: "G 4/G 6",
    label: "EV012357: G 4/G 6"
  },
  {
    valueId: "EV012358",
    valueDesc: "G 10/G 16",
    label: "EV012358: G 10/G 16"
  },
  {
    valueId: "EV012359",
    valueDesc: "Qn 1.5",
    label: "EV012359: Qn 1.5"
  },
  {
    valueId: "EV012360",
    valueDesc: "Qn 2.5",
    label: "EV012360: Qn 2.5"
  },
  {
    valueId: "EV012361",
    valueDesc: "Qn 3.5",
    label: "EV012361: Qn 3.5"
  },
  {
    valueId: "EV012362",
    valueDesc: "Qn 5",
    label: "EV012362: Qn 5"
  },
  {
    valueId: "EV012363",
    valueDesc: "Qn 10",
    label: "EV012363: Qn 10"
  },
  {
    valueId: "EV012368",
    valueDesc: "1:2",
    label: "EV012368: 1:2"
  },
  {
    valueId: "EV012369",
    valueDesc: "1:3",
    label: "EV012369: 1:3"
  },
  {
    valueId: "EV012370",
    valueDesc: "2:3",
    label: "EV012370: 2:3"
  },
  {
    valueId: "EV012371",
    valueDesc: "Rubber ring culvert",
    label: "EV012371: Rubber ring culvert"
  },
  {
    valueId: "EV012372",
    valueDesc: "Coupling culvert",
    label: "EV012372: Coupling culvert"
  },
  {
    valueId: "EV012373",
    valueDesc: "Post-tensioned culvert",
    label: "EV012373: Post-tensioned culvert"
  },
  {
    valueId: "EV012379",
    valueDesc: "Slope tube",
    label: "EV012379: Slope tube"
  },
  {
    valueId: "EV012380",
    valueDesc: "2200 mm",
    label: "EV012380: 2200 mm"
  },
  {
    valueId: "EV012381",
    valueDesc: "250/375 mm",
    label: "EV012381: 250/375 mm"
  },
  {
    valueId: "EV012382",
    valueDesc: "300/450 mm",
    label: "EV012382: 300/450 mm"
  },
  {
    valueId: "EV012383",
    valueDesc: "400/600 mm",
    label: "EV012383: 400/600 mm"
  },
  {
    valueId: "EV012384",
    valueDesc: "500/750 mm",
    label: "EV012384: 500/750 mm"
  },
  {
    valueId: "EV012385",
    valueDesc: "600/900 mm",
    label: "EV012385: 600/900 mm"
  },
  {
    valueId: "EV012386",
    valueDesc: "700/1050 mm",
    label: "EV012386: 700/1050 mm"
  },
  {
    valueId: "EV012387",
    valueDesc: "800/1200 mm",
    label: "EV012387: 800/1200 mm"
  },
  {
    valueId: "EV012388",
    valueDesc: "900/1350 mm",
    label: "EV012388: 900/1350 mm"
  },
  {
    valueId: "EV012389",
    valueDesc: "1000/1500 mm",
    label: "EV012389: 1000/1500 mm"
  },
  {
    valueId: "EV012390",
    valueDesc: "Roller ring",
    label: "EV012390: Roller ring"
  },
  {
    valueId: "EV012391",
    valueDesc: "Sliding ring",
    label: "EV012391: Sliding ring"
  },
  {
    valueId: "EV012394",
    valueDesc: "Bending yoke",
    label: "EV012394: Bending yoke"
  },
  {
    valueId: "EV012395",
    valueDesc: "Bending die",
    label: "EV012395: Bending die"
  },
  {
    valueId: "EV012396",
    valueDesc: "Egg-shaped",
    label: "EV012396: Egg-shaped"
  },
  {
    valueId: "EV012400",
    valueDesc: "Plywood",
    label: "EV012400: Plywood"
  },
  {
    valueId: "EV012401",
    valueDesc: "Lauan",
    label: "EV012401: Lauan"
  },
  {
    valueId: "EV012402",
    valueDesc: "Mixed hardwood",
    label: "EV012402: Mixed hardwood"
  },
  {
    valueId: "EV012403",
    valueDesc: "Populus",
    label: "EV012403: Populus"
  },
  {
    valueId: "EV012408",
    valueDesc: "FSC",
    label: "EV012408: FSC"
  },
  {
    valueId: "EV012409",
    valueDesc: "MTCC",
    label: "EV012409: MTCC"
  },
  {
    valueId: "EV012410",
    valueDesc: "PEFC",
    label: "EV012410: PEFC"
  },
  {
    valueId: "EV012411",
    valueDesc: "Composed",
    label: "EV012411: Composed"
  },
  {
    valueId: "EV012412",
    valueDesc: "Aluminium screen",
    label: "EV012412: Aluminium screen"
  },
  {
    valueId: "EV012413",
    valueDesc: "Steel tube/pipe",
    label: "EV012413: Steel tube/pipe"
  },
  {
    valueId: "EV012414",
    valueDesc: "Okoumé",
    label: "EV012414: Okoumé"
  },
  {
    valueId: "EV012415",
    valueDesc: "Tricoya",
    label: "EV012415: Tricoya"
  },
  {
    valueId: "EV012416",
    valueDesc: "0: None",
    label: "EV012416: 0: None"
  },
  {
    valueId: "EV012417",
    valueDesc: "1: 0-8 mm",
    label: "EV012417: 1: 0-8 mm"
  },
  {
    valueId: "EV012418",
    valueDesc: "2: 0 - 4 mm",
    label: "EV012418: 2: 0 - 4 mm"
  },
  {
    valueId: "EV012419",
    valueDesc: "3: 0 - 2 mm",
    label: "EV012419: 3: 0 - 2 mm"
  },
  {
    valueId: "EV012421",
    valueDesc: "Paint foil (MDO)",
    label: "EV012421: Paint foil (MDO)"
  },
  {
    valueId: "EV012422",
    valueDesc: "Accoya",
    label: "EV012422: Accoya"
  },
  {
    valueId: "EV012423",
    valueDesc: "American oak",
    label: "EV012423: American oak"
  },
  {
    valueId: "EV012424",
    valueDesc: "Ekki",
    label: "EV012424: Ekki"
  },
  {
    valueId: "EV012425",
    valueDesc: "Opepe",
    label: "EV012425: Opepe"
  },
  {
    valueId: "EV012426",
    valueDesc: "European oak",
    label: "EV012426: European oak"
  },
  {
    valueId: "EV012427",
    valueDesc: "Iroko",
    label: "EV012427: Iroko"
  },
  {
    valueId: "EV012428",
    valueDesc: "Okan",
    label: "EV012428: Okan"
  },
  {
    valueId: "EV012429",
    valueDesc: "Oregon Pine",
    label: "EV012429: Oregon Pine"
  },
  {
    valueId: "EV012430",
    valueDesc: "Sapelli",
    label: "EV012430: Sapelli"
  },
  {
    valueId: "EV012431",
    valueDesc: "Sipo",
    label: "EV012431: Sipo"
  },
  {
    valueId: "EV012432",
    valueDesc: "Teak",
    label: "EV012432: Teak"
  },
  {
    valueId: "EV012433",
    valueDesc: "Shelled",
    label: "EV012433: Shelled"
  },
  {
    valueId: "EV012434",
    valueDesc: "C16",
    label: "EV012434: C16"
  },
  {
    valueId: "EV012435",
    valueDesc: "C18",
    label: "EV012435: C18"
  },
  {
    valueId: "EV012436",
    valueDesc: "C24",
    label: "EV012436: C24"
  },
  {
    valueId: "EV012437",
    valueDesc: "C27",
    label: "EV012437: C27"
  },
  {
    valueId: "EV012438",
    valueDesc: "C30",
    label: "EV012438: C30"
  },
  {
    valueId: "EV012439",
    valueDesc: "C40",
    label: "EV012439: C40"
  },
  {
    valueId: "EV012440",
    valueDesc: "C45",
    label: "EV012440: C45"
  },
  {
    valueId: "EV012441",
    valueDesc: "C50",
    label: "EV012441: C50"
  },
  {
    valueId: "EV012442",
    valueDesc: "D18",
    label: "EV012442: D18"
  },
  {
    valueId: "EV012443",
    valueDesc: "D24",
    label: "EV012443: D24"
  },
  {
    valueId: "EV012444",
    valueDesc: "D30",
    label: "EV012444: D30"
  },
  {
    valueId: "EV012445",
    valueDesc: "D35",
    label: "EV012445: D35"
  },
  {
    valueId: "EV012446",
    valueDesc: "D40",
    label: "EV012446: D40"
  },
  {
    valueId: "EV012447",
    valueDesc: "D50",
    label: "EV012447: D50"
  },
  {
    valueId: "EV012448",
    valueDesc: "D60",
    label: "EV012448: D60"
  },
  {
    valueId: "EV012449",
    valueDesc: "D70",
    label: "EV012449: D70"
  },
  {
    valueId: "EV012450",
    valueDesc: "C35",
    label: "EV012450: C35"
  },
  {
    valueId: "EV012451",
    valueDesc: "Laminated",
    label: "EV012451: Laminated"
  },
  {
    valueId: "EV012452",
    valueDesc: "Fingerjointed",
    label: "EV012452: Fingerjointed"
  },
  {
    valueId: "EV012453",
    valueDesc: "Fingerjointed/laminated",
    label: "EV012453: Fingerjointed/laminated"
  },
  {
    valueId: "EV012454",
    valueDesc: "Fine serrated",
    label: "EV012454: Fine serrated"
  },
  {
    valueId: "EV012455",
    valueDesc: "Architrave",
    label: "EV012455: Architrave"
  },
  {
    valueId: "EV012456",
    valueDesc: "Half round trim",
    label: "EV012456: Half round trim"
  },
  {
    valueId: "EV012457",
    valueDesc: "Wall-ending trim (GIBO)",
    label: "EV012457: Wall-ending trim (GIBO)"
  },
  {
    valueId: "EV012458",
    valueDesc: "Corner trim",
    label: "EV012458: Corner trim"
  },
  {
    valueId: "EV012459",
    valueDesc: "Concave trim",
    label: "EV012459: Concave trim"
  },
  {
    valueId: "EV012460",
    valueDesc: "Coving frame",
    label: "EV012460: Coving frame"
  },
  {
    valueId: "EV012461",
    valueDesc: "Head end bead",
    label: "EV012461: Head end bead"
  },
  {
    valueId: "EV012462",
    valueDesc: "Wall panelling frame",
    label: "EV012462: Wall panelling frame"
  },
  {
    valueId: "EV012463",
    valueDesc: "Laminated veneer lumber (LVL)",
    label: "EV012463: Laminated veneer lumber (LVL)"
  },
  {
    valueId: "EV012465",
    valueDesc: "With foil",
    label: "EV012465: With foil"
  },
  {
    valueId: "EV012468",
    valueDesc: "3: Outside unprotected",
    label: "EV012468: 3: Outside unprotected"
  },
  {
    valueId: "EV012469",
    valueDesc: "2: Outside sheltered",
    label: "EV012469: 2: Outside sheltered"
  },
  {
    valueId: "EV012470",
    valueDesc: "1: Inside",
    label: "EV012470: 1: Inside"
  },
  {
    valueId: "EV012477",
    valueDesc: "Tongue and groove (round about)",
    label: "EV012477: Tongue and groove (round about)"
  },
  {
    valueId: "EV012478",
    valueDesc: "Tongue and groove (long edge)",
    label: "EV012478: Tongue and groove (long edge)"
  },
  {
    valueId: "EV012479",
    valueDesc: "Straight edge",
    label: "EV012479: Straight edge"
  },
  {
    valueId: "EV012480",
    valueDesc: "Hardboard",
    label: "EV012480: Hardboard"
  },
  {
    valueId: "EV012481",
    valueDesc: "Softboard",
    label: "EV012481: Softboard"
  },
  {
    valueId: "EV012482",
    valueDesc: "Toilet rim block holder",
    label: "EV012482: Toilet rim block holder"
  },
  {
    valueId: "EV012483",
    valueDesc: "135°",
    label: "EV012483: 135°"
  },
  {
    valueId: "EV012484",
    valueDesc: "Washed",
    label: "EV012484: Washed"
  },
  {
    valueId: "EV012485",
    valueDesc: "Bush hammered",
    label: "EV012485: Bush hammered"
  },
  {
    valueId: "EV012486",
    valueDesc: "Tongue and groove",
    label: "EV012486: Tongue and groove"
  },
  {
    valueId: "EV012487",
    valueDesc: "Concave and convex fit double",
    label: "EV012487: Concave and convex fit double"
  },
  {
    valueId: "EV012488",
    valueDesc: "Concealed fish beak",
    label: "EV012488: Concealed fish beak"
  },
  {
    valueId: "EV012489",
    valueDesc: "Fish beak",
    label: "EV012489: Fish beak"
  },
  {
    valueId: "EV012490",
    valueDesc: "Fish beak double",
    label: "EV012490: Fish beak double"
  },
  {
    valueId: "EV012492",
    valueDesc: "95°",
    label: "EV012492: 95°"
  },
  {
    valueId: "EV012493",
    valueDesc: "100°",
    label: "EV012493: 100°"
  },
  {
    valueId: "EV012494",
    valueDesc: "105°",
    label: "EV012494: 105°"
  },
  {
    valueId: "EV012495",
    valueDesc: "110°",
    label: "EV012495: 110°"
  },
  {
    valueId: "EV012496",
    valueDesc: "115°",
    label: "EV012496: 115°"
  },
  {
    valueId: "EV012497",
    valueDesc: "120°",
    label: "EV012497: 120°"
  },
  {
    valueId: "EV012498",
    valueDesc: "125°",
    label: "EV012498: 125°"
  },
  {
    valueId: "EV012499",
    valueDesc: "130°",
    label: "EV012499: 130°"
  },
  {
    valueId: "EV012500",
    valueDesc: "140°",
    label: "EV012500: 140°"
  },
  {
    valueId: "EV012501",
    valueDesc: "145°",
    label: "EV012501: 145°"
  },
  {
    valueId: "EV012502",
    valueDesc: "150°",
    label: "EV012502: 150°"
  },
  {
    valueId: "EV012503",
    valueDesc: "155°",
    label: "EV012503: 155°"
  },
  {
    valueId: "EV012504",
    valueDesc: "160°",
    label: "EV012504: 160°"
  },
  {
    valueId: "EV012505",
    valueDesc: "165°",
    label: "EV012505: 165°"
  },
  {
    valueId: "EV012506",
    valueDesc: "170°",
    label: "EV012506: 170°"
  },
  {
    valueId: "EV012507",
    valueDesc: "175°",
    label: "EV012507: 175°"
  },
  {
    valueId: "EV012508",
    valueDesc: "Block pattern",
    label: "EV012508: Block pattern"
  },
  {
    valueId: "EV012509",
    valueDesc: "Water passing",
    label: "EV012509: Water passing"
  },
  {
    valueId: "EV012510",
    valueDesc: "Water permeable",
    label: "EV012510: Water permeable"
  },
  {
    valueId: "EV012511",
    valueDesc: "Water passing and permeable",
    label: "EV012511: Water passing and permeable"
  },
  {
    valueId: "EV012512",
    valueDesc: "Anti-graffiti",
    label: "EV012512: Anti-graffiti"
  },
  {
    valueId: "EV012513",
    valueDesc: "Dirt proof",
    label: "EV012513: Dirt proof"
  },
  {
    valueId: "EV012514",
    valueDesc: "Weatherproof",
    label: "EV012514: Weatherproof"
  },
  {
    valueId: "EV012515",
    valueDesc: "Sand",
    label: "EV012515: Sand"
  },
  {
    valueId: "EV012516",
    valueDesc: "Aerated concrete",
    label: "EV012516: Aerated concrete"
  },
  {
    valueId: "EV012517",
    valueDesc: "Plaster",
    label: "EV012517: Plaster"
  },
  {
    valueId: "EV012518",
    valueDesc: "Lime sand brick",
    label: "EV012518: Lime sand brick"
  },
  {
    valueId: "EV012519",
    valueDesc: "Ceramic (perforated)",
    label: "EV012519: Ceramic (perforated)"
  },
  {
    valueId: "EV012520",
    valueDesc: "Ceramic (full)",
    label: "EV012520: Ceramic (full)"
  },
  {
    valueId: "EV012522",
    valueDesc: "Hand moulded",
    label: "EV012522: Hand moulded"
  },
  {
    valueId: "EV012525",
    valueDesc: "Uni colour",
    label: "EV012525: Uni colour"
  },
  {
    valueId: "EV012526",
    valueDesc: "Broken",
    label: "EV012526: Broken"
  },
  {
    valueId: "EV012527",
    valueDesc: "Nervate",
    label: "EV012527: Nervate"
  },
  {
    valueId: "EV012528",
    valueDesc: "F1",
    label: "EV012528: F1"
  },
  {
    valueId: "EV012529",
    valueDesc: "F2",
    label: "EV012529: F2"
  },
  {
    valueId: "EV012530",
    valueDesc: "F2 (frost-class D)",
    label: "EV012530: F2 (frost-class D)"
  },
  {
    valueId: "EV012531",
    valueDesc: "Thick format",
    label: "EV012531: Thick format"
  },
  {
    valueId: "EV012532",
    valueDesc: "Cobble format",
    label: "EV012532: Cobble format"
  },
  {
    valueId: "EV012533",
    valueDesc: "Artificial Stone",
    label: "EV012533: Artificial Stone"
  },
  {
    valueId: "EV012534",
    valueDesc: "Upright edge left",
    label: "EV012534: Upright edge left"
  },
  {
    valueId: "EV012535",
    valueDesc: "Upright edge right",
    label: "EV012535: Upright edge right"
  },
  {
    valueId: "EV012536",
    valueDesc: "Groove left",
    label: "EV012536: Groove left"
  },
  {
    valueId: "EV012537",
    valueDesc: "Groove right",
    label: "EV012537: Groove right"
  },
  {
    valueId: "EV012538",
    valueDesc: "Middle groove",
    label: "EV012538: Middle groove"
  },
  {
    valueId: "EV012539",
    valueDesc: "Window sill clip",
    label: "EV012539: Window sill clip"
  },
  {
    valueId: "EV012540",
    valueDesc: "Mineral",
    label: "EV012540: Mineral"
  },
  {
    valueId: "EV012542",
    valueDesc: "Double size",
    label: "EV012542: Double size"
  },
  {
    valueId: "EV012543",
    valueDesc: "Straight stacked",
    label: "EV012543: Straight stacked"
  },
  {
    valueId: "EV012544",
    valueDesc: "Elbow pattern",
    label: "EV012544: Elbow pattern"
  },
  {
    valueId: "EV012545",
    valueDesc: "Twill pattern",
    label: "EV012545: Twill pattern"
  },
  {
    valueId: "EV012546",
    valueDesc: "Half stone",
    label: "EV012546: Half stone"
  },
  {
    valueId: "EV012547",
    valueDesc: "Bishop Cap",
    label: "EV012547: Bishop Cap"
  },
  {
    valueId: "EV012548",
    valueDesc: "Twill stone",
    label: "EV012548: Twill stone"
  },
  {
    valueId: "EV012549",
    valueDesc: "Nibble",
    label: "EV012549: Nibble"
  },
  {
    valueId: "EV012550",
    valueDesc: "Barrel-polish",
    label: "EV012550: Barrel-polish"
  },
  {
    valueId: "EV012551",
    valueDesc: "Sticks",
    label: "EV012551: Sticks"
  },
  {
    valueId: "EV012552",
    valueDesc: "Calibrated",
    label: "EV012552: Calibrated"
  },
  {
    valueId: "EV012553",
    valueDesc: "Washed out",
    label: "EV012553: Washed out"
  },
  {
    valueId: "EV012554",
    valueDesc: "Continuous strips",
    label: "EV012554: Continuous strips"
  },
  {
    valueId: "EV012555",
    valueDesc: "3-layer plate",
    label: "EV012555: 3-layer plate"
  },
  {
    valueId: "EV012556",
    valueDesc: "Flat door",
    label: "EV012556: Flat door"
  },
  {
    valueId: "EV012557",
    valueDesc: "Panel door",
    label: "EV012557: Panel door"
  },
  {
    valueId: "EV012558",
    valueDesc: "Line door",
    label: "EV012558: Line door"
  },
  {
    valueId: "EV012559",
    valueDesc: "Facet Profile",
    label: "EV012559: Facet Profile"
  },
  {
    valueId: "EV012560",
    valueDesc: "Bead Profile",
    label: "EV012560: Bead Profile"
  },
  {
    valueId: "EV012561",
    valueDesc: "Straight profile",
    label: "EV012561: Straight profile"
  },
  {
    valueId: "EV012562",
    valueDesc: "Smooth with surface",
    label: "EV012562: Smooth with surface"
  },
  {
    valueId: "EV012563",
    valueDesc: "Bossing (bevelled)",
    label: "EV012563: Bossing (bevelled)"
  },
  {
    valueId: "EV012564",
    valueDesc: "Veneer",
    label: "EV012564: Veneer"
  },
  {
    valueId: "EV012565",
    valueDesc: "No overlap",
    label: "EV012565: No overlap"
  },
  {
    valueId: "EV012566",
    valueDesc: "With overlap",
    label: "EV012566: With overlap"
  },
  {
    valueId: "EV012567",
    valueDesc: "Front door",
    label: "EV012567: Front door"
  },
  {
    valueId: "EV012568",
    valueDesc: "Back door",
    label: "EV012568: Back door"
  },
  {
    valueId: "EV012569",
    valueDesc: "Garden/balcony/patio door",
    label: "EV012569: Garden/balcony/patio door"
  },
  {
    valueId: "EV012570",
    valueDesc: "Garage door",
    label: "EV012570: Garage door"
  },
  {
    valueId: "EV012571",
    valueDesc: "Barn door",
    label: "EV012571: Barn door"
  },
  {
    valueId: "EV012573",
    valueDesc: "Plate (composite)",
    label: "EV012573: Plate (composite)"
  },
  {
    valueId: "EV012575",
    valueDesc: "Sapupira",
    label: "EV012575: Sapupira"
  },
  {
    valueId: "EV012576",
    valueDesc: "Tatajuba",
    label: "EV012576: Tatajuba"
  },
  {
    valueId: "EV012577",
    valueDesc: "HDF/MDF",
    label: "EV012577: HDF/MDF"
  },
  {
    valueId: "EV012579",
    valueDesc: "Pre-lacquered",
    label: "EV012579: Pre-lacquered"
  },
  {
    valueId: "EV012580",
    valueDesc: "Double-head",
    label: "EV012580: Double-head"
  },
  {
    valueId: "EV012581",
    valueDesc: "Positioner",
    label: "EV012581: Positioner"
  },
  {
    valueId: "EV012582",
    valueDesc: "ISO 1185 (black interior)",
    label: "EV012582: ISO 1185 (black interior)"
  },
  {
    valueId: "EV012583",
    valueDesc: "ISO 3731 (white interior)",
    label: "EV012583: ISO 3731 (white interior)"
  },
  {
    valueId: "EV012584",
    valueDesc: "ISO 12098",
    label: "EV012584: ISO 12098"
  },
  {
    valueId: "EV012585",
    valueDesc: "ISO 7638",
    label: "EV012585: ISO 7638"
  },
  {
    valueId: "EV012586",
    valueDesc: "Open/close",
    label: "EV012586: Open/close"
  },
  {
    valueId: "EV012587",
    valueDesc: "Class 0",
    label: "EV012587: Class 0"
  },
  {
    valueId: "EV012588",
    valueDesc: "SKG*",
    label: "EV012588: SKG*"
  },
  {
    valueId: "EV012589",
    valueDesc: "SKG**",
    label: "EV012589: SKG**"
  },
  {
    valueId: "EV012590",
    valueDesc: "SKG***",
    label: "EV012590: SKG***"
  },
  {
    valueId: "EV012591",
    valueDesc: "Inward swinging",
    label: "EV012591: Inward swinging"
  },
  {
    valueId: "EV012592",
    valueDesc: "Outward swinging",
    label: "EV012592: Outward swinging"
  },
  {
    valueId: "EV012593",
    valueDesc: "Round corner",
    label: "EV012593: Round corner"
  },
  {
    valueId: "EV012594",
    valueDesc: "Hardened steel",
    label: "EV012594: Hardened steel"
  },
  {
    valueId: "EV012595",
    valueDesc: "Pin tumbler lock key",
    label: "EV012595: Pin tumbler lock key"
  },
  {
    valueId: "EV012596",
    valueDesc: "Lever lock key",
    label: "EV012596: Lever lock key"
  },
  {
    valueId: "EV012597",
    valueDesc: "Bit key",
    label: "EV012597: Bit key"
  },
  {
    valueId: "EV012598",
    valueDesc: "Four sided key",
    label: "EV012598: Four sided key"
  },
  {
    valueId: "EV012599",
    valueDesc: "Free - occupied",
    label: "EV012599: Free - occupied"
  },
  {
    valueId: "EV012600",
    valueDesc: "Pushlock cylinder",
    label: "EV012600: Pushlock cylinder"
  },
  {
    valueId: "EV012601",
    valueDesc: "Oval cylinder",
    label: "EV012601: Oval cylinder"
  },
  {
    valueId: "EV012604",
    valueDesc: "Face mounting strike box",
    label: "EV012604: Face mounting strike box"
  },
  {
    valueId: "EV012605",
    valueDesc: "Recessed strike box",
    label: "EV012605: Recessed strike box"
  },
  {
    valueId: "EV012606",
    valueDesc: "Closing loop",
    label: "EV012606: Closing loop"
  },
  {
    valueId: "EV012607",
    valueDesc: "Outer cylinder/inner knob",
    label: "EV012607: Outer cylinder/inner knob"
  },
  {
    valueId: "EV012608",
    valueDesc: "F4",
    label: "EV012608: F4"
  },
  {
    valueId: "EV012609",
    valueDesc: "Strikebox",
    label: "EV012609: Strikebox"
  },
  {
    valueId: "EV012610",
    valueDesc: "Lockable knob",
    label: "EV012610: Lockable knob"
  },
  {
    valueId: "EV012611",
    valueDesc: "Not-Lockable knob",
    label: "EV012611: Not-Lockable knob"
  },
  {
    valueId: "EV012613",
    valueDesc: "Four sided pin tumbler lock key",
    label: "EV012613: Four sided pin tumbler lock key"
  },
  {
    valueId: "EV012618",
    valueDesc: "Round cylinder",
    label: "EV012618: Round cylinder"
  },
  {
    valueId: "EV012619",
    valueDesc: "Ball piece",
    label: "EV012619: Ball piece"
  },
  {
    valueId: "EV012620",
    valueDesc: "Continuous right-angled",
    label: "EV012620: Continuous right-angled"
  },
  {
    valueId: "EV012621",
    valueDesc: "Continuous parallel",
    label: "EV012621: Continuous parallel"
  },
  {
    valueId: "EV012622",
    valueDesc: "Recessed edge",
    label: "EV012622: Recessed edge"
  },
  {
    valueId: "EV012629",
    valueDesc: "Wood wool cement slab",
    label: "EV012629: Wood wool cement slab"
  },
  {
    valueId: "EV012630",
    valueDesc: "Kraft paper",
    label: "EV012630: Kraft paper"
  },
  {
    valueId: "EV012631",
    valueDesc: "Aluminium craft",
    label: "EV012631: Aluminium craft"
  },
  {
    valueId: "EV012632",
    valueDesc: "Blanket/mat",
    label: "EV012632: Blanket/mat"
  },
  {
    valueId: "EV012633",
    valueDesc: "Extruded polystyrene (XPS)",
    label: "EV012633: Extruded polystyrene (XPS)"
  },
  {
    valueId: "EV012634",
    valueDesc: "Phenol resin",
    label: "EV012634: Phenol resin"
  },
  {
    valueId: "EV012635",
    valueDesc: "Cellular glass",
    label: "EV012635: Cellular glass"
  },
  {
    valueId: "EV012636",
    valueDesc: "Flakes/wool",
    label: "EV012636: Flakes/wool"
  },
  {
    valueId: "EV012637",
    valueDesc: "Pearls",
    label: "EV012637: Pearls"
  },
  {
    valueId: "EV012638",
    valueDesc: "Cant strip",
    label: "EV012638: Cant strip"
  },
  {
    valueId: "EV012639",
    valueDesc: "Cant rib",
    label: "EV012639: Cant rib"
  },
  {
    valueId: "EV012640",
    valueDesc: "Saddleback",
    label: "EV012640: Saddleback"
  },
  {
    valueId: "EV012641",
    valueDesc: "Double boarding",
    label: "EV012641: Double boarding"
  },
  {
    valueId: "EV012642",
    valueDesc: "Single boarding",
    label: "EV012642: Single boarding"
  },
  {
    valueId: "EV012652",
    valueDesc: "Purlins and wall plates",
    label: "EV012652: Purlins and wall plates"
  },
  {
    valueId: "EV012653",
    valueDesc: "Loadbearing walls and/or rafters",
    label: "EV012653: Loadbearing walls and/or rafters"
  },
  {
    valueId: "EV012654",
    valueDesc: "Rafters",
    label: "EV012654: Rafters"
  },
  {
    valueId: "EV012656",
    valueDesc: "Existing roof deck without battens",
    label: "EV012656: Existing roof deck without battens"
  },
  {
    valueId: "EV012657",
    valueDesc: "Existing roof deck with battens or existing roof elements",
    label: "EV012657: Existing roof deck with battens or existing roof elements"
  },
  {
    valueId: "EV012658",
    valueDesc: "Isolation from the inside between the purlins",
    label: "EV012658: Isolation from the inside between the purlins"
  },
  {
    valueId: "EV012666",
    valueDesc: "OSB",
    label: "EV012666: OSB"
  },
  {
    valueId: "EV012668",
    valueDesc: "Cementitious",
    label: "EV012668: Cementitious"
  },
  {
    valueId: "EV012671",
    valueDesc: "Wood decor",
    label: "EV012671: Wood decor"
  },
  {
    valueId: "EV012672",
    valueDesc: "Metal decor",
    label: "EV012672: Metal decor"
  },
  {
    valueId: "EV012673",
    valueDesc: "Stone decor",
    label: "EV012673: Stone decor"
  },
  {
    valueId: "EV012674",
    valueDesc: "Print (fantasy)",
    label: "EV012674: Print (fantasy)"
  },
  {
    valueId: "EV012675",
    valueDesc: "Bevelsiding",
    label: "EV012675: Bevelsiding"
  },
  {
    valueId: "EV012676",
    valueDesc: "Channelsiding",
    label: "EV012676: Channelsiding"
  },
  {
    valueId: "EV012677",
    valueDesc: "Swedish rebate",
    label: "EV012677: Swedish rebate"
  },
  {
    valueId: "EV012678",
    valueDesc: "Finnish rebate",
    label: "EV012678: Finnish rebate"
  },
  {
    valueId: "EV012680",
    valueDesc: "Concrete sand",
    label: "EV012680: Concrete sand"
  },
  {
    valueId: "EV012681",
    valueDesc: "Crusher sand",
    label: "EV012681: Crusher sand"
  },
  {
    valueId: "EV012682",
    valueDesc: "Drainage sand",
    label: "EV012682: Drainage sand"
  },
  {
    valueId: "EV012683",
    valueDesc: "Filter sand",
    label: "EV012683: Filter sand"
  },
  {
    valueId: "EV012684",
    valueDesc: "Masonry sand",
    label: "EV012684: Masonry sand"
  },
  {
    valueId: "EV012686",
    valueDesc: "Play Sand",
    label: "EV012686: Play Sand"
  },
  {
    valueId: "EV012687",
    valueDesc: "Fall sand",
    label: "EV012687: Fall sand"
  },
  {
    valueId: "EV012689",
    valueDesc: "Joint sand",
    label: "EV012689: Joint sand"
  },
  {
    valueId: "EV012690",
    valueDesc: "Silver Sand",
    label: "EV012690: Silver Sand"
  },
  {
    valueId: "EV012691",
    valueDesc: "Pivot window",
    label: "EV012691: Pivot window"
  },
  {
    valueId: "EV012692",
    valueDesc: "Top hung pivot",
    label: "EV012692: Top hung pivot"
  },
  {
    valueId: "EV012693",
    valueDesc: "Exit roof window",
    label: "EV012693: Exit roof window"
  },
  {
    valueId: "EV012694",
    valueDesc: "Tilt and turn",
    label: "EV012694: Tilt and turn"
  },
  {
    valueId: "EV012696",
    valueDesc: "Flemish tile",
    label: "EV012696: Flemish tile"
  },
  {
    valueId: "EV012697",
    valueDesc: "Neroma tile",
    label: "EV012697: Neroma tile"
  },
  {
    valueId: "EV012698",
    valueDesc: "Finkenberger tile",
    label: "EV012698: Finkenberger tile"
  },
  {
    valueId: "EV012699",
    valueDesc: "Hollow tile",
    label: "EV012699: Hollow tile"
  },
  {
    valueId: "EV012700",
    valueDesc: "Single glass",
    label: "EV012700: Single glass"
  },
  {
    valueId: "EV012701",
    valueDesc: "Double glass",
    label: "EV012701: Double glass"
  },
  {
    valueId: "EV012702",
    valueDesc: "Three-layer glass",
    label: "EV012702: Three-layer glass"
  },
  {
    valueId: "EV012703",
    valueDesc: "Four-layer glass",
    label: "EV012703: Four-layer glass"
  },
  {
    valueId: "EV012704",
    valueDesc: "Five-layer glass",
    label: "EV012704: Five-layer glass"
  },
  {
    valueId: "EV012705",
    valueDesc: "Single layer acrylic",
    label: "EV012705: Single layer acrylic"
  },
  {
    valueId: "EV012707",
    valueDesc: "Roof tile",
    label: "EV012707: Roof tile"
  },
  {
    valueId: "EV012708",
    valueDesc: "Tilt",
    label: "EV012708: Tilt"
  },
  {
    valueId: "EV012712",
    valueDesc: "Latch and lock",
    label: "EV012712: Latch and lock"
  },
  {
    valueId: "EV012713",
    valueDesc: "Ball pin",
    label: "EV012713: Ball pin"
  },
  {
    valueId: "EV012714",
    valueDesc: "Nail fixation",
    label: "EV012714: Nail fixation"
  },
  {
    valueId: "EV012715",
    valueDesc: "Vertical joint",
    label: "EV012715: Vertical joint"
  },
  {
    valueId: "EV012716",
    valueDesc: "Sawing",
    label: "EV012716: Sawing"
  },
  {
    valueId: "EV012717",
    valueDesc: "Sanding",
    label: "EV012717: Sanding"
  },
  {
    valueId: "EV012723",
    valueDesc: "Plaster axe",
    label: "EV012723: Plaster axe"
  },
  {
    valueId: "EV012727",
    valueDesc: "Fill sand",
    label: "EV012727: Fill sand"
  },
  {
    valueId: "EV012728",
    valueDesc: "Floor sand",
    label: "EV012728: Floor sand"
  },
  {
    valueId: "EV012729",
    valueDesc: "Regular grind",
    label: "EV012729: Regular grind"
  },
  {
    valueId: "EV012730",
    valueDesc: "Filter Gravel",
    label: "EV012730: Filter Gravel"
  },
  {
    valueId: "EV012731",
    valueDesc: "Roofing gravel",
    label: "EV012731: Roofing gravel"
  },
  {
    valueId: "EV012744",
    valueDesc: "Blind with handle hole",
    label: "EV012744: Blind with handle hole"
  },
  {
    valueId: "EV012746",
    valueDesc: "Stainless steel 444 (1.4521)",
    label: "EV012746: Stainless steel 444 (1.4521)"
  },
  {
    valueId: "EV012747",
    valueDesc: "Stainless steel 439 (1.4520)",
    label: "EV012747: Stainless steel 439 (1.4520)"
  },
  {
    valueId: "EV012752",
    valueDesc: "Sloping",
    label: "EV012752: Sloping"
  },
  {
    valueId: "EV012753",
    valueDesc: "Lead 35",
    label: "EV012753: Lead 35"
  },
  {
    valueId: "EV012755",
    valueDesc: "Spin-formed",
    label: "EV012755: Spin-formed"
  },
  {
    valueId: "EV012756",
    valueDesc: "80x80 mm",
    label: "EV012756: 80x80 mm"
  },
  {
    valueId: "EV012757",
    valueDesc: "100x100 mm",
    label: "EV012757: 100x100 mm"
  },
  {
    valueId: "EV012758",
    valueDesc: "70 mm",
    label: "EV012758: 70 mm"
  },
  {
    valueId: "EV012759",
    valueDesc: "Tapered edge square covered",
    label: "EV012759: Tapered edge square covered"
  },
  {
    valueId: "EV012760",
    valueDesc: "Tapered edge half round covered",
    label: "EV012760: Tapered edge half round covered"
  },
  {
    valueId: "EV012761",
    valueDesc: "Half round edge covered",
    label: "EV012761: Half round edge covered"
  },
  {
    valueId: "EV012762",
    valueDesc: "Round edge covered",
    label: "EV012762: Round edge covered"
  },
  {
    valueId: "EV012763",
    valueDesc: "Bevelled edge covered",
    label: "EV012763: Bevelled edge covered"
  },
  {
    valueId: "EV012764",
    valueDesc: "Square cutting edge",
    label: "EV012764: Square cutting edge"
  },
  {
    valueId: "EV012765",
    valueDesc: "Bevelled cutting edge",
    label: "EV012765: Bevelled cutting edge"
  },
  {
    valueId: "EV012766",
    valueDesc: "Gable tile",
    label: "EV012766: Gable tile"
  },
  {
    valueId: "EV012772",
    valueDesc: "Facade board",
    label: "EV012772: Facade board"
  },
  {
    valueId: "EV012776",
    valueDesc: "Profiled/hollow",
    label: "EV012776: Profiled/hollow"
  },
  {
    valueId: "EV012777",
    valueDesc: "Natural",
    label: "EV012777: Natural"
  },
  {
    valueId: "EV012778",
    valueDesc: "Braise",
    label: "EV012778: Braise"
  },
  {
    valueId: "EV012779",
    valueDesc: "Engobe",
    label: "EV012779: Engobe"
  },
  {
    valueId: "EV012781",
    valueDesc: "Noble engobe",
    label: "EV012781: Noble engobe"
  },
  {
    valueId: "EV012782",
    valueDesc: "Glaze",
    label: "EV012782: Glaze"
  },
  {
    valueId: "EV012783",
    valueDesc: "Plain",
    label: "EV012783: Plain"
  },
  {
    valueId: "EV012792",
    valueDesc: "Sand blasted",
    label: "EV012792: Sand blasted"
  },
  {
    valueId: "EV012794",
    valueDesc: "Glazed",
    label: "EV012794: Glazed"
  },
  {
    valueId: "EV012795",
    valueDesc: "Square edge covered",
    label: "EV012795: Square edge covered"
  },
  {
    valueId: "EV012816",
    valueDesc: "Balloon ridge tile",
    label: "EV012816: Balloon ridge tile"
  },
  {
    valueId: "EV012817",
    valueDesc: "Mono ridge",
    label: "EV012817: Mono ridge"
  },
  {
    valueId: "EV012818",
    valueDesc: "Hook ridge tile",
    label: "EV012818: Hook ridge tile"
  },
  {
    valueId: "EV012819",
    valueDesc: "Half round hip rafter ridge",
    label: "EV012819: Half round hip rafter ridge"
  },
  {
    valueId: "EV012820",
    valueDesc: "Half round ridge tile",
    label: "EV012820: Half round ridge tile"
  },
  {
    valueId: "EV012821",
    valueDesc: "Hip rafter ridge",
    label: "EV012821: Hip rafter ridge"
  },
  {
    valueId: "EV012822",
    valueDesc: "Angular scale ridge tile",
    label: "EV012822: Angular scale ridge tile"
  },
  {
    valueId: "EV012823",
    valueDesc: "Angular ridge tile",
    label: "EV012823: Angular ridge tile"
  },
  {
    valueId: "EV012824",
    valueDesc: "Flat ridge tile",
    label: "EV012824: Flat ridge tile"
  },
  {
    valueId: "EV012825",
    valueDesc: "Scale ridge tile",
    label: "EV012825: Scale ridge tile"
  },
  {
    valueId: "EV012826",
    valueDesc: "Gable ridge",
    label: "EV012826: Gable ridge"
  },
  {
    valueId: "EV012827",
    valueDesc: "Beginning",
    label: "EV012827: Beginning"
  },
  {
    valueId: "EV012828",
    valueDesc: "End profile",
    label: "EV012828: End profile"
  },
  {
    valueId: "EV012829",
    valueDesc: "Intermediate profile",
    label: "EV012829: Intermediate profile"
  },
  {
    valueId: "EV012830",
    valueDesc: "Wall hook",
    label: "EV012830: Wall hook"
  },
  {
    valueId: "EV012831",
    valueDesc: "Nose",
    label: "EV012831: Nose"
  },
  {
    valueId: "EV012832",
    valueDesc: "Ventilation",
    label: "EV012832: Ventilation"
  },
  {
    valueId: "EV012833",
    valueDesc: "FireWire",
    label: "EV012833: FireWire"
  },
  {
    valueId: "EV012834",
    valueDesc: "Bevel siding (without rebate)",
    label: "EV012834: Bevel siding (without rebate)"
  },
  {
    valueId: "EV012835",
    valueDesc: "Drop siding",
    label: "EV012835: Drop siding"
  },
  {
    valueId: "EV012836",
    valueDesc: "Bead siding",
    label: "EV012836: Bead siding"
  },
  {
    valueId: "EV012837",
    valueDesc: "Overhang clapboard siding",
    label: "EV012837: Overhang clapboard siding"
  },
  {
    valueId: "EV012838",
    valueDesc: "Rhombus",
    label: "EV012838: Rhombus"
  },
  {
    valueId: "EV012839",
    valueDesc: "Drop siding with tongue and groove",
    label: "EV012839: Drop siding with tongue and groove"
  },
  {
    valueId: "EV012841",
    valueDesc: "Deck piece",
    label: "EV012841: Deck piece"
  },
  {
    valueId: "EV012842",
    valueDesc: "Swedish siding (with rebate)",
    label: "EV012842: Swedish siding (with rebate)"
  },
  {
    valueId: "EV012843",
    valueDesc: "In swing/out swing",
    label: "EV012843: In swing/out swing"
  },
  {
    valueId: "EV012845",
    valueDesc: "Pair",
    label: "EV012845: Pair"
  },
  {
    valueId: "EV012846",
    valueDesc: "Hole part",
    label: "EV012846: Hole part"
  },
  {
    valueId: "EV012847",
    valueDesc: "Spindle part",
    label: "EV012847: Spindle part"
  },
  {
    valueId: "EV012849",
    valueDesc: "Scale",
    label: "EV012849: Scale"
  },
  {
    valueId: "EV012850",
    valueDesc: "Door frame",
    label: "EV012850: Door frame"
  },
  {
    valueId: "EV012852",
    valueDesc: "Rounded",
    label: "EV012852: Rounded"
  },
  {
    valueId: "EV012856",
    valueDesc: "Drill in",
    label: "EV012856: Drill in"
  },
  {
    valueId: "EV012857",
    valueDesc: "Barrel hinge",
    label: "EV012857: Barrel hinge"
  },
  {
    valueId: "EV012858",
    valueDesc: "Knurled pin",
    label: "EV012858: Knurled pin"
  },
  {
    valueId: "EV012859",
    valueDesc: "Smooth pen",
    label: "EV012859: Smooth pen"
  },
  {
    valueId: "EV012860",
    valueDesc: "Rack and pinion",
    label: "EV012860: Rack and pinion"
  },
  {
    valueId: "EV012861",
    valueDesc: "Scissor arm",
    label: "EV012861: Scissor arm"
  },
  {
    valueId: "EV012862",
    valueDesc: "0 Not suitable for fire doors",
    label: "EV012862: 0 Not suitable for fire doors"
  },
  {
    valueId: "EV012863",
    valueDesc: "1 Suitable for fire doors",
    label: "EV012863: 1 Suitable for fire doors"
  },
  {
    valueId: "EV012865",
    valueDesc: "1: Little",
    label: "EV012865: 1: Little"
  },
  {
    valueId: "EV012866",
    valueDesc: "2: Average",
    label: "EV012866: 2: Average"
  },
  {
    valueId: "EV012867",
    valueDesc: "3: High",
    label: "EV012867: 3: High"
  },
  {
    valueId: "EV012868",
    valueDesc: "4: Very high",
    label: "EV012868: 4: Very high"
  },
  {
    valueId: "EV012869",
    valueDesc: "Door guide",
    label: "EV012869: Door guide"
  },
  {
    valueId: "EV012870",
    valueDesc: "Door handle hole",
    label: "EV012870: Door handle hole"
  },
  {
    valueId: "EV012871",
    valueDesc: "F1 Aluminium naturel",
    label: "EV012871: F1 Aluminium naturel"
  },
  {
    valueId: "EV012872",
    valueDesc: "F2 Aluminium champagne",
    label: "EV012872: F2 Aluminium champagne"
  },
  {
    valueId: "EV012873",
    valueDesc: "F4 Aluminium bronze colour",
    label: "EV012873: F4 Aluminium bronze colour"
  },
  {
    valueId: "EV012874",
    valueDesc: "Long back plate (>200 mm)",
    label: "EV012874: Long back plate (>200 mm)"
  },
  {
    valueId: "EV012875",
    valueDesc: "Short backplate (<=200 mm)",
    label: "EV012875: Short backplate (<=200 mm)"
  },
  {
    valueId: "EV012876",
    valueDesc: "Blind without handle hole",
    label: "EV012876: Blind without handle hole"
  },
  {
    valueId: "EV012877",
    valueDesc: "Wide backplate",
    label: "EV012877: Wide backplate"
  },
  {
    valueId: "EV012878",
    valueDesc: "Narrow backplate",
    label: "EV012878: Narrow backplate"
  },
  {
    valueId: "EV012879",
    valueDesc: "Polyurethane (PU) with wood core",
    label: "EV012879: Polyurethane (PU) with wood core"
  },
  {
    valueId: "EV012880",
    valueDesc: "Fibre cement",
    label: "EV012880: Fibre cement"
  },
  {
    valueId: "EV012881",
    valueDesc: "32/9",
    label: "EV012881: 32/9"
  },
  {
    valueId: "EV012882",
    valueDesc: "76/18",
    label: "EV012882: 76/18"
  },
  {
    valueId: "EV012883",
    valueDesc: "94/38",
    label: "EV012883: 94/38"
  },
  {
    valueId: "EV012884",
    valueDesc: "130/30",
    label: "EV012884: 130/30"
  },
  {
    valueId: "EV012885",
    valueDesc: "146/48",
    label: "EV012885: 146/48"
  },
  {
    valueId: "EV012886",
    valueDesc: "177/51",
    label: "EV012886: 177/51"
  },
  {
    valueId: "EV012887",
    valueDesc: "270/30",
    label: "EV012887: 270/30"
  },
  {
    valueId: "EV012888",
    valueDesc: "Heavy concrete",
    label: "EV012888: Heavy concrete"
  },
  {
    valueId: "EV012889",
    valueDesc: "Normal concrete",
    label: "EV012889: Normal concrete"
  },
  {
    valueId: "EV012890",
    valueDesc: "Light concrete",
    label: "EV012890: Light concrete"
  },
  {
    valueId: "EV012891",
    valueDesc: "Underfilling mortar",
    label: "EV012891: Underfilling mortar"
  },
  {
    valueId: "EV012892",
    valueDesc: "Casting mortar",
    label: "EV012892: Casting mortar"
  },
  {
    valueId: "EV012893",
    valueDesc: "Spray concrete mortar",
    label: "EV012893: Spray concrete mortar"
  },
  {
    valueId: "EV012894",
    valueDesc: "Underwater concrete mortar",
    label: "EV012894: Underwater concrete mortar"
  },
  {
    valueId: "EV012895",
    valueDesc: "Modelling mortar",
    label: "EV012895: Modelling mortar"
  },
  {
    valueId: "EV012896",
    valueDesc: "C8/10",
    label: "EV012896: C8/10"
  },
  {
    valueId: "EV012897",
    valueDesc: "C12/15",
    label: "EV012897: C12/15"
  },
  {
    valueId: "EV012898",
    valueDesc: "C16/20",
    label: "EV012898: C16/20"
  },
  {
    valueId: "EV012899",
    valueDesc: "C20/25",
    label: "EV012899: C20/25"
  },
  {
    valueId: "EV012900",
    valueDesc: "C25/30",
    label: "EV012900: C25/30"
  },
  {
    valueId: "EV012901",
    valueDesc: "C30/37",
    label: "EV012901: C30/37"
  },
  {
    valueId: "EV012902",
    valueDesc: "C35/45",
    label: "EV012902: C35/45"
  },
  {
    valueId: "EV012903",
    valueDesc: "C40/50",
    label: "EV012903: C40/50"
  },
  {
    valueId: "EV012904",
    valueDesc: "C45/55",
    label: "EV012904: C45/55"
  },
  {
    valueId: "EV012905",
    valueDesc: "C50/60",
    label: "EV012905: C50/60"
  },
  {
    valueId: "EV012906",
    valueDesc: "C55/67",
    label: "EV012906: C55/67"
  },
  {
    valueId: "EV012907",
    valueDesc: "C60/75",
    label: "EV012907: C60/75"
  },
  {
    valueId: "EV012908",
    valueDesc: "C70/85",
    label: "EV012908: C70/85"
  },
  {
    valueId: "EV012909",
    valueDesc: "C80/95",
    label: "EV012909: C80/95"
  },
  {
    valueId: "EV012910",
    valueDesc: "C90/105",
    label: "EV012910: C90/105"
  },
  {
    valueId: "EV012911",
    valueDesc: "C100/115",
    label: "EV012911: C100/115"
  },
  {
    valueId: "EV012912",
    valueDesc: "K30",
    label: "EV012912: K30"
  },
  {
    valueId: "EV012913",
    valueDesc: "K50",
    label: "EV012913: K50"
  },
  {
    valueId: "EV012914",
    valueDesc: "K70",
    label: "EV012914: K70"
  },
  {
    valueId: "EV012915",
    valueDesc: "LC8/9",
    label: "EV012915: LC8/9"
  },
  {
    valueId: "EV012916",
    valueDesc: "LC12/13",
    label: "EV012916: LC12/13"
  },
  {
    valueId: "EV012917",
    valueDesc: "LC16/18",
    label: "EV012917: LC16/18"
  },
  {
    valueId: "EV012918",
    valueDesc: "LC20/22",
    label: "EV012918: LC20/22"
  },
  {
    valueId: "EV012919",
    valueDesc: "LC25/28",
    label: "EV012919: LC25/28"
  },
  {
    valueId: "EV012920",
    valueDesc: "LC30/33",
    label: "EV012920: LC30/33"
  },
  {
    valueId: "EV012921",
    valueDesc: "LC35/38",
    label: "EV012921: LC35/38"
  },
  {
    valueId: "EV012922",
    valueDesc: "LC40/44",
    label: "EV012922: LC40/44"
  },
  {
    valueId: "EV012923",
    valueDesc: "LC45/50",
    label: "EV012923: LC45/50"
  },
  {
    valueId: "EV012924",
    valueDesc: "LC50/55",
    label: "EV012924: LC50/55"
  },
  {
    valueId: "EV012925",
    valueDesc: "LC55/60",
    label: "EV012925: LC55/60"
  },
  {
    valueId: "EV012926",
    valueDesc: "LC60/66",
    label: "EV012926: LC60/66"
  },
  {
    valueId: "EV012927",
    valueDesc: "LC70/77",
    label: "EV012927: LC70/77"
  },
  {
    valueId: "EV012928",
    valueDesc: "LC80/88",
    label: "EV012928: LC80/88"
  },
  {
    valueId: "EV012929",
    valueDesc: "X0",
    label: "EV012929: X0"
  },
  {
    valueId: "EV012930",
    valueDesc: "XC1",
    label: "EV012930: XC1"
  },
  {
    valueId: "EV012931",
    valueDesc: "XC2",
    label: "EV012931: XC2"
  },
  {
    valueId: "EV012932",
    valueDesc: "XC3",
    label: "EV012932: XC3"
  },
  {
    valueId: "EV012933",
    valueDesc: "XC4",
    label: "EV012933: XC4"
  },
  {
    valueId: "EV012934",
    valueDesc: "XD1",
    label: "EV012934: XD1"
  },
  {
    valueId: "EV012935",
    valueDesc: "XD2",
    label: "EV012935: XD2"
  },
  {
    valueId: "EV012936",
    valueDesc: "XD3",
    label: "EV012936: XD3"
  },
  {
    valueId: "EV012937",
    valueDesc: "XS1",
    label: "EV012937: XS1"
  },
  {
    valueId: "EV012938",
    valueDesc: "XS2",
    label: "EV012938: XS2"
  },
  {
    valueId: "EV012939",
    valueDesc: "XS3",
    label: "EV012939: XS3"
  },
  {
    valueId: "EV012940",
    valueDesc: "CEM I",
    label: "EV012940: CEM I"
  },
  {
    valueId: "EV012943",
    valueDesc: "CEM II/B-T",
    label: "EV012943: CEM II/B-T"
  },
  {
    valueId: "EV012945",
    valueDesc: "CEM IV",
    label: "EV012945: CEM IV"
  },
  {
    valueId: "EV012946",
    valueDesc: "CEM V",
    label: "EV012946: CEM V"
  },
  {
    valueId: "EV012947",
    valueDesc: "F3",
    label: "EV012947: F3"
  },
  {
    valueId: "EV012948",
    valueDesc: "C0",
    label: "EV012948: C0"
  },
  {
    valueId: "EV012952",
    valueDesc: "XF1",
    label: "EV012952: XF1"
  },
  {
    valueId: "EV012953",
    valueDesc: "XF2",
    label: "EV012953: XF2"
  },
  {
    valueId: "EV012954",
    valueDesc: "XF3",
    label: "EV012954: XF3"
  },
  {
    valueId: "EV012955",
    valueDesc: "XF4",
    label: "EV012955: XF4"
  },
  {
    valueId: "EV012956",
    valueDesc: "XA1",
    label: "EV012956: XA1"
  },
  {
    valueId: "EV012957",
    valueDesc: "XA2",
    label: "EV012957: XA2"
  },
  {
    valueId: "EV012958",
    valueDesc: "XA3",
    label: "EV012958: XA3"
  },
  {
    valueId: "EV012959",
    valueDesc: "Pre-treatment agent",
    label: "EV012959: Pre-treatment agent"
  },
  {
    valueId: "EV012962",
    valueDesc: "Calcium sulphate",
    label: "EV012962: Calcium sulphate"
  },
  {
    valueId: "EV012963",
    valueDesc: "Magnesite",
    label: "EV012963: Magnesite"
  },
  {
    valueId: "EV012964",
    valueDesc: "Mastic asphalt",
    label: "EV012964: Mastic asphalt"
  },
  {
    valueId: "EV012968",
    valueDesc: "C25",
    label: "EV012968: C25"
  },
  {
    valueId: "EV012969",
    valueDesc: "C60",
    label: "EV012969: C60"
  },
  {
    valueId: "EV012970",
    valueDesc: "C70",
    label: "EV012970: C70"
  },
  {
    valueId: "EV012971",
    valueDesc: "C80",
    label: "EV012971: C80"
  },
  {
    valueId: "EV012975",
    valueDesc: "F30",
    label: "EV012975: F30"
  },
  {
    valueId: "EV012976",
    valueDesc: "F40",
    label: "EV012976: F40"
  },
  {
    valueId: "EV012977",
    valueDesc: "F50",
    label: "EV012977: F50"
  },
  {
    valueId: "EV012978",
    valueDesc: "Cement/lime",
    label: "EV012978: Cement/lime"
  },
  {
    valueId: "EV012979",
    valueDesc: "Lime",
    label: "EV012979: Lime"
  },
  {
    valueId: "EV012980",
    valueDesc: "Masonry mortar",
    label: "EV012980: Masonry mortar"
  },
  {
    valueId: "EV012981",
    valueDesc: "Joint mortar",
    label: "EV012981: Joint mortar"
  },
  {
    valueId: "EV012982",
    valueDesc: "Thin-set mortar",
    label: "EV012982: Thin-set mortar"
  },
  {
    valueId: "EV012983",
    valueDesc: "Mason and joint mortar",
    label: "EV012983: Mason and joint mortar"
  },
  {
    valueId: "EV012984",
    valueDesc: "Glue mortar",
    label: "EV012984: Glue mortar"
  },
  {
    valueId: "EV012985",
    valueDesc: "A Outside",
    label: "EV012985: A Outside"
  },
  {
    valueId: "EV012986",
    valueDesc: "B Inside",
    label: "EV012986: B Inside"
  },
  {
    valueId: "EV012987",
    valueDesc: "C Recording transformation",
    label: "EV012987: C Recording transformation"
  },
  {
    valueId: "EV012988",
    valueDesc: "G general application",
    label: "EV012988: G general application"
  },
  {
    valueId: "EV012989",
    valueDesc: "L Lightweight masonry mortar",
    label: "EV012989: L Lightweight masonry mortar"
  },
  {
    valueId: "EV012990",
    valueDesc: "T Glue mortar",
    label: "EV012990: T Glue mortar"
  },
  {
    valueId: "EV012991",
    valueDesc: "MX1 in dry environment",
    label: "EV012991: MX1 in dry environment"
  },
  {
    valueId: "EV012992",
    valueDesc: "MX2 exposed to moisture and water",
    label: "EV012992: MX2 exposed to moisture and water"
  },
  {
    valueId: "EV012993",
    valueDesc: "MX3 exposed to moisture and freeze/thaw cycles",
    label: "EV012993: MX3 exposed to moisture and freeze/thaw cycles"
  },
  {
    valueId: "EV012994",
    valueDesc: "MX4 exposed to salt saturated air, seawater or de-icing salts",
    label: "EV012994: MX4 exposed to salt saturated air, seawater or de-icing salts"
  },
  {
    valueId: "EV012995",
    valueDesc: "MX5 in aggressive chemical environment",
    label: "EV012995: MX5 in aggressive chemical environment"
  },
  {
    valueId: "EV012996",
    valueDesc: "M1",
    label: "EV012996: M1"
  },
  {
    valueId: "EV012997",
    valueDesc: "M15",
    label: "EV012997: M15"
  },
  {
    valueId: "EV012998",
    valueDesc: "IW1 very little absorption <0.5",
    label: "EV012998: IW1 very little absorption <0.5"
  },
  {
    valueId: "EV012999",
    valueDesc: "IW2 moderate absorption 0.5 - 1.5",
    label: "EV012999: IW2 moderate absorption 0.5 - 1.5"
  },
  {
    valueId: "EV013000",
    valueDesc: "IW3 regular absorption 1.5 to 4",
    label: "EV013000: IW3 regular absorption 1.5 to 4"
  },
  {
    valueId: "EV013001",
    valueDesc: "IW4 high absorption >4",
    label: "EV013001: IW4 high absorption >4"
  },
  {
    valueId: "EV013002",
    valueDesc: "XS thin glue joint <3 mm",
    label: "EV013002: XS thin glue joint <3 mm"
  },
  {
    valueId: "EV013003",
    valueDesc: "S thick glue joint 3 - 6 mm",
    label: "EV013003: S thick glue joint 3 - 6 mm"
  },
  {
    valueId: "EV013004",
    valueDesc: "M thin masonry joint 4 - 8 mm",
    label: "EV013004: M thin masonry joint 4 - 8 mm"
  },
  {
    valueId: "EV013005",
    valueDesc: "L Medium masonry joint 8-12 mm",
    label: "EV013005: L Medium masonry joint 8-12 mm"
  },
  {
    valueId: "EV013006",
    valueDesc: "XL Thick masonry joint >12 mm",
    label: "EV013006: XL Thick masonry joint >12 mm"
  },
  {
    valueId: "EV013007",
    valueDesc: "Colourful",
    label: "EV013007: Colourful"
  },
  {
    valueId: "EV013008",
    valueDesc: "Straight strip",
    label: "EV013008: Straight strip"
  },
  {
    valueId: "EV013017",
    valueDesc: "Fluent",
    label: "EV013017: Fluent"
  },
  {
    valueId: "EV013019",
    valueDesc: "Stretcher brick",
    label: "EV013019: Stretcher brick"
  },
  {
    valueId: "EV013020",
    valueDesc: "Header - Stretcher",
    label: "EV013020: Header - Stretcher"
  },
  {
    valueId: "EV013021",
    valueDesc: "Header - Stretcher - Header",
    label: "EV013021: Header - Stretcher - Header"
  },
  {
    valueId: "EV013028",
    valueDesc: "Straight/upwards",
    label: "EV013028: Straight/upwards"
  },
  {
    valueId: "EV013029",
    valueDesc: "Downstairs right",
    label: "EV013029: Downstairs right"
  },
  {
    valueId: "EV013030",
    valueDesc: "Upstairs right",
    label: "EV013030: Upstairs right"
  },
  {
    valueId: "EV013031",
    valueDesc: "Downstairs left",
    label: "EV013031: Downstairs left"
  },
  {
    valueId: "EV013032",
    valueDesc: "Upstairs left",
    label: "EV013032: Upstairs left"
  },
  {
    valueId: "EV013033",
    valueDesc: "S320",
    label: "EV013033: S320"
  },
  {
    valueId: "EV013034",
    valueDesc: "B500A",
    label: "EV013034: B500A"
  },
  {
    valueId: "EV013035",
    valueDesc: "B500B",
    label: "EV013035: B500B"
  },
  {
    valueId: "EV013036",
    valueDesc: "B500C",
    label: "EV013036: B500C"
  },
  {
    valueId: "EV013037",
    valueDesc: "Concrete rib",
    label: "EV013037: Concrete rib"
  },
  {
    valueId: "EV013038",
    valueDesc: "Cube",
    label: "EV013038: Cube"
  },
  {
    valueId: "EV013039",
    valueDesc: "Split tablet",
    label: "EV013039: Split tablet"
  },
  {
    valueId: "EV013040",
    valueDesc: "Megalith",
    label: "EV013040: Megalith"
  },
  {
    valueId: "EV013041",
    valueDesc: "Z-wave",
    label: "EV013041: Z-wave"
  },
  {
    valueId: "EV013042",
    valueDesc: "B25",
    label: "EV013042: B25"
  },
  {
    valueId: "EV013043",
    valueDesc: "B45",
    label: "EV013043: B45"
  },
  {
    valueId: "EV013044",
    valueDesc: "B50",
    label: "EV013044: B50"
  },
  {
    valueId: "EV013045",
    valueDesc: "B55",
    label: "EV013045: B55"
  },
  {
    valueId: "EV013046",
    valueDesc: "C53/65",
    label: "EV013046: C53/65"
  },
  {
    valueId: "EV013047",
    valueDesc: "Hollow core slab",
    label: "EV013047: Hollow core slab"
  },
  {
    valueId: "EV013051",
    valueDesc: "Roof accessing",
    label: "EV013051: Roof accessing"
  },
  {
    valueId: "EV013052",
    valueDesc: "Ventilating",
    label: "EV013052: Ventilating"
  },
  {
    valueId: "EV013055",
    valueDesc: "Three-walled",
    label: "EV013055: Three-walled"
  },
  {
    valueId: "EV013056",
    valueDesc: "Four-walled",
    label: "EV013056: Four-walled"
  },
  {
    valueId: "EV013057",
    valueDesc: "Polycarbonate sun-resistant",
    label: "EV013057: Polycarbonate sun-resistant"
  },
  {
    valueId: "EV013058",
    valueDesc: "End sill",
    label: "EV013058: End sill"
  },
  {
    valueId: "EV013059",
    valueDesc: "Intermediate Sill",
    label: "EV013059: Intermediate Sill"
  },
  {
    valueId: "EV013060",
    valueDesc: "Miter angle",
    label: "EV013060: Miter angle"
  },
  {
    valueId: "EV013061",
    valueDesc: "Isolation mounting clip",
    label: "EV013061: Isolation mounting clip"
  },
  {
    valueId: "EV013063",
    valueDesc: "R1",
    label: "EV013063: R1"
  },
  {
    valueId: "EV013064",
    valueDesc: "R0",
    label: "EV013064: R0"
  },
  {
    valueId: "EV013065",
    valueDesc: "0-4",
    label: "EV013065: 0-4"
  },
  {
    valueId: "EV013066",
    valueDesc: "4-12",
    label: "EV013066: 4-12"
  },
  {
    valueId: "EV013067",
    valueDesc: "4-16",
    label: "EV013067: 4-16"
  },
  {
    valueId: "EV013068",
    valueDesc: "0-8",
    label: "EV013068: 0-8"
  },
  {
    valueId: "EV013069",
    valueDesc: "0-20",
    label: "EV013069: 0-20"
  },
  {
    valueId: "EV013070",
    valueDesc: "Mono gable tile",
    label: "EV013070: Mono gable tile"
  },
  {
    valueId: "EV013072",
    valueDesc: "HR+",
    label: "EV013072: HR+"
  },
  {
    valueId: "EV013073",
    valueDesc: "HR++",
    label: "EV013073: HR++"
  },
  {
    valueId: "EV013074",
    valueDesc: "HR+++",
    label: "EV013074: HR+++"
  },
  {
    valueId: "EV013075",
    valueDesc: "Stainless steel 316 (1.4362)",
    label: "EV013075: Stainless steel 316 (1.4362)"
  },
  {
    valueId: "EV013076",
    valueDesc: "Quick release",
    label: "EV013076: Quick release"
  },
  {
    valueId: "EV013077",
    valueDesc: "Waterborne",
    label: "EV013077: Waterborne"
  },
  {
    valueId: "EV013078",
    valueDesc: "SMP polymer",
    label: "EV013078: SMP polymer"
  },
  {
    valueId: "EV013079",
    valueDesc: "Porous - Porous",
    label: "EV013079: Porous - Porous"
  },
  {
    valueId: "EV013080",
    valueDesc: "Porous - Non porous",
    label: "EV013080: Porous - Non porous"
  },
  {
    valueId: "EV013081",
    valueDesc: "Non porous - Non porous",
    label: "EV013081: Non porous - Non porous"
  },
  {
    valueId: "EV013082",
    valueDesc: "Colourless",
    label: "EV013082: Colourless"
  },
  {
    valueId: "EV013083",
    valueDesc: "D1",
    label: "EV013083: D1"
  },
  {
    valueId: "EV013084",
    valueDesc: "D2",
    label: "EV013084: D2"
  },
  {
    valueId: "EV013085",
    valueDesc: "D3",
    label: "EV013085: D3"
  },
  {
    valueId: "EV013086",
    valueDesc: "D4",
    label: "EV013086: D4"
  },
  {
    valueId: "EV013087",
    valueDesc: "Cyanoacrylate (CA)",
    label: "EV013087: Cyanoacrylate (CA)"
  },
  {
    valueId: "EV013088",
    valueDesc: "PVC glue",
    label: "EV013088: PVC glue"
  },
  {
    valueId: "EV013089",
    valueDesc: "PVC C glue",
    label: "EV013089: PVC C glue"
  },
  {
    valueId: "EV013090",
    valueDesc: "ABS glue",
    label: "EV013090: ABS glue"
  },
  {
    valueId: "EV013093",
    valueDesc: "PVA (Polyvinyl acetate)",
    label: "EV013093: PVA (Polyvinyl acetate)"
  },
  {
    valueId: "EV013094",
    valueDesc: "Polymer",
    label: "EV013094: Polymer"
  },
  {
    valueId: "EV013095",
    valueDesc: "Eave profile",
    label: "EV013095: Eave profile"
  },
  {
    valueId: "EV013096",
    valueDesc: "Eave-system profile",
    label: "EV013096: Eave-system profile"
  },
  {
    valueId: "EV013097",
    valueDesc: "Bird Scrap",
    label: "EV013097: Bird Scrap"
  },
  {
    valueId: "EV013098",
    valueDesc: "Bird/mouse scrap",
    label: "EV013098: Bird/mouse scrap"
  },
  {
    valueId: "EV013099",
    valueDesc: "Ventilating bottom lath",
    label: "EV013099: Ventilating bottom lath"
  },
  {
    valueId: "EV013100",
    valueDesc: "Bird Vide",
    label: "EV013100: Bird Vide"
  },
  {
    valueId: "EV013101",
    valueDesc: "Ridge bracket",
    label: "EV013101: Ridge bracket"
  },
  {
    valueId: "EV013102",
    valueDesc: "Ridge hook",
    label: "EV013102: Ridge hook"
  },
  {
    valueId: "EV013103",
    valueDesc: "Tile hook",
    label: "EV013103: Tile hook"
  },
  {
    valueId: "EV013104",
    valueDesc: "Tile screw",
    label: "EV013104: Tile screw"
  },
  {
    valueId: "EV013105",
    valueDesc: "Clock screw",
    label: "EV013105: Clock screw"
  },
  {
    valueId: "EV013107",
    valueDesc: "Ridge slat holder",
    label: "EV013107: Ridge slat holder"
  },
  {
    valueId: "EV013108",
    valueDesc: "Valley gutter",
    label: "EV013108: Valley gutter"
  },
  {
    valueId: "EV013109",
    valueDesc: "Bottom ridge",
    label: "EV013109: Bottom ridge"
  },
  {
    valueId: "EV013110",
    valueDesc: "Hip rafter stepped flashing",
    label: "EV013110: Hip rafter stepped flashing"
  },
  {
    valueId: "EV013111",
    valueDesc: "Ridge stepped flashing",
    label: "EV013111: Ridge stepped flashing"
  },
  {
    valueId: "EV013112",
    valueDesc: "Head stepped flashing",
    label: "EV013112: Head stepped flashing"
  },
  {
    valueId: "EV013113",
    valueDesc: "Ridge hat",
    label: "EV013113: Ridge hat"
  },
  {
    valueId: "EV013114",
    valueDesc: "Aluminium/butyl",
    label: "EV013114: Aluminium/butyl"
  },
  {
    valueId: "EV013115",
    valueDesc: "Aluminium/PP",
    label: "EV013115: Aluminium/PP"
  },
  {
    valueId: "EV013116",
    valueDesc: "Acrylate coating",
    label: "EV013116: Acrylate coating"
  },
  {
    valueId: "EV013117",
    valueDesc: "Bird proofing",
    label: "EV013117: Bird proofing"
  },
  {
    valueId: "EV013118",
    valueDesc: "Adjustable ridge capping",
    label: "EV013118: Adjustable ridge capping"
  },
  {
    valueId: "EV013119",
    valueDesc: "Barge board",
    label: "EV013119: Barge board"
  },
  {
    valueId: "EV013120",
    valueDesc: "Gable end piece",
    label: "EV013120: Gable end piece"
  },
  {
    valueId: "EV013122",
    valueDesc: "Fixed angle ridge",
    label: "EV013122: Fixed angle ridge"
  },
  {
    valueId: "EV013123",
    valueDesc: "Joint device",
    label: "EV013123: Joint device"
  },
  {
    valueId: "EV013124",
    valueDesc: "Slope profile",
    label: "EV013124: Slope profile"
  },
  {
    valueId: "EV013125",
    valueDesc: "VI",
    label: "EV013125: VI"
  },
  {
    valueId: "EV013126",
    valueDesc: "VII",
    label: "EV013126: VII"
  },
  {
    valueId: "EV013127",
    valueDesc: "VIII",
    label: "EV013127: VIII"
  },
  {
    valueId: "EV013128",
    valueDesc: "1-field",
    label: "EV013128: 1-field"
  },
  {
    valueId: "EV013129",
    valueDesc: "2-fields",
    label: "EV013129: 2-fields"
  },
  {
    valueId: "EV013130",
    valueDesc: "Multiple fields",
    label: "EV013130: Multiple fields"
  },
  {
    valueId: "EV013131",
    valueDesc: "Ceiling shoring",
    label: "EV013131: Ceiling shoring"
  },
  {
    valueId: "EV013132",
    valueDesc: "Wall shoring",
    label: "EV013132: Wall shoring"
  },
  {
    valueId: "EV013133",
    valueDesc: "Lower bracket",
    label: "EV013133: Lower bracket"
  },
  {
    valueId: "EV013134",
    valueDesc: "Upper bracket",
    label: "EV013134: Upper bracket"
  },
  {
    valueId: "EV013135",
    valueDesc: "Lightweight concrete",
    label: "EV013135: Lightweight concrete"
  },
  {
    valueId: "EV013138",
    valueDesc: "Mounting Screen",
    label: "EV013138: Mounting Screen"
  },
  {
    valueId: "EV013139",
    valueDesc: "Roller screen",
    label: "EV013139: Roller screen"
  },
  {
    valueId: "EV013140",
    valueDesc: "Plisse screen",
    label: "EV013140: Plisse screen"
  },
  {
    valueId: "EV013141",
    valueDesc: "In the clear",
    label: "EV013141: In the clear"
  },
  {
    valueId: "EV013142",
    valueDesc: "Coupling gutter (renovation profile)",
    label: "EV013142: Coupling gutter (renovation profile)"
  },
  {
    valueId: "EV013143",
    valueDesc: "Hidden gutter",
    label: "EV013143: Hidden gutter"
  },
  {
    valueId: "EV013144",
    valueDesc: "Gutter element",
    label: "EV013144: Gutter element"
  },
  {
    valueId: "EV013146",
    valueDesc: "H-profile",
    label: "EV013146: H-profile"
  },
  {
    valueId: "EV013147",
    valueDesc: "Top and two sides",
    label: "EV013147: Top and two sides"
  },
  {
    valueId: "EV013148",
    valueDesc: "UNP",
    label: "EV013148: UNP"
  },
  {
    valueId: "EV013149",
    valueDesc: "350",
    label: "EV013149: 350"
  },
  {
    valueId: "EV013150",
    valueDesc: "Sidewall",
    label: "EV013150: Sidewall"
  },
  {
    valueId: "EV013151",
    valueDesc: "Corner element",
    label: "EV013151: Corner element"
  },
  {
    valueId: "EV013152",
    valueDesc: "CEM II",
    label: "EV013152: CEM II"
  },
  {
    valueId: "EV013153",
    valueDesc: "CEM III/A",
    label: "EV013153: CEM III/A"
  },
  {
    valueId: "EV013154",
    valueDesc: "CEM III/C",
    label: "EV013154: CEM III/C"
  },
  {
    valueId: "EV013155",
    valueDesc: "CEM III/B",
    label: "EV013155: CEM III/B"
  },
  {
    valueId: "EV013157",
    valueDesc: "32.5 N",
    label: "EV013157: 32.5 N"
  },
  {
    valueId: "EV013158",
    valueDesc: "32.5 R",
    label: "EV013158: 32.5 R"
  },
  {
    valueId: "EV013159",
    valueDesc: "42.5 N",
    label: "EV013159: 42.5 N"
  },
  {
    valueId: "EV013160",
    valueDesc: "42.5 R",
    label: "EV013160: 42.5 R"
  },
  {
    valueId: "EV013161",
    valueDesc: "52.5 N",
    label: "EV013161: 52.5 N"
  },
  {
    valueId: "EV013162",
    valueDesc: "52.5 R",
    label: "EV013162: 52.5 R"
  },
  {
    valueId: "EV013163",
    valueDesc: "Fire stop tape",
    label: "EV013163: Fire stop tape"
  },
  {
    valueId: "EV013164",
    valueDesc: "St E190 (1.0031)",
    label: "EV013164: St E190 (1.0031)"
  },
  {
    valueId: "EV013165",
    valueDesc: "SN10",
    label: "EV013165: SN10"
  },
  {
    valueId: "EV013166",
    valueDesc: "SN12",
    label: "EV013166: SN12"
  },
  {
    valueId: "EV013167",
    valueDesc: "SN16",
    label: "EV013167: SN16"
  },
  {
    valueId: "EV013168",
    valueDesc: "SN20",
    label: "EV013168: SN20"
  },
  {
    valueId: "EV013169",
    valueDesc: "SN22",
    label: "EV013169: SN22"
  },
  {
    valueId: "EV013170",
    valueDesc: "PVC-O",
    label: "EV013170: PVC-O"
  },
  {
    valueId: "EV013171",
    valueDesc: "DN 60",
    label: "EV013171: DN 60"
  },
  {
    valueId: "EV013172",
    valueDesc: "DN 160",
    label: "EV013172: DN 160"
  },
  {
    valueId: "EV013173",
    valueDesc: "DN 315",
    label: "EV013173: DN 315"
  },
  {
    valueId: "EV013174",
    valueDesc: "DN 630",
    label: "EV013174: DN 630"
  },
  {
    valueId: "EV013175",
    valueDesc: "Spigot/tube end",
    label: "EV013175: Spigot/tube end"
  },
  {
    valueId: "EV013176",
    valueDesc: "Cross pair",
    label: "EV013176: Cross pair"
  },
  {
    valueId: "EV013177",
    valueDesc: "Double flat",
    label: "EV013177: Double flat"
  },
  {
    valueId: "EV013179",
    valueDesc: "Ms 58",
    label: "EV013179: Ms 58"
  },
  {
    valueId: "EV013180",
    valueDesc: "Ms 70",
    label: "EV013180: Ms 70"
  },
  {
    valueId: "EV013181",
    valueDesc: "M23",
    label: "EV013181: M23"
  },
  {
    valueId: "EV013183",
    valueDesc: "Double bridge",
    label: "EV013183: Double bridge"
  },
  {
    valueId: "EV013185",
    valueDesc: "P/S-siphon",
    label: "EV013185: P/S-siphon"
  },
  {
    valueId: "EV013186",
    valueDesc: "Capacitive multitouch",
    label: "EV013186: Capacitive multitouch"
  },
  {
    valueId: "EV013187",
    valueDesc: "Resistive touch",
    label: "EV013187: Resistive touch"
  },
  {
    valueId: "EV013188",
    valueDesc: "Infrared touch",
    label: "EV013188: Infrared touch"
  },
  {
    valueId: "EV013189",
    valueDesc: "Thrust screw",
    label: "EV013189: Thrust screw"
  },
  {
    valueId: "EV013190",
    valueDesc: "Broaching",
    label: "EV013190: Broaching"
  },
  {
    valueId: "EV013191",
    valueDesc: "PN 24",
    label: "EV013191: PN 24"
  },
  {
    valueId: "EV013192",
    valueDesc: "DN 63",
    label: "EV013192: DN 63"
  },
  {
    valueId: "EV013193",
    valueDesc: "DN 355",
    label: "EV013193: DN 355"
  },
  {
    valueId: "EV013194",
    valueDesc: "DN 560",
    label: "EV013194: DN 560"
  },
  {
    valueId: "EV013195",
    valueDesc: "DN 180",
    label: "EV013195: DN 180"
  },
  {
    valueId: "EV013196",
    valueDesc: "DN 225",
    label: "EV013196: DN 225"
  },
  {
    valueId: "EV013197",
    valueDesc: "DN 280",
    label: "EV013197: DN 280"
  },
  {
    valueId: "EV013198",
    valueDesc: "Real hair",
    label: "EV013198: Real hair"
  },
  {
    valueId: "EV013199",
    valueDesc: "Remote operation",
    label: "EV013199: Remote operation"
  },
  {
    valueId: "EV013200",
    valueDesc: "Ductile iron socket",
    label: "EV013200: Ductile iron socket"
  },
  {
    valueId: "EV013201",
    valueDesc: "BAIO",
    label: "EV013201: BAIO"
  },
  {
    valueId: "EV013202",
    valueDesc: "16 V",
    label: "EV013202: 16 V"
  },
  {
    valueId: "EV013203",
    valueDesc: "425 mm",
    label: "EV013203: 425 mm"
  },
  {
    valueId: "EV013206",
    valueDesc: "Mechanical coupler PVC",
    label: "EV013206: Mechanical coupler PVC"
  },
  {
    valueId: "EV013207",
    valueDesc: "Mechanical coupler copper",
    label: "EV013207: Mechanical coupler copper"
  },
  {
    valueId: "EV013208",
    valueDesc: "Mechanical coupler cast iron",
    label: "EV013208: Mechanical coupler cast iron"
  },
  {
    valueId: "EV013209",
    valueDesc: "Mechanical coupler ductile iron",
    label: "EV013209: Mechanical coupler ductile iron"
  },
  {
    valueId: "EV013210",
    valueDesc: "Mechanical coupler PE",
    label: "EV013210: Mechanical coupler PE"
  },
  {
    valueId: "EV013211",
    valueDesc: "Mechanical coupler",
    label: "EV013211: Mechanical coupler"
  },
  {
    valueId: "EV013213",
    valueDesc: "BACnet MSTP/IP",
    label: "EV013213: BACnet MSTP/IP"
  },
  {
    valueId: "EV013214",
    valueDesc: "MP-bus/BACnet/2-10 V",
    label: "EV013214: MP-bus/BACnet/2-10 V"
  },
  {
    valueId: "EV013216",
    valueDesc: "Bus connection / 2-10 V",
    label: "EV013216: Bus connection / 2-10 V"
  },
  {
    valueId: "EV013217",
    valueDesc: "Direct on drive",
    label: "EV013217: Direct on drive"
  },
  {
    valueId: "EV013218",
    valueDesc: "Equal percentage/linear (adjustable)",
    label: "EV013218: Equal percentage/linear (adjustable)"
  },
  {
    valueId: "EV013219",
    valueDesc: "Pt500",
    label: "EV013219: Pt500"
  },
  {
    valueId: "EV013221",
    valueDesc: "IP67/IP69K",
    label: "EV013221: IP67/IP69K"
  },
  {
    valueId: "EV013222",
    valueDesc: "28 mm internal hexagon",
    label: "EV013222: 28 mm internal hexagon"
  },
  {
    valueId: "EV013223",
    valueDesc: "30 mm internal hexagon",
    label: "EV013223: 30 mm internal hexagon"
  },
  {
    valueId: "EV013224",
    valueDesc: "160x120",
    label: "EV013224: 160x120"
  },
  {
    valueId: "EV013226",
    valueDesc: "Camera cable",
    label: "EV013226: Camera cable"
  },
  {
    valueId: "EV013227",
    valueDesc: "Extension cable for camera cable",
    label: "EV013227: Extension cable for camera cable"
  },
  {
    valueId: "EV013229",
    valueDesc: "M1.6",
    label: "EV013229: M1.6"
  },
  {
    valueId: "EV013239",
    valueDesc: "Windows Embedded Standard 7",
    label: "EV013239: Windows Embedded Standard 7"
  },
  {
    valueId: "EV013240",
    valueDesc: "Windows Embedded 8",
    label: "EV013240: Windows Embedded 8"
  },
  {
    valueId: "EV013241",
    valueDesc: "Windows 10",
    label: "EV013241: Windows 10"
  },
  {
    valueId: "EV013242",
    valueDesc: "Ribbing groove (groove coupling)",
    label: "EV013242: Ribbing groove (groove coupling)"
  },
  {
    valueId: "EV013246",
    valueDesc: "Hybrid",
    label: "EV013246: Hybrid"
  },
  {
    valueId: "EV013247",
    valueDesc: "Hydrogen",
    label: "EV013247: Hydrogen"
  },
  {
    valueId: "EV013260",
    valueDesc: "Hollow",
    label: "EV013260: Hollow"
  },
  {
    valueId: "EV013285",
    valueDesc: "Flat wall",
    label: "EV013285: Flat wall"
  },
  {
    valueId: "EV013287",
    valueDesc: "Plastic pipe",
    label: "EV013287: Plastic pipe"
  },
  {
    valueId: "EV013288",
    valueDesc: "Concrete pipe",
    label: "EV013288: Concrete pipe"
  },
  {
    valueId: "EV013289",
    valueDesc: "Steel pipe",
    label: "EV013289: Steel pipe"
  },
  {
    valueId: "EV013290",
    valueDesc: "Over water surface",
    label: "EV013290: Over water surface"
  },
  {
    valueId: "EV013293",
    valueDesc: "Below water surface",
    label: "EV013293: Below water surface"
  },
  {
    valueId: "EV013304",
    valueDesc: "Chrome-vanadium steel",
    label: "EV013304: Chrome-vanadium steel"
  },
  {
    valueId: "EV013314",
    valueDesc: "Multicomponent",
    label: "EV013314: Multicomponent"
  },
  {
    valueId: "EV013317",
    valueDesc: "Phosphatised",
    label: "EV013317: Phosphatised"
  },
  {
    valueId: "EV013324",
    valueDesc: "Fine-step rail",
    label: "EV013324: Fine-step rail"
  },
  {
    valueId: "EV013325",
    valueDesc: "Preselection of clamping force",
    label: "EV013325: Preselection of clamping force"
  },
  {
    valueId: "EV013326",
    valueDesc: "Angle drive",
    label: "EV013326: Angle drive"
  },
  {
    valueId: "EV013338",
    valueDesc: "Service valve",
    label: "EV013338: Service valve"
  },
  {
    valueId: "EV013339",
    valueDesc: "Gate valve",
    label: "EV013339: Gate valve"
  },
  {
    valueId: "EV013340",
    valueDesc: "Butterfly valve",
    label: "EV013340: Butterfly valve"
  },
  {
    valueId: "EV013342",
    valueDesc: "Corrugated",
    label: "EV013342: Corrugated"
  },
  {
    valueId: "EV013347",
    valueDesc: "Cm",
    label: "EV013347: Cm"
  },
  {
    valueId: "EV013349",
    valueDesc: "Cm/inch",
    label: "EV013349: Cm/inch"
  },
  {
    valueId: "EV013350",
    valueDesc: "dm",
    label: "EV013350: dm"
  },
  {
    valueId: "EV013352",
    valueDesc: "Inch/mm",
    label: "EV013352: Inch/mm"
  },
  {
    valueId: "EV013353",
    valueDesc: "Mm/cm",
    label: "EV013353: Mm/cm"
  },
  {
    valueId: "EV013355",
    valueDesc: "EG-class II",
    label: "EV013355: EG-class II"
  },
  {
    valueId: "EV013357",
    valueDesc: "PE-63",
    label: "EV013357: PE-63"
  },
  {
    valueId: "EV013363",
    valueDesc: "150",
    label: "EV013363: 150"
  },
  {
    valueId: "EV013368",
    valueDesc: "Basic (B)",
    label: "EV013368: Basic (B)"
  },
  {
    valueId: "EV013369",
    valueDesc: "Rutile (R)",
    label: "EV013369: Rutile (R)"
  },
  {
    valueId: "EV013370",
    valueDesc: "Rutile (R, RR)",
    label: "EV013370: Rutile (R, RR)"
  },
  {
    valueId: "EV013371",
    valueDesc: "Rutile-basic (RB)",
    label: "EV013371: Rutile-basic (RB)"
  },
  {
    valueId: "EV013372",
    valueDesc: "Rutile-acid (RA)",
    label: "EV013372: Rutile-acid (RA)"
  },
  {
    valueId: "EV013373",
    valueDesc: "Rutile-cellulose (RC)",
    label: "EV013373: Rutile-cellulose (RC)"
  },
  {
    valueId: "EV013374",
    valueDesc: "Cellulose (C)",
    label: "EV013374: Cellulose (C)"
  },
  {
    valueId: "EV013377",
    valueDesc: "C-hook",
    label: "EV013377: C-hook"
  },
  {
    valueId: "EV013386",
    valueDesc: "WS",
    label: "EV013386: WS"
  },
  {
    valueId: "EV013389",
    valueDesc: "KEL",
    label: "EV013389: KEL"
  },
  {
    valueId: "EV013390",
    valueDesc: "KSJ",
    label: "EV013390: KSJ"
  },
  {
    valueId: "EV013391",
    valueDesc: "KSK",
    label: "EV013391: KSK"
  },
  {
    valueId: "EV013392",
    valueDesc: "KUD",
    label: "EV013392: KUD"
  },
  {
    valueId: "EV013393",
    valueDesc: "RBF",
    label: "EV013393: RBF"
  },
  {
    valueId: "EV013394",
    valueDesc: "SKM",
    label: "EV013394: SKM"
  },
  {
    valueId: "EV013395",
    valueDesc: "SPG",
    label: "EV013395: SPG"
  },
  {
    valueId: "EV013396",
    valueDesc: "TRE",
    label: "EV013396: TRE"
  },
  {
    valueId: "EV013397",
    valueDesc: "WKN",
    label: "EV013397: WKN"
  },
  {
    valueId: "EV013398",
    valueDesc: "WRC",
    label: "EV013398: WRC"
  },
  {
    valueId: "EV013399",
    valueDesc: "ZYA",
    label: "EV013399: ZYA"
  },
  {
    valueId: "EV013400",
    valueDesc: "ZYA-S",
    label: "EV013400: ZYA-S"
  },
  {
    valueId: "EV013401",
    valueDesc: "HM",
    label: "EV013401: HM"
  },
  {
    valueId: "EV013402",
    valueDesc: "HSS",
    label: "EV013402: HSS"
  },
  {
    valueId: "EV013403",
    valueDesc: "UNS",
    label: "EV013403: UNS"
  },
  {
    valueId: "EV013406",
    valueDesc: "#3",
    label: "EV013406: #3"
  },
  {
    valueId: "EV013407",
    valueDesc: "#4",
    label: "EV013407: #4"
  },
  {
    valueId: "EV013408",
    valueDesc: "#5",
    label: "EV013408: #5"
  },
  {
    valueId: "EV013409",
    valueDesc: "#6",
    label: "EV013409: #6"
  },
  {
    valueId: "EV013413",
    valueDesc: "Bell shape",
    label: "EV013413: Bell shape"
  },
  {
    valueId: "EV013415",
    valueDesc: "2A",
    label: "EV013415: 2A"
  },
  {
    valueId: "EV013416",
    valueDesc: "3A",
    label: "EV013416: 3A"
  },
  {
    valueId: "EV013419",
    valueDesc: "HSS-E",
    label: "EV013419: HSS-E"
  },
  {
    valueId: "EV013426",
    valueDesc: "Corundum",
    label: "EV013426: Corundum"
  },
  {
    valueId: "EV013427",
    valueDesc: "Silicon carbide",
    label: "EV013427: Silicon carbide"
  },
  {
    valueId: "EV013428",
    valueDesc: "01",
    label: "EV013428: 01"
  },
  {
    valueId: "EV013429",
    valueDesc: "02",
    label: "EV013429: 02"
  },
  {
    valueId: "EV013430",
    valueDesc: "Colour cup",
    label: "EV013430: Colour cup"
  },
  {
    valueId: "EV013431",
    valueDesc: "Router carriage",
    label: "EV013431: Router carriage"
  },
  {
    valueId: "EV013432",
    valueDesc: "Planer blade",
    label: "EV013432: Planer blade"
  },
  {
    valueId: "EV013433",
    valueDesc: "Cutting oil",
    label: "EV013433: Cutting oil"
  },
  {
    valueId: "EV013434",
    valueDesc: "Punch",
    label: "EV013434: Punch"
  },
  {
    valueId: "EV013436",
    valueDesc: "Card scraper",
    label: "EV013436: Card scraper"
  },
  {
    valueId: "EV013437",
    valueDesc: "Saw blade guide",
    label: "EV013437: Saw blade guide"
  },
  {
    valueId: "EV013438",
    valueDesc: "Grinding strip",
    label: "EV013438: Grinding strip"
  },
  {
    valueId: "EV013439",
    valueDesc: "Joint scraper",
    label: "EV013439: Joint scraper"
  },
  {
    valueId: "EV013440",
    valueDesc: "Blade",
    label: "EV013440: Blade"
  },
  {
    valueId: "EV013442",
    valueDesc: "Clamping disc",
    label: "EV013442: Clamping disc"
  },
  {
    valueId: "EV013443",
    valueDesc: "Steam shield",
    label: "EV013443: Steam shield"
  },
  {
    valueId: "EV013447",
    valueDesc: "Pistol shape",
    label: "EV013447: Pistol shape"
  },
  {
    valueId: "EV013448",
    valueDesc: "Claw coupling G 1/2 inch",
    label: "EV013448: Claw coupling G 1/2 inch"
  },
  {
    valueId: "EV013449",
    valueDesc: "Claw coupling G 3/4 inch",
    label: "EV013449: Claw coupling G 3/4 inch"
  },
  {
    valueId: "EV013450",
    valueDesc: "Claw coupling G 1 inch",
    label: "EV013450: Claw coupling G 1 inch"
  },
  {
    valueId: "EV013457",
    valueDesc: "Perforation type 1",
    label: "EV013457: Perforation type 1"
  },
  {
    valueId: "EV013458",
    valueDesc: "Perforation type 2",
    label: "EV013458: Perforation type 2"
  },
  {
    valueId: "EV013459",
    valueDesc: "Perforation type 3",
    label: "EV013459: Perforation type 3"
  },
  {
    valueId: "EV013460",
    valueDesc: "Perforation type 4",
    label: "EV013460: Perforation type 4"
  },
  {
    valueId: "EV013461",
    valueDesc: "Fully perforated",
    label: "EV013461: Fully perforated"
  },
  {
    valueId: "EV013462",
    valueDesc: "Top perforated",
    label: "EV013462: Top perforated"
  },
  {
    valueId: "EV013473",
    valueDesc: "Grate",
    label: "EV013473: Grate"
  },
  {
    valueId: "EV013477",
    valueDesc: "District cooling",
    label: "EV013477: District cooling"
  },
  {
    valueId: "EV013478",
    valueDesc: "Pressure-sewer",
    label: "EV013478: Pressure-sewer"
  },
  {
    valueId: "EV013480",
    valueDesc: "Beehive grate",
    label: "EV013480: Beehive grate"
  },
  {
    valueId: "EV013481",
    valueDesc: "S235JR (1.0038)",
    label: "EV013481: S235JR (1.0038)"
  },
  {
    valueId: "EV013482",
    valueDesc: "S275JR (1.0044)",
    label: "EV013482: S275JR (1.0044)"
  },
  {
    valueId: "EV013483",
    valueDesc: "S355J2 (1.0577)",
    label: "EV013483: S355J2 (1.0577)"
  },
  {
    valueId: "EV013484",
    valueDesc: "Red-leaded red",
    label: "EV013484: Red-leaded red"
  },
  {
    valueId: "EV013485",
    valueDesc: "Red-leaded grey",
    label: "EV013485: Red-leaded grey"
  },
  {
    valueId: "EV013489",
    valueDesc: "Iron-based",
    label: "EV013489: Iron-based"
  },
  {
    valueId: "EV013490",
    valueDesc: "Aluminium-based",
    label: "EV013490: Aluminium-based"
  },
  {
    valueId: "EV013492",
    valueDesc: "Peat",
    label: "EV013492: Peat"
  },
  {
    valueId: "EV013493",
    valueDesc: "Bark",
    label: "EV013493: Bark"
  },
  {
    valueId: "EV013505",
    valueDesc: "G4-0",
    label: "EV013505: G4-0"
  },
  {
    valueId: "EV013506",
    valueDesc: "G4-1",
    label: "EV013506: G4-1"
  },
  {
    valueId: "EV013507",
    valueDesc: "G4-2",
    label: "EV013507: G4-2"
  },
  {
    valueId: "EV013508",
    valueDesc: "G4-3",
    label: "EV013508: G4-3"
  },
  {
    valueId: "EV013509",
    valueDesc: "G4-4",
    label: "EV013509: G4-4"
  },
  {
    valueId: "EV013510",
    valueDesc: "G2-0",
    label: "EV013510: G2-0"
  },
  {
    valueId: "EV013511",
    valueDesc: "G2-1",
    label: "EV013511: G2-1"
  },
  {
    valueId: "EV013512",
    valueDesc: "G2-2",
    label: "EV013512: G2-2"
  },
  {
    valueId: "EV013513",
    valueDesc: "G2-3",
    label: "EV013513: G2-3"
  },
  {
    valueId: "EV013514",
    valueDesc: "G2-4",
    label: "EV013514: G2-4"
  },
  {
    valueId: "EV013515",
    valueDesc: "CR 2477N",
    label: "EV013515: CR 2477N"
  },
  {
    valueId: "EV013517",
    valueDesc: "B1ca",
    label: "EV013517: B1ca"
  },
  {
    valueId: "EV013518",
    valueDesc: "B2ca",
    label: "EV013518: B2ca"
  },
  {
    valueId: "EV013519",
    valueDesc: "Cca",
    label: "EV013519: Cca"
  },
  {
    valueId: "EV013520",
    valueDesc: "Dca",
    label: "EV013520: Dca"
  },
  {
    valueId: "EV013521",
    valueDesc: "Eca",
    label: "EV013521: Eca"
  },
  {
    valueId: "EV013522",
    valueDesc: "s1a",
    label: "EV013522: s1a"
  },
  {
    valueId: "EV013523",
    valueDesc: "s1b",
    label: "EV013523: s1b"
  },
  {
    valueId: "EV013524",
    valueDesc: "a1",
    label: "EV013524: a1"
  },
  {
    valueId: "EV013525",
    valueDesc: "a2",
    label: "EV013525: a2"
  },
  {
    valueId: "EV013526",
    valueDesc: "a3",
    label: "EV013526: a3"
  },
  {
    valueId: "EV013527",
    valueDesc: "Aca",
    label: "EV013527: Aca"
  },
  {
    valueId: "EV013528",
    valueDesc: "Fca",
    label: "EV013528: Fca"
  },
  {
    valueId: "EV013529",
    valueDesc: "Electropolished",
    label: "EV013529: Electropolished"
  },
  {
    valueId: "EV013530",
    valueDesc: "Water/fluid",
    label: "EV013530: Water/fluid"
  },
  {
    valueId: "EV013534",
    valueDesc: "NTR/A",
    label: "EV013534: NTR/A"
  },
  {
    valueId: "EV013535",
    valueDesc: "NTR/AB",
    label: "EV013535: NTR/AB"
  },
  {
    valueId: "EV013536",
    valueDesc: "NTR/B",
    label: "EV013536: NTR/B"
  },
  {
    valueId: "EV013537",
    valueDesc: "NTR/M",
    label: "EV013537: NTR/M"
  },
  {
    valueId: "EV013538",
    valueDesc: "Siberian larch",
    label: "EV013538: Siberian larch"
  },
  {
    valueId: "EV013539",
    valueDesc: "Industrially lacquered with primer and top layer",
    label: "EV013539: Industrially lacquered with primer and top layer"
  },
  {
    valueId: "EV013540",
    valueDesc: "Industrially applied coating",
    label: "EV013540: Industrially applied coating"
  },
  {
    valueId: "EV013541",
    valueDesc: "Industrially applied distemper (lime paint)",
    label: "EV013541: Industrially applied distemper (lime paint)"
  },
  {
    valueId: "EV013542",
    valueDesc: "Industrially lacquered with primer",
    label: "EV013542: Industrially lacquered with primer"
  },
  {
    valueId: "EV013548",
    valueDesc: "Buffer baseplate",
    label: "EV013548: Buffer baseplate"
  },
  {
    valueId: "EV013549",
    valueDesc: "Infiltration baseplate",
    label: "EV013549: Infiltration baseplate"
  },
  {
    valueId: "EV013550",
    valueDesc: "Side plate",
    label: "EV013550: Side plate"
  },
  {
    valueId: "EV013551",
    valueDesc: "Shear connector",
    label: "EV013551: Shear connector"
  },
  {
    valueId: "EV013552",
    valueDesc: "Infiltration unit clip",
    label: "EV013552: Infiltration unit clip"
  },
  {
    valueId: "EV013555",
    valueDesc: "Paraffin",
    label: "EV013555: Paraffin"
  },
  {
    valueId: "EV013556",
    valueDesc: "Salt hydrate",
    label: "EV013556: Salt hydrate"
  },
  {
    valueId: "EV013557",
    valueDesc: "Grains",
    label: "EV013557: Grains"
  },
  {
    valueId: "EV013558",
    valueDesc: "3.5/12",
    label: "EV013558: 3.5/12"
  },
  {
    valueId: "EV013559",
    valueDesc: "Coax 12",
    label: "EV013559: Coax 12"
  },
  {
    valueId: "EV013560",
    valueDesc: "TL 3",
    label: "EV013560: TL 3"
  },
  {
    valueId: "EV013561",
    valueDesc: "TL 6",
    label: "EV013561: TL 6"
  },
  {
    valueId: "EV013562",
    valueDesc: "Top side right",
    label: "EV013562: Top side right"
  },
  {
    valueId: "EV013566",
    valueDesc: "Back wall mounting",
    label: "EV013566: Back wall mounting"
  },
  {
    valueId: "EV013567",
    valueDesc: "Liquid soap",
    label: "EV013567: Liquid soap"
  },
  {
    valueId: "EV013568",
    valueDesc: "Foam soap",
    label: "EV013568: Foam soap"
  },
  {
    valueId: "EV013569",
    valueDesc: "Liquid soap/foam soap",
    label: "EV013569: Liquid soap/foam soap"
  },
  {
    valueId: "EV013570",
    valueDesc: "BE",
    label: "EV013570: BE"
  },
  {
    valueId: "EV013574",
    valueDesc: "Tapered",
    label: "EV013574: Tapered"
  },
  {
    valueId: "EV013580",
    valueDesc: "Dual-core",
    label: "EV013580: Dual-core"
  },
  {
    valueId: "EV013581",
    valueDesc: "Quad-core",
    label: "EV013581: Quad-core"
  },
  {
    valueId: "EV013582",
    valueDesc: "Hex-core",
    label: "EV013582: Hex-core"
  },
  {
    valueId: "EV013583",
    valueDesc: "Map data",
    label: "EV013583: Map data"
  },
  {
    valueId: "EV013584",
    valueDesc: "Front frame",
    label: "EV013584: Front frame"
  },
  {
    valueId: "EV013585",
    valueDesc: "41 x 62 mm",
    label: "EV013585: 41 x 62 mm"
  },
  {
    valueId: "EV013586",
    valueDesc: "Mineral casting",
    label: "EV013586: Mineral casting"
  },
  {
    valueId: "EV013587",
    valueDesc: "Stainless steel duplex (1.4517)",
    label: "EV013587: Stainless steel duplex (1.4517)"
  },
  {
    valueId: "EV013591",
    valueDesc: "IPX4D",
    label: "EV013591: IPX4D"
  },
  {
    valueId: "EV013595",
    valueDesc: "Creosote",
    label: "EV013595: Creosote"
  },
  {
    valueId: "EV013596",
    valueDesc: "Copper-salt impregnation",
    label: "EV013596: Copper-salt impregnation"
  },
  {
    valueId: "EV013597",
    valueDesc: "Breaking whip",
    label: "EV013597: Breaking whip"
  },
  {
    valueId: "EV013598",
    valueDesc: "Breaking chamber",
    label: "EV013598: Breaking chamber"
  },
  {
    valueId: "EV013599",
    valueDesc: "Vacuum insulated",
    label: "EV013599: Vacuum insulated"
  },
  {
    valueId: "EV013600",
    valueDesc: "Gas insulated",
    label: "EV013600: Gas insulated"
  },
  {
    valueId: "EV013601",
    valueDesc: "Air insulated",
    label: "EV013601: Air insulated"
  },
  {
    valueId: "EV013602",
    valueDesc: "Cable disconnector",
    label: "EV013602: Cable disconnector"
  },
  {
    valueId: "EV013603",
    valueDesc: "Switchgear disconnector",
    label: "EV013603: Switchgear disconnector"
  },
  {
    valueId: "EV013604",
    valueDesc: "Earthing disconnector",
    label: "EV013604: Earthing disconnector"
  },
  {
    valueId: "EV013605",
    valueDesc: "Overhead line disconnector",
    label: "EV013605: Overhead line disconnector"
  },
  {
    valueId: "EV013606",
    valueDesc: "EN-GJS-450-10",
    label: "EV013606: EN-GJS-450-10"
  },
  {
    valueId: "EV013608",
    valueDesc: "Fusegear switch",
    label: "EV013608: Fusegear switch"
  },
  {
    valueId: "EV013609",
    valueDesc: "Cable switch",
    label: "EV013609: Cable switch"
  },
  {
    valueId: "EV013610",
    valueDesc: "Loadbreak switch",
    label: "EV013610: Loadbreak switch"
  },
  {
    valueId: "EV013611",
    valueDesc: "Line switch",
    label: "EV013611: Line switch"
  },
  {
    valueId: "EV013612",
    valueDesc: "Contact pole",
    label: "EV013612: Contact pole"
  },
  {
    valueId: "EV013613",
    valueDesc: "Fuse disconnector poles",
    label: "EV013613: Fuse disconnector poles"
  },
  {
    valueId: "EV013614",
    valueDesc: "Contact whip",
    label: "EV013614: Contact whip"
  },
  {
    valueId: "EV013615",
    valueDesc: "Downwards contact extension pole",
    label: "EV013615: Downwards contact extension pole"
  },
  {
    valueId: "EV013616",
    valueDesc: "Cable connection bracket",
    label: "EV013616: Cable connection bracket"
  },
  {
    valueId: "EV013617",
    valueDesc: "Assembly kit, manual",
    label: "EV013617: Assembly kit, manual"
  },
  {
    valueId: "EV013618",
    valueDesc: "Assembly kit, motorized",
    label: "EV013618: Assembly kit, motorized"
  },
  {
    valueId: "EV013619",
    valueDesc: "Switchgear shaft",
    label: "EV013619: Switchgear shaft"
  },
  {
    valueId: "EV013620",
    valueDesc: "Shaft support",
    label: "EV013620: Shaft support"
  },
  {
    valueId: "EV013621",
    valueDesc: "Service rod",
    label: "EV013621: Service rod"
  },
  {
    valueId: "EV013622",
    valueDesc: "Connector Fe/Al",
    label: "EV013622: Connector Fe/Al"
  },
  {
    valueId: "EV013623",
    valueDesc: "Connector Cu",
    label: "EV013623: Connector Cu"
  },
  {
    valueId: "EV013624",
    valueDesc: "Connector Al/Cu",
    label: "EV013624: Connector Al/Cu"
  },
  {
    valueId: "EV013625",
    valueDesc: "Flexible connection bracket",
    label: "EV013625: Flexible connection bracket"
  },
  {
    valueId: "EV013626",
    valueDesc: "Bracket, surge arrestor",
    label: "EV013626: Bracket, surge arrestor"
  },
  {
    valueId: "EV013627",
    valueDesc: "Extension bar",
    label: "EV013627: Extension bar"
  },
  {
    valueId: "EV013628",
    valueDesc: "Fuse protection tube",
    label: "EV013628: Fuse protection tube"
  },
  {
    valueId: "EV013629",
    valueDesc: "Clutch rod",
    label: "EV013629: Clutch rod"
  },
  {
    valueId: "EV013630",
    valueDesc: "Insulator rod",
    label: "EV013630: Insulator rod"
  },
  {
    valueId: "EV013631",
    valueDesc: "Hand drive",
    label: "EV013631: Hand drive"
  },
  {
    valueId: "EV013632",
    valueDesc: "Pole root brace",
    label: "EV013632: Pole root brace"
  },
  {
    valueId: "EV013633",
    valueDesc: "Rock bolt",
    label: "EV013633: Rock bolt"
  },
  {
    valueId: "EV013634",
    valueDesc: "Toothed plate",
    label: "EV013634: Toothed plate"
  },
  {
    valueId: "EV013635",
    valueDesc: "Support pole bracket (hinge)",
    label: "EV013635: Support pole bracket (hinge)"
  },
  {
    valueId: "EV013636",
    valueDesc: "Bottom pin",
    label: "EV013636: Bottom pin"
  },
  {
    valueId: "EV013637",
    valueDesc: "Base brace (straight)",
    label: "EV013637: Base brace (straight)"
  },
  {
    valueId: "EV013638",
    valueDesc: "Base cover",
    label: "EV013638: Base cover"
  },
  {
    valueId: "EV013640",
    valueDesc: "Cross brace (bended)",
    label: "EV013640: Cross brace (bended)"
  },
  {
    valueId: "EV013641",
    valueDesc: "Pole cap",
    label: "EV013641: Pole cap"
  },
  {
    valueId: "EV013644",
    valueDesc: "Q",
    label: "EV013644: Q"
  },
  {
    valueId: "EV013646",
    valueDesc: "RB (Rear Backward)",
    label: "EV013646: RB (Rear Backward)"
  },
  {
    valueId: "EV013648",
    valueDesc: "Crushed marble",
    label: "EV013648: Crushed marble"
  },
  {
    valueId: "EV013651",
    valueDesc: "Console and plug bolt",
    label: "EV013651: Console and plug bolt"
  },
  {
    valueId: "EV013652",
    valueDesc: "Pin insulator",
    label: "EV013652: Pin insulator"
  },
  {
    valueId: "EV013653",
    valueDesc: "Support insulator",
    label: "EV013653: Support insulator"
  },
  {
    valueId: "EV013654",
    valueDesc: "Tension insulator",
    label: "EV013654: Tension insulator"
  },
  {
    valueId: "EV013655",
    valueDesc: "Suspension insulator",
    label: "EV013655: Suspension insulator"
  },
  {
    valueId: "EV013656",
    valueDesc: "Strain insulator",
    label: "EV013656: Strain insulator"
  },
  {
    valueId: "EV013657",
    valueDesc: "Cap and pin insulator",
    label: "EV013657: Cap and pin insulator"
  },
  {
    valueId: "EV013658",
    valueDesc: "Stay wire anchor",
    label: "EV013658: Stay wire anchor"
  },
  {
    valueId: "EV013659",
    valueDesc: "Stay wire fastener",
    label: "EV013659: Stay wire fastener"
  },
  {
    valueId: "EV013660",
    valueDesc: "Stay wire tightener",
    label: "EV013660: Stay wire tightener"
  },
  {
    valueId: "EV013661",
    valueDesc: "Stay wire base plate",
    label: "EV013661: Stay wire base plate"
  },
  {
    valueId: "EV013663",
    valueDesc: "Stay wire marker",
    label: "EV013663: Stay wire marker"
  },
  {
    valueId: "EV013664",
    valueDesc: "Stay wire link",
    label: "EV013664: Stay wire link"
  },
  {
    valueId: "EV013665",
    valueDesc: "Stay wire brace",
    label: "EV013665: Stay wire brace"
  },
  {
    valueId: "EV013666",
    valueDesc: "Extension eye",
    label: "EV013666: Extension eye"
  },
  {
    valueId: "EV013667",
    valueDesc: "Thimble",
    label: "EV013667: Thimble"
  },
  {
    valueId: "EV013668",
    valueDesc: "Dead end tie",
    label: "EV013668: Dead end tie"
  },
  {
    valueId: "EV013670",
    valueDesc: "Automatic joint",
    label: "EV013670: Automatic joint"
  },
  {
    valueId: "EV013671",
    valueDesc: "Eye rod",
    label: "EV013671: Eye rod"
  },
  {
    valueId: "EV013673",
    valueDesc: "Rock eye bolt",
    label: "EV013673: Rock eye bolt"
  },
  {
    valueId: "EV013674",
    valueDesc: "Stay wire set",
    label: "EV013674: Stay wire set"
  },
  {
    valueId: "EV013675",
    valueDesc: "Automatic dead end",
    label: "EV013675: Automatic dead end"
  },
  {
    valueId: "EV013685",
    valueDesc: "RHS (Rectangular Hollow Section)",
    label: "EV013685: RHS (Rectangular Hollow Section)"
  },
  {
    valueId: "EV013687",
    valueDesc: "Support cross arm",
    label: "EV013687: Support cross arm"
  },
  {
    valueId: "EV013688",
    valueDesc: "Deadend cross arm",
    label: "EV013688: Deadend cross arm"
  },
  {
    valueId: "EV013689",
    valueDesc: "Angled cross arm",
    label: "EV013689: Angled cross arm"
  },
  {
    valueId: "EV013690",
    valueDesc: "One pole",
    label: "EV013690: One pole"
  },
  {
    valueId: "EV013691",
    valueDesc: "Two pole",
    label: "EV013691: Two pole"
  },
  {
    valueId: "EV013692",
    valueDesc: "One pole/two pole",
    label: "EV013692: One pole/two pole"
  },
  {
    valueId: "EV013693",
    valueDesc: "Cross arm mounting set",
    label: "EV013693: Cross arm mounting set"
  },
  {
    valueId: "EV013694",
    valueDesc: "Suspension insulator clevis",
    label: "EV013694: Suspension insulator clevis"
  },
  {
    valueId: "EV013695",
    valueDesc: "Suspension insulator y-clevis/ball",
    label: "EV013695: Suspension insulator y-clevis/ball"
  },
  {
    valueId: "EV013696",
    valueDesc: "Suspension insulator socket/ball",
    label: "EV013696: Suspension insulator socket/ball"
  },
  {
    valueId: "EV013697",
    valueDesc: "Suspension insulator oval eye/ball",
    label: "EV013697: Suspension insulator oval eye/ball"
  },
  {
    valueId: "EV013698",
    valueDesc: "Cross arm support",
    label: "EV013698: Cross arm support"
  },
  {
    valueId: "EV013699",
    valueDesc: "Insulator pin",
    label: "EV013699: Insulator pin"
  },
  {
    valueId: "EV013700",
    valueDesc: "U-bracket",
    label: "EV013700: U-bracket"
  },
  {
    valueId: "EV013701",
    valueDesc: "PE-Xa",
    label: "EV013701: PE-Xa"
  },
  {
    valueId: "EV013702",
    valueDesc: "PE-Xb",
    label: "EV013702: PE-Xb"
  },
  {
    valueId: "EV013706",
    valueDesc: "Suspension/tension insulator",
    label: "EV013706: Suspension/tension insulator"
  },
  {
    valueId: "EV013707",
    valueDesc: "DN 40 - DN 50",
    label: "EV013707: DN 40 - DN 50"
  },
  {
    valueId: "EV013709",
    valueDesc: "0.6/1 (1.2) kV",
    label: "EV013709: 0.6/1 (1.2) kV"
  },
  {
    valueId: "EV013710",
    valueDesc: "3.6/6 (7.2) kV",
    label: "EV013710: 3.6/6 (7.2) kV"
  },
  {
    valueId: "EV013711",
    valueDesc: "3.8/6.6 (7.2) kV",
    label: "EV013711: 3.8/6.6 (7.2) kV"
  },
  {
    valueId: "EV013712",
    valueDesc: "6/10 (12) kV",
    label: "EV013712: 6/10 (12) kV"
  },
  {
    valueId: "EV013713",
    valueDesc: "6.35/11 (12) kV",
    label: "EV013713: 6.35/11 (12) kV"
  },
  {
    valueId: "EV013714",
    valueDesc: "8.7/15 (17.5) kV",
    label: "EV013714: 8.7/15 (17.5) kV"
  },
  {
    valueId: "EV013715",
    valueDesc: "12/20 (24) kV",
    label: "EV013715: 12/20 (24) kV"
  },
  {
    valueId: "EV013716",
    valueDesc: "12.7/22 (24) kV",
    label: "EV013716: 12.7/22 (24) kV"
  },
  {
    valueId: "EV013717",
    valueDesc: "18/30 (36) kV",
    label: "EV013717: 18/30 (36) kV"
  },
  {
    valueId: "EV013718",
    valueDesc: "19/33 (36) kV",
    label: "EV013718: 19/33 (36) kV"
  },
  {
    valueId: "EV013719",
    valueDesc: "20.8/36 (42) kV",
    label: "EV013719: 20.8/36 (42) kV"
  },
  {
    valueId: "EV013720",
    valueDesc: "36/63 (72.5) kV",
    label: "EV013720: 36/63 (72.5) kV"
  },
  {
    valueId: "EV013721",
    valueDesc: "40/69 (72.5) kV",
    label: "EV013721: 40/69 (72.5) kV"
  },
  {
    valueId: "EV013722",
    valueDesc: "52/90 (100) kV",
    label: "EV013722: 52/90 (100) kV"
  },
  {
    valueId: "EV013723",
    valueDesc: "64/110 (123) kV",
    label: "EV013723: 64/110 (123) kV"
  },
  {
    valueId: "EV013724",
    valueDesc: "76/132 (145) kV",
    label: "EV013724: 76/132 (145) kV"
  },
  {
    valueId: "EV013725",
    valueDesc: "87/150 (170) kV",
    label: "EV013725: 87/150 (170) kV"
  },
  {
    valueId: "EV013726",
    valueDesc: "130/225 (245) kV",
    label: "EV013726: 130/225 (245) kV"
  },
  {
    valueId: "EV013727",
    valueDesc: "160/275 (300) kV",
    label: "EV013727: 160/275 (300) kV"
  },
  {
    valueId: "EV013728",
    valueDesc: "200/345 (362) kV",
    label: "EV013728: 200/345 (362) kV"
  },
  {
    valueId: "EV013729",
    valueDesc: "230/400 (420) kV",
    label: "EV013729: 230/400 (420) kV"
  },
  {
    valueId: "EV013730",
    valueDesc: "290/500 (550) kV",
    label: "EV013730: 290/500 (550) kV"
  },
  {
    valueId: "EV013731",
    valueDesc: "Hybrid-shrink",
    label: "EV013731: Hybrid-shrink"
  },
  {
    valueId: "EV013732",
    valueDesc: "Paper/plastic",
    label: "EV013732: Paper/plastic"
  },
  {
    valueId: "EV013733",
    valueDesc: "NHC",
    label: "EV013733: NHC"
  },
  {
    valueId: "EV013734",
    valueDesc: "With",
    label: "EV013734: With"
  },
  {
    valueId: "EV013735",
    valueDesc: "Penetrable",
    label: "EV013735: Penetrable"
  },
  {
    valueId: "EV013738",
    valueDesc: "Desk built-in device",
    label: "EV013738: Desk built-in device"
  },
  {
    valueId: "EV013742",
    valueDesc: "225",
    label: "EV013742: 225"
  },
  {
    valueId: "EV013743",
    valueDesc: "230",
    label: "EV013743: 230"
  },
  {
    valueId: "EV013744",
    valueDesc: "180/230",
    label: "EV013744: 180/230"
  },
  {
    valueId: "EV013745",
    valueDesc: "Tongue and groove (short edge)",
    label: "EV013745: Tongue and groove (short edge)"
  },
  {
    valueId: "EV013747",
    valueDesc: "AFNOR NFS 87500 / IRIG B",
    label: "EV013747: AFNOR NFS 87500 / IRIG B"
  },
  {
    valueId: "EV013748",
    valueDesc: "Network Time Protocol (NTP) - wired RJ45",
    label: "EV013748: Network Time Protocol (NTP) - wired RJ45"
  },
  {
    valueId: "EV013749",
    valueDesc: "Network Time Protocol (NTP) - wireless WLAN",
    label: "EV013749: Network Time Protocol (NTP) - wireless WLAN"
  },
  {
    valueId: "EV013750",
    valueDesc: "ASCII RS 485",
    label: "EV013750: ASCII RS 485"
  },
  {
    valueId: "EV013751",
    valueDesc: "Hours, minutes",
    label: "EV013751: Hours, minutes"
  },
  {
    valueId: "EV013752",
    valueDesc: "Hours, minutes, seconds",
    label: "EV013752: Hours, minutes, seconds"
  },
  {
    valueId: "EV013753",
    valueDesc: "Programming circuits",
    label: "EV013753: Programming circuits"
  },
  {
    valueId: "EV013754",
    valueDesc: "CR 2354",
    label: "EV013754: CR 2354"
  },
  {
    valueId: "EV013755",
    valueDesc: "Waxed",
    label: "EV013755: Waxed"
  },
  {
    valueId: "EV013757",
    valueDesc: "Starter",
    label: "EV013757: Starter"
  },
  {
    valueId: "EV013758",
    valueDesc: "Traction",
    label: "EV013758: Traction"
  },
  {
    valueId: "EV013759",
    valueDesc: "Semi-traction",
    label: "EV013759: Semi-traction"
  },
  {
    valueId: "EV013760",
    valueDesc: "Cyclic",
    label: "EV013760: Cyclic"
  },
  {
    valueId: "EV013761",
    valueDesc: "Deep cycle",
    label: "EV013761: Deep cycle"
  },
  {
    valueId: "EV013762",
    valueDesc: "Back-up/floating",
    label: "EV013762: Back-up/floating"
  },
  {
    valueId: "EV013763",
    valueDesc: "OLED",
    label: "EV013763: OLED"
  },
  {
    valueId: "EV013766",
    valueDesc: "Ultra HD",
    label: "EV013766: Ultra HD"
  },
  {
    valueId: "EV013767",
    valueDesc: "Full HD",
    label: "EV013767: Full HD"
  },
  {
    valueId: "EV013768",
    valueDesc: "HD Ready",
    label: "EV013768: HD Ready"
  },
  {
    valueId: "EV013769",
    valueDesc: "Wires and connector",
    label: "EV013769: Wires and connector"
  },
  {
    valueId: "EV013770",
    valueDesc: "Faston 4.8",
    label: "EV013770: Faston 4.8"
  },
  {
    valueId: "EV013771",
    valueDesc: "Faston 6.35",
    label: "EV013771: Faston 6.35"
  },
  {
    valueId: "EV013772",
    valueDesc: "Faston 4.8 (-) and 6.35 (+)",
    label: "EV013772: Faston 4.8 (-) and 6.35 (+)"
  },
  {
    valueId: "EV013773",
    valueDesc: "Female insert M5",
    label: "EV013773: Female insert M5"
  },
  {
    valueId: "EV013774",
    valueDesc: "Female insert M6",
    label: "EV013774: Female insert M6"
  },
  {
    valueId: "EV013775",
    valueDesc: "Female insert M8",
    label: "EV013775: Female insert M8"
  },
  {
    valueId: "EV013776",
    valueDesc: "Male insert M6",
    label: "EV013776: Male insert M6"
  },
  {
    valueId: "EV013777",
    valueDesc: "Male insert M8",
    label: "EV013777: Male insert M8"
  },
  {
    valueId: "EV013778",
    valueDesc: "Metric bolt M5",
    label: "EV013778: Metric bolt M5"
  },
  {
    valueId: "EV013779",
    valueDesc: "Metric bolt M10",
    label: "EV013779: Metric bolt M10"
  },
  {
    valueId: "EV013780",
    valueDesc: "Polyphenylsulfone (PPSU)",
    label: "EV013780: Polyphenylsulfone (PPSU)"
  },
  {
    valueId: "EV013783",
    valueDesc: "PE-Xc",
    label: "EV013783: PE-Xc"
  },
  {
    valueId: "EV013784",
    valueDesc: "PE-RT I",
    label: "EV013784: PE-RT I"
  },
  {
    valueId: "EV013785",
    valueDesc: "PE-RT II",
    label: "EV013785: PE-RT II"
  },
  {
    valueId: "EV013792",
    valueDesc: "320x240",
    label: "EV013792: 320x240"
  },
  {
    valueId: "EV013799",
    valueDesc: "1280x800",
    label: "EV013799: 1280x800"
  },
  {
    valueId: "EV013800",
    valueDesc: "A (250 A)",
    label: "EV013800: A (250 A)"
  },
  {
    valueId: "EV013801",
    valueDesc: "B (400 A)",
    label: "EV013801: B (400 A)"
  },
  {
    valueId: "EV013802",
    valueDesc: "C (630 A)",
    label: "EV013802: C (630 A)"
  },
  {
    valueId: "EV013803",
    valueDesc: "D (1250 A)",
    label: "EV013803: D (1250 A)"
  },
  {
    valueId: "EV013804",
    valueDesc: "E (1600 A)",
    label: "EV013804: E (1600 A)"
  },
  {
    valueId: "EV013805",
    valueDesc: "F (2500 A)",
    label: "EV013805: F (2500 A)"
  },
  {
    valueId: "EV013807",
    valueDesc: "Lifting lug",
    label: "EV013807: Lifting lug"
  },
  {
    valueId: "EV013808",
    valueDesc: "Earthing point",
    label: "EV013808: Earthing point"
  },
  {
    valueId: "EV013811",
    valueDesc: "Oil drying agent",
    label: "EV013811: Oil drying agent"
  },
  {
    valueId: "EV013812",
    valueDesc: "Insulator adapter",
    label: "EV013812: Insulator adapter"
  },
  {
    valueId: "EV013813",
    valueDesc: "Fixing flange",
    label: "EV013813: Fixing flange"
  },
  {
    valueId: "EV013814",
    valueDesc: "Arc protection device",
    label: "EV013814: Arc protection device"
  },
  {
    valueId: "EV013815",
    valueDesc: "Surge arrester bracket",
    label: "EV013815: Surge arrester bracket"
  },
  {
    valueId: "EV013818",
    valueDesc: "Compact camera",
    label: "EV013818: Compact camera"
  },
  {
    valueId: "EV013819",
    valueDesc: "System camera",
    label: "EV013819: System camera"
  },
  {
    valueId: "EV013820",
    valueDesc: "Reflex camera",
    label: "EV013820: Reflex camera"
  },
  {
    valueId: "EV013821",
    valueDesc: "CMOS sensor",
    label: "EV013821: CMOS sensor"
  },
  {
    valueId: "EV013822",
    valueDesc: "CCD sensor",
    label: "EV013822: CCD sensor"
  },
  {
    valueId: "EV013826",
    valueDesc: "Animal guard",
    label: "EV013826: Animal guard"
  },
  {
    valueId: "EV013827",
    valueDesc: "Italian type P17/11",
    label: "EV013827: Italian type P17/11"
  },
  {
    valueId: "EV013828",
    valueDesc: "Italian type P10",
    label: "EV013828: Italian type P10"
  },
  {
    valueId: "EV013830",
    valueDesc: "Net/mesh",
    label: "EV013830: Net/mesh"
  },
  {
    valueId: "EV013831",
    valueDesc: "AA, free air gap",
    label: "EV013831: AA, free air gap"
  },
  {
    valueId: "EV013834",
    valueDesc: "AD",
    label: "EV013834: AD"
  },
  {
    valueId: "EV013837",
    valueDesc: "BA",
    label: "EV013837: BA"
  },
  {
    valueId: "EV013838",
    valueDesc: "CA",
    label: "EV013838: CA"
  },
  {
    valueId: "EV013839",
    valueDesc: "DA",
    label: "EV013839: DA"
  },
  {
    valueId: "EV013840",
    valueDesc: "DB",
    label: "EV013840: DB"
  },
  {
    valueId: "EV013842",
    valueDesc: "EB",
    label: "EV013842: EB"
  },
  {
    valueId: "EV013843",
    valueDesc: "EC",
    label: "EV013843: EC"
  },
  {
    valueId: "EV013844",
    valueDesc: "ED",
    label: "EV013844: ED"
  },
  {
    valueId: "EV013845",
    valueDesc: "GA",
    label: "EV013845: GA"
  },
  {
    valueId: "EV013846",
    valueDesc: "GB",
    label: "EV013846: GB"
  },
  {
    valueId: "EV013847",
    valueDesc: "HA",
    label: "EV013847: HA"
  },
  {
    valueId: "EV013848",
    valueDesc: "HC",
    label: "EV013848: HC"
  },
  {
    valueId: "EV013849",
    valueDesc: "HD",
    label: "EV013849: HD"
  },
  {
    valueId: "EV013850",
    valueDesc: "LA",
    label: "EV013850: LA"
  },
  {
    valueId: "EV013851",
    valueDesc: "LB",
    label: "EV013851: LB"
  },
  {
    valueId: "EV013855",
    valueDesc: "M34 x 1.5",
    label: "EV013855: M34 x 1.5"
  },
  {
    valueId: "EV013856",
    valueDesc: "C spout",
    label: "EV013856: C spout"
  },
  {
    valueId: "EV013857",
    valueDesc: "L spout",
    label: "EV013857: L spout"
  },
  {
    valueId: "EV013858",
    valueDesc: "J spout",
    label: "EV013858: J spout"
  },
  {
    valueId: "EV013859",
    valueDesc: "Double jointed spout",
    label: "EV013859: Double jointed spout"
  },
  {
    valueId: "EV013860",
    valueDesc: "14",
    label: "EV013860: 14"
  },
  {
    valueId: "EV013861",
    valueDesc: "18",
    label: "EV013861: 18"
  },
  {
    valueId: "EV013865",
    valueDesc: "Brass/Plastic",
    label: "EV013865: Brass/Plastic"
  },
  {
    valueId: "EV013870",
    valueDesc: "Solid (S)",
    label: "EV013870: Solid (S)"
  },
  {
    valueId: "EV013879",
    valueDesc: "Clear glass",
    label: "EV013879: Clear glass"
  },
  {
    valueId: "EV013880",
    valueDesc: "Frosted glass",
    label: "EV013880: Frosted glass"
  },
  {
    valueId: "EV013881",
    valueDesc: "Cotswold glass",
    label: "EV013881: Cotswold glass"
  },
  {
    valueId: "EV013883",
    valueDesc: "Clear cathedral glass",
    label: "EV013883: Clear cathedral glass"
  },
  {
    valueId: "EV013884",
    valueDesc: "Darkened antique glass",
    label: "EV013884: Darkened antique glass"
  },
  {
    valueId: "EV013885",
    valueDesc: "HDF",
    label: "EV013885: HDF"
  },
  {
    valueId: "EV013886",
    valueDesc: "Stile",
    label: "EV013886: Stile"
  },
  {
    valueId: "EV013887",
    valueDesc: "Moulded",
    label: "EV013887: Moulded"
  },
  {
    valueId: "EV013888",
    valueDesc: "Filling",
    label: "EV013888: Filling"
  },
  {
    valueId: "EV013890",
    valueDesc: "Entrance door",
    label: "EV013890: Entrance door"
  },
  {
    valueId: "EV013893",
    valueDesc: "Sauna door",
    label: "EV013893: Sauna door"
  },
  {
    valueId: "EV013895",
    valueDesc: "Booth door",
    label: "EV013895: Booth door"
  },
  {
    valueId: "EV013897",
    valueDesc: "Gothic glass",
    label: "EV013897: Gothic glass"
  },
  {
    valueId: "EV013907",
    valueDesc: "Inward opening",
    label: "EV013907: Inward opening"
  },
  {
    valueId: "EV013908",
    valueDesc: "Outward opening",
    label: "EV013908: Outward opening"
  },
  {
    valueId: "EV013910",
    valueDesc: "Tophinged",
    label: "EV013910: Tophinged"
  },
  {
    valueId: "EV013911",
    valueDesc: "Sidehinged",
    label: "EV013911: Sidehinged"
  },
  {
    valueId: "EV013912",
    valueDesc: "Top swing",
    label: "EV013912: Top swing"
  },
  {
    valueId: "EV013913",
    valueDesc: "Vertical slidehinged/side turn",
    label: "EV013913: Vertical slidehinged/side turn"
  },
  {
    valueId: "EV013914",
    valueDesc: "Two way",
    label: "EV013914: Two way"
  },
  {
    valueId: "EV013915",
    valueDesc: "Bottomhinged",
    label: "EV013915: Bottomhinged"
  },
  {
    valueId: "EV013916",
    valueDesc: "Fixed frame",
    label: "EV013916: Fixed frame"
  },
  {
    valueId: "EV013925",
    valueDesc: "Wood/aluminium",
    label: "EV013925: Wood/aluminium"
  },
  {
    valueId: "EV013926",
    valueDesc: "Split glass",
    label: "EV013926: Split glass"
  },
  {
    valueId: "EV013927",
    valueDesc: "Wall surface mounting/ceiling surface mounting",
    label: "EV013927: Wall surface mounting/ceiling surface mounting"
  },
  {
    valueId: "EV013928",
    valueDesc: "GQ9.5",
    label: "EV013928: GQ9.5"
  },
  {
    valueId: "EV013929",
    valueDesc: "P5",
    label: "EV013929: P5"
  },
  {
    valueId: "EV013930",
    valueDesc: "h60",
    label: "EV013930: h60"
  },
  {
    valueId: "EV013931",
    valueDesc: "2GX11",
    label: "EV013931: 2GX11"
  },
  {
    valueId: "EV013932",
    valueDesc: "GU7",
    label: "EV013932: GU7"
  },
  {
    valueId: "EV013935",
    valueDesc: "Ending",
    label: "EV013935: Ending"
  },
  {
    valueId: "EV013939",
    valueDesc: "B1",
    label: "EV013939: B1"
  },
  {
    valueId: "EV013940",
    valueDesc: "Truck tyre",
    label: "EV013940: Truck tyre"
  },
  {
    valueId: "EV013941",
    valueDesc: "Forklift truck tyre",
    label: "EV013941: Forklift truck tyre"
  },
  {
    valueId: "EV013964",
    valueDesc: "CLASS 30",
    label: "EV013964: CLASS 30"
  },
  {
    valueId: "EV013965",
    valueDesc: "Class 1M",
    label: "EV013965: Class 1M"
  },
  {
    valueId: "EV013966",
    valueDesc: "Class 2M",
    label: "EV013966: Class 2M"
  },
  {
    valueId: "EV013967",
    valueDesc: "Class 3R",
    label: "EV013967: Class 3R"
  },
  {
    valueId: "EV013968",
    valueDesc: "Class 3B",
    label: "EV013968: Class 3B"
  },
  {
    valueId: "EV013969",
    valueDesc: "AluPEX",
    label: "EV013969: AluPEX"
  },
  {
    valueId: "EV013970",
    valueDesc: "Push-Open",
    label: "EV013970: Push-Open"
  },
  {
    valueId: "EV013971",
    valueDesc: "Quadruple",
    label: "EV013971: Quadruple"
  },
  {
    valueId: "EV013972",
    valueDesc: "Barbs",
    label: "EV013972: Barbs"
  },
  {
    valueId: "EV013974",
    valueDesc: "Aluminium/EPS",
    label: "EV013974: Aluminium/EPS"
  },
  {
    valueId: "EV013975",
    valueDesc: "IP68/IPX9K",
    label: "EV013975: IP68/IPX9K"
  },
  {
    valueId: "EV013976",
    valueDesc: "Complete module",
    label: "EV013976: Complete module"
  },
  {
    valueId: "EV013979",
    valueDesc: "Isolated female 6.35 connectors",
    label: "EV013979: Isolated female 6.35 connectors"
  },
  {
    valueId: "EV013980",
    valueDesc: "Crocodile clip",
    label: "EV013980: Crocodile clip"
  },
  {
    valueId: "EV013981",
    valueDesc: "Foolproof double connector",
    label: "EV013981: Foolproof double connector"
  },
  {
    valueId: "EV013982",
    valueDesc: "Double connector with fuse",
    label: "EV013982: Double connector with fuse"
  },
  {
    valueId: "EV013983",
    valueDesc: "4-pins connector",
    label: "EV013983: 4-pins connector"
  },
  {
    valueId: "EV013984",
    valueDesc: "Without galvanic separation",
    label: "EV013984: Without galvanic separation"
  },
  {
    valueId: "EV013985",
    valueDesc: "CR 2325",
    label: "EV013985: CR 2325"
  },
  {
    valueId: "EV013986",
    valueDesc: "CR 1225",
    label: "EV013986: CR 1225"
  },
  {
    valueId: "EV013987",
    valueDesc: "CR 1025",
    label: "EV013987: CR 1025"
  },
  {
    valueId: "EV013988",
    valueDesc: "M22 x 1.0",
    label: "EV013988: M22 x 1.0"
  },
  {
    valueId: "EV013989",
    valueDesc: "M20 x 1.0",
    label: "EV013989: M20 x 1.0"
  },
  {
    valueId: "EV013990",
    valueDesc: "M24 x 1.0",
    label: "EV013990: M24 x 1.0"
  },
  {
    valueId: "EV013994",
    valueDesc: "Front-opening freezer",
    label: "EV013994: Front-opening freezer"
  },
  {
    valueId: "EV013995",
    valueDesc: "Top-opening freezer",
    label: "EV013995: Top-opening freezer"
  },
  {
    valueId: "EV013996",
    valueDesc: "Lead (dry cell, gel)",
    label: "EV013996: Lead (dry cell, gel)"
  },
  {
    valueId: "EV013997",
    valueDesc: "Z-rail",
    label: "EV013997: Z-rail"
  },
  {
    valueId: "EV013998",
    valueDesc: "Brushed/grinded",
    label: "EV013998: Brushed/grinded"
  },
  {
    valueId: "EV013999",
    valueDesc: "Earthquake kit",
    label: "EV013999: Earthquake kit"
  },
  {
    valueId: "EV014005",
    valueDesc: "Electronic release",
    label: "EV014005: Electronic release"
  },
  {
    valueId: "EV014006",
    valueDesc: "Single direction",
    label: "EV014006: Single direction"
  },
  {
    valueId: "EV014007",
    valueDesc: "NH0, NH1, NH2",
    label: "EV014007: NH0, NH1, NH2"
  },
  {
    valueId: "EV014008",
    valueDesc: "Mechanical combi indicator",
    label: "EV014008: Mechanical combi indicator"
  },
  {
    valueId: "EV014009",
    valueDesc: "Mechanical top-plate indicator",
    label: "EV014009: Mechanical top-plate indicator"
  },
  {
    valueId: "EV014012",
    valueDesc: "Capsule",
    label: "EV014012: Capsule"
  },
  {
    valueId: "EV014013",
    valueDesc: "Rope tensioner",
    label: "EV014013: Rope tensioner"
  },
  {
    valueId: "EV014014",
    valueDesc: "SDCM1",
    label: "EV014014: SDCM1"
  },
  {
    valueId: "EV014015",
    valueDesc: "SDCM2",
    label: "EV014015: SDCM2"
  },
  {
    valueId: "EV014016",
    valueDesc: "SDCM3",
    label: "EV014016: SDCM3"
  },
  {
    valueId: "EV014017",
    valueDesc: "SDCM4",
    label: "EV014017: SDCM4"
  },
  {
    valueId: "EV014018",
    valueDesc: "SDCM5",
    label: "EV014018: SDCM5"
  },
  {
    valueId: "EV014019",
    valueDesc: "SDCM6",
    label: "EV014019: SDCM6"
  },
  {
    valueId: "EV014020",
    valueDesc: "SDCM7",
    label: "EV014020: SDCM7"
  },
  {
    valueId: "EV014021",
    valueDesc: "RG0",
    label: "EV014021: RG0"
  },
  {
    valueId: "EV014022",
    valueDesc: "RG1",
    label: "EV014022: RG1"
  },
  {
    valueId: "EV014023",
    valueDesc: "RG2",
    label: "EV014023: RG2"
  },
  {
    valueId: "EV014024",
    valueDesc: "RG3",
    label: "EV014024: RG3"
  },
  {
    valueId: "EV014025",
    valueDesc: "RG4",
    label: "EV014025: RG4"
  },
  {
    valueId: "EV014032",
    valueDesc: "Intel Core i3",
    label: "EV014032: Intel Core i3"
  },
  {
    valueId: "EV014033",
    valueDesc: "Intel Xeon",
    label: "EV014033: Intel Xeon"
  },
  {
    valueId: "EV014034",
    valueDesc: "12K",
    label: "EV014034: 12K"
  },
  {
    valueId: "EV014035",
    valueDesc: "3R",
    label: "EV014035: 3R"
  },
  {
    valueId: "EV014036",
    valueDesc: "3RX",
    label: "EV014036: 3RX"
  },
  {
    valueId: "EV014037",
    valueDesc: "3S",
    label: "EV014037: 3S"
  },
  {
    valueId: "EV014038",
    valueDesc: "3SX",
    label: "EV014038: 3SX"
  },
  {
    valueId: "EV014039",
    valueDesc: "3X",
    label: "EV014039: 3X"
  },
  {
    valueId: "EV014040",
    valueDesc: "4X",
    label: "EV014040: 4X"
  },
  {
    valueId: "EV014041",
    valueDesc: "6P",
    label: "EV014041: 6P"
  },
  {
    valueId: "EV014042",
    valueDesc: "Applied automatically",
    label: "EV014042: Applied automatically"
  },
  {
    valueId: "EV014043",
    valueDesc: "Printed serial code",
    label: "EV014043: Printed serial code"
  },
  {
    valueId: "EV014044",
    valueDesc: "Electronic serial code",
    label: "EV014044: Electronic serial code"
  },
  {
    valueId: "EV014045",
    valueDesc: "Month",
    label: "EV014045: Month"
  },
  {
    valueId: "EV014046",
    valueDesc: "Non expiring",
    label: "EV014046: Non expiring"
  },
  {
    valueId: "EV014047",
    valueDesc: "Absolute encoder",
    label: "EV014047: Absolute encoder"
  },
  {
    valueId: "EV014048",
    valueDesc: "Connector M23",
    label: "EV014048: Connector M23"
  },
  {
    valueId: "EV014049",
    valueDesc: "Fixing clip",
    label: "EV014049: Fixing clip"
  },
  {
    valueId: "EV014050",
    valueDesc: "Actuator pin",
    label: "EV014050: Actuator pin"
  },
  {
    valueId: "EV014051",
    valueDesc: "Code strip",
    label: "EV014051: Code strip"
  },
  {
    valueId: "EV014052",
    valueDesc: "Flange plate",
    label: "EV014052: Flange plate"
  },
  {
    valueId: "EV014055",
    valueDesc: "Objective/lens",
    label: "EV014055: Objective/lens"
  },
  {
    valueId: "EV014057",
    valueDesc: "Probe",
    label: "EV014057: Probe"
  },
  {
    valueId: "EV014058",
    valueDesc: "Fixed mounted",
    label: "EV014058: Fixed mounted"
  },
  {
    valueId: "EV014059",
    valueDesc: "CEE socket outlet 3-pole 32 A",
    label: "EV014059: CEE socket outlet 3-pole 32 A"
  },
  {
    valueId: "EV014062",
    valueDesc: "MA",
    label: "EV014062: MA"
  },
  {
    valueId: "EV014063",
    valueDesc: "6x23 mm",
    label: "EV014063: 6x23 mm"
  },
  {
    valueId: "EV014064",
    valueDesc: "8x23 mm",
    label: "EV014064: 8x23 mm"
  },
  {
    valueId: "EV014065",
    valueDesc: "8x32 mm",
    label: "EV014065: 8x32 mm"
  },
  {
    valueId: "EV014066",
    valueDesc: "10x26 mm",
    label: "EV014066: 10x26 mm"
  },
  {
    valueId: "EV014067",
    valueDesc: "10x32 mm",
    label: "EV014067: 10x32 mm"
  },
  {
    valueId: "EV014068",
    valueDesc: "Shell",
    label: "EV014068: Shell"
  },
  {
    valueId: "EV014069",
    valueDesc: "Three-part button",
    label: "EV014069: Three-part button"
  },
  {
    valueId: "EV014073",
    valueDesc: "USB charger",
    label: "EV014073: USB charger"
  },
  {
    valueId: "EV014083",
    valueDesc: "Basic element with holder/support",
    label: "EV014083: Basic element with holder/support"
  },
  {
    valueId: "EV014084",
    valueDesc: "LR 11",
    label: "EV014084: LR 11"
  },
  {
    valueId: "EV014085",
    valueDesc: "MR 9",
    label: "EV014085: MR 9"
  },
  {
    valueId: "EV014086",
    valueDesc: "1/2 AA",
    label: "EV014086: 1/2 AA"
  },
  {
    valueId: "EV014087",
    valueDesc: "2/3 AA",
    label: "EV014087: 2/3 AA"
  },
  {
    valueId: "EV014088",
    valueDesc: "6 LP 3146",
    label: "EV014088: 6 LP 3146"
  },
  {
    valueId: "EV014089",
    valueDesc: "xComfort",
    label: "EV014089: xComfort"
  },
  {
    valueId: "EV014100",
    valueDesc: "Lady",
    label: "EV014100: Lady"
  },
  {
    valueId: "EV014101",
    valueDesc: "Mini",
    label: "EV014101: Mini"
  },
  {
    valueId: "EV014102",
    valueDesc: "3 LR 12",
    label: "EV014102: 3 LR 12"
  },
  {
    valueId: "EV014103",
    valueDesc: "4 LR 25 Y",
    label: "EV014103: 4 LR 25 Y"
  },
  {
    valueId: "EV014104",
    valueDesc: "4 R25 Y",
    label: "EV014104: 4 R25 Y"
  },
  {
    valueId: "EV014105",
    valueDesc: "8 R 25",
    label: "EV014105: 8 R 25"
  },
  {
    valueId: "EV014106",
    valueDesc: "FR6",
    label: "EV014106: FR6"
  },
  {
    valueId: "EV014107",
    valueDesc: "AAAA",
    label: "EV014107: AAAA"
  },
  {
    valueId: "EV014108",
    valueDesc: "6HR61",
    label: "EV014108: 6HR61"
  },
  {
    valueId: "EV014109",
    valueDesc: "9V",
    label: "EV014109: 9V"
  },
  {
    valueId: "EV014110",
    valueDesc: "AS/NZS 2 pin plugtop",
    label: "EV014110: AS/NZS 2 pin plugtop"
  },
  {
    valueId: "EV014111",
    valueDesc: "AS/NZS 3 pin plugtop",
    label: "EV014111: AS/NZS 3 pin plugtop"
  },
  {
    valueId: "EV014112",
    valueDesc: "AS/NZS 4 pin plugtop",
    label: "EV014112: AS/NZS 4 pin plugtop"
  },
  {
    valueId: "EV014113",
    valueDesc: "AS/NZS 5 pin plugtop",
    label: "EV014113: AS/NZS 5 pin plugtop"
  },
  {
    valueId: "EV014114",
    valueDesc: "NEMA turn-locking plug",
    label: "EV014114: NEMA turn-locking plug"
  },
  {
    valueId: "EV014115",
    valueDesc: "Swiss norm type 11",
    label: "EV014115: Swiss norm type 11"
  },
  {
    valueId: "EV014116",
    valueDesc: "AS/NZS 3 pin socket",
    label: "EV014116: AS/NZS 3 pin socket"
  },
  {
    valueId: "EV014117",
    valueDesc: "AS/NZS 4 pin socket",
    label: "EV014117: AS/NZS 4 pin socket"
  },
  {
    valueId: "EV014118",
    valueDesc: "AS/NZS 5 pin socket",
    label: "EV014118: AS/NZS 5 pin socket"
  },
  {
    valueId: "EV014119",
    valueDesc: "NEMA-straight blade coupler",
    label: "EV014119: NEMA-straight blade coupler"
  },
  {
    valueId: "EV014120",
    valueDesc: "NEMA turn-locking connector",
    label: "EV014120: NEMA turn-locking connector"
  },
  {
    valueId: "EV014121",
    valueDesc: "Multi-standard socket",
    label: "EV014121: Multi-standard socket"
  },
  {
    valueId: "EV014122",
    valueDesc: "5-30",
    label: "EV014122: 5-30"
  },
  {
    valueId: "EV014123",
    valueDesc: "6-30",
    label: "EV014123: 6-30"
  },
  {
    valueId: "EV014124",
    valueDesc: "7-30",
    label: "EV014124: 7-30"
  },
  {
    valueId: "EV014125",
    valueDesc: "5-50",
    label: "EV014125: 5-50"
  },
  {
    valueId: "EV014126",
    valueDesc: "6-50",
    label: "EV014126: 6-50"
  },
  {
    valueId: "EV014127",
    valueDesc: "7-50",
    label: "EV014127: 7-50"
  },
  {
    valueId: "EV014128",
    valueDesc: "5-15",
    label: "EV014128: 5-15"
  },
  {
    valueId: "EV014129",
    valueDesc: "6-15",
    label: "EV014129: 6-15"
  },
  {
    valueId: "EV014130",
    valueDesc: "7-15",
    label: "EV014130: 7-15"
  },
  {
    valueId: "EV014131",
    valueDesc: "5-20",
    label: "EV014131: 5-20"
  },
  {
    valueId: "EV014132",
    valueDesc: "6-20",
    label: "EV014132: 6-20"
  },
  {
    valueId: "EV014133",
    valueDesc: "14-20",
    label: "EV014133: 14-20"
  },
  {
    valueId: "EV014134",
    valueDesc: "14-30",
    label: "EV014134: 14-30"
  },
  {
    valueId: "EV014135",
    valueDesc: "14-50",
    label: "EV014135: 14-50"
  },
  {
    valueId: "EV014136",
    valueDesc: "14-60",
    label: "EV014136: 14-60"
  },
  {
    valueId: "EV014137",
    valueDesc: "15-20",
    label: "EV014137: 15-20"
  },
  {
    valueId: "EV014138",
    valueDesc: "15-30",
    label: "EV014138: 15-30"
  },
  {
    valueId: "EV014139",
    valueDesc: "15-50",
    label: "EV014139: 15-50"
  },
  {
    valueId: "EV014140",
    valueDesc: "15-60",
    label: "EV014140: 15-60"
  },
  {
    valueId: "EV014141",
    valueDesc: "Escalator",
    label: "EV014141: Escalator"
  },
  {
    valueId: "EV014142",
    valueDesc: "Autowalk",
    label: "EV014142: Autowalk"
  },
  {
    valueId: "EV014143",
    valueDesc: "Touchpad",
    label: "EV014143: Touchpad"
  },
  {
    valueId: "EV014145",
    valueDesc: "Passenger elevator",
    label: "EV014145: Passenger elevator"
  },
  {
    valueId: "EV014146",
    valueDesc: "Freight elevator",
    label: "EV014146: Freight elevator"
  },
  {
    valueId: "EV014147",
    valueDesc: "Passenger-/freight elevator",
    label: "EV014147: Passenger-/freight elevator"
  },
  {
    valueId: "EV014148",
    valueDesc: "In top of elevator shaft",
    label: "EV014148: In top of elevator shaft"
  },
  {
    valueId: "EV014149",
    valueDesc: "In pit of elevator shaft",
    label: "EV014149: In pit of elevator shaft"
  },
  {
    valueId: "EV014150",
    valueDesc: "On top of elevator shaft",
    label: "EV014150: On top of elevator shaft"
  },
  {
    valueId: "EV014156",
    valueDesc: "1-leaf",
    label: "EV014156: 1-leaf"
  },
  {
    valueId: "EV014157",
    valueDesc: "2-leaf telescopic",
    label: "EV014157: 2-leaf telescopic"
  },
  {
    valueId: "EV014158",
    valueDesc: "3-leaf telescopic",
    label: "EV014158: 3-leaf telescopic"
  },
  {
    valueId: "EV014159",
    valueDesc: "2-leaf central",
    label: "EV014159: 2-leaf central"
  },
  {
    valueId: "EV014160",
    valueDesc: "4-leaf central",
    label: "EV014160: 4-leaf central"
  },
  {
    valueId: "EV014161",
    valueDesc: "6-leaf central",
    label: "EV014161: 6-leaf central"
  },
  {
    valueId: "EV014162",
    valueDesc: "Thread size",
    label: "EV014162: Thread size"
  },
  {
    valueId: "EV014163",
    valueDesc: "Radius",
    label: "EV014163: Radius"
  },
  {
    valueId: "EV014164",
    valueDesc: "Thickness",
    label: "EV014164: Thickness"
  },
  {
    valueId: "EV014165",
    valueDesc: "Depth",
    label: "EV014165: Depth"
  },
  {
    valueId: "EV014166",
    valueDesc: "Round nose tree",
    label: "EV014166: Round nose tree"
  },
  {
    valueId: "EV014167",
    valueDesc: "Pointed tree",
    label: "EV014167: Pointed tree"
  },
  {
    valueId: "EV014168",
    valueDesc: "Ball nose cone",
    label: "EV014168: Ball nose cone"
  },
  {
    valueId: "EV014169",
    valueDesc: "Pointed cone",
    label: "EV014169: Pointed cone"
  },
  {
    valueId: "EV014170",
    valueDesc: "Cylinder with end cut",
    label: "EV014170: Cylinder with end cut"
  },
  {
    valueId: "EV014171",
    valueDesc: "Ball nose cylinder",
    label: "EV014171: Ball nose cylinder"
  },
  {
    valueId: "EV014172",
    valueDesc: "Natural sponge",
    label: "EV014172: Natural sponge"
  },
  {
    valueId: "EV014173",
    valueDesc: "Recessed mounted device plug",
    label: "EV014173: Recessed mounted device plug"
  },
  {
    valueId: "EV014174",
    valueDesc: "ML-1",
    label: "EV014174: ML-1"
  },
  {
    valueId: "EV014175",
    valueDesc: "ML-2",
    label: "EV014175: ML-2"
  },
  {
    valueId: "EV014176",
    valueDesc: "ML-3",
    label: "EV014176: ML-3"
  },
  {
    valueId: "EV014177",
    valueDesc: "Mounting with nail",
    label: "EV014177: Mounting with nail"
  },
  {
    valueId: "EV014178",
    valueDesc: "C1",
    label: "EV014178: C1"
  },
  {
    valueId: "EV014179",
    valueDesc: "C2",
    label: "EV014179: C2"
  },
  {
    valueId: "EV014180",
    valueDesc: "C3",
    label: "EV014180: C3"
  },
  {
    valueId: "EV014181",
    valueDesc: "C4",
    label: "EV014181: C4"
  },
  {
    valueId: "EV014182",
    valueDesc: "C5",
    label: "EV014182: C5"
  },
  {
    valueId: "EV014183",
    valueDesc: "C6",
    label: "EV014183: C6"
  },
  {
    valueId: "EV014184",
    valueDesc: "C7",
    label: "EV014184: C7"
  },
  {
    valueId: "EV014185",
    valueDesc: "C8",
    label: "EV014185: C8"
  },
  {
    valueId: "EV014186",
    valueDesc: "C9",
    label: "EV014186: C9"
  },
  {
    valueId: "EV014187",
    valueDesc: "C10",
    label: "EV014187: C10"
  },
  {
    valueId: "EV014188",
    valueDesc: "C11",
    label: "EV014188: C11"
  },
  {
    valueId: "EV014189",
    valueDesc: "C12",
    label: "EV014189: C12"
  },
  {
    valueId: "EV014190",
    valueDesc: "C13",
    label: "EV014190: C13"
  },
  {
    valueId: "EV014191",
    valueDesc: "C15",
    label: "EV014191: C15"
  },
  {
    valueId: "EV014192",
    valueDesc: "C15A",
    label: "EV014192: C15A"
  },
  {
    valueId: "EV014194",
    valueDesc: "C16A",
    label: "EV014194: C16A"
  },
  {
    valueId: "EV014195",
    valueDesc: "C17",
    label: "EV014195: C17"
  },
  {
    valueId: "EV014197",
    valueDesc: "C19",
    label: "EV014197: C19"
  },
  {
    valueId: "EV014198",
    valueDesc: "C21",
    label: "EV014198: C21"
  },
  {
    valueId: "EV014199",
    valueDesc: "C23",
    label: "EV014199: C23"
  },
  {
    valueId: "EV014201",
    valueDesc: "Bandage",
    label: "EV014201: Bandage"
  },
  {
    valueId: "EV014202",
    valueDesc: "Respirator",
    label: "EV014202: Respirator"
  },
  {
    valueId: "EV014203",
    valueDesc: "Cardiopulmonary resuscitation mask (CPR)",
    label: "EV014203: Cardiopulmonary resuscitation mask (CPR)"
  },
  {
    valueId: "EV014204",
    valueDesc: "Defibrillator (AED)",
    label: "EV014204: Defibrillator (AED)"
  },
  {
    valueId: "EV014205",
    valueDesc: "Medicated cleanser",
    label: "EV014205: Medicated cleanser"
  },
  {
    valueId: "EV014206",
    valueDesc: "Antiseptic wipes",
    label: "EV014206: Antiseptic wipes"
  },
  {
    valueId: "EV014207",
    valueDesc: "Triangular sling",
    label: "EV014207: Triangular sling"
  },
  {
    valueId: "EV014208",
    valueDesc: "Disposable syringe",
    label: "EV014208: Disposable syringe"
  },
  {
    valueId: "EV014209",
    valueDesc: "Gauze",
    label: "EV014209: Gauze"
  },
  {
    valueId: "EV014210",
    valueDesc: "Dry ice pack",
    label: "EV014210: Dry ice pack"
  },
  {
    valueId: "EV014211",
    valueDesc: "Sharps container",
    label: "EV014211: Sharps container"
  },
  {
    valueId: "EV014212",
    valueDesc: "Sterile pad",
    label: "EV014212: Sterile pad"
  },
  {
    valueId: "EV014213",
    valueDesc: "Medicine tablets",
    label: "EV014213: Medicine tablets"
  },
  {
    valueId: "EV014214",
    valueDesc: "Cotton bud",
    label: "EV014214: Cotton bud"
  },
  {
    valueId: "EV014215",
    valueDesc: "Tongue depressor",
    label: "EV014215: Tongue depressor"
  },
  {
    valueId: "EV014216",
    valueDesc: "Safety pin",
    label: "EV014216: Safety pin"
  },
  {
    valueId: "EV014217",
    valueDesc: "Elastic bands",
    label: "EV014217: Elastic bands"
  },
  {
    valueId: "EV014218",
    valueDesc: "Strapping buckle",
    label: "EV014218: Strapping buckle"
  },
  {
    valueId: "EV014220",
    valueDesc: "Air cushion",
    label: "EV014220: Air cushion"
  },
  {
    valueId: "EV014221",
    valueDesc: "Wrap",
    label: "EV014221: Wrap"
  },
  {
    valueId: "EV014222",
    valueDesc: "Strapping",
    label: "EV014222: Strapping"
  },
  {
    valueId: "EV014223",
    valueDesc: "Envelope",
    label: "EV014223: Envelope"
  },
  {
    valueId: "EV014224",
    valueDesc: "Translucent",
    label: "EV014224: Translucent"
  },
  {
    valueId: "EV014225",
    valueDesc: "Opaque",
    label: "EV014225: Opaque"
  },
  {
    valueId: "EV014226",
    valueDesc: "Chemicals",
    label: "EV014226: Chemicals"
  },
  {
    valueId: "EV014227",
    valueDesc: "Spill kit",
    label: "EV014227: Spill kit"
  },
  {
    valueId: "EV014228",
    valueDesc: "Spill pallet",
    label: "EV014228: Spill pallet"
  },
  {
    valueId: "EV014229",
    valueDesc: "Floating boom",
    label: "EV014229: Floating boom"
  },
  {
    valueId: "EV014230",
    valueDesc: "Sock",
    label: "EV014230: Sock"
  },
  {
    valueId: "EV014231",
    valueDesc: "Pad",
    label: "EV014231: Pad"
  },
  {
    valueId: "EV014233",
    valueDesc: "Compressed air",
    label: "EV014233: Compressed air"
  },
  {
    valueId: "EV014234",
    valueDesc: "Bosun chair",
    label: "EV014234: Bosun chair"
  },
  {
    valueId: "EV014235",
    valueDesc: "Elbow protector",
    label: "EV014235: Elbow protector"
  },
  {
    valueId: "EV014236",
    valueDesc: "Elbow sleeve",
    label: "EV014236: Elbow sleeve"
  },
  {
    valueId: "EV014237",
    valueDesc: "Wrist protector",
    label: "EV014237: Wrist protector"
  },
  {
    valueId: "EV014238",
    valueDesc: "Arm sleeve",
    label: "EV014238: Arm sleeve"
  },
  {
    valueId: "EV014239",
    valueDesc: "Pull-on",
    label: "EV014239: Pull-on"
  },
  {
    valueId: "EV014240",
    valueDesc: "Ambidextrous",
    label: "EV014240: Ambidextrous"
  },
  {
    valueId: "EV014241",
    valueDesc: "Blended fabric",
    label: "EV014241: Blended fabric"
  },
  {
    valueId: "EV014242",
    valueDesc: "Natural fibre",
    label: "EV014242: Natural fibre"
  },
  {
    valueId: "EV014243",
    valueDesc: "Keyway mounting",
    label: "EV014243: Keyway mounting"
  },
  {
    valueId: "EV014247",
    valueDesc: "605",
    label: "EV014247: 605"
  },
  {
    valueId: "EV014248",
    valueDesc: "610",
    label: "EV014248: 610"
  },
  {
    valueId: "EV014249",
    valueDesc: "DIN 3-pole",
    label: "EV014249: DIN 3-pole"
  },
  {
    valueId: "EV014250",
    valueDesc: "Typ PG11",
    label: "EV014250: Typ PG11"
  },
  {
    valueId: "EV014251",
    valueDesc: "611",
    label: "EV014251: 611"
  },
  {
    valueId: "EV014252",
    valueDesc: "1x 431a",
    label: "EV014252: 1x 431a"
  },
  {
    valueId: "EV014253",
    valueDesc: "2x 610",
    label: "EV014253: 2x 610"
  },
  {
    valueId: "EV014254",
    valueDesc: "3x RJ12 6(6)",
    label: "EV014254: 3x RJ12 6(6)"
  },
  {
    valueId: "EV014255",
    valueDesc: "610 + RJ12",
    label: "EV014255: 610 + RJ12"
  },
  {
    valueId: "EV014256",
    valueDesc: "123 mm",
    label: "EV014256: 123 mm"
  },
  {
    valueId: "EV014257",
    valueDesc: "LC-Quad",
    label: "EV014257: LC-Quad"
  },
  {
    valueId: "EV014258",
    valueDesc: "ANSI A",
    label: "EV014258: ANSI A"
  },
  {
    valueId: "EV014259",
    valueDesc: "ANSI C",
    label: "EV014259: ANSI C"
  },
  {
    valueId: "EV014260",
    valueDesc: "Standard (EN/IEC/ANSI-B)",
    label: "EV014260: Standard (EN/IEC/ANSI-B)"
  },
  {
    valueId: "EV014262",
    valueDesc: "Multi mode 50/125 and 62.5/125",
    label: "EV014262: Multi mode 50/125 and 62.5/125"
  },
  {
    valueId: "EV014263",
    valueDesc: "U-link",
    label: "EV014263: U-link"
  },
  {
    valueId: "EV014264",
    valueDesc: "2x 431a",
    label: "EV014264: 2x 431a"
  },
  {
    valueId: "EV014265",
    valueDesc: "S0-/UP0-Port",
    label: "EV014265: S0-/UP0-Port"
  },
  {
    valueId: "EV014266",
    valueDesc: "200 mV",
    label: "EV014266: 200 mV"
  },
  {
    valueId: "EV014267",
    valueDesc: "340 mV",
    label: "EV014267: 340 mV"
  },
  {
    valueId: "EV014268",
    valueDesc: "400 mV",
    label: "EV014268: 400 mV"
  },
  {
    valueId: "EV014269",
    valueDesc: "60 mV",
    label: "EV014269: 60 mV"
  },
  {
    valueId: "EV014270",
    valueDesc: "200 µA",
    label: "EV014270: 200 µA"
  },
  {
    valueId: "EV014271",
    valueDesc: "400 µA",
    label: "EV014271: 400 µA"
  },
  {
    valueId: "EV014272",
    valueDesc: "50 µA",
    label: "EV014272: 50 µA"
  },
  {
    valueId: "EV014273",
    valueDesc: "600 µA",
    label: "EV014273: 600 µA"
  },
  {
    valueId: "EV014282",
    valueDesc: "Button and button hole",
    label: "EV014282: Button and button hole"
  },
  {
    valueId: "EV014283",
    valueDesc: "Unisex",
    label: "EV014283: Unisex"
  },
  {
    valueId: "EV014284",
    valueDesc: "34",
    label: "EV014284: 34"
  },
  {
    valueId: "EV014295",
    valueDesc: "7XL",
    label: "EV014295: 7XL"
  },
  {
    valueId: "EV014296",
    valueDesc: "8XL",
    label: "EV014296: 8XL"
  },
  {
    valueId: "EV014297",
    valueDesc: "Short fit",
    label: "EV014297: Short fit"
  },
  {
    valueId: "EV014298",
    valueDesc: "Long fit",
    label: "EV014298: Long fit"
  },
  {
    valueId: "EV014299",
    valueDesc: "Regular fit",
    label: "EV014299: Regular fit"
  },
  {
    valueId: "EV014300",
    valueDesc: "1/1 long",
    label: "EV014300: 1/1 long"
  },
  {
    valueId: "EV014301",
    valueDesc: "3/4 long",
    label: "EV014301: 3/4 long"
  },
  {
    valueId: "EV014302",
    valueDesc: "Knee length",
    label: "EV014302: Knee length"
  },
  {
    valueId: "EV014303",
    valueDesc: "Synthetic fibre",
    label: "EV014303: Synthetic fibre"
  },
  {
    valueId: "EV014304",
    valueDesc: "Polo-collar",
    label: "EV014304: Polo-collar"
  },
  {
    valueId: "EV014305",
    valueDesc: "Turtle neck",
    label: "EV014305: Turtle neck"
  },
  {
    valueId: "EV014306",
    valueDesc: "Boat neck",
    label: "EV014306: Boat neck"
  },
  {
    valueId: "EV014307",
    valueDesc: "Button collar",
    label: "EV014307: Button collar"
  },
  {
    valueId: "EV014308",
    valueDesc: "V neck",
    label: "EV014308: V neck"
  },
  {
    valueId: "EV014311",
    valueDesc: "Fibre based material",
    label: "EV014311: Fibre based material"
  },
  {
    valueId: "EV014312",
    valueDesc: "1/2 length",
    label: "EV014312: 1/2 length"
  },
  {
    valueId: "EV014313",
    valueDesc: "3/4 length",
    label: "EV014313: 3/4 length"
  },
  {
    valueId: "EV014324",
    valueDesc: "1+1+3 finger",
    label: "EV014324: 1+1+3 finger"
  },
  {
    valueId: "EV014325",
    valueDesc: "1+2+2 finger",
    label: "EV014325: 1+2+2 finger"
  },
  {
    valueId: "EV014326",
    valueDesc: "Mitten",
    label: "EV014326: Mitten"
  },
  {
    valueId: "EV014327",
    valueDesc: "5-finger",
    label: "EV014327: 5-finger"
  },
  {
    valueId: "EV014328",
    valueDesc: "Deer skin",
    label: "EV014328: Deer skin"
  },
  {
    valueId: "EV014330",
    valueDesc: "Cow skin",
    label: "EV014330: Cow skin"
  },
  {
    valueId: "EV014331",
    valueDesc: "Pig skin",
    label: "EV014331: Pig skin"
  },
  {
    valueId: "EV014332",
    valueDesc: "Goat leather",
    label: "EV014332: Goat leather"
  },
  {
    valueId: "EV014333",
    valueDesc: "3/4 coating",
    label: "EV014333: 3/4 coating"
  },
  {
    valueId: "EV014334",
    valueDesc: "1/2-coated",
    label: "EV014334: 1/2-coated"
  },
  {
    valueId: "EV014335",
    valueDesc: "Palm coated",
    label: "EV014335: Palm coated"
  },
  {
    valueId: "EV014336",
    valueDesc: "Fully coated",
    label: "EV014336: Fully coated"
  },
  {
    valueId: "EV014337",
    valueDesc: "Elastic cuff",
    label: "EV014337: Elastic cuff"
  },
  {
    valueId: "EV014338",
    valueDesc: "Crisscross",
    label: "EV014338: Crisscross"
  },
  {
    valueId: "EV014339",
    valueDesc: "Fishscale grip",
    label: "EV014339: Fishscale grip"
  },
  {
    valueId: "EV014340",
    valueDesc: "Wrist length cuff",
    label: "EV014340: Wrist length cuff"
  },
  {
    valueId: "EV014341",
    valueDesc: "Forearm length cuff",
    label: "EV014341: Forearm length cuff"
  },
  {
    valueId: "EV014342",
    valueDesc: "Cap style",
    label: "EV014342: Cap style"
  },
  {
    valueId: "EV014343",
    valueDesc: "Full brim",
    label: "EV014343: Full brim"
  },
  {
    valueId: "EV014344",
    valueDesc: "Bump cap",
    label: "EV014344: Bump cap"
  },
  {
    valueId: "EV014345",
    valueDesc: "Belt buckle",
    label: "EV014345: Belt buckle"
  },
  {
    valueId: "EV014346",
    valueDesc: "550 °C - 30 s",
    label: "EV014346: 550 °C - 30 s"
  },
  {
    valueId: "EV014347",
    valueDesc: "750 °C - 30 s",
    label: "EV014347: 750 °C - 30 s"
  },
  {
    valueId: "EV014348",
    valueDesc: "850 °C - 30 s",
    label: "EV014348: 850 °C - 30 s"
  },
  {
    valueId: "EV014349",
    valueDesc: "960 °C - 30 s",
    label: "EV014349: 960 °C - 30 s"
  },
  {
    valueId: "EV014350",
    valueDesc: "Beard net",
    label: "EV014350: Beard net"
  },
  {
    valueId: "EV014351",
    valueDesc: "Bow tie",
    label: "EV014351: Bow tie"
  },
  {
    valueId: "EV014352",
    valueDesc: "Tie",
    label: "EV014352: Tie"
  },
  {
    valueId: "EV014353",
    valueDesc: "Lanyard",
    label: "EV014353: Lanyard"
  },
  {
    valueId: "EV014354",
    valueDesc: "Sleep mask",
    label: "EV014354: Sleep mask"
  },
  {
    valueId: "EV014355",
    valueDesc: "Hard hat liner",
    label: "EV014355: Hard hat liner"
  },
  {
    valueId: "EV014356",
    valueDesc: "Neck guard",
    label: "EV014356: Neck guard"
  },
  {
    valueId: "EV014357",
    valueDesc: "Taping knife",
    label: "EV014357: Taping knife"
  },
  {
    valueId: "EV014358",
    valueDesc: "Ball pein hammer",
    label: "EV014358: Ball pein hammer"
  },
  {
    valueId: "EV014361",
    valueDesc: "Rip saw",
    label: "EV014361: Rip saw"
  },
  {
    valueId: "EV014362",
    valueDesc: "Fret saw",
    label: "EV014362: Fret saw"
  },
  {
    valueId: "EV014363",
    valueDesc: "Hacksaw",
    label: "EV014363: Hacksaw"
  },
  {
    valueId: "EV014364",
    valueDesc: "Jab/drywall saw",
    label: "EV014364: Jab/drywall saw"
  },
  {
    valueId: "EV014367",
    valueDesc: "Wax crayon",
    label: "EV014367: Wax crayon"
  },
  {
    valueId: "EV014368",
    valueDesc: "Truck mount",
    label: "EV014368: Truck mount"
  },
  {
    valueId: "EV014369",
    valueDesc: "Axis for drum",
    label: "EV014369: Axis for drum"
  },
  {
    valueId: "EV014370",
    valueDesc: "Bar with rolls",
    label: "EV014370: Bar with rolls"
  },
  {
    valueId: "EV014371",
    valueDesc: "Frame with drums",
    label: "EV014371: Frame with drums"
  },
  {
    valueId: "EV014372",
    valueDesc: "Platform ladder",
    label: "EV014372: Platform ladder"
  },
  {
    valueId: "EV014375",
    valueDesc: "Spokeshave",
    label: "EV014375: Spokeshave"
  },
  {
    valueId: "EV014376",
    valueDesc: "Hatchet",
    label: "EV014376: Hatchet"
  },
  {
    valueId: "EV014377",
    valueDesc: "Block splitter",
    label: "EV014377: Block splitter"
  },
  {
    valueId: "EV014378",
    valueDesc: "Splitting wedge",
    label: "EV014378: Splitting wedge"
  },
  {
    valueId: "EV014379",
    valueDesc: "Normal axe",
    label: "EV014379: Normal axe"
  },
  {
    valueId: "EV014383",
    valueDesc: "NPSM (National Pipe Straight Mechanical)",
    label: "EV014383: NPSM (National Pipe Straight Mechanical)"
  },
  {
    valueId: "EV014384",
    valueDesc: "Scribing height gauge",
    label: "EV014384: Scribing height gauge"
  },
  {
    valueId: "EV014386",
    valueDesc: "API (American Petroleum Institute)",
    label: "EV014386: API (American Petroleum Institute)"
  },
  {
    valueId: "EV014387",
    valueDesc: "Wire rope",
    label: "EV014387: Wire rope"
  },
  {
    valueId: "EV014388",
    valueDesc: "Fibre ribbon",
    label: "EV014388: Fibre ribbon"
  },
  {
    valueId: "EV014389",
    valueDesc: "Front cutting edge",
    label: "EV014389: Front cutting edge"
  },
  {
    valueId: "EV014390",
    valueDesc: "Scissor cutting edge",
    label: "EV014390: Scissor cutting edge"
  },
  {
    valueId: "EV014391",
    valueDesc: "Centre cutting edge",
    label: "EV014391: Centre cutting edge"
  },
  {
    valueId: "EV014393",
    valueDesc: "Torq-set",
    label: "EV014393: Torq-set"
  },
  {
    valueId: "EV014395",
    valueDesc: "Spanner head (2-hole)",
    label: "EV014395: Spanner head (2-hole)"
  },
  {
    valueId: "EV014396",
    valueDesc: "Inch",
    label: "EV014396: Inch"
  },
  {
    valueId: "EV014397",
    valueDesc: "Cranked",
    label: "EV014397: Cranked"
  },
  {
    valueId: "EV014398",
    valueDesc: "SJO (CA/US)",
    label: "EV014398: SJO (CA/US)"
  },
  {
    valueId: "EV014399",
    valueDesc: "SJOOW (CA/US)",
    label: "EV014399: SJOOW (CA/US)"
  },
  {
    valueId: "EV014400",
    valueDesc: "SJOW (CA/US)",
    label: "EV014400: SJOW (CA/US)"
  },
  {
    valueId: "EV014401",
    valueDesc: "SJT (CA/US)",
    label: "EV014401: SJT (CA/US)"
  },
  {
    valueId: "EV014402",
    valueDesc: "SJTW (CA/US)",
    label: "EV014402: SJTW (CA/US)"
  },
  {
    valueId: "EV014403",
    valueDesc: "SOOW (CA/US)",
    label: "EV014403: SOOW (CA/US)"
  },
  {
    valueId: "EV014404",
    valueDesc: "SVO (CA/US)",
    label: "EV014404: SVO (CA/US)"
  },
  {
    valueId: "EV014405",
    valueDesc: "SVT (CA/US)",
    label: "EV014405: SVT (CA/US)"
  },
  {
    valueId: "EV014406",
    valueDesc: "Straight shank",
    label: "EV014406: Straight shank"
  },
  {
    valueId: "EV014407",
    valueDesc: "With hole",
    label: "EV014407: With hole"
  },
  {
    valueId: "EV014408",
    valueDesc: "T-Shank",
    label: "EV014408: T-Shank"
  },
  {
    valueId: "EV014409",
    valueDesc: "U-Shank",
    label: "EV014409: U-Shank"
  },
  {
    valueId: "EV014410",
    valueDesc: "Diffuser lens/optic/panel",
    label: "EV014410: Diffuser lens/optic/panel"
  },
  {
    valueId: "EV014411",
    valueDesc: "Focal lens",
    label: "EV014411: Focal lens"
  },
  {
    valueId: "EV014412",
    valueDesc: "Refractor lens",
    label: "EV014412: Refractor lens"
  },
  {
    valueId: "EV014413",
    valueDesc: "Fluorescent lamp D=13 mm",
    label: "EV014413: Fluorescent lamp D=13 mm"
  },
  {
    valueId: "EV014414",
    valueDesc: "Glass matt/satinized",
    label: "EV014414: Glass matt/satinized"
  },
  {
    valueId: "EV014415",
    valueDesc: "Plastic matt/satinized",
    label: "EV014415: Plastic matt/satinized"
  },
  {
    valueId: "EV014416",
    valueDesc: "RSC",
    label: "EV014416: RSC"
  },
  {
    valueId: "EV014417",
    valueDesc: "Electronic ballast",
    label: "EV014417: Electronic ballast"
  },
  {
    valueId: "EV014418",
    valueDesc: "Hydraulic hand pump",
    label: "EV014418: Hydraulic hand pump"
  },
  {
    valueId: "EV014422",
    valueDesc: "G26",
    label: "EV014422: G26"
  },
  {
    valueId: "EV014423",
    valueDesc: "6:1",
    label: "EV014423: 6:1"
  },
  {
    valueId: "EV014424",
    valueDesc: "Flat 5.2x0.5 mm",
    label: "EV014424: Flat 5.2x0.5 mm"
  },
  {
    valueId: "EV014425",
    valueDesc: "Flat 6.3x0.5 mm",
    label: "EV014425: Flat 6.3x0.5 mm"
  },
  {
    valueId: "EV014426",
    valueDesc: "Flat 8.0x0.8 mm",
    label: "EV014426: Flat 8.0x0.8 mm"
  },
  {
    valueId: "EV014427",
    valueDesc: "Round 2 mm",
    label: "EV014427: Round 2 mm"
  },
  {
    valueId: "EV014429",
    valueDesc: "1000 kcmil",
    label: "EV014429: 1000 kcmil"
  },
  {
    valueId: "EV014430",
    valueDesc: "250 kcmil",
    label: "EV014430: 250 kcmil"
  },
  {
    valueId: "EV014431",
    valueDesc: "300 kcmil",
    label: "EV014431: 300 kcmil"
  },
  {
    valueId: "EV014432",
    valueDesc: "350 kcmil",
    label: "EV014432: 350 kcmil"
  },
  {
    valueId: "EV014433",
    valueDesc: "400 kcmil",
    label: "EV014433: 400 kcmil"
  },
  {
    valueId: "EV014434",
    valueDesc: "500 kcmil",
    label: "EV014434: 500 kcmil"
  },
  {
    valueId: "EV014435",
    valueDesc: "600 kcmil",
    label: "EV014435: 600 kcmil"
  },
  {
    valueId: "EV014436",
    valueDesc: "750 kcmil",
    label: "EV014436: 750 kcmil"
  },
  {
    valueId: "EV014437",
    valueDesc: "Flat-pin shape",
    label: "EV014437: Flat-pin shape"
  },
  {
    valueId: "EV014438",
    valueDesc: "Double elbow 45° with enclosure",
    label: "EV014438: Double elbow 45° with enclosure"
  },
  {
    valueId: "EV014439",
    valueDesc: "Bend type L (North America)",
    label: "EV014439: Bend type L (North America)"
  },
  {
    valueId: "EV014441",
    valueDesc: "Plug-in to threaded",
    label: "EV014441: Plug-in to threaded"
  },
  {
    valueId: "EV014445",
    valueDesc: "WL (Wire Lead)",
    label: "EV014445: WL (Wire Lead)"
  },
  {
    valueId: "EV014446",
    valueDesc: "4.5x15 mm",
    label: "EV014446: 4.5x15 mm"
  },
  {
    valueId: "EV014447",
    valueDesc: "5x15 mm",
    label: "EV014447: 5x15 mm"
  },
  {
    valueId: "EV014448",
    valueDesc: "DII, DIII",
    label: "EV014448: DII, DIII"
  },
  {
    valueId: "EV014450",
    valueDesc: "CC",
    label: "EV014450: CC"
  },
  {
    valueId: "EV014451",
    valueDesc: "K5",
    label: "EV014451: K5"
  },
  {
    valueId: "EV014452",
    valueDesc: "M (Midget)",
    label: "EV014452: M (Midget)"
  },
  {
    valueId: "EV014453",
    valueDesc: "RK1",
    label: "EV014453: RK1"
  },
  {
    valueId: "EV014454",
    valueDesc: "RK5",
    label: "EV014454: RK5"
  },
  {
    valueId: "EV014457",
    valueDesc: "Escape route lighting",
    label: "EV014457: Escape route lighting"
  },
  {
    valueId: "EV014458",
    valueDesc: "Escape route signage",
    label: "EV014458: Escape route signage"
  },
  {
    valueId: "EV014459",
    valueDesc: "Escape route lighting/signage",
    label: "EV014459: Escape route lighting/signage"
  },
  {
    valueId: "EV014460",
    valueDesc: "Silver grey",
    label: "EV014460: Silver grey"
  },
  {
    valueId: "EV014461",
    valueDesc: "Tandem",
    label: "EV014461: Tandem"
  },
  {
    valueId: "EV014462",
    valueDesc: "Bottle/skittle",
    label: "EV014462: Bottle/skittle"
  },
  {
    valueId: "EV014463",
    valueDesc: "Adjustable trim",
    label: "EV014463: Adjustable trim"
  },
  {
    valueId: "EV014464",
    valueDesc: "Non-adjustable trim",
    label: "EV014464: Non-adjustable trim"
  },
  {
    valueId: "EV014465",
    valueDesc: "Connection cable with mains plug (2P+E)",
    label: "EV014465: Connection cable with mains plug (2P+E)"
  },
  {
    valueId: "EV014466",
    valueDesc: "BSP",
    label: "EV014466: BSP"
  },
  {
    valueId: "EV014467",
    valueDesc: "Solvent-based",
    label: "EV014467: Solvent-based"
  },
  {
    valueId: "EV014468",
    valueDesc: "Start capacitor",
    label: "EV014468: Start capacitor"
  },
  {
    valueId: "EV014469",
    valueDesc: "Sensor (unit)",
    label: "EV014469: Sensor (unit)"
  },
  {
    valueId: "EV014472",
    valueDesc: "Desk type device (fixed)",
    label: "EV014472: Desk type device (fixed)"
  },
  {
    valueId: "EV014473",
    valueDesc: "Desk type device (free standing)",
    label: "EV014473: Desk type device (free standing)"
  },
  {
    valueId: "EV014474",
    valueDesc: "T6",
    label: "EV014474: T6"
  },
  {
    valueId: "EV014475",
    valueDesc: "T6.5",
    label: "EV014475: T6.5"
  },
  {
    valueId: "EV014476",
    valueDesc: "T7",
    label: "EV014476: T7"
  },
  {
    valueId: "EV014477",
    valueDesc: "T8",
    label: "EV014477: T8"
  },
  {
    valueId: "EV014479",
    valueDesc: "BD17",
    label: "EV014479: BD17"
  },
  {
    valueId: "EV014480",
    valueDesc: "BT37",
    label: "EV014480: BT37"
  },
  {
    valueId: "EV014481",
    valueDesc: "BT56",
    label: "EV014481: BT56"
  },
  {
    valueId: "EV014482",
    valueDesc: "E25",
    label: "EV014482: E25"
  },
  {
    valueId: "EV014483",
    valueDesc: "ED17",
    label: "EV014483: ED17"
  },
  {
    valueId: "EV014484",
    valueDesc: "ED18",
    label: "EV014484: ED18"
  },
  {
    valueId: "EV014485",
    valueDesc: "ED23.5",
    label: "EV014485: ED23.5"
  },
  {
    valueId: "EV014486",
    valueDesc: "ED25",
    label: "EV014486: ED25"
  },
  {
    valueId: "EV014487",
    valueDesc: "ED28",
    label: "EV014487: ED28"
  },
  {
    valueId: "EV014488",
    valueDesc: "ED37",
    label: "EV014488: ED37"
  },
  {
    valueId: "EV014489",
    valueDesc: "EDX17",
    label: "EV014489: EDX17"
  },
  {
    valueId: "EV014490",
    valueDesc: "T12",
    label: "EV014490: T12"
  },
  {
    valueId: "EV014491",
    valueDesc: "T14",
    label: "EV014491: T14"
  },
  {
    valueId: "EV014492",
    valueDesc: "T15",
    label: "EV014492: T15"
  },
  {
    valueId: "EV014493",
    valueDesc: "T17",
    label: "EV014493: T17"
  },
  {
    valueId: "EV014494",
    valueDesc: "T21",
    label: "EV014494: T21"
  },
  {
    valueId: "EV014495",
    valueDesc: "T25",
    label: "EV014495: T25"
  },
  {
    valueId: "EV014496",
    valueDesc: "T3.5",
    label: "EV014496: T3.5"
  },
  {
    valueId: "EV014497",
    valueDesc: "T9",
    label: "EV014497: T9"
  },
  {
    valueId: "EV014498",
    valueDesc: "TD",
    label: "EV014498: TD"
  },
  {
    valueId: "EV014499",
    valueDesc: "TD6",
    label: "EV014499: TD6"
  },
  {
    valueId: "EV014500",
    valueDesc: "TD7",
    label: "EV014500: TD7"
  },
  {
    valueId: "EV014502",
    valueDesc: "A19",
    label: "EV014502: A19"
  },
  {
    valueId: "EV014503",
    valueDesc: "B11",
    label: "EV014503: B11"
  },
  {
    valueId: "EV014504",
    valueDesc: "BA11",
    label: "EV014504: BA11"
  },
  {
    valueId: "EV014505",
    valueDesc: "BT15",
    label: "EV014505: BT15"
  },
  {
    valueId: "EV014506",
    valueDesc: "F10.5",
    label: "EV014506: F10.5"
  },
  {
    valueId: "EV014507",
    valueDesc: "F15",
    label: "EV014507: F15"
  },
  {
    valueId: "EV014508",
    valueDesc: "G16.5",
    label: "EV014508: G16.5"
  },
  {
    valueId: "EV014509",
    valueDesc: "G25",
    label: "EV014509: G25"
  },
  {
    valueId: "EV014510",
    valueDesc: "T2 3/4",
    label: "EV014510: T2 3/4"
  },
  {
    valueId: "EV014511",
    valueDesc: "T2 7/8",
    label: "EV014511: T2 7/8"
  },
  {
    valueId: "EV014512",
    valueDesc: "T2.5",
    label: "EV014512: T2.5"
  },
  {
    valueId: "EV014513",
    valueDesc: "B10.5",
    label: "EV014513: B10.5"
  },
  {
    valueId: "EV014514",
    valueDesc: "B13",
    label: "EV014514: B13"
  },
  {
    valueId: "EV014515",
    valueDesc: "B8",
    label: "EV014515: B8"
  },
  {
    valueId: "EV014516",
    valueDesc: "B9.5",
    label: "EV014516: B9.5"
  },
  {
    valueId: "EV014517",
    valueDesc: "BA9",
    label: "EV014517: BA9"
  },
  {
    valueId: "EV014518",
    valueDesc: "BA9.5",
    label: "EV014518: BA9.5"
  },
  {
    valueId: "EV014519",
    valueDesc: "CA10",
    label: "EV014519: CA10"
  },
  {
    valueId: "EV014520",
    valueDesc: "CA11",
    label: "EV014520: CA11"
  },
  {
    valueId: "EV014521",
    valueDesc: "CA5",
    label: "EV014521: CA5"
  },
  {
    valueId: "EV014522",
    valueDesc: "CA7",
    label: "EV014522: CA7"
  },
  {
    valueId: "EV014523",
    valueDesc: "CA8",
    label: "EV014523: CA8"
  },
  {
    valueId: "EV014524",
    valueDesc: "F10",
    label: "EV014524: F10"
  },
  {
    valueId: "EV014525",
    valueDesc: "F20",
    label: "EV014525: F20"
  },
  {
    valueId: "EV014526",
    valueDesc: "S11",
    label: "EV014526: S11"
  },
  {
    valueId: "EV014527",
    valueDesc: "A23",
    label: "EV014527: A23"
  },
  {
    valueId: "EV014528",
    valueDesc: "BT28",
    label: "EV014528: BT28"
  },
  {
    valueId: "EV014529",
    valueDesc: "R40",
    label: "EV014529: R40"
  },
  {
    valueId: "EV014530",
    valueDesc: "R60",
    label: "EV014530: R60"
  },
  {
    valueId: "EV014533",
    valueDesc: "A17",
    label: "EV014533: A17"
  },
  {
    valueId: "EV014534",
    valueDesc: "A20",
    label: "EV014534: A20"
  },
  {
    valueId: "EV014535",
    valueDesc: "A21",
    label: "EV014535: A21"
  },
  {
    valueId: "EV014536",
    valueDesc: "A25",
    label: "EV014536: A25"
  },
  {
    valueId: "EV014537",
    valueDesc: "K16",
    label: "EV014537: K16"
  },
  {
    valueId: "EV014538",
    valueDesc: "PS25",
    label: "EV014538: PS25"
  },
  {
    valueId: "EV014539",
    valueDesc: "PS30",
    label: "EV014539: PS30"
  },
  {
    valueId: "EV014540",
    valueDesc: "PS35",
    label: "EV014540: PS35"
  },
  {
    valueId: "EV014541",
    valueDesc: "PS40",
    label: "EV014541: PS40"
  },
  {
    valueId: "EV014542",
    valueDesc: "S14",
    label: "EV014542: S14"
  },
  {
    valueId: "EV014543",
    valueDesc: "2D",
    label: "EV014543: 2D"
  },
  {
    valueId: "EV014549",
    valueDesc: "T5",
    label: "EV014549: T5"
  },
  {
    valueId: "EV014550",
    valueDesc: "BR20",
    label: "EV014550: BR20"
  },
  {
    valueId: "EV014551",
    valueDesc: "BR30",
    label: "EV014551: BR30"
  },
  {
    valueId: "EV014552",
    valueDesc: "BR38",
    label: "EV014552: BR38"
  },
  {
    valueId: "EV014553",
    valueDesc: "BR40",
    label: "EV014553: BR40"
  },
  {
    valueId: "EV014554",
    valueDesc: "ER30",
    label: "EV014554: ER30"
  },
  {
    valueId: "EV014555",
    valueDesc: "PAR36",
    label: "EV014555: PAR36"
  },
  {
    valueId: "EV014556",
    valueDesc: "PAR38",
    label: "EV014556: PAR38"
  },
  {
    valueId: "EV014557",
    valueDesc: "PAR46",
    label: "EV014557: PAR46"
  },
  {
    valueId: "EV014558",
    valueDesc: "PAR64",
    label: "EV014558: PAR64"
  },
  {
    valueId: "EV014559",
    valueDesc: "R16",
    label: "EV014559: R16"
  },
  {
    valueId: "EV014560",
    valueDesc: "R125",
    label: "EV014560: R125"
  },
  {
    valueId: "EV014561",
    valueDesc: "T2",
    label: "EV014561: T2"
  },
  {
    valueId: "EV014562",
    valueDesc: "EYJ",
    label: "EV014562: EYJ"
  },
  {
    valueId: "EV014563",
    valueDesc: "EYP",
    label: "EV014563: EYP"
  },
  {
    valueId: "EV014564",
    valueDesc: "EYR",
    label: "EV014564: EYR"
  },
  {
    valueId: "EV014565",
    valueDesc: "EYS",
    label: "EV014565: EYS"
  },
  {
    valueId: "EV014566",
    valueDesc: "MR11",
    label: "EV014566: MR11"
  },
  {
    valueId: "EV014567",
    valueDesc: "MR16",
    label: "EV014567: MR16"
  },
  {
    valueId: "EV014568",
    valueDesc: "ES16",
    label: "EV014568: ES16"
  },
  {
    valueId: "EV014569",
    valueDesc: "PAR16",
    label: "EV014569: PAR16"
  },
  {
    valueId: "EV014570",
    valueDesc: "PAR20",
    label: "EV014570: PAR20"
  },
  {
    valueId: "EV014571",
    valueDesc: "PAR25",
    label: "EV014571: PAR25"
  },
  {
    valueId: "EV014574",
    valueDesc: "PAR56",
    label: "EV014574: PAR56"
  },
  {
    valueId: "EV014575",
    valueDesc: "R111",
    label: "EV014575: R111"
  },
  {
    valueId: "EV014577",
    valueDesc: "E14s",
    label: "EV014577: E14s"
  },
  {
    valueId: "EV014578",
    valueDesc: "E5.5",
    label: "EV014578: E5.5"
  },
  {
    valueId: "EV014579",
    valueDesc: "G1.27",
    label: "EV014579: G1.27"
  },
  {
    valueId: "EV014580",
    valueDesc: "G2.54",
    label: "EV014580: G2.54"
  },
  {
    valueId: "EV014581",
    valueDesc: "G3.17",
    label: "EV014581: G3.17"
  },
  {
    valueId: "EV014582",
    valueDesc: "MF6s/8",
    label: "EV014582: MF6s/8"
  },
  {
    valueId: "EV014583",
    valueDesc: "MM3s/6",
    label: "EV014583: MM3s/6"
  },
  {
    valueId: "EV014584",
    valueDesc: "MP screw terminal",
    label: "EV014584: MP screw terminal"
  },
  {
    valueId: "EV014585",
    valueDesc: "S15",
    label: "EV014585: S15"
  },
  {
    valueId: "EV014586",
    valueDesc: "S4S/8",
    label: "EV014586: S4S/8"
  },
  {
    valueId: "EV014587",
    valueDesc: "S5.5",
    label: "EV014587: S5.5"
  },
  {
    valueId: "EV014588",
    valueDesc: "S7d",
    label: "EV014588: S7d"
  },
  {
    valueId: "EV014589",
    valueDesc: "SM4s/7",
    label: "EV014589: SM4s/7"
  },
  {
    valueId: "EV014590",
    valueDesc: "SM5s",
    label: "EV014590: SM5s"
  },
  {
    valueId: "EV014591",
    valueDesc: "SV8.5",
    label: "EV014591: SV8.5"
  },
  {
    valueId: "EV014592",
    valueDesc: "SX3s",
    label: "EV014592: SX3s"
  },
  {
    valueId: "EV014593",
    valueDesc: "SX6s",
    label: "EV014593: SX6s"
  },
  {
    valueId: "EV014594",
    valueDesc: "T6.0",
    label: "EV014594: T6.0"
  },
  {
    valueId: "EV014595",
    valueDesc: "TS1",
    label: "EV014595: TS1"
  },
  {
    valueId: "EV014596",
    valueDesc: "TS2",
    label: "EV014596: TS2"
  },
  {
    valueId: "EV014597",
    valueDesc: "TS3",
    label: "EV014597: TS3"
  },
  {
    valueId: "EV014598",
    valueDesc: "TS5",
    label: "EV014598: TS5"
  },
  {
    valueId: "EV014599",
    valueDesc: "W2.1x4.9d",
    label: "EV014599: W2.1x4.9d"
  },
  {
    valueId: "EV014602",
    valueDesc: "Red/yellow",
    label: "EV014602: Red/yellow"
  },
  {
    valueId: "EV014604",
    valueDesc: "G19",
    label: "EV014604: G19"
  },
  {
    valueId: "EV014605",
    valueDesc: "G30",
    label: "EV014605: G30"
  },
  {
    valueId: "EV014606",
    valueDesc: "G40",
    label: "EV014606: G40"
  },
  {
    valueId: "EV014607",
    valueDesc: "B3 1/4",
    label: "EV014607: B3 1/4"
  },
  {
    valueId: "EV014608",
    valueDesc: "G3 1/2",
    label: "EV014608: G3 1/2"
  },
  {
    valueId: "EV014609",
    valueDesc: "G4 1/2",
    label: "EV014609: G4 1/2"
  },
  {
    valueId: "EV014610",
    valueDesc: "G6",
    label: "EV014610: G6"
  },
  {
    valueId: "EV014611",
    valueDesc: "HB2",
    label: "EV014611: HB2"
  },
  {
    valueId: "EV014612",
    valueDesc: "R12",
    label: "EV014612: R12"
  },
  {
    valueId: "EV014613",
    valueDesc: "T1 3/4",
    label: "EV014613: T1 3/4"
  },
  {
    valueId: "EV014614",
    valueDesc: "T2 1/2",
    label: "EV014614: T2 1/2"
  },
  {
    valueId: "EV014615",
    valueDesc: "T3 1/4",
    label: "EV014615: T3 1/4"
  },
  {
    valueId: "EV014616",
    valueDesc: "TL3",
    label: "EV014616: TL3"
  },
  {
    valueId: "EV014617",
    valueDesc: "A16",
    label: "EV014617: A16"
  },
  {
    valueId: "EV014618",
    valueDesc: "G10",
    label: "EV014618: G10"
  },
  {
    valueId: "EV014619",
    valueDesc: "G20",
    label: "EV014619: G20"
  },
  {
    valueId: "EV014620",
    valueDesc: "PAR30",
    label: "EV014620: PAR30"
  },
  {
    valueId: "EV014621",
    valueDesc: "R30",
    label: "EV014621: R30"
  },
  {
    valueId: "EV014622",
    valueDesc: "SLS",
    label: "EV014622: SLS"
  },
  {
    valueId: "EV014623",
    valueDesc: "T16",
    label: "EV014623: T16"
  },
  {
    valueId: "EV014624",
    valueDesc: "T19",
    label: "EV014624: T19"
  },
  {
    valueId: "EV014625",
    valueDesc: "T20",
    label: "EV014625: T20"
  },
  {
    valueId: "EV014626",
    valueDesc: "T23",
    label: "EV014626: T23"
  },
  {
    valueId: "EV014627",
    valueDesc: "T24",
    label: "EV014627: T24"
  },
  {
    valueId: "EV014629",
    valueDesc: "DC Bay",
    label: "EV014629: DC Bay"
  },
  {
    valueId: "EV014630",
    valueDesc: "ED23",
    label: "EV014630: ED23"
  },
  {
    valueId: "EV014631",
    valueDesc: "A-type",
    label: "EV014631: A-type"
  },
  {
    valueId: "EV014632",
    valueDesc: "H40",
    label: "EV014632: H40"
  },
  {
    valueId: "EV014633",
    valueDesc: "BDTH",
    label: "EV014633: BDTH"
  },
  {
    valueId: "EV014634",
    valueDesc: "BCA",
    label: "EV014634: BCA"
  },
  {
    valueId: "EV014635",
    valueDesc: "CYV",
    label: "EV014635: CYV"
  },
  {
    valueId: "EV014636",
    valueDesc: "EBV",
    label: "EV014636: EBV"
  },
  {
    valueId: "EV014637",
    valueDesc: "EBW",
    label: "EV014637: EBW"
  },
  {
    valueId: "EV014638",
    valueDesc: "ECA",
    label: "EV014638: ECA"
  },
  {
    valueId: "EV014639",
    valueDesc: "EHD",
    label: "EV014639: EHD"
  },
  {
    valueId: "EV014640",
    valueDesc: "EHP",
    label: "EV014640: EHP"
  },
  {
    valueId: "EV014641",
    valueDesc: "EPT",
    label: "EV014641: EPT"
  },
  {
    valueId: "EV014642",
    valueDesc: "ETC",
    label: "EV014642: ETC"
  },
  {
    valueId: "EV014643",
    valueDesc: "EVW",
    label: "EV014643: EVW"
  },
  {
    valueId: "EV014644",
    valueDesc: "C-ring",
    label: "EV014644: C-ring"
  },
  {
    valueId: "EV014645",
    valueDesc: "E-ring",
    label: "EV014645: E-ring"
  },
  {
    valueId: "EV014647",
    valueDesc: "Basic ring",
    label: "EV014647: Basic ring"
  },
  {
    valueId: "EV014648",
    valueDesc: "Self-locking nut",
    label: "EV014648: Self-locking nut"
  },
  {
    valueId: "EV014650",
    valueDesc: "Panoramic",
    label: "EV014650: Panoramic"
  },
  {
    valueId: "EV014658",
    valueDesc: "Spring pin dual-sided",
    label: "EV014658: Spring pin dual-sided"
  },
  {
    valueId: "EV014659",
    valueDesc: "Linchpin",
    label: "EV014659: Linchpin"
  },
  {
    valueId: "EV014660",
    valueDesc: "Split pin",
    label: "EV014660: Split pin"
  },
  {
    valueId: "EV014661",
    valueDesc: "R-shaped spring type",
    label: "EV014661: R-shaped spring type"
  },
  {
    valueId: "EV014662",
    valueDesc: "Graphics card",
    label: "EV014662: Graphics card"
  },
  {
    valueId: "EV014663",
    valueDesc: "Address card",
    label: "EV014663: Address card"
  },
  {
    valueId: "EV014664",
    valueDesc: "Licence key",
    label: "EV014664: Licence key"
  },
  {
    valueId: "EV014665",
    valueDesc: "IPX8",
    label: "EV014665: IPX8"
  },
  {
    valueId: "EV014666",
    valueDesc: "IP69",
    label: "EV014666: IP69"
  },
  {
    valueId: "EV014667",
    valueDesc: "Docking frame",
    label: "EV014667: Docking frame"
  },
  {
    valueId: "EV014668",
    valueDesc: "Rail bracket",
    label: "EV014668: Rail bracket"
  },
  {
    valueId: "EV014669",
    valueDesc: "Counter frame",
    label: "EV014669: Counter frame"
  },
  {
    valueId: "EV014670",
    valueDesc: "Terminal block/plug-in",
    label: "EV014670: Terminal block/plug-in"
  },
  {
    valueId: "EV014671",
    valueDesc: "C-rail",
    label: "EV014671: C-rail"
  },
  {
    valueId: "EV014672",
    valueDesc: "Nickel/gold",
    label: "EV014672: Nickel/gold"
  },
  {
    valueId: "EV014678",
    valueDesc: "Liquid crystal polymer",
    label: "EV014678: Liquid crystal polymer"
  },
  {
    valueId: "EV014679",
    valueDesc: "Polybutylene terephthalate (PBT)",
    label: "EV014679: Polybutylene terephthalate (PBT)"
  },
  {
    valueId: "EV014680",
    valueDesc: "Locking bolt",
    label: "EV014680: Locking bolt"
  },
  {
    valueId: "EV014681",
    valueDesc: "Rolled",
    label: "EV014681: Rolled"
  },
  {
    valueId: "EV014682",
    valueDesc: "Jaw clamp",
    label: "EV014682: Jaw clamp"
  },
  {
    valueId: "EV014683",
    valueDesc: "Chrome-vanadium molybdenum (CVM)",
    label: "EV014683: Chrome-vanadium molybdenum (CVM)"
  },
  {
    valueId: "EV014696",
    valueDesc: "Foundation box",
    label: "EV014696: Foundation box"
  },
  {
    valueId: "EV014697",
    valueDesc: "Transmission arm",
    label: "EV014697: Transmission arm"
  },
  {
    valueId: "EV014698",
    valueDesc: "Siren",
    label: "EV014698: Siren"
  },
  {
    valueId: "EV014699",
    valueDesc: "Hood",
    label: "EV014699: Hood"
  },
  {
    valueId: "EV014700",
    valueDesc: "Wall mount box",
    label: "EV014700: Wall mount box"
  },
  {
    valueId: "EV014701",
    valueDesc: "Pan-Tilt-Zoom",
    label: "EV014701: Pan-Tilt-Zoom"
  },
  {
    valueId: "EV014702",
    valueDesc: "Standard (Fixed)",
    label: "EV014702: Standard (Fixed)"
  },
  {
    valueId: "EV014703",
    valueDesc: "Reader",
    label: "EV014703: Reader"
  },
  {
    valueId: "EV014704",
    valueDesc: "Enrolment device",
    label: "EV014704: Enrolment device"
  },
  {
    valueId: "EV014705",
    valueDesc: "Biometric and code",
    label: "EV014705: Biometric and code"
  },
  {
    valueId: "EV014706",
    valueDesc: "Data medium and biometric",
    label: "EV014706: Data medium and biometric"
  },
  {
    valueId: "EV014707",
    valueDesc: "Data medium and code",
    label: "EV014707: Data medium and code"
  },
  {
    valueId: "EV014708",
    valueDesc: "Data medium, biometric and code",
    label: "EV014708: Data medium, biometric and code"
  },
  {
    valueId: "EV014709",
    valueDesc: "iClass/iClass SE (13.56 MHz)",
    label: "EV014709: iClass/iClass SE (13.56 MHz)"
  },
  {
    valueId: "EV014710",
    valueDesc: "Magnet strip",
    label: "EV014710: Magnet strip"
  },
  {
    valueId: "EV014711",
    valueDesc: "MIFARE (13.56 MHz)",
    label: "EV014711: MIFARE (13.56 MHz)"
  },
  {
    valueId: "EV014712",
    valueDesc: "Prox/EM (13.56 MHz/125 KHz)",
    label: "EV014712: Prox/EM (13.56 MHz/125 KHz)"
  },
  {
    valueId: "EV014714",
    valueDesc: "Intel Celeron",
    label: "EV014714: Intel Celeron"
  },
  {
    valueId: "EV014719",
    valueDesc: "Decoder",
    label: "EV014719: Decoder"
  },
  {
    valueId: "EV014720",
    valueDesc: "Encoder",
    label: "EV014720: Encoder"
  },
  {
    valueId: "EV014722",
    valueDesc: "Diagnostic/installation tool",
    label: "EV014722: Diagnostic/installation tool"
  },
  {
    valueId: "EV014723",
    valueDesc: "I/O or audio device",
    label: "EV014723: I/O or audio device"
  },
  {
    valueId: "EV014724",
    valueDesc: "Input device",
    label: "EV014724: Input device"
  },
  {
    valueId: "EV014725",
    valueDesc: "Corrugated metal sheet",
    label: "EV014725: Corrugated metal sheet"
  },
  {
    valueId: "EV014726",
    valueDesc: "DW",
    label: "EV014726: DW"
  },
  {
    valueId: "EV014727",
    valueDesc: "Compressor",
    label: "EV014727: Compressor"
  },
  {
    valueId: "EV014728",
    valueDesc: "Filter drier",
    label: "EV014728: Filter drier"
  },
  {
    valueId: "EV014729",
    valueDesc: "Turned (lathe)",
    label: "EV014729: Turned (lathe)"
  },
  {
    valueId: "EV014730",
    valueDesc: "Cable to ground rod",
    label: "EV014730: Cable to ground rod"
  },
  {
    valueId: "EV014731",
    valueDesc: "Cable to rebar",
    label: "EV014731: Cable to rebar"
  },
  {
    valueId: "EV014732",
    valueDesc: "Cable to steel",
    label: "EV014732: Cable to steel"
  },
  {
    valueId: "EV014733",
    valueDesc: "Cathodic protection",
    label: "EV014733: Cathodic protection"
  },
  {
    valueId: "EV014734",
    valueDesc: "Crankcase heater",
    label: "EV014734: Crankcase heater"
  },
  {
    valueId: "EV014735",
    valueDesc: "Screw rivet",
    label: "EV014735: Screw rivet"
  },
  {
    valueId: "EV014736",
    valueDesc: "Percussion rivet",
    label: "EV014736: Percussion rivet"
  },
  {
    valueId: "EV014737",
    valueDesc: "Cable to lug or busbar",
    label: "EV014737: Cable to lug or busbar"
  },
  {
    valueId: "EV014738",
    valueDesc: "Grounding",
    label: "EV014738: Grounding"
  },
  {
    valueId: "EV014739",
    valueDesc: "Industrial",
    label: "EV014739: Industrial"
  },
  {
    valueId: "EV014740",
    valueDesc: "Ground rod to busbar",
    label: "EV014740: Ground rod to busbar"
  },
  {
    valueId: "EV014741",
    valueDesc: "Busbar to busbar",
    label: "EV014741: Busbar to busbar"
  },
  {
    valueId: "EV014742",
    valueDesc: "Lug or busbar to steel",
    label: "EV014742: Lug or busbar to steel"
  },
  {
    valueId: "EV014743",
    valueDesc: "Pole",
    label: "EV014743: Pole"
  },
  {
    valueId: "EV014744",
    valueDesc: "Test terminal",
    label: "EV014744: Test terminal"
  },
  {
    valueId: "EV014745",
    valueDesc: "Wire reel",
    label: "EV014745: Wire reel"
  },
  {
    valueId: "EV014746",
    valueDesc: "Cross-shaped keys",
    label: "EV014746: Cross-shaped keys"
  },
  {
    valueId: "EV014747",
    valueDesc: "Plastic/stainless steel 304 (V2A)",
    label: "EV014747: Plastic/stainless steel 304 (V2A)"
  },
  {
    valueId: "EV014750",
    valueDesc: "Aluminium magnesium silicon alloy",
    label: "EV014750: Aluminium magnesium silicon alloy"
  },
  {
    valueId: "EV014752",
    valueDesc: "Copper/bronze",
    label: "EV014752: Copper/bronze"
  },
  {
    valueId: "EV014758",
    valueDesc: "1.5 modular units",
    label: "EV014758: 1.5 modular units"
  },
  {
    valueId: "EV014759",
    valueDesc: "4.5 modular units",
    label: "EV014759: 4.5 modular units"
  },
  {
    valueId: "EV014760",
    valueDesc: "IPX4W",
    label: "EV014760: IPX4W"
  },
  {
    valueId: "EV014761",
    valueDesc: "1.6/5.6 plug / 1.6/5.6 socket",
    label: "EV014761: 1.6/5.6 plug / 1.6/5.6 socket"
  },
  {
    valueId: "EV014762",
    valueDesc: "Terminal/RJ45",
    label: "EV014762: Terminal/RJ45"
  },
  {
    valueId: "EV014763",
    valueDesc: "LSA/RJ45",
    label: "EV014763: LSA/RJ45"
  },
  {
    valueId: "EV014765",
    valueDesc: "Electronic ignitor",
    label: "EV014765: Electronic ignitor"
  },
  {
    valueId: "EV014766",
    valueDesc: "Flint ignitor",
    label: "EV014766: Flint ignitor"
  },
  {
    valueId: "EV014767",
    valueDesc: "Hammer support",
    label: "EV014767: Hammer support"
  },
  {
    valueId: "EV014769",
    valueDesc: "Optical remote display",
    label: "EV014769: Optical remote display"
  },
  {
    valueId: "EV014770",
    valueDesc: "Earthing bracket",
    label: "EV014770: Earthing bracket"
  },
  {
    valueId: "EV014771",
    valueDesc: "Sealing device",
    label: "EV014771: Sealing device"
  },
  {
    valueId: "EV014772",
    valueDesc: "Voltage limiting device",
    label: "EV014772: Voltage limiting device"
  },
  {
    valueId: "EV014773",
    valueDesc: "Mast adapter for voltage limiting device",
    label: "EV014773: Mast adapter for voltage limiting device"
  },
  {
    valueId: "EV014774",
    valueDesc: "Impulse counter",
    label: "EV014774: Impulse counter"
  },
  {
    valueId: "EV014775",
    valueDesc: "Earthing ring",
    label: "EV014775: Earthing ring"
  },
  {
    valueId: "EV014776",
    valueDesc: "Interior and exterior area",
    label: "EV014776: Interior and exterior area"
  },
  {
    valueId: "EV014777",
    valueDesc: "Earthing module",
    label: "EV014777: Earthing module"
  },
  {
    valueId: "EV014779",
    valueDesc: "Railway systems",
    label: "EV014779: Railway systems"
  },
  {
    valueId: "EV014780",
    valueDesc: "Power cables for overhead lines and outdoor switchgears",
    label: "EV014780: Power cables for overhead lines and outdoor switchgears"
  },
  {
    valueId: "EV014781",
    valueDesc: "Switchgears",
    label: "EV014781: Switchgears"
  },
  {
    valueId: "EV014782",
    valueDesc: "S/L",
    label: "EV014782: S/L"
  },
  {
    valueId: "EV014783",
    valueDesc: "Measuring impedance LRM - XC",
    label: "EV014783: Measuring impedance LRM - XC"
  },
  {
    valueId: "EV014784",
    valueDesc: "Measuring adapter LR - LRM",
    label: "EV014784: Measuring adapter LR - LRM"
  },
  {
    valueId: "EV014785",
    valueDesc: "Operating rod",
    label: "EV014785: Operating rod"
  },
  {
    valueId: "EV014786",
    valueDesc: "Earthing rod",
    label: "EV014786: Earthing rod"
  },
  {
    valueId: "EV014787",
    valueDesc: "Earth connecting element with wing nut M12",
    label: "EV014787: Earth connecting element with wing nut M12"
  },
  {
    valueId: "EV014788",
    valueDesc: "Earth connecting element with wing nut M16",
    label: "EV014788: Earth connecting element with wing nut M16"
  },
  {
    valueId: "EV014789",
    valueDesc: "Earthing clamp",
    label: "EV014789: Earthing clamp"
  },
  {
    valueId: "EV014790",
    valueDesc: "Screw clamp",
    label: "EV014790: Screw clamp"
  },
  {
    valueId: "EV014791",
    valueDesc: "T-connection for earthing cartridge",
    label: "EV014791: T-connection for earthing cartridge"
  },
  {
    valueId: "EV014792",
    valueDesc: "USB-Stick",
    label: "EV014792: USB-Stick"
  },
  {
    valueId: "EV014793",
    valueDesc: "Earth-side",
    label: "EV014793: Earth-side"
  },
  {
    valueId: "EV014794",
    valueDesc: "Phase-side",
    label: "EV014794: Phase-side"
  },
  {
    valueId: "EV014795",
    valueDesc: "Silicone cable, red",
    label: "EV014795: Silicone cable, red"
  },
  {
    valueId: "EV014796",
    valueDesc: "Copper alloy",
    label: "EV014796: Copper alloy"
  },
  {
    valueId: "EV014797",
    valueDesc: "Mast adapter",
    label: "EV014797: Mast adapter"
  },
  {
    valueId: "EV014798",
    valueDesc: "Lock-out device",
    label: "EV014798: Lock-out device"
  },
  {
    valueId: "EV014799",
    valueDesc: "Screw-in earthing insert",
    label: "EV014799: Screw-in earthing insert"
  },
  {
    valueId: "EV014800",
    valueDesc: "Operating head",
    label: "EV014800: Operating head"
  },
  {
    valueId: "EV014801",
    valueDesc: "Oil reservoir",
    label: "EV014801: Oil reservoir"
  },
  {
    valueId: "EV014802",
    valueDesc: "Cleaning material",
    label: "EV014802: Cleaning material"
  },
  {
    valueId: "EV014803",
    valueDesc: "Lock-out element",
    label: "EV014803: Lock-out element"
  },
  {
    valueId: "EV014804",
    valueDesc: "Extension prod",
    label: "EV014804: Extension prod"
  },
  {
    valueId: "EV014805",
    valueDesc: "Remote control panel",
    label: "EV014805: Remote control panel"
  },
  {
    valueId: "EV014806",
    valueDesc: "DIN rail mounting",
    label: "EV014806: DIN rail mounting"
  },
  {
    valueId: "EV014807",
    valueDesc: "Neck strap",
    label: "EV014807: Neck strap"
  },
  {
    valueId: "EV014808",
    valueDesc: "In-ear headphone",
    label: "EV014808: In-ear headphone"
  },
  {
    valueId: "EV014809",
    valueDesc: "Half-In-ear headphone",
    label: "EV014809: Half-In-ear headphone"
  },
  {
    valueId: "EV014810",
    valueDesc: "On-ear headphone",
    label: "EV014810: On-ear headphone"
  },
  {
    valueId: "EV014811",
    valueDesc: "Over-ear headphone",
    label: "EV014811: Over-ear headphone"
  },
  {
    valueId: "EV014812",
    valueDesc: "Freely positionable",
    label: "EV014812: Freely positionable"
  },
  {
    valueId: "EV014815",
    valueDesc: "H-adapter",
    label: "EV014815: H-adapter"
  },
  {
    valueId: "EV014816",
    valueDesc: "T-adapter",
    label: "EV014816: T-adapter"
  },
  {
    valueId: "EV014817",
    valueDesc: "Degree of protection (IP)",
    label: "EV014817: Degree of protection (IP)"
  },
  {
    valueId: "EV014818",
    valueDesc: "Stainless steel/plastic",
    label: "EV014818: Stainless steel/plastic"
  },
  {
    valueId: "EV014819",
    valueDesc: "Max. 33 V AC/70 V DC",
    label: "EV014819: Max. 33 V AC/70 V DC"
  },
  {
    valueId: "EV014820",
    valueDesc: "Radio clock",
    label: "EV014820: Radio clock"
  },
  {
    valueId: "EV014822",
    valueDesc: "Solid-state drive",
    label: "EV014822: Solid-state drive"
  },
  {
    valueId: "EV014823",
    valueDesc: "microSD",
    label: "EV014823: microSD"
  },
  {
    valueId: "EV014824",
    valueDesc: "Action camera",
    label: "EV014824: Action camera"
  },
  {
    valueId: "EV014825",
    valueDesc: "12/24 V",
    label: "EV014825: 12/24 V"
  },
  {
    valueId: "EV014826",
    valueDesc: "Current/gas",
    label: "EV014826: Current/gas"
  },
  {
    valueId: "EV014827",
    valueDesc: "Ironing station",
    label: "EV014827: Ironing station"
  },
  {
    valueId: "EV014828",
    valueDesc: "Blowing function",
    label: "EV014828: Blowing function"
  },
  {
    valueId: "EV014829",
    valueDesc: "Suction function",
    label: "EV014829: Suction function"
  },
  {
    valueId: "EV014830",
    valueDesc: "Blowing/suction function",
    label: "EV014830: Blowing/suction function"
  },
  {
    valueId: "EV014831",
    valueDesc: "HDR10",
    label: "EV014831: HDR10"
  },
  {
    valueId: "EV014832",
    valueDesc: "Dolby Vision",
    label: "EV014832: Dolby Vision"
  },
  {
    valueId: "EV014833",
    valueDesc: "Manufacturer specific",
    label: "EV014833: Manufacturer specific"
  },
  {
    valueId: "EV014834",
    valueDesc: "Loop amplifier",
    label: "EV014834: Loop amplifier"
  },
  {
    valueId: "EV014835",
    valueDesc: "Earthing mortar",
    label: "EV014835: Earthing mortar"
  },
  {
    valueId: "EV014836",
    valueDesc: "G-fuse 4.5x15 mm",
    label: "EV014836: G-fuse 4.5x15 mm"
  },
  {
    valueId: "EV014837",
    valueDesc: "G-fuse 5x15 mm",
    label: "EV014837: G-fuse 5x15 mm"
  },
  {
    valueId: "EV014838",
    valueDesc: "Microphone clamp",
    label: "EV014838: Microphone clamp"
  },
  {
    valueId: "EV014839",
    valueDesc: "Microphone boom arm",
    label: "EV014839: Microphone boom arm"
  },
  {
    valueId: "EV014840",
    valueDesc: "End of line module",
    label: "EV014840: End of line module"
  },
  {
    valueId: "EV014841",
    valueDesc: "Barrier boom",
    label: "EV014841: Barrier boom"
  },
  {
    valueId: "EV014842",
    valueDesc: "Fire dome",
    label: "EV014842: Fire dome"
  },
  {
    valueId: "EV014843",
    valueDesc: "Weekly timer",
    label: "EV014843: Weekly timer"
  },
  {
    valueId: "EV014844",
    valueDesc: "1/2 long",
    label: "EV014844: 1/2 long"
  },
  {
    valueId: "EV014845",
    valueDesc: "Beanie",
    label: "EV014845: Beanie"
  },
  {
    valueId: "EV014846",
    valueDesc: "Beret",
    label: "EV014846: Beret"
  },
  {
    valueId: "EV014847",
    valueDesc: "Bouffant cap",
    label: "EV014847: Bouffant cap"
  },
  {
    valueId: "EV014848",
    valueDesc: "Bandana",
    label: "EV014848: Bandana"
  },
  {
    valueId: "EV014849",
    valueDesc: "Hat",
    label: "EV014849: Hat"
  },
  {
    valueId: "EV014850",
    valueDesc: "16/19/20 mm",
    label: "EV014850: 16/19/20 mm"
  },
  {
    valueId: "EV014851",
    valueDesc: "Message manager",
    label: "EV014851: Message manager"
  },
  {
    valueId: "EV014852",
    valueDesc: "Audio expander",
    label: "EV014852: Audio expander"
  },
  {
    valueId: "EV014853",
    valueDesc: "Quarter sphere-shape",
    label: "EV014853: Quarter sphere-shape"
  },
  {
    valueId: "EV014854",
    valueDesc: "Eighth sphere-shape",
    label: "EV014854: Eighth sphere-shape"
  },
  {
    valueId: "EV014855",
    valueDesc: "Underpants",
    label: "EV014855: Underpants"
  },
  {
    valueId: "EV014856",
    valueDesc: "Undershirt",
    label: "EV014856: Undershirt"
  },
  {
    valueId: "EV014857",
    valueDesc: "Bra",
    label: "EV014857: Bra"
  },
  {
    valueId: "EV014858",
    valueDesc: "Bodysuit",
    label: "EV014858: Bodysuit"
  },
  {
    valueId: "EV014859",
    valueDesc: "Fire fighting",
    label: "EV014859: Fire fighting"
  },
  {
    valueId: "EV014860",
    valueDesc: "Thread M45",
    label: "EV014860: Thread M45"
  },
  {
    valueId: "EV014861",
    valueDesc: "Rounded thread",
    label: "EV014861: Rounded thread"
  },
  {
    valueId: "EV014862",
    valueDesc: "Brake pad",
    label: "EV014862: Brake pad"
  },
  {
    valueId: "EV014863",
    valueDesc: "Terminal block",
    label: "EV014863: Terminal block"
  },
  {
    valueId: "EV014864",
    valueDesc: "Carbon brush",
    label: "EV014864: Carbon brush"
  },
  {
    valueId: "EV014865",
    valueDesc: "End shield",
    label: "EV014865: End shield"
  },
  {
    valueId: "EV014866",
    valueDesc: "Fan cover",
    label: "EV014866: Fan cover"
  },
  {
    valueId: "EV014867",
    valueDesc: "Shaft key",
    label: "EV014867: Shaft key"
  },
  {
    valueId: "EV014868",
    valueDesc: "Sanitary napkin",
    label: "EV014868: Sanitary napkin"
  },
  {
    valueId: "EV014869",
    valueDesc: "Deodorant",
    label: "EV014869: Deodorant"
  },
  {
    valueId: "EV014870",
    valueDesc: "Body moisturizer",
    label: "EV014870: Body moisturizer"
  },
  {
    valueId: "EV014871",
    valueDesc: "Skin-Sanitizer",
    label: "EV014871: Skin-Sanitizer"
  },
  {
    valueId: "EV014873",
    valueDesc: "Contact lens cleaner",
    label: "EV014873: Contact lens cleaner"
  },
  {
    valueId: "EV014874",
    valueDesc: "Lip balm",
    label: "EV014874: Lip balm"
  },
  {
    valueId: "EV014875",
    valueDesc: "Perfume",
    label: "EV014875: Perfume"
  },
  {
    valueId: "EV014876",
    valueDesc: "Barrier cream",
    label: "EV014876: Barrier cream"
  },
  {
    valueId: "EV014877",
    valueDesc: "Shampoo",
    label: "EV014877: Shampoo"
  },
  {
    valueId: "EV014878",
    valueDesc: "Sunscreen",
    label: "EV014878: Sunscreen"
  },
  {
    valueId: "EV014879",
    valueDesc: "Tampon",
    label: "EV014879: Tampon"
  },
  {
    valueId: "EV014880",
    valueDesc: "Solid material",
    label: "EV014880: Solid material"
  },
  {
    valueId: "EV014881",
    valueDesc: "Gel/lotion",
    label: "EV014881: Gel/lotion"
  },
  {
    valueId: "EV014882",
    valueDesc: "Foam",
    label: "EV014882: Foam"
  },
  {
    valueId: "EV014883",
    valueDesc: "Squeeze action tube",
    label: "EV014883: Squeeze action tube"
  },
  {
    valueId: "EV014884",
    valueDesc: "Pouch/bag (disposable)",
    label: "EV014884: Pouch/bag (disposable)"
  },
  {
    valueId: "EV014885",
    valueDesc: "Soft drink",
    label: "EV014885: Soft drink"
  },
  {
    valueId: "EV014886",
    valueDesc: "Coffee",
    label: "EV014886: Coffee"
  },
  {
    valueId: "EV014887",
    valueDesc: "Milk",
    label: "EV014887: Milk"
  },
  {
    valueId: "EV014888",
    valueDesc: "Tea",
    label: "EV014888: Tea"
  },
  {
    valueId: "EV014889",
    valueDesc: "Hot chocolate",
    label: "EV014889: Hot chocolate"
  },
  {
    valueId: "EV014890",
    valueDesc: "Freeze pop",
    label: "EV014890: Freeze pop"
  },
  {
    valueId: "EV014891",
    valueDesc: "Foil pack",
    label: "EV014891: Foil pack"
  },
  {
    valueId: "EV014892",
    valueDesc: "Bean",
    label: "EV014892: Bean"
  },
  {
    valueId: "EV014893",
    valueDesc: "Helmet",
    label: "EV014893: Helmet"
  },
  {
    valueId: "EV014894",
    valueDesc: "Belt strap",
    label: "EV014894: Belt strap"
  },
  {
    valueId: "EV014895",
    valueDesc: "Facepiece",
    label: "EV014895: Facepiece"
  },
  {
    valueId: "EV014896",
    valueDesc: "Facial tissue",
    label: "EV014896: Facial tissue"
  },
  {
    valueId: "EV014897",
    valueDesc: "Bathroom tissue",
    label: "EV014897: Bathroom tissue"
  },
  {
    valueId: "EV014898",
    valueDesc: "Paper towel",
    label: "EV014898: Paper towel"
  },
  {
    valueId: "EV014899",
    valueDesc: "Wipers",
    label: "EV014899: Wipers"
  },
  {
    valueId: "EV014900",
    valueDesc: "Napkin",
    label: "EV014900: Napkin"
  },
  {
    valueId: "EV014901",
    valueDesc: "Toilet seat cover",
    label: "EV014901: Toilet seat cover"
  },
  {
    valueId: "EV014902",
    valueDesc: "Barrier chain",
    label: "EV014902: Barrier chain"
  },
  {
    valueId: "EV014903",
    valueDesc: "Barrier fencing",
    label: "EV014903: Barrier fencing"
  },
  {
    valueId: "EV014904",
    valueDesc: "Rewindable barrier",
    label: "EV014904: Rewindable barrier"
  },
  {
    valueId: "EV014905",
    valueDesc: "Foldable barrier",
    label: "EV014905: Foldable barrier"
  },
  {
    valueId: "EV014906",
    valueDesc: "Flagged cord",
    label: "EV014906: Flagged cord"
  },
  {
    valueId: "EV014907",
    valueDesc: "Guide barrier/separation wall",
    label: "EV014907: Guide barrier/separation wall"
  },
  {
    valueId: "EV014908",
    valueDesc: "Barrier cone",
    label: "EV014908: Barrier cone"
  },
  {
    valueId: "EV014909",
    valueDesc: "Barrier bar",
    label: "EV014909: Barrier bar"
  },
  {
    valueId: "EV014911",
    valueDesc: "Speed hump",
    label: "EV014911: Speed hump"
  },
  {
    valueId: "EV014912",
    valueDesc: "Barrier sleeve",
    label: "EV014912: Barrier sleeve"
  },
  {
    valueId: "EV014913",
    valueDesc: "Hand-held traffic signal",
    label: "EV014913: Hand-held traffic signal"
  },
  {
    valueId: "EV014914",
    valueDesc: "Emergency push button",
    label: "EV014914: Emergency push button"
  },
  {
    valueId: "EV014937",
    valueDesc: "IP46",
    label: "EV014937: IP46"
  },
  {
    valueId: "EV014940",
    valueDesc: "IP61",
    label: "EV014940: IP61"
  },
  {
    valueId: "EV014941",
    valueDesc: "IP62",
    label: "EV014941: IP62"
  },
  {
    valueId: "EV014942",
    valueDesc: "IP63",
    label: "EV014942: IP63"
  },
  {
    valueId: "EV014943",
    valueDesc: "Network splitter",
    label: "EV014943: Network splitter"
  },
  {
    valueId: "EV014944",
    valueDesc: "Microphone transmitter",
    label: "EV014944: Microphone transmitter"
  },
  {
    valueId: "EV014945",
    valueDesc: "Call station module",
    label: "EV014945: Call station module"
  },
  {
    valueId: "EV014946",
    valueDesc: "Call station keypad module",
    label: "EV014946: Call station keypad module"
  },
  {
    valueId: "EV014947",
    valueDesc: "Roughness of the surface",
    label: "EV014947: Roughness of the surface"
  },
  {
    valueId: "EV014948",
    valueDesc: "Hardness of the material",
    label: "EV014948: Hardness of the material"
  },
  {
    valueId: "EV014949",
    valueDesc: "DC-load meter",
    label: "EV014949: DC-load meter"
  },
  {
    valueId: "EV014950",
    valueDesc: "Belt tension meter",
    label: "EV014950: Belt tension meter"
  },
  {
    valueId: "EV014952",
    valueDesc: "Coating thickness gauge",
    label: "EV014952: Coating thickness gauge"
  },
  {
    valueId: "EV014953",
    valueDesc: "IC tester",
    label: "EV014953: IC tester"
  },
  {
    valueId: "EV014955",
    valueDesc: "3D glasses",
    label: "EV014955: 3D glasses"
  },
  {
    valueId: "EV014957",
    valueDesc: "Protocol converter",
    label: "EV014957: Protocol converter"
  },
  {
    valueId: "EV014958",
    valueDesc: "#8",
    label: "EV014958: #8"
  },
  {
    valueId: "EV014959",
    valueDesc: "#10",
    label: "EV014959: #10"
  },
  {
    valueId: "EV014960",
    valueDesc: "IPX2",
    label: "EV014960: IPX2"
  },
  {
    valueId: "EV014961",
    valueDesc: "Info module",
    label: "EV014961: Info module"
  },
  {
    valueId: "EV014962",
    valueDesc: "Call display",
    label: "EV014962: Call display"
  },
  {
    valueId: "EV014963",
    valueDesc: "Mailbox",
    label: "EV014963: Mailbox"
  },
  {
    valueId: "EV014964",
    valueDesc: "Video module",
    label: "EV014964: Video module"
  },
  {
    valueId: "EV014968",
    valueDesc: "PSTN",
    label: "EV014968: PSTN"
  },
  {
    valueId: "EV014969",
    valueDesc: "Main station",
    label: "EV014969: Main station"
  },
  {
    valueId: "EV014970",
    valueDesc: "Substation",
    label: "EV014970: Substation"
  },
  {
    valueId: "EV014971",
    valueDesc: "Half-duplex",
    label: "EV014971: Half-duplex"
  },
  {
    valueId: "EV014972",
    valueDesc: "Full-duplex",
    label: "EV014972: Full-duplex"
  },
  {
    valueId: "EV014973",
    valueDesc: "Anti reflection foil",
    label: "EV014973: Anti reflection foil"
  },
  {
    valueId: "EV014974",
    valueDesc: "Name card holder",
    label: "EV014974: Name card holder"
  },
  {
    valueId: "EV014975",
    valueDesc: "Induction loop neckband",
    label: "EV014975: Induction loop neckband"
  },
  {
    valueId: "EV014976",
    valueDesc: "I-shape",
    label: "EV014976: I-shape"
  },
  {
    valueId: "EV014977",
    valueDesc: "L-shape",
    label: "EV014977: L-shape"
  },
  {
    valueId: "EV014978",
    valueDesc: "Pocket receiver",
    label: "EV014978: Pocket receiver"
  },
  {
    valueId: "EV014980",
    valueDesc: "Wall connection",
    label: "EV014980: Wall connection"
  },
  {
    valueId: "EV014981",
    valueDesc: "Middle profile",
    label: "EV014981: Middle profile"
  },
  {
    valueId: "EV014982",
    valueDesc: "Cover for outer corner",
    label: "EV014982: Cover for outer corner"
  },
  {
    valueId: "EV014983",
    valueDesc: "Tablet computer",
    label: "EV014983: Tablet computer"
  },
  {
    valueId: "EV014984",
    valueDesc: "Screen protector foil",
    label: "EV014984: Screen protector foil"
  },
  {
    valueId: "EV014985",
    valueDesc: "Stick",
    label: "EV014985: Stick"
  },
  {
    valueId: "EV014988",
    valueDesc: "Electronic button",
    label: "EV014988: Electronic button"
  },
  {
    valueId: "EV014989",
    valueDesc: "0°",
    label: "EV014989: 0°"
  },
  {
    valueId: "EV014991",
    valueDesc: "Single-Cut",
    label: "EV014991: Single-Cut"
  },
  {
    valueId: "EV014992",
    valueDesc: "Double-Cut",
    label: "EV014992: Double-Cut"
  },
  {
    valueId: "EV014993",
    valueDesc: "Rasp-Cut",
    label: "EV014993: Rasp-Cut"
  },
  {
    valueId: "EV014994",
    valueDesc: "Curved-Cut",
    label: "EV014994: Curved-Cut"
  },
  {
    valueId: "EV014995",
    valueDesc: "Hand file",
    label: "EV014995: Hand file"
  },
  {
    valueId: "EV014996",
    valueDesc: "Flat file",
    label: "EV014996: Flat file"
  },
  {
    valueId: "EV014997",
    valueDesc: "Knee high",
    label: "EV014997: Knee high"
  },
  {
    valueId: "EV014998",
    valueDesc: "Ankle high",
    label: "EV014998: Ankle high"
  },
  {
    valueId: "EV014999",
    valueDesc: "Calf high",
    label: "EV014999: Calf high"
  },
  {
    valueId: "EV015000",
    valueDesc: "High leg",
    label: "EV015000: High leg"
  },
  {
    valueId: "EV015001",
    valueDesc: "Gutter set",
    label: "EV015001: Gutter set"
  },
  {
    valueId: "EV015002",
    valueDesc: "Closing frame",
    label: "EV015002: Closing frame"
  },
  {
    valueId: "EV015005",
    valueDesc: "Flat smooth",
    label: "EV015005: Flat smooth"
  },
  {
    valueId: "EV015006",
    valueDesc: "Flat profiled",
    label: "EV015006: Flat profiled"
  },
  {
    valueId: "EV015007",
    valueDesc: "Flame shaped",
    label: "EV015007: Flame shaped"
  },
  {
    valueId: "EV015009",
    valueDesc: "Telecommunication",
    label: "EV015009: Telecommunication"
  },
  {
    valueId: "EV015010",
    valueDesc: "Semi-outside",
    label: "EV015010: Semi-outside"
  },
  {
    valueId: "EV015011",
    valueDesc: "Scissors",
    label: "EV015011: Scissors"
  },
  {
    valueId: "EV015012",
    valueDesc: "Crosswise",
    label: "EV015012: Crosswise"
  },
  {
    valueId: "EV015013",
    valueDesc: "AR37",
    label: "EV015013: AR37"
  },
  {
    valueId: "EV015014",
    valueDesc: "AR48",
    label: "EV015014: AR48"
  },
  {
    valueId: "EV015015",
    valueDesc: "AR56",
    label: "EV015015: AR56"
  },
  {
    valueId: "EV015016",
    valueDesc: "AR70",
    label: "EV015016: AR70"
  },
  {
    valueId: "EV015017",
    valueDesc: "AR111",
    label: "EV015017: AR111"
  },
  {
    valueId: "EV015018",
    valueDesc: "MR8",
    label: "EV015018: MR8"
  },
  {
    valueId: "EV015019",
    valueDesc: "Suitable for through wiring",
    label: "EV015019: Suitable for through wiring"
  },
  {
    valueId: "EV015020",
    valueDesc: "Through wiring included",
    label: "EV015020: Through wiring included"
  },
  {
    valueId: "EV015021",
    valueDesc: "Middle clamp",
    label: "EV015021: Middle clamp"
  },
  {
    valueId: "EV015022",
    valueDesc: "Termination clamp",
    label: "EV015022: Termination clamp"
  },
  {
    valueId: "EV015023",
    valueDesc: "Profile connector",
    label: "EV015023: Profile connector"
  },
  {
    valueId: "EV015024",
    valueDesc: "Slide-system middle element",
    label: "EV015024: Slide-system middle element"
  },
  {
    valueId: "EV015025",
    valueDesc: "Slide-system end element",
    label: "EV015025: Slide-system end element"
  },
  {
    valueId: "EV015026",
    valueDesc: "Blue/white",
    label: "EV015026: Blue/white"
  },
  {
    valueId: "EV015027",
    valueDesc: "NH01",
    label: "EV015027: NH01"
  },
  {
    valueId: "EV015028",
    valueDesc: "NH02",
    label: "EV015028: NH02"
  },
  {
    valueId: "EV015029",
    valueDesc: "NH03",
    label: "EV015029: NH03"
  },
  {
    valueId: "EV015030",
    valueDesc: "NH1C",
    label: "EV015030: NH1C"
  },
  {
    valueId: "EV015031",
    valueDesc: "NH2C",
    label: "EV015031: NH2C"
  },
  {
    valueId: "EV015032",
    valueDesc: "NH3C",
    label: "EV015032: NH3C"
  },
  {
    valueId: "EV015033",
    valueDesc: "UL",
    label: "EV015033: UL"
  },
  {
    valueId: "EV015034",
    valueDesc: "IEEE",
    label: "EV015034: IEEE"
  },
  {
    valueId: "EV015035",
    valueDesc: "Full-range",
    label: "EV015035: Full-range"
  },
  {
    valueId: "EV015036",
    valueDesc: "Back-up",
    label: "EV015036: Back-up"
  },
  {
    valueId: "EV015037",
    valueDesc: "General purpose",
    label: "EV015037: General purpose"
  },
  {
    valueId: "EV015038",
    valueDesc: "Fixed buttonhead",
    label: "EV015038: Fixed buttonhead"
  },
  {
    valueId: "EV015039",
    valueDesc: "Removable buttonhead",
    label: "EV015039: Removable buttonhead"
  },
  {
    valueId: "EV015040",
    valueDesc: "Double tail",
    label: "EV015040: Double tail"
  },
  {
    valueId: "EV015041",
    valueDesc: "Busbar cutter",
    label: "EV015041: Busbar cutter"
  },
  {
    valueId: "EV015042",
    valueDesc: "Busbar bender",
    label: "EV015042: Busbar bender"
  },
  {
    valueId: "EV015043",
    valueDesc: "CSA",
    label: "EV015043: CSA"
  },
  {
    valueId: "EV015044",
    valueDesc: "TL",
    label: "EV015044: TL"
  },
  {
    valueId: "EV015045",
    valueDesc: "TC",
    label: "EV015045: TC"
  },
  {
    valueId: "EV015046",
    valueDesc: "SL",
    label: "EV015046: SL"
  },
  {
    valueId: "EV015047",
    valueDesc: "Card encoder",
    label: "EV015047: Card encoder"
  },
  {
    valueId: "EV015048",
    valueDesc: "Transliteration foot pedal",
    label: "EV015048: Transliteration foot pedal"
  },
  {
    valueId: "EV015049",
    valueDesc: "Three pole",
    label: "EV015049: Three pole"
  },
  {
    valueId: "EV015050",
    valueDesc: "Hot plate",
    label: "EV015050: Hot plate"
  },
  {
    valueId: "EV015051",
    valueDesc: "Gas flame",
    label: "EV015051: Gas flame"
  },
  {
    valueId: "EV015052",
    valueDesc: "Heating coil",
    label: "EV015052: Heating coil"
  },
  {
    valueId: "EV015053",
    valueDesc: "IP66/IP67/IP69",
    label: "EV015053: IP66/IP67/IP69"
  },
  {
    valueId: "EV015054",
    valueDesc: "Data slide line protection",
    label: "EV015054: Data slide line protection"
  },
  {
    valueId: "EV015055",
    valueDesc: "Quick selection disc 'Personenschutz'",
    label: "EV015055: Quick selection disc 'Personenschutz'"
  },
  {
    valueId: "EV015056",
    valueDesc: "Drawing template",
    label: "EV015056: Drawing template"
  },
  {
    valueId: "EV015057",
    valueDesc: "Electrical schematic",
    label: "EV015057: Electrical schematic"
  },
  {
    valueId: "EV015058",
    valueDesc: "Electrical symbols",
    label: "EV015058: Electrical symbols"
  },
  {
    valueId: "EV015059",
    valueDesc: "Housing symbols",
    label: "EV015059: Housing symbols"
  },
  {
    valueId: "EV015060",
    valueDesc: "AU/NZ/PG/AR (AS/NZS 3112) (type I)",
    label: "EV015060: AU/NZ/PG/AR (AS/NZS 3112) (type I)"
  },
  {
    valueId: "EV015061",
    valueDesc: "CH (SEV 1011 type 13) (type J)",
    label: "EV015061: CH (SEV 1011 type 13) (type J)"
  },
  {
    valueId: "EV015062",
    valueDesc: "BE/DE/NL/RU protective contact CEE 7/3 (type F)",
    label: "EV015062: BE/DE/NL/RU protective contact CEE 7/3 (type F)"
  },
  {
    valueId: "EV015063",
    valueDesc: "BE/DE/NL/RU/CH (Euro) (type C)",
    label: "EV015063: BE/DE/NL/RU/CH (Euro) (type C)"
  },
  {
    valueId: "EV015064",
    valueDesc: "FR (earth pin) (type E)",
    label: "EV015064: FR (earth pin) (type E)"
  },
  {
    valueId: "EV015065",
    valueDesc: "IT (S/P 11)",
    label: "EV015065: IT (S/P 11)"
  },
  {
    valueId: "EV015066",
    valueDesc: "IT (S/P 17) (type L)",
    label: "EV015066: IT (S/P 17) (type L)"
  },
  {
    valueId: "EV015067",
    valueDesc: "IT (P 30)",
    label: "EV015067: IT (P 30)"
  },
  {
    valueId: "EV015068",
    valueDesc: "UK/HK (BS 1363) (type G)",
    label: "EV015068: UK/HK (BS 1363) (type G)"
  },
  {
    valueId: "EV015069",
    valueDesc: "US/CA/CN (NEMA 1-15) (type A)",
    label: "EV015069: US/CA/CN (NEMA 1-15) (type A)"
  },
  {
    valueId: "EV015070",
    valueDesc: "US/CA (NEMA 5-15) (type B)",
    label: "EV015070: US/CA (NEMA 5-15) (type B)"
  },
  {
    valueId: "EV015071",
    valueDesc: "Short-time delayed",
    label: "EV015071: Short-time delayed"
  },
  {
    valueId: "EV015072",
    valueDesc: "Selective",
    label: "EV015072: Selective"
  },
  {
    valueId: "EV015073",
    valueDesc: "Isolating transformer",
    label: "EV015073: Isolating transformer"
  },
  {
    valueId: "EV015075",
    valueDesc: "Termination plug",
    label: "EV015075: Termination plug"
  },
  {
    valueId: "EV015076",
    valueDesc: "Cable splitter",
    label: "EV015076: Cable splitter"
  },
  {
    valueId: "EV015077",
    valueDesc: "Fibre optic interface",
    label: "EV015077: Fibre optic interface"
  },
  {
    valueId: "EV015078",
    valueDesc: "Call station kit",
    label: "EV015078: Call station kit"
  },
  {
    valueId: "EV015079",
    valueDesc: "Feedback suppressor",
    label: "EV015079: Feedback suppressor"
  },
  {
    valueId: "EV015080",
    valueDesc: "Reference electrode",
    label: "EV015080: Reference electrode"
  },
  {
    valueId: "EV015081",
    valueDesc: "Glass electrode",
    label: "EV015081: Glass electrode"
  },
  {
    valueId: "EV015082",
    valueDesc: "Combination electrode",
    label: "EV015082: Combination electrode"
  },
  {
    valueId: "EV015083",
    valueDesc: "pH",
    label: "EV015083: pH"
  },
  {
    valueId: "EV015084",
    valueDesc: "Redox",
    label: "EV015084: Redox"
  },
  {
    valueId: "EV015085",
    valueDesc: "Steam-sterilisable glass",
    label: "EV015085: Steam-sterilisable glass"
  },
  {
    valueId: "EV015086",
    valueDesc: "High-alkaline glass",
    label: "EV015086: High-alkaline glass"
  },
  {
    valueId: "EV015087",
    valueDesc: "High-temperature glass",
    label: "EV015087: High-temperature glass"
  },
  {
    valueId: "EV015088",
    valueDesc: "Universal glass",
    label: "EV015088: Universal glass"
  },
  {
    valueId: "EV015089",
    valueDesc: "Platinum tip",
    label: "EV015089: Platinum tip"
  },
  {
    valueId: "EV015090",
    valueDesc: "Gold tip",
    label: "EV015090: Gold tip"
  },
  {
    valueId: "EV015091",
    valueDesc: "Screw head PG 13.5",
    label: "EV015091: Screw head PG 13.5"
  },
  {
    valueId: "EV015092",
    valueDesc: "Plug head",
    label: "EV015092: Plug head"
  },
  {
    valueId: "EV015093",
    valueDesc: "Fixed cable head, plain",
    label: "EV015093: Fixed cable head, plain"
  },
  {
    valueId: "EV015094",
    valueDesc: "Fixed cable head PG 13.5",
    label: "EV015094: Fixed cable head PG 13.5"
  },
  {
    valueId: "EV015096",
    valueDesc: "PTFE ring",
    label: "EV015096: PTFE ring"
  },
  {
    valueId: "EV015097",
    valueDesc: "Zirconium dioxide",
    label: "EV015097: Zirconium dioxide"
  },
  {
    valueId: "EV015098",
    valueDesc: "Annular gap",
    label: "EV015098: Annular gap"
  },
  {
    valueId: "EV015099",
    valueDesc: "Conductive",
    label: "EV015099: Conductive"
  },
  {
    valueId: "EV015100",
    valueDesc: "0.01",
    label: "EV015100: 0.01"
  },
  {
    valueId: "EV015101",
    valueDesc: "0.1",
    label: "EV015101: 0.1"
  },
  {
    valueId: "EV015103",
    valueDesc: "Stainless steel 316 L (1.4435)",
    label: "EV015103: Stainless steel 316 L (1.4435)"
  },
  {
    valueId: "EV015106",
    valueDesc: "G 2 inch",
    label: "EV015106: G 2 inch"
  },
  {
    valueId: "EV015107",
    valueDesc: "Screw connection PG 13.5",
    label: "EV015107: Screw connection PG 13.5"
  },
  {
    valueId: "EV015109",
    valueDesc: "°C",
    label: "EV015109: °C"
  },
  {
    valueId: "EV015110",
    valueDesc: "Flat round",
    label: "EV015110: Flat round"
  },
  {
    valueId: "EV015111",
    valueDesc: "Auxiliary transformer protection",
    label: "EV015111: Auxiliary transformer protection"
  },
  {
    valueId: "EV015112",
    valueDesc: "Primary side transformer protection",
    label: "EV015112: Primary side transformer protection"
  },
  {
    valueId: "EV015113",
    valueDesc: "Switchgear protection",
    label: "EV015113: Switchgear protection"
  },
  {
    valueId: "EV015114",
    valueDesc: "Potential equalisation terminal",
    label: "EV015114: Potential equalisation terminal"
  },
  {
    valueId: "EV015115",
    valueDesc: "Disconnector",
    label: "EV015115: Disconnector"
  },
  {
    valueId: "EV015117",
    valueDesc: "Earthing cable lug",
    label: "EV015117: Earthing cable lug"
  },
  {
    valueId: "EV015118",
    valueDesc: "G24d",
    label: "EV015118: G24d"
  },
  {
    valueId: "EV015119",
    valueDesc: "G24q",
    label: "EV015119: G24q"
  },
  {
    valueId: "EV015121",
    valueDesc: "Baby changing",
    label: "EV015121: Baby changing"
  },
  {
    valueId: "EV015122",
    valueDesc: "Light signal",
    label: "EV015122: Light signal"
  },
  {
    valueId: "EV015125",
    valueDesc: "Bend type LB (North America)",
    label: "EV015125: Bend type LB (North America)"
  },
  {
    valueId: "EV015126",
    valueDesc: "Bend type LL (North America)",
    label: "EV015126: Bend type LL (North America)"
  },
  {
    valueId: "EV015127",
    valueDesc: "Bend type LR (North America)",
    label: "EV015127: Bend type LR (North America)"
  },
  {
    valueId: "EV015128",
    valueDesc: "Tile",
    label: "EV015128: Tile"
  },
  {
    valueId: "EV015129",
    valueDesc: "Included",
    label: "EV015129: Included"
  },
  {
    valueId: "EV015130",
    valueDesc: "Floor stand",
    label: "EV015130: Floor stand"
  },
  {
    valueId: "EV015133",
    valueDesc: "Thuja",
    label: "EV015133: Thuja"
  },
  {
    valueId: "EV015134",
    valueDesc: "Resin floor adapter ring",
    label: "EV015134: Resin floor adapter ring"
  },
  {
    valueId: "EV015135",
    valueDesc: "Inlet fitting",
    label: "EV015135: Inlet fitting"
  },
  {
    valueId: "EV015136",
    valueDesc: "Sand trap/sediment trap",
    label: "EV015136: Sand trap/sediment trap"
  },
  {
    valueId: "EV015138",
    valueDesc: "Water trap",
    label: "EV015138: Water trap"
  },
  {
    valueId: "EV015140",
    valueDesc: "Drain cleaning plug",
    label: "EV015140: Drain cleaning plug"
  },
  {
    valueId: "EV015142",
    valueDesc: "Cutting template for waterproof layer",
    label: "EV015142: Cutting template for waterproof layer"
  },
  {
    valueId: "EV015143",
    valueDesc: "EN 10025-2",
    label: "EV015143: EN 10025-2"
  },
  {
    valueId: "EV015144",
    valueDesc: "EN 10025-3",
    label: "EV015144: EN 10025-3"
  },
  {
    valueId: "EV015145",
    valueDesc: "S235J0 (1.0114)",
    label: "EV015145: S235J0 (1.0114)"
  },
  {
    valueId: "EV015146",
    valueDesc: "S235J2 (1.0117)",
    label: "EV015146: S235J2 (1.0117)"
  },
  {
    valueId: "EV015147",
    valueDesc: "S275J0 (1.0143)",
    label: "EV015147: S275J0 (1.0143)"
  },
  {
    valueId: "EV015148",
    valueDesc: "S355J0 (1.0553)",
    label: "EV015148: S355J0 (1.0553)"
  },
  {
    valueId: "EV015149",
    valueDesc: "S355K2 (1.0596)",
    label: "EV015149: S355K2 (1.0596)"
  },
  {
    valueId: "EV015150",
    valueDesc: "S450J0 (1.0590)",
    label: "EV015150: S450J0 (1.0590)"
  },
  {
    valueId: "EV015151",
    valueDesc: "S275N (1.0490)",
    label: "EV015151: S275N (1.0490)"
  },
  {
    valueId: "EV015152",
    valueDesc: "S275NL (1.0491)",
    label: "EV015152: S275NL (1.0491)"
  },
  {
    valueId: "EV015153",
    valueDesc: "S355N (1.0545)",
    label: "EV015153: S355N (1.0545)"
  },
  {
    valueId: "EV015154",
    valueDesc: "S355NL (1.0546)",
    label: "EV015154: S355NL (1.0546)"
  },
  {
    valueId: "EV015155",
    valueDesc: "S420N (1.8902)",
    label: "EV015155: S420N (1.8902)"
  },
  {
    valueId: "EV015156",
    valueDesc: "S420NL (1.8912)",
    label: "EV015156: S420NL (1.8912)"
  },
  {
    valueId: "EV015157",
    valueDesc: "S460N (1.8901)",
    label: "EV015157: S460N (1.8901)"
  },
  {
    valueId: "EV015158",
    valueDesc: "S460NL (1.8903)",
    label: "EV015158: S460NL (1.8903)"
  },
  {
    valueId: "EV015159",
    valueDesc: "2.1",
    label: "EV015159: 2.1"
  },
  {
    valueId: "EV015160",
    valueDesc: "2.2",
    label: "EV015160: 2.2"
  },
  {
    valueId: "EV015161",
    valueDesc: "3.1",
    label: "EV015161: 3.1"
  },
  {
    valueId: "EV015162",
    valueDesc: "3.2",
    label: "EV015162: 3.2"
  },
  {
    valueId: "EV015170",
    valueDesc: "With 45° eyelet",
    label: "EV015170: With 45° eyelet"
  },
  {
    valueId: "EV015171",
    valueDesc: "With cladding hook",
    label: "EV015171: With cladding hook"
  },
  {
    valueId: "EV015172",
    valueDesc: "With clip for modular ceiling",
    label: "EV015172: With clip for modular ceiling"
  },
  {
    valueId: "EV015173",
    valueDesc: "With decorative end",
    label: "EV015173: With decorative end"
  },
  {
    valueId: "EV015174",
    valueDesc: "With magnetic end",
    label: "EV015174: With magnetic end"
  },
  {
    valueId: "EV015175",
    valueDesc: "With horizontal cable clip",
    label: "EV015175: With horizontal cable clip"
  },
  {
    valueId: "EV015176",
    valueDesc: "With powder nailer end",
    label: "EV015176: With powder nailer end"
  },
  {
    valueId: "EV015177",
    valueDesc: "With gas nailer end",
    label: "EV015177: With gas nailer end"
  },
  {
    valueId: "EV015178",
    valueDesc: "With channel nut end",
    label: "EV015178: With channel nut end"
  },
  {
    valueId: "EV015179",
    valueDesc: "With cable tray hook",
    label: "EV015179: With cable tray hook"
  },
  {
    valueId: "EV015180",
    valueDesc: "Tapped end",
    label: "EV015180: Tapped end"
  },
  {
    valueId: "EV015181",
    valueDesc: "Clip for signpost",
    label: "EV015181: Clip for signpost"
  },
  {
    valueId: "EV015182",
    valueDesc: "Quarter-turn",
    label: "EV015182: Quarter-turn"
  },
  {
    valueId: "EV015183",
    valueDesc: "Channel nut",
    label: "EV015183: Channel nut"
  },
  {
    valueId: "EV015184",
    valueDesc: "Clip for rectangular ventilation duct",
    label: "EV015184: Clip for rectangular ventilation duct"
  },
  {
    valueId: "EV015185",
    valueDesc: "Clip for plenum",
    label: "EV015185: Clip for plenum"
  },
  {
    valueId: "EV015186",
    valueDesc: ">2",
    label: "EV015186: >2"
  },
  {
    valueId: "EV015187",
    valueDesc: "Chequered",
    label: "EV015187: Chequered"
  },
  {
    valueId: "EV015189",
    valueDesc: "Flowers",
    label: "EV015189: Flowers"
  },
  {
    valueId: "EV015190",
    valueDesc: "Hearts",
    label: "EV015190: Hearts"
  },
  {
    valueId: "EV015191",
    valueDesc: "Spheres",
    label: "EV015191: Spheres"
  },
  {
    valueId: "EV015192",
    valueDesc: "Low (30-50 cm)",
    label: "EV015192: Low (30-50 cm)"
  },
  {
    valueId: "EV015193",
    valueDesc: "Plinth (<30 cm)",
    label: "EV015193: Plinth (<30 cm)"
  },
  {
    valueId: "EV015194",
    valueDesc: "Square straight",
    label: "EV015194: Square straight"
  },
  {
    valueId: "EV015195",
    valueDesc: "Square conical",
    label: "EV015195: Square conical"
  },
  {
    valueId: "EV015196",
    valueDesc: "Round straight",
    label: "EV015196: Round straight"
  },
  {
    valueId: "EV015197",
    valueDesc: "Round conical",
    label: "EV015197: Round conical"
  },
  {
    valueId: "EV015198",
    valueDesc: "Rectangular straight",
    label: "EV015198: Rectangular straight"
  },
  {
    valueId: "EV015199",
    valueDesc: "Rectangular conical",
    label: "EV015199: Rectangular conical"
  },
  {
    valueId: "EV015200",
    valueDesc: "Oval straight",
    label: "EV015200: Oval straight"
  },
  {
    valueId: "EV015201",
    valueDesc: "Oval conical",
    label: "EV015201: Oval conical"
  },
  {
    valueId: "EV015202",
    valueDesc: "Full",
    label: "EV015202: Full"
  },
  {
    valueId: "EV015203",
    valueDesc: "Clog",
    label: "EV015203: Clog"
  },
  {
    valueId: "EV015204",
    valueDesc: "EN 10210-1/2",
    label: "EV015204: EN 10210-1/2"
  },
  {
    valueId: "EV015206",
    valueDesc: "S235JRH",
    label: "EV015206: S235JRH"
  },
  {
    valueId: "EV015207",
    valueDesc: "S275J0H",
    label: "EV015207: S275J0H"
  },
  {
    valueId: "EV015208",
    valueDesc: "S275J2H",
    label: "EV015208: S275J2H"
  },
  {
    valueId: "EV015209",
    valueDesc: "S275NH",
    label: "EV015209: S275NH"
  },
  {
    valueId: "EV015210",
    valueDesc: "S275NLH",
    label: "EV015210: S275NLH"
  },
  {
    valueId: "EV015211",
    valueDesc: "S355J0H",
    label: "EV015211: S355J0H"
  },
  {
    valueId: "EV015212",
    valueDesc: "S355J2H",
    label: "EV015212: S355J2H"
  },
  {
    valueId: "EV015213",
    valueDesc: "S355NH",
    label: "EV015213: S355NH"
  },
  {
    valueId: "EV015214",
    valueDesc: "S355NLH",
    label: "EV015214: S355NLH"
  },
  {
    valueId: "EV015215",
    valueDesc: "S460NH",
    label: "EV015215: S460NH"
  },
  {
    valueId: "EV015216",
    valueDesc: "S460NLH",
    label: "EV015216: S460NLH"
  },
  {
    valueId: "EV015217",
    valueDesc: "EN 10025-4",
    label: "EV015217: EN 10025-4"
  },
  {
    valueId: "EV015218",
    valueDesc: "EN 10025-5",
    label: "EV015218: EN 10025-5"
  },
  {
    valueId: "EV015219",
    valueDesc: "EN 10025-6",
    label: "EV015219: EN 10025-6"
  },
  {
    valueId: "EV015220",
    valueDesc: "S275M",
    label: "EV015220: S275M"
  },
  {
    valueId: "EV015221",
    valueDesc: "S275ML",
    label: "EV015221: S275ML"
  },
  {
    valueId: "EV015222",
    valueDesc: "S355M",
    label: "EV015222: S355M"
  },
  {
    valueId: "EV015223",
    valueDesc: "S355ML",
    label: "EV015223: S355ML"
  },
  {
    valueId: "EV015224",
    valueDesc: "S420M",
    label: "EV015224: S420M"
  },
  {
    valueId: "EV015225",
    valueDesc: "S420ML",
    label: "EV015225: S420ML"
  },
  {
    valueId: "EV015226",
    valueDesc: "S460M",
    label: "EV015226: S460M"
  },
  {
    valueId: "EV015227",
    valueDesc: "S460ML",
    label: "EV015227: S460ML"
  },
  {
    valueId: "EV015228",
    valueDesc: "S235J0W",
    label: "EV015228: S235J0W"
  },
  {
    valueId: "EV015230",
    valueDesc: "S355J0W",
    label: "EV015230: S355J0W"
  },
  {
    valueId: "EV015231",
    valueDesc: "S235J2W",
    label: "EV015231: S235J2W"
  },
  {
    valueId: "EV015232",
    valueDesc: "S355J0WP",
    label: "EV015232: S355J0WP"
  },
  {
    valueId: "EV015233",
    valueDesc: "S355J2WP",
    label: "EV015233: S355J2WP"
  },
  {
    valueId: "EV015234",
    valueDesc: "S355J2W",
    label: "EV015234: S355J2W"
  },
  {
    valueId: "EV015235",
    valueDesc: "S355K2W",
    label: "EV015235: S355K2W"
  },
  {
    valueId: "EV015236",
    valueDesc: "S460Q",
    label: "EV015236: S460Q"
  },
  {
    valueId: "EV015237",
    valueDesc: "S460QL",
    label: "EV015237: S460QL"
  },
  {
    valueId: "EV015238",
    valueDesc: "S460QL1",
    label: "EV015238: S460QL1"
  },
  {
    valueId: "EV015239",
    valueDesc: "S500Q",
    label: "EV015239: S500Q"
  },
  {
    valueId: "EV015240",
    valueDesc: "S500QL",
    label: "EV015240: S500QL"
  },
  {
    valueId: "EV015241",
    valueDesc: "S500QL1",
    label: "EV015241: S500QL1"
  },
  {
    valueId: "EV015242",
    valueDesc: "S550Q",
    label: "EV015242: S550Q"
  },
  {
    valueId: "EV015243",
    valueDesc: "S550QL",
    label: "EV015243: S550QL"
  },
  {
    valueId: "EV015244",
    valueDesc: "S550QL1",
    label: "EV015244: S550QL1"
  },
  {
    valueId: "EV015245",
    valueDesc: "S620Q",
    label: "EV015245: S620Q"
  },
  {
    valueId: "EV015246",
    valueDesc: "S620QL",
    label: "EV015246: S620QL"
  },
  {
    valueId: "EV015247",
    valueDesc: "S620QL1",
    label: "EV015247: S620QL1"
  },
  {
    valueId: "EV015248",
    valueDesc: "S690Q",
    label: "EV015248: S690Q"
  },
  {
    valueId: "EV015249",
    valueDesc: "S690QL",
    label: "EV015249: S690QL"
  },
  {
    valueId: "EV015250",
    valueDesc: "S690QL1",
    label: "EV015250: S690QL1"
  },
  {
    valueId: "EV015251",
    valueDesc: "S890Q",
    label: "EV015251: S890Q"
  },
  {
    valueId: "EV015252",
    valueDesc: "S890QL",
    label: "EV015252: S890QL"
  },
  {
    valueId: "EV015253",
    valueDesc: "S890QL1",
    label: "EV015253: S890QL1"
  },
  {
    valueId: "EV015254",
    valueDesc: "S960Q",
    label: "EV015254: S960Q"
  },
  {
    valueId: "EV015255",
    valueDesc: "S960QL",
    label: "EV015255: S960QL"
  },
  {
    valueId: "EV015257",
    valueDesc: "Torx TTAP",
    label: "EV015257: Torx TTAP"
  },
  {
    valueId: "EV015258",
    valueDesc: "Wood fibre",
    label: "EV015258: Wood fibre"
  },
  {
    valueId: "EV015259",
    valueDesc: "Paper wool",
    label: "EV015259: Paper wool"
  },
  {
    valueId: "EV015260",
    valueDesc: "Perlite",
    label: "EV015260: Perlite"
  },
  {
    valueId: "EV015261",
    valueDesc: "T(1)",
    label: "EV015261: T(1)"
  },
  {
    valueId: "EV015262",
    valueDesc: "T(2)",
    label: "EV015262: T(2)"
  },
  {
    valueId: "EV015263",
    valueDesc: "Factory made Mineral Wool (MW) according to EN 13162 + A1",
    label: "EV015263: Factory made Mineral Wool (MW) according to EN 13162 + A1"
  },
  {
    valueId: "EV015264",
    valueDesc: "Factory made Expanded Polystyrene (EPS) according to EN 13163:2013 + A2:2016",
    label: "EV015264: Factory made Expanded Polystyrene (EPS) according to EN 13163:2013 + A2:2016"
  },
  {
    valueId: "EV015266",
    valueDesc: "Factory made Extruded Polystyrene Foam (XPS) according to EN 13164 + A1",
    label: "EV015266: Factory made Extruded Polystyrene Foam (XPS) according to EN 13164 + A1"
  },
  {
    valueId: "EV015267",
    valueDesc: "Factory made Rigid Polyurethane Foam (PU) according to EN 13165:2013 + A2:2016",
    label: "EV015267: Factory made Rigid Polyurethane Foam (PU) according to EN 13165:2013 + A2:2016"
  },
  {
    valueId: "EV015269",
    valueDesc: "R39",
    label: "EV015269: R39"
  },
  {
    valueId: "EV015270",
    valueDesc: "R45",
    label: "EV015270: R45"
  },
  {
    valueId: "EV015271",
    valueDesc: "R50",
    label: "EV015271: R50"
  },
  {
    valueId: "EV015272",
    valueDesc: "R63",
    label: "EV015272: R63"
  },
  {
    valueId: "EV015273",
    valueDesc: "R65",
    label: "EV015273: R65"
  },
  {
    valueId: "EV015274",
    valueDesc: "R80",
    label: "EV015274: R80"
  },
  {
    valueId: "EV015275",
    valueDesc: "R90",
    label: "EV015275: R90"
  },
  {
    valueId: "EV015276",
    valueDesc: "R95",
    label: "EV015276: R95"
  },
  {
    valueId: "EV015277",
    valueDesc: "Access module for mailman",
    label: "EV015277: Access module for mailman"
  },
  {
    valueId: "EV015278",
    valueDesc: "Demonstration case",
    label: "EV015278: Demonstration case"
  },
  {
    valueId: "EV015279",
    valueDesc: "To profile",
    label: "EV015279: To profile"
  },
  {
    valueId: "EV015280",
    valueDesc: "To panel",
    label: "EV015280: To panel"
  },
  {
    valueId: "EV015281",
    valueDesc: "To substructure",
    label: "EV015281: To substructure"
  },
  {
    valueId: "EV015282",
    valueDesc: "Alignment set",
    label: "EV015282: Alignment set"
  },
  {
    valueId: "EV015283",
    valueDesc: "Alignment spanner",
    label: "EV015283: Alignment spanner"
  },
  {
    valueId: "EV015284",
    valueDesc: "Ballast carrier",
    label: "EV015284: Ballast carrier"
  },
  {
    valueId: "EV015285",
    valueDesc: "Tile clamp",
    label: "EV015285: Tile clamp"
  },
  {
    valueId: "EV015286",
    valueDesc: "Free standing model (monobloc)",
    label: "EV015286: Free standing model (monobloc)"
  },
  {
    valueId: "EV015289",
    valueDesc: "Aerogel",
    label: "EV015289: Aerogel"
  },
  {
    valueId: "EV015290",
    valueDesc: "OM5",
    label: "EV015290: OM5"
  },
  {
    valueId: "EV015291",
    valueDesc: "Singlemode and Multimode 50/125",
    label: "EV015291: Singlemode and Multimode 50/125"
  },
  {
    valueId: "EV015292",
    valueDesc: "DCL",
    label: "EV015292: DCL"
  },
  {
    valueId: "EV015293",
    valueDesc: "0.2",
    label: "EV015293: 0.2"
  },
  {
    valueId: "EV015294",
    valueDesc: "0.2S",
    label: "EV015294: 0.2S"
  },
  {
    valueId: "EV015295",
    valueDesc: "0.5",
    label: "EV015295: 0.5"
  },
  {
    valueId: "EV015296",
    valueDesc: "0.5S",
    label: "EV015296: 0.5S"
  },
  {
    valueId: "EV015298",
    valueDesc: "EVA/PVC",
    label: "EV015298: EVA/PVC"
  },
  {
    valueId: "EV015299",
    valueDesc: "FPO/TPO",
    label: "EV015299: FPO/TPO"
  },
  {
    valueId: "EV015300",
    valueDesc: "Slate premiums/granules",
    label: "EV015300: Slate premiums/granules"
  },
  {
    valueId: "EV015301",
    valueDesc: "Characterized",
    label: "EV015301: Characterized"
  },
  {
    valueId: "EV015302",
    valueDesc: "NOX reducing surface",
    label: "EV015302: NOX reducing surface"
  },
  {
    valueId: "EV015303",
    valueDesc: "BROOF (t1)",
    label: "EV015303: BROOF (t1)"
  },
  {
    valueId: "EV015304",
    valueDesc: "BROOF (t2)",
    label: "EV015304: BROOF (t2)"
  },
  {
    valueId: "EV015305",
    valueDesc: "BROOF (t3)",
    label: "EV015305: BROOF (t3)"
  },
  {
    valueId: "EV015306",
    valueDesc: "BROOF (t4)",
    label: "EV015306: BROOF (t4)"
  },
  {
    valueId: "EV015307",
    valueDesc: "Welding with hot air",
    label: "EV015307: Welding with hot air"
  },
  {
    valueId: "EV015309",
    valueDesc: "Self-adhesive joint",
    label: "EV015309: Self-adhesive joint"
  },
  {
    valueId: "EV015310",
    valueDesc: "Two-point/three-point",
    label: "EV015310: Two-point/three-point"
  },
  {
    valueId: "EV015311",
    valueDesc: "Sliding field",
    label: "EV015311: Sliding field"
  },
  {
    valueId: "EV015312",
    valueDesc: "SMB male",
    label: "EV015312: SMB male"
  },
  {
    valueId: "EV015313",
    valueDesc: "SMB female",
    label: "EV015313: SMB female"
  },
  {
    valueId: "EV015314",
    valueDesc: "Smooth edge",
    label: "EV015314: Smooth edge"
  },
  {
    valueId: "EV015316",
    valueDesc: "Distemper (lime paint)",
    label: "EV015316: Distemper (lime paint)"
  },
  {
    valueId: "EV015317",
    valueDesc: "R32",
    label: "EV015317: R32"
  },
  {
    valueId: "EV015318",
    valueDesc: "Clamp bush/end bush/axle bush",
    label: "EV015318: Clamp bush/end bush/axle bush"
  },
  {
    valueId: "EV015319",
    valueDesc: "Cement additive",
    label: "EV015319: Cement additive"
  },
  {
    valueId: "EV015321",
    valueDesc: "Green/white",
    label: "EV015321: Green/white"
  },
  {
    valueId: "EV015322",
    valueDesc: "Nickel silver",
    label: "EV015322: Nickel silver"
  },
  {
    valueId: "EV015323",
    valueDesc: "Wrought iron",
    label: "EV015323: Wrought iron"
  },
  {
    valueId: "EV015324",
    valueDesc: "Type A - Direct release",
    label: "EV015324: Type A - Direct release"
  },
  {
    valueId: "EV015325",
    valueDesc: "Type B - Indirect release",
    label: "EV015325: Type B - Indirect release"
  },
  {
    valueId: "EV015328",
    valueDesc: "Combination MIFARE - Prox/EM",
    label: "EV015328: Combination MIFARE - Prox/EM"
  },
  {
    valueId: "EV015329",
    valueDesc: "Mounted on sliding rod",
    label: "EV015329: Mounted on sliding rod"
  },
  {
    valueId: "EV015330",
    valueDesc: "Grooving",
    label: "EV015330: Grooving"
  },
  {
    valueId: "EV015333",
    valueDesc: "Without hole",
    label: "EV015333: Without hole"
  },
  {
    valueId: "EV015335",
    valueDesc: "Latch",
    label: "EV015335: Latch"
  },
  {
    valueId: "EV015337",
    valueDesc: "Roller pin",
    label: "EV015337: Roller pin"
  },
  {
    valueId: "EV015338",
    valueDesc: "Hook latch",
    label: "EV015338: Hook latch"
  },
  {
    valueId: "EV015339",
    valueDesc: "Compass bolt",
    label: "EV015339: Compass bolt"
  },
  {
    valueId: "EV015340",
    valueDesc: "Bolt locking bar",
    label: "EV015340: Bolt locking bar"
  },
  {
    valueId: "EV015341",
    valueDesc: "Latchbolt",
    label: "EV015341: Latchbolt"
  },
  {
    valueId: "EV015342",
    valueDesc: "Hook bolt",
    label: "EV015342: Hook bolt"
  },
  {
    valueId: "EV015344",
    valueDesc: "Pivot hook deadbolt",
    label: "EV015344: Pivot hook deadbolt"
  },
  {
    valueId: "EV015345",
    valueDesc: "Round bolt",
    label: "EV015345: Round bolt"
  },
  {
    valueId: "EV015346",
    valueDesc: "Duo round bolt",
    label: "EV015346: Duo round bolt"
  },
  {
    valueId: "EV015348",
    valueDesc: "Massive deadbolt",
    label: "EV015348: Massive deadbolt"
  },
  {
    valueId: "EV015349",
    valueDesc: "Massive deadbolt and latchbolt",
    label: "EV015349: Massive deadbolt and latchbolt"
  },
  {
    valueId: "EV015350",
    valueDesc: "Massive deadbolt and latchpin",
    label: "EV015350: Massive deadbolt and latchpin"
  },
  {
    valueId: "EV015351",
    valueDesc: "Pivot hook",
    label: "EV015351: Pivot hook"
  },
  {
    valueId: "EV015352",
    valueDesc: "Pivot hook and round bolt",
    label: "EV015352: Pivot hook and round bolt"
  },
  {
    valueId: "EV015353",
    valueDesc: "Pivot hook and duo round bolt",
    label: "EV015353: Pivot hook and duo round bolt"
  },
  {
    valueId: "EV015354",
    valueDesc: "Pivot hook and massive deadbolt",
    label: "EV015354: Pivot hook and massive deadbolt"
  },
  {
    valueId: "EV015355",
    valueDesc: "Pivot deadbolt",
    label: "EV015355: Pivot deadbolt"
  },
  {
    valueId: "EV015356",
    valueDesc: "Pivot deadbolt and round bolt",
    label: "EV015356: Pivot deadbolt and round bolt"
  },
  {
    valueId: "EV015357",
    valueDesc: "Pivot deadbolt and duo round bolt",
    label: "EV015357: Pivot deadbolt and duo round bolt"
  },
  {
    valueId: "EV015358",
    valueDesc: "Flat forend angular",
    label: "EV015358: Flat forend angular"
  },
  {
    valueId: "EV015359",
    valueDesc: "Flat forend rounded",
    label: "EV015359: Flat forend rounded"
  },
  {
    valueId: "EV015360",
    valueDesc: "U forend",
    label: "EV015360: U forend"
  },
  {
    valueId: "EV015361",
    valueDesc: "Angle forend",
    label: "EV015361: Angle forend"
  },
  {
    valueId: "EV015364",
    valueDesc: "Flanged strike plate",
    label: "EV015364: Flanged strike plate"
  },
  {
    valueId: "EV015370",
    valueDesc: "Half open",
    label: "EV015370: Half open"
  },
  {
    valueId: "EV015371",
    valueDesc: "Hinge side",
    label: "EV015371: Hinge side"
  },
  {
    valueId: "EV015372",
    valueDesc: "Opposite hinge side",
    label: "EV015372: Opposite hinge side"
  },
  {
    valueId: "EV015373",
    valueDesc: "Hinge side and opposite hinge side",
    label: "EV015373: Hinge side and opposite hinge side"
  },
  {
    valueId: "EV015377",
    valueDesc: "Flat conical",
    label: "EV015377: Flat conical"
  },
  {
    valueId: "EV015385",
    valueDesc: "Screw-on",
    label: "EV015385: Screw-on"
  },
  {
    valueId: "EV015386",
    valueDesc: "For screw in",
    label: "EV015386: For screw in"
  },
  {
    valueId: "EV015387",
    valueDesc: "For weld-in",
    label: "EV015387: For weld-in"
  },
  {
    valueId: "EV015396",
    valueDesc: "1 sealing lip",
    label: "EV015396: 1 sealing lip"
  },
  {
    valueId: "EV015397",
    valueDesc: "2 sealing lips",
    label: "EV015397: 2 sealing lips"
  },
  {
    valueId: "EV015398",
    valueDesc: "Hose profile",
    label: "EV015398: Hose profile"
  },
  {
    valueId: "EV015401",
    valueDesc: "9 mm",
    label: "EV015401: 9 mm"
  },
  {
    valueId: "EV015406",
    valueDesc: "Square backplate",
    label: "EV015406: Square backplate"
  },
  {
    valueId: "EV015407",
    valueDesc: "Door handle escutcheon",
    label: "EV015407: Door handle escutcheon"
  },
  {
    valueId: "EV015408",
    valueDesc: "Cylinder escutcheon",
    label: "EV015408: Cylinder escutcheon"
  },
  {
    valueId: "EV015409",
    valueDesc: "Opposite escutcheon",
    label: "EV015409: Opposite escutcheon"
  },
  {
    valueId: "EV015410",
    valueDesc: "Warded lock",
    label: "EV015410: Warded lock"
  },
  {
    valueId: "EV015411",
    valueDesc: "Profile double cylinder",
    label: "EV015411: Profile double cylinder"
  },
  {
    valueId: "EV015412",
    valueDesc: "WC/Bath 7 mm",
    label: "EV015412: WC/Bath 7 mm"
  },
  {
    valueId: "EV015413",
    valueDesc: "WC/Bath 8 mm",
    label: "EV015413: WC/Bath 8 mm"
  },
  {
    valueId: "EV015414",
    valueDesc: "WC/bath 9 mm",
    label: "EV015414: WC/bath 9 mm"
  },
  {
    valueId: "EV015415",
    valueDesc: "Round cylinder 43 mm",
    label: "EV015415: Round cylinder 43 mm"
  },
  {
    valueId: "EV015416",
    valueDesc: "Round cylinder 22 mm",
    label: "EV015416: Round cylinder 22 mm"
  },
  {
    valueId: "EV015417",
    valueDesc: "Chubb",
    label: "EV015417: Chubb"
  },
  {
    valueId: "EV015420",
    valueDesc: "M23 x 1.0",
    label: "EV015420: M23 x 1.0"
  },
  {
    valueId: "EV015421",
    valueDesc: "M29 x 1.0",
    label: "EV015421: M29 x 1.0"
  },
  {
    valueId: "EV015422",
    valueDesc: "Invisible",
    label: "EV015422: Invisible"
  },
  {
    valueId: "EV015423",
    valueDesc: "Door handle two-sided",
    label: "EV015423: Door handle two-sided"
  },
  {
    valueId: "EV015425",
    valueDesc: "Alternate set",
    label: "EV015425: Alternate set"
  },
  {
    valueId: "EV015437",
    valueDesc: "Door handle",
    label: "EV015437: Door handle"
  },
  {
    valueId: "EV015458",
    valueDesc: "Door handle actuation",
    label: "EV015458: Door handle actuation"
  },
  {
    valueId: "EV015459",
    valueDesc: "Single-throw",
    label: "EV015459: Single-throw"
  },
  {
    valueId: "EV015460",
    valueDesc: "Double-throw",
    label: "EV015460: Double-throw"
  },
  {
    valueId: "EV015462",
    valueDesc: "3 mm protruding",
    label: "EV015462: 3 mm protruding"
  },
  {
    valueId: "EV015463",
    valueDesc: "5 mm protruding",
    label: "EV015463: 5 mm protruding"
  },
  {
    valueId: "EV015464",
    valueDesc: "Without deadbolt",
    label: "EV015464: Without deadbolt"
  },
  {
    valueId: "EV015465",
    valueDesc: "Standard deadbolt",
    label: "EV015465: Standard deadbolt"
  },
  {
    valueId: "EV015466",
    valueDesc: "Die-cast",
    label: "EV015466: Die-cast"
  },
  {
    valueId: "EV015467",
    valueDesc: "Main lock",
    label: "EV015467: Main lock"
  },
  {
    valueId: "EV015468",
    valueDesc: "Main lock with top lock",
    label: "EV015468: Main lock with top lock"
  },
  {
    valueId: "EV015469",
    valueDesc: "Multi-point locking",
    label: "EV015469: Multi-point locking"
  },
  {
    valueId: "EV015470",
    valueDesc: "Without latch",
    label: "EV015470: Without latch"
  },
  {
    valueId: "EV015471",
    valueDesc: "Standard latch",
    label: "EV015471: Standard latch"
  },
  {
    valueId: "EV015472",
    valueDesc: "Silent latch",
    label: "EV015472: Silent latch"
  },
  {
    valueId: "EV015473",
    valueDesc: "Roller latch",
    label: "EV015473: Roller latch"
  },
  {
    valueId: "EV015474",
    valueDesc: "Crank latch",
    label: "EV015474: Crank latch"
  },
  {
    valueId: "EV015475",
    valueDesc: "Cross latch",
    label: "EV015475: Cross latch"
  },
  {
    valueId: "EV015476",
    valueDesc: "Control latch",
    label: "EV015476: Control latch"
  },
  {
    valueId: "EV015477",
    valueDesc: "Blocking latch",
    label: "EV015477: Blocking latch"
  },
  {
    valueId: "EV015478",
    valueDesc: "Bond latch",
    label: "EV015478: Bond latch"
  },
  {
    valueId: "EV015481",
    valueDesc: "With latch holdback",
    label: "EV015481: With latch holdback"
  },
  {
    valueId: "EV015482",
    valueDesc: "Key-controlled latch holdback",
    label: "EV015482: Key-controlled latch holdback"
  },
  {
    valueId: "EV015483",
    valueDesc: "Controlled latch holdback",
    label: "EV015483: Controlled latch holdback"
  },
  {
    valueId: "EV015484",
    valueDesc: "Clamping follower",
    label: "EV015484: Clamping follower"
  },
  {
    valueId: "EV015485",
    valueDesc: "Special follower",
    label: "EV015485: Special follower"
  },
  {
    valueId: "EV015486",
    valueDesc: "Ball-bearing follower",
    label: "EV015486: Ball-bearing follower"
  },
  {
    valueId: "EV015487",
    valueDesc: "Self-tensioning follower",
    label: "EV015487: Self-tensioning follower"
  },
  {
    valueId: "EV015488",
    valueDesc: "Split follower",
    label: "EV015488: Split follower"
  },
  {
    valueId: "EV015489",
    valueDesc: "Angled forend angular",
    label: "EV015489: Angled forend angular"
  },
  {
    valueId: "EV015490",
    valueDesc: "Angled forend rounded",
    label: "EV015490: Angled forend rounded"
  },
  {
    valueId: "EV015492",
    valueDesc: "Resistance class WK 1",
    label: "EV015492: Resistance class WK 1"
  },
  {
    valueId: "EV015493",
    valueDesc: "Resistance class WK 2",
    label: "EV015493: Resistance class WK 2"
  },
  {
    valueId: "EV015494",
    valueDesc: "Resistance class WK 3",
    label: "EV015494: Resistance class WK 3"
  },
  {
    valueId: "EV015495",
    valueDesc: "Resistance class WK 4",
    label: "EV015495: Resistance class WK 4"
  },
  {
    valueId: "EV015496",
    valueDesc: "Resistance class WK 5",
    label: "EV015496: Resistance class WK 5"
  },
  {
    valueId: "EV015497",
    valueDesc: "Resistance class WK 6",
    label: "EV015497: Resistance class WK 6"
  },
  {
    valueId: "EV015498",
    valueDesc: "Resistance class RC 1 N",
    label: "EV015498: Resistance class RC 1 N"
  },
  {
    valueId: "EV015499",
    valueDesc: "Resistance class RC 2 N",
    label: "EV015499: Resistance class RC 2 N"
  },
  {
    valueId: "EV015500",
    valueDesc: "Resistance class RC 3",
    label: "EV015500: Resistance class RC 3"
  },
  {
    valueId: "EV015501",
    valueDesc: "Resistance class RC 4",
    label: "EV015501: Resistance class RC 4"
  },
  {
    valueId: "EV015502",
    valueDesc: "Resistance class RC 5",
    label: "EV015502: Resistance class RC 5"
  },
  {
    valueId: "EV015503",
    valueDesc: "Resistance class RC 6",
    label: "EV015503: Resistance class RC 6"
  },
  {
    valueId: "EV015504",
    valueDesc: "Class A",
    label: "EV015504: Class A"
  },
  {
    valueId: "EV015505",
    valueDesc: "Stainless steel 416 (1.4005)",
    label: "EV015505: Stainless steel 416 (1.4005)"
  },
  {
    valueId: "EV015507",
    valueDesc: "Mushroom pin",
    label: "EV015507: Mushroom pin"
  },
  {
    valueId: "EV015520",
    valueDesc: "Inlet",
    label: "EV015520: Inlet"
  },
  {
    valueId: "EV015526",
    valueDesc: "Hinge forend",
    label: "EV015526: Hinge forend"
  },
  {
    valueId: "EV015530",
    valueDesc: "Keep switching lock",
    label: "EV015530: Keep switching lock"
  },
  {
    valueId: "EV015531",
    valueDesc: "Keep switching lock with top lock",
    label: "EV015531: Keep switching lock with top lock"
  },
  {
    valueId: "EV015532",
    valueDesc: "Keep switching lock with top and low lock",
    label: "EV015532: Keep switching lock with top and low lock"
  },
  {
    valueId: "EV015533",
    valueDesc: "Lever actuation for espagnolette lock",
    label: "EV015533: Lever actuation for espagnolette lock"
  },
  {
    valueId: "EV015536",
    valueDesc: "Drilling in",
    label: "EV015536: Drilling in"
  },
  {
    valueId: "EV015537",
    valueDesc: "Milling in",
    label: "EV015537: Milling in"
  },
  {
    valueId: "EV015538",
    valueDesc: "5-part",
    label: "EV015538: 5-part"
  },
  {
    valueId: "EV015542",
    valueDesc: "Two-dimensional",
    label: "EV015542: Two-dimensional"
  },
  {
    valueId: "EV015543",
    valueDesc: "1 star",
    label: "EV015543: 1 star"
  },
  {
    valueId: "EV015544",
    valueDesc: "2 stars",
    label: "EV015544: 2 stars"
  },
  {
    valueId: "EV015545",
    valueDesc: "3 stars",
    label: "EV015545: 3 stars"
  },
  {
    valueId: "EV015547",
    valueDesc: "Running rail",
    label: "EV015547: Running rail"
  },
  {
    valueId: "EV015549",
    valueDesc: "Dynamic pressure measurement",
    label: "EV015549: Dynamic pressure measurement"
  },
  {
    valueId: "EV015550",
    valueDesc: "Static pressure measurement",
    label: "EV015550: Static pressure measurement"
  },
  {
    valueId: "EV015551",
    valueDesc: "Anemometric",
    label: "EV015551: Anemometric"
  },
  {
    valueId: "EV015553",
    valueDesc: "Syringe",
    label: "EV015553: Syringe"
  },
  {
    valueId: "EV015554",
    valueDesc: "24 - 125 V DC / 24 - 240 V AC",
    label: "EV015554: 24 - 125 V DC / 24 - 240 V AC"
  },
  {
    valueId: "EV015555",
    valueDesc: "NO Normaly open",
    label: "EV015555: NO Normaly open"
  },
  {
    valueId: "EV015556",
    valueDesc: "NC normaly closed",
    label: "EV015556: NC normaly closed"
  },
  {
    valueId: "EV015557",
    valueDesc: "NO/NC (depends on mounting)",
    label: "EV015557: NO/NC (depends on mounting)"
  },
  {
    valueId: "EV015558",
    valueDesc: "Safety position adjustable",
    label: "EV015558: Safety position adjustable"
  },
  {
    valueId: "EV015559",
    valueDesc: "Outer door",
    label: "EV015559: Outer door"
  },
  {
    valueId: "EV015561",
    valueDesc: "Cover angle",
    label: "EV015561: Cover angle"
  },
  {
    valueId: "EV015562",
    valueDesc: "Door leaf mounting",
    label: "EV015562: Door leaf mounting"
  },
  {
    valueId: "EV015563",
    valueDesc: "Door frame mounting",
    label: "EV015563: Door frame mounting"
  },
  {
    valueId: "EV015564",
    valueDesc: "HE",
    label: "EV015564: HE"
  },
  {
    valueId: "EV015565",
    valueDesc: "Fuse holder connector",
    label: "EV015565: Fuse holder connector"
  },
  {
    valueId: "EV015566",
    valueDesc: "Individual main circuit breaker with whip",
    label: "EV015566: Individual main circuit breaker with whip"
  },
  {
    valueId: "EV015567",
    valueDesc: "Joint cone",
    label: "EV015567: Joint cone"
  },
  {
    valueId: "EV015568",
    valueDesc: "Floor blanking plate",
    label: "EV015568: Floor blanking plate"
  },
  {
    valueId: "EV015570",
    valueDesc: "8/9 mm",
    label: "EV015570: 8/9 mm"
  },
  {
    valueId: "EV015571",
    valueDesc: "8/9/8 mm",
    label: "EV015571: 8/9/8 mm"
  },
  {
    valueId: "EV015572",
    valueDesc: "8/10 mm",
    label: "EV015572: 8/10 mm"
  },
  {
    valueId: "EV015573",
    valueDesc: "8/10/8 mm",
    label: "EV015573: 8/10/8 mm"
  },
  {
    valueId: "EV015574",
    valueDesc: "Switch board/external protection 2 directions",
    label: "EV015574: Switch board/external protection 2 directions"
  },
  {
    valueId: "EV015575",
    valueDesc: "Switch board/external protection 3 directions",
    label: "EV015575: Switch board/external protection 3 directions"
  },
  {
    valueId: "EV015576",
    valueDesc: "Switch board/internal protection",
    label: "EV015576: Switch board/internal protection"
  },
  {
    valueId: "EV015577",
    valueDesc: "Glass front 90°",
    label: "EV015577: Glass front 90°"
  },
  {
    valueId: "EV015578",
    valueDesc: "Glass front 135°",
    label: "EV015578: Glass front 135°"
  },
  {
    valueId: "EV015579",
    valueDesc: "Glass front 180°",
    label: "EV015579: Glass front 180°"
  },
  {
    valueId: "EV015580",
    valueDesc: "Sealing to wall",
    label: "EV015580: Sealing to wall"
  },
  {
    valueId: "EV015581",
    valueDesc: "Sealing to floor",
    label: "EV015581: Sealing to floor"
  },
  {
    valueId: "EV015585",
    valueDesc: "1A",
    label: "EV015585: 1A"
  },
  {
    valueId: "EV015586",
    valueDesc: "4A",
    label: "EV015586: 4A"
  },
  {
    valueId: "EV015587",
    valueDesc: "5A",
    label: "EV015587: 5A"
  },
  {
    valueId: "EV015588",
    valueDesc: "6A",
    label: "EV015588: 6A"
  },
  {
    valueId: "EV015589",
    valueDesc: "7A",
    label: "EV015589: 7A"
  },
  {
    valueId: "EV015590",
    valueDesc: "8A",
    label: "EV015590: 8A"
  },
  {
    valueId: "EV015591",
    valueDesc: "9A",
    label: "EV015591: 9A"
  },
  {
    valueId: "EV015592",
    valueDesc: "Exxx",
    label: "EV015592: Exxx"
  },
  {
    valueId: "EV015595",
    valueDesc: "Half-cylinder",
    label: "EV015595: Half-cylinder"
  },
  {
    valueId: "EV015599",
    valueDesc: "Knob cylinder",
    label: "EV015599: Knob cylinder"
  },
  {
    valueId: "EV015605",
    valueDesc: "Cam lock cylinder",
    label: "EV015605: Cam lock cylinder"
  },
  {
    valueId: "EV015611",
    valueDesc: "Double locking nose",
    label: "EV015611: Double locking nose"
  },
  {
    valueId: "EV015612",
    valueDesc: "Four-fold locking nose",
    label: "EV015612: Four-fold locking nose"
  },
  {
    valueId: "EV015613",
    valueDesc: "Pinion",
    label: "EV015613: Pinion"
  },
  {
    valueId: "EV015614",
    valueDesc: "Conventional key",
    label: "EV015614: Conventional key"
  },
  {
    valueId: "EV015615",
    valueDesc: "Reversible key",
    label: "EV015615: Reversible key"
  },
  {
    valueId: "EV015616",
    valueDesc: "Activ transponder technology",
    label: "EV015616: Activ transponder technology"
  },
  {
    valueId: "EV015617",
    valueDesc: "Passiv transponder technology",
    label: "EV015617: Passiv transponder technology"
  },
  {
    valueId: "EV015618",
    valueDesc: "VdS A",
    label: "EV015618: VdS A"
  },
  {
    valueId: "EV015619",
    valueDesc: "VdS B",
    label: "EV015619: VdS B"
  },
  {
    valueId: "EV015620",
    valueDesc: "VdS EMA",
    label: "EV015620: VdS EMA"
  },
  {
    valueId: "EV015621",
    valueDesc: "VdS C",
    label: "EV015621: VdS C"
  },
  {
    valueId: "EV015623",
    valueDesc: "Glass door",
    label: "EV015623: Glass door"
  },
  {
    valueId: "EV015624",
    valueDesc: "Aluminium framed door",
    label: "EV015624: Aluminium framed door"
  },
  {
    valueId: "EV015625",
    valueDesc: "Top-running mounting",
    label: "EV015625: Top-running mounting"
  },
  {
    valueId: "EV015626",
    valueDesc: "Bottom-running mounting",
    label: "EV015626: Bottom-running mounting"
  },
  {
    valueId: "EV015627",
    valueDesc: "Door leaf running inside the cabinet",
    label: "EV015627: Door leaf running inside the cabinet"
  },
  {
    valueId: "EV015628",
    valueDesc: "Door leaf running in front of the cabinet",
    label: "EV015628: Door leaf running in front of the cabinet"
  },
  {
    valueId: "EV015629",
    valueDesc: "Door leaf guided inside and in front of the cabinet",
    label: "EV015629: Door leaf guided inside and in front of the cabinet"
  },
  {
    valueId: "EV015630",
    valueDesc: "Door leaf front-flush",
    label: "EV015630: Door leaf front-flush"
  },
  {
    valueId: "EV015631",
    valueDesc: "With bar",
    label: "EV015631: With bar"
  },
  {
    valueId: "EV015634",
    valueDesc: "Push down of door handle",
    label: "EV015634: Push down of door handle"
  },
  {
    valueId: "EV015635",
    valueDesc: "Push down of rod handle",
    label: "EV015635: Push down of rod handle"
  },
  {
    valueId: "EV015636",
    valueDesc: "Push of pushbar/touchbar",
    label: "EV015636: Push of pushbar/touchbar"
  },
  {
    valueId: "EV015637",
    valueDesc: "Turning to the side of espagnolette bolt",
    label: "EV015637: Turning to the side of espagnolette bolt"
  },
  {
    valueId: "EV015638",
    valueDesc: "Turning to the side of door guard",
    label: "EV015638: Turning to the side of door guard"
  },
  {
    valueId: "EV015639",
    valueDesc: "Funk-reed-contact",
    label: "EV015639: Funk-reed-contact"
  },
  {
    valueId: "EV015640",
    valueDesc: "Profile half-cylinder",
    label: "EV015640: Profile half-cylinder"
  },
  {
    valueId: "EV015641",
    valueDesc: "Prepared for profile half-cylinder",
    label: "EV015641: Prepared for profile half-cylinder"
  },
  {
    valueId: "EV015643",
    valueDesc: "Wall and ceiling",
    label: "EV015643: Wall and ceiling"
  },
  {
    valueId: "EV015645",
    valueDesc: "DisplayPort (DP)",
    label: "EV015645: DisplayPort (DP)"
  },
  {
    valueId: "EV015646",
    valueDesc: "Mini DisplayPort (MiniDP)",
    label: "EV015646: Mini DisplayPort (MiniDP)"
  },
  {
    valueId: "EV015655",
    valueDesc: "Support plate",
    label: "EV015655: Support plate"
  },
  {
    valueId: "EV015656",
    valueDesc: "Edge-fixing hanger",
    label: "EV015656: Edge-fixing hanger"
  },
  {
    valueId: "EV015657",
    valueDesc: "Angled support plate",
    label: "EV015657: Angled support plate"
  },
  {
    valueId: "EV015659",
    valueDesc: "WC/Bath",
    label: "EV015659: WC/Bath"
  },
  {
    valueId: "EV015664",
    valueDesc: "Parallel/telescopic",
    label: "EV015664: Parallel/telescopic"
  },
  {
    valueId: "EV015665",
    valueDesc: "Synchronous/symmetrical",
    label: "EV015665: Synchronous/symmetrical"
  },
  {
    valueId: "EV015666",
    valueDesc: "Parallel synchronous",
    label: "EV015666: Parallel synchronous"
  },
  {
    valueId: "EV015667",
    valueDesc: "-",
    label: "EV015667: -"
  },
  {
    valueId: "EV015668",
    valueDesc: "Deadbolt",
    label: "EV015668: Deadbolt"
  },
  {
    valueId: "EV015669",
    valueDesc: "Latch and deadbolt",
    label: "EV015669: Latch and deadbolt"
  },
  {
    valueId: "EV015670",
    valueDesc: "To set in concrete",
    label: "EV015670: To set in concrete"
  },
  {
    valueId: "EV015677",
    valueDesc: "8/8 mm",
    label: "EV015677: 8/8 mm"
  },
  {
    valueId: "EV015678",
    valueDesc: "10/10 mm",
    label: "EV015678: 10/10 mm"
  },
  {
    valueId: "EV015679",
    valueDesc: "12/12 mm",
    label: "EV015679: 12/12 mm"
  },
  {
    valueId: "EV015680",
    valueDesc: "13/13 mm",
    label: "EV015680: 13/13 mm"
  },
  {
    valueId: "EV015681",
    valueDesc: "14/14 mm",
    label: "EV015681: 14/14 mm"
  },
  {
    valueId: "EV015682",
    valueDesc: "16/16 mm",
    label: "EV015682: 16/16 mm"
  },
  {
    valueId: "EV015683",
    valueDesc: "18/18 mm",
    label: "EV015683: 18/18 mm"
  },
  {
    valueId: "EV015684",
    valueDesc: "25/10 mm",
    label: "EV015684: 25/10 mm"
  },
  {
    valueId: "EV015685",
    valueDesc: "20/10 mm",
    label: "EV015685: 20/10 mm"
  },
  {
    valueId: "EV015686",
    valueDesc: "Console tube",
    label: "EV015686: Console tube"
  },
  {
    valueId: "EV015694",
    valueDesc: "CEE 7/1 unearthed",
    label: "EV015694: CEE 7/1 unearthed"
  },
  {
    valueId: "EV015695",
    valueDesc: "Single discharge blow through device",
    label: "EV015695: Single discharge blow through device"
  },
  {
    valueId: "EV015696",
    valueDesc: "Dual discharge blow through device",
    label: "EV015696: Dual discharge blow through device"
  },
  {
    valueId: "EV015697",
    valueDesc: "Single discharge draw through",
    label: "EV015697: Single discharge draw through"
  },
  {
    valueId: "EV015698",
    valueDesc: "Round plug (CEE 7/2)",
    label: "EV015698: Round plug (CEE 7/2)"
  },
  {
    valueId: "EV015699",
    valueDesc: "Window handle side",
    label: "EV015699: Window handle side"
  },
  {
    valueId: "EV015700",
    valueDesc: "Window hinge side",
    label: "EV015700: Window hinge side"
  },
  {
    valueId: "EV015701",
    valueDesc: "Window handle- and window hinge side",
    label: "EV015701: Window handle- and window hinge side"
  },
  {
    valueId: "EV015702",
    valueDesc: "Window door handle",
    label: "EV015702: Window door handle"
  },
  {
    valueId: "EV015709",
    valueDesc: "Locking automatic",
    label: "EV015709: Locking automatic"
  },
  {
    valueId: "EV015710",
    valueDesc: "Folding bracket",
    label: "EV015710: Folding bracket"
  },
  {
    valueId: "EV015719",
    valueDesc: "Outside escutcheon",
    label: "EV015719: Outside escutcheon"
  },
  {
    valueId: "EV015720",
    valueDesc: "Armor plate",
    label: "EV015720: Armor plate"
  },
  {
    valueId: "EV015721",
    valueDesc: "Lock case connection plate",
    label: "EV015721: Lock case connection plate"
  },
  {
    valueId: "EV015722",
    valueDesc: "Lock body support",
    label: "EV015722: Lock body support"
  },
  {
    valueId: "EV015723",
    valueDesc: "Blocking bracket module",
    label: "EV015723: Blocking bracket module"
  },
  {
    valueId: "EV015724",
    valueDesc: "Wall strike plate",
    label: "EV015724: Wall strike plate"
  },
  {
    valueId: "EV015725",
    valueDesc: "DX (direct expansion)",
    label: "EV015725: DX (direct expansion)"
  },
  {
    valueId: "EV015727",
    valueDesc: "R717 (NH3)",
    label: "EV015727: R717 (NH3)"
  },
  {
    valueId: "EV015728",
    valueDesc: "R744 (CO2)",
    label: "EV015728: R744 (CO2)"
  },
  {
    valueId: "EV015729",
    valueDesc: "Cu/Cu",
    label: "EV015729: Cu/Cu"
  },
  {
    valueId: "EV015730",
    valueDesc: "Cu/Al",
    label: "EV015730: Cu/Al"
  },
  {
    valueId: "EV015731",
    valueDesc: "Cu/Al-Vinyl",
    label: "EV015731: Cu/Al-Vinyl"
  },
  {
    valueId: "EV015732",
    valueDesc: "Hot water",
    label: "EV015732: Hot water"
  },
  {
    valueId: "EV015733",
    valueDesc: "Electrical and hot gas",
    label: "EV015733: Electrical and hot gas"
  },
  {
    valueId: "EV015734",
    valueDesc: "Lead acid and lithium-ions",
    label: "EV015734: Lead acid and lithium-ions"
  },
  {
    valueId: "EV015735",
    valueDesc: "Stainless steel/Aluminium",
    label: "EV015735: Stainless steel/Aluminium"
  },
  {
    valueId: "EV015736",
    valueDesc: "Mortice lock",
    label: "EV015736: Mortice lock"
  },
  {
    valueId: "EV015737",
    valueDesc: "Furniture lock",
    label: "EV015737: Furniture lock"
  },
  {
    valueId: "EV015738",
    valueDesc: "Different locking",
    label: "EV015738: Different locking"
  },
  {
    valueId: "EV015739",
    valueDesc: "Identical locking",
    label: "EV015739: Identical locking"
  },
  {
    valueId: "EV015745",
    valueDesc: "Class 6",
    label: "EV015745: Class 6"
  },
  {
    valueId: "EV015747",
    valueDesc: "Flat material, inwards angled",
    label: "EV015747: Flat material, inwards angled"
  },
  {
    valueId: "EV015748",
    valueDesc: "Flat material, outwards angled",
    label: "EV015748: Flat material, outwards angled"
  },
  {
    valueId: "EV015750",
    valueDesc: "Straight bar",
    label: "EV015750: Straight bar"
  },
  {
    valueId: "EV015751",
    valueDesc: "Bulged bar",
    label: "EV015751: Bulged bar"
  },
  {
    valueId: "EV015752",
    valueDesc: "Handlebar",
    label: "EV015752: Handlebar"
  },
  {
    valueId: "EV015759",
    valueDesc: "NMEA 2000",
    label: "EV015759: NMEA 2000"
  },
  {
    valueId: "EV015760",
    valueDesc: "CAN (Controller Area Network)",
    label: "EV015760: CAN (Controller Area Network)"
  },
  {
    valueId: "EV015761",
    valueDesc: "Long backplate (>200 mm), round",
    label: "EV015761: Long backplate (>200 mm), round"
  },
  {
    valueId: "EV015762",
    valueDesc: "Short backplate (<=200 mm), angular",
    label: "EV015762: Short backplate (<=200 mm), angular"
  },
  {
    valueId: "EV015763",
    valueDesc: "Short backplate (<=200 mm), round",
    label: "EV015763: Short backplate (<=200 mm), round"
  },
  {
    valueId: "EV015769",
    valueDesc: "Door handle and key escutcheon, angular",
    label: "EV015769: Door handle and key escutcheon, angular"
  },
  {
    valueId: "EV015770",
    valueDesc: "Door handle and key escutcheon, round",
    label: "EV015770: Door handle and key escutcheon, round"
  },
  {
    valueId: "EV015771",
    valueDesc: "Door handle and key escutcheon, oval",
    label: "EV015771: Door handle and key escutcheon, oval"
  },
  {
    valueId: "EV015772",
    valueDesc: "Long backplate (>200 mm), angular",
    label: "EV015772: Long backplate (>200 mm), angular"
  },
  {
    valueId: "EV015782",
    valueDesc: "Planar",
    label: "EV015782: Planar"
  },
  {
    valueId: "EV015787",
    valueDesc: "Bush with internal thread",
    label: "EV015787: Bush with internal thread"
  },
  {
    valueId: "EV015789",
    valueDesc: "Floor spring",
    label: "EV015789: Floor spring"
  },
  {
    valueId: "EV015790",
    valueDesc: "Overhead door closer",
    label: "EV015790: Overhead door closer"
  },
  {
    valueId: "EV015795",
    valueDesc: "Link arm",
    label: "EV015795: Link arm"
  },
  {
    valueId: "EV015796",
    valueDesc: "Closing unit",
    label: "EV015796: Closing unit"
  },
  {
    valueId: "EV015798",
    valueDesc: "Virtual",
    label: "EV015798: Virtual"
  },
  {
    valueId: "EV015799",
    valueDesc: "Router",
    label: "EV015799: Router"
  },
  {
    valueId: "EV015800",
    valueDesc: "Firewall",
    label: "EV015800: Firewall"
  },
  {
    valueId: "EV015802",
    valueDesc: "Network switch",
    label: "EV015802: Network switch"
  },
  {
    valueId: "EV015803",
    valueDesc: "Video conferencing",
    label: "EV015803: Video conferencing"
  },
  {
    valueId: "EV015804",
    valueDesc: "IP Video surveillance",
    label: "EV015804: IP Video surveillance"
  },
  {
    valueId: "EV015805",
    valueDesc: "VPN",
    label: "EV015805: VPN"
  },
  {
    valueId: "EV015806",
    valueDesc: "Threat defence",
    label: "EV015806: Threat defence"
  },
  {
    valueId: "EV015807",
    valueDesc: "Upgrade",
    label: "EV015807: Upgrade"
  },
  {
    valueId: "EV015813",
    valueDesc: "Unlockable",
    label: "EV015813: Unlockable"
  },
  {
    valueId: "EV015821",
    valueDesc: "Sliding door",
    label: "EV015821: Sliding door"
  },
  {
    valueId: "EV015822",
    valueDesc: "Room door",
    label: "EV015822: Room door"
  },
  {
    valueId: "EV015823",
    valueDesc: "Front door and room door",
    label: "EV015823: Front door and room door"
  },
  {
    valueId: "EV015824",
    valueDesc: "Outer flap",
    label: "EV015824: Outer flap"
  },
  {
    valueId: "EV015825",
    valueDesc: "Inner flap",
    label: "EV015825: Inner flap"
  },
  {
    valueId: "EV015826",
    valueDesc: "Outer- and inner flap",
    label: "EV015826: Outer- and inner flap"
  },
  {
    valueId: "EV015827",
    valueDesc: "Outer flap, shaft, inner flap",
    label: "EV015827: Outer flap, shaft, inner flap"
  },
  {
    valueId: "EV015833",
    valueDesc: "Secondary closing edge, hinge side",
    label: "EV015833: Secondary closing edge, hinge side"
  },
  {
    valueId: "EV015834",
    valueDesc: "Secondary closing edge, opposite hinge side",
    label: "EV015834: Secondary closing edge, opposite hinge side"
  },
  {
    valueId: "EV015835",
    valueDesc: "Main closing edge",
    label: "EV015835: Main closing edge"
  },
  {
    valueId: "EV015839",
    valueDesc: "Clipped",
    label: "EV015839: Clipped"
  },
  {
    valueId: "EV015840",
    valueDesc: "Ball control valve",
    label: "EV015840: Ball control valve"
  },
  {
    valueId: "EV015841",
    valueDesc: "With top flange according to ISO 5211",
    label: "EV015841: With top flange according to ISO 5211"
  },
  {
    valueId: "EV015842",
    valueDesc: "With central screw in spindle",
    label: "EV015842: With central screw in spindle"
  },
  {
    valueId: "EV015843",
    valueDesc: "Pressure boosting amplifier",
    label: "EV015843: Pressure boosting amplifier"
  },
  {
    valueId: "EV015844",
    valueDesc: "Mat binder",
    label: "EV015844: Mat binder"
  },
  {
    valueId: "EV015856",
    valueDesc: "/",
    label: "EV015856: /"
  },
  {
    valueId: "EV015857",
    valueDesc: "Magnet operation",
    label: "EV015857: Magnet operation"
  },
  {
    valueId: "EV015858",
    valueDesc: "Microchip",
    label: "EV015858: Microchip"
  },
  {
    valueId: "EV015864",
    valueDesc: "Women/Men",
    label: "EV015864: Women/Men"
  },
  {
    valueId: "EV015866",
    valueDesc: "Smoking ban",
    label: "EV015866: Smoking ban"
  },
  {
    valueId: "EV015868",
    valueDesc: "Arrow",
    label: "EV015868: Arrow"
  },
  {
    valueId: "EV015869",
    valueDesc: "Contour",
    label: "EV015869: Contour"
  },
  {
    valueId: "EV015870",
    valueDesc: "Door leaf and floor",
    label: "EV015870: Door leaf and floor"
  },
  {
    valueId: "EV015871",
    valueDesc: "Door leaf and wall",
    label: "EV015871: Door leaf and wall"
  },
  {
    valueId: "EV015873",
    valueDesc: "Free actuation",
    label: "EV015873: Free actuation"
  },
  {
    valueId: "EV015874",
    valueDesc: "Doctor",
    label: "EV015874: Doctor"
  },
  {
    valueId: "EV015875",
    valueDesc: "Fish",
    label: "EV015875: Fish"
  },
  {
    valueId: "EV015876",
    valueDesc: "Female head",
    label: "EV015876: Female head"
  },
  {
    valueId: "EV015877",
    valueDesc: "French handle",
    label: "EV015877: French handle"
  },
  {
    valueId: "EV015878",
    valueDesc: "Georgian",
    label: "EV015878: Georgian"
  },
  {
    valueId: "EV015879",
    valueDesc: "Lyre",
    label: "EV015879: Lyre"
  },
  {
    valueId: "EV015880",
    valueDesc: "Horse",
    label: "EV015880: Horse"
  },
  {
    valueId: "EV015881",
    valueDesc: "Lion",
    label: "EV015881: Lion"
  },
  {
    valueId: "EV015882",
    valueDesc: "Bird",
    label: "EV015882: Bird"
  },
  {
    valueId: "EV015883",
    valueDesc: "At knocker",
    label: "EV015883: At knocker"
  },
  {
    valueId: "EV015885",
    valueDesc: "Security latch",
    label: "EV015885: Security latch"
  },
  {
    valueId: "EV015886",
    valueDesc: "Sports hall fitting",
    label: "EV015886: Sports hall fitting"
  },
  {
    valueId: "EV015894",
    valueDesc: "QR111",
    label: "EV015894: QR111"
  },
  {
    valueId: "EV015895",
    valueDesc: "Radius latch",
    label: "EV015895: Radius latch"
  },
  {
    valueId: "EV015896",
    valueDesc: "Weak model",
    label: "EV015896: Weak model"
  },
  {
    valueId: "EV015897",
    valueDesc: "Standard model",
    label: "EV015897: Standard model"
  },
  {
    valueId: "EV015898",
    valueDesc: "Strengthen model",
    label: "EV015898: Strengthen model"
  },
  {
    valueId: "EV015902",
    valueDesc: "U-shaped striking plate",
    label: "EV015902: U-shaped striking plate"
  },
  {
    valueId: "EV015916",
    valueDesc: "Yellow chromium plated",
    label: "EV015916: Yellow chromium plated"
  },
  {
    valueId: "EV015926",
    valueDesc: "Stone socket",
    label: "EV015926: Stone socket"
  },
  {
    valueId: "EV015929",
    valueDesc: "To immure",
    label: "EV015929: To immure"
  },
  {
    valueId: "EV015932",
    valueDesc: "Point holder",
    label: "EV015932: Point holder"
  },
  {
    valueId: "EV015933",
    valueDesc: "Clamping device",
    label: "EV015933: Clamping device"
  },
  {
    valueId: "EV015935",
    valueDesc: "Water/glycol",
    label: "EV015935: Water/glycol"
  },
  {
    valueId: "EV015936",
    valueDesc: "Condensing",
    label: "EV015936: Condensing"
  },
  {
    valueId: "EV015937",
    valueDesc: "Gas cooler",
    label: "EV015937: Gas cooler"
  },
  {
    valueId: "EV015938",
    valueDesc: "Liquid cooler",
    label: "EV015938: Liquid cooler"
  },
  {
    valueId: "EV015939",
    valueDesc: "Pressing collar",
    label: "EV015939: Pressing collar"
  },
  {
    valueId: "EV015940",
    valueDesc: "1:40",
    label: "EV015940: 1:40"
  },
  {
    valueId: "EV015941",
    valueDesc: "1:60",
    label: "EV015941: 1:60"
  },
  {
    valueId: "EV015942",
    valueDesc: "1:100",
    label: "EV015942: 1:100"
  },
  {
    valueId: "EV015943",
    valueDesc: "Grade 1 (max 100 Mbps)",
    label: "EV015943: Grade 1 (max 100 Mbps)"
  },
  {
    valueId: "EV015944",
    valueDesc: "Grade 2 (max 1 Gbps)",
    label: "EV015944: Grade 2 (max 1 Gbps)"
  },
  {
    valueId: "EV015945",
    valueDesc: "Grade 2 TV (max 1 Gbps, SAT/DVB-T transmission)",
    label: "EV015945: Grade 2 TV (max 1 Gbps, SAT/DVB-T transmission)"
  },
  {
    valueId: "EV015946",
    valueDesc: "Grade 3 (max 10 Gbps, DVB-T transmission)",
    label: "EV015946: Grade 3 (max 10 Gbps, DVB-T transmission)"
  },
  {
    valueId: "EV015947",
    valueDesc: "Grade 3 TV (max 10 Gbps, SAT/DVB-T transmission)",
    label: "EV015947: Grade 3 TV (max 10 Gbps, SAT/DVB-T transmission)"
  },
  {
    valueId: "EV015949",
    valueDesc: "Fibre optic and ADSL/VDSL",
    label: "EV015949: Fibre optic and ADSL/VDSL"
  },
  {
    valueId: "EV015950",
    valueDesc: "Copper internal ending device",
    label: "EV015950: Copper internal ending device"
  },
  {
    valueId: "EV015951",
    valueDesc: "Optical internal ending device",
    label: "EV015951: Optical internal ending device"
  },
  {
    valueId: "EV015952",
    valueDesc: "Individual electrical connection cabinet",
    label: "EV015952: Individual electrical connection cabinet"
  },
  {
    valueId: "EV015953",
    valueDesc: "Low voltage emerging connection cabinet",
    label: "EV015953: Low voltage emerging connection cabinet"
  },
  {
    valueId: "EV015954",
    valueDesc: "Cat-7 certification set",
    label: "EV015954: Cat-7 certification set"
  },
  {
    valueId: "EV015955",
    valueDesc: "Cat-8 (draft) certification set",
    label: "EV015955: Cat-8 (draft) certification set"
  },
  {
    valueId: "EV015956",
    valueDesc: "SFP",
    label: "EV015956: SFP"
  },
  {
    valueId: "EV015957",
    valueDesc: "SFP+",
    label: "EV015957: SFP+"
  },
  {
    valueId: "EV015958",
    valueDesc: "QSFP",
    label: "EV015958: QSFP"
  },
  {
    valueId: "EV015959",
    valueDesc: "LC/PC",
    label: "EV015959: LC/PC"
  },
  {
    valueId: "EV015963",
    valueDesc: "Multi gigabit",
    label: "EV015963: Multi gigabit"
  },
  {
    valueId: "EV015964",
    valueDesc: "H.264",
    label: "EV015964: H.264"
  },
  {
    valueId: "EV015965",
    valueDesc: "H.265",
    label: "EV015965: H.265"
  },
  {
    valueId: "EV015966",
    valueDesc: "H.323",
    label: "EV015966: H.323"
  },
  {
    valueId: "EV015967",
    valueDesc: "SIP",
    label: "EV015967: SIP"
  },
  {
    valueId: "EV015971",
    valueDesc: "Perpetual",
    label: "EV015971: Perpetual"
  },
  {
    valueId: "EV015972",
    valueDesc: "Subscription",
    label: "EV015972: Subscription"
  },
  {
    valueId: "EV015973",
    valueDesc: "g",
    label: "EV015973: g"
  },
  {
    valueId: "EV015974",
    valueDesc: "Paint",
    label: "EV015974: Paint"
  },
  {
    valueId: "EV015975",
    valueDesc: "Opaque stain",
    label: "EV015975: Opaque stain"
  },
  {
    valueId: "EV015976",
    valueDesc: "Stain",
    label: "EV015976: Stain"
  },
  {
    valueId: "EV015979",
    valueDesc: "80/130 mm",
    label: "EV015979: 80/130 mm"
  },
  {
    valueId: "EV015980",
    valueDesc: "110/160 mm",
    label: "EV015980: 110/160 mm"
  },
  {
    valueId: "EV015996",
    valueDesc: "Milliken",
    label: "EV015996: Milliken"
  },
  {
    valueId: "EV015998",
    valueDesc: "DIP-switches",
    label: "EV015998: DIP-switches"
  },
  {
    valueId: "EV015999",
    valueDesc: "Operating software or DIP switches",
    label: "EV015999: Operating software or DIP switches"
  },
  {
    valueId: "EV016000",
    valueDesc: "TH/BE",
    label: "EV016000: TH/BE"
  },
  {
    valueId: "EV016001",
    valueDesc: "BE/HE",
    label: "EV016001: BE/HE"
  },
  {
    valueId: "EV016002",
    valueDesc: "TH/U",
    label: "EV016002: TH/U"
  },
  {
    valueId: "EV016003",
    valueDesc: "Welding with open flame",
    label: "EV016003: Welding with open flame"
  },
  {
    valueId: "EV016005",
    valueDesc: "Cast iron GG 15 (GJL-150)",
    label: "EV016005: Cast iron GG 15 (GJL-150)"
  },
  {
    valueId: "EV016007",
    valueDesc: "E 120",
    label: "EV016007: E 120"
  },
  {
    valueId: "EV016008",
    valueDesc: "Free match",
    label: "EV016008: Free match"
  },
  {
    valueId: "EV016009",
    valueDesc: "Straight match",
    label: "EV016009: Straight match"
  },
  {
    valueId: "EV016010",
    valueDesc: "Offset match",
    label: "EV016010: Offset match"
  },
  {
    valueId: "EV016011",
    valueDesc: "Scroll compressor",
    label: "EV016011: Scroll compressor"
  },
  {
    valueId: "EV016012",
    valueDesc: "Piston compressor",
    label: "EV016012: Piston compressor"
  },
  {
    valueId: "EV016013",
    valueDesc: "Screw compressor",
    label: "EV016013: Screw compressor"
  },
  {
    valueId: "EV016014",
    valueDesc: "Rotary compressor",
    label: "EV016014: Rotary compressor"
  },
  {
    valueId: "EV016015",
    valueDesc: "Centrifugal compressor",
    label: "EV016015: Centrifugal compressor"
  },
  {
    valueId: "EV016016",
    valueDesc: "Open compressor",
    label: "EV016016: Open compressor"
  },
  {
    valueId: "EV016017",
    valueDesc: "Semi hermetic compressor",
    label: "EV016017: Semi hermetic compressor"
  },
  {
    valueId: "EV016018",
    valueDesc: "Hermetic compressor",
    label: "EV016018: Hermetic compressor"
  },
  {
    valueId: "EV016019",
    valueDesc: "Refrigerant circuit",
    label: "EV016019: Refrigerant circuit"
  },
  {
    valueId: "EV016022",
    valueDesc: "Electronically commutated (EC)",
    label: "EV016022: Electronically commutated (EC)"
  },
  {
    valueId: "EV016028",
    valueDesc: "250",
    label: "EV016028: 250"
  },
  {
    valueId: "EV016029",
    valueDesc: "160/250 mm",
    label: "EV016029: 160/250 mm"
  },
  {
    valueId: "EV016030",
    valueDesc: "125/200 mm",
    label: "EV016030: 125/200 mm"
  },
  {
    valueId: "EV016031",
    valueDesc: "Pressing jaw",
    label: "EV016031: Pressing jaw"
  },
  {
    valueId: "EV016032",
    valueDesc: "Tap water circuit",
    label: "EV016032: Tap water circuit"
  },
  {
    valueId: "EV016058",
    valueDesc: "Nail gun fastening",
    label: "EV016058: Nail gun fastening"
  },
  {
    valueId: "EV016059",
    valueDesc: "Plier stapler",
    label: "EV016059: Plier stapler"
  },
  {
    valueId: "EV016060",
    valueDesc: "Stapler",
    label: "EV016060: Stapler"
  },
  {
    valueId: "EV016062",
    valueDesc: "Cap nut",
    label: "EV016062: Cap nut"
  },
  {
    valueId: "EV016064",
    valueDesc: "Castle nut",
    label: "EV016064: Castle nut"
  },
  {
    valueId: "EV016066",
    valueDesc: "External square",
    label: "EV016066: External square"
  },
  {
    valueId: "EV016067",
    valueDesc: "Flowmaker",
    label: "EV016067: Flowmaker"
  },
  {
    valueId: "EV016069",
    valueDesc: "Gear-driven",
    label: "EV016069: Gear-driven"
  },
  {
    valueId: "EV016070",
    valueDesc: "Adhesive tape",
    label: "EV016070: Adhesive tape"
  },
  {
    valueId: "EV016071",
    valueDesc: "1x2",
    label: "EV016071: 1x2"
  },
  {
    valueId: "EV016072",
    valueDesc: "1x3",
    label: "EV016072: 1x3"
  },
  {
    valueId: "EV016073",
    valueDesc: "1x6",
    label: "EV016073: 1x6"
  },
  {
    valueId: "EV016074",
    valueDesc: "1x8",
    label: "EV016074: 1x8"
  },
  {
    valueId: "EV016075",
    valueDesc: "1x16",
    label: "EV016075: 1x16"
  },
  {
    valueId: "EV016076",
    valueDesc: "1x32",
    label: "EV016076: 1x32"
  },
  {
    valueId: "EV016077",
    valueDesc: "1x64",
    label: "EV016077: 1x64"
  },
  {
    valueId: "EV016078",
    valueDesc: "Splice cassette",
    label: "EV016078: Splice cassette"
  },
  {
    valueId: "EV016079",
    valueDesc: "LC/APC",
    label: "EV016079: LC/APC"
  },
  {
    valueId: "EV016080",
    valueDesc: "LC/UPC",
    label: "EV016080: LC/UPC"
  },
  {
    valueId: "EV016081",
    valueDesc: "SC/APC",
    label: "EV016081: SC/APC"
  },
  {
    valueId: "EV016082",
    valueDesc: "SC/UPC",
    label: "EV016082: SC/UPC"
  },
  {
    valueId: "EV016083",
    valueDesc: "Open end",
    label: "EV016083: Open end"
  },
  {
    valueId: "EV016084",
    valueDesc: "Clamp connection",
    label: "EV016084: Clamp connection"
  },
  {
    valueId: "EV016085",
    valueDesc: "Wood oil",
    label: "EV016085: Wood oil"
  },
  {
    valueId: "EV016086",
    valueDesc: "Eggshell",
    label: "EV016086: Eggshell"
  },
  {
    valueId: "EV016087",
    valueDesc: "Semi-gloss",
    label: "EV016087: Semi-gloss"
  },
  {
    valueId: "EV016093",
    valueDesc: "Push rod",
    label: "EV016093: Push rod"
  },
  {
    valueId: "EV016094",
    valueDesc: "Factory made Phenol Foam (PF) according to EN 13166",
    label: "EV016094: Factory made Phenol Foam (PF) according to EN 13166"
  },
  {
    valueId: "EV016095",
    valueDesc: "Factory made Cellular Glass (CG) according to EN 13167",
    label: "EV016095: Factory made Cellular Glass (CG) according to EN 13167"
  },
  {
    valueId: "EV016096",
    valueDesc: "Factory made wood wool (WW) according to EN 13168",
    label: "EV016096: Factory made wood wool (WW) according to EN 13168"
  },
  {
    valueId: "EV016097",
    valueDesc: "Factory made Perlite (EPB) according to EN 13169",
    label: "EV016097: Factory made Perlite (EPB) according to EN 13169"
  },
  {
    valueId: "EV016098",
    valueDesc: "Push bar",
    label: "EV016098: Push bar"
  },
  {
    valueId: "EV016099",
    valueDesc: "Single door",
    label: "EV016099: Single door"
  },
  {
    valueId: "EV016100",
    valueDesc: "Double-point locking",
    label: "EV016100: Double-point locking"
  },
  {
    valueId: "EV016101",
    valueDesc: "Internally/externally",
    label: "EV016101: Internally/externally"
  },
  {
    valueId: "EV016102",
    valueDesc: "R600a",
    label: "EV016102: R600a"
  },
  {
    valueId: "EV016103",
    valueDesc: "R422d",
    label: "EV016103: R422d"
  },
  {
    valueId: "EV016104",
    valueDesc: "Europrofile",
    label: "EV016104: Europrofile"
  },
  {
    valueId: "EV016105",
    valueDesc: "Left/bottom",
    label: "EV016105: Left/bottom"
  },
  {
    valueId: "EV016106",
    valueDesc: "Right/bottom",
    label: "EV016106: Right/bottom"
  },
  {
    valueId: "EV016108",
    valueDesc: "Phenol-resorcinol-formaldehyde (PRF)",
    label: "EV016108: Phenol-resorcinol-formaldehyde (PRF)"
  },
  {
    valueId: "EV016109",
    valueDesc: "Melamine-urea-formaldehyde (MUF)",
    label: "EV016109: Melamine-urea-formaldehyde (MUF)"
  },
  {
    valueId: "EV016111",
    valueDesc: "Copper impregnated",
    label: "EV016111: Copper impregnated"
  },
  {
    valueId: "EV016112",
    valueDesc: "Heavy metal free",
    label: "EV016112: Heavy metal free"
  },
  {
    valueId: "EV016113",
    valueDesc: "Lithium Iron Phosphate (LFP)",
    label: "EV016113: Lithium Iron Phosphate (LFP)"
  },
  {
    valueId: "EV016115",
    valueDesc: "Single curved",
    label: "EV016115: Single curved"
  },
  {
    valueId: "EV016117",
    valueDesc: "Soft-close damper",
    label: "EV016117: Soft-close damper"
  },
  {
    valueId: "EV016118",
    valueDesc: "Double curved",
    label: "EV016118: Double curved"
  },
  {
    valueId: "EV016120",
    valueDesc: "Ridge tile",
    label: "EV016120: Ridge tile"
  },
  {
    valueId: "EV016125",
    valueDesc: "Ridge connector sloped",
    label: "EV016125: Ridge connector sloped"
  },
  {
    valueId: "EV016131",
    valueDesc: "Under tile",
    label: "EV016131: Under tile"
  },
  {
    valueId: "EV016133",
    valueDesc: "Mono tile",
    label: "EV016133: Mono tile"
  },
  {
    valueId: "EV016134",
    valueDesc: "Ventilation tile",
    label: "EV016134: Ventilation tile"
  },
  {
    valueId: "EV016135",
    valueDesc: "Mansard tile",
    label: "EV016135: Mansard tile"
  },
  {
    valueId: "EV016137",
    valueDesc: "1008",
    label: "EV016137: 1008"
  },
  {
    valueId: "EV016139",
    valueDesc: "Volume part",
    label: "EV016139: Volume part"
  },
  {
    valueId: "EV016140",
    valueDesc: "Throttle",
    label: "EV016140: Throttle"
  },
  {
    valueId: "EV016143",
    valueDesc: "Internal thread cylindrical BSPT-Rp (ISO 7-1 / EN 10226-1)",
    label: "EV016143: Internal thread cylindrical BSPT-Rp (ISO 7-1 / EN 10226-1)"
  },
  {
    valueId: "EV016144",
    valueDesc: "Pipe feed-through",
    label: "EV016144: Pipe feed-through"
  },
  {
    valueId: "EV016146",
    valueDesc: "Synthetic oil (KNAN)",
    label: "EV016146: Synthetic oil (KNAN)"
  },
  {
    valueId: "EV016147",
    valueDesc: "Mineral oil (ONAN)",
    label: "EV016147: Mineral oil (ONAN)"
  },
  {
    valueId: "EV016148",
    valueDesc: "Yyn0",
    label: "EV016148: Yyn0"
  },
  {
    valueId: "EV016149",
    valueDesc: "Y(N)yn0",
    label: "EV016149: Y(N)yn0"
  },
  {
    valueId: "EV016150",
    valueDesc: "Dyn11",
    label: "EV016150: Dyn11"
  },
  {
    valueId: "EV016151",
    valueDesc: "Dyn5",
    label: "EV016151: Dyn5"
  },
  {
    valueId: "EV016152",
    valueDesc: "Dyn11yn11",
    label: "EV016152: Dyn11yn11"
  },
  {
    valueId: "EV016153",
    valueDesc: "Dyn11d0",
    label: "EV016153: Dyn11d0"
  },
  {
    valueId: "EV016154",
    valueDesc: "Dyn11-Dd0",
    label: "EV016154: Dyn11-Dd0"
  },
  {
    valueId: "EV016155",
    valueDesc: "YNd11",
    label: "EV016155: YNd11"
  },
  {
    valueId: "EV016156",
    valueDesc: "YNd5",
    label: "EV016156: YNd5"
  },
  {
    valueId: "EV016158",
    valueDesc: "+2/-4 x 2.5 %",
    label: "EV016158: +2/-4 x 2.5 %"
  },
  {
    valueId: "EV016159",
    valueDesc: "+/- 2 x 2.5 %",
    label: "EV016159: +/- 2 x 2.5 %"
  },
  {
    valueId: "EV016160",
    valueDesc: "Leakage indication",
    label: "EV016160: Leakage indication"
  },
  {
    valueId: "EV016161",
    valueDesc: "Taperlock bush",
    label: "EV016161: Taperlock bush"
  },
  {
    valueId: "EV016162",
    valueDesc: "SPA",
    label: "EV016162: SPA"
  },
  {
    valueId: "EV016163",
    valueDesc: "SPB",
    label: "EV016163: SPB"
  },
  {
    valueId: "EV016164",
    valueDesc: "SPC",
    label: "EV016164: SPC"
  },
  {
    valueId: "EV016165",
    valueDesc: "SPZ",
    label: "EV016165: SPZ"
  },
  {
    valueId: "EV016166",
    valueDesc: "3V/9N",
    label: "EV016166: 3V/9N"
  },
  {
    valueId: "EV016167",
    valueDesc: "5V/15N",
    label: "EV016167: 5V/15N"
  },
  {
    valueId: "EV016168",
    valueDesc: "8V/25N",
    label: "EV016168: 8V/25N"
  },
  {
    valueId: "EV016169",
    valueDesc: "AX/X13",
    label: "EV016169: AX/X13"
  },
  {
    valueId: "EV016171",
    valueDesc: "XPZ",
    label: "EV016171: XPZ"
  },
  {
    valueId: "EV016172",
    valueDesc: "XPA",
    label: "EV016172: XPA"
  },
  {
    valueId: "EV016173",
    valueDesc: "XPC",
    label: "EV016173: XPC"
  },
  {
    valueId: "EV016174",
    valueDesc: "ZX/X10",
    label: "EV016174: ZX/X10"
  },
  {
    valueId: "EV016175",
    valueDesc: "Y/6",
    label: "EV016175: Y/6"
  },
  {
    valueId: "EV016176",
    valueDesc: "Z/10",
    label: "EV016176: Z/10"
  },
  {
    valueId: "EV016177",
    valueDesc: "A/13",
    label: "EV016177: A/13"
  },
  {
    valueId: "EV016178",
    valueDesc: "B/17",
    label: "EV016178: B/17"
  },
  {
    valueId: "EV016179",
    valueDesc: "C/22",
    label: "EV016179: C/22"
  },
  {
    valueId: "EV016180",
    valueDesc: "BX/X17",
    label: "EV016180: BX/X17"
  },
  {
    valueId: "EV016181",
    valueDesc: "CX/X22",
    label: "EV016181: CX/X22"
  },
  {
    valueId: "EV016182",
    valueDesc: "D/32",
    label: "EV016182: D/32"
  },
  {
    valueId: "EV016183",
    valueDesc: "E/40",
    label: "EV016183: E/40"
  },
  {
    valueId: "EV016184",
    valueDesc: "XPB",
    label: "EV016184: XPB"
  },
  {
    valueId: "EV016185",
    valueDesc: "1108",
    label: "EV016185: 1108"
  },
  {
    valueId: "EV016186",
    valueDesc: "1210",
    label: "EV016186: 1210"
  },
  {
    valueId: "EV016187",
    valueDesc: "1610",
    label: "EV016187: 1610"
  },
  {
    valueId: "EV016188",
    valueDesc: "1615",
    label: "EV016188: 1615"
  },
  {
    valueId: "EV016189",
    valueDesc: "2012",
    label: "EV016189: 2012"
  },
  {
    valueId: "EV016190",
    valueDesc: "2517",
    label: "EV016190: 2517"
  },
  {
    valueId: "EV016191",
    valueDesc: "3020",
    label: "EV016191: 3020"
  },
  {
    valueId: "EV016192",
    valueDesc: "3030",
    label: "EV016192: 3030"
  },
  {
    valueId: "EV016193",
    valueDesc: "3525",
    label: "EV016193: 3525"
  },
  {
    valueId: "EV016194",
    valueDesc: "4040",
    label: "EV016194: 4040"
  },
  {
    valueId: "EV016195",
    valueDesc: "4545",
    label: "EV016195: 4545"
  },
  {
    valueId: "EV016196",
    valueDesc: "5050",
    label: "EV016196: 5050"
  },
  {
    valueId: "EV016197",
    valueDesc: "3535",
    label: "EV016197: 3535"
  },
  {
    valueId: "EV016198",
    valueDesc: "4030",
    label: "EV016198: 4030"
  },
  {
    valueId: "EV016199",
    valueDesc: "4535",
    label: "EV016199: 4535"
  },
  {
    valueId: "EV016200",
    valueDesc: "5040",
    label: "EV016200: 5040"
  },
  {
    valueId: "EV016201",
    valueDesc: "Distributor mounting bracket",
    label: "EV016201: Distributor mounting bracket"
  },
  {
    valueId: "EV016202",
    valueDesc: "E30 - E60",
    label: "EV016202: E30 - E60"
  },
  {
    valueId: "EV016203",
    valueDesc: "E30 - E90",
    label: "EV016203: E30 - E90"
  },
  {
    valueId: "EV016205",
    valueDesc: "+/- 5 %",
    label: "EV016205: +/- 5 %"
  },
  {
    valueId: "EV016206",
    valueDesc: "Tile oil",
    label: "EV016206: Tile oil"
  },
  {
    valueId: "EV016207",
    valueDesc: "Grout cleanser",
    label: "EV016207: Grout cleanser"
  },
  {
    valueId: "EV016208",
    valueDesc: "Grout cleaning tool",
    label: "EV016208: Grout cleaning tool"
  },
  {
    valueId: "EV016209",
    valueDesc: "Levelling clip",
    label: "EV016209: Levelling clip"
  },
  {
    valueId: "EV016210",
    valueDesc: "Orange/white",
    label: "EV016210: Orange/white"
  },
  {
    valueId: "EV016211",
    valueDesc: "Radio transmitter",
    label: "EV016211: Radio transmitter"
  },
  {
    valueId: "EV016212",
    valueDesc: "Emulsion polymer isocyanate (EPI)",
    label: "EV016212: Emulsion polymer isocyanate (EPI)"
  },
  {
    valueId: "EV016213",
    valueDesc: "150/160 mm",
    label: "EV016213: 150/160 mm"
  },
  {
    valueId: "EV016214",
    valueDesc: "Variable beam angle",
    label: "EV016214: Variable beam angle"
  },
  {
    valueId: "EV016215",
    valueDesc: "Danish standard (earthing pin)",
    label: "EV016215: Danish standard (earthing pin)"
  },
  {
    valueId: "EV016219",
    valueDesc: "Colours and numbers",
    label: "EV016219: Colours and numbers"
  },
  {
    valueId: "EV016220",
    valueDesc: "Lithium Nickel Manganese Cobalt (NMC)",
    label: "EV016220: Lithium Nickel Manganese Cobalt (NMC)"
  },
  {
    valueId: "EV016222",
    valueDesc: "Laminates",
    label: "EV016222: Laminates"
  },
  {
    valueId: "EV016223",
    valueDesc: "Tappet for caliper face spanner",
    label: "EV016223: Tappet for caliper face spanner"
  },
  {
    valueId: "EV016224",
    valueDesc: "9B",
    label: "EV016224: 9B"
  },
  {
    valueId: "EV016225",
    valueDesc: "8B",
    label: "EV016225: 8B"
  },
  {
    valueId: "EV016226",
    valueDesc: "7B",
    label: "EV016226: 7B"
  },
  {
    valueId: "EV016227",
    valueDesc: "6B",
    label: "EV016227: 6B"
  },
  {
    valueId: "EV016228",
    valueDesc: "5B",
    label: "EV016228: 5B"
  },
  {
    valueId: "EV016229",
    valueDesc: "4B",
    label: "EV016229: 4B"
  },
  {
    valueId: "EV016230",
    valueDesc: "3B",
    label: "EV016230: 3B"
  },
  {
    valueId: "EV016231",
    valueDesc: "2B",
    label: "EV016231: 2B"
  },
  {
    valueId: "EV016232",
    valueDesc: "2H",
    label: "EV016232: 2H"
  },
  {
    valueId: "EV016233",
    valueDesc: "3H",
    label: "EV016233: 3H"
  },
  {
    valueId: "EV016234",
    valueDesc: "4H",
    label: "EV016234: 4H"
  },
  {
    valueId: "EV016235",
    valueDesc: "5H",
    label: "EV016235: 5H"
  },
  {
    valueId: "EV016236",
    valueDesc: "6H",
    label: "EV016236: 6H"
  },
  {
    valueId: "EV016237",
    valueDesc: "7H",
    label: "EV016237: 7H"
  },
  {
    valueId: "EV016238",
    valueDesc: "8H",
    label: "EV016238: 8H"
  },
  {
    valueId: "EV016239",
    valueDesc: "9H",
    label: "EV016239: 9H"
  },
  {
    valueId: "EV016240",
    valueDesc: "Very soft",
    label: "EV016240: Very soft"
  },
  {
    valueId: "EV016241",
    valueDesc: "Very hard",
    label: "EV016241: Very hard"
  },
  {
    valueId: "EV016242",
    valueDesc: "Extreme hard",
    label: "EV016242: Extreme hard"
  },
  {
    valueId: "EV016245",
    valueDesc: "Mechanical with strength booster",
    label: "EV016245: Mechanical with strength booster"
  },
  {
    valueId: "EV016246",
    valueDesc: "Mechanical without strength booster",
    label: "EV016246: Mechanical without strength booster"
  },
  {
    valueId: "EV016247",
    valueDesc: "Mechanical-hydraulic",
    label: "EV016247: Mechanical-hydraulic"
  },
  {
    valueId: "EV016251",
    valueDesc: "Fresh spray",
    label: "EV016251: Fresh spray"
  },
  {
    valueId: "EV016252",
    valueDesc: "Impregnating spray",
    label: "EV016252: Impregnating spray"
  },
  {
    valueId: "EV016253",
    valueDesc: "Shoe polish",
    label: "EV016253: Shoe polish"
  },
  {
    valueId: "EV016254",
    valueDesc: "Trouser belt",
    label: "EV016254: Trouser belt"
  },
  {
    valueId: "EV016255",
    valueDesc: "Spiked shoe",
    label: "EV016255: Spiked shoe"
  },
  {
    valueId: "EV016256",
    valueDesc: "Leather grease",
    label: "EV016256: Leather grease"
  },
  {
    valueId: "EV016257",
    valueDesc: "Shoe sponge",
    label: "EV016257: Shoe sponge"
  },
  {
    valueId: "EV016260",
    valueDesc: "Scarf",
    label: "EV016260: Scarf"
  },
  {
    valueId: "EV016261",
    valueDesc: "Neckerchief",
    label: "EV016261: Neckerchief"
  },
  {
    valueId: "EV016262",
    valueDesc: "Buckle",
    label: "EV016262: Buckle"
  },
  {
    valueId: "EV016263",
    valueDesc: "Hard wax",
    label: "EV016263: Hard wax"
  },
  {
    valueId: "EV016264",
    valueDesc: "Wood cement",
    label: "EV016264: Wood cement"
  },
  {
    valueId: "EV016265",
    valueDesc: "Soft wax",
    label: "EV016265: Soft wax"
  },
  {
    valueId: "EV016266",
    valueDesc: "Wood stain",
    label: "EV016266: Wood stain"
  },
  {
    valueId: "EV016267",
    valueDesc: "Armouring tape",
    label: "EV016267: Armouring tape"
  },
  {
    valueId: "EV016269",
    valueDesc: "Titanium + magnesium",
    label: "EV016269: Titanium + magnesium"
  },
  {
    valueId: "EV016270",
    valueDesc: "23",
    label: "EV016270: 23"
  },
  {
    valueId: "EV016271",
    valueDesc: "IR-A",
    label: "EV016271: IR-A"
  },
  {
    valueId: "EV016272",
    valueDesc: "IR-B",
    label: "EV016272: IR-B"
  },
  {
    valueId: "EV016273",
    valueDesc: "IR-C",
    label: "EV016273: IR-C"
  },
  {
    valueId: "EV016274",
    valueDesc: "Combined thermo- and hygrometer",
    label: "EV016274: Combined thermo- and hygrometer"
  },
  {
    valueId: "EV016275",
    valueDesc: "Single lock (electronic)",
    label: "EV016275: Single lock (electronic)"
  },
  {
    valueId: "EV016276",
    valueDesc: "Double lock (electronic lock and key)",
    label: "EV016276: Double lock (electronic lock and key)"
  },
  {
    valueId: "EV016277",
    valueDesc: "Double lock (electronic lock and knob)",
    label: "EV016277: Double lock (electronic lock and knob)"
  },
  {
    valueId: "EV016278",
    valueDesc: "Double lock (electronic lock on both sides)",
    label: "EV016278: Double lock (electronic lock on both sides)"
  },
  {
    valueId: "EV016279",
    valueDesc: "Hammer wedge",
    label: "EV016279: Hammer wedge"
  },
  {
    valueId: "EV016280",
    valueDesc: "4K UHD",
    label: "EV016280: 4K UHD"
  },
  {
    valueId: "EV016281",
    valueDesc: "8K UHD",
    label: "EV016281: 8K UHD"
  },
  {
    valueId: "EV016283",
    valueDesc: "Pipe cleaning drill",
    label: "EV016283: Pipe cleaning drill"
  },
  {
    valueId: "EV016286",
    valueDesc: "Terminal profile",
    label: "EV016286: Terminal profile"
  },
  {
    valueId: "EV016287",
    valueDesc: "Lock",
    label: "EV016287: Lock"
  },
  {
    valueId: "EV016289",
    valueDesc: "Refrigeration dryer",
    label: "EV016289: Refrigeration dryer"
  },
  {
    valueId: "EV016290",
    valueDesc: "Adsorption dryer",
    label: "EV016290: Adsorption dryer"
  },
  {
    valueId: "EV016291",
    valueDesc: "Membrane dryer",
    label: "EV016291: Membrane dryer"
  },
  {
    valueId: "EV016292",
    valueDesc: "Foil eraser",
    label: "EV016292: Foil eraser"
  },
  {
    valueId: "EV016293",
    valueDesc: "Jarrah",
    label: "EV016293: Jarrah"
  },
  {
    valueId: "EV016294",
    valueDesc: "1-rod",
    label: "EV016294: 1-rod"
  },
  {
    valueId: "EV016295",
    valueDesc: "2-rod",
    label: "EV016295: 2-rod"
  },
  {
    valueId: "EV016296",
    valueDesc: "3-rod",
    label: "EV016296: 3-rod"
  },
  {
    valueId: "EV016297",
    valueDesc: "4-rod",
    label: "EV016297: 4-rod"
  },
  {
    valueId: "EV016298",
    valueDesc: "Pattern",
    label: "EV016298: Pattern"
  },
  {
    valueId: "EV016299",
    valueDesc: "Elm",
    label: "EV016299: Elm"
  },
  {
    valueId: "EV016300",
    valueDesc: "Slate/stone",
    label: "EV016300: Slate/stone"
  },
  {
    valueId: "EV016302",
    valueDesc: "Dark",
    label: "EV016302: Dark"
  },
  {
    valueId: "EV016303",
    valueDesc: "Medium/half dark",
    label: "EV016303: Medium/half dark"
  },
  {
    valueId: "EV016304",
    valueDesc: "31",
    label: "EV016304: 31"
  },
  {
    valueId: "EV016307",
    valueDesc: "Rustic stone",
    label: "EV016307: Rustic stone"
  },
  {
    valueId: "EV016308",
    valueDesc: "Stone structure",
    label: "EV016308: Stone structure"
  },
  {
    valueId: "EV016309",
    valueDesc: "Wood structure",
    label: "EV016309: Wood structure"
  },
  {
    valueId: "EV016310",
    valueDesc: "Hard wax oil",
    label: "EV016310: Hard wax oil"
  },
  {
    valueId: "EV016311",
    valueDesc: "Throughflow with straight branches",
    label: "EV016311: Throughflow with straight branches"
  },
  {
    valueId: "EV016312",
    valueDesc: "Throughflow with angled branches",
    label: "EV016312: Throughflow with angled branches"
  },
  {
    valueId: "EV016313",
    valueDesc: "Not throughflow with straight branches",
    label: "EV016313: Not throughflow with straight branches"
  },
  {
    valueId: "EV016314",
    valueDesc: "Not throughflow with angled branches",
    label: "EV016314: Not throughflow with angled branches"
  },
  {
    valueId: "EV016315",
    valueDesc: "K1-10",
    label: "EV016315: K1-10"
  },
  {
    valueId: "EV016316",
    valueDesc: "K2-10",
    label: "EV016316: K2-10"
  },
  {
    valueId: "EV016317",
    valueDesc: "K2-30",
    label: "EV016317: K2-30"
  },
  {
    valueId: "EV016318",
    valueDesc: "K2-60",
    label: "EV016318: K2-60"
  },
  {
    valueId: "EV016319",
    valueDesc: "EN 520 Gypsum plasterboard - lined with cardboard",
    label: "EV016319: EN 520 Gypsum plasterboard - lined with cardboard"
  },
  {
    valueId: "EV016320",
    valueDesc: "EN 15283-1 Fleece reinforced plasterboard",
    label: "EV016320: EN 15283-1 Fleece reinforced plasterboard"
  },
  {
    valueId: "EV016321",
    valueDesc: "EN 15283-2 Gypsum fibre board",
    label: "EV016321: EN 15283-2 Gypsum fibre board"
  },
  {
    valueId: "EV016322",
    valueDesc: "Hub",
    label: "EV016322: Hub"
  },
  {
    valueId: "EV016323",
    valueDesc: "Work table",
    label: "EV016323: Work table"
  },
  {
    valueId: "EV016324",
    valueDesc: "Two sides",
    label: "EV016324: Two sides"
  },
  {
    valueId: "EV016325",
    valueDesc: "Four sides",
    label: "EV016325: Four sides"
  },
  {
    valueId: "EV016326",
    valueDesc: "PE-100 RC",
    label: "EV016326: PE-100 RC"
  },
  {
    valueId: "EV016327",
    valueDesc: "Stainless steel 304 (1.4307)",
    label: "EV016327: Stainless steel 304 (1.4307)"
  },
  {
    valueId: "EV016328",
    valueDesc: "Stainless steel 316 L (1.4432)",
    label: "EV016328: Stainless steel 316 L (1.4432)"
  },
  {
    valueId: "EV016329",
    valueDesc: "Busbar adapter",
    label: "EV016329: Busbar adapter"
  },
  {
    valueId: "EV016331",
    valueDesc: "MS-Polymer",
    label: "EV016331: MS-Polymer"
  },
  {
    valueId: "EV016332",
    valueDesc: "Refractory grout",
    label: "EV016332: Refractory grout"
  },
  {
    valueId: "EV016336",
    valueDesc: "Spongeable",
    label: "EV016336: Spongeable"
  },
  {
    valueId: "EV016337",
    valueDesc: "Washable",
    label: "EV016337: Washable"
  },
  {
    valueId: "EV016338",
    valueDesc: "Extra washable",
    label: "EV016338: Extra washable"
  },
  {
    valueId: "EV016339",
    valueDesc: "Headlocked",
    label: "EV016339: Headlocked"
  },
  {
    valueId: "EV016340",
    valueDesc: "Head- and sidelocked",
    label: "EV016340: Head- and sidelocked"
  },
  {
    valueId: "EV016342",
    valueDesc: "Grid foil",
    label: "EV016342: Grid foil"
  },
  {
    valueId: "EV016343",
    valueDesc: "Pipe mounting rail",
    label: "EV016343: Pipe mounting rail"
  },
  {
    valueId: "EV016344",
    valueDesc: "Measuring point marking",
    label: "EV016344: Measuring point marking"
  },
  {
    valueId: "EV016347",
    valueDesc: "Pipe guide wall clamp",
    label: "EV016347: Pipe guide wall clamp"
  },
  {
    valueId: "EV016348",
    valueDesc: "Hot cutting device",
    label: "EV016348: Hot cutting device"
  },
  {
    valueId: "EV016350",
    valueDesc: "Reverse alternate length",
    label: "EV016350: Reverse alternate length"
  },
  {
    valueId: "EV016351",
    valueDesc: "Scrubbable",
    label: "EV016351: Scrubbable"
  },
  {
    valueId: "EV016352",
    valueDesc: "Extra scrubbable",
    label: "EV016352: Extra scrubbable"
  },
  {
    valueId: "EV016353",
    valueDesc: "Straight/straight",
    label: "EV016353: Straight/straight"
  },
  {
    valueId: "EV016354",
    valueDesc: "Straight/angled",
    label: "EV016354: Straight/angled"
  },
  {
    valueId: "EV016355",
    valueDesc: "Angled/angled",
    label: "EV016355: Angled/angled"
  },
  {
    valueId: "EV016356",
    valueDesc: "92/200",
    label: "EV016356: 92/200"
  },
  {
    valueId: "EV016357",
    valueDesc: "94/35",
    label: "EV016357: 94/35"
  },
  {
    valueId: "EV016358",
    valueDesc: "73.5/20",
    label: "EV016358: 73.5/20"
  },
  {
    valueId: "EV016360",
    valueDesc: "Roof tile plate",
    label: "EV016360: Roof tile plate"
  },
  {
    valueId: "EV016361",
    valueDesc: "Cup dispenser",
    label: "EV016361: Cup dispenser"
  },
  {
    valueId: "EV016362",
    valueDesc: "Drum tongs",
    label: "EV016362: Drum tongs"
  },
  {
    valueId: "EV016363",
    valueDesc: "Rainwater pipe",
    label: "EV016363: Rainwater pipe"
  },
  {
    valueId: "EV016364",
    valueDesc: "Corbel unit",
    label: "EV016364: Corbel unit"
  },
  {
    valueId: "EV016365",
    valueDesc: "Lintel",
    label: "EV016365: Lintel"
  },
  {
    valueId: "EV016367",
    valueDesc: "Flue adapter",
    label: "EV016367: Flue adapter"
  },
  {
    valueId: "EV016368",
    valueDesc: "Fireback",
    label: "EV016368: Fireback"
  },
  {
    valueId: "EV016369",
    valueDesc: "Throat restrictor",
    label: "EV016369: Throat restrictor"
  },
  {
    valueId: "EV016370",
    valueDesc: "Firebrick",
    label: "EV016370: Firebrick"
  },
  {
    valueId: "EV016372",
    valueDesc: "Windscreen cleaner",
    label: "EV016372: Windscreen cleaner"
  },
  {
    valueId: "EV016373",
    valueDesc: "Brake fluid",
    label: "EV016373: Brake fluid"
  },
  {
    valueId: "EV016374",
    valueDesc: "Cooling liquid",
    label: "EV016374: Cooling liquid"
  },
  {
    valueId: "EV016379",
    valueDesc: "IP4X",
    label: "EV016379: IP4X"
  },
  {
    valueId: "EV016380",
    valueDesc: "IP5X",
    label: "EV016380: IP5X"
  },
  {
    valueId: "EV016381",
    valueDesc: "IP6X",
    label: "EV016381: IP6X"
  },
  {
    valueId: "EV016382",
    valueDesc: "Fireplace grate",
    label: "EV016382: Fireplace grate"
  },
  {
    valueId: "EV016383",
    valueDesc: "Fireplace baffle",
    label: "EV016383: Fireplace baffle"
  },
  {
    valueId: "EV016384",
    valueDesc: "Ash pan",
    label: "EV016384: Ash pan"
  },
  {
    valueId: "EV016385",
    valueDesc: "Angled pipe",
    label: "EV016385: Angled pipe"
  },
  {
    valueId: "EV016386",
    valueDesc: "Furniture carrying strap",
    label: "EV016386: Furniture carrying strap"
  },
  {
    valueId: "EV016387",
    valueDesc: "Hose carrying strap",
    label: "EV016387: Hose carrying strap"
  },
  {
    valueId: "EV016388",
    valueDesc: "Pocket alarm",
    label: "EV016388: Pocket alarm"
  },
  {
    valueId: "EV016390",
    valueDesc: "Joint piece",
    label: "EV016390: Joint piece"
  },
  {
    valueId: "EV016391",
    valueDesc: "Refill",
    label: "EV016391: Refill"
  },
  {
    valueId: "EV016392",
    valueDesc: "Tap connector",
    label: "EV016392: Tap connector"
  },
  {
    valueId: "EV016393",
    valueDesc: "Hose connector",
    label: "EV016393: Hose connector"
  },
  {
    valueId: "EV016396",
    valueDesc: "Part with main air duct",
    label: "EV016396: Part with main air duct"
  },
  {
    valueId: "EV016397",
    valueDesc: "Part with branches air duct",
    label: "EV016397: Part with branches air duct"
  },
  {
    valueId: "EV016398",
    valueDesc: "Expanded polypropylene (EPP)",
    label: "EV016398: Expanded polypropylene (EPP)"
  },
  {
    valueId: "EV016399",
    valueDesc: "150/180 mm",
    label: "EV016399: 150/180 mm"
  },
  {
    valueId: "EV016400",
    valueDesc: "100/125 mm",
    label: "EV016400: 100/125 mm"
  },
  {
    valueId: "EV016401",
    valueDesc: "125/150/160/180 mm",
    label: "EV016401: 125/150/160/180 mm"
  },
  {
    valueId: "EV016402",
    valueDesc: "Varnish",
    label: "EV016402: Varnish"
  },
  {
    valueId: "EV016403",
    valueDesc: "Impregnation",
    label: "EV016403: Impregnation"
  },
  {
    valueId: "EV016404",
    valueDesc: "Completely matt",
    label: "EV016404: Completely matt"
  },
  {
    valueId: "EV016405",
    valueDesc: "Silicon emulsion",
    label: "EV016405: Silicon emulsion"
  },
  {
    valueId: "EV016406",
    valueDesc: "Silicate",
    label: "EV016406: Silicate"
  },
  {
    valueId: "EV016407",
    valueDesc: "Liquid and propellant",
    label: "EV016407: Liquid and propellant"
  },
  {
    valueId: "EV016408",
    valueDesc: "Ex ic",
    label: "EV016408: Ex ic"
  },
  {
    valueId: "EV016409",
    valueDesc: "Insert-/duct end",
    label: "EV016409: Insert-/duct end"
  },
  {
    valueId: "EV016410",
    valueDesc: "Expanded polyethylene (EPE)",
    label: "EV016410: Expanded polyethylene (EPE)"
  },
  {
    valueId: "EV016411",
    valueDesc: "Semi solid (B)",
    label: "EV016411: Semi solid (B)"
  },
  {
    valueId: "EV016412",
    valueDesc: "Hollow (H)",
    label: "EV016412: Hollow (H)"
  },
  {
    valueId: "EV016413",
    valueDesc: "Sinter (single)",
    label: "EV016413: Sinter (single)"
  },
  {
    valueId: "EV016414",
    valueDesc: "LN",
    label: "EV016414: LN"
  },
  {
    valueId: "EV016415",
    valueDesc: "EH",
    label: "EV016415: EH"
  },
  {
    valueId: "EV016416",
    valueDesc: "ES",
    label: "EV016416: ES"
  },
  {
    valueId: "EV016417",
    valueDesc: "AH",
    label: "EV016417: AH"
  },
  {
    valueId: "EV016418",
    valueDesc: "AS",
    label: "EV016418: AS"
  },
  {
    valueId: "EV016419",
    valueDesc: "AB",
    label: "EV016419: AB"
  },
  {
    valueId: "EV016420",
    valueDesc: "KH",
    label: "EV016420: KH"
  },
  {
    valueId: "EV016421",
    valueDesc: "Special hollow (N)",
    label: "EV016421: Special hollow (N)"
  },
  {
    valueId: "EV016422",
    valueDesc: "OD",
    label: "EV016422: OD"
  },
  {
    valueId: "EV016423",
    valueDesc: "HFD",
    label: "EV016423: HFD"
  },
  {
    valueId: "EV016424",
    valueDesc: "SD",
    label: "EV016424: SD"
  },
  {
    valueId: "EV016425",
    valueDesc: "SFD",
    label: "EV016425: SFD"
  },
  {
    valueId: "EV016426",
    valueDesc: "Sinter (double)",
    label: "EV016426: Sinter (double)"
  },
  {
    valueId: "EV016427",
    valueDesc: "Special solid (AR)",
    label: "EV016427: Special solid (AR)"
  },
  {
    valueId: "EV016428",
    valueDesc: "Solid (R)",
    label: "EV016428: Solid (R)"
  },
  {
    valueId: "EV016429",
    valueDesc: "Hollow (NS)",
    label: "EV016429: Hollow (NS)"
  },
  {
    valueId: "EV016430",
    valueDesc: "Semi solid (PLP)",
    label: "EV016430: Semi solid (PLP)"
  },
  {
    valueId: "EV016431",
    valueDesc: "Hollow (PL)",
    label: "EV016431: Hollow (PL)"
  },
  {
    valueId: "EV016432",
    valueDesc: "White base",
    label: "EV016432: White base"
  },
  {
    valueId: "EV016434",
    valueDesc: "A base (white)",
    label: "EV016434: A base (white)"
  },
  {
    valueId: "EV016436",
    valueDesc: "B base (white)",
    label: "EV016436: B base (white)"
  },
  {
    valueId: "EV016437",
    valueDesc: "C base (neutral)",
    label: "EV016437: C base (neutral)"
  },
  {
    valueId: "EV016445",
    valueDesc: "Deep grey base (coloured)",
    label: "EV016445: Deep grey base (coloured)"
  },
  {
    valueId: "EV016446",
    valueDesc: "Light grey base (coloured)",
    label: "EV016446: Light grey base (coloured)"
  },
  {
    valueId: "EV016447",
    valueDesc: "Medium grey base (coloured)",
    label: "EV016447: Medium grey base (coloured)"
  },
  {
    valueId: "EV016448",
    valueDesc: "Dark grey base (coloured)",
    label: "EV016448: Dark grey base (coloured)"
  },
  {
    valueId: "EV016449",
    valueDesc: "Yellow base (coloured)",
    label: "EV016449: Yellow base (coloured)"
  },
  {
    valueId: "EV016452",
    valueDesc: "Bright yellow base (coloured)",
    label: "EV016452: Bright yellow base (coloured)"
  },
  {
    valueId: "EV016453",
    valueDesc: "Golden yellow base (coloured)",
    label: "EV016453: Golden yellow base (coloured)"
  },
  {
    valueId: "EV016454",
    valueDesc: "Yellow orange base (coloured)",
    label: "EV016454: Yellow orange base (coloured)"
  },
  {
    valueId: "EV016455",
    valueDesc: "Orange base (coloured)",
    label: "EV016455: Orange base (coloured)"
  },
  {
    valueId: "EV016457",
    valueDesc: "Ochre base (coloured)",
    label: "EV016457: Ochre base (coloured)"
  },
  {
    valueId: "EV016458",
    valueDesc: "Oxide yellow base (coloured)",
    label: "EV016458: Oxide yellow base (coloured)"
  },
  {
    valueId: "EV016459",
    valueDesc: "Oxid red base (coloured)",
    label: "EV016459: Oxid red base (coloured)"
  },
  {
    valueId: "EV016461",
    valueDesc: "Red base (coloured)",
    label: "EV016461: Red base (coloured)"
  },
  {
    valueId: "EV016462",
    valueDesc: "Bordeaux base (coloured)",
    label: "EV016462: Bordeaux base (coloured)"
  },
  {
    valueId: "EV016463",
    valueDesc: "Green base (coloured)",
    label: "EV016463: Green base (coloured)"
  },
  {
    valueId: "EV016464",
    valueDesc: "Bright red base (coloured)",
    label: "EV016464: Bright red base (coloured)"
  },
  {
    valueId: "EV016471",
    valueDesc: "Clear base (neutral)",
    label: "EV016471: Clear base (neutral)"
  },
  {
    valueId: "EV016473",
    valueDesc: "Mixed colour",
    label: "EV016473: Mixed colour"
  },
  {
    valueId: "EV016475",
    valueDesc: "Uninsulated duct",
    label: "EV016475: Uninsulated duct"
  },
  {
    valueId: "EV016476",
    valueDesc: "Internally insulated duct",
    label: "EV016476: Internally insulated duct"
  },
  {
    valueId: "EV016477",
    valueDesc: "Externally insulated duct",
    label: "EV016477: Externally insulated duct"
  },
  {
    valueId: "EV016478",
    valueDesc: "Hard foam duct (full insulation)",
    label: "EV016478: Hard foam duct (full insulation)"
  },
  {
    valueId: "EV016479",
    valueDesc: "Double-walled duct",
    label: "EV016479: Double-walled duct"
  },
  {
    valueId: "EV016480",
    valueDesc: "Connector M10",
    label: "EV016480: Connector M10"
  },
  {
    valueId: "EV016481",
    valueDesc: "M42 x 2.0",
    label: "EV016481: M42 x 2.0"
  },
  {
    valueId: "EV016482",
    valueDesc: "M50 x 2.0",
    label: "EV016482: M50 x 2.0"
  },
  {
    valueId: "EV016483",
    valueDesc: "100/180 mm",
    label: "EV016483: 100/180 mm"
  },
  {
    valueId: "EV016484",
    valueDesc: "130/250 mm",
    label: "EV016484: 130/250 mm"
  },
  {
    valueId: "EV016485",
    valueDesc: "CuZn39Pb3 (CW617N)",
    label: "EV016485: CuZn39Pb3 (CW617N)"
  },
  {
    valueId: "EV016486",
    valueDesc: "CuSn4Zn2PS",
    label: "EV016486: CuSn4Zn2PS"
  },
  {
    valueId: "EV016487",
    valueDesc: "0.001 mg/l",
    label: "EV016487: 0.001 mg/l"
  },
  {
    valueId: "EV016488",
    valueDesc: "0.01 mg/l",
    label: "EV016488: 0.01 mg/l"
  },
  {
    valueId: "EV016489",
    valueDesc: "0.1 mg/l",
    label: "EV016489: 0.1 mg/l"
  },
  {
    valueId: "EV016490",
    valueDesc: "0.001 µS/cm",
    label: "EV016490: 0.001 µS/cm"
  },
  {
    valueId: "EV016491",
    valueDesc: "0.01 µS/cm",
    label: "EV016491: 0.01 µS/cm"
  },
  {
    valueId: "EV016492",
    valueDesc: "0.1 µS/cm",
    label: "EV016492: 0.1 µS/cm"
  },
  {
    valueId: "EV016493",
    valueDesc: "1 µS/cm",
    label: "EV016493: 1 µS/cm"
  },
  {
    valueId: "EV016498",
    valueDesc: "0.001",
    label: "EV016498: 0.001"
  },
  {
    valueId: "EV016501",
    valueDesc: "mm/s",
    label: "EV016501: mm/s"
  },
  {
    valueId: "EV016502",
    valueDesc: "Inch/s",
    label: "EV016502: Inch/s"
  },
  {
    valueId: "EV016503",
    valueDesc: "mm/s and inch/s",
    label: "EV016503: mm/s and inch/s"
  },
  {
    valueId: "EV016504",
    valueDesc: "Wing support",
    label: "EV016504: Wing support"
  },
  {
    valueId: "EV016505",
    valueDesc: "Silicon bronze",
    label: "EV016505: Silicon bronze"
  },
  {
    valueId: "EV016518",
    valueDesc: "E220 (1.0215)",
    label: "EV016518: E220 (1.0215)"
  },
  {
    valueId: "EV016520",
    valueDesc: "CuZn21Si3P (CC768S)",
    label: "EV016520: CuZn21Si3P (CC768S)"
  },
  {
    valueId: "EV016521",
    valueDesc: "CuZn21Si3P (CW724R)",
    label: "EV016521: CuZn21Si3P (CW724R)"
  },
  {
    valueId: "EV016522",
    valueDesc: "CuZn40Pb2 (CW617N)",
    label: "EV016522: CuZn40Pb2 (CW617N)"
  },
  {
    valueId: "EV016523",
    valueDesc: "CuZn36Pb-C (CC7770S)",
    label: "EV016523: CuZn36Pb-C (CC7770S)"
  },
  {
    valueId: "EV016524",
    valueDesc: "CuSi4Zn4MnP-C",
    label: "EV016524: CuSi4Zn4MnP-C"
  },
  {
    valueId: "EV016525",
    valueDesc: "CuSi4Zn9MnP-C",
    label: "EV016525: CuSi4Zn9MnP-C"
  },
  {
    valueId: "EV016526",
    valueDesc: "CuSi4Zn4MnP",
    label: "EV016526: CuSi4Zn4MnP"
  },
  {
    valueId: "EV016527",
    valueDesc: "CuSi4Zn9MnP",
    label: "EV016527: CuSi4Zn9MnP"
  },
  {
    valueId: "EV016528",
    valueDesc: "PP-M",
    label: "EV016528: PP-M"
  },
  {
    valueId: "EV016531",
    valueDesc: "Sliding sleeve",
    label: "EV016531: Sliding sleeve"
  },
  {
    valueId: "EV016533",
    valueDesc: "Eccentrically rectangular",
    label: "EV016533: Eccentrically rectangular"
  },
  {
    valueId: "EV016534",
    valueDesc: "UC 1",
    label: "EV016534: UC 1"
  },
  {
    valueId: "EV016535",
    valueDesc: "UC 2",
    label: "EV016535: UC 2"
  },
  {
    valueId: "EV016536",
    valueDesc: "UC 3",
    label: "EV016536: UC 3"
  },
  {
    valueId: "EV016537",
    valueDesc: "UC 4",
    label: "EV016537: UC 4"
  },
  {
    valueId: "EV016538",
    valueDesc: "UC 5",
    label: "EV016538: UC 5"
  },
  {
    valueId: "EV016539",
    valueDesc: "IPX2D",
    label: "EV016539: IPX2D"
  },
  {
    valueId: "EV016540",
    valueDesc: "Cross cabinet hinge",
    label: "EV016540: Cross cabinet hinge"
  },
  {
    valueId: "EV016541",
    valueDesc: "Stainless steel (1.4405)",
    label: "EV016541: Stainless steel (1.4405)"
  },
  {
    valueId: "EV016551",
    valueDesc: "DN 13",
    label: "EV016551: DN 13"
  },
  {
    valueId: "EV016557",
    valueDesc: "DN 26",
    label: "EV016557: DN 26"
  },
  {
    valueId: "EV016560",
    valueDesc: "m/s²",
    label: "EV016560: m/s²"
  },
  {
    valueId: "EV016561",
    valueDesc: "ft/s²",
    label: "EV016561: ft/s²"
  },
  {
    valueId: "EV016562",
    valueDesc: "m/s² and g",
    label: "EV016562: m/s² and g"
  },
  {
    valueId: "EV016563",
    valueDesc: "µm",
    label: "EV016563: µm"
  },
  {
    valueId: "EV016564",
    valueDesc: "Mils",
    label: "EV016564: Mils"
  },
  {
    valueId: "EV016565",
    valueDesc: "µm and mils",
    label: "EV016565: µm and mils"
  },
  {
    valueId: "EV016610",
    valueDesc: "Mushroom tappet",
    label: "EV016610: Mushroom tappet"
  },
  {
    valueId: "EV016612",
    valueDesc: "Keystone module",
    label: "EV016612: Keystone module"
  },
  {
    valueId: "EV016613",
    valueDesc: "Universal D-flange",
    label: "EV016613: Universal D-flange"
  },
  {
    valueId: "EV016616",
    valueDesc: "Three-dimensional",
    label: "EV016616: Three-dimensional"
  },
  {
    valueId: "EV016624",
    valueDesc: "PNR",
    label: "EV016624: PNR"
  },
  {
    valueId: "EV016630",
    valueDesc: "3xblack / 1xblue",
    label: "EV016630: 3xblack / 1xblue"
  },
  {
    valueId: "EV016631",
    valueDesc: "3xblack / 1xblue / 1xgreen/yellow",
    label: "EV016631: 3xblack / 1xblue / 1xgreen/yellow"
  },
  {
    valueId: "EV016632",
    valueDesc: "3xblack",
    label: "EV016632: 3xblack"
  },
  {
    valueId: "EV016640",
    valueDesc: "Lockable",
    label: "EV016640: Lockable"
  },
  {
    valueId: "EV016647",
    valueDesc: "Decorative groove",
    label: "EV016647: Decorative groove"
  },
  {
    valueId: "EV016652",
    valueDesc: "Parquet/laminate flooring",
    label: "EV016652: Parquet/laminate flooring"
  },
  {
    valueId: "EV016653",
    valueDesc: "Carpet/PVC-flooring",
    label: "EV016653: Carpet/PVC-flooring"
  },
  {
    valueId: "EV016655",
    valueDesc: "EN 343",
    label: "EV016655: EN 343"
  },
  {
    valueId: "EV016658",
    valueDesc: "Permanent",
    label: "EV016658: Permanent"
  },
  {
    valueId: "EV016659",
    valueDesc: "Temporary",
    label: "EV016659: Temporary"
  },
  {
    valueId: "EV016660",
    valueDesc: "Globe valve",
    label: "EV016660: Globe valve"
  },
  {
    valueId: "EV016672",
    valueDesc: "Level",
    label: "EV016672: Level"
  },
  {
    valueId: "EV016676",
    valueDesc: "Integrated compressor",
    label: "EV016676: Integrated compressor"
  },
  {
    valueId: "EV016677",
    valueDesc: "Manual/compressed air",
    label: "EV016677: Manual/compressed air"
  },
  {
    valueId: "EV016692",
    valueDesc: "Immersion tube/duct",
    label: "EV016692: Immersion tube/duct"
  },
  {
    valueId: "EV016694",
    valueDesc: "Eyelet",
    label: "EV016694: Eyelet"
  },
  {
    valueId: "EV016699",
    valueDesc: "Credit card",
    label: "EV016699: Credit card"
  },
  {
    valueId: "EV016700",
    valueDesc: "Visiting card",
    label: "EV016700: Visiting card"
  },
  {
    valueId: "EV016701",
    valueDesc: "Shackle lock",
    label: "EV016701: Shackle lock"
  },
  {
    valueId: "EV016702",
    valueDesc: "Folding lock",
    label: "EV016702: Folding lock"
  },
  {
    valueId: "EV016703",
    valueDesc: "Chain lock",
    label: "EV016703: Chain lock"
  },
  {
    valueId: "EV016704",
    valueDesc: "Cable lock",
    label: "EV016704: Cable lock"
  },
  {
    valueId: "EV016705",
    valueDesc: "Frame lock",
    label: "EV016705: Frame lock"
  },
  {
    valueId: "EV016706",
    valueDesc: "NDIR",
    label: "EV016706: NDIR"
  },
  {
    valueId: "EV016707",
    valueDesc: "Capacitive polymer sensor",
    label: "EV016707: Capacitive polymer sensor"
  },
  {
    valueId: "EV016711",
    valueDesc: "Exchangeable optic",
    label: "EV016711: Exchangeable optic"
  },
  {
    valueId: "EV016714",
    valueDesc: "Ni1000 TK5000",
    label: "EV016714: Ni1000 TK5000"
  },
  {
    valueId: "EV016725",
    valueDesc: "NTC, 1.8K",
    label: "EV016725: NTC, 1.8K"
  },
  {
    valueId: "EV016726",
    valueDesc: "NTC, 10K",
    label: "EV016726: NTC, 10K"
  },
  {
    valueId: "EV016727",
    valueDesc: "NTC, 5K",
    label: "EV016727: NTC, 5K"
  },
  {
    valueId: "EV016728",
    valueDesc: "NTC, 20K",
    label: "EV016728: NTC, 20K"
  },
  {
    valueId: "EV016729",
    valueDesc: "Weight fork",
    label: "EV016729: Weight fork"
  },
  {
    valueId: "EV016730",
    valueDesc: "Hook for spring scale",
    label: "EV016730: Hook for spring scale"
  },
  {
    valueId: "EV016731",
    valueDesc: "Clamp for spring scale",
    label: "EV016731: Clamp for spring scale"
  },
  {
    valueId: "EV016732",
    valueDesc: "Drag indicator",
    label: "EV016732: Drag indicator"
  },
  {
    valueId: "EV016733",
    valueDesc: "Bird weighing cone",
    label: "EV016733: Bird weighing cone"
  },
  {
    valueId: "EV016734",
    valueDesc: "Disc brush",
    label: "EV016734: Disc brush"
  },
  {
    valueId: "EV016735",
    valueDesc: "Cylinder brush",
    label: "EV016735: Cylinder brush"
  },
  {
    valueId: "EV016736",
    valueDesc: "CNC-controlled",
    label: "EV016736: CNC-controlled"
  },
  {
    valueId: "EV016737",
    valueDesc: "Cycle controlled",
    label: "EV016737: Cycle controlled"
  },
  {
    valueId: "EV016740",
    valueDesc: "Level controlled",
    label: "EV016740: Level controlled"
  },
  {
    valueId: "EV016741",
    valueDesc: "Time controlled",
    label: "EV016741: Time controlled"
  },
  {
    valueId: "EV016745",
    valueDesc: "IPX5B",
    label: "EV016745: IPX5B"
  },
  {
    valueId: "EV016746",
    valueDesc: "IP00B",
    label: "EV016746: IP00B"
  },
  {
    valueId: "EV016756",
    valueDesc: "Ram protection wall",
    label: "EV016756: Ram protection wall"
  },
  {
    valueId: "EV016757",
    valueDesc: "Pillar protection",
    label: "EV016757: Pillar protection"
  },
  {
    valueId: "EV016758",
    valueDesc: "U-ram protection",
    label: "EV016758: U-ram protection"
  },
  {
    valueId: "EV016762",
    valueDesc: "Case ground shelf",
    label: "EV016762: Case ground shelf"
  },
  {
    valueId: "EV016763",
    valueDesc: "Screw shelf",
    label: "EV016763: Screw shelf"
  },
  {
    valueId: "EV016764",
    valueDesc: "Plug shelf",
    label: "EV016764: Plug shelf"
  },
  {
    valueId: "EV016765",
    valueDesc: "Pallets shelf",
    label: "EV016765: Pallets shelf"
  },
  {
    valueId: "EV016766",
    valueDesc: "Cantilever shelf",
    label: "EV016766: Cantilever shelf"
  },
  {
    valueId: "EV016767",
    valueDesc: "Wide span shelf",
    label: "EV016767: Wide span shelf"
  },
  {
    valueId: "EV016770",
    valueDesc: "Round pipe",
    label: "EV016770: Round pipe"
  },
  {
    valueId: "EV016771",
    valueDesc: "Trapezoidal profile",
    label: "EV016771: Trapezoidal profile"
  },
  {
    valueId: "EV016772",
    valueDesc: "Square profile",
    label: "EV016772: Square profile"
  },
  {
    valueId: "EV016775",
    valueDesc: "Pointed nozzle",
    label: "EV016775: Pointed nozzle"
  },
  {
    valueId: "EV016776",
    valueDesc: "Superstructure post",
    label: "EV016776: Superstructure post"
  },
  {
    valueId: "EV016777",
    valueDesc: "Corner post",
    label: "EV016777: Corner post"
  },
  {
    valueId: "EV016778",
    valueDesc: "End post",
    label: "EV016778: End post"
  },
  {
    valueId: "EV016779",
    valueDesc: "Middle post",
    label: "EV016779: Middle post"
  },
  {
    valueId: "EV016780",
    valueDesc: "T-post",
    label: "EV016780: T-post"
  },
  {
    valueId: "EV016784",
    valueDesc: "Additional cooler",
    label: "EV016784: Additional cooler"
  },
  {
    valueId: "EV016787",
    valueDesc: "Craneable",
    label: "EV016787: Craneable"
  },
  {
    valueId: "EV016788",
    valueDesc: "Can be handled by forklift",
    label: "EV016788: Can be handled by forklift"
  },
  {
    valueId: "EV016789",
    valueDesc: "Can be handled by forklift/crane",
    label: "EV016789: Can be handled by forklift/crane"
  },
  {
    valueId: "EV016794",
    valueDesc: "In the distributor",
    label: "EV016794: In the distributor"
  },
  {
    valueId: "EV016795",
    valueDesc: "In the air outlet",
    label: "EV016795: In the air outlet"
  },
  {
    valueId: "EV016797",
    valueDesc: "Blasting hose",
    label: "EV016797: Blasting hose"
  },
  {
    valueId: "EV016799",
    valueDesc: "Brushes motor",
    label: "EV016799: Brushes motor"
  },
  {
    valueId: "EV016801",
    valueDesc: "EC-motor",
    label: "EV016801: EC-motor"
  },
  {
    valueId: "EV016805",
    valueDesc: "A4/A3 (ISO 216) across",
    label: "EV016805: A4/A3 (ISO 216) across"
  },
  {
    valueId: "EV016806",
    valueDesc: "A5/A4 (ISO 216) across",
    label: "EV016806: A5/A4 (ISO 216) across"
  },
  {
    valueId: "EV016807",
    valueDesc: "Dodecagonal",
    label: "EV016807: Dodecagonal"
  },
  {
    valueId: "EV016815",
    valueDesc: "EPDM/APTK",
    label: "EV016815: EPDM/APTK"
  },
  {
    valueId: "EV016816",
    valueDesc: "EPDM/PP",
    label: "EV016816: EPDM/PP"
  },
  {
    valueId: "EV016817",
    valueDesc: "SEBS",
    label: "EV016817: SEBS"
  },
  {
    valueId: "EV016818",
    valueDesc: "Acrylic paint",
    label: "EV016818: Acrylic paint"
  },
  {
    valueId: "EV016822",
    valueDesc: "P-tappet",
    label: "EV016822: P-tappet"
  },
  {
    valueId: "EV016823",
    valueDesc: "Placket tappet",
    label: "EV016823: Placket tappet"
  },
  {
    valueId: "EV016824",
    valueDesc: "V-tappet",
    label: "EV016824: V-tappet"
  },
  {
    valueId: "EV016825",
    valueDesc: "Constant",
    label: "EV016825: Constant"
  },
  {
    valueId: "EV016830",
    valueDesc: "Gap exhaust tappet",
    label: "EV016830: Gap exhaust tappet"
  },
  {
    valueId: "EV016831",
    valueDesc: "Furniture rack",
    label: "EV016831: Furniture rack"
  },
  {
    valueId: "EV016832",
    valueDesc: "Table frame",
    label: "EV016832: Table frame"
  },
  {
    valueId: "EV016836",
    valueDesc: "One-sided bevelled",
    label: "EV016836: One-sided bevelled"
  },
  {
    valueId: "EV016837",
    valueDesc: "Double-sided bevelled",
    label: "EV016837: Double-sided bevelled"
  },
  {
    valueId: "EV016839",
    valueDesc: "Step element",
    label: "EV016839: Step element"
  },
  {
    valueId: "EV016841",
    valueDesc: "Light",
    label: "EV016841: Light"
  },
  {
    valueId: "EV016850",
    valueDesc: "PVD-coated",
    label: "EV016850: PVD-coated"
  },
  {
    valueId: "EV016851",
    valueDesc: "Spherical",
    label: "EV016851: Spherical"
  },
  {
    valueId: "EV016852",
    valueDesc: "Brass ring",
    label: "EV016852: Brass ring"
  },
  {
    valueId: "EV016854",
    valueDesc: "Maintenance-free plain bearing technology",
    label: "EV016854: Maintenance-free plain bearing technology"
  },
  {
    valueId: "EV016855",
    valueDesc: "Clay",
    label: "EV016855: Clay"
  },
  {
    valueId: "EV016856",
    valueDesc: "Sidelocked",
    label: "EV016856: Sidelocked"
  },
  {
    valueId: "EV016859",
    valueDesc: "Adapter frame",
    label: "EV016859: Adapter frame"
  },
  {
    valueId: "EV016869",
    valueDesc: "Protective roof",
    label: "EV016869: Protective roof"
  },
  {
    valueId: "EV016870",
    valueDesc: "Protective cabin",
    label: "EV016870: Protective cabin"
  },
  {
    valueId: "EV016871",
    valueDesc: "Dustpan",
    label: "EV016871: Dustpan"
  },
  {
    valueId: "EV016872",
    valueDesc: "Throw",
    label: "EV016872: Throw"
  },
  {
    valueId: "EV016873",
    valueDesc: "Both wheels",
    label: "EV016873: Both wheels"
  },
  {
    valueId: "EV016874",
    valueDesc: "One wheel",
    label: "EV016874: One wheel"
  },
  {
    valueId: "EV016875",
    valueDesc: "Longitudinal divider",
    label: "EV016875: Longitudinal divider"
  },
  {
    valueId: "EV016876",
    valueDesc: "Transversal divider",
    label: "EV016876: Transversal divider"
  },
  {
    valueId: "EV016877",
    valueDesc: "Counter-rotated",
    label: "EV016877: Counter-rotated"
  },
  {
    valueId: "EV016878",
    valueDesc: "Synchronous",
    label: "EV016878: Synchronous"
  },
  {
    valueId: "EV016885",
    valueDesc: "Metric thread",
    label: "EV016885: Metric thread"
  },
  {
    valueId: "EV016886",
    valueDesc: "Double dowel",
    label: "EV016886: Double dowel"
  },
  {
    valueId: "EV016888",
    valueDesc: "Screw in dowel",
    label: "EV016888: Screw in dowel"
  },
  {
    valueId: "EV016889",
    valueDesc: "Ending dowel",
    label: "EV016889: Ending dowel"
  },
  {
    valueId: "EV016890",
    valueDesc: "Hinge dowel",
    label: "EV016890: Hinge dowel"
  },
  {
    valueId: "EV016891",
    valueDesc: "Fast mounting dowel",
    label: "EV016891: Fast mounting dowel"
  },
  {
    valueId: "EV016892",
    valueDesc: "Double sleeve",
    label: "EV016892: Double sleeve"
  },
  {
    valueId: "EV016893",
    valueDesc: "Spread sleeve",
    label: "EV016893: Spread sleeve"
  },
  {
    valueId: "EV016895",
    valueDesc: "Banister support",
    label: "EV016895: Banister support"
  },
  {
    valueId: "EV016897",
    valueDesc: "Screw set",
    label: "EV016897: Screw set"
  },
  {
    valueId: "EV016898",
    valueDesc: "Connection joint",
    label: "EV016898: Connection joint"
  },
  {
    valueId: "EV016899",
    valueDesc: "Connection angle",
    label: "EV016899: Connection angle"
  },
  {
    valueId: "EV016900",
    valueDesc: "Folding eyelet",
    label: "EV016900: Folding eyelet"
  },
  {
    valueId: "EV016901",
    valueDesc: "Spring box",
    label: "EV016901: Spring box"
  },
  {
    valueId: "EV016902",
    valueDesc: "Spring-screw acorn",
    label: "EV016902: Spring-screw acorn"
  },
  {
    valueId: "EV016904",
    valueDesc: "Staircase rod protection",
    label: "EV016904: Staircase rod protection"
  },
  {
    valueId: "EV016905",
    valueDesc: "40°",
    label: "EV016905: 40°"
  },
  {
    valueId: "EV016906",
    valueDesc: "Closing control",
    label: "EV016906: Closing control"
  },
  {
    valueId: "EV016907",
    valueDesc: "Special function",
    label: "EV016907: Special function"
  },
  {
    valueId: "EV016914",
    valueDesc: "Synthetics",
    label: "EV016914: Synthetics"
  },
  {
    valueId: "EV016935",
    valueDesc: "Wing rebate",
    label: "EV016935: Wing rebate"
  },
  {
    valueId: "EV016936",
    valueDesc: "Forend",
    label: "EV016936: Forend"
  },
  {
    valueId: "EV016937",
    valueDesc: "Flash-over",
    label: "EV016937: Flash-over"
  },
  {
    valueId: "EV016940",
    valueDesc: "Lockable bilateral",
    label: "EV016940: Lockable bilateral"
  },
  {
    valueId: "EV016942",
    valueDesc: "Lockable bilateral, flush exterior, with profile cylinder",
    label: "EV016942: Lockable bilateral, flush exterior, with profile cylinder"
  },
  {
    valueId: "EV016945",
    valueDesc: "Visibly screwed",
    label: "EV016945: Visibly screwed"
  },
  {
    valueId: "EV016946",
    valueDesc: "Invisibly screwed",
    label: "EV016946: Invisibly screwed"
  },
  {
    valueId: "EV016947",
    valueDesc: "Pusher left-displaying",
    label: "EV016947: Pusher left-displaying"
  },
  {
    valueId: "EV016948",
    valueDesc: "Pusher right-displaying",
    label: "EV016948: Pusher right-displaying"
  },
  {
    valueId: "EV016953",
    valueDesc: "CuZn38As",
    label: "EV016953: CuZn38As"
  },
  {
    valueId: "EV016954",
    valueDesc: "CuSn5Zn5Pb2-C-GC",
    label: "EV016954: CuSn5Zn5Pb2-C-GC"
  },
  {
    valueId: "EV016955",
    valueDesc: "CuSn12-C-GC",
    label: "EV016955: CuSn12-C-GC"
  },
  {
    valueId: "EV016956",
    valueDesc: "CuSn5Zn5Pb2-C-GS",
    label: "EV016956: CuSn5Zn5Pb2-C-GS"
  },
  {
    valueId: "EV016957",
    valueDesc: "CuZn36Pb-C-GM",
    label: "EV016957: CuZn36Pb-C-GM"
  },
  {
    valueId: "EV016958",
    valueDesc: "CuZn35Pb2Al-C-GM",
    label: "EV016958: CuZn35Pb2Al-C-GM"
  },
  {
    valueId: "EV016963",
    valueDesc: "Electromotive",
    label: "EV016963: Electromotive"
  },
  {
    valueId: "EV016964",
    valueDesc: "Electrothermal",
    label: "EV016964: Electrothermal"
  },
  {
    valueId: "EV016972",
    valueDesc: "M21 x 1.0",
    label: "EV016972: M21 x 1.0"
  },
  {
    valueId: "EV016982",
    valueDesc: "Hanging file folder organizer",
    label: "EV016982: Hanging file folder organizer"
  },
  {
    valueId: "EV016983",
    valueDesc: "Drawer partition",
    label: "EV016983: Drawer partition"
  },
  {
    valueId: "EV016993",
    valueDesc: "Filling shelf",
    label: "EV016993: Filling shelf"
  },
  {
    valueId: "EV016994",
    valueDesc: "Filling basket",
    label: "EV016994: Filling basket"
  },
  {
    valueId: "EV016995",
    valueDesc: "Spice rack",
    label: "EV016995: Spice rack"
  },
  {
    valueId: "EV016996",
    valueDesc: "Knife holder",
    label: "EV016996: Knife holder"
  },
  {
    valueId: "EV016997",
    valueDesc: "Rolls holder",
    label: "EV016997: Rolls holder"
  },
  {
    valueId: "EV016998",
    valueDesc: "Bag holder",
    label: "EV016998: Bag holder"
  },
  {
    valueId: "EV016999",
    valueDesc: "Rotating panel",
    label: "EV016999: Rotating panel"
  },
  {
    valueId: "EV017000",
    valueDesc: "Pedestal reinforcing ring",
    label: "EV017000: Pedestal reinforcing ring"
  },
  {
    valueId: "EV017001",
    valueDesc: "Round basket",
    label: "EV017001: Round basket"
  },
  {
    valueId: "EV017002",
    valueDesc: "Swivel arm",
    label: "EV017002: Swivel arm"
  },
  {
    valueId: "EV017003",
    valueDesc: "Table plate support",
    label: "EV017003: Table plate support"
  },
  {
    valueId: "EV017004",
    valueDesc: "Detent",
    label: "EV017004: Detent"
  },
  {
    valueId: "EV017005",
    valueDesc: "Triangular plate",
    label: "EV017005: Triangular plate"
  },
  {
    valueId: "EV017006",
    valueDesc: "Support bar",
    label: "EV017006: Support bar"
  },
  {
    valueId: "EV017007",
    valueDesc: "Frame angle",
    label: "EV017007: Frame angle"
  },
  {
    valueId: "EV017009",
    valueDesc: "Wheel locking device",
    label: "EV017009: Wheel locking device"
  },
  {
    valueId: "EV017010",
    valueDesc: "Total locking device",
    label: "EV017010: Total locking device"
  },
  {
    valueId: "EV017012",
    valueDesc: "Holding plate",
    label: "EV017012: Holding plate"
  },
  {
    valueId: "EV017020",
    valueDesc: "CAD support",
    label: "EV017020: CAD support"
  },
  {
    valueId: "EV017021",
    valueDesc: "Closing plan management",
    label: "EV017021: Closing plan management"
  },
  {
    valueId: "EV017022",
    valueDesc: "Key issue/-return",
    label: "EV017022: Key issue/-return"
  },
  {
    valueId: "EV017024",
    valueDesc: "Wall/wash basin",
    label: "EV017024: Wall/wash basin"
  },
  {
    valueId: "EV017025",
    valueDesc: "Key and handle",
    label: "EV017025: Key and handle"
  },
  {
    valueId: "EV017026",
    valueDesc: "Manually/remote control",
    label: "EV017026: Manually/remote control"
  },
  {
    valueId: "EV017033",
    valueDesc: "1 1/4 inch UNC",
    label: "EV017033: 1 1/4 inch UNC"
  },
  {
    valueId: "EV017034",
    valueDesc: "13 mm",
    label: "EV017034: 13 mm"
  },
  {
    valueId: "EV017035",
    valueDesc: "M23 x 1.5",
    label: "EV017035: M23 x 1.5"
  },
  {
    valueId: "EV017037",
    valueDesc: "Brass CW625N (CuZn35Pb1.5AlAs)",
    label: "EV017037: Brass CW625N (CuZn35Pb1.5AlAs)"
  },
  {
    valueId: "EV017039",
    valueDesc: "GRP GF65",
    label: "EV017039: GRP GF65"
  },
  {
    valueId: "EV017041",
    valueDesc: "DN 5",
    label: "EV017041: DN 5"
  },
  {
    valueId: "EV017043",
    valueDesc: "Bronze, leadfree",
    label: "EV017043: Bronze, leadfree"
  },
  {
    valueId: "EV017045",
    valueDesc: "PE-HD",
    label: "EV017045: PE-HD"
  },
  {
    valueId: "EV017046",
    valueDesc: "PE-LD",
    label: "EV017046: PE-LD"
  },
  {
    valueId: "EV017048",
    valueDesc: "Trumpet head",
    label: "EV017048: Trumpet head"
  },
  {
    valueId: "EV017049",
    valueDesc: "Immersion heater",
    label: "EV017049: Immersion heater"
  },
  {
    valueId: "EV017050",
    valueDesc: "Inside the light pole",
    label: "EV017050: Inside the light pole"
  },
  {
    valueId: "EV017056",
    valueDesc: "Adapter body clamp",
    label: "EV017056: Adapter body clamp"
  },
  {
    valueId: "EV017057",
    valueDesc: "Laundry",
    label: "EV017057: Laundry"
  },
  {
    valueId: "EV017060",
    valueDesc: "0.001 Ix",
    label: "EV017060: 0.001 Ix"
  },
  {
    valueId: "EV017061",
    valueDesc: "Multi adjuster",
    label: "EV017061: Multi adjuster"
  },
  {
    valueId: "EV017062",
    valueDesc: "Single microphone",
    label: "EV017062: Single microphone"
  },
  {
    valueId: "EV017063",
    valueDesc: "Microphone array",
    label: "EV017063: Microphone array"
  },
  {
    valueId: "EV017064",
    valueDesc: "USB-C",
    label: "EV017064: USB-C"
  },
  {
    valueId: "EV017065",
    valueDesc: "CH (SEV 1011 Typ 23) (type J)",
    label: "EV017065: CH (SEV 1011 Typ 23) (type J)"
  },
  {
    valueId: "EV017066",
    valueDesc: "Pressure piece for flanging tool",
    label: "EV017066: Pressure piece for flanging tool"
  },
  {
    valueId: "EV017067",
    valueDesc: "Compressive collet chuck",
    label: "EV017067: Compressive collet chuck"
  },
  {
    valueId: "EV017068",
    valueDesc: "Draw-in collet chuck",
    label: "EV017068: Draw-in collet chuck"
  },
  {
    valueId: "EV017069",
    valueDesc: "Counterbore holder",
    label: "EV017069: Counterbore holder"
  },
  {
    valueId: "EV017070",
    valueDesc: "Boring bar holder",
    label: "EV017070: Boring bar holder"
  },
  {
    valueId: "EV017071",
    valueDesc: "Knurling wheel",
    label: "EV017071: Knurling wheel"
  },
  {
    valueId: "EV017072",
    valueDesc: "Stair measuring tool",
    label: "EV017072: Stair measuring tool"
  },
  {
    valueId: "EV017073",
    valueDesc: "Sliding bevel",
    label: "EV017073: Sliding bevel"
  },
  {
    valueId: "EV017074",
    valueDesc: "Flange angle",
    label: "EV017074: Flange angle"
  },
  {
    valueId: "EV017075",
    valueDesc: "Centering flange",
    label: "EV017075: Centering flange"
  },
  {
    valueId: "EV017076",
    valueDesc: "Tm (statement manufacturer)",
    label: "EV017076: Tm (statement manufacturer)"
  },
  {
    valueId: "EV017077",
    valueDesc: "Rm (statement manufacturer)",
    label: "EV017077: Rm (statement manufacturer)"
  },
  {
    valueId: "EV017078",
    valueDesc: "T1 (between 0.4 x nominal mm and 3 mm)",
    label: "EV017078: T1 (between 0.4 x nominal mm and 3 mm)"
  },
  {
    valueId: "EV017079",
    valueDesc: "T2 (between 0.25 x nominal mm and 2 mm)",
    label: "EV017079: T2 (between 0.25 x nominal mm and 2 mm)"
  },
  {
    valueId: "EV017080",
    valueDesc: "D1 (±10 %)",
    label: "EV017080: D1 (±10 %)"
  },
  {
    valueId: "EV017081",
    valueDesc: "D2 (±5 %)",
    label: "EV017081: D2 (±5 %)"
  },
  {
    valueId: "EV017082",
    valueDesc: "Dm (statement manufacturer)",
    label: "EV017082: Dm (statement manufacturer)"
  },
  {
    valueId: "EV017083",
    valueDesc: "S0 (no requirements)",
    label: "EV017083: S0 (no requirements)"
  },
  {
    valueId: "EV017084",
    valueDesc: "S1 (<0.17 % Na+K, <0.08 % Mg)",
    label: "EV017084: S1 (<0.17 % Na+K, <0.08 % Mg)"
  },
  {
    valueId: "EV017085",
    valueDesc: "S2 (<0.06 % Na+K, <0.03 % Mg)",
    label: "EV017085: S2 (<0.06 % Na+K, <0.03 % Mg)"
  },
  {
    valueId: "EV017086",
    valueDesc: "R2 (0.3 x nominale size mm)",
    label: "EV017086: R2 (0.3 x nominale size mm)"
  },
  {
    valueId: "EV017087",
    valueDesc: "R1 (0.6 x nominal size mm)",
    label: "EV017087: R1 (0.6 x nominal size mm)"
  },
  {
    valueId: "EV017089",
    valueDesc: "Waterstruck",
    label: "EV017089: Waterstruck"
  },
  {
    valueId: "EV017090",
    valueDesc: "F1 (moderately frost resistant)",
    label: "EV017090: F1 (moderately frost resistant)"
  },
  {
    valueId: "EV017091",
    valueDesc: "F2 (frost-resistant)",
    label: "EV017091: F2 (frost-resistant)"
  },
  {
    valueId: "EV017092",
    valueDesc: "F0 (not frost-resistant)",
    label: "EV017092: F0 (not frost-resistant)"
  },
  {
    valueId: "EV017093",
    valueDesc: "Brush holder",
    label: "EV017093: Brush holder"
  },
  {
    valueId: "EV017094",
    valueDesc: "Bio-polyethylene foamed (foam Bio-PE)",
    label: "EV017094: Bio-polyethylene foamed (foam Bio-PE)"
  },
  {
    valueId: "EV017096",
    valueDesc: "Plastic/aluminium composite foil",
    label: "EV017096: Plastic/aluminium composite foil"
  },
  {
    valueId: "EV017097",
    valueDesc: "One side pre-slit",
    label: "EV017097: One side pre-slit"
  },
  {
    valueId: "EV017099",
    valueDesc: "0 (measuring circuits without a measurement category)",
    label: "EV017099: 0 (measuring circuits without a measurement category)"
  },
  {
    valueId: "EV017101",
    valueDesc: "Measuring cable",
    label: "EV017101: Measuring cable"
  },
  {
    valueId: "EV017102",
    valueDesc: "Water pump",
    label: "EV017102: Water pump"
  },
  {
    valueId: "EV017103",
    valueDesc: "Corkscrew",
    label: "EV017103: Corkscrew"
  },
  {
    valueId: "EV017104",
    valueDesc: "Grill fan",
    label: "EV017104: Grill fan"
  },
  {
    valueId: "EV017105",
    valueDesc: "Tar",
    label: "EV017105: Tar"
  },
  {
    valueId: "EV017106",
    valueDesc: "Pickled and passivated",
    label: "EV017106: Pickled and passivated"
  },
  {
    valueId: "EV017107",
    valueDesc: "22.5°",
    label: "EV017107: 22.5°"
  },
  {
    valueId: "EV017108",
    valueDesc: "5/10",
    label: "EV017108: 5/10"
  },
  {
    valueId: "EV017109",
    valueDesc: "EPDM rubber (foam EPDM)",
    label: "EV017109: EPDM rubber (foam EPDM)"
  },
  {
    valueId: "EV017110",
    valueDesc: "NBR rubber (foam NBR)",
    label: "EV017110: NBR rubber (foam NBR)"
  },
  {
    valueId: "EV017111",
    valueDesc: "Acrylonitrile styrene acrylate (ASA)",
    label: "EV017111: Acrylonitrile styrene acrylate (ASA)"
  },
  {
    valueId: "EV017113",
    valueDesc: "Room",
    label: "EV017113: Room"
  },
  {
    valueId: "EV017114",
    valueDesc: "Strap-on",
    label: "EV017114: Strap-on"
  },
  {
    valueId: "EV017115",
    valueDesc: "M/V",
    label: "EV017115: M/V"
  },
  {
    valueId: "EV017116",
    valueDesc: "Stainless steel duplex (1.4162)",
    label: "EV017116: Stainless steel duplex (1.4162)"
  },
  {
    valueId: "EV017117",
    valueDesc: "NTC, 10K-2",
    label: "EV017117: NTC, 10K-2"
  },
  {
    valueId: "EV017118",
    valueDesc: "NTC, 10K-3",
    label: "EV017118: NTC, 10K-3"
  },
  {
    valueId: "EV017120",
    valueDesc: "E-paper",
    label: "EV017120: E-paper"
  },
  {
    valueId: "EV017121",
    valueDesc: "Cassette (1-way)",
    label: "EV017121: Cassette (1-way)"
  },
  {
    valueId: "EV017122",
    valueDesc: "Cassette (4-way)",
    label: "EV017122: Cassette (4-way)"
  },
  {
    valueId: "EV017124",
    valueDesc: "Pull stud",
    label: "EV017124: Pull stud"
  },
  {
    valueId: "EV017129",
    valueDesc: "NTC, 30K",
    label: "EV017129: NTC, 30K"
  },
  {
    valueId: "EV017130",
    valueDesc: "NTC, 50K",
    label: "EV017130: NTC, 50K"
  },
  {
    valueId: "EV017131",
    valueDesc: "Shoe",
    label: "EV017131: Shoe"
  },
  {
    valueId: "EV017132",
    valueDesc: "Boot",
    label: "EV017132: Boot"
  },
  {
    valueId: "EV017135",
    valueDesc: "SRA",
    label: "EV017135: SRA"
  },
  {
    valueId: "EV017136",
    valueDesc: "SRB",
    label: "EV017136: SRB"
  },
  {
    valueId: "EV017137",
    valueDesc: "SRC",
    label: "EV017137: SRC"
  },
  {
    valueId: "EV017138",
    valueDesc: "S1P",
    label: "EV017138: S1P"
  },
  {
    valueId: "EV017143",
    valueDesc: "SB",
    label: "EV017143: SB"
  },
  {
    valueId: "EV017144",
    valueDesc: "IPX4B",
    label: "EV017144: IPX4B"
  },
  {
    valueId: "EV017145",
    valueDesc: "Periscope",
    label: "EV017145: Periscope"
  },
  {
    valueId: "EV017146",
    valueDesc: "Distributor/collector",
    label: "EV017146: Distributor/collector"
  },
  {
    valueId: "EV017148",
    valueDesc: "io-homecontrol",
    label: "EV017148: io-homecontrol"
  },
  {
    valueId: "EV017149",
    valueDesc: "Flat tile",
    label: "EV017149: Flat tile"
  },
  {
    valueId: "EV017150",
    valueDesc: "Self-controlling, mechanically",
    label: "EV017150: Self-controlling, mechanically"
  },
  {
    valueId: "EV017151",
    valueDesc: "Self-controlling, electronic",
    label: "EV017151: Self-controlling, electronic"
  },
  {
    valueId: "EV017152",
    valueDesc: "P1A",
    label: "EV017152: P1A"
  },
  {
    valueId: "EV017153",
    valueDesc: "P2A",
    label: "EV017153: P2A"
  },
  {
    valueId: "EV017154",
    valueDesc: "P3A",
    label: "EV017154: P3A"
  },
  {
    valueId: "EV017155",
    valueDesc: "P4A",
    label: "EV017155: P4A"
  },
  {
    valueId: "EV017156",
    valueDesc: "P5A",
    label: "EV017156: P5A"
  },
  {
    valueId: "EV017157",
    valueDesc: "RC 2",
    label: "EV017157: RC 2"
  },
  {
    valueId: "EV017158",
    valueDesc: "RC 3",
    label: "EV017158: RC 3"
  },
  {
    valueId: "EV017159",
    valueDesc: "RC 4",
    label: "EV017159: RC 4"
  },
  {
    valueId: "EV017160",
    valueDesc: "RC 5",
    label: "EV017160: RC 5"
  },
  {
    valueId: "EV017161",
    valueDesc: "Monobloc",
    label: "EV017161: Monobloc"
  },
  {
    valueId: "EV017163",
    valueDesc: "Lifting beam",
    label: "EV017163: Lifting beam"
  },
  {
    valueId: "EV017164",
    valueDesc: "Ergonomical",
    label: "EV017164: Ergonomical"
  },
  {
    valueId: "EV017166",
    valueDesc: "Elastically connected plates",
    label: "EV017166: Elastically connected plates"
  },
  {
    valueId: "EV017170",
    valueDesc: "Zircon corundum",
    label: "EV017170: Zircon corundum"
  },
  {
    valueId: "EV017171",
    valueDesc: "Electrical conversion set",
    label: "EV017171: Electrical conversion set"
  },
  {
    valueId: "EV017172",
    valueDesc: "Limit switching",
    label: "EV017172: Limit switching"
  },
  {
    valueId: "EV017173",
    valueDesc: "Mounting workstand",
    label: "EV017173: Mounting workstand"
  },
  {
    valueId: "EV017175",
    valueDesc: "Handhydraulic pump",
    label: "EV017175: Handhydraulic pump"
  },
  {
    valueId: "EV017176",
    valueDesc: "Chuck jaw for flaring tool",
    label: "EV017176: Chuck jaw for flaring tool"
  },
  {
    valueId: "EV017178",
    valueDesc: "Flat iron",
    label: "EV017178: Flat iron"
  },
  {
    valueId: "EV017186",
    valueDesc: "Drilling steel",
    label: "EV017186: Drilling steel"
  },
  {
    valueId: "EV017187",
    valueDesc: "Drill bit",
    label: "EV017187: Drill bit"
  },
  {
    valueId: "EV017188",
    valueDesc: "Boring bar",
    label: "EV017188: Boring bar"
  },
  {
    valueId: "EV017189",
    valueDesc: "Lathe tool",
    label: "EV017189: Lathe tool"
  },
  {
    valueId: "EV017191",
    valueDesc: "Extension",
    label: "EV017191: Extension"
  },
  {
    valueId: "EV017192",
    valueDesc: "2D sensor head",
    label: "EV017192: 2D sensor head"
  },
  {
    valueId: "EV017193",
    valueDesc: "Optical version",
    label: "EV017193: Optical version"
  },
  {
    valueId: "EV017202",
    valueDesc: "Blended gas",
    label: "EV017202: Blended gas"
  },
  {
    valueId: "EV017205",
    valueDesc: "Vernier",
    label: "EV017205: Vernier"
  },
  {
    valueId: "EV017206",
    valueDesc: "Clock",
    label: "EV017206: Clock"
  },
  {
    valueId: "EV017208",
    valueDesc: "All seasons",
    label: "EV017208: All seasons"
  },
  {
    valueId: "EV017209",
    valueDesc: "Thermo",
    label: "EV017209: Thermo"
  },
  {
    valueId: "EV017211",
    valueDesc: "Round pointed",
    label: "EV017211: Round pointed"
  },
  {
    valueId: "EV017212",
    valueDesc: "Round blunt",
    label: "EV017212: Round blunt"
  },
  {
    valueId: "EV017213",
    valueDesc: "Funnel shaped",
    label: "EV017213: Funnel shaped"
  },
  {
    valueId: "EV017215",
    valueDesc: "Wheelbarrow basin",
    label: "EV017215: Wheelbarrow basin"
  },
  {
    valueId: "EV017216",
    valueDesc: "Height adjuster",
    label: "EV017216: Height adjuster"
  },
  {
    valueId: "EV017218",
    valueDesc: "Drawer side set left/right",
    label: "EV017218: Drawer side set left/right"
  },
  {
    valueId: "EV017221",
    valueDesc: "ISO Coarse",
    label: "EV017221: ISO Coarse"
  },
  {
    valueId: "EV017222",
    valueDesc: "ISO ePM10",
    label: "EV017222: ISO ePM10"
  },
  {
    valueId: "EV017223",
    valueDesc: "ISO ePM2.5",
    label: "EV017223: ISO ePM2.5"
  },
  {
    valueId: "EV017224",
    valueDesc: "ISO ePM1",
    label: "EV017224: ISO ePM1"
  },
  {
    valueId: "EV017237",
    valueDesc: "Brush washing head",
    label: "EV017237: Brush washing head"
  },
  {
    valueId: "EV017238",
    valueDesc: "Drop-file extension",
    label: "EV017238: Drop-file extension"
  },
  {
    valueId: "EV017239",
    valueDesc: "Transport wheel",
    label: "EV017239: Transport wheel"
  },
  {
    valueId: "EV017241",
    valueDesc: "Single lens spectacles",
    label: "EV017241: Single lens spectacles"
  },
  {
    valueId: "EV017242",
    valueDesc: "Twin lens spectacles",
    label: "EV017242: Twin lens spectacles"
  },
  {
    valueId: "EV017244",
    valueDesc: "Cable transition",
    label: "EV017244: Cable transition"
  },
  {
    valueId: "EV017245",
    valueDesc: "Bolt switch contact",
    label: "EV017245: Bolt switch contact"
  },
  {
    valueId: "EV017246",
    valueDesc: "Phase bracket (flag)",
    label: "EV017246: Phase bracket (flag)"
  },
  {
    valueId: "EV017247",
    valueDesc: "Insulator bracket",
    label: "EV017247: Insulator bracket"
  },
  {
    valueId: "EV017248",
    valueDesc: "Pole clamp",
    label: "EV017248: Pole clamp"
  },
  {
    valueId: "EV017250",
    valueDesc: "Earthing material",
    label: "EV017250: Earthing material"
  },
  {
    valueId: "EV017251",
    valueDesc: "Barndoors for spotlight",
    label: "EV017251: Barndoors for spotlight"
  },
  {
    valueId: "EV017252",
    valueDesc: "GPS tracker",
    label: "EV017252: GPS tracker"
  },
  {
    valueId: "EV017256",
    valueDesc: "Wing chisel",
    label: "EV017256: Wing chisel"
  },
  {
    valueId: "EV017257",
    valueDesc: "Jointing chisel",
    label: "EV017257: Jointing chisel"
  },
  {
    valueId: "EV017258",
    valueDesc: "Hollow chisel",
    label: "EV017258: Hollow chisel"
  },
  {
    valueId: "EV017261",
    valueDesc: "Pole base reinforcing set",
    label: "EV017261: Pole base reinforcing set"
  },
  {
    valueId: "EV017262",
    valueDesc: "Phase/wire bracket",
    label: "EV017262: Phase/wire bracket"
  },
  {
    valueId: "EV017271",
    valueDesc: "Shower head for watering can",
    label: "EV017271: Shower head for watering can"
  },
  {
    valueId: "EV017272",
    valueDesc: "Stainless steel 1.4028 (AISI 420F)",
    label: "EV017272: Stainless steel 1.4028 (AISI 420F)"
  },
  {
    valueId: "EV017276",
    valueDesc: "Cast iron EN-GJL-HB175",
    label: "EV017276: Cast iron EN-GJL-HB175"
  },
  {
    valueId: "EV017277",
    valueDesc: "Bronze CuSn10-C",
    label: "EV017277: Bronze CuSn10-C"
  },
  {
    valueId: "EV017278",
    valueDesc: "Cast steel 1.0625",
    label: "EV017278: Cast steel 1.0625"
  },
  {
    valueId: "EV017280",
    valueDesc: "Stainless steel 304 (1.4308)",
    label: "EV017280: Stainless steel 304 (1.4308)"
  },
  {
    valueId: "EV017290",
    valueDesc: "LIN (Local Interconnect Network)",
    label: "EV017290: LIN (Local Interconnect Network)"
  },
  {
    valueId: "EV017292",
    valueDesc: "ASME B16.1",
    label: "EV017292: ASME B16.1"
  },
  {
    valueId: "EV017295",
    valueDesc: "EN ISO 1452-2",
    label: "EV017295: EN ISO 1452-2"
  },
  {
    valueId: "EV017296",
    valueDesc: "ISO 228-1",
    label: "EV017296: ISO 228-1"
  },
  {
    valueId: "EV017298",
    valueDesc: "EN 1092-1",
    label: "EV017298: EN 1092-1"
  },
  {
    valueId: "EV017299",
    valueDesc: "EN 1092-2",
    label: "EV017299: EN 1092-2"
  },
  {
    valueId: "EV017300",
    valueDesc: "ISO 7005-2",
    label: "EV017300: ISO 7005-2"
  },
  {
    valueId: "EV017301",
    valueDesc: "ISO 7-1 / EN 10226-1",
    label: "EV017301: ISO 7-1 / EN 10226-1"
  },
  {
    valueId: "EV017303",
    valueDesc: "IE4",
    label: "EV017303: IE4"
  },
  {
    valueId: "EV017304",
    valueDesc: "IE5",
    label: "EV017304: IE5"
  },
  {
    valueId: "EV017305",
    valueDesc: "Solid spindle",
    label: "EV017305: Solid spindle"
  },
  {
    valueId: "EV017306",
    valueDesc: "Split square spindle",
    label: "EV017306: Split square spindle"
  },
  {
    valueId: "EV017307",
    valueDesc: "Alternate square spindle",
    label: "EV017307: Alternate square spindle"
  },
  {
    valueId: "EV017308",
    valueDesc: "Frame for mesh grating",
    label: "EV017308: Frame for mesh grating"
  },
  {
    valueId: "EV017309",
    valueDesc: "Scissors spring",
    label: "EV017309: Scissors spring"
  },
  {
    valueId: "EV017311",
    valueDesc: "Armrest",
    label: "EV017311: Armrest"
  },
  {
    valueId: "EV017312",
    valueDesc: "Coin tray",
    label: "EV017312: Coin tray"
  },
  {
    valueId: "EV017313",
    valueDesc: "High-frequency welding",
    label: "EV017313: High-frequency welding"
  },
  {
    valueId: "EV017314",
    valueDesc: "Sew on",
    label: "EV017314: Sew on"
  },
  {
    valueId: "EV017315",
    valueDesc: "PN 12",
    label: "EV017315: PN 12"
  },
  {
    valueId: "EV017316",
    valueDesc: "PN 9",
    label: "EV017316: PN 9"
  },
  {
    valueId: "EV017317",
    valueDesc: "PN 7",
    label: "EV017317: PN 7"
  },
  {
    valueId: "EV017318",
    valueDesc: "Warning triangle",
    label: "EV017318: Warning triangle"
  },
  {
    valueId: "EV017319",
    valueDesc: "Traffic beacon",
    label: "EV017319: Traffic beacon"
  },
  {
    valueId: "EV017320",
    valueDesc: "Sign stand",
    label: "EV017320: Sign stand"
  },
  {
    valueId: "EV017321",
    valueDesc: "Sign post",
    label: "EV017321: Sign post"
  },
  {
    valueId: "EV017324",
    valueDesc: "-20 - 90 °C",
    label: "EV017324: -20 - 90 °C"
  },
  {
    valueId: "EV017326",
    valueDesc: "Cross for traffic signs",
    label: "EV017326: Cross for traffic signs"
  },
  {
    valueId: "EV017327",
    valueDesc: "Parking hoop",
    label: "EV017327: Parking hoop"
  },
  {
    valueId: "EV017328",
    valueDesc: "Safety barrier",
    label: "EV017328: Safety barrier"
  },
  {
    valueId: "EV017329",
    valueDesc: "Marking nail",
    label: "EV017329: Marking nail"
  },
  {
    valueId: "EV017330",
    valueDesc: "3/8 inch external thread",
    label: "EV017330: 3/8 inch external thread"
  },
  {
    valueId: "EV017331",
    valueDesc: "Mouthpiece",
    label: "EV017331: Mouthpiece"
  },
  {
    valueId: "EV017332",
    valueDesc: "R448a",
    label: "EV017332: R448a"
  },
  {
    valueId: "EV017333",
    valueDesc: "2.5d",
    label: "EV017333: 2.5d"
  },
  {
    valueId: "EV017335",
    valueDesc: "DN 42",
    label: "EV017335: DN 42"
  },
  {
    valueId: "EV017336",
    valueDesc: "DN 43",
    label: "EV017336: DN 43"
  },
  {
    valueId: "EV017337",
    valueDesc: "Slotted nozzle",
    label: "EV017337: Slotted nozzle"
  },
  {
    valueId: "EV017338",
    valueDesc: "Cutting nozzle",
    label: "EV017338: Cutting nozzle"
  },
  {
    valueId: "EV017339",
    valueDesc: "Welding nozzle",
    label: "EV017339: Welding nozzle"
  },
  {
    valueId: "EV017340",
    valueDesc: "Welding shoe",
    label: "EV017340: Welding shoe"
  },
  {
    valueId: "EV017341",
    valueDesc: "Angle nozzle",
    label: "EV017341: Angle nozzle"
  },
  {
    valueId: "EV017342",
    valueDesc: "Very fine",
    label: "EV017342: Very fine"
  },
  {
    valueId: "EV017343",
    valueDesc: "Cylindrical with pointed end",
    label: "EV017343: Cylindrical with pointed end"
  },
  {
    valueId: "EV017344",
    valueDesc: "Quick-change chuck (hexagonal)",
    label: "EV017344: Quick-change chuck (hexagonal)"
  },
  {
    valueId: "EV017345",
    valueDesc: "Full thread",
    label: "EV017345: Full thread"
  },
  {
    valueId: "EV017346",
    valueDesc: "Partial thread",
    label: "EV017346: Partial thread"
  },
  {
    valueId: "EV017349",
    valueDesc: "Frost protection valve",
    label: "EV017349: Frost protection valve"
  },
  {
    valueId: "EV017350",
    valueDesc: "Filing tray",
    label: "EV017350: Filing tray"
  },
  {
    valueId: "EV017351",
    valueDesc: "Magazine file",
    label: "EV017351: Magazine file"
  },
  {
    valueId: "EV017353",
    valueDesc: "400/690 V",
    label: "EV017353: 400/690 V"
  },
  {
    valueId: "EV017354",
    valueDesc: "8-pole",
    label: "EV017354: 8-pole"
  },
  {
    valueId: "EV017355",
    valueDesc: "12-pole",
    label: "EV017355: 12-pole"
  },
  {
    valueId: "EV017356",
    valueDesc: "2/4-pole",
    label: "EV017356: 2/4-pole"
  },
  {
    valueId: "EV017357",
    valueDesc: "4/8-pole",
    label: "EV017357: 4/8-pole"
  },
  {
    valueId: "EV017358",
    valueDesc: "6/12-pole",
    label: "EV017358: 6/12-pole"
  },
  {
    valueId: "EV017359",
    valueDesc: "6-pole",
    label: "EV017359: 6-pole"
  },
  {
    valueId: "EV017361",
    valueDesc: "Plastic grips",
    label: "EV017361: Plastic grips"
  },
  {
    valueId: "EV017362",
    valueDesc: "Panel-track curtain",
    label: "EV017362: Panel-track curtain"
  },
  {
    valueId: "EV017363",
    valueDesc: "Strip curtain",
    label: "EV017363: Strip curtain"
  },
  {
    valueId: "EV017364",
    valueDesc: "Loops",
    label: "EV017364: Loops"
  },
  {
    valueId: "EV017365",
    valueDesc: "Rail system",
    label: "EV017365: Rail system"
  },
  {
    valueId: "EV017366",
    valueDesc: "Double hexagon",
    label: "EV017366: Double hexagon"
  },
  {
    valueId: "EV017367",
    valueDesc: "Bar alarm",
    label: "EV017367: Bar alarm"
  },
  {
    valueId: "EV017368",
    valueDesc: "Adjustment profile",
    label: "EV017368: Adjustment profile"
  },
  {
    valueId: "EV017369",
    valueDesc: "Transition profile",
    label: "EV017369: Transition profile"
  },
  {
    valueId: "EV017373",
    valueDesc: "Net bandage",
    label: "EV017373: Net bandage"
  },
  {
    valueId: "EV017374",
    valueDesc: "First aid scissors",
    label: "EV017374: First aid scissors"
  },
  {
    valueId: "EV017376",
    valueDesc: "Adhesive plaster",
    label: "EV017376: Adhesive plaster"
  },
  {
    valueId: "EV017377",
    valueDesc: "Rescue blanket",
    label: "EV017377: Rescue blanket"
  },
  {
    valueId: "EV017378",
    valueDesc: "Patient stretcher",
    label: "EV017378: Patient stretcher"
  },
  {
    valueId: "EV017379",
    valueDesc: "Patient stretcher cabinet",
    label: "EV017379: Patient stretcher cabinet"
  },
  {
    valueId: "EV017380",
    valueDesc: "Emergency box for chemicals",
    label: "EV017380: Emergency box for chemicals"
  },
  {
    valueId: "EV017381",
    valueDesc: "First aid room couch",
    label: "EV017381: First aid room couch"
  },
  {
    valueId: "EV017382",
    valueDesc: "Plaster cabinet",
    label: "EV017382: Plaster cabinet"
  },
  {
    valueId: "EV017384",
    valueDesc: "Set clip/mounting plate",
    label: "EV017384: Set clip/mounting plate"
  },
  {
    valueId: "EV017386",
    valueDesc: "Worktop connector",
    label: "EV017386: Worktop connector"
  },
  {
    valueId: "EV017387",
    valueDesc: "Back panel connector",
    label: "EV017387: Back panel connector"
  },
  {
    valueId: "EV017388",
    valueDesc: "Trapezoid connecting fitting",
    label: "EV017388: Trapezoid connecting fitting"
  },
  {
    valueId: "EV017389",
    valueDesc: "Connecting screw",
    label: "EV017389: Connecting screw"
  },
  {
    valueId: "EV017390",
    valueDesc: "Polishing wax",
    label: "EV017390: Polishing wax"
  },
  {
    valueId: "EV017391",
    valueDesc: "Steel chips",
    label: "EV017391: Steel chips"
  },
  {
    valueId: "EV017392",
    valueDesc: "Polishing creme",
    label: "EV017392: Polishing creme"
  },
  {
    valueId: "EV017393",
    valueDesc: "Round nut",
    label: "EV017393: Round nut"
  },
  {
    valueId: "EV017394",
    valueDesc: "Top loader",
    label: "EV017394: Top loader"
  },
  {
    valueId: "EV017395",
    valueDesc: "Side loader",
    label: "EV017395: Side loader"
  },
  {
    valueId: "EV017396",
    valueDesc: "Sub loader",
    label: "EV017396: Sub loader"
  },
  {
    valueId: "EV017397",
    valueDesc: "Single shot",
    label: "EV017397: Single shot"
  },
  {
    valueId: "EV017398",
    valueDesc: "Contact releaser",
    label: "EV017398: Contact releaser"
  },
  {
    valueId: "EV017399",
    valueDesc: "Marking ring",
    label: "EV017399: Marking ring"
  },
  {
    valueId: "EV017400",
    valueDesc: "Key tag",
    label: "EV017400: Key tag"
  },
  {
    valueId: "EV017401",
    valueDesc: "Key chain",
    label: "EV017401: Key chain"
  },
  {
    valueId: "EV017402",
    valueDesc: "Adhesive tape dispenser",
    label: "EV017402: Adhesive tape dispenser"
  },
  {
    valueId: "EV017403",
    valueDesc: "Scraper",
    label: "EV017403: Scraper"
  },
  {
    valueId: "EV017404",
    valueDesc: "Deburrer",
    label: "EV017404: Deburrer"
  },
  {
    valueId: "EV017405",
    valueDesc: "Varnish stripper",
    label: "EV017405: Varnish stripper"
  },
  {
    valueId: "EV017407",
    valueDesc: "Flat nozzle",
    label: "EV017407: Flat nozzle"
  },
  {
    valueId: "EV017408",
    valueDesc: "Threaded nipple",
    label: "EV017408: Threaded nipple"
  },
  {
    valueId: "EV017409",
    valueDesc: "Ball valve",
    label: "EV017409: Ball valve"
  },
  {
    valueId: "EV017410",
    valueDesc: "Round nozzle",
    label: "EV017410: Round nozzle"
  },
  {
    valueId: "EV017413",
    valueDesc: "ANSI-CAT",
    label: "EV017413: ANSI-CAT"
  },
  {
    valueId: "EV017414",
    valueDesc: "Jacobs cone",
    label: "EV017414: Jacobs cone"
  },
  {
    valueId: "EV017415",
    valueDesc: "MAS 403 BT",
    label: "EV017415: MAS 403 BT"
  },
  {
    valueId: "EV017416",
    valueDesc: "MEK",
    label: "EV017416: MEK"
  },
  {
    valueId: "EV017417",
    valueDesc: "Inch thread",
    label: "EV017417: Inch thread"
  },
  {
    valueId: "EV017418",
    valueDesc: "Milling cutter arbor",
    label: "EV017418: Milling cutter arbor"
  },
  {
    valueId: "EV017419",
    valueDesc: "Processing head",
    label: "EV017419: Processing head"
  },
  {
    valueId: "EV017420",
    valueDesc: "Taper sleeve",
    label: "EV017420: Taper sleeve"
  },
  {
    valueId: "EV017421",
    valueDesc: "Spindle taper wiper",
    label: "EV017421: Spindle taper wiper"
  },
  {
    valueId: "EV017422",
    valueDesc: "Tip holder",
    label: "EV017422: Tip holder"
  },
  {
    valueId: "EV017423",
    valueDesc: "Quick change insert",
    label: "EV017423: Quick change insert"
  },
  {
    valueId: "EV017424",
    valueDesc: "Shrink extension",
    label: "EV017424: Shrink extension"
  },
  {
    valueId: "EV017425",
    valueDesc: "Adjustment sleeve basic holder",
    label: "EV017425: Adjustment sleeve basic holder"
  },
  {
    valueId: "EV017426",
    valueDesc: "Tool holder slug",
    label: "EV017426: Tool holder slug"
  },
  {
    valueId: "EV017427",
    valueDesc: "Intermediate sleeve",
    label: "EV017427: Intermediate sleeve"
  },
  {
    valueId: "EV017428",
    valueDesc: "Notches",
    label: "EV017428: Notches"
  },
  {
    valueId: "EV017429",
    valueDesc: "Knurled",
    label: "EV017429: Knurled"
  },
  {
    valueId: "EV017430",
    valueDesc: "Pen holder",
    label: "EV017430: Pen holder"
  },
  {
    valueId: "EV017431",
    valueDesc: "Chisel pointed",
    label: "EV017431: Chisel pointed"
  },
  {
    valueId: "EV017438",
    valueDesc: "HSS-PM",
    label: "EV017438: HSS-PM"
  },
  {
    valueId: "EV017439",
    valueDesc: "Morse taper",
    label: "EV017439: Morse taper"
  },
  {
    valueId: "EV017440",
    valueDesc: "Laundry bag",
    label: "EV017440: Laundry bag"
  },
  {
    valueId: "EV017441",
    valueDesc: "Throw-in slot",
    label: "EV017441: Throw-in slot"
  },
  {
    valueId: "EV017442",
    valueDesc: "Scissors anvil",
    label: "EV017442: Scissors anvil"
  },
  {
    valueId: "EV017443",
    valueDesc: "Screed shoe",
    label: "EV017443: Screed shoe"
  },
  {
    valueId: "EV017444",
    valueDesc: "Button strip",
    label: "EV017444: Button strip"
  },
  {
    valueId: "EV017445",
    valueDesc: "Jewellery",
    label: "EV017445: Jewellery"
  },
  {
    valueId: "EV017446",
    valueDesc: "M 45x3 (fire brigade) (EN 148-3)",
    label: "EV017446: M 45x3 (fire brigade) (EN 148-3)"
  },
  {
    valueId: "EV017447",
    valueDesc: "Rd 40 standard round thread (EN 148-1)",
    label: "EV017447: Rd 40 standard round thread (EN 148-1)"
  },
  {
    valueId: "EV017448",
    valueDesc: "Lifting device",
    label: "EV017448: Lifting device"
  },
  {
    valueId: "EV017449",
    valueDesc: "Digital display",
    label: "EV017449: Digital display"
  },
  {
    valueId: "EV017450",
    valueDesc: "Limit thread plug gauge",
    label: "EV017450: Limit thread plug gauge"
  },
  {
    valueId: "EV017451",
    valueDesc: "Measuring bridge",
    label: "EV017451: Measuring bridge"
  },
  {
    valueId: "EV017452",
    valueDesc: "Plastic pocket (with perforation)",
    label: "EV017452: Plastic pocket (with perforation)"
  },
  {
    valueId: "EV017453",
    valueDesc: "Clear plastic sheath",
    label: "EV017453: Clear plastic sheath"
  },
  {
    valueId: "EV017454",
    valueDesc: "Closure sleeve for strapping",
    label: "EV017454: Closure sleeve for strapping"
  },
  {
    valueId: "EV017455",
    valueDesc: "Locating plate",
    label: "EV017455: Locating plate"
  },
  {
    valueId: "EV017456",
    valueDesc: "Center point",
    label: "EV017456: Center point"
  },
  {
    valueId: "EV017457",
    valueDesc: "Suction head for core drill bit",
    label: "EV017457: Suction head for core drill bit"
  },
  {
    valueId: "EV017458",
    valueDesc: "Ejector spring for hole saw",
    label: "EV017458: Ejector spring for hole saw"
  },
  {
    valueId: "EV017459",
    valueDesc: "Balaclava",
    label: "EV017459: Balaclava"
  },
  {
    valueId: "EV017460",
    valueDesc: "Holding mandrel",
    label: "EV017460: Holding mandrel"
  },
  {
    valueId: "EV017461",
    valueDesc: "Clamping screw",
    label: "EV017461: Clamping screw"
  },
  {
    valueId: "EV017462",
    valueDesc: "Core cutter",
    label: "EV017462: Core cutter"
  },
  {
    valueId: "EV017463",
    valueDesc: "Centring pin",
    label: "EV017463: Centring pin"
  },
  {
    valueId: "EV017464",
    valueDesc: "Milling arbor wrench",
    label: "EV017464: Milling arbor wrench"
  },
  {
    valueId: "EV017466",
    valueDesc: "Stacking frame",
    label: "EV017466: Stacking frame"
  },
  {
    valueId: "EV017467",
    valueDesc: "Space frame",
    label: "EV017467: Space frame"
  },
  {
    valueId: "EV017468",
    valueDesc: "Carrier",
    label: "EV017468: Carrier"
  },
  {
    valueId: "EV017469",
    valueDesc: "Lock wire for rubbish sack",
    label: "EV017469: Lock wire for rubbish sack"
  },
  {
    valueId: "EV017470",
    valueDesc: "Holding ring",
    label: "EV017470: Holding ring"
  },
  {
    valueId: "EV017471",
    valueDesc: "Tubing post",
    label: "EV017471: Tubing post"
  },
  {
    valueId: "EV017472",
    valueDesc: "Bell ribbon",
    label: "EV017472: Bell ribbon"
  },
  {
    valueId: "EV017473",
    valueDesc: "Carbon steel",
    label: "EV017473: Carbon steel"
  },
  {
    valueId: "EV017474",
    valueDesc: "Pointed/pointed",
    label: "EV017474: Pointed/pointed"
  },
  {
    valueId: "EV017475",
    valueDesc: "Pointed/blunt",
    label: "EV017475: Pointed/blunt"
  },
  {
    valueId: "EV017476",
    valueDesc: "Blunt/blunt",
    label: "EV017476: Blunt/blunt"
  },
  {
    valueId: "EV017477",
    valueDesc: "Reverse cranked",
    label: "EV017477: Reverse cranked"
  },
  {
    valueId: "EV017479",
    valueDesc: "Flow cup",
    label: "EV017479: Flow cup"
  },
  {
    valueId: "EV017480",
    valueDesc: "Hanging cup",
    label: "EV017480: Hanging cup"
  },
  {
    valueId: "EV017482",
    valueDesc: "Drill holder key",
    label: "EV017482: Drill holder key"
  },
  {
    valueId: "EV017484",
    valueDesc: "Hollow spindle stop",
    label: "EV017484: Hollow spindle stop"
  },
  {
    valueId: "EV017485",
    valueDesc: "Pressure regulator",
    label: "EV017485: Pressure regulator"
  },
  {
    valueId: "EV017486",
    valueDesc: "Handle piece",
    label: "EV017486: Handle piece"
  },
  {
    valueId: "EV017487",
    valueDesc: "Hose burst protection",
    label: "EV017487: Hose burst protection"
  },
  {
    valueId: "EV017488",
    valueDesc: "Soldering oil",
    label: "EV017488: Soldering oil"
  },
  {
    valueId: "EV017489",
    valueDesc: "Center punch",
    label: "EV017489: Center punch"
  },
  {
    valueId: "EV017490",
    valueDesc: "Driving carrier",
    label: "EV017490: Driving carrier"
  },
  {
    valueId: "EV017491",
    valueDesc: "Toggle lever",
    label: "EV017491: Toggle lever"
  },
  {
    valueId: "EV017492",
    valueDesc: "Barrel end",
    label: "EV017492: Barrel end"
  },
  {
    valueId: "EV017493",
    valueDesc: "Pipe nib",
    label: "EV017493: Pipe nib"
  },
  {
    valueId: "EV017494",
    valueDesc: "Mounting arbor",
    label: "EV017494: Mounting arbor"
  },
  {
    valueId: "EV017495",
    valueDesc: "Driven",
    label: "EV017495: Driven"
  },
  {
    valueId: "EV017496",
    valueDesc: "Slave module",
    label: "EV017496: Slave module"
  },
  {
    valueId: "EV017499",
    valueDesc: "Bolt plate",
    label: "EV017499: Bolt plate"
  },
  {
    valueId: "EV017500",
    valueDesc: "Bolt loop",
    label: "EV017500: Bolt loop"
  },
  {
    valueId: "EV017501",
    valueDesc: "Rebate locking bolt",
    label: "EV017501: Rebate locking bolt"
  },
  {
    valueId: "EV017502",
    valueDesc: "Thermal wheel",
    label: "EV017502: Thermal wheel"
  },
  {
    valueId: "EV017503",
    valueDesc: "Outside hexagon with slot",
    label: "EV017503: Outside hexagon with slot"
  },
  {
    valueId: "EV017504",
    valueDesc: "Tungsten carbid",
    label: "EV017504: Tungsten carbid"
  },
  {
    valueId: "EV017505",
    valueDesc: "Counter current unit",
    label: "EV017505: Counter current unit"
  },
  {
    valueId: "EV017506",
    valueDesc: "Indoor and outdoor positioning",
    label: "EV017506: Indoor and outdoor positioning"
  },
  {
    valueId: "EV017507",
    valueDesc: "1-0",
    label: "EV017507: 1-0"
  },
  {
    valueId: "EV017508",
    valueDesc: "01-10",
    label: "EV017508: 01-10"
  },
  {
    valueId: "EV017509",
    valueDesc: "Colour code",
    label: "EV017509: Colour code"
  },
  {
    valueId: "EV017510",
    valueDesc: "LSA-Plus/screw",
    label: "EV017510: LSA-Plus/screw"
  },
  {
    valueId: "EV017511",
    valueDesc: "LSA Profile/screw",
    label: "EV017511: LSA Profile/screw"
  },
  {
    valueId: "EV017512",
    valueDesc: "110/screw",
    label: "EV017512: 110/screw"
  },
  {
    valueId: "EV017513",
    valueDesc: "Gate hinge",
    label: "EV017513: Gate hinge"
  },
  {
    valueId: "EV017514",
    valueDesc: "Gate support",
    label: "EV017514: Gate support"
  },
  {
    valueId: "EV017515",
    valueDesc: "Garden gate latch",
    label: "EV017515: Garden gate latch"
  },
  {
    valueId: "EV017518",
    valueDesc: "Stop for shutter",
    label: "EV017518: Stop for shutter"
  },
  {
    valueId: "EV017519",
    valueDesc: "Counterweight",
    label: "EV017519: Counterweight"
  },
  {
    valueId: "EV017520",
    valueDesc: "Carriage",
    label: "EV017520: Carriage"
  },
  {
    valueId: "EV017521",
    valueDesc: "Anti-lift device",
    label: "EV017521: Anti-lift device"
  },
  {
    valueId: "EV017522",
    valueDesc: "Latch locking",
    label: "EV017522: Latch locking"
  },
  {
    valueId: "EV017523",
    valueDesc: "Anti-slam device",
    label: "EV017523: Anti-slam device"
  },
  {
    valueId: "EV017524",
    valueDesc: "Cover rail",
    label: "EV017524: Cover rail"
  },
  {
    valueId: "EV017525",
    valueDesc: "Window grille",
    label: "EV017525: Window grille"
  },
  {
    valueId: "EV017526",
    valueDesc: "Closing angle",
    label: "EV017526: Closing angle"
  },
  {
    valueId: "EV017527",
    valueDesc: "Bump stop",
    label: "EV017527: Bump stop"
  },
  {
    valueId: "EV017528",
    valueDesc: "Guiding ring",
    label: "EV017528: Guiding ring"
  },
  {
    valueId: "EV017529",
    valueDesc: "Protection profile",
    label: "EV017529: Protection profile"
  },
  {
    valueId: "EV017530",
    valueDesc: "Protection roller blind",
    label: "EV017530: Protection roller blind"
  },
  {
    valueId: "EV017531",
    valueDesc: "Measuring wedge",
    label: "EV017531: Measuring wedge"
  },
  {
    valueId: "EV017532",
    valueDesc: "Tile wedge",
    label: "EV017532: Tile wedge"
  },
  {
    valueId: "EV017533",
    valueDesc: "Door wedge",
    label: "EV017533: Door wedge"
  },
  {
    valueId: "EV017535",
    valueDesc: "Column fixing",
    label: "EV017535: Column fixing"
  },
  {
    valueId: "EV017536",
    valueDesc: "Salammoniac",
    label: "EV017536: Salammoniac"
  },
  {
    valueId: "EV017538",
    valueDesc: "Barrel key",
    label: "EV017538: Barrel key"
  },
  {
    valueId: "EV017539",
    valueDesc: "Cover inner part",
    label: "EV017539: Cover inner part"
  },
  {
    valueId: "EV017540",
    valueDesc: "Adapter clamping arm",
    label: "EV017540: Adapter clamping arm"
  },
  {
    valueId: "EV017541",
    valueDesc: "Multi-angle pad",
    label: "EV017541: Multi-angle pad"
  },
  {
    valueId: "EV017542",
    valueDesc: "Zero setter",
    label: "EV017542: Zero setter"
  },
  {
    valueId: "EV017543",
    valueDesc: "Reducing bush",
    label: "EV017543: Reducing bush"
  },
  {
    valueId: "EV017544",
    valueDesc: "Adjustment bush",
    label: "EV017544: Adjustment bush"
  },
  {
    valueId: "EV017545",
    valueDesc: "Adjustment bush nut",
    label: "EV017545: Adjustment bush nut"
  },
  {
    valueId: "EV017546",
    valueDesc: "Extension bush",
    label: "EV017546: Extension bush"
  },
  {
    valueId: "EV017547",
    valueDesc: "Reducing insert",
    label: "EV017547: Reducing insert"
  },
  {
    valueId: "EV017548",
    valueDesc: "Depth stop",
    label: "EV017548: Depth stop"
  },
  {
    valueId: "EV017549",
    valueDesc: "Tank cleaner head",
    label: "EV017549: Tank cleaner head"
  },
  {
    valueId: "EV017550",
    valueDesc: "Steam pistol",
    label: "EV017550: Steam pistol"
  },
  {
    valueId: "EV017551",
    valueDesc: "Press for floor wiper",
    label: "EV017551: Press for floor wiper"
  },
  {
    valueId: "EV017552",
    valueDesc: "Cleaning pad",
    label: "EV017552: Cleaning pad"
  },
  {
    valueId: "EV017553",
    valueDesc: "Vacuum beam",
    label: "EV017553: Vacuum beam"
  },
  {
    valueId: "EV017554",
    valueDesc: "Wiping roller",
    label: "EV017554: Wiping roller"
  },
  {
    valueId: "EV017555",
    valueDesc: "Surface cleaner",
    label: "EV017555: Surface cleaner"
  },
  {
    valueId: "EV017556",
    valueDesc: "High-pressure gun",
    label: "EV017556: High-pressure gun"
  },
  {
    valueId: "EV017557",
    valueDesc: "Tyres",
    label: "EV017557: Tyres"
  },
  {
    valueId: "EV017559",
    valueDesc: "Micro fibre",
    label: "EV017559: Micro fibre"
  },
  {
    valueId: "EV017562",
    valueDesc: "Wing screw",
    label: "EV017562: Wing screw"
  },
  {
    valueId: "EV017563",
    valueDesc: "Socket outlet fixing plate",
    label: "EV017563: Socket outlet fixing plate"
  },
  {
    valueId: "EV017564",
    valueDesc: "Steel arbor",
    label: "EV017564: Steel arbor"
  },
  {
    valueId: "EV017565",
    valueDesc: "Chip protection",
    label: "EV017565: Chip protection"
  },
  {
    valueId: "EV017566",
    valueDesc: "Face plate",
    label: "EV017566: Face plate"
  },
  {
    valueId: "EV017567",
    valueDesc: "Lathe chuck jaw",
    label: "EV017567: Lathe chuck jaw"
  },
  {
    valueId: "EV017568",
    valueDesc: "Unscrewing device",
    label: "EV017568: Unscrewing device"
  },
  {
    valueId: "EV017569",
    valueDesc: "Clamp turn holder",
    label: "EV017569: Clamp turn holder"
  },
  {
    valueId: "EV017570",
    valueDesc: "Barcode scanner",
    label: "EV017570: Barcode scanner"
  },
  {
    valueId: "EV017571",
    valueDesc: "Release button",
    label: "EV017571: Release button"
  },
  {
    valueId: "EV017573",
    valueDesc: "TT-TN",
    label: "EV017573: TT-TN"
  },
  {
    valueId: "EV017574",
    valueDesc: "Insert case",
    label: "EV017574: Insert case"
  },
  {
    valueId: "EV017575",
    valueDesc: "Small parts magazine",
    label: "EV017575: Small parts magazine"
  },
  {
    valueId: "EV017576",
    valueDesc: "Pull-out stop",
    label: "EV017576: Pull-out stop"
  },
  {
    valueId: "EV017577",
    valueDesc: "Shelf divider",
    label: "EV017577: Shelf divider"
  },
  {
    valueId: "EV017578",
    valueDesc: "G 1 1/4 inch",
    label: "EV017578: G 1 1/4 inch"
  },
  {
    valueId: "EV017579",
    valueDesc: "H-1.5",
    label: "EV017579: H-1.5"
  },
  {
    valueId: "EV017580",
    valueDesc: "K-3",
    label: "EV017580: K-3"
  },
  {
    valueId: "EV017581",
    valueDesc: "L-15",
    label: "EV017581: L-15"
  },
  {
    valueId: "EV017582",
    valueDesc: "R-50",
    label: "EV017582: R-50"
  },
  {
    valueId: "EV017583",
    valueDesc: "M-125",
    label: "EV017583: M-125"
  },
  {
    valueId: "EV017584",
    valueDesc: "N-250",
    label: "EV017584: N-250"
  },
  {
    valueId: "EV017585",
    valueDesc: "P-400",
    label: "EV017585: P-400"
  },
  {
    valueId: "EV017586",
    valueDesc: "Riveted",
    label: "EV017586: Riveted"
  },
  {
    valueId: "EV017587",
    valueDesc: "Roulette",
    label: "EV017587: Roulette"
  },
  {
    valueId: "EV017588",
    valueDesc: "Fork extension",
    label: "EV017588: Fork extension"
  },
  {
    valueId: "EV017589",
    valueDesc: "Snow pusher",
    label: "EV017589: Snow pusher"
  },
  {
    valueId: "EV017590",
    valueDesc: "Telescopic handler",
    label: "EV017590: Telescopic handler"
  },
  {
    valueId: "EV017591",
    valueDesc: "Loading traverse",
    label: "EV017591: Loading traverse"
  },
  {
    valueId: "EV017592",
    valueDesc: "Embossing unit",
    label: "EV017592: Embossing unit"
  },
  {
    valueId: "EV017593",
    valueDesc: "Steel type holder",
    label: "EV017593: Steel type holder"
  },
  {
    valueId: "EV017594",
    valueDesc: "Steel types, single",
    label: "EV017594: Steel types, single"
  },
  {
    valueId: "EV017595",
    valueDesc: "Punch wheel",
    label: "EV017595: Punch wheel"
  },
  {
    valueId: "EV017596",
    valueDesc: "Hand lifter",
    label: "EV017596: Hand lifter"
  },
  {
    valueId: "EV017597",
    valueDesc: "Steel winch",
    label: "EV017597: Steel winch"
  },
  {
    valueId: "EV017598",
    valueDesc: "Bottle jack",
    label: "EV017598: Bottle jack"
  },
  {
    valueId: "EV017599",
    valueDesc: "Trolley jack",
    label: "EV017599: Trolley jack"
  },
  {
    valueId: "EV017600",
    valueDesc: "End frame safety extension",
    label: "EV017600: End frame safety extension"
  },
  {
    valueId: "EV017601",
    valueDesc: "Axle unit for cable drums",
    label: "EV017601: Axle unit for cable drums"
  },
  {
    valueId: "EV017602",
    valueDesc: "Axle for cable drum",
    label: "EV017602: Axle for cable drum"
  },
  {
    valueId: "EV017603",
    valueDesc: "Tie hanger",
    label: "EV017603: Tie hanger"
  },
  {
    valueId: "EV017604",
    valueDesc: "Diagonal cross",
    label: "EV017604: Diagonal cross"
  },
  {
    valueId: "EV017606",
    valueDesc: "Inser for bush hammer",
    label: "EV017606: Inser for bush hammer"
  },
  {
    valueId: "EV017607",
    valueDesc: "Impact head for face hammer",
    label: "EV017607: Impact head for face hammer"
  },
  {
    valueId: "EV017608",
    valueDesc: "Stick protection",
    label: "EV017608: Stick protection"
  },
  {
    valueId: "EV017609",
    valueDesc: "Tensioning edge",
    label: "EV017609: Tensioning edge"
  },
  {
    valueId: "EV017610",
    valueDesc: "Tension socket",
    label: "EV017610: Tension socket"
  },
  {
    valueId: "EV017611",
    valueDesc: "Cross support",
    label: "EV017611: Cross support"
  },
  {
    valueId: "EV017612",
    valueDesc: "Bolt stop",
    label: "EV017612: Bolt stop"
  },
  {
    valueId: "EV017613",
    valueDesc: "Roller chain",
    label: "EV017613: Roller chain"
  },
  {
    valueId: "EV017614",
    valueDesc: "Protective cap strip",
    label: "EV017614: Protective cap strip"
  },
  {
    valueId: "EV017615",
    valueDesc: "Water trap with back flow protection",
    label: "EV017615: Water trap with back flow protection"
  },
  {
    valueId: "EV017618",
    valueDesc: "Long goods",
    label: "EV017618: Long goods"
  },
  {
    valueId: "EV017619",
    valueDesc: "Ring goods",
    label: "EV017619: Ring goods"
  },
  {
    valueId: "EV017620",
    valueDesc: "Axle support",
    label: "EV017620: Axle support"
  },
  {
    valueId: "EV017621",
    valueDesc: "Lock washer",
    label: "EV017621: Lock washer"
  },
  {
    valueId: "EV017622",
    valueDesc: "Axle",
    label: "EV017622: Axle"
  },
  {
    valueId: "EV017623",
    valueDesc: "Adjusting ring",
    label: "EV017623: Adjusting ring"
  },
  {
    valueId: "EV017625",
    valueDesc: "Adiabatic",
    label: "EV017625: Adiabatic"
  },
  {
    valueId: "EV017628",
    valueDesc: "Mini-DVI",
    label: "EV017628: Mini-DVI"
  },
  {
    valueId: "EV017629",
    valueDesc: "Micro-DVI",
    label: "EV017629: Micro-DVI"
  },
  {
    valueId: "EV017630",
    valueDesc: "Thunderbolt",
    label: "EV017630: Thunderbolt"
  },
  {
    valueId: "EV017631",
    valueDesc: "Guiding pin",
    label: "EV017631: Guiding pin"
  },
  {
    valueId: "EV017632",
    valueDesc: "Free-lying and underground installation tube",
    label: "EV017632: Free-lying and underground installation tube"
  },
  {
    valueId: "EV017633",
    valueDesc: "Underground installation tube",
    label: "EV017633: Underground installation tube"
  },
  {
    valueId: "EV017634",
    valueDesc: "Finishing coat scraper",
    label: "EV017634: Finishing coat scraper"
  },
  {
    valueId: "EV017635",
    valueDesc: "Tile wash kit",
    label: "EV017635: Tile wash kit"
  },
  {
    valueId: "EV017636",
    valueDesc: "Joint straightener",
    label: "EV017636: Joint straightener"
  },
  {
    valueId: "EV017637",
    valueDesc: "Grid float",
    label: "EV017637: Grid float"
  },
  {
    valueId: "EV017638",
    valueDesc: "Rubbing board",
    label: "EV017638: Rubbing board"
  },
  {
    valueId: "EV017639",
    valueDesc: "Lay on for rubbing board",
    label: "EV017639: Lay on for rubbing board"
  },
  {
    valueId: "EV017640",
    valueDesc: "Length deburrer",
    label: "EV017640: Length deburrer"
  },
  {
    valueId: "EV017641",
    valueDesc: "Pipe deburrer",
    label: "EV017641: Pipe deburrer"
  },
  {
    valueId: "EV017642",
    valueDesc: "Cutting stand for angle grinder",
    label: "EV017642: Cutting stand for angle grinder"
  },
  {
    valueId: "EV017643",
    valueDesc: "Grinding disc dresser",
    label: "EV017643: Grinding disc dresser"
  },
  {
    valueId: "EV017644",
    valueDesc: "Drum lifter",
    label: "EV017644: Drum lifter"
  },
  {
    valueId: "EV017645",
    valueDesc: "Steering wheel",
    label: "EV017645: Steering wheel"
  },
  {
    valueId: "EV017646",
    valueDesc: "Bending lever",
    label: "EV017646: Bending lever"
  },
  {
    valueId: "EV017647",
    valueDesc: "Base body",
    label: "EV017647: Base body"
  },
  {
    valueId: "EV017648",
    valueDesc: "Motor filter",
    label: "EV017648: Motor filter"
  },
  {
    valueId: "EV017649",
    valueDesc: "Grinding dust filter",
    label: "EV017649: Grinding dust filter"
  },
  {
    valueId: "EV017650",
    valueDesc: "Permanent magnet",
    label: "EV017650: Permanent magnet"
  },
  {
    valueId: "EV017652",
    valueDesc: "Stripe/text",
    label: "EV017652: Stripe/text"
  },
  {
    valueId: "EV017653",
    valueDesc: "Filling tunnel",
    label: "EV017653: Filling tunnel"
  },
  {
    valueId: "EV017654",
    valueDesc: "Rack for filling tunnel",
    label: "EV017654: Rack for filling tunnel"
  },
  {
    valueId: "EV017655",
    valueDesc: "Connecting chain",
    label: "EV017655: Connecting chain"
  },
  {
    valueId: "EV017657",
    valueDesc: "Pre-applied adhesive",
    label: "EV017657: Pre-applied adhesive"
  },
  {
    valueId: "EV017658",
    valueDesc: "Telescopic drawer",
    label: "EV017658: Telescopic drawer"
  },
  {
    valueId: "EV017678",
    valueDesc: "R454c",
    label: "EV017678: R454c"
  },
  {
    valueId: "EV017679",
    valueDesc: "Separation pin",
    label: "EV017679: Separation pin"
  },
  {
    valueId: "EV017687",
    valueDesc: "Pop-up waste (click-clack)",
    label: "EV017687: Pop-up waste (click-clack)"
  },
  {
    valueId: "EV017692",
    valueDesc: "Antenna bracket",
    label: "EV017692: Antenna bracket"
  },
  {
    valueId: "EV017694",
    valueDesc: "Insert transition ring",
    label: "EV017694: Insert transition ring"
  },
  {
    valueId: "EV017695",
    valueDesc: "Cuff",
    label: "EV017695: Cuff"
  },
  {
    valueId: "EV017696",
    valueDesc: "Mounting kit for soil",
    label: "EV017696: Mounting kit for soil"
  },
  {
    valueId: "EV017697",
    valueDesc: "Mounting kit for rock",
    label: "EV017697: Mounting kit for rock"
  },
  {
    valueId: "EV017698",
    valueDesc: "Plate bracket",
    label: "EV017698: Plate bracket"
  },
  {
    valueId: "EV017701",
    valueDesc: "Cold tap water",
    label: "EV017701: Cold tap water"
  },
  {
    valueId: "EV017702",
    valueDesc: "Hot tap water",
    label: "EV017702: Hot tap water"
  },
  {
    valueId: "EV017704",
    valueDesc: "Rotary piston",
    label: "EV017704: Rotary piston"
  },
  {
    valueId: "EV017707",
    valueDesc: "OMS",
    label: "EV017707: OMS"
  },
  {
    valueId: "EV017708",
    valueDesc: "Cassette (2-way)",
    label: "EV017708: Cassette (2-way)"
  },
  {
    valueId: "EV017710",
    valueDesc: "Comfort insole",
    label: "EV017710: Comfort insole"
  },
  {
    valueId: "EV017711",
    valueDesc: "Support insole",
    label: "EV017711: Support insole"
  },
  {
    valueId: "EV017712",
    valueDesc: "Correction insole",
    label: "EV017712: Correction insole"
  },
  {
    valueId: "EV017713",
    valueDesc: "Low arch",
    label: "EV017713: Low arch"
  },
  {
    valueId: "EV017714",
    valueDesc: "Medium arch",
    label: "EV017714: Medium arch"
  },
  {
    valueId: "EV017715",
    valueDesc: "High arch",
    label: "EV017715: High arch"
  },
  {
    valueId: "EV017716",
    valueDesc: "Cascade spout",
    label: "EV017716: Cascade spout"
  },
  {
    valueId: "EV017718",
    valueDesc: "Underground",
    label: "EV017718: Underground"
  },
  {
    valueId: "EV017719",
    valueDesc: "Above-ground",
    label: "EV017719: Above-ground"
  },
  {
    valueId: "EV017721",
    valueDesc: "0.1S",
    label: "EV017721: 0.1S"
  },
  {
    valueId: "EV017725",
    valueDesc: "Head rest",
    label: "EV017725: Head rest"
  },
  {
    valueId: "EV017726",
    valueDesc: "Entry aid",
    label: "EV017726: Entry aid"
  },
  {
    valueId: "EV017727",
    valueDesc: "Tub anchor",
    label: "EV017727: Tub anchor"
  },
  {
    valueId: "EV017728",
    valueDesc: "Sound system",
    label: "EV017728: Sound system"
  },
  {
    valueId: "EV017729",
    valueDesc: "Extension coupler",
    label: "EV017729: Extension coupler"
  },
  {
    valueId: "EV017731",
    valueDesc: "T30",
    label: "EV017731: T30"
  },
  {
    valueId: "EV017732",
    valueDesc: "T50",
    label: "EV017732: T50"
  },
  {
    valueId: "EV017733",
    valueDesc: "T70",
    label: "EV017733: T70"
  },
  {
    valueId: "EV017734",
    valueDesc: "T90",
    label: "EV017734: T90"
  },
  {
    valueId: "EV017735",
    valueDesc: "T130",
    label: "EV017735: T130"
  },
  {
    valueId: "EV017736",
    valueDesc: "T180",
    label: "EV017736: T180"
  },
  {
    valueId: "EV017737",
    valueDesc: "T30/70",
    label: "EV017737: T30/70"
  },
  {
    valueId: "EV017738",
    valueDesc: "T30/90",
    label: "EV017738: T30/90"
  },
  {
    valueId: "EV017739",
    valueDesc: "T30/130",
    label: "EV017739: T30/130"
  },
  {
    valueId: "EV017740",
    valueDesc: "T30/180",
    label: "EV017740: T30/180"
  },
  {
    valueId: "EV017741",
    valueDesc: "Turbine wheel",
    label: "EV017741: Turbine wheel"
  },
  {
    valueId: "EV017742",
    valueDesc: "Fluid oscillation",
    label: "EV017742: Fluid oscillation"
  },
  {
    valueId: "EV017743",
    valueDesc: "Semi dry",
    label: "EV017743: Semi dry"
  },
  {
    valueId: "EV017745",
    valueDesc: "Prepared",
    label: "EV017745: Prepared"
  },
  {
    valueId: "EV017746",
    valueDesc: "Wired",
    label: "EV017746: Wired"
  },
  {
    valueId: "EV017747",
    valueDesc: "Radio 868 MHz",
    label: "EV017747: Radio 868 MHz"
  },
  {
    valueId: "EV017748",
    valueDesc: "Optical pulse",
    label: "EV017748: Optical pulse"
  },
  {
    valueId: "EV017749",
    valueDesc: "MiniBus",
    label: "EV017749: MiniBus"
  },
  {
    valueId: "EV017750",
    valueDesc: "LoRa",
    label: "EV017750: LoRa"
  },
  {
    valueId: "EV017751",
    valueDesc: "NB-IoT",
    label: "EV017751: NB-IoT"
  },
  {
    valueId: "EV017752",
    valueDesc: "DX51",
    label: "EV017752: DX51"
  },
  {
    valueId: "EV017753",
    valueDesc: "S250DG",
    label: "EV017753: S250DG"
  },
  {
    valueId: "EV017754",
    valueDesc: "S350DG",
    label: "EV017754: S350DG"
  },
  {
    valueId: "EV017755",
    valueDesc: "Z 100 g/m2",
    label: "EV017755: Z 100 g/m2"
  },
  {
    valueId: "EV017756",
    valueDesc: "Z 140 g/m2",
    label: "EV017756: Z 140 g/m2"
  },
  {
    valueId: "EV017757",
    valueDesc: "Z 275 g/m2",
    label: "EV017757: Z 275 g/m2"
  },
  {
    valueId: "EV017758",
    valueDesc: "ZM 310 g/m2",
    label: "EV017758: ZM 310 g/m2"
  },
  {
    valueId: "EV017759",
    valueDesc: "Indoor wall",
    label: "EV017759: Indoor wall"
  },
  {
    valueId: "EV017760",
    valueDesc: "NPD (No Performance Determined)",
    label: "EV017760: NPD (No Performance Determined)"
  },
  {
    valueId: "EV017761",
    valueDesc: "<=30 dB",
    label: "EV017761: <=30 dB"
  },
  {
    valueId: "EV017762",
    valueDesc: "<=35 dB",
    label: "EV017762: <=35 dB"
  },
  {
    valueId: "EV017763",
    valueDesc: "<40 dB",
    label: "EV017763: <40 dB"
  },
  {
    valueId: "EV017764",
    valueDesc: "Flexible hose",
    label: "EV017764: Flexible hose"
  },
  {
    valueId: "EV017765",
    valueDesc: "802.3bt (4PPoE)",
    label: "EV017765: 802.3bt (4PPoE)"
  },
  {
    valueId: "EV017766",
    valueDesc: "125/190 mm",
    label: "EV017766: 125/190 mm"
  },
  {
    valueId: "EV017767",
    valueDesc: "160/230 mm",
    label: "EV017767: 160/230 mm"
  },
  {
    valueId: "EV017768",
    valueDesc: "200/265 mm",
    label: "EV017768: 200/265 mm"
  },
  {
    valueId: "EV017769",
    valueDesc: "250/315 mm",
    label: "EV017769: 250/315 mm"
  },
  {
    valueId: "EV017770",
    valueDesc: "Vertical, drain valve down",
    label: "EV017770: Vertical, drain valve down"
  },
  {
    valueId: "EV017771",
    valueDesc: "Horizontal, drain valve down",
    label: "EV017771: Horizontal, drain valve down"
  },
  {
    valueId: "EV017772",
    valueDesc: "Separating position",
    label: "EV017772: Separating position"
  },
  {
    valueId: "EV017773",
    valueDesc: "Pass-through position",
    label: "EV017773: Pass-through position"
  },
  {
    valueId: "EV017774",
    valueDesc: "Lifting system",
    label: "EV017774: Lifting system"
  },
  {
    valueId: "EV017775",
    valueDesc: "Lifting system connecting device",
    label: "EV017775: Lifting system connecting device"
  },
  {
    valueId: "EV017776",
    valueDesc: "Lifting device controller",
    label: "EV017776: Lifting device controller"
  },
  {
    valueId: "EV017777",
    valueDesc: "Weather protection shield",
    label: "EV017777: Weather protection shield"
  },
  {
    valueId: "EV017778",
    valueDesc: "ASTM A536 Grade 65-45-12",
    label: "EV017778: ASTM A536 Grade 65-45-12"
  },
  {
    valueId: "EV017779",
    valueDesc: "ASTM A743 CF8",
    label: "EV017779: ASTM A743 CF8"
  },
  {
    valueId: "EV017780",
    valueDesc: "Valve spindle upper part",
    label: "EV017780: Valve spindle upper part"
  },
  {
    valueId: "EV017781",
    valueDesc: "CuZn36Pb3 (CW603N)",
    label: "EV017781: CuZn36Pb3 (CW603N)"
  },
  {
    valueId: "EV017782",
    valueDesc: "CuZn33Pb1.5AlAs (CW626N)",
    label: "EV017782: CuZn33Pb1.5AlAs (CW626N)"
  },
  {
    valueId: "EV017783",
    valueDesc: "3-pipe",
    label: "EV017783: 3-pipe"
  },
  {
    valueId: "EV017784",
    valueDesc: "4-pipe",
    label: "EV017784: 4-pipe"
  },
  {
    valueId: "EV017785",
    valueDesc: "Surface mounting/built-under",
    label: "EV017785: Surface mounting/built-under"
  },
  {
    valueId: "EV017786",
    valueDesc: "Type I",
    label: "EV017786: Type I"
  },
  {
    valueId: "EV017787",
    valueDesc: "Type II",
    label: "EV017787: Type II"
  },
  {
    valueId: "EV017788",
    valueDesc: "Type III",
    label: "EV017788: Type III"
  },
  {
    valueId: "EV017789",
    valueDesc: "Type IV",
    label: "EV017789: Type IV"
  },
  {
    valueId: "EV017792",
    valueDesc: "Up",
    label: "EV017792: Up"
  },
  {
    valueId: "EV017793",
    valueDesc: "On",
    label: "EV017793: On"
  },
  {
    valueId: "EV017794",
    valueDesc: "Off",
    label: "EV017794: Off"
  },
  {
    valueId: "EV017795",
    valueDesc: "Down",
    label: "EV017795: Down"
  },
  {
    valueId: "EV017796",
    valueDesc: "Close",
    label: "EV017796: Close"
  },
  {
    valueId: "EV017797",
    valueDesc: "Forward",
    label: "EV017797: Forward"
  },
  {
    valueId: "EV017798",
    valueDesc: "Reverse",
    label: "EV017798: Reverse"
  },
  {
    valueId: "EV017799",
    valueDesc: "Raise",
    label: "EV017799: Raise"
  },
  {
    valueId: "EV017800",
    valueDesc: "Lower",
    label: "EV017800: Lower"
  },
  {
    valueId: "EV017803",
    valueDesc: "Fast",
    label: "EV017803: Fast"
  },
  {
    valueId: "EV017804",
    valueDesc: "Slow",
    label: "EV017804: Slow"
  },
  {
    valueId: "EV017805",
    valueDesc: "Test",
    label: "EV017805: Test"
  },
  {
    valueId: "EV017806",
    valueDesc: "Running",
    label: "EV017806: Running"
  },
  {
    valueId: "EV017807",
    valueDesc: "Fault",
    label: "EV017807: Fault"
  },
  {
    valueId: "EV017808",
    valueDesc: "Reset",
    label: "EV017808: Reset"
  },
  {
    valueId: "EV017809",
    valueDesc: "Emergency Stop",
    label: "EV017809: Emergency Stop"
  },
  {
    valueId: "EV017810",
    valueDesc: "Main switch",
    label: "EV017810: Main switch"
  },
  {
    valueId: "EV017811",
    valueDesc: "USB/mains",
    label: "EV017811: USB/mains"
  },
  {
    valueId: "EV017812",
    valueDesc: "Fuel cap",
    label: "EV017812: Fuel cap"
  },
  {
    valueId: "EV017813",
    valueDesc: "Sounding pipe closure",
    label: "EV017813: Sounding pipe closure"
  },
  {
    valueId: "EV017814",
    valueDesc: "Ventilation hood",
    label: "EV017814: Ventilation hood"
  },
  {
    valueId: "EV017815",
    valueDesc: "Fuel level indicator",
    label: "EV017815: Fuel level indicator"
  },
  {
    valueId: "EV017816",
    valueDesc: "Limit value transmitter",
    label: "EV017816: Limit value transmitter"
  },
  {
    valueId: "EV017817",
    valueDesc: "Tank drain fitting",
    label: "EV017817: Tank drain fitting"
  },
  {
    valueId: "EV017818",
    valueDesc: "Flexible suction pipe",
    label: "EV017818: Flexible suction pipe"
  },
  {
    valueId: "EV017819",
    valueDesc: "Fluid protection",
    label: "EV017819: Fluid protection"
  },
  {
    valueId: "EV017820",
    valueDesc: "Stuffing gland",
    label: "EV017820: Stuffing gland"
  },
  {
    valueId: "EV017821",
    valueDesc: "Condensing vessel",
    label: "EV017821: Condensing vessel"
  },
  {
    valueId: "EV017822",
    valueDesc: "Leakage warning device",
    label: "EV017822: Leakage warning device"
  },
  {
    valueId: "EV017823",
    valueDesc: "Leakage probe",
    label: "EV017823: Leakage probe"
  },
  {
    valueId: "EV017824",
    valueDesc: "Base frame",
    label: "EV017824: Base frame"
  },
  {
    valueId: "EV017825",
    valueDesc: "Dispensing system",
    label: "EV017825: Dispensing system"
  },
  {
    valueId: "EV017826",
    valueDesc: "Buoyancy protection",
    label: "EV017826: Buoyancy protection"
  },
  {
    valueId: "EV017827",
    valueDesc: "Swedish telephone outlet",
    label: "EV017827: Swedish telephone outlet"
  },
  {
    valueId: "EV017828",
    valueDesc: "Reverse osmosis",
    label: "EV017828: Reverse osmosis"
  },
  {
    valueId: "EV017829",
    valueDesc: "Washing soda method",
    label: "EV017829: Washing soda method"
  },
  {
    valueId: "EV017830",
    valueDesc: "Stainless steel 420 (1.4021)",
    label: "EV017830: Stainless steel 420 (1.4021)"
  },
  {
    valueId: "EV017831",
    valueDesc: "Stainless steel 316 (1.4408)",
    label: "EV017831: Stainless steel 316 (1.4408)"
  },
  {
    valueId: "EV017833",
    valueDesc: "Spare tube",
    label: "EV017833: Spare tube"
  },
  {
    valueId: "EV017834",
    valueDesc: "Collector connection",
    label: "EV017834: Collector connection"
  },
  {
    valueId: "EV017835",
    valueDesc: "Cover element",
    label: "EV017835: Cover element"
  },
  {
    valueId: "EV017836",
    valueDesc: "Field compensator",
    label: "EV017836: Field compensator"
  },
  {
    valueId: "EV017837",
    valueDesc: "U-bend",
    label: "EV017837: U-bend"
  },
  {
    valueId: "EV017841",
    valueDesc: "Thermal insulation",
    label: "EV017841: Thermal insulation"
  },
  {
    valueId: "EV017845",
    valueDesc: "Cover rosette",
    label: "EV017845: Cover rosette"
  },
  {
    valueId: "EV017846",
    valueDesc: "Scale ring",
    label: "EV017846: Scale ring"
  },
  {
    valueId: "EV017850",
    valueDesc: "Diaphragm drive",
    label: "EV017850: Diaphragm drive"
  },
  {
    valueId: "EV017851",
    valueDesc: "Measuring and drainage unit",
    label: "EV017851: Measuring and drainage unit"
  },
  {
    valueId: "EV017853",
    valueDesc: "R+D ball valve",
    label: "EV017853: R+D ball valve"
  },
  {
    valueId: "EV017854",
    valueDesc: "Actuator adapter",
    label: "EV017854: Actuator adapter"
  },
  {
    valueId: "EV017855",
    valueDesc: "Flexible hose line",
    label: "EV017855: Flexible hose line"
  },
  {
    valueId: "EV017856",
    valueDesc: "Inlay",
    label: "EV017856: Inlay"
  },
  {
    valueId: "EV017857",
    valueDesc: "Blocking cap",
    label: "EV017857: Blocking cap"
  },
  {
    valueId: "EV017858",
    valueDesc: "Plug bolt",
    label: "EV017858: Plug bolt"
  },
  {
    valueId: "EV017859",
    valueDesc: "Overcurrent cap",
    label: "EV017859: Overcurrent cap"
  },
  {
    valueId: "EV017860",
    valueDesc: "Meter screw connection",
    label: "EV017860: Meter screw connection"
  },
  {
    valueId: "EV017861",
    valueDesc: "Allgas socket",
    label: "EV017861: Allgas socket"
  },
  {
    valueId: "EV017862",
    valueDesc: "Thermal shut-off device",
    label: "EV017862: Thermal shut-off device"
  },
  {
    valueId: "EV017865",
    valueDesc: "Filter sock",
    label: "EV017865: Filter sock"
  },
  {
    valueId: "EV017866",
    valueDesc: "Filter cup",
    label: "EV017866: Filter cup"
  },
  {
    valueId: "EV017867",
    valueDesc: "Oil filter",
    label: "EV017867: Oil filter"
  },
  {
    valueId: "EV017868",
    valueDesc: "Upper part for socket wrenches",
    label: "EV017868: Upper part for socket wrenches"
  },
  {
    valueId: "EV017869",
    valueDesc: "Connection tile",
    label: "EV017869: Connection tile"
  },
  {
    valueId: "EV017870",
    valueDesc: "Mansard gable tile",
    label: "EV017870: Mansard gable tile"
  },
  {
    valueId: "EV017871",
    valueDesc: "Bird nest tile",
    label: "EV017871: Bird nest tile"
  },
  {
    valueId: "EV017872",
    valueDesc: "Half roof tile",
    label: "EV017872: Half roof tile"
  },
  {
    valueId: "EV017873",
    valueDesc: "Whole roof tile",
    label: "EV017873: Whole roof tile"
  },
  {
    valueId: "EV017874",
    valueDesc: "Two-thirds roof tile",
    label: "EV017874: Two-thirds roof tile"
  },
  {
    valueId: "EV017875",
    valueDesc: "Three-quarter roof tile",
    label: "EV017875: Three-quarter roof tile"
  },
  {
    valueId: "EV017876",
    valueDesc: "One and a half roof tile",
    label: "EV017876: One and a half roof tile"
  },
  {
    valueId: "EV017877",
    valueDesc: "Two roof tiles",
    label: "EV017877: Two roof tiles"
  },
  {
    valueId: "EV017879",
    valueDesc: "Connection set heat meter",
    label: "EV017879: Connection set heat meter"
  },
  {
    valueId: "EV017882",
    valueDesc: "Copper soldered",
    label: "EV017882: Copper soldered"
  },
  {
    valueId: "EV017883",
    valueDesc: "Nickel soldered",
    label: "EV017883: Nickel soldered"
  },
  {
    valueId: "EV017885",
    valueDesc: "EPDM, aramid and inorganic fibres (NBR bound)",
    label: "EV017885: EPDM, aramid and inorganic fibres (NBR bound)"
  },
  {
    valueId: "EV017887",
    valueDesc: "Storage connection set",
    label: "EV017887: Storage connection set"
  },
  {
    valueId: "EV017888",
    valueDesc: "Exchange set",
    label: "EV017888: Exchange set"
  },
  {
    valueId: "EV017889",
    valueDesc: "Volume flow sensor",
    label: "EV017889: Volume flow sensor"
  },
  {
    valueId: "EV017890",
    valueDesc: "Circulation set",
    label: "EV017890: Circulation set"
  },
  {
    valueId: "EV017891",
    valueDesc: "Fitting or transition piece",
    label: "EV017891: Fitting or transition piece"
  },
  {
    valueId: "EV017892",
    valueDesc: "Back coating set",
    label: "EV017892: Back coating set"
  },
  {
    valueId: "EV017893",
    valueDesc: "Cascading set",
    label: "EV017893: Cascading set"
  },
  {
    valueId: "EV017894",
    valueDesc: "Flow regulator",
    label: "EV017894: Flow regulator"
  },
  {
    valueId: "EV017895",
    valueDesc: "Single strand",
    label: "EV017895: Single strand"
  },
  {
    valueId: "EV017896",
    valueDesc: "Single strand with return",
    label: "EV017896: Single strand with return"
  },
  {
    valueId: "EV017897",
    valueDesc: "Double strand",
    label: "EV017897: Double strand"
  },
  {
    valueId: "EV017898",
    valueDesc: "Sintered bronze",
    label: "EV017898: Sintered bronze"
  },
  {
    valueId: "EV017904",
    valueDesc: "Vortex",
    label: "EV017904: Vortex"
  },
  {
    valueId: "EV017905",
    valueDesc: "Sintered plastic",
    label: "EV017905: Sintered plastic"
  },
  {
    valueId: "EV017912",
    valueDesc: "Renovation frame",
    label: "EV017912: Renovation frame"
  },
  {
    valueId: "EV017913",
    valueDesc: "Ball valve connection set",
    label: "EV017913: Ball valve connection set"
  },
  {
    valueId: "EV017914",
    valueDesc: "Plug for temperature sensor",
    label: "EV017914: Plug for temperature sensor"
  },
  {
    valueId: "EV017915",
    valueDesc: "Temperature control set",
    label: "EV017915: Temperature control set"
  },
  {
    valueId: "EV017916",
    valueDesc: "Fitting piece",
    label: "EV017916: Fitting piece"
  },
  {
    valueId: "EV017917",
    valueDesc: "Flushing and mounting bridge",
    label: "EV017917: Flushing and mounting bridge"
  },
  {
    valueId: "EV017918",
    valueDesc: "Return temperature limiter set",
    label: "EV017918: Return temperature limiter set"
  },
  {
    valueId: "EV017919",
    valueDesc: "Conversion kit water meter",
    label: "EV017919: Conversion kit water meter"
  },
  {
    valueId: "EV017920",
    valueDesc: "Thermal insulation shell",
    label: "EV017920: Thermal insulation shell"
  },
  {
    valueId: "EV017921",
    valueDesc: "Carcass mounting-set",
    label: "EV017921: Carcass mounting-set"
  },
  {
    valueId: "EV017922",
    valueDesc: "Third round hip ridge",
    label: "EV017922: Third round hip ridge"
  },
  {
    valueId: "EV017923",
    valueDesc: "PP-RP",
    label: "EV017923: PP-RP"
  },
  {
    valueId: "EV017924",
    valueDesc: "PP-RCT",
    label: "EV017924: PP-RCT"
  },
  {
    valueId: "EV017925",
    valueDesc: "3-story",
    label: "EV017925: 3-story"
  },
  {
    valueId: "EV017926",
    valueDesc: "Bimetal (stainless steel/aluminium)",
    label: "EV017926: Bimetal (stainless steel/aluminium)"
  },
  {
    valueId: "EV017927",
    valueDesc: "Multi-chamber sealing profile",
    label: "EV017927: Multi-chamber sealing profile"
  },
  {
    valueId: "EV017928",
    valueDesc: "Door stop",
    label: "EV017928: Door stop"
  },
  {
    valueId: "EV017929",
    valueDesc: "Compress",
    label: "EV017929: Compress"
  },
  {
    valueId: "EV017930",
    valueDesc: "Threaded nut",
    label: "EV017930: Threaded nut"
  },
  {
    valueId: "EV017931",
    valueDesc: "Unlock",
    label: "EV017931: Unlock"
  },
  {
    valueId: "EV017932",
    valueDesc: "Pry off",
    label: "EV017932: Pry off"
  },
  {
    valueId: "EV017933",
    valueDesc: "Drain valve holder with flushing flow restrictor",
    label: "EV017933: Drain valve holder with flushing flow restrictor"
  },
  {
    valueId: "EV017934",
    valueDesc: "Drain valve set",
    label: "EV017934: Drain valve set"
  },
  {
    valueId: "EV017935",
    valueDesc: "Building protection plate",
    label: "EV017935: Building protection plate"
  },
  {
    valueId: "EV017937",
    valueDesc: "Filling hose",
    label: "EV017937: Filling hose"
  },
  {
    valueId: "EV017938",
    valueDesc: "Inflation valve set",
    label: "EV017938: Inflation valve set"
  },
  {
    valueId: "EV017939",
    valueDesc: "Revision shaft",
    label: "EV017939: Revision shaft"
  },
  {
    valueId: "EV017941",
    valueDesc: "Welding pod with open bottom",
    label: "EV017941: Welding pod with open bottom"
  },
  {
    valueId: "EV017942",
    valueDesc: "Welding pod with closed bottom",
    label: "EV017942: Welding pod with closed bottom"
  },
  {
    valueId: "EV017951",
    valueDesc: "Single segment",
    label: "EV017951: Single segment"
  },
  {
    valueId: "EV017952",
    valueDesc: "Ring segment",
    label: "EV017952: Ring segment"
  },
  {
    valueId: "EV017954",
    valueDesc: "Siphon detection (calorimetry)",
    label: "EV017954: Siphon detection (calorimetry)"
  },
  {
    valueId: "EV017955",
    valueDesc: "GL1",
    label: "EV017955: GL1"
  },
  {
    valueId: "EV017956",
    valueDesc: "GL3",
    label: "EV017956: GL3"
  },
  {
    valueId: "EV017957",
    valueDesc: "GL9",
    label: "EV017957: GL9"
  },
  {
    valueId: "EV017958",
    valueDesc: "GL11",
    label: "EV017958: GL11"
  },
  {
    valueId: "EV017959",
    valueDesc: "GL13",
    label: "EV017959: GL13"
  },
  {
    valueId: "EV017960",
    valueDesc: "GL15",
    label: "EV017960: GL15"
  },
  {
    valueId: "EV017961",
    valueDesc: "GL7",
    label: "EV017961: GL7"
  },
  {
    valueId: "EV017962",
    valueDesc: "GL18",
    label: "EV017962: GL18"
  },
  {
    valueId: "EV017963",
    valueDesc: "GLS1",
    label: "EV017963: GLS1"
  },
  {
    valueId: "EV017964",
    valueDesc: "GLS2",
    label: "EV017964: GLS2"
  },
  {
    valueId: "EV017965",
    valueDesc: "GLS3",
    label: "EV017965: GLS3"
  },
  {
    valueId: "EV017966",
    valueDesc: "GLS4",
    label: "EV017966: GLS4"
  },
  {
    valueId: "EV017967",
    valueDesc: "GLS5",
    label: "EV017967: GLS5"
  },
  {
    valueId: "EV017968",
    valueDesc: "GLS15",
    label: "EV017968: GLS15"
  },
  {
    valueId: "EV017969",
    valueDesc: "GLS24",
    label: "EV017969: GLS24"
  },
  {
    valueId: "EV017970",
    valueDesc: "GLS27",
    label: "EV017970: GLS27"
  },
  {
    valueId: "EV017971",
    valueDesc: "GLS47",
    label: "EV017971: GLS47"
  },
  {
    valueId: "EV017972",
    valueDesc: "GLS51",
    label: "EV017972: GLS51"
  },
  {
    valueId: "EV017973",
    valueDesc: "GLS52",
    label: "EV017973: GLS52"
  },
  {
    valueId: "EV017974",
    valueDesc: "GLS74",
    label: "EV017974: GLS74"
  },
  {
    valueId: "EV017975",
    valueDesc: "PP-MX",
    label: "EV017975: PP-MX"
  },
  {
    valueId: "EV017976",
    valueDesc: "PP-MD",
    label: "EV017976: PP-MD"
  },
  {
    valueId: "EV017978",
    valueDesc: "PE-S2",
    label: "EV017978: PE-S2"
  },
  {
    valueId: "EV017979",
    valueDesc: "Checkered flat head",
    label: "EV017979: Checkered flat head"
  },
  {
    valueId: "EV017980",
    valueDesc: "Headless",
    label: "EV017980: Headless"
  },
  {
    valueId: "EV017981",
    valueDesc: "Brad head",
    label: "EV017981: Brad head"
  },
  {
    valueId: "EV017982",
    valueDesc: "Casing head",
    label: "EV017982: Casing head"
  },
  {
    valueId: "EV017983",
    valueDesc: "Diamond head",
    label: "EV017983: Diamond head"
  },
  {
    valueId: "EV017984",
    valueDesc: "Umbrella head",
    label: "EV017984: Umbrella head"
  },
  {
    valueId: "EV017987",
    valueDesc: "Film",
    label: "EV017987: Film"
  },
  {
    valueId: "EV017988",
    valueDesc: "Butt joint",
    label: "EV017988: Butt joint"
  },
  {
    valueId: "EV017989",
    valueDesc: "Foil joint",
    label: "EV017989: Foil joint"
  },
  {
    valueId: "EV017990",
    valueDesc: "Overlapped joint",
    label: "EV017990: Overlapped joint"
  },
  {
    valueId: "EV017991",
    valueDesc: "Without grit zone",
    label: "EV017991: Without grit zone"
  },
  {
    valueId: "EV017992",
    valueDesc: "Grain tips sanded down",
    label: "EV017992: Grain tips sanded down"
  },
  {
    valueId: "EV017993",
    valueDesc: "Diagonal cut",
    label: "EV017993: Diagonal cut"
  },
  {
    valueId: "EV017994",
    valueDesc: "Sine joint",
    label: "EV017994: Sine joint"
  },
  {
    valueId: "EV017995",
    valueDesc: "Zig zag joint",
    label: "EV017995: Zig zag joint"
  },
  {
    valueId: "EV017996",
    valueDesc: "Star-shaped hole",
    label: "EV017996: Star-shaped hole"
  },
  {
    valueId: "EV017997",
    valueDesc: "X-Lock",
    label: "EV017997: X-Lock"
  },
  {
    valueId: "EV017998",
    valueDesc: "Metallic and non-metallic target",
    label: "EV017998: Metallic and non-metallic target"
  },
  {
    valueId: "EV018000",
    valueDesc: "M21 x 1.5",
    label: "EV018000: M21 x 1.5"
  },
  {
    valueId: "EV018002",
    valueDesc: "DN 0.8",
    label: "EV018002: DN 0.8"
  },
  {
    valueId: "EV018003",
    valueDesc: "DN 7",
    label: "EV018003: DN 7"
  },
  {
    valueId: "EV018004",
    valueDesc: "DN 17",
    label: "EV018004: DN 17"
  },
  {
    valueId: "EV018005",
    valueDesc: "2-pole pin connector",
    label: "EV018005: 2-pole pin connector"
  },
  {
    valueId: "EV018015",
    valueDesc: "3 stars and 1 eye",
    label: "EV018015: 3 stars and 1 eye"
  },
  {
    valueId: "EV018019",
    valueDesc: "Vulcanised fibre",
    label: "EV018019: Vulcanised fibre"
  },
  {
    valueId: "EV018020",
    valueDesc: "Extra coarse",
    label: "EV018020: Extra coarse"
  },
  {
    valueId: "EV018021",
    valueDesc: "Fineness coarse",
    label: "EV018021: Fineness coarse"
  },
  {
    valueId: "EV018022",
    valueDesc: "Fineness medium",
    label: "EV018022: Fineness medium"
  },
  {
    valueId: "EV018023",
    valueDesc: "Fineness fine",
    label: "EV018023: Fineness fine"
  },
  {
    valueId: "EV018024",
    valueDesc: "Fineness very fine",
    label: "EV018024: Fineness very fine"
  },
  {
    valueId: "EV018025",
    valueDesc: "Super fine",
    label: "EV018025: Super fine"
  },
  {
    valueId: "EV018026",
    valueDesc: "Fineness ultra fine",
    label: "EV018026: Fineness ultra fine"
  },
  {
    valueId: "EV018027",
    valueDesc: "Extra fine",
    label: "EV018027: Extra fine"
  },
  {
    valueId: "EV018028",
    valueDesc: "Non-abrasive",
    label: "EV018028: Non-abrasive"
  },
  {
    valueId: "EV018029",
    valueDesc: "Domestic water station",
    label: "EV018029: Domestic water station"
  },
  {
    valueId: "EV018030",
    valueDesc: "Blunt pointed",
    label: "EV018030: Blunt pointed"
  },
  {
    valueId: "EV018031",
    valueDesc: "Flat pointed",
    label: "EV018031: Flat pointed"
  },
  {
    valueId: "EV018032",
    valueDesc: "Clinch pointed",
    label: "EV018032: Clinch pointed"
  },
  {
    valueId: "EV018033",
    valueDesc: "Conical countersink shape 60°",
    label: "EV018033: Conical countersink shape 60°"
  },
  {
    valueId: "EV018034",
    valueDesc: "Conical countersink shape 90°",
    label: "EV018034: Conical countersink shape 90°"
  },
  {
    valueId: "EV018036",
    valueDesc: "FLA",
    label: "EV018036: FLA"
  },
  {
    valueId: "EV018037",
    valueDesc: "Locking bracket",
    label: "EV018037: Locking bracket"
  },
  {
    valueId: "EV018038",
    valueDesc: "Connection set for refill pump",
    label: "EV018038: Connection set for refill pump"
  },
  {
    valueId: "EV018039",
    valueDesc: "Ventilator cord",
    label: "EV018039: Ventilator cord"
  },
  {
    valueId: "EV018040",
    valueDesc: "Ventilator pot",
    label: "EV018040: Ventilator pot"
  },
  {
    valueId: "EV018041",
    valueDesc: "Controller insert block",
    label: "EV018041: Controller insert block"
  },
  {
    valueId: "EV018042",
    valueDesc: "Special cut INOX",
    label: "EV018042: Special cut INOX"
  },
  {
    valueId: "EV018043",
    valueDesc: "Special cut steel",
    label: "EV018043: Special cut steel"
  },
  {
    valueId: "EV018044",
    valueDesc: "Coarse single serration",
    label: "EV018044: Coarse single serration"
  },
  {
    valueId: "EV018066",
    valueDesc: "Edging strip",
    label: "EV018066: Edging strip"
  },
  {
    valueId: "EV018067",
    valueDesc: "Girder clamp",
    label: "EV018067: Girder clamp"
  },
  {
    valueId: "EV018068",
    valueDesc: "Joint sealing strap for IP4X conformity",
    label: "EV018068: Joint sealing strap for IP4X conformity"
  },
  {
    valueId: "EV018069",
    valueDesc: "Pin rack",
    label: "EV018069: Pin rack"
  },
  {
    valueId: "EV018070",
    valueDesc: "Pull switch adaptor",
    label: "EV018070: Pull switch adaptor"
  },
  {
    valueId: "EV018071",
    valueDesc: "Socket adaptor",
    label: "EV018071: Socket adaptor"
  },
  {
    valueId: "EV018072",
    valueDesc: "Suspension unit for luminaires",
    label: "EV018072: Suspension unit for luminaires"
  },
  {
    valueId: "EV018074",
    valueDesc: "Rolling mushroom tappet",
    label: "EV018074: Rolling mushroom tappet"
  },
  {
    valueId: "EV018075",
    valueDesc: "IP66K",
    label: "EV018075: IP66K"
  },
  {
    valueId: "EV018076",
    valueDesc: "Data/network technology",
    label: "EV018076: Data/network technology"
  },
  {
    valueId: "EV018077",
    valueDesc: "Class AB",
    label: "EV018077: Class AB"
  },
  {
    valueId: "EV018079",
    valueDesc: "Flat 5.0x0.8 mm",
    label: "EV018079: Flat 5.0x0.8 mm"
  },
  {
    valueId: "EV018080",
    valueDesc: "Flat 2.8x0.3 mm",
    label: "EV018080: Flat 2.8x0.3 mm"
  },
  {
    valueId: "EV018081",
    valueDesc: "Split nut",
    label: "EV018081: Split nut"
  },
  {
    valueId: "EV018082",
    valueDesc: "Polyolefin, mono-oriented",
    label: "EV018082: Polyolefin, mono-oriented"
  },
  {
    valueId: "EV018083",
    valueDesc: "Windows Server 2012",
    label: "EV018083: Windows Server 2012"
  },
  {
    valueId: "EV018084",
    valueDesc: "Cracked concrete",
    label: "EV018084: Cracked concrete"
  },
  {
    valueId: "EV018085",
    valueDesc: "Uncracked concrete",
    label: "EV018085: Uncracked concrete"
  },
  {
    valueId: "EV018086",
    valueDesc: "C1 and C2",
    label: "EV018086: C1 and C2"
  },
  {
    valueId: "EV018087",
    valueDesc: "ASIC",
    label: "EV018087: ASIC"
  },
  {
    valueId: "EV018088",
    valueDesc: "Service package",
    label: "EV018088: Service package"
  },
  {
    valueId: "EV018089",
    valueDesc: "EtherNet/IP",
    label: "EV018089: EtherNet/IP"
  },
  {
    valueId: "EV018091",
    valueDesc: "Full-wave control",
    label: "EV018091: Full-wave control"
  },
  {
    valueId: "EV018092",
    valueDesc: "Stainless steel X2CrMoTi29-4 (1.4592)",
    label: "EV018092: Stainless steel X2CrMoTi29-4 (1.4592)"
  },
  {
    valueId: "EV018093",
    valueDesc: "External (included)",
    label: "EV018093: External (included)"
  },
  {
    valueId: "EV018094",
    valueDesc: "External (not included)",
    label: "EV018094: External (not included)"
  },
  {
    valueId: "EV018095",
    valueDesc: "Regulated in fixed steps",
    label: "EV018095: Regulated in fixed steps"
  },
  {
    valueId: "EV018096",
    valueDesc: "Cleaning cartridge",
    label: "EV018096: Cleaning cartridge"
  },
  {
    valueId: "EV018097",
    valueDesc: "Boiler",
    label: "EV018097: Boiler"
  },
  {
    valueId: "EV018104",
    valueDesc: "Stainless steel 304 L (1.4307)",
    label: "EV018104: Stainless steel 304 L (1.4307)"
  },
  {
    valueId: "EV018105",
    valueDesc: "Stainless steel 304 LN (1.4311)",
    label: "EV018105: Stainless steel 304 LN (1.4311)"
  },
  {
    valueId: "EV018106",
    valueDesc: "Stainless steel 316 LN (1.4406)",
    label: "EV018106: Stainless steel 316 LN (1.4406)"
  },
  {
    valueId: "EV018107",
    valueDesc: "Stainless steel X1NiCrMoCuN25-20-7 (1.4529)",
    label: "EV018107: Stainless steel X1NiCrMoCuN25-20-7 (1.4529)"
  },
  {
    valueId: "EV018108",
    valueDesc: "Stainless steel 316 LN (1.4429)",
    label: "EV018108: Stainless steel 316 LN (1.4429)"
  },
  {
    valueId: "EV018109",
    valueDesc: "Stainless steel 316 (1.4436)",
    label: "EV018109: Stainless steel 316 (1.4436)"
  },
  {
    valueId: "EV018110",
    valueDesc: "Stainless steel 304 H (1.4948)",
    label: "EV018110: Stainless steel 304 H (1.4948)"
  },
  {
    valueId: "EV018111",
    valueDesc: "Railway circuit protection",
    label: "EV018111: Railway circuit protection"
  },
  {
    valueId: "EV018113",
    valueDesc: "Connection cover",
    label: "EV018113: Connection cover"
  },
  {
    valueId: "EV018118",
    valueDesc: "Construction protection",
    label: "EV018118: Construction protection"
  },
  {
    valueId: "EV018120",
    valueDesc: "Extension set",
    label: "EV018120: Extension set"
  },
  {
    valueId: "EV018121",
    valueDesc: "S1 25 %",
    label: "EV018121: S1 25 %"
  },
  {
    valueId: "EV018122",
    valueDesc: "S1/S3 15 %",
    label: "EV018122: S1/S3 15 %"
  },
  {
    valueId: "EV018123",
    valueDesc: "S1/S3 20 %",
    label: "EV018123: S1/S3 20 %"
  },
  {
    valueId: "EV018124",
    valueDesc: "S1/S3 60 %",
    label: "EV018124: S1/S3 60 %"
  },
  {
    valueId: "EV018125",
    valueDesc: "S2 10 min",
    label: "EV018125: S2 10 min"
  },
  {
    valueId: "EV018126",
    valueDesc: "S2 15 min",
    label: "EV018126: S2 15 min"
  },
  {
    valueId: "EV018127",
    valueDesc: "S2 30 min",
    label: "EV018127: S2 30 min"
  },
  {
    valueId: "EV018128",
    valueDesc: "S3 10 %",
    label: "EV018128: S3 10 %"
  },
  {
    valueId: "EV018129",
    valueDesc: "S3 15 %",
    label: "EV018129: S3 15 %"
  },
  {
    valueId: "EV018130",
    valueDesc: "S3 15 % 80 sec",
    label: "EV018130: S3 15 % 80 sec"
  },
  {
    valueId: "EV018131",
    valueDesc: "S3 25 %",
    label: "EV018131: S3 25 %"
  },
  {
    valueId: "EV018132",
    valueDesc: "S3 28 % 36 sec",
    label: "EV018132: S3 28 % 36 sec"
  },
  {
    valueId: "EV018133",
    valueDesc: "S3/S1",
    label: "EV018133: S3/S1"
  },
  {
    valueId: "EV018134",
    valueDesc: "S3 30 %",
    label: "EV018134: S3 30 %"
  },
  {
    valueId: "EV018135",
    valueDesc: "Auto-transformer starter",
    label: "EV018135: Auto-transformer starter"
  },
  {
    valueId: "EV018136",
    valueDesc: "Soft starter",
    label: "EV018136: Soft starter"
  },
  {
    valueId: "EV018137",
    valueDesc: "Variable-frequency drive (VFD)",
    label: "EV018137: Variable-frequency drive (VFD)"
  },
  {
    valueId: "EV018138",
    valueDesc: "Protective grounding",
    label: "EV018138: Protective grounding"
  },
  {
    valueId: "EV018139",
    valueDesc: "Neutral wire",
    label: "EV018139: Neutral wire"
  },
  {
    valueId: "EV018140",
    valueDesc: "Neutral wire, protective earthing",
    label: "EV018140: Neutral wire, protective earthing"
  },
  {
    valueId: "EV018141",
    valueDesc: "Extension cable adapter",
    label: "EV018141: Extension cable adapter"
  },
  {
    valueId: "EV018143",
    valueDesc: "Battery compartment",
    label: "EV018143: Battery compartment"
  },
  {
    valueId: "EV018144",
    valueDesc: "C-Bus",
    label: "EV018144: C-Bus"
  },
  {
    valueId: "EV018146",
    valueDesc: "Button integrated in front of basin",
    label: "EV018146: Button integrated in front of basin"
  },
  {
    valueId: "EV018147",
    valueDesc: "Cable box",
    label: "EV018147: Cable box"
  },
  {
    valueId: "EV018152",
    valueDesc: "IP1X",
    label: "EV018152: IP1X"
  },
  {
    valueId: "EV018163",
    valueDesc: "Stand-Alone",
    label: "EV018163: Stand-Alone"
  },
  {
    valueId: "EV018164",
    valueDesc: "With evaluation software",
    label: "EV018164: With evaluation software"
  },
  {
    valueId: "EV018165",
    valueDesc: "Combustible gas and hydrocarbon",
    label: "EV018165: Combustible gas and hydrocarbon"
  },
  {
    valueId: "EV018166",
    valueDesc: "Heating and air conditioning",
    label: "EV018166: Heating and air conditioning"
  },
  {
    valueId: "EV018167",
    valueDesc: "Gas and chemicals",
    label: "EV018167: Gas and chemicals"
  },
  {
    valueId: "EV018168",
    valueDesc: "Sanitation and storm water",
    label: "EV018168: Sanitation and storm water"
  },
  {
    valueId: "EV018169",
    valueDesc: ">=200 N",
    label: "EV018169: >=200 N"
  },
  {
    valueId: "EV018170",
    valueDesc: ">=300 N",
    label: "EV018170: >=300 N"
  },
  {
    valueId: "EV018171",
    valueDesc: "Self-drilling",
    label: "EV018171: Self-drilling"
  },
  {
    valueId: "EV018172",
    valueDesc: "Continuously galvanized zinc-magnesium",
    label: "EV018172: Continuously galvanized zinc-magnesium"
  },
  {
    valueId: "EV018173",
    valueDesc: "Mixed heating circuit",
    label: "EV018173: Mixed heating circuit"
  },
  {
    valueId: "EV018174",
    valueDesc: "Unmixed heating circuit",
    label: "EV018174: Unmixed heating circuit"
  },
  {
    valueId: "EV018175",
    valueDesc: "Return temperature raising facility",
    label: "EV018175: Return temperature raising facility"
  },
  {
    valueId: "EV018176",
    valueDesc: "Fixed-value control",
    label: "EV018176: Fixed-value control"
  },
  {
    valueId: "EV018178",
    valueDesc: "Three-way mixer",
    label: "EV018178: Three-way mixer"
  },
  {
    valueId: "EV018179",
    valueDesc: "Flange pipe",
    label: "EV018179: Flange pipe"
  },
  {
    valueId: "EV018180",
    valueDesc: "Four-way mixer",
    label: "EV018180: Four-way mixer"
  },
  {
    valueId: "EV018181",
    valueDesc: "Setting group",
    label: "EV018181: Setting group"
  },
  {
    valueId: "EV018182",
    valueDesc: "Bypass plug",
    label: "EV018182: Bypass plug"
  },
  {
    valueId: "EV018183",
    valueDesc: "Regulation piece",
    label: "EV018183: Regulation piece"
  },
  {
    valueId: "EV018185",
    valueDesc: "Temperature controller set",
    label: "EV018185: Temperature controller set"
  },
  {
    valueId: "EV018186",
    valueDesc: "Entry and exit",
    label: "EV018186: Entry and exit"
  },
  {
    valueId: "EV018187",
    valueDesc: "4X, 13",
    label: "EV018187: 4X, 13"
  },
  {
    valueId: "EV018188",
    valueDesc: "4X, 12",
    label: "EV018188: 4X, 12"
  },
  {
    valueId: "EV018189",
    valueDesc: "3R, 4X, 13",
    label: "EV018189: 3R, 4X, 13"
  },
  {
    valueId: "EV018190",
    valueDesc: "6P, 13",
    label: "EV018190: 6P, 13"
  },
  {
    valueId: "EV018191",
    valueDesc: "4X, 6, 13",
    label: "EV018191: 4X, 6, 13"
  },
  {
    valueId: "EV018192",
    valueDesc: "3S, 6P, 13",
    label: "EV018192: 3S, 6P, 13"
  },
  {
    valueId: "EV018197",
    valueDesc: "MC4 connector",
    label: "EV018197: MC4 connector"
  },
  {
    valueId: "EV018198",
    valueDesc: "3R, 12",
    label: "EV018198: 3R, 12"
  },
  {
    valueId: "EV018199",
    valueDesc: "8K UHD-II",
    label: "EV018199: 8K UHD-II"
  },
  {
    valueId: "EV018202",
    valueDesc: "Changeover contactor",
    label: "EV018202: Changeover contactor"
  },
  {
    valueId: "EV018203",
    valueDesc: "Switch unit",
    label: "EV018203: Switch unit"
  },
  {
    valueId: "EV018204",
    valueDesc: "Testing pin",
    label: "EV018204: Testing pin"
  },
  {
    valueId: "EV018205",
    valueDesc: "Anti chalk system",
    label: "EV018205: Anti chalk system"
  },
  {
    valueId: "EV018206",
    valueDesc: "Dosing pump",
    label: "EV018206: Dosing pump"
  },
  {
    valueId: "EV018207",
    valueDesc: "Leakage protection",
    label: "EV018207: Leakage protection"
  },
  {
    valueId: "EV018213",
    valueDesc: "R452a",
    label: "EV018213: R452a"
  },
  {
    valueId: "EV018214",
    valueDesc: "R744",
    label: "EV018214: R744"
  },
  {
    valueId: "EV018215",
    valueDesc: "Suction cup",
    label: "EV018215: Suction cup"
  },
  {
    valueId: "EV018216",
    valueDesc: "Pressure reducer cartridge",
    label: "EV018216: Pressure reducer cartridge"
  },
  {
    valueId: "EV018217",
    valueDesc: "Adjustment handle",
    label: "EV018217: Adjustment handle"
  },
  {
    valueId: "EV018218",
    valueDesc: "Relief tap",
    label: "EV018218: Relief tap"
  },
  {
    valueId: "EV018220",
    valueDesc: "Suction housing",
    label: "EV018220: Suction housing"
  },
  {
    valueId: "EV018221",
    valueDesc: "Angle funnel",
    label: "EV018221: Angle funnel"
  },
  {
    valueId: "EV018224",
    valueDesc: "Filter round brush",
    label: "EV018224: Filter round brush"
  },
  {
    valueId: "EV018234",
    valueDesc: "Danish",
    label: "EV018234: Danish"
  },
  {
    valueId: "EV018235",
    valueDesc: "Portuguese",
    label: "EV018235: Portuguese"
  },
  {
    valueId: "EV018236",
    valueDesc: "Spanish",
    label: "EV018236: Spanish"
  },
  {
    valueId: "EV018237",
    valueDesc: "Dutch",
    label: "EV018237: Dutch"
  },
  {
    valueId: "EV018238",
    valueDesc: "Italian",
    label: "EV018238: Italian"
  },
  {
    valueId: "EV018239",
    valueDesc: "Polish",
    label: "EV018239: Polish"
  },
  {
    valueId: "EV018240",
    valueDesc: "Greek",
    label: "EV018240: Greek"
  },
  {
    valueId: "EV018241",
    valueDesc: "Czech",
    label: "EV018241: Czech"
  },
  {
    valueId: "EV018242",
    valueDesc: "Estonian",
    label: "EV018242: Estonian"
  },
  {
    valueId: "EV018243",
    valueDesc: "Finnish",
    label: "EV018243: Finnish"
  },
  {
    valueId: "EV018244",
    valueDesc: "Hungarian",
    label: "EV018244: Hungarian"
  },
  {
    valueId: "EV018245",
    valueDesc: "Latvian",
    label: "EV018245: Latvian"
  },
  {
    valueId: "EV018246",
    valueDesc: "Lithuanian",
    label: "EV018246: Lithuanian"
  },
  {
    valueId: "EV018247",
    valueDesc: "Russian",
    label: "EV018247: Russian"
  },
  {
    valueId: "EV018248",
    valueDesc: "Slovakian",
    label: "EV018248: Slovakian"
  },
  {
    valueId: "EV018249",
    valueDesc: "Swedish",
    label: "EV018249: Swedish"
  },
  {
    valueId: "EV018250",
    valueDesc: "Slovenian",
    label: "EV018250: Slovenian"
  },
  {
    valueId: "EV018269",
    valueDesc: "Cutted",
    label: "EV018269: Cutted"
  },
  {
    valueId: "EV018270",
    valueDesc: "APC1",
    label: "EV018270: APC1"
  },
  {
    valueId: "EV018271",
    valueDesc: "APC2",
    label: "EV018271: APC2"
  },
  {
    valueId: "EV018272",
    valueDesc: "APC3",
    label: "EV018272: APC3"
  },
  {
    valueId: "EV018273",
    valueDesc: "APC4",
    label: "EV018273: APC4"
  },
  {
    valueId: "EV018274",
    valueDesc: "Cartridge for water softening",
    label: "EV018274: Cartridge for water softening"
  },
  {
    valueId: "EV018275",
    valueDesc: "Cartridge for demineralization",
    label: "EV018275: Cartridge for demineralization"
  },
  {
    valueId: "EV018276",
    valueDesc: "Cartridge for demineralization and pH stabilization",
    label: "EV018276: Cartridge for demineralization and pH stabilization"
  },
  {
    valueId: "EV018277",
    valueDesc: "Exchange granules for water softening",
    label: "EV018277: Exchange granules for water softening"
  },
  {
    valueId: "EV018278",
    valueDesc: "Exchange granules for demineralization",
    label: "EV018278: Exchange granules for demineralization"
  },
  {
    valueId: "EV018279",
    valueDesc: "Exchange granules for demineralization and pH stabilization",
    label: "EV018279: Exchange granules for demineralization and pH stabilization"
  },
  {
    valueId: "EV018280",
    valueDesc: "Calibration solution",
    label: "EV018280: Calibration solution"
  },
  {
    valueId: "EV018281",
    valueDesc: "Cartridge adapter",
    label: "EV018281: Cartridge adapter"
  },
  {
    valueId: "EV018282",
    valueDesc: "Conductivity sensor",
    label: "EV018282: Conductivity sensor"
  },
  {
    valueId: "EV018284",
    valueDesc: "Hose set",
    label: "EV018284: Hose set"
  },
  {
    valueId: "EV018285",
    valueDesc: "System separator cartridge",
    label: "EV018285: System separator cartridge"
  },
  {
    valueId: "EV018286",
    valueDesc: "Drain funnel",
    label: "EV018286: Drain funnel"
  },
  {
    valueId: "EV018287",
    valueDesc: "Service case for BA testing",
    label: "EV018287: Service case for BA testing"
  },
  {
    valueId: "EV018288",
    valueDesc: "Barrier",
    label: "EV018288: Barrier"
  },
  {
    valueId: "EV018289",
    valueDesc: "Sealing clamp",
    label: "EV018289: Sealing clamp"
  },
  {
    valueId: "EV018290",
    valueDesc: "Test screw connection",
    label: "EV018290: Test screw connection"
  },
  {
    valueId: "EV018291",
    valueDesc: "Non-return valve cartridge",
    label: "EV018291: Non-return valve cartridge"
  },
  {
    valueId: "EV018292",
    valueDesc: "Funnel insert",
    label: "EV018292: Funnel insert"
  },
  {
    valueId: "EV018293",
    valueDesc: "Lighting ring",
    label: "EV018293: Lighting ring"
  },
  {
    valueId: "EV018294",
    valueDesc: "Salt container",
    label: "EV018294: Salt container"
  },
  {
    valueId: "EV018295",
    valueDesc: "Control head",
    label: "EV018295: Control head"
  },
  {
    valueId: "EV018296",
    valueDesc: "Resin bottle",
    label: "EV018296: Resin bottle"
  },
  {
    valueId: "EV018297",
    valueDesc: "Control head cover",
    label: "EV018297: Control head cover"
  },
  {
    valueId: "EV018298",
    valueDesc: "Blending valve",
    label: "EV018298: Blending valve"
  },
  {
    valueId: "EV018299",
    valueDesc: "Chlorine cell",
    label: "EV018299: Chlorine cell"
  },
  {
    valueId: "EV018300",
    valueDesc: "Drive piston",
    label: "EV018300: Drive piston"
  },
  {
    valueId: "EV018301",
    valueDesc: "Drive unit",
    label: "EV018301: Drive unit"
  },
  {
    valueId: "EV018302",
    valueDesc: "Adapter connection for hose extension",
    label: "EV018302: Adapter connection for hose extension"
  },
  {
    valueId: "EV018303",
    valueDesc: "Extension for flange cable",
    label: "EV018303: Extension for flange cable"
  },
  {
    valueId: "EV018304",
    valueDesc: "Regeneration cartridge",
    label: "EV018304: Regeneration cartridge"
  },
  {
    valueId: "EV018305",
    valueDesc: "Regeneration salt",
    label: "EV018305: Regeneration salt"
  },
  {
    valueId: "EV018306",
    valueDesc: "Overflow hose",
    label: "EV018306: Overflow hose"
  },
  {
    valueId: "EV018307",
    valueDesc: "Shut-off handle",
    label: "EV018307: Shut-off handle"
  },
  {
    valueId: "EV018309",
    valueDesc: "Housing feed-through nipple",
    label: "EV018309: Housing feed-through nipple"
  },
  {
    valueId: "EV018310",
    valueDesc: "Jointing sleeve for protective tube",
    label: "EV018310: Jointing sleeve for protective tube"
  },
  {
    valueId: "EV018311",
    valueDesc: "Nail/screw protection plate",
    label: "EV018311: Nail/screw protection plate"
  },
  {
    valueId: "EV018312",
    valueDesc: "Edge cutter for pipe-in-pipe junction box",
    label: "EV018312: Edge cutter for pipe-in-pipe junction box"
  },
  {
    valueId: "EV018313",
    valueDesc: "Outlet cover for drainage",
    label: "EV018313: Outlet cover for drainage"
  },
  {
    valueId: "EV018314",
    valueDesc: "Strike/fixing plate for protective tube",
    label: "EV018314: Strike/fixing plate for protective tube"
  },
  {
    valueId: "EV018315",
    valueDesc: "Drainage bend",
    label: "EV018315: Drainage bend"
  },
  {
    valueId: "EV018316",
    valueDesc: "Spray guard",
    label: "EV018316: Spray guard"
  },
  {
    valueId: "EV018317",
    valueDesc: "Bellow sleeve",
    label: "EV018317: Bellow sleeve"
  },
  {
    valueId: "EV018318",
    valueDesc: "Pipe brolly (umbrella)",
    label: "EV018318: Pipe brolly (umbrella)"
  },
  {
    valueId: "EV018319",
    valueDesc: "Extension neck",
    label: "EV018319: Extension neck"
  },
  {
    valueId: "EV018320",
    valueDesc: "Bracket for distributor",
    label: "EV018320: Bracket for distributor"
  },
  {
    valueId: "EV018321",
    valueDesc: "Pipe pulling tool",
    label: "EV018321: Pipe pulling tool"
  },
  {
    valueId: "EV018322",
    valueDesc: "Pipe replacement connection",
    label: "EV018322: Pipe replacement connection"
  },
  {
    valueId: "EV018323",
    valueDesc: "N1",
    label: "EV018323: N1"
  },
  {
    valueId: "EV018324",
    valueDesc: "N3",
    label: "EV018324: N3"
  },
  {
    valueId: "EV018325",
    valueDesc: "N4",
    label: "EV018325: N4"
  },
  {
    valueId: "EV018326",
    valueDesc: "N5",
    label: "EV018326: N5"
  },
  {
    valueId: "EV018327",
    valueDesc: "3D-UNB",
    label: "EV018327: 3D-UNB"
  },
  {
    valueId: "EV018328",
    valueDesc: "LUXORliving",
    label: "EV018328: LUXORliving"
  },
  {
    valueId: "EV018337",
    valueDesc: "Straight seat valve",
    label: "EV018337: Straight seat valve"
  },
  {
    valueId: "EV018338",
    valueDesc: "Circulation valve",
    label: "EV018338: Circulation valve"
  },
  {
    valueId: "EV018339",
    valueDesc: "Flush-mounted valve",
    label: "EV018339: Flush-mounted valve"
  },
  {
    valueId: "EV018342",
    valueDesc: "Shut-off and intermediate flange valve",
    label: "EV018342: Shut-off and intermediate flange valve"
  },
  {
    valueId: "EV018350",
    valueDesc: "Double meander",
    label: "EV018350: Double meander"
  },
  {
    valueId: "EV018351",
    valueDesc: "Shower mixer bracket",
    label: "EV018351: Shower mixer bracket"
  },
  {
    valueId: "EV018352",
    valueDesc: "Track stop",
    label: "EV018352: Track stop"
  },
  {
    valueId: "EV018353",
    valueDesc: "Conversion kit for vertical/horizontal movement",
    label: "EV018353: Conversion kit for vertical/horizontal movement"
  },
  {
    valueId: "EV018354",
    valueDesc: "Cupboard/shelve fitting bracket",
    label: "EV018354: Cupboard/shelve fitting bracket"
  },
  {
    valueId: "EV018355",
    valueDesc: "Rail glider",
    label: "EV018355: Rail glider"
  },
  {
    valueId: "EV018356",
    valueDesc: "Single meander",
    label: "EV018356: Single meander"
  },
  {
    valueId: "EV018357",
    valueDesc: "Right-hand operated",
    label: "EV018357: Right-hand operated"
  },
  {
    valueId: "EV018358",
    valueDesc: "Left-hand operated",
    label: "EV018358: Left-hand operated"
  },
  {
    valueId: "EV018359",
    valueDesc: "K1",
    label: "EV018359: K1"
  },
  {
    valueId: "EV018360",
    valueDesc: "K4",
    label: "EV018360: K4"
  },
  {
    valueId: "EV018361",
    valueDesc: "K9",
    label: "EV018361: K9"
  },
  {
    valueId: "EV018362",
    valueDesc: "K13",
    label: "EV018362: K13"
  },
  {
    valueId: "EV018363",
    valueDesc: "K20",
    label: "EV018363: K20"
  },
  {
    valueId: "EV018364",
    valueDesc: "Type 1 and 2 and 3",
    label: "EV018364: Type 1 and 2 and 3"
  },
  {
    valueId: "EV018365",
    valueDesc: "Mast/wall",
    label: "EV018365: Mast/wall"
  },
  {
    valueId: "EV018366",
    valueDesc: "Separate conductor",
    label: "EV018366: Separate conductor"
  },
  {
    valueId: "EV018367",
    valueDesc: "Foam pad",
    label: "EV018367: Foam pad"
  },
  {
    valueId: "EV018368",
    valueDesc: "Equipotential bonding plate",
    label: "EV018368: Equipotential bonding plate"
  },
  {
    valueId: "EV018371",
    valueDesc: "Holder for air-termination equipment",
    label: "EV018371: Holder for air-termination equipment"
  },
  {
    valueId: "EV018372",
    valueDesc: "Metal roof holder",
    label: "EV018372: Metal roof holder"
  },
  {
    valueId: "EV018373",
    valueDesc: "BA (4)",
    label: "EV018373: BA (4)"
  },
  {
    valueId: "EV018377",
    valueDesc: "External connector holder",
    label: "EV018377: External connector holder"
  },
  {
    valueId: "EV018378",
    valueDesc: "Payment module",
    label: "EV018378: Payment module"
  },
  {
    valueId: "EV018379",
    valueDesc: "Cabinet mounted/skeleton unit",
    label: "EV018379: Cabinet mounted/skeleton unit"
  },
  {
    valueId: "EV018380",
    valueDesc: "Type 2 + 3",
    label: "EV018380: Type 2 + 3"
  },
  {
    valueId: "EV018381",
    valueDesc: "Straight cuff",
    label: "EV018381: Straight cuff"
  },
  {
    valueId: "EV018383",
    valueDesc: "Wing part",
    label: "EV018383: Wing part"
  },
  {
    valueId: "EV018385",
    valueDesc: "In receiving element",
    label: "EV018385: In receiving element"
  },
  {
    valueId: "EV018386",
    valueDesc: "Hygrostat",
    label: "EV018386: Hygrostat"
  },
  {
    valueId: "EV018387",
    valueDesc: "Thermostat/hygrostat",
    label: "EV018387: Thermostat/hygrostat"
  },
  {
    valueId: "EV018388",
    valueDesc: "Titanium zinc (EN 988)",
    label: "EV018388: Titanium zinc (EN 988)"
  },
  {
    valueId: "EV018389",
    valueDesc: "Click-nut",
    label: "EV018389: Click-nut"
  },
  {
    valueId: "EV018390",
    valueDesc: "3/8 inch internal thread",
    label: "EV018390: 3/8 inch internal thread"
  },
  {
    valueId: "EV018391",
    valueDesc: "Click and screw locking facility",
    label: "EV018391: Click and screw locking facility"
  },
  {
    valueId: "EV018392",
    valueDesc: "Receiving element",
    label: "EV018392: Receiving element"
  },
  {
    valueId: "EV018394",
    valueDesc: "IP3XD",
    label: "EV018394: IP3XD"
  },
  {
    valueId: "EV018395",
    valueDesc: "PS6",
    label: "EV018395: PS6"
  },
  {
    valueId: "EV018396",
    valueDesc: "PS10",
    label: "EV018396: PS10"
  },
  {
    valueId: "EV018397",
    valueDesc: "PS16",
    label: "EV018397: PS16"
  },
  {
    valueId: "EV018398",
    valueDesc: "PS32",
    label: "EV018398: PS32"
  },
  {
    valueId: "EV018399",
    valueDesc: "Type 250",
    label: "EV018399: Type 250"
  },
  {
    valueId: "EV018400",
    valueDesc: "Type 450",
    label: "EV018400: Type 450"
  },
  {
    valueId: "EV018401",
    valueDesc: "Type 750",
    label: "EV018401: Type 750"
  },
  {
    valueId: "EV018406",
    valueDesc: "IP66/IP67/IP68/IP69",
    label: "EV018406: IP66/IP67/IP68/IP69"
  },
  {
    valueId: "EV018409",
    valueDesc: "Screw terminal",
    label: "EV018409: Screw terminal"
  },
  {
    valueId: "EV018410",
    valueDesc: "Push-in terminal",
    label: "EV018410: Push-in terminal"
  },
  {
    valueId: "EV018411",
    valueDesc: "Bottom-hung sash",
    label: "EV018411: Bottom-hung sash"
  },
  {
    valueId: "EV018412",
    valueDesc: "6.3x25 mm",
    label: "EV018412: 6.3x25 mm"
  },
  {
    valueId: "EV018413",
    valueDesc: "7x23 mm",
    label: "EV018413: 7x23 mm"
  },
  {
    valueId: "EV018414",
    valueDesc: "11x26 mm",
    label: "EV018414: 11x26 mm"
  },
  {
    valueId: "EV018415",
    valueDesc: "13x29 mm",
    label: "EV018415: 13x29 mm"
  },
  {
    valueId: "EV018416",
    valueDesc: "13x35 mm",
    label: "EV018416: 13x35 mm"
  },
  {
    valueId: "EV018417",
    valueDesc: "23x57 mm",
    label: "EV018417: 23x57 mm"
  },
  {
    valueId: "EV018418",
    valueDesc: "gM (motor protection)",
    label: "EV018418: gM (motor protection)"
  },
  {
    valueId: "EV018419",
    valueDesc: "gU (utility/distribution protection)",
    label: "EV018419: gU (utility/distribution protection)"
  },
  {
    valueId: "EV018420",
    valueDesc: "Low-/medium voltage",
    label: "EV018420: Low-/medium voltage"
  },
  {
    valueId: "EV018421",
    valueDesc: "Medium-/high voltage",
    label: "EV018421: Medium-/high voltage"
  },
  {
    valueId: "EV018422",
    valueDesc: "High voltage",
    label: "EV018422: High voltage"
  },
  {
    valueId: "EV018423",
    valueDesc: "EnOcean",
    label: "EV018423: EnOcean"
  },
  {
    valueId: "EV018424",
    valueDesc: "aBat (partial range battery protection)",
    label: "EV018424: aBat (partial range battery protection)"
  },
  {
    valueId: "EV018425",
    valueDesc: "gBat (battery protection)",
    label: "EV018425: gBat (battery protection)"
  },
  {
    valueId: "EV018427",
    valueDesc: "01XL",
    label: "EV018427: 01XL"
  },
  {
    valueId: "EV018428",
    valueDesc: "1XL",
    label: "EV018428: 1XL"
  },
  {
    valueId: "EV018429",
    valueDesc: "2XL",
    label: "EV018429: 2XL"
  },
  {
    valueId: "EV018430",
    valueDesc: "01L",
    label: "EV018430: 01L"
  },
  {
    valueId: "EV018431",
    valueDesc: "1L",
    label: "EV018431: 1L"
  },
  {
    valueId: "EV018432",
    valueDesc: "2L",
    label: "EV018432: 2L"
  },
  {
    valueId: "EV018433",
    valueDesc: "3L",
    label: "EV018433: 3L"
  },
  {
    valueId: "EV018455",
    valueDesc: "2+N/PE",
    label: "EV018455: 2+N/PE"
  },
  {
    valueId: "EV018458",
    valueDesc: "Oil hardened",
    label: "EV018458: Oil hardened"
  },
  {
    valueId: "EV018462",
    valueDesc: "Corner turning tool",
    label: "EV018462: Corner turning tool"
  },
  {
    valueId: "EV018463",
    valueDesc: "Side turning tool",
    label: "EV018463: Side turning tool"
  },
  {
    valueId: "EV018464",
    valueDesc: "End-cut turning tool",
    label: "EV018464: End-cut turning tool"
  },
  {
    valueId: "EV018466",
    valueDesc: "Recessed",
    label: "EV018466: Recessed"
  },
  {
    valueId: "EV018468",
    valueDesc: "Tip",
    label: "EV018468: Tip"
  },
  {
    valueId: "EV018469",
    valueDesc: "Adjustment",
    label: "EV018469: Adjustment"
  },
  {
    valueId: "EV018476",
    valueDesc: "Feed pawl",
    label: "EV018476: Feed pawl"
  },
  {
    valueId: "EV018478",
    valueDesc: "Cutter head",
    label: "EV018478: Cutter head"
  },
  {
    valueId: "EV018482",
    valueDesc: "Calibration arbor",
    label: "EV018482: Calibration arbor"
  },
  {
    valueId: "EV018483",
    valueDesc: "Test support",
    label: "EV018483: Test support"
  },
  {
    valueId: "EV018484",
    valueDesc: "Length stop",
    label: "EV018484: Length stop"
  },
  {
    valueId: "EV018485",
    valueDesc: "Positioning aid",
    label: "EV018485: Positioning aid"
  },
  {
    valueId: "EV018492",
    valueDesc: "Half-set",
    label: "EV018492: Half-set"
  },
  {
    valueId: "EV018494",
    valueDesc: "Push handle",
    label: "EV018494: Push handle"
  },
  {
    valueId: "EV018497",
    valueDesc: "Graphite polystyrene (GPS)",
    label: "EV018497: Graphite polystyrene (GPS)"
  },
  {
    valueId: "EV018500",
    valueDesc: "Adapter flange",
    label: "EV018500: Adapter flange"
  },
  {
    valueId: "EV018506",
    valueDesc: "M8 / M10 / 1/2 inch (M-F)",
    label: "EV018506: M8 / M10 / 1/2 inch (M-F)"
  },
  {
    valueId: "EV018507",
    valueDesc: "3/4 inch M12/M16",
    label: "EV018507: 3/4 inch M12/M16"
  },
  {
    valueId: "EV018508",
    valueDesc: "3/4 inch - M16",
    label: "EV018508: 3/4 inch - M16"
  },
  {
    valueId: "EV018509",
    valueDesc: "1/2 inch - M12",
    label: "EV018509: 1/2 inch - M12"
  },
  {
    valueId: "EV018510",
    valueDesc: "Bottom and backside",
    label: "EV018510: Bottom and backside"
  },
  {
    valueId: "EV018512",
    valueDesc: "Dual (+)",
    label: "EV018512: Dual (+)"
  },
  {
    valueId: "EV018513",
    valueDesc: "Right turn circular",
    label: "EV018513: Right turn circular"
  },
  {
    valueId: "EV018514",
    valueDesc: "Left turn circular",
    label: "EV018514: Left turn circular"
  },
  {
    valueId: "EV018515",
    valueDesc: "Coupler fixed length",
    label: "EV018515: Coupler fixed length"
  },
  {
    valueId: "EV018516",
    valueDesc: "Mounting holes",
    label: "EV018516: Mounting holes"
  },
  {
    valueId: "EV018519",
    valueDesc: "Fitting to fitting adaptor",
    label: "EV018519: Fitting to fitting adaptor"
  },
  {
    valueId: "EV018520",
    valueDesc: "Flanged connector",
    label: "EV018520: Flanged connector"
  },
  {
    valueId: "EV018521",
    valueDesc: "Elbow bend (round to rectangular)",
    label: "EV018521: Elbow bend (round to rectangular)"
  },
  {
    valueId: "EV018522",
    valueDesc: "Yy0",
    label: "EV018522: Yy0"
  },
  {
    valueId: "EV018523",
    valueDesc: "Dd0",
    label: "EV018523: Dd0"
  },
  {
    valueId: "EV018524",
    valueDesc: "Yd1",
    label: "EV018524: Yd1"
  },
  {
    valueId: "EV018525",
    valueDesc: "Dy1",
    label: "EV018525: Dy1"
  },
  {
    valueId: "EV018526",
    valueDesc: "Yd5",
    label: "EV018526: Yd5"
  },
  {
    valueId: "EV018527",
    valueDesc: "Dy5",
    label: "EV018527: Dy5"
  },
  {
    valueId: "EV018528",
    valueDesc: "Yy6",
    label: "EV018528: Yy6"
  },
  {
    valueId: "EV018529",
    valueDesc: "Dd6",
    label: "EV018529: Dd6"
  },
  {
    valueId: "EV018530",
    valueDesc: "Yd11",
    label: "EV018530: Yd11"
  },
  {
    valueId: "EV018531",
    valueDesc: "Dy11",
    label: "EV018531: Dy11"
  },
  {
    valueId: "EV018532",
    valueDesc: "Symbol 'Span'",
    label: "EV018532: Symbol 'Span'"
  },
  {
    valueId: "EV018533",
    valueDesc: "Symbol 'Liquidity STOP'",
    label: "EV018533: Symbol 'Liquidity STOP'"
  },
  {
    valueId: "EV018534",
    valueDesc: "Symbol 'Horn'",
    label: "EV018534: Symbol 'Horn'"
  },
  {
    valueId: "EV018535",
    valueDesc: "Symbol 'Automatic outlet'",
    label: "EV018535: Symbol 'Automatic outlet'"
  },
  {
    valueId: "EV018536",
    valueDesc: "Symbol 'Reset'",
    label: "EV018536: Symbol 'Reset'"
  },
  {
    valueId: "EV018537",
    valueDesc: "Symbol 'Hand'",
    label: "EV018537: Symbol 'Hand'"
  },
  {
    valueId: "EV018538",
    valueDesc: "Symbol 'Release'",
    label: "EV018538: Symbol 'Release'"
  },
  {
    valueId: "EV018539",
    valueDesc: "Symbol 'Liquidity'",
    label: "EV018539: Symbol 'Liquidity'"
  },
  {
    valueId: "EV018540",
    valueDesc: "Symbol 'Lock'",
    label: "EV018540: Symbol 'Lock'"
  },
  {
    valueId: "EV018541",
    valueDesc: "Symbol 'Electric voltage'",
    label: "EV018541: Symbol 'Electric voltage'"
  },
  {
    valueId: "EV018542",
    valueDesc: "Symbol 'Spindle START'",
    label: "EV018542: Symbol 'Spindle START'"
  },
  {
    valueId: "EV018543",
    valueDesc: "Symbol 'Spindle STOP'",
    label: "EV018543: Symbol 'Spindle STOP'"
  },
  {
    valueId: "EV018544",
    valueDesc: "Hand 0 Auto",
    label: "EV018544: Hand 0 Auto"
  },
  {
    valueId: "EV018546",
    valueDesc: "Symbol '0-1'",
    label: "EV018546: Symbol '0-1'"
  },
  {
    valueId: "EV018547",
    valueDesc: "Symbol 'Emergency Stop'",
    label: "EV018547: Symbol 'Emergency Stop'"
  },
  {
    valueId: "EV018548",
    valueDesc: "Error",
    label: "EV018548: Error"
  },
  {
    valueId: "EV018549",
    valueDesc: "Push",
    label: "EV018549: Push"
  },
  {
    valueId: "EV018551",
    valueDesc: "Dosing tank station",
    label: "EV018551: Dosing tank station"
  },
  {
    valueId: "EV018552",
    valueDesc: "Dosing skid system",
    label: "EV018552: Dosing skid system"
  },
  {
    valueId: "EV018553",
    valueDesc: "Gas dosing system",
    label: "EV018553: Gas dosing system"
  },
  {
    valueId: "EV018554",
    valueDesc: "Chlorine dioxide generation and dosing system",
    label: "EV018554: Chlorine dioxide generation and dosing system"
  },
  {
    valueId: "EV018555",
    valueDesc: "Sodium hypochlorite generation system",
    label: "EV018555: Sodium hypochlorite generation system"
  },
  {
    valueId: "EV018557",
    valueDesc: "Polymer preparation system",
    label: "EV018557: Polymer preparation system"
  },
  {
    valueId: "EV018558",
    valueDesc: "Recirculation pump",
    label: "EV018558: Recirculation pump"
  },
  {
    valueId: "EV018559",
    valueDesc: "Ex db",
    label: "EV018559: Ex db"
  },
  {
    valueId: "EV018560",
    valueDesc: "Ex eb",
    label: "EV018560: Ex eb"
  },
  {
    valueId: "EV018561",
    valueDesc: "Polyurethane (PU) with steel core",
    label: "EV018561: Polyurethane (PU) with steel core"
  },
  {
    valueId: "EV018562",
    valueDesc: "Attention wastewater pipe",
    label: "EV018562: Attention wastewater pipe"
  },
  {
    valueId: "EV018563",
    valueDesc: "Attention gas pipe",
    label: "EV018563: Attention gas pipe"
  },
  {
    valueId: "EV018564",
    valueDesc: "Attention power cable",
    label: "EV018564: Attention power cable"
  },
  {
    valueId: "EV018565",
    valueDesc: "Attention water pipe",
    label: "EV018565: Attention water pipe"
  },
  {
    valueId: "EV018567",
    valueDesc: "Pressure transmitter",
    label: "EV018567: Pressure transmitter"
  },
  {
    valueId: "EV018568",
    valueDesc: "Liquid flow sensor",
    label: "EV018568: Liquid flow sensor"
  },
  {
    valueId: "EV018569",
    valueDesc: "Cable termination set",
    label: "EV018569: Cable termination set"
  },
  {
    valueId: "EV018570",
    valueDesc: "Angle seat valve",
    label: "EV018570: Angle seat valve"
  },
  {
    valueId: "EV018571",
    valueDesc: "Regulating valve",
    label: "EV018571: Regulating valve"
  },
  {
    valueId: "EV018572",
    valueDesc: "Pressing connection",
    label: "EV018572: Pressing connection"
  },
  {
    valueId: "EV018573",
    valueDesc: "Short spindle",
    label: "EV018573: Short spindle"
  },
  {
    valueId: "EV018574",
    valueDesc: "Wing handle",
    label: "EV018574: Wing handle"
  },
  {
    valueId: "EV018575",
    valueDesc: "Rectangular backplate",
    label: "EV018575: Rectangular backplate"
  },
  {
    valueId: "EV018576",
    valueDesc: "Residual current transformer",
    label: "EV018576: Residual current transformer"
  },
  {
    valueId: "EV018577",
    valueDesc: "Split current transformer",
    label: "EV018577: Split current transformer"
  },
  {
    valueId: "EV018579",
    valueDesc: "Outlet flow",
    label: "EV018579: Outlet flow"
  },
  {
    valueId: "EV018580",
    valueDesc: "Inlet flow",
    label: "EV018580: Inlet flow"
  },
  {
    valueId: "EV018581",
    valueDesc: "25:1",
    label: "EV018581: 25:1"
  },
  {
    valueId: "EV018582",
    valueDesc: "50:1",
    label: "EV018582: 50:1"
  },
  {
    valueId: "EV018583",
    valueDesc: "100:1",
    label: "EV018583: 100:1"
  },
  {
    valueId: "EV018584",
    valueDesc: "250:1",
    label: "EV018584: 250:1"
  },
  {
    valueId: "EV018588",
    valueDesc: "8.1 (IEC)",
    label: "EV018588: 8.1 (IEC)"
  },
  {
    valueId: "EV018589",
    valueDesc: "8.2 (IEC)",
    label: "EV018589: 8.2 (IEC)"
  },
  {
    valueId: "EV018590",
    valueDesc: "Polyphenylene oxide (PPO)",
    label: "EV018590: Polyphenylene oxide (PPO)"
  },
  {
    valueId: "EV018591",
    valueDesc: "M/F",
    label: "EV018591: M/F"
  },
  {
    valueId: "EV018593",
    valueDesc: "Built-in and prepared",
    label: "EV018593: Built-in and prepared"
  },
  {
    valueId: "EV018594",
    valueDesc: "8.5 mm",
    label: "EV018594: 8.5 mm"
  },
  {
    valueId: "EV018595",
    valueDesc: "PPE-PS",
    label: "EV018595: PPE-PS"
  },
  {
    valueId: "EV018596",
    valueDesc: "S1/S2 15 min",
    label: "EV018596: S1/S2 15 min"
  },
  {
    valueId: "EV018597",
    valueDesc: "S1/S3 50 % 1 min",
    label: "EV018597: S1/S3 50 % 1 min"
  },
  {
    valueId: "EV018598",
    valueDesc: "S1/S3 40 %",
    label: "EV018598: S1/S3 40 %"
  },
  {
    valueId: "EV018599",
    valueDesc: "S3 10 % 1 min",
    label: "EV018599: S3 10 % 1 min"
  },
  {
    valueId: "EV018600",
    valueDesc: "S3 13 % 3 min",
    label: "EV018600: S3 13 % 3 min"
  },
  {
    valueId: "EV018601",
    valueDesc: "S3 20 % 1 min",
    label: "EV018601: S3 20 % 1 min"
  },
  {
    valueId: "EV018602",
    valueDesc: "S3 30 % 1 min",
    label: "EV018602: S3 30 % 1 min"
  },
  {
    valueId: "EV018603",
    valueDesc: "S3 33 % 45 min",
    label: "EV018603: S3 33 % 45 min"
  },
  {
    valueId: "EV018604",
    valueDesc: "S3 35 % 1 min",
    label: "EV018604: S3 35 % 1 min"
  },
  {
    valueId: "EV018605",
    valueDesc: "S3 40 % 1 min",
    label: "EV018605: S3 40 % 1 min"
  },
  {
    valueId: "EV018606",
    valueDesc: "S3 40 % 10 min",
    label: "EV018606: S3 40 % 10 min"
  },
  {
    valueId: "EV018607",
    valueDesc: "S3 45 % 20 min",
    label: "EV018607: S3 45 % 20 min"
  },
  {
    valueId: "EV018608",
    valueDesc: "S3 50 % 1 min",
    label: "EV018608: S3 50 % 1 min"
  },
  {
    valueId: "EV018610",
    valueDesc: "Staples",
    label: "EV018610: Staples"
  },
  {
    valueId: "EV018612",
    valueDesc: "A5",
    label: "EV018612: A5"
  },
  {
    valueId: "EV018616",
    valueDesc: "Through hardening",
    label: "EV018616: Through hardening"
  },
  {
    valueId: "EV018617",
    valueDesc: "Case hardening",
    label: "EV018617: Case hardening"
  },
  {
    valueId: "EV018618",
    valueDesc: "Outside hexagon with Phillips",
    label: "EV018618: Outside hexagon with Phillips"
  },
  {
    valueId: "EV018619",
    valueDesc: "Outside hexagon with Pozidriv",
    label: "EV018619: Outside hexagon with Pozidriv"
  },
  {
    valueId: "EV018620",
    valueDesc: "Outside hexagon with Torx",
    label: "EV018620: Outside hexagon with Torx"
  },
  {
    valueId: "EV018621",
    valueDesc: "Lox",
    label: "EV018621: Lox"
  },
  {
    valueId: "EV018622",
    valueDesc: "Double square",
    label: "EV018622: Double square"
  },
  {
    valueId: "EV018623",
    valueDesc: "Curb link chain",
    label: "EV018623: Curb link chain"
  },
  {
    valueId: "EV018624",
    valueDesc: "Sash chain",
    label: "EV018624: Sash chain"
  },
  {
    valueId: "EV018625",
    valueDesc: "Ball chain",
    label: "EV018625: Ball chain"
  },
  {
    valueId: "EV018626",
    valueDesc: "Hammer blows",
    label: "EV018626: Hammer blows"
  },
  {
    valueId: "EV018628",
    valueDesc: "Cleat",
    label: "EV018628: Cleat"
  },
  {
    valueId: "EV018631",
    valueDesc: "Blunt point tip",
    label: "EV018631: Blunt point tip"
  },
  {
    valueId: "EV018632",
    valueDesc: "Strap hinge",
    label: "EV018632: Strap hinge"
  },
  {
    valueId: "EV018633",
    valueDesc: "T-hinge",
    label: "EV018633: T-hinge"
  },
  {
    valueId: "EV018634",
    valueDesc: "Weld hinge",
    label: "EV018634: Weld hinge"
  },
  {
    valueId: "EV018636",
    valueDesc: "Cranked band hinge",
    label: "EV018636: Cranked band hinge"
  },
  {
    valueId: "EV018637",
    valueDesc: "Straight band hinge",
    label: "EV018637: Straight band hinge"
  },
  {
    valueId: "EV018638",
    valueDesc: "Top left/bottom right",
    label: "EV018638: Top left/bottom right"
  },
  {
    valueId: "EV018639",
    valueDesc: "Top right/bottom left",
    label: "EV018639: Top right/bottom left"
  },
  {
    valueId: "EV018641",
    valueDesc: "Plate gudgeon",
    label: "EV018641: Plate gudgeon"
  },
  {
    valueId: "EV018642",
    valueDesc: "Hook to bolt gudgeon",
    label: "EV018642: Hook to bolt gudgeon"
  },
  {
    valueId: "EV018643",
    valueDesc: "Brickwork gate hooks gudgeon",
    label: "EV018643: Brickwork gate hooks gudgeon"
  },
  {
    valueId: "EV018644",
    valueDesc: "Weld gudgeon",
    label: "EV018644: Weld gudgeon"
  },
  {
    valueId: "EV018645",
    valueDesc: "Double strap field hinge",
    label: "EV018645: Double strap field hinge"
  },
  {
    valueId: "EV018646",
    valueDesc: "Screw-in gudgeon",
    label: "EV018646: Screw-in gudgeon"
  },
  {
    valueId: "EV018647",
    valueDesc: "AC-1",
    label: "EV018647: AC-1"
  },
  {
    valueId: "EV018648",
    valueDesc: "AC-2",
    label: "EV018648: AC-2"
  },
  {
    valueId: "EV018649",
    valueDesc: "AC-3",
    label: "EV018649: AC-3"
  },
  {
    valueId: "EV018650",
    valueDesc: "AC-4",
    label: "EV018650: AC-4"
  },
  {
    valueId: "EV018651",
    valueDesc: "AC-5a",
    label: "EV018651: AC-5a"
  },
  {
    valueId: "EV018652",
    valueDesc: "AC-5b",
    label: "EV018652: AC-5b"
  },
  {
    valueId: "EV018653",
    valueDesc: "AC-6a",
    label: "EV018653: AC-6a"
  },
  {
    valueId: "EV018654",
    valueDesc: "AC-6b",
    label: "EV018654: AC-6b"
  },
  {
    valueId: "EV018655",
    valueDesc: "AC-7a",
    label: "EV018655: AC-7a"
  },
  {
    valueId: "EV018656",
    valueDesc: "AC-7b",
    label: "EV018656: AC-7b"
  },
  {
    valueId: "EV018658",
    valueDesc: "AC-8a",
    label: "EV018658: AC-8a"
  },
  {
    valueId: "EV018659",
    valueDesc: "AC-8b",
    label: "EV018659: AC-8b"
  },
  {
    valueId: "EV018700",
    valueDesc: "DC-1",
    label: "EV018700: DC-1"
  },
  {
    valueId: "EV018701",
    valueDesc: "DC-3",
    label: "EV018701: DC-3"
  },
  {
    valueId: "EV018702",
    valueDesc: "DC-5",
    label: "EV018702: DC-5"
  },
  {
    valueId: "EV018703",
    valueDesc: "DC-6",
    label: "EV018703: DC-6"
  },
  {
    valueId: "EV018715",
    valueDesc: "Drill housing",
    label: "EV018715: Drill housing"
  },
  {
    valueId: "EV018716",
    valueDesc: "Holder/adapter",
    label: "EV018716: Holder/adapter"
  },
  {
    valueId: "EV018717",
    valueDesc: "Manometer device/balloon holder",
    label: "EV018717: Manometer device/balloon holder"
  },
  {
    valueId: "EV018718",
    valueDesc: "Straight coupling with valve",
    label: "EV018718: Straight coupling with valve"
  },
  {
    valueId: "EV018719",
    valueDesc: "Right-angle coupling with valve",
    label: "EV018719: Right-angle coupling with valve"
  },
  {
    valueId: "EV018720",
    valueDesc: "Support tube housing",
    label: "EV018720: Support tube housing"
  },
  {
    valueId: "EV018721",
    valueDesc: "Support balloon tube",
    label: "EV018721: Support balloon tube"
  },
  {
    valueId: "EV018722",
    valueDesc: "Balloon",
    label: "EV018722: Balloon"
  },
  {
    valueId: "EV018723",
    valueDesc: "Swiss norm type 21",
    label: "EV018723: Swiss norm type 21"
  },
  {
    valueId: "EV018725",
    valueDesc: "Welding sleeve",
    label: "EV018725: Welding sleeve"
  },
  {
    valueId: "EV018726",
    valueDesc: "FR 03",
    label: "EV018726: FR 03"
  },
  {
    valueId: "EV018728",
    valueDesc: "FR 20",
    label: "EV018728: FR 20"
  },
  {
    valueId: "EV018729",
    valueDesc: "6 LF 22",
    label: "EV018729: 6 LF 22"
  },
  {
    valueId: "EV018730",
    valueDesc: "Patinated",
    label: "EV018730: Patinated"
  },
  {
    valueId: "EV018731",
    valueDesc: "Holster",
    label: "EV018731: Holster"
  },
  {
    valueId: "EV018732",
    valueDesc: "G 40",
    label: "EV018732: G 40"
  },
  {
    valueId: "EV018733",
    valueDesc: "G 65",
    label: "EV018733: G 65"
  },
  {
    valueId: "EV018734",
    valueDesc: "G 100",
    label: "EV018734: G 100"
  },
  {
    valueId: "EV018735",
    valueDesc: "G 160",
    label: "EV018735: G 160"
  },
  {
    valueId: "EV018736",
    valueDesc: "G 250",
    label: "EV018736: G 250"
  },
  {
    valueId: "EV018737",
    valueDesc: "G 400",
    label: "EV018737: G 400"
  },
  {
    valueId: "EV018738",
    valueDesc: "G 650",
    label: "EV018738: G 650"
  },
  {
    valueId: "EV018739",
    valueDesc: "G 1000",
    label: "EV018739: G 1000"
  },
  {
    valueId: "EV018740",
    valueDesc: "G 1600",
    label: "EV018740: G 1600"
  },
  {
    valueId: "EV018741",
    valueDesc: "G 2500",
    label: "EV018741: G 2500"
  },
  {
    valueId: "EV018742",
    valueDesc: "G 4000",
    label: "EV018742: G 4000"
  },
  {
    valueId: "EV018743",
    valueDesc: "G 6500",
    label: "EV018743: G 6500"
  },
  {
    valueId: "EV018744",
    valueDesc: "G 10000",
    label: "EV018744: G 10000"
  },
  {
    valueId: "EV018745",
    valueDesc: "G 16000",
    label: "EV018745: G 16000"
  },
  {
    valueId: "EV018746",
    valueDesc: "Wall section",
    label: "EV018746: Wall section"
  },
  {
    valueId: "EV018747",
    valueDesc: "Door section",
    label: "EV018747: Door section"
  },
  {
    valueId: "EV018748",
    valueDesc: "Fan blade",
    label: "EV018748: Fan blade"
  },
  {
    valueId: "EV018750",
    valueDesc: "Thermal mass",
    label: "EV018750: Thermal mass"
  },
  {
    valueId: "EV018751",
    valueDesc: "Rotary",
    label: "EV018751: Rotary"
  },
  {
    valueId: "EV018752",
    valueDesc: "2.5",
    label: "EV018752: 2.5"
  },
  {
    valueId: "EV018753",
    valueDesc: "5-wire",
    label: "EV018753: 5-wire"
  },
  {
    valueId: "EV018754",
    valueDesc: "Round hook",
    label: "EV018754: Round hook"
  },
  {
    valueId: "EV018755",
    valueDesc: "Belt bag",
    label: "EV018755: Belt bag"
  },
  {
    valueId: "EV018756",
    valueDesc: "Long pile",
    label: "EV018756: Long pile"
  },
  {
    valueId: "EV018757",
    valueDesc: "Short pile",
    label: "EV018757: Short pile"
  },
  {
    valueId: "EV018762",
    valueDesc: "5 V DC",
    label: "EV018762: 5 V DC"
  },
  {
    valueId: "EV018763",
    valueDesc: "Alarm input module",
    label: "EV018763: Alarm input module"
  },
  {
    valueId: "EV018764",
    valueDesc: "Analogue input module",
    label: "EV018764: Analogue input module"
  },
  {
    valueId: "EV018765",
    valueDesc: "Analogue output module",
    label: "EV018765: Analogue output module"
  },
  {
    valueId: "EV018766",
    valueDesc: "Pressure sensor",
    label: "EV018766: Pressure sensor"
  },
  {
    valueId: "EV018768",
    valueDesc: "Communication cable",
    label: "EV018768: Communication cable"
  },
  {
    valueId: "EV018771",
    valueDesc: "M75 x 1.5",
    label: "EV018771: M75 x 1.5"
  },
  {
    valueId: "EV018773",
    valueDesc: "Push-open kit for drawer",
    label: "EV018773: Push-open kit for drawer"
  },
  {
    valueId: "EV018774",
    valueDesc: "Storage box for drawer",
    label: "EV018774: Storage box for drawer"
  },
  {
    valueId: "EV018775",
    valueDesc: "Decorative frame",
    label: "EV018775: Decorative frame"
  },
  {
    valueId: "EV018776",
    valueDesc: "Drawer insert",
    label: "EV018776: Drawer insert"
  },
  {
    valueId: "EV018777",
    valueDesc: "P235GH (1.0345)",
    label: "EV018777: P235GH (1.0345)"
  },
  {
    valueId: "EV018781",
    valueDesc: "Push-on",
    label: "EV018781: Push-on"
  },
  {
    valueId: "EV018782",
    valueDesc: "FL7",
    label: "EV018782: FL7"
  },
  {
    valueId: "EV018783",
    valueDesc: "FL13",
    label: "EV018783: FL13"
  },
  {
    valueId: "EV018784",
    valueDesc: "FL15",
    label: "EV018784: FL15"
  },
  {
    valueId: "EV018785",
    valueDesc: "FL21",
    label: "EV018785: FL21"
  },
  {
    valueId: "EV018786",
    valueDesc: "FL28",
    label: "EV018786: FL28"
  },
  {
    valueId: "EV018787",
    valueDesc: "FL33",
    label: "EV018787: FL33"
  },
  {
    valueId: "EV018788",
    valueDesc: "Coupling set with base plate",
    label: "EV018788: Coupling set with base plate"
  },
  {
    valueId: "EV018789",
    valueDesc: "AF",
    label: "EV018789: AF"
  },
  {
    valueId: "EV018790",
    valueDesc: "AG",
    label: "EV018790: AG"
  },
  {
    valueId: "EV018792",
    valueDesc: "1 x 1 1/2 inch",
    label: "EV018792: 1 x 1 1/2 inch"
  },
  {
    valueId: "EV018793",
    valueDesc: "2 x 1 1/2 inch",
    label: "EV018793: 2 x 1 1/2 inch"
  },
  {
    valueId: "EV018794",
    valueDesc: "Well system",
    label: "EV018794: Well system"
  },
  {
    valueId: "EV018795",
    valueDesc: "Filling system",
    label: "EV018795: Filling system"
  },
  {
    valueId: "EV018799",
    valueDesc: "Screwed terminal and push-in clamp",
    label: "EV018799: Screwed terminal and push-in clamp"
  },
  {
    valueId: "EV018801",
    valueDesc: "4 Ohm",
    label: "EV018801: 4 Ohm"
  },
  {
    valueId: "EV018803",
    valueDesc: "Inner corner profile",
    label: "EV018803: Inner corner profile"
  },
  {
    valueId: "EV018804",
    valueDesc: "Outer corner profile",
    label: "EV018804: Outer corner profile"
  },
  {
    valueId: "EV018805",
    valueDesc: "Plate joint rail",
    label: "EV018805: Plate joint rail"
  },
  {
    valueId: "EV018806",
    valueDesc: "Magnetic stamp",
    label: "EV018806: Magnetic stamp"
  },
  {
    valueId: "EV018807",
    valueDesc: "Connection profile",
    label: "EV018807: Connection profile"
  },
  {
    valueId: "EV018808",
    valueDesc: "Fire extinguisher",
    label: "EV018808: Fire extinguisher"
  },
  {
    valueId: "EV018809",
    valueDesc: "Access forbidden",
    label: "EV018809: Access forbidden"
  },
  {
    valueId: "EV018810",
    valueDesc: "Camera/video surveillance (CCTV)",
    label: "EV018810: Camera/video surveillance (CCTV)"
  },
  {
    valueId: "EV018811",
    valueDesc: "Å (AA)",
    label: "EV018811: Å (AA)"
  },
  {
    valueId: "EV018812",
    valueDesc: "Ä (AE)",
    label: "EV018812: Ä (AE)"
  },
  {
    valueId: "EV018813",
    valueDesc: "Ö (OE)",
    label: "EV018813: Ö (OE)"
  },
  {
    valueId: "EV018814",
    valueDesc: "Emergency exit",
    label: "EV018814: Emergency exit"
  },
  {
    valueId: "EV018815",
    valueDesc: "Safety helmet",
    label: "EV018815: Safety helmet"
  },
  {
    valueId: "EV018816",
    valueDesc: "Electrical hazard",
    label: "EV018816: Electrical hazard"
  },
  {
    valueId: "EV018817",
    valueDesc: "Dangerous conduit",
    label: "EV018817: Dangerous conduit"
  },
  {
    valueId: "EV018818",
    valueDesc: "CCTV area",
    label: "EV018818: CCTV area"
  },
  {
    valueId: "EV018819",
    valueDesc: "Photovoltaics (PV)",
    label: "EV018819: Photovoltaics (PV)"
  },
  {
    valueId: "EV018820",
    valueDesc: "Laser radiation",
    label: "EV018820: Laser radiation"
  },
  {
    valueId: "EV018821",
    valueDesc: "Room air or outside air",
    label: "EV018821: Room air or outside air"
  },
  {
    valueId: "EV018824",
    valueDesc: "Draining rack",
    label: "EV018824: Draining rack"
  },
  {
    valueId: "EV018825",
    valueDesc: "Drip tray",
    label: "EV018825: Drip tray"
  },
  {
    valueId: "EV018826",
    valueDesc: "Draining mat",
    label: "EV018826: Draining mat"
  },
  {
    valueId: "EV018827",
    valueDesc: "M16.5 x 1.0",
    label: "EV018827: M16.5 x 1.0"
  },
  {
    valueId: "EV018828",
    valueDesc: "Japanese pull saw",
    label: "EV018828: Japanese pull saw"
  },
  {
    valueId: "EV018830",
    valueDesc: "Wall board",
    label: "EV018830: Wall board"
  },
  {
    valueId: "EV018831",
    valueDesc: "Sarg clamp",
    label: "EV018831: Sarg clamp"
  },
  {
    valueId: "EV018832",
    valueDesc: "Flood connection piece",
    label: "EV018832: Flood connection piece"
  },
  {
    valueId: "EV018833",
    valueDesc: "PN 6.3",
    label: "EV018833: PN 6.3"
  },
  {
    valueId: "EV018834",
    valueDesc: "Installation board",
    label: "EV018834: Installation board"
  },
  {
    valueId: "EV018835",
    valueDesc: "Outlet piece",
    label: "EV018835: Outlet piece"
  },
  {
    valueId: "EV018840",
    valueDesc: "Foam concrete",
    label: "EV018840: Foam concrete"
  },
  {
    valueId: "EV018848",
    valueDesc: "For separated conductors",
    label: "EV018848: For separated conductors"
  },
  {
    valueId: "EV018849",
    valueDesc: "For conductors bound in cable",
    label: "EV018849: For conductors bound in cable"
  },
  {
    valueId: "EV018850",
    valueDesc: "Plug-in connector for plug-in building installation",
    label: "EV018850: Plug-in connector for plug-in building installation"
  },
  {
    valueId: "EV018852",
    valueDesc: "Plejd",
    label: "EV018852: Plejd"
  },
  {
    valueId: "EV018853",
    valueDesc: "Single Zhaga book 18",
    label: "EV018853: Single Zhaga book 18"
  },
  {
    valueId: "EV018854",
    valueDesc: "Dual Zhaga book 18",
    label: "EV018854: Dual Zhaga book 18"
  },
  {
    valueId: "EV018855",
    valueDesc: "ANSI C136.41",
    label: "EV018855: ANSI C136.41"
  },
  {
    valueId: "EV018856",
    valueDesc: "Impregnated cellulose paper",
    label: "EV018856: Impregnated cellulose paper"
  },
  {
    valueId: "EV018857",
    valueDesc: "5-hole",
    label: "EV018857: 5-hole"
  },
  {
    valueId: "EV018858",
    valueDesc: "C1F",
    label: "EV018858: C1F"
  },
  {
    valueId: "EV018859",
    valueDesc: "C2F",
    label: "EV018859: C2F"
  },
  {
    valueId: "EV018860",
    valueDesc: "C1T",
    label: "EV018860: C1T"
  },
  {
    valueId: "EV018861",
    valueDesc: "C2T",
    label: "EV018861: C2T"
  },
  {
    valueId: "EV018862",
    valueDesc: "C1FT",
    label: "EV018862: C1FT"
  },
  {
    valueId: "EV018863",
    valueDesc: "C2FT",
    label: "EV018863: C2FT"
  },
  {
    valueId: "EV018864",
    valueDesc: "C2E",
    label: "EV018864: C2E"
  },
  {
    valueId: "EV018865",
    valueDesc: "C2FE",
    label: "EV018865: C2FE"
  },
  {
    valueId: "EV018866",
    valueDesc: "C2FTE",
    label: "EV018866: C2FTE"
  },
  {
    valueId: "EV018867",
    valueDesc: "C2TE",
    label: "EV018867: C2TE"
  },
  {
    valueId: "EV018868",
    valueDesc: "D1T",
    label: "EV018868: D1T"
  },
  {
    valueId: "EV018869",
    valueDesc: "D2T",
    label: "EV018869: D2T"
  },
  {
    valueId: "EV018870",
    valueDesc: "D2E",
    label: "EV018870: D2E"
  },
  {
    valueId: "EV018871",
    valueDesc: "D2TE",
    label: "EV018871: D2TE"
  },
  {
    valueId: "EV018872",
    valueDesc: "R1T",
    label: "EV018872: R1T"
  },
  {
    valueId: "EV018873",
    valueDesc: "R2T",
    label: "EV018873: R2T"
  },
  {
    valueId: "EV018874",
    valueDesc: "10x35 mm",
    label: "EV018874: 10x35 mm"
  },
  {
    valueId: "EV018875",
    valueDesc: "GOST",
    label: "EV018875: GOST"
  },
  {
    valueId: "EV018876",
    valueDesc: "Flat metal",
    label: "EV018876: Flat metal"
  },
  {
    valueId: "EV018877",
    valueDesc: "Metal tip",
    label: "EV018877: Metal tip"
  },
  {
    valueId: "EV018878",
    valueDesc: "Thermoplastic polyurethane (TPU)",
    label: "EV018878: Thermoplastic polyurethane (TPU)"
  },
  {
    valueId: "EV018879",
    valueDesc: "15 min",
    label: "EV018879: 15 min"
  },
  {
    valueId: "EV018880",
    valueDesc: "Polyurethane (PUR) + Thermoplastic polyurethane (TPU)",
    label: "EV018880: Polyurethane (PUR) + Thermoplastic polyurethane (TPU)"
  },
  {
    valueId: "EV018881",
    valueDesc: "Rubber + Rubber - ethylene-vinyl acetate (EVA)",
    label: "EV018881: Rubber + Rubber - ethylene-vinyl acetate (EVA)"
  },
  {
    valueId: "EV018882",
    valueDesc: "Polyurethane (PUR) + NBR (nitrile rubber)",
    label: "EV018882: Polyurethane (PUR) + NBR (nitrile rubber)"
  },
  {
    valueId: "EV018883",
    valueDesc: "Coil/battery",
    label: "EV018883: Coil/battery"
  },
  {
    valueId: "EV018884",
    valueDesc: "Copper sheathed steel",
    label: "EV018884: Copper sheathed steel"
  },
  {
    valueId: "EV018886",
    valueDesc: "Eye/eye",
    label: "EV018886: Eye/eye"
  },
  {
    valueId: "EV018887",
    valueDesc: "Eye/hook",
    label: "EV018887: Eye/hook"
  },
  {
    valueId: "EV018888",
    valueDesc: "Hook/hook",
    label: "EV018888: Hook/hook"
  },
  {
    valueId: "EV018940",
    valueDesc: "Close coupled",
    label: "EV018940: Close coupled"
  },
  {
    valueId: "EV018941",
    valueDesc: "F/TH",
    label: "EV018941: F/TH"
  },
  {
    valueId: "EV018942",
    valueDesc: "Mounting adhesive",
    label: "EV018942: Mounting adhesive"
  },
  {
    valueId: "EV018944",
    valueDesc: "Snap hook",
    label: "EV018944: Snap hook"
  },
  {
    valueId: "EV018945",
    valueDesc: "Simplex hook",
    label: "EV018945: Simplex hook"
  },
  {
    valueId: "EV018946",
    valueDesc: "Clevis hook",
    label: "EV018946: Clevis hook"
  },
  {
    valueId: "EV018947",
    valueDesc: "Dispersion adhesive",
    label: "EV018947: Dispersion adhesive"
  },
  {
    valueId: "EV018948",
    valueDesc: "Powder adhesive",
    label: "EV018948: Powder adhesive"
  },
  {
    valueId: "EV018949",
    valueDesc: "Multi component adhesive",
    label: "EV018949: Multi component adhesive"
  },
  {
    valueId: "EV018950",
    valueDesc: "S1 (>=2.5 mm)",
    label: "EV018950: S1 (>=2.5 mm)"
  },
  {
    valueId: "EV018951",
    valueDesc: "S2 (>5 mm)",
    label: "EV018951: S2 (>5 mm)"
  },
  {
    valueId: "EV018952",
    valueDesc: "RM",
    label: "EV018952: RM"
  },
  {
    valueId: "EV018953",
    valueDesc: "CM01",
    label: "EV018953: CM01"
  },
  {
    valueId: "EV018954",
    valueDesc: "CM02",
    label: "EV018954: CM02"
  },
  {
    valueId: "EV018955",
    valueDesc: "DM01",
    label: "EV018955: DM01"
  },
  {
    valueId: "EV018956",
    valueDesc: "DM02",
    label: "EV018956: DM02"
  },
  {
    valueId: "EV018957",
    valueDesc: "RM01",
    label: "EV018957: RM01"
  },
  {
    valueId: "EV018958",
    valueDesc: "RM02",
    label: "EV018958: RM02"
  },
  {
    valueId: "EV018959",
    valueDesc: "CMP",
    label: "EV018959: CMP"
  },
  {
    valueId: "EV018960",
    valueDesc: "RMP",
    label: "EV018960: RMP"
  },
  {
    valueId: "EV018961",
    valueDesc: "CM01P",
    label: "EV018961: CM01P"
  },
  {
    valueId: "EV018962",
    valueDesc: "CM02P",
    label: "EV018962: CM02P"
  },
  {
    valueId: "EV018963",
    valueDesc: "DM01P",
    label: "EV018963: DM01P"
  },
  {
    valueId: "EV018964",
    valueDesc: "DM02P",
    label: "EV018964: DM02P"
  },
  {
    valueId: "EV018965",
    valueDesc: "RM01P",
    label: "EV018965: RM01P"
  },
  {
    valueId: "EV018966",
    valueDesc: "RM02P",
    label: "EV018966: RM02P"
  },
  {
    valueId: "EV018967",
    valueDesc: "DMP",
    label: "EV018967: DMP"
  },
  {
    valueId: "EV018968",
    valueDesc: "EC1",
    label: "EV018968: EC1"
  },
  {
    valueId: "EV018969",
    valueDesc: "EC1 plus",
    label: "EV018969: EC1 plus"
  },
  {
    valueId: "EV018970",
    valueDesc: "EC2",
    label: "EV018970: EC2"
  },
  {
    valueId: "EV018971",
    valueDesc: "CM",
    label: "EV018971: CM"
  },
  {
    valueId: "EV018972",
    valueDesc: "DM",
    label: "EV018972: DM"
  },
  {
    valueId: "EV018973",
    valueDesc: "Disc diffuser",
    label: "EV018973: Disc diffuser"
  },
  {
    valueId: "EV018974",
    valueDesc: "Plate diffuser",
    label: "EV018974: Plate diffuser"
  },
  {
    valueId: "EV018975",
    valueDesc: "Strip diffuser",
    label: "EV018975: Strip diffuser"
  },
  {
    valueId: "EV018976",
    valueDesc: "Tube diffuser",
    label: "EV018976: Tube diffuser"
  },
  {
    valueId: "EV018977",
    valueDesc: "C1E",
    label: "EV018977: C1E"
  },
  {
    valueId: "EV018978",
    valueDesc: "C1TE",
    label: "EV018978: C1TE"
  },
  {
    valueId: "EV018979",
    valueDesc: "C1FE",
    label: "EV018979: C1FE"
  },
  {
    valueId: "EV018980",
    valueDesc: "D1E",
    label: "EV018980: D1E"
  },
  {
    valueId: "EV018981",
    valueDesc: "D1TE",
    label: "EV018981: D1TE"
  },
  {
    valueId: "EV018984",
    valueDesc: "SC/APC + LC/UPC",
    label: "EV018984: SC/APC + LC/UPC"
  },
  {
    valueId: "EV018985",
    valueDesc: "LC/UPC + LC/APC",
    label: "EV018985: LC/UPC + LC/APC"
  },
  {
    valueId: "EV018988",
    valueDesc: "Plate heat exchanger",
    label: "EV018988: Plate heat exchanger"
  },
  {
    valueId: "EV018990",
    valueDesc: "Shower unit",
    label: "EV018990: Shower unit"
  },
  {
    valueId: "EV018991",
    valueDesc: "Electronics",
    label: "EV018991: Electronics"
  },
  {
    valueId: "EV018992",
    valueDesc: "Hot air dryer",
    label: "EV018992: Hot air dryer"
  },
  {
    valueId: "EV018993",
    valueDesc: "Smell extraction",
    label: "EV018993: Smell extraction"
  },
  {
    valueId: "EV018994",
    valueDesc: "Control terminal strip unit",
    label: "EV018994: Control terminal strip unit"
  },
  {
    valueId: "EV018996",
    valueDesc: "Mounted bearing",
    label: "EV018996: Mounted bearing"
  },
  {
    valueId: "EV018997",
    valueDesc: "Gear reducer",
    label: "EV018997: Gear reducer"
  },
  {
    valueId: "EV018998",
    valueDesc: "Mounted bearing/gear reducer",
    label: "EV018998: Mounted bearing/gear reducer"
  },
  {
    valueId: "EV018999",
    valueDesc: "Generic machine",
    label: "EV018999: Generic machine"
  },
  {
    valueId: "EV019007",
    valueDesc: "IEEE 802.15.4",
    label: "EV019007: IEEE 802.15.4"
  },
  {
    valueId: "EV019008",
    valueDesc: "IEEE 802.15.1",
    label: "EV019008: IEEE 802.15.1"
  },
  {
    valueId: "EV019009",
    valueDesc: "128-bit AES",
    label: "EV019009: 128-bit AES"
  },
  {
    valueId: "EV019010",
    valueDesc: "256-bit AES",
    label: "EV019010: 256-bit AES"
  },
  {
    valueId: "EV019011",
    valueDesc: "IEC 62351",
    label: "EV019011: IEC 62351"
  },
  {
    valueId: "EV019012",
    valueDesc: "Lithium Thionylchloride (Li/SOCI2)",
    label: "EV019012: Lithium Thionylchloride (Li/SOCI2)"
  },
  {
    valueId: "EV019013",
    valueDesc: "Lithium Permanganate (Li/MnO4)",
    label: "EV019013: Lithium Permanganate (Li/MnO4)"
  },
  {
    valueId: "EV019014",
    valueDesc: "I, IIC, IIIC",
    label: "EV019014: I, IIC, IIIC"
  },
  {
    valueId: "EV019015",
    valueDesc: "IIC, IIIC",
    label: "EV019015: IIC, IIIC"
  },
  {
    valueId: "EV019016",
    valueDesc: "Shower toilet pump",
    label: "EV019016: Shower toilet pump"
  },
  {
    valueId: "EV019017",
    valueDesc: "Light module",
    label: "EV019017: Light module"
  },
  {
    valueId: "EV019018",
    valueDesc: "Sound module",
    label: "EV019018: Sound module"
  },
  {
    valueId: "EV019023",
    valueDesc: "Auto open and close unit",
    label: "EV019023: Auto open and close unit"
  },
  {
    valueId: "EV019027",
    valueDesc: "High (>=1 N/mm²)",
    label: "EV019027: High (>=1 N/mm²)"
  },
  {
    valueId: "EV019028",
    valueDesc: "Normal (0.5 - 1 N/mm²)",
    label: "EV019028: Normal (0.5 - 1 N/mm²)"
  },
  {
    valueId: "EV019039",
    valueDesc: "B38",
    label: "EV019039: B38"
  },
  {
    valueId: "EV019040",
    valueDesc: "ST35",
    label: "EV019040: ST35"
  },
  {
    valueId: "EV019041",
    valueDesc: "T65",
    label: "EV019041: T65"
  },
  {
    valueId: "EV019042",
    valueDesc: "P48",
    label: "EV019042: P48"
  },
  {
    valueId: "EV019043",
    valueDesc: "P50",
    label: "EV019043: P50"
  },
  {
    valueId: "EV019044",
    valueDesc: "B35",
    label: "EV019044: B35"
  },
  {
    valueId: "EV019045",
    valueDesc: "ST64",
    label: "EV019045: ST64"
  },
  {
    valueId: "EV019046",
    valueDesc: "BA35",
    label: "EV019046: BA35"
  },
  {
    valueId: "EV019047",
    valueDesc: "BA38",
    label: "EV019047: BA38"
  },
  {
    valueId: "EV019048",
    valueDesc: "B40",
    label: "EV019048: B40"
  },
  {
    valueId: "EV019049",
    valueDesc: "T32",
    label: "EV019049: T32"
  },
  {
    valueId: "EV019050",
    valueDesc: "G200",
    label: "EV019050: G200"
  },
  {
    valueId: "EV019051",
    valueDesc: "G120",
    label: "EV019051: G120"
  },
  {
    valueId: "EV019052",
    valueDesc: "G93",
    label: "EV019052: G93"
  },
  {
    valueId: "EV019053",
    valueDesc: "British Telecom master telephone outlet",
    label: "EV019053: British Telecom master telephone outlet"
  },
  {
    valueId: "EV019055",
    valueDesc: "Fire protection set",
    label: "EV019055: Fire protection set"
  },
  {
    valueId: "EV019057",
    valueDesc: "Fire protection sleeve",
    label: "EV019057: Fire protection sleeve"
  },
  {
    valueId: "EV019061",
    valueDesc: "Hair trap",
    label: "EV019061: Hair trap"
  },
  {
    valueId: "EV019063",
    valueDesc: "Sound insulation mat",
    label: "EV019063: Sound insulation mat"
  },
  {
    valueId: "EV019064",
    valueDesc: "RT 0",
    label: "EV019064: RT 0"
  },
  {
    valueId: "EV019065",
    valueDesc: "RT I",
    label: "EV019065: RT I"
  },
  {
    valueId: "EV019066",
    valueDesc: "RT II",
    label: "EV019066: RT II"
  },
  {
    valueId: "EV019067",
    valueDesc: "RT III",
    label: "EV019067: RT III"
  },
  {
    valueId: "EV019068",
    valueDesc: "RT IV",
    label: "EV019068: RT IV"
  },
  {
    valueId: "EV019069",
    valueDesc: "RT V",
    label: "EV019069: RT V"
  },
  {
    valueId: "EV019070",
    valueDesc: "gG (cable protection/equipment protection)",
    label: "EV019070: gG (cable protection/equipment protection)"
  },
  {
    valueId: "EV019071",
    valueDesc: "Gabbro",
    label: "EV019071: Gabbro"
  },
  {
    valueId: "EV019072",
    valueDesc: "4/40 mm graded coarse aggregate",
    label: "EV019072: 4/40 mm graded coarse aggregate"
  },
  {
    valueId: "EV019073",
    valueDesc: "4/20 mm graded coarse aggregate",
    label: "EV019073: 4/20 mm graded coarse aggregate"
  },
  {
    valueId: "EV019074",
    valueDesc: "2/14 mm graded coarse aggregate",
    label: "EV019074: 2/14 mm graded coarse aggregate"
  },
  {
    valueId: "EV019075",
    valueDesc: "20/40 mm single sized coarse aggregate",
    label: "EV019075: 20/40 mm single sized coarse aggregate"
  },
  {
    valueId: "EV019076",
    valueDesc: "14/20 mm single sized aggregate",
    label: "EV019076: 14/20 mm single sized aggregate"
  },
  {
    valueId: "EV019077",
    valueDesc: "10/20 mm single sized aggregate",
    label: "EV019077: 10/20 mm single sized aggregate"
  },
  {
    valueId: "EV019078",
    valueDesc: "8/14 mm single sized coarse aggregate",
    label: "EV019078: 8/14 mm single sized coarse aggregate"
  },
  {
    valueId: "EV019079",
    valueDesc: "6.3/14 mm single sized coarse aggregate",
    label: "EV019079: 6.3/14 mm single sized coarse aggregate"
  },
  {
    valueId: "EV019080",
    valueDesc: "6.3/10 mm single sized coarse aggregate",
    label: "EV019080: 6.3/10 mm single sized coarse aggregate"
  },
  {
    valueId: "EV019081",
    valueDesc: "4/10 mm single sized coarse aggregate",
    label: "EV019081: 4/10 mm single sized coarse aggregate"
  },
  {
    valueId: "EV019082",
    valueDesc: "2.8/6.3 mm single sized coarse aggregate",
    label: "EV019082: 2.8/6.3 mm single sized coarse aggregate"
  },
  {
    valueId: "EV019083",
    valueDesc: "2/6.3 mm single sized coarse aggregate",
    label: "EV019083: 2/6.3 mm single sized coarse aggregate"
  },
  {
    valueId: "EV019084",
    valueDesc: "2/4 mm single sized fine aggregate",
    label: "EV019084: 2/4 mm single sized fine aggregate"
  },
  {
    valueId: "EV019085",
    valueDesc: "1/4 mm single sized fine aggregate",
    label: "EV019085: 1/4 mm single sized fine aggregate"
  },
  {
    valueId: "EV019086",
    valueDesc: "0/40 mm all in aggregate",
    label: "EV019086: 0/40 mm all in aggregate"
  },
  {
    valueId: "EV019087",
    valueDesc: "0/20 mm all in aggregate",
    label: "EV019087: 0/20 mm all in aggregate"
  },
  {
    valueId: "EV019088",
    valueDesc: "0/10 mm all in aggregate",
    label: "EV019088: 0/10 mm all in aggregate"
  },
  {
    valueId: "EV019089",
    valueDesc: "0/6.3 mm all in aggregate",
    label: "EV019089: 0/6.3 mm all in aggregate"
  },
  {
    valueId: "EV019093",
    valueDesc: "0/4 mm (CP) coarse sand",
    label: "EV019093: 0/4 mm (CP) coarse sand"
  },
  {
    valueId: "EV019094",
    valueDesc: "0/4 mm (MP) medium sand",
    label: "EV019094: 0/4 mm (MP) medium sand"
  },
  {
    valueId: "EV019095",
    valueDesc: "0/2 mm (MP) - medium sand",
    label: "EV019095: 0/2 mm (MP) - medium sand"
  },
  {
    valueId: "EV019096",
    valueDesc: "0/2 mm (FP) - fine sand",
    label: "EV019096: 0/2 mm (FP) - fine sand"
  },
  {
    valueId: "EV019097",
    valueDesc: "0/1 mm (FP) - fine sand",
    label: "EV019097: 0/1 mm (FP) - fine sand"
  },
  {
    valueId: "EV019099",
    valueDesc: "CEM I-SR 0",
    label: "EV019099: CEM I-SR 0"
  },
  {
    valueId: "EV019100",
    valueDesc: "CEM I-SR 3",
    label: "EV019100: CEM I-SR 3"
  },
  {
    valueId: "EV019101",
    valueDesc: "CEM II/A-D",
    label: "EV019101: CEM II/A-D"
  },
  {
    valueId: "EV019102",
    valueDesc: "CEM II/A-L",
    label: "EV019102: CEM II/A-L"
  },
  {
    valueId: "EV019103",
    valueDesc: "CEM II/A-LL",
    label: "EV019103: CEM II/A-LL"
  },
  {
    valueId: "EV019104",
    valueDesc: "CEM II/A-S",
    label: "EV019104: CEM II/A-S"
  },
  {
    valueId: "EV019105",
    valueDesc: "CEM II/B-S",
    label: "EV019105: CEM II/B-S"
  },
  {
    valueId: "EV019106",
    valueDesc: "CEM II/A-P",
    label: "EV019106: CEM II/A-P"
  },
  {
    valueId: "EV019107",
    valueDesc: "CEM II/B-P",
    label: "EV019107: CEM II/B-P"
  },
  {
    valueId: "EV019108",
    valueDesc: "CEM II/B-P+SR",
    label: "EV019108: CEM II/B-P+SR"
  },
  {
    valueId: "EV019109",
    valueDesc: "CEM II/A-Q",
    label: "EV019109: CEM II/A-Q"
  },
  {
    valueId: "EV019110",
    valueDesc: "CEM II/B-Q",
    label: "EV019110: CEM II/B-Q"
  },
  {
    valueId: "EV019111",
    valueDesc: "CEM II/B-Q+SR",
    label: "EV019111: CEM II/B-Q+SR"
  },
  {
    valueId: "EV019112",
    valueDesc: "CEM II/A-V",
    label: "EV019112: CEM II/A-V"
  },
  {
    valueId: "EV019113",
    valueDesc: "CEM II/B-V",
    label: "EV019113: CEM II/B-V"
  },
  {
    valueId: "EV019114",
    valueDesc: "CEM II/B-V+SR",
    label: "EV019114: CEM II/B-V+SR"
  },
  {
    valueId: "EV019115",
    valueDesc: "CEM II/A-M (S-L or LL)",
    label: "EV019115: CEM II/A-M (S-L or LL)"
  },
  {
    valueId: "EV019116",
    valueDesc: "CEM II/A-M (L or LL-S)",
    label: "EV019116: CEM II/A-M (L or LL-S)"
  },
  {
    valueId: "EV019117",
    valueDesc: "CEM II/A-M (P or Q-L or LL)",
    label: "EV019117: CEM II/A-M (P or Q-L or LL)"
  },
  {
    valueId: "EV019118",
    valueDesc: "CEM II/A-M (L or LL-P or Q)",
    label: "EV019118: CEM II/A-M (L or LL-P or Q)"
  },
  {
    valueId: "EV019120",
    valueDesc: "A, B",
    label: "EV019120: A, B"
  },
  {
    valueId: "EV019121",
    valueDesc: "A, B, C",
    label: "EV019121: A, B, C"
  },
  {
    valueId: "EV019122",
    valueDesc: "A, B, C, D, E",
    label: "EV019122: A, B, C, D, E"
  },
  {
    valueId: "EV019123",
    valueDesc: "PE-RT 0",
    label: "EV019123: PE-RT 0"
  },
  {
    valueId: "EV019124",
    valueDesc: "Grey/black",
    label: "EV019124: Grey/black"
  },
  {
    valueId: "EV019125",
    valueDesc: "+, -",
    label: "EV019125: +, -"
  },
  {
    valueId: "EV019126",
    valueDesc: "+, -, PE",
    label: "EV019126: +, -, PE"
  },
  {
    valueId: "EV019127",
    valueDesc: "1, 2, 3, 4",
    label: "EV019127: 1, 2, 3, 4"
  },
  {
    valueId: "EV019128",
    valueDesc: "1, 2, 3, 4, 5/N",
    label: "EV019128: 1, 2, 3, 4, 5/N"
  },
  {
    valueId: "EV019129",
    valueDesc: "1, 2, 3, 4, N, PE",
    label: "EV019129: 1, 2, 3, 4, N, PE"
  },
  {
    valueId: "EV019130",
    valueDesc: "1, 2, 3, 4, PE",
    label: "EV019130: 1, 2, 3, 4, PE"
  },
  {
    valueId: "EV019131",
    valueDesc: "1, 2, 3, 4/N",
    label: "EV019131: 1, 2, 3, 4/N"
  },
  {
    valueId: "EV019132",
    valueDesc: "1, 2, 3, 4/N, 5",
    label: "EV019132: 1, 2, 3, 4/N, 5"
  },
  {
    valueId: "EV019133",
    valueDesc: "1, 2, 3, 4/N, 5, 6, 7",
    label: "EV019133: 1, 2, 3, 4/N, 5, 6, 7"
  },
  {
    valueId: "EV019134",
    valueDesc: "1, 2, 3, 4/N, 5, 6, PE",
    label: "EV019134: 1, 2, 3, 4/N, 5, 6, PE"
  },
  {
    valueId: "EV019135",
    valueDesc: "1, 2, PE, 3, 4/N",
    label: "EV019135: 1, 2, PE, 3, 4/N"
  },
  {
    valueId: "EV019136",
    valueDesc: "1, 2, PE, 3/N",
    label: "EV019136: 1, 2, PE, 3/N"
  },
  {
    valueId: "EV019137",
    valueDesc: "1, 2, PE, 3/N, 4",
    label: "EV019137: 1, 2, PE, 3/N, 4"
  },
  {
    valueId: "EV019138",
    valueDesc: "1, 2/N",
    label: "EV019138: 1, 2/N"
  },
  {
    valueId: "EV019139",
    valueDesc: "1, 2/N, 3",
    label: "EV019139: 1, 2/N, 3"
  },
  {
    valueId: "EV019140",
    valueDesc: "1, 2/N, 3, 4",
    label: "EV019140: 1, 2/N, 3, 4"
  },
  {
    valueId: "EV019141",
    valueDesc: "1, 2/N, 3, 4, 5",
    label: "EV019141: 1, 2/N, 3, 4, 5"
  },
  {
    valueId: "EV019142",
    valueDesc: "1, 2/N, 3, 4, PE",
    label: "EV019142: 1, 2/N, 3, 4, PE"
  },
  {
    valueId: "EV019145",
    valueDesc: "Tank-in-tank (double walled)",
    label: "EV019145: Tank-in-tank (double walled)"
  },
  {
    valueId: "EV019146",
    valueDesc: "Reel cabinet door",
    label: "EV019146: Reel cabinet door"
  },
  {
    valueId: "EV019148",
    valueDesc: "Device connector (IEC 60320)",
    label: "EV019148: Device connector (IEC 60320)"
  },
  {
    valueId: "EV019149",
    valueDesc: "Protective contact plug CEE 7/4 (type F)",
    label: "EV019149: Protective contact plug CEE 7/4 (type F)"
  },
  {
    valueId: "EV019151",
    valueDesc: "Gluing",
    label: "EV019151: Gluing"
  },
  {
    valueId: "EV019152",
    valueDesc: "Dry stacking",
    label: "EV019152: Dry stacking"
  },
  {
    valueId: "EV019153",
    valueDesc: "Masonry",
    label: "EV019153: Masonry"
  },
  {
    valueId: "EV019163",
    valueDesc: "5/15",
    label: "EV019163: 5/15"
  },
  {
    valueId: "EV019164",
    valueDesc: "5/25",
    label: "EV019164: 5/25"
  },
  {
    valueId: "EV019166",
    valueDesc: "DN 34",
    label: "EV019166: DN 34"
  },
  {
    valueId: "EV019167",
    valueDesc: "DN 41",
    label: "EV019167: DN 41"
  },
  {
    valueId: "EV019168",
    valueDesc: "DN 54",
    label: "EV019168: DN 54"
  },
  {
    valueId: "EV019169",
    valueDesc: "Standard factory setting",
    label: "EV019169: Standard factory setting"
  },
  {
    valueId: "EV019170",
    valueDesc: "Automatic settable",
    label: "EV019170: Automatic settable"
  },
  {
    valueId: "EV019171",
    valueDesc: "Manual settable",
    label: "EV019171: Manual settable"
  },
  {
    valueId: "EV019172",
    valueDesc: "By means of a conversion kit, included",
    label: "EV019172: By means of a conversion kit, included"
  },
  {
    valueId: "EV019173",
    valueDesc: "By means of a conversion kit, not included",
    label: "EV019173: By means of a conversion kit, not included"
  },
  {
    valueId: "EV019174",
    valueDesc: "Vacuum",
    label: "EV019174: Vacuum"
  },
  {
    valueId: "EV019175",
    valueDesc: "Atmospheric",
    label: "EV019175: Atmospheric"
  },
  {
    valueId: "EV019181",
    valueDesc: "CG1",
    label: "EV019181: CG1"
  },
  {
    valueId: "EV019182",
    valueDesc: "CG1A",
    label: "EV019182: CG1A"
  },
  {
    valueId: "EV019183",
    valueDesc: "CG2",
    label: "EV019183: CG2"
  },
  {
    valueId: "EV019184",
    valueDesc: "CG2A",
    label: "EV019184: CG2A"
  },
  {
    valueId: "EV019185",
    valueDesc: "CG2WA",
    label: "EV019185: CG2WA"
  },
  {
    valueId: "EV019186",
    valueDesc: "RG",
    label: "EV019186: RG"
  },
  {
    valueId: "EV019187",
    valueDesc: "Resin bonded",
    label: "EV019187: Resin bonded"
  },
  {
    valueId: "EV019193",
    valueDesc: "Hybrid plug CEE 7/7 (type E/F)",
    label: "EV019193: Hybrid plug CEE 7/7 (type E/F)"
  },
  {
    valueId: "EV019194",
    valueDesc: "Feed holder",
    label: "EV019194: Feed holder"
  },
  {
    valueId: "EV019195",
    valueDesc: "Feed adapter",
    label: "EV019195: Feed adapter"
  },
  {
    valueId: "EV019196",
    valueDesc: "T1+",
    label: "EV019196: T1+"
  },
  {
    valueId: "EV019197",
    valueDesc: "T2+",
    label: "EV019197: T2+"
  },
  {
    valueId: "EV019198",
    valueDesc: "R1+",
    label: "EV019198: R1+"
  },
  {
    valueId: "EV019199",
    valueDesc: "3,0",
    label: "EV019199: 3,0"
  },
  {
    valueId: "EV019200",
    valueDesc: "2,8",
    label: "EV019200: 2,8"
  },
  {
    valueId: "EV019201",
    valueDesc: "2,6",
    label: "EV019201: 2,6"
  },
  {
    valueId: "EV019202",
    valueDesc: "2,4",
    label: "EV019202: 2,4"
  },
  {
    valueId: "EV019203",
    valueDesc: "2,2",
    label: "EV019203: 2,2"
  },
  {
    valueId: "EV019204",
    valueDesc: "2,0",
    label: "EV019204: 2,0"
  },
  {
    valueId: "EV019205",
    valueDesc: "1,8",
    label: "EV019205: 1,8"
  },
  {
    valueId: "EV019206",
    valueDesc: "1,6",
    label: "EV019206: 1,6"
  },
  {
    valueId: "EV019207",
    valueDesc: "1,4",
    label: "EV019207: 1,4"
  },
  {
    valueId: "EV019208",
    valueDesc: "1,2",
    label: "EV019208: 1,2"
  },
  {
    valueId: "EV019209",
    valueDesc: "1,0",
    label: "EV019209: 1,0"
  },
  {
    valueId: "EV019210",
    valueDesc: "0,9",
    label: "EV019210: 0,9"
  },
  {
    valueId: "EV019211",
    valueDesc: "0,8",
    label: "EV019211: 0,8"
  },
  {
    valueId: "EV019212",
    valueDesc: "0,7",
    label: "EV019212: 0,7"
  },
  {
    valueId: "EV019213",
    valueDesc: "M50",
    label: "EV019213: M50"
  },
  {
    valueId: "EV019214",
    valueDesc: "0,6",
    label: "EV019214: 0,6"
  },
  {
    valueId: "EV019215",
    valueDesc: "SN6",
    label: "EV019215: SN6"
  },
  {
    valueId: "EV019216",
    valueDesc: "British Telecom secondary telephone outlet",
    label: "EV019216: British Telecom secondary telephone outlet"
  },
  {
    valueId: "EV019219",
    valueDesc: "Diplexer TV/radio",
    label: "EV019219: Diplexer TV/radio"
  },
  {
    valueId: "EV019220",
    valueDesc: "Diplexer TV/satellite",
    label: "EV019220: Diplexer TV/satellite"
  },
  {
    valueId: "EV019221",
    valueDesc: "Triplexer TV/radio/satellite",
    label: "EV019221: Triplexer TV/radio/satellite"
  },
  {
    valueId: "EV019222",
    valueDesc: "Quadplexer TV/radio/2 x satellite",
    label: "EV019222: Quadplexer TV/radio/2 x satellite"
  },
  {
    valueId: "EV019223",
    valueDesc: "1, 3, 4, N, PE",
    label: "EV019223: 1, 3, 4, N, PE"
  },
  {
    valueId: "EV019224",
    valueDesc: "1, PE, 2/N",
    label: "EV019224: 1, PE, 2/N"
  },
  {
    valueId: "EV019225",
    valueDesc: "1, PE, 2/N, 3",
    label: "EV019225: 1, PE, 2/N, 3"
  },
  {
    valueId: "EV019227",
    valueDesc: "1+, 2-, 2, N, PE, 1",
    label: "EV019227: 1+, 2-, 2, N, PE, 1"
  },
  {
    valueId: "EV019228",
    valueDesc: "D1, D2",
    label: "EV019228: D1, D2"
  },
  {
    valueId: "EV019229",
    valueDesc: "L, N, PE, 1, 2, 3, 4",
    label: "EV019229: L, N, PE, 1, 2, 3, 4"
  },
  {
    valueId: "EV019230",
    valueDesc: "L, N, PE, 1, 2, Ls",
    label: "EV019230: L, N, PE, 1, 2, Ls"
  },
  {
    valueId: "EV019231",
    valueDesc: "L, N, PE, L, N, PE",
    label: "EV019231: L, N, PE, L, N, PE"
  },
  {
    valueId: "EV019232",
    valueDesc: "N, E, 1, 2, 3",
    label: "EV019232: N, E, 1, 2, 3"
  },
  {
    valueId: "EV019233",
    valueDesc: "IP65/IP67/IP69",
    label: "EV019233: IP65/IP67/IP69"
  },
  {
    valueId: "EV019234",
    valueDesc: "IP65/IP68",
    label: "EV019234: IP65/IP68"
  },
  {
    valueId: "EV019235",
    valueDesc: "IP65/IP68/IP69",
    label: "EV019235: IP65/IP68/IP69"
  },
  {
    valueId: "EV019236",
    valueDesc: "IP66/IP68",
    label: "EV019236: IP66/IP68"
  },
  {
    valueId: "EV019237",
    valueDesc: "IP66/IP68/IP69",
    label: "EV019237: IP66/IP68/IP69"
  },
  {
    valueId: "EV019238",
    valueDesc: "Lamp post globe",
    label: "EV019238: Lamp post globe"
  },
  {
    valueId: "EV019239",
    valueDesc: "Luminaire grid",
    label: "EV019239: Luminaire grid"
  },
  {
    valueId: "EV019244",
    valueDesc: "1250 kcmil",
    label: "EV019244: 1250 kcmil"
  },
  {
    valueId: "EV019245",
    valueDesc: "1575 kcmil",
    label: "EV019245: 1575 kcmil"
  },
  {
    valueId: "EV019246",
    valueDesc: "1600 kcmil",
    label: "EV019246: 1600 kcmil"
  },
  {
    valueId: "EV019247",
    valueDesc: "2000 kcmil",
    label: "EV019247: 2000 kcmil"
  },
  {
    valueId: "EV019248",
    valueDesc: "2500 kcmil",
    label: "EV019248: 2500 kcmil"
  },
  {
    valueId: "EV019249",
    valueDesc: "Closed surface",
    label: "EV019249: Closed surface"
  },
  {
    valueId: "EV019250",
    valueDesc: "SC simplex",
    label: "EV019250: SC simplex"
  },
  {
    valueId: "EV019251",
    valueDesc: "N95",
    label: "EV019251: N95"
  },
  {
    valueId: "EV019252",
    valueDesc: "KN95",
    label: "EV019252: KN95"
  },
  {
    valueId: "EV019253",
    valueDesc: "Strain gauge",
    label: "EV019253: Strain gauge"
  },
  {
    valueId: "EV019255",
    valueDesc: "Top opening",
    label: "EV019255: Top opening"
  },
  {
    valueId: "EV019256",
    valueDesc: "Top and side opening",
    label: "EV019256: Top and side opening"
  },
  {
    valueId: "EV019257",
    valueDesc: "Finger coated",
    label: "EV019257: Finger coated"
  },
  {
    valueId: "EV019258",
    valueDesc: "CS I",
    label: "EV019258: CS I"
  },
  {
    valueId: "EV019259",
    valueDesc: "CS II",
    label: "EV019259: CS II"
  },
  {
    valueId: "EV019260",
    valueDesc: "CS III",
    label: "EV019260: CS III"
  },
  {
    valueId: "EV019261",
    valueDesc: "CS IV",
    label: "EV019261: CS IV"
  },
  {
    valueId: "EV019262",
    valueDesc: "Spiral bound",
    label: "EV019262: Spiral bound"
  },
  {
    valueId: "EV019263",
    valueDesc: "Loose sheets",
    label: "EV019263: Loose sheets"
  },
  {
    valueId: "EV019264",
    valueDesc: "Plug CEE 7/4 (type F)",
    label: "EV019264: Plug CEE 7/4 (type F)"
  },
  {
    valueId: "EV019266",
    valueDesc: "OSB/1",
    label: "EV019266: OSB/1"
  },
  {
    valueId: "EV019267",
    valueDesc: "OSB/2",
    label: "EV019267: OSB/2"
  },
  {
    valueId: "EV019268",
    valueDesc: "OSB/3",
    label: "EV019268: OSB/3"
  },
  {
    valueId: "EV019269",
    valueDesc: "OSB/4",
    label: "EV019269: OSB/4"
  },
  {
    valueId: "EV019270",
    valueDesc: "2+",
    label: "EV019270: 2+"
  },
  {
    valueId: "EV019271",
    valueDesc: "B4",
    label: "EV019271: B4"
  },
  {
    valueId: "EV019272",
    valueDesc: "B5",
    label: "EV019272: B5"
  },
  {
    valueId: "EV019273",
    valueDesc: "B6",
    label: "EV019273: B6"
  },
  {
    valueId: "EV019274",
    valueDesc: "B7",
    label: "EV019274: B7"
  },
  {
    valueId: "EV019326",
    valueDesc: "H2",
    label: "EV019326: H2"
  },
  {
    valueId: "EV019327",
    valueDesc: "B3",
    label: "EV019327: B3"
  },
  {
    valueId: "EV019328",
    valueDesc: "Cable insertion tool",
    label: "EV019328: Cable insertion tool"
  },
  {
    valueId: "EV019333",
    valueDesc: "Use class 1 (above ground, covered, dry conditions)",
    label: "EV019333: Use class 1 (above ground, covered, dry conditions)"
  },
  {
    valueId: "EV019334",
    valueDesc: "Use class 2 (above ground, covered, risk of wetting/humid conditions)",
    label: "EV019334: Use class 2 (above ground, covered, risk of wetting/humid conditions)"
  },
  {
    valueId: "EV019335",
    valueDesc: "Use class 3 (above ground, not covered, exterior conditions)",
    label: "EV019335: Use class 3 (above ground, not covered, exterior conditions)"
  },
  {
    valueId: "EV019336",
    valueDesc: "Use class 4 (in contact with ground or fresh waters)",
    label: "EV019336: Use class 4 (in contact with ground or fresh waters)"
  },
  {
    valueId: "EV019337",
    valueDesc: "Use class 5 (in salt waters)",
    label: "EV019337: Use class 5 (in salt waters)"
  },
  {
    valueId: "EV019338",
    valueDesc: "Melamine-Phenolic",
    label: "EV019338: Melamine-Phenolic"
  },
  {
    valueId: "EV019339",
    valueDesc: "Broom brace",
    label: "EV019339: Broom brace"
  },
  {
    valueId: "EV019340",
    valueDesc: "Pop-up valve with vertical lever",
    label: "EV019340: Pop-up valve with vertical lever"
  },
  {
    valueId: "EV019341",
    valueDesc: "Strainer waste valve",
    label: "EV019341: Strainer waste valve"
  },
  {
    valueId: "EV019342",
    valueDesc: "Strainer tilt plug waste valve",
    label: "EV019342: Strainer tilt plug waste valve"
  },
  {
    valueId: "EV019343",
    valueDesc: "Strainer plug waste valve",
    label: "EV019343: Strainer plug waste valve"
  },
  {
    valueId: "EV019344",
    valueDesc: "Industrial immersion heater",
    label: "EV019344: Industrial immersion heater"
  },
  {
    valueId: "EV019345",
    valueDesc: "Construction site radio",
    label: "EV019345: Construction site radio"
  },
  {
    valueId: "EV019346",
    valueDesc: "Absorption",
    label: "EV019346: Absorption"
  },
  {
    valueId: "EV019348",
    valueDesc: "Machinery dolly",
    label: "EV019348: Machinery dolly"
  },
  {
    valueId: "EV019349",
    valueDesc: "Barrel cart",
    label: "EV019349: Barrel cart"
  },
  {
    valueId: "EV019350",
    valueDesc: "M24 x 19tpi",
    label: "EV019350: M24 x 19tpi"
  },
  {
    valueId: "EV019352",
    valueDesc: "HDCVI",
    label: "EV019352: HDCVI"
  },
  {
    valueId: "EV019353",
    valueDesc: "IP66/IP67/IP6K6K",
    label: "EV019353: IP66/IP67/IP6K6K"
  },
  {
    valueId: "EV019354",
    valueDesc: "IP66/IP67/IP6K9K",
    label: "EV019354: IP66/IP67/IP6K9K"
  },
  {
    valueId: "EV019356",
    valueDesc: "Inlet/outlet flow",
    label: "EV019356: Inlet/outlet flow"
  },
  {
    valueId: "EV019357",
    valueDesc: "DN 32 - DN40",
    label: "EV019357: DN 32 - DN40"
  },
  {
    valueId: "EV019358",
    valueDesc: "Double connection, inlet-outlet",
    label: "EV019358: Double connection, inlet-outlet"
  },
  {
    valueId: "EV019359",
    valueDesc: "Double connection, outlet-inlet",
    label: "EV019359: Double connection, outlet-inlet"
  },
  {
    valueId: "EV019360",
    valueDesc: "1/2 inch (15) - 3/4 inch (20)",
    label: "EV019360: 1/2 inch (15) - 3/4 inch (20)"
  },
  {
    valueId: "EV019361",
    valueDesc: "Corn",
    label: "EV019361: Corn"
  },
  {
    valueId: "EV019362",
    valueDesc: "Edison",
    label: "EV019362: Edison"
  },
  {
    valueId: "EV019363",
    valueDesc: "Paint foil",
    label: "EV019363: Paint foil"
  },
  {
    valueId: "EV019364",
    valueDesc: "Boiler noise silencer",
    label: "EV019364: Boiler noise silencer"
  },
  {
    valueId: "EV019366",
    valueDesc: "Starter set",
    label: "EV019366: Starter set"
  },
  {
    valueId: "EV019368",
    valueDesc: "Cellulose fibre",
    label: "EV019368: Cellulose fibre"
  },
  {
    valueId: "EV019369",
    valueDesc: "Rebate (four edge)",
    label: "EV019369: Rebate (four edge)"
  },
  {
    valueId: "EV019370",
    valueDesc: "Rebate (long edge)",
    label: "EV019370: Rebate (long edge)"
  },
  {
    valueId: "EV019371",
    valueDesc: "Rebate (short edge)",
    label: "EV019371: Rebate (short edge)"
  },
  {
    valueId: "EV019376",
    valueDesc: "Chimney extension",
    label: "EV019376: Chimney extension"
  },
  {
    valueId: "EV019377",
    valueDesc: "Gluing and masonry",
    label: "EV019377: Gluing and masonry"
  },
  {
    valueId: "EV019378",
    valueDesc: "Factory made wood fibre (WF) according to EN 13171:2012+A1:2015",
    label: "EV019378: Factory made wood fibre (WF) according to EN 13171:2012+A1:2015"
  },
  {
    valueId: "EV019379",
    valueDesc: "Edge-trimmed",
    label: "EV019379: Edge-trimmed"
  },
  {
    valueId: "EV019380",
    valueDesc: "Edge-untrimmed",
    label: "EV019380: Edge-untrimmed"
  },
  {
    valueId: "EV019381",
    valueDesc: "Radio receiver module",
    label: "EV019381: Radio receiver module"
  },
  {
    valueId: "EV019382",
    valueDesc: "Table radio",
    label: "EV019382: Table radio"
  },
  {
    valueId: "EV019383",
    valueDesc: "Gravity",
    label: "EV019383: Gravity"
  },
  {
    valueId: "EV019388",
    valueDesc: "LTE",
    label: "EV019388: LTE"
  },
  {
    valueId: "EV019390",
    valueDesc: "Intermediate console",
    label: "EV019390: Intermediate console"
  },
  {
    valueId: "EV019391",
    valueDesc: "63/96 mm",
    label: "EV019391: 63/96 mm"
  },
  {
    valueId: "EV019392",
    valueDesc: "Absorption silencer",
    label: "EV019392: Absorption silencer"
  },
  {
    valueId: "EV019394",
    valueDesc: "Reflective silencer",
    label: "EV019394: Reflective silencer"
  },
  {
    valueId: "EV019395",
    valueDesc: "Double-end bar",
    label: "EV019395: Double-end bar"
  },
  {
    valueId: "EV019396",
    valueDesc: "<50 ms",
    label: "EV019396: <50 ms"
  },
  {
    valueId: "EV019397",
    valueDesc: "<200 ms",
    label: "EV019397: <200 ms"
  },
  {
    valueId: "EV019398",
    valueDesc: "<500 ms",
    label: "EV019398: <500 ms"
  },
  {
    valueId: "EV019399",
    valueDesc: "<1 s",
    label: "EV019399: <1 s"
  },
  {
    valueId: "EV019407",
    valueDesc: "Ü",
    label: "EV019407: Ü"
  },
  {
    valueId: "EV019408",
    valueDesc: "ß",
    label: "EV019408: ß"
  },
  {
    valueId: "EV019409",
    valueDesc: "Shaft - chimney insert",
    label: "EV019409: Shaft - chimney insert"
  },
  {
    valueId: "EV019411",
    valueDesc: "Euroconus standardless",
    label: "EV019411: Euroconus standardless"
  },
  {
    valueId: "EV019412",
    valueDesc: "Euroconus according to EN 16313",
    label: "EV019412: Euroconus according to EN 16313"
  },
  {
    valueId: "EV019413",
    valueDesc: "Handleless",
    label: "EV019413: Handleless"
  },
  {
    valueId: "EV019414",
    valueDesc: "QLED",
    label: "EV019414: QLED"
  },
  {
    valueId: "EV019415",
    valueDesc: "AZERTY",
    label: "EV019415: AZERTY"
  },
  {
    valueId: "EV019416",
    valueDesc: "QWERTZ",
    label: "EV019416: QWERTZ"
  },
  {
    valueId: "EV019417",
    valueDesc: "QWERTY",
    label: "EV019417: QWERTY"
  },
  {
    valueId: "EV019424",
    valueDesc: "Correction pen",
    label: "EV019424: Correction pen"
  },
  {
    valueId: "EV019426",
    valueDesc: "AC/AC",
    label: "EV019426: AC/AC"
  },
  {
    valueId: "EV019427",
    valueDesc: "DC/DC",
    label: "EV019427: DC/DC"
  },
  {
    valueId: "EV019428",
    valueDesc: "GNSS",
    label: "EV019428: GNSS"
  },
  {
    valueId: "EV019429",
    valueDesc: "ASCII+TOP",
    label: "EV019429: ASCII+TOP"
  },
  {
    valueId: "EV019430",
    valueDesc: "Precision Time Protocol (PTP), wired RJ45",
    label: "EV019430: Precision Time Protocol (PTP), wired RJ45"
  },
  {
    valueId: "EV019431",
    valueDesc: "Precision Time Protocol (PTP), fibre optic",
    label: "EV019431: Precision Time Protocol (PTP), fibre optic"
  },
  {
    valueId: "EV019432",
    valueDesc: "ASCII RS-232",
    label: "EV019432: ASCII RS-232"
  },
  {
    valueId: "EV019433",
    valueDesc: "PPS (Pulse-per-second signal)",
    label: "EV019433: PPS (Pulse-per-second signal)"
  },
  {
    valueId: "EV019434",
    valueDesc: "TCXO",
    label: "EV019434: TCXO"
  },
  {
    valueId: "EV019435",
    valueDesc: "OCXO",
    label: "EV019435: OCXO"
  },
  {
    valueId: "EV019436",
    valueDesc: "OCXO LN",
    label: "EV019436: OCXO LN"
  },
  {
    valueId: "EV019437",
    valueDesc: "DOCXO",
    label: "EV019437: DOCXO"
  },
  {
    valueId: "EV019438",
    valueDesc: "Rubidium",
    label: "EV019438: Rubidium"
  },
  {
    valueId: "EV019439",
    valueDesc: "Tape roller",
    label: "EV019439: Tape roller"
  },
  {
    valueId: "EV019441",
    valueDesc: "Diaphragm expansion vessel",
    label: "EV019441: Diaphragm expansion vessel"
  },
  {
    valueId: "EV019442",
    valueDesc: "Overflow monitoring",
    label: "EV019442: Overflow monitoring"
  },
  {
    valueId: "EV019445",
    valueDesc: "Magnetic valve",
    label: "EV019445: Magnetic valve"
  },
  {
    valueId: "EV019446",
    valueDesc: "Water exchange group",
    label: "EV019446: Water exchange group"
  },
  {
    valueId: "EV019448",
    valueDesc: "Automatic backwash system",
    label: "EV019448: Automatic backwash system"
  },
  {
    valueId: "EV019449",
    valueDesc: "Tension clamp",
    label: "EV019449: Tension clamp"
  },
  {
    valueId: "EV019450",
    valueDesc: "Suspension clamp",
    label: "EV019450: Suspension clamp"
  },
  {
    valueId: "EV019451",
    valueDesc: "Tie wire",
    label: "EV019451: Tie wire"
  },
  {
    valueId: "EV019452",
    valueDesc: "Silver tin oxide (AgSnO2)",
    label: "EV019452: Silver tin oxide (AgSnO2)"
  },
  {
    valueId: "EV019453",
    valueDesc: "Silver cadmium oxide (AgCdO)",
    label: "EV019453: Silver cadmium oxide (AgCdO)"
  },
  {
    valueId: "EV019454",
    valueDesc: "Silver nickel (AgNi)",
    label: "EV019454: Silver nickel (AgNi)"
  },
  {
    valueId: "EV019455",
    valueDesc: "<1 ms",
    label: "EV019455: <1 ms"
  },
  {
    valueId: "EV019459",
    valueDesc: "Plug CEE 7/6 (type E)",
    label: "EV019459: Plug CEE 7/6 (type E)"
  },
  {
    valueId: "EV019460",
    valueDesc: "Broomstick holder",
    label: "EV019460: Broomstick holder"
  },
  {
    valueId: "EV019461",
    valueDesc: "Extension for exhaust gas roof bushing",
    label: "EV019461: Extension for exhaust gas roof bushing"
  },
  {
    valueId: "EV019462",
    valueDesc: "Mug",
    label: "EV019462: Mug"
  },
  {
    valueId: "EV019463",
    valueDesc: "DN 710",
    label: "EV019463: DN 710"
  },
  {
    valueId: "EV019464",
    valueDesc: "Letters and digits assortment",
    label: "EV019464: Letters and digits assortment"
  },
  {
    valueId: "EV019465",
    valueDesc: "GPLM",
    label: "EV019465: GPLM"
  },
  {
    valueId: "EV019466",
    valueDesc: "TLMA",
    label: "EV019466: TLMA"
  },
  {
    valueId: "EV019467",
    valueDesc: "TLMB",
    label: "EV019467: TLMB"
  },
  {
    valueId: "EV019468",
    valueDesc: "Hot air",
    label: "EV019468: Hot air"
  },
  {
    valueId: "EV019471",
    valueDesc: "macOS",
    label: "EV019471: macOS"
  },
  {
    valueId: "EV019478",
    valueDesc: "Pan-Tilt",
    label: "EV019478: Pan-Tilt"
  },
  {
    valueId: "EV019479",
    valueDesc: "Averaging",
    label: "EV019479: Averaging"
  },
  {
    valueId: "EV019480",
    valueDesc: "True root mean square",
    label: "EV019480: True root mean square"
  },
  {
    valueId: "EV019481",
    valueDesc: "Ventilation heat exchanger",
    label: "EV019481: Ventilation heat exchanger"
  },
  {
    valueId: "EV019482",
    valueDesc: "Enthalpy heat exchanger",
    label: "EV019482: Enthalpy heat exchanger"
  },
  {
    valueId: "EV019483",
    valueDesc: "CW1128",
    label: "EV019483: CW1128"
  },
  {
    valueId: "EV019484",
    valueDesc: "CW1128/CW1198",
    label: "EV019484: CW1128/CW1198"
  },
  {
    valueId: "EV019485",
    valueDesc: "CW1308",
    label: "EV019485: CW1308"
  },
  {
    valueId: "EV019486",
    valueDesc: "CW1308B",
    label: "EV019486: CW1308B"
  },
  {
    valueId: "EV019487",
    valueDesc: "CW1600",
    label: "EV019487: CW1600"
  },
  {
    valueId: "EV019491",
    valueDesc: "1, 2/N, 3/L",
    label: "EV019491: 1, 2/N, 3/L"
  },
  {
    valueId: "EV019492",
    valueDesc: "D1, D2, PE",
    label: "EV019492: D1, D2, PE"
  },
  {
    valueId: "EV019493",
    valueDesc: "Pole hatch",
    label: "EV019493: Pole hatch"
  },
  {
    valueId: "EV019494",
    valueDesc: "ED90",
    label: "EV019494: ED90"
  },
  {
    valueId: "EV019496",
    valueDesc: "Chrome OS",
    label: "EV019496: Chrome OS"
  },
  {
    valueId: "EV019497",
    valueDesc: "Compound seal",
    label: "EV019497: Compound seal"
  },
  {
    valueId: "EV019498",
    valueDesc: "TV/SAT",
    label: "EV019498: TV/SAT"
  },
  {
    valueId: "EV019500",
    valueDesc: "TV/RD/SAT",
    label: "EV019500: TV/RD/SAT"
  },
  {
    valueId: "EV019502",
    valueDesc: "Taper shank arbor",
    label: "EV019502: Taper shank arbor"
  },
  {
    valueId: "EV019503",
    valueDesc: "Taper drift",
    label: "EV019503: Taper drift"
  },
  {
    valueId: "EV019504",
    valueDesc: "Reducing sleeve for drill shaft",
    label: "EV019504: Reducing sleeve for drill shaft"
  },
  {
    valueId: "EV019505",
    valueDesc: "Hanging scale",
    label: "EV019505: Hanging scale"
  },
  {
    valueId: "EV019506",
    valueDesc: "Crane scale",
    label: "EV019506: Crane scale"
  },
  {
    valueId: "EV019507",
    valueDesc: "Floor scale",
    label: "EV019507: Floor scale"
  },
  {
    valueId: "EV019508",
    valueDesc: "0.00001 Ohm",
    label: "EV019508: 0.00001 Ohm"
  },
  {
    valueId: "EV019509",
    valueDesc: "0.000001 Ohm",
    label: "EV019509: 0.000001 Ohm"
  },
  {
    valueId: "EV019511",
    valueDesc: "Drinking cup lid",
    label: "EV019511: Drinking cup lid"
  },
  {
    valueId: "EV019512",
    valueDesc: "Cake server",
    label: "EV019512: Cake server"
  },
  {
    valueId: "EV019513",
    valueDesc: "Replacement fan",
    label: "EV019513: Replacement fan"
  },
  {
    valueId: "EV019518",
    valueDesc: "IK11",
    label: "EV019518: IK11"
  },
  {
    valueId: "EV019519",
    valueDesc: "AC-3e",
    label: "EV019519: AC-3e"
  },
  {
    valueId: "EV019520",
    valueDesc: "AC-7a/AC-7b",
    label: "EV019520: AC-7a/AC-7b"
  },
  {
    valueId: "EV019521",
    valueDesc: "Cars",
    label: "EV019521: Cars"
  },
  {
    valueId: "EV019522",
    valueDesc: "Parallel to roof ridge",
    label: "EV019522: Parallel to roof ridge"
  },
  {
    valueId: "EV019523",
    valueDesc: "Perpendicular to roof ridge",
    label: "EV019523: Perpendicular to roof ridge"
  },
  {
    valueId: "EV019524",
    valueDesc: "Parallel and perpendicular to roof ridge",
    label: "EV019524: Parallel and perpendicular to roof ridge"
  },
  {
    valueId: "EV019527",
    valueDesc: "Standing seam panel roof",
    label: "EV019527: Standing seam panel roof"
  },
  {
    valueId: "EV019528",
    valueDesc: "Corrugated fibre cement roof",
    label: "EV019528: Corrugated fibre cement roof"
  },
  {
    valueId: "EV019541",
    valueDesc: "Powered by measurement",
    label: "EV019541: Powered by measurement"
  },
  {
    valueId: "EV019542",
    valueDesc: "Intel Core i9",
    label: "EV019542: Intel Core i9"
  },
  {
    valueId: "EV019543",
    valueDesc: "AMD Ryzen 3",
    label: "EV019543: AMD Ryzen 3"
  },
  {
    valueId: "EV019544",
    valueDesc: "AMD Ryzen 5",
    label: "EV019544: AMD Ryzen 5"
  },
  {
    valueId: "EV019545",
    valueDesc: "AMD Ryzen 7",
    label: "EV019545: AMD Ryzen 7"
  },
  {
    valueId: "EV019546",
    valueDesc: "AMD Ryzen 9",
    label: "EV019546: AMD Ryzen 9"
  },
  {
    valueId: "EV019547",
    valueDesc: "Brass, lead-free",
    label: "EV019547: Brass, lead-free"
  },
  {
    valueId: "EV019548",
    valueDesc: "Star",
    label: "EV019548: Star"
  },
  {
    valueId: "EV019549",
    valueDesc: "Forklift attachment",
    label: "EV019549: Forklift attachment"
  },
  {
    valueId: "EV019550",
    valueDesc: "FL9",
    label: "EV019550: FL9"
  },
  {
    valueId: "EV019551",
    valueDesc: "Dissolved oxygen",
    label: "EV019551: Dissolved oxygen"
  },
  {
    valueId: "EV019552",
    valueDesc: "Argentometry",
    label: "EV019552: Argentometry"
  },
  {
    valueId: "EV019553",
    valueDesc: "pH buffer solution",
    label: "EV019553: pH buffer solution"
  },
  {
    valueId: "EV019554",
    valueDesc: "Redox buffer solution",
    label: "EV019554: Redox buffer solution"
  },
  {
    valueId: "EV019555",
    valueDesc: "Conductivity standard solution",
    label: "EV019555: Conductivity standard solution"
  },
  {
    valueId: "EV019556",
    valueDesc: "Maintenance/cleaning solution",
    label: "EV019556: Maintenance/cleaning solution"
  },
  {
    valueId: "EV019557",
    valueDesc: "Storage solution",
    label: "EV019557: Storage solution"
  },
  {
    valueId: "EV019558",
    valueDesc: "Wire mesh pallet box",
    label: "EV019558: Wire mesh pallet box"
  },
  {
    valueId: "EV019559",
    valueDesc: "Pallet container",
    label: "EV019559: Pallet container"
  },
  {
    valueId: "EV019560",
    valueDesc: "Pallet stacking frame",
    label: "EV019560: Pallet stacking frame"
  },
  {
    valueId: "EV019561",
    valueDesc: "Four-way pallet",
    label: "EV019561: Four-way pallet"
  },
  {
    valueId: "EV019562",
    valueDesc: "HB.H",
    label: "EV019562: HB.H"
  },
  {
    valueId: "EV019563",
    valueDesc: "HB.E",
    label: "EV019563: HB.E"
  },
  {
    valueId: "EV019564",
    valueDesc: "HB.LA",
    label: "EV019564: HB.LA"
  },
  {
    valueId: "EV019566",
    valueDesc: "HB.HLA1",
    label: "EV019566: HB.HLA1"
  },
  {
    valueId: "EV019567",
    valueDesc: "HB.HLA2",
    label: "EV019567: HB.HLA2"
  },
  {
    valueId: "EV019568",
    valueDesc: "SB.H",
    label: "EV019568: SB.H"
  },
  {
    valueId: "EV019569",
    valueDesc: "SB.E",
    label: "EV019569: SB.E"
  },
  {
    valueId: "EV019570",
    valueDesc: "SB.LS",
    label: "EV019570: SB.LS"
  },
  {
    valueId: "EV019571",
    valueDesc: "SB.HLS",
    label: "EV019571: SB.HLS"
  },
  {
    valueId: "EV019572",
    valueDesc: "Trailer hitch",
    label: "EV019572: Trailer hitch"
  },
  {
    valueId: "EV019573",
    valueDesc: "75x75",
    label: "EV019573: 75x75"
  },
  {
    valueId: "EV019574",
    valueDesc: "100x100",
    label: "EV019574: 100x100"
  },
  {
    valueId: "EV019575",
    valueDesc: "200x100",
    label: "EV019575: 200x100"
  },
  {
    valueId: "EV019576",
    valueDesc: "200x200",
    label: "EV019576: 200x200"
  },
  {
    valueId: "EV019577",
    valueDesc: "300x200",
    label: "EV019577: 300x200"
  },
  {
    valueId: "EV019578",
    valueDesc: "300x300",
    label: "EV019578: 300x300"
  },
  {
    valueId: "EV019579",
    valueDesc: "400x200",
    label: "EV019579: 400x200"
  },
  {
    valueId: "EV019580",
    valueDesc: "400x300",
    label: "EV019580: 400x300"
  },
  {
    valueId: "EV019581",
    valueDesc: "400x400",
    label: "EV019581: 400x400"
  },
  {
    valueId: "EV019582",
    valueDesc: "600x400",
    label: "EV019582: 600x400"
  },
  {
    valueId: "EV019583",
    valueDesc: "21:9",
    label: "EV019583: 21:9"
  },
  {
    valueId: "EV019584",
    valueDesc: "5:4",
    label: "EV019584: 5:4"
  },
  {
    valueId: "EV019585",
    valueDesc: "Memory card",
    label: "EV019585: Memory card"
  },
  {
    valueId: "EV019586",
    valueDesc: "Banana plug 2 mm",
    label: "EV019586: Banana plug 2 mm"
  },
  {
    valueId: "EV019587",
    valueDesc: "Banana plug 4 mm",
    label: "EV019587: Banana plug 4 mm"
  },
  {
    valueId: "EV019588",
    valueDesc: "Label feed",
    label: "EV019588: Label feed"
  },
  {
    valueId: "EV019589",
    valueDesc: "Voltage",
    label: "EV019589: Voltage"
  },
  {
    valueId: "EV019590",
    valueDesc: "Voltage and current",
    label: "EV019590: Voltage and current"
  },
  {
    valueId: "EV019595",
    valueDesc: "Hungarian point",
    label: "EV019595: Hungarian point"
  },
  {
    valueId: "EV019596",
    valueDesc: "Fish scale",
    label: "EV019596: Fish scale"
  },
  {
    valueId: "EV019597",
    valueDesc: "Moroccan lantern",
    label: "EV019597: Moroccan lantern"
  },
  {
    valueId: "EV019599",
    valueDesc: "Muretto",
    label: "EV019599: Muretto"
  },
  {
    valueId: "EV019602",
    valueDesc: "V3",
    label: "EV019602: V3"
  },
  {
    valueId: "EV019603",
    valueDesc: "V4",
    label: "EV019603: V4"
  },
  {
    valueId: "EV019604",
    valueDesc: "Follower plate",
    label: "EV019604: Follower plate"
  },
  {
    valueId: "EV019605",
    valueDesc: "Patchwork",
    label: "EV019605: Patchwork"
  },
  {
    valueId: "EV019606",
    valueDesc: "Design",
    label: "EV019606: Design"
  },
  {
    valueId: "EV019607",
    valueDesc: "Terrazzo",
    label: "EV019607: Terrazzo"
  },
  {
    valueId: "EV019609",
    valueDesc: "Edge protector element",
    label: "EV019609: Edge protector element"
  },
  {
    valueId: "EV019610",
    valueDesc: "Gantry crane",
    label: "EV019610: Gantry crane"
  },
  {
    valueId: "EV019615",
    valueDesc: "Turn two-part with fixed panel",
    label: "EV019615: Turn two-part with fixed panel"
  },
  {
    valueId: "EV019616",
    valueDesc: "Turn one-part with two fixed panels",
    label: "EV019616: Turn one-part with two fixed panels"
  },
  {
    valueId: "EV019617",
    valueDesc: "Slide one-part with two fixed panels",
    label: "EV019617: Slide one-part with two fixed panels"
  },
  {
    valueId: "EV019618",
    valueDesc: "Slide two-part with one fixed panel",
    label: "EV019618: Slide two-part with one fixed panel"
  },
  {
    valueId: "EV019619",
    valueDesc: "Slide three-part with one fixed panel",
    label: "EV019619: Slide three-part with one fixed panel"
  },
  {
    valueId: "EV019622",
    valueDesc: "Reflecting",
    label: "EV019622: Reflecting"
  },
  {
    valueId: "EV019625",
    valueDesc: "Amphibian ladder",
    label: "EV019625: Amphibian ladder"
  },
  {
    valueId: "EV019627",
    valueDesc: "SATA",
    label: "EV019627: SATA"
  },
  {
    valueId: "EV019631",
    valueDesc: "Two-way pallet",
    label: "EV019631: Two-way pallet"
  },
  {
    valueId: "EV019632",
    valueDesc: "Arcing horn",
    label: "EV019632: Arcing horn"
  },
  {
    valueId: "EV019633",
    valueDesc: "Earthing clips",
    label: "EV019633: Earthing clips"
  },
  {
    valueId: "EV019634",
    valueDesc: "Magnesite bonded wood wool",
    label: "EV019634: Magnesite bonded wood wool"
  },
  {
    valueId: "EV019635",
    valueDesc: "Gipsum vinyl",
    label: "EV019635: Gipsum vinyl"
  },
  {
    valueId: "EV019636",
    valueDesc: "Semi-concealed",
    label: "EV019636: Semi-concealed"
  },
  {
    valueId: "EV019639",
    valueDesc: "Without bevelled edge",
    label: "EV019639: Without bevelled edge"
  },
  {
    valueId: "EV019641",
    valueDesc: "XO",
    label: "EV019641: XO"
  },
  {
    valueId: "EV019642",
    valueDesc: "IC (Integrated Circuit)",
    label: "EV019642: IC (Integrated Circuit)"
  },
  {
    valueId: "EV019643",
    valueDesc: "Operational amplifier",
    label: "EV019643: Operational amplifier"
  },
  {
    valueId: "EV019644",
    valueDesc: "Thyristor",
    label: "EV019644: Thyristor"
  },
  {
    valueId: "EV019645",
    valueDesc: "Galvanized and painted",
    label: "EV019645: Galvanized and painted"
  },
  {
    valueId: "EV019646",
    valueDesc: "Face cleanser",
    label: "EV019646: Face cleanser"
  },
  {
    valueId: "EV019647",
    valueDesc: "Hand cleanser",
    label: "EV019647: Hand cleanser"
  },
  {
    valueId: "EV019648",
    valueDesc: "Star sky",
    label: "EV019648: Star sky"
  },
  {
    valueId: "EV019649",
    valueDesc: "External thread (NPSM)",
    label: "EV019649: External thread (NPSM)"
  },
  {
    valueId: "EV019650",
    valueDesc: "Internal thread (NPSM)",
    label: "EV019650: Internal thread (NPSM)"
  },
  {
    valueId: "EV019651",
    valueDesc: "ASME B1.20.1",
    label: "EV019651: ASME B1.20.1"
  },
  {
    valueId: "EV019652",
    valueDesc: "Basket",
    label: "EV019652: Basket"
  },
  {
    valueId: "EV019653",
    valueDesc: "Tie/belt holder",
    label: "EV019653: Tie/belt holder"
  },
  {
    valueId: "EV019656",
    valueDesc: "Trouser hanger",
    label: "EV019656: Trouser hanger"
  },
  {
    valueId: "EV019657",
    valueDesc: "SAT/RD",
    label: "EV019657: SAT/RD"
  },
  {
    valueId: "EV019662",
    valueDesc: "EN AW-1050A (Al 99,5)",
    label: "EV019662: EN AW-1050A (Al 99,5)"
  },
  {
    valueId: "EV019663",
    valueDesc: "UV-C lamp",
    label: "EV019663: UV-C lamp"
  },
  {
    valueId: "EV019664",
    valueDesc: "3, N, PE, 2, 1, 1+, 2-",
    label: "EV019664: 3, N, PE, 2, 1, 1+, 2-"
  },
  {
    valueId: "EV019665",
    valueDesc: "PE-Xe",
    label: "EV019665: PE-Xe"
  },
  {
    valueId: "EV019666",
    valueDesc: "Phase cut-on/PWM",
    label: "EV019666: Phase cut-on/PWM"
  },
  {
    valueId: "EV019671",
    valueDesc: "1 or 3",
    label: "EV019671: 1 or 3"
  },
  {
    valueId: "EV019673",
    valueDesc: "10A",
    label: "EV019673: 10A"
  },
  {
    valueId: "EV019674",
    valueDesc: "15A",
    label: "EV019674: 15A"
  },
  {
    valueId: "EV019675",
    valueDesc: "20A",
    label: "EV019675: 20A"
  },
  {
    valueId: "EV019676",
    valueDesc: "25A",
    label: "EV019676: 25A"
  },
  {
    valueId: "EV019677",
    valueDesc: "32A",
    label: "EV019677: 32A"
  },
  {
    valueId: "EV019678",
    valueDesc: "40A",
    label: "EV019678: 40A"
  },
  {
    valueId: "EV019679",
    valueDesc: "50A",
    label: "EV019679: 50A"
  },
  {
    valueId: "EV019680",
    valueDesc: "65A",
    label: "EV019680: 65A"
  },
  {
    valueId: "EV019681",
    valueDesc: "80A",
    label: "EV019681: 80A"
  },
  {
    valueId: "EV019682",
    valueDesc: "90A",
    label: "EV019682: 90A"
  },
  {
    valueId: "EV019683",
    valueDesc: "100A",
    label: "EV019683: 100A"
  },
  {
    valueId: "EV019684",
    valueDesc: "125A",
    label: "EV019684: 125A"
  },
  {
    valueId: "EV019685",
    valueDesc: "150A",
    label: "EV019685: 150A"
  },
  {
    valueId: "EV019686",
    valueDesc: "175A",
    label: "EV019686: 175A"
  },
  {
    valueId: "EV019687",
    valueDesc: "200A",
    label: "EV019687: 200A"
  },
  {
    valueId: "EV019688",
    valueDesc: "225A",
    label: "EV019688: 225A"
  },
  {
    valueId: "EV019689",
    valueDesc: "250A",
    label: "EV019689: 250A"
  },
  {
    valueId: "EV019690",
    valueDesc: "300A",
    label: "EV019690: 300A"
  },
  {
    valueId: "EV019691",
    valueDesc: "350A",
    label: "EV019691: 350A"
  },
  {
    valueId: "EV019692",
    valueDesc: "400A",
    label: "EV019692: 400A"
  },
  {
    valueId: "EV019693",
    valueDesc: "JIS B 2239",
    label: "EV019693: JIS B 2239"
  },
  {
    valueId: "EV019694",
    valueDesc: "JIS B 2220",
    label: "EV019694: JIS B 2220"
  },
  {
    valueId: "EV019695",
    valueDesc: "Class 125",
    label: "EV019695: Class 125"
  },
  {
    valueId: "EV019696",
    valueDesc: "Class 150",
    label: "EV019696: Class 150"
  },
  {
    valueId: "EV019697",
    valueDesc: "Class 250",
    label: "EV019697: Class 250"
  },
  {
    valueId: "EV019698",
    valueDesc: "Class 300",
    label: "EV019698: Class 300"
  },
  {
    valueId: "EV019699",
    valueDesc: "Class 400",
    label: "EV019699: Class 400"
  },
  {
    valueId: "EV019700",
    valueDesc: "Class 600",
    label: "EV019700: Class 600"
  },
  {
    valueId: "EV019701",
    valueDesc: "Class 900",
    label: "EV019701: Class 900"
  },
  {
    valueId: "EV019702",
    valueDesc: "Class 1500",
    label: "EV019702: Class 1500"
  },
  {
    valueId: "EV019703",
    valueDesc: "2K",
    label: "EV019703: 2K"
  },
  {
    valueId: "EV019704",
    valueDesc: "5K",
    label: "EV019704: 5K"
  },
  {
    valueId: "EV019705",
    valueDesc: "10K",
    label: "EV019705: 10K"
  },
  {
    valueId: "EV019706",
    valueDesc: "16K",
    label: "EV019706: 16K"
  },
  {
    valueId: "EV019707",
    valueDesc: "20K",
    label: "EV019707: 20K"
  },
  {
    valueId: "EV019708",
    valueDesc: "30K",
    label: "EV019708: 30K"
  },
  {
    valueId: "EV019709",
    valueDesc: "40K",
    label: "EV019709: 40K"
  },
  {
    valueId: "EV019710",
    valueDesc: "63K",
    label: "EV019710: 63K"
  },
  {
    valueId: "EV019711",
    valueDesc: "Filter bag for sludge bucket",
    label: "EV019711: Filter bag for sludge bucket"
  },
  {
    valueId: "EV019712",
    valueDesc: "Polyester adhesive",
    label: "EV019712: Polyester adhesive"
  },
  {
    valueId: "EV019713",
    valueDesc: "Renovation adapter",
    label: "EV019713: Renovation adapter"
  },
  {
    valueId: "EV019714",
    valueDesc: "ASME B16.5",
    label: "EV019714: ASME B16.5"
  },
  {
    valueId: "EV019715",
    valueDesc: "Alarm system",
    label: "EV019715: Alarm system"
  },
  {
    valueId: "EV019716",
    valueDesc: "ASME B16.42",
    label: "EV019716: ASME B16.42"
  },
  {
    valueId: "EV019717",
    valueDesc: "AS 2129 Chart E",
    label: "EV019717: AS 2129 Chart E"
  },
  {
    valueId: "EV019718",
    valueDesc: "Extension element",
    label: "EV019718: Extension element"
  },
  {
    valueId: "EV019719",
    valueDesc: "Horizontal/vertical, drain valve down",
    label: "EV019719: Horizontal/vertical, drain valve down"
  },
  {
    valueId: "EV019720",
    valueDesc: "Entry",
    label: "EV019720: Entry"
  },
  {
    valueId: "EV019721",
    valueDesc: "Tiled roof",
    label: "EV019721: Tiled roof"
  },
  {
    valueId: "EV019722",
    valueDesc: "Bituminous roofing",
    label: "EV019722: Bituminous roofing"
  },
  {
    valueId: "EV019723",
    valueDesc: "Shingles roof",
    label: "EV019723: Shingles roof"
  },
  {
    valueId: "EV019729",
    valueDesc: "Barrier profile",
    label: "EV019729: Barrier profile"
  },
  {
    valueId: "EV019730",
    valueDesc: "Expansion joint profile",
    label: "EV019730: Expansion joint profile"
  },
  {
    valueId: "EV019731",
    valueDesc: "Dispersion mortar",
    label: "EV019731: Dispersion mortar"
  },
  {
    valueId: "EV019732",
    valueDesc: "Single rounded",
    label: "EV019732: Single rounded"
  },
  {
    valueId: "EV019733",
    valueDesc: "Double rounded",
    label: "EV019733: Double rounded"
  },
  {
    valueId: "EV019734",
    valueDesc: "Fully rounded",
    label: "EV019734: Fully rounded"
  },
  {
    valueId: "EV019735",
    valueDesc: "Glued laminate edge",
    label: "EV019735: Glued laminate edge"
  },
  {
    valueId: "EV019736",
    valueDesc: "Glued wooden edge",
    label: "EV019736: Glued wooden edge"
  },
  {
    valueId: "EV019737",
    valueDesc: "Glued aluminium edge",
    label: "EV019737: Glued aluminium edge"
  },
  {
    valueId: "EV019738",
    valueDesc: "Without edge cover",
    label: "EV019738: Without edge cover"
  },
  {
    valueId: "EV019741",
    valueDesc: "Shower, washbasin, toilet",
    label: "EV019741: Shower, washbasin, toilet"
  },
  {
    valueId: "EV019742",
    valueDesc: "Shower, wash basin",
    label: "EV019742: Shower, wash basin"
  },
  {
    valueId: "EV019743",
    valueDesc: "Upgrade set",
    label: "EV019743: Upgrade set"
  },
  {
    valueId: "EV019744",
    valueDesc: "Safety valve",
    label: "EV019744: Safety valve"
  },
  {
    valueId: "EV019745",
    valueDesc: "Double barrier",
    label: "EV019745: Double barrier"
  },
  {
    valueId: "EV019746",
    valueDesc: "Drain angle",
    label: "EV019746: Drain angle"
  },
  {
    valueId: "EV019747",
    valueDesc: "S-bend",
    label: "EV019747: S-bend"
  },
  {
    valueId: "EV019748",
    valueDesc: "Claw ring",
    label: "EV019748: Claw ring"
  },
  {
    valueId: "EV019749",
    valueDesc: "Mounting box",
    label: "EV019749: Mounting box"
  },
  {
    valueId: "EV019750",
    valueDesc: "Non-load bearing",
    label: "EV019750: Non-load bearing"
  },
  {
    valueId: "EV019751",
    valueDesc: "Main bearing/constructive",
    label: "EV019751: Main bearing/constructive"
  },
  {
    valueId: "EV019752",
    valueDesc: "R454b",
    label: "EV019752: R454b"
  },
  {
    valueId: "EV019754",
    valueDesc: "S30",
    label: "EV019754: S30"
  },
  {
    valueId: "EV019755",
    valueDesc: "S60",
    label: "EV019755: S60"
  },
  {
    valueId: "EV019756",
    valueDesc: "S90",
    label: "EV019756: S90"
  },
  {
    valueId: "EV019757",
    valueDesc: "S120",
    label: "EV019757: S120"
  },
  {
    valueId: "EV019758",
    valueDesc: "S180",
    label: "EV019758: S180"
  },
  {
    valueId: "EV019759",
    valueDesc: "Knee",
    label: "EV019759: Knee"
  },
  {
    valueId: "EV019760",
    valueDesc: "Stainless steel 904L (1.4539)",
    label: "EV019760: Stainless steel 904L (1.4539)"
  },
  {
    valueId: "EV019761",
    valueDesc: "PVDF, carbon fibre reinforced",
    label: "EV019761: PVDF, carbon fibre reinforced"
  },
  {
    valueId: "EV019762",
    valueDesc: "PPE-PS-GF",
    label: "EV019762: PPE-PS-GF"
  },
  {
    valueId: "EV019763",
    valueDesc: "CuZn16Si4-C (CC761S)",
    label: "EV019763: CuZn16Si4-C (CC761S)"
  },
  {
    valueId: "EV019764",
    valueDesc: "PES-GF",
    label: "EV019764: PES-GF"
  },
  {
    valueId: "EV019765",
    valueDesc: "CuZn37Pb2 (CW606N)",
    label: "EV019765: CuZn37Pb2 (CW606N)"
  },
  {
    valueId: "EV019766",
    valueDesc: "Nickel alloy",
    label: "EV019766: Nickel alloy"
  },
  {
    valueId: "EV019767",
    valueDesc: "Wiper blade adapter",
    label: "EV019767: Wiper blade adapter"
  },
  {
    valueId: "EV019768",
    valueDesc: "Aluminium trihydrate (ATH)",
    label: "EV019768: Aluminium trihydrate (ATH)"
  },
  {
    valueId: "EV019769",
    valueDesc: "MMA (acryl)",
    label: "EV019769: MMA (acryl)"
  },
  {
    valueId: "EV019770",
    valueDesc: "Bauxite",
    label: "EV019770: Bauxite"
  },
  {
    valueId: "EV019771",
    valueDesc: "Polyether foam/fibre cement",
    label: "EV019771: Polyether foam/fibre cement"
  },
  {
    valueId: "EV019772",
    valueDesc: "Centre plate",
    label: "EV019772: Centre plate"
  },
  {
    valueId: "EV019778",
    valueDesc: "Right side",
    label: "EV019778: Right side"
  },
  {
    valueId: "EV019779",
    valueDesc: "Left side",
    label: "EV019779: Left side"
  },
  {
    valueId: "EV019780",
    valueDesc: "All sides",
    label: "EV019780: All sides"
  },
  {
    valueId: "EV019786",
    valueDesc: "Slotted plate",
    label: "EV019786: Slotted plate"
  },
  {
    valueId: "EV019787",
    valueDesc: "Batten step",
    label: "EV019787: Batten step"
  },
  {
    valueId: "EV019788",
    valueDesc: "End sleeve",
    label: "EV019788: End sleeve"
  },
  {
    valueId: "EV019789",
    valueDesc: "Strut",
    label: "EV019789: Strut"
  },
  {
    valueId: "EV019795",
    valueDesc: "A2L",
    label: "EV019795: A2L"
  },
  {
    valueId: "EV019798",
    valueDesc: "L1-15",
    label: "EV019798: L1-15"
  },
  {
    valueId: "EV019799",
    valueDesc: "L2-20",
    label: "EV019799: L2-20"
  },
  {
    valueId: "EV019800",
    valueDesc: "L5-15",
    label: "EV019800: L5-15"
  },
  {
    valueId: "EV019801",
    valueDesc: "L5-20",
    label: "EV019801: L5-20"
  },
  {
    valueId: "EV019802",
    valueDesc: "L6-15",
    label: "EV019802: L6-15"
  },
  {
    valueId: "EV019803",
    valueDesc: "L6-20",
    label: "EV019803: L6-20"
  },
  {
    valueId: "EV019804",
    valueDesc: "L6-30",
    label: "EV019804: L6-30"
  },
  {
    valueId: "EV019805",
    valueDesc: "L7-15",
    label: "EV019805: L7-15"
  },
  {
    valueId: "EV019806",
    valueDesc: "L7-20",
    label: "EV019806: L7-20"
  },
  {
    valueId: "EV019807",
    valueDesc: "L7-30",
    label: "EV019807: L7-30"
  },
  {
    valueId: "EV019808",
    valueDesc: "L7-50",
    label: "EV019808: L7-50"
  },
  {
    valueId: "EV019809",
    valueDesc: "L7-60",
    label: "EV019809: L7-60"
  },
  {
    valueId: "EV019810",
    valueDesc: "L8-20",
    label: "EV019810: L8-20"
  },
  {
    valueId: "EV019811",
    valueDesc: "L8-30",
    label: "EV019811: L8-30"
  },
  {
    valueId: "EV019812",
    valueDesc: "L9-20",
    label: "EV019812: L9-20"
  },
  {
    valueId: "EV019813",
    valueDesc: "L14-20",
    label: "EV019813: L14-20"
  },
  {
    valueId: "EV019814",
    valueDesc: "L14-30",
    label: "EV019814: L14-30"
  },
  {
    valueId: "EV019815",
    valueDesc: "L15-20",
    label: "EV019815: L15-20"
  },
  {
    valueId: "EV019816",
    valueDesc: "L15-30",
    label: "EV019816: L15-30"
  },
  {
    valueId: "EV019817",
    valueDesc: "L16-20",
    label: "EV019817: L16-20"
  },
  {
    valueId: "EV019818",
    valueDesc: "L16-30",
    label: "EV019818: L16-30"
  },
  {
    valueId: "EV019819",
    valueDesc: "L17-30",
    label: "EV019819: L17-30"
  },
  {
    valueId: "EV019820",
    valueDesc: "L18-20",
    label: "EV019820: L18-20"
  },
  {
    valueId: "EV019821",
    valueDesc: "L18-30",
    label: "EV019821: L18-30"
  },
  {
    valueId: "EV019822",
    valueDesc: "L19-20",
    label: "EV019822: L19-20"
  },
  {
    valueId: "EV019823",
    valueDesc: "L19-30",
    label: "EV019823: L19-30"
  },
  {
    valueId: "EV019824",
    valueDesc: "L20-20",
    label: "EV019824: L20-20"
  },
  {
    valueId: "EV019825",
    valueDesc: "L20-30",
    label: "EV019825: L20-30"
  },
  {
    valueId: "EV019826",
    valueDesc: "L21-20",
    label: "EV019826: L21-20"
  },
  {
    valueId: "EV019827",
    valueDesc: "L21-30",
    label: "EV019827: L21-30"
  },
  {
    valueId: "EV019828",
    valueDesc: "L22-20",
    label: "EV019828: L22-20"
  },
  {
    valueId: "EV019829",
    valueDesc: "L22-30",
    label: "EV019829: L22-30"
  },
  {
    valueId: "EV019830",
    valueDesc: "L23-20",
    label: "EV019830: L23-20"
  },
  {
    valueId: "EV019831",
    valueDesc: "L23-30",
    label: "EV019831: L23-30"
  },
  {
    valueId: "EV019832",
    valueDesc: "ANSI 250",
    label: "EV019832: ANSI 250"
  },
  {
    valueId: "EV019836",
    valueDesc: "Straight arbor",
    label: "EV019836: Straight arbor"
  },
  {
    valueId: "EV019846",
    valueDesc: "2T",
    label: "EV019846: 2T"
  },
  {
    valueId: "EV019847",
    valueDesc: "4T",
    label: "EV019847: 4T"
  },
  {
    valueId: "EV019848",
    valueDesc: "4T log",
    label: "EV019848: 4T log"
  },
  {
    valueId: "EV019849",
    valueDesc: "RD",
    label: "EV019849: RD"
  },
  {
    valueId: "EV019851",
    valueDesc: "30x57 mm",
    label: "EV019851: 30x57 mm"
  },
  {
    valueId: "EV019852",
    valueDesc: "MDF.H",
    label: "EV019852: MDF.H"
  },
  {
    valueId: "EV019853",
    valueDesc: "MDF.LA",
    label: "EV019853: MDF.LA"
  },
  {
    valueId: "EV019854",
    valueDesc: "MDF.HLS",
    label: "EV019854: MDF.HLS"
  },
  {
    valueId: "EV019855",
    valueDesc: "L-MDF",
    label: "EV019855: L-MDF"
  },
  {
    valueId: "EV019856",
    valueDesc: "L.MDF.H",
    label: "EV019856: L.MDF.H"
  },
  {
    valueId: "EV019857",
    valueDesc: "UL1-MDF",
    label: "EV019857: UL1-MDF"
  },
  {
    valueId: "EV019858",
    valueDesc: "UL2-MDF",
    label: "EV019858: UL2-MDF"
  },
  {
    valueId: "EV019859",
    valueDesc: "MDF.RWH",
    label: "EV019859: MDF.RWH"
  },
  {
    valueId: "EV019860",
    valueDesc: "MBH",
    label: "EV019860: MBH"
  },
  {
    valueId: "EV019861",
    valueDesc: "MBL",
    label: "EV019861: MBL"
  },
  {
    valueId: "EV019862",
    valueDesc: "MBH.H",
    label: "EV019862: MBH.H"
  },
  {
    valueId: "EV019863",
    valueDesc: "MBH.LA1",
    label: "EV019863: MBH.LA1"
  },
  {
    valueId: "EV019864",
    valueDesc: "MBH.LA2",
    label: "EV019864: MBH.LA2"
  },
  {
    valueId: "EV019865",
    valueDesc: "MBH.HLS1",
    label: "EV019865: MBH.HLS1"
  },
  {
    valueId: "EV019866",
    valueDesc: "MBH.HLS2",
    label: "EV019866: MBH.HLS2"
  },
  {
    valueId: "EV019867",
    valueDesc: "MBL.E",
    label: "EV019867: MBL.E"
  },
  {
    valueId: "EV019868",
    valueDesc: "MBH.E",
    label: "EV019868: MBH.E"
  },
  {
    valueId: "EV019869",
    valueDesc: "Medium board",
    label: "EV019869: Medium board"
  },
  {
    valueId: "EV019870",
    valueDesc: "P1 General purpose boards for use in dry conditions",
    label: "EV019870: P1 General purpose boards for use in dry conditions"
  },
  {
    valueId: "EV019871",
    valueDesc: "P2 Boards for interior fitments for use in dry conditions",
    label: "EV019871: P2 Boards for interior fitments for use in dry conditions"
  },
  {
    valueId: "EV019872",
    valueDesc: "P3 Non load-bearing boards for use in humid conditions",
    label: "EV019872: P3 Non load-bearing boards for use in humid conditions"
  },
  {
    valueId: "EV019873",
    valueDesc: "P4 Load-bearing boards for use in dry conditions",
    label: "EV019873: P4 Load-bearing boards for use in dry conditions"
  },
  {
    valueId: "EV019874",
    valueDesc: "P5 Load-bearing boards for use in humid conditions",
    label: "EV019874: P5 Load-bearing boards for use in humid conditions"
  },
  {
    valueId: "EV019875",
    valueDesc: "P6 Heavy duty load-bearing boards for use in dry conditions",
    label: "EV019875: P6 Heavy duty load-bearing boards for use in dry conditions"
  },
  {
    valueId: "EV019876",
    valueDesc: "P7 Heavy duty load-bearing boards for use in humid conditions",
    label: "EV019876: P7 Heavy duty load-bearing boards for use in humid conditions"
  },
  {
    valueId: "EV019878",
    valueDesc: "Exchange cartridge",
    label: "EV019878: Exchange cartridge"
  },
  {
    valueId: "EV019879",
    valueDesc: "Renovation board (non-load bearing)",
    label: "EV019879: Renovation board (non-load bearing)"
  },
  {
    valueId: "EV019880",
    valueDesc: "EURO",
    label: "EV019880: EURO"
  },
  {
    valueId: "EV019881",
    valueDesc: "TEXAS 10/25",
    label: "EV019881: TEXAS 10/25"
  },
  {
    valueId: "EV019882",
    valueDesc: "TEXAS 35/50",
    label: "EV019882: TEXAS 35/50"
  },
  {
    valueId: "EV019883",
    valueDesc: "TEXAS 70/95",
    label: "EV019883: TEXAS 70/95"
  },
  {
    valueId: "EV019884",
    valueDesc: "TEXAS 50/70",
    label: "EV019884: TEXAS 50/70"
  },
  {
    valueId: "EV019885",
    valueDesc: "IP21S",
    label: "EV019885: IP21S"
  },
  {
    valueId: "EV019886",
    valueDesc: "Acid (A)",
    label: "EV019886: Acid (A)"
  },
  {
    valueId: "EV019887",
    valueDesc: "Oxidizer (O)",
    label: "EV019887: Oxidizer (O)"
  },
  {
    valueId: "EV019890",
    valueDesc: "Sliding protection for movable ladder",
    label: "EV019890: Sliding protection for movable ladder"
  },
  {
    valueId: "EV019892",
    valueDesc: "Fan",
    label: "EV019892: Fan"
  },
  {
    valueId: "EV019893",
    valueDesc: "Dual",
    label: "EV019893: Dual"
  },
  {
    valueId: "EV019894",
    valueDesc: "Pipe joint, straight",
    label: "EV019894: Pipe joint, straight"
  },
  {
    valueId: "EV019895",
    valueDesc: "Pipe joint, angled",
    label: "EV019895: Pipe joint, angled"
  },
  {
    valueId: "EV019896",
    valueDesc: "Platform",
    label: "EV019896: Platform"
  },
  {
    valueId: "EV019897",
    valueDesc: "Attachment for safety line",
    label: "EV019897: Attachment for safety line"
  },
  {
    valueId: "EV019898",
    valueDesc: "British Standard 3 pin plugtop",
    label: "EV019898: British Standard 3 pin plugtop"
  },
  {
    valueId: "EV019899",
    valueDesc: "IP11",
    label: "EV019899: IP11"
  },
  {
    valueId: "EV019900",
    valueDesc: "Separate cable outlet",
    label: "EV019900: Separate cable outlet"
  },
  {
    valueId: "EV019901",
    valueDesc: "Brush outlet lengthwise",
    label: "EV019901: Brush outlet lengthwise"
  },
  {
    valueId: "EV019902",
    valueDesc: "Modular partition (frame part)",
    label: "EV019902: Modular partition (frame part)"
  },
  {
    valueId: "EV019903",
    valueDesc: "Modular partition (module part)",
    label: "EV019903: Modular partition (module part)"
  },
  {
    valueId: "EV019904",
    valueDesc: "DIN 1041",
    label: "EV019904: DIN 1041"
  },
  {
    valueId: "EV019905",
    valueDesc: "DIN 6475",
    label: "EV019905: DIN 6475"
  },
  {
    valueId: "EV019918",
    valueDesc: "Protection panel",
    label: "EV019918: Protection panel"
  },
  {
    valueId: "EV019919",
    valueDesc: "Spacer plate",
    label: "EV019919: Spacer plate"
  },
  {
    valueId: "EV019920",
    valueDesc: "Grill plate",
    label: "EV019920: Grill plate"
  },
  {
    valueId: "EV019921",
    valueDesc: "Ventilation grille",
    label: "EV019921: Ventilation grille"
  },
  {
    valueId: "EV019922",
    valueDesc: "Hob trivet",
    label: "EV019922: Hob trivet"
  },
  {
    valueId: "EV019923",
    valueDesc: "Wok ring",
    label: "EV019923: Wok ring"
  },
  {
    valueId: "EV019924",
    valueDesc: "Handle strip",
    label: "EV019924: Handle strip"
  },
  {
    valueId: "EV019925",
    valueDesc: "Tablet",
    label: "EV019925: Tablet"
  },
  {
    valueId: "EV019926",
    valueDesc: "Maintenance kit",
    label: "EV019926: Maintenance kit"
  },
  {
    valueId: "EV019927",
    valueDesc: "Food storage container",
    label: "EV019927: Food storage container"
  },
  {
    valueId: "EV019933",
    valueDesc: "IP2XD",
    label: "EV019933: IP2XD"
  },
  {
    valueId: "EV019944",
    valueDesc: "Non corrosive gases",
    label: "EV019944: Non corrosive gases"
  },
  {
    valueId: "EV019946",
    valueDesc: "GLS88",
    label: "EV019946: GLS88"
  },
  {
    valueId: "EV019948",
    valueDesc: "Clamping mandrel",
    label: "EV019948: Clamping mandrel"
  },
  {
    valueId: "EV019949",
    valueDesc: "Clamping pin",
    label: "EV019949: Clamping pin"
  },
  {
    valueId: "EV019951",
    valueDesc: "1.5",
    label: "EV019951: 1.5"
  },
  {
    valueId: "EV019952",
    valueDesc: "Switch disconnector box",
    label: "EV019952: Switch disconnector box"
  },
  {
    valueId: "EV019953",
    valueDesc: "Swing door drive",
    label: "EV019953: Swing door drive"
  },
  {
    valueId: "EV019956",
    valueDesc: "Cornice",
    label: "EV019956: Cornice"
  },
  {
    valueId: "EV019957",
    valueDesc: "Rusticated block",
    label: "EV019957: Rusticated block"
  },
  {
    valueId: "EV019958",
    valueDesc: "Console stone",
    label: "EV019958: Console stone"
  },
  {
    valueId: "EV019960",
    valueDesc: "Frame profile",
    label: "EV019960: Frame profile"
  },
  {
    valueId: "EV019961",
    valueDesc: "Soffit profile",
    label: "EV019961: Soffit profile"
  },
  {
    valueId: "EV019967",
    valueDesc: "Fibre reinforced concrete",
    label: "EV019967: Fibre reinforced concrete"
  },
  {
    valueId: "EV019971",
    valueDesc: "Fibre optic loop sensor",
    label: "EV019971: Fibre optic loop sensor"
  },
  {
    valueId: "EV019972",
    valueDesc: "Point/lens-type sensor",
    label: "EV019972: Point/lens-type sensor"
  },
  {
    valueId: "EV019999",
    valueDesc: "Box shape",
    label: "EV019999: Box shape"
  },
  {
    valueId: "EV020000",
    valueDesc: "Lever handle",
    label: "EV020000: Lever handle"
  },
  {
    valueId: "EV020001",
    valueDesc: "Glued sleeve",
    label: "EV020001: Glued sleeve"
  },
  {
    valueId: "EV020003",
    valueDesc: "Hand wheel",
    label: "EV020003: Hand wheel"
  },
  {
    valueId: "EV020004",
    valueDesc: "Linear",
    label: "EV020004: Linear"
  },
  {
    valueId: "EV020005",
    valueDesc: "Ball",
    label: "EV020005: Ball"
  },
  {
    valueId: "EV020006",
    valueDesc: "Plate",
    label: "EV020006: Plate"
  },
  {
    valueId: "EV020007",
    valueDesc: "11",
    label: "EV020007: 11"
  },
  {
    valueId: "EV020008",
    valueDesc: "21",
    label: "EV020008: 21"
  },
  {
    valueId: "EV020009",
    valueDesc: "22",
    label: "EV020009: 22"
  },
  {
    valueId: "EV020010",
    valueDesc: "33",
    label: "EV020010: 33"
  },
  {
    valueId: "EV020014",
    valueDesc: "Right-angle coupling",
    label: "EV020014: Right-angle coupling"
  },
  {
    valueId: "EV020015",
    valueDesc: "Side-outlet elbow",
    label: "EV020015: Side-outlet elbow"
  },
  {
    valueId: "EV020016",
    valueDesc: "Cross piece 90°",
    label: "EV020016: Cross piece 90°"
  },
  {
    valueId: "EV020017",
    valueDesc: "Nipple",
    label: "EV020017: Nipple"
  },
  {
    valueId: "EV020019",
    valueDesc: "Straight coupling",
    label: "EV020019: Straight coupling"
  },
  {
    valueId: "EV020020",
    valueDesc: "Hose pillar",
    label: "EV020020: Hose pillar"
  },
  {
    valueId: "EV020025",
    valueDesc: "Elbow (fitting)",
    label: "EV020025: Elbow (fitting)"
  },
  {
    valueId: "EV020026",
    valueDesc: "Fins",
    label: "EV020026: Fins"
  },
  {
    valueId: "EV020027",
    valueDesc: "Internal thread conical BSPT-Rc (ISO 7-1 / EN 10226-1)",
    label: "EV020027: Internal thread conical BSPT-Rc (ISO 7-1 / EN 10226-1)"
  },
  {
    valueId: "EV020028",
    valueDesc: "Modulating",
    label: "EV020028: Modulating"
  },
  {
    valueId: "EV020029",
    valueDesc: "Sheet steel",
    label: "EV020029: Sheet steel"
  },
  {
    valueId: "EV020030",
    valueDesc: "Cor-Ten steel",
    label: "EV020030: Cor-Ten steel"
  },
  {
    valueId: "EV020036",
    valueDesc: "Thermostatic",
    label: "EV020036: Thermostatic"
  },
  {
    valueId: "EV020037",
    valueDesc: "Storz",
    label: "EV020037: Storz"
  },
  {
    valueId: "EV020039",
    valueDesc: "Whirl",
    label: "EV020039: Whirl"
  },
  {
    valueId: "EV020041",
    valueDesc: "Steam",
    label: "EV020041: Steam"
  },
  {
    valueId: "EV020042",
    valueDesc: "Double-sided corner",
    label: "EV020042: Double-sided corner"
  },
  {
    valueId: "EV020044",
    valueDesc: "Centrifugal",
    label: "EV020044: Centrifugal"
  },
  {
    valueId: "EV020045",
    valueDesc: "Axial",
    label: "EV020045: Axial"
  },
  {
    valueId: "EV020046",
    valueDesc: "Double-size",
    label: "EV020046: Double-size"
  },
  {
    valueId: "EV020047",
    valueDesc: "Mulden, flat",
    label: "EV020047: Mulden, flat"
  },
  {
    valueId: "EV020048",
    valueDesc: "Tuille du nord tile",
    label: "EV020048: Tuille du nord tile"
  },
  {
    valueId: "EV020049",
    valueDesc: "Wrong right-angled",
    label: "EV020049: Wrong right-angled"
  },
  {
    valueId: "EV020050",
    valueDesc: "Reducing sleeve",
    label: "EV020050: Reducing sleeve"
  },
  {
    valueId: "EV020053",
    valueDesc: "Rim",
    label: "EV020053: Rim"
  },
  {
    valueId: "EV020054",
    valueDesc: "Welding neck flange",
    label: "EV020054: Welding neck flange"
  },
  {
    valueId: "EV020055",
    valueDesc: "Welding plate flange",
    label: "EV020055: Welding plate flange"
  },
  {
    valueId: "EV020057",
    valueDesc: "Saddle",
    label: "EV020057: Saddle"
  },
  {
    valueId: "EV020058",
    valueDesc: "Soldered flange",
    label: "EV020058: Soldered flange"
  },
  {
    valueId: "EV020059",
    valueDesc: "Compression",
    label: "EV020059: Compression"
  },
  {
    valueId: "EV020060",
    valueDesc: "Threaded flange",
    label: "EV020060: Threaded flange"
  },
  {
    valueId: "EV020061",
    valueDesc: "Offset",
    label: "EV020061: Offset"
  },
  {
    valueId: "EV020063",
    valueDesc: "Bend",
    label: "EV020063: Bend"
  },
  {
    valueId: "EV020064",
    valueDesc: "Push-on flange",
    label: "EV020064: Push-on flange"
  },
  {
    valueId: "EV020065",
    valueDesc: "Hose socket",
    label: "EV020065: Hose socket"
  },
  {
    valueId: "EV020066",
    valueDesc: "Segmental bend",
    label: "EV020066: Segmental bend"
  },
  {
    valueId: "EV020067",
    valueDesc: "Press sleeve",
    label: "EV020067: Press sleeve"
  },
  {
    valueId: "EV020068",
    valueDesc: "Electro welded sleeve",
    label: "EV020068: Electro welded sleeve"
  },
  {
    valueId: "EV020069",
    valueDesc: "Glue",
    label: "EV020069: Glue"
  },
  {
    valueId: "EV020070",
    valueDesc: "5 inch",
    label: "EV020070: 5 inch"
  },
  {
    valueId: "EV020071",
    valueDesc: "6 inch",
    label: "EV020071: 6 inch"
  },
  {
    valueId: "EV020072",
    valueDesc: "Cap",
    label: "EV020072: Cap"
  },
  {
    valueId: "EV020073",
    valueDesc: "Blind flange",
    label: "EV020073: Blind flange"
  },
  {
    valueId: "EV020074",
    valueDesc: "30 min",
    label: "EV020074: 30 min"
  },
  {
    valueId: "EV020075",
    valueDesc: "60 min",
    label: "EV020075: 60 min"
  },
  {
    valueId: "EV020076",
    valueDesc: "90 min",
    label: "EV020076: 90 min"
  },
  {
    valueId: "EV020077",
    valueDesc: "120 min",
    label: "EV020077: 120 min"
  },
  {
    valueId: "EV020078",
    valueDesc: "St 33 (1.0035)",
    label: "EV020078: St 33 (1.0035)"
  },
  {
    valueId: "EV020079",
    valueDesc: "St 35 (1.0308)",
    label: "EV020079: St 35 (1.0308)"
  },
  {
    valueId: "EV020080",
    valueDesc: "ABS",
    label: "EV020080: ABS"
  },
  {
    valueId: "EV020082",
    valueDesc: "PVC-C",
    label: "EV020082: PVC-C"
  },
  {
    valueId: "EV020083",
    valueDesc: "Polyvinylidene fluoride (PVDF)",
    label: "EV020083: Polyvinylidene fluoride (PVDF)"
  },
  {
    valueId: "EV020084",
    valueDesc: "Glued end",
    label: "EV020084: Glued end"
  },
  {
    valueId: "EV020085",
    valueDesc: "Plastic welded end",
    label: "EV020085: Plastic welded end"
  },
  {
    valueId: "EV020086",
    valueDesc: "Pickled",
    label: "EV020086: Pickled"
  },
  {
    valueId: "EV020087",
    valueDesc: "Blasted",
    label: "EV020087: Blasted"
  },
  {
    valueId: "EV020088",
    valueDesc: "Laminate",
    label: "EV020088: Laminate"
  },
  {
    valueId: "EV020089",
    valueDesc: "Stainless steel 304 L (1.4306)",
    label: "EV020089: Stainless steel 304 L (1.4306)"
  },
  {
    valueId: "EV020090",
    valueDesc: "Stainless steel 316 L (1.4404)",
    label: "EV020090: Stainless steel 316 L (1.4404)"
  },
  {
    valueId: "EV020091",
    valueDesc: "Thermal oil",
    label: "EV020091: Thermal oil"
  },
  {
    valueId: "EV020092",
    valueDesc: "Thermoelectric",
    label: "EV020092: Thermoelectric"
  },
  {
    valueId: "EV020093",
    valueDesc: "High/low",
    label: "EV020093: High/low"
  },
  {
    valueId: "EV020094",
    valueDesc: "80",
    label: "EV020094: 80"
  },
  {
    valueId: "EV020095",
    valueDesc: "Contrarotating blades",
    label: "EV020095: Contrarotating blades"
  },
  {
    valueId: "EV020096",
    valueDesc: "With mixing tap",
    label: "EV020096: With mixing tap"
  },
  {
    valueId: "EV020097",
    valueDesc: "Without mixing tap",
    label: "EV020097: Without mixing tap"
  },
  {
    valueId: "EV020098",
    valueDesc: "15 mm",
    label: "EV020098: 15 mm"
  },
  {
    valueId: "EV020099",
    valueDesc: "80 mm",
    label: "EV020099: 80 mm"
  },
  {
    valueId: "EV020100",
    valueDesc: "100 mm",
    label: "EV020100: 100 mm"
  },
  {
    valueId: "EV020101",
    valueDesc: "PN 2.5",
    label: "EV020101: PN 2.5"
  },
  {
    valueId: "EV020102",
    valueDesc: "PN 6",
    label: "EV020102: PN 6"
  },
  {
    valueId: "EV020103",
    valueDesc: "PN 10",
    label: "EV020103: PN 10"
  },
  {
    valueId: "EV020104",
    valueDesc: "PN 16",
    label: "EV020104: PN 16"
  },
  {
    valueId: "EV020105",
    valueDesc: "PN 25",
    label: "EV020105: PN 25"
  },
  {
    valueId: "EV020106",
    valueDesc: "PN 40",
    label: "EV020106: PN 40"
  },
  {
    valueId: "EV020107",
    valueDesc: "PN 63",
    label: "EV020107: PN 63"
  },
  {
    valueId: "EV020108",
    valueDesc: "PN 100",
    label: "EV020108: PN 100"
  },
  {
    valueId: "EV020109",
    valueDesc: "DN 6",
    label: "EV020109: DN 6"
  },
  {
    valueId: "EV020110",
    valueDesc: "DN 8",
    label: "EV020110: DN 8"
  },
  {
    valueId: "EV020111",
    valueDesc: "DN 10",
    label: "EV020111: DN 10"
  },
  {
    valueId: "EV020112",
    valueDesc: "DN 40",
    label: "EV020112: DN 40"
  },
  {
    valueId: "EV020113",
    valueDesc: "DN 50",
    label: "EV020113: DN 50"
  },
  {
    valueId: "EV020114",
    valueDesc: "DN 65",
    label: "EV020114: DN 65"
  },
  {
    valueId: "EV020115",
    valueDesc: "DN 80",
    label: "EV020115: DN 80"
  },
  {
    valueId: "EV020116",
    valueDesc: "DN 100",
    label: "EV020116: DN 100"
  },
  {
    valueId: "EV020117",
    valueDesc: "DN 125",
    label: "EV020117: DN 125"
  },
  {
    valueId: "EV020118",
    valueDesc: "DN 150",
    label: "EV020118: DN 150"
  },
  {
    valueId: "EV020119",
    valueDesc: "DN 175",
    label: "EV020119: DN 175"
  },
  {
    valueId: "EV020120",
    valueDesc: "DN 200",
    label: "EV020120: DN 200"
  },
  {
    valueId: "EV020121",
    valueDesc: "DN 350",
    label: "EV020121: DN 350"
  },
  {
    valueId: "EV020122",
    valueDesc: "DN 400",
    label: "EV020122: DN 400"
  },
  {
    valueId: "EV020123",
    valueDesc: "DN 450",
    label: "EV020123: DN 450"
  },
  {
    valueId: "EV020124",
    valueDesc: "DN 500",
    label: "EV020124: DN 500"
  },
  {
    valueId: "EV020125",
    valueDesc: "DN 250",
    label: "EV020125: DN 250"
  },
  {
    valueId: "EV020126",
    valueDesc: "12 mm",
    label: "EV020126: 12 mm"
  },
  {
    valueId: "EV020127",
    valueDesc: "22 mm",
    label: "EV020127: 22 mm"
  },
  {
    valueId: "EV020128",
    valueDesc: "8 mm",
    label: "EV020128: 8 mm"
  },
  {
    valueId: "EV020129",
    valueDesc: "28 mm",
    label: "EV020129: 28 mm"
  },
  {
    valueId: "EV020130",
    valueDesc: "63 mm",
    label: "EV020130: 63 mm"
  },
  {
    valueId: "EV020132",
    valueDesc: "G 3/4 inch",
    label: "EV020132: G 3/4 inch"
  },
  {
    valueId: "EV020133",
    valueDesc: "G 1 inch",
    label: "EV020133: G 1 inch"
  },
  {
    valueId: "EV020134",
    valueDesc: "56 mm",
    label: "EV020134: 56 mm"
  },
  {
    valueId: "EV020135",
    valueDesc: "110 mm",
    label: "EV020135: 110 mm"
  },
  {
    valueId: "EV020136",
    valueDesc: "125 mm",
    label: "EV020136: 125 mm"
  },
  {
    valueId: "EV020137",
    valueDesc: "160 mm",
    label: "EV020137: 160 mm"
  },
  {
    valueId: "EV020138",
    valueDesc: "200 mm",
    label: "EV020138: 200 mm"
  },
  {
    valueId: "EV020139",
    valueDesc: "250 mm",
    label: "EV020139: 250 mm"
  },
  {
    valueId: "EV020140",
    valueDesc: "150 mm",
    label: "EV020140: 150 mm"
  },
  {
    valueId: "EV020141",
    valueDesc: "300 mm",
    label: "EV020141: 300 mm"
  },
  {
    valueId: "EV020143",
    valueDesc: "M22",
    label: "EV020143: M22"
  },
  {
    valueId: "EV020144",
    valueDesc: "M24",
    label: "EV020144: M24"
  },
  {
    valueId: "EV020145",
    valueDesc: "PN 8",
    label: "EV020145: PN 8"
  },
  {
    valueId: "EV020146",
    valueDesc: "PN 12.5",
    label: "EV020146: PN 12.5"
  },
  {
    valueId: "EV020147",
    valueDesc: "PN 3.2",
    label: "EV020147: PN 3.2"
  },
  {
    valueId: "EV020148",
    valueDesc: "PN 4",
    label: "EV020148: PN 4"
  },
  {
    valueId: "EV020149",
    valueDesc: "300",
    label: "EV020149: 300"
  },
  {
    valueId: "EV020150",
    valueDesc: "Straight with bend",
    label: "EV020150: Straight with bend"
  },
  {
    valueId: "EV020151",
    valueDesc: "Sections",
    label: "EV020151: Sections"
  },
  {
    valueId: "EV020152",
    valueDesc: "Boiler tube",
    label: "EV020152: Boiler tube"
  },
  {
    valueId: "EV020153",
    valueDesc: "180 mm",
    label: "EV020153: 180 mm"
  },
  {
    valueId: "EV020154",
    valueDesc: "400 mm",
    label: "EV020154: 400 mm"
  },
  {
    valueId: "EV020155",
    valueDesc: "450 mm",
    label: "EV020155: 450 mm"
  },
  {
    valueId: "EV020156",
    valueDesc: "500 mm",
    label: "EV020156: 500 mm"
  },
  {
    valueId: "EV020157",
    valueDesc: "800 mm",
    label: "EV020157: 800 mm"
  },
  {
    valueId: "EV020158",
    valueDesc: "900 mm",
    label: "EV020158: 900 mm"
  },
  {
    valueId: "EV020159",
    valueDesc: "1000 mm",
    label: "EV020159: 1000 mm"
  },
  {
    valueId: "EV020160",
    valueDesc: "18 mm",
    label: "EV020160: 18 mm"
  },
  {
    valueId: "EV020161",
    valueDesc: "Gas-filled",
    label: "EV020161: Gas-filled"
  },
  {
    valueId: "EV020162",
    valueDesc: "Liquid-filled",
    label: "EV020162: Liquid-filled"
  },
  {
    valueId: "EV020163",
    valueDesc: "Wax-filled",
    label: "EV020163: Wax-filled"
  },
  {
    valueId: "EV020165",
    valueDesc: "Covered",
    label: "EV020165: Covered"
  },
  {
    valueId: "EV020166",
    valueDesc: "Etched",
    label: "EV020166: Etched"
  },
  {
    valueId: "EV020167",
    valueDesc: "Sanded",
    label: "EV020167: Sanded"
  },
  {
    valueId: "EV020168",
    valueDesc: "Fibre-reinforced plastic",
    label: "EV020168: Fibre-reinforced plastic"
  },
  {
    valueId: "EV020169",
    valueDesc: "PE-40",
    label: "EV020169: PE-40"
  },
  {
    valueId: "EV020170",
    valueDesc: "PE-50",
    label: "EV020170: PE-50"
  },
  {
    valueId: "EV020171",
    valueDesc: "PE-80",
    label: "EV020171: PE-80"
  },
  {
    valueId: "EV020172",
    valueDesc: "PE-100",
    label: "EV020172: PE-100"
  },
  {
    valueId: "EV020173",
    valueDesc: "PVC-CPE",
    label: "EV020173: PVC-CPE"
  },
  {
    valueId: "EV020174",
    valueDesc: "PVC recycled",
    label: "EV020174: PVC recycled"
  },
  {
    valueId: "EV020175",
    valueDesc: "St 34.2 (1.0034)",
    label: "EV020175: St 34.2 (1.0034)"
  },
  {
    valueId: "EV020176",
    valueDesc: "St 37 (1.0254)",
    label: "EV020176: St 37 (1.0254)"
  },
  {
    valueId: "EV020177",
    valueDesc: "St 44.0 (1.0256)",
    label: "EV020177: St 44.0 (1.0256)"
  },
  {
    valueId: "EV020178",
    valueDesc: "St 50.2 (1.0050)",
    label: "EV020178: St 50.2 (1.0050)"
  },
  {
    valueId: "EV020179",
    valueDesc: "St 52.0 (1.0421)",
    label: "EV020179: St 52.0 (1.0421)"
  },
  {
    valueId: "EV020180",
    valueDesc: "St 60.2 (1.0060)",
    label: "EV020180: St 60.2 (1.0060)"
  },
  {
    valueId: "EV020181",
    valueDesc: "St 70.2 (1.0070)",
    label: "EV020181: St 70.2 (1.0070)"
  },
  {
    valueId: "EV020182",
    valueDesc: "Bronze (Rg 5)",
    label: "EV020182: Bronze (Rg 5)"
  },
  {
    valueId: "EV020183",
    valueDesc: "Zinc-poor bronze (Rg 10)",
    label: "EV020183: Zinc-poor bronze (Rg 10)"
  },
  {
    valueId: "EV020184",
    valueDesc: "Zinc-free bronze (90/10)",
    label: "EV020184: Zinc-free bronze (90/10)"
  },
  {
    valueId: "EV020185",
    valueDesc: "Aluminium bronze",
    label: "EV020185: Aluminium bronze"
  },
  {
    valueId: "EV020186",
    valueDesc: "Dezincification resistant brass (DZR)",
    label: "EV020186: Dezincification resistant brass (DZR)"
  },
  {
    valueId: "EV020187",
    valueDesc: "Cast iron GG 20 (GJL-200)",
    label: "EV020187: Cast iron GG 20 (GJL-200)"
  },
  {
    valueId: "EV020188",
    valueDesc: "Cast iron GG 25 (GJL-250)",
    label: "EV020188: Cast iron GG 25 (GJL-250)"
  },
  {
    valueId: "EV020189",
    valueDesc: "Ductile cast iron",
    label: "EV020189: Ductile cast iron"
  },
  {
    valueId: "EV020190",
    valueDesc: "Cast iron GGG 40.3 (GJS-400-18)",
    label: "EV020190: Cast iron GGG 40.3 (GJS-400-18)"
  },
  {
    valueId: "EV020191",
    valueDesc: "Stainless steel 321 (1.4541)",
    label: "EV020191: Stainless steel 321 (1.4541)"
  },
  {
    valueId: "EV020192",
    valueDesc: "Stainless steel 316 Ti (1.4571)",
    label: "EV020192: Stainless steel 316 Ti (1.4571)"
  },
  {
    valueId: "EV020193",
    valueDesc: "Brushed",
    label: "EV020193: Brushed"
  },
  {
    valueId: "EV020195",
    valueDesc: "Threaded pipe",
    label: "EV020195: Threaded pipe"
  },
  {
    valueId: "EV020196",
    valueDesc: "Boiler pipe",
    label: "EV020196: Boiler pipe"
  },
  {
    valueId: "EV020197",
    valueDesc: "Low-temperature pipe",
    label: "EV020197: Low-temperature pipe"
  },
  {
    valueId: "EV020198",
    valueDesc: "Construction pipe",
    label: "EV020198: Construction pipe"
  },
  {
    valueId: "EV020199",
    valueDesc: "Steam pipe",
    label: "EV020199: Steam pipe"
  },
  {
    valueId: "EV020200",
    valueDesc: "Greenhouse pipe",
    label: "EV020200: Greenhouse pipe"
  },
  {
    valueId: "EV020201",
    valueDesc: "Stepped",
    label: "EV020201: Stepped"
  },
  {
    valueId: "EV020202",
    valueDesc: "1 x 230 V",
    label: "EV020202: 1 x 230 V"
  },
  {
    valueId: "EV020203",
    valueDesc: "3 x 230 V",
    label: "EV020203: 3 x 230 V"
  },
  {
    valueId: "EV020204",
    valueDesc: "3 x 400 V",
    label: "EV020204: 3 x 400 V"
  },
  {
    valueId: "EV020205",
    valueDesc: "Capillary",
    label: "EV020205: Capillary"
  },
  {
    valueId: "EV020207",
    valueDesc: "1/8 inch (6)",
    label: "EV020207: 1/8 inch (6)"
  },
  {
    valueId: "EV020208",
    valueDesc: "3/8 inch (10)",
    label: "EV020208: 3/8 inch (10)"
  },
  {
    valueId: "EV020209",
    valueDesc: "1/2 inch (15)",
    label: "EV020209: 1/2 inch (15)"
  },
  {
    valueId: "EV020210",
    valueDesc: "3/4 inch (20)",
    label: "EV020210: 3/4 inch (20)"
  },
  {
    valueId: "EV020211",
    valueDesc: "1 inch (25)",
    label: "EV020211: 1 inch (25)"
  },
  {
    valueId: "EV020212",
    valueDesc: "1 1/4 inch (32)",
    label: "EV020212: 1 1/4 inch (32)"
  },
  {
    valueId: "EV020213",
    valueDesc: "1 1/2 inch (40)",
    label: "EV020213: 1 1/2 inch (40)"
  },
  {
    valueId: "EV020214",
    valueDesc: "2 inch (50)",
    label: "EV020214: 2 inch (50)"
  },
  {
    valueId: "EV020215",
    valueDesc: "2 1/2 inch (65)",
    label: "EV020215: 2 1/2 inch (65)"
  },
  {
    valueId: "EV020216",
    valueDesc: "3 inch (80)",
    label: "EV020216: 3 inch (80)"
  },
  {
    valueId: "EV020217",
    valueDesc: "4 inch (100)",
    label: "EV020217: 4 inch (100)"
  },
  {
    valueId: "EV020218",
    valueDesc: "5 inch (125)",
    label: "EV020218: 5 inch (125)"
  },
  {
    valueId: "EV020219",
    valueDesc: "6 inch (150)",
    label: "EV020219: 6 inch (150)"
  },
  {
    valueId: "EV020220",
    valueDesc: "8 inch (200)",
    label: "EV020220: 8 inch (200)"
  },
  {
    valueId: "EV020221",
    valueDesc: "10 inch (250)",
    label: "EV020221: 10 inch (250)"
  },
  {
    valueId: "EV020222",
    valueDesc: "12 inch (300)",
    label: "EV020222: 12 inch (300)"
  },
  {
    valueId: "EV020227",
    valueDesc: "Drain",
    label: "EV020227: Drain"
  },
  {
    valueId: "EV020229",
    valueDesc: "2-speed",
    label: "EV020229: 2-speed"
  },
  {
    valueId: "EV020230",
    valueDesc: "3-speed",
    label: "EV020230: 3-speed"
  },
  {
    valueId: "EV020235",
    valueDesc: "Internal thread cylindrical BSPP-G (ISO 228-1)",
    label: "EV020235: Internal thread cylindrical BSPP-G (ISO 228-1)"
  },
  {
    valueId: "EV020236",
    valueDesc: "Internal thread metric",
    label: "EV020236: Internal thread metric"
  },
  {
    valueId: "EV020237",
    valueDesc: "Internal thread conical (NPT)",
    label: "EV020237: Internal thread conical (NPT)"
  },
  {
    valueId: "EV020238",
    valueDesc: "External thread conical BSPT-R (ISO 7-1 / EN 10226-1)",
    label: "EV020238: External thread conical BSPT-R (ISO 7-1 / EN 10226-1)"
  },
  {
    valueId: "EV020239",
    valueDesc: "External thread cylindrical BSPP-G (ISO 228-1)",
    label: "EV020239: External thread cylindrical BSPP-G (ISO 228-1)"
  },
  {
    valueId: "EV020240",
    valueDesc: "External thread metric",
    label: "EV020240: External thread metric"
  },
  {
    valueId: "EV020241",
    valueDesc: "External thread conical (NPT)",
    label: "EV020241: External thread conical (NPT)"
  },
  {
    valueId: "EV020242",
    valueDesc: "Compression ring",
    label: "EV020242: Compression ring"
  },
  {
    valueId: "EV020243",
    valueDesc: "Pressureless",
    label: "EV020243: Pressureless"
  },
  {
    valueId: "EV020244",
    valueDesc: "PN 20",
    label: "EV020244: PN 20"
  },
  {
    valueId: "EV020245",
    valueDesc: "PN 32",
    label: "EV020245: PN 32"
  },
  {
    valueId: "EV020246",
    valueDesc: "Hand chain",
    label: "EV020246: Hand chain"
  },
  {
    valueId: "EV020247",
    valueDesc: "Button",
    label: "EV020247: Button"
  },
  {
    valueId: "EV020249",
    valueDesc: "3-part",
    label: "EV020249: 3-part"
  },
  {
    valueId: "EV020252",
    valueDesc: "Monoflange",
    label: "EV020252: Monoflange"
  },
  {
    valueId: "EV020253",
    valueDesc: "Semi-monoflange",
    label: "EV020253: Semi-monoflange"
  },
  {
    valueId: "EV020254",
    valueDesc: "Wafer type",
    label: "EV020254: Wafer type"
  },
  {
    valueId: "EV020256",
    valueDesc: "Double flange",
    label: "EV020256: Double flange"
  },
  {
    valueId: "EV020257",
    valueDesc: "Replaceable",
    label: "EV020257: Replaceable"
  },
  {
    valueId: "EV020259",
    valueDesc: "T-shaped",
    label: "EV020259: T-shaped"
  },
  {
    valueId: "EV020260",
    valueDesc: "Hydrant",
    label: "EV020260: Hydrant"
  },
  {
    valueId: "EV020263",
    valueDesc: "Crank handle",
    label: "EV020263: Crank handle"
  },
  {
    valueId: "EV020264",
    valueDesc: "Ridge",
    label: "EV020264: Ridge"
  },
  {
    valueId: "EV020265",
    valueDesc: "Cross piece with branch",
    label: "EV020265: Cross piece with branch"
  },
  {
    valueId: "EV020266",
    valueDesc: "T-piece with double branch",
    label: "EV020266: T-piece with double branch"
  },
  {
    valueId: "EV020267",
    valueDesc: "57",
    label: "EV020267: 57"
  },
  {
    valueId: "EV020268",
    valueDesc: "115",
    label: "EV020268: 115"
  },
  {
    valueId: "EV020269",
    valueDesc: "Quick response",
    label: "EV020269: Quick response"
  },
  {
    valueId: "EV020270",
    valueDesc: "Fast response",
    label: "EV020270: Fast response"
  },
  {
    valueId: "EV020271",
    valueDesc: "206",
    label: "EV020271: 206"
  },
  {
    valueId: "EV020272",
    valueDesc: "360",
    label: "EV020272: 360"
  },
  {
    valueId: "EV020275",
    valueDesc: "DN 12",
    label: "EV020275: DN 12"
  },
  {
    valueId: "EV020276",
    valueDesc: "DN 70",
    label: "EV020276: DN 70"
  },
  {
    valueId: "EV020277",
    valueDesc: "L-bore",
    label: "EV020277: L-bore"
  },
  {
    valueId: "EV020278",
    valueDesc: "T-bore",
    label: "EV020278: T-bore"
  },
  {
    valueId: "EV020279",
    valueDesc: "T piece with side piece",
    label: "EV020279: T piece with side piece"
  },
  {
    valueId: "EV020280",
    valueDesc: "T piece 45°",
    label: "EV020280: T piece 45°"
  },
  {
    valueId: "EV020285",
    valueDesc: "Worm gearbox",
    label: "EV020285: Worm gearbox"
  },
  {
    valueId: "EV020286",
    valueDesc: "Rubber/plastic",
    label: "EV020286: Rubber/plastic"
  },
  {
    valueId: "EV020287",
    valueDesc: "Soldered end",
    label: "EV020287: Soldered end"
  },
  {
    valueId: "EV020288",
    valueDesc: "Soldered sleeve",
    label: "EV020288: Soldered sleeve"
  },
  {
    valueId: "EV020289",
    valueDesc: "Fire valve",
    label: "EV020289: Fire valve"
  },
  {
    valueId: "EV020290",
    valueDesc: "Cast iron GGG 50 (GJS-500-7)",
    label: "EV020290: Cast iron GGG 50 (GJS-500-7)"
  },
  {
    valueId: "EV020291",
    valueDesc: "Rubber-sealing",
    label: "EV020291: Rubber-sealing"
  },
  {
    valueId: "EV020292",
    valueDesc: "Plastic-sealing",
    label: "EV020292: Plastic-sealing"
  },
  {
    valueId: "EV020293",
    valueDesc: "Metal-sealing",
    label: "EV020293: Metal-sealing"
  },
  {
    valueId: "EV020294",
    valueDesc: "Felt",
    label: "EV020294: Felt"
  },
  {
    valueId: "EV020297",
    valueDesc: "Collar",
    label: "EV020297: Collar"
  },
  {
    valueId: "EV020298",
    valueDesc: "Sleeve with seal",
    label: "EV020298: Sleeve with seal"
  },
  {
    valueId: "EV020299",
    valueDesc: "Connection cable with plug",
    label: "EV020299: Connection cable with plug"
  },
  {
    valueId: "EV020300",
    valueDesc: "Terminals",
    label: "EV020300: Terminals"
  },
  {
    valueId: "EV020301",
    valueDesc: "RU (Rear Under)",
    label: "EV020301: RU (Rear Under)"
  },
  {
    valueId: "EV020302",
    valueDesc: "PS (Pipe short)",
    label: "EV020302: PS (Pipe short)"
  },
  {
    valueId: "EV020303",
    valueDesc: "SH (S shape hidden)",
    label: "EV020303: SH (S shape hidden)"
  },
  {
    valueId: "EV020304",
    valueDesc: "MU (Middle Under)",
    label: "EV020304: MU (Middle Under)"
  },
  {
    valueId: "EV020305",
    valueDesc: "Deep-flush toilet",
    label: "EV020305: Deep-flush toilet"
  },
  {
    valueId: "EV020306",
    valueDesc: "Flat flush",
    label: "EV020306: Flat flush"
  },
  {
    valueId: "EV020307",
    valueDesc: "Glued neck flange",
    label: "EV020307: Glued neck flange"
  },
  {
    valueId: "EV020308",
    valueDesc: "Laminating flange",
    label: "EV020308: Laminating flange"
  },
  {
    valueId: "EV020309",
    valueDesc: "Groove coupling flange",
    label: "EV020309: Groove coupling flange"
  },
  {
    valueId: "EV020311",
    valueDesc: "Union nut",
    label: "EV020311: Union nut"
  },
  {
    valueId: "EV020312",
    valueDesc: "Plastic welded sleeve",
    label: "EV020312: Plastic welded sleeve"
  },
  {
    valueId: "EV020313",
    valueDesc: "Support flange (split)",
    label: "EV020313: Support flange (split)"
  },
  {
    valueId: "EV020314",
    valueDesc: "150 mbar",
    label: "EV020314: 150 mbar"
  },
  {
    valueId: "EV020315",
    valueDesc: "Polybutylene (PB)",
    label: "EV020315: Polybutylene (PB)"
  },
  {
    valueId: "EV020316",
    valueDesc: "PTC",
    label: "EV020316: PTC"
  },
  {
    valueId: "EV020317",
    valueDesc: "Pt1000",
    label: "EV020317: Pt1000"
  },
  {
    valueId: "EV020318",
    valueDesc: "Ni1000",
    label: "EV020318: Ni1000"
  },
  {
    valueId: "EV020319",
    valueDesc: "Absolute",
    label: "EV020319: Absolute"
  },
  {
    valueId: "EV020320",
    valueDesc: "Relative",
    label: "EV020320: Relative"
  },
  {
    valueId: "EV020321",
    valueDesc: "0 - 20 V",
    label: "EV020321: 0 - 20 V"
  },
  {
    valueId: "EV020322",
    valueDesc: "0 - 1 bar",
    label: "EV020322: 0 - 1 bar"
  },
  {
    valueId: "EV020324",
    valueDesc: "Alloyed steel",
    label: "EV020324: Alloyed steel"
  },
  {
    valueId: "EV020325",
    valueDesc: "Internal thread/external thread",
    label: "EV020325: Internal thread/external thread"
  },
  {
    valueId: "EV020326",
    valueDesc: "Coach screw pin",
    label: "EV020326: Coach screw pin"
  },
  {
    valueId: "EV020327",
    valueDesc: "Clamping bolt",
    label: "EV020327: Clamping bolt"
  },
  {
    valueId: "EV020328",
    valueDesc: "Tapping pin",
    label: "EV020328: Tapping pin"
  },
  {
    valueId: "EV020329",
    valueDesc: "M8 / M10",
    label: "EV020329: M8 / M10"
  },
  {
    valueId: "EV020330",
    valueDesc: "M10 / M12",
    label: "EV020330: M10 / M12"
  },
  {
    valueId: "EV020331",
    valueDesc: "Ceramic/ceramic",
    label: "EV020331: Ceramic/ceramic"
  },
  {
    valueId: "EV020332",
    valueDesc: "Ceramic/plastic",
    label: "EV020332: Ceramic/plastic"
  },
  {
    valueId: "EV020333",
    valueDesc: "Pull button",
    label: "EV020333: Pull button"
  },
  {
    valueId: "EV020334",
    valueDesc: "Stainless steel/Stainless steel",
    label: "EV020334: Stainless steel/Stainless steel"
  },
  {
    valueId: "EV020335",
    valueDesc: "Friction",
    label: "EV020335: Friction"
  },
  {
    valueId: "EV020336",
    valueDesc: "Deactivated when open NO",
    label: "EV020336: Deactivated when open NO"
  },
  {
    valueId: "EV020337",
    valueDesc: "Deactivated when closed NC",
    label: "EV020337: Deactivated when closed NC"
  },
  {
    valueId: "EV020338",
    valueDesc: "Insert end",
    label: "EV020338: Insert end"
  },
  {
    valueId: "EV020339",
    valueDesc: "Duct end",
    label: "EV020339: Duct end"
  },
  {
    valueId: "EV020340",
    valueDesc: "Beaded edge",
    label: "EV020340: Beaded edge"
  },
  {
    valueId: "EV020341",
    valueDesc: "Angle profile",
    label: "EV020341: Angle profile"
  },
  {
    valueId: "EV020342",
    valueDesc: "Standard fuse",
    label: "EV020342: Standard fuse"
  },
  {
    valueId: "EV020343",
    valueDesc: "Electric motor",
    label: "EV020343: Electric motor"
  },
  {
    valueId: "EV020344",
    valueDesc: "Pneumatic motor",
    label: "EV020344: Pneumatic motor"
  },
  {
    valueId: "EV020345",
    valueDesc: "Unlocking magnet",
    label: "EV020345: Unlocking magnet"
  },
  {
    valueId: "EV020346",
    valueDesc: "Unlocking cylinder",
    label: "EV020346: Unlocking cylinder"
  },
  {
    valueId: "EV020347",
    valueDesc: "Handle/lever",
    label: "EV020347: Handle/lever"
  },
  {
    valueId: "EV020348",
    valueDesc: "Low-lifting",
    label: "EV020348: Low-lifting"
  },
  {
    valueId: "EV020349",
    valueDesc: "High-lifting",
    label: "EV020349: High-lifting"
  },
  {
    valueId: "EV020350",
    valueDesc: "Proportional",
    label: "EV020350: Proportional"
  },
  {
    valueId: "EV020351",
    valueDesc: "2x32",
    label: "EV020351: 2x32"
  },
  {
    valueId: "EV020358",
    valueDesc: "Dual plate",
    label: "EV020358: Dual plate"
  },
  {
    valueId: "EV020368",
    valueDesc: "Glass wool",
    label: "EV020368: Glass wool"
  },
  {
    valueId: "EV020372",
    valueDesc: "Disc",
    label: "EV020372: Disc"
  },
  {
    valueId: "EV020373",
    valueDesc: "Swing type",
    label: "EV020373: Swing type"
  },
  {
    valueId: "EV020374",
    valueDesc: "Tube end",
    label: "EV020374: Tube end"
  },
  {
    valueId: "EV020375",
    valueDesc: "Press",
    label: "EV020375: Press"
  },
  {
    valueId: "EV020376",
    valueDesc: "Shrink sleeve",
    label: "EV020376: Shrink sleeve"
  },
  {
    valueId: "EV020377",
    valueDesc: "PE-reinforced",
    label: "EV020377: PE-reinforced"
  },
  {
    valueId: "EV020378",
    valueDesc: "Cross piece 88.5°",
    label: "EV020378: Cross piece 88.5°"
  },
  {
    valueId: "EV020379",
    valueDesc: "Horizontal connection",
    label: "EV020379: Horizontal connection"
  },
  {
    valueId: "EV020380",
    valueDesc: "Vertical connection",
    label: "EV020380: Vertical connection"
  },
  {
    valueId: "EV020386",
    valueDesc: "Vertical/vertical incomplete",
    label: "EV020386: Vertical/vertical incomplete"
  },
  {
    valueId: "EV020387",
    valueDesc: "Vertical/vertical complete",
    label: "EV020387: Vertical/vertical complete"
  },
  {
    valueId: "EV020388",
    valueDesc: "Horizontal/horizontal",
    label: "EV020388: Horizontal/horizontal"
  },
  {
    valueId: "EV020390",
    valueDesc: "Smooth bend",
    label: "EV020390: Smooth bend"
  },
  {
    valueId: "EV020391",
    valueDesc: "Wrinkled bend",
    label: "EV020391: Wrinkled bend"
  },
  {
    valueId: "EV020393",
    valueDesc: "Strap",
    label: "EV020393: Strap"
  },
  {
    valueId: "EV020394",
    valueDesc: "Bracket/wall bracket",
    label: "EV020394: Bracket/wall bracket"
  },
  {
    valueId: "EV020395",
    valueDesc: "Hanger",
    label: "EV020395: Hanger"
  },
  {
    valueId: "EV020396",
    valueDesc: "Nail point pin",
    label: "EV020396: Nail point pin"
  },
  {
    valueId: "EV020398",
    valueDesc: "High-calorific natural gas",
    label: "EV020398: High-calorific natural gas"
  },
  {
    valueId: "EV020399",
    valueDesc: "Low-calorific natural gas",
    label: "EV020399: Low-calorific natural gas"
  },
  {
    valueId: "EV020400",
    valueDesc: "HBO 1",
    label: "EV020400: HBO 1"
  },
  {
    valueId: "EV020401",
    valueDesc: "Thermosiphon",
    label: "EV020401: Thermosiphon"
  },
  {
    valueId: "EV020402",
    valueDesc: "Back-flow",
    label: "EV020402: Back-flow"
  },
  {
    valueId: "EV020403",
    valueDesc: "Towards wall",
    label: "EV020403: Towards wall"
  },
  {
    valueId: "EV020404",
    valueDesc: "Towards floor",
    label: "EV020404: Towards floor"
  },
  {
    valueId: "EV020407",
    valueDesc: "Long flame",
    label: "EV020407: Long flame"
  },
  {
    valueId: "EV020408",
    valueDesc: "Short flame",
    label: "EV020408: Short flame"
  },
  {
    valueId: "EV020409",
    valueDesc: "Biogas",
    label: "EV020409: Biogas"
  },
  {
    valueId: "EV020410",
    valueDesc: "Ionisation electrode",
    label: "EV020410: Ionisation electrode"
  },
  {
    valueId: "EV020411",
    valueDesc: "UV cell",
    label: "EV020411: UV cell"
  },
  {
    valueId: "EV020414",
    valueDesc: "Pentagonal",
    label: "EV020414: Pentagonal"
  },
  {
    valueId: "EV020415",
    valueDesc: "Cylindrical glass/plastic",
    label: "EV020415: Cylindrical glass/plastic"
  },
  {
    valueId: "EV020416",
    valueDesc: "Gauge body with pipes",
    label: "EV020416: Gauge body with pipes"
  },
  {
    valueId: "EV020417",
    valueDesc: "DN 18",
    label: "EV020417: DN 18"
  },
  {
    valueId: "EV020418",
    valueDesc: "Reflex glass",
    label: "EV020418: Reflex glass"
  },
  {
    valueId: "EV020420",
    valueDesc: "Coloured glass",
    label: "EV020420: Coloured glass"
  },
  {
    valueId: "EV020421",
    valueDesc: "Rear",
    label: "EV020421: Rear"
  },
  {
    valueId: "EV020422",
    valueDesc: "mbar",
    label: "EV020422: mbar"
  },
  {
    valueId: "EV020423",
    valueDesc: "kPa",
    label: "EV020423: kPa"
  },
  {
    valueId: "EV020424",
    valueDesc: "MPa",
    label: "EV020424: MPa"
  },
  {
    valueId: "EV020425",
    valueDesc: "mmwc (mm water column)",
    label: "EV020425: mmwc (mm water column)"
  },
  {
    valueId: "EV020426",
    valueDesc: "mwc (meter water column)",
    label: "EV020426: mwc (meter water column)"
  },
  {
    valueId: "EV020427",
    valueDesc: "mmHg",
    label: "EV020427: mmHg"
  },
  {
    valueId: "EV020428",
    valueDesc: "bar/psi",
    label: "EV020428: bar/psi"
  },
  {
    valueId: "EV020429",
    valueDesc: "Pascal",
    label: "EV020429: Pascal"
  },
  {
    valueId: "EV020430",
    valueDesc: "Window glass",
    label: "EV020430: Window glass"
  },
  {
    valueId: "EV020431",
    valueDesc: "Front flange",
    label: "EV020431: Front flange"
  },
  {
    valueId: "EV020432",
    valueDesc: "Wall flange",
    label: "EV020432: Wall flange"
  },
  {
    valueId: "EV020434",
    valueDesc: "Single-action",
    label: "EV020434: Single-action"
  },
  {
    valueId: "EV020435",
    valueDesc: "Dual-action",
    label: "EV020435: Dual-action"
  },
  {
    valueId: "EV020436",
    valueDesc: "Quadruple action",
    label: "EV020436: Quadruple action"
  },
  {
    valueId: "EV020437",
    valueDesc: "Double-valved",
    label: "EV020437: Double-valved"
  },
  {
    valueId: "EV020438",
    valueDesc: "Mobile",
    label: "EV020438: Mobile"
  },
  {
    valueId: "EV020439",
    valueDesc: "Stationary",
    label: "EV020439: Stationary"
  },
  {
    valueId: "EV020440",
    valueDesc: "Gas volume",
    label: "EV020440: Gas volume"
  },
  {
    valueId: "EV020441",
    valueDesc: "Gas composition",
    label: "EV020441: Gas composition"
  },
  {
    valueId: "EV020442",
    valueDesc: "O2",
    label: "EV020442: O2"
  },
  {
    valueId: "EV020443",
    valueDesc: "CO2",
    label: "EV020443: CO2"
  },
  {
    valueId: "EV020444",
    valueDesc: "CH4",
    label: "EV020444: CH4"
  },
  {
    valueId: "EV020446",
    valueDesc: "Gas sensors",
    label: "EV020446: Gas sensors"
  },
  {
    valueId: "EV020447",
    valueDesc: "Lying",
    label: "EV020447: Lying"
  },
  {
    valueId: "EV020449",
    valueDesc: "HBO 3",
    label: "EV020449: HBO 3"
  },
  {
    valueId: "EV020451",
    valueDesc: "Hair element",
    label: "EV020451: Hair element"
  },
  {
    valueId: "EV020452",
    valueDesc: "External thread flare (UNF)",
    label: "EV020452: External thread flare (UNF)"
  },
  {
    valueId: "EV020453",
    valueDesc: "W",
    label: "EV020453: W"
  },
  {
    valueId: "EV020454",
    valueDesc: "Air valve",
    label: "EV020454: Air valve"
  },
  {
    valueId: "EV020455",
    valueDesc: "District heating",
    label: "EV020455: District heating"
  },
  {
    valueId: "EV020456",
    valueDesc: "Mulden tile, concave",
    label: "EV020456: Mulden tile, concave"
  },
  {
    valueId: "EV020457",
    valueDesc: "Utrechter",
    label: "EV020457: Utrechter"
  },
  {
    valueId: "EV020458",
    valueDesc: "RBB/Neroma",
    label: "EV020458: RBB/Neroma"
  },
  {
    valueId: "EV020459",
    valueDesc: "Steinfelder",
    label: "EV020459: Steinfelder"
  },
  {
    valueId: "EV020460",
    valueDesc: "Screw holes",
    label: "EV020460: Screw holes"
  },
  {
    valueId: "EV020462",
    valueDesc: "Clamped in",
    label: "EV020462: Clamped in"
  },
  {
    valueId: "EV020465",
    valueDesc: "Steel coated",
    label: "EV020465: Steel coated"
  },
  {
    valueId: "EV020466",
    valueDesc: "Double right angle left",
    label: "EV020466: Double right angle left"
  },
  {
    valueId: "EV020467",
    valueDesc: "Double right angle right",
    label: "EV020467: Double right angle right"
  },
  {
    valueId: "EV020468",
    valueDesc: "X port",
    label: "EV020468: X port"
  },
  {
    valueId: "EV020469",
    valueDesc: "Continuous plate",
    label: "EV020469: Continuous plate"
  },
  {
    valueId: "EV020470",
    valueDesc: "Blade edge",
    label: "EV020470: Blade edge"
  },
  {
    valueId: "EV020472",
    valueDesc: "Flare internal thread (NPT)",
    label: "EV020472: Flare internal thread (NPT)"
  },
  {
    valueId: "EV020473",
    valueDesc: "Internal thread flare (UNF)",
    label: "EV020473: Internal thread flare (UNF)"
  },
  {
    valueId: "EV020474",
    valueDesc: "Flare external thread (NPT)",
    label: "EV020474: Flare external thread (NPT)"
  },
  {
    valueId: "EV020475",
    valueDesc: "On a corner",
    label: "EV020475: On a corner"
  },
  {
    valueId: "EV020476",
    valueDesc: "Centric",
    label: "EV020476: Centric"
  },
  {
    valueId: "EV020477",
    valueDesc: "Every 20 cm",
    label: "EV020477: Every 20 cm"
  },
  {
    valueId: "EV020478",
    valueDesc: "Every 10 cm",
    label: "EV020478: Every 10 cm"
  },
  {
    valueId: "EV020479",
    valueDesc: "Every 5 cm",
    label: "EV020479: Every 5 cm"
  },
  {
    valueId: "EV020480",
    valueDesc: "Every cm",
    label: "EV020480: Every cm"
  },
  {
    valueId: "EV020482",
    valueDesc: "Bolt nut combination",
    label: "EV020482: Bolt nut combination"
  },
  {
    valueId: "EV020483",
    valueDesc: "Slotted hole",
    label: "EV020483: Slotted hole"
  },
  {
    valueId: "EV020484",
    valueDesc: "Holes spread out over surf.",
    label: "EV020484: Holes spread out over surf."
  },
  {
    valueId: "EV020485",
    valueDesc: "Steps of 5 mm",
    label: "EV020485: Steps of 5 mm"
  },
  {
    valueId: "EV020487",
    valueDesc: "Self-supporting",
    label: "EV020487: Self-supporting"
  },
  {
    valueId: "EV020488",
    valueDesc: "Self-supporting, solid",
    label: "EV020488: Self-supporting, solid"
  },
  {
    valueId: "EV020489",
    valueDesc: "Clamps with screw",
    label: "EV020489: Clamps with screw"
  },
  {
    valueId: "EV020490",
    valueDesc: "Plasterboard",
    label: "EV020490: Plasterboard"
  },
  {
    valueId: "EV020491",
    valueDesc: "Calcium silicate (watertight)",
    label: "EV020491: Calcium silicate (watertight)"
  },
  {
    valueId: "EV020492",
    valueDesc: "Eight-shaped",
    label: "EV020492: Eight-shaped"
  },
  {
    valueId: "EV020493",
    valueDesc: "18/40 mm",
    label: "EV020493: 18/40 mm"
  },
  {
    valueId: "EV020494",
    valueDesc: "Bracket system",
    label: "EV020494: Bracket system"
  },
  {
    valueId: "EV020495",
    valueDesc: "Belt system",
    label: "EV020495: Belt system"
  },
  {
    valueId: "EV020496",
    valueDesc: "Click system",
    label: "EV020496: Click system"
  },
  {
    valueId: "EV020499",
    valueDesc: "Chromium-plated metal",
    label: "EV020499: Chromium-plated metal"
  },
  {
    valueId: "EV020504",
    valueDesc: "Flush bend",
    label: "EV020504: Flush bend"
  },
  {
    valueId: "EV020505",
    valueDesc: "Out in front",
    label: "EV020505: Out in front"
  },
  {
    valueId: "EV020506",
    valueDesc: "Out at rear",
    label: "EV020506: Out at rear"
  },
  {
    valueId: "EV020507",
    valueDesc: "Continuous straight",
    label: "EV020507: Continuous straight"
  },
  {
    valueId: "EV020509",
    valueDesc: "Fluorelastomer rubber (FPM/FKM)",
    label: "EV020509: Fluorelastomer rubber (FPM/FKM)"
  },
  {
    valueId: "EV020510",
    valueDesc: "PFPM",
    label: "EV020510: PFPM"
  },
  {
    valueId: "EV020511",
    valueDesc: "NBR (nitrile rubber)",
    label: "EV020511: NBR (nitrile rubber)"
  },
  {
    valueId: "EV020512",
    valueDesc: "CR (neoprene)",
    label: "EV020512: CR (neoprene)"
  },
  {
    valueId: "EV020516",
    valueDesc: "Square according to DIN",
    label: "EV020516: Square according to DIN"
  },
  {
    valueId: "EV020517",
    valueDesc: "Square according to ISO",
    label: "EV020517: Square according to ISO"
  },
  {
    valueId: "EV020518",
    valueDesc: "According to DIN",
    label: "EV020518: According to DIN"
  },
  {
    valueId: "EV020519",
    valueDesc: "According to ISO",
    label: "EV020519: According to ISO"
  },
  {
    valueId: "EV020520",
    valueDesc: "14 mm",
    label: "EV020520: 14 mm"
  },
  {
    valueId: "EV020521",
    valueDesc: "24 mm",
    label: "EV020521: 24 mm"
  },
  {
    valueId: "EV020523",
    valueDesc: "Screwed",
    label: "EV020523: Screwed"
  },
  {
    valueId: "EV020524",
    valueDesc: "Bricked in",
    label: "EV020524: Bricked in"
  },
  {
    valueId: "EV020526",
    valueDesc: "R",
    label: "EV020526: R"
  },
  {
    valueId: "EV020527",
    valueDesc: "Graphite 98 %",
    label: "EV020527: Graphite 98 %"
  },
  {
    valueId: "EV020528",
    valueDesc: "Minerals (Flexite)",
    label: "EV020528: Minerals (Flexite)"
  },
  {
    valueId: "EV020529",
    valueDesc: "ASME B16.47A(MSS0SP 44)",
    label: "EV020529: ASME B16.47A(MSS0SP 44)"
  },
  {
    valueId: "EV020530",
    valueDesc: "ASME B16.47B(API 605)",
    label: "EV020530: ASME B16.47B(API 605)"
  },
  {
    valueId: "EV020531",
    valueDesc: "Thin-walled steel pipe end",
    label: "EV020531: Thin-walled steel pipe end"
  },
  {
    valueId: "EV020533",
    valueDesc: "Urinal",
    label: "EV020533: Urinal"
  },
  {
    valueId: "EV020537",
    valueDesc: "Extension flush bend",
    label: "EV020537: Extension flush bend"
  },
  {
    valueId: "EV020538",
    valueDesc: "Reservoir connection bend",
    label: "EV020538: Reservoir connection bend"
  },
  {
    valueId: "EV020539",
    valueDesc: "In urinal",
    label: "EV020539: In urinal"
  },
  {
    valueId: "EV020540",
    valueDesc: "P-siphon",
    label: "EV020540: P-siphon"
  },
  {
    valueId: "EV020541",
    valueDesc: "Bottle siphon",
    label: "EV020541: Bottle siphon"
  },
  {
    valueId: "EV020542",
    valueDesc: "S-siphon",
    label: "EV020542: S-siphon"
  },
  {
    valueId: "EV020543",
    valueDesc: "Bend 180°",
    label: "EV020543: Bend 180°"
  },
  {
    valueId: "EV020544",
    valueDesc: "H-shaped",
    label: "EV020544: H-shaped"
  },
  {
    valueId: "EV020545",
    valueDesc: "7.6 / 15.3 / 30.6 cm",
    label: "EV020545: 7.6 / 15.3 / 30.6 cm"
  },
  {
    valueId: "EV020546",
    valueDesc: "7.6 cm",
    label: "EV020546: 7.6 cm"
  },
  {
    valueId: "EV020547",
    valueDesc: "15.3 / 7.6 cm",
    label: "EV020547: 15.3 / 7.6 cm"
  },
  {
    valueId: "EV020548",
    valueDesc: "7.6 / 5.3 cm",
    label: "EV020548: 7.6 / 5.3 cm"
  },
  {
    valueId: "EV020549",
    valueDesc: "15.3 cm",
    label: "EV020549: 15.3 cm"
  },
  {
    valueId: "EV020550",
    valueDesc: "30.6 cm",
    label: "EV020550: 30.6 cm"
  },
  {
    valueId: "EV020551",
    valueDesc: "10 cm",
    label: "EV020551: 10 cm"
  },
  {
    valueId: "EV020552",
    valueDesc: "120 mm",
    label: "EV020552: 120 mm"
  },
  {
    valueId: "EV020553",
    valueDesc: "100/120 mm",
    label: "EV020553: 100/120 mm"
  },
  {
    valueId: "EV020554",
    valueDesc: "76.5 mm",
    label: "EV020554: 76.5 mm"
  },
  {
    valueId: "EV020555",
    valueDesc: "76.5/153 mm",
    label: "EV020555: 76.5/153 mm"
  },
  {
    valueId: "EV020556",
    valueDesc: "153 mm",
    label: "EV020556: 153 mm"
  },
  {
    valueId: "EV020559",
    valueDesc: "Potential-free contact",
    label: "EV020559: Potential-free contact"
  },
  {
    valueId: "EV020560",
    valueDesc: "ASME B16.21",
    label: "EV020560: ASME B16.21"
  },
  {
    valueId: "EV020561",
    valueDesc: "Perforated/nail gusset",
    label: "EV020561: Perforated/nail gusset"
  },
  {
    valueId: "EV020562",
    valueDesc: "Cold and hot water",
    label: "EV020562: Cold and hot water"
  },
  {
    valueId: "EV020564",
    valueDesc: "Red/A",
    label: "EV020564: Red/A"
  },
  {
    valueId: "EV020565",
    valueDesc: "Red/PTFE",
    label: "EV020565: Red/PTFE"
  },
  {
    valueId: "EV020566",
    valueDesc: "Red/St",
    label: "EV020566: Red/St"
  },
  {
    valueId: "EV020567",
    valueDesc: "Black/CR",
    label: "EV020567: Black/CR"
  },
  {
    valueId: "EV020568",
    valueDesc: "IIR (butyl)",
    label: "EV020568: IIR (butyl)"
  },
  {
    valueId: "EV020569",
    valueDesc: "NBR black",
    label: "EV020569: NBR black"
  },
  {
    valueId: "EV020570",
    valueDesc: "NBR white (supply)",
    label: "EV020570: NBR white (supply)"
  },
  {
    valueId: "EV020571",
    valueDesc: "CSM (Hypalon)",
    label: "EV020571: CSM (Hypalon)"
  },
  {
    valueId: "EV020572",
    valueDesc: "NR (natural rubber)",
    label: "EV020572: NR (natural rubber)"
  },
  {
    valueId: "EV020573",
    valueDesc: "MVQ (silicons)",
    label: "EV020573: MVQ (silicons)"
  },
  {
    valueId: "EV020574",
    valueDesc: "Armide",
    label: "EV020574: Armide"
  },
  {
    valueId: "EV020575",
    valueDesc: "Nomex",
    label: "EV020575: Nomex"
  },
  {
    valueId: "EV020576",
    valueDesc: "Steel galvanised",
    label: "EV020576: Steel galvanised"
  },
  {
    valueId: "EV020578",
    valueDesc: "Octagonal",
    label: "EV020578: Octagonal"
  },
  {
    valueId: "EV020579",
    valueDesc: "PE diffusion-tight",
    label: "EV020579: PE diffusion-tight"
  },
  {
    valueId: "EV020581",
    valueDesc: "Three-point",
    label: "EV020581: Three-point"
  },
  {
    valueId: "EV020582",
    valueDesc: "Sendzimir-galvanised steel",
    label: "EV020582: Sendzimir-galvanised steel"
  },
  {
    valueId: "EV020583",
    valueDesc: "DN 600",
    label: "EV020583: DN 600"
  },
  {
    valueId: "EV020584",
    valueDesc: "DN 700",
    label: "EV020584: DN 700"
  },
  {
    valueId: "EV020585",
    valueDesc: "DN 800",
    label: "EV020585: DN 800"
  },
  {
    valueId: "EV020586",
    valueDesc: "DN 900",
    label: "EV020586: DN 900"
  },
  {
    valueId: "EV020587",
    valueDesc: "DN 1000",
    label: "EV020587: DN 1000"
  },
  {
    valueId: "EV020588",
    valueDesc: "DN 1200",
    label: "EV020588: DN 1200"
  },
  {
    valueId: "EV020589",
    valueDesc: "DN 1400",
    label: "EV020589: DN 1400"
  },
  {
    valueId: "EV020590",
    valueDesc: "DN 1600",
    label: "EV020590: DN 1600"
  },
  {
    valueId: "EV020591",
    valueDesc: "DN 1800",
    label: "EV020591: DN 1800"
  },
  {
    valueId: "EV020592",
    valueDesc: "DN 2000",
    label: "EV020592: DN 2000"
  },
  {
    valueId: "EV020593",
    valueDesc: "S-trap",
    label: "EV020593: S-trap"
  },
  {
    valueId: "EV020594",
    valueDesc: "Pigtail",
    label: "EV020594: Pigtail"
  },
  {
    valueId: "EV020595",
    valueDesc: "PN 160",
    label: "EV020595: PN 160"
  },
  {
    valueId: "EV020596",
    valueDesc: "PN 320",
    label: "EV020596: PN 320"
  },
  {
    valueId: "EV020597",
    valueDesc: "PN 400",
    label: "EV020597: PN 400"
  },
  {
    valueId: "EV020598",
    valueDesc: "PN 64",
    label: "EV020598: PN 64"
  },
  {
    valueId: "EV020599",
    valueDesc: "T-bar",
    label: "EV020599: T-bar"
  },
  {
    valueId: "EV020600",
    valueDesc: "PN 10/16",
    label: "EV020600: PN 10/16"
  },
  {
    valueId: "EV020601",
    valueDesc: "PN 6/10",
    label: "EV020601: PN 6/10"
  },
  {
    valueId: "EV020602",
    valueDesc: "Deck",
    label: "EV020602: Deck"
  },
  {
    valueId: "EV020603",
    valueDesc: "Single tap",
    label: "EV020603: Single tap"
  },
  {
    valueId: "EV020604",
    valueDesc: "2 taps",
    label: "EV020604: 2 taps"
  },
  {
    valueId: "EV020605",
    valueDesc: "3 taps",
    label: "EV020605: 3 taps"
  },
  {
    valueId: "EV020606",
    valueDesc: "4 taps",
    label: "EV020606: 4 taps"
  },
  {
    valueId: "EV020607",
    valueDesc: "5 taps",
    label: "EV020607: 5 taps"
  },
  {
    valueId: "EV020608",
    valueDesc: "Mixing tap",
    label: "EV020608: Mixing tap"
  },
  {
    valueId: "EV020609",
    valueDesc: "Mixing and cold water tap",
    label: "EV020609: Mixing and cold water tap"
  },
  {
    valueId: "EV020611",
    valueDesc: "Manually operated",
    label: "EV020611: Manually operated"
  },
  {
    valueId: "EV020612",
    valueDesc: "Medium-controlled",
    label: "EV020612: Medium-controlled"
  },
  {
    valueId: "EV020615",
    valueDesc: "Support pipe section",
    label: "EV020615: Support pipe section"
  },
  {
    valueId: "EV020616",
    valueDesc: "M10 / 1/2 inch",
    label: "EV020616: M10 / 1/2 inch"
  },
  {
    valueId: "EV020617",
    valueDesc: "Vertical profile",
    label: "EV020617: Vertical profile"
  },
  {
    valueId: "EV020618",
    valueDesc: "Hanging gutter (semi-round)",
    label: "EV020618: Hanging gutter (semi-round)"
  },
  {
    valueId: "EV020619",
    valueDesc: "Gutter box (straight)",
    label: "EV020619: Gutter box (straight)"
  },
  {
    valueId: "EV020620",
    valueDesc: "Wedge",
    label: "EV020620: Wedge"
  },
  {
    valueId: "EV020621",
    valueDesc: "20x20 mm",
    label: "EV020621: 20x20 mm"
  },
  {
    valueId: "EV020622",
    valueDesc: "25 * 25 mm",
    label: "EV020622: 25 * 25 mm"
  },
  {
    valueId: "EV020625",
    valueDesc: "25 mm round",
    label: "EV020625: 25 mm round"
  },
  {
    valueId: "EV020626",
    valueDesc: "30 mm round",
    label: "EV020626: 30 mm round"
  },
  {
    valueId: "EV020627",
    valueDesc: "Rubber with textile inlay",
    label: "EV020627: Rubber with textile inlay"
  },
  {
    valueId: "EV020629",
    valueDesc: "Coal",
    label: "EV020629: Coal"
  },
  {
    valueId: "EV020630",
    valueDesc: "Wood blocks",
    label: "EV020630: Wood blocks"
  },
  {
    valueId: "EV020631",
    valueDesc: "TTB",
    label: "EV020631: TTB"
  },
  {
    valueId: "EV020632",
    valueDesc: "Oxy pilot",
    label: "EV020632: Oxy pilot"
  },
  {
    valueId: "EV020633",
    valueDesc: "Kitchen water heater",
    label: "EV020633: Kitchen water heater"
  },
  {
    valueId: "EV020634",
    valueDesc: "Kitchen/shower water heater",
    label: "EV020634: Kitchen/shower water heater"
  },
  {
    valueId: "EV020635",
    valueDesc: "Bath water heater",
    label: "EV020635: Bath water heater"
  },
  {
    valueId: "EV020636",
    valueDesc: "100/100 mm",
    label: "EV020636: 100/100 mm"
  },
  {
    valueId: "EV020639",
    valueDesc: "130 mm",
    label: "EV020639: 130 mm"
  },
  {
    valueId: "EV020640",
    valueDesc: "63/69 mm",
    label: "EV020640: 63/69 mm"
  },
  {
    valueId: "EV020641",
    valueDesc: "60/90 - 70/70 mm",
    label: "EV020641: 60/90 - 70/70 mm"
  },
  {
    valueId: "EV020642",
    valueDesc: "Loose set of taps",
    label: "EV020642: Loose set of taps"
  },
  {
    valueId: "EV020647",
    valueDesc: "Group I, <=20 dB(A)",
    label: "EV020647: Group I, <=20 dB(A)"
  },
  {
    valueId: "EV020648",
    valueDesc: "Group II, <=30 dB(A)",
    label: "EV020648: Group II, <=30 dB(A)"
  },
  {
    valueId: "EV020649",
    valueDesc: "Tail bracket",
    label: "EV020649: Tail bracket"
  },
  {
    valueId: "EV020650",
    valueDesc: "Cross-tail bracket",
    label: "EV020650: Cross-tail bracket"
  },
  {
    valueId: "EV020651",
    valueDesc: "Chair bracket",
    label: "EV020651: Chair bracket"
  },
  {
    valueId: "EV020652",
    valueDesc: "Half cover bracket",
    label: "EV020652: Half cover bracket"
  },
  {
    valueId: "EV020654",
    valueDesc: "Funnel gutter (bevelled)",
    label: "EV020654: Funnel gutter (bevelled)"
  },
  {
    valueId: "EV020655",
    valueDesc: "Square A flange",
    label: "EV020655: Square A flange"
  },
  {
    valueId: "EV020656",
    valueDesc: "Not replaceable",
    label: "EV020656: Not replaceable"
  },
  {
    valueId: "EV020657",
    valueDesc: "Profile-less",
    label: "EV020657: Profile-less"
  },
  {
    valueId: "EV020658",
    valueDesc: "Fixed setting",
    label: "EV020658: Fixed setting"
  },
  {
    valueId: "EV020660",
    valueDesc: "Radar",
    label: "EV020660: Radar"
  },
  {
    valueId: "EV020661",
    valueDesc: "Washbasin/top structure",
    label: "EV020661: Washbasin/top structure"
  },
  {
    valueId: "EV020662",
    valueDesc: "Photocell",
    label: "EV020662: Photocell"
  },
  {
    valueId: "EV020663",
    valueDesc: "Conductivity",
    label: "EV020663: Conductivity"
  },
  {
    valueId: "EV020664",
    valueDesc: "Soft-touch",
    label: "EV020664: Soft-touch"
  },
  {
    valueId: "EV020667",
    valueDesc: "Removable hose connection",
    label: "EV020667: Removable hose connection"
  },
  {
    valueId: "EV020668",
    valueDesc: "Fixed hose spigot",
    label: "EV020668: Fixed hose spigot"
  },
  {
    valueId: "EV020669",
    valueDesc: "Fixed hose spigot 45°",
    label: "EV020669: Fixed hose spigot 45°"
  },
  {
    valueId: "EV020670",
    valueDesc: "Removable hose spigot",
    label: "EV020670: Removable hose spigot"
  },
  {
    valueId: "EV020671",
    valueDesc: "Removable hose spigot, 45°",
    label: "EV020671: Removable hose spigot, 45°"
  },
  {
    valueId: "EV020672",
    valueDesc: "6 mm compression",
    label: "EV020672: 6 mm compression"
  },
  {
    valueId: "EV020673",
    valueDesc: "Cap bracket",
    label: "EV020673: Cap bracket"
  },
  {
    valueId: "EV020674",
    valueDesc: "Single-sided with offset",
    label: "EV020674: Single-sided with offset"
  },
  {
    valueId: "EV020675",
    valueDesc: "Electromotor 24 V",
    label: "EV020675: Electromotor 24 V"
  },
  {
    valueId: "EV020676",
    valueDesc: "Electromotor 230 V",
    label: "EV020676: Electromotor 230 V"
  },
  {
    valueId: "EV020677",
    valueDesc: "Electronic on bath rim",
    label: "EV020677: Electronic on bath rim"
  },
  {
    valueId: "EV020678",
    valueDesc: "Pneumatic on bath rim",
    label: "EV020678: Pneumatic on bath rim"
  },
  {
    valueId: "EV020680",
    valueDesc: "Chromium-plated brass",
    label: "EV020680: Chromium-plated brass"
  },
  {
    valueId: "EV020681",
    valueDesc: "Chromium-plated plastic",
    label: "EV020681: Chromium-plated plastic"
  },
  {
    valueId: "EV020685",
    valueDesc: "S spout",
    label: "EV020685: S spout"
  },
  {
    valueId: "EV020686",
    valueDesc: "U spout",
    label: "EV020686: U spout"
  },
  {
    valueId: "EV020687",
    valueDesc: "Duct connection profile",
    label: "EV020687: Duct connection profile"
  },
  {
    valueId: "EV020688",
    valueDesc: "Spout",
    label: "EV020688: Spout"
  },
  {
    valueId: "EV020689",
    valueDesc: "Water film",
    label: "EV020689: Water film"
  },
  {
    valueId: "EV020690",
    valueDesc: "Double-eccentric",
    label: "EV020690: Double-eccentric"
  },
  {
    valueId: "EV020691",
    valueDesc: "Primer",
    label: "EV020691: Primer"
  },
  {
    valueId: "EV020692",
    valueDesc: "Slots",
    label: "EV020692: Slots"
  },
  {
    valueId: "EV020693",
    valueDesc: "Self-locking",
    label: "EV020693: Self-locking"
  },
  {
    valueId: "EV020694",
    valueDesc: "Fixed setting",
    label: "EV020694: Fixed setting"
  },
  {
    valueId: "EV020695",
    valueDesc: "Spiral-rebated",
    label: "EV020695: Spiral-rebated"
  },
  {
    valueId: "EV020696",
    valueDesc: "Longitudinal rebated joint",
    label: "EV020696: Longitudinal rebated joint"
  },
  {
    valueId: "EV020697",
    valueDesc: "Longitudinal welded joint",
    label: "EV020697: Longitudinal welded joint"
  },
  {
    valueId: "EV020698",
    valueDesc: "Blown bitumen",
    label: "EV020698: Blown bitumen"
  },
  {
    valueId: "EV020699",
    valueDesc: "SBS modified bitumen",
    label: "EV020699: SBS modified bitumen"
  },
  {
    valueId: "EV020700",
    valueDesc: "APP-modified bitumen",
    label: "EV020700: APP-modified bitumen"
  },
  {
    valueId: "EV020701",
    valueDesc: "Bitumen combination",
    label: "EV020701: Bitumen combination"
  },
  {
    valueId: "EV020703",
    valueDesc: "Glass fabric",
    label: "EV020703: Glass fabric"
  },
  {
    valueId: "EV020704",
    valueDesc: "Glass fleece",
    label: "EV020704: Glass fleece"
  },
  {
    valueId: "EV020706",
    valueDesc: "Polyester sheet",
    label: "EV020706: Polyester sheet"
  },
  {
    valueId: "EV020707",
    valueDesc: "Polyester/glass",
    label: "EV020707: Polyester/glass"
  },
  {
    valueId: "EV020708",
    valueDesc: "Polyethylene film",
    label: "EV020708: Polyethylene film"
  },
  {
    valueId: "EV020709",
    valueDesc: "Metal foil",
    label: "EV020709: Metal foil"
  },
  {
    valueId: "EV020710",
    valueDesc: "Fine mineral",
    label: "EV020710: Fine mineral"
  },
  {
    valueId: "EV020711",
    valueDesc: "Coarse mineral, UV-resistant",
    label: "EV020711: Coarse mineral, UV-resistant"
  },
  {
    valueId: "EV020712",
    valueDesc: "Coarse mineral, vapour-pressure distributing",
    label: "EV020712: Coarse mineral, vapour-pressure distributing"
  },
  {
    valueId: "EV020713",
    valueDesc: "Extra coating",
    label: "EV020713: Extra coating"
  },
  {
    valueId: "EV020714",
    valueDesc: "VB class A",
    label: "EV020714: VB class A"
  },
  {
    valueId: "EV020715",
    valueDesc: "VB class B",
    label: "EV020715: VB class B"
  },
  {
    valueId: "EV020716",
    valueDesc: "VD class A",
    label: "EV020716: VD class A"
  },
  {
    valueId: "EV020717",
    valueDesc: "VD class B",
    label: "EV020717: VD class B"
  },
  {
    valueId: "EV020718",
    valueDesc: "BD class A",
    label: "EV020718: BD class A"
  },
  {
    valueId: "EV020719",
    valueDesc: "BD class B",
    label: "EV020719: BD class B"
  },
  {
    valueId: "EV020720",
    valueDesc: "Freely drawing-in",
    label: "EV020720: Freely drawing-in"
  },
  {
    valueId: "EV020721",
    valueDesc: "Chromium steel",
    label: "EV020721: Chromium steel"
  },
  {
    valueId: "EV020722",
    valueDesc: "With collar",
    label: "EV020722: With collar"
  },
  {
    valueId: "EV020723",
    valueDesc: "With hexagonal",
    label: "EV020723: With hexagonal"
  },
  {
    valueId: "EV020728",
    valueDesc: "Asphalted",
    label: "EV020728: Asphalted"
  },
  {
    valueId: "EV020729",
    valueDesc: "EN AW-6060 T5(AlMgSi)",
    label: "EV020729: EN AW-6060 T5(AlMgSi)"
  },
  {
    valueId: "EV020730",
    valueDesc: "EN AW-6082 T6(AlSi1MgMn)",
    label: "EV020730: EN AW-6082 T6(AlSi1MgMn)"
  },
  {
    valueId: "EV020731",
    valueDesc: "EN AW-1070 A (AL 99.7)",
    label: "EV020731: EN AW-1070 A (AL 99.7)"
  },
  {
    valueId: "EV020733",
    valueDesc: "Soldered",
    label: "EV020733: Soldered"
  },
  {
    valueId: "EV020737",
    valueDesc: "Grinded",
    label: "EV020737: Grinded"
  },
  {
    valueId: "EV020738",
    valueDesc: "L shape left",
    label: "EV020738: L shape left"
  },
  {
    valueId: "EV020739",
    valueDesc: "L shape right",
    label: "EV020739: L shape right"
  },
  {
    valueId: "EV020740",
    valueDesc: "L shape left/right",
    label: "EV020740: L shape left/right"
  },
  {
    valueId: "EV020741",
    valueDesc: "Wall mirror",
    label: "EV020741: Wall mirror"
  },
  {
    valueId: "EV020742",
    valueDesc: "Shaving mirror/cosmetic mirror",
    label: "EV020742: Shaving mirror/cosmetic mirror"
  },
  {
    valueId: "EV020743",
    valueDesc: "Mirror tile",
    label: "EV020743: Mirror tile"
  },
  {
    valueId: "EV020744",
    valueDesc: "Full-length mirror",
    label: "EV020744: Full-length mirror"
  },
  {
    valueId: "EV020745",
    valueDesc: "Tilting mirror",
    label: "EV020745: Tilting mirror"
  },
  {
    valueId: "EV020746",
    valueDesc: "Wall mirror/groove decor",
    label: "EV020746: Wall mirror/groove decor"
  },
  {
    valueId: "EV020747",
    valueDesc: "Folding mirror",
    label: "EV020747: Folding mirror"
  },
  {
    valueId: "EV020748",
    valueDesc: "Rectangular/2 round corners",
    label: "EV020748: Rectangular/2 round corners"
  },
  {
    valueId: "EV020749",
    valueDesc: "Rectangular/4 round corners",
    label: "EV020749: Rectangular/4 round corners"
  },
  {
    valueId: "EV020750",
    valueDesc: "Arc shape",
    label: "EV020750: Arc shape"
  },
  {
    valueId: "EV020751",
    valueDesc: "Trapezoid 2 round corners",
    label: "EV020751: Trapezoid 2 round corners"
  },
  {
    valueId: "EV020753",
    valueDesc: "Arc with 2 side panels",
    label: "EV020753: Arc with 2 side panels"
  },
  {
    valueId: "EV020754",
    valueDesc: "Rectangular with 2 side panels",
    label: "EV020754: Rectangular with 2 side panels"
  },
  {
    valueId: "EV020755",
    valueDesc: "Rectangular with point above",
    label: "EV020755: Rectangular with point above"
  },
  {
    valueId: "EV020757",
    valueDesc: "Left and right",
    label: "EV020757: Left and right"
  },
  {
    valueId: "EV020758",
    valueDesc: "Removable",
    label: "EV020758: Removable"
  },
  {
    valueId: "EV020759",
    valueDesc: "Fixed/upwards folding",
    label: "EV020759: Fixed/upwards folding"
  },
  {
    valueId: "EV020760",
    valueDesc: "Removable/upwards folding",
    label: "EV020760: Removable/upwards folding"
  },
  {
    valueId: "EV020761",
    valueDesc: "Rock wool",
    label: "EV020761: Rock wool"
  },
  {
    valueId: "EV020762",
    valueDesc: "ACM",
    label: "EV020762: ACM"
  },
  {
    valueId: "EV020763",
    valueDesc: "ECO",
    label: "EV020763: ECO"
  },
  {
    valueId: "EV020764",
    valueDesc: "FMVQ (fluorosilicones)",
    label: "EV020764: FMVQ (fluorosilicones)"
  },
  {
    valueId: "EV020765",
    valueDesc: "Hydrogenated acrylonitrile butadiene rubber (HNBR)",
    label: "EV020765: Hydrogenated acrylonitrile butadiene rubber (HNBR)"
  },
  {
    valueId: "EV020766",
    valueDesc: "SBR",
    label: "EV020766: SBR"
  },
  {
    valueId: "EV020768",
    valueDesc: "Polyether ether ketone (PEEK)",
    label: "EV020768: Polyether ether ketone (PEEK)"
  },
  {
    valueId: "EV020769",
    valueDesc: "Siliconised",
    label: "EV020769: Siliconised"
  },
  {
    valueId: "EV020770",
    valueDesc: "Molykoted",
    label: "EV020770: Molykoted"
  },
  {
    valueId: "EV020771",
    valueDesc: "Talced",
    label: "EV020771: Talced"
  },
  {
    valueId: "EV020772",
    valueDesc: "PTFE coated",
    label: "EV020772: PTFE coated"
  },
  {
    valueId: "EV020773",
    valueDesc: "Graphited",
    label: "EV020773: Graphited"
  },
  {
    valueId: "EV020775",
    valueDesc: "PN 250",
    label: "EV020775: PN 250"
  },
  {
    valueId: "EV020776",
    valueDesc: "Thermostatic element",
    label: "EV020776: Thermostatic element"
  },
  {
    valueId: "EV020777",
    valueDesc: "Slide end",
    label: "EV020777: Slide end"
  },
  {
    valueId: "EV020779",
    valueDesc: "Rebated",
    label: "EV020779: Rebated"
  },
  {
    valueId: "EV020780",
    valueDesc: "Cast",
    label: "EV020780: Cast"
  },
  {
    valueId: "EV020781",
    valueDesc: "HBO 2",
    label: "EV020781: HBO 2"
  },
  {
    valueId: "EV020783",
    valueDesc: "RBB fast cover/Weerter git",
    label: "EV020783: RBB fast cover/Weerter git"
  },
  {
    valueId: "EV020784",
    valueDesc: "Slide sleeve",
    label: "EV020784: Slide sleeve"
  },
  {
    valueId: "EV020785",
    valueDesc: "Re-improved double-size",
    label: "EV020785: Re-improved double-size"
  },
  {
    valueId: "EV020786",
    valueDesc: "Sleeveless-slide transition",
    label: "EV020786: Sleeveless-slide transition"
  },
  {
    valueId: "EV020788",
    valueDesc: "Collector",
    label: "EV020788: Collector"
  },
  {
    valueId: "EV020789",
    valueDesc: "Z-shape",
    label: "EV020789: Z-shape"
  },
  {
    valueId: "EV020790",
    valueDesc: "Lead 20",
    label: "EV020790: Lead 20"
  },
  {
    valueId: "EV020791",
    valueDesc: "Lead 25",
    label: "EV020791: Lead 25"
  },
  {
    valueId: "EV020792",
    valueDesc: "Lead 30",
    label: "EV020792: Lead 30"
  },
  {
    valueId: "EV020796",
    valueDesc: "Liquids",
    label: "EV020796: Liquids"
  },
  {
    valueId: "EV020797",
    valueDesc: "Gases",
    label: "EV020797: Gases"
  },
  {
    valueId: "EV020800",
    valueDesc: "Axially limited",
    label: "EV020800: Axially limited"
  },
  {
    valueId: "EV020801",
    valueDesc: "Differential",
    label: "EV020801: Differential"
  },
  {
    valueId: "EV020802",
    valueDesc: "Angular (hinge)",
    label: "EV020802: Angular (hinge)"
  },
  {
    valueId: "EV020803",
    valueDesc: "Thermostatic with button",
    label: "EV020803: Thermostatic with button"
  },
  {
    valueId: "EV020804",
    valueDesc: "Thermostatic without button",
    label: "EV020804: Thermostatic without button"
  },
  {
    valueId: "EV020805",
    valueDesc: "XNBR",
    label: "EV020805: XNBR"
  },
  {
    valueId: "EV020806",
    valueDesc: "Perfluoroalkoxy (PFA)",
    label: "EV020806: Perfluoroalkoxy (PFA)"
  },
  {
    valueId: "EV020807",
    valueDesc: "Hard fabric",
    label: "EV020807: Hard fabric"
  },
  {
    valueId: "EV020808",
    valueDesc: "Monel 400",
    label: "EV020808: Monel 400"
  },
  {
    valueId: "EV020809",
    valueDesc: "Hastelloy",
    label: "EV020809: Hastelloy"
  },
  {
    valueId: "EV020811",
    valueDesc: "Welded end",
    label: "EV020811: Welded end"
  },
  {
    valueId: "EV020812",
    valueDesc: "Welded sleeve",
    label: "EV020812: Welded sleeve"
  },
  {
    valueId: "EV020813",
    valueDesc: "PP-GF",
    label: "EV020813: PP-GF"
  },
  {
    valueId: "EV020814",
    valueDesc: "PP-C",
    label: "EV020814: PP-C"
  },
  {
    valueId: "EV020815",
    valueDesc: "PP-R",
    label: "EV020815: PP-R"
  },
  {
    valueId: "EV020816",
    valueDesc: "PP-H",
    label: "EV020816: PP-H"
  },
  {
    valueId: "EV020817",
    valueDesc: "PP-S",
    label: "EV020817: PP-S"
  },
  {
    valueId: "EV020818",
    valueDesc: "St 45 (1.0408)",
    label: "EV020818: St 45 (1.0408)"
  },
  {
    valueId: "EV020819",
    valueDesc: "Gasket grooves",
    label: "EV020819: Gasket grooves"
  },
  {
    valueId: "EV020820",
    valueDesc: "O ring groove",
    label: "EV020820: O ring groove"
  },
  {
    valueId: "EV020821",
    valueDesc: "Electric weld",
    label: "EV020821: Electric weld"
  },
  {
    valueId: "EV020822",
    valueDesc: "Welded-on flange",
    label: "EV020822: Welded-on flange"
  },
  {
    valueId: "EV020823",
    valueDesc: "Ribbed",
    label: "EV020823: Ribbed"
  },
  {
    valueId: "EV020827",
    valueDesc: "Chemical",
    label: "EV020827: Chemical"
  },
  {
    valueId: "EV020828",
    valueDesc: "Synthetic resin",
    label: "EV020828: Synthetic resin"
  },
  {
    valueId: "EV020830",
    valueDesc: "Holes",
    label: "EV020830: Holes"
  },
  {
    valueId: "EV020832",
    valueDesc: "Pulse",
    label: "EV020832: Pulse"
  },
  {
    valueId: "EV020833",
    valueDesc: "U shaped",
    label: "EV020833: U shaped"
  },
  {
    valueId: "EV020834",
    valueDesc: "NTC resistance",
    label: "EV020834: NTC resistance"
  },
  {
    valueId: "EV020835",
    valueDesc: "Triangular star",
    label: "EV020835: Triangular star"
  },
  {
    valueId: "EV020836",
    valueDesc: "14 inch",
    label: "EV020836: 14 inch"
  },
  {
    valueId: "EV020838",
    valueDesc: "On-off",
    label: "EV020838: On-off"
  },
  {
    valueId: "EV020840",
    valueDesc: "Rapid coupling",
    label: "EV020840: Rapid coupling"
  },
  {
    valueId: "EV020841",
    valueDesc: "Glass fibre reinforced",
    label: "EV020841: Glass fibre reinforced"
  },
  {
    valueId: "EV020842",
    valueDesc: "L coupling",
    label: "EV020842: L coupling"
  },
  {
    valueId: "EV020843",
    valueDesc: "ANSI",
    label: "EV020843: ANSI"
  },
  {
    valueId: "EV020846",
    valueDesc: "Air scoop",
    label: "EV020846: Air scoop"
  },
  {
    valueId: "EV020847",
    valueDesc: "Fin",
    label: "EV020847: Fin"
  },
  {
    valueId: "EV020848",
    valueDesc: "Labyrinth",
    label: "EV020848: Labyrinth"
  },
  {
    valueId: "EV020849",
    valueDesc: "Screws and junction box",
    label: "EV020849: Screws and junction box"
  },
  {
    valueId: "EV020850",
    valueDesc: "Junction box and tie rod",
    label: "EV020850: Junction box and tie rod"
  },
  {
    valueId: "EV020852",
    valueDesc: "Four-sided",
    label: "EV020852: Four-sided"
  },
  {
    valueId: "EV020854",
    valueDesc: "Aramid",
    label: "EV020854: Aramid"
  },
  {
    valueId: "EV020855",
    valueDesc: "Carbon fibre",
    label: "EV020855: Carbon fibre"
  },
  {
    valueId: "EV020856",
    valueDesc: "Vegetable fibre",
    label: "EV020856: Vegetable fibre"
  },
  {
    valueId: "EV020858",
    valueDesc: "Graphite fibre",
    label: "EV020858: Graphite fibre"
  },
  {
    valueId: "EV020859",
    valueDesc: "PTFE, graphite-filled",
    label: "EV020859: PTFE, graphite-filled"
  },
  {
    valueId: "EV020860",
    valueDesc: "PTFE, talc-filled",
    label: "EV020860: PTFE, talc-filled"
  },
  {
    valueId: "EV020861",
    valueDesc: "PTFE, extended",
    label: "EV020861: PTFE, extended"
  },
  {
    valueId: "EV020862",
    valueDesc: "Aramid stacking yarn",
    label: "EV020862: Aramid stacking yarn"
  },
  {
    valueId: "EV020863",
    valueDesc: "Aramid monofilament",
    label: "EV020863: Aramid monofilament"
  },
  {
    valueId: "EV020864",
    valueDesc: "Graphite, expanded",
    label: "EV020864: Graphite, expanded"
  },
  {
    valueId: "EV020865",
    valueDesc: "Graphite, pure",
    label: "EV020865: Graphite, pure"
  },
  {
    valueId: "EV020866",
    valueDesc: "Rameh",
    label: "EV020866: Rameh"
  },
  {
    valueId: "EV020867",
    valueDesc: "PTFE fibre",
    label: "EV020867: PTFE fibre"
  },
  {
    valueId: "EV020868",
    valueDesc: "PTFE, pure",
    label: "EV020868: PTFE, pure"
  },
  {
    valueId: "EV020869",
    valueDesc: "Concentric braiding",
    label: "EV020869: Concentric braiding"
  },
  {
    valueId: "EV020870",
    valueDesc: "Simple braiding",
    label: "EV020870: Simple braiding"
  },
  {
    valueId: "EV020871",
    valueDesc: "Cross-braiding (one mat.)",
    label: "EV020871: Cross-braiding (one mat.)"
  },
  {
    valueId: "EV020872",
    valueDesc: "Zebra braiding",
    label: "EV020872: Zebra braiding"
  },
  {
    valueId: "EV020873",
    valueDesc: "Longitudinal braiding",
    label: "EV020873: Longitudinal braiding"
  },
  {
    valueId: "EV020874",
    valueDesc: "Wound",
    label: "EV020874: Wound"
  },
  {
    valueId: "EV020875",
    valueDesc: "Folded",
    label: "EV020875: Folded"
  },
  {
    valueId: "EV020876",
    valueDesc: "Extruded",
    label: "EV020876: Extruded"
  },
  {
    valueId: "EV020877",
    valueDesc: "Bound carbon",
    label: "EV020877: Bound carbon"
  },
  {
    valueId: "EV020878",
    valueDesc: "Metal carbide",
    label: "EV020878: Metal carbide"
  },
  {
    valueId: "EV020879",
    valueDesc: "Metal oxide",
    label: "EV020879: Metal oxide"
  },
  {
    valueId: "EV020880",
    valueDesc: "Elastomer, sheathed",
    label: "EV020880: Elastomer, sheathed"
  },
  {
    valueId: "EV020881",
    valueDesc: "Non-elastomer",
    label: "EV020881: Non-elastomer"
  },
  {
    valueId: "EV020882",
    valueDesc: "P",
    label: "EV020882: P"
  },
  {
    valueId: "EV020883",
    valueDesc: "U1",
    label: "EV020883: U1"
  },
  {
    valueId: "EV020884",
    valueDesc: "U2",
    label: "EV020884: U2"
  },
  {
    valueId: "EV020885",
    valueDesc: "U3",
    label: "EV020885: U3"
  },
  {
    valueId: "EV020886",
    valueDesc: "Q1",
    label: "EV020886: Q1"
  },
  {
    valueId: "EV020887",
    valueDesc: "Q2",
    label: "EV020887: Q2"
  },
  {
    valueId: "EV020888",
    valueDesc: "Q3",
    label: "EV020888: Q3"
  },
  {
    valueId: "EV020889",
    valueDesc: "Q4",
    label: "EV020889: Q4"
  },
  {
    valueId: "EV020890",
    valueDesc: "J",
    label: "EV020890: J"
  },
  {
    valueId: "EV020891",
    valueDesc: "V",
    label: "EV020891: V"
  },
  {
    valueId: "EV020892",
    valueDesc: "Y1",
    label: "EV020892: Y1"
  },
  {
    valueId: "EV020893",
    valueDesc: "Y2",
    label: "EV020893: Y2"
  },
  {
    valueId: "EV020894",
    valueDesc: "Shrunk",
    label: "EV020894: Shrunk"
  },
  {
    valueId: "EV020895",
    valueDesc: "Glued",
    label: "EV020895: Glued"
  },
  {
    valueId: "EV020897",
    valueDesc: "Two button",
    label: "EV020897: Two button"
  },
  {
    valueId: "EV020898",
    valueDesc: "FPO",
    label: "EV020898: FPO"
  },
  {
    valueId: "EV020899",
    valueDesc: "Polyisobutylene (PIB)",
    label: "EV020899: Polyisobutylene (PIB)"
  },
  {
    valueId: "EV020900",
    valueDesc: "TPO",
    label: "EV020900: TPO"
  },
  {
    valueId: "EV020901",
    valueDesc: "ECB",
    label: "EV020901: ECB"
  },
  {
    valueId: "EV020902",
    valueDesc: "PVC-P",
    label: "EV020902: PVC-P"
  },
  {
    valueId: "EV020903",
    valueDesc: "Sponge rubber",
    label: "EV020903: Sponge rubber"
  },
  {
    valueId: "EV020904",
    valueDesc: "Cellular rubber",
    label: "EV020904: Cellular rubber"
  },
  {
    valueId: "EV020905",
    valueDesc: "E1",
    label: "EV020905: E1"
  },
  {
    valueId: "EV020906",
    valueDesc: "E2",
    label: "EV020906: E2"
  },
  {
    valueId: "EV020907",
    valueDesc: "E3",
    label: "EV020907: E3"
  },
  {
    valueId: "EV020908",
    valueDesc: "DN 300",
    label: "EV020908: DN 300"
  },
  {
    valueId: "EV020909",
    valueDesc: "Fully enclosed",
    label: "EV020909: Fully enclosed"
  },
  {
    valueId: "EV020910",
    valueDesc: "Multi-spring",
    label: "EV020910: Multi-spring"
  },
  {
    valueId: "EV020911",
    valueDesc: "Single-spring",
    label: "EV020911: Single-spring"
  },
  {
    valueId: "EV020912",
    valueDesc: "Graphite",
    label: "EV020912: Graphite"
  },
  {
    valueId: "EV020913",
    valueDesc: "Flat with cut",
    label: "EV020913: Flat with cut"
  },
  {
    valueId: "EV020914",
    valueDesc: "Spiral-wound",
    label: "EV020914: Spiral-wound"
  },
  {
    valueId: "EV020916",
    valueDesc: "Ceramic fibre",
    label: "EV020916: Ceramic fibre"
  },
  {
    valueId: "EV020917",
    valueDesc: "Braided",
    label: "EV020917: Braided"
  },
  {
    valueId: "EV020918",
    valueDesc: "Mineral fibre",
    label: "EV020918: Mineral fibre"
  },
  {
    valueId: "EV020919",
    valueDesc: "8 inch",
    label: "EV020919: 8 inch"
  },
  {
    valueId: "EV020920",
    valueDesc: "12 inch",
    label: "EV020920: 12 inch"
  },
  {
    valueId: "EV020921",
    valueDesc: "16 inch",
    label: "EV020921: 16 inch"
  },
  {
    valueId: "EV020922",
    valueDesc: "18 inch",
    label: "EV020922: 18 inch"
  },
  {
    valueId: "EV020923",
    valueDesc: "20 inch",
    label: "EV020923: 20 inch"
  },
  {
    valueId: "EV020924",
    valueDesc: "22 inch",
    label: "EV020924: 22 inch"
  },
  {
    valueId: "EV020925",
    valueDesc: "26 inch",
    label: "EV020925: 26 inch"
  },
  {
    valueId: "EV020926",
    valueDesc: "28 inch",
    label: "EV020926: 28 inch"
  },
  {
    valueId: "EV020927",
    valueDesc: "30 inch",
    label: "EV020927: 30 inch"
  },
  {
    valueId: "EV020928",
    valueDesc: "32 inch",
    label: "EV020928: 32 inch"
  },
  {
    valueId: "EV020929",
    valueDesc: "34 inch",
    label: "EV020929: 34 inch"
  },
  {
    valueId: "EV020930",
    valueDesc: "36 inch",
    label: "EV020930: 36 inch"
  },
  {
    valueId: "EV020931",
    valueDesc: "38 inch",
    label: "EV020931: 38 inch"
  },
  {
    valueId: "EV020932",
    valueDesc: "40 inch",
    label: "EV020932: 40 inch"
  },
  {
    valueId: "EV020933",
    valueDesc: "42 inch",
    label: "EV020933: 42 inch"
  },
  {
    valueId: "EV020934",
    valueDesc: "44 inch",
    label: "EV020934: 44 inch"
  },
  {
    valueId: "EV020935",
    valueDesc: "46 inch",
    label: "EV020935: 46 inch"
  },
  {
    valueId: "EV020936",
    valueDesc: "48 inch",
    label: "EV020936: 48 inch"
  },
  {
    valueId: "EV020937",
    valueDesc: "50 inch",
    label: "EV020937: 50 inch"
  },
  {
    valueId: "EV020938",
    valueDesc: "52 inch",
    label: "EV020938: 52 inch"
  },
  {
    valueId: "EV020939",
    valueDesc: "54 inch",
    label: "EV020939: 54 inch"
  },
  {
    valueId: "EV020940",
    valueDesc: "56 inch",
    label: "EV020940: 56 inch"
  },
  {
    valueId: "EV020941",
    valueDesc: "58 inch",
    label: "EV020941: 58 inch"
  },
  {
    valueId: "EV020942",
    valueDesc: "60 inch",
    label: "EV020942: 60 inch"
  },
  {
    valueId: "EV020950",
    valueDesc: "H13",
    label: "EV020950: H13"
  },
  {
    valueId: "EV020951",
    valueDesc: "H14",
    label: "EV020951: H14"
  },
  {
    valueId: "EV020952",
    valueDesc: "U15",
    label: "EV020952: U15"
  },
  {
    valueId: "EV020953",
    valueDesc: "U16",
    label: "EV020953: U16"
  },
  {
    valueId: "EV020954",
    valueDesc: "U17",
    label: "EV020954: U17"
  },
  {
    valueId: "EV020957",
    valueDesc: "Active carbon",
    label: "EV020957: Active carbon"
  },
  {
    valueId: "EV020958",
    valueDesc: "Glass fibre paper",
    label: "EV020958: Glass fibre paper"
  },
  {
    valueId: "EV020959",
    valueDesc: "MDF",
    label: "EV020959: MDF"
  },
  {
    valueId: "EV020960",
    valueDesc: "Chipboard",
    label: "EV020960: Chipboard"
  },
  {
    valueId: "EV020961",
    valueDesc: "Cardboard",
    label: "EV020961: Cardboard"
  },
  {
    valueId: "EV020962",
    valueDesc: "Inorganic fibre",
    label: "EV020962: Inorganic fibre"
  },
  {
    valueId: "EV020963",
    valueDesc: "Pure",
    label: "EV020963: Pure"
  },
  {
    valueId: "EV020964",
    valueDesc: "Expended",
    label: "EV020964: Expended"
  },
  {
    valueId: "EV020965",
    valueDesc: "Filled",
    label: "EV020965: Filled"
  },
  {
    valueId: "EV020966",
    valueDesc: "Extended",
    label: "EV020966: Extended"
  },
  {
    valueId: "EV020967",
    valueDesc: "PCTFE",
    label: "EV020967: PCTFE"
  },
  {
    valueId: "EV020968",
    valueDesc: "PEI",
    label: "EV020968: PEI"
  },
  {
    valueId: "EV020969",
    valueDesc: "PETP",
    label: "EV020969: PETP"
  },
  {
    valueId: "EV020970",
    valueDesc: "PF",
    label: "EV020970: PF"
  },
  {
    valueId: "EV020971",
    valueDesc: "PI",
    label: "EV020971: PI"
  },
  {
    valueId: "EV020972",
    valueDesc: "PPS",
    label: "EV020972: PPS"
  },
  {
    valueId: "EV020973",
    valueDesc: "PSU",
    label: "EV020973: PSU"
  },
  {
    valueId: "EV020974",
    valueDesc: "Hard paper",
    label: "EV020974: Hard paper"
  },
  {
    valueId: "EV020975",
    valueDesc: "PES",
    label: "EV020975: PES"
  },
  {
    valueId: "EV020977",
    valueDesc: "Half hard 1S",
    label: "EV020977: Half hard 1S"
  },
  {
    valueId: "EV020978",
    valueDesc: "Soft 1S",
    label: "EV020978: Soft 1S"
  },
  {
    valueId: "EV020979",
    valueDesc: "Half hard 57S",
    label: "EV020979: Half hard 57S"
  },
  {
    valueId: "EV020980",
    valueDesc: "51 ST",
    label: "EV020980: 51 ST"
  },
  {
    valueId: "EV020981",
    valueDesc: "Ms 63",
    label: "EV020981: Ms 63"
  },
  {
    valueId: "EV020982",
    valueDesc: "Ms 64 hard",
    label: "EV020982: Ms 64 hard"
  },
  {
    valueId: "EV020983",
    valueDesc: "Ms 64 half hard",
    label: "EV020983: Ms 64 half hard"
  },
  {
    valueId: "EV020984",
    valueDesc: "Ms 64 soft",
    label: "EV020984: Ms 64 soft"
  },
  {
    valueId: "EV020985",
    valueDesc: "70",
    label: "EV020985: 70"
  },
  {
    valueId: "EV020986",
    valueDesc: "F 22",
    label: "EV020986: F 22"
  },
  {
    valueId: "EV020987",
    valueDesc: "F 24",
    label: "EV020987: F 24"
  },
  {
    valueId: "EV020988",
    valueDesc: "F 25",
    label: "EV020988: F 25"
  },
  {
    valueId: "EV020989",
    valueDesc: "Stainless steel 430 (1.4016)",
    label: "EV020989: Stainless steel 430 (1.4016)"
  },
  {
    valueId: "EV020990",
    valueDesc: "Hastelloy B-2",
    label: "EV020990: Hastelloy B-2"
  },
  {
    valueId: "EV020991",
    valueDesc: "Hastelloy C-4",
    label: "EV020991: Hastelloy C-4"
  },
  {
    valueId: "EV020992",
    valueDesc: "Hastelloy C-22",
    label: "EV020992: Hastelloy C-22"
  },
  {
    valueId: "EV020993",
    valueDesc: "Hastelloy C-276",
    label: "EV020993: Hastelloy C-276"
  },
  {
    valueId: "EV020994",
    valueDesc: "Incoloy 800/H/HT",
    label: "EV020994: Incoloy 800/H/HT"
  },
  {
    valueId: "EV020995",
    valueDesc: "Inconel 600",
    label: "EV020995: Inconel 600"
  },
  {
    valueId: "EV020996",
    valueDesc: "Inconel 625",
    label: "EV020996: Inconel 625"
  },
  {
    valueId: "EV020997",
    valueDesc: "Glass wool-reinforced plastic",
    label: "EV020997: Glass wool-reinforced plastic"
  },
  {
    valueId: "EV020998",
    valueDesc: "Double round",
    label: "EV020998: Double round"
  },
  {
    valueId: "EV020999",
    valueDesc: "Clover-leaf",
    label: "EV020999: Clover-leaf"
  },
  {
    valueId: "EV021000",
    valueDesc: "Triangle 80°",
    label: "EV021000: Triangle 80°"
  },
  {
    valueId: "EV021001",
    valueDesc: "Triangle 90°",
    label: "EV021001: Triangle 90°"
  },
  {
    valueId: "EV021002",
    valueDesc: "M/F (seal)",
    label: "EV021002: M/F (seal)"
  },
  {
    valueId: "EV021003",
    valueDesc: "Tongue and groove",
    label: "EV021003: Tongue and groove"
  },
  {
    valueId: "EV021004",
    valueDesc: "DIN 2690 (RF/FF)",
    label: "EV021004: DIN 2690 (RF/FF)"
  },
  {
    valueId: "EV021005",
    valueDesc: "DIN 2691 (tongue and groove)",
    label: "EV021005: DIN 2691 (tongue and groove)"
  },
  {
    valueId: "EV021006",
    valueDesc: "DIN 2692 (m/v)",
    label: "EV021006: DIN 2692 (m/v)"
  },
  {
    valueId: "EV021007",
    valueDesc: "U-round",
    label: "EV021007: U-round"
  },
  {
    valueId: "EV021008",
    valueDesc: "U-square",
    label: "EV021008: U-square"
  },
  {
    valueId: "EV021009",
    valueDesc: "V-Sliced",
    label: "EV021009: V-Sliced"
  },
  {
    valueId: "EV021010",
    valueDesc: "Fibre",
    label: "EV021010: Fibre"
  },
  {
    valueId: "EV021011",
    valueDesc: "Phenolic",
    label: "EV021011: Phenolic"
  },
  {
    valueId: "EV021012",
    valueDesc: "Spring-activated seal",
    label: "EV021012: Spring-activated seal"
  },
  {
    valueId: "EV021013",
    valueDesc: "Fine flat",
    label: "EV021013: Fine flat"
  },
  {
    valueId: "EV021014",
    valueDesc: "Fine round",
    label: "EV021014: Fine round"
  },
  {
    valueId: "EV021015",
    valueDesc: "Coarse flat",
    label: "EV021015: Coarse flat"
  },
  {
    valueId: "EV021016",
    valueDesc: "Coarse round",
    label: "EV021016: Coarse round"
  },
  {
    valueId: "EV021017",
    valueDesc: "Punched",
    label: "EV021017: Punched"
  },
  {
    valueId: "EV021018",
    valueDesc: "Spiral with inner ring",
    label: "EV021018: Spiral with inner ring"
  },
  {
    valueId: "EV021019",
    valueDesc: "Spiral with outer ring",
    label: "EV021019: Spiral with outer ring"
  },
  {
    valueId: "EV021020",
    valueDesc: "Spiral with inner and outer ring",
    label: "EV021020: Spiral with inner and outer ring"
  },
  {
    valueId: "EV021021",
    valueDesc: "Spiral ring",
    label: "EV021021: Spiral ring"
  },
  {
    valueId: "EV021022",
    valueDesc: "ASME B16.20",
    label: "EV021022: ASME B16.20"
  },
  {
    valueId: "EV021023",
    valueDesc: "BS 3381",
    label: "EV021023: BS 3381"
  },
  {
    valueId: "EV021024",
    valueDesc: "Wall (180°)",
    label: "EV021024: Wall (180°)"
  },
  {
    valueId: "EV021025",
    valueDesc: "Angle (90°)",
    label: "EV021025: Angle (90°)"
  },
  {
    valueId: "EV021026",
    valueDesc: "Free-standing (360°)",
    label: "EV021026: Free-standing (360°)"
  },
  {
    valueId: "EV021027",
    valueDesc: "5S",
    label: "EV021027: 5S"
  },
  {
    valueId: "EV021028",
    valueDesc: "10S",
    label: "EV021028: 10S"
  },
  {
    valueId: "EV021029",
    valueDesc: "100",
    label: "EV021029: 100"
  },
  {
    valueId: "EV021030",
    valueDesc: "120",
    label: "EV021030: 120"
  },
  {
    valueId: "EV021031",
    valueDesc: "140",
    label: "EV021031: 140"
  },
  {
    valueId: "EV021032",
    valueDesc: "160",
    label: "EV021032: 160"
  },
  {
    valueId: "EV021033",
    valueDesc: "40",
    label: "EV021033: 40"
  },
  {
    valueId: "EV021034",
    valueDesc: "STD",
    label: "EV021034: STD"
  },
  {
    valueId: "EV021036",
    valueDesc: "XXS",
    label: "EV021036: XXS"
  },
  {
    valueId: "EV021037",
    valueDesc: "Monodirectional",
    label: "EV021037: Monodirectional"
  },
  {
    valueId: "EV021038",
    valueDesc: "Bidirectional",
    label: "EV021038: Bidirectional"
  },
  {
    valueId: "EV021039",
    valueDesc: "RF/FF",
    label: "EV021039: RF/FF"
  },
  {
    valueId: "EV021040",
    valueDesc: "Sintered PTFE",
    label: "EV021040: Sintered PTFE"
  },
  {
    valueId: "EV021041",
    valueDesc: "Expended PTFE",
    label: "EV021041: Expended PTFE"
  },
  {
    valueId: "EV021042",
    valueDesc: "Soft iron, galvanised",
    label: "EV021042: Soft iron, galvanised"
  },
  {
    valueId: "EV021043",
    valueDesc: "Soft iron, cadmium-plated",
    label: "EV021043: Soft iron, cadmium-plated"
  },
  {
    valueId: "EV021044",
    valueDesc: "R-oval",
    label: "EV021044: R-oval"
  },
  {
    valueId: "EV021045",
    valueDesc: "R-octagonal",
    label: "EV021045: R-octagonal"
  },
  {
    valueId: "EV021046",
    valueDesc: "RX",
    label: "EV021046: RX"
  },
  {
    valueId: "EV021047",
    valueDesc: "BX",
    label: "EV021047: BX"
  },
  {
    valueId: "EV021048",
    valueDesc: "1 13/16 inch",
    label: "EV021048: 1 13/16 inch"
  },
  {
    valueId: "EV021049",
    valueDesc: "2 1/16 inch",
    label: "EV021049: 2 1/16 inch"
  },
  {
    valueId: "EV021050",
    valueDesc: "2 1/8 inch",
    label: "EV021050: 2 1/8 inch"
  },
  {
    valueId: "EV021051",
    valueDesc: "2 9/16 inch",
    label: "EV021051: 2 9/16 inch"
  },
  {
    valueId: "EV021052",
    valueDesc: "3 1/16 inch",
    label: "EV021052: 3 1/16 inch"
  },
  {
    valueId: "EV021053",
    valueDesc: "3 1/8 inch",
    label: "EV021053: 3 1/8 inch"
  },
  {
    valueId: "EV021054",
    valueDesc: "4 1/16 inch",
    label: "EV021054: 4 1/16 inch"
  },
  {
    valueId: "EV021055",
    valueDesc: "5 3/4 inch",
    label: "EV021055: 5 3/4 inch"
  },
  {
    valueId: "EV021056",
    valueDesc: "7 1/16 inch",
    label: "EV021056: 7 1/16 inch"
  },
  {
    valueId: "EV021057",
    valueDesc: "13 5/8 inch",
    label: "EV021057: 13 5/8 inch"
  },
  {
    valueId: "EV021058",
    valueDesc: "16 3/4 inch",
    label: "EV021058: 16 3/4 inch"
  },
  {
    valueId: "EV021059",
    valueDesc: "20 3/4 inch",
    label: "EV021059: 20 3/4 inch"
  },
  {
    valueId: "EV021060",
    valueDesc: "21 1/4 inch",
    label: "EV021060: 21 1/4 inch"
  },
  {
    valueId: "EV021061",
    valueDesc: "9 inch",
    label: "EV021061: 9 inch"
  },
  {
    valueId: "EV021062",
    valueDesc: "11 inch",
    label: "EV021062: 11 inch"
  },
  {
    valueId: "EV021063",
    valueDesc: "Rubber with fabric",
    label: "EV021063: Rubber with fabric"
  },
  {
    valueId: "EV021064",
    valueDesc: "Stainless steel/graphite",
    label: "EV021064: Stainless steel/graphite"
  },
  {
    valueId: "EV021065",
    valueDesc: "Cover",
    label: "EV021065: Cover"
  },
  {
    valueId: "EV021066",
    valueDesc: "17 mm",
    label: "EV021066: 17 mm"
  },
  {
    valueId: "EV021067",
    valueDesc: "31 mm",
    label: "EV021067: 31 mm"
  },
  {
    valueId: "EV021068",
    valueDesc: "Guide block",
    label: "EV021068: Guide block"
  },
  {
    valueId: "EV021069",
    valueDesc: "Chain sprocket",
    label: "EV021069: Chain sprocket"
  },
  {
    valueId: "EV021070",
    valueDesc: "Chain guide",
    label: "EV021070: Chain guide"
  },
  {
    valueId: "EV021071",
    valueDesc: "Plain bearing",
    label: "EV021071: Plain bearing"
  },
  {
    valueId: "EV021072",
    valueDesc: "Ball bearing",
    label: "EV021072: Ball bearing"
  },
  {
    valueId: "EV021073",
    valueDesc: "Needle roller bearing",
    label: "EV021073: Needle roller bearing"
  },
  {
    valueId: "EV021075",
    valueDesc: "Single D-section",
    label: "EV021075: Single D-section"
  },
  {
    valueId: "EV021076",
    valueDesc: "Double D-section",
    label: "EV021076: Double D-section"
  },
  {
    valueId: "EV021077",
    valueDesc: "UHMWPE",
    label: "EV021077: UHMWPE"
  },
  {
    valueId: "EV021078",
    valueDesc: "X",
    label: "EV021078: X"
  },
  {
    valueId: "EV021080",
    valueDesc: "ASTM",
    label: "EV021080: ASTM"
  },
  {
    valueId: "EV021081",
    valueDesc: "Under pressure",
    label: "EV021081: Under pressure"
  },
  {
    valueId: "EV021082",
    valueDesc: "Elastomer",
    label: "EV021082: Elastomer"
  },
  {
    valueId: "EV021083",
    valueDesc: "Flanged wheel",
    label: "EV021083: Flanged wheel"
  },
  {
    valueId: "EV021084",
    valueDesc: "Pneumatic tyre",
    label: "EV021084: Pneumatic tyre"
  },
  {
    valueId: "EV021085",
    valueDesc: "Solid with rim",
    label: "EV021085: Solid with rim"
  },
  {
    valueId: "EV021086",
    valueDesc: "Rigid caster",
    label: "EV021086: Rigid caster"
  },
  {
    valueId: "EV021087",
    valueDesc: "Castor, without brake",
    label: "EV021087: Castor, without brake"
  },
  {
    valueId: "EV021088",
    valueDesc: "Castor, with brake",
    label: "EV021088: Castor, with brake"
  },
  {
    valueId: "EV021089",
    valueDesc: "Pipe clip",
    label: "EV021089: Pipe clip"
  },
  {
    valueId: "EV021090",
    valueDesc: "Inner tube",
    label: "EV021090: Inner tube"
  },
  {
    valueId: "EV021091",
    valueDesc: "Outer tyre",
    label: "EV021091: Outer tyre"
  },
  {
    valueId: "EV021092",
    valueDesc: "Aluminium oxide",
    label: "EV021092: Aluminium oxide"
  },
  {
    valueId: "EV021093",
    valueDesc: "Pigtail",
    label: "EV021093: Pigtail"
  },
  {
    valueId: "EV021094",
    valueDesc: "Wreath",
    label: "EV021094: Wreath"
  },
  {
    valueId: "EV021095",
    valueDesc: "C-section with fastening strip",
    label: "EV021095: C-section with fastening strip"
  },
  {
    valueId: "EV021097",
    valueDesc: "Encapsulated on two sides",
    label: "EV021097: Encapsulated on two sides"
  },
  {
    valueId: "EV021098",
    valueDesc: "Encapsulated on three sides",
    label: "EV021098: Encapsulated on three sides"
  },
  {
    valueId: "EV021099",
    valueDesc: "Connecting plug",
    label: "EV021099: Connecting plug"
  },
  {
    valueId: "EV021100",
    valueDesc: "Daily program",
    label: "EV021100: Daily program"
  },
  {
    valueId: "EV021101",
    valueDesc: "Temperature/daily program",
    label: "EV021101: Temperature/daily program"
  },
  {
    valueId: "EV021102",
    valueDesc: "Temperature/weekly program",
    label: "EV021102: Temperature/weekly program"
  },
  {
    valueId: "EV021103",
    valueDesc: "Weekly program",
    label: "EV021103: Weekly program"
  },
  {
    valueId: "EV021104",
    valueDesc: "3 x 440 V",
    label: "EV021104: 3 x 440 V"
  },
  {
    valueId: "EV021105",
    valueDesc: "Pole-reversible",
    label: "EV021105: Pole-reversible"
  },
  {
    valueId: "EV021107",
    valueDesc: "With cast-on float",
    label: "EV021107: With cast-on float"
  },
  {
    valueId: "EV021108",
    valueDesc: "With external float",
    label: "EV021108: With external float"
  },
  {
    valueId: "EV021109",
    valueDesc: "With internal float",
    label: "EV021109: With internal float"
  },
  {
    valueId: "EV021110",
    valueDesc: "Steel, chromium-plated",
    label: "EV021110: Steel, chromium-plated"
  },
  {
    valueId: "EV021112",
    valueDesc: "9/16 inch",
    label: "EV021112: 9/16 inch"
  },
  {
    valueId: "EV021113",
    valueDesc: "Thin wall",
    label: "EV021113: Thin wall"
  },
  {
    valueId: "EV021114",
    valueDesc: "Medium wall",
    label: "EV021114: Medium wall"
  },
  {
    valueId: "EV021115",
    valueDesc: "Thick wall",
    label: "EV021115: Thick wall"
  },
  {
    valueId: "EV021116",
    valueDesc: "NBR2",
    label: "EV021116: NBR2"
  },
  {
    valueId: "EV021117",
    valueDesc: "NBR3",
    label: "EV021117: NBR3"
  },
  {
    valueId: "EV021118",
    valueDesc: "CIIR",
    label: "EV021118: CIIR"
  },
  {
    valueId: "EV021119",
    valueDesc: "PE-X",
    label: "EV021119: PE-X"
  },
  {
    valueId: "EV021120",
    valueDesc: "NR/SBR",
    label: "EV021120: NR/SBR"
  },
  {
    valueId: "EV021121",
    valueDesc: "SBR/EPDM",
    label: "EV021121: SBR/EPDM"
  },
  {
    valueId: "EV021122",
    valueDesc: "SBR/NBR",
    label: "EV021122: SBR/NBR"
  },
  {
    valueId: "EV021123",
    valueDesc: "UPE",
    label: "EV021123: UPE"
  },
  {
    valueId: "EV021124",
    valueDesc: "NBR/CR",
    label: "EV021124: NBR/CR"
  },
  {
    valueId: "EV021125",
    valueDesc: "NBR/EPDM",
    label: "EV021125: NBR/EPDM"
  },
  {
    valueId: "EV021126",
    valueDesc: "NBR/PVC",
    label: "EV021126: NBR/PVC"
  },
  {
    valueId: "EV021127",
    valueDesc: "NR/NBR",
    label: "EV021127: NR/NBR"
  },
  {
    valueId: "EV021128",
    valueDesc: "Glass fibre PVC coating",
    label: "EV021128: Glass fibre PVC coating"
  },
  {
    valueId: "EV021129",
    valueDesc: "Glass fibre silicone coating",
    label: "EV021129: Glass fibre silicone coating"
  },
  {
    valueId: "EV021130",
    valueDesc: "Glass fibre neoprene coating",
    label: "EV021130: Glass fibre neoprene coating"
  },
  {
    valueId: "EV021131",
    valueDesc: "Polyester/AU",
    label: "EV021131: Polyester/AU"
  },
  {
    valueId: "EV021132",
    valueDesc: "Tank unit (male)",
    label: "EV021132: Tank unit (male)"
  },
  {
    valueId: "EV021133",
    valueDesc: "4 1/2 inch",
    label: "EV021133: 4 1/2 inch"
  },
  {
    valueId: "EV021134",
    valueDesc: "5 1/2 inch",
    label: "EV021134: 5 1/2 inch"
  },
  {
    valueId: "EV021135",
    valueDesc: "Switching",
    label: "EV021135: Switching"
  },
  {
    valueId: "EV021136",
    valueDesc: "V-snare",
    label: "EV021136: V-snare"
  },
  {
    valueId: "EV021137",
    valueDesc: "Horsehair",
    label: "EV021137: Horsehair"
  },
  {
    valueId: "EV021138",
    valueDesc: "Aluminium/zinc",
    label: "EV021138: Aluminium/zinc"
  },
  {
    valueId: "EV021139",
    valueDesc: "1 1/8 inch",
    label: "EV021139: 1 1/8 inch"
  },
  {
    valueId: "EV021141",
    valueDesc: "13/32 inch",
    label: "EV021141: 13/32 inch"
  },
  {
    valueId: "EV021143",
    valueDesc: "ANSI sch 10",
    label: "EV021143: ANSI sch 10"
  },
  {
    valueId: "EV021144",
    valueDesc: "ANSI sch 40",
    label: "EV021144: ANSI sch 40"
  },
  {
    valueId: "EV021145",
    valueDesc: "Sinking ball",
    label: "EV021145: Sinking ball"
  },
  {
    valueId: "EV021146",
    valueDesc: "Ball, spring-loaded",
    label: "EV021146: Ball, spring-loaded"
  },
  {
    valueId: "EV021147",
    valueDesc: "Banjo coupling",
    label: "EV021147: Banjo coupling"
  },
  {
    valueId: "EV021148",
    valueDesc: "DIN 2642/PN 16",
    label: "EV021148: DIN 2642/PN 16"
  },
  {
    valueId: "EV021149",
    valueDesc: "DIN 2655/PN 25",
    label: "EV021149: DIN 2655/PN 25"
  },
  {
    valueId: "EV021150",
    valueDesc: "DIN 2656/PN 40",
    label: "EV021150: DIN 2656/PN 40"
  },
  {
    valueId: "EV021151",
    valueDesc: "DN 4",
    label: "EV021151: DN 4"
  },
  {
    valueId: "EV021152",
    valueDesc: "Double ball",
    label: "EV021152: Double ball"
  },
  {
    valueId: "EV021153",
    valueDesc: "Double ball, spring-loaded",
    label: "EV021153: Double ball, spring-loaded"
  },
  {
    valueId: "EV021154",
    valueDesc: "Double plug",
    label: "EV021154: Double plug"
  },
  {
    valueId: "EV021155",
    valueDesc: "E-motor with gearbox",
    label: "EV021155: E-motor with gearbox"
  },
  {
    valueId: "EV021156",
    valueDesc: "Hose unit (female)",
    label: "EV021156: Hose unit (female)"
  },
  {
    valueId: "EV021157",
    valueDesc: "Sleeve weld",
    label: "EV021157: Sleeve weld"
  },
  {
    valueId: "EV021158",
    valueDesc: "Stainless steel 301 (1.4310)",
    label: "EV021158: Stainless steel 301 (1.4310)"
  },
  {
    valueId: "EV021159",
    valueDesc: "Partition coupling, square",
    label: "EV021159: Partition coupling, square"
  },
  {
    valueId: "EV021160",
    valueDesc: "Partition coupling, straight",
    label: "EV021160: Partition coupling, straight"
  },
  {
    valueId: "EV021161",
    valueDesc: "Butt weld",
    label: "EV021161: Butt weld"
  },
  {
    valueId: "EV021162",
    valueDesc: "Spring-loaded",
    label: "EV021162: Spring-loaded"
  },
  {
    valueId: "EV021163",
    valueDesc: "Internal thread JIC",
    label: "EV021163: Internal thread JIC"
  },
  {
    valueId: "EV021164",
    valueDesc: "Internal thread JIS",
    label: "EV021164: Internal thread JIS"
  },
  {
    valueId: "EV021165",
    valueDesc: "Internal thread SAE",
    label: "EV021165: Internal thread SAE"
  },
  {
    valueId: "EV021166",
    valueDesc: "External thread JIC",
    label: "EV021166: External thread JIC"
  },
  {
    valueId: "EV021167",
    valueDesc: "External thread JIS",
    label: "EV021167: External thread JIS"
  },
  {
    valueId: "EV021168",
    valueDesc: "External thread SAE",
    label: "EV021168: External thread SAE"
  },
  {
    valueId: "EV021170",
    valueDesc: "PN 10 / DN 50",
    label: "EV021170: PN 10 / DN 50"
  },
  {
    valueId: "EV021171",
    valueDesc: "PN 10 / DN 80",
    label: "EV021171: PN 10 / DN 80"
  },
  {
    valueId: "EV021172",
    valueDesc: "Pre-pressed",
    label: "EV021172: Pre-pressed"
  },
  {
    valueId: "EV021173",
    valueDesc: "7/16 inch",
    label: "EV021173: 7/16 inch"
  },
  {
    valueId: "EV021174",
    valueDesc: "1 1/16 inch",
    label: "EV021174: 1 1/16 inch"
  },
  {
    valueId: "EV021175",
    valueDesc: "1 3/16 inch",
    label: "EV021175: 1 3/16 inch"
  },
  {
    valueId: "EV021176",
    valueDesc: "1 5/16 inch",
    label: "EV021176: 1 5/16 inch"
  },
  {
    valueId: "EV021177",
    valueDesc: "1 5/8 inch",
    label: "EV021177: 1 5/8 inch"
  },
  {
    valueId: "EV021178",
    valueDesc: "1 7/8 inch",
    label: "EV021178: 1 7/8 inch"
  },
  {
    valueId: "EV021179",
    valueDesc: "Internal dairy thread (DIN 405)",
    label: "EV021179: Internal dairy thread (DIN 405)"
  },
  {
    valueId: "EV021180",
    valueDesc: "External dairy thread (DIN 405)",
    label: "EV021180: External dairy thread (DIN 405)"
  },
  {
    valueId: "EV021181",
    valueDesc: "EN",
    label: "EV021181: EN"
  },
  {
    valueId: "EV021182",
    valueDesc: "SAE",
    label: "EV021182: SAE"
  },
  {
    valueId: "EV021184",
    valueDesc: "Cladding",
    label: "EV021184: Cladding"
  },
  {
    valueId: "EV021185",
    valueDesc: "Gasket",
    label: "EV021185: Gasket"
  },
  {
    valueId: "EV021186",
    valueDesc: "Liquid (Gel)",
    label: "EV021186: Liquid (Gel)"
  },
  {
    valueId: "EV021187",
    valueDesc: "Single screw",
    label: "EV021187: Single screw"
  },
  {
    valueId: "EV021188",
    valueDesc: "Double screw",
    label: "EV021188: Double screw"
  },
  {
    valueId: "EV021189",
    valueDesc: "Single click",
    label: "EV021189: Single click"
  },
  {
    valueId: "EV021190",
    valueDesc: "Double click",
    label: "EV021190: Double click"
  },
  {
    valueId: "EV021191",
    valueDesc: "Drawing-in",
    label: "EV021191: Drawing-in"
  },
  {
    valueId: "EV021192",
    valueDesc: "Exhaust",
    label: "EV021192: Exhaust"
  },
  {
    valueId: "EV021193",
    valueDesc: "Glass fibre fleece",
    label: "EV021193: Glass fibre fleece"
  },
  {
    valueId: "EV021194",
    valueDesc: "Beech wood",
    label: "EV021194: Beech wood"
  },
  {
    valueId: "EV021195",
    valueDesc: "DIN 2642/PN 10",
    label: "EV021195: DIN 2642/PN 10"
  },
  {
    valueId: "EV021196",
    valueDesc: "VK (male)",
    label: "EV021196: VK (male)"
  },
  {
    valueId: "EV021197",
    valueDesc: "MK (female)",
    label: "EV021197: MK (female)"
  },
  {
    valueId: "EV021198",
    valueDesc: "MB (female) cap",
    label: "EV021198: MB (female) cap"
  },
  {
    valueId: "EV021199",
    valueDesc: "VB (male) plug",
    label: "EV021199: VB (male) plug"
  },
  {
    valueId: "EV021200",
    valueDesc: "Lateral",
    label: "EV021200: Lateral"
  },
  {
    valueId: "EV021201",
    valueDesc: "PTFE with carbon (black)",
    label: "EV021201: PTFE with carbon (black)"
  },
  {
    valueId: "EV021202",
    valueDesc: "PTFE without carbon (white)",
    label: "EV021202: PTFE without carbon (white)"
  },
  {
    valueId: "EV021203",
    valueDesc: "Partition coupling",
    label: "EV021203: Partition coupling"
  },
  {
    valueId: "EV021204",
    valueDesc: "Cutting edge",
    label: "EV021204: Cutting edge"
  },
  {
    valueId: "EV021205",
    valueDesc: "Metal ring",
    label: "EV021205: Metal ring"
  },
  {
    valueId: "EV021206",
    valueDesc: "Soft ring",
    label: "EV021206: Soft ring"
  },
  {
    valueId: "EV021207",
    valueDesc: "Inner cone, 60°",
    label: "EV021207: Inner cone, 60°"
  },
  {
    valueId: "EV021208",
    valueDesc: "Inner cone, 45°",
    label: "EV021208: Inner cone, 45°"
  },
  {
    valueId: "EV021209",
    valueDesc: "Inner cone, 37°",
    label: "EV021209: Inner cone, 37°"
  },
  {
    valueId: "EV021210",
    valueDesc: "Outer cone, 45°",
    label: "EV021210: Outer cone, 45°"
  },
  {
    valueId: "EV021211",
    valueDesc: "Outer cone, 37°",
    label: "EV021211: Outer cone, 37°"
  },
  {
    valueId: "EV021212",
    valueDesc: "Face-sealing",
    label: "EV021212: Face-sealing"
  },
  {
    valueId: "EV021213",
    valueDesc: "Inner cone, 24°",
    label: "EV021213: Inner cone, 24°"
  },
  {
    valueId: "EV021214",
    valueDesc: "Steel, yellow, galvanised",
    label: "EV021214: Steel, yellow, galvanised"
  },
  {
    valueId: "EV021215",
    valueDesc: "Steel, phosphated",
    label: "EV021215: Steel, phosphated"
  },
  {
    valueId: "EV021216",
    valueDesc: "LL (very light)",
    label: "EV021216: LL (very light)"
  },
  {
    valueId: "EV021217",
    valueDesc: "L (light)",
    label: "EV021217: L (light)"
  },
  {
    valueId: "EV021218",
    valueDesc: "S (heavy)",
    label: "EV021218: S (heavy)"
  },
  {
    valueId: "EV021219",
    valueDesc: "Right-angle bulkhead coupling",
    label: "EV021219: Right-angle bulkhead coupling"
  },
  {
    valueId: "EV021220",
    valueDesc: "Straight bulkhead coupling",
    label: "EV021220: Straight bulkhead coupling"
  },
  {
    valueId: "EV021221",
    valueDesc: "Cutting ring coupling",
    label: "EV021221: Cutting ring coupling"
  },
  {
    valueId: "EV021222",
    valueDesc: "Two ridges",
    label: "EV021222: Two ridges"
  },
  {
    valueId: "EV021223",
    valueDesc: "Gripping edge",
    label: "EV021223: Gripping edge"
  },
  {
    valueId: "EV021224",
    valueDesc: "Flare coupling",
    label: "EV021224: Flare coupling"
  },
  {
    valueId: "EV021227",
    valueDesc: "VKS (male)",
    label: "EV021227: VKS (male)"
  },
  {
    valueId: "EV021228",
    valueDesc: "MKS (female)",
    label: "EV021228: MKS (female)"
  },
  {
    valueId: "EV021229",
    valueDesc: "Coach screw",
    label: "EV021229: Coach screw"
  },
  {
    valueId: "EV021230",
    valueDesc: "1/2 inch up to and incl. 3/4 inch",
    label: "EV021230: 1/2 inch up to and incl. 3/4 inch"
  },
  {
    valueId: "EV021231",
    valueDesc: "1.1/4 inch up to and incl. 2.1/2 inch",
    label: "EV021231: 1.1/4 inch up to and incl. 2.1/2 inch"
  },
  {
    valueId: "EV021232",
    valueDesc: "3 inch up to and incl. 4 inch",
    label: "EV021232: 3 inch up to and incl. 4 inch"
  },
  {
    valueId: "EV021233",
    valueDesc: "Slide tube",
    label: "EV021233: Slide tube"
  },
  {
    valueId: "EV021234",
    valueDesc: "Half-high",
    label: "EV021234: Half-high"
  },
  {
    valueId: "EV021235",
    valueDesc: "One button",
    label: "EV021235: One button"
  },
  {
    valueId: "EV021236",
    valueDesc: "Claw coupling",
    label: "EV021236: Claw coupling"
  },
  {
    valueId: "EV021237",
    valueDesc: "Double D",
    label: "EV021237: Double D"
  },
  {
    valueId: "EV021238",
    valueDesc: "Key way",
    label: "EV021238: Key way"
  },
  {
    valueId: "EV021239",
    valueDesc: "Gland nut and pipe",
    label: "EV021239: Gland nut and pipe"
  },
  {
    valueId: "EV021240",
    valueDesc: "Sleeve, high tensile strength",
    label: "EV021240: Sleeve, high tensile strength"
  },
  {
    valueId: "EV021241",
    valueDesc: "Zirconium oxide",
    label: "EV021241: Zirconium oxide"
  },
  {
    valueId: "EV021242",
    valueDesc: "Circular belt",
    label: "EV021242: Circular belt"
  },
  {
    valueId: "EV021243",
    valueDesc: "V-belt",
    label: "EV021243: V-belt"
  },
  {
    valueId: "EV021244",
    valueDesc: "Flat belt (round)",
    label: "EV021244: Flat belt (round)"
  },
  {
    valueId: "EV021245",
    valueDesc: "Flat belt (edge)",
    label: "EV021245: Flat belt (edge)"
  },
  {
    valueId: "EV021249",
    valueDesc: "M6 / M8",
    label: "EV021249: M6 / M8"
  },
  {
    valueId: "EV021250",
    valueDesc: "M8 / M12",
    label: "EV021250: M8 / M12"
  },
  {
    valueId: "EV021251",
    valueDesc: "Lip ring",
    label: "EV021251: Lip ring"
  },
  {
    valueId: "EV021252",
    valueDesc: "Clamping strip",
    label: "EV021252: Clamping strip"
  },
  {
    valueId: "EV021253",
    valueDesc: "9-pole male",
    label: "EV021253: 9-pole male"
  },
  {
    valueId: "EV021254",
    valueDesc: "9-pole female",
    label: "EV021254: 9-pole female"
  },
  {
    valueId: "EV021255",
    valueDesc: "25-pole male",
    label: "EV021255: 25-pole male"
  },
  {
    valueId: "EV021256",
    valueDesc: "25-pole female",
    label: "EV021256: 25-pole female"
  },
  {
    valueId: "EV021258",
    valueDesc: "Anti-splash jet nozzle",
    label: "EV021258: Anti-splash jet nozzle"
  },
  {
    valueId: "EV021259",
    valueDesc: "Foam jet nozzle",
    label: "EV021259: Foam jet nozzle"
  },
  {
    valueId: "EV021260",
    valueDesc: "Internal foam jet nozzle",
    label: "EV021260: Internal foam jet nozzle"
  },
  {
    valueId: "EV021263",
    valueDesc: "Polyester PVC coating",
    label: "EV021263: Polyester PVC coating"
  },
  {
    valueId: "EV021264",
    valueDesc: "PA PVC coating",
    label: "EV021264: PA PVC coating"
  },
  {
    valueId: "EV021265",
    valueDesc: "Monel",
    label: "EV021265: Monel"
  },
  {
    valueId: "EV021266",
    valueDesc: "Canvas NBR coating",
    label: "EV021266: Canvas NBR coating"
  },
  {
    valueId: "EV021267",
    valueDesc: "Steel PVC coating",
    label: "EV021267: Steel PVC coating"
  },
  {
    valueId: "EV021268",
    valueDesc: "0.2 - 1 bar",
    label: "EV021268: 0.2 - 1 bar"
  },
  {
    valueId: "EV021269",
    valueDesc: "Welded-on",
    label: "EV021269: Welded-on"
  },
  {
    valueId: "EV021270",
    valueDesc: "Adapter (male)",
    label: "EV021270: Adapter (male)"
  },
  {
    valueId: "EV021271",
    valueDesc: "PTFE/FPM",
    label: "EV021271: PTFE/FPM"
  },
  {
    valueId: "EV021272",
    valueDesc: "PTFE/NBR",
    label: "EV021272: PTFE/NBR"
  },
  {
    valueId: "EV021273",
    valueDesc: "PTFE/MVQ (Silicons)",
    label: "EV021273: PTFE/MVQ (Silicons)"
  },
  {
    valueId: "EV021274",
    valueDesc: "PTFE/EPDM",
    label: "EV021274: PTFE/EPDM"
  },
  {
    valueId: "EV021275",
    valueDesc: "Plug (male)",
    label: "EV021275: Plug (male)"
  },
  {
    valueId: "EV021276",
    valueDesc: "Cap (female)",
    label: "EV021276: Cap (female)"
  },
  {
    valueId: "EV021277",
    valueDesc: "3 inch up to and incl. 5 inch",
    label: "EV021277: 3 inch up to and incl. 5 inch"
  },
  {
    valueId: "EV021278",
    valueDesc: "2 1/4 inch",
    label: "EV021278: 2 1/4 inch"
  },
  {
    valueId: "EV021279",
    valueDesc: "DN 165",
    label: "EV021279: DN 165"
  },
  {
    valueId: "EV021280",
    valueDesc: "DN 205",
    label: "EV021280: DN 205"
  },
  {
    valueId: "EV021281",
    valueDesc: "DN 38",
    label: "EV021281: DN 38"
  },
  {
    valueId: "EV021282",
    valueDesc: "DN 45",
    label: "EV021282: DN 45"
  },
  {
    valueId: "EV021283",
    valueDesc: "DN 135",
    label: "EV021283: DN 135"
  },
  {
    valueId: "EV021284",
    valueDesc: "DN 76",
    label: "EV021284: DN 76"
  },
  {
    valueId: "EV021285",
    valueDesc: "DN 89",
    label: "EV021285: DN 89"
  },
  {
    valueId: "EV021286",
    valueDesc: "DN 102",
    label: "EV021286: DN 102"
  },
  {
    valueId: "EV021287",
    valueDesc: "DN 108",
    label: "EV021287: DN 108"
  },
  {
    valueId: "EV021288",
    valueDesc: "DN 133",
    label: "EV021288: DN 133"
  },
  {
    valueId: "EV021289",
    valueDesc: "DN 159",
    label: "EV021289: DN 159"
  },
  {
    valueId: "EV021290",
    valueDesc: "DN 194",
    label: "EV021290: DN 194"
  },
  {
    valueId: "EV021291",
    valueDesc: "Ball with clamp ring",
    label: "EV021291: Ball with clamp ring"
  },
  {
    valueId: "EV021292",
    valueDesc: "Ball without clamp ring",
    label: "EV021292: Ball without clamp ring"
  },
  {
    valueId: "EV021293",
    valueDesc: "Cup with clamp ring",
    label: "EV021293: Cup with clamp ring"
  },
  {
    valueId: "EV021294",
    valueDesc: "Cup without clamp ring",
    label: "EV021294: Cup without clamp ring"
  },
  {
    valueId: "EV021295",
    valueDesc: "JIS",
    label: "EV021295: JIS"
  },
  {
    valueId: "EV021296",
    valueDesc: "TTMA",
    label: "EV021296: TTMA"
  },
  {
    valueId: "EV021297",
    valueDesc: "DIN 2817",
    label: "EV021297: DIN 2817"
  },
  {
    valueId: "EV021298",
    valueDesc: "DIN 2826",
    label: "EV021298: DIN 2826"
  },
  {
    valueId: "EV021299",
    valueDesc: "44 mm",
    label: "EV021299: 44 mm"
  },
  {
    valueId: "EV021300",
    valueDesc: "51 mm",
    label: "EV021300: 51 mm"
  },
  {
    valueId: "EV021301",
    valueDesc: "59 mm",
    label: "EV021301: 59 mm"
  },
  {
    valueId: "EV021302",
    valueDesc: "66 mm",
    label: "EV021302: 66 mm"
  },
  {
    valueId: "EV021303",
    valueDesc: "81 mm",
    label: "EV021303: 81 mm"
  },
  {
    valueId: "EV021304",
    valueDesc: "89 mm",
    label: "EV021304: 89 mm"
  },
  {
    valueId: "EV021305",
    valueDesc: "105 mm",
    label: "EV021305: 105 mm"
  },
  {
    valueId: "EV021306",
    valueDesc: "115 mm",
    label: "EV021306: 115 mm"
  },
  {
    valueId: "EV021307",
    valueDesc: "133 mm",
    label: "EV021307: 133 mm"
  },
  {
    valueId: "EV021308",
    valueDesc: "148 mm",
    label: "EV021308: 148 mm"
  },
  {
    valueId: "EV021309",
    valueDesc: "159 mm",
    label: "EV021309: 159 mm"
  },
  {
    valueId: "EV021310",
    valueDesc: "188 mm",
    label: "EV021310: 188 mm"
  },
  {
    valueId: "EV021311",
    valueDesc: "220 mm",
    label: "EV021311: 220 mm"
  },
  {
    valueId: "EV021312",
    valueDesc: "278 mm",
    label: "EV021312: 278 mm"
  },
  {
    valueId: "EV021313",
    valueDesc: "41 mm",
    label: "EV021313: 41 mm"
  },
  {
    valueId: "EV021314",
    valueDesc: "42 mm",
    label: "EV021314: 42 mm"
  },
  {
    valueId: "EV021315",
    valueDesc: "Plug (male)",
    label: "EV021315: Plug (male)"
  },
  {
    valueId: "EV021316",
    valueDesc: "Coupling (female)",
    label: "EV021316: Coupling (female)"
  },
  {
    valueId: "EV021317",
    valueDesc: "Quarter round",
    label: "EV021317: Quarter round"
  },
  {
    valueId: "EV021318",
    valueDesc: "Foot wall pedal",
    label: "EV021318: Foot wall pedal"
  },
  {
    valueId: "EV021319",
    valueDesc: "Foot floor pedal",
    label: "EV021319: Foot floor pedal"
  },
  {
    valueId: "EV021320",
    valueDesc: "1 button manual operation",
    label: "EV021320: 1 button manual operation"
  },
  {
    valueId: "EV021321",
    valueDesc: "2 button manual operation",
    label: "EV021321: 2 button manual operation"
  },
  {
    valueId: "EV021322",
    valueDesc: "DN 75",
    label: "EV021322: DN 75"
  },
  {
    valueId: "EV021323",
    valueDesc: "DN 90",
    label: "EV021323: DN 90"
  },
  {
    valueId: "EV021324",
    valueDesc: "DN 110",
    label: "EV021324: DN 110"
  },
  {
    valueId: "EV021325",
    valueDesc: "Flat ring",
    label: "EV021325: Flat ring"
  },
  {
    valueId: "EV021326",
    valueDesc: "34 mm",
    label: "EV021326: 34 mm"
  },
  {
    valueId: "EV021327",
    valueDesc: "50.5 mm",
    label: "EV021327: 50.5 mm"
  },
  {
    valueId: "EV021328",
    valueDesc: "64 mm",
    label: "EV021328: 64 mm"
  },
  {
    valueId: "EV021329",
    valueDesc: "77.5 mm",
    label: "EV021329: 77.5 mm"
  },
  {
    valueId: "EV021330",
    valueDesc: "91 mm",
    label: "EV021330: 91 mm"
  },
  {
    valueId: "EV021331",
    valueDesc: "106 mm",
    label: "EV021331: 106 mm"
  },
  {
    valueId: "EV021332",
    valueDesc: "119 mm",
    label: "EV021332: 119 mm"
  },
  {
    valueId: "EV021333",
    valueDesc: "155 mm",
    label: "EV021333: 155 mm"
  },
  {
    valueId: "EV021334",
    valueDesc: "183 mm",
    label: "EV021334: 183 mm"
  },
  {
    valueId: "EV021335",
    valueDesc: "18 cm",
    label: "EV021335: 18 cm"
  },
  {
    valueId: "EV021336",
    valueDesc: "23 cm",
    label: "EV021336: 23 cm"
  },
  {
    valueId: "EV021337",
    valueDesc: "18 cm and 23 cm",
    label: "EV021337: 18 cm and 23 cm"
  },
  {
    valueId: "EV021340",
    valueDesc: "Side angle left",
    label: "EV021340: Side angle left"
  },
  {
    valueId: "EV021341",
    valueDesc: "Side angle right",
    label: "EV021341: Side angle right"
  },
  {
    valueId: "EV021342",
    valueDesc: "Side angle left and right",
    label: "EV021342: Side angle left and right"
  },
  {
    valueId: "EV021343",
    valueDesc: "Blind plate/service hatch",
    label: "EV021343: Blind plate/service hatch"
  },
  {
    valueId: "EV021346",
    valueDesc: "600x600 mm",
    label: "EV021346: 600x600 mm"
  },
  {
    valueId: "EV021347",
    valueDesc: "600x300 mm",
    label: "EV021347: 600x300 mm"
  },
  {
    valueId: "EV021348",
    valueDesc: "1200x300 mm",
    label: "EV021348: 1200x300 mm"
  },
  {
    valueId: "EV021349",
    valueDesc: "All patterns",
    label: "EV021349: All patterns"
  },
  {
    valueId: "EV021350",
    valueDesc: "W-shaped",
    label: "EV021350: W-shaped"
  },
  {
    valueId: "EV021351",
    valueDesc: "Flat panel",
    label: "EV021351: Flat panel"
  },
  {
    valueId: "EV021352",
    valueDesc: "Visible screw",
    label: "EV021352: Visible screw"
  },
  {
    valueId: "EV021353",
    valueDesc: "Hidden screw",
    label: "EV021353: Hidden screw"
  },
  {
    valueId: "EV021354",
    valueDesc: "Hidden clamp",
    label: "EV021354: Hidden clamp"
  },
  {
    valueId: "EV021355",
    valueDesc: "Wall hand button",
    label: "EV021355: Wall hand button"
  },
  {
    valueId: "EV021358",
    valueDesc: "Plaster reinforced with fibres",
    label: "EV021358: Plaster reinforced with fibres"
  },
  {
    valueId: "EV021359",
    valueDesc: "Forged steel",
    label: "EV021359: Forged steel"
  },
  {
    valueId: "EV021360",
    valueDesc: "Cast iron GGG 40 (GJS-400-15)",
    label: "EV021360: Cast iron GGG 40 (GJS-400-15)"
  },
  {
    valueId: "EV021361",
    valueDesc: "Blasting and welding primer",
    label: "EV021361: Blasting and welding primer"
  },
  {
    valueId: "EV021362",
    valueDesc: "Welding primer",
    label: "EV021362: Welding primer"
  },
  {
    valueId: "EV021364",
    valueDesc: "SN1",
    label: "EV021364: SN1"
  },
  {
    valueId: "EV021365",
    valueDesc: "SN2",
    label: "EV021365: SN2"
  },
  {
    valueId: "EV021366",
    valueDesc: "SN4",
    label: "EV021366: SN4"
  },
  {
    valueId: "EV021367",
    valueDesc: "SN8",
    label: "EV021367: SN8"
  },
  {
    valueId: "EV021368",
    valueDesc: "One-sided sleeve",
    label: "EV021368: One-sided sleeve"
  },
  {
    valueId: "EV021372",
    valueDesc: "Cold-manufactured",
    label: "EV021372: Cold-manufactured"
  },
  {
    valueId: "EV021373",
    valueDesc: "Hot-manufactured",
    label: "EV021373: Hot-manufactured"
  },
  {
    valueId: "EV021374",
    valueDesc: "Scaffolding pipe",
    label: "EV021374: Scaffolding pipe"
  },
  {
    valueId: "EV021375",
    valueDesc: "Plastic with wood core",
    label: "EV021375: Plastic with wood core"
  },
  {
    valueId: "EV021376",
    valueDesc: "Solid wood",
    label: "EV021376: Solid wood"
  },
  {
    valueId: "EV021377",
    valueDesc: "Layered wood",
    label: "EV021377: Layered wood"
  },
  {
    valueId: "EV021379",
    valueDesc: "Cherry",
    label: "EV021379: Cherry"
  },
  {
    valueId: "EV021380",
    valueDesc: "Mahogany",
    label: "EV021380: Mahogany"
  },
  {
    valueId: "EV021381",
    valueDesc: "Alder",
    label: "EV021381: Alder"
  },
  {
    valueId: "EV021382",
    valueDesc: "Ash",
    label: "EV021382: Ash"
  },
  {
    valueId: "EV021383",
    valueDesc: "Birch",
    label: "EV021383: Birch"
  },
  {
    valueId: "EV021384",
    valueDesc: "Wengé",
    label: "EV021384: Wengé"
  },
  {
    valueId: "EV021385",
    valueDesc: "St 37.2 (1.0037)",
    label: "EV021385: St 37.2 (1.0037)"
  },
  {
    valueId: "EV021386",
    valueDesc: "St 44.2 (1.0044)",
    label: "EV021386: St 44.2 (1.0044)"
  },
  {
    valueId: "EV021387",
    valueDesc: "St 44.3 annealed (1.0144)",
    label: "EV021387: St 44.3 annealed (1.0144)"
  },
  {
    valueId: "EV021388",
    valueDesc: "St 44.3 unannealed (1.0431)",
    label: "EV021388: St 44.3 unannealed (1.0431)"
  },
  {
    valueId: "EV021389",
    valueDesc: "St 52.3 annealed (1.0570)",
    label: "EV021389: St 52.3 annealed (1.0570)"
  },
  {
    valueId: "EV021390",
    valueDesc: "St 52.3 unannealed (1.0553)",
    label: "EV021390: St 52.3 unannealed (1.0553)"
  },
  {
    valueId: "EV021391",
    valueDesc: "Coconut fibre",
    label: "EV021391: Coconut fibre"
  },
  {
    valueId: "EV021392",
    valueDesc: "Infiltration pipe",
    label: "EV021392: Infiltration pipe"
  },
  {
    valueId: "EV021393",
    valueDesc: "Drainage pipe",
    label: "EV021393: Drainage pipe"
  },
  {
    valueId: "EV021394",
    valueDesc: "Drainage/infiltration pipe",
    label: "EV021394: Drainage/infiltration pipe"
  },
  {
    valueId: "EV021395",
    valueDesc: "Triple-eccentric",
    label: "EV021395: Triple-eccentric"
  },
  {
    valueId: "EV021396",
    valueDesc: "Butterfly screw valve",
    label: "EV021396: Butterfly screw valve"
  },
  {
    valueId: "EV021397",
    valueDesc: "Ball valves",
    label: "EV021397: Ball valves"
  },
  {
    valueId: "EV021398",
    valueDesc: "Membrane valve",
    label: "EV021398: Membrane valve"
  },
  {
    valueId: "EV021399",
    valueDesc: "Ust 37.2 (1.0036)",
    label: "EV021399: Ust 37.2 (1.0036)"
  },
  {
    valueId: "EV021400",
    valueDesc: "Rst 37.2 (1.0038)",
    label: "EV021400: Rst 37.2 (1.0038)"
  },
  {
    valueId: "EV021401",
    valueDesc: "Rst 37.3 U (1.0114)",
    label: "EV021401: Rst 37.3 U (1.0114)"
  },
  {
    valueId: "EV021402",
    valueDesc: "Rst 37.3 N (1.0116)",
    label: "EV021402: Rst 37.3 N (1.0116)"
  },
  {
    valueId: "EV021403",
    valueDesc: "TT St 35 N (1.0356)",
    label: "EV021403: TT St 35 N (1.0356)"
  },
  {
    valueId: "EV021404",
    valueDesc: "St 35.8 (1.0305)",
    label: "EV021404: St 35.8 (1.0305)"
  },
  {
    valueId: "EV021406",
    valueDesc: "Class A pipe",
    label: "EV021406: Class A pipe"
  },
  {
    valueId: "EV021407",
    valueDesc: "Hydraulic pipe",
    label: "EV021407: Hydraulic pipe"
  },
  {
    valueId: "EV021409",
    valueDesc: "Flap valve",
    label: "EV021409: Flap valve"
  },
  {
    valueId: "EV021410",
    valueDesc: "24 V AC",
    label: "EV021410: 24 V AC"
  },
  {
    valueId: "EV021411",
    valueDesc: "24 V DC",
    label: "EV021411: 24 V DC"
  },
  {
    valueId: "EV021412",
    valueDesc: "230 V AC",
    label: "EV021412: 230 V AC"
  },
  {
    valueId: "EV021413",
    valueDesc: "400 V AC",
    label: "EV021413: 400 V AC"
  },
  {
    valueId: "EV021414",
    valueDesc: "0 - 5 V",
    label: "EV021414: 0 - 5 V"
  },
  {
    valueId: "EV021415",
    valueDesc: "Painted",
    label: "EV021415: Painted"
  },
  {
    valueId: "EV021417",
    valueDesc: "Delivery flange",
    label: "EV021417: Delivery flange"
  },
  {
    valueId: "EV021418",
    valueDesc: "Clamping flange",
    label: "EV021418: Clamping flange"
  },
  {
    valueId: "EV021419",
    valueDesc: "Steel C 22.8 (1.0460)",
    label: "EV021419: Steel C 22.8 (1.0460)"
  },
  {
    valueId: "EV021420",
    valueDesc: "Steel C 22.3 (1.0427)",
    label: "EV021420: Steel C 22.3 (1.0427)"
  },
  {
    valueId: "EV021421",
    valueDesc: "Rst 37.2 (1.0114)",
    label: "EV021421: Rst 37.2 (1.0114)"
  },
  {
    valueId: "EV021422",
    valueDesc: "Sliding cap",
    label: "EV021422: Sliding cap"
  },
  {
    valueId: "EV021425",
    valueDesc: "Pins inserted in lid",
    label: "EV021425: Pins inserted in lid"
  },
  {
    valueId: "EV021426",
    valueDesc: "Sycamore",
    label: "EV021426: Sycamore"
  },
  {
    valueId: "EV021429",
    valueDesc: "With cogwheels",
    label: "EV021429: With cogwheels"
  },
  {
    valueId: "EV021430",
    valueDesc: "Two-point",
    label: "EV021430: Two-point"
  },
  {
    valueId: "EV021431",
    valueDesc: "PN 10 / DN 65",
    label: "EV021431: PN 10 / DN 65"
  },
  {
    valueId: "EV021432",
    valueDesc: "DN 52",
    label: "EV021432: DN 52"
  },
  {
    valueId: "EV021433",
    valueDesc: "Variable volume",
    label: "EV021433: Variable volume"
  },
  {
    valueId: "EV021434",
    valueDesc: "Constant volume",
    label: "EV021434: Constant volume"
  },
  {
    valueId: "EV021435",
    valueDesc: "Variable volume induction",
    label: "EV021435: Variable volume induction"
  },
  {
    valueId: "EV021437",
    valueDesc: "Pressure controls",
    label: "EV021437: Pressure controls"
  },
  {
    valueId: "EV021438",
    valueDesc: "Vacuum tube",
    label: "EV021438: Vacuum tube"
  },
  {
    valueId: "EV021439",
    valueDesc: "0 - 0.6 bar",
    label: "EV021439: 0 - 0.6 bar"
  },
  {
    valueId: "EV021440",
    valueDesc: "0 - 1.6 bar",
    label: "EV021440: 0 - 1.6 bar"
  },
  {
    valueId: "EV021441",
    valueDesc: "0 - 2.5 bar",
    label: "EV021441: 0 - 2.5 bar"
  },
  {
    valueId: "EV021442",
    valueDesc: "0 - 4 bar",
    label: "EV021442: 0 - 4 bar"
  },
  {
    valueId: "EV021443",
    valueDesc: "0 - 6 bar",
    label: "EV021443: 0 - 6 bar"
  },
  {
    valueId: "EV021444",
    valueDesc: "0 - 10 bar",
    label: "EV021444: 0 - 10 bar"
  },
  {
    valueId: "EV021445",
    valueDesc: "0 - 16 bar",
    label: "EV021445: 0 - 16 bar"
  },
  {
    valueId: "EV021446",
    valueDesc: "0 - 25 bar",
    label: "EV021446: 0 - 25 bar"
  },
  {
    valueId: "EV021447",
    valueDesc: "0 - 40 bar",
    label: "EV021447: 0 - 40 bar"
  },
  {
    valueId: "EV021448",
    valueDesc: "0 - 60 bar",
    label: "EV021448: 0 - 60 bar"
  },
  {
    valueId: "EV021449",
    valueDesc: "0 - 100 bar",
    label: "EV021449: 0 - 100 bar"
  },
  {
    valueId: "EV021450",
    valueDesc: "0 - 160 bar",
    label: "EV021450: 0 - 160 bar"
  },
  {
    valueId: "EV021451",
    valueDesc: "0 - 250 bar",
    label: "EV021451: 0 - 250 bar"
  },
  {
    valueId: "EV021452",
    valueDesc: "0 - 400 bar",
    label: "EV021452: 0 - 400 bar"
  },
  {
    valueId: "EV021453",
    valueDesc: "0 - 600 bar",
    label: "EV021453: 0 - 600 bar"
  },
  {
    valueId: "EV021454",
    valueDesc: "0 - 1000 bar",
    label: "EV021454: 0 - 1000 bar"
  },
  {
    valueId: "EV021455",
    valueDesc: "0 - 1600 bar",
    label: "EV021455: 0 - 1600 bar"
  },
  {
    valueId: "EV021456",
    valueDesc: "-0.6 - 0 bar",
    label: "EV021456: -0.6 - 0 bar"
  },
  {
    valueId: "EV021457",
    valueDesc: "-1 - 0.6 bar",
    label: "EV021457: -1 - 0.6 bar"
  },
  {
    valueId: "EV021458",
    valueDesc: "-1 - 1.5 bar",
    label: "EV021458: -1 - 1.5 bar"
  },
  {
    valueId: "EV021459",
    valueDesc: "-1 - 3 bar",
    label: "EV021459: -1 - 3 bar"
  },
  {
    valueId: "EV021460",
    valueDesc: "-1 - 5 bar",
    label: "EV021460: -1 - 5 bar"
  },
  {
    valueId: "EV021461",
    valueDesc: "-1 - 9 bar",
    label: "EV021461: -1 - 9 bar"
  },
  {
    valueId: "EV021462",
    valueDesc: "-1 - 15 bar",
    label: "EV021462: -1 - 15 bar"
  },
  {
    valueId: "EV021463",
    valueDesc: "-1 - 24 bar",
    label: "EV021463: -1 - 24 bar"
  },
  {
    valueId: "EV021464",
    valueDesc: "-1 - 0 bar",
    label: "EV021464: -1 - 0 bar"
  },
  {
    valueId: "EV021465",
    valueDesc: "0.1 %",
    label: "EV021465: 0.1 %"
  },
  {
    valueId: "EV021466",
    valueDesc: "0.25 %",
    label: "EV021466: 0.25 %"
  },
  {
    valueId: "EV021467",
    valueDesc: "0.6 %",
    label: "EV021467: 0.6 %"
  },
  {
    valueId: "EV021468",
    valueDesc: "1 %",
    label: "EV021468: 1 %"
  },
  {
    valueId: "EV021469",
    valueDesc: "1.6 %",
    label: "EV021469: 1.6 %"
  },
  {
    valueId: "EV021470",
    valueDesc: "2.5 %",
    label: "EV021470: 2.5 %"
  },
  {
    valueId: "EV021471",
    valueDesc: "Built-in bracket",
    label: "EV021471: Built-in bracket"
  },
  {
    valueId: "EV021472",
    valueDesc: "Roller head",
    label: "EV021472: Roller head"
  },
  {
    valueId: "EV021473",
    valueDesc: "V-head",
    label: "EV021473: V-head"
  },
  {
    valueId: "EV021474",
    valueDesc: "Air",
    label: "EV021474: Air"
  },
  {
    valueId: "EV021475",
    valueDesc: "Bulb type",
    label: "EV021475: Bulb type"
  },
  {
    valueId: "EV021481",
    valueDesc: "0 - 120 °C",
    label: "EV021481: 0 - 120 °C"
  },
  {
    valueId: "EV021491",
    valueDesc: "0 - 16 mbar",
    label: "EV021491: 0 - 16 mbar"
  },
  {
    valueId: "EV021492",
    valueDesc: "0 - 25 mbar",
    label: "EV021492: 0 - 25 mbar"
  },
  {
    valueId: "EV021493",
    valueDesc: "0 - 40 mbar",
    label: "EV021493: 0 - 40 mbar"
  },
  {
    valueId: "EV021494",
    valueDesc: "0 - 100 mbar",
    label: "EV021494: 0 - 100 mbar"
  },
  {
    valueId: "EV021495",
    valueDesc: "0 - 160 mbar",
    label: "EV021495: 0 - 160 mbar"
  },
  {
    valueId: "EV021496",
    valueDesc: "0 - 250 mbar",
    label: "EV021496: 0 - 250 mbar"
  },
  {
    valueId: "EV021497",
    valueDesc: "0 - 400 mbar",
    label: "EV021497: 0 - 400 mbar"
  },
  {
    valueId: "EV021498",
    valueDesc: "0 - 60 mbar",
    label: "EV021498: 0 - 60 mbar"
  },
  {
    valueId: "EV021499",
    valueDesc: "0 - 6 mbar",
    label: "EV021499: 0 - 6 mbar"
  },
  {
    valueId: "EV021500",
    valueDesc: "0 - 10 mbar",
    label: "EV021500: 0 - 10 mbar"
  },
  {
    valueId: "EV021501",
    valueDesc: "-400 - 0 mbar",
    label: "EV021501: -400 - 0 mbar"
  },
  {
    valueId: "EV021502",
    valueDesc: "-250 - 0 mbar",
    label: "EV021502: -250 - 0 mbar"
  },
  {
    valueId: "EV021503",
    valueDesc: "-100 - 0 mbar",
    label: "EV021503: -100 - 0 mbar"
  },
  {
    valueId: "EV021504",
    valueDesc: "-60 - 0 mbar",
    label: "EV021504: -60 - 0 mbar"
  },
  {
    valueId: "EV021505",
    valueDesc: "-40 - 0 mbar",
    label: "EV021505: -40 - 0 mbar"
  },
  {
    valueId: "EV021506",
    valueDesc: "-25 - 0 mbar",
    label: "EV021506: -25 - 0 mbar"
  },
  {
    valueId: "EV021507",
    valueDesc: "-16 - 0 mbar",
    label: "EV021507: -16 - 0 mbar"
  },
  {
    valueId: "EV021508",
    valueDesc: "-10 - 0 mbar",
    label: "EV021508: -10 - 0 mbar"
  },
  {
    valueId: "EV021509",
    valueDesc: "-6 - 0 mbar",
    label: "EV021509: -6 - 0 mbar"
  },
  {
    valueId: "EV021510",
    valueDesc: "0 - 2500 bar",
    label: "EV021510: 0 - 2500 bar"
  },
  {
    valueId: "EV021511",
    valueDesc: "0 - 7000 bar",
    label: "EV021511: 0 - 7000 bar"
  },
  {
    valueId: "EV021512",
    valueDesc: "Small model (100 mm)",
    label: "EV021512: Small model (100 mm)"
  },
  {
    valueId: "EV021513",
    valueDesc: "Medium-sized model (150 mm)",
    label: "EV021513: Medium-sized model (150 mm)"
  },
  {
    valueId: "EV021514",
    valueDesc: "Large model (200 mm)",
    label: "EV021514: Large model (200 mm)"
  },
  {
    valueId: "EV021515",
    valueDesc: "Resistance sensor",
    label: "EV021515: Resistance sensor"
  },
  {
    valueId: "EV021516",
    valueDesc: "Plastic element",
    label: "EV021516: Plastic element"
  },
  {
    valueId: "EV021517",
    valueDesc: "Vane",
    label: "EV021517: Vane"
  },
  {
    valueId: "EV021518",
    valueDesc: "Bellows",
    label: "EV021518: Bellows"
  },
  {
    valueId: "EV021519",
    valueDesc: "Extension pin",
    label: "EV021519: Extension pin"
  },
  {
    valueId: "EV021520",
    valueDesc: "Al 99.5 (3.0255)",
    label: "EV021520: Al 99.5 (3.0255)"
  },
  {
    valueId: "EV021521",
    valueDesc: "Seamless",
    label: "EV021521: Seamless"
  },
  {
    valueId: "EV021522",
    valueDesc: "Shrink end",
    label: "EV021522: Shrink end"
  },
  {
    valueId: "EV021523",
    valueDesc: "AlMgSi 0.5 (3.3206)",
    label: "EV021523: AlMgSi 0.5 (3.3206)"
  },
  {
    valueId: "EV021524",
    valueDesc: "Blue-annealed",
    label: "EV021524: Blue-annealed"
  },
  {
    valueId: "EV021525",
    valueDesc: "Internally coated",
    label: "EV021525: Internally coated"
  },
  {
    valueId: "EV021526",
    valueDesc: "Externally coated",
    label: "EV021526: Externally coated"
  },
  {
    valueId: "EV021527",
    valueDesc: "Internally and externally coated",
    label: "EV021527: Internally and externally coated"
  },
  {
    valueId: "EV021528",
    valueDesc: "Air cavity",
    label: "EV021528: Air cavity"
  },
  {
    valueId: "EV021529",
    valueDesc: "Overlap",
    label: "EV021529: Overlap"
  },
  {
    valueId: "EV021530",
    valueDesc: "60/100 mm",
    label: "EV021530: 60/100 mm"
  },
  {
    valueId: "EV021531",
    valueDesc: "70/110 mm",
    label: "EV021531: 70/110 mm"
  },
  {
    valueId: "EV021532",
    valueDesc: "80/110 mm",
    label: "EV021532: 80/110 mm"
  },
  {
    valueId: "EV021533",
    valueDesc: "100/150 mm",
    label: "EV021533: 100/150 mm"
  },
  {
    valueId: "EV021534",
    valueDesc: "130/180 mm",
    label: "EV021534: 130/180 mm"
  },
  {
    valueId: "EV021535",
    valueDesc: "80/125 mm",
    label: "EV021535: 80/125 mm"
  },
  {
    valueId: "EV021536",
    valueDesc: "Al 99.0 (3.0205)",
    label: "EV021536: Al 99.0 (3.0205)"
  },
  {
    valueId: "EV021537",
    valueDesc: "PP, not readily flammable",
    label: "EV021537: PP, not readily flammable"
  },
  {
    valueId: "EV021538",
    valueDesc: "Aluzinc",
    label: "EV021538: Aluzinc"
  },
  {
    valueId: "EV021539",
    valueDesc: "Collar",
    label: "EV021539: Collar"
  },
  {
    valueId: "EV021540",
    valueDesc: "130/200 mm",
    label: "EV021540: 130/200 mm"
  },
  {
    valueId: "EV021541",
    valueDesc: "150/200 mm",
    label: "EV021541: 150/200 mm"
  },
  {
    valueId: "EV021542",
    valueDesc: "Hinged flaps",
    label: "EV021542: Hinged flaps"
  },
  {
    valueId: "EV021543",
    valueDesc: "Hidden hinged flaps",
    label: "EV021543: Hidden hinged flaps"
  },
  {
    valueId: "EV021544",
    valueDesc: "Inspection piece, straight",
    label: "EV021544: Inspection piece, straight"
  },
  {
    valueId: "EV021545",
    valueDesc: "Inspection piece, square",
    label: "EV021545: Inspection piece, square"
  },
  {
    valueId: "EV021546",
    valueDesc: "Inspection piece, bend",
    label: "EV021546: Inspection piece, bend"
  },
  {
    valueId: "EV021547",
    valueDesc: "Draught limiting device",
    label: "EV021547: Draught limiting device"
  },
  {
    valueId: "EV021548",
    valueDesc: "Condensate drain segment",
    label: "EV021548: Condensate drain segment"
  },
  {
    valueId: "EV021549",
    valueDesc: "Sliding piece segment",
    label: "EV021549: Sliding piece segment"
  },
  {
    valueId: "EV021550",
    valueDesc: "87°",
    label: "EV021550: 87°"
  },
  {
    valueId: "EV021551",
    valueDesc: "42 V DC",
    label: "EV021551: 42 V DC"
  },
  {
    valueId: "EV021552",
    valueDesc: "Equal percentage",
    label: "EV021552: Equal percentage"
  },
  {
    valueId: "EV021553",
    valueDesc: "Deactivated when open",
    label: "EV021553: Deactivated when open"
  },
  {
    valueId: "EV021554",
    valueDesc: "Deactivated when closed",
    label: "EV021554: Deactivated when closed"
  },
  {
    valueId: "EV021555",
    valueDesc: "0.2 - 0.6 bar",
    label: "EV021555: 0.2 - 0.6 bar"
  },
  {
    valueId: "EV021556",
    valueDesc: "0.6 - 1 bar",
    label: "EV021556: 0.6 - 1 bar"
  },
  {
    valueId: "EV021557",
    valueDesc: "3 - 9 psi",
    label: "EV021557: 3 - 9 psi"
  },
  {
    valueId: "EV021558",
    valueDesc: "9 - 15 psi",
    label: "EV021558: 9 - 15 psi"
  },
  {
    valueId: "EV021559",
    valueDesc: "42 V AC",
    label: "EV021559: 42 V AC"
  },
  {
    valueId: "EV021560",
    valueDesc: "2-10 V",
    label: "EV021560: 2-10 V"
  },
  {
    valueId: "EV021561",
    valueDesc: "Complementary",
    label: "EV021561: Complementary"
  },
  {
    valueId: "EV021562",
    valueDesc: "3 - 15 psi",
    label: "EV021562: 3 - 15 psi"
  },
  {
    valueId: "EV021563",
    valueDesc: "Single-walled",
    label: "EV021563: Single-walled"
  },
  {
    valueId: "EV021564",
    valueDesc: "Concentric",
    label: "EV021564: Concentric"
  },
  {
    valueId: "EV021565",
    valueDesc: "Double-walled",
    label: "EV021565: Double-walled"
  },
  {
    valueId: "EV021566",
    valueDesc: "60/60 mm",
    label: "EV021566: 60/60 mm"
  },
  {
    valueId: "EV021567",
    valueDesc: "80/80 mm",
    label: "EV021567: 80/80 mm"
  },
  {
    valueId: "EV021569",
    valueDesc: "130/130 mm",
    label: "EV021569: 130/130 mm"
  },
  {
    valueId: "EV021570",
    valueDesc: "150/150 mm",
    label: "EV021570: 150/150 mm"
  },
  {
    valueId: "EV021571",
    valueDesc: "Diagonal",
    label: "EV021571: Diagonal"
  },
  {
    valueId: "EV021572",
    valueDesc: "Seamless weld (butt)",
    label: "EV021572: Seamless weld (butt)"
  },
  {
    valueId: "EV021573",
    valueDesc: "Longitudinal weld (overlap)",
    label: "EV021573: Longitudinal weld (overlap)"
  },
  {
    valueId: "EV021574",
    valueDesc: "Open with overlap",
    label: "EV021574: Open with overlap"
  },
  {
    valueId: "EV021575",
    valueDesc: "Cap/lid",
    label: "EV021575: Cap/lid"
  },
  {
    valueId: "EV021577",
    valueDesc: "Wall chair",
    label: "EV021577: Wall chair"
  },
  {
    valueId: "EV021578",
    valueDesc: "Top plate",
    label: "EV021578: Top plate"
  },
  {
    valueId: "EV021580",
    valueDesc: "Round air duct",
    label: "EV021580: Round air duct"
  },
  {
    valueId: "EV021581",
    valueDesc: "Rectangular air duct",
    label: "EV021581: Rectangular air duct"
  },
  {
    valueId: "EV021582",
    valueDesc: "CR/SBR",
    label: "EV021582: CR/SBR"
  },
  {
    valueId: "EV021583",
    valueDesc: "PP/EPDM",
    label: "EV021583: PP/EPDM"
  },
  {
    valueId: "EV021584",
    valueDesc: "Polyester/CSM",
    label: "EV021584: Polyester/CSM"
  },
  {
    valueId: "EV021585",
    valueDesc: "PTFE/glass fibre",
    label: "EV021585: PTFE/glass fibre"
  },
  {
    valueId: "EV021586",
    valueDesc: "110 V AC",
    label: "EV021586: 110 V AC"
  },
  {
    valueId: "EV021587",
    valueDesc: "0 - 22 mbar",
    label: "EV021587: 0 - 22 mbar"
  },
  {
    valueId: "EV021588",
    valueDesc: "15 - 120 mbar",
    label: "EV021588: 15 - 120 mbar"
  },
  {
    valueId: "EV021589",
    valueDesc: "100 - 250 mbar",
    label: "EV021589: 100 - 250 mbar"
  },
  {
    valueId: "EV021590",
    valueDesc: "AlMg3 (3.3503)",
    label: "EV021590: AlMg3 (3.3503)"
  },
  {
    valueId: "EV021591",
    valueDesc: "Glass fibre cloth",
    label: "EV021591: Glass fibre cloth"
  },
  {
    valueId: "EV021592",
    valueDesc: "Vapour inhibition layer",
    label: "EV021592: Vapour inhibition layer"
  },
  {
    valueId: "EV021593",
    valueDesc: "50 %",
    label: "EV021593: 50 %"
  },
  {
    valueId: "EV021594",
    valueDesc: "SMS internal thread",
    label: "EV021594: SMS internal thread"
  },
  {
    valueId: "EV021595",
    valueDesc: "SMS external thread",
    label: "EV021595: SMS external thread"
  },
  {
    valueId: "EV021596",
    valueDesc: "Expanded metal",
    label: "EV021596: Expanded metal"
  },
  {
    valueId: "EV021597",
    valueDesc: "Gutter model",
    label: "EV021597: Gutter model"
  },
  {
    valueId: "EV021598",
    valueDesc: "DIN 24154-R2",
    label: "EV021598: DIN 24154-R2"
  },
  {
    valueId: "EV021599",
    valueDesc: "MP bush",
    label: "EV021599: MP bush"
  },
  {
    valueId: "EV021600",
    valueDesc: "Oval air duct",
    label: "EV021600: Oval air duct"
  },
  {
    valueId: "EV021601",
    valueDesc: "15°",
    label: "EV021601: 15°"
  },
  {
    valueId: "EV021602",
    valueDesc: "DIN 2673/PN 10",
    label: "EV021602: DIN 2673/PN 10"
  },
  {
    valueId: "EV021603",
    valueDesc: "DIN 2676/PN 40",
    label: "EV021603: DIN 2676/PN 40"
  },
  {
    valueId: "EV021604",
    valueDesc: "Separate coils",
    label: "EV021604: Separate coils"
  },
  {
    valueId: "EV021605",
    valueDesc: "Dahlander",
    label: "EV021605: Dahlander"
  },
  {
    valueId: "EV021606",
    valueDesc: "Double triangle/double star",
    label: "EV021606: Double triangle/double star"
  },
  {
    valueId: "EV021612",
    valueDesc: "Ex d IIC T4",
    label: "EV021612: Ex d IIC T4"
  },
  {
    valueId: "EV021614",
    valueDesc: "Flange ring",
    label: "EV021614: Flange ring"
  },
  {
    valueId: "EV021615",
    valueDesc: "3000 2 pole",
    label: "EV021615: 3000 2 pole"
  },
  {
    valueId: "EV021616",
    valueDesc: "1500 4 pole",
    label: "EV021616: 1500 4 pole"
  },
  {
    valueId: "EV021617",
    valueDesc: "1000 6-pin",
    label: "EV021617: 1000 6-pin"
  },
  {
    valueId: "EV021618",
    valueDesc: "750 8-pin",
    label: "EV021618: 750 8-pin"
  },
  {
    valueId: "EV021619",
    valueDesc: "500 12-pin",
    label: "EV021619: 500 12-pin"
  },
  {
    valueId: "EV021621",
    valueDesc: "Servomotor",
    label: "EV021621: Servomotor"
  },
  {
    valueId: "EV021622",
    valueDesc: "Round connection",
    label: "EV021622: Round connection"
  },
  {
    valueId: "EV021623",
    valueDesc: "Flat connection",
    label: "EV021623: Flat connection"
  },
  {
    valueId: "EV021624",
    valueDesc: "DIN 24154-R5",
    label: "EV021624: DIN 24154-R5"
  },
  {
    valueId: "EV021625",
    valueDesc: "DN 216",
    label: "EV021625: DN 216"
  },
  {
    valueId: "EV021626",
    valueDesc: "Non-return valve",
    label: "EV021626: Non-return valve"
  },
  {
    valueId: "EV021627",
    valueDesc: "Insert end with sealing ring",
    label: "EV021627: Insert end with sealing ring"
  },
  {
    valueId: "EV021628",
    valueDesc: "Cover flashing",
    label: "EV021628: Cover flashing"
  },
  {
    valueId: "EV021629",
    valueDesc: "Self-adhesive cover piece, bitumen",
    label: "EV021629: Self-adhesive cover piece, bitumen"
  },
  {
    valueId: "EV021630",
    valueDesc: "Suction side above the roof",
    label: "EV021630: Suction side above the roof"
  },
  {
    valueId: "EV021631",
    valueDesc: "Suction side below the roof",
    label: "EV021631: Suction side below the roof"
  },
  {
    valueId: "EV021632",
    valueDesc: "Delivery side",
    label: "EV021632: Delivery side"
  },
  {
    valueId: "EV021633",
    valueDesc: "Suspended above the pit",
    label: "EV021633: Suspended above the pit"
  },
  {
    valueId: "EV021634",
    valueDesc: "Standing in the pit",
    label: "EV021634: Standing in the pit"
  },
  {
    valueId: "EV021635",
    valueDesc: "One-sheet",
    label: "EV021635: One-sheet"
  },
  {
    valueId: "EV021636",
    valueDesc: "Split valve butterfly",
    label: "EV021636: Split valve butterfly"
  },
  {
    valueId: "EV021641",
    valueDesc: "600 mm",
    label: "EV021641: 600 mm"
  },
  {
    valueId: "EV021643",
    valueDesc: "Finkenberger/Frankfurter",
    label: "EV021643: Finkenberger/Frankfurter"
  },
  {
    valueId: "EV021644",
    valueDesc: "Neuwwieder/Rhein-Ruhr",
    label: "EV021644: Neuwwieder/Rhein-Ruhr"
  },
  {
    valueId: "EV021646",
    valueDesc: "Square bend",
    label: "EV021646: Square bend"
  },
  {
    valueId: "EV021652",
    valueDesc: "Single with lid",
    label: "EV021652: Single with lid"
  },
  {
    valueId: "EV021653",
    valueDesc: "Double with lid",
    label: "EV021653: Double with lid"
  },
  {
    valueId: "EV021654",
    valueDesc: "Single branch",
    label: "EV021654: Single branch"
  },
  {
    valueId: "EV021655",
    valueDesc: "Double branches",
    label: "EV021655: Double branches"
  },
  {
    valueId: "EV021657",
    valueDesc: "1-pipe",
    label: "EV021657: 1-pipe"
  },
  {
    valueId: "EV021658",
    valueDesc: "2-pipe",
    label: "EV021658: 2-pipe"
  },
  {
    valueId: "EV021664",
    valueDesc: "63/95.5 mm",
    label: "EV021664: 63/95.5 mm"
  },
  {
    valueId: "EV021665",
    valueDesc: "Concentric/parallel",
    label: "EV021665: Concentric/parallel"
  },
  {
    valueId: "EV021666",
    valueDesc: "Parallel/concentric",
    label: "EV021666: Parallel/concentric"
  },
  {
    valueId: "EV021667",
    valueDesc: "1 x 230 V AC",
    label: "EV021667: 1 x 230 V AC"
  },
  {
    valueId: "EV021670",
    valueDesc: "Connecting cord with plug with protective contact",
    label: "EV021670: Connecting cord with plug with protective contact"
  },
  {
    valueId: "EV021671",
    valueDesc: "Perilex connecting cord",
    label: "EV021671: Perilex connecting cord"
  },
  {
    valueId: "EV021672",
    valueDesc: "Steel, lined",
    label: "EV021672: Steel, lined"
  },
  {
    valueId: "EV021673",
    valueDesc: "3 * 400 V AC",
    label: "EV021673: 3 * 400 V AC"
  },
  {
    valueId: "EV021674",
    valueDesc: "T-piece 90° fluent",
    label: "EV021674: T-piece 90° fluent"
  },
  {
    valueId: "EV021680",
    valueDesc: "200",
    label: "EV021680: 200"
  },
  {
    valueId: "EV021681",
    valueDesc: "Niche pipe cover",
    label: "EV021681: Niche pipe cover"
  },
  {
    valueId: "EV021682",
    valueDesc: "Stoneware pipe reduction",
    label: "EV021682: Stoneware pipe reduction"
  },
  {
    valueId: "EV021683",
    valueDesc: "111 mm",
    label: "EV021683: 111 mm"
  },
  {
    valueId: "EV021684",
    valueDesc: "350 mm",
    label: "EV021684: 350 mm"
  },
  {
    valueId: "EV021685",
    valueDesc: "24 V AC/DC",
    label: "EV021685: 24 V AC/DC"
  },
  {
    valueId: "EV021686",
    valueDesc: "Guide",
    label: "EV021686: Guide"
  },
  {
    valueId: "EV021687",
    valueDesc: "Pressure difference",
    label: "EV021687: Pressure difference"
  },
  {
    valueId: "EV021688",
    valueDesc: "Rotation",
    label: "EV021688: Rotation"
  },
  {
    valueId: "EV021689",
    valueDesc: "Electromagnetic",
    label: "EV021689: Electromagnetic"
  },
  {
    valueId: "EV021690",
    valueDesc: "T-piece, 90° right-angled",
    label: "EV021690: T-piece, 90° right-angled"
  },
  {
    valueId: "EV021691",
    valueDesc: "Clamping bush",
    label: "EV021691: Clamping bush"
  },
  {
    valueId: "EV021692",
    valueDesc: "PIR (polyisocianurate)",
    label: "EV021692: PIR (polyisocianurate)"
  },
  {
    valueId: "EV021693",
    valueDesc: "PTF",
    label: "EV021693: PTF"
  },
  {
    valueId: "EV021694",
    valueDesc: "Central heating",
    label: "EV021694: Central heating"
  },
  {
    valueId: "EV021695",
    valueDesc: "Air treatment",
    label: "EV021695: Air treatment"
  },
  {
    valueId: "EV021696",
    valueDesc: "CH/air treatment",
    label: "EV021696: CH/air treatment"
  },
  {
    valueId: "EV021697",
    valueDesc: "Readjustment",
    label: "EV021697: Readjustment"
  },
  {
    valueId: "EV021698",
    valueDesc: "Tap water",
    label: "EV021698: Tap water"
  },
  {
    valueId: "EV021699",
    valueDesc: "CH/tap water",
    label: "EV021699: CH/tap water"
  },
  {
    valueId: "EV021700",
    valueDesc: "CH/air/tap water",
    label: "EV021700: CH/air/tap water"
  },
  {
    valueId: "EV021701",
    valueDesc: "Special applications",
    label: "EV021701: Special applications"
  },
  {
    valueId: "EV021702",
    valueDesc: "Room air",
    label: "EV021702: Room air"
  },
  {
    valueId: "EV021703",
    valueDesc: "Inlet air",
    label: "EV021703: Inlet air"
  },
  {
    valueId: "EV021704",
    valueDesc: "Return air",
    label: "EV021704: Return air"
  },
  {
    valueId: "EV021705",
    valueDesc: "Humidity controls",
    label: "EV021705: Humidity controls"
  },
  {
    valueId: "EV021706",
    valueDesc: "Heating readjustment",
    label: "EV021706: Heating readjustment"
  },
  {
    valueId: "EV021707",
    valueDesc: "Cooling readjustment",
    label: "EV021707: Cooling readjustment"
  },
  {
    valueId: "EV021708",
    valueDesc: "Frost protection",
    label: "EV021708: Frost protection"
  },
  {
    valueId: "EV021709",
    valueDesc: "Night ventilation",
    label: "EV021709: Night ventilation"
  },
  {
    valueId: "EV021710",
    valueDesc: "Reheater",
    label: "EV021710: Reheater"
  },
  {
    valueId: "EV021711",
    valueDesc: "Summer/winter",
    label: "EV021711: Summer/winter"
  },
  {
    valueId: "EV021712",
    valueDesc: "Temp. switch functions",
    label: "EV021712: Temp. switch functions"
  },
  {
    valueId: "EV021713",
    valueDesc: "Tap water pump",
    label: "EV021713: Tap water pump"
  },
  {
    valueId: "EV021714",
    valueDesc: "Climate ceiling",
    label: "EV021714: Climate ceiling"
  },
  {
    valueId: "EV021715",
    valueDesc: "Fan coil units",
    label: "EV021715: Fan coil units"
  },
  {
    valueId: "EV021718",
    valueDesc: "Single-strap",
    label: "EV021718: Single-strap"
  },
  {
    valueId: "EV021720",
    valueDesc: "Double base plate",
    label: "EV021720: Double base plate"
  },
  {
    valueId: "EV021721",
    valueDesc: "Legs",
    label: "EV021721: Legs"
  },
  {
    valueId: "EV021722",
    valueDesc: "Brace fastening",
    label: "EV021722: Brace fastening"
  },
  {
    valueId: "EV021723",
    valueDesc: "Threaded sleeves",
    label: "EV021723: Threaded sleeves"
  },
  {
    valueId: "EV021724",
    valueDesc: "Square duct",
    label: "EV021724: Square duct"
  },
  {
    valueId: "EV021725",
    valueDesc: "Flue",
    label: "EV021725: Flue"
  },
  {
    valueId: "EV021726",
    valueDesc: "Air inlet/flue gas outlet",
    label: "EV021726: Air inlet/flue gas outlet"
  },
  {
    valueId: "EV021729",
    valueDesc: "Round tube",
    label: "EV021729: Round tube"
  },
  {
    valueId: "EV021730",
    valueDesc: "Flat rod",
    label: "EV021730: Flat rod"
  },
  {
    valueId: "EV021731",
    valueDesc: "Oval tube",
    label: "EV021731: Oval tube"
  },
  {
    valueId: "EV021732",
    valueDesc: "Round rod",
    label: "EV021732: Round rod"
  },
  {
    valueId: "EV021733",
    valueDesc: "Rack/bracket",
    label: "EV021733: Rack/bracket"
  },
  {
    valueId: "EV021734",
    valueDesc: "Rotating holder",
    label: "EV021734: Rotating holder"
  },
  {
    valueId: "EV021735",
    valueDesc: "Fixed holder",
    label: "EV021735: Fixed holder"
  },
  {
    valueId: "EV021736",
    valueDesc: "Plateau",
    label: "EV021736: Plateau"
  },
  {
    valueId: "EV021737",
    valueDesc: "Plateau with grab rail",
    label: "EV021737: Plateau with grab rail"
  },
  {
    valueId: "EV021738",
    valueDesc: "Round ring",
    label: "EV021738: Round ring"
  },
  {
    valueId: "EV021739",
    valueDesc: "Oval ring",
    label: "EV021739: Oval ring"
  },
  {
    valueId: "EV021740",
    valueDesc: "Trapezoid ring",
    label: "EV021740: Trapezoid ring"
  },
  {
    valueId: "EV021741",
    valueDesc: "Rectangular ring",
    label: "EV021741: Rectangular ring"
  },
  {
    valueId: "EV021743",
    valueDesc: "Insert card",
    label: "EV021743: Insert card"
  },
  {
    valueId: "EV021744",
    valueDesc: "Condensate drain",
    label: "EV021744: Condensate drain"
  },
  {
    valueId: "EV021745",
    valueDesc: "Sliding piece",
    label: "EV021745: Sliding piece"
  },
  {
    valueId: "EV021746",
    valueDesc: "Draught controller",
    label: "EV021746: Draught controller"
  },
  {
    valueId: "EV021747",
    valueDesc: "On/Off",
    label: "EV021747: On/Off"
  },
  {
    valueId: "EV021753",
    valueDesc: "Chrome/gold",
    label: "EV021753: Chrome/gold"
  },
  {
    valueId: "EV021757",
    valueDesc: "Horizontal sides",
    label: "EV021757: Horizontal sides"
  },
  {
    valueId: "EV021758",
    valueDesc: "Matt glass",
    label: "EV021758: Matt glass"
  },
  {
    valueId: "EV021759",
    valueDesc: "Matt plastic",
    label: "EV021759: Matt plastic"
  },
  {
    valueId: "EV021761",
    valueDesc: "700 mm",
    label: "EV021761: 700 mm"
  },
  {
    valueId: "EV021762",
    valueDesc: "1200 mm",
    label: "EV021762: 1200 mm"
  },
  {
    valueId: "EV021763",
    valueDesc: "1400 mm",
    label: "EV021763: 1400 mm"
  },
  {
    valueId: "EV021764",
    valueDesc: "1600 mm",
    label: "EV021764: 1600 mm"
  },
  {
    valueId: "EV021765",
    valueDesc: "1800 mm",
    label: "EV021765: 1800 mm"
  },
  {
    valueId: "EV021766",
    valueDesc: "2000 mm",
    label: "EV021766: 2000 mm"
  },
  {
    valueId: "EV021767",
    valueDesc: "175 mm",
    label: "EV021767: 175 mm"
  },
  {
    valueId: "EV021768",
    valueDesc: "750 mm",
    label: "EV021768: 750 mm"
  },
  {
    valueId: "EV021769",
    valueDesc: "1500 mm",
    label: "EV021769: 1500 mm"
  },
  {
    valueId: "EV021770",
    valueDesc: "1250 mm",
    label: "EV021770: 1250 mm"
  },
  {
    valueId: "EV021771",
    valueDesc: "Phenol foam",
    label: "EV021771: Phenol foam"
  },
  {
    valueId: "EV021772",
    valueDesc: "PIR foam",
    label: "EV021772: PIR foam"
  },
  {
    valueId: "EV021773",
    valueDesc: "Cement",
    label: "EV021773: Cement"
  },
  {
    valueId: "EV021774",
    valueDesc: "Corner model",
    label: "EV021774: Corner model"
  },
  {
    valueId: "EV021775",
    valueDesc: "Vertical sides",
    label: "EV021775: Vertical sides"
  },
  {
    valueId: "EV021776",
    valueDesc: "ASME B16.5 R1",
    label: "EV021776: ASME B16.5 R1"
  },
  {
    valueId: "EV021777",
    valueDesc: "ASME B16.5 R3",
    label: "EV021777: ASME B16.5 R3"
  },
  {
    valueId: "EV021778",
    valueDesc: "EN 1514-2",
    label: "EV021778: EN 1514-2"
  },
  {
    valueId: "EV021779",
    valueDesc: "Bent forwards",
    label: "EV021779: Bent forwards"
  },
  {
    valueId: "EV021780",
    valueDesc: "Bent backwards",
    label: "EV021780: Bent backwards"
  },
  {
    valueId: "EV021781",
    valueDesc: "Straight closed",
    label: "EV021781: Straight closed"
  },
  {
    valueId: "EV021782",
    valueDesc: "Straight open",
    label: "EV021782: Straight open"
  },
  {
    valueId: "EV021784",
    valueDesc: "Dense",
    label: "EV021784: Dense"
  },
  {
    valueId: "EV021788",
    valueDesc: "Upward",
    label: "EV021788: Upward"
  },
  {
    valueId: "EV021789",
    valueDesc: "Sideward",
    label: "EV021789: Sideward"
  },
  {
    valueId: "EV021790",
    valueDesc: "550 mm",
    label: "EV021790: 550 mm"
  },
  {
    valueId: "EV021791",
    valueDesc: "Column",
    label: "EV021791: Column"
  },
  {
    valueId: "EV021794",
    valueDesc: "Eccentric in height",
    label: "EV021794: Eccentric in height"
  },
  {
    valueId: "EV021795",
    valueDesc: "Eccentric in width",
    label: "EV021795: Eccentric in width"
  },
  {
    valueId: "EV021796",
    valueDesc: "Eccentric in height/width, left",
    label: "EV021796: Eccentric in height/width, left"
  },
  {
    valueId: "EV021797",
    valueDesc: "Eccentric in height/width, right",
    label: "EV021797: Eccentric in height/width, right"
  },
  {
    valueId: "EV021798",
    valueDesc: "Insulation plate duct",
    label: "EV021798: Insulation plate duct"
  },
  {
    valueId: "EV021799",
    valueDesc: "Facing side inwards",
    label: "EV021799: Facing side inwards"
  },
  {
    valueId: "EV021800",
    valueDesc: "650 mm",
    label: "EV021800: 650 mm"
  },
  {
    valueId: "EV021801",
    valueDesc: "850 mm",
    label: "EV021801: 850 mm"
  },
  {
    valueId: "EV021802",
    valueDesc: "950 mm",
    label: "EV021802: 950 mm"
  },
  {
    valueId: "EV021803",
    valueDesc: "1050 mm",
    label: "EV021803: 1050 mm"
  },
  {
    valueId: "EV021804",
    valueDesc: "1100 mm",
    label: "EV021804: 1100 mm"
  },
  {
    valueId: "EV021805",
    valueDesc: "1150 mm",
    label: "EV021805: 1150 mm"
  },
  {
    valueId: "EV021806",
    valueDesc: "1300 mm",
    label: "EV021806: 1300 mm"
  },
  {
    valueId: "EV021807",
    valueDesc: "1450 mm",
    label: "EV021807: 1450 mm"
  },
  {
    valueId: "EV021808",
    valueDesc: "1350 mm",
    label: "EV021808: 1350 mm"
  },
  {
    valueId: "EV021809",
    valueDesc: "Aluminium laminate",
    label: "EV021809: Aluminium laminate"
  },
  {
    valueId: "EV021810",
    valueDesc: "Glass fibre reinforced aluminium",
    label: "EV021810: Glass fibre reinforced aluminium"
  },
  {
    valueId: "EV021811",
    valueDesc: "Glass fibre PU coating",
    label: "EV021811: Glass fibre PU coating"
  },
  {
    valueId: "EV021812",
    valueDesc: "1-layer",
    label: "EV021812: 1-layer"
  },
  {
    valueId: "EV021813",
    valueDesc: "2-layer",
    label: "EV021813: 2-layer"
  },
  {
    valueId: "EV021814",
    valueDesc: "3-layer",
    label: "EV021814: 3-layer"
  },
  {
    valueId: "EV021815",
    valueDesc: "Aluminium laminate with spiral",
    label: "EV021815: Aluminium laminate with spiral"
  },
  {
    valueId: "EV021816",
    valueDesc: "Plastic with spiral",
    label: "EV021816: Plastic with spiral"
  },
  {
    valueId: "EV021819",
    valueDesc: "1/2 inch / 3/4 inch",
    label: "EV021819: 1/2 inch / 3/4 inch"
  },
  {
    valueId: "EV021820",
    valueDesc: "D profile",
    label: "EV021820: D profile"
  },
  {
    valueId: "EV021821",
    valueDesc: "Half round",
    label: "EV021821: Half round"
  },
  {
    valueId: "EV021823",
    valueDesc: "Slightly bent",
    label: "EV021823: Slightly bent"
  },
  {
    valueId: "EV021825",
    valueDesc: "On wall",
    label: "EV021825: On wall"
  },
  {
    valueId: "EV021826",
    valueDesc: "Perpendicular to wall",
    label: "EV021826: Perpendicular to wall"
  },
  {
    valueId: "EV021827",
    valueDesc: "On column",
    label: "EV021827: On column"
  },
  {
    valueId: "EV021828",
    valueDesc: "Steel/steel",
    label: "EV021828: Steel/steel"
  },
  {
    valueId: "EV021830",
    valueDesc: "Steel/aluminium",
    label: "EV021830: Steel/aluminium"
  },
  {
    valueId: "EV021832",
    valueDesc: "Top",
    label: "EV021832: Top"
  },
  {
    valueId: "EV021833",
    valueDesc: "Top/front",
    label: "EV021833: Top/front"
  },
  {
    valueId: "EV021835",
    valueDesc: "Merbau",
    label: "EV021835: Merbau"
  },
  {
    valueId: "EV021836",
    valueDesc: "Collapsible",
    label: "EV021836: Collapsible"
  },
  {
    valueId: "EV021837",
    valueDesc: "Towel",
    label: "EV021837: Towel"
  },
  {
    valueId: "EV021838",
    valueDesc: "Wardrobe",
    label: "EV021838: Wardrobe"
  },
  {
    valueId: "EV021839",
    valueDesc: "Glass shelf",
    label: "EV021839: Glass shelf"
  },
  {
    valueId: "EV021840",
    valueDesc: "Piece of furniture",
    label: "EV021840: Piece of furniture"
  },
  {
    valueId: "EV021841",
    valueDesc: "Electrical element",
    label: "EV021841: Electrical element"
  },
  {
    valueId: "EV021844",
    valueDesc: "Toilet support",
    label: "EV021844: Toilet support"
  },
  {
    valueId: "EV021845",
    valueDesc: "Polyamide with steel core",
    label: "EV021845: Polyamide with steel core"
  },
  {
    valueId: "EV021846",
    valueDesc: "PMMA (Acrylate)",
    label: "EV021846: PMMA (Acrylate)"
  },
  {
    valueId: "EV021848",
    valueDesc: "Shower seat",
    label: "EV021848: Shower seat"
  },
  {
    valueId: "EV021849",
    valueDesc: "Single leg",
    label: "EV021849: Single leg"
  },
  {
    valueId: "EV021850",
    valueDesc: "Double leg",
    label: "EV021850: Double leg"
  },
  {
    valueId: "EV021851",
    valueDesc: "Wall rail",
    label: "EV021851: Wall rail"
  },
  {
    valueId: "EV021852",
    valueDesc: "Wall attachment",
    label: "EV021852: Wall attachment"
  },
  {
    valueId: "EV021853",
    valueDesc: "Bath attachment",
    label: "EV021853: Bath attachment"
  },
  {
    valueId: "EV021854",
    valueDesc: "Movable",
    label: "EV021854: Movable"
  },
  {
    valueId: "EV021855",
    valueDesc: "Frame bracket",
    label: "EV021855: Frame bracket"
  },
  {
    valueId: "EV021856",
    valueDesc: "Corner wall bracket",
    label: "EV021856: Corner wall bracket"
  },
  {
    valueId: "EV021857",
    valueDesc: "Grab rail",
    label: "EV021857: Grab rail"
  },
  {
    valueId: "EV021859",
    valueDesc: "Switch box",
    label: "EV021859: Switch box"
  },
  {
    valueId: "EV021860",
    valueDesc: "Speed controls",
    label: "EV021860: Speed controls"
  },
  {
    valueId: "EV021861",
    valueDesc: "Maintenance-/service switch",
    label: "EV021861: Maintenance-/service switch"
  },
  {
    valueId: "EV021863",
    valueDesc: "Frost protection thermostat",
    label: "EV021863: Frost protection thermostat"
  },
  {
    valueId: "EV021864",
    valueDesc: "Construction thermostat",
    label: "EV021864: Construction thermostat"
  },
  {
    valueId: "EV021865",
    valueDesc: "Console set",
    label: "EV021865: Console set"
  },
  {
    valueId: "EV021869",
    valueDesc: "Filter section",
    label: "EV021869: Filter section"
  },
  {
    valueId: "EV021870",
    valueDesc: "Exhaust section",
    label: "EV021870: Exhaust section"
  },
  {
    valueId: "EV021872",
    valueDesc: "Wall",
    label: "EV021872: Wall"
  },
  {
    valueId: "EV021873",
    valueDesc: "Hanging (bath edge)",
    label: "EV021873: Hanging (bath edge)"
  },
  {
    valueId: "EV021874",
    valueDesc: "Toilet supports",
    label: "EV021874: Toilet supports"
  },
  {
    valueId: "EV021875",
    valueDesc: "Standing toilet",
    label: "EV021875: Standing toilet"
  },
  {
    valueId: "EV021879",
    valueDesc: "Upwards folding",
    label: "EV021879: Upwards folding"
  },
  {
    valueId: "EV021880",
    valueDesc: "Removable and upwards folding",
    label: "EV021880: Removable and upwards folding"
  },
  {
    valueId: "EV021881",
    valueDesc: "Gas spring",
    label: "EV021881: Gas spring"
  },
  {
    valueId: "EV021882",
    valueDesc: "Spindle",
    label: "EV021882: Spindle"
  },
  {
    valueId: "EV021883",
    valueDesc: "Wall-hung WC pan",
    label: "EV021883: Wall-hung WC pan"
  },
  {
    valueId: "EV021884",
    valueDesc: "Suction section",
    label: "EV021884: Suction section"
  },
  {
    valueId: "EV021885",
    valueDesc: "Mixing section",
    label: "EV021885: Mixing section"
  },
  {
    valueId: "EV021886",
    valueDesc: "Fan sealing plate",
    label: "EV021886: Fan sealing plate"
  },
  {
    valueId: "EV021887",
    valueDesc: "Condensate collector",
    label: "EV021887: Condensate collector"
  },
  {
    valueId: "EV021888",
    valueDesc: "Front exhaust conversion set",
    label: "EV021888: Front exhaust conversion set"
  },
  {
    valueId: "EV021889",
    valueDesc: "Exhaust plenum",
    label: "EV021889: Exhaust plenum"
  },
  {
    valueId: "EV021890",
    valueDesc: "Air switch box",
    label: "EV021890: Air switch box"
  },
  {
    valueId: "EV021891",
    valueDesc: "Suction grille",
    label: "EV021891: Suction grille"
  },
  {
    valueId: "EV021892",
    valueDesc: "Outlet grille",
    label: "EV021892: Outlet grille"
  },
  {
    valueId: "EV021893",
    valueDesc: "Outlet corner piece",
    label: "EV021893: Outlet corner piece"
  },
  {
    valueId: "EV021894",
    valueDesc: "Room thermostat with position controller",
    label: "EV021894: Room thermostat with position controller"
  },
  {
    valueId: "EV021895",
    valueDesc: "Floor built-in",
    label: "EV021895: Floor built-in"
  },
  {
    valueId: "EV021897",
    valueDesc: "Supply unit",
    label: "EV021897: Supply unit"
  },
  {
    valueId: "EV021900",
    valueDesc: "Heating/cooling selector",
    label: "EV021900: Heating/cooling selector"
  },
  {
    valueId: "EV021901",
    valueDesc: "2-way control valve",
    label: "EV021901: 2-way control valve"
  },
  {
    valueId: "EV021902",
    valueDesc: "3-way control valve",
    label: "EV021902: 3-way control valve"
  },
  {
    valueId: "EV021903",
    valueDesc: "Flexible air connection",
    label: "EV021903: Flexible air connection"
  },
  {
    valueId: "EV021904",
    valueDesc: "Front wall",
    label: "EV021904: Front wall"
  },
  {
    valueId: "EV021905",
    valueDesc: "Parrot",
    label: "EV021905: Parrot"
  },
  {
    valueId: "EV021906",
    valueDesc: "Triangle",
    label: "EV021906: Triangle"
  },
  {
    valueId: "EV021907",
    valueDesc: "Foot control",
    label: "EV021907: Foot control"
  },
  {
    valueId: "EV021908",
    valueDesc: "Self mover",
    label: "EV021908: Self mover"
  },
  {
    valueId: "EV021909",
    valueDesc: "Seat",
    label: "EV021909: Seat"
  },
  {
    valueId: "EV021910",
    valueDesc: "Stretcher",
    label: "EV021910: Stretcher"
  },
  {
    valueId: "EV021911",
    valueDesc: "Bath bottom",
    label: "EV021911: Bath bottom"
  },
  {
    valueId: "EV021912",
    valueDesc: "Rotating and transfer aid",
    label: "EV021912: Rotating and transfer aid"
  },
  {
    valueId: "EV021913",
    valueDesc: "Adapter for battery lift",
    label: "EV021913: Adapter for battery lift"
  },
  {
    valueId: "EV021914",
    valueDesc: "Seat filler stop",
    label: "EV021914: Seat filler stop"
  },
  {
    valueId: "EV021915",
    valueDesc: "Telescopic legs",
    label: "EV021915: Telescopic legs"
  },
  {
    valueId: "EV021916",
    valueDesc: "Toilet bucket holder",
    label: "EV021916: Toilet bucket holder"
  },
  {
    valueId: "EV021917",
    valueDesc: "Footrest/footboard",
    label: "EV021917: Footrest/footboard"
  },
  {
    valueId: "EV021918",
    valueDesc: "Leg support",
    label: "EV021918: Leg support"
  },
  {
    valueId: "EV021919",
    valueDesc: "Bottom insert",
    label: "EV021919: Bottom insert"
  },
  {
    valueId: "EV021920",
    valueDesc: "Brake",
    label: "EV021920: Brake"
  },
  {
    valueId: "EV021921",
    valueDesc: "Armrest locking device",
    label: "EV021921: Armrest locking device"
  },
  {
    valueId: "EV021922",
    valueDesc: "Loose back cover",
    label: "EV021922: Loose back cover"
  },
  {
    valueId: "EV021923",
    valueDesc: "Loose seat cover",
    label: "EV021923: Loose seat cover"
  },
  {
    valueId: "EV021924",
    valueDesc: "Soft lay-on seat",
    label: "EV021924: Soft lay-on seat"
  },
  {
    valueId: "EV021925",
    valueDesc: "Toilet raiser",
    label: "EV021925: Toilet raiser"
  },
  {
    valueId: "EV021926",
    valueDesc: "Connecting bracket",
    label: "EV021926: Connecting bracket"
  },
  {
    valueId: "EV021927",
    valueDesc: "Stabilising bracket",
    label: "EV021927: Stabilising bracket"
  },
  {
    valueId: "EV021928",
    valueDesc: "Splash guard",
    label: "EV021928: Splash guard"
  },
  {
    valueId: "EV021929",
    valueDesc: "Decorative piece",
    label: "EV021929: Decorative piece"
  },
  {
    valueId: "EV021930",
    valueDesc: "Sliding plate",
    label: "EV021930: Sliding plate"
  },
  {
    valueId: "EV021931",
    valueDesc: "Front gate",
    label: "EV021931: Front gate"
  },
  {
    valueId: "EV021932",
    valueDesc: "Side gate",
    label: "EV021932: Side gate"
  },
  {
    valueId: "EV021933",
    valueDesc: "Fold",
    label: "EV021933: Fold"
  },
  {
    valueId: "EV021934",
    valueDesc: "Two-grip",
    label: "EV021934: Two-grip"
  },
  {
    valueId: "EV021935",
    valueDesc: "Elbow",
    label: "EV021935: Elbow"
  },
  {
    valueId: "EV021936",
    valueDesc: "Pendulum",
    label: "EV021936: Pendulum"
  },
  {
    valueId: "EV021937",
    valueDesc: "Shower/toilet seat",
    label: "EV021937: Shower/toilet seat"
  },
  {
    valueId: "EV021938",
    valueDesc: "Rotating tray",
    label: "EV021938: Rotating tray"
  },
  {
    valueId: "EV021939",
    valueDesc: "Battery charger",
    label: "EV021939: Battery charger"
  },
  {
    valueId: "EV021941",
    valueDesc: "Turn one-part",
    label: "EV021941: Turn one-part"
  },
  {
    valueId: "EV021942",
    valueDesc: "Turn two-part",
    label: "EV021942: Turn two-part"
  },
  {
    valueId: "EV021943",
    valueDesc: "Turn one-part with fixed panel",
    label: "EV021943: Turn one-part with fixed panel"
  },
  {
    valueId: "EV021946",
    valueDesc: "Walk-in",
    label: "EV021946: Walk-in"
  },
  {
    valueId: "EV021947",
    valueDesc: "With profile",
    label: "EV021947: With profile"
  },
  {
    valueId: "EV021948",
    valueDesc: "Partly profiled",
    label: "EV021948: Partly profiled"
  },
  {
    valueId: "EV021965",
    valueDesc: "15",
    label: "EV021965: 15"
  },
  {
    valueId: "EV021969",
    valueDesc: "Pump switch",
    label: "EV021969: Pump switch"
  },
  {
    valueId: "EV021971",
    valueDesc: "Motor protection",
    label: "EV021971: Motor protection"
  },
  {
    valueId: "EV021976",
    valueDesc: "Control system",
    label: "EV021976: Control system"
  },
  {
    valueId: "EV021978",
    valueDesc: "Control and operating system",
    label: "EV021978: Control and operating system"
  },
  {
    valueId: "EV021979",
    valueDesc: "Differential pressure transmitter",
    label: "EV021979: Differential pressure transmitter"
  },
  {
    valueId: "EV021982",
    valueDesc: "Capacitor, complete",
    label: "EV021982: Capacitor, complete"
  },
  {
    valueId: "EV021983",
    valueDesc: "0 - 0.4 bar",
    label: "EV021983: 0 - 0.4 bar"
  },
  {
    valueId: "EV021984",
    valueDesc: "Improved double size tile",
    label: "EV021984: Improved double size tile"
  },
  {
    valueId: "EV021985",
    valueDesc: "Parallel/parallel",
    label: "EV021985: Parallel/parallel"
  },
  {
    valueId: "EV021989",
    valueDesc: "Methylfluoroalkoxy (MFA)",
    label: "EV021989: Methylfluoroalkoxy (MFA)"
  },
  {
    valueId: "EV021992",
    valueDesc: "Bar",
    label: "EV021992: Bar"
  },
  {
    valueId: "EV021993",
    valueDesc: "Quadratic",
    label: "EV021993: Quadratic"
  },
  {
    valueId: "EV021994",
    valueDesc: "3/4 inch Euroconus",
    label: "EV021994: 3/4 inch Euroconus"
  },
  {
    valueId: "EV021995",
    valueDesc: "Towards floor/wall",
    label: "EV021995: Towards floor/wall"
  },
  {
    valueId: "EV021996",
    valueDesc: "1/2 inch internal thread",
    label: "EV021996: 1/2 inch internal thread"
  },
  {
    valueId: "EV021997",
    valueDesc: "Clamp 26",
    label: "EV021997: Clamp 26"
  },
  {
    valueId: "EV021998",
    valueDesc: "Clamp 34",
    label: "EV021998: Clamp 34"
  },
  {
    valueId: "EV021999",
    valueDesc: "Click 22",
    label: "EV021999: Click 22"
  },
  {
    valueId: "EV022000",
    valueDesc: "Radiator button",
    label: "EV022000: Radiator button"
  },
  {
    valueId: "EV022001",
    valueDesc: "Fill and drain valve lower block",
    label: "EV022001: Fill and drain valve lower block"
  },
  {
    valueId: "EV022003",
    valueDesc: "Suspension hooks",
    label: "EV022003: Suspension hooks"
  },
  {
    valueId: "EV022004",
    valueDesc: "Plug bolts",
    label: "EV022004: Plug bolts"
  },
  {
    valueId: "EV022007",
    valueDesc: "M38",
    label: "EV022007: M38"
  },
  {
    valueId: "EV022008",
    valueDesc: "26 mm",
    label: "EV022008: 26 mm"
  },
  {
    valueId: "EV022009",
    valueDesc: "Brand-dependent",
    label: "EV022009: Brand-dependent"
  },
  {
    valueId: "EV022010",
    valueDesc: "Pulse-pause",
    label: "EV022010: Pulse-pause"
  },
  {
    valueId: "EV022011",
    valueDesc: "PWM (pulse-width-modulated)",
    label: "EV022011: PWM (pulse-width-modulated)"
  },
  {
    valueId: "EV022012",
    valueDesc: "Sealed ring",
    label: "EV022012: Sealed ring"
  },
  {
    valueId: "EV022013",
    valueDesc: "Separable ring",
    label: "EV022013: Separable ring"
  },
  {
    valueId: "EV022014",
    valueDesc: "Slide over pipe",
    label: "EV022014: Slide over pipe"
  },
  {
    valueId: "EV022015",
    valueDesc: "Clamp around pipe",
    label: "EV022015: Clamp around pipe"
  },
  {
    valueId: "EV022016",
    valueDesc: "Blades",
    label: "EV022016: Blades"
  },
  {
    valueId: "EV022017",
    valueDesc: "Expanded polystyrene (EPS)",
    label: "EV022017: Expanded polystyrene (EPS)"
  },
  {
    valueId: "EV022019",
    valueDesc: "S-coupling",
    label: "EV022019: S-coupling"
  },
  {
    valueId: "EV022020",
    valueDesc: "Slide coupling",
    label: "EV022020: Slide coupling"
  },
  {
    valueId: "EV022021",
    valueDesc: "Ametal-C",
    label: "EV022021: Ametal-C"
  },
  {
    valueId: "EV022022",
    valueDesc: "Tailpiece/internal thread",
    label: "EV022022: Tailpiece/internal thread"
  },
  {
    valueId: "EV022023",
    valueDesc: "External thread/external thread",
    label: "EV022023: External thread/external thread"
  },
  {
    valueId: "EV022024",
    valueDesc: "M28 x 1.5",
    label: "EV022024: M28 x 1.5"
  },
  {
    valueId: "EV022025",
    valueDesc: "M30 x 1.5",
    label: "EV022025: M30 x 1.5"
  },
  {
    valueId: "EV022026",
    valueDesc: "M30 x 1.0",
    label: "EV022026: M30 x 1.0"
  },
  {
    valueId: "EV022027",
    valueDesc: "Allen 22",
    label: "EV022027: Allen 22"
  },
  {
    valueId: "EV022028",
    valueDesc: "Tailpiece/external thread",
    label: "EV022028: Tailpiece/external thread"
  },
  {
    valueId: "EV022029",
    valueDesc: "With valve guard",
    label: "EV022029: With valve guard"
  },
  {
    valueId: "EV022030",
    valueDesc: "Without valve guard",
    label: "EV022030: Without valve guard"
  },
  {
    valueId: "EV022031",
    valueDesc: "Liquid",
    label: "EV022031: Liquid"
  },
  {
    valueId: "EV022036",
    valueDesc: "Hand button",
    label: "EV022036: Hand button"
  },
  {
    valueId: "EV022038",
    valueDesc: "Covering cap/ornamental cap",
    label: "EV022038: Covering cap/ornamental cap"
  },
  {
    valueId: "EV022039",
    valueDesc: "Internal mechanism",
    label: "EV022039: Internal mechanism"
  },
  {
    valueId: "EV022040",
    valueDesc: "Gland",
    label: "EV022040: Gland"
  },
  {
    valueId: "EV022041",
    valueDesc: "Screw for hand wheel",
    label: "EV022041: Screw for hand wheel"
  },
  {
    valueId: "EV022042",
    valueDesc: "Linking tube",
    label: "EV022042: Linking tube"
  },
  {
    valueId: "EV022043",
    valueDesc: "Connecting cone",
    label: "EV022043: Connecting cone"
  },
  {
    valueId: "EV022044",
    valueDesc: "Connecting nipple",
    label: "EV022044: Connecting nipple"
  },
  {
    valueId: "EV022046",
    valueDesc: "Disassembly key",
    label: "EV022046: Disassembly key"
  },
  {
    valueId: "EV022047",
    valueDesc: "Blocking pin",
    label: "EV022047: Blocking pin"
  },
  {
    valueId: "EV022048",
    valueDesc: "Anti-theft ring",
    label: "EV022048: Anti-theft ring"
  },
  {
    valueId: "EV022049",
    valueDesc: "Anti-vandalism cap",
    label: "EV022049: Anti-vandalism cap"
  },
  {
    valueId: "EV022050",
    valueDesc: "Adjustment key",
    label: "EV022050: Adjustment key"
  },
  {
    valueId: "EV022051",
    valueDesc: "Coil for capillary pipe",
    label: "EV022051: Coil for capillary pipe"
  },
  {
    valueId: "EV022052",
    valueDesc: "Fill/drain valve",
    label: "EV022052: Fill/drain valve"
  },
  {
    valueId: "EV022053",
    valueDesc: "Spindle extension",
    label: "EV022053: Spindle extension"
  },
  {
    valueId: "EV022054",
    valueDesc: "Tilting non-return valve",
    label: "EV022054: Tilting non-return valve"
  },
  {
    valueId: "EV022055",
    valueDesc: "Membrane non-return valve",
    label: "EV022055: Membrane non-return valve"
  },
  {
    valueId: "EV022056",
    valueDesc: "Telescopic",
    label: "EV022056: Telescopic"
  },
  {
    valueId: "EV022057",
    valueDesc: "2-jet",
    label: "EV022057: 2-jet"
  },
  {
    valueId: "EV022058",
    valueDesc: "Rectified",
    label: "EV022058: Rectified"
  },
  {
    valueId: "EV022059",
    valueDesc: "Two-way valve",
    label: "EV022059: Two-way valve"
  },
  {
    valueId: "EV022060",
    valueDesc: "Three-way valve",
    label: "EV022060: Three-way valve"
  },
  {
    valueId: "EV022062",
    valueDesc: "Loose housing",
    label: "EV022062: Loose housing"
  },
  {
    valueId: "EV022063",
    valueDesc: "Pull chain",
    label: "EV022063: Pull chain"
  },
  {
    valueId: "EV022064",
    valueDesc: "Interruptible",
    label: "EV022064: Interruptible"
  },
  {
    valueId: "EV022065",
    valueDesc: "Two quantities",
    label: "EV022065: Two quantities"
  },
  {
    valueId: "EV022066",
    valueDesc: "Mains",
    label: "EV022066: Mains"
  },
  {
    valueId: "EV022067",
    valueDesc: "With pull cord",
    label: "EV022067: With pull cord"
  },
  {
    valueId: "EV022068",
    valueDesc: "M38 x 1.5",
    label: "EV022068: M38 x 1.5"
  },
  {
    valueId: "EV022069",
    valueDesc: "Adapter from different manufacturer",
    label: "EV022069: Adapter from different manufacturer"
  },
  {
    valueId: "EV022070",
    valueDesc: "Limiting clip",
    label: "EV022070: Limiting clip"
  },
  {
    valueId: "EV022071",
    valueDesc: "Tailpiece",
    label: "EV022071: Tailpiece"
  },
  {
    valueId: "EV022072",
    valueDesc: "Extended tailpiece",
    label: "EV022072: Extended tailpiece"
  },
  {
    valueId: "EV022073",
    valueDesc: "Supporting case",
    label: "EV022073: Supporting case"
  },
  {
    valueId: "EV022074",
    valueDesc: "Thermostatic expansion module",
    label: "EV022074: Thermostatic expansion module"
  },
  {
    valueId: "EV022075",
    valueDesc: "Disinfection module",
    label: "EV022075: Disinfection module"
  },
  {
    valueId: "EV022076",
    valueDesc: "Lockable coupling",
    label: "EV022076: Lockable coupling"
  },
  {
    valueId: "EV022077",
    valueDesc: "H support block",
    label: "EV022077: H support block"
  },
  {
    valueId: "EV022078",
    valueDesc: "M22 x 1.5",
    label: "EV022078: M22 x 1.5"
  },
  {
    valueId: "EV022079",
    valueDesc: "Spare set",
    label: "EV022079: Spare set"
  },
  {
    valueId: "EV022080",
    valueDesc: "Vinyl",
    label: "EV022080: Vinyl"
  },
  {
    valueId: "EV022081",
    valueDesc: "Polyester/cotton",
    label: "EV022081: Polyester/cotton"
  },
  {
    valueId: "EV022082",
    valueDesc: "Pipe length",
    label: "EV022082: Pipe length"
  },
  {
    valueId: "EV022084",
    valueDesc: "Rosette",
    label: "EV022084: Rosette"
  },
  {
    valueId: "EV022085",
    valueDesc: "Soap tray",
    label: "EV022085: Soap tray"
  },
  {
    valueId: "EV022086",
    valueDesc: "Stabilisation",
    label: "EV022086: Stabilisation"
  },
  {
    valueId: "EV022087",
    valueDesc: "Towel holder",
    label: "EV022087: Towel holder"
  },
  {
    valueId: "EV022090",
    valueDesc: "Slide two-part",
    label: "EV022090: Slide two-part"
  },
  {
    valueId: "EV022091",
    valueDesc: "Slide three-part",
    label: "EV022091: Slide three-part"
  },
  {
    valueId: "EV022092",
    valueDesc: "Slide four-part",
    label: "EV022092: Slide four-part"
  },
  {
    valueId: "EV022093",
    valueDesc: "Fixed one-part",
    label: "EV022093: Fixed one-part"
  },
  {
    valueId: "EV022094",
    valueDesc: "Swing one-part",
    label: "EV022094: Swing one-part"
  },
  {
    valueId: "EV022095",
    valueDesc: "Swing two-part",
    label: "EV022095: Swing two-part"
  },
  {
    valueId: "EV022096",
    valueDesc: "Swing three-part",
    label: "EV022096: Swing three-part"
  },
  {
    valueId: "EV022097",
    valueDesc: "Swing one-part with fixed panel",
    label: "EV022097: Swing one-part with fixed panel"
  },
  {
    valueId: "EV022098",
    valueDesc: "Swing two-part with fixed panel",
    label: "EV022098: Swing two-part with fixed panel"
  },
  {
    valueId: "EV022102",
    valueDesc: "Handles/buttons",
    label: "EV022102: Handles/buttons"
  },
  {
    valueId: "EV022103",
    valueDesc: "OpenTherm",
    label: "EV022103: OpenTherm"
  },
  {
    valueId: "EV022105",
    valueDesc: "PID",
    label: "EV022105: PID"
  },
  {
    valueId: "EV022107",
    valueDesc: "3-/4-wire",
    label: "EV022107: 3-/4-wire"
  },
  {
    valueId: "EV022108",
    valueDesc: "Ground plate",
    label: "EV022108: Ground plate"
  },
  {
    valueId: "EV022109",
    valueDesc: "Operate-switch",
    label: "EV022109: Operate-switch"
  },
  {
    valueId: "EV022110",
    valueDesc: "Zone control",
    label: "EV022110: Zone control"
  },
  {
    valueId: "EV022111",
    valueDesc: "Weather-dependent boiler control",
    label: "EV022111: Weather-dependent boiler control"
  },
  {
    valueId: "EV022112",
    valueDesc: "District heating control set",
    label: "EV022112: District heating control set"
  },
  {
    valueId: "EV022113",
    valueDesc: "Floor heating control set",
    label: "EV022113: Floor heating control set"
  },
  {
    valueId: "EV022114",
    valueDesc: "Self-controlling",
    label: "EV022114: Self-controlling"
  },
  {
    valueId: "EV022115",
    valueDesc: "Thermal drive module",
    label: "EV022115: Thermal drive module"
  },
  {
    valueId: "EV022116",
    valueDesc: "Drain adapter",
    label: "EV022116: Drain adapter"
  },
  {
    valueId: "EV022117",
    valueDesc: "Fixed probe",
    label: "EV022117: Fixed probe"
  },
  {
    valueId: "EV022118",
    valueDesc: "Sensor and control remote",
    label: "EV022118: Sensor and control remote"
  },
  {
    valueId: "EV022119",
    valueDesc: "Thermomotor",
    label: "EV022119: Thermomotor"
  },
  {
    valueId: "EV022120",
    valueDesc: "CTR",
    label: "EV022120: CTR"
  },
  {
    valueId: "EV022121",
    valueDesc: "Spring/cartridge",
    label: "EV022121: Spring/cartridge"
  },
  {
    valueId: "EV022124",
    valueDesc: "0 - 5 V / 5 - 10 V",
    label: "EV022124: 0 - 5 V / 5 - 10 V"
  },
  {
    valueId: "EV022125",
    valueDesc: "Binary",
    label: "EV022125: Binary"
  },
  {
    valueId: "EV022126",
    valueDesc: "230 V AC/DC",
    label: "EV022126: 230 V AC/DC"
  },
  {
    valueId: "EV022127",
    valueDesc: "Reversible",
    label: "EV022127: Reversible"
  },
  {
    valueId: "EV022129",
    valueDesc: "Operating software",
    label: "EV022129: Operating software"
  },
  {
    valueId: "EV022131",
    valueDesc: "Programming/service magnet",
    label: "EV022131: Programming/service magnet"
  },
  {
    valueId: "EV022132",
    valueDesc: "Spacer yoke",
    label: "EV022132: Spacer yoke"
  },
  {
    valueId: "EV022133",
    valueDesc: "Feedback potentiometer",
    label: "EV022133: Feedback potentiometer"
  },
  {
    valueId: "EV022134",
    valueDesc: "Transformer set",
    label: "EV022134: Transformer set"
  },
  {
    valueId: "EV022137",
    valueDesc: "Weather-dependent control set",
    label: "EV022137: Weather-dependent control set"
  },
  {
    valueId: "EV022139",
    valueDesc: "Automatic time setting (ATS)",
    label: "EV022139: Automatic time setting (ATS)"
  },
  {
    valueId: "EV022140",
    valueDesc: "Supply switch unit",
    label: "EV022140: Supply switch unit"
  },
  {
    valueId: "EV022141",
    valueDesc: "Zone expansion unit",
    label: "EV022141: Zone expansion unit"
  },
  {
    valueId: "EV022142",
    valueDesc: "Bush cable",
    label: "EV022142: Bush cable"
  },
  {
    valueId: "EV022143",
    valueDesc: "Switch supply unit",
    label: "EV022143: Switch supply unit"
  },
  {
    valueId: "EV022145",
    valueDesc: "Radiator controller",
    label: "EV022145: Radiator controller"
  },
  {
    valueId: "EV022146",
    valueDesc: "Cleaner's sink",
    label: "EV022146: Cleaner's sink"
  },
  {
    valueId: "EV022147",
    valueDesc: "Bath/shower mixing tap",
    label: "EV022147: Bath/shower mixing tap"
  },
  {
    valueId: "EV022149",
    valueDesc: "Top mounting",
    label: "EV022149: Top mounting"
  },
  {
    valueId: "EV022150",
    valueDesc: "Left, right and centre",
    label: "EV022150: Left, right and centre"
  },
  {
    valueId: "EV022152",
    valueDesc: "Stainless steel 318 (1.4583)",
    label: "EV022152: Stainless steel 318 (1.4583)"
  },
  {
    valueId: "EV022153",
    valueDesc: "Deep",
    label: "EV022153: Deep"
  },
  {
    valueId: "EV022154",
    valueDesc: "Cascade",
    label: "EV022154: Cascade"
  },
  {
    valueId: "EV022155",
    valueDesc: "Straight wall",
    label: "EV022155: Straight wall"
  },
  {
    valueId: "EV022156",
    valueDesc: "Cold/premixed",
    label: "EV022156: Cold/premixed"
  },
  {
    valueId: "EV022157",
    valueDesc: "Central/middle",
    label: "EV022157: Central/middle"
  },
  {
    valueId: "EV022158",
    valueDesc: "Radiator valve/foot valve",
    label: "EV022158: Radiator valve/foot valve"
  },
  {
    valueId: "EV022159",
    valueDesc: "With adapter",
    label: "EV022159: With adapter"
  },
  {
    valueId: "EV022160",
    valueDesc: "Logical",
    label: "EV022160: Logical"
  },
  {
    valueId: "EV022161",
    valueDesc: "Impulse pipe",
    label: "EV022161: Impulse pipe"
  },
  {
    valueId: "EV022162",
    valueDesc: "Gasket set",
    label: "EV022162: Gasket set"
  },
  {
    valueId: "EV022163",
    valueDesc: "O-ring set",
    label: "EV022163: O-ring set"
  },
  {
    valueId: "EV022165",
    valueDesc: "Filling/drainage set",
    label: "EV022165: Filling/drainage set"
  },
  {
    valueId: "EV022166",
    valueDesc: "Measuring nipple",
    label: "EV022166: Measuring nipple"
  },
  {
    valueId: "EV022167",
    valueDesc: "Insulation shell",
    label: "EV022167: Insulation shell"
  },
  {
    valueId: "EV022168",
    valueDesc: "Capillary line",
    label: "EV022168: Capillary line"
  },
  {
    valueId: "EV022169",
    valueDesc: "Extension set for capillary tube",
    label: "EV022169: Extension set for capillary tube"
  },
  {
    valueId: "EV022170",
    valueDesc: "Adapter for capillary tube",
    label: "EV022170: Adapter for capillary tube"
  },
  {
    valueId: "EV022171",
    valueDesc: "Sealing nipple for capillary tube",
    label: "EV022171: Sealing nipple for capillary tube"
  },
  {
    valueId: "EV022172",
    valueDesc: "Measuring hose",
    label: "EV022172: Measuring hose"
  },
  {
    valueId: "EV022173",
    valueDesc: "Measuring hose with filter and cut-off valve",
    label: "EV022173: Measuring hose with filter and cut-off valve"
  },
  {
    valueId: "EV022174",
    valueDesc: "Measuring needle",
    label: "EV022174: Measuring needle"
  },
  {
    valueId: "EV022175",
    valueDesc: "Measuring adapter set",
    label: "EV022175: Measuring adapter set"
  },
  {
    valueId: "EV022176",
    valueDesc: "Expansion set",
    label: "EV022176: Expansion set"
  },
  {
    valueId: "EV022177",
    valueDesc: "Charger",
    label: "EV022177: Charger"
  },
  {
    valueId: "EV022181",
    valueDesc: "Flask",
    label: "EV022181: Flask"
  },
  {
    valueId: "EV022182",
    valueDesc: "Bottle",
    label: "EV022182: Bottle"
  },
  {
    valueId: "EV022183",
    valueDesc: "Jerrycan",
    label: "EV022183: Jerrycan"
  },
  {
    valueId: "EV022185",
    valueDesc: "Click sleeve",
    label: "EV022185: Click sleeve"
  },
  {
    valueId: "EV022186",
    valueDesc: "PEHD-Xc",
    label: "EV022186: PEHD-Xc"
  },
  {
    valueId: "EV022187",
    valueDesc: "PE-MD",
    label: "EV022187: PE-MD"
  },
  {
    valueId: "EV022188",
    valueDesc: "Ethylene vinyl alcohol (EVOH)",
    label: "EV022188: Ethylene vinyl alcohol (EVOH)"
  },
  {
    valueId: "EV022189",
    valueDesc: "Dirt",
    label: "EV022189: Dirt"
  },
  {
    valueId: "EV022190",
    valueDesc: "Air/dirt",
    label: "EV022190: Air/dirt"
  },
  {
    valueId: "EV022191",
    valueDesc: "Insert key",
    label: "EV022191: Insert key"
  },
  {
    valueId: "EV022193",
    valueDesc: "Locking ring",
    label: "EV022193: Locking ring"
  },
  {
    valueId: "EV022194",
    valueDesc: "Built-in section",
    label: "EV022194: Built-in section"
  },
  {
    valueId: "EV022195",
    valueDesc: "Finishing section",
    label: "EV022195: Finishing section"
  },
  {
    valueId: "EV022196",
    valueDesc: "Built-in section + finishing section",
    label: "EV022196: Built-in section + finishing section"
  },
  {
    valueId: "EV022197",
    valueDesc: "Top section, ceramic",
    label: "EV022197: Top section, ceramic"
  },
  {
    valueId: "EV022198",
    valueDesc: "Top section, non-ceramic",
    label: "EV022198: Top section, non-ceramic"
  },
  {
    valueId: "EV022199",
    valueDesc: "Cartouche, ceramic",
    label: "EV022199: Cartouche, ceramic"
  },
  {
    valueId: "EV022200",
    valueDesc: "Cartouche, ball",
    label: "EV022200: Cartouche, ball"
  },
  {
    valueId: "EV022201",
    valueDesc: "Hose (gland nut)",
    label: "EV022201: Hose (gland nut)"
  },
  {
    valueId: "EV022202",
    valueDesc: "1-hole",
    label: "EV022202: 1-hole"
  },
  {
    valueId: "EV022203",
    valueDesc: "2-hole",
    label: "EV022203: 2-hole"
  },
  {
    valueId: "EV022204",
    valueDesc: "3-hole",
    label: "EV022204: 3-hole"
  },
  {
    valueId: "EV022205",
    valueDesc: "Pipe",
    label: "EV022205: Pipe"
  },
  {
    valueId: "EV022206",
    valueDesc: "Hose (compression)",
    label: "EV022206: Hose (compression)"
  },
  {
    valueId: "EV022214",
    valueDesc: "Rotating, bottom",
    label: "EV022214: Rotating, bottom"
  },
  {
    valueId: "EV022215",
    valueDesc: "Rotating, top",
    label: "EV022215: Rotating, top"
  },
  {
    valueId: "EV022216",
    valueDesc: "Fixed, bottom",
    label: "EV022216: Fixed, bottom"
  },
  {
    valueId: "EV022217",
    valueDesc: "Fixed, top",
    label: "EV022217: Fixed, top"
  },
  {
    valueId: "EV022218",
    valueDesc: "Flexible with rinsing shower",
    label: "EV022218: Flexible with rinsing shower"
  },
  {
    valueId: "EV022220",
    valueDesc: "Aerator, fixed",
    label: "EV022220: Aerator, fixed"
  },
  {
    valueId: "EV022221",
    valueDesc: "Anti-splash spout, fixed",
    label: "EV022221: Anti-splash spout, fixed"
  },
  {
    valueId: "EV022222",
    valueDesc: "Aerator with ball joint",
    label: "EV022222: Aerator with ball joint"
  },
  {
    valueId: "EV022223",
    valueDesc: "Anti-splash spout with ball joint",
    label: "EV022223: Anti-splash spout with ball joint"
  },
  {
    valueId: "EV022224",
    valueDesc: "Bath edge/tile edge",
    label: "EV022224: Bath edge/tile edge"
  },
  {
    valueId: "EV022228",
    valueDesc: "Front part",
    label: "EV022228: Front part"
  },
  {
    valueId: "EV022229",
    valueDesc: "Covered (rear)",
    label: "EV022229: Covered (rear)"
  },
  {
    valueId: "EV022230",
    valueDesc: "Self-bearing supporting legs",
    label: "EV022230: Self-bearing supporting legs"
  },
  {
    valueId: "EV022231",
    valueDesc: "Non-self-bearing supporting legs",
    label: "EV022231: Non-self-bearing supporting legs"
  },
  {
    valueId: "EV022232",
    valueDesc: "Attachment plate",
    label: "EV022232: Attachment plate"
  },
  {
    valueId: "EV022233",
    valueDesc: "Outflow mechanism",
    label: "EV022233: Outflow mechanism"
  },
  {
    valueId: "EV022234",
    valueDesc: "Breaker set",
    label: "EV022234: Breaker set"
  },
  {
    valueId: "EV022235",
    valueDesc: "Volume flow limiter",
    label: "EV022235: Volume flow limiter"
  },
  {
    valueId: "EV022236",
    valueDesc: "Float valve membrane",
    label: "EV022236: Float valve membrane"
  },
  {
    valueId: "EV022237",
    valueDesc: "Bottom valve membrane",
    label: "EV022237: Bottom valve membrane"
  },
  {
    valueId: "EV022238",
    valueDesc: "Float valve filter",
    label: "EV022238: Float valve filter"
  },
  {
    valueId: "EV022239",
    valueDesc: "Connecting set middle rear",
    label: "EV022239: Connecting set middle rear"
  },
  {
    valueId: "EV022240",
    valueDesc: "Frame spacer",
    label: "EV022240: Frame spacer"
  },
  {
    valueId: "EV022241",
    valueDesc: "Flushing system conversion set",
    label: "EV022241: Flushing system conversion set"
  },
  {
    valueId: "EV022242",
    valueDesc: "Combination tile",
    label: "EV022242: Combination tile"
  },
  {
    valueId: "EV022243",
    valueDesc: "Skirting",
    label: "EV022243: Skirting"
  },
  {
    valueId: "EV022244",
    valueDesc: "Stair tread",
    label: "EV022244: Stair tread"
  },
  {
    valueId: "EV022245",
    valueDesc: "Splinter",
    label: "EV022245: Splinter"
  },
  {
    valueId: "EV022246",
    valueDesc: "Corner strip",
    label: "EV022246: Corner strip"
  },
  {
    valueId: "EV022247",
    valueDesc: "Floor piece",
    label: "EV022247: Floor piece"
  },
  {
    valueId: "EV022248",
    valueDesc: "Uni",
    label: "EV022248: Uni"
  },
  {
    valueId: "EV022249",
    valueDesc: "Porcellanato",
    label: "EV022249: Porcellanato"
  },
  {
    valueId: "EV022250",
    valueDesc: "Red body",
    label: "EV022250: Red body"
  },
  {
    valueId: "EV022251",
    valueDesc: "White body",
    label: "EV022251: White body"
  },
  {
    valueId: "EV022252",
    valueDesc: "Relief",
    label: "EV022252: Relief"
  },
  {
    valueId: "EV022253",
    valueDesc: "Cut-out",
    label: "EV022253: Cut-out"
  },
  {
    valueId: "EV022254",
    valueDesc: "13",
    label: "EV022254: 13"
  },
  {
    valueId: "EV022256",
    valueDesc: "Barrel-polished",
    label: "EV022256: Barrel-polished"
  },
  {
    valueId: "EV022257",
    valueDesc: "Brushed/barrel-polished",
    label: "EV022257: Brushed/barrel-polished"
  },
  {
    valueId: "EV022258",
    valueDesc: "Patinati",
    label: "EV022258: Patinati"
  },
  {
    valueId: "EV022259",
    valueDesc: "Smoothed",
    label: "EV022259: Smoothed"
  },
  {
    valueId: "EV022260",
    valueDesc: "Satin-gloss",
    label: "EV022260: Satin-gloss"
  },
  {
    valueId: "EV022261",
    valueDesc: "Mat",
    label: "EV022261: Mat"
  },
  {
    valueId: "EV022262",
    valueDesc: "Travertine",
    label: "EV022262: Travertine"
  },
  {
    valueId: "EV022263",
    valueDesc: "French",
    label: "EV022263: French"
  },
  {
    valueId: "EV022264",
    valueDesc: "Half-brick",
    label: "EV022264: Half-brick"
  },
  {
    valueId: "EV022265",
    valueDesc: "Norwegian",
    label: "EV022265: Norwegian"
  },
  {
    valueId: "EV022266",
    valueDesc: "Header",
    label: "EV022266: Header"
  },
  {
    valueId: "EV022267",
    valueDesc: "Herringbone",
    label: "EV022267: Herringbone"
  },
  {
    valueId: "EV022268",
    valueDesc: "Flemish",
    label: "EV022268: Flemish"
  },
  {
    valueId: "EV022269",
    valueDesc: "Free",
    label: "EV022269: Free"
  },
  {
    valueId: "EV022270",
    valueDesc: "Sandstone",
    label: "EV022270: Sandstone"
  },
  {
    valueId: "EV022271",
    valueDesc: "Belgian bluestone",
    label: "EV022271: Belgian bluestone"
  },
  {
    valueId: "EV022272",
    valueDesc: "Unistone",
    label: "EV022272: Unistone"
  },
  {
    valueId: "EV022273",
    valueDesc: "Corner stone",
    label: "EV022273: Corner stone"
  },
  {
    valueId: "EV022274",
    valueDesc: "End stone",
    label: "EV022274: End stone"
  },
  {
    valueId: "EV022275",
    valueDesc: "1000",
    label: "EV022275: 1000"
  },
  {
    valueId: "EV022277",
    valueDesc: "End piece external thread with drain and de-aeration",
    label: "EV022277: End piece external thread with drain and de-aeration"
  },
  {
    valueId: "EV022278",
    valueDesc: "End piece internal thread with drain and de-aeration",
    label: "EV022278: End piece internal thread with drain and de-aeration"
  },
  {
    valueId: "EV022279",
    valueDesc: "End plug",
    label: "EV022279: End plug"
  },
  {
    valueId: "EV022282",
    valueDesc: "Facing",
    label: "EV022282: Facing"
  },
  {
    valueId: "EV022284",
    valueDesc: "Single-grip",
    label: "EV022284: Single-grip"
  },
  {
    valueId: "EV022286",
    valueDesc: "Argon",
    label: "EV022286: Argon"
  },
  {
    valueId: "EV022287",
    valueDesc: "Forming gas",
    label: "EV022287: Forming gas"
  },
  {
    valueId: "EV022288",
    valueDesc: "Helium",
    label: "EV022288: Helium"
  },
  {
    valueId: "EV022289",
    valueDesc: "Carbon dioxide",
    label: "EV022289: Carbon dioxide"
  },
  {
    valueId: "EV022290",
    valueDesc: "Nitrogen",
    label: "EV022290: Nitrogen"
  },
  {
    valueId: "EV022291",
    valueDesc: "Package",
    label: "EV022291: Package"
  },
  {
    valueId: "EV022292",
    valueDesc: "LU1",
    label: "EV022292: LU1"
  },
  {
    valueId: "EV022293",
    valueDesc: "Ri2",
    label: "EV022293: Ri2"
  },
  {
    valueId: "EV022294",
    valueDesc: "RU1",
    label: "EV022294: RU1"
  },
  {
    valueId: "EV022295",
    valueDesc: "RU3",
    label: "EV022295: RU3"
  },
  {
    valueId: "EV022296",
    valueDesc: "RU4",
    label: "EV022296: RU4"
  },
  {
    valueId: "EV022297",
    valueDesc: "RU6",
    label: "EV022297: RU6"
  },
  {
    valueId: "EV022298",
    valueDesc: "R22",
    label: "EV022298: R22"
  },
  {
    valueId: "EV022299",
    valueDesc: "R23",
    label: "EV022299: R23"
  },
  {
    valueId: "EV022300",
    valueDesc: "R134a",
    label: "EV022300: R134a"
  },
  {
    valueId: "EV022301",
    valueDesc: "R401a",
    label: "EV022301: R401a"
  },
  {
    valueId: "EV022302",
    valueDesc: "R402a",
    label: "EV022302: R402a"
  },
  {
    valueId: "EV022303",
    valueDesc: "R404a",
    label: "EV022303: R404a"
  },
  {
    valueId: "EV022304",
    valueDesc: "R407c",
    label: "EV022304: R407c"
  },
  {
    valueId: "EV022305",
    valueDesc: "R410a",
    label: "EV022305: R410a"
  },
  {
    valueId: "EV022306",
    valueDesc: "R413a",
    label: "EV022306: R413a"
  },
  {
    valueId: "EV022307",
    valueDesc: "R417a",
    label: "EV022307: R417a"
  },
  {
    valueId: "EV022308",
    valueDesc: "R507",
    label: "EV022308: R507"
  },
  {
    valueId: "EV022309",
    valueDesc: "CGA660",
    label: "EV022309: CGA660"
  },
  {
    valueId: "EV022310",
    valueDesc: "Mixing gas, other",
    label: "EV022310: Mixing gas, other"
  },
  {
    valueId: "EV022312",
    valueDesc: "7/32 inch",
    label: "EV022312: 7/32 inch"
  },
  {
    valueId: "EV022313",
    valueDesc: "Package tank",
    label: "EV022313: Package tank"
  },
  {
    valueId: "EV022314",
    valueDesc: "Slide-in",
    label: "EV022314: Slide-in"
  },
  {
    valueId: "EV022316",
    valueDesc: "Plastic jacket, yellow",
    label: "EV022316: Plastic jacket, yellow"
  },
  {
    valueId: "EV022317",
    valueDesc: "Plastic jacket, grey",
    label: "EV022317: Plastic jacket, grey"
  },
  {
    valueId: "EV022318",
    valueDesc: "Plastic jacket, white",
    label: "EV022318: Plastic jacket, white"
  },
  {
    valueId: "EV022322",
    valueDesc: "On tap spout",
    label: "EV022322: On tap spout"
  },
  {
    valueId: "EV022323",
    valueDesc: "1/2 inch + M22",
    label: "EV022323: 1/2 inch + M22"
  },
  {
    valueId: "EV022324",
    valueDesc: "Red/chrome",
    label: "EV022324: Red/chrome"
  },
  {
    valueId: "EV022325",
    valueDesc: "Red/black",
    label: "EV022325: Red/black"
  },
  {
    valueId: "EV022326",
    valueDesc: "Red/chrome/stainless steel",
    label: "EV022326: Red/chrome/stainless steel"
  },
  {
    valueId: "EV022329",
    valueDesc: "Click bracket",
    label: "EV022329: Click bracket"
  },
  {
    valueId: "EV022330",
    valueDesc: "Wall cover",
    label: "EV022330: Wall cover"
  },
  {
    valueId: "EV022331",
    valueDesc: "Inner bend",
    label: "EV022331: Inner bend"
  },
  {
    valueId: "EV022332",
    valueDesc: "Outer bend",
    label: "EV022332: Outer bend"
  },
  {
    valueId: "EV022333",
    valueDesc: "Flat bend",
    label: "EV022333: Flat bend"
  },
  {
    valueId: "EV022335",
    valueDesc: "2 settings integrated",
    label: "EV022335: 2 settings integrated"
  },
  {
    valueId: "EV022336",
    valueDesc: "3 settings integrated",
    label: "EV022336: 3 settings integrated"
  },
  {
    valueId: "EV022338",
    valueDesc: "50/50 mm",
    label: "EV022338: 50/50 mm"
  },
  {
    valueId: "EV022339",
    valueDesc: "70/70 mm",
    label: "EV022339: 70/70 mm"
  },
  {
    valueId: "EV022341",
    valueDesc: "35 - 35°",
    label: "EV022341: 35 - 35°"
  },
  {
    valueId: "EV022342",
    valueDesc: "RBB quick roofing",
    label: "EV022342: RBB quick roofing"
  },
  {
    valueId: "EV022343",
    valueDesc: "Single-sided with hook",
    label: "EV022343: Single-sided with hook"
  },
  {
    valueId: "EV022344",
    valueDesc: "Single-sided with loops",
    label: "EV022344: Single-sided with loops"
  },
  {
    valueId: "EV022345",
    valueDesc: "Roof boarding",
    label: "EV022345: Roof boarding"
  },
  {
    valueId: "EV022346",
    valueDesc: "Top",
    label: "EV022346: Top"
  },
  {
    valueId: "EV022348",
    valueDesc: "363",
    label: "EV022348: 363"
  },
  {
    valueId: "EV022349",
    valueDesc: "Standard response",
    label: "EV022349: Standard response"
  },
  {
    valueId: "EV022350",
    valueDesc: "147/161",
    label: "EV022350: 147/161"
  },
  {
    valueId: "EV022351",
    valueDesc: "242",
    label: "EV022351: 242"
  },
  {
    valueId: "EV022352",
    valueDesc: "Saw cut",
    label: "EV022352: Saw cut"
  },
  {
    valueId: "EV022353",
    valueDesc: "Allen key",
    label: "EV022353: Allen key"
  },
  {
    valueId: "EV022354",
    valueDesc: "With hose connection",
    label: "EV022354: With hose connection"
  },
  {
    valueId: "EV022355",
    valueDesc: "43",
    label: "EV022355: 43"
  },
  {
    valueId: "EV022357",
    valueDesc: "Special response",
    label: "EV022357: Special response"
  },
  {
    valueId: "EV022358",
    valueDesc: "Standing/hanging (conventional)",
    label: "EV022358: Standing/hanging (conventional)"
  },
  {
    valueId: "EV022359",
    valueDesc: "Special",
    label: "EV022359: Special"
  },
  {
    valueId: "EV022360",
    valueDesc: "Storage cabinet for spare sprinklers",
    label: "EV022360: Storage cabinet for spare sprinklers"
  },
  {
    valueId: "EV022361",
    valueDesc: "Heat collection plate",
    label: "EV022361: Heat collection plate"
  },
  {
    valueId: "EV022362",
    valueDesc: "Single-part",
    label: "EV022362: Single-part"
  },
  {
    valueId: "EV022363",
    valueDesc: "Deluge",
    label: "EV022363: Deluge"
  },
  {
    valueId: "EV022364",
    valueDesc: "Preaction",
    label: "EV022364: Preaction"
  },
  {
    valueId: "EV022367",
    valueDesc: "For T-piece",
    label: "EV022367: For T-piece"
  },
  {
    valueId: "EV022368",
    valueDesc: "Thermo-element",
    label: "EV022368: Thermo-element"
  },
  {
    valueId: "EV022369",
    valueDesc: "Tap",
    label: "EV022369: Tap"
  },
  {
    valueId: "EV022370",
    valueDesc: "Red/blue",
    label: "EV022370: Red/blue"
  },
  {
    valueId: "EV022371",
    valueDesc: "Degrees",
    label: "EV022371: Degrees"
  },
  {
    valueId: "EV022372",
    valueDesc: "Bamboo",
    label: "EV022372: Bamboo"
  },
  {
    valueId: "EV022373",
    valueDesc: "Walnut",
    label: "EV022373: Walnut"
  },
  {
    valueId: "EV022374",
    valueDesc: "Pear",
    label: "EV022374: Pear"
  },
  {
    valueId: "EV022377",
    valueDesc: "Floor/wall",
    label: "EV022377: Floor/wall"
  },
  {
    valueId: "EV022378",
    valueDesc: "Natural stone, solid",
    label: "EV022378: Natural stone, solid"
  },
  {
    valueId: "EV022379",
    valueDesc: "Granulate stone",
    label: "EV022379: Granulate stone"
  },
  {
    valueId: "EV022380",
    valueDesc: "Reed",
    label: "EV022380: Reed"
  },
  {
    valueId: "EV022381",
    valueDesc: "Fabric (textile)",
    label: "EV022381: Fabric (textile)"
  },
  {
    valueId: "EV022382",
    valueDesc: "Steel wire, plasticised",
    label: "EV022382: Steel wire, plasticised"
  },
  {
    valueId: "EV022383",
    valueDesc: "With front mirror",
    label: "EV022383: With front mirror"
  },
  {
    valueId: "EV022384",
    valueDesc: "With interior mirror",
    label: "EV022384: With interior mirror"
  },
  {
    valueId: "EV022385",
    valueDesc: "With front- and interior mirror",
    label: "EV022385: With front- and interior mirror"
  },
  {
    valueId: "EV022386",
    valueDesc: "Without mirror",
    label: "EV022386: Without mirror"
  },
  {
    valueId: "EV022387",
    valueDesc: "Top/tap hole",
    label: "EV022387: Top/tap hole"
  },
  {
    valueId: "EV022389",
    valueDesc: "Formed",
    label: "EV022389: Formed"
  },
  {
    valueId: "EV022390",
    valueDesc: "Sealed top",
    label: "EV022390: Sealed top"
  },
  {
    valueId: "EV022391",
    valueDesc: "With washbasin opening(s)",
    label: "EV022391: With washbasin opening(s)"
  },
  {
    valueId: "EV022392",
    valueDesc: "With integrated washbasin(s)",
    label: "EV022392: With integrated washbasin(s)"
  },
  {
    valueId: "EV022393",
    valueDesc: "With built-in washbasin(s)",
    label: "EV022393: With built-in washbasin(s)"
  },
  {
    valueId: "EV022394",
    valueDesc: "50/80 mm",
    label: "EV022394: 50/80 mm"
  },
  {
    valueId: "EV022395",
    valueDesc: "60/90 mm",
    label: "EV022395: 60/90 mm"
  },
  {
    valueId: "EV022396",
    valueDesc: "Two-way",
    label: "EV022396: Two-way"
  },
  {
    valueId: "EV022397",
    valueDesc: "Three-way",
    label: "EV022397: Three-way"
  },
  {
    valueId: "EV022398",
    valueDesc: "Vent trim",
    label: "EV022398: Vent trim"
  },
  {
    valueId: "EV022399",
    valueDesc: "Pressure relief trim",
    label: "EV022399: Pressure relief trim"
  },
  {
    valueId: "EV022400",
    valueDesc: "Water-motor alarm",
    label: "EV022400: Water-motor alarm"
  },
  {
    valueId: "EV022401",
    valueDesc: "Pilot light",
    label: "EV022401: Pilot light"
  },
  {
    valueId: "EV022402",
    valueDesc: "Gas block",
    label: "EV022402: Gas block"
  },
  {
    valueId: "EV022404",
    valueDesc: "Ignition",
    label: "EV022404: Ignition"
  },
  {
    valueId: "EV022405",
    valueDesc: "60x80 mm",
    label: "EV022405: 60x80 mm"
  },
  {
    valueId: "EV022406",
    valueDesc: "70x80 mm",
    label: "EV022406: 70x80 mm"
  },
  {
    valueId: "EV022407",
    valueDesc: "80x100 mm",
    label: "EV022407: 80x100 mm"
  },
  {
    valueId: "EV022408",
    valueDesc: "60x100 mm",
    label: "EV022408: 60x100 mm"
  },
  {
    valueId: "EV022410",
    valueDesc: "Bottom-centre",
    label: "EV022410: Bottom-centre"
  },
  {
    valueId: "EV022411",
    valueDesc: "Bottom-side",
    label: "EV022411: Bottom-side"
  },
  {
    valueId: "EV022412",
    valueDesc: "Stainless steel 306L (1.4404)",
    label: "EV022412: Stainless steel 306L (1.4404)"
  },
  {
    valueId: "EV022413",
    valueDesc: "Stainless steel 306 Ti (1.4571)",
    label: "EV022413: Stainless steel 306 Ti (1.4571)"
  },
  {
    valueId: "EV022415",
    valueDesc: "Packing gland",
    label: "EV022415: Packing gland"
  },
  {
    valueId: "EV022418",
    valueDesc: "Servomotor shelf",
    label: "EV022418: Servomotor shelf"
  },
  {
    valueId: "EV022419",
    valueDesc: "Auxiliary contact",
    label: "EV022419: Auxiliary contact"
  },
  {
    valueId: "EV022420",
    valueDesc: "Spindle heating",
    label: "EV022420: Spindle heating"
  },
  {
    valueId: "EV022421",
    valueDesc: "3-wire",
    label: "EV022421: 3-wire"
  },
  {
    valueId: "EV022422",
    valueDesc: "4-wire",
    label: "EV022422: 4-wire"
  },
  {
    valueId: "EV022424",
    valueDesc: "Recessed mounted tap",
    label: "EV022424: Recessed mounted tap"
  },
  {
    valueId: "EV022425",
    valueDesc: "Bell ear",
    label: "EV022425: Bell ear"
  },
  {
    valueId: "EV022426",
    valueDesc: "With gaskets",
    label: "EV022426: With gaskets"
  },
  {
    valueId: "EV022428",
    valueDesc: "Pressure gauge",
    label: "EV022428: Pressure gauge"
  },
  {
    valueId: "EV022429",
    valueDesc: "Immersion tube",
    label: "EV022429: Immersion tube"
  },
  {
    valueId: "EV022430",
    valueDesc: "Charging pump",
    label: "EV022430: Charging pump"
  },
  {
    valueId: "EV022431",
    valueDesc: "Control equipment",
    label: "EV022431: Control equipment"
  },
  {
    valueId: "EV022432",
    valueDesc: "Element",
    label: "EV022432: Element"
  },
  {
    valueId: "EV022433",
    valueDesc: "Coat",
    label: "EV022433: Coat"
  },
  {
    valueId: "EV022434",
    valueDesc: "Exchanger",
    label: "EV022434: Exchanger"
  },
  {
    valueId: "EV022435",
    valueDesc: "Spindle coupling",
    label: "EV022435: Spindle coupling"
  },
  {
    valueId: "EV022436",
    valueDesc: "Bottom plate extension",
    label: "EV022436: Bottom plate extension"
  },
  {
    valueId: "EV022437",
    valueDesc: "Shaft extension",
    label: "EV022437: Shaft extension"
  },
  {
    valueId: "EV022438",
    valueDesc: "Reversible clamp trestle",
    label: "EV022438: Reversible clamp trestle"
  },
  {
    valueId: "EV022439",
    valueDesc: "Valve lever",
    label: "EV022439: Valve lever"
  },
  {
    valueId: "EV022440",
    valueDesc: "Ball joint",
    label: "EV022440: Ball joint"
  },
  {
    valueId: "EV022441",
    valueDesc: "Drive lever",
    label: "EV022441: Drive lever"
  },
  {
    valueId: "EV022442",
    valueDesc: "Rod mounting set",
    label: "EV022442: Rod mounting set"
  },
  {
    valueId: "EV022443",
    valueDesc: "Setting indicator",
    label: "EV022443: Setting indicator"
  },
  {
    valueId: "EV022444",
    valueDesc: "Rotation angle limiter",
    label: "EV022444: Rotation angle limiter"
  },
  {
    valueId: "EV022445",
    valueDesc: "VAV controller",
    label: "EV022445: VAV controller"
  },
  {
    valueId: "EV022446",
    valueDesc: "Installation set for E box",
    label: "EV022446: Installation set for E box"
  },
  {
    valueId: "EV022447",
    valueDesc: "Installation set for partition",
    label: "EV022447: Installation set for partition"
  },
  {
    valueId: "EV022448",
    valueDesc: "Feed-through piece",
    label: "EV022448: Feed-through piece"
  },
  {
    valueId: "EV022449",
    valueDesc: "Measuring hose set",
    label: "EV022449: Measuring hose set"
  },
  {
    valueId: "EV022450",
    valueDesc: "Venturi tube",
    label: "EV022450: Venturi tube"
  },
  {
    valueId: "EV022451",
    valueDesc: "Watertight housing",
    label: "EV022451: Watertight housing"
  },
  {
    valueId: "EV022452",
    valueDesc: "Mounting console",
    label: "EV022452: Mounting console"
  },
  {
    valueId: "EV022453",
    valueDesc: "Test spray",
    label: "EV022453: Test spray"
  },
  {
    valueId: "EV022454",
    valueDesc: "Pot",
    label: "EV022454: Pot"
  },
  {
    valueId: "EV022457",
    valueDesc: "Screw-in clamp",
    label: "EV022457: Screw-in clamp"
  },
  {
    valueId: "EV022458",
    valueDesc: "EN 14420-3",
    label: "EV022458: EN 14420-3"
  },
  {
    valueId: "EV022459",
    valueDesc: "2.0",
    label: "EV022459: 2.0"
  },
  {
    valueId: "EV022460",
    valueDesc: "4.0",
    label: "EV022460: 4.0"
  },
  {
    valueId: "EV022461",
    valueDesc: "5.0",
    label: "EV022461: 5.0"
  },
  {
    valueId: "EV022462",
    valueDesc: "6.0",
    label: "EV022462: 6.0"
  },
  {
    valueId: "EV022463",
    valueDesc: "0 - 10 V / 4 - 20 mA",
    label: "EV022463: 0 - 10 V / 4 - 20 mA"
  },
  {
    valueId: "EV022465",
    valueDesc: "Set of couplings",
    label: "EV022465: Set of couplings"
  },
  {
    valueId: "EV022467",
    valueDesc: "18 - 30 V DC",
    label: "EV022467: 18 - 30 V DC"
  },
  {
    valueId: "EV022468",
    valueDesc: "With loop end",
    label: "EV022468: With loop end"
  },
  {
    valueId: "EV022469",
    valueDesc: "With stud end",
    label: "EV022469: With stud end"
  },
  {
    valueId: "EV022470",
    valueDesc: "With toggle end",
    label: "EV022470: With toggle end"
  },
  {
    valueId: "EV022471",
    valueDesc: "With snap hook end",
    label: "EV022471: With snap hook end"
  },
  {
    valueId: "EV022472",
    valueDesc: "With eyelet end",
    label: "EV022472: With eyelet end"
  },
  {
    valueId: "EV022473",
    valueDesc: "With 90° eyelet",
    label: "EV022473: With 90° eyelet"
  },
  {
    valueId: "EV022474",
    valueDesc: "With end stop",
    label: "EV022474: With end stop"
  },
  {
    valueId: "EV022475",
    valueDesc: "With barrel end",
    label: "EV022475: With barrel end"
  },
  {
    valueId: "EV022476",
    valueDesc: "Double ended hanger (Y)",
    label: "EV022476: Double ended hanger (Y)"
  },
  {
    valueId: "EV022477",
    valueDesc: "Threaded adapter",
    label: "EV022477: Threaded adapter"
  },
  {
    valueId: "EV022479",
    valueDesc: "Spring clip",
    label: "EV022479: Spring clip"
  },
  {
    valueId: "EV022480",
    valueDesc: "Beam and purlin clip",
    label: "EV022480: Beam and purlin clip"
  },
  {
    valueId: "EV022482",
    valueDesc: "Drop-in anchor",
    label: "EV022482: Drop-in anchor"
  },
  {
    valueId: "EV022483",
    valueDesc: "Eye bolt",
    label: "EV022483: Eye bolt"
  },
  {
    valueId: "EV022484",
    valueDesc: "Link hook",
    label: "EV022484: Link hook"
  },
  {
    valueId: "EV022485",
    valueDesc: "Ceiling fixing kit",
    label: "EV022485: Ceiling fixing kit"
  },
  {
    valueId: "EV022486",
    valueDesc: "Carabine hook",
    label: "EV022486: Carabine hook"
  },
  {
    valueId: "EV022487",
    valueDesc: "Corner saddle",
    label: "EV022487: Corner saddle"
  },
  {
    valueId: "EV022489",
    valueDesc: "Ceiling clip",
    label: "EV022489: Ceiling clip"
  },
  {
    valueId: "EV022490",
    valueDesc: "Tensioning tool",
    label: "EV022490: Tensioning tool"
  },
  {
    valueId: "EV022491",
    valueDesc: "Setting punch",
    label: "EV022491: Setting punch"
  },
  {
    valueId: "EV022492",
    valueDesc: "Protective sleeve tubing",
    label: "EV022492: Protective sleeve tubing"
  },
  {
    valueId: "EV022493",
    valueDesc: "Wire rope cutter",
    label: "EV022493: Wire rope cutter"
  },
  {
    valueId: "EV022496",
    valueDesc: "Ni 1000",
    label: "EV022496: Ni 1000"
  },
  {
    valueId: "EV022498",
    valueDesc: "Changeover construction set",
    label: "EV022498: Changeover construction set"
  },
  {
    valueId: "EV022499",
    valueDesc: "Cable locking spring",
    label: "EV022499: Cable locking spring"
  },
  {
    valueId: "EV022500",
    valueDesc: "Sensor holder",
    label: "EV022500: Sensor holder"
  },
  {
    valueId: "EV022501",
    valueDesc: "Terminal housing",
    label: "EV022501: Terminal housing"
  },
  {
    valueId: "EV022502",
    valueDesc: "Reducing nipple",
    label: "EV022502: Reducing nipple"
  },
  {
    valueId: "EV022503",
    valueDesc: "Water seal",
    label: "EV022503: Water seal"
  },
  {
    valueId: "EV022505",
    valueDesc: "Measuring probe",
    label: "EV022505: Measuring probe"
  },
  {
    valueId: "EV022506",
    valueDesc: "Cooling only",
    label: "EV022506: Cooling only"
  },
  {
    valueId: "EV022507",
    valueDesc: "Cooling and heating",
    label: "EV022507: Cooling and heating"
  },
  {
    valueId: "EV022508",
    valueDesc: "Ceiling suspended",
    label: "EV022508: Ceiling suspended"
  },
  {
    valueId: "EV022509",
    valueDesc: "Ceiling installation (duct)",
    label: "EV022509: Ceiling installation (duct)"
  },
  {
    valueId: "EV022510",
    valueDesc: "R290",
    label: "EV022510: R290"
  },
  {
    valueId: "EV022518",
    valueDesc: "Wall (high)",
    label: "EV022518: Wall (high)"
  },
  {
    valueId: "EV022519",
    valueDesc: "Wall (low)",
    label: "EV022519: Wall (low)"
  },
  {
    valueId: "EV022520",
    valueDesc: "Single-split",
    label: "EV022520: Single-split"
  },
  {
    valueId: "EV022521",
    valueDesc: "Multi-split",
    label: "EV022521: Multi-split"
  },
  {
    valueId: "EV022522",
    valueDesc: "Single-split/multi-split",
    label: "EV022522: Single-split/multi-split"
  },
  {
    valueId: "EV022523",
    valueDesc: "3 * 400 V AC +N",
    label: "EV022523: 3 * 400 V AC +N"
  },
  {
    valueId: "EV022524",
    valueDesc: "3/4 inch face-sealing",
    label: "EV022524: 3/4 inch face-sealing"
  },
  {
    valueId: "EV022525",
    valueDesc: "Shower curtain ring",
    label: "EV022525: Shower curtain ring"
  },
  {
    valueId: "EV022526",
    valueDesc: "Ceiling support for shower curtain rod",
    label: "EV022526: Ceiling support for shower curtain rod"
  },
  {
    valueId: "EV022527",
    valueDesc: "Supply",
    label: "EV022527: Supply"
  },
  {
    valueId: "EV022528",
    valueDesc: "Supply and drainage",
    label: "EV022528: Supply and drainage"
  },
  {
    valueId: "EV022529",
    valueDesc: "Rubber - Polychloroprene (PCP)",
    label: "EV022529: Rubber - Polychloroprene (PCP)"
  },
  {
    valueId: "EV022530",
    valueDesc: "External sensor",
    label: "EV022530: External sensor"
  },
  {
    valueId: "EV022535",
    valueDesc: "315 mm",
    label: "EV022535: 315 mm"
  },
  {
    valueId: "EV022536",
    valueDesc: "Controller for 230 V",
    label: "EV022536: Controller for 230 V"
  },
  {
    valueId: "EV022537",
    valueDesc: "Controller for 400 V",
    label: "EV022537: Controller for 400 V"
  },
  {
    valueId: "EV022538",
    valueDesc: "Room temperature sensor",
    label: "EV022538: Room temperature sensor"
  },
  {
    valueId: "EV022539",
    valueDesc: "Duct temperature sensor",
    label: "EV022539: Duct temperature sensor"
  },
  {
    valueId: "EV022540",
    valueDesc: "Control valve",
    label: "EV022540: Control valve"
  },
  {
    valueId: "EV022541",
    valueDesc: "Controller",
    label: "EV022541: Controller"
  },
  {
    valueId: "EV022542",
    valueDesc: "1/2 inch- 3/4 inch",
    label: "EV022542: 1/2 inch- 3/4 inch"
  },
  {
    valueId: "EV022543",
    valueDesc: "1/4 inch - 1/2 inch",
    label: "EV022543: 1/4 inch - 1/2 inch"
  },
  {
    valueId: "EV022544",
    valueDesc: "1/4 inch - 3/8 inch",
    label: "EV022544: 1/4 inch - 3/8 inch"
  },
  {
    valueId: "EV022545",
    valueDesc: "1/4 inch - 5/8 inch",
    label: "EV022545: 1/4 inch - 5/8 inch"
  },
  {
    valueId: "EV022546",
    valueDesc: "3/8 inch - 3/4 inch",
    label: "EV022546: 3/8 inch - 3/4 inch"
  },
  {
    valueId: "EV022547",
    valueDesc: "3/8 inch - 5/8 inch",
    label: "EV022547: 3/8 inch - 5/8 inch"
  },
  {
    valueId: "EV022548",
    valueDesc: "3/8 inch - 1/2 inch",
    label: "EV022548: 3/8 inch - 1/2 inch"
  },
  {
    valueId: "EV022549",
    valueDesc: "Couplings supplied separately",
    label: "EV022549: Couplings supplied separately"
  },
  {
    valueId: "EV022550",
    valueDesc: "Couplings preassembled on 1 side",
    label: "EV022550: Couplings preassembled on 1 side"
  },
  {
    valueId: "EV022551",
    valueDesc: "Couplings preassembled on 2 sides",
    label: "EV022551: Couplings preassembled on 2 sides"
  },
  {
    valueId: "EV022552",
    valueDesc: "2 x single",
    label: "EV022552: 2 x single"
  },
  {
    valueId: "EV022553",
    valueDesc: "Control panel/controller",
    label: "EV022553: Control panel/controller"
  },
  {
    valueId: "EV022554",
    valueDesc: "Outdoor unit grille",
    label: "EV022554: Outdoor unit grille"
  },
  {
    valueId: "EV022555",
    valueDesc: "Timer",
    label: "EV022555: Timer"
  },
  {
    valueId: "EV022556",
    valueDesc: "Interface adapter",
    label: "EV022556: Interface adapter"
  },
  {
    valueId: "EV022557",
    valueDesc: "Protective grille",
    label: "EV022557: Protective grille"
  },
  {
    valueId: "EV022558",
    valueDesc: "Setting transformer",
    label: "EV022558: Setting transformer"
  },
  {
    valueId: "EV022559",
    valueDesc: "Service module",
    label: "EV022559: Service module"
  },
  {
    valueId: "EV022560",
    valueDesc: "Insulation angle for duct",
    label: "EV022560: Insulation angle for duct"
  },
  {
    valueId: "EV022561",
    valueDesc: "Locking clip",
    label: "EV022561: Locking clip"
  },
  {
    valueId: "EV022562",
    valueDesc: "Bathtub",
    label: "EV022562: Bathtub"
  },
  {
    valueId: "EV022563",
    valueDesc: "(Foot) hip bath",
    label: "EV022563: (Foot) hip bath"
  },
  {
    valueId: "EV022564",
    valueDesc: "Bath/shower combination",
    label: "EV022564: Bath/shower combination"
  },
  {
    valueId: "EV022565",
    valueDesc: "Combination type (asymmetrical)",
    label: "EV022565: Combination type (asymmetrical)"
  },
  {
    valueId: "EV022566",
    valueDesc: "Foot end",
    label: "EV022566: Foot end"
  },
  {
    valueId: "EV022568",
    valueDesc: "Air and water",
    label: "EV022568: Air and water"
  },
  {
    valueId: "EV022569",
    valueDesc: "Bath",
    label: "EV022569: Bath"
  },
  {
    valueId: "EV022570",
    valueDesc: "Shower tray",
    label: "EV022570: Shower tray"
  },
  {
    valueId: "EV022571",
    valueDesc: "Front panel",
    label: "EV022571: Front panel"
  },
  {
    valueId: "EV022572",
    valueDesc: "Side panel",
    label: "EV022572: Side panel"
  },
  {
    valueId: "EV022574",
    valueDesc: "Front skirting",
    label: "EV022574: Front skirting"
  },
  {
    valueId: "EV022575",
    valueDesc: "Side skirting",
    label: "EV022575: Side skirting"
  },
  {
    valueId: "EV022576",
    valueDesc: "Frame",
    label: "EV022576: Frame"
  },
  {
    valueId: "EV022577",
    valueDesc: "Legs (set)",
    label: "EV022577: Legs (set)"
  },
  {
    valueId: "EV022578",
    valueDesc: "Mounted on bath tap",
    label: "EV022578: Mounted on bath tap"
  },
  {
    valueId: "EV022580",
    valueDesc: "Transformer",
    label: "EV022580: Transformer"
  },
  {
    valueId: "EV022581",
    valueDesc: "Tie rod (pull handle)",
    label: "EV022581: Tie rod (pull handle)"
  },
  {
    valueId: "EV022582",
    valueDesc: "Push bar (push handle)",
    label: "EV022582: Push bar (push handle)"
  },
  {
    valueId: "EV022583",
    valueDesc: "4-hole",
    label: "EV022583: 4-hole"
  },
  {
    valueId: "EV022584",
    valueDesc: "Symbol",
    label: "EV022584: Symbol"
  },
  {
    valueId: "EV022585",
    valueDesc: "Operating cable",
    label: "EV022585: Operating cable"
  },
  {
    valueId: "EV022586",
    valueDesc: "Signal light",
    label: "EV022586: Signal light"
  },
  {
    valueId: "EV022587",
    valueDesc: "Shut-off valve",
    label: "EV022587: Shut-off valve"
  },
  {
    valueId: "EV022588",
    valueDesc: "Plastic/glass",
    label: "EV022588: Plastic/glass"
  },
  {
    valueId: "EV022589",
    valueDesc: "Aluminium/glass",
    label: "EV022589: Aluminium/glass"
  },
  {
    valueId: "EV022590",
    valueDesc: "Wall and corner",
    label: "EV022590: Wall and corner"
  },
  {
    valueId: "EV022591",
    valueDesc: "Touch control/push button",
    label: "EV022591: Touch control/push button"
  },
  {
    valueId: "EV022592",
    valueDesc: "IP59K",
    label: "EV022592: IP59K"
  },
  {
    valueId: "EV022594",
    valueDesc: "With outlet",
    label: "EV022594: With outlet"
  },
  {
    valueId: "EV022595",
    valueDesc: "With outlet and washing-up shower connection",
    label: "EV022595: With outlet and washing-up shower connection"
  },
  {
    valueId: "EV022596",
    valueDesc: "With washing-up shower and outlet",
    label: "EV022596: With washing-up shower and outlet"
  },
  {
    valueId: "EV022597",
    valueDesc: "With washing-up shower",
    label: "EV022597: With washing-up shower"
  },
  {
    valueId: "EV022599",
    valueDesc: "Wall and top",
    label: "EV022599: Wall and top"
  },
  {
    valueId: "EV022600",
    valueDesc: "Revision set",
    label: "EV022600: Revision set"
  },
  {
    valueId: "EV022601",
    valueDesc: "Non-return valve",
    label: "EV022601: Non-return valve"
  },
  {
    valueId: "EV022602",
    valueDesc: "Floor support control unit",
    label: "EV022602: Floor support control unit"
  },
  {
    valueId: "EV022603",
    valueDesc: "Hog's bristle",
    label: "EV022603: Hog's bristle"
  },
  {
    valueId: "EV022604",
    valueDesc: "Bakelite",
    label: "EV022604: Bakelite"
  },
  {
    valueId: "EV022605",
    valueDesc: "Bonnet",
    label: "EV022605: Bonnet"
  },
  {
    valueId: "EV022608",
    valueDesc: "DN 16",
    label: "EV022608: DN 16"
  },
  {
    valueId: "EV022609",
    valueDesc: "5/8 inch (16)",
    label: "EV022609: 5/8 inch (16)"
  },
  {
    valueId: "EV022610",
    valueDesc: "115 V AC",
    label: "EV022610: 115 V AC"
  },
  {
    valueId: "EV022611",
    valueDesc: "12 - 48 V DC / 15 - 48 V AC",
    label: "EV022611: 12 - 48 V DC / 15 - 48 V AC"
  },
  {
    valueId: "EV022612",
    valueDesc: "80 - 240 V AC/DC",
    label: "EV022612: 80 - 240 V AC/DC"
  },
  {
    valueId: "EV022613",
    valueDesc: "Pump",
    label: "EV022613: Pump"
  },
  {
    valueId: "EV022614",
    valueDesc: "Pump pot",
    label: "EV022614: Pump pot"
  },
  {
    valueId: "EV022615",
    valueDesc: "Perrot clamp coupling",
    label: "EV022615: Perrot clamp coupling"
  },
  {
    valueId: "EV022616",
    valueDesc: "Bauer clamp coupling",
    label: "EV022616: Bauer clamp coupling"
  },
  {
    valueId: "EV022617",
    valueDesc: "DIN 86111",
    label: "EV022617: DIN 86111"
  },
  {
    valueId: "EV022618",
    valueDesc: "DIN 86112",
    label: "EV022618: DIN 86112"
  },
  {
    valueId: "EV022619",
    valueDesc: "DIN 86121",
    label: "EV022619: DIN 86121"
  },
  {
    valueId: "EV022621",
    valueDesc: "ISO 5211",
    label: "EV022621: ISO 5211"
  },
  {
    valueId: "EV022622",
    valueDesc: "Sealing ring",
    label: "EV022622: Sealing ring"
  },
  {
    valueId: "EV022623",
    valueDesc: "Cap",
    label: "EV022623: Cap"
  },
  {
    valueId: "EV022624",
    valueDesc: "DIN 86129",
    label: "EV022624: DIN 86129"
  },
  {
    valueId: "EV022625",
    valueDesc: "Ohasil",
    label: "EV022625: Ohasil"
  },
  {
    valueId: "EV022626",
    valueDesc: "Sigma flange",
    label: "EV022626: Sigma flange"
  },
  {
    valueId: "EV022627",
    valueDesc: "Clamp scale coupling flange",
    label: "EV022627: Clamp scale coupling flange"
  },
  {
    valueId: "EV022628",
    valueDesc: "150 lbs",
    label: "EV022628: 150 lbs"
  },
  {
    valueId: "EV022629",
    valueDesc: "300 lbs",
    label: "EV022629: 300 lbs"
  },
  {
    valueId: "EV022630",
    valueDesc: "600 lbs",
    label: "EV022630: 600 lbs"
  },
  {
    valueId: "EV022631",
    valueDesc: "800 lbs",
    label: "EV022631: 800 lbs"
  },
  {
    valueId: "EV022632",
    valueDesc: "Stainless steel 316 (1.4408)",
    label: "EV022632: Stainless steel 316 (1.4408)"
  },
  {
    valueId: "EV022633",
    valueDesc: "ASTM A105N",
    label: "EV022633: ASTM A105N"
  },
  {
    valueId: "EV022634",
    valueDesc: "ASTM A216 WCB",
    label: "EV022634: ASTM A216 WCB"
  },
  {
    valueId: "EV022636",
    valueDesc: "Para rubber",
    label: "EV022636: Para rubber"
  },
  {
    valueId: "EV022641",
    valueDesc: "ASTM A351 CF8M",
    label: "EV022641: ASTM A351 CF8M"
  },
  {
    valueId: "EV022642",
    valueDesc: "PDCPD",
    label: "EV022642: PDCPD"
  },
  {
    valueId: "EV022643",
    valueDesc: "PN 6/10/16",
    label: "EV022643: PN 6/10/16"
  },
  {
    valueId: "EV022644",
    valueDesc: "Shaft",
    label: "EV022644: Shaft"
  },
  {
    valueId: "EV022645",
    valueDesc: "1000 PSI WOG",
    label: "EV022645: 1000 PSI WOG"
  },
  {
    valueId: "EV022646",
    valueDesc: "1500 PSI WOG",
    label: "EV022646: 1500 PSI WOG"
  },
  {
    valueId: "EV022647",
    valueDesc: "2000 PSI WOG",
    label: "EV022647: 2000 PSI WOG"
  },
  {
    valueId: "EV022648",
    valueDesc: "DN 220",
    label: "EV022648: DN 220"
  },
  {
    valueId: "EV022649",
    valueDesc: "JIS 10K",
    label: "EV022649: JIS 10K"
  },
  {
    valueId: "EV022651",
    valueDesc: "Vulkollan",
    label: "EV022651: Vulkollan"
  },
  {
    valueId: "EV022652",
    valueDesc: "CuZn36Pb2As (CW602N)",
    label: "EV022652: CuZn36Pb2As (CW602N)"
  },
  {
    valueId: "EV022653",
    valueDesc: "JIS 5K",
    label: "EV022653: JIS 5K"
  },
  {
    valueId: "EV022655",
    valueDesc: "PVC-HI",
    label: "EV022655: PVC-HI"
  },
  {
    valueId: "EV022656",
    valueDesc: "ASTM A126 Grade B",
    label: "EV022656: ASTM A126 Grade B"
  },
  {
    valueId: "EV022657",
    valueDesc: "ASTM A182 F5",
    label: "EV022657: ASTM A182 F5"
  },
  {
    valueId: "EV022658",
    valueDesc: "ASTM A182 F11",
    label: "EV022658: ASTM A182 F11"
  },
  {
    valueId: "EV022659",
    valueDesc: "ASTM A350 LF2",
    label: "EV022659: ASTM A350 LF2"
  },
  {
    valueId: "EV022660",
    valueDesc: "ASTM B61",
    label: "EV022660: ASTM B61"
  },
  {
    valueId: "EV022661",
    valueDesc: "ASTM B62",
    label: "EV022661: ASTM B62"
  },
  {
    valueId: "EV022662",
    valueDesc: "ASTM A182 F51",
    label: "EV022662: ASTM A182 F51"
  },
  {
    valueId: "EV022663",
    valueDesc: "2 3/8 inch",
    label: "EV022663: 2 3/8 inch"
  },
  {
    valueId: "EV022665",
    valueDesc: "Paddle wheel",
    label: "EV022665: Paddle wheel"
  },
  {
    valueId: "EV022666",
    valueDesc: "Turbine",
    label: "EV022666: Turbine"
  },
  {
    valueId: "EV022667",
    valueDesc: "Stainless steel duplex (1.4462)",
    label: "EV022667: Stainless steel duplex (1.4462)"
  },
  {
    valueId: "EV022668",
    valueDesc: "Incoloy 800 (1.4876)",
    label: "EV022668: Incoloy 800 (1.4876)"
  },
  {
    valueId: "EV022669",
    valueDesc: "Incoloy 825 (2.4858)",
    label: "EV022669: Incoloy 825 (2.4858)"
  },
  {
    valueId: "EV022670",
    valueDesc: "Stainless steel 304 L (1.4304)",
    label: "EV022670: Stainless steel 304 L (1.4304)"
  },
  {
    valueId: "EV022671",
    valueDesc: "Titanium G1 (3.7025)",
    label: "EV022671: Titanium G1 (3.7025)"
  },
  {
    valueId: "EV022672",
    valueDesc: "Titanium G2 (3.7035)",
    label: "EV022672: Titanium G2 (3.7035)"
  },
  {
    valueId: "EV022673",
    valueDesc: "Mica",
    label: "EV022673: Mica"
  },
  {
    valueId: "EV022674",
    valueDesc: "Inner cone 12°",
    label: "EV022674: Inner cone 12°"
  },
  {
    valueId: "EV022675",
    valueDesc: "Internal thread NSFHT",
    label: "EV022675: Internal thread NSFHT"
  },
  {
    valueId: "EV022676",
    valueDesc: "Internal thread UNI - 45",
    label: "EV022676: Internal thread UNI - 45"
  },
  {
    valueId: "EV022677",
    valueDesc: "Internal thread UNI - 70",
    label: "EV022677: Internal thread UNI - 70"
  },
  {
    valueId: "EV022678",
    valueDesc: "Internal thread food products",
    label: "EV022678: Internal thread food products"
  },
  {
    valueId: "EV022679",
    valueDesc: "External thread food products",
    label: "EV022679: External thread food products"
  },
  {
    valueId: "EV022681",
    valueDesc: "Lever",
    label: "EV022681: Lever"
  },
  {
    valueId: "EV022682",
    valueDesc: "Build-on flange",
    label: "EV022682: Build-on flange"
  },
  {
    valueId: "EV022683",
    valueDesc: "10000 PSI WOG",
    label: "EV022683: 10000 PSI WOG"
  },
  {
    valueId: "EV022684",
    valueDesc: "200 lbs",
    label: "EV022684: 200 lbs"
  },
  {
    valueId: "EV022685",
    valueDesc: "1500 lbs",
    label: "EV022685: 1500 lbs"
  },
  {
    valueId: "EV022686",
    valueDesc: "3000 PSI WOG",
    label: "EV022686: 3000 PSI WOG"
  },
  {
    valueId: "EV022687",
    valueDesc: "5000 PSI WOG",
    label: "EV022687: 5000 PSI WOG"
  },
  {
    valueId: "EV022688",
    valueDesc: "JIS 16K",
    label: "EV022688: JIS 16K"
  },
  {
    valueId: "EV022690",
    valueDesc: "Steel GP240GH N (1.0619)",
    label: "EV022690: Steel GP240GH N (1.0619)"
  },
  {
    valueId: "EV022691",
    valueDesc: "Steel P250GH (1.0460)",
    label: "EV022691: Steel P250GH (1.0460)"
  },
  {
    valueId: "EV022692",
    valueDesc: "Soft iron non galvanized",
    label: "EV022692: Soft iron non galvanized"
  },
  {
    valueId: "EV022694",
    valueDesc: "Stainless steel 316 (1.4409)",
    label: "EV022694: Stainless steel 316 (1.4409)"
  },
  {
    valueId: "EV022695",
    valueDesc: "Weight",
    label: "EV022695: Weight"
  },
  {
    valueId: "EV022696",
    valueDesc: "200 PSI WOG",
    label: "EV022696: 200 PSI WOG"
  },
  {
    valueId: "EV022697",
    valueDesc: "Displacer",
    label: "EV022697: Displacer"
  },
  {
    valueId: "EV022698",
    valueDesc: "PN 420",
    label: "EV022698: PN 420"
  },
  {
    valueId: "EV022700",
    valueDesc: "0 - 600 mbar",
    label: "EV022700: 0 - 600 mbar"
  },
  {
    valueId: "EV022701",
    valueDesc: "-100 - 150 mbar",
    label: "EV022701: -100 - 150 mbar"
  },
  {
    valueId: "EV022702",
    valueDesc: "-20 - 20 mbar",
    label: "EV022702: -20 - 20 mbar"
  },
  {
    valueId: "EV022703",
    valueDesc: "PVDF-EL",
    label: "EV022703: PVDF-EL"
  },
  {
    valueId: "EV022704",
    valueDesc: "PTFE-EL",
    label: "EV022704: PTFE-EL"
  },
  {
    valueId: "EV022705",
    valueDesc: "PN 125",
    label: "EV022705: PN 125"
  },
  {
    valueId: "EV022709",
    valueDesc: "UHMPE",
    label: "EV022709: UHMPE"
  },
  {
    valueId: "EV022710",
    valueDesc: "Lug type",
    label: "EV022710: Lug type"
  },
  {
    valueId: "EV022711",
    valueDesc: "ASTM A216 WCC",
    label: "EV022711: ASTM A216 WCC"
  },
  {
    valueId: "EV022712",
    valueDesc: "ASTM A352 LCB",
    label: "EV022712: ASTM A352 LCB"
  },
  {
    valueId: "EV022713",
    valueDesc: "ASTM A352 LCC",
    label: "EV022713: ASTM A352 LCC"
  },
  {
    valueId: "EV022714",
    valueDesc: "Tri-clamp",
    label: "EV022714: Tri-clamp"
  },
  {
    valueId: "EV022715",
    valueDesc: "DN 14",
    label: "EV022715: DN 14"
  },
  {
    valueId: "EV022717",
    valueDesc: "Pull mechanism",
    label: "EV022717: Pull mechanism"
  },
  {
    valueId: "EV022718",
    valueDesc: "125 lbs",
    label: "EV022718: 125 lbs"
  },
  {
    valueId: "EV022719",
    valueDesc: "JIS 20K",
    label: "EV022719: JIS 20K"
  },
  {
    valueId: "EV022720",
    valueDesc: "JIS 40K",
    label: "EV022720: JIS 40K"
  },
  {
    valueId: "EV022721",
    valueDesc: "PN 200",
    label: "EV022721: PN 200"
  },
  {
    valueId: "EV022722",
    valueDesc: "PN 50",
    label: "EV022722: PN 50"
  },
  {
    valueId: "EV022723",
    valueDesc: "PN 500",
    label: "EV022723: PN 500"
  },
  {
    valueId: "EV022724",
    valueDesc: "PN 630",
    label: "EV022724: PN 630"
  },
  {
    valueId: "EV022725",
    valueDesc: "15Mo3 (1.5415)",
    label: "EV022725: 15Mo3 (1.5415)"
  },
  {
    valueId: "EV022726",
    valueDesc: "Stainless steel 309 (1.4828)",
    label: "EV022726: Stainless steel 309 (1.4828)"
  },
  {
    valueId: "EV022729",
    valueDesc: "Stainless steel 410 (1.4006)",
    label: "EV022729: Stainless steel 410 (1.4006)"
  },
  {
    valueId: "EV022733",
    valueDesc: "CuNiFe",
    label: "EV022733: CuNiFe"
  },
  {
    valueId: "EV022734",
    valueDesc: "Triclover flange",
    label: "EV022734: Triclover flange"
  },
  {
    valueId: "EV022735",
    valueDesc: "Chemraz",
    label: "EV022735: Chemraz"
  },
  {
    valueId: "EV022737",
    valueDesc: "3 %",
    label: "EV022737: 3 %"
  },
  {
    valueId: "EV022738",
    valueDesc: "0.5 - 2.5 V",
    label: "EV022738: 0.5 - 2.5 V"
  },
  {
    valueId: "EV022745",
    valueDesc: "Hostaflon",
    label: "EV022745: Hostaflon"
  },
  {
    valueId: "EV022747",
    valueDesc: "0 - 0.025 bar",
    label: "EV022747: 0 - 0.025 bar"
  },
  {
    valueId: "EV022748",
    valueDesc: "0 - 0.04 bar",
    label: "EV022748: 0 - 0.04 bar"
  },
  {
    valueId: "EV022749",
    valueDesc: "0 - 0.06 bar",
    label: "EV022749: 0 - 0.06 bar"
  },
  {
    valueId: "EV022750",
    valueDesc: "0 - 0.1 bar",
    label: "EV022750: 0 - 0.1 bar"
  },
  {
    valueId: "EV022751",
    valueDesc: "0 - 0.12 bar",
    label: "EV022751: 0 - 0.12 bar"
  },
  {
    valueId: "EV022752",
    valueDesc: "0 - 0.16 bar",
    label: "EV022752: 0 - 0.16 bar"
  },
  {
    valueId: "EV022753",
    valueDesc: "0 - 0.2 bar",
    label: "EV022753: 0 - 0.2 bar"
  },
  {
    valueId: "EV022754",
    valueDesc: "0 - 0.25 bar",
    label: "EV022754: 0 - 0.25 bar"
  },
  {
    valueId: "EV022755",
    valueDesc: "0 - 12 bar",
    label: "EV022755: 0 - 12 bar"
  },
  {
    valueId: "EV022756",
    valueDesc: "0 - 2 bar",
    label: "EV022756: 0 - 2 bar"
  },
  {
    valueId: "EV022757",
    valueDesc: "0 - 7 bar",
    label: "EV022757: 0 - 7 bar"
  },
  {
    valueId: "EV022758",
    valueDesc: "-0.01 - 0 bar",
    label: "EV022758: -0.01 - 0 bar"
  },
  {
    valueId: "EV022759",
    valueDesc: "-1 - 12 bar",
    label: "EV022759: -1 - 12 bar"
  },
  {
    valueId: "EV022760",
    valueDesc: "-1 - 25 bar",
    label: "EV022760: -1 - 25 bar"
  },
  {
    valueId: "EV022761",
    valueDesc: "-1 - 30 bar",
    label: "EV022761: -1 - 30 bar"
  },
  {
    valueId: "EV022764",
    valueDesc: "2 %",
    label: "EV022764: 2 %"
  },
  {
    valueId: "EV022775",
    valueDesc: "Thimble with lip ring",
    label: "EV022775: Thimble with lip ring"
  },
  {
    valueId: "EV022776",
    valueDesc: "60/80 mm",
    label: "EV022776: 60/80 mm"
  },
  {
    valueId: "EV022778",
    valueDesc: "Broom",
    label: "EV022778: Broom"
  },
  {
    valueId: "EV022779",
    valueDesc: "Spade",
    label: "EV022779: Spade"
  },
  {
    valueId: "EV022780",
    valueDesc: "Shovel",
    label: "EV022780: Shovel"
  },
  {
    valueId: "EV022781",
    valueDesc: "Spray can",
    label: "EV022781: Spray can"
  },
  {
    valueId: "EV022783",
    valueDesc: "Inconel",
    label: "EV022783: Inconel"
  },
  {
    valueId: "EV022784",
    valueDesc: "Half sphere-shape",
    label: "EV022784: Half sphere-shape"
  },
  {
    valueId: "EV022785",
    valueDesc: "Kalrez",
    label: "EV022785: Kalrez"
  },
  {
    valueId: "EV022786",
    valueDesc: "Powder",
    label: "EV022786: Powder"
  },
  {
    valueId: "EV022787",
    valueDesc: "Impeller",
    label: "EV022787: Impeller"
  },
  {
    valueId: "EV022788",
    valueDesc: "DIN 16282",
    label: "EV022788: DIN 16282"
  },
  {
    valueId: "EV022789",
    valueDesc: "No energy",
    label: "EV022789: No energy"
  },
  {
    valueId: "EV022792",
    valueDesc: "EA",
    label: "EV022792: EA"
  },
  {
    valueId: "EV022795",
    valueDesc: "DIN 11851",
    label: "EV022795: DIN 11851"
  },
  {
    valueId: "EV022796",
    valueDesc: "DIN 11861",
    label: "EV022796: DIN 11861"
  },
  {
    valueId: "EV022797",
    valueDesc: "DIN 11864",
    label: "EV022797: DIN 11864"
  },
  {
    valueId: "EV022798",
    valueDesc: "DIN 8542",
    label: "EV022798: DIN 8542"
  },
  {
    valueId: "EV022799",
    valueDesc: "ISO 2853",
    label: "EV022799: ISO 2853"
  },
  {
    valueId: "EV022800",
    valueDesc: "Pressure seal",
    label: "EV022800: Pressure seal"
  },
  {
    valueId: "EV022801",
    valueDesc: "Lights",
    label: "EV022801: Lights"
  },
  {
    valueId: "EV022802",
    valueDesc: "Slide/spring rings",
    label: "EV022802: Slide/spring rings"
  },
  {
    valueId: "EV022803",
    valueDesc: "Valve and spindle",
    label: "EV022803: Valve and spindle"
  },
  {
    valueId: "EV022804",
    valueDesc: "Spindle-bellow",
    label: "EV022804: Spindle-bellow"
  },
  {
    valueId: "EV022805",
    valueDesc: "Sieve",
    label: "EV022805: Sieve"
  },
  {
    valueId: "EV022806",
    valueDesc: "Connection block",
    label: "EV022806: Connection block"
  },
  {
    valueId: "EV022807",
    valueDesc: "Fall pipe",
    label: "EV022807: Fall pipe"
  },
  {
    valueId: "EV022808",
    valueDesc: "Motor",
    label: "EV022808: Motor"
  },
  {
    valueId: "EV022809",
    valueDesc: "Sledge",
    label: "EV022809: Sledge"
  },
  {
    valueId: "EV022810",
    valueDesc: "Heating",
    label: "EV022810: Heating"
  },
  {
    valueId: "EV022811",
    valueDesc: "Sift basket",
    label: "EV022811: Sift basket"
  },
  {
    valueId: "EV022812",
    valueDesc: "Bucket filter, single",
    label: "EV022812: Bucket filter, single"
  },
  {
    valueId: "EV022813",
    valueDesc: "Bucket filter, double",
    label: "EV022813: Bucket filter, double"
  },
  {
    valueId: "EV022814",
    valueDesc: "Y-filter",
    label: "EV022814: Y-filter"
  },
  {
    valueId: "EV022815",
    valueDesc: "Self-cleaning filter",
    label: "EV022815: Self-cleaning filter"
  },
  {
    valueId: "EV022816",
    valueDesc: "Interclamp filter (temporarily)",
    label: "EV022816: Interclamp filter (temporarily)"
  },
  {
    valueId: "EV022817",
    valueDesc: "Strainer (maritime)",
    label: "EV022817: Strainer (maritime)"
  },
  {
    valueId: "EV022818",
    valueDesc: "Suction strainer",
    label: "EV022818: Suction strainer"
  },
  {
    valueId: "EV022819",
    valueDesc: "DN 15 - DN 20",
    label: "EV022819: DN 15 - DN 20"
  },
  {
    valueId: "EV022820",
    valueDesc: "DN 20 - DN 25",
    label: "EV022820: DN 20 - DN 25"
  },
  {
    valueId: "EV022821",
    valueDesc: "DN 32 - DN 40 - DN 50",
    label: "EV022821: DN 32 - DN 40 - DN 50"
  },
  {
    valueId: "EV022822",
    valueDesc: "Cu/Ni",
    label: "EV022822: Cu/Ni"
  },
  {
    valueId: "EV022823",
    valueDesc: "Sight flow glass",
    label: "EV022823: Sight flow glass"
  },
  {
    valueId: "EV022824",
    valueDesc: "Pedal",
    label: "EV022824: Pedal"
  },
  {
    valueId: "EV022825",
    valueDesc: "Steering head cover",
    label: "EV022825: Steering head cover"
  },
  {
    valueId: "EV022826",
    valueDesc: "Sealing cup",
    label: "EV022826: Sealing cup"
  },
  {
    valueId: "EV022827",
    valueDesc: "Sealing ball",
    label: "EV022827: Sealing ball"
  },
  {
    valueId: "EV022828",
    valueDesc: "Ring gag",
    label: "EV022828: Ring gag"
  },
  {
    valueId: "EV022829",
    valueDesc: "One point",
    label: "EV022829: One point"
  },
  {
    valueId: "EV022830",
    valueDesc: "Four point",
    label: "EV022830: Four point"
  },
  {
    valueId: "EV022831",
    valueDesc: "EEx d IIC T6",
    label: "EV022831: EEx d IIC T6"
  },
  {
    valueId: "EV022832",
    valueDesc: "EEx ia IIB T4",
    label: "EV022832: EEx ia IIB T4"
  },
  {
    valueId: "EV022833",
    valueDesc: "EEx ia IIB T5",
    label: "EV022833: EEx ia IIB T5"
  },
  {
    valueId: "EV022834",
    valueDesc: "EEx ia IIB T6",
    label: "EV022834: EEx ia IIB T6"
  },
  {
    valueId: "EV022835",
    valueDesc: "EEx ia IIC T4",
    label: "EV022835: EEx ia IIC T4"
  },
  {
    valueId: "EV022836",
    valueDesc: "EEx ia IIC T5",
    label: "EV022836: EEx ia IIC T5"
  },
  {
    valueId: "EV022837",
    valueDesc: "EEx ia IIC T6",
    label: "EV022837: EEx ia IIC T6"
  },
  {
    valueId: "EV022838",
    valueDesc: "Stop plate",
    label: "EV022838: Stop plate"
  },
  {
    valueId: "EV022839",
    valueDesc: "Friction ring",
    label: "EV022839: Friction ring"
  },
  {
    valueId: "EV022840",
    valueDesc: "Rectifier",
    label: "EV022840: Rectifier"
  },
  {
    valueId: "EV022842",
    valueDesc: "Lift magnet",
    label: "EV022842: Lift magnet"
  },
  {
    valueId: "EV022843",
    valueDesc: "Kern course",
    label: "EV022843: Kern course"
  },
  {
    valueId: "EV022844",
    valueDesc: "Magnetic core",
    label: "EV022844: Magnetic core"
  },
  {
    valueId: "EV022845",
    valueDesc: "Throttle valve",
    label: "EV022845: Throttle valve"
  },
  {
    valueId: "EV022846",
    valueDesc: "Top part",
    label: "EV022846: Top part"
  },
  {
    valueId: "EV022847",
    valueDesc: "Built-in cabinet",
    label: "EV022847: Built-in cabinet"
  },
  {
    valueId: "EV022848",
    valueDesc: "Cone",
    label: "EV022848: Cone"
  },
  {
    valueId: "EV022849",
    valueDesc: "Extension pipe",
    label: "EV022849: Extension pipe"
  },
  {
    valueId: "EV022850",
    valueDesc: "Magnetic holder",
    label: "EV022850: Magnetic holder"
  },
  {
    valueId: "EV022851",
    valueDesc: "Conduction pin",
    label: "EV022851: Conduction pin"
  },
  {
    valueId: "EV022853",
    valueDesc: "Connection pipe",
    label: "EV022853: Connection pipe"
  },
  {
    valueId: "EV022854",
    valueDesc: "Piston",
    label: "EV022854: Piston"
  },
  {
    valueId: "EV022855",
    valueDesc: "Level plug",
    label: "EV022855: Level plug"
  },
  {
    valueId: "EV022856",
    valueDesc: "Level pipe cap",
    label: "EV022856: Level pipe cap"
  },
  {
    valueId: "EV022857",
    valueDesc: "Level pipe cap - deck plug",
    label: "EV022857: Level pipe cap - deck plug"
  },
  {
    valueId: "EV022858",
    valueDesc: "Valve",
    label: "EV022858: Valve"
  },
  {
    valueId: "EV022859",
    valueDesc: "Lantern",
    label: "EV022859: Lantern"
  },
  {
    valueId: "EV022860",
    valueDesc: "Driveshaft",
    label: "EV022860: Driveshaft"
  },
  {
    valueId: "EV022861",
    valueDesc: "Emergency manual operation",
    label: "EV022861: Emergency manual operation"
  },
  {
    valueId: "EV022862",
    valueDesc: "Lining",
    label: "EV022862: Lining"
  },
  {
    valueId: "EV022863",
    valueDesc: "Damping plate",
    label: "EV022863: Damping plate"
  },
  {
    valueId: "EV022864",
    valueDesc: "Signal key",
    label: "EV022864: Signal key"
  },
  {
    valueId: "EV022865",
    valueDesc: "Gear wheel box",
    label: "EV022865: Gear wheel box"
  },
  {
    valueId: "EV022866",
    valueDesc: "Vibration damper",
    label: "EV022866: Vibration damper"
  },
  {
    valueId: "EV022867",
    valueDesc: "Heating element",
    label: "EV022867: Heating element"
  },
  {
    valueId: "EV022868",
    valueDesc: "Valve and seat",
    label: "EV022868: Valve and seat"
  },
  {
    valueId: "EV022869",
    valueDesc: "Guiding plate",
    label: "EV022869: Guiding plate"
  },
  {
    valueId: "EV022870",
    valueDesc: "Setting button",
    label: "EV022870: Setting button"
  },
  {
    valueId: "EV022871",
    valueDesc: "Set-screw",
    label: "EV022871: Set-screw"
  },
  {
    valueId: "EV022872",
    valueDesc: "Valve disc",
    label: "EV022872: Valve disc"
  },
  {
    valueId: "EV022873",
    valueDesc: "Float ball",
    label: "EV022873: Float ball"
  },
  {
    valueId: "EV022875",
    valueDesc: "Inner ring",
    label: "EV022875: Inner ring"
  },
  {
    valueId: "EV022876",
    valueDesc: "Outer ring",
    label: "EV022876: Outer ring"
  },
  {
    valueId: "EV022877",
    valueDesc: "Centre ring",
    label: "EV022877: Centre ring"
  },
  {
    valueId: "EV022878",
    valueDesc: "Bordel",
    label: "EV022878: Bordel"
  },
  {
    valueId: "EV022879",
    valueDesc: "Lay on",
    label: "EV022879: Lay on"
  },
  {
    valueId: "EV022880",
    valueDesc: "Steam valve",
    label: "EV022880: Steam valve"
  },
  {
    valueId: "EV022881",
    valueDesc: "Spring set",
    label: "EV022881: Spring set"
  },
  {
    valueId: "EV022882",
    valueDesc: "Lighting",
    label: "EV022882: Lighting"
  },
  {
    valueId: "EV022883",
    valueDesc: "Borosilicate",
    label: "EV022883: Borosilicate"
  },
  {
    valueId: "EV022884",
    valueDesc: "Soda lime",
    label: "EV022884: Soda lime"
  },
  {
    valueId: "EV022885",
    valueDesc: "Cover gasket",
    label: "EV022885: Cover gasket"
  },
  {
    valueId: "EV022887",
    valueDesc: "Adjusting set pin",
    label: "EV022887: Adjusting set pin"
  },
  {
    valueId: "EV022888",
    valueDesc: "Water separator",
    label: "EV022888: Water separator"
  },
  {
    valueId: "EV022889",
    valueDesc: "BS 5156",
    label: "EV022889: BS 5156"
  },
  {
    valueId: "EV022890",
    valueDesc: "EN 558-1, 1",
    label: "EV022890: EN 558-1, 1"
  },
  {
    valueId: "EV022891",
    valueDesc: "PPG",
    label: "EV022891: PPG"
  },
  {
    valueId: "EV022892",
    valueDesc: "Plug EN 175301-803 type A",
    label: "EV022892: Plug EN 175301-803 type A"
  },
  {
    valueId: "EV022893",
    valueDesc: "Plug EN 175301-803 type B",
    label: "EV022893: Plug EN 175301-803 type B"
  },
  {
    valueId: "EV022894",
    valueDesc: "Plug EN 175301-803 type C",
    label: "EV022894: Plug EN 175301-803 type C"
  },
  {
    valueId: "EV022895",
    valueDesc: "UR",
    label: "EV022895: UR"
  },
  {
    valueId: "EV022898",
    valueDesc: "70 %, max. 13 min on, 30 % rest",
    label: "EV022898: 70 %, max. 13 min on, 30 % rest"
  },
  {
    valueId: "EV022901",
    valueDesc: "Drip tube",
    label: "EV022901: Drip tube"
  },
  {
    valueId: "EV022902",
    valueDesc: "Rotor",
    label: "EV022902: Rotor"
  },
  {
    valueId: "EV022903",
    valueDesc: "Vane",
    label: "EV022903: Vane"
  },
  {
    valueId: "EV022904",
    valueDesc: "EN 558-1, 16",
    label: "EV022904: EN 558-1, 16"
  },
  {
    valueId: "EV022905",
    valueDesc: "EN 558-1, 49",
    label: "EV022905: EN 558-1, 49"
  },
  {
    valueId: "EV022906",
    valueDesc: "EN 558-1, 52",
    label: "EV022906: EN 558-1, 52"
  },
  {
    valueId: "EV022916",
    valueDesc: "Split range",
    label: "EV022916: Split range"
  },
  {
    valueId: "EV022917",
    valueDesc: "Al 99.9 (3.0305)",
    label: "EV022917: Al 99.9 (3.0305)"
  },
  {
    valueId: "EV022918",
    valueDesc: "G 1/4 inch",
    label: "EV022918: G 1/4 inch"
  },
  {
    valueId: "EV022919",
    valueDesc: "G 1/8 inch",
    label: "EV022919: G 1/8 inch"
  },
  {
    valueId: "EV022922",
    valueDesc: "Cable box with screw clamps",
    label: "EV022922: Cable box with screw clamps"
  },
  {
    valueId: "EV022923",
    valueDesc: "Cable box with screw clamps, moulded",
    label: "EV022923: Cable box with screw clamps, moulded"
  },
  {
    valueId: "EV022924",
    valueDesc: "Moulded cable",
    label: "EV022924: Moulded cable"
  },
  {
    valueId: "EV022925",
    valueDesc: "10 %, max. 5 min on, 90 % rest",
    label: "EV022925: 10 %, max. 5 min on, 90 % rest"
  },
  {
    valueId: "EV022926",
    valueDesc: "20 %, max. 30 min on, 80 % rest",
    label: "EV022926: 20 %, max. 30 min on, 80 % rest"
  },
  {
    valueId: "EV022928",
    valueDesc: "Paddle",
    label: "EV022928: Paddle"
  },
  {
    valueId: "EV022929",
    valueDesc: "Vibrating fork",
    label: "EV022929: Vibrating fork"
  },
  {
    valueId: "EV022930",
    valueDesc: "Direct load",
    label: "EV022930: Direct load"
  },
  {
    valueId: "EV022931",
    valueDesc: "IS NAMUR",
    label: "EV022931: IS NAMUR"
  },
  {
    valueId: "EV022932",
    valueDesc: "PNP/PLC",
    label: "EV022932: PNP/PLC"
  },
  {
    valueId: "EV022934",
    valueDesc: "PPC",
    label: "EV022934: PPC"
  },
  {
    valueId: "EV022938",
    valueDesc: "Braiding wire",
    label: "EV022938: Braiding wire"
  },
  {
    valueId: "EV022941",
    valueDesc: "Slide one-part",
    label: "EV022941: Slide one-part"
  },
  {
    valueId: "EV022942",
    valueDesc: "Men",
    label: "EV022942: Men"
  },
  {
    valueId: "EV022943",
    valueDesc: "Women",
    label: "EV022943: Women"
  },
  {
    valueId: "EV022944",
    valueDesc: "Disabled persons",
    label: "EV022944: Disabled persons"
  },
  {
    valueId: "EV022945",
    valueDesc: "Paste",
    label: "EV022945: Paste"
  },
  {
    valueId: "EV022957",
    valueDesc: "Sharp",
    label: "EV022957: Sharp"
  },
  {
    valueId: "EV022958",
    valueDesc: "110 V",
    label: "EV022958: 110 V"
  },
  {
    valueId: "EV022959",
    valueDesc: "ANSI 125",
    label: "EV022959: ANSI 125"
  },
  {
    valueId: "EV022960",
    valueDesc: "ANSI 150",
    label: "EV022960: ANSI 150"
  },
  {
    valueId: "EV022961",
    valueDesc: "ANSI 1500",
    label: "EV022961: ANSI 1500"
  },
  {
    valueId: "EV022962",
    valueDesc: "ANSI 300",
    label: "EV022962: ANSI 300"
  },
  {
    valueId: "EV022963",
    valueDesc: "ANSI 600",
    label: "EV022963: ANSI 600"
  },
  {
    valueId: "EV022964",
    valueDesc: "ANSI 900",
    label: "EV022964: ANSI 900"
  },
  {
    valueId: "EV022965",
    valueDesc: "With sensor",
    label: "EV022965: With sensor"
  },
  {
    valueId: "EV022966",
    valueDesc: "ANSI 800",
    label: "EV022966: ANSI 800"
  },
  {
    valueId: "EV022967",
    valueDesc: "ANSI 2000",
    label: "EV022967: ANSI 2000"
  },
  {
    valueId: "EV022968",
    valueDesc: "Synthetic",
    label: "EV022968: Synthetic"
  },
  {
    valueId: "EV022969",
    valueDesc: "Screw-down non return disc",
    label: "EV022969: Screw-down non return disc"
  },
  {
    valueId: "EV022970",
    valueDesc: "Balanced valve",
    label: "EV022970: Balanced valve"
  },
  {
    valueId: "EV022971",
    valueDesc: "Control flap",
    label: "EV022971: Control flap"
  },
  {
    valueId: "EV022972",
    valueDesc: "Fixed disc",
    label: "EV022972: Fixed disc"
  },
  {
    valueId: "EV022973",
    valueDesc: "100/170",
    label: "EV022973: 100/170"
  },
  {
    valueId: "EV022985",
    valueDesc: "G 1.6",
    label: "EV022985: G 1.6"
  },
  {
    valueId: "EV022986",
    valueDesc: "G 4",
    label: "EV022986: G 4"
  },
  {
    valueId: "EV022987",
    valueDesc: "G 6",
    label: "EV022987: G 6"
  },
  {
    valueId: "EV022988",
    valueDesc: "G 16",
    label: "EV022988: G 16"
  },
  {
    valueId: "EV022989",
    valueDesc: "G 25",
    label: "EV022989: G 25"
  },
  {
    valueId: "EV022994",
    valueDesc: "Flint",
    label: "EV022994: Flint"
  },
  {
    valueId: "EV022996",
    valueDesc: "TH",
    label: "EV022996: TH"
  },
  {
    valueId: "EV022997",
    valueDesc: "SA",
    label: "EV022997: SA"
  },
  {
    valueId: "EV022998",
    valueDesc: "Quadrant",
    label: "EV022998: Quadrant"
  },
  {
    valueId: "EV023006",
    valueDesc: "Before pump",
    label: "EV023006: Before pump"
  },
  {
    valueId: "EV023007",
    valueDesc: "After pump",
    label: "EV023007: After pump"
  },
  {
    valueId: "EV023008",
    valueDesc: "110/150 mm",
    label: "EV023008: 110/150 mm"
  },
  {
    valueId: "EV023009",
    valueDesc: "Gas street",
    label: "EV023009: Gas street"
  },
  {
    valueId: "EV023010",
    valueDesc: "Pilot flame",
    label: "EV023010: Pilot flame"
  },
  {
    valueId: "EV023011",
    valueDesc: "Double right-angled",
    label: "EV023011: Double right-angled"
  },
  {
    valueId: "EV023014",
    valueDesc: "Modified bitumen",
    label: "EV023014: Modified bitumen"
  },
  {
    valueId: "EV023019",
    valueDesc: "Volume control",
    label: "EV023019: Volume control"
  },
  {
    valueId: "EV023025",
    valueDesc: "Odour trap/stench trap",
    label: "EV023025: Odour trap/stench trap"
  },
  {
    valueId: "EV023026",
    valueDesc: "Dirt trap grid",
    label: "EV023026: Dirt trap grid"
  },
  {
    valueId: "EV023030",
    valueDesc: "Zinc band",
    label: "EV023030: Zinc band"
  },
  {
    valueId: "EV023031",
    valueDesc: "Zinc sheet",
    label: "EV023031: Zinc sheet"
  },
  {
    valueId: "EV023033",
    valueDesc: "Lead sheet",
    label: "EV023033: Lead sheet"
  },
  {
    valueId: "EV023034",
    valueDesc: "Cutting Lead",
    label: "EV023034: Cutting Lead"
  },
  {
    valueId: "EV023038",
    valueDesc: "150x200 mm",
    label: "EV023038: 150x200 mm"
  },
  {
    valueId: "EV023039",
    valueDesc: "150x150 mm",
    label: "EV023039: 150x150 mm"
  },
  {
    valueId: "EV023043",
    valueDesc: "Polyester concrete",
    label: "EV023043: Polyester concrete"
  },
  {
    valueId: "EV023044",
    valueDesc: "A-15",
    label: "EV023044: A-15"
  },
  {
    valueId: "EV023045",
    valueDesc: "B-125",
    label: "EV023045: B-125"
  },
  {
    valueId: "EV023046",
    valueDesc: "C-250",
    label: "EV023046: C-250"
  },
  {
    valueId: "EV023047",
    valueDesc: "D-400",
    label: "EV023047: D-400"
  },
  {
    valueId: "EV023048",
    valueDesc: "E-600",
    label: "EV023048: E-600"
  },
  {
    valueId: "EV023049",
    valueDesc: "F-900",
    label: "EV023049: F-900"
  },
  {
    valueId: "EV023050",
    valueDesc: "Line",
    label: "EV023050: Line"
  },
  {
    valueId: "EV023051",
    valueDesc: "Back to back",
    label: "EV023051: Back to back"
  },
  {
    valueId: "EV023052",
    valueDesc: "Copper - nickel - iron",
    label: "EV023052: Copper - nickel - iron"
  },
  {
    valueId: "EV023053",
    valueDesc: "Pyramid",
    label: "EV023053: Pyramid"
  },
  {
    valueId: "EV023055",
    valueDesc: "Time control",
    label: "EV023055: Time control"
  },
  {
    valueId: "EV023071",
    valueDesc: "Indoor unit grille",
    label: "EV023071: Indoor unit grille"
  },
  {
    valueId: "EV023087",
    valueDesc: "Flushing agent",
    label: "EV023087: Flushing agent"
  },
  {
    valueId: "EV023088",
    valueDesc: "Leak sealer",
    label: "EV023088: Leak sealer"
  },
  {
    valueId: "EV023089",
    valueDesc: "Vaseline",
    label: "EV023089: Vaseline"
  },
  {
    valueId: "EV023090",
    valueDesc: "Protector substance",
    label: "EV023090: Protector substance"
  },
  {
    valueId: "EV023092",
    valueDesc: "Silencer",
    label: "EV023092: Silencer"
  },
  {
    valueId: "EV023094",
    valueDesc: "Multispray",
    label: "EV023094: Multispray"
  },
  {
    valueId: "EV023095",
    valueDesc: "Lubricant",
    label: "EV023095: Lubricant"
  },
  {
    valueId: "EV023096",
    valueDesc: "Mineral oil",
    label: "EV023096: Mineral oil"
  },
  {
    valueId: "EV023097",
    valueDesc: "Synthetic oil",
    label: "EV023097: Synthetic oil"
  },
  {
    valueId: "EV023098",
    valueDesc: "Plant oil",
    label: "EV023098: Plant oil"
  },
  {
    valueId: "EV023099",
    valueDesc: "Toilet",
    label: "EV023099: Toilet"
  },
  {
    valueId: "EV023100",
    valueDesc: "Vegetal",
    label: "EV023100: Vegetal"
  },
  {
    valueId: "EV023101",
    valueDesc: "2 x 230 V",
    label: "EV023101: 2 x 230 V"
  },
  {
    valueId: "EV023102",
    valueDesc: "Clock thermostat",
    label: "EV023102: Clock thermostat"
  },
  {
    valueId: "EV023103",
    valueDesc: "Room thermostat",
    label: "EV023103: Room thermostat"
  },
  {
    valueId: "EV023104",
    valueDesc: "3 positions",
    label: "EV023104: 3 positions"
  },
  {
    valueId: "EV023105",
    valueDesc: "SOLAS A (offshore passages)",
    label: "EV023105: SOLAS A (offshore passages)"
  },
  {
    valueId: "EV023106",
    valueDesc: "SOLAS B (coastal passages)",
    label: "EV023106: SOLAS B (coastal passages)"
  },
  {
    valueId: "EV023107",
    valueDesc: "M26 x 1.5",
    label: "EV023107: M26 x 1.5"
  },
  {
    valueId: "EV023108",
    valueDesc: "M33 x 2.0",
    label: "EV023108: M33 x 2.0"
  },
  {
    valueId: "EV023109",
    valueDesc: "35 mm",
    label: "EV023109: 35 mm"
  },
  {
    valueId: "EV023113",
    valueDesc: "With pin",
    label: "EV023113: With pin"
  },
  {
    valueId: "EV023114",
    valueDesc: "With lug",
    label: "EV023114: With lug"
  },
  {
    valueId: "EV023115",
    valueDesc: "Gauge torx",
    label: "EV023115: Gauge torx"
  },
  {
    valueId: "EV023116",
    valueDesc: "Handle torque screwdriver",
    label: "EV023116: Handle torque screwdriver"
  },
  {
    valueId: "EV023117",
    valueDesc: "Plug in blade slot",
    label: "EV023117: Plug in blade slot"
  },
  {
    valueId: "EV023118",
    valueDesc: "Plug in blade cross",
    label: "EV023118: Plug in blade cross"
  },
  {
    valueId: "EV023119",
    valueDesc: "Plug in blade torx",
    label: "EV023119: Plug in blade torx"
  },
  {
    valueId: "EV023120",
    valueDesc: "Demagnetizer",
    label: "EV023120: Demagnetizer"
  },
  {
    valueId: "EV023122",
    valueDesc: "Crosswise divider",
    label: "EV023122: Crosswise divider"
  },
  {
    valueId: "EV023123",
    valueDesc: "Lengthwise divider",
    label: "EV023123: Lengthwise divider"
  },
  {
    valueId: "EV023126",
    valueDesc: "Distance module",
    label: "EV023126: Distance module"
  },
  {
    valueId: "EV023127",
    valueDesc: "Machine holder",
    label: "EV023127: Machine holder"
  },
  {
    valueId: "EV023128",
    valueDesc: "Tool panel",
    label: "EV023128: Tool panel"
  },
  {
    valueId: "EV023129",
    valueDesc: "Anti-slip mat",
    label: "EV023129: Anti-slip mat"
  },
  {
    valueId: "EV023131",
    valueDesc: "Tool clamp",
    label: "EV023131: Tool clamp"
  },
  {
    valueId: "EV023132",
    valueDesc: "Writing support",
    label: "EV023132: Writing support"
  },
  {
    valueId: "EV023133",
    valueDesc: "Tool holder",
    label: "EV023133: Tool holder"
  },
  {
    valueId: "EV023134",
    valueDesc: "Retaining clip for nuts",
    label: "EV023134: Retaining clip for nuts"
  },
  {
    valueId: "EV023135",
    valueDesc: "Driving handle",
    label: "EV023135: Driving handle"
  },
  {
    valueId: "EV023136",
    valueDesc: "High-speed swivel disc",
    label: "EV023136: High-speed swivel disc"
  },
  {
    valueId: "EV023140",
    valueDesc: "Safety ring",
    label: "EV023140: Safety ring"
  },
  {
    valueId: "EV023141",
    valueDesc: "Safety splint",
    label: "EV023141: Safety splint"
  },
  {
    valueId: "EV023143",
    valueDesc: "Reaction foot",
    label: "EV023143: Reaction foot"
  },
  {
    valueId: "EV023144",
    valueDesc: "Weld on fitting",
    label: "EV023144: Weld on fitting"
  },
  {
    valueId: "EV023146",
    valueDesc: "Cutting wheel",
    label: "EV023146: Cutting wheel"
  },
  {
    valueId: "EV023148",
    valueDesc: "Plastic holder",
    label: "EV023148: Plastic holder"
  },
  {
    valueId: "EV023149",
    valueDesc: "Wooden holder",
    label: "EV023149: Wooden holder"
  },
  {
    valueId: "EV023150",
    valueDesc: "Ring end fitting",
    label: "EV023150: Ring end fitting"
  },
  {
    valueId: "EV023151",
    valueDesc: "Open end fitting",
    label: "EV023151: Open end fitting"
  },
  {
    valueId: "EV023152",
    valueDesc: "Open ring end fitting",
    label: "EV023152: Open ring end fitting"
  },
  {
    valueId: "EV023153",
    valueDesc: "Square fitting",
    label: "EV023153: Square fitting"
  },
  {
    valueId: "EV023154",
    valueDesc: "Ratchet fitting",
    label: "EV023154: Ratchet fitting"
  },
  {
    valueId: "EV023155",
    valueDesc: "Internal hexagon fitting",
    label: "EV023155: Internal hexagon fitting"
  },
  {
    valueId: "EV023156",
    valueDesc: "Bit holder fitting",
    label: "EV023156: Bit holder fitting"
  },
  {
    valueId: "EV023157",
    valueDesc: "Mushroom head",
    label: "EV023157: Mushroom head"
  },
  {
    valueId: "EV023158",
    valueDesc: "Copper hammer",
    label: "EV023158: Copper hammer"
  },
  {
    valueId: "EV023159",
    valueDesc: "Lead hammer",
    label: "EV023159: Lead hammer"
  },
  {
    valueId: "EV023161",
    valueDesc: "Paving hammer",
    label: "EV023161: Paving hammer"
  },
  {
    valueId: "EV023162",
    valueDesc: "Glaziers hammer",
    label: "EV023162: Glaziers hammer"
  },
  {
    valueId: "EV023163",
    valueDesc: "Geologist's hammer",
    label: "EV023163: Geologist's hammer"
  },
  {
    valueId: "EV023166",
    valueDesc: "Carpenters hammer",
    label: "EV023166: Carpenters hammer"
  },
  {
    valueId: "EV023169",
    valueDesc: "Wheel-hub puller",
    label: "EV023169: Wheel-hub puller"
  },
  {
    valueId: "EV023170",
    valueDesc: "Ball joint puller",
    label: "EV023170: Ball joint puller"
  },
  {
    valueId: "EV023172",
    valueDesc: "Cartridge hook",
    label: "EV023172: Cartridge hook"
  },
  {
    valueId: "EV023173",
    valueDesc: "Drive shaft puller",
    label: "EV023173: Drive shaft puller"
  },
  {
    valueId: "EV023174",
    valueDesc: "Steering-wheel puller",
    label: "EV023174: Steering-wheel puller"
  },
  {
    valueId: "EV023175",
    valueDesc: "Cross-beam",
    label: "EV023175: Cross-beam"
  },
  {
    valueId: "EV023176",
    valueDesc: "Pulling leg",
    label: "EV023176: Pulling leg"
  },
  {
    valueId: "EV023177",
    valueDesc: "Head",
    label: "EV023177: Head"
  },
  {
    valueId: "EV023178",
    valueDesc: "Spindle pressure pad",
    label: "EV023178: Spindle pressure pad"
  },
  {
    valueId: "EV023179",
    valueDesc: "Thread insert",
    label: "EV023179: Thread insert"
  },
  {
    valueId: "EV023180",
    valueDesc: "Flange separator",
    label: "EV023180: Flange separator"
  },
  {
    valueId: "EV023182",
    valueDesc: "Cylinder tip",
    label: "EV023182: Cylinder tip"
  },
  {
    valueId: "EV023183",
    valueDesc: "Extension rod",
    label: "EV023183: Extension rod"
  },
  {
    valueId: "EV023184",
    valueDesc: "Pulling chuck",
    label: "EV023184: Pulling chuck"
  },
  {
    valueId: "EV023185",
    valueDesc: "Hydraulic cylinder/press",
    label: "EV023185: Hydraulic cylinder/press"
  },
  {
    valueId: "EV023187",
    valueDesc: "Spindle hydraulic",
    label: "EV023187: Spindle hydraulic"
  },
  {
    valueId: "EV023188",
    valueDesc: "Dismantling/assembly fork",
    label: "EV023188: Dismantling/assembly fork"
  },
  {
    valueId: "EV023190",
    valueDesc: "Electrical unblocker",
    label: "EV023190: Electrical unblocker"
  },
  {
    valueId: "EV023191",
    valueDesc: "Flexible pipe cleaning shaft",
    label: "EV023191: Flexible pipe cleaning shaft"
  },
  {
    valueId: "EV023192",
    valueDesc: "Extension flexible pipe cleaning shaft",
    label: "EV023192: Extension flexible pipe cleaning shaft"
  },
  {
    valueId: "EV023193",
    valueDesc: "Clamping plate",
    label: "EV023193: Clamping plate"
  },
  {
    valueId: "EV023194",
    valueDesc: "Caliper face spanner",
    label: "EV023194: Caliper face spanner"
  },
  {
    valueId: "EV023203",
    valueDesc: "Wiper arm",
    label: "EV023203: Wiper arm"
  },
  {
    valueId: "EV023204",
    valueDesc: "Tubular profile",
    label: "EV023204: Tubular profile"
  },
  {
    valueId: "EV023205",
    valueDesc: "Panel",
    label: "EV023205: Panel"
  },
  {
    valueId: "EV023206",
    valueDesc: "Bulkhead mount",
    label: "EV023206: Bulkhead mount"
  },
  {
    valueId: "EV023207",
    valueDesc: "Reservoir",
    label: "EV023207: Reservoir"
  },
  {
    valueId: "EV023208",
    valueDesc: "Halon",
    label: "EV023208: Halon"
  },
  {
    valueId: "EV023209",
    valueDesc: "Main control",
    label: "EV023209: Main control"
  },
  {
    valueId: "EV023210",
    valueDesc: "Sub control",
    label: "EV023210: Sub control"
  },
  {
    valueId: "EV023211",
    valueDesc: "Software",
    label: "EV023211: Software"
  },
  {
    valueId: "EV023212",
    valueDesc: "Tissue wet",
    label: "EV023212: Tissue wet"
  },
  {
    valueId: "EV023213",
    valueDesc: "Tissue dry",
    label: "EV023213: Tissue dry"
  },
  {
    valueId: "EV023214",
    valueDesc: "EN 393",
    label: "EV023214: EN 393"
  },
  {
    valueId: "EV023215",
    valueDesc: "EN 395",
    label: "EV023215: EN 395"
  },
  {
    valueId: "EV023216",
    valueDesc: "EN 396",
    label: "EV023216: EN 396"
  },
  {
    valueId: "EV023217",
    valueDesc: "EN 399",
    label: "EV023217: EN 399"
  },
  {
    valueId: "EV023218",
    valueDesc: "Dry suit",
    label: "EV023218: Dry suit"
  },
  {
    valueId: "EV023219",
    valueDesc: "Immersion suit",
    label: "EV023219: Immersion suit"
  },
  {
    valueId: "EV023220",
    valueDesc: "Fire fighting suit",
    label: "EV023220: Fire fighting suit"
  },
  {
    valueId: "EV023221",
    valueDesc: "Adult",
    label: "EV023221: Adult"
  },
  {
    valueId: "EV023222",
    valueDesc: "Child",
    label: "EV023222: Child"
  },
  {
    valueId: "EV023223",
    valueDesc: "Rounding cutter",
    label: "EV023223: Rounding cutter"
  },
  {
    valueId: "EV023224",
    valueDesc: "Chamfering cutter",
    label: "EV023224: Chamfering cutter"
  },
  {
    valueId: "EV023225",
    valueDesc: "Groove cutter",
    label: "EV023225: Groove cutter"
  },
  {
    valueId: "EV023226",
    valueDesc: "Hollow edge forming cutter",
    label: "EV023226: Hollow edge forming cutter"
  },
  {
    valueId: "EV023227",
    valueDesc: "Laminate trim cutter",
    label: "EV023227: Laminate trim cutter"
  },
  {
    valueId: "EV023228",
    valueDesc: "Panel cutter",
    label: "EV023228: Panel cutter"
  },
  {
    valueId: "EV023229",
    valueDesc: "Edge forming cutter",
    label: "EV023229: Edge forming cutter"
  },
  {
    valueId: "EV023230",
    valueDesc: "Hinge cutter",
    label: "EV023230: Hinge cutter"
  },
  {
    valueId: "EV023231",
    valueDesc: "Disc cutter",
    label: "EV023231: Disc cutter"
  },
  {
    valueId: "EV023232",
    valueDesc: "Slot cutter",
    label: "EV023232: Slot cutter"
  },
  {
    valueId: "EV023233",
    valueDesc: "Spiral milling cutter",
    label: "EV023233: Spiral milling cutter"
  },
  {
    valueId: "EV023234",
    valueDesc: "Rebating cutter",
    label: "EV023234: Rebating cutter"
  },
  {
    valueId: "EV023235",
    valueDesc: "V-groove cutter",
    label: "EV023235: V-groove cutter"
  },
  {
    valueId: "EV023236",
    valueDesc: "Joining cutter",
    label: "EV023236: Joining cutter"
  },
  {
    valueId: "EV023237",
    valueDesc: "Straight cutter",
    label: "EV023237: Straight cutter"
  },
  {
    valueId: "EV023238",
    valueDesc: "Switch blade cutter",
    label: "EV023238: Switch blade cutter"
  },
  {
    valueId: "EV023239",
    valueDesc: "Dovetail cutter",
    label: "EV023239: Dovetail cutter"
  },
  {
    valueId: "EV023240",
    valueDesc: "7 inch",
    label: "EV023240: 7 inch"
  },
  {
    valueId: "EV023241",
    valueDesc: "13 inch",
    label: "EV023241: 13 inch"
  },
  {
    valueId: "EV023242",
    valueDesc: "15 inch",
    label: "EV023242: 15 inch"
  },
  {
    valueId: "EV023243",
    valueDesc: "2 stroke",
    label: "EV023243: 2 stroke"
  },
  {
    valueId: "EV023244",
    valueDesc: "4 stroke",
    label: "EV023244: 4 stroke"
  },
  {
    valueId: "EV023245",
    valueDesc: "Masthead",
    label: "EV023245: Masthead"
  },
  {
    valueId: "EV023246",
    valueDesc: "Port",
    label: "EV023246: Port"
  },
  {
    valueId: "EV023247",
    valueDesc: "Starboard",
    label: "EV023247: Starboard"
  },
  {
    valueId: "EV023248",
    valueDesc: "Stern",
    label: "EV023248: Stern"
  },
  {
    valueId: "EV023249",
    valueDesc: "Tow",
    label: "EV023249: Tow"
  },
  {
    valueId: "EV023250",
    valueDesc: "Starboard/port",
    label: "EV023250: Starboard/port"
  },
  {
    valueId: "EV023251",
    valueDesc: "Starboard/port/stern",
    label: "EV023251: Starboard/port/stern"
  },
  {
    valueId: "EV023253",
    valueDesc: "Red/green/white",
    label: "EV023253: Red/green/white"
  },
  {
    valueId: "EV023255",
    valueDesc: "Holder",
    label: "EV023255: Holder"
  },
  {
    valueId: "EV023257",
    valueDesc: "Dual station unit",
    label: "EV023257: Dual station unit"
  },
  {
    valueId: "EV023258",
    valueDesc: "Eye",
    label: "EV023258: Eye"
  },
  {
    valueId: "EV023259",
    valueDesc: "Damper",
    label: "EV023259: Damper"
  },
  {
    valueId: "EV023260",
    valueDesc: "Fork end",
    label: "EV023260: Fork end"
  },
  {
    valueId: "EV023261",
    valueDesc: "Cable brake",
    label: "EV023261: Cable brake"
  },
  {
    valueId: "EV023264",
    valueDesc: "Lifeline",
    label: "EV023264: Lifeline"
  },
  {
    valueId: "EV023265",
    valueDesc: "Axe",
    label: "EV023265: Axe"
  },
  {
    valueId: "EV023267",
    valueDesc: "CO2 cylinder",
    label: "EV023267: CO2 cylinder"
  },
  {
    valueId: "EV023268",
    valueDesc: "Bobbin",
    label: "EV023268: Bobbin"
  },
  {
    valueId: "EV023269",
    valueDesc: "Repair kit",
    label: "EV023269: Repair kit"
  },
  {
    valueId: "EV023270",
    valueDesc: "Thermal protective aid",
    label: "EV023270: Thermal protective aid"
  },
  {
    valueId: "EV023271",
    valueDesc: "AIS",
    label: "EV023271: AIS"
  },
  {
    valueId: "EV023272",
    valueDesc: "EPIRB",
    label: "EV023272: EPIRB"
  },
  {
    valueId: "EV023273",
    valueDesc: "MOB",
    label: "EV023273: MOB"
  },
  {
    valueId: "EV023274",
    valueDesc: "PLB",
    label: "EV023274: PLB"
  },
  {
    valueId: "EV023275",
    valueDesc: "SART",
    label: "EV023275: SART"
  },
  {
    valueId: "EV023276",
    valueDesc: "PN 315",
    label: "EV023276: PN 315"
  },
  {
    valueId: "EV023277",
    valueDesc: "PN 800",
    label: "EV023277: PN 800"
  },
  {
    valueId: "EV023278",
    valueDesc: "CuZn39Pb3 (CW614N)",
    label: "EV023278: CuZn39Pb3 (CW614N)"
  },
  {
    valueId: "EV023280",
    valueDesc: "Equal sided",
    label: "EV023280: Equal sided"
  },
  {
    valueId: "EV023281",
    valueDesc: "Unequal sided",
    label: "EV023281: Unequal sided"
  },
  {
    valueId: "EV023282",
    valueDesc: "C45 (1.0503)",
    label: "EV023282: C45 (1.0503)"
  },
  {
    valueId: "EV023283",
    valueDesc: "C35 (1.0501)",
    label: "EV023283: C35 (1.0501)"
  },
  {
    valueId: "EV023284",
    valueDesc: "Stainless steel 303 (1.4305)",
    label: "EV023284: Stainless steel 303 (1.4305)"
  },
  {
    valueId: "EV023285",
    valueDesc: "42CrMo4 (1.7225)",
    label: "EV023285: 42CrMo4 (1.7225)"
  },
  {
    valueId: "EV023286",
    valueDesc: "Stage circulation",
    label: "EV023286: Stage circulation"
  },
  {
    valueId: "EV023290",
    valueDesc: "Lead (dry cell, AGM)",
    label: "EV023290: Lead (dry cell, AGM)"
  },
  {
    valueId: "EV023291",
    valueDesc: "PA 6",
    label: "EV023291: PA 6"
  },
  {
    valueId: "EV023292",
    valueDesc: "PA 6.6",
    label: "EV023292: PA 6.6"
  },
  {
    valueId: "EV023293",
    valueDesc: "EN AW-5083 (AlMg4.5Mn)",
    label: "EV023293: EN AW-5083 (AlMg4.5Mn)"
  },
  {
    valueId: "EV023295",
    valueDesc: "Cocos",
    label: "EV023295: Cocos"
  },
  {
    valueId: "EV023296",
    valueDesc: "Flax",
    label: "EV023296: Flax"
  },
  {
    valueId: "EV023297",
    valueDesc: "Wool",
    label: "EV023297: Wool"
  },
  {
    valueId: "EV023298",
    valueDesc: "Bentonite",
    label: "EV023298: Bentonite"
  },
  {
    valueId: "EV023299",
    valueDesc: "Jute",
    label: "EV023299: Jute"
  },
  {
    valueId: "EV023300",
    valueDesc: "EN AC-47000 (AlSi12(Cu))",
    label: "EV023300: EN AC-47000 (AlSi12(Cu))"
  },
  {
    valueId: "EV023302",
    valueDesc: "DN 1100",
    label: "EV023302: DN 1100"
  },
  {
    valueId: "EV023303",
    valueDesc: "DN 1300",
    label: "EV023303: DN 1300"
  },
  {
    valueId: "EV023304",
    valueDesc: "DN 1500",
    label: "EV023304: DN 1500"
  },
  {
    valueId: "EV023305",
    valueDesc: "PN 5",
    label: "EV023305: PN 5"
  },
  {
    valueId: "EV023306",
    valueDesc: "PN 210",
    label: "EV023306: PN 210"
  },
  {
    valueId: "EV023307",
    valueDesc: "Single bulkhead flange",
    label: "EV023307: Single bulkhead flange"
  },
  {
    valueId: "EV023308",
    valueDesc: "Double bulkhead flange",
    label: "EV023308: Double bulkhead flange"
  },
  {
    valueId: "EV023309",
    valueDesc: "Counter flange",
    label: "EV023309: Counter flange"
  },
  {
    valueId: "EV023310",
    valueDesc: "Spectacle flange",
    label: "EV023310: Spectacle flange"
  },
  {
    valueId: "EV023311",
    valueDesc: "PN 640",
    label: "EV023311: PN 640"
  },
  {
    valueId: "EV023312",
    valueDesc: "Hot rolled",
    label: "EV023312: Hot rolled"
  },
  {
    valueId: "EV023313",
    valueDesc: "Cold rolled",
    label: "EV023313: Cold rolled"
  },
  {
    valueId: "EV023314",
    valueDesc: "Hot drawn",
    label: "EV023314: Hot drawn"
  },
  {
    valueId: "EV023315",
    valueDesc: "Cold drawn",
    label: "EV023315: Cold drawn"
  },
  {
    valueId: "EV023316",
    valueDesc: "Machined",
    label: "EV023316: Machined"
  },
  {
    valueId: "EV023317",
    valueDesc: "7 inch (175)",
    label: "EV023317: 7 inch (175)"
  },
  {
    valueId: "EV023318",
    valueDesc: "3.1/2 inch (95)",
    label: "EV023318: 3.1/2 inch (95)"
  },
  {
    valueId: "EV023319",
    valueDesc: "DN 95",
    label: "EV023319: DN 95"
  },
  {
    valueId: "EV023320",
    valueDesc: "Grade A",
    label: "EV023320: Grade A"
  },
  {
    valueId: "EV023321",
    valueDesc: "Grade B",
    label: "EV023321: Grade B"
  },
  {
    valueId: "EV023322",
    valueDesc: "AH/DH32",
    label: "EV023322: AH/DH32"
  },
  {
    valueId: "EV023323",
    valueDesc: "AH/DH36",
    label: "EV023323: AH/DH36"
  },
  {
    valueId: "EV023324",
    valueDesc: "AH/DH40",
    label: "EV023324: AH/DH40"
  },
  {
    valueId: "EV023326",
    valueDesc: "St 37.4 (1.0255)",
    label: "EV023326: St 37.4 (1.0255)"
  },
  {
    valueId: "EV023327",
    valueDesc: "DN 1050",
    label: "EV023327: DN 1050"
  },
  {
    valueId: "EV023328",
    valueDesc: "DN 1150",
    label: "EV023328: DN 1150"
  },
  {
    valueId: "EV023329",
    valueDesc: "DN 19",
    label: "EV023329: DN 19"
  },
  {
    valueId: "EV023330",
    valueDesc: "DN 550",
    label: "EV023330: DN 550"
  },
  {
    valueId: "EV023331",
    valueDesc: "DN 650",
    label: "EV023331: DN 650"
  },
  {
    valueId: "EV023332",
    valueDesc: "DN 750",
    label: "EV023332: DN 750"
  },
  {
    valueId: "EV023333",
    valueDesc: "DN 850",
    label: "EV023333: DN 850"
  },
  {
    valueId: "EV023334",
    valueDesc: "DN 950",
    label: "EV023334: DN 950"
  },
  {
    valueId: "EV023335",
    valueDesc: "St 37.2 (1.0039)",
    label: "EV023335: St 37.2 (1.0039)"
  },
  {
    valueId: "EV023336",
    valueDesc: "EN AW-5056 (AlMg5Cr)",
    label: "EV023336: EN AW-5056 (AlMg5Cr)"
  },
  {
    valueId: "EV023337",
    valueDesc: "EN AW-5754 (AlMg3)",
    label: "EV023337: EN AW-5754 (AlMg3)"
  },
  {
    valueId: "EV023338",
    valueDesc: "EN AW-6005 A (AlMgSi)",
    label: "EV023338: EN AW-6005 A (AlMgSi)"
  },
  {
    valueId: "EV023340",
    valueDesc: "St 37.8 (1.0345)",
    label: "EV023340: St 37.8 (1.0345)"
  },
  {
    valueId: "EV023343",
    valueDesc: "Spade rudder",
    label: "EV023343: Spade rudder"
  },
  {
    valueId: "EV023344",
    valueDesc: "Pintle rudder",
    label: "EV023344: Pintle rudder"
  },
  {
    valueId: "EV023345",
    valueDesc: "Cutting ring",
    label: "EV023345: Cutting ring"
  },
  {
    valueId: "EV023346",
    valueDesc: "PN 2",
    label: "EV023346: PN 2"
  },
  {
    valueId: "EV023347",
    valueDesc: "C22 (steel)",
    label: "EV023347: C22 (steel)"
  },
  {
    valueId: "EV023350",
    valueDesc: "Unblocker",
    label: "EV023350: Unblocker"
  },
  {
    valueId: "EV023351",
    valueDesc: "Grinding paste",
    label: "EV023351: Grinding paste"
  },
  {
    valueId: "EV023352",
    valueDesc: "Silicone grease",
    label: "EV023352: Silicone grease"
  },
  {
    valueId: "EV023353",
    valueDesc: "Silicon carbid grains",
    label: "EV023353: Silicon carbid grains"
  },
  {
    valueId: "EV023354",
    valueDesc: "Silicone oil",
    label: "EV023354: Silicone oil"
  },
  {
    valueId: "EV023355",
    valueDesc: "Coated granules",
    label: "EV023355: Coated granules"
  },
  {
    valueId: "EV023356",
    valueDesc: "Spray",
    label: "EV023356: Spray"
  },
  {
    valueId: "EV023357",
    valueDesc: "Hard silver solder",
    label: "EV023357: Hard silver solder"
  },
  {
    valueId: "EV023358",
    valueDesc: "Hard copper solder",
    label: "EV023358: Hard copper solder"
  },
  {
    valueId: "EV023359",
    valueDesc: "Cushion",
    label: "EV023359: Cushion"
  },
  {
    valueId: "EV023360",
    valueDesc: "St 35.4 (1.0309)",
    label: "EV023360: St 35.4 (1.0309)"
  },
  {
    valueId: "EV023361",
    valueDesc: "M8 x 1.0",
    label: "EV023361: M8 x 1.0"
  },
  {
    valueId: "EV023362",
    valueDesc: "M10 x 1.25",
    label: "EV023362: M10 x 1.25"
  },
  {
    valueId: "EV023363",
    valueDesc: "M12 x 1.25",
    label: "EV023363: M12 x 1.25"
  },
  {
    valueId: "EV023364",
    valueDesc: "M14 x 1.5",
    label: "EV023364: M14 x 1.5"
  },
  {
    valueId: "EV023365",
    valueDesc: "M18 x 1.5",
    label: "EV023365: M18 x 1.5"
  },
  {
    valueId: "EV023366",
    valueDesc: "M24 x 1.5",
    label: "EV023366: M24 x 1.5"
  },
  {
    valueId: "EV023367",
    valueDesc: "M24 x 2.0",
    label: "EV023367: M24 x 2.0"
  },
  {
    valueId: "EV023368",
    valueDesc: "M27 x 2.0",
    label: "EV023368: M27 x 2.0"
  },
  {
    valueId: "EV023369",
    valueDesc: "M30 x 2.0",
    label: "EV023369: M30 x 2.0"
  },
  {
    valueId: "EV023370",
    valueDesc: "M36 x 3.0",
    label: "EV023370: M36 x 3.0"
  },
  {
    valueId: "EV023371",
    valueDesc: "M45 x 2.0",
    label: "EV023371: M45 x 2.0"
  },
  {
    valueId: "EV023372",
    valueDesc: "NBR soft ring",
    label: "EV023372: NBR soft ring"
  },
  {
    valueId: "EV023373",
    valueDesc: "EPDM soft ring",
    label: "EV023373: EPDM soft ring"
  },
  {
    valueId: "EV023374",
    valueDesc: "FPM (FKM) soft ring",
    label: "EV023374: FPM (FKM) soft ring"
  },
  {
    valueId: "EV023375",
    valueDesc: "CuNi10Fe1.6Mn",
    label: "EV023375: CuNi10Fe1.6Mn"
  },
  {
    valueId: "EV023376",
    valueDesc: "16/20 mm",
    label: "EV023376: 16/20 mm"
  },
  {
    valueId: "EV023378",
    valueDesc: "EN-GJMW-400-5 (EN-JM1030)",
    label: "EV023378: EN-GJMW-400-5 (EN-JM1030)"
  },
  {
    valueId: "EV023379",
    valueDesc: "40S",
    label: "EV023379: 40S"
  },
  {
    valueId: "EV023380",
    valueDesc: "80S",
    label: "EV023380: 80S"
  },
  {
    valueId: "EV023381",
    valueDesc: "CuNi10Fe1Mn",
    label: "EV023381: CuNi10Fe1Mn"
  },
  {
    valueId: "EV023382",
    valueDesc: "Hydraulic/cone grease nipples",
    label: "EV023382: Hydraulic/cone grease nipples"
  },
  {
    valueId: "EV023383",
    valueDesc: "Button head grease nipple",
    label: "EV023383: Button head grease nipple"
  },
  {
    valueId: "EV023384",
    valueDesc: "Hexagonal head grease nipple",
    label: "EV023384: Hexagonal head grease nipple"
  },
  {
    valueId: "EV023385",
    valueDesc: "Ball grease nipple",
    label: "EV023385: Ball grease nipple"
  },
  {
    valueId: "EV023386",
    valueDesc: "Concave grease nipple",
    label: "EV023386: Concave grease nipple"
  },
  {
    valueId: "EV023387",
    valueDesc: "Recessed grease nipple",
    label: "EV023387: Recessed grease nipple"
  },
  {
    valueId: "EV023388",
    valueDesc: "Bayonet grease nipple",
    label: "EV023388: Bayonet grease nipple"
  },
  {
    valueId: "EV023389",
    valueDesc: "Oil nipple",
    label: "EV023389: Oil nipple"
  },
  {
    valueId: "EV023390",
    valueDesc: "External thread BSW",
    label: "EV023390: External thread BSW"
  },
  {
    valueId: "EV023391",
    valueDesc: "External thread BSF",
    label: "EV023391: External thread BSF"
  },
  {
    valueId: "EV023392",
    valueDesc: "DIN 71412",
    label: "EV023392: DIN 71412"
  },
  {
    valueId: "EV023393",
    valueDesc: "DIN 3404",
    label: "EV023393: DIN 3404"
  },
  {
    valueId: "EV023394",
    valueDesc: "DIN 3402",
    label: "EV023394: DIN 3402"
  },
  {
    valueId: "EV023395",
    valueDesc: "DIN 3405",
    label: "EV023395: DIN 3405"
  },
  {
    valueId: "EV023396",
    valueDesc: "Bronze (Rg 7)",
    label: "EV023396: Bronze (Rg 7)"
  },
  {
    valueId: "EV023397",
    valueDesc: "S8",
    label: "EV023397: S8"
  },
  {
    valueId: "EV023398",
    valueDesc: "S10",
    label: "EV023398: S10"
  },
  {
    valueId: "EV023421",
    valueDesc: "Steel/CuNiFe",
    label: "EV023421: Steel/CuNiFe"
  },
  {
    valueId: "EV023423",
    valueDesc: "Shave plane",
    label: "EV023423: Shave plane"
  },
  {
    valueId: "EV023424",
    valueDesc: "Groove plane",
    label: "EV023424: Groove plane"
  },
  {
    valueId: "EV023425",
    valueDesc: "Hand plane",
    label: "EV023425: Hand plane"
  },
  {
    valueId: "EV023426",
    valueDesc: "Nose plane",
    label: "EV023426: Nose plane"
  },
  {
    valueId: "EV023428",
    valueDesc: "EN AW-5182 (Al4.5Mg0.3Mn)",
    label: "EV023428: EN AW-5182 (Al4.5Mg0.3Mn)"
  },
  {
    valueId: "EV023430",
    valueDesc: "Squeeze lever",
    label: "EV023430: Squeeze lever"
  },
  {
    valueId: "EV023431",
    valueDesc: "Tilting lever",
    label: "EV023431: Tilting lever"
  },
  {
    valueId: "EV023432",
    valueDesc: "Control lever",
    label: "EV023432: Control lever"
  },
  {
    valueId: "EV023433",
    valueDesc: "1-piece impulse unit",
    label: "EV023433: 1-piece impulse unit"
  },
  {
    valueId: "EV023434",
    valueDesc: "2-piece impulse unit",
    label: "EV023434: 2-piece impulse unit"
  },
  {
    valueId: "EV023435",
    valueDesc: "3-piece impulse unit",
    label: "EV023435: 3-piece impulse unit"
  },
  {
    valueId: "EV023436",
    valueDesc: "Siliconcarbide",
    label: "EV023436: Siliconcarbide"
  },
  {
    valueId: "EV023437",
    valueDesc: "One size",
    label: "EV023437: One size"
  },
  {
    valueId: "EV023438",
    valueDesc: "Cork",
    label: "EV023438: Cork"
  },
  {
    valueId: "EV023439",
    valueDesc: "Coarse",
    label: "EV023439: Coarse"
  },
  {
    valueId: "EV023440",
    valueDesc: "Fine",
    label: "EV023440: Fine"
  },
  {
    valueId: "EV023441",
    valueDesc: "Keyway cutter",
    label: "EV023441: Keyway cutter"
  },
  {
    valueId: "EV023442",
    valueDesc: "Copy cutter",
    label: "EV023442: Copy cutter"
  },
  {
    valueId: "EV023443",
    valueDesc: "Roughing cutter",
    label: "EV023443: Roughing cutter"
  },
  {
    valueId: "EV023444",
    valueDesc: "Radius cutter",
    label: "EV023444: Radius cutter"
  },
  {
    valueId: "EV023445",
    valueDesc: "T-slot cutter",
    label: "EV023445: T-slot cutter"
  },
  {
    valueId: "EV023446",
    valueDesc: "Woodruff cutter",
    label: "EV023446: Woodruff cutter"
  },
  {
    valueId: "EV023447",
    valueDesc: "DN 28",
    label: "EV023447: DN 28"
  },
  {
    valueId: "EV023448",
    valueDesc: "DN 33",
    label: "EV023448: DN 33"
  },
  {
    valueId: "EV023449",
    valueDesc: "Sponge",
    label: "EV023449: Sponge"
  },
  {
    valueId: "EV023450",
    valueDesc: "DN 320",
    label: "EV023450: DN 320"
  },
  {
    valueId: "EV023451",
    valueDesc: "0000",
    label: "EV023451: 0000"
  },
  {
    valueId: "EV023454",
    valueDesc: "Dirty oil/bilge water discharge",
    label: "EV023454: Dirty oil/bilge water discharge"
  },
  {
    valueId: "EV023455",
    valueDesc: "Sanitary discharge",
    label: "EV023455: Sanitary discharge"
  },
  {
    valueId: "EV023456",
    valueDesc: "Fuel oil",
    label: "EV023456: Fuel oil"
  },
  {
    valueId: "EV023459",
    valueDesc: "Clutch alignment tool",
    label: "EV023459: Clutch alignment tool"
  },
  {
    valueId: "EV023460",
    valueDesc: "Universal alignment tool for automobile doors",
    label: "EV023460: Universal alignment tool for automobile doors"
  },
  {
    valueId: "EV023462",
    valueDesc: "Special ring spanner",
    label: "EV023462: Special ring spanner"
  },
  {
    valueId: "EV023464",
    valueDesc: "Spark plug connector pliers",
    label: "EV023464: Spark plug connector pliers"
  },
  {
    valueId: "EV023465",
    valueDesc: "Heater plug socket pliers",
    label: "EV023465: Heater plug socket pliers"
  },
  {
    valueId: "EV023466",
    valueDesc: "Valve seating-ring pliers",
    label: "EV023466: Valve seating-ring pliers"
  },
  {
    valueId: "EV023467",
    valueDesc: "Fuel hose clamp",
    label: "EV023467: Fuel hose clamp"
  },
  {
    valueId: "EV023468",
    valueDesc: "Plastic lever bar",
    label: "EV023468: Plastic lever bar"
  },
  {
    valueId: "EV023469",
    valueDesc: "Extractor pliers",
    label: "EV023469: Extractor pliers"
  },
  {
    valueId: "EV023471",
    valueDesc: "Pull hook for rubber exhaust mountings",
    label: "EV023471: Pull hook for rubber exhaust mountings"
  },
  {
    valueId: "EV023472",
    valueDesc: "Perforated panel",
    label: "EV023472: Perforated panel"
  },
  {
    valueId: "EV023474",
    valueDesc: "Workshop module",
    label: "EV023474: Workshop module"
  },
  {
    valueId: "EV023475",
    valueDesc: "Separator plastic",
    label: "EV023475: Separator plastic"
  },
  {
    valueId: "EV023476",
    valueDesc: "Separator steel",
    label: "EV023476: Separator steel"
  },
  {
    valueId: "EV023478",
    valueDesc: "Wing",
    label: "EV023478: Wing"
  },
  {
    valueId: "EV023479",
    valueDesc: "C45K",
    label: "EV023479: C45K"
  },
  {
    valueId: "EV023480",
    valueDesc: "Horizontal acting toggle clamp",
    label: "EV023480: Horizontal acting toggle clamp"
  },
  {
    valueId: "EV023481",
    valueDesc: "Vertical acting toggle clamp",
    label: "EV023481: Vertical acting toggle clamp"
  },
  {
    valueId: "EV023482",
    valueDesc: "Hook type toggle clamp horizontal",
    label: "EV023482: Hook type toggle clamp horizontal"
  },
  {
    valueId: "EV023483",
    valueDesc: "Hook type toggle clamp vertical",
    label: "EV023483: Hook type toggle clamp vertical"
  },
  {
    valueId: "EV023484",
    valueDesc: "Push-pull type toggle clamp",
    label: "EV023484: Push-pull type toggle clamp"
  },
  {
    valueId: "EV023485",
    valueDesc: "Counter catch",
    label: "EV023485: Counter catch"
  },
  {
    valueId: "EV023486",
    valueDesc: "Weld spring plate",
    label: "EV023486: Weld spring plate"
  },
  {
    valueId: "EV023487",
    valueDesc: "Slide spring plate",
    label: "EV023487: Slide spring plate"
  },
  {
    valueId: "EV023488",
    valueDesc: "Spring bracket",
    label: "EV023488: Spring bracket"
  },
  {
    valueId: "EV023489",
    valueDesc: "Rivet spring plate",
    label: "EV023489: Rivet spring plate"
  },
  {
    valueId: "EV023491",
    valueDesc: "Leather welding hood",
    label: "EV023491: Leather welding hood"
  },
  {
    valueId: "EV023492",
    valueDesc: "Welding cap",
    label: "EV023492: Welding cap"
  },
  {
    valueId: "EV023493",
    valueDesc: "Welding wimple",
    label: "EV023493: Welding wimple"
  },
  {
    valueId: "EV023494",
    valueDesc: "Release unit",
    label: "EV023494: Release unit"
  },
  {
    valueId: "EV023496",
    valueDesc: "Valve spring compressor",
    label: "EV023496: Valve spring compressor"
  },
  {
    valueId: "EV023500",
    valueDesc: "Jointer plane",
    label: "EV023500: Jointer plane"
  },
  {
    valueId: "EV023501",
    valueDesc: "Bench plane",
    label: "EV023501: Bench plane"
  },
  {
    valueId: "EV023502",
    valueDesc: "Shoulder plane",
    label: "EV023502: Shoulder plane"
  },
  {
    valueId: "EV023503",
    valueDesc: "Combination plane",
    label: "EV023503: Combination plane"
  },
  {
    valueId: "EV023504",
    valueDesc: "Plane chisel",
    label: "EV023504: Plane chisel"
  },
  {
    valueId: "EV023505",
    valueDesc: "Gasket scraper",
    label: "EV023505: Gasket scraper"
  },
  {
    valueId: "EV023506",
    valueDesc: "14 inch (350)",
    label: "EV023506: 14 inch (350)"
  },
  {
    valueId: "EV023507",
    valueDesc: "16 inch (400)",
    label: "EV023507: 16 inch (400)"
  },
  {
    valueId: "EV023508",
    valueDesc: "Tile spacer",
    label: "EV023508: Tile spacer"
  },
  {
    valueId: "EV023509",
    valueDesc: "2.3/4 inch (70)",
    label: "EV023509: 2.3/4 inch (70)"
  },
  {
    valueId: "EV023510",
    valueDesc: "Valve grinding tool",
    label: "EV023510: Valve grinding tool"
  },
  {
    valueId: "EV023511",
    valueDesc: "Stainless steel 431 (1.4057)",
    label: "EV023511: Stainless steel 431 (1.4057)"
  },
  {
    valueId: "EV023512",
    valueDesc: "For hexagon socket head cap screw",
    label: "EV023512: For hexagon socket head cap screw"
  },
  {
    valueId: "EV023513",
    valueDesc: "For outside hexagon bolt and nut",
    label: "EV023513: For outside hexagon bolt and nut"
  },
  {
    valueId: "EV023514",
    valueDesc: "Saw-horse",
    label: "EV023514: Saw-horse"
  },
  {
    valueId: "EV023515",
    valueDesc: "Roll carrier",
    label: "EV023515: Roll carrier"
  },
  {
    valueId: "EV023516",
    valueDesc: "Ball-bearing carrier",
    label: "EV023516: Ball-bearing carrier"
  },
  {
    valueId: "EV023517",
    valueDesc: "Gland seal",
    label: "EV023517: Gland seal"
  },
  {
    valueId: "EV023518",
    valueDesc: "Mechanical seal",
    label: "EV023518: Mechanical seal"
  },
  {
    valueId: "EV023520",
    valueDesc: "Tool",
    label: "EV023520: Tool"
  },
  {
    valueId: "EV023521",
    valueDesc: "Etui",
    label: "EV023521: Etui"
  },
  {
    valueId: "EV023523",
    valueDesc: "Clip-on glasses",
    label: "EV023523: Clip-on glasses"
  },
  {
    valueId: "EV023524",
    valueDesc: "Side- and upper flap",
    label: "EV023524: Side- and upper flap"
  },
  {
    valueId: "EV023525",
    valueDesc: "Nose bridge",
    label: "EV023525: Nose bridge"
  },
  {
    valueId: "EV023526",
    valueDesc: "Glasses strap",
    label: "EV023526: Glasses strap"
  },
  {
    valueId: "EV023527",
    valueDesc: "Hand wiper",
    label: "EV023527: Hand wiper"
  },
  {
    valueId: "EV023528",
    valueDesc: "Filler neck",
    label: "EV023528: Filler neck"
  },
  {
    valueId: "EV023529",
    valueDesc: "Water lubricated",
    label: "EV023529: Water lubricated"
  },
  {
    valueId: "EV023530",
    valueDesc: "Oil lubricated",
    label: "EV023530: Oil lubricated"
  },
  {
    valueId: "EV023531",
    valueDesc: "Upholstering",
    label: "EV023531: Upholstering"
  },
  {
    valueId: "EV023532",
    valueDesc: "Mould",
    label: "EV023532: Mould"
  },
  {
    valueId: "EV023533",
    valueDesc: "Fixed pitch open propeller",
    label: "EV023533: Fixed pitch open propeller"
  },
  {
    valueId: "EV023534",
    valueDesc: "Controllable pitch open propeller",
    label: "EV023534: Controllable pitch open propeller"
  },
  {
    valueId: "EV023535",
    valueDesc: "Fixed pitch thruster propeller",
    label: "EV023535: Fixed pitch thruster propeller"
  },
  {
    valueId: "EV023536",
    valueDesc: "Controllable pitch thruster propeller",
    label: "EV023536: Controllable pitch thruster propeller"
  },
  {
    valueId: "EV023537",
    valueDesc: "Waterjet impeller",
    label: "EV023537: Waterjet impeller"
  },
  {
    valueId: "EV023538",
    valueDesc: "Transverse thruster propeller",
    label: "EV023538: Transverse thruster propeller"
  },
  {
    valueId: "EV023539",
    valueDesc: "Counter-rotating propeller",
    label: "EV023539: Counter-rotating propeller"
  },
  {
    valueId: "EV023540",
    valueDesc: "EI30",
    label: "EV023540: EI30"
  },
  {
    valueId: "EV023541",
    valueDesc: "EI60",
    label: "EV023541: EI60"
  },
  {
    valueId: "EV023542",
    valueDesc: "EI90",
    label: "EV023542: EI90"
  },
  {
    valueId: "EV023543",
    valueDesc: "EI120",
    label: "EV023543: EI120"
  },
  {
    valueId: "EV023544",
    valueDesc: "Shield",
    label: "EV023544: Shield"
  },
  {
    valueId: "EV023545",
    valueDesc: "Sweatband",
    label: "EV023545: Sweatband"
  },
  {
    valueId: "EV023546",
    valueDesc: "Outdoor unit",
    label: "EV023546: Outdoor unit"
  },
  {
    valueId: "EV023547",
    valueDesc: "Indoor unit",
    label: "EV023547: Indoor unit"
  },
  {
    valueId: "EV023548",
    valueDesc: "Plastic belt",
    label: "EV023548: Plastic belt"
  },
  {
    valueId: "EV023549",
    valueDesc: "Stainless steel cable",
    label: "EV023549: Stainless steel cable"
  },
  {
    valueId: "EV023550",
    valueDesc: "Galvanized cable",
    label: "EV023550: Galvanized cable"
  },
  {
    valueId: "EV023551",
    valueDesc: "Grip anchor",
    label: "EV023551: Grip anchor"
  },
  {
    valueId: "EV023552",
    valueDesc: "Beam anchor",
    label: "EV023552: Beam anchor"
  },
  {
    valueId: "EV023553",
    valueDesc: "Anchor loop sling",
    label: "EV023553: Anchor loop sling"
  },
  {
    valueId: "EV023554",
    valueDesc: "Tripod",
    label: "EV023554: Tripod"
  },
  {
    valueId: "EV023555",
    valueDesc: "Davit system",
    label: "EV023555: Davit system"
  },
  {
    valueId: "EV023556",
    valueDesc: "Cap",
    label: "EV023556: Cap"
  },
  {
    valueId: "EV023557",
    valueDesc: "Chin strap",
    label: "EV023557: Chin strap"
  },
  {
    valueId: "EV023558",
    valueDesc: "Hygiene set",
    label: "EV023558: Hygiene set"
  },
  {
    valueId: "EV023559",
    valueDesc: "Dispenser",
    label: "EV023559: Dispenser"
  },
  {
    valueId: "EV023560",
    valueDesc: "Metal welded end",
    label: "EV023560: Metal welded end"
  },
  {
    valueId: "EV023564",
    valueDesc: "Mechanical emergency steering",
    label: "EV023564: Mechanical emergency steering"
  },
  {
    valueId: "EV023565",
    valueDesc: "Passage",
    label: "EV023565: Passage"
  },
  {
    valueId: "EV023566",
    valueDesc: "Support bush",
    label: "EV023566: Support bush"
  },
  {
    valueId: "EV023568",
    valueDesc: "HEA",
    label: "EV023568: HEA"
  },
  {
    valueId: "EV023569",
    valueDesc: "HEB",
    label: "EV023569: HEB"
  },
  {
    valueId: "EV023570",
    valueDesc: "HEM",
    label: "EV023570: HEM"
  },
  {
    valueId: "EV023571",
    valueDesc: "O",
    label: "EV023571: O"
  },
  {
    valueId: "EV023572",
    valueDesc: "DN 50 - DN 65",
    label: "EV023572: DN 50 - DN 65"
  },
  {
    valueId: "EV023573",
    valueDesc: "HEAA",
    label: "EV023573: HEAA"
  },
  {
    valueId: "EV023574",
    valueDesc: "INP",
    label: "EV023574: INP"
  },
  {
    valueId: "EV023575",
    valueDesc: "IPE",
    label: "EV023575: IPE"
  },
  {
    valueId: "EV023576",
    valueDesc: "270",
    label: "EV023576: 270"
  },
  {
    valueId: "EV023577",
    valueDesc: "330",
    label: "EV023577: 330"
  },
  {
    valueId: "EV023578",
    valueDesc: "Radial",
    label: "EV023578: Radial"
  },
  {
    valueId: "EV023579",
    valueDesc: "Hook",
    label: "EV023579: Hook"
  },
  {
    valueId: "EV023580",
    valueDesc: "Securing cable",
    label: "EV023580: Securing cable"
  },
  {
    valueId: "EV023581",
    valueDesc: "18 inch (450)",
    label: "EV023581: 18 inch (450)"
  },
  {
    valueId: "EV023582",
    valueDesc: "20 inch (500)",
    label: "EV023582: 20 inch (500)"
  },
  {
    valueId: "EV023583",
    valueDesc: "22 inch (550)",
    label: "EV023583: 22 inch (550)"
  },
  {
    valueId: "EV023584",
    valueDesc: "24 inch (600)",
    label: "EV023584: 24 inch (600)"
  },
  {
    valueId: "EV023585",
    valueDesc: "Signal flag",
    label: "EV023585: Signal flag"
  },
  {
    valueId: "EV023586",
    valueDesc: "National flag",
    label: "EV023586: National flag"
  },
  {
    valueId: "EV023587",
    valueDesc: "Logo flag",
    label: "EV023587: Logo flag"
  },
  {
    valueId: "EV023588",
    valueDesc: "Earthenware",
    label: "EV023588: Earthenware"
  },
  {
    valueId: "EV023589",
    valueDesc: "Bone china",
    label: "EV023589: Bone china"
  },
  {
    valueId: "EV023590",
    valueDesc: "Stoneware",
    label: "EV023590: Stoneware"
  },
  {
    valueId: "EV023591",
    valueDesc: "HHP",
    label: "EV023591: HHP"
  },
  {
    valueId: "EV023592",
    valueDesc: "SHHP (VHHP)",
    label: "EV023592: SHHP (VHHP)"
  },
  {
    valueId: "EV023593",
    valueDesc: "U4",
    label: "EV023593: U4"
  },
  {
    valueId: "EV023594",
    valueDesc: "Gas traction spring",
    label: "EV023594: Gas traction spring"
  },
  {
    valueId: "EV023599",
    valueDesc: "Acrylic/PU",
    label: "EV023599: Acrylic/PU"
  },
  {
    valueId: "EV023600",
    valueDesc: "Alkyd",
    label: "EV023600: Alkyd"
  },
  {
    valueId: "EV023601",
    valueDesc: "Chlorinated rubber",
    label: "EV023601: Chlorinated rubber"
  },
  {
    valueId: "EV023602",
    valueDesc: "Siloxane",
    label: "EV023602: Siloxane"
  },
  {
    valueId: "EV023603",
    valueDesc: "Tin free",
    label: "EV023603: Tin free"
  },
  {
    valueId: "EV023604",
    valueDesc: "Zinc silicate",
    label: "EV023604: Zinc silicate"
  },
  {
    valueId: "EV023605",
    valueDesc: "Fuel filter",
    label: "EV023605: Fuel filter"
  },
  {
    valueId: "EV023606",
    valueDesc: "Linen",
    label: "EV023606: Linen"
  },
  {
    valueId: "EV023607",
    valueDesc: "Cotton/linen",
    label: "EV023607: Cotton/linen"
  },
  {
    valueId: "EV023608",
    valueDesc: "Lifenet",
    label: "EV023608: Lifenet"
  },
  {
    valueId: "EV023610",
    valueDesc: "Explosive",
    label: "EV023610: Explosive"
  },
  {
    valueId: "EV023611",
    valueDesc: "Polyether",
    label: "EV023611: Polyether"
  },
  {
    valueId: "EV023612",
    valueDesc: "Duck feathers",
    label: "EV023612: Duck feathers"
  },
  {
    valueId: "EV023613",
    valueDesc: "Goose feathers",
    label: "EV023613: Goose feathers"
  },
  {
    valueId: "EV023614",
    valueDesc: "Metal springs",
    label: "EV023614: Metal springs"
  },
  {
    valueId: "EV023615",
    valueDesc: "Viscoelastic polyurethane foam",
    label: "EV023615: Viscoelastic polyurethane foam"
  },
  {
    valueId: "EV023616",
    valueDesc: "Polyether foam",
    label: "EV023616: Polyether foam"
  },
  {
    valueId: "EV023617",
    valueDesc: "Polyurethane foam",
    label: "EV023617: Polyurethane foam"
  },
  {
    valueId: "EV023618",
    valueDesc: "Latex foam",
    label: "EV023618: Latex foam"
  },
  {
    valueId: "EV023619",
    valueDesc: "Silk",
    label: "EV023619: Silk"
  },
  {
    valueId: "EV023620",
    valueDesc: "Allyl diglycol carbonate (ADC)",
    label: "EV023620: Allyl diglycol carbonate (ADC)"
  },
  {
    valueId: "EV023621",
    valueDesc: "Wiper",
    label: "EV023621: Wiper"
  },
  {
    valueId: "EV023623",
    valueDesc: "Pintle",
    label: "EV023623: Pintle"
  },
  {
    valueId: "EV023624",
    valueDesc: "Pintle bush",
    label: "EV023624: Pintle bush"
  },
  {
    valueId: "EV023625",
    valueDesc: "Distance plate",
    label: "EV023625: Distance plate"
  },
  {
    valueId: "EV023627",
    valueDesc: "Disinfectant",
    label: "EV023627: Disinfectant"
  },
  {
    valueId: "EV023629",
    valueDesc: "Air filter",
    label: "EV023629: Air filter"
  },
  {
    valueId: "EV023630",
    valueDesc: "Air filter unit",
    label: "EV023630: Air filter unit"
  },
  {
    valueId: "EV023631",
    valueDesc: "Visor",
    label: "EV023631: Visor"
  },
  {
    valueId: "EV023632",
    valueDesc: "Striking plate",
    label: "EV023632: Striking plate"
  },
  {
    valueId: "EV023633",
    valueDesc: "Hoodie",
    label: "EV023633: Hoodie"
  },
  {
    valueId: "EV023634",
    valueDesc: "A0 (ISO 216)",
    label: "EV023634: A0 (ISO 216)"
  },
  {
    valueId: "EV023635",
    valueDesc: "A1 (ISO 216)",
    label: "EV023635: A1 (ISO 216)"
  },
  {
    valueId: "EV023636",
    valueDesc: "A2 (ISO 216)",
    label: "EV023636: A2 (ISO 216)"
  },
  {
    valueId: "EV023637",
    valueDesc: "A6 (ISO 216)",
    label: "EV023637: A6 (ISO 216)"
  },
  {
    valueId: "EV023638",
    valueDesc: "B0 (ISO 216)",
    label: "EV023638: B0 (ISO 216)"
  },
  {
    valueId: "EV023639",
    valueDesc: "B1 (ISO 216)",
    label: "EV023639: B1 (ISO 216)"
  },
  {
    valueId: "EV023640",
    valueDesc: "B2 (ISO 216)",
    label: "EV023640: B2 (ISO 216)"
  },
  {
    valueId: "EV023641",
    valueDesc: "B3 (ISO 216)",
    label: "EV023641: B3 (ISO 216)"
  },
  {
    valueId: "EV023642",
    valueDesc: "B4 (ISO 216)",
    label: "EV023642: B4 (ISO 216)"
  },
  {
    valueId: "EV023643",
    valueDesc: "Active (ICCP)",
    label: "EV023643: Active (ICCP)"
  },
  {
    valueId: "EV023644",
    valueDesc: "Active (ICAF)",
    label: "EV023644: Active (ICAF)"
  },
  {
    valueId: "EV023645",
    valueDesc: "Ice protection",
    label: "EV023645: Ice protection"
  },
  {
    valueId: "EV023646",
    valueDesc: "Magnesium",
    label: "EV023646: Magnesium"
  },
  {
    valueId: "EV023647",
    valueDesc: "Fairing",
    label: "EV023647: Fairing"
  },
  {
    valueId: "EV023648",
    valueDesc: "Spray nozzle",
    label: "EV023648: Spray nozzle"
  },
  {
    valueId: "EV023652",
    valueDesc: "Chin protector",
    label: "EV023652: Chin protector"
  },
  {
    valueId: "EV023653",
    valueDesc: "Pear shaped link",
    label: "EV023653: Pear shaped link"
  },
  {
    valueId: "EV023654",
    valueDesc: "Stud link",
    label: "EV023654: Stud link"
  },
  {
    valueId: "EV023655",
    valueDesc: "Studless link",
    label: "EV023655: Studless link"
  },
  {
    valueId: "EV023656",
    valueDesc: "Anchor shackle",
    label: "EV023656: Anchor shackle"
  },
  {
    valueId: "EV023657",
    valueDesc: "Kenter shackle",
    label: "EV023657: Kenter shackle"
  },
  {
    valueId: "EV023658",
    valueDesc: "Joining shackle",
    label: "EV023658: Joining shackle"
  },
  {
    valueId: "EV023659",
    valueDesc: "Anchor swivel",
    label: "EV023659: Anchor swivel"
  },
  {
    valueId: "EV023660",
    valueDesc: "Swivel forerunner",
    label: "EV023660: Swivel forerunner"
  },
  {
    valueId: "EV023661",
    valueDesc: "Anchor swivel shackle",
    label: "EV023661: Anchor swivel shackle"
  },
  {
    valueId: "EV023662",
    valueDesc: "Buoy shackle",
    label: "EV023662: Buoy shackle"
  },
  {
    valueId: "EV023663",
    valueDesc: "Combustion engine (diesel)",
    label: "EV023663: Combustion engine (diesel)"
  },
  {
    valueId: "EV023664",
    valueDesc: "Combustion engine (petrol)",
    label: "EV023664: Combustion engine (petrol)"
  },
  {
    valueId: "EV023665",
    valueDesc: "Activator",
    label: "EV023665: Activator"
  },
  {
    valueId: "EV023666",
    valueDesc: "Alert unit",
    label: "EV023666: Alert unit"
  },
  {
    valueId: "EV023667",
    valueDesc: "Direction light",
    label: "EV023667: Direction light"
  },
  {
    valueId: "EV023668",
    valueDesc: "All-round",
    label: "EV023668: All-round"
  },
  {
    valueId: "EV023669",
    valueDesc: "Red/white",
    label: "EV023669: Red/white"
  },
  {
    valueId: "EV023670",
    valueDesc: "IPX6",
    label: "EV023670: IPX6"
  },
  {
    valueId: "EV023671",
    valueDesc: "Three link adaptor",
    label: "EV023671: Three link adaptor"
  },
  {
    valueId: "EV023672",
    valueDesc: "Triangle plate",
    label: "EV023672: Triangle plate"
  },
  {
    valueId: "EV023673",
    valueDesc: "Guide roller",
    label: "EV023673: Guide roller"
  },
  {
    valueId: "EV023674",
    valueDesc: "Lamp holder",
    label: "EV023674: Lamp holder"
  },
  {
    valueId: "EV023675",
    valueDesc: "Mooring chock",
    label: "EV023675: Mooring chock"
  },
  {
    valueId: "EV023677",
    valueDesc: "Bearing housing",
    label: "EV023677: Bearing housing"
  },
  {
    valueId: "EV023678",
    valueDesc: "Grapnel",
    label: "EV023678: Grapnel"
  },
  {
    valueId: "EV023679",
    valueDesc: "Tow bracket",
    label: "EV023679: Tow bracket"
  },
  {
    valueId: "EV023680",
    valueDesc: "Map",
    label: "EV023680: Map"
  },
  {
    valueId: "EV023682",
    valueDesc: "Bow thruster unit",
    label: "EV023682: Bow thruster unit"
  },
  {
    valueId: "EV023683",
    valueDesc: "Stern thruster unit",
    label: "EV023683: Stern thruster unit"
  },
  {
    valueId: "EV023684",
    valueDesc: "Buffer",
    label: "EV023684: Buffer"
  },
  {
    valueId: "EV023685",
    valueDesc: "A516-Gr55 (1.0463)",
    label: "EV023685: A516-Gr55 (1.0463)"
  },
  {
    valueId: "EV023686",
    valueDesc: "EN AW-5086 (AlMg4)",
    label: "EV023686: EN AW-5086 (AlMg4)"
  },
  {
    valueId: "EV023687",
    valueDesc: "Wire guide",
    label: "EV023687: Wire guide"
  },
  {
    valueId: "EV023688",
    valueDesc: "Synthetic leather",
    label: "EV023688: Synthetic leather"
  },
  {
    valueId: "EV023690",
    valueDesc: "Fabric/artificial leather",
    label: "EV023690: Fabric/artificial leather"
  },
  {
    valueId: "EV023691",
    valueDesc: "Fabric/leather",
    label: "EV023691: Fabric/leather"
  },
  {
    valueId: "EV023692",
    valueDesc: "Leather/artificial leather",
    label: "EV023692: Leather/artificial leather"
  },
  {
    valueId: "EV023693",
    valueDesc: "Fabric/leather/artificial leather",
    label: "EV023693: Fabric/leather/artificial leather"
  },
  {
    valueId: "EV023694",
    valueDesc: "Fixed column",
    label: "EV023694: Fixed column"
  },
  {
    valueId: "EV023695",
    valueDesc: "Electrically adjustable column",
    label: "EV023695: Electrically adjustable column"
  },
  {
    valueId: "EV023696",
    valueDesc: "Hydraulically adjustable column",
    label: "EV023696: Hydraulically adjustable column"
  },
  {
    valueId: "EV023697",
    valueDesc: "Pneumatic adjustable column",
    label: "EV023697: Pneumatic adjustable column"
  },
  {
    valueId: "EV023698",
    valueDesc: "Manually adjustable column",
    label: "EV023698: Manually adjustable column"
  },
  {
    valueId: "EV023699",
    valueDesc: "Air suspended",
    label: "EV023699: Air suspended"
  },
  {
    valueId: "EV023700",
    valueDesc: "Cross base",
    label: "EV023700: Cross base"
  },
  {
    valueId: "EV023701",
    valueDesc: "Storage box",
    label: "EV023701: Storage box"
  },
  {
    valueId: "EV023702",
    valueDesc: "Footrest",
    label: "EV023702: Footrest"
  },
  {
    valueId: "EV023703",
    valueDesc: "Safety belt",
    label: "EV023703: Safety belt"
  },
  {
    valueId: "EV023704",
    valueDesc: "Arm support",
    label: "EV023704: Arm support"
  },
  {
    valueId: "EV023705",
    valueDesc: "Headrest",
    label: "EV023705: Headrest"
  },
  {
    valueId: "EV023706",
    valueDesc: "Lumbar support",
    label: "EV023706: Lumbar support"
  },
  {
    valueId: "EV023707",
    valueDesc: "Side lower support",
    label: "EV023707: Side lower support"
  },
  {
    valueId: "EV023708",
    valueDesc: "Seat slider",
    label: "EV023708: Seat slider"
  },
  {
    valueId: "EV023709",
    valueDesc: "(Broom)stick",
    label: "EV023709: (Broom)stick"
  },
  {
    valueId: "EV023710",
    valueDesc: "HDPE",
    label: "EV023710: HDPE"
  },
  {
    valueId: "EV023711",
    valueDesc: "Chlorofluorocarbon (CFC)",
    label: "EV023711: Chlorofluorocarbon (CFC)"
  },
  {
    valueId: "EV023712",
    valueDesc: "Chlorofluoroaniline (CFA)",
    label: "EV023712: Chlorofluoroaniline (CFA)"
  },
  {
    valueId: "EV023713",
    valueDesc: "Chain and tyre net type",
    label: "EV023713: Chain and tyre net type"
  },
  {
    valueId: "EV023714",
    valueDesc: "Sling type",
    label: "EV023714: Sling type"
  },
  {
    valueId: "EV023715",
    valueDesc: "Rib type",
    label: "EV023715: Rib type"
  },
  {
    valueId: "EV023716",
    valueDesc: "Hydro pneumatic",
    label: "EV023716: Hydro pneumatic"
  },
  {
    valueId: "EV023717",
    valueDesc: "Inflatable",
    label: "EV023717: Inflatable"
  },
  {
    valueId: "EV023718",
    valueDesc: "Car tyre",
    label: "EV023718: Car tyre"
  },
  {
    valueId: "EV023719",
    valueDesc: "Airplane tyre",
    label: "EV023719: Airplane tyre"
  },
  {
    valueId: "EV023720",
    valueDesc: "Connection piece",
    label: "EV023720: Connection piece"
  },
  {
    valueId: "EV023721",
    valueDesc: "Hand microphone",
    label: "EV023721: Hand microphone"
  },
  {
    valueId: "EV023722",
    valueDesc: "Binnacle",
    label: "EV023722: Binnacle"
  },
  {
    valueId: "EV023723",
    valueDesc: "Barrel",
    label: "EV023723: Barrel"
  },
  {
    valueId: "EV023726",
    valueDesc: "Stiff boom crane",
    label: "EV023726: Stiff boom crane"
  },
  {
    valueId: "EV023727",
    valueDesc: "Telescopic boom crane",
    label: "EV023727: Telescopic boom crane"
  },
  {
    valueId: "EV023728",
    valueDesc: "Fully foldable telescopic crane",
    label: "EV023728: Fully foldable telescopic crane"
  },
  {
    valueId: "EV023729",
    valueDesc: "Knuckle boom crane",
    label: "EV023729: Knuckle boom crane"
  },
  {
    valueId: "EV023730",
    valueDesc: "Reel",
    label: "EV023730: Reel"
  },
  {
    valueId: "EV023731",
    valueDesc: "Davit crane",
    label: "EV023731: Davit crane"
  },
  {
    valueId: "EV023732",
    valueDesc: "Bolt/weld",
    label: "EV023732: Bolt/weld"
  },
  {
    valueId: "EV023733",
    valueDesc: "A0",
    label: "EV023733: A0"
  },
  {
    valueId: "EV023734",
    valueDesc: "A15",
    label: "EV023734: A15"
  },
  {
    valueId: "EV023735",
    valueDesc: "A30",
    label: "EV023735: A30"
  },
  {
    valueId: "EV023736",
    valueDesc: "A60",
    label: "EV023736: A60"
  },
  {
    valueId: "EV023737",
    valueDesc: "B0",
    label: "EV023737: B0"
  },
  {
    valueId: "EV023738",
    valueDesc: "B15",
    label: "EV023738: B15"
  },
  {
    valueId: "EV023739",
    valueDesc: "B30",
    label: "EV023739: B30"
  },
  {
    valueId: "EV023740",
    valueDesc: "B60",
    label: "EV023740: B60"
  },
  {
    valueId: "EV023741",
    valueDesc: "Day/night",
    label: "EV023741: Day/night"
  },
  {
    valueId: "EV023742",
    valueDesc: "Free/occupied",
    label: "EV023742: Free/occupied"
  },
  {
    valueId: "EV023743",
    valueDesc: "Double free/occupied",
    label: "EV023743: Double free/occupied"
  },
  {
    valueId: "EV023746",
    valueDesc: "Rough",
    label: "EV023746: Rough"
  },
  {
    valueId: "EV023747",
    valueDesc: "Planed",
    label: "EV023747: Planed"
  },
  {
    valueId: "EV023749",
    valueDesc: "Cast iron/bronze",
    label: "EV023749: Cast iron/bronze"
  },
  {
    valueId: "EV023750",
    valueDesc: "3 x 460 V",
    label: "EV023750: 3 x 460 V"
  },
  {
    valueId: "EV023751",
    valueDesc: "Cover strip",
    label: "EV023751: Cover strip"
  },
  {
    valueId: "EV023752",
    valueDesc: "Back plate",
    label: "EV023752: Back plate"
  },
  {
    valueId: "EV023753",
    valueDesc: "Rolling",
    label: "EV023753: Rolling"
  },
  {
    valueId: "EV023754",
    valueDesc: "Sliding",
    label: "EV023754: Sliding"
  },
  {
    valueId: "EV023755",
    valueDesc: "Round flange",
    label: "EV023755: Round flange"
  },
  {
    valueId: "EV023756",
    valueDesc: "Square flange",
    label: "EV023756: Square flange"
  },
  {
    valueId: "EV023757",
    valueDesc: "Prime layer",
    label: "EV023757: Prime layer"
  },
  {
    valueId: "EV023758",
    valueDesc: "Screed layer",
    label: "EV023758: Screed layer"
  },
  {
    valueId: "EV023759",
    valueDesc: "Finish layer",
    label: "EV023759: Finish layer"
  },
  {
    valueId: "EV023760",
    valueDesc: "Grade 80",
    label: "EV023760: Grade 80"
  },
  {
    valueId: "EV023761",
    valueDesc: "Grade 100",
    label: "EV023761: Grade 100"
  },
  {
    valueId: "EV023762",
    valueDesc: "Copper plate",
    label: "EV023762: Copper plate"
  },
  {
    valueId: "EV023763",
    valueDesc: "Band copper",
    label: "EV023763: Band copper"
  },
  {
    valueId: "EV023764",
    valueDesc: "Hatch locking",
    label: "EV023764: Hatch locking"
  },
  {
    valueId: "EV023765",
    valueDesc: "Spring catch",
    label: "EV023765: Spring catch"
  },
  {
    valueId: "EV023766",
    valueDesc: "Melamine",
    label: "EV023766: Melamine"
  },
  {
    valueId: "EV023767",
    valueDesc: "Acrylate",
    label: "EV023767: Acrylate"
  },
  {
    valueId: "EV023768",
    valueDesc: "Wood veneer",
    label: "EV023768: Wood veneer"
  },
  {
    valueId: "EV023769",
    valueDesc: "Cherry wood",
    label: "EV023769: Cherry wood"
  },
  {
    valueId: "EV023770",
    valueDesc: "Mahogany wood",
    label: "EV023770: Mahogany wood"
  },
  {
    valueId: "EV023771",
    valueDesc: "Birch wood",
    label: "EV023771: Birch wood"
  },
  {
    valueId: "EV023772",
    valueDesc: "Short link",
    label: "EV023772: Short link"
  },
  {
    valueId: "EV023773",
    valueDesc: "Hard mineral",
    label: "EV023773: Hard mineral"
  },
  {
    valueId: "EV023774",
    valueDesc: "Soft mineral",
    label: "EV023774: Soft mineral"
  },
  {
    valueId: "EV023775",
    valueDesc: "Medium link",
    label: "EV023775: Medium link"
  },
  {
    valueId: "EV023776",
    valueDesc: "Long link",
    label: "EV023776: Long link"
  },
  {
    valueId: "EV023777",
    valueDesc: "Half overlay",
    label: "EV023777: Half overlay"
  },
  {
    valueId: "EV023778",
    valueDesc: "HPL (High pressured laminate)",
    label: "EV023778: HPL (High pressured laminate)"
  },
  {
    valueId: "EV023779",
    valueDesc: "Bush",
    label: "EV023779: Bush"
  },
  {
    valueId: "EV023780",
    valueDesc: "Clockwise",
    label: "EV023780: Clockwise"
  },
  {
    valueId: "EV023781",
    valueDesc: "Counter clockwise",
    label: "EV023781: Counter clockwise"
  },
  {
    valueId: "EV023782",
    valueDesc: "Tapered roller bearing",
    label: "EV023782: Tapered roller bearing"
  },
  {
    valueId: "EV023783",
    valueDesc: "Cylindrical roller bearing",
    label: "EV023783: Cylindrical roller bearing"
  },
  {
    valueId: "EV023784",
    valueDesc: "Spherical roller bearing",
    label: "EV023784: Spherical roller bearing"
  },
  {
    valueId: "EV023790",
    valueDesc: "PN 350",
    label: "EV023790: PN 350"
  },
  {
    valueId: "EV023792",
    valueDesc: "Swivel bolt",
    label: "EV023792: Swivel bolt"
  },
  {
    valueId: "EV023794",
    valueDesc: "Diamond",
    label: "EV023794: Diamond"
  },
  {
    valueId: "EV023796",
    valueDesc: "Weighing scale",
    label: "EV023796: Weighing scale"
  },
  {
    valueId: "EV023797",
    valueDesc: "Tensioner",
    label: "EV023797: Tensioner"
  },
  {
    valueId: "EV023798",
    valueDesc: "Propeller boss",
    label: "EV023798: Propeller boss"
  },
  {
    valueId: "EV023800",
    valueDesc: "Magnetron",
    label: "EV023800: Magnetron"
  },
  {
    valueId: "EV023801",
    valueDesc: "Cup",
    label: "EV023801: Cup"
  },
  {
    valueId: "EV023802",
    valueDesc: "Beer glass",
    label: "EV023802: Beer glass"
  },
  {
    valueId: "EV023803",
    valueDesc: "Champagne glass",
    label: "EV023803: Champagne glass"
  },
  {
    valueId: "EV023804",
    valueDesc: "Cocktail glass",
    label: "EV023804: Cocktail glass"
  },
  {
    valueId: "EV023805",
    valueDesc: "Coffee/tea glass",
    label: "EV023805: Coffee/tea glass"
  },
  {
    valueId: "EV023806",
    valueDesc: "Long drink/water glass",
    label: "EV023806: Long drink/water glass"
  },
  {
    valueId: "EV023807",
    valueDesc: "Carafe/water jar",
    label: "EV023807: Carafe/water jar"
  },
  {
    valueId: "EV023808",
    valueDesc: "Whiskey glass",
    label: "EV023808: Whiskey glass"
  },
  {
    valueId: "EV023809",
    valueDesc: "Wine glass",
    label: "EV023809: Wine glass"
  },
  {
    valueId: "EV023812",
    valueDesc: "Dessert plate",
    label: "EV023812: Dessert plate"
  },
  {
    valueId: "EV023813",
    valueDesc: "Dinner plate",
    label: "EV023813: Dinner plate"
  },
  {
    valueId: "EV023814",
    valueDesc: "Pastry plate",
    label: "EV023814: Pastry plate"
  },
  {
    valueId: "EV023815",
    valueDesc: "Gourmet plate",
    label: "EV023815: Gourmet plate"
  },
  {
    valueId: "EV023816",
    valueDesc: "Underplate",
    label: "EV023816: Underplate"
  },
  {
    valueId: "EV023817",
    valueDesc: "Breakfast plate",
    label: "EV023817: Breakfast plate"
  },
  {
    valueId: "EV023818",
    valueDesc: "Pasta plate",
    label: "EV023818: Pasta plate"
  },
  {
    valueId: "EV023819",
    valueDesc: "Risotto plate",
    label: "EV023819: Risotto plate"
  },
  {
    valueId: "EV023820",
    valueDesc: "Soup plate",
    label: "EV023820: Soup plate"
  },
  {
    valueId: "EV023821",
    valueDesc: "Fruit bowl",
    label: "EV023821: Fruit bowl"
  },
  {
    valueId: "EV023822",
    valueDesc: "Serving bowl",
    label: "EV023822: Serving bowl"
  },
  {
    valueId: "EV023823",
    valueDesc: "Salad bowl",
    label: "EV023823: Salad bowl"
  },
  {
    valueId: "EV023824",
    valueDesc: "Hot dish",
    label: "EV023824: Hot dish"
  },
  {
    valueId: "EV023825",
    valueDesc: "Butter dish",
    label: "EV023825: Butter dish"
  },
  {
    valueId: "EV023826",
    valueDesc: "Spoon",
    label: "EV023826: Spoon"
  },
  {
    valueId: "EV023827",
    valueDesc: "Colander",
    label: "EV023827: Colander"
  },
  {
    valueId: "EV023828",
    valueDesc: "Masher",
    label: "EV023828: Masher"
  },
  {
    valueId: "EV023829",
    valueDesc: "Pineapple drill",
    label: "EV023829: Pineapple drill"
  },
  {
    valueId: "EV023830",
    valueDesc: "Apple corer",
    label: "EV023830: Apple corer"
  },
  {
    valueId: "EV023831",
    valueDesc: "Pastry brush",
    label: "EV023831: Pastry brush"
  },
  {
    valueId: "EV023832",
    valueDesc: "Whisk",
    label: "EV023832: Whisk"
  },
  {
    valueId: "EV023833",
    valueDesc: "Scoop",
    label: "EV023833: Scoop"
  },
  {
    valueId: "EV023834",
    valueDesc: "Cheese slicer",
    label: "EV023834: Cheese slicer"
  },
  {
    valueId: "EV023835",
    valueDesc: "Garlic press",
    label: "EV023835: Garlic press"
  },
  {
    valueId: "EV023836",
    valueDesc: "Nutcracker",
    label: "EV023836: Nutcracker"
  },
  {
    valueId: "EV023837",
    valueDesc: "Grater",
    label: "EV023837: Grater"
  },
  {
    valueId: "EV023838",
    valueDesc: "Skimmer",
    label: "EV023838: Skimmer"
  },
  {
    valueId: "EV023839",
    valueDesc: "Egg slicer",
    label: "EV023839: Egg slicer"
  },
  {
    valueId: "EV023840",
    valueDesc: "Spatula",
    label: "EV023840: Spatula"
  },
  {
    valueId: "EV023841",
    valueDesc: "Tongs",
    label: "EV023841: Tongs"
  },
  {
    valueId: "EV023842",
    valueDesc: "CuSn5Zn5Pb2",
    label: "EV023842: CuSn5Zn5Pb2"
  },
  {
    valueId: "EV023845",
    valueDesc: "Egg cup",
    label: "EV023845: Egg cup"
  },
  {
    valueId: "EV023846",
    valueDesc: "LDPE",
    label: "EV023846: LDPE"
  },
  {
    valueId: "EV023847",
    valueDesc: "Oil/vinegar set",
    label: "EV023847: Oil/vinegar set"
  },
  {
    valueId: "EV023848",
    valueDesc: "Pepper/salt shaker",
    label: "EV023848: Pepper/salt shaker"
  },
  {
    valueId: "EV023849",
    valueDesc: "Sauce boat",
    label: "EV023849: Sauce boat"
  },
  {
    valueId: "EV023850",
    valueDesc: "Sugar pot",
    label: "EV023850: Sugar pot"
  },
  {
    valueId: "EV023851",
    valueDesc: "Teapot",
    label: "EV023851: Teapot"
  },
  {
    valueId: "EV023852",
    valueDesc: "Kapok",
    label: "EV023852: Kapok"
  },
  {
    valueId: "EV023853",
    valueDesc: "Feathers",
    label: "EV023853: Feathers"
  },
  {
    valueId: "EV023854",
    valueDesc: "Down",
    label: "EV023854: Down"
  },
  {
    valueId: "EV023857",
    valueDesc: "Trackball",
    label: "EV023857: Trackball"
  },
  {
    valueId: "EV023858",
    valueDesc: "Antenna for mobile VHF radio",
    label: "EV023858: Antenna for mobile VHF radio"
  },
  {
    valueId: "EV023863",
    valueDesc: "Pine",
    label: "EV023863: Pine"
  },
  {
    valueId: "EV023864",
    valueDesc: "Spruce",
    label: "EV023864: Spruce"
  },
  {
    valueId: "EV023865",
    valueDesc: "Western Red Cedar",
    label: "EV023865: Western Red Cedar"
  },
  {
    valueId: "EV023866",
    valueDesc: "European larch",
    label: "EV023866: European larch"
  },
  {
    valueId: "EV023867",
    valueDesc: "Douglas",
    label: "EV023867: Douglas"
  },
  {
    valueId: "EV023868",
    valueDesc: "Planed (smooth around)",
    label: "EV023868: Planed (smooth around)"
  },
  {
    valueId: "EV023869",
    valueDesc: "Planed profile",
    label: "EV023869: Planed profile"
  },
  {
    valueId: "EV023870",
    valueDesc: "Oiled",
    label: "EV023870: Oiled"
  },
  {
    valueId: "EV023871",
    valueDesc: "2-3",
    label: "EV023871: 2-3"
  },
  {
    valueId: "EV023872",
    valueDesc: "2-4",
    label: "EV023872: 2-4"
  },
  {
    valueId: "EV023873",
    valueDesc: "3-4",
    label: "EV023873: 3-4"
  },
  {
    valueId: "EV023875",
    valueDesc: "Mould burnt",
    label: "EV023875: Mould burnt"
  },
  {
    valueId: "EV023876",
    valueDesc: "Extruded",
    label: "EV023876: Extruded"
  },
  {
    valueId: "EV023877",
    valueDesc: "Waal",
    label: "EV023877: Waal"
  },
  {
    valueId: "EV023886",
    valueDesc: "Nuanced",
    label: "EV023886: Nuanced"
  },
  {
    valueId: "EV023887",
    valueDesc: "Tree tile",
    label: "EV023887: Tree tile"
  },
  {
    valueId: "EV023888",
    valueDesc: "Marbles tile",
    label: "EV023888: Marbles tile"
  },
  {
    valueId: "EV023889",
    valueDesc: "Guidance tile",
    label: "EV023889: Guidance tile"
  },
  {
    valueId: "EV023890",
    valueDesc: "Grass tile",
    label: "EV023890: Grass tile"
  },
  {
    valueId: "EV023891",
    valueDesc: "Grit diamond tile",
    label: "EV023891: Grit diamond tile"
  },
  {
    valueId: "EV023892",
    valueDesc: "Top layer - washed",
    label: "EV023892: Top layer - washed"
  },
  {
    valueId: "EV023893",
    valueDesc: "Text-/number-/symbol tile",
    label: "EV023893: Text-/number-/symbol tile"
  },
  {
    valueId: "EV023894",
    valueDesc: "Top layer - not washed",
    label: "EV023894: Top layer - not washed"
  },
  {
    valueId: "EV023895",
    valueDesc: "Gravel",
    label: "EV023895: Gravel"
  },
  {
    valueId: "EV023896",
    valueDesc: "Basalt",
    label: "EV023896: Basalt"
  },
  {
    valueId: "EV023897",
    valueDesc: "Door closer arm",
    label: "EV023897: Door closer arm"
  },
  {
    valueId: "EV023914",
    valueDesc: "Single plate",
    label: "EV023914: Single plate"
  },
  {
    valueId: "EV023915",
    valueDesc: "Double plate",
    label: "EV023915: Double plate"
  },
  {
    valueId: "EV023916",
    valueDesc: "s1",
    label: "EV023916: s1"
  },
  {
    valueId: "EV023917",
    valueDesc: "s2",
    label: "EV023917: s2"
  },
  {
    valueId: "EV023918",
    valueDesc: "s3",
    label: "EV023918: s3"
  },
  {
    valueId: "EV023919",
    valueDesc: "d0",
    label: "EV023919: d0"
  },
  {
    valueId: "EV023920",
    valueDesc: "d1",
    label: "EV023920: d1"
  },
  {
    valueId: "EV023921",
    valueDesc: "d2",
    label: "EV023921: d2"
  },
  {
    valueId: "EV023922",
    valueDesc: "Book",
    label: "EV023922: Book"
  },
  {
    valueId: "EV023923",
    valueDesc: "Grease lubricated",
    label: "EV023923: Grease lubricated"
  },
  {
    valueId: "EV023926",
    valueDesc: "NACA",
    label: "EV023926: NACA"
  },
  {
    valueId: "EV023927",
    valueDesc: "Manganese bronze",
    label: "EV023927: Manganese bronze"
  },
  {
    valueId: "EV023928",
    valueDesc: "Nickle-aluminium bronze",
    label: "EV023928: Nickle-aluminium bronze"
  },
  {
    valueId: "EV023929",
    valueDesc: "Triple",
    label: "EV023929: Triple"
  },
  {
    valueId: "EV023930",
    valueDesc: "S355JR (1.0045)",
    label: "EV023930: S355JR (1.0045)"
  },
  {
    valueId: "EV023931",
    valueDesc: "St 52-3 N (1.0576)",
    label: "EV023931: St 52-3 N (1.0576)"
  },
  {
    valueId: "EV023933",
    valueDesc: "CuSn5Zn5Pb5-C",
    label: "EV023933: CuSn5Zn5Pb5-C"
  },
  {
    valueId: "EV023934",
    valueDesc: "Fluxgate",
    label: "EV023934: Fluxgate"
  },
  {
    valueId: "EV023935",
    valueDesc: "Gyro",
    label: "EV023935: Gyro"
  },
  {
    valueId: "EV023936",
    valueDesc: "GPS",
    label: "EV023936: GPS"
  },
  {
    valueId: "EV023938",
    valueDesc: "AST 52 (1.0577)",
    label: "EV023938: AST 52 (1.0577)"
  },
  {
    valueId: "EV023939",
    valueDesc: "Inner propeller shaft starboard",
    label: "EV023939: Inner propeller shaft starboard"
  },
  {
    valueId: "EV023940",
    valueDesc: "Inner propeller shaft port side",
    label: "EV023940: Inner propeller shaft port side"
  },
  {
    valueId: "EV023941",
    valueDesc: "Outer propeller shaft starboard",
    label: "EV023941: Outer propeller shaft starboard"
  },
  {
    valueId: "EV023942",
    valueDesc: "Outer propeller shaft port side",
    label: "EV023942: Outer propeller shaft port side"
  },
  {
    valueId: "EV023943",
    valueDesc: "Centre propeller shaft",
    label: "EV023943: Centre propeller shaft"
  },
  {
    valueId: "EV023944",
    valueDesc: "Propeller shaft starboard",
    label: "EV023944: Propeller shaft starboard"
  },
  {
    valueId: "EV023945",
    valueDesc: "Propeller shaft port side",
    label: "EV023945: Propeller shaft port side"
  },
  {
    valueId: "EV023946",
    valueDesc: "I-strut",
    label: "EV023946: I-strut"
  },
  {
    valueId: "EV023947",
    valueDesc: "V-strut",
    label: "EV023947: V-strut"
  },
  {
    valueId: "EV023948",
    valueDesc: "Rectangular bar",
    label: "EV023948: Rectangular bar"
  },
  {
    valueId: "EV023949",
    valueDesc: "Railing",
    label: "EV023949: Railing"
  },
  {
    valueId: "EV023950",
    valueDesc: "Motor bearing set",
    label: "EV023950: Motor bearing set"
  },
  {
    valueId: "EV023951",
    valueDesc: "Pump bearing set",
    label: "EV023951: Pump bearing set"
  },
  {
    valueId: "EV023952",
    valueDesc: "Pump head",
    label: "EV023952: Pump head"
  },
  {
    valueId: "EV023953",
    valueDesc: "Float switch",
    label: "EV023953: Float switch"
  },
  {
    valueId: "EV023954",
    valueDesc: "Pressure switch",
    label: "EV023954: Pressure switch"
  },
  {
    valueId: "EV023956",
    valueDesc: "44",
    label: "EV023956: 44"
  },
  {
    valueId: "EV023957",
    valueDesc: "Sandwich",
    label: "EV023957: Sandwich"
  },
  {
    valueId: "EV023958",
    valueDesc: "M30",
    label: "EV023958: M30"
  },
  {
    valueId: "EV023959",
    valueDesc: "M36",
    label: "EV023959: M36"
  },
  {
    valueId: "EV023960",
    valueDesc: "Stabilizer",
    label: "EV023960: Stabilizer"
  },
  {
    valueId: "EV023961",
    valueDesc: "Fixed support",
    label: "EV023961: Fixed support"
  },
  {
    valueId: "EV023963",
    valueDesc: "Plug stop",
    label: "EV023963: Plug stop"
  },
  {
    valueId: "EV023964",
    valueDesc: "Cylindrical with radius end",
    label: "EV023964: Cylindrical with radius end"
  },
  {
    valueId: "EV023965",
    valueDesc: "Bracket mounting",
    label: "EV023965: Bracket mounting"
  },
  {
    valueId: "EV023966",
    valueDesc: "Peak hook",
    label: "EV023966: Peak hook"
  },
  {
    valueId: "EV023967",
    valueDesc: "Claw hook",
    label: "EV023967: Claw hook"
  },
  {
    valueId: "EV023968",
    valueDesc: "D-ring",
    label: "EV023968: D-ring"
  },
  {
    valueId: "EV023969",
    valueDesc: "Groove hole fitting",
    label: "EV023969: Groove hole fitting"
  },
  {
    valueId: "EV023970",
    valueDesc: "Round hole fitting",
    label: "EV023970: Round hole fitting"
  },
  {
    valueId: "EV023971",
    valueDesc: "Loop",
    label: "EV023971: Loop"
  },
  {
    valueId: "EV023973",
    valueDesc: "Angled input",
    label: "EV023973: Angled input"
  },
  {
    valueId: "EV023974",
    valueDesc: "Angled output",
    label: "EV023974: Angled output"
  },
  {
    valueId: "EV023975",
    valueDesc: "Angled input and output",
    label: "EV023975: Angled input and output"
  },
  {
    valueId: "EV023976",
    valueDesc: "Diesel (MGO)",
    label: "EV023976: Diesel (MGO)"
  },
  {
    valueId: "EV023977",
    valueDesc: "Diesel (HFO)",
    label: "EV023977: Diesel (HFO)"
  },
  {
    valueId: "EV023979",
    valueDesc: "Hull",
    label: "EV023979: Hull"
  },
  {
    valueId: "EV023980",
    valueDesc: "Dome shape",
    label: "EV023980: Dome shape"
  },
  {
    valueId: "EV023981",
    valueDesc: "Bullet shape",
    label: "EV023981: Bullet shape"
  },
  {
    valueId: "EV023982",
    valueDesc: "IP6K9K",
    label: "EV023982: IP6K9K"
  },
  {
    valueId: "EV023983",
    valueDesc: "Composite video",
    label: "EV023983: Composite video"
  },
  {
    valueId: "EV023987",
    valueDesc: "USB 3.0",
    label: "EV023987: USB 3.0"
  },
  {
    valueId: "EV023988",
    valueDesc: "USB Micro B",
    label: "EV023988: USB Micro B"
  },
  {
    valueId: "EV023989",
    valueDesc: "TH/U/H",
    label: "EV023989: TH/U/H"
  },
  {
    valueId: "EV023990",
    valueDesc: "Position feedback",
    label: "EV023990: Position feedback"
  },
  {
    valueId: "EV023994",
    valueDesc: "PA 6.6 - G30",
    label: "EV023994: PA 6.6 - G30"
  },
  {
    valueId: "EV023995",
    valueDesc: "Reinforcing steel",
    label: "EV023995: Reinforcing steel"
  },
  {
    valueId: "EV024003",
    valueDesc: "Worm screw clamp for hose",
    label: "EV024003: Worm screw clamp for hose"
  },
  {
    valueId: "EV024005",
    valueDesc: "Installation set",
    label: "EV024005: Installation set"
  },
  {
    valueId: "EV024007",
    valueDesc: "Installation tube with system connection",
    label: "EV024007: Installation tube with system connection"
  },
  {
    valueId: "EV024008",
    valueDesc: "Weather protection roof",
    label: "EV024008: Weather protection roof"
  },
  {
    valueId: "EV024009",
    valueDesc: "Charging cable holder",
    label: "EV024009: Charging cable holder"
  },
  {
    valueId: "EV024010",
    valueDesc: "WLAN extension",
    label: "EV024010: WLAN extension"
  },
  {
    valueId: "EV024011",
    valueDesc: "LTE extension",
    label: "EV024011: LTE extension"
  },
  {
    valueId: "EV024012",
    valueDesc: "Flush-mounted/hollow wall",
    label: "EV024012: Flush-mounted/hollow wall"
  },
  {
    valueId: "EV024014",
    valueDesc: "Single panel",
    label: "EV024014: Single panel"
  },
  {
    valueId: "EV024015",
    valueDesc: "Power parts",
    label: "EV024015: Power parts"
  },
  {
    valueId: "EV024018",
    valueDesc: "Wood wool magnesite slab",
    label: "EV024018: Wood wool magnesite slab"
  },
  {
    valueId: "EV024020",
    valueDesc: "Sheep's wool",
    label: "EV024020: Sheep's wool"
  },
  {
    valueId: "EV024021",
    valueDesc: "Wood wool",
    label: "EV024021: Wood wool"
  },
  {
    valueId: "EV024026",
    valueDesc: "Galvanic/electrolytic zinc plated and powder coating",
    label: "EV024026: Galvanic/electrolytic zinc plated and powder coating"
  },
  {
    valueId: "EV024029",
    valueDesc: "Electrically tested",
    label: "EV024029: Electrically tested"
  },
  {
    valueId: "EV024030",
    valueDesc: "Tested according to DGUV regulation 3",
    label: "EV024030: Tested according to DGUV regulation 3"
  },
  {
    valueId: "EV024031",
    valueDesc: "Next test",
    label: "EV024031: Next test"
  },
  {
    valueId: "EV024032",
    valueDesc: "Fiberglass fuse",
    label: "EV024032: Fiberglass fuse"
  },
  {
    valueId: "EV024033",
    valueDesc: "Castor set",
    label: "EV024033: Castor set"
  },
  {
    valueId: "EV024034",
    valueDesc: "Transportation set",
    label: "EV024034: Transportation set"
  },
  {
    valueId: "EV024035",
    valueDesc: "Mesh cable tray",
    label: "EV024035: Mesh cable tray"
  },
  {
    valueId: "EV024037",
    valueDesc: "Hybrid (battery/diesel)",
    label: "EV024037: Hybrid (battery/diesel)"
  },
  {
    valueId: "EV024038",
    valueDesc: "Solar/battery",
    label: "EV024038: Solar/battery"
  },
  {
    valueId: "EV024039",
    valueDesc: "Printed",
    label: "EV024039: Printed"
  },
  {
    valueId: "EV024041",
    valueDesc: "Slide one-part with one fixed panel",
    label: "EV024041: Slide one-part with one fixed panel"
  },
  {
    valueId: "EV024044",
    valueDesc: "Factory made rubber (NBR) according to EN 14304:2009+A1",
    label: "EV024044: Factory made rubber (NBR) according to EN 14304:2009+A1"
  },
  {
    valueId: "EV024045",
    valueDesc: "Factory made low density polyethylene (LDPE) according to EN 14313:2009+A1",
    label: "EV024045: Factory made low density polyethylene (LDPE) according to EN 14313:2009+A1"
  },
  {
    valueId: "EV024046",
    valueDesc: "Factory made rubber (EPDM) according to EN 14304:2009+A1",
    label: "EV024046: Factory made rubber (EPDM) according to EN 14304:2009+A1"
  },
  {
    valueId: "EV024047",
    valueDesc: "M0",
    label: "EV024047: M0"
  },
  {
    valueId: "EV024048",
    valueDesc: "M1 (1000)",
    label: "EV024048: M1 (1000)"
  },
  {
    valueId: "EV024049",
    valueDesc: "M1 (2000)",
    label: "EV024049: M1 (2000)"
  },
  {
    valueId: "EV024050",
    valueDesc: "M2 (5000)",
    label: "EV024050: M2 (5000)"
  },
  {
    valueId: "EV024051",
    valueDesc: "M2 (10000)",
    label: "EV024051: M2 (10000)"
  },
  {
    valueId: "EV024052",
    valueDesc: "E0",
    label: "EV024052: E0"
  },
  {
    valueId: "EV024055",
    valueDesc: "1:10",
    label: "EV024055: 1:10"
  },
  {
    valueId: "EV024056",
    valueDesc: "1:1000",
    label: "EV024056: 1:1000"
  },
  {
    valueId: "EV024057",
    valueDesc: "EI180",
    label: "EV024057: EI180"
  },
  {
    valueId: "EV024058",
    valueDesc: "Trough",
    label: "EV024058: Trough"
  },
  {
    valueId: "EV024059",
    valueDesc: "Steel reinforced concrete",
    label: "EV024059: Steel reinforced concrete"
  },
  {
    valueId: "EV024064",
    valueDesc: "Cable entry foam",
    label: "EV024064: Cable entry foam"
  },
  {
    valueId: "EV024065",
    valueDesc: "CEM II/A-M (V-L or LL)",
    label: "EV024065: CEM II/A-M (V-L or LL)"
  },
  {
    valueId: "EV024066",
    valueDesc: "CEM II/A-M (L or LL-V)",
    label: "EV024066: CEM II/A-M (L or LL-V)"
  },
  {
    valueId: "EV024067",
    valueDesc: "CEM II/B-M (S-L or LL)",
    label: "EV024067: CEM II/B-M (S-L or LL)"
  },
  {
    valueId: "EV024068",
    valueDesc: "CEM II/B-M (L or LL-S)",
    label: "EV024068: CEM II/B-M (L or LL-S)"
  },
  {
    valueId: "EV024069",
    valueDesc: "CEM II/B-M (P or Q-L or LL)",
    label: "EV024069: CEM II/B-M (P or Q-L or LL)"
  },
  {
    valueId: "EV024070",
    valueDesc: "CEM II/B-M (L or LL-P or Q)",
    label: "EV024070: CEM II/B-M (L or LL-P or Q)"
  },
  {
    valueId: "EV024071",
    valueDesc: "CEM II/B-M (V-L or LL)",
    label: "EV024071: CEM II/B-M (V-L or LL)"
  },
  {
    valueId: "EV024072",
    valueDesc: "CEM II/B-M (L or LL-V)",
    label: "EV024072: CEM II/B-M (L or LL-V)"
  },
  {
    valueId: "EV024073",
    valueDesc: "CEM III/A+SR",
    label: "EV024073: CEM III/A+SR"
  },
  {
    valueId: "EV024074",
    valueDesc: "CEM III/B+SR",
    label: "EV024074: CEM III/B+SR"
  },
  {
    valueId: "EV024075",
    valueDesc: "CEM IV/B(P)",
    label: "EV024075: CEM IV/B(P)"
  },
  {
    valueId: "EV024076",
    valueDesc: "CEM IV/B(Q)",
    label: "EV024076: CEM IV/B(Q)"
  },
  {
    valueId: "EV024077",
    valueDesc: "CEM IV/B(V)",
    label: "EV024077: CEM IV/B(V)"
  },
  {
    valueId: "EV024081",
    valueDesc: "Hydrated lime",
    label: "EV024081: Hydrated lime"
  },
  {
    valueId: "EV024082",
    valueDesc: "Natural hydraulic lime",
    label: "EV024082: Natural hydraulic lime"
  },
  {
    valueId: "EV024083",
    valueDesc: "Designed",
    label: "EV024083: Designed"
  },
  {
    valueId: "EV024084",
    valueDesc: "Prescribed",
    label: "EV024084: Prescribed"
  },
  {
    valueId: "EV024085",
    valueDesc: "Air and gas insulated",
    label: "EV024085: Air and gas insulated"
  },
  {
    valueId: "EV024086",
    valueDesc: "A1L",
    label: "EV024086: A1L"
  },
  {
    valueId: "EV024087",
    valueDesc: "BL",
    label: "EV024087: BL"
  },
  {
    valueId: "EV024088",
    valueDesc: "CL",
    label: "EV024088: CL"
  },
  {
    valueId: "EV024089",
    valueDesc: "DL",
    label: "EV024089: DL"
  },
  {
    valueId: "EV024090",
    valueDesc: "EL",
    label: "EV024090: EL"
  },
  {
    valueId: "EV024091",
    valueDesc: "FL",
    label: "EV024091: FL"
  },
  {
    valueId: "EV024092",
    valueDesc: "Multi-part (lags)",
    label: "EV024092: Multi-part (lags)"
  },
  {
    valueId: "EV024097",
    valueDesc: "Grate lock",
    label: "EV024097: Grate lock"
  },
  {
    valueId: "EV024098",
    valueDesc: "At the primary supply pipe",
    label: "EV024098: At the primary supply pipe"
  },
  {
    valueId: "EV024099",
    valueDesc: "At the primary return pipe",
    label: "EV024099: At the primary return pipe"
  },
  {
    valueId: "EV024113",
    valueDesc: "Suitable as smoke protection door",
    label: "EV024113: Suitable as smoke protection door"
  },
  {
    valueId: "EV024114",
    valueDesc: "Suitable as fire protection door",
    label: "EV024114: Suitable as fire protection door"
  },
  {
    valueId: "EV024115",
    valueDesc: "Suitable as a fire and smoke protection door",
    label: "EV024115: Suitable as a fire and smoke protection door"
  },
  {
    valueId: "EV024120",
    valueDesc: "In escape routes with a rotating fitting",
    label: "EV024120: In escape routes with a rotating fitting"
  },
  {
    valueId: "EV024121",
    valueDesc: "In escape routes without rotating fittings",
    label: "EV024121: In escape routes without rotating fittings"
  },
  {
    valueId: "EV024122",
    valueDesc: "For self-closing fire protection doors with rotating fittings",
    label: "EV024122: For self-closing fire protection doors with rotating fittings"
  },
  {
    valueId: "EV024123",
    valueDesc: "For self-closing fire protection doors without rotating fittings",
    label: "EV024123: For self-closing fire protection doors without rotating fittings"
  },
  {
    valueId: "EV024130",
    valueDesc: "-15 - 50 °C",
    label: "EV024130: -15 - 50 °C"
  },
  {
    valueId: "EV024131",
    valueDesc: "-15 - 75 °C",
    label: "EV024131: -15 - 75 °C"
  },
  {
    valueId: "EV024135",
    valueDesc: "Sliding guide",
    label: "EV024135: Sliding guide"
  },
  {
    valueId: "EV024136",
    valueDesc: "Ball bearing slide",
    label: "EV024136: Ball bearing slide"
  },
  {
    valueId: "EV024137",
    valueDesc: "Roller guide",
    label: "EV024137: Roller guide"
  },
  {
    valueId: "EV024138",
    valueDesc: "Partial extendible",
    label: "EV024138: Partial extendible"
  },
  {
    valueId: "EV024139",
    valueDesc: "Overextension",
    label: "EV024139: Overextension"
  },
  {
    valueId: "EV024140",
    valueDesc: "AHT-72",
    label: "EV024140: AHT-72"
  },
  {
    valueId: "EV024141",
    valueDesc: "NSS-24",
    label: "EV024141: NSS-24"
  },
  {
    valueId: "EV024142",
    valueDesc: "NSS-48",
    label: "EV024142: NSS-48"
  },
  {
    valueId: "EV024143",
    valueDesc: "NSS-72",
    label: "EV024143: NSS-72"
  },
  {
    valueId: "EV024144",
    valueDesc: "NSS-96",
    label: "EV024144: NSS-96"
  },
  {
    valueId: "EV024145",
    valueDesc: "Joint",
    label: "EV024145: Joint"
  },
  {
    valueId: "EV024146",
    valueDesc: "Joint bearing",
    label: "EV024146: Joint bearing"
  },
  {
    valueId: "EV024147",
    valueDesc: "Post/frame transmission",
    label: "EV024147: Post/frame transmission"
  },
  {
    valueId: "EV024148",
    valueDesc: "Corner angle transmission",
    label: "EV024148: Corner angle transmission"
  },
  {
    valueId: "EV024149",
    valueDesc: "Control for window motor",
    label: "EV024149: Control for window motor"
  },
  {
    valueId: "EV024150",
    valueDesc: "Secondary closing edge",
    label: "EV024150: Secondary closing edge"
  },
  {
    valueId: "EV024151",
    valueDesc: "Crank bar",
    label: "EV024151: Crank bar"
  },
  {
    valueId: "EV024198",
    valueDesc: "Fair-faced concrete",
    label: "EV024198: Fair-faced concrete"
  },
  {
    valueId: "EV024199",
    valueDesc: "G3 (Green Gas for Grid)",
    label: "EV024199: G3 (Green Gas for Grid)"
  },
  {
    valueId: "EV024201",
    valueDesc: "Polyphthalamides (PPA)",
    label: "EV024201: Polyphthalamides (PPA)"
  },
  {
    valueId: "EV024202",
    valueDesc: "Redispersible powder",
    label: "EV024202: Redispersible powder"
  },
  {
    valueId: "EV024203",
    valueDesc: "Fiber",
    label: "EV024203: Fiber"
  },
  {
    valueId: "EV024206",
    valueDesc: "Socket outlet CEE 7/3 (type F)",
    label: "EV024206: Socket outlet CEE 7/3 (type F)"
  },
  {
    valueId: "EV024207",
    valueDesc: "Coupler CEE 7/3 (Typ F)",
    label: "EV024207: Coupler CEE 7/3 (Typ F)"
  },
  {
    valueId: "EV024208",
    valueDesc: "Cutting blade",
    label: "EV024208: Cutting blade"
  },
  {
    valueId: "EV024211",
    valueDesc: "Keystone",
    label: "EV024211: Keystone"
  },
  {
    valueId: "EV024212",
    valueDesc: "Metal wire mesh",
    label: "EV024212: Metal wire mesh"
  },
  {
    valueId: "EV024213",
    valueDesc: "Club hammer",
    label: "EV024213: Club hammer"
  },
  {
    valueId: "EV024214",
    valueDesc: "Plastic damp proof foil",
    label: "EV024214: Plastic damp proof foil"
  },
  {
    valueId: "EV024215",
    valueDesc: "Fuse system A",
    label: "EV024215: Fuse system A"
  },
  {
    valueId: "EV024216",
    valueDesc: "Fuse system B",
    label: "EV024216: Fuse system B"
  },
  {
    valueId: "EV024217",
    valueDesc: "Fuse system C",
    label: "EV024217: Fuse system C"
  },
  {
    valueId: "EV024218",
    valueDesc: "Fuse system E",
    label: "EV024218: Fuse system E"
  },
  {
    valueId: "EV024219",
    valueDesc: "Fuse system G",
    label: "EV024219: Fuse system G"
  },
  {
    valueId: "EV024220",
    valueDesc: "Fuse system I",
    label: "EV024220: Fuse system I"
  },
  {
    valueId: "EV024221",
    valueDesc: "IEC 60269-4 flush end connections type A",
    label: "EV024221: IEC 60269-4 flush end connections type A"
  },
  {
    valueId: "EV024222",
    valueDesc: "IEC 60269-4 flush end connections type B",
    label: "EV024222: IEC 60269-4 flush end connections type B"
  },
  {
    valueId: "EV024225",
    valueDesc: "1.6",
    label: "EV024225: 1.6"
  },
  {
    valueId: "EV024226",
    valueDesc: "Pendant kit",
    label: "EV024226: Pendant kit"
  },
  {
    valueId: "EV024227",
    valueDesc: "Attraction",
    label: "EV024227: Attraction"
  },
  {
    valueId: "EV024228",
    valueDesc: "Corner hinge",
    label: "EV024228: Corner hinge"
  },
  {
    valueId: "EV024229",
    valueDesc: "Rebate corner hinge",
    label: "EV024229: Rebate corner hinge"
  },
  {
    valueId: "EV024230",
    valueDesc: "Bottom hinge",
    label: "EV024230: Bottom hinge"
  },
  {
    valueId: "EV024231",
    valueDesc: "Swing one-part with two fixed panels",
    label: "EV024231: Swing one-part with two fixed panels"
  },
  {
    valueId: "EV024232",
    valueDesc: "5/20",
    label: "EV024232: 5/20"
  },
  {
    valueId: "EV024233",
    valueDesc: "15/35",
    label: "EV024233: 15/35"
  },
  {
    valueId: "EV024234",
    valueDesc: "0,5",
    label: "EV024234: 0,5"
  },
  {
    valueId: "EV024235",
    valueDesc: "4-rows",
    label: "EV024235: 4-rows"
  },
  {
    valueId: "EV024236",
    valueDesc: "Sledge hammer",
    label: "EV024236: Sledge hammer"
  }
];

const etimFeatures = [
  {
    featureId: "EF000001",
    featureDesc: "Nominal current",
    label: "EF000001: Nominal current"
  },
  {
    featureId: "EF000002",
    featureDesc: "Nominal voltage",
    label: "EF000002: Nominal voltage"
  },
  {
    featureId: "EF000003",
    featureDesc: "Mounting method",
    label: "EF000003: Mounting method"
  },
  {
    featureId: "EF000004",
    featureDesc: "Protection class",
    label: "EF000004: Protection class"
  },
  {
    featureId: "EF000005",
    featureDesc: "Assembly arrangement",
    label: "EF000005: Assembly arrangement"
  },
  {
    featureId: "EF000006",
    featureDesc: "Method of operation",
    label: "EF000006: Method of operation"
  },
  {
    featureId: "EF000007",
    featureDesc: "Colour",
    label: "EF000007: Colour"
  },
  {
    featureId: "EF000008",
    featureDesc: "Width",
    label: "EF000008: Width"
  },
  {
    featureId: "EF000010",
    featureDesc: "Model",
    label: "EF000010: Model"
  },
  {
    featureId: "EF000012",
    featureDesc: "Head form",
    label: "EF000012: Head form"
  },
  {
    featureId: "EF000013",
    featureDesc: "Screwing system",
    label: "EF000013: Screwing system"
  },
  {
    featureId: "EF000014",
    featureDesc: "Sleeve length",
    label: "EF000014: Sleeve length"
  },
  {
    featureId: "EF000015",
    featureDesc: "Outer diameter",
    label: "EF000015: Outer diameter"
  },
  {
    featureId: "EF000016",
    featureDesc: "Suitable for max. cable diameter",
    label: "EF000016: Suitable for max. cable diameter"
  },
  {
    featureId: "EF000017",
    featureDesc: "Watertight",
    label: "EF000017: Watertight"
  },
  {
    featureId: "EF000018",
    featureDesc: "Core cross section",
    label: "EF000018: Core cross section"
  },
  {
    featureId: "EF000019",
    featureDesc: "Insulated",
    label: "EF000019: Insulated"
  },
  {
    featureId: "EF000022",
    featureDesc: "Oil resistant",
    label: "EF000022: Oil resistant"
  },
  {
    featureId: "EF000023",
    featureDesc: "Heat-resistant",
    label: "EF000023: Heat-resistant"
  },
  {
    featureId: "EF000024",
    featureDesc: "UV resistant",
    label: "EF000024: UV resistant"
  },
  {
    featureId: "EF000025",
    featureDesc: "Halogen free",
    label: "EF000025: Halogen free"
  },
  {
    featureId: "EF000026",
    featureDesc: "Duct width",
    label: "EF000026: Duct width"
  },
  {
    featureId: "EF000028",
    featureDesc: "Principle of operation",
    label: "EF000028: Principle of operation"
  },
  {
    featureId: "EF000029",
    featureDesc: "Contacts",
    label: "EF000029: Contacts"
  },
  {
    featureId: "EF000030",
    featureDesc: "Function",
    label: "EF000030: Function"
  },
  {
    featureId: "EF000033",
    featureDesc: "Switch",
    label: "EF000033: Switch"
  },
  {
    featureId: "EF000034",
    featureDesc: "Frequency",
    label: "EF000034: Frequency"
  },
  {
    featureId: "EF000035",
    featureDesc: "Lamp power",
    label: "EF000035: Lamp power"
  },
  {
    featureId: "EF000036",
    featureDesc: "Reflector",
    label: "EF000036: Reflector"
  },
  {
    featureId: "EF000037",
    featureDesc: "Circuit",
    label: "EF000037: Circuit"
  },
  {
    featureId: "EF000038",
    featureDesc: "Screening",
    label: "EF000038: Screening"
  },
  {
    featureId: "EF000039",
    featureDesc: "Suitable for through-wiring",
    label: "EF000039: Suitable for through-wiring"
  },
  {
    featureId: "EF000040",
    featureDesc: "Height",
    label: "EF000040: Height"
  },
  {
    featureId: "EF000041",
    featureDesc: "Application",
    label: "EF000041: Application"
  },
  {
    featureId: "EF000042",
    featureDesc: "Socket",
    label: "EF000042: Socket"
  },
  {
    featureId: "EF000043",
    featureDesc: "Number of loudspeaker outputs",
    label: "EF000043: Number of loudspeaker outputs"
  },
  {
    featureId: "EF000046",
    featureDesc: "Wireless",
    label: "EF000046: Wireless"
  },
  {
    featureId: "EF000047",
    featureDesc: "Amperage",
    label: "EF000047: Amperage"
  },
  {
    featureId: "EF000048",
    featureDesc: "Lamp holder",
    label: "EF000048: Lamp holder"
  },
  {
    featureId: "EF000049",
    featureDesc: "Depth",
    label: "EF000049: Depth"
  },
  {
    featureId: "EF000051",
    featureDesc: "Shape",
    label: "EF000051: Shape"
  },
  {
    featureId: "EF000054",
    featureDesc: "Number of poles",
    label: "EF000054: Number of poles"
  },
  {
    featureId: "EF000055",
    featureDesc: "Cover",
    label: "EF000055: Cover"
  },
  {
    featureId: "EF000057",
    featureDesc: "Main switch",
    label: "EF000057: Main switch"
  },
  {
    featureId: "EF000058",
    featureDesc: "Max. cable cross section",
    label: "EF000058: Max. cable cross section"
  },
  {
    featureId: "EF000060",
    featureDesc: "Nominal cross section conductor",
    label: "EF000060: Nominal cross section conductor"
  },
  {
    featureId: "EF000061",
    featureDesc: "Material core insulation",
    label: "EF000061: Material core insulation"
  },
  {
    featureId: "EF000062",
    featureDesc: "Rotatable",
    label: "EF000062: Rotatable"
  },
  {
    featureId: "EF000063",
    featureDesc: "Length belt/tape/tie",
    label: "EF000063: Length belt/tape/tie"
  },
  {
    featureId: "EF000064",
    featureDesc: "Type of connection",
    label: "EF000064: Type of connection"
  },
  {
    featureId: "EF000065",
    featureDesc: "Inner diameter",
    label: "EF000065: Inner diameter"
  },
  {
    featureId: "EF000066",
    featureDesc: "Suitable for lamp type",
    label: "EF000066: Suitable for lamp type"
  },
  {
    featureId: "EF000067",
    featureDesc: "Core identification",
    label: "EF000067: Core identification"
  },
  {
    featureId: "EF000068",
    featureDesc: "Dielectric",
    label: "EF000068: Dielectric"
  },
  {
    featureId: "EF000069",
    featureDesc: "Total number of fibre bundles",
    label: "EF000069: Total number of fibre bundles"
  },
  {
    featureId: "EF000071",
    featureDesc: "With carpet border",
    label: "EF000071: With carpet border"
  },
  {
    featureId: "EF000072",
    featureDesc: "Duct height",
    label: "EF000072: Duct height"
  },
  {
    featureId: "EF000073",
    featureDesc: "Suitable for",
    label: "EF000073: Suitable for"
  },
  {
    featureId: "EF000074",
    featureDesc: "Number of units",
    label: "EF000074: Number of units"
  },
  {
    featureId: "EF000076",
    featureDesc: "Power",
    label: "EF000076: Power"
  },
  {
    featureId: "EF000078",
    featureDesc: "Luminous flux",
    label: "EF000078: Luminous flux"
  },
  {
    featureId: "EF000079",
    featureDesc: "Hole diameter",
    label: "EF000079: Hole diameter"
  },
  {
    featureId: "EF000080",
    featureDesc: "Fibre type",
    label: "EF000080: Fibre type"
  },
  {
    featureId: "EF000081",
    featureDesc: "Cable type",
    label: "EF000081: Cable type"
  },
  {
    featureId: "EF000083",
    featureDesc: "Flexible",
    label: "EF000083: Flexible"
  },
  {
    featureId: "EF000084",
    featureDesc: "Shock resistant",
    label: "EF000084: Shock resistant"
  },
  {
    featureId: "EF000085",
    featureDesc: "Conductor cross section",
    label: "EF000085: Conductor cross section"
  },
  {
    featureId: "EF000088",
    featureDesc: "Size",
    label: "EF000088: Size"
  },
  {
    featureId: "EF000089",
    featureDesc: "Light centre height",
    label: "EF000089: Light centre height"
  },
  {
    featureId: "EF000090",
    featureDesc: "Mounting arm length",
    label: "EF000090: Mounting arm length"
  },
  {
    featureId: "EF000091",
    featureDesc: "Collapsible",
    label: "EF000091: Collapsible"
  },
  {
    featureId: "EF000093",
    featureDesc: "With base plate",
    label: "EF000093: With base plate"
  },
  {
    featureId: "EF000097",
    featureDesc: "Self adhesive mounting",
    label: "EF000097: Self adhesive mounting"
  },
  {
    featureId: "EF000098",
    featureDesc: "Type of side wall",
    label: "EF000098: Type of side wall"
  },
  {
    featureId: "EF000101",
    featureDesc: "Application range",
    label: "EF000101: Application range"
  },
  {
    featureId: "EF000103",
    featureDesc: "Colour temperature",
    label: "EF000103: Colour temperature"
  },
  {
    featureId: "EF000104",
    featureDesc: "Supply voltage",
    label: "EF000104: Supply voltage"
  },
  {
    featureId: "EF000105",
    featureDesc: "Mains voltage",
    label: "EF000105: Mains voltage"
  },
  {
    featureId: "EF000106",
    featureDesc: "Input voltage",
    label: "EF000106: Input voltage"
  },
  {
    featureId: "EF000107",
    featureDesc: "Output voltage",
    label: "EF000107: Output voltage"
  },
  {
    featureId: "EF000108",
    featureDesc: "Galvanic separation",
    label: "EF000108: Galvanic separation"
  },
  {
    featureId: "EF000109",
    featureDesc: "Electronic",
    label: "EF000109: Electronic"
  },
  {
    featureId: "EF000111",
    featureDesc: "Operating voltage",
    label: "EF000111: Operating voltage"
  },
  {
    featureId: "EF000112",
    featureDesc: "Output",
    label: "EF000112: Output"
  },
  {
    featureId: "EF000113",
    featureDesc: "Lockable",
    label: "EF000113: Lockable"
  },
  {
    featureId: "EF000114",
    featureDesc: "Closed housing",
    label: "EF000114: Closed housing"
  },
  {
    featureId: "EF000115",
    featureDesc: "Perforated",
    label: "EF000115: Perforated"
  },
  {
    featureId: "EF000116",
    featureDesc: "RAL-number",
    label: "EF000116: RAL-number"
  },
  {
    featureId: "EF000117",
    featureDesc: "With hinged lid",
    label: "EF000117: With hinged lid"
  },
  {
    featureId: "EF000118",
    featureDesc: "With mounting plate",
    label: "EF000118: With mounting plate"
  },
  {
    featureId: "EF000119",
    featureDesc: "Expandable",
    label: "EF000119: Expandable"
  },
  {
    featureId: "EF000120",
    featureDesc: "Directional characteristic",
    label: "EF000120: Directional characteristic"
  },
  {
    featureId: "EF000121",
    featureDesc: "Tape width",
    label: "EF000121: Tape width"
  },
  {
    featureId: "EF000122",
    featureDesc: "Number of doors",
    label: "EF000122: Number of doors"
  },
  {
    featureId: "EF000123",
    featureDesc: "Number of locks",
    label: "EF000123: Number of locks"
  },
  {
    featureId: "EF000124",
    featureDesc: "Connection type",
    label: "EF000124: Connection type"
  },
  {
    featureId: "EF000125",
    featureDesc: "Thickness",
    label: "EF000125: Thickness"
  },
  {
    featureId: "EF000126",
    featureDesc: "Number of connections",
    label: "EF000126: Number of connections"
  },
  {
    featureId: "EF000128",
    featureDesc: "Beam angle",
    label: "EF000128: Beam angle"
  },
  {
    featureId: "EF000129",
    featureDesc: "Text strip",
    label: "EF000129: Text strip"
  },
  {
    featureId: "EF000131",
    featureDesc: "Imprint",
    label: "EF000131: Imprint"
  },
  {
    featureId: "EF000132",
    featureDesc: "Nominal dimension",
    label: "EF000132: Nominal dimension"
  },
  {
    featureId: "EF000133",
    featureDesc: "Utilization",
    label: "EF000133: Utilization"
  },
  {
    featureId: "EF000134",
    featureDesc: "Illumination",
    label: "EF000134: Illumination"
  },
  {
    featureId: "EF000136",
    featureDesc: "Housing colour",
    label: "EF000136: Housing colour"
  },
  {
    featureId: "EF000137",
    featureDesc: "Dimmable",
    label: "EF000137: Dimmable"
  },
  {
    featureId: "EF000138",
    featureDesc: "Housing shape",
    label: "EF000138: Housing shape"
  },
  {
    featureId: "EF000139",
    featureDesc: "Surface protection",
    label: "EF000139: Surface protection"
  },
  {
    featureId: "EF000141",
    featureDesc: "Type of connector",
    label: "EF000141: Type of connector"
  },
  {
    featureId: "EF000143",
    featureDesc: "Type of current",
    label: "EF000143: Type of current"
  },
  {
    featureId: "EF000144",
    featureDesc: "Loudness",
    label: "EF000144: Loudness"
  },
  {
    featureId: "EF000145",
    featureDesc: "Current supply",
    label: "EF000145: Current supply"
  },
  {
    featureId: "EF000146",
    featureDesc: "Number of contacts",
    label: "EF000146: Number of contacts"
  },
  {
    featureId: "EF000147",
    featureDesc: "Creep-under protection",
    label: "EF000147: Creep-under protection"
  },
  {
    featureId: "EF000148",
    featureDesc: "With connection cord and plug",
    label: "EF000148: With connection cord and plug"
  },
  {
    featureId: "EF000150",
    featureDesc: "Housing",
    label: "EF000150: Housing"
  },
  {
    featureId: "EF000152",
    featureDesc: "Suitable for steel wire braiding",
    label: "EF000152: Suitable for steel wire braiding"
  },
  {
    featureId: "EF000153",
    featureDesc: "Replaceable cutting head",
    label: "EF000153: Replaceable cutting head"
  },
  {
    featureId: "EF000154",
    featureDesc: "Length of blade",
    label: "EF000154: Length of blade"
  },
  {
    featureId: "EF000155",
    featureDesc: "Insulation",
    label: "EF000155: Insulation"
  },
  {
    featureId: "EF000160",
    featureDesc: "Packaging",
    label: "EF000160: Packaging"
  },
  {
    featureId: "EF000162",
    featureDesc: "Cutting edge",
    label: "EF000162: Cutting edge"
  },
  {
    featureId: "EF000163",
    featureDesc: "With wire cutter",
    label: "EF000163: With wire cutter"
  },
  {
    featureId: "EF000165",
    featureDesc: "Pliers shape",
    label: "EF000165: Pliers shape"
  },
  {
    featureId: "EF000167",
    featureDesc: "Weight",
    label: "EF000167: Weight"
  },
  {
    featureId: "EF000168",
    featureDesc: "With cutting device",
    label: "EF000168: With cutting device"
  },
  {
    featureId: "EF000169",
    featureDesc: "Nominal power",
    label: "EF000169: Nominal power"
  },
  {
    featureId: "EF000173",
    featureDesc: "No-load stroke rate",
    label: "EF000173: No-load stroke rate"
  },
  {
    featureId: "EF000174",
    featureDesc: "Stroke",
    label: "EF000174: Stroke"
  },
  {
    featureId: "EF000176",
    featureDesc: "Reservoir volume",
    label: "EF000176: Reservoir volume"
  },
  {
    featureId: "EF000177",
    featureDesc: "Wrench width",
    label: "EF000177: Wrench width"
  },
  {
    featureId: "EF000178",
    featureDesc: "Shaft length",
    label: "EF000178: Shaft length"
  },
  {
    featureId: "EF000185",
    featureDesc: "Warming-up time",
    label: "EF000185: Warming-up time"
  },
  {
    featureId: "EF000186",
    featureDesc: "Soldering tip shape",
    label: "EF000186: Soldering tip shape"
  },
  {
    featureId: "EF000187",
    featureDesc: "Voltage type",
    label: "EF000187: Voltage type"
  },
  {
    featureId: "EF000188",
    featureDesc: "Switching current",
    label: "EF000188: Switching current"
  },
  {
    featureId: "EF000189",
    featureDesc: "Switching function",
    label: "EF000189: Switching function"
  },
  {
    featureId: "EF000190",
    featureDesc: "Colour button",
    label: "EF000190: Colour button"
  },
  {
    featureId: "EF000193",
    featureDesc: "Switching-on delay",
    label: "EF000193: Switching-on delay"
  },
  {
    featureId: "EF000194",
    featureDesc: "Switch-off delay",
    label: "EF000194: Switch-off delay"
  },
  {
    featureId: "EF000197",
    featureDesc: "With movement sensor",
    label: "EF000197: With movement sensor"
  },
  {
    featureId: "EF000198",
    featureDesc: "With signal lamp",
    label: "EF000198: With signal lamp"
  },
  {
    featureId: "EF000199",
    featureDesc: "Suitable for duct cover height",
    label: "EF000199: Suitable for duct cover height"
  },
  {
    featureId: "EF000202",
    featureDesc: "Autonomy",
    label: "EF000202: Autonomy"
  },
  {
    featureId: "EF000203",
    featureDesc: "Number of memory locations",
    label: "EF000203: Number of memory locations"
  },
  {
    featureId: "EF000204",
    featureDesc: "Time program",
    label: "EF000204: Time program"
  },
  {
    featureId: "EF000205",
    featureDesc: "Shortest switching time",
    label: "EF000205: Shortest switching time"
  },
  {
    featureId: "EF000206",
    featureDesc: "Radio-controlled time setting",
    label: "EF000206: Radio-controlled time setting"
  },
  {
    featureId: "EF000207",
    featureDesc: "Colour lens",
    label: "EF000207: Colour lens"
  },
  {
    featureId: "EF000208",
    featureDesc: "Type of locking",
    label: "EF000208: Type of locking"
  },
  {
    featureId: "EF000209",
    featureDesc: "Cable assembly",
    label: "EF000209: Cable assembly"
  },
  {
    featureId: "EF000213",
    featureDesc: "Voltage",
    label: "EF000213: Voltage"
  },
  {
    featureId: "EF000214",
    featureDesc: "Scope of delivery",
    label: "EF000214: Scope of delivery"
  },
  {
    featureId: "EF000215",
    featureDesc: "Type of accessory/spare part",
    label: "EF000215: Type of accessory/spare part"
  },
  {
    featureId: "EF000218",
    featureDesc: "Built-in depth",
    label: "EF000218: Built-in depth"
  },
  {
    featureId: "EF000219",
    featureDesc: "Number of appliances to build in",
    label: "EF000219: Number of appliances to build in"
  },
  {
    featureId: "EF000222",
    featureDesc: "Shortest switching interval",
    label: "EF000222: Shortest switching interval"
  },
  {
    featureId: "EF000223",
    featureDesc: "Random generator",
    label: "EF000223: Random generator"
  },
  {
    featureId: "EF000224",
    featureDesc: "Astro program",
    label: "EF000224: Astro program"
  },
  {
    featureId: "EF000227",
    featureDesc: "Rated current",
    label: "EF000227: Rated current"
  },
  {
    featureId: "EF000228",
    featureDesc: "Rated voltage",
    label: "EF000228: Rated voltage"
  },
  {
    featureId: "EF000229",
    featureDesc: "Wall thickness",
    label: "EF000229: Wall thickness"
  },
  {
    featureId: "EF000230",
    featureDesc: "Construction",
    label: "EF000230: Construction"
  },
  {
    featureId: "EF000231",
    featureDesc: "Number of switch positions",
    label: "EF000231: Number of switch positions"
  },
  {
    featureId: "EF000232",
    featureDesc: "Max. connected load",
    label: "EF000232: Max. connected load"
  },
  {
    featureId: "EF000233",
    featureDesc: "Power supply system",
    label: "EF000233: Power supply system"
  },
  {
    featureId: "EF000234",
    featureDesc: "With memory function",
    label: "EF000234: With memory function"
  },
  {
    featureId: "EF000237",
    featureDesc: "Adjustment",
    label: "EF000237: Adjustment"
  },
  {
    featureId: "EF000241",
    featureDesc: "With dimmer",
    label: "EF000241: With dimmer"
  },
  {
    featureId: "EF000242",
    featureDesc: "Signal transmission",
    label: "EF000242: Signal transmission"
  },
  {
    featureId: "EF000243",
    featureDesc: "Diameter openings",
    label: "EF000243: Diameter openings"
  },
  {
    featureId: "EF000244",
    featureDesc: "Suitable for control current circuit",
    label: "EF000244: Suitable for control current circuit"
  },
  {
    featureId: "EF000245",
    featureDesc: "Suitable for main current circuit",
    label: "EF000245: Suitable for main current circuit"
  },
  {
    featureId: "EF000249",
    featureDesc: "Type of ventilation",
    label: "EF000249: Type of ventilation"
  },
  {
    featureId: "EF000250",
    featureDesc: "Max. number of revolutions",
    label: "EF000250: Max. number of revolutions"
  },
  {
    featureId: "EF000252",
    featureDesc: "With mounting rail",
    label: "EF000252: With mounting rail"
  },
  {
    featureId: "EF000256",
    featureDesc: "With time switch clock",
    label: "EF000256: With time switch clock"
  },
  {
    featureId: "EF000258",
    featureDesc: "Max. load capacity",
    label: "EF000258: Max. load capacity"
  },
  {
    featureId: "EF000259",
    featureDesc: "Number of channels",
    label: "EF000259: Number of channels"
  },
  {
    featureId: "EF000260",
    featureDesc: "Max. lamp power",
    label: "EF000260: Max. lamp power"
  },
  {
    featureId: "EF000261",
    featureDesc: "Number of fields",
    label: "EF000261: Number of fields"
  },
  {
    featureId: "EF000262",
    featureDesc: "Connection space",
    label: "EF000262: Connection space"
  },
  {
    featureId: "EF000264",
    featureDesc: "With additional heating system",
    label: "EF000264: With additional heating system"
  },
  {
    featureId: "EF000266",
    featureDesc: "Number of rows",
    label: "EF000266: Number of rows"
  },
  {
    featureId: "EF000267",
    featureDesc: "Max. cross section incoming cable",
    label: "EF000267: Max. cross section incoming cable"
  },
  {
    featureId: "EF000268",
    featureDesc: "Max. cross section connection cable",
    label: "EF000268: Max. cross section connection cable"
  },
  {
    featureId: "EF000269",
    featureDesc: "For installation kit",
    label: "EF000269: For installation kit"
  },
  {
    featureId: "EF000275",
    featureDesc: "Shape of cable lug",
    label: "EF000275: Shape of cable lug"
  },
  {
    featureId: "EF000276",
    featureDesc: "Panel entry",
    label: "EF000276: Panel entry"
  },
  {
    featureId: "EF000277",
    featureDesc: "With seal head",
    label: "EF000277: With seal head"
  },
  {
    featureId: "EF000278",
    featureDesc: "With transformer",
    label: "EF000278: With transformer"
  },
  {
    featureId: "EF000279",
    featureDesc: "Type of transformer",
    label: "EF000279: Type of transformer"
  },
  {
    featureId: "EF000280",
    featureDesc: "Suitable for lamp power",
    label: "EF000280: Suitable for lamp power"
  },
  {
    featureId: "EF000282",
    featureDesc: "Type of grid",
    label: "EF000282: Type of grid"
  },
  {
    featureId: "EF000287",
    featureDesc: "For transformer power",
    label: "EF000287: For transformer power"
  },
  {
    featureId: "EF000289",
    featureDesc: "With socket outlet",
    label: "EF000289: With socket outlet"
  },
  {
    featureId: "EF000291",
    featureDesc: "Sensor element",
    label: "EF000291: Sensor element"
  },
  {
    featureId: "EF000292",
    featureDesc: "Material of light head",
    label: "EF000292: Material of light head"
  },
  {
    featureId: "EF000301",
    featureDesc: "Swivelling",
    label: "EF000301: Swivelling"
  },
  {
    featureId: "EF000302",
    featureDesc: "With twilight switch",
    label: "EF000302: With twilight switch"
  },
  {
    featureId: "EF000303",
    featureDesc: "Overhang length",
    label: "EF000303: Overhang length"
  },
  {
    featureId: "EF000305",
    featureDesc: "With PC/printer interface",
    label: "EF000305: With PC/printer interface"
  },
  {
    featureId: "EF000306",
    featureDesc: "Type of indication",
    label: "EF000306: Type of indication"
  },
  {
    featureId: "EF000307",
    featureDesc: "Measured value memory",
    label: "EF000307: Measured value memory"
  },
  {
    featureId: "EF000308",
    featureDesc: "Grease filling against corrosion",
    label: "EF000308: Grease filling against corrosion"
  },
  {
    featureId: "EF000309",
    featureDesc: "Min. inner diameter",
    label: "EF000309: Min. inner diameter"
  },
  {
    featureId: "EF000311",
    featureDesc: "Modular construction",
    label: "EF000311: Modular construction"
  },
  {
    featureId: "EF000312",
    featureDesc: "Overcurrent protection",
    label: "EF000312: Overcurrent protection"
  },
  {
    featureId: "EF000313",
    featureDesc: "Max. data transmission rate",
    label: "EF000313: Max. data transmission rate"
  },
  {
    featureId: "EF000315",
    featureDesc: "Connection type primary side",
    label: "EF000315: Connection type primary side"
  },
  {
    featureId: "EF000316",
    featureDesc: "Connection type secondary side",
    label: "EF000316: Connection type secondary side"
  },
  {
    featureId: "EF000317",
    featureDesc: "Bi-directional",
    label: "EF000317: Bi-directional"
  },
  {
    featureId: "EF000318",
    featureDesc: "Number of inputs",
    label: "EF000318: Number of inputs"
  },
  {
    featureId: "EF000319",
    featureDesc: "Number of outputs",
    label: "EF000319: Number of outputs"
  },
  {
    featureId: "EF000321",
    featureDesc: "Connection 1",
    label: "EF000321: Connection 1"
  },
  {
    featureId: "EF000322",
    featureDesc: "Connection 2",
    label: "EF000322: Connection 2"
  },
  {
    featureId: "EF000323",
    featureDesc: "Outlet direction",
    label: "EF000323: Outlet direction"
  },
  {
    featureId: "EF000324",
    featureDesc: "Suitable for aeration/de-aeration",
    label: "EF000324: Suitable for aeration/de-aeration"
  },
  {
    featureId: "EF000325",
    featureDesc: "With cable entry",
    label: "EF000325: With cable entry"
  },
  {
    featureId: "EF000326",
    featureDesc: "Suitable for enclosure building width",
    label: "EF000326: Suitable for enclosure building width"
  },
  {
    featureId: "EF000327",
    featureDesc: "Suitable for enclosure building depth",
    label: "EF000327: Suitable for enclosure building depth"
  },
  {
    featureId: "EF000329",
    featureDesc: "Extendable",
    label: "EF000329: Extendable"
  },
  {
    featureId: "EF000332",
    featureDesc: "Built-in height",
    label: "EF000332: Built-in height"
  },
  {
    featureId: "EF000336",
    featureDesc: "Number of socket outlets",
    label: "EF000336: Number of socket outlets"
  },
  {
    featureId: "EF000337",
    featureDesc: "Suitable for 19 inch rack mounting",
    label: "EF000337: Suitable for 19 inch rack mounting"
  },
  {
    featureId: "EF000338",
    featureDesc: "For max. number of fans",
    label: "EF000338: For max. number of fans"
  },
  {
    featureId: "EF000339",
    featureDesc: "Type of covering",
    label: "EF000339: Type of covering"
  },
  {
    featureId: "EF000341",
    featureDesc: "With mounting arm",
    label: "EF000341: With mounting arm"
  },
  {
    featureId: "EF000342",
    featureDesc: "Time indicator",
    label: "EF000342: Time indicator"
  },
  {
    featureId: "EF000343",
    featureDesc: "With date indication",
    label: "EF000343: With date indication"
  },
  {
    featureId: "EF000344",
    featureDesc: "Heating capacity",
    label: "EF000344: Heating capacity"
  },
  {
    featureId: "EF000346",
    featureDesc: "Power consumption",
    label: "EF000346: Power consumption"
  },
  {
    featureId: "EF000347",
    featureDesc: "Control",
    label: "EF000347: Control"
  },
  {
    featureId: "EF000348",
    featureDesc: "Insert",
    label: "EF000348: Insert"
  },
  {
    featureId: "EF000349",
    featureDesc: "Fake money restitution",
    label: "EF000349: Fake money restitution"
  },
  {
    featureId: "EF000350",
    featureDesc: "Indication",
    label: "EF000350: Indication"
  },
  {
    featureId: "EF000351",
    featureDesc: "Number of phases",
    label: "EF000351: Number of phases"
  },
  {
    featureId: "EF000352",
    featureDesc: "Max. door/gate width",
    label: "EF000352: Max. door/gate width"
  },
  {
    featureId: "EF000353",
    featureDesc: "Max. tensile force",
    label: "EF000353: Max. tensile force"
  },
  {
    featureId: "EF000354",
    featureDesc: "Belt thickness",
    label: "EF000354: Belt thickness"
  },
  {
    featureId: "EF000355",
    featureDesc: "Belt length",
    label: "EF000355: Belt length"
  },
  {
    featureId: "EF000356",
    featureDesc: "Max. weight of roller shutter",
    label: "EF000356: Max. weight of roller shutter"
  },
  {
    featureId: "EF000357",
    featureDesc: "Moment of torque",
    label: "EF000357: Moment of torque"
  },
  {
    featureId: "EF000358",
    featureDesc: "Length of power cord",
    label: "EF000358: Length of power cord"
  },
  {
    featureId: "EF000359",
    featureDesc: "Type of stand/base",
    label: "EF000359: Type of stand/base"
  },
  {
    featureId: "EF000360",
    featureDesc: "Display",
    label: "EF000360: Display"
  },
  {
    featureId: "EF000361",
    featureDesc: "With cover",
    label: "EF000361: With cover"
  },
  {
    featureId: "EF000362",
    featureDesc: "With name plate",
    label: "EF000362: With name plate"
  },
  {
    featureId: "EF000363",
    featureDesc: "Size indication",
    label: "EF000363: Size indication"
  },
  {
    featureId: "EF000364",
    featureDesc: "Capacity",
    label: "EF000364: Capacity"
  },
  {
    featureId: "EF000365",
    featureDesc: "High-speed battery charger",
    label: "EF000365: High-speed battery charger"
  },
  {
    featureId: "EF000368",
    featureDesc: "Thread length",
    label: "EF000368: Thread length"
  },
  {
    featureId: "EF000369",
    featureDesc: "Total length",
    label: "EF000369: Total length"
  },
  {
    featureId: "EF000370",
    featureDesc: "With nail",
    label: "EF000370: With nail"
  },
  {
    featureId: "EF000371",
    featureDesc: "Nail length",
    label: "EF000371: Nail length"
  },
  {
    featureId: "EF000372",
    featureDesc: "Range",
    label: "EF000372: Range"
  },
  {
    featureId: "EF000375",
    featureDesc: "Opening direction according to DIN",
    label: "EF000375: Opening direction according to DIN"
  },
  {
    featureId: "EF000376",
    featureDesc: "Shape of strike plate",
    label: "EF000376: Shape of strike plate"
  },
  {
    featureId: "EF000377",
    featureDesc: "With mechanical unlocking",
    label: "EF000377: With mechanical unlocking"
  },
  {
    featureId: "EF000378",
    featureDesc: "With locking",
    label: "EF000378: With locking"
  },
  {
    featureId: "EF000379",
    featureDesc: "With feedback signal",
    label: "EF000379: With feedback signal"
  },
  {
    featureId: "EF000380",
    featureDesc: "Water protected",
    label: "EF000380: Water protected"
  },
  {
    featureId: "EF000381",
    featureDesc: "Type of control gear",
    label: "EF000381: Type of control gear"
  },
  {
    featureId: "EF000382",
    featureDesc: "Length of anchor plug",
    label: "EF000382: Length of anchor plug"
  },
  {
    featureId: "EF000384",
    featureDesc: "Min. drill hole depth",
    label: "EF000384: Min. drill hole depth"
  },
  {
    featureId: "EF000385",
    featureDesc: "With screw",
    label: "EF000385: With screw"
  },
  {
    featureId: "EF000386",
    featureDesc: "Material thickness",
    label: "EF000386: Material thickness"
  },
  {
    featureId: "EF000387",
    featureDesc: "Suitable for number of cables NYM 3x1.5",
    label: "EF000387: Suitable for number of cables NYM 3x1.5"
  },
  {
    featureId: "EF000388",
    featureDesc: "Number of compartments",
    label: "EF000388: Number of compartments"
  },
  {
    featureId: "EF000389",
    featureDesc: "With enhanced contact protection",
    label: "EF000389: With enhanced contact protection"
  },
  {
    featureId: "EF000391",
    featureDesc: "With strip code",
    label: "EF000391: With strip code"
  },
  {
    featureId: "EF000392",
    featureDesc: "Built-in possibility for over voltage protection",
    label: "EF000392: Built-in possibility for over voltage protection"
  },
  {
    featureId: "EF000393",
    featureDesc: "Number of mountable connection strips",
    label: "EF000393: Number of mountable connection strips"
  },
  {
    featureId: "EF000394",
    featureDesc: "Modular spacing",
    label: "EF000394: Modular spacing"
  },
  {
    featureId: "EF000396",
    featureDesc: "Suitable for type of connection",
    label: "EF000396: Suitable for type of connection"
  },
  {
    featureId: "EF000397",
    featureDesc: "Mounting dimension (standardised)",
    label: "EF000397: Mounting dimension (standardised)"
  },
  {
    featureId: "EF000398",
    featureDesc: "With connecting lugs",
    label: "EF000398: With connecting lugs"
  },
  {
    featureId: "EF000408",
    featureDesc: "Number of CEE socket outlets (IEC 60309) 16 A / 400 V / 5P (1, 2, 3, N, PE)",
    label: "EF000408: Number of CEE socket outlets (IEC 60309) 16 A / 400 V / 5P (1, 2, 3, N, PE)"
  },
  {
    featureId: "EF000409",
    featureDesc: "Number of CEE socket outlets (IEC 60309) 32 A / 400 V / 5P (1, 2, 3, N, PE)",
    label: "EF000409: Number of CEE socket outlets (IEC 60309) 32 A / 400 V / 5P (1, 2, 3, N, PE)"
  },
  {
    featureId: "EF000410",
    featureDesc: "Number of CEE socket outlets (IEC 60309) 63 A / 400 V / 5P (1, 2, 3, N, PE)",
    label: "EF000410: Number of CEE socket outlets (IEC 60309) 63 A / 400 V / 5P (1, 2, 3, N, PE)"
  },
  {
    featureId: "EF000411",
    featureDesc: "Number of socket outlets with protective contact CEE 7/3 (type F)",
    label: "EF000411: Number of socket outlets with protective contact CEE 7/3 (type F)"
  },
  {
    featureId: "EF000412",
    featureDesc: "Residual current circuit breaker (RCCB)",
    label: "EF000412: Residual current circuit breaker (RCCB)"
  },
  {
    featureId: "EF000413",
    featureDesc: "Measuring range selection",
    label: "EF000413: Measuring range selection"
  },
  {
    featureId: "EF000414",
    featureDesc: "Current measuring range, AC",
    label: "EF000414: Current measuring range, AC"
  },
  {
    featureId: "EF000415",
    featureDesc: "Current measuring range, DC",
    label: "EF000415: Current measuring range, DC"
  },
  {
    featureId: "EF000416",
    featureDesc: "Frequency",
    label: "EF000416: Frequency"
  },
  {
    featureId: "EF000417",
    featureDesc: "Current measuring range",
    label: "EF000417: Current measuring range"
  },
  {
    featureId: "EF000418",
    featureDesc: "Resistance measuring range",
    label: "EF000418: Resistance measuring range"
  },
  {
    featureId: "EF000419",
    featureDesc: "Jaw opening",
    label: "EF000419: Jaw opening"
  },
  {
    featureId: "EF000420",
    featureDesc: "True RMS measurement",
    label: "EF000420: True RMS measurement"
  },
  {
    featureId: "EF000421",
    featureDesc: "Lightning impulse current (10/350 µs)",
    label: "EF000421: Lightning impulse current (10/350 µs)"
  },
  {
    featureId: "EF000422",
    featureDesc: "With remote signalling contact",
    label: "EF000422: With remote signalling contact"
  },
  {
    featureId: "EF000425",
    featureDesc: "Type of storage medium",
    label: "EF000425: Type of storage medium"
  },
  {
    featureId: "EF000426",
    featureDesc: "Output power",
    label: "EF000426: Output power"
  },
  {
    featureId: "EF000428",
    featureDesc: "Bridging time full load",
    label: "EF000428: Bridging time full load"
  },
  {
    featureId: "EF000432",
    featureDesc: "Suitable for network-management",
    label: "EF000432: Suitable for network-management"
  },
  {
    featureId: "EF000434",
    featureDesc: "Self-adhesive",
    label: "EF000434: Self-adhesive"
  },
  {
    featureId: "EF000436",
    featureDesc: "Number of device cups",
    label: "EF000436: Number of device cups"
  },
  {
    featureId: "EF000437",
    featureDesc: "Number of conduit inlets",
    label: "EF000437: Number of conduit inlets"
  },
  {
    featureId: "EF000441",
    featureDesc: "Adjustable",
    label: "EF000441: Adjustable"
  },
  {
    featureId: "EF000442",
    featureDesc: "Colour rendering index CRI",
    label: "EF000442: Colour rendering index CRI"
  },
  {
    featureId: "EF000443",
    featureDesc: "Connection",
    label: "EF000443: Connection"
  },
  {
    featureId: "EF000444",
    featureDesc: "Loading capacity",
    label: "EF000444: Loading capacity"
  },
  {
    featureId: "EF000445",
    featureDesc: "Height (from centre water connection)",
    label: "EF000445: Height (from centre water connection)"
  },
  {
    featureId: "EF000446",
    featureDesc: "Connected load",
    label: "EF000446: Connected load"
  },
  {
    featureId: "EF000447",
    featureDesc: "Material reservoir",
    label: "EF000447: Material reservoir"
  },
  {
    featureId: "EF000448",
    featureDesc: "Control element",
    label: "EF000448: Control element"
  },
  {
    featureId: "EF000449",
    featureDesc: "Colour boiler tank",
    label: "EF000449: Colour boiler tank"
  },
  {
    featureId: "EF000451",
    featureDesc: "With acoustic cook signal",
    label: "EF000451: With acoustic cook signal"
  },
  {
    featureId: "EF000452",
    featureDesc: "Systems",
    label: "EF000452: Systems"
  },
  {
    featureId: "EF000453",
    featureDesc: "Operation mode",
    label: "EF000453: Operation mode"
  },
  {
    featureId: "EF000455",
    featureDesc: "With tap/mixer",
    label: "EF000455: With tap/mixer"
  },
  {
    featureId: "EF000456",
    featureDesc: "With heat exchanger",
    label: "EF000456: With heat exchanger"
  },
  {
    featureId: "EF000457",
    featureDesc: "With thermal insulation",
    label: "EF000457: With thermal insulation"
  },
  {
    featureId: "EF000458",
    featureDesc: "With display",
    label: "EF000458: With display"
  },
  {
    featureId: "EF000460",
    featureDesc: "Heating system",
    label: "EF000460: Heating system"
  },
  {
    featureId: "EF000462",
    featureDesc: "Type of regulation",
    label: "EF000462: Type of regulation"
  },
  {
    featureId: "EF000463",
    featureDesc: "With heater connection",
    label: "EF000463: With heater connection"
  },
  {
    featureId: "EF000465",
    featureDesc: "Nominal input additional heating system",
    label: "EF000465: Nominal input additional heating system"
  },
  {
    featureId: "EF000466",
    featureDesc: "Standby power consumption/24 h",
    label: "EF000466: Standby power consumption/24 h"
  },
  {
    featureId: "EF000467",
    featureDesc: "Number of selectable power levels",
    label: "EF000467: Number of selectable power levels"
  },
  {
    featureId: "EF000469",
    featureDesc: "With temperature controller",
    label: "EF000469: With temperature controller"
  },
  {
    featureId: "EF000470",
    featureDesc: "With static discharge",
    label: "EF000470: With static discharge"
  },
  {
    featureId: "EF000471",
    featureDesc: "Flame retardant",
    label: "EF000471: Flame retardant"
  },
  {
    featureId: "EF000472",
    featureDesc: "Number of fibres",
    label: "EF000472: Number of fibres"
  },
  {
    featureId: "EF000473",
    featureDesc: "Spiral length (retracted)",
    label: "EF000473: Spiral length (retracted)"
  },
  {
    featureId: "EF000474",
    featureDesc: "Spiral length (extended)",
    label: "EF000474: Spiral length (extended)"
  },
  {
    featureId: "EF000476",
    featureDesc: "Number of indoor stations",
    label: "EF000476: Number of indoor stations"
  },
  {
    featureId: "EF000477",
    featureDesc: "Number of outdoor stations",
    label: "EF000477: Number of outdoor stations"
  },
  {
    featureId: "EF000478",
    featureDesc: "Colour indoor station",
    label: "EF000478: Colour indoor station"
  },
  {
    featureId: "EF000479",
    featureDesc: "With heating function",
    label: "EF000479: With heating function"
  },
  {
    featureId: "EF000480",
    featureDesc: "Cool power",
    label: "EF000480: Cool power"
  },
  {
    featureId: "EF000483",
    featureDesc: "With remote control",
    label: "EF000483: With remote control"
  },
  {
    featureId: "EF000486",
    featureDesc: "Surface",
    label: "EF000486: Surface"
  },
  {
    featureId: "EF000488",
    featureDesc: "With on/off switch",
    label: "EF000488: With on/off switch"
  },
  {
    featureId: "EF000493",
    featureDesc: "Immersing depth",
    label: "EF000493: Immersing depth"
  },
  {
    featureId: "EF000495",
    featureDesc: "Number of charger circuits",
    label: "EF000495: Number of charger circuits"
  },
  {
    featureId: "EF000499",
    featureDesc: "Receiving range",
    label: "EF000499: Receiving range"
  },
  {
    featureId: "EF000500",
    featureDesc: "Max. gain",
    label: "EF000500: Max. gain"
  },
  {
    featureId: "EF000501",
    featureDesc: "Suitable for twin modules",
    label: "EF000501: Suitable for twin modules"
  },
  {
    featureId: "EF000502",
    featureDesc: "Max. number of programs/channels to process",
    label: "EF000502: Max. number of programs/channels to process"
  },
  {
    featureId: "EF000503",
    featureDesc: "Stereo",
    label: "EF000503: Stereo"
  },
  {
    featureId: "EF000504",
    featureDesc: "Adjacent channel compatible",
    label: "EF000504: Adjacent channel compatible"
  },
  {
    featureId: "EF000505",
    featureDesc: "Input signal",
    label: "EF000505: Input signal"
  },
  {
    featureId: "EF000506",
    featureDesc: "Output signal",
    label: "EF000506: Output signal"
  },
  {
    featureId: "EF000507",
    featureDesc: "Cascadable",
    label: "EF000507: Cascadable"
  },
  {
    featureId: "EF000508",
    featureDesc: "Power adaptor",
    label: "EF000508: Power adaptor"
  },
  {
    featureId: "EF000509",
    featureDesc: "Remote feedable",
    label: "EF000509: Remote feedable"
  },
  {
    featureId: "EF000510",
    featureDesc: "Twin-Receiver",
    label: "EF000510: Twin-Receiver"
  },
  {
    featureId: "EF000512",
    featureDesc: "Number of scart-connections",
    label: "EF000512: Number of scart-connections"
  },
  {
    featureId: "EF000513",
    featureDesc: "Control signal",
    label: "EF000513: Control signal"
  },
  {
    featureId: "EF000514",
    featureDesc: "With protective enclosure",
    label: "EF000514: With protective enclosure"
  },
  {
    featureId: "EF000515",
    featureDesc: "With mast support",
    label: "EF000515: With mast support"
  },
  {
    featureId: "EF000516",
    featureDesc: "Fit for multifeed",
    label: "EF000516: Fit for multifeed"
  },
  {
    featureId: "EF000517",
    featureDesc: "Type of feeding system/LNB",
    label: "EF000517: Type of feeding system/LNB"
  },
  {
    featureId: "EF000518",
    featureDesc: "Support arm complete pre-mounted",
    label: "EF000518: Support arm complete pre-mounted"
  },
  {
    featureId: "EF000519",
    featureDesc: "Return path channel",
    label: "EF000519: Return path channel"
  },
  {
    featureId: "EF000520",
    featureDesc: "With input splitter",
    label: "EF000520: With input splitter"
  },
  {
    featureId: "EF000521",
    featureDesc: "Rib diameter",
    label: "EF000521: Rib diameter"
  },
  {
    featureId: "EF000522",
    featureDesc: "With automatic switch",
    label: "EF000522: With automatic switch"
  },
  {
    featureId: "EF000523",
    featureDesc: "Max. engine power",
    label: "EF000523: Max. engine power"
  },
  {
    featureId: "EF000524",
    featureDesc: "Heat exchanger surface",
    label: "EF000524: Heat exchanger surface"
  },
  {
    featureId: "EF000525",
    featureDesc: "Flange diameter",
    label: "EF000525: Flange diameter"
  },
  {
    featureId: "EF000526",
    featureDesc: "Max. immersion depth",
    label: "EF000526: Max. immersion depth"
  },
  {
    featureId: "EF000528",
    featureDesc: "Suitable for max. pipe diameter",
    label: "EF000528: Suitable for max. pipe diameter"
  },
  {
    featureId: "EF000531",
    featureDesc: "With thermal feedback",
    label: "EF000531: With thermal feedback"
  },
  {
    featureId: "EF000535",
    featureDesc: "With IR sensor",
    label: "EF000535: With IR sensor"
  },
  {
    featureId: "EF000536",
    featureDesc: "Cable length",
    label: "EF000536: Cable length"
  },
  {
    featureId: "EF000537",
    featureDesc: "Cable entry",
    label: "EF000537: Cable entry"
  },
  {
    featureId: "EF000538",
    featureDesc: "Number of outlets",
    label: "EF000538: Number of outlets"
  },
  {
    featureId: "EF000539",
    featureDesc: "Through loss at 860 MHz",
    label: "EF000539: Through loss at 860 MHz"
  },
  {
    featureId: "EF000540",
    featureDesc: "Tap loss at 860 MHz",
    label: "EF000540: Tap loss at 860 MHz"
  },
  {
    featureId: "EF000541",
    featureDesc: "LNB version",
    label: "EF000541: LNB version"
  },
  {
    featureId: "EF000542",
    featureDesc: "Diameter of dish antenna",
    label: "EF000542: Diameter of dish antenna"
  },
  {
    featureId: "EF000543",
    featureDesc: "Colour dish antenna",
    label: "EF000543: Colour dish antenna"
  },
  {
    featureId: "EF000544",
    featureDesc: "Number of satellite-receivers",
    label: "EF000544: Number of satellite-receivers"
  },
  {
    featureId: "EF000547",
    featureDesc: "Colour control elements",
    label: "EF000547: Colour control elements"
  },
  {
    featureId: "EF000549",
    featureDesc: "With key",
    label: "EF000549: With key"
  },
  {
    featureId: "EF000550",
    featureDesc: "With wire protection",
    label: "EF000550: With wire protection"
  },
  {
    featureId: "EF000551",
    featureDesc: "Diameter",
    label: "EF000551: Diameter"
  },
  {
    featureId: "EF000553",
    featureDesc: "Suitable for cable tie width to",
    label: "EF000553: Suitable for cable tie width to"
  },
  {
    featureId: "EF000554",
    featureDesc: "Colour plug",
    label: "EF000554: Colour plug"
  },
  {
    featureId: "EF000557",
    featureDesc: "Equipped with",
    label: "EF000557: Equipped with"
  },
  {
    featureId: "EF000559",
    featureDesc: "Max. amperage",
    label: "EF000559: Max. amperage"
  },
  {
    featureId: "EF000560",
    featureDesc: "Nominal electricity consumption",
    label: "EF000560: Nominal electricity consumption"
  },
  {
    featureId: "EF000561",
    featureDesc: "Impedance connection 1",
    label: "EF000561: Impedance connection 1"
  },
  {
    featureId: "EF000562",
    featureDesc: "Impedance connection 2",
    label: "EF000562: Impedance connection 2"
  },
  {
    featureId: "EF000564",
    featureDesc: "Power of light generator",
    label: "EF000564: Power of light generator"
  },
  {
    featureId: "EF000565",
    featureDesc: "Material of fibres",
    label: "EF000565: Material of fibres"
  },
  {
    featureId: "EF000567",
    featureDesc: "With colour disc",
    label: "EF000567: With colour disc"
  },
  {
    featureId: "EF000568",
    featureDesc: "Type of colour disc",
    label: "EF000568: Type of colour disc"
  },
  {
    featureId: "EF000569",
    featureDesc: "With decoupling inductance",
    label: "EF000569: With decoupling inductance"
  },
  {
    featureId: "EF000571",
    featureDesc: "With toothing",
    label: "EF000571: With toothing"
  },
  {
    featureId: "EF000576",
    featureDesc: "With newspaper compartment",
    label: "EF000576: With newspaper compartment"
  },
  {
    featureId: "EF000578",
    featureDesc: "Time-out",
    label: "EF000578: Time-out"
  },
  {
    featureId: "EF000579",
    featureDesc: "Separate power supply",
    label: "EF000579: Separate power supply"
  },
  {
    featureId: "EF000580",
    featureDesc: "Number of meter panels",
    label: "EF000580: Number of meter panels"
  },
  {
    featureId: "EF000581",
    featureDesc: "With starter insertion",
    label: "EF000581: With starter insertion"
  },
  {
    featureId: "EF000582",
    featureDesc: "Nipple thread",
    label: "EF000582: Nipple thread"
  },
  {
    featureId: "EF000584",
    featureDesc: "Number of clamp positions",
    label: "EF000584: Number of clamp positions"
  },
  {
    featureId: "EF000586",
    featureDesc: "Type of profile",
    label: "EF000586: Type of profile"
  },
  {
    featureId: "EF000587",
    featureDesc: "Electricity consumption",
    label: "EF000587: Electricity consumption"
  },
  {
    featureId: "EF000588",
    featureDesc: "Continuity test",
    label: "EF000588: Continuity test"
  },
  {
    featureId: "EF000589",
    featureDesc: "Continuity indication",
    label: "EF000589: Continuity indication"
  },
  {
    featureId: "EF000590",
    featureDesc: "Isolation resistance measuring range",
    label: "EF000590: Isolation resistance measuring range"
  },
  {
    featureId: "EF000591",
    featureDesc: "Low resistance measurement",
    label: "EF000591: Low resistance measurement"
  },
  {
    featureId: "EF000592",
    featureDesc: "Earthing measurement",
    label: "EF000592: Earthing measurement"
  },
  {
    featureId: "EF000593",
    featureDesc: "Protective ground resistance measuring range",
    label: "EF000593: Protective ground resistance measuring range"
  },
  {
    featureId: "EF000594",
    featureDesc: "Interface",
    label: "EF000594: Interface"
  },
  {
    featureId: "EF000595",
    featureDesc: "Max. measurable conduit length",
    label: "EF000595: Max. measurable conduit length"
  },
  {
    featureId: "EF000596",
    featureDesc: "Type of network",
    label: "EF000596: Type of network"
  },
  {
    featureId: "EF000597",
    featureDesc: "Capacitance measurement",
    label: "EF000597: Capacitance measurement"
  },
  {
    featureId: "EF000599",
    featureDesc: "Frequency measurement",
    label: "EF000599: Frequency measurement"
  },
  {
    featureId: "EF000600",
    featureDesc: "With decibel measurement (dB)",
    label: "EF000600: With decibel measurement (dB)"
  },
  {
    featureId: "EF000602",
    featureDesc: "For cable duct height",
    label: "EF000602: For cable duct height"
  },
  {
    featureId: "EF000603",
    featureDesc: "For cable duct width",
    label: "EF000603: For cable duct width"
  },
  {
    featureId: "EF000609",
    featureDesc: "Type of fastening screws",
    label: "EF000609: Type of fastening screws"
  },
  {
    featureId: "EF000610",
    featureDesc: "With connection holes",
    label: "EF000610: With connection holes"
  },
  {
    featureId: "EF000613",
    featureDesc: "Bus type",
    label: "EF000613: Bus type"
  },
  {
    featureId: "EF000615",
    featureDesc: "With overheating protection",
    label: "EF000615: With overheating protection"
  },
  {
    featureId: "EF000616",
    featureDesc: "Frost protection circuit",
    label: "EF000616: Frost protection circuit"
  },
  {
    featureId: "EF000619",
    featureDesc: "Max. electricity consumption",
    label: "EF000619: Max. electricity consumption"
  },
  {
    featureId: "EF000620",
    featureDesc: "Max. power supply",
    label: "EF000620: Max. power supply"
  },
  {
    featureId: "EF000622",
    featureDesc: "Gain FM",
    label: "EF000622: Gain FM"
  },
  {
    featureId: "EF000623",
    featureDesc: "Gain B I",
    label: "EF000623: Gain B I"
  },
  {
    featureId: "EF000624",
    featureDesc: "Gain B III",
    label: "EF000624: Gain B III"
  },
  {
    featureId: "EF000625",
    featureDesc: "Gain B IV/V",
    label: "EF000625: Gain B IV/V"
  },
  {
    featureId: "EF000627",
    featureDesc: "Number of fan levels",
    label: "EF000627: Number of fan levels"
  },
  {
    featureId: "EF000629",
    featureDesc: "Measuring point",
    label: "EF000629: Measuring point"
  },
  {
    featureId: "EF000630",
    featureDesc: "Pump-/drain valve",
    label: "EF000630: Pump-/drain valve"
  },
  {
    featureId: "EF000631",
    featureDesc: "Number of massage levels",
    label: "EF000631: Number of massage levels"
  },
  {
    featureId: "EF000632",
    featureDesc: "Infrared heat",
    label: "EF000632: Infrared heat"
  },
  {
    featureId: "EF000633",
    featureDesc: "Tank volume",
    label: "EF000633: Tank volume"
  },
  {
    featureId: "EF000634",
    featureDesc: "Max. pressure",
    label: "EF000634: Max. pressure"
  },
  {
    featureId: "EF000635",
    featureDesc: "Adapter for flat-iron",
    label: "EF000635: Adapter for flat-iron"
  },
  {
    featureId: "EF000637",
    featureDesc: "Bag volume",
    label: "EF000637: Bag volume"
  },
  {
    featureId: "EF000638",
    featureDesc: "Chopper",
    label: "EF000638: Chopper"
  },
  {
    featureId: "EF000639",
    featureDesc: "Number of UVA-tubes",
    label: "EF000639: Number of UVA-tubes"
  },
  {
    featureId: "EF000640",
    featureDesc: "Facial tanner",
    label: "EF000640: Facial tanner"
  },
  {
    featureId: "EF000643",
    featureDesc: "Foldable",
    label: "EF000643: Foldable"
  },
  {
    featureId: "EF000644",
    featureDesc: "Number of power levels",
    label: "EF000644: Number of power levels"
  },
  {
    featureId: "EF000645",
    featureDesc: "Cooling stage",
    label: "EF000645: Cooling stage"
  },
  {
    featureId: "EF000646",
    featureDesc: "Voltage change",
    label: "EF000646: Voltage change"
  },
  {
    featureId: "EF000647",
    featureDesc: "Diffusor",
    label: "EF000647: Diffusor"
  },
  {
    featureId: "EF000648",
    featureDesc: "Number of brush end-pieces",
    label: "EF000648: Number of brush end-pieces"
  },
  {
    featureId: "EF000649",
    featureDesc: "Cross section",
    label: "EF000649: Cross section"
  },
  {
    featureId: "EF000650",
    featureDesc: "Iron sole",
    label: "EF000650: Iron sole"
  },
  {
    featureId: "EF000651",
    featureDesc: "Water tank",
    label: "EF000651: Water tank"
  },
  {
    featureId: "EF000652",
    featureDesc: "Safety breaker",
    label: "EF000652: Safety breaker"
  },
  {
    featureId: "EF000653",
    featureDesc: "Anti chalk system",
    label: "EF000653: Anti chalk system"
  },
  {
    featureId: "EF000654",
    featureDesc: "Razor system",
    label: "EF000654: Razor system"
  },
  {
    featureId: "EF000655",
    featureDesc: "Pivoting head",
    label: "EF000655: Pivoting head"
  },
  {
    featureId: "EF000658",
    featureDesc: "Number of end piece nozzles",
    label: "EF000658: Number of end piece nozzles"
  },
  {
    featureId: "EF000659",
    featureDesc: "Number of click-on brushes",
    label: "EF000659: Number of click-on brushes"
  },
  {
    featureId: "EF000660",
    featureDesc: "Volume of water tank",
    label: "EF000660: Volume of water tank"
  },
  {
    featureId: "EF000661",
    featureDesc: "Power control stepless",
    label: "EF000661: Power control stepless"
  },
  {
    featureId: "EF000662",
    featureDesc: "Automatic switch-off",
    label: "EF000662: Automatic switch-off"
  },
  {
    featureId: "EF000664",
    featureDesc: "Suitable for wall mounting",
    label: "EF000664: Suitable for wall mounting"
  },
  {
    featureId: "EF000665",
    featureDesc: "With removing lifter",
    label: "EF000665: With removing lifter"
  },
  {
    featureId: "EF000666",
    featureDesc: "Partition",
    label: "EF000666: Partition"
  },
  {
    featureId: "EF000667",
    featureDesc: "Add'n weigh facility",
    label: "EF000667: Add'n weigh facility"
  },
  {
    featureId: "EF000668",
    featureDesc: "Cutting thickness adjustable up to",
    label: "EF000668: Cutting thickness adjustable up to"
  },
  {
    featureId: "EF000669",
    featureDesc: "Frost blade",
    label: "EF000669: Frost blade"
  },
  {
    featureId: "EF000670",
    featureDesc: "Power control",
    label: "EF000670: Power control"
  },
  {
    featureId: "EF000671",
    featureDesc: "Number of dry stages",
    label: "EF000671: Number of dry stages"
  },
  {
    featureId: "EF000672",
    featureDesc: "Timer",
    label: "EF000672: Timer"
  },
  {
    featureId: "EF000673",
    featureDesc: "Pulp ejection",
    label: "EF000673: Pulp ejection"
  },
  {
    featureId: "EF000674",
    featureDesc: "Selector switch for soft ice",
    label: "EF000674: Selector switch for soft ice"
  },
  {
    featureId: "EF000675",
    featureDesc: "Loading capacity flour",
    label: "EF000675: Loading capacity flour"
  },
  {
    featureId: "EF000676",
    featureDesc: "Whisk system",
    label: "EF000676: Whisk system"
  },
  {
    featureId: "EF000677",
    featureDesc: "Cutting system",
    label: "EF000677: Cutting system"
  },
  {
    featureId: "EF000678",
    featureDesc: "Mix system",
    label: "EF000678: Mix system"
  },
  {
    featureId: "EF000679",
    featureDesc: "Double seam",
    label: "EF000679: Double seam"
  },
  {
    featureId: "EF000680",
    featureDesc: "Adjustable welding time",
    label: "EF000680: Adjustable welding time"
  },
  {
    featureId: "EF000681",
    featureDesc: "Self-extinguishing",
    label: "EF000681: Self-extinguishing"
  },
  {
    featureId: "EF000682",
    featureDesc: "Type of grill plate",
    label: "EF000682: Type of grill plate"
  },
  {
    featureId: "EF000683",
    featureDesc: "Type of grill surface",
    label: "EF000683: Type of grill surface"
  },
  {
    featureId: "EF000684",
    featureDesc: "Material pad",
    label: "EF000684: Material pad"
  },
  {
    featureId: "EF000685",
    featureDesc: "Set version",
    label: "EF000685: Set version"
  },
  {
    featureId: "EF000686",
    featureDesc: "Capacity eggs",
    label: "EF000686: Capacity eggs"
  },
  {
    featureId: "EF000687",
    featureDesc: "Keep-warm setting",
    label: "EF000687: Keep-warm setting"
  },
  {
    featureId: "EF000688",
    featureDesc: "Capacity espresso part",
    label: "EF000688: Capacity espresso part"
  },
  {
    featureId: "EF000690",
    featureDesc: "Number of steam baskets",
    label: "EF000690: Number of steam baskets"
  },
  {
    featureId: "EF000692",
    featureDesc: "Number of timers",
    label: "EF000692: Number of timers"
  },
  {
    featureId: "EF000693",
    featureDesc: "Time indication",
    label: "EF000693: Time indication"
  },
  {
    featureId: "EF000694",
    featureDesc: "Stop watch",
    label: "EF000694: Stop watch"
  },
  {
    featureId: "EF000695",
    featureDesc: "Volume cavity",
    label: "EF000695: Volume cavity"
  },
  {
    featureId: "EF000696",
    featureDesc: "Type of heating",
    label: "EF000696: Type of heating"
  },
  {
    featureId: "EF000697",
    featureDesc: "Fine setting",
    label: "EF000697: Fine setting"
  },
  {
    featureId: "EF000698",
    featureDesc: "Grinding system",
    label: "EF000698: Grinding system"
  },
  {
    featureId: "EF000699",
    featureDesc: "Juice extractor setting",
    label: "EF000699: Juice extractor setting"
  },
  {
    featureId: "EF000701",
    featureDesc: "Bread roll warmer",
    label: "EF000701: Bread roll warmer"
  },
  {
    featureId: "EF000702",
    featureDesc: "Dust protection cover",
    label: "EF000702: Dust protection cover"
  },
  {
    featureId: "EF000703",
    featureDesc: "Automatic basket elevator",
    label: "EF000703: Automatic basket elevator"
  },
  {
    featureId: "EF000706",
    featureDesc: "Number of plate sets",
    label: "EF000706: Number of plate sets"
  },
  {
    featureId: "EF000707",
    featureDesc: "Controllable thermostat",
    label: "EF000707: Controllable thermostat"
  },
  {
    featureId: "EF000708",
    featureDesc: "Roller diameter",
    label: "EF000708: Roller diameter"
  },
  {
    featureId: "EF000709",
    featureDesc: "Roller width",
    label: "EF000709: Roller width"
  },
  {
    featureId: "EF000710",
    featureDesc: "Number of ribs",
    label: "EF000710: Number of ribs"
  },
  {
    featureId: "EF000711",
    featureDesc: "Program selection",
    label: "EF000711: Program selection"
  },
  {
    featureId: "EF000712",
    featureDesc: "Time preselection",
    label: "EF000712: Time preselection"
  },
  {
    featureId: "EF000713",
    featureDesc: "Alarm tone switch-off",
    label: "EF000713: Alarm tone switch-off"
  },
  {
    featureId: "EF000717",
    featureDesc: "Diameter cooking field 1",
    label: "EF000717: Diameter cooking field 1"
  },
  {
    featureId: "EF000718",
    featureDesc: "Diameter cooking field 2",
    label: "EF000718: Diameter cooking field 2"
  },
  {
    featureId: "EF000719",
    featureDesc: "Power cooking plate 1",
    label: "EF000719: Power cooking plate 1"
  },
  {
    featureId: "EF000720",
    featureDesc: "Power cooking plate 2",
    label: "EF000720: Power cooking plate 2"
  },
  {
    featureId: "EF000721",
    featureDesc: "Jug type",
    label: "EF000721: Jug type"
  },
  {
    featureId: "EF000722",
    featureDesc: "Water tank removable",
    label: "EF000722: Water tank removable"
  },
  {
    featureId: "EF000723",
    featureDesc: "Tea filter",
    label: "EF000723: Tea filter"
  },
  {
    featureId: "EF000724",
    featureDesc: "With water filter",
    label: "EF000724: With water filter"
  },
  {
    featureId: "EF000726",
    featureDesc: "Input method",
    label: "EF000726: Input method"
  },
  {
    featureId: "EF000727",
    featureDesc: "Print process",
    label: "EF000727: Print process"
  },
  {
    featureId: "EF000728",
    featureDesc: "Print resolution",
    label: "EF000728: Print resolution"
  },
  {
    featureId: "EF000729",
    featureDesc: "Number of text lines",
    label: "EF000729: Number of text lines"
  },
  {
    featureId: "EF000730",
    featureDesc: "Digits in display",
    label: "EF000730: Digits in display"
  },
  {
    featureId: "EF000731",
    featureDesc: "Repetition of text",
    label: "EF000731: Repetition of text"
  },
  {
    featureId: "EF000732",
    featureDesc: "Working time",
    label: "EF000732: Working time"
  },
  {
    featureId: "EF000734",
    featureDesc: "Bend radius protection/trumpet flare",
    label: "EF000734: Bend radius protection/trumpet flare"
  },
  {
    featureId: "EF000735",
    featureDesc: "With cable screw gland",
    label: "EF000735: With cable screw gland"
  },
  {
    featureId: "EF000738",
    featureDesc: "Visible diagonal screen size",
    label: "EF000738: Visible diagonal screen size"
  },
  {
    featureId: "EF000742",
    featureDesc: "Number of program memory positions",
    label: "EF000742: Number of program memory positions"
  },
  {
    featureId: "EF000743",
    featureDesc: "Number of screws",
    label: "EF000743: Number of screws"
  },
  {
    featureId: "EF000744",
    featureDesc: "With carrying handle",
    label: "EF000744: With carrying handle"
  },
  {
    featureId: "EF000745",
    featureDesc: "With temperature control",
    label: "EF000745: With temperature control"
  },
  {
    featureId: "EF000746",
    featureDesc: "Temperature indication",
    label: "EF000746: Temperature indication"
  },
  {
    featureId: "EF000749",
    featureDesc: "RDS",
    label: "EF000749: RDS"
  },
  {
    featureId: "EF000751",
    featureDesc: "CD changer control",
    label: "EF000751: CD changer control"
  },
  {
    featureId: "EF000758",
    featureDesc: "Energy efficiency class",
    label: "EF000758: Energy efficiency class"
  },
  {
    featureId: "EF000761",
    featureDesc: "Climate class",
    label: "EF000761: Climate class"
  },
  {
    featureId: "EF000767",
    featureDesc: "Construction type",
    label: "EF000767: Construction type"
  },
  {
    featureId: "EF000769",
    featureDesc: "Net capacity freezing compartment",
    label: "EF000769: Net capacity freezing compartment"
  },
  {
    featureId: "EF000774",
    featureDesc: "Number of included curlers",
    label: "EF000774: Number of included curlers"
  },
  {
    featureId: "EF000775",
    featureDesc: "Capacity normative cutleries",
    label: "EF000775: Capacity normative cutleries"
  },
  {
    featureId: "EF000776",
    featureDesc: "Sound level (in operation)",
    label: "EF000776: Sound level (in operation)"
  },
  {
    featureId: "EF000777",
    featureDesc: "Energy consumption",
    label: "EF000777: Energy consumption"
  },
  {
    featureId: "EF000781",
    featureDesc: "Protection",
    label: "EF000781: Protection"
  },
  {
    featureId: "EF000783",
    featureDesc: "Number of connections sensor/actuator",
    label: "EF000783: Number of connections sensor/actuator"
  },
  {
    featureId: "EF000786",
    featureDesc: "Number of relays",
    label: "EF000786: Number of relays"
  },
  {
    featureId: "EF000787",
    featureDesc: "Number of loudspeaker groups",
    label: "EF000787: Number of loudspeaker groups"
  },
  {
    featureId: "EF000788",
    featureDesc: "Suitable for surface to",
    label: "EF000788: Suitable for surface to"
  },
  {
    featureId: "EF000789",
    featureDesc: "Recording time",
    label: "EF000789: Recording time"
  },
  {
    featureId: "EF000790",
    featureDesc: "Remote read-out",
    label: "EF000790: Remote read-out"
  },
  {
    featureId: "EF000795",
    featureDesc: "Play time",
    label: "EF000795: Play time"
  },
  {
    featureId: "EF000797",
    featureDesc: "Number of CD's in changer",
    label: "EF000797: Number of CD's in changer"
  },
  {
    featureId: "EF000798",
    featureDesc: "Frequency indication",
    label: "EF000798: Frequency indication"
  },
  {
    featureId: "EF000799",
    featureDesc: "Number of wake-up times",
    label: "EF000799: Number of wake-up times"
  },
  {
    featureId: "EF000802",
    featureDesc: "Number of title memories",
    label: "EF000802: Number of title memories"
  },
  {
    featureId: "EF000803",
    featureDesc: "Number of cassette drives",
    label: "EF000803: Number of cassette drives"
  },
  {
    featureId: "EF000805",
    featureDesc: "Music capacity",
    label: "EF000805: Music capacity"
  },
  {
    featureId: "EF000806",
    featureDesc: "Nominal capacity",
    label: "EF000806: Nominal capacity"
  },
  {
    featureId: "EF000807",
    featureDesc: "Voice control",
    label: "EF000807: Voice control"
  },
  {
    featureId: "EF000808",
    featureDesc: "Automatic switch-off at tape end",
    label: "EF000808: Automatic switch-off at tape end"
  },
  {
    featureId: "EF000809",
    featureDesc: "Music power at 4 Ohm",
    label: "EF000809: Music power at 4 Ohm"
  },
  {
    featureId: "EF000810",
    featureDesc: "Sinus power at 4 Ohm",
    label: "EF000810: Sinus power at 4 Ohm"
  },
  {
    featureId: "EF000811",
    featureDesc: "Optical zoom",
    label: "EF000811: Optical zoom"
  },
  {
    featureId: "EF000812",
    featureDesc: "Digital zoom",
    label: "EF000812: Digital zoom"
  },
  {
    featureId: "EF000813",
    featureDesc: "LCD screen",
    label: "EF000813: LCD screen"
  },
  {
    featureId: "EF000814",
    featureDesc: "CD Player",
    label: "EF000814: CD Player"
  },
  {
    featureId: "EF000818",
    featureDesc: "Auto reverse",
    label: "EF000818: Auto reverse"
  },
  {
    featureId: "EF000820",
    featureDesc: "Number of connectable devices",
    label: "EF000820: Number of connectable devices"
  },
  {
    featureId: "EF000822",
    featureDesc: "Connection for electric vacuum brush",
    label: "EF000822: Connection for electric vacuum brush"
  },
  {
    featureId: "EF000823",
    featureDesc: "Rotating tray",
    label: "EF000823: Rotating tray"
  },
  {
    featureId: "EF000824",
    featureDesc: "Max. microwave power",
    label: "EF000824: Max. microwave power"
  },
  {
    featureId: "EF000826",
    featureDesc: "Number of cooking plates",
    label: "EF000826: Number of cooking plates"
  },
  {
    featureId: "EF000829",
    featureDesc: "With dual cooking zone",
    label: "EF000829: With dual cooking zone"
  },
  {
    featureId: "EF000830",
    featureDesc: "With oval baking zone",
    label: "EF000830: With oval baking zone"
  },
  {
    featureId: "EF000831",
    featureDesc: "With warming zone",
    label: "EF000831: With warming zone"
  },
  {
    featureId: "EF000835",
    featureDesc: "Oven cleaning",
    label: "EF000835: Oven cleaning"
  },
  {
    featureId: "EF000836",
    featureDesc: "Loudness controller",
    label: "EF000836: Loudness controller"
  },
  {
    featureId: "EF000837",
    featureDesc: "Number of storage surfaces",
    label: "EF000837: Number of storage surfaces"
  },
  {
    featureId: "EF000840",
    featureDesc: "Number of integrated choke coils",
    label: "EF000840: Number of integrated choke coils"
  },
  {
    featureId: "EF000841",
    featureDesc: "Programmable",
    label: "EF000841: Programmable"
  },
  {
    featureId: "EF000844",
    featureDesc: "Subwoofer",
    label: "EF000844: Subwoofer"
  },
  {
    featureId: "EF000845",
    featureDesc: "Number of loudspeaker components",
    label: "EF000845: Number of loudspeaker components"
  },
  {
    featureId: "EF000846",
    featureDesc: "Built-in width",
    label: "EF000846: Built-in width"
  },
  {
    featureId: "EF000847",
    featureDesc: "Input",
    label: "EF000847: Input"
  },
  {
    featureId: "EF000849",
    featureDesc: "Picture brightness (ANSI)",
    label: "EF000849: Picture brightness (ANSI)"
  },
  {
    featureId: "EF000852",
    featureDesc: "Diameter tap hole",
    label: "EF000852: Diameter tap hole"
  },
  {
    featureId: "EF000853",
    featureDesc: "Place of sink",
    label: "EF000853: Place of sink"
  },
  {
    featureId: "EF000854",
    featureDesc: "Ex-class",
    label: "EF000854: Ex-class"
  },
  {
    featureId: "EF000855",
    featureDesc: "Number of connections for analogue end devices",
    label: "EF000855: Number of connections for analogue end devices"
  },
  {
    featureId: "EF000856",
    featureDesc: "Serial interface",
    label: "EF000856: Serial interface"
  },
  {
    featureId: "EF000861",
    featureDesc: "Output power (music)",
    label: "EF000861: Output power (music)"
  },
  {
    featureId: "EF000862",
    featureDesc: "Length of ironing table",
    label: "EF000862: Length of ironing table"
  },
  {
    featureId: "EF000863",
    featureDesc: "Width ironing surface",
    label: "EF000863: Width ironing surface"
  },
  {
    featureId: "EF000864",
    featureDesc: "Width indoor unit",
    label: "EF000864: Width indoor unit"
  },
  {
    featureId: "EF000865",
    featureDesc: "Height indoor unit",
    label: "EF000865: Height indoor unit"
  },
  {
    featureId: "EF000866",
    featureDesc: "Depth indoor unit",
    label: "EF000866: Depth indoor unit"
  },
  {
    featureId: "EF000867",
    featureDesc: "Weight indoor unit",
    label: "EF000867: Weight indoor unit"
  },
  {
    featureId: "EF000868",
    featureDesc: "Height outdoor unit",
    label: "EF000868: Height outdoor unit"
  },
  {
    featureId: "EF000869",
    featureDesc: "Width outdoor unit",
    label: "EF000869: Width outdoor unit"
  },
  {
    featureId: "EF000870",
    featureDesc: "Depth outdoor unit",
    label: "EF000870: Depth outdoor unit"
  },
  {
    featureId: "EF000871",
    featureDesc: "Weight outdoor unit",
    label: "EF000871: Weight outdoor unit"
  },
  {
    featureId: "EF000872",
    featureDesc: "With transmission function",
    label: "EF000872: With transmission function"
  },
  {
    featureId: "EF000873",
    featureDesc: "Number of lines to feed",
    label: "EF000873: Number of lines to feed"
  },
  {
    featureId: "EF000874",
    featureDesc: "Max. drill diameter in steel",
    label: "EF000874: Max. drill diameter in steel"
  },
  {
    featureId: "EF000876",
    featureDesc: "Impedance",
    label: "EF000876: Impedance"
  },
  {
    featureId: "EF000877",
    featureDesc: "Max. current (Imax)",
    label: "EF000877: Max. current (Imax)"
  },
  {
    featureId: "EF000878",
    featureDesc: "With strain relief",
    label: "EF000878: With strain relief"
  },
  {
    featureId: "EF000879",
    featureDesc: "Number of conductors",
    label: "EF000879: Number of conductors"
  },
  {
    featureId: "EF000880",
    featureDesc: "Volume",
    label: "EF000880: Volume"
  },
  {
    featureId: "EF000881",
    featureDesc: "Screened",
    label: "EF000881: Screened"
  },
  {
    featureId: "EF000884",
    featureDesc: "Number of module positions",
    label: "EF000884: Number of module positions"
  },
  {
    featureId: "EF000885",
    featureDesc: "With cool element",
    label: "EF000885: With cool element"
  },
  {
    featureId: "EF000887",
    featureDesc: "Clamps",
    label: "EF000887: Clamps"
  },
  {
    featureId: "EF000888",
    featureDesc: "Radio-controlled",
    label: "EF000888: Radio-controlled"
  },
  {
    featureId: "EF000889",
    featureDesc: "Release characteristic",
    label: "EF000889: Release characteristic"
  },
  {
    featureId: "EF000892",
    featureDesc: "Fail-Safe",
    label: "EF000892: Fail-Safe"
  },
  {
    featureId: "EF000893",
    featureDesc: "Number of max. stackable devices",
    label: "EF000893: Number of max. stackable devices"
  },
  {
    featureId: "EF000894",
    featureDesc: "Number of ports 10 Mbps BNC",
    label: "EF000894: Number of ports 10 Mbps BNC"
  },
  {
    featureId: "EF000895",
    featureDesc: "Number of ports 10 Mbps AUI",
    label: "EF000895: Number of ports 10 Mbps AUI"
  },
  {
    featureId: "EF000897",
    featureDesc: "Number of ports 10 Mbps of ST",
    label: "EF000897: Number of ports 10 Mbps of ST"
  },
  {
    featureId: "EF000899",
    featureDesc: "Number of ports 100 Mbps RJ45",
    label: "EF000899: Number of ports 100 Mbps RJ45"
  },
  {
    featureId: "EF000900",
    featureDesc: "Number of ports 100 Mbps SC",
    label: "EF000900: Number of ports 100 Mbps SC"
  },
  {
    featureId: "EF000902",
    featureDesc: "Number of ports 1000 Mbps RJ45",
    label: "EF000902: Number of ports 1000 Mbps RJ45"
  },
  {
    featureId: "EF000903",
    featureDesc: "Number of ports 1000 Mbps SC",
    label: "EF000903: Number of ports 1000 Mbps SC"
  },
  {
    featureId: "EF000907",
    featureDesc: "Number of ports ISDN WAN",
    label: "EF000907: Number of ports ISDN WAN"
  },
  {
    featureId: "EF000914",
    featureDesc: "Firewall",
    label: "EF000914: Firewall"
  },
  {
    featureId: "EF000916",
    featureDesc: "Number of ports serial",
    label: "EF000916: Number of ports serial"
  },
  {
    featureId: "EF000917",
    featureDesc: "Number of ports parallel",
    label: "EF000917: Number of ports parallel"
  },
  {
    featureId: "EF000922",
    featureDesc: "Number of ports 10 Mbps of SC",
    label: "EF000922: Number of ports 10 Mbps of SC"
  },
  {
    featureId: "EF000923",
    featureDesc: "Number of ports 100 Mbps ST",
    label: "EF000923: Number of ports 100 Mbps ST"
  },
  {
    featureId: "EF000928",
    featureDesc: "Number of fans",
    label: "EF000928: Number of fans"
  },
  {
    featureId: "EF000931",
    featureDesc: "Colour code",
    label: "EF000931: Colour code"
  },
  {
    featureId: "EF000935",
    featureDesc: "IEC indication",
    label: "EF000935: IEC indication"
  },
  {
    featureId: "EF000936",
    featureDesc: "Other indications",
    label: "EF000936: Other indications"
  },
  {
    featureId: "EF000939",
    featureDesc: "With hollow cable",
    label: "EF000939: With hollow cable"
  },
  {
    featureId: "EF000940",
    featureDesc: "Pin assignment",
    label: "EF000940: Pin assignment"
  },
  {
    featureId: "EF000942",
    featureDesc: "Radio button",
    label: "EF000942: Radio button"
  },
  {
    featureId: "EF000943",
    featureDesc: "Built-in diameter",
    label: "EF000943: Built-in diameter"
  },
  {
    featureId: "EF000946",
    featureDesc: "Managed",
    label: "EF000946: Managed"
  },
  {
    featureId: "EF000947",
    featureDesc: "Mains filter",
    label: "EF000947: Mains filter"
  },
  {
    featureId: "EF000952",
    featureDesc: "Number of ports DPC",
    label: "EF000952: Number of ports DPC"
  },
  {
    featureId: "EF000953",
    featureDesc: "Number of ports Twinax",
    label: "EF000953: Number of ports Twinax"
  },
  {
    featureId: "EF000954",
    featureDesc: "Number of ports ST",
    label: "EF000954: Number of ports ST"
  },
  {
    featureId: "EF000955",
    featureDesc: "Suitable for bus connection",
    label: "EF000955: Suitable for bus connection"
  },
  {
    featureId: "EF000956",
    featureDesc: "Number of sub-clock lines",
    label: "EF000956: Number of sub-clock lines"
  },
  {
    featureId: "EF000957",
    featureDesc: "Number of control current circuits",
    label: "EF000957: Number of control current circuits"
  },
  {
    featureId: "EF000958",
    featureDesc: "Reserve accumulator",
    label: "EF000958: Reserve accumulator"
  },
  {
    featureId: "EF000970",
    featureDesc: "Answering machine",
    label: "EF000970: Answering machine"
  },
  {
    featureId: "EF000971",
    featureDesc: "Hands free",
    label: "EF000971: Hands free"
  },
  {
    featureId: "EF000972",
    featureDesc: "Display for charge fee",
    label: "EF000972: Display for charge fee"
  },
  {
    featureId: "EF000973",
    featureDesc: "Open listening",
    label: "EF000973: Open listening"
  },
  {
    featureId: "EF000974",
    featureDesc: "Number of speed dial keys",
    label: "EF000974: Number of speed dial keys"
  },
  {
    featureId: "EF000975",
    featureDesc: "Head set connection",
    label: "EF000975: Head set connection"
  },
  {
    featureId: "EF000977",
    featureDesc: "Number of TFE-interfaces",
    label: "EF000977: Number of TFE-interfaces"
  },
  {
    featureId: "EF000978",
    featureDesc: "Number of programmable MSNs",
    label: "EF000978: Number of programmable MSNs"
  },
  {
    featureId: "EF000979",
    featureDesc: "Connectable to",
    label: "EF000979: Connectable to"
  },
  {
    featureId: "EF000981",
    featureDesc: "Number of entries number memory",
    label: "EF000981: Number of entries number memory"
  },
  {
    featureId: "EF000982",
    featureDesc: "Emergency operation",
    label: "EF000982: Emergency operation"
  },
  {
    featureId: "EF000983",
    featureDesc: "Number of system telephones",
    label: "EF000983: Number of system telephones"
  },
  {
    featureId: "EF000984",
    featureDesc: "In service with",
    label: "EF000984: In service with"
  },
  {
    featureId: "EF000985",
    featureDesc: "Operation as ISDN telephone possible",
    label: "EF000985: Operation as ISDN telephone possible"
  },
  {
    featureId: "EF000986",
    featureDesc: "Modular expandable",
    label: "EF000986: Modular expandable"
  },
  {
    featureId: "EF000987",
    featureDesc: "Number of burners",
    label: "EF000987: Number of burners"
  },
  {
    featureId: "EF000988",
    featureDesc: "Ignition protection",
    label: "EF000988: Ignition protection"
  },
  {
    featureId: "EF000989",
    featureDesc: "Type of gas",
    label: "EF000989: Type of gas"
  },
  {
    featureId: "EF000990",
    featureDesc: "Pocket torch function",
    label: "EF000990: Pocket torch function"
  },
  {
    featureId: "EF000991",
    featureDesc: "Max. discharge flow",
    label: "EF000991: Max. discharge flow"
  },
  {
    featureId: "EF000992",
    featureDesc: "Max. static height",
    label: "EF000992: Max. static height"
  },
  {
    featureId: "EF000993",
    featureDesc: "Number of loudspeakers",
    label: "EF000993: Number of loudspeakers"
  },
  {
    featureId: "EF000994",
    featureDesc: "Tone quality adjustable",
    label: "EF000994: Tone quality adjustable"
  },
  {
    featureId: "EF000995",
    featureDesc: "Loading capacity (max. flour weight)",
    label: "EF000995: Loading capacity (max. flour weight)"
  },
  {
    featureId: "EF000996",
    featureDesc: "Adjustable",
    label: "EF000996: Adjustable"
  },
  {
    featureId: "EF000997",
    featureDesc: "Number of ports USB-A",
    label: "EF000997: Number of ports USB-A"
  },
  {
    featureId: "EF000998",
    featureDesc: "Number of ports USB-B",
    label: "EF000998: Number of ports USB-B"
  },
  {
    featureId: "EF000999",
    featureDesc: "Probe form",
    label: "EF000999: Probe form"
  },
  {
    featureId: "EF001000",
    featureDesc: "Max. contact pressure",
    label: "EF001000: Max. contact pressure"
  },
  {
    featureId: "EF001001",
    featureDesc: "Head diameter",
    label: "EF001001: Head diameter"
  },
  {
    featureId: "EF001002",
    featureDesc: "Refinement",
    label: "EF001002: Refinement"
  },
  {
    featureId: "EF001003",
    featureDesc: "Max. power consumption",
    label: "EF001003: Max. power consumption"
  },
  {
    featureId: "EF001004",
    featureDesc: "Max. output level",
    label: "EF001004: Max. output level"
  },
  {
    featureId: "EF001005",
    featureDesc: "Number of mail boxes",
    label: "EF001005: Number of mail boxes"
  },
  {
    featureId: "EF001006",
    featureDesc: "Measurement principle",
    label: "EF001006: Measurement principle"
  },
  {
    featureId: "EF001007",
    featureDesc: "Radio protocol",
    label: "EF001007: Radio protocol"
  },
  {
    featureId: "EF001012",
    featureDesc: "Number of ports 100 Mbps MT RJ",
    label: "EF001012: Number of ports 100 Mbps MT RJ"
  },
  {
    featureId: "EF001013",
    featureDesc: "Number of ports 1000 Mbps MT RJ",
    label: "EF001013: Number of ports 1000 Mbps MT RJ"
  },
  {
    featureId: "EF001018",
    featureDesc: "Splitter protection",
    label: "EF001018: Splitter protection"
  },
  {
    featureId: "EF001019",
    featureDesc: "Low-pressure technique",
    label: "EF001019: Low-pressure technique"
  },
  {
    featureId: "EF001020",
    featureDesc: "Suitable for baking oven",
    label: "EF001020: Suitable for baking oven"
  },
  {
    featureId: "EF001021",
    featureDesc: "Integrated starter",
    label: "EF001021: Integrated starter"
  },
  {
    featureId: "EF001022",
    featureDesc: "Axial filament",
    label: "EF001022: Axial filament"
  },
  {
    featureId: "EF001023",
    featureDesc: "Suitable for electronic ballast",
    label: "EF001023: Suitable for electronic ballast"
  },
  {
    featureId: "EF001024",
    featureDesc: "Armouring",
    label: "EF001024: Armouring"
  },
  {
    featureId: "EF001026",
    featureDesc: "Direct connection to main supply",
    label: "EF001026: Direct connection to main supply"
  },
  {
    featureId: "EF001027",
    featureDesc: "Lead sheath",
    label: "EF001027: Lead sheath"
  },
  {
    featureId: "EF001028",
    featureDesc: "Flame resistant",
    label: "EF001028: Flame resistant"
  },
  {
    featureId: "EF001029",
    featureDesc: "Drain wire",
    label: "EF001029: Drain wire"
  },
  {
    featureId: "EF001030",
    featureDesc: "Size (TX)",
    label: "EF001030: Size (TX)"
  },
  {
    featureId: "EF001031",
    featureDesc: "Antimagnetic",
    label: "EF001031: Antimagnetic"
  },
  {
    featureId: "EF001033",
    featureDesc: "Protective contact",
    label: "EF001033: Protective contact"
  },
  {
    featureId: "EF001034",
    featureDesc: "Plastic housing",
    label: "EF001034: Plastic housing"
  },
  {
    featureId: "EF001035",
    featureDesc: "Label space/information surface",
    label: "EF001035: Label space/information surface"
  },
  {
    featureId: "EF001036",
    featureDesc: "Basic element",
    label: "EF001036: Basic element"
  },
  {
    featureId: "EF001038",
    featureDesc: "Time setting",
    label: "EF001038: Time setting"
  },
  {
    featureId: "EF001039",
    featureDesc: "Pre-stamping",
    label: "EF001039: Pre-stamping"
  },
  {
    featureId: "EF001040",
    featureDesc: "Suitable for standard switching equipment",
    label: "EF001040: Suitable for standard switching equipment"
  },
  {
    featureId: "EF001044",
    featureDesc: "Dimension",
    label: "EF001044: Dimension"
  },
  {
    featureId: "EF001045",
    featureDesc: "Centre plate",
    label: "EF001045: Centre plate"
  },
  {
    featureId: "EF001051",
    featureDesc: "Eject-mechanism",
    label: "EF001051: Eject-mechanism"
  },
  {
    featureId: "EF001052",
    featureDesc: "Max. number of cables",
    label: "EF001052: Max. number of cables"
  },
  {
    featureId: "EF001053",
    featureDesc: "Mounting thickness",
    label: "EF001053: Mounting thickness"
  },
  {
    featureId: "EF001055",
    featureDesc: "Perforation",
    label: "EF001055: Perforation"
  },
  {
    featureId: "EF001056",
    featureDesc: "Pin shape",
    label: "EF001056: Pin shape"
  },
  {
    featureId: "EF001059",
    featureDesc: "Height side wall",
    label: "EF001059: Height side wall"
  },
  {
    featureId: "EF001060",
    featureDesc: "Type of rung",
    label: "EF001060: Type of rung"
  },
  {
    featureId: "EF001062",
    featureDesc: "EMC-version",
    label: "EF001062: EMC-version"
  },
  {
    featureId: "EF001063",
    featureDesc: "Width branch",
    label: "EF001063: Width branch"
  },
  {
    featureId: "EF001067",
    featureDesc: "Double-sided",
    label: "EF001067: Double-sided"
  },
  {
    featureId: "EF001071",
    featureDesc: "Strengthened construction",
    label: "EF001071: Strengthened construction"
  },
  {
    featureId: "EF001072",
    featureDesc: "Diameter feed-through",
    label: "EF001072: Diameter feed-through"
  },
  {
    featureId: "EF001073",
    featureDesc: "Wall thickness after shrinking",
    label: "EF001073: Wall thickness after shrinking"
  },
  {
    featureId: "EF001074",
    featureDesc: "Resistant to chemicals",
    label: "EF001074: Resistant to chemicals"
  },
  {
    featureId: "EF001076",
    featureDesc: "Hinging",
    label: "EF001076: Hinging"
  },
  {
    featureId: "EF001078",
    featureDesc: "Self cutting",
    label: "EF001078: Self cutting"
  },
  {
    featureId: "EF001079",
    featureDesc: "Visible signalling route",
    label: "EF001079: Visible signalling route"
  },
  {
    featureId: "EF001080",
    featureDesc: "Length of signalling route",
    label: "EF001080: Length of signalling route"
  },
  {
    featureId: "EF001081",
    featureDesc: "Rotating signalling route",
    label: "EF001081: Rotating signalling route"
  },
  {
    featureId: "EF001082",
    featureDesc: "With digital indication",
    label: "EF001082: With digital indication"
  },
  {
    featureId: "EF001084",
    featureDesc: "Shaft diameter",
    label: "EF001084: Shaft diameter"
  },
  {
    featureId: "EF001085",
    featureDesc: "Inner diameter of eye",
    label: "EF001085: Inner diameter of eye"
  },
  {
    featureId: "EF001086",
    featureDesc: "Stepless mounting",
    label: "EF001086: Stepless mounting"
  },
  {
    featureId: "EF001088",
    featureDesc: "Extension possible",
    label: "EF001088: Extension possible"
  },
  {
    featureId: "EF001089",
    featureDesc: "With glazed door",
    label: "EF001089: With glazed door"
  },
  {
    featureId: "EF001090",
    featureDesc: "Frequency supply voltage",
    label: "EF001090: Frequency supply voltage"
  },
  {
    featureId: "EF001092",
    featureDesc: "With point",
    label: "EF001092: With point"
  },
  {
    featureId: "EF001093",
    featureDesc: "Thread moulding",
    label: "EF001093: Thread moulding"
  },
  {
    featureId: "EF001094",
    featureDesc: "With transparent cover",
    label: "EF001094: With transparent cover"
  },
  {
    featureId: "EF001096",
    featureDesc: "Wall-mounted transmitter",
    label: "EF001096: Wall-mounted transmitter"
  },
  {
    featureId: "EF001098",
    featureDesc: "Staircase light controller",
    label: "EF001098: Staircase light controller"
  },
  {
    featureId: "EF001099",
    featureDesc: "Bridging switch",
    label: "EF001099: Bridging switch"
  },
  {
    featureId: "EF001102",
    featureDesc: "With housing",
    label: "EF001102: With housing"
  },
  {
    featureId: "EF001104",
    featureDesc: "With thermostat",
    label: "EF001104: With thermostat"
  },
  {
    featureId: "EF001112",
    featureDesc: "Number of single wall socket outlets",
    label: "EF001112: Number of single wall socket outlets"
  },
  {
    featureId: "EF001113",
    featureDesc: "Number of double wall socket outlets",
    label: "EF001113: Number of double wall socket outlets"
  },
  {
    featureId: "EF001114",
    featureDesc: "Number of triple wall socket outlets",
    label: "EF001114: Number of triple wall socket outlets"
  },
  {
    featureId: "EF001115",
    featureDesc: "Number of perilex wall socket outlets",
    label: "EF001115: Number of perilex wall socket outlets"
  },
  {
    featureId: "EF001116",
    featureDesc: "Number of data outlets",
    label: "EF001116: Number of data outlets"
  },
  {
    featureId: "EF001118",
    featureDesc: "Number of single openings",
    label: "EF001118: Number of single openings"
  },
  {
    featureId: "EF001119",
    featureDesc: "Number of double openings",
    label: "EF001119: Number of double openings"
  },
  {
    featureId: "EF001120",
    featureDesc: "Number of triple openings",
    label: "EF001120: Number of triple openings"
  },
  {
    featureId: "EF001121",
    featureDesc: "Number of CEE-/perilex openings",
    label: "EF001121: Number of CEE-/perilex openings"
  },
  {
    featureId: "EF001122",
    featureDesc: "Side perforation",
    label: "EF001122: Side perforation"
  },
  {
    featureId: "EF001126",
    featureDesc: "Modular version",
    label: "EF001126: Modular version"
  },
  {
    featureId: "EF001127",
    featureDesc: "Suitable for front mounting",
    label: "EF001127: Suitable for front mounting"
  },
  {
    featureId: "EF001129",
    featureDesc: "Release-delay",
    label: "EF001129: Release-delay"
  },
  {
    featureId: "EF001130",
    featureDesc: "Suitable for depth of wall duct",
    label: "EF001130: Suitable for depth of wall duct"
  },
  {
    featureId: "EF001131",
    featureDesc: "Inner depth",
    label: "EF001131: Inner depth"
  },
  {
    featureId: "EF001132",
    featureDesc: "With connector openings",
    label: "EF001132: With connector openings"
  },
  {
    featureId: "EF001134",
    featureDesc: "DIN-rail",
    label: "EF001134: DIN-rail"
  },
  {
    featureId: "EF001135",
    featureDesc: "Type of cover",
    label: "EF001135: Type of cover"
  },
  {
    featureId: "EF001136",
    featureDesc: "With room temperature controller",
    label: "EF001136: With room temperature controller"
  },
  {
    featureId: "EF001139",
    featureDesc: "Type",
    label: "EF001139: Type"
  },
  {
    featureId: "EF001140",
    featureDesc: "Front shape",
    label: "EF001140: Front shape"
  },
  {
    featureId: "EF001141",
    featureDesc: "Switching contact",
    label: "EF001141: Switching contact"
  },
  {
    featureId: "EF001142",
    featureDesc: "Suitable for illumination",
    label: "EF001142: Suitable for illumination"
  },
  {
    featureId: "EF001144",
    featureDesc: "Measurement between phase and neutral conductor possible",
    label: "EF001144: Measurement between phase and neutral conductor possible"
  },
  {
    featureId: "EF001145",
    featureDesc: "Measurement between phases possible",
    label: "EF001145: Measurement between phases possible"
  },
  {
    featureId: "EF001146",
    featureDesc: "Quick locking",
    label: "EF001146: Quick locking"
  },
  {
    featureId: "EF001148",
    featureDesc: "Number of conductors (without earthing)",
    label: "EF001148: Number of conductors (without earthing)"
  },
  {
    featureId: "EF001149",
    featureDesc: "Phase selection",
    label: "EF001149: Phase selection"
  },
  {
    featureId: "EF001150",
    featureDesc: "Splash proof",
    label: "EF001150: Splash proof"
  },
  {
    featureId: "EF001161",
    featureDesc: "With de-aeration",
    label: "EF001161: With de-aeration"
  },
  {
    featureId: "EF001162",
    featureDesc: "With built-in filter",
    label: "EF001162: With built-in filter"
  },
  {
    featureId: "EF001163",
    featureDesc: "Padlock locking",
    label: "EF001163: Padlock locking"
  },
  {
    featureId: "EF001164",
    featureDesc: "Toggle",
    label: "EF001164: Toggle"
  },
  {
    featureId: "EF001165",
    featureDesc: "Type of handle",
    label: "EF001165: Type of handle"
  },
  {
    featureId: "EF001170",
    featureDesc: "Suitable for IR switch",
    label: "EF001170: Suitable for IR switch"
  },
  {
    featureId: "EF001171",
    featureDesc: "Suitable for IR venetian shutter switch",
    label: "EF001171: Suitable for IR venetian shutter switch"
  },
  {
    featureId: "EF001172",
    featureDesc: "Suitable for KNX",
    label: "EF001172: Suitable for KNX"
  },
  {
    featureId: "EF001173",
    featureDesc: "Suitable for power-/switch unit",
    label: "EF001173: Suitable for power-/switch unit"
  },
  {
    featureId: "EF001174",
    featureDesc: "Suitable for IR dimmer",
    label: "EF001174: Suitable for IR dimmer"
  },
  {
    featureId: "EF001175",
    featureDesc: "Suitable for IR push button",
    label: "EF001175: Suitable for IR push button"
  },
  {
    featureId: "EF001176",
    featureDesc: "Suitable for switch",
    label: "EF001176: Suitable for switch"
  },
  {
    featureId: "EF001177",
    featureDesc: "Suitable for pulse button",
    label: "EF001177: Suitable for pulse button"
  },
  {
    featureId: "EF001178",
    featureDesc: "Suitable for dimmer",
    label: "EF001178: Suitable for dimmer"
  },
  {
    featureId: "EF001179",
    featureDesc: "Suitable for roller shutter switch",
    label: "EF001179: Suitable for roller shutter switch"
  },
  {
    featureId: "EF001181",
    featureDesc: "Feedback-signal contact",
    label: "EF001181: Feedback-signal contact"
  },
  {
    featureId: "EF001183",
    featureDesc: "Daily program",
    label: "EF001183: Daily program"
  },
  {
    featureId: "EF001184",
    featureDesc: "Weekly program",
    label: "EF001184: Weekly program"
  },
  {
    featureId: "EF001185",
    featureDesc: "Annual program",
    label: "EF001185: Annual program"
  },
  {
    featureId: "EF001187",
    featureDesc: "With cover",
    label: "EF001187: With cover"
  },
  {
    featureId: "EF001189",
    featureDesc: "Suitable for compact fluorescent lamp",
    label: "EF001189: Suitable for compact fluorescent lamp"
  },
  {
    featureId: "EF001193",
    featureDesc: "Suitable for movement sensor",
    label: "EF001193: Suitable for movement sensor"
  },
  {
    featureId: "EF001198",
    featureDesc: "Suitable for wireless transmission",
    label: "EF001198: Suitable for wireless transmission"
  },
  {
    featureId: "EF001202",
    featureDesc: "With switch",
    label: "EF001202: With switch"
  },
  {
    featureId: "EF001203",
    featureDesc: "With light sensor",
    label: "EF001203: With light sensor"
  },
  {
    featureId: "EF001204",
    featureDesc: "Red-leaded",
    label: "EF001204: Red-leaded"
  },
  {
    featureId: "EF001205",
    featureDesc: "Tube diameter",
    label: "EF001205: Tube diameter"
  },
  {
    featureId: "EF001206",
    featureDesc: "Nickel-plated",
    label: "EF001206: Nickel-plated"
  },
  {
    featureId: "EF001207",
    featureDesc: "Corrosion resistant",
    label: "EF001207: Corrosion resistant"
  },
  {
    featureId: "EF001210",
    featureDesc: "Glass-fibre reinforced",
    label: "EF001210: Glass-fibre reinforced"
  },
  {
    featureId: "EF001213",
    featureDesc: "Presence simulation",
    label: "EF001213: Presence simulation"
  },
  {
    featureId: "EF001214",
    featureDesc: "Summer-/winter switchover",
    label: "EF001214: Summer-/winter switchover"
  },
  {
    featureId: "EF001215",
    featureDesc: "Overhear protected",
    label: "EF001215: Overhear protected"
  },
  {
    featureId: "EF001217",
    featureDesc: "Type of sealing",
    label: "EF001217: Type of sealing"
  },
  {
    featureId: "EF001218",
    featureDesc: "Angle",
    label: "EF001218: Angle"
  },
  {
    featureId: "EF001221",
    featureDesc: "Extension piece",
    label: "EF001221: Extension piece"
  },
  {
    featureId: "EF001222",
    featureDesc: "Number of groups",
    label: "EF001222: Number of groups"
  },
  {
    featureId: "EF001223",
    featureDesc: "Positioning on rail system",
    label: "EF001223: Positioning on rail system"
  },
  {
    featureId: "EF001224",
    featureDesc: "Type of fuse",
    label: "EF001224: Type of fuse"
  },
  {
    featureId: "EF001225",
    featureDesc: "Max. number of tubes",
    label: "EF001225: Max. number of tubes"
  },
  {
    featureId: "EF001227",
    featureDesc: "With plug",
    label: "EF001227: With plug"
  },
  {
    featureId: "EF001228",
    featureDesc: "Closed",
    label: "EF001228: Closed"
  },
  {
    featureId: "EF001229",
    featureDesc: "Couplable",
    label: "EF001229: Couplable"
  },
  {
    featureId: "EF001231",
    featureDesc: "Number of connection clamps",
    label: "EF001231: Number of connection clamps"
  },
  {
    featureId: "EF001232",
    featureDesc: "Number of DIN-rails",
    label: "EF001232: Number of DIN-rails"
  },
  {
    featureId: "EF001233",
    featureDesc: "Number of modular spacings per DIN-rail",
    label: "EF001233: Number of modular spacings per DIN-rail"
  },
  {
    featureId: "EF001238",
    featureDesc: "Plastic cover plate",
    label: "EF001238: Plastic cover plate"
  },
  {
    featureId: "EF001241",
    featureDesc: "Illuminated",
    label: "EF001241: Illuminated"
  },
  {
    featureId: "EF001249",
    featureDesc: "Hardened steel nail",
    label: "EF001249: Hardened steel nail"
  },
  {
    featureId: "EF001251",
    featureDesc: "Elevated",
    label: "EF001251: Elevated"
  },
  {
    featureId: "EF001257",
    featureDesc: "Material quality",
    label: "EF001257: Material quality"
  },
  {
    featureId: "EF001259",
    featureDesc: "Facet",
    label: "EF001259: Facet"
  },
  {
    featureId: "EF001260",
    featureDesc: "Material sleeve",
    label: "EF001260: Material sleeve"
  },
  {
    featureId: "EF001261",
    featureDesc: "With spring",
    label: "EF001261: With spring"
  },
  {
    featureId: "EF001263",
    featureDesc: "Ball-proof",
    label: "EF001263: Ball-proof"
  },
  {
    featureId: "EF001265",
    featureDesc: "Suitable for suspended mounting",
    label: "EF001265: Suitable for suspended mounting"
  },
  {
    featureId: "EF001271",
    featureDesc: "Meaning pictogram",
    label: "EF001271: Meaning pictogram"
  },
  {
    featureId: "EF001274",
    featureDesc: "Number of tubes",
    label: "EF001274: Number of tubes"
  },
  {
    featureId: "EF001277",
    featureDesc: "Suitable for labelling type",
    label: "EF001277: Suitable for labelling type"
  },
  {
    featureId: "EF001278",
    featureDesc: "Type of indicator",
    label: "EF001278: Type of indicator"
  },
  {
    featureId: "EF001280",
    featureDesc: "Number of groups/phases",
    label: "EF001280: Number of groups/phases"
  },
  {
    featureId: "EF001281",
    featureDesc: "Bendable",
    label: "EF001281: Bendable"
  },
  {
    featureId: "EF001285",
    featureDesc: "Top end diameter",
    label: "EF001285: Top end diameter"
  },
  {
    featureId: "EF001286",
    featureDesc: "Supporting bracket",
    label: "EF001286: Supporting bracket"
  },
  {
    featureId: "EF001291",
    featureDesc: "Lamp power of fluorescent batten",
    label: "EF001291: Lamp power of fluorescent batten"
  },
  {
    featureId: "EF001293",
    featureDesc: "With wiring",
    label: "EF001293: With wiring"
  },
  {
    featureId: "EF001294",
    featureDesc: "Number of cores wiring",
    label: "EF001294: Number of cores wiring"
  },
  {
    featureId: "EF001295",
    featureDesc: "Integrated cable duct",
    label: "EF001295: Integrated cable duct"
  },
  {
    featureId: "EF001297",
    featureDesc: "Integrated electromechanical coupling",
    label: "EF001297: Integrated electromechanical coupling"
  },
  {
    featureId: "EF001299",
    featureDesc: "With power track",
    label: "EF001299: With power track"
  },
  {
    featureId: "EF001300",
    featureDesc: "Number of connection points",
    label: "EF001300: Number of connection points"
  },
  {
    featureId: "EF001301",
    featureDesc: "With CEE-plug",
    label: "EF001301: With CEE-plug"
  },
  {
    featureId: "EF001302",
    featureDesc: "Rechargeable",
    label: "EF001302: Rechargeable"
  },
  {
    featureId: "EF001303",
    featureDesc: "Built-in charging circuit",
    label: "EF001303: Built-in charging circuit"
  },
  {
    featureId: "EF001305",
    featureDesc: "Width (big)",
    label: "EF001305: Width (big)"
  },
  {
    featureId: "EF001306",
    featureDesc: "Width (small)",
    label: "EF001306: Width (small)"
  },
  {
    featureId: "EF001309",
    featureDesc: "Max. power",
    label: "EF001309: Max. power"
  },
  {
    featureId: "EF001310",
    featureDesc: "Max. operating temperature",
    label: "EF001310: Max. operating temperature"
  },
  {
    featureId: "EF001313",
    featureDesc: "Switching power",
    label: "EF001313: Switching power"
  },
  {
    featureId: "EF001314",
    featureDesc: "Motor power",
    label: "EF001314: Motor power"
  },
  {
    featureId: "EF001315",
    featureDesc: "Max. output current",
    label: "EF001315: Max. output current"
  },
  {
    featureId: "EF001322",
    featureDesc: "Max. reach frontally",
    label: "EF001322: Max. reach frontally"
  },
  {
    featureId: "EF001323",
    featureDesc: "Max. reach sideways",
    label: "EF001323: Max. reach sideways"
  },
  {
    featureId: "EF001325",
    featureDesc: "Max. switching current (resistive load)",
    label: "EF001325: Max. switching current (resistive load)"
  },
  {
    featureId: "EF001327",
    featureDesc: "Max. transmission range",
    label: "EF001327: Max. transmission range"
  },
  {
    featureId: "EF001331",
    featureDesc: "Rotated central insert",
    label: "EF001331: Rotated central insert"
  },
  {
    featureId: "EF001332",
    featureDesc: "Over voltage protection",
    label: "EF001332: Over voltage protection"
  },
  {
    featureId: "EF001333",
    featureDesc: "Fault current protection",
    label: "EF001333: Fault current protection"
  },
  {
    featureId: "EF001334",
    featureDesc: "Special power supply",
    label: "EF001334: Special power supply"
  },
  {
    featureId: "EF001335",
    featureDesc: "Nominal cross section",
    label: "EF001335: Nominal cross section"
  },
  {
    featureId: "EF001336",
    featureDesc: "Longitudinal sealed",
    label: "EF001336: Longitudinal sealed"
  },
  {
    featureId: "EF001337",
    featureDesc: "Code digit",
    label: "EF001337: Code digit"
  },
  {
    featureId: "EF001338",
    featureDesc: "Connecting angle",
    label: "EF001338: Connecting angle"
  },
  {
    featureId: "EF001339",
    featureDesc: "Flange shape",
    label: "EF001339: Flange shape"
  },
  {
    featureId: "EF001341",
    featureDesc: "Reduced cross section",
    label: "EF001341: Reduced cross section"
  },
  {
    featureId: "EF001342",
    featureDesc: "Oil stop/centre bar",
    label: "EF001342: Oil stop/centre bar"
  },
  {
    featureId: "EF001343",
    featureDesc: "For pull protected connections",
    label: "EF001343: For pull protected connections"
  },
  {
    featureId: "EF001344",
    featureDesc: "Connection as copper bolt",
    label: "EF001344: Connection as copper bolt"
  },
  {
    featureId: "EF001345",
    featureDesc: "Number of conductors per cable",
    label: "EF001345: Number of conductors per cable"
  },
  {
    featureId: "EF001347",
    featureDesc: "Compressive strength",
    label: "EF001347: Compressive strength"
  },
  {
    featureId: "EF001348",
    featureDesc: "Lamp efficacy",
    label: "EF001348: Lamp efficacy"
  },
  {
    featureId: "EF001349",
    featureDesc: "Ignition strip",
    label: "EF001349: Ignition strip"
  },
  {
    featureId: "EF001350",
    featureDesc: "Black light lamp",
    label: "EF001350: Black light lamp"
  },
  {
    featureId: "EF001351",
    featureDesc: "Fast ignition",
    label: "EF001351: Fast ignition"
  },
  {
    featureId: "EF001352",
    featureDesc: "Starter less operation",
    label: "EF001352: Starter less operation"
  },
  {
    featureId: "EF001353",
    featureDesc: "UV protection",
    label: "EF001353: UV protection"
  },
  {
    featureId: "EF001354",
    featureDesc: "Infrared coating/-technique",
    label: "EF001354: Infrared coating/-technique"
  },
  {
    featureId: "EF001355",
    featureDesc: "ANSI",
    label: "EF001355: ANSI"
  },
  {
    featureId: "EF001356",
    featureDesc: "Reflector type",
    label: "EF001356: Reflector type"
  },
  {
    featureId: "EF001357",
    featureDesc: "Luminous intensity",
    label: "EF001357: Luminous intensity"
  },
  {
    featureId: "EF001358",
    featureDesc: "Transformer integrated",
    label: "EF001358: Transformer integrated"
  },
  {
    featureId: "EF001359",
    featureDesc: "Burning position",
    label: "EF001359: Burning position"
  },
  {
    featureId: "EF001360",
    featureDesc: "Lamp shape",
    label: "EF001360: Lamp shape"
  },
  {
    featureId: "EF001361",
    featureDesc: "Integrated twilight sensor",
    label: "EF001361: Integrated twilight sensor"
  },
  {
    featureId: "EF001362",
    featureDesc: "Amalgam technology",
    label: "EF001362: Amalgam technology"
  },
  {
    featureId: "EF001364",
    featureDesc: "Rated operation power at AC-3, 400 V",
    label: "EF001364: Rated operation power at AC-3, 400 V"
  },
  {
    featureId: "EF001365",
    featureDesc: "Type of excitation",
    label: "EF001365: Type of excitation"
  },
  {
    featureId: "EF001366",
    featureDesc: "Rated insulation voltage Ui",
    label: "EF001366: Rated insulation voltage Ui"
  },
  {
    featureId: "EF001370",
    featureDesc: "Height of housing",
    label: "EF001370: Height of housing"
  },
  {
    featureId: "EF001371",
    featureDesc: "Shape of lens",
    label: "EF001371: Shape of lens"
  },
  {
    featureId: "EF001372",
    featureDesc: "Number of command positions",
    label: "EF001372: Number of command positions"
  },
  {
    featureId: "EF001373",
    featureDesc: "Colour housing cover",
    label: "EF001373: Colour housing cover"
  },
  {
    featureId: "EF001374",
    featureDesc: "Number of normally open contacts as main contact",
    label: "EF001374: Number of normally open contacts as main contact"
  },
  {
    featureId: "EF001375",
    featureDesc: "Number of normally closed contacts as main contact",
    label: "EF001375: Number of normally closed contacts as main contact"
  },
  {
    featureId: "EF001376",
    featureDesc: "Number of auxiliary contacts as normally open contact",
    label: "EF001376: Number of auxiliary contacts as normally open contact"
  },
  {
    featureId: "EF001377",
    featureDesc: "Number of auxiliary contacts as normally closed contact",
    label: "EF001377: Number of auxiliary contacts as normally closed contact"
  },
  {
    featureId: "EF001378",
    featureDesc: "Rated operation current Ie, 400 V",
    label: "EF001378: Rated operation current Ie, 400 V"
  },
  {
    featureId: "EF001384",
    featureDesc: "With integrated suppressor",
    label: "EF001384: With integrated suppressor"
  },
  {
    featureId: "EF001385",
    featureDesc: "Rated operation current Ie at AC-15, 230 V",
    label: "EF001385: Rated operation current Ie at AC-15, 230 V"
  },
  {
    featureId: "EF001386",
    featureDesc: "Rated operation current Ie at DC-13, 230 V",
    label: "EF001386: Rated operation current Ie at DC-13, 230 V"
  },
  {
    featureId: "EF001387",
    featureDesc: "With integrated auxiliary switch",
    label: "EF001387: With integrated auxiliary switch"
  },
  {
    featureId: "EF001389",
    featureDesc: "Rated permanent current Iu",
    label: "EF001389: Rated permanent current Iu"
  },
  {
    featureId: "EF001390",
    featureDesc: "With integrated under voltage release",
    label: "EF001390: With integrated under voltage release"
  },
  {
    featureId: "EF001391",
    featureDesc: "Number of poles",
    label: "EF001391: Number of poles"
  },
  {
    featureId: "EF001392",
    featureDesc: "Rated operation current Ie at AC-3, 400 V",
    label: "EF001392: Rated operation current Ie at AC-3, 400 V"
  },
  {
    featureId: "EF001393",
    featureDesc: "Rated operation current Ie at AC-1, 400 V",
    label: "EF001393: Rated operation current Ie at AC-1, 400 V"
  },
  {
    featureId: "EF001399",
    featureDesc: "With integrated voltage decreasing resistor",
    label: "EF001399: With integrated voltage decreasing resistor"
  },
  {
    featureId: "EF001400",
    featureDesc: "With integrated diode",
    label: "EF001400: With integrated diode"
  },
  {
    featureId: "EF001401",
    featureDesc: "Type of lighting",
    label: "EF001401: Type of lighting"
  },
  {
    featureId: "EF001402",
    featureDesc: "Colour light source",
    label: "EF001402: Colour light source"
  },
  {
    featureId: "EF001403",
    featureDesc: "Unlocking method",
    label: "EF001403: Unlocking method"
  },
  {
    featureId: "EF001404",
    featureDesc: "Colour cap",
    label: "EF001404: Colour cap"
  },
  {
    featureId: "EF001407",
    featureDesc: "Spring-return",
    label: "EF001407: Spring-return"
  },
  {
    featureId: "EF001408",
    featureDesc: "With manual on/off switch",
    label: "EF001408: With manual on/off switch"
  },
  {
    featureId: "EF001409",
    featureDesc: "Switching capacity at AC-3, 240 V",
    label: "EF001409: Switching capacity at AC-3, 240 V"
  },
  {
    featureId: "EF001410",
    featureDesc: "Initial setting",
    label: "EF001410: Initial setting"
  },
  {
    featureId: "EF001411",
    featureDesc: "End setting",
    label: "EF001411: End setting"
  },
  {
    featureId: "EF001413",
    featureDesc: "Short-circuit release function",
    label: "EF001413: Short-circuit release function"
  },
  {
    featureId: "EF001416",
    featureDesc: "With zero (off) position",
    label: "EF001416: With zero (off) position"
  },
  {
    featureId: "EF001420",
    featureDesc: "Delay adjustable",
    label: "EF001420: Delay adjustable"
  },
  {
    featureId: "EF001421",
    featureDesc: "Max. delay time",
    label: "EF001421: Max. delay time"
  },
  {
    featureId: "EF001422",
    featureDesc: "Type of drive",
    label: "EF001422: Type of drive"
  },
  {
    featureId: "EF001423",
    featureDesc: "Diameter cap",
    label: "EF001423: Diameter cap"
  },
  {
    featureId: "EF001428",
    featureDesc: "Input current at signal 1",
    label: "EF001428: Input current at signal 1"
  },
  {
    featureId: "EF001430",
    featureDesc: "Distance between rail centre, 50 mm",
    label: "EF001430: Distance between rail centre, 50 mm"
  },
  {
    featureId: "EF001431",
    featureDesc: "Distance between rail centre, 40 mm",
    label: "EF001431: Distance between rail centre, 40 mm"
  },
  {
    featureId: "EF001432",
    featureDesc: "Distance between rail centre, 60 mm",
    label: "EF001432: Distance between rail centre, 60 mm"
  },
  {
    featureId: "EF001433",
    featureDesc: "Suitable for emergency stop",
    label: "EF001433: Suitable for emergency stop"
  },
  {
    featureId: "EF001434",
    featureDesc: "With extension shaft",
    label: "EF001434: With extension shaft"
  },
  {
    featureId: "EF001435",
    featureDesc: "Rated operating voltage",
    label: "EF001435: Rated operating voltage"
  },
  {
    featureId: "EF001437",
    featureDesc: "Type of fuses",
    label: "EF001437: Type of fuses"
  },
  {
    featureId: "EF001438",
    featureDesc: "Length",
    label: "EF001438: Length"
  },
  {
    featureId: "EF001443",
    featureDesc: "Rated operation current Ie",
    label: "EF001443: Rated operation current Ie"
  },
  {
    featureId: "EF001444",
    featureDesc: "Conditioned rated short-circuit current Iq",
    label: "EF001444: Conditioned rated short-circuit current Iq"
  },
  {
    featureId: "EF001446",
    featureDesc: "Function flashlight",
    label: "EF001446: Function flashlight"
  },
  {
    featureId: "EF001447",
    featureDesc: "Function acoustic",
    label: "EF001447: Function acoustic"
  },
  {
    featureId: "EF001448",
    featureDesc: "Function continuous light",
    label: "EF001448: Function continuous light"
  },
  {
    featureId: "EF001449",
    featureDesc: "Number of elements",
    label: "EF001449: Number of elements"
  },
  {
    featureId: "EF001450",
    featureDesc: "Auxiliary contacts, extendable",
    label: "EF001450: Auxiliary contacts, extendable"
  },
  {
    featureId: "EF001452",
    featureDesc: "Construction size fuse insert",
    label: "EF001452: Construction size fuse insert"
  },
  {
    featureId: "EF001453",
    featureDesc: "Channel range",
    label: "EF001453: Channel range"
  },
  {
    featureId: "EF001454",
    featureDesc: "Sealable",
    label: "EF001454: Sealable"
  },
  {
    featureId: "EF001455",
    featureDesc: "Type of inlet",
    label: "EF001455: Type of inlet"
  },
  {
    featureId: "EF001456",
    featureDesc: "Height/depth",
    label: "EF001456: Height/depth"
  },
  {
    featureId: "EF001457",
    featureDesc: "Punch shape",
    label: "EF001457: Punch shape"
  },
  {
    featureId: "EF001458",
    featureDesc: "Nominal size",
    label: "EF001458: Nominal size"
  },
  {
    featureId: "EF001462",
    featureDesc: "Construction/operation",
    label: "EF001462: Construction/operation"
  },
  {
    featureId: "EF001463",
    featureDesc: "Isolation protection 1000 V",
    label: "EF001463: Isolation protection 1000 V"
  },
  {
    featureId: "EF001464",
    featureDesc: "Soldering tip diameter",
    label: "EF001464: Soldering tip diameter"
  },
  {
    featureId: "EF001465",
    featureDesc: "Max. soldering tip temperature",
    label: "EF001465: Max. soldering tip temperature"
  },
  {
    featureId: "EF001466",
    featureDesc: "Warm-up period",
    label: "EF001466: Warm-up period"
  },
  {
    featureId: "EF001467",
    featureDesc: "Antistatic version",
    label: "EF001467: Antistatic version"
  },
  {
    featureId: "EF001468",
    featureDesc: "Type of holding",
    label: "EF001468: Type of holding"
  },
  {
    featureId: "EF001469",
    featureDesc: "Type of power supply",
    label: "EF001469: Type of power supply"
  },
  {
    featureId: "EF001470",
    featureDesc: "Secondary voltage",
    label: "EF001470: Secondary voltage"
  },
  {
    featureId: "EF001471",
    featureDesc: "Digital indication",
    label: "EF001471: Digital indication"
  },
  {
    featureId: "EF001472",
    featureDesc: "Number of call buttons",
    label: "EF001472: Number of call buttons"
  },
  {
    featureId: "EF001474",
    featureDesc: "Installation technique",
    label: "EF001474: Installation technique"
  },
  {
    featureId: "EF001476",
    featureDesc: "Mutable call tone",
    label: "EF001476: Mutable call tone"
  },
  {
    featureId: "EF001478",
    featureDesc: "Operation door lock",
    label: "EF001478: Operation door lock"
  },
  {
    featureId: "EF001479",
    featureDesc: "Internal communication",
    label: "EF001479: Internal communication"
  },
  {
    featureId: "EF001483",
    featureDesc: "Additional device connectable",
    label: "EF001483: Additional device connectable"
  },
  {
    featureId: "EF001484",
    featureDesc: "Loudness control",
    label: "EF001484: Loudness control"
  },
  {
    featureId: "EF001485",
    featureDesc: "Mail boxes connectable",
    label: "EF001485: Mail boxes connectable"
  },
  {
    featureId: "EF001486",
    featureDesc: "Info label connectable",
    label: "EF001486: Info label connectable"
  },
  {
    featureId: "EF001487",
    featureDesc: "230 V integration",
    label: "EF001487: 230 V integration"
  },
  {
    featureId: "EF001488",
    featureDesc: "Illumination integrable",
    label: "EF001488: Illumination integrable"
  },
  {
    featureId: "EF001489",
    featureDesc: "Anti-theft protection integrable",
    label: "EF001489: Anti-theft protection integrable"
  },
  {
    featureId: "EF001490",
    featureDesc: "With video",
    label: "EF001490: With video"
  },
  {
    featureId: "EF001491",
    featureDesc: "With info sign",
    label: "EF001491: With info sign"
  },
  {
    featureId: "EF001492",
    featureDesc: "Colour outdoor station",
    label: "EF001492: Colour outdoor station"
  },
  {
    featureId: "EF001493",
    featureDesc: "Remote programmable",
    label: "EF001493: Remote programmable"
  },
  {
    featureId: "EF001498",
    featureDesc: "Video driving",
    label: "EF001498: Video driving"
  },
  {
    featureId: "EF001499",
    featureDesc: "Bus system KNX",
    label: "EF001499: Bus system KNX"
  },
  {
    featureId: "EF001500",
    featureDesc: "Bus system radio frequent",
    label: "EF001500: Bus system radio frequent"
  },
  {
    featureId: "EF001501",
    featureDesc: "Bus system Powernet",
    label: "EF001501: Bus system Powernet"
  },
  {
    featureId: "EF001503",
    featureDesc: "Max. switching power",
    label: "EF001503: Max. switching power"
  },
  {
    featureId: "EF001504",
    featureDesc: "With bus connection",
    label: "EF001504: With bus connection"
  },
  {
    featureId: "EF001507",
    featureDesc: "Max. output power",
    label: "EF001507: Max. output power"
  },
  {
    featureId: "EF001508",
    featureDesc: "Type of load",
    label: "EF001508: Type of load"
  },
  {
    featureId: "EF001509",
    featureDesc: "Max. control current",
    label: "EF001509: Max. control current"
  },
  {
    featureId: "EF001511",
    featureDesc: "Input current drive",
    label: "EF001511: Input current drive"
  },
  {
    featureId: "EF001512",
    featureDesc: "Input voltage drive",
    label: "EF001512: Input voltage drive"
  },
  {
    featureId: "EF001515",
    featureDesc: "Text field",
    label: "EF001515: Text field"
  },
  {
    featureId: "EF001518",
    featureDesc: "Bus voltage stockable",
    label: "EF001518: Bus voltage stockable"
  },
  {
    featureId: "EF001519",
    featureDesc: "Imprintable label",
    label: "EF001519: Imprintable label"
  },
  {
    featureId: "EF001527",
    featureDesc: "Language",
    label: "EF001527: Language"
  },
  {
    featureId: "EF001529",
    featureDesc: "Tariff switch",
    label: "EF001529: Tariff switch"
  },
  {
    featureId: "EF001530",
    featureDesc: "S0 impulse interface",
    label: "EF001530: S0 impulse interface"
  },
  {
    featureId: "EF001531",
    featureDesc: "Reactive power",
    label: "EF001531: Reactive power"
  },
  {
    featureId: "EF001532",
    featureDesc: "Accuracy class",
    label: "EF001532: Accuracy class"
  },
  {
    featureId: "EF001533",
    featureDesc: "Approved according to PTB",
    label: "EF001533: Approved according to PTB"
  },
  {
    featureId: "EF001534",
    featureDesc: "Fault current measurement",
    label: "EF001534: Fault current measurement"
  },
  {
    featureId: "EF001535",
    featureDesc: "Load current measurement",
    label: "EF001535: Load current measurement"
  },
  {
    featureId: "EF001536",
    featureDesc: "Max. load current",
    label: "EF001536: Max. load current"
  },
  {
    featureId: "EF001537",
    featureDesc: "Labelled",
    label: "EF001537: Labelled"
  },
  {
    featureId: "EF001538",
    featureDesc: "With control element",
    label: "EF001538: With control element"
  },
  {
    featureId: "EF001539",
    featureDesc: "Programme diameter",
    label: "EF001539: Programme diameter"
  },
  {
    featureId: "EF001541",
    featureDesc: "Delayed",
    label: "EF001541: Delayed"
  },
  {
    featureId: "EF001543",
    featureDesc: "Suitable for off-load switch",
    label: "EF001543: Suitable for off-load switch"
  },
  {
    featureId: "EF001545",
    featureDesc: "Suitable for overload relay",
    label: "EF001545: Suitable for overload relay"
  },
  {
    featureId: "EF001546",
    featureDesc: "Type of button",
    label: "EF001546: Type of button"
  },
  {
    featureId: "EF001547",
    featureDesc: "Suitable for cable modem",
    label: "EF001547: Suitable for cable modem"
  },
  {
    featureId: "EF001548",
    featureDesc: "Suitable for remote power supply",
    label: "EF001548: Suitable for remote power supply"
  },
  {
    featureId: "EF001549",
    featureDesc: "D-net",
    label: "EF001549: D-net"
  },
  {
    featureId: "EF001550",
    featureDesc: "E-net",
    label: "EF001550: E-net"
  },
  {
    featureId: "EF001551",
    featureDesc: "UMTS",
    label: "EF001551: UMTS"
  },
  {
    featureId: "EF001552",
    featureDesc: "DVB-T (digital video broadcast - terrestrial)",
    label: "EF001552: DVB-T (digital video broadcast - terrestrial)"
  },
  {
    featureId: "EF001553",
    featureDesc: "DAB (digital audio broadcast)",
    label: "EF001553: DAB (digital audio broadcast)"
  },
  {
    featureId: "EF001554",
    featureDesc: "FM receiver",
    label: "EF001554: FM receiver"
  },
  {
    featureId: "EF001555",
    featureDesc: "Special range for radio",
    label: "EF001555: Special range for radio"
  },
  {
    featureId: "EF001556",
    featureDesc: "Integrated multi switch",
    label: "EF001556: Integrated multi switch"
  },
  {
    featureId: "EF001557",
    featureDesc: "Terrestrial feed",
    label: "EF001557: Terrestrial feed"
  },
  {
    featureId: "EF001558",
    featureDesc: "Holding fixture",
    label: "EF001558: Holding fixture"
  },
  {
    featureId: "EF001559",
    featureDesc: "Max. number of modules",
    label: "EF001559: Max. number of modules"
  },
  {
    featureId: "EF001560",
    featureDesc: "With output combiner",
    label: "EF001560: With output combiner"
  },
  {
    featureId: "EF001561",
    featureDesc: "Integrated power adaptor",
    label: "EF001561: Integrated power adaptor"
  },
  {
    featureId: "EF001562",
    featureDesc: "Suitable for 19 inch cabinet",
    label: "EF001562: Suitable for 19 inch cabinet"
  },
  {
    featureId: "EF001563",
    featureDesc: "Max. equalization",
    label: "EF001563: Max. equalization"
  },
  {
    featureId: "EF001564",
    featureDesc: "Diameter/side length",
    label: "EF001564: Diameter/side length"
  },
  {
    featureId: "EF001565",
    featureDesc: "Feed installation",
    label: "EF001565: Feed installation"
  },
  {
    featureId: "EF001567",
    featureDesc: "Suitable for type of fibre",
    label: "EF001567: Suitable for type of fibre"
  },
  {
    featureId: "EF001568",
    featureDesc: "Material of ferrule",
    label: "EF001568: Material of ferrule"
  },
  {
    featureId: "EF001569",
    featureDesc: "APC-version",
    label: "EF001569: APC-version"
  },
  {
    featureId: "EF001571",
    featureDesc: "Suitable for ribbon cable",
    label: "EF001571: Suitable for ribbon cable"
  },
  {
    featureId: "EF001572",
    featureDesc: "Extra monitor connection",
    label: "EF001572: Extra monitor connection"
  },
  {
    featureId: "EF001573",
    featureDesc: "Material cutting surface",
    label: "EF001573: Material cutting surface"
  },
  {
    featureId: "EF001576",
    featureDesc: "Number of connectors rear side",
    label: "EF001576: Number of connectors rear side"
  },
  {
    featureId: "EF001577",
    featureDesc: "With bus/coupling",
    label: "EF001577: With bus/coupling"
  },
  {
    featureId: "EF001579",
    featureDesc: "Suitable for number of connectors",
    label: "EF001579: Suitable for number of connectors"
  },
  {
    featureId: "EF001580",
    featureDesc: "With coupling/adapter",
    label: "EF001580: With coupling/adapter"
  },
  {
    featureId: "EF001582",
    featureDesc: "With pigtails",
    label: "EF001582: With pigtails"
  },
  {
    featureId: "EF001583",
    featureDesc: "Suitable for number of couplings/adapters",
    label: "EF001583: Suitable for number of couplings/adapters"
  },
  {
    featureId: "EF001585",
    featureDesc: "With front plate",
    label: "EF001585: With front plate"
  },
  {
    featureId: "EF001586",
    featureDesc: "Chamfered",
    label: "EF001586: Chamfered"
  },
  {
    featureId: "EF001587",
    featureDesc: "Contact type",
    label: "EF001587: Contact type"
  },
  {
    featureId: "EF001588",
    featureDesc: "Insulated mounting",
    label: "EF001588: Insulated mounting"
  },
  {
    featureId: "EF001589",
    featureDesc: "Suitable for number of poles",
    label: "EF001589: Suitable for number of poles"
  },
  {
    featureId: "EF001590",
    featureDesc: "Two piece",
    label: "EF001590: Two piece"
  },
  {
    featureId: "EF001592",
    featureDesc: "With splice holder",
    label: "EF001592: With splice holder"
  },
  {
    featureId: "EF001594",
    featureDesc: "Type of tone",
    label: "EF001594: Type of tone"
  },
  {
    featureId: "EF001595",
    featureDesc: "Type of device",
    label: "EF001595: Type of device"
  },
  {
    featureId: "EF001596",
    featureDesc: "Housing material",
    label: "EF001596: Housing material"
  },
  {
    featureId: "EF001597",
    featureDesc: "Suitable for safety functions",
    label: "EF001597: Suitable for safety functions"
  },
  {
    featureId: "EF001601",
    featureDesc: "Coating housing",
    label: "EF001601: Coating housing"
  },
  {
    featureId: "EF001602",
    featureDesc: "With status indication",
    label: "EF001602: With status indication"
  },
  {
    featureId: "EF001604",
    featureDesc: "Integrated automatic shutter",
    label: "EF001604: Integrated automatic shutter"
  },
  {
    featureId: "EF001605",
    featureDesc: "Volume flow",
    label: "EF001605: Volume flow"
  },
  {
    featureId: "EF001607",
    featureDesc: "Nominal width",
    label: "EF001607: Nominal width"
  },
  {
    featureId: "EF001613",
    featureDesc: "Circuit integrity",
    label: "EF001613: Circuit integrity"
  },
  {
    featureId: "EF001615",
    featureDesc: "Nominal voltage U",
    label: "EF001615: Nominal voltage U"
  },
  {
    featureId: "EF001617",
    featureDesc: "Stranding element",
    label: "EF001617: Stranding element"
  },
  {
    featureId: "EF001618",
    featureDesc: "Colour outer sheath",
    label: "EF001618: Colour outer sheath"
  },
  {
    featureId: "EF001619",
    featureDesc: "Category",
    label: "EF001619: Category"
  },
  {
    featureId: "EF001620",
    featureDesc: "Shape of conductor",
    label: "EF001620: Shape of conductor"
  },
  {
    featureId: "EF001621",
    featureDesc: "Diameter conductor",
    label: "EF001621: Diameter conductor"
  },
  {
    featureId: "EF001622",
    featureDesc: "Conductor material",
    label: "EF001622: Conductor material"
  },
  {
    featureId: "EF001623",
    featureDesc: "Screen over stranding element",
    label: "EF001623: Screen over stranding element"
  },
  {
    featureId: "EF001624",
    featureDesc: "Screen over stranding",
    label: "EF001624: Screen over stranding"
  },
  {
    featureId: "EF001625",
    featureDesc: "Material outer sheath",
    label: "EF001625: Material outer sheath"
  },
  {
    featureId: "EF001626",
    featureDesc: "Type of outer conductor",
    label: "EF001626: Type of outer conductor"
  },
  {
    featureId: "EF001627",
    featureDesc: "Inner conductor material",
    label: "EF001627: Inner conductor material"
  },
  {
    featureId: "EF001628",
    featureDesc: "Characteristic impedance",
    label: "EF001628: Characteristic impedance"
  },
  {
    featureId: "EF001629",
    featureDesc: "Diameter inner conductor",
    label: "EF001629: Diameter inner conductor"
  },
  {
    featureId: "EF001630",
    featureDesc: "Diameter outer conductor",
    label: "EF001630: Diameter outer conductor"
  },
  {
    featureId: "EF001631",
    featureDesc: "Outer conductor material",
    label: "EF001631: Outer conductor material"
  },
  {
    featureId: "EF001632",
    featureDesc: "Conductor category",
    label: "EF001632: Conductor category"
  },
  {
    featureId: "EF001636",
    featureDesc: "Material conductor 2",
    label: "EF001636: Material conductor 2"
  },
  {
    featureId: "EF001637",
    featureDesc: "Laminated sheath",
    label: "EF001637: Laminated sheath"
  },
  {
    featureId: "EF001638",
    featureDesc: "Fibre optic elements",
    label: "EF001638: Fibre optic elements"
  },
  {
    featureId: "EF001639",
    featureDesc: "Mains frequency",
    label: "EF001639: Mains frequency"
  },
  {
    featureId: "EF001642",
    featureDesc: "Rotational speed",
    label: "EF001642: Rotational speed"
  },
  {
    featureId: "EF001643",
    featureDesc: "Diameter impeller",
    label: "EF001643: Diameter impeller"
  },
  {
    featureId: "EF001644",
    featureDesc: "Temperature class according to directive 94/9/EC",
    label: "EF001644: Temperature class according to directive 94/9/EC"
  },
  {
    featureId: "EF001645",
    featureDesc: "Device group according to directive 94/9/EC",
    label: "EF001645: Device group according to directive 94/9/EC"
  },
  {
    featureId: "EF001648",
    featureDesc: "Connection diameter",
    label: "EF001648: Connection diameter"
  },
  {
    featureId: "EF001649",
    featureDesc: "Filter class",
    label: "EF001649: Filter class"
  },
  {
    featureId: "EF001650",
    featureDesc: "Filter type",
    label: "EF001650: Filter type"
  },
  {
    featureId: "EF001651",
    featureDesc: "With fire barrier",
    label: "EF001651: With fire barrier"
  },
  {
    featureId: "EF001652",
    featureDesc: "Built-in size width",
    label: "EF001652: Built-in size width"
  },
  {
    featureId: "EF001653",
    featureDesc: "Built-in size height",
    label: "EF001653: Built-in size height"
  },
  {
    featureId: "EF001654",
    featureDesc: "Built-in size depth",
    label: "EF001654: Built-in size depth"
  },
  {
    featureId: "EF001656",
    featureDesc: "With heating",
    label: "EF001656: With heating"
  },
  {
    featureId: "EF001663",
    featureDesc: "Rated frequency",
    label: "EF001663: Rated frequency"
  },
  {
    featureId: "EF001664",
    featureDesc: "Mounting position",
    label: "EF001664: Mounting position"
  },
  {
    featureId: "EF001666",
    featureDesc: "Cross section height",
    label: "EF001666: Cross section height"
  },
  {
    featureId: "EF001667",
    featureDesc: "Cross section width",
    label: "EF001667: Cross section width"
  },
  {
    featureId: "EF001668",
    featureDesc: "Suitable for tube mounting",
    label: "EF001668: Suitable for tube mounting"
  },
  {
    featureId: "EF001669",
    featureDesc: "Suitable for angle mounting",
    label: "EF001669: Suitable for angle mounting"
  },
  {
    featureId: "EF001672",
    featureDesc: "Inner diameter desoldering tip",
    label: "EF001672: Inner diameter desoldering tip"
  },
  {
    featureId: "EF001673",
    featureDesc: "Type of desoldering tip",
    label: "EF001673: Type of desoldering tip"
  },
  {
    featureId: "EF001676",
    featureDesc: "Inner conductor category",
    label: "EF001676: Inner conductor category"
  },
  {
    featureId: "EF001678",
    featureDesc: "Type of tube",
    label: "EF001678: Type of tube"
  },
  {
    featureId: "EF001679",
    featureDesc: "Number of fibres per tube",
    label: "EF001679: Number of fibres per tube"
  },
  {
    featureId: "EF001682",
    featureDesc: "Cable metal free",
    label: "EF001682: Cable metal free"
  },
  {
    featureId: "EF001683",
    featureDesc: "Armouring/reinforcement",
    label: "EF001683: Armouring/reinforcement"
  },
  {
    featureId: "EF001685",
    featureDesc: "Permitted cable outer temperature during assembling/handling",
    label: "EF001685: Permitted cable outer temperature during assembling/handling"
  },
  {
    featureId: "EF001686",
    featureDesc: "Permitted cable outer temperature after assembling without vibration",
    label: "EF001686: Permitted cable outer temperature after assembling without vibration"
  },
  {
    featureId: "EF001687",
    featureDesc: "Torsion resistant",
    label: "EF001687: Torsion resistant"
  },
  {
    featureId: "EF001688",
    featureDesc: "Number of stranding elements",
    label: "EF001688: Number of stranding elements"
  },
  {
    featureId: "EF001691",
    featureDesc: "Protective sheath",
    label: "EF001691: Protective sheath"
  },
  {
    featureId: "EF001692",
    featureDesc: "AWG-size",
    label: "EF001692: AWG-size"
  },
  {
    featureId: "EF001696",
    featureDesc: "Nominal cross section pilot/reduced conductors",
    label: "EF001696: Nominal cross section pilot/reduced conductors"
  },
  {
    featureId: "EF001697",
    featureDesc: "Number of pilot/reduced conductors",
    label: "EF001697: Number of pilot/reduced conductors"
  },
  {
    featureId: "EF001698",
    featureDesc: "Material conductor 1",
    label: "EF001698: Material conductor 1"
  },
  {
    featureId: "EF001699",
    featureDesc: "Number of thermo-pairs",
    label: "EF001699: Number of thermo-pairs"
  },
  {
    featureId: "EF001700",
    featureDesc: "Inner semi-conducting layer",
    label: "EF001700: Inner semi-conducting layer"
  },
  {
    featureId: "EF001701",
    featureDesc: "Outer semi-conducting layer",
    label: "EF001701: Outer semi-conducting layer"
  },
  {
    featureId: "EF001703",
    featureDesc: "Core colour",
    label: "EF001703: Core colour"
  },
  {
    featureId: "EF001704",
    featureDesc: "Longitudinal water blocking screen",
    label: "EF001704: Longitudinal water blocking screen"
  },
  {
    featureId: "EF001707",
    featureDesc: "Max. supply temperature",
    label: "EF001707: Max. supply temperature"
  },
  {
    featureId: "EF001709",
    featureDesc: "Controller",
    label: "EF001709: Controller"
  },
  {
    featureId: "EF001711",
    featureDesc: "Voltage level",
    label: "EF001711: Voltage level"
  },
  {
    featureId: "EF001712",
    featureDesc: "Temperature protected till",
    label: "EF001712: Temperature protected till"
  },
  {
    featureId: "EF001713",
    featureDesc: "For cables resistant to short circuit",
    label: "EF001713: For cables resistant to short circuit"
  },
  {
    featureId: "EF001714",
    featureDesc: "Reel/roll packaging",
    label: "EF001714: Reel/roll packaging"
  },
  {
    featureId: "EF001715",
    featureDesc: "Colour insulation",
    label: "EF001715: Colour insulation"
  },
  {
    featureId: "EF001716",
    featureDesc: "Plug dimensions",
    label: "EF001716: Plug dimensions"
  },
  {
    featureId: "EF001717",
    featureDesc: "Number of parts",
    label: "EF001717: Number of parts"
  },
  {
    featureId: "EF001719",
    featureDesc: "Number of mounting holes",
    label: "EF001719: Number of mounting holes"
  },
  {
    featureId: "EF001724",
    featureDesc: "With shear-off head",
    label: "EF001724: With shear-off head"
  },
  {
    featureId: "EF001729",
    featureDesc: "Printed",
    label: "EF001729: Printed"
  },
  {
    featureId: "EF001731",
    featureDesc: "Temperature",
    label: "EF001731: Temperature"
  },
  {
    featureId: "EF001732",
    featureDesc: "Max. starting current",
    label: "EF001732: Max. starting current"
  },
  {
    featureId: "EF001734",
    featureDesc: "With Astro program",
    label: "EF001734: With Astro program"
  },
  {
    featureId: "EF001735",
    featureDesc: "With sun sensor",
    label: "EF001735: With sun sensor"
  },
  {
    featureId: "EF001739",
    featureDesc: "Max. number of switching contacts",
    label: "EF001739: Max. number of switching contacts"
  },
  {
    featureId: "EF001740",
    featureDesc: "Max. number of venetian-blind outputs",
    label: "EF001740: Max. number of venetian-blind outputs"
  },
  {
    featureId: "EF001741",
    featureDesc: "Switching current (resistive)",
    label: "EF001741: Switching current (resistive)"
  },
  {
    featureId: "EF001742",
    featureDesc: "Operating temperature",
    label: "EF001742: Operating temperature"
  },
  {
    featureId: "EF001743",
    featureDesc: "Fire resistance according to UL94",
    label: "EF001743: Fire resistance according to UL94"
  },
  {
    featureId: "EF001744",
    featureDesc: "Bending radius, static",
    label: "EF001744: Bending radius, static"
  },
  {
    featureId: "EF001745",
    featureDesc: "Sheathing material",
    label: "EF001745: Sheathing material"
  },
  {
    featureId: "EF001746",
    featureDesc: "Flame accelerating",
    label: "EF001746: Flame accelerating"
  },
  {
    featureId: "EF001757",
    featureDesc: "Revolving thread",
    label: "EF001757: Revolving thread"
  },
  {
    featureId: "EF001758",
    featureDesc: "Thread type",
    label: "EF001758: Thread type"
  },
  {
    featureId: "EF001760",
    featureDesc: "Lowest inner diameter hose",
    label: "EF001760: Lowest inner diameter hose"
  },
  {
    featureId: "EF001761",
    featureDesc: "Total height",
    label: "EF001761: Total height"
  },
  {
    featureId: "EF001763",
    featureDesc: "Approved in combination with protective conduit UR",
    label: "EF001763: Approved in combination with protective conduit UR"
  },
  {
    featureId: "EF001765",
    featureDesc: "Approved in combination with protective conduit CSA",
    label: "EF001765: Approved in combination with protective conduit CSA"
  },
  {
    featureId: "EF001766",
    featureDesc: "Approved in combination with protective conduit Lloyd's Register",
    label: "EF001766: Approved in combination with protective conduit Lloyd's Register"
  },
  {
    featureId: "EF001768",
    featureDesc: "Material filament",
    label: "EF001768: Material filament"
  },
  {
    featureId: "EF001769",
    featureDesc: "Plain interior",
    label: "EF001769: Plain interior"
  },
  {
    featureId: "EF001770",
    featureDesc: "Flexibility",
    label: "EF001770: Flexibility"
  },
  {
    featureId: "EF001772",
    featureDesc: "Approved according to UR",
    label: "EF001772: Approved according to UR"
  },
  {
    featureId: "EF001773",
    featureDesc: "Approved according to N",
    label: "EF001773: Approved according to N"
  },
  {
    featureId: "EF001774",
    featureDesc: "One-piece",
    label: "EF001774: One-piece"
  },
  {
    featureId: "EF001776",
    featureDesc: "Sealing",
    label: "EF001776: Sealing"
  },
  {
    featureId: "EF001777",
    featureDesc: "Suspended load capacity",
    label: "EF001777: Suspended load capacity"
  },
  {
    featureId: "EF001778",
    featureDesc: "Electrical characteristics",
    label: "EF001778: Electrical characteristics"
  },
  {
    featureId: "EF001779",
    featureDesc: "Suitable for EX-applications",
    label: "EF001779: Suitable for EX-applications"
  },
  {
    featureId: "EF001782",
    featureDesc: "Approved according to FI",
    label: "EF001782: Approved according to FI"
  },
  {
    featureId: "EF001783",
    featureDesc: "With approval according to UL",
    label: "EF001783: With approval according to UL"
  },
  {
    featureId: "EF001784",
    featureDesc: "Approved according to CSA",
    label: "EF001784: Approved according to CSA"
  },
  {
    featureId: "EF001786",
    featureDesc: "Degree of protection (IP) conduit dependant",
    label: "EF001786: Degree of protection (IP) conduit dependant"
  },
  {
    featureId: "EF001787",
    featureDesc: "Approved in combination with protective conduit UL",
    label: "EF001787: Approved in combination with protective conduit UL"
  },
  {
    featureId: "EF001788",
    featureDesc: "Approved in combination with protective conduit FI",
    label: "EF001788: Approved in combination with protective conduit FI"
  },
  {
    featureId: "EF001790",
    featureDesc: "Nominal voltage U0",
    label: "EF001790: Nominal voltage U0"
  },
  {
    featureId: "EF001791",
    featureDesc: "Screen",
    label: "EF001791: Screen"
  },
  {
    featureId: "EF001792",
    featureDesc: "Number of cores",
    label: "EF001792: Number of cores"
  },
  {
    featureId: "EF001794",
    featureDesc: "Suspension element",
    label: "EF001794: Suspension element"
  },
  {
    featureId: "EF001795",
    featureDesc: "Longitudinal water blocking conductors",
    label: "EF001795: Longitudinal water blocking conductors"
  },
  {
    featureId: "EF001796",
    featureDesc: "Blade length",
    label: "EF001796: Blade length"
  },
  {
    featureId: "EF001797",
    featureDesc: "Number of air bubbles",
    label: "EF001797: Number of air bubbles"
  },
  {
    featureId: "EF001798",
    featureDesc: "Cutting edge width",
    label: "EF001798: Cutting edge width"
  },
  {
    featureId: "EF001799",
    featureDesc: "Cutting edge shape",
    label: "EF001799: Cutting edge shape"
  },
  {
    featureId: "EF001800",
    featureDesc: "Nominal cross section concentric conductor",
    label: "EF001800: Nominal cross section concentric conductor"
  },
  {
    featureId: "EF001803",
    featureDesc: "Line length",
    label: "EF001803: Line length"
  },
  {
    featureId: "EF001804",
    featureDesc: "Spatula width",
    label: "EF001804: Spatula width"
  },
  {
    featureId: "EF001805",
    featureDesc: "Longitudinal section",
    label: "EF001805: Longitudinal section"
  },
  {
    featureId: "EF001806",
    featureDesc: "Round edge",
    label: "EF001806: Round edge"
  },
  {
    featureId: "EF001808",
    featureDesc: "Depth dimension",
    label: "EF001808: Depth dimension"
  },
  {
    featureId: "EF001809",
    featureDesc: "Accuracy",
    label: "EF001809: Accuracy"
  },
  {
    featureId: "EF001810",
    featureDesc: "Blade width",
    label: "EF001810: Blade width"
  },
  {
    featureId: "EF001811",
    featureDesc: "Blade thickness",
    label: "EF001811: Blade thickness"
  },
  {
    featureId: "EF001812",
    featureDesc: "Material stick",
    label: "EF001812: Material stick"
  },
  {
    featureId: "EF001813",
    featureDesc: "Joint",
    label: "EF001813: Joint"
  },
  {
    featureId: "EF001816",
    featureDesc: "Compression shape",
    label: "EF001816: Compression shape"
  },
  {
    featureId: "EF001817",
    featureDesc: "With locking device (for mechanical tools)",
    label: "EF001817: With locking device (for mechanical tools)"
  },
  {
    featureId: "EF001819",
    featureDesc: "Material to process",
    label: "EF001819: Material to process"
  },
  {
    featureId: "EF001821",
    featureDesc: "Cutting depth",
    label: "EF001821: Cutting depth"
  },
  {
    featureId: "EF001822",
    featureDesc: "Saw-blade length",
    label: "EF001822: Saw-blade length"
  },
  {
    featureId: "EF001823",
    featureDesc: "Waterproof",
    label: "EF001823: Waterproof"
  },
  {
    featureId: "EF001825",
    featureDesc: "Max. diameter cable drum",
    label: "EF001825: Max. diameter cable drum"
  },
  {
    featureId: "EF001826",
    featureDesc: "Max. width cable drum",
    label: "EF001826: Max. width cable drum"
  },
  {
    featureId: "EF001827",
    featureDesc: "Fixator",
    label: "EF001827: Fixator"
  },
  {
    featureId: "EF001831",
    featureDesc: "Power measuring",
    label: "EF001831: Power measuring"
  },
  {
    featureId: "EF001832",
    featureDesc: "Leakage current measuring",
    label: "EF001832: Leakage current measuring"
  },
  {
    featureId: "EF001833",
    featureDesc: "Measurement in cables with for- and back conductor",
    label: "EF001833: Measurement in cables with for- and back conductor"
  },
  {
    featureId: "EF001834",
    featureDesc: "Version as adapter to connect to multi meter",
    label: "EF001834: Version as adapter to connect to multi meter"
  },
  {
    featureId: "EF001835",
    featureDesc: "Harmonics measurement",
    label: "EF001835: Harmonics measurement"
  },
  {
    featureId: "EF001836",
    featureDesc: "Measuring range",
    label: "EF001836: Measuring range"
  },
  {
    featureId: "EF001837",
    featureDesc: "Lowest resolution",
    label: "EF001837: Lowest resolution"
  },
  {
    featureId: "EF001839",
    featureDesc: "Centre drill diameter",
    label: "EF001839: Centre drill diameter"
  },
  {
    featureId: "EF001840",
    featureDesc: "Hole edge core drill",
    label: "EF001840: Hole edge core drill"
  },
  {
    featureId: "EF001841",
    featureDesc: "Holding system",
    label: "EF001841: Holding system"
  },
  {
    featureId: "EF001842",
    featureDesc: "Material cable tie",
    label: "EF001842: Material cable tie"
  },
  {
    featureId: "EF001845",
    featureDesc: "Voltage indication",
    label: "EF001845: Voltage indication"
  },
  {
    featureId: "EF001846",
    featureDesc: "Internal base load",
    label: "EF001846: Internal base load"
  },
  {
    featureId: "EF001847",
    featureDesc: "Single pole phase indication",
    label: "EF001847: Single pole phase indication"
  },
  {
    featureId: "EF001848",
    featureDesc: "Contact less voltage detection",
    label: "EF001848: Contact less voltage detection"
  },
  {
    featureId: "EF001849",
    featureDesc: "Data hold",
    label: "EF001849: Data hold"
  },
  {
    featureId: "EF001850",
    featureDesc: "Background lighting LC-indication",
    label: "EF001850: Background lighting LC-indication"
  },
  {
    featureId: "EF001851",
    featureDesc: "Test prod protection",
    label: "EF001851: Test prod protection"
  },
  {
    featureId: "EF001852",
    featureDesc: "Measuring point lightning",
    label: "EF001852: Measuring point lightning"
  },
  {
    featureId: "EF001854",
    featureDesc: "Max. resistance test value",
    label: "EF001854: Max. resistance test value"
  },
  {
    featureId: "EF001855",
    featureDesc: "Interference fixed voltage >50 V AC/DC",
    label: "EF001855: Interference fixed voltage >50 V AC/DC"
  },
  {
    featureId: "EF001856",
    featureDesc: "Max. search depth",
    label: "EF001856: Max. search depth"
  },
  {
    featureId: "EF001858",
    featureDesc: "Cable sorter function",
    label: "EF001858: Cable sorter function"
  },
  {
    featureId: "EF001859",
    featureDesc: "Suitable for searching wood",
    label: "EF001859: Suitable for searching wood"
  },
  {
    featureId: "EF001860",
    featureDesc: "Suitable for searching metal",
    label: "EF001860: Suitable for searching metal"
  },
  {
    featureId: "EF001861",
    featureDesc: "Suitable for searching plastic",
    label: "EF001861: Suitable for searching plastic"
  },
  {
    featureId: "EF001863",
    featureDesc: "Isolation resistance measurement",
    label: "EF001863: Isolation resistance measurement"
  },
  {
    featureId: "EF001864",
    featureDesc: "Over voltage protector testing",
    label: "EF001864: Over voltage protector testing"
  },
  {
    featureId: "EF001865",
    featureDesc: "Loop resistance/impedance measurement",
    label: "EF001865: Loop resistance/impedance measurement"
  },
  {
    featureId: "EF001866",
    featureDesc: "Mains internal resistance measurement",
    label: "EF001866: Mains internal resistance measurement"
  },
  {
    featureId: "EF001867",
    featureDesc: "RCD/FI testing",
    label: "EF001867: RCD/FI testing"
  },
  {
    featureId: "EF001868",
    featureDesc: "Curve shape at RCD/FI testing",
    label: "EF001868: Curve shape at RCD/FI testing"
  },
  {
    featureId: "EF001869",
    featureDesc: "Field rotation test",
    label: "EF001869: Field rotation test"
  },
  {
    featureId: "EF001870",
    featureDesc: "Selective grounding measurement",
    label: "EF001870: Selective grounding measurement"
  },
  {
    featureId: "EF001871",
    featureDesc: "Earth loop measurement",
    label: "EF001871: Earth loop measurement"
  },
  {
    featureId: "EF001872",
    featureDesc: "Specific grounding measurement",
    label: "EF001872: Specific grounding measurement"
  },
  {
    featureId: "EF001873",
    featureDesc: "Printer connection",
    label: "EF001873: Printer connection"
  },
  {
    featureId: "EF001874",
    featureDesc: "Commander for remote control of testing device",
    label: "EF001874: Commander for remote control of testing device"
  },
  {
    featureId: "EF001875",
    featureDesc: "Equivalent leakage current",
    label: "EF001875: Equivalent leakage current"
  },
  {
    featureId: "EF001876",
    featureDesc: "Differential current",
    label: "EF001876: Differential current"
  },
  {
    featureId: "EF001877",
    featureDesc: "Consumption current measurement",
    label: "EF001877: Consumption current measurement"
  },
  {
    featureId: "EF001878",
    featureDesc: "Suitable for instructed personnel",
    label: "EF001878: Suitable for instructed personnel"
  },
  {
    featureId: "EF001879",
    featureDesc: "Limit value indication",
    label: "EF001879: Limit value indication"
  },
  {
    featureId: "EF001880",
    featureDesc: "Automatic test sequence",
    label: "EF001880: Automatic test sequence"
  },
  {
    featureId: "EF001882",
    featureDesc: "Connection for external measuring adapter",
    label: "EF001882: Connection for external measuring adapter"
  },
  {
    featureId: "EF001883",
    featureDesc: "Testing of extension leads",
    label: "EF001883: Testing of extension leads"
  },
  {
    featureId: "EF001888",
    featureDesc: "Retractable antenna",
    label: "EF001888: Retractable antenna"
  },
  {
    featureId: "EF001890",
    featureDesc: "Measurement of differential temperature",
    label: "EF001890: Measurement of differential temperature"
  },
  {
    featureId: "EF001891",
    featureDesc: "Measurement of relative air humidity",
    label: "EF001891: Measurement of relative air humidity"
  },
  {
    featureId: "EF001892",
    featureDesc: "Measurement of material humidity",
    label: "EF001892: Measurement of material humidity"
  },
  {
    featureId: "EF001893",
    featureDesc: "Number of rungs",
    label: "EF001893: Number of rungs"
  },
  {
    featureId: "EF001894",
    featureDesc: "Max. cross section overhead line",
    label: "EF001894: Max. cross section overhead line"
  },
  {
    featureId: "EF001895",
    featureDesc: "Protruding cutting edge",
    label: "EF001895: Protruding cutting edge"
  },
  {
    featureId: "EF001897",
    featureDesc: "Cutting edge length",
    label: "EF001897: Cutting edge length"
  },
  {
    featureId: "EF001898",
    featureDesc: "Suitable for operation with impact",
    label: "EF001898: Suitable for operation with impact"
  },
  {
    featureId: "EF001901",
    featureDesc: "Max. cutting diameter",
    label: "EF001901: Max. cutting diameter"
  },
  {
    featureId: "EF001902",
    featureDesc: "Indication three phases",
    label: "EF001902: Indication three phases"
  },
  {
    featureId: "EF001903",
    featureDesc: "Motor rotation direction tester",
    label: "EF001903: Motor rotation direction tester"
  },
  {
    featureId: "EF001904",
    featureDesc: "Contact less detection of the sense of rotation",
    label: "EF001904: Contact less detection of the sense of rotation"
  },
  {
    featureId: "EF001905",
    featureDesc: "Measurement electrical field",
    label: "EF001905: Measurement electrical field"
  },
  {
    featureId: "EF001906",
    featureDesc: "Measurement electromagnetic fields",
    label: "EF001906: Measurement electromagnetic fields"
  },
  {
    featureId: "EF001907",
    featureDesc: "Measurement air speed",
    label: "EF001907: Measurement air speed"
  },
  {
    featureId: "EF001908",
    featureDesc: "Volume flow measurement",
    label: "EF001908: Volume flow measurement"
  },
  {
    featureId: "EF001909",
    featureDesc: "Measurement of sonic",
    label: "EF001909: Measurement of sonic"
  },
  {
    featureId: "EF001910",
    featureDesc: "Contact less rotational speed measuring (optic)",
    label: "EF001910: Contact less rotational speed measuring (optic)"
  },
  {
    featureId: "EF001911",
    featureDesc: "Mechanic rotational speed measuring",
    label: "EF001911: Mechanic rotational speed measuring"
  },
  {
    featureId: "EF001912",
    featureDesc: "Measurement surface speed",
    label: "EF001912: Measurement surface speed"
  },
  {
    featureId: "EF001913",
    featureDesc: "Earth bond voltage drop measuring range",
    label: "EF001913: Earth bond voltage drop measuring range"
  },
  {
    featureId: "EF001914",
    featureDesc: "Loop impedance measurement",
    label: "EF001914: Loop impedance measurement"
  },
  {
    featureId: "EF001916",
    featureDesc: "Rest voltage measurement",
    label: "EF001916: Rest voltage measurement"
  },
  {
    featureId: "EF001918",
    featureDesc: "Protective conductor resistance measurement",
    label: "EF001918: Protective conductor resistance measurement"
  },
  {
    featureId: "EF001919",
    featureDesc: "Protective conductor current",
    label: "EF001919: Protective conductor current"
  },
  {
    featureId: "EF001920",
    featureDesc: "Contact current",
    label: "EF001920: Contact current"
  },
  {
    featureId: "EF001921",
    featureDesc: "Stationary measuring installation",
    label: "EF001921: Stationary measuring installation"
  },
  {
    featureId: "EF001922",
    featureDesc: "Alarm output",
    label: "EF001922: Alarm output"
  },
  {
    featureId: "EF001923",
    featureDesc: "External current transformer",
    label: "EF001923: External current transformer"
  },
  {
    featureId: "EF001924",
    featureDesc: "Number of voltage input channels",
    label: "EF001924: Number of voltage input channels"
  },
  {
    featureId: "EF001925",
    featureDesc: "Number of current input channels",
    label: "EF001925: Number of current input channels"
  },
  {
    featureId: "EF001926",
    featureDesc: "Current measurement",
    label: "EF001926: Current measurement"
  },
  {
    featureId: "EF001927",
    featureDesc: "Power factor",
    label: "EF001927: Power factor"
  },
  {
    featureId: "EF001928",
    featureDesc: "Effective power",
    label: "EF001928: Effective power"
  },
  {
    featureId: "EF001929",
    featureDesc: "Apparent power",
    label: "EF001929: Apparent power"
  },
  {
    featureId: "EF001930",
    featureDesc: "Step angle (cos phi)",
    label: "EF001930: Step angle (cos phi)"
  },
  {
    featureId: "EF001931",
    featureDesc: "Power/work",
    label: "EF001931: Power/work"
  },
  {
    featureId: "EF001934",
    featureDesc: "Plug in technique",
    label: "EF001934: Plug in technique"
  },
  {
    featureId: "EF001942",
    featureDesc: "Current carrying capacity",
    label: "EF001942: Current carrying capacity"
  },
  {
    featureId: "EF001943",
    featureDesc: "Equivalent device leakage current",
    label: "EF001943: Equivalent device leakage current"
  },
  {
    featureId: "EF001944",
    featureDesc: "Patient leakage current",
    label: "EF001944: Patient leakage current"
  },
  {
    featureId: "EF001950",
    featureDesc: "Nominal power consumption",
    label: "EF001950: Nominal power consumption"
  },
  {
    featureId: "EF001951",
    featureDesc: "Machine weight",
    label: "EF001951: Machine weight"
  },
  {
    featureId: "EF001955",
    featureDesc: "Control extra function(s)",
    label: "EF001955: Control extra function(s)"
  },
  {
    featureId: "EF001956",
    featureDesc: "Function lamps",
    label: "EF001956: Function lamps"
  },
  {
    featureId: "EF001958",
    featureDesc: "With automatic door opener",
    label: "EF001958: With automatic door opener"
  },
  {
    featureId: "EF001960",
    featureDesc: "Max. tap/splitter loss",
    label: "EF001960: Max. tap/splitter loss"
  },
  {
    featureId: "EF001961",
    featureDesc: "Diameter mast pipe",
    label: "EF001961: Diameter mast pipe"
  },
  {
    featureId: "EF001963",
    featureDesc: "Max. number of cores",
    label: "EF001963: Max. number of cores"
  },
  {
    featureId: "EF001964",
    featureDesc: "Max. operation pressure",
    label: "EF001964: Max. operation pressure"
  },
  {
    featureId: "EF001965",
    featureDesc: "Number of indicator lights",
    label: "EF001965: Number of indicator lights"
  },
  {
    featureId: "EF001968",
    featureDesc: "With axis extension",
    label: "EF001968: With axis extension"
  },
  {
    featureId: "EF001969",
    featureDesc: "With door interlock",
    label: "EF001969: With door interlock"
  },
  {
    featureId: "EF001970",
    featureDesc: "With handle red/yellow",
    label: "EF001970: With handle red/yellow"
  },
  {
    featureId: "EF001980",
    featureDesc: "Outer diameter cable ring",
    label: "EF001980: Outer diameter cable ring"
  },
  {
    featureId: "EF001981",
    featureDesc: "Inner diameter cable ring",
    label: "EF001981: Inner diameter cable ring"
  },
  {
    featureId: "EF001985",
    featureDesc: "Battery capacity",
    label: "EF001985: Battery capacity"
  },
  {
    featureId: "EF001989",
    featureDesc: "Disc diameter",
    label: "EF001989: Disc diameter"
  },
  {
    featureId: "EF001991",
    featureDesc: "Sensitivity",
    label: "EF001991: Sensitivity"
  },
  {
    featureId: "EF001992",
    featureDesc: "Number of digital inputs",
    label: "EF001992: Number of digital inputs"
  },
  {
    featureId: "EF001993",
    featureDesc: "Number of digital outputs",
    label: "EF001993: Number of digital outputs"
  },
  {
    featureId: "EF001994",
    featureDesc: "Number of analogue inputs",
    label: "EF001994: Number of analogue inputs"
  },
  {
    featureId: "EF001995",
    featureDesc: "Number of analogue outputs",
    label: "EF001995: Number of analogue outputs"
  },
  {
    featureId: "EF001996",
    featureDesc: "Navigation",
    label: "EF001996: Navigation"
  },
  {
    featureId: "EF002000",
    featureDesc: "With hard disc",
    label: "EF002000: With hard disc"
  },
  {
    featureId: "EF002001",
    featureDesc: "MP3",
    label: "EF002001: MP3"
  },
  {
    featureId: "EF002002",
    featureDesc: "With CD-RW drive",
    label: "EF002002: With CD-RW drive"
  },
  {
    featureId: "EF002003",
    featureDesc: "Radio-controlled clock",
    label: "EF002003: Radio-controlled clock"
  },
  {
    featureId: "EF002009",
    featureDesc: "Hotel mode",
    label: "EF002009: Hotel mode"
  },
  {
    featureId: "EF002010",
    featureDesc: "Number of drivers",
    label: "EF002010: Number of drivers"
  },
  {
    featureId: "EF002012",
    featureDesc: "Active system",
    label: "EF002012: Active system"
  },
  {
    featureId: "EF002013",
    featureDesc: "Mains supply",
    label: "EF002013: Mains supply"
  },
  {
    featureId: "EF002014",
    featureDesc: "Earplug connection",
    label: "EF002014: Earplug connection"
  },
  {
    featureId: "EF002015",
    featureDesc: "Recording capacity",
    label: "EF002015: Recording capacity"
  },
  {
    featureId: "EF002018",
    featureDesc: "Tone quality",
    label: "EF002018: Tone quality"
  },
  {
    featureId: "EF002019",
    featureDesc: "Digital Surround Decoder",
    label: "EF002019: Digital Surround Decoder"
  },
  {
    featureId: "EF002020",
    featureDesc: "Max. number of controllable devices",
    label: "EF002020: Max. number of controllable devices"
  },
  {
    featureId: "EF002026",
    featureDesc: "USB connection",
    label: "EF002026: USB connection"
  },
  {
    featureId: "EF002027",
    featureDesc: "Resolution",
    label: "EF002027: Resolution"
  },
  {
    featureId: "EF002028",
    featureDesc: "Voice guidance",
    label: "EF002028: Voice guidance"
  },
  {
    featureId: "EF002030",
    featureDesc: "Card reader",
    label: "EF002030: Card reader"
  },
  {
    featureId: "EF002031",
    featureDesc: "Fast memory capacity/ROM",
    label: "EF002031: Fast memory capacity/ROM"
  },
  {
    featureId: "EF002032",
    featureDesc: "Screen diagonal",
    label: "EF002032: Screen diagonal"
  },
  {
    featureId: "EF002038",
    featureDesc: "Auto focus",
    label: "EF002038: Auto focus"
  },
  {
    featureId: "EF002039",
    featureDesc: "USB interface",
    label: "EF002039: USB interface"
  },
  {
    featureId: "EF002040",
    featureDesc: "Software",
    label: "EF002040: Software"
  },
  {
    featureId: "EF002042",
    featureDesc: "Screen size",
    label: "EF002042: Screen size"
  },
  {
    featureId: "EF002045",
    featureDesc: "Memory capacity",
    label: "EF002045: Memory capacity"
  },
  {
    featureId: "EF002048",
    featureDesc: "Power rating",
    label: "EF002048: Power rating"
  },
  {
    featureId: "EF002050",
    featureDesc: "Water protection system",
    label: "EF002050: Water protection system"
  },
  {
    featureId: "EF002053",
    featureDesc: "Time remaining indicator",
    label: "EF002053: Time remaining indicator"
  },
  {
    featureId: "EF002057",
    featureDesc: "Height with worktop",
    label: "EF002057: Height with worktop"
  },
  {
    featureId: "EF002058",
    featureDesc: "Dryer control",
    label: "EF002058: Dryer control"
  },
  {
    featureId: "EF002059",
    featureDesc: "Type of microwave oven",
    label: "EF002059: Type of microwave oven"
  },
  {
    featureId: "EF002061",
    featureDesc: "Cavity capacity",
    label: "EF002061: Cavity capacity"
  },
  {
    featureId: "EF002065",
    featureDesc: "Decor-enabled",
    label: "EF002065: Decor-enabled"
  },
  {
    featureId: "EF002067",
    featureDesc: "Star rating",
    label: "EF002067: Star rating"
  },
  {
    featureId: "EF002072",
    featureDesc: "Hinge position",
    label: "EF002072: Hinge position"
  },
  {
    featureId: "EF002073",
    featureDesc: "Reversible door hinge",
    label: "EF002073: Reversible door hinge"
  },
  {
    featureId: "EF002074",
    featureDesc: "Cover door installation system",
    label: "EF002074: Cover door installation system"
  },
  {
    featureId: "EF002075",
    featureDesc: "Frost-free system",
    label: "EF002075: Frost-free system"
  },
  {
    featureId: "EF002076",
    featureDesc: "Thermometer freezer compartment",
    label: "EF002076: Thermometer freezer compartment"
  },
  {
    featureId: "EF002077",
    featureDesc: "Thermometer fridge compartment",
    label: "EF002077: Thermometer fridge compartment"
  },
  {
    featureId: "EF002080",
    featureDesc: "Type of baking oven",
    label: "EF002080: Type of baking oven"
  },
  {
    featureId: "EF002082",
    featureDesc: "Slicing knife",
    label: "EF002082: Slicing knife"
  },
  {
    featureId: "EF002083",
    featureDesc: "Current surge",
    label: "EF002083: Current surge"
  },
  {
    featureId: "EF002085",
    featureDesc: "Suction function",
    label: "EF002085: Suction function"
  },
  {
    featureId: "EF002086",
    featureDesc: "Controllable steam flow",
    label: "EF002086: Controllable steam flow"
  },
  {
    featureId: "EF002087",
    featureDesc: "With drain valve",
    label: "EF002087: With drain valve"
  },
  {
    featureId: "EF002088",
    featureDesc: "Permanent connection to water supply",
    label: "EF002088: Permanent connection to water supply"
  },
  {
    featureId: "EF002089",
    featureDesc: "Ice crush function",
    label: "EF002089: Ice crush function"
  },
  {
    featureId: "EF002090",
    featureDesc: "Quick steam",
    label: "EF002090: Quick steam"
  },
  {
    featureId: "EF002091",
    featureDesc: "Milk foaming",
    label: "EF002091: Milk foaming"
  },
  {
    featureId: "EF002092",
    featureDesc: "Coffee powder tunnel",
    label: "EF002092: Coffee powder tunnel"
  },
  {
    featureId: "EF002093",
    featureDesc: "Automatic cleaning",
    label: "EF002093: Automatic cleaning"
  },
  {
    featureId: "EF002094",
    featureDesc: "Hot water function",
    label: "EF002094: Hot water function"
  },
  {
    featureId: "EF002095",
    featureDesc: "Programmable content cups",
    label: "EF002095: Programmable content cups"
  },
  {
    featureId: "EF002096",
    featureDesc: "Adjustable grinder",
    label: "EF002096: Adjustable grinder"
  },
  {
    featureId: "EF002097",
    featureDesc: "Adjustable coffee level",
    label: "EF002097: Adjustable coffee level"
  },
  {
    featureId: "EF002098",
    featureDesc: "Cup system",
    label: "EF002098: Cup system"
  },
  {
    featureId: "EF002099",
    featureDesc: "Oil drain possibility",
    label: "EF002099: Oil drain possibility"
  },
  {
    featureId: "EF002100",
    featureDesc: "With sensor",
    label: "EF002100: With sensor"
  },
  {
    featureId: "EF002101",
    featureDesc: "Warming tray",
    label: "EF002101: Warming tray"
  },
  {
    featureId: "EF002102",
    featureDesc: "Drip-stop",
    label: "EF002102: Drip-stop"
  },
  {
    featureId: "EF002103",
    featureDesc: "Integrated grinder",
    label: "EF002103: Integrated grinder"
  },
  {
    featureId: "EF002104",
    featureDesc: "Aroma selector",
    label: "EF002104: Aroma selector"
  },
  {
    featureId: "EF002107",
    featureDesc: "Vacuum power control",
    label: "EF002107: Vacuum power control"
  },
  {
    featureId: "EF002108",
    featureDesc: "Energy supply",
    label: "EF002108: Energy supply"
  },
  {
    featureId: "EF002109",
    featureDesc: "Fat mass analysis",
    label: "EF002109: Fat mass analysis"
  },
  {
    featureId: "EF002111",
    featureDesc: "Coating",
    label: "EF002111: Coating"
  },
  {
    featureId: "EF002112",
    featureDesc: "Number of trays",
    label: "EF002112: Number of trays"
  },
  {
    featureId: "EF002113",
    featureDesc: "Crepe plate",
    label: "EF002113: Crepe plate"
  },
  {
    featureId: "EF002114",
    featureDesc: "Cleaning system",
    label: "EF002114: Cleaning system"
  },
  {
    featureId: "EF002116",
    featureDesc: "Container",
    label: "EF002116: Container"
  },
  {
    featureId: "EF002117",
    featureDesc: "Telescopic tube",
    label: "EF002117: Telescopic tube"
  },
  {
    featureId: "EF002118",
    featureDesc: "Vacuum brush",
    label: "EF002118: Vacuum brush"
  },
  {
    featureId: "EF002119",
    featureDesc: "Indication, full",
    label: "EF002119: Indication, full"
  },
  {
    featureId: "EF002121",
    featureDesc: "Defrost level",
    label: "EF002121: Defrost level"
  },
  {
    featureId: "EF002124",
    featureDesc: "Oscillation",
    label: "EF002124: Oscillation"
  },
  {
    featureId: "EF002126",
    featureDesc: "Payment meter",
    label: "EF002126: Payment meter"
  },
  {
    featureId: "EF002127",
    featureDesc: "Cordless",
    label: "EF002127: Cordless"
  },
  {
    featureId: "EF002129",
    featureDesc: "Cook stop function",
    label: "EF002129: Cook stop function"
  },
  {
    featureId: "EF002130",
    featureDesc: "Heating element",
    label: "EF002130: Heating element"
  },
  {
    featureId: "EF002131",
    featureDesc: "Case",
    label: "EF002131: Case"
  },
  {
    featureId: "EF002134",
    featureDesc: "Cartridge volume",
    label: "EF002134: Cartridge volume"
  },
  {
    featureId: "EF002136",
    featureDesc: "Nominal connection power",
    label: "EF002136: Nominal connection power"
  },
  {
    featureId: "EF002137",
    featureDesc: "With premounted heating element",
    label: "EF002137: With premounted heating element"
  },
  {
    featureId: "EF002138",
    featureDesc: "With electronic heating element",
    label: "EF002138: With electronic heating element"
  },
  {
    featureId: "EF002139",
    featureDesc: "AC control system selectable",
    label: "EF002139: AC control system selectable"
  },
  {
    featureId: "EF002140",
    featureDesc: "Interrupt indication",
    label: "EF002140: Interrupt indication"
  },
  {
    featureId: "EF002141",
    featureDesc: "With thermal relays",
    label: "EF002141: With thermal relays"
  },
  {
    featureId: "EF002142",
    featureDesc: "Lint screen in air intake section",
    label: "EF002142: Lint screen in air intake section"
  },
  {
    featureId: "EF002143",
    featureDesc: "Nominal content",
    label: "EF002143: Nominal content"
  },
  {
    featureId: "EF002145",
    featureDesc: "Circulation connection",
    label: "EF002145: Circulation connection"
  },
  {
    featureId: "EF002147",
    featureDesc: "Operation",
    label: "EF002147: Operation"
  },
  {
    featureId: "EF002149",
    featureDesc: "With temperature limit",
    label: "EF002149: With temperature limit"
  },
  {
    featureId: "EF002153",
    featureDesc: "Material boiler tank",
    label: "EF002153: Material boiler tank"
  },
  {
    featureId: "EF002154",
    featureDesc: "Type of heating element",
    label: "EF002154: Type of heating element"
  },
  {
    featureId: "EF002155",
    featureDesc: "Material pressure tank",
    label: "EF002155: Material pressure tank"
  },
  {
    featureId: "EF002158",
    featureDesc: "Suitable for preheated water",
    label: "EF002158: Suitable for preheated water"
  },
  {
    featureId: "EF002159",
    featureDesc: "Tap surface",
    label: "EF002159: Tap surface"
  },
  {
    featureId: "EF002160",
    featureDesc: "Temperature selection",
    label: "EF002160: Temperature selection"
  },
  {
    featureId: "EF002164",
    featureDesc: "Cold water connection",
    label: "EF002164: Cold water connection"
  },
  {
    featureId: "EF002165",
    featureDesc: "Hot water connection",
    label: "EF002165: Hot water connection"
  },
  {
    featureId: "EF002167",
    featureDesc: "With thermometer",
    label: "EF002167: With thermometer"
  },
  {
    featureId: "EF002169",
    featureDesc: "Material",
    label: "EF002169: Material"
  },
  {
    featureId: "EF002173",
    featureDesc: "Operating pressure (water sided)",
    label: "EF002173: Operating pressure (water sided)"
  },
  {
    featureId: "EF002176",
    featureDesc: "Solar integration",
    label: "EF002176: Solar integration"
  },
  {
    featureId: "EF002177",
    featureDesc: "Max. water temperature",
    label: "EF002177: Max. water temperature"
  },
  {
    featureId: "EF002179",
    featureDesc: "With air duct connection",
    label: "EF002179: With air duct connection"
  },
  {
    featureId: "EF002183",
    featureDesc: "Primary volume flow",
    label: "EF002183: Primary volume flow"
  },
  {
    featureId: "EF002184",
    featureDesc: "Secondary volume flow",
    label: "EF002184: Secondary volume flow"
  },
  {
    featureId: "EF002189",
    featureDesc: "Control power",
    label: "EF002189: Control power"
  },
  {
    featureId: "EF002190",
    featureDesc: "Low recharge adjustable",
    label: "EF002190: Low recharge adjustable"
  },
  {
    featureId: "EF002191",
    featureDesc: "Weather dependent control",
    label: "EF002191: Weather dependent control"
  },
  {
    featureId: "EF002192",
    featureDesc: "ED-system",
    label: "EF002192: ED-system"
  },
  {
    featureId: "EF002193",
    featureDesc: "Summer logic",
    label: "EF002193: Summer logic"
  },
  {
    featureId: "EF002194",
    featureDesc: "Temperature adjustment",
    label: "EF002194: Temperature adjustment"
  },
  {
    featureId: "EF002196",
    featureDesc: "With fan",
    label: "EF002196: With fan"
  },
  {
    featureId: "EF002197",
    featureDesc: "Heating conductor load",
    label: "EF002197: Heating conductor load"
  },
  {
    featureId: "EF002198",
    featureDesc: "With support mat",
    label: "EF002198: With support mat"
  },
  {
    featureId: "EF002199",
    featureDesc: "Fixation thermal conductor",
    label: "EF002199: Fixation thermal conductor"
  },
  {
    featureId: "EF002200",
    featureDesc: "Number of cold conductors",
    label: "EF002200: Number of cold conductors"
  },
  {
    featureId: "EF002201",
    featureDesc: "Length of cold conductors",
    label: "EF002201: Length of cold conductors"
  },
  {
    featureId: "EF002205",
    featureDesc: "Heat recovery by",
    label: "EF002205: Heat recovery by"
  },
  {
    featureId: "EF002206",
    featureDesc: "Heat source",
    label: "EF002206: Heat source"
  },
  {
    featureId: "EF002209",
    featureDesc: "With water heating",
    label: "EF002209: With water heating"
  },
  {
    featureId: "EF002217",
    featureDesc: "Mounting over cradle",
    label: "EF002217: Mounting over cradle"
  },
  {
    featureId: "EF002218",
    featureDesc: "Covered wall mount",
    label: "EF002218: Covered wall mount"
  },
  {
    featureId: "EF002219",
    featureDesc: "With insulating panel",
    label: "EF002219: With insulating panel"
  },
  {
    featureId: "EF002220",
    featureDesc: "Connection to",
    label: "EF002220: Connection to"
  },
  {
    featureId: "EF002221",
    featureDesc: "With circulation pump",
    label: "EF002221: With circulation pump"
  },
  {
    featureId: "EF002222",
    featureDesc: "With safety valve",
    label: "EF002222: With safety valve"
  },
  {
    featureId: "EF002223",
    featureDesc: "With pressure gauge",
    label: "EF002223: With pressure gauge"
  },
  {
    featureId: "EF002225",
    featureDesc: "With filling- and draining valve",
    label: "EF002225: With filling- and draining valve"
  },
  {
    featureId: "EF002226",
    featureDesc: "Max. flow rate",
    label: "EF002226: Max. flow rate"
  },
  {
    featureId: "EF002227",
    featureDesc: "Type of collector",
    label: "EF002227: Type of collector"
  },
  {
    featureId: "EF002228",
    featureDesc: "Total surface area",
    label: "EF002228: Total surface area"
  },
  {
    featureId: "EF002233",
    featureDesc: "Nominal flow rate",
    label: "EF002233: Nominal flow rate"
  },
  {
    featureId: "EF002237",
    featureDesc: "With marble cover plate",
    label: "EF002237: With marble cover plate"
  },
  {
    featureId: "EF002238",
    featureDesc: "Connected load heating element",
    label: "EF002238: Connected load heating element"
  },
  {
    featureId: "EF002240",
    featureDesc: "Air through-put",
    label: "EF002240: Air through-put"
  },
  {
    featureId: "EF002242",
    featureDesc: "With fragrance dispenser",
    label: "EF002242: With fragrance dispenser"
  },
  {
    featureId: "EF002243",
    featureDesc: "Operating pressure",
    label: "EF002243: Operating pressure"
  },
  {
    featureId: "EF002244",
    featureDesc: "Material heating element",
    label: "EF002244: Material heating element"
  },
  {
    featureId: "EF002245",
    featureDesc: "Safety temperature limit",
    label: "EF002245: Safety temperature limit"
  },
  {
    featureId: "EF002249",
    featureDesc: "Self de-aerating circulating pump",
    label: "EF002249: Self de-aerating circulating pump"
  },
  {
    featureId: "EF002250",
    featureDesc: "Discharge head circulating pump",
    label: "EF002250: Discharge head circulating pump"
  },
  {
    featureId: "EF002257",
    featureDesc: "Thermally insulated",
    label: "EF002257: Thermally insulated"
  },
  {
    featureId: "EF002267",
    featureDesc: "Connection feed/return heating",
    label: "EF002267: Connection feed/return heating"
  },
  {
    featureId: "EF002270",
    featureDesc: "Sound performance level",
    label: "EF002270: Sound performance level"
  },
  {
    featureId: "EF002274",
    featureDesc: "Type of defrost",
    label: "EF002274: Type of defrost"
  },
  {
    featureId: "EF002276",
    featureDesc: "Water content",
    label: "EF002276: Water content"
  },
  {
    featureId: "EF002279",
    featureDesc: "Connection heating",
    label: "EF002279: Connection heating"
  },
  {
    featureId: "EF002280",
    featureDesc: "Connection heat pump",
    label: "EF002280: Connection heat pump"
  },
  {
    featureId: "EF002286",
    featureDesc: "Connection supply",
    label: "EF002286: Connection supply"
  },
  {
    featureId: "EF002287",
    featureDesc: "Connection return",
    label: "EF002287: Connection return"
  },
  {
    featureId: "EF002288",
    featureDesc: "With mixing valve",
    label: "EF002288: With mixing valve"
  },
  {
    featureId: "EF002290",
    featureDesc: "With servomotor",
    label: "EF002290: With servomotor"
  },
  {
    featureId: "EF002291",
    featureDesc: "Connection feed/return",
    label: "EF002291: Connection feed/return"
  },
  {
    featureId: "EF002292",
    featureDesc: "Connection storage circuit",
    label: "EF002292: Connection storage circuit"
  },
  {
    featureId: "EF002294",
    featureDesc: "With safety overflow valve",
    label: "EF002294: With safety overflow valve"
  },
  {
    featureId: "EF002297",
    featureDesc: "Uni-directional",
    label: "EF002297: Uni-directional"
  },
  {
    featureId: "EF002299",
    featureDesc: "Response pressure",
    label: "EF002299: Response pressure"
  },
  {
    featureId: "EF002300",
    featureDesc: "Adjustment range",
    label: "EF002300: Adjustment range"
  },
  {
    featureId: "EF002302",
    featureDesc: "Property",
    label: "EF002302: Property"
  },
  {
    featureId: "EF002304",
    featureDesc: "For number of collectors",
    label: "EF002304: For number of collectors"
  },
  {
    featureId: "EF002305",
    featureDesc: "Applicable height",
    label: "EF002305: Applicable height"
  },
  {
    featureId: "EF002306",
    featureDesc: "Thickness of thermal insulation",
    label: "EF002306: Thickness of thermal insulation"
  },
  {
    featureId: "EF002307",
    featureDesc: "Material of thermal insulation",
    label: "EF002307: Material of thermal insulation"
  },
  {
    featureId: "EF002309",
    featureDesc: "With plug-in connection",
    label: "EF002309: With plug-in connection"
  },
  {
    featureId: "EF002310",
    featureDesc: "Suitable for glycol",
    label: "EF002310: Suitable for glycol"
  },
  {
    featureId: "EF002311",
    featureDesc: "Max. volume flow",
    label: "EF002311: Max. volume flow"
  },
  {
    featureId: "EF002312",
    featureDesc: "Frost-proof to",
    label: "EF002312: Frost-proof to"
  },
  {
    featureId: "EF002313",
    featureDesc: "Resistant to a continuous temperature to",
    label: "EF002313: Resistant to a continuous temperature to"
  },
  {
    featureId: "EF002321",
    featureDesc: "Connection size",
    label: "EF002321: Connection size"
  },
  {
    featureId: "EF002323",
    featureDesc: "Max. pressure difference",
    label: "EF002323: Max. pressure difference"
  },
  {
    featureId: "EF002326",
    featureDesc: "Front glass",
    label: "EF002326: Front glass"
  },
  {
    featureId: "EF002327",
    featureDesc: "Shock-proof",
    label: "EF002327: Shock-proof"
  },
  {
    featureId: "EF002328",
    featureDesc: "Suitable for refrigerator",
    label: "EF002328: Suitable for refrigerator"
  },
  {
    featureId: "EF002329",
    featureDesc: "Krypton",
    label: "EF002329: Krypton"
  },
  {
    featureId: "EF002330",
    featureDesc: "Strip range AWG",
    label: "EF002330: Strip range AWG"
  },
  {
    featureId: "EF002336",
    featureDesc: "Overload release current setting",
    label: "EF002336: Overload release current setting"
  },
  {
    featureId: "EF002337",
    featureDesc: "Type of mirroring",
    label: "EF002337: Type of mirroring"
  },
  {
    featureId: "EF002338",
    featureDesc: "External ignitor required",
    label: "EF002338: External ignitor required"
  },
  {
    featureId: "EF002339",
    featureDesc: "External ballast required",
    label: "EF002339: External ballast required"
  },
  {
    featureId: "EF002340",
    featureDesc: "Sun tanning lamp",
    label: "EF002340: Sun tanning lamp"
  },
  {
    featureId: "EF002341",
    featureDesc: "Material discharge tube",
    label: "EF002341: Material discharge tube"
  },
  {
    featureId: "EF002342",
    featureDesc: "Mercury-free",
    label: "EF002342: Mercury-free"
  },
  {
    featureId: "EF002343",
    featureDesc: "Number of discharge tubes",
    label: "EF002343: Number of discharge tubes"
  },
  {
    featureId: "EF002344",
    featureDesc: "Manufacturer specific system components required",
    label: "EF002344: Manufacturer specific system components required"
  },
  {
    featureId: "EF002348",
    featureDesc: "Suitable for diameter",
    label: "EF002348: Suitable for diameter"
  },
  {
    featureId: "EF002349",
    featureDesc: "Internal reflector",
    label: "EF002349: Internal reflector"
  },
  {
    featureId: "EF002350",
    featureDesc: "Setting",
    label: "EF002350: Setting"
  },
  {
    featureId: "EF002352",
    featureDesc: "Full spectrum lamp",
    label: "EF002352: Full spectrum lamp"
  },
  {
    featureId: "EF002353",
    featureDesc: "Suitable for emergency power with DC supply",
    label: "EF002353: Suitable for emergency power with DC supply"
  },
  {
    featureId: "EF002354",
    featureDesc: "Suitable for frequent switching",
    label: "EF002354: Suitable for frequent switching"
  },
  {
    featureId: "EF002355",
    featureDesc: "Voltage",
    label: "EF002355: Voltage"
  },
  {
    featureId: "EF002356",
    featureDesc: "Cable diameter",
    label: "EF002356: Cable diameter"
  },
  {
    featureId: "EF002358",
    featureDesc: "Rated fault current",
    label: "EF002358: Rated fault current"
  },
  {
    featureId: "EF002359",
    featureDesc: "Suitable for torches",
    label: "EF002359: Suitable for torches"
  },
  {
    featureId: "EF002360",
    featureDesc: "Suitable for pointer",
    label: "EF002360: Suitable for pointer"
  },
  {
    featureId: "EF002361",
    featureDesc: "Suitable for traffic lights",
    label: "EF002361: Suitable for traffic lights"
  },
  {
    featureId: "EF002362",
    featureDesc: "Suitable for variable road signs",
    label: "EF002362: Suitable for variable road signs"
  },
  {
    featureId: "EF002363",
    featureDesc: "Suitable for rail traffic",
    label: "EF002363: Suitable for rail traffic"
  },
  {
    featureId: "EF002365",
    featureDesc: "Halogen",
    label: "EF002365: Halogen"
  },
  {
    featureId: "EF002366",
    featureDesc: "Number of filaments",
    label: "EF002366: Number of filaments"
  },
  {
    featureId: "EF002367",
    featureDesc: "Length of arc",
    label: "EF002367: Length of arc"
  },
  {
    featureId: "EF002368",
    featureDesc: "Filament shape",
    label: "EF002368: Filament shape"
  },
  {
    featureId: "EF002369",
    featureDesc: "Filament length",
    label: "EF002369: Filament length"
  },
  {
    featureId: "EF002370",
    featureDesc: "Technology",
    label: "EF002370: Technology"
  },
  {
    featureId: "EF002371",
    featureDesc: "Reflector diameter",
    label: "EF002371: Reflector diameter"
  },
  {
    featureId: "EF002382",
    featureDesc: "Time delay at signal change",
    label: "EF002382: Time delay at signal change"
  },
  {
    featureId: "EF002383",
    featureDesc: "Standard indication (ECE)",
    label: "EF002383: Standard indication (ECE)"
  },
  {
    featureId: "EF002384",
    featureDesc: "Overload protector",
    label: "EF002384: Overload protector"
  },
  {
    featureId: "EF002386",
    featureDesc: "Ambient temperature",
    label: "EF002386: Ambient temperature"
  },
  {
    featureId: "EF002390",
    featureDesc: "Suitable for pipe diameter",
    label: "EF002390: Suitable for pipe diameter"
  },
  {
    featureId: "EF002391",
    featureDesc: "Chucking range",
    label: "EF002391: Chucking range"
  },
  {
    featureId: "EF002392",
    featureDesc: "Clamping range",
    label: "EF002392: Clamping range"
  },
  {
    featureId: "EF002393",
    featureDesc: "Operating temperature",
    label: "EF002393: Operating temperature"
  },
  {
    featureId: "EF002395",
    featureDesc: "Cross section of take-off cable",
    label: "EF002395: Cross section of take-off cable"
  },
  {
    featureId: "EF002398",
    featureDesc: "Screen size diagonal",
    label: "EF002398: Screen size diagonal"
  },
  {
    featureId: "EF002399",
    featureDesc: "Type of command",
    label: "EF002399: Type of command"
  },
  {
    featureId: "EF002400",
    featureDesc: "With protection hood",
    label: "EF002400: With protection hood"
  },
  {
    featureId: "EF002401",
    featureDesc: "With interlocking",
    label: "EF002401: With interlocking"
  },
  {
    featureId: "EF002403",
    featureDesc: "Tube length",
    label: "EF002403: Tube length"
  },
  {
    featureId: "EF002404",
    featureDesc: "Prewiring on terminal rail",
    label: "EF002404: Prewiring on terminal rail"
  },
  {
    featureId: "EF002405",
    featureDesc: "With switch lock",
    label: "EF002405: With switch lock"
  },
  {
    featureId: "EF002406",
    featureDesc: "Version as assembly kit",
    label: "EF002406: Version as assembly kit"
  },
  {
    featureId: "EF002407",
    featureDesc: "Scheme for two-hand control",
    label: "EF002407: Scheme for two-hand control"
  },
  {
    featureId: "EF002408",
    featureDesc: "With foot",
    label: "EF002408: With foot"
  },
  {
    featureId: "EF002409",
    featureDesc: "Stepped switching",
    label: "EF002409: Stepped switching"
  },
  {
    featureId: "EF002414",
    featureDesc: "Number of speeds",
    label: "EF002414: Number of speeds"
  },
  {
    featureId: "EF002416",
    featureDesc: "Type of executable motions",
    label: "EF002416: Type of executable motions"
  },
  {
    featureId: "EF002417",
    featureDesc: "Rated operation current Ie at AC-21, 400 V",
    label: "EF002417: Rated operation current Ie at AC-21, 400 V"
  },
  {
    featureId: "EF002418",
    featureDesc: "Rated operating voltage Ue",
    label: "EF002418: Rated operating voltage Ue"
  },
  {
    featureId: "EF002423",
    featureDesc: "Lamp type",
    label: "EF002423: Lamp type"
  },
  {
    featureId: "EF002424",
    featureDesc: "Centre mounting, hole diameter",
    label: "EF002424: Centre mounting, hole diameter"
  },
  {
    featureId: "EF002425",
    featureDesc: "Number of actuation directions",
    label: "EF002425: Number of actuation directions"
  },
  {
    featureId: "EF002426",
    featureDesc: "Number of steps",
    label: "EF002426: Number of steps"
  },
  {
    featureId: "EF002427",
    featureDesc: "Number of normally open contacts per actuation direction",
    label: "EF002427: Number of normally open contacts per actuation direction"
  },
  {
    featureId: "EF002428",
    featureDesc: "With retraction in 0-position",
    label: "EF002428: With retraction in 0-position"
  },
  {
    featureId: "EF002429",
    featureDesc: "Colour front ring",
    label: "EF002429: Colour front ring"
  },
  {
    featureId: "EF002430",
    featureDesc: "Analogue",
    label: "EF002430: Analogue"
  },
  {
    featureId: "EF002431",
    featureDesc: "Resistance",
    label: "EF002431: Resistance"
  },
  {
    featureId: "EF002432",
    featureDesc: "DC-isolation",
    label: "EF002432: DC-isolation"
  },
  {
    featureId: "EF002433",
    featureDesc: "Core type",
    label: "EF002433: Core type"
  },
  {
    featureId: "EF002435",
    featureDesc: "Anti-kink sleeve",
    label: "EF002435: Anti-kink sleeve"
  },
  {
    featureId: "EF002440",
    featureDesc: "Duct installation",
    label: "EF002440: Duct installation"
  },
  {
    featureId: "EF002442",
    featureDesc: "Type of fastening",
    label: "EF002442: Type of fastening"
  },
  {
    featureId: "EF002444",
    featureDesc: "Shielded buses",
    label: "EF002444: Shielded buses"
  },
  {
    featureId: "EF002445",
    featureDesc: "Shielded housing",
    label: "EF002445: Shielded housing"
  },
  {
    featureId: "EF002450",
    featureDesc: "Separate strain relief",
    label: "EF002450: Separate strain relief"
  },
  {
    featureId: "EF002452",
    featureDesc: "Dust protection cap",
    label: "EF002452: Dust protection cap"
  },
  {
    featureId: "EF002453",
    featureDesc: "Max. start vacuum",
    label: "EF002453: Max. start vacuum"
  },
  {
    featureId: "EF002454",
    featureDesc: "Model with flux",
    label: "EF002454: Model with flux"
  },
  {
    featureId: "EF002456",
    featureDesc: "Vacuum volume",
    label: "EF002456: Vacuum volume"
  },
  {
    featureId: "EF002459",
    featureDesc: "Operating time with gas charge",
    label: "EF002459: Operating time with gas charge"
  },
  {
    featureId: "EF002460",
    featureDesc: "Max. temperature",
    label: "EF002460: Max. temperature"
  },
  {
    featureId: "EF002461",
    featureDesc: "Ignition",
    label: "EF002461: Ignition"
  },
  {
    featureId: "EF002462",
    featureDesc: "Type of soldering tip",
    label: "EF002462: Type of soldering tip"
  },
  {
    featureId: "EF002463",
    featureDesc: "Solder quantity",
    label: "EF002463: Solder quantity"
  },
  {
    featureId: "EF002464",
    featureDesc: "Volume flow",
    label: "EF002464: Volume flow"
  },
  {
    featureId: "EF002465",
    featureDesc: "Type of extraction",
    label: "EF002465: Type of extraction"
  },
  {
    featureId: "EF002466",
    featureDesc: "Number of insertable soldering tips",
    label: "EF002466: Number of insertable soldering tips"
  },
  {
    featureId: "EF002467",
    featureDesc: "Number of layers",
    label: "EF002467: Number of layers"
  },
  {
    featureId: "EF002468",
    featureDesc: "Hole central",
    label: "EF002468: Hole central"
  },
  {
    featureId: "EF002469",
    featureDesc: "Max. deviation",
    label: "EF002469: Max. deviation"
  },
  {
    featureId: "EF002470",
    featureDesc: "Housing length",
    label: "EF002470: Housing length"
  },
  {
    featureId: "EF002471",
    featureDesc: "Housing diameter",
    label: "EF002471: Housing diameter"
  },
  {
    featureId: "EF002472",
    featureDesc: "Diameter suction cup 1",
    label: "EF002472: Diameter suction cup 1"
  },
  {
    featureId: "EF002473",
    featureDesc: "Diameter suction cup 2",
    label: "EF002473: Diameter suction cup 2"
  },
  {
    featureId: "EF002474",
    featureDesc: "Diameter suction cup 3",
    label: "EF002474: Diameter suction cup 3"
  },
  {
    featureId: "EF002476",
    featureDesc: "Connectable to DC control system",
    label: "EF002476: Connectable to DC control system"
  },
  {
    featureId: "EF002479",
    featureDesc: "Plain exterior",
    label: "EF002479: Plain exterior"
  },
  {
    featureId: "EF002480",
    featureDesc: "Suitable for outdoor application",
    label: "EF002480: Suitable for outdoor application"
  },
  {
    featureId: "EF002481",
    featureDesc: "Integrated timer",
    label: "EF002481: Integrated timer"
  },
  {
    featureId: "EF002482",
    featureDesc: "Collector connection",
    label: "EF002482: Collector connection"
  },
  {
    featureId: "EF002484",
    featureDesc: "With connection expansion reservoir",
    label: "EF002484: With connection expansion reservoir"
  },
  {
    featureId: "EF002485",
    featureDesc: "With automatic de-aeration",
    label: "EF002485: With automatic de-aeration"
  },
  {
    featureId: "EF002486",
    featureDesc: "Voltage measuring range",
    label: "EF002486: Voltage measuring range"
  },
  {
    featureId: "EF002489",
    featureDesc: "Voltage measuring range/AC direct",
    label: "EF002489: Voltage measuring range/AC direct"
  },
  {
    featureId: "EF002491",
    featureDesc: "Voltage measurement, AC",
    label: "EF002491: Voltage measurement, AC"
  },
  {
    featureId: "EF002492",
    featureDesc: "Voltage measurement, DC",
    label: "EF002492: Voltage measurement, DC"
  },
  {
    featureId: "EF002494",
    featureDesc: "Measurement subdivision",
    label: "EF002494: Measurement subdivision"
  },
  {
    featureId: "EF002495",
    featureDesc: "Connection type connection 1",
    label: "EF002495: Connection type connection 1"
  },
  {
    featureId: "EF002496",
    featureDesc: "Connection type connection 2",
    label: "EF002496: Connection type connection 2"
  },
  {
    featureId: "EF002497",
    featureDesc: "Mounting method shielding connection",
    label: "EF002497: Mounting method shielding connection"
  },
  {
    featureId: "EF002498",
    featureDesc: "Pin assignment connection 1",
    label: "EF002498: Pin assignment connection 1"
  },
  {
    featureId: "EF002499",
    featureDesc: "Pin assignment connection 2",
    label: "EF002499: Pin assignment connection 2"
  },
  {
    featureId: "EF002500",
    featureDesc: "Separate earthing connection",
    label: "EF002500: Separate earthing connection"
  },
  {
    featureId: "EF002503",
    featureDesc: "Outer diameter desoldering tip",
    label: "EF002503: Outer diameter desoldering tip"
  },
  {
    featureId: "EF002504",
    featureDesc: "Max. outer diameter hose",
    label: "EF002504: Max. outer diameter hose"
  },
  {
    featureId: "EF002505",
    featureDesc: "Type of connector connection 1",
    label: "EF002505: Type of connector connection 1"
  },
  {
    featureId: "EF002506",
    featureDesc: "Type of connector connection 2",
    label: "EF002506: Type of connector connection 2"
  },
  {
    featureId: "EF002511",
    featureDesc: "Number of branches",
    label: "EF002511: Number of branches"
  },
  {
    featureId: "EF002519",
    featureDesc: "Number of clamp positions per pole",
    label: "EF002519: Number of clamp positions per pole"
  },
  {
    featureId: "EF002520",
    featureDesc: "Insulating material",
    label: "EF002520: Insulating material"
  },
  {
    featureId: "EF002521",
    featureDesc: "Inflammability class of insulation material according to with UL94",
    label: "EF002521: Inflammability class of insulation material according to with UL94"
  },
  {
    featureId: "EF002522",
    featureDesc: "Width/grid dimension",
    label: "EF002522: Width/grid dimension"
  },
  {
    featureId: "EF002525",
    featureDesc: "Closing plate required",
    label: "EF002525: Closing plate required"
  },
  {
    featureId: "EF002526",
    featureDesc: "Type of electrical connection 1",
    label: "EF002526: Type of electrical connection 1"
  },
  {
    featureId: "EF002527",
    featureDesc: "Type of electrical connection 2",
    label: "EF002527: Type of electrical connection 2"
  },
  {
    featureId: "EF002528",
    featureDesc: "Connecting plate required",
    label: "EF002528: Connecting plate required"
  },
  {
    featureId: "EF002529",
    featureDesc: "Type of indication element",
    label: "EF002529: Type of indication element"
  },
  {
    featureId: "EF002530",
    featureDesc: "Type of fuse insert",
    label: "EF002530: Type of fuse insert"
  },
  {
    featureId: "EF002531",
    featureDesc: "Type of fuse holder",
    label: "EF002531: Type of fuse holder"
  },
  {
    featureId: "EF002532",
    featureDesc: "Type of indication element for defect indication",
    label: "EF002532: Type of indication element for defect indication"
  },
  {
    featureId: "EF002533",
    featureDesc: "Supply voltage for defect-indication",
    label: "EF002533: Supply voltage for defect-indication"
  },
  {
    featureId: "EF002534",
    featureDesc: "With PE connection",
    label: "EF002534: With PE connection"
  },
  {
    featureId: "EF002535",
    featureDesc: "With shield connection",
    label: "EF002535: With shield connection"
  },
  {
    featureId: "EF002536",
    featureDesc: "With power supply function",
    label: "EF002536: With power supply function"
  },
  {
    featureId: "EF002537",
    featureDesc: "LED switching for",
    label: "EF002537: LED switching for"
  },
  {
    featureId: "EF002543",
    featureDesc: "Type of thermal element",
    label: "EF002543: Type of thermal element"
  },
  {
    featureId: "EF002544",
    featureDesc: "PEN function possible",
    label: "EF002544: PEN function possible"
  },
  {
    featureId: "EF002545",
    featureDesc: "Type of slitting",
    label: "EF002545: Type of slitting"
  },
  {
    featureId: "EF002546",
    featureDesc: "Type of cross disconnection",
    label: "EF002546: Type of cross disconnection"
  },
  {
    featureId: "EF002547",
    featureDesc: "With integrated test socket",
    label: "EF002547: With integrated test socket"
  },
  {
    featureId: "EF002548",
    featureDesc: "Type of component",
    label: "EF002548: Type of component"
  },
  {
    featureId: "EF002550",
    featureDesc: "Number of bridged clamps",
    label: "EF002550: Number of bridged clamps"
  },
  {
    featureId: "EF002551",
    featureDesc: "Snap in",
    label: "EF002551: Snap in"
  },
  {
    featureId: "EF002555",
    featureDesc: "With integrated feed-through flange",
    label: "EF002555: With integrated feed-through flange"
  },
  {
    featureId: "EF002556",
    featureDesc: "Thickness of mounting plate",
    label: "EF002556: Thickness of mounting plate"
  },
  {
    featureId: "EF002557",
    featureDesc: "Suitable for N-busbar",
    label: "EF002557: Suitable for N-busbar"
  },
  {
    featureId: "EF002558",
    featureDesc: "Surface treatment contacts",
    label: "EF002558: Surface treatment contacts"
  },
  {
    featureId: "EF002560",
    featureDesc: "Leakage current type",
    label: "EF002560: Leakage current type"
  },
  {
    featureId: "EF002561",
    featureDesc: "Selective protection",
    label: "EF002561: Selective protection"
  },
  {
    featureId: "EF002562",
    featureDesc: "Short-circuit breaking capacity (Icw)",
    label: "EF002562: Short-circuit breaking capacity (Icw)"
  },
  {
    featureId: "EF002563",
    featureDesc: "Rated switching capacity",
    label: "EF002563: Rated switching capacity"
  },
  {
    featureId: "EF002564",
    featureDesc: "Surge current capacity",
    label: "EF002564: Surge current capacity"
  },
  {
    featureId: "EF002566",
    featureDesc: "Construction size",
    label: "EF002566: Construction size"
  },
  {
    featureId: "EF002567",
    featureDesc: "Dimensions",
    label: "EF002567: Dimensions"
  },
  {
    featureId: "EF002568",
    featureDesc: "Current limiting class",
    label: "EF002568: Current limiting class"
  },
  {
    featureId: "EF002569",
    featureDesc: "Over voltage category",
    label: "EF002569: Over voltage category"
  },
  {
    featureId: "EF002570",
    featureDesc: "Pollution degree",
    label: "EF002570: Pollution degree"
  },
  {
    featureId: "EF002572",
    featureDesc: "Utilization category",
    label: "EF002572: Utilization category"
  },
  {
    featureId: "EF002573",
    featureDesc: "Bus-mounting type",
    label: "EF002573: Bus-mounting type"
  },
  {
    featureId: "EF002574",
    featureDesc: "Swivelling mechanism",
    label: "EF002574: Swivelling mechanism"
  },
  {
    featureId: "EF002575",
    featureDesc: "Quantity",
    label: "EF002575: Quantity"
  },
  {
    featureId: "EF002576",
    featureDesc: "Characteristic",
    label: "EF002576: Characteristic"
  },
  {
    featureId: "EF002578",
    featureDesc: "Rated surge voltage invariability",
    label: "EF002578: Rated surge voltage invariability"
  },
  {
    featureId: "EF002583",
    featureDesc: "Switching-off blocking",
    label: "EF002583: Switching-off blocking"
  },
  {
    featureId: "EF002584",
    featureDesc: "Switching-on blocking",
    label: "EF002584: Switching-on blocking"
  },
  {
    featureId: "EF002585",
    featureDesc: "Text",
    label: "EF002585: Text"
  },
  {
    featureId: "EF002586",
    featureDesc: "Microphone connection",
    label: "EF002586: Microphone connection"
  },
  {
    featureId: "EF002587",
    featureDesc: "Number of frequency bands",
    label: "EF002587: Number of frequency bands"
  },
  {
    featureId: "EF002590",
    featureDesc: "Variable deep bass filter",
    label: "EF002590: Variable deep bass filter"
  },
  {
    featureId: "EF002591",
    featureDesc: "Variable high bass filter",
    label: "EF002591: Variable high bass filter"
  },
  {
    featureId: "EF002594",
    featureDesc: "With holder",
    label: "EF002594: With holder"
  },
  {
    featureId: "EF002605",
    featureDesc: "Number",
    label: "EF002605: Number"
  },
  {
    featureId: "EF002607",
    featureDesc: "Max. air volume",
    label: "EF002607: Max. air volume"
  },
  {
    featureId: "EF002610",
    featureDesc: "Transport length",
    label: "EF002610: Transport length"
  },
  {
    featureId: "EF002613",
    featureDesc: "Mounting length",
    label: "EF002613: Mounting length"
  },
  {
    featureId: "EF002616",
    featureDesc: "With wall mount",
    label: "EF002616: With wall mount"
  },
  {
    featureId: "EF002618",
    featureDesc: "Air volume",
    label: "EF002618: Air volume"
  },
  {
    featureId: "EF002619",
    featureDesc: "Closed luminaire required",
    label: "EF002619: Closed luminaire required"
  },
  {
    featureId: "EF002621",
    featureDesc: "Flickering candle lamp",
    label: "EF002621: Flickering candle lamp"
  },
  {
    featureId: "EF002622",
    featureDesc: "Carbon filament",
    label: "EF002622: Carbon filament"
  },
  {
    featureId: "EF002623",
    featureDesc: "Mirror colour",
    label: "EF002623: Mirror colour"
  },
  {
    featureId: "EF002630",
    featureDesc: "Colour of light",
    label: "EF002630: Colour of light"
  },
  {
    featureId: "EF002631",
    featureDesc: "Number of lighting tubes",
    label: "EF002631: Number of lighting tubes"
  },
  {
    featureId: "EF002632",
    featureDesc: "Reflector colour",
    label: "EF002632: Reflector colour"
  },
  {
    featureId: "EF002633",
    featureDesc: "Lighting for plants",
    label: "EF002633: Lighting for plants"
  },
  {
    featureId: "EF002634",
    featureDesc: "Replacement for mercury vapour lamp",
    label: "EF002634: Replacement for mercury vapour lamp"
  },
  {
    featureId: "EF002635",
    featureDesc: "Suitable for matrix information boards",
    label: "EF002635: Suitable for matrix information boards"
  },
  {
    featureId: "EF002638",
    featureDesc: "Busbar distance",
    label: "EF002638: Busbar distance"
  },
  {
    featureId: "EF002639",
    featureDesc: "Busbar height",
    label: "EF002639: Busbar height"
  },
  {
    featureId: "EF002640",
    featureDesc: "Busbar thickness",
    label: "EF002640: Busbar thickness"
  },
  {
    featureId: "EF002642",
    featureDesc: "Max. opening angle",
    label: "EF002642: Max. opening angle"
  },
  {
    featureId: "EF002643",
    featureDesc: "Inlying hinge",
    label: "EF002643: Inlying hinge"
  },
  {
    featureId: "EF002644",
    featureDesc: "Outlying hinge",
    label: "EF002644: Outlying hinge"
  },
  {
    featureId: "EF002645",
    featureDesc: "Hinge adjustable",
    label: "EF002645: Hinge adjustable"
  },
  {
    featureId: "EF002647",
    featureDesc: "IEC-amperage",
    label: "EF002647: IEC-amperage"
  },
  {
    featureId: "EF002648",
    featureDesc: "Amperage according to UL/CSA (for UL-version)",
    label: "EF002648: Amperage according to UL/CSA (for UL-version)"
  },
  {
    featureId: "EF002649",
    featureDesc: "Connection system",
    label: "EF002649: Connection system"
  },
  {
    featureId: "EF002650",
    featureDesc: "Insert direction",
    label: "EF002650: Insert direction"
  },
  {
    featureId: "EF002652",
    featureDesc: "Suitable for protective contact pin",
    label: "EF002652: Suitable for protective contact pin"
  },
  {
    featureId: "EF002653",
    featureDesc: "Flange size",
    label: "EF002653: Flange size"
  },
  {
    featureId: "EF002654",
    featureDesc: "Configuration",
    label: "EF002654: Configuration"
  },
  {
    featureId: "EF002655",
    featureDesc: "Phase inverter",
    label: "EF002655: Phase inverter"
  },
  {
    featureId: "EF002657",
    featureDesc: "Earth leakage switch",
    label: "EF002657: Earth leakage switch"
  },
  {
    featureId: "EF002658",
    featureDesc: "Empty locations on mounting rail",
    label: "EF002658: Empty locations on mounting rail"
  },
  {
    featureId: "EF002659",
    featureDesc: "Number of levels",
    label: "EF002659: Number of levels"
  },
  {
    featureId: "EF002660",
    featureDesc: "Number of clamp positions per level",
    label: "EF002660: Number of clamp positions per level"
  },
  {
    featureId: "EF002661",
    featureDesc: "Internally connected stages",
    label: "EF002661: Internally connected stages"
  },
  {
    featureId: "EF002662",
    featureDesc: "Dimension vertical to mounting plate inner side",
    label: "EF002662: Dimension vertical to mounting plate inner side"
  },
  {
    featureId: "EF002663",
    featureDesc: "Dimension parallel to mounting plate inner side",
    label: "EF002663: Dimension parallel to mounting plate inner side"
  },
  {
    featureId: "EF002664",
    featureDesc: "Dimension vertical to mounting plate outer side",
    label: "EF002664: Dimension vertical to mounting plate outer side"
  },
  {
    featureId: "EF002665",
    featureDesc: "Dimension parallel to mounting plate outer side",
    label: "EF002665: Dimension parallel to mounting plate outer side"
  },
  {
    featureId: "EF002667",
    featureDesc: "Type of latching",
    label: "EF002667: Type of latching"
  },
  {
    featureId: "EF002668",
    featureDesc: "Supply voltage for indication",
    label: "EF002668: Supply voltage for indication"
  },
  {
    featureId: "EF002669",
    featureDesc: "Voltage type for indication",
    label: "EF002669: Voltage type for indication"
  },
  {
    featureId: "EF002670",
    featureDesc: "Number of potentials",
    label: "EF002670: Number of potentials"
  },
  {
    featureId: "EF002671",
    featureDesc: "Basic colour",
    label: "EF002671: Basic colour"
  },
  {
    featureId: "EF002672",
    featureDesc: "PE-function",
    label: "EF002672: PE-function"
  },
  {
    featureId: "EF002673",
    featureDesc: "With N-disconnect slide",
    label: "EF002673: With N-disconnect slide"
  },
  {
    featureId: "EF002674",
    featureDesc: "N function possible",
    label: "EF002674: N function possible"
  },
  {
    featureId: "EF002676",
    featureDesc: "Voltage type for defect indication",
    label: "EF002676: Voltage type for defect indication"
  },
  {
    featureId: "EF002677",
    featureDesc: "Drill hole distance centrically",
    label: "EF002677: Drill hole distance centrically"
  },
  {
    featureId: "EF002680",
    featureDesc: "Niche depth",
    label: "EF002680: Niche depth"
  },
  {
    featureId: "EF002682",
    featureDesc: "Cable diameter main cable",
    label: "EF002682: Cable diameter main cable"
  },
  {
    featureId: "EF002683",
    featureDesc: "Cable diameter branch cable",
    label: "EF002683: Cable diameter branch cable"
  },
  {
    featureId: "EF002684",
    featureDesc: "Integrated door opening circuit",
    label: "EF002684: Integrated door opening circuit"
  },
  {
    featureId: "EF002685",
    featureDesc: "Control function",
    label: "EF002685: Control function"
  },
  {
    featureId: "EF002686",
    featureDesc: "Service indication",
    label: "EF002686: Service indication"
  },
  {
    featureId: "EF002689",
    featureDesc: "Type of lamp holder",
    label: "EF002689: Type of lamp holder"
  },
  {
    featureId: "EF002690",
    featureDesc: "Stroke motion extendable to",
    label: "EF002690: Stroke motion extendable to"
  },
  {
    featureId: "EF002691",
    featureDesc: "With rail runner",
    label: "EF002691: With rail runner"
  },
  {
    featureId: "EF002692",
    featureDesc: "With interior push-button",
    label: "EF002692: With interior push-button"
  },
  {
    featureId: "EF002693",
    featureDesc: "Separate control unit housing",
    label: "EF002693: Separate control unit housing"
  },
  {
    featureId: "EF002694",
    featureDesc: "Suitable for gates with low-lintel-fitting",
    label: "EF002694: Suitable for gates with low-lintel-fitting"
  },
  {
    featureId: "EF002695",
    featureDesc: "Soft run",
    label: "EF002695: Soft run"
  },
  {
    featureId: "EF002696",
    featureDesc: "Cycle counter",
    label: "EF002696: Cycle counter"
  },
  {
    featureId: "EF002697",
    featureDesc: "Door status indication",
    label: "EF002697: Door status indication"
  },
  {
    featureId: "EF002698",
    featureDesc: "Defined running direction control",
    label: "EF002698: Defined running direction control"
  },
  {
    featureId: "EF002699",
    featureDesc: "Connection for red traffic light",
    label: "EF002699: Connection for red traffic light"
  },
  {
    featureId: "EF002700",
    featureDesc: "Number of safety inputs",
    label: "EF002700: Number of safety inputs"
  },
  {
    featureId: "EF002702",
    featureDesc: "Suitable for collective garages",
    label: "EF002702: Suitable for collective garages"
  },
  {
    featureId: "EF002703",
    featureDesc: "Floating relay output",
    label: "EF002703: Floating relay output"
  },
  {
    featureId: "EF002704",
    featureDesc: "Frequency radio receiver",
    label: "EF002704: Frequency radio receiver"
  },
  {
    featureId: "EF002705",
    featureDesc: "Standard stroke motion",
    label: "EF002705: Standard stroke motion"
  },
  {
    featureId: "EF002706",
    featureDesc: "With manual transmitter",
    label: "EF002706: With manual transmitter"
  },
  {
    featureId: "EF002708",
    featureDesc: "Octagonal steel shaft SW 40",
    label: "EF002708: Octagonal steel shaft SW 40"
  },
  {
    featureId: "EF002709",
    featureDesc: "Octagonal steel shaft SW 50",
    label: "EF002709: Octagonal steel shaft SW 50"
  },
  {
    featureId: "EF002710",
    featureDesc: "Octagonal steel shaft SW 60",
    label: "EF002710: Octagonal steel shaft SW 60"
  },
  {
    featureId: "EF002711",
    featureDesc: "Octagonal steel shaft SW 70",
    label: "EF002711: Octagonal steel shaft SW 70"
  },
  {
    featureId: "EF002712",
    featureDesc: "For roller blinds",
    label: "EF002712: For roller blinds"
  },
  {
    featureId: "EF002713",
    featureDesc: "For awnings",
    label: "EF002713: For awnings"
  },
  {
    featureId: "EF002714",
    featureDesc: "Diameter of grooved shaft",
    label: "EF002714: Diameter of grooved shaft"
  },
  {
    featureId: "EF002715",
    featureDesc: "For grooved tube shaft",
    label: "EF002715: For grooved tube shaft"
  },
  {
    featureId: "EF002716",
    featureDesc: "Max. length of roller shutter",
    label: "EF002716: Max. length of roller shutter"
  },
  {
    featureId: "EF002718",
    featureDesc: "Type of end switch",
    label: "EF002718: Type of end switch"
  },
  {
    featureId: "EF002719",
    featureDesc: "Back jump",
    label: "EF002719: Back jump"
  },
  {
    featureId: "EF002720",
    featureDesc: "Electronic route monitoring",
    label: "EF002720: Electronic route monitoring"
  },
  {
    featureId: "EF002721",
    featureDesc: "Electronic obstacle recognition",
    label: "EF002721: Electronic obstacle recognition"
  },
  {
    featureId: "EF002722",
    featureDesc: "With carrier",
    label: "EF002722: With carrier"
  },
  {
    featureId: "EF002723",
    featureDesc: "With shaft adapter",
    label: "EF002723: With shaft adapter"
  },
  {
    featureId: "EF002724",
    featureDesc: "Diameter of bell shell",
    label: "EF002724: Diameter of bell shell"
  },
  {
    featureId: "EF002728",
    featureDesc: "Shutter",
    label: "EF002728: Shutter"
  },
  {
    featureId: "EF002729",
    featureDesc: "Number of connectable participators",
    label: "EF002729: Number of connectable participators"
  },
  {
    featureId: "EF002732",
    featureDesc: "Max. width of door/gate wing",
    label: "EF002732: Max. width of door/gate wing"
  },
  {
    featureId: "EF002733",
    featureDesc: "Max. weight of door/gate leaf",
    label: "EF002733: Max. weight of door/gate leaf"
  },
  {
    featureId: "EF002734",
    featureDesc: "Drive principle",
    label: "EF002734: Drive principle"
  },
  {
    featureId: "EF002735",
    featureDesc: "Type of motor",
    label: "EF002735: Type of motor"
  },
  {
    featureId: "EF002736",
    featureDesc: "Motor voltage",
    label: "EF002736: Motor voltage"
  },
  {
    featureId: "EF002737",
    featureDesc: "Limit switch",
    label: "EF002737: Limit switch"
  },
  {
    featureId: "EF002738",
    featureDesc: "Automatic closing",
    label: "EF002738: Automatic closing"
  },
  {
    featureId: "EF002739",
    featureDesc: "With motor control unit",
    label: "EF002739: With motor control unit"
  },
  {
    featureId: "EF002740",
    featureDesc: "With post fittings",
    label: "EF002740: With post fittings"
  },
  {
    featureId: "EF002741",
    featureDesc: "With wing fittings",
    label: "EF002741: With wing fittings"
  },
  {
    featureId: "EF002742",
    featureDesc: "With key-switch",
    label: "EF002742: With key-switch"
  },
  {
    featureId: "EF002743",
    featureDesc: "With radio receiver",
    label: "EF002743: With radio receiver"
  },
  {
    featureId: "EF002744",
    featureDesc: "Integrated control unit",
    label: "EF002744: Integrated control unit"
  },
  {
    featureId: "EF002745",
    featureDesc: "Max. door/gate weight",
    label: "EF002745: Max. door/gate weight"
  },
  {
    featureId: "EF002746",
    featureDesc: "Partial opening",
    label: "EF002746: Partial opening"
  },
  {
    featureId: "EF002747",
    featureDesc: "Length of rack",
    label: "EF002747: Length of rack"
  },
  {
    featureId: "EF002748",
    featureDesc: "With rack",
    label: "EF002748: With rack"
  },
  {
    featureId: "EF002749",
    featureDesc: "Colour of lamp hood",
    label: "EF002749: Colour of lamp hood"
  },
  {
    featureId: "EF002750",
    featureDesc: "Type of optical signal",
    label: "EF002750: Type of optical signal"
  },
  {
    featureId: "EF002751",
    featureDesc: "Type of acoustic signal",
    label: "EF002751: Type of acoustic signal"
  },
  {
    featureId: "EF002752",
    featureDesc: "With system holder",
    label: "EF002752: With system holder"
  },
  {
    featureId: "EF002753",
    featureDesc: "GPS (global positioning system)",
    label: "EF002753: GPS (global positioning system)"
  },
  {
    featureId: "EF002754",
    featureDesc: "With amplifier",
    label: "EF002754: With amplifier"
  },
  {
    featureId: "EF002756",
    featureDesc: "Optics",
    label: "EF002756: Optics"
  },
  {
    featureId: "EF002760",
    featureDesc: "Material call buttons",
    label: "EF002760: Material call buttons"
  },
  {
    featureId: "EF002763",
    featureDesc: "Number of lighting fields",
    label: "EF002763: Number of lighting fields"
  },
  {
    featureId: "EF002764",
    featureDesc: "Diameter optics",
    label: "EF002764: Diameter optics"
  },
  {
    featureId: "EF002765",
    featureDesc: "Number of tones",
    label: "EF002765: Number of tones"
  },
  {
    featureId: "EF002766",
    featureDesc: "Modulation",
    label: "EF002766: Modulation"
  },
  {
    featureId: "EF002767",
    featureDesc: "Rolling code system",
    label: "EF002767: Rolling code system"
  },
  {
    featureId: "EF002769",
    featureDesc: "Number of commands",
    label: "EF002769: Number of commands"
  },
  {
    featureId: "EF002771",
    featureDesc: "Flush-mounted radio transmitter",
    label: "EF002771: Flush-mounted radio transmitter"
  },
  {
    featureId: "EF002772",
    featureDesc: "Surface mounted radio transmitter",
    label: "EF002772: Surface mounted radio transmitter"
  },
  {
    featureId: "EF002774",
    featureDesc: "Keyboard protection cover",
    label: "EF002774: Keyboard protection cover"
  },
  {
    featureId: "EF002775",
    featureDesc: "Radio frequency",
    label: "EF002775: Radio frequency"
  },
  {
    featureId: "EF002776",
    featureDesc: "Tip diameter/tip width",
    label: "EF002776: Tip diameter/tip width"
  },
  {
    featureId: "EF002777",
    featureDesc: "Outer diameter shaft",
    label: "EF002777: Outer diameter shaft"
  },
  {
    featureId: "EF002778",
    featureDesc: "Inner diameter shaft",
    label: "EF002778: Inner diameter shaft"
  },
  {
    featureId: "EF002781",
    featureDesc: "Cool beam",
    label: "EF002781: Cool beam"
  },
  {
    featureId: "EF002782",
    featureDesc: "Tensile strength",
    label: "EF002782: Tensile strength"
  },
  {
    featureId: "EF002783",
    featureDesc: "PC-programming set for Windows 95",
    label: "EF002783: PC-programming set for Windows 95"
  },
  {
    featureId: "EF002784",
    featureDesc: "PC-programming set for Windows NT",
    label: "EF002784: PC-programming set for Windows NT"
  },
  {
    featureId: "EF002785",
    featureDesc: "PC-programming set for Windows 98",
    label: "EF002785: PC-programming set for Windows 98"
  },
  {
    featureId: "EF002786",
    featureDesc: "PC-programming set for Windows XP",
    label: "EF002786: PC-programming set for Windows XP"
  },
  {
    featureId: "EF002787",
    featureDesc: "PC-programming set for Windows 2000",
    label: "EF002787: PC-programming set for Windows 2000"
  },
  {
    featureId: "EF002788",
    featureDesc: "CEE socket outlet 16 A",
    label: "EF002788: CEE socket outlet 16 A"
  },
  {
    featureId: "EF002789",
    featureDesc: "CEE socket outlet 32 A",
    label: "EF002789: CEE socket outlet 32 A"
  },
  {
    featureId: "EF002790",
    featureDesc: "CEE socket outlet 63 A",
    label: "EF002790: CEE socket outlet 63 A"
  },
  {
    featureId: "EF002791",
    featureDesc: "CEE socket outlet 125 A",
    label: "EF002791: CEE socket outlet 125 A"
  },
  {
    featureId: "EF002793",
    featureDesc: "Other fixtures and/or empty locations",
    label: "EF002793: Other fixtures and/or empty locations"
  },
  {
    featureId: "EF002794",
    featureDesc: "Power supply/connection possibility",
    label: "EF002794: Power supply/connection possibility"
  },
  {
    featureId: "EF002795",
    featureDesc: "Handling",
    label: "EF002795: Handling"
  },
  {
    featureId: "EF002799",
    featureDesc: "Type of mounting material",
    label: "EF002799: Type of mounting material"
  },
  {
    featureId: "EF002800",
    featureDesc: "Monitor",
    label: "EF002800: Monitor"
  },
  {
    featureId: "EF002801",
    featureDesc: "Measuring range satellite",
    label: "EF002801: Measuring range satellite"
  },
  {
    featureId: "EF002802",
    featureDesc: "Measuring range terrestrial",
    label: "EF002802: Measuring range terrestrial"
  },
  {
    featureId: "EF002803",
    featureDesc: "Measuring range CATV",
    label: "EF002803: Measuring range CATV"
  },
  {
    featureId: "EF002805",
    featureDesc: "Printer",
    label: "EF002805: Printer"
  },
  {
    featureId: "EF002806",
    featureDesc: "Measurement indication",
    label: "EF002806: Measurement indication"
  },
  {
    featureId: "EF002809",
    featureDesc: "Washing performance class (2019/2014/EU)",
    label: "EF002809: Washing performance class (2019/2014/EU)"
  },
  {
    featureId: "EF002810",
    featureDesc: "Spin drying performance class (2019/2014/EU)",
    label: "EF002810: Spin drying performance class (2019/2014/EU)"
  },
  {
    featureId: "EF002811",
    featureDesc: "Cage diameter",
    label: "EF002811: Cage diameter"
  },
  {
    featureId: "EF002812",
    featureDesc: "Cutting range",
    label: "EF002812: Cutting range"
  },
  {
    featureId: "EF002814",
    featureDesc: "Arbor",
    label: "EF002814: Arbor"
  },
  {
    featureId: "EF002821",
    featureDesc: "Lowest radius",
    label: "EF002821: Lowest radius"
  },
  {
    featureId: "EF002826",
    featureDesc: "Weight including battery-pack",
    label: "EF002826: Weight including battery-pack"
  },
  {
    featureId: "EF002829",
    featureDesc: "Drill diameter concrete with core drill",
    label: "EF002829: Drill diameter concrete with core drill"
  },
  {
    featureId: "EF002830",
    featureDesc: "Drill diameter in concrete",
    label: "EF002830: Drill diameter in concrete"
  },
  {
    featureId: "EF002831",
    featureDesc: "Max. single-blow energy",
    label: "EF002831: Max. single-blow energy"
  },
  {
    featureId: "EF002832",
    featureDesc: "Drill chuck span",
    label: "EF002832: Drill chuck span"
  },
  {
    featureId: "EF002835",
    featureDesc: "Drill diameter steel 2nd gear",
    label: "EF002835: Drill diameter steel 2nd gear"
  },
  {
    featureId: "EF002836",
    featureDesc: "Drill diameter steel 1st gear",
    label: "EF002836: Drill diameter steel 1st gear"
  },
  {
    featureId: "EF002837",
    featureDesc: "Drill diameter wood 2nd gear",
    label: "EF002837: Drill diameter wood 2nd gear"
  },
  {
    featureId: "EF002838",
    featureDesc: "Drill diameter wood 1st gear",
    label: "EF002838: Drill diameter wood 1st gear"
  },
  {
    featureId: "EF002842",
    featureDesc: "Max. torque",
    label: "EF002842: Max. torque"
  },
  {
    featureId: "EF002843",
    featureDesc: "Tool insertion",
    label: "EF002843: Tool insertion"
  },
  {
    featureId: "EF002846",
    featureDesc: "No-load vibration frequency",
    label: "EF002846: No-load vibration frequency"
  },
  {
    featureId: "EF002847",
    featureDesc: "Diameter of oscillating circuit",
    label: "EF002847: Diameter of oscillating circuit"
  },
  {
    featureId: "EF002848",
    featureDesc: "Base-plate corner jig",
    label: "EF002848: Base-plate corner jig"
  },
  {
    featureId: "EF002849",
    featureDesc: "Cutting depth (90°)",
    label: "EF002849: Cutting depth (90°)"
  },
  {
    featureId: "EF002850",
    featureDesc: "Cutting depth (45°)",
    label: "EF002850: Cutting depth (45°)"
  },
  {
    featureId: "EF002852",
    featureDesc: "Cutting length",
    label: "EF002852: Cutting length"
  },
  {
    featureId: "EF002853",
    featureDesc: "Stroke rate",
    label: "EF002853: Stroke rate"
  },
  {
    featureId: "EF002855",
    featureDesc: "Saw blade diameter",
    label: "EF002855: Saw blade diameter"
  },
  {
    featureId: "EF002856",
    featureDesc: "Saw blade bore hole",
    label: "EF002856: Saw blade bore hole"
  },
  {
    featureId: "EF002858",
    featureDesc: "Max. stick length",
    label: "EF002858: Max. stick length"
  },
  {
    featureId: "EF002859",
    featureDesc: "Max. stick diameter",
    label: "EF002859: Max. stick diameter"
  },
  {
    featureId: "EF002862",
    featureDesc: "Air flow control",
    label: "EF002862: Air flow control"
  },
  {
    featureId: "EF002864",
    featureDesc: "Length of base sole",
    label: "EF002864: Length of base sole"
  },
  {
    featureId: "EF002865",
    featureDesc: "Planing depth",
    label: "EF002865: Planing depth"
  },
  {
    featureId: "EF002866",
    featureDesc: "Planing width",
    label: "EF002866: Planing width"
  },
  {
    featureId: "EF002870",
    featureDesc: "Max. negative pressure",
    label: "EF002870: Max. negative pressure"
  },
  {
    featureId: "EF002872",
    featureDesc: "Nominal diameter vacuum hose",
    label: "EF002872: Nominal diameter vacuum hose"
  },
  {
    featureId: "EF002873",
    featureDesc: "Drill diameter concrete 2nd gear",
    label: "EF002873: Drill diameter concrete 2nd gear"
  },
  {
    featureId: "EF002874",
    featureDesc: "Drill diameter concrete 1st gear",
    label: "EF002874: Drill diameter concrete 1st gear"
  },
  {
    featureId: "EF002875",
    featureDesc: "Impacts",
    label: "EF002875: Impacts"
  },
  {
    featureId: "EF002882",
    featureDesc: "Cutting depth in wood",
    label: "EF002882: Cutting depth in wood"
  },
  {
    featureId: "EF002886",
    featureDesc: "Angular dimension",
    label: "EF002886: Angular dimension"
  },
  {
    featureId: "EF002887",
    featureDesc: "Drill diameter in steel",
    label: "EF002887: Drill diameter in steel"
  },
  {
    featureId: "EF002888",
    featureDesc: "Drill diameter in wood",
    label: "EF002888: Drill diameter in wood"
  },
  {
    featureId: "EF002891",
    featureDesc: "RCD/FI testing with rising residual current",
    label: "EF002891: RCD/FI testing with rising residual current"
  },
  {
    featureId: "EF002892",
    featureDesc: "RCD/FI analysis function",
    label: "EF002892: RCD/FI analysis function"
  },
  {
    featureId: "EF002893",
    featureDesc: "Measuring instrument for voltage measurement",
    label: "EF002893: Measuring instrument for voltage measurement"
  },
  {
    featureId: "EF002894",
    featureDesc: "Measuring instrument for current measurement",
    label: "EF002894: Measuring instrument for current measurement"
  },
  {
    featureId: "EF002895",
    featureDesc: "Measuring instrument for resistance measurement",
    label: "EF002895: Measuring instrument for resistance measurement"
  },
  {
    featureId: "EF002896",
    featureDesc: "Measuring instrument for temperature measurement",
    label: "EF002896: Measuring instrument for temperature measurement"
  },
  {
    featureId: "EF002897",
    featureDesc: "Testing device for voltage",
    label: "EF002897: Testing device for voltage"
  },
  {
    featureId: "EF002898",
    featureDesc: "Testing device for continuity",
    label: "EF002898: Testing device for continuity"
  },
  {
    featureId: "EF002899",
    featureDesc: "Testing device for phase sequence",
    label: "EF002899: Testing device for phase sequence"
  },
  {
    featureId: "EF002900",
    featureDesc: "Testing device for isolation",
    label: "EF002900: Testing device for isolation"
  },
  {
    featureId: "EF002901",
    featureDesc: "Testing device for loop resistance",
    label: "EF002901: Testing device for loop resistance"
  },
  {
    featureId: "EF002902",
    featureDesc: "Testing device for RCD/earth leakage switch",
    label: "EF002902: Testing device for RCD/earth leakage switch"
  },
  {
    featureId: "EF002903",
    featureDesc: "Measuring instrument-set for training",
    label: "EF002903: Measuring instrument-set for training"
  },
  {
    featureId: "EF002905",
    featureDesc: "Suitable for wet operation",
    label: "EF002905: Suitable for wet operation"
  },
  {
    featureId: "EF002906",
    featureDesc: "BIT insertion",
    label: "EF002906: BIT insertion"
  },
  {
    featureId: "EF002908",
    featureDesc: "Type of desoldering tool",
    label: "EF002908: Type of desoldering tool"
  },
  {
    featureId: "EF002909",
    featureDesc: "Type of desoldering insert",
    label: "EF002909: Type of desoldering insert"
  },
  {
    featureId: "EF002910",
    featureDesc: "Mechanical coding",
    label: "EF002910: Mechanical coding"
  },
  {
    featureId: "EF002913",
    featureDesc: "Suitable for round cable",
    label: "EF002913: Suitable for round cable"
  },
  {
    featureId: "EF002914",
    featureDesc: "Suitable for flat cable",
    label: "EF002914: Suitable for flat cable"
  },
  {
    featureId: "EF002915",
    featureDesc: "Suitable for litz wire conductor",
    label: "EF002915: Suitable for litz wire conductor"
  },
  {
    featureId: "EF002916",
    featureDesc: "Suitable for solid conductors",
    label: "EF002916: Suitable for solid conductors"
  },
  {
    featureId: "EF002918",
    featureDesc: "Load bearing capacity",
    label: "EF002918: Load bearing capacity"
  },
  {
    featureId: "EF002922",
    featureDesc: "Mounting method cover",
    label: "EF002922: Mounting method cover"
  },
  {
    featureId: "EF002923",
    featureDesc: "Number of usable covers",
    label: "EF002923: Number of usable covers"
  },
  {
    featureId: "EF002927",
    featureDesc: "With edge breaker",
    label: "EF002927: With edge breaker"
  },
  {
    featureId: "EF002928",
    featureDesc: "Spiral groove",
    label: "EF002928: Spiral groove"
  },
  {
    featureId: "EF002930",
    featureDesc: "Number of poles connection 1",
    label: "EF002930: Number of poles connection 1"
  },
  {
    featureId: "EF002931",
    featureDesc: "Number of poles connection 2",
    label: "EF002931: Number of poles connection 2"
  },
  {
    featureId: "EF002935",
    featureDesc: "Type of connector",
    label: "EF002935: Type of connector"
  },
  {
    featureId: "EF002938",
    featureDesc: "Cable strain relief",
    label: "EF002938: Cable strain relief"
  },
  {
    featureId: "EF002941",
    featureDesc: "In housing",
    label: "EF002941: In housing"
  },
  {
    featureId: "EF002942",
    featureDesc: "In tube base housing",
    label: "EF002942: In tube base housing"
  },
  {
    featureId: "EF002943",
    featureDesc: "Printed circuit board/module",
    label: "EF002943: Printed circuit board/module"
  },
  {
    featureId: "EF002944",
    featureDesc: "Push button mode",
    label: "EF002944: Push button mode"
  },
  {
    featureId: "EF002945",
    featureDesc: "Switching mode",
    label: "EF002945: Switching mode"
  },
  {
    featureId: "EF002946",
    featureDesc: "With manual radio transmitter",
    label: "EF002946: With manual radio transmitter"
  },
  {
    featureId: "EF002947",
    featureDesc: "With radio coding device",
    label: "EF002947: With radio coding device"
  },
  {
    featureId: "EF002948",
    featureDesc: "With radio controlled push button",
    label: "EF002948: With radio controlled push button"
  },
  {
    featureId: "EF002949",
    featureDesc: "Number of connectable time switches",
    label: "EF002949: Number of connectable time switches"
  },
  {
    featureId: "EF002950",
    featureDesc: "Width in number of modular spacings",
    label: "EF002950: Width in number of modular spacings"
  },
  {
    featureId: "EF002951",
    featureDesc: "Number of changeover switches",
    label: "EF002951: Number of changeover switches"
  },
  {
    featureId: "EF002952",
    featureDesc: "Control voltage 1",
    label: "EF002952: Control voltage 1"
  },
  {
    featureId: "EF002953",
    featureDesc: "Control voltage 2",
    label: "EF002953: Control voltage 2"
  },
  {
    featureId: "EF002954",
    featureDesc: "Type of control voltage 1",
    label: "EF002954: Type of control voltage 1"
  },
  {
    featureId: "EF002955",
    featureDesc: "Type of control voltage 2",
    label: "EF002955: Type of control voltage 2"
  },
  {
    featureId: "EF002956",
    featureDesc: "Frequency control voltage 1",
    label: "EF002956: Frequency control voltage 1"
  },
  {
    featureId: "EF002957",
    featureDesc: "Frequency control voltage 2",
    label: "EF002957: Frequency control voltage 2"
  },
  {
    featureId: "EF002960",
    featureDesc: "Wiring system",
    label: "EF002960: Wiring system"
  },
  {
    featureId: "EF002961",
    featureDesc: "Pre-warning of switching-off",
    label: "EF002961: Pre-warning of switching-off"
  },
  {
    featureId: "EF002962",
    featureDesc: "Repetitive",
    label: "EF002962: Repetitive"
  },
  {
    featureId: "EF002963",
    featureDesc: "Nominal frequency",
    label: "EF002963: Nominal frequency"
  },
  {
    featureId: "EF002964",
    featureDesc: "60 min program",
    label: "EF002964: 60 min program"
  },
  {
    featureId: "EF002965",
    featureDesc: "24 h program",
    label: "EF002965: 24 h program"
  },
  {
    featureId: "EF002968",
    featureDesc: "Holiday program",
    label: "EF002968: Holiday program"
  },
  {
    featureId: "EF002969",
    featureDesc: "Random program",
    label: "EF002969: Random program"
  },
  {
    featureId: "EF002970",
    featureDesc: "Impulse program",
    label: "EF002970: Impulse program"
  },
  {
    featureId: "EF002971",
    featureDesc: "Cycle program",
    label: "EF002971: Cycle program"
  },
  {
    featureId: "EF002972",
    featureDesc: "With presence indicator",
    label: "EF002972: With presence indicator"
  },
  {
    featureId: "EF002973",
    featureDesc: "Mains synchronous",
    label: "EF002973: Mains synchronous"
  },
  {
    featureId: "EF002974",
    featureDesc: "Quartz controlled",
    label: "EF002974: Quartz controlled"
  },
  {
    featureId: "EF002977",
    featureDesc: "Automatic switching summer-/winter time",
    label: "EF002977: Automatic switching summer-/winter time"
  },
  {
    featureId: "EF002978",
    featureDesc: "Suitable for manual operation",
    label: "EF002978: Suitable for manual operation"
  },
  {
    featureId: "EF002979",
    featureDesc: "Switching preselection",
    label: "EF002979: Switching preselection"
  },
  {
    featureId: "EF002981",
    featureDesc: "Nominal switching current at 250 V, potential free",
    label: "EF002981: Nominal switching current at 250 V, potential free"
  },
  {
    featureId: "EF002982",
    featureDesc: "Nominal switching current at 230 V, not potential free",
    label: "EF002982: Nominal switching current at 230 V, not potential free"
  },
  {
    featureId: "EF002991",
    featureDesc: "Voltage type (supply voltage)",
    label: "EF002991: Voltage type (supply voltage)"
  },
  {
    featureId: "EF002993",
    featureDesc: "Rated surge voltage",
    label: "EF002993: Rated surge voltage"
  },
  {
    featureId: "EF002994",
    featureDesc: "With N-conductor",
    label: "EF002994: With N-conductor"
  },
  {
    featureId: "EF002996",
    featureDesc: "Type of memory",
    label: "EF002996: Type of memory"
  },
  {
    featureId: "EF002997",
    featureDesc: "Memory size",
    label: "EF002997: Memory size"
  },
  {
    featureId: "EF002998",
    featureDesc: "Max. number of integrated analogue inputs",
    label: "EF002998: Max. number of integrated analogue inputs"
  },
  {
    featureId: "EF002999",
    featureDesc: "Max. number of integrated analogue outputs",
    label: "EF002999: Max. number of integrated analogue outputs"
  },
  {
    featureId: "EF003000",
    featureDesc: "Max. number of integrated digital inputs",
    label: "EF003000: Max. number of integrated digital inputs"
  },
  {
    featureId: "EF003001",
    featureDesc: "Max. number of integrated digital outputs",
    label: "EF003001: Max. number of integrated digital outputs"
  },
  {
    featureId: "EF003004",
    featureDesc: "Processing time (1K, binary operation)",
    label: "EF003004: Processing time (1K, binary operation)"
  },
  {
    featureId: "EF003005",
    featureDesc: "Max. number of addressable analogue I/O-ports",
    label: "EF003005: Max. number of addressable analogue I/O-ports"
  },
  {
    featureId: "EF003006",
    featureDesc: "Max. number of addressable digital I/O-ports",
    label: "EF003006: Max. number of addressable digital I/O-ports"
  },
  {
    featureId: "EF003017",
    featureDesc: "Supporting protocol for LON",
    label: "EF003017: Supporting protocol for LON"
  },
  {
    featureId: "EF003020",
    featureDesc: "Number of HW-interfaces industrial Ethernet",
    label: "EF003020: Number of HW-interfaces industrial Ethernet"
  },
  {
    featureId: "EF003021",
    featureDesc: "Number of HW-interfaces RS-232",
    label: "EF003021: Number of HW-interfaces RS-232"
  },
  {
    featureId: "EF003022",
    featureDesc: "Number of HW-interfaces RS-422",
    label: "EF003022: Number of HW-interfaces RS-422"
  },
  {
    featureId: "EF003023",
    featureDesc: "Number of HW-interfaces RS-485",
    label: "EF003023: Number of HW-interfaces RS-485"
  },
  {
    featureId: "EF003024",
    featureDesc: "Number of HW-interfaces serial TTY",
    label: "EF003024: Number of HW-interfaces serial TTY"
  },
  {
    featureId: "EF003025",
    featureDesc: "Number of HW-interfaces USB",
    label: "EF003025: Number of HW-interfaces USB"
  },
  {
    featureId: "EF003026",
    featureDesc: "Number of HW-interfaces parallel",
    label: "EF003026: Number of HW-interfaces parallel"
  },
  {
    featureId: "EF003027",
    featureDesc: "Number of HW-interfaces other",
    label: "EF003027: Number of HW-interfaces other"
  },
  {
    featureId: "EF003028",
    featureDesc: "With optical interface",
    label: "EF003028: With optical interface"
  },
  {
    featureId: "EF003029",
    featureDesc: "Additional program memory possible",
    label: "EF003029: Additional program memory possible"
  },
  {
    featureId: "EF003032",
    featureDesc: "With process simulation, hardware/software",
    label: "EF003032: With process simulation, hardware/software"
  },
  {
    featureId: "EF003033",
    featureDesc: "Type of voltage (input voltage)",
    label: "EF003033: Type of voltage (input voltage)"
  },
  {
    featureId: "EF003034",
    featureDesc: "Power output",
    label: "EF003034: Power output"
  },
  {
    featureId: "EF003035",
    featureDesc: "With editor for programming language IL (Instruction List)",
    label: "EF003035: With editor for programming language IL (Instruction List)"
  },
  {
    featureId: "EF003036",
    featureDesc: "With editor for programming language LD (Ladder Diagram)",
    label: "EF003036: With editor for programming language LD (Ladder Diagram)"
  },
  {
    featureId: "EF003037",
    featureDesc: "With editor for programming language FBD (Function Block Diagram)",
    label: "EF003037: With editor for programming language FBD (Function Block Diagram)"
  },
  {
    featureId: "EF003038",
    featureDesc: "With editor for programming language ST (structured text)",
    label: "EF003038: With editor for programming language ST (structured text)"
  },
  {
    featureId: "EF003039",
    featureDesc: "With editor for programming language SFC (Sequential Function Chart)",
    label: "EF003039: With editor for programming language SFC (Sequential Function Chart)"
  },
  {
    featureId: "EF003040",
    featureDesc: "With editor for manufacturer specific programming language",
    label: "EF003040: With editor for manufacturer specific programming language"
  },
  {
    featureId: "EF003041",
    featureDesc: "Suitable for operating system Windows 9x",
    label: "EF003041: Suitable for operating system Windows 9x"
  },
  {
    featureId: "EF003042",
    featureDesc: "Suitable for operating system Windows NT",
    label: "EF003042: Suitable for operating system Windows NT"
  },
  {
    featureId: "EF003043",
    featureDesc: "Suitable for operating system Windows 2000",
    label: "EF003043: Suitable for operating system Windows 2000"
  },
  {
    featureId: "EF003044",
    featureDesc: "Suitable for operating system Windows XP",
    label: "EF003044: Suitable for operating system Windows XP"
  },
  {
    featureId: "EF003045",
    featureDesc: "Suitable for operating system Windows ME",
    label: "EF003045: Suitable for operating system Windows ME"
  },
  {
    featureId: "EF003046",
    featureDesc: "Suitable for operating system Windows CE",
    label: "EF003046: Suitable for operating system Windows CE"
  },
  {
    featureId: "EF003047",
    featureDesc: "Suitable for operating system Linux",
    label: "EF003047: Suitable for operating system Linux"
  },
  {
    featureId: "EF003048",
    featureDesc: "Suitable for other operating system",
    label: "EF003048: Suitable for other operating system"
  },
  {
    featureId: "EF003049",
    featureDesc: "Object library",
    label: "EF003049: Object library"
  },
  {
    featureId: "EF003050",
    featureDesc: "Supporting protocol for TCP/IP",
    label: "EF003050: Supporting protocol for TCP/IP"
  },
  {
    featureId: "EF003051",
    featureDesc: "Supporting protocol for ASI",
    label: "EF003051: Supporting protocol for ASI"
  },
  {
    featureId: "EF003052",
    featureDesc: "Supporting protocol for PROFIBUS",
    label: "EF003052: Supporting protocol for PROFIBUS"
  },
  {
    featureId: "EF003053",
    featureDesc: "Supporting protocol for CAN",
    label: "EF003053: Supporting protocol for CAN"
  },
  {
    featureId: "EF003054",
    featureDesc: "Supporting protocol for INTERBUS",
    label: "EF003054: Supporting protocol for INTERBUS"
  },
  {
    featureId: "EF003055",
    featureDesc: "Supporting protocol for KNX",
    label: "EF003055: Supporting protocol for KNX"
  },
  {
    featureId: "EF003057",
    featureDesc: "Supporting protocol for Modbus",
    label: "EF003057: Supporting protocol for Modbus"
  },
  {
    featureId: "EF003058",
    featureDesc: "Supporting protocol for Data-Highway",
    label: "EF003058: Supporting protocol for Data-Highway"
  },
  {
    featureId: "EF003059",
    featureDesc: "Supporting protocol for DeviceNet",
    label: "EF003059: Supporting protocol for DeviceNet"
  },
  {
    featureId: "EF003060",
    featureDesc: "Supporting protocol for SUCONET",
    label: "EF003060: Supporting protocol for SUCONET"
  },
  {
    featureId: "EF003062",
    featureDesc: "Supporting protocol for other bus systems",
    label: "EF003062: Supporting protocol for other bus systems"
  },
  {
    featureId: "EF003063",
    featureDesc: "Integrated diagnosis function",
    label: "EF003063: Integrated diagnosis function"
  },
  {
    featureId: "EF003064",
    featureDesc: "Integrated configuration function",
    label: "EF003064: Integrated configuration function"
  },
  {
    featureId: "EF003065",
    featureDesc: "Integrated evaluation function",
    label: "EF003065: Integrated evaluation function"
  },
  {
    featureId: "EF003066",
    featureDesc: "Integrated application configuration function",
    label: "EF003066: Integrated application configuration function"
  },
  {
    featureId: "EF003067",
    featureDesc: "Integrated data detection function",
    label: "EF003067: Integrated data detection function"
  },
  {
    featureId: "EF003068",
    featureDesc: "Integrated communication function",
    label: "EF003068: Integrated communication function"
  },
  {
    featureId: "EF003069",
    featureDesc: "Technology function integrated",
    label: "EF003069: Technology function integrated"
  },
  {
    featureId: "EF003070",
    featureDesc: "Put into operation function integrated",
    label: "EF003070: Put into operation function integrated"
  },
  {
    featureId: "EF003072",
    featureDesc: "Simulation function integrated",
    label: "EF003072: Simulation function integrated"
  },
  {
    featureId: "EF003073",
    featureDesc: "Integrated motion function",
    label: "EF003073: Integrated motion function"
  },
  {
    featureId: "EF003074",
    featureDesc: "Integrated analysis function",
    label: "EF003074: Integrated analysis function"
  },
  {
    featureId: "EF003075",
    featureDesc: "Integrated position function",
    label: "EF003075: Integrated position function"
  },
  {
    featureId: "EF003076",
    featureDesc: "According to IEC 1131-3 (DIN EN 61131-3)",
    label: "EF003076: According to IEC 1131-3 (DIN EN 61131-3)"
  },
  {
    featureId: "EF003077",
    featureDesc: "Number of slots",
    label: "EF003077: Number of slots"
  },
  {
    featureId: "EF003078",
    featureDesc: "With pluggable modules, digital I/O",
    label: "EF003078: With pluggable modules, digital I/O"
  },
  {
    featureId: "EF003079",
    featureDesc: "With pluggable modules, analogue I/O",
    label: "EF003079: With pluggable modules, analogue I/O"
  },
  {
    featureId: "EF003080",
    featureDesc: "With pluggable modules, communication modules",
    label: "EF003080: With pluggable modules, communication modules"
  },
  {
    featureId: "EF003081",
    featureDesc: "With pluggable modules, function and technology modules",
    label: "EF003081: With pluggable modules, function and technology modules"
  },
  {
    featureId: "EF003082",
    featureDesc: "With pluggable modules, central modules",
    label: "EF003082: With pluggable modules, central modules"
  },
  {
    featureId: "EF003083",
    featureDesc: "With pluggable modules, others",
    label: "EF003083: With pluggable modules, others"
  },
  {
    featureId: "EF003086",
    featureDesc: "Type of enclosure",
    label: "EF003086: Type of enclosure"
  },
  {
    featureId: "EF003087",
    featureDesc: "With integrated PCB-mounting connection system",
    label: "EF003087: With integrated PCB-mounting connection system"
  },
  {
    featureId: "EF003088",
    featureDesc: "Side-by-side housing",
    label: "EF003088: Side-by-side housing"
  },
  {
    featureId: "EF003092",
    featureDesc: "Output voltage stabilized",
    label: "EF003092: Output voltage stabilized"
  },
  {
    featureId: "EF003097",
    featureDesc: "Colour display",
    label: "EF003097: Colour display"
  },
  {
    featureId: "EF003098",
    featureDesc: "Number of colours of the display",
    label: "EF003098: Number of colours of the display"
  },
  {
    featureId: "EF003099",
    featureDesc: "Number of pixels, horizontal",
    label: "EF003099: Number of pixels, horizontal"
  },
  {
    featureId: "EF003100",
    featureDesc: "Number of pixels, vertical",
    label: "EF003100: Number of pixels, vertical"
  },
  {
    featureId: "EF003102",
    featureDesc: "With touch screen",
    label: "EF003102: With touch screen"
  },
  {
    featureId: "EF003103",
    featureDesc: "Number of function buttons, programmable",
    label: "EF003103: Number of function buttons, programmable"
  },
  {
    featureId: "EF003105",
    featureDesc: "With numeric keyboard",
    label: "EF003105: With numeric keyboard"
  },
  {
    featureId: "EF003106",
    featureDesc: "With alpha numeric keyboard",
    label: "EF003106: With alpha numeric keyboard"
  },
  {
    featureId: "EF003108",
    featureDesc: "Useful project memory/user memory",
    label: "EF003108: Useful project memory/user memory"
  },
  {
    featureId: "EF003109",
    featureDesc: "With message indication",
    label: "EF003109: With message indication"
  },
  {
    featureId: "EF003110",
    featureDesc: "With message system (incl. buffer and confirmation)",
    label: "EF003110: With message system (incl. buffer and confirmation)"
  },
  {
    featureId: "EF003111",
    featureDesc: "Process value representation (output) possible",
    label: "EF003111: Process value representation (output) possible"
  },
  {
    featureId: "EF003112",
    featureDesc: "Process default value (input) possible",
    label: "EF003112: Process default value (input) possible"
  },
  {
    featureId: "EF003113",
    featureDesc: "With recipes",
    label: "EF003113: With recipes"
  },
  {
    featureId: "EF003114",
    featureDesc: "Number of password levels",
    label: "EF003114: Number of password levels"
  },
  {
    featureId: "EF003115",
    featureDesc: "With printer output",
    label: "EF003115: With printer output"
  },
  {
    featureId: "EF003116",
    featureDesc: "Number of online languages",
    label: "EF003116: Number of online languages"
  },
  {
    featureId: "EF003117",
    featureDesc: "Additional software components, loadable",
    label: "EF003117: Additional software components, loadable"
  },
  {
    featureId: "EF003118",
    featureDesc: "Degree of protection (IP), front side",
    label: "EF003118: Degree of protection (IP), front side"
  },
  {
    featureId: "EF003121",
    featureDesc: "With SW interfaces",
    label: "EF003121: With SW interfaces"
  },
  {
    featureId: "EF003122",
    featureDesc: "Processor type",
    label: "EF003122: Processor type"
  },
  {
    featureId: "EF003123",
    featureDesc: "Max. main memory",
    label: "EF003123: Max. main memory"
  },
  {
    featureId: "EF003125",
    featureDesc: "With DVD-drive",
    label: "EF003125: With DVD-drive"
  },
  {
    featureId: "EF003128",
    featureDesc: "With floppy disc drive",
    label: "EF003128: With floppy disc drive"
  },
  {
    featureId: "EF003129",
    featureDesc: "With other storage media",
    label: "EF003129: With other storage media"
  },
  {
    featureId: "EF003130",
    featureDesc: "With graphic card",
    label: "EF003130: With graphic card"
  },
  {
    featureId: "EF003131",
    featureDesc: "With sound card",
    label: "EF003131: With sound card"
  },
  {
    featureId: "EF003132",
    featureDesc: "Customer individual configuration",
    label: "EF003132: Customer individual configuration"
  },
  {
    featureId: "EF003133",
    featureDesc: "Number of free AGP-slots",
    label: "EF003133: Number of free AGP-slots"
  },
  {
    featureId: "EF003134",
    featureDesc: "Number of free PCI-slots",
    label: "EF003134: Number of free PCI-slots"
  },
  {
    featureId: "EF003135",
    featureDesc: "Number of free ISA-slots",
    label: "EF003135: Number of free ISA-slots"
  },
  {
    featureId: "EF003136",
    featureDesc: "Number of free PCMCIA-slots",
    label: "EF003136: Number of free PCMCIA-slots"
  },
  {
    featureId: "EF003137",
    featureDesc: "Number of other free slots",
    label: "EF003137: Number of other free slots"
  },
  {
    featureId: "EF003138",
    featureDesc: "Number of HW-interfaces SCSI",
    label: "EF003138: Number of HW-interfaces SCSI"
  },
  {
    featureId: "EF003139",
    featureDesc: "Number of HW-interfaces PS2",
    label: "EF003139: Number of HW-interfaces PS2"
  },
  {
    featureId: "EF003141",
    featureDesc: "Integrated keyboard",
    label: "EF003141: Integrated keyboard"
  },
  {
    featureId: "EF003142",
    featureDesc: "Number of function buttons",
    label: "EF003142: Number of function buttons"
  },
  {
    featureId: "EF003143",
    featureDesc: "Mouse-cursor control integrated",
    label: "EF003143: Mouse-cursor control integrated"
  },
  {
    featureId: "EF003144",
    featureDesc: "Number of buttons",
    label: "EF003144: Number of buttons"
  },
  {
    featureId: "EF003145",
    featureDesc: "With parameterizable buttons",
    label: "EF003145: With parameterizable buttons"
  },
  {
    featureId: "EF003146",
    featureDesc: "Number of indicator lamps",
    label: "EF003146: Number of indicator lamps"
  },
  {
    featureId: "EF003147",
    featureDesc: "With test for buttons and indicator lamps",
    label: "EF003147: With test for buttons and indicator lamps"
  },
  {
    featureId: "EF003148",
    featureDesc: "Number of built-in positions for control circuit devices",
    label: "EF003148: Number of built-in positions for control circuit devices"
  },
  {
    featureId: "EF003151",
    featureDesc: "Industry- and technological expansions",
    label: "EF003151: Industry- and technological expansions"
  },
  {
    featureId: "EF003152",
    featureDesc: "Multi-user capable, runtime",
    label: "EF003152: Multi-user capable, runtime"
  },
  {
    featureId: "EF003153",
    featureDesc: "Max. number of servers",
    label: "EF003153: Max. number of servers"
  },
  {
    featureId: "EF003154",
    featureDesc: "Max. number of clients",
    label: "EF003154: Max. number of clients"
  },
  {
    featureId: "EF003155",
    featureDesc: "Suitable for web-clients",
    label: "EF003155: Suitable for web-clients"
  },
  {
    featureId: "EF003156",
    featureDesc: "Redundancy",
    label: "EF003156: Redundancy"
  },
  {
    featureId: "EF003157",
    featureDesc: "Max. number of multi-users configuration",
    label: "EF003157: Max. number of multi-users configuration"
  },
  {
    featureId: "EF003158",
    featureDesc: "Online projecting possible",
    label: "EF003158: Online projecting possible"
  },
  {
    featureId: "EF003159",
    featureDesc: "Integrable in the engineering software o.t autom.system",
    label: "EF003159: Integrable in the engineering software o.t autom.system"
  },
  {
    featureId: "EF003160",
    featureDesc: "Number of online/runtime languages",
    label: "EF003160: Number of online/runtime languages"
  },
  {
    featureId: "EF003161",
    featureDesc: "Number of configuration-/runtime languages",
    label: "EF003161: Number of configuration-/runtime languages"
  },
  {
    featureId: "EF003162",
    featureDesc: "Number of configuration-/desktop languages",
    label: "EF003162: Number of configuration-/desktop languages"
  },
  {
    featureId: "EF003163",
    featureDesc: "With script language Visual Basic",
    label: "EF003163: With script language Visual Basic"
  },
  {
    featureId: "EF003164",
    featureDesc: "With other script language",
    label: "EF003164: With other script language"
  },
  {
    featureId: "EF003165",
    featureDesc: "Library with object elements",
    label: "EF003165: Library with object elements"
  },
  {
    featureId: "EF003166",
    featureDesc: "User groups (password protection) possible",
    label: "EF003166: User groups (password protection) possible"
  },
  {
    featureId: "EF003167",
    featureDesc: "Message system",
    label: "EF003167: Message system"
  },
  {
    featureId: "EF003168",
    featureDesc: "User archive/recipes management",
    label: "EF003168: User archive/recipes management"
  },
  {
    featureId: "EF003169",
    featureDesc: "Reporting system/protocol system",
    label: "EF003169: Reporting system/protocol system"
  },
  {
    featureId: "EF003170",
    featureDesc: "Function process diagnostics",
    label: "EF003170: Function process diagnostics"
  },
  {
    featureId: "EF003171",
    featureDesc: "SQL-database",
    label: "EF003171: SQL-database"
  },
  {
    featureId: "EF003172",
    featureDesc: "With OPC-interface, server",
    label: "EF003172: With OPC-interface, server"
  },
  {
    featureId: "EF003173",
    featureDesc: "With OPC-interface, client",
    label: "EF003173: With OPC-interface, client"
  },
  {
    featureId: "EF003174",
    featureDesc: "With programming interfaces",
    label: "EF003174: With programming interfaces"
  },
  {
    featureId: "EF003175",
    featureDesc: "Min. required processor speed for runtime system",
    label: "EF003175: Min. required processor speed for runtime system"
  },
  {
    featureId: "EF003176",
    featureDesc: "Min. required main memory for runtime system",
    label: "EF003176: Min. required main memory for runtime system"
  },
  {
    featureId: "EF003177",
    featureDesc: "Runtime operating system, Windows XP",
    label: "EF003177: Runtime operating system, Windows XP"
  },
  {
    featureId: "EF003178",
    featureDesc: "Runtime operating system, Windows 2000",
    label: "EF003178: Runtime operating system, Windows 2000"
  },
  {
    featureId: "EF003179",
    featureDesc: "Runtime operating system, Linux",
    label: "EF003179: Runtime operating system, Linux"
  },
  {
    featureId: "EF003180",
    featureDesc: "Runtime operating system, other",
    label: "EF003180: Runtime operating system, other"
  },
  {
    featureId: "EF003181",
    featureDesc: "Min. required processor speed for configuration software",
    label: "EF003181: Min. required processor speed for configuration software"
  },
  {
    featureId: "EF003182",
    featureDesc: "Min. required main memory for configuration software",
    label: "EF003182: Min. required main memory for configuration software"
  },
  {
    featureId: "EF003183",
    featureDesc: "Loadable for operating system for configuration software, Windows 9x",
    label: "EF003183: Loadable for operating system for configuration software, Windows 9x"
  },
  {
    featureId: "EF003184",
    featureDesc: "Max. number of subscribers",
    label: "EF003184: Max. number of subscribers"
  },
  {
    featureId: "EF003185",
    featureDesc: "Type of receiver",
    label: "EF003185: Type of receiver"
  },
  {
    featureId: "EF003186",
    featureDesc: "Loadable for operating system for configuration software, Windows NT",
    label: "EF003186: Loadable for operating system for configuration software, Windows NT"
  },
  {
    featureId: "EF003187",
    featureDesc: "Loadable for operating system for configuration software, Windows 2000",
    label: "EF003187: Loadable for operating system for configuration software, Windows 2000"
  },
  {
    featureId: "EF003188",
    featureDesc: "Loadable for operating system for configuration software, Windows XP",
    label: "EF003188: Loadable for operating system for configuration software, Windows XP"
  },
  {
    featureId: "EF003189",
    featureDesc: "Loadable for operating system for configuration software, Windows ME",
    label: "EF003189: Loadable for operating system for configuration software, Windows ME"
  },
  {
    featureId: "EF003190",
    featureDesc: "Loadable for operating system for configuration software, Linux",
    label: "EF003190: Loadable for operating system for configuration software, Linux"
  },
  {
    featureId: "EF003192",
    featureDesc: "Level adjuster",
    label: "EF003192: Level adjuster"
  },
  {
    featureId: "EF003193",
    featureDesc: "Equalizer",
    label: "EF003193: Equalizer"
  },
  {
    featureId: "EF003194",
    featureDesc: "Gain (satellite)",
    label: "EF003194: Gain (satellite)"
  },
  {
    featureId: "EF003195",
    featureDesc: "Gain (terrestrial)",
    label: "EF003195: Gain (terrestrial)"
  },
  {
    featureId: "EF003196",
    featureDesc: "Return path channel (CATV)",
    label: "EF003196: Return path channel (CATV)"
  },
  {
    featureId: "EF003197",
    featureDesc: "22-kHz generator",
    label: "EF003197: 22-kHz generator"
  },
  {
    featureId: "EF003198",
    featureDesc: "With relay output",
    label: "EF003198: With relay output"
  },
  {
    featureId: "EF003199",
    featureDesc: "With backup memory (pluggable in handheld device)",
    label: "EF003199: With backup memory (pluggable in handheld device)"
  },
  {
    featureId: "EF003200",
    featureDesc: "PC-compatible",
    label: "EF003200: PC-compatible"
  },
  {
    featureId: "EF003201",
    featureDesc: "Type of output",
    label: "EF003201: Type of output"
  },
  {
    featureId: "EF003202",
    featureDesc: "Output current",
    label: "EF003202: Output current"
  },
  {
    featureId: "EF003203",
    featureDesc: "Short-circuit protection, outputs available",
    label: "EF003203: Short-circuit protection, outputs available"
  },
  {
    featureId: "EF003204",
    featureDesc: "Input, current",
    label: "EF003204: Input, current"
  },
  {
    featureId: "EF003205",
    featureDesc: "Input, voltage",
    label: "EF003205: Input, voltage"
  },
  {
    featureId: "EF003206",
    featureDesc: "Input, resistor",
    label: "EF003206: Input, resistor"
  },
  {
    featureId: "EF003207",
    featureDesc: "Input, resistance thermometer",
    label: "EF003207: Input, resistance thermometer"
  },
  {
    featureId: "EF003208",
    featureDesc: "Input, thermocouple",
    label: "EF003208: Input, thermocouple"
  },
  {
    featureId: "EF003209",
    featureDesc: "Input signal, configurable",
    label: "EF003209: Input signal, configurable"
  },
  {
    featureId: "EF003212",
    featureDesc: "Output, current",
    label: "EF003212: Output, current"
  },
  {
    featureId: "EF003213",
    featureDesc: "Output, voltage",
    label: "EF003213: Output, voltage"
  },
  {
    featureId: "EF003214",
    featureDesc: "Output signal configurable",
    label: "EF003214: Output signal configurable"
  },
  {
    featureId: "EF003215",
    featureDesc: "Output voltage at digital output",
    label: "EF003215: Output voltage at digital output"
  },
  {
    featureId: "EF003218",
    featureDesc: "Output current at digital output",
    label: "EF003218: Output current at digital output"
  },
  {
    featureId: "EF003219",
    featureDesc: "Analogue input, current",
    label: "EF003219: Analogue input, current"
  },
  {
    featureId: "EF003220",
    featureDesc: "Analogue audio output",
    label: "EF003220: Analogue audio output"
  },
  {
    featureId: "EF003221",
    featureDesc: "Analogue input, voltage",
    label: "EF003221: Analogue input, voltage"
  },
  {
    featureId: "EF003222",
    featureDesc: "Analogue input, resistor",
    label: "EF003222: Analogue input, resistor"
  },
  {
    featureId: "EF003223",
    featureDesc: "Digital audio output, coaxial",
    label: "EF003223: Digital audio output, coaxial"
  },
  {
    featureId: "EF003224",
    featureDesc: "Analogue input, resistor temperature",
    label: "EF003224: Analogue input, resistor temperature"
  },
  {
    featureId: "EF003225",
    featureDesc: "Analogue input, thermocouple",
    label: "EF003225: Analogue input, thermocouple"
  },
  {
    featureId: "EF003226",
    featureDesc: "Digital audio output, optical",
    label: "EF003226: Digital audio output, optical"
  },
  {
    featureId: "EF003227",
    featureDesc: "Analogue input signal configurable",
    label: "EF003227: Analogue input signal configurable"
  },
  {
    featureId: "EF003232",
    featureDesc: "Child-protection",
    label: "EF003232: Child-protection"
  },
  {
    featureId: "EF003234",
    featureDesc: "Analogue output, current",
    label: "EF003234: Analogue output, current"
  },
  {
    featureId: "EF003235",
    featureDesc: "Analogue output, voltage",
    label: "EF003235: Analogue output, voltage"
  },
  {
    featureId: "EF003236",
    featureDesc: "Analogue output signal configurable",
    label: "EF003236: Analogue output signal configurable"
  },
  {
    featureId: "EF003239",
    featureDesc: "Conditional Access (CA)-embedded",
    label: "EF003239: Conditional Access (CA)-embedded"
  },
  {
    featureId: "EF003242",
    featureDesc: "Software update",
    label: "EF003242: Software update"
  },
  {
    featureId: "EF003244",
    featureDesc: "Number of functions",
    label: "EF003244: Number of functions"
  },
  {
    featureId: "EF003248",
    featureDesc: "Suitable for counting",
    label: "EF003248: Suitable for counting"
  },
  {
    featureId: "EF003249",
    featureDesc: "Suitable for weighing",
    label: "EF003249: Suitable for weighing"
  },
  {
    featureId: "EF003251",
    featureDesc: "Suitable for welding control",
    label: "EF003251: Suitable for welding control"
  },
  {
    featureId: "EF003252",
    featureDesc: "Suitable for pressure control",
    label: "EF003252: Suitable for pressure control"
  },
  {
    featureId: "EF003253",
    featureDesc: "Suitable for NC",
    label: "EF003253: Suitable for NC"
  },
  {
    featureId: "EF003254",
    featureDesc: "Suitable for SSI",
    label: "EF003254: Suitable for SSI"
  },
  {
    featureId: "EF003255",
    featureDesc: "Suitable for electronic positioning",
    label: "EF003255: Suitable for electronic positioning"
  },
  {
    featureId: "EF003256",
    featureDesc: "Suitable for CNC",
    label: "EF003256: Suitable for CNC"
  },
  {
    featureId: "EF003258",
    featureDesc: "Suitable for incremental data detection",
    label: "EF003258: Suitable for incremental data detection"
  },
  {
    featureId: "EF003259",
    featureDesc: "Suitable for detection absolute value",
    label: "EF003259: Suitable for detection absolute value"
  },
  {
    featureId: "EF003260",
    featureDesc: "Suitable for flux controller",
    label: "EF003260: Suitable for flux controller"
  },
  {
    featureId: "EF003261",
    featureDesc: "Suitable for flux measurement",
    label: "EF003261: Suitable for flux measurement"
  },
  {
    featureId: "EF003262",
    featureDesc: "Suitable for path controller",
    label: "EF003262: Suitable for path controller"
  },
  {
    featureId: "EF003263",
    featureDesc: "Suitable for cam controller",
    label: "EF003263: Suitable for cam controller"
  },
  {
    featureId: "EF003264",
    featureDesc: "Suitable for flying saw",
    label: "EF003264: Suitable for flying saw"
  },
  {
    featureId: "EF003265",
    featureDesc: "Suitable for multi-axis control",
    label: "EF003265: Suitable for multi-axis control"
  },
  {
    featureId: "EF003266",
    featureDesc: "Suitable for single-axis controller",
    label: "EF003266: Suitable for single-axis controller"
  },
  {
    featureId: "EF003267",
    featureDesc: "Suitable for multi-axis positioning",
    label: "EF003267: Suitable for multi-axis positioning"
  },
  {
    featureId: "EF003268",
    featureDesc: "Suitable for single-axis positioning",
    label: "EF003268: Suitable for single-axis positioning"
  },
  {
    featureId: "EF003271",
    featureDesc: "Transmission rate",
    label: "EF003271: Transmission rate"
  },
  {
    featureId: "EF003272",
    featureDesc: "With potential separation",
    label: "EF003272: With potential separation"
  },
  {
    featureId: "EF003286",
    featureDesc: "Type of software interface",
    label: "EF003286: Type of software interface"
  },
  {
    featureId: "EF003287",
    featureDesc: "Switching current for digital outputs",
    label: "EF003287: Switching current for digital outputs"
  },
  {
    featureId: "EF003288",
    featureDesc: "Starting performance according to IEC 1131 (DIN EN 61131)",
    label: "EF003288: Starting performance according to IEC 1131 (DIN EN 61131)"
  },
  {
    featureId: "EF003290",
    featureDesc: "Slot length",
    label: "EF003290: Slot length"
  },
  {
    featureId: "EF003291",
    featureDesc: "Slot width",
    label: "EF003291: Slot width"
  },
  {
    featureId: "EF003292",
    featureDesc: "External power supply required",
    label: "EF003292: External power supply required"
  },
  {
    featureId: "EF003293",
    featureDesc: "Number of subscribers",
    label: "EF003293: Number of subscribers"
  },
  {
    featureId: "EF003294",
    featureDesc: "Terrestrial input",
    label: "EF003294: Terrestrial input"
  },
  {
    featureId: "EF003295",
    featureDesc: "Number of trunk line outputs",
    label: "EF003295: Number of trunk line outputs"
  },
  {
    featureId: "EF003297",
    featureDesc: "Number of display lines",
    label: "EF003297: Number of display lines"
  },
  {
    featureId: "EF003298",
    featureDesc: "Number of display characters per line",
    label: "EF003298: Number of display characters per line"
  },
  {
    featureId: "EF003299",
    featureDesc: "Max. character height, display",
    label: "EF003299: Max. character height, display"
  },
  {
    featureId: "EF003300",
    featureDesc: "Graphic objects presentable",
    label: "EF003300: Graphic objects presentable"
  },
  {
    featureId: "EF003301",
    featureDesc: "With enabling button/emergency-stop switch",
    label: "EF003301: With enabling button/emergency-stop switch"
  },
  {
    featureId: "EF003302",
    featureDesc: "Return path channel boost",
    label: "EF003302: Return path channel boost"
  },
  {
    featureId: "EF003303",
    featureDesc: "Gain VHF",
    label: "EF003303: Gain VHF"
  },
  {
    featureId: "EF003304",
    featureDesc: "Gain UHF",
    label: "EF003304: Gain UHF"
  },
  {
    featureId: "EF003305",
    featureDesc: "Connection, pluggable",
    label: "EF003305: Connection, pluggable"
  },
  {
    featureId: "EF003306",
    featureDesc: "Max. fall height according to IEC 60068-2-32",
    label: "EF003306: Max. fall height according to IEC 60068-2-32"
  },
  {
    featureId: "EF003312",
    featureDesc: "Horizontal frequency",
    label: "EF003312: Horizontal frequency"
  },
  {
    featureId: "EF003313",
    featureDesc: "Frame frequency",
    label: "EF003313: Frame frequency"
  },
  {
    featureId: "EF003314",
    featureDesc: "With On Screen Display",
    label: "EF003314: With On Screen Display"
  },
  {
    featureId: "EF003315",
    featureDesc: "With HW-interface video signal, analogue",
    label: "EF003315: With HW-interface video signal, analogue"
  },
  {
    featureId: "EF003316",
    featureDesc: "With HW-interface digital video signal",
    label: "EF003316: With HW-interface digital video signal"
  },
  {
    featureId: "EF003317",
    featureDesc: "With HW-interface USB",
    label: "EF003317: With HW-interface USB"
  },
  {
    featureId: "EF003318",
    featureDesc: "With HW-interface PS2",
    label: "EF003318: With HW-interface PS2"
  },
  {
    featureId: "EF003320",
    featureDesc: "Short-circuit protection, digital outputs available",
    label: "EF003320: Short-circuit protection, digital outputs available"
  },
  {
    featureId: "EF003322",
    featureDesc: "Evaluation function",
    label: "EF003322: Evaluation function"
  },
  {
    featureId: "EF003324",
    featureDesc: "With data detection function",
    label: "EF003324: With data detection function"
  },
  {
    featureId: "EF003328",
    featureDesc: "Maintenance function integrated",
    label: "EF003328: Maintenance function integrated"
  },
  {
    featureId: "EF003333",
    featureDesc: "Multi-lingual",
    label: "EF003333: Multi-lingual"
  },
  {
    featureId: "EF003334",
    featureDesc: "Suitable for target system PLC",
    label: "EF003334: Suitable for target system PLC"
  },
  {
    featureId: "EF003335",
    featureDesc: "Suitable for target system PC",
    label: "EF003335: Suitable for target system PC"
  },
  {
    featureId: "EF003336",
    featureDesc: "Suitable for target system HMI",
    label: "EF003336: Suitable for target system HMI"
  },
  {
    featureId: "EF003338",
    featureDesc: "With integrated power supply",
    label: "EF003338: With integrated power supply"
  },
  {
    featureId: "EF003339",
    featureDesc: "Number of LED colour modes",
    label: "EF003339: Number of LED colour modes"
  },
  {
    featureId: "EF003340",
    featureDesc: "With detachable clamps",
    label: "EF003340: With detachable clamps"
  },
  {
    featureId: "EF003341",
    featureDesc: "Inputs",
    label: "EF003341: Inputs"
  },
  {
    featureId: "EF003342",
    featureDesc: "With start input",
    label: "EF003342: With start input"
  },
  {
    featureId: "EF003343",
    featureDesc: "With feedback circuit",
    label: "EF003343: With feedback circuit"
  },
  {
    featureId: "EF003344",
    featureDesc: "Number of outputs, safety related, undelayed, with contact",
    label: "EF003344: Number of outputs, safety related, undelayed, with contact"
  },
  {
    featureId: "EF003345",
    featureDesc: "Number of outputs, safety related, delayed, with contact",
    label: "EF003345: Number of outputs, safety related, delayed, with contact"
  },
  {
    featureId: "EF003346",
    featureDesc: "Number of outputs, safety related, undelayed, semiconductors",
    label: "EF003346: Number of outputs, safety related, undelayed, semiconductors"
  },
  {
    featureId: "EF003347",
    featureDesc: "Number of outputs, safety related, delayed, semiconductors",
    label: "EF003347: Number of outputs, safety related, delayed, semiconductors"
  },
  {
    featureId: "EF003348",
    featureDesc: "Number of outputs, signalling function, undelayed, with contact",
    label: "EF003348: Number of outputs, signalling function, undelayed, with contact"
  },
  {
    featureId: "EF003349",
    featureDesc: "Number of outputs, signalling function, delayed, with contact",
    label: "EF003349: Number of outputs, signalling function, delayed, with contact"
  },
  {
    featureId: "EF003350",
    featureDesc: "Number of outputs, signalling function, undelayed, semiconductors",
    label: "EF003350: Number of outputs, signalling function, undelayed, semiconductors"
  },
  {
    featureId: "EF003351",
    featureDesc: "Number of outputs, signalling function, delayed, semiconductors",
    label: "EF003351: Number of outputs, signalling function, delayed, semiconductors"
  },
  {
    featureId: "EF003355",
    featureDesc: "Drive, polarization",
    label: "EF003355: Drive, polarization"
  },
  {
    featureId: "EF003356",
    featureDesc: "Drive, switching attitude",
    label: "EF003356: Drive, switching attitude"
  },
  {
    featureId: "EF003357",
    featureDesc: "With forced-guided contacts",
    label: "EF003357: With forced-guided contacts"
  },
  {
    featureId: "EF003358",
    featureDesc: "Type of switch contact",
    label: "EF003358: Type of switch contact"
  },
  {
    featureId: "EF003359",
    featureDesc: "Complete with socket",
    label: "EF003359: Complete with socket"
  },
  {
    featureId: "EF003361",
    featureDesc: "Remote operation possible",
    label: "EF003361: Remote operation possible"
  },
  {
    featureId: "EF003362",
    featureDesc: "Suitable as remote control",
    label: "EF003362: Suitable as remote control"
  },
  {
    featureId: "EF003363",
    featureDesc: "Function flashing, starting with pause, fixed time",
    label: "EF003363: Function flashing, starting with pause, fixed time"
  },
  {
    featureId: "EF003364",
    featureDesc: "Function flashing, starting with pulse, fixed time",
    label: "EF003364: Function flashing, starting with pulse, fixed time"
  },
  {
    featureId: "EF003365",
    featureDesc: "Clock function, starting with pause, variable",
    label: "EF003365: Clock function, starting with pause, variable"
  },
  {
    featureId: "EF003366",
    featureDesc: "Clock function, starting with pulse, variable",
    label: "EF003366: Clock function, starting with pulse, variable"
  },
  {
    featureId: "EF003373",
    featureDesc: "With semiconductor output",
    label: "EF003373: With semiconductor output"
  },
  {
    featureId: "EF003374",
    featureDesc: "Function delay-on energization",
    label: "EF003374: Function delay-on energization"
  },
  {
    featureId: "EF003378",
    featureDesc: "Function floating contact on energization",
    label: "EF003378: Function floating contact on energization"
  },
  {
    featureId: "EF003380",
    featureDesc: "Function star-delta",
    label: "EF003380: Function star-delta"
  },
  {
    featureId: "EF003384",
    featureDesc: "Time range",
    label: "EF003384: Time range"
  },
  {
    featureId: "EF003390",
    featureDesc: "Single-phase under voltage possible",
    label: "EF003390: Single-phase under voltage possible"
  },
  {
    featureId: "EF003391",
    featureDesc: "Three-phase under voltage possible",
    label: "EF003391: Three-phase under voltage possible"
  },
  {
    featureId: "EF003392",
    featureDesc: "Single-phase over voltage possible",
    label: "EF003392: Single-phase over voltage possible"
  },
  {
    featureId: "EF003393",
    featureDesc: "Three-phase over voltage possible",
    label: "EF003393: Three-phase over voltage possible"
  },
  {
    featureId: "EF003394",
    featureDesc: "Single-phase hysteresis possible",
    label: "EF003394: Single-phase hysteresis possible"
  },
  {
    featureId: "EF003395",
    featureDesc: "Three-phase hysteresis possible",
    label: "EF003395: Three-phase hysteresis possible"
  },
  {
    featureId: "EF003396",
    featureDesc: "Contains function DC-under voltage",
    label: "EF003396: Contains function DC-under voltage"
  },
  {
    featureId: "EF003397",
    featureDesc: "Contains function DC-over voltage",
    label: "EF003397: Contains function DC-over voltage"
  },
  {
    featureId: "EF003398",
    featureDesc: "Contains function DC-voltage hysteresis",
    label: "EF003398: Contains function DC-voltage hysteresis"
  },
  {
    featureId: "EF003399",
    featureDesc: "Type of voltage measuring range",
    label: "EF003399: Type of voltage measuring range"
  },
  {
    featureId: "EF003401",
    featureDesc: "Function delay on de-energization",
    label: "EF003401: Function delay on de-energization"
  },
  {
    featureId: "EF003402",
    featureDesc: "Function floating contact on de-energization",
    label: "EF003402: Function floating contact on de-energization"
  },
  {
    featureId: "EF003403",
    featureDesc: "Function pulse shaping",
    label: "EF003403: Function pulse shaping"
  },
  {
    featureId: "EF003405",
    featureDesc: "Single-phase under current possible",
    label: "EF003405: Single-phase under current possible"
  },
  {
    featureId: "EF003406",
    featureDesc: "Three-phase under current possible",
    label: "EF003406: Three-phase under current possible"
  },
  {
    featureId: "EF003407",
    featureDesc: "Single-phase overcurrent possible",
    label: "EF003407: Single-phase overcurrent possible"
  },
  {
    featureId: "EF003408",
    featureDesc: "Three-phase overcurrent possible",
    label: "EF003408: Three-phase overcurrent possible"
  },
  {
    featureId: "EF003409",
    featureDesc: "Contains function DC-voltage under current",
    label: "EF003409: Contains function DC-voltage under current"
  },
  {
    featureId: "EF003410",
    featureDesc: "Contains function DC-voltage overcurrent",
    label: "EF003410: Contains function DC-voltage overcurrent"
  },
  {
    featureId: "EF003411",
    featureDesc: "Function DC-current hysteresis",
    label: "EF003411: Function DC-current hysteresis"
  },
  {
    featureId: "EF003412",
    featureDesc: "Phase sequence monitoring",
    label: "EF003412: Phase sequence monitoring"
  },
  {
    featureId: "EF003413",
    featureDesc: "Phase failure detection",
    label: "EF003413: Phase failure detection"
  },
  {
    featureId: "EF003414",
    featureDesc: "Function under voltage detection",
    label: "EF003414: Function under voltage detection"
  },
  {
    featureId: "EF003415",
    featureDesc: "Function over voltage detection",
    label: "EF003415: Function over voltage detection"
  },
  {
    featureId: "EF003416",
    featureDesc: "Phase imbalance monitoring",
    label: "EF003416: Phase imbalance monitoring"
  },
  {
    featureId: "EF003417",
    featureDesc: "Response value for frequency",
    label: "EF003417: Response value for frequency"
  },
  {
    featureId: "EF003418",
    featureDesc: "Adjustable setting value for Cos-Phi",
    label: "EF003418: Adjustable setting value for Cos-Phi"
  },
  {
    featureId: "EF003419",
    featureDesc: "Line capacity",
    label: "EF003419: Line capacity"
  },
  {
    featureId: "EF003422",
    featureDesc: "Indication of insulation value",
    label: "EF003422: Indication of insulation value"
  },
  {
    featureId: "EF003423",
    featureDesc: "Error registration possible",
    label: "EF003423: Error registration possible"
  },
  {
    featureId: "EF003424",
    featureDesc: "Indication of difference current",
    label: "EF003424: Indication of difference current"
  },
  {
    featureId: "EF003425",
    featureDesc: "Number of measuring circuits",
    label: "EF003425: Number of measuring circuits"
  },
  {
    featureId: "EF003426",
    featureDesc: "External reset possible",
    label: "EF003426: External reset possible"
  },
  {
    featureId: "EF003427",
    featureDesc: "Number of inputs for electrodes",
    label: "EF003427: Number of inputs for electrodes"
  },
  {
    featureId: "EF003429",
    featureDesc: "Response value sensitivity adjustable",
    label: "EF003429: Response value sensitivity adjustable"
  },
  {
    featureId: "EF003430",
    featureDesc: "Function rotational speed monitoring",
    label: "EF003430: Function rotational speed monitoring"
  },
  {
    featureId: "EF003431",
    featureDesc: "Function standstill monitoring",
    label: "EF003431: Function standstill monitoring"
  },
  {
    featureId: "EF003433",
    featureDesc: "Cable construction",
    label: "EF003433: Cable construction"
  },
  {
    featureId: "EF003435",
    featureDesc: "Type class for safety demands according to EN 574",
    label: "EF003435: Type class for safety demands according to EN 574"
  },
  {
    featureId: "EF003444",
    featureDesc: "Function extension possible",
    label: "EF003444: Function extension possible"
  },
  {
    featureId: "EF003447",
    featureDesc: "Number of connectors front side",
    label: "EF003447: Number of connectors front side"
  },
  {
    featureId: "EF003451",
    featureDesc: "Mechanical",
    label: "EF003451: Mechanical"
  },
  {
    featureId: "EF003452",
    featureDesc: "Optical",
    label: "EF003452: Optical"
  },
  {
    featureId: "EF003453",
    featureDesc: "Surface-mounted",
    label: "EF003453: Surface-mounted"
  },
  {
    featureId: "EF003454",
    featureDesc: "Built-in",
    label: "EF003454: Built-in"
  },
  {
    featureId: "EF003455",
    featureDesc: "Floor box/subfloor mounted",
    label: "EF003455: Floor box/subfloor mounted"
  },
  {
    featureId: "EF003456",
    featureDesc: "With label space",
    label: "EF003456: With label space"
  },
  {
    featureId: "EF003460",
    featureDesc: "Conductor cross section flexible (fine-strand) with cable end sleeve",
    label: "EF003460: Conductor cross section flexible (fine-strand) with cable end sleeve"
  },
  {
    featureId: "EF003462",
    featureDesc: "Conductor cross section solid (solid, stranded)",
    label: "EF003462: Conductor cross section solid (solid, stranded)"
  },
  {
    featureId: "EF003469",
    featureDesc: "Suitable for motor drive",
    label: "EF003469: Suitable for motor drive"
  },
  {
    featureId: "EF003473",
    featureDesc: "Connecting diagram",
    label: "EF003473: Connecting diagram"
  },
  {
    featureId: "EF003474",
    featureDesc: "Option list",
    label: "EF003474: Option list"
  },
  {
    featureId: "EF003475",
    featureDesc: "Supporting document",
    label: "EF003475: Supporting document"
  },
  {
    featureId: "EF003477",
    featureDesc: "Databank",
    label: "EF003477: Databank"
  },
  {
    featureId: "EF003478",
    featureDesc: "Documentation",
    label: "EF003478: Documentation"
  },
  {
    featureId: "EF003479",
    featureDesc: "Spare parts list",
    label: "EF003479: Spare parts list"
  },
  {
    featureId: "EF003480",
    featureDesc: "Flyer",
    label: "EF003480: Flyer"
  },
  {
    featureId: "EF003481",
    featureDesc: "Operating instructions",
    label: "EF003481: Operating instructions"
  },
  {
    featureId: "EF003483",
    featureDesc: "Catalogue",
    label: "EF003483: Catalogue"
  },
  {
    featureId: "EF003485",
    featureDesc: "Panorama, summary",
    label: "EF003485: Panorama, summary"
  },
  {
    featureId: "EF003486",
    featureDesc: "Price list",
    label: "EF003486: Price list"
  },
  {
    featureId: "EF003487",
    featureDesc: "Repair manual",
    label: "EF003487: Repair manual"
  },
  {
    featureId: "EF003488",
    featureDesc: "Circuit diagram",
    label: "EF003488: Circuit diagram"
  },
  {
    featureId: "EF003489",
    featureDesc: "Service manual",
    label: "EF003489: Service manual"
  },
  {
    featureId: "EF003490",
    featureDesc: "Other documentation",
    label: "EF003490: Other documentation"
  },
  {
    featureId: "EF003491",
    featureDesc: "Alignment",
    label: "EF003491: Alignment"
  },
  {
    featureId: "EF003496",
    featureDesc: "Type of switch",
    label: "EF003496: Type of switch"
  },
  {
    featureId: "EF003497",
    featureDesc: "Type of socket outlet",
    label: "EF003497: Type of socket outlet"
  },
  {
    featureId: "EF003498",
    featureDesc: "Max. transmission loss",
    label: "EF003498: Max. transmission loss"
  },
  {
    featureId: "EF003499",
    featureDesc: "Tap loss at 2150 MHz",
    label: "EF003499: Tap loss at 2150 MHz"
  },
  {
    featureId: "EF003500",
    featureDesc: "Through loss at 2150 MHz",
    label: "EF003500: Through loss at 2150 MHz"
  },
  {
    featureId: "EF003501",
    featureDesc: "With discharging function",
    label: "EF003501: With discharging function"
  },
  {
    featureId: "EF003514",
    featureDesc: "EMC strain relief",
    label: "EF003514: EMC strain relief"
  },
  {
    featureId: "EF003520",
    featureDesc: "Cross section of the connecting conductor",
    label: "EF003520: Cross section of the connecting conductor"
  },
  {
    featureId: "EF003523",
    featureDesc: "Number of strands",
    label: "EF003523: Number of strands"
  },
  {
    featureId: "EF003525",
    featureDesc: "Voltage protection level",
    label: "EF003525: Voltage protection level"
  },
  {
    featureId: "EF003531",
    featureDesc: "Number of auxiliary contacts as change-over contact",
    label: "EF003531: Number of auxiliary contacts as change-over contact"
  },
  {
    featureId: "EF003532",
    featureDesc: "Suitable for outdoor use",
    label: "EF003532: Suitable for outdoor use"
  },
  {
    featureId: "EF003533",
    featureDesc: "Metal-free",
    label: "EF003533: Metal-free"
  },
  {
    featureId: "EF003535",
    featureDesc: "Ethernet",
    label: "EF003535: Ethernet"
  },
  {
    featureId: "EF003536",
    featureDesc: "Fast Ethernet",
    label: "EF003536: Fast Ethernet"
  },
  {
    featureId: "EF003537",
    featureDesc: "ISDN",
    label: "EF003537: ISDN"
  },
  {
    featureId: "EF003538",
    featureDesc: "xDSL",
    label: "EF003538: xDSL"
  },
  {
    featureId: "EF003544",
    featureDesc: "Number of ports LAN",
    label: "EF003544: Number of ports LAN"
  },
  {
    featureId: "EF003545",
    featureDesc: "Number of ports WAN",
    label: "EF003545: Number of ports WAN"
  },
  {
    featureId: "EF003546",
    featureDesc: "Gigabit Ethernet",
    label: "EF003546: Gigabit Ethernet"
  },
  {
    featureId: "EF003547",
    featureDesc: "Number of ports 10 Mbps RJ45",
    label: "EF003547: Number of ports 10 Mbps RJ45"
  },
  {
    featureId: "EF003548",
    featureDesc: "Number of ports 10 Mbps VF-45",
    label: "EF003548: Number of ports 10 Mbps VF-45"
  },
  {
    featureId: "EF003549",
    featureDesc: "Number of ports 10/100 Mbps RJ45",
    label: "EF003549: Number of ports 10/100 Mbps RJ45"
  },
  {
    featureId: "EF003550",
    featureDesc: "Number of ports 100 Mbps VF-45",
    label: "EF003550: Number of ports 100 Mbps VF-45"
  },
  {
    featureId: "EF003551",
    featureDesc: "Number of ports 1000 Mbps SC (SX)",
    label: "EF003551: Number of ports 1000 Mbps SC (SX)"
  },
  {
    featureId: "EF003552",
    featureDesc: "Number of ports 1000 Mbps SC (LX)",
    label: "EF003552: Number of ports 1000 Mbps SC (LX)"
  },
  {
    featureId: "EF003553",
    featureDesc: "Number of ports GIBC (SX)",
    label: "EF003553: Number of ports GIBC (SX)"
  },
  {
    featureId: "EF003554",
    featureDesc: "Number of ports GIBC (LX)",
    label: "EF003554: Number of ports GIBC (LX)"
  },
  {
    featureId: "EF003555",
    featureDesc: "Suitable for 19 inch mounting",
    label: "EF003555: Suitable for 19 inch mounting"
  },
  {
    featureId: "EF003559",
    featureDesc: "Suitable for number of cores",
    label: "EF003559: Suitable for number of cores"
  },
  {
    featureId: "EF003561",
    featureDesc: "Number of height units",
    label: "EF003561: Number of height units"
  },
  {
    featureId: "EF003563",
    featureDesc: "Thread size (metric)",
    label: "EF003563: Thread size (metric)"
  },
  {
    featureId: "EF003566",
    featureDesc: "Suitable for bolt size (metric)",
    label: "EF003566: Suitable for bolt size (metric)"
  },
  {
    featureId: "EF003570",
    featureDesc: "Capacity coffee section (cups)",
    label: "EF003570: Capacity coffee section (cups)"
  },
  {
    featureId: "EF003575",
    featureDesc: "Resolution of the analogue inputs",
    label: "EF003575: Resolution of the analogue inputs"
  },
  {
    featureId: "EF003576",
    featureDesc: "Code length (Bit)",
    label: "EF003576: Code length (Bit)"
  },
  {
    featureId: "EF003577",
    featureDesc: "Resolution of the analogue outputs",
    label: "EF003577: Resolution of the analogue outputs"
  },
  {
    featureId: "EF003578",
    featureDesc: "Max. glue capacity",
    label: "EF003578: Max. glue capacity"
  },
  {
    featureId: "EF003579",
    featureDesc: "With controller",
    label: "EF003579: With controller"
  },
  {
    featureId: "EF003582",
    featureDesc: "With time function",
    label: "EF003582: With time function"
  },
  {
    featureId: "EF003583",
    featureDesc: "With reflector",
    label: "EF003583: With reflector"
  },
  {
    featureId: "EF003586",
    featureDesc: "Switching voltage",
    label: "EF003586: Switching voltage"
  },
  {
    featureId: "EF003587",
    featureDesc: "Switching frequency",
    label: "EF003587: Switching frequency"
  },
  {
    featureId: "EF003589",
    featureDesc: "Max. pulses per revolution",
    label: "EF003589: Max. pulses per revolution"
  },
  {
    featureId: "EF003590",
    featureDesc: "Signal outputs of rotary encoder",
    label: "EF003590: Signal outputs of rotary encoder"
  },
  {
    featureId: "EF003591",
    featureDesc: "Type of switching contact",
    label: "EF003591: Type of switching contact"
  },
  {
    featureId: "EF003593",
    featureDesc: "Number of inlets",
    label: "EF003593: Number of inlets"
  },
  {
    featureId: "EF003597",
    featureDesc: "Number of module slots",
    label: "EF003597: Number of module slots"
  },
  {
    featureId: "EF003598",
    featureDesc: "Number of max. stackable switches",
    label: "EF003598: Number of max. stackable switches"
  },
  {
    featureId: "EF003601",
    featureDesc: "Type of digital output",
    label: "EF003601: Type of digital output"
  },
  {
    featureId: "EF003602",
    featureDesc: "With contact",
    label: "EF003602: With contact"
  },
  {
    featureId: "EF003603",
    featureDesc: "Addressable",
    label: "EF003603: Addressable"
  },
  {
    featureId: "EF003604",
    featureDesc: "Short-time delayed tripping",
    label: "EF003604: Short-time delayed tripping"
  },
  {
    featureId: "EF003605",
    featureDesc: "Type of digital input",
    label: "EF003605: Type of digital input"
  },
  {
    featureId: "EF003611",
    featureDesc: "Connection type loudspeaker bus",
    label: "EF003611: Connection type loudspeaker bus"
  },
  {
    featureId: "EF003612",
    featureDesc: "Connecting type Scart",
    label: "EF003612: Connecting type Scart"
  },
  {
    featureId: "EF003613",
    featureDesc: "Connecting type RCA",
    label: "EF003613: Connecting type RCA"
  },
  {
    featureId: "EF003614",
    featureDesc: "Connecting type clamp",
    label: "EF003614: Connecting type clamp"
  },
  {
    featureId: "EF003615",
    featureDesc: "Connecting type screw clamp",
    label: "EF003615: Connecting type screw clamp"
  },
  {
    featureId: "EF003616",
    featureDesc: "Connecting type DIN 5-pole",
    label: "EF003616: Connecting type DIN 5-pole"
  },
  {
    featureId: "EF003617",
    featureDesc: "Grinding disc diameter",
    label: "EF003617: Grinding disc diameter"
  },
  {
    featureId: "EF003621",
    featureDesc: "With transformer plug",
    label: "EF003621: With transformer plug"
  },
  {
    featureId: "EF003622",
    featureDesc: "With charging station",
    label: "EF003622: With charging station"
  },
  {
    featureId: "EF003623",
    featureDesc: "Variable transmitting power",
    label: "EF003623: Variable transmitting power"
  },
  {
    featureId: "EF003625",
    featureDesc: "Connection voltage",
    label: "EF003625: Connection voltage"
  },
  {
    featureId: "EF003626",
    featureDesc: "Potential free switch contact",
    label: "EF003626: Potential free switch contact"
  },
  {
    featureId: "EF003628",
    featureDesc: "Picture system",
    label: "EF003628: Picture system"
  },
  {
    featureId: "EF003629",
    featureDesc: "Number of amplifiers",
    label: "EF003629: Number of amplifiers"
  },
  {
    featureId: "EF003635",
    featureDesc: "Mandrel size",
    label: "EF003635: Mandrel size"
  },
  {
    featureId: "EF003636",
    featureDesc: "Connection cable length",
    label: "EF003636: Connection cable length"
  },
  {
    featureId: "EF003638",
    featureDesc: "Number of cameras",
    label: "EF003638: Number of cameras"
  },
  {
    featureId: "EF003639",
    featureDesc: "Humidity indication",
    label: "EF003639: Humidity indication"
  },
  {
    featureId: "EF003640",
    featureDesc: "Vacuum polisher",
    label: "EF003640: Vacuum polisher"
  },
  {
    featureId: "EF003641",
    featureDesc: "Bottle sterilizer",
    label: "EF003641: Bottle sterilizer"
  },
  {
    featureId: "EF003642",
    featureDesc: "Baby dummy cleaner",
    label: "EF003642: Baby dummy cleaner"
  },
  {
    featureId: "EF003645",
    featureDesc: "Right-angled model",
    label: "EF003645: Right-angled model"
  },
  {
    featureId: "EF003647",
    featureDesc: "Switching distance",
    label: "EF003647: Switching distance"
  },
  {
    featureId: "EF003648",
    featureDesc: "Max. switching current",
    label: "EF003648: Max. switching current"
  },
  {
    featureId: "EF003649",
    featureDesc: "Bolt dimension (metric)",
    label: "EF003649: Bolt dimension (metric)"
  },
  {
    featureId: "EF003651",
    featureDesc: "Width of the front",
    label: "EF003651: Width of the front"
  },
  {
    featureId: "EF003652",
    featureDesc: "Height of the front",
    label: "EF003652: Height of the front"
  },
  {
    featureId: "EF003654",
    featureDesc: "Closed end cap",
    label: "EF003654: Closed end cap"
  },
  {
    featureId: "EF003656",
    featureDesc: "Approved according to LUL/British Standard",
    label: "EF003656: Approved according to LUL/British Standard"
  },
  {
    featureId: "EF003657",
    featureDesc: "Approved according to Lloyd's Register TA",
    label: "EF003657: Approved according to Lloyd's Register TA"
  },
  {
    featureId: "EF003658",
    featureDesc: "Approved according to UTAC",
    label: "EF003658: Approved according to UTAC"
  },
  {
    featureId: "EF003659",
    featureDesc: "Thread material",
    label: "EF003659: Thread material"
  },
  {
    featureId: "EF003660",
    featureDesc: "Sheathing material 1st coating",
    label: "EF003660: Sheathing material 1st coating"
  },
  {
    featureId: "EF003661",
    featureDesc: "Sheathing material 2nd coating (exterior)",
    label: "EF003661: Sheathing material 2nd coating (exterior)"
  },
  {
    featureId: "EF003662",
    featureDesc: "Diameter punching insert",
    label: "EF003662: Diameter punching insert"
  },
  {
    featureId: "EF003664",
    featureDesc: "Max. number of dual cores",
    label: "EF003664: Max. number of dual cores"
  },
  {
    featureId: "EF003671",
    featureDesc: "Mining sector approval",
    label: "EF003671: Mining sector approval"
  },
  {
    featureId: "EF003675",
    featureDesc: "Total length after shrink",
    label: "EF003675: Total length after shrink"
  },
  {
    featureId: "EF003676",
    featureDesc: "Inner diameter before heat-shrink",
    label: "EF003676: Inner diameter before heat-shrink"
  },
  {
    featureId: "EF003677",
    featureDesc: "Inner diameter after heat-shrink",
    label: "EF003677: Inner diameter after heat-shrink"
  },
  {
    featureId: "EF003678",
    featureDesc: "Max. wall thickness",
    label: "EF003678: Max. wall thickness"
  },
  {
    featureId: "EF003681",
    featureDesc: "Surface mounting",
    label: "EF003681: Surface mounting"
  },
  {
    featureId: "EF003682",
    featureDesc: "Suitable for concrete installation",
    label: "EF003682: Suitable for concrete installation"
  },
  {
    featureId: "EF003683",
    featureDesc: "Machine and systems installation",
    label: "EF003683: Machine and systems installation"
  },
  {
    featureId: "EF003684",
    featureDesc: "Suitable for underfloor installation (bitumen, hot asphalt)",
    label: "EF003684: Suitable for underfloor installation (bitumen, hot asphalt)"
  },
  {
    featureId: "EF003685",
    featureDesc: "Suitable for underfloor installation (screed)",
    label: "EF003685: Suitable for underfloor installation (screed)"
  },
  {
    featureId: "EF003686",
    featureDesc: "Outdoor installation",
    label: "EF003686: Outdoor installation"
  },
  {
    featureId: "EF003687",
    featureDesc: "Suitable for installation on wood",
    label: "EF003687: Suitable for installation on wood"
  },
  {
    featureId: "EF003688",
    featureDesc: "Suitable for hollow wall installation",
    label: "EF003688: Suitable for hollow wall installation"
  },
  {
    featureId: "EF003689",
    featureDesc: "Suitable for underground installation",
    label: "EF003689: Suitable for underground installation"
  },
  {
    featureId: "EF003690",
    featureDesc: "Suitable for high pressure sodium-vapour lamp",
    label: "EF003690: Suitable for high pressure sodium-vapour lamp"
  },
  {
    featureId: "EF003691",
    featureDesc: "Suitable for halogen metal mercury vapour lamp",
    label: "EF003691: Suitable for halogen metal mercury vapour lamp"
  },
  {
    featureId: "EF003692",
    featureDesc: "Suitable for low pressure sodium-vapour lamp",
    label: "EF003692: Suitable for low pressure sodium-vapour lamp"
  },
  {
    featureId: "EF003693",
    featureDesc: "Suitable for fluorescent lamp",
    label: "EF003693: Suitable for fluorescent lamp"
  },
  {
    featureId: "EF003696",
    featureDesc: "Short-circuit protected",
    label: "EF003696: Short-circuit protected"
  },
  {
    featureId: "EF003697",
    featureDesc: "Overload-/temperature protection",
    label: "EF003697: Overload-/temperature protection"
  },
  {
    featureId: "EF003698",
    featureDesc: "With secondary automatic circuit breaker",
    label: "EF003698: With secondary automatic circuit breaker"
  },
  {
    featureId: "EF003699",
    featureDesc: "Number of output positions (pairs)",
    label: "EF003699: Number of output positions (pairs)"
  },
  {
    featureId: "EF003700",
    featureDesc: "Mounting rail armament",
    label: "EF003700: Mounting rail armament"
  },
  {
    featureId: "EF003701",
    featureDesc: "Electrical connection",
    label: "EF003701: Electrical connection"
  },
  {
    featureId: "EF003702",
    featureDesc: "Max. busbar thickness",
    label: "EF003702: Max. busbar thickness"
  },
  {
    featureId: "EF003703",
    featureDesc: "Width of the adapter",
    label: "EF003703: Width of the adapter"
  },
  {
    featureId: "EF003704",
    featureDesc: "Rail width",
    label: "EF003704: Rail width"
  },
  {
    featureId: "EF003705",
    featureDesc: "Nominal operating current",
    label: "EF003705: Nominal operating current"
  },
  {
    featureId: "EF003712",
    featureDesc: "Pitch thread",
    label: "EF003712: Pitch thread"
  },
  {
    featureId: "EF003715",
    featureDesc: "Thread size (imperial)",
    label: "EF003715: Thread size (imperial)"
  },
  {
    featureId: "EF003716",
    featureDesc: "Thread size CTG G",
    label: "EF003716: Thread size CTG G"
  },
  {
    featureId: "EF003718",
    featureDesc: "With outer thread",
    label: "EF003718: With outer thread"
  },
  {
    featureId: "EF003719",
    featureDesc: "Thread size inner thread (metric)",
    label: "EF003719: Thread size inner thread (metric)"
  },
  {
    featureId: "EF003720",
    featureDesc: "Thread size outer thread (metric)",
    label: "EF003720: Thread size outer thread (metric)"
  },
  {
    featureId: "EF003721",
    featureDesc: "Pitch inner thread",
    label: "EF003721: Pitch inner thread"
  },
  {
    featureId: "EF003722",
    featureDesc: "Pitch outer thread",
    label: "EF003722: Pitch outer thread"
  },
  {
    featureId: "EF003723",
    featureDesc: "With thread",
    label: "EF003723: With thread"
  },
  {
    featureId: "EF003725",
    featureDesc: "For lamp type TC-D",
    label: "EF003725: For lamp type TC-D"
  },
  {
    featureId: "EF003727",
    featureDesc: "For lamp type TC-DD",
    label: "EF003727: For lamp type TC-DD"
  },
  {
    featureId: "EF003728",
    featureDesc: "For lamp type TC-L",
    label: "EF003728: For lamp type TC-L"
  },
  {
    featureId: "EF003731",
    featureDesc: "For lamp type TC-DEL",
    label: "EF003731: For lamp type TC-DEL"
  },
  {
    featureId: "EF003732",
    featureDesc: "For lamp type TC-TEL",
    label: "EF003732: For lamp type TC-TEL"
  },
  {
    featureId: "EF003741",
    featureDesc: "For lamp type T7",
    label: "EF003741: For lamp type T7"
  },
  {
    featureId: "EF003742",
    featureDesc: "For lamp type T16",
    label: "EF003742: For lamp type T16"
  },
  {
    featureId: "EF003743",
    featureDesc: "For lamp type T26",
    label: "EF003743: For lamp type T26"
  },
  {
    featureId: "EF003747",
    featureDesc: "For lamp type T-R",
    label: "EF003747: For lamp type T-R"
  },
  {
    featureId: "EF003748",
    featureDesc: "For lamp type TC-T",
    label: "EF003748: For lamp type TC-T"
  },
  {
    featureId: "EF003763",
    featureDesc: "For lamp type TC-S",
    label: "EF003763: For lamp type TC-S"
  },
  {
    featureId: "EF003770",
    featureDesc: "For lamp type HI",
    label: "EF003770: For lamp type HI"
  },
  {
    featureId: "EF003772",
    featureDesc: "Text colour",
    label: "EF003772: Text colour"
  },
  {
    featureId: "EF003773",
    featureDesc: "Tape colour",
    label: "EF003773: Tape colour"
  },
  {
    featureId: "EF003774",
    featureDesc: "With auxiliary terminal",
    label: "EF003774: With auxiliary terminal"
  },
  {
    featureId: "EF003775",
    featureDesc: "Primary voltage",
    label: "EF003775: Primary voltage"
  },
  {
    featureId: "EF003776",
    featureDesc: "Secondary voltage 1",
    label: "EF003776: Secondary voltage 1"
  },
  {
    featureId: "EF003777",
    featureDesc: "Secondary voltage 2",
    label: "EF003777: Secondary voltage 2"
  },
  {
    featureId: "EF003778",
    featureDesc: "Secondary voltage 3",
    label: "EF003778: Secondary voltage 3"
  },
  {
    featureId: "EF003779",
    featureDesc: "Secondary amperage 1",
    label: "EF003779: Secondary amperage 1"
  },
  {
    featureId: "EF003780",
    featureDesc: "Secondary amperage 2",
    label: "EF003780: Secondary amperage 2"
  },
  {
    featureId: "EF003781",
    featureDesc: "Secondary amperage 3",
    label: "EF003781: Secondary amperage 3"
  },
  {
    featureId: "EF003782",
    featureDesc: "Temperature protection",
    label: "EF003782: Temperature protection"
  },
  {
    featureId: "EF003783",
    featureDesc: "With insert contacts",
    label: "EF003783: With insert contacts"
  },
  {
    featureId: "EF003784",
    featureDesc: "With reboiling setting",
    label: "EF003784: With reboiling setting"
  },
  {
    featureId: "EF003787",
    featureDesc: "Spiralized cable",
    label: "EF003787: Spiralized cable"
  },
  {
    featureId: "EF003788",
    featureDesc: "Temperature resistance",
    label: "EF003788: Temperature resistance"
  },
  {
    featureId: "EF003790",
    featureDesc: "Energy cable",
    label: "EF003790: Energy cable"
  },
  {
    featureId: "EF003792",
    featureDesc: "Halogen free according to EN IEC 60754-2",
    label: "EF003792: Halogen free according to EN IEC 60754-2"
  },
  {
    featureId: "EF003795",
    featureDesc: "Concentric conductor",
    label: "EF003795: Concentric conductor"
  },
  {
    featureId: "EF003796",
    featureDesc: "Cable width approx.",
    label: "EF003796: Cable width approx."
  },
  {
    featureId: "EF003797",
    featureDesc: "Cable height approx.",
    label: "EF003797: Cable height approx."
  },
  {
    featureId: "EF003799",
    featureDesc: "Low smoke according to EN IEC 61034-2",
    label: "EF003799: Low smoke according to EN IEC 61034-2"
  },
  {
    featureId: "EF003802",
    featureDesc: "Stranding",
    label: "EF003802: Stranding"
  },
  {
    featureId: "EF003803",
    featureDesc: "Max. conductor temperature",
    label: "EF003803: Max. conductor temperature"
  },
  {
    featureId: "EF003804",
    featureDesc: "Protective barrier",
    label: "EF003804: Protective barrier"
  },
  {
    featureId: "EF003806",
    featureDesc: "Blow in",
    label: "EF003806: Blow in"
  },
  {
    featureId: "EF003807",
    featureDesc: "With cover frame",
    label: "EF003807: With cover frame"
  },
  {
    featureId: "EF003808",
    featureDesc: "Dim function with dimmer basic element",
    label: "EF003808: Dim function with dimmer basic element"
  },
  {
    featureId: "EF003809",
    featureDesc: "External signal voltage necessary",
    label: "EF003809: External signal voltage necessary"
  },
  {
    featureId: "EF003810",
    featureDesc: "Analogue input",
    label: "EF003810: Analogue input"
  },
  {
    featureId: "EF003811",
    featureDesc: "Other bus systems",
    label: "EF003811: Other bus systems"
  },
  {
    featureId: "EF003812",
    featureDesc: "Number of connectable motors",
    label: "EF003812: Number of connectable motors"
  },
  {
    featureId: "EF003814",
    featureDesc: "Response value luminosity",
    label: "EF003814: Response value luminosity"
  },
  {
    featureId: "EF003815",
    featureDesc: "Number of control elements",
    label: "EF003815: Number of control elements"
  },
  {
    featureId: "EF003816",
    featureDesc: "Number of rockers",
    label: "EF003816: Number of rockers"
  },
  {
    featureId: "EF003818",
    featureDesc: "Number of inputs for light sensor",
    label: "EF003818: Number of inputs for light sensor"
  },
  {
    featureId: "EF003819",
    featureDesc: "Number of switch inputs",
    label: "EF003819: Number of switch inputs"
  },
  {
    featureId: "EF003820",
    featureDesc: "Number of input channels",
    label: "EF003820: Number of input channels"
  },
  {
    featureId: "EF003821",
    featureDesc: "Number of output channels",
    label: "EF003821: Number of output channels"
  },
  {
    featureId: "EF003822",
    featureDesc: "Number of serial interfaces",
    label: "EF003822: Number of serial interfaces"
  },
  {
    featureId: "EF003823",
    featureDesc: "Imprint/indication",
    label: "EF003823: Imprint/indication"
  },
  {
    featureId: "EF003824",
    featureDesc: "Mounting direction",
    label: "EF003824: Mounting direction"
  },
  {
    featureId: "EF003825",
    featureDesc: "Switching-off brightness",
    label: "EF003825: Switching-off brightness"
  },
  {
    featureId: "EF003826",
    featureDesc: "Switch-off protection",
    label: "EF003826: Switch-off protection"
  },
  {
    featureId: "EF003827",
    featureDesc: "Printable with",
    label: "EF003827: Printable with"
  },
  {
    featureId: "EF003829",
    featureDesc: "With lighting",
    label: "EF003829: With lighting"
  },
  {
    featureId: "EF003831",
    featureDesc: "Bus system LON",
    label: "EF003831: Bus system LON"
  },
  {
    featureId: "EF003832",
    featureDesc: "Suitable for C-load",
    label: "EF003832: Suitable for C-load"
  },
  {
    featureId: "EF003833",
    featureDesc: "Twilight value adjustable",
    label: "EF003833: Twilight value adjustable"
  },
  {
    featureId: "EF003834",
    featureDesc: "With integrated DCF77 receiver",
    label: "EF003834: With integrated DCF77 receiver"
  },
  {
    featureId: "EF003835",
    featureDesc: "With integrated DCF clock",
    label: "EF003835: With integrated DCF clock"
  },
  {
    featureId: "EF003836",
    featureDesc: "Demounting protection",
    label: "EF003836: Demounting protection"
  },
  {
    featureId: "EF003837",
    featureDesc: "With anti-theft/dismantling protection",
    label: "EF003837: With anti-theft/dismantling protection"
  },
  {
    featureId: "EF003838",
    featureDesc: "Hole diameter built-in",
    label: "EF003838: Hole diameter built-in"
  },
  {
    featureId: "EF003839",
    featureDesc: "Built-in wall thickness",
    label: "EF003839: Built-in wall thickness"
  },
  {
    featureId: "EF003840",
    featureDesc: "Input voltage",
    label: "EF003840: Input voltage"
  },
  {
    featureId: "EF003841",
    featureDesc: "Engaging brightness",
    label: "EF003841: Engaging brightness"
  },
  {
    featureId: "EF003843",
    featureDesc: "Diameter reach on floor",
    label: "EF003843: Diameter reach on floor"
  },
  {
    featureId: "EF003846",
    featureDesc: "External programming",
    label: "EF003846: External programming"
  },
  {
    featureId: "EF003848",
    featureDesc: "Remote operation",
    label: "EF003848: Remote operation"
  },
  {
    featureId: "EF003849",
    featureDesc: "Radio interface",
    label: "EF003849: Radio interface"
  },
  {
    featureId: "EF003850",
    featureDesc: "Radio-controlled (DCF77)",
    label: "EF003850: Radio-controlled (DCF77)"
  },
  {
    featureId: "EF003851",
    featureDesc: "Suitable for application with dimmer",
    label: "EF003851: Suitable for application with dimmer"
  },
  {
    featureId: "EF003852",
    featureDesc: "Suitable for venetian-/roller shutter switch",
    label: "EF003852: Suitable for venetian-/roller shutter switch"
  },
  {
    featureId: "EF003853",
    featureDesc: "Suitable for application with venetian shutter switch",
    label: "EF003853: Suitable for application with venetian shutter switch"
  },
  {
    featureId: "EF003854",
    featureDesc: "Suitable for venetian shutter switch",
    label: "EF003854: Suitable for venetian shutter switch"
  },
  {
    featureId: "EF003855",
    featureDesc: "Suitable for application with switch",
    label: "EF003855: Suitable for application with switch"
  },
  {
    featureId: "EF003857",
    featureDesc: "With integrated GSM-modem",
    label: "EF003857: With integrated GSM-modem"
  },
  {
    featureId: "EF003858",
    featureDesc: "HVAC-control",
    label: "EF003858: HVAC-control"
  },
  {
    featureId: "EF003859",
    featureDesc: "Number of integrated output channels",
    label: "EF003859: Number of integrated output channels"
  },
  {
    featureId: "EF003860",
    featureDesc: "Number of integrated input channels",
    label: "EF003860: Number of integrated input channels"
  },
  {
    featureId: "EF003861",
    featureDesc: "IR interface",
    label: "EF003861: IR interface"
  },
  {
    featureId: "EF003862",
    featureDesc: "IR sensor",
    label: "EF003862: IR sensor"
  },
  {
    featureId: "EF003863",
    featureDesc: "Constant light control",
    label: "EF003863: Constant light control"
  },
  {
    featureId: "EF003864",
    featureDesc: "Monitoring window/light outlet",
    label: "EF003864: Monitoring window/light outlet"
  },
  {
    featureId: "EF003866",
    featureDesc: "LED controlling",
    label: "EF003866: LED controlling"
  },
  {
    featureId: "EF003867",
    featureDesc: "Light value memory",
    label: "EF003867: Light value memory"
  },
  {
    featureId: "EF003868",
    featureDesc: "Luster terminal",
    label: "EF003868: Luster terminal"
  },
  {
    featureId: "EF003869",
    featureDesc: "Manual set point setting",
    label: "EF003869: Manual set point setting"
  },
  {
    featureId: "EF003871",
    featureDesc: "With glass breaking detector connection",
    label: "EF003871: With glass breaking detector connection"
  },
  {
    featureId: "EF003872",
    featureDesc: "With brightness sensor connection",
    label: "EF003872: With brightness sensor connection"
  },
  {
    featureId: "EF003873",
    featureDesc: "With signal tone",
    label: "EF003873: With signal tone"
  },
  {
    featureId: "EF003874",
    featureDesc: "Mounting height",
    label: "EF003874: Mounting height"
  },
  {
    featureId: "EF003876",
    featureDesc: "Substation input",
    label: "EF003876: Substation input"
  },
  {
    featureId: "EF003877",
    featureDesc: "Nominal current socket outlet",
    label: "EF003877: Nominal current socket outlet"
  },
  {
    featureId: "EF003878",
    featureDesc: "Parallel-service possible",
    label: "EF003878: Parallel-service possible"
  },
  {
    featureId: "EF003879",
    featureDesc: "Presence button",
    label: "EF003879: Presence button"
  },
  {
    featureId: "EF003880",
    featureDesc: "Protocol",
    label: "EF003880: Protocol"
  },
  {
    featureId: "EF003881",
    featureDesc: "Provider dependent",
    label: "EF003881: Provider dependent"
  },
  {
    featureId: "EF003882",
    featureDesc: "RAL-number (similar)",
    label: "EF003882: RAL-number (similar)"
  },
  {
    featureId: "EF003883",
    featureDesc: "RAL-number (similar) control element",
    label: "EF003883: RAL-number (similar) control element"
  },
  {
    featureId: "EF003884",
    featureDesc: "RAL-number (similar) housing",
    label: "EF003884: RAL-number (similar) housing"
  },
  {
    featureId: "EF003887",
    featureDesc: "Switch capacity HVAC",
    label: "EF003887: Switch capacity HVAC"
  },
  {
    featureId: "EF003888",
    featureDesc: "Switching current for fluorescent lamps",
    label: "EF003888: Switching current for fluorescent lamps"
  },
  {
    featureId: "EF003889",
    featureDesc: "Serial dimmer",
    label: "EF003889: Serial dimmer"
  },
  {
    featureId: "EF003890",
    featureDesc: "With signal unit",
    label: "EF003890: With signal unit"
  },
  {
    featureId: "EF003891",
    featureDesc: "Sun protection value adjustable",
    label: "EF003891: Sun protection value adjustable"
  },
  {
    featureId: "EF003892",
    featureDesc: "Status-LED",
    label: "EF003892: Status-LED"
  },
  {
    featureId: "EF003893",
    featureDesc: "With dust cover",
    label: "EF003893: With dust cover"
  },
  {
    featureId: "EF003894",
    featureDesc: "Control current",
    label: "EF003894: Control current"
  },
  {
    featureId: "EF003895",
    featureDesc: "Push button switch",
    label: "EF003895: Push button switch"
  },
  {
    featureId: "EF003898",
    featureDesc: "Teach-function for response value luminosity",
    label: "EF003898: Teach-function for response value luminosity"
  },
  {
    featureId: "EF003900",
    featureDesc: "Temperature value adjustable",
    label: "EF003900: Temperature value adjustable"
  },
  {
    featureId: "EF003903",
    featureDesc: "Updateable",
    label: "EF003903: Updateable"
  },
  {
    featureId: "EF003904",
    featureDesc: "Networkable",
    label: "EF003904: Networkable"
  },
  {
    featureId: "EF003905",
    featureDesc: "Different phases connectable",
    label: "EF003905: Different phases connectable"
  },
  {
    featureId: "EF003906",
    featureDesc: "Applicable with movement sensor",
    label: "EF003906: Applicable with movement sensor"
  },
  {
    featureId: "EF003907",
    featureDesc: "Applicable with transmission button",
    label: "EF003907: Applicable with transmission button"
  },
  {
    featureId: "EF003908",
    featureDesc: "Applicable with infrared button",
    label: "EF003908: Applicable with infrared button"
  },
  {
    featureId: "EF003909",
    featureDesc: "Applicable with presence indicator",
    label: "EF003909: Applicable with presence indicator"
  },
  {
    featureId: "EF003910",
    featureDesc: "Applicable with button",
    label: "EF003910: Applicable with button"
  },
  {
    featureId: "EF003911",
    featureDesc: "Applicable with time switch/timer",
    label: "EF003911: Applicable with time switch/timer"
  },
  {
    featureId: "EF003912",
    featureDesc: "Application as button",
    label: "EF003912: Application as button"
  },
  {
    featureId: "EF003913",
    featureDesc: "Visualization",
    label: "EF003913: Visualization"
  },
  {
    featureId: "EF003914",
    featureDesc: "Local operation/hand operation",
    label: "EF003914: Local operation/hand operation"
  },
  {
    featureId: "EF003915",
    featureDesc: "Web-Server",
    label: "EF003915: Web-Server"
  },
  {
    featureId: "EF003916",
    featureDesc: "Weather station",
    label: "EF003916: Weather station"
  },
  {
    featureId: "EF003917",
    featureDesc: "Wind velocity value adjustable",
    label: "EF003917: Wind velocity value adjustable"
  },
  {
    featureId: "EF003918",
    featureDesc: "Central functional unit for senders/actuators",
    label: "EF003918: Central functional unit for senders/actuators"
  },
  {
    featureId: "EF003919",
    featureDesc: "Central input",
    label: "EF003919: Central input"
  },
  {
    featureId: "EF003920",
    featureDesc: "Forced switch off",
    label: "EF003920: Forced switch off"
  },
  {
    featureId: "EF003921",
    featureDesc: "Outer diameter approx.",
    label: "EF003921: Outer diameter approx."
  },
  {
    featureId: "EF003923",
    featureDesc: "Data and communication cable",
    label: "EF003923: Data and communication cable"
  },
  {
    featureId: "EF003924",
    featureDesc: "Fibre optic cable",
    label: "EF003924: Fibre optic cable"
  },
  {
    featureId: "EF003925",
    featureDesc: "With power adaptor",
    label: "EF003925: With power adaptor"
  },
  {
    featureId: "EF003926",
    featureDesc: "With recording function",
    label: "EF003926: With recording function"
  },
  {
    featureId: "EF003927",
    featureDesc: "With digital output",
    label: "EF003927: With digital output"
  },
  {
    featureId: "EF003928",
    featureDesc: "With digital input",
    label: "EF003928: With digital input"
  },
  {
    featureId: "EF003929",
    featureDesc: "Cross section",
    label: "EF003929: Cross section"
  },
  {
    featureId: "EF003933",
    featureDesc: "Output voltage",
    label: "EF003933: Output voltage"
  },
  {
    featureId: "EF003934",
    featureDesc: "Nominal size",
    label: "EF003934: Nominal size"
  },
  {
    featureId: "EF003935",
    featureDesc: "Scanning principle",
    label: "EF003935: Scanning principle"
  },
  {
    featureId: "EF003936",
    featureDesc: "Scanning interval",
    label: "EF003936: Scanning interval"
  },
  {
    featureId: "EF003938",
    featureDesc: "Type of electrical connection at the motor output",
    label: "EF003938: Type of electrical connection at the motor output"
  },
  {
    featureId: "EF003939",
    featureDesc: "Suitable for burner technology",
    label: "EF003939: Suitable for burner technology"
  },
  {
    featureId: "EF003940",
    featureDesc: "Suitable for emergency stop function",
    label: "EF003940: Suitable for emergency stop function"
  },
  {
    featureId: "EF003941",
    featureDesc: "Suitable for press controls",
    label: "EF003941: Suitable for press controls"
  },
  {
    featureId: "EF003942",
    featureDesc: "Suitable for control technology",
    label: "EF003942: Suitable for control technology"
  },
  {
    featureId: "EF003943",
    featureDesc: "Suitable for other application areas",
    label: "EF003943: Suitable for other application areas"
  },
  {
    featureId: "EF003944",
    featureDesc: "Suitable for transfer streets",
    label: "EF003944: Suitable for transfer streets"
  },
  {
    featureId: "EF003945",
    featureDesc: "Number of semiconductor outputs with signalling function",
    label: "EF003945: Number of semiconductor outputs with signalling function"
  },
  {
    featureId: "EF003946",
    featureDesc: "Number of contact energized outputs with signalling function",
    label: "EF003946: Number of contact energized outputs with signalling function"
  },
  {
    featureId: "EF003948",
    featureDesc: "Type of sensor",
    label: "EF003948: Type of sensor"
  },
  {
    featureId: "EF003949",
    featureDesc: "Number of motor outlets",
    label: "EF003949: Number of motor outlets"
  },
  {
    featureId: "EF003950",
    featureDesc: "Number of safety fields",
    label: "EF003950: Number of safety fields"
  },
  {
    featureId: "EF003951",
    featureDesc: "Number of protected semiconductor outputs",
    label: "EF003951: Number of protected semiconductor outputs"
  },
  {
    featureId: "EF003952",
    featureDesc: "Number of protected contact energized outputs",
    label: "EF003952: Number of protected contact energized outputs"
  },
  {
    featureId: "EF003953",
    featureDesc: "Number of cams",
    label: "EF003953: Number of cams"
  },
  {
    featureId: "EF003954",
    featureDesc: "Number of beams",
    label: "EF003954: Number of beams"
  },
  {
    featureId: "EF003955",
    featureDesc: "Fade-out possible",
    label: "EF003955: Fade-out possible"
  },
  {
    featureId: "EF003956",
    featureDesc: "Type of interface for safety communication",
    label: "EF003956: Type of interface for safety communication"
  },
  {
    featureId: "EF003958",
    featureDesc: "Type of analogy output",
    label: "EF003958: Type of analogy output"
  },
  {
    featureId: "EF003959",
    featureDesc: "Type of switching output of the OSSD",
    label: "EF003959: Type of switching output of the OSSD"
  },
  {
    featureId: "EF003961",
    featureDesc: "Type of electric connection",
    label: "EF003961: Type of electric connection"
  },
  {
    featureId: "EF003962",
    featureDesc: "Type of switching output",
    label: "EF003962: Type of switching output"
  },
  {
    featureId: "EF003963",
    featureDesc: "Type of switch function",
    label: "EF003963: Type of switch function"
  },
  {
    featureId: "EF003964",
    featureDesc: "Signalling interval",
    label: "EF003964: Signalling interval"
  },
  {
    featureId: "EF003968",
    featureDesc: "With blanking function",
    label: "EF003968: With blanking function"
  },
  {
    featureId: "EF003972",
    featureDesc: "Rail mounting possible",
    label: "EF003972: Rail mounting possible"
  },
  {
    featureId: "EF003973",
    featureDesc: "Wall mounting possible",
    label: "EF003973: Wall mounting possible"
  },
  {
    featureId: "EF003974",
    featureDesc: "Rated rotational speed at rated frequency",
    label: "EF003974: Rated rotational speed at rated frequency"
  },
  {
    featureId: "EF003975",
    featureDesc: "Rated fault current",
    label: "EF003975: Rated fault current"
  },
  {
    featureId: "EF003976",
    featureDesc: "Rated performance at rated frequency",
    label: "EF003976: Rated performance at rated frequency"
  },
  {
    featureId: "EF003977",
    featureDesc: "Rated voltage",
    label: "EF003977: Rated voltage"
  },
  {
    featureId: "EF003978",
    featureDesc: "Rated control supply voltage AC 50 Hz",
    label: "EF003978: Rated control supply voltage AC 50 Hz"
  },
  {
    featureId: "EF003979",
    featureDesc: "Rated control supply voltage AC 60 Hz",
    label: "EF003979: Rated control supply voltage AC 60 Hz"
  },
  {
    featureId: "EF003980",
    featureDesc: "Rated control supply voltage DC",
    label: "EF003980: Rated control supply voltage DC"
  },
  {
    featureId: "EF003981",
    featureDesc: "Rated current Iu",
    label: "EF003981: Rated current Iu"
  },
  {
    featureId: "EF003982",
    featureDesc: "Operation agent-safety class",
    label: "EF003982: Operation agent-safety class"
  },
  {
    featureId: "EF003983",
    featureDesc: "Operating voltage",
    label: "EF003983: Operating voltage"
  },
  {
    featureId: "EF003984",
    featureDesc: "Frame frequency",
    label: "EF003984: Frame frequency"
  },
  {
    featureId: "EF003986",
    featureDesc: "Blind zone",
    label: "EF003986: Blind zone"
  },
  {
    featureId: "EF003987",
    featureDesc: "With brake output",
    label: "EF003987: With brake output"
  },
  {
    featureId: "EF003989",
    featureDesc: "Bus diagnosis possible",
    label: "EF003989: Bus diagnosis possible"
  },
  {
    featureId: "EF003990",
    featureDesc: "With bus coupler for modular station connection",
    label: "EF003990: With bus coupler for modular station connection"
  },
  {
    featureId: "EF003991",
    featureDesc: "Detection capability for test bodies",
    label: "EF003991: Detection capability for test bodies"
  },
  {
    featureId: "EF003992",
    featureDesc: "Continuity resistance",
    label: "EF003992: Continuity resistance"
  },
  {
    featureId: "EF003993",
    featureDesc: "With power supply module",
    label: "EF003993: With power supply module"
  },
  {
    featureId: "EF003994",
    featureDesc: "Setting procedure",
    label: "EF003994: Setting procedure"
  },
  {
    featureId: "EF003995",
    featureDesc: "Type of frequency band of the receiving spectrum",
    label: "EF003995: Type of frequency band of the receiving spectrum"
  },
  {
    featureId: "EF003996",
    featureDesc: "Explosion safety",
    label: "EF003996: Explosion safety"
  },
  {
    featureId: "EF003997",
    featureDesc: "Explosion safety category for gas",
    label: "EF003997: Explosion safety category for gas"
  },
  {
    featureId: "EF003998",
    featureDesc: "Explosion safety category for dust",
    label: "EF003998: Explosion safety category for dust"
  },
  {
    featureId: "EF004001",
    featureDesc: "Measuring process for colour detection",
    label: "EF004001: Measuring process for colour detection"
  },
  {
    featureId: "EF004002",
    featureDesc: "Fieldbus connection over separate bus coupler possible",
    label: "EF004002: Fieldbus connection over separate bus coupler possible"
  },
  {
    featureId: "EF004005",
    featureDesc: "Function principle",
    label: "EF004005: Function principle"
  },
  {
    featureId: "EF004008",
    featureDesc: "Construction type housing",
    label: "EF004008: Construction type housing"
  },
  {
    featureId: "EF004010",
    featureDesc: "Geometrical resolution",
    label: "EF004010: Geometrical resolution"
  },
  {
    featureId: "EF004011",
    featureDesc: "Protected switch distance OFF",
    label: "EF004011: Protected switch distance OFF"
  },
  {
    featureId: "EF004012",
    featureDesc: "Protected switch distance ON",
    label: "EF004012: Protected switch distance ON"
  },
  {
    featureId: "EF004015",
    featureDesc: "Limiting frequency",
    label: "EF004015: Limiting frequency"
  },
  {
    featureId: "EF004017",
    featureDesc: "Standard for interfaces",
    label: "EF004017: Standard for interfaces"
  },
  {
    featureId: "EF004018",
    featureDesc: "With configurable message output",
    label: "EF004018: With configurable message output"
  },
  {
    featureId: "EF004019",
    featureDesc: "Contact material",
    label: "EF004019: Contact material"
  },
  {
    featureId: "EF004020",
    featureDesc: "Material of contact surface",
    label: "EF004020: Material of contact surface"
  },
  {
    featureId: "EF004027",
    featureDesc: "Laser protection class",
    label: "EF004027: Laser protection class"
  },
  {
    featureId: "EF004028",
    featureDesc: "No-load current",
    label: "EF004028: No-load current"
  },
  {
    featureId: "EF004029",
    featureDesc: "Type of light",
    label: "EF004029: Type of light"
  },
  {
    featureId: "EF004030",
    featureDesc: "Light dot",
    label: "EF004030: Light dot"
  },
  {
    featureId: "EF004032",
    featureDesc: "Scope of delivery of the one-way system",
    label: "EF004032: Scope of delivery of the one-way system"
  },
  {
    featureId: "EF004034",
    featureDesc: "Relative linearity deflection",
    label: "EF004034: Relative linearity deflection"
  },
  {
    featureId: "EF004035",
    featureDesc: "Max. output current at protected output",
    label: "EF004035: Max. output current at protected output"
  },
  {
    featureId: "EF004037",
    featureDesc: "Max. current carrying capacity per I/O signal",
    label: "EF004037: Max. current carrying capacity per I/O signal"
  },
  {
    featureId: "EF004038",
    featureDesc: "Max. current carrying capacity per socket",
    label: "EF004038: Max. current carrying capacity per socket"
  },
  {
    featureId: "EF004039",
    featureDesc: "Max. total current",
    label: "EF004039: Max. total current"
  },
  {
    featureId: "EF004041",
    featureDesc: "Mechanical mounting condition for sensor",
    label: "EF004041: Mechanical mounting condition for sensor"
  },
  {
    featureId: "EF004044",
    featureDesc: "Relative measurement accuracy",
    label: "EF004044: Relative measurement accuracy"
  },
  {
    featureId: "EF004045",
    featureDesc: "Min. output current",
    label: "EF004045: Min. output current"
  },
  {
    featureId: "EF004046",
    featureDesc: "Min. reflector distance",
    label: "EF004046: Min. reflector distance"
  },
  {
    featureId: "EF004048",
    featureDesc: "With imprint",
    label: "EF004048: With imprint"
  },
  {
    featureId: "EF004050",
    featureDesc: "With fibre optics connection",
    label: "EF004050: With fibre optics connection"
  },
  {
    featureId: "EF004052",
    featureDesc: "With monitoring function downstream switching devices",
    label: "EF004052: With monitoring function downstream switching devices"
  },
  {
    featureId: "EF004053",
    featureDesc: "With exchange lens",
    label: "EF004053: With exchange lens"
  },
  {
    featureId: "EF004054",
    featureDesc: "With restart blockage",
    label: "EF004054: With restart blockage"
  },
  {
    featureId: "EF004055",
    featureDesc: "Rated operation current of the motor",
    label: "EF004055: Rated operation current of the motor"
  },
  {
    featureId: "EF004056",
    featureDesc: "With motor current parameter setting",
    label: "EF004056: With motor current parameter setting"
  },
  {
    featureId: "EF004057",
    featureDesc: "With muting function",
    label: "EF004057: With muting function"
  },
  {
    featureId: "EF004059",
    featureDesc: "Nominal operating distance",
    label: "EF004059: Nominal operating distance"
  },
  {
    featureId: "EF004060",
    featureDesc: "Measuring principle for optical distance measurement",
    label: "EF004060: Measuring principle for optical distance measurement"
  },
  {
    featureId: "EF004061",
    featureDesc: "Override possible",
    label: "EF004061: Override possible"
  },
  {
    featureId: "EF004093",
    featureDesc: "Cross-/short circuit identification possible",
    label: "EF004093: Cross-/short circuit identification possible"
  },
  {
    featureId: "EF004094",
    featureDesc: "Response time",
    label: "EF004094: Response time"
  },
  {
    featureId: "EF004095",
    featureDesc: "Reduced resolution",
    label: "EF004095: Reduced resolution"
  },
  {
    featureId: "EF004097",
    featureDesc: "Transmission range of the measuring range",
    label: "EF004097: Transmission range of the measuring range"
  },
  {
    featureId: "EF004098",
    featureDesc: "Transmission range of the safety field",
    label: "EF004098: Transmission range of the safety field"
  },
  {
    featureId: "EF004099",
    featureDesc: "Transmission range of the alert field",
    label: "EF004099: Transmission range of the alert field"
  },
  {
    featureId: "EF004100",
    featureDesc: "Relative repeat accuracy",
    label: "EF004100: Relative repeat accuracy"
  },
  {
    featureId: "EF004101",
    featureDesc: "Scan angle",
    label: "EF004101: Scan angle"
  },
  {
    featureId: "EF004102",
    featureDesc: "Switching voltage of OSSD at state 'high'",
    label: "EF004102: Switching voltage of OSSD at state 'high'"
  },
  {
    featureId: "EF004103",
    featureDesc: "Safety field height",
    label: "EF004103: Safety field height"
  },
  {
    featureId: "EF004104",
    featureDesc: "Suitable as segment module",
    label: "EF004104: Suitable as segment module"
  },
  {
    featureId: "EF004105",
    featureDesc: "Type of safety according to IEC 61496-1",
    label: "EF004105: Type of safety according to IEC 61496-1"
  },
  {
    featureId: "EF004109",
    featureDesc: "Cam distance",
    label: "EF004109: Cam distance"
  },
  {
    featureId: "EF004112",
    featureDesc: "Plunger orientation",
    label: "EF004112: Plunger orientation"
  },
  {
    featureId: "EF004113",
    featureDesc: "Beam spacing",
    label: "EF004113: Beam spacing"
  },
  {
    featureId: "EF004114",
    featureDesc: "With beam coding",
    label: "EF004114: With beam coding"
  },
  {
    featureId: "EF004115",
    featureDesc: "Vulcanize",
    label: "EF004115: Vulcanize"
  },
  {
    featureId: "EF004117",
    featureDesc: "Synchronisation possible",
    label: "EF004117: Synchronisation possible"
  },
  {
    featureId: "EF004118",
    featureDesc: "System accessory",
    label: "EF004118: System accessory"
  },
  {
    featureId: "EF004119",
    featureDesc: "Clock control possible",
    label: "EF004119: Clock control possible"
  },
  {
    featureId: "EF004121",
    featureDesc: "Operating distance",
    label: "EF004121: Operating distance"
  },
  {
    featureId: "EF004123",
    featureDesc: "With thermal motor protection",
    label: "EF004123: With thermal motor protection"
  },
  {
    featureId: "EF004127",
    featureDesc: "Supply voltage",
    label: "EF004127: Supply voltage"
  },
  {
    featureId: "EF004129",
    featureDesc: "Pre failure notice",
    label: "EF004129: Pre failure notice"
  },
  {
    featureId: "EF004130",
    featureDesc: "Condition analysis system",
    label: "EF004130: Condition analysis system"
  },
  {
    featureId: "EF004131",
    featureDesc: "With alert field output",
    label: "EF004131: With alert field output"
  },
  {
    featureId: "EF004132",
    featureDesc: "Wavelength of the sensor",
    label: "EF004132: Wavelength of the sensor"
  },
  {
    featureId: "EF004133",
    featureDesc: "Material active area of sensor",
    label: "EF004133: Material active area of sensor"
  },
  {
    featureId: "EF004134",
    featureDesc: "Material of optical surface",
    label: "EF004134: Material of optical surface"
  },
  {
    featureId: "EF004136",
    featureDesc: "Material of cable sheath",
    label: "EF004136: Material of cable sheath"
  },
  {
    featureId: "EF004137",
    featureDesc: "Material fibre optics core",
    label: "EF004137: Material fibre optics core"
  },
  {
    featureId: "EF004139",
    featureDesc: "Operating principle",
    label: "EF004139: Operating principle"
  },
  {
    featureId: "EF004140",
    featureDesc: "Suitable for other target system",
    label: "EF004140: Suitable for other target system"
  },
  {
    featureId: "EF004141",
    featureDesc: "Appendant operation agent (Ex ia)",
    label: "EF004141: Appendant operation agent (Ex ia)"
  },
  {
    featureId: "EF004142",
    featureDesc: "Appendant operation agent (Ex ib)",
    label: "EF004142: Appendant operation agent (Ex ib)"
  },
  {
    featureId: "EF004145",
    featureDesc: "With approval for BG BIA",
    label: "EF004145: With approval for BG BIA"
  },
  {
    featureId: "EF004147",
    featureDesc: "Type of perforation",
    label: "EF004147: Type of perforation"
  },
  {
    featureId: "EF004148",
    featureDesc: "Connected load",
    label: "EF004148: Connected load"
  },
  {
    featureId: "EF004149",
    featureDesc: "Fuse size of the device",
    label: "EF004149: Fuse size of the device"
  },
  {
    featureId: "EF004155",
    featureDesc: "Max. outer diameter",
    label: "EF004155: Max. outer diameter"
  },
  {
    featureId: "EF004156",
    featureDesc: "With concentric shielding",
    label: "EF004156: With concentric shielding"
  },
  {
    featureId: "EF004158",
    featureDesc: "With tool",
    label: "EF004158: With tool"
  },
  {
    featureId: "EF004160",
    featureDesc: "Thread size",
    label: "EF004160: Thread size"
  },
  {
    featureId: "EF004162",
    featureDesc: "Installation on wood",
    label: "EF004162: Installation on wood"
  },
  {
    featureId: "EF004166",
    featureDesc: "Connecting shaft",
    label: "EF004166: Connecting shaft"
  },
  {
    featureId: "EF004167",
    featureDesc: "Terminal lug length",
    label: "EF004167: Terminal lug length"
  },
  {
    featureId: "EF004168",
    featureDesc: "Connecting hole",
    label: "EF004168: Connecting hole"
  },
  {
    featureId: "EF004170",
    featureDesc: "Connection cross section",
    label: "EF004170: Connection cross section"
  },
  {
    featureId: "EF004171",
    featureDesc: "Number of connections flat conductor 30 mm",
    label: "EF004171: Number of connections flat conductor 30 mm"
  },
  {
    featureId: "EF004172",
    featureDesc: "Number of connections flat conductor 40 mm",
    label: "EF004172: Number of connections flat conductor 40 mm"
  },
  {
    featureId: "EF004173",
    featureDesc: "Number of connections conductor to 16 mm² fixed",
    label: "EF004173: Number of connections conductor to 16 mm² fixed"
  },
  {
    featureId: "EF004174",
    featureDesc: "Number of connections conductor to 25 mm² fixed",
    label: "EF004174: Number of connections conductor to 25 mm² fixed"
  },
  {
    featureId: "EF004175",
    featureDesc: "Number of connections conductor to 6 mm² fixed",
    label: "EF004175: Number of connections conductor to 6 mm² fixed"
  },
  {
    featureId: "EF004176",
    featureDesc: "Number of connections conductor to 95 mm² fixed",
    label: "EF004176: Number of connections conductor to 95 mm² fixed"
  },
  {
    featureId: "EF004177",
    featureDesc: "Number of connections round conductor 10 mm",
    label: "EF004177: Number of connections round conductor 10 mm"
  },
  {
    featureId: "EF004178",
    featureDesc: "Number of connections round conductor 8 mm",
    label: "EF004178: Number of connections round conductor 8 mm"
  },
  {
    featureId: "EF004179",
    featureDesc: "Number of connections round conductor 8-10 mm",
    label: "EF004179: Number of connections round conductor 8-10 mm"
  },
  {
    featureId: "EF004180",
    featureDesc: "Lightning conductor insertion",
    label: "EF004180: Lightning conductor insertion"
  },
  {
    featureId: "EF004181",
    featureDesc: "Exhausting",
    label: "EF004181: Exhausting"
  },
  {
    featureId: "EF004183",
    featureDesc: "Mounting type conductor",
    label: "EF004183: Mounting type conductor"
  },
  {
    featureId: "EF004184",
    featureDesc: "Mounting type bar",
    label: "EF004184: Mounting type bar"
  },
  {
    featureId: "EF004185",
    featureDesc: "Mounting type holder",
    label: "EF004185: Mounting type holder"
  },
  {
    featureId: "EF004186",
    featureDesc: "Mounting hole",
    label: "EF004186: Mounting hole"
  },
  {
    featureId: "EF004187",
    featureDesc: "Diameter connection plate",
    label: "EF004187: Diameter connection plate"
  },
  {
    featureId: "EF004188",
    featureDesc: "Diameter fibres",
    label: "EF004188: Diameter fibres"
  },
  {
    featureId: "EF004191",
    featureDesc: "Explosion-tested version",
    label: "EF004191: Explosion-tested version"
  },
  {
    featureId: "EF004192",
    featureDesc: "Remote signalling",
    label: "EF004192: Remote signalling"
  },
  {
    featureId: "EF004193",
    featureDesc: "Follow current extinguishing capability",
    label: "EF004193: Follow current extinguishing capability"
  },
  {
    featureId: "EF004194",
    featureDesc: "Suitable for holding shaft type",
    label: "EF004194: Suitable for holding shaft type"
  },
  {
    featureId: "EF004195",
    featureDesc: "Suitable for diameter round conductor",
    label: "EF004195: Suitable for diameter round conductor"
  },
  {
    featureId: "EF004197",
    featureDesc: "Suitable for connection",
    label: "EF004197: Suitable for connection"
  },
  {
    featureId: "EF004198",
    featureDesc: "Max. continuous voltage AC",
    label: "EF004198: Max. continuous voltage AC"
  },
  {
    featureId: "EF004200",
    featureDesc: "Insulator",
    label: "EF004200: Insulator"
  },
  {
    featureId: "EF004201",
    featureDesc: "Clamping/chucking range",
    label: "EF004201: Clamping/chucking range"
  },
  {
    featureId: "EF004204",
    featureDesc: "Clamping range round conductor",
    label: "EF004204: Clamping range round conductor"
  },
  {
    featureId: "EF004205",
    featureDesc: "Clamp width flat conductor",
    label: "EF004205: Clamp width flat conductor"
  },
  {
    featureId: "EF004207",
    featureDesc: "Form of contact",
    label: "EF004207: Form of contact"
  },
  {
    featureId: "EF004208",
    featureDesc: "Length carrier",
    label: "EF004208: Length carrier"
  },
  {
    featureId: "EF004211",
    featureDesc: "Conductor diameter",
    label: "EF004211: Conductor diameter"
  },
  {
    featureId: "EF004214",
    featureDesc: "Nominal discharge surge current (8/20)",
    label: "EF004214: Nominal discharge surge current (8/20)"
  },
  {
    featureId: "EF004215",
    featureDesc: "Total discharge current (8/20) (L+N-PE)",
    label: "EF004215: Total discharge current (8/20) (L+N-PE)"
  },
  {
    featureId: "EF004216",
    featureDesc: "Nominal discharge surge current (8/20) (L-N)",
    label: "EF004216: Nominal discharge surge current (8/20) (L-N)"
  },
  {
    featureId: "EF004218",
    featureDesc: "Nominal inductivity",
    label: "EF004218: Nominal inductivity"
  },
  {
    featureId: "EF004219",
    featureDesc: "Nominal load current",
    label: "EF004219: Nominal load current"
  },
  {
    featureId: "EF004220",
    featureDesc: "Nominal voltage AC",
    label: "EF004220: Nominal voltage AC"
  },
  {
    featureId: "EF004221",
    featureDesc: "Nominal voltage DC",
    label: "EF004221: Nominal voltage DC"
  },
  {
    featureId: "EF004226",
    featureDesc: "Surface of the clamp",
    label: "EF004226: Surface of the clamp"
  },
  {
    featureId: "EF004227",
    featureDesc: "Surface of the contact rail",
    label: "EF004227: Surface of the contact rail"
  },
  {
    featureId: "EF004228",
    featureDesc: "Test class",
    label: "EF004228: Test class"
  },
  {
    featureId: "EF004229",
    featureDesc: "Category type 1",
    label: "EF004229: Category type 1"
  },
  {
    featureId: "EF004230",
    featureDesc: "Category type 2",
    label: "EF004230: Category type 2"
  },
  {
    featureId: "EF004231",
    featureDesc: "Category type 3",
    label: "EF004231: Category type 3"
  },
  {
    featureId: "EF004232",
    featureDesc: "Pipe chucking range",
    label: "EF004232: Pipe chucking range"
  },
  {
    featureId: "EF004233",
    featureDesc: "Voltage protection level core/core",
    label: "EF004233: Voltage protection level core/core"
  },
  {
    featureId: "EF004234",
    featureDesc: "Voltage protection level core/earth",
    label: "EF004234: Voltage protection level core/earth"
  },
  {
    featureId: "EF004236",
    featureDesc: "Voltage protection level L-N",
    label: "EF004236: Voltage protection level L-N"
  },
  {
    featureId: "EF004237",
    featureDesc: "Voltage protection level L-PE/N-PE",
    label: "EF004237: Voltage protection level L-PE/N-PE"
  },
  {
    featureId: "EF004238",
    featureDesc: "Signal critical frequency",
    label: "EF004238: Signal critical frequency"
  },
  {
    featureId: "EF004239",
    featureDesc: "Signal - max. continuous voltage AC",
    label: "EF004239: Signal - max. continuous voltage AC"
  },
  {
    featureId: "EF004240",
    featureDesc: "Signal - max. continuous voltage DC",
    label: "EF004240: Signal - max. continuous voltage DC"
  },
  {
    featureId: "EF004241",
    featureDesc: "Signalling at the device",
    label: "EF004241: Signalling at the device"
  },
  {
    featureId: "EF004242",
    featureDesc: "Signal nominal discharge surge current (8/20)",
    label: "EF004242: Signal nominal discharge surge current (8/20)"
  },
  {
    featureId: "EF004243",
    featureDesc: "Signal nominal load current",
    label: "EF004243: Signal nominal load current"
  },
  {
    featureId: "EF004244",
    featureDesc: "Signal nominal voltage AC",
    label: "EF004244: Signal nominal voltage AC"
  },
  {
    featureId: "EF004245",
    featureDesc: "Signal nominal voltage DC",
    label: "EF004245: Signal nominal voltage DC"
  },
  {
    featureId: "EF004246",
    featureDesc: "Signal safety level core/core",
    label: "EF004246: Signal safety level core/core"
  },
  {
    featureId: "EF004247",
    featureDesc: "Signal safety level core/earth",
    label: "EF004247: Signal safety level core/earth"
  },
  {
    featureId: "EF004248",
    featureDesc: "Rod diameter",
    label: "EF004248: Rod diameter"
  },
  {
    featureId: "EF004249",
    featureDesc: "Diminished from 16 mm to 10 mm",
    label: "EF004249: Diminished from 16 mm to 10 mm"
  },
  {
    featureId: "EF004250",
    featureDesc: "Material of connection",
    label: "EF004250: Material of connection"
  },
  {
    featureId: "EF004251",
    featureDesc: "Material of holder",
    label: "EF004251: Material of holder"
  },
  {
    featureId: "EF004252",
    featureDesc: "Material of clamp",
    label: "EF004252: Material of clamp"
  },
  {
    featureId: "EF004253",
    featureDesc: "Material of contact rail",
    label: "EF004253: Material of contact rail"
  },
  {
    featureId: "EF004254",
    featureDesc: "Material of carrier",
    label: "EF004254: Material of carrier"
  },
  {
    featureId: "EF004255",
    featureDesc: "Characteristic conductor material",
    label: "EF004255: Characteristic conductor material"
  },
  {
    featureId: "EF004257",
    featureDesc: "Bimetal connector",
    label: "EF004257: Bimetal connector"
  },
  {
    featureId: "EF004258",
    featureDesc: "Number of lighting heads",
    label: "EF004258: Number of lighting heads"
  },
  {
    featureId: "EF004259",
    featureDesc: "Type of luminaire",
    label: "EF004259: Type of luminaire"
  },
  {
    featureId: "EF004264",
    featureDesc: "Direct-/indirect proportion separately controllable",
    label: "EF004264: Direct-/indirect proportion separately controllable"
  },
  {
    featureId: "EF004265",
    featureDesc: "Built-in length",
    label: "EF004265: Built-in length"
  },
  {
    featureId: "EF004267",
    featureDesc: "Electrical connection with connector system",
    label: "EF004267: Electrical connection with connector system"
  },
  {
    featureId: "EF004269",
    featureDesc: "Colour cover",
    label: "EF004269: Colour cover"
  },
  {
    featureId: "EF004274",
    featureDesc: "Suitable for light line configuration",
    label: "EF004274: Suitable for light line configuration"
  },
  {
    featureId: "EF004275",
    featureDesc: "Suitable for emergency lighting",
    label: "EF004275: Suitable for emergency lighting"
  },
  {
    featureId: "EF004280",
    featureDesc: "Lamp power filament 1",
    label: "EF004280: Lamp power filament 1"
  },
  {
    featureId: "EF004281",
    featureDesc: "Lamp power filament 2",
    label: "EF004281: Lamp power filament 2"
  },
  {
    featureId: "EF004282",
    featureDesc: "Light outlet",
    label: "EF004282: Light outlet"
  },
  {
    featureId: "EF004283",
    featureDesc: "Light sharing",
    label: "EF004283: Light sharing"
  },
  {
    featureId: "EF004284",
    featureDesc: "Material cover",
    label: "EF004284: Material cover"
  },
  {
    featureId: "EF004285",
    featureDesc: "Max. electrical capacity per phase",
    label: "EF004285: Max. electrical capacity per phase"
  },
  {
    featureId: "EF004286",
    featureDesc: "Pendant length",
    label: "EF004286: Pendant length"
  },
  {
    featureId: "EF004287",
    featureDesc: "Cross section wiring cores",
    label: "EF004287: Cross section wiring cores"
  },
  {
    featureId: "EF004290",
    featureDesc: "Material reflector",
    label: "EF004290: Material reflector"
  },
  {
    featureId: "EF004292",
    featureDesc: "Ring diameter",
    label: "EF004292: Ring diameter"
  },
  {
    featureId: "EF004293",
    featureDesc: "Impact strength",
    label: "EF004293: Impact strength"
  },
  {
    featureId: "EF004294",
    featureDesc: "Over rollable",
    label: "EF004294: Over rollable"
  },
  {
    featureId: "EF004295",
    featureDesc: "Variable distance of electrical units",
    label: "EF004295: Variable distance of electrical units"
  },
  {
    featureId: "EF004298",
    featureDesc: "Nominal voltage",
    label: "EF004298: Nominal voltage"
  },
  {
    featureId: "EF004301",
    featureDesc: "1st interval unit",
    label: "EF004301: 1st interval unit"
  },
  {
    featureId: "EF004306",
    featureDesc: "Number of ports 10 Gbit SC (SR)",
    label: "EF004306: Number of ports 10 Gbit SC (SR)"
  },
  {
    featureId: "EF004307",
    featureDesc: "Number of ports 10 Gbps LC (LX)",
    label: "EF004307: Number of ports 10 Gbps LC (LX)"
  },
  {
    featureId: "EF004308",
    featureDesc: "Number of ports 10 Gbps LC (SX)",
    label: "EF004308: Number of ports 10 Gbps LC (SX)"
  },
  {
    featureId: "EF004311",
    featureDesc: "10 Gigabit Ethernet",
    label: "EF004311: 10 Gigabit Ethernet"
  },
  {
    featureId: "EF004312",
    featureDesc: "Number of ports 10 Mbps MTRJ",
    label: "EF004312: Number of ports 10 Mbps MTRJ"
  },
  {
    featureId: "EF004314",
    featureDesc: "Number of ports 10/100 Mbps LAN",
    label: "EF004314: Number of ports 10/100 Mbps LAN"
  },
  {
    featureId: "EF004315",
    featureDesc: "Number of ports 10/100 Mbps M12",
    label: "EF004315: Number of ports 10/100 Mbps M12"
  },
  {
    featureId: "EF004316",
    featureDesc: "Number of ports 10/100/1000 Mbps RJ45",
    label: "EF004316: Number of ports 10/100/1000 Mbps RJ45"
  },
  {
    featureId: "EF004317",
    featureDesc: "Number of ports 100/1000 Mbps RJ45",
    label: "EF004317: Number of ports 100/1000 Mbps RJ45"
  },
  {
    featureId: "EF004318",
    featureDesc: "Number of ports 1000 Mbps GBIC",
    label: "EF004318: Number of ports 1000 Mbps GBIC"
  },
  {
    featureId: "EF004319",
    featureDesc: "Number of ports 1000 Mbps LC (LX)",
    label: "EF004319: Number of ports 1000 Mbps LC (LX)"
  },
  {
    featureId: "EF004320",
    featureDesc: "Number of ports 1000 Mbps LC (SX)",
    label: "EF004320: Number of ports 1000 Mbps LC (SX)"
  },
  {
    featureId: "EF004321",
    featureDesc: "Number of ports 1000 Mbps Mini GBIC",
    label: "EF004321: Number of ports 1000 Mbps Mini GBIC"
  },
  {
    featureId: "EF004323",
    featureDesc: "19 inch-keyboard",
    label: "EF004323: 19 inch-keyboard"
  },
  {
    featureId: "EF004324",
    featureDesc: "2nd interval unit",
    label: "EF004324: 2nd interval unit"
  },
  {
    featureId: "EF004327",
    featureDesc: "With point outlet",
    label: "EF004327: With point outlet"
  },
  {
    featureId: "EF004328",
    featureDesc: "Max. length",
    label: "EF004328: Max. length"
  },
  {
    featureId: "EF004329",
    featureDesc: "Max. surface temperature",
    label: "EF004329: Max. surface temperature"
  },
  {
    featureId: "EF004331",
    featureDesc: "Connection cross section",
    label: "EF004331: Connection cross section"
  },
  {
    featureId: "EF004332",
    featureDesc: "1st interval",
    label: "EF004332: 1st interval"
  },
  {
    featureId: "EF004333",
    featureDesc: "2nd interval",
    label: "EF004333: 2nd interval"
  },
  {
    featureId: "EF004335",
    featureDesc: "360° base",
    label: "EF004335: 360° base"
  },
  {
    featureId: "EF004338",
    featureDesc: "Snap-off knife blades",
    label: "EF004338: Snap-off knife blades"
  },
  {
    featureId: "EF004339",
    featureDesc: "Cover monitoring",
    label: "EF004339: Cover monitoring"
  },
  {
    featureId: "EF004340",
    featureDesc: "Cover mounting opening",
    label: "EF004340: Cover mounting opening"
  },
  {
    featureId: "EF004341",
    featureDesc: "Number of branches per phase",
    label: "EF004341: Number of branches per phase"
  },
  {
    featureId: "EF004344",
    featureDesc: "Terminal resistors",
    label: "EF004344: Terminal resistors"
  },
  {
    featureId: "EF004345",
    featureDesc: "Automatic failure reports",
    label: "EF004345: Automatic failure reports"
  },
  {
    featureId: "EF004346",
    featureDesc: "Distance",
    label: "EF004346: Distance"
  },
  {
    featureId: "EF004347",
    featureDesc: "Distance relating to",
    label: "EF004347: Distance relating to"
  },
  {
    featureId: "EF004349",
    featureDesc: "Nominal output power AC",
    label: "EF004349: Nominal output power AC"
  },
  {
    featureId: "EF004351",
    featureDesc: "Battery quality",
    label: "EF004351: Battery quality"
  },
  {
    featureId: "EF004352",
    featureDesc: "Active",
    label: "EF004352: Active"
  },
  {
    featureId: "EF004353",
    featureDesc: "Acoustic notification",
    label: "EF004353: Acoustic notification"
  },
  {
    featureId: "EF004354",
    featureDesc: "Adjustable to duct width",
    label: "EF004354: Adjustable to duct width"
  },
  {
    featureId: "EF004356",
    featureDesc: "Analogue connection",
    label: "EF004356: Analogue connection"
  },
  {
    featureId: "EF004357",
    featureDesc: "Number of analogue connections",
    label: "EF004357: Number of analogue connections"
  },
  {
    featureId: "EF004358",
    featureDesc: "Analogue bar graph display",
    label: "EF004358: Analogue bar graph display"
  },
  {
    featureId: "EF004360",
    featureDesc: "Indicated measuring range",
    label: "EF004360: Indicated measuring range"
  },
  {
    featureId: "EF004361",
    featureDesc: "Installation size",
    label: "EF004361: Installation size"
  },
  {
    featureId: "EF004364",
    featureDesc: "Sealing profile height",
    label: "EF004364: Sealing profile height"
  },
  {
    featureId: "EF004366",
    featureDesc: "Position sink",
    label: "EF004366: Position sink"
  },
  {
    featureId: "EF004367",
    featureDesc: "Report text duration",
    label: "EF004367: Report text duration"
  },
  {
    featureId: "EF004368",
    featureDesc: "Number of announcement texts",
    label: "EF004368: Number of announcement texts"
  },
  {
    featureId: "EF004369",
    featureDesc: "Connection for external antenna",
    label: "EF004369: Connection for external antenna"
  },
  {
    featureId: "EF004372",
    featureDesc: "Connection type backside",
    label: "EF004372: Connection type backside"
  },
  {
    featureId: "EF004373",
    featureDesc: "Connection type front side",
    label: "EF004373: Connection type front side"
  },
  {
    featureId: "EF004374",
    featureDesc: "Connection type construction part",
    label: "EF004374: Connection type construction part"
  },
  {
    featureId: "EF004376",
    featureDesc: "Connecting thread metric",
    label: "EF004376: Connecting thread metric"
  },
  {
    featureId: "EF004379",
    featureDesc: "Connection halogen free",
    label: "EF004379: Connection halogen free"
  },
  {
    featureId: "EF004382",
    featureDesc: "Just-operate current",
    label: "EF004382: Just-operate current"
  },
  {
    featureId: "EF004383",
    featureDesc: "Aerial gain",
    label: "EF004383: Aerial gain"
  },
  {
    featureId: "EF004384",
    featureDesc: "Anti-shock system (CD)",
    label: "EF004384: Anti-shock system (CD)"
  },
  {
    featureId: "EF004385",
    featureDesc: "Anti-shock system (MP3)",
    label: "EF004385: Anti-shock system (MP3)"
  },
  {
    featureId: "EF004389",
    featureDesc: "Number of connection cables",
    label: "EF004389: Number of connection cables"
  },
  {
    featureId: "EF004391",
    featureDesc: "Number of drawers/baskets",
    label: "EF004391: Number of drawers/baskets"
  },
  {
    featureId: "EF004392",
    featureDesc: "Number of automatic cooking plates",
    label: "EF004392: Number of automatic cooking plates"
  },
  {
    featureId: "EF004394",
    featureDesc: "Number of high-speed cooking plates",
    label: "EF004394: Number of high-speed cooking plates"
  },
  {
    featureId: "EF004395",
    featureDesc: "Number of buses/couplings",
    label: "EF004395: Number of buses/couplings"
  },
  {
    featureId: "EF004400",
    featureDesc: "Number of dimmer functions",
    label: "EF004400: Number of dimmer functions"
  },
  {
    featureId: "EF004401",
    featureDesc: "Number of double cores",
    label: "EF004401: Number of double cores"
  },
  {
    featureId: "EF004405",
    featureDesc: "Number of input current circuits",
    label: "EF004405: Number of input current circuits"
  },
  {
    featureId: "EF004408",
    featureDesc: "Number of permanent partitions",
    label: "EF004408: Number of permanent partitions"
  },
  {
    featureId: "EF004409",
    featureDesc: "Number of bottles storable (0.75 l Bordeaux)",
    label: "EF004409: Number of bottles storable (0.75 l Bordeaux)"
  },
  {
    featureId: "EF004410",
    featureDesc: "Number of stew burners",
    label: "EF004410: Number of stew burners"
  },
  {
    featureId: "EF004411",
    featureDesc: "Number of freezing compartments with covers",
    label: "EF004411: Number of freezing compartments with covers"
  },
  {
    featureId: "EF004412",
    featureDesc: "Number of joints",
    label: "EF004412: Number of joints"
  },
  {
    featureId: "EF004413",
    featureDesc: "Number of basic programs standard",
    label: "EF004413: Number of basic programs standard"
  },
  {
    featureId: "EF004415",
    featureDesc: "Number of heating levels",
    label: "EF004415: Number of heating levels"
  },
  {
    featureId: "EF004416",
    featureDesc: "Number of HF-inputs",
    label: "EF004416: Number of HF-inputs"
  },
  {
    featureId: "EF004419",
    featureDesc: "Number of cables",
    label: "EF004419: Number of cables"
  },
  {
    featureId: "EF004421",
    featureDesc: "Number of cables/conduits",
    label: "EF004421: Number of cables/conduits"
  },
  {
    featureId: "EF004422",
    featureDesc: "Number of cooking plates (simultaneously usable)",
    label: "EF004422: Number of cooking plates (simultaneously usable)"
  },
  {
    featureId: "EF004423",
    featureDesc: "Number of cables NYM 3x1.5",
    label: "EF004423: Number of cables NYM 3x1.5"
  },
  {
    featureId: "EF004424",
    featureDesc: "Number of conduit outlets",
    label: "EF004424: Number of conduit outlets"
  },
  {
    featureId: "EF004427",
    featureDesc: "Number of modules",
    label: "EF004427: Number of modules"
  },
  {
    featureId: "EF004428",
    featureDesc: "Number of normal burners",
    label: "EF004428: Number of normal burners"
  },
  {
    featureId: "EF004429",
    featureDesc: "Number of normal cooking plates",
    label: "EF004429: Number of normal cooking plates"
  },
  {
    featureId: "EF004430",
    featureDesc: "Number of covers",
    label: "EF004430: Number of covers"
  },
  {
    featureId: "EF004431",
    featureDesc: "Number of programs",
    label: "EF004431: Number of programs"
  },
  {
    featureId: "EF004433",
    featureDesc: "Number of suction motors",
    label: "EF004433: Number of suction motors"
  },
  {
    featureId: "EF004434",
    featureDesc: "Number of cutting edges",
    label: "EF004434: Number of cutting edges"
  },
  {
    featureId: "EF004435",
    featureDesc: "Number of storable values",
    label: "EF004435: Number of storable values"
  },
  {
    featureId: "EF004437",
    featureDesc: "Number of large burners",
    label: "EF004437: Number of large burners"
  },
  {
    featureId: "EF004438",
    featureDesc: "Number of plug-in partitions",
    label: "EF004438: Number of plug-in partitions"
  },
  {
    featureId: "EF004441",
    featureDesc: "Number of stones",
    label: "EF004441: Number of stones"
  },
  {
    featureId: "EF004442",
    featureDesc: "Number of positions total (counter)",
    label: "EF004442: Number of positions total (counter)"
  },
  {
    featureId: "EF004444",
    featureDesc: "Number of memory locations telephone directory",
    label: "EF004444: Number of memory locations telephone directory"
  },
  {
    featureId: "EF004445",
    featureDesc: "Number of temperature zones",
    label: "EF004445: Number of temperature zones"
  },
  {
    featureId: "EF004446",
    featureDesc: "Number of partitions",
    label: "EF004446: Number of partitions"
  },
  {
    featureId: "EF004447",
    featureDesc: "Number of tariff control panels",
    label: "EF004447: Number of tariff control panels"
  },
  {
    featureId: "EF004448",
    featureDesc: "Number of separated cooling circuits",
    label: "EF004448: Number of separated cooling circuits"
  },
  {
    featureId: "EF004450",
    featureDesc: "Number of condensors",
    label: "EF004450: Number of condensors"
  },
  {
    featureId: "EF004453",
    featureDesc: "Number of distributor rows",
    label: "EF004453: Number of distributor rows"
  },
  {
    featureId: "EF004454",
    featureDesc: "Number of controllable inverted rectifiers",
    label: "EF004454: Number of controllable inverted rectifiers"
  },
  {
    featureId: "EF004455",
    featureDesc: "Number of meter boards",
    label: "EF004455: Number of meter boards"
  },
  {
    featureId: "EF004456",
    featureDesc: "Number of strain relieves",
    label: "EF004456: Number of strain relieves"
  },
  {
    featureId: "EF004457",
    featureDesc: "Detachable worktop",
    label: "EF004457: Detachable worktop"
  },
  {
    featureId: "EF004458",
    featureDesc: "Type of worktop",
    label: "EF004458: Type of worktop"
  },
  {
    featureId: "EF004459",
    featureDesc: "Recording type",
    label: "EF004459: Recording type"
  },
  {
    featureId: "EF004460",
    featureDesc: "Type of cooking plates",
    label: "EF004460: Type of cooking plates"
  },
  {
    featureId: "EF004461",
    featureDesc: "Type of air conduction",
    label: "EF004461: Type of air conduction"
  },
  {
    featureId: "EF004462",
    featureDesc: "Type of closure",
    label: "EF004462: Type of closure"
  },
  {
    featureId: "EF004463",
    featureDesc: "Type of temperature control",
    label: "EF004463: Type of temperature control"
  },
  {
    featureId: "EF004464",
    featureDesc: "Type of door",
    label: "EF004464: Type of door"
  },
  {
    featureId: "EF004465",
    featureDesc: "Type of data carrier",
    label: "EF004465: Type of data carrier"
  },
  {
    featureId: "EF004470",
    featureDesc: "Cooking plate model",
    label: "EF004470: Cooking plate model"
  },
  {
    featureId: "EF004471",
    featureDesc: "Fire retardant version",
    label: "EF004471: Fire retardant version"
  },
  {
    featureId: "EF004473",
    featureDesc: "Type of fuse status indicator",
    label: "EF004473: Type of fuse status indicator"
  },
  {
    featureId: "EF004474",
    featureDesc: "Type of conduit outlet",
    label: "EF004474: Type of conduit outlet"
  },
  {
    featureId: "EF004475",
    featureDesc: "Version with cover",
    label: "EF004475: Version with cover"
  },
  {
    featureId: "EF004476",
    featureDesc: "Output 1",
    label: "EF004476: Output 1"
  },
  {
    featureId: "EF004477",
    featureDesc: "Output 2",
    label: "EF004477: Output 2"
  },
  {
    featureId: "EF004478",
    featureDesc: "Output frequency",
    label: "EF004478: Output frequency"
  },
  {
    featureId: "EF004479",
    featureDesc: "Output power",
    label: "EF004479: Output power"
  },
  {
    featureId: "EF004484",
    featureDesc: "Levelling height",
    label: "EF004484: Levelling height"
  },
  {
    featureId: "EF004485",
    featureDesc: "Curing process",
    label: "EF004485: Curing process"
  },
  {
    featureId: "EF004486",
    featureDesc: "Outlet width",
    label: "EF004486: Outlet width"
  },
  {
    featureId: "EF004487",
    featureDesc: "Outlet diameter",
    label: "EF004487: Outlet diameter"
  },
  {
    featureId: "EF004488",
    featureDesc: "Outlet length",
    label: "EF004488: Outlet length"
  },
  {
    featureId: "EF004491",
    featureDesc: "Release delay",
    label: "EF004491: Release delay"
  },
  {
    featureId: "EF004494",
    featureDesc: "Automatic switching summer/winter",
    label: "EF004494: Automatic switching summer/winter"
  },
  {
    featureId: "EF004496",
    featureDesc: "Automatic water level adoption to load",
    label: "EF004496: Automatic water level adoption to load"
  },
  {
    featureId: "EF004497",
    featureDesc: "Automatic ice-cube maker",
    label: "EF004497: Automatic ice-cube maker"
  },
  {
    featureId: "EF004498",
    featureDesc: "AWG-range",
    label: "EF004498: AWG-range"
  },
  {
    featureId: "EF004499",
    featureDesc: "Tie thickness",
    label: "EF004499: Tie thickness"
  },
  {
    featureId: "EF004500",
    featureDesc: "Belt speed",
    label: "EF004500: Belt speed"
  },
  {
    featureId: "EF004501",
    featureDesc: "Bass reflex system",
    label: "EF004501: Bass reflex system"
  },
  {
    featureId: "EF004502",
    featureDesc: "Opening type",
    label: "EF004502: Opening type"
  },
  {
    featureId: "EF004504",
    featureDesc: "Assembly height under carpeting",
    label: "EF004504: Assembly height under carpeting"
  },
  {
    featureId: "EF004505",
    featureDesc: "Assembly height over duct",
    label: "EF004505: Assembly height over duct"
  },
  {
    featureId: "EF004507",
    featureDesc: "Mounting to max. wall thickness",
    label: "EF004507: Mounting to max. wall thickness"
  },
  {
    featureId: "EF004508",
    featureDesc: "Mounting switching equipment",
    label: "EF004508: Mounting switching equipment"
  },
  {
    featureId: "EF004509",
    featureDesc: "Mounting surfaces",
    label: "EF004509: Mounting surfaces"
  },
  {
    featureId: "EF004515",
    featureDesc: "Rated voltage light sensor",
    label: "EF004515: Rated voltage light sensor"
  },
  {
    featureId: "EF004517",
    featureDesc: "Rated current (control contacts)",
    label: "EF004517: Rated current (control contacts)"
  },
  {
    featureId: "EF004520",
    featureDesc: "Contactless",
    label: "EF004520: Contactless"
  },
  {
    featureId: "EF004521",
    featureDesc: "Contact safe",
    label: "EF004521: Contact safe"
  },
  {
    featureId: "EF004523",
    featureDesc: "Suitable for label area",
    label: "EF004523: Suitable for label area"
  },
  {
    featureId: "EF004524",
    featureDesc: "Hydraulic operation",
    label: "EF004524: Hydraulic operation"
  },
  {
    featureId: "EF004525",
    featureDesc: "Mechanical operation",
    label: "EF004525: Mechanical operation"
  },
  {
    featureId: "EF004526",
    featureDesc: "Visibility angle",
    label: "EF004526: Visibility angle"
  },
  {
    featureId: "EF004527",
    featureDesc: "Operation mode grill",
    label: "EF004527: Operation mode grill"
  },
  {
    featureId: "EF004528",
    featureDesc: "Operation mode hot air",
    label: "EF004528: Operation mode hot air"
  },
  {
    featureId: "EF004529",
    featureDesc: "Operation mode top heat/bottom heat",
    label: "EF004529: Operation mode top heat/bottom heat"
  },
  {
    featureId: "EF004530",
    featureDesc: "Operation mode switch",
    label: "EF004530: Operation mode switch"
  },
  {
    featureId: "EF004533",
    featureDesc: "Hour meter",
    label: "EF004533: Hour meter"
  },
  {
    featureId: "EF004534",
    featureDesc: "Operating system",
    label: "EF004534: Operating system"
  },
  {
    featureId: "EF004539",
    featureDesc: "Picture stabilizer",
    label: "EF004539: Picture stabilizer"
  },
  {
    featureId: "EF004540",
    featureDesc: "Bluetooth interface",
    label: "EF004540: Bluetooth interface"
  },
  {
    featureId: "EF004541",
    featureDesc: "With opening carpeting",
    label: "EF004541: With opening carpeting"
  },
  {
    featureId: "EF004543",
    featureDesc: "Protective frame carpeting",
    label: "EF004543: Protective frame carpeting"
  },
  {
    featureId: "EF004544",
    featureDesc: "Bottom plate",
    label: "EF004544: Bottom plate"
  },
  {
    featureId: "EF004545",
    featureDesc: "Bottom perforation",
    label: "EF004545: Bottom perforation"
  },
  {
    featureId: "EF004546",
    featureDesc: "Floor maintenance",
    label: "EF004546: Floor maintenance"
  },
  {
    featureId: "EF004547",
    featureDesc: "Bottom duct",
    label: "EF004547: Bottom duct"
  },
  {
    featureId: "EF004549",
    featureDesc: "Drill diameter soft wood",
    label: "EF004549: Drill diameter soft wood"
  },
  {
    featureId: "EF004552",
    featureDesc: "Drill diameter concrete 3rd gear",
    label: "EF004552: Drill diameter concrete 3rd gear"
  },
  {
    featureId: "EF004553",
    featureDesc: "Drill diameter concrete with hammer drill",
    label: "EF004553: Drill diameter concrete with hammer drill"
  },
  {
    featureId: "EF004554",
    featureDesc: "Drill diameter wood 3rd gear",
    label: "EF004554: Drill diameter wood 3rd gear"
  },
  {
    featureId: "EF004555",
    featureDesc: "Drill diameter wood 4th gear",
    label: "EF004555: Drill diameter wood 4th gear"
  },
  {
    featureId: "EF004556",
    featureDesc: "Drill diameter brickwork with core drill",
    label: "EF004556: Drill diameter brickwork with core drill"
  },
  {
    featureId: "EF004557",
    featureDesc: "Drill diameter steel 3rd gear",
    label: "EF004557: Drill diameter steel 3rd gear"
  },
  {
    featureId: "EF004558",
    featureDesc: "Drill diameter steel 4th gear",
    label: "EF004558: Drill diameter steel 4th gear"
  },
  {
    featureId: "EF004559",
    featureDesc: "Drill diameter stone 1st gear",
    label: "EF004559: Drill diameter stone 1st gear"
  },
  {
    featureId: "EF004560",
    featureDesc: "Drill diameter stone 2nd gear",
    label: "EF004560: Drill diameter stone 2nd gear"
  },
  {
    featureId: "EF004561",
    featureDesc: "Drill diameter stone 3rd gear",
    label: "EF004561: Drill diameter stone 3rd gear"
  },
  {
    featureId: "EF004562",
    featureDesc: "Drill hole diameter",
    label: "EF004562: Drill hole diameter"
  },
  {
    featureId: "EF004563",
    featureDesc: "Drill hole depth",
    label: "EF004563: Drill hole depth"
  },
  {
    featureId: "EF004564",
    featureDesc: "Width outside dimension",
    label: "EF004564: Width outside dimension"
  },
  {
    featureId: "EF004565",
    featureDesc: "Width opening",
    label: "EF004565: Width opening"
  },
  {
    featureId: "EF004566",
    featureDesc: "Width built-in dimension",
    label: "EF004566: Width built-in dimension"
  },
  {
    featureId: "EF004567",
    featureDesc: "Width/diameter",
    label: "EF004567: Width/diameter"
  },
  {
    featureId: "EF004568",
    featureDesc: "Focus",
    label: "EF004568: Focus"
  },
  {
    featureId: "EF004569",
    featureDesc: "Focal size",
    label: "EF004569: Focal size"
  },
  {
    featureId: "EF004570",
    featureDesc: "Bridge function",
    label: "EF004570: Bridge function"
  },
  {
    featureId: "EF004571",
    featureDesc: "Breaking load",
    label: "EF004571: Breaking load"
  },
  {
    featureId: "EF004572",
    featureDesc: "Gross capacity",
    label: "EF004572: Gross capacity"
  },
  {
    featureId: "EF004573",
    featureDesc: "Gross capacity total",
    label: "EF004573: Gross capacity total"
  },
  {
    featureId: "EF004574",
    featureDesc: "Bus type/interface",
    label: "EF004574: Bus type/interface"
  },
  {
    featureId: "EF004576",
    featureDesc: "CD-R",
    label: "EF004576: CD-R"
  },
  {
    featureId: "EF004577",
    featureDesc: "CLIP function",
    label: "EF004577: CLIP function"
  },
  {
    featureId: "EF004579",
    featureDesc: "Roofing material",
    label: "EF004579: Roofing material"
  },
  {
    featureId: "EF004581",
    featureDesc: "Steam cooking",
    label: "EF004581: Steam cooking"
  },
  {
    featureId: "EF004582",
    featureDesc: "Data-exchange",
    label: "EF004582: Data-exchange"
  },
  {
    featureId: "EF004584",
    featureDesc: "DC-system voltage",
    label: "EF004584: DC-system voltage"
  },
  {
    featureId: "EF004586",
    featureDesc: "Cover attachment",
    label: "EF004586: Cover attachment"
  },
  {
    featureId: "EF004587",
    featureDesc: "Cover attachment clamping",
    label: "EF004587: Cover attachment clamping"
  },
  {
    featureId: "EF004591",
    featureDesc: "Decoration",
    label: "EF004591: Decoration"
  },
  {
    featureId: "EF004592",
    featureDesc: "Compatible with third-party covers",
    label: "EF004592: Compatible with third-party covers"
  },
  {
    featureId: "EF004593",
    featureDesc: "Sealing range",
    label: "EF004593: Sealing range"
  },
  {
    featureId: "EF004594",
    featureDesc: "Suitable for placing of sealing",
    label: "EF004594: Suitable for placing of sealing"
  },
  {
    featureId: "EF004596",
    featureDesc: "Digital transmission",
    label: "EF004596: Digital transmission"
  },
  {
    featureId: "EF004598",
    featureDesc: "Dim capacity per channel",
    label: "EF004598: Dim capacity per channel"
  },
  {
    featureId: "EF004600",
    featureDesc: "Direct drive",
    label: "EF004600: Direct drive"
  },
  {
    featureId: "EF004601",
    featureDesc: "Display diagonal",
    label: "EF004601: Display diagonal"
  },
  {
    featureId: "EF004602",
    featureDesc: "DiVx exposure",
    label: "EF004602: DiVx exposure"
  },
  {
    featureId: "EF004603",
    featureDesc: "Wire diameter",
    label: "EF004603: Wire diameter"
  },
  {
    featureId: "EF004604",
    featureDesc: "Indication of direction of field rotation",
    label: "EF004604: Indication of direction of field rotation"
  },
  {
    featureId: "EF004605",
    featureDesc: "Torque setting",
    label: "EF004605: Torque setting"
  },
  {
    featureId: "EF004606",
    featureDesc: "Rotational speed by reverse motion",
    label: "EF004606: Rotational speed by reverse motion"
  },
  {
    featureId: "EF004607",
    featureDesc: "Rotational speed by cutting",
    label: "EF004607: Rotational speed by cutting"
  },
  {
    featureId: "EF004608",
    featureDesc: "Give pressure (external pressure pump)",
    label: "EF004608: Give pressure (external pressure pump)"
  },
  {
    featureId: "EF004609",
    featureDesc: "Pressure measurement",
    label: "EF004609: Pressure measurement"
  },
  {
    featureId: "EF004614",
    featureDesc: "DSSS",
    label: "EF004614: DSSS"
  },
  {
    featureId: "EF004615",
    featureDesc: "Resistance test/diode test",
    label: "EF004615: Resistance test/diode test"
  },
  {
    featureId: "EF004617",
    featureDesc: "Diameter opening",
    label: "EF004617: Diameter opening"
  },
  {
    featureId: "EF004618",
    featureDesc: "Diameter wing nozzle",
    label: "EF004618: Diameter wing nozzle"
  },
  {
    featureId: "EF004619",
    featureDesc: "Diameter rotating tray",
    label: "EF004619: Diameter rotating tray"
  },
  {
    featureId: "EF004620",
    featureDesc: "Diameter",
    label: "EF004620: Diameter"
  },
  {
    featureId: "EF004626",
    featureDesc: "DVD-player",
    label: "EF004626: DVD-player"
  },
  {
    featureId: "EF004628",
    featureDesc: "DVD-R recording",
    label: "EF004628: DVD-R recording"
  },
  {
    featureId: "EF004635",
    featureDesc: "Own consumption",
    label: "EF004635: Own consumption"
  },
  {
    featureId: "EF004637",
    featureDesc: "Build in corner cupboard",
    label: "EF004637: Build in corner cupboard"
  },
  {
    featureId: "EF004638",
    featureDesc: "Build in under sink",
    label: "EF004638: Build in under sink"
  },
  {
    featureId: "EF004640",
    featureDesc: "Built-in size",
    label: "EF004640: Built-in size"
  },
  {
    featureId: "EF004641",
    featureDesc: "Built-in opening for floor column",
    label: "EF004641: Built-in opening for floor column"
  },
  {
    featureId: "EF004644",
    featureDesc: "Input for time program",
    label: "EF004644: Input for time program"
  },
  {
    featureId: "EF004645",
    featureDesc: "Input-/alarm contacts",
    label: "EF004645: Input-/alarm contacts"
  },
  {
    featureId: "EF004646",
    featureDesc: "Built-in light",
    label: "EF004646: Built-in light"
  },
  {
    featureId: "EF004647",
    featureDesc: "Engaging automatic compressed air tool",
    label: "EF004647: Engaging automatic compressed air tool"
  },
  {
    featureId: "EF004648",
    featureDesc: "Engaging automatic electric tool",
    label: "EF004648: Engaging automatic electric tool"
  },
  {
    featureId: "EF004649",
    featureDesc: "Engaging threshold power",
    label: "EF004649: Engaging threshold power"
  },
  {
    featureId: "EF004650",
    featureDesc: "Engaging threshold current",
    label: "EF004650: Engaging threshold current"
  },
  {
    featureId: "EF004651",
    featureDesc: "Adjustment range set-back",
    label: "EF004651: Adjustment range set-back"
  },
  {
    featureId: "EF004652",
    featureDesc: "Adjustment range frost protection",
    label: "EF004652: Adjustment range frost protection"
  },
  {
    featureId: "EF004653",
    featureDesc: "Adjustment range comfort",
    label: "EF004653: Adjustment range comfort"
  },
  {
    featureId: "EF004655",
    featureDesc: "Electrical lifespan (control contacts)",
    label: "EF004655: Electrical lifespan (control contacts)"
  },
  {
    featureId: "EF004656",
    featureDesc: "Receiver",
    label: "EF004656: Receiver"
  },
  {
    featureId: "EF004658",
    featureDesc: "Detection short-circuit current/overcurrent protection",
    label: "EF004658: Detection short-circuit current/overcurrent protection"
  },
  {
    featureId: "EF004659",
    featureDesc: "Equivalent patient leakage current",
    label: "EF004659: Equivalent patient leakage current"
  },
  {
    featureId: "EF004661",
    featureDesc: "Number of Ethernet-connections",
    label: "EF004661: Number of Ethernet-connections"
  },
  {
    featureId: "EF004662",
    featureDesc: "European efficiency",
    label: "EF004662: European efficiency"
  },
  {
    featureId: "EF004663",
    featureDesc: "Explosion-proof",
    label: "EF004663: Explosion-proof"
  },
  {
    featureId: "EF004664",
    featureDesc: "Number of external ISDN connections",
    label: "EF004664: Number of external ISDN connections"
  },
  {
    featureId: "EF004665",
    featureDesc: "Colour print",
    label: "EF004665: Colour print"
  },
  {
    featureId: "EF004667",
    featureDesc: "Colour front",
    label: "EF004667: Colour front"
  },
  {
    featureId: "EF004668",
    featureDesc: "Colour calotte",
    label: "EF004668: Colour calotte"
  },
  {
    featureId: "EF004669",
    featureDesc: "Colour anti-kink sleeve",
    label: "EF004669: Colour anti-kink sleeve"
  },
  {
    featureId: "EF004673",
    featureDesc: "Colour connector system",
    label: "EF004673: Colour connector system"
  },
  {
    featureId: "EF004675",
    featureDesc: "Colour conduit outlet",
    label: "EF004675: Colour conduit outlet"
  },
  {
    featureId: "EF004676",
    featureDesc: "Colour finder",
    label: "EF004676: Colour finder"
  },
  {
    featureId: "EF004678",
    featureDesc: "Capacity espresso cups",
    label: "EF004678: Capacity espresso cups"
  },
  {
    featureId: "EF004679",
    featureDesc: "Capacity dishes (28 cm)",
    label: "EF004679: Capacity dishes (28 cm)"
  },
  {
    featureId: "EF004680",
    featureDesc: "Fax",
    label: "EF004680: Fax"
  },
  {
    featureId: "EF004681",
    featureDesc: "Remote control for testing device",
    label: "EF004681: Remote control for testing device"
  },
  {
    featureId: "EF004682",
    featureDesc: "Memory capacity hard disc",
    label: "EF004682: Memory capacity hard disc"
  },
  {
    featureId: "EF004684",
    featureDesc: "FHSS",
    label: "EF004684: FHSS"
  },
  {
    featureId: "EF004685",
    featureDesc: "Flat cable coupling",
    label: "EF004685: Flat cable coupling"
  },
  {
    featureId: "EF004687",
    featureDesc: "Frequency supply voltage",
    label: "EF004687: Frequency supply voltage"
  },
  {
    featureId: "EF004689",
    featureDesc: "Front plate width",
    label: "EF004689: Front plate width"
  },
  {
    featureId: "EF004690",
    featureDesc: "Front plate height",
    label: "EF004690: Front plate height"
  },
  {
    featureId: "EF004691",
    featureDesc: "Diameter panel opening",
    label: "EF004691: Diameter panel opening"
  },
  {
    featureId: "EF004692",
    featureDesc: "With frost-free system",
    label: "EF004692: With frost-free system"
  },
  {
    featureId: "EF004696",
    featureDesc: "Radio connection",
    label: "EF004696: Radio connection"
  },
  {
    featureId: "EF004697",
    featureDesc: "Radio exchange method (GSM)",
    label: "EF004697: Radio exchange method (GSM)"
  },
  {
    featureId: "EF004698",
    featureDesc: "With switch function",
    label: "EF004698: With switch function"
  },
  {
    featureId: "EF004699",
    featureDesc: "Function buttons with LED",
    label: "EF004699: Function buttons with LED"
  },
  {
    featureId: "EF004700",
    featureDesc: "Function buttons total",
    label: "EF004700: Function buttons total"
  },
  {
    featureId: "EF004702",
    featureDesc: "For number of electric fittings",
    label: "EF004702: For number of electric fittings"
  },
  {
    featureId: "EF004705",
    featureDesc: "For PCF/HCS-fibres 230 µm",
    label: "EF004705: For PCF/HCS-fibres 230 µm"
  },
  {
    featureId: "EF004708",
    featureDesc: "For multimode 125 µm",
    label: "EF004708: For multimode 125 µm"
  },
  {
    featureId: "EF004709",
    featureDesc: "For POF-fibres 1000 µm",
    label: "EF004709: For POF-fibres 1000 µm"
  },
  {
    featureId: "EF004713",
    featureDesc: "For pipe size metric (M..)",
    label: "EF004713: For pipe size metric (M..)"
  },
  {
    featureId: "EF004714",
    featureDesc: "For pipe size PG",
    label: "EF004714: For pipe size PG"
  },
  {
    featureId: "EF004715",
    featureDesc: "For single mode 125 µm",
    label: "EF004715: For single mode 125 µm"
  },
  {
    featureId: "EF004718",
    featureDesc: "Foot switch",
    label: "EF004718: Foot switch"
  },
  {
    featureId: "EF004720",
    featureDesc: "Autonomy in years",
    label: "EF004720: Autonomy in years"
  },
  {
    featureId: "EF004721",
    featureDesc: "Autonomy in hours",
    label: "EF004721: Autonomy in hours"
  },
  {
    featureId: "EF004724",
    featureDesc: "Suitable for outdoor mounting",
    label: "EF004724: Suitable for outdoor mounting"
  },
  {
    featureId: "EF004725",
    featureDesc: "Suitable for built-in devices",
    label: "EF004725: Suitable for built-in devices"
  },
  {
    featureId: "EF004726",
    featureDesc: "Suitable for deep-freeze equipment",
    label: "EF004726: Suitable for deep-freeze equipment"
  },
  {
    featureId: "EF004727",
    featureDesc: "Suitable for upright freezer",
    label: "EF004727: Suitable for upright freezer"
  },
  {
    featureId: "EF004728",
    featureDesc: "Suitable for deep-freezer chest",
    label: "EF004728: Suitable for deep-freezer chest"
  },
  {
    featureId: "EF004730",
    featureDesc: "Suitable for dish washer",
    label: "EF004730: Suitable for dish washer"
  },
  {
    featureId: "EF004731",
    featureDesc: "Suitable for stove",
    label: "EF004731: Suitable for stove"
  },
  {
    featureId: "EF004732",
    featureDesc: "Suitable for combined refrigerator-freezer unit",
    label: "EF004732: Suitable for combined refrigerator-freezer unit"
  },
  {
    featureId: "EF004733",
    featureDesc: "Suitable for refrigeration equipment",
    label: "EF004733: Suitable for refrigeration equipment"
  },
  {
    featureId: "EF004734",
    featureDesc: "Suitable for microwave ovens",
    label: "EF004734: Suitable for microwave ovens"
  },
  {
    featureId: "EF004735",
    featureDesc: "Suitable for standing equipment",
    label: "EF004735: Suitable for standing equipment"
  },
  {
    featureId: "EF004736",
    featureDesc: "Suitable for washing machines",
    label: "EF004736: Suitable for washing machines"
  },
  {
    featureId: "EF004737",
    featureDesc: "Suitable for tumble dryer",
    label: "EF004737: Suitable for tumble dryer"
  },
  {
    featureId: "EF004738",
    featureDesc: "Freezing capacity",
    label: "EF004738: Freezing capacity"
  },
  {
    featureId: "EF004739",
    featureDesc: "Hardened",
    label: "EF004739: Hardened"
  },
  {
    featureId: "EF004742",
    featureDesc: "Mitre angle left",
    label: "EF004742: Mitre angle left"
  },
  {
    featureId: "EF004743",
    featureDesc: "Mitre angle right",
    label: "EF004743: Mitre angle right"
  },
  {
    featureId: "EF004744",
    featureDesc: "Accuracy class meter",
    label: "EF004744: Accuracy class meter"
  },
  {
    featureId: "EF004745",
    featureDesc: "Device conduction current",
    label: "EF004745: Device conduction current"
  },
  {
    featureId: "EF004748",
    featureDesc: "Device stroke",
    label: "EF004748: Device stroke"
  },
  {
    featureId: "EF004750",
    featureDesc: "Chassis part for device cups",
    label: "EF004750: Chassis part for device cups"
  },
  {
    featureId: "EF004752",
    featureDesc: "Total volume",
    label: "EF004752: Total volume"
  },
  {
    featureId: "EF004753",
    featureDesc: "Conversation time to",
    label: "EF004753: Conversation time to"
  },
  {
    featureId: "EF004754",
    featureDesc: "Thread in steel metric",
    label: "EF004754: Thread in steel metric"
  },
  {
    featureId: "EF004756",
    featureDesc: "Connection size thread",
    label: "EF004756: Connection size thread"
  },
  {
    featureId: "EF004757",
    featureDesc: "Thread diameter",
    label: "EF004757: Thread diameter"
  },
  {
    featureId: "EF004759",
    featureDesc: "Thread size M",
    label: "EF004759: Thread size M"
  },
  {
    featureId: "EF004763",
    featureDesc: "Threading (metric) up to",
    label: "EF004763: Threading (metric) up to"
  },
  {
    featureId: "EF004764",
    featureDesc: "Width mesh cable tray",
    label: "EF004764: Width mesh cable tray"
  },
  {
    featureId: "EF004765",
    featureDesc: "Wire mesh tray height",
    label: "EF004765: Wire mesh tray height"
  },
  {
    featureId: "EF004766",
    featureDesc: "Number of grey scales",
    label: "EF004766: Number of grey scales"
  },
  {
    featureId: "EF004767",
    featureDesc: "With grill",
    label: "EF004767: With grill"
  },
  {
    featureId: "EF004768",
    featureDesc: "Group switch",
    label: "EF004768: Group switch"
  },
  {
    featureId: "EF004769",
    featureDesc: "Gripping force",
    label: "EF004769: Gripping force"
  },
  {
    featureId: "EF004771",
    featureDesc: "Main surface material",
    label: "EF004771: Main surface material"
  },
  {
    featureId: "EF004773",
    featureDesc: "HDTV enabled",
    label: "EF004773: HDTV enabled"
  },
  {
    featureId: "EF004774",
    featureDesc: "Hot air",
    label: "EF004774: Hot air"
  },
  {
    featureId: "EF004777",
    featureDesc: "Max. continuous voltage DC",
    label: "EF004777: Max. continuous voltage DC"
  },
  {
    featureId: "EF004778",
    featureDesc: "Height opening",
    label: "EF004778: Height opening"
  },
  {
    featureId: "EF004779",
    featureDesc: "Height without worktop",
    label: "EF004779: Height without worktop"
  },
  {
    featureId: "EF004781",
    featureDesc: "Suitable for side wall height",
    label: "EF004781: Suitable for side wall height"
  },
  {
    featureId: "EF004782",
    featureDesc: "Hearing aid compatible",
    label: "EF004782: Hearing aid compatible"
  },
  {
    featureId: "EF004783",
    featureDesc: "Max. opening",
    label: "EF004783: Max. opening"
  },
  {
    featureId: "EF004788",
    featureDesc: "Indoor",
    label: "EF004788: Indoor"
  },
  {
    featureId: "EF004789",
    featureDesc: "Inductance measurement",
    label: "EF004789: Inductance measurement"
  },
  {
    featureId: "EF004791",
    featureDesc: "Inner fan in cooler unit",
    label: "EF004791: Inner fan in cooler unit"
  },
  {
    featureId: "EF004793",
    featureDesc: "Inner radius",
    label: "EF004793: Inner radius"
  },
  {
    featureId: "EF004796",
    featureDesc: "Integrable in",
    label: "EF004796: Integrable in"
  },
  {
    featureId: "EF004799",
    featureDesc: "Integrated partition",
    label: "EF004799: Integrated partition"
  },
  {
    featureId: "EF004803",
    featureDesc: "Integrated ISDN Gateway",
    label: "EF004803: Integrated ISDN Gateway"
  },
  {
    featureId: "EF004804",
    featureDesc: "Integrated telephone",
    label: "EF004804: Integrated telephone"
  },
  {
    featureId: "EF004805",
    featureDesc: "Number of internal ISDN connections",
    label: "EF004805: Number of internal ISDN connections"
  },
  {
    featureId: "EF004806",
    featureDesc: "ISDN connection",
    label: "EF004806: ISDN connection"
  },
  {
    featureId: "EF004807",
    featureDesc: "Number of ISDN connections (S0-bus, internal)",
    label: "EF004807: Number of ISDN connections (S0-bus, internal)"
  },
  {
    featureId: "EF004808",
    featureDesc: "Number of ISDN base connections (external)",
    label: "EF004808: Number of ISDN base connections (external)"
  },
  {
    featureId: "EF004810",
    featureDesc: "Insulation thickness",
    label: "EF004810: Insulation thickness"
  },
  {
    featureId: "EF004811",
    featureDesc: "Insulation thickness",
    label: "EF004811: Insulation thickness"
  },
  {
    featureId: "EF004812",
    featureDesc: "JPEG exposure",
    label: "EF004812: JPEG exposure"
  },
  {
    featureId: "EF004815",
    featureDesc: "With cable fixing clip",
    label: "EF004815: With cable fixing clip"
  },
  {
    featureId: "EF004816",
    featureDesc: "Cable ladder branch width",
    label: "EF004816: Cable ladder branch width"
  },
  {
    featureId: "EF004817",
    featureDesc: "Cable ladder width",
    label: "EF004817: Cable ladder width"
  },
  {
    featureId: "EF004818",
    featureDesc: "Cable ladder height",
    label: "EF004818: Cable ladder height"
  },
  {
    featureId: "EF004819",
    featureDesc: "Cable tray branch width",
    label: "EF004819: Cable tray branch width"
  },
  {
    featureId: "EF004820",
    featureDesc: "Cable tray width",
    label: "EF004820: Cable tray width"
  },
  {
    featureId: "EF004822",
    featureDesc: "Cable tray height",
    label: "EF004822: Cable tray height"
  },
  {
    featureId: "EF004824",
    featureDesc: "Duct connection",
    label: "EF004824: Duct connection"
  },
  {
    featureId: "EF004825",
    featureDesc: "Duct mounting",
    label: "EF004825: Duct mounting"
  },
  {
    featureId: "EF004826",
    featureDesc: "With duct connector",
    label: "EF004826: With duct connector"
  },
  {
    featureId: "EF004827",
    featureDesc: "Material duct",
    label: "EF004827: Material duct"
  },
  {
    featureId: "EF004828",
    featureDesc: "Cassette height",
    label: "EF004828: Cassette height"
  },
  {
    featureId: "EF004833",
    featureDesc: "Staple length",
    label: "EF004833: Staple length"
  },
  {
    featureId: "EF004834",
    featureDesc: "Staple length at 10 mm width",
    label: "EF004834: Staple length at 10 mm width"
  },
  {
    featureId: "EF004835",
    featureDesc: "Staple length at 4 mm width",
    label: "EF004835: Staple length at 4 mm width"
  },
  {
    featureId: "EF004836",
    featureDesc: "Staple length (flat staple) at 10 mm width",
    label: "EF004836: Staple length (flat staple) at 10 mm width"
  },
  {
    featureId: "EF004838",
    featureDesc: "Lowest resolution, DC voltage",
    label: "EF004838: Lowest resolution, DC voltage"
  },
  {
    featureId: "EF004839",
    featureDesc: "Lowest resolution, DC current",
    label: "EF004839: Lowest resolution, DC current"
  },
  {
    featureId: "EF004840",
    featureDesc: "Lowest resolution, AC voltage",
    label: "EF004840: Lowest resolution, AC voltage"
  },
  {
    featureId: "EF004841",
    featureDesc: "Lowest resolution, AC current",
    label: "EF004841: Lowest resolution, AC current"
  },
  {
    featureId: "EF004842",
    featureDesc: "Lowest resolution, resistance",
    label: "EF004842: Lowest resolution, resistance"
  },
  {
    featureId: "EF004843",
    featureDesc: "Lowest input sensitivity",
    label: "EF004843: Lowest input sensitivity"
  },
  {
    featureId: "EF004845",
    featureDesc: "Shape of blade",
    label: "EF004845: Shape of blade"
  },
  {
    featureId: "EF004846",
    featureDesc: "Cook field control unit integrated",
    label: "EF004846: Cook field control unit integrated"
  },
  {
    featureId: "EF004848",
    featureDesc: "Microwave combination",
    label: "EF004848: Microwave combination"
  },
  {
    featureId: "EF004849",
    featureDesc: "Number of combination-ports RJ45/Mini GBIC",
    label: "EF004849: Number of combination-ports RJ45/Mini GBIC"
  },
  {
    featureId: "EF004851",
    featureDesc: "Contact pin",
    label: "EF004851: Contact pin"
  },
  {
    featureId: "EF004852",
    featureDesc: "Contrast x:1",
    label: "EF004852: Contrast x:1"
  },
  {
    featureId: "EF004853",
    featureDesc: "Photocopier",
    label: "EF004853: Photocopier"
  },
  {
    featureId: "EF004854",
    featureDesc: "Granulation",
    label: "EF004854: Granulation"
  },
  {
    featureId: "EF004855",
    featureDesc: "Shortest switching time channel 1",
    label: "EF004855: Shortest switching time channel 1"
  },
  {
    featureId: "EF004856",
    featureDesc: "Shortest switching time channel 2",
    label: "EF004856: Shortest switching time channel 2"
  },
  {
    featureId: "EF004857",
    featureDesc: "Shortest temperature phase",
    label: "EF004857: Shortest temperature phase"
  },
  {
    featureId: "EF004859",
    featureDesc: "Short-circuit current",
    label: "EF004859: Short-circuit current"
  },
  {
    featureId: "EF004860",
    featureDesc: "Short-time peak load",
    label: "EF004860: Short-time peak load"
  },
  {
    featureId: "EF004861",
    featureDesc: "Charging option for spare batteries",
    label: "EF004861: Charging option for spare batteries"
  },
  {
    featureId: "EF004862",
    featureDesc: "Charging controller integrated",
    label: "EF004862: Charging controller integrated"
  },
  {
    featureId: "EF004863",
    featureDesc: "Charging voltage",
    label: "EF004863: Charging voltage"
  },
  {
    featureId: "EF004864",
    featureDesc: "Position of controller",
    label: "EF004864: Position of controller"
  },
  {
    featureId: "EF004865",
    featureDesc: "Position of blower",
    label: "EF004865: Position of blower"
  },
  {
    featureId: "EF004866",
    featureDesc: "Position of freezing compartment",
    label: "EF004866: Position of freezing compartment"
  },
  {
    featureId: "EF004868",
    featureDesc: "Storage at power failure",
    label: "EF004868: Storage at power failure"
  },
  {
    featureId: "EF004870",
    featureDesc: "Lamp socket",
    label: "EF004870: Lamp socket"
  },
  {
    featureId: "EF004871",
    featureDesc: "LAN connection",
    label: "EF004871: LAN connection"
  },
  {
    featureId: "EF004874",
    featureDesc: "Length connection cord",
    label: "EF004874: Length connection cord"
  },
  {
    featureId: "EF004875",
    featureDesc: "Length built-in dimension",
    label: "EF004875: Length built-in dimension"
  },
  {
    featureId: "EF004878",
    featureDesc: "Load switchable",
    label: "EF004878: Load switchable"
  },
  {
    featureId: "EF004879",
    featureDesc: "On-load speed 1st gear",
    label: "EF004879: On-load speed 1st gear"
  },
  {
    featureId: "EF004880",
    featureDesc: "On-load speed 2nd gear",
    label: "EF004880: On-load speed 2nd gear"
  },
  {
    featureId: "EF004881",
    featureDesc: "Operation indication",
    label: "EF004881: Operation indication"
  },
  {
    featureId: "EF004882",
    featureDesc: "Open listening on",
    label: "EF004882: Open listening on"
  },
  {
    featureId: "EF004883",
    featureDesc: "Layer 2",
    label: "EF004883: Layer 2"
  },
  {
    featureId: "EF004884",
    featureDesc: "Layer 3",
    label: "EF004884: Layer 3"
  },
  {
    featureId: "EF004887",
    featureDesc: "With LCD indication",
    label: "EF004887: With LCD indication"
  },
  {
    featureId: "EF004888",
    featureDesc: "Empty chassis",
    label: "EF004888: Empty chassis"
  },
  {
    featureId: "EF004890",
    featureDesc: "No-load stroke rate",
    label: "EF004890: No-load stroke rate"
  },
  {
    featureId: "EF004891",
    featureDesc: "Open circuit voltage",
    label: "EF004891: Open circuit voltage"
  },
  {
    featureId: "EF004892",
    featureDesc: "Power",
    label: "EF004892: Power"
  },
  {
    featureId: "EF004894",
    featureDesc: "Power step OFF",
    label: "EF004894: Power step OFF"
  },
  {
    featureId: "EF004896",
    featureDesc: "Power tolerance",
    label: "EF004896: Power tolerance"
  },
  {
    featureId: "EF004899",
    featureDesc: "With conductivity measurement",
    label: "EF004899: With conductivity measurement"
  },
  {
    featureId: "EF004900",
    featureDesc: "Adaptive (self-learning)",
    label: "EF004900: Adaptive (self-learning)"
  },
  {
    featureId: "EF004901",
    featureDesc: "Reading strategy",
    label: "EF004901: Reading strategy"
  },
  {
    featureId: "EF004902",
    featureDesc: "Luminaire hook mounting",
    label: "EF004902: Luminaire hook mounting"
  },
  {
    featureId: "EF004903",
    featureDesc: "Type of delivery",
    label: "EF004903: Type of delivery"
  },
  {
    featureId: "EF004905",
    featureDesc: "Link test function",
    label: "EF004905: Link test function"
  },
  {
    featureId: "EF004907",
    featureDesc: "Solvable fastener",
    label: "EF004907: Solvable fastener"
  },
  {
    featureId: "EF004909",
    featureDesc: "Air volume per suction motor",
    label: "EF004909: Air volume per suction motor"
  },
  {
    featureId: "EF004910",
    featureDesc: "Air circulation",
    label: "EF004910: Air circulation"
  },
  {
    featureId: "EF004911",
    featureDesc: "Magnetic",
    label: "EF004911: Magnetic"
  },
  {
    featureId: "EF004912",
    featureDesc: "Manageable",
    label: "EF004912: Manageable"
  },
  {
    featureId: "EF004913",
    featureDesc: "Unit of measurement",
    label: "EF004913: Unit of measurement"
  },
  {
    featureId: "EF004915",
    featureDesc: "Material stepping surface",
    label: "EF004915: Material stepping surface"
  },
  {
    featureId: "EF004916",
    featureDesc: "Material grease filter",
    label: "EF004916: Material grease filter"
  },
  {
    featureId: "EF004919",
    featureDesc: "Max. switching power (control contacts)",
    label: "EF004919: Max. switching power (control contacts)"
  },
  {
    featureId: "EF004920",
    featureDesc: "Max. switching current (cos phi = 0.6)",
    label: "EF004920: Max. switching current (cos phi = 0.6)"
  },
  {
    featureId: "EF004921",
    featureDesc: "Max. time-delayed switch-off",
    label: "EF004921: Max. time-delayed switch-off"
  },
  {
    featureId: "EF004922",
    featureDesc: "Max. number of connections for analogue devices, optional",
    label: "EF004922: Max. number of connections for analogue devices, optional"
  },
  {
    featureId: "EF004923",
    featureDesc: "Max. number of inputs",
    label: "EF004923: Max. number of inputs"
  },
  {
    featureId: "EF004924",
    featureDesc: "Max. number of persons",
    label: "EF004924: Max. number of persons"
  },
  {
    featureId: "EF004925",
    featureDesc: "Max. number of control inputs",
    label: "EF004925: Max. number of control inputs"
  },
  {
    featureId: "EF004926",
    featureDesc: "Max. number of doors",
    label: "EF004926: Max. number of doors"
  },
  {
    featureId: "EF004927",
    featureDesc: "Max. number of video inputs",
    label: "EF004927: Max. number of video inputs"
  },
  {
    featureId: "EF004936",
    featureDesc: "Max. drill diameter",
    label: "EF004936: Max. drill diameter"
  },
  {
    featureId: "EF004937",
    featureDesc: "Max. diameter core drill wet in concrete with suction",
    label: "EF004937: Max. diameter core drill wet in concrete with suction"
  },
  {
    featureId: "EF004938",
    featureDesc: "Max. diameter core drill wet in concrete without suction",
    label: "EF004938: Max. diameter core drill wet in concrete without suction"
  },
  {
    featureId: "EF004939",
    featureDesc: "Max. diameter core drill dry in concrete",
    label: "EF004939: Max. diameter core drill dry in concrete"
  },
  {
    featureId: "EF004940",
    featureDesc: "Max. diameter core drill dry in brickwork",
    label: "EF004940: Max. diameter core drill dry in brickwork"
  },
  {
    featureId: "EF004941",
    featureDesc: "Max. continuous current",
    label: "EF004941: Max. continuous current"
  },
  {
    featureId: "EF004942",
    featureDesc: "Max. input power DC",
    label: "EF004942: Max. input power DC"
  },
  {
    featureId: "EF004943",
    featureDesc: "Max. input voltage",
    label: "EF004943: Max. input voltage"
  },
  {
    featureId: "EF004944",
    featureDesc: "Max. input current",
    label: "EF004944: Max. input current"
  },
  {
    featureId: "EF004945",
    featureDesc: "Max. electrical switching cycles",
    label: "EF004945: Max. electrical switching cycles"
  },
  {
    featureId: "EF004946",
    featureDesc: "Max. rebate depth",
    label: "EF004946: Max. rebate depth"
  },
  {
    featureId: "EF004948",
    featureDesc: "Max. air volume air extraction (according to EN 61591)",
    label: "EF004948: Max. air volume air extraction (according to EN 61591)"
  },
  {
    featureId: "EF004949",
    featureDesc: "Max. blower output recirculation function",
    label: "EF004949: Max. blower output recirculation function"
  },
  {
    featureId: "EF004952",
    featureDesc: "Max. incandescent lamp load",
    label: "EF004952: Max. incandescent lamp load"
  },
  {
    featureId: "EF004953",
    featureDesc: "Max. grill capacity",
    label: "EF004953: Max. grill capacity"
  },
  {
    featureId: "EF004954",
    featureDesc: "Max. stroke",
    label: "EF004954: Max. stroke"
  },
  {
    featureId: "EF004955",
    featureDesc: "Max. pulse repetition",
    label: "EF004955: Max. pulse repetition"
  },
  {
    featureId: "EF004956",
    featureDesc: "Max. number of ISDN connections (S0 bus, internal), optional",
    label: "EF004956: Max. number of ISDN connections (S0 bus, internal), optional"
  },
  {
    featureId: "EF004957",
    featureDesc: "Max. number of ISDN basic connections (external), optional",
    label: "EF004957: Max. number of ISDN basic connections (external), optional"
  },
  {
    featureId: "EF004958",
    featureDesc: "Max. loading current",
    label: "EF004958: Max. loading current"
  },
  {
    featureId: "EF004961",
    featureDesc: "Max. on-load speed",
    label: "EF004961: Max. on-load speed"
  },
  {
    featureId: "EF004962",
    featureDesc: "Max. on-load speed 1st gear",
    label: "EF004962: Max. on-load speed 1st gear"
  },
  {
    featureId: "EF004963",
    featureDesc: "Max. on-load speed 2nd gear",
    label: "EF004963: Max. on-load speed 2nd gear"
  },
  {
    featureId: "EF004964",
    featureDesc: "Max. on-load speed 3rd gear",
    label: "EF004964: Max. on-load speed 3rd gear"
  },
  {
    featureId: "EF004965",
    featureDesc: "Max. on-load speed 4th gear",
    label: "EF004965: Max. on-load speed 4th gear"
  },
  {
    featureId: "EF004969",
    featureDesc: "Max. conductor cross section",
    label: "EF004969: Max. conductor cross section"
  },
  {
    featureId: "EF004970",
    featureDesc: "Max. conductor length light sensor",
    label: "EF004970: Max. conductor length light sensor"
  },
  {
    featureId: "EF004971",
    featureDesc: "Max. load fluorescent lamp",
    label: "EF004971: Max. load fluorescent lamp"
  },
  {
    featureId: "EF004972",
    featureDesc: "Max. load fluorescent lamp (Duo circuit)",
    label: "EF004972: Max. load fluorescent lamp (Duo circuit)"
  },
  {
    featureId: "EF004973",
    featureDesc: "Max. load fluorescent lamp (parallel compensated)",
    label: "EF004973: Max. load fluorescent lamp (parallel compensated)"
  },
  {
    featureId: "EF004974",
    featureDesc: "Max. material thickness in aluminium 250 N/mm²",
    label: "EF004974: Max. material thickness in aluminium 250 N/mm²"
  },
  {
    featureId: "EF004975",
    featureDesc: "Max. material thickness in foam",
    label: "EF004975: Max. material thickness in foam"
  },
  {
    featureId: "EF004976",
    featureDesc: "Max. material thickness in steel 400 N/mm²",
    label: "EF004976: Max. material thickness in steel 400 N/mm²"
  },
  {
    featureId: "EF004977",
    featureDesc: "Max. material thickness in steel 600 N/mm²",
    label: "EF004977: Max. material thickness in steel 600 N/mm²"
  },
  {
    featureId: "EF004978",
    featureDesc: "Max. material thickness in steel 800 N/mm²",
    label: "EF004978: Max. material thickness in steel 800 N/mm²"
  },
  {
    featureId: "EF004979",
    featureDesc: "Max. plate thickness",
    label: "EF004979: Max. plate thickness"
  },
  {
    featureId: "EF004980",
    featureDesc: "Max. pumping pressure",
    label: "EF004980: Max. pumping pressure"
  },
  {
    featureId: "EF004981",
    featureDesc: "Max. switching voltage (control contacts)",
    label: "EF004981: Max. switching voltage (control contacts)"
  },
  {
    featureId: "EF004983",
    featureDesc: "Max. disc diameter",
    label: "EF004983: Max. disc diameter"
  },
  {
    featureId: "EF004984",
    featureDesc: "Max. number of strokes",
    label: "EF004984: Max. number of strokes"
  },
  {
    featureId: "EF004986",
    featureDesc: "Spin speed",
    label: "EF004986: Spin speed"
  },
  {
    featureId: "EF004987",
    featureDesc: "Max. screw diameter",
    label: "EF004987: Max. screw diameter"
  },
  {
    featureId: "EF004988",
    featureDesc: "Max. pivoting range",
    label: "EF004988: Max. pivoting range"
  },
  {
    featureId: "EF004989",
    featureDesc: "Max. voltage measuring range, AC",
    label: "EF004989: Max. voltage measuring range, AC"
  },
  {
    featureId: "EF004990",
    featureDesc: "Max. voltage measuring range, DC",
    label: "EF004990: Max. voltage measuring range, DC"
  },
  {
    featureId: "EF004991",
    featureDesc: "Max. current measuring range, AC",
    label: "EF004991: Max. current measuring range, AC"
  },
  {
    featureId: "EF004992",
    featureDesc: "Max. current measuring range, DC",
    label: "EF004992: Max. current measuring range, DC"
  },
  {
    featureId: "EF004993",
    featureDesc: "Max. system voltage",
    label: "EF004993: Max. system voltage"
  },
  {
    featureId: "EF004995",
    featureDesc: "Max. number of UP0-interfaces, optional",
    label: "EF004995: Max. number of UP0-interfaces, optional"
  },
  {
    featureId: "EF004996",
    featureDesc: "Max. power loss",
    label: "EF004996: Max. power loss"
  },
  {
    featureId: "EF004997",
    featureDesc: "Max. resistance measuring range",
    label: "EF004997: Max. resistance measuring range"
  },
  {
    featureId: "EF004998",
    featureDesc: "Max. effective range",
    label: "EF004998: Max. effective range"
  },
  {
    featureId: "EF005002",
    featureDesc: "Max. value storage",
    label: "EF005002: Max. value storage"
  },
  {
    featureId: "EF005003",
    featureDesc: "Mechanical lifetime (control contacts)",
    label: "EF005003: Mechanical lifetime (control contacts)"
  },
  {
    featureId: "EF005004",
    featureDesc: "Multi seal area",
    label: "EF005004: Multi seal area"
  },
  {
    featureId: "EF005005",
    featureDesc: "Multiple display",
    label: "EF005005: Multiple display"
  },
  {
    featureId: "EF005006",
    featureDesc: "Type of messaging",
    label: "EF005006: Type of messaging"
  },
  {
    featureId: "EF005014",
    featureDesc: "Measuring circuit category",
    label: "EF005014: Measuring circuit category"
  },
  {
    featureId: "EF005015",
    featureDesc: "Measuring mechanism",
    label: "EF005015: Measuring mechanism"
  },
  {
    featureId: "EF005016",
    featureDesc: "MIB I",
    label: "EF005016: MIB I"
  },
  {
    featureId: "EF005017",
    featureDesc: "MIB II",
    label: "EF005017: MIB II"
  },
  {
    featureId: "EF005018",
    featureDesc: "Min. drill diameter",
    label: "EF005018: Min. drill diameter"
  },
  {
    featureId: "EF005022",
    featureDesc: "Min. hollow depth",
    label: "EF005022: Min. hollow depth"
  },
  {
    featureId: "EF005025",
    featureDesc: "Min. rated voltage",
    label: "EF005025: Min. rated voltage"
  },
  {
    featureId: "EF005027",
    featureDesc: "Min. built-in depth",
    label: "EF005027: Min. built-in depth"
  },
  {
    featureId: "EF005028",
    featureDesc: "Min. height",
    label: "EF005028: Min. height"
  },
  {
    featureId: "EF005029",
    featureDesc: "Min. value storage",
    label: "EF005029: Min. value storage"
  },
  {
    featureId: "EF005030",
    featureDesc: "With cover plate for the hob",
    label: "EF005030: With cover plate for the hob"
  },
  {
    featureId: "EF005032",
    featureDesc: "With screening",
    label: "EF005032: With screening"
  },
  {
    featureId: "EF005033",
    featureDesc: "With terminal resistor",
    label: "EF005033: With terminal resistor"
  },
  {
    featureId: "EF005034",
    featureDesc: "With active charcoal",
    label: "EF005034: With active charcoal"
  },
  {
    featureId: "EF005035",
    featureDesc: "With anchor plate",
    label: "EF005035: With anchor plate"
  },
  {
    featureId: "EF005036",
    featureDesc: "With connection cable",
    label: "EF005036: With connection cable"
  },
  {
    featureId: "EF005037",
    featureDesc: "With number of couplings/adapters",
    label: "EF005037: With number of couplings/adapters"
  },
  {
    featureId: "EF005038",
    featureDesc: "With indication",
    label: "EF005038: With indication"
  },
  {
    featureId: "EF005040",
    featureDesc: "With label area",
    label: "EF005040: With label area"
  },
  {
    featureId: "EF005042",
    featureDesc: "With bore hole",
    label: "EF005042: With bore hole"
  },
  {
    featureId: "EF005043",
    featureDesc: "With buses/couplings",
    label: "EF005043: With buses/couplings"
  },
  {
    featureId: "EF005044",
    featureDesc: "With rotary spit motor",
    label: "EF005044: With rotary spit motor"
  },
  {
    featureId: "EF005045",
    featureDesc: "With plug and screw hook",
    label: "EF005045: With plug and screw hook"
  },
  {
    featureId: "EF005046",
    featureDesc: "With filter change indication",
    label: "EF005046: With filter change indication"
  },
  {
    featureId: "EF005047",
    featureDesc: "With earth leakage switch",
    label: "EF005047: With earth leakage switch"
  },
  {
    featureId: "EF005048",
    featureDesc: "With full insulating materials",
    label: "EF005048: With full insulating materials"
  },
  {
    featureId: "EF005049",
    featureDesc: "With locknut",
    label: "EF005049: With locknut"
  },
  {
    featureId: "EF005050",
    featureDesc: "With handle",
    label: "EF005050: With handle"
  },
  {
    featureId: "EF005051",
    featureDesc: "With screw hook",
    label: "EF005051: With screw hook"
  },
  {
    featureId: "EF005052",
    featureDesc: "With hand protection",
    label: "EF005052: With hand protection"
  },
  {
    featureId: "EF005055",
    featureDesc: "With control lamp",
    label: "EF005055: With control lamp"
  },
  {
    featureId: "EF005057",
    featureDesc: "With charging cradle",
    label: "EF005057: With charging cradle"
  },
  {
    featureId: "EF005059",
    featureDesc: "With nut",
    label: "EF005059: With nut"
  },
  {
    featureId: "EF005062",
    featureDesc: "With programmable overnight shutoff",
    label: "EF005062: With programmable overnight shutoff"
  },
  {
    featureId: "EF005063",
    featureDesc: "With basket sieve (strainer)",
    label: "EF005063: With basket sieve (strainer)"
  },
  {
    featureId: "EF005066",
    featureDesc: "With screws",
    label: "EF005066: With screws"
  },
  {
    featureId: "EF005067",
    featureDesc: "With screw accessories",
    label: "EF005067: With screw accessories"
  },
  {
    featureId: "EF005068",
    featureDesc: "With security switch off",
    label: "EF005068: With security switch off"
  },
  {
    featureId: "EF005070",
    featureDesc: "With base",
    label: "EF005070: With base"
  },
  {
    featureId: "EF005072",
    featureDesc: "With distance piece",
    label: "EF005072: With distance piece"
  },
  {
    featureId: "EF005074",
    featureDesc: "With temperature indication",
    label: "EF005074: With temperature indication"
  },
  {
    featureId: "EF005076",
    featureDesc: "With washer",
    label: "EF005076: With washer"
  },
  {
    featureId: "EF005077",
    featureDesc: "With video motion detector",
    label: "EF005077: With video motion detector"
  },
  {
    featureId: "EF005080",
    featureDesc: "Middle support",
    label: "EF005080: Middle support"
  },
  {
    featureId: "EF005081",
    featureDesc: "Terminals announceable",
    label: "EF005081: Terminals announceable"
  },
  {
    featureId: "EF005084",
    featureDesc: "Mounting",
    label: "EF005084: Mounting"
  },
  {
    featureId: "EF005086",
    featureDesc: "Mounting method installation unit",
    label: "EF005086: Mounting method installation unit"
  },
  {
    featureId: "EF005088",
    featureDesc: "Mounting cover insertion",
    label: "EF005088: Mounting cover insertion"
  },
  {
    featureId: "EF005089",
    featureDesc: "Mounting surface",
    label: "EF005089: Mounting surface"
  },
  {
    featureId: "EF005090",
    featureDesc: "Mounting opening",
    label: "EF005090: Mounting opening"
  },
  {
    featureId: "EF005091",
    featureDesc: "Mounting opening for floor column",
    label: "EF005091: Mounting opening for floor column"
  },
  {
    featureId: "EF005092",
    featureDesc: "Motor zoom",
    label: "EF005092: Motor zoom"
  },
  {
    featureId: "EF005093",
    featureDesc: "MP3 playing",
    label: "EF005093: MP3 playing"
  },
  {
    featureId: "EF005094",
    featureDesc: "MPP-voltage",
    label: "EF005094: MPP-voltage"
  },
  {
    featureId: "EF005095",
    featureDesc: "Rated MPP-voltage",
    label: "EF005095: Rated MPP-voltage"
  },
  {
    featureId: "EF005096",
    featureDesc: "MPP-current",
    label: "EF005096: MPP-current"
  },
  {
    featureId: "EF005097",
    featureDesc: "MPP-tracking",
    label: "EF005097: MPP-tracking"
  },
  {
    featureId: "EF005099",
    featureDesc: "Multi function",
    label: "EF005099: Multi function"
  },
  {
    featureId: "EF005100",
    featureDesc: "Number of connectable handsets",
    label: "EF005100: Number of connectable handsets"
  },
  {
    featureId: "EF005101",
    featureDesc: "Music power at 8 Ohm",
    label: "EF005101: Music power at 8 Ohm"
  },
  {
    featureId: "EF005102",
    featureDesc: "Music power centre loudspeaker",
    label: "EF005102: Music power centre loudspeaker"
  },
  {
    featureId: "EF005103",
    featureDesc: "Music power front loudspeaker",
    label: "EF005103: Music power front loudspeaker"
  },
  {
    featureId: "EF005104",
    featureDesc: "Music power rear loudspeaker",
    label: "EF005104: Music power rear loudspeaker"
  },
  {
    featureId: "EF005105",
    featureDesc: "Music power subwoofer",
    label: "EF005105: Music power subwoofer"
  },
  {
    featureId: "EF005107",
    featureDesc: "Nail diameter",
    label: "EF005107: Nail diameter"
  },
  {
    featureId: "EF005108",
    featureDesc: "Nail length",
    label: "EF005108: Nail length"
  },
  {
    featureId: "EF005109",
    featureDesc: "Suitable for wet cleaning",
    label: "EF005109: Suitable for wet cleaning"
  },
  {
    featureId: "EF005113",
    featureDesc: "Angle of inclination",
    label: "EF005113: Angle of inclination"
  },
  {
    featureId: "EF005114",
    featureDesc: "Nominal diameter",
    label: "EF005114: Nominal diameter"
  },
  {
    featureId: "EF005115",
    featureDesc: "Nominal size PG",
    label: "EF005115: Nominal size PG"
  },
  {
    featureId: "EF005116",
    featureDesc: "Nominal power",
    label: "EF005116: Nominal power"
  },
  {
    featureId: "EF005117",
    featureDesc: "Nominal test voltage 100 V",
    label: "EF005117: Nominal test voltage 100 V"
  },
  {
    featureId: "EF005118",
    featureDesc: "Nominal test voltage 1000 V",
    label: "EF005118: Nominal test voltage 1000 V"
  },
  {
    featureId: "EF005119",
    featureDesc: "Nominal test voltage 250 V",
    label: "EF005119: Nominal test voltage 250 V"
  },
  {
    featureId: "EF005120",
    featureDesc: "Nominal test voltage 2500 V",
    label: "EF005120: Nominal test voltage 2500 V"
  },
  {
    featureId: "EF005121",
    featureDesc: "Nominal test voltage 500 V",
    label: "EF005121: Nominal test voltage 500 V"
  },
  {
    featureId: "EF005122",
    featureDesc: "Nominal test voltage 5000 V",
    label: "EF005122: Nominal test voltage 5000 V"
  },
  {
    featureId: "EF005123",
    featureDesc: "Nominal test voltage adjustable",
    label: "EF005123: Nominal test voltage adjustable"
  },
  {
    featureId: "EF005124",
    featureDesc: "Nominal cross section",
    label: "EF005124: Nominal cross section"
  },
  {
    featureId: "EF005125",
    featureDesc: "Nominal switching current at 230 V AC",
    label: "EF005125: Nominal switching current at 230 V AC"
  },
  {
    featureId: "EF005126",
    featureDesc: "Nominal switching current at 250 V AC",
    label: "EF005126: Nominal switching current at 250 V AC"
  },
  {
    featureId: "EF005127",
    featureDesc: "Nominal voltage",
    label: "EF005127: Nominal voltage"
  },
  {
    featureId: "EF005129",
    featureDesc: "Net capacity total unit",
    label: "EF005129: Net capacity total unit"
  },
  {
    featureId: "EF005132",
    featureDesc: "Output phases",
    label: "EF005132: Output phases"
  },
  {
    featureId: "EF005133",
    featureDesc: "Network connection",
    label: "EF005133: Network connection"
  },
  {
    featureId: "EF005136",
    featureDesc: "Levelling range",
    label: "EF005136: Levelling range"
  },
  {
    featureId: "EF005137",
    featureDesc: "Standard paper",
    label: "EF005137: Standard paper"
  },
  {
    featureId: "EF005138",
    featureDesc: "Emergency power supply integrated",
    label: "EF005138: Emergency power supply integrated"
  },
  {
    featureId: "EF005139",
    featureDesc: "Groove width",
    label: "EF005139: Groove width"
  },
  {
    featureId: "EF005140",
    featureDesc: "Useful cross section",
    label: "EF005140: Useful cross section"
  },
  {
    featureId: "EF005141",
    featureDesc: "Top heat/bottom heat",
    label: "EF005141: Top heat/bottom heat"
  },
  {
    featureId: "EF005142",
    featureDesc: "Surface protection clamp",
    label: "EF005142: Surface protection clamp"
  },
  {
    featureId: "EF005143",
    featureDesc: "Surface protection screw",
    label: "EF005143: Surface protection screw"
  },
  {
    featureId: "EF005145",
    featureDesc: "Sealing lip cover",
    label: "EF005145: Sealing lip cover"
  },
  {
    featureId: "EF005146",
    featureDesc: "Cover width",
    label: "EF005146: Cover width"
  },
  {
    featureId: "EF005147",
    featureDesc: "Cover width 1",
    label: "EF005147: Cover width 1"
  },
  {
    featureId: "EF005148",
    featureDesc: "Cover width 2",
    label: "EF005148: Cover width 2"
  },
  {
    featureId: "EF005149",
    featureDesc: "Cover width 3",
    label: "EF005149: Cover width 3"
  },
  {
    featureId: "EF005150",
    featureDesc: "Lens connection",
    label: "EF005150: Lens connection"
  },
  {
    featureId: "EF005153",
    featureDesc: "Opening diameter",
    label: "EF005153: Opening diameter"
  },
  {
    featureId: "EF005155",
    featureDesc: "Wash optimizing system",
    label: "EF005155: Wash optimizing system"
  },
  {
    featureId: "EF005156",
    featureDesc: "Optical notification",
    label: "EF005156: Optical notification"
  },
  {
    featureId: "EF005158",
    featureDesc: "Outdoor",
    label: "EF005158: Outdoor"
  },
  {
    featureId: "EF005159",
    featureDesc: "Pad system",
    label: "EF005159: Pad system"
  },
  {
    featureId: "EF005160",
    featureDesc: "Panic function",
    label: "EF005160: Panic function"
  },
  {
    featureId: "EF005161",
    featureDesc: "Paper stock",
    label: "EF005161: Paper stock"
  },
  {
    featureId: "EF005162",
    featureDesc: "Parallel connection",
    label: "EF005162: Parallel connection"
  },
  {
    featureId: "EF005163",
    featureDesc: "Suitable for wire mesh tray",
    label: "EF005163: Suitable for wire mesh tray"
  },
  {
    featureId: "EF005164",
    featureDesc: "Suitable for mesh cable tray filament",
    label: "EF005164: Suitable for mesh cable tray filament"
  },
  {
    featureId: "EF005165",
    featureDesc: "Suitable for cable ladder",
    label: "EF005165: Suitable for cable ladder"
  },
  {
    featureId: "EF005166",
    featureDesc: "Suitable for cable tray",
    label: "EF005166: Suitable for cable tray"
  },
  {
    featureId: "EF005170",
    featureDesc: "PC-binding",
    label: "EF005170: PC-binding"
  },
  {
    featureId: "EF005171",
    featureDesc: "PC-connection",
    label: "EF005171: PC-connection"
  },
  {
    featureId: "EF005172",
    featureDesc: "Perforation diameter",
    label: "EF005172: Perforation diameter"
  },
  {
    featureId: "EF005173",
    featureDesc: "Perforation width",
    label: "EF005173: Perforation width"
  },
  {
    featureId: "EF005174",
    featureDesc: "Perforation length",
    label: "EF005174: Perforation length"
  },
  {
    featureId: "EF005175",
    featureDesc: "Plate width",
    label: "EF005175: Plate width"
  },
  {
    featureId: "EF005176",
    featureDesc: "Plate height",
    label: "EF005176: Plate height"
  },
  {
    featureId: "EF005178",
    featureDesc: "Power over Ethernet",
    label: "EF005178: Power over Ethernet"
  },
  {
    featureId: "EF005179",
    featureDesc: "Profile shape",
    label: "EF005179: Profile shape"
  },
  {
    featureId: "EF005181",
    featureDesc: "Program shift divide 1",
    label: "EF005181: Program shift divide 1"
  },
  {
    featureId: "EF005182",
    featureDesc: "Program shift divide 2",
    label: "EF005182: Program shift divide 2"
  },
  {
    featureId: "EF005183",
    featureDesc: "Type of protocol Apple Ether Talk",
    label: "EF005183: Type of protocol Apple Ether Talk"
  },
  {
    featureId: "EF005185",
    featureDesc: "Type of protocol IP",
    label: "EF005185: Type of protocol IP"
  },
  {
    featureId: "EF005187",
    featureDesc: "Type of protocol IPX/SPX",
    label: "EF005187: Type of protocol IPX/SPX"
  },
  {
    featureId: "EF005188",
    featureDesc: "Type of protocol NetBEUI",
    label: "EF005188: Type of protocol NetBEUI"
  },
  {
    featureId: "EF005190",
    featureDesc: "Type of test",
    label: "EF005190: Type of test"
  },
  {
    featureId: "EF005193",
    featureDesc: "Cross section connection cable",
    label: "EF005193: Cross section connection cable"
  },
  {
    featureId: "EF005196",
    featureDesc: "With frame",
    label: "EF005196: With frame"
  },
  {
    featureId: "EF005197",
    featureDesc: "Frame model",
    label: "EF005197: Frame model"
  },
  {
    featureId: "EF005199",
    featureDesc: "Locking lever",
    label: "EF005199: Locking lever"
  },
  {
    featureId: "EF005203",
    featureDesc: "Forming of reference signal",
    label: "EF005203: Forming of reference signal"
  },
  {
    featureId: "EF005204",
    featureDesc: "Cleaning efficiency class",
    label: "EF005204: Cleaning efficiency class"
  },
  {
    featureId: "EF005205",
    featureDesc: "Relative value measurement",
    label: "EF005205: Relative value measurement"
  },
  {
    featureId: "EF005206",
    featureDesc: "Repeater function",
    label: "EF005206: Repeater function"
  },
  {
    featureId: "EF005207",
    featureDesc: "Reset function",
    label: "EF005207: Reset function"
  },
  {
    featureId: "EF005208",
    featureDesc: "Remain heat indication",
    label: "EF005208: Remain heat indication"
  },
  {
    featureId: "EF005209",
    featureDesc: "Direction",
    label: "EF005209: Direction"
  },
  {
    featureId: "EF005210",
    featureDesc: "Change of direction",
    label: "EF005210: Change of direction"
  },
  {
    featureId: "EF005211",
    featureDesc: "Belt drive",
    label: "EF005211: Belt drive"
  },
  {
    featureId: "EF005212",
    featureDesc: "Tray mounting",
    label: "EF005212: Tray mounting"
  },
  {
    featureId: "EF005213",
    featureDesc: "RMON",
    label: "EF005213: RMON"
  },
  {
    featureId: "EF005214",
    featureDesc: "Tube/cable diameter",
    label: "EF005214: Tube/cable diameter"
  },
  {
    featureId: "EF005215",
    featureDesc: "Tube diameter",
    label: "EF005215: Tube diameter"
  },
  {
    featureId: "EF005220",
    featureDesc: "SACD playing",
    label: "EF005220: SACD playing"
  },
  {
    featureId: "EF005222",
    featureDesc: "Column height",
    label: "EF005222: Column height"
  },
  {
    featureId: "EF005223",
    featureDesc: "Scanner",
    label: "EF005223: Scanner"
  },
  {
    featureId: "EF005224",
    featureDesc: "Sound level",
    label: "EF005224: Sound level"
  },
  {
    featureId: "EF005225",
    featureDesc: "With switching output",
    label: "EF005225: With switching output"
  },
  {
    featureId: "EF005226",
    featureDesc: "Number of switchable ISDN-connections",
    label: "EF005226: Number of switchable ISDN-connections"
  },
  {
    featureId: "EF005228",
    featureDesc: "Switching contacts",
    label: "EF005228: Switching contacts"
  },
  {
    featureId: "EF005229",
    featureDesc: "Switch capacity incandescent lamps",
    label: "EF005229: Switch capacity incandescent lamps"
  },
  {
    featureId: "EF005230",
    featureDesc: "Switch capacity fluorescent lamp DUO",
    label: "EF005230: Switch capacity fluorescent lamp DUO"
  },
  {
    featureId: "EF005231",
    featureDesc: "Switch capacity fluorescent lamp inductive",
    label: "EF005231: Switch capacity fluorescent lamp inductive"
  },
  {
    featureId: "EF005232",
    featureDesc: "Switch capacity fluorescent lamp parallel",
    label: "EF005232: Switch capacity fluorescent lamp parallel"
  },
  {
    featureId: "EF005233",
    featureDesc: "Switch capacity",
    label: "EF005233: Switch capacity"
  },
  {
    featureId: "EF005234",
    featureDesc: "Switching voltage",
    label: "EF005234: Switching voltage"
  },
  {
    featureId: "EF005236",
    featureDesc: "Engaging biometrical",
    label: "EF005236: Engaging biometrical"
  },
  {
    featureId: "EF005237",
    featureDesc: "Engaging electronically",
    label: "EF005237: Engaging electronically"
  },
  {
    featureId: "EF005238",
    featureDesc: "Engaging mental",
    label: "EF005238: Engaging mental"
  },
  {
    featureId: "EF005239",
    featureDesc: "Engaging mechanical",
    label: "EF005239: Engaging mechanical"
  },
  {
    featureId: "EF005240",
    featureDesc: "Slider for hand switch",
    label: "EF005240: Slider for hand switch"
  },
  {
    featureId: "EF005241",
    featureDesc: "Screening effectiveness",
    label: "EF005241: Screening effectiveness"
  },
  {
    featureId: "EF005242",
    featureDesc: "Loop measurement without RCD/FI release",
    label: "EF005242: Loop measurement without RCD/FI release"
  },
  {
    featureId: "EF005243",
    featureDesc: "Abrasive mounting",
    label: "EF005243: Abrasive mounting"
  },
  {
    featureId: "EF005244",
    featureDesc: "Base-plate width",
    label: "EF005244: Base-plate width"
  },
  {
    featureId: "EF005245",
    featureDesc: "Base-plate length",
    label: "EF005245: Base-plate length"
  },
  {
    featureId: "EF005246",
    featureDesc: "Distance of slots",
    label: "EF005246: Distance of slots"
  },
  {
    featureId: "EF005247",
    featureDesc: "Slit width",
    label: "EF005247: Slit width"
  },
  {
    featureId: "EF005248",
    featureDesc: "Cutting speed",
    label: "EF005248: Cutting speed"
  },
  {
    featureId: "EF005249",
    featureDesc: "Cutting trail width",
    label: "EF005249: Cutting trail width"
  },
  {
    featureId: "EF005250",
    featureDesc: "Cutting range at 45°/90°",
    label: "EF005250: Cutting range at 45°/90°"
  },
  {
    featureId: "EF005251",
    featureDesc: "Cutting range at 90°/45°",
    label: "EF005251: Cutting range at 90°/45°"
  },
  {
    featureId: "EF005252",
    featureDesc: "Cutting range at 90°/90°",
    label: "EF005252: Cutting range at 90°/90°"
  },
  {
    featureId: "EF005253",
    featureDesc: "Joint cover",
    label: "EF005253: Joint cover"
  },
  {
    featureId: "EF005254",
    featureDesc: "Interface external",
    label: "EF005254: Interface external"
  },
  {
    featureId: "EF005255",
    featureDesc: "Cutting depth adjustable",
    label: "EF005255: Cutting depth adjustable"
  },
  {
    featureId: "EF005257",
    featureDesc: "Cutting depth in brick work",
    label: "EF005257: Cutting depth in brick work"
  },
  {
    featureId: "EF005258",
    featureDesc: "Cutting depth in sheet-metal (400 N/mm²)",
    label: "EF005258: Cutting depth in sheet-metal (400 N/mm²)"
  },
  {
    featureId: "EF005259",
    featureDesc: "Cutting depth",
    label: "EF005259: Cutting depth"
  },
  {
    featureId: "EF005261",
    featureDesc: "Suitable for screw diameter",
    label: "EF005261: Suitable for screw diameter"
  },
  {
    featureId: "EF005262",
    featureDesc: "Screw diameter",
    label: "EF005262: Screw diameter"
  },
  {
    featureId: "EF005264",
    featureDesc: "Push force",
    label: "EF005264: Push force"
  },
  {
    featureId: "EF005265",
    featureDesc: "Degree of protection (IP) light sensor",
    label: "EF005265: Degree of protection (IP) light sensor"
  },
  {
    featureId: "EF005266",
    featureDesc: "With protective foil",
    label: "EF005266: With protective foil"
  },
  {
    featureId: "EF005267",
    featureDesc: "Pivoting range",
    label: "EF005267: Pivoting range"
  },
  {
    featureId: "EF005268",
    featureDesc: "Hexagon angular dimension",
    label: "EF005268: Hexagon angular dimension"
  },
  {
    featureId: "EF005269",
    featureDesc: "Self boring",
    label: "EF005269: Self boring"
  },
  {
    featureId: "EF005270",
    featureDesc: "Sending-/receiving storage",
    label: "EF005270: Sending-/receiving storage"
  },
  {
    featureId: "EF005271",
    featureDesc: "Transmitter",
    label: "EF005271: Transmitter"
  },
  {
    featureId: "EF005273",
    featureDesc: "Serial connection",
    label: "EF005273: Serial connection"
  },
  {
    featureId: "EF005274",
    featureDesc: "Continuous-advance mode",
    label: "EF005274: Continuous-advance mode"
  },
  {
    featureId: "EF005275",
    featureDesc: "Setting force",
    label: "EF005275: Setting force"
  },
  {
    featureId: "EF005278",
    featureDesc: "Sinus power at 8 Ohm",
    label: "EF005278: Sinus power at 8 Ohm"
  },
  {
    featureId: "EF005279",
    featureDesc: "Sinus power central loudspeaker",
    label: "EF005279: Sinus power central loudspeaker"
  },
  {
    featureId: "EF005280",
    featureDesc: "Sinus power front loudspeaker",
    label: "EF005280: Sinus power front loudspeaker"
  },
  {
    featureId: "EF005281",
    featureDesc: "Sinus power rear loudspeaker",
    label: "EF005281: Sinus power rear loudspeaker"
  },
  {
    featureId: "EF005282",
    featureDesc: "Sinus power subwoofer",
    label: "EF005282: Sinus power subwoofer"
  },
  {
    featureId: "EF005283",
    featureDesc: "SMS-functionality",
    label: "EF005283: SMS-functionality"
  },
  {
    featureId: "EF005284",
    featureDesc: "SNMP",
    label: "EF005284: SNMP"
  },
  {
    featureId: "EF005285",
    featureDesc: "Socket vacuuming nozzle",
    label: "EF005285: Socket vacuuming nozzle"
  },
  {
    featureId: "EF005286",
    featureDesc: "Slit punch",
    label: "EF005286: Slit punch"
  },
  {
    featureId: "EF005288",
    featureDesc: "Clamping neck diameter",
    label: "EF005288: Clamping neck diameter"
  },
  {
    featureId: "EF005289",
    featureDesc: "Clamping cable diameter",
    label: "EF005289: Clamping cable diameter"
  },
  {
    featureId: "EF005290",
    featureDesc: "Voltage output 0 V ... 10 V",
    label: "EF005290: Voltage output 0 V ... 10 V"
  },
  {
    featureId: "EF005291",
    featureDesc: "Voltage output 0 mV ... 100 mV",
    label: "EF005291: Voltage output 0 mV ... 100 mV"
  },
  {
    featureId: "EF005293",
    featureDesc: "Voltage type (excitation voltage)",
    label: "EF005293: Voltage type (excitation voltage)"
  },
  {
    featureId: "EF005294",
    featureDesc: "Voltage type (operating voltage)",
    label: "EF005294: Voltage type (operating voltage)"
  },
  {
    featureId: "EF005295",
    featureDesc: "Voltage measurement",
    label: "EF005295: Voltage measurement"
  },
  {
    featureId: "EF005296",
    featureDesc: "Voltage test (high voltage test)",
    label: "EF005296: Voltage test (high voltage test)"
  },
  {
    featureId: "EF005298",
    featureDesc: "Storage of electrical values (DC)",
    label: "EF005298: Storage of electrical values (DC)"
  },
  {
    featureId: "EF005299",
    featureDesc: "Spiral cut",
    label: "EF005299: Spiral cut"
  },
  {
    featureId: "EF005300",
    featureDesc: "Peak value memory",
    label: "EF005300: Peak value memory"
  },
  {
    featureId: "EF005301",
    featureDesc: "Voice transmission (AWAG)",
    label: "EF005301: Voice transmission (AWAG)"
  },
  {
    featureId: "EF005302",
    featureDesc: "Stabilized",
    label: "EF005302: Stabilized"
  },
  {
    featureId: "EF005303",
    featureDesc: "Standalone",
    label: "EF005303: Standalone"
  },
  {
    featureId: "EF005304",
    featureDesc: "Standby-time up to",
    label: "EF005304: Standby-time up to"
  },
  {
    featureId: "EF005305",
    featureDesc: "With stand (base)",
    label: "EF005305: With stand (base)"
  },
  {
    featureId: "EF005306",
    featureDesc: "Start hole diameter",
    label: "EF005306: Start hole diameter"
  },
  {
    featureId: "EF005307",
    featureDesc: "Start time preselection",
    label: "EF005307: Start time preselection"
  },
  {
    featureId: "EF005308",
    featureDesc: "Socket outlet combination",
    label: "EF005308: Socket outlet combination"
  },
  {
    featureId: "EF005309",
    featureDesc: "Connector",
    label: "EF005309: Connector"
  },
  {
    featureId: "EF005311",
    featureDesc: "Type of connector side 1",
    label: "EF005311: Type of connector side 1"
  },
  {
    featureId: "EF005312",
    featureDesc: "Type of connector side 2",
    label: "EF005312: Type of connector side 2"
  },
  {
    featureId: "EF005315",
    featureDesc: "Control exits",
    label: "EF005315: Control exits"
  },
  {
    featureId: "EF005316",
    featureDesc: "Control inputs",
    label: "EF005316: Control inputs"
  },
  {
    featureId: "EF005318",
    featureDesc: "Control signal at HF input",
    label: "EF005318: Control signal at HF input"
  },
  {
    featureId: "EF005319",
    featureDesc: "Shutter control",
    label: "EF005319: Shutter control"
  },
  {
    featureId: "EF005320",
    featureDesc: "Control over oven/cooker",
    label: "EF005320: Control over oven/cooker"
  },
  {
    featureId: "EF005324",
    featureDesc: "Output current 0 mA ... 24 mA",
    label: "EF005324: Output current 0 mA ... 24 mA"
  },
  {
    featureId: "EF005325",
    featureDesc: "Current surge function, number of channels",
    label: "EF005325: Current surge function, number of channels"
  },
  {
    featureId: "EF005329",
    featureDesc: "Symmetric",
    label: "EF005329: Symmetric"
  },
  {
    featureId: "EF005332",
    featureDesc: "Cycle time (ON/OFF)",
    label: "EF005332: Cycle time (ON/OFF)"
  },
  {
    featureId: "EF005333",
    featureDesc: "TAPI/CTI",
    label: "EF005333: TAPI/CTI"
  },
  {
    featureId: "EF005334",
    featureDesc: "Keyboard interface",
    label: "EF005334: Keyboard interface"
  },
  {
    featureId: "EF005335",
    featureDesc: "Push button input",
    label: "EF005335: Push button input"
  },
  {
    featureId: "EF005336",
    featureDesc: "Duty cycle measurements",
    label: "EF005336: Duty cycle measurements"
  },
  {
    featureId: "EF005337",
    featureDesc: "Separable screw gland",
    label: "EF005337: Separable screw gland"
  },
  {
    featureId: "EF005339",
    featureDesc: "Telephoto lens",
    label: "EF005339: Telephoto lens"
  },
  {
    featureId: "EF005341",
    featureDesc: "Text guidance in display",
    label: "EF005341: Text guidance in display"
  },
  {
    featureId: "EF005342",
    featureDesc: "Text report",
    label: "EF005342: Text report"
  },
  {
    featureId: "EF005343",
    featureDesc: "TFE-interface",
    label: "EF005343: TFE-interface"
  },
  {
    featureId: "EF005344",
    featureDesc: "Thermal element RTD",
    label: "EF005344: Thermal element RTD"
  },
  {
    featureId: "EF005345",
    featureDesc: "Thermal element type B",
    label: "EF005345: Thermal element type B"
  },
  {
    featureId: "EF005346",
    featureDesc: "Thermal element type E",
    label: "EF005346: Thermal element type E"
  },
  {
    featureId: "EF005347",
    featureDesc: "Thermal element type J",
    label: "EF005347: Thermal element type J"
  },
  {
    featureId: "EF005348",
    featureDesc: "Thermal element type K",
    label: "EF005348: Thermal element type K"
  },
  {
    featureId: "EF005349",
    featureDesc: "Thermal element type L",
    label: "EF005349: Thermal element type L"
  },
  {
    featureId: "EF005350",
    featureDesc: "Thermal element type N",
    label: "EF005350: Thermal element type N"
  },
  {
    featureId: "EF005351",
    featureDesc: "Thermal element type R",
    label: "EF005351: Thermal element type R"
  },
  {
    featureId: "EF005352",
    featureDesc: "Thermal element type S",
    label: "EF005352: Thermal element type S"
  },
  {
    featureId: "EF005353",
    featureDesc: "Thermal element type T",
    label: "EF005353: Thermal element type T"
  },
  {
    featureId: "EF005354",
    featureDesc: "Thermal element type U",
    label: "EF005354: Thermal element type U"
  },
  {
    featureId: "EF005356",
    featureDesc: "Depth of flooring recess",
    label: "EF005356: Depth of flooring recess"
  },
  {
    featureId: "EF005357",
    featureDesc: "Low voltage disconnect",
    label: "EF005357: Low voltage disconnect"
  },
  {
    featureId: "EF005358",
    featureDesc: "Number of ports Token Ring",
    label: "EF005358: Number of ports Token Ring"
  },
  {
    featureId: "EF005359",
    featureDesc: "Touch panel",
    label: "EF005359: Touch panel"
  },
  {
    featureId: "EF005361",
    featureDesc: "Partition width",
    label: "EF005361: Partition width"
  },
  {
    featureId: "EF005362",
    featureDesc: "Partition height",
    label: "EF005362: Partition height"
  },
  {
    featureId: "EF005364",
    featureDesc: "Drying system",
    label: "EF005364: Drying system"
  },
  {
    featureId: "EF005365",
    featureDesc: "Drying efficiency class",
    label: "EF005365: Drying efficiency class"
  },
  {
    featureId: "EF005366",
    featureDesc: "Drum volume",
    label: "EF005366: Drum volume"
  },
  {
    featureId: "EF005367",
    featureDesc: "Door build in installation",
    label: "EF005367: Door build in installation"
  },
  {
    featureId: "EF005369",
    featureDesc: "Transmission standard",
    label: "EF005369: Transmission standard"
  },
  {
    featureId: "EF005370",
    featureDesc: "Monitoring radius",
    label: "EF005370: Monitoring radius"
  },
  {
    featureId: "EF005371",
    featureDesc: "Type of clock",
    label: "EF005371: Type of clock"
  },
  {
    featureId: "EF005372",
    featureDesc: "Number of reversible ISDN-connections (external/internal)",
    label: "EF005372: Number of reversible ISDN-connections (external/internal)"
  },
  {
    featureId: "EF005373",
    featureDesc: "Lower connection space",
    label: "EF005373: Lower connection space"
  },
  {
    featureId: "EF005375",
    featureDesc: "Base with partition",
    label: "EF005375: Base with partition"
  },
  {
    featureId: "EF005377",
    featureDesc: "Number of UP0-interfaces",
    label: "EF005377: Number of UP0-interfaces"
  },
  {
    featureId: "EF005378",
    featureDesc: "USB",
    label: "EF005378: USB"
  },
  {
    featureId: "EF005379",
    featureDesc: "Number of USB ports",
    label: "EF005379: Number of USB ports"
  },
  {
    featureId: "EF005380",
    featureDesc: "USV- technology",
    label: "EF005380: USV- technology"
  },
  {
    featureId: "EF005381",
    featureDesc: "With vacuum",
    label: "EF005381: With vacuum"
  },
  {
    featureId: "EF005384",
    featureDesc: "Covered cable route",
    label: "EF005384: Covered cable route"
  },
  {
    featureId: "EF005386",
    featureDesc: "Magnification factor",
    label: "EF005386: Magnification factor"
  },
  {
    featureId: "EF005387",
    featureDesc: "Power loss",
    label: "EF005387: Power loss"
  },
  {
    featureId: "EF005388",
    featureDesc: "Encoding",
    label: "EF005388: Encoding"
  },
  {
    featureId: "EF005389",
    featureDesc: "Supply voltage AC",
    label: "EF005389: Supply voltage AC"
  },
  {
    featureId: "EF005390",
    featureDesc: "Supply voltage required",
    label: "EF005390: Supply voltage required"
  },
  {
    featureId: "EF005394",
    featureDesc: "Number of ports Voice",
    label: "EF005394: Number of ports Voice"
  },
  {
    featureId: "EF005396",
    featureDesc: "Pre-programmed",
    label: "EF005396: Pre-programmed"
  },
  {
    featureId: "EF005400",
    featureDesc: "VPN",
    label: "EF005400: VPN"
  },
  {
    featureId: "EF005401",
    featureDesc: "VPN-security",
    label: "EF005401: VPN-security"
  },
  {
    featureId: "EF005402",
    featureDesc: "Selectable pulse width triggering",
    label: "EF005402: Selectable pulse width triggering"
  },
  {
    featureId: "EF005404",
    featureDesc: "Walkie-Talkie-function",
    label: "EF005404: Walkie-Talkie-function"
  },
  {
    featureId: "EF005405",
    featureDesc: "Transformer connection",
    label: "EF005405: Transformer connection"
  },
  {
    featureId: "EF005410",
    featureDesc: "Warning signal malfunction",
    label: "EF005410: Warning signal malfunction"
  },
  {
    featureId: "EF005411",
    featureDesc: "Water level indication",
    label: "EF005411: Water level indication"
  },
  {
    featureId: "EF005412",
    featureDesc: "Wide angle lens",
    label: "EF005412: Wide angle lens"
  },
  {
    featureId: "EF005413",
    featureDesc: "Material installation column",
    label: "EF005413: Material installation column"
  },
  {
    featureId: "EF005414",
    featureDesc: "Material cover",
    label: "EF005414: Material cover"
  },
  {
    featureId: "EF005415",
    featureDesc: "Material side wall/bottom duct",
    label: "EF005415: Material side wall/bottom duct"
  },
  {
    featureId: "EF005416",
    featureDesc: "Material plug",
    label: "EF005416: Material plug"
  },
  {
    featureId: "EF005418",
    featureDesc: "Material conduit outlet",
    label: "EF005418: Material conduit outlet"
  },
  {
    featureId: "EF005419",
    featureDesc: "Material nail",
    label: "EF005419: Material nail"
  },
  {
    featureId: "EF005420",
    featureDesc: "Material cover",
    label: "EF005420: Material cover"
  },
  {
    featureId: "EF005421",
    featureDesc: "Material disc",
    label: "EF005421: Material disc"
  },
  {
    featureId: "EF005424",
    featureDesc: "Wire stock",
    label: "EF005424: Wire stock"
  },
  {
    featureId: "EF005425",
    featureDesc: "Resistances of isolating floors and walls",
    label: "EF005425: Resistances of isolating floors and walls"
  },
  {
    featureId: "EF005426",
    featureDesc: "Resistance measurement",
    label: "EF005426: Resistance measurement"
  },
  {
    featureId: "EF005429",
    featureDesc: "Wi-Fi-compatible",
    label: "EF005429: Wi-Fi-compatible"
  },
  {
    featureId: "EF005430",
    featureDesc: "Air tight",
    label: "EF005430: Air tight"
  },
  {
    featureId: "EF005431",
    featureDesc: "Bend angle",
    label: "EF005431: Bend angle"
  },
  {
    featureId: "EF005432",
    featureDesc: "Angle adjustable",
    label: "EF005432: Angle adjustable"
  },
  {
    featureId: "EF005433",
    featureDesc: "Angle model",
    label: "EF005433: Angle model"
  },
  {
    featureId: "EF005434",
    featureDesc: "Angle",
    label: "EF005434: Angle"
  },
  {
    featureId: "EF005435",
    featureDesc: "Number of ports wireless",
    label: "EF005435: Number of ports wireless"
  },
  {
    featureId: "EF005436",
    featureDesc: "Weatherproof",
    label: "EF005436: Weatherproof"
  },
  {
    featureId: "EF005437",
    featureDesc: "WMA playing",
    label: "EF005437: WMA playing"
  },
  {
    featureId: "EF005438",
    featureDesc: "X.31/X.25",
    label: "EF005438: X.31/X.25"
  },
  {
    featureId: "EF005440",
    featureDesc: "Count area",
    label: "EF005440: Count area"
  },
  {
    featureId: "EF005441",
    featureDesc: "Type of meter",
    label: "EF005441: Type of meter"
  },
  {
    featureId: "EF005442",
    featureDesc: "Time functions",
    label: "EF005442: Time functions"
  },
  {
    featureId: "EF005443",
    featureDesc: "Cell material",
    label: "EF005443: Cell material"
  },
  {
    featureId: "EF005445",
    featureDesc: "Size of figures",
    label: "EF005445: Size of figures"
  },
  {
    featureId: "EF005446",
    featureDesc: "Strain relief possibility",
    label: "EF005446: Strain relief possibility"
  },
  {
    featureId: "EF005448",
    featureDesc: "App. load pull zone",
    label: "EF005448: App. load pull zone"
  },
  {
    featureId: "EF005449",
    featureDesc: "With approval",
    label: "EF005449: With approval"
  },
  {
    featureId: "EF005452",
    featureDesc: "Width",
    label: "EF005452: Width"
  },
  {
    featureId: "EF005454",
    featureDesc: "Height",
    label: "EF005454: Height"
  },
  {
    featureId: "EF005455",
    featureDesc: "Frequency",
    label: "EF005455: Frequency"
  },
  {
    featureId: "EF005457",
    featureDesc: "Depth",
    label: "EF005457: Depth"
  },
  {
    featureId: "EF005459",
    featureDesc: "Duct height",
    label: "EF005459: Duct height"
  },
  {
    featureId: "EF005462",
    featureDesc: "Nominal size in inch",
    label: "EF005462: Nominal size in inch"
  },
  {
    featureId: "EF005464",
    featureDesc: "Built-in depth",
    label: "EF005464: Built-in depth"
  },
  {
    featureId: "EF005466",
    featureDesc: "Suitable for cable diameter",
    label: "EF005466: Suitable for cable diameter"
  },
  {
    featureId: "EF005467",
    featureDesc: "With connection space",
    label: "EF005467: With connection space"
  },
  {
    featureId: "EF005472",
    featureDesc: "With turntable",
    label: "EF005472: With turntable"
  },
  {
    featureId: "EF005474",
    featureDesc: "Degree of protection (IP)",
    label: "EF005474: Degree of protection (IP)"
  },
  {
    featureId: "EF005476",
    featureDesc: "Dimension",
    label: "EF005476: Dimension"
  },
  {
    featureId: "EF005480",
    featureDesc: "Right-angled",
    label: "EF005480: Right-angled"
  },
  {
    featureId: "EF005483",
    featureDesc: "Length",
    label: "EF005483: Length"
  },
  {
    featureId: "EF005488",
    featureDesc: "With inner cable",
    label: "EF005488: With inner cable"
  },
  {
    featureId: "EF005492",
    featureDesc: "Wall distance",
    label: "EF005492: Wall distance"
  },
  {
    featureId: "EF005495",
    featureDesc: "Suitable for pipe diameter",
    label: "EF005495: Suitable for pipe diameter"
  },
  {
    featureId: "EF005496",
    featureDesc: "For tube diameter",
    label: "EF005496: For tube diameter"
  },
  {
    featureId: "EF005497",
    featureDesc: "Core diameter",
    label: "EF005497: Core diameter"
  },
  {
    featureId: "EF005500",
    featureDesc: "With protective cover",
    label: "EF005500: With protective cover"
  },
  {
    featureId: "EF005502",
    featureDesc: "Connection voltage",
    label: "EF005502: Connection voltage"
  },
  {
    featureId: "EF005508",
    featureDesc: "Laminated",
    label: "EF005508: Laminated"
  },
  {
    featureId: "EF005511",
    featureDesc: "Applicable for multimode",
    label: "EF005511: Applicable for multimode"
  },
  {
    featureId: "EF005512",
    featureDesc: "Applicable for single mode",
    label: "EF005512: Applicable for single mode"
  },
  {
    featureId: "EF005513",
    featureDesc: "With case/bag",
    label: "EF005513: With case/bag"
  },
  {
    featureId: "EF005514",
    featureDesc: "With adapter ST",
    label: "EF005514: With adapter ST"
  },
  {
    featureId: "EF005515",
    featureDesc: "With adapter SC",
    label: "EF005515: With adapter SC"
  },
  {
    featureId: "EF005516",
    featureDesc: "With other adapter",
    label: "EF005516: With other adapter"
  },
  {
    featureId: "EF005517",
    featureDesc: "With object lighting",
    label: "EF005517: With object lighting"
  },
  {
    featureId: "EF005519",
    featureDesc: "0-10 V input",
    label: "EF005519: 0-10 V input"
  },
  {
    featureId: "EF005520",
    featureDesc: "Tightening moment",
    label: "EF005520: Tightening moment"
  },
  {
    featureId: "EF005524",
    featureDesc: "Type of electrical connection, box-sided",
    label: "EF005524: Type of electrical connection, box-sided"
  },
  {
    featureId: "EF005525",
    featureDesc: "Type of electrical connection, field-sided",
    label: "EF005525: Type of electrical connection, field-sided"
  },
  {
    featureId: "EF005527",
    featureDesc: "Type of plug-in contact, inner side",
    label: "EF005527: Type of plug-in contact, inner side"
  },
  {
    featureId: "EF005528",
    featureDesc: "Type of plug-in contact, outer side",
    label: "EF005528: Type of plug-in contact, outer side"
  },
  {
    featureId: "EF005536",
    featureDesc: "Number of 3-pole connectors",
    label: "EF005536: Number of 3-pole connectors"
  },
  {
    featureId: "EF005537",
    featureDesc: "Number of 4-pole connectors",
    label: "EF005537: Number of 4-pole connectors"
  },
  {
    featureId: "EF005538",
    featureDesc: "Number of 5-pole connectors",
    label: "EF005538: Number of 5-pole connectors"
  },
  {
    featureId: "EF005540",
    featureDesc: "Number of earth leakage circuit breakers",
    label: "EF005540: Number of earth leakage circuit breakers"
  },
  {
    featureId: "EF005541",
    featureDesc: "Number of earth leakage switches 300 mA",
    label: "EF005541: Number of earth leakage switches 300 mA"
  },
  {
    featureId: "EF005542",
    featureDesc: "Number of earth leakage switches 30 mA",
    label: "EF005542: Number of earth leakage switches 30 mA"
  },
  {
    featureId: "EF005544",
    featureDesc: "Number of alarm outputs",
    label: "EF005544: Number of alarm outputs"
  },
  {
    featureId: "EF005545",
    featureDesc: "Number of hooks",
    label: "EF005545: Number of hooks"
  },
  {
    featureId: "EF005546",
    featureDesc: "Number of control units",
    label: "EF005546: Number of control units"
  },
  {
    featureId: "EF005548",
    featureDesc: "Number of protected poles",
    label: "EF005548: Number of protected poles"
  },
  {
    featureId: "EF005549",
    featureDesc: "Number of conduit lead-ins",
    label: "EF005549: Number of conduit lead-ins"
  },
  {
    featureId: "EF005550",
    featureDesc: "Number of camera inputs",
    label: "EF005550: Number of camera inputs"
  },
  {
    featureId: "EF005554",
    featureDesc: "Number of connector holes",
    label: "EF005554: Number of connector holes"
  },
  {
    featureId: "EF005555",
    featureDesc: "Number of digits",
    label: "EF005555: Number of digits"
  },
  {
    featureId: "EF005556",
    featureDesc: "Number of direct groups",
    label: "EF005556: Number of direct groups"
  },
  {
    featureId: "EF005558",
    featureDesc: "Number of push buttons",
    label: "EF005558: Number of push buttons"
  },
  {
    featureId: "EF005561",
    featureDesc: "Number of groups behind earth leakage switch",
    label: "EF005561: Number of groups behind earth leakage switch"
  },
  {
    featureId: "EF005564",
    featureDesc: "Number of miniature circuit breakers (MCB)",
    label: "EF005564: Number of miniature circuit breakers (MCB)"
  },
  {
    featureId: "EF005567",
    featureDesc: "Number of selector switches",
    label: "EF005567: Number of selector switches"
  },
  {
    featureId: "EF005568",
    featureDesc: "Number of power current groups 25 A",
    label: "EF005568: Number of power current groups 25 A"
  },
  {
    featureId: "EF005569",
    featureDesc: "Number of power current groups 63 A",
    label: "EF005569: Number of power current groups 63 A"
  },
  {
    featureId: "EF005571",
    featureDesc: "Number of light groups",
    label: "EF005571: Number of light groups"
  },
  {
    featureId: "EF005572",
    featureDesc: "Number of measuring ranges",
    label: "EF005572: Number of measuring ranges"
  },
  {
    featureId: "EF005575",
    featureDesc: "Number of knife blade fuse holders",
    label: "EF005575: Number of knife blade fuse holders"
  },
  {
    featureId: "EF005577",
    featureDesc: "Number of net conductors",
    label: "EF005577: Number of net conductors"
  },
  {
    featureId: "EF005579",
    featureDesc: "Number of perforation rows",
    label: "EF005579: Number of perforation rows"
  },
  {
    featureId: "EF005580",
    featureDesc: "Number of pins",
    label: "EF005580: Number of pins"
  },
  {
    featureId: "EF005581",
    featureDesc: "Number of poles main switch",
    label: "EF005581: Number of poles main switch"
  },
  {
    featureId: "EF005585",
    featureDesc: "Number of washers",
    label: "EF005585: Number of washers"
  },
  {
    featureId: "EF005586",
    featureDesc: "Number of switches",
    label: "EF005586: Number of switches"
  },
  {
    featureId: "EF005590",
    featureDesc: "Number of key switches",
    label: "EF005590: Number of key switches"
  },
  {
    featureId: "EF005591",
    featureDesc: "Number of openings",
    label: "EF005591: Number of openings"
  },
  {
    featureId: "EF005593",
    featureDesc: "Number of teeth per inch",
    label: "EF005593: Number of teeth per inch"
  },
  {
    featureId: "EF005598",
    featureDesc: "Number of square openings",
    label: "EF005598: Number of square openings"
  },
  {
    featureId: "EF005599",
    featureDesc: "Number of make-and-break contacts per direction",
    label: "EF005599: Number of make-and-break contacts per direction"
  },
  {
    featureId: "EF005600",
    featureDesc: "With PE-connection",
    label: "EF005600: With PE-connection"
  },
  {
    featureId: "EF005601",
    featureDesc: "With ground fault protection function",
    label: "EF005601: With ground fault protection function"
  },
  {
    featureId: "EF005604",
    featureDesc: "Inlet from the rear",
    label: "EF005604: Inlet from the rear"
  },
  {
    featureId: "EF005610",
    featureDesc: "With sealing",
    label: "EF005610: With sealing"
  },
  {
    featureId: "EF005614",
    featureDesc: "Dimensions glass fuse",
    label: "EF005614: Dimensions glass fuse"
  },
  {
    featureId: "EF005617",
    featureDesc: "Breaking capacity",
    label: "EF005617: Breaking capacity"
  },
  {
    featureId: "EF005621",
    featureDesc: "Breaking capacity according to IEC 947, Icu, 400 V",
    label: "EF005621: Breaking capacity according to IEC 947, Icu, 400 V"
  },
  {
    featureId: "EF005624",
    featureDesc: "With alarm function",
    label: "EF005624: With alarm function"
  },
  {
    featureId: "EF005625",
    featureDesc: "Only suitable as substation",
    label: "EF005625: Only suitable as substation"
  },
  {
    featureId: "EF005626",
    featureDesc: "American model",
    label: "EF005626: American model"
  },
  {
    featureId: "EF005627",
    featureDesc: "Anti-theft model",
    label: "EF005627: Anti-theft model"
  },
  {
    featureId: "EF005629",
    featureDesc: "Automatic cable winding",
    label: "EF005629: Automatic cable winding"
  },
  {
    featureId: "EF005630",
    featureDesc: "Operation mechanism behind cover/door",
    label: "EF005630: Operation mechanism behind cover/door"
  },
  {
    featureId: "EF005631",
    featureDesc: "Length long leg",
    label: "EF005631: Length long leg"
  },
  {
    featureId: "EF005634",
    featureDesc: "Initial value measuring range (capacitive)",
    label: "EF005634: Initial value measuring range (capacitive)"
  },
  {
    featureId: "EF005635",
    featureDesc: "Initial value scale",
    label: "EF005635: Initial value scale"
  },
  {
    featureId: "EF005636",
    featureDesc: "Initial value scale (capacitive)",
    label: "EF005636: Initial value scale (capacitive)"
  },
  {
    featureId: "EF005637",
    featureDesc: "Jaw width",
    label: "EF005637: Jaw width"
  },
  {
    featureId: "EF005638",
    featureDesc: "Load type",
    label: "EF005638: Load type"
  },
  {
    featureId: "EF005639",
    featureDesc: "With bell transformer",
    label: "EF005639: With bell transformer"
  },
  {
    featureId: "EF005640",
    featureDesc: "Shackle width",
    label: "EF005640: Shackle width"
  },
  {
    featureId: "EF005642",
    featureDesc: "Fastening magnet",
    label: "EF005642: Fastening magnet"
  },
  {
    featureId: "EF005643",
    featureDesc: "Fastening method",
    label: "EF005643: Fastening method"
  },
  {
    featureId: "EF005644",
    featureDesc: "Fastening provision",
    label: "EF005644: Fastening provision"
  },
  {
    featureId: "EF005648",
    featureDesc: "Blade width",
    label: "EF005648: Blade width"
  },
  {
    featureId: "EF005650",
    featureDesc: "Blade length",
    label: "EF005650: Blade length"
  },
  {
    featureId: "EF005651",
    featureDesc: "Blind plate",
    label: "EF005651: Blind plate"
  },
  {
    featureId: "EF005652",
    featureDesc: "Blind cover",
    label: "EF005652: Blind cover"
  },
  {
    featureId: "EF005654",
    featureDesc: "Bolt length",
    label: "EF005654: Bolt length"
  },
  {
    featureId: "EF005655",
    featureDesc: "Width branch/opening",
    label: "EF005655: Width branch/opening"
  },
  {
    featureId: "EF005657",
    featureDesc: "Pipe locking",
    label: "EF005657: Pipe locking"
  },
  {
    featureId: "EF005658",
    featureDesc: "Width meter scale",
    label: "EF005658: Width meter scale"
  },
  {
    featureId: "EF005660",
    featureDesc: "Bending strength",
    label: "EF005660: Bending strength"
  },
  {
    featureId: "EF005663",
    featureDesc: "Outer diameter of ring",
    label: "EF005663: Outer diameter of ring"
  },
  {
    featureId: "EF005666",
    featureDesc: "Coder",
    label: "EF005666: Coder"
  },
  {
    featureId: "EF005667",
    featureDesc: "Direction of coding",
    label: "EF005667: Direction of coding"
  },
  {
    featureId: "EF005668",
    featureDesc: "Communication bus",
    label: "EF005668: Communication bus"
  },
  {
    featureId: "EF005669",
    featureDesc: "Communications protocol",
    label: "EF005669: Communications protocol"
  },
  {
    featureId: "EF005670",
    featureDesc: "Condensation ring",
    label: "EF005670: Condensation ring"
  },
  {
    featureId: "EF005673",
    featureDesc: "Heating- + sanitary mechanic set",
    label: "EF005673: Heating- + sanitary mechanic set"
  },
  {
    featureId: "EF005674",
    featureDesc: "Dew point calculation",
    label: "EF005674: Dew point calculation"
  },
  {
    featureId: "EF005675",
    featureDesc: "Cover mounting possible",
    label: "EF005675: Cover mounting possible"
  },
  {
    featureId: "EF005677",
    featureDesc: "Diameter (metric)",
    label: "EF005677: Diameter (metric)"
  },
  {
    featureId: "EF005678",
    featureDesc: "Diameter drain",
    label: "EF005678: Diameter drain"
  },
  {
    featureId: "EF005681",
    featureDesc: "Shackle diameter",
    label: "EF005681: Shackle diameter"
  },
  {
    featureId: "EF005684",
    featureDesc: "Diameter tip",
    label: "EF005684: Diameter tip"
  },
  {
    featureId: "EF005685",
    featureDesc: "Diameter grinding stone",
    label: "EF005685: Diameter grinding stone"
  },
  {
    featureId: "EF005686",
    featureDesc: "Depth branch/opening",
    label: "EF005686: Depth branch/opening"
  },
  {
    featureId: "EF005687",
    featureDesc: "Depth marking",
    label: "EF005687: Depth marking"
  },
  {
    featureId: "EF005689",
    featureDesc: "Direct mounting possible",
    label: "EF005689: Direct mounting possible"
  },
  {
    featureId: "EF005690",
    featureDesc: "Through-going profile",
    label: "EF005690: Through-going profile"
  },
  {
    featureId: "EF005691",
    featureDesc: "Through-going thread",
    label: "EF005691: Through-going thread"
  },
  {
    featureId: "EF005692",
    featureDesc: "Through-going pin",
    label: "EF005692: Through-going pin"
  },
  {
    featureId: "EF005696",
    featureDesc: "Trough-feed model",
    label: "EF005696: Trough-feed model"
  },
  {
    featureId: "EF005700",
    featureDesc: "Thread diameter (ST..)",
    label: "EF005700: Thread diameter (ST..)"
  },
  {
    featureId: "EF005701",
    featureDesc: "Thread size inner thread (inch)",
    label: "EF005701: Thread size inner thread (inch)"
  },
  {
    featureId: "EF005702",
    featureDesc: "Thread size outer thread (inch)",
    label: "EF005702: Thread size outer thread (inch)"
  },
  {
    featureId: "EF005703",
    featureDesc: "Thread type inner thread",
    label: "EF005703: Thread type inner thread"
  },
  {
    featureId: "EF005704",
    featureDesc: "Thread type outer thread",
    label: "EF005704: Thread type outer thread"
  },
  {
    featureId: "EF005708",
    featureDesc: "With mounting frame",
    label: "EF005708: With mounting frame"
  },
  {
    featureId: "EF005709",
    featureDesc: "Support ring",
    label: "EF005709: Support ring"
  },
  {
    featureId: "EF005712",
    featureDesc: "Three-wire model",
    label: "EF005712: Three-wire model"
  },
  {
    featureId: "EF005714",
    featureDesc: "Double rise",
    label: "EF005714: Double rise"
  },
  {
    featureId: "EF005715",
    featureDesc: "Double-walled",
    label: "EF005715: Double-walled"
  },
  {
    featureId: "EF005717",
    featureDesc: "Cross connectable",
    label: "EF005717: Cross connectable"
  },
  {
    featureId: "EF005719",
    featureDesc: "End value measuring range (inductive)",
    label: "EF005719: End value measuring range (inductive)"
  },
  {
    featureId: "EF005720",
    featureDesc: "End value scale (inductive)",
    label: "EF005720: End value scale (inductive)"
  },
  {
    featureId: "EF005721",
    featureDesc: "Electrician set",
    label: "EF005721: Electrician set"
  },
  {
    featureId: "EF005722",
    featureDesc: "Type of electrical accessory/spare part",
    label: "EF005722: Type of electrical accessory/spare part"
  },
  {
    featureId: "EF005725",
    featureDesc: "Electric drive",
    label: "EF005725: Electric drive"
  },
  {
    featureId: "EF005728",
    featureDesc: "For Ex-zone gas",
    label: "EF005728: For Ex-zone gas"
  },
  {
    featureId: "EF005729",
    featureDesc: "With flange",
    label: "EF005729: With flange"
  },
  {
    featureId: "EF005730",
    featureDesc: "Flexible spout",
    label: "EF005730: Flexible spout"
  },
  {
    featureId: "EF005732",
    featureDesc: "Frequency auxiliary voltage",
    label: "EF005732: Frequency auxiliary voltage"
  },
  {
    featureId: "EF005735",
    featureDesc: "Frequency input voltage",
    label: "EF005735: Frequency input voltage"
  },
  {
    featureId: "EF005736",
    featureDesc: "Frequency measuring voltage",
    label: "EF005736: Frequency measuring voltage"
  },
  {
    featureId: "EF005737",
    featureDesc: "Frequency rated current",
    label: "EF005737: Frequency rated current"
  },
  {
    featureId: "EF005740",
    featureDesc: "Gas-/waterproof",
    label: "EF005740: Gas-/waterproof"
  },
  {
    featureId: "EF005742",
    featureDesc: "Hole width",
    label: "EF005742: Hole width"
  },
  {
    featureId: "EF005743",
    featureDesc: "Hole height",
    label: "EF005743: Hole height"
  },
  {
    featureId: "EF005745",
    featureDesc: "Cranked",
    label: "EF005745: Cranked"
  },
  {
    featureId: "EF005748",
    featureDesc: "Twisted glass",
    label: "EF005748: Twisted glass"
  },
  {
    featureId: "EF005752",
    featureDesc: "Separated compartments",
    label: "EF005752: Separated compartments"
  },
  {
    featureId: "EF005753",
    featureDesc: "Suitable for junction box",
    label: "EF005753: Suitable for junction box"
  },
  {
    featureId: "EF005754",
    featureDesc: "Suitable as central plate",
    label: "EF005754: Suitable as central plate"
  },
  {
    featureId: "EF005755",
    featureDesc: "Suitable for 230 V incandescent lamp/halogen lamp",
    label: "EF005755: Suitable for 230 V incandescent lamp/halogen lamp"
  },
  {
    featureId: "EF005760",
    featureDesc: "Suitable for number of fibres",
    label: "EF005760: Suitable for number of fibres"
  },
  {
    featureId: "EF005763",
    featureDesc: "Suitable for acetylene",
    label: "EF005763: Suitable for acetylene"
  },
  {
    featureId: "EF005765",
    featureDesc: "Suitable for adjusting button",
    label: "EF005765: Suitable for adjusting button"
  },
  {
    featureId: "EF005767",
    featureDesc: "Suitable for shaft",
    label: "EF005767: Suitable for shaft"
  },
  {
    featureId: "EF005768",
    featureDesc: "Suitable for AS interface",
    label: "EF005768: Suitable for AS interface"
  },
  {
    featureId: "EF005770",
    featureDesc: "Suitable for moped",
    label: "EF005770: Suitable for moped"
  },
  {
    featureId: "EF005771",
    featureDesc: "Suitable for motorbike",
    label: "EF005771: Suitable for motorbike"
  },
  {
    featureId: "EF005772",
    featureDesc: "Suitable for pipe",
    label: "EF005772: Suitable for pipe"
  },
  {
    featureId: "EF005773",
    featureDesc: "Suitable for outside locking rings",
    label: "EF005773: Suitable for outside locking rings"
  },
  {
    featureId: "EF005774",
    featureDesc: "Suitable for outdoor set-up",
    label: "EF005774: Suitable for outdoor set-up"
  },
  {
    featureId: "EF005776",
    featureDesc: "Suitable for central conduit box",
    label: "EF005776: Suitable for central conduit box"
  },
  {
    featureId: "EF005777",
    featureDesc: "Type of plug-in connector system",
    label: "EF005777: Type of plug-in connector system"
  },
  {
    featureId: "EF005778",
    featureDesc: "Suitable for socket outlet",
    label: "EF005778: Suitable for socket outlet"
  },
  {
    featureId: "EF005780",
    featureDesc: "Suitable for thread diameter in inch",
    label: "EF005780: Suitable for thread diameter in inch"
  },
  {
    featureId: "EF005783",
    featureDesc: "Suitable for thread size (metric)",
    label: "EF005783: Suitable for thread size (metric)"
  },
  {
    featureId: "EF005784",
    featureDesc: "Suitable for electronic low voltage transformer",
    label: "EF005784: Suitable for electronic low voltage transformer"
  },
  {
    featureId: "EF005785",
    featureDesc: "Suitable for bicycle",
    label: "EF005785: Suitable for bicycle"
  },
  {
    featureId: "EF005786",
    featureDesc: "Suitable for gas concrete",
    label: "EF005786: Suitable for gas concrete"
  },
  {
    featureId: "EF005792",
    featureDesc: "Suitable for subfloor duct countersunk (closed)",
    label: "EF005792: Suitable for subfloor duct countersunk (closed)"
  },
  {
    featureId: "EF005794",
    featureDesc: "Suitable for fibre optic",
    label: "EF005794: Suitable for fibre optic"
  },
  {
    featureId: "EF005797",
    featureDesc: "Suitable for coach screw",
    label: "EF005797: Suitable for coach screw"
  },
  {
    featureId: "EF005802",
    featureDesc: "Suitable for built-in installation box",
    label: "EF005802: Suitable for built-in installation box"
  },
  {
    featureId: "EF005808",
    featureDesc: "Suitable for cable box",
    label: "EF005808: Suitable for cable box"
  },
  {
    featureId: "EF005817",
    featureDesc: "Suitable for installation duct",
    label: "EF005817: Suitable for installation duct"
  },
  {
    featureId: "EF005820",
    featureDesc: "Suitable for medical alarm",
    label: "EF005820: Suitable for medical alarm"
  },
  {
    featureId: "EF005821",
    featureDesc: "Suitable for multi-wire conductors",
    label: "EF005821: Suitable for multi-wire conductors"
  },
  {
    featureId: "EF005822",
    featureDesc: "Suitable for metal tape",
    label: "EF005822: Suitable for metal tape"
  },
  {
    featureId: "EF005823",
    featureDesc: "Suitable for metrical mounting",
    label: "EF005823: Suitable for metrical mounting"
  },
  {
    featureId: "EF005825",
    featureDesc: "Suitable for mounting rail",
    label: "EF005825: Suitable for mounting rail"
  },
  {
    featureId: "EF005826",
    featureDesc: "Suitable for motor safety switch",
    label: "EF005826: Suitable for motor safety switch"
  },
  {
    featureId: "EF005829",
    featureDesc: "Suitable for network protocol measurement",
    label: "EF005829: Suitable for network protocol measurement"
  },
  {
    featureId: "EF005832",
    featureDesc: "Suitable for surface mounted box for flush-mounted switching equipment",
    label: "EF005832: Suitable for surface mounted box for flush-mounted switching equipment"
  },
  {
    featureId: "EF005833",
    featureDesc: "Suitable for subfloor duct overhead open",
    label: "EF005833: Suitable for subfloor duct overhead open"
  },
  {
    featureId: "EF005834",
    featureDesc: "Suitable for emergency call button",
    label: "EF005834: Suitable for emergency call button"
  },
  {
    featureId: "EF005841",
    featureDesc: "Suitable for sheet-metal screw",
    label: "EF005841: Suitable for sheet-metal screw"
  },
  {
    featureId: "EF005842",
    featureDesc: "Suitable for blind holes",
    label: "EF005842: Suitable for blind holes"
  },
  {
    featureId: "EF005843",
    featureDesc: "Suitable for program selector switch",
    label: "EF005843: Suitable for program selector switch"
  },
  {
    featureId: "EF005845",
    featureDesc: "Suitable for radio distribution",
    label: "EF005845: Suitable for radio distribution"
  },
  {
    featureId: "EF005847",
    featureDesc: "Suitable for rain sensor",
    label: "EF005847: Suitable for rain sensor"
  },
  {
    featureId: "EF005848",
    featureDesc: "Suitable for stainless steel",
    label: "EF005848: Suitable for stainless steel"
  },
  {
    featureId: "EF005850",
    featureDesc: "Suitable for SDS-max",
    label: "EF005850: Suitable for SDS-max"
  },
  {
    featureId: "EF005851",
    featureDesc: "Suitable for rapid charger",
    label: "EF005851: Suitable for rapid charger"
  },
  {
    featureId: "EF005852",
    featureDesc: "Suitable for flexible conductors",
    label: "EF005852: Suitable for flexible conductors"
  },
  {
    featureId: "EF005854",
    featureDesc: "Suitable for strut profiles",
    label: "EF005854: Suitable for strut profiles"
  },
  {
    featureId: "EF005855",
    featureDesc: "Suitable for fluorescent tube/energy-saving lamp",
    label: "EF005855: Suitable for fluorescent tube/energy-saving lamp"
  },
  {
    featureId: "EF005856",
    featureDesc: "Suitable for staircase time switch",
    label: "EF005856: Suitable for staircase time switch"
  },
  {
    featureId: "EF005857",
    featureDesc: "Suitable for pull contact",
    label: "EF005857: Suitable for pull contact"
  },
  {
    featureId: "EF005858",
    featureDesc: "Suitable for expansion ring",
    label: "EF005858: Suitable for expansion ring"
  },
  {
    featureId: "EF005859",
    featureDesc: "Suitable for vessel",
    label: "EF005859: Suitable for vessel"
  },
  {
    featureId: "EF005863",
    featureDesc: "Suitable for power circuit breaker",
    label: "EF005863: Suitable for power circuit breaker"
  },
  {
    featureId: "EF005864",
    featureDesc: "Suitable for subfloor installation duct box",
    label: "EF005864: Suitable for subfloor installation duct box"
  },
  {
    featureId: "EF005865",
    featureDesc: "Suitable for thread type cable screw gland",
    label: "EF005865: Suitable for thread type cable screw gland"
  },
  {
    featureId: "EF005866",
    featureDesc: "Suitable for wind sensor",
    label: "EF005866: Suitable for wind sensor"
  },
  {
    featureId: "EF005867",
    featureDesc: "Suitable for hexagonal bolts",
    label: "EF005867: Suitable for hexagonal bolts"
  },
  {
    featureId: "EF005868",
    featureDesc: "Suitable for nurse call station",
    label: "EF005868: Suitable for nurse call station"
  },
  {
    featureId: "EF005870",
    featureDesc: "Closed version",
    label: "EF005870: Closed version"
  },
  {
    featureId: "EF005872",
    featureDesc: "Tray width in horizontal direction",
    label: "EF005872: Tray width in horizontal direction"
  },
  {
    featureId: "EF005873",
    featureDesc: "Tray width in vertical direction",
    label: "EF005873: Tray width in vertical direction"
  },
  {
    featureId: "EF005874",
    featureDesc: "Gutter depth",
    label: "EF005874: Gutter depth"
  },
  {
    featureId: "EF005877",
    featureDesc: "Engravable",
    label: "EF005877: Engravable"
  },
  {
    featureId: "EF005884",
    featureDesc: "Padlock shackle",
    label: "EF005884: Padlock shackle"
  },
  {
    featureId: "EF005885",
    featureDesc: "Bow shackle",
    label: "EF005885: Bow shackle"
  },
  {
    featureId: "EF005887",
    featureDesc: "Dual spacing screw thread",
    label: "EF005887: Dual spacing screw thread"
  },
  {
    featureId: "EF005888",
    featureDesc: "Lift height",
    label: "EF005888: Lift height"
  },
  {
    featureId: "EF005889",
    featureDesc: "Hoisting capacity",
    label: "EF005889: Hoisting capacity"
  },
  {
    featureId: "EF005894",
    featureDesc: "Height including pole",
    label: "EF005894: Height including pole"
  },
  {
    featureId: "EF005896",
    featureDesc: "Height meter scale",
    label: "EF005896: Height meter scale"
  },
  {
    featureId: "EF005900",
    featureDesc: "Auxiliary voltage (AC)",
    label: "EF005900: Auxiliary voltage (AC)"
  },
  {
    featureId: "EF005901",
    featureDesc: "Auxiliary voltage (DC)",
    label: "EF005901: Auxiliary voltage (DC)"
  },
  {
    featureId: "EF005902",
    featureDesc: "In the rail",
    label: "EF005902: In the rail"
  },
  {
    featureId: "EF005905",
    featureDesc: "With light source",
    label: "EF005905: With light source"
  },
  {
    featureId: "EF005907",
    featureDesc: "Infrared measurement",
    label: "EF005907: Infrared measurement"
  },
  {
    featureId: "EF005908",
    featureDesc: "Input for substation",
    label: "EF005908: Input for substation"
  },
  {
    featureId: "EF005909",
    featureDesc: "Built-in battery",
    label: "EF005909: Built-in battery"
  },
  {
    featureId: "EF005910",
    featureDesc: "Built-in transformer",
    label: "EF005910: Built-in transformer"
  },
  {
    featureId: "EF005911",
    featureDesc: "Built-in summer",
    label: "EF005911: Built-in summer"
  },
  {
    featureId: "EF005912",
    featureDesc: "Inlay",
    label: "EF005912: Inlay"
  },
  {
    featureId: "EF005913",
    featureDesc: "With inlay",
    label: "EF005913: With inlay"
  },
  {
    featureId: "EF005914",
    featureDesc: "Built as switching-on coil",
    label: "EF005914: Built as switching-on coil"
  },
  {
    featureId: "EF005915",
    featureDesc: "Screw-in length",
    label: "EF005915: Screw-in length"
  },
  {
    featureId: "EF005919",
    featureDesc: "Adjustable",
    label: "EF005919: Adjustable"
  },
  {
    featureId: "EF005920",
    featureDesc: "Tone adjustable",
    label: "EF005920: Tone adjustable"
  },
  {
    featureId: "EF005922",
    featureDesc: "Setting time",
    label: "EF005922: Setting time"
  },
  {
    featureId: "EF005923",
    featureDesc: "Internal memory",
    label: "EF005923: Internal memory"
  },
  {
    featureId: "EF005924",
    featureDesc: "Venetian shutter switch",
    label: "EF005924: Venetian shutter switch"
  },
  {
    featureId: "EF005927",
    featureDesc: "Positioning cable feed, box-sided",
    label: "EF005927: Positioning cable feed, box-sided"
  },
  {
    featureId: "EF005928",
    featureDesc: "Positioning cable feed, field-sided",
    label: "EF005928: Positioning cable feed, field-sided"
  },
  {
    featureId: "EF005930",
    featureDesc: "Lock width",
    label: "EF005930: Lock width"
  },
  {
    featureId: "EF005931",
    featureDesc: "Depth of notch adjustable",
    label: "EF005931: Depth of notch adjustable"
  },
  {
    featureId: "EF005932",
    featureDesc: "Chain clamp assembly",
    label: "EF005932: Chain clamp assembly"
  },
  {
    featureId: "EF005934",
    featureDesc: "Class",
    label: "EF005934: Class"
  },
  {
    featureId: "EF005935",
    featureDesc: "Clamping width",
    label: "EF005935: Clamping width"
  },
  {
    featureId: "EF005936",
    featureDesc: "Clamping height",
    label: "EF005936: Clamping height"
  },
  {
    featureId: "EF005937",
    featureDesc: "Clamp feature",
    label: "EF005937: Clamp feature"
  },
  {
    featureId: "EF005938",
    featureDesc: "Universal joint",
    label: "EF005938: Universal joint"
  },
  {
    featureId: "EF005941",
    featureDesc: "Button closing",
    label: "EF005941: Button closing"
  },
  {
    featureId: "EF005942",
    featureDesc: "Ball locking",
    label: "EF005942: Ball locking"
  },
  {
    featureId: "EF005943",
    featureDesc: "Cooker group",
    label: "EF005943: Cooker group"
  },
  {
    featureId: "EF005944",
    featureDesc: "Cooker group suitable as power current group",
    label: "EF005944: Cooker group suitable as power current group"
  },
  {
    featureId: "EF005947",
    featureDesc: "With drawers case",
    label: "EF005947: With drawers case"
  },
  {
    featureId: "EF005948",
    featureDesc: "Long version",
    label: "EF005948: Long version"
  },
  {
    featureId: "EF005950",
    featureDesc: "With LED indication",
    label: "EF005950: With LED indication"
  },
  {
    featureId: "EF005953",
    featureDesc: "Jaw length",
    label: "EF005953: Jaw length"
  },
  {
    featureId: "EF005954",
    featureDesc: "Wire length connecting cable",
    label: "EF005954: Wire length connecting cable"
  },
  {
    featureId: "EF005957",
    featureDesc: "Delivery on roll",
    label: "EF005957: Delivery on roll"
  },
  {
    featureId: "EF005958",
    featureDesc: "Colour of light according to EN 12464-1",
    label: "EF005958: Colour of light according to EN 12464-1"
  },
  {
    featureId: "EF005960",
    featureDesc: "Logic object",
    label: "EF005960: Logic object"
  },
  {
    featureId: "EF005961",
    featureDesc: "Loose glass",
    label: "EF005961: Loose glass"
  },
  {
    featureId: "EF005962",
    featureDesc: "Separate sensor",
    label: "EF005962: Separate sensor"
  },
  {
    featureId: "EF005963",
    featureDesc: "Size (metric)",
    label: "EF005963: Size (metric)"
  },
  {
    featureId: "EF005964",
    featureDesc: "Dimension 1 (metric)",
    label: "EF005964: Dimension 1 (metric)"
  },
  {
    featureId: "EF005965",
    featureDesc: "Dimension 2 (metric)",
    label: "EF005965: Dimension 2 (metric)"
  },
  {
    featureId: "EF005968",
    featureDesc: "Machine insertion",
    label: "EF005968: Machine insertion"
  },
  {
    featureId: "EF005972",
    featureDesc: "Material front ring",
    label: "EF005972: Material front ring"
  },
  {
    featureId: "EF005973",
    featureDesc: "Material of cap",
    label: "EF005973: Material of cap"
  },
  {
    featureId: "EF005974",
    featureDesc: "Material of pin",
    label: "EF005974: Material of pin"
  },
  {
    featureId: "EF005976",
    featureDesc: "Max. cheeks opening",
    label: "EF005976: Max. cheeks opening"
  },
  {
    featureId: "EF005977",
    featureDesc: "Max. load",
    label: "EF005977: Max. load"
  },
  {
    featureId: "EF005979",
    featureDesc: "Max. hold temperature",
    label: "EF005979: Max. hold temperature"
  },
  {
    featureId: "EF005980",
    featureDesc: "Max. length of pull cord",
    label: "EF005980: Max. length of pull cord"
  },
  {
    featureId: "EF005981",
    featureDesc: "Max. material thickness",
    label: "EF005981: Max. material thickness"
  },
  {
    featureId: "EF005983",
    featureDesc: "Max. platform height",
    label: "EF005983: Max. platform height"
  },
  {
    featureId: "EF005984",
    featureDesc: "Max. secondary voltage",
    label: "EF005984: Max. secondary voltage"
  },
  {
    featureId: "EF005986",
    featureDesc: "Max. spanner opening",
    label: "EF005986: Max. spanner opening"
  },
  {
    featureId: "EF005992",
    featureDesc: "Max. needle deflection",
    label: "EF005992: Max. needle deflection"
  },
  {
    featureId: "EF005993",
    featureDesc: "Mechanical controllable",
    label: "EF005993: Mechanical controllable"
  },
  {
    featureId: "EF005994",
    featureDesc: "Type of mechanical accessory/spare part",
    label: "EF005994: Type of mechanical accessory/spare part"
  },
  {
    featureId: "EF005995",
    featureDesc: "Multi-coiled filament",
    label: "EF005995: Multi-coiled filament"
  },
  {
    featureId: "EF005997",
    featureDesc: "Concurrently switching neutral conductor",
    label: "EF005997: Concurrently switching neutral conductor"
  },
  {
    featureId: "EF005998",
    featureDesc: "Counter measuring range",
    label: "EF005998: Counter measuring range"
  },
  {
    featureId: "EF006001",
    featureDesc: "Measuring voltage",
    label: "EF006001: Measuring voltage"
  },
  {
    featureId: "EF006003",
    featureDesc: "Measurement system",
    label: "EF006003: Measurement system"
  },
  {
    featureId: "EF006004",
    featureDesc: "With stop",
    label: "EF006004: With stop"
  },
  {
    featureId: "EF006007",
    featureDesc: "With anti-slip layer",
    label: "EF006007: With anti-slip layer"
  },
  {
    featureId: "EF006008",
    featureDesc: "With fastening holes",
    label: "EF006008: With fastening holes"
  },
  {
    featureId: "EF006009",
    featureDesc: "With mounting material",
    label: "EF006009: With mounting material"
  },
  {
    featureId: "EF006010",
    featureDesc: "With thread screws/sliding nuts",
    label: "EF006010: With thread screws/sliding nuts"
  },
  {
    featureId: "EF006011",
    featureDesc: "With centre drill",
    label: "EF006011: With centre drill"
  },
  {
    featureId: "EF006012",
    featureDesc: "With peg",
    label: "EF006012: With peg"
  },
  {
    featureId: "EF006013",
    featureDesc: "With filter",
    label: "EF006013: With filter"
  },
  {
    featureId: "EF006014",
    featureDesc: "With front ring",
    label: "EF006014: With front ring"
  },
  {
    featureId: "EF006015",
    featureDesc: "With hole",
    label: "EF006015: With hole"
  },
  {
    featureId: "EF006016",
    featureDesc: "With glass",
    label: "EF006016: With glass"
  },
  {
    featureId: "EF006017",
    featureDesc: "With glove",
    label: "EF006017: With glove"
  },
  {
    featureId: "EF006022",
    featureDesc: "With meter scale",
    label: "EF006022: With meter scale"
  },
  {
    featureId: "EF006023",
    featureDesc: "With sleeve",
    label: "EF006023: With sleeve"
  },
  {
    featureId: "EF006024",
    featureDesc: "With nylon ring",
    label: "EF006024: With nylon ring"
  },
  {
    featureId: "EF006025",
    featureDesc: "With interference suppressor filter",
    label: "EF006025: With interference suppressor filter"
  },
  {
    featureId: "EF006029",
    featureDesc: "With scraper",
    label: "EF006029: With scraper"
  },
  {
    featureId: "EF006031",
    featureDesc: "With cord switch",
    label: "EF006031: With cord switch"
  },
  {
    featureId: "EF006034",
    featureDesc: "With temperature sensor",
    label: "EF006034: With temperature sensor"
  },
  {
    featureId: "EF006038",
    featureDesc: "With mitre",
    label: "EF006038: With mitre"
  },
  {
    featureId: "EF006039",
    featureDesc: "With exchangeable lens/symbol",
    label: "EF006039: With exchangeable lens/symbol"
  },
  {
    featureId: "EF006042",
    featureDesc: "With sieve",
    label: "EF006042: With sieve"
  },
  {
    featureId: "EF006043",
    featureDesc: "With diagonal cutting nipper",
    label: "EF006043: With diagonal cutting nipper"
  },
  {
    featureId: "EF006048",
    featureDesc: "Distance between rail centre, 100 mm",
    label: "EF006048: Distance between rail centre, 100 mm"
  },
  {
    featureId: "EF006049",
    featureDesc: "Distance between rail centre, 185 mm",
    label: "EF006049: Distance between rail centre, 185 mm"
  },
  {
    featureId: "EF006050",
    featureDesc: "Military model",
    label: "EF006050: Military model"
  },
  {
    featureId: "EF006051",
    featureDesc: "Horizontal mounting",
    label: "EF006051: Horizontal mounting"
  },
  {
    featureId: "EF006052",
    featureDesc: "Mounting in built-in installation box",
    label: "EF006052: Mounting in built-in installation box"
  },
  {
    featureId: "EF006054",
    featureDesc: "Mounting plate depth-adjustable",
    label: "EF006054: Mounting plate depth-adjustable"
  },
  {
    featureId: "EF006056",
    featureDesc: "Mounting method rail system",
    label: "EF006056: Mounting method rail system"
  },
  {
    featureId: "EF006057",
    featureDesc: "Mounting method D-system fuse base",
    label: "EF006057: Mounting method D-system fuse base"
  },
  {
    featureId: "EF006058",
    featureDesc: "Photoluminescent (glow in the dark)",
    label: "EF006058: Photoluminescent (glow in the dark)"
  },
  {
    featureId: "EF006059",
    featureDesc: "NATO-mounting holes",
    label: "EF006059: NATO-mounting holes"
  },
  {
    featureId: "EF006060",
    featureDesc: "Neodym-lamp",
    label: "EF006060: Neodym-lamp"
  },
  {
    featureId: "EF006064",
    featureDesc: "Rated short-circuit breaking capacity according to EN 60898",
    label: "EF006064: Rated short-circuit breaking capacity according to EN 60898"
  },
  {
    featureId: "EF006065",
    featureDesc: "Rated short-circuit breaking capacity according to IEC 60947-2",
    label: "EF006065: Rated short-circuit breaking capacity according to IEC 60947-2"
  },
  {
    featureId: "EF006069",
    featureDesc: "Rated operation current Ie at AC-1",
    label: "EF006069: Rated operation current Ie at AC-1"
  },
  {
    featureId: "EF006070",
    featureDesc: "Rated operation current Ie at AC-3",
    label: "EF006070: Rated operation current Ie at AC-3"
  },
  {
    featureId: "EF006071",
    featureDesc: "Rated permanent current contacts",
    label: "EF006071: Rated permanent current contacts"
  },
  {
    featureId: "EF006072",
    featureDesc: "Nominal end value of the scale",
    label: "EF006072: Nominal end value of the scale"
  },
  {
    featureId: "EF006074",
    featureDesc: "Nominal measuring voltage",
    label: "EF006074: Nominal measuring voltage"
  },
  {
    featureId: "EF006078",
    featureDesc: "Nominal voltage AC 50 Hz",
    label: "EF006078: Nominal voltage AC 50 Hz"
  },
  {
    featureId: "EF006087",
    featureDesc: "Secondary nominal current",
    label: "EF006087: Secondary nominal current"
  },
  {
    featureId: "EF006090",
    featureDesc: "Reversible arms",
    label: "EF006090: Reversible arms"
  },
  {
    featureId: "EF006093",
    featureDesc: "On roll",
    label: "EF006093: On roll"
  },
  {
    featureId: "EF006094",
    featureDesc: "Imprint ISO symbols",
    label: "EF006094: Imprint ISO symbols"
  },
  {
    featureId: "EF006098",
    featureDesc: "Above the rail",
    label: "EF006098: Above the rail"
  },
  {
    featureId: "EF006101",
    featureDesc: "Overcurrent scale",
    label: "EF006101: Overcurrent scale"
  },
  {
    featureId: "EF006103",
    featureDesc: "Parallel cheeks",
    label: "EF006103: Parallel cheeks"
  },
  {
    featureId: "EF006104",
    featureDesc: "Fitting on eyeglasses",
    label: "EF006104: Fitting on eyeglasses"
  },
  {
    featureId: "EF006105",
    featureDesc: "Cartridges power",
    label: "EF006105: Cartridges power"
  },
  {
    featureId: "EF006107",
    featureDesc: "With PC connection",
    label: "EF006107: With PC connection"
  },
  {
    featureId: "EF006108",
    featureDesc: "Pin diameter",
    label: "EF006108: Pin diameter"
  },
  {
    featureId: "EF006110",
    featureDesc: "Direction of punch holes",
    label: "EF006110: Direction of punch holes"
  },
  {
    featureId: "EF006111",
    featureDesc: "Pioneer workbench",
    label: "EF006111: Pioneer workbench"
  },
  {
    featureId: "EF006112",
    featureDesc: "Plate thickness",
    label: "EF006112: Plate thickness"
  },
  {
    featureId: "EF006113",
    featureDesc: "Ceiling fastening",
    label: "EF006113: Ceiling fastening"
  },
  {
    featureId: "EF006115",
    featureDesc: "Flat model",
    label: "EF006115: Flat model"
  },
  {
    featureId: "EF006116",
    featureDesc: "Handle length",
    label: "EF006116: Handle length"
  },
  {
    featureId: "EF006118",
    featureDesc: "Precision model",
    label: "EF006118: Precision model"
  },
  {
    featureId: "EF006125",
    featureDesc: "Primary voltage 1",
    label: "EF006125: Primary voltage 1"
  },
  {
    featureId: "EF006126",
    featureDesc: "Primary voltage 2",
    label: "EF006126: Primary voltage 2"
  },
  {
    featureId: "EF006127",
    featureDesc: "Primary voltage 3",
    label: "EF006127: Primary voltage 3"
  },
  {
    featureId: "EF006134",
    featureDesc: "Voltage measuring range 1",
    label: "EF006134: Voltage measuring range 1"
  },
  {
    featureId: "EF006135",
    featureDesc: "Voltage measuring range 2",
    label: "EF006135: Voltage measuring range 2"
  },
  {
    featureId: "EF006136",
    featureDesc: "Voltage control",
    label: "EF006136: Voltage control"
  },
  {
    featureId: "EF006138",
    featureDesc: "Coil voltage",
    label: "EF006138: Coil voltage"
  },
  {
    featureId: "EF006144",
    featureDesc: "Control voltage",
    label: "EF006144: Control voltage"
  },
  {
    featureId: "EF006154",
    featureDesc: "Primary current",
    label: "EF006154: Primary current"
  },
  {
    featureId: "EF006157",
    featureDesc: "Suitable for mounting on PCB",
    label: "EF006157: Suitable for mounting on PCB"
  },
  {
    featureId: "EF006158",
    featureDesc: "Profile width",
    label: "EF006158: Profile width"
  },
  {
    featureId: "EF006159",
    featureDesc: "Profile height",
    label: "EF006159: Profile height"
  },
  {
    featureId: "EF006161",
    featureDesc: "Pulse contact",
    label: "EF006161: Pulse contact"
  },
  {
    featureId: "EF006163",
    featureDesc: "Rail mounting",
    label: "EF006163: Rail mounting"
  },
  {
    featureId: "EF006166",
    featureDesc: "Straight model",
    label: "EF006166: Straight model"
  },
  {
    featureId: "EF006167",
    featureDesc: "Clockwise and counter-clockwise",
    label: "EF006167: Clockwise and counter-clockwise"
  },
  {
    featureId: "EF006168",
    featureDesc: "Controllable rotational speed",
    label: "EF006168: Controllable rotational speed"
  },
  {
    featureId: "EF006169",
    featureDesc: "Tackable",
    label: "EF006169: Tackable"
  },
  {
    featureId: "EF006170",
    featureDesc: "Ring core",
    label: "EF006170: Ring core"
  },
  {
    featureId: "EF006171",
    featureDesc: "Ring nipple",
    label: "EF006171: Ring nipple"
  },
  {
    featureId: "EF006172",
    featureDesc: "Rollable",
    label: "EF006172: Rollable"
  },
  {
    featureId: "EF006173",
    featureDesc: "Round head",
    label: "EF006173: Round head"
  },
  {
    featureId: "EF006174",
    featureDesc: "Smoke switcher",
    label: "EF006174: Smoke switcher"
  },
  {
    featureId: "EF006177",
    featureDesc: "Battery pack",
    label: "EF006177: Battery pack"
  },
  {
    featureId: "EF006179",
    featureDesc: "Scales lighting",
    label: "EF006179: Scales lighting"
  },
  {
    featureId: "EF006180",
    featureDesc: "Chain link diameter",
    label: "EF006180: Chain link diameter"
  },
  {
    featureId: "EF006183",
    featureDesc: "Disconnect function",
    label: "EF006183: Disconnect function"
  },
  {
    featureId: "EF006184",
    featureDesc: "With shoulder strap",
    label: "EF006184: With shoulder strap"
  },
  {
    featureId: "EF006185",
    featureDesc: "Screw thread (full length)",
    label: "EF006185: Screw thread (full length)"
  },
  {
    featureId: "EF006186",
    featureDesc: "Signal-red",
    label: "EF006186: Signal-red"
  },
  {
    featureId: "EF006188",
    featureDesc: "Stay-set indicator",
    label: "EF006188: Stay-set indicator"
  },
  {
    featureId: "EF006189",
    featureDesc: "Slotted hole",
    label: "EF006189: Slotted hole"
  },
  {
    featureId: "EF006190",
    featureDesc: "Wrench size large",
    label: "EF006190: Wrench size large"
  },
  {
    featureId: "EF006191",
    featureDesc: "Wrench size small",
    label: "EF006191: Wrench size small"
  },
  {
    featureId: "EF006193",
    featureDesc: "Fast clamping system",
    label: "EF006193: Fast clamping system"
  },
  {
    featureId: "EF006194",
    featureDesc: "Fast exchange head",
    label: "EF006194: Fast exchange head"
  },
  {
    featureId: "EF006198",
    featureDesc: "Type of coupler",
    label: "EF006198: Type of coupler"
  },
  {
    featureId: "EF006200",
    featureDesc: "Chucking clamp",
    label: "EF006200: Chucking clamp"
  },
  {
    featureId: "EF006201",
    featureDesc: "Clamping reach",
    label: "EF006201: Clamping reach"
  },
  {
    featureId: "EF006202",
    featureDesc: "Tension screw",
    label: "EF006202: Tension screw"
  },
  {
    featureId: "EF006203",
    featureDesc: "Clamping spread",
    label: "EF006203: Clamping spread"
  },
  {
    featureId: "EF006205",
    featureDesc: "Special application",
    label: "EF006205: Special application"
  },
  {
    featureId: "EF006208",
    featureDesc: "Spreading",
    label: "EF006208: Spreading"
  },
  {
    featureId: "EF006209",
    featureDesc: "With tilting protection",
    label: "EF006209: With tilting protection"
  },
  {
    featureId: "EF006210",
    featureDesc: "Standing height",
    label: "EF006210: Standing height"
  },
  {
    featureId: "EF006212",
    featureDesc: "Stackable",
    label: "EF006212: Stackable"
  },
  {
    featureId: "EF006213",
    featureDesc: "Pitch measure",
    label: "EF006213: Pitch measure"
  },
  {
    featureId: "EF006217",
    featureDesc: "With set screw",
    label: "EF006217: With set screw"
  },
  {
    featureId: "EF006218",
    featureDesc: "Stamp diameter",
    label: "EF006218: Stamp diameter"
  },
  {
    featureId: "EF006219",
    featureDesc: "Pin connection",
    label: "EF006219: Pin connection"
  },
  {
    featureId: "EF006220",
    featureDesc: "With edge",
    label: "EF006220: With edge"
  },
  {
    featureId: "EF006222",
    featureDesc: "Strip length adjustable",
    label: "EF006222: Strip length adjustable"
  },
  {
    featureId: "EF006224",
    featureDesc: "Tooth distance",
    label: "EF006224: Tooth distance"
  },
  {
    featureId: "EF006225",
    featureDesc: "Push-/tip-operation",
    label: "EF006225: Push-/tip-operation"
  },
  {
    featureId: "EF006229",
    featureDesc: "Temperature measurement",
    label: "EF006229: Temperature measurement"
  },
  {
    featureId: "EF006230",
    featureDesc: "Non-rebound",
    label: "EF006230: Non-rebound"
  },
  {
    featureId: "EF006231",
    featureDesc: "Test turn-off time",
    label: "EF006231: Test turn-off time"
  },
  {
    featureId: "EF006232",
    featureDesc: "T-handle",
    label: "EF006232: T-handle"
  },
  {
    featureId: "EF006233",
    featureDesc: "With thermal overload protection",
    label: "EF006233: With thermal overload protection"
  },
  {
    featureId: "EF006235",
    featureDesc: "Permitted net load",
    label: "EF006235: Permitted net load"
  },
  {
    featureId: "EF006237",
    featureDesc: "With rotational speed/frequency control",
    label: "EF006237: With rotational speed/frequency control"
  },
  {
    featureId: "EF006238",
    featureDesc: "Tolerance",
    label: "EF006238: Tolerance"
  },
  {
    featureId: "EF006239",
    featureDesc: "Total number of groups",
    label: "EF006239: Total number of groups"
  },
  {
    featureId: "EF006240",
    featureDesc: "Total number of poles (without ground contact)",
    label: "EF006240: Total number of poles (without ground contact)"
  },
  {
    featureId: "EF006243",
    featureDesc: "Transparent",
    label: "EF006243: Transparent"
  },
  {
    featureId: "EF006244",
    featureDesc: "Transparent cover/door",
    label: "EF006244: Transparent cover/door"
  },
  {
    featureId: "EF006245",
    featureDesc: "Cascade",
    label: "EF006245: Cascade"
  },
  {
    featureId: "EF006247",
    featureDesc: "Pull power",
    label: "EF006247: Pull power"
  },
  {
    featureId: "EF006248",
    featureDesc: "Adapter plug",
    label: "EF006248: Adapter plug"
  },
  {
    featureId: "EF006249",
    featureDesc: "Double hex insert",
    label: "EF006249: Double hex insert"
  },
  {
    featureId: "EF006250",
    featureDesc: "Type of tyres",
    label: "EF006250: Type of tyres"
  },
  {
    featureId: "EF006253",
    featureDesc: "Construction type lens",
    label: "EF006253: Construction type lens"
  },
  {
    featureId: "EF006254",
    featureDesc: "Sensor/feeler type",
    label: "EF006254: Sensor/feeler type"
  },
  {
    featureId: "EF006256",
    featureDesc: "Shape of opening",
    label: "EF006256: Shape of opening"
  },
  {
    featureId: "EF006258",
    featureDesc: "Wiring on terminal strip",
    label: "EF006258: Wiring on terminal strip"
  },
  {
    featureId: "EF006259",
    featureDesc: "Extension ring",
    label: "EF006259: Extension ring"
  },
  {
    featureId: "EF006260",
    featureDesc: "Expansion panel",
    label: "EF006260: Expansion panel"
  },
  {
    featureId: "EF006261",
    featureDesc: "Unfolding",
    label: "EF006261: Unfolding"
  },
  {
    featureId: "EF006263",
    featureDesc: "Built as switch-off coil",
    label: "EF006263: Built as switch-off coil"
  },
  {
    featureId: "EF006265",
    featureDesc: "Construction rear wall (inner side)",
    label: "EF006265: Construction rear wall (inner side)"
  },
  {
    featureId: "EF006266",
    featureDesc: "Type of glass/cover",
    label: "EF006266: Type of glass/cover"
  },
  {
    featureId: "EF006267",
    featureDesc: "Single wrench model",
    label: "EF006267: Single wrench model"
  },
  {
    featureId: "EF006269",
    featureDesc: "Type of plug-in contact, field sided",
    label: "EF006269: Type of plug-in contact, field sided"
  },
  {
    featureId: "EF006272",
    featureDesc: "Outer pipe diameter",
    label: "EF006272: Outer pipe diameter"
  },
  {
    featureId: "EF006273",
    featureDesc: "Outer pipe diameter",
    label: "EF006273: Outer pipe diameter"
  },
  {
    featureId: "EF006274",
    featureDesc: "Exchangeable centring tip",
    label: "EF006274: Exchangeable centring tip"
  },
  {
    featureId: "EF006279",
    featureDesc: "With ventilation door",
    label: "EF006279: With ventilation door"
  },
  {
    featureId: "EF006280",
    featureDesc: "Bracing",
    label: "EF006280: Bracing"
  },
  {
    featureId: "EF006281",
    featureDesc: "Interlockable",
    label: "EF006281: Interlockable"
  },
  {
    featureId: "EF006282",
    featureDesc: "Lockable lever",
    label: "EF006282: Lockable lever"
  },
  {
    featureId: "EF006283",
    featureDesc: "Locking in 0-position",
    label: "EF006283: Locking in 0-position"
  },
  {
    featureId: "EF006285",
    featureDesc: "Connection size socket side",
    label: "EF006285: Connection size socket side"
  },
  {
    featureId: "EF006286",
    featureDesc: "Place variable",
    label: "EF006286: Place variable"
  },
  {
    featureId: "EF006290",
    featureDesc: "Exchangeable jaws",
    label: "EF006290: Exchangeable jaws"
  },
  {
    featureId: "EF006291",
    featureDesc: "Exchangeable knives",
    label: "EF006291: Exchangeable knives"
  },
  {
    featureId: "EF006292",
    featureDesc: "Exchangeable tip",
    label: "EF006292: Exchangeable tip"
  },
  {
    featureId: "EF006293",
    featureDesc: "Exchangeable tips",
    label: "EF006293: Exchangeable tips"
  },
  {
    featureId: "EF006294",
    featureDesc: "Fibre length",
    label: "EF006294: Fibre length"
  },
  {
    featureId: "EF006296",
    featureDesc: "Square lead",
    label: "EF006296: Square lead"
  },
  {
    featureId: "EF006298",
    featureDesc: "Power supply position",
    label: "EF006298: Power supply position"
  },
  {
    featureId: "EF006299",
    featureDesc: "Low spark model",
    label: "EF006299: Low spark model"
  },
  {
    featureId: "EF006300",
    featureDesc: "For application at low temperatures",
    label: "EF006300: For application at low temperatures"
  },
  {
    featureId: "EF006301",
    featureDesc: "Suitable for neon application",
    label: "EF006301: Suitable for neon application"
  },
  {
    featureId: "EF006302",
    featureDesc: "For nominal tube diameter",
    label: "EF006302: For nominal tube diameter"
  },
  {
    featureId: "EF006304",
    featureDesc: "With grounding connection",
    label: "EF006304: With grounding connection"
  },
  {
    featureId: "EF006306",
    featureDesc: "With lock",
    label: "EF006306: With lock"
  },
  {
    featureId: "EF006309",
    featureDesc: "Pillar shape",
    label: "EF006309: Pillar shape"
  },
  {
    featureId: "EF006310",
    featureDesc: "Free turning head",
    label: "EF006310: Free turning head"
  },
  {
    featureId: "EF006311",
    featureDesc: "Animal zone",
    label: "EF006311: Animal zone"
  },
  {
    featureId: "EF006312",
    featureDesc: "With alert-/information symbol",
    label: "EF006312: With alert-/information symbol"
  },
  {
    featureId: "EF006313",
    featureDesc: "With alert-/information text",
    label: "EF006313: With alert-/information text"
  },
  {
    featureId: "EF006314",
    featureDesc: "Wall fastening possible",
    label: "EF006314: Wall fastening possible"
  },
  {
    featureId: "EF006316",
    featureDesc: "Washing machine switch",
    label: "EF006316: Washing machine switch"
  },
  {
    featureId: "EF006318",
    featureDesc: "Working length",
    label: "EF006318: Working length"
  },
  {
    featureId: "EF006319",
    featureDesc: "Self-locking",
    label: "EF006319: Self-locking"
  },
  {
    featureId: "EF006320",
    featureDesc: "Self synchronising",
    label: "EF006320: Self synchronising"
  },
  {
    featureId: "EF006323",
    featureDesc: "With side- and upper flaps",
    label: "EF006323: With side- and upper flaps"
  },
  {
    featureId: "EF006324",
    featureDesc: "Number of alarm inputs",
    label: "EF006324: Number of alarm inputs"
  },
  {
    featureId: "EF006325",
    featureDesc: "Accuracy per day",
    label: "EF006325: Accuracy per day"
  },
  {
    featureId: "EF006326",
    featureDesc: "DCF77 time synchronisation for digital timers",
    label: "EF006326: DCF77 time synchronisation for digital timers"
  },
  {
    featureId: "EF006327",
    featureDesc: "Technique",
    label: "EF006327: Technique"
  },
  {
    featureId: "EF006328",
    featureDesc: "With stand holder",
    label: "EF006328: With stand holder"
  },
  {
    featureId: "EF006329",
    featureDesc: "With gooseneck",
    label: "EF006329: With gooseneck"
  },
  {
    featureId: "EF006330",
    featureDesc: "Number of microphone inputs",
    label: "EF006330: Number of microphone inputs"
  },
  {
    featureId: "EF006331",
    featureDesc: "Vandal proof",
    label: "EF006331: Vandal proof"
  },
  {
    featureId: "EF006332",
    featureDesc: "Screen diagonal (inch)",
    label: "EF006332: Screen diagonal (inch)"
  },
  {
    featureId: "EF006333",
    featureDesc: "Max. heating water supply temperature",
    label: "EF006333: Max. heating water supply temperature"
  },
  {
    featureId: "EF006334",
    featureDesc: "Nominal voltage 1",
    label: "EF006334: Nominal voltage 1"
  },
  {
    featureId: "EF006335",
    featureDesc: "Nominal voltage 2",
    label: "EF006335: Nominal voltage 2"
  },
  {
    featureId: "EF006336",
    featureDesc: "Min. heating power",
    label: "EF006336: Min. heating power"
  },
  {
    featureId: "EF006337",
    featureDesc: "Max. heating power",
    label: "EF006337: Max. heating power"
  },
  {
    featureId: "EF006338",
    featureDesc: "Max. mounting height",
    label: "EF006338: Max. mounting height"
  },
  {
    featureId: "EF006339",
    featureDesc: "Max. door width",
    label: "EF006339: Max. door width"
  },
  {
    featureId: "EF006340",
    featureDesc: "Air volume",
    label: "EF006340: Air volume"
  },
  {
    featureId: "EF006342",
    featureDesc: "Max. input power heating",
    label: "EF006342: Max. input power heating"
  },
  {
    featureId: "EF006343",
    featureDesc: "Sound level (in operation)",
    label: "EF006343: Sound level (in operation)"
  },
  {
    featureId: "EF006344",
    featureDesc: "With floating shoe",
    label: "EF006344: With floating shoe"
  },
  {
    featureId: "EF006345",
    featureDesc: "Suitable for aluminium",
    label: "EF006345: Suitable for aluminium"
  },
  {
    featureId: "EF006346",
    featureDesc: "Suitable for plastic",
    label: "EF006346: Suitable for plastic"
  },
  {
    featureId: "EF006347",
    featureDesc: "Suitable for copper",
    label: "EF006347: Suitable for copper"
  },
  {
    featureId: "EF006348",
    featureDesc: "Suitable for steel",
    label: "EF006348: Suitable for steel"
  },
  {
    featureId: "EF006350",
    featureDesc: "Number of drill stages",
    label: "EF006350: Number of drill stages"
  },
  {
    featureId: "EF006352",
    featureDesc: "Suitable for asphalt",
    label: "EF006352: Suitable for asphalt"
  },
  {
    featureId: "EF006353",
    featureDesc: "Suitable for brick",
    label: "EF006353: Suitable for brick"
  },
  {
    featureId: "EF006354",
    featureDesc: "Suitable for concrete",
    label: "EF006354: Suitable for concrete"
  },
  {
    featureId: "EF006355",
    featureDesc: "Suitable for concrete/concrete stone",
    label: "EF006355: Suitable for concrete/concrete stone"
  },
  {
    featureId: "EF006356",
    featureDesc: "Suitable for armoured concrete",
    label: "EF006356: Suitable for armoured concrete"
  },
  {
    featureId: "EF006357",
    featureDesc: "Suitable for cast iron pipe",
    label: "EF006357: Suitable for cast iron pipe"
  },
  {
    featureId: "EF006358",
    featureDesc: "Suitable for bluestone",
    label: "EF006358: Suitable for bluestone"
  },
  {
    featureId: "EF006359",
    featureDesc: "Suitable for calcium silicate brick",
    label: "EF006359: Suitable for calcium silicate brick"
  },
  {
    featureId: "EF006360",
    featureDesc: "Suitable for ceramics",
    label: "EF006360: Suitable for ceramics"
  },
  {
    featureId: "EF006361",
    featureDesc: "Suitable for metal",
    label: "EF006361: Suitable for metal"
  },
  {
    featureId: "EF006362",
    featureDesc: "Suitable for natural stone",
    label: "EF006362: Suitable for natural stone"
  },
  {
    featureId: "EF006363",
    featureDesc: "Suitable for chamotte",
    label: "EF006363: Suitable for chamotte"
  },
  {
    featureId: "EF006364",
    featureDesc: "Suitable for washed out concrete",
    label: "EF006364: Suitable for washed out concrete"
  },
  {
    featureId: "EF006365",
    featureDesc: "Suitable for roof tile",
    label: "EF006365: Suitable for roof tile"
  },
  {
    featureId: "EF006366",
    featureDesc: "Number of guiding drills",
    label: "EF006366: Number of guiding drills"
  },
  {
    featureId: "EF006367",
    featureDesc: "Number of fork spanners",
    label: "EF006367: Number of fork spanners"
  },
  {
    featureId: "EF006368",
    featureDesc: "Number of hot air guns",
    label: "EF006368: Number of hot air guns"
  },
  {
    featureId: "EF006369",
    featureDesc: "Number of wood borers",
    label: "EF006369: Number of wood borers"
  },
  {
    featureId: "EF006370",
    featureDesc: "Number of HSS spiral drills",
    label: "EF006370: Number of HSS spiral drills"
  },
  {
    featureId: "EF006371",
    featureDesc: "Number of cable cutters",
    label: "EF006371: Number of cable cutters"
  },
  {
    featureId: "EF006372",
    featureDesc: "Number of coaxial tools",
    label: "EF006372: Number of coaxial tools"
  },
  {
    featureId: "EF006373",
    featureDesc: "Number of crosshead screwdrivers PH",
    label: "EF006373: Number of crosshead screwdrivers PH"
  },
  {
    featureId: "EF006374",
    featureDesc: "Number of crosshead screwdrivers PZ",
    label: "EF006374: Number of crosshead screwdrivers PZ"
  },
  {
    featureId: "EF006375",
    featureDesc: "Number of hole saws",
    label: "EF006375: Number of hole saws"
  },
  {
    featureId: "EF006376",
    featureDesc: "Number of finishing tools fibre optic cable",
    label: "EF006376: Number of finishing tools fibre optic cable"
  },
  {
    featureId: "EF006377",
    featureDesc: "Number of hole edge core drills",
    label: "EF006377: Number of hole edge core drills"
  },
  {
    featureId: "EF006378",
    featureDesc: "Number of ring spanners",
    label: "EF006378: Number of ring spanners"
  },
  {
    featureId: "EF006379",
    featureDesc: "Number of slotted screwdrivers",
    label: "EF006379: Number of slotted screwdrivers"
  },
  {
    featureId: "EF006380",
    featureDesc: "Number of socket inserts",
    label: "EF006380: Number of socket inserts"
  },
  {
    featureId: "EF006381",
    featureDesc: "Number of socket spanners",
    label: "EF006381: Number of socket spanners"
  },
  {
    featureId: "EF006382",
    featureDesc: "Number of open-end spanners",
    label: "EF006382: Number of open-end spanners"
  },
  {
    featureId: "EF006383",
    featureDesc: "Number of stone drills",
    label: "EF006383: Number of stone drills"
  },
  {
    featureId: "EF006384",
    featureDesc: "Number of step drills",
    label: "EF006384: Number of step drills"
  },
  {
    featureId: "EF006385",
    featureDesc: "Number of Torx screwdrivers",
    label: "EF006385: Number of Torx screwdrivers"
  },
  {
    featureId: "EF006386",
    featureDesc: "Total number of tools",
    label: "EF006386: Total number of tools"
  },
  {
    featureId: "EF006387",
    featureDesc: "Battery type",
    label: "EF006387: Battery type"
  },
  {
    featureId: "EF006388",
    featureDesc: "Stainless steel, pickled",
    label: "EF006388: Stainless steel, pickled"
  },
  {
    featureId: "EF006392",
    featureDesc: "Hinged joint horizontal",
    label: "EF006392: Hinged joint horizontal"
  },
  {
    featureId: "EF006393",
    featureDesc: "Hinged joint vertical",
    label: "EF006393: Hinged joint vertical"
  },
  {
    featureId: "EF006394",
    featureDesc: "Suitable for rising ladder",
    label: "EF006394: Suitable for rising ladder"
  },
  {
    featureId: "EF006395",
    featureDesc: "Application lightning protection",
    label: "EF006395: Application lightning protection"
  },
  {
    featureId: "EF006398",
    featureDesc: "Suitable for duct depth",
    label: "EF006398: Suitable for duct depth"
  },
  {
    featureId: "EF006400",
    featureDesc: "Surface protection column",
    label: "EF006400: Surface protection column"
  },
  {
    featureId: "EF006401",
    featureDesc: "Suitable for number of lamellas",
    label: "EF006401: Suitable for number of lamellas"
  },
  {
    featureId: "EF006402",
    featureDesc: "Mounting method box",
    label: "EF006402: Mounting method box"
  },
  {
    featureId: "EF006403",
    featureDesc: "Height baseboard duct",
    label: "EF006403: Height baseboard duct"
  },
  {
    featureId: "EF006404",
    featureDesc: "Depth plinth skirting duct",
    label: "EF006404: Depth plinth skirting duct"
  },
  {
    featureId: "EF006405",
    featureDesc: "Colour top cover",
    label: "EF006405: Colour top cover"
  },
  {
    featureId: "EF006406",
    featureDesc: "Number of mounting openings",
    label: "EF006406: Number of mounting openings"
  },
  {
    featureId: "EF006407",
    featureDesc: "Suitable for expansion joint",
    label: "EF006407: Suitable for expansion joint"
  },
  {
    featureId: "EF006408",
    featureDesc: "Suitable for subfloor duct overhead closed",
    label: "EF006408: Suitable for subfloor duct overhead closed"
  },
  {
    featureId: "EF006409",
    featureDesc: "Suitable for subfloor duct",
    label: "EF006409: Suitable for subfloor duct"
  },
  {
    featureId: "EF006410",
    featureDesc: "Suitable for hollow floor",
    label: "EF006410: Suitable for hollow floor"
  },
  {
    featureId: "EF006411",
    featureDesc: "Suitable for double floor built-in",
    label: "EF006411: Suitable for double floor built-in"
  },
  {
    featureId: "EF006412",
    featureDesc: "Number of round openings",
    label: "EF006412: Number of round openings"
  },
  {
    featureId: "EF006413",
    featureDesc: "Number of oval openings",
    label: "EF006413: Number of oval openings"
  },
  {
    featureId: "EF006415",
    featureDesc: "With connection opening",
    label: "EF006415: With connection opening"
  },
  {
    featureId: "EF006416",
    featureDesc: "With protrusion/offset",
    label: "EF006416: With protrusion/offset"
  },
  {
    featureId: "EF006417",
    featureDesc: "Elbow plug",
    label: "EF006417: Elbow plug"
  },
  {
    featureId: "EF006418",
    featureDesc: "Separable",
    label: "EF006418: Separable"
  },
  {
    featureId: "EF006419",
    featureDesc: "Safety transformer",
    label: "EF006419: Safety transformer"
  },
  {
    featureId: "EF006420",
    featureDesc: "Luminaire with limited surface temperature, sign 'D“ according to EN 60598-2-24",
    label: "EF006420: Luminaire with limited surface temperature, sign 'D“ according to EN 60598-2-24"
  },
  {
    featureId: "EF006423",
    featureDesc: "Covering of the luminaire with thermally insulating material possible",
    label: "EF006423: Covering of the luminaire with thermally insulating material possible"
  },
  {
    featureId: "EF006426",
    featureDesc: "With cable tray",
    label: "EF006426: With cable tray"
  },
  {
    featureId: "EF006427",
    featureDesc: "Material wire",
    label: "EF006427: Material wire"
  },
  {
    featureId: "EF006428",
    featureDesc: "Wire colour",
    label: "EF006428: Wire colour"
  },
  {
    featureId: "EF006432",
    featureDesc: "Suitable for high voltage luminaires",
    label: "EF006432: Suitable for high voltage luminaires"
  },
  {
    featureId: "EF006433",
    featureDesc: "Suitable for low voltage luminaires",
    label: "EF006433: Suitable for low voltage luminaires"
  },
  {
    featureId: "EF006434",
    featureDesc: "Max. diameter luminaire",
    label: "EF006434: Max. diameter luminaire"
  },
  {
    featureId: "EF006435",
    featureDesc: "Min. ceiling thickness",
    label: "EF006435: Min. ceiling thickness"
  },
  {
    featureId: "EF006436",
    featureDesc: "Suitable for number of light sources",
    label: "EF006436: Suitable for number of light sources"
  },
  {
    featureId: "EF006437",
    featureDesc: "Surface polished",
    label: "EF006437: Surface polished"
  },
  {
    featureId: "EF006443",
    featureDesc: "Thread size PG inner thread",
    label: "EF006443: Thread size PG inner thread"
  },
  {
    featureId: "EF006447",
    featureDesc: "Thread size PG outer thread",
    label: "EF006447: Thread size PG outer thread"
  },
  {
    featureId: "EF006451",
    featureDesc: "With repair tape",
    label: "EF006451: With repair tape"
  },
  {
    featureId: "EF006452",
    featureDesc: "With abrasive cloth",
    label: "EF006452: With abrasive cloth"
  },
  {
    featureId: "EF006453",
    featureDesc: "With cleaning cloth",
    label: "EF006453: With cleaning cloth"
  },
  {
    featureId: "EF006454",
    featureDesc: "Diameter shaft before shrink",
    label: "EF006454: Diameter shaft before shrink"
  },
  {
    featureId: "EF006455",
    featureDesc: "Diameter shaft after shrink",
    label: "EF006455: Diameter shaft after shrink"
  },
  {
    featureId: "EF006466",
    featureDesc: "Inner width",
    label: "EF006466: Inner width"
  },
  {
    featureId: "EF006467",
    featureDesc: "Insulated cable end sleeves",
    label: "EF006467: Insulated cable end sleeves"
  },
  {
    featureId: "EF006468",
    featureDesc: "Insulated cable connections",
    label: "EF006468: Insulated cable connections"
  },
  {
    featureId: "EF006469",
    featureDesc: "Non-insulated cable end sleeves",
    label: "EF006469: Non-insulated cable end sleeves"
  },
  {
    featureId: "EF006471",
    featureDesc: "Crimp cable lug for Cu-conductors",
    label: "EF006471: Crimp cable lug for Cu-conductors"
  },
  {
    featureId: "EF006472",
    featureDesc: "Tube cable lug for Cu-conductors",
    label: "EF006472: Tube cable lug for Cu-conductors"
  },
  {
    featureId: "EF006474",
    featureDesc: "Cable screw glands",
    label: "EF006474: Cable screw glands"
  },
  {
    featureId: "EF006475",
    featureDesc: "With headband",
    label: "EF006475: With headband"
  },
  {
    featureId: "EF006476",
    featureDesc: "Folding",
    label: "EF006476: Folding"
  },
  {
    featureId: "EF006479",
    featureDesc: "Thread connection",
    label: "EF006479: Thread connection"
  },
  {
    featureId: "EF006480",
    featureDesc: "Suitable for plate thickness",
    label: "EF006480: Suitable for plate thickness"
  },
  {
    featureId: "EF006481",
    featureDesc: "Suitable for U-profile (8 %)",
    label: "EF006481: Suitable for U-profile (8 %)"
  },
  {
    featureId: "EF006482",
    featureDesc: "Height hook",
    label: "EF006482: Height hook"
  },
  {
    featureId: "EF006483",
    featureDesc: "With pump",
    label: "EF006483: With pump"
  },
  {
    featureId: "EF006484",
    featureDesc: "Extended top-piece",
    label: "EF006484: Extended top-piece"
  },
  {
    featureId: "EF006485",
    featureDesc: "With joint",
    label: "EF006485: With joint"
  },
  {
    featureId: "EF006486",
    featureDesc: "Number of bits PH (crosshead)",
    label: "EF006486: Number of bits PH (crosshead)"
  },
  {
    featureId: "EF006487",
    featureDesc: "Number of bits PZ (crosshead)",
    label: "EF006487: Number of bits PZ (crosshead)"
  },
  {
    featureId: "EF006488",
    featureDesc: "Number of bits Torx",
    label: "EF006488: Number of bits Torx"
  },
  {
    featureId: "EF006489",
    featureDesc: "Number of bits groove",
    label: "EF006489: Number of bits groove"
  },
  {
    featureId: "EF006490",
    featureDesc: "Number of bits total",
    label: "EF006490: Number of bits total"
  },
  {
    featureId: "EF006491",
    featureDesc: "With magnetic holder",
    label: "EF006491: With magnetic holder"
  },
  {
    featureId: "EF006492",
    featureDesc: "Sizes",
    label: "EF006492: Sizes"
  },
  {
    featureId: "EF006493",
    featureDesc: "Number of keys",
    label: "EF006493: Number of keys"
  },
  {
    featureId: "EF006494",
    featureDesc: "For lamp diameter",
    label: "EF006494: For lamp diameter"
  },
  {
    featureId: "EF006495",
    featureDesc: "Span",
    label: "EF006495: Span"
  },
  {
    featureId: "EF006498",
    featureDesc: "Suitable for hole saw",
    label: "EF006498: Suitable for hole saw"
  },
  {
    featureId: "EF006499",
    featureDesc: "Number of hole punches",
    label: "EF006499: Number of hole punches"
  },
  {
    featureId: "EF006500",
    featureDesc: "Material handle",
    label: "EF006500: Material handle"
  },
  {
    featureId: "EF006501",
    featureDesc: "Material bristles",
    label: "EF006501: Material bristles"
  },
  {
    featureId: "EF006502",
    featureDesc: "Max. cord diameter",
    label: "EF006502: Max. cord diameter"
  },
  {
    featureId: "EF006503",
    featureDesc: "Material tray",
    label: "EF006503: Material tray"
  },
  {
    featureId: "EF006504",
    featureDesc: "Insulation value SNR",
    label: "EF006504: Insulation value SNR"
  },
  {
    featureId: "EF006505",
    featureDesc: "With cord",
    label: "EF006505: With cord"
  },
  {
    featureId: "EF006506",
    featureDesc: "Number of pairs",
    label: "EF006506: Number of pairs"
  },
  {
    featureId: "EF006507",
    featureDesc: "Universal size",
    label: "EF006507: Universal size"
  },
  {
    featureId: "EF006508",
    featureDesc: "Collar size",
    label: "EF006508: Collar size"
  },
  {
    featureId: "EF006509",
    featureDesc: "Short-sleeved",
    label: "EF006509: Short-sleeved"
  },
  {
    featureId: "EF006510",
    featureDesc: "With strap",
    label: "EF006510: With strap"
  },
  {
    featureId: "EF006511",
    featureDesc: "Hood removable",
    label: "EF006511: Hood removable"
  },
  {
    featureId: "EF006512",
    featureDesc: "Heat protection",
    label: "EF006512: Heat protection"
  },
  {
    featureId: "EF006513",
    featureDesc: "Protection against chemicals",
    label: "EF006513: Protection against chemicals"
  },
  {
    featureId: "EF006515",
    featureDesc: "Suitable for hollow wall box",
    label: "EF006515: Suitable for hollow wall box"
  },
  {
    featureId: "EF006516",
    featureDesc: "Handle collapsible",
    label: "EF006516: Handle collapsible"
  },
  {
    featureId: "EF006517",
    featureDesc: "One hand control",
    label: "EF006517: One hand control"
  },
  {
    featureId: "EF006518",
    featureDesc: "Rope length",
    label: "EF006518: Rope length"
  },
  {
    featureId: "EF006519",
    featureDesc: "Outer diameter spout",
    label: "EF006519: Outer diameter spout"
  },
  {
    featureId: "EF006520",
    featureDesc: "Number of grinding stones",
    label: "EF006520: Number of grinding stones"
  },
  {
    featureId: "EF006521",
    featureDesc: "Symmetrical model",
    label: "EF006521: Symmetrical model"
  },
  {
    featureId: "EF006522",
    featureDesc: "Supply voltage lamp",
    label: "EF006522: Supply voltage lamp"
  },
  {
    featureId: "EF006523",
    featureDesc: "With protective insulation",
    label: "EF006523: With protective insulation"
  },
  {
    featureId: "EF006524",
    featureDesc: "Joy stick length",
    label: "EF006524: Joy stick length"
  },
  {
    featureId: "EF006525",
    featureDesc: "Flashing light",
    label: "EF006525: Flashing light"
  },
  {
    featureId: "EF006527",
    featureDesc: "With screw nail",
    label: "EF006527: With screw nail"
  },
  {
    featureId: "EF006529",
    featureDesc: "Material screw",
    label: "EF006529: Material screw"
  },
  {
    featureId: "EF006530",
    featureDesc: "Material clamp",
    label: "EF006530: Material clamp"
  },
  {
    featureId: "EF006531",
    featureDesc: "Double model",
    label: "EF006531: Double model"
  },
  {
    featureId: "EF006533",
    featureDesc: "Insulating",
    label: "EF006533: Insulating"
  },
  {
    featureId: "EF006534",
    featureDesc: "Suitable for high voltage",
    label: "EF006534: Suitable for high voltage"
  },
  {
    featureId: "EF006535",
    featureDesc: "Vulcanizing",
    label: "EF006535: Vulcanizing"
  },
  {
    featureId: "EF006536",
    featureDesc: "Min. busbar thickness",
    label: "EF006536: Min. busbar thickness"
  },
  {
    featureId: "EF006537",
    featureDesc: "Lamella thickness",
    label: "EF006537: Lamella thickness"
  },
  {
    featureId: "EF006538",
    featureDesc: "Number of lamellas",
    label: "EF006538: Number of lamellas"
  },
  {
    featureId: "EF006539",
    featureDesc: "Max. number of padlocks",
    label: "EF006539: Max. number of padlocks"
  },
  {
    featureId: "EF006540",
    featureDesc: "Suitable for shackle diameter",
    label: "EF006540: Suitable for shackle diameter"
  },
  {
    featureId: "EF006551",
    featureDesc: "Number of fuses",
    label: "EF006551: Number of fuses"
  },
  {
    featureId: "EF006552",
    featureDesc: "Rebuilding from fix to plug-in",
    label: "EF006552: Rebuilding from fix to plug-in"
  },
  {
    featureId: "EF006553",
    featureDesc: "Rebuilding from plug-in to fix",
    label: "EF006553: Rebuilding from plug-in to fix"
  },
  {
    featureId: "EF006555",
    featureDesc: "Ampere meter",
    label: "EF006555: Ampere meter"
  },
  {
    featureId: "EF006556",
    featureDesc: "Blind power meter",
    label: "EF006556: Blind power meter"
  },
  {
    featureId: "EF006557",
    featureDesc: "With rotational speed meter",
    label: "EF006557: With rotational speed meter"
  },
  {
    featureId: "EF006558",
    featureDesc: "Frequency meter",
    label: "EF006558: Frequency meter"
  },
  {
    featureId: "EF006559",
    featureDesc: "Pulse counter",
    label: "EF006559: Pulse counter"
  },
  {
    featureId: "EF006560",
    featureDesc: "Voltmeter",
    label: "EF006560: Voltmeter"
  },
  {
    featureId: "EF006561",
    featureDesc: "Effective power measurement device",
    label: "EF006561: Effective power measurement device"
  },
  {
    featureId: "EF006562",
    featureDesc: "With hook",
    label: "EF006562: With hook"
  },
  {
    featureId: "EF006569",
    featureDesc: "Surface finishing",
    label: "EF006569: Surface finishing"
  },
  {
    featureId: "EF006570",
    featureDesc: "Electronic controllable",
    label: "EF006570: Electronic controllable"
  },
  {
    featureId: "EF006571",
    featureDesc: "With miniature fuse",
    label: "EF006571: With miniature fuse"
  },
  {
    featureId: "EF006572",
    featureDesc: "Suitable for wall duct",
    label: "EF006572: Suitable for wall duct"
  },
  {
    featureId: "EF006575",
    featureDesc: "With flashlight",
    label: "EF006575: With flashlight"
  },
  {
    featureId: "EF006577",
    featureDesc: "Number of CEE socket outlets",
    label: "EF006577: Number of CEE socket outlets"
  },
  {
    featureId: "EF006578",
    featureDesc: "Number of socket outlets with earth pin",
    label: "EF006578: Number of socket outlets with earth pin"
  },
  {
    featureId: "EF006579",
    featureDesc: "Number of other socket outlets",
    label: "EF006579: Number of other socket outlets"
  },
  {
    featureId: "EF006580",
    featureDesc: "Number of cold device socket outlets",
    label: "EF006580: Number of cold device socket outlets"
  },
  {
    featureId: "EF006581",
    featureDesc: "Detection angle vertical",
    label: "EF006581: Detection angle vertical"
  },
  {
    featureId: "EF006582",
    featureDesc: "Detection angle horizontal",
    label: "EF006582: Detection angle horizontal"
  },
  {
    featureId: "EF006583",
    featureDesc: "Suitable for wire wound low voltage transformer",
    label: "EF006583: Suitable for wire wound low voltage transformer"
  },
  {
    featureId: "EF006584",
    featureDesc: "Clock hour position",
    label: "EF006584: Clock hour position"
  },
  {
    featureId: "EF006585",
    featureDesc: "With operation lever",
    label: "EF006585: With operation lever"
  },
  {
    featureId: "EF006586",
    featureDesc: "End plate model",
    label: "EF006586: End plate model"
  },
  {
    featureId: "EF006587",
    featureDesc: "Partition model",
    label: "EF006587: Partition model"
  },
  {
    featureId: "EF006588",
    featureDesc: "Inner height",
    label: "EF006588: Inner height"
  },
  {
    featureId: "EF006589",
    featureDesc: "Min. switch-on time",
    label: "EF006589: Min. switch-on time"
  },
  {
    featureId: "EF006590",
    featureDesc: "Max. duty cycle",
    label: "EF006590: Max. duty cycle"
  },
  {
    featureId: "EF006591",
    featureDesc: "Clickable",
    label: "EF006591: Clickable"
  },
  {
    featureId: "EF006596",
    featureDesc: "Suitable for flange thickness",
    label: "EF006596: Suitable for flange thickness"
  },
  {
    featureId: "EF006597",
    featureDesc: "With welded on nut",
    label: "EF006597: With welded on nut"
  },
  {
    featureId: "EF006599",
    featureDesc: "Roofing sheet mounting with stud",
    label: "EF006599: Roofing sheet mounting with stud"
  },
  {
    featureId: "EF006600",
    featureDesc: "Plastic sheathed",
    label: "EF006600: Plastic sheathed"
  },
  {
    featureId: "EF006601",
    featureDesc: "Pin dimension",
    label: "EF006601: Pin dimension"
  },
  {
    featureId: "EF006602",
    featureDesc: "Video norm",
    label: "EF006602: Video norm"
  },
  {
    featureId: "EF006603",
    featureDesc: "Number of monitor outputs",
    label: "EF006603: Number of monitor outputs"
  },
  {
    featureId: "EF006604",
    featureDesc: "With loop through function",
    label: "EF006604: With loop through function"
  },
  {
    featureId: "EF006605",
    featureDesc: "Automatic sequence",
    label: "EF006605: Automatic sequence"
  },
  {
    featureId: "EF006606",
    featureDesc: "Video loss function",
    label: "EF006606: Video loss function"
  },
  {
    featureId: "EF006607",
    featureDesc: "Text generator",
    label: "EF006607: Text generator"
  },
  {
    featureId: "EF006608",
    featureDesc: "OSD-programming",
    label: "EF006608: OSD-programming"
  },
  {
    featureId: "EF006609",
    featureDesc: "With communication interface",
    label: "EF006609: With communication interface"
  },
  {
    featureId: "EF006610",
    featureDesc: "Full screen at alarm",
    label: "EF006610: Full screen at alarm"
  },
  {
    featureId: "EF006611",
    featureDesc: "Automatic alarm processing",
    label: "EF006611: Automatic alarm processing"
  },
  {
    featureId: "EF006612",
    featureDesc: "Interrupt free shifting",
    label: "EF006612: Interrupt free shifting"
  },
  {
    featureId: "EF006613",
    featureDesc: "Output current",
    label: "EF006613: Output current"
  },
  {
    featureId: "EF006615",
    featureDesc: "Bit size",
    label: "EF006615: Bit size"
  },
  {
    featureId: "EF006616",
    featureDesc: "Wrench size",
    label: "EF006616: Wrench size"
  },
  {
    featureId: "EF006617",
    featureDesc: "Size crosshead",
    label: "EF006617: Size crosshead"
  },
  {
    featureId: "EF006619",
    featureDesc: "Size tap wrench",
    label: "EF006619: Size tap wrench"
  },
  {
    featureId: "EF006620",
    featureDesc: "Number of socket outlets CEE 7/16 (EURO)",
    label: "EF006620: Number of socket outlets CEE 7/16 (EURO)"
  },
  {
    featureId: "EF006622",
    featureDesc: "For lamp type HM",
    label: "EF006622: For lamp type HM"
  },
  {
    featureId: "EF006623",
    featureDesc: "For lamp type HS",
    label: "EF006623: For lamp type HS"
  },
  {
    featureId: "EF006624",
    featureDesc: "For lamp type LS",
    label: "EF006624: For lamp type LS"
  },
  {
    featureId: "EF006625",
    featureDesc: "For lamp type T16-R",
    label: "EF006625: For lamp type T16-R"
  },
  {
    featureId: "EF006626",
    featureDesc: "For lamp type T38",
    label: "EF006626: For lamp type T38"
  },
  {
    featureId: "EF006627",
    featureDesc: "For lamp type TC-F",
    label: "EF006627: For lamp type TC-F"
  },
  {
    featureId: "EF006628",
    featureDesc: "For lamp type TC-QEL",
    label: "EF006628: For lamp type TC-QEL"
  },
  {
    featureId: "EF006629",
    featureDesc: "For lamp type TC-SEL",
    label: "EF006629: For lamp type TC-SEL"
  },
  {
    featureId: "EF006630",
    featureDesc: "For lamp type T-U",
    label: "EF006630: For lamp type T-U"
  },
  {
    featureId: "EF006631",
    featureDesc: "Suitable for lamp power (1 lamp)",
    label: "EF006631: Suitable for lamp power (1 lamp)"
  },
  {
    featureId: "EF006632",
    featureDesc: "Suitable for lamp power (2 lamps)",
    label: "EF006632: Suitable for lamp power (2 lamps)"
  },
  {
    featureId: "EF006633",
    featureDesc: "Suitable for lamp power (3 lamps)",
    label: "EF006633: Suitable for lamp power (3 lamps)"
  },
  {
    featureId: "EF006634",
    featureDesc: "Suitable for lamp power (4 lamps)",
    label: "EF006634: Suitable for lamp power (4 lamps)"
  },
  {
    featureId: "EF006635",
    featureDesc: "Suitable for explosion proof luminaires",
    label: "EF006635: Suitable for explosion proof luminaires"
  },
  {
    featureId: "EF006637",
    featureDesc: "Number of files",
    label: "EF006637: Number of files"
  },
  {
    featureId: "EF006638",
    featureDesc: "Number of bits hexagon socket",
    label: "EF006638: Number of bits hexagon socket"
  },
  {
    featureId: "EF006640",
    featureDesc: "Material of point",
    label: "EF006640: Material of point"
  },
  {
    featureId: "EF006641",
    featureDesc: "Diamond wheel",
    label: "EF006641: Diamond wheel"
  },
  {
    featureId: "EF006644",
    featureDesc: "Suitable for wall mounting",
    label: "EF006644: Suitable for wall mounting"
  },
  {
    featureId: "EF006645",
    featureDesc: "Head width",
    label: "EF006645: Head width"
  },
  {
    featureId: "EF006647",
    featureDesc: "With editor for programming language Flowchart",
    label: "EF006647: With editor for programming language Flowchart"
  },
  {
    featureId: "EF006652",
    featureDesc: "Digital in-/outputs, configurable",
    label: "EF006652: Digital in-/outputs, configurable"
  },
  {
    featureId: "EF006653",
    featureDesc: "Remote module",
    label: "EF006653: Remote module"
  },
  {
    featureId: "EF006657",
    featureDesc: "Supporting protocol for SERCOS",
    label: "EF006657: Supporting protocol for SERCOS"
  },
  {
    featureId: "EF006662",
    featureDesc: "Supporting protocol for INTERBUS-Safety",
    label: "EF006662: Supporting protocol for INTERBUS-Safety"
  },
  {
    featureId: "EF006663",
    featureDesc: "Supporting protocol for PROFIBUS Safety",
    label: "EF006663: Supporting protocol for PROFIBUS Safety"
  },
  {
    featureId: "EF006668",
    featureDesc: "Release class",
    label: "EF006668: Release class"
  },
  {
    featureId: "EF006669",
    featureDesc: "Suitable for number of built-in signal lights",
    label: "EF006669: Suitable for number of built-in signal lights"
  },
  {
    featureId: "EF006670",
    featureDesc: "Number of mushroom-shaped push-buttons",
    label: "EF006670: Number of mushroom-shaped push-buttons"
  },
  {
    featureId: "EF006671",
    featureDesc: "Position of connection for main current circuit",
    label: "EF006671: Position of connection for main current circuit"
  },
  {
    featureId: "EF006673",
    featureDesc: "110-compatible",
    label: "EF006673: 110-compatible"
  },
  {
    featureId: "EF006674",
    featureDesc: "Branch off type",
    label: "EF006674: Branch off type"
  },
  {
    featureId: "EF006679",
    featureDesc: "Suitable for head light passenger car",
    label: "EF006679: Suitable for head light passenger car"
  },
  {
    featureId: "EF006680",
    featureDesc: "Suitable for head light truck",
    label: "EF006680: Suitable for head light truck"
  },
  {
    featureId: "EF006681",
    featureDesc: "Suitable for other lighting passenger car",
    label: "EF006681: Suitable for other lighting passenger car"
  },
  {
    featureId: "EF006682",
    featureDesc: "Suitable for other lighting truck",
    label: "EF006682: Suitable for other lighting truck"
  },
  {
    featureId: "EF006685",
    featureDesc: "Height chimney",
    label: "EF006685: Height chimney"
  },
  {
    featureId: "EF006686",
    featureDesc: "Height cap",
    label: "EF006686: Height cap"
  },
  {
    featureId: "EF006687",
    featureDesc: "Total height",
    label: "EF006687: Total height"
  },
  {
    featureId: "EF006688",
    featureDesc: "Edge exhaustion",
    label: "EF006688: Edge exhaustion"
  },
  {
    featureId: "EF006689",
    featureDesc: "Castor adjustment",
    label: "EF006689: Castor adjustment"
  },
  {
    featureId: "EF006690",
    featureDesc: "Acoustic damping",
    label: "EF006690: Acoustic damping"
  },
  {
    featureId: "EF006691",
    featureDesc: "With short-circuit release",
    label: "EF006691: With short-circuit release"
  },
  {
    featureId: "EF006692",
    featureDesc: "Suitable for fuses",
    label: "EF006692: Suitable for fuses"
  },
  {
    featureId: "EF006693",
    featureDesc: "With fuse",
    label: "EF006693: With fuse"
  },
  {
    featureId: "EF006694",
    featureDesc: "Forced opening",
    label: "EF006694: Forced opening"
  },
  {
    featureId: "EF006697",
    featureDesc: "Motor drive integrated",
    label: "EF006697: Motor drive integrated"
  },
  {
    featureId: "EF006700",
    featureDesc: "For thread diameter",
    label: "EF006700: For thread diameter"
  },
  {
    featureId: "EF006702",
    featureDesc: "Type of shaft",
    label: "EF006702: Type of shaft"
  },
  {
    featureId: "EF006703",
    featureDesc: "Printer integrated",
    label: "EF006703: Printer integrated"
  },
  {
    featureId: "EF006704",
    featureDesc: "With reset function",
    label: "EF006704: With reset function"
  },
  {
    featureId: "EF006705",
    featureDesc: "Frequency input voltage",
    label: "EF006705: Frequency input voltage"
  },
  {
    featureId: "EF006706",
    featureDesc: "Rated switch current",
    label: "EF006706: Rated switch current"
  },
  {
    featureId: "EF006707",
    featureDesc: "Push button glow-lamp current",
    label: "EF006707: Push button glow-lamp current"
  },
  {
    featureId: "EF006708",
    featureDesc: "Adjustment range brightness value",
    label: "EF006708: Adjustment range brightness value"
  },
  {
    featureId: "EF006709",
    featureDesc: "Protection class light sensor",
    label: "EF006709: Protection class light sensor"
  },
  {
    featureId: "EF006710",
    featureDesc: "Mounting method light sensor",
    label: "EF006710: Mounting method light sensor"
  },
  {
    featureId: "EF006711",
    featureDesc: "Monitoring voltage",
    label: "EF006711: Monitoring voltage"
  },
  {
    featureId: "EF006712",
    featureDesc: "Assembly depth",
    label: "EF006712: Assembly depth"
  },
  {
    featureId: "EF006713",
    featureDesc: "Central function OUT",
    label: "EF006713: Central function OUT"
  },
  {
    featureId: "EF006715",
    featureDesc: "Stair light and forewarn",
    label: "EF006715: Stair light and forewarn"
  },
  {
    featureId: "EF006716",
    featureDesc: "Castor function, number of channels",
    label: "EF006716: Castor function, number of channels"
  },
  {
    featureId: "EF006717",
    featureDesc: "Number of universal dim channels",
    label: "EF006717: Number of universal dim channels"
  },
  {
    featureId: "EF006718",
    featureDesc: "Roller shutter-/sun protection control, number of channels",
    label: "EF006718: Roller shutter-/sun protection control, number of channels"
  },
  {
    featureId: "EF006719",
    featureDesc: "Wind sensor",
    label: "EF006719: Wind sensor"
  },
  {
    featureId: "EF006720",
    featureDesc: "Rain sensor",
    label: "EF006720: Rain sensor"
  },
  {
    featureId: "EF006721",
    featureDesc: "Temperature sensor",
    label: "EF006721: Temperature sensor"
  },
  {
    featureId: "EF006722",
    featureDesc: "Brightness sensor",
    label: "EF006722: Brightness sensor"
  },
  {
    featureId: "EF006723",
    featureDesc: "Twilight sensor",
    label: "EF006723: Twilight sensor"
  },
  {
    featureId: "EF006724",
    featureDesc: "Module expansion up to max. number of channels",
    label: "EF006724: Module expansion up to max. number of channels"
  },
  {
    featureId: "EF006725",
    featureDesc: "Identification colour",
    label: "EF006725: Identification colour"
  },
  {
    featureId: "EF006726",
    featureDesc: "Voltage according to EN 60309-2",
    label: "EF006726: Voltage according to EN 60309-2"
  },
  {
    featureId: "EF006727",
    featureDesc: "Days off programmable",
    label: "EF006727: Days off programmable"
  },
  {
    featureId: "EF006728",
    featureDesc: "Sub-clocks controllable over bus-system",
    label: "EF006728: Sub-clocks controllable over bus-system"
  },
  {
    featureId: "EF006729",
    featureDesc: "Temperature control with built-in continuous control",
    label: "EF006729: Temperature control with built-in continuous control"
  },
  {
    featureId: "EF006730",
    featureDesc: "Buttons for manual operation at the drive",
    label: "EF006730: Buttons for manual operation at the drive"
  },
  {
    featureId: "EF006731",
    featureDesc: "Temperature and stroke indication",
    label: "EF006731: Temperature and stroke indication"
  },
  {
    featureId: "EF006733",
    featureDesc: "Voltage supply over bus",
    label: "EF006733: Voltage supply over bus"
  },
  {
    featureId: "EF006735",
    featureDesc: "With DCF77",
    label: "EF006735: With DCF77"
  },
  {
    featureId: "EF006736",
    featureDesc: "Acoustic signal",
    label: "EF006736: Acoustic signal"
  },
  {
    featureId: "EF006737",
    featureDesc: "Interface 1-10 V",
    label: "EF006737: Interface 1-10 V"
  },
  {
    featureId: "EF006738",
    featureDesc: "Modular expandability",
    label: "EF006738: Modular expandability"
  },
  {
    featureId: "EF006741",
    featureDesc: "Correcting variable consistent",
    label: "EF006741: Correcting variable consistent"
  },
  {
    featureId: "EF006742",
    featureDesc: "Correcting variable switching",
    label: "EF006742: Correcting variable switching"
  },
  {
    featureId: "EF006743",
    featureDesc: "Valve protection function",
    label: "EF006743: Valve protection function"
  },
  {
    featureId: "EF006744",
    featureDesc: "Manual switch",
    label: "EF006744: Manual switch"
  },
  {
    featureId: "EF006745",
    featureDesc: "Suitable for built-in installation",
    label: "EF006745: Suitable for built-in installation"
  },
  {
    featureId: "EF006748",
    featureDesc: "With exchangeable symbol",
    label: "EF006748: With exchangeable symbol"
  },
  {
    featureId: "EF006751",
    featureDesc: "With screw gland entry",
    label: "EF006751: With screw gland entry"
  },
  {
    featureId: "EF006752",
    featureDesc: "With voice announcement function",
    label: "EF006752: With voice announcement function"
  },
  {
    featureId: "EF006753",
    featureDesc: "With tuner",
    label: "EF006753: With tuner"
  },
  {
    featureId: "EF006754",
    featureDesc: "With frequency indication",
    label: "EF006754: With frequency indication"
  },
  {
    featureId: "EF006755",
    featureDesc: "Suitable for degree of protection (IP)",
    label: "EF006755: Suitable for degree of protection (IP)"
  },
  {
    featureId: "EF006756",
    featureDesc: "Number of installation boxes",
    label: "EF006756: Number of installation boxes"
  },
  {
    featureId: "EF006757",
    featureDesc: "Material keyboard",
    label: "EF006757: Material keyboard"
  },
  {
    featureId: "EF006758",
    featureDesc: "Connection network",
    label: "EF006758: Connection network"
  },
  {
    featureId: "EF006759",
    featureDesc: "Suitable for table mounting",
    label: "EF006759: Suitable for table mounting"
  },
  {
    featureId: "EF006760",
    featureDesc: "Suitable for built-in mounting",
    label: "EF006760: Suitable for built-in mounting"
  },
  {
    featureId: "EF006761",
    featureDesc: "Hands free kit",
    label: "EF006761: Hands free kit"
  },
  {
    featureId: "EF006762",
    featureDesc: "Signal type",
    label: "EF006762: Signal type"
  },
  {
    featureId: "EF006765",
    featureDesc: "Number of colours",
    label: "EF006765: Number of colours"
  },
  {
    featureId: "EF006766",
    featureDesc: "Number of modes",
    label: "EF006766: Number of modes"
  },
  {
    featureId: "EF006767",
    featureDesc: "Built-in acoustic signal",
    label: "EF006767: Built-in acoustic signal"
  },
  {
    featureId: "EF006769",
    featureDesc: "Number of connections per pole",
    label: "EF006769: Number of connections per pole"
  },
  {
    featureId: "EF006771",
    featureDesc: "Pole codification",
    label: "EF006771: Pole codification"
  },
  {
    featureId: "EF006772",
    featureDesc: "Colour coding/contact insert",
    label: "EF006772: Colour coding/contact insert"
  },
  {
    featureId: "EF006773",
    featureDesc: "Fire load",
    label: "EF006773: Fire load"
  },
  {
    featureId: "EF006776",
    featureDesc: "Built-in version",
    label: "EF006776: Built-in version"
  },
  {
    featureId: "EF006780",
    featureDesc: "Mains voltage",
    label: "EF006780: Mains voltage"
  },
  {
    featureId: "EF006781",
    featureDesc: "Rated operation current Ie",
    label: "EF006781: Rated operation current Ie"
  },
  {
    featureId: "EF006783",
    featureDesc: "Mains frequency",
    label: "EF006783: Mains frequency"
  },
  {
    featureId: "EF006784",
    featureDesc: "Resolution of the light curtain",
    label: "EF006784: Resolution of the light curtain"
  },
  {
    featureId: "EF006785",
    featureDesc: "Over voltage category",
    label: "EF006785: Over voltage category"
  },
  {
    featureId: "EF006786",
    featureDesc: "Pollution degree",
    label: "EF006786: Pollution degree"
  },
  {
    featureId: "EF006789",
    featureDesc: "Component of the desk system",
    label: "EF006789: Component of the desk system"
  },
  {
    featureId: "EF006790",
    featureDesc: "Output voltage AC",
    label: "EF006790: Output voltage AC"
  },
  {
    featureId: "EF006792",
    featureDesc: "1st secondary output voltage",
    label: "EF006792: 1st secondary output voltage"
  },
  {
    featureId: "EF006793",
    featureDesc: "1st secondary output voltage AC",
    label: "EF006793: 1st secondary output voltage AC"
  },
  {
    featureId: "EF006794",
    featureDesc: "1st secondary output voltage DC",
    label: "EF006794: 1st secondary output voltage DC"
  },
  {
    featureId: "EF006795",
    featureDesc: "Mounting level",
    label: "EF006795: Mounting level"
  },
  {
    featureId: "EF006796",
    featureDesc: "Type of profile rail",
    label: "EF006796: Type of profile rail"
  },
  {
    featureId: "EF006797",
    featureDesc: "2nd secondary output voltage",
    label: "EF006797: 2nd secondary output voltage"
  },
  {
    featureId: "EF006798",
    featureDesc: "2nd secondary output voltage AC",
    label: "EF006798: 2nd secondary output voltage AC"
  },
  {
    featureId: "EF006799",
    featureDesc: "2nd secondary output voltage DC",
    label: "EF006799: 2nd secondary output voltage DC"
  },
  {
    featureId: "EF006800",
    featureDesc: "3rd secondary output voltage",
    label: "EF006800: 3rd secondary output voltage"
  },
  {
    featureId: "EF006801",
    featureDesc: "3rd secondary output voltage AC",
    label: "EF006801: 3rd secondary output voltage AC"
  },
  {
    featureId: "EF006802",
    featureDesc: "3rd secondary output voltage DC",
    label: "EF006802: 3rd secondary output voltage DC"
  },
  {
    featureId: "EF006803",
    featureDesc: "4-quadrant operation possible",
    label: "EF006803: 4-quadrant operation possible"
  },
  {
    featureId: "EF006805",
    featureDesc: "Alarm-management",
    label: "EF006805: Alarm-management"
  },
  {
    featureId: "EF006807",
    featureDesc: "Analogue output 0 V ... 10 V",
    label: "EF006807: Analogue output 0 V ... 10 V"
  },
  {
    featureId: "EF006808",
    featureDesc: "Analogue output 0 mA ... 20 mA",
    label: "EF006808: Analogue output 0 mA ... 20 mA"
  },
  {
    featureId: "EF006809",
    featureDesc: "Analogue output -10 V ... +10 V",
    label: "EF006809: Analogue output -10 V ... +10 V"
  },
  {
    featureId: "EF006810",
    featureDesc: "Analogue output 4 mA ... 20 mA",
    label: "EF006810: Analogue output 4 mA ... 20 mA"
  },
  {
    featureId: "EF006811",
    featureDesc: "Add-on type",
    label: "EF006811: Add-on type"
  },
  {
    featureId: "EF006812",
    featureDesc: "Initial value measuring range pressure",
    label: "EF006812: Initial value measuring range pressure"
  },
  {
    featureId: "EF006815",
    featureDesc: "Alignment of cooling unit",
    label: "EF006815: Alignment of cooling unit"
  },
  {
    featureId: "EF006816",
    featureDesc: "Connectable cable type",
    label: "EF006816: Connectable cable type"
  },
  {
    featureId: "EF006817",
    featureDesc: "Connection for NPN-sensors",
    label: "EF006817: Connection for NPN-sensors"
  },
  {
    featureId: "EF006819",
    featureDesc: "Type of electrical connection of main circuit",
    label: "EF006819: Type of electrical connection of main circuit"
  },
  {
    featureId: "EF006820",
    featureDesc: "Connection type auxiliary circuit",
    label: "EF006820: Connection type auxiliary circuit"
  },
  {
    featureId: "EF006821",
    featureDesc: "Connection type conductor",
    label: "EF006821: Connection type conductor"
  },
  {
    featureId: "EF006822",
    featureDesc: "Connection type PCB",
    label: "EF006822: Connection type PCB"
  },
  {
    featureId: "EF006823",
    featureDesc: "Diameter connection pin",
    label: "EF006823: Diameter connection pin"
  },
  {
    featureId: "EF006824",
    featureDesc: "Strip length",
    label: "EF006824: Strip length"
  },
  {
    featureId: "EF006825",
    featureDesc: "Length pin",
    label: "EF006825: Length pin"
  },
  {
    featureId: "EF006826",
    featureDesc: "Material connection surface",
    label: "EF006826: Material connection surface"
  },
  {
    featureId: "EF006827",
    featureDesc: "Modular spacing connections",
    label: "EF006827: Modular spacing connections"
  },
  {
    featureId: "EF006830",
    featureDesc: "Number of analogue outputs, integrated",
    label: "EF006830: Number of analogue outputs, integrated"
  },
  {
    featureId: "EF006832",
    featureDesc: "Number of analogue inputs, integrated",
    label: "EF006832: Number of analogue inputs, integrated"
  },
  {
    featureId: "EF006833",
    featureDesc: "Number of connectable valves",
    label: "EF006833: Number of connectable valves"
  },
  {
    featureId: "EF006835",
    featureDesc: "Number of outputs, undelayed, normally closed contact",
    label: "EF006835: Number of outputs, undelayed, normally closed contact"
  },
  {
    featureId: "EF006836",
    featureDesc: "Number of outputs, undelayed, normally open contact",
    label: "EF006836: Number of outputs, undelayed, normally open contact"
  },
  {
    featureId: "EF006837",
    featureDesc: "Number of outputs, undelayed, change-over contact",
    label: "EF006837: Number of outputs, undelayed, change-over contact"
  },
  {
    featureId: "EF006838",
    featureDesc: "Number of outputs, delayed, normally closed contact",
    label: "EF006838: Number of outputs, delayed, normally closed contact"
  },
  {
    featureId: "EF006839",
    featureDesc: "Number of outputs, delayed, normally open contact",
    label: "EF006839: Number of outputs, delayed, normally open contact"
  },
  {
    featureId: "EF006840",
    featureDesc: "Number of outputs, delayed, change-over contact",
    label: "EF006840: Number of outputs, delayed, change-over contact"
  },
  {
    featureId: "EF006841",
    featureDesc: "Number of output ranges +10 V ... -10 V",
    label: "EF006841: Number of output ranges +10 V ... -10 V"
  },
  {
    featureId: "EF006842",
    featureDesc: "Number of output ranges +5 V ... -5 V",
    label: "EF006842: Number of output ranges +5 V ... -5 V"
  },
  {
    featureId: "EF006843",
    featureDesc: "Number of output ranges 0 V ... 1 V",
    label: "EF006843: Number of output ranges 0 V ... 1 V"
  },
  {
    featureId: "EF006844",
    featureDesc: "Number of output ranges 0 mA ... 10 mA",
    label: "EF006844: Number of output ranges 0 mA ... 10 mA"
  },
  {
    featureId: "EF006845",
    featureDesc: "Number of output ranges 0 V ... 10 V",
    label: "EF006845: Number of output ranges 0 V ... 10 V"
  },
  {
    featureId: "EF006846",
    featureDesc: "Number of output ranges 0 mV ... 100 mV",
    label: "EF006846: Number of output ranges 0 mV ... 100 mV"
  },
  {
    featureId: "EF006847",
    featureDesc: "Number of output ranges 0 V ... 2 V",
    label: "EF006847: Number of output ranges 0 V ... 2 V"
  },
  {
    featureId: "EF006848",
    featureDesc: "Number of output ranges 0 V ... 2.5 V",
    label: "EF006848: Number of output ranges 0 V ... 2.5 V"
  },
  {
    featureId: "EF006849",
    featureDesc: "Number of output ranges 0 mA ... 20 mA",
    label: "EF006849: Number of output ranges 0 mA ... 20 mA"
  },
  {
    featureId: "EF006850",
    featureDesc: "Number of output ranges 0 V ... 20 V",
    label: "EF006850: Number of output ranges 0 V ... 20 V"
  },
  {
    featureId: "EF006851",
    featureDesc: "Number of output ranges 0 mV ... 200 mV",
    label: "EF006851: Number of output ranges 0 mV ... 200 mV"
  },
  {
    featureId: "EF006852",
    featureDesc: "Number of output ranges 0 V ... 24 V",
    label: "EF006852: Number of output ranges 0 V ... 24 V"
  },
  {
    featureId: "EF006853",
    featureDesc: "Number of output ranges 0 V ... 30 V",
    label: "EF006853: Number of output ranges 0 V ... 30 V"
  },
  {
    featureId: "EF006854",
    featureDesc: "Number of output ranges 0 mV ... 300 mV",
    label: "EF006854: Number of output ranges 0 mV ... 300 mV"
  },
  {
    featureId: "EF006855",
    featureDesc: "Number of output ranges 0 mA ... 5 mA",
    label: "EF006855: Number of output ranges 0 mA ... 5 mA"
  },
  {
    featureId: "EF006856",
    featureDesc: "Number of output ranges 0 V ... 5 V",
    label: "EF006856: Number of output ranges 0 V ... 5 V"
  },
  {
    featureId: "EF006857",
    featureDesc: "Number of output ranges 0 mV ... 500 mV",
    label: "EF006857: Number of output ranges 0 mV ... 500 mV"
  },
  {
    featureId: "EF006858",
    featureDesc: "Number of output ranges 0 mV ... 60 mV",
    label: "EF006858: Number of output ranges 0 mV ... 60 mV"
  },
  {
    featureId: "EF006859",
    featureDesc: "Number of output ranges -1 V ... +1 V",
    label: "EF006859: Number of output ranges -1 V ... +1 V"
  },
  {
    featureId: "EF006860",
    featureDesc: "Number of output ranges 1 V ... 5 V",
    label: "EF006860: Number of output ranges 1 V ... 5 V"
  },
  {
    featureId: "EF006861",
    featureDesc: "Number of output ranges -10 mA ... +10 mA",
    label: "EF006861: Number of output ranges -10 mA ... +10 mA"
  },
  {
    featureId: "EF006862",
    featureDesc: "Number of output ranges -10 V ... +10 V",
    label: "EF006862: Number of output ranges -10 V ... +10 V"
  },
  {
    featureId: "EF006863",
    featureDesc: "Number of output ranges 10 V ... 0 V",
    label: "EF006863: Number of output ranges 10 V ... 0 V"
  },
  {
    featureId: "EF006864",
    featureDesc: "Number of output ranges 10 V ... 2 V",
    label: "EF006864: Number of output ranges 10 V ... 2 V"
  },
  {
    featureId: "EF006865",
    featureDesc: "Number of output ranges -100 mV ... +100 mV",
    label: "EF006865: Number of output ranges -100 mV ... +100 mV"
  },
  {
    featureId: "EF006866",
    featureDesc: "Number of output ranges -2 V ... +2 V",
    label: "EF006866: Number of output ranges -2 V ... +2 V"
  },
  {
    featureId: "EF006867",
    featureDesc: "Number of output ranges 2 V ... 10 V",
    label: "EF006867: Number of output ranges 2 V ... 10 V"
  },
  {
    featureId: "EF006868",
    featureDesc: "Number of output ranges -2.5 V ... +2.5 V",
    label: "EF006868: Number of output ranges -2.5 V ... +2.5 V"
  },
  {
    featureId: "EF006869",
    featureDesc: "Number of output ranges -20 mA ... +20 mA",
    label: "EF006869: Number of output ranges -20 mA ... +20 mA"
  },
  {
    featureId: "EF006870",
    featureDesc: "Number of output ranges -20 V ... +20 V",
    label: "EF006870: Number of output ranges -20 V ... +20 V"
  },
  {
    featureId: "EF006871",
    featureDesc: "Number of output ranges 20 mA ... 0 mA",
    label: "EF006871: Number of output ranges 20 mA ... 0 mA"
  },
  {
    featureId: "EF006872",
    featureDesc: "Number of output ranges 20 mA ... 4 mA",
    label: "EF006872: Number of output ranges 20 mA ... 4 mA"
  },
  {
    featureId: "EF006873",
    featureDesc: "Number of output ranges -300 mV ... +300 mV",
    label: "EF006873: Number of output ranges -300 mV ... +300 mV"
  },
  {
    featureId: "EF006874",
    featureDesc: "Number of output ranges 4 mA ... 20 mA",
    label: "EF006874: Number of output ranges 4 mA ... 20 mA"
  },
  {
    featureId: "EF006875",
    featureDesc: "Number of output ranges -5 mA ... +5 mA",
    label: "EF006875: Number of output ranges -5 mA ... +5 mA"
  },
  {
    featureId: "EF006876",
    featureDesc: "Number of output ranges -5 V ... +5 V",
    label: "EF006876: Number of output ranges -5 V ... +5 V"
  },
  {
    featureId: "EF006877",
    featureDesc: "Number of output ranges 5 V ... 0 V",
    label: "EF006877: Number of output ranges 5 V ... 0 V"
  },
  {
    featureId: "EF006878",
    featureDesc: "Number of output ranges 5 V ... 1 V",
    label: "EF006878: Number of output ranges 5 V ... 1 V"
  },
  {
    featureId: "EF006879",
    featureDesc: "Number of output ranges -500 mV ... +500 mV",
    label: "EF006879: Number of output ranges -500 mV ... +500 mV"
  },
  {
    featureId: "EF006880",
    featureDesc: "Number of output ranges -60 mV ... +60 mV",
    label: "EF006880: Number of output ranges -60 mV ... +60 mV"
  },
  {
    featureId: "EF006882",
    featureDesc: "Number of integrated digital outputs",
    label: "EF006882: Number of integrated digital outputs"
  },
  {
    featureId: "EF006883",
    featureDesc: "Number of integrated digital inputs",
    label: "EF006883: Number of integrated digital inputs"
  },
  {
    featureId: "EF006884",
    featureDesc: "Number of displays",
    label: "EF006884: Number of displays"
  },
  {
    featureId: "EF006885",
    featureDesc: "Number of channels per connector slot",
    label: "EF006885: Number of channels per connector slot"
  },
  {
    featureId: "EF006886",
    featureDesc: "Number of inputs according to NAMUR",
    label: "EF006886: Number of inputs according to NAMUR"
  },
  {
    featureId: "EF006887",
    featureDesc: "Number of inputs, potential-free",
    label: "EF006887: Number of inputs, potential-free"
  },
  {
    featureId: "EF006888",
    featureDesc: "Number of input ranges +10 V ... -10 V",
    label: "EF006888: Number of input ranges +10 V ... -10 V"
  },
  {
    featureId: "EF006889",
    featureDesc: "Number of input ranges +5 V ... -5 V",
    label: "EF006889: Number of input ranges +5 V ... -5 V"
  },
  {
    featureId: "EF006890",
    featureDesc: "Number of input ranges 0 V ... 1 V",
    label: "EF006890: Number of input ranges 0 V ... 1 V"
  },
  {
    featureId: "EF006891",
    featureDesc: "Number of input ranges 0 mA ... 10 mA",
    label: "EF006891: Number of input ranges 0 mA ... 10 mA"
  },
  {
    featureId: "EF006892",
    featureDesc: "Number of input ranges 0 V ... 10 V",
    label: "EF006892: Number of input ranges 0 V ... 10 V"
  },
  {
    featureId: "EF006893",
    featureDesc: "Number of input ranges 0 mV ... 100 mV",
    label: "EF006893: Number of input ranges 0 mV ... 100 mV"
  },
  {
    featureId: "EF006894",
    featureDesc: "Number of input ranges 0 V ... 2 V",
    label: "EF006894: Number of input ranges 0 V ... 2 V"
  },
  {
    featureId: "EF006895",
    featureDesc: "Number of input ranges 0 V ... 2.5 V",
    label: "EF006895: Number of input ranges 0 V ... 2.5 V"
  },
  {
    featureId: "EF006896",
    featureDesc: "Number of input ranges 0 mA ... 20 mA",
    label: "EF006896: Number of input ranges 0 mA ... 20 mA"
  },
  {
    featureId: "EF006897",
    featureDesc: "Number of input ranges 0 V ... 20 V",
    label: "EF006897: Number of input ranges 0 V ... 20 V"
  },
  {
    featureId: "EF006898",
    featureDesc: "Number of input ranges 0 mV ... 200 mV",
    label: "EF006898: Number of input ranges 0 mV ... 200 mV"
  },
  {
    featureId: "EF006899",
    featureDesc: "Number of input ranges 0 V ... 24 V",
    label: "EF006899: Number of input ranges 0 V ... 24 V"
  },
  {
    featureId: "EF006900",
    featureDesc: "Number of input ranges 0 V ... 30 V",
    label: "EF006900: Number of input ranges 0 V ... 30 V"
  },
  {
    featureId: "EF006901",
    featureDesc: "Number of input ranges 0 mV ... 300 mV",
    label: "EF006901: Number of input ranges 0 mV ... 300 mV"
  },
  {
    featureId: "EF006902",
    featureDesc: "Number of input ranges 0 mA ... 5 mA",
    label: "EF006902: Number of input ranges 0 mA ... 5 mA"
  },
  {
    featureId: "EF006903",
    featureDesc: "Number of input ranges 0 V ... 5 V",
    label: "EF006903: Number of input ranges 0 V ... 5 V"
  },
  {
    featureId: "EF006904",
    featureDesc: "Number of input ranges 0 mV ... 500 mV",
    label: "EF006904: Number of input ranges 0 mV ... 500 mV"
  },
  {
    featureId: "EF006905",
    featureDesc: "Number of input ranges 0 mV ... 60 mV",
    label: "EF006905: Number of input ranges 0 mV ... 60 mV"
  },
  {
    featureId: "EF006906",
    featureDesc: "Number of input ranges -1 V ... +1 V",
    label: "EF006906: Number of input ranges -1 V ... +1 V"
  },
  {
    featureId: "EF006907",
    featureDesc: "Number of input ranges 1 V ... 5 V",
    label: "EF006907: Number of input ranges 1 V ... 5 V"
  },
  {
    featureId: "EF006908",
    featureDesc: "Number of input ranges -10 mA ... +10 mA",
    label: "EF006908: Number of input ranges -10 mA ... +10 mA"
  },
  {
    featureId: "EF006909",
    featureDesc: "Number of input ranges -10 V ... +10 V",
    label: "EF006909: Number of input ranges -10 V ... +10 V"
  },
  {
    featureId: "EF006910",
    featureDesc: "Number of input ranges 10 V ... 0 V",
    label: "EF006910: Number of input ranges 10 V ... 0 V"
  },
  {
    featureId: "EF006911",
    featureDesc: "Number of input ranges 10 V ... 2 V",
    label: "EF006911: Number of input ranges 10 V ... 2 V"
  },
  {
    featureId: "EF006912",
    featureDesc: "Number of input ranges -100 mV ... +100 mV",
    label: "EF006912: Number of input ranges -100 mV ... +100 mV"
  },
  {
    featureId: "EF006913",
    featureDesc: "Number of input ranges -2 V ... +2 V",
    label: "EF006913: Number of input ranges -2 V ... +2 V"
  },
  {
    featureId: "EF006914",
    featureDesc: "Number of input ranges 2 V ... 10 V",
    label: "EF006914: Number of input ranges 2 V ... 10 V"
  },
  {
    featureId: "EF006915",
    featureDesc: "Number of input ranges -2.5 V ... +2.5 V",
    label: "EF006915: Number of input ranges -2.5 V ... +2.5 V"
  },
  {
    featureId: "EF006916",
    featureDesc: "Number of input ranges -20 mA ... +20 mA",
    label: "EF006916: Number of input ranges -20 mA ... +20 mA"
  },
  {
    featureId: "EF006917",
    featureDesc: "Number of input ranges -20 V ... +20 V",
    label: "EF006917: Number of input ranges -20 V ... +20 V"
  },
  {
    featureId: "EF006918",
    featureDesc: "Number of input ranges 20 mA ... 0 mA",
    label: "EF006918: Number of input ranges 20 mA ... 0 mA"
  },
  {
    featureId: "EF006919",
    featureDesc: "Number of input ranges 20 mA ... 4 mA",
    label: "EF006919: Number of input ranges 20 mA ... 4 mA"
  },
  {
    featureId: "EF006920",
    featureDesc: "Number of input ranges -300 mV ... +300 mV",
    label: "EF006920: Number of input ranges -300 mV ... +300 mV"
  },
  {
    featureId: "EF006921",
    featureDesc: "Number of input ranges 4 mA ... 20 mA",
    label: "EF006921: Number of input ranges 4 mA ... 20 mA"
  },
  {
    featureId: "EF006922",
    featureDesc: "Number of input ranges -5 mA ... +5 mA",
    label: "EF006922: Number of input ranges -5 mA ... +5 mA"
  },
  {
    featureId: "EF006923",
    featureDesc: "Number of input ranges -5 V ... +5 V",
    label: "EF006923: Number of input ranges -5 V ... +5 V"
  },
  {
    featureId: "EF006924",
    featureDesc: "Number of input ranges 5 V ... 0 V",
    label: "EF006924: Number of input ranges 5 V ... 0 V"
  },
  {
    featureId: "EF006925",
    featureDesc: "Number of input ranges 5 V ... 1 V",
    label: "EF006925: Number of input ranges 5 V ... 1 V"
  },
  {
    featureId: "EF006926",
    featureDesc: "Number of input ranges -500 mV ... +500 mV",
    label: "EF006926: Number of input ranges -500 mV ... +500 mV"
  },
  {
    featureId: "EF006927",
    featureDesc: "Number of input ranges -60 mV ... +60 mV",
    label: "EF006927: Number of input ranges -60 mV ... +60 mV"
  },
  {
    featureId: "EF006929",
    featureDesc: "Number of electronic outputs",
    label: "EF006929: Number of electronic outputs"
  },
  {
    featureId: "EF006931",
    featureDesc: "Number of fixed inputs",
    label: "EF006931: Number of fixed inputs"
  },
  {
    featureId: "EF006932",
    featureDesc: "Number of interfaces PROFINET",
    label: "EF006932: Number of interfaces PROFINET"
  },
  {
    featureId: "EF006933",
    featureDesc: "Number of HW-interfaces wireless",
    label: "EF006933: Number of HW-interfaces wireless"
  },
  {
    featureId: "EF006934",
    featureDesc: "Number of plug-in contact inserts",
    label: "EF006934: Number of plug-in contact inserts"
  },
  {
    featureId: "EF006935",
    featureDesc: "Number of plug-in contact rows",
    label: "EF006935: Number of plug-in contact rows"
  },
  {
    featureId: "EF006936",
    featureDesc: "Number of outputs, with contact",
    label: "EF006936: Number of outputs, with contact"
  },
  {
    featureId: "EF006937",
    featureDesc: "Number of switching points",
    label: "EF006937: Number of switching points"
  },
  {
    featureId: "EF006938",
    featureDesc: "Number of steps per revolution",
    label: "EF006938: Number of steps per revolution"
  },
  {
    featureId: "EF006939",
    featureDesc: "Number of sensor-/actuator-slots",
    label: "EF006939: Number of sensor-/actuator-slots"
  },
  {
    featureId: "EF006940",
    featureDesc: "Number of safety auxiliary contacts",
    label: "EF006940: Number of safety auxiliary contacts"
  },
  {
    featureId: "EF006941",
    featureDesc: "Number of status indications",
    label: "EF006941: Number of status indications"
  },
  {
    featureId: "EF006943",
    featureDesc: "Number of revolutions",
    label: "EF006943: Number of revolutions"
  },
  {
    featureId: "EF006945",
    featureDesc: "Number of selectable inputs",
    label: "EF006945: Number of selectable inputs"
  },
  {
    featureId: "EF006946",
    featureDesc: "Number of limit values",
    label: "EF006946: Number of limit values"
  },
  {
    featureId: "EF006947",
    featureDesc: "Number of primary inputs",
    label: "EF006947: Number of primary inputs"
  },
  {
    featureId: "EF006949",
    featureDesc: "Type of actuation",
    label: "EF006949: Type of actuation"
  },
  {
    featureId: "EF006950",
    featureDesc: "Type of process connection",
    label: "EF006950: Type of process connection"
  },
  {
    featureId: "EF006952",
    featureDesc: "Pluggable on auxiliary contact block",
    label: "EF006952: Pluggable on auxiliary contact block"
  },
  {
    featureId: "EF006954",
    featureDesc: "Version as built-in device",
    label: "EF006954: Version as built-in device"
  },
  {
    featureId: "EF006959",
    featureDesc: "Version as emergency stop installation",
    label: "EF006959: Version as emergency stop installation"
  },
  {
    featureId: "EF006965",
    featureDesc: "Version as busbar adapter",
    label: "EF006965: Version as busbar adapter"
  },
  {
    featureId: "EF006966",
    featureDesc: "Version as glazed door",
    label: "EF006966: Version as glazed door"
  },
  {
    featureId: "EF006967",
    featureDesc: "Version as door for disconnecting switch interlocking",
    label: "EF006967: Version as door for disconnecting switch interlocking"
  },
  {
    featureId: "EF006968",
    featureDesc: "Type of shaft encoder",
    label: "EF006968: Type of shaft encoder"
  },
  {
    featureId: "EF006969",
    featureDesc: "Type of switch function of the inputs",
    label: "EF006969: Type of switch function of the inputs"
  },
  {
    featureId: "EF006972",
    featureDesc: "Type of branches for sensor-/actuator cables",
    label: "EF006972: Type of branches for sensor-/actuator cables"
  },
  {
    featureId: "EF006974",
    featureDesc: "Type of temperature control",
    label: "EF006974: Type of temperature control"
  },
  {
    featureId: "EF006976",
    featureDesc: "Type of control element",
    label: "EF006976: Type of control element"
  },
  {
    featureId: "EF006977",
    featureDesc: "Type of interface",
    label: "EF006977: Type of interface"
  },
  {
    featureId: "EF006978",
    featureDesc: "Type of converter",
    label: "EF006978: Type of converter"
  },
  {
    featureId: "EF006979",
    featureDesc: "Type of electrical connection for auxiliary- and control current circuit",
    label: "EF006979: Type of electrical connection for auxiliary- and control current circuit"
  },
  {
    featureId: "EF006980",
    featureDesc: "Type of housing",
    label: "EF006980: Type of housing"
  },
  {
    featureId: "EF006981",
    featureDesc: "Version as main switch",
    label: "EF006981: Version as main switch"
  },
  {
    featureId: "EF006982",
    featureDesc: "Type of coding element",
    label: "EF006982: Type of coding element"
  },
  {
    featureId: "EF006985",
    featureDesc: "Version as safety switch",
    label: "EF006985: Version as safety switch"
  },
  {
    featureId: "EF006986",
    featureDesc: "Version as maintenance-/service switch",
    label: "EF006986: Version as maintenance-/service switch"
  },
  {
    featureId: "EF006987",
    featureDesc: "Output electronic",
    label: "EF006987: Output electronic"
  },
  {
    featureId: "EF006988",
    featureDesc: "Outputs, reversible delayed/undelayed",
    label: "EF006988: Outputs, reversible delayed/undelayed"
  },
  {
    featureId: "EF006991",
    featureDesc: "Number of output circuits opto-coupler",
    label: "EF006991: Number of output circuits opto-coupler"
  },
  {
    featureId: "EF006992",
    featureDesc: "Number of output circuits relays normally open contact",
    label: "EF006992: Number of output circuits relays normally open contact"
  },
  {
    featureId: "EF006993",
    featureDesc: "Number of output circuits relays change-over contact",
    label: "EF006993: Number of output circuits relays change-over contact"
  },
  {
    featureId: "EF006994",
    featureDesc: "Number of output circuits transistor antivalent",
    label: "EF006994: Number of output circuits transistor antivalent"
  },
  {
    featureId: "EF006995",
    featureDesc: "Number of output circuits transistor npn",
    label: "EF006995: Number of output circuits transistor npn"
  },
  {
    featureId: "EF006996",
    featureDesc: "Number of output circuits transistor pnp",
    label: "EF006996: Number of output circuits transistor pnp"
  },
  {
    featureId: "EF006997",
    featureDesc: "Number of output circuits transistor potential-free",
    label: "EF006997: Number of output circuits transistor potential-free"
  },
  {
    featureId: "EF006998",
    featureDesc: "Number of phases output",
    label: "EF006998: Number of phases output"
  },
  {
    featureId: "EF006999",
    featureDesc: "Output voltage AC 50 Hz",
    label: "EF006999: Output voltage AC 50 Hz"
  },
  {
    featureId: "EF007000",
    featureDesc: "Output voltage AC 60 Hz",
    label: "EF007000: Output voltage AC 60 Hz"
  },
  {
    featureId: "EF007001",
    featureDesc: "Output voltage DC",
    label: "EF007001: Output voltage DC"
  },
  {
    featureId: "EF007002",
    featureDesc: "Secondary voltage adjustable",
    label: "EF007002: Secondary voltage adjustable"
  },
  {
    featureId: "EF007005",
    featureDesc: "Built as energy saving transformer",
    label: "EF007005: Built as energy saving transformer"
  },
  {
    featureId: "EF007006",
    featureDesc: "With switched-off indicator",
    label: "EF007006: With switched-off indicator"
  },
  {
    featureId: "EF007007",
    featureDesc: "Switch off technique",
    label: "EF007007: Switch off technique"
  },
  {
    featureId: "EF007008",
    featureDesc: "Switch off pressure",
    label: "EF007008: Switch off pressure"
  },
  {
    featureId: "EF007009",
    featureDesc: "Adjustable switch off temperature",
    label: "EF007009: Adjustable switch off temperature"
  },
  {
    featureId: "EF007010",
    featureDesc: "AWG-number",
    label: "EF007010: AWG-number"
  },
  {
    featureId: "EF007011",
    featureDesc: "Type of banding",
    label: "EF007011: Type of banding"
  },
  {
    featureId: "EF007016",
    featureDesc: "Front built-in possible",
    label: "EF007016: Front built-in possible"
  },
  {
    featureId: "EF007017",
    featureDesc: "Mounting type, gallows mounting",
    label: "EF007017: Mounting type, gallows mounting"
  },
  {
    featureId: "EF007018",
    featureDesc: "Rack-assembly possible",
    label: "EF007018: Rack-assembly possible"
  },
  {
    featureId: "EF007019",
    featureDesc: "Mounting type, table foot",
    label: "EF007019: Mounting type, table foot"
  },
  {
    featureId: "EF007020",
    featureDesc: "Wall mounting/direct mounting",
    label: "EF007020: Wall mounting/direct mounting"
  },
  {
    featureId: "EF007024",
    featureDesc: "Rated operating frequency",
    label: "EF007024: Rated operating frequency"
  },
  {
    featureId: "EF007025",
    featureDesc: "Rated operation power at AC-3, 230 V",
    label: "EF007025: Rated operation power at AC-3, 230 V"
  },
  {
    featureId: "EF007026",
    featureDesc: "Rated operation power at DC-3 / DC-5 at 440 V",
    label: "EF007026: Rated operation power at DC-3 / DC-5 at 440 V"
  },
  {
    featureId: "EF007027",
    featureDesc: "Rated operation power at AC-23, 400 V",
    label: "EF007027: Rated operation power at AC-23, 400 V"
  },
  {
    featureId: "EF007029",
    featureDesc: "Rated voltage Ue at AC 50 Hz",
    label: "EF007029: Rated voltage Ue at AC 50 Hz"
  },
  {
    featureId: "EF007030",
    featureDesc: "Rated voltage Ue at AC 60 Hz",
    label: "EF007030: Rated voltage Ue at AC 60 Hz"
  },
  {
    featureId: "EF007031",
    featureDesc: "Rated voltage Ue at DC",
    label: "EF007031: Rated voltage Ue at DC"
  },
  {
    featureId: "EF007033",
    featureDesc: "Rated operation current Ie at AC-15, 600 V",
    label: "EF007033: Rated operation current Ie at AC-15, 600 V"
  },
  {
    featureId: "EF007035",
    featureDesc: "Rated operation current Ie at DC-3 / DC-5 at 440 V",
    label: "EF007035: Rated operation current Ie at DC-3 / DC-5 at 440 V"
  },
  {
    featureId: "EF007039",
    featureDesc: "Rated operation current Ie at 40 °C Tu",
    label: "EF007039: Rated operation current Ie at 40 °C Tu"
  },
  {
    featureId: "EF007040",
    featureDesc: "Rated operation current Ie at AC12, 125 V",
    label: "EF007040: Rated operation current Ie at AC12, 125 V"
  },
  {
    featureId: "EF007041",
    featureDesc: "Rated operation current Ie at AC12, 220 V",
    label: "EF007041: Rated operation current Ie at AC12, 220 V"
  },
  {
    featureId: "EF007042",
    featureDesc: "Rated operation current Ie at AC12, 24 V",
    label: "EF007042: Rated operation current Ie at AC12, 24 V"
  },
  {
    featureId: "EF007043",
    featureDesc: "Rated operation current Ie at DC-12, 24 V",
    label: "EF007043: Rated operation current Ie at DC-12, 24 V"
  },
  {
    featureId: "EF007044",
    featureDesc: "Rated blind power at 400 V, 50 Hz",
    label: "EF007044: Rated blind power at 400 V, 50 Hz"
  },
  {
    featureId: "EF007048",
    featureDesc: "Rated short-circuit breaking capacity lcu at 400 V, 50 Hz",
    label: "EF007048: Rated short-circuit breaking capacity lcu at 400 V, 50 Hz"
  },
  {
    featureId: "EF007049",
    featureDesc: "Rated short-circuit breaking capacity lcu at 400 V, AC",
    label: "EF007049: Rated short-circuit breaking capacity lcu at 400 V, AC"
  },
  {
    featureId: "EF007050",
    featureDesc: "Rated short-time withstand current lcw",
    label: "EF007050: Rated short-time withstand current lcw"
  },
  {
    featureId: "EF007051",
    featureDesc: "Rated power",
    label: "EF007051: Rated power"
  },
  {
    featureId: "EF007053",
    featureDesc: "Rated power three-phase motor, inline, at 230 V",
    label: "EF007053: Rated power three-phase motor, inline, at 230 V"
  },
  {
    featureId: "EF007054",
    featureDesc: "Rated power three-phase motor, inline, at 400 V",
    label: "EF007054: Rated power three-phase motor, inline, at 400 V"
  },
  {
    featureId: "EF007055",
    featureDesc: "Rated power three-phase motor, inside delta, at 230 V",
    label: "EF007055: Rated power three-phase motor, inside delta, at 230 V"
  },
  {
    featureId: "EF007056",
    featureDesc: "Rated power three-phase motor, inside delta, at 400 V",
    label: "EF007056: Rated power three-phase motor, inside delta, at 400 V"
  },
  {
    featureId: "EF007065",
    featureDesc: "Rated voltage AC 50 Hz",
    label: "EF007065: Rated voltage AC 50 Hz"
  },
  {
    featureId: "EF007066",
    featureDesc: "Rated voltage AC 60 Hz",
    label: "EF007066: Rated voltage AC 60 Hz"
  },
  {
    featureId: "EF007067",
    featureDesc: "Rated voltage DC",
    label: "EF007067: Rated voltage DC"
  },
  {
    featureId: "EF007068",
    featureDesc: "Rated control supply voltage magnet drive AC 50 Hz",
    label: "EF007068: Rated control supply voltage magnet drive AC 50 Hz"
  },
  {
    featureId: "EF007069",
    featureDesc: "Rated control supply voltage magnet drive AC 60 Hz",
    label: "EF007069: Rated control supply voltage magnet drive AC 60 Hz"
  },
  {
    featureId: "EF007070",
    featureDesc: "Rated control supply voltage magnet drive DC",
    label: "EF007070: Rated control supply voltage magnet drive DC"
  },
  {
    featureId: "EF007074",
    featureDesc: "Rated surrounding temperature without derating",
    label: "EF007074: Rated surrounding temperature without derating"
  },
  {
    featureId: "EF007075",
    featureDesc: "Rated supply voltage AC",
    label: "EF007075: Rated supply voltage AC"
  },
  {
    featureId: "EF007076",
    featureDesc: "Rated supply voltage AC 50 Hz",
    label: "EF007076: Rated supply voltage AC 50 Hz"
  },
  {
    featureId: "EF007077",
    featureDesc: "Rated supply voltage AC 60 Hz",
    label: "EF007077: Rated supply voltage AC 60 Hz"
  },
  {
    featureId: "EF007078",
    featureDesc: "Rated supply voltage DC",
    label: "EF007078: Rated supply voltage DC"
  },
  {
    featureId: "EF007080",
    featureDesc: "Bursting pressure",
    label: "EF007080: Bursting pressure"
  },
  {
    featureId: "EF007082",
    featureDesc: "Rated operation current Ie at AC-15, 125 V",
    label: "EF007082: Rated operation current Ie at AC-15, 125 V"
  },
  {
    featureId: "EF007084",
    featureDesc: "Rated operation current Ie at AC-15, 24 V",
    label: "EF007084: Rated operation current Ie at AC-15, 24 V"
  },
  {
    featureId: "EF007085",
    featureDesc: "Rated operation current Ie at DC-13, 125 V",
    label: "EF007085: Rated operation current Ie at DC-13, 125 V"
  },
  {
    featureId: "EF007087",
    featureDesc: "Rated operation current Ie at DC-13, 24 V",
    label: "EF007087: Rated operation current Ie at DC-13, 24 V"
  },
  {
    featureId: "EF007090",
    featureDesc: "Suitable for operating system Windows 2003 Server",
    label: "EF007090: Suitable for operating system Windows 2003 Server"
  },
  {
    featureId: "EF007091",
    featureDesc: "Suitable for operating system Windows Vista",
    label: "EF007091: Suitable for operating system Windows Vista"
  },
  {
    featureId: "EF007092",
    featureDesc: "Width clamp",
    label: "EF007092: Width clamp"
  },
  {
    featureId: "EF007094",
    featureDesc: "Width sensor",
    label: "EF007094: Width sensor"
  },
  {
    featureId: "EF007095",
    featureDesc: "Width amplifier",
    label: "EF007095: Width amplifier"
  },
  {
    featureId: "EF007096",
    featureDesc: "Load",
    label: "EF007096: Load"
  },
  {
    featureId: "EF007097",
    featureDesc: "Load influence",
    label: "EF007097: Load influence"
  },
  {
    featureId: "EF007099",
    featureDesc: "Roof ventilator",
    label: "EF007099: Roof ventilator"
  },
  {
    featureId: "EF007103",
    featureDesc: "Double interrupting",
    label: "EF007103: Double interrupting"
  },
  {
    featureId: "EF007104",
    featureDesc: "Wire break- or measuring circuit monitoring",
    label: "EF007104: Wire break- or measuring circuit monitoring"
  },
  {
    featureId: "EF007105",
    featureDesc: "Pressure",
    label: "EF007105: Pressure"
  },
  {
    featureId: "EF007106",
    featureDesc: "Compression-resistant",
    label: "EF007106: Compression-resistant"
  },
  {
    featureId: "EF007107",
    featureDesc: "Compressive strength measuring head",
    label: "EF007107: Compressive strength measuring head"
  },
  {
    featureId: "EF007108",
    featureDesc: "Type of pressure connection",
    label: "EF007108: Type of pressure connection"
  },
  {
    featureId: "EF007110",
    featureDesc: "Diameter axle",
    label: "EF007110: Diameter axle"
  },
  {
    featureId: "EF007111",
    featureDesc: "Diameter sensor",
    label: "EF007111: Diameter sensor"
  },
  {
    featureId: "EF007112",
    featureDesc: "Inherently safe according to EN 60947-5-6 NAMUR",
    label: "EF007112: Inherently safe according to EN 60947-5-6 NAMUR"
  },
  {
    featureId: "EF007114",
    featureDesc: "Input for analogue temperature sensor",
    label: "EF007114: Input for analogue temperature sensor"
  },
  {
    featureId: "EF007115",
    featureDesc: "Input for earth fault detection",
    label: "EF007115: Input for earth fault detection"
  },
  {
    featureId: "EF007116",
    featureDesc: "Input for thermistor connection",
    label: "EF007116: Input for thermistor connection"
  },
  {
    featureId: "EF007117",
    featureDesc: "Number of phases input",
    label: "EF007117: Number of phases input"
  },
  {
    featureId: "EF007118",
    featureDesc: "Input voltage AC 50 Hz",
    label: "EF007118: Input voltage AC 50 Hz"
  },
  {
    featureId: "EF007119",
    featureDesc: "Input voltage AC 60 Hz",
    label: "EF007119: Input voltage AC 60 Hz"
  },
  {
    featureId: "EF007120",
    featureDesc: "Input voltage DC",
    label: "EF007120: Input voltage DC"
  },
  {
    featureId: "EF007121",
    featureDesc: "Primary voltage AC",
    label: "EF007121: Primary voltage AC"
  },
  {
    featureId: "EF007122",
    featureDesc: "Primary voltage tolerance",
    label: "EF007122: Primary voltage tolerance"
  },
  {
    featureId: "EF007123",
    featureDesc: "Scope of application",
    label: "EF007123: Scope of application"
  },
  {
    featureId: "EF007124",
    featureDesc: "Engaging pressure",
    label: "EF007124: Engaging pressure"
  },
  {
    featureId: "EF007125",
    featureDesc: "Adjustable switch on temperature",
    label: "EF007125: Adjustable switch on temperature"
  },
  {
    featureId: "EF007126",
    featureDesc: "Built as slide-in ventilator",
    label: "EF007126: Built as slide-in ventilator"
  },
  {
    featureId: "EF007127",
    featureDesc: "Adjustable setting value for flow for liquids",
    label: "EF007127: Adjustable setting value for flow for liquids"
  },
  {
    featureId: "EF007128",
    featureDesc: "Adjustable setting value for flow for gases",
    label: "EF007128: Adjustable setting value for flow for gases"
  },
  {
    featureId: "EF007129",
    featureDesc: "Adjustment range short-term delayed short-circuit release",
    label: "EF007129: Adjustment range short-term delayed short-circuit release"
  },
  {
    featureId: "EF007131",
    featureDesc: "Adjustment range undelayed short-circuit release",
    label: "EF007131: Adjustment range undelayed short-circuit release"
  },
  {
    featureId: "EF007132",
    featureDesc: "Electronic version",
    label: "EF007132: Electronic version"
  },
  {
    featureId: "EF007134",
    featureDesc: "End value measuring range pressure",
    label: "EF007134: End value measuring range pressure"
  },
  {
    featureId: "EF007135",
    featureDesc: "Energy type",
    label: "EF007135: Energy type"
  },
  {
    featureId: "EF007136",
    featureDesc: "Contains analogue output module",
    label: "EF007136: Contains analogue output module"
  },
  {
    featureId: "EF007137",
    featureDesc: "Contains analogue input module",
    label: "EF007137: Contains analogue input module"
  },
  {
    featureId: "EF007138",
    featureDesc: "Contains module rack",
    label: "EF007138: Contains module rack"
  },
  {
    featureId: "EF007139",
    featureDesc: "Contains control unit",
    label: "EF007139: Contains control unit"
  },
  {
    featureId: "EF007140",
    featureDesc: "Contains libraries",
    label: "EF007140: Contains libraries"
  },
  {
    featureId: "EF007141",
    featureDesc: "Contains digital output module",
    label: "EF007141: Contains digital output module"
  },
  {
    featureId: "EF007142",
    featureDesc: "Contains digital input module",
    label: "EF007142: Contains digital input module"
  },
  {
    featureId: "EF007143",
    featureDesc: "Contains documentation",
    label: "EF007143: Contains documentation"
  },
  {
    featureId: "EF007144",
    featureDesc: "Contains engineering software",
    label: "EF007144: Contains engineering software"
  },
  {
    featureId: "EF007145",
    featureDesc: "Contains function building blocks",
    label: "EF007145: Contains function building blocks"
  },
  {
    featureId: "EF007146",
    featureDesc: "Contains function module",
    label: "EF007146: Contains function module"
  },
  {
    featureId: "EF007147",
    featureDesc: "Contains basic device",
    label: "EF007147: Contains basic device"
  },
  {
    featureId: "EF007148",
    featureDesc: "Contains industry PC",
    label: "EF007148: Contains industry PC"
  },
  {
    featureId: "EF007149",
    featureDesc: "Contains communication module",
    label: "EF007149: Contains communication module"
  },
  {
    featureId: "EF007150",
    featureDesc: "Contains monitor",
    label: "EF007150: Contains monitor"
  },
  {
    featureId: "EF007151",
    featureDesc: "Contains panel-PC",
    label: "EF007151: Contains panel-PC"
  },
  {
    featureId: "EF007152",
    featureDesc: "Contains programming software",
    label: "EF007152: Contains programming software"
  },
  {
    featureId: "EF007153",
    featureDesc: "Contains simulation module",
    label: "EF007153: Contains simulation module"
  },
  {
    featureId: "EF007154",
    featureDesc: "Contains slot-PLC",
    label: "EF007154: Contains slot-PLC"
  },
  {
    featureId: "EF007155",
    featureDesc: "Contains soft-PLC",
    label: "EF007155: Contains soft-PLC"
  },
  {
    featureId: "EF007156",
    featureDesc: "Contains memory unit",
    label: "EF007156: Contains memory unit"
  },
  {
    featureId: "EF007157",
    featureDesc: "Contains power supply",
    label: "EF007157: Contains power supply"
  },
  {
    featureId: "EF007158",
    featureDesc: "Contains keypad",
    label: "EF007158: Contains keypad"
  },
  {
    featureId: "EF007159",
    featureDesc: "Contains technology module",
    label: "EF007159: Contains technology module"
  },
  {
    featureId: "EF007160",
    featureDesc: "Contains connection cable",
    label: "EF007160: Contains connection cable"
  },
  {
    featureId: "EF007161",
    featureDesc: "Contains visualization",
    label: "EF007161: Contains visualization"
  },
  {
    featureId: "EF007162",
    featureDesc: "Contains other components",
    label: "EF007162: Contains other components"
  },
  {
    featureId: "EF007163",
    featureDesc: "Expansion device",
    label: "EF007163: Expansion device"
  },
  {
    featureId: "EF007164",
    featureDesc: "ETSI-compliant",
    label: "EF007164: ETSI-compliant"
  },
  {
    featureId: "EF007165",
    featureDesc: "Colour indicator light cap",
    label: "EF007165: Colour indicator light cap"
  },
  {
    featureId: "EF007167",
    featureDesc: "Colour control element",
    label: "EF007167: Colour control element"
  },
  {
    featureId: "EF007168",
    featureDesc: "Colour body",
    label: "EF007168: Colour body"
  },
  {
    featureId: "EF007169",
    featureDesc: "Filter bank performance",
    label: "EF007169: Filter bank performance"
  },
  {
    featureId: "EF007172",
    featureDesc: "With escape release",
    label: "EF007172: With escape release"
  },
  {
    featureId: "EF007173",
    featureDesc: "Volume flow, freeblowing",
    label: "EF007173: Volume flow, freeblowing"
  },
  {
    featureId: "EF007175",
    featureDesc: "Type of frequency band",
    label: "EF007175: Type of frequency band"
  },
  {
    featureId: "EF007176",
    featureDesc: "Front shield size",
    label: "EF007176: Front shield size"
  },
  {
    featureId: "EF007178",
    featureDesc: "Radio standard Bluetooth",
    label: "EF007178: Radio standard Bluetooth"
  },
  {
    featureId: "EF007179",
    featureDesc: "Radio standard WLAN 802.11",
    label: "EF007179: Radio standard WLAN 802.11"
  },
  {
    featureId: "EF007180",
    featureDesc: "With automatic feeding",
    label: "EF007180: With automatic feeding"
  },
  {
    featureId: "EF007181",
    featureDesc: "Function contactless working protection installation",
    label: "EF007181: Function contactless working protection installation"
  },
  {
    featureId: "EF007182",
    featureDesc: "Function operating mode selection",
    label: "EF007182: Function operating mode selection"
  },
  {
    featureId: "EF007183",
    featureDesc: "Function master/slave",
    label: "EF007183: Function master/slave"
  },
  {
    featureId: "EF007184",
    featureDesc: "Function emergency stop",
    label: "EF007184: Function emergency stop"
  },
  {
    featureId: "EF007185",
    featureDesc: "Function contacter monitoring",
    label: "EF007185: Function contacter monitoring"
  },
  {
    featureId: "EF007186",
    featureDesc: "With function restart blockage",
    label: "EF007186: With function restart blockage"
  },
  {
    featureId: "EF007187",
    featureDesc: "Function access control",
    label: "EF007187: Function access control"
  },
  {
    featureId: "EF007188",
    featureDesc: "Function affirm pushbutton",
    label: "EF007188: Function affirm pushbutton"
  },
  {
    featureId: "EF007189",
    featureDesc: "Function 2-hand switching",
    label: "EF007189: Function 2-hand switching"
  },
  {
    featureId: "EF007190",
    featureDesc: "Contains function modules for analogue signals",
    label: "EF007190: Contains function modules for analogue signals"
  },
  {
    featureId: "EF007191",
    featureDesc: "Contains function modules for drives",
    label: "EF007191: Contains function modules for drives"
  },
  {
    featureId: "EF007192",
    featureDesc: "Contains function modules for bus handling",
    label: "EF007192: Contains function modules for bus handling"
  },
  {
    featureId: "EF007193",
    featureDesc: "Contains function modules for diagnostics",
    label: "EF007193: Contains function modules for diagnostics"
  },
  {
    featureId: "EF007194",
    featureDesc: "Contains function modules for documentation",
    label: "EF007194: Contains function modules for documentation"
  },
  {
    featureId: "EF007195",
    featureDesc: "Contains function modules for FUZZY-Control",
    label: "EF007195: Contains function modules for FUZZY-Control"
  },
  {
    featureId: "EF007196",
    featureDesc: "Contains function modules for communication",
    label: "EF007196: Contains function modules for communication"
  },
  {
    featureId: "EF007197",
    featureDesc: "Contains function modules for Motion Control",
    label: "EF007197: Contains function modules for Motion Control"
  },
  {
    featureId: "EF007198",
    featureDesc: "Contains function modules for positioning",
    label: "EF007198: Contains function modules for positioning"
  },
  {
    featureId: "EF007199",
    featureDesc: "Contains function modules for redundancy",
    label: "EF007199: Contains function modules for redundancy"
  },
  {
    featureId: "EF007200",
    featureDesc: "Contains function modules for control",
    label: "EF007200: Contains function modules for control"
  },
  {
    featureId: "EF007201",
    featureDesc: "Contains function modules for simulation",
    label: "EF007201: Contains function modules for simulation"
  },
  {
    featureId: "EF007202",
    featureDesc: "Contains function modules for other applications",
    label: "EF007202: Contains function modules for other applications"
  },
  {
    featureId: "EF007203",
    featureDesc: "Contains function modules for teleservice",
    label: "EF007203: Contains function modules for teleservice"
  },
  {
    featureId: "EF007204",
    featureDesc: "Contains function modules for visualization",
    label: "EF007204: Contains function modules for visualization"
  },
  {
    featureId: "EF007205",
    featureDesc: "Contains function modules for electronic positioning",
    label: "EF007205: Contains function modules for electronic positioning"
  },
  {
    featureId: "EF007206",
    featureDesc: "Contains function modules for counting",
    label: "EF007206: Contains function modules for counting"
  },
  {
    featureId: "EF007207",
    featureDesc: "Contains function modules for status graph",
    label: "EF007207: Contains function modules for status graph"
  },
  {
    featureId: "EF007208",
    featureDesc: "Function block automatic reset",
    label: "EF007208: Function block automatic reset"
  },
  {
    featureId: "EF007209",
    featureDesc: "Function block contactless working protection installation",
    label: "EF007209: Function block contactless working protection installation"
  },
  {
    featureId: "EF007210",
    featureDesc: "Function block operating mode selection",
    label: "EF007210: Function block operating mode selection"
  },
  {
    featureId: "EF007211",
    featureDesc: "Function block emergency stop",
    label: "EF007211: Function block emergency stop"
  },
  {
    featureId: "EF007212",
    featureDesc: "Function block restart blockage",
    label: "EF007212: Function block restart blockage"
  },
  {
    featureId: "EF007213",
    featureDesc: "Function block access control",
    label: "EF007213: Function block access control"
  },
  {
    featureId: "EF007214",
    featureDesc: "Function block affirm pushbutton",
    label: "EF007214: Function block affirm pushbutton"
  },
  {
    featureId: "EF007215",
    featureDesc: "Function block 2-hand switching",
    label: "EF007215: Function block 2-hand switching"
  },
  {
    featureId: "EF007217",
    featureDesc: "Suitable for devices with auxiliary switch",
    label: "EF007217: Suitable for devices with auxiliary switch"
  },
  {
    featureId: "EF007218",
    featureDesc: "Suitable for devices with N-conductor",
    label: "EF007218: Suitable for devices with N-conductor"
  },
  {
    featureId: "EF007219",
    featureDesc: "Busbar width",
    label: "EF007219: Busbar width"
  },
  {
    featureId: "EF007220",
    featureDesc: "Busbar thickness",
    label: "EF007220: Busbar thickness"
  },
  {
    featureId: "EF007222",
    featureDesc: "Galvanic separation between output to output",
    label: "EF007222: Galvanic separation between output to output"
  },
  {
    featureId: "EF007223",
    featureDesc: "Galvanic separation between input and output",
    label: "EF007223: Galvanic separation between input and output"
  },
  {
    featureId: "EF007224",
    featureDesc: "Galvanic separation between inputs",
    label: "EF007224: Galvanic separation between inputs"
  },
  {
    featureId: "EF007225",
    featureDesc: "Galvanic separation between supply voltage and all other current circuits",
    label: "EF007225: Galvanic separation between supply voltage and all other current circuits"
  },
  {
    featureId: "EF007226",
    featureDesc: "Suitable as 2-point controller",
    label: "EF007226: Suitable as 2-point controller"
  },
  {
    featureId: "EF007227",
    featureDesc: "Suitable as output reactance coil",
    label: "EF007227: Suitable as output reactance coil"
  },
  {
    featureId: "EF007228",
    featureDesc: "Suitable as limiter",
    label: "EF007228: Suitable as limiter"
  },
  {
    featureId: "EF007229",
    featureDesc: "Suitable as du/dt-filter",
    label: "EF007229: Suitable as du/dt-filter"
  },
  {
    featureId: "EF007230",
    featureDesc: "Suitable as interference suppression reactance coil",
    label: "EF007230: Suitable as interference suppression reactance coil"
  },
  {
    featureId: "EF007231",
    featureDesc: "Suitable as radio interference suppression filter",
    label: "EF007231: Suitable as radio interference suppression filter"
  },
  {
    featureId: "EF007232",
    featureDesc: "Suitable as ripple filter choke",
    label: "EF007232: Suitable as ripple filter choke"
  },
  {
    featureId: "EF007233",
    featureDesc: "Suitable as commutation reactance coil",
    label: "EF007233: Suitable as commutation reactance coil"
  },
  {
    featureId: "EF007234",
    featureDesc: "Suitable as net reactance coil",
    label: "EF007234: Suitable as net reactance coil"
  },
  {
    featureId: "EF007235",
    featureDesc: "Built as safety transformer",
    label: "EF007235: Built as safety transformer"
  },
  {
    featureId: "EF007236",
    featureDesc: "Suitable as sinus filter",
    label: "EF007236: Suitable as sinus filter"
  },
  {
    featureId: "EF007237",
    featureDesc: "Suitable as audio frequency blockage",
    label: "EF007237: Suitable as audio frequency blockage"
  },
  {
    featureId: "EF007238",
    featureDesc: "Built as isolating transformer",
    label: "EF007238: Built as isolating transformer"
  },
  {
    featureId: "EF007239",
    featureDesc: "Suitable as guard",
    label: "EF007239: Suitable as guard"
  },
  {
    featureId: "EF007241",
    featureDesc: "Suitable for number of devices",
    label: "EF007241: Suitable for number of devices"
  },
  {
    featureId: "EF007242",
    featureDesc: "Suitable for strip conductor connection",
    label: "EF007242: Suitable for strip conductor connection"
  },
  {
    featureId: "EF007243",
    featureDesc: "Suitable for floor mounting",
    label: "EF007243: Suitable for floor mounting"
  },
  {
    featureId: "EF007244",
    featureDesc: "Suitable for push button",
    label: "EF007244: Suitable for push button"
  },
  {
    featureId: "EF007245",
    featureDesc: "Suitable for liquids",
    label: "EF007245: Suitable for liquids"
  },
  {
    featureId: "EF007246",
    featureDesc: "Suitable for front mounting",
    label: "EF007246: Suitable for front mounting"
  },
  {
    featureId: "EF007247",
    featureDesc: "Suitable for front mounting 4-hole",
    label: "EF007247: Suitable for front mounting 4-hole"
  },
  {
    featureId: "EF007248",
    featureDesc: "Suitable for front mounting centre",
    label: "EF007248: Suitable for front mounting centre"
  },
  {
    featureId: "EF007249",
    featureDesc: "Suitable for gases",
    label: "EF007249: Suitable for gases"
  },
  {
    featureId: "EF007250",
    featureDesc: "Suitable for DIN rail (top hat rail) mounting",
    label: "EF007250: Suitable for DIN rail (top hat rail) mounting"
  },
  {
    featureId: "EF007251",
    featureDesc: "Suitable for cable patch",
    label: "EF007251: Suitable for cable patch"
  },
  {
    featureId: "EF007252",
    featureDesc: "Suitable for switch disconnector",
    label: "EF007252: Suitable for switch disconnector"
  },
  {
    featureId: "EF007253",
    featureDesc: "Suitable for PCB thickness",
    label: "EF007253: Suitable for PCB thickness"
  },
  {
    featureId: "EF007254",
    featureDesc: "Suitable for illuminated push buttons",
    label: "EF007254: Suitable for illuminated push buttons"
  },
  {
    featureId: "EF007255",
    featureDesc: "Suitable for indicator light",
    label: "EF007255: Suitable for indicator light"
  },
  {
    featureId: "EF007257",
    featureDesc: "Suitable for mushroom head push button",
    label: "EF007257: Suitable for mushroom head push button"
  },
  {
    featureId: "EF007258",
    featureDesc: "Suitable for round conductor connection",
    label: "EF007258: Suitable for round conductor connection"
  },
  {
    featureId: "EF007259",
    featureDesc: "Suitable for busbar mounting",
    label: "EF007259: Suitable for busbar mounting"
  },
  {
    featureId: "EF007260",
    featureDesc: "Suitable for sector conductor connection",
    label: "EF007260: Suitable for sector conductor connection"
  },
  {
    featureId: "EF007261",
    featureDesc: "Suitable for signalling lamp",
    label: "EF007261: Suitable for signalling lamp"
  },
  {
    featureId: "EF007262",
    featureDesc: "Suitable for distribution board installation",
    label: "EF007262: Suitable for distribution board installation"
  },
  {
    featureId: "EF007263",
    featureDesc: "Suitable for selector switch",
    label: "EF007263: Suitable for selector switch"
  },
  {
    featureId: "EF007264",
    featureDesc: "Suitable for intermediate mounting",
    label: "EF007264: Suitable for intermediate mounting"
  },
  {
    featureId: "EF007266",
    featureDesc: "Suitable for monitoring of magnetic switches",
    label: "EF007266: Suitable for monitoring of magnetic switches"
  },
  {
    featureId: "EF007267",
    featureDesc: "Suitable for monitoring of proximity switches",
    label: "EF007267: Suitable for monitoring of proximity switches"
  },
  {
    featureId: "EF007268",
    featureDesc: "Suitable for monitoring of emergency-stop circuits",
    label: "EF007268: Suitable for monitoring of emergency-stop circuits"
  },
  {
    featureId: "EF007269",
    featureDesc: "Suitable for monitoring of optoelectronic protection equipment",
    label: "EF007269: Suitable for monitoring of optoelectronic protection equipment"
  },
  {
    featureId: "EF007270",
    featureDesc: "Suitable for monitoring of position switches",
    label: "EF007270: Suitable for monitoring of position switches"
  },
  {
    featureId: "EF007271",
    featureDesc: "Suitable for monitoring of tactile sensors",
    label: "EF007271: Suitable for monitoring of tactile sensors"
  },
  {
    featureId: "EF007272",
    featureDesc: "Suitable for monitoring of valves",
    label: "EF007272: Suitable for monitoring of valves"
  },
  {
    featureId: "EF007275",
    featureDesc: "Device model",
    label: "EF007275: Device model"
  },
  {
    featureId: "EF007276",
    featureDesc: "Construction type of device",
    label: "EF007276: Construction type of device"
  },
  {
    featureId: "EF007277",
    featureDesc: "Thread sleeves sensor/actuator slots",
    label: "EF007277: Thread sleeves sensor/actuator slots"
  },
  {
    featureId: "EF007279",
    featureDesc: "Basic device",
    label: "EF007279: Basic device"
  },
  {
    featureId: "EF007281",
    featureDesc: "Number of auxiliary contacts as normally closed contact, delayed switching",
    label: "EF007281: Number of auxiliary contacts as normally closed contact, delayed switching"
  },
  {
    featureId: "EF007282",
    featureDesc: "Number of auxiliary contacts as normally open contact, leading",
    label: "EF007282: Number of auxiliary contacts as normally open contact, leading"
  },
  {
    featureId: "EF007283",
    featureDesc: "Height of sensor",
    label: "EF007283: Height of sensor"
  },
  {
    featureId: "EF007284",
    featureDesc: "Height amplifier",
    label: "EF007284: Height amplifier"
  },
  {
    featureId: "EF007289",
    featureDesc: "Impulse rate per cycle",
    label: "EF007289: Impulse rate per cycle"
  },
  {
    featureId: "EF007291",
    featureDesc: "Rated inductance",
    label: "EF007291: Rated inductance"
  },
  {
    featureId: "EF007293",
    featureDesc: "Integrated breaking resistance",
    label: "EF007293: Integrated breaking resistance"
  },
  {
    featureId: "EF007294",
    featureDesc: "Application in industrial area permitted",
    label: "EF007294: Application in industrial area permitted"
  },
  {
    featureId: "EF007295",
    featureDesc: "Application in domestic- and commercial area permitted",
    label: "EF007295: Application in domestic- and commercial area permitted"
  },
  {
    featureId: "EF007296",
    featureDesc: "Integrated earth fault protection",
    label: "EF007296: Integrated earth fault protection"
  },
  {
    featureId: "EF007297",
    featureDesc: "Integrated motor overload protection",
    label: "EF007297: Integrated motor overload protection"
  },
  {
    featureId: "EF007298",
    featureDesc: "IO link master",
    label: "EF007298: IO link master"
  },
  {
    featureId: "EF007299",
    featureDesc: "Type of insulation material according to IEC 85",
    label: "EF007299: Type of insulation material according to IEC 85"
  },
  {
    featureId: "EF007300",
    featureDesc: "Length of the preassembled cable",
    label: "EF007300: Length of the preassembled cable"
  },
  {
    featureId: "EF007301",
    featureDesc: "Cable length configurable",
    label: "EF007301: Cable length configurable"
  },
  {
    featureId: "EF007302",
    featureDesc: "Cable outer diameter for cable gland",
    label: "EF007302: Cable outer diameter for cable gland"
  },
  {
    featureId: "EF007305",
    featureDesc: "With communication interface analogue",
    label: "EF007305: With communication interface analogue"
  },
  {
    featureId: "EF007306",
    featureDesc: "With communication interface AS-Interface",
    label: "EF007306: With communication interface AS-Interface"
  },
  {
    featureId: "EF007307",
    featureDesc: "With communication interface CANOpen",
    label: "EF007307: With communication interface CANOpen"
  },
  {
    featureId: "EF007308",
    featureDesc: "With communication interface DeviceNet",
    label: "EF007308: With communication interface DeviceNet"
  },
  {
    featureId: "EF007309",
    featureDesc: "With communication interface Ethernet",
    label: "EF007309: With communication interface Ethernet"
  },
  {
    featureId: "EF007310",
    featureDesc: "With communication interface INTERBUS",
    label: "EF007310: With communication interface INTERBUS"
  },
  {
    featureId: "EF007311",
    featureDesc: "With communication interface PROFIBUS",
    label: "EF007311: With communication interface PROFIBUS"
  },
  {
    featureId: "EF007312",
    featureDesc: "With communication interface RS-232",
    label: "EF007312: With communication interface RS-232"
  },
  {
    featureId: "EF007313",
    featureDesc: "With communication interface RS-422",
    label: "EF007313: With communication interface RS-422"
  },
  {
    featureId: "EF007314",
    featureDesc: "With communication interface RS-485",
    label: "EF007314: With communication interface RS-485"
  },
  {
    featureId: "EF007315",
    featureDesc: "With communication interface SSD",
    label: "EF007315: With communication interface SSD"
  },
  {
    featureId: "EF007316",
    featureDesc: "With communication interface SSI",
    label: "EF007316: With communication interface SSI"
  },
  {
    featureId: "EF007317",
    featureDesc: "Complete device in housing",
    label: "EF007317: Complete device in housing"
  },
  {
    featureId: "EF007319",
    featureDesc: "Diameter contact female",
    label: "EF007319: Diameter contact female"
  },
  {
    featureId: "EF007320",
    featureDesc: "Diameter plug-in contact",
    label: "EF007320: Diameter plug-in contact"
  },
  {
    featureId: "EF007324",
    featureDesc: "Contact insert without contacts",
    label: "EF007324: Contact insert without contacts"
  },
  {
    featureId: "EF007326",
    featureDesc: "Modular spacing contacts",
    label: "EF007326: Modular spacing contacts"
  },
  {
    featureId: "EF007327",
    featureDesc: "Diameter contact pin",
    label: "EF007327: Diameter contact pin"
  },
  {
    featureId: "EF007329",
    featureDesc: "Type of cooling medium",
    label: "EF007329: Type of cooling medium"
  },
  {
    featureId: "EF007330",
    featureDesc: "Cooling capacity, usable at 35 °C",
    label: "EF007330: Cooling capacity, usable at 35 °C"
  },
  {
    featureId: "EF007332",
    featureDesc: "Short-circuit-proof",
    label: "EF007332: Short-circuit-proof"
  },
  {
    featureId: "EF007333",
    featureDesc: "Short-circuit current limiter",
    label: "EF007333: Short-circuit current limiter"
  },
  {
    featureId: "EF007334",
    featureDesc: "Length of sensor",
    label: "EF007334: Length of sensor"
  },
  {
    featureId: "EF007335",
    featureDesc: "Length amplifier",
    label: "EF007335: Length amplifier"
  },
  {
    featureId: "EF007337",
    featureDesc: "Connectable conductor cross section solid-core",
    label: "EF007337: Connectable conductor cross section solid-core"
  },
  {
    featureId: "EF007339",
    featureDesc: "Connectable conductor cross section fine-strand with cable end sleeve",
    label: "EF007339: Connectable conductor cross section fine-strand with cable end sleeve"
  },
  {
    featureId: "EF007341",
    featureDesc: "Connectable conductor cross section fine-strand without cable end sleeve",
    label: "EF007341: Connectable conductor cross section fine-strand without cable end sleeve"
  },
  {
    featureId: "EF007343",
    featureDesc: "Connectable conductor cross section multi-wired",
    label: "EF007343: Connectable conductor cross section multi-wired"
  },
  {
    featureId: "EF007346",
    featureDesc: "With ventilation slots",
    label: "EF007346: With ventilation slots"
  },
  {
    featureId: "EF007348",
    featureDesc: "Number of relay outputs",
    label: "EF007348: Number of relay outputs"
  },
  {
    featureId: "EF007349",
    featureDesc: "Max. number of time switches",
    label: "EF007349: Max. number of time switches"
  },
  {
    featureId: "EF007350",
    featureDesc: "Max. output frequency",
    label: "EF007350: Max. output frequency"
  },
  {
    featureId: "EF007351",
    featureDesc: "Max. output current AC 50 Hz",
    label: "EF007351: Max. output current AC 50 Hz"
  },
  {
    featureId: "EF007352",
    featureDesc: "Max. output current AC 60 Hz",
    label: "EF007352: Max. output current AC 60 Hz"
  },
  {
    featureId: "EF007353",
    featureDesc: "Max. output current DC",
    label: "EF007353: Max. output current DC"
  },
  {
    featureId: "EF007355",
    featureDesc: "Max. rated operation voltage Ue AC",
    label: "EF007355: Max. rated operation voltage Ue AC"
  },
  {
    featureId: "EF007356",
    featureDesc: "Max. rated operation current Ie",
    label: "EF007356: Max. rated operation current Ie"
  },
  {
    featureId: "EF007357",
    featureDesc: "Max. rated operation voltage Ue",
    label: "EF007357: Max. rated operation voltage Ue"
  },
  {
    featureId: "EF007358",
    featureDesc: "Max. rated current (Ith) at rated voltage AC",
    label: "EF007358: Max. rated current (Ith) at rated voltage AC"
  },
  {
    featureId: "EF007359",
    featureDesc: "Max. rated current (Ith) at rated voltage DC",
    label: "EF007359: Max. rated current (Ith) at rated voltage DC"
  },
  {
    featureId: "EF007360",
    featureDesc: "Max. input current AC 50 Hz",
    label: "EF007360: Max. input current AC 50 Hz"
  },
  {
    featureId: "EF007361",
    featureDesc: "Max. input current AC 60 Hz",
    label: "EF007361: Max. input current AC 60 Hz"
  },
  {
    featureId: "EF007362",
    featureDesc: "Max. input current DC",
    label: "EF007362: Max. input current DC"
  },
  {
    featureId: "EF007364",
    featureDesc: "Max. output at linear load at rated output voltage",
    label: "EF007364: Max. output at linear load at rated output voltage"
  },
  {
    featureId: "EF007365",
    featureDesc: "Max. test pressure",
    label: "EF007365: Max. test pressure"
  },
  {
    featureId: "EF007366",
    featureDesc: "Max. output at quadratic load at rated output voltage",
    label: "EF007366: Max. output at quadratic load at rated output voltage"
  },
  {
    featureId: "EF007367",
    featureDesc: "Max. switching distance",
    label: "EF007367: Max. switching distance"
  },
  {
    featureId: "EF007368",
    featureDesc: "Max. rated current of one contact",
    label: "EF007368: Max. rated current of one contact"
  },
  {
    featureId: "EF007369",
    featureDesc: "Medium temperature",
    label: "EF007369: Medium temperature"
  },
  {
    featureId: "EF007370",
    featureDesc: "Rated operating frequency",
    label: "EF007370: Rated operating frequency"
  },
  {
    featureId: "EF007371",
    featureDesc: "With measuring head",
    label: "EF007371: With measuring head"
  },
  {
    featureId: "EF007372",
    featureDesc: "Measuring head in device integrated",
    label: "EF007372: Measuring head in device integrated"
  },
  {
    featureId: "EF007373",
    featureDesc: "Differential gap, temperature",
    label: "EF007373: Differential gap, temperature"
  },
  {
    featureId: "EF007374",
    featureDesc: "Min. permitted supply voltage",
    label: "EF007374: Min. permitted supply voltage"
  },
  {
    featureId: "EF007375",
    featureDesc: "With roof plate",
    label: "EF007375: With roof plate"
  },
  {
    featureId: "EF007376",
    featureDesc: "With earthing",
    label: "EF007376: With earthing"
  },
  {
    featureId: "EF007377",
    featureDesc: "With front door",
    label: "EF007377: With front door"
  },
  {
    featureId: "EF007378",
    featureDesc: "With hand operation",
    label: "EF007378: With hand operation"
  },
  {
    featureId: "EF007380",
    featureDesc: "With copper rail",
    label: "EF007380: With copper rail"
  },
  {
    featureId: "EF007381",
    featureDesc: "With rear door",
    label: "EF007381: With rear door"
  },
  {
    featureId: "EF007383",
    featureDesc: "With sidewall",
    label: "EF007383: With sidewall"
  },
  {
    featureId: "EF007384",
    featureDesc: "With error protection",
    label: "EF007384: With error protection"
  },
  {
    featureId: "EF007385",
    featureDesc: "With interlocking device",
    label: "EF007385: With interlocking device"
  },
  {
    featureId: "EF007386",
    featureDesc: "Motor drive optional",
    label: "EF007386: Motor drive optional"
  },
  {
    featureId: "EF007389",
    featureDesc: "Nominal volume flow rate",
    label: "EF007389: Nominal volume flow rate"
  },
  {
    featureId: "EF007391",
    featureDesc: "Nominal cooling performance L32Oil20 at 50Hz",
    label: "EF007391: Nominal cooling performance L32Oil20 at 50Hz"
  },
  {
    featureId: "EF007392",
    featureDesc: "Nominal cooling performance L32W18 at 50Hz",
    label: "EF007392: Nominal cooling performance L32W18 at 50Hz"
  },
  {
    featureId: "EF007393",
    featureDesc: "Nominal cooling performance L35W10 200l/h",
    label: "EF007393: Nominal cooling performance L35W10 200l/h"
  },
  {
    featureId: "EF007394",
    featureDesc: "Nominal cooling performance L35W10 400l/h",
    label: "EF007394: Nominal cooling performance L35W10 400l/h"
  },
  {
    featureId: "EF007395",
    featureDesc: "Off delay timer",
    label: "EF007395: Off delay timer"
  },
  {
    featureId: "EF007396",
    featureDesc: "On delay timer",
    label: "EF007396: On delay timer"
  },
  {
    featureId: "EF007397",
    featureDesc: "Version for extended temperature range",
    label: "EF007397: Version for extended temperature range"
  },
  {
    featureId: "EF007398",
    featureDesc: "Parameterizable",
    label: "EF007398: Parameterizable"
  },
  {
    featureId: "EF007399",
    featureDesc: "Performance level according to EN ISO 13849-1",
    label: "EF007399: Performance level according to EN ISO 13849-1"
  },
  {
    featureId: "EF007400",
    featureDesc: "Phase failure sensitive",
    label: "EF007400: Phase failure sensitive"
  },
  {
    featureId: "EF007401",
    featureDesc: "Polarization",
    label: "EF007401: Polarization"
  },
  {
    featureId: "EF007402",
    featureDesc: "Number of poles sensor/actuator slots",
    label: "EF007402: Number of poles sensor/actuator slots"
  },
  {
    featureId: "EF007403",
    featureDesc: "Number of poles, primary side",
    label: "EF007403: Number of poles, primary side"
  },
  {
    featureId: "EF007405",
    featureDesc: "Rated primary current",
    label: "EF007405: Rated primary current"
  },
  {
    featureId: "EF007409",
    featureDesc: "Primary voltage 10",
    label: "EF007409: Primary voltage 10"
  },
  {
    featureId: "EF007410",
    featureDesc: "Primary voltage 4",
    label: "EF007410: Primary voltage 4"
  },
  {
    featureId: "EF007411",
    featureDesc: "Primary voltage 5",
    label: "EF007411: Primary voltage 5"
  },
  {
    featureId: "EF007412",
    featureDesc: "Primary voltage 6",
    label: "EF007412: Primary voltage 6"
  },
  {
    featureId: "EF007413",
    featureDesc: "Primary voltage 7",
    label: "EF007413: Primary voltage 7"
  },
  {
    featureId: "EF007414",
    featureDesc: "Primary voltage 8",
    label: "EF007414: Primary voltage 8"
  },
  {
    featureId: "EF007415",
    featureDesc: "Primary voltage 9",
    label: "EF007415: Primary voltage 9"
  },
  {
    featureId: "EF007416",
    featureDesc: "Loadable for operating system for configuration software, Windows 2003 Server",
    label: "EF007416: Loadable for operating system for configuration software, Windows 2003 Server"
  },
  {
    featureId: "EF007417",
    featureDesc: "Loadable for operating system for configuration software, Windows Vista",
    label: "EF007417: Loadable for operating system for configuration software, Windows Vista"
  },
  {
    featureId: "EF007445",
    featureDesc: "Cross-wire monitoring possible",
    label: "EF007445: Cross-wire monitoring possible"
  },
  {
    featureId: "EF007446",
    featureDesc: "RAL-number corpus",
    label: "EF007446: RAL-number corpus"
  },
  {
    featureId: "EF007447",
    featureDesc: "Switching function latching",
    label: "EF007447: Switching function latching"
  },
  {
    featureId: "EF007448",
    featureDesc: "With reflector",
    label: "EF007448: With reflector"
  },
  {
    featureId: "EF007452",
    featureDesc: "Relative short circuit voltage",
    label: "EF007452: Relative short circuit voltage"
  },
  {
    featureId: "EF007453",
    featureDesc: "Relative symmetric net voltage tolerance",
    label: "EF007453: Relative symmetric net voltage tolerance"
  },
  {
    featureId: "EF007454",
    featureDesc: "Relative symmetric net frequency tolerance",
    label: "EF007454: Relative symmetric net frequency tolerance"
  },
  {
    featureId: "EF007455",
    featureDesc: "Resonance frequency",
    label: "EF007455: Resonance frequency"
  },
  {
    featureId: "EF007456",
    featureDesc: "Resonance frequency, electric filter",
    label: "EF007456: Resonance frequency, electric filter"
  },
  {
    featureId: "EF007457",
    featureDesc: "Runtime operation system, Windows Vista",
    label: "EF007457: Runtime operation system, Windows Vista"
  },
  {
    featureId: "EF007458",
    featureDesc: "SCADA functionality",
    label: "EF007458: SCADA functionality"
  },
  {
    featureId: "EF007461",
    featureDesc: "Fix cable version suitable for cable guide chain",
    label: "EF007461: Fix cable version suitable for cable guide chain"
  },
  {
    featureId: "EF007462",
    featureDesc: "Snap mounting",
    label: "EF007462: Snap mounting"
  },
  {
    featureId: "EF007464",
    featureDesc: "Degree of protection (IP) evaluation electronic",
    label: "EF007464: Degree of protection (IP) evaluation electronic"
  },
  {
    featureId: "EF007465",
    featureDesc: "Degree of protection (IP) measuring head",
    label: "EF007465: Degree of protection (IP) measuring head"
  },
  {
    featureId: "EF007466",
    featureDesc: "Degree of protection (IP), mounted",
    label: "EF007466: Degree of protection (IP), mounted"
  },
  {
    featureId: "EF007467",
    featureDesc: "Contactor control function block",
    label: "EF007467: Contactor control function block"
  },
  {
    featureId: "EF007468",
    featureDesc: "With protective conductor",
    label: "EF007468: With protective conductor"
  },
  {
    featureId: "EF007470",
    featureDesc: "Rated secondary apparent power",
    label: "EF007470: Rated secondary apparent power"
  },
  {
    featureId: "EF007471",
    featureDesc: "Rated secondary current",
    label: "EF007471: Rated secondary current"
  },
  {
    featureId: "EF007472",
    featureDesc: "Secondary voltage 1",
    label: "EF007472: Secondary voltage 1"
  },
  {
    featureId: "EF007473",
    featureDesc: "Secondary voltage 10",
    label: "EF007473: Secondary voltage 10"
  },
  {
    featureId: "EF007474",
    featureDesc: "Secondary voltage 2",
    label: "EF007474: Secondary voltage 2"
  },
  {
    featureId: "EF007475",
    featureDesc: "Secondary voltage 3",
    label: "EF007475: Secondary voltage 3"
  },
  {
    featureId: "EF007476",
    featureDesc: "Secondary voltage 4",
    label: "EF007476: Secondary voltage 4"
  },
  {
    featureId: "EF007477",
    featureDesc: "Secondary voltage 5",
    label: "EF007477: Secondary voltage 5"
  },
  {
    featureId: "EF007478",
    featureDesc: "Secondary voltage 6",
    label: "EF007478: Secondary voltage 6"
  },
  {
    featureId: "EF007479",
    featureDesc: "Secondary voltage 7",
    label: "EF007479: Secondary voltage 7"
  },
  {
    featureId: "EF007480",
    featureDesc: "Secondary voltage 8",
    label: "EF007480: Secondary voltage 8"
  },
  {
    featureId: "EF007481",
    featureDesc: "Secondary voltage 9",
    label: "EF007481: Secondary voltage 9"
  },
  {
    featureId: "EF007484",
    featureDesc: "Software preinstalled",
    label: "EF007484: Software preinstalled"
  },
  {
    featureId: "EF007485",
    featureDesc: "With other analogue output",
    label: "EF007485: With other analogue output"
  },
  {
    featureId: "EF007491",
    featureDesc: "Voltage release optional",
    label: "EF007491: Voltage release optional"
  },
  {
    featureId: "EF007492",
    featureDesc: "Voltage detection module",
    label: "EF007492: Voltage detection module"
  },
  {
    featureId: "EF007493",
    featureDesc: "Language switch possible",
    label: "EF007493: Language switch possible"
  },
  {
    featureId: "EF007494",
    featureDesc: "Type of connector for trunk line",
    label: "EF007494: Type of connector for trunk line"
  },
  {
    featureId: "EF007495",
    featureDesc: "Connector model",
    label: "EF007495: Connector model"
  },
  {
    featureId: "EF007496",
    featureDesc: "Diameter connector",
    label: "EF007496: Diameter connector"
  },
  {
    featureId: "EF007497",
    featureDesc: "Stationary wave relation",
    label: "EF007497: Stationary wave relation"
  },
  {
    featureId: "EF007498",
    featureDesc: "Stop category according to IEC 60204",
    label: "EF007498: Stop category according to IEC 60204"
  },
  {
    featureId: "EF007499",
    featureDesc: "Malfunction message output",
    label: "EF007499: Malfunction message output"
  },
  {
    featureId: "EF007500",
    featureDesc: "Rated current AC",
    label: "EF007500: Rated current AC"
  },
  {
    featureId: "EF007501",
    featureDesc: "Current detection module",
    label: "EF007501: Current detection module"
  },
  {
    featureId: "EF007502",
    featureDesc: "Rated clock frequency",
    label: "EF007502: Rated clock frequency"
  },
  {
    featureId: "EF007503",
    featureDesc: "Sensing mode",
    label: "EF007503: Sensing mode"
  },
  {
    featureId: "EF007504",
    featureDesc: "Module width",
    label: "EF007504: Module width"
  },
  {
    featureId: "EF007505",
    featureDesc: "Ambient temperature for evaluation electronic",
    label: "EF007505: Ambient temperature for evaluation electronic"
  },
  {
    featureId: "EF007507",
    featureDesc: "Temperature drift",
    label: "EF007507: Temperature drift"
  },
  {
    featureId: "EF007508",
    featureDesc: "Transistor output",
    label: "EF007508: Transistor output"
  },
  {
    featureId: "EF007509",
    featureDesc: "Trend output (not linear, analog)",
    label: "EF007509: Trend output (not linear, analog)"
  },
  {
    featureId: "EF007510",
    featureDesc: "Trend representation",
    label: "EF007510: Trend representation"
  },
  {
    featureId: "EF007511",
    featureDesc: "Overcurrent limiting factor",
    label: "EF007511: Overcurrent limiting factor"
  },
  {
    featureId: "EF007513",
    featureDesc: "Type of transmission medium",
    label: "EF007513: Type of transmission medium"
  },
  {
    featureId: "EF007515",
    featureDesc: "Ambient temperature during operating",
    label: "EF007515: Ambient temperature during operating"
  },
  {
    featureId: "EF007516",
    featureDesc: "Air circulator",
    label: "EF007516: Air circulator"
  },
  {
    featureId: "EF007517",
    featureDesc: "Flush-mounted installation",
    label: "EF007517: Flush-mounted installation"
  },
  {
    featureId: "EF007519",
    featureDesc: "Supporting protocol for AS-Interface Safety at Work",
    label: "EF007519: Supporting protocol for AS-Interface Safety at Work"
  },
  {
    featureId: "EF007520",
    featureDesc: "Supporting protocol for DeviceNet Safety",
    label: "EF007520: Supporting protocol for DeviceNet Safety"
  },
  {
    featureId: "EF007521",
    featureDesc: "Supporting protocol for EtherNet/IP",
    label: "EF007521: Supporting protocol for EtherNet/IP"
  },
  {
    featureId: "EF007522",
    featureDesc: "Supporting protocol for Foundation Fieldbus",
    label: "EF007522: Supporting protocol for Foundation Fieldbus"
  },
  {
    featureId: "EF007524",
    featureDesc: "Supporting protocol for PROFINET CBA",
    label: "EF007524: Supporting protocol for PROFINET CBA"
  },
  {
    featureId: "EF007525",
    featureDesc: "Supporting protocol for PROFINET IO",
    label: "EF007525: Supporting protocol for PROFINET IO"
  },
  {
    featureId: "EF007526",
    featureDesc: "Supporting protocol for PROFIsafe",
    label: "EF007526: Supporting protocol for PROFIsafe"
  },
  {
    featureId: "EF007527",
    featureDesc: "Supporting protocol for SafetyBUS p",
    label: "EF007527: Supporting protocol for SafetyBUS p"
  },
  {
    featureId: "EF007528",
    featureDesc: "Valve switching time from 0 to 1",
    label: "EF007528: Valve switching time from 0 to 1"
  },
  {
    featureId: "EF007529",
    featureDesc: "Valve switching time from 1 to 0",
    label: "EF007529: Valve switching time from 1 to 0"
  },
  {
    featureId: "EF007530",
    featureDesc: "Filtering factor",
    label: "EF007530: Filtering factor"
  },
  {
    featureId: "EF007532",
    featureDesc: "Supply voltage AC 50 Hz",
    label: "EF007532: Supply voltage AC 50 Hz"
  },
  {
    featureId: "EF007533",
    featureDesc: "Supply voltage AC 60 Hz",
    label: "EF007533: Supply voltage AC 60 Hz"
  },
  {
    featureId: "EF007534",
    featureDesc: "Supply voltage DC",
    label: "EF007534: Supply voltage DC"
  },
  {
    featureId: "EF007535",
    featureDesc: "Preinstalled operating system",
    label: "EF007535: Preinstalled operating system"
  },
  {
    featureId: "EF007536",
    featureDesc: "Suitable for wall built-in",
    label: "EF007536: Suitable for wall built-in"
  },
  {
    featureId: "EF007538",
    featureDesc: "Specific thermal output",
    label: "EF007538: Specific thermal output"
  },
  {
    featureId: "EF007539",
    featureDesc: "Material adapter plate",
    label: "EF007539: Material adapter plate"
  },
  {
    featureId: "EF007540",
    featureDesc: "Material coding element",
    label: "EF007540: Material coding element"
  },
  {
    featureId: "EF007541",
    featureDesc: "Material spring clip",
    label: "EF007541: Material spring clip"
  },
  {
    featureId: "EF007542",
    featureDesc: "Material assembly frame",
    label: "EF007542: Material assembly frame"
  },
  {
    featureId: "EF007543",
    featureDesc: "Material insulation body",
    label: "EF007543: Material insulation body"
  },
  {
    featureId: "EF007544",
    featureDesc: "Material contact",
    label: "EF007544: Material contact"
  },
  {
    featureId: "EF007545",
    featureDesc: "Material connector insert",
    label: "EF007545: Material connector insert"
  },
  {
    featureId: "EF007546",
    featureDesc: "Angle contact/conductor connection",
    label: "EF007546: Angle contact/conductor connection"
  },
  {
    featureId: "EF007547",
    featureDesc: "Angle PCB/contact",
    label: "EF007547: Angle PCB/contact"
  },
  {
    featureId: "EF007548",
    featureDesc: "Angle PCB/conductor connection",
    label: "EF007548: Angle PCB/conductor connection"
  },
  {
    featureId: "EF007549",
    featureDesc: "Connection type meter",
    label: "EF007549: Connection type meter"
  },
  {
    featureId: "EF007550",
    featureDesc: "Tariff type",
    label: "EF007550: Tariff type"
  },
  {
    featureId: "EF007551",
    featureDesc: "Demountable",
    label: "EF007551: Demountable"
  },
  {
    featureId: "EF007552",
    featureDesc: "Guard locking force",
    label: "EF007552: Guard locking force"
  },
  {
    featureId: "EF007553",
    featureDesc: "Permitted voltage at output",
    label: "EF007553: Permitted voltage at output"
  },
  {
    featureId: "EF007554",
    featureDesc: "Permitted voltage at input",
    label: "EF007554: Permitted voltage at input"
  },
  {
    featureId: "EF007556",
    featureDesc: "With control gear",
    label: "EF007556: With control gear"
  },
  {
    featureId: "EF007557",
    featureDesc: "With ignitor",
    label: "EF007557: With ignitor"
  },
  {
    featureId: "EF007558",
    featureDesc: "Adjustable optics",
    label: "EF007558: Adjustable optics"
  },
  {
    featureId: "EF007559",
    featureDesc: "Suitable for spigot size",
    label: "EF007559: Suitable for spigot size"
  },
  {
    featureId: "EF007560",
    featureDesc: "LNB feed",
    label: "EF007560: LNB feed"
  },
  {
    featureId: "EF007561",
    featureDesc: "Height levelling",
    label: "EF007561: Height levelling"
  },
  {
    featureId: "EF007562",
    featureDesc: "Snap-off",
    label: "EF007562: Snap-off"
  },
  {
    featureId: "EF007563",
    featureDesc: "Branch off duct connection",
    label: "EF007563: Branch off duct connection"
  },
  {
    featureId: "EF007564",
    featureDesc: "Outgoing width",
    label: "EF007564: Outgoing width"
  },
  {
    featureId: "EF007565",
    featureDesc: "Outgoing height",
    label: "EF007565: Outgoing height"
  },
  {
    featureId: "EF007566",
    featureDesc: "With suspension",
    label: "EF007566: With suspension"
  },
  {
    featureId: "EF007567",
    featureDesc: "Strip range diameter",
    label: "EF007567: Strip range diameter"
  },
  {
    featureId: "EF007568",
    featureDesc: "Strip range cross-section",
    label: "EF007568: Strip range cross-section"
  },
  {
    featureId: "EF007569",
    featureDesc: "Detachable collection container",
    label: "EF007569: Detachable collection container"
  },
  {
    featureId: "EF007570",
    featureDesc: "Suction power",
    label: "EF007570: Suction power"
  },
  {
    featureId: "EF007571",
    featureDesc: "With distance to the wall",
    label: "EF007571: With distance to the wall"
  },
  {
    featureId: "EF007572",
    featureDesc: "Core cross section",
    label: "EF007572: Core cross section"
  },
  {
    featureId: "EF007573",
    featureDesc: "Number of cores",
    label: "EF007573: Number of cores"
  },
  {
    featureId: "EF007575",
    featureDesc: "Response value luminosity adjustable",
    label: "EF007575: Response value luminosity adjustable"
  },
  {
    featureId: "EF007576",
    featureDesc: "Application hole punching",
    label: "EF007576: Application hole punching"
  },
  {
    featureId: "EF007577",
    featureDesc: "Application pressing",
    label: "EF007577: Application pressing"
  },
  {
    featureId: "EF007578",
    featureDesc: "Application cutting",
    label: "EF007578: Application cutting"
  },
  {
    featureId: "EF007580",
    featureDesc: "Number of ports 10 Gbps SC (LX)",
    label: "EF007580: Number of ports 10 Gbps SC (LX)"
  },
  {
    featureId: "EF007581",
    featureDesc: "Number of ports 10 Gbps SC (SX)",
    label: "EF007581: Number of ports 10 Gbps SC (SX)"
  },
  {
    featureId: "EF007583",
    featureDesc: "Number of tap-off units",
    label: "EF007583: Number of tap-off units"
  },
  {
    featureId: "EF007584",
    featureDesc: "Number of connection clamps for lightning control",
    label: "EF007584: Number of connection clamps for lightning control"
  },
  {
    featureId: "EF007585",
    featureDesc: "Number of connection clamps for earth rods",
    label: "EF007585: Number of connection clamps for earth rods"
  },
  {
    featureId: "EF007586",
    featureDesc: "Number of arms of the top-piece",
    label: "EF007586: Number of arms of the top-piece"
  },
  {
    featureId: "EF007587",
    featureDesc: "Number of outputs for acoustic signal generators",
    label: "EF007587: Number of outputs for acoustic signal generators"
  },
  {
    featureId: "EF007588",
    featureDesc: "Number of outputs for optical signal generators",
    label: "EF007588: Number of outputs for optical signal generators"
  },
  {
    featureId: "EF007589",
    featureDesc: "Number of automatic detectors",
    label: "EF007589: Number of automatic detectors"
  },
  {
    featureId: "EF007590",
    featureDesc: "Number of basic element for surge protection device",
    label: "EF007590: Number of basic element for surge protection device"
  },
  {
    featureId: "EF007591",
    featureDesc: "Number of flame resistant sides",
    label: "EF007591: Number of flame resistant sides"
  },
  {
    featureId: "EF007592",
    featureDesc: "Number of actuation points",
    label: "EF007592: Number of actuation points"
  },
  {
    featureId: "EF007593",
    featureDesc: "Number of pixels (Megapixel)",
    label: "EF007593: Number of pixels (Megapixel)"
  },
  {
    featureId: "EF007594",
    featureDesc: "Number of lightning current arrester for power supply systems",
    label: "EF007594: Number of lightning current arrester for power supply systems"
  },
  {
    featureId: "EF007595",
    featureDesc: "Number of lightning current arrester for data networks/MCR-technology",
    label: "EF007595: Number of lightning current arrester for data networks/MCR-technology"
  },
  {
    featureId: "EF007596",
    featureDesc: "Number of CI-interfaces",
    label: "EF007596: Number of CI-interfaces"
  },
  {
    featureId: "EF007597",
    featureDesc: "Number of C-clamps for round wire",
    label: "EF007597: Number of C-clamps for round wire"
  },
  {
    featureId: "EF007598",
    featureDesc: "Number of roof conductor holders for lightning protection",
    label: "EF007598: Number of roof conductor holders for lightning protection"
  },
  {
    featureId: "EF007599",
    featureDesc: "Number of data connections",
    label: "EF007599: Number of data connections"
  },
  {
    featureId: "EF007600",
    featureDesc: "Number of distance holders for lightning protection",
    label: "EF007600: Number of distance holders for lightning protection"
  },
  {
    featureId: "EF007601",
    featureDesc: "Number of units to build in rear side",
    label: "EF007601: Number of units to build in rear side"
  },
  {
    featureId: "EF007602",
    featureDesc: "Number of units to build in front side",
    label: "EF007602: Number of units to build in front side"
  },
  {
    featureId: "EF007603",
    featureDesc: "Number of driving-in tools for earth rods",
    label: "EF007603: Number of driving-in tools for earth rods"
  },
  {
    featureId: "EF007604",
    featureDesc: "Number of electronic sensors",
    label: "EF007604: Number of electronic sensors"
  },
  {
    featureId: "EF007605",
    featureDesc: "Number of decoupling coils for internal lightning protection",
    label: "EF007605: Number of decoupling coils for internal lightning protection"
  },
  {
    featureId: "EF007606",
    featureDesc: "Number of earth terminal clamps",
    label: "EF007606: Number of earth terminal clamps"
  },
  {
    featureId: "EF007607",
    featureDesc: "Number of lead-in earthing rods for lightning protection",
    label: "EF007607: Number of lead-in earthing rods for lightning protection"
  },
  {
    featureId: "EF007608",
    featureDesc: "Number of earthing strap clamps",
    label: "EF007608: Number of earthing strap clamps"
  },
  {
    featureId: "EF007609",
    featureDesc: "Number of fixed earthing terminals",
    label: "EF007609: Number of fixed earthing terminals"
  },
  {
    featureId: "EF007610",
    featureDesc: "Number of earthing plates",
    label: "EF007610: Number of earthing plates"
  },
  {
    featureId: "EF007611",
    featureDesc: "Number of earthing pipe clamp",
    label: "EF007611: Number of earthing pipe clamp"
  },
  {
    featureId: "EF007612",
    featureDesc: "Number of air-termination equipment for lightning protection",
    label: "EF007612: Number of air-termination equipment for lightning protection"
  },
  {
    featureId: "EF007613",
    featureDesc: "Number of fault-signal switches",
    label: "EF007613: Number of fault-signal switches"
  },
  {
    featureId: "EF007614",
    featureDesc: "Number of remote signalling modules for lightning current-/surge arrester",
    label: "EF007614: Number of remote signalling modules for lightning current-/surge arrester"
  },
  {
    featureId: "EF007615",
    featureDesc: "Number of flat strips for lightning protection",
    label: "EF007615: Number of flat strips for lightning protection"
  },
  {
    featureId: "EF007616",
    featureDesc: "Number of device units",
    label: "EF007616: Number of device units"
  },
  {
    featureId: "EF007617",
    featureDesc: "Number of surge protection devices for terminal equipment",
    label: "EF007617: Number of surge protection devices for terminal equipment"
  },
  {
    featureId: "EF007618",
    featureDesc: "Number of velocity levels",
    label: "EF007618: Number of velocity levels"
  },
  {
    featureId: "EF007619",
    featureDesc: "Number of glasses",
    label: "EF007619: Number of glasses"
  },
  {
    featureId: "EF007620",
    featureDesc: "Number of HDMI-connections",
    label: "EF007620: Number of HDMI-connections"
  },
  {
    featureId: "EF007621",
    featureDesc: "Number of combined arresters for power supply systems",
    label: "EF007621: Number of combined arresters for power supply systems"
  },
  {
    featureId: "EF007622",
    featureDesc: "Number of combined arresters for data networks/MCR-technology",
    label: "EF007622: Number of combined arresters for data networks/MCR-technology"
  },
  {
    featureId: "EF007623",
    featureDesc: "Number of conductor holders for lightning protection",
    label: "EF007623: Number of conductor holders for lightning protection"
  },
  {
    featureId: "EF007624",
    featureDesc: "Number of light points",
    label: "EF007624: Number of light points"
  },
  {
    featureId: "EF007625",
    featureDesc: "Number of detection groups",
    label: "EF007625: Number of detection groups"
  },
  {
    featureId: "EF007626",
    featureDesc: "Number of measuring tools",
    label: "EF007626: Number of measuring tools"
  },
  {
    featureId: "EF007627",
    featureDesc: "Number of metal cables",
    label: "EF007627: Number of metal cables"
  },
  {
    featureId: "EF007628",
    featureDesc: "Number of ports Mini-GBIC (LX)",
    label: "EF007628: Number of ports Mini-GBIC (LX)"
  },
  {
    featureId: "EF007629",
    featureDesc: "Number of ports Mini-GBIC (SX)",
    label: "EF007629: Number of ports Mini-GBIC (SX)"
  },
  {
    featureId: "EF007631",
    featureDesc: "Number of fuse switch disconnectors",
    label: "EF007631: Number of fuse switch disconnectors"
  },
  {
    featureId: "EF007632",
    featureDesc: "Number of non-automatic detectors",
    label: "EF007632: Number of non-automatic detectors"
  },
  {
    featureId: "EF007633",
    featureDesc: "Number of equipotential bonding bars",
    label: "EF007633: Number of equipotential bonding bars"
  },
  {
    featureId: "EF007634",
    featureDesc: "Number of measurement devices for surge protection",
    label: "EF007634: Number of measurement devices for surge protection"
  },
  {
    featureId: "EF007635",
    featureDesc: "Number of radio connections",
    label: "EF007635: Number of radio connections"
  },
  {
    featureId: "EF007636",
    featureDesc: "Number of tube earthing clamps for lightning protection",
    label: "EF007636: Number of tube earthing clamps for lightning protection"
  },
  {
    featureId: "EF007637",
    featureDesc: "Number of round conductors/wires for lightning protection",
    label: "EF007637: Number of round conductors/wires for lightning protection"
  },
  {
    featureId: "EF007638",
    featureDesc: "Number of sabotage groups",
    label: "EF007638: Number of sabotage groups"
  },
  {
    featureId: "EF007639",
    featureDesc: "Number of satellite-/TV-connections",
    label: "EF007639: Number of satellite-/TV-connections"
  },
  {
    featureId: "EF007640",
    featureDesc: "Number of protective/isolating spark gaps for lightning protection",
    label: "EF007640: Number of protective/isolating spark gaps for lightning protection"
  },
  {
    featureId: "EF007641",
    featureDesc: "Number of sensor cooking plates",
    label: "EF007641: Number of sensor cooking plates"
  },
  {
    featureId: "EF007642",
    featureDesc: "Number of bases for air termination systems lightning protection",
    label: "EF007642: Number of bases for air termination systems lightning protection"
  },
  {
    featureId: "EF007643",
    featureDesc: "Number of other ports",
    label: "EF007643: Number of other ports"
  },
  {
    featureId: "EF007644",
    featureDesc: "Number of earthing rods",
    label: "EF007644: Number of earthing rods"
  },
  {
    featureId: "EF007645",
    featureDesc: "Number of rod holders for lightning protection",
    label: "EF007645: Number of rod holders for lightning protection"
  },
  {
    featureId: "EF007646",
    featureDesc: "Number of telephone connections analogue",
    label: "EF007646: Number of telephone connections analogue"
  },
  {
    featureId: "EF007647",
    featureDesc: "Number of telephone connections ISDN",
    label: "EF007647: Number of telephone connections ISDN"
  },
  {
    featureId: "EF007648",
    featureDesc: "Number of disconnection clamps for lightning protection",
    label: "EF007648: Number of disconnection clamps for lightning protection"
  },
  {
    featureId: "EF007649",
    featureDesc: "Number of assault groups",
    label: "EF007649: Number of assault groups"
  },
  {
    featureId: "EF007650",
    featureDesc: "Number of surge protection devices for power supply systems",
    label: "EF007650: Number of surge protection devices for power supply systems"
  },
  {
    featureId: "EF007651",
    featureDesc: "Number of surge protection devices for data networks/MCR-technology",
    label: "EF007651: Number of surge protection devices for data networks/MCR-technology"
  },
  {
    featureId: "EF007652",
    featureDesc: "Number of surge protection devices for power supply- and information technology",
    label: "EF007652: Number of surge protection devices for power supply- and information technology"
  },
  {
    featureId: "EF007653",
    featureDesc: "Number of connectors for lightning protection",
    label: "EF007653: Number of connectors for lightning protection"
  },
  {
    featureId: "EF007654",
    featureDesc: "Number of provided built-in units",
    label: "EF007654: Number of provided built-in units"
  },
  {
    featureId: "EF007656",
    featureDesc: "Number of draw bolts",
    label: "EF007656: Number of draw bolts"
  },
  {
    featureId: "EF007657",
    featureDesc: "Number of connectors connection 1",
    label: "EF007657: Number of connectors connection 1"
  },
  {
    featureId: "EF007658",
    featureDesc: "Number of connectors connection 2",
    label: "EF007658: Number of connectors connection 2"
  },
  {
    featureId: "EF007659",
    featureDesc: "Indication on the device",
    label: "EF007659: Indication on the device"
  },
  {
    featureId: "EF007660",
    featureDesc: "Tensioning strength",
    label: "EF007660: Tensioning strength"
  },
  {
    featureId: "EF007661",
    featureDesc: "Type of housing feed-through",
    label: "EF007661: Type of housing feed-through"
  },
  {
    featureId: "EF007665",
    featureDesc: "Type of socket outlets",
    label: "EF007665: Type of socket outlets"
  },
  {
    featureId: "EF007666",
    featureDesc: "Type of side joist",
    label: "EF007666: Type of side joist"
  },
  {
    featureId: "EF007667",
    featureDesc: "Switch-off delay self-learning",
    label: "EF007667: Switch-off delay self-learning"
  },
  {
    featureId: "EF007668",
    featureDesc: "Outer diameter sleeve",
    label: "EF007668: Outer diameter sleeve"
  },
  {
    featureId: "EF007670",
    featureDesc: "Evaluation",
    label: "EF007670: Evaluation"
  },
  {
    featureId: "EF007671",
    featureDesc: "Automatic vacuuming",
    label: "EF007671: Automatic vacuuming"
  },
  {
    featureId: "EF007672",
    featureDesc: "Baking oven",
    label: "EF007672: Baking oven"
  },
  {
    featureId: "EF007673",
    featureDesc: "Band closure",
    label: "EF007673: Band closure"
  },
  {
    featureId: "EF007674",
    featureDesc: "Construction type rectangular",
    label: "EF007674: Construction type rectangular"
  },
  {
    featureId: "EF007677",
    featureDesc: "Printable",
    label: "EF007677: Printable"
  },
  {
    featureId: "EF007678",
    featureDesc: "Mounting track",
    label: "EF007678: Mounting track"
  },
  {
    featureId: "EF007679",
    featureDesc: "Rated operation current",
    label: "EF007679: Rated operation current"
  },
  {
    featureId: "EF007680",
    featureDesc: "Rated switch current auxiliary device",
    label: "EF007680: Rated switch current auxiliary device"
  },
  {
    featureId: "EF007681",
    featureDesc: "Rated voltage",
    label: "EF007681: Rated voltage"
  },
  {
    featureId: "EF007682",
    featureDesc: "Rated voltage AC",
    label: "EF007682: Rated voltage AC"
  },
  {
    featureId: "EF007683",
    featureDesc: "Rated voltage auxiliary device",
    label: "EF007683: Rated voltage auxiliary device"
  },
  {
    featureId: "EF007684",
    featureDesc: "Rated current AC 50 Hz",
    label: "EF007684: Rated current AC 50 Hz"
  },
  {
    featureId: "EF007685",
    featureDesc: "Rated current rail system",
    label: "EF007685: Rated current rail system"
  },
  {
    featureId: "EF007686",
    featureDesc: "Equipped with busbar",
    label: "EF007686: Equipped with busbar"
  },
  {
    featureId: "EF007687",
    featureDesc: "Mode of use tube cable outlet for floor duct",
    label: "EF007687: Mode of use tube cable outlet for floor duct"
  },
  {
    featureId: "EF007688",
    featureDesc: "Operating current",
    label: "EF007688: Operating current"
  },
  {
    featureId: "EF007690",
    featureDesc: "Operating temperature, short-run",
    label: "EF007690: Operating temperature, short-run"
  },
  {
    featureId: "EF007692",
    featureDesc: "Image format",
    label: "EF007692: Image format"
  },
  {
    featureId: "EF007694",
    featureDesc: "Suitable for workplace according to EN 12464-1",
    label: "EF007694: Suitable for workplace according to EN 12464-1"
  },
  {
    featureId: "EF007695",
    featureDesc: "Fresh-keeping zone / 0 °C-zone",
    label: "EF007695: Fresh-keeping zone / 0 °C-zone"
  },
  {
    featureId: "EF007696",
    featureDesc: "Blinking light function main lamp",
    label: "EF007696: Blinking light function main lamp"
  },
  {
    featureId: "EF007697",
    featureDesc: "Blinking light function secondary lamp",
    label: "EF007697: Blinking light function secondary lamp"
  },
  {
    featureId: "EF007698",
    featureDesc: "Lightning current load-bearing capacity",
    label: "EF007698: Lightning current load-bearing capacity"
  },
  {
    featureId: "EF007699",
    featureDesc: "Bluetooth",
    label: "EF007699: Bluetooth"
  },
  {
    featureId: "EF007700",
    featureDesc: "Bluetooth-handsfree-installation",
    label: "EF007700: Bluetooth-handsfree-installation"
  },
  {
    featureId: "EF007701",
    featureDesc: "Floor distance",
    label: "EF007701: Floor distance"
  },
  {
    featureId: "EF007702",
    featureDesc: "Floor flush immersible",
    label: "EF007702: Floor flush immersible"
  },
  {
    featureId: "EF007703",
    featureDesc: "Bore diameter",
    label: "EF007703: Bore diameter"
  },
  {
    featureId: "EF007704",
    featureDesc: "Fireproof bulkhead",
    label: "EF007704: Fireproof bulkhead"
  },
  {
    featureId: "EF007705",
    featureDesc: "Fire resistant properties",
    label: "EF007705: Fire resistant properties"
  },
  {
    featureId: "EF007708",
    featureDesc: "Width grill surface",
    label: "EF007708: Width grill surface"
  },
  {
    featureId: "EF007709",
    featureDesc: "Width of the tread",
    label: "EF007709: Width of the tread"
  },
  {
    featureId: "EF007710",
    featureDesc: "Suitable for wall duct width",
    label: "EF007710: Suitable for wall duct width"
  },
  {
    featureId: "EF007711",
    featureDesc: "Suitable for wall duct width",
    label: "EF007711: Suitable for wall duct width"
  },
  {
    featureId: "EF007712",
    featureDesc: "Width installation duct",
    label: "EF007712: Width installation duct"
  },
  {
    featureId: "EF007713",
    featureDesc: "Width corner duct",
    label: "EF007713: Width corner duct"
  },
  {
    featureId: "EF007716",
    featureDesc: "Width duct connection",
    label: "EF007716: Width duct connection"
  },
  {
    featureId: "EF007717",
    featureDesc: "Detect flammable gas",
    label: "EF007717: Detect flammable gas"
  },
  {
    featureId: "EF007718",
    featureDesc: "Bread roll top-piece",
    label: "EF007718: Bread roll top-piece"
  },
  {
    featureId: "EF007719",
    featureDesc: "Bundle diameter",
    label: "EF007719: Bundle diameter"
  },
  {
    featureId: "EF007721",
    featureDesc: "Cappuccino-system",
    label: "EF007721: Cappuccino-system"
  },
  {
    featureId: "EF007724",
    featureDesc: "Nominal cross section Cu",
    label: "EF007724: Nominal cross section Cu"
  },
  {
    featureId: "EF007725",
    featureDesc: "Continuous voltage AC",
    label: "EF007725: Continuous voltage AC"
  },
  {
    featureId: "EF007727",
    featureDesc: "Expansion equalization",
    label: "EF007727: Expansion equalization"
  },
  {
    featureId: "EF007728",
    featureDesc: "Sealing lip",
    label: "EF007728: Sealing lip"
  },
  {
    featureId: "EF007729",
    featureDesc: "Service primary side",
    label: "EF007729: Service primary side"
  },
  {
    featureId: "EF007730",
    featureDesc: "Service secondary side",
    label: "EF007730: Service secondary side"
  },
  {
    featureId: "EF007732",
    featureDesc: "Double layer",
    label: "EF007732: Double layer"
  },
  {
    featureId: "EF007733",
    featureDesc: "Rotating spit",
    label: "EF007733: Rotating spit"
  },
  {
    featureId: "EF007734",
    featureDesc: "Diameter tube",
    label: "EF007734: Diameter tube"
  },
  {
    featureId: "EF007735",
    featureDesc: "DVB-T-tuner",
    label: "EF007735: DVB-T-tuner"
  },
  {
    featureId: "EF007736",
    featureDesc: "Property picture system",
    label: "EF007736: Property picture system"
  },
  {
    featureId: "EF007737",
    featureDesc: "Built-in position",
    label: "EF007737: Built-in position"
  },
  {
    featureId: "EF007738",
    featureDesc: "Built-in opening for built-in unit",
    label: "EF007738: Built-in opening for built-in unit"
  },
  {
    featureId: "EF007740",
    featureDesc: "Ice-cube quantity",
    label: "EF007740: Ice-cube quantity"
  },
  {
    featureId: "EF007745",
    featureDesc: "Decoupling possibility cover from base unit",
    label: "EF007745: Decoupling possibility cover from base unit"
  },
  {
    featureId: "EF007746",
    featureDesc: "Decoupling possibility base from main body",
    label: "EF007746: Decoupling possibility base from main body"
  },
  {
    featureId: "EF007747",
    featureDesc: "Desoldering tip diameter",
    label: "EF007747: Desoldering tip diameter"
  },
  {
    featureId: "EF007748",
    featureDesc: "Desoldering tip shape",
    label: "EF007748: Desoldering tip shape"
  },
  {
    featureId: "EF007749",
    featureDesc: "Occurrence memory",
    label: "EF007749: Occurrence memory"
  },
  {
    featureId: "EF007750",
    featureDesc: "Detection range",
    label: "EF007750: Detection range"
  },
  {
    featureId: "EF007751",
    featureDesc: "Produces DiSEqC-commands",
    label: "EF007751: Produces DiSEqC-commands"
  },
  {
    featureId: "EF007753",
    featureDesc: "Detect explosive gas",
    label: "EF007753: Detect explosive gas"
  },
  {
    featureId: "EF007754",
    featureDesc: "External pushbutton input",
    label: "EF007754: External pushbutton input"
  },
  {
    featureId: "EF007756",
    featureDesc: "Colour cable",
    label: "EF007756: Colour cable"
  },
  {
    featureId: "EF007757",
    featureDesc: "Colour column",
    label: "EF007757: Colour column"
  },
  {
    featureId: "EF007758",
    featureDesc: "Colour coding",
    label: "EF007758: Colour coding"
  },
  {
    featureId: "EF007760",
    featureDesc: "Fibre class",
    label: "EF007760: Fibre class"
  },
  {
    featureId: "EF007762",
    featureDesc: "Loading capacity bean reservoir",
    label: "EF007762: Loading capacity bean reservoir"
  },
  {
    featureId: "EF007763",
    featureDesc: "Capacity deep frying good",
    label: "EF007763: Capacity deep frying good"
  },
  {
    featureId: "EF007764",
    featureDesc: "Fire resistance class E - circuit integrity",
    label: "EF007764: Fire resistance class E - circuit integrity"
  },
  {
    featureId: "EF007765",
    featureDesc: "Fire resistance class I - installation duct",
    label: "EF007765: Fire resistance class I - installation duct"
  },
  {
    featureId: "EF007766",
    featureDesc: "Flux percentage",
    label: "EF007766: Flux percentage"
  },
  {
    featureId: "EF007768",
    featureDesc: "Interference voltage",
    label: "EF007768: Interference voltage"
  },
  {
    featureId: "EF007769",
    featureDesc: "Frequency backward channel",
    label: "EF007769: Frequency backward channel"
  },
  {
    featureId: "EF007770",
    featureDesc: "Deep frying system",
    label: "EF007770: Deep frying system"
  },
  {
    featureId: "EF007771",
    featureDesc: "Filling quantity",
    label: "EF007771: Filling quantity"
  },
  {
    featureId: "EF007772",
    featureDesc: "Filling quantity oil",
    label: "EF007772: Filling quantity oil"
  },
  {
    featureId: "EF007773",
    featureDesc: "Radio system",
    label: "EF007773: Radio system"
  },
  {
    featureId: "EF007774",
    featureDesc: "For box-/housing width",
    label: "EF007774: For box-/housing width"
  },
  {
    featureId: "EF007775",
    featureDesc: "For box-/housing diameter",
    label: "EF007775: For box-/housing diameter"
  },
  {
    featureId: "EF007776",
    featureDesc: "For box-/housing length",
    label: "EF007776: For box-/housing length"
  },
  {
    featureId: "EF007777",
    featureDesc: "Suitable for glasses",
    label: "EF007777: Suitable for glasses"
  },
  {
    featureId: "EF007778",
    featureDesc: "For nominal duct width",
    label: "EF007778: For nominal duct width"
  },
  {
    featureId: "EF007783",
    featureDesc: "Suitable for no-voltage switch",
    label: "EF007783: Suitable for no-voltage switch"
  },
  {
    featureId: "EF007784",
    featureDesc: "For tube connection",
    label: "EF007784: For tube connection"
  },
  {
    featureId: "EF007785",
    featureDesc: "For tube diameter, large",
    label: "EF007785: For tube diameter, large"
  },
  {
    featureId: "EF007786",
    featureDesc: "For tube diameter, small",
    label: "EF007786: For tube diameter, small"
  },
  {
    featureId: "EF007787",
    featureDesc: "Suitable for reverser",
    label: "EF007787: Suitable for reverser"
  },
  {
    featureId: "EF007789",
    featureDesc: "For wall thickness",
    label: "EF007789: For wall thickness"
  },
  {
    featureId: "EF007790",
    featureDesc: "Suitable as inspection light",
    label: "EF007790: Suitable as inspection light"
  },
  {
    featureId: "EF007791",
    featureDesc: "Suitable for Al/St-cables",
    label: "EF007791: Suitable for Al/St-cables"
  },
  {
    featureId: "EF007792",
    featureDesc: "Suitable for Aldrey-cables",
    label: "EF007792: Suitable for Aldrey-cables"
  },
  {
    featureId: "EF007793",
    featureDesc: "Suitable for surface mounting",
    label: "EF007793: Suitable for surface mounting"
  },
  {
    featureId: "EF007794",
    featureDesc: "Suitable for number of couplers",
    label: "EF007794: Suitable for number of couplers"
  },
  {
    featureId: "EF007795",
    featureDesc: "Suitable for number of module inserts",
    label: "EF007795: Suitable for number of module inserts"
  },
  {
    featureId: "EF007796",
    featureDesc: "Suitable for number of splice holders",
    label: "EF007796: Suitable for number of splice holders"
  },
  {
    featureId: "EF007797",
    featureDesc: "Suitable for number of splice protections",
    label: "EF007797: Suitable for number of splice protections"
  },
  {
    featureId: "EF007799",
    featureDesc: "Suitable for fixing with cable ties",
    label: "EF007799: Suitable for fixing with cable ties"
  },
  {
    featureId: "EF007800",
    featureDesc: "Suitable for lightning protection",
    label: "EF007800: Suitable for lightning protection"
  },
  {
    featureId: "EF007801",
    featureDesc: "Suitable for non-ferrous metals",
    label: "EF007801: Suitable for non-ferrous metals"
  },
  {
    featureId: "EF007802",
    featureDesc: "Suitable for touch sensor connector for bus system",
    label: "EF007802: Suitable for touch sensor connector for bus system"
  },
  {
    featureId: "EF007804",
    featureDesc: "Suitable for fine strand conductors",
    label: "EF007804: Suitable for fine strand conductors"
  },
  {
    featureId: "EF007805",
    featureDesc: "Suitable for flat conductors",
    label: "EF007805: Suitable for flat conductors"
  },
  {
    featureId: "EF007806",
    featureDesc: "Suitable for circuit integrity",
    label: "EF007806: Suitable for circuit integrity"
  },
  {
    featureId: "EF007807",
    featureDesc: "Suitable for nominal thread size inch NPT/gas pipe thread",
    label: "EF007807: Suitable for nominal thread size inch NPT/gas pipe thread"
  },
  {
    featureId: "EF007808",
    featureDesc: "Suitable for nominal thread metric/PG",
    label: "EF007808: Suitable for nominal thread metric/PG"
  },
  {
    featureId: "EF007809",
    featureDesc: "Suitable for gypsum fibre panels",
    label: "EF007809: Suitable for gypsum fibre panels"
  },
  {
    featureId: "EF007810",
    featureDesc: "Suitable for gypsum-cardboard",
    label: "EF007810: Suitable for gypsum-cardboard"
  },
  {
    featureId: "EF007811",
    featureDesc: "Suitable for cast iron",
    label: "EF007811: Suitable for cast iron"
  },
  {
    featureId: "EF007812",
    featureDesc: "Suitable for high temperatures (to 650 °C)",
    label: "EF007812: Suitable for high temperatures (to 650 °C)"
  },
  {
    featureId: "EF007813",
    featureDesc: "Suitable for wood",
    label: "EF007813: Suitable for wood"
  },
  {
    featureId: "EF007814",
    featureDesc: "Suitable for duct width",
    label: "EF007814: Suitable for duct width"
  },
  {
    featureId: "EF007815",
    featureDesc: "Suitable for insulated varnish",
    label: "EF007815: Suitable for insulated varnish"
  },
  {
    featureId: "EF007816",
    featureDesc: "Suitable for solid conductors",
    label: "EF007816: Suitable for solid conductors"
  },
  {
    featureId: "EF007817",
    featureDesc: "Suitable for max. bolt diameter",
    label: "EF007817: Suitable for max. bolt diameter"
  },
  {
    featureId: "EF007818",
    featureDesc: "Suitable for stranded conductors",
    label: "EF007818: Suitable for stranded conductors"
  },
  {
    featureId: "EF007819",
    featureDesc: "Suitable for brass",
    label: "EF007819: Suitable for brass"
  },
  {
    featureId: "EF007820",
    featureDesc: "Suitable for round conductors",
    label: "EF007820: Suitable for round conductors"
  },
  {
    featureId: "EF007821",
    featureDesc: "Suitable in fire resistance areas",
    label: "EF007821: Suitable in fire resistance areas"
  },
  {
    featureId: "EF007822",
    featureDesc: "Suitable for cutting of mesh cable trays",
    label: "EF007822: Suitable for cutting of mesh cable trays"
  },
  {
    featureId: "EF007823",
    featureDesc: "Primary colour housing",
    label: "EF007823: Primary colour housing"
  },
  {
    featureId: "EF007824",
    featureDesc: "Crossed",
    label: "EF007824: Crossed"
  },
  {
    featureId: "EF007825",
    featureDesc: "Slanted supporting table",
    label: "EF007825: Slanted supporting table"
  },
  {
    featureId: "EF007826",
    featureDesc: "Device fitting",
    label: "EF007826: Device fitting"
  },
  {
    featureId: "EF007828",
    featureDesc: "Noise attenuation",
    label: "EF007828: Noise attenuation"
  },
  {
    featureId: "EF007829",
    featureDesc: "Noise emission",
    label: "EF007829: Noise emission"
  },
  {
    featureId: "EF007833",
    featureDesc: "Beverage dispenser",
    label: "EF007833: Beverage dispenser"
  },
  {
    featureId: "EF007834",
    featureDesc: "Thread connection size",
    label: "EF007834: Thread connection size"
  },
  {
    featureId: "EF007835",
    featureDesc: "Thread size punch side",
    label: "EF007835: Thread size punch side"
  },
  {
    featureId: "EF007836",
    featureDesc: "Thread size actuator side",
    label: "EF007836: Thread size actuator side"
  },
  {
    featureId: "EF007837",
    featureDesc: "Thread size PG",
    label: "EF007837: Thread size PG"
  },
  {
    featureId: "EF007838",
    featureDesc: "Nominal thread size inch/gas pipe thread",
    label: "EF007838: Nominal thread size inch/gas pipe thread"
  },
  {
    featureId: "EF007839",
    featureDesc: "Nominal thread size metric/PG",
    label: "EF007839: Nominal thread size metric/PG"
  },
  {
    featureId: "EF007840",
    featureDesc: "Detect toxic gas",
    label: "EF007840: Detect toxic gas"
  },
  {
    featureId: "EF007841",
    featureDesc: "Grip design",
    label: "EF007841: Grip design"
  },
  {
    featureId: "EF007842",
    featureDesc: "Basic colour blind",
    label: "EF007842: Basic colour blind"
  },
  {
    featureId: "EF007843",
    featureDesc: "Basic colour front",
    label: "EF007843: Basic colour front"
  },
  {
    featureId: "EF007844",
    featureDesc: "Basic colour door",
    label: "EF007844: Basic colour door"
  },
  {
    featureId: "EF007845",
    featureDesc: "Basic colour frame",
    label: "EF007845: Basic colour frame"
  },
  {
    featureId: "EF007847",
    featureDesc: "Main lamp",
    label: "EF007847: Main lamp"
  },
  {
    featureId: "EF007848",
    featureDesc: "Main switch rated current",
    label: "EF007848: Main switch rated current"
  },
  {
    featureId: "EF007849",
    featureDesc: "Skin care system",
    label: "EF007849: Skin care system"
  },
  {
    featureId: "EF007851",
    featureDesc: "HDMI-connection",
    label: "EF007851: HDMI-connection"
  },
  {
    featureId: "EF007853",
    featureDesc: "HF loop-through output",
    label: "EF007853: HF loop-through output"
  },
  {
    featureId: "EF007854",
    featureDesc: "HF modulator",
    label: "EF007854: HF modulator"
  },
  {
    featureId: "EF007856",
    featureDesc: "High conducting inner coating",
    label: "EF007856: High conducting inner coating"
  },
  {
    featureId: "EF007857",
    featureDesc: "High-voltage-proof insulated model",
    label: "EF007857: High-voltage-proof insulated model"
  },
  {
    featureId: "EF007859",
    featureDesc: "Height installation duct",
    label: "EF007859: Height installation duct"
  },
  {
    featureId: "EF007860",
    featureDesc: "Height side joist",
    label: "EF007860: Height side joist"
  },
  {
    featureId: "EF007861",
    featureDesc: "Height corner duct",
    label: "EF007861: Height corner duct"
  },
  {
    featureId: "EF007863",
    featureDesc: "Height duct connection",
    label: "EF007863: Height duct connection"
  },
  {
    featureId: "EF007864",
    featureDesc: "Handset colour",
    label: "EF007864: Handset colour"
  },
  {
    featureId: "EF007865",
    featureDesc: "Handset cord",
    label: "EF007865: Handset cord"
  },
  {
    featureId: "EF007866",
    featureDesc: "Sleeve form",
    label: "EF007866: Sleeve form"
  },
  {
    featureId: "EF007868",
    featureDesc: "Suitable for induction",
    label: "EF007868: Suitable for induction"
  },
  {
    featureId: "EF007870",
    featureDesc: "Inhale function",
    label: "EF007870: Inhale function"
  },
  {
    featureId: "EF007871",
    featureDesc: "Volume can/cartouche",
    label: "EF007871: Volume can/cartouche"
  },
  {
    featureId: "EF007872",
    featureDesc: "Volume bin/bag",
    label: "EF007872: Volume bin/bag"
  },
  {
    featureId: "EF007873",
    featureDesc: "Inner diameter sleeve",
    label: "EF007873: Inner diameter sleeve"
  },
  {
    featureId: "EF007874",
    featureDesc: "Inner diameter finger after shrink",
    label: "EF007874: Inner diameter finger after shrink"
  },
  {
    featureId: "EF007875",
    featureDesc: "Inner diameter finger before shrink",
    label: "EF007875: Inner diameter finger before shrink"
  },
  {
    featureId: "EF007876",
    featureDesc: "Integrated dial device",
    label: "EF007876: Integrated dial device"
  },
  {
    featureId: "EF007879",
    featureDesc: "Cable coil up",
    label: "EF007879: Cable coil up"
  },
  {
    featureId: "EF007881",
    featureDesc: "Cable type connector connection 2",
    label: "EF007881: Cable type connector connection 2"
  },
  {
    featureId: "EF007883",
    featureDesc: "Duct width inward",
    label: "EF007883: Duct width inward"
  },
  {
    featureId: "EF007884",
    featureDesc: "Duct width",
    label: "EF007884: Duct width"
  },
  {
    featureId: "EF007885",
    featureDesc: "Duct height inward",
    label: "EF007885: Duct height inward"
  },
  {
    featureId: "EF007886",
    featureDesc: "Map representation",
    label: "EF007886: Map representation"
  },
  {
    featureId: "EF007887",
    featureDesc: "Lowest bending radius horizontal",
    label: "EF007887: Lowest bending radius horizontal"
  },
  {
    featureId: "EF007888",
    featureDesc: "Lowest bending radius vertical",
    label: "EF007888: Lowest bending radius vertical"
  },
  {
    featureId: "EF007889",
    featureDesc: "Clamp position fixed",
    label: "EF007889: Clamp position fixed"
  },
  {
    featureId: "EF007890",
    featureDesc: "Encodable",
    label: "EF007890: Encodable"
  },
  {
    featureId: "EF007892",
    featureDesc: "Head model",
    label: "EF007892: Head model"
  },
  {
    featureId: "EF007893",
    featureDesc: "Water mass analysis",
    label: "EF007893: Water mass analysis"
  },
  {
    featureId: "EF007894",
    featureDesc: "Creepage path",
    label: "EF007894: Creepage path"
  },
  {
    featureId: "EF007895",
    featureDesc: "Crumb drawer",
    label: "EF007895: Crumb drawer"
  },
  {
    featureId: "EF007896",
    featureDesc: "Type of cooling",
    label: "EF007896: Type of cooling"
  },
  {
    featureId: "EF007900",
    featureDesc: "Lamp wattage main lamp",
    label: "EF007900: Lamp wattage main lamp"
  },
  {
    featureId: "EF007901",
    featureDesc: "Lamp wattage auxiliary lamp",
    label: "EF007901: Lamp wattage auxiliary lamp"
  },
  {
    featureId: "EF007902",
    featureDesc: "Cover plate length",
    label: "EF007902: Cover plate length"
  },
  {
    featureId: "EF007906",
    featureDesc: "Length suitable for",
    label: "EF007906: Length suitable for"
  },
  {
    featureId: "EF007907",
    featureDesc: "Length suction hose",
    label: "EF007907: Length suction hose"
  },
  {
    featureId: "EF007908",
    featureDesc: "Length distributor",
    label: "EF007908: Length distributor"
  },
  {
    featureId: "EF007909",
    featureDesc: "Lengthwise slotted",
    label: "EF007909: Lengthwise slotted"
  },
  {
    featureId: "EF007910",
    featureDesc: "Power boost suitable",
    label: "EF007910: Power boost suitable"
  },
  {
    featureId: "EF007912",
    featureDesc: "Nominal cross section conductor branch cable",
    label: "EF007912: Nominal cross section conductor branch cable"
  },
  {
    featureId: "EF007913",
    featureDesc: "Nominal cross section conductor main cable",
    label: "EF007913: Nominal cross section conductor main cable"
  },
  {
    featureId: "EF007914",
    featureDesc: "Nominal cross section conductor",
    label: "EF007914: Nominal cross section conductor"
  },
  {
    featureId: "EF007917",
    featureDesc: "Luminaire heads flexible",
    label: "EF007917: Luminaire heads flexible"
  },
  {
    featureId: "EF007918",
    featureDesc: "Puncher sizes ISO",
    label: "EF007918: Puncher sizes ISO"
  },
  {
    featureId: "EF007919",
    featureDesc: "Puncher sizes PG",
    label: "EF007919: Puncher sizes PG"
  },
  {
    featureId: "EF007920",
    featureDesc: "Soldering tip replaceable",
    label: "EF007920: Soldering tip replaceable"
  },
  {
    featureId: "EF007921",
    featureDesc: "LSA",
    label: "EF007921: LSA"
  },
  {
    featureId: "EF007922",
    featureDesc: "Material thickness cover",
    label: "EF007922: Material thickness cover"
  },
  {
    featureId: "EF007923",
    featureDesc: "Max. number of glasses 1 litre",
    label: "EF007923: Max. number of glasses 1 litre"
  },
  {
    featureId: "EF007924",
    featureDesc: "Max. number of system sensors",
    label: "EF007924: Max. number of system sensors"
  },
  {
    featureId: "EF007925",
    featureDesc: "Max. number of compartments",
    label: "EF007925: Max. number of compartments"
  },
  {
    featureId: "EF007926",
    featureDesc: "Max. rated current",
    label: "EF007926: Max. rated current"
  },
  {
    featureId: "EF007928",
    featureDesc: "Max. foil width",
    label: "EF007928: Max. foil width"
  },
  {
    featureId: "EF007929",
    featureDesc: "Max. yogurt quantity",
    label: "EF007929: Max. yogurt quantity"
  },
  {
    featureId: "EF007930",
    featureDesc: "Knife diameter",
    label: "EF007930: Knife diameter"
  },
  {
    featureId: "EF007931",
    featureDesc: "Microwave",
    label: "EF007931: Microwave"
  },
  {
    featureId: "EF007932",
    featureDesc: "MIL specified",
    label: "EF007932: MIL specified"
  },
  {
    featureId: "EF007933",
    featureDesc: "MIL approval",
    label: "EF007933: MIL approval"
  },
  {
    featureId: "EF007935",
    featureDesc: "Min. height finishing layer",
    label: "EF007935: Min. height finishing layer"
  },
  {
    featureId: "EF007936",
    featureDesc: "Min. holding force",
    label: "EF007936: Min. holding force"
  },
  {
    featureId: "EF007938",
    featureDesc: "With strip function",
    label: "EF007938: With strip function"
  },
  {
    featureId: "EF007939",
    featureDesc: "With impact protection",
    label: "EF007939: With impact protection"
  },
  {
    featureId: "EF007941",
    featureDesc: "With automatic return",
    label: "EF007941: With automatic return"
  },
  {
    featureId: "EF007942",
    featureDesc: "With space for gear",
    label: "EF007942: With space for gear"
  },
  {
    featureId: "EF007944",
    featureDesc: "With bottom perforation",
    label: "EF007944: With bottom perforation"
  },
  {
    featureId: "EF007945",
    featureDesc: "With busbar",
    label: "EF007945: With busbar"
  },
  {
    featureId: "EF007946",
    featureDesc: "With DVD",
    label: "EF007946: With DVD"
  },
  {
    featureId: "EF007947",
    featureDesc: "With remote switch connection",
    label: "EF007947: With remote switch connection"
  },
  {
    featureId: "EF007949",
    featureDesc: "With fixed symbol/imprint",
    label: "EF007949: With fixed symbol/imprint"
  },
  {
    featureId: "EF007950",
    featureDesc: "With glass disc",
    label: "EF007950: With glass disc"
  },
  {
    featureId: "EF007951",
    featureDesc: "With inner glue",
    label: "EF007951: With inner glue"
  },
  {
    featureId: "EF007952",
    featureDesc: "With integrated holder",
    label: "EF007952: With integrated holder"
  },
  {
    featureId: "EF007953",
    featureDesc: "With duct entry",
    label: "EF007953: With duct entry"
  },
  {
    featureId: "EF007954",
    featureDesc: "With identification flag",
    label: "EF007954: With identification flag"
  },
  {
    featureId: "EF007955",
    featureDesc: "With couplers",
    label: "EF007955: With couplers"
  },
  {
    featureId: "EF007956",
    featureDesc: "With laser",
    label: "EF007956: With laser"
  },
  {
    featureId: "EF007957",
    featureDesc: "Equipped with switch disconnector",
    label: "EF007957: Equipped with switch disconnector"
  },
  {
    featureId: "EF007958",
    featureDesc: "Equipped with power circuit-breaker",
    label: "EF007958: Equipped with power circuit-breaker"
  },
  {
    featureId: "EF007959",
    featureDesc: "With mounting bracket",
    label: "EF007959: With mounting bracket"
  },
  {
    featureId: "EF007960",
    featureDesc: "With rodent protection",
    label: "EF007960: With rodent protection"
  },
  {
    featureId: "EF007962",
    featureDesc: "With N-PE clamp",
    label: "EF007962: With N-PE clamp"
  },
  {
    featureId: "EF007963",
    featureDesc: "With N-rail",
    label: "EF007963: With N-rail"
  },
  {
    featureId: "EF007964",
    featureDesc: "Equipped with no-voltage switch",
    label: "EF007964: Equipped with no-voltage switch"
  },
  {
    featureId: "EF007965",
    featureDesc: "With cover",
    label: "EF007965: With cover"
  },
  {
    featureId: "EF007966",
    featureDesc: "With paper cover",
    label: "EF007966: With paper cover"
  },
  {
    featureId: "EF007967",
    featureDesc: "With PE-rail",
    label: "EF007967: With PE-rail"
  },
  {
    featureId: "EF007968",
    featureDesc: "With potential equalization",
    label: "EF007968: With potential equalization"
  },
  {
    featureId: "EF007970",
    featureDesc: "With sight-hole",
    label: "EF007970: With sight-hole"
  },
  {
    featureId: "EF007971",
    featureDesc: "With interlock device",
    label: "EF007971: With interlock device"
  },
  {
    featureId: "EF007972",
    featureDesc: "With keyboard",
    label: "EF007972: With keyboard"
  },
  {
    featureId: "EF007973",
    featureDesc: "With partition panel",
    label: "EF007973: With partition panel"
  },
  {
    featureId: "EF007974",
    featureDesc: "Equipped with reverser",
    label: "EF007974: Equipped with reverser"
  },
  {
    featureId: "EF007975",
    featureDesc: "With local operation",
    label: "EF007975: With local operation"
  },
  {
    featureId: "EF007976",
    featureDesc: "With accessories",
    label: "EF007976: With accessories"
  },
  {
    featureId: "EF007977",
    featureDesc: "Modular",
    label: "EF007977: Modular"
  },
  {
    featureId: "EF007978",
    featureDesc: "Mounting alongside enclosure",
    label: "EF007978: Mounting alongside enclosure"
  },
  {
    featureId: "EF007979",
    featureDesc: "Mounting over enclosure",
    label: "EF007979: Mounting over enclosure"
  },
  {
    featureId: "EF007980",
    featureDesc: "Mounting under enclosure",
    label: "EF007980: Mounting under enclosure"
  },
  {
    featureId: "EF007981",
    featureDesc: "Mounting method duct",
    label: "EF007981: Mounting method duct"
  },
  {
    featureId: "EF007982",
    featureDesc: "According to DIN",
    label: "EF007982: According to DIN"
  },
  {
    featureId: "EF007984",
    featureDesc: "Approximate value for the energy consumption/year",
    label: "EF007984: Approximate value for the energy consumption/year"
  },
  {
    featureId: "EF007985",
    featureDesc: "Auxiliary lamp",
    label: "EF007985: Auxiliary lamp"
  },
  {
    featureId: "EF007986",
    featureDesc: "Nominal service time",
    label: "EF007986: Nominal service time"
  },
  {
    featureId: "EF007987",
    featureDesc: "Nominal pressure",
    label: "EF007987: Nominal pressure"
  },
  {
    featureId: "EF007988",
    featureDesc: "Nominal diameter inch",
    label: "EF007988: Nominal diameter inch"
  },
  {
    featureId: "EF007989",
    featureDesc: "Nominal height",
    label: "EF007989: Nominal height"
  },
  {
    featureId: "EF007991",
    featureDesc: "Nominal cross section connection cable",
    label: "EF007991: Nominal cross section connection cable"
  },
  {
    featureId: "EF007995",
    featureDesc: "Nominal voltage high value",
    label: "EF007995: Nominal voltage high value"
  },
  {
    featureId: "EF007996",
    featureDesc: "Nominal voltage low value",
    label: "EF007996: Nominal voltage low value"
  },
  {
    featureId: "EF007997",
    featureDesc: "Mains switch",
    label: "EF007997: Mains switch"
  },
  {
    featureId: "EF007998",
    featureDesc: "Non-isolated cable connections",
    label: "EF007998: Non-isolated cable connections"
  },
  {
    featureId: "EF007999",
    featureDesc: "Levelling height",
    label: "EF007999: Levelling height"
  },
  {
    featureId: "EF008002",
    featureDesc: "Net capacity",
    label: "EF008002: Net capacity"
  },
  {
    featureId: "EF008004",
    featureDesc: "Net capacity fridge compartment",
    label: "EF008004: Net capacity fridge compartment"
  },
  {
    featureId: "EF008007",
    featureDesc: "Cover surface",
    label: "EF008007: Cover surface"
  },
  {
    featureId: "EF008008",
    featureDesc: "Surface side wall/bottom duct",
    label: "EF008008: Surface side wall/bottom duct"
  },
  {
    featureId: "EF008009",
    featureDesc: "Cover detachable",
    label: "EF008009: Cover detachable"
  },
  {
    featureId: "EF008010",
    featureDesc: "Open (mountable afterwards)",
    label: "EF008010: Open (mountable afterwards)"
  },
  {
    featureId: "EF008011",
    featureDesc: "Optimum mounting height",
    label: "EF008011: Optimum mounting height"
  },
  {
    featureId: "EF008013",
    featureDesc: "Suitable for thread size PG",
    label: "EF008013: Suitable for thread size PG"
  },
  {
    featureId: "EF008014",
    featureDesc: "Permanent refillable",
    label: "EF008014: Permanent refillable"
  },
  {
    featureId: "EF008015",
    featureDesc: "Plate thickness/product length",
    label: "EF008015: Plate thickness/product length"
  },
  {
    featureId: "EF008016",
    featureDesc: "Number of poles of the clamp",
    label: "EF008016: Number of poles of the clamp"
  },
  {
    featureId: "EF008017",
    featureDesc: "Dose selector switch",
    label: "EF008017: Dose selector switch"
  },
  {
    featureId: "EF008023",
    featureDesc: "Programming lock",
    label: "EF008023: Programming lock"
  },
  {
    featureId: "EF008024",
    featureDesc: "Cross section form",
    label: "EF008024: Cross section form"
  },
  {
    featureId: "EF008025",
    featureDesc: "RAL-number column",
    label: "EF008025: RAL-number column"
  },
  {
    featureId: "EF008026",
    featureDesc: "RAL-number cover",
    label: "EF008026: RAL-number cover"
  },
  {
    featureId: "EF008027",
    featureDesc: "Low smoke (according to EN 61034-2)",
    label: "EF008027: Low smoke (according to EN 61034-2)"
  },
  {
    featureId: "EF008028",
    featureDesc: "Noise figure",
    label: "EF008028: Noise figure"
  },
  {
    featureId: "EF008029",
    featureDesc: "Reduced Al nominal cross section",
    label: "EF008029: Reduced Al nominal cross section"
  },
  {
    featureId: "EF008030",
    featureDesc: "Row distance",
    label: "EF008030: Row distance"
  },
  {
    featureId: "EF008036",
    featureDesc: "Specific call tone",
    label: "EF008036: Specific call tone"
  },
  {
    featureId: "EF008037",
    featureDesc: "Switchable",
    label: "EF008037: Switchable"
  },
  {
    featureId: "EF008038",
    featureDesc: "Engaging indication",
    label: "EF008038: Engaging indication"
  },
  {
    featureId: "EF008039",
    featureDesc: "Leg width",
    label: "EF008039: Leg width"
  },
  {
    featureId: "EF008040",
    featureDesc: "Leg length",
    label: "EF008040: Leg length"
  },
  {
    featureId: "EF008041",
    featureDesc: "Screen diameter",
    label: "EF008041: Screen diameter"
  },
  {
    featureId: "EF008042",
    featureDesc: "Hose length",
    label: "EF008042: Hose length"
  },
  {
    featureId: "EF008043",
    featureDesc: "Suitable for cable guide chain",
    label: "EF008043: Suitable for cable guide chain"
  },
  {
    featureId: "EF008044",
    featureDesc: "Slender flange model",
    label: "EF008044: Slender flange model"
  },
  {
    featureId: "EF008045",
    featureDesc: "Cutting jaws exchangeable",
    label: "EF008045: Cutting jaws exchangeable"
  },
  {
    featureId: "EF008046",
    featureDesc: "Cutting blade integrated",
    label: "EF008046: Cutting blade integrated"
  },
  {
    featureId: "EF008047",
    featureDesc: "Cutting blade exchangeable",
    label: "EF008047: Cutting blade exchangeable"
  },
  {
    featureId: "EF008048",
    featureDesc: "Shrink rate",
    label: "EF008048: Shrink rate"
  },
  {
    featureId: "EF008049",
    featureDesc: "Voltage protection level core/signal ground",
    label: "EF008049: Voltage protection level core/signal ground"
  },
  {
    featureId: "EF008050",
    featureDesc: "Voltage protection level signal ground/protective ground",
    label: "EF008050: Voltage protection level signal ground/protective ground"
  },
  {
    featureId: "EF008051",
    featureDesc: "Self cleaning",
    label: "EF008051: Self cleaning"
  },
  {
    featureId: "EF008052",
    featureDesc: "Self sticking/amalgamating",
    label: "EF008052: Self sticking/amalgamating"
  },
  {
    featureId: "EF008055",
    featureDesc: "Voltage reversible",
    label: "EF008055: Voltage reversible"
  },
  {
    featureId: "EF008058",
    featureDesc: "Special tool necessary",
    label: "EF008058: Special tool necessary"
  },
  {
    featureId: "EF008059",
    featureDesc: "Suitable for dishwasher use",
    label: "EF008059: Suitable for dishwasher use"
  },
  {
    featureId: "EF008060",
    featureDesc: "Punch force",
    label: "EF008060: Punch force"
  },
  {
    featureId: "EF008061",
    featureDesc: "Dust bag use",
    label: "EF008061: Dust bag use"
  },
  {
    featureId: "EF008062",
    featureDesc: "Connector connection 1",
    label: "EF008062: Connector connection 1"
  },
  {
    featureId: "EF008063",
    featureDesc: "Connector connection 2",
    label: "EF008063: Connector connection 2"
  },
  {
    featureId: "EF008064",
    featureDesc: "Connector housing",
    label: "EF008064: Connector housing"
  },
  {
    featureId: "EF008065",
    featureDesc: "Case connector connection 1",
    label: "EF008065: Case connector connection 1"
  },
  {
    featureId: "EF008066",
    featureDesc: "Case connector connection 2",
    label: "EF008066: Case connector connection 2"
  },
  {
    featureId: "EF008067",
    featureDesc: "Connector system system-oriented",
    label: "EF008067: Connector system system-oriented"
  },
  {
    featureId: "EF008068",
    featureDesc: "Type of connector external",
    label: "EF008068: Type of connector external"
  },
  {
    featureId: "EF008069",
    featureDesc: "Type of connector interior",
    label: "EF008069: Type of connector interior"
  },
  {
    featureId: "EF008071",
    featureDesc: "Control voltage type auxiliary equipment",
    label: "EF008071: Control voltage type auxiliary equipment"
  },
  {
    featureId: "EF008072",
    featureDesc: "Detect embroidering gas",
    label: "EF008072: Detect embroidering gas"
  },
  {
    featureId: "EF008073",
    featureDesc: "St nominal cross section",
    label: "EF008073: St nominal cross section"
  },
  {
    featureId: "EF008074",
    featureDesc: "Current surge function selectable",
    label: "EF008074: Current surge function selectable"
  },
  {
    featureId: "EF008075",
    featureDesc: "Step less temperature preselection",
    label: "EF008075: Step less temperature preselection"
  },
  {
    featureId: "EF008076",
    featureDesc: "Nozzle",
    label: "EF008076: Nozzle"
  },
  {
    featureId: "EF008077",
    featureDesc: "S-Video output",
    label: "EF008077: S-Video output"
  },
  {
    featureId: "EF008078",
    featureDesc: "Number of cups",
    label: "EF008078: Number of cups"
  },
  {
    featureId: "EF008079",
    featureDesc: "Depth grill surface",
    label: "EF008079: Depth grill surface"
  },
  {
    featureId: "EF008080",
    featureDesc: "Depth of the tread",
    label: "EF008080: Depth of the tread"
  },
  {
    featureId: "EF008081",
    featureDesc: "Width crucible",
    label: "EF008081: Width crucible"
  },
  {
    featureId: "EF008082",
    featureDesc: "Length crucible",
    label: "EF008082: Length crucible"
  },
  {
    featureId: "EF008083",
    featureDesc: "Depth crucible",
    label: "EF008083: Depth crucible"
  },
  {
    featureId: "EF008084",
    featureDesc: "Timer function",
    label: "EF008084: Timer function"
  },
  {
    featureId: "EF008085",
    featureDesc: "Table foot",
    label: "EF008085: Table foot"
  },
  {
    featureId: "EF008086",
    featureDesc: "Touch function",
    label: "EF008086: Touch function"
  },
  {
    featureId: "EF008087",
    featureDesc: "Touchscreen operation",
    label: "EF008087: Touchscreen operation"
  },
  {
    featureId: "EF008088",
    featureDesc: "Partition fixing",
    label: "EF008088: Partition fixing"
  },
  {
    featureId: "EF008089",
    featureDesc: "Partitions possible",
    label: "EF008089: Partitions possible"
  },
  {
    featureId: "EF008090",
    featureDesc: "Transmission technique",
    label: "EF008090: Transmission technique"
  },
  {
    featureId: "EF008091",
    featureDesc: "Monitoring equipment",
    label: "EF008091: Monitoring equipment"
  },
  {
    featureId: "EF008092",
    featureDesc: "Ultra sonic function",
    label: "EF008092: Ultra sonic function"
  },
  {
    featureId: "EF008093",
    featureDesc: "UL approval",
    label: "EF008093: UL approval"
  },
  {
    featureId: "EF008095",
    featureDesc: "Air circulation",
    label: "EF008095: Air circulation"
  },
  {
    featureId: "EF008096",
    featureDesc: "Sheathing",
    label: "EF008096: Sheathing"
  },
  {
    featureId: "EF008098",
    featureDesc: "Flush-mounted installation/built-in installation",
    label: "EF008098: Flush-mounted installation/built-in installation"
  },
  {
    featureId: "EF008099",
    featureDesc: "UV-stable according to ASTM D6779",
    label: "EF008099: UV-stable according to ASTM D6779"
  },
  {
    featureId: "EF008103",
    featureDesc: "Networkable via radiografic (standard)",
    label: "EF008103: Networkable via radiografic (standard)"
  },
  {
    featureId: "EF008104",
    featureDesc: "Networkable via cable",
    label: "EF008104: Networkable via cable"
  },
  {
    featureId: "EF008105",
    featureDesc: "Closable (two-part)",
    label: "EF008105: Closable (two-part)"
  },
  {
    featureId: "EF008106",
    featureDesc: "Gain",
    label: "EF008106: Gain"
  },
  {
    featureId: "EF008107",
    featureDesc: "VG approval",
    label: "EF008107: VG approval"
  },
  {
    featureId: "EF008109",
    featureDesc: "Whole grain program",
    label: "EF008109: Whole grain program"
  },
  {
    featureId: "EF008110",
    featureDesc: "Prewired",
    label: "EF008110: Prewired"
  },
  {
    featureId: "EF008116",
    featureDesc: "Additional interfaces",
    label: "EF008116: Additional interfaces"
  },
  {
    featureId: "EF008117",
    featureDesc: "Filament width",
    label: "EF008117: Filament width"
  },
  {
    featureId: "EF008118",
    featureDesc: "Material insulation",
    label: "EF008118: Material insulation"
  },
  {
    featureId: "EF008119",
    featureDesc: "Material blade",
    label: "EF008119: Material blade"
  },
  {
    featureId: "EF008120",
    featureDesc: "Material cooking plate",
    label: "EF008120: Material cooking plate"
  },
  {
    featureId: "EF008121",
    featureDesc: "Material mixing bowl",
    label: "EF008121: Material mixing bowl"
  },
  {
    featureId: "EF008122",
    featureDesc: "Material screw gland",
    label: "EF008122: Material screw gland"
  },
  {
    featureId: "EF008123",
    featureDesc: "Material case",
    label: "EF008123: Material case"
  },
  {
    featureId: "EF008125",
    featureDesc: "Material blender shaft",
    label: "EF008125: Material blender shaft"
  },
  {
    featureId: "EF008126",
    featureDesc: "Material connector housing",
    label: "EF008126: Material connector housing"
  },
  {
    featureId: "EF008127",
    featureDesc: "Material connector housing, connection 1",
    label: "EF008127: Material connector housing, connection 1"
  },
  {
    featureId: "EF008128",
    featureDesc: "Material connector housing, connection 2",
    label: "EF008128: Material connector housing, connection 2"
  },
  {
    featureId: "EF008129",
    featureDesc: "Material tool",
    label: "EF008129: Material tool"
  },
  {
    featureId: "EF008130",
    featureDesc: "Material holder",
    label: "EF008130: Material holder"
  },
  {
    featureId: "EF008131",
    featureDesc: "Material installation column halogen free",
    label: "EF008131: Material installation column halogen free"
  },
  {
    featureId: "EF008132",
    featureDesc: "Material cover halogen free",
    label: "EF008132: Material cover halogen free"
  },
  {
    featureId: "EF008133",
    featureDesc: "Material side wall",
    label: "EF008133: Material side wall"
  },
  {
    featureId: "EF008134",
    featureDesc: "Material mirror",
    label: "EF008134: Material mirror"
  },
  {
    featureId: "EF008136",
    featureDesc: "Material quality installation column",
    label: "EF008136: Material quality installation column"
  },
  {
    featureId: "EF008137",
    featureDesc: "Material quality cover",
    label: "EF008137: Material quality cover"
  },
  {
    featureId: "EF008138",
    featureDesc: "Angle desoldering insert",
    label: "EF008138: Angle desoldering insert"
  },
  {
    featureId: "EF008139",
    featureDesc: "Top end diameter, luminaire side",
    label: "EF008139: Top end diameter, luminaire side"
  },
  {
    featureId: "EF008140",
    featureDesc: "Top end diameter, pole side",
    label: "EF008140: Top end diameter, pole side"
  },
  {
    featureId: "EF008141",
    featureDesc: "Accessories required",
    label: "EF008141: Accessories required"
  },
  {
    featureId: "EF008142",
    featureDesc: "Approved in combination with protective conduit according to DIN EN 61386-23",
    label: "EF008142: Approved in combination with protective conduit according to DIN EN 61386-23"
  },
  {
    featureId: "EF008143",
    featureDesc: "Approved according to",
    label: "EF008143: Approved according to"
  },
  {
    featureId: "EF008144",
    featureDesc: "Approved according to DIN EN 61386-23",
    label: "EF008144: Approved according to DIN EN 61386-23"
  },
  {
    featureId: "EF008145",
    featureDesc: "Approved according to NF F 16-101",
    label: "EF008145: Approved according to NF F 16-101"
  },
  {
    featureId: "EF008147",
    featureDesc: "Additional equipment integrated",
    label: "EF008147: Additional equipment integrated"
  },
  {
    featureId: "EF008148",
    featureDesc: "Additional equipment possible",
    label: "EF008148: Additional equipment possible"
  },
  {
    featureId: "EF008149",
    featureDesc: "Additional equipment attached at delivery",
    label: "EF008149: Additional equipment attached at delivery"
  },
  {
    featureId: "EF008150",
    featureDesc: "Mounting perforation",
    label: "EF008150: Mounting perforation"
  },
  {
    featureId: "EF008153",
    featureDesc: "Angle",
    label: "EF008153: Angle"
  },
  {
    featureId: "EF008154",
    featureDesc: "Type of interior lighting",
    label: "EF008154: Type of interior lighting"
  },
  {
    featureId: "EF008156",
    featureDesc: "Expansion possibility",
    label: "EF008156: Expansion possibility"
  },
  {
    featureId: "EF008157",
    featureDesc: "Beam angle",
    label: "EF008157: Beam angle"
  },
  {
    featureId: "EF008158",
    featureDesc: "Hole diameter",
    label: "EF008158: Hole diameter"
  },
  {
    featureId: "EF008159",
    featureDesc: "Connection groundwater circuit",
    label: "EF008159: Connection groundwater circuit"
  },
  {
    featureId: "EF008160",
    featureDesc: "Band width",
    label: "EF008160: Band width"
  },
  {
    featureId: "EF008163",
    featureDesc: "Cooling performance",
    label: "EF008163: Cooling performance"
  },
  {
    featureId: "EF008170",
    featureDesc: "Control voltage DC",
    label: "EF008170: Control voltage DC"
  },
  {
    featureId: "EF008171",
    featureDesc: "Air power",
    label: "EF008171: Air power"
  },
  {
    featureId: "EF008172",
    featureDesc: "Vacuum",
    label: "EF008172: Vacuum"
  },
  {
    featureId: "EF008173",
    featureDesc: "Suitable for central vacuum systems",
    label: "EF008173: Suitable for central vacuum systems"
  },
  {
    featureId: "EF008175",
    featureDesc: "With striker",
    label: "EF008175: With striker"
  },
  {
    featureId: "EF008176",
    featureDesc: "Flicker flame",
    label: "EF008176: Flicker flame"
  },
  {
    featureId: "EF008178",
    featureDesc: "Suitable for planes",
    label: "EF008178: Suitable for planes"
  },
  {
    featureId: "EF008179",
    featureDesc: "Suitable for forklifts",
    label: "EF008179: Suitable for forklifts"
  },
  {
    featureId: "EF008181",
    featureDesc: "With optical defect indication",
    label: "EF008181: With optical defect indication"
  },
  {
    featureId: "EF008182",
    featureDesc: "Basic element without cover",
    label: "EF008182: Basic element without cover"
  },
  {
    featureId: "EF008185",
    featureDesc: "Rung centre spacing",
    label: "EF008185: Rung centre spacing"
  },
  {
    featureId: "EF008186",
    featureDesc: "With shock protection",
    label: "EF008186: With shock protection"
  },
  {
    featureId: "EF008187",
    featureDesc: "Initial value of the undelayed short-circuit release - setting range",
    label: "EF008187: Initial value of the undelayed short-circuit release - setting range"
  },
  {
    featureId: "EF008188",
    featureDesc: "End value adjustment range undelayed short-circuit release",
    label: "EF008188: End value adjustment range undelayed short-circuit release"
  },
  {
    featureId: "EF008189",
    featureDesc: "Max. permitted delay-on energization time",
    label: "EF008189: Max. permitted delay-on energization time"
  },
  {
    featureId: "EF008190",
    featureDesc: "Min. adjustable delay-on energization time",
    label: "EF008190: Min. adjustable delay-on energization time"
  },
  {
    featureId: "EF008191",
    featureDesc: "Max. permitted off-delay time",
    label: "EF008191: Max. permitted off-delay time"
  },
  {
    featureId: "EF008192",
    featureDesc: "Min. adjustable off-delay time",
    label: "EF008192: Min. adjustable off-delay time"
  },
  {
    featureId: "EF008193",
    featureDesc: "Response value voltage",
    label: "EF008193: Response value voltage"
  },
  {
    featureId: "EF008194",
    featureDesc: "Max. permitted operate delay time",
    label: "EF008194: Max. permitted operate delay time"
  },
  {
    featureId: "EF008195",
    featureDesc: "Min. adjustable operate delay time",
    label: "EF008195: Min. adjustable operate delay time"
  },
  {
    featureId: "EF008197",
    featureDesc: "Initial value measuring range voltage",
    label: "EF008197: Initial value measuring range voltage"
  },
  {
    featureId: "EF008198",
    featureDesc: "Initial value measuring range current",
    label: "EF008198: Initial value measuring range current"
  },
  {
    featureId: "EF008200",
    featureDesc: "Connectable conductor cross section",
    label: "EF008200: Connectable conductor cross section"
  },
  {
    featureId: "EF008201",
    featureDesc: "Delay-on energization time",
    label: "EF008201: Delay-on energization time"
  },
  {
    featureId: "EF008202",
    featureDesc: "Response value impedance 1",
    label: "EF008202: Response value impedance 1"
  },
  {
    featureId: "EF008203",
    featureDesc: "Response value impedance 2",
    label: "EF008203: Response value impedance 2"
  },
  {
    featureId: "EF008204",
    featureDesc: "Response value amperage 1",
    label: "EF008204: Response value amperage 1"
  },
  {
    featureId: "EF008205",
    featureDesc: "Response value amperage 2",
    label: "EF008205: Response value amperage 2"
  },
  {
    featureId: "EF008206",
    featureDesc: "Operating voltage AC 50 Hz",
    label: "EF008206: Operating voltage AC 50 Hz"
  },
  {
    featureId: "EF008207",
    featureDesc: "Operating voltage AC 60 Hz",
    label: "EF008207: Operating voltage AC 60 Hz"
  },
  {
    featureId: "EF008208",
    featureDesc: "Operating voltage DC",
    label: "EF008208: Operating voltage DC"
  },
  {
    featureId: "EF008209",
    featureDesc: "Adjustable distance",
    label: "EF008209: Adjustable distance"
  },
  {
    featureId: "EF008210",
    featureDesc: "Adjustable setting value for relative air humidity",
    label: "EF008210: Adjustable setting value for relative air humidity"
  },
  {
    featureId: "EF008211",
    featureDesc: "Adjustable current range",
    label: "EF008211: Adjustable current range"
  },
  {
    featureId: "EF008213",
    featureDesc: "Measuring range length",
    label: "EF008213: Measuring range length"
  },
  {
    featureId: "EF008215",
    featureDesc: "Measuring range rotational speed",
    label: "EF008215: Measuring range rotational speed"
  },
  {
    featureId: "EF008216",
    featureDesc: "Permitted working pressure",
    label: "EF008216: Permitted working pressure"
  },
  {
    featureId: "EF008217",
    featureDesc: "Permitted cable diameter",
    label: "EF008217: Permitted cable diameter"
  },
  {
    featureId: "EF008218",
    featureDesc: "Position cable feed",
    label: "EF008218: Position cable feed"
  },
  {
    featureId: "EF008219",
    featureDesc: "Connection position",
    label: "EF008219: Connection position"
  },
  {
    featureId: "EF008220",
    featureDesc: "Type of connection system",
    label: "EF008220: Type of connection system"
  },
  {
    featureId: "EF008221",
    featureDesc: "Type of set-up",
    label: "EF008221: Type of set-up"
  },
  {
    featureId: "EF008222",
    featureDesc: "Type of output voltage",
    label: "EF008222: Type of output voltage"
  },
  {
    featureId: "EF008225",
    featureDesc: "Type of data transmission",
    label: "EF008225: Type of data transmission"
  },
  {
    featureId: "EF008226",
    featureDesc: "Type of PCB fastening",
    label: "EF008226: Type of PCB fastening"
  },
  {
    featureId: "EF008229",
    featureDesc: "Type of lamp socket",
    label: "EF008229: Type of lamp socket"
  },
  {
    featureId: "EF008233",
    featureDesc: "Type of switch drive",
    label: "EF008233: Type of switch drive"
  },
  {
    featureId: "EF008234",
    featureDesc: "Type of motor protection",
    label: "EF008234: Type of motor protection"
  },
  {
    featureId: "EF008236",
    featureDesc: "Type of DIN-rail adapter",
    label: "EF008236: Type of DIN-rail adapter"
  },
  {
    featureId: "EF008238",
    featureDesc: "Physical measurement principle",
    label: "EF008238: Physical measurement principle"
  },
  {
    featureId: "EF008239",
    featureDesc: "Construction size fuse inserts",
    label: "EF008239: Construction size fuse inserts"
  },
  {
    featureId: "EF008240",
    featureDesc: "Device construction",
    label: "EF008240: Device construction"
  },
  {
    featureId: "EF008241",
    featureDesc: "Phase load",
    label: "EF008241: Phase load"
  },
  {
    featureId: "EF008242",
    featureDesc: "Voltage type for actuating",
    label: "EF008242: Voltage type for actuating"
  },
  {
    featureId: "EF008244",
    featureDesc: "Type of display",
    label: "EF008244: Type of display"
  },
  {
    featureId: "EF008245",
    featureDesc: "Evaluation inputs",
    label: "EF008245: Evaluation inputs"
  },
  {
    featureId: "EF008246",
    featureDesc: "Evaluation signal",
    label: "EF008246: Evaluation signal"
  },
  {
    featureId: "EF008248",
    featureDesc: "Material contact body",
    label: "EF008248: Material contact body"
  },
  {
    featureId: "EF008249",
    featureDesc: "Colour cable sheath",
    label: "EF008249: Colour cable sheath"
  },
  {
    featureId: "EF008250",
    featureDesc: "Rated current In",
    label: "EF008250: Rated current In"
  },
  {
    featureId: "EF008251",
    featureDesc: "Operating frequency",
    label: "EF008251: Operating frequency"
  },
  {
    featureId: "EF008252",
    featureDesc: "Number of electrical connections",
    label: "EF008252: Number of electrical connections"
  },
  {
    featureId: "EF008254",
    featureDesc: "Inner diameter opening",
    label: "EF008254: Inner diameter opening"
  },
  {
    featureId: "EF008255",
    featureDesc: "Number of contacts as normally closed contact",
    label: "EF008255: Number of contacts as normally closed contact"
  },
  {
    featureId: "EF008256",
    featureDesc: "Number of contacts as normally open contact",
    label: "EF008256: Number of contacts as normally open contact"
  },
  {
    featureId: "EF008257",
    featureDesc: "Number of contacts as change-over contact",
    label: "EF008257: Number of contacts as change-over contact"
  },
  {
    featureId: "EF008258",
    featureDesc: "Max. apparent power",
    label: "EF008258: Max. apparent power"
  },
  {
    featureId: "EF008259",
    featureDesc: "Max. power on-delay time",
    label: "EF008259: Max. power on-delay time"
  },
  {
    featureId: "EF008260",
    featureDesc: "Number of signal tower elements",
    label: "EF008260: Number of signal tower elements"
  },
  {
    featureId: "EF008261",
    featureDesc: "Pressure-switching differential",
    label: "EF008261: Pressure-switching differential"
  },
  {
    featureId: "EF008263",
    featureDesc: "Number of normally closed contacts per actuation direction",
    label: "EF008263: Number of normally closed contacts per actuation direction"
  },
  {
    featureId: "EF008265",
    featureDesc: "End value measuring range voltage",
    label: "EF008265: End value measuring range voltage"
  },
  {
    featureId: "EF008266",
    featureDesc: "End value measuring range current",
    label: "EF008266: End value measuring range current"
  },
  {
    featureId: "EF008268",
    featureDesc: "Secondary rated current In",
    label: "EF008268: Secondary rated current In"
  },
  {
    featureId: "EF008269",
    featureDesc: "Profile height busbar",
    label: "EF008269: Profile height busbar"
  },
  {
    featureId: "EF008270",
    featureDesc: "Specific paper mass (grammage)",
    label: "EF008270: Specific paper mass (grammage)"
  },
  {
    featureId: "EF008271",
    featureDesc: "Suitable for input board PLC",
    label: "EF008271: Suitable for input board PLC"
  },
  {
    featureId: "EF008272",
    featureDesc: "Suitable for output card PLC",
    label: "EF008272: Suitable for output card PLC"
  },
  {
    featureId: "EF008273",
    featureDesc: "Suitable for digital signals",
    label: "EF008273: Suitable for digital signals"
  },
  {
    featureId: "EF008274",
    featureDesc: "Suitable for analogue signals",
    label: "EF008274: Suitable for analogue signals"
  },
  {
    featureId: "EF008275",
    featureDesc: "Interference resistance to magnetic fields",
    label: "EF008275: Interference resistance to magnetic fields"
  },
  {
    featureId: "EF008276",
    featureDesc: "Material of saddle clamp",
    label: "EF008276: Material of saddle clamp"
  },
  {
    featureId: "EF008277",
    featureDesc: "Material base part",
    label: "EF008277: Material base part"
  },
  {
    featureId: "EF008278",
    featureDesc: "Number of connections round conductor total",
    label: "EF008278: Number of connections round conductor total"
  },
  {
    featureId: "EF008279",
    featureDesc: "Material conductor holder",
    label: "EF008279: Material conductor holder"
  },
  {
    featureId: "EF008280",
    featureDesc: "Insulating section",
    label: "EF008280: Insulating section"
  },
  {
    featureId: "EF008284",
    featureDesc: "Max. output current 1",
    label: "EF008284: Max. output current 1"
  },
  {
    featureId: "EF008285",
    featureDesc: "Max. output current 2",
    label: "EF008285: Max. output current 2"
  },
  {
    featureId: "EF008286",
    featureDesc: "Max. output current 3",
    label: "EF008286: Max. output current 3"
  },
  {
    featureId: "EF008287",
    featureDesc: "Suitable as side panel",
    label: "EF008287: Suitable as side panel"
  },
  {
    featureId: "EF008288",
    featureDesc: "Suitable as rear panel",
    label: "EF008288: Suitable as rear panel"
  },
  {
    featureId: "EF008289",
    featureDesc: "Rated switching distance",
    label: "EF008289: Rated switching distance"
  },
  {
    featureId: "EF008290",
    featureDesc: "Fork depth",
    label: "EF008290: Fork depth"
  },
  {
    featureId: "EF008291",
    featureDesc: "Fork width",
    label: "EF008291: Fork width"
  },
  {
    featureId: "EF008292",
    featureDesc: "Min. object size",
    label: "EF008292: Min. object size"
  },
  {
    featureId: "EF008293",
    featureDesc: "Frame opening lengthwise",
    label: "EF008293: Frame opening lengthwise"
  },
  {
    featureId: "EF008294",
    featureDesc: "Frame opening cross",
    label: "EF008294: Frame opening cross"
  },
  {
    featureId: "EF008296",
    featureDesc: "Triangulation",
    label: "EF008296: Triangulation"
  },
  {
    featureId: "EF008297",
    featureDesc: "Dismantle length",
    label: "EF008297: Dismantle length"
  },
  {
    featureId: "EF008298",
    featureDesc: "Connection round cable",
    label: "EF008298: Connection round cable"
  },
  {
    featureId: "EF008300",
    featureDesc: "Number of LEDs",
    label: "EF008300: Number of LEDs"
  },
  {
    featureId: "EF008302",
    featureDesc: "Type of integrated switching",
    label: "EF008302: Type of integrated switching"
  },
  {
    featureId: "EF008303",
    featureDesc: "Type of finished cable",
    label: "EF008303: Type of finished cable"
  },
  {
    featureId: "EF008304",
    featureDesc: "Type of distributor",
    label: "EF008304: Type of distributor"
  },
  {
    featureId: "EF008305",
    featureDesc: "Type side 1",
    label: "EF008305: Type side 1"
  },
  {
    featureId: "EF008306",
    featureDesc: "Type side 2",
    label: "EF008306: Type side 2"
  },
  {
    featureId: "EF008307",
    featureDesc: "Fixation rung",
    label: "EF008307: Fixation rung"
  },
  {
    featureId: "EF008308",
    featureDesc: "Diameter mounting hole",
    label: "EF008308: Diameter mounting hole"
  },
  {
    featureId: "EF008310",
    featureDesc: "Diameter lamp",
    label: "EF008310: Diameter lamp"
  },
  {
    featureId: "EF008312",
    featureDesc: "Colour of the separate adapter piece",
    label: "EF008312: Colour of the separate adapter piece"
  },
  {
    featureId: "EF008313",
    featureDesc: "For gas- and oil burners",
    label: "EF008313: For gas- and oil burners"
  },
  {
    featureId: "EF008315",
    featureDesc: "For max. bundle diameter",
    label: "EF008315: For max. bundle diameter"
  },
  {
    featureId: "EF008316",
    featureDesc: "For fuse size",
    label: "EF008316: For fuse size"
  },
  {
    featureId: "EF008317",
    featureDesc: "Insulating piece permanent temperature-resistant",
    label: "EF008317: Insulating piece permanent temperature-resistant"
  },
  {
    featureId: "EF008318",
    featureDesc: "Lowest bending radius",
    label: "EF008318: Lowest bending radius"
  },
  {
    featureId: "EF008319",
    featureDesc: "Lamp power per meter",
    label: "EF008319: Lamp power per meter"
  },
  {
    featureId: "EF008320",
    featureDesc: "Length of particular segments",
    label: "EF008320: Length of particular segments"
  },
  {
    featureId: "EF008322",
    featureDesc: "Conductor end treatment",
    label: "EF008322: Conductor end treatment"
  },
  {
    featureId: "EF008323",
    featureDesc: "Cable permanent temperature-resistant to",
    label: "EF008323: Cable permanent temperature-resistant to"
  },
  {
    featureId: "EF008324",
    featureDesc: "With connection set",
    label: "EF008324: With connection set"
  },
  {
    featureId: "EF008325",
    featureDesc: "With end piece",
    label: "EF008325: With end piece"
  },
  {
    featureId: "EF008326",
    featureDesc: "Mounting temperature",
    label: "EF008326: Mounting temperature"
  },
  {
    featureId: "EF008327",
    featureDesc: "Multiwatt model",
    label: "EF008327: Multiwatt model"
  },
  {
    featureId: "EF008328",
    featureDesc: "Phase pickup",
    label: "EF008328: Phase pickup"
  },
  {
    featureId: "EF008331",
    featureDesc: "Xenon",
    label: "EF008331: Xenon"
  },
  {
    featureId: "EF008332",
    featureDesc: "For connection of paper- with plastic insulated cables",
    label: "EF008332: For connection of paper- with plastic insulated cables"
  },
  {
    featureId: "EF008333",
    featureDesc: "Niche width",
    label: "EF008333: Niche width"
  },
  {
    featureId: "EF008334",
    featureDesc: "Niche height",
    label: "EF008334: Niche height"
  },
  {
    featureId: "EF008339",
    featureDesc: "Suitable for cable management system width",
    label: "EF008339: Suitable for cable management system width"
  },
  {
    featureId: "EF008340",
    featureDesc: "Number of nodes per meter",
    label: "EF008340: Number of nodes per meter"
  },
  {
    featureId: "EF008342",
    featureDesc: "Average nominal lifespan",
    label: "EF008342: Average nominal lifespan"
  },
  {
    featureId: "EF008343",
    featureDesc: "Type of lens",
    label: "EF008343: Type of lens"
  },
  {
    featureId: "EF008344",
    featureDesc: "Suitable for temperature control",
    label: "EF008344: Suitable for temperature control"
  },
  {
    featureId: "EF008345",
    featureDesc: "Window transmitter",
    label: "EF008345: Window transmitter"
  },
  {
    featureId: "EF008346",
    featureDesc: "Suitable for indication element",
    label: "EF008346: Suitable for indication element"
  },
  {
    featureId: "EF008355",
    featureDesc: "Height flanged sides",
    label: "EF008355: Height flanged sides"
  },
  {
    featureId: "EF008356",
    featureDesc: "Even surface load per m²",
    label: "EF008356: Even surface load per m²"
  },
  {
    featureId: "EF008357",
    featureDesc: "Point load per 25x25 mm",
    label: "EF008357: Point load per 25x25 mm"
  },
  {
    featureId: "EF008358",
    featureDesc: "With contact-/distance projections",
    label: "EF008358: With contact-/distance projections"
  },
  {
    featureId: "EF008359",
    featureDesc: "Self-extinguishing according to NEN-EN 13501-1 B(fl) S1-d0",
    label: "EF008359: Self-extinguishing according to NEN-EN 13501-1 B(fl) S1-d0"
  },
  {
    featureId: "EF008360",
    featureDesc: "With recess",
    label: "EF008360: With recess"
  },
  {
    featureId: "EF008361",
    featureDesc: "Length recess",
    label: "EF008361: Length recess"
  },
  {
    featureId: "EF008362",
    featureDesc: "Width recess",
    label: "EF008362: Width recess"
  },
  {
    featureId: "EF008363",
    featureDesc: "Diameter recess",
    label: "EF008363: Diameter recess"
  },
  {
    featureId: "EF008364",
    featureDesc: "Suitable for cable channelling to the workplace",
    label: "EF008364: Suitable for cable channelling to the workplace"
  },
  {
    featureId: "EF008365",
    featureDesc: "Suitable for connection surface tank",
    label: "EF008365: Suitable for connection surface tank"
  },
  {
    featureId: "EF008366",
    featureDesc: "Suitable for connection cable output",
    label: "EF008366: Suitable for connection cable output"
  },
  {
    featureId: "EF008367",
    featureDesc: "With disposable cover",
    label: "EF008367: With disposable cover"
  },
  {
    featureId: "EF008369",
    featureDesc: "Recyclable",
    label: "EF008369: Recyclable"
  },
  {
    featureId: "EF008370",
    featureDesc: "For connection to wall",
    label: "EF008370: For connection to wall"
  },
  {
    featureId: "EF008371",
    featureDesc: "Number of flanged sides",
    label: "EF008371: Number of flanged sides"
  },
  {
    featureId: "EF008372",
    featureDesc: "Suitable for data",
    label: "EF008372: Suitable for data"
  },
  {
    featureId: "EF008373",
    featureDesc: "Suitable for 230 V",
    label: "EF008373: Suitable for 230 V"
  },
  {
    featureId: "EF008374",
    featureDesc: "Built-in positions modular 45 mm",
    label: "EF008374: Built-in positions modular 45 mm"
  },
  {
    featureId: "EF008375",
    featureDesc: "With connection block",
    label: "EF008375: With connection block"
  },
  {
    featureId: "EF008376",
    featureDesc: "Suitable for data connector cradle",
    label: "EF008376: Suitable for data connector cradle"
  },
  {
    featureId: "EF008377",
    featureDesc: "Cable outlet tile required",
    label: "EF008377: Cable outlet tile required"
  },
  {
    featureId: "EF008378",
    featureDesc: "Type of input",
    label: "EF008378: Type of input"
  },
  {
    featureId: "EF008379",
    featureDesc: "Type of branch",
    label: "EF008379: Type of branch"
  },
  {
    featureId: "EF008380",
    featureDesc: "Width cable tie",
    label: "EF008380: Width cable tie"
  },
  {
    featureId: "EF008381",
    featureDesc: "Type of measuring instrument",
    label: "EF008381: Type of measuring instrument"
  },
  {
    featureId: "EF008382",
    featureDesc: "Brand independent calibration",
    label: "EF008382: Brand independent calibration"
  },
  {
    featureId: "EF008383",
    featureDesc: "Calibration according to ISO/IEC 17025",
    label: "EF008383: Calibration according to ISO/IEC 17025"
  },
  {
    featureId: "EF008384",
    featureDesc: "Approved according to BSH/49/40P/96",
    label: "EF008384: Approved according to BSH/49/40P/96"
  },
  {
    featureId: "EF008385",
    featureDesc: "KVR signal",
    label: "EF008385: KVR signal"
  },
  {
    featureId: "EF008386",
    featureDesc: "Type of plug-in contact, box-sided",
    label: "EF008386: Type of plug-in contact, box-sided"
  },
  {
    featureId: "EF008387",
    featureDesc: "NVP value",
    label: "EF008387: NVP value"
  },
  {
    featureId: "EF008389",
    featureDesc: "Conductive coating",
    label: "EF008389: Conductive coating"
  },
  {
    featureId: "EF008390",
    featureDesc: "Frequency sound signal",
    label: "EF008390: Frequency sound signal"
  },
  {
    featureId: "EF008391",
    featureDesc: "Material horn",
    label: "EF008391: Material horn"
  },
  {
    featureId: "EF008394",
    featureDesc: "With vibration damper",
    label: "EF008394: With vibration damper"
  },
  {
    featureId: "EF008402",
    featureDesc: "Angle rotated central insert",
    label: "EF008402: Angle rotated central insert"
  },
  {
    featureId: "EF008403",
    featureDesc: "Type of mains connection",
    label: "EF008403: Type of mains connection"
  },
  {
    featureId: "EF008404",
    featureDesc: "For Ex-Zone dust",
    label: "EF008404: For Ex-Zone dust"
  },
  {
    featureId: "EF008405",
    featureDesc: "Drill hole distance vertical",
    label: "EF008405: Drill hole distance vertical"
  },
  {
    featureId: "EF008406",
    featureDesc: "Drill hole distance horizontal",
    label: "EF008406: Drill hole distance horizontal"
  },
  {
    featureId: "EF008407",
    featureDesc: "Flange size vertical",
    label: "EF008407: Flange size vertical"
  },
  {
    featureId: "EF008408",
    featureDesc: "Flange size horizontal",
    label: "EF008408: Flange size horizontal"
  },
  {
    featureId: "EF008409",
    featureDesc: "Rated excitation voltage",
    label: "EF008409: Rated excitation voltage"
  },
  {
    featureId: "EF008427",
    featureDesc: "Connector (information technology)",
    label: "EF008427: Connector (information technology)"
  },
  {
    featureId: "EF008429",
    featureDesc: "Quadband network",
    label: "EF008429: Quadband network"
  },
  {
    featureId: "EF008430",
    featureDesc: "Triple band network",
    label: "EF008430: Triple band network"
  },
  {
    featureId: "EF008431",
    featureDesc: "Dual SIM",
    label: "EF008431: Dual SIM"
  },
  {
    featureId: "EF008432",
    featureDesc: "UMTS network",
    label: "EF008432: UMTS network"
  },
  {
    featureId: "EF008433",
    featureDesc: "Other network",
    label: "EF008433: Other network"
  },
  {
    featureId: "EF008434",
    featureDesc: "Data transfer via Bluetooth",
    label: "EF008434: Data transfer via Bluetooth"
  },
  {
    featureId: "EF008435",
    featureDesc: "Data transfer via USB",
    label: "EF008435: Data transfer via USB"
  },
  {
    featureId: "EF008436",
    featureDesc: "Data transfer via Wi-Fi",
    label: "EF008436: Data transfer via Wi-Fi"
  },
  {
    featureId: "EF008437",
    featureDesc: "Push Mail function",
    label: "EF008437: Push Mail function"
  },
  {
    featureId: "EF008438",
    featureDesc: "Data transfer via GPRS",
    label: "EF008438: Data transfer via GPRS"
  },
  {
    featureId: "EF008439",
    featureDesc: "With adequate keyboard",
    label: "EF008439: With adequate keyboard"
  },
  {
    featureId: "EF008440",
    featureDesc: "Expandable memory",
    label: "EF008440: Expandable memory"
  },
  {
    featureId: "EF008441",
    featureDesc: "With voice command",
    label: "EF008441: With voice command"
  },
  {
    featureId: "EF008442",
    featureDesc: "With MP3 player",
    label: "EF008442: With MP3 player"
  },
  {
    featureId: "EF008443",
    featureDesc: "With camera",
    label: "EF008443: With camera"
  },
  {
    featureId: "EF008445",
    featureDesc: "With FM radio",
    label: "EF008445: With FM radio"
  },
  {
    featureId: "EF008446",
    featureDesc: "Max. paper format",
    label: "EF008446: Max. paper format"
  },
  {
    featureId: "EF008447",
    featureDesc: "Delivery quantity",
    label: "EF008447: Delivery quantity"
  },
  {
    featureId: "EF008448",
    featureDesc: "Detect carbon monoxide (CO)",
    label: "EF008448: Detect carbon monoxide (CO)"
  },
  {
    featureId: "EF008449",
    featureDesc: "Detects carbon dioxide (CO2)",
    label: "EF008449: Detects carbon dioxide (CO2)"
  },
  {
    featureId: "EF008450",
    featureDesc: "Detect ammonia (NH3)",
    label: "EF008450: Detect ammonia (NH3)"
  },
  {
    featureId: "EF008451",
    featureDesc: "Detect methane (CH4)",
    label: "EF008451: Detect methane (CH4)"
  },
  {
    featureId: "EF008452",
    featureDesc: "Detect propane (C3H8)",
    label: "EF008452: Detect propane (C3H8)"
  },
  {
    featureId: "EF008453",
    featureDesc: "Detect butane (C4H10)",
    label: "EF008453: Detect butane (C4H10)"
  },
  {
    featureId: "EF008454",
    featureDesc: "Detect domestic gas",
    label: "EF008454: Detect domestic gas"
  },
  {
    featureId: "EF008455",
    featureDesc: "Detect anaesthetic gas",
    label: "EF008455: Detect anaesthetic gas"
  },
  {
    featureId: "EF008456",
    featureDesc: "Detect irritant gas",
    label: "EF008456: Detect irritant gas"
  },
  {
    featureId: "EF008457",
    featureDesc: "Detect other gas species",
    label: "EF008457: Detect other gas species"
  },
  {
    featureId: "EF008458",
    featureDesc: "Modular spacing",
    label: "EF008458: Modular spacing"
  },
  {
    featureId: "EF008459",
    featureDesc: "Position of the shackle",
    label: "EF008459: Position of the shackle"
  },
  {
    featureId: "EF008473",
    featureDesc: "Number of big stones",
    label: "EF008473: Number of big stones"
  },
  {
    featureId: "EF008474",
    featureDesc: "Number of little relief stones",
    label: "EF008474: Number of little relief stones"
  },
  {
    featureId: "EF008475",
    featureDesc: "With pliers",
    label: "EF008475: With pliers"
  },
  {
    featureId: "EF008477",
    featureDesc: "Can be used on wet surface",
    label: "EF008477: Can be used on wet surface"
  },
  {
    featureId: "EF008478",
    featureDesc: "Acid-free",
    label: "EF008478: Acid-free"
  },
  {
    featureId: "EF008479",
    featureDesc: "Covers supplied with the product",
    label: "EF008479: Covers supplied with the product"
  },
  {
    featureId: "EF008481",
    featureDesc: "Rated operation current Ie at AC-15, 400 V",
    label: "EF008481: Rated operation current Ie at AC-15, 400 V"
  },
  {
    featureId: "EF008482",
    featureDesc: "Reduction blind power",
    label: "EF008482: Reduction blind power"
  },
  {
    featureId: "EF008484",
    featureDesc: "Reduction energy consumption",
    label: "EF008484: Reduction energy consumption"
  },
  {
    featureId: "EF008485",
    featureDesc: "Reduction apparent power",
    label: "EF008485: Reduction apparent power"
  },
  {
    featureId: "EF008486",
    featureDesc: "Reduction line voltage",
    label: "EF008486: Reduction line voltage"
  },
  {
    featureId: "EF008488",
    featureDesc: "Outer width",
    label: "EF008488: Outer width"
  },
  {
    featureId: "EF008489",
    featureDesc: "Outer height",
    label: "EF008489: Outer height"
  },
  {
    featureId: "EF008490",
    featureDesc: "Max. cantilever length",
    label: "EF008490: Max. cantilever length"
  },
  {
    featureId: "EF008491",
    featureDesc: "Multi-axial motion",
    label: "EF008491: Multi-axial motion"
  },
  {
    featureId: "EF008493",
    featureDesc: "Number of links",
    label: "EF008493: Number of links"
  },
  {
    featureId: "EF008494",
    featureDesc: "Opening principle",
    label: "EF008494: Opening principle"
  },
  {
    featureId: "EF008495",
    featureDesc: "Rated operation current Ie at AC-4, 400 V",
    label: "EF008495: Rated operation current Ie at AC-4, 400 V"
  },
  {
    featureId: "EF008496",
    featureDesc: "Rated operation power at AC-4, 400 V",
    label: "EF008496: Rated operation power at AC-4, 400 V"
  },
  {
    featureId: "EF008497",
    featureDesc: "Number of cells",
    label: "EF008497: Number of cells"
  },
  {
    featureId: "EF008498",
    featureDesc: "Explosion-tested version 'Ex e'",
    label: "EF008498: Explosion-tested version 'Ex e'"
  },
  {
    featureId: "EF008500",
    featureDesc: "Number of USB connections",
    label: "EF008500: Number of USB connections"
  },
  {
    featureId: "EF008501",
    featureDesc: "Number of audio connections",
    label: "EF008501: Number of audio connections"
  },
  {
    featureId: "EF008504",
    featureDesc: "Number of CAT 5E connections",
    label: "EF008504: Number of CAT 5E connections"
  },
  {
    featureId: "EF008505",
    featureDesc: "Number of CAT 6 connections",
    label: "EF008505: Number of CAT 6 connections"
  },
  {
    featureId: "EF008508",
    featureDesc: "With power bridge",
    label: "EF008508: With power bridge"
  },
  {
    featureId: "EF008509",
    featureDesc: "PC-programming set for Windows 7",
    label: "EF008509: PC-programming set for Windows 7"
  },
  {
    featureId: "EF008511",
    featureDesc: "Number of C13 socket outlets",
    label: "EF008511: Number of C13 socket outlets"
  },
  {
    featureId: "EF008512",
    featureDesc: "Number of C20 socket outlets",
    label: "EF008512: Number of C20 socket outlets"
  },
  {
    featureId: "EF008513",
    featureDesc: "Number of C19 socket outlets",
    label: "EF008513: Number of C19 socket outlets"
  },
  {
    featureId: "EF008515",
    featureDesc: "Number of grey-scales/blue-scales of display",
    label: "EF008515: Number of grey-scales/blue-scales of display"
  },
  {
    featureId: "EF008516",
    featureDesc: "Digital inputs configurable",
    label: "EF008516: Digital inputs configurable"
  },
  {
    featureId: "EF008518",
    featureDesc: "Analogue inputs configurable",
    label: "EF008518: Analogue inputs configurable"
  },
  {
    featureId: "EF008519",
    featureDesc: "Analogue outputs configurable",
    label: "EF008519: Analogue outputs configurable"
  },
  {
    featureId: "EF008520",
    featureDesc: "Digital outputs configurable",
    label: "EF008520: Digital outputs configurable"
  },
  {
    featureId: "EF008521",
    featureDesc: "Material of contact insert",
    label: "EF008521: Material of contact insert"
  },
  {
    featureId: "EF008523",
    featureDesc: "Radio standard GPRS",
    label: "EF008523: Radio standard GPRS"
  },
  {
    featureId: "EF008524",
    featureDesc: "Radio standard GSM",
    label: "EF008524: Radio standard GSM"
  },
  {
    featureId: "EF008525",
    featureDesc: "Radio standard UMTS",
    label: "EF008525: Radio standard UMTS"
  },
  {
    featureId: "EF008526",
    featureDesc: "Suitable for operating system Windows 7",
    label: "EF008526: Suitable for operating system Windows 7"
  },
  {
    featureId: "EF008528",
    featureDesc: "Lowering height",
    label: "EF008528: Lowering height"
  },
  {
    featureId: "EF008530",
    featureDesc: "Hoisting load",
    label: "EF008530: Hoisting load"
  },
  {
    featureId: "EF008531",
    featureDesc: "Hoisting speed",
    label: "EF008531: Hoisting speed"
  },
  {
    featureId: "EF008532",
    featureDesc: "Number of suspension cables",
    label: "EF008532: Number of suspension cables"
  },
  {
    featureId: "EF008533",
    featureDesc: "For nominal voltage up to",
    label: "EF008533: For nominal voltage up to"
  },
  {
    featureId: "EF008534",
    featureDesc: "Arc fault tested",
    label: "EF008534: Arc fault tested"
  },
  {
    featureId: "EF008535",
    featureDesc: "With chin protection",
    label: "EF008535: With chin protection"
  },
  {
    featureId: "EF008536",
    featureDesc: "For dielectric strength up to",
    label: "EF008536: For dielectric strength up to"
  },
  {
    featureId: "EF008537",
    featureDesc: "Chopping system",
    label: "EF008537: Chopping system"
  },
  {
    featureId: "EF008538",
    featureDesc: "Idle rotational speed",
    label: "EF008538: Idle rotational speed"
  },
  {
    featureId: "EF008539",
    featureDesc: "Max. material throughput",
    label: "EF008539: Max. material throughput"
  },
  {
    featureId: "EF008540",
    featureDesc: "Max. branch thickness",
    label: "EF008540: Max. branch thickness"
  },
  {
    featureId: "EF008542",
    featureDesc: "Branch thickness",
    label: "EF008542: Branch thickness"
  },
  {
    featureId: "EF008543",
    featureDesc: "Distance of blades",
    label: "EF008543: Distance of blades"
  },
  {
    featureId: "EF008544",
    featureDesc: "Cutting width",
    label: "EF008544: Cutting width"
  },
  {
    featureId: "EF008545",
    featureDesc: "Cutting number at idle",
    label: "EF008545: Cutting number at idle"
  },
  {
    featureId: "EF008546",
    featureDesc: "Sword length",
    label: "EF008546: Sword length"
  },
  {
    featureId: "EF008547",
    featureDesc: "Utilizable knife length",
    label: "EF008547: Utilizable knife length"
  },
  {
    featureId: "EF008548",
    featureDesc: "Adjustable conductor cross section",
    label: "EF008548: Adjustable conductor cross section"
  },
  {
    featureId: "EF008549",
    featureDesc: "Type of electrical connection, control sided",
    label: "EF008549: Type of electrical connection, control sided"
  },
  {
    featureId: "EF008550",
    featureDesc: "With self-test mechanism",
    label: "EF008550: With self-test mechanism"
  },
  {
    featureId: "EF008551",
    featureDesc: "Usable at precipitation",
    label: "EF008551: Usable at precipitation"
  },
  {
    featureId: "EF008552",
    featureDesc: "Type of system",
    label: "EF008552: Type of system"
  },
  {
    featureId: "EF008554",
    featureDesc: "Utilizable length of cut",
    label: "EF008554: Utilizable length of cut"
  },
  {
    featureId: "EF008555",
    featureDesc: "Max. head load",
    label: "EF008555: Max. head load"
  },
  {
    featureId: "EF008556",
    featureDesc: "Plug connector, secured against rotation",
    label: "EF008556: Plug connector, secured against rotation"
  },
  {
    featureId: "EF008558",
    featureDesc: "Recording head for",
    label: "EF008558: Recording head for"
  },
  {
    featureId: "EF008559",
    featureDesc: "Max. short circuit current",
    label: "EF008559: Max. short circuit current"
  },
  {
    featureId: "EF008560",
    featureDesc: "Wire cross section, phase-side",
    label: "EF008560: Wire cross section, phase-side"
  },
  {
    featureId: "EF008561",
    featureDesc: "Wire cross section, earth-side",
    label: "EF008561: Wire cross section, earth-side"
  },
  {
    featureId: "EF008562",
    featureDesc: "Length 1-pole rope",
    label: "EF008562: Length 1-pole rope"
  },
  {
    featureId: "EF008563",
    featureDesc: "Length phaseline a",
    label: "EF008563: Length phaseline a"
  },
  {
    featureId: "EF008564",
    featureDesc: "Length phaseline b",
    label: "EF008564: Length phaseline b"
  },
  {
    featureId: "EF008565",
    featureDesc: "Length phaseline c",
    label: "EF008565: Length phaseline c"
  },
  {
    featureId: "EF008566",
    featureDesc: "Length earth line",
    label: "EF008566: Length earth line"
  },
  {
    featureId: "EF008567",
    featureDesc: "Connecting part, phase-side",
    label: "EF008567: Connecting part, phase-side"
  },
  {
    featureId: "EF008568",
    featureDesc: "Connecting part, earth-side",
    label: "EF008568: Connecting part, earth-side"
  },
  {
    featureId: "EF008569",
    featureDesc: "Fully insulated",
    label: "EF008569: Fully insulated"
  },
  {
    featureId: "EF008570",
    featureDesc: "Max. chain speed",
    label: "EF008570: Max. chain speed"
  },
  {
    featureId: "EF008571",
    featureDesc: "Smallest possible mouth",
    label: "EF008571: Smallest possible mouth"
  },
  {
    featureId: "EF008572",
    featureDesc: "Connection of the earthing and short circuit device",
    label: "EF008572: Connection of the earthing and short circuit device"
  },
  {
    featureId: "EF008573",
    featureDesc: "For wire cross section",
    label: "EF008573: For wire cross section"
  },
  {
    featureId: "EF008574",
    featureDesc: "Anti-rotation protection",
    label: "EF008574: Anti-rotation protection"
  },
  {
    featureId: "EF008575",
    featureDesc: "Admission of the activation rod",
    label: "EF008575: Admission of the activation rod"
  },
  {
    featureId: "EF008576",
    featureDesc: "Earthing cable cross section",
    label: "EF008576: Earthing cable cross section"
  },
  {
    featureId: "EF008579",
    featureDesc: "Refillable",
    label: "EF008579: Refillable"
  },
  {
    featureId: "EF008580",
    featureDesc: "For interior and outdoor installations",
    label: "EF008580: For interior and outdoor installations"
  },
  {
    featureId: "EF008581",
    featureDesc: "Cutting system",
    label: "EF008581: Cutting system"
  },
  {
    featureId: "EF008582",
    featureDesc: "Cutting height",
    label: "EF008582: Cutting height"
  },
  {
    featureId: "EF008583",
    featureDesc: "Volume catcher",
    label: "EF008583: Volume catcher"
  },
  {
    featureId: "EF008584",
    featureDesc: "Steam capacity",
    label: "EF008584: Steam capacity"
  },
  {
    featureId: "EF008585",
    featureDesc: "Mower cut circle diameter",
    label: "EF008585: Mower cut circle diameter"
  },
  {
    featureId: "EF008586",
    featureDesc: "Length of telescopic handle",
    label: "EF008586: Length of telescopic handle"
  },
  {
    featureId: "EF008587",
    featureDesc: "Working width",
    label: "EF008587: Working width"
  },
  {
    featureId: "EF008588",
    featureDesc: "Stretch sleeve",
    label: "EF008588: Stretch sleeve"
  },
  {
    featureId: "EF008590",
    featureDesc: "Idle rotational speed",
    label: "EF008590: Idle rotational speed"
  },
  {
    featureId: "EF008591",
    featureDesc: "Milling basket stroke",
    label: "EF008591: Milling basket stroke"
  },
  {
    featureId: "EF008592",
    featureDesc: "Collet chuck diameter",
    label: "EF008592: Collet chuck diameter"
  },
  {
    featureId: "EF008593",
    featureDesc: "Mitre setting left",
    label: "EF008593: Mitre setting left"
  },
  {
    featureId: "EF008594",
    featureDesc: "Mitre setting right",
    label: "EF008594: Mitre setting right"
  },
  {
    featureId: "EF008595",
    featureDesc: "Inclination setting left",
    label: "EF008595: Inclination setting left"
  },
  {
    featureId: "EF008596",
    featureDesc: "Inclination setting right",
    label: "EF008596: Inclination setting right"
  },
  {
    featureId: "EF008597",
    featureDesc: "Width of bearing surface",
    label: "EF008597: Width of bearing surface"
  },
  {
    featureId: "EF008598",
    featureDesc: "Length of bearing surface",
    label: "EF008598: Length of bearing surface"
  },
  {
    featureId: "EF008599",
    featureDesc: "Cutting width 90°/90°",
    label: "EF008599: Cutting width 90°/90°"
  },
  {
    featureId: "EF008600",
    featureDesc: "Cutting depth 90°/90°",
    label: "EF008600: Cutting depth 90°/90°"
  },
  {
    featureId: "EF008601",
    featureDesc: "Cutting width 90°/45°",
    label: "EF008601: Cutting width 90°/45°"
  },
  {
    featureId: "EF008602",
    featureDesc: "Cutting depth 90°/45°",
    label: "EF008602: Cutting depth 90°/45°"
  },
  {
    featureId: "EF008603",
    featureDesc: "Cutting width 45°/90°",
    label: "EF008603: Cutting width 45°/90°"
  },
  {
    featureId: "EF008604",
    featureDesc: "Cutting depth 45°/90°",
    label: "EF008604: Cutting depth 45°/90°"
  },
  {
    featureId: "EF008605",
    featureDesc: "Cutting width 45°/45°",
    label: "EF008605: Cutting width 45°/45°"
  },
  {
    featureId: "EF008606",
    featureDesc: "Cutting depth 45°/45°",
    label: "EF008606: Cutting depth 45°/45°"
  },
  {
    featureId: "EF008607",
    featureDesc: "Cutting width chop operation 90°/90°",
    label: "EF008607: Cutting width chop operation 90°/90°"
  },
  {
    featureId: "EF008608",
    featureDesc: "Cutting depth chop operation 90°/90°",
    label: "EF008608: Cutting depth chop operation 90°/90°"
  },
  {
    featureId: "EF008609",
    featureDesc: "Cutting width chop operation 90°/45°",
    label: "EF008609: Cutting width chop operation 90°/45°"
  },
  {
    featureId: "EF008610",
    featureDesc: "Cutting depth chop operation 90°/45°",
    label: "EF008610: Cutting depth chop operation 90°/45°"
  },
  {
    featureId: "EF008611",
    featureDesc: "Cutting width chop operation 45°/90°",
    label: "EF008611: Cutting width chop operation 45°/90°"
  },
  {
    featureId: "EF008612",
    featureDesc: "Cutting depth chop operation 45°/90°",
    label: "EF008612: Cutting depth chop operation 45°/90°"
  },
  {
    featureId: "EF008613",
    featureDesc: "Cutting width chop operation 45°/45°",
    label: "EF008613: Cutting width chop operation 45°/45°"
  },
  {
    featureId: "EF008614",
    featureDesc: "Cutting depth chop operation 45°/45°",
    label: "EF008614: Cutting depth chop operation 45°/45°"
  },
  {
    featureId: "EF008615",
    featureDesc: "Cutting height table operation 90°",
    label: "EF008615: Cutting height table operation 90°"
  },
  {
    featureId: "EF008616",
    featureDesc: "Cutting height table operation 45°",
    label: "EF008616: Cutting height table operation 45°"
  },
  {
    featureId: "EF008618",
    featureDesc: "Number of poles (total)",
    label: "EF008618: Number of poles (total)"
  },
  {
    featureId: "EF008625",
    featureDesc: "Glove size",
    label: "EF008625: Glove size"
  },
  {
    featureId: "EF008626",
    featureDesc: "Glove size (US)",
    label: "EF008626: Glove size (US)"
  },
  {
    featureId: "EF008627",
    featureDesc: "Vibrations",
    label: "EF008627: Vibrations"
  },
  {
    featureId: "EF008628",
    featureDesc: "Diameter of vibrator",
    label: "EF008628: Diameter of vibrator"
  },
  {
    featureId: "EF008629",
    featureDesc: "Length of rubber hose",
    label: "EF008629: Length of rubber hose"
  },
  {
    featureId: "EF008634",
    featureDesc: "Insertion machine-side",
    label: "EF008634: Insertion machine-side"
  },
  {
    featureId: "EF008644",
    featureDesc: "Insertion tool-side",
    label: "EF008644: Insertion tool-side"
  },
  {
    featureId: "EF008646",
    featureDesc: "Suitable for nonferrous metals",
    label: "EF008646: Suitable for nonferrous metals"
  },
  {
    featureId: "EF008648",
    featureDesc: "Suitable for stone",
    label: "EF008648: Suitable for stone"
  },
  {
    featureId: "EF008650",
    featureDesc: "Size holding system",
    label: "EF008650: Size holding system"
  },
  {
    featureId: "EF008655",
    featureDesc: "Lifting speed",
    label: "EF008655: Lifting speed"
  },
  {
    featureId: "EF008683",
    featureDesc: "Suitable for DI",
    label: "EF008683: Suitable for DI"
  },
  {
    featureId: "EF008684",
    featureDesc: "Suitable for DII",
    label: "EF008684: Suitable for DII"
  },
  {
    featureId: "EF008685",
    featureDesc: "Suitable for DIII",
    label: "EF008685: Suitable for DIII"
  },
  {
    featureId: "EF008686",
    featureDesc: "Suitable for DIV",
    label: "EF008686: Suitable for DIV"
  },
  {
    featureId: "EF008687",
    featureDesc: "Suitable for DV",
    label: "EF008687: Suitable for DV"
  },
  {
    featureId: "EF008688",
    featureDesc: "Hot water connection",
    label: "EF008688: Hot water connection"
  },
  {
    featureId: "EF008689",
    featureDesc: "Number of bits TriWing",
    label: "EF008689: Number of bits TriWing"
  },
  {
    featureId: "EF008690",
    featureDesc: "Number of bits two hole",
    label: "EF008690: Number of bits two hole"
  },
  {
    featureId: "EF008691",
    featureDesc: "Max. total height",
    label: "EF008691: Max. total height"
  },
  {
    featureId: "EF008692",
    featureDesc: "Suitable for BNC plug",
    label: "EF008692: Suitable for BNC plug"
  },
  {
    featureId: "EF008693",
    featureDesc: "Suitable for D-Sub plug",
    label: "EF008693: Suitable for D-Sub plug"
  },
  {
    featureId: "EF008694",
    featureDesc: "Suitable for F-compression plug",
    label: "EF008694: Suitable for F-compression plug"
  },
  {
    featureId: "EF008695",
    featureDesc: "Suitable for F plug",
    label: "EF008695: Suitable for F plug"
  },
  {
    featureId: "EF008696",
    featureDesc: "Suitable for LSA plus",
    label: "EF008696: Suitable for LSA plus"
  },
  {
    featureId: "EF008697",
    featureDesc: "Suitable for RJ plug",
    label: "EF008697: Suitable for RJ plug"
  },
  {
    featureId: "EF008698",
    featureDesc: "Suitable for low current connector",
    label: "EF008698: Suitable for low current connector"
  },
  {
    featureId: "EF008699",
    featureDesc: "Suitable for ST plug",
    label: "EF008699: Suitable for ST plug"
  },
  {
    featureId: "EF008700",
    featureDesc: "Suitable for SC plug",
    label: "EF008700: Suitable for SC plug"
  },
  {
    featureId: "EF008701",
    featureDesc: "Suitable for SMA plug",
    label: "EF008701: Suitable for SMA plug"
  },
  {
    featureId: "EF008702",
    featureDesc: "Suitable for LC plug",
    label: "EF008702: Suitable for LC plug"
  },
  {
    featureId: "EF008703",
    featureDesc: "Suitable for MT-RJ plug",
    label: "EF008703: Suitable for MT-RJ plug"
  },
  {
    featureId: "EF008704",
    featureDesc: "Transmitting power",
    label: "EF008704: Transmitting power"
  },
  {
    featureId: "EF008705",
    featureDesc: "Surface brushed",
    label: "EF008705: Surface brushed"
  },
  {
    featureId: "EF008706",
    featureDesc: "Quality of surface coating",
    label: "EF008706: Quality of surface coating"
  },
  {
    featureId: "EF008707",
    featureDesc: "Braided",
    label: "EF008707: Braided"
  },
  {
    featureId: "EF008708",
    featureDesc: "Connection lugs drilled",
    label: "EF008708: Connection lugs drilled"
  },
  {
    featureId: "EF008709",
    featureDesc: "Max. weight luminaire",
    label: "EF008709: Max. weight luminaire"
  },
  {
    featureId: "EF008710",
    featureDesc: "Powdered",
    label: "EF008710: Powdered"
  },
  {
    featureId: "EF008711",
    featureDesc: "Sterile",
    label: "EF008711: Sterile"
  },
  {
    featureId: "EF008712",
    featureDesc: "Type of drill holder",
    label: "EF008712: Type of drill holder"
  },
  {
    featureId: "EF008727",
    featureDesc: "With auto test for earth leakage function",
    label: "EF008727: With auto test for earth leakage function"
  },
  {
    featureId: "EF008728",
    featureDesc: "Suitable for bracket mounting",
    label: "EF008728: Suitable for bracket mounting"
  },
  {
    featureId: "EF008729",
    featureDesc: "Suitable for max. number of poles main contact unit (total)",
    label: "EF008729: Suitable for max. number of poles main contact unit (total)"
  },
  {
    featureId: "EF008730",
    featureDesc: "Suitable for max. current main contact unit",
    label: "EF008730: Suitable for max. current main contact unit"
  },
  {
    featureId: "EF008731",
    featureDesc: "Luminous flux at emergency operation",
    label: "EF008731: Luminous flux at emergency operation"
  },
  {
    featureId: "EF008732",
    featureDesc: "Recognition distance",
    label: "EF008732: Recognition distance"
  },
  {
    featureId: "EF008733",
    featureDesc: "Communication interface inverter RS-232",
    label: "EF008733: Communication interface inverter RS-232"
  },
  {
    featureId: "EF008734",
    featureDesc: "Communication interface inverter RS-485",
    label: "EF008734: Communication interface inverter RS-485"
  },
  {
    featureId: "EF008735",
    featureDesc: "Communication interface inverter Bluetooth",
    label: "EF008735: Communication interface inverter Bluetooth"
  },
  {
    featureId: "EF008736",
    featureDesc: "Communication interface inverter CAN-Bus",
    label: "EF008736: Communication interface inverter CAN-Bus"
  },
  {
    featureId: "EF008737",
    featureDesc: "Communication interface inverter MOD-Bus",
    label: "EF008737: Communication interface inverter MOD-Bus"
  },
  {
    featureId: "EF008738",
    featureDesc: "Communication interface inverter ethernet",
    label: "EF008738: Communication interface inverter ethernet"
  },
  {
    featureId: "EF008739",
    featureDesc: "Communication interface internet analogue modem",
    label: "EF008739: Communication interface internet analogue modem"
  },
  {
    featureId: "EF008740",
    featureDesc: "Communication interface internet ISDN modem",
    label: "EF008740: Communication interface internet ISDN modem"
  },
  {
    featureId: "EF008741",
    featureDesc: "Communication interface internet GSM modem",
    label: "EF008741: Communication interface internet GSM modem"
  },
  {
    featureId: "EF008742",
    featureDesc: "Communication interface internet ethernet",
    label: "EF008742: Communication interface internet ethernet"
  },
  {
    featureId: "EF008745",
    featureDesc: "Interface S0",
    label: "EF008745: Interface S0"
  },
  {
    featureId: "EF008746",
    featureDesc: "Interface ethernet",
    label: "EF008746: Interface ethernet"
  },
  {
    featureId: "EF008747",
    featureDesc: "Number of independent MPPT inputs",
    label: "EF008747: Number of independent MPPT inputs"
  },
  {
    featureId: "EF008748",
    featureDesc: "Energy consumption (conventional heating)",
    label: "EF008748: Energy consumption (conventional heating)"
  },
  {
    featureId: "EF008749",
    featureDesc: "Energy consumption (recirculating air/hot air)",
    label: "EF008749: Energy consumption (recirculating air/hot air)"
  },
  {
    featureId: "EF008750",
    featureDesc: "Energy consumption (pyrolytic self-cleaning)",
    label: "EF008750: Energy consumption (pyrolytic self-cleaning)"
  },
  {
    featureId: "EF008751",
    featureDesc: "Energy consumption per drying program 'cotton'",
    label: "EF008751: Energy consumption per drying program 'cotton'"
  },
  {
    featureId: "EF008752",
    featureDesc: "Capacity 'cotton'",
    label: "EF008752: Capacity 'cotton'"
  },
  {
    featureId: "EF008755",
    featureDesc: "Measurement of lightning impulse currents",
    label: "EF008755: Measurement of lightning impulse currents"
  },
  {
    featureId: "EF008774",
    featureDesc: "Internet radio",
    label: "EF008774: Internet radio"
  },
  {
    featureId: "EF008776",
    featureDesc: "Current supply primary",
    label: "EF008776: Current supply primary"
  },
  {
    featureId: "EF008777",
    featureDesc: "With voltage indication",
    label: "EF008777: With voltage indication"
  },
  {
    featureId: "EF008778",
    featureDesc: "With backup battery",
    label: "EF008778: With backup battery"
  },
  {
    featureId: "EF008779",
    featureDesc: "Battery included",
    label: "EF008779: Battery included"
  },
  {
    featureId: "EF008780",
    featureDesc: "With rechargeable backup battery",
    label: "EF008780: With rechargeable backup battery"
  },
  {
    featureId: "EF008781",
    featureDesc: "Max. number networkable",
    label: "EF008781: Max. number networkable"
  },
  {
    featureId: "EF008782",
    featureDesc: "Type of test-/pause button",
    label: "EF008782: Type of test-/pause button"
  },
  {
    featureId: "EF008783",
    featureDesc: "Networkable via radio (optional)",
    label: "EF008783: Networkable via radio (optional)"
  },
  {
    featureId: "EF008784",
    featureDesc: "Interchangeable sensor",
    label: "EF008784: Interchangeable sensor"
  },
  {
    featureId: "EF008785",
    featureDesc: "For chainlight",
    label: "EF008785: For chainlight"
  },
  {
    featureId: "EF008786",
    featureDesc: "Suitable for general lighting",
    label: "EF008786: Suitable for general lighting"
  },
  {
    featureId: "EF008789",
    featureDesc: "Max. surface temperature T dust",
    label: "EF008789: Max. surface temperature T dust"
  },
  {
    featureId: "EF008790",
    featureDesc: "Resistor module",
    label: "EF008790: Resistor module"
  },
  {
    featureId: "EF008791",
    featureDesc: "Resistance Rm",
    label: "EF008791: Resistance Rm"
  },
  {
    featureId: "EF008792",
    featureDesc: "Resistance Re",
    label: "EF008792: Resistance Re"
  },
  {
    featureId: "EF008793",
    featureDesc: "Alignment of the control element",
    label: "EF008793: Alignment of the control element"
  },
  {
    featureId: "EF008795",
    featureDesc: "Max. PV-voltage",
    label: "EF008795: Max. PV-voltage"
  },
  {
    featureId: "EF008796",
    featureDesc: "Voltage protection level N-PE",
    label: "EF008796: Voltage protection level N-PE"
  },
  {
    featureId: "EF008797",
    featureDesc: "Integrated backup fuse",
    label: "EF008797: Integrated backup fuse"
  },
  {
    featureId: "EF008798",
    featureDesc: "Nominal voltage AC",
    label: "EF008798: Nominal voltage AC"
  },
  {
    featureId: "EF008799",
    featureDesc: "Nominal voltage DC",
    label: "EF008799: Nominal voltage DC"
  },
  {
    featureId: "EF008800",
    featureDesc: "Pro-active thermo-control",
    label: "EF008800: Pro-active thermo-control"
  },
  {
    featureId: "EF008801",
    featureDesc: "Signal frequency",
    label: "EF008801: Signal frequency"
  },
  {
    featureId: "EF008802",
    featureDesc: "Max. number of counters",
    label: "EF008802: Max. number of counters"
  },
  {
    featureId: "EF008803",
    featureDesc: "Type of primary energy",
    label: "EF008803: Type of primary energy"
  },
  {
    featureId: "EF008804",
    featureDesc: "Interfaces data transmission",
    label: "EF008804: Interfaces data transmission"
  },
  {
    featureId: "EF008805",
    featureDesc: "Interfaces data receiving",
    label: "EF008805: Interfaces data receiving"
  },
  {
    featureId: "EF008806",
    featureDesc: "Suitable for integration of actuators",
    label: "EF008806: Suitable for integration of actuators"
  },
  {
    featureId: "EF008807",
    featureDesc: "Suitable for integration of vehicle charging station",
    label: "EF008807: Suitable for integration of vehicle charging station"
  },
  {
    featureId: "EF008808",
    featureDesc: "Device width",
    label: "EF008808: Device width"
  },
  {
    featureId: "EF008809",
    featureDesc: "Device height",
    label: "EF008809: Device height"
  },
  {
    featureId: "EF008810",
    featureDesc: "Device depth",
    label: "EF008810: Device depth"
  },
  {
    featureId: "EF008811",
    featureDesc: "Equipped with 1",
    label: "EF008811: Equipped with 1"
  },
  {
    featureId: "EF008812",
    featureDesc: "Equipped with 2",
    label: "EF008812: Equipped with 2"
  },
  {
    featureId: "EF008813",
    featureDesc: "Number of socket outlets total",
    label: "EF008813: Number of socket outlets total"
  },
  {
    featureId: "EF008814",
    featureDesc: "Number of socket outlets switchable",
    label: "EF008814: Number of socket outlets switchable"
  },
  {
    featureId: "EF008819",
    featureDesc: "Outer thread cutting",
    label: "EF008819: Outer thread cutting"
  },
  {
    featureId: "EF008820",
    featureDesc: "Inner thread cutting",
    label: "EF008820: Inner thread cutting"
  },
  {
    featureId: "EF008821",
    featureDesc: "Type of mangle",
    label: "EF008821: Type of mangle"
  },
  {
    featureId: "EF008822",
    featureDesc: "With floor temperature sensor",
    label: "EF008822: With floor temperature sensor"
  },
  {
    featureId: "EF008823",
    featureDesc: "Spring loaded tweezers/gripper",
    label: "EF008823: Spring loaded tweezers/gripper"
  },
  {
    featureId: "EF008824",
    featureDesc: "Suitable for oral care appliance",
    label: "EF008824: Suitable for oral care appliance"
  },
  {
    featureId: "EF008825",
    featureDesc: "Suitable for hair care device",
    label: "EF008825: Suitable for hair care device"
  },
  {
    featureId: "EF008826",
    featureDesc: "Suitable for shaver",
    label: "EF008826: Suitable for shaver"
  },
  {
    featureId: "EF008827",
    featureDesc: "Max. conductor cross section solid (solid, stranded)",
    label: "EF008827: Max. conductor cross section solid (solid, stranded)"
  },
  {
    featureId: "EF008828",
    featureDesc: "Max. conductor cross section flexible (fine-strand)",
    label: "EF008828: Max. conductor cross section flexible (fine-strand)"
  },
  {
    featureId: "EF008829",
    featureDesc: "Grid perforated",
    label: "EF008829: Grid perforated"
  },
  {
    featureId: "EF008831",
    featureDesc: "Reflector perforated",
    label: "EF008831: Reflector perforated"
  },
  {
    featureId: "EF008832",
    featureDesc: "Suitable for use by an instructed person",
    label: "EF008832: Suitable for use by an instructed person"
  },
  {
    featureId: "EF008834",
    featureDesc: "Tariff control",
    label: "EF008834: Tariff control"
  },
  {
    featureId: "EF008835",
    featureDesc: "Escapement mechanism",
    label: "EF008835: Escapement mechanism"
  },
  {
    featureId: "EF008836",
    featureDesc: "Calibrated",
    label: "EF008836: Calibrated"
  },
  {
    featureId: "EF008837",
    featureDesc: "Approval",
    label: "EF008837: Approval"
  },
  {
    featureId: "EF008838",
    featureDesc: "Pulse output",
    label: "EF008838: Pulse output"
  },
  {
    featureId: "EF008839",
    featureDesc: "Pulse type",
    label: "EF008839: Pulse type"
  },
  {
    featureId: "EF008840",
    featureDesc: "Pulse rate",
    label: "EF008840: Pulse rate"
  },
  {
    featureId: "EF008842",
    featureDesc: "Accuracy class",
    label: "EF008842: Accuracy class"
  },
  {
    featureId: "EF008843",
    featureDesc: "Pole type",
    label: "EF008843: Pole type"
  },
  {
    featureId: "EF008844",
    featureDesc: "Measurement type load profile",
    label: "EF008844: Measurement type load profile"
  },
  {
    featureId: "EF008845",
    featureDesc: "EEC40 signature",
    label: "EF008845: EEC40 signature"
  },
  {
    featureId: "EF008846",
    featureDesc: "With lock code",
    label: "EF008846: With lock code"
  },
  {
    featureId: "EF008847",
    featureDesc: "Connection type screw connection to housing",
    label: "EF008847: Connection type screw connection to housing"
  },
  {
    featureId: "EF008848",
    featureDesc: "With loudspeaker",
    label: "EF008848: With loudspeaker"
  },
  {
    featureId: "EF008849",
    featureDesc: "Number of ports 100/1000 Mbps Mini GBIC",
    label: "EF008849: Number of ports 100/1000 Mbps Mini GBIC"
  },
  {
    featureId: "EF008850",
    featureDesc: "Number of ports 10 Gbps Mini GBIC",
    label: "EF008850: Number of ports 10 Gbps Mini GBIC"
  },
  {
    featureId: "EF008851",
    featureDesc: "Wi-Fi",
    label: "EF008851: Wi-Fi"
  },
  {
    featureId: "EF008852",
    featureDesc: "Display diagonal (inch)",
    label: "EF008852: Display diagonal (inch)"
  },
  {
    featureId: "EF008853",
    featureDesc: "DVB-C-tuner",
    label: "EF008853: DVB-C-tuner"
  },
  {
    featureId: "EF008854",
    featureDesc: "DVB-S-tuner",
    label: "EF008854: DVB-S-tuner"
  },
  {
    featureId: "EF008855",
    featureDesc: "Bus system KNX-RF (Radio Frequent)",
    label: "EF008855: Bus system KNX-RF (Radio Frequent)"
  },
  {
    featureId: "EF008856",
    featureDesc: "Lamp voltage",
    label: "EF008856: Lamp voltage"
  },
  {
    featureId: "EF008858",
    featureDesc: "Number of units horizontal",
    label: "EF008858: Number of units horizontal"
  },
  {
    featureId: "EF008859",
    featureDesc: "Number of units vertical",
    label: "EF008859: Number of units vertical"
  },
  {
    featureId: "EF008861",
    featureDesc: "With function lighting",
    label: "EF008861: With function lighting"
  },
  {
    featureId: "EF008862",
    featureDesc: "With orientation lighting",
    label: "EF008862: With orientation lighting"
  },
  {
    featureId: "EF008864",
    featureDesc: "Rated apparent power",
    label: "EF008864: Rated apparent power"
  },
  {
    featureId: "EF008865",
    featureDesc: "Loadable for operating system for configuration software, Windows 7",
    label: "EF008865: Loadable for operating system for configuration software, Windows 7"
  },
  {
    featureId: "EF008866",
    featureDesc: "Colour switching equipment",
    label: "EF008866: Colour switching equipment"
  },
  {
    featureId: "EF008868",
    featureDesc: "With separate reading light",
    label: "EF008868: With separate reading light"
  },
  {
    featureId: "EF008869",
    featureDesc: "Stepped shape",
    label: "EF008869: Stepped shape"
  },
  {
    featureId: "EF008871",
    featureDesc: "Nominal voltage (Un) N-L",
    label: "EF008871: Nominal voltage (Un) N-L"
  },
  {
    featureId: "EF008872",
    featureDesc: "Nominal voltage (Un) L-L",
    label: "EF008872: Nominal voltage (Un) L-L"
  },
  {
    featureId: "EF008873",
    featureDesc: "Nominal current (In)",
    label: "EF008873: Nominal current (In)"
  },
  {
    featureId: "EF008874",
    featureDesc: "Rated fault current adjustable",
    label: "EF008874: Rated fault current adjustable"
  },
  {
    featureId: "EF008875",
    featureDesc: "Number of poles",
    label: "EF008875: Number of poles"
  },
  {
    featureId: "EF008876",
    featureDesc: "CB radio",
    label: "EF008876: CB radio"
  },
  {
    featureId: "EF008877",
    featureDesc: "Type carrier",
    label: "EF008877: Type carrier"
  },
  {
    featureId: "EF008878",
    featureDesc: "Cut type",
    label: "EF008878: Cut type"
  },
  {
    featureId: "EF008880",
    featureDesc: "With paper cutter",
    label: "EF008880: With paper cutter"
  },
  {
    featureId: "EF008881",
    featureDesc: "Integrated cash collector",
    label: "EF008881: Integrated cash collector"
  },
  {
    featureId: "EF008882",
    featureDesc: "Daybook",
    label: "EF008882: Daybook"
  },
  {
    featureId: "EF008883",
    featureDesc: "Number of max. punchable sheets",
    label: "EF008883: Number of max. punchable sheets"
  },
  {
    featureId: "EF008884",
    featureDesc: "Number of max. bindable sheets",
    label: "EF008884: Number of max. bindable sheets"
  },
  {
    featureId: "EF008885",
    featureDesc: "Design of the grid/reflector",
    label: "EF008885: Design of the grid/reflector"
  },
  {
    featureId: "EF008886",
    featureDesc: "Tank capacity",
    label: "EF008886: Tank capacity"
  },
  {
    featureId: "EF008887",
    featureDesc: "With automatic start/stop",
    label: "EF008887: With automatic start/stop"
  },
  {
    featureId: "EF008889",
    featureDesc: "Number of cylinders",
    label: "EF008889: Number of cylinders"
  },
  {
    featureId: "EF008890",
    featureDesc: "Piston displacement per minute",
    label: "EF008890: Piston displacement per minute"
  },
  {
    featureId: "EF008891",
    featureDesc: "With motor protection",
    label: "EF008891: With motor protection"
  },
  {
    featureId: "EF008892",
    featureDesc: "With spring security",
    label: "EF008892: With spring security"
  },
  {
    featureId: "EF008893",
    featureDesc: "With outlet valve",
    label: "EF008893: With outlet valve"
  },
  {
    featureId: "EF008894",
    featureDesc: "With condensate drain",
    label: "EF008894: With condensate drain"
  },
  {
    featureId: "EF008896",
    featureDesc: "Hose connection",
    label: "EF008896: Hose connection"
  },
  {
    featureId: "EF008904",
    featureDesc: "DIN rail (top hat rail) mounting optional",
    label: "EF008904: DIN rail (top hat rail) mounting optional"
  },
  {
    featureId: "EF008906",
    featureDesc: "Complete device with protection unit",
    label: "EF008906: Complete device with protection unit"
  },
  {
    featureId: "EF008908",
    featureDesc: "Duty cycle",
    label: "EF008908: Duty cycle"
  },
  {
    featureId: "EF008911",
    featureDesc: "Self-parking",
    label: "EF008911: Self-parking"
  },
  {
    featureId: "EF008912",
    featureDesc: "Adjustable wiping arc",
    label: "EF008912: Adjustable wiping arc"
  },
  {
    featureId: "EF008913",
    featureDesc: "Wiping arc",
    label: "EF008913: Wiping arc"
  },
  {
    featureId: "EF008914",
    featureDesc: "Wiping arc adjustment increment",
    label: "EF008914: Wiping arc adjustment increment"
  },
  {
    featureId: "EF008915",
    featureDesc: "Axis length",
    label: "EF008915: Axis length"
  },
  {
    featureId: "EF008916",
    featureDesc: "Max. arm length",
    label: "EF008916: Max. arm length"
  },
  {
    featureId: "EF008918",
    featureDesc: "Length adjustable",
    label: "EF008918: Length adjustable"
  },
  {
    featureId: "EF008920",
    featureDesc: "Springs adjustable",
    label: "EF008920: Springs adjustable"
  },
  {
    featureId: "EF008921",
    featureDesc: "Number of springs",
    label: "EF008921: Number of springs"
  },
  {
    featureId: "EF008923",
    featureDesc: "Tip length",
    label: "EF008923: Tip length"
  },
  {
    featureId: "EF008925",
    featureDesc: "Hydraulic spindle",
    label: "EF008925: Hydraulic spindle"
  },
  {
    featureId: "EF008926",
    featureDesc: "Width of hooked foot",
    label: "EF008926: Width of hooked foot"
  },
  {
    featureId: "EF008928",
    featureDesc: "Depth of hooked foot",
    label: "EF008928: Depth of hooked foot"
  },
  {
    featureId: "EF008929",
    featureDesc: "Thickness of hooked foot at tip",
    label: "EF008929: Thickness of hooked foot at tip"
  },
  {
    featureId: "EF008931",
    featureDesc: "Scale division",
    label: "EF008931: Scale division"
  },
  {
    featureId: "EF008933",
    featureDesc: "Max. output torque",
    label: "EF008933: Max. output torque"
  },
  {
    featureId: "EF008934",
    featureDesc: "Max. input torque",
    label: "EF008934: Max. input torque"
  },
  {
    featureId: "EF008940",
    featureDesc: "Height of head small",
    label: "EF008940: Height of head small"
  },
  {
    featureId: "EF008941",
    featureDesc: "Height of head large",
    label: "EF008941: Height of head large"
  },
  {
    featureId: "EF008942",
    featureDesc: "Max. reaction measure",
    label: "EF008942: Max. reaction measure"
  },
  {
    featureId: "EF008943",
    featureDesc: "Min. reaction measure",
    label: "EF008943: Min. reaction measure"
  },
  {
    featureId: "EF008944",
    featureDesc: "Music power at 100 V",
    label: "EF008944: Music power at 100 V"
  },
  {
    featureId: "EF008945",
    featureDesc: "Blade diameter",
    label: "EF008945: Blade diameter"
  },
  {
    featureId: "EF008946",
    featureDesc: "Wrench width (hexagon drive)",
    label: "EF008946: Wrench width (hexagon drive)"
  },
  {
    featureId: "EF008947",
    featureDesc: "Connection size",
    label: "EF008947: Connection size"
  },
  {
    featureId: "EF008948",
    featureDesc: "Clamping spread (internal pulling)",
    label: "EF008948: Clamping spread (internal pulling)"
  },
  {
    featureId: "EF008949",
    featureDesc: "Max. clamping spread (external pulling)",
    label: "EF008949: Max. clamping spread (external pulling)"
  },
  {
    featureId: "EF008950",
    featureDesc: "Connection size input square drive",
    label: "EF008950: Connection size input square drive"
  },
  {
    featureId: "EF008951",
    featureDesc: "Connection size output square drive",
    label: "EF008951: Connection size output square drive"
  },
  {
    featureId: "EF008952",
    featureDesc: "With reaction bar",
    label: "EF008952: With reaction bar"
  },
  {
    featureId: "EF008953",
    featureDesc: "With reaction foot",
    label: "EF008953: With reaction foot"
  },
  {
    featureId: "EF008954",
    featureDesc: "With sockets",
    label: "EF008954: With sockets"
  },
  {
    featureId: "EF008955",
    featureDesc: "Swing back angle",
    label: "EF008955: Swing back angle"
  },
  {
    featureId: "EF008956",
    featureDesc: "Half moon model",
    label: "EF008956: Half moon model"
  },
  {
    featureId: "EF008957",
    featureDesc: "100 V amplifier",
    label: "EF008957: 100 V amplifier"
  },
  {
    featureId: "EF008958",
    featureDesc: "Sound projector",
    label: "EF008958: Sound projector"
  },
  {
    featureId: "EF008959",
    featureDesc: "Horn speaker",
    label: "EF008959: Horn speaker"
  },
  {
    featureId: "EF008960",
    featureDesc: "Total number of diodes",
    label: "EF008960: Total number of diodes"
  },
  {
    featureId: "EF008961",
    featureDesc: "Max. current (Imax) per diode",
    label: "EF008961: Max. current (Imax) per diode"
  },
  {
    featureId: "EF008962",
    featureDesc: "Max. operating voltage (Umax)",
    label: "EF008962: Max. operating voltage (Umax)"
  },
  {
    featureId: "EF008963",
    featureDesc: "Common anode",
    label: "EF008963: Common anode"
  },
  {
    featureId: "EF008964",
    featureDesc: "Common cathode",
    label: "EF008964: Common cathode"
  },
  {
    featureId: "EF008965",
    featureDesc: "Reversible",
    label: "EF008965: Reversible"
  },
  {
    featureId: "EF008966",
    featureDesc: "Spatula length",
    label: "EF008966: Spatula length"
  },
  {
    featureId: "EF008967",
    featureDesc: "Wrench size small (inch)",
    label: "EF008967: Wrench size small (inch)"
  },
  {
    featureId: "EF008968",
    featureDesc: "Wrench size large (inch)",
    label: "EF008968: Wrench size large (inch)"
  },
  {
    featureId: "EF008969",
    featureDesc: "Head width small",
    label: "EF008969: Head width small"
  },
  {
    featureId: "EF008970",
    featureDesc: "Head width large",
    label: "EF008970: Head width large"
  },
  {
    featureId: "EF008971",
    featureDesc: "Wrench depth small",
    label: "EF008971: Wrench depth small"
  },
  {
    featureId: "EF008972",
    featureDesc: "Wrench depth large",
    label: "EF008972: Wrench depth large"
  },
  {
    featureId: "EF008973",
    featureDesc: "With tommy bar",
    label: "EF008973: With tommy bar"
  },
  {
    featureId: "EF008977",
    featureDesc: "Suitable for tommy bar",
    label: "EF008977: Suitable for tommy bar"
  },
  {
    featureId: "EF008978",
    featureDesc: "Wrench depth",
    label: "EF008978: Wrench depth"
  },
  {
    featureId: "EF008979",
    featureDesc: "Wrench width (inch)",
    label: "EF008979: Wrench width (inch)"
  },
  {
    featureId: "EF008982",
    featureDesc: "Dimension",
    label: "EF008982: Dimension"
  },
  {
    featureId: "EF008984",
    featureDesc: "Construction ratchet",
    label: "EF008984: Construction ratchet"
  },
  {
    featureId: "EF008989",
    featureDesc: "Number of pliers",
    label: "EF008989: Number of pliers"
  },
  {
    featureId: "EF008990",
    featureDesc: "Number of swivelhead spanners",
    label: "EF008990: Number of swivelhead spanners"
  },
  {
    featureId: "EF008991",
    featureDesc: "Number of double head spanners",
    label: "EF008991: Number of double head spanners"
  },
  {
    featureId: "EF008992",
    featureDesc: "Number of combination spanners",
    label: "EF008992: Number of combination spanners"
  },
  {
    featureId: "EF008993",
    featureDesc: "Right-angled wrench model",
    label: "EF008993: Right-angled wrench model"
  },
  {
    featureId: "EF008995",
    featureDesc: "Step size adjustment",
    label: "EF008995: Step size adjustment"
  },
  {
    featureId: "EF008996",
    featureDesc: "Accuracy scale division",
    label: "EF008996: Accuracy scale division"
  },
  {
    featureId: "EF008997",
    featureDesc: "Suitable for extension",
    label: "EF008997: Suitable for extension"
  },
  {
    featureId: "EF008998",
    featureDesc: "With extension",
    label: "EF008998: With extension"
  },
  {
    featureId: "EF008999",
    featureDesc: "Length extension",
    label: "EF008999: Length extension"
  },
  {
    featureId: "EF009000",
    featureDesc: "Weight head",
    label: "EF009000: Weight head"
  },
  {
    featureId: "EF009001",
    featureDesc: "Weight total",
    label: "EF009001: Weight total"
  },
  {
    featureId: "EF009003",
    featureDesc: "Blade height",
    label: "EF009003: Blade height"
  },
  {
    featureId: "EF009004",
    featureDesc: "Wrench width open end",
    label: "EF009004: Wrench width open end"
  },
  {
    featureId: "EF009005",
    featureDesc: "Wrench width open end (inch)",
    label: "EF009005: Wrench width open end (inch)"
  },
  {
    featureId: "EF009006",
    featureDesc: "Wrench width socket/ring/ratchet",
    label: "EF009006: Wrench width socket/ring/ratchet"
  },
  {
    featureId: "EF009007",
    featureDesc: "Head width open end",
    label: "EF009007: Head width open end"
  },
  {
    featureId: "EF009008",
    featureDesc: "Head width socket/ring/ratchet",
    label: "EF009008: Head width socket/ring/ratchet"
  },
  {
    featureId: "EF009009",
    featureDesc: "Head height open end",
    label: "EF009009: Head height open end"
  },
  {
    featureId: "EF009010",
    featureDesc: "Head height socket/ring/ratchet",
    label: "EF009010: Head height socket/ring/ratchet"
  },
  {
    featureId: "EF009011",
    featureDesc: "Wrench width socket/ring/ratchet (inch)",
    label: "EF009011: Wrench width socket/ring/ratchet (inch)"
  },
  {
    featureId: "EF009012",
    featureDesc: "Scale",
    label: "EF009012: Scale"
  },
  {
    featureId: "EF009013",
    featureDesc: "Cutting edge length",
    label: "EF009013: Cutting edge length"
  },
  {
    featureId: "EF009014",
    featureDesc: "ESD tested",
    label: "EF009014: ESD tested"
  },
  {
    featureId: "EF009015",
    featureDesc: "Number of sockets for screwdriver",
    label: "EF009015: Number of sockets for screwdriver"
  },
  {
    featureId: "EF009016",
    featureDesc: "Number of Torx sockets",
    label: "EF009016: Number of Torx sockets"
  },
  {
    featureId: "EF009017",
    featureDesc: "Number of hexagonal sockets",
    label: "EF009017: Number of hexagonal sockets"
  },
  {
    featureId: "EF009018",
    featureDesc: "Size bit insertion",
    label: "EF009018: Size bit insertion"
  },
  {
    featureId: "EF009019",
    featureDesc: "Size bit insertion",
    label: "EF009019: Size bit insertion"
  },
  {
    featureId: "EF009020",
    featureDesc: "Size machine insertion",
    label: "EF009020: Size machine insertion"
  },
  {
    featureId: "EF009021",
    featureDesc: "Size machine insertion",
    label: "EF009021: Size machine insertion"
  },
  {
    featureId: "EF009022",
    featureDesc: "Socket holder",
    label: "EF009022: Socket holder"
  },
  {
    featureId: "EF009023",
    featureDesc: "Shaft width",
    label: "EF009023: Shaft width"
  },
  {
    featureId: "EF009024",
    featureDesc: "Shaft height",
    label: "EF009024: Shaft height"
  },
  {
    featureId: "EF009025",
    featureDesc: "Connection size socket side",
    label: "EF009025: Connection size socket side"
  },
  {
    featureId: "EF009026",
    featureDesc: "Type at tool side",
    label: "EF009026: Type at tool side"
  },
  {
    featureId: "EF009027",
    featureDesc: "Type at socket side",
    label: "EF009027: Type at socket side"
  },
  {
    featureId: "EF009029",
    featureDesc: "For impact screwdriver",
    label: "EF009029: For impact screwdriver"
  },
  {
    featureId: "EF009030",
    featureDesc: "Number of teeth",
    label: "EF009030: Number of teeth"
  },
  {
    featureId: "EF009031",
    featureDesc: "Thickness saw blade",
    label: "EF009031: Thickness saw blade"
  },
  {
    featureId: "EF009034",
    featureDesc: "Tooth distance",
    label: "EF009034: Tooth distance"
  },
  {
    featureId: "EF009035",
    featureDesc: "Granulation",
    label: "EF009035: Granulation"
  },
  {
    featureId: "EF009036",
    featureDesc: "Suitable for paint",
    label: "EF009036: Suitable for paint"
  },
  {
    featureId: "EF009037",
    featureDesc: "Suitable for glass",
    label: "EF009037: Suitable for glass"
  },
  {
    featureId: "EF009038",
    featureDesc: "Number of sheets",
    label: "EF009038: Number of sheets"
  },
  {
    featureId: "EF009039",
    featureDesc: "Diameter drill",
    label: "EF009039: Diameter drill"
  },
  {
    featureId: "EF009041",
    featureDesc: "Number of hexagonal keys",
    label: "EF009041: Number of hexagonal keys"
  },
  {
    featureId: "EF009042",
    featureDesc: "Number of adjustable wrenches",
    label: "EF009042: Number of adjustable wrenches"
  },
  {
    featureId: "EF009043",
    featureDesc: "Number of bit inserts",
    label: "EF009043: Number of bit inserts"
  },
  {
    featureId: "EF009044",
    featureDesc: "Number of testing tools",
    label: "EF009044: Number of testing tools"
  },
  {
    featureId: "EF009045",
    featureDesc: "Number of ratchets",
    label: "EF009045: Number of ratchets"
  },
  {
    featureId: "EF009046",
    featureDesc: "Number of tommy bars",
    label: "EF009046: Number of tommy bars"
  },
  {
    featureId: "EF009047",
    featureDesc: "Number of chisels and punches",
    label: "EF009047: Number of chisels and punches"
  },
  {
    featureId: "EF009048",
    featureDesc: "Number of hammers",
    label: "EF009048: Number of hammers"
  },
  {
    featureId: "EF009049",
    featureDesc: "Number of multifunctional tools",
    label: "EF009049: Number of multifunctional tools"
  },
  {
    featureId: "EF009050",
    featureDesc: "Number of saws",
    label: "EF009050: Number of saws"
  },
  {
    featureId: "EF009051",
    featureDesc: "With straight cutting edge",
    label: "EF009051: With straight cutting edge"
  },
  {
    featureId: "EF009053",
    featureDesc: "With curved cutting edge",
    label: "EF009053: With curved cutting edge"
  },
  {
    featureId: "EF009054",
    featureDesc: "With claw",
    label: "EF009054: With claw"
  },
  {
    featureId: "EF009055",
    featureDesc: "With ball head",
    label: "EF009055: With ball head"
  },
  {
    featureId: "EF009056",
    featureDesc: "Number of batteries",
    label: "EF009056: Number of batteries"
  },
  {
    featureId: "EF009057",
    featureDesc: "Burn time",
    label: "EF009057: Burn time"
  },
  {
    featureId: "EF009059",
    featureDesc: "Max. clamping width",
    label: "EF009059: Max. clamping width"
  },
  {
    featureId: "EF009060",
    featureDesc: "Material glass",
    label: "EF009060: Material glass"
  },
  {
    featureId: "EF009061",
    featureDesc: "Scratch proof",
    label: "EF009061: Scratch proof"
  },
  {
    featureId: "EF009062",
    featureDesc: "Anti-Fog",
    label: "EF009062: Anti-Fog"
  },
  {
    featureId: "EF009064",
    featureDesc: "Material headband",
    label: "EF009064: Material headband"
  },
  {
    featureId: "EF009066",
    featureDesc: "Cable knife",
    label: "EF009066: Cable knife"
  },
  {
    featureId: "EF009067",
    featureDesc: "Exhalation valve",
    label: "EF009067: Exhalation valve"
  },
  {
    featureId: "EF009068",
    featureDesc: "Filter holder",
    label: "EF009068: Filter holder"
  },
  {
    featureId: "EF009069",
    featureDesc: "Adjustable straps",
    label: "EF009069: Adjustable straps"
  },
  {
    featureId: "EF009071",
    featureDesc: "Knee joint",
    label: "EF009071: Knee joint"
  },
  {
    featureId: "EF009072",
    featureDesc: "Length probe",
    label: "EF009072: Length probe"
  },
  {
    featureId: "EF009073",
    featureDesc: "Bow diameter",
    label: "EF009073: Bow diameter"
  },
  {
    featureId: "EF009074",
    featureDesc: "Inner width opening",
    label: "EF009074: Inner width opening"
  },
  {
    featureId: "EF009076",
    featureDesc: "Cutting diameter",
    label: "EF009076: Cutting diameter"
  },
  {
    featureId: "EF009078",
    featureDesc: "Number of charging units",
    label: "EF009078: Number of charging units"
  },
  {
    featureId: "EF009089",
    featureDesc: "With house connection box",
    label: "EF009089: With house connection box"
  },
  {
    featureId: "EF009091",
    featureDesc: "Number of earth leakage circuit breakers type A",
    label: "EF009091: Number of earth leakage circuit breakers type A"
  },
  {
    featureId: "EF009092",
    featureDesc: "Number of earth leakage circuit breakers type B",
    label: "EF009092: Number of earth leakage circuit breakers type B"
  },
  {
    featureId: "EF009106",
    featureDesc: "Full thread",
    label: "EF009106: Full thread"
  },
  {
    featureId: "EF009109",
    featureDesc: "Toggle nut",
    label: "EF009109: Toggle nut"
  },
  {
    featureId: "EF009110",
    featureDesc: "Max. load at 60° angle",
    label: "EF009110: Max. load at 60° angle"
  },
  {
    featureId: "EF009111",
    featureDesc: "Number of arms",
    label: "EF009111: Number of arms"
  },
  {
    featureId: "EF009113",
    featureDesc: "Connection hook with chain",
    label: "EF009113: Connection hook with chain"
  },
  {
    featureId: "EF009114",
    featureDesc: "Size opening hook",
    label: "EF009114: Size opening hook"
  },
  {
    featureId: "EF009116",
    featureDesc: "Closure type hook",
    label: "EF009116: Closure type hook"
  },
  {
    featureId: "EF009117",
    featureDesc: "With master link",
    label: "EF009117: With master link"
  },
  {
    featureId: "EF009118",
    featureDesc: "Connection master link with chain",
    label: "EF009118: Connection master link with chain"
  },
  {
    featureId: "EF009119",
    featureDesc: "Master link diameter",
    label: "EF009119: Master link diameter"
  },
  {
    featureId: "EF009120",
    featureDesc: "Suitable for old zinc",
    label: "EF009120: Suitable for old zinc"
  },
  {
    featureId: "EF009121",
    featureDesc: "Suitable for new zinc",
    label: "EF009121: Suitable for new zinc"
  },
  {
    featureId: "EF009122",
    featureDesc: "Suitable for lead",
    label: "EF009122: Suitable for lead"
  },
  {
    featureId: "EF009123",
    featureDesc: "Rinseable with water",
    label: "EF009123: Rinseable with water"
  },
  {
    featureId: "EF009124",
    featureDesc: "No-spat",
    label: "EF009124: No-spat"
  },
  {
    featureId: "EF009125",
    featureDesc: "With brush",
    label: "EF009125: With brush"
  },
  {
    featureId: "EF009126",
    featureDesc: "Combustible",
    label: "EF009126: Combustible"
  },
  {
    featureId: "EF009127",
    featureDesc: "Tin percentage",
    label: "EF009127: Tin percentage"
  },
  {
    featureId: "EF009128",
    featureDesc: "Spray can",
    label: "EF009128: Spray can"
  },
  {
    featureId: "EF009129",
    featureDesc: "Max. cartridge diameter",
    label: "EF009129: Max. cartridge diameter"
  },
  {
    featureId: "EF009130",
    featureDesc: "Max. cartridge length",
    label: "EF009130: Max. cartridge length"
  },
  {
    featureId: "EF009131",
    featureDesc: "PU foam gun",
    label: "EF009131: PU foam gun"
  },
  {
    featureId: "EF009132",
    featureDesc: "Lead free",
    label: "EF009132: Lead free"
  },
  {
    featureId: "EF009133",
    featureDesc: "Chromate free",
    label: "EF009133: Chromate free"
  },
  {
    featureId: "EF009134",
    featureDesc: "Quick drying",
    label: "EF009134: Quick drying"
  },
  {
    featureId: "EF009135",
    featureDesc: "Length (inch)",
    label: "EF009135: Length (inch)"
  },
  {
    featureId: "EF009139",
    featureDesc: "Screwdriver model",
    label: "EF009139: Screwdriver model"
  },
  {
    featureId: "EF009142",
    featureDesc: "Double sided drill",
    label: "EF009142: Double sided drill"
  },
  {
    featureId: "EF009143",
    featureDesc: "Bowl wheel",
    label: "EF009143: Bowl wheel"
  },
  {
    featureId: "EF009146",
    featureDesc: "With ball knob",
    label: "EF009146: With ball knob"
  },
  {
    featureId: "EF009147",
    featureDesc: "Metric",
    label: "EF009147: Metric"
  },
  {
    featureId: "EF009148",
    featureDesc: "Conical",
    label: "EF009148: Conical"
  },
  {
    featureId: "EF009149",
    featureDesc: "Breaking torque",
    label: "EF009149: Breaking torque"
  },
  {
    featureId: "EF009150",
    featureDesc: "With ball",
    label: "EF009150: With ball"
  },
  {
    featureId: "EF009152",
    featureDesc: "Required switching force with 80 mm diameter",
    label: "EF009152: Required switching force with 80 mm diameter"
  },
  {
    featureId: "EF009153",
    featureDesc: "Max. force with 80 mm diameter",
    label: "EF009153: Max. force with 80 mm diameter"
  },
  {
    featureId: "EF009154",
    featureDesc: "Max. force per cm²",
    label: "EF009154: Max. force per cm²"
  },
  {
    featureId: "EF009155",
    featureDesc: "With edge trim",
    label: "EF009155: With edge trim"
  },
  {
    featureId: "EF009156",
    featureDesc: "Max. number of addressable charging points",
    label: "EF009156: Max. number of addressable charging points"
  },
  {
    featureId: "EF009157",
    featureDesc: "Number of CEE socket outlets (IEC 60309) 32 A / 400 V / 4P (1, 2, 3, PE)",
    label: "EF009157: Number of CEE socket outlets (IEC 60309) 32 A / 400 V / 4P (1, 2, 3, PE)"
  },
  {
    featureId: "EF009158",
    featureDesc: "Number of earth leakage switches 10 mA",
    label: "EF009158: Number of earth leakage switches 10 mA"
  },
  {
    featureId: "EF009159",
    featureDesc: "Frequency band 1",
    label: "EF009159: Frequency band 1"
  },
  {
    featureId: "EF009160",
    featureDesc: "Frequency band 2",
    label: "EF009160: Frequency band 2"
  },
  {
    featureId: "EF009161",
    featureDesc: "Frequency band 3",
    label: "EF009161: Frequency band 3"
  },
  {
    featureId: "EF009162",
    featureDesc: "Frequency band 4",
    label: "EF009162: Frequency band 4"
  },
  {
    featureId: "EF009163",
    featureDesc: "With bit ratchet",
    label: "EF009163: With bit ratchet"
  },
  {
    featureId: "EF009164",
    featureDesc: "Suitable for bits",
    label: "EF009164: Suitable for bits"
  },
  {
    featureId: "EF009165",
    featureDesc: "Rated permanent current at AC-21, 400 V",
    label: "EF009165: Rated permanent current at AC-21, 400 V"
  },
  {
    featureId: "EF009166",
    featureDesc: "Switching power at 400 V",
    label: "EF009166: Switching power at 400 V"
  },
  {
    featureId: "EF009169",
    featureDesc: "With door",
    label: "EF009169: With door"
  },
  {
    featureId: "EF009170",
    featureDesc: "Material plate thickness cabinet",
    label: "EF009170: Material plate thickness cabinet"
  },
  {
    featureId: "EF009171",
    featureDesc: "Material plate thickness door/cover",
    label: "EF009171: Material plate thickness door/cover"
  },
  {
    featureId: "EF009172",
    featureDesc: "With batteries",
    label: "EF009172: With batteries"
  },
  {
    featureId: "EF009173",
    featureDesc: "Plug-in axle with flat end",
    label: "EF009173: Plug-in axle with flat end"
  },
  {
    featureId: "EF009174",
    featureDesc: "Thickness body",
    label: "EF009174: Thickness body"
  },
  {
    featureId: "EF009175",
    featureDesc: "Max. temperature measuring range",
    label: "EF009175: Max. temperature measuring range"
  },
  {
    featureId: "EF009176",
    featureDesc: "Lowest resolution temperature",
    label: "EF009176: Lowest resolution temperature"
  },
  {
    featureId: "EF009177",
    featureDesc: "Max. capacitance measuring range",
    label: "EF009177: Max. capacitance measuring range"
  },
  {
    featureId: "EF009178",
    featureDesc: "Lowest resolution, capacitance",
    label: "EF009178: Lowest resolution, capacitance"
  },
  {
    featureId: "EF009179",
    featureDesc: "Max. frequency measuring range",
    label: "EF009179: Max. frequency measuring range"
  },
  {
    featureId: "EF009180",
    featureDesc: "Lowest resolution, frequency",
    label: "EF009180: Lowest resolution, frequency"
  },
  {
    featureId: "EF009181",
    featureDesc: "Protection against mechanical risk",
    label: "EF009181: Protection against mechanical risk"
  },
  {
    featureId: "EF009182",
    featureDesc: "Protection against chemical risk",
    label: "EF009182: Protection against chemical risk"
  },
  {
    featureId: "EF009183",
    featureDesc: "Protection against micro-organism risk",
    label: "EF009183: Protection against micro-organism risk"
  },
  {
    featureId: "EF009184",
    featureDesc: "Protection against heat and fire risk",
    label: "EF009184: Protection against heat and fire risk"
  },
  {
    featureId: "EF009185",
    featureDesc: "Protection against cold risk",
    label: "EF009185: Protection against cold risk"
  },
  {
    featureId: "EF009186",
    featureDesc: "Protection against radio-active contamination risk",
    label: "EF009186: Protection against radio-active contamination risk"
  },
  {
    featureId: "EF009187",
    featureDesc: "Protection against ionizing radiation risk",
    label: "EF009187: Protection against ionizing radiation risk"
  },
  {
    featureId: "EF009188",
    featureDesc: "With sweatband",
    label: "EF009188: With sweatband"
  },
  {
    featureId: "EF009189",
    featureDesc: "Suspension",
    label: "EF009189: Suspension"
  },
  {
    featureId: "EF009191",
    featureDesc: "Nominal protection factor (NPF)",
    label: "EF009191: Nominal protection factor (NPF)"
  },
  {
    featureId: "EF009192",
    featureDesc: "Retractable knife",
    label: "EF009192: Retractable knife"
  },
  {
    featureId: "EF009193",
    featureDesc: "Suitable for welding",
    label: "EF009193: Suitable for welding"
  },
  {
    featureId: "EF009194",
    featureDesc: "Suitable for overpressure",
    label: "EF009194: Suitable for overpressure"
  },
  {
    featureId: "EF009195",
    featureDesc: "Fluid tight",
    label: "EF009195: Fluid tight"
  },
  {
    featureId: "EF009196",
    featureDesc: "Material lock case",
    label: "EF009196: Material lock case"
  },
  {
    featureId: "EF009197",
    featureDesc: "Bracket height",
    label: "EF009197: Bracket height"
  },
  {
    featureId: "EF009198",
    featureDesc: "Material (lock) shackle",
    label: "EF009198: Material (lock) shackle"
  },
  {
    featureId: "EF009199",
    featureDesc: "Discus model",
    label: "EF009199: Discus model"
  },
  {
    featureId: "EF009200",
    featureDesc: "Low surface temperature",
    label: "EF009200: Low surface temperature"
  },
  {
    featureId: "EF009201",
    featureDesc: "Universal connectable",
    label: "EF009201: Universal connectable"
  },
  {
    featureId: "EF009204",
    featureDesc: "Provided with revolving ring",
    label: "EF009204: Provided with revolving ring"
  },
  {
    featureId: "EF009206",
    featureDesc: "Housing feed-through by seal membrane",
    label: "EF009206: Housing feed-through by seal membrane"
  },
  {
    featureId: "EF009207",
    featureDesc: "Housing feed-through by nozzle",
    label: "EF009207: Housing feed-through by nozzle"
  },
  {
    featureId: "EF009208",
    featureDesc: "Housing feed-through by step membrane",
    label: "EF009208: Housing feed-through by step membrane"
  },
  {
    featureId: "EF009209",
    featureDesc: "Pipe locking optional",
    label: "EF009209: Pipe locking optional"
  },
  {
    featureId: "EF009210",
    featureDesc: "Number of included spouts",
    label: "EF009210: Number of included spouts"
  },
  {
    featureId: "EF009212",
    featureDesc: "Cover model",
    label: "EF009212: Cover model"
  },
  {
    featureId: "EF009213",
    featureDesc: "Number of extra push buttons",
    label: "EF009213: Number of extra push buttons"
  },
  {
    featureId: "EF009214",
    featureDesc: "Internal bypass",
    label: "EF009214: Internal bypass"
  },
  {
    featureId: "EF009215",
    featureDesc: "Torque control",
    label: "EF009215: Torque control"
  },
  {
    featureId: "EF009216",
    featureDesc: "Espagnolette locking (3-point locking)",
    label: "EF009216: Espagnolette locking (3-point locking)"
  },
  {
    featureId: "EF009219",
    featureDesc: "Overload protection",
    label: "EF009219: Overload protection"
  },
  {
    featureId: "EF009220",
    featureDesc: "Overtemperature protection",
    label: "EF009220: Overtemperature protection"
  },
  {
    featureId: "EF009221",
    featureDesc: "Hard metal version",
    label: "EF009221: Hard metal version"
  },
  {
    featureId: "EF009223",
    featureDesc: "Number of shelves",
    label: "EF009223: Number of shelves"
  },
  {
    featureId: "EF009225",
    featureDesc: "Nominal input voltage",
    label: "EF009225: Nominal input voltage"
  },
  {
    featureId: "EF009226",
    featureDesc: "Nominal input current inverter",
    label: "EF009226: Nominal input current inverter"
  },
  {
    featureId: "EF009227",
    featureDesc: "Nominal input current generator",
    label: "EF009227: Nominal input current generator"
  },
  {
    featureId: "EF009228",
    featureDesc: "Nominal input current shore",
    label: "EF009228: Nominal input current shore"
  },
  {
    featureId: "EF009229",
    featureDesc: "With filter mat",
    label: "EF009229: With filter mat"
  },
  {
    featureId: "EF009230",
    featureDesc: "Suitable for wire/chain link diameter",
    label: "EF009230: Suitable for wire/chain link diameter"
  },
  {
    featureId: "EF009231",
    featureDesc: "Brake holding load",
    label: "EF009231: Brake holding load"
  },
  {
    featureId: "EF009232",
    featureDesc: "Hauling speed",
    label: "EF009232: Hauling speed"
  },
  {
    featureId: "EF009233",
    featureDesc: "Reel capacity",
    label: "EF009233: Reel capacity"
  },
  {
    featureId: "EF009234",
    featureDesc: "With wire/chain guide",
    label: "EF009234: With wire/chain guide"
  },
  {
    featureId: "EF009235",
    featureDesc: "With warping head",
    label: "EF009235: With warping head"
  },
  {
    featureId: "EF009239",
    featureDesc: "Transfer time switch off",
    label: "EF009239: Transfer time switch off"
  },
  {
    featureId: "EF009240",
    featureDesc: "Transfer time switch on",
    label: "EF009240: Transfer time switch on"
  },
  {
    featureId: "EF009241",
    featureDesc: "Frequency watch",
    label: "EF009241: Frequency watch"
  },
  {
    featureId: "EF009242",
    featureDesc: "Generator input time delay",
    label: "EF009242: Generator input time delay"
  },
  {
    featureId: "EF009243",
    featureDesc: "Frame size according to IEC",
    label: "EF009243: Frame size according to IEC"
  },
  {
    featureId: "EF009244",
    featureDesc: "Full-load efficiency",
    label: "EF009244: Full-load efficiency"
  },
  {
    featureId: "EF009245",
    featureDesc: "Starting current ratio",
    label: "EF009245: Starting current ratio"
  },
  {
    featureId: "EF009246",
    featureDesc: "Starting torque ratio",
    label: "EF009246: Starting torque ratio"
  },
  {
    featureId: "EF009247",
    featureDesc: "Breakdown torque ratio",
    label: "EF009247: Breakdown torque ratio"
  },
  {
    featureId: "EF009248",
    featureDesc: "Voltage anti-condensation heater",
    label: "EF009248: Voltage anti-condensation heater"
  },
  {
    featureId: "EF009249",
    featureDesc: "Output power anti-condensation heater",
    label: "EF009249: Output power anti-condensation heater"
  },
  {
    featureId: "EF009250",
    featureDesc: "With positive temperature coefficient resistor (PTC)",
    label: "EF009250: With positive temperature coefficient resistor (PTC)"
  },
  {
    featureId: "EF009251",
    featureDesc: "With resistance temperature detector (RTD)",
    label: "EF009251: With resistance temperature detector (RTD)"
  },
  {
    featureId: "EF009252",
    featureDesc: "Intrinsic safety (IS)",
    label: "EF009252: Intrinsic safety (IS)"
  },
  {
    featureId: "EF009253",
    featureDesc: "Overload capability",
    label: "EF009253: Overload capability"
  },
  {
    featureId: "EF009254",
    featureDesc: "Cooling method",
    label: "EF009254: Cooling method"
  },
  {
    featureId: "EF009255",
    featureDesc: "Shaft end type",
    label: "EF009255: Shaft end type"
  },
  {
    featureId: "EF009256",
    featureDesc: "Vacuum pressure impregnation (VPI)",
    label: "EF009256: Vacuum pressure impregnation (VPI)"
  },
  {
    featureId: "EF009257",
    featureDesc: "Generator power",
    label: "EF009257: Generator power"
  },
  {
    featureId: "EF009258",
    featureDesc: "With built-in USB power supply",
    label: "EF009258: With built-in USB power supply"
  },
  {
    featureId: "EF009259",
    featureDesc: "Number of buttons with LED",
    label: "EF009259: Number of buttons with LED"
  },
  {
    featureId: "EF009260",
    featureDesc: "Number of system buttons",
    label: "EF009260: Number of system buttons"
  },
  {
    featureId: "EF009262",
    featureDesc: "Throughflow capacity (min. 10 kPa)",
    label: "EF009262: Throughflow capacity (min. 10 kPa)"
  },
  {
    featureId: "EF009263",
    featureDesc: "Throughflow capacity (min. 15 kPa)",
    label: "EF009263: Throughflow capacity (min. 15 kPa)"
  },
  {
    featureId: "EF009264",
    featureDesc: "With flow measuring point",
    label: "EF009264: With flow measuring point"
  },
  {
    featureId: "EF009265",
    featureDesc: "Automatic flow control",
    label: "EF009265: Automatic flow control"
  },
  {
    featureId: "EF009268",
    featureDesc: "With integrated replenishment automat",
    label: "EF009268: With integrated replenishment automat"
  },
  {
    featureId: "EF009272",
    featureDesc: "Primary frequency",
    label: "EF009272: Primary frequency"
  },
  {
    featureId: "EF009273",
    featureDesc: "Secondary frequency",
    label: "EF009273: Secondary frequency"
  },
  {
    featureId: "EF009274",
    featureDesc: "Number of phases secondary",
    label: "EF009274: Number of phases secondary"
  },
  {
    featureId: "EF009275",
    featureDesc: "Number of phases primary",
    label: "EF009275: Number of phases primary"
  },
  {
    featureId: "EF009276",
    featureDesc: "Auto shut down function",
    label: "EF009276: Auto shut down function"
  },
  {
    featureId: "EF009277",
    featureDesc: "Diameter bore hole (opening)",
    label: "EF009277: Diameter bore hole (opening)"
  },
  {
    featureId: "EF009278",
    featureDesc: "Pitched roof",
    label: "EF009278: Pitched roof"
  },
  {
    featureId: "EF009279",
    featureDesc: "Cooling capacity 25 °C - 7/12 perceived high",
    label: "EF009279: Cooling capacity 25 °C - 7/12 perceived high"
  },
  {
    featureId: "EF009280",
    featureDesc: "Cooling capacity 25 °C - 7/12 perceived medium",
    label: "EF009280: Cooling capacity 25 °C - 7/12 perceived medium"
  },
  {
    featureId: "EF009281",
    featureDesc: "Cooling capacity 25 °C - 7/12 perceived low",
    label: "EF009281: Cooling capacity 25 °C - 7/12 perceived low"
  },
  {
    featureId: "EF009282",
    featureDesc: "Wireless range",
    label: "EF009282: Wireless range"
  },
  {
    featureId: "EF009283",
    featureDesc: "Connection for microphone",
    label: "EF009283: Connection for microphone"
  },
  {
    featureId: "EF009284",
    featureDesc: "With mouse",
    label: "EF009284: With mouse"
  },
  {
    featureId: "EF009286",
    featureDesc: "With trackball",
    label: "EF009286: With trackball"
  },
  {
    featureId: "EF009291",
    featureDesc: "Outer pipe diameter tap water",
    label: "EF009291: Outer pipe diameter tap water"
  },
  {
    featureId: "EF009300",
    featureDesc: "Max. power per charging point",
    label: "EF009300: Max. power per charging point"
  },
  {
    featureId: "EF009301",
    featureDesc: "Number of vehicle connectors Type 1",
    label: "EF009301: Number of vehicle connectors Type 1"
  },
  {
    featureId: "EF009302",
    featureDesc: "Number of vehicle connectors Type 1 CCS",
    label: "EF009302: Number of vehicle connectors Type 1 CCS"
  },
  {
    featureId: "EF009303",
    featureDesc: "Number of vehicle connectors Type 2",
    label: "EF009303: Number of vehicle connectors Type 2"
  },
  {
    featureId: "EF009304",
    featureDesc: "Number of vehicle connectors Type 2 CCS",
    label: "EF009304: Number of vehicle connectors Type 2 CCS"
  },
  {
    featureId: "EF009305",
    featureDesc: "Number of socket outlets Type 2",
    label: "EF009305: Number of socket outlets Type 2"
  },
  {
    featureId: "EF009306",
    featureDesc: "Number of vehicle connectors GB AC",
    label: "EF009306: Number of vehicle connectors GB AC"
  },
  {
    featureId: "EF009307",
    featureDesc: "Number of vehicle connectors GB DC",
    label: "EF009307: Number of vehicle connectors GB DC"
  },
  {
    featureId: "EF009308",
    featureDesc: "Number of socket outlets GB",
    label: "EF009308: Number of socket outlets GB"
  },
  {
    featureId: "EF009309",
    featureDesc: "Number of socket outlets Type 3",
    label: "EF009309: Number of socket outlets Type 3"
  },
  {
    featureId: "EF009310",
    featureDesc: "Number of vehicle connectors CHAdeMO",
    label: "EF009310: Number of vehicle connectors CHAdeMO"
  },
  {
    featureId: "EF009311",
    featureDesc: "Number of household socket outlets",
    label: "EF009311: Number of household socket outlets"
  },
  {
    featureId: "EF009312",
    featureDesc: "With load management",
    label: "EF009312: With load management"
  },
  {
    featureId: "EF009313",
    featureDesc: "With energy meter",
    label: "EF009313: With energy meter"
  },
  {
    featureId: "EF009314",
    featureDesc: "With miniature circuit breaker (MCB)",
    label: "EF009314: With miniature circuit breaker (MCB)"
  },
  {
    featureId: "EF009315",
    featureDesc: "Loading current",
    label: "EF009315: Loading current"
  },
  {
    featureId: "EF009316",
    featureDesc: "Emergency release at power failure",
    label: "EF009316: Emergency release at power failure"
  },
  {
    featureId: "EF009317",
    featureDesc: "Number of communication conductors",
    label: "EF009317: Number of communication conductors"
  },
  {
    featureId: "EF009318",
    featureDesc: "Type vehicle side",
    label: "EF009318: Type vehicle side"
  },
  {
    featureId: "EF009319",
    featureDesc: "Type infrastructure side",
    label: "EF009319: Type infrastructure side"
  },
  {
    featureId: "EF009320",
    featureDesc: "With Incable Control Box (ICCB)",
    label: "EF009320: With Incable Control Box (ICCB)"
  },
  {
    featureId: "EF009321",
    featureDesc: "Charging mode",
    label: "EF009321: Charging mode"
  },
  {
    featureId: "EF009322",
    featureDesc: "Standard",
    label: "EF009322: Standard"
  },
  {
    featureId: "EF009323",
    featureDesc: "With interlocking actuator",
    label: "EF009323: With interlocking actuator"
  },
  {
    featureId: "EF009324",
    featureDesc: "With access interlocking",
    label: "EF009324: With access interlocking"
  },
  {
    featureId: "EF009325",
    featureDesc: "Number of communication contacts",
    label: "EF009325: Number of communication contacts"
  },
  {
    featureId: "EF009326",
    featureDesc: "Max. nominal current",
    label: "EF009326: Max. nominal current"
  },
  {
    featureId: "EF009327",
    featureDesc: "Length of the fixed connection cable",
    label: "EF009327: Length of the fixed connection cable"
  },
  {
    featureId: "EF009328",
    featureDesc: "LED integrated",
    label: "EF009328: LED integrated"
  },
  {
    featureId: "EF009329",
    featureDesc: "Cross section of the power contacts",
    label: "EF009329: Cross section of the power contacts"
  },
  {
    featureId: "EF009330",
    featureDesc: "Simulation vehicle status",
    label: "EF009330: Simulation vehicle status"
  },
  {
    featureId: "EF009331",
    featureDesc: "Simulation conductor cross section",
    label: "EF009331: Simulation conductor cross section"
  },
  {
    featureId: "EF009332",
    featureDesc: "Simulation fault current",
    label: "EF009332: Simulation fault current"
  },
  {
    featureId: "EF009333",
    featureDesc: "Status display charging process",
    label: "EF009333: Status display charging process"
  },
  {
    featureId: "EF009334",
    featureDesc: "Test PE connection",
    label: "EF009334: Test PE connection"
  },
  {
    featureId: "EF009335",
    featureDesc: "Measurement resistance charging connector",
    label: "EF009335: Measurement resistance charging connector"
  },
  {
    featureId: "EF009336",
    featureDesc: "Signal output",
    label: "EF009336: Signal output"
  },
  {
    featureId: "EF009337",
    featureDesc: "Connection type 1",
    label: "EF009337: Connection type 1"
  },
  {
    featureId: "EF009338",
    featureDesc: "Connection type 1 CCS",
    label: "EF009338: Connection type 1 CCS"
  },
  {
    featureId: "EF009339",
    featureDesc: "Connection type 2",
    label: "EF009339: Connection type 2"
  },
  {
    featureId: "EF009340",
    featureDesc: "Connection type 2 CCS",
    label: "EF009340: Connection type 2 CCS"
  },
  {
    featureId: "EF009341",
    featureDesc: "Connection type 3",
    label: "EF009341: Connection type 3"
  },
  {
    featureId: "EF009342",
    featureDesc: "Connection GB",
    label: "EF009342: Connection GB"
  },
  {
    featureId: "EF009343",
    featureDesc: "Connection CHAdeMO",
    label: "EF009343: Connection CHAdeMO"
  },
  {
    featureId: "EF009345",
    featureDesc: "Nominal current",
    label: "EF009345: Nominal current"
  },
  {
    featureId: "EF009346",
    featureDesc: "Colour temperature",
    label: "EF009346: Colour temperature"
  },
  {
    featureId: "EF009347",
    featureDesc: "Max. system power",
    label: "EF009347: Max. system power"
  },
  {
    featureId: "EF009349",
    featureDesc: "Rated luminous flux according to IEC 62722-2-1",
    label: "EF009349: Rated luminous flux according to IEC 62722-2-1"
  },
  {
    featureId: "EF009350",
    featureDesc: "Colour of light",
    label: "EF009350: Colour of light"
  },
  {
    featureId: "EF009351",
    featureDesc: "Adjustability",
    label: "EF009351: Adjustability"
  },
  {
    featureId: "EF009352",
    featureDesc: "Suitable for traverse mounting",
    label: "EF009352: Suitable for traverse mounting"
  },
  {
    featureId: "EF009353",
    featureDesc: "Suitable for pedestal/floor mounting",
    label: "EF009353: Suitable for pedestal/floor mounting"
  },
  {
    featureId: "EF009354",
    featureDesc: "Suitable for light-track mounting",
    label: "EF009354: Suitable for light-track mounting"
  },
  {
    featureId: "EF009355",
    featureDesc: "Remote control",
    label: "EF009355: Remote control"
  },
  {
    featureId: "EF009356",
    featureDesc: "With radio control",
    label: "EF009356: With radio control"
  },
  {
    featureId: "EF009358",
    featureDesc: "With light regulation program control",
    label: "EF009358: With light regulation program control"
  },
  {
    featureId: "EF009364",
    featureDesc: "Suitable for additional heating central heating",
    label: "EF009364: Suitable for additional heating central heating"
  },
  {
    featureId: "EF009365",
    featureDesc: "Connection for headphones",
    label: "EF009365: Connection for headphones"
  },
  {
    featureId: "EF009366",
    featureDesc: "Diameter without insulation",
    label: "EF009366: Diameter without insulation"
  },
  {
    featureId: "EF009367",
    featureDesc: "Number of immersion tubes",
    label: "EF009367: Number of immersion tubes"
  },
  {
    featureId: "EF009368",
    featureDesc: "Diameter immersion tube",
    label: "EF009368: Diameter immersion tube"
  },
  {
    featureId: "EF009369",
    featureDesc: "Connection electrical element",
    label: "EF009369: Connection electrical element"
  },
  {
    featureId: "EF009370",
    featureDesc: "With perforation for dust extraction",
    label: "EF009370: With perforation for dust extraction"
  },
  {
    featureId: "EF009371",
    featureDesc: "Hook-and-loop fastening",
    label: "EF009371: Hook-and-loop fastening"
  },
  {
    featureId: "EF009372",
    featureDesc: "With air slots",
    label: "EF009372: With air slots"
  },
  {
    featureId: "EF009374",
    featureDesc: "Thread connection controller",
    label: "EF009374: Thread connection controller"
  },
  {
    featureId: "EF009376",
    featureDesc: "Suitable for number of lamps",
    label: "EF009376: Suitable for number of lamps"
  },
  {
    featureId: "EF009377",
    featureDesc: "With fitting piece for heat meter",
    label: "EF009377: With fitting piece for heat meter"
  },
  {
    featureId: "EF009378",
    featureDesc: "With heat meter",
    label: "EF009378: With heat meter"
  },
  {
    featureId: "EF009379",
    featureDesc: "Primary supply temperature",
    label: "EF009379: Primary supply temperature"
  },
  {
    featureId: "EF009380",
    featureDesc: "Primary pressure difference",
    label: "EF009380: Primary pressure difference"
  },
  {
    featureId: "EF009381",
    featureDesc: "Tap water power",
    label: "EF009381: Tap water power"
  },
  {
    featureId: "EF009384",
    featureDesc: "Adjustment range hot water temperature",
    label: "EF009384: Adjustment range hot water temperature"
  },
  {
    featureId: "EF009386",
    featureDesc: "Regulation for central heating boiler control",
    label: "EF009386: Regulation for central heating boiler control"
  },
  {
    featureId: "EF009387",
    featureDesc: "Regulation for buffer tank control",
    label: "EF009387: Regulation for buffer tank control"
  },
  {
    featureId: "EF009388",
    featureDesc: "Heating power electrical element adjustable in stages",
    label: "EF009388: Heating power electrical element adjustable in stages"
  },
  {
    featureId: "EF009389",
    featureDesc: "Nominal power tap water",
    label: "EF009389: Nominal power tap water"
  },
  {
    featureId: "EF009390",
    featureDesc: "Nominal load upper value tap water",
    label: "EF009390: Nominal load upper value tap water"
  },
  {
    featureId: "EF009392",
    featureDesc: "Nominal load lower value tap water",
    label: "EF009392: Nominal load lower value tap water"
  },
  {
    featureId: "EF009393",
    featureDesc: "Nominal value output voltage 1",
    label: "EF009393: Nominal value output voltage 1"
  },
  {
    featureId: "EF009394",
    featureDesc: "Nominal value output voltage 2",
    label: "EF009394: Nominal value output voltage 2"
  },
  {
    featureId: "EF009395",
    featureDesc: "Nominal value output voltage 3",
    label: "EF009395: Nominal value output voltage 3"
  },
  {
    featureId: "EF009396",
    featureDesc: "Nominal value output current 1",
    label: "EF009396: Nominal value output current 1"
  },
  {
    featureId: "EF009397",
    featureDesc: "Nominal value output current 2",
    label: "EF009397: Nominal value output current 2"
  },
  {
    featureId: "EF009398",
    featureDesc: "Nominal value output current 3",
    label: "EF009398: Nominal value output current 3"
  },
  {
    featureId: "EF009399",
    featureDesc: "Signalling",
    label: "EF009399: Signalling"
  },
  {
    featureId: "EF009400",
    featureDesc: "Colour depth",
    label: "EF009400: Colour depth"
  },
  {
    featureId: "EF009401",
    featureDesc: "With IR lamp",
    label: "EF009401: With IR lamp"
  },
  {
    featureId: "EF009403",
    featureDesc: "SIL according to EN 62061",
    label: "EF009403: SIL according to EN 62061"
  },
  {
    featureId: "EF009404",
    featureDesc: "Max. voltage distortion output (linear load)",
    label: "EF009404: Max. voltage distortion output (linear load)"
  },
  {
    featureId: "EF009405",
    featureDesc: "Output power factor",
    label: "EF009405: Output power factor"
  },
  {
    featureId: "EF009406",
    featureDesc: "Overall efficiency",
    label: "EF009406: Overall efficiency"
  },
  {
    featureId: "EF009407",
    featureDesc: "Efficiency in eco-mode",
    label: "EF009407: Efficiency in eco-mode"
  },
  {
    featureId: "EF009408",
    featureDesc: "Version as reversing switch",
    label: "EF009408: Version as reversing switch"
  },
  {
    featureId: "EF009409",
    featureDesc: "Arc-proof",
    label: "EF009409: Arc-proof"
  },
  {
    featureId: "EF009410",
    featureDesc: "Position control element",
    label: "EF009410: Position control element"
  },
  {
    featureId: "EF009411",
    featureDesc: "With day indication",
    label: "EF009411: With day indication"
  },
  {
    featureId: "EF009412",
    featureDesc: "Number of cores single core cable",
    label: "EF009412: Number of cores single core cable"
  },
  {
    featureId: "EF009413",
    featureDesc: "Number of cores signal-/telecommunications cable",
    label: "EF009413: Number of cores signal-/telecommunications cable"
  },
  {
    featureId: "EF009414",
    featureDesc: "Number of coaxial cables",
    label: "EF009414: Number of coaxial cables"
  },
  {
    featureId: "EF009415",
    featureDesc: "Number of data cables",
    label: "EF009415: Number of data cables"
  },
  {
    featureId: "EF009416",
    featureDesc: "With draw wire",
    label: "EF009416: With draw wire"
  },
  {
    featureId: "EF009417",
    featureDesc: "Number of cores low voltage power cable",
    label: "EF009417: Number of cores low voltage power cable"
  },
  {
    featureId: "EF009418",
    featureDesc: "Number of loudspeaker cables",
    label: "EF009418: Number of loudspeaker cables"
  },
  {
    featureId: "EF009419",
    featureDesc: "Label space/information surface",
    label: "EF009419: Label space/information surface"
  },
  {
    featureId: "EF009420",
    featureDesc: "Function lighting",
    label: "EF009420: Function lighting"
  },
  {
    featureId: "EF009421",
    featureDesc: "With tangible imprint",
    label: "EF009421: With tangible imprint"
  },
  {
    featureId: "EF009422",
    featureDesc: "With cable lugs",
    label: "EF009422: With cable lugs"
  },
  {
    featureId: "EF009424",
    featureDesc: "External controller required",
    label: "EF009424: External controller required"
  },
  {
    featureId: "EF009426",
    featureDesc: "Modulation percentage",
    label: "EF009426: Modulation percentage"
  },
  {
    featureId: "EF009427",
    featureDesc: "External pressure system pump available at nominal flow",
    label: "EF009427: External pressure system pump available at nominal flow"
  },
  {
    featureId: "EF009428",
    featureDesc: "Data transfer via bus connection",
    label: "EF009428: Data transfer via bus connection"
  },
  {
    featureId: "EF009434",
    featureDesc: "Type of pump (system pump)",
    label: "EF009434: Type of pump (system pump)"
  },
  {
    featureId: "EF009435",
    featureDesc: "Type voltage pump (system pump)",
    label: "EF009435: Type voltage pump (system pump)"
  },
  {
    featureId: "EF009436",
    featureDesc: "Speed control pump (system pump)",
    label: "EF009436: Speed control pump (system pump)"
  },
  {
    featureId: "EF009437",
    featureDesc: "Type of speed control (system pump)",
    label: "EF009437: Type of speed control (system pump)"
  },
  {
    featureId: "EF009438",
    featureDesc: "Sofstarter built-in",
    label: "EF009438: Sofstarter built-in"
  },
  {
    featureId: "EF009439",
    featureDesc: "Limitation max. electric power consumption",
    label: "EF009439: Limitation max. electric power consumption"
  },
  {
    featureId: "EF009440",
    featureDesc: "Suitable for active cooling",
    label: "EF009440: Suitable for active cooling"
  },
  {
    featureId: "EF009441",
    featureDesc: "Cooling performance at 35/18 °C according to EN 14511",
    label: "EF009441: Cooling performance at 35/18 °C according to EN 14511"
  },
  {
    featureId: "EF009442",
    featureDesc: "Cooling performance at 35/7 °C according to EN 14511",
    label: "EF009442: Cooling performance at 35/7 °C according to EN 14511"
  },
  {
    featureId: "EF009443",
    featureDesc: "Number of BNC sockets",
    label: "EF009443: Number of BNC sockets"
  },
  {
    featureId: "EF009444",
    featureDesc: "Number of RCA audio sockets",
    label: "EF009444: Number of RCA audio sockets"
  },
  {
    featureId: "EF009445",
    featureDesc: "Number of jack sockets 2.5 mm",
    label: "EF009445: Number of jack sockets 2.5 mm"
  },
  {
    featureId: "EF009446",
    featureDesc: "Number of jack sockets 3.5 mm",
    label: "EF009446: Number of jack sockets 3.5 mm"
  },
  {
    featureId: "EF009447",
    featureDesc: "Number of jack sockets 6.3 mm",
    label: "EF009447: Number of jack sockets 6.3 mm"
  },
  {
    featureId: "EF009448",
    featureDesc: "Number of composite video sockets",
    label: "EF009448: Number of composite video sockets"
  },
  {
    featureId: "EF009449",
    featureDesc: "Number of component video sockets",
    label: "EF009449: Number of component video sockets"
  },
  {
    featureId: "EF009450",
    featureDesc: "Number of D-Sub sockets 15-pole",
    label: "EF009450: Number of D-Sub sockets 15-pole"
  },
  {
    featureId: "EF009451",
    featureDesc: "Number of D-Sub sockets 9-pole",
    label: "EF009451: Number of D-Sub sockets 9-pole"
  },
  {
    featureId: "EF009452",
    featureDesc: "Number of DVI sockets",
    label: "EF009452: Number of DVI sockets"
  },
  {
    featureId: "EF009453",
    featureDesc: "Number of Sat F sockets",
    label: "EF009453: Number of Sat F sockets"
  },
  {
    featureId: "EF009455",
    featureDesc: "Number of S-Video sockets",
    label: "EF009455: Number of S-Video sockets"
  },
  {
    featureId: "EF009456",
    featureDesc: "Number of WBT sockets",
    label: "EF009456: Number of WBT sockets"
  },
  {
    featureId: "EF009457",
    featureDesc: "Number of XLR sockets",
    label: "EF009457: Number of XLR sockets"
  },
  {
    featureId: "EF009458",
    featureDesc: "Number of HDMI sockets",
    label: "EF009458: Number of HDMI sockets"
  },
  {
    featureId: "EF009459",
    featureDesc: "Hot tap water control integrated",
    label: "EF009459: Hot tap water control integrated"
  },
  {
    featureId: "EF009460",
    featureDesc: "Number of USB sockets",
    label: "EF009460: Number of USB sockets"
  },
  {
    featureId: "EF009461",
    featureDesc: "Number of loud speaker connections",
    label: "EF009461: Number of loud speaker connections"
  },
  {
    featureId: "EF009462",
    featureDesc: "Number of RJ45 sockets",
    label: "EF009462: Number of RJ45 sockets"
  },
  {
    featureId: "EF009463",
    featureDesc: "Number of Scart sockets",
    label: "EF009463: Number of Scart sockets"
  },
  {
    featureId: "EF009464",
    featureDesc: "Number of TNC sockets",
    label: "EF009464: Number of TNC sockets"
  },
  {
    featureId: "EF009465",
    featureDesc: "Number of coax sockets according to IEC 60169-2",
    label: "EF009465: Number of coax sockets according to IEC 60169-2"
  },
  {
    featureId: "EF009466",
    featureDesc: "Radio frequent bidirectional",
    label: "EF009466: Radio frequent bidirectional"
  },
  {
    featureId: "EF009467",
    featureDesc: "Shape of jaw",
    label: "EF009467: Shape of jaw"
  },
  {
    featureId: "EF009468",
    featureDesc: "Diameter of coil/ring",
    label: "EF009468: Diameter of coil/ring"
  },
  {
    featureId: "EF009469",
    featureDesc: "Measuring range for frequency",
    label: "EF009469: Measuring range for frequency"
  },
  {
    featureId: "EF009470",
    featureDesc: "Suitable for clamp-mounting",
    label: "EF009470: Suitable for clamp-mounting"
  },
  {
    featureId: "EF009471",
    featureDesc: "Suitable for constant current",
    label: "EF009471: Suitable for constant current"
  },
  {
    featureId: "EF009472",
    featureDesc: "Suitable for constant voltage",
    label: "EF009472: Suitable for constant voltage"
  },
  {
    featureId: "EF009473",
    featureDesc: "Suitable for DC-voltage (primary side)",
    label: "EF009473: Suitable for DC-voltage (primary side)"
  },
  {
    featureId: "EF009474",
    featureDesc: "Operating current",
    label: "EF009474: Operating current"
  },
  {
    featureId: "EF009475",
    featureDesc: "For cable outer diameter",
    label: "EF009475: For cable outer diameter"
  },
  {
    featureId: "EF009476",
    featureDesc: "Castable",
    label: "EF009476: Castable"
  },
  {
    featureId: "EF009477",
    featureDesc: "With casting compound",
    label: "EF009477: With casting compound"
  },
  {
    featureId: "EF009478",
    featureDesc: "Diameter wall opening",
    label: "EF009478: Diameter wall opening"
  },
  {
    featureId: "EF009480",
    featureDesc: "Dismantling possible",
    label: "EF009480: Dismantling possible"
  },
  {
    featureId: "EF009481",
    featureDesc: "Cable bundling possible",
    label: "EF009481: Cable bundling possible"
  },
  {
    featureId: "EF009482",
    featureDesc: "Constant light output (CLO)",
    label: "EF009482: Constant light output (CLO)"
  },
  {
    featureId: "EF009483",
    featureDesc: "Weighted energy consumption in 1000 hours",
    label: "EF009483: Weighted energy consumption in 1000 hours"
  },
  {
    featureId: "EF009484",
    featureDesc: "Number of colour channels",
    label: "EF009484: Number of colour channels"
  },
  {
    featureId: "EF009485",
    featureDesc: "Length of chain/band loop",
    label: "EF009485: Length of chain/band loop"
  },
  {
    featureId: "EF009486",
    featureDesc: "With anti-nail protection plate",
    label: "EF009486: With anti-nail protection plate"
  },
  {
    featureId: "EF009487",
    featureDesc: "With metatarsal guard",
    label: "EF009487: With metatarsal guard"
  },
  {
    featureId: "EF009488",
    featureDesc: "Rated operation current Ie at AC-51",
    label: "EF009488: Rated operation current Ie at AC-51"
  },
  {
    featureId: "EF009489",
    featureDesc: "Rated operation current Ie at AC-53a",
    label: "EF009489: Rated operation current Ie at AC-53a"
  },
  {
    featureId: "EF009490",
    featureDesc: "Number of CEE socket outlets (IEC 60309) 16 A / 24 V / 2P (L, N)",
    label: "EF009490: Number of CEE socket outlets (IEC 60309) 16 A / 24 V / 2P (L, N)"
  },
  {
    featureId: "EF009491",
    featureDesc: "Number of CEE socket outlets (IEC 60309) 16 A / 230 V / 3P (L, N, PE)",
    label: "EF009491: Number of CEE socket outlets (IEC 60309) 16 A / 230 V / 3P (L, N, PE)"
  },
  {
    featureId: "EF009492",
    featureDesc: "Number of CEE socket outlets (IEC 60309) 16 A / 400 V / 4P (1, 2, 3, PE)",
    label: "EF009492: Number of CEE socket outlets (IEC 60309) 16 A / 400 V / 4P (1, 2, 3, PE)"
  },
  {
    featureId: "EF009493",
    featureDesc: "Dual band network",
    label: "EF009493: Dual band network"
  },
  {
    featureId: "EF009494",
    featureDesc: "Diameter at base",
    label: "EF009494: Diameter at base"
  },
  {
    featureId: "EF009495",
    featureDesc: "Integrated power-supply for cameras",
    label: "EF009495: Integrated power-supply for cameras"
  },
  {
    featureId: "EF009496",
    featureDesc: "Number of socket outlets Italian standard type Bipasso",
    label: "EF009496: Number of socket outlets Italian standard type Bipasso"
  },
  {
    featureId: "EF009497",
    featureDesc: "Anti-nuisance tripping version",
    label: "EF009497: Anti-nuisance tripping version"
  },
  {
    featureId: "EF009499",
    featureDesc: "Tapered handle",
    label: "EF009499: Tapered handle"
  },
  {
    featureId: "EF009502",
    featureDesc: "Type of rotor",
    label: "EF009502: Type of rotor"
  },
  {
    featureId: "EF009503",
    featureDesc: "Number of rotor blades",
    label: "EF009503: Number of rotor blades"
  },
  {
    featureId: "EF009504",
    featureDesc: "Engaging wind speed",
    label: "EF009504: Engaging wind speed"
  },
  {
    featureId: "EF009505",
    featureDesc: "Shutdown wind speed",
    label: "EF009505: Shutdown wind speed"
  },
  {
    featureId: "EF009506",
    featureDesc: "Length of module",
    label: "EF009506: Length of module"
  },
  {
    featureId: "EF009507",
    featureDesc: "Width of module",
    label: "EF009507: Width of module"
  },
  {
    featureId: "EF009508",
    featureDesc: "Height at top-edge",
    label: "EF009508: Height at top-edge"
  },
  {
    featureId: "EF009509",
    featureDesc: "Module efficiency factor (STC)",
    label: "EF009509: Module efficiency factor (STC)"
  },
  {
    featureId: "EF009510",
    featureDesc: "Reverse current load",
    label: "EF009510: Reverse current load"
  },
  {
    featureId: "EF009511",
    featureDesc: "With alternating-current converter",
    label: "EF009511: With alternating-current converter"
  },
  {
    featureId: "EF009512",
    featureDesc: "Max. generator power",
    label: "EF009512: Max. generator power"
  },
  {
    featureId: "EF009513",
    featureDesc: "Number of socket outlets Italian standard type P11",
    label: "EF009513: Number of socket outlets Italian standard type P11"
  },
  {
    featureId: "EF009514",
    featureDesc: "Number of NEMA-outlets",
    label: "EF009514: Number of NEMA-outlets"
  },
  {
    featureId: "EF009515",
    featureDesc: "Punch sizes",
    label: "EF009515: Punch sizes"
  },
  {
    featureId: "EF009516",
    featureDesc: "With hexagonal nut",
    label: "EF009516: With hexagonal nut"
  },
  {
    featureId: "EF009517",
    featureDesc: "With acorn nut",
    label: "EF009517: With acorn nut"
  },
  {
    featureId: "EF009518",
    featureDesc: "Fluorescent",
    label: "EF009518: Fluorescent"
  },
  {
    featureId: "EF009524",
    featureDesc: "Suitable for other tube materials",
    label: "EF009524: Suitable for other tube materials"
  },
  {
    featureId: "EF009525",
    featureDesc: "With scrub surface",
    label: "EF009525: With scrub surface"
  },
  {
    featureId: "EF009526",
    featureDesc: "Number of drilling machines",
    label: "EF009526: Number of drilling machines"
  },
  {
    featureId: "EF009527",
    featureDesc: "Number of machines",
    label: "EF009527: Number of machines"
  },
  {
    featureId: "EF009528",
    featureDesc: "Number of saw blades",
    label: "EF009528: Number of saw blades"
  },
  {
    featureId: "EF009529",
    featureDesc: "Number of grinders",
    label: "EF009529: Number of grinders"
  },
  {
    featureId: "EF009531",
    featureDesc: "Lined",
    label: "EF009531: Lined"
  },
  {
    featureId: "EF009532",
    featureDesc: "Colour of lens(es)",
    label: "EF009532: Colour of lens(es)"
  },
  {
    featureId: "EF009533",
    featureDesc: "Mirrored",
    label: "EF009533: Mirrored"
  },
  {
    featureId: "EF009534",
    featureDesc: "Antireflective glasses",
    label: "EF009534: Antireflective glasses"
  },
  {
    featureId: "EF009535",
    featureDesc: "Type of venting",
    label: "EF009535: Type of venting"
  },
  {
    featureId: "EF009536",
    featureDesc: "Darkening",
    label: "EF009536: Darkening"
  },
  {
    featureId: "EF009537",
    featureDesc: "Material quality headband",
    label: "EF009537: Material quality headband"
  },
  {
    featureId: "EF009538",
    featureDesc: "Carrying type",
    label: "EF009538: Carrying type"
  },
  {
    featureId: "EF009539",
    featureDesc: "Active noise cancelling",
    label: "EF009539: Active noise cancelling"
  },
  {
    featureId: "EF009540",
    featureDesc: "Replaceable cushions",
    label: "EF009540: Replaceable cushions"
  },
  {
    featureId: "EF009541",
    featureDesc: "Thigh protector",
    label: "EF009541: Thigh protector"
  },
  {
    featureId: "EF009542",
    featureDesc: "Knee protector",
    label: "EF009542: Knee protector"
  },
  {
    featureId: "EF009543",
    featureDesc: "Shin protector",
    label: "EF009543: Shin protector"
  },
  {
    featureId: "EF009544",
    featureDesc: "With foot protector",
    label: "EF009544: With foot protector"
  },
  {
    featureId: "EF009545",
    featureDesc: "SD-card slot",
    label: "EF009545: SD-card slot"
  },
  {
    featureId: "EF009546",
    featureDesc: "MicroSD-card slot",
    label: "EF009546: MicroSD-card slot"
  },
  {
    featureId: "EF009547",
    featureDesc: "Long-Term Evolution (LTE)",
    label: "EF009547: Long-Term Evolution (LTE)"
  },
  {
    featureId: "EF009548",
    featureDesc: "Camera on the front",
    label: "EF009548: Camera on the front"
  },
  {
    featureId: "EF009549",
    featureDesc: "Resolution camera on the back",
    label: "EF009549: Resolution camera on the back"
  },
  {
    featureId: "EF009550",
    featureDesc: "USB connection with host function",
    label: "EF009550: USB connection with host function"
  },
  {
    featureId: "EF009551",
    featureDesc: "Sync-connection",
    label: "EF009551: Sync-connection"
  },
  {
    featureId: "EF009552",
    featureDesc: "Graphics output",
    label: "EF009552: Graphics output"
  },
  {
    featureId: "EF009553",
    featureDesc: "Pluggable external keyboard",
    label: "EF009553: Pluggable external keyboard"
  },
  {
    featureId: "EF009554",
    featureDesc: "Number of openings for flange plates",
    label: "EF009554: Number of openings for flange plates"
  },
  {
    featureId: "EF009555",
    featureDesc: "Rated short-circuit breaking capacity Icn according to EN 60898 at 400 V",
    label: "EF009555: Rated short-circuit breaking capacity Icn according to EN 60898 at 400 V"
  },
  {
    featureId: "EF009556",
    featureDesc: "Rated short-circuit breaking capacity Icn according to EN 60898 at 230 V",
    label: "EF009556: Rated short-circuit breaking capacity Icn according to EN 60898 at 230 V"
  },
  {
    featureId: "EF009557",
    featureDesc: "Rated short-circuit breaking capacity Icu according to IEC 60947-2 at 230 V",
    label: "EF009557: Rated short-circuit breaking capacity Icu according to IEC 60947-2 at 230 V"
  },
  {
    featureId: "EF009558",
    featureDesc: "Rated short-circuit breaking capacity Icu according to IEC 60947-2 at 400 V",
    label: "EF009558: Rated short-circuit breaking capacity Icu according to IEC 60947-2 at 400 V"
  },
  {
    featureId: "EF009560",
    featureDesc: "Max. output voltage",
    label: "EF009560: Max. output voltage"
  },
  {
    featureId: "EF009561",
    featureDesc: "Nominal output current I2N",
    label: "EF009561: Nominal output current I2N"
  },
  {
    featureId: "EF009563",
    featureDesc: "Material connection lug",
    label: "EF009563: Material connection lug"
  },
  {
    featureId: "EF009564",
    featureDesc: "With automatic night switch-off",
    label: "EF009564: With automatic night switch-off"
  },
  {
    featureId: "EF009566",
    featureDesc: "Pivoting range sensor, horizontal",
    label: "EF009566: Pivoting range sensor, horizontal"
  },
  {
    featureId: "EF009567",
    featureDesc: "Pivoting range sensor, vertical",
    label: "EF009567: Pivoting range sensor, vertical"
  },
  {
    featureId: "EF009568",
    featureDesc: "Number of switching zones",
    label: "EF009568: Number of switching zones"
  },
  {
    featureId: "EF009569",
    featureDesc: "With DALI interface",
    label: "EF009569: With DALI interface"
  },
  {
    featureId: "EF009571",
    featureDesc: "Number of modules horizontal (module system)",
    label: "EF009571: Number of modules horizontal (module system)"
  },
  {
    featureId: "EF009572",
    featureDesc: "Number of modules vertical (module system)",
    label: "EF009572: Number of modules vertical (module system)"
  },
  {
    featureId: "EF009573",
    featureDesc: "Number of modules (module system)",
    label: "EF009573: Number of modules (module system)"
  },
  {
    featureId: "EF009574",
    featureDesc: "Suitable for modular domestic switching devices",
    label: "EF009574: Suitable for modular domestic switching devices"
  },
  {
    featureId: "EF009577",
    featureDesc: "Mounting method of the module inserts",
    label: "EF009577: Mounting method of the module inserts"
  },
  {
    featureId: "EF009579",
    featureDesc: "Suitable for mounting in round junction box 60 mm",
    label: "EF009579: Suitable for mounting in round junction box 60 mm"
  },
  {
    featureId: "EF009580",
    featureDesc: "Suitable for mounting on DIN-rail 35 mm",
    label: "EF009580: Suitable for mounting on DIN-rail 35 mm"
  },
  {
    featureId: "EF009582",
    featureDesc: "Pole fastening",
    label: "EF009582: Pole fastening"
  },
  {
    featureId: "EF009583",
    featureDesc: "Suitable for enclosure building height",
    label: "EF009583: Suitable for enclosure building height"
  },
  {
    featureId: "EF009584",
    featureDesc: "Suitable for roof planking",
    label: "EF009584: Suitable for roof planking"
  },
  {
    featureId: "EF009585",
    featureDesc: "Suitable for bottom planking",
    label: "EF009585: Suitable for bottom planking"
  },
  {
    featureId: "EF009586",
    featureDesc: "For internal separation form 2a",
    label: "EF009586: For internal separation form 2a"
  },
  {
    featureId: "EF009587",
    featureDesc: "For internal separation form 2b",
    label: "EF009587: For internal separation form 2b"
  },
  {
    featureId: "EF009588",
    featureDesc: "For internal separation form 3a",
    label: "EF009588: For internal separation form 3a"
  },
  {
    featureId: "EF009589",
    featureDesc: "For internal separation form 3b",
    label: "EF009589: For internal separation form 3b"
  },
  {
    featureId: "EF009590",
    featureDesc: "For internal separation form 4a",
    label: "EF009590: For internal separation form 4a"
  },
  {
    featureId: "EF009591",
    featureDesc: "For internal separation form 4b",
    label: "EF009591: For internal separation form 4b"
  },
  {
    featureId: "EF009592",
    featureDesc: "Busbar position",
    label: "EF009592: Busbar position"
  },
  {
    featureId: "EF009598",
    featureDesc: "Type of motor starter",
    label: "EF009598: Type of motor starter"
  },
  {
    featureId: "EF009599",
    featureDesc: "Rated operation power at AC-3, 230 V, 3-phase",
    label: "EF009599: Rated operation power at AC-3, 230 V, 3-phase"
  },
  {
    featureId: "EF009600",
    featureDesc: "Rated power, 460 V, 60 Hz, 3-phase",
    label: "EF009600: Rated power, 460 V, 60 Hz, 3-phase"
  },
  {
    featureId: "EF009601",
    featureDesc: "Rated power, 575 V, 60 Hz, 3-phase",
    label: "EF009601: Rated power, 575 V, 60 Hz, 3-phase"
  },
  {
    featureId: "EF009602",
    featureDesc: "Rated operation current at AC-3, 400 V",
    label: "EF009602: Rated operation current at AC-3, 400 V"
  },
  {
    featureId: "EF009603",
    featureDesc: "Ambient temperature, upper operating limit",
    label: "EF009603: Ambient temperature, upper operating limit"
  },
  {
    featureId: "EF009604",
    featureDesc: "Temperature compensated overload protection",
    label: "EF009604: Temperature compensated overload protection"
  },
  {
    featureId: "EF009605",
    featureDesc: "Rated conditional short-circuit current, type 2, 400 V",
    label: "EF009605: Rated conditional short-circuit current, type 2, 400 V"
  },
  {
    featureId: "EF009606",
    featureDesc: "Rated conditional short-circuit current, type 2, 230 V",
    label: "EF009606: Rated conditional short-circuit current, type 2, 230 V"
  },
  {
    featureId: "EF009607",
    featureDesc: "Rated conditional short-circuit current, type 1, 480 Y/277 V",
    label: "EF009607: Rated conditional short-circuit current, type 1, 480 Y/277 V"
  },
  {
    featureId: "EF009608",
    featureDesc: "Rated conditional short-circuit current, type 1, 600 Y/347 V",
    label: "EF009608: Rated conditional short-circuit current, type 1, 600 Y/347 V"
  },
  {
    featureId: "EF009610",
    featureDesc: "Number of sensor inputs for IEPE sensors",
    label: "EF009610: Number of sensor inputs for IEPE sensors"
  },
  {
    featureId: "EF009611",
    featureDesc: "Number of sensor inputs for MEMS sensors",
    label: "EF009611: Number of sensor inputs for MEMS sensors"
  },
  {
    featureId: "EF009612",
    featureDesc: "Number of rotational speed inputs",
    label: "EF009612: Number of rotational speed inputs"
  },
  {
    featureId: "EF009613",
    featureDesc: "Number of indicator outputs",
    label: "EF009613: Number of indicator outputs"
  },
  {
    featureId: "EF009614",
    featureDesc: "Measuring range vibration frequency",
    label: "EF009614: Measuring range vibration frequency"
  },
  {
    featureId: "EF009617",
    featureDesc: "Access via web server",
    label: "EF009617: Access via web server"
  },
  {
    featureId: "EF009619",
    featureDesc: "Measuring range vibration acceleration (gravity g)",
    label: "EF009619: Measuring range vibration acceleration (gravity g)"
  },
  {
    featureId: "EF009620",
    featureDesc: "Type of UV radiation",
    label: "EF009620: Type of UV radiation"
  },
  {
    featureId: "EF009621",
    featureDesc: "Suitable for air disinfection",
    label: "EF009621: Suitable for air disinfection"
  },
  {
    featureId: "EF009622",
    featureDesc: "Suitable for personal well-being",
    label: "EF009622: Suitable for personal well-being"
  },
  {
    featureId: "EF009623",
    featureDesc: "Suitable for insect trapping",
    label: "EF009623: Suitable for insect trapping"
  },
  {
    featureId: "EF009624",
    featureDesc: "Suitable for medical applications",
    label: "EF009624: Suitable for medical applications"
  },
  {
    featureId: "EF009625",
    featureDesc: "Suitable for reprography",
    label: "EF009625: Suitable for reprography"
  },
  {
    featureId: "EF009626",
    featureDesc: "Suitable for sun-tanning",
    label: "EF009626: Suitable for sun-tanning"
  },
  {
    featureId: "EF009628",
    featureDesc: "Suitable for water disinfection",
    label: "EF009628: Suitable for water disinfection"
  },
  {
    featureId: "EF009629",
    featureDesc: "Mercury content",
    label: "EF009629: Mercury content"
  },
  {
    featureId: "EF009630",
    featureDesc: "External driver required",
    label: "EF009630: External driver required"
  },
  {
    featureId: "EF009632",
    featureDesc: "Prospective short-circuit current",
    label: "EF009632: Prospective short-circuit current"
  },
  {
    featureId: "EF009633",
    featureDesc: "Max. permitted short-circuit duration (at 25 kA)",
    label: "EF009633: Max. permitted short-circuit duration (at 25 kA)"
  },
  {
    featureId: "EF009634",
    featureDesc: "Typical reaction time (at 25 kA)",
    label: "EF009634: Typical reaction time (at 25 kA)"
  },
  {
    featureId: "EF009635",
    featureDesc: "Version as basin",
    label: "EF009635: Version as basin"
  },
  {
    featureId: "EF009636",
    featureDesc: "Max. rated voltage",
    label: "EF009636: Max. rated voltage"
  },
  {
    featureId: "EF009638",
    featureDesc: "Max. equivalent separation distance s (in air)",
    label: "EF009638: Max. equivalent separation distance s (in air)"
  },
  {
    featureId: "EF009639",
    featureDesc: "Max. equivalent separation distance s (solid building materials)",
    label: "EF009639: Max. equivalent separation distance s (solid building materials)"
  },
  {
    featureId: "EF009643",
    featureDesc: "Voltage protection level (DC+ - DC-)",
    label: "EF009643: Voltage protection level (DC+ - DC-)"
  },
  {
    featureId: "EF009644",
    featureDesc: "Voltage protection level (DC+/DC- - PE)",
    label: "EF009644: Voltage protection level (DC+/DC- - PE)"
  },
  {
    featureId: "EF009645",
    featureDesc: "With web interface",
    label: "EF009645: With web interface"
  },
  {
    featureId: "EF009647",
    featureDesc: "Max. number storable records",
    label: "EF009647: Max. number storable records"
  },
  {
    featureId: "EF009649",
    featureDesc: "Toothing",
    label: "EF009649: Toothing"
  },
  {
    featureId: "EF009650",
    featureDesc: "Intercom",
    label: "EF009650: Intercom"
  },
  {
    featureId: "EF009651",
    featureDesc: "Colour push button",
    label: "EF009651: Colour push button"
  },
  {
    featureId: "EF009652",
    featureDesc: "Suitable for bus connection",
    label: "EF009652: Suitable for bus connection"
  },
  {
    featureId: "EF009653",
    featureDesc: "Button function",
    label: "EF009653: Button function"
  },
  {
    featureId: "EF009654",
    featureDesc: "With connection for",
    label: "EF009654: With connection for"
  },
  {
    featureId: "EF009655",
    featureDesc: "With Q-label",
    label: "EF009655: With Q-label"
  },
  {
    featureId: "EF009657",
    featureDesc: "Nominal cross section main conductor, SE",
    label: "EF009657: Nominal cross section main conductor, SE"
  },
  {
    featureId: "EF009658",
    featureDesc: "Nominal cross section main conductor, SM",
    label: "EF009658: Nominal cross section main conductor, SM"
  },
  {
    featureId: "EF009659",
    featureDesc: "Nominal cross section branch conductor, SM/RM",
    label: "EF009659: Nominal cross section branch conductor, SM/RM"
  },
  {
    featureId: "EF009660",
    featureDesc: "Nominal cross section branch conductor, SE/RE",
    label: "EF009660: Nominal cross section branch conductor, SE/RE"
  },
  {
    featureId: "EF009661",
    featureDesc: "Material main conductor",
    label: "EF009661: Material main conductor"
  },
  {
    featureId: "EF009662",
    featureDesc: "Material branch conductor",
    label: "EF009662: Material branch conductor"
  },
  {
    featureId: "EF009665",
    featureDesc: "Terminal insulated",
    label: "EF009665: Terminal insulated"
  },
  {
    featureId: "EF009666",
    featureDesc: "Mounting under voltage possible",
    label: "EF009666: Mounting under voltage possible"
  },
  {
    featureId: "EF009667",
    featureDesc: "Clamping channel opening main conductor",
    label: "EF009667: Clamping channel opening main conductor"
  },
  {
    featureId: "EF009668",
    featureDesc: "Clamping channel opening branch conductor",
    label: "EF009668: Clamping channel opening branch conductor"
  },
  {
    featureId: "EF009669",
    featureDesc: "Nominal cross section branch conductor, SE",
    label: "EF009669: Nominal cross section branch conductor, SE"
  },
  {
    featureId: "EF009670",
    featureDesc: "Nominal cross section branch conductor, RE",
    label: "EF009670: Nominal cross section branch conductor, RE"
  },
  {
    featureId: "EF009671",
    featureDesc: "Nominal cross section branch conductor, SM",
    label: "EF009671: Nominal cross section branch conductor, SM"
  },
  {
    featureId: "EF009672",
    featureDesc: "Nominal cross section branch conductor, RM",
    label: "EF009672: Nominal cross section branch conductor, RM"
  },
  {
    featureId: "EF009673",
    featureDesc: "Nominal cross section copper, RM",
    label: "EF009673: Nominal cross section copper, RM"
  },
  {
    featureId: "EF009674",
    featureDesc: "Nominal cross section copper, RE",
    label: "EF009674: Nominal cross section copper, RE"
  },
  {
    featureId: "EF009675",
    featureDesc: "Nominal cross section copper, SM",
    label: "EF009675: Nominal cross section copper, SM"
  },
  {
    featureId: "EF009676",
    featureDesc: "Nominal cross section aluminium, RM",
    label: "EF009676: Nominal cross section aluminium, RM"
  },
  {
    featureId: "EF009677",
    featureDesc: "Nominal cross section aluminium, RE",
    label: "EF009677: Nominal cross section aluminium, RE"
  },
  {
    featureId: "EF009678",
    featureDesc: "Nominal cross section aluminium, SM",
    label: "EF009678: Nominal cross section aluminium, SM"
  },
  {
    featureId: "EF009679",
    featureDesc: "Nominal cross section aluminium, SE",
    label: "EF009679: Nominal cross section aluminium, SE"
  },
  {
    featureId: "EF009680",
    featureDesc: "Material conductor",
    label: "EF009680: Material conductor"
  },
  {
    featureId: "EF009681",
    featureDesc: "With connection accessories",
    label: "EF009681: With connection accessories"
  },
  {
    featureId: "EF009683",
    featureDesc: "Nominal cross section aluminium, RM/SM",
    label: "EF009683: Nominal cross section aluminium, RM/SM"
  },
  {
    featureId: "EF009684",
    featureDesc: "Active testing",
    label: "EF009684: Active testing"
  },
  {
    featureId: "EF009685",
    featureDesc: "Passive testing",
    label: "EF009685: Passive testing"
  },
  {
    featureId: "EF009686",
    featureDesc: "Alternative method",
    label: "EF009686: Alternative method"
  },
  {
    featureId: "EF009687",
    featureDesc: "Differential current method",
    label: "EF009687: Differential current method"
  },
  {
    featureId: "EF009688",
    featureDesc: "Direct method",
    label: "EF009688: Direct method"
  },
  {
    featureId: "EF009689",
    featureDesc: "Bending radius, dynamic",
    label: "EF009689: Bending radius, dynamic"
  },
  {
    featureId: "EF009690",
    featureDesc: "Connection cross section (fine-strand)",
    label: "EF009690: Connection cross section (fine-strand)"
  },
  {
    featureId: "EF009691",
    featureDesc: "Connection cross section (solid-core)",
    label: "EF009691: Connection cross section (solid-core)"
  },
  {
    featureId: "EF009692",
    featureDesc: "Max. continuous temperature resistance contact part",
    label: "EF009692: Max. continuous temperature resistance contact part"
  },
  {
    featureId: "EF009693",
    featureDesc: "Max. continuous temperature resistance strain relief",
    label: "EF009693: Max. continuous temperature resistance strain relief"
  },
  {
    featureId: "EF009694",
    featureDesc: "Rated current to luminaire",
    label: "EF009694: Rated current to luminaire"
  },
  {
    featureId: "EF009695",
    featureDesc: "Length soldering pin",
    label: "EF009695: Length soldering pin"
  },
  {
    featureId: "EF009696",
    featureDesc: "Mounting possibility",
    label: "EF009696: Mounting possibility"
  },
  {
    featureId: "EF009697",
    featureDesc: "Wall thickness housing",
    label: "EF009697: Wall thickness housing"
  },
  {
    featureId: "EF009698",
    featureDesc: "Max. operating temperature at conductor",
    label: "EF009698: Max. operating temperature at conductor"
  },
  {
    featureId: "EF009699",
    featureDesc: "Frequency band",
    label: "EF009699: Frequency band"
  },
  {
    featureId: "EF009700",
    featureDesc: "Number of ports 10/100/1000 Mbps LAN",
    label: "EF009700: Number of ports 10/100/1000 Mbps LAN"
  },
  {
    featureId: "EF009701",
    featureDesc: "Beam angle horizontal",
    label: "EF009701: Beam angle horizontal"
  },
  {
    featureId: "EF009702",
    featureDesc: "Beam angle vertical",
    label: "EF009702: Beam angle vertical"
  },
  {
    featureId: "EF009703",
    featureDesc: "Radio standard eGPRS",
    label: "EF009703: Radio standard eGPRS"
  },
  {
    featureId: "EF009704",
    featureDesc: "Radio standard HSUPA",
    label: "EF009704: Radio standard HSUPA"
  },
  {
    featureId: "EF009705",
    featureDesc: "Radio standard HSDPA",
    label: "EF009705: Radio standard HSDPA"
  },
  {
    featureId: "EF009706",
    featureDesc: "Radio standard HSPA+",
    label: "EF009706: Radio standard HSPA+"
  },
  {
    featureId: "EF009707",
    featureDesc: "Radio standard CDMA",
    label: "EF009707: Radio standard CDMA"
  },
  {
    featureId: "EF009708",
    featureDesc: "Radio standard EV-DO",
    label: "EF009708: Radio standard EV-DO"
  },
  {
    featureId: "EF009709",
    featureDesc: "Radio standard LTE",
    label: "EF009709: Radio standard LTE"
  },
  {
    featureId: "EF009710",
    featureDesc: "Radio standard WAN ADSL",
    label: "EF009710: Radio standard WAN ADSL"
  },
  {
    featureId: "EF009711",
    featureDesc: "Radio standard WAN ADSL2",
    label: "EF009711: Radio standard WAN ADSL2"
  },
  {
    featureId: "EF009712",
    featureDesc: "Radio standard WAN ADSL2+",
    label: "EF009712: Radio standard WAN ADSL2+"
  },
  {
    featureId: "EF009713",
    featureDesc: "Radio standard WAN SHDSL",
    label: "EF009713: Radio standard WAN SHDSL"
  },
  {
    featureId: "EF009714",
    featureDesc: "Power over Ethernet (PoE)",
    label: "EF009714: Power over Ethernet (PoE)"
  },
  {
    featureId: "EF009715",
    featureDesc: "Number of ports PoE",
    label: "EF009715: Number of ports PoE"
  },
  {
    featureId: "EF009716",
    featureDesc: "Max. PoE power",
    label: "EF009716: Max. PoE power"
  },
  {
    featureId: "EF009717",
    featureDesc: "Duplex printing",
    label: "EF009717: Duplex printing"
  },
  {
    featureId: "EF009719",
    featureDesc: "Number of analogue public network connections",
    label: "EF009719: Number of analogue public network connections"
  },
  {
    featureId: "EF009720",
    featureDesc: "Number of VoIP-channels (external)",
    label: "EF009720: Number of VoIP-channels (external)"
  },
  {
    featureId: "EF009721",
    featureDesc: "Number of VoIP-channels (internal)",
    label: "EF009721: Number of VoIP-channels (internal)"
  },
  {
    featureId: "EF009722",
    featureDesc: "Number of VoIP-channels (internal/external)",
    label: "EF009722: Number of VoIP-channels (internal/external)"
  },
  {
    featureId: "EF009723",
    featureDesc: "Max. number of VoIP-channels, optional",
    label: "EF009723: Max. number of VoIP-channels, optional"
  },
  {
    featureId: "EF009724",
    featureDesc: "Number of voicemail-/fax boxes",
    label: "EF009724: Number of voicemail-/fax boxes"
  },
  {
    featureId: "EF009725",
    featureDesc: "Max. number of voicemail-/fax boxes, optional",
    label: "EF009725: Max. number of voicemail-/fax boxes, optional"
  },
  {
    featureId: "EF009726",
    featureDesc: "Input connection",
    label: "EF009726: Input connection"
  },
  {
    featureId: "EF009727",
    featureDesc: "Number of output connections C13",
    label: "EF009727: Number of output connections C13"
  },
  {
    featureId: "EF009728",
    featureDesc: "Number of output connections C19",
    label: "EF009728: Number of output connections C19"
  },
  {
    featureId: "EF009729",
    featureDesc: "Number of output connections with protective contact CEE 7/3 (type F)",
    label: "EF009729: Number of output connections with protective contact CEE 7/3 (type F)"
  },
  {
    featureId: "EF009730",
    featureDesc: "Number of output connections fixed connection",
    label: "EF009730: Number of output connections fixed connection"
  },
  {
    featureId: "EF009731",
    featureDesc: "Wrench width (square drive)",
    label: "EF009731: Wrench width (square drive)"
  },
  {
    featureId: "EF009732",
    featureDesc: "Square drive/hexagon drive",
    label: "EF009732: Square drive/hexagon drive"
  },
  {
    featureId: "EF009734",
    featureDesc: "Square drive/hexagon drive over the whole blade",
    label: "EF009734: Square drive/hexagon drive over the whole blade"
  },
  {
    featureId: "EF009735",
    featureDesc: "Length short leg",
    label: "EF009735: Length short leg"
  },
  {
    featureId: "EF009736",
    featureDesc: "Lamella model",
    label: "EF009736: Lamella model"
  },
  {
    featureId: "EF009738",
    featureDesc: "Square rod",
    label: "EF009738: Square rod"
  },
  {
    featureId: "EF009739",
    featureDesc: "Double pulley",
    label: "EF009739: Double pulley"
  },
  {
    featureId: "EF009740",
    featureDesc: "Private automatic branch exchange (PABE) necessary",
    label: "EF009740: Private automatic branch exchange (PABE) necessary"
  },
  {
    featureId: "EF009741",
    featureDesc: "Function blinking light",
    label: "EF009741: Function blinking light"
  },
  {
    featureId: "EF009742",
    featureDesc: "Solar operation",
    label: "EF009742: Solar operation"
  },
  {
    featureId: "EF009743",
    featureDesc: "With cam clamp",
    label: "EF009743: With cam clamp"
  },
  {
    featureId: "EF009744",
    featureDesc: "With tap spreader",
    label: "EF009744: With tap spreader"
  },
  {
    featureId: "EF009745",
    featureDesc: "Width/diameter draw-in wire",
    label: "EF009745: Width/diameter draw-in wire"
  },
  {
    featureId: "EF009746",
    featureDesc: "Material thickness draw-in wire",
    label: "EF009746: Material thickness draw-in wire"
  },
  {
    featureId: "EF009747",
    featureDesc: "Approved according to ICAO",
    label: "EF009747: Approved according to ICAO"
  },
  {
    featureId: "EF009748",
    featureDesc: "Pneumatically operated",
    label: "EF009748: Pneumatically operated"
  },
  {
    featureId: "EF009749",
    featureDesc: "SID",
    label: "EF009749: SID"
  },
  {
    featureId: "EF009750",
    featureDesc: "Insulated neck diameter",
    label: "EF009750: Insulated neck diameter"
  },
  {
    featureId: "EF009751",
    featureDesc: "Function cable localisation",
    label: "EF009751: Function cable localisation"
  },
  {
    featureId: "EF009752",
    featureDesc: "Function cable sheath fault localisation",
    label: "EF009752: Function cable sheath fault localisation"
  },
  {
    featureId: "EF009753",
    featureDesc: "Function marker localisation",
    label: "EF009753: Function marker localisation"
  },
  {
    featureId: "EF009754",
    featureDesc: "Function marker programming",
    label: "EF009754: Function marker programming"
  },
  {
    featureId: "EF009755",
    featureDesc: "Bulged blade",
    label: "EF009755: Bulged blade"
  },
  {
    featureId: "EF009756",
    featureDesc: "With earth contact frame",
    label: "EF009756: With earth contact frame"
  },
  {
    featureId: "EF009758",
    featureDesc: "With coupling pliers",
    label: "EF009758: With coupling pliers"
  },
  {
    featureId: "EF009759",
    featureDesc: "Max. localisation/reading depth",
    label: "EF009759: Max. localisation/reading depth"
  },
  {
    featureId: "EF009760",
    featureDesc: "With inspection opening and cover",
    label: "EF009760: With inspection opening and cover"
  },
  {
    featureId: "EF009761",
    featureDesc: "For discharge tube",
    label: "EF009761: For discharge tube"
  },
  {
    featureId: "EF009762",
    featureDesc: "With discharge tubes",
    label: "EF009762: With discharge tubes"
  },
  {
    featureId: "EF009763",
    featureDesc: "Housing feed-through by break-out opening",
    label: "EF009763: Housing feed-through by break-out opening"
  },
  {
    featureId: "EF009764",
    featureDesc: "Suitable for LED luminaires",
    label: "EF009764: Suitable for LED luminaires"
  },
  {
    featureId: "EF009765",
    featureDesc: "Rated operation current Ie at AC-53b",
    label: "EF009765: Rated operation current Ie at AC-53b"
  },
  {
    featureId: "EF009766",
    featureDesc: "Bus module detachable",
    label: "EF009766: Bus module detachable"
  },
  {
    featureId: "EF009768",
    featureDesc: "With memory card",
    label: "EF009768: With memory card"
  },
  {
    featureId: "EF009769",
    featureDesc: "Halogen free according to EN 60754-1/2",
    label: "EF009769: Halogen free according to EN 60754-1/2"
  },
  {
    featureId: "EF009770",
    featureDesc: "Oil resistant according to EN IEC 60811-404",
    label: "EF009770: Oil resistant according to EN IEC 60811-404"
  },
  {
    featureId: "EF009771",
    featureDesc: "Low temperature resistant according to EN 60811-504+505+506",
    label: "EF009771: Low temperature resistant according to EN 60811-504+505+506"
  },
  {
    featureId: "EF009772",
    featureDesc: "Earthing cable length",
    label: "EF009772: Earthing cable length"
  },
  {
    featureId: "EF009777",
    featureDesc: "Sensitivity vibration acceleration sensor (mV/gravity g)",
    label: "EF009777: Sensitivity vibration acceleration sensor (mV/gravity g)"
  },
  {
    featureId: "EF009778",
    featureDesc: "Type of heating emitter control",
    label: "EF009778: Type of heating emitter control"
  },
  {
    featureId: "EF009779",
    featureDesc: "Number of outputs for heating emitter",
    label: "EF009779: Number of outputs for heating emitter"
  },
  {
    featureId: "EF009780",
    featureDesc: "Number of heating emitters per output",
    label: "EF009780: Number of heating emitters per output"
  },
  {
    featureId: "EF009781",
    featureDesc: "Output voltage for heating power",
    label: "EF009781: Output voltage for heating power"
  },
  {
    featureId: "EF009782",
    featureDesc: "Output current for heating power",
    label: "EF009782: Output current for heating power"
  },
  {
    featureId: "EF009783",
    featureDesc: "Power capacity for heating power per output",
    label: "EF009783: Power capacity for heating power per output"
  },
  {
    featureId: "EF009784",
    featureDesc: "Number of outputs for fans",
    label: "EF009784: Number of outputs for fans"
  },
  {
    featureId: "EF009785",
    featureDesc: "Output voltage for fans",
    label: "EF009785: Output voltage for fans"
  },
  {
    featureId: "EF009786",
    featureDesc: "Power carrying capacity for fans per output",
    label: "EF009786: Power carrying capacity for fans per output"
  },
  {
    featureId: "EF009787",
    featureDesc: "Type of module",
    label: "EF009787: Type of module"
  },
  {
    featureId: "EF009788",
    featureDesc: "Temperature measuring range",
    label: "EF009788: Temperature measuring range"
  },
  {
    featureId: "EF009789",
    featureDesc: "Accuracy of measurement",
    label: "EF009789: Accuracy of measurement"
  },
  {
    featureId: "EF009790",
    featureDesc: "Relative accuracy of measurement voltage",
    label: "EF009790: Relative accuracy of measurement voltage"
  },
  {
    featureId: "EF009791",
    featureDesc: "Relative accuracy of measurement current",
    label: "EF009791: Relative accuracy of measurement current"
  },
  {
    featureId: "EF009792",
    featureDesc: "Supply voltage DC",
    label: "EF009792: Supply voltage DC"
  },
  {
    featureId: "EF009793",
    featureDesc: "Input voltage per DC input",
    label: "EF009793: Input voltage per DC input"
  },
  {
    featureId: "EF009794",
    featureDesc: "Input current per DC input",
    label: "EF009794: Input current per DC input"
  },
  {
    featureId: "EF009795",
    featureDesc: "Max. opening cycles per hour",
    label: "EF009795: Max. opening cycles per hour"
  },
  {
    featureId: "EF009796",
    featureDesc: "Pinion position",
    label: "EF009796: Pinion position"
  },
  {
    featureId: "EF009797",
    featureDesc: "Specific energy (W/R)",
    label: "EF009797: Specific energy (W/R)"
  },
  {
    featureId: "EF009798",
    featureDesc: "Energy-coordinated protection effect with regard to the terminal equipment",
    label: "EF009798: Energy-coordinated protection effect with regard to the terminal equipment"
  },
  {
    featureId: "EF009801",
    featureDesc: "Saw band length",
    label: "EF009801: Saw band length"
  },
  {
    featureId: "EF009802",
    featureDesc: "Saw band width",
    label: "EF009802: Saw band width"
  },
  {
    featureId: "EF009804",
    featureDesc: "Number of teeth per inch",
    label: "EF009804: Number of teeth per inch"
  },
  {
    featureId: "EF009805",
    featureDesc: "Oscillating frequency",
    label: "EF009805: Oscillating frequency"
  },
  {
    featureId: "EF009806",
    featureDesc: "Number of screw drivers (battery)",
    label: "EF009806: Number of screw drivers (battery)"
  },
  {
    featureId: "EF009807",
    featureDesc: "Max. transmission rate, reading",
    label: "EF009807: Max. transmission rate, reading"
  },
  {
    featureId: "EF009808",
    featureDesc: "Max. transmission rate, writing",
    label: "EF009808: Max. transmission rate, writing"
  },
  {
    featureId: "EF009809",
    featureDesc: "Max. image capture frequency",
    label: "EF009809: Max. image capture frequency"
  },
  {
    featureId: "EF009810",
    featureDesc: "Cotton proportion",
    label: "EF009810: Cotton proportion"
  },
  {
    featureId: "EF009811",
    featureDesc: "Sock size",
    label: "EF009811: Sock size"
  },
  {
    featureId: "EF009813",
    featureDesc: "Process temperature",
    label: "EF009813: Process temperature"
  },
  {
    featureId: "EF009814",
    featureDesc: "Nominal pressure rating according to EN 1333 (PN ...)",
    label: "EF009814: Nominal pressure rating according to EN 1333 (PN ...)"
  },
  {
    featureId: "EF009815",
    featureDesc: "Nominal pressure rating according to ASME B16.5 (Class ...)",
    label: "EF009815: Nominal pressure rating according to ASME B16.5 (Class ...)"
  },
  {
    featureId: "EF009816",
    featureDesc: "Nominal pressure rating according to EN 1333 at H-side (PN ...)",
    label: "EF009816: Nominal pressure rating according to EN 1333 at H-side (PN ...)"
  },
  {
    featureId: "EF009817",
    featureDesc: "Nominal pressure rating according to ASME B16.5 at H-side (Class ...)",
    label: "EF009817: Nominal pressure rating according to ASME B16.5 at H-side (Class ...)"
  },
  {
    featureId: "EF009818",
    featureDesc: "Nominal pressure rating according to EN 1333 at L-side (PN ...)",
    label: "EF009818: Nominal pressure rating according to EN 1333 at L-side (PN ...)"
  },
  {
    featureId: "EF009819",
    featureDesc: "Nominal pressure rating according to ASME B16.5 at L-side (Class ...)",
    label: "EF009819: Nominal pressure rating according to ASME B16.5 at L-side (Class ...)"
  },
  {
    featureId: "EF009826",
    featureDesc: "Measuring range volume flux",
    label: "EF009826: Measuring range volume flux"
  },
  {
    featureId: "EF009827",
    featureDesc: "Pressure loss",
    label: "EF009827: Pressure loss"
  },
  {
    featureId: "EF009828",
    featureDesc: "Measuring range mass flux",
    label: "EF009828: Measuring range mass flux"
  },
  {
    featureId: "EF009829",
    featureDesc: "Medium density",
    label: "EF009829: Medium density"
  },
  {
    featureId: "EF009830",
    featureDesc: "Viscosity of the medium",
    label: "EF009830: Viscosity of the medium"
  },
  {
    featureId: "EF009832",
    featureDesc: "Type of connection head",
    label: "EF009832: Type of connection head"
  },
  {
    featureId: "EF009833",
    featureDesc: "Outer diameter thermowell top",
    label: "EF009833: Outer diameter thermowell top"
  },
  {
    featureId: "EF009834",
    featureDesc: "Outer diameter thermowell",
    label: "EF009834: Outer diameter thermowell"
  },
  {
    featureId: "EF009836",
    featureDesc: "Diameter measuring insert",
    label: "EF009836: Diameter measuring insert"
  },
  {
    featureId: "EF009838",
    featureDesc: "Built-in length thermowell",
    label: "EF009838: Built-in length thermowell"
  },
  {
    featureId: "EF009839",
    featureDesc: "Length thermowell",
    label: "EF009839: Length thermowell"
  },
  {
    featureId: "EF009840",
    featureDesc: "Length measuring insert",
    label: "EF009840: Length measuring insert"
  },
  {
    featureId: "EF009841",
    featureDesc: "Battery powered",
    label: "EF009841: Battery powered"
  },
  {
    featureId: "EF009843",
    featureDesc: "Isolation resistance measurement with ramp function",
    label: "EF009843: Isolation resistance measurement with ramp function"
  },
  {
    featureId: "EF009844",
    featureDesc: "Low resistance measurement with ramp function",
    label: "EF009844: Low resistance measurement with ramp function"
  },
  {
    featureId: "EF009845",
    featureDesc: "PRCD testing type S, type K",
    label: "EF009845: PRCD testing type S, type K"
  },
  {
    featureId: "EF009846",
    featureDesc: "Testing of IMDs and RCMs",
    label: "EF009846: Testing of IMDs and RCMs"
  },
  {
    featureId: "EF009847",
    featureDesc: "Measuring of leakage currents with adapter",
    label: "EF009847: Measuring of leakage currents with adapter"
  },
  {
    featureId: "EF009848",
    featureDesc: "Testing of charging devices E-Mobility, simulation of vehicle status",
    label: "EF009848: Testing of charging devices E-Mobility, simulation of vehicle status"
  },
  {
    featureId: "EF009849",
    featureDesc: "Structured measured value memory",
    label: "EF009849: Structured measured value memory"
  },
  {
    featureId: "EF009850",
    featureDesc: "Nominal test voltage adjustable with ramp function",
    label: "EF009850: Nominal test voltage adjustable with ramp function"
  },
  {
    featureId: "EF009851",
    featureDesc: "External voltage detection at OFF-state",
    label: "EF009851: External voltage detection at OFF-state"
  },
  {
    featureId: "EF009853",
    featureDesc: "Milliohm measurement with 4 wire technique (Kelvin connection)",
    label: "EF009853: Milliohm measurement with 4 wire technique (Kelvin connection)"
  },
  {
    featureId: "EF009854",
    featureDesc: "Temperature measurement RTD",
    label: "EF009854: Temperature measurement RTD"
  },
  {
    featureId: "EF009855",
    featureDesc: "Temperature measurement TC",
    label: "EF009855: Temperature measurement TC"
  },
  {
    featureId: "EF009856",
    featureDesc: "Nominal test voltage 10 V",
    label: "EF009856: Nominal test voltage 10 V"
  },
  {
    featureId: "EF009857",
    featureDesc: "Nominal test voltage 50 V",
    label: "EF009857: Nominal test voltage 50 V"
  },
  {
    featureId: "EF009858",
    featureDesc: "Cable length measurement",
    label: "EF009858: Cable length measurement"
  },
  {
    featureId: "EF009859",
    featureDesc: "Cable symmetry testing for two wire connections a-b-E",
    label: "EF009859: Cable symmetry testing for two wire connections a-b-E"
  },
  {
    featureId: "EF009860",
    featureDesc: "Power and energy measurement",
    label: "EF009860: Power and energy measurement"
  },
  {
    featureId: "EF009861",
    featureDesc: "Harmonics analysis/power quality",
    label: "EF009861: Harmonics analysis/power quality"
  },
  {
    featureId: "EF009862",
    featureDesc: "Rotational speed measuring",
    label: "EF009862: Rotational speed measuring"
  },
  {
    featureId: "EF009863",
    featureDesc: "Automatic circuit breaker",
    label: "EF009863: Automatic circuit breaker"
  },
  {
    featureId: "EF009864",
    featureDesc: "Max. voltage measuring circuit category",
    label: "EF009864: Max. voltage measuring circuit category"
  },
  {
    featureId: "EF009865",
    featureDesc: "Voltage output 0 V ... +/- 300 mV",
    label: "EF009865: Voltage output 0 V ... +/- 300 mV"
  },
  {
    featureId: "EF009866",
    featureDesc: "Voltage output 0 V ... 3 V",
    label: "EF009866: Voltage output 0 V ... 3 V"
  },
  {
    featureId: "EF009867",
    featureDesc: "Voltage output 0 V ... 15 V",
    label: "EF009867: Voltage output 0 V ... 15 V"
  },
  {
    featureId: "EF009868",
    featureDesc: "Lowest resolution voltage output",
    label: "EF009868: Lowest resolution voltage output"
  },
  {
    featureId: "EF009869",
    featureDesc: "Lowest resolution current output",
    label: "EF009869: Lowest resolution current output"
  },
  {
    featureId: "EF009870",
    featureDesc: "Power sink (transmitter simulator) 0 mA ... 24 mA",
    label: "EF009870: Power sink (transmitter simulator) 0 mA ... 24 mA"
  },
  {
    featureId: "EF009871",
    featureDesc: "Lowest resolution power sink",
    label: "EF009871: Lowest resolution power sink"
  },
  {
    featureId: "EF009872",
    featureDesc: "Resistor output",
    label: "EF009872: Resistor output"
  },
  {
    featureId: "EF009873",
    featureDesc: "Max. resistance encoder",
    label: "EF009873: Max. resistance encoder"
  },
  {
    featureId: "EF009874",
    featureDesc: "Thermal element RTD Pt100",
    label: "EF009874: Thermal element RTD Pt100"
  },
  {
    featureId: "EF009875",
    featureDesc: "Thermal element RTD Pt1000",
    label: "EF009875: Thermal element RTD Pt1000"
  },
  {
    featureId: "EF009876",
    featureDesc: "Thermal element RTD Ni100",
    label: "EF009876: Thermal element RTD Ni100"
  },
  {
    featureId: "EF009877",
    featureDesc: "Thermal element RTD Ni1000",
    label: "EF009877: Thermal element RTD Ni1000"
  },
  {
    featureId: "EF009878",
    featureDesc: "Frequency and pulse groups encoding",
    label: "EF009878: Frequency and pulse groups encoding"
  },
  {
    featureId: "EF009879",
    featureDesc: "Max. frequency",
    label: "EF009879: Max. frequency"
  },
  {
    featureId: "EF009880",
    featureDesc: "Ramp and stair function",
    label: "EF009880: Ramp and stair function"
  },
  {
    featureId: "EF009881",
    featureDesc: "Simultaneous measuring and encoding",
    label: "EF009881: Simultaneous measuring and encoding"
  },
  {
    featureId: "EF009883",
    featureDesc: "Horizontal positioning",
    label: "EF009883: Horizontal positioning"
  },
  {
    featureId: "EF009884",
    featureDesc: "Calibration software",
    label: "EF009884: Calibration software"
  },
  {
    featureId: "EF009885",
    featureDesc: "With integrated POF conductor",
    label: "EF009885: With integrated POF conductor"
  },
  {
    featureId: "EF009886",
    featureDesc: "Analogue output 0 V ... 2 V",
    label: "EF009886: Analogue output 0 V ... 2 V"
  },
  {
    featureId: "EF009887",
    featureDesc: "Analogue output 0 V ... 5 V",
    label: "EF009887: Analogue output 0 V ... 5 V"
  },
  {
    featureId: "EF009888",
    featureDesc: "Analogue output 2 V ... 10 V",
    label: "EF009888: Analogue output 2 V ... 10 V"
  },
  {
    featureId: "EF009889",
    featureDesc: "SIL according to IEC 61508",
    label: "EF009889: SIL according to IEC 61508"
  },
  {
    featureId: "EF009890",
    featureDesc: "Milling diameter",
    label: "EF009890: Milling diameter"
  },
  {
    featureId: "EF009891",
    featureDesc: "Milling depth",
    label: "EF009891: Milling depth"
  },
  {
    featureId: "EF009892",
    featureDesc: "Suitable for drilling machines",
    label: "EF009892: Suitable for drilling machines"
  },
  {
    featureId: "EF009893",
    featureDesc: "Suitable for right angle grinders",
    label: "EF009893: Suitable for right angle grinders"
  },
  {
    featureId: "EF009894",
    featureDesc: "Nail diameter",
    label: "EF009894: Nail diameter"
  },
  {
    featureId: "EF009895",
    featureDesc: "Number of ports 100 Mbps POF",
    label: "EF009895: Number of ports 100 Mbps POF"
  },
  {
    featureId: "EF009896",
    featureDesc: "Socket outlet adapter",
    label: "EF009896: Socket outlet adapter"
  },
  {
    featureId: "EF009897",
    featureDesc: "Output effective power",
    label: "EF009897: Output effective power"
  },
  {
    featureId: "EF009898",
    featureDesc: "Output apparent power",
    label: "EF009898: Output apparent power"
  },
  {
    featureId: "EF009899",
    featureDesc: "Heating LNB support",
    label: "EF009899: Heating LNB support"
  },
  {
    featureId: "EF009900",
    featureDesc: "Suitable as outdoor surface heating",
    label: "EF009900: Suitable as outdoor surface heating"
  },
  {
    featureId: "EF009901",
    featureDesc: "Suitable as roof surface heating",
    label: "EF009901: Suitable as roof surface heating"
  },
  {
    featureId: "EF009902",
    featureDesc: "Frequency-selective monitoring via vibration speed spectrum",
    label: "EF009902: Frequency-selective monitoring via vibration speed spectrum"
  },
  {
    featureId: "EF009903",
    featureDesc: "Frequency-selective monitoring via vibration acceleration spectrum",
    label: "EF009903: Frequency-selective monitoring via vibration acceleration spectrum"
  },
  {
    featureId: "EF009904",
    featureDesc: "Frequency-selective monitoring via envelope analysis",
    label: "EF009904: Frequency-selective monitoring via envelope analysis"
  },
  {
    featureId: "EF009906",
    featureDesc: "Vibration characteristic monitoring via value of the vibration speed RMS",
    label: "EF009906: Vibration characteristic monitoring via value of the vibration speed RMS"
  },
  {
    featureId: "EF009907",
    featureDesc: "Vibration characteristic monitoring via diagnostic characteristic value DKW",
    label: "EF009907: Vibration characteristic monitoring via diagnostic characteristic value DKW"
  },
  {
    featureId: "EF009908",
    featureDesc: "Type of acoustic signal device",
    label: "EF009908: Type of acoustic signal device"
  },
  {
    featureId: "EF009909",
    featureDesc: "Cartridge volume",
    label: "EF009909: Cartridge volume"
  },
  {
    featureId: "EF009910",
    featureDesc: "Number of ports 1000 Mbps POF",
    label: "EF009910: Number of ports 1000 Mbps POF"
  },
  {
    featureId: "EF009911",
    featureDesc: "Connection size at tool side",
    label: "EF009911: Connection size at tool side"
  },
  {
    featureId: "EF009912",
    featureDesc: "Connection size at tool side",
    label: "EF009912: Connection size at tool side"
  },
  {
    featureId: "EF009913",
    featureDesc: "Can be used on plastic",
    label: "EF009913: Can be used on plastic"
  },
  {
    featureId: "EF009914",
    featureDesc: "Can be used on concrete",
    label: "EF009914: Can be used on concrete"
  },
  {
    featureId: "EF009915",
    featureDesc: "Can be used on metal",
    label: "EF009915: Can be used on metal"
  },
  {
    featureId: "EF009916",
    featureDesc: "Suitable for cables",
    label: "EF009916: Suitable for cables"
  },
  {
    featureId: "EF009917",
    featureDesc: "Suitable for tubes",
    label: "EF009917: Suitable for tubes"
  },
  {
    featureId: "EF009918",
    featureDesc: "For nominal tube diameter",
    label: "EF009918: For nominal tube diameter"
  },
  {
    featureId: "EF009919",
    featureDesc: "Shrink technique",
    label: "EF009919: Shrink technique"
  },
  {
    featureId: "EF009920",
    featureDesc: "Number of conductors",
    label: "EF009920: Number of conductors"
  },
  {
    featureId: "EF009921",
    featureDesc: "Inserts exchangeable",
    label: "EF009921: Inserts exchangeable"
  },
  {
    featureId: "EF009922",
    featureDesc: "Number of included inserts",
    label: "EF009922: Number of included inserts"
  },
  {
    featureId: "EF009923",
    featureDesc: "Suitable for operating system Windows 8",
    label: "EF009923: Suitable for operating system Windows 8"
  },
  {
    featureId: "EF009924",
    featureDesc: "Loadable for operating system for configuration software, Windows 8",
    label: "EF009924: Loadable for operating system for configuration software, Windows 8"
  },
  {
    featureId: "EF009925",
    featureDesc: "PC-programming set for Windows 8",
    label: "EF009925: PC-programming set for Windows 8"
  },
  {
    featureId: "EF009926",
    featureDesc: "System configuration DC",
    label: "EF009926: System configuration DC"
  },
  {
    featureId: "EF009927",
    featureDesc: "System configuration IT",
    label: "EF009927: System configuration IT"
  },
  {
    featureId: "EF009928",
    featureDesc: "System configuration TN",
    label: "EF009928: System configuration TN"
  },
  {
    featureId: "EF009929",
    featureDesc: "System configuration TN-C",
    label: "EF009929: System configuration TN-C"
  },
  {
    featureId: "EF009930",
    featureDesc: "System configuration TN-S",
    label: "EF009930: System configuration TN-S"
  },
  {
    featureId: "EF009931",
    featureDesc: "System configuration TT",
    label: "EF009931: System configuration TT"
  },
  {
    featureId: "EF009932",
    featureDesc: "System configuration other",
    label: "EF009932: System configuration other"
  },
  {
    featureId: "EF009933",
    featureDesc: "System configuration TN-C-S",
    label: "EF009933: System configuration TN-C-S"
  },
  {
    featureId: "EF009935",
    featureDesc: "Strengthened edge on all sides",
    label: "EF009935: Strengthened edge on all sides"
  },
  {
    featureId: "EF009937",
    featureDesc: "Elastic cord on all sides",
    label: "EF009937: Elastic cord on all sides"
  },
  {
    featureId: "EF009939",
    featureDesc: "With hooks",
    label: "EF009939: With hooks"
  },
  {
    featureId: "EF009940",
    featureDesc: "With loops",
    label: "EF009940: With loops"
  },
  {
    featureId: "EF009941",
    featureDesc: "Suitable for handroll",
    label: "EF009941: Suitable for handroll"
  },
  {
    featureId: "EF009943",
    featureDesc: "Suitable for machine roll",
    label: "EF009943: Suitable for machine roll"
  },
  {
    featureId: "EF009945",
    featureDesc: "Max. roller width",
    label: "EF009945: Max. roller width"
  },
  {
    featureId: "EF009947",
    featureDesc: "Lip",
    label: "EF009947: Lip"
  },
  {
    featureId: "EF009950",
    featureDesc: "With line",
    label: "EF009950: With line"
  },
  {
    featureId: "EF009951",
    featureDesc: "Length line",
    label: "EF009951: Length line"
  },
  {
    featureId: "EF009952",
    featureDesc: "Fire bucket",
    label: "EF009952: Fire bucket"
  },
  {
    featureId: "EF009953",
    featureDesc: "Left closing",
    label: "EF009953: Left closing"
  },
  {
    featureId: "EF009960",
    featureDesc: "Approved according to GMDSS",
    label: "EF009960: Approved according to GMDSS"
  },
  {
    featureId: "EF009961",
    featureDesc: "Capacity 15h",
    label: "EF009961: Capacity 15h"
  },
  {
    featureId: "EF009962",
    featureDesc: "Number of threaded holes",
    label: "EF009962: Number of threaded holes"
  },
  {
    featureId: "EF009963",
    featureDesc: "Peukert constant",
    label: "EF009963: Peukert constant"
  },
  {
    featureId: "EF009964",
    featureDesc: "Size threaded hole",
    label: "EF009964: Size threaded hole"
  },
  {
    featureId: "EF009965",
    featureDesc: "View angle",
    label: "EF009965: View angle"
  },
  {
    featureId: "EF009966",
    featureDesc: "With compass",
    label: "EF009966: With compass"
  },
  {
    featureId: "EF009967",
    featureDesc: "Number of signals",
    label: "EF009967: Number of signals"
  },
  {
    featureId: "EF009968",
    featureDesc: "Nitrogen gas filled",
    label: "EF009968: Nitrogen gas filled"
  },
  {
    featureId: "EF009969",
    featureDesc: "Flange width",
    label: "EF009969: Flange width"
  },
  {
    featureId: "EF009970",
    featureDesc: "Swept entry type (with inner radius)",
    label: "EF009970: Swept entry type (with inner radius)"
  },
  {
    featureId: "EF009971",
    featureDesc: "Clear opening",
    label: "EF009971: Clear opening"
  },
  {
    featureId: "EF009972",
    featureDesc: "Shape of outer wall",
    label: "EF009972: Shape of outer wall"
  },
  {
    featureId: "EF009974",
    featureDesc: "With connection",
    label: "EF009974: With connection"
  },
  {
    featureId: "EF009975",
    featureDesc: "Diameter connection 1",
    label: "EF009975: Diameter connection 1"
  },
  {
    featureId: "EF009978",
    featureDesc: "Bottom open",
    label: "EF009978: Bottom open"
  },
  {
    featureId: "EF009979",
    featureDesc: "Sand reception capacity",
    label: "EF009979: Sand reception capacity"
  },
  {
    featureId: "EF009980",
    featureDesc: "Wet surface",
    label: "EF009980: Wet surface"
  },
  {
    featureId: "EF009981",
    featureDesc: "Perforated surface",
    label: "EF009981: Perforated surface"
  },
  {
    featureId: "EF009982",
    featureDesc: "Infiltration opening",
    label: "EF009982: Infiltration opening"
  },
  {
    featureId: "EF009983",
    featureDesc: "With geotextile",
    label: "EF009983: With geotextile"
  },
  {
    featureId: "EF009984",
    featureDesc: "Type of geotextile",
    label: "EF009984: Type of geotextile"
  },
  {
    featureId: "EF009989",
    featureDesc: "Grid pattern",
    label: "EF009989: Grid pattern"
  },
  {
    featureId: "EF009990",
    featureDesc: "Place grid",
    label: "EF009990: Place grid"
  },
  {
    featureId: "EF009993",
    featureDesc: "Connection shape",
    label: "EF009993: Connection shape"
  },
  {
    featureId: "EF009995",
    featureDesc: "Load class according to EN 1433",
    label: "EF009995: Load class according to EN 1433"
  },
  {
    featureId: "EF009996",
    featureDesc: "Wheelchair friendly",
    label: "EF009996: Wheelchair friendly"
  },
  {
    featureId: "EF009997",
    featureDesc: "Diameter connection 2",
    label: "EF009997: Diameter connection 2"
  },
  {
    featureId: "EF009999",
    featureDesc: "With sand catcher/sand trap",
    label: "EF009999: With sand catcher/sand trap"
  },
  {
    featureId: "EF010000",
    featureDesc: "With amphibians stair",
    label: "EF010000: With amphibians stair"
  },
  {
    featureId: "EF010001",
    featureDesc: "With odour trap",
    label: "EF010001: With odour trap"
  },
  {
    featureId: "EF010002",
    featureDesc: "With impact resistant bottom",
    label: "EF010002: With impact resistant bottom"
  },
  {
    featureId: "EF010003",
    featureDesc: "Built-in slope",
    label: "EF010003: Built-in slope"
  },
  {
    featureId: "EF010005",
    featureDesc: "Height 1",
    label: "EF010005: Height 1"
  },
  {
    featureId: "EF010006",
    featureDesc: "Height 2",
    label: "EF010006: Height 2"
  },
  {
    featureId: "EF010007",
    featureDesc: "Inner height 1",
    label: "EF010007: Inner height 1"
  },
  {
    featureId: "EF010008",
    featureDesc: "Inner height 2",
    label: "EF010008: Inner height 2"
  },
  {
    featureId: "EF010009",
    featureDesc: "With edge profile",
    label: "EF010009: With edge profile"
  },
  {
    featureId: "EF010010",
    featureDesc: "Material edge profile",
    label: "EF010010: Material edge profile"
  },
  {
    featureId: "EF010011",
    featureDesc: "Surface protection edge profile",
    label: "EF010011: Surface protection edge profile"
  },
  {
    featureId: "EF010012",
    featureDesc: "Material quality gutter",
    label: "EF010012: Material quality gutter"
  },
  {
    featureId: "EF010014",
    featureDesc: "With dirt trap bucket",
    label: "EF010014: With dirt trap bucket"
  },
  {
    featureId: "EF010015",
    featureDesc: "Hole diameter connection",
    label: "EF010015: Hole diameter connection"
  },
  {
    featureId: "EF010016",
    featureDesc: "Model gas meter",
    label: "EF010016: Model gas meter"
  },
  {
    featureId: "EF010017",
    featureDesc: "Connection size gas meter",
    label: "EF010017: Connection size gas meter"
  },
  {
    featureId: "EF010018",
    featureDesc: "Model water meter",
    label: "EF010018: Model water meter"
  },
  {
    featureId: "EF010019",
    featureDesc: "Angle of inclination ratio",
    label: "EF010019: Angle of inclination ratio"
  },
  {
    featureId: "EF010020",
    featureDesc: "Reinforced",
    label: "EF010020: Reinforced"
  },
  {
    featureId: "EF010021",
    featureDesc: "With stoplog groove",
    label: "EF010021: With stoplog groove"
  },
  {
    featureId: "EF010022",
    featureDesc: "With sliding",
    label: "EF010022: With sliding"
  },
  {
    featureId: "EF010023",
    featureDesc: "Straight flow profile",
    label: "EF010023: Straight flow profile"
  },
  {
    featureId: "EF010024",
    featureDesc: "With overflow wall",
    label: "EF010024: With overflow wall"
  },
  {
    featureId: "EF010025",
    featureDesc: "With slope termination",
    label: "EF010025: With slope termination"
  },
  {
    featureId: "EF010026",
    featureDesc: "Type of slope termination",
    label: "EF010026: Type of slope termination"
  },
  {
    featureId: "EF010027",
    featureDesc: "Slope termination with beam head",
    label: "EF010027: Slope termination with beam head"
  },
  {
    featureId: "EF010029",
    featureDesc: "Inner diameter round",
    label: "EF010029: Inner diameter round"
  },
  {
    featureId: "EF010030",
    featureDesc: "Inner dimensions egg shape",
    label: "EF010030: Inner dimensions egg shape"
  },
  {
    featureId: "EF010031",
    featureDesc: "With rubber ring",
    label: "EF010031: With rubber ring"
  },
  {
    featureId: "EF010032",
    featureDesc: "Type of ring",
    label: "EF010032: Type of ring"
  },
  {
    featureId: "EF010034",
    featureDesc: "With inlet",
    label: "EF010034: With inlet"
  },
  {
    featureId: "EF010035",
    featureDesc: "Diameter of inlet",
    label: "EF010035: Diameter of inlet"
  },
  {
    featureId: "EF010044",
    featureDesc: "For radiator type",
    label: "EF010044: For radiator type"
  },
  {
    featureId: "EF010045",
    featureDesc: "Suitable for radiator length",
    label: "EF010045: Suitable for radiator length"
  },
  {
    featureId: "EF010046",
    featureDesc: "With trap cap",
    label: "EF010046: With trap cap"
  },
  {
    featureId: "EF010047",
    featureDesc: "With column",
    label: "EF010047: With column"
  },
  {
    featureId: "EF010048",
    featureDesc: "With legs",
    label: "EF010048: With legs"
  },
  {
    featureId: "EF010049",
    featureDesc: "Outer diameter steel cable",
    label: "EF010049: Outer diameter steel cable"
  },
  {
    featureId: "EF010050",
    featureDesc: "Suitable for corrugated pipe",
    label: "EF010050: Suitable for corrugated pipe"
  },
  {
    featureId: "EF010051",
    featureDesc: "Coated grid",
    label: "EF010051: Coated grid"
  },
  {
    featureId: "EF010052",
    featureDesc: "Hanging culvert",
    label: "EF010052: Hanging culvert"
  },
  {
    featureId: "EF010054",
    featureDesc: "Eco culvert (with platform)",
    label: "EF010054: Eco culvert (with platform)"
  },
  {
    featureId: "EF010055",
    featureDesc: "With integrated sliding ring",
    label: "EF010055: With integrated sliding ring"
  },
  {
    featureId: "EF010056",
    featureDesc: "With self-lubricating sliding ring",
    label: "EF010056: With self-lubricating sliding ring"
  },
  {
    featureId: "EF010057",
    featureDesc: "Flat foot tube",
    label: "EF010057: Flat foot tube"
  },
  {
    featureId: "EF010058",
    featureDesc: "Specific weight",
    label: "EF010058: Specific weight"
  },
  {
    featureId: "EF010060",
    featureDesc: "Distance between both sides",
    label: "EF010060: Distance between both sides"
  },
  {
    featureId: "EF010061",
    featureDesc: "Closure",
    label: "EF010061: Closure"
  },
  {
    featureId: "EF010062",
    featureDesc: "With pockets",
    label: "EF010062: With pockets"
  },
  {
    featureId: "EF010064",
    featureDesc: "With adjustable jaws",
    label: "EF010064: With adjustable jaws"
  },
  {
    featureId: "EF010065",
    featureDesc: "Diameter burner head",
    label: "EF010065: Diameter burner head"
  },
  {
    featureId: "EF010066",
    featureDesc: "Length extension pipe",
    label: "EF010066: Length extension pipe"
  },
  {
    featureId: "EF010067",
    featureDesc: "Length gas hose",
    label: "EF010067: Length gas hose"
  },
  {
    featureId: "EF010068",
    featureDesc: "Flame temperature settable",
    label: "EF010068: Flame temperature settable"
  },
  {
    featureId: "EF010069",
    featureDesc: "Flame temperature",
    label: "EF010069: Flame temperature"
  },
  {
    featureId: "EF010071",
    featureDesc: "With strip",
    label: "EF010071: With strip"
  },
  {
    featureId: "EF010072",
    featureDesc: "Wood type of veneer",
    label: "EF010072: Wood type of veneer"
  },
  {
    featureId: "EF010073",
    featureDesc: "Wood kind of indoor veneer",
    label: "EF010073: Wood kind of indoor veneer"
  },
  {
    featureId: "EF010077",
    featureDesc: "Environmental certification",
    label: "EF010077: Environmental certification"
  },
  {
    featureId: "EF010078",
    featureDesc: "Formaldehyde emission according to EN 717-2",
    label: "EF010078: Formaldehyde emission according to EN 717-2"
  },
  {
    featureId: "EF010079",
    featureDesc: "GND guarantee",
    label: "EF010079: GND guarantee"
  },
  {
    featureId: "EF010080",
    featureDesc: "Type of stabilization",
    label: "EF010080: Type of stabilization"
  },
  {
    featureId: "EF010081",
    featureDesc: "Fire resistance according to EN 1634-1:2005",
    label: "EF010081: Fire resistance according to EN 1634-1:2005"
  },
  {
    featureId: "EF010082",
    featureDesc: "Stability class according to EN 12219",
    label: "EF010082: Stability class according to EN 12219"
  },
  {
    featureId: "EF010083",
    featureDesc: "Thermal transmittance value (U)",
    label: "EF010083: Thermal transmittance value (U)"
  },
  {
    featureId: "EF010084",
    featureDesc: "Insulation value edge timber (U)",
    label: "EF010084: Insulation value edge timber (U)"
  },
  {
    featureId: "EF010085",
    featureDesc: "Insulation value isolated part (U)",
    label: "EF010085: Insulation value isolated part (U)"
  },
  {
    featureId: "EF010086",
    featureDesc: "Soundproof",
    label: "EF010086: Soundproof"
  },
  {
    featureId: "EF010087",
    featureDesc: "Suitable to mill into (max.)",
    label: "EF010087: Suitable to mill into (max.)"
  },
  {
    featureId: "EF010089",
    featureDesc: "Basis for assessment doors 001 SKH",
    label: "EF010089: Basis for assessment doors 001 SKH"
  },
  {
    featureId: "EF010090",
    featureDesc: "Layer thickness primer",
    label: "EF010090: Layer thickness primer"
  },
  {
    featureId: "EF010091",
    featureDesc: "Layer thickness paint foil",
    label: "EF010091: Layer thickness paint foil"
  },
  {
    featureId: "EF010092",
    featureDesc: "Type of wood",
    label: "EF010092: Type of wood"
  },
  {
    featureId: "EF010093",
    featureDesc: "Modified",
    label: "EF010093: Modified"
  },
  {
    featureId: "EF010094",
    featureDesc: "Modification method",
    label: "EF010094: Modification method"
  },
  {
    featureId: "EF010095",
    featureDesc: "Spiked",
    label: "EF010095: Spiked"
  },
  {
    featureId: "EF010096",
    featureDesc: "Fire retardant impregnated",
    label: "EF010096: Fire retardant impregnated"
  },
  {
    featureId: "EF010097",
    featureDesc: "Strength class",
    label: "EF010097: Strength class"
  },
  {
    featureId: "EF010098",
    featureDesc: "Layer thickness",
    label: "EF010098: Layer thickness"
  },
  {
    featureId: "EF010099",
    featureDesc: "Square-edged",
    label: "EF010099: Square-edged"
  },
  {
    featureId: "EF010100",
    featureDesc: "With bark",
    label: "EF010100: With bark"
  },
  {
    featureId: "EF010101",
    featureDesc: "Number of treated sides",
    label: "EF010101: Number of treated sides"
  },
  {
    featureId: "EF010102",
    featureDesc: "With dust corner",
    label: "EF010102: With dust corner"
  },
  {
    featureId: "EF010105",
    featureDesc: "Application class (BRL1705)",
    label: "EF010105: Application class (BRL1705)"
  },
  {
    featureId: "EF010106",
    featureDesc: "Veneer direction",
    label: "EF010106: Veneer direction"
  },
  {
    featureId: "EF010107",
    featureDesc: "Edge finish",
    label: "EF010107: Edge finish"
  },
  {
    featureId: "EF010108",
    featureDesc: "Water resistant",
    label: "EF010108: Water resistant"
  },
  {
    featureId: "EF010109",
    featureDesc: "With toilet rim block holder",
    label: "EF010109: With toilet rim block holder"
  },
  {
    featureId: "EF010110",
    featureDesc: "Height visible surface",
    label: "EF010110: Height visible surface"
  },
  {
    featureId: "EF010111",
    featureDesc: "Colourfast",
    label: "EF010111: Colourfast"
  },
  {
    featureId: "EF010112",
    featureDesc: "Texture",
    label: "EF010112: Texture"
  },
  {
    featureId: "EF010113",
    featureDesc: "Pattern package",
    label: "EF010113: Pattern package"
  },
  {
    featureId: "EF010114",
    featureDesc: "Water regulation",
    label: "EF010114: Water regulation"
  },
  {
    featureId: "EF010115",
    featureDesc: "Reflective",
    label: "EF010115: Reflective"
  },
  {
    featureId: "EF010116",
    featureDesc: "Grinding method",
    label: "EF010116: Grinding method"
  },
  {
    featureId: "EF010117",
    featureDesc: "Length tile",
    label: "EF010117: Length tile"
  },
  {
    featureId: "EF010118",
    featureDesc: "Width tile",
    label: "EF010118: Width tile"
  },
  {
    featureId: "EF010119",
    featureDesc: "Tile thickness",
    label: "EF010119: Tile thickness"
  },
  {
    featureId: "EF010120",
    featureDesc: "Length package",
    label: "EF010120: Length package"
  },
  {
    featureId: "EF010121",
    featureDesc: "Width package",
    label: "EF010121: Width package"
  },
  {
    featureId: "EF010122",
    featureDesc: "Number of tiles per package",
    label: "EF010122: Number of tiles per package"
  },
  {
    featureId: "EF010123",
    featureDesc: "Bevel height",
    label: "EF010123: Bevel height"
  },
  {
    featureId: "EF010124",
    featureDesc: "Bevel width",
    label: "EF010124: Bevel width"
  },
  {
    featureId: "EF010126",
    featureDesc: "Suitable for visible masonry",
    label: "EF010126: Suitable for visible masonry"
  },
  {
    featureId: "EF010127",
    featureDesc: "Insulation value (R)",
    label: "EF010127: Insulation value (R)"
  },
  {
    featureId: "EF010129",
    featureDesc: "Sand coated",
    label: "EF010129: Sand coated"
  },
  {
    featureId: "EF010130",
    featureDesc: "Frost resistance according to EN 772-22",
    label: "EF010130: Frost resistance according to EN 772-22"
  },
  {
    featureId: "EF010131",
    featureDesc: "Number per meter",
    label: "EF010131: Number per meter"
  },
  {
    featureId: "EF010132",
    featureDesc: "Dummy joint",
    label: "EF010132: Dummy joint"
  },
  {
    featureId: "EF010133",
    featureDesc: "Visible surface",
    label: "EF010133: Visible surface"
  },
  {
    featureId: "EF010134",
    featureDesc: "Height front",
    label: "EF010134: Height front"
  },
  {
    featureId: "EF010135",
    featureDesc: "Fill lintel",
    label: "EF010135: Fill lintel"
  },
  {
    featureId: "EF010137",
    featureDesc: "With water rebate",
    label: "EF010137: With water rebate"
  },
  {
    featureId: "EF010139",
    featureDesc: "Flow width",
    label: "EF010139: Flow width"
  },
  {
    featureId: "EF010140",
    featureDesc: "Flow depth",
    label: "EF010140: Flow depth"
  },
  {
    featureId: "EF010141",
    featureDesc: "With sliding sleeve",
    label: "EF010141: With sliding sleeve"
  },
  {
    featureId: "EF010142",
    featureDesc: "With integrated insulation",
    label: "EF010142: With integrated insulation"
  },
  {
    featureId: "EF010143",
    featureDesc: "Barrier",
    label: "EF010143: Barrier"
  },
  {
    featureId: "EF010144",
    featureDesc: "Size of stone",
    label: "EF010144: Size of stone"
  },
  {
    featureId: "EF010145",
    featureDesc: "With attachments",
    label: "EF010145: With attachments"
  },
  {
    featureId: "EF010146",
    featureDesc: "Blasted",
    label: "EF010146: Blasted"
  },
  {
    featureId: "EF010147",
    featureDesc: "Blaster method",
    label: "EF010147: Blaster method"
  },
  {
    featureId: "EF010148",
    featureDesc: "Bush hammered (hammered)",
    label: "EF010148: Bush hammered (hammered)"
  },
  {
    featureId: "EF010149",
    featureDesc: "Outdated",
    label: "EF010149: Outdated"
  },
  {
    featureId: "EF010150",
    featureDesc: "Aging Method",
    label: "EF010150: Aging Method"
  },
  {
    featureId: "EF010151",
    featureDesc: "Length stone",
    label: "EF010151: Length stone"
  },
  {
    featureId: "EF010152",
    featureDesc: "Width stone",
    label: "EF010152: Width stone"
  },
  {
    featureId: "EF010153",
    featureDesc: "Thickness stone",
    label: "EF010153: Thickness stone"
  },
  {
    featureId: "EF010154",
    featureDesc: "Number of bricks per package",
    label: "EF010154: Number of bricks per package"
  },
  {
    featureId: "EF010159",
    featureDesc: "Bonding method",
    label: "EF010159: Bonding method"
  },
  {
    featureId: "EF010160",
    featureDesc: "Quality class wood",
    label: "EF010160: Quality class wood"
  },
  {
    featureId: "EF010161",
    featureDesc: "Height (excl. Fanlight)",
    label: "EF010161: Height (excl. Fanlight)"
  },
  {
    featureId: "EF010162",
    featureDesc: "With fanlight",
    label: "EF010162: With fanlight"
  },
  {
    featureId: "EF010163",
    featureDesc: "With lintel",
    label: "EF010163: With lintel"
  },
  {
    featureId: "EF010164",
    featureDesc: "With sidelight",
    label: "EF010164: With sidelight"
  },
  {
    featureId: "EF010165",
    featureDesc: "Suitable for door with no overlap",
    label: "EF010165: Suitable for door with no overlap"
  },
  {
    featureId: "EF010166",
    featureDesc: "Suitable for door with overlap",
    label: "EF010166: Suitable for door with overlap"
  },
  {
    featureId: "EF010167",
    featureDesc: "Profiling",
    label: "EF010167: Profiling"
  },
  {
    featureId: "EF010168",
    featureDesc: "Type of glazing bead",
    label: "EF010168: Type of glazing bead"
  },
  {
    featureId: "EF010169",
    featureDesc: "Panel type",
    label: "EF010169: Panel type"
  },
  {
    featureId: "EF010170",
    featureDesc: "Edge shape",
    label: "EF010170: Edge shape"
  },
  {
    featureId: "EF010171",
    featureDesc: "With glass opening",
    label: "EF010171: With glass opening"
  },
  {
    featureId: "EF010172",
    featureDesc: "Suitable for transparent treatment",
    label: "EF010172: Suitable for transparent treatment"
  },
  {
    featureId: "EF010173",
    featureDesc: "Door construction",
    label: "EF010173: Door construction"
  },
  {
    featureId: "EF010174",
    featureDesc: "Wood type massive wooden door",
    label: "EF010174: Wood type massive wooden door"
  },
  {
    featureId: "EF010175",
    featureDesc: "Material cover plate composite door",
    label: "EF010175: Material cover plate composite door"
  },
  {
    featureId: "EF010176",
    featureDesc: "Duo door",
    label: "EF010176: Duo door"
  },
  {
    featureId: "EF010177",
    featureDesc: "Door set (double door)",
    label: "EF010177: Door set (double door)"
  },
  {
    featureId: "EF010178",
    featureDesc: "Ornament rack",
    label: "EF010178: Ornament rack"
  },
  {
    featureId: "EF010179",
    featureDesc: "Letter slot possible",
    label: "EF010179: Letter slot possible"
  },
  {
    featureId: "EF010181",
    featureDesc: "Nominal duct width connection 1",
    label: "EF010181: Nominal duct width connection 1"
  },
  {
    featureId: "EF010182",
    featureDesc: "Nominal duct height connection 1",
    label: "EF010182: Nominal duct height connection 1"
  },
  {
    featureId: "EF010183",
    featureDesc: "Nominal duct width connection 2",
    label: "EF010183: Nominal duct width connection 2"
  },
  {
    featureId: "EF010184",
    featureDesc: "Nominal duct height connection 2",
    label: "EF010184: Nominal duct height connection 2"
  },
  {
    featureId: "EF010185",
    featureDesc: "Working length connection 6",
    label: "EF010185: Working length connection 6"
  },
  {
    featureId: "EF010186",
    featureDesc: "Safety lock",
    label: "EF010186: Safety lock"
  },
  {
    featureId: "EF010188",
    featureDesc: "Police certificate",
    label: "EF010188: Police certificate"
  },
  {
    featureId: "EF010189",
    featureDesc: "Narrow door lock",
    label: "EF010189: Narrow door lock"
  },
  {
    featureId: "EF010190",
    featureDesc: "Swing direction according to DIN",
    label: "EF010190: Swing direction according to DIN"
  },
  {
    featureId: "EF010191",
    featureDesc: "Material front plate",
    label: "EF010191: Material front plate"
  },
  {
    featureId: "EF010192",
    featureDesc: "Model front plate",
    label: "EF010192: Model front plate"
  },
  {
    featureId: "EF010193",
    featureDesc: "Length front plate",
    label: "EF010193: Length front plate"
  },
  {
    featureId: "EF010194",
    featureDesc: "Thickness front plate",
    label: "EF010194: Thickness front plate"
  },
  {
    featureId: "EF010195",
    featureDesc: "Material latch bolt",
    label: "EF010195: Material latch bolt"
  },
  {
    featureId: "EF010196",
    featureDesc: "Material lock bolt",
    label: "EF010196: Material lock bolt"
  },
  {
    featureId: "EF010197",
    featureDesc: "Hook bolt",
    label: "EF010197: Hook bolt"
  },
  {
    featureId: "EF010198",
    featureDesc: "Lock control",
    label: "EF010198: Lock control"
  },
  {
    featureId: "EF010199",
    featureDesc: "Cylinder type",
    label: "EF010199: Cylinder type"
  },
  {
    featureId: "EF010200",
    featureDesc: "Depth lock case",
    label: "EF010200: Depth lock case"
  },
  {
    featureId: "EF010201",
    featureDesc: "With strike box",
    label: "EF010201: With strike box"
  },
  {
    featureId: "EF010202",
    featureDesc: "Mandrel size",
    label: "EF010202: Mandrel size"
  },
  {
    featureId: "EF010203",
    featureDesc: "Stepless adjustable",
    label: "EF010203: Stepless adjustable"
  },
  {
    featureId: "EF010204",
    featureDesc: "Safety version",
    label: "EF010204: Safety version"
  },
  {
    featureId: "EF010205",
    featureDesc: "Max. vertical height",
    label: "EF010205: Max. vertical height"
  },
  {
    featureId: "EF010206",
    featureDesc: "Material cover cap",
    label: "EF010206: Material cover cap"
  },
  {
    featureId: "EF010209",
    featureDesc: "Door strike",
    label: "EF010209: Door strike"
  },
  {
    featureId: "EF010210",
    featureDesc: "Material bolt",
    label: "EF010210: Material bolt"
  },
  {
    featureId: "EF010212",
    featureDesc: "Type of door plate",
    label: "EF010212: Type of door plate"
  },
  {
    featureId: "EF010216",
    featureDesc: "Thermal insulation",
    label: "EF010216: Thermal insulation"
  },
  {
    featureId: "EF010217",
    featureDesc: "Acoustic insulation",
    label: "EF010217: Acoustic insulation"
  },
  {
    featureId: "EF010220",
    featureDesc: "Suitable for wall",
    label: "EF010220: Suitable for wall"
  },
  {
    featureId: "EF010221",
    featureDesc: "Suitable for facade",
    label: "EF010221: Suitable for facade"
  },
  {
    featureId: "EF010222",
    featureDesc: "Suitable for ceiling",
    label: "EF010222: Suitable for ceiling"
  },
  {
    featureId: "EF010223",
    featureDesc: "Suitable for floor",
    label: "EF010223: Suitable for floor"
  },
  {
    featureId: "EF010224",
    featureDesc: "Material upholstery front/top",
    label: "EF010224: Material upholstery front/top"
  },
  {
    featureId: "EF010225",
    featureDesc: "Material upholstery rear/bottom",
    label: "EF010225: Material upholstery rear/bottom"
  },
  {
    featureId: "EF010226",
    featureDesc: "With nail flange (reinforced fixation strip)",
    label: "EF010226: With nail flange (reinforced fixation strip)"
  },
  {
    featureId: "EF010227",
    featureDesc: "With decorative finish",
    label: "EF010227: With decorative finish"
  },
  {
    featureId: "EF010231",
    featureDesc: "Sound absorption coefficient ISO 354",
    label: "EF010231: Sound absorption coefficient ISO 354"
  },
  {
    featureId: "EF010232",
    featureDesc: "Dynamic stiffness according to EN 29052-1",
    label: "EF010232: Dynamic stiffness according to EN 29052-1"
  },
  {
    featureId: "EF010233",
    featureDesc: "Max. moist absorption",
    label: "EF010233: Max. moist absorption"
  },
  {
    featureId: "EF010234",
    featureDesc: "Attached with bituminised glass fibre",
    label: "EF010234: Attached with bituminised glass fibre"
  },
  {
    featureId: "EF010236",
    featureDesc: "With rebate",
    label: "EF010236: With rebate"
  },
  {
    featureId: "EF010237",
    featureDesc: "Rimless",
    label: "EF010237: Rimless"
  },
  {
    featureId: "EF010239",
    featureDesc: "Suitable for roofing material",
    label: "EF010239: Suitable for roofing material"
  },
  {
    featureId: "EF010240",
    featureDesc: "Suitable for substructure",
    label: "EF010240: Suitable for substructure"
  },
  {
    featureId: "EF010241",
    featureDesc: "Gutter overhang",
    label: "EF010241: Gutter overhang"
  },
  {
    featureId: "EF010242",
    featureDesc: "Span",
    label: "EF010242: Span"
  },
  {
    featureId: "EF010245",
    featureDesc: "Thermal resistance value",
    label: "EF010245: Thermal resistance value"
  },
  {
    featureId: "EF010248",
    featureDesc: "Voluminous mass",
    label: "EF010248: Voluminous mass"
  },
  {
    featureId: "EF010255",
    featureDesc: "Colour fastness according to greyscale (ISO 105 A02)",
    label: "EF010255: Colour fastness according to greyscale (ISO 105 A02)"
  },
  {
    featureId: "EF010257",
    featureDesc: "Double sided UV resistant",
    label: "EF010257: Double sided UV resistant"
  },
  {
    featureId: "EF010258",
    featureDesc: "Specific mass",
    label: "EF010258: Specific mass"
  },
  {
    featureId: "EF010259",
    featureDesc: "Connection 6",
    label: "EF010259: Connection 6"
  },
  {
    featureId: "EF010260",
    featureDesc: "Material window frame",
    label: "EF010260: Material window frame"
  },
  {
    featureId: "EF010261",
    featureDesc: "Material exterior finish",
    label: "EF010261: Material exterior finish"
  },
  {
    featureId: "EF010262",
    featureDesc: "Material interior finish",
    label: "EF010262: Material interior finish"
  },
  {
    featureId: "EF010263",
    featureDesc: "Outside colour",
    label: "EF010263: Outside colour"
  },
  {
    featureId: "EF010264",
    featureDesc: "Width window frame",
    label: "EF010264: Width window frame"
  },
  {
    featureId: "EF010265",
    featureDesc: "Height upper part window frame",
    label: "EF010265: Height upper part window frame"
  },
  {
    featureId: "EF010266",
    featureDesc: "Height lower part window frame",
    label: "EF010266: Height lower part window frame"
  },
  {
    featureId: "EF010268",
    featureDesc: "Type of glass",
    label: "EF010268: Type of glass"
  },
  {
    featureId: "EF010269",
    featureDesc: "Safety glass according to NEN 3569",
    label: "EF010269: Safety glass according to NEN 3569"
  },
  {
    featureId: "EF010270",
    featureDesc: "Fall safe",
    label: "EF010270: Fall safe"
  },
  {
    featureId: "EF010271",
    featureDesc: "Ornament glass",
    label: "EF010271: Ornament glass"
  },
  {
    featureId: "EF010272",
    featureDesc: "Sun-/heat resistant",
    label: "EF010272: Sun-/heat resistant"
  },
  {
    featureId: "EF010273",
    featureDesc: "Ug-value",
    label: "EF010273: Ug-value"
  },
  {
    featureId: "EF010274",
    featureDesc: "Uw-value",
    label: "EF010274: Uw-value"
  },
  {
    featureId: "EF010275",
    featureDesc: "G-value",
    label: "EF010275: G-value"
  },
  {
    featureId: "EF010276",
    featureDesc: "Rw-value",
    label: "EF010276: Rw-value"
  },
  {
    featureId: "EF010277",
    featureDesc: "Air volume flow",
    label: "EF010277: Air volume flow"
  },
  {
    featureId: "EF010278",
    featureDesc: "Adjustment ventilation",
    label: "EF010278: Adjustment ventilation"
  },
  {
    featureId: "EF010282",
    featureDesc: "With permanent flashing",
    label: "EF010282: With permanent flashing"
  },
  {
    featureId: "EF010283",
    featureDesc: "Height window frame",
    label: "EF010283: Height window frame"
  },
  {
    featureId: "EF010284",
    featureDesc: "Max. illuminated surface",
    label: "EF010284: Max. illuminated surface"
  },
  {
    featureId: "EF010285",
    featureDesc: "Max. extension",
    label: "EF010285: Max. extension"
  },
  {
    featureId: "EF010286",
    featureDesc: "Max. thickness roofing",
    label: "EF010286: Max. thickness roofing"
  },
  {
    featureId: "EF010290",
    featureDesc: "Width throw-in",
    label: "EF010290: Width throw-in"
  },
  {
    featureId: "EF010292",
    featureDesc: "Water- and wind tight",
    label: "EF010292: Water- and wind tight"
  },
  {
    featureId: "EF010293",
    featureDesc: "Height throw-in",
    label: "EF010293: Height throw-in"
  },
  {
    featureId: "EF010295",
    featureDesc: "Surface protection hinge",
    label: "EF010295: Surface protection hinge"
  },
  {
    featureId: "EF010296",
    featureDesc: "Model pin",
    label: "EF010296: Model pin"
  },
  {
    featureId: "EF010298",
    featureDesc: "Model hinge",
    label: "EF010298: Model hinge"
  },
  {
    featureId: "EF010299",
    featureDesc: "Anti-burglary pin",
    label: "EF010299: Anti-burglary pin"
  },
  {
    featureId: "EF010304",
    featureDesc: "Pump system",
    label: "EF010304: Pump system"
  },
  {
    featureId: "EF010305",
    featureDesc: "Adjustable nozzle",
    label: "EF010305: Adjustable nozzle"
  },
  {
    featureId: "EF010306",
    featureDesc: "With viewing window",
    label: "EF010306: With viewing window"
  },
  {
    featureId: "EF010308",
    featureDesc: "Portable",
    label: "EF010308: Portable"
  },
  {
    featureId: "EF010310",
    featureDesc: "Cut angle",
    label: "EF010310: Cut angle"
  },
  {
    featureId: "EF010311",
    featureDesc: "With upright edge",
    label: "EF010311: With upright edge"
  },
  {
    featureId: "EF010312",
    featureDesc: "With angled upstanding edge",
    label: "EF010312: With angled upstanding edge"
  },
  {
    featureId: "EF010313",
    featureDesc: "With joint mesh",
    label: "EF010313: With joint mesh"
  },
  {
    featureId: "EF010314",
    featureDesc: "Width base plate",
    label: "EF010314: Width base plate"
  },
  {
    featureId: "EF010315",
    featureDesc: "Length base plate",
    label: "EF010315: Length base plate"
  },
  {
    featureId: "EF010316",
    featureDesc: "Forward",
    label: "EF010316: Forward"
  },
  {
    featureId: "EF010317",
    featureDesc: "Backward",
    label: "EF010317: Backward"
  },
  {
    featureId: "EF010319",
    featureDesc: "Impact energy",
    label: "EF010319: Impact energy"
  },
  {
    featureId: "EF010320",
    featureDesc: "Max. angle of inclination",
    label: "EF010320: Max. angle of inclination"
  },
  {
    featureId: "EF010323",
    featureDesc: "Knife type",
    label: "EF010323: Knife type"
  },
  {
    featureId: "EF010325",
    featureDesc: "Max. spray tip",
    label: "EF010325: Max. spray tip"
  },
  {
    featureId: "EF010326",
    featureDesc: "With topping",
    label: "EF010326: With topping"
  },
  {
    featureId: "EF010327",
    featureDesc: "Material topping",
    label: "EF010327: Material topping"
  },
  {
    featureId: "EF010328",
    featureDesc: "Length saw blade",
    label: "EF010328: Length saw blade"
  },
  {
    featureId: "EF010330",
    featureDesc: "Saw depth",
    label: "EF010330: Saw depth"
  },
  {
    featureId: "EF010331",
    featureDesc: "With twine cord",
    label: "EF010331: With twine cord"
  },
  {
    featureId: "EF010335",
    featureDesc: "Cut width",
    label: "EF010335: Cut width"
  },
  {
    featureId: "EF010336",
    featureDesc: "Saw blade model",
    label: "EF010336: Saw blade model"
  },
  {
    featureId: "EF010337",
    featureDesc: "Grain size according to EN 12620",
    label: "EF010337: Grain size according to EN 12620"
  },
  {
    featureId: "EF010338",
    featureDesc: "Thermal conductivity according to EN 1745 (lambda-10, dry, P=90 %)",
    label: "EF010338: Thermal conductivity according to EN 1745 (lambda-10, dry, P=90 %)"
  },
  {
    featureId: "EF010339",
    featureDesc: "Model wall cover",
    label: "EF010339: Model wall cover"
  },
  {
    featureId: "EF010340",
    featureDesc: "Model end stone",
    label: "EF010340: Model end stone"
  },
  {
    featureId: "EF010342",
    featureDesc: "Fall",
    label: "EF010342: Fall"
  },
  {
    featureId: "EF010351",
    featureDesc: "Material spout",
    label: "EF010351: Material spout"
  },
  {
    featureId: "EF010352",
    featureDesc: "Material quality spout",
    label: "EF010352: Material quality spout"
  },
  {
    featureId: "EF010353",
    featureDesc: "Material quality roof flashing",
    label: "EF010353: Material quality roof flashing"
  },
  {
    featureId: "EF010354",
    featureDesc: "Surface protection spout",
    label: "EF010354: Surface protection spout"
  },
  {
    featureId: "EF010355",
    featureDesc: "Surface protection roof flashing",
    label: "EF010355: Surface protection roof flashing"
  },
  {
    featureId: "EF010356",
    featureDesc: "Production method roof flashing",
    label: "EF010356: Production method roof flashing"
  },
  {
    featureId: "EF010358",
    featureDesc: "Constructive",
    label: "EF010358: Constructive"
  },
  {
    featureId: "EF010359",
    featureDesc: "Suitable as plaster board",
    label: "EF010359: Suitable as plaster board"
  },
  {
    featureId: "EF010361",
    featureDesc: "Edge finishing long side",
    label: "EF010361: Edge finishing long side"
  },
  {
    featureId: "EF010362",
    featureDesc: "Edge finishing head end",
    label: "EF010362: Edge finishing head end"
  },
  {
    featureId: "EF010363",
    featureDesc: "Covering width",
    label: "EF010363: Covering width"
  },
  {
    featureId: "EF010364",
    featureDesc: "Batten spacing",
    label: "EF010364: Batten spacing"
  },
  {
    featureId: "EF010365",
    featureDesc: "Min. roof pitch",
    label: "EF010365: Min. roof pitch"
  },
  {
    featureId: "EF010366",
    featureDesc: "Colour visible surface",
    label: "EF010366: Colour visible surface"
  },
  {
    featureId: "EF010368",
    featureDesc: "Number per square meter",
    label: "EF010368: Number per square meter"
  },
  {
    featureId: "EF010369",
    featureDesc: "Fleece reinforced",
    label: "EF010369: Fleece reinforced"
  },
  {
    featureId: "EF010371",
    featureDesc: "With double clasp",
    label: "EF010371: With double clasp"
  },
  {
    featureId: "EF010372",
    featureDesc: "Number per meter",
    label: "EF010372: Number per meter"
  },
  {
    featureId: "EF010373",
    featureDesc: "For gypsum board thickness",
    label: "EF010373: For gypsum board thickness"
  },
  {
    featureId: "EF010374",
    featureDesc: "For plaster thickness",
    label: "EF010374: For plaster thickness"
  },
  {
    featureId: "EF010376",
    featureDesc: "Material wire mesh",
    label: "EF010376: Material wire mesh"
  },
  {
    featureId: "EF010377",
    featureDesc: "With toplayer",
    label: "EF010377: With toplayer"
  },
  {
    featureId: "EF010378",
    featureDesc: "Can be washed off",
    label: "EF010378: Can be washed off"
  },
  {
    featureId: "EF010379",
    featureDesc: "SKG-mark",
    label: "EF010379: SKG-mark"
  },
  {
    featureId: "EF010380",
    featureDesc: "Door thickness with standard plate",
    label: "EF010380: Door thickness with standard plate"
  },
  {
    featureId: "EF010381",
    featureDesc: "Door thickness with SKG plate",
    label: "EF010381: Door thickness with SKG plate"
  },
  {
    featureId: "EF010382",
    featureDesc: "Only suitable for rolling shutter",
    label: "EF010382: Only suitable for rolling shutter"
  },
  {
    featureId: "EF010383",
    featureDesc: "Diameter button",
    label: "EF010383: Diameter button"
  },
  {
    featureId: "EF010384",
    featureDesc: "With ajar position",
    label: "EF010384: With ajar position"
  },
  {
    featureId: "EF010385",
    featureDesc: "Knot diameter",
    label: "EF010385: Knot diameter"
  },
  {
    featureId: "EF010386",
    featureDesc: "Carrying capacity per 2",
    label: "EF010386: Carrying capacity per 2"
  },
  {
    featureId: "EF010387",
    featureDesc: "Carrying capacity per 3",
    label: "EF010387: Carrying capacity per 3"
  },
  {
    featureId: "EF010388",
    featureDesc: "Carrying capacity per 4",
    label: "EF010388: Carrying capacity per 4"
  },
  {
    featureId: "EF010389",
    featureDesc: "Rising butt hinge",
    label: "EF010389: Rising butt hinge"
  },
  {
    featureId: "EF010390",
    featureDesc: "Surface treatment hinge",
    label: "EF010390: Surface treatment hinge"
  },
  {
    featureId: "EF010391",
    featureDesc: "Narrow hinge",
    label: "EF010391: Narrow hinge"
  },
  {
    featureId: "EF010395",
    featureDesc: "Material arm",
    label: "EF010395: Material arm"
  },
  {
    featureId: "EF010396",
    featureDesc: "Type of arm",
    label: "EF010396: Type of arm"
  },
  {
    featureId: "EF010397",
    featureDesc: "Adjustable opening damping",
    label: "EF010397: Adjustable opening damping"
  },
  {
    featureId: "EF010398",
    featureDesc: "Closing speed adjustable",
    label: "EF010398: Closing speed adjustable"
  },
  {
    featureId: "EF010399",
    featureDesc: "Adjustable end stop",
    label: "EF010399: Adjustable end stop"
  },
  {
    featureId: "EF010400",
    featureDesc: "Adjustable spring pressure",
    label: "EF010400: Adjustable spring pressure"
  },
  {
    featureId: "EF010401",
    featureDesc: "Door opening",
    label: "EF010401: Door opening"
  },
  {
    featureId: "EF010403",
    featureDesc: "Max. exterior door width",
    label: "EF010403: Max. exterior door width"
  },
  {
    featureId: "EF010404",
    featureDesc: "Closing speed",
    label: "EF010404: Closing speed"
  },
  {
    featureId: "EF010405",
    featureDesc: "Fire resistant according to EN 1634-1",
    label: "EF010405: Fire resistant according to EN 1634-1"
  },
  {
    featureId: "EF010406",
    featureDesc: "Corrosion resistance",
    label: "EF010406: Corrosion resistance"
  },
  {
    featureId: "EF010407",
    featureDesc: "Max. interior door width",
    label: "EF010407: Max. interior door width"
  },
  {
    featureId: "EF010408",
    featureDesc: "For outside control",
    label: "EF010408: For outside control"
  },
  {
    featureId: "EF010409",
    featureDesc: "For inside control",
    label: "EF010409: For inside control"
  },
  {
    featureId: "EF010410",
    featureDesc: "Core pulling protection",
    label: "EF010410: Core pulling protection"
  },
  {
    featureId: "EF010411",
    featureDesc: "Thickness interior backplate",
    label: "EF010411: Thickness interior backplate"
  },
  {
    featureId: "EF010412",
    featureDesc: "Thickness exterior backplate",
    label: "EF010412: Thickness exterior backplate"
  },
  {
    featureId: "EF010414",
    featureDesc: "Number of locking pins",
    label: "EF010414: Number of locking pins"
  },
  {
    featureId: "EF010415",
    featureDesc: "Adjustable cylinder cam",
    label: "EF010415: Adjustable cylinder cam"
  },
  {
    featureId: "EF010416",
    featureDesc: "Anti-drill safety",
    label: "EF010416: Anti-drill safety"
  },
  {
    featureId: "EF010417",
    featureDesc: "Anti picking safety",
    label: "EF010417: Anti picking safety"
  },
  {
    featureId: "EF010418",
    featureDesc: "Anti-snap safety",
    label: "EF010418: Anti-snap safety"
  },
  {
    featureId: "EF010419",
    featureDesc: "Registration code on key",
    label: "EF010419: Registration code on key"
  },
  {
    featureId: "EF010420",
    featureDesc: "With forced smoke and heat exhaust",
    label: "EF010420: With forced smoke and heat exhaust"
  },
  {
    featureId: "EF010421",
    featureDesc: "Surface treatment window frame",
    label: "EF010421: Surface treatment window frame"
  },
  {
    featureId: "EF010422",
    featureDesc: "Max. light output",
    label: "EF010422: Max. light output"
  },
  {
    featureId: "EF010423",
    featureDesc: "Type of wave",
    label: "EF010423: Type of wave"
  },
  {
    featureId: "EF010424",
    featureDesc: "Number of walls",
    label: "EF010424: Number of walls"
  },
  {
    featureId: "EF010425",
    featureDesc: "Type of concrete",
    label: "EF010425: Type of concrete"
  },
  {
    featureId: "EF010426",
    featureDesc: "Type of mortar",
    label: "EF010426: Type of mortar"
  },
  {
    featureId: "EF010427",
    featureDesc: "Strength class according to EN 206-1",
    label: "EF010427: Strength class according to EN 206-1"
  },
  {
    featureId: "EF010428",
    featureDesc: "Environmental class concrete rebar according to EN 206-1",
    label: "EF010428: Environmental class concrete rebar according to EN 206-1"
  },
  {
    featureId: "EF010429",
    featureDesc: "Environmental class concrete rebar carbonation according to EN 206-1",
    label: "EF010429: Environmental class concrete rebar carbonation according to EN 206-1"
  },
  {
    featureId: "EF010430",
    featureDesc: "Environmental class concrete rebar chloride and de-icing salts acc. to EN 206-1",
    label: "EF010430: Environmental class concrete rebar chloride and de-icing salts acc. to EN 206-1"
  },
  {
    featureId: "EF010431",
    featureDesc: "Environmental class concrete rebar seawater according to EN 206-1",
    label: "EF010431: Environmental class concrete rebar seawater according to EN 206-1"
  },
  {
    featureId: "EF010432",
    featureDesc: "Environmental class concrete frost, with/without de-icing salts acc. to EN 206-1",
    label: "EF010432: Environmental class concrete frost, with/without de-icing salts acc. to EN 206-1"
  },
  {
    featureId: "EF010433",
    featureDesc: "Environmental class aggressive concrete according to EN 206-1",
    label: "EF010433: Environmental class aggressive concrete according to EN 206-1"
  },
  {
    featureId: "EF010434",
    featureDesc: "Consistency class size compaction according to EN 206-1",
    label: "EF010434: Consistency class size compaction according to EN 206-1"
  },
  {
    featureId: "EF010435",
    featureDesc: "Consistency class slump according to EN 206-1",
    label: "EF010435: Consistency class slump according to EN 206-1"
  },
  {
    featureId: "EF010436",
    featureDesc: "Consistency class shake measure according to EN 206-1",
    label: "EF010436: Consistency class shake measure according to EN 206-1"
  },
  {
    featureId: "EF010438",
    featureDesc: "Type of cement",
    label: "EF010438: Type of cement"
  },
  {
    featureId: "EF010439",
    featureDesc: "Proceeds",
    label: "EF010439: Proceeds"
  },
  {
    featureId: "EF010440",
    featureDesc: "Without crimp",
    label: "EF010440: Without crimp"
  },
  {
    featureId: "EF010441",
    featureDesc: "Max. grain size",
    label: "EF010441: Max. grain size"
  },
  {
    featureId: "EF010442",
    featureDesc: "Suitable for non-absorbent surfaces",
    label: "EF010442: Suitable for non-absorbent surfaces"
  },
  {
    featureId: "EF010443",
    featureDesc: "Suitable for absorbent surfaces",
    label: "EF010443: Suitable for absorbent surfaces"
  },
  {
    featureId: "EF010446",
    featureDesc: "Binding agent",
    label: "EF010446: Binding agent"
  },
  {
    featureId: "EF010447",
    featureDesc: "Compressive strength according to EN 13813",
    label: "EF010447: Compressive strength according to EN 13813"
  },
  {
    featureId: "EF010448",
    featureDesc: "Bending strength according to EN 13813",
    label: "EF010448: Bending strength according to EN 13813"
  },
  {
    featureId: "EF010449",
    featureDesc: "Layer thickness",
    label: "EF010449: Layer thickness"
  },
  {
    featureId: "EF010450",
    featureDesc: "Fibre-reinforced",
    label: "EF010450: Fibre-reinforced"
  },
  {
    featureId: "EF010451",
    featureDesc: "Mortar application type according to EN 998-2",
    label: "EF010451: Mortar application type according to EN 998-2"
  },
  {
    featureId: "EF010452",
    featureDesc: "Masonry mortar type according to EN 998-2",
    label: "EF010452: Masonry mortar type according to EN 998-2"
  },
  {
    featureId: "EF010453",
    featureDesc: "Application class according to EN 1996-2",
    label: "EF010453: Application class according to EN 1996-2"
  },
  {
    featureId: "EF010454",
    featureDesc: "Compressive strength according to EN 998-2",
    label: "EF010454: Compressive strength according to EN 998-2"
  },
  {
    featureId: "EF010455",
    featureDesc: "Joint thickness",
    label: "EF010455: Joint thickness"
  },
  {
    featureId: "EF010457",
    featureDesc: "Quality",
    label: "EF010457: Quality"
  },
  {
    featureId: "EF010459",
    featureDesc: "Profiled",
    label: "EF010459: Profiled"
  },
  {
    featureId: "EF010465",
    featureDesc: "Nominal duct width branch",
    label: "EF010465: Nominal duct width branch"
  },
  {
    featureId: "EF010466",
    featureDesc: "Nominal duct height branch",
    label: "EF010466: Nominal duct height branch"
  },
  {
    featureId: "EF010467",
    featureDesc: "With rebar ends",
    label: "EF010467: With rebar ends"
  },
  {
    featureId: "EF010468",
    featureDesc: "For operating method",
    label: "EF010468: For operating method"
  },
  {
    featureId: "EF010471",
    featureDesc: "Material quality hinge",
    label: "EF010471: Material quality hinge"
  },
  {
    featureId: "EF010472",
    featureDesc: "Number of included keys",
    label: "EF010472: Number of included keys"
  },
  {
    featureId: "EF010473",
    featureDesc: "With door handle plate",
    label: "EF010473: With door handle plate"
  },
  {
    featureId: "EF010474",
    featureDesc: "Height knob",
    label: "EF010474: Height knob"
  },
  {
    featureId: "EF010475",
    featureDesc: "Width knob",
    label: "EF010475: Width knob"
  },
  {
    featureId: "EF010476",
    featureDesc: "Number of layers surface treatment",
    label: "EF010476: Number of layers surface treatment"
  },
  {
    featureId: "EF010478",
    featureDesc: "Fair-face sight side",
    label: "EF010478: Fair-face sight side"
  },
  {
    featureId: "EF010483",
    featureDesc: "Width gearbox",
    label: "EF010483: Width gearbox"
  },
  {
    featureId: "EF010484",
    featureDesc: "Length operating element",
    label: "EF010484: Length operating element"
  },
  {
    featureId: "EF010486",
    featureDesc: "Height gearbox",
    label: "EF010486: Height gearbox"
  },
  {
    featureId: "EF010488",
    featureDesc: "Diffuse glass",
    label: "EF010488: Diffuse glass"
  },
  {
    featureId: "EF010489",
    featureDesc: "Reflective glass",
    label: "EF010489: Reflective glass"
  },
  {
    featureId: "EF010491",
    featureDesc: "Insulation value (U)",
    label: "EF010491: Insulation value (U)"
  },
  {
    featureId: "EF010492",
    featureDesc: "Under pressure processable",
    label: "EF010492: Under pressure processable"
  },
  {
    featureId: "EF010493",
    featureDesc: "Temperature resistance longtime",
    label: "EF010493: Temperature resistance longtime"
  },
  {
    featureId: "EF010494",
    featureDesc: "Temperature resistance shorttime",
    label: "EF010494: Temperature resistance shorttime"
  },
  {
    featureId: "EF010495",
    featureDesc: "Two component version",
    label: "EF010495: Two component version"
  },
  {
    featureId: "EF010496",
    featureDesc: "Width upper flange",
    label: "EF010496: Width upper flange"
  },
  {
    featureId: "EF010497",
    featureDesc: "Width bottom flange",
    label: "EF010497: Width bottom flange"
  },
  {
    featureId: "EF010498",
    featureDesc: "Diameter upper thread",
    label: "EF010498: Diameter upper thread"
  },
  {
    featureId: "EF010499",
    featureDesc: "Diameter diagonal thread",
    label: "EF010499: Diameter diagonal thread"
  },
  {
    featureId: "EF010500",
    featureDesc: "Diameter under thread",
    label: "EF010500: Diameter under thread"
  },
  {
    featureId: "EF010502",
    featureDesc: "Hinge point",
    label: "EF010502: Hinge point"
  },
  {
    featureId: "EF010503",
    featureDesc: "Material flooring",
    label: "EF010503: Material flooring"
  },
  {
    featureId: "EF010504",
    featureDesc: "Fire resistance according to EN 1992-1-1",
    label: "EF010504: Fire resistance according to EN 1992-1-1"
  },
  {
    featureId: "EF010505",
    featureDesc: "With exhaust",
    label: "EF010505: With exhaust"
  },
  {
    featureId: "EF010506",
    featureDesc: "Width roof opening",
    label: "EF010506: Width roof opening"
  },
  {
    featureId: "EF010507",
    featureDesc: "Length roof opening",
    label: "EF010507: Length roof opening"
  },
  {
    featureId: "EF010508",
    featureDesc: "Height roof upstand",
    label: "EF010508: Height roof upstand"
  },
  {
    featureId: "EF010509",
    featureDesc: "Roof upstand insulated",
    label: "EF010509: Roof upstand insulated"
  },
  {
    featureId: "EF010510",
    featureDesc: "With plastic shell",
    label: "EF010510: With plastic shell"
  },
  {
    featureId: "EF010512",
    featureDesc: "Material plastic shell",
    label: "EF010512: Material plastic shell"
  },
  {
    featureId: "EF010514",
    featureDesc: "Number of layers plastic shell",
    label: "EF010514: Number of layers plastic shell"
  },
  {
    featureId: "EF010515",
    featureDesc: "Diameter hose tulle",
    label: "EF010515: Diameter hose tulle"
  },
  {
    featureId: "EF010530",
    featureDesc: "Diameter roof opening",
    label: "EF010530: Diameter roof opening"
  },
  {
    featureId: "EF010531",
    featureDesc: "Version corner",
    label: "EF010531: Version corner"
  },
  {
    featureId: "EF010532",
    featureDesc: "With pipeline openings",
    label: "EF010532: With pipeline openings"
  },
  {
    featureId: "EF010533",
    featureDesc: "With pipes",
    label: "EF010533: With pipes"
  },
  {
    featureId: "EF010534",
    featureDesc: "Suitable for clean brickwork",
    label: "EF010534: Suitable for clean brickwork"
  },
  {
    featureId: "EF010535",
    featureDesc: "Size spreading according to EN 1344",
    label: "EF010535: Size spreading according to EN 1344"
  },
  {
    featureId: "EF010536",
    featureDesc: "Water absorption according to EN 1344",
    label: "EF010536: Water absorption according to EN 1344"
  },
  {
    featureId: "EF010544",
    featureDesc: "Wired glass",
    label: "EF010544: Wired glass"
  },
  {
    featureId: "EF010545",
    featureDesc: "Hardened glass",
    label: "EF010545: Hardened glass"
  },
  {
    featureId: "EF010548",
    featureDesc: "Max. expansion",
    label: "EF010548: Max. expansion"
  },
  {
    featureId: "EF010549",
    featureDesc: "Impregnated",
    label: "EF010549: Impregnated"
  },
  {
    featureId: "EF010551",
    featureDesc: "Suitable for plaster",
    label: "EF010551: Suitable for plaster"
  },
  {
    featureId: "EF010552",
    featureDesc: "Suitable for plasterboard",
    label: "EF010552: Suitable for plasterboard"
  },
  {
    featureId: "EF010555",
    featureDesc: "Cavity size",
    label: "EF010555: Cavity size"
  },
  {
    featureId: "EF010557",
    featureDesc: "Spindle version",
    label: "EF010557: Spindle version"
  },
  {
    featureId: "EF010558",
    featureDesc: "Adjustable middle column",
    label: "EF010558: Adjustable middle column"
  },
  {
    featureId: "EF010560",
    featureDesc: "Tripod head",
    label: "EF010560: Tripod head"
  },
  {
    featureId: "EF010561",
    featureDesc: "Diameter tripod head",
    label: "EF010561: Diameter tripod head"
  },
  {
    featureId: "EF010562",
    featureDesc: "Clamp method tripod legs",
    label: "EF010562: Clamp method tripod legs"
  },
  {
    featureId: "EF010563",
    featureDesc: "Suitable for type of surface",
    label: "EF010563: Suitable for type of surface"
  },
  {
    featureId: "EF010564",
    featureDesc: "Solvent free",
    label: "EF010564: Solvent free"
  },
  {
    featureId: "EF010565",
    featureDesc: "Waterborne",
    label: "EF010565: Waterborne"
  },
  {
    featureId: "EF010566",
    featureDesc: "Colour after drying",
    label: "EF010566: Colour after drying"
  },
  {
    featureId: "EF010567",
    featureDesc: "Moisture resistance according to EN 204",
    label: "EF010567: Moisture resistance according to EN 204"
  },
  {
    featureId: "EF010568",
    featureDesc: "Filling",
    label: "EF010568: Filling"
  },
  {
    featureId: "EF010569",
    featureDesc: "Suitable for PVC",
    label: "EF010569: Suitable for PVC"
  },
  {
    featureId: "EF010570",
    featureDesc: "Suitable for PE",
    label: "EF010570: Suitable for PE"
  },
  {
    featureId: "EF010571",
    featureDesc: "Suitable for glassfibre plastic",
    label: "EF010571: Suitable for glassfibre plastic"
  },
  {
    featureId: "EF010572",
    featureDesc: "Suitable for coated steel",
    label: "EF010572: Suitable for coated steel"
  },
  {
    featureId: "EF010573",
    featureDesc: "Sprayable",
    label: "EF010573: Sprayable"
  },
  {
    featureId: "EF010574",
    featureDesc: "THF free",
    label: "EF010574: THF free"
  },
  {
    featureId: "EF010575",
    featureDesc: "Max. temperature resistance at normal load",
    label: "EF010575: Max. temperature resistance at normal load"
  },
  {
    featureId: "EF010576",
    featureDesc: "Max. temperature resistance at peak load",
    label: "EF010576: Max. temperature resistance at peak load"
  },
  {
    featureId: "EF010577",
    featureDesc: "Suitable for max. pipe diameter",
    label: "EF010577: Suitable for max. pipe diameter"
  },
  {
    featureId: "EF010578",
    featureDesc: "Suitable for pressure",
    label: "EF010578: Suitable for pressure"
  },
  {
    featureId: "EF010579",
    featureDesc: "Gap fitting",
    label: "EF010579: Gap fitting"
  },
  {
    featureId: "EF010580",
    featureDesc: "Press fitting",
    label: "EF010580: Press fitting"
  },
  {
    featureId: "EF010582",
    featureDesc: "Suitable for sanding",
    label: "EF010582: Suitable for sanding"
  },
  {
    featureId: "EF010583",
    featureDesc: "Suitable for load-bearing applications",
    label: "EF010583: Suitable for load-bearing applications"
  },
  {
    featureId: "EF010585",
    featureDesc: "With double comb",
    label: "EF010585: With double comb"
  },
  {
    featureId: "EF010586",
    featureDesc: "Suitable for concrete tile",
    label: "EF010586: Suitable for concrete tile"
  },
  {
    featureId: "EF010587",
    featureDesc: "Suitable for ceramic tile",
    label: "EF010587: Suitable for ceramic tile"
  },
  {
    featureId: "EF010588",
    featureDesc: "Suitable for half round ridge",
    label: "EF010588: Suitable for half round ridge"
  },
  {
    featureId: "EF010589",
    featureDesc: "Suitable for scale ridge tile",
    label: "EF010589: Suitable for scale ridge tile"
  },
  {
    featureId: "EF010591",
    featureDesc: "Drying time",
    label: "EF010591: Drying time"
  },
  {
    featureId: "EF010593",
    featureDesc: "Reinforcement tape needed",
    label: "EF010593: Reinforcement tape needed"
  },
  {
    featureId: "EF010594",
    featureDesc: "Processing method",
    label: "EF010594: Processing method"
  },
  {
    featureId: "EF010595",
    featureDesc: "Class of the temperature control",
    label: "EF010595: Class of the temperature control"
  },
  {
    featureId: "EF010596",
    featureDesc: "Standing loss (S)",
    label: "EF010596: Standing loss (S)"
  },
  {
    featureId: "EF010597",
    featureDesc: "Seasonal space heating energy efficiency (Ns)",
    label: "EF010597: Seasonal space heating energy efficiency (Ns)"
  },
  {
    featureId: "EF010598",
    featureDesc: "Collector efficiency",
    label: "EF010598: Collector efficiency"
  },
  {
    featureId: "EF010599",
    featureDesc: "Water heating energy efficiency (Nwh)",
    label: "EF010599: Water heating energy efficiency (Nwh)"
  },
  {
    featureId: "EF010600",
    featureDesc: "Amount of span fields",
    label: "EF010600: Amount of span fields"
  },
  {
    featureId: "EF010601",
    featureDesc: "Upholstery front/top vapor-tight",
    label: "EF010601: Upholstery front/top vapor-tight"
  },
  {
    featureId: "EF010602",
    featureDesc: "Upholstery rear/bottom damp proof",
    label: "EF010602: Upholstery rear/bottom damp proof"
  },
  {
    featureId: "EF010603",
    featureDesc: "With bucket hook",
    label: "EF010603: With bucket hook"
  },
  {
    featureId: "EF010604",
    featureDesc: "Max. width paint roller",
    label: "EF010604: Max. width paint roller"
  },
  {
    featureId: "EF010605",
    featureDesc: "With paint grid",
    label: "EF010605: With paint grid"
  },
  {
    featureId: "EF010606",
    featureDesc: "Suitable for type plaster knife",
    label: "EF010606: Suitable for type plaster knife"
  },
  {
    featureId: "EF010608",
    featureDesc: "Diameter handle",
    label: "EF010608: Diameter handle"
  },
  {
    featureId: "EF010610",
    featureDesc: "Distance facade to shoring prop",
    label: "EF010610: Distance facade to shoring prop"
  },
  {
    featureId: "EF010611",
    featureDesc: "Type adjustability",
    label: "EF010611: Type adjustability"
  },
  {
    featureId: "EF010612",
    featureDesc: "With finger protection",
    label: "EF010612: With finger protection"
  },
  {
    featureId: "EF010613",
    featureDesc: "Working height",
    label: "EF010613: Working height"
  },
  {
    featureId: "EF010614",
    featureDesc: "Rotation direction handle",
    label: "EF010614: Rotation direction handle"
  },
  {
    featureId: "EF010615",
    featureDesc: "Position handle",
    label: "EF010615: Position handle"
  },
  {
    featureId: "EF010616",
    featureDesc: "Foot width",
    label: "EF010616: Foot width"
  },
  {
    featureId: "EF010617",
    featureDesc: "End bearing steel",
    label: "EF010617: End bearing steel"
  },
  {
    featureId: "EF010618",
    featureDesc: "End bearing masonry",
    label: "EF010618: End bearing masonry"
  },
  {
    featureId: "EF010619",
    featureDesc: "End bearing concrete",
    label: "EF010619: End bearing concrete"
  },
  {
    featureId: "EF010624",
    featureDesc: "Material frame corner",
    label: "EF010624: Material frame corner"
  },
  {
    featureId: "EF010625",
    featureDesc: "Colour netting",
    label: "EF010625: Colour netting"
  },
  {
    featureId: "EF010626",
    featureDesc: "Suitable for angled threshold",
    label: "EF010626: Suitable for angled threshold"
  },
  {
    featureId: "EF010627",
    featureDesc: "Height under strip",
    label: "EF010627: Height under strip"
  },
  {
    featureId: "EF010628",
    featureDesc: "With hinges",
    label: "EF010628: With hinges"
  },
  {
    featureId: "EF010629",
    featureDesc: "Position hinge",
    label: "EF010629: Position hinge"
  },
  {
    featureId: "EF010630",
    featureDesc: "Wheelchair accessible",
    label: "EF010630: Wheelchair accessible"
  },
  {
    featureId: "EF010631",
    featureDesc: "Material rolling shutter",
    label: "EF010631: Material rolling shutter"
  },
  {
    featureId: "EF010637",
    featureDesc: "Consumption",
    label: "EF010637: Consumption"
  },
  {
    featureId: "EF010638",
    featureDesc: "With Cam on the bottom",
    label: "EF010638: With Cam on the bottom"
  },
  {
    featureId: "EF010639",
    featureDesc: "Width below left",
    label: "EF010639: Width below left"
  },
  {
    featureId: "EF010640",
    featureDesc: "Width above left",
    label: "EF010640: Width above left"
  },
  {
    featureId: "EF010641",
    featureDesc: "Width below right",
    label: "EF010641: Width below right"
  },
  {
    featureId: "EF010642",
    featureDesc: "Width above right",
    label: "EF010642: Width above right"
  },
  {
    featureId: "EF010643",
    featureDesc: "Total height links",
    label: "EF010643: Total height links"
  },
  {
    featureId: "EF010644",
    featureDesc: "Total height right",
    label: "EF010644: Total height right"
  },
  {
    featureId: "EF010645",
    featureDesc: "Height visible surface left",
    label: "EF010645: Height visible surface left"
  },
  {
    featureId: "EF010646",
    featureDesc: "Height visible surface right",
    label: "EF010646: Height visible surface right"
  },
  {
    featureId: "EF010647",
    featureDesc: "Number per 360 degree",
    label: "EF010647: Number per 360 degree"
  },
  {
    featureId: "EF010650",
    featureDesc: "Composite formwork",
    label: "EF010650: Composite formwork"
  },
  {
    featureId: "EF010652",
    featureDesc: "Cement with high sulphate resistance (SR)",
    label: "EF010652: Cement with high sulphate resistance (SR)"
  },
  {
    featureId: "EF010653",
    featureDesc: "Cement with limited alkali (LA)",
    label: "EF010653: Cement with limited alkali (LA)"
  },
  {
    featureId: "EF010654",
    featureDesc: "Cement with low heat of hydration (LH)",
    label: "EF010654: Cement with low heat of hydration (LH)"
  },
  {
    featureId: "EF010655",
    featureDesc: "Cement with high initial strength (HES)",
    label: "EF010655: Cement with high initial strength (HES)"
  },
  {
    featureId: "EF010656",
    featureDesc: "Material quality outer pipe",
    label: "EF010656: Material quality outer pipe"
  },
  {
    featureId: "EF010665",
    featureDesc: "Material quality valve disc",
    label: "EF010665: Material quality valve disc"
  },
  {
    featureId: "EF010666",
    featureDesc: "Suitable for electra",
    label: "EF010666: Suitable for electra"
  },
  {
    featureId: "EF010667",
    featureDesc: "Suitable for telecom",
    label: "EF010667: Suitable for telecom"
  },
  {
    featureId: "EF010668",
    featureDesc: "Suitable for central antenna system",
    label: "EF010668: Suitable for central antenna system"
  },
  {
    featureId: "EF010669",
    featureDesc: "Ring stiffness class",
    label: "EF010669: Ring stiffness class"
  },
  {
    featureId: "EF010670",
    featureDesc: "Standard Dimension Ratio (SDR)",
    label: "EF010670: Standard Dimension Ratio (SDR)"
  },
  {
    featureId: "EF010671",
    featureDesc: "Surface protection connection 1",
    label: "EF010671: Surface protection connection 1"
  },
  {
    featureId: "EF010672",
    featureDesc: "Surface treatment connection 1",
    label: "EF010672: Surface treatment connection 1"
  },
  {
    featureId: "EF010673",
    featureDesc: "Surface protection connection 2",
    label: "EF010673: Surface protection connection 2"
  },
  {
    featureId: "EF010674",
    featureDesc: "Surface treatment connection 2",
    label: "EF010674: Surface treatment connection 2"
  },
  {
    featureId: "EF010675",
    featureDesc: "Material connection 3",
    label: "EF010675: Material connection 3"
  },
  {
    featureId: "EF010676",
    featureDesc: "Material quality connection 3",
    label: "EF010676: Material quality connection 3"
  },
  {
    featureId: "EF010677",
    featureDesc: "Surface protection connection 3",
    label: "EF010677: Surface protection connection 3"
  },
  {
    featureId: "EF010678",
    featureDesc: "Surface treatment connection 3",
    label: "EF010678: Surface treatment connection 3"
  },
  {
    featureId: "EF010679",
    featureDesc: "Wall thickness, connection 5",
    label: "EF010679: Wall thickness, connection 5"
  },
  {
    featureId: "EF010680",
    featureDesc: "Wall thickness, connection 6",
    label: "EF010680: Wall thickness, connection 6"
  },
  {
    featureId: "EF010681",
    featureDesc: "Thread size tap connection",
    label: "EF010681: Thread size tap connection"
  },
  {
    featureId: "EF010682",
    featureDesc: "Diameter handwheel",
    label: "EF010682: Diameter handwheel"
  },
  {
    featureId: "EF010683",
    featureDesc: "Material union nut",
    label: "EF010683: Material union nut"
  },
  {
    featureId: "EF010685",
    featureDesc: "For inner pipe diameter",
    label: "EF010685: For inner pipe diameter"
  },
  {
    featureId: "EF010690",
    featureDesc: "Flow limiter",
    label: "EF010690: Flow limiter"
  },
  {
    featureId: "EF010692",
    featureDesc: "Number of cable screw gland plates",
    label: "EF010692: Number of cable screw gland plates"
  },
  {
    featureId: "EF010733",
    featureDesc: "Position control panel",
    label: "EF010733: Position control panel"
  },
  {
    featureId: "EF010738",
    featureDesc: "Repeatability (+/-) according to ISO 9283",
    label: "EF010738: Repeatability (+/-) according to ISO 9283"
  },
  {
    featureId: "EF010746",
    featureDesc: "Degrees of freedom",
    label: "EF010746: Degrees of freedom"
  },
  {
    featureId: "EF010759",
    featureDesc: "Number of supports",
    label: "EF010759: Number of supports"
  },
  {
    featureId: "EF010767",
    featureDesc: "Max. shorten length",
    label: "EF010767: Max. shorten length"
  },
  {
    featureId: "EF010768",
    featureDesc: "With filler hose set",
    label: "EF010768: With filler hose set"
  },
  {
    featureId: "EF010769",
    featureDesc: "With pressure expansion vessel console",
    label: "EF010769: With pressure expansion vessel console"
  },
  {
    featureId: "EF010772",
    featureDesc: "SEER (Seasonal Energy Efficiency Ratio) at average conditions",
    label: "EF010772: SEER (Seasonal Energy Efficiency Ratio) at average conditions"
  },
  {
    featureId: "EF010773",
    featureDesc: "SEER (Seasonal Energy Efficiency Ratio) at colder conditions",
    label: "EF010773: SEER (Seasonal Energy Efficiency Ratio) at colder conditions"
  },
  {
    featureId: "EF010774",
    featureDesc: "SEER (Seasonal Energy Efficiency Ratio) at warmer conditions",
    label: "EF010774: SEER (Seasonal Energy Efficiency Ratio) at warmer conditions"
  },
  {
    featureId: "EF010777",
    featureDesc: "Capacity profile",
    label: "EF010777: Capacity profile"
  },
  {
    featureId: "EF010779",
    featureDesc: "Pump power consumption (solpump)",
    label: "EF010779: Pump power consumption (solpump)"
  },
  {
    featureId: "EF010780",
    featureDesc: "Pump power consumption standby (solstandbypump)",
    label: "EF010780: Pump power consumption standby (solstandbypump)"
  },
  {
    featureId: "EF010781",
    featureDesc: "Hot water tank class",
    label: "EF010781: Hot water tank class"
  },
  {
    featureId: "EF010782",
    featureDesc: "Supplementary electricity consumption (Qaux)",
    label: "EF010782: Supplementary electricity consumption (Qaux)"
  },
  {
    featureId: "EF010784",
    featureDesc: "SEER low temperature application (35 °C) at average conditions",
    label: "EF010784: SEER low temperature application (35 °C) at average conditions"
  },
  {
    featureId: "EF010785",
    featureDesc: "SEER low temperature application (35 °C) at colder conditions",
    label: "EF010785: SEER low temperature application (35 °C) at colder conditions"
  },
  {
    featureId: "EF010786",
    featureDesc: "SEER low temperature application (35 °C) at warmer conditions",
    label: "EF010786: SEER low temperature application (35 °C) at warmer conditions"
  },
  {
    featureId: "EF010787",
    featureDesc: "SEER middle temperature application (55 °C) at average conditions",
    label: "EF010787: SEER middle temperature application (55 °C) at average conditions"
  },
  {
    featureId: "EF010788",
    featureDesc: "SEER middle temperature application (55 °C) at colder conditions",
    label: "EF010788: SEER middle temperature application (55 °C) at colder conditions"
  },
  {
    featureId: "EF010789",
    featureDesc: "SEER middle temperature application (55 °C) at warmer conditions",
    label: "EF010789: SEER middle temperature application (55 °C) at warmer conditions"
  },
  {
    featureId: "EF010790",
    featureDesc: "Yearly share of non-solar energy (Qnonsol) capacity profile M",
    label: "EF010790: Yearly share of non-solar energy (Qnonsol) capacity profile M"
  },
  {
    featureId: "EF010791",
    featureDesc: "Yearly share of non-solar energy (Qnonsol) capacity profile L",
    label: "EF010791: Yearly share of non-solar energy (Qnonsol) capacity profile L"
  },
  {
    featureId: "EF010792",
    featureDesc: "Yearly share of non-solar energy (Qnonsol) capacity profile XL",
    label: "EF010792: Yearly share of non-solar energy (Qnonsol) capacity profile XL"
  },
  {
    featureId: "EF010793",
    featureDesc: "Yearly share of non-solar energy (Qnonsol) capacity profile XXL",
    label: "EF010793: Yearly share of non-solar energy (Qnonsol) capacity profile XXL"
  },
  {
    featureId: "EF010794",
    featureDesc: "Standby power consumption (solstandby)",
    label: "EF010794: Standby power consumption (solstandby)"
  },
  {
    featureId: "EF010795",
    featureDesc: "Built-in height",
    label: "EF010795: Built-in height"
  },
  {
    featureId: "EF010797",
    featureDesc: "Nominal duct diameter, indoor connection",
    label: "EF010797: Nominal duct diameter, indoor connection"
  },
  {
    featureId: "EF010798",
    featureDesc: "Nominal duct diameter, outdoor connection",
    label: "EF010798: Nominal duct diameter, outdoor connection"
  },
  {
    featureId: "EF010799",
    featureDesc: "1st order heat loss coefficient (a1)",
    label: "EF010799: 1st order heat loss coefficient (a1)"
  },
  {
    featureId: "EF010800",
    featureDesc: "2nd order heat loss coefficient (a2)",
    label: "EF010800: 2nd order heat loss coefficient (a2)"
  },
  {
    featureId: "EF010801",
    featureDesc: "Incidence angle modifier (IAM)",
    label: "EF010801: Incidence angle modifier (IAM)"
  },
  {
    featureId: "EF010802",
    featureDesc: "Inner conductor surface",
    label: "EF010802: Inner conductor surface"
  },
  {
    featureId: "EF010803",
    featureDesc: "Outer conductor surface",
    label: "EF010803: Outer conductor surface"
  },
  {
    featureId: "EF010804",
    featureDesc: "Conductor surface",
    label: "EF010804: Conductor surface"
  },
  {
    featureId: "EF010805",
    featureDesc: "Coaxial cable",
    label: "EF010805: Coaxial cable"
  },
  {
    featureId: "EF010807",
    featureDesc: "Wrench width 1",
    label: "EF010807: Wrench width 1"
  },
  {
    featureId: "EF010808",
    featureDesc: "Wrench width 2",
    label: "EF010808: Wrench width 2"
  },
  {
    featureId: "EF010810",
    featureDesc: "Suitable for free-standing assembly",
    label: "EF010810: Suitable for free-standing assembly"
  },
  {
    featureId: "EF010811",
    featureDesc: "Number of cables",
    label: "EF010811: Number of cables"
  },
  {
    featureId: "EF010814",
    featureDesc: "Burn time autonomous",
    label: "EF010814: Burn time autonomous"
  },
  {
    featureId: "EF010815",
    featureDesc: "Max. heated space",
    label: "EF010815: Max. heated space"
  },
  {
    featureId: "EF010816",
    featureDesc: "Content storage tank",
    label: "EF010816: Content storage tank"
  },
  {
    featureId: "EF010817",
    featureDesc: "With connection for central heating",
    label: "EF010817: With connection for central heating"
  },
  {
    featureId: "EF010827",
    featureDesc: "Centre distance connection",
    label: "EF010827: Centre distance connection"
  },
  {
    featureId: "EF010829",
    featureDesc: "Outer radius",
    label: "EF010829: Outer radius"
  },
  {
    featureId: "EF010835",
    featureDesc: "Width bottom side",
    label: "EF010835: Width bottom side"
  },
  {
    featureId: "EF010846",
    featureDesc: "Lamella distance",
    label: "EF010846: Lamella distance"
  },
  {
    featureId: "EF010852",
    featureDesc: "Max. label width",
    label: "EF010852: Max. label width"
  },
  {
    featureId: "EF010853",
    featureDesc: "Max. label length",
    label: "EF010853: Max. label length"
  },
  {
    featureId: "EF010854",
    featureDesc: "With ty-rap",
    label: "EF010854: With ty-rap"
  },
  {
    featureId: "EF010855",
    featureDesc: "Energy efficiency class room heating",
    label: "EF010855: Energy efficiency class room heating"
  },
  {
    featureId: "EF010856",
    featureDesc: "Energy efficiency class water heating",
    label: "EF010856: Energy efficiency class water heating"
  },
  {
    featureId: "EF010867",
    featureDesc: "Luminous flux per meter",
    label: "EF010867: Luminous flux per meter"
  },
  {
    featureId: "EF010871",
    featureDesc: "Flushing water quantity",
    label: "EF010871: Flushing water quantity"
  },
  {
    featureId: "EF010872",
    featureDesc: "Suitable for cable gland",
    label: "EF010872: Suitable for cable gland"
  },
  {
    featureId: "EF010880",
    featureDesc: "Connection size hose/union nut",
    label: "EF010880: Connection size hose/union nut"
  },
  {
    featureId: "EF010881",
    featureDesc: "Nominal diameter drain connection",
    label: "EF010881: Nominal diameter drain connection"
  },
  {
    featureId: "EF010882",
    featureDesc: "Nominal diameter primary connection",
    label: "EF010882: Nominal diameter primary connection"
  },
  {
    featureId: "EF010884",
    featureDesc: "Touch technology",
    label: "EF010884: Touch technology"
  },
  {
    featureId: "EF010896",
    featureDesc: "Nominal diameter hot tap water",
    label: "EF010896: Nominal diameter hot tap water"
  },
  {
    featureId: "EF010897",
    featureDesc: "Supplementary electricity consumption at full load (ELmax)",
    label: "EF010897: Supplementary electricity consumption at full load (ELmax)"
  },
  {
    featureId: "EF010900",
    featureDesc: "Diameter floor drain",
    label: "EF010900: Diameter floor drain"
  },
  {
    featureId: "EF010904",
    featureDesc: "With lifting gear",
    label: "EF010904: With lifting gear"
  },
  {
    featureId: "EF010910",
    featureDesc: "Useful heat emission at nom. heat emission, operation at high temperature (P4)",
    label: "EF010910: Useful heat emission at nom. heat emission, operation at high temperature (P4)"
  },
  {
    featureId: "EF010911",
    featureDesc: "Useful efficiency at nominal heat emission, operation at high temperature (n4)",
    label: "EF010911: Useful efficiency at nominal heat emission, operation at high temperature (n4)"
  },
  {
    featureId: "EF010912",
    featureDesc: "Supplementary electricity consumption in standby-mode (Psb)",
    label: "EF010912: Supplementary electricity consumption in standby-mode (Psb)"
  },
  {
    featureId: "EF010913",
    featureDesc: "Standby heat loss (Pstby)",
    label: "EF010913: Standby heat loss (Pstby)"
  },
  {
    featureId: "EF010933",
    featureDesc: "COPd at Tj = +7 °C",
    label: "EF010933: COPd at Tj = +7 °C"
  },
  {
    featureId: "EF010935",
    featureDesc: "Rail length",
    label: "EF010935: Rail length"
  },
  {
    featureId: "EF010966",
    featureDesc: "Width grid frame",
    label: "EF010966: Width grid frame"
  },
  {
    featureId: "EF010967",
    featureDesc: "Length grid frame",
    label: "EF010967: Length grid frame"
  },
  {
    featureId: "EF010968",
    featureDesc: "Height grid frame",
    label: "EF010968: Height grid frame"
  },
  {
    featureId: "EF010976",
    featureDesc: "Shape of plenum",
    label: "EF010976: Shape of plenum"
  },
  {
    featureId: "EF010977",
    featureDesc: "Reverse (negative) T-piece model",
    label: "EF010977: Reverse (negative) T-piece model"
  },
  {
    featureId: "EF010978",
    featureDesc: "Flow radius",
    label: "EF010978: Flow radius"
  },
  {
    featureId: "EF011003",
    featureDesc: "Finished top layer",
    label: "EF011003: Finished top layer"
  },
  {
    featureId: "EF011004",
    featureDesc: "Direction drain plug",
    label: "EF011004: Direction drain plug"
  },
  {
    featureId: "EF011011",
    featureDesc: "Longitudinal water blocking cable",
    label: "EF011011: Longitudinal water blocking cable"
  },
  {
    featureId: "EF011012",
    featureDesc: "Radial water blocking cable",
    label: "EF011012: Radial water blocking cable"
  },
  {
    featureId: "EF011020",
    featureDesc: "Eye width",
    label: "EF011020: Eye width"
  },
  {
    featureId: "EF011021",
    featureDesc: "Suitable for corner assembly left",
    label: "EF011021: Suitable for corner assembly left"
  },
  {
    featureId: "EF011022",
    featureDesc: "Suitable for corner assembly right",
    label: "EF011022: Suitable for corner assembly right"
  },
  {
    featureId: "EF011039",
    featureDesc: "Suitable for floor heating control",
    label: "EF011039: Suitable for floor heating control"
  },
  {
    featureId: "EF011040",
    featureDesc: "Suitable for district heating control",
    label: "EF011040: Suitable for district heating control"
  },
  {
    featureId: "EF011042",
    featureDesc: "With presence perception",
    label: "EF011042: With presence perception"
  },
  {
    featureId: "EF011061",
    featureDesc: "With fall protection",
    label: "EF011061: With fall protection"
  },
  {
    featureId: "EF011062",
    featureDesc: "Height ground level - inner bottom side pipe",
    label: "EF011062: Height ground level - inner bottom side pipe"
  },
  {
    featureId: "EF011063",
    featureDesc: "Flat bottom",
    label: "EF011063: Flat bottom"
  },
  {
    featureId: "EF011064",
    featureDesc: "With solid shaft",
    label: "EF011064: With solid shaft"
  },
  {
    featureId: "EF011066",
    featureDesc: "Inner diameter pit floor",
    label: "EF011066: Inner diameter pit floor"
  },
  {
    featureId: "EF011067",
    featureDesc: "Outer diameter pit floor",
    label: "EF011067: Outer diameter pit floor"
  },
  {
    featureId: "EF011068",
    featureDesc: "With telescope",
    label: "EF011068: With telescope"
  },
  {
    featureId: "EF011069",
    featureDesc: "Pit floor colour",
    label: "EF011069: Pit floor colour"
  },
  {
    featureId: "EF011070",
    featureDesc: "Shaft colour",
    label: "EF011070: Shaft colour"
  },
  {
    featureId: "EF011071",
    featureDesc: "Telescope colour",
    label: "EF011071: Telescope colour"
  },
  {
    featureId: "EF011072",
    featureDesc: "IFTTT support available",
    label: "EF011072: IFTTT support available"
  },
  {
    featureId: "EF011073",
    featureDesc: "Water dilution",
    label: "EF011073: Water dilution"
  },
  {
    featureId: "EF011074",
    featureDesc: "Freezing point",
    label: "EF011074: Freezing point"
  },
  {
    featureId: "EF011075",
    featureDesc: "Boiling point",
    label: "EF011075: Boiling point"
  },
  {
    featureId: "EF011076",
    featureDesc: "Contains inhibitors",
    label: "EF011076: Contains inhibitors"
  },
  {
    featureId: "EF011077",
    featureDesc: "With pulling eye",
    label: "EF011077: With pulling eye"
  },
  {
    featureId: "EF011078",
    featureDesc: "Kvs value 1",
    label: "EF011078: Kvs value 1"
  },
  {
    featureId: "EF011079",
    featureDesc: "Leakage loss of Kvs value 1",
    label: "EF011079: Leakage loss of Kvs value 1"
  },
  {
    featureId: "EF011080",
    featureDesc: "Kvs value 2",
    label: "EF011080: Kvs value 2"
  },
  {
    featureId: "EF011081",
    featureDesc: "Leakage loss of Kvs value 2",
    label: "EF011081: Leakage loss of Kvs value 2"
  },
  {
    featureId: "EF011082",
    featureDesc: "Reversing valve",
    label: "EF011082: Reversing valve"
  },
  {
    featureId: "EF011083",
    featureDesc: "Dead band (all ports closed)",
    label: "EF011083: Dead band (all ports closed)"
  },
  {
    featureId: "EF011084",
    featureDesc: "With integrated flow measurement",
    label: "EF011084: With integrated flow measurement"
  },
  {
    featureId: "EF011085",
    featureDesc: "Drive mounting in duct",
    label: "EF011085: Drive mounting in duct"
  },
  {
    featureId: "EF011086",
    featureDesc: "Delivery assembled",
    label: "EF011086: Delivery assembled"
  },
  {
    featureId: "EF011099",
    featureDesc: "With pictogram",
    label: "EF011099: With pictogram"
  },
  {
    featureId: "EF011100",
    featureDesc: "Torque spring",
    label: "EF011100: Torque spring"
  },
  {
    featureId: "EF011101",
    featureDesc: "Material drive",
    label: "EF011101: Material drive"
  },
  {
    featureId: "EF011102",
    featureDesc: "Form fit hollow spindle",
    label: "EF011102: Form fit hollow spindle"
  },
  {
    featureId: "EF011103",
    featureDesc: "Size of hollow spindle",
    label: "EF011103: Size of hollow spindle"
  },
  {
    featureId: "EF011104",
    featureDesc: "Locking in safety position",
    label: "EF011104: Locking in safety position"
  },
  {
    featureId: "EF011105",
    featureDesc: "Thermo-electrical safety fuse",
    label: "EF011105: Thermo-electrical safety fuse"
  },
  {
    featureId: "EF011106",
    featureDesc: "Shaft hole through-hole",
    label: "EF011106: Shaft hole through-hole"
  },
  {
    featureId: "EF011107",
    featureDesc: "Hollow spindle size shaft adapter",
    label: "EF011107: Hollow spindle size shaft adapter"
  },
  {
    featureId: "EF011108",
    featureDesc: "Number of auxiliary contacts",
    label: "EF011108: Number of auxiliary contacts"
  },
  {
    featureId: "EF011109",
    featureDesc: "Surface protection outer side",
    label: "EF011109: Surface protection outer side"
  },
  {
    featureId: "EF011110",
    featureDesc: "Suitable for central heating",
    label: "EF011110: Suitable for central heating"
  },
  {
    featureId: "EF011111",
    featureDesc: "Suitable for heat pump",
    label: "EF011111: Suitable for heat pump"
  },
  {
    featureId: "EF011113",
    featureDesc: "With connection temperature sensor",
    label: "EF011113: With connection temperature sensor"
  },
  {
    featureId: "EF011114",
    featureDesc: "Number of connections of temperature sensor",
    label: "EF011114: Number of connections of temperature sensor"
  },
  {
    featureId: "EF011115",
    featureDesc: "Connection size temperature sensor",
    label: "EF011115: Connection size temperature sensor"
  },
  {
    featureId: "EF011116",
    featureDesc: "With extra outlet",
    label: "EF011116: With extra outlet"
  },
  {
    featureId: "EF011117",
    featureDesc: "Number of temperature sensors",
    label: "EF011117: Number of temperature sensors"
  },
  {
    featureId: "EF011118",
    featureDesc: "Temperature sensors paired",
    label: "EF011118: Temperature sensors paired"
  },
  {
    featureId: "EF011119",
    featureDesc: "Type of pressure compensation",
    label: "EF011119: Type of pressure compensation"
  },
  {
    featureId: "EF011121",
    featureDesc: "Weight without battery-pack",
    label: "EF011121: Weight without battery-pack"
  },
  {
    featureId: "EF011123",
    featureDesc: "Load class according to EN 13598-2",
    label: "EF011123: Load class according to EN 13598-2"
  },
  {
    featureId: "EF011125",
    featureDesc: "Idle rotational speed 1st gear",
    label: "EF011125: Idle rotational speed 1st gear"
  },
  {
    featureId: "EF011126",
    featureDesc: "Idle rotational speed 2nd gear",
    label: "EF011126: Idle rotational speed 2nd gear"
  },
  {
    featureId: "EF011127",
    featureDesc: "Idle rotational speed 3rd gear",
    label: "EF011127: Idle rotational speed 3rd gear"
  },
  {
    featureId: "EF011129",
    featureDesc: "Delivery force",
    label: "EF011129: Delivery force"
  },
  {
    featureId: "EF011130",
    featureDesc: "Max. milling diameter",
    label: "EF011130: Max. milling diameter"
  },
  {
    featureId: "EF011131",
    featureDesc: "Milling depth adjustment",
    label: "EF011131: Milling depth adjustment"
  },
  {
    featureId: "EF011135",
    featureDesc: "Idle rotational speed 4th gear",
    label: "EF011135: Idle rotational speed 4th gear"
  },
  {
    featureId: "EF011136",
    featureDesc: "Min. cutting length",
    label: "EF011136: Min. cutting length"
  },
  {
    featureId: "EF011137",
    featureDesc: "Display resolution",
    label: "EF011137: Display resolution"
  },
  {
    featureId: "EF011142",
    featureDesc: "Max. bevel height steel 400 N/mm²",
    label: "EF011142: Max. bevel height steel 400 N/mm²"
  },
  {
    featureId: "EF011143",
    featureDesc: "Max. bevel height steel 600 N/mm²",
    label: "EF011143: Max. bevel height steel 600 N/mm²"
  },
  {
    featureId: "EF011144",
    featureDesc: "Max. bevel height steel 800 N/mm²",
    label: "EF011144: Max. bevel height steel 800 N/mm²"
  },
  {
    featureId: "EF011145",
    featureDesc: "Max. bevel height aluminium 250 N/mm²",
    label: "EF011145: Max. bevel height aluminium 250 N/mm²"
  },
  {
    featureId: "EF011147",
    featureDesc: "Min. inner radius",
    label: "EF011147: Min. inner radius"
  },
  {
    featureId: "EF011149",
    featureDesc: "Blow speed",
    label: "EF011149: Blow speed"
  },
  {
    featureId: "EF011157",
    featureDesc: "Max. work piece width",
    label: "EF011157: Max. work piece width"
  },
  {
    featureId: "EF011158",
    featureDesc: "Max. work piece diameter",
    label: "EF011158: Max. work piece diameter"
  },
  {
    featureId: "EF011160",
    featureDesc: "Brush width",
    label: "EF011160: Brush width"
  },
  {
    featureId: "EF011161",
    featureDesc: "Tool insertion",
    label: "EF011161: Tool insertion"
  },
  {
    featureId: "EF011162",
    featureDesc: "Drill diameter",
    label: "EF011162: Drill diameter"
  },
  {
    featureId: "EF011163",
    featureDesc: "Max. drill depth",
    label: "EF011163: Max. drill depth"
  },
  {
    featureId: "EF011166",
    featureDesc: "Diameter of oscillating circuit 1",
    label: "EF011166: Diameter of oscillating circuit 1"
  },
  {
    featureId: "EF011167",
    featureDesc: "Diameter of oscillating circuit 2",
    label: "EF011167: Diameter of oscillating circuit 2"
  },
  {
    featureId: "EF011168",
    featureDesc: "Rim height",
    label: "EF011168: Rim height"
  },
  {
    featureId: "EF011169",
    featureDesc: "Fold height",
    label: "EF011169: Fold height"
  },
  {
    featureId: "EF011171",
    featureDesc: "Stroke rate",
    label: "EF011171: Stroke rate"
  },
  {
    featureId: "EF011175",
    featureDesc: "Max. grinding stone diameter",
    label: "EF011175: Max. grinding stone diameter"
  },
  {
    featureId: "EF011176",
    featureDesc: "Min. plate thickness",
    label: "EF011176: Min. plate thickness"
  },
  {
    featureId: "EF011179",
    featureDesc: "Rim gap",
    label: "EF011179: Rim gap"
  },
  {
    featureId: "EF011180",
    featureDesc: "Max. rim height",
    label: "EF011180: Max. rim height"
  },
  {
    featureId: "EF011188",
    featureDesc: "Fine setting",
    label: "EF011188: Fine setting"
  },
  {
    featureId: "EF011192",
    featureDesc: "On-load speed 3rd gear",
    label: "EF011192: On-load speed 3rd gear"
  },
  {
    featureId: "EF011207",
    featureDesc: "Milling width",
    label: "EF011207: Milling width"
  },
  {
    featureId: "EF011209",
    featureDesc: "Rebate depth",
    label: "EF011209: Rebate depth"
  },
  {
    featureId: "EF011210",
    featureDesc: "Max. axial milling depth",
    label: "EF011210: Max. axial milling depth"
  },
  {
    featureId: "EF011211",
    featureDesc: "Max. radial milling depth",
    label: "EF011211: Max. radial milling depth"
  },
  {
    featureId: "EF011212",
    featureDesc: "Cutting circle diameter",
    label: "EF011212: Cutting circle diameter"
  },
  {
    featureId: "EF011213",
    featureDesc: "Lateral milling height",
    label: "EF011213: Lateral milling height"
  },
  {
    featureId: "EF011214",
    featureDesc: "Max. diameter of driver pad",
    label: "EF011214: Max. diameter of driver pad"
  },
  {
    featureId: "EF011216",
    featureDesc: "Wrap angle",
    label: "EF011216: Wrap angle"
  },
  {
    featureId: "EF011217",
    featureDesc: "Max. diameter of mixing garde",
    label: "EF011217: Max. diameter of mixing garde"
  },
  {
    featureId: "EF011219",
    featureDesc: "Milling depth",
    label: "EF011219: Milling depth"
  },
  {
    featureId: "EF011229",
    featureDesc: "Beveling angle continuously variable",
    label: "EF011229: Beveling angle continuously variable"
  },
  {
    featureId: "EF011230",
    featureDesc: "Beveling angle 45°",
    label: "EF011230: Beveling angle 45°"
  },
  {
    featureId: "EF011231",
    featureDesc: "Beveling angle 37.5°",
    label: "EF011231: Beveling angle 37.5°"
  },
  {
    featureId: "EF011232",
    featureDesc: "Beveling angle 30°",
    label: "EF011232: Beveling angle 30°"
  },
  {
    featureId: "EF011233",
    featureDesc: "Max. discharge efficiency",
    label: "EF011233: Max. discharge efficiency"
  },
  {
    featureId: "EF011237",
    featureDesc: "Milling path across",
    label: "EF011237: Milling path across"
  },
  {
    featureId: "EF011238",
    featureDesc: "Milling path along",
    label: "EF011238: Milling path along"
  },
  {
    featureId: "EF011249",
    featureDesc: "With flexible shaft",
    label: "EF011249: With flexible shaft"
  },
  {
    featureId: "EF011254",
    featureDesc: "With pipe-break safety function",
    label: "EF011254: With pipe-break safety function"
  },
  {
    featureId: "EF011264",
    featureDesc: "Front vise",
    label: "EF011264: Front vise"
  },
  {
    featureId: "EF011267",
    featureDesc: "Tail vise",
    label: "EF011267: Tail vise"
  },
  {
    featureId: "EF011271",
    featureDesc: "Thickness worktop",
    label: "EF011271: Thickness worktop"
  },
  {
    featureId: "EF011274",
    featureDesc: "Min. contact area",
    label: "EF011274: Min. contact area"
  },
  {
    featureId: "EF011276",
    featureDesc: "Bandwidth",
    label: "EF011276: Bandwidth"
  },
  {
    featureId: "EF011277",
    featureDesc: "Suitable for plastic band",
    label: "EF011277: Suitable for plastic band"
  },
  {
    featureId: "EF011279",
    featureDesc: "Container volume waste water",
    label: "EF011279: Container volume waste water"
  },
  {
    featureId: "EF011283",
    featureDesc: "Coupling type",
    label: "EF011283: Coupling type"
  },
  {
    featureId: "EF011290",
    featureDesc: "Cutting range",
    label: "EF011290: Cutting range"
  },
  {
    featureId: "EF011296",
    featureDesc: "Contains rip saw",
    label: "EF011296: Contains rip saw"
  },
  {
    featureId: "EF011297",
    featureDesc: "Contains Japanese saw",
    label: "EF011297: Contains Japanese saw"
  },
  {
    featureId: "EF011306",
    featureDesc: "Saw blade exchangeable",
    label: "EF011306: Saw blade exchangeable"
  },
  {
    featureId: "EF011307",
    featureDesc: "Saw blade",
    label: "EF011307: Saw blade"
  },
  {
    featureId: "EF011309",
    featureDesc: "With plastic handle",
    label: "EF011309: With plastic handle"
  },
  {
    featureId: "EF011311",
    featureDesc: "Shank diameter",
    label: "EF011311: Shank diameter"
  },
  {
    featureId: "EF011312",
    featureDesc: "With centre punch",
    label: "EF011312: With centre punch"
  },
  {
    featureId: "EF011314",
    featureDesc: "Blade material",
    label: "EF011314: Blade material"
  },
  {
    featureId: "EF011317",
    featureDesc: "Cap rotatable",
    label: "EF011317: Cap rotatable"
  },
  {
    featureId: "EF011318",
    featureDesc: "Outer hexagon socket (AF)",
    label: "EF011318: Outer hexagon socket (AF)"
  },
  {
    featureId: "EF011326",
    featureDesc: "Diameter of plug-in peg",
    label: "EF011326: Diameter of plug-in peg"
  },
  {
    featureId: "EF011327",
    featureDesc: "Number of brushes",
    label: "EF011327: Number of brushes"
  },
  {
    featureId: "EF011333",
    featureDesc: "Can be shortened",
    label: "EF011333: Can be shortened"
  },
  {
    featureId: "EF011336",
    featureDesc: "Suitable for assembly with street cover",
    label: "EF011336: Suitable for assembly with street cover"
  },
  {
    featureId: "EF011337",
    featureDesc: "Replaceable mandrel",
    label: "EF011337: Replaceable mandrel"
  },
  {
    featureId: "EF011339",
    featureDesc: "Length inserted",
    label: "EF011339: Length inserted"
  },
  {
    featureId: "EF011340",
    featureDesc: "Extended length",
    label: "EF011340: Extended length"
  },
  {
    featureId: "EF011344",
    featureDesc: "Scaling",
    label: "EF011344: Scaling"
  },
  {
    featureId: "EF011351",
    featureDesc: "Covering material version",
    label: "EF011351: Covering material version"
  },
  {
    featureId: "EF011359",
    featureDesc: "Cutting material",
    label: "EF011359: Cutting material"
  },
  {
    featureId: "EF011361",
    featureDesc: "Bull nose centre",
    label: "EF011361: Bull nose centre"
  },
  {
    featureId: "EF011362",
    featureDesc: "Max. battery duration",
    label: "EF011362: Max. battery duration"
  },
  {
    featureId: "EF011363",
    featureDesc: "Volume of dust catch container",
    label: "EF011363: Volume of dust catch container"
  },
  {
    featureId: "EF011364",
    featureDesc: "Head length",
    label: "EF011364: Head length"
  },
  {
    featureId: "EF011369",
    featureDesc: "Flank angle",
    label: "EF011369: Flank angle"
  },
  {
    featureId: "EF011372",
    featureDesc: "Basket diameter",
    label: "EF011372: Basket diameter"
  },
  {
    featureId: "EF011373",
    featureDesc: "Jigsaw blades",
    label: "EF011373: Jigsaw blades"
  },
  {
    featureId: "EF011374",
    featureDesc: "Circular saw blades",
    label: "EF011374: Circular saw blades"
  },
  {
    featureId: "EF011375",
    featureDesc: "Saw blades for foam saw",
    label: "EF011375: Saw blades for foam saw"
  },
  {
    featureId: "EF011376",
    featureDesc: "Saw blades for sabre saw",
    label: "EF011376: Saw blades for sabre saw"
  },
  {
    featureId: "EF011377",
    featureDesc: "Suitable for gas-powered saw",
    label: "EF011377: Suitable for gas-powered saw"
  },
  {
    featureId: "EF011378",
    featureDesc: "Suitable for electro-powered saw",
    label: "EF011378: Suitable for electro-powered saw"
  },
  {
    featureId: "EF011379",
    featureDesc: "Particle type",
    label: "EF011379: Particle type"
  },
  {
    featureId: "EF011385",
    featureDesc: "Countersink diameter",
    label: "EF011385: Countersink diameter"
  },
  {
    featureId: "EF011386",
    featureDesc: "Number of counterbores",
    label: "EF011386: Number of counterbores"
  },
  {
    featureId: "EF011388",
    featureDesc: "Number of guiding pins",
    label: "EF011388: Number of guiding pins"
  },
  {
    featureId: "EF011389",
    featureDesc: "Diameter guiding pin",
    label: "EF011389: Diameter guiding pin"
  },
  {
    featureId: "EF011390",
    featureDesc: "Colour mantle pipe",
    label: "EF011390: Colour mantle pipe"
  },
  {
    featureId: "EF011391",
    featureDesc: "Colour on band/stripe",
    label: "EF011391: Colour on band/stripe"
  },
  {
    featureId: "EF011396",
    featureDesc: "Max. operating pressure (psi)",
    label: "EF011396: Max. operating pressure (psi)"
  },
  {
    featureId: "EF011398",
    featureDesc: "Air inlet device-sided",
    label: "EF011398: Air inlet device-sided"
  },
  {
    featureId: "EF011401",
    featureDesc: "Transparent cover",
    label: "EF011401: Transparent cover"
  },
  {
    featureId: "EF011413",
    featureDesc: "Angle of inclination",
    label: "EF011413: Angle of inclination"
  },
  {
    featureId: "EF011414",
    featureDesc: "Max. loosening torque",
    label: "EF011414: Max. loosening torque"
  },
  {
    featureId: "EF011415",
    featureDesc: "Max. tightening torque",
    label: "EF011415: Max. tightening torque"
  },
  {
    featureId: "EF011416",
    featureDesc: "Attract torque adjustable",
    label: "EF011416: Attract torque adjustable"
  },
  {
    featureId: "EF011455",
    featureDesc: "Location perforation",
    label: "EF011455: Location perforation"
  },
  {
    featureId: "EF011456",
    featureDesc: "Inlet area per meter",
    label: "EF011456: Inlet area per meter"
  },
  {
    featureId: "EF011459",
    featureDesc: "Externally epoxy",
    label: "EF011459: Externally epoxy"
  },
  {
    featureId: "EF011460",
    featureDesc: "External asphalt layer",
    label: "EF011460: External asphalt layer"
  },
  {
    featureId: "EF011472",
    featureDesc: "With support tile",
    label: "EF011472: With support tile"
  },
  {
    featureId: "EF011475",
    featureDesc: "For plastic gully",
    label: "EF011475: For plastic gully"
  },
  {
    featureId: "EF011479",
    featureDesc: "For cable gully",
    label: "EF011479: For cable gully"
  },
  {
    featureId: "EF011480",
    featureDesc: "Cover with hinge",
    label: "EF011480: Cover with hinge"
  },
  {
    featureId: "EF011485",
    featureDesc: "Dimension free opening",
    label: "EF011485: Dimension free opening"
  },
  {
    featureId: "EF011486",
    featureDesc: "Number of free openings",
    label: "EF011486: Number of free openings"
  },
  {
    featureId: "EF011487",
    featureDesc: "With drainage device",
    label: "EF011487: With drainage device"
  },
  {
    featureId: "EF011489",
    featureDesc: "With integrated high pressure flush",
    label: "EF011489: With integrated high pressure flush"
  },
  {
    featureId: "EF011494",
    featureDesc: "Right-angled sawn",
    label: "EF011494: Right-angled sawn"
  },
  {
    featureId: "EF011495",
    featureDesc: "Miter angle sawn",
    label: "EF011495: Miter angle sawn"
  },
  {
    featureId: "EF011496",
    featureDesc: "With drill holes",
    label: "EF011496: With drill holes"
  },
  {
    featureId: "EF011500",
    featureDesc: "Separation class",
    label: "EF011500: Separation class"
  },
  {
    featureId: "EF011501",
    featureDesc: "Design capacity (number of persons)",
    label: "EF011501: Design capacity (number of persons)"
  },
  {
    featureId: "EF011502",
    featureDesc: "Volume class",
    label: "EF011502: Volume class"
  },
  {
    featureId: "EF011504",
    featureDesc: "Wet volume",
    label: "EF011504: Wet volume"
  },
  {
    featureId: "EF011505",
    featureDesc: "Suitable for heavy metals",
    label: "EF011505: Suitable for heavy metals"
  },
  {
    featureId: "EF011507",
    featureDesc: "With water connection",
    label: "EF011507: With water connection"
  },
  {
    featureId: "EF011508",
    featureDesc: "Precipitant",
    label: "EF011508: Precipitant"
  },
  {
    featureId: "EF011509",
    featureDesc: "Ventilation",
    label: "EF011509: Ventilation"
  },
  {
    featureId: "EF011510",
    featureDesc: "Number of ventilation outputs",
    label: "EF011510: Number of ventilation outputs"
  },
  {
    featureId: "EF011517",
    featureDesc: "With built-in economiser",
    label: "EF011517: With built-in economiser"
  },
  {
    featureId: "EF011518",
    featureDesc: "Max. time data storage",
    label: "EF011518: Max. time data storage"
  },
  {
    featureId: "EF011519",
    featureDesc: "Delta-T management",
    label: "EF011519: Delta-T management"
  },
  {
    featureId: "EF011520",
    featureDesc: "With direct RJ45 connection",
    label: "EF011520: With direct RJ45 connection"
  },
  {
    featureId: "EF011521",
    featureDesc: "Realtime monitoring",
    label: "EF011521: Realtime monitoring"
  },
  {
    featureId: "EF011522",
    featureDesc: "Power control",
    label: "EF011522: Power control"
  },
  {
    featureId: "EF011523",
    featureDesc: "Operation via hand terminal possible",
    label: "EF011523: Operation via hand terminal possible"
  },
  {
    featureId: "EF011527",
    featureDesc: "Reduction organic material (BOD7)",
    label: "EF011527: Reduction organic material (BOD7)"
  },
  {
    featureId: "EF011528",
    featureDesc: "Reduction phosphor (P)",
    label: "EF011528: Reduction phosphor (P)"
  },
  {
    featureId: "EF011529",
    featureDesc: "Reduction nitrogen (N)",
    label: "EF011529: Reduction nitrogen (N)"
  },
  {
    featureId: "EF011530",
    featureDesc: "Effective against intestinal enterococcus",
    label: "EF011530: Effective against intestinal enterococcus"
  },
  {
    featureId: "EF011531",
    featureDesc: "Effective against E-coli",
    label: "EF011531: Effective against E-coli"
  },
  {
    featureId: "EF011532",
    featureDesc: "Material filter",
    label: "EF011532: Material filter"
  },
  {
    featureId: "EF011533",
    featureDesc: "Volume sand trap",
    label: "EF011533: Volume sand trap"
  },
  {
    featureId: "EF011534",
    featureDesc: "Angle connection 1",
    label: "EF011534: Angle connection 1"
  },
  {
    featureId: "EF011535",
    featureDesc: "Angle connection 3",
    label: "EF011535: Angle connection 3"
  },
  {
    featureId: "EF011536",
    featureDesc: "Outlet angle",
    label: "EF011536: Outlet angle"
  },
  {
    featureId: "EF011537",
    featureDesc: "Difference between inlet and outlet invert level",
    label: "EF011537: Difference between inlet and outlet invert level"
  },
  {
    featureId: "EF011538",
    featureDesc: "Material sealing telescope pipe",
    label: "EF011538: Material sealing telescope pipe"
  },
  {
    featureId: "EF011539",
    featureDesc: "With movable plate",
    label: "EF011539: With movable plate"
  },
  {
    featureId: "EF011540",
    featureDesc: "Can be opened",
    label: "EF011540: Can be opened"
  },
  {
    featureId: "EF011542",
    featureDesc: "Thread size connection 2 (metric)",
    label: "EF011542: Thread size connection 2 (metric)"
  },
  {
    featureId: "EF011544",
    featureDesc: "Nominal duct width connection 3",
    label: "EF011544: Nominal duct width connection 3"
  },
  {
    featureId: "EF011545",
    featureDesc: "Nominal duct height connection 3",
    label: "EF011545: Nominal duct height connection 3"
  },
  {
    featureId: "EF011550",
    featureDesc: "Grading rules according to EN 1611-1",
    label: "EF011550: Grading rules according to EN 1611-1"
  },
  {
    featureId: "EF011561",
    featureDesc: "Suitable for sand",
    label: "EF011561: Suitable for sand"
  },
  {
    featureId: "EF011570",
    featureDesc: "Water pressure",
    label: "EF011570: Water pressure"
  },
  {
    featureId: "EF011577",
    featureDesc: "Reaction-to-fire according to EN 13501-6: Class",
    label: "EF011577: Reaction-to-fire according to EN 13501-6: Class"
  },
  {
    featureId: "EF011578",
    featureDesc: "Reaction-to-fire according to EN 13501-6: Smoke production",
    label: "EF011578: Reaction-to-fire according to EN 13501-6: Smoke production"
  },
  {
    featureId: "EF011579",
    featureDesc: "Reaction-to-fire according to EN 13501-6: Flaming droplets/particles",
    label: "EF011579: Reaction-to-fire according to EN 13501-6: Flaming droplets/particles"
  },
  {
    featureId: "EF011580",
    featureDesc: "Reaction-to-fire according to EN 13501-6: Acidity",
    label: "EF011580: Reaction-to-fire according to EN 13501-6: Acidity"
  },
  {
    featureId: "EF011581",
    featureDesc: "Preassembled",
    label: "EF011581: Preassembled"
  },
  {
    featureId: "EF011586",
    featureDesc: "Lamp power",
    label: "EF011586: Lamp power"
  },
  {
    featureId: "EF011587",
    featureDesc: "Luminous flux",
    label: "EF011587: Luminous flux"
  },
  {
    featureId: "EF011589",
    featureDesc: "Suitable for wall thickness",
    label: "EF011589: Suitable for wall thickness"
  },
  {
    featureId: "EF011590",
    featureDesc: "Material quality floor drain",
    label: "EF011590: Material quality floor drain"
  },
  {
    featureId: "EF011592",
    featureDesc: "Surface treatment grid",
    label: "EF011592: Surface treatment grid"
  },
  {
    featureId: "EF011594",
    featureDesc: "Removable water trap",
    label: "EF011594: Removable water trap"
  },
  {
    featureId: "EF011596",
    featureDesc: "Type of odour seal",
    label: "EF011596: Type of odour seal"
  },
  {
    featureId: "EF011597",
    featureDesc: "Closable",
    label: "EF011597: Closable"
  },
  {
    featureId: "EF011598",
    featureDesc: "With clamping ring for vinyl floor",
    label: "EF011598: With clamping ring for vinyl floor"
  },
  {
    featureId: "EF011601",
    featureDesc: "NWPC wood preservation class according to EN 351-1 and EN 335-1",
    label: "EF011601: NWPC wood preservation class according to EN 351-1 and EN 335-1"
  },
  {
    featureId: "EF011602",
    featureDesc: "With connection for drainage pipe",
    label: "EF011602: With connection for drainage pipe"
  },
  {
    featureId: "EF011603",
    featureDesc: "Prepared for pre-stamping for pipes",
    label: "EF011603: Prepared for pre-stamping for pipes"
  },
  {
    featureId: "EF011604",
    featureDesc: "Grid for coated floor",
    label: "EF011604: Grid for coated floor"
  },
  {
    featureId: "EF011606",
    featureDesc: "Grid for vinyl floor",
    label: "EF011606: Grid for vinyl floor"
  },
  {
    featureId: "EF011607",
    featureDesc: "Number of extra connections",
    label: "EF011607: Number of extra connections"
  },
  {
    featureId: "EF011609",
    featureDesc: "With strainer",
    label: "EF011609: With strainer"
  },
  {
    featureId: "EF011610",
    featureDesc: "Shape of siphon tube",
    label: "EF011610: Shape of siphon tube"
  },
  {
    featureId: "EF011611",
    featureDesc: "With drain funnel",
    label: "EF011611: With drain funnel"
  },
  {
    featureId: "EF011612",
    featureDesc: "PCM material",
    label: "EF011612: PCM material"
  },
  {
    featureId: "EF011613",
    featureDesc: "Melting temperature",
    label: "EF011613: Melting temperature"
  },
  {
    featureId: "EF011614",
    featureDesc: "Impacts",
    label: "EF011614: Impacts"
  },
  {
    featureId: "EF011615",
    featureDesc: "With base frame",
    label: "EF011615: With base frame"
  },
  {
    featureId: "EF011616",
    featureDesc: "Frequency bandwidth",
    label: "EF011616: Frequency bandwidth"
  },
  {
    featureId: "EF011617",
    featureDesc: "With wok burner",
    label: "EF011617: With wok burner"
  },
  {
    featureId: "EF011618",
    featureDesc: "Material pan support",
    label: "EF011618: Material pan support"
  },
  {
    featureId: "EF011621",
    featureDesc: "Basic shape",
    label: "EF011621: Basic shape"
  },
  {
    featureId: "EF011622",
    featureDesc: "Number of beakers",
    label: "EF011622: Number of beakers"
  },
  {
    featureId: "EF011623",
    featureDesc: "Colour beaker",
    label: "EF011623: Colour beaker"
  },
  {
    featureId: "EF011625",
    featureDesc: "Surface protection control unit",
    label: "EF011625: Surface protection control unit"
  },
  {
    featureId: "EF011626",
    featureDesc: "Type of soap",
    label: "EF011626: Type of soap"
  },
  {
    featureId: "EF011628",
    featureDesc: "Suicide resistant",
    label: "EF011628: Suicide resistant"
  },
  {
    featureId: "EF011629",
    featureDesc: "With door stopper function",
    label: "EF011629: With door stopper function"
  },
  {
    featureId: "EF011630",
    featureDesc: "Flow direction",
    label: "EF011630: Flow direction"
  },
  {
    featureId: "EF011632",
    featureDesc: "With offset",
    label: "EF011632: With offset"
  },
  {
    featureId: "EF011633",
    featureDesc: "DAB+",
    label: "EF011633: DAB+"
  },
  {
    featureId: "EF011634",
    featureDesc: "Visible diagonal screen size (inch)",
    label: "EF011634: Visible diagonal screen size (inch)"
  },
  {
    featureId: "EF011635",
    featureDesc: "Power consumption in operation",
    label: "EF011635: Power consumption in operation"
  },
  {
    featureId: "EF011637",
    featureDesc: "Smart TV",
    label: "EF011637: Smart TV"
  },
  {
    featureId: "EF011638",
    featureDesc: "DVB-T2 tuner (Codec H.264)",
    label: "EF011638: DVB-T2 tuner (Codec H.264)"
  },
  {
    featureId: "EF011639",
    featureDesc: "DVB-C2-tuner",
    label: "EF011639: DVB-C2-tuner"
  },
  {
    featureId: "EF011640",
    featureDesc: "DVB-S2-tuner",
    label: "EF011640: DVB-S2-tuner"
  },
  {
    featureId: "EF011644",
    featureDesc: "Width (effective dimension)",
    label: "EF011644: Width (effective dimension)"
  },
  {
    featureId: "EF011645",
    featureDesc: "Height (effective dimension)",
    label: "EF011645: Height (effective dimension)"
  },
  {
    featureId: "EF011646",
    featureDesc: "Height external dimension",
    label: "EF011646: Height external dimension"
  },
  {
    featureId: "EF011649",
    featureDesc: "Number of tap holes for each washbasin",
    label: "EF011649: Number of tap holes for each washbasin"
  },
  {
    featureId: "EF011650",
    featureDesc: "With control knob hole",
    label: "EF011650: With control knob hole"
  },
  {
    featureId: "EF011652",
    featureDesc: "Material impeller/pump wheel",
    label: "EF011652: Material impeller/pump wheel"
  },
  {
    featureId: "EF011656",
    featureDesc: "With three-ring cooking zone",
    label: "EF011656: With three-ring cooking zone"
  },
  {
    featureId: "EF011657",
    featureDesc: "Disconnecting method",
    label: "EF011657: Disconnecting method"
  },
  {
    featureId: "EF011658",
    featureDesc: "Type of disconnector",
    label: "EF011658: Type of disconnector"
  },
  {
    featureId: "EF011662",
    featureDesc: "Type of seat",
    label: "EF011662: Type of seat"
  },
  {
    featureId: "EF011664",
    featureDesc: "With quick release seat",
    label: "EF011664: With quick release seat"
  },
  {
    featureId: "EF011665",
    featureDesc: "With scent dispenser",
    label: "EF011665: With scent dispenser"
  },
  {
    featureId: "EF011666",
    featureDesc: "Centre distance bolt holes",
    label: "EF011666: Centre distance bolt holes"
  },
  {
    featureId: "EF011668",
    featureDesc: "With wider concealing foot",
    label: "EF011668: With wider concealing foot"
  },
  {
    featureId: "EF011670",
    featureDesc: "Suitable for shower tray mounting",
    label: "EF011670: Suitable for shower tray mounting"
  },
  {
    featureId: "EF011671",
    featureDesc: "Suitable for tiled floor mounting",
    label: "EF011671: Suitable for tiled floor mounting"
  },
  {
    featureId: "EF011672",
    featureDesc: "Swing door",
    label: "EF011672: Swing door"
  },
  {
    featureId: "EF011673",
    featureDesc: "Suitable for mounting with door",
    label: "EF011673: Suitable for mounting with door"
  },
  {
    featureId: "EF011674",
    featureDesc: "Suitable for mounting in line",
    label: "EF011674: Suitable for mounting in line"
  },
  {
    featureId: "EF011675",
    featureDesc: "With instantaneous water heater",
    label: "EF011675: With instantaneous water heater"
  },
  {
    featureId: "EF011676",
    featureDesc: "With drain pump",
    label: "EF011676: With drain pump"
  },
  {
    featureId: "EF011678",
    featureDesc: "Min. dry lightning impulse withstand voltage",
    label: "EF011678: Min. dry lightning impulse withstand voltage"
  },
  {
    featureId: "EF011679",
    featureDesc: "Wet power frequency withstand voltage",
    label: "EF011679: Wet power frequency withstand voltage"
  },
  {
    featureId: "EF011680",
    featureDesc: "Suitable for corner entrance",
    label: "EF011680: Suitable for corner entrance"
  },
  {
    featureId: "EF011681",
    featureDesc: "Suitable for mounting with side wall",
    label: "EF011681: Suitable for mounting with side wall"
  },
  {
    featureId: "EF011682",
    featureDesc: "Suitable for niche mounting",
    label: "EF011682: Suitable for niche mounting"
  },
  {
    featureId: "EF011691",
    featureDesc: "With insulator pin",
    label: "EF011691: With insulator pin"
  },
  {
    featureId: "EF011695",
    featureDesc: "Degree of protection (IP) busbar",
    label: "EF011695: Degree of protection (IP) busbar"
  },
  {
    featureId: "EF011696",
    featureDesc: "Equipped with connectors",
    label: "EF011696: Equipped with connectors"
  },
  {
    featureId: "EF011698",
    featureDesc: "With fire sleeve",
    label: "EF011698: With fire sleeve"
  },
  {
    featureId: "EF011700",
    featureDesc: "Rated voltage U0/U (Um)",
    label: "EF011700: Rated voltage U0/U (Um)"
  },
  {
    featureId: "EF011704",
    featureDesc: "Painting method",
    label: "EF011704: Painting method"
  },
  {
    featureId: "EF011705",
    featureDesc: "Tap holes",
    label: "EF011705: Tap holes"
  },
  {
    featureId: "EF011706",
    featureDesc: "Suitable for application of waterproof membrane under tile floor",
    label: "EF011706: Suitable for application of waterproof membrane under tile floor"
  },
  {
    featureId: "EF011707",
    featureDesc: "Suitable for concrete floor",
    label: "EF011707: Suitable for concrete floor"
  },
  {
    featureId: "EF011708",
    featureDesc: "Suitable for coated floor",
    label: "EF011708: Suitable for coated floor"
  },
  {
    featureId: "EF011709",
    featureDesc: "Suitable for vinyl floor",
    label: "EF011709: Suitable for vinyl floor"
  },
  {
    featureId: "EF011710",
    featureDesc: "Mechanical coupler, connection 1 suitable for",
    label: "EF011710: Mechanical coupler, connection 1 suitable for"
  },
  {
    featureId: "EF011711",
    featureDesc: "Mechanical coupler, connection 2 suitable for",
    label: "EF011711: Mechanical coupler, connection 2 suitable for"
  },
  {
    featureId: "EF011712",
    featureDesc: "With seat heating",
    label: "EF011712: With seat heating"
  },
  {
    featureId: "EF011714",
    featureDesc: "With flushing water connection",
    label: "EF011714: With flushing water connection"
  },
  {
    featureId: "EF011715",
    featureDesc: "Raised seat",
    label: "EF011715: Raised seat"
  },
  {
    featureId: "EF011717",
    featureDesc: "Inlet location",
    label: "EF011717: Inlet location"
  },
  {
    featureId: "EF011718",
    featureDesc: "With holding for urinal rim block",
    label: "EF011718: With holding for urinal rim block"
  },
  {
    featureId: "EF011719",
    featureDesc: "With tap ledge",
    label: "EF011719: With tap ledge"
  },
  {
    featureId: "EF011720",
    featureDesc: "With microphone",
    label: "EF011720: With microphone"
  },
  {
    featureId: "EF011721",
    featureDesc: "Microphone length",
    label: "EF011721: Microphone length"
  },
  {
    featureId: "EF011723",
    featureDesc: "With concealed siphon",
    label: "EF011723: With concealed siphon"
  },
  {
    featureId: "EF011724",
    featureDesc: "Centre distance of wall-hung fastener",
    label: "EF011724: Centre distance of wall-hung fastener"
  },
  {
    featureId: "EF011726",
    featureDesc: "Suitable for console length",
    label: "EF011726: Suitable for console length"
  },
  {
    featureId: "EF011727",
    featureDesc: "Type of time setting",
    label: "EF011727: Type of time setting"
  },
  {
    featureId: "EF011729",
    featureDesc: "Count up/down timer",
    label: "EF011729: Count up/down timer"
  },
  {
    featureId: "EF011734",
    featureDesc: "Auto orientation",
    label: "EF011734: Auto orientation"
  },
  {
    featureId: "EF011735",
    featureDesc: "Thermal image",
    label: "EF011735: Thermal image"
  },
  {
    featureId: "EF011736",
    featureDesc: "Visual image",
    label: "EF011736: Visual image"
  },
  {
    featureId: "EF011738",
    featureDesc: "Water-bearing front panel",
    label: "EF011738: Water-bearing front panel"
  },
  {
    featureId: "EF011739",
    featureDesc: "Thermal sensitivity at 30 °C",
    label: "EF011739: Thermal sensitivity at 30 °C"
  },
  {
    featureId: "EF011740",
    featureDesc: "Video recording",
    label: "EF011740: Video recording"
  },
  {
    featureId: "EF011741",
    featureDesc: "Suitable for various applications",
    label: "EF011741: Suitable for various applications"
  },
  {
    featureId: "EF011742",
    featureDesc: "Suitable for UPS",
    label: "EF011742: Suitable for UPS"
  },
  {
    featureId: "EF011744",
    featureDesc: "Suitable for battery charger unit",
    label: "EF011744: Suitable for battery charger unit"
  },
  {
    featureId: "EF011747",
    featureDesc: "Front connection",
    label: "EF011747: Front connection"
  },
  {
    featureId: "EF011748",
    featureDesc: "Lifespan at 20 °C",
    label: "EF011748: Lifespan at 20 °C"
  },
  {
    featureId: "EF011749",
    featureDesc: "Eurobat certified lifespan",
    label: "EF011749: Eurobat certified lifespan"
  },
  {
    featureId: "EF011750",
    featureDesc: "Number of cycles to a depth of discharge of 100 %",
    label: "EF011750: Number of cycles to a depth of discharge of 100 %"
  },
  {
    featureId: "EF011751",
    featureDesc: "Outer diameter thermal insulation",
    label: "EF011751: Outer diameter thermal insulation"
  },
  {
    featureId: "EF011752",
    featureDesc: "Lambda value",
    label: "EF011752: Lambda value"
  },
  {
    featureId: "EF011754",
    featureDesc: "Type of cable termination",
    label: "EF011754: Type of cable termination"
  },
  {
    featureId: "EF011755",
    featureDesc: "With cable lug",
    label: "EF011755: With cable lug"
  },
  {
    featureId: "EF011756",
    featureDesc: "Nominal current according to EN 50180/EN 50181",
    label: "EF011756: Nominal current according to EN 50180/EN 50181"
  },
  {
    featureId: "EF011757",
    featureDesc: "Type of plug termination",
    label: "EF011757: Type of plug termination"
  },
  {
    featureId: "EF011759",
    featureDesc: "With plug-type cable connections",
    label: "EF011759: With plug-type cable connections"
  },
  {
    featureId: "EF011760",
    featureDesc: "According to Ecodesign Regulation (EU) No 548/2014",
    label: "EF011760: According to Ecodesign Regulation (EU) No 548/2014"
  },
  {
    featureId: "EF011762",
    featureDesc: "No-load loss",
    label: "EF011762: No-load loss"
  },
  {
    featureId: "EF011764",
    featureDesc: "Number of lifting lugs",
    label: "EF011764: Number of lifting lugs"
  },
  {
    featureId: "EF011765",
    featureDesc: "Number of earthing terminals",
    label: "EF011765: Number of earthing terminals"
  },
  {
    featureId: "EF011766",
    featureDesc: "Number of oil filling openings",
    label: "EF011766: Number of oil filling openings"
  },
  {
    featureId: "EF011767",
    featureDesc: "Number of oil draining openings",
    label: "EF011767: Number of oil draining openings"
  },
  {
    featureId: "EF011769",
    featureDesc: "Suitable for rebar diameter",
    label: "EF011769: Suitable for rebar diameter"
  },
  {
    featureId: "EF011770",
    featureDesc: "Weldable",
    label: "EF011770: Weldable"
  },
  {
    featureId: "EF011771",
    featureDesc: "Fillable with grout",
    label: "EF011771: Fillable with grout"
  },
  {
    featureId: "EF011772",
    featureDesc: "Measurement RF signal",
    label: "EF011772: Measurement RF signal"
  },
  {
    featureId: "EF011773",
    featureDesc: "Measurement radioactive radiation",
    label: "EF011773: Measurement radioactive radiation"
  },
  {
    featureId: "EF011774",
    featureDesc: "Max. video recording quality",
    label: "EF011774: Max. video recording quality"
  },
  {
    featureId: "EF011775",
    featureDesc: "Backflow safeguard according to EN 1717",
    label: "EF011775: Backflow safeguard according to EN 1717"
  },
  {
    featureId: "EF011777",
    featureDesc: "Rated permanent current at AC-23, 400 V",
    label: "EF011777: Rated permanent current at AC-23, 400 V"
  },
  {
    featureId: "EF011781",
    featureDesc: "Type of stop valve for external equipment",
    label: "EF011781: Type of stop valve for external equipment"
  },
  {
    featureId: "EF011783",
    featureDesc: "With mixing function for cold and warm water",
    label: "EF011783: With mixing function for cold and warm water"
  },
  {
    featureId: "EF011785",
    featureDesc: "With bracket suitable for high wall mounting",
    label: "EF011785: With bracket suitable for high wall mounting"
  },
  {
    featureId: "EF011786",
    featureDesc: "With bracket suitable for low wall mounting",
    label: "EF011786: With bracket suitable for low wall mounting"
  },
  {
    featureId: "EF011787",
    featureDesc: "With bracket suitable for floor mounting",
    label: "EF011787: With bracket suitable for floor mounting"
  },
  {
    featureId: "EF011789",
    featureDesc: "AWG size",
    label: "EF011789: AWG size"
  },
  {
    featureId: "EF011790",
    featureDesc: "Nominal cross section conductor (AWG)",
    label: "EF011790: Nominal cross section conductor (AWG)"
  },
  {
    featureId: "EF011792",
    featureDesc: "Nominal cross section pilot/reduced conductors (AWG)",
    label: "EF011792: Nominal cross section pilot/reduced conductors (AWG)"
  },
  {
    featureId: "EF011793",
    featureDesc: "Suitable for U mounting",
    label: "EF011793: Suitable for U mounting"
  },
  {
    featureId: "EF011794",
    featureDesc: "Height carpeting",
    label: "EF011794: Height carpeting"
  },
  {
    featureId: "EF011795",
    featureDesc: "Material motor housing",
    label: "EF011795: Material motor housing"
  },
  {
    featureId: "EF011796",
    featureDesc: "Material quality motor housing",
    label: "EF011796: Material quality motor housing"
  },
  {
    featureId: "EF011800",
    featureDesc: "Operation method for shower",
    label: "EF011800: Operation method for shower"
  },
  {
    featureId: "EF011801",
    featureDesc: "Min. permitted bending radius, moving application with forced guidance",
    label: "EF011801: Min. permitted bending radius, moving application with forced guidance"
  },
  {
    featureId: "EF011802",
    featureDesc: "Min. permitted bending radius, moving application/free movement",
    label: "EF011802: Min. permitted bending radius, moving application/free movement"
  },
  {
    featureId: "EF011803",
    featureDesc: "Min. permitted bending radius, stationary application/permanent installation",
    label: "EF011803: Min. permitted bending radius, stationary application/permanent installation"
  },
  {
    featureId: "EF011804",
    featureDesc: "Number of screen layers",
    label: "EF011804: Number of screen layers"
  },
  {
    featureId: "EF011805",
    featureDesc: "Specification core insulation",
    label: "EF011805: Specification core insulation"
  },
  {
    featureId: "EF011806",
    featureDesc: "Specification material outer sheath",
    label: "EF011806: Specification material outer sheath"
  },
  {
    featureId: "EF011807",
    featureDesc: "Dimension extra outlet",
    label: "EF011807: Dimension extra outlet"
  },
  {
    featureId: "EF011808",
    featureDesc: "Surface treatment on the inside",
    label: "EF011808: Surface treatment on the inside"
  },
  {
    featureId: "EF011809",
    featureDesc: "Number of branching directions",
    label: "EF011809: Number of branching directions"
  },
  {
    featureId: "EF011810",
    featureDesc: "Deviant distance between branches",
    label: "EF011810: Deviant distance between branches"
  },
  {
    featureId: "EF011811",
    featureDesc: "Material quality spindle",
    label: "EF011811: Material quality spindle"
  },
  {
    featureId: "EF011812",
    featureDesc: "Door leaf width",
    label: "EF011812: Door leaf width"
  },
  {
    featureId: "EF011813",
    featureDesc: "Door leaf height",
    label: "EF011813: Door leaf height"
  },
  {
    featureId: "EF011814",
    featureDesc: "Door leaf thickness",
    label: "EF011814: Door leaf thickness"
  },
  {
    featureId: "EF011815",
    featureDesc: "Doorframe module width",
    label: "EF011815: Doorframe module width"
  },
  {
    featureId: "EF011816",
    featureDesc: "Doorframe module height",
    label: "EF011816: Doorframe module height"
  },
  {
    featureId: "EF011817",
    featureDesc: "Doorframe width",
    label: "EF011817: Doorframe width"
  },
  {
    featureId: "EF011818",
    featureDesc: "Doorframe height",
    label: "EF011818: Doorframe height"
  },
  {
    featureId: "EF011819",
    featureDesc: "Doorframe depth",
    label: "EF011819: Doorframe depth"
  },
  {
    featureId: "EF011822",
    featureDesc: "Door with glass",
    label: "EF011822: Door with glass"
  },
  {
    featureId: "EF011824",
    featureDesc: "Opening direction",
    label: "EF011824: Opening direction"
  },
  {
    featureId: "EF011828",
    featureDesc: "Door leaf material",
    label: "EF011828: Door leaf material"
  },
  {
    featureId: "EF011829",
    featureDesc: "Door leaf structure",
    label: "EF011829: Door leaf structure"
  },
  {
    featureId: "EF011831",
    featureDesc: "Design of door",
    label: "EF011831: Design of door"
  },
  {
    featureId: "EF011836",
    featureDesc: "Approved for handicapped",
    label: "EF011836: Approved for handicapped"
  },
  {
    featureId: "EF011839",
    featureDesc: "Window module width",
    label: "EF011839: Window module width"
  },
  {
    featureId: "EF011840",
    featureDesc: "Window module height",
    label: "EF011840: Window module height"
  },
  {
    featureId: "EF011842",
    featureDesc: "Window frame depth",
    label: "EF011842: Window frame depth"
  },
  {
    featureId: "EF011843",
    featureDesc: "Window width",
    label: "EF011843: Window width"
  },
  {
    featureId: "EF011845",
    featureDesc: "Window height",
    label: "EF011845: Window height"
  },
  {
    featureId: "EF011847",
    featureDesc: "Opening function",
    label: "EF011847: Opening function"
  },
  {
    featureId: "EF011848",
    featureDesc: "Opening field",
    label: "EF011848: Opening field"
  },
  {
    featureId: "EF011849",
    featureDesc: "Emergency exit",
    label: "EF011849: Emergency exit"
  },
  {
    featureId: "EF011853",
    featureDesc: "Mullions",
    label: "EF011853: Mullions"
  },
  {
    featureId: "EF011854",
    featureDesc: "Aluminium surface",
    label: "EF011854: Aluminium surface"
  },
  {
    featureId: "EF011855",
    featureDesc: "Material quality lock pipe",
    label: "EF011855: Material quality lock pipe"
  },
  {
    featureId: "EF011856",
    featureDesc: "Material quality pit",
    label: "EF011856: Material quality pit"
  },
  {
    featureId: "EF011857",
    featureDesc: "Carpet cleaning performance class",
    label: "EF011857: Carpet cleaning performance class"
  },
  {
    featureId: "EF011858",
    featureDesc: "Hard floor cleaning performance class",
    label: "EF011858: Hard floor cleaning performance class"
  },
  {
    featureId: "EF011859",
    featureDesc: "Dust re-emission class",
    label: "EF011859: Dust re-emission class"
  },
  {
    featureId: "EF011860",
    featureDesc: "Average annual energy consumption",
    label: "EF011860: Average annual energy consumption"
  },
  {
    featureId: "EF011864",
    featureDesc: "Condensation efficiency class (392/2012/EU)",
    label: "EF011864: Condensation efficiency class (392/2012/EU)"
  },
  {
    featureId: "EF011865",
    featureDesc: "Fluid dynamic efficiency class",
    label: "EF011865: Fluid dynamic efficiency class"
  },
  {
    featureId: "EF011866",
    featureDesc: "Lighting efficiency class",
    label: "EF011866: Lighting efficiency class"
  },
  {
    featureId: "EF011867",
    featureDesc: "Grease filtering efficiency class",
    label: "EF011867: Grease filtering efficiency class"
  },
  {
    featureId: "EF011868",
    featureDesc: "Type of wiring",
    label: "EF011868: Type of wiring"
  },
  {
    featureId: "EF011870",
    featureDesc: "Rated life time L70/B50 at 25 °C",
    label: "EF011870: Rated life time L70/B50 at 25 °C"
  },
  {
    featureId: "EF011871",
    featureDesc: "Rated life time L80/B10 at 25 °C",
    label: "EF011871: Rated life time L80/B10 at 25 °C"
  },
  {
    featureId: "EF011876",
    featureDesc: "With sling",
    label: "EF011876: With sling"
  },
  {
    featureId: "EF011877",
    featureDesc: "Suitable for bath/shower sewage",
    label: "EF011877: Suitable for bath/shower sewage"
  },
  {
    featureId: "EF011878",
    featureDesc: "Suitable for kitchen sewage",
    label: "EF011878: Suitable for kitchen sewage"
  },
  {
    featureId: "EF011879",
    featureDesc: "Suitable for wash sewage",
    label: "EF011879: Suitable for wash sewage"
  },
  {
    featureId: "EF011880",
    featureDesc: "Suitable for water toilet sewage",
    label: "EF011880: Suitable for water toilet sewage"
  },
  {
    featureId: "EF011881",
    featureDesc: "Laserclass",
    label: "EF011881: Laserclass"
  },
  {
    featureId: "EF011882",
    featureDesc: "With pressure tank",
    label: "EF011882: With pressure tank"
  },
  {
    featureId: "EF011883",
    featureDesc: "Volume pressure tank",
    label: "EF011883: Volume pressure tank"
  },
  {
    featureId: "EF011884",
    featureDesc: "Suitable for indoor installation",
    label: "EF011884: Suitable for indoor installation"
  },
  {
    featureId: "EF011885",
    featureDesc: "Suitable for outdoor installation",
    label: "EF011885: Suitable for outdoor installation"
  },
  {
    featureId: "EF011886",
    featureDesc: "With heating cable",
    label: "EF011886: With heating cable"
  },
  {
    featureId: "EF011889",
    featureDesc: "Max. medium temperature (momentarily)",
    label: "EF011889: Max. medium temperature (momentarily)"
  },
  {
    featureId: "EF011890",
    featureDesc: "With platform",
    label: "EF011890: With platform"
  },
  {
    featureId: "EF011892",
    featureDesc: "With door frame",
    label: "EF011892: With door frame"
  },
  {
    featureId: "EF011894",
    featureDesc: "RAL-number frame",
    label: "EF011894: RAL-number frame"
  },
  {
    featureId: "EF011895",
    featureDesc: "Colour door",
    label: "EF011895: Colour door"
  },
  {
    featureId: "EF011896",
    featureDesc: "RAL-number door",
    label: "EF011896: RAL-number door"
  },
  {
    featureId: "EF011897",
    featureDesc: "Surface protection door",
    label: "EF011897: Surface protection door"
  },
  {
    featureId: "EF011898",
    featureDesc: "With waterproof bottom",
    label: "EF011898: With waterproof bottom"
  },
  {
    featureId: "EF011899",
    featureDesc: "Suitable for application of waterproof membrane",
    label: "EF011899: Suitable for application of waterproof membrane"
  },
  {
    featureId: "EF011901",
    featureDesc: "Number of holes on top",
    label: "EF011901: Number of holes on top"
  },
  {
    featureId: "EF011902",
    featureDesc: "With open holes on top",
    label: "EF011902: With open holes on top"
  },
  {
    featureId: "EF011903",
    featureDesc: "With open holes on sides",
    label: "EF011903: With open holes on sides"
  },
  {
    featureId: "EF011904",
    featureDesc: "Number of holes in bottom",
    label: "EF011904: Number of holes in bottom"
  },
  {
    featureId: "EF011905",
    featureDesc: "With holes in bottom",
    label: "EF011905: With holes in bottom"
  },
  {
    featureId: "EF011906",
    featureDesc: "With pipe feed-through sealing",
    label: "EF011906: With pipe feed-through sealing"
  },
  {
    featureId: "EF011907",
    featureDesc: "With brackets for distributor",
    label: "EF011907: With brackets for distributor"
  },
  {
    featureId: "EF011908",
    featureDesc: "With outlet for leakage indication",
    label: "EF011908: With outlet for leakage indication"
  },
  {
    featureId: "EF011909",
    featureDesc: "Suitable as floor bend",
    label: "EF011909: Suitable as floor bend"
  },
  {
    featureId: "EF011910",
    featureDesc: "Suitable as wall bend fixture",
    label: "EF011910: Suitable as wall bend fixture"
  },
  {
    featureId: "EF011911",
    featureDesc: "With built-in distributor",
    label: "EF011911: With built-in distributor"
  },
  {
    featureId: "EF011914",
    featureDesc: "Suitable for EPS",
    label: "EF011914: Suitable for EPS"
  },
  {
    featureId: "EF011916",
    featureDesc: "Material quality union nut",
    label: "EF011916: Material quality union nut"
  },
  {
    featureId: "EF011918",
    featureDesc: "Wave band",
    label: "EF011918: Wave band"
  },
  {
    featureId: "EF011919",
    featureDesc: "Continuous heating power at 10 °C",
    label: "EF011919: Continuous heating power at 10 °C"
  },
  {
    featureId: "EF011920",
    featureDesc: "Total cooling capacity according to EN 14511 at 35/35 °C",
    label: "EF011920: Total cooling capacity according to EN 14511 at 35/35 °C"
  },
  {
    featureId: "EF011921",
    featureDesc: "Speed-regulated",
    label: "EF011921: Speed-regulated"
  },
  {
    featureId: "EF011922",
    featureDesc: "Active current limitation x IN",
    label: "EF011922: Active current limitation x IN"
  },
  {
    featureId: "EF011923",
    featureDesc: "Fuse type",
    label: "EF011923: Fuse type"
  },
  {
    featureId: "EF011924",
    featureDesc: "Fail safe element",
    label: "EF011924: Fail safe element"
  },
  {
    featureId: "EF011925",
    featureDesc: "Switch-off time",
    label: "EF011925: Switch-off time"
  },
  {
    featureId: "EF011926",
    featureDesc: "Max. capacitive load",
    label: "EF011926: Max. capacitive load"
  },
  {
    featureId: "EF011927",
    featureDesc: "Regulation type on/off",
    label: "EF011927: Regulation type on/off"
  },
  {
    featureId: "EF011928",
    featureDesc: "Regulation type modulating",
    label: "EF011928: Regulation type modulating"
  },
  {
    featureId: "EF011929",
    featureDesc: "Regulation type splitrange",
    label: "EF011929: Regulation type splitrange"
  },
  {
    featureId: "EF011931",
    featureDesc: "Connected load heating element",
    label: "EF011931: Connected load heating element"
  },
  {
    featureId: "EF011932",
    featureDesc: "Connected load ventilator",
    label: "EF011932: Connected load ventilator"
  },
  {
    featureId: "EF011933",
    featureDesc: "Built-in width",
    label: "EF011933: Built-in width"
  },
  {
    featureId: "EF011934",
    featureDesc: "Front door locking system",
    label: "EF011934: Front door locking system"
  },
  {
    featureId: "EF011935",
    featureDesc: "Rear door locking system",
    label: "EF011935: Rear door locking system"
  },
  {
    featureId: "EF011936",
    featureDesc: "With vertical cable management",
    label: "EF011936: With vertical cable management"
  },
  {
    featureId: "EF011937",
    featureDesc: "Material front door",
    label: "EF011937: Material front door"
  },
  {
    featureId: "EF011938",
    featureDesc: "Material rear door",
    label: "EF011938: Material rear door"
  },
  {
    featureId: "EF011940",
    featureDesc: "Reset function input",
    label: "EF011940: Reset function input"
  },
  {
    featureId: "EF011941",
    featureDesc: "Reset function automatic",
    label: "EF011941: Reset function automatic"
  },
  {
    featureId: "EF011942",
    featureDesc: "Reset function push-button",
    label: "EF011942: Reset function push-button"
  },
  {
    featureId: "EF011943",
    featureDesc: "Release indication",
    label: "EF011943: Release indication"
  },
  {
    featureId: "EF011944",
    featureDesc: "Supporting protocol for BACnet",
    label: "EF011944: Supporting protocol for BACnet"
  },
  {
    featureId: "EF011945",
    featureDesc: "With tension indication",
    label: "EF011945: With tension indication"
  },
  {
    featureId: "EF011946",
    featureDesc: "Colour consistency (McAdam ellipse)",
    label: "EF011946: Colour consistency (McAdam ellipse)"
  },
  {
    featureId: "EF011947",
    featureDesc: "Total harmonic distortion",
    label: "EF011947: Total harmonic distortion"
  },
  {
    featureId: "EF011948",
    featureDesc: "Photobiological safety according to EN 62471",
    label: "EF011948: Photobiological safety according to EN 62471"
  },
  {
    featureId: "EF011951",
    featureDesc: "With mounting holes on the side",
    label: "EF011951: With mounting holes on the side"
  },
  {
    featureId: "EF011954",
    featureDesc: "Luminous flux in standby mode",
    label: "EF011954: Luminous flux in standby mode"
  },
  {
    featureId: "EF011955",
    featureDesc: "Resistance measuring range",
    label: "EF011955: Resistance measuring range"
  },
  {
    featureId: "EF011956",
    featureDesc: "Switching at zero-crossing",
    label: "EF011956: Switching at zero-crossing"
  },
  {
    featureId: "EF011957",
    featureDesc: "Processor clock rate",
    label: "EF011957: Processor clock rate"
  },
  {
    featureId: "EF011958",
    featureDesc: "Number of storage media bays",
    label: "EF011958: Number of storage media bays"
  },
  {
    featureId: "EF011959",
    featureDesc: "Degree of protection (NEMA)",
    label: "EF011959: Degree of protection (NEMA)"
  },
  {
    featureId: "EF011960",
    featureDesc: "Rated operation power NEMA",
    label: "EF011960: Rated operation power NEMA"
  },
  {
    featureId: "EF011961",
    featureDesc: "Degree of protection (NEMA), front side",
    label: "EF011961: Degree of protection (NEMA), front side"
  },
  {
    featureId: "EF011962",
    featureDesc: "Duration unit",
    label: "EF011962: Duration unit"
  },
  {
    featureId: "EF011963",
    featureDesc: "With stud",
    label: "EF011963: With stud"
  },
  {
    featureId: "EF011964",
    featureDesc: "With auxiliary release",
    label: "EF011964: With auxiliary release"
  },
  {
    featureId: "EF011965",
    featureDesc: "Number of NEMA socket outlets",
    label: "EF011965: Number of NEMA socket outlets"
  },
  {
    featureId: "EF011973",
    featureDesc: "Clearance from back plate",
    label: "EF011973: Clearance from back plate"
  },
  {
    featureId: "EF011974",
    featureDesc: "Number of CEE socket outlets (IEC 60309) 125A / 400 V / 5P (1, 2, 3, N, PE)",
    label: "EF011974: Number of CEE socket outlets (IEC 60309) 125A / 400 V / 5P (1, 2, 3, N, PE)"
  },
  {
    featureId: "EF011975",
    featureDesc: "Number of socket outlets Swiss standard type 13",
    label: "EF011975: Number of socket outlets Swiss standard type 13"
  },
  {
    featureId: "EF011977",
    featureDesc: "Number of socket outlets Swiss standard type 15",
    label: "EF011977: Number of socket outlets Swiss standard type 15"
  },
  {
    featureId: "EF011978",
    featureDesc: "Number of socket outlets Swiss standard type 23",
    label: "EF011978: Number of socket outlets Swiss standard type 23"
  },
  {
    featureId: "EF011979",
    featureDesc: "Number of socket outlets Swiss standard type 25",
    label: "EF011979: Number of socket outlets Swiss standard type 25"
  },
  {
    featureId: "EF011980",
    featureDesc: "Rated impulse withstand voltage Uimp",
    label: "EF011980: Rated impulse withstand voltage Uimp"
  },
  {
    featureId: "EF011981",
    featureDesc: "Rated short-circuit breaking capacity Icn according to EN 61009-1",
    label: "EF011981: Rated short-circuit breaking capacity Icn according to EN 61009-1"
  },
  {
    featureId: "EF011991",
    featureDesc: "Forced switch on",
    label: "EF011991: Forced switch on"
  },
  {
    featureId: "EF011992",
    featureDesc: "Min. starting current",
    label: "EF011992: Min. starting current"
  },
  {
    featureId: "EF011993",
    featureDesc: "Omni directional",
    label: "EF011993: Omni directional"
  },
  {
    featureId: "EF011994",
    featureDesc: "Number of ports 10 Gbps LC (SR)",
    label: "EF011994: Number of ports 10 Gbps LC (SR)"
  },
  {
    featureId: "EF011995",
    featureDesc: "Number of ports 10 Gbps LC (LR)",
    label: "EF011995: Number of ports 10 Gbps LC (LR)"
  },
  {
    featureId: "EF011996",
    featureDesc: "Wavelength",
    label: "EF011996: Wavelength"
  },
  {
    featureId: "EF011997",
    featureDesc: "40 Gigabit Ethernet",
    label: "EF011997: 40 Gigabit Ethernet"
  },
  {
    featureId: "EF011998",
    featureDesc: "Number of active contacts (round)",
    label: "EF011998: Number of active contacts (round)"
  },
  {
    featureId: "EF011999",
    featureDesc: "Number of active contacts (flat)",
    label: "EF011999: Number of active contacts (flat)"
  },
  {
    featureId: "EF012001",
    featureDesc: "NEMA designation",
    label: "EF012001: NEMA designation"
  },
  {
    featureId: "EF012002",
    featureDesc: "Step width",
    label: "EF012002: Step width"
  },
  {
    featureId: "EF012003",
    featureDesc: "Upper truss head height",
    label: "EF012003: Upper truss head height"
  },
  {
    featureId: "EF012004",
    featureDesc: "Lower truss head height",
    label: "EF012004: Lower truss head height"
  },
  {
    featureId: "EF012005",
    featureDesc: "Inclined truss section height",
    label: "EF012005: Inclined truss section height"
  },
  {
    featureId: "EF012007",
    featureDesc: "Nominal speed",
    label: "EF012007: Nominal speed"
  },
  {
    featureId: "EF012008",
    featureDesc: "Vertical rise",
    label: "EF012008: Vertical rise"
  },
  {
    featureId: "EF012009",
    featureDesc: "Balustrade height",
    label: "EF012009: Balustrade height"
  },
  {
    featureId: "EF012010",
    featureDesc: "Number horizontal steps top",
    label: "EF012010: Number horizontal steps top"
  },
  {
    featureId: "EF012011",
    featureDesc: "Number horizontal steps bottom",
    label: "EF012011: Number horizontal steps bottom"
  },
  {
    featureId: "EF012012",
    featureDesc: "Balustrade extension",
    label: "EF012012: Balustrade extension"
  },
  {
    featureId: "EF012013",
    featureDesc: "Number of universal multi standard sockets",
    label: "EF012013: Number of universal multi standard sockets"
  },
  {
    featureId: "EF012014",
    featureDesc: "Number of serviced floors",
    label: "EF012014: Number of serviced floors"
  },
  {
    featureId: "EF012015",
    featureDesc: "Number of accesses front side",
    label: "EF012015: Number of accesses front side"
  },
  {
    featureId: "EF012016",
    featureDesc: "Number of accesses back side",
    label: "EF012016: Number of accesses back side"
  },
  {
    featureId: "EF012017",
    featureDesc: "Drive position",
    label: "EF012017: Drive position"
  },
  {
    featureId: "EF012018",
    featureDesc: "Type of door front side",
    label: "EF012018: Type of door front side"
  },
  {
    featureId: "EF012019",
    featureDesc: "Type of door back side",
    label: "EF012019: Type of door back side"
  },
  {
    featureId: "EF012020",
    featureDesc: "Clear width door front side",
    label: "EF012020: Clear width door front side"
  },
  {
    featureId: "EF012021",
    featureDesc: "Clear height door front side",
    label: "EF012021: Clear height door front side"
  },
  {
    featureId: "EF012022",
    featureDesc: "Clear width door back side",
    label: "EF012022: Clear width door back side"
  },
  {
    featureId: "EF012023",
    featureDesc: "Clear height door back side",
    label: "EF012023: Clear height door back side"
  },
  {
    featureId: "EF012024",
    featureDesc: "Inner width elevator car",
    label: "EF012024: Inner width elevator car"
  },
  {
    featureId: "EF012025",
    featureDesc: "Inner depth elevator car",
    label: "EF012025: Inner depth elevator car"
  },
  {
    featureId: "EF012026",
    featureDesc: "Inner height elevator car",
    label: "EF012026: Inner height elevator car"
  },
  {
    featureId: "EF012027",
    featureDesc: "Position drive in shaft (orientation from front side into shaft)",
    label: "EF012027: Position drive in shaft (orientation from front side into shaft)"
  },
  {
    featureId: "EF012028",
    featureDesc: "Type of measurement",
    label: "EF012028: Type of measurement"
  },
  {
    featureId: "EF012030",
    featureDesc: "Metric graduation",
    label: "EF012030: Metric graduation"
  },
  {
    featureId: "EF012031",
    featureDesc: "Imperial graduation",
    label: "EF012031: Imperial graduation"
  },
  {
    featureId: "EF012032",
    featureDesc: "Auto-darkening filter",
    label: "EF012032: Auto-darkening filter"
  },
  {
    featureId: "EF012033",
    featureDesc: "Annular cutter",
    label: "EF012033: Annular cutter"
  },
  {
    featureId: "EF012034",
    featureDesc: "Burr",
    label: "EF012034: Burr"
  },
  {
    featureId: "EF012036",
    featureDesc: "With dustpan",
    label: "EF012036: With dustpan"
  },
  {
    featureId: "EF012037",
    featureDesc: "Number of socket outlets British Standard BS1363",
    label: "EF012037: Number of socket outlets British Standard BS1363"
  },
  {
    featureId: "EF012038",
    featureDesc: "Number of empty module positions",
    label: "EF012038: Number of empty module positions"
  },
  {
    featureId: "EF012039",
    featureDesc: "With mounting grid",
    label: "EF012039: With mounting grid"
  },
  {
    featureId: "EF012041",
    featureDesc: "Bubble wrap",
    label: "EF012041: Bubble wrap"
  },
  {
    featureId: "EF012042",
    featureDesc: "Material finish",
    label: "EF012042: Material finish"
  },
  {
    featureId: "EF012043",
    featureDesc: "Propulsion agent",
    label: "EF012043: Propulsion agent"
  },
  {
    featureId: "EF012044",
    featureDesc: "Suitable for fire class A (solid)",
    label: "EF012044: Suitable for fire class A (solid)"
  },
  {
    featureId: "EF012045",
    featureDesc: "Suitable for class B (liquid)",
    label: "EF012045: Suitable for class B (liquid)"
  },
  {
    featureId: "EF012046",
    featureDesc: "Suitable for class C (gas)",
    label: "EF012046: Suitable for class C (gas)"
  },
  {
    featureId: "EF012047",
    featureDesc: "Suitable for class D (metals)",
    label: "EF012047: Suitable for class D (metals)"
  },
  {
    featureId: "EF012049",
    featureDesc: "Suitable for class F (fat)",
    label: "EF012049: Suitable for class F (fat)"
  },
  {
    featureId: "EF012052",
    featureDesc: "Suitable for facility size (Number of persons)",
    label: "EF012052: Suitable for facility size (Number of persons)"
  },
  {
    featureId: "EF012053",
    featureDesc: "Disposable",
    label: "EF012053: Disposable"
  },
  {
    featureId: "EF012054",
    featureDesc: "Fitting type",
    label: "EF012054: Fitting type"
  },
  {
    featureId: "EF012055",
    featureDesc: "Water repellent",
    label: "EF012055: Water repellent"
  },
  {
    featureId: "EF012056",
    featureDesc: "Size (US/CA)",
    label: "EF012056: Size (US/CA)"
  },
  {
    featureId: "EF012057",
    featureDesc: "Bib style",
    label: "EF012057: Bib style"
  },
  {
    featureId: "EF012058",
    featureDesc: "Number of pockets",
    label: "EF012058: Number of pockets"
  },
  {
    featureId: "EF012061",
    featureDesc: "Number of ports 40 Gbps Mini GBIC",
    label: "EF012061: Number of ports 40 Gbps Mini GBIC"
  },
  {
    featureId: "EF012062",
    featureDesc: "Number of ports 100 Gbps Mini GBIC",
    label: "EF012062: Number of ports 100 Gbps Mini GBIC"
  },
  {
    featureId: "EF012063",
    featureDesc: "Number of ports 10 Gbps RJ45",
    label: "EF012063: Number of ports 10 Gbps RJ45"
  },
  {
    featureId: "EF012064",
    featureDesc: "UPC-Version",
    label: "EF012064: UPC-Version"
  },
  {
    featureId: "EF012065",
    featureDesc: "Number of AP's supported",
    label: "EF012065: Number of AP's supported"
  },
  {
    featureId: "EF012066",
    featureDesc: "Firewall throughput",
    label: "EF012066: Firewall throughput"
  },
  {
    featureId: "EF012067",
    featureDesc: "Outer diameter sheath single fibre",
    label: "EF012067: Outer diameter sheath single fibre"
  },
  {
    featureId: "EF012068",
    featureDesc: "With adapter FC",
    label: "EF012068: With adapter FC"
  },
  {
    featureId: "EF012069",
    featureDesc: "With adapter LC",
    label: "EF012069: With adapter LC"
  },
  {
    featureId: "EF012070",
    featureDesc: "MIMO (Multiple-Input Multiple-Output )",
    label: "EF012070: MIMO (Multiple-Input Multiple-Output )"
  },
  {
    featureId: "EF012072",
    featureDesc: "High visibility (signal colours)",
    label: "EF012072: High visibility (signal colours)"
  },
  {
    featureId: "EF012073",
    featureDesc: "With reflective striping",
    label: "EF012073: With reflective striping"
  },
  {
    featureId: "EF012074",
    featureDesc: "Gender",
    label: "EF012074: Gender"
  },
  {
    featureId: "EF012075",
    featureDesc: "Child model",
    label: "EF012075: Child model"
  },
  {
    featureId: "EF012076",
    featureDesc: "With bracket",
    label: "EF012076: With bracket"
  },
  {
    featureId: "EF012077",
    featureDesc: "Replacement pods",
    label: "EF012077: Replacement pods"
  },
  {
    featureId: "EF012079",
    featureDesc: "Neckline type",
    label: "EF012079: Neckline type"
  },
  {
    featureId: "EF012080",
    featureDesc: "Wrinkle free",
    label: "EF012080: Wrinkle free"
  },
  {
    featureId: "EF012081",
    featureDesc: "Clothing length",
    label: "EF012081: Clothing length"
  },
  {
    featureId: "EF012082",
    featureDesc: "Type of padding",
    label: "EF012082: Type of padding"
  },
  {
    featureId: "EF012085",
    featureDesc: "Cuff type",
    label: "EF012085: Cuff type"
  },
  {
    featureId: "EF012086",
    featureDesc: "Cut resistant",
    label: "EF012086: Cut resistant"
  },
  {
    featureId: "EF012087",
    featureDesc: "Type of hood",
    label: "EF012087: Type of hood"
  },
  {
    featureId: "EF012088",
    featureDesc: "Sole material",
    label: "EF012088: Sole material"
  },
  {
    featureId: "EF012089",
    featureDesc: "Material of upper part",
    label: "EF012089: Material of upper part"
  },
  {
    featureId: "EF012090",
    featureDesc: "Rated ambient temperature according to IEC 62722-2-1",
    label: "EF012090: Rated ambient temperature according to IEC 62722-2-1"
  },
  {
    featureId: "EF012091",
    featureDesc: "Filament test according to IEC 60695-2-11",
    label: "EF012091: Filament test according to IEC 60695-2-11"
  },
  {
    featureId: "EF012092",
    featureDesc: "Wide fit",
    label: "EF012092: Wide fit"
  },
  {
    featureId: "EF012093",
    featureDesc: "Inner lining",
    label: "EF012093: Inner lining"
  },
  {
    featureId: "EF012097",
    featureDesc: "With reinforced ankle",
    label: "EF012097: With reinforced ankle"
  },
  {
    featureId: "EF012098",
    featureDesc: "Protects against particulates",
    label: "EF012098: Protects against particulates"
  },
  {
    featureId: "EF012099",
    featureDesc: "Protects against nuisance level acid gas",
    label: "EF012099: Protects against nuisance level acid gas"
  },
  {
    featureId: "EF012100",
    featureDesc: "Protects against nuisance odours",
    label: "EF012100: Protects against nuisance odours"
  },
  {
    featureId: "EF012101",
    featureDesc: "Protects against viruses",
    label: "EF012101: Protects against viruses"
  },
  {
    featureId: "EF012103",
    featureDesc: "Protects against organic vapour",
    label: "EF012103: Protects against organic vapour"
  },
  {
    featureId: "EF012104",
    featureDesc: "Protects against acid gas",
    label: "EF012104: Protects against acid gas"
  },
  {
    featureId: "EF012105",
    featureDesc: "Protects against ammonia",
    label: "EF012105: Protects against ammonia"
  },
  {
    featureId: "EF012106",
    featureDesc: "Protects against methylamine",
    label: "EF012106: Protects against methylamine"
  },
  {
    featureId: "EF012107",
    featureDesc: "Protects against formaldehyde",
    label: "EF012107: Protects against formaldehyde"
  },
  {
    featureId: "EF012108",
    featureDesc: "Protects against mercury",
    label: "EF012108: Protects against mercury"
  },
  {
    featureId: "EF012109",
    featureDesc: "Protects against chlorine",
    label: "EF012109: Protects against chlorine"
  },
  {
    featureId: "EF012110",
    featureDesc: "Protects against hydrogen chloride",
    label: "EF012110: Protects against hydrogen chloride"
  },
  {
    featureId: "EF012111",
    featureDesc: "Protects against sulfur dioxide",
    label: "EF012111: Protects against sulfur dioxide"
  },
  {
    featureId: "EF012112",
    featureDesc: "Protects against hydrogen sulfide",
    label: "EF012112: Protects against hydrogen sulfide"
  },
  {
    featureId: "EF012113",
    featureDesc: "Protects against hydrogen fluoride",
    label: "EF012113: Protects against hydrogen fluoride"
  },
  {
    featureId: "EF012114",
    featureDesc: "Protects against chlorine dioxide",
    label: "EF012114: Protects against chlorine dioxide"
  },
  {
    featureId: "EF012116",
    featureDesc: "Number of hand holes",
    label: "EF012116: Number of hand holes"
  },
  {
    featureId: "EF012117",
    featureDesc: "Scraper type",
    label: "EF012117: Scraper type"
  },
  {
    featureId: "EF012118",
    featureDesc: "Interchangeable tip",
    label: "EF012118: Interchangeable tip"
  },
  {
    featureId: "EF012121",
    featureDesc: "Twisted",
    label: "EF012121: Twisted"
  },
  {
    featureId: "EF012122",
    featureDesc: "Lamp power per foot",
    label: "EF012122: Lamp power per foot"
  },
  {
    featureId: "EF012124",
    featureDesc: "Removable tray",
    label: "EF012124: Removable tray"
  },
  {
    featureId: "EF012125",
    featureDesc: "Total number of compartments",
    label: "EF012125: Total number of compartments"
  },
  {
    featureId: "EF012126",
    featureDesc: "With foam",
    label: "EF012126: With foam"
  },
  {
    featureId: "EF012127",
    featureDesc: "With divider",
    label: "EF012127: With divider"
  },
  {
    featureId: "EF012128",
    featureDesc: "Platform height",
    label: "EF012128: Platform height"
  },
  {
    featureId: "EF012129",
    featureDesc: "With vial",
    label: "EF012129: With vial"
  },
  {
    featureId: "EF012130",
    featureDesc: "Two-sided",
    label: "EF012130: Two-sided"
  },
  {
    featureId: "EF012131",
    featureDesc: "Number of bits inner square (Robertson)",
    label: "EF012131: Number of bits inner square (Robertson)"
  },
  {
    featureId: "EF012132",
    featureDesc: "Thread per inch",
    label: "EF012132: Thread per inch"
  },
  {
    featureId: "EF012133",
    featureDesc: "Automatic operation",
    label: "EF012133: Automatic operation"
  },
  {
    featureId: "EF012134",
    featureDesc: "Power return",
    label: "EF012134: Power return"
  },
  {
    featureId: "EF012135",
    featureDesc: "Ratcheting model",
    label: "EF012135: Ratcheting model"
  },
  {
    featureId: "EF012136",
    featureDesc: "Point count",
    label: "EF012136: Point count"
  },
  {
    featureId: "EF012137",
    featureDesc: "Chain style model",
    label: "EF012137: Chain style model"
  },
  {
    featureId: "EF012138",
    featureDesc: "Engineering vice",
    label: "EF012138: Engineering vice"
  },
  {
    featureId: "EF012140",
    featureDesc: "With hammer holder",
    label: "EF012140: With hammer holder"
  },
  {
    featureId: "EF012141",
    featureDesc: "Angled",
    label: "EF012141: Angled"
  },
  {
    featureId: "EF012142",
    featureDesc: "Physical form",
    label: "EF012142: Physical form"
  },
  {
    featureId: "EF012143",
    featureDesc: "Suitable for window",
    label: "EF012143: Suitable for window"
  },
  {
    featureId: "EF012147",
    featureDesc: "Tip angle",
    label: "EF012147: Tip angle"
  },
  {
    featureId: "EF012148",
    featureDesc: "Dust category according to IEC/EN 60335-2-69",
    label: "EF012148: Dust category according to IEC/EN 60335-2-69"
  },
  {
    featureId: "EF012149",
    featureDesc: "Suitable for belt-/roller sanders",
    label: "EF012149: Suitable for belt-/roller sanders"
  },
  {
    featureId: "EF012152",
    featureDesc: "Dimming 0-10 V",
    label: "EF012152: Dimming 0-10 V"
  },
  {
    featureId: "EF012153",
    featureDesc: "Dimming 1-10 V",
    label: "EF012153: Dimming 1-10 V"
  },
  {
    featureId: "EF012154",
    featureDesc: "Dimming DALI",
    label: "EF012154: Dimming DALI"
  },
  {
    featureId: "EF012155",
    featureDesc: "Dimming DMX",
    label: "EF012155: Dimming DMX"
  },
  {
    featureId: "EF012156",
    featureDesc: "Dimming potentiometer (integrated)",
    label: "EF012156: Dimming potentiometer (integrated)"
  },
  {
    featureId: "EF012158",
    featureDesc: "Dimming LineSwitch",
    label: "EF012158: Dimming LineSwitch"
  },
  {
    featureId: "EF012159",
    featureDesc: "Dimming manufacturer's proprietary system",
    label: "EF012159: Dimming manufacturer's proprietary system"
  },
  {
    featureId: "EF012160",
    featureDesc: "Dimming mains voltage modulation",
    label: "EF012160: Dimming mains voltage modulation"
  },
  {
    featureId: "EF012161",
    featureDesc: "Dimming phase cut-off",
    label: "EF012161: Dimming phase cut-off"
  },
  {
    featureId: "EF012162",
    featureDesc: "Dimming phase cut-on",
    label: "EF012162: Dimming phase cut-on"
  },
  {
    featureId: "EF012163",
    featureDesc: "Dimming programmable",
    label: "EF012163: Dimming programmable"
  },
  {
    featureId: "EF012164",
    featureDesc: "Dimming RF",
    label: "EF012164: Dimming RF"
  },
  {
    featureId: "EF012165",
    featureDesc: "Dimming Sine Wave Reduction",
    label: "EF012165: Dimming Sine Wave Reduction"
  },
  {
    featureId: "EF012166",
    featureDesc: "Dimming Touch and Dim",
    label: "EF012166: Dimming Touch and Dim"
  },
  {
    featureId: "EF012167",
    featureDesc: "Dimming Zigbee",
    label: "EF012167: Dimming Zigbee"
  },
  {
    featureId: "EF012168",
    featureDesc: "Dimming with push-button",
    label: "EF012168: Dimming with push-button"
  },
  {
    featureId: "EF012169",
    featureDesc: "No dim function",
    label: "EF012169: No dim function"
  },
  {
    featureId: "EF012170",
    featureDesc: "Light distributor",
    label: "EF012170: Light distributor"
  },
  {
    featureId: "EF012172",
    featureDesc: "Luminaire efficacy",
    label: "EF012172: Luminaire efficacy"
  },
  {
    featureId: "EF012173",
    featureDesc: "Self retracting",
    label: "EF012173: Self retracting"
  },
  {
    featureId: "EF012174",
    featureDesc: "Cycle time",
    label: "EF012174: Cycle time"
  },
  {
    featureId: "EF012175",
    featureDesc: "Compression force",
    label: "EF012175: Compression force"
  },
  {
    featureId: "EF012176",
    featureDesc: "Suitable for low voltage cable system",
    label: "EF012176: Suitable for low voltage cable system"
  },
  {
    featureId: "EF012177",
    featureDesc: "Stud size (imperial)",
    label: "EF012177: Stud size (imperial)"
  },
  {
    featureId: "EF012178",
    featureDesc: "Min. AWG-size",
    label: "EF012178: Min. AWG-size"
  },
  {
    featureId: "EF012179",
    featureDesc: "Max. AWG-size",
    label: "EF012179: Max. AWG-size"
  },
  {
    featureId: "EF012180",
    featureDesc: "With inner thread",
    label: "EF012180: With inner thread"
  },
  {
    featureId: "EF012184",
    featureDesc: "For armoured cable",
    label: "EF012184: For armoured cable"
  },
  {
    featureId: "EF012185",
    featureDesc: "Number of feedthroughs",
    label: "EF012185: Number of feedthroughs"
  },
  {
    featureId: "EF012186",
    featureDesc: "Mix bundle",
    label: "EF012186: Mix bundle"
  },
  {
    featureId: "EF012187",
    featureDesc: "Diameter single fibre",
    label: "EF012187: Diameter single fibre"
  },
  {
    featureId: "EF012188",
    featureDesc: "Dimming DSI",
    label: "EF012188: Dimming DSI"
  },
  {
    featureId: "EF012189",
    featureDesc: "Protection of base part",
    label: "EF012189: Protection of base part"
  },
  {
    featureId: "EF012190",
    featureDesc: "With voting function",
    label: "EF012190: With voting function"
  },
  {
    featureId: "EF012191",
    featureDesc: "With built-in RFID card reader",
    label: "EF012191: With built-in RFID card reader"
  },
  {
    featureId: "EF012192",
    featureDesc: "With priority function for chairman",
    label: "EF012192: With priority function for chairman"
  },
  {
    featureId: "EF012193",
    featureDesc: "With muting function for chairman",
    label: "EF012193: With muting function for chairman"
  },
  {
    featureId: "EF012194",
    featureDesc: "Capacity 2",
    label: "EF012194: Capacity 2"
  },
  {
    featureId: "EF012195",
    featureDesc: "With flame detection",
    label: "EF012195: With flame detection"
  },
  {
    featureId: "EF012196",
    featureDesc: "Lamp designation",
    label: "EF012196: Lamp designation"
  },
  {
    featureId: "EF012199",
    featureDesc: "Filament lamp",
    label: "EF012199: Filament lamp"
  },
  {
    featureId: "EF012201",
    featureDesc: "Min. number of switching cycles",
    label: "EF012201: Min. number of switching cycles"
  },
  {
    featureId: "EF012202",
    featureDesc: "Internal version",
    label: "EF012202: Internal version"
  },
  {
    featureId: "EF012203",
    featureDesc: "With lens",
    label: "EF012203: With lens"
  },
  {
    featureId: "EF012204",
    featureDesc: "Suitable for auxiliary lamp",
    label: "EF012204: Suitable for auxiliary lamp"
  },
  {
    featureId: "EF012205",
    featureDesc: "Number of nodes per foot",
    label: "EF012205: Number of nodes per foot"
  },
  {
    featureId: "EF012206",
    featureDesc: "Type of fixing element",
    label: "EF012206: Type of fixing element"
  },
  {
    featureId: "EF012207",
    featureDesc: "Mounting type of the fixing element",
    label: "EF012207: Mounting type of the fixing element"
  },
  {
    featureId: "EF012208",
    featureDesc: "Fixing direction",
    label: "EF012208: Fixing direction"
  },
  {
    featureId: "EF012209",
    featureDesc: "Type of mounting rail",
    label: "EF012209: Type of mounting rail"
  },
  {
    featureId: "EF012210",
    featureDesc: "Mounting method of the cable gland",
    label: "EF012210: Mounting method of the cable gland"
  },
  {
    featureId: "EF012212",
    featureDesc: "Min. number of insertion cycles",
    label: "EF012212: Min. number of insertion cycles"
  },
  {
    featureId: "EF012213",
    featureDesc: "Type of contact",
    label: "EF012213: Type of contact"
  },
  {
    featureId: "EF012216",
    featureDesc: "Number of power contacts",
    label: "EF012216: Number of power contacts"
  },
  {
    featureId: "EF012217",
    featureDesc: "Number of control contacts",
    label: "EF012217: Number of control contacts"
  },
  {
    featureId: "EF012218",
    featureDesc: "Column depth",
    label: "EF012218: Column depth"
  },
  {
    featureId: "EF012219",
    featureDesc: "Cover depth",
    label: "EF012219: Cover depth"
  },
  {
    featureId: "EF012222",
    featureDesc: "With captivity element",
    label: "EF012222: With captivity element"
  },
  {
    featureId: "EF012223",
    featureDesc: "According to EN 54-23",
    label: "EF012223: According to EN 54-23"
  },
  {
    featureId: "EF012224",
    featureDesc: "Coverage volume",
    label: "EF012224: Coverage volume"
  },
  {
    featureId: "EF012226",
    featureDesc: "Max. infrared reach",
    label: "EF012226: Max. infrared reach"
  },
  {
    featureId: "EF012227",
    featureDesc: "Horizontal field of view",
    label: "EF012227: Horizontal field of view"
  },
  {
    featureId: "EF012228",
    featureDesc: "Vertical field of view",
    label: "EF012228: Vertical field of view"
  },
  {
    featureId: "EF012230",
    featureDesc: "Number of audio outputs",
    label: "EF012230: Number of audio outputs"
  },
  {
    featureId: "EF012231",
    featureDesc: "Number of audio inputs",
    label: "EF012231: Number of audio inputs"
  },
  {
    featureId: "EF012232",
    featureDesc: "Number of data contacts",
    label: "EF012232: Number of data contacts"
  },
  {
    featureId: "EF012234",
    featureDesc: "RAID 0 capable",
    label: "EF012234: RAID 0 capable"
  },
  {
    featureId: "EF012235",
    featureDesc: "RAID 1 capable",
    label: "EF012235: RAID 1 capable"
  },
  {
    featureId: "EF012236",
    featureDesc: "RAID 5 capable",
    label: "EF012236: RAID 5 capable"
  },
  {
    featureId: "EF012241",
    featureDesc: "Hot-swappable hard disk capability",
    label: "EF012241: Hot-swappable hard disk capability"
  },
  {
    featureId: "EF012242",
    featureDesc: "Number of pre-licensed cameras",
    label: "EF012242: Number of pre-licensed cameras"
  },
  {
    featureId: "EF012244",
    featureDesc: "Supports H.264 codec",
    label: "EF012244: Supports H.264 codec"
  },
  {
    featureId: "EF012245",
    featureDesc: "Supports H.265 codec",
    label: "EF012245: Supports H.265 codec"
  },
  {
    featureId: "EF012246",
    featureDesc: "Supports Motion JPEG codec",
    label: "EF012246: Supports Motion JPEG codec"
  },
  {
    featureId: "EF012247",
    featureDesc: "Supports MPEG-4 codec",
    label: "EF012247: Supports MPEG-4 codec"
  },
  {
    featureId: "EF012248",
    featureDesc: "Number of detector addresses",
    label: "EF012248: Number of detector addresses"
  },
  {
    featureId: "EF012251",
    featureDesc: "With polka dot",
    label: "EF012251: With polka dot"
  },
  {
    featureId: "EF012252",
    featureDesc: "With stripe",
    label: "EF012252: With stripe"
  },
  {
    featureId: "EF012253",
    featureDesc: "Detectable",
    label: "EF012253: Detectable"
  },
  {
    featureId: "EF012261",
    featureDesc: "Suitable for multiple use",
    label: "EF012261: Suitable for multiple use"
  },
  {
    featureId: "EF012262",
    featureDesc: "Suitable for cathodic welding",
    label: "EF012262: Suitable for cathodic welding"
  },
  {
    featureId: "EF012264",
    featureDesc: "Suitable for use in high voltage area",
    label: "EF012264: Suitable for use in high voltage area"
  },
  {
    featureId: "EF012265",
    featureDesc: "Reduced-emission connection",
    label: "EF012265: Reduced-emission connection"
  },
  {
    featureId: "EF012266",
    featureDesc: "Suitable for rail application",
    label: "EF012266: Suitable for rail application"
  },
  {
    featureId: "EF012267",
    featureDesc: "Assembly height conductor holder",
    label: "EF012267: Assembly height conductor holder"
  },
  {
    featureId: "EF012268",
    featureDesc: "Suitable for width flat conductor",
    label: "EF012268: Suitable for width flat conductor"
  },
  {
    featureId: "EF012269",
    featureDesc: "Rod diameter",
    label: "EF012269: Rod diameter"
  },
  {
    featureId: "EF012270",
    featureDesc: "Rated impulse sparkover voltage",
    label: "EF012270: Rated impulse sparkover voltage"
  },
  {
    featureId: "EF012271",
    featureDesc: "Rated power-frequency withstand voltage",
    label: "EF012271: Rated power-frequency withstand voltage"
  },
  {
    featureId: "EF012272",
    featureDesc: "Diameter holding interception rod",
    label: "EF012272: Diameter holding interception rod"
  },
  {
    featureId: "EF012273",
    featureDesc: "Lightning current load-bearing capacity (10/350 µs)",
    label: "EF012273: Lightning current load-bearing capacity (10/350 µs)"
  },
  {
    featureId: "EF012274",
    featureDesc: "Max. discharge surge current (8/20)",
    label: "EF012274: Max. discharge surge current (8/20)"
  },
  {
    featureId: "EF012275",
    featureDesc: "Total discharge current (8/20) (L1+L2+L3+N-PE)",
    label: "EF012275: Total discharge current (8/20) (L1+L2+L3+N-PE)"
  },
  {
    featureId: "EF012276",
    featureDesc: "Voltage protection level L-PE",
    label: "EF012276: Voltage protection level L-PE"
  },
  {
    featureId: "EF012277",
    featureDesc: "Clamping range cable",
    label: "EF012277: Clamping range cable"
  },
  {
    featureId: "EF012278",
    featureDesc: "Min. depth of built-in installation box",
    label: "EF012278: Min. depth of built-in installation box"
  },
  {
    featureId: "EF012279",
    featureDesc: "Total discharge current (8/20)",
    label: "EF012279: Total discharge current (8/20)"
  },
  {
    featureId: "EF012280",
    featureDesc: "Continuous voltage DC",
    label: "EF012280: Continuous voltage DC"
  },
  {
    featureId: "EF012281",
    featureDesc: "Rated voltage DC",
    label: "EF012281: Rated voltage DC"
  },
  {
    featureId: "EF012282",
    featureDesc: "Construction size",
    label: "EF012282: Construction size"
  },
  {
    featureId: "EF012283",
    featureDesc: "Size",
    label: "EF012283: Size"
  },
  {
    featureId: "EF012284",
    featureDesc: "Direct incident energy",
    label: "EF012284: Direct incident energy"
  },
  {
    featureId: "EF012285",
    featureDesc: "Arc energy",
    label: "EF012285: Arc energy"
  },
  {
    featureId: "EF012286",
    featureDesc: "Suitable for additional cooling",
    label: "EF012286: Suitable for additional cooling"
  },
  {
    featureId: "EF012287",
    featureDesc: "Suitable for additional heating",
    label: "EF012287: Suitable for additional heating"
  },
  {
    featureId: "EF012288",
    featureDesc: "Total length",
    label: "EF012288: Total length"
  },
  {
    featureId: "EF012289",
    featureDesc: "With connection loudspeaker",
    label: "EF012289: With connection loudspeaker"
  },
  {
    featureId: "EF012290",
    featureDesc: "Number of supported call stations",
    label: "EF012290: Number of supported call stations"
  },
  {
    featureId: "EF012291",
    featureDesc: "Number of supported paging zones",
    label: "EF012291: Number of supported paging zones"
  },
  {
    featureId: "EF012292",
    featureDesc: "Number of simultaneous supported audio channels",
    label: "EF012292: Number of simultaneous supported audio channels"
  },
  {
    featureId: "EF012293",
    featureDesc: "With digital message player",
    label: "EF012293: With digital message player"
  },
  {
    featureId: "EF012294",
    featureDesc: "Max. wire cross section",
    label: "EF012294: Max. wire cross section"
  },
  {
    featureId: "EF012295",
    featureDesc: "Coupling mechanism",
    label: "EF012295: Coupling mechanism"
  },
  {
    featureId: "EF012296",
    featureDesc: "Max. wire cross section copper",
    label: "EF012296: Max. wire cross section copper"
  },
  {
    featureId: "EF012297",
    featureDesc: "Diameter fixed point",
    label: "EF012297: Diameter fixed point"
  },
  {
    featureId: "EF012298",
    featureDesc: "Cable sheath",
    label: "EF012298: Cable sheath"
  },
  {
    featureId: "EF012299",
    featureDesc: "Max. sound pressure level",
    label: "EF012299: Max. sound pressure level"
  },
  {
    featureId: "EF012300",
    featureDesc: "Impedance",
    label: "EF012300: Impedance"
  },
  {
    featureId: "EF012301",
    featureDesc: "Single-ear headphone",
    label: "EF012301: Single-ear headphone"
  },
  {
    featureId: "EF012303",
    featureDesc: "Screw-in thread",
    label: "EF012303: Screw-in thread"
  },
  {
    featureId: "EF012304",
    featureDesc: "Material thread ring",
    label: "EF012304: Material thread ring"
  },
  {
    featureId: "EF012306",
    featureDesc: "Scannable symbol/barrier free",
    label: "EF012306: Scannable symbol/barrier free"
  },
  {
    featureId: "EF012307",
    featureDesc: "With wind protection",
    label: "EF012307: With wind protection"
  },
  {
    featureId: "EF012308",
    featureDesc: "With lock function",
    label: "EF012308: With lock function"
  },
  {
    featureId: "EF012309",
    featureDesc: "Material inner container/bottom",
    label: "EF012309: Material inner container/bottom"
  },
  {
    featureId: "EF012310",
    featureDesc: "Hidden heating element",
    label: "EF012310: Hidden heating element"
  },
  {
    featureId: "EF012311",
    featureDesc: "Length inlet hose",
    label: "EF012311: Length inlet hose"
  },
  {
    featureId: "EF012312",
    featureDesc: "Length outlet hose",
    label: "EF012312: Length outlet hose"
  },
  {
    featureId: "EF012313",
    featureDesc: "Detector cooled",
    label: "EF012313: Detector cooled"
  },
  {
    featureId: "EF012314",
    featureDesc: "Field of view (FOV) vertical",
    label: "EF012314: Field of view (FOV) vertical"
  },
  {
    featureId: "EF012315",
    featureDesc: "Field of view (FOV) horizontal",
    label: "EF012315: Field of view (FOV) horizontal"
  },
  {
    featureId: "EF012316",
    featureDesc: "Instantaneous field of view (IFOV)",
    label: "EF012316: Instantaneous field of view (IFOV)"
  },
  {
    featureId: "EF012317",
    featureDesc: "With built-in torch",
    label: "EF012317: With built-in torch"
  },
  {
    featureId: "EF012318",
    featureDesc: "Moisture content on dry load after max. spin",
    label: "EF012318: Moisture content on dry load after max. spin"
  },
  {
    featureId: "EF012319",
    featureDesc: "Mobile High-Definition Link (MHL)",
    label: "EF012319: Mobile High-Definition Link (MHL)"
  },
  {
    featureId: "EF012320",
    featureDesc: "Analogue video out",
    label: "EF012320: Analogue video out"
  },
  {
    featureId: "EF012323",
    featureDesc: "FireWire (IEEE1394) connection",
    label: "EF012323: FireWire (IEEE1394) connection"
  },
  {
    featureId: "EF012324",
    featureDesc: "Programme time of the standard cotton programme at full load",
    label: "EF012324: Programme time of the standard cotton programme at full load"
  },
  {
    featureId: "EF012325",
    featureDesc: "Focus stacking",
    label: "EF012325: Focus stacking"
  },
  {
    featureId: "EF012326",
    featureDesc: "Programme time of the standard cotton programme at partial load",
    label: "EF012326: Programme time of the standard cotton programme at partial load"
  },
  {
    featureId: "EF012327",
    featureDesc: "Image averaging",
    label: "EF012327: Image averaging"
  },
  {
    featureId: "EF012329",
    featureDesc: "With laser distance measurement",
    label: "EF012329: With laser distance measurement"
  },
  {
    featureId: "EF012330",
    featureDesc: "Frames per second (fps)",
    label: "EF012330: Frames per second (fps)"
  },
  {
    featureId: "EF012331",
    featureDesc: "Power consumption in standby mode",
    label: "EF012331: Power consumption in standby mode"
  },
  {
    featureId: "EF012332",
    featureDesc: "Power consumption on off mode",
    label: "EF012332: Power consumption on off mode"
  },
  {
    featureId: "EF012333",
    featureDesc: "Automatic programs",
    label: "EF012333: Automatic programs"
  },
  {
    featureId: "EF012334",
    featureDesc: "Number of cavities",
    label: "EF012334: Number of cavities"
  },
  {
    featureId: "EF012335",
    featureDesc: "Energy consumption per cycle conventional",
    label: "EF012335: Energy consumption per cycle conventional"
  },
  {
    featureId: "EF012336",
    featureDesc: "Energy consumption per cycle forced air convection",
    label: "EF012336: Energy consumption per cycle forced air convection"
  },
  {
    featureId: "EF012337",
    featureDesc: "Flash-card slot",
    label: "EF012337: Flash-card slot"
  },
  {
    featureId: "EF012338",
    featureDesc: "Max. working voltage",
    label: "EF012338: Max. working voltage"
  },
  {
    featureId: "EF012339",
    featureDesc: "Effective anchorage depth",
    label: "EF012339: Effective anchorage depth"
  },
  {
    featureId: "EF012340",
    featureDesc: "Number of induction plates",
    label: "EF012340: Number of induction plates"
  },
  {
    featureId: "EF012341",
    featureDesc: "Number of gas burners",
    label: "EF012341: Number of gas burners"
  },
  {
    featureId: "EF012342",
    featureDesc: "With resin capsule",
    label: "EF012342: With resin capsule"
  },
  {
    featureId: "EF012347",
    featureDesc: "Gas connection rating",
    label: "EF012347: Gas connection rating"
  },
  {
    featureId: "EF012348",
    featureDesc: "Electrical connection rating",
    label: "EF012348: Electrical connection rating"
  },
  {
    featureId: "EF012349",
    featureDesc: "Energy consumption gas oven per cycle conventional",
    label: "EF012349: Energy consumption gas oven per cycle conventional"
  },
  {
    featureId: "EF012350",
    featureDesc: "Energy consumption gas oven per cycle forced air convection",
    label: "EF012350: Energy consumption gas oven per cycle forced air convection"
  },
  {
    featureId: "EF012351",
    featureDesc: "AM receiver",
    label: "EF012351: AM receiver"
  },
  {
    featureId: "EF012352",
    featureDesc: "Long wave",
    label: "EF012352: Long wave"
  },
  {
    featureId: "EF012353",
    featureDesc: "Short wave (world receiver)",
    label: "EF012353: Short wave (world receiver)"
  },
  {
    featureId: "EF012354",
    featureDesc: "Output power (music) per channel",
    label: "EF012354: Output power (music) per channel"
  },
  {
    featureId: "EF012355",
    featureDesc: "Effective sinus power per channel",
    label: "EF012355: Effective sinus power per channel"
  },
  {
    featureId: "EF012356",
    featureDesc: "Double DIN device",
    label: "EF012356: Double DIN device"
  },
  {
    featureId: "EF012357",
    featureDesc: "Max. reading rate per second",
    label: "EF012357: Max. reading rate per second"
  },
  {
    featureId: "EF012358",
    featureDesc: "Max. writing speed per second",
    label: "EF012358: Max. writing speed per second"
  },
  {
    featureId: "EF012359",
    featureDesc: "Driving lane assistant",
    label: "EF012359: Driving lane assistant"
  },
  {
    featureId: "EF012360",
    featureDesc: "Random access memory (RAM)",
    label: "EF012360: Random access memory (RAM)"
  },
  {
    featureId: "EF012361",
    featureDesc: "NFC (Near Field Communication)",
    label: "EF012361: NFC (Near Field Communication)"
  },
  {
    featureId: "EF012362",
    featureDesc: "With record player",
    label: "EF012362: With record player"
  },
  {
    featureId: "EF012363",
    featureDesc: "Blu-ray player",
    label: "EF012363: Blu-ray player"
  },
  {
    featureId: "EF012364",
    featureDesc: "With AV receiver",
    label: "EF012364: With AV receiver"
  },
  {
    featureId: "EF012365",
    featureDesc: "Input voltage DC",
    label: "EF012365: Input voltage DC"
  },
  {
    featureId: "EF012366",
    featureDesc: "Ironing table",
    label: "EF012366: Ironing table"
  },
  {
    featureId: "EF012367",
    featureDesc: "Height ironing table",
    label: "EF012367: Height ironing table"
  },
  {
    featureId: "EF012368",
    featureDesc: "Water tank refillable at any time",
    label: "EF012368: Water tank refillable at any time"
  },
  {
    featureId: "EF012369",
    featureDesc: "Blu-ray recorder",
    label: "EF012369: Blu-ray recorder"
  },
  {
    featureId: "EF012370",
    featureDesc: "Suitable for 3D representation",
    label: "EF012370: Suitable for 3D representation"
  },
  {
    featureId: "EF012371",
    featureDesc: "Conversion 2D to 3D",
    label: "EF012371: Conversion 2D to 3D"
  },
  {
    featureId: "EF012372",
    featureDesc: "Conversion Full-HD to 4K",
    label: "EF012372: Conversion Full-HD to 4K"
  },
  {
    featureId: "EF012373",
    featureDesc: "HDR (High Dynamic Range)",
    label: "EF012373: HDR (High Dynamic Range)"
  },
  {
    featureId: "EF012374",
    featureDesc: "DLNA",
    label: "EF012374: DLNA"
  },
  {
    featureId: "EF012375",
    featureDesc: "Web browser",
    label: "EF012375: Web browser"
  },
  {
    featureId: "EF012376",
    featureDesc: "Video on demand",
    label: "EF012376: Video on demand"
  },
  {
    featureId: "EF012377",
    featureDesc: "HbbTV",
    label: "EF012377: HbbTV"
  },
  {
    featureId: "EF012378",
    featureDesc: "Time shifting",
    label: "EF012378: Time shifting"
  },
  {
    featureId: "EF012379",
    featureDesc: "USB media player",
    label: "EF012379: USB media player"
  },
  {
    featureId: "EF012380",
    featureDesc: "DVB-T2-HD tuner (Codec H.265)",
    label: "EF012380: DVB-T2-HD tuner (Codec H.265)"
  },
  {
    featureId: "EF012381",
    featureDesc: "HDMI-CEC",
    label: "EF012381: HDMI-CEC"
  },
  {
    featureId: "EF012382",
    featureDesc: "Number of CI+ interfaces",
    label: "EF012382: Number of CI+ interfaces"
  },
  {
    featureId: "EF012383",
    featureDesc: "App installation possible",
    label: "EF012383: App installation possible"
  },
  {
    featureId: "EF012384",
    featureDesc: "Operation by app",
    label: "EF012384: Operation by app"
  },
  {
    featureId: "EF012385",
    featureDesc: "Handling with software",
    label: "EF012385: Handling with software"
  },
  {
    featureId: "EF012386",
    featureDesc: "Number of headset connectors",
    label: "EF012386: Number of headset connectors"
  },
  {
    featureId: "EF012387",
    featureDesc: "70 V amplifier",
    label: "EF012387: 70 V amplifier"
  },
  {
    featureId: "EF012388",
    featureDesc: "50 V amplifier",
    label: "EF012388: 50 V amplifier"
  },
  {
    featureId: "EF012389",
    featureDesc: "Power loss at rated current",
    label: "EF012389: Power loss at rated current"
  },
  {
    featureId: "EF012390",
    featureDesc: "Coordination class according to IEC 60947-4-3",
    label: "EF012390: Coordination class according to IEC 60947-4-3"
  },
  {
    featureId: "EF012391",
    featureDesc: "Inputs with priority switching",
    label: "EF012391: Inputs with priority switching"
  },
  {
    featureId: "EF012392",
    featureDesc: "Music power at 70 V",
    label: "EF012392: Music power at 70 V"
  },
  {
    featureId: "EF012393",
    featureDesc: "Music power at 50 V",
    label: "EF012393: Music power at 50 V"
  },
  {
    featureId: "EF012394",
    featureDesc: "Number of RJ12 ports",
    label: "EF012394: Number of RJ12 ports"
  },
  {
    featureId: "EF012395",
    featureDesc: "Number of keypad connectors",
    label: "EF012395: Number of keypad connectors"
  },
  {
    featureId: "EF012396",
    featureDesc: "Cross profile",
    label: "EF012396: Cross profile"
  },
  {
    featureId: "EF012397",
    featureDesc: "Diameter earthing pin",
    label: "EF012397: Diameter earthing pin"
  },
  {
    featureId: "EF012398",
    featureDesc: "Opening clearance",
    label: "EF012398: Opening clearance"
  },
  {
    featureId: "EF012399",
    featureDesc: "Thermal-/visual image (in one image)",
    label: "EF012399: Thermal-/visual image (in one image)"
  },
  {
    featureId: "EF012402",
    featureDesc: "Sleeve type",
    label: "EF012402: Sleeve type"
  },
  {
    featureId: "EF012403",
    featureDesc: "Head circumference",
    label: "EF012403: Head circumference"
  },
  {
    featureId: "EF012404",
    featureDesc: "Number of bindings",
    label: "EF012404: Number of bindings"
  },
  {
    featureId: "EF012405",
    featureDesc: "Increase image size",
    label: "EF012405: Increase image size"
  },
  {
    featureId: "EF012406",
    featureDesc: "With sunshade",
    label: "EF012406: With sunshade"
  },
  {
    featureId: "EF012407",
    featureDesc: "Size of facepiece",
    label: "EF012407: Size of facepiece"
  },
  {
    featureId: "EF012408",
    featureDesc: "Type of air circuit system",
    label: "EF012408: Type of air circuit system"
  },
  {
    featureId: "EF012409",
    featureDesc: "Material head harness",
    label: "EF012409: Material head harness"
  },
  {
    featureId: "EF012410",
    featureDesc: "With jacket",
    label: "EF012410: With jacket"
  },
  {
    featureId: "EF012411",
    featureDesc: "With trousers",
    label: "EF012411: With trousers"
  },
  {
    featureId: "EF012412",
    featureDesc: "With skirt",
    label: "EF012412: With skirt"
  },
  {
    featureId: "EF012413",
    featureDesc: "With vest",
    label: "EF012413: With vest"
  },
  {
    featureId: "EF012414",
    featureDesc: "Dispensing method",
    label: "EF012414: Dispensing method"
  },
  {
    featureId: "EF012415",
    featureDesc: "Sun protection factor",
    label: "EF012415: Sun protection factor"
  },
  {
    featureId: "EF012416",
    featureDesc: "Type of beverage",
    label: "EF012416: Type of beverage"
  },
  {
    featureId: "EF012418",
    featureDesc: "Sheet length",
    label: "EF012418: Sheet length"
  },
  {
    featureId: "EF012419",
    featureDesc: "Sheet width",
    label: "EF012419: Sheet width"
  },
  {
    featureId: "EF012420",
    featureDesc: "Stand loudspeaker",
    label: "EF012420: Stand loudspeaker"
  },
  {
    featureId: "EF012421",
    featureDesc: "Sound bar",
    label: "EF012421: Sound bar"
  },
  {
    featureId: "EF012422",
    featureDesc: "With digital alarm",
    label: "EF012422: With digital alarm"
  },
  {
    featureId: "EF012423",
    featureDesc: "Suitable for exchangeable lenses",
    label: "EF012423: Suitable for exchangeable lenses"
  },
  {
    featureId: "EF012424",
    featureDesc: "Motor power input (P1) per motor",
    label: "EF012424: Motor power input (P1) per motor"
  },
  {
    featureId: "EF012425",
    featureDesc: "Motor power output (P2) per motor",
    label: "EF012425: Motor power output (P2) per motor"
  },
  {
    featureId: "EF012428",
    featureDesc: "With camera control function",
    label: "EF012428: With camera control function"
  },
  {
    featureId: "EF012429",
    featureDesc: "With transcription function",
    label: "EF012429: With transcription function"
  },
  {
    featureId: "EF012430",
    featureDesc: "With microphone management function",
    label: "EF012430: With microphone management function"
  },
  {
    featureId: "EF012431",
    featureDesc: "With interpreter function",
    label: "EF012431: With interpreter function"
  },
  {
    featureId: "EF012432",
    featureDesc: "With message service",
    label: "EF012432: With message service"
  },
  {
    featureId: "EF012433",
    featureDesc: "With identification function",
    label: "EF012433: With identification function"
  },
  {
    featureId: "EF012434",
    featureDesc: "With streaming function",
    label: "EF012434: With streaming function"
  },
  {
    featureId: "EF012436",
    featureDesc: "Opening angle camera",
    label: "EF012436: Opening angle camera"
  },
  {
    featureId: "EF012437",
    featureDesc: "Zoom, pan/tilt function",
    label: "EF012437: Zoom, pan/tilt function"
  },
  {
    featureId: "EF012438",
    featureDesc: "Manual adjustable camera",
    label: "EF012438: Manual adjustable camera"
  },
  {
    featureId: "EF012439",
    featureDesc: "CompactFlash I card slot",
    label: "EF012439: CompactFlash I card slot"
  },
  {
    featureId: "EF012440",
    featureDesc: "CompactFlash II card slot",
    label: "EF012440: CompactFlash II card slot"
  },
  {
    featureId: "EF012441",
    featureDesc: "MiniSD-card slot",
    label: "EF012441: MiniSD-card slot"
  },
  {
    featureId: "EF012445",
    featureDesc: "Mounting method indoor station",
    label: "EF012445: Mounting method indoor station"
  },
  {
    featureId: "EF012446",
    featureDesc: "Mounting method outdoor station",
    label: "EF012446: Mounting method outdoor station"
  },
  {
    featureId: "EF012447",
    featureDesc: "Material outdoor station",
    label: "EF012447: Material outdoor station"
  },
  {
    featureId: "EF012448",
    featureDesc: "Expandable to max. number of indoor stations",
    label: "EF012448: Expandable to max. number of indoor stations"
  },
  {
    featureId: "EF012449",
    featureDesc: "Expandable to max. number of outdoor stations",
    label: "EF012449: Expandable to max. number of outdoor stations"
  },
  {
    featureId: "EF012450",
    featureDesc: "Can be connected to smartphone",
    label: "EF012450: Can be connected to smartphone"
  },
  {
    featureId: "EF012451",
    featureDesc: "Type of communication",
    label: "EF012451: Type of communication"
  },
  {
    featureId: "EF012452",
    featureDesc: "Single call function",
    label: "EF012452: Single call function"
  },
  {
    featureId: "EF012453",
    featureDesc: "Group call function",
    label: "EF012453: Group call function"
  },
  {
    featureId: "EF012454",
    featureDesc: "General call function",
    label: "EF012454: General call function"
  },
  {
    featureId: "EF012456",
    featureDesc: "Number of positions",
    label: "EF012456: Number of positions"
  },
  {
    featureId: "EF012457",
    featureDesc: "With VU meter",
    label: "EF012457: With VU meter"
  },
  {
    featureId: "EF012458",
    featureDesc: "With acoustic feedback suppression",
    label: "EF012458: With acoustic feedback suppression"
  },
  {
    featureId: "EF012459",
    featureDesc: "Max. number of supported devices",
    label: "EF012459: Max. number of supported devices"
  },
  {
    featureId: "EF012460",
    featureDesc: "Supports simultaneous interpretation",
    label: "EF012460: Supports simultaneous interpretation"
  },
  {
    featureId: "EF012461",
    featureDesc: "Column width",
    label: "EF012461: Column width"
  },
  {
    featureId: "EF012462",
    featureDesc: "Pipe fixing interior",
    label: "EF012462: Pipe fixing interior"
  },
  {
    featureId: "EF012463",
    featureDesc: "MPP power by STC",
    label: "EF012463: MPP power by STC"
  },
  {
    featureId: "EF012464",
    featureDesc: "Number of bypass diodes",
    label: "EF012464: Number of bypass diodes"
  },
  {
    featureId: "EF012465",
    featureDesc: "Temperature coefficient Isc",
    label: "EF012465: Temperature coefficient Isc"
  },
  {
    featureId: "EF012466",
    featureDesc: "Temperature coefficient Uoc",
    label: "EF012466: Temperature coefficient Uoc"
  },
  {
    featureId: "EF012467",
    featureDesc: "Temperature coefficient Pmpp",
    label: "EF012467: Temperature coefficient Pmpp"
  },
  {
    featureId: "EF012468",
    featureDesc: "Suitable for emergency power",
    label: "EF012468: Suitable for emergency power"
  },
  {
    featureId: "EF012470",
    featureDesc: "Number of grooving chisels",
    label: "EF012470: Number of grooving chisels"
  },
  {
    featureId: "EF012471",
    featureDesc: "Number of spade chisels",
    label: "EF012471: Number of spade chisels"
  },
  {
    featureId: "EF012473",
    featureDesc: "Suitable for magnetizing",
    label: "EF012473: Suitable for magnetizing"
  },
  {
    featureId: "EF012474",
    featureDesc: "Suitable for demagnetizing",
    label: "EF012474: Suitable for demagnetizing"
  },
  {
    featureId: "EF012475",
    featureDesc: "Tar rake",
    label: "EF012475: Tar rake"
  },
  {
    featureId: "EF012476",
    featureDesc: "Container volume fresh water",
    label: "EF012476: Container volume fresh water"
  },
  {
    featureId: "EF012482",
    featureDesc: "Redundant power supply",
    label: "EF012482: Redundant power supply"
  },
  {
    featureId: "EF012483",
    featureDesc: "Usable battery capacity",
    label: "EF012483: Usable battery capacity"
  },
  {
    featureId: "EF012484",
    featureDesc: "Max. depth of discharge",
    label: "EF012484: Max. depth of discharge"
  },
  {
    featureId: "EF012485",
    featureDesc: "Number of complete charge cycles",
    label: "EF012485: Number of complete charge cycles"
  },
  {
    featureId: "EF012486",
    featureDesc: "Pit saw",
    label: "EF012486: Pit saw"
  },
  {
    featureId: "EF012491",
    featureDesc: "Material holder/housing",
    label: "EF012491: Material holder/housing"
  },
  {
    featureId: "EF012492",
    featureDesc: "Orientation",
    label: "EF012492: Orientation"
  },
  {
    featureId: "EF012493",
    featureDesc: "Knee control lid",
    label: "EF012493: Knee control lid"
  },
  {
    featureId: "EF012494",
    featureDesc: "Can be hung in a wall holder",
    label: "EF012494: Can be hung in a wall holder"
  },
  {
    featureId: "EF012495",
    featureDesc: "With attachment points for accessories",
    label: "EF012495: With attachment points for accessories"
  },
  {
    featureId: "EF012496",
    featureDesc: "Flip up with spring support",
    label: "EF012496: Flip up with spring support"
  },
  {
    featureId: "EF012497",
    featureDesc: "Activating of toilet flushing",
    label: "EF012497: Activating of toilet flushing"
  },
  {
    featureId: "EF012498",
    featureDesc: "Triggering of emergency call",
    label: "EF012498: Triggering of emergency call"
  },
  {
    featureId: "EF012499",
    featureDesc: "Cut shape",
    label: "EF012499: Cut shape"
  },
  {
    featureId: "EF012500",
    featureDesc: "Cut number",
    label: "EF012500: Cut number"
  },
  {
    featureId: "EF012502",
    featureDesc: "RAL-number housing",
    label: "EF012502: RAL-number housing"
  },
  {
    featureId: "EF012503",
    featureDesc: "Moment of resistance Wy",
    label: "EF012503: Moment of resistance Wy"
  },
  {
    featureId: "EF012504",
    featureDesc: "Moment of resistance Wz",
    label: "EF012504: Moment of resistance Wz"
  },
  {
    featureId: "EF012507",
    featureDesc: "Surface structure front side panel",
    label: "EF012507: Surface structure front side panel"
  },
  {
    featureId: "EF012508",
    featureDesc: "With protective hose",
    label: "EF012508: With protective hose"
  },
  {
    featureId: "EF012509",
    featureDesc: "Belt width",
    label: "EF012509: Belt width"
  },
  {
    featureId: "EF012510",
    featureDesc: "According to EN 81-20",
    label: "EF012510: According to EN 81-20"
  },
  {
    featureId: "EF012511",
    featureDesc: "According to EN 115",
    label: "EF012511: According to EN 115"
  },
  {
    featureId: "EF012512",
    featureDesc: "Energy efficiency class according to ISO 25745",
    label: "EF012512: Energy efficiency class according to ISO 25745"
  },
  {
    featureId: "EF012513",
    featureDesc: "Area moment of inertia Iy",
    label: "EF012513: Area moment of inertia Iy"
  },
  {
    featureId: "EF012514",
    featureDesc: "Area of inertia Iz",
    label: "EF012514: Area of inertia Iz"
  },
  {
    featureId: "EF012515",
    featureDesc: "With optical drive",
    label: "EF012515: With optical drive"
  },
  {
    featureId: "EF012516",
    featureDesc: "Socket with 3 contacts",
    label: "EF012516: Socket with 3 contacts"
  },
  {
    featureId: "EF012517",
    featureDesc: "Suitable for round flashing luminaire",
    label: "EF012517: Suitable for round flashing luminaire"
  },
  {
    featureId: "EF012518",
    featureDesc: "Nominal operation time",
    label: "EF012518: Nominal operation time"
  },
  {
    featureId: "EF012519",
    featureDesc: "With soldering hole",
    label: "EF012519: With soldering hole"
  },
  {
    featureId: "EF012520",
    featureDesc: "Number of preinstalled hard discs",
    label: "EF012520: Number of preinstalled hard discs"
  },
  {
    featureId: "EF012521",
    featureDesc: "Number of hard disc bays",
    label: "EF012521: Number of hard disc bays"
  },
  {
    featureId: "EF012523",
    featureDesc: "Diameter round conductor 1",
    label: "EF012523: Diameter round conductor 1"
  },
  {
    featureId: "EF012524",
    featureDesc: "Width flat conductor 1",
    label: "EF012524: Width flat conductor 1"
  },
  {
    featureId: "EF012525",
    featureDesc: "Diameter round conductor 2",
    label: "EF012525: Diameter round conductor 2"
  },
  {
    featureId: "EF012526",
    featureDesc: "Width flat conductor 2",
    label: "EF012526: Width flat conductor 2"
  },
  {
    featureId: "EF012527",
    featureDesc: "For interior installations",
    label: "EF012527: For interior installations"
  },
  {
    featureId: "EF012528",
    featureDesc: "For outdoor installations",
    label: "EF012528: For outdoor installations"
  },
  {
    featureId: "EF012529",
    featureDesc: "Min. clamping range inch",
    label: "EF012529: Min. clamping range inch"
  },
  {
    featureId: "EF012530",
    featureDesc: "Max. clamping range inch",
    label: "EF012530: Max. clamping range inch"
  },
  {
    featureId: "EF012535",
    featureDesc: "Diameter earthing connection",
    label: "EF012535: Diameter earthing connection"
  },
  {
    featureId: "EF012536",
    featureDesc: "App control via Bluetooth",
    label: "EF012536: App control via Bluetooth"
  },
  {
    featureId: "EF012537",
    featureDesc: "With fixing clamps",
    label: "EF012537: With fixing clamps"
  },
  {
    featureId: "EF012538",
    featureDesc: "Insulated metal gripping lugs (IMGL)",
    label: "EF012538: Insulated metal gripping lugs (IMGL)"
  },
  {
    featureId: "EF012539",
    featureDesc: "Performance category according to IEC 60282-1",
    label: "EF012539: Performance category according to IEC 60282-1"
  },
  {
    featureId: "EF012540",
    featureDesc: "Type of termination",
    label: "EF012540: Type of termination"
  },
  {
    featureId: "EF012541",
    featureDesc: "Pick up current",
    label: "EF012541: Pick up current"
  },
  {
    featureId: "EF012542",
    featureDesc: "Number of current pulses",
    label: "EF012542: Number of current pulses"
  },
  {
    featureId: "EF012543",
    featureDesc: "Time-delay",
    label: "EF012543: Time-delay"
  },
  {
    featureId: "EF012544",
    featureDesc: "Vibration according to ISO 866211",
    label: "EF012544: Vibration according to ISO 866211"
  },
  {
    featureId: "EF012545",
    featureDesc: "With toilet seat",
    label: "EF012545: With toilet seat"
  },
  {
    featureId: "EF012546",
    featureDesc: "Vibration according to EN 60745",
    label: "EF012546: Vibration according to EN 60745"
  },
  {
    featureId: "EF012547",
    featureDesc: "Snapping in opening",
    label: "EF012547: Snapping in opening"
  },
  {
    featureId: "EF012548",
    featureDesc: "Magazine capacity",
    label: "EF012548: Magazine capacity"
  },
  {
    featureId: "EF012549",
    featureDesc: "Number of shots per battery charge",
    label: "EF012549: Number of shots per battery charge"
  },
  {
    featureId: "EF012550",
    featureDesc: "Number of shots per gas charge",
    label: "EF012550: Number of shots per gas charge"
  },
  {
    featureId: "EF012551",
    featureDesc: "Drywall screwdriver",
    label: "EF012551: Drywall screwdriver"
  },
  {
    featureId: "EF012552",
    featureDesc: "Suitable for public address system",
    label: "EF012552: Suitable for public address system"
  },
  {
    featureId: "EF012553",
    featureDesc: "Suitable for conference system",
    label: "EF012553: Suitable for conference system"
  },
  {
    featureId: "EF012554",
    featureDesc: "Energy type hob",
    label: "EF012554: Energy type hob"
  },
  {
    featureId: "EF012555",
    featureDesc: "Energy type baking oven",
    label: "EF012555: Energy type baking oven"
  },
  {
    featureId: "EF012556",
    featureDesc: "Heating technology of hobs",
    label: "EF012556: Heating technology of hobs"
  },
  {
    featureId: "EF012557",
    featureDesc: "Suitable for coiled screws",
    label: "EF012557: Suitable for coiled screws"
  },
  {
    featureId: "EF012558",
    featureDesc: "Sensor resolution (horizontal)",
    label: "EF012558: Sensor resolution (horizontal)"
  },
  {
    featureId: "EF012559",
    featureDesc: "Sensor resolution (vertical)",
    label: "EF012559: Sensor resolution (vertical)"
  },
  {
    featureId: "EF012561",
    featureDesc: "Display resolution (horizontal)",
    label: "EF012561: Display resolution (horizontal)"
  },
  {
    featureId: "EF012562",
    featureDesc: "Display resolution (vertical)",
    label: "EF012562: Display resolution (vertical)"
  },
  {
    featureId: "EF012563",
    featureDesc: "Impulse driver",
    label: "EF012563: Impulse driver"
  },
  {
    featureId: "EF012564",
    featureDesc: "Heat emission according to EN 442 20 °C - 70/40",
    label: "EF012564: Heat emission according to EN 442 20 °C - 70/40"
  },
  {
    featureId: "EF012565",
    featureDesc: "Suitable for nominal thread metric/PG",
    label: "EF012565: Suitable for nominal thread metric/PG"
  },
  {
    featureId: "EF012567",
    featureDesc: "Printer connection",
    label: "EF012567: Printer connection"
  },
  {
    featureId: "EF012569",
    featureDesc: "Max. presence range",
    label: "EF012569: Max. presence range"
  },
  {
    featureId: "EF012570",
    featureDesc: "Mounting in the soil",
    label: "EF012570: Mounting in the soil"
  },
  {
    featureId: "EF012571",
    featureDesc: "Mounting under water",
    label: "EF012571: Mounting under water"
  },
  {
    featureId: "EF012572",
    featureDesc: "Suitable for conductor material",
    label: "EF012572: Suitable for conductor material"
  },
  {
    featureId: "EF012573",
    featureDesc: "Calculation voltage drop",
    label: "EF012573: Calculation voltage drop"
  },
  {
    featureId: "EF012574",
    featureDesc: "Calculation switch off conditions",
    label: "EF012574: Calculation switch off conditions"
  },
  {
    featureId: "EF012575",
    featureDesc: "Symbols",
    label: "EF012575: Symbols"
  },
  {
    featureId: "EF012576",
    featureDesc: "With plug for AU/NZ/PG/AR (AS/NZS 3112) (type I)",
    label: "EF012576: With plug for AU/NZ/PG/AR (AS/NZS 3112) (type I)"
  },
  {
    featureId: "EF012579",
    featureDesc: "With plug for BE/DE/NL/RU (protective contact CEE 7/4, type F)",
    label: "EF012579: With plug for BE/DE/NL/RU (protective contact CEE 7/4, type F)"
  },
  {
    featureId: "EF012580",
    featureDesc: "With plug for FR (earth pin) (type E)",
    label: "EF012580: With plug for FR (earth pin) (type E)"
  },
  {
    featureId: "EF012581",
    featureDesc: "With plug for UK/HK (BS 1363) (type G)",
    label: "EF012581: With plug for UK/HK (BS 1363) (type G)"
  },
  {
    featureId: "EF012582",
    featureDesc: "With plug for IT (S/P 11)",
    label: "EF012582: With plug for IT (S/P 11)"
  },
  {
    featureId: "EF012583",
    featureDesc: "With plug for IT (S/P 17) (type L)",
    label: "EF012583: With plug for IT (S/P 17) (type L)"
  },
  {
    featureId: "EF012584",
    featureDesc: "With plug for US/CA/CN (NEMA 1-15) (type A)",
    label: "EF012584: With plug for US/CA/CN (NEMA 1-15) (type A)"
  },
  {
    featureId: "EF012585",
    featureDesc: "With plug for US/CA/CN (NEMA 5-15) (type B)",
    label: "EF012585: With plug for US/CA/CN (NEMA 5-15) (type B)"
  },
  {
    featureId: "EF012586",
    featureDesc: "Type of bus",
    label: "EF012586: Type of bus"
  },
  {
    featureId: "EF012587",
    featureDesc: "Disconnection characteristic",
    label: "EF012587: Disconnection characteristic"
  },
  {
    featureId: "EF012588",
    featureDesc: "Number of socket outlets Italian standard type P17",
    label: "EF012588: Number of socket outlets Italian standard type P17"
  },
  {
    featureId: "EF012590",
    featureDesc: "Diameter fibre bundle",
    label: "EF012590: Diameter fibre bundle"
  },
  {
    featureId: "EF012591",
    featureDesc: "Arched",
    label: "EF012591: Arched"
  },
  {
    featureId: "EF012592",
    featureDesc: "Conical tapering",
    label: "EF012592: Conical tapering"
  },
  {
    featureId: "EF012593",
    featureDesc: "With paging zone status LED",
    label: "EF012593: With paging zone status LED"
  },
  {
    featureId: "EF012594",
    featureDesc: "With water draining",
    label: "EF012594: With water draining"
  },
  {
    featureId: "EF012595",
    featureDesc: "Memory capacity per hard disc",
    label: "EF012595: Memory capacity per hard disc"
  },
  {
    featureId: "EF012596",
    featureDesc: "Nominal battery capacity",
    label: "EF012596: Nominal battery capacity"
  },
  {
    featureId: "EF012597",
    featureDesc: "RAL-number outside",
    label: "EF012597: RAL-number outside"
  },
  {
    featureId: "EF012598",
    featureDesc: "Measurement",
    label: "EF012598: Measurement"
  },
  {
    featureId: "EF012599",
    featureDesc: "Active part",
    label: "EF012599: Active part"
  },
  {
    featureId: "EF012600",
    featureDesc: "Cable plug",
    label: "EF012600: Cable plug"
  },
  {
    featureId: "EF012601",
    featureDesc: "Diaphragm",
    label: "EF012601: Diaphragm"
  },
  {
    featureId: "EF012602",
    featureDesc: "Suitable for thread size NPT",
    label: "EF012602: Suitable for thread size NPT"
  },
  {
    featureId: "EF012603",
    featureDesc: "Cell constant",
    label: "EF012603: Cell constant"
  },
  {
    featureId: "EF012604",
    featureDesc: "Material electrode",
    label: "EF012604: Material electrode"
  },
  {
    featureId: "EF012605",
    featureDesc: "Number of controller channels",
    label: "EF012605: Number of controller channels"
  },
  {
    featureId: "EF012606",
    featureDesc: "Measuring range effective power",
    label: "EF012606: Measuring range effective power"
  },
  {
    featureId: "EF012607",
    featureDesc: "Measuring range apparent power",
    label: "EF012607: Measuring range apparent power"
  },
  {
    featureId: "EF012608",
    featureDesc: "Type of guard locking",
    label: "EF012608: Type of guard locking"
  },
  {
    featureId: "EF012609",
    featureDesc: "Suitable for protective hoses",
    label: "EF012609: Suitable for protective hoses"
  },
  {
    featureId: "EF012610",
    featureDesc: "Version archive",
    label: "EF012610: Version archive"
  },
  {
    featureId: "EF012611",
    featureDesc: "Getting started",
    label: "EF012611: Getting started"
  },
  {
    featureId: "EF012612",
    featureDesc: "Twisted cores",
    label: "EF012612: Twisted cores"
  },
  {
    featureId: "EF012613",
    featureDesc: "Anti-fog glass",
    label: "EF012613: Anti-fog glass"
  },
  {
    featureId: "EF012614",
    featureDesc: "Self cleaning glass",
    label: "EF012614: Self cleaning glass"
  },
  {
    featureId: "EF012615",
    featureDesc: "Sound-damping glass",
    label: "EF012615: Sound-damping glass"
  },
  {
    featureId: "EF012616",
    featureDesc: "Fire resistant glass",
    label: "EF012616: Fire resistant glass"
  },
  {
    featureId: "EF012617",
    featureDesc: "Decorative glass",
    label: "EF012617: Decorative glass"
  },
  {
    featureId: "EF012618",
    featureDesc: "Applicable with hot tap water circuit",
    label: "EF012618: Applicable with hot tap water circuit"
  },
  {
    featureId: "EF012619",
    featureDesc: "Suitable for vertical/overhead glazing",
    label: "EF012619: Suitable for vertical/overhead glazing"
  },
  {
    featureId: "EF012620",
    featureDesc: "Material quality conductor",
    label: "EF012620: Material quality conductor"
  },
  {
    featureId: "EF012623",
    featureDesc: "Transient discharge current (10/350 µs)",
    label: "EF012623: Transient discharge current (10/350 µs)"
  },
  {
    featureId: "EF012624",
    featureDesc: "Transient discharge current (8/20 µs)",
    label: "EF012624: Transient discharge current (8/20 µs)"
  },
  {
    featureId: "EF012625",
    featureDesc: "Temporary discharge current to 1 s (16.7 Hz, 50 Hz, 60 Hz)",
    label: "EF012625: Temporary discharge current to 1 s (16.7 Hz, 50 Hz, 60 Hz)"
  },
  {
    featureId: "EF012626",
    featureDesc: "Max. long-duration limiting voltage",
    label: "EF012626: Max. long-duration limiting voltage"
  },
  {
    featureId: "EF012627",
    featureDesc: "Short-circuit breaking capacity (Isccr)",
    label: "EF012627: Short-circuit breaking capacity (Isccr)"
  },
  {
    featureId: "EF012628",
    featureDesc: "Rated short-circuit breaking capacity according to EN 61009",
    label: "EF012628: Rated short-circuit breaking capacity according to EN 61009"
  },
  {
    featureId: "EF012629",
    featureDesc: "Bevelled glass",
    label: "EF012629: Bevelled glass"
  },
  {
    featureId: "EF012630",
    featureDesc: "Oil-/fluid-filled",
    label: "EF012630: Oil-/fluid-filled"
  },
  {
    featureId: "EF012631",
    featureDesc: "Consumption indication",
    label: "EF012631: Consumption indication"
  },
  {
    featureId: "EF012632",
    featureDesc: "Open window detection",
    label: "EF012632: Open window detection"
  },
  {
    featureId: "EF012633",
    featureDesc: "Suitable for starter batteries",
    label: "EF012633: Suitable for starter batteries"
  },
  {
    featureId: "EF012634",
    featureDesc: "Suitable for industrial batteries",
    label: "EF012634: Suitable for industrial batteries"
  },
  {
    featureId: "EF012636",
    featureDesc: "Number of supported pilot wire commands",
    label: "EF012636: Number of supported pilot wire commands"
  },
  {
    featureId: "EF012637",
    featureDesc: "Suitable for bathtub",
    label: "EF012637: Suitable for bathtub"
  },
  {
    featureId: "EF012638",
    featureDesc: "Satellite reception",
    label: "EF012638: Satellite reception"
  },
  {
    featureId: "EF012639",
    featureDesc: "Terrestrial reception",
    label: "EF012639: Terrestrial reception"
  },
  {
    featureId: "EF012640",
    featureDesc: "Cable reception",
    label: "EF012640: Cable reception"
  },
  {
    featureId: "EF012641",
    featureDesc: "IP-TV reception",
    label: "EF012641: IP-TV reception"
  },
  {
    featureId: "EF012643",
    featureDesc: "Analogue reception",
    label: "EF012643: Analogue reception"
  },
  {
    featureId: "EF012644",
    featureDesc: "Digital reception",
    label: "EF012644: Digital reception"
  },
  {
    featureId: "EF012645",
    featureDesc: "Tele text function",
    label: "EF012645: Tele text function"
  },
  {
    featureId: "EF012646",
    featureDesc: "Glass with anti-reflection coating",
    label: "EF012646: Glass with anti-reflection coating"
  },
  {
    featureId: "EF012647",
    featureDesc: "Moment of resistance Wx",
    label: "EF012647: Moment of resistance Wx"
  },
  {
    featureId: "EF012648",
    featureDesc: "For frame height",
    label: "EF012648: For frame height"
  },
  {
    featureId: "EF012649",
    featureDesc: "Hook width",
    label: "EF012649: Hook width"
  },
  {
    featureId: "EF012650",
    featureDesc: "Material thickness hook",
    label: "EF012650: Material thickness hook"
  },
  {
    featureId: "EF012651",
    featureDesc: "Suitable for facade mounting",
    label: "EF012651: Suitable for facade mounting"
  },
  {
    featureId: "EF012652",
    featureDesc: "Suitable for pitched roof mounting",
    label: "EF012652: Suitable for pitched roof mounting"
  },
  {
    featureId: "EF012653",
    featureDesc: "Suitable for flat roof mounting",
    label: "EF012653: Suitable for flat roof mounting"
  },
  {
    featureId: "EF012654",
    featureDesc: "Suitable for open terrain mounting",
    label: "EF012654: Suitable for open terrain mounting"
  },
  {
    featureId: "EF012656",
    featureDesc: "Suitable for in-roof mounting",
    label: "EF012656: Suitable for in-roof mounting"
  },
  {
    featureId: "EF012657",
    featureDesc: "Roof perforation necessary",
    label: "EF012657: Roof perforation necessary"
  },
  {
    featureId: "EF012658",
    featureDesc: "Suitable for mounting as facade element",
    label: "EF012658: Suitable for mounting as facade element"
  },
  {
    featureId: "EF012659",
    featureDesc: "Suitable for mounting as overhang/sunshade",
    label: "EF012659: Suitable for mounting as overhang/sunshade"
  },
  {
    featureId: "EF012660",
    featureDesc: "Cable duct optional",
    label: "EF012660: Cable duct optional"
  },
  {
    featureId: "EF012662",
    featureDesc: "COP at 10/35 °C (W10/W35) according to EN 14511",
    label: "EF012662: COP at 10/35 °C (W10/W35) according to EN 14511"
  },
  {
    featureId: "EF012663",
    featureDesc: "Max. thread size (metric)",
    label: "EF012663: Max. thread size (metric)"
  },
  {
    featureId: "EF012664",
    featureDesc: "Stateful inspection throughput",
    label: "EF012664: Stateful inspection throughput"
  },
  {
    featureId: "EF012665",
    featureDesc: "Number of IPSec Site-to-Site VPN",
    label: "EF012665: Number of IPSec Site-to-Site VPN"
  },
  {
    featureId: "EF012666",
    featureDesc: "Number of VPN clients",
    label: "EF012666: Number of VPN clients"
  },
  {
    featureId: "EF012667",
    featureDesc: "Integrated Access Point",
    label: "EF012667: Integrated Access Point"
  },
  {
    featureId: "EF012668",
    featureDesc: "Threat defense",
    label: "EF012668: Threat defense"
  },
  {
    featureId: "EF012686",
    featureDesc: "Passive",
    label: "EF012686: Passive"
  },
  {
    featureId: "EF012689",
    featureDesc: "Max. number of Access Points supported",
    label: "EF012689: Max. number of Access Points supported"
  },
  {
    featureId: "EF012706",
    featureDesc: "Marked",
    label: "EF012706: Marked"
  },
  {
    featureId: "EF012707",
    featureDesc: "Suitable for live-line work",
    label: "EF012707: Suitable for live-line work"
  },
  {
    featureId: "EF012708",
    featureDesc: "Type of material certificate according to EN 10204",
    label: "EF012708: Type of material certificate according to EN 10204"
  },
  {
    featureId: "EF012711",
    featureDesc: "Material base",
    label: "EF012711: Material base"
  },
  {
    featureId: "EF012712",
    featureDesc: "Material lamp shade",
    label: "EF012712: Material lamp shade"
  },
  {
    featureId: "EF012714",
    featureDesc: "Number of colours lamp shade",
    label: "EF012714: Number of colours lamp shade"
  },
  {
    featureId: "EF012715",
    featureDesc: "Main colour lamp shade",
    label: "EF012715: Main colour lamp shade"
  },
  {
    featureId: "EF012716",
    featureDesc: "Secondary colour lamp shade",
    label: "EF012716: Secondary colour lamp shade"
  },
  {
    featureId: "EF012718",
    featureDesc: "Motif/pattern",
    label: "EF012718: Motif/pattern"
  },
  {
    featureId: "EF012719",
    featureDesc: "Anti-fouling function",
    label: "EF012719: Anti-fouling function"
  },
  {
    featureId: "EF012720",
    featureDesc: "Length bottom side",
    label: "EF012720: Length bottom side"
  },
  {
    featureId: "EF012721",
    featureDesc: "Diameter bottom side",
    label: "EF012721: Diameter bottom side"
  },
  {
    featureId: "EF012722",
    featureDesc: "Length top side",
    label: "EF012722: Length top side"
  },
  {
    featureId: "EF012723",
    featureDesc: "Width top side",
    label: "EF012723: Width top side"
  },
  {
    featureId: "EF012724",
    featureDesc: "Diameter top side",
    label: "EF012724: Diameter top side"
  },
  {
    featureId: "EF012725",
    featureDesc: "Suitable for radiator depth",
    label: "EF012725: Suitable for radiator depth"
  },
  {
    featureId: "EF012726",
    featureDesc: "Compressive strength short-term load",
    label: "EF012726: Compressive strength short-term load"
  },
  {
    featureId: "EF012727",
    featureDesc: "Compressive strength long-term load",
    label: "EF012727: Compressive strength long-term load"
  },
  {
    featureId: "EF012728",
    featureDesc: "Max. point load",
    label: "EF012728: Max. point load"
  },
  {
    featureId: "EF012729",
    featureDesc: "Thickness tolerance",
    label: "EF012729: Thickness tolerance"
  },
  {
    featureId: "EF012730",
    featureDesc: "Dimensional thermal conductivity in moisture protected building part",
    label: "EF012730: Dimensional thermal conductivity in moisture protected building part"
  },
  {
    featureId: "EF012731",
    featureDesc: "Dimensional thermal conductivity on external wall, towards ground",
    label: "EF012731: Dimensional thermal conductivity on external wall, towards ground"
  },
  {
    featureId: "EF012732",
    featureDesc: "Dimensional thermal conductivity horizontally in the ground",
    label: "EF012732: Dimensional thermal conductivity horizontally in the ground"
  },
  {
    featureId: "EF012733",
    featureDesc: "Applicable harmonized EN standard",
    label: "EF012733: Applicable harmonized EN standard"
  },
  {
    featureId: "EF012734",
    featureDesc: "Number of connections delivery side",
    label: "EF012734: Number of connections delivery side"
  },
  {
    featureId: "EF012735",
    featureDesc: "Number of connections suction side",
    label: "EF012735: Number of connections suction side"
  },
  {
    featureId: "EF012740",
    featureDesc: "With display backlight",
    label: "EF012740: With display backlight"
  },
  {
    featureId: "EF012741",
    featureDesc: "With emissivity correction",
    label: "EF012741: With emissivity correction"
  },
  {
    featureId: "EF012742",
    featureDesc: "Drop test height",
    label: "EF012742: Drop test height"
  },
  {
    featureId: "EF012743",
    featureDesc: "With measurement control force sensor",
    label: "EF012743: With measurement control force sensor"
  },
  {
    featureId: "EF012744",
    featureDesc: "With bearing condition assessment",
    label: "EF012744: With bearing condition assessment"
  },
  {
    featureId: "EF012745",
    featureDesc: "With machine vibration assessment",
    label: "EF012745: With machine vibration assessment"
  },
  {
    featureId: "EF012752",
    featureDesc: "Max. bearing capacity",
    label: "EF012752: Max. bearing capacity"
  },
  {
    featureId: "EF012753",
    featureDesc: "Max. tap capacity (at 300 kPa)",
    label: "EF012753: Max. tap capacity (at 300 kPa)"
  },
  {
    featureId: "EF012754",
    featureDesc: "Nominal power resistive load",
    label: "EF012754: Nominal power resistive load"
  },
  {
    featureId: "EF012755",
    featureDesc: "Nominal power inductive load",
    label: "EF012755: Nominal power inductive load"
  },
  {
    featureId: "EF012756",
    featureDesc: "Nominal power capacitive load",
    label: "EF012756: Nominal power capacitive load"
  },
  {
    featureId: "EF012757",
    featureDesc: "Height opening",
    label: "EF012757: Height opening"
  },
  {
    featureId: "EF012758",
    featureDesc: "Width opening",
    label: "EF012758: Width opening"
  },
  {
    featureId: "EF012760",
    featureDesc: "Mechanical fastening",
    label: "EF012760: Mechanical fastening"
  },
  {
    featureId: "EF012761",
    featureDesc: "Vacuum fastening",
    label: "EF012761: Vacuum fastening"
  },
  {
    featureId: "EF012762",
    featureDesc: "Fully adhered fastening",
    label: "EF012762: Fully adhered fastening"
  },
  {
    featureId: "EF012763",
    featureDesc: "Ballasted fastening",
    label: "EF012763: Ballasted fastening"
  },
  {
    featureId: "EF012764",
    featureDesc: "Exposed roofing",
    label: "EF012764: Exposed roofing"
  },
  {
    featureId: "EF012766",
    featureDesc: "External fireprotection according to EN 13501-5",
    label: "EF012766: External fireprotection according to EN 13501-5"
  },
  {
    featureId: "EF012767",
    featureDesc: "Extension method",
    label: "EF012767: Extension method"
  },
  {
    featureId: "EF012768",
    featureDesc: "Max. output current charger",
    label: "EF012768: Max. output current charger"
  },
  {
    featureId: "EF012769",
    featureDesc: "Max. output current alternator",
    label: "EF012769: Max. output current alternator"
  },
  {
    featureId: "EF012770",
    featureDesc: "With compensation diode",
    label: "EF012770: With compensation diode"
  },
  {
    featureId: "EF012771",
    featureDesc: "Frame kit",
    label: "EF012771: Frame kit"
  },
  {
    featureId: "EF012774",
    featureDesc: "For knuckle diameter",
    label: "EF012774: For knuckle diameter"
  },
  {
    featureId: "EF012775",
    featureDesc: "For drill-in hinge partition type",
    label: "EF012775: For drill-in hinge partition type"
  },
  {
    featureId: "EF012776",
    featureDesc: "With decorative head",
    label: "EF012776: With decorative head"
  },
  {
    featureId: "EF012777",
    featureDesc: "Colour touchpad",
    label: "EF012777: Colour touchpad"
  },
  {
    featureId: "EF012778",
    featureDesc: "Release according to EN 54-11",
    label: "EF012778: Release according to EN 54-11"
  },
  {
    featureId: "EF012781",
    featureDesc: "Suitable for spraying",
    label: "EF012781: Suitable for spraying"
  },
  {
    featureId: "EF012783",
    featureDesc: "Surface dry (at 23 °C, 50 % R.H.)",
    label: "EF012783: Surface dry (at 23 °C, 50 % R.H.)"
  },
  {
    featureId: "EF012784",
    featureDesc: "Paintable (at 23 °C, 50 % R.H.)",
    label: "EF012784: Paintable (at 23 °C, 50 % R.H.)"
  },
  {
    featureId: "EF012785",
    featureDesc: "Basic colour sealing profile",
    label: "EF012785: Basic colour sealing profile"
  },
  {
    featureId: "EF012786",
    featureDesc: "Material sealing profile",
    label: "EF012786: Material sealing profile"
  },
  {
    featureId: "EF012787",
    featureDesc: "Mounting method guide rail",
    label: "EF012787: Mounting method guide rail"
  },
  {
    featureId: "EF012788",
    featureDesc: "Suitable for sliding rod diameter",
    label: "EF012788: Suitable for sliding rod diameter"
  },
  {
    featureId: "EF012789",
    featureDesc: "Sliding rod mounting",
    label: "EF012789: Sliding rod mounting"
  },
  {
    featureId: "EF012790",
    featureDesc: "Width sealing profile",
    label: "EF012790: Width sealing profile"
  },
  {
    featureId: "EF012791",
    featureDesc: "Height sealing profile",
    label: "EF012791: Height sealing profile"
  },
  {
    featureId: "EF012793",
    featureDesc: "Set up for flush bolt",
    label: "EF012793: Set up for flush bolt"
  },
  {
    featureId: "EF012794",
    featureDesc: "Air circulation/air equalization",
    label: "EF012794: Air circulation/air equalization"
  },
  {
    featureId: "EF012795",
    featureDesc: "Max. acoustic insulation",
    label: "EF012795: Max. acoustic insulation"
  },
  {
    featureId: "EF012796",
    featureDesc: "Suitable for sound insulating doors according to DIN 4109",
    label: "EF012796: Suitable for sound insulating doors according to DIN 4109"
  },
  {
    featureId: "EF012797",
    featureDesc: "Suitable for radiation protection doors according to DIN 6834",
    label: "EF012797: Suitable for radiation protection doors according to DIN 6834"
  },
  {
    featureId: "EF012798",
    featureDesc: "Suitable for smoke shielding doors according to EN 1634-3",
    label: "EF012798: Suitable for smoke shielding doors according to EN 1634-3"
  },
  {
    featureId: "EF012799",
    featureDesc: "Suitable for fire-retardant doors according to EN 1634-1",
    label: "EF012799: Suitable for fire-retardant doors according to EN 1634-1"
  },
  {
    featureId: "EF012800",
    featureDesc: "Suitable for wet- and damp room doors according to EN 1670",
    label: "EF012800: Suitable for wet- and damp room doors according to EN 1670"
  },
  {
    featureId: "EF012801",
    featureDesc: "Suitable for barrier-free building",
    label: "EF012801: Suitable for barrier-free building"
  },
  {
    featureId: "EF012802",
    featureDesc: "Forend shape",
    label: "EF012802: Forend shape"
  },
  {
    featureId: "EF012803",
    featureDesc: "Recess of strike plate",
    label: "EF012803: Recess of strike plate"
  },
  {
    featureId: "EF012804",
    featureDesc: "Distance deadbolt to recess of latch",
    label: "EF012804: Distance deadbolt to recess of latch"
  },
  {
    featureId: "EF012806",
    featureDesc: "Prepared for door opener",
    label: "EF012806: Prepared for door opener"
  },
  {
    featureId: "EF012809",
    featureDesc: "Material of cover sheet",
    label: "EF012809: Material of cover sheet"
  },
  {
    featureId: "EF012810",
    featureDesc: "Leaf width recommended to",
    label: "EF012810: Leaf width recommended to"
  },
  {
    featureId: "EF012811",
    featureDesc: "Leaf weight recommended to",
    label: "EF012811: Leaf weight recommended to"
  },
  {
    featureId: "EF012812",
    featureDesc: "Min. leaf thickness",
    label: "EF012812: Min. leaf thickness"
  },
  {
    featureId: "EF012813",
    featureDesc: "Plug-in axle integrated",
    label: "EF012813: Plug-in axle integrated"
  },
  {
    featureId: "EF012814",
    featureDesc: "Cement box integrated",
    label: "EF012814: Cement box integrated"
  },
  {
    featureId: "EF012815",
    featureDesc: "Cement box adjustment range length",
    label: "EF012815: Cement box adjustment range length"
  },
  {
    featureId: "EF012816",
    featureDesc: "Cement box adjustment range width",
    label: "EF012816: Cement box adjustment range width"
  },
  {
    featureId: "EF012817",
    featureDesc: "Cement box adjustment range height",
    label: "EF012817: Cement box adjustment range height"
  },
  {
    featureId: "EF012818",
    featureDesc: "Closing force adjustable",
    label: "EF012818: Closing force adjustable"
  },
  {
    featureId: "EF012819",
    featureDesc: "Freeswing-function",
    label: "EF012819: Freeswing-function"
  },
  {
    featureId: "EF012820",
    featureDesc: "Type of hold open position",
    label: "EF012820: Type of hold open position"
  },
  {
    featureId: "EF012823",
    featureDesc: "Closing sequence control",
    label: "EF012823: Closing sequence control"
  },
  {
    featureId: "EF012826",
    featureDesc: "Application class according to EN 1154",
    label: "EF012826: Application class according to EN 1154"
  },
  {
    featureId: "EF012827",
    featureDesc: "Permanent operation according to EN 1154",
    label: "EF012827: Permanent operation according to EN 1154"
  },
  {
    featureId: "EF012828",
    featureDesc: "Min. size of closer according to EN 1154",
    label: "EF012828: Min. size of closer according to EN 1154"
  },
  {
    featureId: "EF012829",
    featureDesc: "Max. size of closer according to EN 1154",
    label: "EF012829: Max. size of closer according to EN 1154"
  },
  {
    featureId: "EF012830",
    featureDesc: "Fire and smoke protection according to EN 1154",
    label: "EF012830: Fire and smoke protection according to EN 1154"
  },
  {
    featureId: "EF012831",
    featureDesc: "Safety according to EN 1154",
    label: "EF012831: Safety according to EN 1154"
  },
  {
    featureId: "EF012832",
    featureDesc: "Corrosion resistance according to EN 1154",
    label: "EF012832: Corrosion resistance according to EN 1154"
  },
  {
    featureId: "EF012834",
    featureDesc: "Application class according to EN 1155",
    label: "EF012834: Application class according to EN 1155"
  },
  {
    featureId: "EF012835",
    featureDesc: "Permanent operation according to EN 1155",
    label: "EF012835: Permanent operation according to EN 1155"
  },
  {
    featureId: "EF012836",
    featureDesc: "Min. size of closer according to EN 1155",
    label: "EF012836: Min. size of closer according to EN 1155"
  },
  {
    featureId: "EF012837",
    featureDesc: "Max. size of closer according to EN 1155",
    label: "EF012837: Max. size of closer according to EN 1155"
  },
  {
    featureId: "EF012838",
    featureDesc: "Fire and smoke protection according to EN 1155",
    label: "EF012838: Fire and smoke protection according to EN 1155"
  },
  {
    featureId: "EF012839",
    featureDesc: "Safety according to EN 1155",
    label: "EF012839: Safety according to EN 1155"
  },
  {
    featureId: "EF012840",
    featureDesc: "Corrosion resistance according to EN 1155",
    label: "EF012840: Corrosion resistance according to EN 1155"
  },
  {
    featureId: "EF012841",
    featureDesc: "Basic colour of cover",
    label: "EF012841: Basic colour of cover"
  },
  {
    featureId: "EF012843",
    featureDesc: "Opening support",
    label: "EF012843: Opening support"
  },
  {
    featureId: "EF012844",
    featureDesc: "Delayed closing action",
    label: "EF012844: Delayed closing action"
  },
  {
    featureId: "EF012845",
    featureDesc: "Door leaf mounting",
    label: "EF012845: Door leaf mounting"
  },
  {
    featureId: "EF012846",
    featureDesc: "Transom mounting",
    label: "EF012846: Transom mounting"
  },
  {
    featureId: "EF012848",
    featureDesc: "Assembly height",
    label: "EF012848: Assembly height"
  },
  {
    featureId: "EF012849",
    featureDesc: "Connection of additional smoke alams",
    label: "EF012849: Connection of additional smoke alams"
  },
  {
    featureId: "EF012850",
    featureDesc: "Function as smoke alarm",
    label: "EF012850: Function as smoke alarm"
  },
  {
    featureId: "EF012851",
    featureDesc: "Function as trigger mechanism",
    label: "EF012851: Function as trigger mechanism"
  },
  {
    featureId: "EF012852",
    featureDesc: "Function as power supply",
    label: "EF012852: Function as power supply"
  },
  {
    featureId: "EF012853",
    featureDesc: "Soiling indicator",
    label: "EF012853: Soiling indicator"
  },
  {
    featureId: "EF012856",
    featureDesc: "Suitable according to EN 1158 and EN 1155",
    label: "EF012856: Suitable according to EN 1158 and EN 1155"
  },
  {
    featureId: "EF012857",
    featureDesc: "For flush impact door",
    label: "EF012857: For flush impact door"
  },
  {
    featureId: "EF012858",
    featureDesc: "For rebated door",
    label: "EF012858: For rebated door"
  },
  {
    featureId: "EF012860",
    featureDesc: "Type of axle",
    label: "EF012860: Type of axle"
  },
  {
    featureId: "EF012861",
    featureDesc: "Axis extension",
    label: "EF012861: Axis extension"
  },
  {
    featureId: "EF012862",
    featureDesc: "Type of bearing",
    label: "EF012862: Type of bearing"
  },
  {
    featureId: "EF012864",
    featureDesc: "Min. hinge distance",
    label: "EF012864: Min. hinge distance"
  },
  {
    featureId: "EF012865",
    featureDesc: "Min. width of fixed leaf",
    label: "EF012865: Min. width of fixed leaf"
  },
  {
    featureId: "EF012866",
    featureDesc: "Rebate space for guide rail",
    label: "EF012866: Rebate space for guide rail"
  },
  {
    featureId: "EF012868",
    featureDesc: "Application class according to EN 1158",
    label: "EF012868: Application class according to EN 1158"
  },
  {
    featureId: "EF012869",
    featureDesc: "Permanent operation according to EN 1158",
    label: "EF012869: Permanent operation according to EN 1158"
  },
  {
    featureId: "EF012870",
    featureDesc: "Min. size of closer according to EN 1158",
    label: "EF012870: Min. size of closer according to EN 1158"
  },
  {
    featureId: "EF012871",
    featureDesc: "Max. size of closer according to EN 1158",
    label: "EF012871: Max. size of closer according to EN 1158"
  },
  {
    featureId: "EF012872",
    featureDesc: "Fire and smoke protection according to EN 1158",
    label: "EF012872: Fire and smoke protection according to EN 1158"
  },
  {
    featureId: "EF012873",
    featureDesc: "Safety according to EN 1158",
    label: "EF012873: Safety according to EN 1158"
  },
  {
    featureId: "EF012874",
    featureDesc: "Corrosion resistance according to EN 1158",
    label: "EF012874: Corrosion resistance according to EN 1158"
  },
  {
    featureId: "EF012875",
    featureDesc: "Approved for full panic door",
    label: "EF012875: Approved for full panic door"
  },
  {
    featureId: "EF012877",
    featureDesc: "Max. leaf protrusion",
    label: "EF012877: Max. leaf protrusion"
  },
  {
    featureId: "EF012878",
    featureDesc: "Profile shortenable",
    label: "EF012878: Profile shortenable"
  },
  {
    featureId: "EF012886",
    featureDesc: "Suitable for wooden door",
    label: "EF012886: Suitable for wooden door"
  },
  {
    featureId: "EF012887",
    featureDesc: "Suitable for metal door",
    label: "EF012887: Suitable for metal door"
  },
  {
    featureId: "EF012888",
    featureDesc: "Suitable for steel door",
    label: "EF012888: Suitable for steel door"
  },
  {
    featureId: "EF012889",
    featureDesc: "Suitable for aluminium door",
    label: "EF012889: Suitable for aluminium door"
  },
  {
    featureId: "EF012890",
    featureDesc: "Suitable for glass door",
    label: "EF012890: Suitable for glass door"
  },
  {
    featureId: "EF012893",
    featureDesc: "Suitable for single-action door",
    label: "EF012893: Suitable for single-action door"
  },
  {
    featureId: "EF012897",
    featureDesc: "Groove width",
    label: "EF012897: Groove width"
  },
  {
    featureId: "EF012898",
    featureDesc: "Groove height",
    label: "EF012898: Groove height"
  },
  {
    featureId: "EF012899",
    featureDesc: "Type of sealing profile",
    label: "EF012899: Type of sealing profile"
  },
  {
    featureId: "EF012900",
    featureDesc: "Basic colour housing",
    label: "EF012900: Basic colour housing"
  },
  {
    featureId: "EF012901",
    featureDesc: "Sealing height",
    label: "EF012901: Sealing height"
  },
  {
    featureId: "EF012902",
    featureDesc: "Suitable for plastic door",
    label: "EF012902: Suitable for plastic door"
  },
  {
    featureId: "EF012904",
    featureDesc: "Square rod length",
    label: "EF012904: Square rod length"
  },
  {
    featureId: "EF012908",
    featureDesc: "Basic colour of door handle",
    label: "EF012908: Basic colour of door handle"
  },
  {
    featureId: "EF012911",
    featureDesc: "Type of keyhole",
    label: "EF012911: Type of keyhole"
  },
  {
    featureId: "EF012912",
    featureDesc: "Type of pipe connection",
    label: "EF012912: Type of pipe connection"
  },
  {
    featureId: "EF012917",
    featureDesc: "Positioning studs",
    label: "EF012917: Positioning studs"
  },
  {
    featureId: "EF012919",
    featureDesc: "Call transfer function",
    label: "EF012919: Call transfer function"
  },
  {
    featureId: "EF012921",
    featureDesc: "Height of outer backplate/escutcheon",
    label: "EF012921: Height of outer backplate/escutcheon"
  },
  {
    featureId: "EF012922",
    featureDesc: "Width of outer backplate/escutcheon",
    label: "EF012922: Width of outer backplate/escutcheon"
  },
  {
    featureId: "EF012923",
    featureDesc: "Thickness of outer backplate/escutcheon",
    label: "EF012923: Thickness of outer backplate/escutcheon"
  },
  {
    featureId: "EF012925",
    featureDesc: "Knob cranked",
    label: "EF012925: Knob cranked"
  },
  {
    featureId: "EF012926",
    featureDesc: "Knob rotating",
    label: "EF012926: Knob rotating"
  },
  {
    featureId: "EF012928",
    featureDesc: "Suitable according to EN 179",
    label: "EF012928: Suitable according to EN 179"
  },
  {
    featureId: "EF012930",
    featureDesc: "Use-/utilization category according to EN 1906",
    label: "EF012930: Use-/utilization category according to EN 1906"
  },
  {
    featureId: "EF012933",
    featureDesc: "Fire-resistance according to EN 1906",
    label: "EF012933: Fire-resistance according to EN 1906"
  },
  {
    featureId: "EF012936",
    featureDesc: "Anti-burglary protection according to EN 1906",
    label: "EF012936: Anti-burglary protection according to EN 1906"
  },
  {
    featureId: "EF012940",
    featureDesc: "Height backplate",
    label: "EF012940: Height backplate"
  },
  {
    featureId: "EF012941",
    featureDesc: "Width backplate",
    label: "EF012941: Width backplate"
  },
  {
    featureId: "EF012943",
    featureDesc: "Type of lock",
    label: "EF012943: Type of lock"
  },
  {
    featureId: "EF012945",
    featureDesc: "Actuation of lock",
    label: "EF012945: Actuation of lock"
  },
  {
    featureId: "EF012947",
    featureDesc: "Follower square",
    label: "EF012947: Follower square"
  },
  {
    featureId: "EF012948",
    featureDesc: "Door handle actuation feedback contact",
    label: "EF012948: Door handle actuation feedback contact"
  },
  {
    featureId: "EF012950",
    featureDesc: "Deadbolt throw (size)",
    label: "EF012950: Deadbolt throw (size)"
  },
  {
    featureId: "EF012951",
    featureDesc: "Deadbolt position",
    label: "EF012951: Deadbolt position"
  },
  {
    featureId: "EF012952",
    featureDesc: "Type of deadbolt",
    label: "EF012952: Type of deadbolt"
  },
  {
    featureId: "EF012953",
    featureDesc: "Material deadbolt",
    label: "EF012953: Material deadbolt"
  },
  {
    featureId: "EF012955",
    featureDesc: "Number of locking points",
    label: "EF012955: Number of locking points"
  },
  {
    featureId: "EF012956",
    featureDesc: "Mechanical lock",
    label: "EF012956: Mechanical lock"
  },
  {
    featureId: "EF012957",
    featureDesc: "Mechanical self-locking",
    label: "EF012957: Mechanical self-locking"
  },
  {
    featureId: "EF012958",
    featureDesc: "Motoric self-locking",
    label: "EF012958: Motoric self-locking"
  },
  {
    featureId: "EF012959",
    featureDesc: "Magnetic self-locking",
    label: "EF012959: Magnetic self-locking"
  },
  {
    featureId: "EF012960",
    featureDesc: "Mechanical unlock",
    label: "EF012960: Mechanical unlock"
  },
  {
    featureId: "EF012961",
    featureDesc: "Motoric unlocking",
    label: "EF012961: Motoric unlocking"
  },
  {
    featureId: "EF012963",
    featureDesc: "Latch throw",
    label: "EF012963: Latch throw"
  },
  {
    featureId: "EF012964",
    featureDesc: "Latch position",
    label: "EF012964: Latch position"
  },
  {
    featureId: "EF012965",
    featureDesc: "Type of latch",
    label: "EF012965: Type of latch"
  },
  {
    featureId: "EF012966",
    featureDesc: "Material of latch",
    label: "EF012966: Material of latch"
  },
  {
    featureId: "EF012968",
    featureDesc: "Latch holdback",
    label: "EF012968: Latch holdback"
  },
  {
    featureId: "EF012969",
    featureDesc: "Latch feedback-signal contact",
    label: "EF012969: Latch feedback-signal contact"
  },
  {
    featureId: "EF012970",
    featureDesc: "Type of follower",
    label: "EF012970: Type of follower"
  },
  {
    featureId: "EF012971",
    featureDesc: "Width forend",
    label: "EF012971: Width forend"
  },
  {
    featureId: "EF012972",
    featureDesc: "Type of forend",
    label: "EF012972: Type of forend"
  },
  {
    featureId: "EF012973",
    featureDesc: "Thickness forend",
    label: "EF012973: Thickness forend"
  },
  {
    featureId: "EF012974",
    featureDesc: "Length forend",
    label: "EF012974: Length forend"
  },
  {
    featureId: "EF012975",
    featureDesc: "Material of forend",
    label: "EF012975: Material of forend"
  },
  {
    featureId: "EF012976",
    featureDesc: "Basic colour forend",
    label: "EF012976: Basic colour forend"
  },
  {
    featureId: "EF012978",
    featureDesc: "Type of lock case",
    label: "EF012978: Type of lock case"
  },
  {
    featureId: "EF012982",
    featureDesc: "VdS certification",
    label: "EF012982: VdS certification"
  },
  {
    featureId: "EF012985",
    featureDesc: "Suitable for usage at smoke- and fire protection doors acc. to EN 15684:2013",
    label: "EF012985: Suitable for usage at smoke- and fire protection doors acc. to EN 15684:2013"
  },
  {
    featureId: "EF012989",
    featureDesc: "Number of auxiliary contacts",
    label: "EF012989: Number of auxiliary contacts"
  },
  {
    featureId: "EF012990",
    featureDesc: "Shift function",
    label: "EF012990: Shift function"
  },
  {
    featureId: "EF012991",
    featureDesc: "Number of lock cases",
    label: "EF012991: Number of lock cases"
  },
  {
    featureId: "EF012993",
    featureDesc: "Deadbolt-throw",
    label: "EF012993: Deadbolt-throw"
  },
  {
    featureId: "EF012994",
    featureDesc: "Deadbolt feedback-signal contact",
    label: "EF012994: Deadbolt feedback-signal contact"
  },
  {
    featureId: "EF012996",
    featureDesc: "Additional lock",
    label: "EF012996: Additional lock"
  },
  {
    featureId: "EF013004",
    featureDesc: "Utility category according to EN 12209",
    label: "EF013004: Utility category according to EN 12209"
  },
  {
    featureId: "EF013005",
    featureDesc: "Lasting functionability and load of latch according to EN 12209",
    label: "EF013005: Lasting functionability and load of latch according to EN 12209"
  },
  {
    featureId: "EF013008",
    featureDesc: "Safety - burglary protection according to EN 12209",
    label: "EF013008: Safety - burglary protection according to EN 12209"
  },
  {
    featureId: "EF013014",
    featureDesc: "With slot for fuses",
    label: "EF013014: With slot for fuses"
  },
  {
    featureId: "EF013016",
    featureDesc: "Material of escutcheon/backplate",
    label: "EF013016: Material of escutcheon/backplate"
  },
  {
    featureId: "EF013017",
    featureDesc: "Material of door handle",
    label: "EF013017: Material of door handle"
  },
  {
    featureId: "EF013018",
    featureDesc: "Basic colour of escutcheon/back plate",
    label: "EF013018: Basic colour of escutcheon/back plate"
  },
  {
    featureId: "EF013019",
    featureDesc: "Release of keep switching lock",
    label: "EF013019: Release of keep switching lock"
  },
  {
    featureId: "EF013023",
    featureDesc: "Mass of door, clamp force according to EN 12209",
    label: "EF013023: Mass of door, clamp force according to EN 12209"
  },
  {
    featureId: "EF013030",
    featureDesc: "Mounting type frame",
    label: "EF013030: Mounting type frame"
  },
  {
    featureId: "EF013031",
    featureDesc: "Mounting type wing",
    label: "EF013031: Mounting type wing"
  },
  {
    featureId: "EF013032",
    featureDesc: "Lying concealed",
    label: "EF013032: Lying concealed"
  },
  {
    featureId: "EF013034",
    featureDesc: "For steel door frame",
    label: "EF013034: For steel door frame"
  },
  {
    featureId: "EF013035",
    featureDesc: "For wooden door frame",
    label: "EF013035: For wooden door frame"
  },
  {
    featureId: "EF013036",
    featureDesc: "For plastic door frame",
    label: "EF013036: For plastic door frame"
  },
  {
    featureId: "EF013038",
    featureDesc: "For block door frame",
    label: "EF013038: For block door frame"
  },
  {
    featureId: "EF013039",
    featureDesc: "For mounting frame",
    label: "EF013039: For mounting frame"
  },
  {
    featureId: "EF013040",
    featureDesc: "For wooden wing",
    label: "EF013040: For wooden wing"
  },
  {
    featureId: "EF013041",
    featureDesc: "For metal wing",
    label: "EF013041: For metal wing"
  },
  {
    featureId: "EF013042",
    featureDesc: "For plastic wing",
    label: "EF013042: For plastic wing"
  },
  {
    featureId: "EF013046",
    featureDesc: "With brake function",
    label: "EF013046: With brake function"
  },
  {
    featureId: "EF013050",
    featureDesc: "Adjustment possibility",
    label: "EF013050: Adjustment possibility"
  },
  {
    featureId: "EF013051",
    featureDesc: "Max. weight of door for 2 hinges",
    label: "EF013051: Max. weight of door for 2 hinges"
  },
  {
    featureId: "EF013054",
    featureDesc: "Length roller",
    label: "EF013054: Length roller"
  },
  {
    featureId: "EF013067",
    featureDesc: "Number of door leafs",
    label: "EF013067: Number of door leafs"
  },
  {
    featureId: "EF013068",
    featureDesc: "Max. weight of door leaf",
    label: "EF013068: Max. weight of door leaf"
  },
  {
    featureId: "EF013069",
    featureDesc: "Max. thickness of door leaf",
    label: "EF013069: Max. thickness of door leaf"
  },
  {
    featureId: "EF013070",
    featureDesc: "With switch double-sided",
    label: "EF013070: With switch double-sided"
  },
  {
    featureId: "EF013071",
    featureDesc: "With motion sensor",
    label: "EF013071: With motion sensor"
  },
  {
    featureId: "EF013073",
    featureDesc: "With springing",
    label: "EF013073: With springing"
  },
  {
    featureId: "EF013074",
    featureDesc: "With retention",
    label: "EF013074: With retention"
  },
  {
    featureId: "EF013075",
    featureDesc: "Suitable for glass thickness",
    label: "EF013075: Suitable for glass thickness"
  },
  {
    featureId: "EF013076",
    featureDesc: "Setting method through NFC (Near Field Communication)",
    label: "EF013076: Setting method through NFC (Near Field Communication)"
  },
  {
    featureId: "EF013077",
    featureDesc: "Setting method directly on product",
    label: "EF013077: Setting method directly on product"
  },
  {
    featureId: "EF013078",
    featureDesc: "Setting method through hand programming tool",
    label: "EF013078: Setting method through hand programming tool"
  },
  {
    featureId: "EF013079",
    featureDesc: "Setting method through PC-tool",
    label: "EF013079: Setting method through PC-tool"
  },
  {
    featureId: "EF013080",
    featureDesc: "Type of safety function",
    label: "EF013080: Type of safety function"
  },
  {
    featureId: "EF013081",
    featureDesc: "Principle of operation safety function",
    label: "EF013081: Principle of operation safety function"
  },
  {
    featureId: "EF013082",
    featureDesc: "Energy consumption in operation",
    label: "EF013082: Energy consumption in operation"
  },
  {
    featureId: "EF013083",
    featureDesc: "Energy consumption at rest",
    label: "EF013083: Energy consumption at rest"
  },
  {
    featureId: "EF013084",
    featureDesc: "Suitable for framed door",
    label: "EF013084: Suitable for framed door"
  },
  {
    featureId: "EF013086",
    featureDesc: "Material of roller",
    label: "EF013086: Material of roller"
  },
  {
    featureId: "EF013087",
    featureDesc: "With suspension screw",
    label: "EF013087: With suspension screw"
  },
  {
    featureId: "EF013096",
    featureDesc: "Drilling dimension",
    label: "EF013096: Drilling dimension"
  },
  {
    featureId: "EF013097",
    featureDesc: "Height of flange",
    label: "EF013097: Height of flange"
  },
  {
    featureId: "EF013098",
    featureDesc: "Swinging movement",
    label: "EF013098: Swinging movement"
  },
  {
    featureId: "EF013100",
    featureDesc: "Integrated axis extension",
    label: "EF013100: Integrated axis extension"
  },
  {
    featureId: "EF013101",
    featureDesc: "Opening limitation",
    label: "EF013101: Opening limitation"
  },
  {
    featureId: "EF013102",
    featureDesc: "Tested according to EN 54-5",
    label: "EF013102: Tested according to EN 54-5"
  },
  {
    featureId: "EF013103",
    featureDesc: "Compliant with EN 14637",
    label: "EF013103: Compliant with EN 14637"
  },
  {
    featureId: "EF013104",
    featureDesc: "For casing door frame",
    label: "EF013104: For casing door frame"
  },
  {
    featureId: "EF013106",
    featureDesc: "Conversion from",
    label: "EF013106: Conversion from"
  },
  {
    featureId: "EF013107",
    featureDesc: "Conversion to",
    label: "EF013107: Conversion to"
  },
  {
    featureId: "EF013108",
    featureDesc: "Cross section",
    label: "EF013108: Cross section"
  },
  {
    featureId: "EF013109",
    featureDesc: "Length of stepped part",
    label: "EF013109: Length of stepped part"
  },
  {
    featureId: "EF013110",
    featureDesc: "With clamping spring",
    label: "EF013110: With clamping spring"
  },
  {
    featureId: "EF013111",
    featureDesc: "Prepared for threaded pin",
    label: "EF013111: Prepared for threaded pin"
  },
  {
    featureId: "EF013113",
    featureDesc: "With expanding pin",
    label: "EF013113: With expanding pin"
  },
  {
    featureId: "EF013126",
    featureDesc: "Suitable for inwards opening",
    label: "EF013126: Suitable for inwards opening"
  },
  {
    featureId: "EF013127",
    featureDesc: "Suitable for outwards opening",
    label: "EF013127: Suitable for outwards opening"
  },
  {
    featureId: "EF013128",
    featureDesc: "Suitable for single fold",
    label: "EF013128: Suitable for single fold"
  },
  {
    featureId: "EF013129",
    featureDesc: "Suitable for double fold",
    label: "EF013129: Suitable for double fold"
  },
  {
    featureId: "EF013133",
    featureDesc: "Thermal separation",
    label: "EF013133: Thermal separation"
  },
  {
    featureId: "EF013135",
    featureDesc: "Driving rain impermeability according to EN 12208",
    label: "EF013135: Driving rain impermeability according to EN 12208"
  },
  {
    featureId: "EF013141",
    featureDesc: "Material of cylinder",
    label: "EF013141: Material of cylinder"
  },
  {
    featureId: "EF013143",
    featureDesc: "Emergency function",
    label: "EF013143: Emergency function"
  },
  {
    featureId: "EF013144",
    featureDesc: "Suitable for lock system",
    label: "EF013144: Suitable for lock system"
  },
  {
    featureId: "EF013145",
    featureDesc: "Outer construction length",
    label: "EF013145: Outer construction length"
  },
  {
    featureId: "EF013146",
    featureDesc: "Inner construction length",
    label: "EF013146: Inner construction length"
  },
  {
    featureId: "EF013148",
    featureDesc: "Locking nose",
    label: "EF013148: Locking nose"
  },
  {
    featureId: "EF013149",
    featureDesc: "Adjustable locking nose",
    label: "EF013149: Adjustable locking nose"
  },
  {
    featureId: "EF013150",
    featureDesc: "Anti-drilling protection",
    label: "EF013150: Anti-drilling protection"
  },
  {
    featureId: "EF013152",
    featureDesc: "Mechanical key type",
    label: "EF013152: Mechanical key type"
  },
  {
    featureId: "EF013153",
    featureDesc: "Electronic key type",
    label: "EF013153: Electronic key type"
  },
  {
    featureId: "EF013154",
    featureDesc: "Networkable via Wi-Fi",
    label: "EF013154: Networkable via Wi-Fi"
  },
  {
    featureId: "EF013155",
    featureDesc: "Networkable via Bluetooth",
    label: "EF013155: Networkable via Bluetooth"
  },
  {
    featureId: "EF013156",
    featureDesc: "Networkable via RFID",
    label: "EF013156: Networkable via RFID"
  },
  {
    featureId: "EF013157",
    featureDesc: "Utility category according to EN 15684:2013",
    label: "EF013157: Utility category according to EN 15684:2013"
  },
  {
    featureId: "EF013158",
    featureDesc: "Durability according to EN 15684:2013",
    label: "EF013158: Durability according to EN 15684:2013"
  },
  {
    featureId: "EF013159",
    featureDesc: "Environmental resistance according to EN 15684:2013",
    label: "EF013159: Environmental resistance according to EN 15684:2013"
  },
  {
    featureId: "EF013160",
    featureDesc: "Mechanical wear resistance according to EN 15684:2013",
    label: "EF013160: Mechanical wear resistance according to EN 15684:2013"
  },
  {
    featureId: "EF013161",
    featureDesc: "Electrical wear resistance according to EN 15684:2013",
    label: "EF013161: Electrical wear resistance according to EN 15684:2013"
  },
  {
    featureId: "EF013162",
    featureDesc: "System Management according to EN 15684:2013",
    label: "EF013162: System Management according to EN 15684:2013"
  },
  {
    featureId: "EF013163",
    featureDesc: "Intrusion resistance according to EN 15684:2013",
    label: "EF013163: Intrusion resistance according to EN 15684:2013"
  },
  {
    featureId: "EF013164",
    featureDesc: "Max. leaf height",
    label: "EF013164: Max. leaf height"
  },
  {
    featureId: "EF013165",
    featureDesc: "Viewing safety",
    label: "EF013165: Viewing safety"
  },
  {
    featureId: "EF013166",
    featureDesc: "Height of cutout",
    label: "EF013166: Height of cutout"
  },
  {
    featureId: "EF013167",
    featureDesc: "Width of cutout",
    label: "EF013167: Width of cutout"
  },
  {
    featureId: "EF013168",
    featureDesc: "Air passage",
    label: "EF013168: Air passage"
  },
  {
    featureId: "EF013169",
    featureDesc: "With insect protection",
    label: "EF013169: With insect protection"
  },
  {
    featureId: "EF013172",
    featureDesc: "Suitable for food environment",
    label: "EF013172: Suitable for food environment"
  },
  {
    featureId: "EF013173",
    featureDesc: "Alarm trigger",
    label: "EF013173: Alarm trigger"
  },
  {
    featureId: "EF013175",
    featureDesc: "With pre-alarm",
    label: "EF013175: With pre-alarm"
  },
  {
    featureId: "EF013177",
    featureDesc: "Approved according to EN 179",
    label: "EF013177: Approved according to EN 179"
  },
  {
    featureId: "EF013179",
    featureDesc: "With individual approval",
    label: "EF013179: With individual approval"
  },
  {
    featureId: "EF013180",
    featureDesc: "With permanent approval",
    label: "EF013180: With permanent approval"
  },
  {
    featureId: "EF013181",
    featureDesc: "Number of rails",
    label: "EF013181: Number of rails"
  },
  {
    featureId: "EF013184",
    featureDesc: "Oarlock length",
    label: "EF013184: Oarlock length"
  },
  {
    featureId: "EF013185",
    featureDesc: "Oarlock distance",
    label: "EF013185: Oarlock distance"
  },
  {
    featureId: "EF013188",
    featureDesc: "Material of suspension",
    label: "EF013188: Material of suspension"
  },
  {
    featureId: "EF013191",
    featureDesc: "Snap ring",
    label: "EF013191: Snap ring"
  },
  {
    featureId: "EF013192",
    featureDesc: "Type of running rail",
    label: "EF013192: Type of running rail"
  },
  {
    featureId: "EF013193",
    featureDesc: "Length of running rail",
    label: "EF013193: Length of running rail"
  },
  {
    featureId: "EF013194",
    featureDesc: "Type of guide rail",
    label: "EF013194: Type of guide rail"
  },
  {
    featureId: "EF013195",
    featureDesc: "Length of guide rail",
    label: "EF013195: Length of guide rail"
  },
  {
    featureId: "EF013196",
    featureDesc: "Running position",
    label: "EF013196: Running position"
  },
  {
    featureId: "EF013198",
    featureDesc: "Damping",
    label: "EF013198: Damping"
  },
  {
    featureId: "EF013199",
    featureDesc: "Multi-leaf opening type",
    label: "EF013199: Multi-leaf opening type"
  },
  {
    featureId: "EF013200",
    featureDesc: "Height-adjustable door leaf",
    label: "EF013200: Height-adjustable door leaf"
  },
  {
    featureId: "EF013201",
    featureDesc: "Laterally-adjustable door leaf",
    label: "EF013201: Laterally-adjustable door leaf"
  },
  {
    featureId: "EF013202",
    featureDesc: "Min. door leaf width",
    label: "EF013202: Min. door leaf width"
  },
  {
    featureId: "EF013204",
    featureDesc: "Duration of functionality according to EN 1527",
    label: "EF013204: Duration of functionality according to EN 1527"
  },
  {
    featureId: "EF013205",
    featureDesc: "Door mass according to EN 1527",
    label: "EF013205: Door mass according to EN 1527"
  },
  {
    featureId: "EF013207",
    featureDesc: "Safety according to EN 1527",
    label: "EF013207: Safety according to EN 1527"
  },
  {
    featureId: "EF013208",
    featureDesc: "Corrosion resistance according to EN 1527",
    label: "EF013208: Corrosion resistance according to EN 1527"
  },
  {
    featureId: "EF013210",
    featureDesc: "Type of door according to EN 1527",
    label: "EF013210: Type of door according to EN 1527"
  },
  {
    featureId: "EF013211",
    featureDesc: "Initial friction according to EN 1527",
    label: "EF013211: Initial friction according to EN 1527"
  },
  {
    featureId: "EF013212",
    featureDesc: "With guide rail",
    label: "EF013212: With guide rail"
  },
  {
    featureId: "EF013213",
    featureDesc: "Recessed mounting",
    label: "EF013213: Recessed mounting"
  },
  {
    featureId: "EF013216",
    featureDesc: "Width of centre strike patch",
    label: "EF013216: Width of centre strike patch"
  },
  {
    featureId: "EF013217",
    featureDesc: "Height of centre strike patch",
    label: "EF013217: Height of centre strike patch"
  },
  {
    featureId: "EF013218",
    featureDesc: "Depth of centre strike patch",
    label: "EF013218: Depth of centre strike patch"
  },
  {
    featureId: "EF013219",
    featureDesc: "Material of centre strike patch",
    label: "EF013219: Material of centre strike patch"
  },
  {
    featureId: "EF013220",
    featureDesc: "Basic colour of centre strike patch",
    label: "EF013220: Basic colour of centre strike patch"
  },
  {
    featureId: "EF013221",
    featureDesc: "Depth of hinge",
    label: "EF013221: Depth of hinge"
  },
  {
    featureId: "EF013222",
    featureDesc: "Height of hinge",
    label: "EF013222: Height of hinge"
  },
  {
    featureId: "EF013226",
    featureDesc: "Square cross section",
    label: "EF013226: Square cross section"
  },
  {
    featureId: "EF013228",
    featureDesc: "Sliding pin",
    label: "EF013228: Sliding pin"
  },
  {
    featureId: "EF013229",
    featureDesc: "Door handle length",
    label: "EF013229: Door handle length"
  },
  {
    featureId: "EF013230",
    featureDesc: "Door handle depth",
    label: "EF013230: Door handle depth"
  },
  {
    featureId: "EF013231",
    featureDesc: "Cylinder cover",
    label: "EF013231: Cylinder cover"
  },
  {
    featureId: "EF013232",
    featureDesc: "Drill protection",
    label: "EF013232: Drill protection"
  },
  {
    featureId: "EF013236",
    featureDesc: "Accessory",
    label: "EF013236: Accessory"
  },
  {
    featureId: "EF013237",
    featureDesc: "Spare part",
    label: "EF013237: Spare part"
  },
  {
    featureId: "EF013238",
    featureDesc: "Screw thread",
    label: "EF013238: Screw thread"
  },
  {
    featureId: "EF013239",
    featureDesc: "Screw length",
    label: "EF013239: Screw length"
  },
  {
    featureId: "EF013246",
    featureDesc: "Fan diameter",
    label: "EF013246: Fan diameter"
  },
  {
    featureId: "EF013247",
    featureDesc: "Type of window",
    label: "EF013247: Type of window"
  },
  {
    featureId: "EF013248",
    featureDesc: "Locking spring",
    label: "EF013248: Locking spring"
  },
  {
    featureId: "EF013249",
    featureDesc: "Locking with",
    label: "EF013249: Locking with"
  },
  {
    featureId: "EF013250",
    featureDesc: "Opening with",
    label: "EF013250: Opening with"
  },
  {
    featureId: "EF013251",
    featureDesc: "Gap size",
    label: "EF013251: Gap size"
  },
  {
    featureId: "EF013252",
    featureDesc: "Max. fold thickness",
    label: "EF013252: Max. fold thickness"
  },
  {
    featureId: "EF013255",
    featureDesc: "With blocking bracket",
    label: "EF013255: With blocking bracket"
  },
  {
    featureId: "EF013256",
    featureDesc: "With profile cylinder",
    label: "EF013256: With profile cylinder"
  },
  {
    featureId: "EF013257",
    featureDesc: "Max. door leaf width",
    label: "EF013257: Max. door leaf width"
  },
  {
    featureId: "EF013262",
    featureDesc: "Max. sound level (at a distance of 10 metres)",
    label: "EF013262: Max. sound level (at a distance of 10 metres)"
  },
  {
    featureId: "EF013263",
    featureDesc: "Connection of fluid pipe",
    label: "EF013263: Connection of fluid pipe"
  },
  {
    featureId: "EF013264",
    featureDesc: "Connection suction pipe",
    label: "EF013264: Connection suction pipe"
  },
  {
    featureId: "EF013265",
    featureDesc: "Suction gas temperature",
    label: "EF013265: Suction gas temperature"
  },
  {
    featureId: "EF013266",
    featureDesc: "Type of slider",
    label: "EF013266: Type of slider"
  },
  {
    featureId: "EF013268",
    featureDesc: "Discharge of bolt",
    label: "EF013268: Discharge of bolt"
  },
  {
    featureId: "EF013269",
    featureDesc: "Bolt width",
    label: "EF013269: Bolt width"
  },
  {
    featureId: "EF013271",
    featureDesc: "Loop width",
    label: "EF013271: Loop width"
  },
  {
    featureId: "EF013272",
    featureDesc: "Loop length",
    label: "EF013272: Loop length"
  },
  {
    featureId: "EF013273",
    featureDesc: "Diameter cylinder",
    label: "EF013273: Diameter cylinder"
  },
  {
    featureId: "EF013275",
    featureDesc: "Max. output current at 40 °C",
    label: "EF013275: Max. output current at 40 °C"
  },
  {
    featureId: "EF013277",
    featureDesc: "With cover flap",
    label: "EF013277: With cover flap"
  },
  {
    featureId: "EF013278",
    featureDesc: "Climate class according to EN 12219",
    label: "EF013278: Climate class according to EN 12219"
  },
  {
    featureId: "EF013281",
    featureDesc: "Lock thickness",
    label: "EF013281: Lock thickness"
  },
  {
    featureId: "EF013290",
    featureDesc: "Protection (controlling) inwards",
    label: "EF013290: Protection (controlling) inwards"
  },
  {
    featureId: "EF013291",
    featureDesc: "Protection (controlling) outwards",
    label: "EF013291: Protection (controlling) outwards"
  },
  {
    featureId: "EF013292",
    featureDesc: "Fixing screw",
    label: "EF013292: Fixing screw"
  },
  {
    featureId: "EF013293",
    featureDesc: "Thread size push handle",
    label: "EF013293: Thread size push handle"
  },
  {
    featureId: "EF013296",
    featureDesc: "Fixing points per set",
    label: "EF013296: Fixing points per set"
  },
  {
    featureId: "EF013298",
    featureDesc: "Axial dimension",
    label: "EF013298: Axial dimension"
  },
  {
    featureId: "EF013299",
    featureDesc: "Shape of push handle",
    label: "EF013299: Shape of push handle"
  },
  {
    featureId: "EF013300",
    featureDesc: "Material of push handle",
    label: "EF013300: Material of push handle"
  },
  {
    featureId: "EF013301",
    featureDesc: "Basic colour of push handle",
    label: "EF013301: Basic colour of push handle"
  },
  {
    featureId: "EF013303",
    featureDesc: "Width of handle bar",
    label: "EF013303: Width of handle bar"
  },
  {
    featureId: "EF013304",
    featureDesc: "Thickness of handle bar",
    label: "EF013304: Thickness of handle bar"
  },
  {
    featureId: "EF013305",
    featureDesc: "Material of support",
    label: "EF013305: Material of support"
  },
  {
    featureId: "EF013306",
    featureDesc: "Shape of support",
    label: "EF013306: Shape of support"
  },
  {
    featureId: "EF013309",
    featureDesc: "Camera integrated",
    label: "EF013309: Camera integrated"
  },
  {
    featureId: "EF013310",
    featureDesc: "Output connection",
    label: "EF013310: Output connection"
  },
  {
    featureId: "EF013311",
    featureDesc: "Suitable for power supply",
    label: "EF013311: Suitable for power supply"
  },
  {
    featureId: "EF013312",
    featureDesc: "Suitable for temperature compensated loading",
    label: "EF013312: Suitable for temperature compensated loading"
  },
  {
    featureId: "EF013314",
    featureDesc: "Push handle with fixing",
    label: "EF013314: Push handle with fixing"
  },
  {
    featureId: "EF013315",
    featureDesc: "Shape of handle bar",
    label: "EF013315: Shape of handle bar"
  },
  {
    featureId: "EF013321",
    featureDesc: "Square spindle cross section",
    label: "EF013321: Square spindle cross section"
  },
  {
    featureId: "EF013332",
    featureDesc: "Support shape",
    label: "EF013332: Support shape"
  },
  {
    featureId: "EF013335",
    featureDesc: "Holding force",
    label: "EF013335: Holding force"
  },
  {
    featureId: "EF013337",
    featureDesc: "Approved for fire- and smoke protection doors",
    label: "EF013337: Approved for fire- and smoke protection doors"
  },
  {
    featureId: "EF013338",
    featureDesc: "Smoke detector integrated",
    label: "EF013338: Smoke detector integrated"
  },
  {
    featureId: "EF013339",
    featureDesc: "Smoke switch control unit integrated",
    label: "EF013339: Smoke switch control unit integrated"
  },
  {
    featureId: "EF013340",
    featureDesc: "Type of WLAN controller",
    label: "EF013340: Type of WLAN controller"
  },
  {
    featureId: "EF013341",
    featureDesc: "Type of license",
    label: "EF013341: Type of license"
  },
  {
    featureId: "EF013345",
    featureDesc: "Release force adjustable",
    label: "EF013345: Release force adjustable"
  },
  {
    featureId: "EF013346",
    featureDesc: "Hold open position overridable",
    label: "EF013346: Hold open position overridable"
  },
  {
    featureId: "EF013347",
    featureDesc: "Opening limitation adjustable",
    label: "EF013347: Opening limitation adjustable"
  },
  {
    featureId: "EF013355",
    featureDesc: "Drill depth",
    label: "EF013355: Drill depth"
  },
  {
    featureId: "EF013356",
    featureDesc: "Groove depth",
    label: "EF013356: Groove depth"
  },
  {
    featureId: "EF013357",
    featureDesc: "Length of hollow",
    label: "EF013357: Length of hollow"
  },
  {
    featureId: "EF013358",
    featureDesc: "Width of hollow",
    label: "EF013358: Width of hollow"
  },
  {
    featureId: "EF013360",
    featureDesc: "Certified according to EN 179",
    label: "EF013360: Certified according to EN 179"
  },
  {
    featureId: "EF013361",
    featureDesc: "Certified according to EN 1125",
    label: "EF013361: Certified according to EN 1125"
  },
  {
    featureId: "EF013362",
    featureDesc: "Spring cover",
    label: "EF013362: Spring cover"
  },
  {
    featureId: "EF013363",
    featureDesc: "Type of outer flap",
    label: "EF013363: Type of outer flap"
  },
  {
    featureId: "EF013364",
    featureDesc: "Width door cut-out",
    label: "EF013364: Width door cut-out"
  },
  {
    featureId: "EF013365",
    featureDesc: "Height door cut-out",
    label: "EF013365: Height door cut-out"
  },
  {
    featureId: "EF013367",
    featureDesc: "Width of case",
    label: "EF013367: Width of case"
  },
  {
    featureId: "EF013368",
    featureDesc: "Depth of case",
    label: "EF013368: Depth of case"
  },
  {
    featureId: "EF013369",
    featureDesc: "Box height",
    label: "EF013369: Box height"
  },
  {
    featureId: "EF013370",
    featureDesc: "Volume of case",
    label: "EF013370: Volume of case"
  },
  {
    featureId: "EF013371",
    featureDesc: "Removal",
    label: "EF013371: Removal"
  },
  {
    featureId: "EF013372",
    featureDesc: "Type of render frame",
    label: "EF013372: Type of render frame"
  },
  {
    featureId: "EF013373",
    featureDesc: "Suitable for multiple mail box mounting",
    label: "EF013373: Suitable for multiple mail box mounting"
  },
  {
    featureId: "EF013374",
    featureDesc: "With package compartment",
    label: "EF013374: With package compartment"
  },
  {
    featureId: "EF013375",
    featureDesc: "With post retaining bracket",
    label: "EF013375: With post retaining bracket"
  },
  {
    featureId: "EF013376",
    featureDesc: "With protruding rain angle",
    label: "EF013376: With protruding rain angle"
  },
  {
    featureId: "EF013377",
    featureDesc: "With PIN authorizations for collector",
    label: "EF013377: With PIN authorizations for collector"
  },
  {
    featureId: "EF013379",
    featureDesc: "Mounting position",
    label: "EF013379: Mounting position"
  },
  {
    featureId: "EF013383",
    featureDesc: "For max. crack of the door",
    label: "EF013383: For max. crack of the door"
  },
  {
    featureId: "EF013386",
    featureDesc: "Supporting protocol for MP (multi point)",
    label: "EF013386: Supporting protocol for MP (multi point)"
  },
  {
    featureId: "EF013388",
    featureDesc: "Operating time",
    label: "EF013388: Operating time"
  },
  {
    featureId: "EF013389",
    featureDesc: "Efficiency exhaust filter according to ISO 16890-1:2016",
    label: "EF013389: Efficiency exhaust filter according to ISO 16890-1:2016"
  },
  {
    featureId: "EF013390",
    featureDesc: "Exhaust filter according to ISO 16890-1:2016",
    label: "EF013390: Exhaust filter according to ISO 16890-1:2016"
  },
  {
    featureId: "EF013391",
    featureDesc: "Supply filter according to ISO 16890-1:2016",
    label: "EF013391: Supply filter according to ISO 16890-1:2016"
  },
  {
    featureId: "EF013396",
    featureDesc: "Fire-resisting",
    label: "EF013396: Fire-resisting"
  },
  {
    featureId: "EF013400",
    featureDesc: "Depth of tunnel",
    label: "EF013400: Depth of tunnel"
  },
  {
    featureId: "EF013401",
    featureDesc: "Width of flap",
    label: "EF013401: Width of flap"
  },
  {
    featureId: "EF013402",
    featureDesc: "Height of flap",
    label: "EF013402: Height of flap"
  },
  {
    featureId: "EF013408",
    featureDesc: "Basic colour of stopper",
    label: "EF013408: Basic colour of stopper"
  },
  {
    featureId: "EF013409",
    featureDesc: "Pull-off force",
    label: "EF013409: Pull-off force"
  },
  {
    featureId: "EF013411",
    featureDesc: "Buffer with suspension",
    label: "EF013411: Buffer with suspension"
  },
  {
    featureId: "EF013412",
    featureDesc: "With stopping buffer",
    label: "EF013412: With stopping buffer"
  },
  {
    featureId: "EF013413",
    featureDesc: "Hole for door viewer",
    label: "EF013413: Hole for door viewer"
  },
  {
    featureId: "EF013414",
    featureDesc: "Impact buttom",
    label: "EF013414: Impact buttom"
  },
  {
    featureId: "EF013415",
    featureDesc: "Suitable for one point attachment",
    label: "EF013415: Suitable for one point attachment"
  },
  {
    featureId: "EF013416",
    featureDesc: "Number of control inputs",
    label: "EF013416: Number of control inputs"
  },
  {
    featureId: "EF013417",
    featureDesc: "Number of control outputs",
    label: "EF013417: Number of control outputs"
  },
  {
    featureId: "EF013421",
    featureDesc: "Alarm control external",
    label: "EF013421: Alarm control external"
  },
  {
    featureId: "EF013422",
    featureDesc: "Alarm sound",
    label: "EF013422: Alarm sound"
  },
  {
    featureId: "EF013423",
    featureDesc: "Connectable external signal device",
    label: "EF013423: Connectable external signal device"
  },
  {
    featureId: "EF013424",
    featureDesc: "Connectable external current supply",
    label: "EF013424: Connectable external current supply"
  },
  {
    featureId: "EF013425",
    featureDesc: "Control terminal for bidirectional escape route",
    label: "EF013425: Control terminal for bidirectional escape route"
  },
  {
    featureId: "EF013428",
    featureDesc: "Photoluminescent colour",
    label: "EF013428: Photoluminescent colour"
  },
  {
    featureId: "EF013429",
    featureDesc: "Door leaf mounting",
    label: "EF013429: Door leaf mounting"
  },
  {
    featureId: "EF013430",
    featureDesc: "Framed door mounting",
    label: "EF013430: Framed door mounting"
  },
  {
    featureId: "EF013431",
    featureDesc: "Glass door mounting",
    label: "EF013431: Glass door mounting"
  },
  {
    featureId: "EF013432",
    featureDesc: "Mesh door mounting",
    label: "EF013432: Mesh door mounting"
  },
  {
    featureId: "EF013433",
    featureDesc: "Fence system mounting",
    label: "EF013433: Fence system mounting"
  },
  {
    featureId: "EF013434",
    featureDesc: "Fire protection door mounting",
    label: "EF013434: Fire protection door mounting"
  },
  {
    featureId: "EF013440",
    featureDesc: "Material armouring",
    label: "EF013440: Material armouring"
  },
  {
    featureId: "EF013442",
    featureDesc: "Nominal equivalent cross section of reinforcement",
    label: "EF013442: Nominal equivalent cross section of reinforcement"
  },
  {
    featureId: "EF013443",
    featureDesc: "Suitable for metal door frame",
    label: "EF013443: Suitable for metal door frame"
  },
  {
    featureId: "EF013444",
    featureDesc: "Min. upper door frame gap",
    label: "EF013444: Min. upper door frame gap"
  },
  {
    featureId: "EF013446",
    featureDesc: "Max. leaf weight",
    label: "EF013446: Max. leaf weight"
  },
  {
    featureId: "EF013447",
    featureDesc: "Adjustable door opener latch",
    label: "EF013447: Adjustable door opener latch"
  },
  {
    featureId: "EF013448",
    featureDesc: "Adjustable door opener",
    label: "EF013448: Adjustable door opener"
  },
  {
    featureId: "EF013449",
    featureDesc: "Latch guide integrated",
    label: "EF013449: Latch guide integrated"
  },
  {
    featureId: "EF013450",
    featureDesc: "For latch bolt slide",
    label: "EF013450: For latch bolt slide"
  },
  {
    featureId: "EF013451",
    featureDesc: "Latch spring",
    label: "EF013451: Latch spring"
  },
  {
    featureId: "EF013452",
    featureDesc: "Suitable for fire protection door",
    label: "EF013452: Suitable for fire protection door"
  },
  {
    featureId: "EF013453",
    featureDesc: "Suitable for smoke protection door",
    label: "EF013453: Suitable for smoke protection door"
  },
  {
    featureId: "EF013454",
    featureDesc: "Resistance against break up",
    label: "EF013454: Resistance against break up"
  },
  {
    featureId: "EF013455",
    featureDesc: "Deadbolt outcut",
    label: "EF013455: Deadbolt outcut"
  },
  {
    featureId: "EF013456",
    featureDesc: "For security application",
    label: "EF013456: For security application"
  },
  {
    featureId: "EF013457",
    featureDesc: "For bolt switch contact prepared",
    label: "EF013457: For bolt switch contact prepared"
  },
  {
    featureId: "EF013460",
    featureDesc: "Adjustable strike plate",
    label: "EF013460: Adjustable strike plate"
  },
  {
    featureId: "EF013462",
    featureDesc: "Continuous operation (100 % ED)",
    label: "EF013462: Continuous operation (100 % ED)"
  },
  {
    featureId: "EF013464",
    featureDesc: "Material strike plate",
    label: "EF013464: Material strike plate"
  },
  {
    featureId: "EF013465",
    featureDesc: "Basic colour of strike plate",
    label: "EF013465: Basic colour of strike plate"
  },
  {
    featureId: "EF013467",
    featureDesc: "Length of strike plate",
    label: "EF013467: Length of strike plate"
  },
  {
    featureId: "EF013468",
    featureDesc: "Width of strike plate",
    label: "EF013468: Width of strike plate"
  },
  {
    featureId: "EF013469",
    featureDesc: "Thickness of strike plate",
    label: "EF013469: Thickness of strike plate"
  },
  {
    featureId: "EF013470",
    featureDesc: "Utility category according to EN 14846",
    label: "EF013470: Utility category according to EN 14846"
  },
  {
    featureId: "EF013471",
    featureDesc: "Lasting functionability according to EN 14846",
    label: "EF013471: Lasting functionability according to EN 14846"
  },
  {
    featureId: "EF013472",
    featureDesc: "Mass of door, clamp force according to EN 14846",
    label: "EF013472: Mass of door, clamp force according to EN 14846"
  },
  {
    featureId: "EF013473",
    featureDesc: "Suitable for fire- and smoke protection doors according to EN 14846",
    label: "EF013473: Suitable for fire- and smoke protection doors according to EN 14846"
  },
  {
    featureId: "EF013474",
    featureDesc: "Safety - personal protection according to EN 14846",
    label: "EF013474: Safety - personal protection according to EN 14846"
  },
  {
    featureId: "EF013475",
    featureDesc: "Environmental condition according to EN 14846",
    label: "EF013475: Environmental condition according to EN 14846"
  },
  {
    featureId: "EF013476",
    featureDesc: "Safety - burglary protection according to EN 14846",
    label: "EF013476: Safety - burglary protection according to EN 14846"
  },
  {
    featureId: "EF013477",
    featureDesc: "Protection effect electric operating mode according to EN 14846",
    label: "EF013477: Protection effect electric operating mode according to EN 14846"
  },
  {
    featureId: "EF013478",
    featureDesc: "Protection effect mechanic operating mode according to EN 14846",
    label: "EF013478: Protection effect mechanic operating mode according to EN 14846"
  },
  {
    featureId: "EF013492",
    featureDesc: "Stop sprung",
    label: "EF013492: Stop sprung"
  },
  {
    featureId: "EF013495",
    featureDesc: "Electricity consumption",
    label: "EF013495: Electricity consumption"
  },
  {
    featureId: "EF013496",
    featureDesc: "Condensation temperature",
    label: "EF013496: Condensation temperature"
  },
  {
    featureId: "EF013497",
    featureDesc: "Subcooling",
    label: "EF013497: Subcooling"
  },
  {
    featureId: "EF013498",
    featureDesc: "Compression method",
    label: "EF013498: Compression method"
  },
  {
    featureId: "EF013499",
    featureDesc: "Oil content",
    label: "EF013499: Oil content"
  },
  {
    featureId: "EF013500",
    featureDesc: "Outer pipe diameter delivery side",
    label: "EF013500: Outer pipe diameter delivery side"
  },
  {
    featureId: "EF013501",
    featureDesc: "Fall ratio",
    label: "EF013501: Fall ratio"
  },
  {
    featureId: "EF013503",
    featureDesc: "Smart control",
    label: "EF013503: Smart control"
  },
  {
    featureId: "EF013504",
    featureDesc: "Number of patch cords",
    label: "EF013504: Number of patch cords"
  },
  {
    featureId: "EF013505",
    featureDesc: "Suitable for additional outlet connections",
    label: "EF013505: Suitable for additional outlet connections"
  },
  {
    featureId: "EF013506",
    featureDesc: "Suitable for multiplexing",
    label: "EF013506: Suitable for multiplexing"
  },
  {
    featureId: "EF013507",
    featureDesc: "With designated space for ISP equipments",
    label: "EF013507: With designated space for ISP equipments"
  },
  {
    featureId: "EF013508",
    featureDesc: "Line reception/connection",
    label: "EF013508: Line reception/connection"
  },
  {
    featureId: "EF013509",
    featureDesc: "With Wi-Fi signal repeater",
    label: "EF013509: With Wi-Fi signal repeater"
  },
  {
    featureId: "EF013510",
    featureDesc: "Product fully equipped",
    label: "EF013510: Product fully equipped"
  },
  {
    featureId: "EF013511",
    featureDesc: "Nominal diameter connection overflow",
    label: "EF013511: Nominal diameter connection overflow"
  },
  {
    featureId: "EF013512",
    featureDesc: "Connection overflow",
    label: "EF013512: Connection overflow"
  },
  {
    featureId: "EF013513",
    featureDesc: "Connection, expansion connection",
    label: "EF013513: Connection, expansion connection"
  },
  {
    featureId: "EF013514",
    featureDesc: "Type of transceiver",
    label: "EF013514: Type of transceiver"
  },
  {
    featureId: "EF013515",
    featureDesc: "PoE standard",
    label: "EF013515: PoE standard"
  },
  {
    featureId: "EF013517",
    featureDesc: "Integrated microphone",
    label: "EF013517: Integrated microphone"
  },
  {
    featureId: "EF013519",
    featureDesc: "With monitor",
    label: "EF013519: With monitor"
  },
  {
    featureId: "EF013521",
    featureDesc: "Video standard",
    label: "EF013521: Video standard"
  },
  {
    featureId: "EF013522",
    featureDesc: "Resolution video input",
    label: "EF013522: Resolution video input"
  },
  {
    featureId: "EF013523",
    featureDesc: "Resolution video output",
    label: "EF013523: Resolution video output"
  },
  {
    featureId: "EF013524",
    featureDesc: "For number of cameras",
    label: "EF013524: For number of cameras"
  },
  {
    featureId: "EF013525",
    featureDesc: "Suitable for door/window/details",
    label: "EF013525: Suitable for door/window/details"
  },
  {
    featureId: "EF013532",
    featureDesc: "Cable geometry",
    label: "EF013532: Cable geometry"
  },
  {
    featureId: "EF013533",
    featureDesc: "Suitable for wooden terrace",
    label: "EF013533: Suitable for wooden terrace"
  },
  {
    featureId: "EF013534",
    featureDesc: "Suitable for masonry/concrete",
    label: "EF013534: Suitable for masonry/concrete"
  },
  {
    featureId: "EF013535",
    featureDesc: "Suitable for roof",
    label: "EF013535: Suitable for roof"
  },
  {
    featureId: "EF013537",
    featureDesc: "Connection, air-release",
    label: "EF013537: Connection, air-release"
  },
  {
    featureId: "EF013538",
    featureDesc: "Material protective cover",
    label: "EF013538: Material protective cover"
  },
  {
    featureId: "EF013540",
    featureDesc: "Min. processing temperature",
    label: "EF013540: Min. processing temperature"
  },
  {
    featureId: "EF013544",
    featureDesc: "Escape mask",
    label: "EF013544: Escape mask"
  },
  {
    featureId: "EF013545",
    featureDesc: "Recommended consumption even surface",
    label: "EF013545: Recommended consumption even surface"
  },
  {
    featureId: "EF013546",
    featureDesc: "Recommended consumption uneven surface",
    label: "EF013546: Recommended consumption uneven surface"
  },
  {
    featureId: "EF013548",
    featureDesc: "Technology/generic type",
    label: "EF013548: Technology/generic type"
  },
  {
    featureId: "EF013549",
    featureDesc: "Diameter heater connection",
    label: "EF013549: Diameter heater connection"
  },
  {
    featureId: "EF013551",
    featureDesc: "Conductor resistance at 20 °C",
    label: "EF013551: Conductor resistance at 20 °C"
  },
  {
    featureId: "EF013552",
    featureDesc: "With roof lead-through",
    label: "EF013552: With roof lead-through"
  },
  {
    featureId: "EF013553",
    featureDesc: "Number of connections, LED traffic lights",
    label: "EF013553: Number of connections, LED traffic lights"
  },
  {
    featureId: "EF013554",
    featureDesc: "Number of connections, push buttons",
    label: "EF013554: Number of connections, push buttons"
  },
  {
    featureId: "EF013555",
    featureDesc: "Number of manually operated detectors",
    label: "EF013555: Number of manually operated detectors"
  },
  {
    featureId: "EF013556",
    featureDesc: "Controllers can be connected",
    label: "EF013556: Controllers can be connected"
  },
  {
    featureId: "EF013557",
    featureDesc: "Max. number of doors per controller",
    label: "EF013557: Max. number of doors per controller"
  },
  {
    featureId: "EF013558",
    featureDesc: "Expandable to max. number of controllers",
    label: "EF013558: Expandable to max. number of controllers"
  },
  {
    featureId: "EF013559",
    featureDesc: "Number of programmable inputs",
    label: "EF013559: Number of programmable inputs"
  },
  {
    featureId: "EF013560",
    featureDesc: "Contour code connection 1",
    label: "EF013560: Contour code connection 1"
  },
  {
    featureId: "EF013561",
    featureDesc: "Contour code connection 2",
    label: "EF013561: Contour code connection 2"
  },
  {
    featureId: "EF013562",
    featureDesc: "Contour code connection 3",
    label: "EF013562: Contour code connection 3"
  },
  {
    featureId: "EF013566",
    featureDesc: "Max. total length",
    label: "EF013566: Max. total length"
  },
  {
    featureId: "EF013567",
    featureDesc: "Suitable for type of refrigerant R32",
    label: "EF013567: Suitable for type of refrigerant R32"
  },
  {
    featureId: "EF013568",
    featureDesc: "Suitable for type of refrigerant R404a",
    label: "EF013568: Suitable for type of refrigerant R404a"
  },
  {
    featureId: "EF013569",
    featureDesc: "Suitable for type of refrigerant R134a",
    label: "EF013569: Suitable for type of refrigerant R134a"
  },
  {
    featureId: "EF013570",
    featureDesc: "Suitable for type of refrigerant R507",
    label: "EF013570: Suitable for type of refrigerant R507"
  },
  {
    featureId: "EF013571",
    featureDesc: "Suitable for type of refrigerant R717",
    label: "EF013571: Suitable for type of refrigerant R717"
  },
  {
    featureId: "EF013572",
    featureDesc: "Suitable for type of refrigerant R744 (CO2)",
    label: "EF013572: Suitable for type of refrigerant R744 (CO2)"
  },
  {
    featureId: "EF013573",
    featureDesc: "Total cooling capacity at 0/-8 °C, R404a",
    label: "EF013573: Total cooling capacity at 0/-8 °C, R404a"
  },
  {
    featureId: "EF013575",
    featureDesc: "Macro lens",
    label: "EF013575: Macro lens"
  },
  {
    featureId: "EF013576",
    featureDesc: "Lens mount",
    label: "EF013576: Lens mount"
  },
  {
    featureId: "EF013577",
    featureDesc: "Pattern repeat",
    label: "EF013577: Pattern repeat"
  },
  {
    featureId: "EF013579",
    featureDesc: "Suitable for type of refrigerant R22",
    label: "EF013579: Suitable for type of refrigerant R22"
  },
  {
    featureId: "EF013580",
    featureDesc: "Suitable for type of refrigerant R290",
    label: "EF013580: Suitable for type of refrigerant R290"
  },
  {
    featureId: "EF013581",
    featureDesc: "Suitable for type of refrigerant R401a",
    label: "EF013581: Suitable for type of refrigerant R401a"
  },
  {
    featureId: "EF013582",
    featureDesc: "Suitable for type of refrigerant R402a",
    label: "EF013582: Suitable for type of refrigerant R402a"
  },
  {
    featureId: "EF013583",
    featureDesc: "Suitable for type of refrigerant R407c",
    label: "EF013583: Suitable for type of refrigerant R407c"
  },
  {
    featureId: "EF013584",
    featureDesc: "Suitable for type of refrigerant R413a",
    label: "EF013584: Suitable for type of refrigerant R413a"
  },
  {
    featureId: "EF013585",
    featureDesc: "Suitable for type of refrigerant R417a",
    label: "EF013585: Suitable for type of refrigerant R417a"
  },
  {
    featureId: "EF013586",
    featureDesc: "Total cooling capacity at 10/0 °C, R404a",
    label: "EF013586: Total cooling capacity at 10/0 °C, R404a"
  },
  {
    featureId: "EF013588",
    featureDesc: "Total cooling capacity at -18/-25 °C, R404a",
    label: "EF013588: Total cooling capacity at -18/-25 °C, R404a"
  },
  {
    featureId: "EF013589",
    featureDesc: "Total cooling capacity +40/0 °C, R-404a",
    label: "EF013589: Total cooling capacity +40/0 °C, R-404a"
  },
  {
    featureId: "EF013590",
    featureDesc: "Total cooling capacity 32 °C dT15K, R404a",
    label: "EF013590: Total cooling capacity 32 °C dT15K, R404a"
  },
  {
    featureId: "EF013591",
    featureDesc: "Total cooling capacity 32 °C dT10K, R404a",
    label: "EF013591: Total cooling capacity 32 °C dT10K, R404a"
  },
  {
    featureId: "EF013598",
    featureDesc: "String protection",
    label: "EF013598: String protection"
  },
  {
    featureId: "EF013599",
    featureDesc: "Fuse size input",
    label: "EF013599: Fuse size input"
  },
  {
    featureId: "EF013603",
    featureDesc: "Surface protection wire",
    label: "EF013603: Surface protection wire"
  },
  {
    featureId: "EF013608",
    featureDesc: "Monitoring function",
    label: "EF013608: Monitoring function"
  },
  {
    featureId: "EF013609",
    featureDesc: "Min. mounting height (exhaust height)",
    label: "EF013609: Min. mounting height (exhaust height)"
  },
  {
    featureId: "EF013611",
    featureDesc: "With storage",
    label: "EF013611: With storage"
  },
  {
    featureId: "EF013612",
    featureDesc: "With drain plug",
    label: "EF013612: With drain plug"
  },
  {
    featureId: "EF013613",
    featureDesc: "Thickness including studs",
    label: "EF013613: Thickness including studs"
  },
  {
    featureId: "EF013614",
    featureDesc: "EPS type according to EN 13163",
    label: "EF013614: EPS type according to EN 13163"
  },
  {
    featureId: "EF013615",
    featureDesc: "Foil thickness",
    label: "EF013615: Foil thickness"
  },
  {
    featureId: "EF013617",
    featureDesc: "Type of lifting gear",
    label: "EF013617: Type of lifting gear"
  },
  {
    featureId: "EF013618",
    featureDesc: "With lifting rod",
    label: "EF013618: With lifting rod"
  },
  {
    featureId: "EF013619",
    featureDesc: "Suitable for kitchen sink",
    label: "EF013619: Suitable for kitchen sink"
  },
  {
    featureId: "EF013620",
    featureDesc: "Max. unloading current",
    label: "EF013620: Max. unloading current"
  },
  {
    featureId: "EF013621",
    featureDesc: "Suitable for shower tray",
    label: "EF013621: Suitable for shower tray"
  },
  {
    featureId: "EF013623",
    featureDesc: "Length to bottom side head",
    label: "EF013623: Length to bottom side head"
  },
  {
    featureId: "EF013624",
    featureDesc: "Type of cross head",
    label: "EF013624: Type of cross head"
  },
  {
    featureId: "EF013627",
    featureDesc: "With integrated flushing reservoir",
    label: "EF013627: With integrated flushing reservoir"
  },
  {
    featureId: "EF013628",
    featureDesc: "With safety toecap",
    label: "EF013628: With safety toecap"
  },
  {
    featureId: "EF013629",
    featureDesc: "Material safety toe",
    label: "EF013629: Material safety toe"
  },
  {
    featureId: "EF013630",
    featureDesc: "With internal carbon dioxide (CO2) sensor",
    label: "EF013630: With internal carbon dioxide (CO2) sensor"
  },
  {
    featureId: "EF013631",
    featureDesc: "Suitable for use with lifting gear",
    label: "EF013631: Suitable for use with lifting gear"
  },
  {
    featureId: "EF013632",
    featureDesc: "Material upper part",
    label: "EF013632: Material upper part"
  },
  {
    featureId: "EF013633",
    featureDesc: "Colour upper part",
    label: "EF013633: Colour upper part"
  },
  {
    featureId: "EF013634",
    featureDesc: "Operating wavelength",
    label: "EF013634: Operating wavelength"
  },
  {
    featureId: "EF013635",
    featureDesc: "Connector type input",
    label: "EF013635: Connector type input"
  },
  {
    featureId: "EF013636",
    featureDesc: "Connector type output",
    label: "EF013636: Connector type output"
  },
  {
    featureId: "EF013637",
    featureDesc: "Max. insertion loss",
    label: "EF013637: Max. insertion loss"
  },
  {
    featureId: "EF013638",
    featureDesc: "Min. return loss",
    label: "EF013638: Min. return loss"
  },
  {
    featureId: "EF013639",
    featureDesc: "Max. uniformity loss",
    label: "EF013639: Max. uniformity loss"
  },
  {
    featureId: "EF013640",
    featureDesc: "Max. polarisation dependent loss (PDL)",
    label: "EF013640: Max. polarisation dependent loss (PDL)"
  },
  {
    featureId: "EF013641",
    featureDesc: "Min. directivity",
    label: "EF013641: Min. directivity"
  },
  {
    featureId: "EF013642",
    featureDesc: "Fibre length input",
    label: "EF013642: Fibre length input"
  },
  {
    featureId: "EF013643",
    featureDesc: "Fibre length output",
    label: "EF013643: Fibre length output"
  },
  {
    featureId: "EF013644",
    featureDesc: "With pressure sensor",
    label: "EF013644: With pressure sensor"
  },
  {
    featureId: "EF013645",
    featureDesc: "Pop-up model (click-clack)",
    label: "EF013645: Pop-up model (click-clack)"
  },
  {
    featureId: "EF013646",
    featureDesc: "With hair trap",
    label: "EF013646: With hair trap"
  },
  {
    featureId: "EF013648",
    featureDesc: "Degree of protection (IP) probe",
    label: "EF013648: Degree of protection (IP) probe"
  },
  {
    featureId: "EF013649",
    featureDesc: "Image orientation on display",
    label: "EF013649: Image orientation on display"
  },
  {
    featureId: "EF013650",
    featureDesc: "Diameter camera probe",
    label: "EF013650: Diameter camera probe"
  },
  {
    featureId: "EF013651",
    featureDesc: "Suitable for firewood",
    label: "EF013651: Suitable for firewood"
  },
  {
    featureId: "EF013652",
    featureDesc: "Suitable for briquettes",
    label: "EF013652: Suitable for briquettes"
  },
  {
    featureId: "EF013653",
    featureDesc: "Suitable for wood chips",
    label: "EF013653: Suitable for wood chips"
  },
  {
    featureId: "EF013657",
    featureDesc: "Ceramic lined fireplace",
    label: "EF013657: Ceramic lined fireplace"
  },
  {
    featureId: "EF013660",
    featureDesc: "Max. operating temperature output system",
    label: "EF013660: Max. operating temperature output system"
  },
  {
    featureId: "EF013661",
    featureDesc: "Max. flue gas temperature",
    label: "EF013661: Max. flue gas temperature"
  },
  {
    featureId: "EF013663",
    featureDesc: "Camera directed 90°",
    label: "EF013663: Camera directed 90°"
  },
  {
    featureId: "EF013664",
    featureDesc: "Camera directed forward",
    label: "EF013664: Camera directed forward"
  },
  {
    featureId: "EF013665",
    featureDesc: "Temperature controlled",
    label: "EF013665: Temperature controlled"
  },
  {
    featureId: "EF013666",
    featureDesc: "Temporary extra time",
    label: "EF013666: Temporary extra time"
  },
  {
    featureId: "EF013667",
    featureDesc: "With de-aeration tap",
    label: "EF013667: With de-aeration tap"
  },
  {
    featureId: "EF013668",
    featureDesc: "With blocking function",
    label: "EF013668: With blocking function"
  },
  {
    featureId: "EF013670",
    featureDesc: "Type of tap/mixer",
    label: "EF013670: Type of tap/mixer"
  },
  {
    featureId: "EF013671",
    featureDesc: "Material quality seal",
    label: "EF013671: Material quality seal"
  },
  {
    featureId: "EF013672",
    featureDesc: "Anchor clip length",
    label: "EF013672: Anchor clip length"
  },
  {
    featureId: "EF013673",
    featureDesc: "Type of glue",
    label: "EF013673: Type of glue"
  },
  {
    featureId: "EF013674",
    featureDesc: "Impregnation method of lumber before production",
    label: "EF013674: Impregnation method of lumber before production"
  },
  {
    featureId: "EF013675",
    featureDesc: "Cycle life at 80 % depth of discharge (DOD)",
    label: "EF013675: Cycle life at 80 % depth of discharge (DOD)"
  },
  {
    featureId: "EF013679",
    featureDesc: "Serial operation possible",
    label: "EF013679: Serial operation possible"
  },
  {
    featureId: "EF013680",
    featureDesc: "Battery management system (BMS) integrated",
    label: "EF013680: Battery management system (BMS) integrated"
  },
  {
    featureId: "EF013682",
    featureDesc: "3-phase configuration",
    label: "EF013682: 3-phase configuration"
  },
  {
    featureId: "EF013683",
    featureDesc: "With transfer switch",
    label: "EF013683: With transfer switch"
  },
  {
    featureId: "EF013689",
    featureDesc: "Stapler capacity (number of sheets)",
    label: "EF013689: Stapler capacity (number of sheets)"
  },
  {
    featureId: "EF013691",
    featureDesc: "Max. inlay depth",
    label: "EF013691: Max. inlay depth"
  },
  {
    featureId: "EF013692",
    featureDesc: "Suitable for staple width",
    label: "EF013692: Suitable for staple width"
  },
  {
    featureId: "EF013693",
    featureDesc: "Suitable for staple height",
    label: "EF013693: Suitable for staple height"
  },
  {
    featureId: "EF013694",
    featureDesc: "Laminated safety glass",
    label: "EF013694: Laminated safety glass"
  },
  {
    featureId: "EF013695",
    featureDesc: "Number of grooves",
    label: "EF013695: Number of grooves"
  },
  {
    featureId: "EF013696",
    featureDesc: "Suitable for taperlock bush type",
    label: "EF013696: Suitable for taperlock bush type"
  },
  {
    featureId: "EF013697",
    featureDesc: "Belt profile type",
    label: "EF013697: Belt profile type"
  },
  {
    featureId: "EF013699",
    featureDesc: "Angle of groove",
    label: "EF013699: Angle of groove"
  },
  {
    featureId: "EF013700",
    featureDesc: "Number of die plate holders",
    label: "EF013700: Number of die plate holders"
  },
  {
    featureId: "EF013701",
    featureDesc: "Number of tap holders",
    label: "EF013701: Number of tap holders"
  },
  {
    featureId: "EF013702",
    featureDesc: "Number of tapping drills",
    label: "EF013702: Number of tapping drills"
  },
  {
    featureId: "EF013703",
    featureDesc: "Number of cutting plates",
    label: "EF013703: Number of cutting plates"
  },
  {
    featureId: "EF013704",
    featureDesc: "Pattern repeat length",
    label: "EF013704: Pattern repeat length"
  },
  {
    featureId: "EF013706",
    featureDesc: "Suitable for cooker hood",
    label: "EF013706: Suitable for cooker hood"
  },
  {
    featureId: "EF013707",
    featureDesc: "Number of socket outlets Danish standard (earthing pin)",
    label: "EF013707: Number of socket outlets Danish standard (earthing pin)"
  },
  {
    featureId: "EF013708",
    featureDesc: "With audio induction loop",
    label: "EF013708: With audio induction loop"
  },
  {
    featureId: "EF013709",
    featureDesc: "With digital door code",
    label: "EF013709: With digital door code"
  },
  {
    featureId: "EF013710",
    featureDesc: "Minimum Efficiency Index (MEI)",
    label: "EF013710: Minimum Efficiency Index (MEI)"
  },
  {
    featureId: "EF013711",
    featureDesc: "Suitable for pipe length",
    label: "EF013711: Suitable for pipe length"
  },
  {
    featureId: "EF013712",
    featureDesc: "Suitable for insulated pipes",
    label: "EF013712: Suitable for insulated pipes"
  },
  {
    featureId: "EF013713",
    featureDesc: "Suitable for pipe with mantle pipe",
    label: "EF013713: Suitable for pipe with mantle pipe"
  },
  {
    featureId: "EF013715",
    featureDesc: "Switching transformer",
    label: "EF013715: Switching transformer"
  },
  {
    featureId: "EF013716",
    featureDesc: "Tap changer",
    label: "EF013716: Tap changer"
  },
  {
    featureId: "EF013720",
    featureDesc: "Mounting method on shaft",
    label: "EF013720: Mounting method on shaft"
  },
  {
    featureId: "EF013722",
    featureDesc: "Load loss (secondary voltage 1)",
    label: "EF013722: Load loss (secondary voltage 1)"
  },
  {
    featureId: "EF013723",
    featureDesc: "Load loss (secondary voltage 2)",
    label: "EF013723: Load loss (secondary voltage 2)"
  },
  {
    featureId: "EF013724",
    featureDesc: "With metre indication",
    label: "EF013724: With metre indication"
  },
  {
    featureId: "EF013726",
    featureDesc: "Block stapler",
    label: "EF013726: Block stapler"
  },
  {
    featureId: "EF013729",
    featureDesc: "Identification/coding colour core",
    label: "EF013729: Identification/coding colour core"
  },
  {
    featureId: "EF013732",
    featureDesc: "Reference groove width",
    label: "EF013732: Reference groove width"
  },
  {
    featureId: "EF013733",
    featureDesc: "Distance between grooves",
    label: "EF013733: Distance between grooves"
  },
  {
    featureId: "EF013734",
    featureDesc: "Suitable for powerbelt",
    label: "EF013734: Suitable for powerbelt"
  },
  {
    featureId: "EF013735",
    featureDesc: "Unified Glare Ratio (UGR)",
    label: "EF013735: Unified Glare Ratio (UGR)"
  },
  {
    featureId: "EF013736",
    featureDesc: "Load loss (secondary voltage 3)",
    label: "EF013736: Load loss (secondary voltage 3)"
  },
  {
    featureId: "EF013737",
    featureDesc: "Bib trousers",
    label: "EF013737: Bib trousers"
  },
  {
    featureId: "EF013738",
    featureDesc: "Waders",
    label: "EF013738: Waders"
  },
  {
    featureId: "EF013739",
    featureDesc: "Material weight",
    label: "EF013739: Material weight"
  },
  {
    featureId: "EF013741",
    featureDesc: "Water/wetness protection according to EN 343",
    label: "EF013741: Water/wetness protection according to EN 343"
  },
  {
    featureId: "EF013742",
    featureDesc: "Warning protection according to EN ISO 20471",
    label: "EF013742: Warning protection according to EN ISO 20471"
  },
  {
    featureId: "EF013743",
    featureDesc: "Heat and flame protection according to EN 11612",
    label: "EF013743: Heat and flame protection according to EN 11612"
  },
  {
    featureId: "EF013744",
    featureDesc: "Nominal test voltage 10 kV",
    label: "EF013744: Nominal test voltage 10 kV"
  },
  {
    featureId: "EF013745",
    featureDesc: "Nominal test voltage 15 kV",
    label: "EF013745: Nominal test voltage 15 kV"
  },
  {
    featureId: "EF013747",
    featureDesc: "Material quality edge profile",
    label: "EF013747: Material quality edge profile"
  },
  {
    featureId: "EF013748",
    featureDesc: "Suitable for glass cleaning",
    label: "EF013748: Suitable for glass cleaning"
  },
  {
    featureId: "EF013749",
    featureDesc: "Bend angle",
    label: "EF013749: Bend angle"
  },
  {
    featureId: "EF013750",
    featureDesc: "Outer pipe diameter connection 1",
    label: "EF013750: Outer pipe diameter connection 1"
  },
  {
    featureId: "EF013752",
    featureDesc: "Outer pipe diameter connection 2",
    label: "EF013752: Outer pipe diameter connection 2"
  },
  {
    featureId: "EF013753",
    featureDesc: "Min. wall thickness",
    label: "EF013753: Min. wall thickness"
  },
  {
    featureId: "EF013754",
    featureDesc: "Diameter outer neck",
    label: "EF013754: Diameter outer neck"
  },
  {
    featureId: "EF013755",
    featureDesc: "Centre distance boxes",
    label: "EF013755: Centre distance boxes"
  },
  {
    featureId: "EF013756",
    featureDesc: "Suitable for stud wall",
    label: "EF013756: Suitable for stud wall"
  },
  {
    featureId: "EF013757",
    featureDesc: "Sliding handle",
    label: "EF013757: Sliding handle"
  },
  {
    featureId: "EF013759",
    featureDesc: "Operation by Bluetooth",
    label: "EF013759: Operation by Bluetooth"
  },
  {
    featureId: "EF013760",
    featureDesc: "Beam angle",
    label: "EF013760: Beam angle"
  },
  {
    featureId: "EF013761",
    featureDesc: "Rated luminous flux according to IEC 62612",
    label: "EF013761: Rated luminous flux according to IEC 62612"
  },
  {
    featureId: "EF013762",
    featureDesc: "Surface treatment cylinder",
    label: "EF013762: Surface treatment cylinder"
  },
  {
    featureId: "EF013763",
    featureDesc: "Number of electronic keys",
    label: "EF013763: Number of electronic keys"
  },
  {
    featureId: "EF013766",
    featureDesc: "Core identification according to HD 308 S2",
    label: "EF013766: Core identification according to HD 308 S2"
  },
  {
    featureId: "EF013768",
    featureDesc: "Suitable as telephone cable",
    label: "EF013768: Suitable as telephone cable"
  },
  {
    featureId: "EF013769",
    featureDesc: "Suitable as computer data cable",
    label: "EF013769: Suitable as computer data cable"
  },
  {
    featureId: "EF013770",
    featureDesc: "Suitable as signalling cable",
    label: "EF013770: Suitable as signalling cable"
  },
  {
    featureId: "EF013771",
    featureDesc: "Suitable as installation cable",
    label: "EF013771: Suitable as installation cable"
  },
  {
    featureId: "EF013772",
    featureDesc: "Certified for shipboard application",
    label: "EF013772: Certified for shipboard application"
  },
  {
    featureId: "EF013773",
    featureDesc: "Suitable as medium-voltage cable",
    label: "EF013773: Suitable as medium-voltage cable"
  },
  {
    featureId: "EF013774",
    featureDesc: "Suitable as high-voltage cable",
    label: "EF013774: Suitable as high-voltage cable"
  },
  {
    featureId: "EF013775",
    featureDesc: "Certified as airport lighting cable",
    label: "EF013775: Certified as airport lighting cable"
  },
  {
    featureId: "EF013777",
    featureDesc: "Specific luminous efficacy",
    label: "EF013777: Specific luminous efficacy"
  },
  {
    featureId: "EF013778",
    featureDesc: "Charging temperature",
    label: "EF013778: Charging temperature"
  },
  {
    featureId: "EF013779",
    featureDesc: "Temperature of discharge",
    label: "EF013779: Temperature of discharge"
  },
  {
    featureId: "EF013783",
    featureDesc: "Machine washable",
    label: "EF013783: Machine washable"
  },
  {
    featureId: "EF013784",
    featureDesc: "Heatable",
    label: "EF013784: Heatable"
  },
  {
    featureId: "EF013785",
    featureDesc: "Max. emission heat",
    label: "EF013785: Max. emission heat"
  },
  {
    featureId: "EF013786",
    featureDesc: "Welding protection according to EN 11611",
    label: "EF013786: Welding protection according to EN 11611"
  },
  {
    featureId: "EF013787",
    featureDesc: "Max. laminating width",
    label: "EF013787: Max. laminating width"
  },
  {
    featureId: "EF013788",
    featureDesc: "With eraser",
    label: "EF013788: With eraser"
  },
  {
    featureId: "EF013789",
    featureDesc: "Non-slip grip",
    label: "EF013789: Non-slip grip"
  },
  {
    featureId: "EF013790",
    featureDesc: "Hardness grade",
    label: "EF013790: Hardness grade"
  },
  {
    featureId: "EF013791",
    featureDesc: "Hardness grade character",
    label: "EF013791: Hardness grade character"
  },
  {
    featureId: "EF013792",
    featureDesc: "Handle cover",
    label: "EF013792: Handle cover"
  },
  {
    featureId: "EF013793",
    featureDesc: "Insulated according to EN 60900",
    label: "EF013793: Insulated according to EN 60900"
  },
  {
    featureId: "EF013794",
    featureDesc: "Shape of jaws",
    label: "EF013794: Shape of jaws"
  },
  {
    featureId: "EF013795",
    featureDesc: "With swivel joint connection",
    label: "EF013795: With swivel joint connection"
  },
  {
    featureId: "EF013796",
    featureDesc: "With suspension eyes",
    label: "EF013796: With suspension eyes"
  },
  {
    featureId: "EF013797",
    featureDesc: "Max. heat protection",
    label: "EF013797: Max. heat protection"
  },
  {
    featureId: "EF013798",
    featureDesc: "Shank shape",
    label: "EF013798: Shank shape"
  },
  {
    featureId: "EF013799",
    featureDesc: "Impact force",
    label: "EF013799: Impact force"
  },
  {
    featureId: "EF013800",
    featureDesc: "Jaw height",
    label: "EF013800: Jaw height"
  },
  {
    featureId: "EF013801",
    featureDesc: "Max. tensile strength during installation",
    label: "EF013801: Max. tensile strength during installation"
  },
  {
    featureId: "EF013802",
    featureDesc: "Number of limbs",
    label: "EF013802: Number of limbs"
  },
  {
    featureId: "EF013803",
    featureDesc: "Scaling in mm",
    label: "EF013803: Scaling in mm"
  },
  {
    featureId: "EF013804",
    featureDesc: "Scaling in inch",
    label: "EF013804: Scaling in inch"
  },
  {
    featureId: "EF013805",
    featureDesc: "Bridge length",
    label: "EF013805: Bridge length"
  },
  {
    featureId: "EF013806",
    featureDesc: "Bracket depth",
    label: "EF013806: Bracket depth"
  },
  {
    featureId: "EF013807",
    featureDesc: "Outer ring diameter",
    label: "EF013807: Outer ring diameter"
  },
  {
    featureId: "EF013808",
    featureDesc: "Type of measuring force adjuster",
    label: "EF013808: Type of measuring force adjuster"
  },
  {
    featureId: "EF013809",
    featureDesc: "Diameter of chuck shank",
    label: "EF013809: Diameter of chuck shank"
  },
  {
    featureId: "EF013810",
    featureDesc: "Reading",
    label: "EF013810: Reading"
  },
  {
    featureId: "EF013811",
    featureDesc: "Max. error",
    label: "EF013811: Max. error"
  },
  {
    featureId: "EF013812",
    featureDesc: "With flat side",
    label: "EF013812: With flat side"
  },
  {
    featureId: "EF013813",
    featureDesc: "Cutting direction",
    label: "EF013813: Cutting direction"
  },
  {
    featureId: "EF013814",
    featureDesc: "Number of underpants short",
    label: "EF013814: Number of underpants short"
  },
  {
    featureId: "EF013815",
    featureDesc: "Number of underpants long",
    label: "EF013815: Number of underpants long"
  },
  {
    featureId: "EF013816",
    featureDesc: "Number of undershirts short",
    label: "EF013816: Number of undershirts short"
  },
  {
    featureId: "EF013817",
    featureDesc: "Number of undershirts long",
    label: "EF013817: Number of undershirts long"
  },
  {
    featureId: "EF013818",
    featureDesc: "Number of bras",
    label: "EF013818: Number of bras"
  },
  {
    featureId: "EF013819",
    featureDesc: "Number of bodysuits",
    label: "EF013819: Number of bodysuits"
  },
  {
    featureId: "EF013820",
    featureDesc: "Adjustable neck strap",
    label: "EF013820: Adjustable neck strap"
  },
  {
    featureId: "EF013821",
    featureDesc: "Suitable for leather",
    label: "EF013821: Suitable for leather"
  },
  {
    featureId: "EF013822",
    featureDesc: "Rated system voltage 12 V DC",
    label: "EF013822: Rated system voltage 12 V DC"
  },
  {
    featureId: "EF013823",
    featureDesc: "Rated system voltage 24 V DC",
    label: "EF013823: Rated system voltage 24 V DC"
  },
  {
    featureId: "EF013824",
    featureDesc: "Rated system voltage 48 V DC",
    label: "EF013824: Rated system voltage 48 V DC"
  },
  {
    featureId: "EF013825",
    featureDesc: "Max. loading current at 40 °C",
    label: "EF013825: Max. loading current at 40 °C"
  },
  {
    featureId: "EF013826",
    featureDesc: "Suitable for plunge router",
    label: "EF013826: Suitable for plunge router"
  },
  {
    featureId: "EF013827",
    featureDesc: "With throw-in shaft",
    label: "EF013827: With throw-in shaft"
  },
  {
    featureId: "EF013830",
    featureDesc: "Adjustable air exhaust",
    label: "EF013830: Adjustable air exhaust"
  },
  {
    featureId: "EF013832",
    featureDesc: "Regulation type",
    label: "EF013832: Regulation type"
  },
  {
    featureId: "EF013833",
    featureDesc: "Refrigerant pre-charge",
    label: "EF013833: Refrigerant pre-charge"
  },
  {
    featureId: "EF013834",
    featureDesc: "Power index according to EN 16147 (7 °C)",
    label: "EF013834: Power index according to EN 16147 (7 °C)"
  },
  {
    featureId: "EF013835",
    featureDesc: "Power index according to EN 16147 (15 °C)",
    label: "EF013835: Power index according to EN 16147 (15 °C)"
  },
  {
    featureId: "EF013836",
    featureDesc: "Type of IR radiation",
    label: "EF013836: Type of IR radiation"
  },
  {
    featureId: "EF013837",
    featureDesc: "Colour framework",
    label: "EF013837: Colour framework"
  },
  {
    featureId: "EF013838",
    featureDesc: "With framework",
    label: "EF013838: With framework"
  },
  {
    featureId: "EF013839",
    featureDesc: "Material framework",
    label: "EF013839: Material framework"
  },
  {
    featureId: "EF013840",
    featureDesc: "With door closer",
    label: "EF013840: With door closer"
  },
  {
    featureId: "EF013841",
    featureDesc: "Connection voltage of open-keeping function",
    label: "EF013841: Connection voltage of open-keeping function"
  },
  {
    featureId: "EF013842",
    featureDesc: "Number of inputs HDMI",
    label: "EF013842: Number of inputs HDMI"
  },
  {
    featureId: "EF013844",
    featureDesc: "Number of Ethernet inputs",
    label: "EF013844: Number of Ethernet inputs"
  },
  {
    featureId: "EF013845",
    featureDesc: "Number of RS-232 inputs",
    label: "EF013845: Number of RS-232 inputs"
  },
  {
    featureId: "EF013846",
    featureDesc: "Number of inputs TCP/IP",
    label: "EF013846: Number of inputs TCP/IP"
  },
  {
    featureId: "EF013847",
    featureDesc: "Number of inputs IPX/SPX",
    label: "EF013847: Number of inputs IPX/SPX"
  },
  {
    featureId: "EF013848",
    featureDesc: "Number of inputs HDBase-T",
    label: "EF013848: Number of inputs HDBase-T"
  },
  {
    featureId: "EF013849",
    featureDesc: "Number of inputs DisplayPort (DP)",
    label: "EF013849: Number of inputs DisplayPort (DP)"
  },
  {
    featureId: "EF013850",
    featureDesc: "Number of outputs HDMI",
    label: "EF013850: Number of outputs HDMI"
  },
  {
    featureId: "EF013851",
    featureDesc: "Number of Ethernet outputs",
    label: "EF013851: Number of Ethernet outputs"
  },
  {
    featureId: "EF013852",
    featureDesc: "Number of RS-232 outputs",
    label: "EF013852: Number of RS-232 outputs"
  },
  {
    featureId: "EF013853",
    featureDesc: "Number of outputs TCP/IP",
    label: "EF013853: Number of outputs TCP/IP"
  },
  {
    featureId: "EF013854",
    featureDesc: "Number of outputs IPX/SPX",
    label: "EF013854: Number of outputs IPX/SPX"
  },
  {
    featureId: "EF013857",
    featureDesc: "Number of outputs DisplayPort (DP)",
    label: "EF013857: Number of outputs DisplayPort (DP)"
  },
  {
    featureId: "EF013859",
    featureDesc: "Supports scaling",
    label: "EF013859: Supports scaling"
  },
  {
    featureId: "EF013860",
    featureDesc: "Supports matrix routing",
    label: "EF013860: Supports matrix routing"
  },
  {
    featureId: "EF013861",
    featureDesc: "Supports auto-switching",
    label: "EF013861: Supports auto-switching"
  },
  {
    featureId: "EF013862",
    featureDesc: "Pages exchangeable",
    label: "EF013862: Pages exchangeable"
  },
  {
    featureId: "EF013863",
    featureDesc: "360° convertible",
    label: "EF013863: 360° convertible"
  },
  {
    featureId: "EF013864",
    featureDesc: "With counting function",
    label: "EF013864: With counting function"
  },
  {
    featureId: "EF013865",
    featureDesc: "Suitable for high-pressure washer",
    label: "EF013865: Suitable for high-pressure washer"
  },
  {
    featureId: "EF013867",
    featureDesc: "Externally galvanized",
    label: "EF013867: Externally galvanized"
  },
  {
    featureId: "EF013868",
    featureDesc: "Externally coated",
    label: "EF013868: Externally coated"
  },
  {
    featureId: "EF013869",
    featureDesc: "Number of drain points",
    label: "EF013869: Number of drain points"
  },
  {
    featureId: "EF013871",
    featureDesc: "Suitable for perforated backboard",
    label: "EF013871: Suitable for perforated backboard"
  },
  {
    featureId: "EF013872",
    featureDesc: "With tableclamp",
    label: "EF013872: With tableclamp"
  },
  {
    featureId: "EF013873",
    featureDesc: "Max. cutting speed",
    label: "EF013873: Max. cutting speed"
  },
  {
    featureId: "EF013875",
    featureDesc: "Visual appearance",
    label: "EF013875: Visual appearance"
  },
  {
    featureId: "EF013876",
    featureDesc: "Colour tone",
    label: "EF013876: Colour tone"
  },
  {
    featureId: "EF013877",
    featureDesc: "Locking system",
    label: "EF013877: Locking system"
  },
  {
    featureId: "EF013878",
    featureDesc: "Synchronised surface (EIR)",
    label: "EF013878: Synchronised surface (EIR)"
  },
  {
    featureId: "EF013880",
    featureDesc: "Thickness of wear layer",
    label: "EF013880: Thickness of wear layer"
  },
  {
    featureId: "EF013881",
    featureDesc: "Thermal conductivity according to EN 12664",
    label: "EF013881: Thermal conductivity according to EN 12664"
  },
  {
    featureId: "EF013882",
    featureDesc: "Durability class according to EN 350-2",
    label: "EF013882: Durability class according to EN 350-2"
  },
  {
    featureId: "EF013884",
    featureDesc: "Suitable for fire protection of load-bearing constructions",
    label: "EF013884: Suitable for fire protection of load-bearing constructions"
  },
  {
    featureId: "EF013887",
    featureDesc: "Suitable for protection from radiation",
    label: "EF013887: Suitable for protection from radiation"
  },
  {
    featureId: "EF013888",
    featureDesc: "Factory bent board",
    label: "EF013888: Factory bent board"
  },
  {
    featureId: "EF013891",
    featureDesc: "Type designation A according to EN 520",
    label: "EF013891: Type designation A according to EN 520"
  },
  {
    featureId: "EF013892",
    featureDesc: "Type designation D according to EN 520",
    label: "EF013892: Type designation D according to EN 520"
  },
  {
    featureId: "EF013894",
    featureDesc: "Type designation E according to EN 520",
    label: "EF013894: Type designation E according to EN 520"
  },
  {
    featureId: "EF013895",
    featureDesc: "Type designation F according to EN 520",
    label: "EF013895: Type designation F according to EN 520"
  },
  {
    featureId: "EF013897",
    featureDesc: "Type designation I according to EN 520",
    label: "EF013897: Type designation I according to EN 520"
  },
  {
    featureId: "EF013898",
    featureDesc: "Type designation P according to EN 520",
    label: "EF013898: Type designation P according to EN 520"
  },
  {
    featureId: "EF013899",
    featureDesc: "Type designation R according to EN 520",
    label: "EF013899: Type designation R according to EN 520"
  },
  {
    featureId: "EF013900",
    featureDesc: "Type designation GM according to EN 15283-1",
    label: "EF013900: Type designation GM according to EN 15283-1"
  },
  {
    featureId: "EF013901",
    featureDesc: "Type designation GM-H1 according to EN 15283-1",
    label: "EF013901: Type designation GM-H1 according to EN 15283-1"
  },
  {
    featureId: "EF013902",
    featureDesc: "Type designation GM-H2 according to EN 15283-1",
    label: "EF013902: Type designation GM-H2 according to EN 15283-1"
  },
  {
    featureId: "EF013903",
    featureDesc: "Type designation GM-I according to EN 15283-1",
    label: "EF013903: Type designation GM-I according to EN 15283-1"
  },
  {
    featureId: "EF013904",
    featureDesc: "Type designation GM-F according to EN 15283-1",
    label: "EF013904: Type designation GM-F according to EN 15283-1"
  },
  {
    featureId: "EF013905",
    featureDesc: "Type designation GM-R according to EN 15283-1",
    label: "EF013905: Type designation GM-R according to EN 15283-1"
  },
  {
    featureId: "EF013906",
    featureDesc: "Fire protection ability according to EN 13501-2",
    label: "EF013906: Fire protection ability according to EN 13501-2"
  },
  {
    featureId: "EF013908",
    featureDesc: "Fixing",
    label: "EF013908: Fixing"
  },
  {
    featureId: "EF013909",
    featureDesc: "Number of nuts",
    label: "EF013909: Number of nuts"
  },
  {
    featureId: "EF013910",
    featureDesc: "Vapor-tight",
    label: "EF013910: Vapor-tight"
  },
  {
    featureId: "EF013911",
    featureDesc: "Volume fireplace",
    label: "EF013911: Volume fireplace"
  },
  {
    featureId: "EF013912",
    featureDesc: "Max. firewood length",
    label: "EF013912: Max. firewood length"
  },
  {
    featureId: "EF013913",
    featureDesc: "Heat emission 20 °C - 75/65 high",
    label: "EF013913: Heat emission 20 °C - 75/65 high"
  },
  {
    featureId: "EF013914",
    featureDesc: "Heat emission 20 °C - 75/65 medium",
    label: "EF013914: Heat emission 20 °C - 75/65 medium"
  },
  {
    featureId: "EF013915",
    featureDesc: "Heat emission 20 °C - 75/65 low",
    label: "EF013915: Heat emission 20 °C - 75/65 low"
  },
  {
    featureId: "EF013916",
    featureDesc: "Cooling capacity according to EN 16430 26 °C - 17/19 perceived high",
    label: "EF013916: Cooling capacity according to EN 16430 26 °C - 17/19 perceived high"
  },
  {
    featureId: "EF013917",
    featureDesc: "Cooling capacity according to EN 16430 26 °C - 17/19 perceived medium",
    label: "EF013917: Cooling capacity according to EN 16430 26 °C - 17/19 perceived medium"
  },
  {
    featureId: "EF013918",
    featureDesc: "Cooling capacity according to EN 16430 26 °C - 17/19 perceived low",
    label: "EF013918: Cooling capacity according to EN 16430 26 °C - 17/19 perceived low"
  },
  {
    featureId: "EF013919",
    featureDesc: "Max. throw horizontal",
    label: "EF013919: Max. throw horizontal"
  },
  {
    featureId: "EF013920",
    featureDesc: "Max. throw vertical",
    label: "EF013920: Max. throw vertical"
  },
  {
    featureId: "EF013921",
    featureDesc: "Min. mounting height (horizontal air distribution)",
    label: "EF013921: Min. mounting height (horizontal air distribution)"
  },
  {
    featureId: "EF013922",
    featureDesc: "Min. mounting height (vertical air distribution)",
    label: "EF013922: Min. mounting height (vertical air distribution)"
  },
  {
    featureId: "EF013923",
    featureDesc: "Gas consumption low-calorific natural gas (G25)",
    label: "EF013923: Gas consumption low-calorific natural gas (G25)"
  },
  {
    featureId: "EF013924",
    featureDesc: "Gas consumption propane (G31)",
    label: "EF013924: Gas consumption propane (G31)"
  },
  {
    featureId: "EF013925",
    featureDesc: "Bevelled edge (facet)",
    label: "EF013925: Bevelled edge (facet)"
  },
  {
    featureId: "EF013926",
    featureDesc: "Formaldehyde release according to EN 717-1",
    label: "EF013926: Formaldehyde release according to EN 717-1"
  },
  {
    featureId: "EF013927",
    featureDesc: "Inner diameter pipe sleeve",
    label: "EF013927: Inner diameter pipe sleeve"
  },
  {
    featureId: "EF013928",
    featureDesc: "Weight including filling",
    label: "EF013928: Weight including filling"
  },
  {
    featureId: "EF013929",
    featureDesc: "Gas consumption high-calorific natural gas (G20)",
    label: "EF013929: Gas consumption high-calorific natural gas (G20)"
  },
  {
    featureId: "EF013930",
    featureDesc: "Max. sound level (at a distance of 4 metres)",
    label: "EF013930: Max. sound level (at a distance of 4 metres)"
  },
  {
    featureId: "EF013931",
    featureDesc: "Suitable for jointing together with flax/hemp",
    label: "EF013931: Suitable for jointing together with flax/hemp"
  },
  {
    featureId: "EF013932",
    featureDesc: "Air tightness according to EN 12114",
    label: "EF013932: Air tightness according to EN 12114"
  },
  {
    featureId: "EF013933",
    featureDesc: "Heating element with soapstone sleeve",
    label: "EF013933: Heating element with soapstone sleeve"
  },
  {
    featureId: "EF013934",
    featureDesc: "Anode protection",
    label: "EF013934: Anode protection"
  },
  {
    featureId: "EF013936",
    featureDesc: "Cleaning",
    label: "EF013936: Cleaning"
  },
  {
    featureId: "EF013941",
    featureDesc: "Countersunk head",
    label: "EF013941: Countersunk head"
  },
  {
    featureId: "EF013942",
    featureDesc: "Frequency indoor unit",
    label: "EF013942: Frequency indoor unit"
  },
  {
    featureId: "EF013943",
    featureDesc: "Number of phases indoor unit",
    label: "EF013943: Number of phases indoor unit"
  },
  {
    featureId: "EF013944",
    featureDesc: "Connection voltage indoor unit",
    label: "EF013944: Connection voltage indoor unit"
  },
  {
    featureId: "EF013945",
    featureDesc: "Frequency outdoor unit",
    label: "EF013945: Frequency outdoor unit"
  },
  {
    featureId: "EF013946",
    featureDesc: "Number of phases outdoor unit",
    label: "EF013946: Number of phases outdoor unit"
  },
  {
    featureId: "EF013947",
    featureDesc: "Connection voltage outdoor unit",
    label: "EF013947: Connection voltage outdoor unit"
  },
  {
    featureId: "EF013948",
    featureDesc: "SEER (cooling)",
    label: "EF013948: SEER (cooling)"
  },
  {
    featureId: "EF013950",
    featureDesc: "SCOP (heating)",
    label: "EF013950: SCOP (heating)"
  },
  {
    featureId: "EF013953",
    featureDesc: "Outer pipe diameter connection 3",
    label: "EF013953: Outer pipe diameter connection 3"
  },
  {
    featureId: "EF013956",
    featureDesc: "With swirl",
    label: "EF013956: With swirl"
  },
  {
    featureId: "EF013957",
    featureDesc: "Heating capacity at 0/35 °C (B0/W35) according to EN 14511",
    label: "EF013957: Heating capacity at 0/35 °C (B0/W35) according to EN 14511"
  },
  {
    featureId: "EF013958",
    featureDesc: "Heating capacity at 10/35 °C (W10/W35) according to EN 14511",
    label: "EF013958: Heating capacity at 10/35 °C (W10/W35) according to EN 14511"
  },
  {
    featureId: "EF013959",
    featureDesc: "Equivalent duct diameter, connection 3",
    label: "EF013959: Equivalent duct diameter, connection 3"
  },
  {
    featureId: "EF013960",
    featureDesc: "Equivalent duct diameter, connection 4",
    label: "EF013960: Equivalent duct diameter, connection 4"
  },
  {
    featureId: "EF013961",
    featureDesc: "Nominal duct width connection 4",
    label: "EF013961: Nominal duct width connection 4"
  },
  {
    featureId: "EF013962",
    featureDesc: "Nominal duct height connection 4",
    label: "EF013962: Nominal duct height connection 4"
  },
  {
    featureId: "EF013967",
    featureDesc: "With inlet valve",
    label: "EF013967: With inlet valve"
  },
  {
    featureId: "EF013968",
    featureDesc: "With outlet control valve",
    label: "EF013968: With outlet control valve"
  },
  {
    featureId: "EF013969",
    featureDesc: "With bypass control valve",
    label: "EF013969: With bypass control valve"
  },
  {
    featureId: "EF013970",
    featureDesc: "Control range residual hardness",
    label: "EF013970: Control range residual hardness"
  },
  {
    featureId: "EF013971",
    featureDesc: "With connections for water softener",
    label: "EF013971: With connections for water softener"
  },
  {
    featureId: "EF013972",
    featureDesc: "Concentrate",
    label: "EF013972: Concentrate"
  },
  {
    featureId: "EF013973",
    featureDesc: "Type of adapter",
    label: "EF013973: Type of adapter"
  },
  {
    featureId: "EF013975",
    featureDesc: "Suitable for photovoltaïc test",
    label: "EF013975: Suitable for photovoltaïc test"
  },
  {
    featureId: "EF013976",
    featureDesc: "Suitable for E-mobility test",
    label: "EF013976: Suitable for E-mobility test"
  },
  {
    featureId: "EF013978",
    featureDesc: "Suitable for gypsum",
    label: "EF013978: Suitable for gypsum"
  },
  {
    featureId: "EF013979",
    featureDesc: "Granulation according to CAMI",
    label: "EF013979: Granulation according to CAMI"
  },
  {
    featureId: "EF013980",
    featureDesc: "Granulation according to FEPA",
    label: "EF013980: Granulation according to FEPA"
  },
  {
    featureId: "EF013981",
    featureDesc: "Suitable for inner diameter",
    label: "EF013981: Suitable for inner diameter"
  },
  {
    featureId: "EF013982",
    featureDesc: "Reversible door",
    label: "EF013982: Reversible door"
  },
  {
    featureId: "EF013983",
    featureDesc: "Suitable for garage door operation",
    label: "EF013983: Suitable for garage door operation"
  },
  {
    featureId: "EF013984",
    featureDesc: "Suitable for swing gate operation",
    label: "EF013984: Suitable for swing gate operation"
  },
  {
    featureId: "EF013985",
    featureDesc: "Suitable for sliding gate operation",
    label: "EF013985: Suitable for sliding gate operation"
  },
  {
    featureId: "EF013986",
    featureDesc: "Segregation class according to EN 50174-2",
    label: "EF013986: Segregation class according to EN 50174-2"
  },
  {
    featureId: "EF013989",
    featureDesc: "With water stop",
    label: "EF013989: With water stop"
  },
  {
    featureId: "EF013991",
    featureDesc: "Nominal duct diameter, main duct",
    label: "EF013991: Nominal duct diameter, main duct"
  },
  {
    featureId: "EF013992",
    featureDesc: "Shape of branches",
    label: "EF013992: Shape of branches"
  },
  {
    featureId: "EF013993",
    featureDesc: "Equivalent duct diameter, branches",
    label: "EF013993: Equivalent duct diameter, branches"
  },
  {
    featureId: "EF013994",
    featureDesc: "Nominal duct height branches",
    label: "EF013994: Nominal duct height branches"
  },
  {
    featureId: "EF013995",
    featureDesc: "Nominal duct width branches",
    label: "EF013995: Nominal duct width branches"
  },
  {
    featureId: "EF013996",
    featureDesc: "Embeddable in concrete",
    label: "EF013996: Embeddable in concrete"
  },
  {
    featureId: "EF013997",
    featureDesc: "Ready for use",
    label: "EF013997: Ready for use"
  },
  {
    featureId: "EF013998",
    featureDesc: "Dilutable",
    label: "EF013998: Dilutable"
  },
  {
    featureId: "EF014001",
    featureDesc: "Explosion protection technique",
    label: "EF014001: Explosion protection technique"
  },
  {
    featureId: "EF014004",
    featureDesc: "Accuracy of temperature measurement",
    label: "EF014004: Accuracy of temperature measurement"
  },
  {
    featureId: "EF014005",
    featureDesc: "Accuracy of vibration measurement",
    label: "EF014005: Accuracy of vibration measurement"
  },
  {
    featureId: "EF014006",
    featureDesc: "Spray angle",
    label: "EF014006: Spray angle"
  },
  {
    featureId: "EF014008",
    featureDesc: "Spray pattern",
    label: "EF014008: Spray pattern"
  },
  {
    featureId: "EF014009",
    featureDesc: "Atomizing pressure",
    label: "EF014009: Atomizing pressure"
  },
  {
    featureId: "EF014010",
    featureDesc: "Filter grade",
    label: "EF014010: Filter grade"
  },
  {
    featureId: "EF014011",
    featureDesc: "Low emission (LE)",
    label: "EF014011: Low emission (LE)"
  },
  {
    featureId: "EF014012",
    featureDesc: "Tint base",
    label: "EF014012: Tint base"
  },
  {
    featureId: "EF014015",
    featureDesc: "Material quality duct",
    label: "EF014015: Material quality duct"
  },
  {
    featureId: "EF014016",
    featureDesc: "Wall thickness duct",
    label: "EF014016: Wall thickness duct"
  },
  {
    featureId: "EF014019",
    featureDesc: "Equivalent diameter air passage",
    label: "EF014019: Equivalent diameter air passage"
  },
  {
    featureId: "EF014020",
    featureDesc: "Diameter air passage",
    label: "EF014020: Diameter air passage"
  },
  {
    featureId: "EF014021",
    featureDesc: "Measurement pH value",
    label: "EF014021: Measurement pH value"
  },
  {
    featureId: "EF014022",
    featureDesc: "Measuring range pH",
    label: "EF014022: Measuring range pH"
  },
  {
    featureId: "EF014023",
    featureDesc: "Lowest resolution pH measurement",
    label: "EF014023: Lowest resolution pH measurement"
  },
  {
    featureId: "EF014024",
    featureDesc: "Temperature compensation pH",
    label: "EF014024: Temperature compensation pH"
  },
  {
    featureId: "EF014025",
    featureDesc: "With DC measurement mV",
    label: "EF014025: With DC measurement mV"
  },
  {
    featureId: "EF014026",
    featureDesc: "DC measurement mV",
    label: "EF014026: DC measurement mV"
  },
  {
    featureId: "EF014027",
    featureDesc: "Lowest resolution DC measurement mV",
    label: "EF014027: Lowest resolution DC measurement mV"
  },
  {
    featureId: "EF014029",
    featureDesc: "With dissolved oxygen (DO) measurement",
    label: "EF014029: With dissolved oxygen (DO) measurement"
  },
  {
    featureId: "EF014030",
    featureDesc: "Dissolved oxygen (DO) concentration range",
    label: "EF014030: Dissolved oxygen (DO) concentration range"
  },
  {
    featureId: "EF014031",
    featureDesc: "Lowest resolution DO concentration measurement",
    label: "EF014031: Lowest resolution DO concentration measurement"
  },
  {
    featureId: "EF014032",
    featureDesc: "Dissolved oxygen (DO) saturation range",
    label: "EF014032: Dissolved oxygen (DO) saturation range"
  },
  {
    featureId: "EF014033",
    featureDesc: "Conductivity measuring range",
    label: "EF014033: Conductivity measuring range"
  },
  {
    featureId: "EF014034",
    featureDesc: "Lowest resolution conductivity measurement",
    label: "EF014034: Lowest resolution conductivity measurement"
  },
  {
    featureId: "EF014035",
    featureDesc: "With total dissolved solids (TDS) measurement",
    label: "EF014035: With total dissolved solids (TDS) measurement"
  },
  {
    featureId: "EF014036",
    featureDesc: "Total dissolved solids (TDS) measuring range",
    label: "EF014036: Total dissolved solids (TDS) measuring range"
  },
  {
    featureId: "EF014037",
    featureDesc: "Lowest resolution TDS measurement",
    label: "EF014037: Lowest resolution TDS measurement"
  },
  {
    featureId: "EF014038",
    featureDesc: "With salinity measurement",
    label: "EF014038: With salinity measurement"
  },
  {
    featureId: "EF014039",
    featureDesc: "Salinity range",
    label: "EF014039: Salinity range"
  },
  {
    featureId: "EF014040",
    featureDesc: "Resistance measurement",
    label: "EF014040: Resistance measurement"
  },
  {
    featureId: "EF014042",
    featureDesc: "With ion concentration measurement (ISE)",
    label: "EF014042: With ion concentration measurement (ISE)"
  },
  {
    featureId: "EF014043",
    featureDesc: "Measuring range ion concentration",
    label: "EF014043: Measuring range ion concentration"
  },
  {
    featureId: "EF014044",
    featureDesc: "Lowest resolution ion concentration measurement",
    label: "EF014044: Lowest resolution ion concentration measurement"
  },
  {
    featureId: "EF014045",
    featureDesc: "With turbidity measurement",
    label: "EF014045: With turbidity measurement"
  },
  {
    featureId: "EF014046",
    featureDesc: "SPI interface",
    label: "EF014046: SPI interface"
  },
  {
    featureId: "EF014047",
    featureDesc: "Measuring unit vibration velocity (RMS)",
    label: "EF014047: Measuring unit vibration velocity (RMS)"
  },
  {
    featureId: "EF014048",
    featureDesc: "Programmable waveform generator",
    label: "EF014048: Programmable waveform generator"
  },
  {
    featureId: "EF014049",
    featureDesc: "Resolution of programmable waveform generator DAC",
    label: "EF014049: Resolution of programmable waveform generator DAC"
  },
  {
    featureId: "EF014050",
    featureDesc: "Sinus waveform generator",
    label: "EF014050: Sinus waveform generator"
  },
  {
    featureId: "EF014051",
    featureDesc: "Frequency sinus waveform generator",
    label: "EF014051: Frequency sinus waveform generator"
  },
  {
    featureId: "EF014052",
    featureDesc: "Square waveform generator",
    label: "EF014052: Square waveform generator"
  },
  {
    featureId: "EF014053",
    featureDesc: "Frequency range square waveform generator",
    label: "EF014053: Frequency range square waveform generator"
  },
  {
    featureId: "EF014054",
    featureDesc: "Rise time square waveform generator",
    label: "EF014054: Rise time square waveform generator"
  },
  {
    featureId: "EF014055",
    featureDesc: "Triangle waveform generator",
    label: "EF014055: Triangle waveform generator"
  },
  {
    featureId: "EF014056",
    featureDesc: "Frequency range triangle waveform generator",
    label: "EF014056: Frequency range triangle waveform generator"
  },
  {
    featureId: "EF014057",
    featureDesc: "Sawtooth forward waveform generator",
    label: "EF014057: Sawtooth forward waveform generator"
  },
  {
    featureId: "EF014058",
    featureDesc: "Reverse sawtooth forward waveform generator",
    label: "EF014058: Reverse sawtooth forward waveform generator"
  },
  {
    featureId: "EF014059",
    featureDesc: "Output signal amplitude",
    label: "EF014059: Output signal amplitude"
  },
  {
    featureId: "EF014060",
    featureDesc: "Output signal TTL/CMOS",
    label: "EF014060: Output signal TTL/CMOS"
  },
  {
    featureId: "EF014061",
    featureDesc: "Frequency range TTL/CMOS output",
    label: "EF014061: Frequency range TTL/CMOS output"
  },
  {
    featureId: "EF014062",
    featureDesc: "Rise time, output TTL/CMOS",
    label: "EF014062: Rise time, output TTL/CMOS"
  },
  {
    featureId: "EF014063",
    featureDesc: "AM modulation",
    label: "EF014063: AM modulation"
  },
  {
    featureId: "EF014064",
    featureDesc: "FM modulation",
    label: "EF014064: FM modulation"
  },
  {
    featureId: "EF014065",
    featureDesc: "FSK modulation",
    label: "EF014065: FSK modulation"
  },
  {
    featureId: "EF014066",
    featureDesc: "PSK modulation",
    label: "EF014066: PSK modulation"
  },
  {
    featureId: "EF014067",
    featureDesc: "PWM modulation",
    label: "EF014067: PWM modulation"
  },
  {
    featureId: "EF014068",
    featureDesc: "Frequency sweep mode",
    label: "EF014068: Frequency sweep mode"
  },
  {
    featureId: "EF014069",
    featureDesc: "Burst mode waveform",
    label: "EF014069: Burst mode waveform"
  },
  {
    featureId: "EF014070",
    featureDesc: "Count and frequency meter",
    label: "EF014070: Count and frequency meter"
  },
  {
    featureId: "EF014071",
    featureDesc: "Measuring range count and frequency meter",
    label: "EF014071: Measuring range count and frequency meter"
  },
  {
    featureId: "EF014072",
    featureDesc: "Measuring unit vibration velocity (Peak)",
    label: "EF014072: Measuring unit vibration velocity (Peak)"
  },
  {
    featureId: "EF014076",
    featureDesc: "Suitable for algae and moss removal",
    label: "EF014076: Suitable for algae and moss removal"
  },
  {
    featureId: "EF014077",
    featureDesc: "Suitable for mold removal",
    label: "EF014077: Suitable for mold removal"
  },
  {
    featureId: "EF014078",
    featureDesc: "Suitable for cork",
    label: "EF014078: Suitable for cork"
  },
  {
    featureId: "EF014079",
    featureDesc: "Suitable for laminate",
    label: "EF014079: Suitable for laminate"
  },
  {
    featureId: "EF014081",
    featureDesc: "Min. bending radius without tools",
    label: "EF014081: Min. bending radius without tools"
  },
  {
    featureId: "EF014082",
    featureDesc: "Shape of insulation",
    label: "EF014082: Shape of insulation"
  },
  {
    featureId: "EF014085",
    featureDesc: "Suitable for process water",
    label: "EF014085: Suitable for process water"
  },
  {
    featureId: "EF014099",
    featureDesc: "Max. operating pressure at max. medium temperature",
    label: "EF014099: Max. operating pressure at max. medium temperature"
  },
  {
    featureId: "EF014100",
    featureDesc: "Penetration class according to EN 351-1",
    label: "EF014100: Penetration class according to EN 351-1"
  },
  {
    featureId: "EF014104",
    featureDesc: "With sound decoupling",
    label: "EF014104: With sound decoupling"
  },
  {
    featureId: "EF014109",
    featureDesc: "Outer width rectangular insulation",
    label: "EF014109: Outer width rectangular insulation"
  },
  {
    featureId: "EF014112",
    featureDesc: "Suitable for hanging devices",
    label: "EF014112: Suitable for hanging devices"
  },
  {
    featureId: "EF014113",
    featureDesc: "Suitable for standing devices",
    label: "EF014113: Suitable for standing devices"
  },
  {
    featureId: "EF014114",
    featureDesc: "Thermal property",
    label: "EF014114: Thermal property"
  },
  {
    featureId: "EF014115",
    featureDesc: "With vibration dampers",
    label: "EF014115: With vibration dampers"
  },
  {
    featureId: "EF014116",
    featureDesc: "Measuring unit vibration acceleration (Peak)",
    label: "EF014116: Measuring unit vibration acceleration (Peak)"
  },
  {
    featureId: "EF014117",
    featureDesc: "Measuring unit vibration displacement",
    label: "EF014117: Measuring unit vibration displacement"
  },
  {
    featureId: "EF014125",
    featureDesc: "Warning device",
    label: "EF014125: Warning device"
  },
  {
    featureId: "EF014147",
    featureDesc: "Rubber quality",
    label: "EF014147: Rubber quality"
  },
  {
    featureId: "EF014156",
    featureDesc: "Mounting system",
    label: "EF014156: Mounting system"
  },
  {
    featureId: "EF014157",
    featureDesc: "Visible surface horizontal",
    label: "EF014157: Visible surface horizontal"
  },
  {
    featureId: "EF014158",
    featureDesc: "Visible surface vertical",
    label: "EF014158: Visible surface vertical"
  },
  {
    featureId: "EF014162",
    featureDesc: "Type of flooring",
    label: "EF014162: Type of flooring"
  },
  {
    featureId: "EF014165",
    featureDesc: "Size (EUR)",
    label: "EF014165: Size (EUR)"
  },
  {
    featureId: "EF014166",
    featureDesc: "Material bracket",
    label: "EF014166: Material bracket"
  },
  {
    featureId: "EF014167",
    featureDesc: "Surface protection bracket",
    label: "EF014167: Surface protection bracket"
  },
  {
    featureId: "EF014168",
    featureDesc: "Type of marking paint",
    label: "EF014168: Type of marking paint"
  },
  {
    featureId: "EF014169",
    featureDesc: "With cable routing",
    label: "EF014169: With cable routing"
  },
  {
    featureId: "EF014170",
    featureDesc: "Suitable for water meter length",
    label: "EF014170: Suitable for water meter length"
  },
  {
    featureId: "EF014171",
    featureDesc: "Suitable for number of water meters",
    label: "EF014171: Suitable for number of water meters"
  },
  {
    featureId: "EF014173",
    featureDesc: "Passage width",
    label: "EF014173: Passage width"
  },
  {
    featureId: "EF014174",
    featureDesc: "Passage height",
    label: "EF014174: Passage height"
  },
  {
    featureId: "EF014176",
    featureDesc: "Type of cable feed-through",
    label: "EF014176: Type of cable feed-through"
  },
  {
    featureId: "EF014177",
    featureDesc: "Cleaning performance per battery charge",
    label: "EF014177: Cleaning performance per battery charge"
  },
  {
    featureId: "EF014185",
    featureDesc: "Sensor element inside housing",
    label: "EF014185: Sensor element inside housing"
  },
  {
    featureId: "EF014186",
    featureDesc: "Impact weight",
    label: "EF014186: Impact weight"
  },
  {
    featureId: "EF014188",
    featureDesc: "Colour of board",
    label: "EF014188: Colour of board"
  },
  {
    featureId: "EF014192",
    featureDesc: "Width of foil",
    label: "EF014192: Width of foil"
  },
  {
    featureId: "EF014193",
    featureDesc: "Length of foil",
    label: "EF014193: Length of foil"
  },
  {
    featureId: "EF014194",
    featureDesc: "With filing strip",
    label: "EF014194: With filing strip"
  },
  {
    featureId: "EF014195",
    featureDesc: "Table height",
    label: "EF014195: Table height"
  },
  {
    featureId: "EF014196",
    featureDesc: "Return speed",
    label: "EF014196: Return speed"
  },
  {
    featureId: "EF014197",
    featureDesc: "Split force",
    label: "EF014197: Split force"
  },
  {
    featureId: "EF014198",
    featureDesc: "Max. log length",
    label: "EF014198: Max. log length"
  },
  {
    featureId: "EF014199",
    featureDesc: "Max. log diameter",
    label: "EF014199: Max. log diameter"
  },
  {
    featureId: "EF014200",
    featureDesc: "Forward speed",
    label: "EF014200: Forward speed"
  },
  {
    featureId: "EF014201",
    featureDesc: "For wooden stacking frame",
    label: "EF014201: For wooden stacking frame"
  },
  {
    featureId: "EF014202",
    featureDesc: "Edge height",
    label: "EF014202: Edge height"
  },
  {
    featureId: "EF014204",
    featureDesc: "Melting temperature",
    label: "EF014204: Melting temperature"
  },
  {
    featureId: "EF014205",
    featureDesc: "Measurement of CO2",
    label: "EF014205: Measurement of CO2"
  },
  {
    featureId: "EF014207",
    featureDesc: "Measurement of dewpoint",
    label: "EF014207: Measurement of dewpoint"
  },
  {
    featureId: "EF014208",
    featureDesc: "Measurement of VOC",
    label: "EF014208: Measurement of VOC"
  },
  {
    featureId: "EF014209",
    featureDesc: "Active temperature measurement",
    label: "EF014209: Active temperature measurement"
  },
  {
    featureId: "EF014210",
    featureDesc: "Passive temperature measurement",
    label: "EF014210: Passive temperature measurement"
  },
  {
    featureId: "EF014211",
    featureDesc: "Enthalpy measurement",
    label: "EF014211: Enthalpy measurement"
  },
  {
    featureId: "EF014213",
    featureDesc: "Particulate matter measurement (PM)",
    label: "EF014213: Particulate matter measurement (PM)"
  },
  {
    featureId: "EF014214",
    featureDesc: "CO2 measuring range",
    label: "EF014214: CO2 measuring range"
  },
  {
    featureId: "EF014215",
    featureDesc: "Material thickness",
    label: "EF014215: Material thickness"
  },
  {
    featureId: "EF014216",
    featureDesc: "Measurement of absolute air humidity",
    label: "EF014216: Measurement of absolute air humidity"
  },
  {
    featureId: "EF014217",
    featureDesc: "LoRa technology",
    label: "EF014217: LoRa technology"
  },
  {
    featureId: "EF014218",
    featureDesc: "VOC measuring range",
    label: "EF014218: VOC measuring range"
  },
  {
    featureId: "EF014219",
    featureDesc: "Measuring range relative air humidity",
    label: "EF014219: Measuring range relative air humidity"
  },
  {
    featureId: "EF014220",
    featureDesc: "Measuring range absolute air humidity",
    label: "EF014220: Measuring range absolute air humidity"
  },
  {
    featureId: "EF014221",
    featureDesc: "Enthalpy measuring range",
    label: "EF014221: Enthalpy measuring range"
  },
  {
    featureId: "EF014222",
    featureDesc: "Dewpoint measuring range",
    label: "EF014222: Dewpoint measuring range"
  },
  {
    featureId: "EF014223",
    featureDesc: "Particulate matter measuring range (PM)",
    label: "EF014223: Particulate matter measuring range (PM)"
  },
  {
    featureId: "EF014224",
    featureDesc: "Number of parking spaces",
    label: "EF014224: Number of parking spaces"
  },
  {
    featureId: "EF014225",
    featureDesc: "Wheel gap",
    label: "EF014225: Wheel gap"
  },
  {
    featureId: "EF014226",
    featureDesc: "Operating range",
    label: "EF014226: Operating range"
  },
  {
    featureId: "EF014227",
    featureDesc: "Wrench width 3",
    label: "EF014227: Wrench width 3"
  },
  {
    featureId: "EF014228",
    featureDesc: "Wrench width 4",
    label: "EF014228: Wrench width 4"
  },
  {
    featureId: "EF014229",
    featureDesc: "Number of output signals",
    label: "EF014229: Number of output signals"
  },
  {
    featureId: "EF014230",
    featureDesc: "Scope of application, flow speed medium",
    label: "EF014230: Scope of application, flow speed medium"
  },
  {
    featureId: "EF014231",
    featureDesc: "Max. ambient humidity (non-condensing)",
    label: "EF014231: Max. ambient humidity (non-condensing)"
  },
  {
    featureId: "EF014232",
    featureDesc: "Brush rotational speed",
    label: "EF014232: Brush rotational speed"
  },
  {
    featureId: "EF014233",
    featureDesc: "Spray volume",
    label: "EF014233: Spray volume"
  },
  {
    featureId: "EF014234",
    featureDesc: "Brush motor power consumption",
    label: "EF014234: Brush motor power consumption"
  },
  {
    featureId: "EF014235",
    featureDesc: "Suction nozzle power consumption",
    label: "EF014235: Suction nozzle power consumption"
  },
  {
    featureId: "EF014237",
    featureDesc: "Working width",
    label: "EF014237: Working width"
  },
  {
    featureId: "EF014238",
    featureDesc: "Cylinder brush length",
    label: "EF014238: Cylinder brush length"
  },
  {
    featureId: "EF014239",
    featureDesc: "Suction nozzle width",
    label: "EF014239: Suction nozzle width"
  },
  {
    featureId: "EF014240",
    featureDesc: "Operating speed",
    label: "EF014240: Operating speed"
  },
  {
    featureId: "EF014241",
    featureDesc: "Area efficiency",
    label: "EF014241: Area efficiency"
  },
  {
    featureId: "EF014243",
    featureDesc: "Surface pressure",
    label: "EF014243: Surface pressure"
  },
  {
    featureId: "EF014244",
    featureDesc: "Pre-sweep device",
    label: "EF014244: Pre-sweep device"
  },
  {
    featureId: "EF014245",
    featureDesc: "Working width sweep equipment",
    label: "EF014245: Working width sweep equipment"
  },
  {
    featureId: "EF014246",
    featureDesc: "Drive engine power consumption",
    label: "EF014246: Drive engine power consumption"
  },
  {
    featureId: "EF014248",
    featureDesc: "Tip width",
    label: "EF014248: Tip width"
  },
  {
    featureId: "EF014249",
    featureDesc: "Circulation diameter",
    label: "EF014249: Circulation diameter"
  },
  {
    featureId: "EF014250",
    featureDesc: "Tip height",
    label: "EF014250: Tip height"
  },
  {
    featureId: "EF014251",
    featureDesc: "Swivel length",
    label: "EF014251: Swivel length"
  },
  {
    featureId: "EF014252",
    featureDesc: "Cutting height",
    label: "EF014252: Cutting height"
  },
  {
    featureId: "EF014253",
    featureDesc: "Fence length",
    label: "EF014253: Fence length"
  },
  {
    featureId: "EF014254",
    featureDesc: "Table width",
    label: "EF014254: Table width"
  },
  {
    featureId: "EF014255",
    featureDesc: "Table length",
    label: "EF014255: Table length"
  },
  {
    featureId: "EF014256",
    featureDesc: "Dimension coupling sleeve (for spindle)",
    label: "EF014256: Dimension coupling sleeve (for spindle)"
  },
  {
    featureId: "EF014257",
    featureDesc: "Throat",
    label: "EF014257: Throat"
  },
  {
    featureId: "EF014258",
    featureDesc: "Max. cutting height",
    label: "EF014258: Max. cutting height"
  },
  {
    featureId: "EF014259",
    featureDesc: "Max. cutting width",
    label: "EF014259: Max. cutting width"
  },
  {
    featureId: "EF014260",
    featureDesc: "Max. cutting length",
    label: "EF014260: Max. cutting length"
  },
  {
    featureId: "EF014261",
    featureDesc: "Pre carving saw",
    label: "EF014261: Pre carving saw"
  },
  {
    featureId: "EF014262",
    featureDesc: "Max. cutting width at 90°",
    label: "EF014262: Max. cutting width at 90°"
  },
  {
    featureId: "EF014263",
    featureDesc: "Max. cutting height at 90°",
    label: "EF014263: Max. cutting height at 90°"
  },
  {
    featureId: "EF014264",
    featureDesc: "Max. compressor power",
    label: "EF014264: Max. compressor power"
  },
  {
    featureId: "EF014266",
    featureDesc: "Depositional particle size",
    label: "EF014266: Depositional particle size"
  },
  {
    featureId: "EF014267",
    featureDesc: "Residual oil aerosol content",
    label: "EF014267: Residual oil aerosol content"
  },
  {
    featureId: "EF014268",
    featureDesc: "Residual oil vapor content",
    label: "EF014268: Residual oil vapor content"
  },
  {
    featureId: "EF014273",
    featureDesc: "Material volume regulation",
    label: "EF014273: Material volume regulation"
  },
  {
    featureId: "EF014276",
    featureDesc: "For rivet type",
    label: "EF014276: For rivet type"
  },
  {
    featureId: "EF014281",
    featureDesc: "Max. sheet thickness steel 400 N/mm²",
    label: "EF014281: Max. sheet thickness steel 400 N/mm²"
  },
  {
    featureId: "EF014282",
    featureDesc: "Fold width",
    label: "EF014282: Fold width"
  },
  {
    featureId: "EF014285",
    featureDesc: "Cartridge support rotatable",
    label: "EF014285: Cartridge support rotatable"
  },
  {
    featureId: "EF014288",
    featureDesc: "Air volume regulation",
    label: "EF014288: Air volume regulation"
  },
  {
    featureId: "EF014289",
    featureDesc: "Rubber nozzle protection",
    label: "EF014289: Rubber nozzle protection"
  },
  {
    featureId: "EF014293",
    featureDesc: "Number of wheels",
    label: "EF014293: Number of wheels"
  },
  {
    featureId: "EF014297",
    featureDesc: "Volume catch basin",
    label: "EF014297: Volume catch basin"
  },
  {
    featureId: "EF014298",
    featureDesc: "Number of axle mounts",
    label: "EF014298: Number of axle mounts"
  },
  {
    featureId: "EF014300",
    featureDesc: "Incline of stand",
    label: "EF014300: Incline of stand"
  },
  {
    featureId: "EF014301",
    featureDesc: "Horizontal load",
    label: "EF014301: Horizontal load"
  },
  {
    featureId: "EF014302",
    featureDesc: "Shelf width",
    label: "EF014302: Shelf width"
  },
  {
    featureId: "EF014303",
    featureDesc: "Load capacity per panel",
    label: "EF014303: Load capacity per panel"
  },
  {
    featureId: "EF014305",
    featureDesc: "Hole thickness",
    label: "EF014305: Hole thickness"
  },
  {
    featureId: "EF014306",
    featureDesc: "Square punch hole",
    label: "EF014306: Square punch hole"
  },
  {
    featureId: "EF014309",
    featureDesc: "Number of folder heights",
    label: "EF014309: Number of folder heights"
  },
  {
    featureId: "EF014311",
    featureDesc: "Stand height",
    label: "EF014311: Stand height"
  },
  {
    featureId: "EF014315",
    featureDesc: "Handrail",
    label: "EF014315: Handrail"
  },
  {
    featureId: "EF014316",
    featureDesc: "Wheeled",
    label: "EF014316: Wheeled"
  },
  {
    featureId: "EF014320",
    featureDesc: "Max. opening front vise",
    label: "EF014320: Max. opening front vise"
  },
  {
    featureId: "EF014321",
    featureDesc: "Max. opening tail vise",
    label: "EF014321: Max. opening tail vise"
  },
  {
    featureId: "EF014327",
    featureDesc: "Water output cooled",
    label: "EF014327: Water output cooled"
  },
  {
    featureId: "EF014329",
    featureDesc: "Water output not cooled",
    label: "EF014329: Water output not cooled"
  },
  {
    featureId: "EF014330",
    featureDesc: "Water output hot",
    label: "EF014330: Water output hot"
  },
  {
    featureId: "EF014331",
    featureDesc: "Base area",
    label: "EF014331: Base area"
  },
  {
    featureId: "EF014332",
    featureDesc: "With crane suspension point",
    label: "EF014332: With crane suspension point"
  },
  {
    featureId: "EF014333",
    featureDesc: "With window",
    label: "EF014333: With window"
  },
  {
    featureId: "EF014334",
    featureDesc: "Collecting volume",
    label: "EF014334: Collecting volume"
  },
  {
    featureId: "EF014335",
    featureDesc: "Colour of cover",
    label: "EF014335: Colour of cover"
  },
  {
    featureId: "EF014336",
    featureDesc: "Colour of stand",
    label: "EF014336: Colour of stand"
  },
  {
    featureId: "EF014338",
    featureDesc: "Surface protection stand",
    label: "EF014338: Surface protection stand"
  },
  {
    featureId: "EF014339",
    featureDesc: "Annual cooling energy consumption (350 hours - full load)",
    label: "EF014339: Annual cooling energy consumption (350 hours - full load)"
  },
  {
    featureId: "EF014342",
    featureDesc: "Spacing between the fork brackets/-bags",
    label: "EF014342: Spacing between the fork brackets/-bags"
  },
  {
    featureId: "EF014343",
    featureDesc: "Clear height bracket/pocket",
    label: "EF014343: Clear height bracket/pocket"
  },
  {
    featureId: "EF014345",
    featureDesc: "Central lock",
    label: "EF014345: Central lock"
  },
  {
    featureId: "EF014348",
    featureDesc: "Air pressure",
    label: "EF014348: Air pressure"
  },
  {
    featureId: "EF014349",
    featureDesc: "Air consumption",
    label: "EF014349: Air consumption"
  },
  {
    featureId: "EF014350",
    featureDesc: "Water quantity",
    label: "EF014350: Water quantity"
  },
  {
    featureId: "EF014351",
    featureDesc: "Blasting agent quantity",
    label: "EF014351: Blasting agent quantity"
  },
  {
    featureId: "EF014352",
    featureDesc: "Power consumption water pump",
    label: "EF014352: Power consumption water pump"
  },
  {
    featureId: "EF014353",
    featureDesc: "Power consumption suction nozzle",
    label: "EF014353: Power consumption suction nozzle"
  },
  {
    featureId: "EF014361",
    featureDesc: "Max. high frequency power",
    label: "EF014361: Max. high frequency power"
  },
  {
    featureId: "EF014362",
    featureDesc: "Max. ultrasonic power",
    label: "EF014362: Max. ultrasonic power"
  },
  {
    featureId: "EF014363",
    featureDesc: "Desktop device",
    label: "EF014363: Desktop device"
  },
  {
    featureId: "EF014364",
    featureDesc: "Air pressure",
    label: "EF014364: Air pressure"
  },
  {
    featureId: "EF014366",
    featureDesc: "Dry ice capacity",
    label: "EF014366: Dry ice capacity"
  },
  {
    featureId: "EF014367",
    featureDesc: "Dry ice consumption",
    label: "EF014367: Dry ice consumption"
  },
  {
    featureId: "EF014375",
    featureDesc: "Outer height rectangular insulation",
    label: "EF014375: Outer height rectangular insulation"
  },
  {
    featureId: "EF014376",
    featureDesc: "Cross section branch core",
    label: "EF014376: Cross section branch core"
  },
  {
    featureId: "EF014377",
    featureDesc: "Cross section through-wiring core",
    label: "EF014377: Cross section through-wiring core"
  },
  {
    featureId: "EF014379",
    featureDesc: "Connection size (in inch)",
    label: "EF014379: Connection size (in inch)"
  },
  {
    featureId: "EF014380",
    featureDesc: "Suitable for format",
    label: "EF014380: Suitable for format"
  },
  {
    featureId: "EF014381",
    featureDesc: "Max. number of sheets",
    label: "EF014381: Max. number of sheets"
  },
  {
    featureId: "EF014382",
    featureDesc: "Plastic coated head",
    label: "EF014382: Plastic coated head"
  },
  {
    featureId: "EF014383",
    featureDesc: "Transparent front cover",
    label: "EF014383: Transparent front cover"
  },
  {
    featureId: "EF014384",
    featureDesc: "Interior pocket for unpunched documents",
    label: "EF014384: Interior pocket for unpunched documents"
  },
  {
    featureId: "EF014390",
    featureDesc: "Hardness",
    label: "EF014390: Hardness"
  },
  {
    featureId: "EF014391",
    featureDesc: "Max. cohesiveness",
    label: "EF014391: Max. cohesiveness"
  },
  {
    featureId: "EF014392",
    featureDesc: "Low temperature resistance",
    label: "EF014392: Low temperature resistance"
  },
  {
    featureId: "EF014393",
    featureDesc: "Varnish tolerance",
    label: "EF014393: Varnish tolerance"
  },
  {
    featureId: "EF014394",
    featureDesc: "Flame-retarding",
    label: "EF014394: Flame-retarding"
  },
  {
    featureId: "EF014395",
    featureDesc: "Semi-transparent",
    label: "EF014395: Semi-transparent"
  },
  {
    featureId: "EF014396",
    featureDesc: "Releasable",
    label: "EF014396: Releasable"
  },
  {
    featureId: "EF014397",
    featureDesc: "Locking pin",
    label: "EF014397: Locking pin"
  },
  {
    featureId: "EF014398",
    featureDesc: "Number of locking pins",
    label: "EF014398: Number of locking pins"
  },
  {
    featureId: "EF014399",
    featureDesc: "Grip location",
    label: "EF014399: Grip location"
  },
  {
    featureId: "EF014400",
    featureDesc: "Tilt bearing",
    label: "EF014400: Tilt bearing"
  },
  {
    featureId: "EF014401",
    featureDesc: "Faceplate wing",
    label: "EF014401: Faceplate wing"
  },
  {
    featureId: "EF014402",
    featureDesc: "Cleat",
    label: "EF014402: Cleat"
  },
  {
    featureId: "EF014403",
    featureDesc: "Spread drive",
    label: "EF014403: Spread drive"
  },
  {
    featureId: "EF014404",
    featureDesc: "Material of window",
    label: "EF014404: Material of window"
  },
  {
    featureId: "EF014408",
    featureDesc: "Fence direction",
    label: "EF014408: Fence direction"
  },
  {
    featureId: "EF014409",
    featureDesc: "Tilt first",
    label: "EF014409: Tilt first"
  },
  {
    featureId: "EF014410",
    featureDesc: "Lockable in position",
    label: "EF014410: Lockable in position"
  },
  {
    featureId: "EF014411",
    featureDesc: "Seating on",
    label: "EF014411: Seating on"
  },
  {
    featureId: "EF014412",
    featureDesc: "Hang up/out possible",
    label: "EF014412: Hang up/out possible"
  },
  {
    featureId: "EF014413",
    featureDesc: "Second scissors",
    label: "EF014413: Second scissors"
  },
  {
    featureId: "EF014416",
    featureDesc: "With recessed plinth",
    label: "EF014416: With recessed plinth"
  },
  {
    featureId: "EF014417",
    featureDesc: "Shape of side panel",
    label: "EF014417: Shape of side panel"
  },
  {
    featureId: "EF014424",
    featureDesc: "With impact sound insulation",
    label: "EF014424: With impact sound insulation"
  },
  {
    featureId: "EF014436",
    featureDesc: "With thermal actuator",
    label: "EF014436: With thermal actuator"
  },
  {
    featureId: "EF014438",
    featureDesc: "Number of CEE socket outlets (IEC 60309) 32 A / 230 V / 4P (1, 2, 3, PE)",
    label: "EF014438: Number of CEE socket outlets (IEC 60309) 32 A / 230 V / 4P (1, 2, 3, PE)"
  },
  {
    featureId: "EF014439",
    featureDesc: "Number of CEE socket outlets (IEC 60309) 16 A / 230 V / 5P (1, 2, 3, N, PE)",
    label: "EF014439: Number of CEE socket outlets (IEC 60309) 16 A / 230 V / 5P (1, 2, 3, N, PE)"
  },
  {
    featureId: "EF014440",
    featureDesc: "Number of CEE socket outlets (IEC 60309) 63 A / 230 V / 4P (1, 2, 3, PE)",
    label: "EF014440: Number of CEE socket outlets (IEC 60309) 63 A / 230 V / 4P (1, 2, 3, PE)"
  },
  {
    featureId: "EF014441",
    featureDesc: "Number of CEE socket outlets (IEC 60309) 125 A / 230 V / 4P (1, 2, 3, PE)",
    label: "EF014441: Number of CEE socket outlets (IEC 60309) 125 A / 230 V / 4P (1, 2, 3, PE)"
  },
  {
    featureId: "EF014442",
    featureDesc: "Number of earth leakage switches 100 mA",
    label: "EF014442: Number of earth leakage switches 100 mA"
  },
  {
    featureId: "EF014446",
    featureDesc: "Screw hole distance",
    label: "EF014446: Screw hole distance"
  },
  {
    featureId: "EF014447",
    featureDesc: "Steerable",
    label: "EF014447: Steerable"
  },
  {
    featureId: "EF014449",
    featureDesc: "Type of interlocking",
    label: "EF014449: Type of interlocking"
  },
  {
    featureId: "EF014454",
    featureDesc: "Consumption per m²",
    label: "EF014454: Consumption per m²"
  },
  {
    featureId: "EF014456",
    featureDesc: "Working width main sweep expander",
    label: "EF014456: Working width main sweep expander"
  },
  {
    featureId: "EF014457",
    featureDesc: "Working width with one side broom",
    label: "EF014457: Working width with one side broom"
  },
  {
    featureId: "EF014458",
    featureDesc: "Working width with two side brooms",
    label: "EF014458: Working width with two side brooms"
  },
  {
    featureId: "EF014459",
    featureDesc: "Number of main sweeping barrels",
    label: "EF014459: Number of main sweeping barrels"
  },
  {
    featureId: "EF014460",
    featureDesc: "Sweeping principle",
    label: "EF014460: Sweeping principle"
  },
  {
    featureId: "EF014461",
    featureDesc: "With drive",
    label: "EF014461: With drive"
  },
  {
    featureId: "EF014462",
    featureDesc: "Climbing power",
    label: "EF014462: Climbing power"
  },
  {
    featureId: "EF014463",
    featureDesc: "Deposition level of filter",
    label: "EF014463: Deposition level of filter"
  },
  {
    featureId: "EF014464",
    featureDesc: "Weighing range",
    label: "EF014464: Weighing range"
  },
  {
    featureId: "EF014465",
    featureDesc: "Measurement subdivision",
    label: "EF014465: Measurement subdivision"
  },
  {
    featureId: "EF014466",
    featureDesc: "Length of weighing area",
    label: "EF014466: Length of weighing area"
  },
  {
    featureId: "EF014467",
    featureDesc: "Width of weighing area",
    label: "EF014467: Width of weighing area"
  },
  {
    featureId: "EF014470",
    featureDesc: "With binocular cone",
    label: "EF014470: With binocular cone"
  },
  {
    featureId: "EF014471",
    featureDesc: "Worktop width",
    label: "EF014471: Worktop width"
  },
  {
    featureId: "EF014472",
    featureDesc: "Worktop depth",
    label: "EF014472: Worktop depth"
  },
  {
    featureId: "EF014473",
    featureDesc: "Max. additional load (when stacking)",
    label: "EF014473: Max. additional load (when stacking)"
  },
  {
    featureId: "EF014477",
    featureDesc: "Closing path",
    label: "EF014477: Closing path"
  },
  {
    featureId: "EF014478",
    featureDesc: "Type of key",
    label: "EF014478: Type of key"
  },
  {
    featureId: "EF014480",
    featureDesc: "Nozzle bore",
    label: "EF014480: Nozzle bore"
  },
  {
    featureId: "EF014481",
    featureDesc: "Measuring unit vibration acceleration (RMS)",
    label: "EF014481: Measuring unit vibration acceleration (RMS)"
  },
  {
    featureId: "EF014486",
    featureDesc: "Must be brushed afterwards",
    label: "EF014486: Must be brushed afterwards"
  },
  {
    featureId: "EF014488",
    featureDesc: "Water flow adjustable",
    label: "EF014488: Water flow adjustable"
  },
  {
    featureId: "EF014489",
    featureDesc: "Number of markers",
    label: "EF014489: Number of markers"
  },
  {
    featureId: "EF014490",
    featureDesc: "With clip",
    label: "EF014490: With clip"
  },
  {
    featureId: "EF014491",
    featureDesc: "With level indicator",
    label: "EF014491: With level indicator"
  },
  {
    featureId: "EF014492",
    featureDesc: "Number of lead pencils",
    label: "EF014492: Number of lead pencils"
  },
  {
    featureId: "EF014493",
    featureDesc: "Lead size",
    label: "EF014493: Lead size"
  },
  {
    featureId: "EF014494",
    featureDesc: "Number of display panels",
    label: "EF014494: Number of display panels"
  },
  {
    featureId: "EF014495",
    featureDesc: "Fold height",
    label: "EF014495: Fold height"
  },
  {
    featureId: "EF014496",
    featureDesc: "Stroke air",
    label: "EF014496: Stroke air"
  },
  {
    featureId: "EF014503",
    featureDesc: "With cam",
    label: "EF014503: With cam"
  },
  {
    featureId: "EF014504",
    featureDesc: "Cam diameter",
    label: "EF014504: Cam diameter"
  },
  {
    featureId: "EF014505",
    featureDesc: "Pin length",
    label: "EF014505: Pin length"
  },
  {
    featureId: "EF014506",
    featureDesc: "Rod protrusion",
    label: "EF014506: Rod protrusion"
  },
  {
    featureId: "EF014510",
    featureDesc: "With gas flow monitor",
    label: "EF014510: With gas flow monitor"
  },
  {
    featureId: "EF014511",
    featureDesc: "With thermally actuated shut-off device",
    label: "EF014511: With thermally actuated shut-off device"
  },
  {
    featureId: "EF014512",
    featureDesc: "Adjustment aid for visually impaired people",
    label: "EF014512: Adjustment aid for visually impaired people"
  },
  {
    featureId: "EF014514",
    featureDesc: "HTB (higher thermal load capacity)",
    label: "EF014514: HTB (higher thermal load capacity)"
  },
  {
    featureId: "EF014515",
    featureDesc: "Suitable for actuator",
    label: "EF014515: Suitable for actuator"
  },
  {
    featureId: "EF014516",
    featureDesc: "Actuator connection",
    label: "EF014516: Actuator connection"
  },
  {
    featureId: "EF014519",
    featureDesc: "With insulation shell",
    label: "EF014519: With insulation shell"
  },
  {
    featureId: "EF014529",
    featureDesc: "Preset sealable/lockable",
    label: "EF014529: Preset sealable/lockable"
  },
  {
    featureId: "EF014530",
    featureDesc: "Type of actuator",
    label: "EF014530: Type of actuator"
  },
  {
    featureId: "EF014536",
    featureDesc: "Number of preset values/levels",
    label: "EF014536: Number of preset values/levels"
  },
  {
    featureId: "EF014547",
    featureDesc: "Thermostat connection direction",
    label: "EF014547: Thermostat connection direction"
  },
  {
    featureId: "EF014551",
    featureDesc: "With test connection",
    label: "EF014551: With test connection"
  },
  {
    featureId: "EF014552",
    featureDesc: "Type of test connection",
    label: "EF014552: Type of test connection"
  },
  {
    featureId: "EF014553",
    featureDesc: "Size of test spigot",
    label: "EF014553: Size of test spigot"
  },
  {
    featureId: "EF014559",
    featureDesc: "Type of tread",
    label: "EF014559: Type of tread"
  },
  {
    featureId: "EF014561",
    featureDesc: "Program function",
    label: "EF014561: Program function"
  },
  {
    featureId: "EF014562",
    featureDesc: "Type of internet connection",
    label: "EF014562: Type of internet connection"
  },
  {
    featureId: "EF014563",
    featureDesc: "Supporting protocol for RTS",
    label: "EF014563: Supporting protocol for RTS"
  },
  {
    featureId: "EF014564",
    featureDesc: "Supporting protocol for Z-Wave",
    label: "EF014564: Supporting protocol for Z-Wave"
  },
  {
    featureId: "EF014566",
    featureDesc: "Supporting protocol for EnOcean",
    label: "EF014566: Supporting protocol for EnOcean"
  },
  {
    featureId: "EF014567",
    featureDesc: "Supporting protocol for io-homecontrol",
    label: "EF014567: Supporting protocol for io-homecontrol"
  },
  {
    featureId: "EF014568",
    featureDesc: "Supporting protocol for ZigBee",
    label: "EF014568: Supporting protocol for ZigBee"
  },
  {
    featureId: "EF014570",
    featureDesc: "Control of lighting elements",
    label: "EF014570: Control of lighting elements"
  },
  {
    featureId: "EF014571",
    featureDesc: "Control of opening elements (shutters, doors)",
    label: "EF014571: Control of opening elements (shutters, doors)"
  },
  {
    featureId: "EF014572",
    featureDesc: "Control of heating elements",
    label: "EF014572: Control of heating elements"
  },
  {
    featureId: "EF014573",
    featureDesc: "Control of hot water elements",
    label: "EF014573: Control of hot water elements"
  },
  {
    featureId: "EF014574",
    featureDesc: "Control of AC/ventilation",
    label: "EF014574: Control of AC/ventilation"
  },
  {
    featureId: "EF014575",
    featureDesc: "Control of multimedia elements",
    label: "EF014575: Control of multimedia elements"
  },
  {
    featureId: "EF014576",
    featureDesc: "Control of security elements",
    label: "EF014576: Control of security elements"
  },
  {
    featureId: "EF014577",
    featureDesc: "Tile height",
    label: "EF014577: Tile height"
  },
  {
    featureId: "EF014578",
    featureDesc: "Temperature limit retrofittable",
    label: "EF014578: Temperature limit retrofittable"
  },
  {
    featureId: "EF014581",
    featureDesc: "With wax cooling",
    label: "EF014581: With wax cooling"
  },
  {
    featureId: "EF014582",
    featureDesc: "Suitable for dust extraction",
    label: "EF014582: Suitable for dust extraction"
  },
  {
    featureId: "EF014584",
    featureDesc: "With adapter for dust extraction",
    label: "EF014584: With adapter for dust extraction"
  },
  {
    featureId: "EF014592",
    featureDesc: "Venturi nozzle",
    label: "EF014592: Venturi nozzle"
  },
  {
    featureId: "EF014597",
    featureDesc: "Number of socket outlets French standard type E",
    label: "EF014597: Number of socket outlets French standard type E"
  },
  {
    featureId: "EF014598",
    featureDesc: "Number of CAT 6A connections",
    label: "EF014598: Number of CAT 6A connections"
  },
  {
    featureId: "EF014599",
    featureDesc: "RAL-number pipe",
    label: "EF014599: RAL-number pipe"
  },
  {
    featureId: "EF014600",
    featureDesc: "Wall thickness mantle pipe",
    label: "EF014600: Wall thickness mantle pipe"
  },
  {
    featureId: "EF014601",
    featureDesc: "Material mantle pipe",
    label: "EF014601: Material mantle pipe"
  },
  {
    featureId: "EF014605",
    featureDesc: "With milling grooves under head",
    label: "EF014605: With milling grooves under head"
  },
  {
    featureId: "EF014607",
    featureDesc: "Number per strip",
    label: "EF014607: Number per strip"
  },
  {
    featureId: "EF014611",
    featureDesc: "Number of socket outlets with miniature circuit breaker (MCB)",
    label: "EF014611: Number of socket outlets with miniature circuit breaker (MCB)"
  },
  {
    featureId: "EF014613",
    featureDesc: "Current of miniature circuit breaker (MCB) socket outlets",
    label: "EF014613: Current of miniature circuit breaker (MCB) socket outlets"
  },
  {
    featureId: "EF014614",
    featureDesc: "With opening for circuit breaker reset button",
    label: "EF014614: With opening for circuit breaker reset button"
  },
  {
    featureId: "EF014615",
    featureDesc: "Number of water taps",
    label: "EF014615: Number of water taps"
  },
  {
    featureId: "EF014628",
    featureDesc: "Suitable for reboring",
    label: "EF014628: Suitable for reboring"
  },
  {
    featureId: "EF014637",
    featureDesc: "With sink",
    label: "EF014637: With sink"
  },
  {
    featureId: "EF014638",
    featureDesc: "Material sink",
    label: "EF014638: Material sink"
  },
  {
    featureId: "EF014640",
    featureDesc: "Surface treatment cap",
    label: "EF014640: Surface treatment cap"
  },
  {
    featureId: "EF014643",
    featureDesc: "With plug for CH (SEV 1011 type 11) (type J)",
    label: "EF014643: With plug for CH (SEV 1011 type 11) (type J)"
  },
  {
    featureId: "EF014644",
    featureDesc: "With plug for CH (SEV 1011 type 12) (type J)",
    label: "EF014644: With plug for CH (SEV 1011 type 12) (type J)"
  },
  {
    featureId: "EF014645",
    featureDesc: "With plug for CH (SEV 1011 type 23) (type J)",
    label: "EF014645: With plug for CH (SEV 1011 type 23) (type J)"
  },
  {
    featureId: "EF014646",
    featureDesc: "With plug for BE/DE/NL/RU/CH (Euro) (type C)",
    label: "EF014646: With plug for BE/DE/NL/RU/CH (Euro) (type C)"
  },
  {
    featureId: "EF014648",
    featureDesc: "Test voltage",
    label: "EF014648: Test voltage"
  },
  {
    featureId: "EF014649",
    featureDesc: "Test current",
    label: "EF014649: Test current"
  },
  {
    featureId: "EF014650",
    featureDesc: "Test frequency",
    label: "EF014650: Test frequency"
  },
  {
    featureId: "EF014652",
    featureDesc: "Headphone",
    label: "EF014652: Headphone"
  },
  {
    featureId: "EF014653",
    featureDesc: "With integrated washboard",
    label: "EF014653: With integrated washboard"
  },
  {
    featureId: "EF014654",
    featureDesc: "Size tolerance according to EN 772-16",
    label: "EF014654: Size tolerance according to EN 772-16"
  },
  {
    featureId: "EF014655",
    featureDesc: "Size range according to EN 772-16",
    label: "EF014655: Size range according to EN 772-16"
  },
  {
    featureId: "EF014656",
    featureDesc: "Tolerance voluminous mass class according to EN 771-1",
    label: "EF014656: Tolerance voluminous mass class according to EN 771-1"
  },
  {
    featureId: "EF014658",
    featureDesc: "Water vapour permeability (µ) according to EN 1745",
    label: "EF014658: Water vapour permeability (µ) according to EN 1745"
  },
  {
    featureId: "EF014659",
    featureDesc: "Thermal conductivity according to EN 1745 (lambda-10, dry, P=50 %)",
    label: "EF014659: Thermal conductivity according to EN 1745 (lambda-10, dry, P=50 %)"
  },
  {
    featureId: "EF014660",
    featureDesc: "With space to build in dishwasher/washing machine",
    label: "EF014660: With space to build in dishwasher/washing machine"
  },
  {
    featureId: "EF014662",
    featureDesc: "Content of active soluble salts according to EN 772-5",
    label: "EF014662: Content of active soluble salts according to EN 772-5"
  },
  {
    featureId: "EF014664",
    featureDesc: "Height (nominal)",
    label: "EF014664: Height (nominal)"
  },
  {
    featureId: "EF014665",
    featureDesc: "Height (production size)",
    label: "EF014665: Height (production size)"
  },
  {
    featureId: "EF014667",
    featureDesc: "Perforated inner wall",
    label: "EF014667: Perforated inner wall"
  },
  {
    featureId: "EF014668",
    featureDesc: "Can be used with a multimeter",
    label: "EF014668: Can be used with a multimeter"
  },
  {
    featureId: "EF014669",
    featureDesc: "Can be used with a clamp meter",
    label: "EF014669: Can be used with a clamp meter"
  },
  {
    featureId: "EF014670",
    featureDesc: "Can be used with an appliance tester",
    label: "EF014670: Can be used with an appliance tester"
  },
  {
    featureId: "EF014671",
    featureDesc: "Can be used with an installation tester",
    label: "EF014671: Can be used with an installation tester"
  },
  {
    featureId: "EF014672",
    featureDesc: "Can be used with an earth ground tester",
    label: "EF014672: Can be used with an earth ground tester"
  },
  {
    featureId: "EF014673",
    featureDesc: "Can be used with a machine tester",
    label: "EF014673: Can be used with a machine tester"
  },
  {
    featureId: "EF014674",
    featureDesc: "Can be used with a power analyser",
    label: "EF014674: Can be used with a power analyser"
  },
  {
    featureId: "EF014675",
    featureDesc: "Can be used with a process calibrator",
    label: "EF014675: Can be used with a process calibrator"
  },
  {
    featureId: "EF014676",
    featureDesc: "Can be used with an environmental instrument",
    label: "EF014676: Can be used with an environmental instrument"
  },
  {
    featureId: "EF014677",
    featureDesc: "Can be used with an oscilloscope",
    label: "EF014677: Can be used with an oscilloscope"
  },
  {
    featureId: "EF014678",
    featureDesc: "Can be used with a thermal camera",
    label: "EF014678: Can be used with a thermal camera"
  },
  {
    featureId: "EF014679",
    featureDesc: "Material shell",
    label: "EF014679: Material shell"
  },
  {
    featureId: "EF014680",
    featureDesc: "Surface protection shell",
    label: "EF014680: Surface protection shell"
  },
  {
    featureId: "EF014681",
    featureDesc: "With counter shell",
    label: "EF014681: With counter shell"
  },
  {
    featureId: "EF014682",
    featureDesc: "Material counter shell",
    label: "EF014682: Material counter shell"
  },
  {
    featureId: "EF014683",
    featureDesc: "Water absorption according to EN 772-21",
    label: "EF014683: Water absorption according to EN 772-21"
  },
  {
    featureId: "EF014684",
    featureDesc: "Material quality clamp",
    label: "EF014684: Material quality clamp"
  },
  {
    featureId: "EF014685",
    featureDesc: "Material quality shell",
    label: "EF014685: Material quality shell"
  },
  {
    featureId: "EF014686",
    featureDesc: "Material quality screw",
    label: "EF014686: Material quality screw"
  },
  {
    featureId: "EF014687",
    featureDesc: "In container",
    label: "EF014687: In container"
  },
  {
    featureId: "EF014688",
    featureDesc: "Net content dust",
    label: "EF014688: Net content dust"
  },
  {
    featureId: "EF014689",
    featureDesc: "Net content water",
    label: "EF014689: Net content water"
  },
  {
    featureId: "EF014690",
    featureDesc: "With automatic filter cleaning",
    label: "EF014690: With automatic filter cleaning"
  },
  {
    featureId: "EF014691",
    featureDesc: "Max. free drillspace",
    label: "EF014691: Max. free drillspace"
  },
  {
    featureId: "EF014692",
    featureDesc: "With reverse polarity protection",
    label: "EF014692: With reverse polarity protection"
  },
  {
    featureId: "EF014693",
    featureDesc: "Number of measuring points",
    label: "EF014693: Number of measuring points"
  },
  {
    featureId: "EF014694",
    featureDesc: "Efficiency supply filter according to ISO 16890-1:2016",
    label: "EF014694: Efficiency supply filter according to ISO 16890-1:2016"
  },
  {
    featureId: "EF014695",
    featureDesc: "Offset setting possible",
    label: "EF014695: Offset setting possible"
  },
  {
    featureId: "EF014696",
    featureDesc: "Potentiometer with adjustment knob",
    label: "EF014696: Potentiometer with adjustment knob"
  },
  {
    featureId: "EF014698",
    featureDesc: "With external carbon dioxide (CO2) sensor",
    label: "EF014698: With external carbon dioxide (CO2) sensor"
  },
  {
    featureId: "EF014699",
    featureDesc: "Suitable for (extra) carbon dioxide (CO2) sensor",
    label: "EF014699: Suitable for (extra) carbon dioxide (CO2) sensor"
  },
  {
    featureId: "EF014700",
    featureDesc: "With internal humidity (RH) sensor",
    label: "EF014700: With internal humidity (RH) sensor"
  },
  {
    featureId: "EF014701",
    featureDesc: "With external humidity (RH) sensor",
    label: "EF014701: With external humidity (RH) sensor"
  },
  {
    featureId: "EF014702",
    featureDesc: "Suitable for (extra) humidity (RH) sensor",
    label: "EF014702: Suitable for (extra) humidity (RH) sensor"
  },
  {
    featureId: "EF014703",
    featureDesc: "Suitable for (extra) pressure sensor",
    label: "EF014703: Suitable for (extra) pressure sensor"
  },
  {
    featureId: "EF014706",
    featureDesc: "With wired control unit",
    label: "EF014706: With wired control unit"
  },
  {
    featureId: "EF014707",
    featureDesc: "Suitable for wired control unit",
    label: "EF014707: Suitable for wired control unit"
  },
  {
    featureId: "EF014708",
    featureDesc: "With zone controller",
    label: "EF014708: With zone controller"
  },
  {
    featureId: "EF014709",
    featureDesc: "Suitable for zone controller",
    label: "EF014709: Suitable for zone controller"
  },
  {
    featureId: "EF014710",
    featureDesc: "Rated system voltage 36 V DC",
    label: "EF014710: Rated system voltage 36 V DC"
  },
  {
    featureId: "EF014711",
    featureDesc: "Rated system voltage 60 V DC",
    label: "EF014711: Rated system voltage 60 V DC"
  },
  {
    featureId: "EF014712",
    featureDesc: "Rated system voltage 72 V DC",
    label: "EF014712: Rated system voltage 72 V DC"
  },
  {
    featureId: "EF014714",
    featureDesc: "With magnifier",
    label: "EF014714: With magnifier"
  },
  {
    featureId: "EF014715",
    featureDesc: "Suitable for PCB",
    label: "EF014715: Suitable for PCB"
  },
  {
    featureId: "EF014716",
    featureDesc: "Suitable for vehicle panels",
    label: "EF014716: Suitable for vehicle panels"
  },
  {
    featureId: "EF014717",
    featureDesc: "Suitable for use in an IP30 system",
    label: "EF014717: Suitable for use in an IP30 system"
  },
  {
    featureId: "EF014719",
    featureDesc: "Suitable for use in an IPXXD system",
    label: "EF014719: Suitable for use in an IPXXD system"
  },
  {
    featureId: "EF014720",
    featureDesc: "Suitable for use in an IP4X system",
    label: "EF014720: Suitable for use in an IP4X system"
  },
  {
    featureId: "EF014721",
    featureDesc: "Cover position",
    label: "EF014721: Cover position"
  },
  {
    featureId: "EF014722",
    featureDesc: "Gusseted model",
    label: "EF014722: Gusseted model"
  },
  {
    featureId: "EF014723",
    featureDesc: "Continuous volume flow (Q3)",
    label: "EF014723: Continuous volume flow (Q3)"
  },
  {
    featureId: "EF014724",
    featureDesc: "Height (large)",
    label: "EF014724: Height (large)"
  },
  {
    featureId: "EF014725",
    featureDesc: "Height (small)",
    label: "EF014725: Height (small)"
  },
  {
    featureId: "EF014726",
    featureDesc: "Sensor element outside housing",
    label: "EF014726: Sensor element outside housing"
  },
  {
    featureId: "EF014727",
    featureDesc: "Diameter sensor element",
    label: "EF014727: Diameter sensor element"
  },
  {
    featureId: "EF014728",
    featureDesc: "Material lining",
    label: "EF014728: Material lining"
  },
  {
    featureId: "EF014729",
    featureDesc: "Protection main switch",
    label: "EF014729: Protection main switch"
  },
  {
    featureId: "EF014730",
    featureDesc: "Protection incoming RCD",
    label: "EF014730: Protection incoming RCD"
  },
  {
    featureId: "EF014731",
    featureDesc: "Protection high integrity",
    label: "EF014731: Protection high integrity"
  },
  {
    featureId: "EF014732",
    featureDesc: "Protection split load",
    label: "EF014732: Protection split load"
  },
  {
    featureId: "EF014733",
    featureDesc: "Protection dual RCD",
    label: "EF014733: Protection dual RCD"
  },
  {
    featureId: "EF014736",
    featureDesc: "Number of outgoing ways",
    label: "EF014736: Number of outgoing ways"
  },
  {
    featureId: "EF014737",
    featureDesc: "Orthopaedic",
    label: "EF014737: Orthopaedic"
  },
  {
    featureId: "EF014738",
    featureDesc: "With bump-/scuff cap",
    label: "EF014738: With bump-/scuff cap"
  },
  {
    featureId: "EF014739",
    featureDesc: "With removable insole",
    label: "EF014739: With removable insole"
  },
  {
    featureId: "EF014740",
    featureDesc: "Material bump-/scuff cap",
    label: "EF014740: Material bump-/scuff cap"
  },
  {
    featureId: "EF014741",
    featureDesc: "With midsole",
    label: "EF014741: With midsole"
  },
  {
    featureId: "EF014742",
    featureDesc: "Material midsole",
    label: "EF014742: Material midsole"
  },
  {
    featureId: "EF014743",
    featureDesc: "Slip resistance according to ISO 20344:2011",
    label: "EF014743: Slip resistance according to ISO 20344:2011"
  },
  {
    featureId: "EF014744",
    featureDesc: "Heat insulation of sole (HL)",
    label: "EF014744: Heat insulation of sole (HL)"
  },
  {
    featureId: "EF014745",
    featureDesc: "Cold insulation of sole (CL)",
    label: "EF014745: Cold insulation of sole (CL)"
  },
  {
    featureId: "EF014746",
    featureDesc: "Fuel- and oil resistant (FO) according to ISO 20345:2011",
    label: "EF014746: Fuel- and oil resistant (FO) according to ISO 20345:2011"
  },
  {
    featureId: "EF014747",
    featureDesc: "Hydrocarbons resistant sole (ORO)",
    label: "EF014747: Hydrocarbons resistant sole (ORO)"
  },
  {
    featureId: "EF014748",
    featureDesc: "Hot contact resistant sole to 300 °C",
    label: "EF014748: Hot contact resistant sole to 300 °C"
  },
  {
    featureId: "EF014749",
    featureDesc: "Safety category footwear according to ISO 20345:2011",
    label: "EF014749: Safety category footwear according to ISO 20345:2011"
  },
  {
    featureId: "EF014750",
    featureDesc: "Average temperature measuring",
    label: "EF014750: Average temperature measuring"
  },
  {
    featureId: "EF014751",
    featureDesc: "With hose clamp",
    label: "EF014751: With hose clamp"
  },
  {
    featureId: "EF014752",
    featureDesc: "Tongued",
    label: "EF014752: Tongued"
  },
  {
    featureId: "EF014753",
    featureDesc: "With turning grooves",
    label: "EF014753: With turning grooves"
  },
  {
    featureId: "EF014754",
    featureDesc: "Suitable together with heat-conducting plate",
    label: "EF014754: Suitable together with heat-conducting plate"
  },
  {
    featureId: "EF014755",
    featureDesc: "With straight grooves",
    label: "EF014755: With straight grooves"
  },
  {
    featureId: "EF014756",
    featureDesc: "Max. centre distance of floor joists",
    label: "EF014756: Max. centre distance of floor joists"
  },
  {
    featureId: "EF014757",
    featureDesc: "Opaque glass",
    label: "EF014757: Opaque glass"
  },
  {
    featureId: "EF014758",
    featureDesc: "With window slit valve",
    label: "EF014758: With window slit valve"
  },
  {
    featureId: "EF014759",
    featureDesc: "Mounting angle",
    label: "EF014759: Mounting angle"
  },
  {
    featureId: "EF014761",
    featureDesc: "Suitable for roofs with roof tiles",
    label: "EF014761: Suitable for roofs with roof tiles"
  },
  {
    featureId: "EF014762",
    featureDesc: "Suitable for roof with bituminous roofing",
    label: "EF014762: Suitable for roof with bituminous roofing"
  },
  {
    featureId: "EF014763",
    featureDesc: "Suitable for roof with folded metal sheets",
    label: "EF014763: Suitable for roof with folded metal sheets"
  },
  {
    featureId: "EF014764",
    featureDesc: "Suitable for roof with corrugated metal sheets",
    label: "EF014764: Suitable for roof with corrugated metal sheets"
  },
  {
    featureId: "EF014765",
    featureDesc: "Suitable for roof with click metal sheets",
    label: "EF014765: Suitable for roof with click metal sheets"
  },
  {
    featureId: "EF014766",
    featureDesc: "Suitable for roof with shingles",
    label: "EF014766: Suitable for roof with shingles"
  },
  {
    featureId: "EF014767",
    featureDesc: "Burglar resistance glass according to EN 356",
    label: "EF014767: Burglar resistance glass according to EN 356"
  },
  {
    featureId: "EF014768",
    featureDesc: "Burglar resistance window according to EN 1627:2011",
    label: "EF014768: Burglar resistance window according to EN 1627:2011"
  },
  {
    featureId: "EF014774",
    featureDesc: "Flow rate per stroke of piston",
    label: "EF014774: Flow rate per stroke of piston"
  },
  {
    featureId: "EF014777",
    featureDesc: "Width of keyway",
    label: "EF014777: Width of keyway"
  },
  {
    featureId: "EF014778",
    featureDesc: "Elastic sealing",
    label: "EF014778: Elastic sealing"
  },
  {
    featureId: "EF014779",
    featureDesc: "Functional diameter",
    label: "EF014779: Functional diameter"
  },
  {
    featureId: "EF014780",
    featureDesc: "Body diameter",
    label: "EF014780: Body diameter"
  },
  {
    featureId: "EF014781",
    featureDesc: "Cone length",
    label: "EF014781: Cone length"
  },
  {
    featureId: "EF014782",
    featureDesc: "Taper angle",
    label: "EF014782: Taper angle"
  },
  {
    featureId: "EF014783",
    featureDesc: "Length of collet chuck",
    label: "EF014783: Length of collet chuck"
  },
  {
    featureId: "EF014784",
    featureDesc: "Clamping length",
    label: "EF014784: Clamping length"
  },
  {
    featureId: "EF014785",
    featureDesc: "Connection bore diameter",
    label: "EF014785: Connection bore diameter"
  },
  {
    featureId: "EF014786",
    featureDesc: "Type of collet slot",
    label: "EF014786: Type of collet slot"
  },
  {
    featureId: "EF014787",
    featureDesc: "Brush width",
    label: "EF014787: Brush width"
  },
  {
    featureId: "EF014788",
    featureDesc: "Spring deflection of rolls",
    label: "EF014788: Spring deflection of rolls"
  },
  {
    featureId: "EF014796",
    featureDesc: "Usable length",
    label: "EF014796: Usable length"
  },
  {
    featureId: "EF014797",
    featureDesc: "Facing head",
    label: "EF014797: Facing head"
  },
  {
    featureId: "EF014798",
    featureDesc: "Boring head",
    label: "EF014798: Boring head"
  },
  {
    featureId: "EF014799",
    featureDesc: "Diameter of x-axis",
    label: "EF014799: Diameter of x-axis"
  },
  {
    featureId: "EF014800",
    featureDesc: "Max. diameter of z-axis",
    label: "EF014800: Max. diameter of z-axis"
  },
  {
    featureId: "EF014801",
    featureDesc: "Measuring range of bore",
    label: "EF014801: Measuring range of bore"
  },
  {
    featureId: "EF014802",
    featureDesc: "Measuring range of shaft",
    label: "EF014802: Measuring range of shaft"
  },
  {
    featureId: "EF014804",
    featureDesc: "Max. centre cut",
    label: "EF014804: Max. centre cut"
  },
  {
    featureId: "EF014805",
    featureDesc: "Cutting current",
    label: "EF014805: Cutting current"
  },
  {
    featureId: "EF014806",
    featureDesc: "Max. quality cut",
    label: "EF014806: Max. quality cut"
  },
  {
    featureId: "EF014807",
    featureDesc: "ED at max. cutting current",
    label: "EF014807: ED at max. cutting current"
  },
  {
    featureId: "EF014808",
    featureDesc: "Cutting current at 35 % ED / 40 °C",
    label: "EF014808: Cutting current at 35 % ED / 40 °C"
  },
  {
    featureId: "EF014809",
    featureDesc: "Cutting current at 40 % ED / 40 °C",
    label: "EF014809: Cutting current at 40 % ED / 40 °C"
  },
  {
    featureId: "EF014810",
    featureDesc: "Cutting current at 60 % ED / 40 °C",
    label: "EF014810: Cutting current at 60 % ED / 40 °C"
  },
  {
    featureId: "EF014811",
    featureDesc: "Cutting current at 100 % ED / 40 °C",
    label: "EF014811: Cutting current at 100 % ED / 40 °C"
  },
  {
    featureId: "EF014812",
    featureDesc: "Type of power adjustment",
    label: "EF014812: Type of power adjustment"
  },
  {
    featureId: "EF014814",
    featureDesc: "With water cooling",
    label: "EF014814: With water cooling"
  },
  {
    featureId: "EF014815",
    featureDesc: "Water cooling retrofittable",
    label: "EF014815: Water cooling retrofittable"
  },
  {
    featureId: "EF014817",
    featureDesc: "Gripping force",
    label: "EF014817: Gripping force"
  },
  {
    featureId: "EF014818",
    featureDesc: "Suitable for grinding works",
    label: "EF014818: Suitable for grinding works"
  },
  {
    featureId: "EF014819",
    featureDesc: "Suitable for milling works",
    label: "EF014819: Suitable for milling works"
  },
  {
    featureId: "EF014820",
    featureDesc: "Pole division",
    label: "EF014820: Pole division"
  },
  {
    featureId: "EF014822",
    featureDesc: "Reading (in inch)",
    label: "EF014822: Reading (in inch)"
  },
  {
    featureId: "EF014825",
    featureDesc: "With glass shell",
    label: "EF014825: With glass shell"
  },
  {
    featureId: "EF014826",
    featureDesc: "With inner glass",
    label: "EF014826: With inner glass"
  },
  {
    featureId: "EF014827",
    featureDesc: "Type of inner glass",
    label: "EF014827: Type of inner glass"
  },
  {
    featureId: "EF014828",
    featureDesc: "Type of glass shell",
    label: "EF014828: Type of glass shell"
  },
  {
    featureId: "EF014831",
    featureDesc: "Lateral cable outlet",
    label: "EF014831: Lateral cable outlet"
  },
  {
    featureId: "EF014832",
    featureDesc: "Straight cable outlet",
    label: "EF014832: Straight cable outlet"
  },
  {
    featureId: "EF014833",
    featureDesc: "Diameter of active surface",
    label: "EF014833: Diameter of active surface"
  },
  {
    featureId: "EF014837",
    featureDesc: "Radius of active surface",
    label: "EF014837: Radius of active surface"
  },
  {
    featureId: "EF014839",
    featureDesc: "Fitting type of the electrode",
    label: "EF014839: Fitting type of the electrode"
  },
  {
    featureId: "EF014843",
    featureDesc: "With pin",
    label: "EF014843: With pin"
  },
  {
    featureId: "EF014844",
    featureDesc: "Type of course",
    label: "EF014844: Type of course"
  },
  {
    featureId: "EF014845",
    featureDesc: "Type of horn",
    label: "EF014845: Type of horn"
  },
  {
    featureId: "EF014846",
    featureDesc: "Tile thickness",
    label: "EF014846: Tile thickness"
  },
  {
    featureId: "EF014847",
    featureDesc: "Suitable for carrier roller",
    label: "EF014847: Suitable for carrier roller"
  },
  {
    featureId: "EF014848",
    featureDesc: "Suitable for ball caster",
    label: "EF014848: Suitable for ball caster"
  },
  {
    featureId: "EF014850",
    featureDesc: "Max. number of devices per channel",
    label: "EF014850: Max. number of devices per channel"
  },
  {
    featureId: "EF014851",
    featureDesc: "Max. air volume at 50 Pa",
    label: "EF014851: Max. air volume at 50 Pa"
  },
  {
    featureId: "EF014852",
    featureDesc: "Material window frame",
    label: "EF014852: Material window frame"
  },
  {
    featureId: "EF014854",
    featureDesc: "Phase distance",
    label: "EF014854: Phase distance"
  },
  {
    featureId: "EF014856",
    featureDesc: "Pole distance",
    label: "EF014856: Pole distance"
  },
  {
    featureId: "EF014859",
    featureDesc: "Covering thickness",
    label: "EF014859: Covering thickness"
  },
  {
    featureId: "EF014861",
    featureDesc: "With ventilation",
    label: "EF014861: With ventilation"
  },
  {
    featureId: "EF014863",
    featureDesc: "Standard pellet size",
    label: "EF014863: Standard pellet size"
  },
  {
    featureId: "EF014864",
    featureDesc: "Suitable for pellet size",
    label: "EF014864: Suitable for pellet size"
  },
  {
    featureId: "EF014865",
    featureDesc: "Suitable for poles",
    label: "EF014865: Suitable for poles"
  },
  {
    featureId: "EF014866",
    featureDesc: "Suitable for trees",
    label: "EF014866: Suitable for trees"
  },
  {
    featureId: "EF014867",
    featureDesc: "With fuses",
    label: "EF014867: With fuses"
  },
  {
    featureId: "EF014868",
    featureDesc: "Suitable for distribution trunking system",
    label: "EF014868: Suitable for distribution trunking system"
  },
  {
    featureId: "EF014869",
    featureDesc: "Suitable for lighting trunking system",
    label: "EF014869: Suitable for lighting trunking system"
  },
  {
    featureId: "EF014870",
    featureDesc: "Tamperproof",
    label: "EF014870: Tamperproof"
  },
  {
    featureId: "EF014871",
    featureDesc: "Suction disc diameter",
    label: "EF014871: Suction disc diameter"
  },
  {
    featureId: "EF014872",
    featureDesc: "Number of heads",
    label: "EF014872: Number of heads"
  },
  {
    featureId: "EF014873",
    featureDesc: "Head movable",
    label: "EF014873: Head movable"
  },
  {
    featureId: "EF014875",
    featureDesc: "Height underside tollgate",
    label: "EF014875: Height underside tollgate"
  },
  {
    featureId: "EF014876",
    featureDesc: "Approved according to EN 1869",
    label: "EF014876: Approved according to EN 1869"
  },
  {
    featureId: "EF014877",
    featureDesc: "Processing temperature",
    label: "EF014877: Processing temperature"
  },
  {
    featureId: "EF014878",
    featureDesc: "Number of suction pipes, diameter 3/8 inch",
    label: "EF014878: Number of suction pipes, diameter 3/8 inch"
  },
  {
    featureId: "EF014879",
    featureDesc: "Number of suction pipes, diameter 1/2 inch",
    label: "EF014879: Number of suction pipes, diameter 1/2 inch"
  },
  {
    featureId: "EF014880",
    featureDesc: "Sound pressure level outdoor unit (at night reduction at 1 meter)",
    label: "EF014880: Sound pressure level outdoor unit (at night reduction at 1 meter)"
  },
  {
    featureId: "EF014881",
    featureDesc: "Thread size (metric)",
    label: "EF014881: Thread size (metric)"
  },
  {
    featureId: "EF014882",
    featureDesc: "Number of clamping surfaces",
    label: "EF014882: Number of clamping surfaces"
  },
  {
    featureId: "EF014883",
    featureDesc: "Number of drop-down doors",
    label: "EF014883: Number of drop-down doors"
  },
  {
    featureId: "EF014886",
    featureDesc: "UHF",
    label: "EF014886: UHF"
  },
  {
    featureId: "EF014887",
    featureDesc: "VHF",
    label: "EF014887: VHF"
  },
  {
    featureId: "EF014889",
    featureDesc: "Material mesh grating",
    label: "EF014889: Material mesh grating"
  },
  {
    featureId: "EF014890",
    featureDesc: "Surface protection mesh grating",
    label: "EF014890: Surface protection mesh grating"
  },
  {
    featureId: "EF014891",
    featureDesc: "With mesh grating",
    label: "EF014891: With mesh grating"
  },
  {
    featureId: "EF014892",
    featureDesc: "Amount of material that can be agitated",
    label: "EF014892: Amount of material that can be agitated"
  },
  {
    featureId: "EF014893",
    featureDesc: "Chain anode",
    label: "EF014893: Chain anode"
  },
  {
    featureId: "EF014895",
    featureDesc: "Length of charging cable",
    label: "EF014895: Length of charging cable"
  },
  {
    featureId: "EF014896",
    featureDesc: "Suitable for left-handers",
    label: "EF014896: Suitable for left-handers"
  },
  {
    featureId: "EF014897",
    featureDesc: "Suitable for right-handers",
    label: "EF014897: Suitable for right-handers"
  },
  {
    featureId: "EF014898",
    featureDesc: "Diameter cutting wheel",
    label: "EF014898: Diameter cutting wheel"
  },
  {
    featureId: "EF014899",
    featureDesc: "Hold open position",
    label: "EF014899: Hold open position"
  },
  {
    featureId: "EF014900",
    featureDesc: "Suitable for swing door",
    label: "EF014900: Suitable for swing door"
  },
  {
    featureId: "EF014901",
    featureDesc: "With speak membrane",
    label: "EF014901: With speak membrane"
  },
  {
    featureId: "EF014902",
    featureDesc: "Breathing protection according to EN 136",
    label: "EF014902: Breathing protection according to EN 136"
  },
  {
    featureId: "EF014903",
    featureDesc: "Perforated (button facing)",
    label: "EF014903: Perforated (button facing)"
  },
  {
    featureId: "EF014904",
    featureDesc: "Volume flow rate (BEP)",
    label: "EF014904: Volume flow rate (BEP)"
  },
  {
    featureId: "EF014905",
    featureDesc: "Discharge head at flow rate (BEP)",
    label: "EF014905: Discharge head at flow rate (BEP)"
  },
  {
    featureId: "EF014906",
    featureDesc: "Connection standard inlet side",
    label: "EF014906: Connection standard inlet side"
  },
  {
    featureId: "EF014907",
    featureDesc: "Connection standard outlet side",
    label: "EF014907: Connection standard outlet side"
  },
  {
    featureId: "EF014908",
    featureDesc: "Standard motor",
    label: "EF014908: Standard motor"
  },
  {
    featureId: "EF014909",
    featureDesc: "Suitable for doors",
    label: "EF014909: Suitable for doors"
  },
  {
    featureId: "EF014910",
    featureDesc: "Length delivery hose",
    label: "EF014910: Length delivery hose"
  },
  {
    featureId: "EF014911",
    featureDesc: "Diameter ball",
    label: "EF014911: Diameter ball"
  },
  {
    featureId: "EF014912",
    featureDesc: "Track width",
    label: "EF014912: Track width"
  },
  {
    featureId: "EF014913",
    featureDesc: "Max. bonding gap",
    label: "EF014913: Max. bonding gap"
  },
  {
    featureId: "EF014914",
    featureDesc: "Consistency",
    label: "EF014914: Consistency"
  },
  {
    featureId: "EF014915",
    featureDesc: "Webbing width",
    label: "EF014915: Webbing width"
  },
  {
    featureId: "EF014916",
    featureDesc: "Blinking frequency",
    label: "EF014916: Blinking frequency"
  },
  {
    featureId: "EF014917",
    featureDesc: "Door thickness",
    label: "EF014917: Door thickness"
  },
  {
    featureId: "EF014919",
    featureDesc: "Fold size",
    label: "EF014919: Fold size"
  },
  {
    featureId: "EF014920",
    featureDesc: "Assembly depth",
    label: "EF014920: Assembly depth"
  },
  {
    featureId: "EF014921",
    featureDesc: "Nominal diameter of branches",
    label: "EF014921: Nominal diameter of branches"
  },
  {
    featureId: "EF014923",
    featureDesc: "With collar bolt",
    label: "EF014923: With collar bolt"
  },
  {
    featureId: "EF014924",
    featureDesc: "Max. weld seam width",
    label: "EF014924: Max. weld seam width"
  },
  {
    featureId: "EF014925",
    featureDesc: "Compatible with Apple HomeKit",
    label: "EF014925: Compatible with Apple HomeKit"
  },
  {
    featureId: "EF014926",
    featureDesc: "Compatible with Google Assistant",
    label: "EF014926: Compatible with Google Assistant"
  },
  {
    featureId: "EF014927",
    featureDesc: "Compatible with Amazon Alexa",
    label: "EF014927: Compatible with Amazon Alexa"
  },
  {
    featureId: "EF014928",
    featureDesc: "Thickness intermediate layer",
    label: "EF014928: Thickness intermediate layer"
  },
  {
    featureId: "EF014931",
    featureDesc: "Tested according to ISO 3315",
    label: "EF014931: Tested according to ISO 3315"
  },
  {
    featureId: "EF014932",
    featureDesc: "Tested according to ISO 3316",
    label: "EF014932: Tested according to ISO 3316"
  },
  {
    featureId: "EF014933",
    featureDesc: "Tested according to ISO 6787",
    label: "EF014933: Tested according to ISO 6787"
  },
  {
    featureId: "EF014934",
    featureDesc: "Jaw position",
    label: "EF014934: Jaw position"
  },
  {
    featureId: "EF014935",
    featureDesc: "Square drive",
    label: "EF014935: Square drive"
  },
  {
    featureId: "EF014937",
    featureDesc: "Colour of handle",
    label: "EF014937: Colour of handle"
  },
  {
    featureId: "EF014938",
    featureDesc: "Suitable for multi-layer composite pipes",
    label: "EF014938: Suitable for multi-layer composite pipes"
  },
  {
    featureId: "EF014939",
    featureDesc: "With inner tube deburrer",
    label: "EF014939: With inner tube deburrer"
  },
  {
    featureId: "EF014940",
    featureDesc: "With quick adjustment",
    label: "EF014940: With quick adjustment"
  },
  {
    featureId: "EF014941",
    featureDesc: "Number of holes grinding disc",
    label: "EF014941: Number of holes grinding disc"
  },
  {
    featureId: "EF014942",
    featureDesc: "Visible surface arched",
    label: "EF014942: Visible surface arched"
  },
  {
    featureId: "EF014943",
    featureDesc: "Number of suction pipes, diameter 5/8 inch",
    label: "EF014943: Number of suction pipes, diameter 5/8 inch"
  },
  {
    featureId: "EF014944",
    featureDesc: "Wheel pitch",
    label: "EF014944: Wheel pitch"
  },
  {
    featureId: "EF014945",
    featureDesc: "Number of wheels per shaft",
    label: "EF014945: Number of wheels per shaft"
  },
  {
    featureId: "EF014946",
    featureDesc: "Diameter wheels",
    label: "EF014946: Diameter wheels"
  },
  {
    featureId: "EF014948",
    featureDesc: "Thread spreading",
    label: "EF014948: Thread spreading"
  },
  {
    featureId: "EF014949",
    featureDesc: "Combination thread",
    label: "EF014949: Combination thread"
  },
  {
    featureId: "EF014950",
    featureDesc: "Cleaning performance per battery charge",
    label: "EF014950: Cleaning performance per battery charge"
  },
  {
    featureId: "EF014951",
    featureDesc: "Surface protection blade",
    label: "EF014951: Surface protection blade"
  },
  {
    featureId: "EF014952",
    featureDesc: "Type of door knob",
    label: "EF014952: Type of door knob"
  },
  {
    featureId: "EF014953",
    featureDesc: "Connections outdoor air side",
    label: "EF014953: Connections outdoor air side"
  },
  {
    featureId: "EF014954",
    featureDesc: "Connections residential side",
    label: "EF014954: Connections residential side"
  },
  {
    featureId: "EF014955",
    featureDesc: "Spiral hose",
    label: "EF014955: Spiral hose"
  },
  {
    featureId: "EF014956",
    featureDesc: "Type of frost protection",
    label: "EF014956: Type of frost protection"
  },
  {
    featureId: "EF014957",
    featureDesc: "With constant volume flow control",
    label: "EF014957: With constant volume flow control"
  },
  {
    featureId: "EF014958",
    featureDesc: "With constant pressure control",
    label: "EF014958: With constant pressure control"
  },
  {
    featureId: "EF014959",
    featureDesc: "Max. air volume at 100 Pa",
    label: "EF014959: Max. air volume at 100 Pa"
  },
  {
    featureId: "EF014960",
    featureDesc: "Max. air volume at 150 Pa",
    label: "EF014960: Max. air volume at 150 Pa"
  },
  {
    featureId: "EF014961",
    featureDesc: "Max. air volume at 200 Pa",
    label: "EF014961: Max. air volume at 200 Pa"
  },
  {
    featureId: "EF014962",
    featureDesc: "Max. air volume at 250 Pa",
    label: "EF014962: Max. air volume at 250 Pa"
  },
  {
    featureId: "EF014963",
    featureDesc: "Max. air volume at 300 Pa",
    label: "EF014963: Max. air volume at 300 Pa"
  },
  {
    featureId: "EF014964",
    featureDesc: "Section height",
    label: "EF014964: Section height"
  },
  {
    featureId: "EF014965",
    featureDesc: "Max. air volume at 400 Pa",
    label: "EF014965: Max. air volume at 400 Pa"
  },
  {
    featureId: "EF014966",
    featureDesc: "Max. air volume at 500 Pa",
    label: "EF014966: Max. air volume at 500 Pa"
  },
  {
    featureId: "EF014967",
    featureDesc: "Max. air volume at 600 Pa",
    label: "EF014967: Max. air volume at 600 Pa"
  },
  {
    featureId: "EF014968",
    featureDesc: "Alignment of soldering tip",
    label: "EF014968: Alignment of soldering tip"
  },
  {
    featureId: "EF014969",
    featureDesc: "Operating time with battery pack",
    label: "EF014969: Operating time with battery pack"
  },
  {
    featureId: "EF014970",
    featureDesc: "Weight soldering iron",
    label: "EF014970: Weight soldering iron"
  },
  {
    featureId: "EF014977",
    featureDesc: "For window width",
    label: "EF014977: For window width"
  },
  {
    featureId: "EF014978",
    featureDesc: "Strip width",
    label: "EF014978: Strip width"
  },
  {
    featureId: "EF014979",
    featureDesc: "Motor efficiency class",
    label: "EF014979: Motor efficiency class"
  },
  {
    featureId: "EF014980",
    featureDesc: "Number of ratchet combination wrenches",
    label: "EF014980: Number of ratchet combination wrenches"
  },
  {
    featureId: "EF014981",
    featureDesc: "Number of swivel head wrenches",
    label: "EF014981: Number of swivel head wrenches"
  },
  {
    featureId: "EF014982",
    featureDesc: "Number of reversible ratchet wrenches",
    label: "EF014982: Number of reversible ratchet wrenches"
  },
  {
    featureId: "EF014983",
    featureDesc: "Continuous blade",
    label: "EF014983: Continuous blade"
  },
  {
    featureId: "EF014984",
    featureDesc: "With anti-roll protection",
    label: "EF014984: With anti-roll protection"
  },
  {
    featureId: "EF014985",
    featureDesc: "With striking cap",
    label: "EF014985: With striking cap"
  },
  {
    featureId: "EF014986",
    featureDesc: "Thermal dissipation (Delta T = 20 K) according to IEC/TR 60890",
    label: "EF014986: Thermal dissipation (Delta T = 20 K) according to IEC/TR 60890"
  },
  {
    featureId: "EF014987",
    featureDesc: "Max. permissible load of the enclosure according to IEC 62208",
    label: "EF014987: Max. permissible load of the enclosure according to IEC 62208"
  },
  {
    featureId: "EF014988",
    featureDesc: "Max. permissible load of the door(s) according to IEC 62208",
    label: "EF014988: Max. permissible load of the door(s) according to IEC 62208"
  },
  {
    featureId: "EF014989",
    featureDesc: "Max. permissible load of the mounting plate according to IEC 62208",
    label: "EF014989: Max. permissible load of the mounting plate according to IEC 62208"
  },
  {
    featureId: "EF014990",
    featureDesc: "Max. steam temperature",
    label: "EF014990: Max. steam temperature"
  },
  {
    featureId: "EF014992",
    featureDesc: "Table inclinable",
    label: "EF014992: Table inclinable"
  },
  {
    featureId: "EF014993",
    featureDesc: "Overall height retracted",
    label: "EF014993: Overall height retracted"
  },
  {
    featureId: "EF014994",
    featureDesc: "Clear height",
    label: "EF014994: Clear height"
  },
  {
    featureId: "EF014995",
    featureDesc: "Abrasion resistance according to EN 530 M2",
    label: "EF014995: Abrasion resistance according to EN 530 M2"
  },
  {
    featureId: "EF014996",
    featureDesc: "Burst consistence according to ISO 13938-1",
    label: "EF014996: Burst consistence according to ISO 13938-1"
  },
  {
    featureId: "EF014997",
    featureDesc: "Trapeze tearing strength according to ISO 9073-4",
    label: "EF014997: Trapeze tearing strength according to ISO 9073-4"
  },
  {
    featureId: "EF014998",
    featureDesc: "Suitable for sliding doors",
    label: "EF014998: Suitable for sliding doors"
  },
  {
    featureId: "EF014999",
    featureDesc: "Suitable for sliding gates",
    label: "EF014999: Suitable for sliding gates"
  },
  {
    featureId: "EF015000",
    featureDesc: "Door leaf width",
    label: "EF015000: Door leaf width"
  },
  {
    featureId: "EF015001",
    featureDesc: "With flow-through indicator",
    label: "EF015001: With flow-through indicator"
  },
  {
    featureId: "EF015002",
    featureDesc: "Material quality sleeve",
    label: "EF015002: Material quality sleeve"
  },
  {
    featureId: "EF015003",
    featureDesc: "Push-through installation",
    label: "EF015003: Push-through installation"
  },
  {
    featureId: "EF015004",
    featureDesc: "Pre-positioned installation",
    label: "EF015004: Pre-positioned installation"
  },
  {
    featureId: "EF015005",
    featureDesc: "Spaced installation",
    label: "EF015005: Spaced installation"
  },
  {
    featureId: "EF015006",
    featureDesc: "Max. clamping thickness",
    label: "EF015006: Max. clamping thickness"
  },
  {
    featureId: "EF015007",
    featureDesc: "Adjustable exhaust",
    label: "EF015007: Adjustable exhaust"
  },
  {
    featureId: "EF015008",
    featureDesc: "Air consumption per stroke",
    label: "EF015008: Air consumption per stroke"
  },
  {
    featureId: "EF015009",
    featureDesc: "Magazine type",
    label: "EF015009: Magazine type"
  },
  {
    featureId: "EF015010",
    featureDesc: "Shooting sequence",
    label: "EF015010: Shooting sequence"
  },
  {
    featureId: "EF015011",
    featureDesc: "Lock-out safety device",
    label: "EF015011: Lock-out safety device"
  },
  {
    featureId: "EF015012",
    featureDesc: "Suitable for gas cartridges",
    label: "EF015012: Suitable for gas cartridges"
  },
  {
    featureId: "EF015013",
    featureDesc: "Covered nailing",
    label: "EF015013: Covered nailing"
  },
  {
    featureId: "EF015014",
    featureDesc: "Impact force adjustable",
    label: "EF015014: Impact force adjustable"
  },
  {
    featureId: "EF015015",
    featureDesc: "Barbwire",
    label: "EF015015: Barbwire"
  },
  {
    featureId: "EF015016",
    featureDesc: "Serrated edge blade",
    label: "EF015016: Serrated edge blade"
  },
  {
    featureId: "EF015017",
    featureDesc: "Size (metric)",
    label: "EF015017: Size (metric)"
  },
  {
    featureId: "EF015018",
    featureDesc: "With double cone",
    label: "EF015018: With double cone"
  },
  {
    featureId: "EF015019",
    featureDesc: "With straight shoulder",
    label: "EF015019: With straight shoulder"
  },
  {
    featureId: "EF015020",
    featureDesc: "With lamellas",
    label: "EF015020: With lamellas"
  },
  {
    featureId: "EF015021",
    featureDesc: "With length adjustment",
    label: "EF015021: With length adjustment"
  },
  {
    featureId: "EF015022",
    featureDesc: "With clamping thread",
    label: "EF015022: With clamping thread"
  },
  {
    featureId: "EF015023",
    featureDesc: "With n-edges wrench flat",
    label: "EF015023: With n-edges wrench flat"
  },
  {
    featureId: "EF015025",
    featureDesc: "With coolant supply",
    label: "EF015025: With coolant supply"
  },
  {
    featureId: "EF015026",
    featureDesc: "Concentricity",
    label: "EF015026: Concentricity"
  },
  {
    featureId: "EF015027",
    featureDesc: "Projection length",
    label: "EF015027: Projection length"
  },
  {
    featureId: "EF015028",
    featureDesc: "Tip shape",
    label: "EF015028: Tip shape"
  },
  {
    featureId: "EF015029",
    featureDesc: "With cooling channel",
    label: "EF015029: With cooling channel"
  },
  {
    featureId: "EF015030",
    featureDesc: "With wastewater pump",
    label: "EF015030: With wastewater pump"
  },
  {
    featureId: "EF015031",
    featureDesc: "Lead exchangeable",
    label: "EF015031: Lead exchangeable"
  },
  {
    featureId: "EF015032",
    featureDesc: "With engine brake",
    label: "EF015032: With engine brake"
  },
  {
    featureId: "EF015033",
    featureDesc: "Standard thread",
    label: "EF015033: Standard thread"
  },
  {
    featureId: "EF015034",
    featureDesc: "Suitable for mobile phones",
    label: "EF015034: Suitable for mobile phones"
  },
  {
    featureId: "EF015035",
    featureDesc: "Suitable for notebooks",
    label: "EF015035: Suitable for notebooks"
  },
  {
    featureId: "EF015036",
    featureDesc: "Suitable for navigation systems",
    label: "EF015036: Suitable for navigation systems"
  },
  {
    featureId: "EF015037",
    featureDesc: "Cushioned",
    label: "EF015037: Cushioned"
  },
  {
    featureId: "EF015038",
    featureDesc: "Clamp- and release automatic",
    label: "EF015038: Clamp- and release automatic"
  },
  {
    featureId: "EF015039",
    featureDesc: "Suitable for cellular concrete",
    label: "EF015039: Suitable for cellular concrete"
  },
  {
    featureId: "EF015040",
    featureDesc: "Suitable for fibre-/chipboard",
    label: "EF015040: Suitable for fibre-/chipboard"
  },
  {
    featureId: "EF015041",
    featureDesc: "Suitable for solid brick",
    label: "EF015041: Suitable for solid brick"
  },
  {
    featureId: "EF015042",
    featureDesc: "Suitable for vertical coring brick",
    label: "EF015042: Suitable for vertical coring brick"
  },
  {
    featureId: "EF015044",
    featureDesc: "Milled measuring surface",
    label: "EF015044: Milled measuring surface"
  },
  {
    featureId: "EF015045",
    featureDesc: "Length bristles",
    label: "EF015045: Length bristles"
  },
  {
    featureId: "EF015046",
    featureDesc: "Type of fine setting",
    label: "EF015046: Type of fine setting"
  },
  {
    featureId: "EF015047",
    featureDesc: "Material head",
    label: "EF015047: Material head"
  },
  {
    featureId: "EF015048",
    featureDesc: "With nail extractor",
    label: "EF015048: With nail extractor"
  },
  {
    featureId: "EF015049",
    featureDesc: "With stick protection",
    label: "EF015049: With stick protection"
  },
  {
    featureId: "EF015050",
    featureDesc: "With nail holder",
    label: "EF015050: With nail holder"
  },
  {
    featureId: "EF015051",
    featureDesc: "With telescopic handle",
    label: "EF015051: With telescopic handle"
  },
  {
    featureId: "EF015052",
    featureDesc: "Max. detection depth wood",
    label: "EF015052: Max. detection depth wood"
  },
  {
    featureId: "EF015053",
    featureDesc: "Max. detection depth metal",
    label: "EF015053: Max. detection depth metal"
  },
  {
    featureId: "EF015054",
    featureDesc: "Max. detection depth live wire",
    label: "EF015054: Max. detection depth live wire"
  },
  {
    featureId: "EF015055",
    featureDesc: "Ready-made",
    label: "EF015055: Ready-made"
  },
  {
    featureId: "EF015056",
    featureDesc: "With heat-conducting plate",
    label: "EF015056: With heat-conducting plate"
  },
  {
    featureId: "EF015057",
    featureDesc: "Cutting edge hardness",
    label: "EF015057: Cutting edge hardness"
  },
  {
    featureId: "EF015058",
    featureDesc: "With scoring saw",
    label: "EF015058: With scoring saw"
  },
  {
    featureId: "EF015059",
    featureDesc: "Leaf thickness",
    label: "EF015059: Leaf thickness"
  },
  {
    featureId: "EF015061",
    featureDesc: "Sound pressure level for cooling",
    label: "EF015061: Sound pressure level for cooling"
  },
  {
    featureId: "EF015062",
    featureDesc: "Sound pressure level for heating",
    label: "EF015062: Sound pressure level for heating"
  },
  {
    featureId: "EF015063",
    featureDesc: "Acoustic capacity level",
    label: "EF015063: Acoustic capacity level"
  },
  {
    featureId: "EF015065",
    featureDesc: "For square rod size",
    label: "EF015065: For square rod size"
  },
  {
    featureId: "EF015066",
    featureDesc: "Number of needles",
    label: "EF015066: Number of needles"
  },
  {
    featureId: "EF015067",
    featureDesc: "Nozzle diameter",
    label: "EF015067: Nozzle diameter"
  },
  {
    featureId: "EF015068",
    featureDesc: "Colour lead",
    label: "EF015068: Colour lead"
  },
  {
    featureId: "EF015069",
    featureDesc: "Wide stream",
    label: "EF015069: Wide stream"
  },
  {
    featureId: "EF015070",
    featureDesc: "Round stream",
    label: "EF015070: Round stream"
  },
  {
    featureId: "EF015071",
    featureDesc: "Pressure display",
    label: "EF015071: Pressure display"
  },
  {
    featureId: "EF015072",
    featureDesc: "Swivelling nozzle",
    label: "EF015072: Swivelling nozzle"
  },
  {
    featureId: "EF015073",
    featureDesc: "Number of bins",
    label: "EF015073: Number of bins"
  },
  {
    featureId: "EF015074",
    featureDesc: "Number of horns",
    label: "EF015074: Number of horns"
  },
  {
    featureId: "EF015075",
    featureDesc: "Surface finishing of face",
    label: "EF015075: Surface finishing of face"
  },
  {
    featureId: "EF015076",
    featureDesc: "Barrel diameter",
    label: "EF015076: Barrel diameter"
  },
  {
    featureId: "EF015077",
    featureDesc: "Spindle pitch",
    label: "EF015077: Spindle pitch"
  },
  {
    featureId: "EF015078",
    featureDesc: "Colour ring identification",
    label: "EF015078: Colour ring identification"
  },
  {
    featureId: "EF015079",
    featureDesc: "Suitable for oxyfuel welding",
    label: "EF015079: Suitable for oxyfuel welding"
  },
  {
    featureId: "EF015080",
    featureDesc: "Suitable for resistance welding",
    label: "EF015080: Suitable for resistance welding"
  },
  {
    featureId: "EF015081",
    featureDesc: "Suitable for cutting plates",
    label: "EF015081: Suitable for cutting plates"
  },
  {
    featureId: "EF015082",
    featureDesc: "Suitable for boring bars",
    label: "EF015082: Suitable for boring bars"
  },
  {
    featureId: "EF015083",
    featureDesc: "Hammer drill resistant",
    label: "EF015083: Hammer drill resistant"
  },
  {
    featureId: "EF015084",
    featureDesc: "Suitable for threading",
    label: "EF015084: Suitable for threading"
  },
  {
    featureId: "EF015085",
    featureDesc: "Inner cooling channel",
    label: "EF015085: Inner cooling channel"
  },
  {
    featureId: "EF015086",
    featureDesc: "Outer cooling channel",
    label: "EF015086: Outer cooling channel"
  },
  {
    featureId: "EF015088",
    featureDesc: "Type of strap",
    label: "EF015088: Type of strap"
  },
  {
    featureId: "EF015089",
    featureDesc: "Reversible key",
    label: "EF015089: Reversible key"
  },
  {
    featureId: "EF015091",
    featureDesc: "Change-over system",
    label: "EF015091: Change-over system"
  },
  {
    featureId: "EF015092",
    featureDesc: "With humidifier",
    label: "EF015092: With humidifier"
  },
  {
    featureId: "EF015093",
    featureDesc: "Type of cooling coil",
    label: "EF015093: Type of cooling coil"
  },
  {
    featureId: "EF015094",
    featureDesc: "Door height",
    label: "EF015094: Door height"
  },
  {
    featureId: "EF015095",
    featureDesc: "Type of supply fan",
    label: "EF015095: Type of supply fan"
  },
  {
    featureId: "EF015096",
    featureDesc: "Type of exhaust fan",
    label: "EF015096: Type of exhaust fan"
  },
  {
    featureId: "EF015098",
    featureDesc: "Type of lock function",
    label: "EF015098: Type of lock function"
  },
  {
    featureId: "EF015100",
    featureDesc: "Type of heating coil",
    label: "EF015100: Type of heating coil"
  },
  {
    featureId: "EF015104",
    featureDesc: "Type of coding",
    label: "EF015104: Type of coding"
  },
  {
    featureId: "EF015105",
    featureDesc: "Finger scanning module insertable",
    label: "EF015105: Finger scanning module insertable"
  },
  {
    featureId: "EF015106",
    featureDesc: "Suitable for gates",
    label: "EF015106: Suitable for gates"
  },
  {
    featureId: "EF015107",
    featureDesc: "Suitable for folding doors/gates",
    label: "EF015107: Suitable for folding doors/gates"
  },
  {
    featureId: "EF015108",
    featureDesc: "For hinge diameter",
    label: "EF015108: For hinge diameter"
  },
  {
    featureId: "EF015109",
    featureDesc: "For dowel/plug holes",
    label: "EF015109: For dowel/plug holes"
  },
  {
    featureId: "EF015110",
    featureDesc: "For switch boxes",
    label: "EF015110: For switch boxes"
  },
  {
    featureId: "EF015111",
    featureDesc: "For door drill holes",
    label: "EF015111: For door drill holes"
  },
  {
    featureId: "EF015112",
    featureDesc: "Material transport plain",
    label: "EF015112: Material transport plain"
  },
  {
    featureId: "EF015113",
    featureDesc: "Suitable for floor cleaning device",
    label: "EF015113: Suitable for floor cleaning device"
  },
  {
    featureId: "EF015114",
    featureDesc: "Material carabiner",
    label: "EF015114: Material carabiner"
  },
  {
    featureId: "EF015115",
    featureDesc: "Closure carabiner",
    label: "EF015115: Closure carabiner"
  },
  {
    featureId: "EF015116",
    featureDesc: "Suitable for wall/board thickness",
    label: "EF015116: Suitable for wall/board thickness"
  },
  {
    featureId: "EF015118",
    featureDesc: "Material jaws",
    label: "EF015118: Material jaws"
  },
  {
    featureId: "EF015119",
    featureDesc: "Type of clamping mechanism",
    label: "EF015119: Type of clamping mechanism"
  },
  {
    featureId: "EF015120",
    featureDesc: "Suitable for inflammable liquids",
    label: "EF015120: Suitable for inflammable liquids"
  },
  {
    featureId: "EF015121",
    featureDesc: "Suitable for chemicals/poison",
    label: "EF015121: Suitable for chemicals/poison"
  },
  {
    featureId: "EF015122",
    featureDesc: "Suitable for acids/alkalis",
    label: "EF015122: Suitable for acids/alkalis"
  },
  {
    featureId: "EF015123",
    featureDesc: "Emergency release from the inside",
    label: "EF015123: Emergency release from the inside"
  },
  {
    featureId: "EF015124",
    featureDesc: "Material catch basin",
    label: "EF015124: Material catch basin"
  },
  {
    featureId: "EF015125",
    featureDesc: "Plug-in mounting",
    label: "EF015125: Plug-in mounting"
  },
  {
    featureId: "EF015126",
    featureDesc: "Material shelves",
    label: "EF015126: Material shelves"
  },
  {
    featureId: "EF015127",
    featureDesc: "Voltage system",
    label: "EF015127: Voltage system"
  },
  {
    featureId: "EF015129",
    featureDesc: "Material base sole",
    label: "EF015129: Material base sole"
  },
  {
    featureId: "EF015130",
    featureDesc: "Cutting edge shape",
    label: "EF015130: Cutting edge shape"
  },
  {
    featureId: "EF015131",
    featureDesc: "Load class according to EN 1253",
    label: "EF015131: Load class according to EN 1253"
  },
  {
    featureId: "EF015132",
    featureDesc: "Type of carrier rollers",
    label: "EF015132: Type of carrier rollers"
  },
  {
    featureId: "EF015133",
    featureDesc: "Material carrier rollers",
    label: "EF015133: Material carrier rollers"
  },
  {
    featureId: "EF015134",
    featureDesc: "Number of axles",
    label: "EF015134: Number of axles"
  },
  {
    featureId: "EF015135",
    featureDesc: "Diameter rollers",
    label: "EF015135: Diameter rollers"
  },
  {
    featureId: "EF015136",
    featureDesc: "With nose bracket",
    label: "EF015136: With nose bracket"
  },
  {
    featureId: "EF015137",
    featureDesc: "Letters",
    label: "EF015137: Letters"
  },
  {
    featureId: "EF015138",
    featureDesc: "Numbers",
    label: "EF015138: Numbers"
  },
  {
    featureId: "EF015139",
    featureDesc: "Special characters",
    label: "EF015139: Special characters"
  },
  {
    featureId: "EF015140",
    featureDesc: "With detergent container",
    label: "EF015140: With detergent container"
  },
  {
    featureId: "EF015141",
    featureDesc: "Material worktop",
    label: "EF015141: Material worktop"
  },
  {
    featureId: "EF015142",
    featureDesc: "Max. permitted load capacity flat material",
    label: "EF015142: Max. permitted load capacity flat material"
  },
  {
    featureId: "EF015143",
    featureDesc: "Max. permitted load capacity round material",
    label: "EF015143: Max. permitted load capacity round material"
  },
  {
    featureId: "EF015144",
    featureDesc: "Welding tongs",
    label: "EF015144: Welding tongs"
  },
  {
    featureId: "EF015145",
    featureDesc: "Max. work piece height",
    label: "EF015145: Max. work piece height"
  },
  {
    featureId: "EF015146",
    featureDesc: "With chopper",
    label: "EF015146: With chopper"
  },
  {
    featureId: "EF015147",
    featureDesc: "With threaded hole",
    label: "EF015147: With threaded hole"
  },
  {
    featureId: "EF015148",
    featureDesc: "Number of jaws",
    label: "EF015148: Number of jaws"
  },
  {
    featureId: "EF015149",
    featureDesc: "Jaw connection",
    label: "EF015149: Jaw connection"
  },
  {
    featureId: "EF015150",
    featureDesc: "Jaw stroke",
    label: "EF015150: Jaw stroke"
  },
  {
    featureId: "EF015151",
    featureDesc: "With back support",
    label: "EF015151: With back support"
  },
  {
    featureId: "EF015152",
    featureDesc: "Overall height elevated",
    label: "EF015152: Overall height elevated"
  },
  {
    featureId: "EF015153",
    featureDesc: "Fork length",
    label: "EF015153: Fork length"
  },
  {
    featureId: "EF015154",
    featureDesc: "Power traction motor",
    label: "EF015154: Power traction motor"
  },
  {
    featureId: "EF015155",
    featureDesc: "Power lift motor",
    label: "EF015155: Power lift motor"
  },
  {
    featureId: "EF015156",
    featureDesc: "Turning radius",
    label: "EF015156: Turning radius"
  },
  {
    featureId: "EF015157",
    featureDesc: "With tipping brake",
    label: "EF015157: With tipping brake"
  },
  {
    featureId: "EF015158",
    featureDesc: "Angle of top perforation",
    label: "EF015158: Angle of top perforation"
  },
  {
    featureId: "EF015160",
    featureDesc: "Usable depth",
    label: "EF015160: Usable depth"
  },
  {
    featureId: "EF015161",
    featureDesc: "Type of humidifier",
    label: "EF015161: Type of humidifier"
  },
  {
    featureId: "EF015162",
    featureDesc: "Number of cords (strands)",
    label: "EF015162: Number of cords (strands)"
  },
  {
    featureId: "EF015163",
    featureDesc: "Gripping range",
    label: "EF015163: Gripping range"
  },
  {
    featureId: "EF015164",
    featureDesc: "Suitable for 90°",
    label: "EF015164: Suitable for 90°"
  },
  {
    featureId: "EF015165",
    featureDesc: "Suitable for 45°",
    label: "EF015165: Suitable for 45°"
  },
  {
    featureId: "EF015166",
    featureDesc: "Suitable for 135°",
    label: "EF015166: Suitable for 135°"
  },
  {
    featureId: "EF015167",
    featureDesc: "Number of dowels/plugs",
    label: "EF015167: Number of dowels/plugs"
  },
  {
    featureId: "EF015168",
    featureDesc: "With hand riveter",
    label: "EF015168: With hand riveter"
  },
  {
    featureId: "EF015169",
    featureDesc: "Number of rivets",
    label: "EF015169: Number of rivets"
  },
  {
    featureId: "EF015170",
    featureDesc: "With opening spring",
    label: "EF015170: With opening spring"
  },
  {
    featureId: "EF015171",
    featureDesc: "Max. rivet diameter",
    label: "EF015171: Max. rivet diameter"
  },
  {
    featureId: "EF015172",
    featureDesc: "Number of included storage bins",
    label: "EF015172: Number of included storage bins"
  },
  {
    featureId: "EF015173",
    featureDesc: "Type of gripping",
    label: "EF015173: Type of gripping"
  },
  {
    featureId: "EF015175",
    featureDesc: "With neck protection",
    label: "EF015175: With neck protection"
  },
  {
    featureId: "EF015176",
    featureDesc: "Approved type of underground installation",
    label: "EF015176: Approved type of underground installation"
  },
  {
    featureId: "EF015177",
    featureDesc: "With tilt mechanism",
    label: "EF015177: With tilt mechanism"
  },
  {
    featureId: "EF015178",
    featureDesc: "Spray range",
    label: "EF015178: Spray range"
  },
  {
    featureId: "EF015179",
    featureDesc: "Material leg",
    label: "EF015179: Material leg"
  },
  {
    featureId: "EF015180",
    featureDesc: "Suitable for parquet",
    label: "EF015180: Suitable for parquet"
  },
  {
    featureId: "EF015182",
    featureDesc: "Material shovel",
    label: "EF015182: Material shovel"
  },
  {
    featureId: "EF015183",
    featureDesc: "Colour of coating",
    label: "EF015183: Colour of coating"
  },
  {
    featureId: "EF015184",
    featureDesc: "Suitable for size of canister",
    label: "EF015184: Suitable for size of canister"
  },
  {
    featureId: "EF015185",
    featureDesc: "Number of compressed air connections",
    label: "EF015185: Number of compressed air connections"
  },
  {
    featureId: "EF015187",
    featureDesc: "Material stand",
    label: "EF015187: Material stand"
  },
  {
    featureId: "EF015196",
    featureDesc: "Socket locking",
    label: "EF015196: Socket locking"
  },
  {
    featureId: "EF015197",
    featureDesc: "Integrated unlocking",
    label: "EF015197: Integrated unlocking"
  },
  {
    featureId: "EF015202",
    featureDesc: "Height difference",
    label: "EF015202: Height difference"
  },
  {
    featureId: "EF015203",
    featureDesc: "Stainless steel seat ring",
    label: "EF015203: Stainless steel seat ring"
  },
  {
    featureId: "EF015204",
    featureDesc: "Spindle seal replaceable under pressure",
    label: "EF015204: Spindle seal replaceable under pressure"
  },
  {
    featureId: "EF015205",
    featureDesc: "Spindle thread not in contact with the medium",
    label: "EF015205: Spindle thread not in contact with the medium"
  },
  {
    featureId: "EF015206",
    featureDesc: "Accuracy according to ISO 6789",
    label: "EF015206: Accuracy according to ISO 6789"
  },
  {
    featureId: "EF015208",
    featureDesc: "Floor standing wall model",
    label: "EF015208: Floor standing wall model"
  },
  {
    featureId: "EF015209",
    featureDesc: "Number of poles mains connection",
    label: "EF015209: Number of poles mains connection"
  },
  {
    featureId: "EF015210",
    featureDesc: "Flush function",
    label: "EF015210: Flush function"
  },
  {
    featureId: "EF015211",
    featureDesc: "Control to external pumps",
    label: "EF015211: Control to external pumps"
  },
  {
    featureId: "EF015213",
    featureDesc: "Rotating handle",
    label: "EF015213: Rotating handle"
  },
  {
    featureId: "EF015214",
    featureDesc: "Extendable handle",
    label: "EF015214: Extendable handle"
  },
  {
    featureId: "EF015216",
    featureDesc: "Length of head",
    label: "EF015216: Length of head"
  },
  {
    featureId: "EF015221",
    featureDesc: "Interruptable",
    label: "EF015221: Interruptable"
  },
  {
    featureId: "EF015222",
    featureDesc: "Length ty-rap",
    label: "EF015222: Length ty-rap"
  },
  {
    featureId: "EF015226",
    featureDesc: "Separate socket needed",
    label: "EF015226: Separate socket needed"
  },
  {
    featureId: "EF015227",
    featureDesc: "With sensor housing",
    label: "EF015227: With sensor housing"
  },
  {
    featureId: "EF015228",
    featureDesc: "Width sensor housing",
    label: "EF015228: Width sensor housing"
  },
  {
    featureId: "EF015229",
    featureDesc: "Height sensor housing",
    label: "EF015229: Height sensor housing"
  },
  {
    featureId: "EF015230",
    featureDesc: "Depth sensor housing",
    label: "EF015230: Depth sensor housing"
  },
  {
    featureId: "EF015231",
    featureDesc: "Colour sensor housing",
    label: "EF015231: Colour sensor housing"
  },
  {
    featureId: "EF015233",
    featureDesc: "Material aglets",
    label: "EF015233: Material aglets"
  },
  {
    featureId: "EF015234",
    featureDesc: "Suitable for foot arch",
    label: "EF015234: Suitable for foot arch"
  },
  {
    featureId: "EF015238",
    featureDesc: "Accuracy class active energy according to IEC 62053-22",
    label: "EF015238: Accuracy class active energy according to IEC 62053-22"
  },
  {
    featureId: "EF015239",
    featureDesc: "Accuracy class reactive energy according to IEC 62053-23",
    label: "EF015239: Accuracy class reactive energy according to IEC 62053-23"
  },
  {
    featureId: "EF015240",
    featureDesc: "Sampling frequency",
    label: "EF015240: Sampling frequency"
  },
  {
    featureId: "EF015241",
    featureDesc: "Accuracy class according to IEC 61000-4-30",
    label: "EF015241: Accuracy class according to IEC 61000-4-30"
  },
  {
    featureId: "EF015242",
    featureDesc: "Measurement of harmonics in frequency range 2 kHz - 9 kHz",
    label: "EF015242: Measurement of harmonics in frequency range 2 kHz - 9 kHz"
  },
  {
    featureId: "EF015243",
    featureDesc: "Measurement of harmonics in frequency range 9 kHz - 150 kHz",
    label: "EF015243: Measurement of harmonics in frequency range 9 kHz - 150 kHz"
  },
  {
    featureId: "EF015244",
    featureDesc: "Flicker according to IEC 61000-4-15",
    label: "EF015244: Flicker according to IEC 61000-4-15"
  },
  {
    featureId: "EF015245",
    featureDesc: "With transient detection",
    label: "EF015245: With transient detection"
  },
  {
    featureId: "EF015246",
    featureDesc: "Sampling frequency for transient detection",
    label: "EF015246: Sampling frequency for transient detection"
  },
  {
    featureId: "EF015247",
    featureDesc: "Data evaluation according to EN 50160",
    label: "EF015247: Data evaluation according to EN 50160"
  },
  {
    featureId: "EF015248",
    featureDesc: "Data transfer via PQDif",
    label: "EF015248: Data transfer via PQDif"
  },
  {
    featureId: "EF015249",
    featureDesc: "Fault record transmission via COMTRADE",
    label: "EF015249: Fault record transmission via COMTRADE"
  },
  {
    featureId: "EF015250",
    featureDesc: "Can be cut to size",
    label: "EF015250: Can be cut to size"
  },
  {
    featureId: "EF015251",
    featureDesc: "Underwater lighting optional",
    label: "EF015251: Underwater lighting optional"
  },
  {
    featureId: "EF015252",
    featureDesc: "Disinfection system optional",
    label: "EF015252: Disinfection system optional"
  },
  {
    featureId: "EF015253",
    featureDesc: "Bath water heating optional",
    label: "EF015253: Bath water heating optional"
  },
  {
    featureId: "EF015254",
    featureDesc: "Nozzles colour",
    label: "EF015254: Nozzles colour"
  },
  {
    featureId: "EF015255",
    featureDesc: "Offset model",
    label: "EF015255: Offset model"
  },
  {
    featureId: "EF015256",
    featureDesc: "Suitable for use in an IP54 system",
    label: "EF015256: Suitable for use in an IP54 system"
  },
  {
    featureId: "EF015257",
    featureDesc: "Suitable for use in an IP55 system",
    label: "EF015257: Suitable for use in an IP55 system"
  },
  {
    featureId: "EF015259",
    featureDesc: "Min. tensile strength",
    label: "EF015259: Min. tensile strength"
  },
  {
    featureId: "EF015260",
    featureDesc: "Metallic colour",
    label: "EF015260: Metallic colour"
  },
  {
    featureId: "EF015261",
    featureDesc: "Ratio R (Q3/Q1)",
    label: "EF015261: Ratio R (Q3/Q1)"
  },
  {
    featureId: "EF015262",
    featureDesc: "Temperature class",
    label: "EF015262: Temperature class"
  },
  {
    featureId: "EF015263",
    featureDesc: "Type of vertical mounting",
    label: "EF015263: Type of vertical mounting"
  },
  {
    featureId: "EF015264",
    featureDesc: "Type of meter housing",
    label: "EF015264: Type of meter housing"
  },
  {
    featureId: "EF015266",
    featureDesc: "Communication",
    label: "EF015266: Communication"
  },
  {
    featureId: "EF015267",
    featureDesc: "With water meter bracket",
    label: "EF015267: With water meter bracket"
  },
  {
    featureId: "EF015269",
    featureDesc: "Material quality according to EN 10346",
    label: "EF015269: Material quality according to EN 10346"
  },
  {
    featureId: "EF015271",
    featureDesc: "Surface protection quality according to EN 10346",
    label: "EF015271: Surface protection quality according to EN 10346"
  },
  {
    featureId: "EF015273",
    featureDesc: "Suitable for load bearing",
    label: "EF015273: Suitable for load bearing"
  },
  {
    featureId: "EF015274",
    featureDesc: "Suitable for cement-based boards, outdoor/damp congested environment",
    label: "EF015274: Suitable for cement-based boards, outdoor/damp congested environment"
  },
  {
    featureId: "EF015275",
    featureDesc: "Pre-punched installation holes",
    label: "EF015275: Pre-punched installation holes"
  },
  {
    featureId: "EF015279",
    featureDesc: "Suitable for noise class",
    label: "EF015279: Suitable for noise class"
  },
  {
    featureId: "EF015280",
    featureDesc: "Cold start function",
    label: "EF015280: Cold start function"
  },
  {
    featureId: "EF015281",
    featureDesc: "Soft-close system",
    label: "EF015281: Soft-close system"
  },
  {
    featureId: "EF015282",
    featureDesc: "With push-to-open control",
    label: "EF015282: With push-to-open control"
  },
  {
    featureId: "EF015283",
    featureDesc: "With wall lighting",
    label: "EF015283: With wall lighting"
  },
  {
    featureId: "EF015284",
    featureDesc: "Heat emission according to EN 16430 20 °C - 55/45 low",
    label: "EF015284: Heat emission according to EN 16430 20 °C - 55/45 low"
  },
  {
    featureId: "EF015287",
    featureDesc: "With pull-out protection",
    label: "EF015287: With pull-out protection"
  },
  {
    featureId: "EF015288",
    featureDesc: "Heat emission according to EN 16430 20 °C - 55/45 medium",
    label: "EF015288: Heat emission according to EN 16430 20 °C - 55/45 medium"
  },
  {
    featureId: "EF015289",
    featureDesc: "Heat emission according to EN 16430 20 °C - 55/45 high",
    label: "EF015289: Heat emission according to EN 16430 20 °C - 55/45 high"
  },
  {
    featureId: "EF015290",
    featureDesc: "Heat emission according to EN 16430 20 °C - 45/35 high",
    label: "EF015290: Heat emission according to EN 16430 20 °C - 45/35 high"
  },
  {
    featureId: "EF015291",
    featureDesc: "Heat emission according to EN 16430 20 °C - 45/35 medium",
    label: "EF015291: Heat emission according to EN 16430 20 °C - 45/35 medium"
  },
  {
    featureId: "EF015292",
    featureDesc: "Heat emission according to EN 16430 20 °C - 45/35 low",
    label: "EF015292: Heat emission according to EN 16430 20 °C - 45/35 low"
  },
  {
    featureId: "EF015293",
    featureDesc: "Heat emission according to EN 16430 20 °C - 40/35 high",
    label: "EF015293: Heat emission according to EN 16430 20 °C - 40/35 high"
  },
  {
    featureId: "EF015294",
    featureDesc: "Heat emission according to EN 16430 20 °C - 40/35 medium",
    label: "EF015294: Heat emission according to EN 16430 20 °C - 40/35 medium"
  },
  {
    featureId: "EF015295",
    featureDesc: "Heat emission according to EN 16430 20 °C - 40/35 low",
    label: "EF015295: Heat emission according to EN 16430 20 °C - 40/35 low"
  },
  {
    featureId: "EF015296",
    featureDesc: "Heat emission according to EN 16430 20 °C - 35/30 high",
    label: "EF015296: Heat emission according to EN 16430 20 °C - 35/30 high"
  },
  {
    featureId: "EF015297",
    featureDesc: "Heat emission according to EN 16430 20 °C - 35/30 medium",
    label: "EF015297: Heat emission according to EN 16430 20 °C - 35/30 medium"
  },
  {
    featureId: "EF015298",
    featureDesc: "Heat emission according to EN 16430 20 °C - 35/30 low",
    label: "EF015298: Heat emission according to EN 16430 20 °C - 35/30 low"
  },
  {
    featureId: "EF015299",
    featureDesc: "Cooling capacity according to EN 16430 28 °C - 17/19 perceived low",
    label: "EF015299: Cooling capacity according to EN 16430 28 °C - 17/19 perceived low"
  },
  {
    featureId: "EF015300",
    featureDesc: "Cooling capacity according to EN 16430 28 °C - 17/19 perceived high",
    label: "EF015300: Cooling capacity according to EN 16430 28 °C - 17/19 perceived high"
  },
  {
    featureId: "EF015301",
    featureDesc: "With shower arm",
    label: "EF015301: With shower arm"
  },
  {
    featureId: "EF015302",
    featureDesc: "Fluid category according to EN 1717",
    label: "EF015302: Fluid category according to EN 1717"
  },
  {
    featureId: "EF015303",
    featureDesc: "Pressure drop at nominal flow",
    label: "EF015303: Pressure drop at nominal flow"
  },
  {
    featureId: "EF015304",
    featureDesc: "Fitting length with screw connection",
    label: "EF015304: Fitting length with screw connection"
  },
  {
    featureId: "EF015305",
    featureDesc: "Idle position",
    label: "EF015305: Idle position"
  },
  {
    featureId: "EF015308",
    featureDesc: "Diameter connection 3",
    label: "EF015308: Diameter connection 3"
  },
  {
    featureId: "EF015309",
    featureDesc: "Length shower arm",
    label: "EF015309: Length shower arm"
  },
  {
    featureId: "EF015310",
    featureDesc: "Material membrane/bladder",
    label: "EF015310: Material membrane/bladder"
  },
  {
    featureId: "EF015311",
    featureDesc: "With cable outlet",
    label: "EF015311: With cable outlet"
  },
  {
    featureId: "EF015312",
    featureDesc: "Outlet angle",
    label: "EF015312: Outlet angle"
  },
  {
    featureId: "EF015313",
    featureDesc: "Wall mount adjustable",
    label: "EF015313: Wall mount adjustable"
  },
  {
    featureId: "EF015314",
    featureDesc: "Suitable for drywall",
    label: "EF015314: Suitable for drywall"
  },
  {
    featureId: "EF015315",
    featureDesc: "Suitable for solid wall",
    label: "EF015315: Suitable for solid wall"
  },
  {
    featureId: "EF015316",
    featureDesc: "Suitable for sanitary installation wall system",
    label: "EF015316: Suitable for sanitary installation wall system"
  },
  {
    featureId: "EF015318",
    featureDesc: "With shower head connection",
    label: "EF015318: With shower head connection"
  },
  {
    featureId: "EF015320",
    featureDesc: "Suitable for flushing flow throttling",
    label: "EF015320: Suitable for flushing flow throttling"
  },
  {
    featureId: "EF015321",
    featureDesc: "Suitable for toilets with a reduced contact area",
    label: "EF015321: Suitable for toilets with a reduced contact area"
  },
  {
    featureId: "EF015322",
    featureDesc: "Centre distance of toilet attachment",
    label: "EF015322: Centre distance of toilet attachment"
  },
  {
    featureId: "EF015323",
    featureDesc: "Suitable for shower toilet",
    label: "EF015323: Suitable for shower toilet"
  },
  {
    featureId: "EF015324",
    featureDesc: "With fan connection",
    label: "EF015324: With fan connection"
  },
  {
    featureId: "EF015325",
    featureDesc: "With electrical connection",
    label: "EF015325: With electrical connection"
  },
  {
    featureId: "EF015326",
    featureDesc: "Number of tap water connections",
    label: "EF015326: Number of tap water connections"
  },
  {
    featureId: "EF015327",
    featureDesc: "Load capacity >=400 kg according to EN 997",
    label: "EF015327: Load capacity >=400 kg according to EN 997"
  },
  {
    featureId: "EF015328",
    featureDesc: "Suitable for wall-hung bidet",
    label: "EF015328: Suitable for wall-hung bidet"
  },
  {
    featureId: "EF015329",
    featureDesc: "Suitable for floor standing bidet",
    label: "EF015329: Suitable for floor standing bidet"
  },
  {
    featureId: "EF015330",
    featureDesc: "Suitable for bidet with a reduced contact area",
    label: "EF015330: Suitable for bidet with a reduced contact area"
  },
  {
    featureId: "EF015331",
    featureDesc: "Surface protection cover",
    label: "EF015331: Surface protection cover"
  },
  {
    featureId: "EF015332",
    featureDesc: "Surface treatment cover",
    label: "EF015332: Surface treatment cover"
  },
  {
    featureId: "EF015333",
    featureDesc: "With cable outlet in cover",
    label: "EF015333: With cable outlet in cover"
  },
  {
    featureId: "EF015334",
    featureDesc: "Suitable for floors in computer rooms",
    label: "EF015334: Suitable for floors in computer rooms"
  },
  {
    featureId: "EF015342",
    featureDesc: "Diameter inner pipe 2",
    label: "EF015342: Diameter inner pipe 2"
  },
  {
    featureId: "EF015343",
    featureDesc: "Diameter inner pipe 3",
    label: "EF015343: Diameter inner pipe 3"
  },
  {
    featureId: "EF015344",
    featureDesc: "Diameter inner pipe 4",
    label: "EF015344: Diameter inner pipe 4"
  },
  {
    featureId: "EF015345",
    featureDesc: "Wall thickness inner pipe 2",
    label: "EF015345: Wall thickness inner pipe 2"
  },
  {
    featureId: "EF015346",
    featureDesc: "Wall thickness inner pipe 3",
    label: "EF015346: Wall thickness inner pipe 3"
  },
  {
    featureId: "EF015347",
    featureDesc: "Wall thickness inner pipe 4",
    label: "EF015347: Wall thickness inner pipe 4"
  },
  {
    featureId: "EF015348",
    featureDesc: "Position primary connection",
    label: "EF015348: Position primary connection"
  },
  {
    featureId: "EF015349",
    featureDesc: "Multi-zone manifold",
    label: "EF015349: Multi-zone manifold"
  },
  {
    featureId: "EF015350",
    featureDesc: "Number of groups secondary",
    label: "EF015350: Number of groups secondary"
  },
  {
    featureId: "EF015351",
    featureDesc: "Outer pipe diameter secondary",
    label: "EF015351: Outer pipe diameter secondary"
  },
  {
    featureId: "EF015352",
    featureDesc: "With valves on primary connection",
    label: "EF015352: With valves on primary connection"
  },
  {
    featureId: "EF015354",
    featureDesc: "Max. heating power (at primary dT=20K)",
    label: "EF015354: Max. heating power (at primary dT=20K)"
  },
  {
    featureId: "EF015355",
    featureDesc: "Max. cooling power primary (at primary dT = 7K)",
    label: "EF015355: Max. cooling power primary (at primary dT = 7K)"
  },
  {
    featureId: "EF015356",
    featureDesc: "Min. pressure difference secondary flow/return",
    label: "EF015356: Min. pressure difference secondary flow/return"
  },
  {
    featureId: "EF015357",
    featureDesc: "Energy efficiency room heating",
    label: "EF015357: Energy efficiency room heating"
  },
  {
    featureId: "EF015358",
    featureDesc: "Oil pressure",
    label: "EF015358: Oil pressure"
  },
  {
    featureId: "EF015359",
    featureDesc: "Suitable for bathtub mounting",
    label: "EF015359: Suitable for bathtub mounting"
  },
  {
    featureId: "EF015361",
    featureDesc: "With flush-mounted water meter connection",
    label: "EF015361: With flush-mounted water meter connection"
  },
  {
    featureId: "EF015362",
    featureDesc: "Load test type according to IEC 61537",
    label: "EF015362: Load test type according to IEC 61537"
  },
  {
    featureId: "EF015368",
    featureDesc: "N-exponent according to EN 16430 20 °C - 55/45 low",
    label: "EF015368: N-exponent according to EN 16430 20 °C - 55/45 low"
  },
  {
    featureId: "EF015369",
    featureDesc: "N-exponent according to EN 16430 20 °C - 55/45 medium",
    label: "EF015369: N-exponent according to EN 16430 20 °C - 55/45 medium"
  },
  {
    featureId: "EF015370",
    featureDesc: "N-exponent according to EN 16430 20 °C - 55/45 high",
    label: "EF015370: N-exponent according to EN 16430 20 °C - 55/45 high"
  },
  {
    featureId: "EF015371",
    featureDesc: "N-exponent according to EN 16430 20 °C - 45/35 low",
    label: "EF015371: N-exponent according to EN 16430 20 °C - 45/35 low"
  },
  {
    featureId: "EF015372",
    featureDesc: "N-exponent according to EN 16430 20 °C - 45/35 medium",
    label: "EF015372: N-exponent according to EN 16430 20 °C - 45/35 medium"
  },
  {
    featureId: "EF015373",
    featureDesc: "N-exponent according to EN 16430 20 °C - 45/35 high",
    label: "EF015373: N-exponent according to EN 16430 20 °C - 45/35 high"
  },
  {
    featureId: "EF015374",
    featureDesc: "N-exponent according to EN 16430 20 °C - 40/35 high",
    label: "EF015374: N-exponent according to EN 16430 20 °C - 40/35 high"
  },
  {
    featureId: "EF015375",
    featureDesc: "N-exponent according to EN 16430 20 °C - 40/35 medium",
    label: "EF015375: N-exponent according to EN 16430 20 °C - 40/35 medium"
  },
  {
    featureId: "EF015376",
    featureDesc: "N-exponent according to EN 16430 20 °C - 40/35 low",
    label: "EF015376: N-exponent according to EN 16430 20 °C - 40/35 low"
  },
  {
    featureId: "EF015377",
    featureDesc: "N-exponent according to EN 16430 20 °C - 35/30 high",
    label: "EF015377: N-exponent according to EN 16430 20 °C - 35/30 high"
  },
  {
    featureId: "EF015378",
    featureDesc: "N-exponent according to EN 16430 20 °C - 35/30 medium",
    label: "EF015378: N-exponent according to EN 16430 20 °C - 35/30 medium"
  },
  {
    featureId: "EF015379",
    featureDesc: "N-exponent according to EN 16430 20 °C - 35/30 low",
    label: "EF015379: N-exponent according to EN 16430 20 °C - 35/30 low"
  },
  {
    featureId: "EF015380",
    featureDesc: "N-exponent according to EN 16430 20 °C - cooling mode, low",
    label: "EF015380: N-exponent according to EN 16430 20 °C - cooling mode, low"
  },
  {
    featureId: "EF015381",
    featureDesc: "N-exponent according to EN 16430 20 °C - cooling mode, high",
    label: "EF015381: N-exponent according to EN 16430 20 °C - cooling mode, high"
  },
  {
    featureId: "EF015382",
    featureDesc: "Length immersion tube",
    label: "EF015382: Length immersion tube"
  },
  {
    featureId: "EF015383",
    featureDesc: "Thread size immersion tube",
    label: "EF015383: Thread size immersion tube"
  },
  {
    featureId: "EF015384",
    featureDesc: "With steamer attachment",
    label: "EF015384: With steamer attachment"
  },
  {
    featureId: "EF015385",
    featureDesc: "Number of voltage measurement inputs",
    label: "EF015385: Number of voltage measurement inputs"
  },
  {
    featureId: "EF015388",
    featureDesc: "Number of current measurement inputs",
    label: "EF015388: Number of current measurement inputs"
  },
  {
    featureId: "EF015389",
    featureDesc: "Supporting protocol for IEC 61850 Ethernet",
    label: "EF015389: Supporting protocol for IEC 61850 Ethernet"
  },
  {
    featureId: "EF015390",
    featureDesc: "Supporting protocol for OPC UA PubSub",
    label: "EF015390: Supporting protocol for OPC UA PubSub"
  },
  {
    featureId: "EF015391",
    featureDesc: "With audit logging (audit log)",
    label: "EF015391: With audit logging (audit log)"
  },
  {
    featureId: "EF015392",
    featureDesc: "With firmware signature",
    label: "EF015392: With firmware signature"
  },
  {
    featureId: "EF015393",
    featureDesc: "With logging of security events (security event log)",
    label: "EF015393: With logging of security events (security event log)"
  },
  {
    featureId: "EF015394",
    featureDesc: "With Role Based Access Control (RBAC)",
    label: "EF015394: With Role Based Access Control (RBAC)"
  },
  {
    featureId: "EF015395",
    featureDesc: "Web server with HTTPS transfer protocol",
    label: "EF015395: Web server with HTTPS transfer protocol"
  },
  {
    featureId: "EF015396",
    featureDesc: "Material quality valve butterfly",
    label: "EF015396: Material quality valve butterfly"
  },
  {
    featureId: "EF015398",
    featureDesc: "With electric reheater",
    label: "EF015398: With electric reheater"
  },
  {
    featureId: "EF015400",
    featureDesc: "With differential pressure regulator",
    label: "EF015400: With differential pressure regulator"
  },
  {
    featureId: "EF015402",
    featureDesc: "With conical nipple",
    label: "EF015402: With conical nipple"
  },
  {
    featureId: "EF015403",
    featureDesc: "Braiding galvanized",
    label: "EF015403: Braiding galvanized"
  },
  {
    featureId: "EF015407",
    featureDesc: "Soldering material",
    label: "EF015407: Soldering material"
  },
  {
    featureId: "EF015408",
    featureDesc: "Lateral connection option",
    label: "EF015408: Lateral connection option"
  },
  {
    featureId: "EF015409",
    featureDesc: "Gable tile side",
    label: "EF015409: Gable tile side"
  },
  {
    featureId: "EF015415",
    featureDesc: "Connection expansion tank",
    label: "EF015415: Connection expansion tank"
  },
  {
    featureId: "EF015420",
    featureDesc: "Mesh width of filter element",
    label: "EF015420: Mesh width of filter element"
  },
  {
    featureId: "EF015421",
    featureDesc: "With pressure reducer",
    label: "EF015421: With pressure reducer"
  },
  {
    featureId: "EF015422",
    featureDesc: "With free flow spout",
    label: "EF015422: With free flow spout"
  },
  {
    featureId: "EF015423",
    featureDesc: "Filter element exchangeable",
    label: "EF015423: Filter element exchangeable"
  },
  {
    featureId: "EF015424",
    featureDesc: "Opening pressure valve cone",
    label: "EF015424: Opening pressure valve cone"
  },
  {
    featureId: "EF015425",
    featureDesc: "Number of roof tiles per plate",
    label: "EF015425: Number of roof tiles per plate"
  },
  {
    featureId: "EF015426",
    featureDesc: "With double roll",
    label: "EF015426: With double roll"
  },
  {
    featureId: "EF015431",
    featureDesc: "Size connection storage circuit",
    label: "EF015431: Size connection storage circuit"
  },
  {
    featureId: "EF015432",
    featureDesc: "Safety valve in the drinking water circuit",
    label: "EF015432: Safety valve in the drinking water circuit"
  },
  {
    featureId: "EF015433",
    featureDesc: "Shut-off ball valve in the drinking water circuit",
    label: "EF015433: Shut-off ball valve in the drinking water circuit"
  },
  {
    featureId: "EF015438",
    featureDesc: "Kv value storage circuit",
    label: "EF015438: Kv value storage circuit"
  },
  {
    featureId: "EF015439",
    featureDesc: "Kv value drinking water circuit",
    label: "EF015439: Kv value drinking water circuit"
  },
  {
    featureId: "EF015443",
    featureDesc: "Volume flow sensor",
    label: "EF015443: Volume flow sensor"
  },
  {
    featureId: "EF015444",
    featureDesc: "Pump control signal",
    label: "EF015444: Pump control signal"
  },
  {
    featureId: "EF015447",
    featureDesc: "Strand system",
    label: "EF015447: Strand system"
  },
  {
    featureId: "EF015449",
    featureDesc: "With vacuum manometer",
    label: "EF015449: With vacuum manometer"
  },
  {
    featureId: "EF015451",
    featureDesc: "Connection tank side",
    label: "EF015451: Connection tank side"
  },
  {
    featureId: "EF015452",
    featureDesc: "Nominal diameter connection tank side",
    label: "EF015452: Nominal diameter connection tank side"
  },
  {
    featureId: "EF015453",
    featureDesc: "Connection burner side",
    label: "EF015453: Connection burner side"
  },
  {
    featureId: "EF015454",
    featureDesc: "Nominal diameter connection burner side",
    label: "EF015454: Nominal diameter connection burner side"
  },
  {
    featureId: "EF015456",
    featureDesc: "Material filter cup",
    label: "EF015456: Material filter cup"
  },
  {
    featureId: "EF015459",
    featureDesc: "Material quality filter cup",
    label: "EF015459: Material quality filter cup"
  },
  {
    featureId: "EF015460",
    featureDesc: "Backwash filter",
    label: "EF015460: Backwash filter"
  },
  {
    featureId: "EF015468",
    featureDesc: "With fitting piece for cold water meter",
    label: "EF015468: With fitting piece for cold water meter"
  },
  {
    featureId: "EF015469",
    featureDesc: "Flow regulator",
    label: "EF015469: Flow regulator"
  },
  {
    featureId: "EF015476",
    featureDesc: "Connection size circulation pipe",
    label: "EF015476: Connection size circulation pipe"
  },
  {
    featureId: "EF015494",
    featureDesc: "Zone valve",
    label: "EF015494: Zone valve"
  },
  {
    featureId: "EF015496",
    featureDesc: "High efficiency pump (compliant with EC 547/2012 and EC 641/2009)",
    label: "EF015496: High efficiency pump (compliant with EC 547/2012 and EC 641/2009)"
  },
  {
    featureId: "EF015504",
    featureDesc: "Soldering material heat exchanger",
    label: "EF015504: Soldering material heat exchanger"
  },
  {
    featureId: "EF015505",
    featureDesc: "Heat exchanger fully sealed",
    label: "EF015505: Heat exchanger fully sealed"
  },
  {
    featureId: "EF015511",
    featureDesc: "With straight tailpiece",
    label: "EF015511: With straight tailpiece"
  },
  {
    featureId: "EF015513",
    featureDesc: "With depth gauge",
    label: "EF015513: With depth gauge"
  },
  {
    featureId: "EF015514",
    featureDesc: "With air intake barrier",
    label: "EF015514: With air intake barrier"
  },
  {
    featureId: "EF015515",
    featureDesc: "Angle connection",
    label: "EF015515: Angle connection"
  },
  {
    featureId: "EF015517",
    featureDesc: "Position of cable entry/outlet",
    label: "EF015517: Position of cable entry/outlet"
  },
  {
    featureId: "EF015518",
    featureDesc: "Colour of switch operator",
    label: "EF015518: Colour of switch operator"
  },
  {
    featureId: "EF015519",
    featureDesc: "Number of cycles to a depth of discharge of 50 %",
    label: "EF015519: Number of cycles to a depth of discharge of 50 %"
  },
  {
    featureId: "EF015520",
    featureDesc: "Number of cycles to a depth of discharge of 80 %",
    label: "EF015520: Number of cycles to a depth of discharge of 80 %"
  },
  {
    featureId: "EF015521",
    featureDesc: "Number of cycles to a depth of discharge of 60 %",
    label: "EF015521: Number of cycles to a depth of discharge of 60 %"
  },
  {
    featureId: "EF015523",
    featureDesc: "With meter connection",
    label: "EF015523: With meter connection"
  },
  {
    featureId: "EF015524",
    featureDesc: "Nominal diameter meter service",
    label: "EF015524: Nominal diameter meter service"
  },
  {
    featureId: "EF015525",
    featureDesc: "Material welding connection",
    label: "EF015525: Material welding connection"
  },
  {
    featureId: "EF015526",
    featureDesc: "Suitable for hinged door",
    label: "EF015526: Suitable for hinged door"
  },
  {
    featureId: "EF015527",
    featureDesc: "Suitable for pivot door",
    label: "EF015527: Suitable for pivot door"
  },
  {
    featureId: "EF015528",
    featureDesc: "Suitable for single-leaf door",
    label: "EF015528: Suitable for single-leaf door"
  },
  {
    featureId: "EF015529",
    featureDesc: "Suitable for double-leaf doors",
    label: "EF015529: Suitable for double-leaf doors"
  },
  {
    featureId: "EF015530",
    featureDesc: "Overlapping gasket",
    label: "EF015530: Overlapping gasket"
  },
  {
    featureId: "EF015531",
    featureDesc: "With BSP fitting set",
    label: "EF015531: With BSP fitting set"
  },
  {
    featureId: "EF015532",
    featureDesc: "Mounting position guide rail",
    label: "EF015532: Mounting position guide rail"
  },
  {
    featureId: "EF015533",
    featureDesc: "Max. number of luminaires per miniature circuit breaker C16 (MCB)",
    label: "EF015533: Max. number of luminaires per miniature circuit breaker C16 (MCB)"
  },
  {
    featureId: "EF015534",
    featureDesc: "Max. number of luminaires per miniature circuit breaker B16 (MCB)",
    label: "EF015534: Max. number of luminaires per miniature circuit breaker B16 (MCB)"
  },
  {
    featureId: "EF015535",
    featureDesc: "Suitable for rebated door",
    label: "EF015535: Suitable for rebated door"
  },
  {
    featureId: "EF015536",
    featureDesc: "Suitable for non-rebated door",
    label: "EF015536: Suitable for non-rebated door"
  },
  {
    featureId: "EF015538",
    featureDesc: "Disassembly",
    label: "EF015538: Disassembly"
  },
  {
    featureId: "EF015539",
    featureDesc: "Protection cap profile end",
    label: "EF015539: Protection cap profile end"
  },
  {
    featureId: "EF015541",
    featureDesc: "Suitable for roller conveyor",
    label: "EF015541: Suitable for roller conveyor"
  },
  {
    featureId: "EF015542",
    featureDesc: "Suitable for supplementary door hinge",
    label: "EF015542: Suitable for supplementary door hinge"
  },
  {
    featureId: "EF015543",
    featureDesc: "Suitable for adjustable hinge",
    label: "EF015543: Suitable for adjustable hinge"
  },
  {
    featureId: "EF015544",
    featureDesc: "Suitable for concealed hinge",
    label: "EF015544: Suitable for concealed hinge"
  },
  {
    featureId: "EF015546",
    featureDesc: "Tested permanent operation",
    label: "EF015546: Tested permanent operation"
  },
  {
    featureId: "EF015548",
    featureDesc: "Tested according to EN 16654",
    label: "EF015548: Tested according to EN 16654"
  },
  {
    featureId: "EF015549",
    featureDesc: "Suitable for doors with panic fittings",
    label: "EF015549: Suitable for doors with panic fittings"
  },
  {
    featureId: "EF015550",
    featureDesc: "Tamperproof fixing",
    label: "EF015550: Tamperproof fixing"
  },
  {
    featureId: "EF015551",
    featureDesc: "Extension length of cover",
    label: "EF015551: Extension length of cover"
  },
  {
    featureId: "EF015552",
    featureDesc: "Retrofittable",
    label: "EF015552: Retrofittable"
  },
  {
    featureId: "EF015555",
    featureDesc: "With system separator",
    label: "EF015555: With system separator"
  },
  {
    featureId: "EF015556",
    featureDesc: "Max. temperature at the inlet",
    label: "EF015556: Max. temperature at the inlet"
  },
  {
    featureId: "EF015557",
    featureDesc: "Max. temperature at the outlet",
    label: "EF015557: Max. temperature at the outlet"
  },
  {
    featureId: "EF015573",
    featureDesc: "Decor back wall",
    label: "EF015573: Decor back wall"
  },
  {
    featureId: "EF015576",
    featureDesc: "Suitable for liquid gas",
    label: "EF015576: Suitable for liquid gas"
  },
  {
    featureId: "EF015577",
    featureDesc: "Connection input",
    label: "EF015577: Connection input"
  },
  {
    featureId: "EF015578",
    featureDesc: "Size connection input",
    label: "EF015578: Size connection input"
  },
  {
    featureId: "EF015579",
    featureDesc: "Connection output",
    label: "EF015579: Connection output"
  },
  {
    featureId: "EF015580",
    featureDesc: "Size connection output",
    label: "EF015580: Size connection output"
  },
  {
    featureId: "EF015581",
    featureDesc: "Installation position vertically downwards",
    label: "EF015581: Installation position vertically downwards"
  },
  {
    featureId: "EF015582",
    featureDesc: "Installation position vertically upwards",
    label: "EF015582: Installation position vertically upwards"
  },
  {
    featureId: "EF015588",
    featureDesc: "Dimension square neck bolt",
    label: "EF015588: Dimension square neck bolt"
  },
  {
    featureId: "EF015589",
    featureDesc: "Suitable for tiles",
    label: "EF015589: Suitable for tiles"
  },
  {
    featureId: "EF015590",
    featureDesc: "Segment width",
    label: "EF015590: Segment width"
  },
  {
    featureId: "EF015591",
    featureDesc: "Segment height",
    label: "EF015591: Segment height"
  },
  {
    featureId: "EF015593",
    featureDesc: "Segment length",
    label: "EF015593: Segment length"
  },
  {
    featureId: "EF015595",
    featureDesc: "Material frame/protective cover",
    label: "EF015595: Material frame/protective cover"
  },
  {
    featureId: "EF015596",
    featureDesc: "With indication open/closed",
    label: "EF015596: With indication open/closed"
  },
  {
    featureId: "EF015598",
    featureDesc: "Number of drills for glass",
    label: "EF015598: Number of drills for glass"
  },
  {
    featureId: "EF015599",
    featureDesc: "Number of drills for ceramics",
    label: "EF015599: Number of drills for ceramics"
  },
  {
    featureId: "EF015600",
    featureDesc: "Number of drills for tile",
    label: "EF015600: Number of drills for tile"
  },
  {
    featureId: "EF015601",
    featureDesc: "Number of universal drills",
    label: "EF015601: Number of universal drills"
  },
  {
    featureId: "EF015602",
    featureDesc: "Suitable for gelcoat",
    label: "EF015602: Suitable for gelcoat"
  },
  {
    featureId: "EF015603",
    featureDesc: "Suitable for composite materials",
    label: "EF015603: Suitable for composite materials"
  },
  {
    featureId: "EF015604",
    featureDesc: "Concealed operation",
    label: "EF015604: Concealed operation"
  },
  {
    featureId: "EF015605",
    featureDesc: "Specific heat capacity",
    label: "EF015605: Specific heat capacity"
  },
  {
    featureId: "EF015606",
    featureDesc: "Overflow visible",
    label: "EF015606: Overflow visible"
  },
  {
    featureId: "EF015610",
    featureDesc: "Scattering",
    label: "EF015610: Scattering"
  },
  {
    featureId: "EF015613",
    featureDesc: "Outer diameter incl. sheat/cover",
    label: "EF015613: Outer diameter incl. sheat/cover"
  },
  {
    featureId: "EF015630",
    featureDesc: "With soundproofing set",
    label: "EF015630: With soundproofing set"
  },
  {
    featureId: "EF015632",
    featureDesc: "Extendable handle area",
    label: "EF015632: Extendable handle area"
  },
  {
    featureId: "EF015633",
    featureDesc: "Suitable for railway systems",
    label: "EF015633: Suitable for railway systems"
  },
  {
    featureId: "EF015634",
    featureDesc: "Type of belt joint",
    label: "EF015634: Type of belt joint"
  },
  {
    featureId: "EF015635",
    featureDesc: "Special feature of belt joint",
    label: "EF015635: Special feature of belt joint"
  },
  {
    featureId: "EF015636",
    featureDesc: "Hole pattern",
    label: "EF015636: Hole pattern"
  },
  {
    featureId: "EF015637",
    featureDesc: "Nonwoven fabric-fineness",
    label: "EF015637: Nonwoven fabric-fineness"
  },
  {
    featureId: "EF015639",
    featureDesc: "With return temperature limiter",
    label: "EF015639: With return temperature limiter"
  },
  {
    featureId: "EF015641",
    featureDesc: "X-Lock holding device",
    label: "EF015641: X-Lock holding device"
  },
  {
    featureId: "EF015650",
    featureDesc: "With manual water tap",
    label: "EF015650: With manual water tap"
  },
  {
    featureId: "EF015651",
    featureDesc: "Supplied with thermostatic valves",
    label: "EF015651: Supplied with thermostatic valves"
  },
  {
    featureId: "EF015654",
    featureDesc: "Termination point meter position",
    label: "EF015654: Termination point meter position"
  },
  {
    featureId: "EF015655",
    featureDesc: "Optical efficiency",
    label: "EF015655: Optical efficiency"
  },
  {
    featureId: "EF015656",
    featureDesc: "Opening pressure non return valve",
    label: "EF015656: Opening pressure non return valve"
  },
  {
    featureId: "EF015659",
    featureDesc: "Flow adjustment range",
    label: "EF015659: Flow adjustment range"
  },
  {
    featureId: "EF015662",
    featureDesc: "Size collector connection",
    label: "EF015662: Size collector connection"
  },
  {
    featureId: "EF015663",
    featureDesc: "Size connection expansion tank",
    label: "EF015663: Size connection expansion tank"
  },
  {
    featureId: "EF015664",
    featureDesc: "With electronic solar controller",
    label: "EF015664: With electronic solar controller"
  },
  {
    featureId: "EF015666",
    featureDesc: "Electronic solar controller expandable",
    label: "EF015666: Electronic solar controller expandable"
  },
  {
    featureId: "EF015667",
    featureDesc: "Heat emission according to EN 442 20 °C - 55/45",
    label: "EF015667: Heat emission according to EN 442 20 °C - 55/45"
  },
  {
    featureId: "EF015668",
    featureDesc: "Recessed towel holder",
    label: "EF015668: Recessed towel holder"
  },
  {
    featureId: "EF015672",
    featureDesc: "Height washbasin cabinet",
    label: "EF015672: Height washbasin cabinet"
  },
  {
    featureId: "EF015673",
    featureDesc: "Width washbasin cabinet",
    label: "EF015673: Width washbasin cabinet"
  },
  {
    featureId: "EF015674",
    featureDesc: "Depth washbasin cabinet",
    label: "EF015674: Depth washbasin cabinet"
  },
  {
    featureId: "EF015675",
    featureDesc: "Height bath unit cabinet",
    label: "EF015675: Height bath unit cabinet"
  },
  {
    featureId: "EF015676",
    featureDesc: "Width bath unit cabinet",
    label: "EF015676: Width bath unit cabinet"
  },
  {
    featureId: "EF015677",
    featureDesc: "Depth bath unit cabinet",
    label: "EF015677: Depth bath unit cabinet"
  },
  {
    featureId: "EF015678",
    featureDesc: "Height washbasin top cabinet",
    label: "EF015678: Height washbasin top cabinet"
  },
  {
    featureId: "EF015679",
    featureDesc: "Width washbasin top cabinet",
    label: "EF015679: Width washbasin top cabinet"
  },
  {
    featureId: "EF015680",
    featureDesc: "Depth washbasin top cabinet",
    label: "EF015680: Depth washbasin top cabinet"
  },
  {
    featureId: "EF015682",
    featureDesc: "Flange length",
    label: "EF015682: Flange length"
  },
  {
    featureId: "EF015683",
    featureDesc: "Pin width (flat-pin shape terminal)",
    label: "EF015683: Pin width (flat-pin shape terminal)"
  },
  {
    featureId: "EF015684",
    featureDesc: "Pin diameter (pin shape terminal)",
    label: "EF015684: Pin diameter (pin shape terminal)"
  },
  {
    featureId: "EF015685",
    featureDesc: "Suitable for diameter core drill bit",
    label: "EF015685: Suitable for diameter core drill bit"
  },
  {
    featureId: "EF015686",
    featureDesc: "Lumen maintenance at median useful life of 35,000 h at 25 °C ambient (tq)",
    label: "EF015686: Lumen maintenance at median useful life of 35,000 h at 25 °C ambient (tq)"
  },
  {
    featureId: "EF015687",
    featureDesc: "Lumen maintenance at median useful life of 50,000 h at 25 °C ambient (tq)",
    label: "EF015687: Lumen maintenance at median useful life of 50,000 h at 25 °C ambient (tq)"
  },
  {
    featureId: "EF015688",
    featureDesc: "Lumen maintenance at median useful life of 75,000 h at 25 °C ambient (tq)",
    label: "EF015688: Lumen maintenance at median useful life of 75,000 h at 25 °C ambient (tq)"
  },
  {
    featureId: "EF015689",
    featureDesc: "Lumen maintenance at median useful life of 100,000 h at 25 °C ambient (tq)",
    label: "EF015689: Lumen maintenance at median useful life of 100,000 h at 25 °C ambient (tq)"
  },
  {
    featureId: "EF015690",
    featureDesc: "Failure rate at median useful life of 35,000 h at 25 °C ambient (tq)",
    label: "EF015690: Failure rate at median useful life of 35,000 h at 25 °C ambient (tq)"
  },
  {
    featureId: "EF015691",
    featureDesc: "Failure rate at median useful life of 50,000 h at 25 °C ambient (tq)",
    label: "EF015691: Failure rate at median useful life of 50,000 h at 25 °C ambient (tq)"
  },
  {
    featureId: "EF015692",
    featureDesc: "Failure rate at median useful life of 75,000 h at 25 °C ambient (tq)",
    label: "EF015692: Failure rate at median useful life of 75,000 h at 25 °C ambient (tq)"
  },
  {
    featureId: "EF015693",
    featureDesc: "Failure rate at median useful life of 100,000 h at 25 °C ambient (tq)",
    label: "EF015693: Failure rate at median useful life of 100,000 h at 25 °C ambient (tq)"
  },
  {
    featureId: "EF015694",
    featureDesc: "With support for seat",
    label: "EF015694: With support for seat"
  },
  {
    featureId: "EF015695",
    featureDesc: "With an electric motor",
    label: "EF015695: With an electric motor"
  },
  {
    featureId: "EF015696",
    featureDesc: "Number of independent back flow safeguards",
    label: "EF015696: Number of independent back flow safeguards"
  },
  {
    featureId: "EF015697",
    featureDesc: "With emergency power supply",
    label: "EF015697: With emergency power supply"
  },
  {
    featureId: "EF015698",
    featureDesc: "Suitable for cable management system height",
    label: "EF015698: Suitable for cable management system height"
  },
  {
    featureId: "EF015699",
    featureDesc: "Mounting type grid",
    label: "EF015699: Mounting type grid"
  },
  {
    featureId: "EF015702",
    featureDesc: "Self-adjusting tappet",
    label: "EF015702: Self-adjusting tappet"
  },
  {
    featureId: "EF015703",
    featureDesc: "APC-version connection 1",
    label: "EF015703: APC-version connection 1"
  },
  {
    featureId: "EF015704",
    featureDesc: "APC-version connection 2",
    label: "EF015704: APC-version connection 2"
  },
  {
    featureId: "EF015705",
    featureDesc: "Category of fire protection according to EN 50615",
    label: "EF015705: Category of fire protection according to EN 50615"
  },
  {
    featureId: "EF015706",
    featureDesc: "Max. output temperature",
    label: "EF015706: Max. output temperature"
  },
  {
    featureId: "EF015707",
    featureDesc: "Free axial space for protective tube/waterproofing membrane (depth)",
    label: "EF015707: Free axial space for protective tube/waterproofing membrane (depth)"
  },
  {
    featureId: "EF015708",
    featureDesc: "Free radial space for protective tube/waterproofing membrane (width)",
    label: "EF015708: Free radial space for protective tube/waterproofing membrane (width)"
  },
  {
    featureId: "EF015709",
    featureDesc: "Max. glycol mixture",
    label: "EF015709: Max. glycol mixture"
  },
  {
    featureId: "EF015710",
    featureDesc: "Max. ethanol mixture",
    label: "EF015710: Max. ethanol mixture"
  },
  {
    featureId: "EF015711",
    featureDesc: "Material anchor",
    label: "EF015711: Material anchor"
  },
  {
    featureId: "EF015712",
    featureDesc: "Material quality anchor",
    label: "EF015712: Material quality anchor"
  },
  {
    featureId: "EF015713",
    featureDesc: "Surface protection anchor",
    label: "EF015713: Surface protection anchor"
  },
  {
    featureId: "EF015714",
    featureDesc: "Surface protection sleeve",
    label: "EF015714: Surface protection sleeve"
  },
  {
    featureId: "EF015715",
    featureDesc: "ETA seismic rating",
    label: "EF015715: ETA seismic rating"
  },
  {
    featureId: "EF015716",
    featureDesc: "Suitable for hollow core slab",
    label: "EF015716: Suitable for hollow core slab"
  },
  {
    featureId: "EF015717",
    featureDesc: "Self ventilation",
    label: "EF015717: Self ventilation"
  },
  {
    featureId: "EF015718",
    featureDesc: "External ventilation",
    label: "EF015718: External ventilation"
  },
  {
    featureId: "EF015719",
    featureDesc: "Suitable for toilet bowl",
    label: "EF015719: Suitable for toilet bowl"
  },
  {
    featureId: "EF015720",
    featureDesc: "Centre-to-centre distance of stud bolts",
    label: "EF015720: Centre-to-centre distance of stud bolts"
  },
  {
    featureId: "EF015721",
    featureDesc: "Nominal input heat pump according to EN 16147 (7°C)",
    label: "EF015721: Nominal input heat pump according to EN 16147 (7°C)"
  },
  {
    featureId: "EF015722",
    featureDesc: "Nominal input heat pump according to EN 16147 (20 °C)",
    label: "EF015722: Nominal input heat pump according to EN 16147 (20 °C)"
  },
  {
    featureId: "EF015723",
    featureDesc: "Power consumption in the standby period according to EN 16147",
    label: "EF015723: Power consumption in the standby period according to EN 16147"
  },
  {
    featureId: "EF015724",
    featureDesc: "Power index according to EN 16147 (20 °C)",
    label: "EF015724: Power index according to EN 16147 (20 °C)"
  },
  {
    featureId: "EF015727",
    featureDesc: "Sound performance level indoor unit according to EN 12102 at 0/35 °C",
    label: "EF015727: Sound performance level indoor unit according to EN 12102 at 0/35 °C"
  },
  {
    featureId: "EF015728",
    featureDesc: "Sound performance level outdoor unit according to EN 12102 at 0/35 °C",
    label: "EF015728: Sound performance level outdoor unit according to EN 12102 at 0/35 °C"
  },
  {
    featureId: "EF015729",
    featureDesc: "Degree of protection (IP) interior unit",
    label: "EF015729: Degree of protection (IP) interior unit"
  },
  {
    featureId: "EF015730",
    featureDesc: "Degree of protection (IP) outdoor unit",
    label: "EF015730: Degree of protection (IP) outdoor unit"
  },
  {
    featureId: "EF015731",
    featureDesc: "With collar",
    label: "EF015731: With collar"
  },
  {
    featureId: "EF015732",
    featureDesc: "Fastening by expander tool",
    label: "EF015732: Fastening by expander tool"
  },
  {
    featureId: "EF015735",
    featureDesc: "With communication interface KNX",
    label: "EF015735: With communication interface KNX"
  },
  {
    featureId: "EF015740",
    featureDesc: "Mixed water",
    label: "EF015740: Mixed water"
  },
  {
    featureId: "EF015741",
    featureDesc: "Filtered water",
    label: "EF015741: Filtered water"
  },
  {
    featureId: "EF015742",
    featureDesc: "Sparkling water",
    label: "EF015742: Sparkling water"
  },
  {
    featureId: "EF015743",
    featureDesc: "Boiling water",
    label: "EF015743: Boiling water"
  },
  {
    featureId: "EF015744",
    featureDesc: "Cooled water",
    label: "EF015744: Cooled water"
  },
  {
    featureId: "EF015745",
    featureDesc: "Separated water guidance",
    label: "EF015745: Separated water guidance"
  },
  {
    featureId: "EF015746",
    featureDesc: "Suitable for domestic use",
    label: "EF015746: Suitable for domestic use"
  },
  {
    featureId: "EF015747",
    featureDesc: "Suitable for commercial use",
    label: "EF015747: Suitable for commercial use"
  },
  {
    featureId: "EF015748",
    featureDesc: "Filter capacity",
    label: "EF015748: Filter capacity"
  },
  {
    featureId: "EF015749",
    featureDesc: "Water softening",
    label: "EF015749: Water softening"
  },
  {
    featureId: "EF015750",
    featureDesc: "Mineralization",
    label: "EF015750: Mineralization"
  },
  {
    featureId: "EF015751",
    featureDesc: "Number of automatic disconnection devices (ADS)",
    label: "EF015751: Number of automatic disconnection devices (ADS)"
  },
  {
    featureId: "EF015753",
    featureDesc: "Offline use",
    label: "EF015753: Offline use"
  },
  {
    featureId: "EF015755",
    featureDesc: "Supporting protocol for X3D",
    label: "EF015755: Supporting protocol for X3D"
  },
  {
    featureId: "EF015762",
    featureDesc: "With anti-stick coating",
    label: "EF015762: With anti-stick coating"
  },
  {
    featureId: "EF015763",
    featureDesc: "Control of household appliances",
    label: "EF015763: Control of household appliances"
  },
  {
    featureId: "EF015770",
    featureDesc: "With integrated modem",
    label: "EF015770: With integrated modem"
  },
  {
    featureId: "EF015771",
    featureDesc: "With integrated cooker hood",
    label: "EF015771: With integrated cooker hood"
  },
  {
    featureId: "EF015773",
    featureDesc: "Height below pipe",
    label: "EF015773: Height below pipe"
  },
  {
    featureId: "EF015776",
    featureDesc: "Earthing terminal block",
    label: "EF015776: Earthing terminal block"
  },
  {
    featureId: "EF015777",
    featureDesc: "Neutral terminal block",
    label: "EF015777: Neutral terminal block"
  },
  {
    featureId: "EF015778",
    featureDesc: "Length of flushing pipe",
    label: "EF015778: Length of flushing pipe"
  },
  {
    featureId: "EF015779",
    featureDesc: "Length of drainage pipe",
    label: "EF015779: Length of drainage pipe"
  },
  {
    featureId: "EF015781",
    featureDesc: "Nominal operating mode",
    label: "EF015781: Nominal operating mode"
  },
  {
    featureId: "EF015783",
    featureDesc: "Colour back wall",
    label: "EF015783: Colour back wall"
  },
  {
    featureId: "EF015784",
    featureDesc: "Number of pumps",
    label: "EF015784: Number of pumps"
  },
  {
    featureId: "EF015786",
    featureDesc: "Earth connection",
    label: "EF015786: Earth connection"
  },
  {
    featureId: "EF015789",
    featureDesc: "Activation shunt trip",
    label: "EF015789: Activation shunt trip"
  },
  {
    featureId: "EF015790",
    featureDesc: "Activating undervoltage release",
    label: "EF015790: Activating undervoltage release"
  },
  {
    featureId: "EF015791",
    featureDesc: "Sideways adjustable",
    label: "EF015791: Sideways adjustable"
  },
  {
    featureId: "EF015792",
    featureDesc: "Sideways adjustment range",
    label: "EF015792: Sideways adjustment range"
  },
  {
    featureId: "EF015794",
    featureDesc: "Segmentation",
    label: "EF015794: Segmentation"
  },
  {
    featureId: "EF015795",
    featureDesc: "Number of segments",
    label: "EF015795: Number of segments"
  },
  {
    featureId: "EF015803",
    featureDesc: "Min. drill hole diameter",
    label: "EF015803: Min. drill hole diameter"
  },
  {
    featureId: "EF015804",
    featureDesc: "Material backing plate",
    label: "EF015804: Material backing plate"
  },
  {
    featureId: "EF015805",
    featureDesc: "Max. sand content",
    label: "EF015805: Max. sand content"
  },
  {
    featureId: "EF015806",
    featureDesc: "Suitable for double leaf with mounting pillar",
    label: "EF015806: Suitable for double leaf with mounting pillar"
  },
  {
    featureId: "EF015807",
    featureDesc: "Suitable for roof window",
    label: "EF015807: Suitable for roof window"
  },
  {
    featureId: "EF015808",
    featureDesc: "Material dosing head",
    label: "EF015808: Material dosing head"
  },
  {
    featureId: "EF015809",
    featureDesc: "Material quality dosing head",
    label: "EF015809: Material quality dosing head"
  },
  {
    featureId: "EF015810",
    featureDesc: "Number of revolving contacts",
    label: "EF015810: Number of revolving contacts"
  },
  {
    featureId: "EF015811",
    featureDesc: "Contactless type",
    label: "EF015811: Contactless type"
  },
  {
    featureId: "EF015812",
    featureDesc: "Electrical type of integrated transformer",
    label: "EF015812: Electrical type of integrated transformer"
  },
  {
    featureId: "EF015813",
    featureDesc: "Rated current of monitored circuit",
    label: "EF015813: Rated current of monitored circuit"
  },
  {
    featureId: "EF015814",
    featureDesc: "Rated voltage of monitored circuit",
    label: "EF015814: Rated voltage of monitored circuit"
  },
  {
    featureId: "EF015815",
    featureDesc: "Rated frequency of monitored circuit fn",
    label: "EF015815: Rated frequency of monitored circuit fn"
  },
  {
    featureId: "EF015816",
    featureDesc: "Frequency-selective detection",
    label: "EF015816: Frequency-selective detection"
  },
  {
    featureId: "EF015817",
    featureDesc: "Number of frequency ranges",
    label: "EF015817: Number of frequency ranges"
  },
  {
    featureId: "EF015818",
    featureDesc: "Residual operating current Idn DC",
    label: "EF015818: Residual operating current Idn DC"
  },
  {
    featureId: "EF015819",
    featureDesc: "Residual operating current Idn AC",
    label: "EF015819: Residual operating current Idn AC"
  },
  {
    featureId: "EF015820",
    featureDesc: "Frequency range residual operating current fidn type A",
    label: "EF015820: Frequency range residual operating current fidn type A"
  },
  {
    featureId: "EF015821",
    featureDesc: "Frequency range residual operating current fidn type AC",
    label: "EF015821: Frequency range residual operating current fidn type AC"
  },
  {
    featureId: "EF015822",
    featureDesc: "Frequency range residual operating current fidn type B",
    label: "EF015822: Frequency range residual operating current fidn type B"
  },
  {
    featureId: "EF015823",
    featureDesc: "Interface 4-20 mA",
    label: "EF015823: Interface 4-20 mA"
  },
  {
    featureId: "EF015824",
    featureDesc: "Dimming DALI-2",
    label: "EF015824: Dimming DALI-2"
  },
  {
    featureId: "EF015827",
    featureDesc: "Flickering value Pst LM",
    label: "EF015827: Flickering value Pst LM"
  },
  {
    featureId: "EF015828",
    featureDesc: "Stroboscope effect value SVM",
    label: "EF015828: Stroboscope effect value SVM"
  },
  {
    featureId: "EF015830",
    featureDesc: "Min. pH value",
    label: "EF015830: Min. pH value"
  },
  {
    featureId: "EF015833",
    featureDesc: "Mechanical longitudinal strength",
    label: "EF015833: Mechanical longitudinal strength"
  },
  {
    featureId: "EF015834",
    featureDesc: "Transverse rigidity",
    label: "EF015834: Transverse rigidity"
  },
  {
    featureId: "EF015836",
    featureDesc: "Stop position",
    label: "EF015836: Stop position"
  },
  {
    featureId: "EF015837",
    featureDesc: "Sound pressure level indoor unit according to EN 12102 at 0/35 °C /1m distance",
    label: "EF015837: Sound pressure level indoor unit according to EN 12102 at 0/35 °C /1m distance"
  },
  {
    featureId: "EF015838",
    featureDesc: "Sound pressure level outdoor unit according to EN 12102 at 0/35 °C /1m distance",
    label: "EF015838: Sound pressure level outdoor unit according to EN 12102 at 0/35 °C /1m distance"
  },
  {
    featureId: "EF015839",
    featureDesc: "Pump connection",
    label: "EF015839: Pump connection"
  },
  {
    featureId: "EF015840",
    featureDesc: "Nominal diameter pump connection",
    label: "EF015840: Nominal diameter pump connection"
  },
  {
    featureId: "EF015858",
    featureDesc: "With pump ball valve",
    label: "EF015858: With pump ball valve"
  },
  {
    featureId: "EF015861",
    featureDesc: "Extensible with a heating circuit controller",
    label: "EF015861: Extensible with a heating circuit controller"
  },
  {
    featureId: "EF015862",
    featureDesc: "Extensible with a manifold",
    label: "EF015862: Extensible with a manifold"
  },
  {
    featureId: "EF015863",
    featureDesc: "Extensible with a heat exchanger",
    label: "EF015863: Extensible with a heat exchanger"
  },
  {
    featureId: "EF015864",
    featureDesc: "Cross-trunking mounting",
    label: "EF015864: Cross-trunking mounting"
  },
  {
    featureId: "EF015865",
    featureDesc: "Fuse construction type according to IEC 60269",
    label: "EF015865: Fuse construction type according to IEC 60269"
  },
  {
    featureId: "EF015866",
    featureDesc: "Barrier",
    label: "EF015866: Barrier"
  },
  {
    featureId: "EF015867",
    featureDesc: "Left/right orientation reversible",
    label: "EF015867: Left/right orientation reversible"
  },
  {
    featureId: "EF015868",
    featureDesc: "Length exhaust air hose",
    label: "EF015868: Length exhaust air hose"
  },
  {
    featureId: "EF015869",
    featureDesc: "Diameter exhaust air hose",
    label: "EF015869: Diameter exhaust air hose"
  },
  {
    featureId: "EF015870",
    featureDesc: "Max. input voltage DC",
    label: "EF015870: Max. input voltage DC"
  },
  {
    featureId: "EF015871",
    featureDesc: "Max. input current DC per MPP tracker",
    label: "EF015871: Max. input current DC per MPP tracker"
  },
  {
    featureId: "EF015872",
    featureDesc: "Max. input current DC per string input",
    label: "EF015872: Max. input current DC per string input"
  },
  {
    featureId: "EF015873",
    featureDesc: "Type of USB power supply",
    label: "EF015873: Type of USB power supply"
  },
  {
    featureId: "EF015874",
    featureDesc: "Max. efficiency",
    label: "EF015874: Max. efficiency"
  },
  {
    featureId: "EF015875",
    featureDesc: "CEC efficiency (US products)",
    label: "EF015875: CEC efficiency (US products)"
  },
  {
    featureId: "EF015876",
    featureDesc: "Integrated DC switch",
    label: "EF015876: Integrated DC switch"
  },
  {
    featureId: "EF015878",
    featureDesc: "Number of weld-on nozzles",
    label: "EF015878: Number of weld-on nozzles"
  },
  {
    featureId: "EF015879",
    featureDesc: "Length weld-on nozzles",
    label: "EF015879: Length weld-on nozzles"
  },
  {
    featureId: "EF015880",
    featureDesc: "Switch unit rotatable",
    label: "EF015880: Switch unit rotatable"
  },
  {
    featureId: "EF015881",
    featureDesc: "Switch unit exchangeable without draining the unit",
    label: "EF015881: Switch unit exchangeable without draining the unit"
  },
  {
    featureId: "EF015886",
    featureDesc: "With backflow preventer",
    label: "EF015886: With backflow preventer"
  },
  {
    featureId: "EF015887",
    featureDesc: "With manometer connection",
    label: "EF015887: With manometer connection"
  },
  {
    featureId: "EF015890",
    featureDesc: "Min. pressure for back flush",
    label: "EF015890: Min. pressure for back flush"
  },
  {
    featureId: "EF015897",
    featureDesc: "With vessel manifold",
    label: "EF015897: With vessel manifold"
  },
  {
    featureId: "EF015898",
    featureDesc: "Opening pressure safety valve",
    label: "EF015898: Opening pressure safety valve"
  },
  {
    featureId: "EF015899",
    featureDesc: "Category of system separator",
    label: "EF015899: Category of system separator"
  },
  {
    featureId: "EF015900",
    featureDesc: "Size of cartridge",
    label: "EF015900: Size of cartridge"
  },
  {
    featureId: "EF015901",
    featureDesc: "Suitable for softening",
    label: "EF015901: Suitable for softening"
  },
  {
    featureId: "EF015902",
    featureDesc: "Suitable for full demineralization",
    label: "EF015902: Suitable for full demineralization"
  },
  {
    featureId: "EF015903",
    featureDesc: "Suitable for pH value stabilization",
    label: "EF015903: Suitable for pH value stabilization"
  },
  {
    featureId: "EF015904",
    featureDesc: "Suitable for fixed installation",
    label: "EF015904: Suitable for fixed installation"
  },
  {
    featureId: "EF015905",
    featureDesc: "Suitable for mobile use",
    label: "EF015905: Suitable for mobile use"
  },
  {
    featureId: "EF015909",
    featureDesc: "String connetions per MPPT input",
    label: "EF015909: String connetions per MPPT input"
  },
  {
    featureId: "EF015910",
    featureDesc: "DC connection",
    label: "EF015910: DC connection"
  },
  {
    featureId: "EF015911",
    featureDesc: "AC connection",
    label: "EF015911: AC connection"
  },
  {
    featureId: "EF015914",
    featureDesc: "Nominal diameter water level indicator connection",
    label: "EF015914: Nominal diameter water level indicator connection"
  },
  {
    featureId: "EF015915",
    featureDesc: "Connection water level indicator",
    label: "EF015915: Connection water level indicator"
  },
  {
    featureId: "EF015916",
    featureDesc: "With water level indicator",
    label: "EF015916: With water level indicator"
  },
  {
    featureId: "EF015917",
    featureDesc: "With integrated coin slot",
    label: "EF015917: With integrated coin slot"
  },
  {
    featureId: "EF015918",
    featureDesc: "Literal imprint",
    label: "EF015918: Literal imprint"
  },
  {
    featureId: "EF015922",
    featureDesc: "Language of the imprint",
    label: "EF015922: Language of the imprint"
  },
  {
    featureId: "EF015931",
    featureDesc: "ATPV value of protection against arc fault",
    label: "EF015931: ATPV value of protection against arc fault"
  },
  {
    featureId: "EF015932",
    featureDesc: "Value of protection against arc fault",
    label: "EF015932: Value of protection against arc fault"
  },
  {
    featureId: "EF015933",
    featureDesc: "ELIM value of protection against arc fault",
    label: "EF015933: ELIM value of protection against arc fault"
  },
  {
    featureId: "EF015937",
    featureDesc: "According to IEC 60903",
    label: "EF015937: According to IEC 60903"
  },
  {
    featureId: "EF015938",
    featureDesc: "According to EN 60903",
    label: "EF015938: According to EN 60903"
  },
  {
    featureId: "EF015940",
    featureDesc: "Cover with overpressure release",
    label: "EF015940: Cover with overpressure release"
  },
  {
    featureId: "EF015941",
    featureDesc: "Signal passing door",
    label: "EF015941: Signal passing door"
  },
  {
    featureId: "EF015942",
    featureDesc: "Suitable for anti-freeze medium",
    label: "EF015942: Suitable for anti-freeze medium"
  },
  {
    featureId: "EF015945",
    featureDesc: "Secondary material",
    label: "EF015945: Secondary material"
  },
  {
    featureId: "EF015946",
    featureDesc: "Number of arc fault detection devices AFDD",
    label: "EF015946: Number of arc fault detection devices AFDD"
  },
  {
    featureId: "EF015948",
    featureDesc: "Surface protection union nut",
    label: "EF015948: Surface protection union nut"
  },
  {
    featureId: "EF015949",
    featureDesc: "With wall gasket",
    label: "EF015949: With wall gasket"
  },
  {
    featureId: "EF015950",
    featureDesc: "Number of output connections French standard (Type E)",
    label: "EF015950: Number of output connections French standard (Type E)"
  },
  {
    featureId: "EF015952",
    featureDesc: "Flow Domestic Hot Water (DHW) according to EN 13203-1",
    label: "EF015952: Flow Domestic Hot Water (DHW) according to EN 13203-1"
  },
  {
    featureId: "EF015953",
    featureDesc: "Max. switching power LED",
    label: "EF015953: Max. switching power LED"
  },
  {
    featureId: "EF015960",
    featureDesc: "According to EN 50321",
    label: "EF015960: According to EN 50321"
  },
  {
    featureId: "EF015961",
    featureDesc: "According to EN ISO 20345",
    label: "EF015961: According to EN ISO 20345"
  },
  {
    featureId: "EF015962",
    featureDesc: "Max. overcurrent protection device, parallel connection (fuse)",
    label: "EF015962: Max. overcurrent protection device, parallel connection (fuse)"
  },
  {
    featureId: "EF015963",
    featureDesc: "Max. overcurrent protection device, series connection (fuse)",
    label: "EF015963: Max. overcurrent protection device, series connection (fuse)"
  },
  {
    featureId: "EF015964",
    featureDesc: "Supporting protocol for SNMP",
    label: "EF015964: Supporting protocol for SNMP"
  },
  {
    featureId: "EF015965",
    featureDesc: "Suitable for operating system Windows 10",
    label: "EF015965: Suitable for operating system Windows 10"
  },
  {
    featureId: "EF015969",
    featureDesc: "Absorber volume",
    label: "EF015969: Absorber volume"
  },
  {
    featureId: "EF015970",
    featureDesc: "Arm rest length",
    label: "EF015970: Arm rest length"
  },
  {
    featureId: "EF015972",
    featureDesc: "Pipe laying pattern",
    label: "EF015972: Pipe laying pattern"
  },
  {
    featureId: "EF015974",
    featureDesc: "With spacer",
    label: "EF015974: With spacer"
  },
  {
    featureId: "EF015975",
    featureDesc: "Boundary post for chain",
    label: "EF015975: Boundary post for chain"
  },
  {
    featureId: "EF015981",
    featureDesc: "Electrostatic shielding",
    label: "EF015981: Electrostatic shielding"
  },
  {
    featureId: "EF015982",
    featureDesc: "Drive isolation type",
    label: "EF015982: Drive isolation type"
  },
  {
    featureId: "EF015983",
    featureDesc: "K-factor type",
    label: "EF015983: K-factor type"
  },
  {
    featureId: "EF015984",
    featureDesc: "Harmonic mitigating",
    label: "EF015984: Harmonic mitigating"
  },
  {
    featureId: "EF015985",
    featureDesc: "Coil windings temperature rise",
    label: "EF015985: Coil windings temperature rise"
  },
  {
    featureId: "EF015986",
    featureDesc: "Vector group",
    label: "EF015986: Vector group"
  },
  {
    featureId: "EF015990",
    featureDesc: "Heavy base",
    label: "EF015990: Heavy base"
  },
  {
    featureId: "EF015991",
    featureDesc: "Fillable base",
    label: "EF015991: Fillable base"
  },
  {
    featureId: "EF015992",
    featureDesc: "Cone (circular or four-sided)",
    label: "EF015992: Cone (circular or four-sided)"
  },
  {
    featureId: "EF015994",
    featureDesc: "With stabilizer",
    label: "EF015994: With stabilizer"
  },
  {
    featureId: "EF015997",
    featureDesc: "According to EN 50528",
    label: "EF015997: According to EN 50528"
  },
  {
    featureId: "EF015998",
    featureDesc: "With pole adaptor",
    label: "EF015998: With pole adaptor"
  },
  {
    featureId: "EF015999",
    featureDesc: "Insulation between 2 rungs",
    label: "EF015999: Insulation between 2 rungs"
  },
  {
    featureId: "EF016000",
    featureDesc: "Integrated short-circuit protection",
    label: "EF016000: Integrated short-circuit protection"
  },
  {
    featureId: "EF016001",
    featureDesc: "Integrated short-circuit protection selective to fuse gG",
    label: "EF016001: Integrated short-circuit protection selective to fuse gG"
  },
  {
    featureId: "EF016002",
    featureDesc: "Leakage-current free",
    label: "EF016002: Leakage-current free"
  },
  {
    featureId: "EF016003",
    featureDesc: "Voltage switching spark gap technology",
    label: "EF016003: Voltage switching spark gap technology"
  },
  {
    featureId: "EF016004",
    featureDesc: "Overcurrent protected voltage tapping",
    label: "EF016004: Overcurrent protected voltage tapping"
  },
  {
    featureId: "EF016005",
    featureDesc: "Assembly width",
    label: "EF016005: Assembly width"
  },
  {
    featureId: "EF016006",
    featureDesc: "Signal disconnection",
    label: "EF016006: Signal disconnection"
  },
  {
    featureId: "EF016007",
    featureDesc: "Earthing via",
    label: "EF016007: Earthing via"
  },
  {
    featureId: "EF016008",
    featureDesc: "With tools holder",
    label: "EF016008: With tools holder"
  },
  {
    featureId: "EF016009",
    featureDesc: "Insulation between 2 steps",
    label: "EF016009: Insulation between 2 steps"
  },
  {
    featureId: "EF016011",
    featureDesc: "Lockable cover",
    label: "EF016011: Lockable cover"
  },
  {
    featureId: "EF016012",
    featureDesc: "Diameter inlets",
    label: "EF016012: Diameter inlets"
  },
  {
    featureId: "EF016013",
    featureDesc: "Revision opening",
    label: "EF016013: Revision opening"
  },
  {
    featureId: "EF016014",
    featureDesc: "Hole distance connection 3",
    label: "EF016014: Hole distance connection 3"
  },
  {
    featureId: "EF016015",
    featureDesc: "With protection against dry running",
    label: "EF016015: With protection against dry running"
  },
  {
    featureId: "EF016018",
    featureDesc: "Display area thermometer",
    label: "EF016018: Display area thermometer"
  },
  {
    featureId: "EF016043",
    featureDesc: "Type of over voltage protection device",
    label: "EF016043: Type of over voltage protection device"
  },
  {
    featureId: "EF016044",
    featureDesc: "With meter",
    label: "EF016044: With meter"
  },
  {
    featureId: "EF016048",
    featureDesc: "DALI-2 certified",
    label: "EF016048: DALI-2 certified"
  },
  {
    featureId: "EF016051",
    featureDesc: "Type of edge",
    label: "EF016051: Type of edge"
  },
  {
    featureId: "EF016060",
    featureDesc: "Isolation test single phase",
    label: "EF016060: Isolation test single phase"
  },
  {
    featureId: "EF016061",
    featureDesc: "Isolation test 3-phase",
    label: "EF016061: Isolation test 3-phase"
  },
  {
    featureId: "EF016063",
    featureDesc: "Phase sequence indication",
    label: "EF016063: Phase sequence indication"
  },
  {
    featureId: "EF016064",
    featureDesc: "Temperature compensation",
    label: "EF016064: Temperature compensation"
  },
  {
    featureId: "EF016065",
    featureDesc: "Suitable for inner door",
    label: "EF016065: Suitable for inner door"
  },
  {
    featureId: "EF016066",
    featureDesc: "Suitable for front door",
    label: "EF016066: Suitable for front door"
  },
  {
    featureId: "EF016067",
    featureDesc: "Suitable for contact door",
    label: "EF016067: Suitable for contact door"
  },
  {
    featureId: "EF016068",
    featureDesc: "Supporting protocol for DNP3",
    label: "EF016068: Supporting protocol for DNP3"
  },
  {
    featureId: "EF016069",
    featureDesc: "Supporting protocol for IEC 60870",
    label: "EF016069: Supporting protocol for IEC 60870"
  },
  {
    featureId: "EF016070",
    featureDesc: "Suitable for noise insulation door",
    label: "EF016070: Suitable for noise insulation door"
  },
  {
    featureId: "EF016071",
    featureDesc: "For glass wing",
    label: "EF016071: For glass wing"
  },
  {
    featureId: "EF016072",
    featureDesc: "For aluminium wing",
    label: "EF016072: For aluminium wing"
  },
  {
    featureId: "EF016073",
    featureDesc: "For aluminium door frame",
    label: "EF016073: For aluminium door frame"
  },
  {
    featureId: "EF016075",
    featureDesc: "Material click-mount element",
    label: "EF016075: Material click-mount element"
  },
  {
    featureId: "EF016078",
    featureDesc: "With clamping plate",
    label: "EF016078: With clamping plate"
  },
  {
    featureId: "EF016079",
    featureDesc: "Suitable for hinge construction",
    label: "EF016079: Suitable for hinge construction"
  },
  {
    featureId: "EF016080",
    featureDesc: "Adjustment option",
    label: "EF016080: Adjustment option"
  },
  {
    featureId: "EF016081",
    featureDesc: "Main colour fitting",
    label: "EF016081: Main colour fitting"
  },
  {
    featureId: "EF016082",
    featureDesc: "With extendible belt",
    label: "EF016082: With extendible belt"
  },
  {
    featureId: "EF016083",
    featureDesc: "With foldable base",
    label: "EF016083: With foldable base"
  },
  {
    featureId: "EF016087",
    featureDesc: "Multi axis",
    label: "EF016087: Multi axis"
  },
  {
    featureId: "EF016089",
    featureDesc: "Suitable for compression fitting",
    label: "EF016089: Suitable for compression fitting"
  },
  {
    featureId: "EF016090",
    featureDesc: "With leakage protection",
    label: "EF016090: With leakage protection"
  },
  {
    featureId: "EF016099",
    featureDesc: "Robot",
    label: "EF016099: Robot"
  },
  {
    featureId: "EF016100",
    featureDesc: "Seal width (depth)",
    label: "EF016100: Seal width (depth)"
  },
  {
    featureId: "EF016101",
    featureDesc: "Nominal pressure rating according to EN 1333",
    label: "EF016101: Nominal pressure rating according to EN 1333"
  },
  {
    featureId: "EF016102",
    featureDesc: "Max. allowable pressure",
    label: "EF016102: Max. allowable pressure"
  },
  {
    featureId: "EF016103",
    featureDesc: "Compressive strength according to EN 61386-24",
    label: "EF016103: Compressive strength according to EN 61386-24"
  },
  {
    featureId: "EF016106",
    featureDesc: "Pushthrough membrane",
    label: "EF016106: Pushthrough membrane"
  },
  {
    featureId: "EF016109",
    featureDesc: "Combination movement sensor/push button switch",
    label: "EF016109: Combination movement sensor/push button switch"
  },
  {
    featureId: "EF016110",
    featureDesc: "Diameter cable entry",
    label: "EF016110: Diameter cable entry"
  },
  {
    featureId: "EF016113",
    featureDesc: "With lance valve",
    label: "EF016113: With lance valve"
  },
  {
    featureId: "EF016114",
    featureDesc: "Reversible supply/return",
    label: "EF016114: Reversible supply/return"
  },
  {
    featureId: "EF016115",
    featureDesc: "Surface protection ball",
    label: "EF016115: Surface protection ball"
  },
  {
    featureId: "EF016117",
    featureDesc: "Suitable for solar heating",
    label: "EF016117: Suitable for solar heating"
  },
  {
    featureId: "EF016118",
    featureDesc: "Hydraulic balance control",
    label: "EF016118: Hydraulic balance control"
  },
  {
    featureId: "EF016120",
    featureDesc: "Temperature measurement supply",
    label: "EF016120: Temperature measurement supply"
  },
  {
    featureId: "EF016121",
    featureDesc: "Temperature measurement return",
    label: "EF016121: Temperature measurement return"
  },
  {
    featureId: "EF016123",
    featureDesc: "Resistant to short circuit forces",
    label: "EF016123: Resistant to short circuit forces"
  },
  {
    featureId: "EF016124",
    featureDesc: "Approved according to IEC 61914",
    label: "EF016124: Approved according to IEC 61914"
  },
  {
    featureId: "EF016125",
    featureDesc: "Milliohm measurement",
    label: "EF016125: Milliohm measurement"
  },
  {
    featureId: "EF016126",
    featureDesc: "Number of setting groups",
    label: "EF016126: Number of setting groups"
  },
  {
    featureId: "EF016127",
    featureDesc: "Supporting protocol for IEC 60870-5-103",
    label: "EF016127: Supporting protocol for IEC 60870-5-103"
  },
  {
    featureId: "EF016128",
    featureDesc: "Supporting protocol for IEC 60870-5-101",
    label: "EF016128: Supporting protocol for IEC 60870-5-101"
  },
  {
    featureId: "EF016129",
    featureDesc: "Supporting protocol for IEC 60870-5-104",
    label: "EF016129: Supporting protocol for IEC 60870-5-104"
  },
  {
    featureId: "EF016130",
    featureDesc: "Supporting protocol for IEC 61850-8-1",
    label: "EF016130: Supporting protocol for IEC 61850-8-1"
  },
  {
    featureId: "EF016131",
    featureDesc: "Supporting protocol for IEC 61850-9-2",
    label: "EF016131: Supporting protocol for IEC 61850-9-2"
  },
  {
    featureId: "EF016134",
    featureDesc: "Equipped with 3",
    label: "EF016134: Equipped with 3"
  },
  {
    featureId: "EF016136",
    featureDesc: "Wth communication interface M-Bus",
    label: "EF016136: Wth communication interface M-Bus"
  },
  {
    featureId: "EF016137",
    featureDesc: "Tariff connection",
    label: "EF016137: Tariff connection"
  },
  {
    featureId: "EF016138",
    featureDesc: "Remote display",
    label: "EF016138: Remote display"
  },
  {
    featureId: "EF016139",
    featureDesc: "Gripping surface",
    label: "EF016139: Gripping surface"
  },
  {
    featureId: "EF016140",
    featureDesc: "Forged",
    label: "EF016140: Forged"
  },
  {
    featureId: "EF016143",
    featureDesc: "Vibration according to EN 50144",
    label: "EF016143: Vibration according to EN 50144"
  },
  {
    featureId: "EF016150",
    featureDesc: "Lubrication type",
    label: "EF016150: Lubrication type"
  },
  {
    featureId: "EF016154",
    featureDesc: "Outside model",
    label: "EF016154: Outside model"
  },
  {
    featureId: "EF016155",
    featureDesc: "Inside model",
    label: "EF016155: Inside model"
  },
  {
    featureId: "EF016156",
    featureDesc: "Inside cover",
    label: "EF016156: Inside cover"
  },
  {
    featureId: "EF016157",
    featureDesc: "Outside cover",
    label: "EF016157: Outside cover"
  },
  {
    featureId: "EF016158",
    featureDesc: "Typ of door handle",
    label: "EF016158: Typ of door handle"
  },
  {
    featureId: "EF016159",
    featureDesc: "Curing time",
    label: "EF016159: Curing time"
  },
  {
    featureId: "EF016160",
    featureDesc: "Material door knob",
    label: "EF016160: Material door knob"
  },
  {
    featureId: "EF016161",
    featureDesc: "Diameter of outer escutcheon",
    label: "EF016161: Diameter of outer escutcheon"
  },
  {
    featureId: "EF016162",
    featureDesc: "Height of inner plate/escutcheon",
    label: "EF016162: Height of inner plate/escutcheon"
  },
  {
    featureId: "EF016164",
    featureDesc: "Width of inner plate/escutcheon",
    label: "EF016164: Width of inner plate/escutcheon"
  },
  {
    featureId: "EF016165",
    featureDesc: "Thickness of inner plate/escutcheon",
    label: "EF016165: Thickness of inner plate/escutcheon"
  },
  {
    featureId: "EF016167",
    featureDesc: "Diameter of inner escutcheon",
    label: "EF016167: Diameter of inner escutcheon"
  },
  {
    featureId: "EF016170",
    featureDesc: "Nominal current outdoor unit",
    label: "EF016170: Nominal current outdoor unit"
  },
  {
    featureId: "EF016173",
    featureDesc: "Condensate drain pump included",
    label: "EF016173: Condensate drain pump included"
  },
  {
    featureId: "EF016174",
    featureDesc: "Available pressure",
    label: "EF016174: Available pressure"
  },
  {
    featureId: "EF016175",
    featureDesc: "Protection functions for 1-pole tripping",
    label: "EF016175: Protection functions for 1-pole tripping"
  },
  {
    featureId: "EF016176",
    featureDesc: "Protection functions for 3-pole tripping",
    label: "EF016176: Protection functions for 3-pole tripping"
  },
  {
    featureId: "EF016177",
    featureDesc: "Locked rotor protection (ANSI 14)",
    label: "EF016177: Locked rotor protection (ANSI 14)"
  },
  {
    featureId: "EF016178",
    featureDesc: "Distance protection (ANSI 21/21N)",
    label: "EF016178: Distance protection (ANSI 21/21N)"
  },
  {
    featureId: "EF016179",
    featureDesc: "Overexcitation protection (ANSI 24)",
    label: "EF016179: Overexcitation protection (ANSI 24)"
  },
  {
    featureId: "EF016180",
    featureDesc: "Synchrocheck, synchronising function (ANSI 25)",
    label: "EF016180: Synchrocheck, synchronising function (ANSI 25)"
  },
  {
    featureId: "EF016181",
    featureDesc: "Undervoltage protection (ANSI 27)",
    label: "EF016181: Undervoltage protection (ANSI 27)"
  },
  {
    featureId: "EF016182",
    featureDesc: "Directional power supervision (ANSI 32)",
    label: "EF016182: Directional power supervision (ANSI 32)"
  },
  {
    featureId: "EF016183",
    featureDesc: "Reverse power protection (ANSI 32R)",
    label: "EF016183: Reverse power protection (ANSI 32R)"
  },
  {
    featureId: "EF016184",
    featureDesc: "Undercurrent protection (ANSI 37)",
    label: "EF016184: Undercurrent protection (ANSI 37)"
  },
  {
    featureId: "EF016185",
    featureDesc: "Temperature supervision (ANSI 38)",
    label: "EF016185: Temperature supervision (ANSI 38)"
  },
  {
    featureId: "EF016186",
    featureDesc: "Underexcitation protection (ANSI 40)",
    label: "EF016186: Underexcitation protection (ANSI 40)"
  },
  {
    featureId: "EF016187",
    featureDesc: "Unbalanced-load protection (ANSI 46)",
    label: "EF016187: Unbalanced-load protection (ANSI 46)"
  },
  {
    featureId: "EF016188",
    featureDesc: "Phase-sequence-voltage supervision (ANSI 47)",
    label: "EF016188: Phase-sequence-voltage supervision (ANSI 47)"
  },
  {
    featureId: "EF016189",
    featureDesc: "Starting-time supervision (ANSI 48)",
    label: "EF016189: Starting-time supervision (ANSI 48)"
  },
  {
    featureId: "EF016190",
    featureDesc: "Thermal overload protection (ANSI 49)",
    label: "EF016190: Thermal overload protection (ANSI 49)"
  },
  {
    featureId: "EF016191",
    featureDesc: "Circuit-breaker failure protection (ANSI 50BF)",
    label: "EF016191: Circuit-breaker failure protection (ANSI 50BF)"
  },
  {
    featureId: "EF016192",
    featureDesc: "End fault protection (ANSI 50EF)",
    label: "EF016192: End fault protection (ANSI 50EF)"
  },
  {
    featureId: "EF016193",
    featureDesc: "Voltage dependent overcurrent protection (ANSI 51V)",
    label: "EF016193: Voltage dependent overcurrent protection (ANSI 51V)"
  },
  {
    featureId: "EF016194",
    featureDesc: "Power factor (ANSI 55)",
    label: "EF016194: Power factor (ANSI 55)"
  },
  {
    featureId: "EF016195",
    featureDesc: "Overvoltage protection (ANSI 59)",
    label: "EF016195: Overvoltage protection (ANSI 59)"
  },
  {
    featureId: "EF016196",
    featureDesc: "Overvoltage protection, zero-sequence system (ANSI 59N)",
    label: "EF016196: Overvoltage protection, zero-sequence system (ANSI 59N)"
  },
  {
    featureId: "EF016197",
    featureDesc: "Sensitive ground-fault protection (ANSI 64)",
    label: "EF016197: Sensitive ground-fault protection (ANSI 64)"
  },
  {
    featureId: "EF016198",
    featureDesc: "Restart inhibit (ANSI 66)",
    label: "EF016198: Restart inhibit (ANSI 66)"
  },
  {
    featureId: "EF016200",
    featureDesc: "Power-swing blocking (ANSI 68)",
    label: "EF016200: Power-swing blocking (ANSI 68)"
  },
  {
    featureId: "EF016201",
    featureDesc: "Out-of-step protection (ANSI 78)",
    label: "EF016201: Out-of-step protection (ANSI 78)"
  },
  {
    featureId: "EF016202",
    featureDesc: "Automatic reclosing (ANSI 79)",
    label: "EF016202: Automatic reclosing (ANSI 79)"
  },
  {
    featureId: "EF016203",
    featureDesc: "Frequency protection (ANSI 81)",
    label: "EF016203: Frequency protection (ANSI 81)"
  },
  {
    featureId: "EF016204",
    featureDesc: "Rate-of-frequency-change protection (ANSI 81R)",
    label: "EF016204: Rate-of-frequency-change protection (ANSI 81R)"
  },
  {
    featureId: "EF016205",
    featureDesc: "Under frequency load shedding (ANSI 81U)",
    label: "EF016205: Under frequency load shedding (ANSI 81U)"
  },
  {
    featureId: "EF016206",
    featureDesc: "Lockout (ANSI 86)",
    label: "EF016206: Lockout (ANSI 86)"
  },
  {
    featureId: "EF016207",
    featureDesc: "Differential protection, busbar (ANSI 87B)",
    label: "EF016207: Differential protection, busbar (ANSI 87B)"
  },
  {
    featureId: "EF016208",
    featureDesc: "Differential protection, generator (ANSI 87G)",
    label: "EF016208: Differential protection, generator (ANSI 87G)"
  },
  {
    featureId: "EF016209",
    featureDesc: "Differential protection, line (ANSI 87L)",
    label: "EF016209: Differential protection, line (ANSI 87L)"
  },
  {
    featureId: "EF016210",
    featureDesc: "Differential protection, motor (ANSI 87M)",
    label: "EF016210: Differential protection, motor (ANSI 87M)"
  },
  {
    featureId: "EF016211",
    featureDesc: "Differential protection, transformer (ANSI 87T)",
    label: "EF016211: Differential protection, transformer (ANSI 87T)"
  },
  {
    featureId: "EF016212",
    featureDesc: "Synchrophasor measurement (ANSI PMU)",
    label: "EF016212: Synchrophasor measurement (ANSI PMU)"
  },
  {
    featureId: "EF016213",
    featureDesc: "Articulated head",
    label: "EF016213: Articulated head"
  },
  {
    featureId: "EF016214",
    featureDesc: "Rotating head",
    label: "EF016214: Rotating head"
  },
  {
    featureId: "EF016215",
    featureDesc: "Wet wipe",
    label: "EF016215: Wet wipe"
  },
  {
    featureId: "EF016216",
    featureDesc: "With fastening material for wall",
    label: "EF016216: With fastening material for wall"
  },
  {
    featureId: "EF016217",
    featureDesc: "With fastening material for washbasin",
    label: "EF016217: With fastening material for washbasin"
  },
  {
    featureId: "EF016218",
    featureDesc: "Connection type on cable side",
    label: "EF016218: Connection type on cable side"
  },
  {
    featureId: "EF016219",
    featureDesc: "Connection type on power meter side",
    label: "EF016219: Connection type on power meter side"
  },
  {
    featureId: "EF016221",
    featureDesc: "Number of cable feed-through M20",
    label: "EF016221: Number of cable feed-through M20"
  },
  {
    featureId: "EF016222",
    featureDesc: "Number of cable feed-through M25",
    label: "EF016222: Number of cable feed-through M25"
  },
  {
    featureId: "EF016223",
    featureDesc: "Number of cable feed-through M32",
    label: "EF016223: Number of cable feed-through M32"
  },
  {
    featureId: "EF016225",
    featureDesc: "Number of cable feed-through M40",
    label: "EF016225: Number of cable feed-through M40"
  },
  {
    featureId: "EF016228",
    featureDesc: "Head bore",
    label: "EF016228: Head bore"
  },
  {
    featureId: "EF016229",
    featureDesc: "With shank ribs",
    label: "EF016229: With shank ribs"
  },
  {
    featureId: "EF016230",
    featureDesc: "Tip with milling ribs",
    label: "EF016230: Tip with milling ribs"
  },
  {
    featureId: "EF016232",
    featureDesc: "Magnet location",
    label: "EF016232: Magnet location"
  },
  {
    featureId: "EF016233",
    featureDesc: "Variable flow direction",
    label: "EF016233: Variable flow direction"
  },
  {
    featureId: "EF016234",
    featureDesc: "Heat conduction coefficient insulation material (ʎ)",
    label: "EF016234: Heat conduction coefficient insulation material (ʎ)"
  },
  {
    featureId: "EF016235",
    featureDesc: "Number of installation tubes",
    label: "EF016235: Number of installation tubes"
  },
  {
    featureId: "EF016236",
    featureDesc: "Inner diameter installation tube",
    label: "EF016236: Inner diameter installation tube"
  },
  {
    featureId: "EF016237",
    featureDesc: "Reading strategy biometric",
    label: "EF016237: Reading strategy biometric"
  },
  {
    featureId: "EF016238",
    featureDesc: "Reading strategy code",
    label: "EF016238: Reading strategy code"
  },
  {
    featureId: "EF016239",
    featureDesc: "Reading strategy data medium",
    label: "EF016239: Reading strategy data medium"
  },
  {
    featureId: "EF016240",
    featureDesc: "Max. charge power",
    label: "EF016240: Max. charge power"
  },
  {
    featureId: "EF016241",
    featureDesc: "Max. discharge power",
    label: "EF016241: Max. discharge power"
  },
  {
    featureId: "EF016242",
    featureDesc: "Max. emergency power",
    label: "EF016242: Max. emergency power"
  },
  {
    featureId: "EF016244",
    featureDesc: "Number of glass fibre ducts",
    label: "EF016244: Number of glass fibre ducts"
  },
  {
    featureId: "EF016245",
    featureDesc: "Material in contact with (chemical) medium",
    label: "EF016245: Material in contact with (chemical) medium"
  },
  {
    featureId: "EF016246",
    featureDesc: "Material gasket",
    label: "EF016246: Material gasket"
  },
  {
    featureId: "EF016247",
    featureDesc: "Pipe diameter compression set",
    label: "EF016247: Pipe diameter compression set"
  },
  {
    featureId: "EF016250",
    featureDesc: "Propeller tip speed",
    label: "EF016250: Propeller tip speed"
  },
  {
    featureId: "EF016251",
    featureDesc: "Medium pH value",
    label: "EF016251: Medium pH value"
  },
  {
    featureId: "EF016252",
    featureDesc: "Max. solid matter content",
    label: "EF016252: Max. solid matter content"
  },
  {
    featureId: "EF016258",
    featureDesc: "Inner colour tube",
    label: "EF016258: Inner colour tube"
  },
  {
    featureId: "EF016259",
    featureDesc: "Activation time",
    label: "EF016259: Activation time"
  },
  {
    featureId: "EF016260",
    featureDesc: "Pre-pressure",
    label: "EF016260: Pre-pressure"
  },
  {
    featureId: "EF016261",
    featureDesc: "Colour of light adjustable",
    label: "EF016261: Colour of light adjustable"
  },
  {
    featureId: "EF016262",
    featureDesc: "Luminous flux adjustable",
    label: "EF016262: Luminous flux adjustable"
  },
  {
    featureId: "EF016263",
    featureDesc: "Beam angle adjustable",
    label: "EF016263: Beam angle adjustable"
  },
  {
    featureId: "EF016264",
    featureDesc: "Suitable for rigid tubes",
    label: "EF016264: Suitable for rigid tubes"
  },
  {
    featureId: "EF016265",
    featureDesc: "Suitable for flexible tubes",
    label: "EF016265: Suitable for flexible tubes"
  },
  {
    featureId: "EF016266",
    featureDesc: "Suitable for pliable tubes",
    label: "EF016266: Suitable for pliable tubes"
  },
  {
    featureId: "EF016267",
    featureDesc: "Impact strength according to EN 61386-24",
    label: "EF016267: Impact strength according to EN 61386-24"
  },
  {
    featureId: "EF016275",
    featureDesc: "Nominal diameter housing",
    label: "EF016275: Nominal diameter housing"
  },
  {
    featureId: "EF016277",
    featureDesc: "Horse shoeing pliers",
    label: "EF016277: Horse shoeing pliers"
  },
  {
    featureId: "EF016279",
    featureDesc: "Heating switch",
    label: "EF016279: Heating switch"
  },
  {
    featureId: "EF016282",
    featureDesc: "Diameter connection outlet side",
    label: "EF016282: Diameter connection outlet side"
  },
  {
    featureId: "EF016283",
    featureDesc: "Diameter connection inlet side",
    label: "EF016283: Diameter connection inlet side"
  },
  {
    featureId: "EF016286",
    featureDesc: "Exchangeable control gear",
    label: "EF016286: Exchangeable control gear"
  },
  {
    featureId: "EF016292",
    featureDesc: "Diameter cover",
    label: "EF016292: Diameter cover"
  },
  {
    featureId: "EF016295",
    featureDesc: "For coupling size",
    label: "EF016295: For coupling size"
  },
  {
    featureId: "EF016302",
    featureDesc: "Spiral drill",
    label: "EF016302: Spiral drill"
  },
  {
    featureId: "EF016305",
    featureDesc: "Safe working load according to IEC 61537",
    label: "EF016305: Safe working load according to IEC 61537"
  },
  {
    featureId: "EF016320",
    featureDesc: "Volume flow measurement",
    label: "EF016320: Volume flow measurement"
  },
  {
    featureId: "EF016321",
    featureDesc: "Energy efficiency class (2017/1369/EU)",
    label: "EF016321: Energy efficiency class (2017/1369/EU)"
  },
  {
    featureId: "EF016322",
    featureDesc: "Energy efficiency class (2010/30/EU)",
    label: "EF016322: Energy efficiency class (2010/30/EU)"
  },
  {
    featureId: "EF016323",
    featureDesc: "Suitable for dosing pumps",
    label: "EF016323: Suitable for dosing pumps"
  },
  {
    featureId: "EF016324",
    featureDesc: "Suitable for booster installations",
    label: "EF016324: Suitable for booster installations"
  },
  {
    featureId: "EF016325",
    featureDesc: "Net capacity fresh-keeping zone / 0 °C-zone",
    label: "EF016325: Net capacity fresh-keeping zone / 0 °C-zone"
  },
  {
    featureId: "EF016326",
    featureDesc: "Towel radiator model",
    label: "EF016326: Towel radiator model"
  },
  {
    featureId: "EF016327",
    featureDesc: "Suitable for fine strand conductors without cable end sleeve",
    label: "EF016327: Suitable for fine strand conductors without cable end sleeve"
  },
  {
    featureId: "EF016328",
    featureDesc: "Suitable for extra fine-strand conductors",
    label: "EF016328: Suitable for extra fine-strand conductors"
  },
  {
    featureId: "EF016329",
    featureDesc: "Suitable for extra fine-strand conductors without cable end sleeve",
    label: "EF016329: Suitable for extra fine-strand conductors without cable end sleeve"
  },
  {
    featureId: "EF016330",
    featureDesc: "Conductor cross section, input",
    label: "EF016330: Conductor cross section, input"
  },
  {
    featureId: "EF016331",
    featureDesc: "Conductor cross section, output",
    label: "EF016331: Conductor cross section, output"
  },
  {
    featureId: "EF016332",
    featureDesc: "Conductor cross section, input AWG",
    label: "EF016332: Conductor cross section, input AWG"
  },
  {
    featureId: "EF016333",
    featureDesc: "Conductor cross section, output AWG",
    label: "EF016333: Conductor cross section, output AWG"
  },
  {
    featureId: "EF016334",
    featureDesc: "Hum-free",
    label: "EF016334: Hum-free"
  },
  {
    featureId: "EF016335",
    featureDesc: "Offset connection pipe/radiator",
    label: "EF016335: Offset connection pipe/radiator"
  },
  {
    featureId: "EF016336",
    featureDesc: "Number of clamp positions for neutral conductor",
    label: "EF016336: Number of clamp positions for neutral conductor"
  },
  {
    featureId: "EF016337",
    featureDesc: "Mechanical type of integrated transformer",
    label: "EF016337: Mechanical type of integrated transformer"
  },
  {
    featureId: "EF016339",
    featureDesc: "Suitable for integrated valve fittings",
    label: "EF016339: Suitable for integrated valve fittings"
  },
  {
    featureId: "EF016340",
    featureDesc: "Connection rain water/domestic water",
    label: "EF016340: Connection rain water/domestic water"
  },
  {
    featureId: "EF016341",
    featureDesc: "Size connection rain water/domestic water",
    label: "EF016341: Size connection rain water/domestic water"
  },
  {
    featureId: "EF016342",
    featureDesc: "Thermostatic adjustment element connection",
    label: "EF016342: Thermostatic adjustment element connection"
  },
  {
    featureId: "EF016343",
    featureDesc: "Technical flow range according to EN 1434",
    label: "EF016343: Technical flow range according to EN 1434"
  },
  {
    featureId: "EF016345",
    featureDesc: "Nominal flow rate according to EN 1434 (qp)",
    label: "EF016345: Nominal flow rate according to EN 1434 (qp)"
  },
  {
    featureId: "EF016346",
    featureDesc: "Suitable for district heating",
    label: "EF016346: Suitable for district heating"
  },
  {
    featureId: "EF016347",
    featureDesc: "Approved flow range according to EN 1434 (qi - qs)",
    label: "EF016347: Approved flow range according to EN 1434 (qi - qs)"
  },
  {
    featureId: "EF016348",
    featureDesc: "Suitable for block heating",
    label: "EF016348: Suitable for block heating"
  },
  {
    featureId: "EF016349",
    featureDesc: "Flow sensor position",
    label: "EF016349: Flow sensor position"
  },
  {
    featureId: "EF016351",
    featureDesc: "Supports energy unit MWh",
    label: "EF016351: Supports energy unit MWh"
  },
  {
    featureId: "EF016352",
    featureDesc: "Supports energy unit kWh",
    label: "EF016352: Supports energy unit kWh"
  },
  {
    featureId: "EF016353",
    featureDesc: "Supports energy unit GJ",
    label: "EF016353: Supports energy unit GJ"
  },
  {
    featureId: "EF016354",
    featureDesc: "Supports energy unit Gcal",
    label: "EF016354: Supports energy unit Gcal"
  },
  {
    featureId: "EF016355",
    featureDesc: "Supporting protocol for 3D-UNB",
    label: "EF016355: Supporting protocol for 3D-UNB"
  },
  {
    featureId: "EF016358",
    featureDesc: "Supporting protocol for M-bus (wired)",
    label: "EF016358: Supporting protocol for M-bus (wired)"
  },
  {
    featureId: "EF016359",
    featureDesc: "Supporting protocol for MiniBus",
    label: "EF016359: Supporting protocol for MiniBus"
  },
  {
    featureId: "EF016360",
    featureDesc: "Supporting protocol for NB-IoT",
    label: "EF016360: Supporting protocol for NB-IoT"
  },
  {
    featureId: "EF016361",
    featureDesc: "Supporting protocol for OMS",
    label: "EF016361: Supporting protocol for OMS"
  },
  {
    featureId: "EF016362",
    featureDesc: "Supporting protocol for wireless M-bus (wM-Bus)",
    label: "EF016362: Supporting protocol for wireless M-bus (wM-Bus)"
  },
  {
    featureId: "EF016363",
    featureDesc: "Flow sensor accuracy class according to EN 1434",
    label: "EF016363: Flow sensor accuracy class according to EN 1434"
  },
  {
    featureId: "EF016364",
    featureDesc: "Dynamic range according to EN 1434 (qp:qi)",
    label: "EF016364: Dynamic range according to EN 1434 (qp:qi)"
  },
  {
    featureId: "EF016365",
    featureDesc: "Installation type temperature sensor",
    label: "EF016365: Installation type temperature sensor"
  },
  {
    featureId: "EF016366",
    featureDesc: "Diameter temperature sensor",
    label: "EF016366: Diameter temperature sensor"
  },
  {
    featureId: "EF016367",
    featureDesc: "Length temperature sensor",
    label: "EF016367: Length temperature sensor"
  },
  {
    featureId: "EF016368",
    featureDesc: "Duration of measurement cycle for energy calculation",
    label: "EF016368: Duration of measurement cycle for energy calculation"
  },
  {
    featureId: "EF016369",
    featureDesc: "Approved differential temperature according to EN 1434",
    label: "EF016369: Approved differential temperature according to EN 1434"
  },
  {
    featureId: "EF016370",
    featureDesc: "Suitable for ethanol",
    label: "EF016370: Suitable for ethanol"
  },
  {
    featureId: "EF016371",
    featureDesc: "Built-in length pump",
    label: "EF016371: Built-in length pump"
  },
  {
    featureId: "EF016372",
    featureDesc: "Flow orientation",
    label: "EF016372: Flow orientation"
  },
  {
    featureId: "EF016373",
    featureDesc: "Gravity circulation locking device",
    label: "EF016373: Gravity circulation locking device"
  },
  {
    featureId: "EF016375",
    featureDesc: "Flap disc",
    label: "EF016375: Flap disc"
  },
  {
    featureId: "EF016377",
    featureDesc: "Electrical class according to EN 50321-1",
    label: "EF016377: Electrical class according to EN 50321-1"
  },
  {
    featureId: "EF016378",
    featureDesc: "Protection class against arc fault according to IEC 61482-1-2",
    label: "EF016378: Protection class against arc fault according to IEC 61482-1-2"
  },
  {
    featureId: "EF016379",
    featureDesc: "Electrical class according to EN 60903",
    label: "EF016379: Electrical class according to EN 60903"
  },
  {
    featureId: "EF016380",
    featureDesc: "Built-in diameter",
    label: "EF016380: Built-in diameter"
  },
  {
    featureId: "EF016381",
    featureDesc: "Feed speed",
    label: "EF016381: Feed speed"
  },
  {
    featureId: "EF016382",
    featureDesc: "Energy efficiency class integrated cooker hood (2010/30/EU)",
    label: "EF016382: Energy efficiency class integrated cooker hood (2010/30/EU)"
  },
  {
    featureId: "EF016385",
    featureDesc: "Radio standard 5G",
    label: "EF016385: Radio standard 5G"
  },
  {
    featureId: "EF016386",
    featureDesc: "Interface signal 0-10 V / 2-10 V",
    label: "EF016386: Interface signal 0-10 V / 2-10 V"
  },
  {
    featureId: "EF016387",
    featureDesc: "Interface signal 0-20 mA / 4-20 mA",
    label: "EF016387: Interface signal 0-20 mA / 4-20 mA"
  },
  {
    featureId: "EF016388",
    featureDesc: "Interface PT100 / PT1000 / PTC",
    label: "EF016388: Interface PT100 / PT1000 / PTC"
  },
  {
    featureId: "EF016389",
    featureDesc: "Interface Pulse Width Modulation (PWM)",
    label: "EF016389: Interface Pulse Width Modulation (PWM)"
  },
  {
    featureId: "EF016390",
    featureDesc: "Interface pulse counter",
    label: "EF016390: Interface pulse counter"
  },
  {
    featureId: "EF016391",
    featureDesc: "Supporting protocol for CAN/CANOpen",
    label: "EF016391: Supporting protocol for CAN/CANOpen"
  },
  {
    featureId: "EF016392",
    featureDesc: "Supporting protocol for Modbus TCP",
    label: "EF016392: Supporting protocol for Modbus TCP"
  },
  {
    featureId: "EF016393",
    featureDesc: "Supporting protocol for Modbus RTU",
    label: "EF016393: Supporting protocol for Modbus RTU"
  },
  {
    featureId: "EF016394",
    featureDesc: "Supporting protocol for BACnet MS/TP",
    label: "EF016394: Supporting protocol for BACnet MS/TP"
  },
  {
    featureId: "EF016395",
    featureDesc: "Supporting protocol for BACnet IP",
    label: "EF016395: Supporting protocol for BACnet IP"
  },
  {
    featureId: "EF016396",
    featureDesc: "Supporting protocol for LON/LONWorks",
    label: "EF016396: Supporting protocol for LON/LONWorks"
  },
  {
    featureId: "EF016397",
    featureDesc: "Supporting protocol for LIN Bus",
    label: "EF016397: Supporting protocol for LIN Bus"
  },
  {
    featureId: "EF016399",
    featureDesc: "Mean flow velocity",
    label: "EF016399: Mean flow velocity"
  },
  {
    featureId: "EF016402",
    featureDesc: "Centre distance of toilet seat fastener",
    label: "EF016402: Centre distance of toilet seat fastener"
  },
  {
    featureId: "EF016404",
    featureDesc: "Silent pattern",
    label: "EF016404: Silent pattern"
  },
  {
    featureId: "EF016405",
    featureDesc: "With fastening eyes",
    label: "EF016405: With fastening eyes"
  },
  {
    featureId: "EF016406",
    featureDesc: "Stainless steel group according to ISO 3506",
    label: "EF016406: Stainless steel group according to ISO 3506"
  },
  {
    featureId: "EF016407",
    featureDesc: "Deviating material in drill tip",
    label: "EF016407: Deviating material in drill tip"
  },
  {
    featureId: "EF016408",
    featureDesc: "Type of hardening",
    label: "EF016408: Type of hardening"
  },
  {
    featureId: "EF016409",
    featureDesc: "With fixed flange",
    label: "EF016409: With fixed flange"
  },
  {
    featureId: "EF016410",
    featureDesc: "With washer and seal",
    label: "EF016410: With washer and seal"
  },
  {
    featureId: "EF016411",
    featureDesc: "With pin in slot",
    label: "EF016411: With pin in slot"
  },
  {
    featureId: "EF016412",
    featureDesc: "Fixing thread",
    label: "EF016412: Fixing thread"
  },
  {
    featureId: "EF016413",
    featureDesc: "Welded",
    label: "EF016413: Welded"
  },
  {
    featureId: "EF016414",
    featureDesc: "Link length",
    label: "EF016414: Link length"
  },
  {
    featureId: "EF016416",
    featureDesc: "With hole-enlarging wings",
    label: "EF016416: With hole-enlarging wings"
  },
  {
    featureId: "EF016417",
    featureDesc: "Collated strip packaging",
    label: "EF016417: Collated strip packaging"
  },
  {
    featureId: "EF016418",
    featureDesc: "Type of strip",
    label: "EF016418: Type of strip"
  },
  {
    featureId: "EF016419",
    featureDesc: "Number of single-phase miniature circuit breakers (MCB)",
    label: "EF016419: Number of single-phase miniature circuit breakers (MCB)"
  },
  {
    featureId: "EF016420",
    featureDesc: "Number of three-phase miniature circuit breakers (MCB)",
    label: "EF016420: Number of three-phase miniature circuit breakers (MCB)"
  },
  {
    featureId: "EF016422",
    featureDesc: "Link width",
    label: "EF016422: Link width"
  },
  {
    featureId: "EF016427",
    featureDesc: "Pin height",
    label: "EF016427: Pin height"
  },
  {
    featureId: "EF016431",
    featureDesc: "Compatible with Casambi",
    label: "EF016431: Compatible with Casambi"
  },
  {
    featureId: "EF016438",
    featureDesc: "Number of socket outlets CEE 7/1 (no protective contact)",
    label: "EF016438: Number of socket outlets CEE 7/1 (no protective contact)"
  },
  {
    featureId: "EF016441",
    featureDesc: "Distancing thread",
    label: "EF016441: Distancing thread"
  },
  {
    featureId: "EF016442",
    featureDesc: "Suitable for rubber tube",
    label: "EF016442: Suitable for rubber tube"
  },
  {
    featureId: "EF016443",
    featureDesc: "For venetian blinds",
    label: "EF016443: For venetian blinds"
  },
  {
    featureId: "EF016444",
    featureDesc: "With pre-assembled cabling",
    label: "EF016444: With pre-assembled cabling"
  },
  {
    featureId: "EF016445",
    featureDesc: "Thread size inner thread (metric)",
    label: "EF016445: Thread size inner thread (metric)"
  },
  {
    featureId: "EF016446",
    featureDesc: "Mechanical coupler, connection 3 suitable for",
    label: "EF016446: Mechanical coupler, connection 3 suitable for"
  },
  {
    featureId: "EF016447",
    featureDesc: "Mechanical coupler, connection 4 suitable for",
    label: "EF016447: Mechanical coupler, connection 4 suitable for"
  },
  {
    featureId: "EF016448",
    featureDesc: "Neutral conductor required",
    label: "EF016448: Neutral conductor required"
  },
  {
    featureId: "EF016449",
    featureDesc: "With drain- and overflowset",
    label: "EF016449: With drain- and overflowset"
  },
  {
    featureId: "EF016450",
    featureDesc: "Device diameter",
    label: "EF016450: Device diameter"
  },
  {
    featureId: "EF016451",
    featureDesc: "Air cleaning with UV-C radiation",
    label: "EF016451: Air cleaning with UV-C radiation"
  },
  {
    featureId: "EF016452",
    featureDesc: "Test certificate",
    label: "EF016452: Test certificate"
  },
  {
    featureId: "EF016453",
    featureDesc: "Scaling bar graph linear",
    label: "EF016453: Scaling bar graph linear"
  },
  {
    featureId: "EF016454",
    featureDesc: "Scaling bar graph logarithmic",
    label: "EF016454: Scaling bar graph logarithmic"
  },
  {
    featureId: "EF016455",
    featureDesc: "Efficiency according to ISO 16890-1:2016",
    label: "EF016455: Efficiency according to ISO 16890-1:2016"
  },
  {
    featureId: "EF016456",
    featureDesc: "Filter class according to ISO 16890-1:2016",
    label: "EF016456: Filter class according to ISO 16890-1:2016"
  },
  {
    featureId: "EF016457",
    featureDesc: "Pitch measure",
    label: "EF016457: Pitch measure"
  },
  {
    featureId: "EF016459",
    featureDesc: "With auger point tip",
    label: "EF016459: With auger point tip"
  },
  {
    featureId: "EF016466",
    featureDesc: "CE mark for load bearing according to EN 1090",
    label: "EF016466: CE mark for load bearing according to EN 1090"
  },
  {
    featureId: "EF016467",
    featureDesc: "CE mark for non load bearing according to EN 14195",
    label: "EF016467: CE mark for non load bearing according to EN 14195"
  },
  {
    featureId: "EF016471",
    featureDesc: "Suitable for water meter",
    label: "EF016471: Suitable for water meter"
  },
  {
    featureId: "EF016472",
    featureDesc: "Suitable for gas meter",
    label: "EF016472: Suitable for gas meter"
  },
  {
    featureId: "EF016475",
    featureDesc: "Wire break detection",
    label: "EF016475: Wire break detection"
  },
  {
    featureId: "EF016481",
    featureDesc: "Number of microphones in array",
    label: "EF016481: Number of microphones in array"
  },
  {
    featureId: "EF016482",
    featureDesc: "With acoustic distance measurement",
    label: "EF016482: With acoustic distance measurement"
  },
  {
    featureId: "EF016483",
    featureDesc: "Switching via Reed contact",
    label: "EF016483: Switching via Reed contact"
  },
  {
    featureId: "EF016484",
    featureDesc: "U-shaped",
    label: "EF016484: U-shaped"
  },
  {
    featureId: "EF016485",
    featureDesc: "Length of legs",
    label: "EF016485: Length of legs"
  },
  {
    featureId: "EF016488",
    featureDesc: "Set assembled",
    label: "EF016488: Set assembled"
  },
  {
    featureId: "EF016490",
    featureDesc: "Circuit integrity according to EN 50200 annex E",
    label: "EF016490: Circuit integrity according to EN 50200 annex E"
  },
  {
    featureId: "EF016492",
    featureDesc: "Flame retardant according to IEC 60332-1-2",
    label: "EF016492: Flame retardant according to IEC 60332-1-2"
  },
  {
    featureId: "EF016493",
    featureDesc: "Flame retardant according to IEC 60332-3-21 (Cat A F/R)",
    label: "EF016493: Flame retardant according to IEC 60332-3-21 (Cat A F/R)"
  },
  {
    featureId: "EF016494",
    featureDesc: "Flame retardant according to IEC 60332-3-22 (Cat A)",
    label: "EF016494: Flame retardant according to IEC 60332-3-22 (Cat A)"
  },
  {
    featureId: "EF016495",
    featureDesc: "Flame retardant according to IEC 60332-3-23 (Cat B)",
    label: "EF016495: Flame retardant according to IEC 60332-3-23 (Cat B)"
  },
  {
    featureId: "EF016496",
    featureDesc: "Flame retardant according to IEC 60332-3-24 (Cat C)",
    label: "EF016496: Flame retardant according to IEC 60332-3-24 (Cat C)"
  },
  {
    featureId: "EF016497",
    featureDesc: "Flame retardant according to IEC 60332-3-25 (Cat D)",
    label: "EF016497: Flame retardant according to IEC 60332-3-25 (Cat D)"
  },
  {
    featureId: "EF016498",
    featureDesc: "Halogen free according to EN IEC 60754-1",
    label: "EF016498: Halogen free according to EN IEC 60754-1"
  },
  {
    featureId: "EF016500",
    featureDesc: "Halogen free according to EN IEC 60754-3",
    label: "EF016500: Halogen free according to EN IEC 60754-3"
  },
  {
    featureId: "EF016502",
    featureDesc: "Suitable for energy measurement",
    label: "EF016502: Suitable for energy measurement"
  },
  {
    featureId: "EF016503",
    featureDesc: "Public switched telephone network (PSTN)",
    label: "EF016503: Public switched telephone network (PSTN)"
  },
  {
    featureId: "EF016507",
    featureDesc: "Tearable by hand",
    label: "EF016507: Tearable by hand"
  },
  {
    featureId: "EF016508",
    featureDesc: "Suitable for floor heating",
    label: "EF016508: Suitable for floor heating"
  },
  {
    featureId: "EF016510",
    featureDesc: "With cutting grooves",
    label: "EF016510: With cutting grooves"
  },
  {
    featureId: "EF016512",
    featureDesc: "Quality insulating material",
    label: "EF016512: Quality insulating material"
  },
  {
    featureId: "EF016514",
    featureDesc: "Number of fastening holes per cable lug",
    label: "EF016514: Number of fastening holes per cable lug"
  },
  {
    featureId: "EF016515",
    featureDesc: "Separate elements",
    label: "EF016515: Separate elements"
  },
  {
    featureId: "EF016517",
    featureDesc: "With storage rack",
    label: "EF016517: With storage rack"
  },
  {
    featureId: "EF016520",
    featureDesc: "Diameter of core insulation",
    label: "EF016520: Diameter of core insulation"
  },
  {
    featureId: "EF016523",
    featureDesc: "Suitable for feedthrough diameter",
    label: "EF016523: Suitable for feedthrough diameter"
  },
  {
    featureId: "EF016525",
    featureDesc: "Installation instruction",
    label: "EF016525: Installation instruction"
  },
  {
    featureId: "EF016530",
    featureDesc: "Colour imprint",
    label: "EF016530: Colour imprint"
  },
  {
    featureId: "EF016531",
    featureDesc: "Transparent background",
    label: "EF016531: Transparent background"
  },
  {
    featureId: "EF016532",
    featureDesc: "Colour background",
    label: "EF016532: Colour background"
  },
  {
    featureId: "EF016533",
    featureDesc: "Transparent imprint",
    label: "EF016533: Transparent imprint"
  },
  {
    featureId: "EF016541",
    featureDesc: "Centre distance pipe connections",
    label: "EF016541: Centre distance pipe connections"
  },
  {
    featureId: "EF016545",
    featureDesc: "RAL-number visible side",
    label: "EF016545: RAL-number visible side"
  },
  {
    featureId: "EF016547",
    featureDesc: "Can be used with a vibration meter",
    label: "EF016547: Can be used with a vibration meter"
  },
  {
    featureId: "EF016548",
    featureDesc: "Can be used with an ultrasonic leak detector",
    label: "EF016548: Can be used with an ultrasonic leak detector"
  },
  {
    featureId: "EF016549",
    featureDesc: "Can be used with laser tools",
    label: "EF016549: Can be used with laser tools"
  },
  {
    featureId: "EF016551",
    featureDesc: "Centre distance, radiator connections",
    label: "EF016551: Centre distance, radiator connections"
  },
  {
    featureId: "EF016553",
    featureDesc: "Suitable for magnetic ballast",
    label: "EF016553: Suitable for magnetic ballast"
  },
  {
    featureId: "EF016555",
    featureDesc: "Suitable for placement in cabinet",
    label: "EF016555: Suitable for placement in cabinet"
  },
  {
    featureId: "EF016556",
    featureDesc: "Suitable for placement on countertop",
    label: "EF016556: Suitable for placement on countertop"
  },
  {
    featureId: "EF016557",
    featureDesc: "Suitable for placement on floor",
    label: "EF016557: Suitable for placement on floor"
  },
  {
    featureId: "EF016558",
    featureDesc: "Suitable for refrigerator/freezer",
    label: "EF016558: Suitable for refrigerator/freezer"
  },
  {
    featureId: "EF016559",
    featureDesc: "Suitable for water dispenser",
    label: "EF016559: Suitable for water dispenser"
  },
  {
    featureId: "EF016560",
    featureDesc: "Suitable for coffee machine",
    label: "EF016560: Suitable for coffee machine"
  },
  {
    featureId: "EF016561",
    featureDesc: "Suitable for wine cooler",
    label: "EF016561: Suitable for wine cooler"
  },
  {
    featureId: "EF016564",
    featureDesc: "With fixing tape",
    label: "EF016564: With fixing tape"
  },
  {
    featureId: "EF016565",
    featureDesc: "Prepared for outlet for leakage indication",
    label: "EF016565: Prepared for outlet for leakage indication"
  },
  {
    featureId: "EF016566",
    featureDesc: "Holding capacity condensation water",
    label: "EF016566: Holding capacity condensation water"
  },
  {
    featureId: "EF016567",
    featureDesc: "Suitable for beams",
    label: "EF016567: Suitable for beams"
  },
  {
    featureId: "EF016570",
    featureDesc: "Suitable for sink cabinet",
    label: "EF016570: Suitable for sink cabinet"
  },
  {
    featureId: "EF016574",
    featureDesc: "Fuse length",
    label: "EF016574: Fuse length"
  },
  {
    featureId: "EF016578",
    featureDesc: "Rated voltage Um",
    label: "EF016578: Rated voltage Um"
  },
  {
    featureId: "EF016579",
    featureDesc: "For cooling systems up to",
    label: "EF016579: For cooling systems up to"
  },
  {
    featureId: "EF016580",
    featureDesc: "For heating systems up to",
    label: "EF016580: For heating systems up to"
  },
  {
    featureId: "EF016581",
    featureDesc: "Saw blade included",
    label: "EF016581: Saw blade included"
  },
  {
    featureId: "EF016586",
    featureDesc: "All sides folded up",
    label: "EF016586: All sides folded up"
  },
  {
    featureId: "EF016590",
    featureDesc: "Fastening depth",
    label: "EF016590: Fastening depth"
  },
  {
    featureId: "EF016591",
    featureDesc: "Cable length pressure sensor",
    label: "EF016591: Cable length pressure sensor"
  },
  {
    featureId: "EF016592",
    featureDesc: "Adhesive category according to EN 12004",
    label: "EF016592: Adhesive category according to EN 12004"
  },
  {
    featureId: "EF016593",
    featureDesc: "Type of connection for sensor/communication module",
    label: "EF016593: Type of connection for sensor/communication module"
  },
  {
    featureId: "EF016594",
    featureDesc: "External temperature sensor",
    label: "EF016594: External temperature sensor"
  },
  {
    featureId: "EF016595",
    featureDesc: "Inclination measurement",
    label: "EF016595: Inclination measurement"
  },
  {
    featureId: "EF016596",
    featureDesc: "Compass/cardinal direction measurement",
    label: "EF016596: Compass/cardinal direction measurement"
  },
  {
    featureId: "EF016606",
    featureDesc: "Suitable for solar collector circuit",
    label: "EF016606: Suitable for solar collector circuit"
  },
  {
    featureId: "EF016607",
    featureDesc: "Screw diameter",
    label: "EF016607: Screw diameter"
  },
  {
    featureId: "EF016612",
    featureDesc: "With key ring",
    label: "EF016612: With key ring"
  },
  {
    featureId: "EF016613",
    featureDesc: "Material screening",
    label: "EF016613: Material screening"
  },
  {
    featureId: "EF016614",
    featureDesc: "Surface treatment screening",
    label: "EF016614: Surface treatment screening"
  },
  {
    featureId: "EF016616",
    featureDesc: "Circuit integrity according to IEC 60331-1",
    label: "EF016616: Circuit integrity according to IEC 60331-1"
  },
  {
    featureId: "EF016617",
    featureDesc: "Circuit integrity according to IEC 60331-2",
    label: "EF016617: Circuit integrity according to IEC 60331-2"
  },
  {
    featureId: "EF016618",
    featureDesc: "Circuit integrity according to IEC 60331-3",
    label: "EF016618: Circuit integrity according to IEC 60331-3"
  },
  {
    featureId: "EF016619",
    featureDesc: "Circuit integrity according to IEC 60331-23",
    label: "EF016619: Circuit integrity according to IEC 60331-23"
  },
  {
    featureId: "EF016620",
    featureDesc: "Circuit integrity according to IEC 60331-25",
    label: "EF016620: Circuit integrity according to IEC 60331-25"
  },
  {
    featureId: "EF016621",
    featureDesc: "Circuit integrity according to EN 50200",
    label: "EF016621: Circuit integrity according to EN 50200"
  },
  {
    featureId: "EF016622",
    featureDesc: "Square size 1",
    label: "EF016622: Square size 1"
  },
  {
    featureId: "EF016623",
    featureDesc: "Square size 2",
    label: "EF016623: Square size 2"
  },
  {
    featureId: "EF016624",
    featureDesc: "Square size 3",
    label: "EF016624: Square size 3"
  },
  {
    featureId: "EF016625",
    featureDesc: "Square size 4",
    label: "EF016625: Square size 4"
  },
  {
    featureId: "EF016626",
    featureDesc: "Wire thickness",
    label: "EF016626: Wire thickness"
  },
  {
    featureId: "EF016627",
    featureDesc: "Wire width",
    label: "EF016627: Wire width"
  },
  {
    featureId: "EF016628",
    featureDesc: "Cable length temperature sensor",
    label: "EF016628: Cable length temperature sensor"
  },
  {
    featureId: "EF016629",
    featureDesc: "Model camera",
    label: "EF016629: Model camera"
  },
  {
    featureId: "EF016630",
    featureDesc: "Internal memory capacity camera",
    label: "EF016630: Internal memory capacity camera"
  },
  {
    featureId: "EF016631",
    featureDesc: "Degree of protection (IP) camera",
    label: "EF016631: Degree of protection (IP) camera"
  },
  {
    featureId: "EF016633",
    featureDesc: "Type of hot tap water feature",
    label: "EF016633: Type of hot tap water feature"
  },
  {
    featureId: "EF016634",
    featureDesc: "Material hot tap water feature",
    label: "EF016634: Material hot tap water feature"
  },
  {
    featureId: "EF016638",
    featureDesc: "High contrast display",
    label: "EF016638: High contrast display"
  },
  {
    featureId: "EF016639",
    featureDesc: "Wired camera connection",
    label: "EF016639: Wired camera connection"
  },
  {
    featureId: "EF016640",
    featureDesc: "Wireless camera connection",
    label: "EF016640: Wireless camera connection"
  },
  {
    featureId: "EF016642",
    featureDesc: "Suitable for kitchen mixing tap",
    label: "EF016642: Suitable for kitchen mixing tap"
  },
  {
    featureId: "EF016643",
    featureDesc: "Suitable for washbasin mixing tap",
    label: "EF016643: Suitable for washbasin mixing tap"
  },
  {
    featureId: "EF016660",
    featureDesc: "Tensile adhesive strength according to EN 12004",
    label: "EF016660: Tensile adhesive strength according to EN 12004"
  },
  {
    featureId: "EF016661",
    featureDesc: "Deformability according to EN 12004",
    label: "EF016661: Deformability according to EN 12004"
  },
  {
    featureId: "EF016663",
    featureDesc: "Suitable for thin bed adhesive layers (1-10 mm)",
    label: "EF016663: Suitable for thin bed adhesive layers (1-10 mm)"
  },
  {
    featureId: "EF016664",
    featureDesc: "Suitable for mid bed adhesive layers (5-20 mm)",
    label: "EF016664: Suitable for mid bed adhesive layers (5-20 mm)"
  },
  {
    featureId: "EF016665",
    featureDesc: "Suitable for thick bed adhesive layers (>15 mm)",
    label: "EF016665: Suitable for thick bed adhesive layers (>15 mm)"
  },
  {
    featureId: "EF016666",
    featureDesc: "Extended open time according to EN 12004 (E)",
    label: "EF016666: Extended open time according to EN 12004 (E)"
  },
  {
    featureId: "EF016667",
    featureDesc: "Water impermeability class according to EN 14891",
    label: "EF016667: Water impermeability class according to EN 14891"
  },
  {
    featureId: "EF016668",
    featureDesc: "Low-dust",
    label: "EF016668: Low-dust"
  },
  {
    featureId: "EF016670",
    featureDesc: "High lap shear adhesive strength",
    label: "EF016670: High lap shear adhesive strength"
  },
  {
    featureId: "EF016671",
    featureDesc: "Suitable for fluid bed adhesive layers",
    label: "EF016671: Suitable for fluid bed adhesive layers"
  },
  {
    featureId: "EF016672",
    featureDesc: "Type of diffuser",
    label: "EF016672: Type of diffuser"
  },
  {
    featureId: "EF016673",
    featureDesc: "Lightweight",
    label: "EF016673: Lightweight"
  },
  {
    featureId: "EF016674",
    featureDesc: "Nominal air load",
    label: "EF016674: Nominal air load"
  },
  {
    featureId: "EF016675",
    featureDesc: "Suitable for plaster/anhydrite without primer",
    label: "EF016675: Suitable for plaster/anhydrite without primer"
  },
  {
    featureId: "EF016676",
    featureDesc: "Suitable for tile over tile bonding",
    label: "EF016676: Suitable for tile over tile bonding"
  },
  {
    featureId: "EF016677",
    featureDesc: "Suitable for recently screeded floors",
    label: "EF016677: Suitable for recently screeded floors"
  },
  {
    featureId: "EF016679",
    featureDesc: "Colour light 1",
    label: "EF016679: Colour light 1"
  },
  {
    featureId: "EF016680",
    featureDesc: "Colour light 2",
    label: "EF016680: Colour light 2"
  },
  {
    featureId: "EF016681",
    featureDesc: "Colour light 3",
    label: "EF016681: Colour light 3"
  },
  {
    featureId: "EF016682",
    featureDesc: "Colour light 4",
    label: "EF016682: Colour light 4"
  },
  {
    featureId: "EF016683",
    featureDesc: "Colour light 5",
    label: "EF016683: Colour light 5"
  },
  {
    featureId: "EF016685",
    featureDesc: "Water volume of central heating water",
    label: "EF016685: Water volume of central heating water"
  },
  {
    featureId: "EF016687",
    featureDesc: "Connection type according to IEC 60320, connection 1",
    label: "EF016687: Connection type according to IEC 60320, connection 1"
  },
  {
    featureId: "EF016688",
    featureDesc: "Connection type according to IEC 60320, connection 2",
    label: "EF016688: Connection type according to IEC 60320, connection 2"
  },
  {
    featureId: "EF016690",
    featureDesc: "Encryption standard",
    label: "EF016690: Encryption standard"
  },
  {
    featureId: "EF016691",
    featureDesc: "Authentication standard",
    label: "EF016691: Authentication standard"
  },
  {
    featureId: "EF016693",
    featureDesc: "Temperature resolution",
    label: "EF016693: Temperature resolution"
  },
  {
    featureId: "EF016694",
    featureDesc: "Measuring range magnetic field frequency",
    label: "EF016694: Measuring range magnetic field frequency"
  },
  {
    featureId: "EF016695",
    featureDesc: "Measuring range magnetic field",
    label: "EF016695: Measuring range magnetic field"
  },
  {
    featureId: "EF016696",
    featureDesc: "Suitable for ultrasonic sound measuring",
    label: "EF016696: Suitable for ultrasonic sound measuring"
  },
  {
    featureId: "EF016697",
    featureDesc: "Measuring range ultrasonic sound frequency",
    label: "EF016697: Measuring range ultrasonic sound frequency"
  },
  {
    featureId: "EF016698",
    featureDesc: "Measuring range ultrasonic sound",
    label: "EF016698: Measuring range ultrasonic sound"
  },
  {
    featureId: "EF016699",
    featureDesc: "Replaceable battery",
    label: "EF016699: Replaceable battery"
  },
  {
    featureId: "EF016701",
    featureDesc: "Usable volume",
    label: "EF016701: Usable volume"
  },
  {
    featureId: "EF016702",
    featureDesc: "Glue mounting",
    label: "EF016702: Glue mounting"
  },
  {
    featureId: "EF016703",
    featureDesc: "Sheathing material halogen free",
    label: "EF016703: Sheathing material halogen free"
  },
  {
    featureId: "EF016704",
    featureDesc: "With front cladding",
    label: "EF016704: With front cladding"
  },
  {
    featureId: "EF016705",
    featureDesc: "Outlet pressure",
    label: "EF016705: Outlet pressure"
  },
  {
    featureId: "EF016706",
    featureDesc: "Suitable as brine fluid for heat pumps",
    label: "EF016706: Suitable as brine fluid for heat pumps"
  },
  {
    featureId: "EF016707",
    featureDesc: "Max. current rating of USB power supply",
    label: "EF016707: Max. current rating of USB power supply"
  },
  {
    featureId: "EF016708",
    featureDesc: "Min. brightness controllable",
    label: "EF016708: Min. brightness controllable"
  },
  {
    featureId: "EF016709",
    featureDesc: "Rated output",
    label: "EF016709: Rated output"
  },
  {
    featureId: "EF016710",
    featureDesc: "With electrical locking",
    label: "EF016710: With electrical locking"
  },
  {
    featureId: "EF016711",
    featureDesc: "With mechanical locking",
    label: "EF016711: With mechanical locking"
  },
  {
    featureId: "EF016712",
    featureDesc: "With override stop for flow limitation",
    label: "EF016712: With override stop for flow limitation"
  },
  {
    featureId: "EF016713",
    featureDesc: "With spring-back resistance for max. flow",
    label: "EF016713: With spring-back resistance for max. flow"
  },
  {
    featureId: "EF016714",
    featureDesc: "With spring-back resistance for max. hot water temperature",
    label: "EF016714: With spring-back resistance for max. hot water temperature"
  },
  {
    featureId: "EF016725",
    featureDesc: "Diameter grid connection",
    label: "EF016725: Diameter grid connection"
  },
  {
    featureId: "EF016726",
    featureDesc: "Width grid connection",
    label: "EF016726: Width grid connection"
  },
  {
    featureId: "EF016727",
    featureDesc: "Height grid connection",
    label: "EF016727: Height grid connection"
  },
  {
    featureId: "EF016728",
    featureDesc: "Position duct connection",
    label: "EF016728: Position duct connection"
  },
  {
    featureId: "EF016729",
    featureDesc: "Number of duct connections",
    label: "EF016729: Number of duct connections"
  },
  {
    featureId: "EF016731",
    featureDesc: "Sound class according to EN ISO 3822",
    label: "EF016731: Sound class according to EN ISO 3822"
  },
  {
    featureId: "EF016733",
    featureDesc: "Profile ribbon joint",
    label: "EF016733: Profile ribbon joint"
  },
  {
    featureId: "EF016734",
    featureDesc: "Profile butt joint",
    label: "EF016734: Profile butt joint"
  },
  {
    featureId: "EF016737",
    featureDesc: "Measuring range irradiance",
    label: "EF016737: Measuring range irradiance"
  },
  {
    featureId: "EF016747",
    featureDesc: "Gypsum boards from reprocessing according to EN 14190:2014",
    label: "EF016747: Gypsum boards from reprocessing according to EN 14190:2014"
  },
  {
    featureId: "EF016748",
    featureDesc: "Relay technology category according to IEC 61810-7",
    label: "EF016748: Relay technology category according to IEC 61810-7"
  },
  {
    featureId: "EF016760",
    featureDesc: "Continuity resistor",
    label: "EF016760: Continuity resistor"
  },
  {
    featureId: "EF016761",
    featureDesc: "Continuity measurement current",
    label: "EF016761: Continuity measurement current"
  },
  {
    featureId: "EF016762",
    featureDesc: "Compensation of measuring line resistance",
    label: "EF016762: Compensation of measuring line resistance"
  },
  {
    featureId: "EF016763",
    featureDesc: "Open circuit voltage measurement Uoc",
    label: "EF016763: Open circuit voltage measurement Uoc"
  },
  {
    featureId: "EF016764",
    featureDesc: "Short circuit current measurement Isc",
    label: "EF016764: Short circuit current measurement Isc"
  },
  {
    featureId: "EF016765",
    featureDesc: "Insulation resistance measurement voltage",
    label: "EF016765: Insulation resistance measurement voltage"
  },
  {
    featureId: "EF016766",
    featureDesc: "External voltage detection",
    label: "EF016766: External voltage detection"
  },
  {
    featureId: "EF016767",
    featureDesc: "Polarity check",
    label: "EF016767: Polarity check"
  },
  {
    featureId: "EF016768",
    featureDesc: "Earth fault measurement",
    label: "EF016768: Earth fault measurement"
  },
  {
    featureId: "EF016769",
    featureDesc: "Measurement of solar radiation",
    label: "EF016769: Measurement of solar radiation"
  },
  {
    featureId: "EF016770",
    featureDesc: "Parallel line resistor measurement Rp",
    label: "EF016770: Parallel line resistor measurement Rp"
  },
  {
    featureId: "EF016771",
    featureDesc: "Internal serial resistance measurement Rs",
    label: "EF016771: Internal serial resistance measurement Rs"
  },
  {
    featureId: "EF016772",
    featureDesc: "Measurement Maximum Power Point (MPP)",
    label: "EF016772: Measurement Maximum Power Point (MPP)"
  },
  {
    featureId: "EF016773",
    featureDesc: "Peak performance measurement (Ppk)",
    label: "EF016773: Peak performance measurement (Ppk)"
  },
  {
    featureId: "EF016774",
    featureDesc: "Transposition to STC",
    label: "EF016774: Transposition to STC"
  },
  {
    featureId: "EF016775",
    featureDesc: "Transposition to NOCT",
    label: "EF016775: Transposition to NOCT"
  },
  {
    featureId: "EF016776",
    featureDesc: "Current voltage curve trace",
    label: "EF016776: Current voltage curve trace"
  },
  {
    featureId: "EF016777",
    featureDesc: "Aggregate type",
    label: "EF016777: Aggregate type"
  },
  {
    featureId: "EF016778",
    featureDesc: "Surface protection visible side",
    label: "EF016778: Surface protection visible side"
  },
  {
    featureId: "EF016780",
    featureDesc: "Surface protection back side",
    label: "EF016780: Surface protection back side"
  },
  {
    featureId: "EF016782",
    featureDesc: "Fuse diameter",
    label: "EF016782: Fuse diameter"
  },
  {
    featureId: "EF016783",
    featureDesc: "RAL-number coding/contact insert",
    label: "EF016783: RAL-number coding/contact insert"
  },
  {
    featureId: "EF016788",
    featureDesc: "Cable entry connection 1",
    label: "EF016788: Cable entry connection 1"
  },
  {
    featureId: "EF016789",
    featureDesc: "Cable entry connection 2",
    label: "EF016789: Cable entry connection 2"
  },
  {
    featureId: "EF016790",
    featureDesc: "Radio standard 2G",
    label: "EF016790: Radio standard 2G"
  },
  {
    featureId: "EF016791",
    featureDesc: "Radio standard 3G",
    label: "EF016791: Radio standard 3G"
  },
  {
    featureId: "EF016792",
    featureDesc: "Radio standard 4G",
    label: "EF016792: Radio standard 4G"
  },
  {
    featureId: "EF016796",
    featureDesc: "Surface finish visible side",
    label: "EF016796: Surface finish visible side"
  },
  {
    featureId: "EF016800",
    featureDesc: "Percentage hydrogen",
    label: "EF016800: Percentage hydrogen"
  },
  {
    featureId: "EF016801",
    featureDesc: "Wall thickness connecting pipe, connection 1",
    label: "EF016801: Wall thickness connecting pipe, connection 1"
  },
  {
    featureId: "EF016802",
    featureDesc: "Wall thickness connecting pipe, connection 2",
    label: "EF016802: Wall thickness connecting pipe, connection 2"
  },
  {
    featureId: "EF016804",
    featureDesc: "Suitable as bilge block",
    label: "EF016804: Suitable as bilge block"
  },
  {
    featureId: "EF016805",
    featureDesc: "Thermal bridge prevention",
    label: "EF016805: Thermal bridge prevention"
  },
  {
    featureId: "EF016807",
    featureDesc: "Percentage recycled materials",
    label: "EF016807: Percentage recycled materials"
  },
  {
    featureId: "EF016810",
    featureDesc: "Suitable for low-calorific natural gas",
    label: "EF016810: Suitable for low-calorific natural gas"
  },
  {
    featureId: "EF016811",
    featureDesc: "Suitable for high-calorific natural gas",
    label: "EF016811: Suitable for high-calorific natural gas"
  },
  {
    featureId: "EF016812",
    featureDesc: "Suitable for propane",
    label: "EF016812: Suitable for propane"
  },
  {
    featureId: "EF016813",
    featureDesc: "Suitable for butane",
    label: "EF016813: Suitable for butane"
  },
  {
    featureId: "EF016814",
    featureDesc: "Suitable for methane",
    label: "EF016814: Suitable for methane"
  },
  {
    featureId: "EF016815",
    featureDesc: "Suitable for liquefied petroleum gas (LPG)",
    label: "EF016815: Suitable for liquefied petroleum gas (LPG)"
  },
  {
    featureId: "EF016823",
    featureDesc: "Suitable for hydrogen",
    label: "EF016823: Suitable for hydrogen"
  },
  {
    featureId: "EF016824",
    featureDesc: "Possibility to limit the outlet max. turning radius",
    label: "EF016824: Possibility to limit the outlet max. turning radius"
  },
  {
    featureId: "EF016825",
    featureDesc: "Outlet turning radius",
    label: "EF016825: Outlet turning radius"
  },
  {
    featureId: "EF016826",
    featureDesc: "According to IEC 60282-1",
    label: "EF016826: According to IEC 60282-1"
  },
  {
    featureId: "EF016828",
    featureDesc: "Decoration visible side",
    label: "EF016828: Decoration visible side"
  },
  {
    featureId: "EF016829",
    featureDesc: "Decoration back side",
    label: "EF016829: Decoration back side"
  },
  {
    featureId: "EF016830",
    featureDesc: "Colour group visible side",
    label: "EF016830: Colour group visible side"
  },
  {
    featureId: "EF016831",
    featureDesc: "Colour group back side",
    label: "EF016831: Colour group back side"
  },
  {
    featureId: "EF016832",
    featureDesc: "Degree of gloss visible side",
    label: "EF016832: Degree of gloss visible side"
  },
  {
    featureId: "EF016833",
    featureDesc: "Degree of gloss back side",
    label: "EF016833: Degree of gloss back side"
  },
  {
    featureId: "EF016835",
    featureDesc: "Energy efficiency class of the light source according to EU regulation 2019/2015",
    label: "EF016835: Energy efficiency class of the light source according to EU regulation 2019/2015"
  },
  {
    featureId: "EF016836",
    featureDesc: "Automatic brightness control",
    label: "EF016836: Automatic brightness control"
  },
  {
    featureId: "EF016853",
    featureDesc: "Loudness rating according to EN 62820-1-1",
    label: "EF016853: Loudness rating according to EN 62820-1-1"
  },
  {
    featureId: "EF016856",
    featureDesc: "Type of grout according to EN 13888",
    label: "EF016856: Type of grout according to EN 13888"
  },
  {
    featureId: "EF016857",
    featureDesc: "Low water absorption (W) according to EN 13888",
    label: "EF016857: Low water absorption (W) according to EN 13888"
  },
  {
    featureId: "EF016858",
    featureDesc: "Resistance to chemicals tested according to EN 12808-1",
    label: "EF016858: Resistance to chemicals tested according to EN 12808-1"
  },
  {
    featureId: "EF016859",
    featureDesc: "Abrasion resistance according to EN 12808-2",
    label: "EF016859: Abrasion resistance according to EN 12808-2"
  },
  {
    featureId: "EF016860",
    featureDesc: "Flexural strength according to EN 12808-3",
    label: "EF016860: Flexural strength according to EN 12808-3"
  },
  {
    featureId: "EF016861",
    featureDesc: "Compressive strength according to EN 12808-3",
    label: "EF016861: Compressive strength according to EN 12808-3"
  },
  {
    featureId: "EF016862",
    featureDesc: "Shrinkage according to EN 12808-4",
    label: "EF016862: Shrinkage according to EN 12808-4"
  },
  {
    featureId: "EF016863",
    featureDesc: "Water absorption according to EN 12808-5",
    label: "EF016863: Water absorption according to EN 12808-5"
  },
  {
    featureId: "EF016864",
    featureDesc: "Suitable for joint width",
    label: "EF016864: Suitable for joint width"
  },
  {
    featureId: "EF016865",
    featureDesc: "Machine processable",
    label: "EF016865: Machine processable"
  },
  {
    featureId: "EF016866",
    featureDesc: "Suitable for wall tiles",
    label: "EF016866: Suitable for wall tiles"
  },
  {
    featureId: "EF016867",
    featureDesc: "Suitable for floor tiles",
    label: "EF016867: Suitable for floor tiles"
  },
  {
    featureId: "EF016868",
    featureDesc: "Potlife",
    label: "EF016868: Potlife"
  },
  {
    featureId: "EF016871",
    featureDesc: "Suitable for wall and floor heating",
    label: "EF016871: Suitable for wall and floor heating"
  },
  {
    featureId: "EF016872",
    featureDesc: "Suitable for wooden surface",
    label: "EF016872: Suitable for wooden surface"
  },
  {
    featureId: "EF016873",
    featureDesc: "Nominal diameter shaft",
    label: "EF016873: Nominal diameter shaft"
  },
  {
    featureId: "EF016882",
    featureDesc: "USB recording function",
    label: "EF016882: USB recording function"
  },
  {
    featureId: "EF016883",
    featureDesc: "Max. working voltage AC",
    label: "EF016883: Max. working voltage AC"
  },
  {
    featureId: "EF016884",
    featureDesc: "Max. working voltage DC",
    label: "EF016884: Max. working voltage DC"
  },
  {
    featureId: "EF016885",
    featureDesc: "Size tolerance class according to EN 771",
    label: "EF016885: Size tolerance class according to EN 771"
  },
  {
    featureId: "EF016886",
    featureDesc: "Max. size range class according to EN 771",
    label: "EF016886: Max. size range class according to EN 771"
  },
  {
    featureId: "EF016887",
    featureDesc: "Gross dry density class according to EN 771-2",
    label: "EF016887: Gross dry density class according to EN 771-2"
  },
  {
    featureId: "EF016888",
    featureDesc: "Isolated connections",
    label: "EF016888: Isolated connections"
  },
  {
    featureId: "EF016889",
    featureDesc: "Kind of luminaire cover/post globe",
    label: "EF016889: Kind of luminaire cover/post globe"
  },
  {
    featureId: "EF016891",
    featureDesc: "Number of pilot device lockouts",
    label: "EF016891: Number of pilot device lockouts"
  },
  {
    featureId: "EF016892",
    featureDesc: "Number of fuse lockouts",
    label: "EF016892: Number of fuse lockouts"
  },
  {
    featureId: "EF016893",
    featureDesc: "Number of switch lockouts",
    label: "EF016893: Number of switch lockouts"
  },
  {
    featureId: "EF016894",
    featureDesc: "Number of valve lockouts",
    label: "EF016894: Number of valve lockouts"
  },
  {
    featureId: "EF016895",
    featureDesc: "Number of chain/wire lockouts",
    label: "EF016895: Number of chain/wire lockouts"
  },
  {
    featureId: "EF016896",
    featureDesc: "Number of padlocks",
    label: "EF016896: Number of padlocks"
  },
  {
    featureId: "EF016897",
    featureDesc: "Number of tags",
    label: "EF016897: Number of tags"
  },
  {
    featureId: "EF016899",
    featureDesc: "Anti-fatigue",
    label: "EF016899: Anti-fatigue"
  },
  {
    featureId: "EF016901",
    featureDesc: "Flexural strength according to EN 998-1",
    label: "EF016901: Flexural strength according to EN 998-1"
  },
  {
    featureId: "EF016902",
    featureDesc: "With cutlery drawer",
    label: "EF016902: With cutlery drawer"
  },
  {
    featureId: "EF016903",
    featureDesc: "With cutlery basket",
    label: "EF016903: With cutlery basket"
  },
  {
    featureId: "EF016904",
    featureDesc: "Compressive strength class according to EN 998-1",
    label: "EF016904: Compressive strength class according to EN 998-1"
  },
  {
    featureId: "EF016905",
    featureDesc: "With book cover",
    label: "EF016905: With book cover"
  },
  {
    featureId: "EF016906",
    featureDesc: "Paper weight",
    label: "EF016906: Paper weight"
  },
  {
    featureId: "EF016907",
    featureDesc: "Paper colour",
    label: "EF016907: Paper colour"
  },
  {
    featureId: "EF016908",
    featureDesc: "Adhesive strength according to EN 998-1",
    label: "EF016908: Adhesive strength according to EN 998-1"
  },
  {
    featureId: "EF016909",
    featureDesc: "Max. grain size according to EN 998-1",
    label: "EF016909: Max. grain size according to EN 998-1"
  },
  {
    featureId: "EF016910",
    featureDesc: "Material required per 1 mm layer thickness",
    label: "EF016910: Material required per 1 mm layer thickness"
  },
  {
    featureId: "EF016911",
    featureDesc: "Compressive strength according to EN 998-1",
    label: "EF016911: Compressive strength according to EN 998-1"
  },
  {
    featureId: "EF016912",
    featureDesc: "Type of electronic operating method",
    label: "EF016912: Type of electronic operating method"
  },
  {
    featureId: "EF016913",
    featureDesc: "Class type according to EN 300",
    label: "EF016913: Class type according to EN 300"
  },
  {
    featureId: "EF016914",
    featureDesc: "With built-in bluetooth speaker",
    label: "EF016914: With built-in bluetooth speaker"
  },
  {
    featureId: "EF016917",
    featureDesc: "With shaver socket",
    label: "EF016917: With shaver socket"
  },
  {
    featureId: "EF016919",
    featureDesc: "AVCP system",
    label: "EF016919: AVCP system"
  },
  {
    featureId: "EF016921",
    featureDesc: "Binder/plaster type according to EN 13279",
    label: "EF016921: Binder/plaster type according to EN 13279"
  },
  {
    featureId: "EF016925",
    featureDesc: "Tileable after",
    label: "EF016925: Tileable after"
  },
  {
    featureId: "EF016926",
    featureDesc: "Colourable",
    label: "EF016926: Colourable"
  },
  {
    featureId: "EF016928",
    featureDesc: "Suitable for base layer",
    label: "EF016928: Suitable for base layer"
  },
  {
    featureId: "EF016929",
    featureDesc: "Suitable for finishing layer",
    label: "EF016929: Suitable for finishing layer"
  },
  {
    featureId: "EF016930",
    featureDesc: "Suitable for renovation",
    label: "EF016930: Suitable for renovation"
  },
  {
    featureId: "EF016931",
    featureDesc: "Thermal insulating",
    label: "EF016931: Thermal insulating"
  },
  {
    featureId: "EF016932",
    featureDesc: "Visible side sanded",
    label: "EF016932: Visible side sanded"
  },
  {
    featureId: "EF016933",
    featureDesc: "Back side sanded",
    label: "EF016933: Back side sanded"
  },
  {
    featureId: "EF016935",
    featureDesc: "Bonding quality class according to EN 314-2",
    label: "EF016935: Bonding quality class according to EN 314-2"
  },
  {
    featureId: "EF016936",
    featureDesc: "Biological durability class according to EN 13986",
    label: "EF016936: Biological durability class according to EN 13986"
  },
  {
    featureId: "EF016937",
    featureDesc: "Beam angle horizontal",
    label: "EF016937: Beam angle horizontal"
  },
  {
    featureId: "EF016938",
    featureDesc: "Beam angle vertical",
    label: "EF016938: Beam angle vertical"
  },
  {
    featureId: "EF016939",
    featureDesc: "Luminous intensity",
    label: "EF016939: Luminous intensity"
  },
  {
    featureId: "EF016940",
    featureDesc: "Number of arbors",
    label: "EF016940: Number of arbors"
  },
  {
    featureId: "EF016942",
    featureDesc: "Handle length",
    label: "EF016942: Handle length"
  },
  {
    featureId: "EF016943",
    featureDesc: "Type of waste fitting",
    label: "EF016943: Type of waste fitting"
  },
  {
    featureId: "EF016945",
    featureDesc: "With side shower",
    label: "EF016945: With side shower"
  },
  {
    featureId: "EF016947",
    featureDesc: "Connection 1, bottom left",
    label: "EF016947: Connection 1, bottom left"
  },
  {
    featureId: "EF016948",
    featureDesc: "Connection 2, left side low",
    label: "EF016948: Connection 2, left side low"
  },
  {
    featureId: "EF016949",
    featureDesc: "Connection 3, left side top",
    label: "EF016949: Connection 3, left side top"
  },
  {
    featureId: "EF016950",
    featureDesc: "Connection 4, top left",
    label: "EF016950: Connection 4, top left"
  },
  {
    featureId: "EF016951",
    featureDesc: "Connection 5, top right",
    label: "EF016951: Connection 5, top right"
  },
  {
    featureId: "EF016952",
    featureDesc: "Connection 6, right side top",
    label: "EF016952: Connection 6, right side top"
  },
  {
    featureId: "EF016953",
    featureDesc: "Connection 7, right side low",
    label: "EF016953: Connection 7, right side low"
  },
  {
    featureId: "EF016954",
    featureDesc: "Connection TC, top center",
    label: "EF016954: Connection TC, top center"
  },
  {
    featureId: "EF016955",
    featureDesc: "Connection BC, bottom center",
    label: "EF016955: Connection BC, bottom center"
  },
  {
    featureId: "EF016956",
    featureDesc: "Connection 8, bottom right",
    label: "EF016956: Connection 8, bottom right"
  },
  {
    featureId: "EF016957",
    featureDesc: "Number of socket outlets Italian standard type P30",
    label: "EF016957: Number of socket outlets Italian standard type P30"
  },
  {
    featureId: "EF016958",
    featureDesc: "ONVIF-compliant",
    label: "EF016958: ONVIF-compliant"
  },
  {
    featureId: "EF016959",
    featureDesc: "RAID 6 capable",
    label: "EF016959: RAID 6 capable"
  },
  {
    featureId: "EF016960",
    featureDesc: "RAID 10 capable",
    label: "EF016960: RAID 10 capable"
  },
  {
    featureId: "EF016961",
    featureDesc: "Cutting/pressing force",
    label: "EF016961: Cutting/pressing force"
  },
  {
    featureId: "EF016962",
    featureDesc: "Spreading force",
    label: "EF016962: Spreading force"
  },
  {
    featureId: "EF016963",
    featureDesc: "Application spreading",
    label: "EF016963: Application spreading"
  },
  {
    featureId: "EF016964",
    featureDesc: "Nominal size drain",
    label: "EF016964: Nominal size drain"
  },
  {
    featureId: "EF016966",
    featureDesc: "With drain reducing bush",
    label: "EF016966: With drain reducing bush"
  },
  {
    featureId: "EF016968",
    featureDesc: "Horizontal distance centre siphon to wall",
    label: "EF016968: Horizontal distance centre siphon to wall"
  },
  {
    featureId: "EF016969",
    featureDesc: "Vertical distance centre wall pipe to top side drain plug",
    label: "EF016969: Vertical distance centre wall pipe to top side drain plug"
  },
  {
    featureId: "EF016970",
    featureDesc: "Outer pipe diameter drainage",
    label: "EF016970: Outer pipe diameter drainage"
  },
  {
    featureId: "EF016971",
    featureDesc: "Surface appearance class visible side according to EN 635",
    label: "EF016971: Surface appearance class visible side according to EN 635"
  },
  {
    featureId: "EF016972",
    featureDesc: "Surface appearance class back side according to EN 635",
    label: "EF016972: Surface appearance class back side according to EN 635"
  },
  {
    featureId: "EF016973",
    featureDesc: "Surface protection edge",
    label: "EF016973: Surface protection edge"
  },
  {
    featureId: "EF016974",
    featureDesc: "Flame retardant according to EN 50265-2-1",
    label: "EF016974: Flame retardant according to EN 50265-2-1"
  },
  {
    featureId: "EF016976",
    featureDesc: "Directly pluggable (without power cord)",
    label: "EF016976: Directly pluggable (without power cord)"
  },
  {
    featureId: "EF016977",
    featureDesc: "With light centre connection",
    label: "EF016977: With light centre connection"
  },
  {
    featureId: "EF016978",
    featureDesc: "Number of connectors for wall socket outlets",
    label: "EF016978: Number of connectors for wall socket outlets"
  },
  {
    featureId: "EF016979",
    featureDesc: "Global warming potential (GWP)",
    label: "EF016979: Global warming potential (GWP)"
  },
  {
    featureId: "EF016980",
    featureDesc: "RAL-number back side",
    label: "EF016980: RAL-number back side"
  },
  {
    featureId: "EF016981",
    featureDesc: "Suitable for gluing inner wall bricks",
    label: "EF016981: Suitable for gluing inner wall bricks"
  },
  {
    featureId: "EF016984",
    featureDesc: "Inner pipe diameter",
    label: "EF016984: Inner pipe diameter"
  },
  {
    featureId: "EF016985",
    featureDesc: "Tested according to EAD 090062-00-0404",
    label: "EF016985: Tested according to EAD 090062-00-0404"
  },
  {
    featureId: "EF016986",
    featureDesc: "Adhesive consumption",
    label: "EF016986: Adhesive consumption"
  },
  {
    featureId: "EF016987",
    featureDesc: "Morter consumption",
    label: "EF016987: Morter consumption"
  },
  {
    featureId: "EF016988",
    featureDesc: "Suitable for dry environment",
    label: "EF016988: Suitable for dry environment"
  },
  {
    featureId: "EF016989",
    featureDesc: "Suitable for wet environment",
    label: "EF016989: Suitable for wet environment"
  },
  {
    featureId: "EF016990",
    featureDesc: "Suitable for oily environment",
    label: "EF016990: Suitable for oily environment"
  },
  {
    featureId: "EF016991",
    featureDesc: "Suitable for handle length",
    label: "EF016991: Suitable for handle length"
  },
  {
    featureId: "EF016992",
    featureDesc: "Energy efficiency class (812/2013/EU)",
    label: "EF016992: Energy efficiency class (812/2013/EU)"
  },
  {
    featureId: "EF016994",
    featureDesc: "Energy efficiency class room heating (811/2013/EU)",
    label: "EF016994: Energy efficiency class room heating (811/2013/EU)"
  },
  {
    featureId: "EF016995",
    featureDesc: "Energy efficiency class water heating (811/2013/EU)",
    label: "EF016995: Energy efficiency class water heating (811/2013/EU)"
  },
  {
    featureId: "EF016996",
    featureDesc: "Strength class according to EN 12467",
    label: "EF016996: Strength class according to EN 12467"
  },
  {
    featureId: "EF016997",
    featureDesc: "Number of sheath layers",
    label: "EF016997: Number of sheath layers"
  },
  {
    featureId: "EF016998",
    featureDesc: "With installation tube entry",
    label: "EF016998: With installation tube entry"
  },
  {
    featureId: "EF016999",
    featureDesc: "Suitable for mounting over existing flush-mounted box",
    label: "EF016999: Suitable for mounting over existing flush-mounted box"
  },
  {
    featureId: "EF017002",
    featureDesc: "Number of non-adjustable device fixing lugs",
    label: "EF017002: Number of non-adjustable device fixing lugs"
  },
  {
    featureId: "EF017003",
    featureDesc: "Number of adjustable device fixing lugs",
    label: "EF017003: Number of adjustable device fixing lugs"
  },
  {
    featureId: "EF017004",
    featureDesc: "Colour of coding stripe on mantle pipe",
    label: "EF017004: Colour of coding stripe on mantle pipe"
  },
  {
    featureId: "EF017006",
    featureDesc: "Pressure independent",
    label: "EF017006: Pressure independent"
  },
  {
    featureId: "EF017007",
    featureDesc: "With pressure switch",
    label: "EF017007: With pressure switch"
  },
  {
    featureId: "EF017009",
    featureDesc: "Energy efficiency class (2019/2013/EU)",
    label: "EF017009: Energy efficiency class (2019/2013/EU)"
  },
  {
    featureId: "EF017010",
    featureDesc: "Energy efficiency class refrigerator (2019/2016/EU)",
    label: "EF017010: Energy efficiency class refrigerator (2019/2016/EU)"
  },
  {
    featureId: "EF017011",
    featureDesc: "Energy efficiency class dishwasher (2019/2017/EU)",
    label: "EF017011: Energy efficiency class dishwasher (2019/2017/EU)"
  },
  {
    featureId: "EF017012",
    featureDesc: "Energy efficiency class (392/2012/EU)",
    label: "EF017012: Energy efficiency class (392/2012/EU)"
  },
  {
    featureId: "EF017013",
    featureDesc: "Energy efficiency class complete wash/dry cycle (2019/2014/EU)",
    label: "EF017013: Energy efficiency class complete wash/dry cycle (2019/2014/EU)"
  },
  {
    featureId: "EF017014",
    featureDesc: "Energy efficiency class only washing (2019/2014/EU)",
    label: "EF017014: Energy efficiency class only washing (2019/2014/EU)"
  },
  {
    featureId: "EF017015",
    featureDesc: "With grip chuck",
    label: "EF017015: With grip chuck"
  },
  {
    featureId: "EF017016",
    featureDesc: "Constant power",
    label: "EF017016: Constant power"
  },
  {
    featureId: "EF017017",
    featureDesc: "Self-limiting",
    label: "EF017017: Self-limiting"
  },
  {
    featureId: "EF017018",
    featureDesc: "Analogue input, AC current",
    label: "EF017018: Analogue input, AC current"
  },
  {
    featureId: "EF017019",
    featureDesc: "Analogue input, DC current",
    label: "EF017019: Analogue input, DC current"
  },
  {
    featureId: "EF017020",
    featureDesc: "Analogue input, AC voltage",
    label: "EF017020: Analogue input, AC voltage"
  },
  {
    featureId: "EF017021",
    featureDesc: "Analogue input, DC voltage",
    label: "EF017021: Analogue input, DC voltage"
  },
  {
    featureId: "EF017022",
    featureDesc: "Analogue input, thermistor",
    label: "EF017022: Analogue input, thermistor"
  },
  {
    featureId: "EF017023",
    featureDesc: "Analogue input, resistive temperature sensor",
    label: "EF017023: Analogue input, resistive temperature sensor"
  },
  {
    featureId: "EF017024",
    featureDesc: "Analogue input, bridge measurement system",
    label: "EF017024: Analogue input, bridge measurement system"
  },
  {
    featureId: "EF017025",
    featureDesc: "Logical input",
    label: "EF017025: Logical input"
  },
  {
    featureId: "EF017026",
    featureDesc: "Input with galvanic isolation",
    label: "EF017026: Input with galvanic isolation"
  },
  {
    featureId: "EF017027",
    featureDesc: "Inputs response time according to IEC 60688",
    label: "EF017027: Inputs response time according to IEC 60688"
  },
  {
    featureId: "EF017028",
    featureDesc: "Analogue output response time",
    label: "EF017028: Analogue output response time"
  },
  {
    featureId: "EF017029",
    featureDesc: "Active/reactive power measurement",
    label: "EF017029: Active/reactive power measurement"
  },
  {
    featureId: "EF017030",
    featureDesc: "Phase shift measurement",
    label: "EF017030: Phase shift measurement"
  },
  {
    featureId: "EF017031",
    featureDesc: "HART protocol transparent interface",
    label: "EF017031: HART protocol transparent interface"
  },
  {
    featureId: "EF017033",
    featureDesc: "With lightning shock protection",
    label: "EF017033: With lightning shock protection"
  },
  {
    featureId: "EF017034",
    featureDesc: "With reinforced seismic resistance",
    label: "EF017034: With reinforced seismic resistance"
  },
  {
    featureId: "EF017037",
    featureDesc: "With shaft-/chimney cover",
    label: "EF017037: With shaft-/chimney cover"
  },
  {
    featureId: "EF017039",
    featureDesc: "With filling spout",
    label: "EF017039: With filling spout"
  },
  {
    featureId: "EF017041",
    featureDesc: "Type of grip",
    label: "EF017041: Type of grip"
  },
  {
    featureId: "EF017042",
    featureDesc: "With joystick",
    label: "EF017042: With joystick"
  },
  {
    featureId: "EF017046",
    featureDesc: "Crystal oscillator type",
    label: "EF017046: Crystal oscillator type"
  },
  {
    featureId: "EF017047",
    featureDesc: "Module database",
    label: "EF017047: Module database"
  },
  {
    featureId: "EF017048",
    featureDesc: "Method of current voltage curve trace",
    label: "EF017048: Method of current voltage curve trace"
  },
  {
    featureId: "EF017049",
    featureDesc: "Number of ports USB-C",
    label: "EF017049: Number of ports USB-C"
  },
  {
    featureId: "EF017050",
    featureDesc: "Insulator neck diameter",
    label: "EF017050: Insulator neck diameter"
  },
  {
    featureId: "EF017051",
    featureDesc: "Suitable for conductor cross section",
    label: "EF017051: Suitable for conductor cross section"
  },
  {
    featureId: "EF017052",
    featureDesc: "Suitable for insulated conductors",
    label: "EF017052: Suitable for insulated conductors"
  },
  {
    featureId: "EF017053",
    featureDesc: "Direction of conductor",
    label: "EF017053: Direction of conductor"
  },
  {
    featureId: "EF017054",
    featureDesc: "Heating capacity at -7/35 °C according to EN 14511",
    label: "EF017054: Heating capacity at -7/35 °C according to EN 14511"
  },
  {
    featureId: "EF017055",
    featureDesc: "Suitable for standby switching",
    label: "EF017055: Suitable for standby switching"
  },
  {
    featureId: "EF017056",
    featureDesc: "Suitable for continuous circuit",
    label: "EF017056: Suitable for continuous circuit"
  },
  {
    featureId: "EF017058",
    featureDesc: "Colour backsheet",
    label: "EF017058: Colour backsheet"
  },
  {
    featureId: "EF017059",
    featureDesc: "Colour cells",
    label: "EF017059: Colour cells"
  },
  {
    featureId: "EF017067",
    featureDesc: "Dead zone-free",
    label: "EF017067: Dead zone-free"
  },
  {
    featureId: "EF017068",
    featureDesc: "With surge protection module",
    label: "EF017068: With surge protection module"
  },
  {
    featureId: "EF017069",
    featureDesc: "With suction cups",
    label: "EF017069: With suction cups"
  },
  {
    featureId: "EF017070",
    featureDesc: "Paired co-axial cable",
    label: "EF017070: Paired co-axial cable"
  },
  {
    featureId: "EF017071",
    featureDesc: "2 core co-axial cable (twinax)",
    label: "EF017071: 2 core co-axial cable (twinax)"
  },
  {
    featureId: "EF017072",
    featureDesc: "Manual operation possible",
    label: "EF017072: Manual operation possible"
  },
  {
    featureId: "EF017075",
    featureDesc: "With delayed filling process",
    label: "EF017075: With delayed filling process"
  },
  {
    featureId: "EF017078",
    featureDesc: "Suitable for concealed pressure flush valve",
    label: "EF017078: Suitable for concealed pressure flush valve"
  },
  {
    featureId: "EF017080",
    featureDesc: "With plaster base mat",
    label: "EF017080: With plaster base mat"
  },
  {
    featureId: "EF017081",
    featureDesc: "Thermal disinfection",
    label: "EF017081: Thermal disinfection"
  },
  {
    featureId: "EF017082",
    featureDesc: "With anti-vacuum device",
    label: "EF017082: With anti-vacuum device"
  },
  {
    featureId: "EF017083",
    featureDesc: "Sound absorption class according to ISO 11654",
    label: "EF017083: Sound absorption class according to ISO 11654"
  },
  {
    featureId: "EF017085",
    featureDesc: "Type of camera",
    label: "EF017085: Type of camera"
  },
  {
    featureId: "EF017088",
    featureDesc: "With rip cord",
    label: "EF017088: With rip cord"
  },
  {
    featureId: "EF017089",
    featureDesc: "Suitable for use as security alarm cable",
    label: "EF017089: Suitable for use as security alarm cable"
  },
  {
    featureId: "EF017094",
    featureDesc: "With soldering nipple",
    label: "EF017094: With soldering nipple"
  },
  {
    featureId: "EF017097",
    featureDesc: "With pre-filter",
    label: "EF017097: With pre-filter"
  },
  {
    featureId: "EF017100",
    featureDesc: "Testing according to IEC/EN 60204",
    label: "EF017100: Testing according to IEC/EN 60204"
  },
  {
    featureId: "EF017101",
    featureDesc: "Testing according to IEC/EN 61439",
    label: "EF017101: Testing according to IEC/EN 61439"
  },
  {
    featureId: "EF017102",
    featureDesc: "Testing according to IEC/EN 60335",
    label: "EF017102: Testing according to IEC/EN 60335"
  },
  {
    featureId: "EF017103",
    featureDesc: "Testing according to IEC/EN 60950",
    label: "EF017103: Testing according to IEC/EN 60950"
  },
  {
    featureId: "EF017104",
    featureDesc: "Testing according to IEC/EN 60598",
    label: "EF017104: Testing according to IEC/EN 60598"
  },
  {
    featureId: "EF017105",
    featureDesc: "Testing according to IEC/EN 60065",
    label: "EF017105: Testing according to IEC/EN 60065"
  },
  {
    featureId: "EF017109",
    featureDesc: "Testing according to IEC/EN 61558",
    label: "EF017109: Testing according to IEC/EN 61558"
  },
  {
    featureId: "EF017110",
    featureDesc: "High voltage test for motor windings",
    label: "EF017110: High voltage test for motor windings"
  },
  {
    featureId: "EF017111",
    featureDesc: "Max. current measuring range with clip factor",
    label: "EF017111: Max. current measuring range with clip factor"
  },
  {
    featureId: "EF017114",
    featureDesc: "Shielding efficiency class",
    label: "EF017114: Shielding efficiency class"
  },
  {
    featureId: "EF017115",
    featureDesc: "Number of active contacts (square)",
    label: "EF017115: Number of active contacts (square)"
  },
  {
    featureId: "EF017116",
    featureDesc: "Protective contact",
    label: "EF017116: Protective contact"
  },
  {
    featureId: "EF017121",
    featureDesc: "With wrist rest",
    label: "EF017121: With wrist rest"
  },
  {
    featureId: "EF017122",
    featureDesc: "Suitable for placement on desktop",
    label: "EF017122: Suitable for placement on desktop"
  },
  {
    featureId: "EF017124",
    featureDesc: "With ground spike",
    label: "EF017124: With ground spike"
  },
  {
    featureId: "EF017125",
    featureDesc: "Utilization category according to EN IEC 60947-4-1",
    label: "EF017125: Utilization category according to EN IEC 60947-4-1"
  },
  {
    featureId: "EF017126",
    featureDesc: "With mounting material for roof type",
    label: "EF017126: With mounting material for roof type"
  },
  {
    featureId: "EF017127",
    featureDesc: "Chinese",
    label: "EF017127: Chinese"
  },
  {
    featureId: "EF017128",
    featureDesc: "Dutch",
    label: "EF017128: Dutch"
  },
  {
    featureId: "EF017129",
    featureDesc: "English",
    label: "EF017129: English"
  },
  {
    featureId: "EF017130",
    featureDesc: "French",
    label: "EF017130: French"
  },
  {
    featureId: "EF017131",
    featureDesc: "German",
    label: "EF017131: German"
  },
  {
    featureId: "EF017132",
    featureDesc: "Italian",
    label: "EF017132: Italian"
  },
  {
    featureId: "EF017133",
    featureDesc: "Russian",
    label: "EF017133: Russian"
  },
  {
    featureId: "EF017134",
    featureDesc: "Spanish",
    label: "EF017134: Spanish"
  },
  {
    featureId: "EF017135",
    featureDesc: "Fill factor measurement (FF)",
    label: "EF017135: Fill factor measurement (FF)"
  },
  {
    featureId: "EF017136",
    featureDesc: "Inverter efficiency measurement",
    label: "EF017136: Inverter efficiency measurement"
  },
  {
    featureId: "EF017137",
    featureDesc: "Performance ratio (PV)",
    label: "EF017137: Performance ratio (PV)"
  },
  {
    featureId: "EF017138",
    featureDesc: "Power measurement",
    label: "EF017138: Power measurement"
  },
  {
    featureId: "EF017139",
    featureDesc: "Energy efficiency class according to EU regulation 2019/2015",
    label: "EF017139: Energy efficiency class according to EU regulation 2019/2015"
  },
  {
    featureId: "EF017142",
    featureDesc: "Suitable for moisture-sensitive surfaces",
    label: "EF017142: Suitable for moisture-sensitive surfaces"
  },
  {
    featureId: "EF017143",
    featureDesc: "Application with broom",
    label: "EF017143: Application with broom"
  },
  {
    featureId: "EF017144",
    featureDesc: "Suitable for painting",
    label: "EF017144: Suitable for painting"
  },
  {
    featureId: "EF017145",
    featureDesc: "Suitable for tiling",
    label: "EF017145: Suitable for tiling"
  },
  {
    featureId: "EF017148",
    featureDesc: "Solution type",
    label: "EF017148: Solution type"
  },
  {
    featureId: "EF017149",
    featureDesc: "Concentration pH",
    label: "EF017149: Concentration pH"
  },
  {
    featureId: "EF017151",
    featureDesc: "Redox concentration",
    label: "EF017151: Redox concentration"
  },
  {
    featureId: "EF017152",
    featureDesc: "Diffusion-open",
    label: "EF017152: Diffusion-open"
  },
  {
    featureId: "EF017153",
    featureDesc: "With white light",
    label: "EF017153: With white light"
  },
  {
    featureId: "EF017154",
    featureDesc: "Range",
    label: "EF017154: Range"
  },
  {
    featureId: "EF017155",
    featureDesc: "KCl concentration",
    label: "EF017155: KCl concentration"
  },
  {
    featureId: "EF017156",
    featureDesc: "Concentration of standard conductivity solution",
    label: "EF017156: Concentration of standard conductivity solution"
  },
  {
    featureId: "EF017159",
    featureDesc: "Percentage biobased material according to EN 16785",
    label: "EF017159: Percentage biobased material according to EN 16785"
  },
  {
    featureId: "EF017160",
    featureDesc: "Class type according to EN 622",
    label: "EF017160: Class type according to EN 622"
  },
  {
    featureId: "EF017161",
    featureDesc: "Ball/bolt diameter",
    label: "EF017161: Ball/bolt diameter"
  },
  {
    featureId: "EF017162",
    featureDesc: "Max. trailer weight",
    label: "EF017162: Max. trailer weight"
  },
  {
    featureId: "EF017163",
    featureDesc: "Mounting Interface Standard (MIS) according to VESA",
    label: "EF017163: Mounting Interface Standard (MIS) according to VESA"
  },
  {
    featureId: "EF017164",
    featureDesc: "Suitable for leveling and repair mortar",
    label: "EF017164: Suitable for leveling and repair mortar"
  },
  {
    featureId: "EF017165",
    featureDesc: "Supporting protocol for MQTT",
    label: "EF017165: Supporting protocol for MQTT"
  },
  {
    featureId: "EF017166",
    featureDesc: "Stain and dirt isolating",
    label: "EF017166: Stain and dirt isolating"
  },
  {
    featureId: "EF017167",
    featureDesc: "Power transformer (PT) or power distribution test",
    label: "EF017167: Power transformer (PT) or power distribution test"
  },
  {
    featureId: "EF017168",
    featureDesc: "Voltage transformer (VT) test",
    label: "EF017168: Voltage transformer (VT) test"
  },
  {
    featureId: "EF017169",
    featureDesc: "Current transformer (CT) test",
    label: "EF017169: Current transformer (CT) test"
  },
  {
    featureId: "EF017170",
    featureDesc: "Ratio of the number of turns, measuring range",
    label: "EF017170: Ratio of the number of turns, measuring range"
  },
  {
    featureId: "EF017177",
    featureDesc: "Vector group measurement",
    label: "EF017177: Vector group measurement"
  },
  {
    featureId: "EF017178",
    featureDesc: "OLTC (On Line Tap Changer) control",
    label: "EF017178: OLTC (On Line Tap Changer) control"
  },
  {
    featureId: "EF017179",
    featureDesc: "Winding resistance measurement",
    label: "EF017179: Winding resistance measurement"
  },
  {
    featureId: "EF017180",
    featureDesc: "Timestamp of measurements",
    label: "EF017180: Timestamp of measurements"
  },
  {
    featureId: "EF017184",
    featureDesc: "Channel 1 adjustment",
    label: "EF017184: Channel 1 adjustment"
  },
  {
    featureId: "EF017185",
    featureDesc: "Channel 2 adjustment",
    label: "EF017185: Channel 2 adjustment"
  },
  {
    featureId: "EF017186",
    featureDesc: "Channel 3 adjustment",
    label: "EF017186: Channel 3 adjustment"
  },
  {
    featureId: "EF017187",
    featureDesc: "Channel 1 display",
    label: "EF017187: Channel 1 display"
  },
  {
    featureId: "EF017188",
    featureDesc: "Channel 2 display",
    label: "EF017188: Channel 2 display"
  },
  {
    featureId: "EF017189",
    featureDesc: "Channel 3 display",
    label: "EF017189: Channel 3 display"
  },
  {
    featureId: "EF017190",
    featureDesc: "Signal attenuation",
    label: "EF017190: Signal attenuation"
  },
  {
    featureId: "EF017191",
    featureDesc: "Parallel mode coupling",
    label: "EF017191: Parallel mode coupling"
  },
  {
    featureId: "EF017192",
    featureDesc: "Serial mode coupling",
    label: "EF017192: Serial mode coupling"
  },
  {
    featureId: "EF017194",
    featureDesc: "Vented cover",
    label: "EF017194: Vented cover"
  },
  {
    featureId: "EF017195",
    featureDesc: "Cover weight",
    label: "EF017195: Cover weight"
  },
  {
    featureId: "EF017196",
    featureDesc: "With dirt trap bucket holder",
    label: "EF017196: With dirt trap bucket holder"
  },
  {
    featureId: "EF017198",
    featureDesc: "Terrace tile",
    label: "EF017198: Terrace tile"
  },
  {
    featureId: "EF017199",
    featureDesc: "Grid profile",
    label: "EF017199: Grid profile"
  },
  {
    featureId: "EF017202",
    featureDesc: "Hue variation",
    label: "EF017202: Hue variation"
  },
  {
    featureId: "EF017203",
    featureDesc: "Mohs scale",
    label: "EF017203: Mohs scale"
  },
  {
    featureId: "EF017204",
    featureDesc: "With reel",
    label: "EF017204: With reel"
  },
  {
    featureId: "EF017205",
    featureDesc: "Air intake temperature",
    label: "EF017205: Air intake temperature"
  },
  {
    featureId: "EF017206",
    featureDesc: "Load bearing capacity",
    label: "EF017206: Load bearing capacity"
  },
  {
    featureId: "EF017207",
    featureDesc: "Handbook",
    label: "EF017207: Handbook"
  },
  {
    featureId: "EF017208",
    featureDesc: "Suitable for shower mixing tap",
    label: "EF017208: Suitable for shower mixing tap"
  },
  {
    featureId: "EF017210",
    featureDesc: "Radon tight",
    label: "EF017210: Radon tight"
  },
  {
    featureId: "EF017214",
    featureDesc: "Panel thickness",
    label: "EF017214: Panel thickness"
  },
  {
    featureId: "EF017215",
    featureDesc: "Panel colour",
    label: "EF017215: Panel colour"
  },
  {
    featureId: "EF017216",
    featureDesc: "Panel finishing",
    label: "EF017216: Panel finishing"
  },
  {
    featureId: "EF017217",
    featureDesc: "Easy cleaning treatment",
    label: "EF017217: Easy cleaning treatment"
  },
  {
    featureId: "EF017219",
    featureDesc: "Number of free PCIe-slots",
    label: "EF017219: Number of free PCIe-slots"
  },
  {
    featureId: "EF017220",
    featureDesc: "Cable/chain length",
    label: "EF017220: Cable/chain length"
  },
  {
    featureId: "EF017221",
    featureDesc: "Attenuation",
    label: "EF017221: Attenuation"
  },
  {
    featureId: "EF017222",
    featureDesc: "Suitable for beam width",
    label: "EF017222: Suitable for beam width"
  },
  {
    featureId: "EF017223",
    featureDesc: "Tracking technology",
    label: "EF017223: Tracking technology"
  },
  {
    featureId: "EF017224",
    featureDesc: "Ergonomical",
    label: "EF017224: Ergonomical"
  },
  {
    featureId: "EF017225",
    featureDesc: "Resolution",
    label: "EF017225: Resolution"
  },
  {
    featureId: "EF017226",
    featureDesc: "Suitable for gaming",
    label: "EF017226: Suitable for gaming"
  },
  {
    featureId: "EF017227",
    featureDesc: "Bluetooth/2.4 GHz switch",
    label: "EF017227: Bluetooth/2.4 GHz switch"
  },
  {
    featureId: "EF017228",
    featureDesc: "Max. lifting capacity",
    label: "EF017228: Max. lifting capacity"
  },
  {
    featureId: "EF017229",
    featureDesc: "Suitable for machine operation",
    label: "EF017229: Suitable for machine operation"
  },
  {
    featureId: "EF017230",
    featureDesc: "End piece",
    label: "EF017230: End piece"
  },
  {
    featureId: "EF017231",
    featureDesc: "Suitable for intrusion alarm",
    label: "EF017231: Suitable for intrusion alarm"
  },
  {
    featureId: "EF017232",
    featureDesc: "Suitable for fire alarm",
    label: "EF017232: Suitable for fire alarm"
  },
  {
    featureId: "EF017233",
    featureDesc: "Sides bevelled edge (facet)",
    label: "EF017233: Sides bevelled edge (facet)"
  },
  {
    featureId: "EF017234",
    featureDesc: "Light reflectance",
    label: "EF017234: Light reflectance"
  },
  {
    featureId: "EF017235",
    featureDesc: "With lamp protection basket",
    label: "EF017235: With lamp protection basket"
  },
  {
    featureId: "EF017236",
    featureDesc: "Colour LED indication",
    label: "EF017236: Colour LED indication"
  },
  {
    featureId: "EF017237",
    featureDesc: "Ball impact safety class according to EN 13964",
    label: "EF017237: Ball impact safety class according to EN 13964"
  },
  {
    featureId: "EF017238",
    featureDesc: "Suitable for roof type",
    label: "EF017238: Suitable for roof type"
  },
  {
    featureId: "EF017241",
    featureDesc: "Diameter supply air connection",
    label: "EF017241: Diameter supply air connection"
  },
  {
    featureId: "EF017242",
    featureDesc: "Plastic coated",
    label: "EF017242: Plastic coated"
  },
  {
    featureId: "EF017243",
    featureDesc: "With neutral conductor contact",
    label: "EF017243: With neutral conductor contact"
  },
  {
    featureId: "EF017247",
    featureDesc: "Diameter longitudinal wire",
    label: "EF017247: Diameter longitudinal wire"
  },
  {
    featureId: "EF017249",
    featureDesc: "Vertical cable ladder",
    label: "EF017249: Vertical cable ladder"
  },
  {
    featureId: "EF017250",
    featureDesc: "PED (Pressure Equipment Directive) category according to 2014/68/EU",
    label: "EF017250: PED (Pressure Equipment Directive) category according to 2014/68/EU"
  },
  {
    featureId: "EF017251",
    featureDesc: "Sound performance level according to EN 12102 at 10/35 °C",
    label: "EF017251: Sound performance level according to EN 12102 at 10/35 °C"
  },
  {
    featureId: "EF017252",
    featureDesc: "Control element optional",
    label: "EF017252: Control element optional"
  },
  {
    featureId: "EF017253",
    featureDesc: "PC connection optional",
    label: "EF017253: PC connection optional"
  },
  {
    featureId: "EF017257",
    featureDesc: "With shower hose",
    label: "EF017257: With shower hose"
  },
  {
    featureId: "EF017261",
    featureDesc: "Possible support spacing",
    label: "EF017261: Possible support spacing"
  },
  {
    featureId: "EF017262",
    featureDesc: "Safe working load according to IEC 61537 with support spacing 0.5 m",
    label: "EF017262: Safe working load according to IEC 61537 with support spacing 0.5 m"
  },
  {
    featureId: "EF017263",
    featureDesc: "Safe working load according to IEC 61537 with support spacing 1.0 m",
    label: "EF017263: Safe working load according to IEC 61537 with support spacing 1.0 m"
  },
  {
    featureId: "EF017264",
    featureDesc: "Safe working load according to IEC 61537 with support spacing 1.5 m",
    label: "EF017264: Safe working load according to IEC 61537 with support spacing 1.5 m"
  },
  {
    featureId: "EF017265",
    featureDesc: "Safe working load according to IEC 61537 with support spacing 2.0 m",
    label: "EF017265: Safe working load according to IEC 61537 with support spacing 2.0 m"
  },
  {
    featureId: "EF017266",
    featureDesc: "Safe working load according to IEC 61537 with support spacing 2.5 m",
    label: "EF017266: Safe working load according to IEC 61537 with support spacing 2.5 m"
  },
  {
    featureId: "EF017267",
    featureDesc: "Safe working load according to IEC 61537 with support spacing 3.0 m",
    label: "EF017267: Safe working load according to IEC 61537 with support spacing 3.0 m"
  },
  {
    featureId: "EF017268",
    featureDesc: "Safe working load according to IEC 61537 with support spacing 4.0 m",
    label: "EF017268: Safe working load according to IEC 61537 with support spacing 4.0 m"
  },
  {
    featureId: "EF017269",
    featureDesc: "Safe working load according to IEC 61537 with support spacing 5.0 m",
    label: "EF017269: Safe working load according to IEC 61537 with support spacing 5.0 m"
  },
  {
    featureId: "EF017270",
    featureDesc: "Safe working load according to IEC 61537 with support spacing 6.0 m",
    label: "EF017270: Safe working load according to IEC 61537 with support spacing 6.0 m"
  },
  {
    featureId: "EF017271",
    featureDesc: "Safe working load according to IEC 61537 with support spacing 7.0 m",
    label: "EF017271: Safe working load according to IEC 61537 with support spacing 7.0 m"
  },
  {
    featureId: "EF017272",
    featureDesc: "Safe working load according to IEC 61537 with support spacing 8.0 m",
    label: "EF017272: Safe working load according to IEC 61537 with support spacing 8.0 m"
  },
  {
    featureId: "EF017273",
    featureDesc: "Safe working load according to IEC 61537 with support spacing 10.0 m",
    label: "EF017273: Safe working load according to IEC 61537 with support spacing 10.0 m"
  },
  {
    featureId: "EF017274",
    featureDesc: "Drying time before further processing",
    label: "EF017274: Drying time before further processing"
  },
  {
    featureId: "EF017275",
    featureDesc: "Crack bridging ability according to EN 14891",
    label: "EF017275: Crack bridging ability according to EN 14891"
  },
  {
    featureId: "EF017276",
    featureDesc: "Applicable with reinforcement fabric",
    label: "EF017276: Applicable with reinforcement fabric"
  },
  {
    featureId: "EF017277",
    featureDesc: "Suitable for swimming pools",
    label: "EF017277: Suitable for swimming pools"
  },
  {
    featureId: "EF017278",
    featureDesc: "Suitable for bathroom",
    label: "EF017278: Suitable for bathroom"
  },
  {
    featureId: "EF017279",
    featureDesc: "With decor pattern",
    label: "EF017279: With decor pattern"
  },
  {
    featureId: "EF017280",
    featureDesc: "Suitable according to IEC/EN 61439-4",
    label: "EF017280: Suitable according to IEC/EN 61439-4"
  },
  {
    featureId: "EF017282",
    featureDesc: "With radiator",
    label: "EF017282: With radiator"
  },
  {
    featureId: "EF017283",
    featureDesc: "With carrier bag",
    label: "EF017283: With carrier bag"
  },
  {
    featureId: "EF017284",
    featureDesc: "Suitable for new-build",
    label: "EF017284: Suitable for new-build"
  },
  {
    featureId: "EF017285",
    featureDesc: "Thickness roof element (without battens)",
    label: "EF017285: Thickness roof element (without battens)"
  },
  {
    featureId: "EF017286",
    featureDesc: "Load subconstruction",
    label: "EF017286: Load subconstruction"
  },
  {
    featureId: "EF017287",
    featureDesc: "Suitable for roof tiles/slates",
    label: "EF017287: Suitable for roof tiles/slates"
  },
  {
    featureId: "EF017288",
    featureDesc: "Suitable for bituminous waterproofing",
    label: "EF017288: Suitable for bituminous waterproofing"
  },
  {
    featureId: "EF017289",
    featureDesc: "Suitable for reed (bonded)",
    label: "EF017289: Suitable for reed (bonded)"
  },
  {
    featureId: "EF017290",
    featureDesc: "Suitable for reed (screwed)",
    label: "EF017290: Suitable for reed (screwed)"
  },
  {
    featureId: "EF017291",
    featureDesc: "Suitable for metal roof ventilated",
    label: "EF017291: Suitable for metal roof ventilated"
  },
  {
    featureId: "EF017292",
    featureDesc: "Suitable for metal roof unventilated",
    label: "EF017292: Suitable for metal roof unventilated"
  },
  {
    featureId: "EF017293",
    featureDesc: "Suitable for roof shingles",
    label: "EF017293: Suitable for roof shingles"
  },
  {
    featureId: "EF017294",
    featureDesc: "Suitable for vegation roof",
    label: "EF017294: Suitable for vegation roof"
  },
  {
    featureId: "EF017296",
    featureDesc: "Suitable for solid ceiling",
    label: "EF017296: Suitable for solid ceiling"
  },
  {
    featureId: "EF017297",
    featureDesc: "Min. clearances required within the fire partitioning",
    label: "EF017297: Min. clearances required within the fire partitioning"
  },
  {
    featureId: "EF017298",
    featureDesc: "Suitable for empty plastic pipes",
    label: "EF017298: Suitable for empty plastic pipes"
  },
  {
    featureId: "EF017299",
    featureDesc: "Fire partitioning assignable to 100 %",
    label: "EF017299: Fire partitioning assignable to 100 %"
  },
  {
    featureId: "EF017300",
    featureDesc: "Suitable for flush floor installation",
    label: "EF017300: Suitable for flush floor installation"
  },
  {
    featureId: "EF017301",
    featureDesc: "Suitable for flush ceiling installation",
    label: "EF017301: Suitable for flush ceiling installation"
  },
  {
    featureId: "EF017302",
    featureDesc: "Protection class according to IEC 61140",
    label: "EF017302: Protection class according to IEC 61140"
  },
  {
    featureId: "EF017307",
    featureDesc: "Rated operating voltage AC",
    label: "EF017307: Rated operating voltage AC"
  },
  {
    featureId: "EF017308",
    featureDesc: "Rated operating voltage DC",
    label: "EF017308: Rated operating voltage DC"
  },
  {
    featureId: "EF017309",
    featureDesc: "Tilting height",
    label: "EF017309: Tilting height"
  },
  {
    featureId: "EF017315",
    featureDesc: "Min. distance maintenance door",
    label: "EF017315: Min. distance maintenance door"
  },
  {
    featureId: "EF017316",
    featureDesc: "Sound insulation thickness",
    label: "EF017316: Sound insulation thickness"
  },
  {
    featureId: "EF017317",
    featureDesc: "Sound reduction",
    label: "EF017317: Sound reduction"
  },
  {
    featureId: "EF017318",
    featureDesc: "Air inlet and outlet opening",
    label: "EF017318: Air inlet and outlet opening"
  },
  {
    featureId: "EF017319",
    featureDesc: "Position air inlet",
    label: "EF017319: Position air inlet"
  },
  {
    featureId: "EF017320",
    featureDesc: "Position air outlet",
    label: "EF017320: Position air outlet"
  },
  {
    featureId: "EF017321",
    featureDesc: "With refrigerant loss sensor",
    label: "EF017321: With refrigerant loss sensor"
  },
  {
    featureId: "EF017324",
    featureDesc: "With inspection opening",
    label: "EF017324: With inspection opening"
  },
  {
    featureId: "EF017325",
    featureDesc: "With device connection opening",
    label: "EF017325: With device connection opening"
  },
  {
    featureId: "EF017326",
    featureDesc: "Inside dimension 1",
    label: "EF017326: Inside dimension 1"
  },
  {
    featureId: "EF017327",
    featureDesc: "Suitable for roof walkway",
    label: "EF017327: Suitable for roof walkway"
  },
  {
    featureId: "EF017328",
    featureDesc: "Inside dimension 2",
    label: "EF017328: Inside dimension 2"
  },
  {
    featureId: "EF017330",
    featureDesc: "Centre distance fastening provision for cover plate",
    label: "EF017330: Centre distance fastening provision for cover plate"
  },
  {
    featureId: "EF017331",
    featureDesc: "Suitable for fixed chimney ladder",
    label: "EF017331: Suitable for fixed chimney ladder"
  },
  {
    featureId: "EF017332",
    featureDesc: "Suitable for fixed roof ladder",
    label: "EF017332: Suitable for fixed roof ladder"
  },
  {
    featureId: "EF017333",
    featureDesc: "Suitable for fixed wall ladder",
    label: "EF017333: Suitable for fixed wall ladder"
  },
  {
    featureId: "EF017334",
    featureDesc: "Suitable for roof ridge rail",
    label: "EF017334: Suitable for roof ridge rail"
  },
  {
    featureId: "EF017335",
    featureDesc: "Suitable for roof eaves rail",
    label: "EF017335: Suitable for roof eaves rail"
  },
  {
    featureId: "EF017336",
    featureDesc: "Suitable for roof snow guard",
    label: "EF017336: Suitable for roof snow guard"
  },
  {
    featureId: "EF017340",
    featureDesc: "With S-couplings",
    label: "EF017340: With S-couplings"
  },
  {
    featureId: "EF017341",
    featureDesc: "S-coupling lockable",
    label: "EF017341: S-coupling lockable"
  },
  {
    featureId: "EF017343",
    featureDesc: "Connection hand shower",
    label: "EF017343: Connection hand shower"
  },
  {
    featureId: "EF017348",
    featureDesc: "Number of socket outlets British standard",
    label: "EF017348: Number of socket outlets British standard"
  },
  {
    featureId: "EF017349",
    featureDesc: "With extended threaded rod",
    label: "EF017349: With extended threaded rod"
  },
  {
    featureId: "EF017350",
    featureDesc: "Surface treatment blade",
    label: "EF017350: Surface treatment blade"
  },
  {
    featureId: "EF017355",
    featureDesc: "Nominal value output voltage 4",
    label: "EF017355: Nominal value output voltage 4"
  },
  {
    featureId: "EF017356",
    featureDesc: "Nominal value output voltage 5",
    label: "EF017356: Nominal value output voltage 5"
  },
  {
    featureId: "EF017357",
    featureDesc: "Number of strands per conductor",
    label: "EF017357: Number of strands per conductor"
  },
  {
    featureId: "EF017358",
    featureDesc: "Strand diameter",
    label: "EF017358: Strand diameter"
  },
  {
    featureId: "EF017360",
    featureDesc: "Battery voltage tester",
    label: "EF017360: Battery voltage tester"
  },
  {
    featureId: "EF017364",
    featureDesc: "Quantity of connection options",
    label: "EF017364: Quantity of connection options"
  },
  {
    featureId: "EF017366",
    featureDesc: "Welding thickness on steel (SMAW)",
    label: "EF017366: Welding thickness on steel (SMAW)"
  },
  {
    featureId: "EF017367",
    featureDesc: "Welding thickness on aluminium (SMAW)",
    label: "EF017367: Welding thickness on aluminium (SMAW)"
  },
  {
    featureId: "EF017368",
    featureDesc: "Welding thickness on aluminium (GTAW)",
    label: "EF017368: Welding thickness on aluminium (GTAW)"
  },
  {
    featureId: "EF017369",
    featureDesc: "Welding thickness on steel (GMAW)",
    label: "EF017369: Welding thickness on steel (GMAW)"
  },
  {
    featureId: "EF017370",
    featureDesc: "Welding thickness on aluminium (GMAW)",
    label: "EF017370: Welding thickness on aluminium (GMAW)"
  },
  {
    featureId: "EF017371",
    featureDesc: "Coated electrode diameter (SMAW)",
    label: "EF017371: Coated electrode diameter (SMAW)"
  },
  {
    featureId: "EF017372",
    featureDesc: "Adjustable upslope (GTAW)",
    label: "EF017372: Adjustable upslope (GTAW)"
  },
  {
    featureId: "EF017373",
    featureDesc: "Adjustable downslope (GTAW)",
    label: "EF017373: Adjustable downslope (GTAW)"
  },
  {
    featureId: "EF017374",
    featureDesc: "Trigger management mode (GMAW)",
    label: "EF017374: Trigger management mode (GMAW)"
  },
  {
    featureId: "EF017375",
    featureDesc: "Torch connection type (GMAW)",
    label: "EF017375: Torch connection type (GMAW)"
  },
  {
    featureId: "EF017376",
    featureDesc: "Welding thickness on steel (GTAW)",
    label: "EF017376: Welding thickness on steel (GTAW)"
  },
  {
    featureId: "EF017377",
    featureDesc: "TV frequency band",
    label: "EF017377: TV frequency band"
  },
  {
    featureId: "EF017378",
    featureDesc: "Radio frequency band",
    label: "EF017378: Radio frequency band"
  },
  {
    featureId: "EF017379",
    featureDesc: "SAT frequency band",
    label: "EF017379: SAT frequency band"
  },
  {
    featureId: "EF017380",
    featureDesc: "Diameter transverse wire",
    label: "EF017380: Diameter transverse wire"
  },
  {
    featureId: "EF017381",
    featureDesc: "With isolated clamps",
    label: "EF017381: With isolated clamps"
  },
  {
    featureId: "EF017382",
    featureDesc: "With fingerprint sensor",
    label: "EF017382: With fingerprint sensor"
  },
  {
    featureId: "EF017383",
    featureDesc: "Power connector type",
    label: "EF017383: Power connector type"
  },
  {
    featureId: "EF017384",
    featureDesc: "Disconnectable torch",
    label: "EF017384: Disconnectable torch"
  },
  {
    featureId: "EF017385",
    featureDesc: "Integrated compressor",
    label: "EF017385: Integrated compressor"
  },
  {
    featureId: "EF017386",
    featureDesc: "Gouging function",
    label: "EF017386: Gouging function"
  },
  {
    featureId: "EF017387",
    featureDesc: "Marking function",
    label: "EF017387: Marking function"
  },
  {
    featureId: "EF017388",
    featureDesc: "Air flow/pressure input",
    label: "EF017388: Air flow/pressure input"
  },
  {
    featureId: "EF017390",
    featureDesc: "Automatic air pressure management",
    label: "EF017390: Automatic air pressure management"
  },
  {
    featureId: "EF017392",
    featureDesc: "Torch length",
    label: "EF017392: Torch length"
  },
  {
    featureId: "EF017395",
    featureDesc: "Type of front wheels",
    label: "EF017395: Type of front wheels"
  },
  {
    featureId: "EF017396",
    featureDesc: "Type of rear wheels",
    label: "EF017396: Type of rear wheels"
  },
  {
    featureId: "EF017400",
    featureDesc: "Pendant coupling",
    label: "EF017400: Pendant coupling"
  },
  {
    featureId: "EF017401",
    featureDesc: "Class type according to EN 312",
    label: "EF017401: Class type according to EN 312"
  },
  {
    featureId: "EF017402",
    featureDesc: "Suitable for solar collector",
    label: "EF017402: Suitable for solar collector"
  },
  {
    featureId: "EF017403",
    featureDesc: "Max. indicative span",
    label: "EF017403: Max. indicative span"
  },
  {
    featureId: "EF017404",
    featureDesc: "Sound absorption alpha w",
    label: "EF017404: Sound absorption alpha w"
  },
  {
    featureId: "EF017405",
    featureDesc: "Sound reduction (Ra, tr)",
    label: "EF017405: Sound reduction (Ra, tr)"
  },
  {
    featureId: "EF017406",
    featureDesc: "Cooling performance at 15/18 °C (B15/W18)",
    label: "EF017406: Cooling performance at 15/18 °C (B15/W18)"
  },
  {
    featureId: "EF017407",
    featureDesc: "Cooling performance at 10/18 °C (W10/W18)",
    label: "EF017407: Cooling performance at 10/18 °C (W10/W18)"
  },
  {
    featureId: "EF017408",
    featureDesc: "Programming according to IEC 61131",
    label: "EF017408: Programming according to IEC 61131"
  },
  {
    featureId: "EF017413",
    featureDesc: "Suitable for flushing reservoir",
    label: "EF017413: Suitable for flushing reservoir"
  },
  {
    featureId: "EF017414",
    featureDesc: "With control plate",
    label: "EF017414: With control plate"
  },
  {
    featureId: "EF017415",
    featureDesc: "Surface protection handrail",
    label: "EF017415: Surface protection handrail"
  },
  {
    featureId: "EF017417",
    featureDesc: "Torch connector type",
    label: "EF017417: Torch connector type"
  },
  {
    featureId: "EF017418",
    featureDesc: "With alternator tester",
    label: "EF017418: With alternator tester"
  },
  {
    featureId: "EF017419",
    featureDesc: "With starter tester",
    label: "EF017419: With starter tester"
  },
  {
    featureId: "EF017420",
    featureDesc: "Compatible with start/stop engine",
    label: "EF017420: Compatible with start/stop engine"
  },
  {
    featureId: "EF017421",
    featureDesc: "Min. testable voltage",
    label: "EF017421: Min. testable voltage"
  },
  {
    featureId: "EF017422",
    featureDesc: "With state of health battery tester (SOH)",
    label: "EF017422: With state of health battery tester (SOH)"
  },
  {
    featureId: "EF017423",
    featureDesc: "With state of charge battery tester (SOC)",
    label: "EF017423: With state of charge battery tester (SOC)"
  },
  {
    featureId: "EF017424",
    featureDesc: "Alternator voltage measurement range",
    label: "EF017424: Alternator voltage measurement range"
  },
  {
    featureId: "EF017425",
    featureDesc: "Battery voltage measurement range",
    label: "EF017425: Battery voltage measurement range"
  },
  {
    featureId: "EF017431",
    featureDesc: "Suitable for float glass",
    label: "EF017431: Suitable for float glass"
  },
  {
    featureId: "EF017432",
    featureDesc: "Suitable for tempered glass",
    label: "EF017432: Suitable for tempered glass"
  },
  {
    featureId: "EF017433",
    featureDesc: "Suitable for reinforced glass",
    label: "EF017433: Suitable for reinforced glass"
  },
  {
    featureId: "EF017434",
    featureDesc: "With built-in card reader",
    label: "EF017434: With built-in card reader"
  },
  {
    featureId: "EF017435",
    featureDesc: "Type of card reader",
    label: "EF017435: Type of card reader"
  },
  {
    featureId: "EF017437",
    featureDesc: "Max. short circuit current per MPPT",
    label: "EF017437: Max. short circuit current per MPPT"
  },
  {
    featureId: "EF017438",
    featureDesc: "Connection DC for battery",
    label: "EF017438: Connection DC for battery"
  },
  {
    featureId: "EF017439",
    featureDesc: "Power consumption idle and standby",
    label: "EF017439: Power consumption idle and standby"
  },
  {
    featureId: "EF017440",
    featureDesc: "Max. input current per string",
    label: "EF017440: Max. input current per string"
  },
  {
    featureId: "EF017441",
    featureDesc: "Number of string inputs",
    label: "EF017441: Number of string inputs"
  },
  {
    featureId: "EF017442",
    featureDesc: "Suitable for air conditioner",
    label: "EF017442: Suitable for air conditioner"
  },
  {
    featureId: "EF017443",
    featureDesc: "Suitable for recirculating chiller",
    label: "EF017443: Suitable for recirculating chiller"
  },
  {
    featureId: "EF017444",
    featureDesc: "Suitable for refrigeration plant",
    label: "EF017444: Suitable for refrigeration plant"
  },
  {
    featureId: "EF017446",
    featureDesc: "With flange gasket",
    label: "EF017446: With flange gasket"
  },
  {
    featureId: "EF017447",
    featureDesc: "With drain seal",
    label: "EF017447: With drain seal"
  },
  {
    featureId: "EF017449",
    featureDesc: "Type of cable outlet",
    label: "EF017449: Type of cable outlet"
  },
  {
    featureId: "EF017452",
    featureDesc: "Reassignable",
    label: "EF017452: Reassignable"
  },
  {
    featureId: "EF017453",
    featureDesc: "Height wall opening",
    label: "EF017453: Height wall opening"
  },
  {
    featureId: "EF017454",
    featureDesc: "Width wall opening",
    label: "EF017454: Width wall opening"
  },
  {
    featureId: "EF017456",
    featureDesc: "Shockproof against explosions",
    label: "EF017456: Shockproof against explosions"
  },
  {
    featureId: "EF017459",
    featureDesc: "Profile mounting",
    label: "EF017459: Profile mounting"
  },
  {
    featureId: "EF017460",
    featureDesc: "Profile length",
    label: "EF017460: Profile length"
  },
  {
    featureId: "EF017461",
    featureDesc: "Suitable for wood-aluminium doors",
    label: "EF017461: Suitable for wood-aluminium doors"
  },
  {
    featureId: "EF017462",
    featureDesc: "Suitable for doors with ram protection bar",
    label: "EF017462: Suitable for doors with ram protection bar"
  },
  {
    featureId: "EF017463",
    featureDesc: "Suitable for construction hinges",
    label: "EF017463: Suitable for construction hinges"
  },
  {
    featureId: "EF017468",
    featureDesc: "With volatile organic compound (VOC) sensor",
    label: "EF017468: With volatile organic compound (VOC) sensor"
  },
  {
    featureId: "EF017469",
    featureDesc: "Suitable for extra VOC sensor",
    label: "EF017469: Suitable for extra VOC sensor"
  },
  {
    featureId: "EF017470",
    featureDesc: "Bottom clearance (max. acoustic insulation value)",
    label: "EF017470: Bottom clearance (max. acoustic insulation value)"
  },
  {
    featureId: "EF017471",
    featureDesc: "Energy efficiency class (1253/2014/EU)",
    label: "EF017471: Energy efficiency class (1253/2014/EU)"
  },
  {
    featureId: "EF017472",
    featureDesc: "Delayed lowering",
    label: "EF017472: Delayed lowering"
  },
  {
    featureId: "EF017473",
    featureDesc: "Parallel lowering",
    label: "EF017473: Parallel lowering"
  },
  {
    featureId: "EF017475",
    featureDesc: "Tool-free adjustment",
    label: "EF017475: Tool-free adjustment"
  },
  {
    featureId: "EF017478",
    featureDesc: "Press plate required",
    label: "EF017478: Press plate required"
  },
  {
    featureId: "EF017479",
    featureDesc: "Suitable for ducts",
    label: "EF017479: Suitable for ducts"
  },
  {
    featureId: "EF017480",
    featureDesc: "Suitable for cassettes",
    label: "EF017480: Suitable for cassettes"
  },
  {
    featureId: "EF017481",
    featureDesc: "Material foot",
    label: "EF017481: Material foot"
  },
  {
    featureId: "EF017482",
    featureDesc: "Noise emission class (2019/2016/EU)",
    label: "EF017482: Noise emission class (2019/2016/EU)"
  },
  {
    featureId: "EF017483",
    featureDesc: "Suitable for hobs",
    label: "EF017483: Suitable for hobs"
  },
  {
    featureId: "EF017484",
    featureDesc: "Water consumption eco program per operating cycle",
    label: "EF017484: Water consumption eco program per operating cycle"
  },
  {
    featureId: "EF017485",
    featureDesc: "Energy consumption eco program per operating cycle",
    label: "EF017485: Energy consumption eco program per operating cycle"
  },
  {
    featureId: "EF017486",
    featureDesc: "Energy consumption eco program per 100 operating cycles",
    label: "EF017486: Energy consumption eco program per 100 operating cycles"
  },
  {
    featureId: "EF017487",
    featureDesc: "Noise emission (spin-drying) program eco 40-60",
    label: "EF017487: Noise emission (spin-drying) program eco 40-60"
  },
  {
    featureId: "EF017488",
    featureDesc: "Max. overspeed program eco 40-60",
    label: "EF017488: Max. overspeed program eco 40-60"
  },
  {
    featureId: "EF017489",
    featureDesc: "Weighted energy consumption per 100 washing cycles program eco 40-60",
    label: "EF017489: Weighted energy consumption per 100 washing cycles program eco 40-60"
  },
  {
    featureId: "EF017490",
    featureDesc: "Water consumption per washing cycle program eco 40-60",
    label: "EF017490: Water consumption per washing cycle program eco 40-60"
  },
  {
    featureId: "EF017491",
    featureDesc: "Rated capacity program eco 40-60",
    label: "EF017491: Rated capacity program eco 40-60"
  },
  {
    featureId: "EF017492",
    featureDesc: "Energy consumption per washing cycle program eco 40-60",
    label: "EF017492: Energy consumption per washing cycle program eco 40-60"
  },
  {
    featureId: "EF017493",
    featureDesc: "Weighted energy consumption per 160 drying cycles program cotton",
    label: "EF017493: Weighted energy consumption per 160 drying cycles program cotton"
  },
  {
    featureId: "EF017494",
    featureDesc: "Rated capacity in operating cycle washing/drying",
    label: "EF017494: Rated capacity in operating cycle washing/drying"
  },
  {
    featureId: "EF017495",
    featureDesc: "Weighted energy consumption per 100 cycles program eco 40-60 (washing/drying)",
    label: "EF017495: Weighted energy consumption per 100 cycles program eco 40-60 (washing/drying)"
  },
  {
    featureId: "EF017496",
    featureDesc: "Water consumption per operating cycle washing and drying",
    label: "EF017496: Water consumption per operating cycle washing and drying"
  },
  {
    featureId: "EF017497",
    featureDesc: "Radius of action",
    label: "EF017497: Radius of action"
  },
  {
    featureId: "EF017498",
    featureDesc: "With intensive power step",
    label: "EF017498: With intensive power step"
  },
  {
    featureId: "EF017502",
    featureDesc: "Hi side medium compatibility",
    label: "EF017502: Hi side medium compatibility"
  },
  {
    featureId: "EF017503",
    featureDesc: "Lo side medium compatibility",
    label: "EF017503: Lo side medium compatibility"
  },
  {
    featureId: "EF017504",
    featureDesc: "Suitable for car paint/varnish",
    label: "EF017504: Suitable for car paint/varnish"
  },
  {
    featureId: "EF017505",
    featureDesc: "Suitable for mortar joints",
    label: "EF017505: Suitable for mortar joints"
  },
  {
    featureId: "EF017506",
    featureDesc: "Wire length",
    label: "EF017506: Wire length"
  },
  {
    featureId: "EF017507",
    featureDesc: "With maintenance opening",
    label: "EF017507: With maintenance opening"
  },
  {
    featureId: "EF017508",
    featureDesc: "Network-enabled as a master",
    label: "EF017508: Network-enabled as a master"
  },
  {
    featureId: "EF017509",
    featureDesc: "DC residual current detection",
    label: "EF017509: DC residual current detection"
  },
  {
    featureId: "EF017510",
    featureDesc: "Contactor welding detection",
    label: "EF017510: Contactor welding detection"
  },
  {
    featureId: "EF017511",
    featureDesc: "With key switch",
    label: "EF017511: With key switch"
  },
  {
    featureId: "EF017512",
    featureDesc: "Export of charging logs",
    label: "EF017512: Export of charging logs"
  },
  {
    featureId: "EF017513",
    featureDesc: "OpenChargePointProtocoll (OCPP) version",
    label: "EF017513: OpenChargePointProtocoll (OCPP) version"
  },
  {
    featureId: "EF017515",
    featureDesc: "Plug and charge (ISO 15118)",
    label: "EF017515: Plug and charge (ISO 15118)"
  },
  {
    featureId: "EF017517",
    featureDesc: "Network-enabled as an extender",
    label: "EF017517: Network-enabled as an extender"
  },
  {
    featureId: "EF017518",
    featureDesc: "Measuring range lower value in psi",
    label: "EF017518: Measuring range lower value in psi"
  },
  {
    featureId: "EF017519",
    featureDesc: "Measuring range upper value in psi",
    label: "EF017519: Measuring range upper value in psi"
  },
  {
    featureId: "EF017520",
    featureDesc: "Brush length",
    label: "EF017520: Brush length"
  },
  {
    featureId: "EF017521",
    featureDesc: "Suitable for rebated-flush door",
    label: "EF017521: Suitable for rebated-flush door"
  },
  {
    featureId: "EF017522",
    featureDesc: "Compatible with WPC QI standard",
    label: "EF017522: Compatible with WPC QI standard"
  },
  {
    featureId: "EF017524",
    featureDesc: "Suitable for PVC window",
    label: "EF017524: Suitable for PVC window"
  },
  {
    featureId: "EF017525",
    featureDesc: "Suitable for wooden window",
    label: "EF017525: Suitable for wooden window"
  },
  {
    featureId: "EF017526",
    featureDesc: "Suitable for aluminium window",
    label: "EF017526: Suitable for aluminium window"
  },
  {
    featureId: "EF017527",
    featureDesc: "Suitable for steel window",
    label: "EF017527: Suitable for steel window"
  },
  {
    featureId: "EF017528",
    featureDesc: "Contact pressure adjustable",
    label: "EF017528: Contact pressure adjustable"
  },
  {
    featureId: "EF017530",
    featureDesc: "Diameter bore of the spigot",
    label: "EF017530: Diameter bore of the spigot"
  },
  {
    featureId: "EF017531",
    featureDesc: "Hinge side",
    label: "EF017531: Hinge side"
  },
  {
    featureId: "EF017532",
    featureDesc: "Groove axis",
    label: "EF017532: Groove axis"
  },
  {
    featureId: "EF017533",
    featureDesc: "Airgap",
    label: "EF017533: Airgap"
  },
  {
    featureId: "EF017534",
    featureDesc: "Frequency Modulated Continuous Wave (FMCW) radar",
    label: "EF017534: Frequency Modulated Continuous Wave (FMCW) radar"
  },
  {
    featureId: "EF017535",
    featureDesc: "Overlap width",
    label: "EF017535: Overlap width"
  },
  {
    featureId: "EF017536",
    featureDesc: "Length supporting peg",
    label: "EF017536: Length supporting peg"
  },
  {
    featureId: "EF017537",
    featureDesc: "Number of resistance temperature detector (RTD) inputs",
    label: "EF017537: Number of resistance temperature detector (RTD) inputs"
  },
  {
    featureId: "EF017538",
    featureDesc: "Switch On To Fault protection (SOTF)",
    label: "EF017538: Switch On To Fault protection (SOTF)"
  },
  {
    featureId: "EF017539",
    featureDesc: "Arc protection (ARC)",
    label: "EF017539: Arc protection (ARC)"
  },
  {
    featureId: "EF017540",
    featureDesc: "Current unbalance protection (ANSI 60)",
    label: "EF017540: Current unbalance protection (ANSI 60)"
  },
  {
    featureId: "EF017541",
    featureDesc: "Directional over-current protection (ANSI 67)",
    label: "EF017541: Directional over-current protection (ANSI 67)"
  },
  {
    featureId: "EF017542",
    featureDesc: "Number of mA-outputs",
    label: "EF017542: Number of mA-outputs"
  },
  {
    featureId: "EF017543",
    featureDesc: "Number of Arc-inputs",
    label: "EF017543: Number of Arc-inputs"
  },
  {
    featureId: "EF017549",
    featureDesc: "Type of arc light sensor",
    label: "EF017549: Type of arc light sensor"
  },
  {
    featureId: "EF017550",
    featureDesc: "Max. number of arc sensors",
    label: "EF017550: Max. number of arc sensors"
  },
  {
    featureId: "EF017551",
    featureDesc: "Number of trip outputs",
    label: "EF017551: Number of trip outputs"
  },
  {
    featureId: "EF017552",
    featureDesc: "Operation trip time",
    label: "EF017552: Operation trip time"
  },
  {
    featureId: "EF017553",
    featureDesc: "Number of phase/neutral current inputs",
    label: "EF017553: Number of phase/neutral current inputs"
  },
  {
    featureId: "EF017554",
    featureDesc: "Rated current of phase/neutral current inputs",
    label: "EF017554: Rated current of phase/neutral current inputs"
  },
  {
    featureId: "EF017555",
    featureDesc: "Number of fast fibre optic signal inputs/outputs",
    label: "EF017555: Number of fast fibre optic signal inputs/outputs"
  },
  {
    featureId: "EF017556",
    featureDesc: "Degree of protection (IP), rear side",
    label: "EF017556: Degree of protection (IP), rear side"
  },
  {
    featureId: "EF017557",
    featureDesc: "Number of binary inputs",
    label: "EF017557: Number of binary inputs"
  },
  {
    featureId: "EF017558",
    featureDesc: "Number of binary outputs",
    label: "EF017558: Number of binary outputs"
  },
  {
    featureId: "EF017559",
    featureDesc: "Switchgear technology",
    label: "EF017559: Switchgear technology"
  },
  {
    featureId: "EF017560",
    featureDesc: "With cone pinch fitting",
    label: "EF017560: With cone pinch fitting"
  },
  {
    featureId: "EF017561",
    featureDesc: "With self-sealing connection thread",
    label: "EF017561: With self-sealing connection thread"
  },
  {
    featureId: "EF017562",
    featureDesc: "Type of connecting duct",
    label: "EF017562: Type of connecting duct"
  },
  {
    featureId: "EF017563",
    featureDesc: "Number of HW-interfaces CAN",
    label: "EF017563: Number of HW-interfaces CAN"
  },
  {
    featureId: "EF017564",
    featureDesc: "Supporting protocol for EtherCAT",
    label: "EF017564: Supporting protocol for EtherCAT"
  },
  {
    featureId: "EF017567",
    featureDesc: "Variable operating time",
    label: "EF017567: Variable operating time"
  },
  {
    featureId: "EF017569",
    featureDesc: "Crack bridging",
    label: "EF017569: Crack bridging"
  },
  {
    featureId: "EF017571",
    featureDesc: "Vapor permeable",
    label: "EF017571: Vapor permeable"
  },
  {
    featureId: "EF017572",
    featureDesc: "Suitable for drinking water basin",
    label: "EF017572: Suitable for drinking water basin"
  },
  {
    featureId: "EF017573",
    featureDesc: "With flue gas pipe",
    label: "EF017573: With flue gas pipe"
  },
  {
    featureId: "EF017574",
    featureDesc: "Number of standard connections",
    label: "EF017574: Number of standard connections"
  },
  {
    featureId: "EF017575",
    featureDesc: "Position tap water connection",
    label: "EF017575: Position tap water connection"
  },
  {
    featureId: "EF017579",
    featureDesc: "Stud structure",
    label: "EF017579: Stud structure"
  },
  {
    featureId: "EF017586",
    featureDesc: "Thermal camera",
    label: "EF017586: Thermal camera"
  },
  {
    featureId: "EF017588",
    featureDesc: "Max. excitation voltage",
    label: "EF017588: Max. excitation voltage"
  },
  {
    featureId: "EF017589",
    featureDesc: "Max. excitation current",
    label: "EF017589: Max. excitation current"
  },
  {
    featureId: "EF017590",
    featureDesc: "Multi-imager camera",
    label: "EF017590: Multi-imager camera"
  },
  {
    featureId: "EF017592",
    featureDesc: "Threshold voltage",
    label: "EF017592: Threshold voltage"
  },
  {
    featureId: "EF017594",
    featureDesc: "Recommended min. clearance",
    label: "EF017594: Recommended min. clearance"
  },
  {
    featureId: "EF017595",
    featureDesc: "Suitable for domestic fuel oil (HBO I)",
    label: "EF017595: Suitable for domestic fuel oil (HBO I)"
  },
  {
    featureId: "EF017596",
    featureDesc: "Suitable for domestic fuel oil (HBO II)",
    label: "EF017596: Suitable for domestic fuel oil (HBO II)"
  },
  {
    featureId: "EF017597",
    featureDesc: "With valve adapter",
    label: "EF017597: With valve adapter"
  },
  {
    featureId: "EF017599",
    featureDesc: "Power consumption at max. air volume at 100 Pa",
    label: "EF017599: Power consumption at max. air volume at 100 Pa"
  },
  {
    featureId: "EF017600",
    featureDesc: "Water temperature influence",
    label: "EF017600: Water temperature influence"
  },
  {
    featureId: "EF017601",
    featureDesc: "Thermal efficiency at 70 % air volume at 50 Pa",
    label: "EF017601: Thermal efficiency at 70 % air volume at 50 Pa"
  },
  {
    featureId: "EF017602",
    featureDesc: "Hysteresis",
    label: "EF017602: Hysteresis"
  },
  {
    featureId: "EF017604",
    featureDesc: "Resistance to vertical load applied in a small surface according IEC 61084-2-2",
    label: "EF017604: Resistance to vertical load applied in a small surface according IEC 61084-2-2"
  },
  {
    featureId: "EF017605",
    featureDesc: "Resistance to vertical load applied in a large surface according IEC 61084-2-2",
    label: "EF017605: Resistance to vertical load applied in a large surface according IEC 61084-2-2"
  },
  {
    featureId: "EF017607",
    featureDesc: "With charging cable for E-Mobility",
    label: "EF017607: With charging cable for E-Mobility"
  },
  {
    featureId: "EF017608",
    featureDesc: "Grounding cable reel",
    label: "EF017608: Grounding cable reel"
  },
  {
    featureId: "EF017610",
    featureDesc: "CO2 measurement accuracy",
    label: "EF017610: CO2 measurement accuracy"
  },
  {
    featureId: "EF017615",
    featureDesc: "With connection indicator",
    label: "EF017615: With connection indicator"
  },
  {
    featureId: "EF017618",
    featureDesc: "Suitable for solar",
    label: "EF017618: Suitable for solar"
  },
  {
    featureId: "EF017619",
    featureDesc: "Whirl operating principle",
    label: "EF017619: Whirl operating principle"
  },
  {
    featureId: "EF017620",
    featureDesc: "Negative pressure operating principle",
    label: "EF017620: Negative pressure operating principle"
  },
  {
    featureId: "EF017621",
    featureDesc: "Magnet operating principle",
    label: "EF017621: Magnet operating principle"
  },
  {
    featureId: "EF017622",
    featureDesc: "Certified for UL hazardous location class I",
    label: "EF017622: Certified for UL hazardous location class I"
  },
  {
    featureId: "EF017623",
    featureDesc: "Thrust operating principle",
    label: "EF017623: Thrust operating principle"
  },
  {
    featureId: "EF017624",
    featureDesc: "Partial flow principle",
    label: "EF017624: Partial flow principle"
  },
  {
    featureId: "EF017625",
    featureDesc: "Certified for UL hazardous location class II",
    label: "EF017625: Certified for UL hazardous location class II"
  },
  {
    featureId: "EF017626",
    featureDesc: "Principle full flow with settling",
    label: "EF017626: Principle full flow with settling"
  },
  {
    featureId: "EF017627",
    featureDesc: "Certified for UL hazardous location class III",
    label: "EF017627: Certified for UL hazardous location class III"
  },
  {
    featureId: "EF017628",
    featureDesc: "Certified for UL hazardous location division 1",
    label: "EF017628: Certified for UL hazardous location division 1"
  },
  {
    featureId: "EF017629",
    featureDesc: "Certified for UL hazardous location division 2",
    label: "EF017629: Certified for UL hazardous location division 2"
  },
  {
    featureId: "EF017630",
    featureDesc: "Cleaning possible during operation",
    label: "EF017630: Cleaning possible during operation"
  },
  {
    featureId: "EF017632",
    featureDesc: "Certified for UL hazardous location group A (acetylene)",
    label: "EF017632: Certified for UL hazardous location group A (acetylene)"
  },
  {
    featureId: "EF017633",
    featureDesc: "Certified for UL hazardous location group B (hydrogen)",
    label: "EF017633: Certified for UL hazardous location group B (hydrogen)"
  },
  {
    featureId: "EF017635",
    featureDesc: "Certified for UL hazardous location group C (ethylene)",
    label: "EF017635: Certified for UL hazardous location group C (ethylene)"
  },
  {
    featureId: "EF017636",
    featureDesc: "Certified for UL hazardous location group D (propane)",
    label: "EF017636: Certified for UL hazardous location group D (propane)"
  },
  {
    featureId: "EF017637",
    featureDesc: "Certified for UL hazardous location group E (metal dusts)",
    label: "EF017637: Certified for UL hazardous location group E (metal dusts)"
  },
  {
    featureId: "EF017638",
    featureDesc: "Certified for UL hazardous location group F (carbonaceous dusts)",
    label: "EF017638: Certified for UL hazardous location group F (carbonaceous dusts)"
  },
  {
    featureId: "EF017639",
    featureDesc: "Certified for UL hazardous location group G (non-conductive dusts)",
    label: "EF017639: Certified for UL hazardous location group G (non-conductive dusts)"
  },
  {
    featureId: "EF017640",
    featureDesc: "Number of socket outlets French standard UTE type E",
    label: "EF017640: Number of socket outlets French standard UTE type E"
  },
  {
    featureId: "EF017641",
    featureDesc: "Number of socket outlets Italian standard type P40 L/F",
    label: "EF017641: Number of socket outlets Italian standard type P40 L/F"
  },
  {
    featureId: "EF017642",
    featureDesc: "With freezing compartment",
    label: "EF017642: With freezing compartment"
  },
  {
    featureId: "EF017643",
    featureDesc: "Connection lug galvanized",
    label: "EF017643: Connection lug galvanized"
  },
  {
    featureId: "EF017646",
    featureDesc: "Type of priming",
    label: "EF017646: Type of priming"
  },
  {
    featureId: "EF017649",
    featureDesc: "Post gas time",
    label: "EF017649: Post gas time"
  },
  {
    featureId: "EF017650",
    featureDesc: "Type of torch",
    label: "EF017650: Type of torch"
  },
  {
    featureId: "EF017653",
    featureDesc: "Min. distance between different transits",
    label: "EF017653: Min. distance between different transits"
  },
  {
    featureId: "EF017654",
    featureDesc: "Switchgear solution",
    label: "EF017654: Switchgear solution"
  },
  {
    featureId: "EF017655",
    featureDesc: "Number of incoming panels",
    label: "EF017655: Number of incoming panels"
  },
  {
    featureId: "EF017656",
    featureDesc: "Number of outgoing panels",
    label: "EF017656: Number of outgoing panels"
  },
  {
    featureId: "EF017657",
    featureDesc: "Metering panel",
    label: "EF017657: Metering panel"
  },
  {
    featureId: "EF017658",
    featureDesc: "Max. peak current",
    label: "EF017658: Max. peak current"
  },
  {
    featureId: "EF017659",
    featureDesc: "Internal arc withstand current",
    label: "EF017659: Internal arc withstand current"
  },
  {
    featureId: "EF017661",
    featureDesc: "Insulation medium",
    label: "EF017661: Insulation medium"
  },
  {
    featureId: "EF017662",
    featureDesc: "According to IEC 62271-200",
    label: "EF017662: According to IEC 62271-200"
  },
  {
    featureId: "EF017663",
    featureDesc: "Charging cable permanently lockable at charging device",
    label: "EF017663: Charging cable permanently lockable at charging device"
  },
  {
    featureId: "EF017664",
    featureDesc: "Networkable via LAN",
    label: "EF017664: Networkable via LAN"
  },
  {
    featureId: "EF017665",
    featureDesc: "With MID compliant energy meter",
    label: "EF017665: With MID compliant energy meter"
  },
  {
    featureId: "EF017668",
    featureDesc: "Static load management integrated",
    label: "EF017668: Static load management integrated"
  },
  {
    featureId: "EF017669",
    featureDesc: "Suitable for dynamic load management",
    label: "EF017669: Suitable for dynamic load management"
  },
  {
    featureId: "EF017670",
    featureDesc: "Rated capacitive current back-to-back",
    label: "EF017670: Rated capacitive current back-to-back"
  },
  {
    featureId: "EF017672",
    featureDesc: "Backend included in delivery",
    label: "EF017672: Backend included in delivery"
  },
  {
    featureId: "EF017674",
    featureDesc: "Background monitoring",
    label: "EF017674: Background monitoring"
  },
  {
    featureId: "EF017675",
    featureDesc: "With acoustic sensor",
    label: "EF017675: With acoustic sensor"
  },
  {
    featureId: "EF017676",
    featureDesc: "Fuse system according to IEC 60269",
    label: "EF017676: Fuse system according to IEC 60269"
  },
  {
    featureId: "EF017681",
    featureDesc: "With lockable access door",
    label: "EF017681: With lockable access door"
  },
  {
    featureId: "EF017682",
    featureDesc: "With connection clamps",
    label: "EF017682: With connection clamps"
  },
  {
    featureId: "EF017683",
    featureDesc: "With integrated C-rail",
    label: "EF017683: With integrated C-rail"
  },
  {
    featureId: "EF017684",
    featureDesc: "With integrated DIN rail (top hat rail)",
    label: "EF017684: With integrated DIN rail (top hat rail)"
  },
  {
    featureId: "EF017685",
    featureDesc: "With integrated connection point for potential equalization",
    label: "EF017685: With integrated connection point for potential equalization"
  },
  {
    featureId: "EF017690",
    featureDesc: "Inputs according to IEC 61869",
    label: "EF017690: Inputs according to IEC 61869"
  },
  {
    featureId: "EF017691",
    featureDesc: "Certified according to ANSI/IEEE C37.90",
    label: "EF017691: Certified according to ANSI/IEEE C37.90"
  },
  {
    featureId: "EF017692",
    featureDesc: "Certified for Class 1E according to IEEE C37.98",
    label: "EF017692: Certified for Class 1E according to IEEE C37.98"
  },
  {
    featureId: "EF017693",
    featureDesc: "Certified for Class 1E according to IEEE C37.105",
    label: "EF017693: Certified for Class 1E according to IEEE C37.105"
  },
  {
    featureId: "EF017694",
    featureDesc: "Certified for Class 1E according to IEEE 323-1983",
    label: "EF017694: Certified for Class 1E according to IEEE 323-1983"
  },
  {
    featureId: "EF017695",
    featureDesc: "Certified for Class 1E according to IEEE 344-1987",
    label: "EF017695: Certified for Class 1E according to IEEE 344-1987"
  },
  {
    featureId: "EF017696",
    featureDesc: "Certified according to UL",
    label: "EF017696: Certified according to UL"
  },
  {
    featureId: "EF017697",
    featureDesc: "Certified according to UL/C-UL",
    label: "EF017697: Certified according to UL/C-UL"
  },
  {
    featureId: "EF017698",
    featureDesc: "Rated switching capacity AC",
    label: "EF017698: Rated switching capacity AC"
  },
  {
    featureId: "EF017699",
    featureDesc: "Rated switching capacity DC",
    label: "EF017699: Rated switching capacity DC"
  },
  {
    featureId: "EF017702",
    featureDesc: "House connection",
    label: "EF017702: House connection"
  },
  {
    featureId: "EF017703",
    featureDesc: "Max. illuminated surface area",
    label: "EF017703: Max. illuminated surface area"
  },
  {
    featureId: "EF017704",
    featureDesc: "Mast height",
    label: "EF017704: Mast height"
  },
  {
    featureId: "EF017705",
    featureDesc: "Extra-clear",
    label: "EF017705: Extra-clear"
  },
  {
    featureId: "EF017706",
    featureDesc: "Anti-fragmentation film",
    label: "EF017706: Anti-fragmentation film"
  },
  {
    featureId: "EF017707",
    featureDesc: "Venturi tube",
    label: "EF017707: Venturi tube"
  },
  {
    featureId: "EF017709",
    featureDesc: "Built-in width of wall for U mounting",
    label: "EF017709: Built-in width of wall for U mounting"
  },
  {
    featureId: "EF017710",
    featureDesc: "Stroke length actuator",
    label: "EF017710: Stroke length actuator"
  },
  {
    featureId: "EF017711",
    featureDesc: "Built-in width of wall for corner mounting",
    label: "EF017711: Built-in width of wall for corner mounting"
  },
  {
    featureId: "EF017712",
    featureDesc: "Suitable for above wall mounting",
    label: "EF017712: Suitable for above wall mounting"
  },
  {
    featureId: "EF017714",
    featureDesc: "With ceramic valve",
    label: "EF017714: With ceramic valve"
  },
  {
    featureId: "EF017715",
    featureDesc: "Suitable for combination with bath screen",
    label: "EF017715: Suitable for combination with bath screen"
  },
  {
    featureId: "EF017716",
    featureDesc: "Vibration emission value ah (drilling in metal)",
    label: "EF017716: Vibration emission value ah (drilling in metal)"
  },
  {
    featureId: "EF017717",
    featureDesc: "Vibration uncertainty K (drilling in metal)",
    label: "EF017717: Vibration uncertainty K (drilling in metal)"
  },
  {
    featureId: "EF017718",
    featureDesc: "Sound pressure level",
    label: "EF017718: Sound pressure level"
  },
  {
    featureId: "EF017719",
    featureDesc: "Sound power level",
    label: "EF017719: Sound power level"
  },
  {
    featureId: "EF017720",
    featureDesc: "Sound pressure uncertainty K",
    label: "EF017720: Sound pressure uncertainty K"
  },
  {
    featureId: "EF017722",
    featureDesc: "Vibration emission value ah (impact drilling in masonry)",
    label: "EF017722: Vibration emission value ah (impact drilling in masonry)"
  },
  {
    featureId: "EF017723",
    featureDesc: "Vibration uncertainty K (impact drilling in masonry)",
    label: "EF017723: Vibration uncertainty K (impact drilling in masonry)"
  },
  {
    featureId: "EF017724",
    featureDesc: "Rated mainly active load-breaking current",
    label: "EF017724: Rated mainly active load-breaking current"
  },
  {
    featureId: "EF017725",
    featureDesc: "Rated transfer current",
    label: "EF017725: Rated transfer current"
  },
  {
    featureId: "EF017726",
    featureDesc: "Rated take-over current",
    label: "EF017726: Rated take-over current"
  },
  {
    featureId: "EF017727",
    featureDesc: "Rated short-circuit making current",
    label: "EF017727: Rated short-circuit making current"
  },
  {
    featureId: "EF017728",
    featureDesc: "Rated peak withstand current",
    label: "EF017728: Rated peak withstand current"
  },
  {
    featureId: "EF017729",
    featureDesc: "Breaking capacity with fuses",
    label: "EF017729: Breaking capacity with fuses"
  },
  {
    featureId: "EF017730",
    featureDesc: "Rated normal current with fuses",
    label: "EF017730: Rated normal current with fuses"
  },
  {
    featureId: "EF017731",
    featureDesc: "With motor drive",
    label: "EF017731: With motor drive"
  },
  {
    featureId: "EF017732",
    featureDesc: "Motor drive supply voltage (DC)",
    label: "EF017732: Motor drive supply voltage (DC)"
  },
  {
    featureId: "EF017733",
    featureDesc: "Motor drive supply voltage (AC)",
    label: "EF017733: Motor drive supply voltage (AC)"
  },
  {
    featureId: "EF017735",
    featureDesc: "Number of mechanical operations",
    label: "EF017735: Number of mechanical operations"
  },
  {
    featureId: "EF017736",
    featureDesc: "Mechanical endurance class",
    label: "EF017736: Mechanical endurance class"
  },
  {
    featureId: "EF017737",
    featureDesc: "Electrical endurance class",
    label: "EF017737: Electrical endurance class"
  },
  {
    featureId: "EF017738",
    featureDesc: "Service condition class according to IEC/TS 62271-304:2008-05",
    label: "EF017738: Service condition class according to IEC/TS 62271-304:2008-05"
  },
  {
    featureId: "EF017739",
    featureDesc: "Distance between phases",
    label: "EF017739: Distance between phases"
  },
  {
    featureId: "EF017740",
    featureDesc: "According to IEC 62271-105",
    label: "EF017740: According to IEC 62271-105"
  },
  {
    featureId: "EF017741",
    featureDesc: "According to IEC 62271-103",
    label: "EF017741: According to IEC 62271-103"
  },
  {
    featureId: "EF017742",
    featureDesc: "According to IEC 62271-102",
    label: "EF017742: According to IEC 62271-102"
  },
  {
    featureId: "EF017743",
    featureDesc: "According to IEC 62271-1",
    label: "EF017743: According to IEC 62271-1"
  },
  {
    featureId: "EF017745",
    featureDesc: "Number of breaking operation cycles at rated current",
    label: "EF017745: Number of breaking operation cycles at rated current"
  },
  {
    featureId: "EF017746",
    featureDesc: "Clip factors for current sensor",
    label: "EF017746: Clip factors for current sensor"
  },
  {
    featureId: "EF017747",
    featureDesc: "Clip factors for current transformer",
    label: "EF017747: Clip factors for current transformer"
  },
  {
    featureId: "EF017749",
    featureDesc: "Max. voltage measuring range, AC/DC",
    label: "EF017749: Max. voltage measuring range, AC/DC"
  },
  {
    featureId: "EF017750",
    featureDesc: "Lowest resolution, AC/DC voltage",
    label: "EF017750: Lowest resolution, AC/DC voltage"
  },
  {
    featureId: "EF017751",
    featureDesc: "Max. current measuring range, AC/DC",
    label: "EF017751: Max. current measuring range, AC/DC"
  },
  {
    featureId: "EF017752",
    featureDesc: "Lowest resolution, AC/DC current",
    label: "EF017752: Lowest resolution, AC/DC current"
  },
  {
    featureId: "EF017753",
    featureDesc: "Short-circuit breaking capacity according to class E1",
    label: "EF017753: Short-circuit breaking capacity according to class E1"
  },
  {
    featureId: "EF017754",
    featureDesc: "Breaking capacity with fuses according to class E2",
    label: "EF017754: Breaking capacity with fuses according to class E2"
  },
  {
    featureId: "EF017758",
    featureDesc: "Number of mechanical operation cycles mechanically latched",
    label: "EF017758: Number of mechanical operation cycles mechanically latched"
  },
  {
    featureId: "EF017759",
    featureDesc: "Opening time electrically latched",
    label: "EF017759: Opening time electrically latched"
  },
  {
    featureId: "EF017760",
    featureDesc: "Opening time mechanically latched",
    label: "EF017760: Opening time mechanically latched"
  },
  {
    featureId: "EF017761",
    featureDesc: "Closing time",
    label: "EF017761: Closing time"
  },
  {
    featureId: "EF017762",
    featureDesc: "According to IEC 62271-106",
    label: "EF017762: According to IEC 62271-106"
  },
  {
    featureId: "EF017764",
    featureDesc: "With mechanical latch",
    label: "EF017764: With mechanical latch"
  },
  {
    featureId: "EF017765",
    featureDesc: "Mechanical latch supply voltage (AC)",
    label: "EF017765: Mechanical latch supply voltage (AC)"
  },
  {
    featureId: "EF017766",
    featureDesc: "Mechanical latch supply voltage (DC)",
    label: "EF017766: Mechanical latch supply voltage (DC)"
  },
  {
    featureId: "EF017767",
    featureDesc: "Chopping current",
    label: "EF017767: Chopping current"
  },
  {
    featureId: "EF017768",
    featureDesc: "Testing according to EN 50678",
    label: "EF017768: Testing according to EN 50678"
  },
  {
    featureId: "EF017769",
    featureDesc: "Testing according to EN 50699",
    label: "EF017769: Testing according to EN 50699"
  },
  {
    featureId: "EF017770",
    featureDesc: "Testing according to IEC/EN 62353",
    label: "EF017770: Testing according to IEC/EN 62353"
  },
  {
    featureId: "EF017771",
    featureDesc: "Testing according to IEC/EN 60974-4",
    label: "EF017771: Testing according to IEC/EN 60974-4"
  },
  {
    featureId: "EF017772",
    featureDesc: "Testing according to EN 62638",
    label: "EF017772: Testing according to EN 62638"
  },
  {
    featureId: "EF017773",
    featureDesc: "Testing according to EN 62911",
    label: "EF017773: Testing according to EN 62911"
  },
  {
    featureId: "EF017774",
    featureDesc: "Leakage current measuring DC",
    label: "EF017774: Leakage current measuring DC"
  },
  {
    featureId: "EF017775",
    featureDesc: "Tripping time measurement of (P)RCDs",
    label: "EF017775: Tripping time measurement of (P)RCDs"
  },
  {
    featureId: "EF017776",
    featureDesc: "Entry of test specimen information",
    label: "EF017776: Entry of test specimen information"
  },
  {
    featureId: "EF017777",
    featureDesc: "Adjustable height for floor covering",
    label: "EF017777: Adjustable height for floor covering"
  },
  {
    featureId: "EF017781",
    featureDesc: "Memory capacity included storage medium",
    label: "EF017781: Memory capacity included storage medium"
  },
  {
    featureId: "EF017782",
    featureDesc: "Suitable for bath mixing tap",
    label: "EF017782: Suitable for bath mixing tap"
  },
  {
    featureId: "EF017783",
    featureDesc: "Mortar designation to EN 998-2",
    label: "EF017783: Mortar designation to EN 998-2"
  },
  {
    featureId: "EF017784",
    featureDesc: "With reducing coupling",
    label: "EF017784: With reducing coupling"
  },
  {
    featureId: "EF017787",
    featureDesc: "With folding spring",
    label: "EF017787: With folding spring"
  },
  {
    featureId: "EF017796",
    featureDesc: "With integrated balancing resistors",
    label: "EF017796: With integrated balancing resistors"
  },
  {
    featureId: "EF017798",
    featureDesc: "Recommended mounting height",
    label: "EF017798: Recommended mounting height"
  },
  {
    featureId: "EF017799",
    featureDesc: "Suitable for laminated glass",
    label: "EF017799: Suitable for laminated glass"
  },
  {
    featureId: "EF017800",
    featureDesc: "Requires interface/analyzer",
    label: "EF017800: Requires interface/analyzer"
  },
  {
    featureId: "EF017807",
    featureDesc: "With thermometer",
    label: "EF017807: With thermometer"
  },
  {
    featureId: "EF017808",
    featureDesc: "With pressure gauge",
    label: "EF017808: With pressure gauge"
  },
  {
    featureId: "EF017809",
    featureDesc: "With shut of valve",
    label: "EF017809: With shut of valve"
  },
  {
    featureId: "EF017810",
    featureDesc: "Dosing point for solid or liquid cleaning additives",
    label: "EF017810: Dosing point for solid or liquid cleaning additives"
  },
  {
    featureId: "EF017813",
    featureDesc: "Vibration emission value ah (sawing wood)",
    label: "EF017813: Vibration emission value ah (sawing wood)"
  },
  {
    featureId: "EF017815",
    featureDesc: "Vibration uncertainty K (sawing wood)",
    label: "EF017815: Vibration uncertainty K (sawing wood)"
  },
  {
    featureId: "EF017817",
    featureDesc: "Vibration emission value ah (cutting sheet metal)",
    label: "EF017817: Vibration emission value ah (cutting sheet metal)"
  },
  {
    featureId: "EF017818",
    featureDesc: "Vibration uncertainty K (cutting sheet metal)",
    label: "EF017818: Vibration uncertainty K (cutting sheet metal)"
  },
  {
    featureId: "EF017819",
    featureDesc: "Rear backset",
    label: "EF017819: Rear backset"
  },
  {
    featureId: "EF017820",
    featureDesc: "Vibration emission value ah",
    label: "EF017820: Vibration emission value ah"
  },
  {
    featureId: "EF017821",
    featureDesc: "Vibration uncertainty K",
    label: "EF017821: Vibration uncertainty K"
  },
  {
    featureId: "EF017822",
    featureDesc: "Vibration emission value ah (hammer drilling in concrete)",
    label: "EF017822: Vibration emission value ah (hammer drilling in concrete)"
  },
  {
    featureId: "EF017823",
    featureDesc: "Vibration uncertainty K (hammer drilling in concrete)",
    label: "EF017823: Vibration uncertainty K (hammer drilling in concrete)"
  },
  {
    featureId: "EF017825",
    featureDesc: "Vibration emission value ah (chiseling)",
    label: "EF017825: Vibration emission value ah (chiseling)"
  },
  {
    featureId: "EF017827",
    featureDesc: "Vibrations uncertainty K (chiseling)",
    label: "EF017827: Vibrations uncertainty K (chiseling)"
  },
  {
    featureId: "EF017830",
    featureDesc: "With dirt separator",
    label: "EF017830: With dirt separator"
  },
  {
    featureId: "EF017831",
    featureDesc: "With magnetite separator",
    label: "EF017831: With magnetite separator"
  },
  {
    featureId: "EF017837",
    featureDesc: "Vibration emission value ah (screw driving)",
    label: "EF017837: Vibration emission value ah (screw driving)"
  },
  {
    featureId: "EF017838",
    featureDesc: "Vibration uncertainty K (screw driving)",
    label: "EF017838: Vibration uncertainty K (screw driving)"
  },
  {
    featureId: "EF017839",
    featureDesc: "Vibration emission value ah (sanding solid wood)",
    label: "EF017839: Vibration emission value ah (sanding solid wood)"
  },
  {
    featureId: "EF017840",
    featureDesc: "Vibration uncertainty K (sanding solid wood)",
    label: "EF017840: Vibration uncertainty K (sanding solid wood)"
  },
  {
    featureId: "EF017841",
    featureDesc: "Environmental condition according to EN 12209",
    label: "EF017841: Environmental condition according to EN 12209"
  },
  {
    featureId: "EF017842",
    featureDesc: "Door related application according to EN 12209",
    label: "EF017842: Door related application according to EN 12209"
  },
  {
    featureId: "EF017843",
    featureDesc: "Locking mechanism according to EN 12209",
    label: "EF017843: Locking mechanism according to EN 12209"
  },
  {
    featureId: "EF017844",
    featureDesc: "Electrical latching action",
    label: "EF017844: Electrical latching action"
  },
  {
    featureId: "EF017845",
    featureDesc: "Mechanical end stop",
    label: "EF017845: Mechanical end stop"
  },
  {
    featureId: "EF017846",
    featureDesc: "Control delay",
    label: "EF017846: Control delay"
  },
  {
    featureId: "EF017847",
    featureDesc: "Max. jamb depth",
    label: "EF017847: Max. jamb depth"
  },
  {
    featureId: "EF017848",
    featureDesc: "Door leaf mounting hinge side",
    label: "EF017848: Door leaf mounting hinge side"
  },
  {
    featureId: "EF017849",
    featureDesc: "Door leaf mounting opposite hinge side",
    label: "EF017849: Door leaf mounting opposite hinge side"
  },
  {
    featureId: "EF017850",
    featureDesc: "Transom mounting hinge side",
    label: "EF017850: Transom mounting hinge side"
  },
  {
    featureId: "EF017851",
    featureDesc: "Transom mounting opposite hinge site",
    label: "EF017851: Transom mounting opposite hinge site"
  },
  {
    featureId: "EF017852",
    featureDesc: "Transom mounting glass door",
    label: "EF017852: Transom mounting glass door"
  },
  {
    featureId: "EF017853",
    featureDesc: "Approved according to EN 16005",
    label: "EF017853: Approved according to EN 16005"
  },
  {
    featureId: "EF017856",
    featureDesc: "Vibration emission value ah (impact drilling in concrete)",
    label: "EF017856: Vibration emission value ah (impact drilling in concrete)"
  },
  {
    featureId: "EF017857",
    featureDesc: "Vibration uncertainty K (Impact drilling in concrete)",
    label: "EF017857: Vibration uncertainty K (Impact drilling in concrete)"
  },
  {
    featureId: "EF017858",
    featureDesc: "Vibration emission value ah (tightening screws and nuts of max. size)",
    label: "EF017858: Vibration emission value ah (tightening screws and nuts of max. size)"
  },
  {
    featureId: "EF017859",
    featureDesc: "Vibration uncertainty K (tightening screws and nuts of max. size)",
    label: "EF017859: Vibration uncertainty K (tightening screws and nuts of max. size)"
  },
  {
    featureId: "EF017862",
    featureDesc: "Vibration emission value ah (sawing sheet metal)",
    label: "EF017862: Vibration emission value ah (sawing sheet metal)"
  },
  {
    featureId: "EF017863",
    featureDesc: "Vibration uncertainty K (sawing sheet metal)",
    label: "EF017863: Vibration uncertainty K (sawing sheet metal)"
  },
  {
    featureId: "EF017864",
    featureDesc: "Vibration emission value ah (fastening in wood)",
    label: "EF017864: Vibration emission value ah (fastening in wood)"
  },
  {
    featureId: "EF017865",
    featureDesc: "Vibration uncertainty K (fastening in wood)",
    label: "EF017865: Vibration uncertainty K (fastening in wood)"
  },
  {
    featureId: "EF017866",
    featureDesc: "Vibration emission value ah (surface grinding (roughing))",
    label: "EF017866: Vibration emission value ah (surface grinding (roughing))"
  },
  {
    featureId: "EF017867",
    featureDesc: "Vibration uncertainty K (surface grinding (roughing))",
    label: "EF017867: Vibration uncertainty K (surface grinding (roughing))"
  },
  {
    featureId: "EF017868",
    featureDesc: "Vibration emission value ah (sanding with sanding sheet)",
    label: "EF017868: Vibration emission value ah (sanding with sanding sheet)"
  },
  {
    featureId: "EF017869",
    featureDesc: "Vibration uncertainty K (sanding with sanding sheet)",
    label: "EF017869: Vibration uncertainty K (sanding with sanding sheet)"
  },
  {
    featureId: "EF017870",
    featureDesc: "Function rotating light",
    label: "EF017870: Function rotating light"
  },
  {
    featureId: "EF017871",
    featureDesc: "Function pulsating light",
    label: "EF017871: Function pulsating light"
  },
  {
    featureId: "EF017873",
    featureDesc: "Suitable for M12 connector",
    label: "EF017873: Suitable for M12 connector"
  },
  {
    featureId: "EF017874",
    featureDesc: "Suitable for IO link",
    label: "EF017874: Suitable for IO link"
  },
  {
    featureId: "EF017879",
    featureDesc: "Vibration emission value ah (routing edges)",
    label: "EF017879: Vibration emission value ah (routing edges)"
  },
  {
    featureId: "EF017880",
    featureDesc: "Vibration uncertainty K (routing edges)",
    label: "EF017880: Vibration uncertainty K (routing edges)"
  },
  {
    featureId: "EF017881",
    featureDesc: "Vibration emission value ah (surface grinding, grinding tool diameter of 25 mm)",
    label: "EF017881: Vibration emission value ah (surface grinding, grinding tool diameter of 25 mm)"
  },
  {
    featureId: "EF017882",
    featureDesc: "Vibration uncertainty K (surface grinding, grinding tool diameter of 25 mm)",
    label: "EF017882: Vibration uncertainty K (surface grinding, grinding tool diameter of 25 mm)"
  },
  {
    featureId: "EF017884",
    featureDesc: "Vibration emission value ah (surface grinding up to diameter of 50 mm)",
    label: "EF017884: Vibration emission value ah (surface grinding up to diameter of 50 mm)"
  },
  {
    featureId: "EF017886",
    featureDesc: "Vibration uncertainty K (surface grinding up to diameter of 50 mm)",
    label: "EF017886: Vibration uncertainty K (surface grinding up to diameter of 50 mm)"
  },
  {
    featureId: "EF017887",
    featureDesc: "Vibration emission value ah (sawing metal)",
    label: "EF017887: Vibration emission value ah (sawing metal)"
  },
  {
    featureId: "EF017888",
    featureDesc: "Vibration uncertainty K (sawing metal)",
    label: "EF017888: Vibration uncertainty K (sawing metal)"
  },
  {
    featureId: "EF017889",
    featureDesc: "Type of extension",
    label: "EF017889: Type of extension"
  },
  {
    featureId: "EF017890",
    featureDesc: "Height adjustment range",
    label: "EF017890: Height adjustment range"
  },
  {
    featureId: "EF017891",
    featureDesc: "Side adjustment range",
    label: "EF017891: Side adjustment range"
  },
  {
    featureId: "EF017892",
    featureDesc: "Tilt adjustment range",
    label: "EF017892: Tilt adjustment range"
  },
  {
    featureId: "EF017893",
    featureDesc: "Drawer length",
    label: "EF017893: Drawer length"
  },
  {
    featureId: "EF017894",
    featureDesc: "Travel length",
    label: "EF017894: Travel length"
  },
  {
    featureId: "EF017896",
    featureDesc: "Drawer mounting",
    label: "EF017896: Drawer mounting"
  },
  {
    featureId: "EF017897",
    featureDesc: "Max. shelf thickness",
    label: "EF017897: Max. shelf thickness"
  },
  {
    featureId: "EF017899",
    featureDesc: "Load bearing capacity per piece",
    label: "EF017899: Load bearing capacity per piece"
  },
  {
    featureId: "EF017901",
    featureDesc: "Vibration emission value ah (sawing with plunge-cutting saw blade)",
    label: "EF017901: Vibration emission value ah (sawing with plunge-cutting saw blade)"
  },
  {
    featureId: "EF017902",
    featureDesc: "Vibration uncertainty K (sawing with plunge-cutting saw blade)",
    label: "EF017902: Vibration uncertainty K (sawing with plunge-cutting saw blade)"
  },
  {
    featureId: "EF017903",
    featureDesc: "Vibration emission value ah (sawing with segment saw blade)",
    label: "EF017903: Vibration emission value ah (sawing with segment saw blade)"
  },
  {
    featureId: "EF017904",
    featureDesc: "Vibration uncertainty K (sawing with segment saw blade)",
    label: "EF017904: Vibration uncertainty K (sawing with segment saw blade)"
  },
  {
    featureId: "EF017905",
    featureDesc: "Vibration uncertainty K (grinding)",
    label: "EF017905: Vibration uncertainty K (grinding)"
  },
  {
    featureId: "EF017906",
    featureDesc: "Vibration emission value ah (grinding)",
    label: "EF017906: Vibration emission value ah (grinding)"
  },
  {
    featureId: "EF017907",
    featureDesc: "Vibration emission value an (polishing)",
    label: "EF017907: Vibration emission value an (polishing)"
  },
  {
    featureId: "EF017908",
    featureDesc: "Vibration uncertainty K (polishing)",
    label: "EF017908: Vibration uncertainty K (polishing)"
  },
  {
    featureId: "EF017909",
    featureDesc: "Suitable for time ventilation",
    label: "EF017909: Suitable for time ventilation"
  },
  {
    featureId: "EF017910",
    featureDesc: "Suitable for gap ventilation",
    label: "EF017910: Suitable for gap ventilation"
  },
  {
    featureId: "EF017911",
    featureDesc: "Suitable for wind alarm",
    label: "EF017911: Suitable for wind alarm"
  },
  {
    featureId: "EF017912",
    featureDesc: "Suitable for rain alarm",
    label: "EF017912: Suitable for rain alarm"
  },
  {
    featureId: "EF017913",
    featureDesc: "Status 'Position in %'",
    label: "EF017913: Status 'Position in %'"
  },
  {
    featureId: "EF017914",
    featureDesc: "Status 'Opened'",
    label: "EF017914: Status 'Opened'"
  },
  {
    featureId: "EF017915",
    featureDesc: "Status 'Closed'",
    label: "EF017915: Status 'Closed'"
  },
  {
    featureId: "EF017916",
    featureDesc: "Status 'Opening'",
    label: "EF017916: Status 'Opening'"
  },
  {
    featureId: "EF017917",
    featureDesc: "Status 'Closing'",
    label: "EF017917: Status 'Closing'"
  },
  {
    featureId: "EF017918",
    featureDesc: "Max. cable length to push buttons",
    label: "EF017918: Max. cable length to push buttons"
  },
  {
    featureId: "EF017919",
    featureDesc: "Max. cable length to window",
    label: "EF017919: Max. cable length to window"
  },
  {
    featureId: "EF017920",
    featureDesc: "Max. residual ripple",
    label: "EF017920: Max. residual ripple"
  },
  {
    featureId: "EF017921",
    featureDesc: "Mounting outward-opening leaf",
    label: "EF017921: Mounting outward-opening leaf"
  },
  {
    featureId: "EF017922",
    featureDesc: "Mounting outward-opening frame",
    label: "EF017922: Mounting outward-opening frame"
  },
  {
    featureId: "EF017923",
    featureDesc: "Mounting inward-opening leaf",
    label: "EF017923: Mounting inward-opening leaf"
  },
  {
    featureId: "EF017924",
    featureDesc: "Mounting inward-opening frame",
    label: "EF017924: Mounting inward-opening frame"
  },
  {
    featureId: "EF017926",
    featureDesc: "Opening speed",
    label: "EF017926: Opening speed"
  },
  {
    featureId: "EF017929",
    featureDesc: "Suitable for tilt and turn windows",
    label: "EF017929: Suitable for tilt and turn windows"
  },
  {
    featureId: "EF017930",
    featureDesc: "Suitable for turn windows",
    label: "EF017930: Suitable for turn windows"
  },
  {
    featureId: "EF017931",
    featureDesc: "Frame installation",
    label: "EF017931: Frame installation"
  },
  {
    featureId: "EF017932",
    featureDesc: "Leaf installation",
    label: "EF017932: Leaf installation"
  },
  {
    featureId: "EF017933",
    featureDesc: "Opening speed adjustable",
    label: "EF017933: Opening speed adjustable"
  },
  {
    featureId: "EF017934",
    featureDesc: "Opening speed",
    label: "EF017934: Opening speed"
  },
  {
    featureId: "EF017935",
    featureDesc: "Position feedback",
    label: "EF017935: Position feedback"
  },
  {
    featureId: "EF017936",
    featureDesc: "Min. door leaf height",
    label: "EF017936: Min. door leaf height"
  },
  {
    featureId: "EF017971",
    featureDesc: "Number of loop throughs",
    label: "EF017971: Number of loop throughs"
  },
  {
    featureId: "EF017972",
    featureDesc: "Operation and configuration by app",
    label: "EF017972: Operation and configuration by app"
  },
  {
    featureId: "EF017973",
    featureDesc: "According to IEC 61869-10",
    label: "EF017973: According to IEC 61869-10"
  },
  {
    featureId: "EF017974",
    featureDesc: "According to IEC 61869-11",
    label: "EF017974: According to IEC 61869-11"
  },
  {
    featureId: "EF017977",
    featureDesc: "Rated continuous thermal current",
    label: "EF017977: Rated continuous thermal current"
  },
  {
    featureId: "EF017979",
    featureDesc: "Rated dynamic current",
    label: "EF017979: Rated dynamic current"
  },
  {
    featureId: "EF017981",
    featureDesc: "Current accuracy class",
    label: "EF017981: Current accuracy class"
  },
  {
    featureId: "EF017982",
    featureDesc: "Voltage accuracy class",
    label: "EF017982: Voltage accuracy class"
  },
  {
    featureId: "EF017983",
    featureDesc: "Accuracy limit factor",
    label: "EF017983: Accuracy limit factor"
  },
  {
    featureId: "EF017984",
    featureDesc: "Rated extended primary current factor",
    label: "EF017984: Rated extended primary current factor"
  },
  {
    featureId: "EF017985",
    featureDesc: "Rated burden",
    label: "EF017985: Rated burden"
  },
  {
    featureId: "EF017986",
    featureDesc: "Rated primary voltage",
    label: "EF017986: Rated primary voltage"
  },
  {
    featureId: "EF018021",
    featureDesc: "With interface shaft",
    label: "EF018021: With interface shaft"
  },
  {
    featureId: "EF018024",
    featureDesc: "Rated primary current of application",
    label: "EF018024: Rated primary current of application"
  },
  {
    featureId: "EF018025",
    featureDesc: "Rated primary voltage of application",
    label: "EF018025: Rated primary voltage of application"
  },
  {
    featureId: "EF018026",
    featureDesc: "Rated short-time thermal current for 1s",
    label: "EF018026: Rated short-time thermal current for 1s"
  },
  {
    featureId: "EF018027",
    featureDesc: "Rated short-time thermal current for 3s",
    label: "EF018027: Rated short-time thermal current for 3s"
  },
  {
    featureId: "EF018028",
    featureDesc: "Rated transformation ratio for current measurement (at 50Hz)",
    label: "EF018028: Rated transformation ratio for current measurement (at 50Hz)"
  },
  {
    featureId: "EF018029",
    featureDesc: "Rated transformation ratio for current measurement (at 60Hz)",
    label: "EF018029: Rated transformation ratio for current measurement (at 60Hz)"
  },
  {
    featureId: "EF018030",
    featureDesc: "Rated transformation ratio for voltage measurement",
    label: "EF018030: Rated transformation ratio for voltage measurement"
  },
  {
    featureId: "EF018031",
    featureDesc: "Electrical endurance cycles according to IEC 60947 AC3",
    label: "EF018031: Electrical endurance cycles according to IEC 60947 AC3"
  },
  {
    featureId: "EF018032",
    featureDesc: "Suitable for gypsum mortar",
    label: "EF018032: Suitable for gypsum mortar"
  },
  {
    featureId: "EF018033",
    featureDesc: "Suitable for cement mortar",
    label: "EF018033: Suitable for cement mortar"
  },
  {
    featureId: "EF018034",
    featureDesc: "Suitable for concrete mortar",
    label: "EF018034: Suitable for concrete mortar"
  },
  {
    featureId: "EF018035",
    featureDesc: "Suitable for lime mortar",
    label: "EF018035: Suitable for lime mortar"
  },
  {
    featureId: "EF018036",
    featureDesc: "Air entrainer/defoamer",
    label: "EF018036: Air entrainer/defoamer"
  },
  {
    featureId: "EF018037",
    featureDesc: "Retarder",
    label: "EF018037: Retarder"
  },
  {
    featureId: "EF018038",
    featureDesc: "Accelerator",
    label: "EF018038: Accelerator"
  },
  {
    featureId: "EF018039",
    featureDesc: "Plasticizer",
    label: "EF018039: Plasticizer"
  },
  {
    featureId: "EF018041",
    featureDesc: "Viscosity enhancer",
    label: "EF018041: Viscosity enhancer"
  },
  {
    featureId: "EF018042",
    featureDesc: "Colourant",
    label: "EF018042: Colourant"
  },
  {
    featureId: "EF018043",
    featureDesc: "Lime replacement additive",
    label: "EF018043: Lime replacement additive"
  },
  {
    featureId: "EF018044",
    featureDesc: "Waterproofing additive",
    label: "EF018044: Waterproofing additive"
  },
  {
    featureId: "EF018045",
    featureDesc: "Elastifyer",
    label: "EF018045: Elastifyer"
  },
  {
    featureId: "EF018047",
    featureDesc: "Bonding agent",
    label: "EF018047: Bonding agent"
  },
  {
    featureId: "EF018049",
    featureDesc: "Strength enhancer",
    label: "EF018049: Strength enhancer"
  },
  {
    featureId: "EF018050",
    featureDesc: "Approved according to EN 934-2",
    label: "EF018050: Approved according to EN 934-2"
  },
  {
    featureId: "EF018052",
    featureDesc: "Water reducing additive",
    label: "EF018052: Water reducing additive"
  },
  {
    featureId: "EF018054",
    featureDesc: "Mixing ratio to 100 % binder weight",
    label: "EF018054: Mixing ratio to 100 % binder weight"
  },
  {
    featureId: "EF018058",
    featureDesc: "Type of plug",
    label: "EF018058: Type of plug"
  },
  {
    featureId: "EF018059",
    featureDesc: "Mouse pad",
    label: "EF018059: Mouse pad"
  },
  {
    featureId: "EF018060",
    featureDesc: "Keyboard pad",
    label: "EF018060: Keyboard pad"
  },
  {
    featureId: "EF018061",
    featureDesc: "Drill capacity in steel",
    label: "EF018061: Drill capacity in steel"
  },
  {
    featureId: "EF018062",
    featureDesc: "Number of mechanical operation cycles electrically latched",
    label: "EF018062: Number of mechanical operation cycles electrically latched"
  },
  {
    featureId: "EF018063",
    featureDesc: "Max. voltage for equipment",
    label: "EF018063: Max. voltage for equipment"
  },
  {
    featureId: "EF018065",
    featureDesc: "Material sealing ring",
    label: "EF018065: Material sealing ring"
  },
  {
    featureId: "EF018066",
    featureDesc: "Material intermediate washer",
    label: "EF018066: Material intermediate washer"
  },
  {
    featureId: "EF018067",
    featureDesc: "Keyboard layout",
    label: "EF018067: Keyboard layout"
  },
  {
    featureId: "EF018068",
    featureDesc: "Drill tip size",
    label: "EF018068: Drill tip size"
  },
  {
    featureId: "EF018069",
    featureDesc: "Min. screwing depth in wood",
    label: "EF018069: Min. screwing depth in wood"
  },
  {
    featureId: "EF018071",
    featureDesc: "Profile treatment",
    label: "EF018071: Profile treatment"
  },
  {
    featureId: "EF018072",
    featureDesc: "Surface protection forend",
    label: "EF018072: Surface protection forend"
  },
  {
    featureId: "EF018073",
    featureDesc: "Surface protection lock case",
    label: "EF018073: Surface protection lock case"
  },
  {
    featureId: "EF018074",
    featureDesc: "Max. petrol cylinder capacity",
    label: "EF018074: Max. petrol cylinder capacity"
  },
  {
    featureId: "EF018075",
    featureDesc: "Max. diesel cylinder capacity",
    label: "EF018075: Max. diesel cylinder capacity"
  },
  {
    featureId: "EF018076",
    featureDesc: "Suitable for spray cans",
    label: "EF018076: Suitable for spray cans"
  },
  {
    featureId: "EF018078",
    featureDesc: "Suitable on gravel",
    label: "EF018078: Suitable on gravel"
  },
  {
    featureId: "EF018079",
    featureDesc: "Delay time measuring",
    label: "EF018079: Delay time measuring"
  },
  {
    featureId: "EF018080",
    featureDesc: "With decorative bead",
    label: "EF018080: With decorative bead"
  },
  {
    featureId: "EF018081",
    featureDesc: "Danish",
    label: "EF018081: Danish"
  },
  {
    featureId: "EF018082",
    featureDesc: "Estonian",
    label: "EF018082: Estonian"
  },
  {
    featureId: "EF018083",
    featureDesc: "Latvian",
    label: "EF018083: Latvian"
  },
  {
    featureId: "EF018084",
    featureDesc: "Lithuanian",
    label: "EF018084: Lithuanian"
  },
  {
    featureId: "EF018085",
    featureDesc: "Finnish",
    label: "EF018085: Finnish"
  },
  {
    featureId: "EF018086",
    featureDesc: "Hungarian",
    label: "EF018086: Hungarian"
  },
  {
    featureId: "EF018087",
    featureDesc: "Norwegian",
    label: "EF018087: Norwegian"
  },
  {
    featureId: "EF018088",
    featureDesc: "Polish",
    label: "EF018088: Polish"
  },
  {
    featureId: "EF018089",
    featureDesc: "Portuguese",
    label: "EF018089: Portuguese"
  },
  {
    featureId: "EF018090",
    featureDesc: "Slovenian",
    label: "EF018090: Slovenian"
  },
  {
    featureId: "EF018091",
    featureDesc: "Swedish",
    label: "EF018091: Swedish"
  },
  {
    featureId: "EF018092",
    featureDesc: "Safety glass according to EN 14428",
    label: "EF018092: Safety glass according to EN 14428"
  },
  {
    featureId: "EF018093",
    featureDesc: "Surface protection gutter",
    label: "EF018093: Surface protection gutter"
  },
  {
    featureId: "EF018094",
    featureDesc: "Gas flow rate",
    label: "EF018094: Gas flow rate"
  },
  {
    featureId: "EF018095",
    featureDesc: "Gas pressure",
    label: "EF018095: Gas pressure"
  },
  {
    featureId: "EF018098",
    featureDesc: "Cutting wheel exchangable",
    label: "EF018098: Cutting wheel exchangable"
  },
  {
    featureId: "EF018099",
    featureDesc: "Safe working load according to IEC 61537 with support spacing 9.0 m",
    label: "EF018099: Safe working load according to IEC 61537 with support spacing 9.0 m"
  },
  {
    featureId: "EF018100",
    featureDesc: "Safe working load according to IEC 61537 with support spacing 11.0 m",
    label: "EF018100: Safe working load according to IEC 61537 with support spacing 11.0 m"
  },
  {
    featureId: "EF018101",
    featureDesc: "Safe working load according to IEC 61537 with support spacing 12.0 m",
    label: "EF018101: Safe working load according to IEC 61537 with support spacing 12.0 m"
  },
  {
    featureId: "EF018102",
    featureDesc: "Suitable for application central air supply and -exhaust",
    label: "EF018102: Suitable for application central air supply and -exhaust"
  },
  {
    featureId: "EF018103",
    featureDesc: "Suitable for application in the exhaust gas area",
    label: "EF018103: Suitable for application in the exhaust gas area"
  },
  {
    featureId: "EF018104",
    featureDesc: "Suitable for cable protection",
    label: "EF018104: Suitable for cable protection"
  },
  {
    featureId: "EF018107",
    featureDesc: "Suitable according to EN 62353",
    label: "EF018107: Suitable according to EN 62353"
  },
  {
    featureId: "EF018110",
    featureDesc: "Anti-glare screen",
    label: "EF018110: Anti-glare screen"
  },
  {
    featureId: "EF018111",
    featureDesc: "Cable guide head diameter",
    label: "EF018111: Cable guide head diameter"
  },
  {
    featureId: "EF018112",
    featureDesc: "Screw mounting in metal",
    label: "EF018112: Screw mounting in metal"
  },
  {
    featureId: "EF018113",
    featureDesc: "Screw mounting in wood",
    label: "EF018113: Screw mounting in wood"
  },
  {
    featureId: "EF018119",
    featureDesc: "With protective cap",
    label: "EF018119: With protective cap"
  },
  {
    featureId: "EF018121",
    featureDesc: "Supporting protocol for HSR",
    label: "EF018121: Supporting protocol for HSR"
  },
  {
    featureId: "EF018122",
    featureDesc: "Supporting protocol for PRP",
    label: "EF018122: Supporting protocol for PRP"
  },
  {
    featureId: "EF018123",
    featureDesc: "Supporting protocol for RSTP",
    label: "EF018123: Supporting protocol for RSTP"
  },
  {
    featureId: "EF018124",
    featureDesc: "Supporting protocol for PTP",
    label: "EF018124: Supporting protocol for PTP"
  },
  {
    featureId: "EF018126",
    featureDesc: "Impact strength class according to EN 61386-1",
    label: "EF018126: Impact strength class according to EN 61386-1"
  },
  {
    featureId: "EF018127",
    featureDesc: "Compressive strength class according to EN 61386-1",
    label: "EF018127: Compressive strength class according to EN 61386-1"
  },
  {
    featureId: "EF018128",
    featureDesc: "Number of cylindrical fuse holders",
    label: "EF018128: Number of cylindrical fuse holders"
  },
  {
    featureId: "EF018129",
    featureDesc: "Centre distance tap connection",
    label: "EF018129: Centre distance tap connection"
  },
  {
    featureId: "EF018130",
    featureDesc: "With sliding lid",
    label: "EF018130: With sliding lid"
  },
  {
    featureId: "EF018131",
    featureDesc: "Insert direction connection 1",
    label: "EF018131: Insert direction connection 1"
  },
  {
    featureId: "EF018132",
    featureDesc: "Insert direction connection 2",
    label: "EF018132: Insert direction connection 2"
  },
  {
    featureId: "EF018133",
    featureDesc: "WirelessHART",
    label: "EF018133: WirelessHART"
  },
  {
    featureId: "EF018134",
    featureDesc: "User adjustment of CO2 measurement",
    label: "EF018134: User adjustment of CO2 measurement"
  },
  {
    featureId: "EF018135",
    featureDesc: "Shelf thickness",
    label: "EF018135: Shelf thickness"
  },
  {
    featureId: "EF018136",
    featureDesc: "Suitable for glass shelves",
    label: "EF018136: Suitable for glass shelves"
  },
  {
    featureId: "EF018137",
    featureDesc: "Suitable for wood shelves",
    label: "EF018137: Suitable for wood shelves"
  },
  {
    featureId: "EF018138",
    featureDesc: "Tested according to EN 16337",
    label: "EF018138: Tested according to EN 16337"
  },
  {
    featureId: "EF018139",
    featureDesc: "With control/programming buttons",
    label: "EF018139: With control/programming buttons"
  },
  {
    featureId: "EF018141",
    featureDesc: "Number of brakes",
    label: "EF018141: Number of brakes"
  },
  {
    featureId: "EF018142",
    featureDesc: "With swivel wheels",
    label: "EF018142: With swivel wheels"
  },
  {
    featureId: "EF018143",
    featureDesc: "Overhang length",
    label: "EF018143: Overhang length"
  },
  {
    featureId: "EF018144",
    featureDesc: "Dimming Bluetooth",
    label: "EF018144: Dimming Bluetooth"
  },
  {
    featureId: "EF018145",
    featureDesc: "Dimming Wi-Fi",
    label: "EF018145: Dimming Wi-Fi"
  },
  {
    featureId: "EF018147",
    featureDesc: "CCA operating range according to SAE",
    label: "EF018147: CCA operating range according to SAE"
  },
  {
    featureId: "EF018149",
    featureDesc: "CCA operating range according to IEC",
    label: "EF018149: CCA operating range according to IEC"
  },
  {
    featureId: "EF018151",
    featureDesc: "Reinforced concrete",
    label: "EF018151: Reinforced concrete"
  },
  {
    featureId: "EF018152",
    featureDesc: "Max. side profile thickness drawer",
    label: "EF018152: Max. side profile thickness drawer"
  },
  {
    featureId: "EF018154",
    featureDesc: "Suitable for network",
    label: "EF018154: Suitable for network"
  },
  {
    featureId: "EF018155",
    featureDesc: "Shoe size (Europe)",
    label: "EF018155: Shoe size (Europe)"
  },
  {
    featureId: "EF018156",
    featureDesc: "Shoe size (UK)",
    label: "EF018156: Shoe size (UK)"
  },
  {
    featureId: "EF018157",
    featureDesc: "Shoe size (US)",
    label: "EF018157: Shoe size (US)"
  },
  {
    featureId: "EF018159",
    featureDesc: "Type of lime",
    label: "EF018159: Type of lime"
  },
  {
    featureId: "EF018160",
    featureDesc: "Moisture resistance according to EN 520",
    label: "EF018160: Moisture resistance according to EN 520"
  },
  {
    featureId: "EF018161",
    featureDesc: "Sensor controlled air flow",
    label: "EF018161: Sensor controlled air flow"
  },
  {
    featureId: "EF018162",
    featureDesc: "Suitable for smoke and heat exhaust ventilation (SHEV) system",
    label: "EF018162: Suitable for smoke and heat exhaust ventilation (SHEV) system"
  },
  {
    featureId: "EF018163",
    featureDesc: "Leaf width",
    label: "EF018163: Leaf width"
  },
  {
    featureId: "EF018164",
    featureDesc: "Rated blind power at 400 V, 60 Hz",
    label: "EF018164: Rated blind power at 400 V, 60 Hz"
  },
  {
    featureId: "EF018165",
    featureDesc: "Forend angled",
    label: "EF018165: Forend angled"
  },
  {
    featureId: "EF018166",
    featureDesc: "Suitable as lock for emergency exit doors",
    label: "EF018166: Suitable as lock for emergency exit doors"
  },
  {
    featureId: "EF018168",
    featureDesc: "Fuel- and oil resistant",
    label: "EF018168: Fuel- and oil resistant"
  },
  {
    featureId: "EF018169",
    featureDesc: "Repetition frequency settable",
    label: "EF018169: Repetition frequency settable"
  },
  {
    featureId: "EF018170",
    featureDesc: "Current setting undelayed short-circuit release",
    label: "EF018170: Current setting undelayed short-circuit release"
  },
  {
    featureId: "EF018171",
    featureDesc: "Current setting delayed short-circuit release",
    label: "EF018171: Current setting delayed short-circuit release"
  },
  {
    featureId: "EF018172",
    featureDesc: "Type of shower wall",
    label: "EF018172: Type of shower wall"
  },
  {
    featureId: "EF018173",
    featureDesc: "Configured for one way switching",
    label: "EF018173: Configured for one way switching"
  },
  {
    featureId: "EF018174",
    featureDesc: "Configured for two-way switching",
    label: "EF018174: Configured for two-way switching"
  },
  {
    featureId: "EF018175",
    featureDesc: "Configured for double pole single through switching",
    label: "EF018175: Configured for double pole single through switching"
  },
  {
    featureId: "EF018176",
    featureDesc: "Configured for intermediate switching",
    label: "EF018176: Configured for intermediate switching"
  },
  {
    featureId: "EF018177",
    featureDesc: "Configured for multiway switching",
    label: "EF018177: Configured for multiway switching"
  },
  {
    featureId: "EF018178",
    featureDesc: "Configured for series switching",
    label: "EF018178: Configured for series switching"
  },
  {
    featureId: "EF018179",
    featureDesc: "Duration license",
    label: "EF018179: Duration license"
  },
  {
    featureId: "EF018180",
    featureDesc: "Bit size Phillips",
    label: "EF018180: Bit size Phillips"
  },
  {
    featureId: "EF018181",
    featureDesc: "Core diameter",
    label: "EF018181: Core diameter"
  },
  {
    featureId: "EF020000",
    featureDesc: "Density",
    label: "EF020000: Density"
  },
  {
    featureId: "EF020001",
    featureDesc: "Outlet pattern",
    label: "EF020001: Outlet pattern"
  },
  {
    featureId: "EF020002",
    featureDesc: "Zeta value",
    label: "EF020002: Zeta value"
  },
  {
    featureId: "EF020003",
    featureDesc: "Material heat exchanger",
    label: "EF020003: Material heat exchanger"
  },
  {
    featureId: "EF020004",
    featureDesc: "Roof pitch",
    label: "EF020004: Roof pitch"
  },
  {
    featureId: "EF020005",
    featureDesc: "With drill jig",
    label: "EF020005: With drill jig"
  },
  {
    featureId: "EF020006",
    featureDesc: "Heating capacity adjustable",
    label: "EF020006: Heating capacity adjustable"
  },
  {
    featureId: "EF020007",
    featureDesc: "Article compression class",
    label: "EF020007: Article compression class"
  },
  {
    featureId: "EF020008",
    featureDesc: "Built-in draught limiting device",
    label: "EF020008: Built-in draught limiting device"
  },
  {
    featureId: "EF020009",
    featureDesc: "Length above roof (including cover)",
    label: "EF020009: Length above roof (including cover)"
  },
  {
    featureId: "EF020010",
    featureDesc: "Sealing element",
    label: "EF020010: Sealing element"
  },
  {
    featureId: "EF020011",
    featureDesc: "With gasket",
    label: "EF020011: With gasket"
  },
  {
    featureId: "EF020012",
    featureDesc: "With automatic de-aerator",
    label: "EF020012: With automatic de-aerator"
  },
  {
    featureId: "EF020013",
    featureDesc: "Nominal diameter cold tap water",
    label: "EF020013: Nominal diameter cold tap water"
  },
  {
    featureId: "EF020014",
    featureDesc: "Welded-on strips",
    label: "EF020014: Welded-on strips"
  },
  {
    featureId: "EF020015",
    featureDesc: "Material membrane",
    label: "EF020015: Material membrane"
  },
  {
    featureId: "EF020016",
    featureDesc: "Pre-pressure",
    label: "EF020016: Pre-pressure"
  },
  {
    featureId: "EF020017",
    featureDesc: "Thermal conductivity",
    label: "EF020017: Thermal conductivity"
  },
  {
    featureId: "EF020018",
    featureDesc: "Number of boilers",
    label: "EF020018: Number of boilers"
  },
  {
    featureId: "EF020019",
    featureDesc: "Programme clock",
    label: "EF020019: Programme clock"
  },
  {
    featureId: "EF020020",
    featureDesc: "Nominal diameter",
    label: "EF020020: Nominal diameter"
  },
  {
    featureId: "EF020021",
    featureDesc: "Construction length",
    label: "EF020021: Construction length"
  },
  {
    featureId: "EF020023",
    featureDesc: "Max. medium temperature (continuous)",
    label: "EF020023: Max. medium temperature (continuous)"
  },
  {
    featureId: "EF020025",
    featureDesc: "Spindle and/or hand wheel rising",
    label: "EF020025: Spindle and/or hand wheel rising"
  },
  {
    featureId: "EF020028",
    featureDesc: "Capped",
    label: "EF020028: Capped"
  },
  {
    featureId: "EF020029",
    featureDesc: "Wall/partition/floor thickness",
    label: "EF020029: Wall/partition/floor thickness"
  },
  {
    featureId: "EF020030",
    featureDesc: "Gastight",
    label: "EF020030: Gastight"
  },
  {
    featureId: "EF020031",
    featureDesc: "Sound-absorbing",
    label: "EF020031: Sound-absorbing"
  },
  {
    featureId: "EF020032",
    featureDesc: "Airtight",
    label: "EF020032: Airtight"
  },
  {
    featureId: "EF020033",
    featureDesc: "Stench-tight",
    label: "EF020033: Stench-tight"
  },
  {
    featureId: "EF020034",
    featureDesc: "Tap threshold",
    label: "EF020034: Tap threshold"
  },
  {
    featureId: "EF020035",
    featureDesc: "Built-in pump circuit",
    label: "EF020035: Built-in pump circuit"
  },
  {
    featureId: "EF020036",
    featureDesc: "Time delay",
    label: "EF020036: Time delay"
  },
  {
    featureId: "EF020038",
    featureDesc: "Self-priming",
    label: "EF020038: Self-priming"
  },
  {
    featureId: "EF020041",
    featureDesc: "With dust cap",
    label: "EF020041: With dust cap"
  },
  {
    featureId: "EF020042",
    featureDesc: "Bend radius",
    label: "EF020042: Bend radius"
  },
  {
    featureId: "EF020043",
    featureDesc: "Bend radius (number x D)",
    label: "EF020043: Bend radius (number x D)"
  },
  {
    featureId: "EF020045",
    featureDesc: "Material inlay",
    label: "EF020045: Material inlay"
  },
  {
    featureId: "EF020046",
    featureDesc: "Pipe type",
    label: "EF020046: Pipe type"
  },
  {
    featureId: "EF020047",
    featureDesc: "With sealing rings/gaskets",
    label: "EF020047: With sealing rings/gaskets"
  },
  {
    featureId: "EF020048",
    featureDesc: "With counterflanges",
    label: "EF020048: With counterflanges"
  },
  {
    featureId: "EF020050",
    featureDesc: "With compression set",
    label: "EF020050: With compression set"
  },
  {
    featureId: "EF020052",
    featureDesc: "Nominal diameter connection 2",
    label: "EF020052: Nominal diameter connection 2"
  },
  {
    featureId: "EF020053",
    featureDesc: "Nominal diameter connection 3",
    label: "EF020053: Nominal diameter connection 3"
  },
  {
    featureId: "EF020054",
    featureDesc: "Nominal diameter connection 4",
    label: "EF020054: Nominal diameter connection 4"
  },
  {
    featureId: "EF020055",
    featureDesc: "Eccentric",
    label: "EF020055: Eccentric"
  },
  {
    featureId: "EF020057",
    featureDesc: "DIN-rail mounting",
    label: "EF020057: DIN-rail mounting"
  },
  {
    featureId: "EF020058",
    featureDesc: "Outer connection diameter",
    label: "EF020058: Outer connection diameter"
  },
  {
    featureId: "EF020059",
    featureDesc: "Bellows seal",
    label: "EF020059: Bellows seal"
  },
  {
    featureId: "EF020064",
    featureDesc: "Outer diameter, flanged bush",
    label: "EF020064: Outer diameter, flanged bush"
  },
  {
    featureId: "EF020065",
    featureDesc: "Connection 3",
    label: "EF020065: Connection 3"
  },
  {
    featureId: "EF020066",
    featureDesc: "Connection 4",
    label: "EF020066: Connection 4"
  },
  {
    featureId: "EF020067",
    featureDesc: "Connection 5",
    label: "EF020067: Connection 5"
  },
  {
    featureId: "EF020068",
    featureDesc: "KOMO certified",
    label: "EF020068: KOMO certified"
  },
  {
    featureId: "EF020069",
    featureDesc: "Kema certified",
    label: "EF020069: Kema certified"
  },
  {
    featureId: "EF020071",
    featureDesc: "Gas quality mark VR/SV",
    label: "EF020071: Gas quality mark VR/SV"
  },
  {
    featureId: "EF020073",
    featureDesc: "CW label",
    label: "EF020073: CW label"
  },
  {
    featureId: "EF020074",
    featureDesc: "Gastec QA",
    label: "EF020074: Gastec QA"
  },
  {
    featureId: "EF020075",
    featureDesc: "KIWA certified",
    label: "EF020075: KIWA certified"
  },
  {
    featureId: "EF020076",
    featureDesc: "Permitted by NCP",
    label: "EF020076: Permitted by NCP"
  },
  {
    featureId: "EF020078",
    featureDesc: "With drain valve",
    label: "EF020078: With drain valve"
  },
  {
    featureId: "EF020079",
    featureDesc: "With de-aerator",
    label: "EF020079: With de-aerator"
  },
  {
    featureId: "EF020080",
    featureDesc: "With stop rim",
    label: "EF020080: With stop rim"
  },
  {
    featureId: "EF020081",
    featureDesc: "Boiler system",
    label: "EF020081: Boiler system"
  },
  {
    featureId: "EF020082",
    featureDesc: "Supplied in assembled form",
    label: "EF020082: Supplied in assembled form"
  },
  {
    featureId: "EF020083",
    featureDesc: "Position",
    label: "EF020083: Position"
  },
  {
    featureId: "EF020086",
    featureDesc: "Nominal load lower value",
    label: "EF020086: Nominal load lower value"
  },
  {
    featureId: "EF020087",
    featureDesc: "Nominal load upper value",
    label: "EF020087: Nominal load upper value"
  },
  {
    featureId: "EF020088",
    featureDesc: "Min. operating pressure",
    label: "EF020088: Min. operating pressure"
  },
  {
    featureId: "EF020089",
    featureDesc: "Min. return temperature",
    label: "EF020089: Min. return temperature"
  },
  {
    featureId: "EF020090",
    featureDesc: "Max. gas pre-pressure",
    label: "EF020090: Max. gas pre-pressure"
  },
  {
    featureId: "EF020092",
    featureDesc: "Hot tap water capacity at 60 °C",
    label: "EF020092: Hot tap water capacity at 60 °C"
  },
  {
    featureId: "EF020093",
    featureDesc: "Fuel type",
    label: "EF020093: Fuel type"
  },
  {
    featureId: "EF020094",
    featureDesc: "With built-in circulation pump",
    label: "EF020094: With built-in circulation pump"
  },
  {
    featureId: "EF020096",
    featureDesc: "Built-in weather-dependent controller",
    label: "EF020096: Built-in weather-dependent controller"
  },
  {
    featureId: "EF020097",
    featureDesc: "Built-in expansion vessel",
    label: "EF020097: Built-in expansion vessel"
  },
  {
    featureId: "EF020098",
    featureDesc: "Water-side resistance at dT 20 °C",
    label: "EF020098: Water-side resistance at dT 20 °C"
  },
  {
    featureId: "EF020099",
    featureDesc: "With sleeve",
    label: "EF020099: With sleeve"
  },
  {
    featureId: "EF020100",
    featureDesc: "Max. inlet pressure",
    label: "EF020100: Max. inlet pressure"
  },
  {
    featureId: "EF020102",
    featureDesc: "With soft seal",
    label: "EF020102: With soft seal"
  },
  {
    featureId: "EF020103",
    featureDesc: "Self-closing",
    label: "EF020103: Self-closing"
  },
  {
    featureId: "EF020104",
    featureDesc: "With extended measuring nipples",
    label: "EF020104: With extended measuring nipples"
  },
  {
    featureId: "EF020105",
    featureDesc: "Thermostatic",
    label: "EF020105: Thermostatic"
  },
  {
    featureId: "EF020106",
    featureDesc: "With closure",
    label: "EF020106: With closure"
  },
  {
    featureId: "EF020108",
    featureDesc: "Adjustment element",
    label: "EF020108: Adjustment element"
  },
  {
    featureId: "EF020109",
    featureDesc: "Remote sensor",
    label: "EF020109: Remote sensor"
  },
  {
    featureId: "EF020110",
    featureDesc: "Frost protected",
    label: "EF020110: Frost protected"
  },
  {
    featureId: "EF020111",
    featureDesc: "Mechanically lockable at zero (off) position",
    label: "EF020111: Mechanically lockable at zero (off) position"
  },
  {
    featureId: "EF020114",
    featureDesc: "Length of capillary",
    label: "EF020114: Length of capillary"
  },
  {
    featureId: "EF020115",
    featureDesc: "Battery power supply",
    label: "EF020115: Battery power supply"
  },
  {
    featureId: "EF020116",
    featureDesc: "Freeflow",
    label: "EF020116: Freeflow"
  },
  {
    featureId: "EF020117",
    featureDesc: "Max. boiler capacity",
    label: "EF020117: Max. boiler capacity"
  },
  {
    featureId: "EF020118",
    featureDesc: "Exchangeable non-return valve",
    label: "EF020118: Exchangeable non-return valve"
  },
  {
    featureId: "EF020119",
    featureDesc: "Stop valve",
    label: "EF020119: Stop valve"
  },
  {
    featureId: "EF020120",
    featureDesc: "Type of speed control",
    label: "EF020120: Type of speed control"
  },
  {
    featureId: "EF020121",
    featureDesc: "Motor speed control",
    label: "EF020121: Motor speed control"
  },
  {
    featureId: "EF020122",
    featureDesc: "Outer pipe diameter connection 1",
    label: "EF020122: Outer pipe diameter connection 1"
  },
  {
    featureId: "EF020123",
    featureDesc: "Outer pipe diameter connection 2",
    label: "EF020123: Outer pipe diameter connection 2"
  },
  {
    featureId: "EF020124",
    featureDesc: "Outer pipe diameter connection 3",
    label: "EF020124: Outer pipe diameter connection 3"
  },
  {
    featureId: "EF020125",
    featureDesc: "Outer pipe diameter connection 4",
    label: "EF020125: Outer pipe diameter connection 4"
  },
  {
    featureId: "EF020126",
    featureDesc: "Outer pipe diameter all connections",
    label: "EF020126: Outer pipe diameter all connections"
  },
  {
    featureId: "EF020130",
    featureDesc: "Dehumidification capacity",
    label: "EF020130: Dehumidification capacity"
  },
  {
    featureId: "EF020136",
    featureDesc: "Nominal diameter suction pipe",
    label: "EF020136: Nominal diameter suction pipe"
  },
  {
    featureId: "EF020139",
    featureDesc: "Outer pipe diameter suction pipe",
    label: "EF020139: Outer pipe diameter suction pipe"
  },
  {
    featureId: "EF020142",
    featureDesc: "Outer pipe diameter heating",
    label: "EF020142: Outer pipe diameter heating"
  },
  {
    featureId: "EF020148",
    featureDesc: "With coupling connection 3",
    label: "EF020148: With coupling connection 3"
  },
  {
    featureId: "EF020149",
    featureDesc: "Flange compression class",
    label: "EF020149: Flange compression class"
  },
  {
    featureId: "EF020150",
    featureDesc: "Max. pressure difference at 20 °C",
    label: "EF020150: Max. pressure difference at 20 °C"
  },
  {
    featureId: "EF020151",
    featureDesc: "Length of connection 1",
    label: "EF020151: Length of connection 1"
  },
  {
    featureId: "EF020152",
    featureDesc: "Length of connection 2",
    label: "EF020152: Length of connection 2"
  },
  {
    featureId: "EF020153",
    featureDesc: "Working length connection 1",
    label: "EF020153: Working length connection 1"
  },
  {
    featureId: "EF020154",
    featureDesc: "Working length connection 2",
    label: "EF020154: Working length connection 2"
  },
  {
    featureId: "EF020155",
    featureDesc: "Handle/hand wheel height",
    label: "EF020155: Handle/hand wheel height"
  },
  {
    featureId: "EF020156",
    featureDesc: "Length of handle/diameter of hand wheel",
    label: "EF020156: Length of handle/diameter of hand wheel"
  },
  {
    featureId: "EF020157",
    featureDesc: "Angle of the valve",
    label: "EF020157: Angle of the valve"
  },
  {
    featureId: "EF020158",
    featureDesc: "Spindle angle",
    label: "EF020158: Spindle angle"
  },
  {
    featureId: "EF020159",
    featureDesc: "Length of connection 3",
    label: "EF020159: Length of connection 3"
  },
  {
    featureId: "EF020160",
    featureDesc: "Working length connection 3",
    label: "EF020160: Working length connection 3"
  },
  {
    featureId: "EF020162",
    featureDesc: "Housing construction",
    label: "EF020162: Housing construction"
  },
  {
    featureId: "EF020163",
    featureDesc: "Full bore",
    label: "EF020163: Full bore"
  },
  {
    featureId: "EF020164",
    featureDesc: "Handle height",
    label: "EF020164: Handle height"
  },
  {
    featureId: "EF020165",
    featureDesc: "Handgrip length",
    label: "EF020165: Handgrip length"
  },
  {
    featureId: "EF020166",
    featureDesc: "Side 1 - side 2 angle",
    label: "EF020166: Side 1 - side 2 angle"
  },
  {
    featureId: "EF020167",
    featureDesc: "Side 1 - side 3 angle",
    label: "EF020167: Side 1 - side 3 angle"
  },
  {
    featureId: "EF020168",
    featureDesc: "Working length connection 4",
    label: "EF020168: Working length connection 4"
  },
  {
    featureId: "EF020169",
    featureDesc: "Length of connection 4",
    label: "EF020169: Length of connection 4"
  },
  {
    featureId: "EF020170",
    featureDesc: "With coupling connection 4",
    label: "EF020170: With coupling connection 4"
  },
  {
    featureId: "EF020171",
    featureDesc: "Housing lining",
    label: "EF020171: Housing lining"
  },
  {
    featureId: "EF020172",
    featureDesc: "Max. pressure difference as end valve",
    label: "EF020172: Max. pressure difference as end valve"
  },
  {
    featureId: "EF020173",
    featureDesc: "Passage",
    label: "EF020173: Passage"
  },
  {
    featureId: "EF020174",
    featureDesc: "Plug passage",
    label: "EF020174: Plug passage"
  },
  {
    featureId: "EF020175",
    featureDesc: "Handle/spindle height",
    label: "EF020175: Handle/spindle height"
  },
  {
    featureId: "EF020176",
    featureDesc: "Length of key/handle",
    label: "EF020176: Length of key/handle"
  },
  {
    featureId: "EF020177",
    featureDesc: "Connection 1 - connection 2 angle",
    label: "EF020177: Connection 1 - connection 2 angle"
  },
  {
    featureId: "EF020178",
    featureDesc: "Connection 1 - connection 3 angle",
    label: "EF020178: Connection 1 - connection 3 angle"
  },
  {
    featureId: "EF020180",
    featureDesc: "Material lock pipe",
    label: "EF020180: Material lock pipe"
  },
  {
    featureId: "EF020181",
    featureDesc: "Ground cover",
    label: "EF020181: Ground cover"
  },
  {
    featureId: "EF020182",
    featureDesc: "Valve bore (DN..)",
    label: "EF020182: Valve bore (DN..)"
  },
  {
    featureId: "EF020184",
    featureDesc: "Label",
    label: "EF020184: Label"
  },
  {
    featureId: "EF020185",
    featureDesc: "With operating key",
    label: "EF020185: With operating key"
  },
  {
    featureId: "EF020186",
    featureDesc: "With coupling sleeve",
    label: "EF020186: With coupling sleeve"
  },
  {
    featureId: "EF020187",
    featureDesc: "Column base diameter",
    label: "EF020187: Column base diameter"
  },
  {
    featureId: "EF020188",
    featureDesc: "Max. ambient temperature",
    label: "EF020188: Max. ambient temperature"
  },
  {
    featureId: "EF020189",
    featureDesc: "Min. pressure difference",
    label: "EF020189: Min. pressure difference"
  },
  {
    featureId: "EF020190",
    featureDesc: "Indirect effect",
    label: "EF020190: Indirect effect"
  },
  {
    featureId: "EF020192",
    featureDesc: "Deactivated when open",
    label: "EF020192: Deactivated when open"
  },
  {
    featureId: "EF020193",
    featureDesc: "With measuring connector",
    label: "EF020193: With measuring connector"
  },
  {
    featureId: "EF020194",
    featureDesc: "Sealing unit",
    label: "EF020194: Sealing unit"
  },
  {
    featureId: "EF020195",
    featureDesc: "Axle height",
    label: "EF020195: Axle height"
  },
  {
    featureId: "EF020196",
    featureDesc: "Operation using hand wheel",
    label: "EF020196: Operation using hand wheel"
  },
  {
    featureId: "EF020197",
    featureDesc: "Column height",
    label: "EF020197: Column height"
  },
  {
    featureId: "EF020200",
    featureDesc: "Distance centre-to-centre",
    label: "EF020200: Distance centre-to-centre"
  },
  {
    featureId: "EF020201",
    featureDesc: "Nominal diameter connection 5",
    label: "EF020201: Nominal diameter connection 5"
  },
  {
    featureId: "EF020202",
    featureDesc: "Outer pipe diameter connection 5",
    label: "EF020202: Outer pipe diameter connection 5"
  },
  {
    featureId: "EF020203",
    featureDesc: "Length of connection 5",
    label: "EF020203: Length of connection 5"
  },
  {
    featureId: "EF020204",
    featureDesc: "Working length connection 5",
    label: "EF020204: Working length connection 5"
  },
  {
    featureId: "EF020205",
    featureDesc: "K-factor, nominal bore",
    label: "EF020205: K-factor, nominal bore"
  },
  {
    featureId: "EF020206",
    featureDesc: "Response temperature",
    label: "EF020206: Response temperature"
  },
  {
    featureId: "EF020207",
    featureDesc: "Concealed",
    label: "EF020207: Concealed"
  },
  {
    featureId: "EF020208",
    featureDesc: "Outer pipe diameter connection",
    label: "EF020208: Outer pipe diameter connection"
  },
  {
    featureId: "EF020209",
    featureDesc: "Rosette",
    label: "EF020209: Rosette"
  },
  {
    featureId: "EF020211",
    featureDesc: "Ball bore",
    label: "EF020211: Ball bore"
  },
  {
    featureId: "EF020217",
    featureDesc: "With buffer stud",
    label: "EF020217: With buffer stud"
  },
  {
    featureId: "EF020223",
    featureDesc: "Wall thickness pipe",
    label: "EF020223: Wall thickness pipe"
  },
  {
    featureId: "EF020224",
    featureDesc: "With de-aeration connection",
    label: "EF020224: With de-aeration connection"
  },
  {
    featureId: "EF020225",
    featureDesc: "Thickness flange",
    label: "EF020225: Thickness flange"
  },
  {
    featureId: "EF020226",
    featureDesc: "Inner diameter of push-on flange",
    label: "EF020226: Inner diameter of push-on flange"
  },
  {
    featureId: "EF020228",
    featureDesc: "Outer diameter, flange edge",
    label: "EF020228: Outer diameter, flange edge"
  },
  {
    featureId: "EF020230",
    featureDesc: "Seamless",
    label: "EF020230: Seamless"
  },
  {
    featureId: "EF020231",
    featureDesc: "Precision pipe",
    label: "EF020231: Precision pipe"
  },
  {
    featureId: "EF020233",
    featureDesc: "Connection voltage",
    label: "EF020233: Connection voltage"
  },
  {
    featureId: "EF020234",
    featureDesc: "Connection 1 only for butt weld",
    label: "EF020234: Connection 1 only for butt weld"
  },
  {
    featureId: "EF020236",
    featureDesc: "Suitable for steel tube",
    label: "EF020236: Suitable for steel tube"
  },
  {
    featureId: "EF020237",
    featureDesc: "Suitable for copper tube",
    label: "EF020237: Suitable for copper tube"
  },
  {
    featureId: "EF020238",
    featureDesc: "Flexible connection facility",
    label: "EF020238: Flexible connection facility"
  },
  {
    featureId: "EF020239",
    featureDesc: "Connection facility",
    label: "EF020239: Connection facility"
  },
  {
    featureId: "EF020246",
    featureDesc: "Forced motor cooling",
    label: "EF020246: Forced motor cooling"
  },
  {
    featureId: "EF020259",
    featureDesc: "Outlet location",
    label: "EF020259: Outlet location"
  },
  {
    featureId: "EF020260",
    featureDesc: "Min. flushing water quantity",
    label: "EF020260: Min. flushing water quantity"
  },
  {
    featureId: "EF020261",
    featureDesc: "With thigh supports/fixed toilet seat",
    label: "EF020261: With thigh supports/fixed toilet seat"
  },
  {
    featureId: "EF020262",
    featureDesc: "Flange type",
    label: "EF020262: Flange type"
  },
  {
    featureId: "EF020263",
    featureDesc: "Connection, threaded flange",
    label: "EF020263: Connection, threaded flange"
  },
  {
    featureId: "EF020268",
    featureDesc: "Two-button flush",
    label: "EF020268: Two-button flush"
  },
  {
    featureId: "EF020270",
    featureDesc: "Sensor type",
    label: "EF020270: Sensor type"
  },
  {
    featureId: "EF020273",
    featureDesc: "Probe, internal",
    label: "EF020273: Probe, internal"
  },
  {
    featureId: "EF020274",
    featureDesc: "Setpoint adjustability",
    label: "EF020274: Setpoint adjustability"
  },
  {
    featureId: "EF020275",
    featureDesc: "Standby push button",
    label: "EF020275: Standby push button"
  },
  {
    featureId: "EF020276",
    featureDesc: "Feed pressure",
    label: "EF020276: Feed pressure"
  },
  {
    featureId: "EF020277",
    featureDesc: "With loose immersion tube",
    label: "EF020277: With loose immersion tube"
  },
  {
    featureId: "EF020278",
    featureDesc: "Material immersion tube",
    label: "EF020278: Material immersion tube"
  },
  {
    featureId: "EF020279",
    featureDesc: "Insert length",
    label: "EF020279: Insert length"
  },
  {
    featureId: "EF020280",
    featureDesc: "Housing height",
    label: "EF020280: Housing height"
  },
  {
    featureId: "EF020281",
    featureDesc: "Housing width",
    label: "EF020281: Housing width"
  },
  {
    featureId: "EF020282",
    featureDesc: "Housing depth",
    label: "EF020282: Housing depth"
  },
  {
    featureId: "EF020285",
    featureDesc: "Type of measuring element",
    label: "EF020285: Type of measuring element"
  },
  {
    featureId: "EF020286",
    featureDesc: "Max. contact voltage",
    label: "EF020286: Max. contact voltage"
  },
  {
    featureId: "EF020287",
    featureDesc: "Independently operating",
    label: "EF020287: Independently operating"
  },
  {
    featureId: "EF020288",
    featureDesc: "Suitable for front mounting",
    label: "EF020288: Suitable for front mounting"
  },
  {
    featureId: "EF020289",
    featureDesc: "Pump diameter",
    label: "EF020289: Pump diameter"
  },
  {
    featureId: "EF020290",
    featureDesc: "Pump and motor length",
    label: "EF020290: Pump and motor length"
  },
  {
    featureId: "EF020293",
    featureDesc: "With cutting system",
    label: "EF020293: With cutting system"
  },
  {
    featureId: "EF020294",
    featureDesc: "Connection size, connection facility",
    label: "EF020294: Connection size, connection facility"
  },
  {
    featureId: "EF020295",
    featureDesc: "Flush type",
    label: "EF020295: Flush type"
  },
  {
    featureId: "EF020298",
    featureDesc: "Combination height",
    label: "EF020298: Combination height"
  },
  {
    featureId: "EF020299",
    featureDesc: "Toilet height",
    label: "EF020299: Toilet height"
  },
  {
    featureId: "EF020300",
    featureDesc: "Reservoir operation",
    label: "EF020300: Reservoir operation"
  },
  {
    featureId: "EF020301",
    featureDesc: "Suitable for stainless steel tube",
    label: "EF020301: Suitable for stainless steel tube"
  },
  {
    featureId: "EF020302",
    featureDesc: "Suitable for aluminium tube",
    label: "EF020302: Suitable for aluminium tube"
  },
  {
    featureId: "EF020303",
    featureDesc: "Suitable for plastic tube",
    label: "EF020303: Suitable for plastic tube"
  },
  {
    featureId: "EF020304",
    featureDesc: "Suitable for pipe coil",
    label: "EF020304: Suitable for pipe coil"
  },
  {
    featureId: "EF020305",
    featureDesc: "Max. control pressure",
    label: "EF020305: Max. control pressure"
  },
  {
    featureId: "EF020306",
    featureDesc: "Min. control pressure",
    label: "EF020306: Min. control pressure"
  },
  {
    featureId: "EF020307",
    featureDesc: "Magnetic principle",
    label: "EF020307: Magnetic principle"
  },
  {
    featureId: "EF020309",
    featureDesc: "Built-on end switch in open position",
    label: "EF020309: Built-on end switch in open position"
  },
  {
    featureId: "EF020310",
    featureDesc: "Built-on end switch in closed position",
    label: "EF020310: Built-on end switch in closed position"
  },
  {
    featureId: "EF020311",
    featureDesc: "Nominal duct diameter",
    label: "EF020311: Nominal duct diameter"
  },
  {
    featureId: "EF020312",
    featureDesc: "Nominal duct width",
    label: "EF020312: Nominal duct width"
  },
  {
    featureId: "EF020313",
    featureDesc: "Nominal duct height",
    label: "EF020313: Nominal duct height"
  },
  {
    featureId: "EF020314",
    featureDesc: "Free passage",
    label: "EF020314: Free passage"
  },
  {
    featureId: "EF020315",
    featureDesc: "With inspection hatch",
    label: "EF020315: With inspection hatch"
  },
  {
    featureId: "EF020316",
    featureDesc: "Material inner hose",
    label: "EF020316: Material inner hose"
  },
  {
    featureId: "EF020317",
    featureDesc: "Material outer hose",
    label: "EF020317: Material outer hose"
  },
  {
    featureId: "EF020319",
    featureDesc: "Overflow pressure",
    label: "EF020319: Overflow pressure"
  },
  {
    featureId: "EF020320",
    featureDesc: "Suitable for continuous operation",
    label: "EF020320: Suitable for continuous operation"
  },
  {
    featureId: "EF020321",
    featureDesc: "Gastight valve",
    label: "EF020321: Gastight valve"
  },
  {
    featureId: "EF020322",
    featureDesc: "Spring cap, closed",
    label: "EF020322: Spring cap, closed"
  },
  {
    featureId: "EF020323",
    featureDesc: "Blow-off characteristic",
    label: "EF020323: Blow-off characteristic"
  },
  {
    featureId: "EF020324",
    featureDesc: "Angle of the device",
    label: "EF020324: Angle of the device"
  },
  {
    featureId: "EF020326",
    featureDesc: "Suitable for gases (incl. steam)",
    label: "EF020326: Suitable for gases (incl. steam)"
  },
  {
    featureId: "EF020327",
    featureDesc: "Blow-off pressure",
    label: "EF020327: Blow-off pressure"
  },
  {
    featureId: "EF020328",
    featureDesc: "Valve lifter",
    label: "EF020328: Valve lifter"
  },
  {
    featureId: "EF020329",
    featureDesc: "Angle of safety device",
    label: "EF020329: Angle of safety device"
  },
  {
    featureId: "EF020330",
    featureDesc: "Indirect weight load",
    label: "EF020330: Indirect weight load"
  },
  {
    featureId: "EF020331",
    featureDesc: "Lever length",
    label: "EF020331: Lever length"
  },
  {
    featureId: "EF020332",
    featureDesc: "Weight diameter",
    label: "EF020332: Weight diameter"
  },
  {
    featureId: "EF020334",
    featureDesc: "Suitable for water",
    label: "EF020334: Suitable for water"
  },
  {
    featureId: "EF020335",
    featureDesc: "Suitable for steam",
    label: "EF020335: Suitable for steam"
  },
  {
    featureId: "EF020336",
    featureDesc: "Suitable for compressed air",
    label: "EF020336: Suitable for compressed air"
  },
  {
    featureId: "EF020337",
    featureDesc: "Valve shape",
    label: "EF020337: Valve shape"
  },
  {
    featureId: "EF020338",
    featureDesc: "With lock spring",
    label: "EF020338: With lock spring"
  },
  {
    featureId: "EF020339",
    featureDesc: "Angle of non-return valve",
    label: "EF020339: Angle of non-return valve"
  },
  {
    featureId: "EF020341",
    featureDesc: "Drain location",
    label: "EF020341: Drain location"
  },
  {
    featureId: "EF020345",
    featureDesc: "Integrated arm supports",
    label: "EF020345: Integrated arm supports"
  },
  {
    featureId: "EF020346",
    featureDesc: "Number of persons",
    label: "EF020346: Number of persons"
  },
  {
    featureId: "EF020348",
    featureDesc: "With overflow",
    label: "EF020348: With overflow"
  },
  {
    featureId: "EF020349",
    featureDesc: "With aim target",
    label: "EF020349: With aim target"
  },
  {
    featureId: "EF020350",
    featureDesc: "Material casing",
    label: "EF020350: Material casing"
  },
  {
    featureId: "EF020351",
    featureDesc: "Tap capacity at 60 °C, continuous",
    label: "EF020351: Tap capacity at 60 °C, continuous"
  },
  {
    featureId: "EF020352",
    featureDesc: "Heat-up time from 10 °C to 65 °C",
    label: "EF020352: Heat-up time from 10 °C to 65 °C"
  },
  {
    featureId: "EF020353",
    featureDesc: "Double separation",
    label: "EF020353: Double separation"
  },
  {
    featureId: "EF020354",
    featureDesc: "Flame protection device",
    label: "EF020354: Flame protection device"
  },
  {
    featureId: "EF020356",
    featureDesc: "With cleaning opening",
    label: "EF020356: With cleaning opening"
  },
  {
    featureId: "EF020357",
    featureDesc: "Pilot light burner",
    label: "EF020357: Pilot light burner"
  },
  {
    featureId: "EF020358",
    featureDesc: "Protective anode",
    label: "EF020358: Protective anode"
  },
  {
    featureId: "EF020359",
    featureDesc: "Wall set-up",
    label: "EF020359: Wall set-up"
  },
  {
    featureId: "EF020360",
    featureDesc: "Material bath",
    label: "EF020360: Material bath"
  },
  {
    featureId: "EF020361",
    featureDesc: "Tap hole",
    label: "EF020361: Tap hole"
  },
  {
    featureId: "EF020363",
    featureDesc: "Freestanding",
    label: "EF020363: Freestanding"
  },
  {
    featureId: "EF020364",
    featureDesc: "Min. headroom",
    label: "EF020364: Min. headroom"
  },
  {
    featureId: "EF020365",
    featureDesc: "Mesh width of filter element",
    label: "EF020365: Mesh width of filter element"
  },
  {
    featureId: "EF020366",
    featureDesc: "With permanent magnet",
    label: "EF020366: With permanent magnet"
  },
  {
    featureId: "EF020367",
    featureDesc: "Material filter element",
    label: "EF020367: Material filter element"
  },
  {
    featureId: "EF020368",
    featureDesc: "Height above pipe",
    label: "EF020368: Height above pipe"
  },
  {
    featureId: "EF020369",
    featureDesc: "Height below pipe",
    label: "EF020369: Height below pipe"
  },
  {
    featureId: "EF020372",
    featureDesc: "Material switching plug",
    label: "EF020372: Material switching plug"
  },
  {
    featureId: "EF020373",
    featureDesc: "Automatic dirt removal",
    label: "EF020373: Automatic dirt removal"
  },
  {
    featureId: "EF020374",
    featureDesc: "Motor-controlled dirt scraper",
    label: "EF020374: Motor-controlled dirt scraper"
  },
  {
    featureId: "EF020375",
    featureDesc: "Filter pot, stainless steel",
    label: "EF020375: Filter pot, stainless steel"
  },
  {
    featureId: "EF020376",
    featureDesc: "Number of sealing rings, sleeve",
    label: "EF020376: Number of sealing rings, sleeve"
  },
  {
    featureId: "EF020377",
    featureDesc: "Pipe end type",
    label: "EF020377: Pipe end type"
  },
  {
    featureId: "EF020378",
    featureDesc: "With socket",
    label: "EF020378: With socket"
  },
  {
    featureId: "EF020379",
    featureDesc: "Hardness, copper",
    label: "EF020379: Hardness, copper"
  },
  {
    featureId: "EF020380",
    featureDesc: "Flared",
    label: "EF020380: Flared"
  },
  {
    featureId: "EF020382",
    featureDesc: "Min. bending radius",
    label: "EF020382: Min. bending radius"
  },
  {
    featureId: "EF020383",
    featureDesc: "Expansion coefficient",
    label: "EF020383: Expansion coefficient"
  },
  {
    featureId: "EF020392",
    featureDesc: "Outer pipe diameter connections 2 and 3",
    label: "EF020392: Outer pipe diameter connections 2 and 3"
  },
  {
    featureId: "EF020393",
    featureDesc: "Connections 2 and 3",
    label: "EF020393: Connections 2 and 3"
  },
  {
    featureId: "EF020394",
    featureDesc: "Connection offset",
    label: "EF020394: Connection offset"
  },
  {
    featureId: "EF020395",
    featureDesc: "Length of connections 2 and 3",
    label: "EF020395: Length of connections 2 and 3"
  },
  {
    featureId: "EF020396",
    featureDesc: "Working length connection 2 and 3",
    label: "EF020396: Working length connection 2 and 3"
  },
  {
    featureId: "EF020397",
    featureDesc: "Offset",
    label: "EF020397: Offset"
  },
  {
    featureId: "EF020400",
    featureDesc: "Angle, connection 3-4",
    label: "EF020400: Angle, connection 3-4"
  },
  {
    featureId: "EF020401",
    featureDesc: "Angle, connection 4-5",
    label: "EF020401: Angle, connection 4-5"
  },
  {
    featureId: "EF020402",
    featureDesc: "Nominal diameter connection 6",
    label: "EF020402: Nominal diameter connection 6"
  },
  {
    featureId: "EF020403",
    featureDesc: "Outer pipe diameter connection 6",
    label: "EF020403: Outer pipe diameter connection 6"
  },
  {
    featureId: "EF020405",
    featureDesc: "Length of connection 6",
    label: "EF020405: Length of connection 6"
  },
  {
    featureId: "EF020406",
    featureDesc: "Angle, connection 5-6",
    label: "EF020406: Angle, connection 5-6"
  },
  {
    featureId: "EF020407",
    featureDesc: "Nominal diameter connection 7",
    label: "EF020407: Nominal diameter connection 7"
  },
  {
    featureId: "EF020408",
    featureDesc: "Nominal diameter connection 8",
    label: "EF020408: Nominal diameter connection 8"
  },
  {
    featureId: "EF020409",
    featureDesc: "Outer pipe diameter connection 7",
    label: "EF020409: Outer pipe diameter connection 7"
  },
  {
    featureId: "EF020410",
    featureDesc: "Outer pipe diameter connection 8",
    label: "EF020410: Outer pipe diameter connection 8"
  },
  {
    featureId: "EF020411",
    featureDesc: "Fall pipe offset",
    label: "EF020411: Fall pipe offset"
  },
  {
    featureId: "EF020412",
    featureDesc: "Length of connection 7",
    label: "EF020412: Length of connection 7"
  },
  {
    featureId: "EF020413",
    featureDesc: "Length of connection 8",
    label: "EF020413: Length of connection 8"
  },
  {
    featureId: "EF020414",
    featureDesc: "Trap bend angle",
    label: "EF020414: Trap bend angle"
  },
  {
    featureId: "EF020417",
    featureDesc: "Length from wall plate",
    label: "EF020417: Length from wall plate"
  },
  {
    featureId: "EF020418",
    featureDesc: "Fitting cover",
    label: "EF020418: Fitting cover"
  },
  {
    featureId: "EF020419",
    featureDesc: "Pipe cover",
    label: "EF020419: Pipe cover"
  },
  {
    featureId: "EF020420",
    featureDesc: "Extended",
    label: "EF020420: Extended"
  },
  {
    featureId: "EF020421",
    featureDesc: "Nominal duct diameter, connection 1",
    label: "EF020421: Nominal duct diameter, connection 1"
  },
  {
    featureId: "EF020422",
    featureDesc: "Nominal duct diameter, connection 2",
    label: "EF020422: Nominal duct diameter, connection 2"
  },
  {
    featureId: "EF020423",
    featureDesc: "Bend model",
    label: "EF020423: Bend model"
  },
  {
    featureId: "EF020424",
    featureDesc: "Nominal duct diameter, connection 3",
    label: "EF020424: Nominal duct diameter, connection 3"
  },
  {
    featureId: "EF020425",
    featureDesc: "Nominal duct diameter, connection 4",
    label: "EF020425: Nominal duct diameter, connection 4"
  },
  {
    featureId: "EF020426",
    featureDesc: "Nominal duct diameter, main duct",
    label: "EF020426: Nominal duct diameter, main duct"
  },
  {
    featureId: "EF020427",
    featureDesc: "Connection branches",
    label: "EF020427: Connection branches"
  },
  {
    featureId: "EF020428",
    featureDesc: "With mantle pipe",
    label: "EF020428: With mantle pipe"
  },
  {
    featureId: "EF020429",
    featureDesc: "Outer diameter mantle pipe",
    label: "EF020429: Outer diameter mantle pipe"
  },
  {
    featureId: "EF020430",
    featureDesc: "Pipe colour",
    label: "EF020430: Pipe colour"
  },
  {
    featureId: "EF020431",
    featureDesc: "Min. detected value",
    label: "EF020431: Min. detected value"
  },
  {
    featureId: "EF020432",
    featureDesc: "Min. ambient temperature",
    label: "EF020432: Min. ambient temperature"
  },
  {
    featureId: "EF020433",
    featureDesc: "Testing device for flue gas analysis",
    label: "EF020433: Testing device for flue gas analysis"
  },
  {
    featureId: "EF020435",
    featureDesc: "With printer",
    label: "EF020435: With printer"
  },
  {
    featureId: "EF020436",
    featureDesc: "Mains voltage adapter",
    label: "EF020436: Mains voltage adapter"
  },
  {
    featureId: "EF020437",
    featureDesc: "With case",
    label: "EF020437: With case"
  },
  {
    featureId: "EF020438",
    featureDesc: "Optical alarm",
    label: "EF020438: Optical alarm"
  },
  {
    featureId: "EF020439",
    featureDesc: "Acoustic alarm",
    label: "EF020439: Acoustic alarm"
  },
  {
    featureId: "EF020440",
    featureDesc: "Max. gas testing volume",
    label: "EF020440: Max. gas testing volume"
  },
  {
    featureId: "EF020442",
    featureDesc: "Min. test length",
    label: "EF020442: Min. test length"
  },
  {
    featureId: "EF020443",
    featureDesc: "Vertical mounting",
    label: "EF020443: Vertical mounting"
  },
  {
    featureId: "EF020444",
    featureDesc: "For gas line",
    label: "EF020444: For gas line"
  },
  {
    featureId: "EF020445",
    featureDesc: "For gas valve",
    label: "EF020445: For gas valve"
  },
  {
    featureId: "EF020446",
    featureDesc: "Lock facility",
    label: "EF020446: Lock facility"
  },
  {
    featureId: "EF020447",
    featureDesc: "Nominal power, 80-60 °C",
    label: "EF020447: Nominal power, 80-60 °C"
  },
  {
    featureId: "EF020448",
    featureDesc: "Max. supply temperature (operating temperature)",
    label: "EF020448: Max. supply temperature (operating temperature)"
  },
  {
    featureId: "EF020449",
    featureDesc: "Max. operating pressure",
    label: "EF020449: Max. operating pressure"
  },
  {
    featureId: "EF020450",
    featureDesc: "Built-in filling/drain tap",
    label: "EF020450: Built-in filling/drain tap"
  },
  {
    featureId: "EF020451",
    featureDesc: "Built-in pressure relief valve",
    label: "EF020451: Built-in pressure relief valve"
  },
  {
    featureId: "EF020452",
    featureDesc: "Reheater",
    label: "EF020452: Reheater"
  },
  {
    featureId: "EF020453",
    featureDesc: "Collector surface area",
    label: "EF020453: Collector surface area"
  },
  {
    featureId: "EF020454",
    featureDesc: "Yield according to DST test",
    label: "EF020454: Yield according to DST test"
  },
  {
    featureId: "EF020455",
    featureDesc: "Nominal power of reheater",
    label: "EF020455: Nominal power of reheater"
  },
  {
    featureId: "EF020456",
    featureDesc: "Placement of collector at façade",
    label: "EF020456: Placement of collector at façade"
  },
  {
    featureId: "EF020457",
    featureDesc: "Placement of collector in pitched roof",
    label: "EF020457: Placement of collector in pitched roof"
  },
  {
    featureId: "EF020458",
    featureDesc: "Placement of collector on pitched roof",
    label: "EF020458: Placement of collector on pitched roof"
  },
  {
    featureId: "EF020459",
    featureDesc: "Placement of collector on flat roof",
    label: "EF020459: Placement of collector on flat roof"
  },
  {
    featureId: "EF020460",
    featureDesc: "Collector set-up, portrait",
    label: "EF020460: Collector set-up, portrait"
  },
  {
    featureId: "EF020461",
    featureDesc: "Collector set-up, landscape",
    label: "EF020461: Collector set-up, landscape"
  },
  {
    featureId: "EF020462",
    featureDesc: "Length of collector",
    label: "EF020462: Length of collector"
  },
  {
    featureId: "EF020463",
    featureDesc: "Width, collector",
    label: "EF020463: Width, collector"
  },
  {
    featureId: "EF020464",
    featureDesc: "Height, collector",
    label: "EF020464: Height, collector"
  },
  {
    featureId: "EF020465",
    featureDesc: "Length of boiler",
    label: "EF020465: Length of boiler"
  },
  {
    featureId: "EF020466",
    featureDesc: "Width, boiler",
    label: "EF020466: Width, boiler"
  },
  {
    featureId: "EF020467",
    featureDesc: "Height, boiler",
    label: "EF020467: Height, boiler"
  },
  {
    featureId: "EF020468",
    featureDesc: "Flame shape",
    label: "EF020468: Flame shape"
  },
  {
    featureId: "EF020469",
    featureDesc: "Outer pipe diameter supply",
    label: "EF020469: Outer pipe diameter supply"
  },
  {
    featureId: "EF020475",
    featureDesc: "Outer pipe diameter return",
    label: "EF020475: Outer pipe diameter return"
  },
  {
    featureId: "EF020481",
    featureDesc: "Connection, fuel",
    label: "EF020481: Connection, fuel"
  },
  {
    featureId: "EF020482",
    featureDesc: "Outer pipe diameter fuel",
    label: "EF020482: Outer pipe diameter fuel"
  },
  {
    featureId: "EF020488",
    featureDesc: "Air supply connection",
    label: "EF020488: Air supply connection"
  },
  {
    featureId: "EF020489",
    featureDesc: "Nominal duct width, air supply",
    label: "EF020489: Nominal duct width, air supply"
  },
  {
    featureId: "EF020494",
    featureDesc: "Flue gas connection",
    label: "EF020494: Flue gas connection"
  },
  {
    featureId: "EF020499",
    featureDesc: "Outer pipe diameter cold tap water",
    label: "EF020499: Outer pipe diameter cold tap water"
  },
  {
    featureId: "EF020504",
    featureDesc: "Outer pipe diameter hot tap water",
    label: "EF020504: Outer pipe diameter hot tap water"
  },
  {
    featureId: "EF020510",
    featureDesc: "Condensate connection",
    label: "EF020510: Condensate connection"
  },
  {
    featureId: "EF020511",
    featureDesc: "Outer pipe diameter condensate",
    label: "EF020511: Outer pipe diameter condensate"
  },
  {
    featureId: "EF020522",
    featureDesc: "Connection direction, pipes",
    label: "EF020522: Connection direction, pipes"
  },
  {
    featureId: "EF020523",
    featureDesc: "Suitable for single pipe system",
    label: "EF020523: Suitable for single pipe system"
  },
  {
    featureId: "EF020524",
    featureDesc: "Suitable for two pipe system",
    label: "EF020524: Suitable for two pipe system"
  },
  {
    featureId: "EF020525",
    featureDesc: "Centre distance, supply/return",
    label: "EF020525: Centre distance, supply/return"
  },
  {
    featureId: "EF020526",
    featureDesc: "Mounted length",
    label: "EF020526: Mounted length"
  },
  {
    featureId: "EF020527",
    featureDesc: "Length of tail piece connection",
    label: "EF020527: Length of tail piece connection"
  },
  {
    featureId: "EF020528",
    featureDesc: "Working length of tail piece connection",
    label: "EF020528: Working length of tail piece connection"
  },
  {
    featureId: "EF020529",
    featureDesc: "Tap connection length",
    label: "EF020529: Tap connection length"
  },
  {
    featureId: "EF020530",
    featureDesc: "Working length of tap connection",
    label: "EF020530: Working length of tap connection"
  },
  {
    featureId: "EF020531",
    featureDesc: "Working length of supply and return connection",
    label: "EF020531: Working length of supply and return connection"
  },
  {
    featureId: "EF020532",
    featureDesc: "Centre distance of feed/tap connection",
    label: "EF020532: Centre distance of feed/tap connection"
  },
  {
    featureId: "EF020535",
    featureDesc: "Length of pipe insert",
    label: "EF020535: Length of pipe insert"
  },
  {
    featureId: "EF020536",
    featureDesc: "Direction of pipe insert",
    label: "EF020536: Direction of pipe insert"
  },
  {
    featureId: "EF020537",
    featureDesc: "Connection direction towards wall (square)",
    label: "EF020537: Connection direction towards wall (square)"
  },
  {
    featureId: "EF020538",
    featureDesc: "Connection direction towards floor (straight)",
    label: "EF020538: Connection direction towards floor (straight)"
  },
  {
    featureId: "EF020539",
    featureDesc: "Flame head extension",
    label: "EF020539: Flame head extension"
  },
  {
    featureId: "EF020540",
    featureDesc: "Max. fire seat resistance",
    label: "EF020540: Max. fire seat resistance"
  },
  {
    featureId: "EF020541",
    featureDesc: "Min. gas pre-pressure",
    label: "EF020541: Min. gas pre-pressure"
  },
  {
    featureId: "EF020542",
    featureDesc: "Burner controller",
    label: "EF020542: Burner controller"
  },
  {
    featureId: "EF020544",
    featureDesc: "Outer pipe diameter, gas connection",
    label: "EF020544: Outer pipe diameter, gas connection"
  },
  {
    featureId: "EF020545",
    featureDesc: "Outer duct diameter, air supply",
    label: "EF020545: Outer duct diameter, air supply"
  },
  {
    featureId: "EF020546",
    featureDesc: "Control side, left",
    label: "EF020546: Control side, left"
  },
  {
    featureId: "EF020547",
    featureDesc: "Control side, right",
    label: "EF020547: Control side, right"
  },
  {
    featureId: "EF020548",
    featureDesc: "Material shower tray",
    label: "EF020548: Material shower tray"
  },
  {
    featureId: "EF020549",
    featureDesc: "Flange compression stage, connection 1",
    label: "EF020549: Flange compression stage, connection 1"
  },
  {
    featureId: "EF020550",
    featureDesc: "Flange compression stage, connection 2",
    label: "EF020550: Flange compression stage, connection 2"
  },
  {
    featureId: "EF020551",
    featureDesc: "Lug distance, connection 2",
    label: "EF020551: Lug distance, connection 2"
  },
  {
    featureId: "EF020552",
    featureDesc: "Storz metal-sealing",
    label: "EF020552: Storz metal-sealing"
  },
  {
    featureId: "EF020553",
    featureDesc: "Shape of fire fighting valve",
    label: "EF020553: Shape of fire fighting valve"
  },
  {
    featureId: "EF020554",
    featureDesc: "Angle connection 2",
    label: "EF020554: Angle connection 2"
  },
  {
    featureId: "EF020555",
    featureDesc: "Connection for level gauge body",
    label: "EF020555: Connection for level gauge body"
  },
  {
    featureId: "EF020556",
    featureDesc: "Feed-through option",
    label: "EF020556: Feed-through option"
  },
  {
    featureId: "EF020557",
    featureDesc: "Diameter, connecting pipes",
    label: "EF020557: Diameter, connecting pipes"
  },
  {
    featureId: "EF020559",
    featureDesc: "Min. centre-to-centre distance",
    label: "EF020559: Min. centre-to-centre distance"
  },
  {
    featureId: "EF020563",
    featureDesc: "Medium density",
    label: "EF020563: Medium density"
  },
  {
    featureId: "EF020564",
    featureDesc: "Viscosity",
    label: "EF020564: Viscosity"
  },
  {
    featureId: "EF020566",
    featureDesc: "See-through model",
    label: "EF020566: See-through model"
  },
  {
    featureId: "EF020567",
    featureDesc: "Flow indicator",
    label: "EF020567: Flow indicator"
  },
  {
    featureId: "EF020569",
    featureDesc: "Material cabinet",
    label: "EF020569: Material cabinet"
  },
  {
    featureId: "EF020571",
    featureDesc: "Material process connection",
    label: "EF020571: Material process connection"
  },
  {
    featureId: "EF020572",
    featureDesc: "Connection location",
    label: "EF020572: Connection location"
  },
  {
    featureId: "EF020575",
    featureDesc: "Pressure unit",
    label: "EF020575: Pressure unit"
  },
  {
    featureId: "EF020577",
    featureDesc: "Accuracy class according to EN",
    label: "EF020577: Accuracy class according to EN"
  },
  {
    featureId: "EF020578",
    featureDesc: "Window pane material",
    label: "EF020578: Window pane material"
  },
  {
    featureId: "EF020579",
    featureDesc: "Pressure gauge attachment",
    label: "EF020579: Pressure gauge attachment"
  },
  {
    featureId: "EF020580",
    featureDesc: "Liquid-damped",
    label: "EF020580: Liquid-damped"
  },
  {
    featureId: "EF020586",
    featureDesc: "Max. static pressure",
    label: "EF020586: Max. static pressure"
  },
  {
    featureId: "EF020587",
    featureDesc: "With downcomer",
    label: "EF020587: With downcomer"
  },
  {
    featureId: "EF020588",
    featureDesc: "Nominal pipe size",
    label: "EF020588: Nominal pipe size"
  },
  {
    featureId: "EF020589",
    featureDesc: "Diameter, connection level gauge/body",
    label: "EF020589: Diameter, connection level gauge/body"
  },
  {
    featureId: "EF020590",
    featureDesc: "With level gauge or body",
    label: "EF020590: With level gauge or body"
  },
  {
    featureId: "EF020591",
    featureDesc: "With level gauge protector",
    label: "EF020591: With level gauge protector"
  },
  {
    featureId: "EF020592",
    featureDesc: "With drain",
    label: "EF020592: With drain"
  },
  {
    featureId: "EF020593",
    featureDesc: "Flow rate at delta p = 1 bar",
    label: "EF020593: Flow rate at delta p = 1 bar"
  },
  {
    featureId: "EF020594",
    featureDesc: "Automatic drainage",
    label: "EF020594: Automatic drainage"
  },
  {
    featureId: "EF020595",
    featureDesc: "Motor spray connection",
    label: "EF020595: Motor spray connection"
  },
  {
    featureId: "EF020596",
    featureDesc: "Diameter housing",
    label: "EF020596: Diameter housing"
  },
  {
    featureId: "EF020598",
    featureDesc: "With foot bend",
    label: "EF020598: With foot bend"
  },
  {
    featureId: "EF020599",
    featureDesc: "Separate valve controls",
    label: "EF020599: Separate valve controls"
  },
  {
    featureId: "EF020601",
    featureDesc: "Suitable for continuous discharge",
    label: "EF020601: Suitable for continuous discharge"
  },
  {
    featureId: "EF020602",
    featureDesc: "With reservoir",
    label: "EF020602: With reservoir"
  },
  {
    featureId: "EF020604",
    featureDesc: "Material quality reservoir",
    label: "EF020604: Material quality reservoir"
  },
  {
    featureId: "EF020605",
    featureDesc: "Max. delivery pressure",
    label: "EF020605: Max. delivery pressure"
  },
  {
    featureId: "EF020606",
    featureDesc: "With hose and couplings",
    label: "EF020606: With hose and couplings"
  },
  {
    featureId: "EF020607",
    featureDesc: "Max. suction height",
    label: "EF020607: Max. suction height"
  },
  {
    featureId: "EF020609",
    featureDesc: "Air volume required",
    label: "EF020609: Air volume required"
  },
  {
    featureId: "EF020610",
    featureDesc: "Pressure loss at 125 m3/h",
    label: "EF020610: Pressure loss at 125 m3/h"
  },
  {
    featureId: "EF020611",
    featureDesc: "Grease filter class EU2",
    label: "EF020611: Grease filter class EU2"
  },
  {
    featureId: "EF020612",
    featureDesc: "Air curtain function",
    label: "EF020612: Air curtain function"
  },
  {
    featureId: "EF020613",
    featureDesc: "Number of curtain rings",
    label: "EF020613: Number of curtain rings"
  },
  {
    featureId: "EF020614",
    featureDesc: "With shower curtain",
    label: "EF020614: With shower curtain"
  },
  {
    featureId: "EF020615",
    featureDesc: "With connecting piece",
    label: "EF020615: With connecting piece"
  },
  {
    featureId: "EF020616",
    featureDesc: "Built-in width of wall for mounting with door",
    label: "EF020616: Built-in width of wall for mounting with door"
  },
  {
    featureId: "EF020617",
    featureDesc: "Built-in width of wall for mounting in line",
    label: "EF020617: Built-in width of wall for mounting in line"
  },
  {
    featureId: "EF020618",
    featureDesc: "Type of side",
    label: "EF020618: Type of side"
  },
  {
    featureId: "EF020619",
    featureDesc: "Largest width",
    label: "EF020619: Largest width"
  },
  {
    featureId: "EF020620",
    featureDesc: "Smallest width",
    label: "EF020620: Smallest width"
  },
  {
    featureId: "EF020621",
    featureDesc: "Radius",
    label: "EF020621: Radius"
  },
  {
    featureId: "EF020624",
    featureDesc: "Short straight side",
    label: "EF020624: Short straight side"
  },
  {
    featureId: "EF020625",
    featureDesc: "Slanting side",
    label: "EF020625: Slanting side"
  },
  {
    featureId: "EF020627",
    featureDesc: "With matching shower tray",
    label: "EF020627: With matching shower tray"
  },
  {
    featureId: "EF020629",
    featureDesc: "Built-in width for mounting in niche",
    label: "EF020629: Built-in width for mounting in niche"
  },
  {
    featureId: "EF020630",
    featureDesc: "Built-in width for mounting in line",
    label: "EF020630: Built-in width for mounting in line"
  },
  {
    featureId: "EF020631",
    featureDesc: "Built-in width for mounting with side wall",
    label: "EF020631: Built-in width for mounting with side wall"
  },
  {
    featureId: "EF020632",
    featureDesc: "Overall height from bath edge",
    label: "EF020632: Overall height from bath edge"
  },
  {
    featureId: "EF020633",
    featureDesc: "Type of bath screen",
    label: "EF020633: Type of bath screen"
  },
  {
    featureId: "EF020636",
    featureDesc: "Auxiliary contacts optional",
    label: "EF020636: Auxiliary contacts optional"
  },
  {
    featureId: "EF020643",
    featureDesc: "With receiver",
    label: "EF020643: With receiver"
  },
  {
    featureId: "EF020644",
    featureDesc: "Control characteristic",
    label: "EF020644: Control characteristic"
  },
  {
    featureId: "EF020645",
    featureDesc: "With extra contact",
    label: "EF020645: With extra contact"
  },
  {
    featureId: "EF020646",
    featureDesc: "Weather-dependent control system",
    label: "EF020646: Weather-dependent control system"
  },
  {
    featureId: "EF020648",
    featureDesc: "Setting can be locked",
    label: "EF020648: Setting can be locked"
  },
  {
    featureId: "EF020649",
    featureDesc: "Suitable for anti-theft protection",
    label: "EF020649: Suitable for anti-theft protection"
  },
  {
    featureId: "EF020650",
    featureDesc: "Control element H, support block right",
    label: "EF020650: Control element H, support block right"
  },
  {
    featureId: "EF020651",
    featureDesc: "Control element H, support block left",
    label: "EF020651: Control element H, support block left"
  },
  {
    featureId: "EF020652",
    featureDesc: "Boiler controls",
    label: "EF020652: Boiler controls"
  },
  {
    featureId: "EF020653",
    featureDesc: "Group controls",
    label: "EF020653: Group controls"
  },
  {
    featureId: "EF020654",
    featureDesc: "Number of mixing groups",
    label: "EF020654: Number of mixing groups"
  },
  {
    featureId: "EF020655",
    featureDesc: "Number of pump groups",
    label: "EF020655: Number of pump groups"
  },
  {
    featureId: "EF020656",
    featureDesc: "Air treatment control system",
    label: "EF020656: Air treatment control system"
  },
  {
    featureId: "EF020660",
    featureDesc: "Number of heater groups",
    label: "EF020660: Number of heater groups"
  },
  {
    featureId: "EF020662",
    featureDesc: "Tap water control",
    label: "EF020662: Tap water control"
  },
  {
    featureId: "EF020664",
    featureDesc: "Split-unit controls",
    label: "EF020664: Split-unit controls"
  },
  {
    featureId: "EF020665",
    featureDesc: "Air curtain controls",
    label: "EF020665: Air curtain controls"
  },
  {
    featureId: "EF020666",
    featureDesc: "Swimming pool controls",
    label: "EF020666: Swimming pool controls"
  },
  {
    featureId: "EF020667",
    featureDesc: "Heat pump controls",
    label: "EF020667: Heat pump controls"
  },
  {
    featureId: "EF020670",
    featureDesc: "Connection for control element",
    label: "EF020670: Connection for control element"
  },
  {
    featureId: "EF020671",
    featureDesc: "Valve characteristic",
    label: "EF020671: Valve characteristic"
  },
  {
    featureId: "EF020672",
    featureDesc: "Size of control element",
    label: "EF020672: Size of control element"
  },
  {
    featureId: "EF020673",
    featureDesc: "Position indicator",
    label: "EF020673: Position indicator"
  },
  {
    featureId: "EF020674",
    featureDesc: "Measuring connection",
    label: "EF020674: Measuring connection"
  },
  {
    featureId: "EF020675",
    featureDesc: "Suitable for air accessory",
    label: "EF020675: Suitable for air accessory"
  },
  {
    featureId: "EF020676",
    featureDesc: "Automatic stroke recognition",
    label: "EF020676: Automatic stroke recognition"
  },
  {
    featureId: "EF020677",
    featureDesc: "With safety function",
    label: "EF020677: With safety function"
  },
  {
    featureId: "EF020678",
    featureDesc: "Electric servomotor accessory/spare part",
    label: "EF020678: Electric servomotor accessory/spare part"
  },
  {
    featureId: "EF020679",
    featureDesc: "Thermal servomotor accessory/spare part",
    label: "EF020679: Thermal servomotor accessory/spare part"
  },
  {
    featureId: "EF020680",
    featureDesc: "With tap water circuit",
    label: "EF020680: With tap water circuit"
  },
  {
    featureId: "EF020681",
    featureDesc: "Number of heater outputs",
    label: "EF020681: Number of heater outputs"
  },
  {
    featureId: "EF020682",
    featureDesc: "Number of cooler outputs",
    label: "EF020682: Number of cooler outputs"
  },
  {
    featureId: "EF020683",
    featureDesc: "With sound indicator",
    label: "EF020683: With sound indicator"
  },
  {
    featureId: "EF020684",
    featureDesc: "Clock model",
    label: "EF020684: Clock model"
  },
  {
    featureId: "EF020685",
    featureDesc: "Reception frequency",
    label: "EF020685: Reception frequency"
  },
  {
    featureId: "EF020687",
    featureDesc: "Max. power per channel",
    label: "EF020687: Max. power per channel"
  },
  {
    featureId: "EF020688",
    featureDesc: "Number of channels/zone",
    label: "EF020688: Number of channels/zone"
  },
  {
    featureId: "EF020689",
    featureDesc: "Air heater",
    label: "EF020689: Air heater"
  },
  {
    featureId: "EF020690",
    featureDesc: "Air cooler",
    label: "EF020690: Air cooler"
  },
  {
    featureId: "EF020691",
    featureDesc: "Air humidifier",
    label: "EF020691: Air humidifier"
  },
  {
    featureId: "EF020692",
    featureDesc: "Air reheater",
    label: "EF020692: Air reheater"
  },
  {
    featureId: "EF020693",
    featureDesc: "Boiler exchange valve controls",
    label: "EF020693: Boiler exchange valve controls"
  },
  {
    featureId: "EF020694",
    featureDesc: "Extraction fan control",
    label: "EF020694: Extraction fan control"
  },
  {
    featureId: "EF020695",
    featureDesc: "Number of floor heating groups",
    label: "EF020695: Number of floor heating groups"
  },
  {
    featureId: "EF020696",
    featureDesc: "Extraction fan controls",
    label: "EF020696: Extraction fan controls"
  },
  {
    featureId: "EF020697",
    featureDesc: "Number of extraction fans",
    label: "EF020697: Number of extraction fans"
  },
  {
    featureId: "EF020698",
    featureDesc: "Adjustable flushing water quantity",
    label: "EF020698: Adjustable flushing water quantity"
  },
  {
    featureId: "EF020699",
    featureDesc: "With attachment set",
    label: "EF020699: With attachment set"
  },
  {
    featureId: "EF020700",
    featureDesc: "With side plates",
    label: "EF020700: With side plates"
  },
  {
    featureId: "EF020701",
    featureDesc: "With top plate",
    label: "EF020701: With top plate"
  },
  {
    featureId: "EF020702",
    featureDesc: "Number of sinks",
    label: "EF020702: Number of sinks"
  },
  {
    featureId: "EF020703",
    featureDesc: "With waste tray with plug and sieve (strainer)",
    label: "EF020703: With waste tray with plug and sieve (strainer)"
  },
  {
    featureId: "EF020704",
    featureDesc: "With drain plug and integrated overflow",
    label: "EF020704: With drain plug and integrated overflow"
  },
  {
    featureId: "EF020706",
    featureDesc: "Number of usage locations",
    label: "EF020706: Number of usage locations"
  },
  {
    featureId: "EF020707",
    featureDesc: "Medical model",
    label: "EF020707: Medical model"
  },
  {
    featureId: "EF020708",
    featureDesc: "Punchable tap holes",
    label: "EF020708: Punchable tap holes"
  },
  {
    featureId: "EF020709",
    featureDesc: "Suitable for trap cap",
    label: "EF020709: Suitable for trap cap"
  },
  {
    featureId: "EF020710",
    featureDesc: "Diameter of drain hole",
    label: "EF020710: Diameter of drain hole"
  },
  {
    featureId: "EF020712",
    featureDesc: "Mounting method model-dependent",
    label: "EF020712: Mounting method model-dependent"
  },
  {
    featureId: "EF020713",
    featureDesc: "Raised toilet",
    label: "EF020713: Raised toilet"
  },
  {
    featureId: "EF020714",
    featureDesc: "With stench extraction",
    label: "EF020714: With stench extraction"
  },
  {
    featureId: "EF020715",
    featureDesc: "Powerflush",
    label: "EF020715: Powerflush"
  },
  {
    featureId: "EF020716",
    featureDesc: "Water connection, left",
    label: "EF020716: Water connection, left"
  },
  {
    featureId: "EF020717",
    featureDesc: "Water connection, right",
    label: "EF020717: Water connection, right"
  },
  {
    featureId: "EF020718",
    featureDesc: "Water connection, rear",
    label: "EF020718: Water connection, rear"
  },
  {
    featureId: "EF020719",
    featureDesc: "Water connection, bottom",
    label: "EF020719: Water connection, bottom"
  },
  {
    featureId: "EF020720",
    featureDesc: "With T-piece for 4-way connection",
    label: "EF020720: With T-piece for 4-way connection"
  },
  {
    featureId: "EF020721",
    featureDesc: "With impulse pipe connection",
    label: "EF020721: With impulse pipe connection"
  },
  {
    featureId: "EF020722",
    featureDesc: "Controllable",
    label: "EF020722: Controllable"
  },
  {
    featureId: "EF020723",
    featureDesc: "O-ring seal",
    label: "EF020723: O-ring seal"
  },
  {
    featureId: "EF020724",
    featureDesc: "Control element colour",
    label: "EF020724: Control element colour"
  },
  {
    featureId: "EF020725",
    featureDesc: "With impulse pipe",
    label: "EF020725: With impulse pipe"
  },
  {
    featureId: "EF020726",
    featureDesc: "Membrane-controlled",
    label: "EF020726: Membrane-controlled"
  },
  {
    featureId: "EF020727",
    featureDesc: "Volume flow preset",
    label: "EF020727: Volume flow preset"
  },
  {
    featureId: "EF020728",
    featureDesc: "Adjustable pressure difference",
    label: "EF020728: Adjustable pressure difference"
  },
  {
    featureId: "EF020729",
    featureDesc: "Accessory for pressure difference overflow controller",
    label: "EF020729: Accessory for pressure difference overflow controller"
  },
  {
    featureId: "EF020730",
    featureDesc: "Accessory for constant flow rate controller",
    label: "EF020730: Accessory for constant flow rate controller"
  },
  {
    featureId: "EF020731",
    featureDesc: "Accessory for pressure-compensated control valve",
    label: "EF020731: Accessory for pressure-compensated control valve"
  },
  {
    featureId: "EF020732",
    featureDesc: "Accessory for combined measuring and control valve",
    label: "EF020732: Accessory for combined measuring and control valve"
  },
  {
    featureId: "EF020733",
    featureDesc: "Accessory for pressure difference controller",
    label: "EF020733: Accessory for pressure difference controller"
  },
  {
    featureId: "EF020734",
    featureDesc: "Max. measured pressure",
    label: "EF020734: Max. measured pressure"
  },
  {
    featureId: "EF020735",
    featureDesc: "With PC interface",
    label: "EF020735: With PC interface"
  },
  {
    featureId: "EF020736",
    featureDesc: "With data logging",
    label: "EF020736: With data logging"
  },
  {
    featureId: "EF020737",
    featureDesc: "Outer pipe diameter (inch)",
    label: "EF020737: Outer pipe diameter (inch)"
  },
  {
    featureId: "EF020738",
    featureDesc: "Suitable for descaling",
    label: "EF020738: Suitable for descaling"
  },
  {
    featureId: "EF020739",
    featureDesc: "Suitable for degreasing",
    label: "EF020739: Suitable for degreasing"
  },
  {
    featureId: "EF020740",
    featureDesc: "Suitable for cleaning",
    label: "EF020740: Suitable for cleaning"
  },
  {
    featureId: "EF020741",
    featureDesc: "Suitable as rinsing agent",
    label: "EF020741: Suitable as rinsing agent"
  },
  {
    featureId: "EF020742",
    featureDesc: "Water-soluble",
    label: "EF020742: Water-soluble"
  },
  {
    featureId: "EF020743",
    featureDesc: "Rinsing with water mandatory",
    label: "EF020743: Rinsing with water mandatory"
  },
  {
    featureId: "EF020744",
    featureDesc: "Diluting with water mandatory",
    label: "EF020744: Diluting with water mandatory"
  },
  {
    featureId: "EF020745",
    featureDesc: "Biologically degradable",
    label: "EF020745: Biologically degradable"
  },
  {
    featureId: "EF020746",
    featureDesc: "Processing under high pressure",
    label: "EF020746: Processing under high pressure"
  },
  {
    featureId: "EF020747",
    featureDesc: "Suitable against algae",
    label: "EF020747: Suitable against algae"
  },
  {
    featureId: "EF020748",
    featureDesc: "Suitable against mould",
    label: "EF020748: Suitable against mould"
  },
  {
    featureId: "EF020749",
    featureDesc: "Connection size of tap",
    label: "EF020749: Connection size of tap"
  },
  {
    featureId: "EF020750",
    featureDesc: "Max. sheet thickness",
    label: "EF020750: Max. sheet thickness"
  },
  {
    featureId: "EF020751",
    featureDesc: "Mounting size",
    label: "EF020751: Mounting size"
  },
  {
    featureId: "EF020752",
    featureDesc: "Height above sheet",
    label: "EF020752: Height above sheet"
  },
  {
    featureId: "EF020753",
    featureDesc: "With fracture line",
    label: "EF020753: With fracture line"
  },
  {
    featureId: "EF020754",
    featureDesc: "Protected against overvoltage",
    label: "EF020754: Protected against overvoltage"
  },
  {
    featureId: "EF020755",
    featureDesc: "With chromium-plated edge",
    label: "EF020755: With chromium-plated edge"
  },
  {
    featureId: "EF020756",
    featureDesc: "Quality class inner layer",
    label: "EF020756: Quality class inner layer"
  },
  {
    featureId: "EF020757",
    featureDesc: "Quality class intermediate layer",
    label: "EF020757: Quality class intermediate layer"
  },
  {
    featureId: "EF020758",
    featureDesc: "Quality class outer layer",
    label: "EF020758: Quality class outer layer"
  },
  {
    featureId: "EF020759",
    featureDesc: "Thickness of thermal insulation",
    label: "EF020759: Thickness of thermal insulation"
  },
  {
    featureId: "EF020760",
    featureDesc: "Colour of thermal insulation",
    label: "EF020760: Colour of thermal insulation"
  },
  {
    featureId: "EF020761",
    featureDesc: "Suitable for membrane pressure expansion tank",
    label: "EF020761: Suitable for membrane pressure expansion tank"
  },
  {
    featureId: "EF020762",
    featureDesc: "Suitable for compressor-controlled automatic expansion unit",
    label: "EF020762: Suitable for compressor-controlled automatic expansion unit"
  },
  {
    featureId: "EF020763",
    featureDesc: "With automatic filling function",
    label: "EF020763: With automatic filling function"
  },
  {
    featureId: "EF020764",
    featureDesc: "Connection size of filling pipe",
    label: "EF020764: Connection size of filling pipe"
  },
  {
    featureId: "EF020765",
    featureDesc: "Filling capacity",
    label: "EF020765: Filling capacity"
  },
  {
    featureId: "EF020767",
    featureDesc: "Max. installation contents",
    label: "EF020767: Max. installation contents"
  },
  {
    featureId: "EF020768",
    featureDesc: "Separator type",
    label: "EF020768: Separator type"
  },
  {
    featureId: "EF020769",
    featureDesc: "Inlet/outlet offset distance",
    label: "EF020769: Inlet/outlet offset distance"
  },
  {
    featureId: "EF020771",
    featureDesc: "With dismountable filter",
    label: "EF020771: With dismountable filter"
  },
  {
    featureId: "EF020772",
    featureDesc: "Filter volume",
    label: "EF020772: Filter volume"
  },
  {
    featureId: "EF020773",
    featureDesc: "Filter mesh density",
    label: "EF020773: Filter mesh density"
  },
  {
    featureId: "EF020774",
    featureDesc: "Suitable for bath (wall built-in)",
    label: "EF020774: Suitable for bath (wall built-in)"
  },
  {
    featureId: "EF020775",
    featureDesc: "Suitable for bath edge/tiled edge",
    label: "EF020775: Suitable for bath edge/tiled edge"
  },
  {
    featureId: "EF020776",
    featureDesc: "Suitable for shower",
    label: "EF020776: Suitable for shower"
  },
  {
    featureId: "EF020777",
    featureDesc: "Suitable for washbasin (wall built-in)",
    label: "EF020777: Suitable for washbasin (wall built-in)"
  },
  {
    featureId: "EF020778",
    featureDesc: "Suitable for washbasin (high back wall built-in)",
    label: "EF020778: Suitable for washbasin (high back wall built-in)"
  },
  {
    featureId: "EF020779",
    featureDesc: "Suitable for kitchen",
    label: "EF020779: Suitable for kitchen"
  },
  {
    featureId: "EF020780",
    featureDesc: "Number of grips",
    label: "EF020780: Number of grips"
  },
  {
    featureId: "EF020781",
    featureDesc: "Locking mechanism",
    label: "EF020781: Locking mechanism"
  },
  {
    featureId: "EF020782",
    featureDesc: "Material finishing piece",
    label: "EF020782: Material finishing piece"
  },
  {
    featureId: "EF020783",
    featureDesc: "Surface protection finishing piece",
    label: "EF020783: Surface protection finishing piece"
  },
  {
    featureId: "EF020784",
    featureDesc: "Surface treatment finishing piece",
    label: "EF020784: Surface treatment finishing piece"
  },
  {
    featureId: "EF020785",
    featureDesc: "Connection size supply",
    label: "EF020785: Connection size supply"
  },
  {
    featureId: "EF020786",
    featureDesc: "Number of outgoing connections",
    label: "EF020786: Number of outgoing connections"
  },
  {
    featureId: "EF020788",
    featureDesc: "With outlet",
    label: "EF020788: With outlet"
  },
  {
    featureId: "EF020789",
    featureDesc: "Outlet overhang length",
    label: "EF020789: Outlet overhang length"
  },
  {
    featureId: "EF020790",
    featureDesc: "With fastening facility for front wall installation",
    label: "EF020790: With fastening facility for front wall installation"
  },
  {
    featureId: "EF020791",
    featureDesc: "Material tap",
    label: "EF020791: Material tap"
  },
  {
    featureId: "EF020792",
    featureDesc: "Low-pressure model",
    label: "EF020792: Low-pressure model"
  },
  {
    featureId: "EF020794",
    featureDesc: "With rosettes/cover plate",
    label: "EF020794: With rosettes/cover plate"
  },
  {
    featureId: "EF020795",
    featureDesc: "Outlet model",
    label: "EF020795: Outlet model"
  },
  {
    featureId: "EF020796",
    featureDesc: "Total construction height",
    label: "EF020796: Total construction height"
  },
  {
    featureId: "EF020797",
    featureDesc: "Construction height, underside of tap nozzle",
    label: "EF020797: Construction height, underside of tap nozzle"
  },
  {
    featureId: "EF020799",
    featureDesc: "With grips",
    label: "EF020799: With grips"
  },
  {
    featureId: "EF020800",
    featureDesc: "Includes a chain eye",
    label: "EF020800: Includes a chain eye"
  },
  {
    featureId: "EF020801",
    featureDesc: "With stop valve for external equipment",
    label: "EF020801: With stop valve for external equipment"
  },
  {
    featureId: "EF020803",
    featureDesc: "Can be disconnected under water pressure",
    label: "EF020803: Can be disconnected under water pressure"
  },
  {
    featureId: "EF020804",
    featureDesc: "Tap can be folded in (bayonet)",
    label: "EF020804: Tap can be folded in (bayonet)"
  },
  {
    featureId: "EF020805",
    featureDesc: "With fixed main shower",
    label: "EF020805: With fixed main shower"
  },
  {
    featureId: "EF020806",
    featureDesc: "Suitable for nominal duct diameter",
    label: "EF020806: Suitable for nominal duct diameter"
  },
  {
    featureId: "EF020809",
    featureDesc: "Exhaust end duct width",
    label: "EF020809: Exhaust end duct width"
  },
  {
    featureId: "EF020810",
    featureDesc: "Suction end duct height",
    label: "EF020810: Suction end duct height"
  },
  {
    featureId: "EF020811",
    featureDesc: "Suction end duct width",
    label: "EF020811: Suction end duct width"
  },
  {
    featureId: "EF020812",
    featureDesc: "Number of valve registers",
    label: "EF020812: Number of valve registers"
  },
  {
    featureId: "EF020813",
    featureDesc: "With recess for soap dispenser",
    label: "EF020813: With recess for soap dispenser"
  },
  {
    featureId: "EF020814",
    featureDesc: "Corner model",
    label: "EF020814: Corner model"
  },
  {
    featureId: "EF020815",
    featureDesc: "Flat installation",
    label: "EF020815: Flat installation"
  },
  {
    featureId: "EF020816",
    featureDesc: "Flushing water connection",
    label: "EF020816: Flushing water connection"
  },
  {
    featureId: "EF020818",
    featureDesc: "With shelving",
    label: "EF020818: With shelving"
  },
  {
    featureId: "EF020819",
    featureDesc: "Outer pipe diameter tap water",
    label: "EF020819: Outer pipe diameter tap water"
  },
  {
    featureId: "EF020820",
    featureDesc: "Size of tap water connection",
    label: "EF020820: Size of tap water connection"
  },
  {
    featureId: "EF020821",
    featureDesc: "With anti-condensation insulation",
    label: "EF020821: With anti-condensation insulation"
  },
  {
    featureId: "EF020823",
    featureDesc: "Outer pipe diameter reservoir side",
    label: "EF020823: Outer pipe diameter reservoir side"
  },
  {
    featureId: "EF020824",
    featureDesc: "Tap assembly method",
    label: "EF020824: Tap assembly method"
  },
  {
    featureId: "EF020825",
    featureDesc: "Suitable for bidet",
    label: "EF020825: Suitable for bidet"
  },
  {
    featureId: "EF020826",
    featureDesc: "Suitable for washbasin",
    label: "EF020826: Suitable for washbasin"
  },
  {
    featureId: "EF020827",
    featureDesc: "Suitable for wall-hung toilet",
    label: "EF020827: Suitable for wall-hung toilet"
  },
  {
    featureId: "EF020828",
    featureDesc: "Suitable for urinal",
    label: "EF020828: Suitable for urinal"
  },
  {
    featureId: "EF020829",
    featureDesc: "Suitable for cleaner's sink",
    label: "EF020829: Suitable for cleaner's sink"
  },
  {
    featureId: "EF020830",
    featureDesc: "Suitable for bath/shower mixing tap",
    label: "EF020830: Suitable for bath/shower mixing tap"
  },
  {
    featureId: "EF020831",
    featureDesc: "Suitable for arm support",
    label: "EF020831: Suitable for arm support"
  },
  {
    featureId: "EF020833",
    featureDesc: "For built-in flushing reservoir",
    label: "EF020833: For built-in flushing reservoir"
  },
  {
    featureId: "EF020834",
    featureDesc: "Quality class flushing reservoir",
    label: "EF020834: Quality class flushing reservoir"
  },
  {
    featureId: "EF020835",
    featureDesc: "Floor tile",
    label: "EF020835: Floor tile"
  },
  {
    featureId: "EF020836",
    featureDesc: "Wall tile",
    label: "EF020836: Wall tile"
  },
  {
    featureId: "EF020837",
    featureDesc: "Appearance",
    label: "EF020837: Appearance"
  },
  {
    featureId: "EF020838",
    featureDesc: "Glazed",
    label: "EF020838: Glazed"
  },
  {
    featureId: "EF020839",
    featureDesc: "Rectified",
    label: "EF020839: Rectified"
  },
  {
    featureId: "EF020840",
    featureDesc: "Calibrated",
    label: "EF020840: Calibrated"
  },
  {
    featureId: "EF020841",
    featureDesc: "Surface structure",
    label: "EF020841: Surface structure"
  },
  {
    featureId: "EF020842",
    featureDesc: "Slip class according to DIN 51097",
    label: "EF020842: Slip class according to DIN 51097"
  },
  {
    featureId: "EF020843",
    featureDesc: "Slip class R value according to EN 176 B1 / 177 BIIA / 177 BIII",
    label: "EF020843: Slip class R value according to EN 176 B1 / 177 BIIA / 177 BIII"
  },
  {
    featureId: "EF020844",
    featureDesc: "Wear class according to DIN EN 154 (I to IV) and ISO 10545 T.7 (V)",
    label: "EF020844: Wear class according to DIN EN 154 (I to IV) and ISO 10545 T.7 (V)"
  },
  {
    featureId: "EF020845",
    featureDesc: "Frost-resistant",
    label: "EF020845: Frost-resistant"
  },
  {
    featureId: "EF020846",
    featureDesc: "Surface machining",
    label: "EF020846: Surface machining"
  },
  {
    featureId: "EF020848",
    featureDesc: "Length (nominal)",
    label: "EF020848: Length (nominal)"
  },
  {
    featureId: "EF020849",
    featureDesc: "Width (nominal)",
    label: "EF020849: Width (nominal)"
  },
  {
    featureId: "EF020850",
    featureDesc: "Thickness (nominal)",
    label: "EF020850: Thickness (nominal)"
  },
  {
    featureId: "EF020851",
    featureDesc: "Colour group",
    label: "EF020851: Colour group"
  },
  {
    featureId: "EF020852",
    featureDesc: "Length of tile on mat",
    label: "EF020852: Length of tile on mat"
  },
  {
    featureId: "EF020853",
    featureDesc: "Width of tile on mat",
    label: "EF020853: Width of tile on mat"
  },
  {
    featureId: "EF020854",
    featureDesc: "Bond",
    label: "EF020854: Bond"
  },
  {
    featureId: "EF020855",
    featureDesc: "Length (production size)",
    label: "EF020855: Length (production size)"
  },
  {
    featureId: "EF020856",
    featureDesc: "Width (production size)",
    label: "EF020856: Width (production size)"
  },
  {
    featureId: "EF020857",
    featureDesc: "Suitable for open system",
    label: "EF020857: Suitable for open system"
  },
  {
    featureId: "EF020858",
    featureDesc: "Outer pipe diameter 1 supply",
    label: "EF020858: Outer pipe diameter 1 supply"
  },
  {
    featureId: "EF020859",
    featureDesc: "Outer pipe diameter 2 supply",
    label: "EF020859: Outer pipe diameter 2 supply"
  },
  {
    featureId: "EF020860",
    featureDesc: "Outer pipe diameter 1 return",
    label: "EF020860: Outer pipe diameter 1 return"
  },
  {
    featureId: "EF020861",
    featureDesc: "Outer pipe diameter 2 return",
    label: "EF020861: Outer pipe diameter 2 return"
  },
  {
    featureId: "EF020863",
    featureDesc: "Nominal diameter central heating",
    label: "EF020863: Nominal diameter central heating"
  },
  {
    featureId: "EF020867",
    featureDesc: "Closable groups",
    label: "EF020867: Closable groups"
  },
  {
    featureId: "EF020871",
    featureDesc: "Extendible outlet with aerator",
    label: "EF020871: Extendible outlet with aerator"
  },
  {
    featureId: "EF020872",
    featureDesc: "Extendible outlet with hand shower",
    label: "EF020872: Extendible outlet with hand shower"
  },
  {
    featureId: "EF020873",
    featureDesc: "Extendible side shower",
    label: "EF020873: Extendible side shower"
  },
  {
    featureId: "EF020878",
    featureDesc: "Number of cylinders in package",
    label: "EF020878: Number of cylinders in package"
  },
  {
    featureId: "EF020879",
    featureDesc: "Volume of cylinder/package",
    label: "EF020879: Volume of cylinder/package"
  },
  {
    featureId: "EF020880",
    featureDesc: "Volume of gas at 15 °C and 1 bar",
    label: "EF020880: Volume of gas at 15 °C and 1 bar"
  },
  {
    featureId: "EF020881",
    featureDesc: "Purity 1st digit (x.x)",
    label: "EF020881: Purity 1st digit (x.x)"
  },
  {
    featureId: "EF020882",
    featureDesc: "Purity 2nd digit (x.x)",
    label: "EF020882: Purity 2nd digit (x.x)"
  },
  {
    featureId: "EF020883",
    featureDesc: "1st colour of shoulder cylinder according to EN 1089-3",
    label: "EF020883: 1st colour of shoulder cylinder according to EN 1089-3"
  },
  {
    featureId: "EF020884",
    featureDesc: "1st RAL-number of shoulder cylinder according to EN 1089-3",
    label: "EF020884: 1st RAL-number of shoulder cylinder according to EN 1089-3"
  },
  {
    featureId: "EF020885",
    featureDesc: "2nd colour of shoulder cylinder according to EN 1089-3 (bottom ring)",
    label: "EF020885: 2nd colour of shoulder cylinder according to EN 1089-3 (bottom ring)"
  },
  {
    featureId: "EF020886",
    featureDesc: "2nd RAL-number of shoulder cylinder according to EN 1089-3",
    label: "EF020886: 2nd RAL-number of shoulder cylinder according to EN 1089-3"
  },
  {
    featureId: "EF020887",
    featureDesc: "Colour of cylindrical part according to EN 1089-3",
    label: "EF020887: Colour of cylindrical part according to EN 1089-3"
  },
  {
    featureId: "EF020888",
    featureDesc: "RAL-number of cylindrical part according to EN 1089-3",
    label: "EF020888: RAL-number of cylindrical part according to EN 1089-3"
  },
  {
    featureId: "EF020890",
    featureDesc: "With riser",
    label: "EF020890: With riser"
  },
  {
    featureId: "EF020891",
    featureDesc: "Name according to ASHRAE",
    label: "EF020891: Name according to ASHRAE"
  },
  {
    featureId: "EF020892",
    featureDesc: "Recycling cylinder",
    label: "EF020892: Recycling cylinder"
  },
  {
    featureId: "EF020893",
    featureDesc: "Mass of gas",
    label: "EF020893: Mass of gas"
  },
  {
    featureId: "EF020894",
    featureDesc: "Colour of shoulder cylinder",
    label: "EF020894: Colour of shoulder cylinder"
  },
  {
    featureId: "EF020895",
    featureDesc: "RAL-number of shoulder cylinder",
    label: "EF020895: RAL-number of shoulder cylinder"
  },
  {
    featureId: "EF020896",
    featureDesc: "Vapour pressure at 25 °C",
    label: "EF020896: Vapour pressure at 25 °C"
  },
  {
    featureId: "EF020897",
    featureDesc: "Colour of cylindrical part",
    label: "EF020897: Colour of cylindrical part"
  },
  {
    featureId: "EF020898",
    featureDesc: "RAL-number of cylindrical part",
    label: "EF020898: RAL-number of cylindrical part"
  },
  {
    featureId: "EF020899",
    featureDesc: "Volume of cylinder/package tank",
    label: "EF020899: Volume of cylinder/package tank"
  },
  {
    featureId: "EF020902",
    featureDesc: "With clamp bracket",
    label: "EF020902: With clamp bracket"
  },
  {
    featureId: "EF020904",
    featureDesc: "Type of lining",
    label: "EF020904: Type of lining"
  },
  {
    featureId: "EF020905",
    featureDesc: "Hardness of copper end",
    label: "EF020905: Hardness of copper end"
  },
  {
    featureId: "EF020906",
    featureDesc: "With adapter coupling",
    label: "EF020906: With adapter coupling"
  },
  {
    featureId: "EF020907",
    featureDesc: "With insulating box",
    label: "EF020907: With insulating box"
  },
  {
    featureId: "EF020908",
    featureDesc: "Selector switch for heating and heating with hot water",
    label: "EF020908: Selector switch for heating and heating with hot water"
  },
  {
    featureId: "EF020909",
    featureDesc: "Overtime switch",
    label: "EF020909: Overtime switch"
  },
  {
    featureId: "EF020910",
    featureDesc: "Diameter water connection",
    label: "EF020910: Diameter water connection"
  },
  {
    featureId: "EF020911",
    featureDesc: "Max. horizontal movement",
    label: "EF020911: Max. horizontal movement"
  },
  {
    featureId: "EF020912",
    featureDesc: "Horizontal taper height",
    label: "EF020912: Horizontal taper height"
  },
  {
    featureId: "EF020913",
    featureDesc: "Toilet bowl and seat",
    label: "EF020913: Toilet bowl and seat"
  },
  {
    featureId: "EF020915",
    featureDesc: "Washbasin connection",
    label: "EF020915: Washbasin connection"
  },
  {
    featureId: "EF020916",
    featureDesc: "Fountain connection",
    label: "EF020916: Fountain connection"
  },
  {
    featureId: "EF020917",
    featureDesc: "Washbasin or fountain connection",
    label: "EF020917: Washbasin or fountain connection"
  },
  {
    featureId: "EF020918",
    featureDesc: "Shower connection",
    label: "EF020918: Shower connection"
  },
  {
    featureId: "EF020919",
    featureDesc: "Bath connection",
    label: "EF020919: Bath connection"
  },
  {
    featureId: "EF020920",
    featureDesc: "Bidet connection",
    label: "EF020920: Bidet connection"
  },
  {
    featureId: "EF020921",
    featureDesc: "Washing machine connection",
    label: "EF020921: Washing machine connection"
  },
  {
    featureId: "EF020922",
    featureDesc: "Dishwasher connection",
    label: "EF020922: Dishwasher connection"
  },
  {
    featureId: "EF020923",
    featureDesc: "Number of sprinkler heads",
    label: "EF020923: Number of sprinkler heads"
  },
  {
    featureId: "EF020924",
    featureDesc: "Self-opening",
    label: "EF020924: Self-opening"
  },
  {
    featureId: "EF020925",
    featureDesc: "With squeeze shower",
    label: "EF020925: With squeeze shower"
  },
  {
    featureId: "EF020926",
    featureDesc: "Type of finish",
    label: "EF020926: Type of finish"
  },
  {
    featureId: "EF020929",
    featureDesc: "Duct width",
    label: "EF020929: Duct width"
  },
  {
    featureId: "EF020930",
    featureDesc: "Duct depth",
    label: "EF020930: Duct depth"
  },
  {
    featureId: "EF020931",
    featureDesc: "Material framing",
    label: "EF020931: Material framing"
  },
  {
    featureId: "EF020932",
    featureDesc: "Gutter end",
    label: "EF020932: Gutter end"
  },
  {
    featureId: "EF020933",
    featureDesc: "Number of windings",
    label: "EF020933: Number of windings"
  },
  {
    featureId: "EF020934",
    featureDesc: "Shape of inlet",
    label: "EF020934: Shape of inlet"
  },
  {
    featureId: "EF020935",
    featureDesc: "Shape of outlet",
    label: "EF020935: Shape of outlet"
  },
  {
    featureId: "EF020936",
    featureDesc: "With speed controller",
    label: "EF020936: With speed controller"
  },
  {
    featureId: "EF020937",
    featureDesc: "With power level switch",
    label: "EF020937: With power level switch"
  },
  {
    featureId: "EF020941",
    featureDesc: "Fitting reducing piece",
    label: "EF020941: Fitting reducing piece"
  },
  {
    featureId: "EF020942",
    featureDesc: "Duct reducing piece",
    label: "EF020942: Duct reducing piece"
  },
  {
    featureId: "EF020943",
    featureDesc: "With chimney cover plate",
    label: "EF020943: With chimney cover plate"
  },
  {
    featureId: "EF020944",
    featureDesc: "Bend angle of main duct branch",
    label: "EF020944: Bend angle of main duct branch"
  },
  {
    featureId: "EF020947",
    featureDesc: "Roof type",
    label: "EF020947: Roof type"
  },
  {
    featureId: "EF020948",
    featureDesc: "Feed-through tile model",
    label: "EF020948: Feed-through tile model"
  },
  {
    featureId: "EF020949",
    featureDesc: "Suitable for ridge",
    label: "EF020949: Suitable for ridge"
  },
  {
    featureId: "EF020950",
    featureDesc: "Suitable for thin-walled pipe",
    label: "EF020950: Suitable for thin-walled pipe"
  },
  {
    featureId: "EF020951",
    featureDesc: "Fracture elongation",
    label: "EF020951: Fracture elongation"
  },
  {
    featureId: "EF020953",
    featureDesc: "Return elasticity",
    label: "EF020953: Return elasticity"
  },
  {
    featureId: "EF020954",
    featureDesc: "Max. through flow capacity (150 kPa)",
    label: "EF020954: Max. through flow capacity (150 kPa)"
  },
  {
    featureId: "EF020955",
    featureDesc: "Wood, impregnated",
    label: "EF020955: Wood, impregnated"
  },
  {
    featureId: "EF020956",
    featureDesc: "Response element",
    label: "EF020956: Response element"
  },
  {
    featureId: "EF020957",
    featureDesc: "Enlarged spraying surface",
    label: "EF020957: Enlarged spraying surface"
  },
  {
    featureId: "EF020959",
    featureDesc: "Number of inlets 1/2 inch",
    label: "EF020959: Number of inlets 1/2 inch"
  },
  {
    featureId: "EF020960",
    featureDesc: "Number of outlets 1/2 inch",
    label: "EF020960: Number of outlets 1/2 inch"
  },
  {
    featureId: "EF020961",
    featureDesc: "Number of outlets 3/4 inch",
    label: "EF020961: Number of outlets 3/4 inch"
  },
  {
    featureId: "EF020962",
    featureDesc: "Combined setting possible",
    label: "EF020962: Combined setting possible"
  },
  {
    featureId: "EF020963",
    featureDesc: "Extension set available",
    label: "EF020963: Extension set available"
  },
  {
    featureId: "EF020964",
    featureDesc: "Connection to draw-off points",
    label: "EF020964: Connection to draw-off points"
  },
  {
    featureId: "EF020965",
    featureDesc: "Connection size to draw-off points",
    label: "EF020965: Connection size to draw-off points"
  },
  {
    featureId: "EF020966",
    featureDesc: "Couplings can be closed",
    label: "EF020966: Couplings can be closed"
  },
  {
    featureId: "EF020967",
    featureDesc: "Temperature blocking (38 °C)",
    label: "EF020967: Temperature blocking (38 °C)"
  },
  {
    featureId: "EF020968",
    featureDesc: "With fastening eyes for front wall installations",
    label: "EF020968: With fastening eyes for front wall installations"
  },
  {
    featureId: "EF020969",
    featureDesc: "Outgoing connection",
    label: "EF020969: Outgoing connection"
  },
  {
    featureId: "EF020970",
    featureDesc: "Size of outgoing connection",
    label: "EF020970: Size of outgoing connection"
  },
  {
    featureId: "EF020971",
    featureDesc: "Built-in stop valve model",
    label: "EF020971: Built-in stop valve model"
  },
  {
    featureId: "EF020972",
    featureDesc: "With aerator",
    label: "EF020972: With aerator"
  },
  {
    featureId: "EF020973",
    featureDesc: "With coupling, 12 mm capillary",
    label: "EF020973: With coupling, 12 mm capillary"
  },
  {
    featureId: "EF020974",
    featureDesc: "Deflector",
    label: "EF020974: Deflector"
  },
  {
    featureId: "EF020975",
    featureDesc: "Suitable for high pressure",
    label: "EF020975: Suitable for high pressure"
  },
  {
    featureId: "EF020976",
    featureDesc: "Nominal diameter of sprinkler",
    label: "EF020976: Nominal diameter of sprinkler"
  },
  {
    featureId: "EF020977",
    featureDesc: "Rosette mounting method",
    label: "EF020977: Rosette mounting method"
  },
  {
    featureId: "EF020978",
    featureDesc: "Length of ceiling fitting bottom in mm",
    label: "EF020978: Length of ceiling fitting bottom in mm"
  },
  {
    featureId: "EF020979",
    featureDesc: "Length of ceiling fitting bottom in inch",
    label: "EF020979: Length of ceiling fitting bottom in inch"
  },
  {
    featureId: "EF020980",
    featureDesc: "Number of inlet ports",
    label: "EF020980: Number of inlet ports"
  },
  {
    featureId: "EF020981",
    featureDesc: "Number of outlet ports",
    label: "EF020981: Number of outlet ports"
  },
  {
    featureId: "EF020982",
    featureDesc: "Connection inlet",
    label: "EF020982: Connection inlet"
  },
  {
    featureId: "EF020983",
    featureDesc: "Shower hose connection",
    label: "EF020983: Shower hose connection"
  },
  {
    featureId: "EF020984",
    featureDesc: "Connection size shower hose",
    label: "EF020984: Connection size shower hose"
  },
  {
    featureId: "EF020985",
    featureDesc: "Wall thickness",
    label: "EF020985: Wall thickness"
  },
  {
    featureId: "EF020986",
    featureDesc: "With swivel protection",
    label: "EF020986: With swivel protection"
  },
  {
    featureId: "EF020987",
    featureDesc: "Cold water supply connection",
    label: "EF020987: Cold water supply connection"
  },
  {
    featureId: "EF020988",
    featureDesc: "Connection size cold water supply",
    label: "EF020988: Connection size cold water supply"
  },
  {
    featureId: "EF020989",
    featureDesc: "Rotating nozzle",
    label: "EF020989: Rotating nozzle"
  },
  {
    featureId: "EF020990",
    featureDesc: "With waste operation",
    label: "EF020990: With waste operation"
  },
  {
    featureId: "EF020991",
    featureDesc: "Overall depth",
    label: "EF020991: Overall depth"
  },
  {
    featureId: "EF020992",
    featureDesc: "Number of bath unit cabinets, low",
    label: "EF020992: Number of bath unit cabinets, low"
  },
  {
    featureId: "EF020993",
    featureDesc: "Number of bath unit cabinets, medium-high",
    label: "EF020993: Number of bath unit cabinets, medium-high"
  },
  {
    featureId: "EF020994",
    featureDesc: "Number of bath unit cabinets, high",
    label: "EF020994: Number of bath unit cabinets, high"
  },
  {
    featureId: "EF020995",
    featureDesc: "Number of washbasin top cabinets",
    label: "EF020995: Number of washbasin top cabinets"
  },
  {
    featureId: "EF020996",
    featureDesc: "Number of mirror doors",
    label: "EF020996: Number of mirror doors"
  },
  {
    featureId: "EF020997",
    featureDesc: "Number of washbasin mirror elements",
    label: "EF020997: Number of washbasin mirror elements"
  },
  {
    featureId: "EF020998",
    featureDesc: "Number of washbasin bottom cabinets",
    label: "EF020998: Number of washbasin bottom cabinets"
  },
  {
    featureId: "EF020999",
    featureDesc: "With lighting cove",
    label: "EF020999: With lighting cove"
  },
  {
    featureId: "EF021000",
    featureDesc: "With washing basket",
    label: "EF021000: With washing basket"
  },
  {
    featureId: "EF021001",
    featureDesc: "Model of washbasin(s)",
    label: "EF021001: Model of washbasin(s)"
  },
  {
    featureId: "EF021003",
    featureDesc: "With light switch",
    label: "EF021003: With light switch"
  },
  {
    featureId: "EF021004",
    featureDesc: "With wall socket",
    label: "EF021004: With wall socket"
  },
  {
    featureId: "EF021005",
    featureDesc: "With mirror heating",
    label: "EF021005: With mirror heating"
  },
  {
    featureId: "EF021006",
    featureDesc: "Material body",
    label: "EF021006: Material body"
  },
  {
    featureId: "EF021008",
    featureDesc: "Material front",
    label: "EF021008: Material front"
  },
  {
    featureId: "EF021009",
    featureDesc: "Model of handles",
    label: "EF021009: Model of handles"
  },
  {
    featureId: "EF021010",
    featureDesc: "Colour of cover sheet",
    label: "EF021010: Colour of cover sheet"
  },
  {
    featureId: "EF021011",
    featureDesc: "With highlight/inlay/contrast colour",
    label: "EF021011: With highlight/inlay/contrast colour"
  },
  {
    featureId: "EF021013",
    featureDesc: "With bottom lighting",
    label: "EF021013: With bottom lighting"
  },
  {
    featureId: "EF021014",
    featureDesc: "With top lighting",
    label: "EF021014: With top lighting"
  },
  {
    featureId: "EF021015",
    featureDesc: "With front lighting",
    label: "EF021015: With front lighting"
  },
  {
    featureId: "EF021016",
    featureDesc: "Number of drawers",
    label: "EF021016: Number of drawers"
  },
  {
    featureId: "EF021017",
    featureDesc: "Number of open compartments",
    label: "EF021017: Number of open compartments"
  },
  {
    featureId: "EF021020",
    featureDesc: "Number of doors (total)",
    label: "EF021020: Number of doors (total)"
  },
  {
    featureId: "EF021021",
    featureDesc: "Number of doors, left-opening",
    label: "EF021021: Number of doors, left-opening"
  },
  {
    featureId: "EF021022",
    featureDesc: "Number of doors, right-opening",
    label: "EF021022: Number of doors, right-opening"
  },
  {
    featureId: "EF021023",
    featureDesc: "Number of tumble doors",
    label: "EF021023: Number of tumble doors"
  },
  {
    featureId: "EF021024",
    featureDesc: "Number of sliding doors",
    label: "EF021024: Number of sliding doors"
  },
  {
    featureId: "EF021025",
    featureDesc: "Number of rolling doors",
    label: "EF021025: Number of rolling doors"
  },
  {
    featureId: "EF021026",
    featureDesc: "With sheets of glass",
    label: "EF021026: With sheets of glass"
  },
  {
    featureId: "EF021027",
    featureDesc: "With cover sheet",
    label: "EF021027: With cover sheet"
  },
  {
    featureId: "EF021028",
    featureDesc: "Material cover sheet",
    label: "EF021028: Material cover sheet"
  },
  {
    featureId: "EF021030",
    featureDesc: "With cover/lid",
    label: "EF021030: With cover/lid"
  },
  {
    featureId: "EF021031",
    featureDesc: "Height/thickness",
    label: "EF021031: Height/thickness"
  },
  {
    featureId: "EF021033",
    featureDesc: "With interior lighting",
    label: "EF021033: With interior lighting"
  },
  {
    featureId: "EF021034",
    featureDesc: "With safety lock",
    label: "EF021034: With safety lock"
  },
  {
    featureId: "EF021035",
    featureDesc: "Cool housing",
    label: "EF021035: Cool housing"
  },
  {
    featureId: "EF021036",
    featureDesc: "With thread end",
    label: "EF021036: With thread end"
  },
  {
    featureId: "EF021037",
    featureDesc: "Raised section",
    label: "EF021037: Raised section"
  },
  {
    featureId: "EF021038",
    featureDesc: "Min. mixing water use of draw-off point",
    label: "EF021038: Min. mixing water use of draw-off point"
  },
  {
    featureId: "EF021039",
    featureDesc: "With removable sieve",
    label: "EF021039: With removable sieve"
  },
  {
    featureId: "EF021040",
    featureDesc: "Number of spray types",
    label: "EF021040: Number of spray types"
  },
  {
    featureId: "EF021041",
    featureDesc: "Shower head diameter",
    label: "EF021041: Shower head diameter"
  },
  {
    featureId: "EF021042",
    featureDesc: "Rotating connection",
    label: "EF021042: Rotating connection"
  },
  {
    featureId: "EF021043",
    featureDesc: "Number of washbasins/washbasin openings",
    label: "EF021043: Number of washbasins/washbasin openings"
  },
  {
    featureId: "EF021044",
    featureDesc: "With trap opening(s)",
    label: "EF021044: With trap opening(s)"
  },
  {
    featureId: "EF021045",
    featureDesc: "Tray section",
    label: "EF021045: Tray section"
  },
  {
    featureId: "EF021046",
    featureDesc: "Height of back wall",
    label: "EF021046: Height of back wall"
  },
  {
    featureId: "EF021047",
    featureDesc: "Material of top",
    label: "EF021047: Material of top"
  },
  {
    featureId: "EF021048",
    featureDesc: "Material of washbasin(s)",
    label: "EF021048: Material of washbasin(s)"
  },
  {
    featureId: "EF021049",
    featureDesc: "Colour top",
    label: "EF021049: Colour top"
  },
  {
    featureId: "EF021050",
    featureDesc: "Colour of washbasin(s)",
    label: "EF021050: Colour of washbasin(s)"
  },
  {
    featureId: "EF021051",
    featureDesc: "With top",
    label: "EF021051: With top"
  },
  {
    featureId: "EF021052",
    featureDesc: "With washbasin(s)",
    label: "EF021052: With washbasin(s)"
  },
  {
    featureId: "EF021054",
    featureDesc: "Location of washbasin(s)",
    label: "EF021054: Location of washbasin(s)"
  },
  {
    featureId: "EF021057",
    featureDesc: "With insulation",
    label: "EF021057: With insulation"
  },
  {
    featureId: "EF021058",
    featureDesc: "Rosette shared",
    label: "EF021058: Rosette shared"
  },
  {
    featureId: "EF021059",
    featureDesc: "Suitable for natural gas",
    label: "EF021059: Suitable for natural gas"
  },
  {
    featureId: "EF021061",
    featureDesc: "Outer pipe diameter gas",
    label: "EF021061: Outer pipe diameter gas"
  },
  {
    featureId: "EF021064",
    featureDesc: "Surface protection of tank",
    label: "EF021064: Surface protection of tank"
  },
  {
    featureId: "EF021065",
    featureDesc: "With circulation connection",
    label: "EF021065: With circulation connection"
  },
  {
    featureId: "EF021070",
    featureDesc: "Recirculation connection",
    label: "EF021070: Recirculation connection"
  },
  {
    featureId: "EF021072",
    featureDesc: "Charging pump",
    label: "EF021072: Charging pump"
  },
  {
    featureId: "EF021073",
    featureDesc: "Tap water pump",
    label: "EF021073: Tap water pump"
  },
  {
    featureId: "EF021074",
    featureDesc: "Control equipment",
    label: "EF021074: Control equipment"
  },
  {
    featureId: "EF021075",
    featureDesc: "Legionella protection",
    label: "EF021075: Legionella protection"
  },
  {
    featureId: "EF021076",
    featureDesc: "Exchanger with dual separation",
    label: "EF021076: Exchanger with dual separation"
  },
  {
    featureId: "EF021077",
    featureDesc: "Accessories for directly-fired boiler",
    label: "EF021077: Accessories for directly-fired boiler"
  },
  {
    featureId: "EF021078",
    featureDesc: "Accessories for indirectly-fired boiler",
    label: "EF021078: Accessories for indirectly-fired boiler"
  },
  {
    featureId: "EF021079",
    featureDesc: "Accessories for electrical boiler",
    label: "EF021079: Accessories for electrical boiler"
  },
  {
    featureId: "EF021080",
    featureDesc: "Accessories for storage tank boiler",
    label: "EF021080: Accessories for storage tank boiler"
  },
  {
    featureId: "EF021083",
    featureDesc: "Material exchanger",
    label: "EF021083: Material exchanger"
  },
  {
    featureId: "EF021084",
    featureDesc: "Outer pipe diameter tap water",
    label: "EF021084: Outer pipe diameter tap water"
  },
  {
    featureId: "EF021085",
    featureDesc: "Charging pump (central heating)",
    label: "EF021085: Charging pump (central heating)"
  },
  {
    featureId: "EF021086",
    featureDesc: "Number of charging pumps",
    label: "EF021086: Number of charging pumps"
  },
  {
    featureId: "EF021087",
    featureDesc: "Three-way control valve",
    label: "EF021087: Three-way control valve"
  },
  {
    featureId: "EF021088",
    featureDesc: "Accessories/parts for gas water-boiler",
    label: "EF021088: Accessories/parts for gas water-boiler"
  },
  {
    featureId: "EF021089",
    featureDesc: "Accessories/parts for hot tap water heater",
    label: "EF021089: Accessories/parts for hot tap water heater"
  },
  {
    featureId: "EF021091",
    featureDesc: "Secondary temperature",
    label: "EF021091: Secondary temperature"
  },
  {
    featureId: "EF021092",
    featureDesc: "Number of plates",
    label: "EF021092: Number of plates"
  },
  {
    featureId: "EF021093",
    featureDesc: "Secondary connection",
    label: "EF021093: Secondary connection"
  },
  {
    featureId: "EF021094",
    featureDesc: "Nominal diameter secondary connection",
    label: "EF021094: Nominal diameter secondary connection"
  },
  {
    featureId: "EF021095",
    featureDesc: "Outer pipe diameter secondary connection",
    label: "EF021095: Outer pipe diameter secondary connection"
  },
  {
    featureId: "EF021096",
    featureDesc: "Connection size 1",
    label: "EF021096: Connection size 1"
  },
  {
    featureId: "EF021097",
    featureDesc: "Connection size 2",
    label: "EF021097: Connection size 2"
  },
  {
    featureId: "EF021098",
    featureDesc: "Drain location in roof flashing",
    label: "EF021098: Drain location in roof flashing"
  },
  {
    featureId: "EF021101",
    featureDesc: "Side outlet",
    label: "EF021101: Side outlet"
  },
  {
    featureId: "EF021102",
    featureDesc: "Inner diameter hose",
    label: "EF021102: Inner diameter hose"
  },
  {
    featureId: "EF021105",
    featureDesc: "Suitable for cooled water",
    label: "EF021105: Suitable for cooled water"
  },
  {
    featureId: "EF021108",
    featureDesc: "Nominal diameter hot inlet",
    label: "EF021108: Nominal diameter hot inlet"
  },
  {
    featureId: "EF021110",
    featureDesc: "Connection, hot inlet",
    label: "EF021110: Connection, hot inlet"
  },
  {
    featureId: "EF021111",
    featureDesc: "Nominal diameter cold outlet",
    label: "EF021111: Nominal diameter cold outlet"
  },
  {
    featureId: "EF021112",
    featureDesc: "Connection, cold outlet",
    label: "EF021112: Connection, cold outlet"
  },
  {
    featureId: "EF021113",
    featureDesc: "Number of heating elements",
    label: "EF021113: Number of heating elements"
  },
  {
    featureId: "EF021114",
    featureDesc: "With pressure expansion vessel",
    label: "EF021114: With pressure expansion vessel"
  },
  {
    featureId: "EF021115",
    featureDesc: "Boiler water volume",
    label: "EF021115: Boiler water volume"
  },
  {
    featureId: "EF021116",
    featureDesc: "Expansion tank water volume",
    label: "EF021116: Expansion tank water volume"
  },
  {
    featureId: "EF021117",
    featureDesc: "Max. pressure heating",
    label: "EF021117: Max. pressure heating"
  },
  {
    featureId: "EF021118",
    featureDesc: "With hot tap water facility",
    label: "EF021118: With hot tap water facility"
  },
  {
    featureId: "EF021119",
    featureDesc: "With hot tap water preference",
    label: "EF021119: With hot tap water preference"
  },
  {
    featureId: "EF021120",
    featureDesc: "Max. pressure of hot tap water",
    label: "EF021120: Max. pressure of hot tap water"
  },
  {
    featureId: "EF021121",
    featureDesc: "Water volume of hot tap water",
    label: "EF021121: Water volume of hot tap water"
  },
  {
    featureId: "EF021122",
    featureDesc: "Max. control temperature",
    label: "EF021122: Max. control temperature"
  },
  {
    featureId: "EF021123",
    featureDesc: "Modulating",
    label: "EF021123: Modulating"
  },
  {
    featureId: "EF021124",
    featureDesc: "With day/night function",
    label: "EF021124: With day/night function"
  },
  {
    featureId: "EF021125",
    featureDesc: "Nominal diameter of membrane valve",
    label: "EF021125: Nominal diameter of membrane valve"
  },
  {
    featureId: "EF021126",
    featureDesc: "Membrane suitable for central heating water",
    label: "EF021126: Membrane suitable for central heating water"
  },
  {
    featureId: "EF021127",
    featureDesc: "Membrane suitable for cooled water",
    label: "EF021127: Membrane suitable for cooled water"
  },
  {
    featureId: "EF021128",
    featureDesc: "Membrane suitable for tap water",
    label: "EF021128: Membrane suitable for tap water"
  },
  {
    featureId: "EF021129",
    featureDesc: "Membrane suitable for hot tap water",
    label: "EF021129: Membrane suitable for hot tap water"
  },
  {
    featureId: "EF021130",
    featureDesc: "Membrane suitable for natural gas",
    label: "EF021130: Membrane suitable for natural gas"
  },
  {
    featureId: "EF021131",
    featureDesc: "With rubber seal",
    label: "EF021131: With rubber seal"
  },
  {
    featureId: "EF021132",
    featureDesc: "Boiler connection",
    label: "EF021132: Boiler connection"
  },
  {
    featureId: "EF021133",
    featureDesc: "With outdoor sensor",
    label: "EF021133: With outdoor sensor"
  },
  {
    featureId: "EF021134",
    featureDesc: "With supply sensor",
    label: "EF021134: With supply sensor"
  },
  {
    featureId: "EF021136",
    featureDesc: "Programme clock",
    label: "EF021136: Programme clock"
  },
  {
    featureId: "EF021139",
    featureDesc: "With pressure reducing valve",
    label: "EF021139: With pressure reducing valve"
  },
  {
    featureId: "EF021142",
    featureDesc: "With pump circuit",
    label: "EF021142: With pump circuit"
  },
  {
    featureId: "EF021143",
    featureDesc: "With weather-dependent controller",
    label: "EF021143: With weather-dependent controller"
  },
  {
    featureId: "EF021146",
    featureDesc: "Adjustable width",
    label: "EF021146: Adjustable width"
  },
  {
    featureId: "EF021147",
    featureDesc: "Drain connection height",
    label: "EF021147: Drain connection height"
  },
  {
    featureId: "EF021148",
    featureDesc: "Drain connection width",
    label: "EF021148: Drain connection width"
  },
  {
    featureId: "EF021149",
    featureDesc: "With room sensor",
    label: "EF021149: With room sensor"
  },
  {
    featureId: "EF021151",
    featureDesc: "Centre-to-centre distance of connections",
    label: "EF021151: Centre-to-centre distance of connections"
  },
  {
    featureId: "EF021152",
    featureDesc: "Suitable for insulation shell",
    label: "EF021152: Suitable for insulation shell"
  },
  {
    featureId: "EF021153",
    featureDesc: "Max. depth of exchanger",
    label: "EF021153: Max. depth of exchanger"
  },
  {
    featureId: "EF021154",
    featureDesc: "With wireless remote control",
    label: "EF021154: With wireless remote control"
  },
  {
    featureId: "EF021155",
    featureDesc: "Hot tap water capacity at 40 °C, continuous",
    label: "EF021155: Hot tap water capacity at 40 °C, continuous"
  },
  {
    featureId: "EF021157",
    featureDesc: "Heat transfer",
    label: "EF021157: Heat transfer"
  },
  {
    featureId: "EF021158",
    featureDesc: "Max. transferable capacity 90 / 10-65",
    label: "EF021158: Max. transferable capacity 90 / 10-65"
  },
  {
    featureId: "EF021159",
    featureDesc: "Max. tap water temperature",
    label: "EF021159: Max. tap water temperature"
  },
  {
    featureId: "EF021160",
    featureDesc: "Tap water-side heating, combination boiler",
    label: "EF021160: Tap water-side heating, combination boiler"
  },
  {
    featureId: "EF021161",
    featureDesc: "Peak consumption 10 min / 40 °C",
    label: "EF021161: Peak consumption 10 min / 40 °C"
  },
  {
    featureId: "EF021162",
    featureDesc: "Gas pipe connection",
    label: "EF021162: Gas pipe connection"
  },
  {
    featureId: "EF021164",
    featureDesc: "Outer pipe diameter gas pipe",
    label: "EF021164: Outer pipe diameter gas pipe"
  },
  {
    featureId: "EF021171",
    featureDesc: "Valve travel",
    label: "EF021171: Valve travel"
  },
  {
    featureId: "EF021172",
    featureDesc: "Suitable for butterfly valve",
    label: "EF021172: Suitable for butterfly valve"
  },
  {
    featureId: "EF021174",
    featureDesc: "Nominal butterfly valve bore",
    label: "EF021174: Nominal butterfly valve bore"
  },
  {
    featureId: "EF021175",
    featureDesc: "Suitable for nominal valve bore (DN)",
    label: "EF021175: Suitable for nominal valve bore (DN)"
  },
  {
    featureId: "EF021176",
    featureDesc: "Lowest medium temperature",
    label: "EF021176: Lowest medium temperature"
  },
  {
    featureId: "EF021177",
    featureDesc: "Programming unit",
    label: "EF021177: Programming unit"
  },
  {
    featureId: "EF021180",
    featureDesc: "Suitable for ceiling mounting",
    label: "EF021180: Suitable for ceiling mounting"
  },
  {
    featureId: "EF021181",
    featureDesc: "Suitable for duct mounting",
    label: "EF021181: Suitable for duct mounting"
  },
  {
    featureId: "EF021184",
    featureDesc: "Number of smoke alarms",
    label: "EF021184: Number of smoke alarms"
  },
  {
    featureId: "EF021187",
    featureDesc: "Size of pipe connection",
    label: "EF021187: Size of pipe connection"
  },
  {
    featureId: "EF021188",
    featureDesc: "Female pipe connection",
    label: "EF021188: Female pipe connection"
  },
  {
    featureId: "EF021189",
    featureDesc: "Suitable for filling/drain tap",
    label: "EF021189: Suitable for filling/drain tap"
  },
  {
    featureId: "EF021190",
    featureDesc: "Supply on radiator side",
    label: "EF021190: Supply on radiator side"
  },
  {
    featureId: "EF021191",
    featureDesc: "Suitable for radiator connection 3/4 inch outer",
    label: "EF021191: Suitable for radiator connection 3/4 inch outer"
  },
  {
    featureId: "EF021192",
    featureDesc: "Suitable for radiator connection 1/2 inch inner",
    label: "EF021192: Suitable for radiator connection 1/2 inch inner"
  },
  {
    featureId: "EF021193",
    featureDesc: "Four-way supply/return connection",
    label: "EF021193: Four-way supply/return connection"
  },
  {
    featureId: "EF021194",
    featureDesc: "Supply on control side",
    label: "EF021194: Supply on control side"
  },
  {
    featureId: "EF021195",
    featureDesc: "Suitable for hot tap water",
    label: "EF021195: Suitable for hot tap water"
  },
  {
    featureId: "EF021196",
    featureDesc: "Connection to valve",
    label: "EF021196: Connection to valve"
  },
  {
    featureId: "EF021197",
    featureDesc: "Blocking/limiting adjustment range",
    label: "EF021197: Blocking/limiting adjustment range"
  },
  {
    featureId: "EF021199",
    featureDesc: "Children’s toilet",
    label: "EF021199: Children’s toilet"
  },
  {
    featureId: "EF021200",
    featureDesc: "Centre distance of wall-hung toilet fastener",
    label: "EF021200: Centre distance of wall-hung toilet fastener"
  },
  {
    featureId: "EF021202",
    featureDesc: "With back wall",
    label: "EF021202: With back wall"
  },
  {
    featureId: "EF021203",
    featureDesc: "Support plateau",
    label: "EF021203: Support plateau"
  },
  {
    featureId: "EF021204",
    featureDesc: "Number of washbasins",
    label: "EF021204: Number of washbasins"
  },
  {
    featureId: "EF021206",
    featureDesc: "Suitable for column",
    label: "EF021206: Suitable for column"
  },
  {
    featureId: "EF021207",
    featureDesc: "Suitable for legs",
    label: "EF021207: Suitable for legs"
  },
  {
    featureId: "EF021208",
    featureDesc: "Suitable for cabinet",
    label: "EF021208: Suitable for cabinet"
  },
  {
    featureId: "EF021209",
    featureDesc: "With integrated soap tray",
    label: "EF021209: With integrated soap tray"
  },
  {
    featureId: "EF021210",
    featureDesc: "With drilled hole for soap dispenser",
    label: "EF021210: With drilled hole for soap dispenser"
  },
  {
    featureId: "EF021211",
    featureDesc: "Deactivated when closed",
    label: "EF021211: Deactivated when closed"
  },
  {
    featureId: "EF021212",
    featureDesc: "Connection to stop valve",
    label: "EF021212: Connection to stop valve"
  },
  {
    featureId: "EF021213",
    featureDesc: "With battery",
    label: "EF021213: With battery"
  },
  {
    featureId: "EF021214",
    featureDesc: "Max. number of pipes",
    label: "EF021214: Max. number of pipes"
  },
  {
    featureId: "EF021215",
    featureDesc: "Number of groups",
    label: "EF021215: Number of groups"
  },
  {
    featureId: "EF021216",
    featureDesc: "Rosette height",
    label: "EF021216: Rosette height"
  },
  {
    featureId: "EF021217",
    featureDesc: "Rosette type",
    label: "EF021217: Rosette type"
  },
  {
    featureId: "EF021220",
    featureDesc: "Shape of passage",
    label: "EF021220: Shape of passage"
  },
  {
    featureId: "EF021221",
    featureDesc: "Lead flashing on all sides",
    label: "EF021221: Lead flashing on all sides"
  },
  {
    featureId: "EF021222",
    featureDesc: "Suitable for pitched roof",
    label: "EF021222: Suitable for pitched roof"
  },
  {
    featureId: "EF021223",
    featureDesc: "Heat recovery connection",
    label: "EF021223: Heat recovery connection"
  },
  {
    featureId: "EF021224",
    featureDesc: "Nominal diameter, connection 1",
    label: "EF021224: Nominal diameter, connection 1"
  },
  {
    featureId: "EF021225",
    featureDesc: "Nominal diameter, connection 2",
    label: "EF021225: Nominal diameter, connection 2"
  },
  {
    featureId: "EF021226",
    featureDesc: "Roof flashing with angled piece",
    label: "EF021226: Roof flashing with angled piece"
  },
  {
    featureId: "EF021227",
    featureDesc: "Angled piece diameter",
    label: "EF021227: Angled piece diameter"
  },
  {
    featureId: "EF021228",
    featureDesc: "Outer diameter of lip ring",
    label: "EF021228: Outer diameter of lip ring"
  },
  {
    featureId: "EF021229",
    featureDesc: "Position of lips",
    label: "EF021229: Position of lips"
  },
  {
    featureId: "EF021230",
    featureDesc: "Suitable for PPS flue pipe",
    label: "EF021230: Suitable for PPS flue pipe"
  },
  {
    featureId: "EF021231",
    featureDesc: "Suitable for aluminium flue pipe",
    label: "EF021231: Suitable for aluminium flue pipe"
  },
  {
    featureId: "EF021232",
    featureDesc: "Suitable for stainless steel flue pipe",
    label: "EF021232: Suitable for stainless steel flue pipe"
  },
  {
    featureId: "EF021233",
    featureDesc: "Nominal diameter of insert end",
    label: "EF021233: Nominal diameter of insert end"
  },
  {
    featureId: "EF021234",
    featureDesc: "Nominal diameter of push-on end",
    label: "EF021234: Nominal diameter of push-on end"
  },
  {
    featureId: "EF021235",
    featureDesc: "Working depth",
    label: "EF021235: Working depth"
  },
  {
    featureId: "EF021236",
    featureDesc: "Air supply connection",
    label: "EF021236: Air supply connection"
  },
  {
    featureId: "EF021237",
    featureDesc: "Flue connection",
    label: "EF021237: Flue connection"
  },
  {
    featureId: "EF021238",
    featureDesc: "Anschluss für mechanische Lüftung/Wärmerückgewinnung",
    label: "EF021238: Anschluss für mechanische Lüftung/Wärmerückgewinnung"
  },
  {
    featureId: "EF021239",
    featureDesc: "Sewer de-aeration connection",
    label: "EF021239: Sewer de-aeration connection"
  },
  {
    featureId: "EF021240",
    featureDesc: "Concentric flue/air supply",
    label: "EF021240: Concentric flue/air supply"
  },
  {
    featureId: "EF021241",
    featureDesc: "Diameter of air supply",
    label: "EF021241: Diameter of air supply"
  },
  {
    featureId: "EF021242",
    featureDesc: "Diameter of mechanical ventilation/heat recovery",
    label: "EF021242: Diameter of mechanical ventilation/heat recovery"
  },
  {
    featureId: "EF021243",
    featureDesc: "Diameter of sewer de-aeration",
    label: "EF021243: Diameter of sewer de-aeration"
  },
  {
    featureId: "EF021246",
    featureDesc: "Suitable for plastic multi-layer pipes",
    label: "EF021246: Suitable for plastic multi-layer pipes"
  },
  {
    featureId: "EF021250",
    featureDesc: "Size of radiator connection",
    label: "EF021250: Size of radiator connection"
  },
  {
    featureId: "EF021251",
    featureDesc: "Kv value",
    label: "EF021251: Kv value"
  },
  {
    featureId: "EF021252",
    featureDesc: "Shortened construction length",
    label: "EF021252: Shortened construction length"
  },
  {
    featureId: "EF021253",
    featureDesc: "Reverse direction of flow",
    label: "EF021253: Reverse direction of flow"
  },
  {
    featureId: "EF021255",
    featureDesc: "Kv 2 value",
    label: "EF021255: Kv 2 value"
  },
  {
    featureId: "EF021257",
    featureDesc: "Length of cable/capillary tube to remote sensor",
    label: "EF021257: Length of cable/capillary tube to remote sensor"
  },
  {
    featureId: "EF021258",
    featureDesc: "Max. length of cable/capillary tube to remote control element",
    label: "EF021258: Max. length of cable/capillary tube to remote control element"
  },
  {
    featureId: "EF021259",
    featureDesc: "Diameter remote sensor",
    label: "EF021259: Diameter remote sensor"
  },
  {
    featureId: "EF021260",
    featureDesc: "Length remote sensor",
    label: "EF021260: Length remote sensor"
  },
  {
    featureId: "EF021261",
    featureDesc: "Suitable for radiator",
    label: "EF021261: Suitable for radiator"
  },
  {
    featureId: "EF021262",
    featureDesc: "Temperature sensor model",
    label: "EF021262: Temperature sensor model"
  },
  {
    featureId: "EF021263",
    featureDesc: "Suitable for immersion tube",
    label: "EF021263: Suitable for immersion tube"
  },
  {
    featureId: "EF021265",
    featureDesc: "Suitable for tap water",
    label: "EF021265: Suitable for tap water"
  },
  {
    featureId: "EF021266",
    featureDesc: "Connection size insert probe",
    label: "EF021266: Connection size insert probe"
  },
  {
    featureId: "EF021268",
    featureDesc: "Support block lockable",
    label: "EF021268: Support block lockable"
  },
  {
    featureId: "EF021269",
    featureDesc: "Support block adjustable",
    label: "EF021269: Support block adjustable"
  },
  {
    featureId: "EF021270",
    featureDesc: "Type of radiator valve",
    label: "EF021270: Type of radiator valve"
  },
  {
    featureId: "EF021271",
    featureDesc: "Radiator valve preset",
    label: "EF021271: Radiator valve preset"
  },
  {
    featureId: "EF021272",
    featureDesc: "Radiator stop valve accessories",
    label: "EF021272: Radiator stop valve accessories"
  },
  {
    featureId: "EF021273",
    featureDesc: "Lower connecting block accessories",
    label: "EF021273: Lower connecting block accessories"
  },
  {
    featureId: "EF021274",
    featureDesc: "Radiator thermostat button accessories",
    label: "EF021274: Radiator thermostat button accessories"
  },
  {
    featureId: "EF021275",
    featureDesc: "Radiator foot valve accessories",
    label: "EF021275: Radiator foot valve accessories"
  },
  {
    featureId: "EF021276",
    featureDesc: "With angle fillet",
    label: "EF021276: With angle fillet"
  },
  {
    featureId: "EF021277",
    featureDesc: "With fall",
    label: "EF021277: With fall"
  },
  {
    featureId: "EF021278",
    featureDesc: "With pre-stamping for pipes",
    label: "EF021278: With pre-stamping for pipes"
  },
  {
    featureId: "EF021279",
    featureDesc: "With internal insulation",
    label: "EF021279: With internal insulation"
  },
  {
    featureId: "EF021280",
    featureDesc: "Suitable for central heating water",
    label: "EF021280: Suitable for central heating water"
  },
  {
    featureId: "EF021281",
    featureDesc: "Suitable for toilet water",
    label: "EF021281: Suitable for toilet water"
  },
  {
    featureId: "EF021282",
    featureDesc: "Suitable for gas",
    label: "EF021282: Suitable for gas"
  },
  {
    featureId: "EF021284",
    featureDesc: "With inlet combination",
    label: "EF021284: With inlet combination"
  },
  {
    featureId: "EF021285",
    featureDesc: "With gas valve",
    label: "EF021285: With gas valve"
  },
  {
    featureId: "EF021287",
    featureDesc: "With service valve",
    label: "EF021287: With service valve"
  },
  {
    featureId: "EF021288",
    featureDesc: "With boiler sensor",
    label: "EF021288: With boiler sensor"
  },
  {
    featureId: "EF021289",
    featureDesc: "With three-way valve",
    label: "EF021289: With three-way valve"
  },
  {
    featureId: "EF021291",
    featureDesc: "With insulated jacket",
    label: "EF021291: With insulated jacket"
  },
  {
    featureId: "EF021292",
    featureDesc: "With mechanical setting indicator",
    label: "EF021292: With mechanical setting indicator"
  },
  {
    featureId: "EF021293",
    featureDesc: "With electronic setting indicator",
    label: "EF021293: With electronic setting indicator"
  },
  {
    featureId: "EF021294",
    featureDesc: "Max. mounting height (exhaust height)",
    label: "EF021294: Max. mounting height (exhaust height)"
  },
  {
    featureId: "EF021295",
    featureDesc: "Number of fan settings",
    label: "EF021295: Number of fan settings"
  },
  {
    featureId: "EF021297",
    featureDesc: "Connection voltage of fans",
    label: "EF021297: Connection voltage of fans"
  },
  {
    featureId: "EF021298",
    featureDesc: "Connection voltage of heating element(s)",
    label: "EF021298: Connection voltage of heating element(s)"
  },
  {
    featureId: "EF021299",
    featureDesc: "Max. input power fans",
    label: "EF021299: Max. input power fans"
  },
  {
    featureId: "EF021300",
    featureDesc: "Max. input current of fans",
    label: "EF021300: Max. input current of fans"
  },
  {
    featureId: "EF021301",
    featureDesc: "Max. input current of heater",
    label: "EF021301: Max. input current of heater"
  },
  {
    featureId: "EF021302",
    featureDesc: "Max. sound level (at a distance of 3 metres)",
    label: "EF021302: Max. sound level (at a distance of 3 metres)"
  },
  {
    featureId: "EF021303",
    featureDesc: "With air filter",
    label: "EF021303: With air filter"
  },
  {
    featureId: "EF021304",
    featureDesc: "Cassette model",
    label: "EF021304: Cassette model"
  },
  {
    featureId: "EF021305",
    featureDesc: "Max. heating power 90/70 - 20 °C",
    label: "EF021305: Max. heating power 90/70 - 20 °C"
  },
  {
    featureId: "EF021306",
    featureDesc: "Max. heating power 60/40 - 20 °C",
    label: "EF021306: Max. heating power 60/40 - 20 °C"
  },
  {
    featureId: "EF021307",
    featureDesc: "Suitable for low-temperature heating",
    label: "EF021307: Suitable for low-temperature heating"
  },
  {
    featureId: "EF021308",
    featureDesc: "Position of water connection",
    label: "EF021308: Position of water connection"
  },
  {
    featureId: "EF021309",
    featureDesc: "With water-side controls",
    label: "EF021309: With water-side controls"
  },
  {
    featureId: "EF021313",
    featureDesc: "Water connection, top",
    label: "EF021313: Water connection, top"
  },
  {
    featureId: "EF021319",
    featureDesc: "With flushing bend",
    label: "EF021319: With flushing bend"
  },
  {
    featureId: "EF021320",
    featureDesc: "Self-bearing construction",
    label: "EF021320: Self-bearing construction"
  },
  {
    featureId: "EF021321",
    featureDesc: "With drainage bend",
    label: "EF021321: With drainage bend"
  },
  {
    featureId: "EF021322",
    featureDesc: "With mounting material for the element",
    label: "EF021322: With mounting material for the element"
  },
  {
    featureId: "EF021323",
    featureDesc: "With bidet attachment materials",
    label: "EF021323: With bidet attachment materials"
  },
  {
    featureId: "EF021324",
    featureDesc: "With built-in siphon",
    label: "EF021324: With built-in siphon"
  },
  {
    featureId: "EF021325",
    featureDesc: "With washbasin attachment materials",
    label: "EF021325: With washbasin attachment materials"
  },
  {
    featureId: "EF021326",
    featureDesc: "With mounting material for the toilet",
    label: "EF021326: With mounting material for the toilet"
  },
  {
    featureId: "EF021327",
    featureDesc: "Suitable for radar operation",
    label: "EF021327: Suitable for radar operation"
  },
  {
    featureId: "EF021328",
    featureDesc: "Suitable for infrared operation",
    label: "EF021328: Suitable for infrared operation"
  },
  {
    featureId: "EF021329",
    featureDesc: "With urinal attachment materials",
    label: "EF021329: With urinal attachment materials"
  },
  {
    featureId: "EF021330",
    featureDesc: "With sink attachment materials",
    label: "EF021330: With sink attachment materials"
  },
  {
    featureId: "EF021331",
    featureDesc: "Hygrostat",
    label: "EF021331: Hygrostat"
  },
  {
    featureId: "EF021332",
    featureDesc: "Louver",
    label: "EF021332: Louver"
  },
  {
    featureId: "EF021333",
    featureDesc: "Size of thermostatic adjustment element connection",
    label: "EF021333: Size of thermostatic adjustment element connection"
  },
  {
    featureId: "EF021335",
    featureDesc: "Set type",
    label: "EF021335: Set type"
  },
  {
    featureId: "EF021336",
    featureDesc: "Radiator valve model",
    label: "EF021336: Radiator valve model"
  },
  {
    featureId: "EF021337",
    featureDesc: "Length of spindle extension",
    label: "EF021337: Length of spindle extension"
  },
  {
    featureId: "EF021338",
    featureDesc: "Connecting material accessories",
    label: "EF021338: Connecting material accessories"
  },
  {
    featureId: "EF021339",
    featureDesc: "Length of extended tailpiece",
    label: "EF021339: Length of extended tailpiece"
  },
  {
    featureId: "EF021340",
    featureDesc: "With thermometer connection",
    label: "EF021340: With thermometer connection"
  },
  {
    featureId: "EF021341",
    featureDesc: "Temperature setting range",
    label: "EF021341: Temperature setting range"
  },
  {
    featureId: "EF021342",
    featureDesc: "Min. flow",
    label: "EF021342: Min. flow"
  },
  {
    featureId: "EF021343",
    featureDesc: "Disinfection function",
    label: "EF021343: Disinfection function"
  },
  {
    featureId: "EF021344",
    featureDesc: "With thermostat button",
    label: "EF021344: With thermostat button"
  },
  {
    featureId: "EF021346",
    featureDesc: "With design cover",
    label: "EF021346: With design cover"
  },
  {
    featureId: "EF021349",
    featureDesc: "Room temperature control",
    label: "EF021349: Room temperature control"
  },
  {
    featureId: "EF021350",
    featureDesc: "Water temperature control",
    label: "EF021350: Water temperature control"
  },
  {
    featureId: "EF021351",
    featureDesc: "Can be preset",
    label: "EF021351: Can be preset"
  },
  {
    featureId: "EF021353",
    featureDesc: "Wall thickness, connection 3",
    label: "EF021353: Wall thickness, connection 3"
  },
  {
    featureId: "EF021354",
    featureDesc: "Wall thickness, connection 4",
    label: "EF021354: Wall thickness, connection 4"
  },
  {
    featureId: "EF021356",
    featureDesc: "Max. angle of handle",
    label: "EF021356: Max. angle of handle"
  },
  {
    featureId: "EF021357",
    featureDesc: "Max. capacity",
    label: "EF021357: Max. capacity"
  },
  {
    featureId: "EF021359",
    featureDesc: "With strainer basket",
    label: "EF021359: With strainer basket"
  },
  {
    featureId: "EF021360",
    featureDesc: "Temperature readable",
    label: "EF021360: Temperature readable"
  },
  {
    featureId: "EF021361",
    featureDesc: "Clock",
    label: "EF021361: Clock"
  },
  {
    featureId: "EF021362",
    featureDesc: "Optimisation",
    label: "EF021362: Optimisation"
  },
  {
    featureId: "EF021363",
    featureDesc: "Number of switching programmes",
    label: "EF021363: Number of switching programmes"
  },
  {
    featureId: "EF021365",
    featureDesc: "Fixed cable to sensor",
    label: "EF021365: Fixed cable to sensor"
  },
  {
    featureId: "EF021366",
    featureDesc: "Material element",
    label: "EF021366: Material element"
  },
  {
    featureId: "EF021367",
    featureDesc: "Max. airspeed",
    label: "EF021367: Max. airspeed"
  },
  {
    featureId: "EF021368",
    featureDesc: "Safety device type",
    label: "EF021368: Safety device type"
  },
  {
    featureId: "EF021369",
    featureDesc: "Test principle",
    label: "EF021369: Test principle"
  },
  {
    featureId: "EF021370",
    featureDesc: "Gas type measurement",
    label: "EF021370: Gas type measurement"
  },
  {
    featureId: "EF021371",
    featureDesc: "Average test length",
    label: "EF021371: Average test length"
  },
  {
    featureId: "EF021372",
    featureDesc: "Alarm contact circuit",
    label: "EF021372: Alarm contact circuit"
  },
  {
    featureId: "EF021373",
    featureDesc: "Contact load",
    label: "EF021373: Contact load"
  },
  {
    featureId: "EF021374",
    featureDesc: "Build into gas line",
    label: "EF021374: Build into gas line"
  },
  {
    featureId: "EF021375",
    featureDesc: "Position between magnetic valves",
    label: "EF021375: Position between magnetic valves"
  },
  {
    featureId: "EF021376",
    featureDesc: "Installation",
    label: "EF021376: Installation"
  },
  {
    featureId: "EF021377",
    featureDesc: "Min. oil pressure",
    label: "EF021377: Min. oil pressure"
  },
  {
    featureId: "EF021378",
    featureDesc: "Max. oil pressure",
    label: "EF021378: Max. oil pressure"
  },
  {
    featureId: "EF021379",
    featureDesc: "Outer pipe diameter oil connection",
    label: "EF021379: Outer pipe diameter oil connection"
  },
  {
    featureId: "EF021380",
    featureDesc: "Built-in oil filter",
    label: "EF021380: Built-in oil filter"
  },
  {
    featureId: "EF021381",
    featureDesc: "Built-in operating hours counter",
    label: "EF021381: Built-in operating hours counter"
  },
  {
    featureId: "EF021382",
    featureDesc: "Oil connection",
    label: "EF021382: Oil connection"
  },
  {
    featureId: "EF021383",
    featureDesc: "Gas connection",
    label: "EF021383: Gas connection"
  },
  {
    featureId: "EF021384",
    featureDesc: "Oil preheater",
    label: "EF021384: Oil preheater"
  },
  {
    featureId: "EF021385",
    featureDesc: "Number of atomisers",
    label: "EF021385: Number of atomisers"
  },
  {
    featureId: "EF021386",
    featureDesc: "Cold tap water connection",
    label: "EF021386: Cold tap water connection"
  },
  {
    featureId: "EF021387",
    featureDesc: "Hot tap water connection",
    label: "EF021387: Hot tap water connection"
  },
  {
    featureId: "EF021388",
    featureDesc: "Outer pipe diameter circulation pipe",
    label: "EF021388: Outer pipe diameter circulation pipe"
  },
  {
    featureId: "EF021389",
    featureDesc: "Circulation pipe connection",
    label: "EF021389: Circulation pipe connection"
  },
  {
    featureId: "EF021390",
    featureDesc: "Outer pipe diameter drainage connection",
    label: "EF021390: Outer pipe diameter drainage connection"
  },
  {
    featureId: "EF021391",
    featureDesc: "Connection, drainage connection",
    label: "EF021391: Connection, drainage connection"
  },
  {
    featureId: "EF021393",
    featureDesc: "Sensor length",
    label: "EF021393: Sensor length"
  },
  {
    featureId: "EF021395",
    featureDesc: "Sensor type (temperature)",
    label: "EF021395: Sensor type (temperature)"
  },
  {
    featureId: "EF021399",
    featureDesc: "Vertical positioning",
    label: "EF021399: Vertical positioning"
  },
  {
    featureId: "EF021412",
    featureDesc: "Open model",
    label: "EF021412: Open model"
  },
  {
    featureId: "EF021413",
    featureDesc: "Burner opening diameter",
    label: "EF021413: Burner opening diameter"
  },
  {
    featureId: "EF021414",
    featureDesc: "Electric power of system",
    label: "EF021414: Electric power of system"
  },
  {
    featureId: "EF021416",
    featureDesc: "Suitable for medium",
    label: "EF021416: Suitable for medium"
  },
  {
    featureId: "EF021417",
    featureDesc: "Measuring element",
    label: "EF021417: Measuring element"
  },
  {
    featureId: "EF021418",
    featureDesc: "Number of switching stages",
    label: "EF021418: Number of switching stages"
  },
  {
    featureId: "EF021419",
    featureDesc: "Differential between switch steps",
    label: "EF021419: Differential between switch steps"
  },
  {
    featureId: "EF021420",
    featureDesc: "Contact lock",
    label: "EF021420: Contact lock"
  },
  {
    featureId: "EF021421",
    featureDesc: "Material vane",
    label: "EF021421: Material vane"
  },
  {
    featureId: "EF021422",
    featureDesc: "Vane length",
    label: "EF021422: Vane length"
  },
  {
    featureId: "EF021423",
    featureDesc: "Vane width",
    label: "EF021423: Vane width"
  },
  {
    featureId: "EF021424",
    featureDesc: "Vane thickness",
    label: "EF021424: Vane thickness"
  },
  {
    featureId: "EF021427",
    featureDesc: "With gland nut and soldering pipe, connection 1",
    label: "EF021427: With gland nut and soldering pipe, connection 1"
  },
  {
    featureId: "EF021428",
    featureDesc: "With tension socket, connection 2",
    label: "EF021428: With tension socket, connection 2"
  },
  {
    featureId: "EF021429",
    featureDesc: "With inspection flange",
    label: "EF021429: With inspection flange"
  },
  {
    featureId: "EF021430",
    featureDesc: "Material float",
    label: "EF021430: Material float"
  },
  {
    featureId: "EF021431",
    featureDesc: "Float rod length",
    label: "EF021431: Float rod length"
  },
  {
    featureId: "EF021432",
    featureDesc: "Float diameter",
    label: "EF021432: Float diameter"
  },
  {
    featureId: "EF021435",
    featureDesc: "Waste stopper diameter",
    label: "EF021435: Waste stopper diameter"
  },
  {
    featureId: "EF021436",
    featureDesc: "Make and type-based",
    label: "EF021436: Make and type-based"
  },
  {
    featureId: "EF021438",
    featureDesc: "Type of tile",
    label: "EF021438: Type of tile"
  },
  {
    featureId: "EF021439",
    featureDesc: "Horizontal lead-through",
    label: "EF021439: Horizontal lead-through"
  },
  {
    featureId: "EF021440",
    featureDesc: "Vertical lead-through",
    label: "EF021440: Vertical lead-through"
  },
  {
    featureId: "EF021442",
    featureDesc: "From fuel",
    label: "EF021442: From fuel"
  },
  {
    featureId: "EF021443",
    featureDesc: "To fuel",
    label: "EF021443: To fuel"
  },
  {
    featureId: "EF021445",
    featureDesc: "Number of jets in base",
    label: "EF021445: Number of jets in base"
  },
  {
    featureId: "EF021446",
    featureDesc: "Number of jets in sides",
    label: "EF021446: Number of jets in sides"
  },
  {
    featureId: "EF021448",
    featureDesc: "Number of direction-adjustable jets",
    label: "EF021448: Number of direction-adjustable jets"
  },
  {
    featureId: "EF021449",
    featureDesc: "With underwater lighting",
    label: "EF021449: With underwater lighting"
  },
  {
    featureId: "EF021451",
    featureDesc: "With motif",
    label: "EF021451: With motif"
  },
  {
    featureId: "EF021453",
    featureDesc: "Frame material",
    label: "EF021453: Frame material"
  },
  {
    featureId: "EF021455",
    featureDesc: "Ancillary leg",
    label: "EF021455: Ancillary leg"
  },
  {
    featureId: "EF021456",
    featureDesc: "Arm support",
    label: "EF021456: Arm support"
  },
  {
    featureId: "EF021457",
    featureDesc: "Adjustable height",
    label: "EF021457: Adjustable height"
  },
  {
    featureId: "EF021459",
    featureDesc: "Rotating needle",
    label: "EF021459: Rotating needle"
  },
  {
    featureId: "EF021460",
    featureDesc: "Plate type",
    label: "EF021460: Plate type"
  },
  {
    featureId: "EF021461",
    featureDesc: "Bath colour",
    label: "EF021461: Bath colour"
  },
  {
    featureId: "EF021466",
    featureDesc: "Suitable for air",
    label: "EF021466: Suitable for air"
  },
  {
    featureId: "EF021468",
    featureDesc: "Suitable for nitrogen",
    label: "EF021468: Suitable for nitrogen"
  },
  {
    featureId: "EF021469",
    featureDesc: "Material partition",
    label: "EF021469: Material partition"
  },
  {
    featureId: "EF021470",
    featureDesc: "Material assembly piece",
    label: "EF021470: Material assembly piece"
  },
  {
    featureId: "EF021471",
    featureDesc: "Material trap",
    label: "EF021471: Material trap"
  },
  {
    featureId: "EF021472",
    featureDesc: "Covering plate colour",
    label: "EF021472: Covering plate colour"
  },
  {
    featureId: "EF021474",
    featureDesc: "Cover plate width",
    label: "EF021474: Cover plate width"
  },
  {
    featureId: "EF021475",
    featureDesc: "Built-in box length",
    label: "EF021475: Built-in box length"
  },
  {
    featureId: "EF021476",
    featureDesc: "Built-in box width",
    label: "EF021476: Built-in box width"
  },
  {
    featureId: "EF021477",
    featureDesc: "Built-in box depth",
    label: "EF021477: Built-in box depth"
  },
  {
    featureId: "EF021478",
    featureDesc: "Drainage capacity",
    label: "EF021478: Drainage capacity"
  },
  {
    featureId: "EF021479",
    featureDesc: "Water trap height",
    label: "EF021479: Water trap height"
  },
  {
    featureId: "EF021480",
    featureDesc: "With rosette",
    label: "EF021480: With rosette"
  },
  {
    featureId: "EF021481",
    featureDesc: "Min. length",
    label: "EF021481: Min. length"
  },
  {
    featureId: "EF021483",
    featureDesc: "Shape of intake",
    label: "EF021483: Shape of intake"
  },
  {
    featureId: "EF021484",
    featureDesc: "Length of funnel inlet",
    label: "EF021484: Length of funnel inlet"
  },
  {
    featureId: "EF021485",
    featureDesc: "Width, funnel inlet",
    label: "EF021485: Width, funnel inlet"
  },
  {
    featureId: "EF021486",
    featureDesc: "Height, funnel inlet",
    label: "EF021486: Height, funnel inlet"
  },
  {
    featureId: "EF021487",
    featureDesc: "With wall tube",
    label: "EF021487: With wall tube"
  },
  {
    featureId: "EF021488",
    featureDesc: "Dancer/overflow colour",
    label: "EF021488: Dancer/overflow colour"
  },
  {
    featureId: "EF021489",
    featureDesc: "Connection 1 rotating",
    label: "EF021489: Connection 1 rotating"
  },
  {
    featureId: "EF021490",
    featureDesc: "Electronic ignition",
    label: "EF021490: Electronic ignition"
  },
  {
    featureId: "EF021491",
    featureDesc: "Material roof flashing",
    label: "EF021491: Material roof flashing"
  },
  {
    featureId: "EF021493",
    featureDesc: "Width roof flashing",
    label: "EF021493: Width roof flashing"
  },
  {
    featureId: "EF021494",
    featureDesc: "Length roof flashing",
    label: "EF021494: Length roof flashing"
  },
  {
    featureId: "EF021495",
    featureDesc: "Diameter, leaf catcher",
    label: "EF021495: Diameter, leaf catcher"
  },
  {
    featureId: "EF021496",
    featureDesc: "Material funnel",
    label: "EF021496: Material funnel"
  },
  {
    featureId: "EF021497",
    featureDesc: "With leaf catcher",
    label: "EF021497: With leaf catcher"
  },
  {
    featureId: "EF021498",
    featureDesc: "With mortar cover",
    label: "EF021498: With mortar cover"
  },
  {
    featureId: "EF021499",
    featureDesc: "Max. wheel pressure",
    label: "EF021499: Max. wheel pressure"
  },
  {
    featureId: "EF021500",
    featureDesc: "Width, plate",
    label: "EF021500: Width, plate"
  },
  {
    featureId: "EF021501",
    featureDesc: "Plate length",
    label: "EF021501: Plate length"
  },
  {
    featureId: "EF021502",
    featureDesc: "With roof flashing",
    label: "EF021502: With roof flashing"
  },
  {
    featureId: "EF021505",
    featureDesc: "With grid",
    label: "EF021505: With grid"
  },
  {
    featureId: "EF021506",
    featureDesc: "With connecting piece for roof gully",
    label: "EF021506: With connecting piece for roof gully"
  },
  {
    featureId: "EF021507",
    featureDesc: "Material top-piece",
    label: "EF021507: Material top-piece"
  },
  {
    featureId: "EF021508",
    featureDesc: "Material reinforcing plate",
    label: "EF021508: Material reinforcing plate"
  },
  {
    featureId: "EF021509",
    featureDesc: "Number of attachment points",
    label: "EF021509: Number of attachment points"
  },
  {
    featureId: "EF021511",
    featureDesc: "Edge height",
    label: "EF021511: Edge height"
  },
  {
    featureId: "EF021512",
    featureDesc: "Sheet diameter",
    label: "EF021512: Sheet diameter"
  },
  {
    featureId: "EF021513",
    featureDesc: "Material fastening plate",
    label: "EF021513: Material fastening plate"
  },
  {
    featureId: "EF021514",
    featureDesc: "Number of mounting supports",
    label: "EF021514: Number of mounting supports"
  },
  {
    featureId: "EF021515",
    featureDesc: "Connects to condensation insulating plate",
    label: "EF021515: Connects to condensation insulating plate"
  },
  {
    featureId: "EF021516",
    featureDesc: "Can be used on system funnel",
    label: "EF021516: Can be used on system funnel"
  },
  {
    featureId: "EF021517",
    featureDesc: "Can be used as concrete pouring recess",
    label: "EF021517: Can be used as concrete pouring recess"
  },
  {
    featureId: "EF021518",
    featureDesc: "With fastening strips",
    label: "EF021518: With fastening strips"
  },
  {
    featureId: "EF021519",
    featureDesc: "Length of block",
    label: "EF021519: Length of block"
  },
  {
    featureId: "EF021520",
    featureDesc: "Width, block",
    label: "EF021520: Width, block"
  },
  {
    featureId: "EF021521",
    featureDesc: "Thickness, block",
    label: "EF021521: Thickness, block"
  },
  {
    featureId: "EF021522",
    featureDesc: "Diameter, block",
    label: "EF021522: Diameter, block"
  },
  {
    featureId: "EF021523",
    featureDesc: "Inner diameter of O-ring 1",
    label: "EF021523: Inner diameter of O-ring 1"
  },
  {
    featureId: "EF021524",
    featureDesc: "Inner diameter of O-ring 2",
    label: "EF021524: Inner diameter of O-ring 2"
  },
  {
    featureId: "EF021525",
    featureDesc: "Thickness, O-ring 1",
    label: "EF021525: Thickness, O-ring 1"
  },
  {
    featureId: "EF021526",
    featureDesc: "Thickness, O-ring 2",
    label: "EF021526: Thickness, O-ring 2"
  },
  {
    featureId: "EF021527",
    featureDesc: "Flange material",
    label: "EF021527: Flange material"
  },
  {
    featureId: "EF021529",
    featureDesc: "Max. vapour inhibition layer thickness",
    label: "EF021529: Max. vapour inhibition layer thickness"
  },
  {
    featureId: "EF021530",
    featureDesc: "Inner diameter of flange",
    label: "EF021530: Inner diameter of flange"
  },
  {
    featureId: "EF021531",
    featureDesc: "Outer diameter of flange",
    label: "EF021531: Outer diameter of flange"
  },
  {
    featureId: "EF021536",
    featureDesc: "With insulating ring",
    label: "EF021536: With insulating ring"
  },
  {
    featureId: "EF021537",
    featureDesc: "Diameter roof flashing",
    label: "EF021537: Diameter roof flashing"
  },
  {
    featureId: "EF021538",
    featureDesc: "With protective cover for inlet",
    label: "EF021538: With protective cover for inlet"
  },
  {
    featureId: "EF021540",
    featureDesc: "Material profile",
    label: "EF021540: Material profile"
  },
  {
    featureId: "EF021541",
    featureDesc: "Standard section length",
    label: "EF021541: Standard section length"
  },
  {
    featureId: "EF021544",
    featureDesc: "Position of components",
    label: "EF021544: Position of components"
  },
  {
    featureId: "EF021545",
    featureDesc: "Length of connector",
    label: "EF021545: Length of connector"
  },
  {
    featureId: "EF021546",
    featureDesc: "Working length of connector",
    label: "EF021546: Working length of connector"
  },
  {
    featureId: "EF021547",
    featureDesc: "Max. tensile load",
    label: "EF021547: Max. tensile load"
  },
  {
    featureId: "EF021548",
    featureDesc: "Max. bending load",
    label: "EF021548: Max. bending load"
  },
  {
    featureId: "EF021549",
    featureDesc: "Diameter, mounting holes (metric)",
    label: "EF021549: Diameter, mounting holes (metric)"
  },
  {
    featureId: "EF021550",
    featureDesc: "Width, support",
    label: "EF021550: Width, support"
  },
  {
    featureId: "EF021551",
    featureDesc: "Thickness, strip",
    label: "EF021551: Thickness, strip"
  },
  {
    featureId: "EF021552",
    featureDesc: "Attachment to profile",
    label: "EF021552: Attachment to profile"
  },
  {
    featureId: "EF021553",
    featureDesc: "Mounting holes",
    label: "EF021553: Mounting holes"
  },
  {
    featureId: "EF021554",
    featureDesc: "Length 1",
    label: "EF021554: Length 1"
  },
  {
    featureId: "EF021555",
    featureDesc: "Length 2",
    label: "EF021555: Length 2"
  },
  {
    featureId: "EF021556",
    featureDesc: "Distance choice",
    label: "EF021556: Distance choice"
  },
  {
    featureId: "EF021557",
    featureDesc: "Max. distance between spacers",
    label: "EF021557: Max. distance between spacers"
  },
  {
    featureId: "EF021558",
    featureDesc: "Diameter, slotted hole",
    label: "EF021558: Diameter, slotted hole"
  },
  {
    featureId: "EF021559",
    featureDesc: "Max. distance between connecting strips",
    label: "EF021559: Max. distance between connecting strips"
  },
  {
    featureId: "EF021560",
    featureDesc: "Length of strip 1",
    label: "EF021560: Length of strip 1"
  },
  {
    featureId: "EF021561",
    featureDesc: "Length of strip 2",
    label: "EF021561: Length of strip 2"
  },
  {
    featureId: "EF021562",
    featureDesc: "Width, strip",
    label: "EF021562: Width, strip"
  },
  {
    featureId: "EF021563",
    featureDesc: "Angle, strip",
    label: "EF021563: Angle, strip"
  },
  {
    featureId: "EF021564",
    featureDesc: "Max. width",
    label: "EF021564: Max. width"
  },
  {
    featureId: "EF021565",
    featureDesc: "Min. width",
    label: "EF021565: Min. width"
  },
  {
    featureId: "EF021566",
    featureDesc: "Height reinforcement",
    label: "EF021566: Height reinforcement"
  },
  {
    featureId: "EF021567",
    featureDesc: "Shape of frame",
    label: "EF021567: Shape of frame"
  },
  {
    featureId: "EF021568",
    featureDesc: "Colour frame",
    label: "EF021568: Colour frame"
  },
  {
    featureId: "EF021569",
    featureDesc: "Frame construction",
    label: "EF021569: Frame construction"
  },
  {
    featureId: "EF021570",
    featureDesc: "Frame height",
    label: "EF021570: Frame height"
  },
  {
    featureId: "EF021571",
    featureDesc: "Frame width",
    label: "EF021571: Frame width"
  },
  {
    featureId: "EF021572",
    featureDesc: "Frame depth",
    label: "EF021572: Frame depth"
  },
  {
    featureId: "EF021573",
    featureDesc: "With adjustable floor legs",
    label: "EF021573: With adjustable floor legs"
  },
  {
    featureId: "EF021574",
    featureDesc: "With wall-mounting bracket",
    label: "EF021574: With wall-mounting bracket"
  },
  {
    featureId: "EF021575",
    featureDesc: "Adjustable mounting plate",
    label: "EF021575: Adjustable mounting plate"
  },
  {
    featureId: "EF021576",
    featureDesc: "Pre-assembled element",
    label: "EF021576: Pre-assembled element"
  },
  {
    featureId: "EF021577",
    featureDesc: "Suitable for mixer tap",
    label: "EF021577: Suitable for mixer tap"
  },
  {
    featureId: "EF021578",
    featureDesc: "Suitable for wall mixing tap",
    label: "EF021578: Suitable for wall mixing tap"
  },
  {
    featureId: "EF021579",
    featureDesc: "With threaded rods",
    label: "EF021579: With threaded rods"
  },
  {
    featureId: "EF021580",
    featureDesc: "With tap connecting plate",
    label: "EF021580: With tap connecting plate"
  },
  {
    featureId: "EF021581",
    featureDesc: "With attachment set for system section",
    label: "EF021581: With attachment set for system section"
  },
  {
    featureId: "EF021582",
    featureDesc: "With trap connection bend",
    label: "EF021582: With trap connection bend"
  },
  {
    featureId: "EF021583",
    featureDesc: "Suitable for built-in trap",
    label: "EF021583: Suitable for built-in trap"
  },
  {
    featureId: "EF021584",
    featureDesc: "Adjustable washbasin height",
    label: "EF021584: Adjustable washbasin height"
  },
  {
    featureId: "EF021585",
    featureDesc: "Number of attachment points according to element",
    label: "EF021585: Number of attachment points according to element"
  },
  {
    featureId: "EF021586",
    featureDesc: "Suitable for disabled persons",
    label: "EF021586: Suitable for disabled persons"
  },
  {
    featureId: "EF021587",
    featureDesc: "With siphon",
    label: "EF021587: With siphon"
  },
  {
    featureId: "EF021588",
    featureDesc: "Trap position",
    label: "EF021588: Trap position"
  },
  {
    featureId: "EF021589",
    featureDesc: "With water connection fittings",
    label: "EF021589: With water connection fittings"
  },
  {
    featureId: "EF021590",
    featureDesc: "With wall rosette",
    label: "EF021590: With wall rosette"
  },
  {
    featureId: "EF021591",
    featureDesc: "Drainage hose tulle/hose connection",
    label: "EF021591: Drainage hose tulle/hose connection"
  },
  {
    featureId: "EF021592",
    featureDesc: "With rubber cover rosette",
    label: "EF021592: With rubber cover rosette"
  },
  {
    featureId: "EF021593",
    featureDesc: "Self-adhesive cover rosette",
    label: "EF021593: Self-adhesive cover rosette"
  },
  {
    featureId: "EF021594",
    featureDesc: "Wall rosette, chromium-plated",
    label: "EF021594: Wall rosette, chromium-plated"
  },
  {
    featureId: "EF021595",
    featureDesc: "Diameter, drainage hose tulle/hose connection",
    label: "EF021595: Diameter, drainage hose tulle/hose connection"
  },
  {
    featureId: "EF021596",
    featureDesc: "Max. clamping force",
    label: "EF021596: Max. clamping force"
  },
  {
    featureId: "EF021597",
    featureDesc: "With threaded rod",
    label: "EF021597: With threaded rod"
  },
  {
    featureId: "EF021598",
    featureDesc: "Diameter, threaded rod",
    label: "EF021598: Diameter, threaded rod"
  },
  {
    featureId: "EF021599",
    featureDesc: "Length of pipe from attachment centre",
    label: "EF021599: Length of pipe from attachment centre"
  },
  {
    featureId: "EF021600",
    featureDesc: "Length of pipe clip",
    label: "EF021600: Length of pipe clip"
  },
  {
    featureId: "EF021601",
    featureDesc: "Pipe is supported by",
    label: "EF021601: Pipe is supported by"
  },
  {
    featureId: "EF021602",
    featureDesc: "Length of sleeve",
    label: "EF021602: Length of sleeve"
  },
  {
    featureId: "EF021603",
    featureDesc: "Wall thickness, connection 1",
    label: "EF021603: Wall thickness, connection 1"
  },
  {
    featureId: "EF021604",
    featureDesc: "Wall thickness, connection 2",
    label: "EF021604: Wall thickness, connection 2"
  },
  {
    featureId: "EF021605",
    featureDesc: "Offset direction",
    label: "EF021605: Offset direction"
  },
  {
    featureId: "EF021607",
    featureDesc: "With stop valve",
    label: "EF021607: With stop valve"
  },
  {
    featureId: "EF021608",
    featureDesc: "Suitable for electric finishing set",
    label: "EF021608: Suitable for electric finishing set"
  },
  {
    featureId: "EF021609",
    featureDesc: "Suitable for pneumatic finishing set",
    label: "EF021609: Suitable for pneumatic finishing set"
  },
  {
    featureId: "EF021610",
    featureDesc: "Depth, spacer block",
    label: "EF021610: Depth, spacer block"
  },
  {
    featureId: "EF021611",
    featureDesc: "Length of mantle pipe",
    label: "EF021611: Length of mantle pipe"
  },
  {
    featureId: "EF021615",
    featureDesc: "With sealing ring",
    label: "EF021615: With sealing ring"
  },
  {
    featureId: "EF021616",
    featureDesc: "With contact sound insulation set",
    label: "EF021616: With contact sound insulation set"
  },
  {
    featureId: "EF021617",
    featureDesc: "With unclogging lid",
    label: "EF021617: With unclogging lid"
  },
  {
    featureId: "EF021620",
    featureDesc: "Material t-piece",
    label: "EF021620: Material t-piece"
  },
  {
    featureId: "EF021621",
    featureDesc: "Material sleeve",
    label: "EF021621: Material sleeve"
  },
  {
    featureId: "EF021623",
    featureDesc: "Nominal diameter branches",
    label: "EF021623: Nominal diameter branches"
  },
  {
    featureId: "EF021624",
    featureDesc: "Outer pipe diameter branches",
    label: "EF021624: Outer pipe diameter branches"
  },
  {
    featureId: "EF021625",
    featureDesc: "Distributor working length",
    label: "EF021625: Distributor working length"
  },
  {
    featureId: "EF021626",
    featureDesc: "Length of branch",
    label: "EF021626: Length of branch"
  },
  {
    featureId: "EF021627",
    featureDesc: "Branch working length",
    label: "EF021627: Branch working length"
  },
  {
    featureId: "EF021628",
    featureDesc: "Distance between offset",
    label: "EF021628: Distance between offset"
  },
  {
    featureId: "EF021632",
    featureDesc: "Measuring range adjustable",
    label: "EF021632: Measuring range adjustable"
  },
  {
    featureId: "EF021635",
    featureDesc: "Material sliding ring",
    label: "EF021635: Material sliding ring"
  },
  {
    featureId: "EF021637",
    featureDesc: "Axle diameter",
    label: "EF021637: Axle diameter"
  },
  {
    featureId: "EF021638",
    featureDesc: "Material secondary seal",
    label: "EF021638: Material secondary seal"
  },
  {
    featureId: "EF021640",
    featureDesc: "Length of outside part",
    label: "EF021640: Length of outside part"
  },
  {
    featureId: "EF021643",
    featureDesc: "Pitch circle",
    label: "EF021643: Pitch circle"
  },
  {
    featureId: "EF021648",
    featureDesc: "With lip ring",
    label: "EF021648: With lip ring"
  },
  {
    featureId: "EF021649",
    featureDesc: "With clamping strip",
    label: "EF021649: With clamping strip"
  },
  {
    featureId: "EF021650",
    featureDesc: "Coated",
    label: "EF021650: Coated"
  },
  {
    featureId: "EF021651",
    featureDesc: "Length of spout",
    label: "EF021651: Length of spout"
  },
  {
    featureId: "EF021652",
    featureDesc: "With rosettes",
    label: "EF021652: With rosettes"
  },
  {
    featureId: "EF021654",
    featureDesc: "Nominal duct diameter, flue",
    label: "EF021654: Nominal duct diameter, flue"
  },
  {
    featureId: "EF021655",
    featureDesc: "With clamping slide",
    label: "EF021655: With clamping slide"
  },
  {
    featureId: "EF021656",
    featureDesc: "Heavy-duty model",
    label: "EF021656: Heavy-duty model"
  },
  {
    featureId: "EF021657",
    featureDesc: "Suitable for round-bar steel",
    label: "EF021657: Suitable for round-bar steel"
  },
  {
    featureId: "EF021658",
    featureDesc: "Thread nipple",
    label: "EF021658: Thread nipple"
  },
  {
    featureId: "EF021659",
    featureDesc: "Material inlay strap",
    label: "EF021659: Material inlay strap"
  },
  {
    featureId: "EF021660",
    featureDesc: "Inlay strap colour",
    label: "EF021660: Inlay strap colour"
  },
  {
    featureId: "EF021661",
    featureDesc: "Shape of sensor",
    label: "EF021661: Shape of sensor"
  },
  {
    featureId: "EF021663",
    featureDesc: "Flange attachment",
    label: "EF021663: Flange attachment"
  },
  {
    featureId: "EF021664",
    featureDesc: "Suitable for underwater",
    label: "EF021664: Suitable for underwater"
  },
  {
    featureId: "EF021665",
    featureDesc: "Connecting flange pitch circle",
    label: "EF021665: Connecting flange pitch circle"
  },
  {
    featureId: "EF021667",
    featureDesc: "Key square",
    label: "EF021667: Key square"
  },
  {
    featureId: "EF021669",
    featureDesc: "First open",
    label: "EF021669: First open"
  },
  {
    featureId: "EF021671",
    featureDesc: "Suitable for ammonia",
    label: "EF021671: Suitable for ammonia"
  },
  {
    featureId: "EF021672",
    featureDesc: "Suitable for oil",
    label: "EF021672: Suitable for oil"
  },
  {
    featureId: "EF021674",
    featureDesc: "Bottom connection",
    label: "EF021674: Bottom connection"
  },
  {
    featureId: "EF021676",
    featureDesc: "Material console",
    label: "EF021676: Material console"
  },
  {
    featureId: "EF021677",
    featureDesc: "Suitable for welded bracket on radiator",
    label: "EF021677: Suitable for welded bracket on radiator"
  },
  {
    featureId: "EF021678",
    featureDesc: "Suitable for ribbed radiator",
    label: "EF021678: Suitable for ribbed radiator"
  },
  {
    featureId: "EF021679",
    featureDesc: "Suitable for panel radiator",
    label: "EF021679: Suitable for panel radiator"
  },
  {
    featureId: "EF021680",
    featureDesc: "Inlay/coating",
    label: "EF021680: Inlay/coating"
  },
  {
    featureId: "EF021681",
    featureDesc: "Includes roller",
    label: "EF021681: Includes roller"
  },
  {
    featureId: "EF021682",
    featureDesc: "Filter housing height",
    label: "EF021682: Filter housing height"
  },
  {
    featureId: "EF021684",
    featureDesc: "Material inner ring",
    label: "EF021684: Material inner ring"
  },
  {
    featureId: "EF021685",
    featureDesc: "Material spiral",
    label: "EF021685: Material spiral"
  },
  {
    featureId: "EF021686",
    featureDesc: "Material filling",
    label: "EF021686: Material filling"
  },
  {
    featureId: "EF021687",
    featureDesc: "Material outer ring",
    label: "EF021687: Material outer ring"
  },
  {
    featureId: "EF021688",
    featureDesc: "Seal standard",
    label: "EF021688: Seal standard"
  },
  {
    featureId: "EF021690",
    featureDesc: "Thickness, centring ring",
    label: "EF021690: Thickness, centring ring"
  },
  {
    featureId: "EF021691",
    featureDesc: "Outer diameter of spiral",
    label: "EF021691: Outer diameter of spiral"
  },
  {
    featureId: "EF021692",
    featureDesc: "Inner diameter spiral",
    label: "EF021692: Inner diameter spiral"
  },
  {
    featureId: "EF021694",
    featureDesc: "Flange standard",
    label: "EF021694: Flange standard"
  },
  {
    featureId: "EF021703",
    featureDesc: "Outer pipe diameter drainage",
    label: "EF021703: Outer pipe diameter drainage"
  },
  {
    featureId: "EF021704",
    featureDesc: "Outer pipe diameter flushing",
    label: "EF021704: Outer pipe diameter flushing"
  },
  {
    featureId: "EF021705",
    featureDesc: "Material covering plate",
    label: "EF021705: Material covering plate"
  },
  {
    featureId: "EF021707",
    featureDesc: "With waste fitting",
    label: "EF021707: With waste fitting"
  },
  {
    featureId: "EF021709",
    featureDesc: "Centre distance, inlets",
    label: "EF021709: Centre distance, inlets"
  },
  {
    featureId: "EF021710",
    featureDesc: "Drain connection",
    label: "EF021710: Drain connection"
  },
  {
    featureId: "EF021711",
    featureDesc: "Siphon-waste stopper connection",
    label: "EF021711: Siphon-waste stopper connection"
  },
  {
    featureId: "EF021712",
    featureDesc: "Chrome plated",
    label: "EF021712: Chrome plated"
  },
  {
    featureId: "EF021713",
    featureDesc: "With floor tube",
    label: "EF021713: With floor tube"
  },
  {
    featureId: "EF021714",
    featureDesc: "With drain aeration",
    label: "EF021714: With drain aeration"
  },
  {
    featureId: "EF021715",
    featureDesc: "With extra hose socket connection",
    label: "EF021715: With extra hose socket connection"
  },
  {
    featureId: "EF021716",
    featureDesc: "Siphon part against rear wall",
    label: "EF021716: Siphon part against rear wall"
  },
  {
    featureId: "EF021717",
    featureDesc: "Siphon connection",
    label: "EF021717: Siphon connection"
  },
  {
    featureId: "EF021718",
    featureDesc: "Appliance connection",
    label: "EF021718: Appliance connection"
  },
  {
    featureId: "EF021719",
    featureDesc: "With cover cap",
    label: "EF021719: With cover cap"
  },
  {
    featureId: "EF021720",
    featureDesc: "With backflow safeguard",
    label: "EF021720: With backflow safeguard"
  },
  {
    featureId: "EF021721",
    featureDesc: "Min. density of liquid",
    label: "EF021721: Min. density of liquid"
  },
  {
    featureId: "EF021722",
    featureDesc: "LED indicator inputs",
    label: "EF021722: LED indicator inputs"
  },
  {
    featureId: "EF021723",
    featureDesc: "LED indicator outputs",
    label: "EF021723: LED indicator outputs"
  },
  {
    featureId: "EF021724",
    featureDesc: "Fan control system",
    label: "EF021724: Fan control system"
  },
  {
    featureId: "EF021725",
    featureDesc: "Freely programmable",
    label: "EF021725: Freely programmable"
  },
  {
    featureId: "EF021726",
    featureDesc: "Shaft connection, servomotor",
    label: "EF021726: Shaft connection, servomotor"
  },
  {
    featureId: "EF021727",
    featureDesc: "Torque motor",
    label: "EF021727: Torque motor"
  },
  {
    featureId: "EF021730",
    featureDesc: "Material duct flange",
    label: "EF021730: Material duct flange"
  },
  {
    featureId: "EF021731",
    featureDesc: "Number of sensors",
    label: "EF021731: Number of sensors"
  },
  {
    featureId: "EF021732",
    featureDesc: "Spreadable",
    label: "EF021732: Spreadable"
  },
  {
    featureId: "EF021733",
    featureDesc: "Number of scales",
    label: "EF021733: Number of scales"
  },
  {
    featureId: "EF021734",
    featureDesc: "KNMI licence",
    label: "EF021734: KNMI licence"
  },
  {
    featureId: "EF021735",
    featureDesc: "With load-bearing wall bracket",
    label: "EF021735: With load-bearing wall bracket"
  },
  {
    featureId: "EF021736",
    featureDesc: "Tap connecting plate adjustable",
    label: "EF021736: Tap connecting plate adjustable"
  },
  {
    featureId: "EF021740",
    featureDesc: "Connection sizes",
    label: "EF021740: Connection sizes"
  },
  {
    featureId: "EF021741",
    featureDesc: "Diameter, connections",
    label: "EF021741: Diameter, connections"
  },
  {
    featureId: "EF021742",
    featureDesc: "Width mounting plate",
    label: "EF021742: Width mounting plate"
  },
  {
    featureId: "EF021743",
    featureDesc: "Length of mounting plate",
    label: "EF021743: Length of mounting plate"
  },
  {
    featureId: "EF021744",
    featureDesc: "Depth mounting plate",
    label: "EF021744: Depth mounting plate"
  },
  {
    featureId: "EF021751",
    featureDesc: "Number of wall plate locations",
    label: "EF021751: Number of wall plate locations"
  },
  {
    featureId: "EF021756",
    featureDesc: "Adjustable shower jet strength",
    label: "EF021756: Adjustable shower jet strength"
  },
  {
    featureId: "EF021757",
    featureDesc: "Shower jet temperature adjustable",
    label: "EF021757: Shower jet temperature adjustable"
  },
  {
    featureId: "EF021758",
    featureDesc: "With boiler",
    label: "EF021758: With boiler"
  },
  {
    featureId: "EF021760",
    featureDesc: "Scald protection",
    label: "EF021760: Scald protection"
  },
  {
    featureId: "EF021761",
    featureDesc: "Dryer capacity",
    label: "EF021761: Dryer capacity"
  },
  {
    featureId: "EF021762",
    featureDesc: "Hot air dryer",
    label: "EF021762: Hot air dryer"
  },
  {
    featureId: "EF021764",
    featureDesc: "Min. water pressure",
    label: "EF021764: Min. water pressure"
  },
  {
    featureId: "EF021765",
    featureDesc: "Max. water pressure",
    label: "EF021765: Max. water pressure"
  },
  {
    featureId: "EF021768",
    featureDesc: "Suitable for mano-thermometer",
    label: "EF021768: Suitable for mano-thermometer"
  },
  {
    featureId: "EF021769",
    featureDesc: "Hose size",
    label: "EF021769: Hose size"
  },
  {
    featureId: "EF021771",
    featureDesc: "Nozzled",
    label: "EF021771: Nozzled"
  },
  {
    featureId: "EF021772",
    featureDesc: "Suitable for heating",
    label: "EF021772: Suitable for heating"
  },
  {
    featureId: "EF021776",
    featureDesc: "Number of characters per line",
    label: "EF021776: Number of characters per line"
  },
  {
    featureId: "EF021777",
    featureDesc: "Number of lines",
    label: "EF021777: Number of lines"
  },
  {
    featureId: "EF021778",
    featureDesc: "With mounting set",
    label: "EF021778: With mounting set"
  },
  {
    featureId: "EF021779",
    featureDesc: "Number of bolt holes",
    label: "EF021779: Number of bolt holes"
  },
  {
    featureId: "EF021780",
    featureDesc: "Diameter bolt hole",
    label: "EF021780: Diameter bolt hole"
  },
  {
    featureId: "EF021782",
    featureDesc: "Purity",
    label: "EF021782: Purity"
  },
  {
    featureId: "EF021783",
    featureDesc: "Number of inlays",
    label: "EF021783: Number of inlays"
  },
  {
    featureId: "EF021784",
    featureDesc: "Inlay type",
    label: "EF021784: Inlay type"
  },
  {
    featureId: "EF021787",
    featureDesc: "With anti-stick layer",
    label: "EF021787: With anti-stick layer"
  },
  {
    featureId: "EF021788",
    featureDesc: "Vacuum-resistance at 20 °C",
    label: "EF021788: Vacuum-resistance at 20 °C"
  },
  {
    featureId: "EF021789",
    featureDesc: "Material bellows inner wall",
    label: "EF021789: Material bellows inner wall"
  },
  {
    featureId: "EF021791",
    featureDesc: "Material bellows outer wall",
    label: "EF021791: Material bellows outer wall"
  },
  {
    featureId: "EF021792",
    featureDesc: "Movement sensing, axial +",
    label: "EF021792: Movement sensing, axial +"
  },
  {
    featureId: "EF021793",
    featureDesc: "Movement sensing, axial -",
    label: "EF021793: Movement sensing, axial -"
  },
  {
    featureId: "EF021794",
    featureDesc: "Movement sensing, lateral +/-",
    label: "EF021794: Movement sensing, lateral +/-"
  },
  {
    featureId: "EF021795",
    featureDesc: "Movement sensing, angular +/-",
    label: "EF021795: Movement sensing, angular +/-"
  },
  {
    featureId: "EF021797",
    featureDesc: "Material bellows",
    label: "EF021797: Material bellows"
  },
  {
    featureId: "EF021799",
    featureDesc: "Movement sensing, axial +/-",
    label: "EF021799: Movement sensing, axial +/-"
  },
  {
    featureId: "EF021800",
    featureDesc: "Sleeve lock",
    label: "EF021800: Sleeve lock"
  },
  {
    featureId: "EF021801",
    featureDesc: "Fire-resistance, vertical",
    label: "EF021801: Fire-resistance, vertical"
  },
  {
    featureId: "EF021802",
    featureDesc: "Fire-resistance, horizontal",
    label: "EF021802: Fire-resistance, horizontal"
  },
  {
    featureId: "EF021803",
    featureDesc: "Reaction temperature",
    label: "EF021803: Reaction temperature"
  },
  {
    featureId: "EF021804",
    featureDesc: "Stop system",
    label: "EF021804: Stop system"
  },
  {
    featureId: "EF021805",
    featureDesc: "Width, sleeve",
    label: "EF021805: Width, sleeve"
  },
  {
    featureId: "EF021806",
    featureDesc: "Outer diameter, sleeve",
    label: "EF021806: Outer diameter, sleeve"
  },
  {
    featureId: "EF021808",
    featureDesc: "Number of tap holes",
    label: "EF021808: Number of tap holes"
  },
  {
    featureId: "EF021809",
    featureDesc: "Number of layers",
    label: "EF021809: Number of layers"
  },
  {
    featureId: "EF021811",
    featureDesc: "Length of pipe section",
    label: "EF021811: Length of pipe section"
  },
  {
    featureId: "EF021812",
    featureDesc: "Number of plates",
    label: "EF021812: Number of plates"
  },
  {
    featureId: "EF021828",
    featureDesc: "Ceramic upper section",
    label: "EF021828: Ceramic upper section"
  },
  {
    featureId: "EF021829",
    featureDesc: "Tap threaded connection",
    label: "EF021829: Tap threaded connection"
  },
  {
    featureId: "EF021835",
    featureDesc: "Centre distance",
    label: "EF021835: Centre distance"
  },
  {
    featureId: "EF021836",
    featureDesc: "With waste unit",
    label: "EF021836: With waste unit"
  },
  {
    featureId: "EF021838",
    featureDesc: "Size of threaded connection",
    label: "EF021838: Size of threaded connection"
  },
  {
    featureId: "EF021841",
    featureDesc: "Distance centre-to-centre",
    label: "EF021841: Distance centre-to-centre"
  },
  {
    featureId: "EF021846",
    featureDesc: "Drain capacity",
    label: "EF021846: Drain capacity"
  },
  {
    featureId: "EF021847",
    featureDesc: "Drain plug size",
    label: "EF021847: Drain plug size"
  },
  {
    featureId: "EF021848",
    featureDesc: "Shower tray colour",
    label: "EF021848: Shower tray colour"
  },
  {
    featureId: "EF021849",
    featureDesc: "Model of armrests",
    label: "EF021849: Model of armrests"
  },
  {
    featureId: "EF021850",
    featureDesc: "Suitable for radiator height",
    label: "EF021850: Suitable for radiator height"
  },
  {
    featureId: "EF021851",
    featureDesc: "Suitable for between the panels",
    label: "EF021851: Suitable for between the panels"
  },
  {
    featureId: "EF021852",
    featureDesc: "Suitable for window-sill supports",
    label: "EF021852: Suitable for window-sill supports"
  },
  {
    featureId: "EF021853",
    featureDesc: "Loose base",
    label: "EF021853: Loose base"
  },
  {
    featureId: "EF021855",
    featureDesc: "With shower set",
    label: "EF021855: With shower set"
  },
  {
    featureId: "EF021856",
    featureDesc: "Shaft diameter",
    label: "EF021856: Shaft diameter"
  },
  {
    featureId: "EF021857",
    featureDesc: "With lining",
    label: "EF021857: With lining"
  },
  {
    featureId: "EF021859",
    featureDesc: "Bend angle",
    label: "EF021859: Bend angle"
  },
  {
    featureId: "EF021860",
    featureDesc: "Mobile",
    label: "EF021860: Mobile"
  },
  {
    featureId: "EF021861",
    featureDesc: "Zero-pressure return flow",
    label: "EF021861: Zero-pressure return flow"
  },
  {
    featureId: "EF021862",
    featureDesc: "Position reporting system",
    label: "EF021862: Position reporting system"
  },
  {
    featureId: "EF021863",
    featureDesc: "Operating time",
    label: "EF021863: Operating time"
  },
  {
    featureId: "EF021864",
    featureDesc: "Torque",
    label: "EF021864: Torque"
  },
  {
    featureId: "EF021865",
    featureDesc: "Positioning force",
    label: "EF021865: Positioning force"
  },
  {
    featureId: "EF021866",
    featureDesc: "Stroke length",
    label: "EF021866: Stroke length"
  },
  {
    featureId: "EF021867",
    featureDesc: "Control signal lower value",
    label: "EF021867: Control signal lower value"
  },
  {
    featureId: "EF021868",
    featureDesc: "Control signal upper value",
    label: "EF021868: Control signal upper value"
  },
  {
    featureId: "EF021869",
    featureDesc: "Spring return",
    label: "EF021869: Spring return"
  },
  {
    featureId: "EF021870",
    featureDesc: "Angular deflection",
    label: "EF021870: Angular deflection"
  },
  {
    featureId: "EF021872",
    featureDesc: "With coupling connection 1",
    label: "EF021872: With coupling connection 1"
  },
  {
    featureId: "EF021873",
    featureDesc: "With coupling connection 2",
    label: "EF021873: With coupling connection 2"
  },
  {
    featureId: "EF021874",
    featureDesc: "Material hose/sleeve",
    label: "EF021874: Material hose/sleeve"
  },
  {
    featureId: "EF021878",
    featureDesc: "With weights",
    label: "EF021878: With weights"
  },
  {
    featureId: "EF021880",
    featureDesc: "Number of valves, valve block",
    label: "EF021880: Number of valves, valve block"
  },
  {
    featureId: "EF021881",
    featureDesc: "Connection, process side",
    label: "EF021881: Connection, process side"
  },
  {
    featureId: "EF021882",
    featureDesc: "Connection, instrument side",
    label: "EF021882: Connection, instrument side"
  },
  {
    featureId: "EF021885",
    featureDesc: "Suitable for vacuum",
    label: "EF021885: Suitable for vacuum"
  },
  {
    featureId: "EF021886",
    featureDesc: "Press/turn control button",
    label: "EF021886: Press/turn control button"
  },
  {
    featureId: "EF021887",
    featureDesc: "Lift/turn control button",
    label: "EF021887: Lift/turn control button"
  },
  {
    featureId: "EF021888",
    featureDesc: "Nominal diameter all connections",
    label: "EF021888: Nominal diameter all connections"
  },
  {
    featureId: "EF021889",
    featureDesc: "Air volume, unloaded",
    label: "EF021889: Air volume, unloaded"
  },
  {
    featureId: "EF021890",
    featureDesc: "Air volume at pressure 2",
    label: "EF021890: Air volume at pressure 2"
  },
  {
    featureId: "EF021891",
    featureDesc: "Air volume at pressure 3",
    label: "EF021891: Air volume at pressure 3"
  },
  {
    featureId: "EF021892",
    featureDesc: "Pressure 2",
    label: "EF021892: Pressure 2"
  },
  {
    featureId: "EF021893",
    featureDesc: "Pressure 3",
    label: "EF021893: Pressure 3"
  },
  {
    featureId: "EF021894",
    featureDesc: "Suitable for electronic control",
    label: "EF021894: Suitable for electronic control"
  },
  {
    featureId: "EF021895",
    featureDesc: "Suitable for transformer control",
    label: "EF021895: Suitable for transformer control"
  },
  {
    featureId: "EF021896",
    featureDesc: "Duct diameter",
    label: "EF021896: Duct diameter"
  },
  {
    featureId: "EF021897",
    featureDesc: "Max. air temperature",
    label: "EF021897: Max. air temperature"
  },
  {
    featureId: "EF021898",
    featureDesc: "With volume supply limiter",
    label: "EF021898: With volume supply limiter"
  },
  {
    featureId: "EF021900",
    featureDesc: "Flame barrier",
    label: "EF021900: Flame barrier"
  },
  {
    featureId: "EF021901",
    featureDesc: "Vacuum valve angle",
    label: "EF021901: Vacuum valve angle"
  },
  {
    featureId: "EF021903",
    featureDesc: "Outer diameter connection 1",
    label: "EF021903: Outer diameter connection 1"
  },
  {
    featureId: "EF021904",
    featureDesc: "Outer diameter connection 2",
    label: "EF021904: Outer diameter connection 2"
  },
  {
    featureId: "EF021905",
    featureDesc: "FM quality mark",
    label: "EF021905: FM quality mark"
  },
  {
    featureId: "EF021907",
    featureDesc: "UL quality mark",
    label: "EF021907: UL quality mark"
  },
  {
    featureId: "EF021908",
    featureDesc: "ULC quality mark",
    label: "EF021908: ULC quality mark"
  },
  {
    featureId: "EF021909",
    featureDesc: "LPCB quality mark",
    label: "EF021909: LPCB quality mark"
  },
  {
    featureId: "EF021911",
    featureDesc: "Suitable for terrace attachment",
    label: "EF021911: Suitable for terrace attachment"
  },
  {
    featureId: "EF021912",
    featureDesc: "With top-piece",
    label: "EF021912: With top-piece"
  },
  {
    featureId: "EF021913",
    featureDesc: "With heating element",
    label: "EF021913: With heating element"
  },
  {
    featureId: "EF021914",
    featureDesc: "Vane installation",
    label: "EF021914: Vane installation"
  },
  {
    featureId: "EF021915",
    featureDesc: "Free set-up",
    label: "EF021915: Free set-up"
  },
  {
    featureId: "EF021916",
    featureDesc: "Diameter, funnel inlet",
    label: "EF021916: Diameter, funnel inlet"
  },
  {
    featureId: "EF021917",
    featureDesc: "Suitable for compact radiator",
    label: "EF021917: Suitable for compact radiator"
  },
  {
    featureId: "EF021918",
    featureDesc: "Bracket type",
    label: "EF021918: Bracket type"
  },
  {
    featureId: "EF021919",
    featureDesc: "Height, sleeve",
    label: "EF021919: Height, sleeve"
  },
  {
    featureId: "EF021920",
    featureDesc: "With gauze",
    label: "EF021920: With gauze"
  },
  {
    featureId: "EF021921",
    featureDesc: "Material spherical wire grille",
    label: "EF021921: Material spherical wire grille"
  },
  {
    featureId: "EF021922",
    featureDesc: "Material drain grid",
    label: "EF021922: Material drain grid"
  },
  {
    featureId: "EF021923",
    featureDesc: "Material drain bucket",
    label: "EF021923: Material drain bucket"
  },
  {
    featureId: "EF021924",
    featureDesc: "Material gutter",
    label: "EF021924: Material gutter"
  },
  {
    featureId: "EF021925",
    featureDesc: "Developed tray width",
    label: "EF021925: Developed tray width"
  },
  {
    featureId: "EF021926",
    featureDesc: "Wedge width",
    label: "EF021926: Wedge width"
  },
  {
    featureId: "EF021927",
    featureDesc: "Wedge length",
    label: "EF021927: Wedge length"
  },
  {
    featureId: "EF021928",
    featureDesc: "Wedge angle",
    label: "EF021928: Wedge angle"
  },
  {
    featureId: "EF021929",
    featureDesc: "Thickness, wedge material",
    label: "EF021929: Thickness, wedge material"
  },
  {
    featureId: "EF021930",
    featureDesc: "Width, bracket",
    label: "EF021930: Width, bracket"
  },
  {
    featureId: "EF021931",
    featureDesc: "Height, bracket",
    label: "EF021931: Height, bracket"
  },
  {
    featureId: "EF021932",
    featureDesc: "Length of bracket",
    label: "EF021932: Length of bracket"
  },
  {
    featureId: "EF021933",
    featureDesc: "Thickness, bracket material",
    label: "EF021933: Thickness, bracket material"
  },
  {
    featureId: "EF021934",
    featureDesc: "Attachment, suspension element",
    label: "EF021934: Attachment, suspension element"
  },
  {
    featureId: "EF021935",
    featureDesc: "Can be used on carrying pipe section",
    label: "EF021935: Can be used on carrying pipe section"
  },
  {
    featureId: "EF021936",
    featureDesc: "Number of wedge connections",
    label: "EF021936: Number of wedge connections"
  },
  {
    featureId: "EF021937",
    featureDesc: "Max. bracket width",
    label: "EF021937: Max. bracket width"
  },
  {
    featureId: "EF021938",
    featureDesc: "Belt closed",
    label: "EF021938: Belt closed"
  },
  {
    featureId: "EF021940",
    featureDesc: "Inner diameter of fire hose",
    label: "EF021940: Inner diameter of fire hose"
  },
  {
    featureId: "EF021941",
    featureDesc: "Material reel blade",
    label: "EF021941: Material reel blade"
  },
  {
    featureId: "EF021943",
    featureDesc: "Reel blade distance",
    label: "EF021943: Reel blade distance"
  },
  {
    featureId: "EF021944",
    featureDesc: "Jet nozzle holder",
    label: "EF021944: Jet nozzle holder"
  },
  {
    featureId: "EF021945",
    featureDesc: "Input piece",
    label: "EF021945: Input piece"
  },
  {
    featureId: "EF021946",
    featureDesc: "Feed valve",
    label: "EF021946: Feed valve"
  },
  {
    featureId: "EF021947",
    featureDesc: "Hose guide",
    label: "EF021947: Hose guide"
  },
  {
    featureId: "EF021948",
    featureDesc: "Length of filling hose",
    label: "EF021948: Length of filling hose"
  },
  {
    featureId: "EF021949",
    featureDesc: "With de-aerator key",
    label: "EF021949: With de-aerator key"
  },
  {
    featureId: "EF021950",
    featureDesc: "With filling-drain key",
    label: "EF021950: With filling-drain key"
  },
  {
    featureId: "EF021951",
    featureDesc: "With hose rack",
    label: "EF021951: With hose rack"
  },
  {
    featureId: "EF021952",
    featureDesc: "With swivelling arm",
    label: "EF021952: With swivelling arm"
  },
  {
    featureId: "EF021953",
    featureDesc: "Facility for manual fire alarm",
    label: "EF021953: Facility for manual fire alarm"
  },
  {
    featureId: "EF021954",
    featureDesc: "With extra extinguishing agent section",
    label: "EF021954: With extra extinguishing agent section"
  },
  {
    featureId: "EF021955",
    featureDesc: "With reel cabinet door",
    label: "EF021955: With reel cabinet door"
  },
  {
    featureId: "EF021956",
    featureDesc: "With extra section door",
    label: "EF021956: With extra section door"
  },
  {
    featureId: "EF021957",
    featureDesc: "With cover frame",
    label: "EF021957: With cover frame"
  },
  {
    featureId: "EF021958",
    featureDesc: "Max. reel blade diameter",
    label: "EF021958: Max. reel blade diameter"
  },
  {
    featureId: "EF021959",
    featureDesc: "Heating capacity",
    label: "EF021959: Heating capacity"
  },
  {
    featureId: "EF021962",
    featureDesc: "Suitable for domestic fuel oil (HBO I)",
    label: "EF021962: Suitable for domestic fuel oil (HBO I)"
  },
  {
    featureId: "EF021963",
    featureDesc: "Suitable for paraffin",
    label: "EF021963: Suitable for paraffin"
  },
  {
    featureId: "EF021964",
    featureDesc: "Gas consumption",
    label: "EF021964: Gas consumption"
  },
  {
    featureId: "EF021965",
    featureDesc: "Oil consumption",
    label: "EF021965: Oil consumption"
  },
  {
    featureId: "EF021966",
    featureDesc: "Thermocouple protection",
    label: "EF021966: Thermocouple protection"
  },
  {
    featureId: "EF021967",
    featureDesc: "Ventilation position",
    label: "EF021967: Ventilation position"
  },
  {
    featureId: "EF021968",
    featureDesc: "Dry setting",
    label: "EF021968: Dry setting"
  },
  {
    featureId: "EF021969",
    featureDesc: "Facility for external thermostat",
    label: "EF021969: Facility for external thermostat"
  },
  {
    featureId: "EF021970",
    featureDesc: "Time switch",
    label: "EF021970: Time switch"
  },
  {
    featureId: "EF021971",
    featureDesc: "Facility for air transport hose",
    label: "EF021971: Facility for air transport hose"
  },
  {
    featureId: "EF021972",
    featureDesc: "Diameter exhaust",
    label: "EF021972: Diameter exhaust"
  },
  {
    featureId: "EF021974",
    featureDesc: "Fire pattern",
    label: "EF021974: Fire pattern"
  },
  {
    featureId: "EF021975",
    featureDesc: "Double burner system",
    label: "EF021975: Double burner system"
  },
  {
    featureId: "EF021977",
    featureDesc: "Combustion air supply",
    label: "EF021977: Combustion air supply"
  },
  {
    featureId: "EF021978",
    featureDesc: "Façade connection",
    label: "EF021978: Façade connection"
  },
  {
    featureId: "EF021979",
    featureDesc: "Closed, concentric",
    label: "EF021979: Closed, concentric"
  },
  {
    featureId: "EF021980",
    featureDesc: "Closed, separated",
    label: "EF021980: Closed, separated"
  },
  {
    featureId: "EF021982",
    featureDesc: "With piezo ignition",
    label: "EF021982: With piezo ignition"
  },
  {
    featureId: "EF021983",
    featureDesc: "Facility for remote control",
    label: "EF021983: Facility for remote control"
  },
  {
    featureId: "EF021984",
    featureDesc: "Wall lead-through diameter",
    label: "EF021984: Wall lead-through diameter"
  },
  {
    featureId: "EF021985",
    featureDesc: "Wall lead-through height/width",
    label: "EF021985: Wall lead-through height/width"
  },
  {
    featureId: "EF021986",
    featureDesc: "Min. height of wall lead-through centre",
    label: "EF021986: Min. height of wall lead-through centre"
  },
  {
    featureId: "EF021987",
    featureDesc: "Water heater type",
    label: "EF021987: Water heater type"
  },
  {
    featureId: "EF021988",
    featureDesc: "Hot tap water capacity at 45 °C",
    label: "EF021988: Hot tap water capacity at 45 °C"
  },
  {
    featureId: "EF021989",
    featureDesc: "With flue",
    label: "EF021989: With flue"
  },
  {
    featureId: "EF021990",
    featureDesc: "With flue gas fan",
    label: "EF021990: With flue gas fan"
  },
  {
    featureId: "EF021991",
    featureDesc: "With draught-limiting device",
    label: "EF021991: With draught-limiting device"
  },
  {
    featureId: "EF021992",
    featureDesc: "Diameter, flue/air supply",
    label: "EF021992: Diameter, flue/air supply"
  },
  {
    featureId: "EF021993",
    featureDesc: "Tap water controls",
    label: "EF021993: Tap water controls"
  },
  {
    featureId: "EF021994",
    featureDesc: "Facility for shower connection",
    label: "EF021994: Facility for shower connection"
  },
  {
    featureId: "EF021995",
    featureDesc: "With union nut",
    label: "EF021995: With union nut"
  },
  {
    featureId: "EF021998",
    featureDesc: "Suitable for vertical console",
    label: "EF021998: Suitable for vertical console"
  },
  {
    featureId: "EF022001",
    featureDesc: "Material tank",
    label: "EF022001: Material tank"
  },
  {
    featureId: "EF022002",
    featureDesc: "Double-sided back-flow",
    label: "EF022002: Double-sided back-flow"
  },
  {
    featureId: "EF022016",
    featureDesc: "Max. primary operating pressure",
    label: "EF022016: Max. primary operating pressure"
  },
  {
    featureId: "EF022017",
    featureDesc: "Max. secondary operating pressure",
    label: "EF022017: Max. secondary operating pressure"
  },
  {
    featureId: "EF022024",
    featureDesc: "Primary connection",
    label: "EF022024: Primary connection"
  },
  {
    featureId: "EF022025",
    featureDesc: "Outer pipe diameter primary connection",
    label: "EF022025: Outer pipe diameter primary connection"
  },
  {
    featureId: "EF022026",
    featureDesc: "Volume, discharge tank",
    label: "EF022026: Volume, discharge tank"
  },
  {
    featureId: "EF022029",
    featureDesc: "Adjustable flow rate",
    label: "EF022029: Adjustable flow rate"
  },
  {
    featureId: "EF022034",
    featureDesc: "Spout",
    label: "EF022034: Spout"
  },
  {
    featureId: "EF022042",
    featureDesc: "Volume supply class",
    label: "EF022042: Volume supply class"
  },
  {
    featureId: "EF022044",
    featureDesc: "Material cover bracket",
    label: "EF022044: Material cover bracket"
  },
  {
    featureId: "EF022045",
    featureDesc: "Lead insert",
    label: "EF022045: Lead insert"
  },
  {
    featureId: "EF022046",
    featureDesc: "Length of tail piece",
    label: "EF022046: Length of tail piece"
  },
  {
    featureId: "EF022047",
    featureDesc: "Long lips",
    label: "EF022047: Long lips"
  },
  {
    featureId: "EF022048",
    featureDesc: "With one-strap bracket",
    label: "EF022048: With one-strap bracket"
  },
  {
    featureId: "EF022049",
    featureDesc: "Material waste stack bracket",
    label: "EF022049: Material waste stack bracket"
  },
  {
    featureId: "EF022050",
    featureDesc: "With bottom support",
    label: "EF022050: With bottom support"
  },
  {
    featureId: "EF022051",
    featureDesc: "With bead",
    label: "EF022051: With bead"
  },
  {
    featureId: "EF022052",
    featureDesc: "With lip",
    label: "EF022052: With lip"
  },
  {
    featureId: "EF022053",
    featureDesc: "With tapped hole",
    label: "EF022053: With tapped hole"
  },
  {
    featureId: "EF022054",
    featureDesc: "With rear cleat",
    label: "EF022054: With rear cleat"
  },
  {
    featureId: "EF022056",
    featureDesc: "Fire class B2",
    label: "EF022056: Fire class B2"
  },
  {
    featureId: "EF022061",
    featureDesc: "Outer duct diameter",
    label: "EF022061: Outer duct diameter"
  },
  {
    featureId: "EF022063",
    featureDesc: "With pre-mounted seal",
    label: "EF022063: With pre-mounted seal"
  },
  {
    featureId: "EF022064",
    featureDesc: "With handgrip",
    label: "EF022064: With handgrip"
  },
  {
    featureId: "EF022065",
    featureDesc: "Operating height",
    label: "EF022065: Operating height"
  },
  {
    featureId: "EF022067",
    featureDesc: "End plate, convex",
    label: "EF022067: End plate, convex"
  },
  {
    featureId: "EF022068",
    featureDesc: "Mounting side",
    label: "EF022068: Mounting side"
  },
  {
    featureId: "EF022069",
    featureDesc: "Diameter, duct",
    label: "EF022069: Diameter, duct"
  },
  {
    featureId: "EF022070",
    featureDesc: "Material stud bolt",
    label: "EF022070: Material stud bolt"
  },
  {
    featureId: "EF022071",
    featureDesc: "Internally bituminous",
    label: "EF022071: Internally bituminous"
  },
  {
    featureId: "EF022072",
    featureDesc: "Internally cemented",
    label: "EF022072: Internally cemented"
  },
  {
    featureId: "EF022073",
    featureDesc: "Indirect drive",
    label: "EF022073: Indirect drive"
  },
  {
    featureId: "EF022074",
    featureDesc: "Direct-current motor",
    label: "EF022074: Direct-current motor"
  },
  {
    featureId: "EF022075",
    featureDesc: "Pole-reversible",
    label: "EF022075: Pole-reversible"
  },
  {
    featureId: "EF022076",
    featureDesc: "Suitable for low-current control",
    label: "EF022076: Suitable for low-current control"
  },
  {
    featureId: "EF022077",
    featureDesc: "Suitable for frequency control",
    label: "EF022077: Suitable for frequency control"
  },
  {
    featureId: "EF022078",
    featureDesc: "Number of suction connections",
    label: "EF022078: Number of suction connections"
  },
  {
    featureId: "EF022079",
    featureDesc: "Rear connection",
    label: "EF022079: Rear connection"
  },
  {
    featureId: "EF022080",
    featureDesc: "Switch type",
    label: "EF022080: Switch type"
  },
  {
    featureId: "EF022081",
    featureDesc: "Fixed differential gap",
    label: "EF022081: Fixed differential gap"
  },
  {
    featureId: "EF022082",
    featureDesc: "Max. differential gap",
    label: "EF022082: Max. differential gap"
  },
  {
    featureId: "EF022083",
    featureDesc: "Min. differential gap",
    label: "EF022083: Min. differential gap"
  },
  {
    featureId: "EF022084",
    featureDesc: "Number of switching units",
    label: "EF022084: Number of switching units"
  },
  {
    featureId: "EF022086",
    featureDesc: "Float housing diameter",
    label: "EF022086: Float housing diameter"
  },
  {
    featureId: "EF022087",
    featureDesc: "Centre distance, connections 1 and 2",
    label: "EF022087: Centre distance, connections 1 and 2"
  },
  {
    featureId: "EF022088",
    featureDesc: "Low-level fail-safe",
    label: "EF022088: Low-level fail-safe"
  },
  {
    featureId: "EF022089",
    featureDesc: "High-level fail-safe",
    label: "EF022089: High-level fail-safe"
  },
  {
    featureId: "EF022090",
    featureDesc: "With tension socket",
    label: "EF022090: With tension socket"
  },
  {
    featureId: "EF022106",
    featureDesc: "Undrilled",
    label: "EF022106: Undrilled"
  },
  {
    featureId: "EF022107",
    featureDesc: "Number of flanges",
    label: "EF022107: Number of flanges"
  },
  {
    featureId: "EF022108",
    featureDesc: "Number of gaskets",
    label: "EF022108: Number of gaskets"
  },
  {
    featureId: "EF022109",
    featureDesc: "Number of bolts and nuts",
    label: "EF022109: Number of bolts and nuts"
  },
  {
    featureId: "EF022110",
    featureDesc: "With key cover",
    label: "EF022110: With key cover"
  },
  {
    featureId: "EF022111",
    featureDesc: "Number of detectors",
    label: "EF022111: Number of detectors"
  },
  {
    featureId: "EF022112",
    featureDesc: "With wire break detection",
    label: "EF022112: With wire break detection"
  },
  {
    featureId: "EF022113",
    featureDesc: "Detector height",
    label: "EF022113: Detector height"
  },
  {
    featureId: "EF022114",
    featureDesc: "Detector diameter",
    label: "EF022114: Detector diameter"
  },
  {
    featureId: "EF022115",
    featureDesc: "DIN-rail mounting reinforcer",
    label: "EF022115: DIN-rail mounting reinforcer"
  },
  {
    featureId: "EF022116",
    featureDesc: "Profile",
    label: "EF022116: Profile"
  },
  {
    featureId: "EF022117",
    featureDesc: "Operating method",
    label: "EF022117: Operating method"
  },
  {
    featureId: "EF022119",
    featureDesc: "Max. temperature setting",
    label: "EF022119: Max. temperature setting"
  },
  {
    featureId: "EF022120",
    featureDesc: "Min. pre-pressure",
    label: "EF022120: Min. pre-pressure"
  },
  {
    featureId: "EF022124",
    featureDesc: "Adjustable tap capacity",
    label: "EF022124: Adjustable tap capacity"
  },
  {
    featureId: "EF022125",
    featureDesc: "Max. pre-pressure",
    label: "EF022125: Max. pre-pressure"
  },
  {
    featureId: "EF022126",
    featureDesc: "Cover plate height",
    label: "EF022126: Cover plate height"
  },
  {
    featureId: "EF022129",
    featureDesc: "Max. through flow capacity (300 kPa)",
    label: "EF022129: Max. through flow capacity (300 kPa)"
  },
  {
    featureId: "EF022130",
    featureDesc: "Programmable with remote control",
    label: "EF022130: Programmable with remote control"
  },
  {
    featureId: "EF022131",
    featureDesc: "With pre-flushing",
    label: "EF022131: With pre-flushing"
  },
  {
    featureId: "EF022132",
    featureDesc: "Flushing time usage frequency adjustment",
    label: "EF022132: Flushing time usage frequency adjustment"
  },
  {
    featureId: "EF022133",
    featureDesc: "With building management system connection",
    label: "EF022133: With building management system connection"
  },
  {
    featureId: "EF022134",
    featureDesc: "Adjustable throughflow capacity",
    label: "EF022134: Adjustable throughflow capacity"
  },
  {
    featureId: "EF022139",
    featureDesc: "Diameter, push button",
    label: "EF022139: Diameter, push button"
  },
  {
    featureId: "EF022143",
    featureDesc: "Vane mounting, 1 hole",
    label: "EF022143: Vane mounting, 1 hole"
  },
  {
    featureId: "EF022144",
    featureDesc: "Vane mounting, 2 holes",
    label: "EF022144: Vane mounting, 2 holes"
  },
  {
    featureId: "EF022145",
    featureDesc: "Wall mounting, 1 hole",
    label: "EF022145: Wall mounting, 1 hole"
  },
  {
    featureId: "EF022146",
    featureDesc: "Wall mounting, 2 holes",
    label: "EF022146: Wall mounting, 2 holes"
  },
  {
    featureId: "EF022147",
    featureDesc: "Top mounting, 1 hole",
    label: "EF022147: Top mounting, 1 hole"
  },
  {
    featureId: "EF022148",
    featureDesc: "Panel mounting, 1 hole",
    label: "EF022148: Panel mounting, 1 hole"
  },
  {
    featureId: "EF022149",
    featureDesc: "Column model",
    label: "EF022149: Column model"
  },
  {
    featureId: "EF022151",
    featureDesc: "Angle between taps",
    label: "EF022151: Angle between taps"
  },
  {
    featureId: "EF022152",
    featureDesc: "Suitable for cold water",
    label: "EF022152: Suitable for cold water"
  },
  {
    featureId: "EF022153",
    featureDesc: "Suitable for demineralised water",
    label: "EF022153: Suitable for demineralised water"
  },
  {
    featureId: "EF022154",
    featureDesc: "Suitable for low-pressure steam",
    label: "EF022154: Suitable for low-pressure steam"
  },
  {
    featureId: "EF022155",
    featureDesc: "Suitable for oxygen",
    label: "EF022155: Suitable for oxygen"
  },
  {
    featureId: "EF022156",
    featureDesc: "Suitable for hydrogen",
    label: "EF022156: Suitable for hydrogen"
  },
  {
    featureId: "EF022157",
    featureDesc: "Suitable for carbon dioxide",
    label: "EF022157: Suitable for carbon dioxide"
  },
  {
    featureId: "EF022158",
    featureDesc: "Suitable for argon",
    label: "EF022158: Suitable for argon"
  },
  {
    featureId: "EF022159",
    featureDesc: "Suitable for helium",
    label: "EF022159: Suitable for helium"
  },
  {
    featureId: "EF022161",
    featureDesc: "Suitable for ethylene",
    label: "EF022161: Suitable for ethylene"
  },
  {
    featureId: "EF022162",
    featureDesc: "Suitable for hot water",
    label: "EF022162: Suitable for hot water"
  },
  {
    featureId: "EF022163",
    featureDesc: "Suitable for circulation system",
    label: "EF022163: Suitable for circulation system"
  },
  {
    featureId: "EF022164",
    featureDesc: "Open/closed indicator",
    label: "EF022164: Open/closed indicator"
  },
  {
    featureId: "EF022165",
    featureDesc: "Integrated ball valve",
    label: "EF022165: Integrated ball valve"
  },
  {
    featureId: "EF022166",
    featureDesc: "With pressure controller",
    label: "EF022166: With pressure controller"
  },
  {
    featureId: "EF022167",
    featureDesc: "With control valve",
    label: "EF022167: With control valve"
  },
  {
    featureId: "EF022168",
    featureDesc: "With hand shower",
    label: "EF022168: With hand shower"
  },
  {
    featureId: "EF022169",
    featureDesc: "Material rosette",
    label: "EF022169: Material rosette"
  },
  {
    featureId: "EF022170",
    featureDesc: "With cheek",
    label: "EF022170: With cheek"
  },
  {
    featureId: "EF022171",
    featureDesc: "Material pipe bracket",
    label: "EF022171: Material pipe bracket"
  },
  {
    featureId: "EF022172",
    featureDesc: "Pipe distance adjustable",
    label: "EF022172: Pipe distance adjustable"
  },
  {
    featureId: "EF022173",
    featureDesc: "Wall distance adjustable",
    label: "EF022173: Wall distance adjustable"
  },
  {
    featureId: "EF022174",
    featureDesc: "Pipe distance offset",
    label: "EF022174: Pipe distance offset"
  },
  {
    featureId: "EF022175",
    featureDesc: "Set of half cover brackets",
    label: "EF022175: Set of half cover brackets"
  },
  {
    featureId: "EF022176",
    featureDesc: "Insulation length",
    label: "EF022176: Insulation length"
  },
  {
    featureId: "EF022178",
    featureDesc: "Can be used on solid walls/floors",
    label: "EF022178: Can be used on solid walls/floors"
  },
  {
    featureId: "EF022179",
    featureDesc: "Can be used directly against walls/floors",
    label: "EF022179: Can be used directly against walls/floors"
  },
  {
    featureId: "EF022180",
    featureDesc: "Can be used in separating partitions",
    label: "EF022180: Can be used in separating partitions"
  },
  {
    featureId: "EF022181",
    featureDesc: "Can be used at a distance from walls",
    label: "EF022181: Can be used at a distance from walls"
  },
  {
    featureId: "EF022182",
    featureDesc: "With chain",
    label: "EF022182: With chain"
  },
  {
    featureId: "EF022183",
    featureDesc: "Burner controller high/low",
    label: "EF022183: Burner controller high/low"
  },
  {
    featureId: "EF022184",
    featureDesc: "Burner controller, modulating",
    label: "EF022184: Burner controller, modulating"
  },
  {
    featureId: "EF022185",
    featureDesc: "With legs",
    label: "EF022185: With legs"
  },
  {
    featureId: "EF022186",
    featureDesc: "Built-in bath water heating",
    label: "EF022186: Built-in bath water heating"
  },
  {
    featureId: "EF022187",
    featureDesc: "With disinfection system",
    label: "EF022187: With disinfection system"
  },
  {
    featureId: "EF022188",
    featureDesc: "Blow dry system",
    label: "EF022188: Blow dry system"
  },
  {
    featureId: "EF022191",
    featureDesc: "2-speed motor",
    label: "EF022191: 2-speed motor"
  },
  {
    featureId: "EF022194",
    featureDesc: "Connection delivery side",
    label: "EF022194: Connection delivery side"
  },
  {
    featureId: "EF022195",
    featureDesc: "Connection suction side",
    label: "EF022195: Connection suction side"
  },
  {
    featureId: "EF022198",
    featureDesc: "Nominal duct height of suction end",
    label: "EF022198: Nominal duct height of suction end"
  },
  {
    featureId: "EF022199",
    featureDesc: "Nominal duct width of suction end",
    label: "EF022199: Nominal duct width of suction end"
  },
  {
    featureId: "EF022200",
    featureDesc: "Thermal efficiency",
    label: "EF022200: Thermal efficiency"
  },
  {
    featureId: "EF022201",
    featureDesc: "With bypass",
    label: "EF022201: With bypass"
  },
  {
    featureId: "EF022203",
    featureDesc: "Ladies model",
    label: "EF022203: Ladies model"
  },
  {
    featureId: "EF022204",
    featureDesc: "Integrated siphon",
    label: "EF022204: Integrated siphon"
  },
  {
    featureId: "EF022205",
    featureDesc: "Integrated electronic control",
    label: "EF022205: Integrated electronic control"
  },
  {
    featureId: "EF022213",
    featureDesc: "With wall plate",
    label: "EF022213: With wall plate"
  },
  {
    featureId: "EF022217",
    featureDesc: "Rotating S bend",
    label: "EF022217: Rotating S bend"
  },
  {
    featureId: "EF022221",
    featureDesc: "Side shower",
    label: "EF022221: Side shower"
  },
  {
    featureId: "EF022222",
    featureDesc: "Adjustable head",
    label: "EF022222: Adjustable head"
  },
  {
    featureId: "EF022223",
    featureDesc: "Adjustable spraying angle",
    label: "EF022223: Adjustable spraying angle"
  },
  {
    featureId: "EF022224",
    featureDesc: "Adjustable spraying jet",
    label: "EF022224: Adjustable spraying jet"
  },
  {
    featureId: "EF022225",
    featureDesc: "Rotating spout",
    label: "EF022225: Rotating spout"
  },
  {
    featureId: "EF022226",
    featureDesc: "Outer diameter of rosette",
    label: "EF022226: Outer diameter of rosette"
  },
  {
    featureId: "EF022227",
    featureDesc: "Inner diameter of rosette",
    label: "EF022227: Inner diameter of rosette"
  },
  {
    featureId: "EF022229",
    featureDesc: "Clamping",
    label: "EF022229: Clamping"
  },
  {
    featureId: "EF022232",
    featureDesc: "Cover plate diameter",
    label: "EF022232: Cover plate diameter"
  },
  {
    featureId: "EF022233",
    featureDesc: "Overflow diameter",
    label: "EF022233: Overflow diameter"
  },
  {
    featureId: "EF022234",
    featureDesc: "Drainage pipe rotating",
    label: "EF022234: Drainage pipe rotating"
  },
  {
    featureId: "EF022236",
    featureDesc: "With raised rear side",
    label: "EF022236: With raised rear side"
  },
  {
    featureId: "EF022240",
    featureDesc: "With bucket grid",
    label: "EF022240: With bucket grid"
  },
  {
    featureId: "EF022241",
    featureDesc: "With ribbed shut-off section",
    label: "EF022241: With ribbed shut-off section"
  },
  {
    featureId: "EF022242",
    featureDesc: "With smooth shut-off section",
    label: "EF022242: With smooth shut-off section"
  },
  {
    featureId: "EF022243",
    featureDesc: "With corner/dirt grid",
    label: "EF022243: With corner/dirt grid"
  },
  {
    featureId: "EF022244",
    featureDesc: "Drive section length",
    label: "EF022244: Drive section length"
  },
  {
    featureId: "EF022245",
    featureDesc: "Built-in section length",
    label: "EF022245: Built-in section length"
  },
  {
    featureId: "EF022246",
    featureDesc: "Min. operating space required",
    label: "EF022246: Min. operating space required"
  },
  {
    featureId: "EF022247",
    featureDesc: "Fire damper diameter",
    label: "EF022247: Fire damper diameter"
  },
  {
    featureId: "EF022248",
    featureDesc: "Number of users",
    label: "EF022248: Number of users"
  },
  {
    featureId: "EF022249",
    featureDesc: "Type of draw-off point",
    label: "EF022249: Type of draw-off point"
  },
  {
    featureId: "EF022250",
    featureDesc: "Water supply",
    label: "EF022250: Water supply"
  },
  {
    featureId: "EF022251",
    featureDesc: "With soap distributor",
    label: "EF022251: With soap distributor"
  },
  {
    featureId: "EF022252",
    featureDesc: "With soap dish",
    label: "EF022252: With soap dish"
  },
  {
    featureId: "EF022254",
    featureDesc: "With standpipe",
    label: "EF022254: With standpipe"
  },
  {
    featureId: "EF022255",
    featureDesc: "With rear wall",
    label: "EF022255: With rear wall"
  },
  {
    featureId: "EF022256",
    featureDesc: "Key operated",
    label: "EF022256: Key operated"
  },
  {
    featureId: "EF022258",
    featureDesc: "Separate feed-through for spindle",
    label: "EF022258: Separate feed-through for spindle"
  },
  {
    featureId: "EF022259",
    featureDesc: "Wrench width union nut",
    label: "EF022259: Wrench width union nut"
  },
  {
    featureId: "EF022260",
    featureDesc: "Valve design",
    label: "EF022260: Valve design"
  },
  {
    featureId: "EF022262",
    featureDesc: "Fire-safe",
    label: "EF022262: Fire-safe"
  },
  {
    featureId: "EF022266",
    featureDesc: "Material hose",
    label: "EF022266: Material hose"
  },
  {
    featureId: "EF022267",
    featureDesc: "With conical coupling",
    label: "EF022267: With conical coupling"
  },
  {
    featureId: "EF022268",
    featureDesc: "Shower hose threaded connection",
    label: "EF022268: Shower hose threaded connection"
  },
  {
    featureId: "EF022269",
    featureDesc: "Size of shower hose threaded connection",
    label: "EF022269: Size of shower hose threaded connection"
  },
  {
    featureId: "EF022270",
    featureDesc: "Connection, duct",
    label: "EF022270: Connection, duct"
  },
  {
    featureId: "EF022271",
    featureDesc: "Fully closing",
    label: "EF022271: Fully closing"
  },
  {
    featureId: "EF022272",
    featureDesc: "Single-bearing valve shaft",
    label: "EF022272: Single-bearing valve shaft"
  },
  {
    featureId: "EF022273",
    featureDesc: "Height operating element",
    label: "EF022273: Height operating element"
  },
  {
    featureId: "EF022274",
    featureDesc: "Number of vanes",
    label: "EF022274: Number of vanes"
  },
  {
    featureId: "EF022275",
    featureDesc: "Synchronised rotating blades",
    label: "EF022275: Synchronised rotating blades"
  },
  {
    featureId: "EF022276",
    featureDesc: "Smaller valve plate",
    label: "EF022276: Smaller valve plate"
  },
  {
    featureId: "EF022278",
    featureDesc: "With supporting leg",
    label: "EF022278: With supporting leg"
  },
  {
    featureId: "EF022279",
    featureDesc: "With roll holder",
    label: "EF022279: With roll holder"
  },
  {
    featureId: "EF022280",
    featureDesc: "Concealed fastening",
    label: "EF022280: Concealed fastening"
  },
  {
    featureId: "EF022281",
    featureDesc: "Suitable for corner mounting",
    label: "EF022281: Suitable for corner mounting"
  },
  {
    featureId: "EF022282",
    featureDesc: "Adjustable depth",
    label: "EF022282: Adjustable depth"
  },
  {
    featureId: "EF022284",
    featureDesc: "With soap holder",
    label: "EF022284: With soap holder"
  },
  {
    featureId: "EF022285",
    featureDesc: "Bitumen type",
    label: "EF022285: Bitumen type"
  },
  {
    featureId: "EF022286",
    featureDesc: "Inlay 2",
    label: "EF022286: Inlay 2"
  },
  {
    featureId: "EF022287",
    featureDesc: "Inlay 1",
    label: "EF022287: Inlay 1"
  },
  {
    featureId: "EF022288",
    featureDesc: "Top finish",
    label: "EF022288: Top finish"
  },
  {
    featureId: "EF022289",
    featureDesc: "Bottom finish",
    label: "EF022289: Bottom finish"
  },
  {
    featureId: "EF022290",
    featureDesc: "Top layer",
    label: "EF022290: Top layer"
  },
  {
    featureId: "EF022291",
    featureDesc: "Underlayer",
    label: "EF022291: Underlayer"
  },
  {
    featureId: "EF022292",
    featureDesc: "Vapour-pressure distribution layer",
    label: "EF022292: Vapour-pressure distribution layer"
  },
  {
    featureId: "EF022293",
    featureDesc: "Vapour inhibition layer",
    label: "EF022293: Vapour inhibition layer"
  },
  {
    featureId: "EF022294",
    featureDesc: "Top finish colour",
    label: "EF022294: Top finish colour"
  },
  {
    featureId: "EF022299",
    featureDesc: "Burner controller, pulsating",
    label: "EF022299: Burner controller, pulsating"
  },
  {
    featureId: "EF022300",
    featureDesc: "dT heating air",
    label: "EF022300: dT heating air"
  },
  {
    featureId: "EF022301",
    featureDesc: "Throw",
    label: "EF022301: Throw"
  },
  {
    featureId: "EF022302",
    featureDesc: "Fan type",
    label: "EF022302: Fan type"
  },
  {
    featureId: "EF022303",
    featureDesc: "Pilot light with piezo ignition",
    label: "EF022303: Pilot light with piezo ignition"
  },
  {
    featureId: "EF022304",
    featureDesc: "Euroair Quality label",
    label: "EF022304: Euroair Quality label"
  },
  {
    featureId: "EF022305",
    featureDesc: "Connection side duct height",
    label: "EF022305: Connection side duct height"
  },
  {
    featureId: "EF022306",
    featureDesc: "Connection side duct width",
    label: "EF022306: Connection side duct width"
  },
  {
    featureId: "EF022313",
    featureDesc: "Connection, outlet side",
    label: "EF022313: Connection, outlet side"
  },
  {
    featureId: "EF022314",
    featureDesc: "Exhaust side duct height",
    label: "EF022314: Exhaust side duct height"
  },
  {
    featureId: "EF022315",
    featureDesc: "Exhaust sides duct width",
    label: "EF022315: Exhaust sides duct width"
  },
  {
    featureId: "EF022316",
    featureDesc: "Outer pipe diameter combustion air",
    label: "EF022316: Outer pipe diameter combustion air"
  },
  {
    featureId: "EF022317",
    featureDesc: "Air extraction valve",
    label: "EF022317: Air extraction valve"
  },
  {
    featureId: "EF022318",
    featureDesc: "Air supply valve",
    label: "EF022318: Air supply valve"
  },
  {
    featureId: "EF022319",
    featureDesc: "Air flow rate",
    label: "EF022319: Air flow rate"
  },
  {
    featureId: "EF022320",
    featureDesc: "High-inducing",
    label: "EF022320: High-inducing"
  },
  {
    featureId: "EF022321",
    featureDesc: "Self-controlling",
    label: "EF022321: Self-controlling"
  },
  {
    featureId: "EF022322",
    featureDesc: "Outlet pattern limited",
    label: "EF022322: Outlet pattern limited"
  },
  {
    featureId: "EF022323",
    featureDesc: "With left/right-handed thread",
    label: "EF022323: With left/right-handed thread"
  },
  {
    featureId: "EF022325",
    featureDesc: "Density, insulation",
    label: "EF022325: Density, insulation"
  },
  {
    featureId: "EF022326",
    featureDesc: "Angle type",
    label: "EF022326: Angle type"
  },
  {
    featureId: "EF022327",
    featureDesc: "With slotted holes",
    label: "EF022327: With slotted holes"
  },
  {
    featureId: "EF022328",
    featureDesc: "Material floor drain",
    label: "EF022328: Material floor drain"
  },
  {
    featureId: "EF022329",
    featureDesc: "Material grid",
    label: "EF022329: Material grid"
  },
  {
    featureId: "EF022330",
    featureDesc: "Direction of outlet",
    label: "EF022330: Direction of outlet"
  },
  {
    featureId: "EF022331",
    featureDesc: "Angle, inclined outlet",
    label: "EF022331: Angle, inclined outlet"
  },
  {
    featureId: "EF022332",
    featureDesc: "Width grid",
    label: "EF022332: Width grid"
  },
  {
    featureId: "EF022333",
    featureDesc: "Length grid",
    label: "EF022333: Length grid"
  },
  {
    featureId: "EF022334",
    featureDesc: "Diameter grid",
    label: "EF022334: Diameter grid"
  },
  {
    featureId: "EF022335",
    featureDesc: "Load grid",
    label: "EF022335: Load grid"
  },
  {
    featureId: "EF022336",
    featureDesc: "Number of extra inlets",
    label: "EF022336: Number of extra inlets"
  },
  {
    featureId: "EF022337",
    featureDesc: "Nominal diameter outlet",
    label: "EF022337: Nominal diameter outlet"
  },
  {
    featureId: "EF022338",
    featureDesc: "Connection outlet",
    label: "EF022338: Connection outlet"
  },
  {
    featureId: "EF022339",
    featureDesc: "Outer pipe diameter outlet",
    label: "EF022339: Outer pipe diameter outlet"
  },
  {
    featureId: "EF022340",
    featureDesc: "Nominal diameter inlet",
    label: "EF022340: Nominal diameter inlet"
  },
  {
    featureId: "EF022341",
    featureDesc: "Outer pipe diameter inlet",
    label: "EF022341: Outer pipe diameter inlet"
  },
  {
    featureId: "EF022342",
    featureDesc: "Inlets capped",
    label: "EF022342: Inlets capped"
  },
  {
    featureId: "EF022343",
    featureDesc: "Connection inlets",
    label: "EF022343: Connection inlets"
  },
  {
    featureId: "EF022344",
    featureDesc: "Screw mounting",
    label: "EF022344: Screw mounting"
  },
  {
    featureId: "EF022345",
    featureDesc: "Asphalted",
    label: "EF022345: Asphalted"
  },
  {
    featureId: "EF022346",
    featureDesc: "Material pit",
    label: "EF022346: Material pit"
  },
  {
    featureId: "EF022347",
    featureDesc: "Cover load",
    label: "EF022347: Cover load"
  },
  {
    featureId: "EF022348",
    featureDesc: "Suitable for fat (organic)",
    label: "EF022348: Suitable for fat (organic)"
  },
  {
    featureId: "EF022349",
    featureDesc: "Flow-through capacity",
    label: "EF022349: Flow-through capacity"
  },
  {
    featureId: "EF022350",
    featureDesc: "Inner cladding",
    label: "EF022350: Inner cladding"
  },
  {
    featureId: "EF022351",
    featureDesc: "Outer lining",
    label: "EF022351: Outer lining"
  },
  {
    featureId: "EF022352",
    featureDesc: "Storage capacity of separator",
    label: "EF022352: Storage capacity of separator"
  },
  {
    featureId: "EF022353",
    featureDesc: "With coalescence filter",
    label: "EF022353: With coalescence filter"
  },
  {
    featureId: "EF022354",
    featureDesc: "With mud trap",
    label: "EF022354: With mud trap"
  },
  {
    featureId: "EF022355",
    featureDesc: "Collection capacity, mud trap",
    label: "EF022355: Collection capacity, mud trap"
  },
  {
    featureId: "EF022356",
    featureDesc: "Suitable for oil (mineral)",
    label: "EF022356: Suitable for oil (mineral)"
  },
  {
    featureId: "EF022357",
    featureDesc: "Gutter diameter",
    label: "EF022357: Gutter diameter"
  },
  {
    featureId: "EF022358",
    featureDesc: "Tail length",
    label: "EF022358: Tail length"
  },
  {
    featureId: "EF022359",
    featureDesc: "Nominal diameter expansion connection",
    label: "EF022359: Nominal diameter expansion connection"
  },
  {
    featureId: "EF022360",
    featureDesc: "Outer pipe diameter, expansion connection",
    label: "EF022360: Outer pipe diameter, expansion connection"
  },
  {
    featureId: "EF022362",
    featureDesc: "With exchangeable membrane",
    label: "EF022362: With exchangeable membrane"
  },
  {
    featureId: "EF022364",
    featureDesc: "Pump capacity",
    label: "EF022364: Pump capacity"
  },
  {
    featureId: "EF022365",
    featureDesc: "Digital control unit",
    label: "EF022365: Digital control unit"
  },
  {
    featureId: "EF022366",
    featureDesc: "Double pump/compressor unit",
    label: "EF022366: Double pump/compressor unit"
  },
  {
    featureId: "EF022367",
    featureDesc: "Compressor in base",
    label: "EF022367: Compressor in base"
  },
  {
    featureId: "EF022368",
    featureDesc: "For pump control",
    label: "EF022368: For pump control"
  },
  {
    featureId: "EF022369",
    featureDesc: "For compressor control",
    label: "EF022369: For compressor control"
  },
  {
    featureId: "EF022371",
    featureDesc: "With fixed wall support",
    label: "EF022371: With fixed wall support"
  },
  {
    featureId: "EF022372",
    featureDesc: "End pressure",
    label: "EF022372: End pressure"
  },
  {
    featureId: "EF022375",
    featureDesc: "Connection central heating",
    label: "EF022375: Connection central heating"
  },
  {
    featureId: "EF022376",
    featureDesc: "Tank size",
    label: "EF022376: Tank size"
  },
  {
    featureId: "EF022377",
    featureDesc: "With rapid coupling",
    label: "EF022377: With rapid coupling"
  },
  {
    featureId: "EF022380",
    featureDesc: "Thread connection, tank",
    label: "EF022380: Thread connection, tank"
  },
  {
    featureId: "EF022381",
    featureDesc: "Size of tank thread connection",
    label: "EF022381: Size of tank thread connection"
  },
  {
    featureId: "EF022382",
    featureDesc: "Outer section height",
    label: "EF022382: Outer section height"
  },
  {
    featureId: "EF022383",
    featureDesc: "Outer section width",
    label: "EF022383: Outer section width"
  },
  {
    featureId: "EF022385",
    featureDesc: "Externally red-leaded",
    label: "EF022385: Externally red-leaded"
  },
  {
    featureId: "EF022386",
    featureDesc: "Seat, covered",
    label: "EF022386: Seat, covered"
  },
  {
    featureId: "EF022387",
    featureDesc: "Height of pull handle adjustable",
    label: "EF022387: Height of pull handle adjustable"
  },
  {
    featureId: "EF022388",
    featureDesc: "With arm supports",
    label: "EF022388: With arm supports"
  },
  {
    featureId: "EF022389",
    featureDesc: "With separate glass plate",
    label: "EF022389: With separate glass plate"
  },
  {
    featureId: "EF022391",
    featureDesc: "Glass type",
    label: "EF022391: Glass type"
  },
  {
    featureId: "EF022393",
    featureDesc: "Mirror frame colour",
    label: "EF022393: Mirror frame colour"
  },
  {
    featureId: "EF022394",
    featureDesc: "With facet",
    label: "EF022394: With facet"
  },
  {
    featureId: "EF022396",
    featureDesc: "With mirror frame",
    label: "EF022396: With mirror frame"
  },
  {
    featureId: "EF022397",
    featureDesc: "Material mirror frame",
    label: "EF022397: Material mirror frame"
  },
  {
    featureId: "EF022398",
    featureDesc: "Fastening materials colour",
    label: "EF022398: Fastening materials colour"
  },
  {
    featureId: "EF022401",
    featureDesc: "Connection to shower",
    label: "EF022401: Connection to shower"
  },
  {
    featureId: "EF022402",
    featureDesc: "Operating power",
    label: "EF022402: Operating power"
  },
  {
    featureId: "EF022403",
    featureDesc: "Max. cooling power (dT)",
    label: "EF022403: Max. cooling power (dT)"
  },
  {
    featureId: "EF022404",
    featureDesc: "Adjustable from the outside",
    label: "EF022404: Adjustable from the outside"
  },
  {
    featureId: "EF022406",
    featureDesc: "Nominal duct diameter, branch",
    label: "EF022406: Nominal duct diameter, branch"
  },
  {
    featureId: "EF022407",
    featureDesc: "Connection, bend",
    label: "EF022407: Connection, bend"
  },
  {
    featureId: "EF022408",
    featureDesc: "Connection, branch",
    label: "EF022408: Connection, branch"
  },
  {
    featureId: "EF022411",
    featureDesc: "Crescent seat",
    label: "EF022411: Crescent seat"
  },
  {
    featureId: "EF022412",
    featureDesc: "Control panel width",
    label: "EF022412: Control panel width"
  },
  {
    featureId: "EF022413",
    featureDesc: "Control panel height",
    label: "EF022413: Control panel height"
  },
  {
    featureId: "EF022414",
    featureDesc: "Cord thickness",
    label: "EF022414: Cord thickness"
  },
  {
    featureId: "EF022417",
    featureDesc: "FDA quality mark",
    label: "EF022417: FDA quality mark"
  },
  {
    featureId: "EF022423",
    featureDesc: "Silicone-free",
    label: "EF022423: Silicone-free"
  },
  {
    featureId: "EF022424",
    featureDesc: "LABS-free",
    label: "EF022424: LABS-free"
  },
  {
    featureId: "EF022425",
    featureDesc: "Clamping type",
    label: "EF022425: Clamping type"
  },
  {
    featureId: "EF022426",
    featureDesc: "Measuring pipe lining",
    label: "EF022426: Measuring pipe lining"
  },
  {
    featureId: "EF022428",
    featureDesc: "With universal read-out",
    label: "EF022428: With universal read-out"
  },
  {
    featureId: "EF022431",
    featureDesc: "Min. volume flow",
    label: "EF022431: Min. volume flow"
  },
  {
    featureId: "EF022433",
    featureDesc: "Double compressed-air valve",
    label: "EF022433: Double compressed-air valve"
  },
  {
    featureId: "EF022434",
    featureDesc: "Material inner pipe",
    label: "EF022434: Material inner pipe"
  },
  {
    featureId: "EF022435",
    featureDesc: "Material outer pipe",
    label: "EF022435: Material outer pipe"
  },
  {
    featureId: "EF022436",
    featureDesc: "Overpressure",
    label: "EF022436: Overpressure"
  },
  {
    featureId: "EF022437",
    featureDesc: "With storm collar",
    label: "EF022437: With storm collar"
  },
  {
    featureId: "EF022438",
    featureDesc: "Outer duct diameter, connection 1",
    label: "EF022438: Outer duct diameter, connection 1"
  },
  {
    featureId: "EF022439",
    featureDesc: "Outer duct diameter, connection 2",
    label: "EF022439: Outer duct diameter, connection 2"
  },
  {
    featureId: "EF022440",
    featureDesc: "Outer duct diameter, connection 3",
    label: "EF022440: Outer duct diameter, connection 3"
  },
  {
    featureId: "EF022441",
    featureDesc: "Diameter jacket",
    label: "EF022441: Diameter jacket"
  },
  {
    featureId: "EF022442",
    featureDesc: "Jacket plate thickness",
    label: "EF022442: Jacket plate thickness"
  },
  {
    featureId: "EF022443",
    featureDesc: "Suitable for spiral-folded",
    label: "EF022443: Suitable for spiral-folded"
  },
  {
    featureId: "EF022444",
    featureDesc: "Outer diameter, cone",
    label: "EF022444: Outer diameter, cone"
  },
  {
    featureId: "EF022445",
    featureDesc: "Width inspection opening",
    label: "EF022445: Width inspection opening"
  },
  {
    featureId: "EF022446",
    featureDesc: "Height inspection opening",
    label: "EF022446: Height inspection opening"
  },
  {
    featureId: "EF022447",
    featureDesc: "With screw-lock",
    label: "EF022447: With screw-lock"
  },
  {
    featureId: "EF022448",
    featureDesc: "Nominal duct diameter, branches",
    label: "EF022448: Nominal duct diameter, branches"
  },
  {
    featureId: "EF022450",
    featureDesc: "Round duct",
    label: "EF022450: Round duct"
  },
  {
    featureId: "EF022451",
    featureDesc: "Rectangular duct",
    label: "EF022451: Rectangular duct"
  },
  {
    featureId: "EF022452",
    featureDesc: "With Pitot tube",
    label: "EF022452: With Pitot tube"
  },
  {
    featureId: "EF022453",
    featureDesc: "With glass holder",
    label: "EF022453: With glass holder"
  },
  {
    featureId: "EF022454",
    featureDesc: "With anti-theft security",
    label: "EF022454: With anti-theft security"
  },
  {
    featureId: "EF022458",
    featureDesc: "Wall roughness",
    label: "EF022458: Wall roughness"
  },
  {
    featureId: "EF022461",
    featureDesc: "Housing material",
    label: "EF022461: Housing material"
  },
  {
    featureId: "EF022462",
    featureDesc: "Surface treatment, exterior",
    label: "EF022462: Surface treatment, exterior"
  },
  {
    featureId: "EF022463",
    featureDesc: "Medium",
    label: "EF022463: Medium"
  },
  {
    featureId: "EF022465",
    featureDesc: "Production method",
    label: "EF022465: Production method"
  },
  {
    featureId: "EF022466",
    featureDesc: "Kvs value",
    label: "EF022466: Kvs value"
  },
  {
    featureId: "EF022467",
    featureDesc: "Presetting",
    label: "EF022467: Presetting"
  },
  {
    featureId: "EF022468",
    featureDesc: "Material spindle",
    label: "EF022468: Material spindle"
  },
  {
    featureId: "EF022469",
    featureDesc: "With position indicator",
    label: "EF022469: With position indicator"
  },
  {
    featureId: "EF022470",
    featureDesc: "With drain possibility (connection)",
    label: "EF022470: With drain possibility (connection)"
  },
  {
    featureId: "EF022471",
    featureDesc: "With measuring nipples",
    label: "EF022471: With measuring nipples"
  },
  {
    featureId: "EF022472",
    featureDesc: "Connections",
    label: "EF022472: Connections"
  },
  {
    featureId: "EF022473",
    featureDesc: "Discharge head at nominal flow rate",
    label: "EF022473: Discharge head at nominal flow rate"
  },
  {
    featureId: "EF022475",
    featureDesc: "Built-in pressure gauge",
    label: "EF022475: Built-in pressure gauge"
  },
  {
    featureId: "EF022478",
    featureDesc: "Length of support arm",
    label: "EF022478: Length of support arm"
  },
  {
    featureId: "EF022482",
    featureDesc: "Nominal diameter branch",
    label: "EF022482: Nominal diameter branch"
  },
  {
    featureId: "EF022484",
    featureDesc: "With shut off valve",
    label: "EF022484: With shut off valve"
  },
  {
    featureId: "EF022485",
    featureDesc: "Breakdown voltage",
    label: "EF022485: Breakdown voltage"
  },
  {
    featureId: "EF022486",
    featureDesc: "Material camp strip",
    label: "EF022486: Material camp strip"
  },
  {
    featureId: "EF022487",
    featureDesc: "Number of scale units",
    label: "EF022487: Number of scale units"
  },
  {
    featureId: "EF022491",
    featureDesc: "Outer pipe diameter sleeveless",
    label: "EF022491: Outer pipe diameter sleeveless"
  },
  {
    featureId: "EF022492",
    featureDesc: "Outer pipe diameter transition",
    label: "EF022492: Outer pipe diameter transition"
  },
  {
    featureId: "EF022493",
    featureDesc: "With core/cone",
    label: "EF022493: With core/cone"
  },
  {
    featureId: "EF022494",
    featureDesc: "Damping 125 Hz",
    label: "EF022494: Damping 125 Hz"
  },
  {
    featureId: "EF022495",
    featureDesc: "Damping 250 Hz",
    label: "EF022495: Damping 250 Hz"
  },
  {
    featureId: "EF022496",
    featureDesc: "Damping 500 Hz",
    label: "EF022496: Damping 500 Hz"
  },
  {
    featureId: "EF022497",
    featureDesc: "Damping 1000 Hz",
    label: "EF022497: Damping 1000 Hz"
  },
  {
    featureId: "EF022498",
    featureDesc: "Damping 2000 Hz",
    label: "EF022498: Damping 2000 Hz"
  },
  {
    featureId: "EF022499",
    featureDesc: "Damping 4000 Hz",
    label: "EF022499: Damping 4000 Hz"
  },
  {
    featureId: "EF022500",
    featureDesc: "Material pipe",
    label: "EF022500: Material pipe"
  },
  {
    featureId: "EF022501",
    featureDesc: "Linear system",
    label: "EF022501: Linear system"
  },
  {
    featureId: "EF022502",
    featureDesc: "Thread connection, drain",
    label: "EF022502: Thread connection, drain"
  },
  {
    featureId: "EF022503",
    featureDesc: "Size of drain thread connection",
    label: "EF022503: Size of drain thread connection"
  },
  {
    featureId: "EF022504",
    featureDesc: "Assembled",
    label: "EF022504: Assembled"
  },
  {
    featureId: "EF022505",
    featureDesc: "With second hose coupling",
    label: "EF022505: With second hose coupling"
  },
  {
    featureId: "EF022506",
    featureDesc: "With second hose clamp",
    label: "EF022506: With second hose clamp"
  },
  {
    featureId: "EF022507",
    featureDesc: "With filling instructions",
    label: "EF022507: With filling instructions"
  },
  {
    featureId: "EF022510",
    featureDesc: "Length per element",
    label: "EF022510: Length per element"
  },
  {
    featureId: "EF022511",
    featureDesc: "Working length per element",
    label: "EF022511: Working length per element"
  },
  {
    featureId: "EF022512",
    featureDesc: "Burner element length",
    label: "EF022512: Burner element length"
  },
  {
    featureId: "EF022513",
    featureDesc: "Burner element width",
    label: "EF022513: Burner element width"
  },
  {
    featureId: "EF022514",
    featureDesc: "Burner element height",
    label: "EF022514: Burner element height"
  },
  {
    featureId: "EF022515",
    featureDesc: "Element width",
    label: "EF022515: Element width"
  },
  {
    featureId: "EF022516",
    featureDesc: "Element height",
    label: "EF022516: Element height"
  },
  {
    featureId: "EF022517",
    featureDesc: "With stench trap",
    label: "EF022517: With stench trap"
  },
  {
    featureId: "EF022518",
    featureDesc: "With grit edge",
    label: "EF022518: With grit edge"
  },
  {
    featureId: "EF022519",
    featureDesc: "Connection length",
    label: "EF022519: Connection length"
  },
  {
    featureId: "EF022520",
    featureDesc: "Condensing",
    label: "EF022520: Condensing"
  },
  {
    featureId: "EF022522",
    featureDesc: "Medium of heat exchanger",
    label: "EF022522: Medium of heat exchanger"
  },
  {
    featureId: "EF022528",
    featureDesc: "Medium volume",
    label: "EF022528: Medium volume"
  },
  {
    featureId: "EF022533",
    featureDesc: "Max. overpressure",
    label: "EF022533: Max. overpressure"
  },
  {
    featureId: "EF022534",
    featureDesc: "External electronics required",
    label: "EF022534: External electronics required"
  },
  {
    featureId: "EF022535",
    featureDesc: "Min. switching flow rate",
    label: "EF022535: Min. switching flow rate"
  },
  {
    featureId: "EF022536",
    featureDesc: "Max. switching flow rate",
    label: "EF022536: Max. switching flow rate"
  },
  {
    featureId: "EF022537",
    featureDesc: "Switching section height",
    label: "EF022537: Switching section height"
  },
  {
    featureId: "EF022538",
    featureDesc: "Switching section diameter",
    label: "EF022538: Switching section diameter"
  },
  {
    featureId: "EF022539",
    featureDesc: "Nominal diameter process side",
    label: "EF022539: Nominal diameter process side"
  },
  {
    featureId: "EF022540",
    featureDesc: "Min. pipe diameter",
    label: "EF022540: Min. pipe diameter"
  },
  {
    featureId: "EF022541",
    featureDesc: "Max. contact load",
    label: "EF022541: Max. contact load"
  },
  {
    featureId: "EF022542",
    featureDesc: "Filter element length",
    label: "EF022542: Filter element length"
  },
  {
    featureId: "EF022543",
    featureDesc: "Suitable for outer diameter",
    label: "EF022543: Suitable for outer diameter"
  },
  {
    featureId: "EF022544",
    featureDesc: "Length of inspection opening",
    label: "EF022544: Length of inspection opening"
  },
  {
    featureId: "EF022545",
    featureDesc: "Connection, main duct",
    label: "EF022545: Connection, main duct"
  },
  {
    featureId: "EF022546",
    featureDesc: "With shaft extension bush",
    label: "EF022546: With shaft extension bush"
  },
  {
    featureId: "EF022549",
    featureDesc: "Equivalent duct diameter",
    label: "EF022549: Equivalent duct diameter"
  },
  {
    featureId: "EF022550",
    featureDesc: "Height, branch",
    label: "EF022550: Height, branch"
  },
  {
    featureId: "EF022551",
    featureDesc: "Left-hand model",
    label: "EF022551: Left-hand model"
  },
  {
    featureId: "EF022552",
    featureDesc: "Right-handed model",
    label: "EF022552: Right-handed model"
  },
  {
    featureId: "EF022557",
    featureDesc: "Equivalent duct diameter, connection 1",
    label: "EF022557: Equivalent duct diameter, connection 1"
  },
  {
    featureId: "EF022558",
    featureDesc: "Equivalent duct diameter, connection 2",
    label: "EF022558: Equivalent duct diameter, connection 2"
  },
  {
    featureId: "EF022561",
    featureDesc: "Connection height",
    label: "EF022561: Connection height"
  },
  {
    featureId: "EF022562",
    featureDesc: "Connection width",
    label: "EF022562: Connection width"
  },
  {
    featureId: "EF022563",
    featureDesc: "Height, oval duct",
    label: "EF022563: Height, oval duct"
  },
  {
    featureId: "EF022564",
    featureDesc: "Width, oval duct",
    label: "EF022564: Width, oval duct"
  },
  {
    featureId: "EF022568",
    featureDesc: "Max. negative pressure capacity",
    label: "EF022568: Max. negative pressure capacity"
  },
  {
    featureId: "EF022571",
    featureDesc: "With interior guide pipe",
    label: "EF022571: With interior guide pipe"
  },
  {
    featureId: "EF022573",
    featureDesc: "Outer pipe diameter connections 2, 3, 4",
    label: "EF022573: Outer pipe diameter connections 2, 3, 4"
  },
  {
    featureId: "EF022574",
    featureDesc: "Connections 2-3-4",
    label: "EF022574: Connections 2-3-4"
  },
  {
    featureId: "EF022575",
    featureDesc: "Valve bore",
    label: "EF022575: Valve bore"
  },
  {
    featureId: "EF022576",
    featureDesc: "Material core",
    label: "EF022576: Material core"
  },
  {
    featureId: "EF022577",
    featureDesc: "Hollow core",
    label: "EF022577: Hollow core"
  },
  {
    featureId: "EF022578",
    featureDesc: "Total width",
    label: "EF022578: Total width"
  },
  {
    featureId: "EF022579",
    featureDesc: "With guide ring",
    label: "EF022579: With guide ring"
  },
  {
    featureId: "EF022580",
    featureDesc: "Dual-action",
    label: "EF022580: Dual-action"
  },
  {
    featureId: "EF022581",
    featureDesc: "With support ring",
    label: "EF022581: With support ring"
  },
  {
    featureId: "EF022582",
    featureDesc: "Material o-ring",
    label: "EF022582: Material o-ring"
  },
  {
    featureId: "EF022583",
    featureDesc: "Fabric-reinforced sleeve",
    label: "EF022583: Fabric-reinforced sleeve"
  },
  {
    featureId: "EF022584",
    featureDesc: "Rod-sealing",
    label: "EF022584: Rod-sealing"
  },
  {
    featureId: "EF022585",
    featureDesc: "Piston-sealing",
    label: "EF022585: Piston-sealing"
  },
  {
    featureId: "EF022586",
    featureDesc: "Inner diameter of guide ring",
    label: "EF022586: Inner diameter of guide ring"
  },
  {
    featureId: "EF022589",
    featureDesc: "Number of sleeves",
    label: "EF022589: Number of sleeves"
  },
  {
    featureId: "EF022590",
    featureDesc: "Split",
    label: "EF022590: Split"
  },
  {
    featureId: "EF022591",
    featureDesc: "Material wiper seal",
    label: "EF022591: Material wiper seal"
  },
  {
    featureId: "EF022592",
    featureDesc: "Amplification type",
    label: "EF022592: Amplification type"
  },
  {
    featureId: "EF022593",
    featureDesc: "Compression element",
    label: "EF022593: Compression element"
  },
  {
    featureId: "EF022594",
    featureDesc: "Shaft sealing",
    label: "EF022594: Shaft sealing"
  },
  {
    featureId: "EF022595",
    featureDesc: "Material washer",
    label: "EF022595: Material washer"
  },
  {
    featureId: "EF022596",
    featureDesc: "Material spring",
    label: "EF022596: Material spring"
  },
  {
    featureId: "EF022597",
    featureDesc: "Spring shape",
    label: "EF022597: Spring shape"
  },
  {
    featureId: "EF022598",
    featureDesc: "V-angle",
    label: "EF022598: V-angle"
  },
  {
    featureId: "EF022599",
    featureDesc: "Material support ring",
    label: "EF022599: Material support ring"
  },
  {
    featureId: "EF022600",
    featureDesc: "Fabric-reinforced",
    label: "EF022600: Fabric-reinforced"
  },
  {
    featureId: "EF022601",
    featureDesc: "Material pressure ring",
    label: "EF022601: Material pressure ring"
  },
  {
    featureId: "EF022602",
    featureDesc: "Rod-guiding",
    label: "EF022602: Rod-guiding"
  },
  {
    featureId: "EF022603",
    featureDesc: "Piston-guiding",
    label: "EF022603: Piston-guiding"
  },
  {
    featureId: "EF022604",
    featureDesc: "Material guide ring",
    label: "EF022604: Material guide ring"
  },
  {
    featureId: "EF022605",
    featureDesc: "Material guide strip",
    label: "EF022605: Material guide strip"
  },
  {
    featureId: "EF022606",
    featureDesc: "Material sealing",
    label: "EF022606: Material sealing"
  },
  {
    featureId: "EF022607",
    featureDesc: "Material ring",
    label: "EF022607: Material ring"
  },
  {
    featureId: "EF022608",
    featureDesc: "Self-centring",
    label: "EF022608: Self-centring"
  },
  {
    featureId: "EF022610",
    featureDesc: "Nominal diameter instrument side",
    label: "EF022610: Nominal diameter instrument side"
  },
  {
    featureId: "EF022611",
    featureDesc: "Outer pipe diameter, instrument end",
    label: "EF022611: Outer pipe diameter, instrument end"
  },
  {
    featureId: "EF022612",
    featureDesc: "With calibration ports",
    label: "EF022612: With calibration ports"
  },
  {
    featureId: "EF022613",
    featureDesc: "Number of Storz connections",
    label: "EF022613: Number of Storz connections"
  },
  {
    featureId: "EF022614",
    featureDesc: "Height above ground level",
    label: "EF022614: Height above ground level"
  },
  {
    featureId: "EF022615",
    featureDesc: "With coupling",
    label: "EF022615: With coupling"
  },
  {
    featureId: "EF022616",
    featureDesc: "Duo model",
    label: "EF022616: Duo model"
  },
  {
    featureId: "EF022617",
    featureDesc: "Reducing",
    label: "EF022617: Reducing"
  },
  {
    featureId: "EF022620",
    featureDesc: "Sealing surface",
    label: "EF022620: Sealing surface"
  },
  {
    featureId: "EF022621",
    featureDesc: "Set pressure",
    label: "EF022621: Set pressure"
  },
  {
    featureId: "EF022622",
    featureDesc: "Diameter insulation coupling",
    label: "EF022622: Diameter insulation coupling"
  },
  {
    featureId: "EF022623",
    featureDesc: "With supporting ring",
    label: "EF022623: With supporting ring"
  },
  {
    featureId: "EF022624",
    featureDesc: "Valve piece length",
    label: "EF022624: Valve piece length"
  },
  {
    featureId: "EF022625",
    featureDesc: "Decoupling",
    label: "EF022625: Decoupling"
  },
  {
    featureId: "EF022626",
    featureDesc: "Double-sealing",
    label: "EF022626: Double-sealing"
  },
  {
    featureId: "EF022627",
    featureDesc: "With filling/drain tap",
    label: "EF022627: With filling/drain tap"
  },
  {
    featureId: "EF022628",
    featureDesc: "Medically clean",
    label: "EF022628: Medically clean"
  },
  {
    featureId: "EF022630",
    featureDesc: "With cutter",
    label: "EF022630: With cutter"
  },
  {
    featureId: "EF022631",
    featureDesc: "With support strap",
    label: "EF022631: With support strap"
  },
  {
    featureId: "EF022632",
    featureDesc: "With manhole",
    label: "EF022632: With manhole"
  },
  {
    featureId: "EF022633",
    featureDesc: "Outer diameter, in/outlet",
    label: "EF022633: Outer diameter, in/outlet"
  },
  {
    featureId: "EF022634",
    featureDesc: "Outer diameter, connecting pipe",
    label: "EF022634: Outer diameter, connecting pipe"
  },
  {
    featureId: "EF022635",
    featureDesc: "Outer diameter, de-aerator",
    label: "EF022635: Outer diameter, de-aerator"
  },
  {
    featureId: "EF022637",
    featureDesc: "With safety collar",
    label: "EF022637: With safety collar"
  },
  {
    featureId: "EF022639",
    featureDesc: "Diameter, hose connector",
    label: "EF022639: Diameter, hose connector"
  },
  {
    featureId: "EF022640",
    featureDesc: "Material bolt/wall",
    label: "EF022640: Material bolt/wall"
  },
  {
    featureId: "EF022643",
    featureDesc: "Thread connection, lock nut",
    label: "EF022643: Thread connection, lock nut"
  },
  {
    featureId: "EF022644",
    featureDesc: "Size of lock nut thread connection",
    label: "EF022644: Size of lock nut thread connection"
  },
  {
    featureId: "EF022647",
    featureDesc: "With thrust bushing",
    label: "EF022647: With thrust bushing"
  },
  {
    featureId: "EF022648",
    featureDesc: "Outer diameter, inlay part",
    label: "EF022648: Outer diameter, inlay part"
  },
  {
    featureId: "EF022649",
    featureDesc: "Sealing face, connection 1",
    label: "EF022649: Sealing face, connection 1"
  },
  {
    featureId: "EF022650",
    featureDesc: "Collar thickness",
    label: "EF022650: Collar thickness"
  },
  {
    featureId: "EF022652",
    featureDesc: "Size of gland nut thread connection",
    label: "EF022652: Size of gland nut thread connection"
  },
  {
    featureId: "EF022656",
    featureDesc: "Number of patterns",
    label: "EF022656: Number of patterns"
  },
  {
    featureId: "EF022659",
    featureDesc: "Design form",
    label: "EF022659: Design form"
  },
  {
    featureId: "EF022661",
    featureDesc: "Filling strip",
    label: "EF022661: Filling strip"
  },
  {
    featureId: "EF022666",
    featureDesc: "Norm",
    label: "EF022666: Norm"
  },
  {
    featureId: "EF022675",
    featureDesc: "Pipe size in mm",
    label: "EF022675: Pipe size in mm"
  },
  {
    featureId: "EF022676",
    featureDesc: "Fire class",
    label: "EF022676: Fire class"
  },
  {
    featureId: "EF022677",
    featureDesc: "Sound-damping",
    label: "EF022677: Sound-damping"
  },
  {
    featureId: "EF022680",
    featureDesc: "Capillary, fine-adjustable",
    label: "EF022680: Capillary, fine-adjustable"
  },
  {
    featureId: "EF022682",
    featureDesc: "Rail width",
    label: "EF022682: Rail width"
  },
  {
    featureId: "EF022683",
    featureDesc: "Pipe diameter",
    label: "EF022683: Pipe diameter"
  },
  {
    featureId: "EF022685",
    featureDesc: "Outer pipe diameter",
    label: "EF022685: Outer pipe diameter"
  },
  {
    featureId: "EF022687",
    featureDesc: "Drain plug diameter",
    label: "EF022687: Drain plug diameter"
  },
  {
    featureId: "EF022688",
    featureDesc: "Diameter of concentric system",
    label: "EF022688: Diameter of concentric system"
  },
  {
    featureId: "EF022689",
    featureDesc: "Diameter of parallel system",
    label: "EF022689: Diameter of parallel system"
  },
  {
    featureId: "EF022690",
    featureDesc: "Differential between switch steps",
    label: "EF022690: Differential between switch steps"
  },
  {
    featureId: "EF022691",
    featureDesc: "Differential value",
    label: "EF022691: Differential value"
  },
  {
    featureId: "EF022692",
    featureDesc: "Flow-through capacity",
    label: "EF022692: Flow-through capacity"
  },
  {
    featureId: "EF022694",
    featureDesc: "Thread size",
    label: "EF022694: Thread size"
  },
  {
    featureId: "EF022695",
    featureDesc: "Pressure range",
    label: "EF022695: Pressure range"
  },
  {
    featureId: "EF022696",
    featureDesc: "Flange pressure stage",
    label: "EF022696: Flange pressure stage"
  },
  {
    featureId: "EF022697",
    featureDesc: "With end contacts",
    label: "EF022697: With end contacts"
  },
  {
    featureId: "EF022698",
    featureDesc: "Type of electronic model",
    label: "EF022698: Type of electronic model"
  },
  {
    featureId: "EF022700",
    featureDesc: "With frame",
    label: "EF022700: With frame"
  },
  {
    featureId: "EF022701",
    featureDesc: "Flange seal",
    label: "EF022701: Flange seal"
  },
  {
    featureId: "EF022705",
    featureDesc: "Max. thickness",
    label: "EF022705: Max. thickness"
  },
  {
    featureId: "EF022706",
    featureDesc: "Suitable for pipe diameter",
    label: "EF022706: Suitable for pipe diameter"
  },
  {
    featureId: "EF022707",
    featureDesc: "Left-handed thread",
    label: "EF022707: Left-handed thread"
  },
  {
    featureId: "EF022709",
    featureDesc: "Max. welding current",
    label: "EF022709: Max. welding current"
  },
  {
    featureId: "EF022711",
    featureDesc: "Female plug",
    label: "EF022711: Female plug"
  },
  {
    featureId: "EF022712",
    featureDesc: "Suitable for thread size",
    label: "EF022712: Suitable for thread size"
  },
  {
    featureId: "EF022715",
    featureDesc: "Suitable for outer pipe diameter",
    label: "EF022715: Suitable for outer pipe diameter"
  },
  {
    featureId: "EF022716",
    featureDesc: "Glass thickness",
    label: "EF022716: Glass thickness"
  },
  {
    featureId: "EF022717",
    featureDesc: "Groove width (mm)",
    label: "EF022717: Groove width (mm)"
  },
  {
    featureId: "EF022718",
    featureDesc: "Centre distance",
    label: "EF022718: Centre distance"
  },
  {
    featureId: "EF022719",
    featureDesc: "Gas thread dimension",
    label: "EF022719: Gas thread dimension"
  },
  {
    featureId: "EF022720",
    featureDesc: "Hose anti-breaking device",
    label: "EF022720: Hose anti-breaking device"
  },
  {
    featureId: "EF022721",
    featureDesc: "With economy valve",
    label: "EF022721: With economy valve"
  },
  {
    featureId: "EF022723",
    featureDesc: "With bolt holder",
    label: "EF022723: With bolt holder"
  },
  {
    featureId: "EF022724",
    featureDesc: "Protective foil",
    label: "EF022724: Protective foil"
  },
  {
    featureId: "EF022725",
    featureDesc: "Protection cap",
    label: "EF022725: Protection cap"
  },
  {
    featureId: "EF022726",
    featureDesc: "Thread connection",
    label: "EF022726: Thread connection"
  },
  {
    featureId: "EF022730",
    featureDesc: "Timer",
    label: "EF022730: Timer"
  },
  {
    featureId: "EF022731",
    featureDesc: "Welding range",
    label: "EF022731: Welding range"
  },
  {
    featureId: "EF022732",
    featureDesc: "Self-clamping",
    label: "EF022732: Self-clamping"
  },
  {
    featureId: "EF022739",
    featureDesc: "For deviating gutter construction",
    label: "EF022739: For deviating gutter construction"
  },
  {
    featureId: "EF022740",
    featureDesc: "Ring location",
    label: "EF022740: Ring location"
  },
  {
    featureId: "EF022741",
    featureDesc: "Inner diameter of ring",
    label: "EF022741: Inner diameter of ring"
  },
  {
    featureId: "EF022742",
    featureDesc: "Material foil",
    label: "EF022742: Material foil"
  },
  {
    featureId: "EF022743",
    featureDesc: "Thickness ring",
    label: "EF022743: Thickness ring"
  },
  {
    featureId: "EF022749",
    featureDesc: "Height, overflow brim",
    label: "EF022749: Height, overflow brim"
  },
  {
    featureId: "EF022750",
    featureDesc: "Type of outlet",
    label: "EF022750: Type of outlet"
  },
  {
    featureId: "EF022752",
    featureDesc: "Manually adjustable",
    label: "EF022752: Manually adjustable"
  },
  {
    featureId: "EF022753",
    featureDesc: "Servomotor, adjustable",
    label: "EF022753: Servomotor, adjustable"
  },
  {
    featureId: "EF022754",
    featureDesc: "Without plenum",
    label: "EF022754: Without plenum"
  },
  {
    featureId: "EF022755",
    featureDesc: "With saddle piece",
    label: "EF022755: With saddle piece"
  },
  {
    featureId: "EF022756",
    featureDesc: "Rosette diameter",
    label: "EF022756: Rosette diameter"
  },
  {
    featureId: "EF022757",
    featureDesc: "Ring for building in",
    label: "EF022757: Ring for building in"
  },
  {
    featureId: "EF022758",
    featureDesc: "With insect netting",
    label: "EF022758: With insect netting"
  },
  {
    featureId: "EF022760",
    featureDesc: "Motor-controlled",
    label: "EF022760: Motor-controlled"
  },
  {
    featureId: "EF022761",
    featureDesc: "With built-in window",
    label: "EF022761: With built-in window"
  },
  {
    featureId: "EF022764",
    featureDesc: "Front width",
    label: "EF022764: Front width"
  },
  {
    featureId: "EF022765",
    featureDesc: "Front diameter",
    label: "EF022765: Front diameter"
  },
  {
    featureId: "EF022766",
    featureDesc: "With outflow cone",
    label: "EF022766: With outflow cone"
  },
  {
    featureId: "EF022767",
    featureDesc: "With volume control",
    label: "EF022767: With volume control"
  },
  {
    featureId: "EF022768",
    featureDesc: "Front height",
    label: "EF022768: Front height"
  },
  {
    featureId: "EF022769",
    featureDesc: "With cover edge",
    label: "EF022769: With cover edge"
  },
  {
    featureId: "EF022770",
    featureDesc: "With filter cloth",
    label: "EF022770: With filter cloth"
  },
  {
    featureId: "EF022772",
    featureDesc: "Front length",
    label: "EF022772: Front length"
  },
  {
    featureId: "EF022773",
    featureDesc: "With end closure, left",
    label: "EF022773: With end closure, left"
  },
  {
    featureId: "EF022774",
    featureDesc: "With end closure, right",
    label: "EF022774: With end closure, right"
  },
  {
    featureId: "EF022775",
    featureDesc: "Length of plenum",
    label: "EF022775: Length of plenum"
  },
  {
    featureId: "EF022776",
    featureDesc: "Number of slits",
    label: "EF022776: Number of slits"
  },
  {
    featureId: "EF022777",
    featureDesc: "Volume setting",
    label: "EF022777: Volume setting"
  },
  {
    featureId: "EF022778",
    featureDesc: "Screw holes",
    label: "EF022778: Screw holes"
  },
  {
    featureId: "EF022779",
    featureDesc: "Vertical fins",
    label: "EF022779: Vertical fins"
  },
  {
    featureId: "EF022780",
    featureDesc: "Double row of ribs",
    label: "EF022780: Double row of ribs"
  },
  {
    featureId: "EF022781",
    featureDesc: "Recess width",
    label: "EF022781: Recess width"
  },
  {
    featureId: "EF022782",
    featureDesc: "Recess height",
    label: "EF022782: Recess height"
  },
  {
    featureId: "EF022783",
    featureDesc: "Fin shape",
    label: "EF022783: Fin shape"
  },
  {
    featureId: "EF022784",
    featureDesc: "Light-proof",
    label: "EF022784: Light-proof"
  },
  {
    featureId: "EF022785",
    featureDesc: "Separate heating battery",
    label: "EF022785: Separate heating battery"
  },
  {
    featureId: "EF022787",
    featureDesc: "Outer pipe diameter cooler",
    label: "EF022787: Outer pipe diameter cooler"
  },
  {
    featureId: "EF022788",
    featureDesc: "Connection, cooling system",
    label: "EF022788: Connection, cooling system"
  },
  {
    featureId: "EF022801",
    featureDesc: "Manufacturing method",
    label: "EF022801: Manufacturing method"
  },
  {
    featureId: "EF022802",
    featureDesc: "Material countersliding ring",
    label: "EF022802: Material countersliding ring"
  },
  {
    featureId: "EF022803",
    featureDesc: "Suitable for sliding ring",
    label: "EF022803: Suitable for sliding ring"
  },
  {
    featureId: "EF022804",
    featureDesc: "Suitable for countersliding ring",
    label: "EF022804: Suitable for countersliding ring"
  },
  {
    featureId: "EF022806",
    featureDesc: "Material, gasket 1",
    label: "EF022806: Material, gasket 1"
  },
  {
    featureId: "EF022807",
    featureDesc: "Quality class gasket 1",
    label: "EF022807: Quality class gasket 1"
  },
  {
    featureId: "EF022808",
    featureDesc: "Material, gasket 2",
    label: "EF022808: Material, gasket 2"
  },
  {
    featureId: "EF022809",
    featureDesc: "Quality class gasket 2",
    label: "EF022809: Quality class gasket 2"
  },
  {
    featureId: "EF022810",
    featureDesc: "Siliconised",
    label: "EF022810: Siliconised"
  },
  {
    featureId: "EF022811",
    featureDesc: "Metal-reinforced",
    label: "EF022811: Metal-reinforced"
  },
  {
    featureId: "EF022812",
    featureDesc: "Nominal gasket size",
    label: "EF022812: Nominal gasket size"
  },
  {
    featureId: "EF022815",
    featureDesc: "Ball-protected",
    label: "EF022815: Ball-protected"
  },
  {
    featureId: "EF022816",
    featureDesc: "Suitable for valve",
    label: "EF022816: Suitable for valve"
  },
  {
    featureId: "EF022817",
    featureDesc: "With cut",
    label: "EF022817: With cut"
  },
  {
    featureId: "EF022818",
    featureDesc: "Material code for sliding ring according to DIN 24960",
    label: "EF022818: Material code for sliding ring according to DIN 24960"
  },
  {
    featureId: "EF022820",
    featureDesc: "Outer diameter of sliding ring",
    label: "EF022820: Outer diameter of sliding ring"
  },
  {
    featureId: "EF022822",
    featureDesc: "Material flushing reservoir",
    label: "EF022822: Material flushing reservoir"
  },
  {
    featureId: "EF022823",
    featureDesc: "Flushing",
    label: "EF022823: Flushing"
  },
  {
    featureId: "EF022826",
    featureDesc: "Top inlet",
    label: "EF022826: Top inlet"
  },
  {
    featureId: "EF022827",
    featureDesc: "Rear inlet",
    label: "EF022827: Rear inlet"
  },
  {
    featureId: "EF022828",
    featureDesc: "Vane substructure",
    label: "EF022828: Vane substructure"
  },
  {
    featureId: "EF022829",
    featureDesc: "Vane superstructure",
    label: "EF022829: Vane superstructure"
  },
  {
    featureId: "EF022830",
    featureDesc: "Quality class trap",
    label: "EF022830: Quality class trap"
  },
  {
    featureId: "EF022831",
    featureDesc: "Built-in box height",
    label: "EF022831: Built-in box height"
  },
  {
    featureId: "EF022835",
    featureDesc: "Material structure",
    label: "EF022835: Material structure"
  },
  {
    featureId: "EF022836",
    featureDesc: "IRHD hardness",
    label: "EF022836: IRHD hardness"
  },
  {
    featureId: "EF022837",
    featureDesc: "Base width",
    label: "EF022837: Base width"
  },
  {
    featureId: "EF022838",
    featureDesc: "Base thickness",
    label: "EF022838: Base thickness"
  },
  {
    featureId: "EF022839",
    featureDesc: "Post thickness",
    label: "EF022839: Post thickness"
  },
  {
    featureId: "EF022840",
    featureDesc: "Flat bottom",
    label: "EF022840: Flat bottom"
  },
  {
    featureId: "EF022841",
    featureDesc: "Bottom thickness",
    label: "EF022841: Bottom thickness"
  },
  {
    featureId: "EF022843",
    featureDesc: "Bead height",
    label: "EF022843: Bead height"
  },
  {
    featureId: "EF022844",
    featureDesc: "Material reinforcement",
    label: "EF022844: Material reinforcement"
  },
  {
    featureId: "EF022845",
    featureDesc: "With sponge rubber seal",
    label: "EF022845: With sponge rubber seal"
  },
  {
    featureId: "EF022846",
    featureDesc: "Min. clamping range",
    label: "EF022846: Min. clamping range"
  },
  {
    featureId: "EF022847",
    featureDesc: "Max. clamping range",
    label: "EF022847: Max. clamping range"
  },
  {
    featureId: "EF022848",
    featureDesc: "Top width",
    label: "EF022848: Top width"
  },
  {
    featureId: "EF022849",
    featureDesc: "Tolerance class according to DIN 7715",
    label: "EF022849: Tolerance class according to DIN 7715"
  },
  {
    featureId: "EF022850",
    featureDesc: "With wedge",
    label: "EF022850: With wedge"
  },
  {
    featureId: "EF022851",
    featureDesc: "Rebate size 1",
    label: "EF022851: Rebate size 1"
  },
  {
    featureId: "EF022852",
    featureDesc: "Rebate size 2",
    label: "EF022852: Rebate size 2"
  },
  {
    featureId: "EF022853",
    featureDesc: "Distance between rebates",
    label: "EF022853: Distance between rebates"
  },
  {
    featureId: "EF022855",
    featureDesc: "Back shape",
    label: "EF022855: Back shape"
  },
  {
    featureId: "EF022859",
    featureDesc: "Antifriction finishing",
    label: "EF022859: Antifriction finishing"
  },
  {
    featureId: "EF022861",
    featureDesc: "Metal jacket",
    label: "EF022861: Metal jacket"
  },
  {
    featureId: "EF022862",
    featureDesc: "Retaining ring width",
    label: "EF022862: Retaining ring width"
  },
  {
    featureId: "EF022863",
    featureDesc: "Metal reinforcement",
    label: "EF022863: Metal reinforcement"
  },
  {
    featureId: "EF022864",
    featureDesc: "With dust lip",
    label: "EF022864: With dust lip"
  },
  {
    featureId: "EF022865",
    featureDesc: "Externally sealing",
    label: "EF022865: Externally sealing"
  },
  {
    featureId: "EF022866",
    featureDesc: "Fabric material",
    label: "EF022866: Fabric material"
  },
  {
    featureId: "EF022867",
    featureDesc: "Number of lips",
    label: "EF022867: Number of lips"
  },
  {
    featureId: "EF022869",
    featureDesc: "Hardening",
    label: "EF022869: Hardening"
  },
  {
    featureId: "EF022870",
    featureDesc: "Threaded seal",
    label: "EF022870: Threaded seal"
  },
  {
    featureId: "EF022871",
    featureDesc: "Flange seal",
    label: "EF022871: Flange seal"
  },
  {
    featureId: "EF022873",
    featureDesc: "Material code for secondary seal according to DIN 24960",
    label: "EF022873: Material code for secondary seal according to DIN 24960"
  },
  {
    featureId: "EF022874",
    featureDesc: "Composition of countersliding ring",
    label: "EF022874: Composition of countersliding ring"
  },
  {
    featureId: "EF022875",
    featureDesc: "Chamber diameter, secondary seal",
    label: "EF022875: Chamber diameter, secondary seal"
  },
  {
    featureId: "EF022876",
    featureDesc: "Material code for spring according to DIN 24960",
    label: "EF022876: Material code for spring according to DIN 24960"
  },
  {
    featureId: "EF022877",
    featureDesc: "Sinusoidal spring",
    label: "EF022877: Sinusoidal spring"
  },
  {
    featureId: "EF022878",
    featureDesc: "Direction of rotation left",
    label: "EF022878: Direction of rotation left"
  },
  {
    featureId: "EF022879",
    featureDesc: "Direction of rotation right",
    label: "EF022879: Direction of rotation right"
  },
  {
    featureId: "EF022880",
    featureDesc: "Material code for pressure ring according to DIN 24960",
    label: "EF022880: Material code for pressure ring according to DIN 24960"
  },
  {
    featureId: "EF022881",
    featureDesc: "Metal bellows",
    label: "EF022881: Metal bellows"
  },
  {
    featureId: "EF022882",
    featureDesc: "Pressure-relieved seal",
    label: "EF022882: Pressure-relieved seal"
  },
  {
    featureId: "EF022883",
    featureDesc: "Composition of sliding ring",
    label: "EF022883: Composition of sliding ring"
  },
  {
    featureId: "EF022884",
    featureDesc: "Outer diameter, rotor",
    label: "EF022884: Outer diameter, rotor"
  },
  {
    featureId: "EF022885",
    featureDesc: "Spring type",
    label: "EF022885: Spring type"
  },
  {
    featureId: "EF022886",
    featureDesc: "Material code for countersliding ring according to DIN 24960",
    label: "EF022886: Material code for countersliding ring according to DIN 24960"
  },
  {
    featureId: "EF022887",
    featureDesc: "Built-in length, unmounted",
    label: "EF022887: Built-in length, unmounted"
  },
  {
    featureId: "EF022888",
    featureDesc: "Built-in length, mounted",
    label: "EF022888: Built-in length, mounted"
  },
  {
    featureId: "EF022889",
    featureDesc: "Self-adjusting",
    label: "EF022889: Self-adjusting"
  },
  {
    featureId: "EF022890",
    featureDesc: "Min. built-in diameter",
    label: "EF022890: Min. built-in diameter"
  },
  {
    featureId: "EF022891",
    featureDesc: "Max. built-in diameter",
    label: "EF022891: Max. built-in diameter"
  },
  {
    featureId: "EF022892",
    featureDesc: "Min. pitch circle of flange",
    label: "EF022892: Min. pitch circle of flange"
  },
  {
    featureId: "EF022893",
    featureDesc: "Material of sliding ring on medium side",
    label: "EF022893: Material of sliding ring on medium side"
  },
  {
    featureId: "EF022894",
    featureDesc: "Material of sliding ring on medium side according to DIN 24960",
    label: "EF022894: Material of sliding ring on medium side according to DIN 24960"
  },
  {
    featureId: "EF022895",
    featureDesc: "Material of secondary seal on medium side",
    label: "EF022895: Material of secondary seal on medium side"
  },
  {
    featureId: "EF022896",
    featureDesc: "Material of secondary seal on medium side according to DIN 24960",
    label: "EF022896: Material of secondary seal on medium side according to DIN 24960"
  },
  {
    featureId: "EF022897",
    featureDesc: "Material of spring on medium side",
    label: "EF022897: Material of spring on medium side"
  },
  {
    featureId: "EF022898",
    featureDesc: "Material of spring on medium side according to DIN 24960",
    label: "EF022898: Material of spring on medium side according to DIN 24960"
  },
  {
    featureId: "EF022901",
    featureDesc: "Material of countersliding ring on medium side",
    label: "EF022901: Material of countersliding ring on medium side"
  },
  {
    featureId: "EF022902",
    featureDesc: "Material of countersliding ring on medium side DIN 24960",
    label: "EF022902: Material of countersliding ring on medium side DIN 24960"
  },
  {
    featureId: "EF022903",
    featureDesc: "Material of sliding ring, external",
    label: "EF022903: Material of sliding ring, external"
  },
  {
    featureId: "EF022904",
    featureDesc: "Material of sliding ring, external, according to DIN 24960",
    label: "EF022904: Material of sliding ring, external, according to DIN 24960"
  },
  {
    featureId: "EF022905",
    featureDesc: "Material of secondary seal, external",
    label: "EF022905: Material of secondary seal, external"
  },
  {
    featureId: "EF022906",
    featureDesc: "Material of secondary seal, external, according to DIN 24960",
    label: "EF022906: Material of secondary seal, external, according to DIN 24960"
  },
  {
    featureId: "EF022907",
    featureDesc: "Material of spring, external",
    label: "EF022907: Material of spring, external"
  },
  {
    featureId: "EF022908",
    featureDesc: "Material code for spring external according to DIN 24960",
    label: "EF022908: Material code for spring external according to DIN 24960"
  },
  {
    featureId: "EF022911",
    featureDesc: "Material of countersliding ring, external",
    label: "EF022911: Material of countersliding ring, external"
  },
  {
    featureId: "EF022912",
    featureDesc: "Material of countersliding ring, external, according to DIN 24690",
    label: "EF022912: Material of countersliding ring, external, according to DIN 24690"
  },
  {
    featureId: "EF022913",
    featureDesc: "Spindle diameter",
    label: "EF022913: Spindle diameter"
  },
  {
    featureId: "EF022914",
    featureDesc: "Set height",
    label: "EF022914: Set height"
  },
  {
    featureId: "EF022915",
    featureDesc: "Fire safe BS 6755",
    label: "EF022915: Fire safe BS 6755"
  },
  {
    featureId: "EF022916",
    featureDesc: "Fire safe API 607",
    label: "EF022916: Fire safe API 607"
  },
  {
    featureId: "EF022917",
    featureDesc: "Fire safe API 589",
    label: "EF022917: Fire safe API 589"
  },
  {
    featureId: "EF022918",
    featureDesc: "UHF-expanded",
    label: "EF022918: UHF-expanded"
  },
  {
    featureId: "EF022920",
    featureDesc: "Roller length",
    label: "EF022920: Roller length"
  },
  {
    featureId: "EF022921",
    featureDesc: "Finishing",
    label: "EF022921: Finishing"
  },
  {
    featureId: "EF022922",
    featureDesc: "Top connection on plenum",
    label: "EF022922: Top connection on plenum"
  },
  {
    featureId: "EF022923",
    featureDesc: "Nominal duct diameter connection",
    label: "EF022923: Nominal duct diameter connection"
  },
  {
    featureId: "EF022925",
    featureDesc: "Effective length of inlet piece",
    label: "EF022925: Effective length of inlet piece"
  },
  {
    featureId: "EF022926",
    featureDesc: "Sloping",
    label: "EF022926: Sloping"
  },
  {
    featureId: "EF022927",
    featureDesc: "With care opening",
    label: "EF022927: With care opening"
  },
  {
    featureId: "EF022928",
    featureDesc: "Nominal approach flow rate",
    label: "EF022928: Nominal approach flow rate"
  },
  {
    featureId: "EF022929",
    featureDesc: "Initial pressure difference",
    label: "EF022929: Initial pressure difference"
  },
  {
    featureId: "EF022931",
    featureDesc: "Efficiency",
    label: "EF022931: Efficiency"
  },
  {
    featureId: "EF022932",
    featureDesc: "Filter framing",
    label: "EF022932: Filter framing"
  },
  {
    featureId: "EF022933",
    featureDesc: "Protective plate",
    label: "EF022933: Protective plate"
  },
  {
    featureId: "EF022934",
    featureDesc: "Primary component",
    label: "EF022934: Primary component"
  },
  {
    featureId: "EF022936",
    featureDesc: "Full-face",
    label: "EF022936: Full-face"
  },
  {
    featureId: "EF022937",
    featureDesc: "Secondary component",
    label: "EF022937: Secondary component"
  },
  {
    featureId: "EF022938",
    featureDesc: "Material binder",
    label: "EF022938: Material binder"
  },
  {
    featureId: "EF022939",
    featureDesc: "Flange compression stage (PN)",
    label: "EF022939: Flange compression stage (PN)"
  },
  {
    featureId: "EF022940",
    featureDesc: "Nominal diameter of flange",
    label: "EF022940: Nominal diameter of flange"
  },
  {
    featureId: "EF022941",
    featureDesc: "Pressure class",
    label: "EF022941: Pressure class"
  },
  {
    featureId: "EF022942",
    featureDesc: "Ground",
    label: "EF022942: Ground"
  },
  {
    featureId: "EF022944",
    featureDesc: "Shore hardness",
    label: "EF022944: Shore hardness"
  },
  {
    featureId: "EF022945",
    featureDesc: "Graphite purity",
    label: "EF022945: Graphite purity"
  },
  {
    featureId: "EF022946",
    featureDesc: "Glued",
    label: "EF022946: Glued"
  },
  {
    featureId: "EF022947",
    featureDesc: "Material top layer",
    label: "EF022947: Material top layer"
  },
  {
    featureId: "EF022948",
    featureDesc: "Pre-treated for gluing",
    label: "EF022948: Pre-treated for gluing"
  },
  {
    featureId: "EF022949",
    featureDesc: "Covering",
    label: "EF022949: Covering"
  },
  {
    featureId: "EF022950",
    featureDesc: "Non-printed",
    label: "EF022950: Non-printed"
  },
  {
    featureId: "EF022951",
    featureDesc: "Can be glued",
    label: "EF022951: Can be glued"
  },
  {
    featureId: "EF022952",
    featureDesc: "Dimensions according to",
    label: "EF022952: Dimensions according to"
  },
  {
    featureId: "EF022953",
    featureDesc: "Material envelope",
    label: "EF022953: Material envelope"
  },
  {
    featureId: "EF022954",
    featureDesc: "With waved stainless steel support",
    label: "EF022954: With waved stainless steel support"
  },
  {
    featureId: "EF022955",
    featureDesc: "Diffusion-proof",
    label: "EF022955: Diffusion-proof"
  },
  {
    featureId: "EF022956",
    featureDesc: "Secondary seal",
    label: "EF022956: Secondary seal"
  },
  {
    featureId: "EF022957",
    featureDesc: "Support ring set",
    label: "EF022957: Support ring set"
  },
  {
    featureId: "EF022958",
    featureDesc: "Material insulating ring",
    label: "EF022958: Material insulating ring"
  },
  {
    featureId: "EF022959",
    featureDesc: "Bush material",
    label: "EF022959: Bush material"
  },
  {
    featureId: "EF022960",
    featureDesc: "Material cog",
    label: "EF022960: Material cog"
  },
  {
    featureId: "EF022961",
    featureDesc: "Material centring ring",
    label: "EF022961: Material centring ring"
  },
  {
    featureId: "EF022962",
    featureDesc: "Graphite density",
    label: "EF022962: Graphite density"
  },
  {
    featureId: "EF022963",
    featureDesc: "Cog shape",
    label: "EF022963: Cog shape"
  },
  {
    featureId: "EF022964",
    featureDesc: "Fixed centring ring",
    label: "EF022964: Fixed centring ring"
  },
  {
    featureId: "EF022965",
    featureDesc: "Inner diameter of cog",
    label: "EF022965: Inner diameter of cog"
  },
  {
    featureId: "EF022966",
    featureDesc: "Outer diameter of cog",
    label: "EF022966: Outer diameter of cog"
  },
  {
    featureId: "EF022968",
    featureDesc: "Cog thickness",
    label: "EF022968: Cog thickness"
  },
  {
    featureId: "EF022969",
    featureDesc: "Inlay thickness",
    label: "EF022969: Inlay thickness"
  },
  {
    featureId: "EF022970",
    featureDesc: "Inner length",
    label: "EF022970: Inner length"
  },
  {
    featureId: "EF022971",
    featureDesc: "Gasket width",
    label: "EF022971: Gasket width"
  },
  {
    featureId: "EF022972",
    featureDesc: "Material seating",
    label: "EF022972: Material seating"
  },
  {
    featureId: "EF022973",
    featureDesc: "Seating thickness",
    label: "EF022973: Seating thickness"
  },
  {
    featureId: "EF022974",
    featureDesc: "Inner diameter inner ring",
    label: "EF022974: Inner diameter inner ring"
  },
  {
    featureId: "EF022975",
    featureDesc: "Spiral ring thickness",
    label: "EF022975: Spiral ring thickness"
  },
  {
    featureId: "EF022976",
    featureDesc: "Outer ring thickness",
    label: "EF022976: Outer ring thickness"
  },
  {
    featureId: "EF022977",
    featureDesc: "Inner ring thickness",
    label: "EF022977: Inner ring thickness"
  },
  {
    featureId: "EF022978",
    featureDesc: "Ring type",
    label: "EF022978: Ring type"
  },
  {
    featureId: "EF022979",
    featureDesc: "Ring number",
    label: "EF022979: Ring number"
  },
  {
    featureId: "EF022980",
    featureDesc: "Flange compression stage (ANSI)",
    label: "EF022980: Flange compression stage (ANSI)"
  },
  {
    featureId: "EF022981",
    featureDesc: "Flange compression stage (API)",
    label: "EF022981: Flange compression stage (API)"
  },
  {
    featureId: "EF022982",
    featureDesc: "Nominal diameter (ANSI)",
    label: "EF022982: Nominal diameter (ANSI)"
  },
  {
    featureId: "EF022983",
    featureDesc: "Nominal diameter (API)",
    label: "EF022983: Nominal diameter (API)"
  },
  {
    featureId: "EF022984",
    featureDesc: "Hardness (HB)",
    label: "EF022984: Hardness (HB)"
  },
  {
    featureId: "EF022985",
    featureDesc: "Trough height",
    label: "EF022985: Trough height"
  },
  {
    featureId: "EF022986",
    featureDesc: "Clamping flange",
    label: "EF022986: Clamping flange"
  },
  {
    featureId: "EF022987",
    featureDesc: "With dirt trap",
    label: "EF022987: With dirt trap"
  },
  {
    featureId: "EF022988",
    featureDesc: "Grille front",
    label: "EF022988: Grille front"
  },
  {
    featureId: "EF022989",
    featureDesc: "Recess diameter",
    label: "EF022989: Recess diameter"
  },
  {
    featureId: "EF022990",
    featureDesc: "In cartridge for roller belt air filter unit",
    label: "EF022990: In cartridge for roller belt air filter unit"
  },
  {
    featureId: "EF022991",
    featureDesc: "With pressure difference gauge",
    label: "EF022991: With pressure difference gauge"
  },
  {
    featureId: "EF022992",
    featureDesc: "With contact pressure difference gauge",
    label: "EF022992: With contact pressure difference gauge"
  },
  {
    featureId: "EF022993",
    featureDesc: "With two contact pressure differences gauges",
    label: "EF022993: With two contact pressure differences gauges"
  },
  {
    featureId: "EF022994",
    featureDesc: "Intermediate mounting",
    label: "EF022994: Intermediate mounting"
  },
  {
    featureId: "EF022995",
    featureDesc: "Unit width",
    label: "EF022995: Unit width"
  },
  {
    featureId: "EF022996",
    featureDesc: "Unit height",
    label: "EF022996: Unit height"
  },
  {
    featureId: "EF022997",
    featureDesc: "Unit depth",
    label: "EF022997: Unit depth"
  },
  {
    featureId: "EF022998",
    featureDesc: "Centring ring",
    label: "EF022998: Centring ring"
  },
  {
    featureId: "EF022999",
    featureDesc: "Pipe schedule",
    label: "EF022999: Pipe schedule"
  },
  {
    featureId: "EF023000",
    featureDesc: "Ring model",
    label: "EF023000: Ring model"
  },
  {
    featureId: "EF023001",
    featureDesc: "With flanged rim",
    label: "EF023001: With flanged rim"
  },
  {
    featureId: "EF023002",
    featureDesc: "With tension strip",
    label: "EF023002: With tension strip"
  },
  {
    featureId: "EF023003",
    featureDesc: "Duplex",
    label: "EF023003: Duplex"
  },
  {
    featureId: "EF023004",
    featureDesc: "Roller size",
    label: "EF023004: Roller size"
  },
  {
    featureId: "EF023005",
    featureDesc: "Chain guide",
    label: "EF023005: Chain guide"
  },
  {
    featureId: "EF023006",
    featureDesc: "Tensioning travel",
    label: "EF023006: Tensioning travel"
  },
  {
    featureId: "EF023008",
    featureDesc: "Roll width",
    label: "EF023008: Roll width"
  },
  {
    featureId: "EF023009",
    featureDesc: "Wheel model",
    label: "EF023009: Wheel model"
  },
  {
    featureId: "EF023010",
    featureDesc: "Material tread",
    label: "EF023010: Material tread"
  },
  {
    featureId: "EF023011",
    featureDesc: "Hub length",
    label: "EF023011: Hub length"
  },
  {
    featureId: "EF023012",
    featureDesc: "Bearing",
    label: "EF023012: Bearing"
  },
  {
    featureId: "EF023014",
    featureDesc: "Centric bead",
    label: "EF023014: Centric bead"
  },
  {
    featureId: "EF023015",
    featureDesc: "Bead wall thickness",
    label: "EF023015: Bead wall thickness"
  },
  {
    featureId: "EF023016",
    featureDesc: "Bead shape",
    label: "EF023016: Bead shape"
  },
  {
    featureId: "EF023017",
    featureDesc: "Mounting width 1",
    label: "EF023017: Mounting width 1"
  },
  {
    featureId: "EF023018",
    featureDesc: "Mounting width 2",
    label: "EF023018: Mounting width 2"
  },
  {
    featureId: "EF023019",
    featureDesc: "Operates externally",
    label: "EF023019: Operates externally"
  },
  {
    featureId: "EF023021",
    featureDesc: "Groove length",
    label: "EF023021: Groove length"
  },
  {
    featureId: "EF023022",
    featureDesc: "Solid",
    label: "EF023022: Solid"
  },
  {
    featureId: "EF023023",
    featureDesc: "Seal according to standard",
    label: "EF023023: Seal according to standard"
  },
  {
    featureId: "EF023024",
    featureDesc: "Drain",
    label: "EF023024: Drain"
  },
  {
    featureId: "EF023025",
    featureDesc: "Sliding ring according to standard",
    label: "EF023025: Sliding ring according to standard"
  },
  {
    featureId: "EF023026",
    featureDesc: "Secondary seal according to standard",
    label: "EF023026: Secondary seal according to standard"
  },
  {
    featureId: "EF023027",
    featureDesc: "Rotor according to standard",
    label: "EF023027: Rotor according to standard"
  },
  {
    featureId: "EF023028",
    featureDesc: "Stator according to standard",
    label: "EF023028: Stator according to standard"
  },
  {
    featureId: "EF023029",
    featureDesc: "Suitable for rotation",
    label: "EF023029: Suitable for rotation"
  },
  {
    featureId: "EF023030",
    featureDesc: "Suitable for reciprocating motion",
    label: "EF023030: Suitable for reciprocating motion"
  },
  {
    featureId: "EF023031",
    featureDesc: "Countersliding ring according to standard",
    label: "EF023031: Countersliding ring according to standard"
  },
  {
    featureId: "EF023032",
    featureDesc: "Core colour",
    label: "EF023032: Core colour"
  },
  {
    featureId: "EF023037",
    featureDesc: "Max. electric power input",
    label: "EF023037: Max. electric power input"
  },
  {
    featureId: "EF023038",
    featureDesc: "Material pump casing",
    label: "EF023038: Material pump casing"
  },
  {
    featureId: "EF023051",
    featureDesc: "Shower operation",
    label: "EF023051: Shower operation"
  },
  {
    featureId: "EF023061",
    featureDesc: "Min. pressure",
    label: "EF023061: Min. pressure"
  },
  {
    featureId: "EF023062",
    featureDesc: "With protective/marking stops",
    label: "EF023062: With protective/marking stops"
  },
  {
    featureId: "EF023063",
    featureDesc: "Height of drainage bend adjustable",
    label: "EF023063: Height of drainage bend adjustable"
  },
  {
    featureId: "EF023064",
    featureDesc: "Centre distance of bidet attachment",
    label: "EF023064: Centre distance of bidet attachment"
  },
  {
    featureId: "EF023066",
    featureDesc: "Fork model",
    label: "EF023066: Fork model"
  },
  {
    featureId: "EF023067",
    featureDesc: "Material fork",
    label: "EF023067: Material fork"
  },
  {
    featureId: "EF023068",
    featureDesc: "Sprung",
    label: "EF023068: Sprung"
  },
  {
    featureId: "EF023069",
    featureDesc: "Wheel diameter",
    label: "EF023069: Wheel diameter"
  },
  {
    featureId: "EF023070",
    featureDesc: "Wheel rim diameter",
    label: "EF023070: Wheel rim diameter"
  },
  {
    featureId: "EF023071",
    featureDesc: "Type of tyre",
    label: "EF023071: Type of tyre"
  },
  {
    featureId: "EF023072",
    featureDesc: "Chain position",
    label: "EF023072: Chain position"
  },
  {
    featureId: "EF023073",
    featureDesc: "Double simplex chain",
    label: "EF023073: Double simplex chain"
  },
  {
    featureId: "EF023074",
    featureDesc: "Enclosed chain",
    label: "EF023074: Enclosed chain"
  },
  {
    featureId: "EF023075",
    featureDesc: "Mounting profile",
    label: "EF023075: Mounting profile"
  },
  {
    featureId: "EF023076",
    featureDesc: "Material mounting profile",
    label: "EF023076: Material mounting profile"
  },
  {
    featureId: "EF023078",
    featureDesc: "Belt number according to DIN 2215",
    label: "EF023078: Belt number according to DIN 2215"
  },
  {
    featureId: "EF023079",
    featureDesc: "Tread width",
    label: "EF023079: Tread width"
  },
  {
    featureId: "EF023081",
    featureDesc: "Min. shelf height",
    label: "EF023081: Min. shelf height"
  },
  {
    featureId: "EF023084",
    featureDesc: "Frame suitable for wc with offset >60cm",
    label: "EF023084: Frame suitable for wc with offset >60cm"
  },
  {
    featureId: "EF023085",
    featureDesc: "Spacer under reservoir",
    label: "EF023085: Spacer under reservoir"
  },
  {
    featureId: "EF023086",
    featureDesc: "Pump height",
    label: "EF023086: Pump height"
  },
  {
    featureId: "EF023089",
    featureDesc: "Pump circuit",
    label: "EF023089: Pump circuit"
  },
  {
    featureId: "EF023090",
    featureDesc: "Double pump",
    label: "EF023090: Double pump"
  },
  {
    featureId: "EF023092",
    featureDesc: "Insulation class according to IEC",
    label: "EF023092: Insulation class according to IEC"
  },
  {
    featureId: "EF023093",
    featureDesc: "Motor with extended shaft",
    label: "EF023093: Motor with extended shaft"
  },
  {
    featureId: "EF023094",
    featureDesc: "Height of suction end",
    label: "EF023094: Height of suction end"
  },
  {
    featureId: "EF023095",
    featureDesc: "Height of pressure end",
    label: "EF023095: Height of pressure end"
  },
  {
    featureId: "EF023096",
    featureDesc: "Pump length",
    label: "EF023096: Pump length"
  },
  {
    featureId: "EF023097",
    featureDesc: "Foundation plate length",
    label: "EF023097: Foundation plate length"
  },
  {
    featureId: "EF023098",
    featureDesc: "Foundation plate width",
    label: "EF023098: Foundation plate width"
  },
  {
    featureId: "EF023099",
    featureDesc: "Length of centre pressure/suction connection",
    label: "EF023099: Length of centre pressure/suction connection"
  },
  {
    featureId: "EF023102",
    featureDesc: "Centre size of pressure flange",
    label: "EF023102: Centre size of pressure flange"
  },
  {
    featureId: "EF023105",
    featureDesc: "Max. dimension of fixed parts",
    label: "EF023105: Max. dimension of fixed parts"
  },
  {
    featureId: "EF023106",
    featureDesc: "Storz coupling",
    label: "EF023106: Storz coupling"
  },
  {
    featureId: "EF023107",
    featureDesc: "DSP coupling",
    label: "EF023107: DSP coupling"
  },
  {
    featureId: "EF023108",
    featureDesc: "Nominal DSP size",
    label: "EF023108: Nominal DSP size"
  },
  {
    featureId: "EF023109",
    featureDesc: "Material of inner spiral",
    label: "EF023109: Material of inner spiral"
  },
  {
    featureId: "EF023111",
    featureDesc: "Material of inner foil",
    label: "EF023111: Material of inner foil"
  },
  {
    featureId: "EF023112",
    featureDesc: "Material outer foil",
    label: "EF023112: Material outer foil"
  },
  {
    featureId: "EF023113",
    featureDesc: "Material of outer spiral",
    label: "EF023113: Material of outer spiral"
  },
  {
    featureId: "EF023114",
    featureDesc: "Bending radius",
    label: "EF023114: Bending radius"
  },
  {
    featureId: "EF023115",
    featureDesc: "Material of braiding",
    label: "EF023115: Material of braiding"
  },
  {
    featureId: "EF023116",
    featureDesc: "Max. operating pressure at 20 °C",
    label: "EF023116: Max. operating pressure at 20 °C"
  },
  {
    featureId: "EF023117",
    featureDesc: "Static bending radius at 20 °C",
    label: "EF023117: Static bending radius at 20 °C"
  },
  {
    featureId: "EF023118",
    featureDesc: "Dynamic bending radius at 20 °C",
    label: "EF023118: Dynamic bending radius at 20 °C"
  },
  {
    featureId: "EF023119",
    featureDesc: "Material of inner wall",
    label: "EF023119: Material of inner wall"
  },
  {
    featureId: "EF023120",
    featureDesc: "Material of outer wall",
    label: "EF023120: Material of outer wall"
  },
  {
    featureId: "EF023121",
    featureDesc: "Plastic lining",
    label: "EF023121: Plastic lining"
  },
  {
    featureId: "EF023122",
    featureDesc: "Shape of inner wall",
    label: "EF023122: Shape of inner wall"
  },
  {
    featureId: "EF023123",
    featureDesc: "Antistatic",
    label: "EF023123: Antistatic"
  },
  {
    featureId: "EF023125",
    featureDesc: "Suitable for foods",
    label: "EF023125: Suitable for foods"
  },
  {
    featureId: "EF023126",
    featureDesc: "Inner wall colour",
    label: "EF023126: Inner wall colour"
  },
  {
    featureId: "EF023128",
    featureDesc: "Static wire",
    label: "EF023128: Static wire"
  },
  {
    featureId: "EF023131",
    featureDesc: "Coupling size",
    label: "EF023131: Coupling size"
  },
  {
    featureId: "EF023133",
    featureDesc: "With locking device",
    label: "EF023133: With locking device"
  },
  {
    featureId: "EF023134",
    featureDesc: "For hose diameter",
    label: "EF023134: For hose diameter"
  },
  {
    featureId: "EF023135",
    featureDesc: "Suction coupling",
    label: "EF023135: Suction coupling"
  },
  {
    featureId: "EF023136",
    featureDesc: "Rotating storz head",
    label: "EF023136: Rotating storz head"
  },
  {
    featureId: "EF023137",
    featureDesc: "Metal-sealing",
    label: "EF023137: Metal-sealing"
  },
  {
    featureId: "EF023138",
    featureDesc: "Length of hose connector",
    label: "EF023138: Length of hose connector"
  },
  {
    featureId: "EF023140",
    featureDesc: "Material pump (control element)",
    label: "EF023140: Material pump (control element)"
  },
  {
    featureId: "EF023142",
    featureDesc: "Material fittings",
    label: "EF023142: Material fittings"
  },
  {
    featureId: "EF023143",
    featureDesc: "Material quality pump casing",
    label: "EF023143: Material quality pump casing"
  },
  {
    featureId: "EF023144",
    featureDesc: "Quality class fittings",
    label: "EF023144: Quality class fittings"
  },
  {
    featureId: "EF023148",
    featureDesc: "Number of pumps",
    label: "EF023148: Number of pumps"
  },
  {
    featureId: "EF023149",
    featureDesc: "Suction installation",
    label: "EF023149: Suction installation"
  },
  {
    featureId: "EF023154",
    featureDesc: "With bypass pipe",
    label: "EF023154: With bypass pipe"
  },
  {
    featureId: "EF023157",
    featureDesc: "Suitable for dry set-up",
    label: "EF023157: Suitable for dry set-up"
  },
  {
    featureId: "EF023158",
    featureDesc: "Set-up behind toilet bowl",
    label: "EF023158: Set-up behind toilet bowl"
  },
  {
    featureId: "EF023159",
    featureDesc: "Centre distance of screw threads",
    label: "EF023159: Centre distance of screw threads"
  },
  {
    featureId: "EF023161",
    featureDesc: "Flange width",
    label: "EF023161: Flange width"
  },
  {
    featureId: "EF023163",
    featureDesc: "Height",
    label: "EF023163: Height"
  },
  {
    featureId: "EF023164",
    featureDesc: "Height including legs",
    label: "EF023164: Height including legs"
  },
  {
    featureId: "EF023166",
    featureDesc: "Rail height",
    label: "EF023166: Rail height"
  },
  {
    featureId: "EF023170",
    featureDesc: "Inner diameter",
    label: "EF023170: Inner diameter"
  },
  {
    featureId: "EF023171",
    featureDesc: "Drive",
    label: "EF023171: Drive"
  },
  {
    featureId: "EF023172",
    featureDesc: "Lifting capacity",
    label: "EF023172: Lifting capacity"
  },
  {
    featureId: "EF023174",
    featureDesc: "Max. lifting height",
    label: "EF023174: Max. lifting height"
  },
  {
    featureId: "EF023176",
    featureDesc: "Gravel tray",
    label: "EF023176: Gravel tray"
  },
  {
    featureId: "EF023177",
    featureDesc: "K-factor, nominal bore",
    label: "EF023177: K-factor, nominal bore"
  },
  {
    featureId: "EF023178",
    featureDesc: "Coupling size",
    label: "EF023178: Coupling size"
  },
  {
    featureId: "EF023180",
    featureDesc: "Discharge head",
    label: "EF023180: Discharge head"
  },
  {
    featureId: "EF023181",
    featureDesc: "Length",
    label: "EF023181: Length"
  },
  {
    featureId: "EF023182",
    featureDesc: "Medium temperature (continuous)",
    label: "EF023182: Medium temperature (continuous)"
  },
  {
    featureId: "EF023184",
    featureDesc: "With metal jacket",
    label: "EF023184: With metal jacket"
  },
  {
    featureId: "EF023185",
    featureDesc: "With metal reinforcement",
    label: "EF023185: With metal reinforcement"
  },
  {
    featureId: "EF023186",
    featureDesc: "With mounting profile",
    label: "EF023186: With mounting profile"
  },
  {
    featureId: "EF023187",
    featureDesc: "With reheater",
    label: "EF023187: With reheater"
  },
  {
    featureId: "EF023188",
    featureDesc: "Lug distance",
    label: "EF023188: Lug distance"
  },
  {
    featureId: "EF023189",
    featureDesc: "Nominal flow rate",
    label: "EF023189: Nominal flow rate"
  },
  {
    featureId: "EF023191",
    featureDesc: "Nominal diameter",
    label: "EF023191: Nominal diameter"
  },
  {
    featureId: "EF023193",
    featureDesc: "Nominal diameter connection 1",
    label: "EF023193: Nominal diameter connection 1"
  },
  {
    featureId: "EF023195",
    featureDesc: "Nominal duct diameter",
    label: "EF023195: Nominal duct diameter"
  },
  {
    featureId: "EF023219",
    featureDesc: "Nominal duct diameter, connection",
    label: "EF023219: Nominal duct diameter, connection"
  },
  {
    featureId: "EF023359",
    featureDesc: "Door opening direction",
    label: "EF023359: Door opening direction"
  },
  {
    featureId: "EF023416",
    featureDesc: "Drum thread connection",
    label: "EF023416: Drum thread connection"
  },
  {
    featureId: "EF023418",
    featureDesc: "Outer hose diameter 1",
    label: "EF023418: Outer hose diameter 1"
  },
  {
    featureId: "EF023419",
    featureDesc: "Outer hose diameter 2",
    label: "EF023419: Outer hose diameter 2"
  },
  {
    featureId: "EF023420",
    featureDesc: "Outer hose diameter 3",
    label: "EF023420: Outer hose diameter 3"
  },
  {
    featureId: "EF023421",
    featureDesc: "Outer hose diameter",
    label: "EF023421: Outer hose diameter"
  },
  {
    featureId: "EF023425",
    featureDesc: "Nominal bore of coupling",
    label: "EF023425: Nominal bore of coupling"
  },
  {
    featureId: "EF023426",
    featureDesc: "Max. working length",
    label: "EF023426: Max. working length"
  },
  {
    featureId: "EF023427",
    featureDesc: "For inner diameter hose",
    label: "EF023427: For inner diameter hose"
  },
  {
    featureId: "EF023428",
    featureDesc: "Roll-up mechanism",
    label: "EF023428: Roll-up mechanism"
  },
  {
    featureId: "EF023429",
    featureDesc: "Switches via fire alarm",
    label: "EF023429: Switches via fire alarm"
  },
  {
    featureId: "EF023430",
    featureDesc: "Inlet threaded connection",
    label: "EF023430: Inlet threaded connection"
  },
  {
    featureId: "EF023432",
    featureDesc: "With self-closing valve",
    label: "EF023432: With self-closing valve"
  },
  {
    featureId: "EF023433",
    featureDesc: "Without connection cap",
    label: "EF023433: Without connection cap"
  },
  {
    featureId: "EF023434",
    featureDesc: "Suitable for absolute filter",
    label: "EF023434: Suitable for absolute filter"
  },
  {
    featureId: "EF023435",
    featureDesc: "With prefilter section",
    label: "EF023435: With prefilter section"
  },
  {
    featureId: "EF023436",
    featureDesc: "With carbon filter section",
    label: "EF023436: With carbon filter section"
  },
  {
    featureId: "EF023437",
    featureDesc: "With filter exchange edge",
    label: "EF023437: With filter exchange edge"
  },
  {
    featureId: "EF023438",
    featureDesc: "With seal inspection facility",
    label: "EF023438: With seal inspection facility"
  },
  {
    featureId: "EF023439",
    featureDesc: "Welding stud",
    label: "EF023439: Welding stud"
  },
  {
    featureId: "EF023440",
    featureDesc: "With hose",
    label: "EF023440: With hose"
  },
  {
    featureId: "EF023441",
    featureDesc: "With support frame",
    label: "EF023441: With support frame"
  },
  {
    featureId: "EF023442",
    featureDesc: "Air direction from left to right",
    label: "EF023442: Air direction from left to right"
  },
  {
    featureId: "EF023443",
    featureDesc: "Lug distance 1",
    label: "EF023443: Lug distance 1"
  },
  {
    featureId: "EF023444",
    featureDesc: "Lug distance 2",
    label: "EF023444: Lug distance 2"
  },
  {
    featureId: "EF023445",
    featureDesc: "For lug distance",
    label: "EF023445: For lug distance"
  },
  {
    featureId: "EF023448",
    featureDesc: "Upward air direction",
    label: "EF023448: Upward air direction"
  },
  {
    featureId: "EF023449",
    featureDesc: "Stroke volume",
    label: "EF023449: Stroke volume"
  },
  {
    featureId: "EF023450",
    featureDesc: "Length of legs",
    label: "EF023450: Length of legs"
  },
  {
    featureId: "EF023452",
    featureDesc: "Size of threaded connection (inch)",
    label: "EF023452: Size of threaded connection (inch)"
  },
  {
    featureId: "EF023453",
    featureDesc: "Max. hose length",
    label: "EF023453: Max. hose length"
  },
  {
    featureId: "EF023455",
    featureDesc: "Plate shape",
    label: "EF023455: Plate shape"
  },
  {
    featureId: "EF023456",
    featureDesc: "Hose width",
    label: "EF023456: Hose width"
  },
  {
    featureId: "EF023457",
    featureDesc: "Number of legs",
    label: "EF023457: Number of legs"
  },
  {
    featureId: "EF023458",
    featureDesc: "Max. number of strokes per minute",
    label: "EF023458: Max. number of strokes per minute"
  },
  {
    featureId: "EF023459",
    featureDesc: "Type of non-return valve",
    label: "EF023459: Type of non-return valve"
  },
  {
    featureId: "EF023460",
    featureDesc: "Adjustable stroke length",
    label: "EF023460: Adjustable stroke length"
  },
  {
    featureId: "EF023464",
    featureDesc: "Suitable for building above 70 metres high",
    label: "EF023464: Suitable for building above 70 metres high"
  },
  {
    featureId: "EF023465",
    featureDesc: "Number of ears",
    label: "EF023465: Number of ears"
  },
  {
    featureId: "EF023466",
    featureDesc: "Inner diameter 1",
    label: "EF023466: Inner diameter 1"
  },
  {
    featureId: "EF023467",
    featureDesc: "Inlet 1 thread connection",
    label: "EF023467: Inlet 1 thread connection"
  },
  {
    featureId: "EF023468",
    featureDesc: "Inlet 2 thread connection",
    label: "EF023468: Inlet 2 thread connection"
  },
  {
    featureId: "EF023470",
    featureDesc: "Size of threaded connection",
    label: "EF023470: Size of threaded connection"
  },
  {
    featureId: "EF023473",
    featureDesc: "Inner diameter 2",
    label: "EF023473: Inner diameter 2"
  },
  {
    featureId: "EF023475",
    featureDesc: "Lead-through height",
    label: "EF023475: Lead-through height"
  },
  {
    featureId: "EF023476",
    featureDesc: "Wall thickness 1",
    label: "EF023476: Wall thickness 1"
  },
  {
    featureId: "EF023477",
    featureDesc: "Wall thickness 2",
    label: "EF023477: Wall thickness 2"
  },
  {
    featureId: "EF023478",
    featureDesc: "Number of hose connections",
    label: "EF023478: Number of hose connections"
  },
  {
    featureId: "EF023481",
    featureDesc: "Nominal diameter hose",
    label: "EF023481: Nominal diameter hose"
  },
  {
    featureId: "EF023486",
    featureDesc: "AWG wall thickness",
    label: "EF023486: AWG wall thickness"
  },
  {
    featureId: "EF023488",
    featureDesc: "Quality class unit pipework",
    label: "EF023488: Quality class unit pipework"
  },
  {
    featureId: "EF023489",
    featureDesc: "Material of unit pipework",
    label: "EF023489: Material of unit pipework"
  },
  {
    featureId: "EF023490",
    featureDesc: "Material strap",
    label: "EF023490: Material strap"
  },
  {
    featureId: "EF023491",
    featureDesc: "Material worm screw",
    label: "EF023491: Material worm screw"
  },
  {
    featureId: "EF023493",
    featureDesc: "Material hose connector",
    label: "EF023493: Material hose connector"
  },
  {
    featureId: "EF023494",
    featureDesc: "Fixed flange",
    label: "EF023494: Fixed flange"
  },
  {
    featureId: "EF023496",
    featureDesc: "Size of thread connection 1 (inch)",
    label: "EF023496: Size of thread connection 1 (inch)"
  },
  {
    featureId: "EF023497",
    featureDesc: "Size of thread connection 1",
    label: "EF023497: Size of thread connection 1"
  },
  {
    featureId: "EF023498",
    featureDesc: "Threaded connection 1",
    label: "EF023498: Threaded connection 1"
  },
  {
    featureId: "EF023499",
    featureDesc: "Size of thread connection (inch) 2",
    label: "EF023499: Size of thread connection (inch) 2"
  },
  {
    featureId: "EF023500",
    featureDesc: "Size of thread connection 2",
    label: "EF023500: Size of thread connection 2"
  },
  {
    featureId: "EF023501",
    featureDesc: "Threaded connection 2",
    label: "EF023501: Threaded connection 2"
  },
  {
    featureId: "EF023503",
    featureDesc: "With valve",
    label: "EF023503: With valve"
  },
  {
    featureId: "EF023505",
    featureDesc: "Self-venting",
    label: "EF023505: Self-venting"
  },
  {
    featureId: "EF023507",
    featureDesc: "Min. bursting pressure",
    label: "EF023507: Min. bursting pressure"
  },
  {
    featureId: "EF023510",
    featureDesc: "Sleeve",
    label: "EF023510: Sleeve"
  },
  {
    featureId: "EF023515",
    featureDesc: "With fixation",
    label: "EF023515: With fixation"
  },
  {
    featureId: "EF023517",
    featureDesc: "With DOP connection",
    label: "EF023517: With DOP connection"
  },
  {
    featureId: "EF023518",
    featureDesc: "Built-in height/depth",
    label: "EF023518: Built-in height/depth"
  },
  {
    featureId: "EF023519",
    featureDesc: "Distance between cover straps",
    label: "EF023519: Distance between cover straps"
  },
  {
    featureId: "EF023520",
    featureDesc: "Holder, coated",
    label: "EF023520: Holder, coated"
  },
  {
    featureId: "EF023521",
    featureDesc: "Inlay damping value",
    label: "EF023521: Inlay damping value"
  },
  {
    featureId: "EF023522",
    featureDesc: "Mounting of cover",
    label: "EF023522: Mounting of cover"
  },
  {
    featureId: "EF023523",
    featureDesc: "Diameter of cleaning eye",
    label: "EF023523: Diameter of cleaning eye"
  },
  {
    featureId: "EF023524",
    featureDesc: "Length of cleaning eye",
    label: "EF023524: Length of cleaning eye"
  },
  {
    featureId: "EF023525",
    featureDesc: "Width of cleaning eye",
    label: "EF023525: Width of cleaning eye"
  },
  {
    featureId: "EF023526",
    featureDesc: "With acoustic sheathing",
    label: "EF023526: With acoustic sheathing"
  },
  {
    featureId: "EF023527",
    featureDesc: "Pressure difference over volume box",
    label: "EF023527: Pressure difference over volume box"
  },
  {
    featureId: "EF023528",
    featureDesc: "With room/duct pressure difference sensor",
    label: "EF023528: With room/duct pressure difference sensor"
  },
  {
    featureId: "EF023530",
    featureDesc: "With round mounting stone",
    label: "EF023530: With round mounting stone"
  },
  {
    featureId: "EF023531",
    featureDesc: "With square mounting stone",
    label: "EF023531: With square mounting stone"
  },
  {
    featureId: "EF023532",
    featureDesc: "With pressure relief suction valve",
    label: "EF023532: With pressure relief suction valve"
  },
  {
    featureId: "EF023533",
    featureDesc: "With pressure relief blow-out valve",
    label: "EF023533: With pressure relief blow-out valve"
  },
  {
    featureId: "EF023534",
    featureDesc: "With contrarotating valve section",
    label: "EF023534: With contrarotating valve section"
  },
  {
    featureId: "EF023535",
    featureDesc: "With synchronised rotating valve section",
    label: "EF023535: With synchronised rotating valve section"
  },
  {
    featureId: "EF023536",
    featureDesc: "Air direction",
    label: "EF023536: Air direction"
  },
  {
    featureId: "EF023537",
    featureDesc: "Acoustically insulated",
    label: "EF023537: Acoustically insulated"
  },
  {
    featureId: "EF023538",
    featureDesc: "Double doors",
    label: "EF023538: Double doors"
  },
  {
    featureId: "EF023539",
    featureDesc: "With removable clamps",
    label: "EF023539: With removable clamps"
  },
  {
    featureId: "EF023540",
    featureDesc: "With rebated lock",
    label: "EF023540: With rebated lock"
  },
  {
    featureId: "EF023541",
    featureDesc: "With mortice lock",
    label: "EF023541: With mortice lock"
  },
  {
    featureId: "EF023542",
    featureDesc: "With sight glass",
    label: "EF023542: With sight glass"
  },
  {
    featureId: "EF023543",
    featureDesc: "With pressure by-pass valve",
    label: "EF023543: With pressure by-pass valve"
  },
  {
    featureId: "EF023544",
    featureDesc: "Allowable pressure load",
    label: "EF023544: Allowable pressure load"
  },
  {
    featureId: "EF023545",
    featureDesc: "Installation frame",
    label: "EF023545: Installation frame"
  },
  {
    featureId: "EF023546",
    featureDesc: "Link thickness",
    label: "EF023546: Link thickness"
  },
  {
    featureId: "EF023547",
    featureDesc: "Gap width",
    label: "EF023547: Gap width"
  },
  {
    featureId: "EF023548",
    featureDesc: "Expansion link finish",
    label: "EF023548: Expansion link finish"
  },
  {
    featureId: "EF023549",
    featureDesc: "With edge links",
    label: "EF023549: With edge links"
  },
  {
    featureId: "EF023550",
    featureDesc: "Suitable for outer pipe diameter",
    label: "EF023550: Suitable for outer pipe diameter"
  },
  {
    featureId: "EF023551",
    featureDesc: "Max. bracket strip width",
    label: "EF023551: Max. bracket strip width"
  },
  {
    featureId: "EF023552",
    featureDesc: "Max. bracket height",
    label: "EF023552: Max. bracket height"
  },
  {
    featureId: "EF023553",
    featureDesc: "Size of inlet thread connection (inch)",
    label: "EF023553: Size of inlet thread connection (inch)"
  },
  {
    featureId: "EF023554",
    featureDesc: "Size of inlet thread connection",
    label: "EF023554: Size of inlet thread connection"
  },
  {
    featureId: "EF023555",
    featureDesc: "Size of drum thread connection (inch)",
    label: "EF023555: Size of drum thread connection (inch)"
  },
  {
    featureId: "EF023556",
    featureDesc: "Size of drum thread connection",
    label: "EF023556: Size of drum thread connection"
  },
  {
    featureId: "EF023557",
    featureDesc: "Size of inlet 1 thread connection (inch)",
    label: "EF023557: Size of inlet 1 thread connection (inch)"
  },
  {
    featureId: "EF023558",
    featureDesc: "Size of inlet 1 thread connection (metric)",
    label: "EF023558: Size of inlet 1 thread connection (metric)"
  },
  {
    featureId: "EF023559",
    featureDesc: "Drum 1 thread connection",
    label: "EF023559: Drum 1 thread connection"
  },
  {
    featureId: "EF023560",
    featureDesc: "Size of drum 1 thread connection (inch)",
    label: "EF023560: Size of drum 1 thread connection (inch)"
  },
  {
    featureId: "EF023561",
    featureDesc: "Size of drum 1 thread connection (metric)",
    label: "EF023561: Size of drum 1 thread connection (metric)"
  },
  {
    featureId: "EF023562",
    featureDesc: "Size of inlet 2 thread connection (inch)",
    label: "EF023562: Size of inlet 2 thread connection (inch)"
  },
  {
    featureId: "EF023563",
    featureDesc: "Size of inlet 2 thread connection (metric)",
    label: "EF023563: Size of inlet 2 thread connection (metric)"
  },
  {
    featureId: "EF023564",
    featureDesc: "Drum 2 thread connection",
    label: "EF023564: Drum 2 thread connection"
  },
  {
    featureId: "EF023565",
    featureDesc: "Size of drum 2 thread connection (inch)",
    label: "EF023565: Size of drum 2 thread connection (inch)"
  },
  {
    featureId: "EF023566",
    featureDesc: "Size of drum 2 thread connection (metric)",
    label: "EF023566: Size of drum 2 thread connection (metric)"
  },
  {
    featureId: "EF023569",
    featureDesc: "Coupling size (inch)",
    label: "EF023569: Coupling size (inch)"
  },
  {
    featureId: "EF023570",
    featureDesc: "Loose flange",
    label: "EF023570: Loose flange"
  },
  {
    featureId: "EF023571",
    featureDesc: "Neutral length",
    label: "EF023571: Neutral length"
  },
  {
    featureId: "EF023572",
    featureDesc: "Inspection opening working height",
    label: "EF023572: Inspection opening working height"
  },
  {
    featureId: "EF023573",
    featureDesc: "Angle of branches",
    label: "EF023573: Angle of branches"
  },
  {
    featureId: "EF023574",
    featureDesc: "Height (duct top)",
    label: "EF023574: Height (duct top)"
  },
  {
    featureId: "EF023575",
    featureDesc: "Working height (duct top)",
    label: "EF023575: Working height (duct top)"
  },
  {
    featureId: "EF023576",
    featureDesc: "For round air duct",
    label: "EF023576: For round air duct"
  },
  {
    featureId: "EF023577",
    featureDesc: "For duct diameter",
    label: "EF023577: For duct diameter"
  },
  {
    featureId: "EF023578",
    featureDesc: "Connection 1, gland nut end",
    label: "EF023578: Connection 1, gland nut end"
  },
  {
    featureId: "EF023579",
    featureDesc: "Hexagonal seal 1",
    label: "EF023579: Hexagonal seal 1"
  },
  {
    featureId: "EF023580",
    featureDesc: "Threaded end seal 1",
    label: "EF023580: Threaded end seal 1"
  },
  {
    featureId: "EF023581",
    featureDesc: "Connection 2, gland nut end",
    label: "EF023581: Connection 2, gland nut end"
  },
  {
    featureId: "EF023582",
    featureDesc: "Hexagonal seal 2",
    label: "EF023582: Hexagonal seal 2"
  },
  {
    featureId: "EF023583",
    featureDesc: "Threaded end seal 2",
    label: "EF023583: Threaded end seal 2"
  },
  {
    featureId: "EF023584",
    featureDesc: "Threaded connection 3",
    label: "EF023584: Threaded connection 3"
  },
  {
    featureId: "EF023585",
    featureDesc: "Size of thread connection (inch) 3",
    label: "EF023585: Size of thread connection (inch) 3"
  },
  {
    featureId: "EF023586",
    featureDesc: "Size of thread connection 3",
    label: "EF023586: Size of thread connection 3"
  },
  {
    featureId: "EF023587",
    featureDesc: "Connection 3, gland nut end",
    label: "EF023587: Connection 3, gland nut end"
  },
  {
    featureId: "EF023588",
    featureDesc: "Threaded end seal 3",
    label: "EF023588: Threaded end seal 3"
  },
  {
    featureId: "EF023589",
    featureDesc: "Hexagonal seal 3",
    label: "EF023589: Hexagonal seal 3"
  },
  {
    featureId: "EF023590",
    featureDesc: "Threaded connection 4",
    label: "EF023590: Threaded connection 4"
  },
  {
    featureId: "EF023591",
    featureDesc: "Size of thread connection (inch) 4",
    label: "EF023591: Size of thread connection (inch) 4"
  },
  {
    featureId: "EF023592",
    featureDesc: "Size of thread connection 4",
    label: "EF023592: Size of thread connection 4"
  },
  {
    featureId: "EF023593",
    featureDesc: "Connection 4, gland nut end",
    label: "EF023593: Connection 4, gland nut end"
  },
  {
    featureId: "EF023594",
    featureDesc: "Threaded end seal 4",
    label: "EF023594: Threaded end seal 4"
  },
  {
    featureId: "EF023595",
    featureDesc: "Hexagonal seal 4",
    label: "EF023595: Hexagonal seal 4"
  },
  {
    featureId: "EF023596",
    featureDesc: "Load class (DIN 2353)",
    label: "EF023596: Load class (DIN 2353)"
  },
  {
    featureId: "EF023597",
    featureDesc: "Hexagonal seal",
    label: "EF023597: Hexagonal seal"
  },
  {
    featureId: "EF023598",
    featureDesc: "Number of pipe connections",
    label: "EF023598: Number of pipe connections"
  },
  {
    featureId: "EF023600",
    featureDesc: "Outer pipe diameter connection 1 (inch)",
    label: "EF023600: Outer pipe diameter connection 1 (inch)"
  },
  {
    featureId: "EF023604",
    featureDesc: "Outer pipe diameter connection 2 (inch)",
    label: "EF023604: Outer pipe diameter connection 2 (inch)"
  },
  {
    featureId: "EF023605",
    featureDesc: "Outer pipe diameter connection 3 (inch)",
    label: "EF023605: Outer pipe diameter connection 3 (inch)"
  },
  {
    featureId: "EF023606",
    featureDesc: "Outer pipe diameter connection 4 (inch)",
    label: "EF023606: Outer pipe diameter connection 4 (inch)"
  },
  {
    featureId: "EF023607",
    featureDesc: "Standardised according to JIC",
    label: "EF023607: Standardised according to JIC"
  },
  {
    featureId: "EF023608",
    featureDesc: "At right angle to pipe",
    label: "EF023608: At right angle to pipe"
  },
  {
    featureId: "EF023609",
    featureDesc: "Protective disc for ceramics",
    label: "EF023609: Protective disc for ceramics"
  },
  {
    featureId: "EF023611",
    featureDesc: "Spindle thread length",
    label: "EF023611: Spindle thread length"
  },
  {
    featureId: "EF023612",
    featureDesc: "Suitable for strip lead-through",
    label: "EF023612: Suitable for strip lead-through"
  },
  {
    featureId: "EF023613",
    featureDesc: "Reinforcing ring",
    label: "EF023613: Reinforcing ring"
  },
  {
    featureId: "EF023614",
    featureDesc: "Suitable for 3-layer strip",
    label: "EF023614: Suitable for 3-layer strip"
  },
  {
    featureId: "EF023615",
    featureDesc: "Nominal bore in DN",
    label: "EF023615: Nominal bore in DN"
  },
  {
    featureId: "EF023617",
    featureDesc: "Suitable for pneumatic operation",
    label: "EF023617: Suitable for pneumatic operation"
  },
  {
    featureId: "EF023618",
    featureDesc: "Suitable for electrical operation",
    label: "EF023618: Suitable for electrical operation"
  },
  {
    featureId: "EF023621",
    featureDesc: "With inlet and outlet fittings",
    label: "EF023621: With inlet and outlet fittings"
  },
  {
    featureId: "EF023622",
    featureDesc: "Variable flushing bend length",
    label: "EF023622: Variable flushing bend length"
  },
  {
    featureId: "EF023623",
    featureDesc: "Outer pipe diameter reservoir side flushing bend",
    label: "EF023623: Outer pipe diameter reservoir side flushing bend"
  },
  {
    featureId: "EF023624",
    featureDesc: "Reservoir position",
    label: "EF023624: Reservoir position"
  },
  {
    featureId: "EF023625",
    featureDesc: "Rigid trap",
    label: "EF023625: Rigid trap"
  },
  {
    featureId: "EF023630",
    featureDesc: "Packed plug",
    label: "EF023630: Packed plug"
  },
  {
    featureId: "EF023631",
    featureDesc: "Length of raised edge roof flashing",
    label: "EF023631: Length of raised edge roof flashing"
  },
  {
    featureId: "EF023632",
    featureDesc: "Angle of raised edge roof flashing",
    label: "EF023632: Angle of raised edge roof flashing"
  },
  {
    featureId: "EF023633",
    featureDesc: "Tray height",
    label: "EF023633: Tray height"
  },
  {
    featureId: "EF023634",
    featureDesc: "Tray width",
    label: "EF023634: Tray width"
  },
  {
    featureId: "EF023635",
    featureDesc: "Expansion length",
    label: "EF023635: Expansion length"
  },
  {
    featureId: "EF023636",
    featureDesc: "Fixed point bracket diameter",
    label: "EF023636: Fixed point bracket diameter"
  },
  {
    featureId: "EF023638",
    featureDesc: "Leg thickness",
    label: "EF023638: Leg thickness"
  },
  {
    featureId: "EF023639",
    featureDesc: "Beam thickness",
    label: "EF023639: Beam thickness"
  },
  {
    featureId: "EF023640",
    featureDesc: "Slide length",
    label: "EF023640: Slide length"
  },
  {
    featureId: "EF023641",
    featureDesc: "Bracket attachment, double",
    label: "EF023641: Bracket attachment, double"
  },
  {
    featureId: "EF023642",
    featureDesc: "Adjustment range",
    label: "EF023642: Adjustment range"
  },
  {
    featureId: "EF023643",
    featureDesc: "Max. angular rotation",
    label: "EF023643: Max. angular rotation"
  },
  {
    featureId: "EF023644",
    featureDesc: "Thread connection, top",
    label: "EF023644: Thread connection, top"
  },
  {
    featureId: "EF023645",
    featureDesc: "Thread connection, bottom",
    label: "EF023645: Thread connection, bottom"
  },
  {
    featureId: "EF023646",
    featureDesc: "With inspection hole",
    label: "EF023646: With inspection hole"
  },
  {
    featureId: "EF023647",
    featureDesc: "Outer thread connection",
    label: "EF023647: Outer thread connection"
  },
  {
    featureId: "EF023648",
    featureDesc: "With key face",
    label: "EF023648: With key face"
  },
  {
    featureId: "EF023649",
    featureDesc: "With T-nut",
    label: "EF023649: With T-nut"
  },
  {
    featureId: "EF023650",
    featureDesc: "Damping value",
    label: "EF023650: Damping value"
  },
  {
    featureId: "EF023652",
    featureDesc: "With damper",
    label: "EF023652: With damper"
  },
  {
    featureId: "EF023653",
    featureDesc: "Diameter connection facility",
    label: "EF023653: Diameter connection facility"
  },
  {
    featureId: "EF023654",
    featureDesc: "Connection size duct attachment",
    label: "EF023654: Connection size duct attachment"
  },
  {
    featureId: "EF023655",
    featureDesc: "Attachment connection",
    label: "EF023655: Attachment connection"
  },
  {
    featureId: "EF023656",
    featureDesc: "Size of attachment thread connection (metric)",
    label: "EF023656: Size of attachment thread connection (metric)"
  },
  {
    featureId: "EF023657",
    featureDesc: "Size of connection facility thread connection (metric)",
    label: "EF023657: Size of connection facility thread connection (metric)"
  },
  {
    featureId: "EF023658",
    featureDesc: "Number of connection facilities",
    label: "EF023658: Number of connection facilities"
  },
  {
    featureId: "EF023659",
    featureDesc: "Centre distance of connection facilities",
    label: "EF023659: Centre distance of connection facilities"
  },
  {
    featureId: "EF023660",
    featureDesc: "For shower hose",
    label: "EF023660: For shower hose"
  },
  {
    featureId: "EF023661",
    featureDesc: "For shower head",
    label: "EF023661: For shower head"
  },
  {
    featureId: "EF023662",
    featureDesc: "With covering rosette",
    label: "EF023662: With covering rosette"
  },
  {
    featureId: "EF023664",
    featureDesc: "Number of tap holes",
    label: "EF023664: Number of tap holes"
  },
  {
    featureId: "EF023666",
    featureDesc: "Size of tap threaded connection (inch)",
    label: "EF023666: Size of tap threaded connection (inch)"
  },
  {
    featureId: "EF023674",
    featureDesc: "Size of tap outlet threaded connection (inch)",
    label: "EF023674: Size of tap outlet threaded connection (inch)"
  },
  {
    featureId: "EF023676",
    featureDesc: "Size of threaded connection 1",
    label: "EF023676: Size of threaded connection 1"
  },
  {
    featureId: "EF023677",
    featureDesc: "Size of threaded connection 2",
    label: "EF023677: Size of threaded connection 2"
  },
  {
    featureId: "EF023678",
    featureDesc: "Suitable for threaded rod (M)",
    label: "EF023678: Suitable for threaded rod (M)"
  },
  {
    featureId: "EF023679",
    featureDesc: "Lacquered",
    label: "EF023679: Lacquered"
  },
  {
    featureId: "EF023680",
    featureDesc: "Square",
    label: "EF023680: Square"
  },
  {
    featureId: "EF023681",
    featureDesc: "Communal flue system",
    label: "EF023681: Communal flue system"
  },
  {
    featureId: "EF023683",
    featureDesc: "A-side connector",
    label: "EF023683: A-side connector"
  },
  {
    featureId: "EF023684",
    featureDesc: "B-side connector",
    label: "EF023684: B-side connector"
  },
  {
    featureId: "EF023685",
    featureDesc: "Thermostatically prepared",
    label: "EF023685: Thermostatically prepared"
  },
  {
    featureId: "EF023686",
    featureDesc: "Accent colour",
    label: "EF023686: Accent colour"
  },
  {
    featureId: "EF023688",
    featureDesc: "Tap nozzle",
    label: "EF023688: Tap nozzle"
  },
  {
    featureId: "EF023689",
    featureDesc: "With connection hoses",
    label: "EF023689: With connection hoses"
  },
  {
    featureId: "EF023691",
    featureDesc: "Material button",
    label: "EF023691: Material button"
  },
  {
    featureId: "EF023697",
    featureDesc: "Electrically conductive inner wall",
    label: "EF023697: Electrically conductive inner wall"
  },
  {
    featureId: "EF023698",
    featureDesc: "Spiral",
    label: "EF023698: Spiral"
  },
  {
    featureId: "EF023699",
    featureDesc: "Outer wall colour",
    label: "EF023699: Outer wall colour"
  },
  {
    featureId: "EF023700",
    featureDesc: "Glass thickness",
    label: "EF023700: Glass thickness"
  },
  {
    featureId: "EF023701",
    featureDesc: "Thread size process connection (inch)",
    label: "EF023701: Thread size process connection (inch)"
  },
  {
    featureId: "EF023702",
    featureDesc: "Thread size process connection (mm)",
    label: "EF023702: Thread size process connection (mm)"
  },
  {
    featureId: "EF023703",
    featureDesc: "With nipple",
    label: "EF023703: With nipple"
  },
  {
    featureId: "EF023704",
    featureDesc: "Material nipple",
    label: "EF023704: Material nipple"
  },
  {
    featureId: "EF023705",
    featureDesc: "Thread size nipple (inch)",
    label: "EF023705: Thread size nipple (inch)"
  },
  {
    featureId: "EF023706",
    featureDesc: "Thread size nipple (mm)",
    label: "EF023706: Thread size nipple (mm)"
  },
  {
    featureId: "EF023707",
    featureDesc: "Wall-mounted flange shape",
    label: "EF023707: Wall-mounted flange shape"
  },
  {
    featureId: "EF023708",
    featureDesc: "Exhaust model",
    label: "EF023708: Exhaust model"
  },
  {
    featureId: "EF023709",
    featureDesc: "Outlet underside",
    label: "EF023709: Outlet underside"
  },
  {
    featureId: "EF023710",
    featureDesc: "Hot water inlet connection",
    label: "EF023710: Hot water inlet connection"
  },
  {
    featureId: "EF023711",
    featureDesc: "Size of hot water inlet thread connection (inch)",
    label: "EF023711: Size of hot water inlet thread connection (inch)"
  },
  {
    featureId: "EF023712",
    featureDesc: "Size of hot water inlet thread connection",
    label: "EF023712: Size of hot water inlet thread connection"
  },
  {
    featureId: "EF023715",
    featureDesc: "With EA/EU (polyurethane) inner wall",
    label: "EF023715: With EA/EU (polyurethane) inner wall"
  },
  {
    featureId: "EF023716",
    featureDesc: "Material sensor",
    label: "EF023716: Material sensor"
  },
  {
    featureId: "EF023717",
    featureDesc: "Hose connector grip, smooth",
    label: "EF023717: Hose connector grip, smooth"
  },
  {
    featureId: "EF023718",
    featureDesc: "Threaded end seal",
    label: "EF023718: Threaded end seal"
  },
  {
    featureId: "EF023720",
    featureDesc: "Nominal bore, coupling 1",
    label: "EF023720: Nominal bore, coupling 1"
  },
  {
    featureId: "EF023721",
    featureDesc: "Nominal bore, coupling 2",
    label: "EF023721: Nominal bore, coupling 2"
  },
  {
    featureId: "EF023722",
    featureDesc: "For bore",
    label: "EF023722: For bore"
  },
  {
    featureId: "EF023723",
    featureDesc: "With grip clamps",
    label: "EF023723: With grip clamps"
  },
  {
    featureId: "EF023725",
    featureDesc: "With locking hole",
    label: "EF023725: With locking hole"
  },
  {
    featureId: "EF023729",
    featureDesc: "Designed as a partition coupling",
    label: "EF023729: Designed as a partition coupling"
  },
  {
    featureId: "EF023730",
    featureDesc: "For Storz",
    label: "EF023730: For Storz"
  },
  {
    featureId: "EF023731",
    featureDesc: "For Guillemin",
    label: "EF023731: For Guillemin"
  },
  {
    featureId: "EF023732",
    featureDesc: "For DSP",
    label: "EF023732: For DSP"
  },
  {
    featureId: "EF023733",
    featureDesc: "For TW",
    label: "EF023733: For TW"
  },
  {
    featureId: "EF023734",
    featureDesc: "For coupling size",
    label: "EF023734: For coupling size"
  },
  {
    featureId: "EF023735",
    featureDesc: "With railing",
    label: "EF023735: With railing"
  },
  {
    featureId: "EF023737",
    featureDesc: "With anchor",
    label: "EF023737: With anchor"
  },
  {
    featureId: "EF023738",
    featureDesc: "With stand",
    label: "EF023738: With stand"
  },
  {
    featureId: "EF023739",
    featureDesc: "Shape of operating button",
    label: "EF023739: Shape of operating button"
  },
  {
    featureId: "EF023740",
    featureDesc: "Assembly of operating button",
    label: "EF023740: Assembly of operating button"
  },
  {
    featureId: "EF023741",
    featureDesc: "Operating type",
    label: "EF023741: Operating type"
  },
  {
    featureId: "EF023742",
    featureDesc: "Eccentric connection",
    label: "EF023742: Eccentric connection"
  },
  {
    featureId: "EF023743",
    featureDesc: "Max. height of flushing bend",
    label: "EF023743: Max. height of flushing bend"
  },
  {
    featureId: "EF023744",
    featureDesc: "Min. height of flush bend",
    label: "EF023744: Min. height of flush bend"
  },
  {
    featureId: "EF023745",
    featureDesc: "With spacer tube for radar antenna",
    label: "EF023745: With spacer tube for radar antenna"
  },
  {
    featureId: "EF023747",
    featureDesc: "Lever material",
    label: "EF023747: Lever material"
  },
  {
    featureId: "EF023748",
    featureDesc: "For dairy thread connection (DIN 405) DN",
    label: "EF023748: For dairy thread connection (DIN 405) DN"
  },
  {
    featureId: "EF023749",
    featureDesc: "For flange size",
    label: "EF023749: For flange size"
  },
  {
    featureId: "EF023750",
    featureDesc: "With bolts",
    label: "EF023750: With bolts"
  },
  {
    featureId: "EF023751",
    featureDesc: "Suitable for front operation",
    label: "EF023751: Suitable for front operation"
  },
  {
    featureId: "EF023752",
    featureDesc: "Suitable for shelf operation",
    label: "EF023752: Suitable for shelf operation"
  },
  {
    featureId: "EF023753",
    featureDesc: "With control panel",
    label: "EF023753: With control panel"
  },
  {
    featureId: "EF023754",
    featureDesc: "Length of control panel",
    label: "EF023754: Length of control panel"
  },
  {
    featureId: "EF023755",
    featureDesc: "Adjustable bidet height",
    label: "EF023755: Adjustable bidet height"
  },
  {
    featureId: "EF023756",
    featureDesc: "Adjustable toilet height",
    label: "EF023756: Adjustable toilet height"
  },
  {
    featureId: "EF023757",
    featureDesc: "Pre-assembled flushing unit",
    label: "EF023757: Pre-assembled flushing unit"
  },
  {
    featureId: "EF023758",
    featureDesc: "Adjustable urinal height",
    label: "EF023758: Adjustable urinal height"
  },
  {
    featureId: "EF023759",
    featureDesc: "With inlet fittings",
    label: "EF023759: With inlet fittings"
  },
  {
    featureId: "EF023760",
    featureDesc: "Adjustable sink height",
    label: "EF023760: Adjustable sink height"
  },
  {
    featureId: "EF023761",
    featureDesc: "Arm support height adjustable",
    label: "EF023761: Arm support height adjustable"
  },
  {
    featureId: "EF023762",
    featureDesc: "Console length",
    label: "EF023762: Console length"
  },
  {
    featureId: "EF023763",
    featureDesc: "With wall rail",
    label: "EF023763: With wall rail"
  },
  {
    featureId: "EF023765",
    featureDesc: "Suitable for rail width",
    label: "EF023765: Suitable for rail width"
  },
  {
    featureId: "EF023766",
    featureDesc: "Raised edge",
    label: "EF023766: Raised edge"
  },
  {
    featureId: "EF023768",
    featureDesc: "On the rail",
    label: "EF023768: On the rail"
  },
  {
    featureId: "EF023769",
    featureDesc: "Number of holes/slots per side",
    label: "EF023769: Number of holes/slots per side"
  },
  {
    featureId: "EF023770",
    featureDesc: "Suitable for slate",
    label: "EF023770: Suitable for slate"
  },
  {
    featureId: "EF023771",
    featureDesc: "For surface mounted flushing reservoir",
    label: "EF023771: For surface mounted flushing reservoir"
  },
  {
    featureId: "EF023772",
    featureDesc: "With built-in cupboard",
    label: "EF023772: With built-in cupboard"
  },
  {
    featureId: "EF023773",
    featureDesc: "With lifting system",
    label: "EF023773: With lifting system"
  },
  {
    featureId: "EF023774",
    featureDesc: "Length of threaded connection",
    label: "EF023774: Length of threaded connection"
  },
  {
    featureId: "EF023777",
    featureDesc: "Mixed water connection inlet",
    label: "EF023777: Mixed water connection inlet"
  },
  {
    featureId: "EF023782",
    featureDesc: "With headrest",
    label: "EF023782: With headrest"
  },
  {
    featureId: "EF023783",
    featureDesc: "With backrest",
    label: "EF023783: With backrest"
  },
  {
    featureId: "EF023786",
    featureDesc: "Lockable jets",
    label: "EF023786: Lockable jets"
  },
  {
    featureId: "EF023787",
    featureDesc: "Waterless",
    label: "EF023787: Waterless"
  },
  {
    featureId: "EF023788",
    featureDesc: "With towel holder",
    label: "EF023788: With towel holder"
  },
  {
    featureId: "EF023789",
    featureDesc: "Dirt-resistant",
    label: "EF023789: Dirt-resistant"
  },
  {
    featureId: "EF023790",
    featureDesc: "Anti-bacterial treatment",
    label: "EF023790: Anti-bacterial treatment"
  },
  {
    featureId: "EF023791",
    featureDesc: "With earthing feature",
    label: "EF023791: With earthing feature"
  },
  {
    featureId: "EF023792",
    featureDesc: "Max. flushing water quantity",
    label: "EF023792: Max. flushing water quantity"
  },
  {
    featureId: "EF023793",
    featureDesc: "With bird wire",
    label: "EF023793: With bird wire"
  },
  {
    featureId: "EF023794",
    featureDesc: "Plenum with oval connection",
    label: "EF023794: Plenum with oval connection"
  },
  {
    featureId: "EF023795",
    featureDesc: "Plenum internally insulated",
    label: "EF023795: Plenum internally insulated"
  },
  {
    featureId: "EF023796",
    featureDesc: "Modular ceiling",
    label: "EF023796: Modular ceiling"
  },
  {
    featureId: "EF023797",
    featureDesc: "With plenum for stucco ceiling",
    label: "EF023797: With plenum for stucco ceiling"
  },
  {
    featureId: "EF023798",
    featureDesc: "Number of nozzles",
    label: "EF023798: Number of nozzles"
  },
  {
    featureId: "EF023799",
    featureDesc: "Nozzle material",
    label: "EF023799: Nozzle material"
  },
  {
    featureId: "EF023800",
    featureDesc: "Air pattern, horizontally adjustable",
    label: "EF023800: Air pattern, horizontally adjustable"
  },
  {
    featureId: "EF023801",
    featureDesc: "Air pattern, vertically adjustable",
    label: "EF023801: Air pattern, vertically adjustable"
  },
  {
    featureId: "EF023802",
    featureDesc: "Air pattern, adjustable",
    label: "EF023802: Air pattern, adjustable"
  },
  {
    featureId: "EF023803",
    featureDesc: "Air pattern",
    label: "EF023803: Air pattern"
  },
  {
    featureId: "EF023804",
    featureDesc: "With installation/flange frame",
    label: "EF023804: With installation/flange frame"
  },
  {
    featureId: "EF023805",
    featureDesc: "Mounting of internal parts/grille",
    label: "EF023805: Mounting of internal parts/grille"
  },
  {
    featureId: "EF023806",
    featureDesc: "Suitable for door/wall thickness",
    label: "EF023806: Suitable for door/wall thickness"
  },
  {
    featureId: "EF023807",
    featureDesc: "Nominal diameter heating",
    label: "EF023807: Nominal diameter heating"
  },
  {
    featureId: "EF023808",
    featureDesc: "Nominal diameter cooling",
    label: "EF023808: Nominal diameter cooling"
  },
  {
    featureId: "EF023809",
    featureDesc: "With pneumatic bypass",
    label: "EF023809: With pneumatic bypass"
  },
  {
    featureId: "EF023810",
    featureDesc: "Pneumatic bypass controls",
    label: "EF023810: Pneumatic bypass controls"
  },
  {
    featureId: "EF023811",
    featureDesc: "Suitable for hydraulic operation",
    label: "EF023811: Suitable for hydraulic operation"
  },
  {
    featureId: "EF023814",
    featureDesc: "For urinal flushing",
    label: "EF023814: For urinal flushing"
  },
  {
    featureId: "EF023815",
    featureDesc: "With holes pattern",
    label: "EF023815: With holes pattern"
  },
  {
    featureId: "EF023816",
    featureDesc: "With mounting profiles",
    label: "EF023816: With mounting profiles"
  },
  {
    featureId: "EF023817",
    featureDesc: "Outer pipe diameter collection unit flushing bend",
    label: "EF023817: Outer pipe diameter collection unit flushing bend"
  },
  {
    featureId: "EF023820",
    featureDesc: "Internally lined",
    label: "EF023820: Internally lined"
  },
  {
    featureId: "EF023821",
    featureDesc: "With thermal insulation",
    label: "EF023821: With thermal insulation"
  },
  {
    featureId: "EF023823",
    featureDesc: "Internally coated",
    label: "EF023823: Internally coated"
  },
  {
    featureId: "EF023825",
    featureDesc: "Primary material",
    label: "EF023825: Primary material"
  },
  {
    featureId: "EF023826",
    featureDesc: "Suitable for universal toilet bowl",
    label: "EF023826: Suitable for universal toilet bowl"
  },
  {
    featureId: "EF023827",
    featureDesc: "Wood colour/wood motif",
    label: "EF023827: Wood colour/wood motif"
  },
  {
    featureId: "EF023829",
    featureDesc: "Delaying hinge",
    label: "EF023829: Delaying hinge"
  },
  {
    featureId: "EF023830",
    featureDesc: "Removable for cleaning purposes",
    label: "EF023830: Removable for cleaning purposes"
  },
  {
    featureId: "EF023831",
    featureDesc: "Continuous hinge pin",
    label: "EF023831: Continuous hinge pin"
  },
  {
    featureId: "EF023832",
    featureDesc: "For assembly from above",
    label: "EF023832: For assembly from above"
  },
  {
    featureId: "EF023833",
    featureDesc: "Material hinged leg/rosette",
    label: "EF023833: Material hinged leg/rosette"
  },
  {
    featureId: "EF023835",
    featureDesc: "Material seat/lid",
    label: "EF023835: Material seat/lid"
  },
  {
    featureId: "EF023836",
    featureDesc: "Material hinge flap",
    label: "EF023836: Material hinge flap"
  },
  {
    featureId: "EF023837",
    featureDesc: "Annealed blank",
    label: "EF023837: Annealed blank"
  },
  {
    featureId: "EF023839",
    featureDesc: "Not slotted",
    label: "EF023839: Not slotted"
  },
  {
    featureId: "EF023840",
    featureDesc: "Material valve butterfly",
    label: "EF023840: Material valve butterfly"
  },
  {
    featureId: "EF023841",
    featureDesc: "Valve butterfly lined",
    label: "EF023841: Valve butterfly lined"
  },
  {
    featureId: "EF023842",
    featureDesc: "Grease-free",
    label: "EF023842: Grease-free"
  },
  {
    featureId: "EF023843",
    featureDesc: "Straight bore",
    label: "EF023843: Straight bore"
  },
  {
    featureId: "EF023845",
    featureDesc: "Pilot light",
    label: "EF023845: Pilot light"
  },
  {
    featureId: "EF023846",
    featureDesc: "Second return connection",
    label: "EF023846: Second return connection"
  },
  {
    featureId: "EF023847",
    featureDesc: "Control signal on/off",
    label: "EF023847: Control signal on/off"
  },
  {
    featureId: "EF023848",
    featureDesc: "Control signal high/low",
    label: "EF023848: Control signal high/low"
  },
  {
    featureId: "EF023849",
    featureDesc: "Control signal 0-10 V",
    label: "EF023849: Control signal 0-10 V"
  },
  {
    featureId: "EF023850",
    featureDesc: "Control signal OpenTherm",
    label: "EF023850: Control signal OpenTherm"
  },
  {
    featureId: "EF023851",
    featureDesc: "Max. pressure difference for AC model",
    label: "EF023851: Max. pressure difference for AC model"
  },
  {
    featureId: "EF023852",
    featureDesc: "Max. pressure difference for DC model",
    label: "EF023852: Max. pressure difference for DC model"
  },
  {
    featureId: "EF023853",
    featureDesc: "Thermostatically adjustable",
    label: "EF023853: Thermostatically adjustable"
  },
  {
    featureId: "EF023854",
    featureDesc: "Slit length",
    label: "EF023854: Slit length"
  },
  {
    featureId: "EF023855",
    featureDesc: "With bent tailpiece",
    label: "EF023855: With bent tailpiece"
  },
  {
    featureId: "EF023856",
    featureDesc: "Material coupling",
    label: "EF023856: Material coupling"
  },
  {
    featureId: "EF023857",
    featureDesc: "Material quality coupling",
    label: "EF023857: Material quality coupling"
  },
  {
    featureId: "EF023858",
    featureDesc: "Mounted on pipe",
    label: "EF023858: Mounted on pipe"
  },
  {
    featureId: "EF023859",
    featureDesc: "Flange shape oval",
    label: "EF023859: Flange shape oval"
  },
  {
    featureId: "EF023860",
    featureDesc: "Max. angle of rotation",
    label: "EF023860: Max. angle of rotation"
  },
  {
    featureId: "EF023862",
    featureDesc: "Variable length",
    label: "EF023862: Variable length"
  },
  {
    featureId: "EF023863",
    featureDesc: "Material inner layer",
    label: "EF023863: Material inner layer"
  },
  {
    featureId: "EF023864",
    featureDesc: "Material intermediate layer",
    label: "EF023864: Material intermediate layer"
  },
  {
    featureId: "EF023865",
    featureDesc: "Material outer layer",
    label: "EF023865: Material outer layer"
  },
  {
    featureId: "EF023866",
    featureDesc: "Covering material",
    label: "EF023866: Covering material"
  },
  {
    featureId: "EF023868",
    featureDesc: "Raised part, front",
    label: "EF023868: Raised part, front"
  },
  {
    featureId: "EF023869",
    featureDesc: "Raised part, rear",
    label: "EF023869: Raised part, rear"
  },
  {
    featureId: "EF023870",
    featureDesc: "Buffers with side studs",
    label: "EF023870: Buffers with side studs"
  },
  {
    featureId: "EF023871",
    featureDesc: "Widened seat area",
    label: "EF023871: Widened seat area"
  },
  {
    featureId: "EF023872",
    featureDesc: "With splash guard",
    label: "EF023872: With splash guard"
  },
  {
    featureId: "EF023873",
    featureDesc: "High tensile strength",
    label: "EF023873: High tensile strength"
  },
  {
    featureId: "EF023876",
    featureDesc: "Operation as open model (air supply) possible",
    label: "EF023876: Operation as open model (air supply) possible"
  },
  {
    featureId: "EF023877",
    featureDesc: "Nominal power, 50-30 °C",
    label: "EF023877: Nominal power, 50-30 °C"
  },
  {
    featureId: "EF023880",
    featureDesc: "Suitable for propane",
    label: "EF023880: Suitable for propane"
  },
  {
    featureId: "EF023881",
    featureDesc: "Suitable for butane",
    label: "EF023881: Suitable for butane"
  },
  {
    featureId: "EF023882",
    featureDesc: "Suitable for methane",
    label: "EF023882: Suitable for methane"
  },
  {
    featureId: "EF023886",
    featureDesc: "Nominal diameter of supply",
    label: "EF023886: Nominal diameter of supply"
  },
  {
    featureId: "EF023887",
    featureDesc: "Nominal diameter return",
    label: "EF023887: Nominal diameter return"
  },
  {
    featureId: "EF023888",
    featureDesc: "Nominal diameter fuel",
    label: "EF023888: Nominal diameter fuel"
  },
  {
    featureId: "EF023890",
    featureDesc: "Hot tap water storage",
    label: "EF023890: Hot tap water storage"
  },
  {
    featureId: "EF023891",
    featureDesc: "Three-phase model",
    label: "EF023891: Three-phase model"
  },
  {
    featureId: "EF023892",
    featureDesc: "Hinged leg attachment to seat with",
    label: "EF023892: Hinged leg attachment to seat with"
  },
  {
    featureId: "EF023893",
    featureDesc: "Anti-theft attachment",
    label: "EF023893: Anti-theft attachment"
  },
  {
    featureId: "EF023894",
    featureDesc: "With casing",
    label: "EF023894: With casing"
  },
  {
    featureId: "EF023895",
    featureDesc: "Round top connection",
    label: "EF023895: Round top connection"
  },
  {
    featureId: "EF023896",
    featureDesc: "Without vortex element",
    label: "EF023896: Without vortex element"
  },
  {
    featureId: "EF023897",
    featureDesc: "Filter seal",
    label: "EF023897: Filter seal"
  },
  {
    featureId: "EF023898",
    featureDesc: "Transmission",
    label: "EF023898: Transmission"
  },
  {
    featureId: "EF023900",
    featureDesc: "Allowable relative humidity",
    label: "EF023900: Allowable relative humidity"
  },
  {
    featureId: "EF023901",
    featureDesc: "Adjustable operating range",
    label: "EF023901: Adjustable operating range"
  },
  {
    featureId: "EF023908",
    featureDesc: "Seal, connection 2",
    label: "EF023908: Seal, connection 2"
  },
  {
    featureId: "EF023909",
    featureDesc: "Without flange frame",
    label: "EF023909: Without flange frame"
  },
  {
    featureId: "EF023910",
    featureDesc: "With sound absorber",
    label: "EF023910: With sound absorber"
  },
  {
    featureId: "EF023911",
    featureDesc: "With fresh air connection",
    label: "EF023911: With fresh air connection"
  },
  {
    featureId: "EF023912",
    featureDesc: "With outlet plenum",
    label: "EF023912: With outlet plenum"
  },
  {
    featureId: "EF023913",
    featureDesc: "Water connections, left",
    label: "EF023913: Water connections, left"
  },
  {
    featureId: "EF023914",
    featureDesc: "With level switch",
    label: "EF023914: With level switch"
  },
  {
    featureId: "EF023915",
    featureDesc: "Front exhaust",
    label: "EF023915: Front exhaust"
  },
  {
    featureId: "EF023916",
    featureDesc: "Collector principle",
    label: "EF023916: Collector principle"
  },
  {
    featureId: "EF023917",
    featureDesc: "Glycol-resistant",
    label: "EF023917: Glycol-resistant"
  },
  {
    featureId: "EF023918",
    featureDesc: "Suitable for closed system",
    label: "EF023918: Suitable for closed system"
  },
  {
    featureId: "EF023919",
    featureDesc: "Suitable for swimming pool water heating",
    label: "EF023919: Suitable for swimming pool water heating"
  },
  {
    featureId: "EF023920",
    featureDesc: "With pump and control unit",
    label: "EF023920: With pump and control unit"
  },
  {
    featureId: "EF023921",
    featureDesc: "Boiler and collector integrated",
    label: "EF023921: Boiler and collector integrated"
  },
  {
    featureId: "EF023922",
    featureDesc: "Thermal tape",
    label: "EF023922: Thermal tape"
  },
  {
    featureId: "EF023927",
    featureDesc: "Nominal diameter condensate",
    label: "EF023927: Nominal diameter condensate"
  },
  {
    featureId: "EF023928",
    featureDesc: "Hot fill",
    label: "EF023928: Hot fill"
  },
  {
    featureId: "EF023929",
    featureDesc: "With off-peak electricity circuit",
    label: "EF023929: With off-peak electricity circuit"
  },
  {
    featureId: "EF023930",
    featureDesc: "Nominal diameter circulation pipe",
    label: "EF023930: Nominal diameter circulation pipe"
  },
  {
    featureId: "EF023934",
    featureDesc: "Collector feed connection",
    label: "EF023934: Collector feed connection"
  },
  {
    featureId: "EF023935",
    featureDesc: "Nominal diameter collector feed",
    label: "EF023935: Nominal diameter collector feed"
  },
  {
    featureId: "EF023936",
    featureDesc: "Nominal diameter collector return",
    label: "EF023936: Nominal diameter collector return"
  },
  {
    featureId: "EF023937",
    featureDesc: "Collector return connection",
    label: "EF023937: Collector return connection"
  },
  {
    featureId: "EF023938",
    featureDesc: "Outer pipe diameter feed collector",
    label: "EF023938: Outer pipe diameter feed collector"
  },
  {
    featureId: "EF023939",
    featureDesc: "Outer pipe diameter return collector",
    label: "EF023939: Outer pipe diameter return collector"
  },
  {
    featureId: "EF023940",
    featureDesc: "Nominal diameter gas connection",
    label: "EF023940: Nominal diameter gas connection"
  },
  {
    featureId: "EF023941",
    featureDesc: "Nominal diameter oil connection",
    label: "EF023941: Nominal diameter oil connection"
  },
  {
    featureId: "EF023942",
    featureDesc: "Tap water boiler volume",
    label: "EF023942: Tap water boiler volume"
  },
  {
    featureId: "EF023943",
    featureDesc: "Collector water in stock",
    label: "EF023943: Collector water in stock"
  },
  {
    featureId: "EF023944",
    featureDesc: "Tap water in stock",
    label: "EF023944: Tap water in stock"
  },
  {
    featureId: "EF023945",
    featureDesc: "With integrated return vessel",
    label: "EF023945: With integrated return vessel"
  },
  {
    featureId: "EF023946",
    featureDesc: "Max. differential pressure at 20 °C",
    label: "EF023946: Max. differential pressure at 20 °C"
  },
  {
    featureId: "EF023947",
    featureDesc: "Nominal diameter of filter housing",
    label: "EF023947: Nominal diameter of filter housing"
  },
  {
    featureId: "EF023949",
    featureDesc: "Size of process connection",
    label: "EF023949: Size of process connection"
  },
  {
    featureId: "EF023950",
    featureDesc: "Scale division (pressure)",
    label: "EF023950: Scale division (pressure)"
  },
  {
    featureId: "EF023953",
    featureDesc: "Lined with plastic",
    label: "EF023953: Lined with plastic"
  },
  {
    featureId: "EF023954",
    featureDesc: "With outlet grille",
    label: "EF023954: With outlet grille"
  },
  {
    featureId: "EF023955",
    featureDesc: "Nominal duct diameter, main duct",
    label: "EF023955: Nominal duct diameter, main duct"
  },
  {
    featureId: "EF023956",
    featureDesc: "Return at zero supply voltage",
    label: "EF023956: Return at zero supply voltage"
  },
  {
    featureId: "EF023957",
    featureDesc: "With diverter unit",
    label: "EF023957: With diverter unit"
  },
  {
    featureId: "EF023958",
    featureDesc: "Developed tray width",
    label: "EF023958: Developed tray width"
  },
  {
    featureId: "EF023960",
    featureDesc: "Response time",
    label: "EF023960: Response time"
  },
  {
    featureId: "EF023961",
    featureDesc: "Efficiency according to EN 779",
    label: "EF023961: Efficiency according to EN 779"
  },
  {
    featureId: "EF023963",
    featureDesc: "Pitch measure",
    label: "EF023963: Pitch measure"
  },
  {
    featureId: "EF023966",
    featureDesc: "Outer connection diameter",
    label: "EF023966: Outer connection diameter"
  },
  {
    featureId: "EF023967",
    featureDesc: "With fan controls",
    label: "EF023967: With fan controls"
  },
  {
    featureId: "EF023968",
    featureDesc: "Height adjusting range",
    label: "EF023968: Height adjusting range"
  },
  {
    featureId: "EF023970",
    featureDesc: "Supply voltage",
    label: "EF023970: Supply voltage"
  },
  {
    featureId: "EF023971",
    featureDesc: "For hose diameter",
    label: "EF023971: For hose diameter"
  },
  {
    featureId: "EF023972",
    featureDesc: "With presetting",
    label: "EF023972: With presetting"
  },
  {
    featureId: "EF023974",
    featureDesc: "Heat emission",
    label: "EF023974: Heat emission"
  },
  {
    featureId: "EF023975",
    featureDesc: "Working length",
    label: "EF023975: Working length"
  },
  {
    featureId: "EF023976",
    featureDesc: "Adjustable zero point",
    label: "EF023976: Adjustable zero point"
  },
  {
    featureId: "EF023977",
    featureDesc: "Tube pressure gauge",
    label: "EF023977: Tube pressure gauge"
  },
  {
    featureId: "EF023978",
    featureDesc: "Includes shut-off valve",
    label: "EF023978: Includes shut-off valve"
  },
  {
    featureId: "EF023979",
    featureDesc: "Zero pressure compensation",
    label: "EF023979: Zero pressure compensation"
  },
  {
    featureId: "EF023980",
    featureDesc: "Distance measurement",
    label: "EF023980: Distance measurement"
  },
  {
    featureId: "EF023981",
    featureDesc: "Video text generator",
    label: "EF023981: Video text generator"
  },
  {
    featureId: "EF023983",
    featureDesc: "Measuring instrument for gas pressure measurement",
    label: "EF023983: Measuring instrument for gas pressure measurement"
  },
  {
    featureId: "EF023984",
    featureDesc: "Measuring instrument for air pressure measurement",
    label: "EF023984: Measuring instrument for air pressure measurement"
  },
  {
    featureId: "EF023986",
    featureDesc: "U-shaped clamping bolt",
    label: "EF023986: U-shaped clamping bolt"
  },
  {
    featureId: "EF023990",
    featureDesc: "Outlet side",
    label: "EF023990: Outlet side"
  },
  {
    featureId: "EF023991",
    featureDesc: "Number of round outlets",
    label: "EF023991: Number of round outlets"
  },
  {
    featureId: "EF023992",
    featureDesc: "Diameter of outlets",
    label: "EF023992: Diameter of outlets"
  },
  {
    featureId: "EF023993",
    featureDesc: "Width of fire damper",
    label: "EF023993: Width of fire damper"
  },
  {
    featureId: "EF023994",
    featureDesc: "Height of fire damper",
    label: "EF023994: Height of fire damper"
  },
  {
    featureId: "EF023995",
    featureDesc: "With air rectifier",
    label: "EF023995: With air rectifier"
  },
  {
    featureId: "EF023996",
    featureDesc: "With mechanical constant-volume controller",
    label: "EF023996: With mechanical constant-volume controller"
  },
  {
    featureId: "EF023997",
    featureDesc: "Nominal duct diameter on inlet side",
    label: "EF023997: Nominal duct diameter on inlet side"
  },
  {
    featureId: "EF023998",
    featureDesc: "Connections on inlet side",
    label: "EF023998: Connections on inlet side"
  },
  {
    featureId: "EF023999",
    featureDesc: "External sensor",
    label: "EF023999: External sensor"
  },
  {
    featureId: "EF024000",
    featureDesc: "Thermal feedback",
    label: "EF024000: Thermal feedback"
  },
  {
    featureId: "EF024001",
    featureDesc: "Adjustable differential",
    label: "EF024001: Adjustable differential"
  },
  {
    featureId: "EF024002",
    featureDesc: "Differential between switch steps adjustable",
    label: "EF024002: Differential between switch steps adjustable"
  },
  {
    featureId: "EF024003",
    featureDesc: "Reduced pressure",
    label: "EF024003: Reduced pressure"
  },
  {
    featureId: "EF024004",
    featureDesc: "With balance weight",
    label: "EF024004: With balance weight"
  },
  {
    featureId: "EF024007",
    featureDesc: "Number of towel bars",
    label: "EF024007: Number of towel bars"
  },
  {
    featureId: "EF024008",
    featureDesc: "With spare roll holder",
    label: "EF024008: With spare roll holder"
  },
  {
    featureId: "EF024009",
    featureDesc: "With flannel hook",
    label: "EF024009: With flannel hook"
  },
  {
    featureId: "EF024011",
    featureDesc: "Threaded model",
    label: "EF024011: Threaded model"
  },
  {
    featureId: "EF024014",
    featureDesc: "Fixed",
    label: "EF024014: Fixed"
  },
  {
    featureId: "EF024016",
    featureDesc: "Scale division (temperature)",
    label: "EF024016: Scale division (temperature)"
  },
  {
    featureId: "EF024017",
    featureDesc: "With Pt 100 element",
    label: "EF024017: With Pt 100 element"
  },
  {
    featureId: "EF024021",
    featureDesc: "Material of process connection/membrane",
    label: "EF024021: Material of process connection/membrane"
  },
  {
    featureId: "EF024022",
    featureDesc: "Scale division (mbar)",
    label: "EF024022: Scale division (mbar)"
  },
  {
    featureId: "EF024023",
    featureDesc: "Electronic time-proportional function",
    label: "EF024023: Electronic time-proportional function"
  },
  {
    featureId: "EF024024",
    featureDesc: "Number of switch steps",
    label: "EF024024: Number of switch steps"
  },
  {
    featureId: "EF024025",
    featureDesc: "Max. pipe diameter",
    label: "EF024025: Max. pipe diameter"
  },
  {
    featureId: "EF024026",
    featureDesc: "Sensor insert length",
    label: "EF024026: Sensor insert length"
  },
  {
    featureId: "EF024027",
    featureDesc: "Sensor insert diameter",
    label: "EF024027: Sensor insert diameter"
  },
  {
    featureId: "EF024028",
    featureDesc: "With immersion tube",
    label: "EF024028: With immersion tube"
  },
  {
    featureId: "EF024030",
    featureDesc: "Measuring range lower value (RH)",
    label: "EF024030: Measuring range lower value (RH)"
  },
  {
    featureId: "EF024031",
    featureDesc: "Measuring range upper value (RH)",
    label: "EF024031: Measuring range upper value (RH)"
  },
  {
    featureId: "EF024032",
    featureDesc: "Differential between switch steps (RH)",
    label: "EF024032: Differential between switch steps (RH)"
  },
  {
    featureId: "EF024034",
    featureDesc: "Measuring range lower value in Pa",
    label: "EF024034: Measuring range lower value in Pa"
  },
  {
    featureId: "EF024035",
    featureDesc: "Measuring range upper value in Pa",
    label: "EF024035: Measuring range upper value in Pa"
  },
  {
    featureId: "EF024036",
    featureDesc: "Measuring range lower value in bar",
    label: "EF024036: Measuring range lower value in bar"
  },
  {
    featureId: "EF024037",
    featureDesc: "Measuring range upper value in bar",
    label: "EF024037: Measuring range upper value in bar"
  },
  {
    featureId: "EF024040",
    featureDesc: "Max. pressure difference in Pa",
    label: "EF024040: Max. pressure difference in Pa"
  },
  {
    featureId: "EF024041",
    featureDesc: "Max. pressure difference in bar",
    label: "EF024041: Max. pressure difference in bar"
  },
  {
    featureId: "EF024042",
    featureDesc: "Thread size shower tap assembly",
    label: "EF024042: Thread size shower tap assembly"
  },
  {
    featureId: "EF024043",
    featureDesc: "Thread size shower hose assembly",
    label: "EF024043: Thread size shower hose assembly"
  },
  {
    featureId: "EF024044",
    featureDesc: "Connection to shower tap",
    label: "EF024044: Connection to shower tap"
  },
  {
    featureId: "EF024045",
    featureDesc: "With sliding and hinge piece",
    label: "EF024045: With sliding and hinge piece"
  },
  {
    featureId: "EF024046",
    featureDesc: "With lotion dispenser",
    label: "EF024046: With lotion dispenser"
  },
  {
    featureId: "EF024047",
    featureDesc: "Length of slider rail",
    label: "EF024047: Length of slider rail"
  },
  {
    featureId: "EF024048",
    featureDesc: "With sponge holder",
    label: "EF024048: With sponge holder"
  },
  {
    featureId: "EF024049",
    featureDesc: "With ring tray",
    label: "EF024049: With ring tray"
  },
  {
    featureId: "EF024050",
    featureDesc: "Hairdresser's design",
    label: "EF024050: Hairdresser's design"
  },
  {
    featureId: "EF024051",
    featureDesc: "With beakers/glasses",
    label: "EF024051: With beakers/glasses"
  },
  {
    featureId: "EF024052",
    featureDesc: "Material beakers/glasses",
    label: "EF024052: Material beakers/glasses"
  },
  {
    featureId: "EF024056",
    featureDesc: "Designed acc. to NEN 7203",
    label: "EF024056: Designed acc. to NEN 7203"
  },
  {
    featureId: "EF024057",
    featureDesc: "Surface protection inner pipe",
    label: "EF024057: Surface protection inner pipe"
  },
  {
    featureId: "EF024058",
    featureDesc: "Surface protection outer pipe",
    label: "EF024058: Surface protection outer pipe"
  },
  {
    featureId: "EF024059",
    featureDesc: "Wall thickness inner pipe",
    label: "EF024059: Wall thickness inner pipe"
  },
  {
    featureId: "EF024060",
    featureDesc: "Wall thickness outer pipe",
    label: "EF024060: Wall thickness outer pipe"
  },
  {
    featureId: "EF024063",
    featureDesc: "Surface treatment outer pipe",
    label: "EF024063: Surface treatment outer pipe"
  },
  {
    featureId: "EF024064",
    featureDesc: "Inner pipe-inner pipe connection",
    label: "EF024064: Inner pipe-inner pipe connection"
  },
  {
    featureId: "EF024066",
    featureDesc: "Flue gas and air supply pipe diameter",
    label: "EF024066: Flue gas and air supply pipe diameter"
  },
  {
    featureId: "EF024067",
    featureDesc: "Outer pipe-outer pipe connection",
    label: "EF024067: Outer pipe-outer pipe connection"
  },
  {
    featureId: "EF024069",
    featureDesc: "Internally smooth",
    label: "EF024069: Internally smooth"
  },
  {
    featureId: "EF024071",
    featureDesc: "Only suitable for air supply",
    label: "EF024071: Only suitable for air supply"
  },
  {
    featureId: "EF024074",
    featureDesc: "Quality class inner pipe",
    label: "EF024074: Quality class inner pipe"
  },
  {
    featureId: "EF024075",
    featureDesc: "Tumbler",
    label: "EF024075: Tumbler"
  },
  {
    featureId: "EF024076",
    featureDesc: "Enlarging mirror",
    label: "EF024076: Enlarging mirror"
  },
  {
    featureId: "EF024078",
    featureDesc: "Square design",
    label: "EF024078: Square design"
  },
  {
    featureId: "EF024079",
    featureDesc: "Surface/washbasin assembly",
    label: "EF024079: Surface/washbasin assembly"
  },
  {
    featureId: "EF024080",
    featureDesc: "Number of rolls",
    label: "EF024080: Number of rolls"
  },
  {
    featureId: "EF024085",
    featureDesc: "Suspended from shower/bath side",
    label: "EF024085: Suspended from shower/bath side"
  },
  {
    featureId: "EF024086",
    featureDesc: "With tube holder",
    label: "EF024086: With tube holder"
  },
  {
    featureId: "EF024087",
    featureDesc: "With magnet",
    label: "EF024087: With magnet"
  },
  {
    featureId: "EF024088",
    featureDesc: "Number of toothbrushes",
    label: "EF024088: Number of toothbrushes"
  },
  {
    featureId: "EF024089",
    featureDesc: "With toothbrush holder",
    label: "EF024089: With toothbrush holder"
  },
  {
    featureId: "EF024090",
    featureDesc: "Number of washing lines",
    label: "EF024090: Number of washing lines"
  },
  {
    featureId: "EF024092",
    featureDesc: "Vertical mounting",
    label: "EF024092: Vertical mounting"
  },
  {
    featureId: "EF024093",
    featureDesc: "Min. specific density of liquid",
    label: "EF024093: Min. specific density of liquid"
  },
  {
    featureId: "EF024097",
    featureDesc: "Surface protection inner side",
    label: "EF024097: Surface protection inner side"
  },
  {
    featureId: "EF024106",
    featureDesc: "Outer diameter connection 3",
    label: "EF024106: Outer diameter connection 3"
  },
  {
    featureId: "EF024107",
    featureDesc: "Differential between switch steps in Pa",
    label: "EF024107: Differential between switch steps in Pa"
  },
  {
    featureId: "EF024108",
    featureDesc: "Differential between switch steps in bar",
    label: "EF024108: Differential between switch steps in bar"
  },
  {
    featureId: "EF024109",
    featureDesc: "Auxiliary contacts",
    label: "EF024109: Auxiliary contacts"
  },
  {
    featureId: "EF024110",
    featureDesc: "End contacts",
    label: "EF024110: End contacts"
  },
  {
    featureId: "EF024111",
    featureDesc: "Adjusting force",
    label: "EF024111: Adjusting force"
  },
  {
    featureId: "EF024112",
    featureDesc: "Self-adjusting travel length",
    label: "EF024112: Self-adjusting travel length"
  },
  {
    featureId: "EF024113",
    featureDesc: "Max. pressure difference in opened position",
    label: "EF024113: Max. pressure difference in opened position"
  },
  {
    featureId: "EF024114",
    featureDesc: "Leakage loss of Kvs value",
    label: "EF024114: Leakage loss of Kvs value"
  },
  {
    featureId: "EF024115",
    featureDesc: "Control ratio (Svo)",
    label: "EF024115: Control ratio (Svo)"
  },
  {
    featureId: "EF024116",
    featureDesc: "Characteristic curve",
    label: "EF024116: Characteristic curve"
  },
  {
    featureId: "EF024117",
    featureDesc: "Closing pressure",
    label: "EF024117: Closing pressure"
  },
  {
    featureId: "EF024118",
    featureDesc: "Pressureless opening (NO)",
    label: "EF024118: Pressureless opening (NO)"
  },
  {
    featureId: "EF024119",
    featureDesc: "Production method for inner pipe",
    label: "EF024119: Production method for inner pipe"
  },
  {
    featureId: "EF024120",
    featureDesc: "Production method for outer pipe",
    label: "EF024120: Production method for outer pipe"
  },
  {
    featureId: "EF024122",
    featureDesc: "Angle of connection 3 (branch angle)",
    label: "EF024122: Angle of connection 3 (branch angle)"
  },
  {
    featureId: "EF024123",
    featureDesc: "Positive (overpressure)",
    label: "EF024123: Positive (overpressure)"
  },
  {
    featureId: "EF024124",
    featureDesc: "Wet (condensing)",
    label: "EF024124: Wet (condensing)"
  },
  {
    featureId: "EF024125",
    featureDesc: "Negative (negative pressure)",
    label: "EF024125: Negative (negative pressure)"
  },
  {
    featureId: "EF024126",
    featureDesc: "Dry (non-condensing)",
    label: "EF024126: Dry (non-condensing)"
  },
  {
    featureId: "EF024127",
    featureDesc: "Tap water connection",
    label: "EF024127: Tap water connection"
  },
  {
    featureId: "EF024128",
    featureDesc: "KVS value for bypass",
    label: "EF024128: KVS value for bypass"
  },
  {
    featureId: "EF024129",
    featureDesc: "Leakage loss of KVS value for bypass",
    label: "EF024129: Leakage loss of KVS value for bypass"
  },
  {
    featureId: "EF024130",
    featureDesc: "Characteristic curve for bypass",
    label: "EF024130: Characteristic curve for bypass"
  },
  {
    featureId: "EF024131",
    featureDesc: "Installation method, distributive",
    label: "EF024131: Installation method, distributive"
  },
  {
    featureId: "EF024132",
    featureDesc: "Diameter of single-walled system",
    label: "EF024132: Diameter of single-walled system"
  },
  {
    featureId: "EF024135",
    featureDesc: "Inner diameter of double-walled system",
    label: "EF024135: Inner diameter of double-walled system"
  },
  {
    featureId: "EF024136",
    featureDesc: "Icicle/icing-free",
    label: "EF024136: Icicle/icing-free"
  },
  {
    featureId: "EF024137",
    featureDesc: "With mechanical ventilation connection",
    label: "EF024137: With mechanical ventilation connection"
  },
  {
    featureId: "EF024138",
    featureDesc: "Diameter, mechanical ventilation connection",
    label: "EF024138: Diameter, mechanical ventilation connection"
  },
  {
    featureId: "EF024139",
    featureDesc: "Colour on the roof",
    label: "EF024139: Colour on the roof"
  },
  {
    featureId: "EF024140",
    featureDesc: "RAL-number on the roof",
    label: "EF024140: RAL-number on the roof"
  },
  {
    featureId: "EF024141",
    featureDesc: "Colour below the roof",
    label: "EF024141: Colour below the roof"
  },
  {
    featureId: "EF024142",
    featureDesc: "RAL-number below the roof",
    label: "EF024142: RAL-number below the roof"
  },
  {
    featureId: "EF024143",
    featureDesc: "Housing material quality",
    label: "EF024143: Housing material quality"
  },
  {
    featureId: "EF024144",
    featureDesc: "Material impeller",
    label: "EF024144: Material impeller"
  },
  {
    featureId: "EF024145",
    featureDesc: "Axial model",
    label: "EF024145: Axial model"
  },
  {
    featureId: "EF024146",
    featureDesc: "Air supply",
    label: "EF024146: Air supply"
  },
  {
    featureId: "EF024149",
    featureDesc: "Material quality impeller/pump wheel",
    label: "EF024149: Material quality impeller/pump wheel"
  },
  {
    featureId: "EF024150",
    featureDesc: "Surface protection housing",
    label: "EF024150: Surface protection housing"
  },
  {
    featureId: "EF024151",
    featureDesc: "Surface protection impeller",
    label: "EF024151: Surface protection impeller"
  },
  {
    featureId: "EF024163",
    featureDesc: "Air supply roof fan",
    label: "EF024163: Air supply roof fan"
  },
  {
    featureId: "EF024166",
    featureDesc: "Exhaust direction",
    label: "EF024166: Exhaust direction"
  },
  {
    featureId: "EF024169",
    featureDesc: "With maintenance-/service switch",
    label: "EF024169: With maintenance-/service switch"
  },
  {
    featureId: "EF024172",
    featureDesc: "Fitting connection piece",
    label: "EF024172: Fitting connection piece"
  },
  {
    featureId: "EF024173",
    featureDesc: "Pipe connection",
    label: "EF024173: Pipe connection"
  },
  {
    featureId: "EF024176",
    featureDesc: "Pressed",
    label: "EF024176: Pressed"
  },
  {
    featureId: "EF024177",
    featureDesc: "Without gauze",
    label: "EF024177: Without gauze"
  },
  {
    featureId: "EF024181",
    featureDesc: "Constant pressure",
    label: "EF024181: Constant pressure"
  },
  {
    featureId: "EF024183",
    featureDesc: "Diameter connection 1",
    label: "EF024183: Diameter connection 1"
  },
  {
    featureId: "EF024184",
    featureDesc: "Diameter connection 2",
    label: "EF024184: Diameter connection 2"
  },
  {
    featureId: "EF024185",
    featureDesc: "With protection cloth",
    label: "EF024185: With protection cloth"
  },
  {
    featureId: "EF024186",
    featureDesc: "Damping 63 Hz",
    label: "EF024186: Damping 63 Hz"
  },
  {
    featureId: "EF024187",
    featureDesc: "Damping 8000 Hz",
    label: "EF024187: Damping 8000 Hz"
  },
  {
    featureId: "EF024188",
    featureDesc: "Resonance plate",
    label: "EF024188: Resonance plate"
  },
  {
    featureId: "EF024189",
    featureDesc: "With flow approach cap",
    label: "EF024189: With flow approach cap"
  },
  {
    featureId: "EF024190",
    featureDesc: "With grease trap",
    label: "EF024190: With grease trap"
  },
  {
    featureId: "EF024192",
    featureDesc: "Effective filter surface area",
    label: "EF024192: Effective filter surface area"
  },
  {
    featureId: "EF024193",
    featureDesc: "Number of envelopes (bags)",
    label: "EF024193: Number of envelopes (bags)"
  },
  {
    featureId: "EF024194",
    featureDesc: "Material inner casing",
    label: "EF024194: Material inner casing"
  },
  {
    featureId: "EF024196",
    featureDesc: "Suitable for carbon cylinder(s)",
    label: "EF024196: Suitable for carbon cylinder(s)"
  },
  {
    featureId: "EF024198",
    featureDesc: "Suitable for carbon mats",
    label: "EF024198: Suitable for carbon mats"
  },
  {
    featureId: "EF024202",
    featureDesc: "Outer size of adhesive edge",
    label: "EF024202: Outer size of adhesive edge"
  },
  {
    featureId: "EF024204",
    featureDesc: "Round model",
    label: "EF024204: Round model"
  },
  {
    featureId: "EF024205",
    featureDesc: "Adjustable position feedback signal",
    label: "EF024205: Adjustable position feedback signal"
  },
  {
    featureId: "EF024206",
    featureDesc: "With quick-lock",
    label: "EF024206: With quick-lock"
  },
  {
    featureId: "EF024208",
    featureDesc: "Suitable for bag filter",
    label: "EF024208: Suitable for bag filter"
  },
  {
    featureId: "EF024209",
    featureDesc: "Suitable for cartridge filter",
    label: "EF024209: Suitable for cartridge filter"
  },
  {
    featureId: "EF024210",
    featureDesc: "Max. depth",
    label: "EF024210: Max. depth"
  },
  {
    featureId: "EF024211",
    featureDesc: "Height of filter element",
    label: "EF024211: Height of filter element"
  },
  {
    featureId: "EF024212",
    featureDesc: "Width of filter element",
    label: "EF024212: Width of filter element"
  },
  {
    featureId: "EF024213",
    featureDesc: "Depth of filter element",
    label: "EF024213: Depth of filter element"
  },
  {
    featureId: "EF024214",
    featureDesc: "Number of filter elements",
    label: "EF024214: Number of filter elements"
  },
  {
    featureId: "EF024216",
    featureDesc: "Diameter of cartridges",
    label: "EF024216: Diameter of cartridges"
  },
  {
    featureId: "EF024217",
    featureDesc: "With suspension hooks",
    label: "EF024217: With suspension hooks"
  },
  {
    featureId: "EF024218",
    featureDesc: "With absorption material",
    label: "EF024218: With absorption material"
  },
  {
    featureId: "EF024219",
    featureDesc: "Base size, internal, square",
    label: "EF024219: Base size, internal, square"
  },
  {
    featureId: "EF024220",
    featureDesc: "Base size, internal, round",
    label: "EF024220: Base size, internal, round"
  },
  {
    featureId: "EF024223",
    featureDesc: "Sound pressure level at 4 m (outdoor) at Qmax",
    label: "EF024223: Sound pressure level at 4 m (outdoor) at Qmax"
  },
  {
    featureId: "EF024224",
    featureDesc: "Opening dimension",
    label: "EF024224: Opening dimension"
  },
  {
    featureId: "EF024225",
    featureDesc: "With flap",
    label: "EF024225: With flap"
  },
  {
    featureId: "EF024226",
    featureDesc: "Single-speed motor",
    label: "EF024226: Single-speed motor"
  },
  {
    featureId: "EF024227",
    featureDesc: "Single-speed motor controllable with",
    label: "EF024227: Single-speed motor controllable with"
  },
  {
    featureId: "EF024228",
    featureDesc: "Multi-speed motor with",
    label: "EF024228: Multi-speed motor with"
  },
  {
    featureId: "EF024229",
    featureDesc: "Back draught shutter",
    label: "EF024229: Back draught shutter"
  },
  {
    featureId: "EF024230",
    featureDesc: "Fan tiltable",
    label: "EF024230: Fan tiltable"
  },
  {
    featureId: "EF024231",
    featureDesc: "Specific fan power at Qmax",
    label: "EF024231: Specific fan power at Qmax"
  },
  {
    featureId: "EF024232",
    featureDesc: "Heavier model",
    label: "EF024232: Heavier model"
  },
  {
    featureId: "EF024233",
    featureDesc: "With lead-through bush, top/bottom",
    label: "EF024233: With lead-through bush, top/bottom"
  },
  {
    featureId: "EF024234",
    featureDesc: "Suitable for longitudinal folded joint",
    label: "EF024234: Suitable for longitudinal folded joint"
  },
  {
    featureId: "EF024235",
    featureDesc: "Suitable for longitudinal welded joint",
    label: "EF024235: Suitable for longitudinal welded joint"
  },
  {
    featureId: "EF024236",
    featureDesc: "For flexible sleeve",
    label: "EF024236: For flexible sleeve"
  },
  {
    featureId: "EF024238",
    featureDesc: "Cover outer diameter",
    label: "EF024238: Cover outer diameter"
  },
  {
    featureId: "EF024239",
    featureDesc: "Vertical exhausting",
    label: "EF024239: Vertical exhausting"
  },
  {
    featureId: "EF024240",
    featureDesc: "Suitable for suction",
    label: "EF024240: Suitable for suction"
  },
  {
    featureId: "EF024241",
    featureDesc: "Material quadrant",
    label: "EF024241: Material quadrant"
  },
  {
    featureId: "EF024243",
    featureDesc: "Suitable for servomotor",
    label: "EF024243: Suitable for servomotor"
  },
  {
    featureId: "EF024244",
    featureDesc: "Motor speed (nominal) in rpm",
    label: "EF024244: Motor speed (nominal) in rpm"
  },
  {
    featureId: "EF024245",
    featureDesc: "Cover design",
    label: "EF024245: Cover design"
  },
  {
    featureId: "EF024246",
    featureDesc: "Air supply volume at 50 Pa",
    label: "EF024246: Air supply volume at 50 Pa"
  },
  {
    featureId: "EF024247",
    featureDesc: "Air supply volume at 100 Pa",
    label: "EF024247: Air supply volume at 100 Pa"
  },
  {
    featureId: "EF024248",
    featureDesc: "Air supply volume at 150 Pa",
    label: "EF024248: Air supply volume at 150 Pa"
  },
  {
    featureId: "EF024249",
    featureDesc: "Air extraction volume at 50 Pa",
    label: "EF024249: Air extraction volume at 50 Pa"
  },
  {
    featureId: "EF024250",
    featureDesc: "Air extraction volume at 100 Pa",
    label: "EF024250: Air extraction volume at 100 Pa"
  },
  {
    featureId: "EF024251",
    featureDesc: "Air extraction volume at 150 Pa",
    label: "EF024251: Air extraction volume at 150 Pa"
  },
  {
    featureId: "EF024252",
    featureDesc: "With butterfly valve",
    label: "EF024252: With butterfly valve"
  },
  {
    featureId: "EF024253",
    featureDesc: "With valve section",
    label: "EF024253: With valve section"
  },
  {
    featureId: "EF024254",
    featureDesc: "Valve controls",
    label: "EF024254: Valve controls"
  },
  {
    featureId: "EF024255",
    featureDesc: "Roof fan pitch measure",
    label: "EF024255: Roof fan pitch measure"
  },
  {
    featureId: "EF024259",
    featureDesc: "Height of inspection opening (duct top)",
    label: "EF024259: Height of inspection opening (duct top)"
  },
  {
    featureId: "EF024260",
    featureDesc: "Asymmetrical",
    label: "EF024260: Asymmetrical"
  },
  {
    featureId: "EF024261",
    featureDesc: "Working height of saddle (from duct)",
    label: "EF024261: Working height of saddle (from duct)"
  },
  {
    featureId: "EF024262",
    featureDesc: "Valve butterfly with rubber",
    label: "EF024262: Valve butterfly with rubber"
  },
  {
    featureId: "EF024263",
    featureDesc: "Smaller valve butterfly",
    label: "EF024263: Smaller valve butterfly"
  },
  {
    featureId: "EF024268",
    featureDesc: "Air extraction",
    label: "EF024268: Air extraction"
  },
  {
    featureId: "EF024270",
    featureDesc: "Head size, internal",
    label: "EF024270: Head size, internal"
  },
  {
    featureId: "EF024271",
    featureDesc: "Head size, external",
    label: "EF024271: Head size, external"
  },
  {
    featureId: "EF024272",
    featureDesc: "Pitched roof (max. 65°)",
    label: "EF024272: Pitched roof (max. 65°)"
  },
  {
    featureId: "EF024273",
    featureDesc: "Roof sealing",
    label: "EF024273: Roof sealing"
  },
  {
    featureId: "EF024274",
    featureDesc: "Diameter duct connection",
    label: "EF024274: Diameter duct connection"
  },
  {
    featureId: "EF024275",
    featureDesc: "Air volume at 50 Pa pressure loss",
    label: "EF024275: Air volume at 50 Pa pressure loss"
  },
  {
    featureId: "EF024276",
    featureDesc: "Outer size of sound absorber",
    label: "EF024276: Outer size of sound absorber"
  },
  {
    featureId: "EF024277",
    featureDesc: "Outer dimensions",
    label: "EF024277: Outer dimensions"
  },
  {
    featureId: "EF024280",
    featureDesc: "Adjustable differential gap",
    label: "EF024280: Adjustable differential gap"
  },
  {
    featureId: "EF024281",
    featureDesc: "Differential range/switch hysteresis",
    label: "EF024281: Differential range/switch hysteresis"
  },
  {
    featureId: "EF024283",
    featureDesc: "Suitable for potable water",
    label: "EF024283: Suitable for potable water"
  },
  {
    featureId: "EF024284",
    featureDesc: "Suitable for rainwater",
    label: "EF024284: Suitable for rainwater"
  },
  {
    featureId: "EF024285",
    featureDesc: "Suitable for cooling water",
    label: "EF024285: Suitable for cooling water"
  },
  {
    featureId: "EF024286",
    featureDesc: "Suitable for faeces",
    label: "EF024286: Suitable for faeces"
  },
  {
    featureId: "EF024287",
    featureDesc: "Suitable for domestic wastewater",
    label: "EF024287: Suitable for domestic wastewater"
  },
  {
    featureId: "EF024288",
    featureDesc: "Suitable for condensate",
    label: "EF024288: Suitable for condensate"
  },
  {
    featureId: "EF024289",
    featureDesc: "Differential range/switch hysteresis 2",
    label: "EF024289: Differential range/switch hysteresis 2"
  },
  {
    featureId: "EF024292",
    featureDesc: "Diaphragm valve outer diameter",
    label: "EF024292: Diaphragm valve outer diameter"
  },
  {
    featureId: "EF024293",
    featureDesc: "With spring lock",
    label: "EF024293: With spring lock"
  },
  {
    featureId: "EF024294",
    featureDesc: "Installation also in horizontal duct",
    label: "EF024294: Installation also in horizontal duct"
  },
  {
    featureId: "EF024300",
    featureDesc: "Fixed model",
    label: "EF024300: Fixed model"
  },
  {
    featureId: "EF024301",
    featureDesc: "Nominal pipe diameter",
    label: "EF024301: Nominal pipe diameter"
  },
  {
    featureId: "EF024302",
    featureDesc: "With strengthening ridge",
    label: "EF024302: With strengthening ridge"
  },
  {
    featureId: "EF024303",
    featureDesc: "Height of filling duct",
    label: "EF024303: Height of filling duct"
  },
  {
    featureId: "EF024304",
    featureDesc: "Compensated for outside temperature",
    label: "EF024304: Compensated for outside temperature"
  },
  {
    featureId: "EF024307",
    featureDesc: "Thread size (inch)",
    label: "EF024307: Thread size (inch)"
  },
  {
    featureId: "EF024310",
    featureDesc: "Retractable",
    label: "EF024310: Retractable"
  },
  {
    featureId: "EF024311",
    featureDesc: "Outflow construction",
    label: "EF024311: Outflow construction"
  },
  {
    featureId: "EF024312",
    featureDesc: "Material flue pipe",
    label: "EF024312: Material flue pipe"
  },
  {
    featureId: "EF024313",
    featureDesc: "Material air supply pipe",
    label: "EF024313: Material air supply pipe"
  },
  {
    featureId: "EF024314",
    featureDesc: "Quality class flue pipe",
    label: "EF024314: Quality class flue pipe"
  },
  {
    featureId: "EF024315",
    featureDesc: "Surface protection flue",
    label: "EF024315: Surface protection flue"
  },
  {
    featureId: "EF024316",
    featureDesc: "Surface protection air supply pipe",
    label: "EF024316: Surface protection air supply pipe"
  },
  {
    featureId: "EF024317",
    featureDesc: "Surface treatment air supply pipe",
    label: "EF024317: Surface treatment air supply pipe"
  },
  {
    featureId: "EF024318",
    featureDesc: "Façade side colour",
    label: "EF024318: Façade side colour"
  },
  {
    featureId: "EF024319",
    featureDesc: "RAL-number façade side",
    label: "EF024319: RAL-number façade side"
  },
  {
    featureId: "EF024320",
    featureDesc: "RAL-number inside",
    label: "EF024320: RAL-number inside"
  },
  {
    featureId: "EF024321",
    featureDesc: "Inside colour",
    label: "EF024321: Inside colour"
  },
  {
    featureId: "EF024322",
    featureDesc: "Façade side length",
    label: "EF024322: Façade side length"
  },
  {
    featureId: "EF024323",
    featureDesc: "Flue diameter",
    label: "EF024323: Flue diameter"
  },
  {
    featureId: "EF024324",
    featureDesc: "Number of tiles",
    label: "EF024324: Number of tiles"
  },
  {
    featureId: "EF024326",
    featureDesc: "With sliding plate",
    label: "EF024326: With sliding plate"
  },
  {
    featureId: "EF024327",
    featureDesc: "System diameter",
    label: "EF024327: System diameter"
  },
  {
    featureId: "EF024328",
    featureDesc: "Material plate",
    label: "EF024328: Material plate"
  },
  {
    featureId: "EF024329",
    featureDesc: "Suitable for flat roof",
    label: "EF024329: Suitable for flat roof"
  },
  {
    featureId: "EF024330",
    featureDesc: "Suitable for low-pitched roof",
    label: "EF024330: Suitable for low-pitched roof"
  },
  {
    featureId: "EF024331",
    featureDesc: "Suitable for tiled roof",
    label: "EF024331: Suitable for tiled roof"
  },
  {
    featureId: "EF024332",
    featureDesc: "Base plate",
    label: "EF024332: Base plate"
  },
  {
    featureId: "EF024333",
    featureDesc: "With sliding/rain plate",
    label: "EF024333: With sliding/rain plate"
  },
  {
    featureId: "EF024334",
    featureDesc: "With round connection, 80 mm",
    label: "EF024334: With round connection, 80 mm"
  },
  {
    featureId: "EF024335",
    featureDesc: "Vertical model",
    label: "EF024335: Vertical model"
  },
  {
    featureId: "EF024339",
    featureDesc: "45-degree model",
    label: "EF024339: 45-degree model"
  },
  {
    featureId: "EF024341",
    featureDesc: "Duct size, branch 1",
    label: "EF024341: Duct size, branch 1"
  },
  {
    featureId: "EF024342",
    featureDesc: "Duct size, branch 2",
    label: "EF024342: Duct size, branch 2"
  },
  {
    featureId: "EF024343",
    featureDesc: "Pot height",
    label: "EF024343: Pot height"
  },
  {
    featureId: "EF024344",
    featureDesc: "Underside of pot/underside of branch",
    label: "EF024344: Underside of pot/underside of branch"
  },
  {
    featureId: "EF024346",
    featureDesc: "Bush height",
    label: "EF024346: Bush height"
  },
  {
    featureId: "EF024347",
    featureDesc: "Nominal duct diameter, underside",
    label: "EF024347: Nominal duct diameter, underside"
  },
  {
    featureId: "EF024348",
    featureDesc: "Nominal duct diameter, top",
    label: "EF024348: Nominal duct diameter, top"
  },
  {
    featureId: "EF024349",
    featureDesc: "System connection, underside/top",
    label: "EF024349: System connection, underside/top"
  },
  {
    featureId: "EF024350",
    featureDesc: "With measuring points",
    label: "EF024350: With measuring points"
  },
  {
    featureId: "EF024361",
    featureDesc: "Suitable for wireless remote control",
    label: "EF024361: Suitable for wireless remote control"
  },
  {
    featureId: "EF024362",
    featureDesc: "With digital display",
    label: "EF024362: With digital display"
  },
  {
    featureId: "EF024364",
    featureDesc: "Residential connection, underside",
    label: "EF024364: Residential connection, underside"
  },
  {
    featureId: "EF024365",
    featureDesc: "Residential connection, left side",
    label: "EF024365: Residential connection, left side"
  },
  {
    featureId: "EF024366",
    featureDesc: "Residential connection, right side",
    label: "EF024366: Residential connection, right side"
  },
  {
    featureId: "EF024367",
    featureDesc: "Residential connection, top",
    label: "EF024367: Residential connection, top"
  },
  {
    featureId: "EF024368",
    featureDesc: "Roof connection, side",
    label: "EF024368: Roof connection, side"
  },
  {
    featureId: "EF024369",
    featureDesc: "Roof connection, top",
    label: "EF024369: Roof connection, top"
  },
  {
    featureId: "EF024374",
    featureDesc: "Underside bush/centre branch",
    label: "EF024374: Underside bush/centre branch"
  },
  {
    featureId: "EF024375",
    featureDesc: "Suitable for requirement-controlled ventilation",
    label: "EF024375: Suitable for requirement-controlled ventilation"
  },
  {
    featureId: "EF024376",
    featureDesc: "Controls, 2 positions",
    label: "EF024376: Controls, 2 positions"
  },
  {
    featureId: "EF024377",
    featureDesc: "2-position setting",
    label: "EF024377: 2-position setting"
  },
  {
    featureId: "EF024378",
    featureDesc: "Device emplacement",
    label: "EF024378: Device emplacement"
  },
  {
    featureId: "EF024379",
    featureDesc: "Nominal duct width indoor connection",
    label: "EF024379: Nominal duct width indoor connection"
  },
  {
    featureId: "EF024380",
    featureDesc: "Nominal duct height indoor connection",
    label: "EF024380: Nominal duct height indoor connection"
  },
  {
    featureId: "EF024381",
    featureDesc: "Nominal duct width, outdoor connection",
    label: "EF024381: Nominal duct width, outdoor connection"
  },
  {
    featureId: "EF024382",
    featureDesc: "Nominal duct height, outdoor connection",
    label: "EF024382: Nominal duct height, outdoor connection"
  },
  {
    featureId: "EF024383",
    featureDesc: "Suitable for chimney",
    label: "EF024383: Suitable for chimney"
  },
  {
    featureId: "EF024384",
    featureDesc: "Suitable for roof upstand",
    label: "EF024384: Suitable for roof upstand"
  },
  {
    featureId: "EF024385",
    featureDesc: "Extended model",
    label: "EF024385: Extended model"
  },
  {
    featureId: "EF024390",
    featureDesc: "Measuring method",
    label: "EF024390: Measuring method"
  },
  {
    featureId: "EF024391",
    featureDesc: "Protection for local controls",
    label: "EF024391: Protection for local controls"
  },
  {
    featureId: "EF024392",
    featureDesc: "Failsafe model",
    label: "EF024392: Failsafe model"
  },
  {
    featureId: "EF024394",
    featureDesc: "Standard applications available",
    label: "EF024394: Standard applications available"
  },
  {
    featureId: "EF024395",
    featureDesc: "Underside pot/centre branch",
    label: "EF024395: Underside pot/centre branch"
  },
  {
    featureId: "EF024396",
    featureDesc: "With foamed-in bracket",
    label: "EF024396: With foamed-in bracket"
  },
  {
    featureId: "EF024397",
    featureDesc: "Sliding material",
    label: "EF024397: Sliding material"
  },
  {
    featureId: "EF024398",
    featureDesc: "Centre distance of connection facilities adjustable",
    label: "EF024398: Centre distance of connection facilities adjustable"
  },
  {
    featureId: "EF024402",
    featureDesc: "Air treatment application",
    label: "EF024402: Air treatment application"
  },
  {
    featureId: "EF024403",
    featureDesc: "Special application",
    label: "EF024403: Special application"
  },
  {
    featureId: "EF024404",
    featureDesc: "Tap water application",
    label: "EF024404: Tap water application"
  },
  {
    featureId: "EF024405",
    featureDesc: "Option for configuring controller",
    label: "EF024405: Option for configuring controller"
  },
  {
    featureId: "EF024406",
    featureDesc: "Freely programmable display",
    label: "EF024406: Freely programmable display"
  },
  {
    featureId: "EF024407",
    featureDesc: "Suitable for central heating boiler connection",
    label: "EF024407: Suitable for central heating boiler connection"
  },
  {
    featureId: "EF024408",
    featureDesc: "Outer pipe connection with lip ring",
    label: "EF024408: Outer pipe connection with lip ring"
  },
  {
    featureId: "EF024409",
    featureDesc: "Outer pipe connection with clamping strip",
    label: "EF024409: Outer pipe connection with clamping strip"
  },
  {
    featureId: "EF024410",
    featureDesc: "With inner pipe",
    label: "EF024410: With inner pipe"
  },
  {
    featureId: "EF024411",
    featureDesc: "Material pipe end",
    label: "EF024411: Material pipe end"
  },
  {
    featureId: "EF024412",
    featureDesc: "Side without protection",
    label: "EF024412: Side without protection"
  },
  {
    featureId: "EF024413",
    featureDesc: "With bird protection",
    label: "EF024413: With bird protection"
  },
  {
    featureId: "EF024414",
    featureDesc: "With spark protection wire",
    label: "EF024414: With spark protection wire"
  },
  {
    featureId: "EF024415",
    featureDesc: "Top exhaust (deflector)",
    label: "EF024415: Top exhaust (deflector)"
  },
  {
    featureId: "EF024416",
    featureDesc: "Pipe diameter of air relief cover",
    label: "EF024416: Pipe diameter of air relief cover"
  },
  {
    featureId: "EF024417",
    featureDesc: "Bracket model",
    label: "EF024417: Bracket model"
  },
  {
    featureId: "EF024419",
    featureDesc: "Wind resistant",
    label: "EF024419: Wind resistant"
  },
  {
    featureId: "EF024420",
    featureDesc: "Suitable for extra wind support",
    label: "EF024420: Suitable for extra wind support"
  },
  {
    featureId: "EF024421",
    featureDesc: "Distance of mounting holes",
    label: "EF024421: Distance of mounting holes"
  },
  {
    featureId: "EF024422",
    featureDesc: "Unequal mounting possible",
    label: "EF024422: Unequal mounting possible"
  },
  {
    featureId: "EF024424",
    featureDesc: "Hinging attachment",
    label: "EF024424: Hinging attachment"
  },
  {
    featureId: "EF024425",
    featureDesc: "Suitable for chimney diameter",
    label: "EF024425: Suitable for chimney diameter"
  },
  {
    featureId: "EF024426",
    featureDesc: "Square size",
    label: "EF024426: Square size"
  },
  {
    featureId: "EF024427",
    featureDesc: "Bracket clamp diameter",
    label: "EF024427: Bracket clamp diameter"
  },
  {
    featureId: "EF024428",
    featureDesc: "Max. bracket distance for wind support",
    label: "EF024428: Max. bracket distance for wind support"
  },
  {
    featureId: "EF024429",
    featureDesc: "Adjustment range for wall/rear of bracket",
    label: "EF024429: Adjustment range for wall/rear of bracket"
  },
  {
    featureId: "EF024430",
    featureDesc: "Strengthening plate on bracket",
    label: "EF024430: Strengthening plate on bracket"
  },
  {
    featureId: "EF024431",
    featureDesc: "Unequal wall mounting possible",
    label: "EF024431: Unequal wall mounting possible"
  },
  {
    featureId: "EF024433",
    featureDesc: "Adjustment range for wall/top",
    label: "EF024433: Adjustment range for wall/top"
  },
  {
    featureId: "EF024434",
    featureDesc: "Distance of bracket mounting holes",
    label: "EF024434: Distance of bracket mounting holes"
  },
  {
    featureId: "EF024435",
    featureDesc: "Can be connected to",
    label: "EF024435: Can be connected to"
  },
  {
    featureId: "EF024436",
    featureDesc: "Flue tube diameter",
    label: "EF024436: Flue tube diameter"
  },
  {
    featureId: "EF024438",
    featureDesc: "Adjustment range for wall/rear of flue",
    label: "EF024438: Adjustment range for wall/rear of flue"
  },
  {
    featureId: "EF024439",
    featureDesc: "Air volume at 200 Pa (static)",
    label: "EF024439: Air volume at 200 Pa (static)"
  },
  {
    featureId: "EF024440",
    featureDesc: "Air volume at 400 Pa (static)",
    label: "EF024440: Air volume at 400 Pa (static)"
  },
  {
    featureId: "EF024441",
    featureDesc: "Air volume at 600 Pa (static)",
    label: "EF024441: Air volume at 600 Pa (static)"
  },
  {
    featureId: "EF024442",
    featureDesc: "Air volume at 800 Pa (static)",
    label: "EF024442: Air volume at 800 Pa (static)"
  },
  {
    featureId: "EF024443",
    featureDesc: "Air volume at 1000 Pa (static)",
    label: "EF024443: Air volume at 1000 Pa (static)"
  },
  {
    featureId: "EF024444",
    featureDesc: "Air volume at 1500 Pa (static)",
    label: "EF024444: Air volume at 1500 Pa (static)"
  },
  {
    featureId: "EF024445",
    featureDesc: "Air volume at 2000 Pa (static)",
    label: "EF024445: Air volume at 2000 Pa (static)"
  },
  {
    featureId: "EF024446",
    featureDesc: "Air volume at 2500 Pa (static)",
    label: "EF024446: Air volume at 2500 Pa (static)"
  },
  {
    featureId: "EF024447",
    featureDesc: "Air volume at 3000 Pa (static)",
    label: "EF024447: Air volume at 3000 Pa (static)"
  },
  {
    featureId: "EF024448",
    featureDesc: "Air volume at 3500 Pa (static)",
    label: "EF024448: Air volume at 3500 Pa (static)"
  },
  {
    featureId: "EF024449",
    featureDesc: "Air volume at 4000 Pa (static)",
    label: "EF024449: Air volume at 4000 Pa (static)"
  },
  {
    featureId: "EF024450",
    featureDesc: "Air volume at 4500 Pa (static)",
    label: "EF024450: Air volume at 4500 Pa (static)"
  },
  {
    featureId: "EF024451",
    featureDesc: "Air volume at 5000 Pa (static)",
    label: "EF024451: Air volume at 5000 Pa (static)"
  },
  {
    featureId: "EF024452",
    featureDesc: "Air volume at 6000 Pa (static)",
    label: "EF024452: Air volume at 6000 Pa (static)"
  },
  {
    featureId: "EF024453",
    featureDesc: "Air volume at 8000 Pa (static)",
    label: "EF024453: Air volume at 8000 Pa (static)"
  },
  {
    featureId: "EF024454",
    featureDesc: "Air volume at 10000 Pa (static)",
    label: "EF024454: Air volume at 10000 Pa (static)"
  },
  {
    featureId: "EF024455",
    featureDesc: "Axial movement",
    label: "EF024455: Axial movement"
  },
  {
    featureId: "EF024456",
    featureDesc: "Radial movement",
    label: "EF024456: Radial movement"
  },
  {
    featureId: "EF024457",
    featureDesc: "Functional load",
    label: "EF024457: Functional load"
  },
  {
    featureId: "EF024458",
    featureDesc: "Suitable for strut rail",
    label: "EF024458: Suitable for strut rail"
  },
  {
    featureId: "EF024459",
    featureDesc: "Mounting head",
    label: "EF024459: Mounting head"
  },
  {
    featureId: "EF024460",
    featureDesc: "Fire-resistance",
    label: "EF024460: Fire-resistance"
  },
  {
    featureId: "EF024461",
    featureDesc: "Centre distance bracket",
    label: "EF024461: Centre distance bracket"
  },
  {
    featureId: "EF024462",
    featureDesc: "Permitted vertical load",
    label: "EF024462: Permitted vertical load"
  },
  {
    featureId: "EF024463",
    featureDesc: "Covering rosette design",
    label: "EF024463: Covering rosette design"
  },
  {
    featureId: "EF024464",
    featureDesc: "Material shelf",
    label: "EF024464: Material shelf"
  },
  {
    featureId: "EF024466",
    featureDesc: "Shelf colour",
    label: "EF024466: Shelf colour"
  },
  {
    featureId: "EF024468",
    featureDesc: "Safety glass",
    label: "EF024468: Safety glass"
  },
  {
    featureId: "EF024470",
    featureDesc: "With soap pump",
    label: "EF024470: With soap pump"
  },
  {
    featureId: "EF024471",
    featureDesc: "Glass design",
    label: "EF024471: Glass design"
  },
  {
    featureId: "EF024472",
    featureDesc: "Material bottle",
    label: "EF024472: Material bottle"
  },
  {
    featureId: "EF024473",
    featureDesc: "Holder colour",
    label: "EF024473: Holder colour"
  },
  {
    featureId: "EF024474",
    featureDesc: "With anti-condensation",
    label: "EF024474: With anti-condensation"
  },
  {
    featureId: "EF024475",
    featureDesc: "Relative humidity (RH) readable",
    label: "EF024475: Relative humidity (RH) readable"
  },
  {
    featureId: "EF024476",
    featureDesc: "Readable",
    label: "EF024476: Readable"
  },
  {
    featureId: "EF024477",
    featureDesc: "Communication medium copper",
    label: "EF024477: Communication medium copper"
  },
  {
    featureId: "EF024478",
    featureDesc: "Communication medium fibre optic",
    label: "EF024478: Communication medium fibre optic"
  },
  {
    featureId: "EF024479",
    featureDesc: "Communication medium wireless",
    label: "EF024479: Communication medium wireless"
  },
  {
    featureId: "EF024481",
    featureDesc: "Readjustment application with space module",
    label: "EF024481: Readjustment application with space module"
  },
  {
    featureId: "EF024482",
    featureDesc: "Readjustment application without space module",
    label: "EF024482: Readjustment application without space module"
  },
  {
    featureId: "EF024483",
    featureDesc: "Max. flue diameter",
    label: "EF024483: Max. flue diameter"
  },
  {
    featureId: "EF024484",
    featureDesc: "Diameter flue gas and air supply",
    label: "EF024484: Diameter flue gas and air supply"
  },
  {
    featureId: "EF024485",
    featureDesc: "Key length",
    label: "EF024485: Key length"
  },
  {
    featureId: "EF024487",
    featureDesc: "Sound absorber",
    label: "EF024487: Sound absorber"
  },
  {
    featureId: "EF024489",
    featureDesc: "With exchangeable cover/rosette decor",
    label: "EF024489: With exchangeable cover/rosette decor"
  },
  {
    featureId: "EF024490",
    featureDesc: "Frame profile",
    label: "EF024490: Frame profile"
  },
  {
    featureId: "EF024492",
    featureDesc: "Facet type",
    label: "EF024492: Facet type"
  },
  {
    featureId: "EF024493",
    featureDesc: "Facet design",
    label: "EF024493: Facet design"
  },
  {
    featureId: "EF024494",
    featureDesc: "Double toilet roll holder",
    label: "EF024494: Double toilet roll holder"
  },
  {
    featureId: "EF024495",
    featureDesc: "With separate insert",
    label: "EF024495: With separate insert"
  },
  {
    featureId: "EF024496",
    featureDesc: "Material recess",
    label: "EF024496: Material recess"
  },
  {
    featureId: "EF024497",
    featureDesc: "With separate toothbrush/tube holder",
    label: "EF024497: With separate toothbrush/tube holder"
  },
  {
    featureId: "EF024498",
    featureDesc: "Lead/depth",
    label: "EF024498: Lead/depth"
  },
  {
    featureId: "EF024501",
    featureDesc: "Internal insulation",
    label: "EF024501: Internal insulation"
  },
  {
    featureId: "EF024502",
    featureDesc: "Inner surface protection",
    label: "EF024502: Inner surface protection"
  },
  {
    featureId: "EF024503",
    featureDesc: "Outer surface protection",
    label: "EF024503: Outer surface protection"
  },
  {
    featureId: "EF024504",
    featureDesc: "Foot control lid",
    label: "EF024504: Foot control lid"
  },
  {
    featureId: "EF024505",
    featureDesc: "Decor ring colour",
    label: "EF024505: Decor ring colour"
  },
  {
    featureId: "EF024506",
    featureDesc: "Length of washing line",
    label: "EF024506: Length of washing line"
  },
  {
    featureId: "EF024507",
    featureDesc: "Air volume at 14000 Pa (static, 20 °C)",
    label: "EF024507: Air volume at 14000 Pa (static, 20 °C)"
  },
  {
    featureId: "EF024508",
    featureDesc: "Air volume at 18000 Pa (static, 20 °C)",
    label: "EF024508: Air volume at 18000 Pa (static, 20 °C)"
  },
  {
    featureId: "EF024509",
    featureDesc: "In-line",
    label: "EF024509: In-line"
  },
  {
    featureId: "EF024510",
    featureDesc: "Nominal duct diameter, suction side",
    label: "EF024510: Nominal duct diameter, suction side"
  },
  {
    featureId: "EF024511",
    featureDesc: "Nominal duct diameter, delivery side",
    label: "EF024511: Nominal duct diameter, delivery side"
  },
  {
    featureId: "EF024512",
    featureDesc: "Nominal duct width, delivery side",
    label: "EF024512: Nominal duct width, delivery side"
  },
  {
    featureId: "EF024513",
    featureDesc: "Nominal duct height, delivery side",
    label: "EF024513: Nominal duct height, delivery side"
  },
  {
    featureId: "EF024514",
    featureDesc: "Acoustic capacity at 63 Hz",
    label: "EF024514: Acoustic capacity at 63 Hz"
  },
  {
    featureId: "EF024515",
    featureDesc: "Acoustic capacity at 125 Hz",
    label: "EF024515: Acoustic capacity at 125 Hz"
  },
  {
    featureId: "EF024516",
    featureDesc: "Acoustic capacity at 250 Hz",
    label: "EF024516: Acoustic capacity at 250 Hz"
  },
  {
    featureId: "EF024517",
    featureDesc: "Acoustic capacity at 500 Hz",
    label: "EF024517: Acoustic capacity at 500 Hz"
  },
  {
    featureId: "EF024518",
    featureDesc: "Acoustic capacity at 1000 Hz",
    label: "EF024518: Acoustic capacity at 1000 Hz"
  },
  {
    featureId: "EF024519",
    featureDesc: "Acoustic capacity at 2000 Hz",
    label: "EF024519: Acoustic capacity at 2000 Hz"
  },
  {
    featureId: "EF024520",
    featureDesc: "Acoustic capacity at 4000 Hz",
    label: "EF024520: Acoustic capacity at 4000 Hz"
  },
  {
    featureId: "EF024521",
    featureDesc: "Acoustic capacity at 8000 Hz",
    label: "EF024521: Acoustic capacity at 8000 Hz"
  },
  {
    featureId: "EF024522",
    featureDesc: "Impeller model",
    label: "EF024522: Impeller model"
  },
  {
    featureId: "EF024523",
    featureDesc: "Shelf design",
    label: "EF024523: Shelf design"
  },
  {
    featureId: "EF024524",
    featureDesc: "With roll/guest towel holder",
    label: "EF024524: With roll/guest towel holder"
  },
  {
    featureId: "EF024526",
    featureDesc: "With toilet roll holder",
    label: "EF024526: With toilet roll holder"
  },
  {
    featureId: "EF024527",
    featureDesc: "Brush colour",
    label: "EF024527: Brush colour"
  },
  {
    featureId: "EF024529",
    featureDesc: "Brush diameter",
    label: "EF024529: Brush diameter"
  },
  {
    featureId: "EF024530",
    featureDesc: "Suitable for glass thickness",
    label: "EF024530: Suitable for glass thickness"
  },
  {
    featureId: "EF024531",
    featureDesc: "Number of flannels",
    label: "EF024531: Number of flannels"
  },
  {
    featureId: "EF024532",
    featureDesc: "Insert design",
    label: "EF024532: Insert design"
  },
  {
    featureId: "EF024533",
    featureDesc: "Number of openings for toothbrush",
    label: "EF024533: Number of openings for toothbrush"
  },
  {
    featureId: "EF024534",
    featureDesc: "Number of openings for soap pump",
    label: "EF024534: Number of openings for soap pump"
  },
  {
    featureId: "EF024535",
    featureDesc: "Number of openings for glass",
    label: "EF024535: Number of openings for glass"
  },
  {
    featureId: "EF024537",
    featureDesc: "Surface protection pump",
    label: "EF024537: Surface protection pump"
  },
  {
    featureId: "EF024538",
    featureDesc: "Glass/plastic design",
    label: "EF024538: Glass/plastic design"
  },
  {
    featureId: "EF024541",
    featureDesc: "Detachable",
    label: "EF024541: Detachable"
  },
  {
    featureId: "EF024543",
    featureDesc: "Outer bend model",
    label: "EF024543: Outer bend model"
  },
  {
    featureId: "EF024544",
    featureDesc: "Inner bend model",
    label: "EF024544: Inner bend model"
  },
  {
    featureId: "EF024545",
    featureDesc: "Air guide vanes",
    label: "EF024545: Air guide vanes"
  },
  {
    featureId: "EF024551",
    featureDesc: "With braiding",
    label: "EF024551: With braiding"
  },
  {
    featureId: "EF024552",
    featureDesc: "Colour of slide rod",
    label: "EF024552: Colour of slide rod"
  },
  {
    featureId: "EF024553",
    featureDesc: "Colour of wall holder",
    label: "EF024553: Colour of wall holder"
  },
  {
    featureId: "EF024554",
    featureDesc: "With conical holder",
    label: "EF024554: With conical holder"
  },
  {
    featureId: "EF024555",
    featureDesc: "With hinge piece",
    label: "EF024555: With hinge piece"
  },
  {
    featureId: "EF024557",
    featureDesc: "Impeller diameter",
    label: "EF024557: Impeller diameter"
  },
  {
    featureId: "EF024558",
    featureDesc: "Duct width, suction",
    label: "EF024558: Duct width, suction"
  },
  {
    featureId: "EF024559",
    featureDesc: "Duct height, suction",
    label: "EF024559: Duct height, suction"
  },
  {
    featureId: "EF024560",
    featureDesc: "Duct diameter, suction",
    label: "EF024560: Duct diameter, suction"
  },
  {
    featureId: "EF024561",
    featureDesc: "Duct diameter, exhaust",
    label: "EF024561: Duct diameter, exhaust"
  },
  {
    featureId: "EF024563",
    featureDesc: "Duct width, exhaust",
    label: "EF024563: Duct width, exhaust"
  },
  {
    featureId: "EF024564",
    featureDesc: "Duct height, exhaust",
    label: "EF024564: Duct height, exhaust"
  },
  {
    featureId: "EF024567",
    featureDesc: "Duct side 1 (largest side)",
    label: "EF024567: Duct side 1 (largest side)"
  },
  {
    featureId: "EF024568",
    featureDesc: "Duct side 2",
    label: "EF024568: Duct side 2"
  },
  {
    featureId: "EF024569",
    featureDesc: "Offset model (duct width horizontal)",
    label: "EF024569: Offset model (duct width horizontal)"
  },
  {
    featureId: "EF024570",
    featureDesc: "Bend model (duct width horizontal)",
    label: "EF024570: Bend model (duct width horizontal)"
  },
  {
    featureId: "EF024580",
    featureDesc: "With pre-mounted seal, connection 2",
    label: "EF024580: With pre-mounted seal, connection 2"
  },
  {
    featureId: "EF024581",
    featureDesc: "Surface protection immersion pipe",
    label: "EF024581: Surface protection immersion pipe"
  },
  {
    featureId: "EF024582",
    featureDesc: "Surface protection, fork",
    label: "EF024582: Surface protection, fork"
  },
  {
    featureId: "EF024583",
    featureDesc: "With push-on profile",
    label: "EF024583: With push-on profile"
  },
  {
    featureId: "EF024584",
    featureDesc: "With push-on clamp",
    label: "EF024584: With push-on clamp"
  },
  {
    featureId: "EF024591",
    featureDesc: "Rounding with radius",
    label: "EF024591: Rounding with radius"
  },
  {
    featureId: "EF024592",
    featureDesc: "Quality class duct material",
    label: "EF024592: Quality class duct material"
  },
  {
    featureId: "EF024594",
    featureDesc: "Interior inner diameter",
    label: "EF024594: Interior inner diameter"
  },
  {
    featureId: "EF024595",
    featureDesc: "With exterior spiral",
    label: "EF024595: With exterior spiral"
  },
  {
    featureId: "EF024596",
    featureDesc: "Outer hose with spiral",
    label: "EF024596: Outer hose with spiral"
  },
  {
    featureId: "EF024597",
    featureDesc: "Compressed",
    label: "EF024597: Compressed"
  },
  {
    featureId: "EF024598",
    featureDesc: "Wall thickness inner hose",
    label: "EF024598: Wall thickness inner hose"
  },
  {
    featureId: "EF024599",
    featureDesc: "Material of 1st layer, inner layer",
    label: "EF024599: Material of 1st layer, inner layer"
  },
  {
    featureId: "EF024600",
    featureDesc: "Material of 2nd layer",
    label: "EF024600: Material of 2nd layer"
  },
  {
    featureId: "EF024601",
    featureDesc: "Material of 3rd layer",
    label: "EF024601: Material of 3rd layer"
  },
  {
    featureId: "EF024602",
    featureDesc: "Folded",
    label: "EF024602: Folded"
  },
  {
    featureId: "EF024605",
    featureDesc: "Heat emission according to EN 442 20 °C - 75/65",
    label: "EF024605: Heat emission according to EN 442 20 °C - 75/65"
  },
  {
    featureId: "EF024606",
    featureDesc: "N-exponent",
    label: "EF024606: N-exponent"
  },
  {
    featureId: "EF024607",
    featureDesc: "With side lining",
    label: "EF024607: With side lining"
  },
  {
    featureId: "EF024615",
    featureDesc: "Connection combi 11, underside left/underside left",
    label: "EF024615: Connection combi 11, underside left/underside left"
  },
  {
    featureId: "EF024616",
    featureDesc: "Connection combi 88, underside right/underside right",
    label: "EF024616: Connection combi 88, underside right/underside right"
  },
  {
    featureId: "EF024619",
    featureDesc: "With single-point connection",
    label: "EF024619: With single-point connection"
  },
  {
    featureId: "EF024620",
    featureDesc: "With drain connection",
    label: "EF024620: With drain connection"
  },
  {
    featureId: "EF024634",
    featureDesc: "With integrated thermostatic valve",
    label: "EF024634: With integrated thermostatic valve"
  },
  {
    featureId: "EF024635",
    featureDesc: "With wall brackets",
    label: "EF024635: With wall brackets"
  },
  {
    featureId: "EF024636",
    featureDesc: "With blind stops",
    label: "EF024636: With blind stops"
  },
  {
    featureId: "EF024637",
    featureDesc: "Shape of radiation tube",
    label: "EF024637: Shape of radiation tube"
  },
  {
    featureId: "EF024638",
    featureDesc: "Depth from wall to front side",
    label: "EF024638: Depth from wall to front side"
  },
  {
    featureId: "EF024639",
    featureDesc: "With top cladding",
    label: "EF024639: With top cladding"
  },
  {
    featureId: "EF024640",
    featureDesc: "Shape of collector",
    label: "EF024640: Shape of collector"
  },
  {
    featureId: "EF024641",
    featureDesc: "Radiant tube",
    label: "EF024641: Radiant tube"
  },
  {
    featureId: "EF024643",
    featureDesc: "Radiator design",
    label: "EF024643: Radiator design"
  },
  {
    featureId: "EF024648",
    featureDesc: "Anchor model",
    label: "EF024648: Anchor model"
  },
  {
    featureId: "EF024649",
    featureDesc: "Heat emission according to EN 442 laboratory",
    label: "EF024649: Heat emission according to EN 442 laboratory"
  },
  {
    featureId: "EF024653",
    featureDesc: "Connection combi 22, side bottom-left/side bottom-left",
    label: "EF024653: Connection combi 22, side bottom-left/side bottom-left"
  },
  {
    featureId: "EF024654",
    featureDesc: "Connection combi 77, side bottom-right/side bottom-right",
    label: "EF024654: Connection combi 77, side bottom-right/side bottom-right"
  },
  {
    featureId: "EF024655",
    featureDesc: "Connection combi 78, side bottom-right/underside right",
    label: "EF024655: Connection combi 78, side bottom-right/underside right"
  },
  {
    featureId: "EF024656",
    featureDesc: "Connection combi 21, side bottom-left/underside left",
    label: "EF024656: Connection combi 21, side bottom-left/underside left"
  },
  {
    featureId: "EF024657",
    featureDesc: "Air inlet",
    label: "EF024657: Air inlet"
  },
  {
    featureId: "EF024658",
    featureDesc: "Air exhaust",
    label: "EF024658: Air exhaust"
  },
  {
    featureId: "EF024659",
    featureDesc: "Base height",
    label: "EF024659: Base height"
  },
  {
    featureId: "EF024660",
    featureDesc: "Base colour same as housing",
    label: "EF024660: Base colour same as housing"
  },
  {
    featureId: "EF024662",
    featureDesc: "With console",
    label: "EF024662: With console"
  },
  {
    featureId: "EF024664",
    featureDesc: "Height, bracket/separating partition",
    label: "EF024664: Height, bracket/separating partition"
  },
  {
    featureId: "EF024665",
    featureDesc: "Shaft height above convector",
    label: "EF024665: Shaft height above convector"
  },
  {
    featureId: "EF024666",
    featureDesc: "Height, convector element",
    label: "EF024666: Height, convector element"
  },
  {
    featureId: "EF024674",
    featureDesc: "Suitable for electrical element",
    label: "EF024674: Suitable for electrical element"
  },
  {
    featureId: "EF024675",
    featureDesc: "With electrical element",
    label: "EF024675: With electrical element"
  },
  {
    featureId: "EF024676",
    featureDesc: "With mirror",
    label: "EF024676: With mirror"
  },
  {
    featureId: "EF024677",
    featureDesc: "With consoles",
    label: "EF024677: With consoles"
  },
  {
    featureId: "EF024678",
    featureDesc: "Material, grid frame",
    label: "EF024678: Material, grid frame"
  },
  {
    featureId: "EF024679",
    featureDesc: "Outer length",
    label: "EF024679: Outer length"
  },
  {
    featureId: "EF024680",
    featureDesc: "Outer width",
    label: "EF024680: Outer width"
  },
  {
    featureId: "EF024681",
    featureDesc: "Outer depth",
    label: "EF024681: Outer depth"
  },
  {
    featureId: "EF024682",
    featureDesc: "Surface protection grid",
    label: "EF024682: Surface protection grid"
  },
  {
    featureId: "EF024683",
    featureDesc: "Grid colour",
    label: "EF024683: Grid colour"
  },
  {
    featureId: "EF024684",
    featureDesc: "Grid wood type",
    label: "EF024684: Grid wood type"
  },
  {
    featureId: "EF024685",
    featureDesc: "Grid model",
    label: "EF024685: Grid model"
  },
  {
    featureId: "EF024686",
    featureDesc: "Heat exchanger position",
    label: "EF024686: Heat exchanger position"
  },
  {
    featureId: "EF024687",
    featureDesc: "Watertight pit",
    label: "EF024687: Watertight pit"
  },
  {
    featureId: "EF024690",
    featureDesc: "With connection pipes",
    label: "EF024690: With connection pipes"
  },
  {
    featureId: "EF024691",
    featureDesc: "Material division plate",
    label: "EF024691: Material division plate"
  },
  {
    featureId: "EF024696",
    featureDesc: "Suitable for damp space",
    label: "EF024696: Suitable for damp space"
  },
  {
    featureId: "EF024697",
    featureDesc: "Frame material quality class",
    label: "EF024697: Frame material quality class"
  },
  {
    featureId: "EF024698",
    featureDesc: "Surface protection frame",
    label: "EF024698: Surface protection frame"
  },
  {
    featureId: "EF024699",
    featureDesc: "Surface treatment frame",
    label: "EF024699: Surface treatment frame"
  },
  {
    featureId: "EF024700",
    featureDesc: "Material seat",
    label: "EF024700: Material seat"
  },
  {
    featureId: "EF024701",
    featureDesc: "Seat colour",
    label: "EF024701: Seat colour"
  },
  {
    featureId: "EF024702",
    featureDesc: "Seat material quality class",
    label: "EF024702: Seat material quality class"
  },
  {
    featureId: "EF024703",
    featureDesc: "Surface protection seat material",
    label: "EF024703: Surface protection seat material"
  },
  {
    featureId: "EF024704",
    featureDesc: "Surface treatment seat",
    label: "EF024704: Surface treatment seat"
  },
  {
    featureId: "EF024705",
    featureDesc: "Seat width",
    label: "EF024705: Seat width"
  },
  {
    featureId: "EF024706",
    featureDesc: "Seat height",
    label: "EF024706: Seat height"
  },
  {
    featureId: "EF024707",
    featureDesc: "Type of coating",
    label: "EF024707: Type of coating"
  },
  {
    featureId: "EF024708",
    featureDesc: "Arm supports",
    label: "EF024708: Arm supports"
  },
  {
    featureId: "EF024709",
    featureDesc: "Height adjustment range",
    label: "EF024709: Height adjustment range"
  },
  {
    featureId: "EF024710",
    featureDesc: "Adjustable backrest",
    label: "EF024710: Adjustable backrest"
  },
  {
    featureId: "EF024711",
    featureDesc: "With transfer rail",
    label: "EF024711: With transfer rail"
  },
  {
    featureId: "EF024712",
    featureDesc: "Adjustable when loaded",
    label: "EF024712: Adjustable when loaded"
  },
  {
    featureId: "EF024713",
    featureDesc: "Lockable in vertical position",
    label: "EF024713: Lockable in vertical position"
  },
  {
    featureId: "EF024714",
    featureDesc: "Can be turned to one side",
    label: "EF024714: Can be turned to one side"
  },
  {
    featureId: "EF024715",
    featureDesc: "With floor tripod",
    label: "EF024715: With floor tripod"
  },
  {
    featureId: "EF024717",
    featureDesc: "Material wall attachment",
    label: "EF024717: Material wall attachment"
  },
  {
    featureId: "EF024718",
    featureDesc: "Max. static load",
    label: "EF024718: Max. static load"
  },
  {
    featureId: "EF024719",
    featureDesc: "Seat equipped with holes",
    label: "EF024719: Seat equipped with holes"
  },
  {
    featureId: "EF024720",
    featureDesc: "Slatted seat",
    label: "EF024720: Slatted seat"
  },
  {
    featureId: "EF024721",
    featureDesc: "Lying surface colour",
    label: "EF024721: Lying surface colour"
  },
  {
    featureId: "EF024722",
    featureDesc: "Material lying surface",
    label: "EF024722: Material lying surface"
  },
  {
    featureId: "EF024723",
    featureDesc: "Lying surface material quality class",
    label: "EF024723: Lying surface material quality class"
  },
  {
    featureId: "EF024724",
    featureDesc: "Number of side fences",
    label: "EF024724: Number of side fences"
  },
  {
    featureId: "EF024725",
    featureDesc: "Covered lying surface",
    label: "EF024725: Covered lying surface"
  },
  {
    featureId: "EF024726",
    featureDesc: "Lying surface equipped with holes",
    label: "EF024726: Lying surface equipped with holes"
  },
  {
    featureId: "EF024727",
    featureDesc: "Fold-down side fences",
    label: "EF024727: Fold-down side fences"
  },
  {
    featureId: "EF024728",
    featureDesc: "With collection tray",
    label: "EF024728: With collection tray"
  },
  {
    featureId: "EF024729",
    featureDesc: "Removable side fences",
    label: "EF024729: Removable side fences"
  },
  {
    featureId: "EF024730",
    featureDesc: "With mattress",
    label: "EF024730: With mattress"
  },
  {
    featureId: "EF024731",
    featureDesc: "With cushions",
    label: "EF024731: With cushions"
  },
  {
    featureId: "EF024732",
    featureDesc: "Length of part 1 (largest part)",
    label: "EF024732: Length of part 1 (largest part)"
  },
  {
    featureId: "EF024733",
    featureDesc: "Length of part 2",
    label: "EF024733: Length of part 2"
  },
  {
    featureId: "EF024734",
    featureDesc: "Length of part 3 (vertical part)",
    label: "EF024734: Length of part 3 (vertical part)"
  },
  {
    featureId: "EF024736",
    featureDesc: "With shower head holder",
    label: "EF024736: With shower head holder"
  },
  {
    featureId: "EF024737",
    featureDesc: "With slider rail combination",
    label: "EF024737: With slider rail combination"
  },
  {
    featureId: "EF024739",
    featureDesc: "Sound pressure level at height of 5 metres",
    label: "EF024739: Sound pressure level at height of 5 metres"
  },
  {
    featureId: "EF024740",
    featureDesc: "Adjustable induction in exhaust air",
    label: "EF024740: Adjustable induction in exhaust air"
  },
  {
    featureId: "EF024741",
    featureDesc: "Adjustable fins on exhaust side",
    label: "EF024741: Adjustable fins on exhaust side"
  },
  {
    featureId: "EF024742",
    featureDesc: "Free-exhausting",
    label: "EF024742: Free-exhausting"
  },
  {
    featureId: "EF024746",
    featureDesc: "Console suitable for wall",
    label: "EF024746: Console suitable for wall"
  },
  {
    featureId: "EF024747",
    featureDesc: "Console suitable for ceiling",
    label: "EF024747: Console suitable for ceiling"
  },
  {
    featureId: "EF024748",
    featureDesc: "Console suitable for steel column",
    label: "EF024748: Console suitable for steel column"
  },
  {
    featureId: "EF024752",
    featureDesc: "Module",
    label: "EF024752: Module"
  },
  {
    featureId: "EF024753",
    featureDesc: "With glue set",
    label: "EF024753: With glue set"
  },
  {
    featureId: "EF024754",
    featureDesc: "Seat depth",
    label: "EF024754: Seat depth"
  },
  {
    featureId: "EF024755",
    featureDesc: "Variable lockable swivel arm",
    label: "EF024755: Variable lockable swivel arm"
  },
  {
    featureId: "EF024756",
    featureDesc: "Depth of pull handle adjustable",
    label: "EF024756: Depth of pull handle adjustable"
  },
  {
    featureId: "EF024757",
    featureDesc: "Suitable for bath inner dimension",
    label: "EF024757: Suitable for bath inner dimension"
  },
  {
    featureId: "EF024759",
    featureDesc: "Handrail colour",
    label: "EF024759: Handrail colour"
  },
  {
    featureId: "EF024760",
    featureDesc: "Handrail material",
    label: "EF024760: Handrail material"
  },
  {
    featureId: "EF024761",
    featureDesc: "Handrail material quality class",
    label: "EF024761: Handrail material quality class"
  },
  {
    featureId: "EF024762",
    featureDesc: "Usable with built-in reservoir",
    label: "EF024762: Usable with built-in reservoir"
  },
  {
    featureId: "EF024763",
    featureDesc: "With hot-water facility",
    label: "EF024763: With hot-water facility"
  },
  {
    featureId: "EF024764",
    featureDesc: "Air temperature adjustable",
    label: "EF024764: Air temperature adjustable"
  },
  {
    featureId: "EF024765",
    featureDesc: "Suitable for back control",
    label: "EF024765: Suitable for back control"
  },
  {
    featureId: "EF024766",
    featureDesc: "Vaginal douche function",
    label: "EF024766: Vaginal douche function"
  },
  {
    featureId: "EF024767",
    featureDesc: "Suitable for high/low frame",
    label: "EF024767: Suitable for high/low frame"
  },
  {
    featureId: "EF024768",
    featureDesc: "With mixing tap",
    label: "EF024768: With mixing tap"
  },
  {
    featureId: "EF024769",
    featureDesc: "Extendible hand shower",
    label: "EF024769: Extendible hand shower"
  },
  {
    featureId: "EF024770",
    featureDesc: "With scissor mirror",
    label: "EF024770: With scissor mirror"
  },
  {
    featureId: "EF024771",
    featureDesc: "Suitable for standard seat",
    label: "EF024771: Suitable for standard seat"
  },
  {
    featureId: "EF024772",
    featureDesc: "Soft seat",
    label: "EF024772: Soft seat"
  },
  {
    featureId: "EF024773",
    featureDesc: "Vandalism-proof attachment",
    label: "EF024773: Vandalism-proof attachment"
  },
  {
    featureId: "EF024774",
    featureDesc: "Anatomically-shaped seat",
    label: "EF024774: Anatomically-shaped seat"
  },
  {
    featureId: "EF024775",
    featureDesc: "Backrest colour",
    label: "EF024775: Backrest colour"
  },
  {
    featureId: "EF024776",
    featureDesc: "Material backrest",
    label: "EF024776: Material backrest"
  },
  {
    featureId: "EF024777",
    featureDesc: "Backrest material quality class",
    label: "EF024777: Backrest material quality class"
  },
  {
    featureId: "EF024778",
    featureDesc: "With foot supports",
    label: "EF024778: With foot supports"
  },
  {
    featureId: "EF024779",
    featureDesc: "Adjustable foot supports",
    label: "EF024779: Adjustable foot supports"
  },
  {
    featureId: "EF024780",
    featureDesc: "Foldable seat",
    label: "EF024780: Foldable seat"
  },
  {
    featureId: "EF024781",
    featureDesc: "Rail for bed pan and/or slop pail",
    label: "EF024781: Rail for bed pan and/or slop pail"
  },
  {
    featureId: "EF024782",
    featureDesc: "Including lap belt",
    label: "EF024782: Including lap belt"
  },
  {
    featureId: "EF024783",
    featureDesc: "Including calf strap",
    label: "EF024783: Including calf strap"
  },
  {
    featureId: "EF024784",
    featureDesc: "Toilet override",
    label: "EF024784: Toilet override"
  },
  {
    featureId: "EF024786",
    featureDesc: "Number of wall attachment points",
    label: "EF024786: Number of wall attachment points"
  },
  {
    featureId: "EF024787",
    featureDesc: "Number of floor attachment points",
    label: "EF024787: Number of floor attachment points"
  },
  {
    featureId: "EF024788",
    featureDesc: "Type of adjustment",
    label: "EF024788: Type of adjustment"
  },
  {
    featureId: "EF024789",
    featureDesc: "Type of electrical operation",
    label: "EF024789: Type of electrical operation"
  },
  {
    featureId: "EF024790",
    featureDesc: "Depth adjustment range",
    label: "EF024790: Depth adjustment range"
  },
  {
    featureId: "EF024791",
    featureDesc: "With washbasin",
    label: "EF024791: With washbasin"
  },
  {
    featureId: "EF024792",
    featureDesc: "With shelf",
    label: "EF024792: With shelf"
  },
  {
    featureId: "EF024793",
    featureDesc: "With flexible inlet and outlet set",
    label: "EF024793: With flexible inlet and outlet set"
  },
  {
    featureId: "EF024794",
    featureDesc: "Suitable for standard washbasin",
    label: "EF024794: Suitable for standard washbasin"
  },
  {
    featureId: "EF024797",
    featureDesc: "Suitable for heating",
    label: "EF024797: Suitable for heating"
  },
  {
    featureId: "EF024798",
    featureDesc: "Suitable for cooling",
    label: "EF024798: Suitable for cooling"
  },
  {
    featureId: "EF024799",
    featureDesc: "With separate batteries",
    label: "EF024799: With separate batteries"
  },
  {
    featureId: "EF024803",
    featureDesc: "Heat emission 20 °C - 90/70 high",
    label: "EF024803: Heat emission 20 °C - 90/70 high"
  },
  {
    featureId: "EF024804",
    featureDesc: "Heat emission 20 °C - 90/70 medium",
    label: "EF024804: Heat emission 20 °C - 90/70 medium"
  },
  {
    featureId: "EF024805",
    featureDesc: "Heat emission 20 °C - 90/70 low",
    label: "EF024805: Heat emission 20 °C - 90/70 low"
  },
  {
    featureId: "EF024806",
    featureDesc: "Cooling capacity 25 °C - 6/12 perceived high",
    label: "EF024806: Cooling capacity 25 °C - 6/12 perceived high"
  },
  {
    featureId: "EF024807",
    featureDesc: "Cooling capacity 25 °C - 6/12 perceived medium",
    label: "EF024807: Cooling capacity 25 °C - 6/12 perceived medium"
  },
  {
    featureId: "EF024808",
    featureDesc: "Cooling capacity 25 °C - 6/12 perceived low",
    label: "EF024808: Cooling capacity 25 °C - 6/12 perceived low"
  },
  {
    featureId: "EF024809",
    featureDesc: "Air volume high",
    label: "EF024809: Air volume high"
  },
  {
    featureId: "EF024810",
    featureDesc: "Air volume medium",
    label: "EF024810: Air volume medium"
  },
  {
    featureId: "EF024811",
    featureDesc: "Air volume low",
    label: "EF024811: Air volume low"
  },
  {
    featureId: "EF024812",
    featureDesc: "Sound pressure level high",
    label: "EF024812: Sound pressure level high"
  },
  {
    featureId: "EF024813",
    featureDesc: "Sound pressure level medium",
    label: "EF024813: Sound pressure level medium"
  },
  {
    featureId: "EF024814",
    featureDesc: "Sound pressure level low",
    label: "EF024814: Sound pressure level low"
  },
  {
    featureId: "EF024815",
    featureDesc: "With electric heating element",
    label: "EF024815: With electric heating element"
  },
  {
    featureId: "EF024816",
    featureDesc: "With drip-tray",
    label: "EF024816: With drip-tray"
  },
  {
    featureId: "EF024817",
    featureDesc: "With built-in position controller",
    label: "EF024817: With built-in position controller"
  },
  {
    featureId: "EF024818",
    featureDesc: "Suitable for cabinet depth/height",
    label: "EF024818: Suitable for cabinet depth/height"
  },
  {
    featureId: "EF024822",
    featureDesc: "Built-in depth adjustable",
    label: "EF024822: Built-in depth adjustable"
  },
  {
    featureId: "EF024823",
    featureDesc: "RAL-number grid",
    label: "EF024823: RAL-number grid"
  },
  {
    featureId: "EF024824",
    featureDesc: "Encasing material",
    label: "EF024824: Encasing material"
  },
  {
    featureId: "EF024826",
    featureDesc: "Bath length",
    label: "EF024826: Bath length"
  },
  {
    featureId: "EF024827",
    featureDesc: "Bath width",
    label: "EF024827: Bath width"
  },
  {
    featureId: "EF024828",
    featureDesc: "Bath height",
    label: "EF024828: Bath height"
  },
  {
    featureId: "EF024829",
    featureDesc: "Casing length",
    label: "EF024829: Casing length"
  },
  {
    featureId: "EF024830",
    featureDesc: "Casing width",
    label: "EF024830: Casing width"
  },
  {
    featureId: "EF024831",
    featureDesc: "Casing height",
    label: "EF024831: Casing height"
  },
  {
    featureId: "EF024832",
    featureDesc: "With handles",
    label: "EF024832: With handles"
  },
  {
    featureId: "EF024833",
    featureDesc: "With whirlpool",
    label: "EF024833: With whirlpool"
  },
  {
    featureId: "EF024835",
    featureDesc: "Emergency stop button",
    label: "EF024835: Emergency stop button"
  },
  {
    featureId: "EF024836",
    featureDesc: "Automatic temperature controller",
    label: "EF024836: Automatic temperature controller"
  },
  {
    featureId: "EF024837",
    featureDesc: "Automatic water level limiter",
    label: "EF024837: Automatic water level limiter"
  },
  {
    featureId: "EF024838",
    featureDesc: "With folding shower window",
    label: "EF024838: With folding shower window"
  },
  {
    featureId: "EF024839",
    featureDesc: "Column lift",
    label: "EF024839: Column lift"
  },
  {
    featureId: "EF024840",
    featureDesc: "With reducing valve",
    label: "EF024840: With reducing valve"
  },
  {
    featureId: "EF024841",
    featureDesc: "With non-return valve",
    label: "EF024841: With non-return valve"
  },
  {
    featureId: "EF024842",
    featureDesc: "With floor plate",
    label: "EF024842: With floor plate"
  },
  {
    featureId: "EF024844",
    featureDesc: "With toilet bowl/pan",
    label: "EF024844: With toilet bowl/pan"
  },
  {
    featureId: "EF024845",
    featureDesc: "Water temperature",
    label: "EF024845: Water temperature"
  },
  {
    featureId: "EF024846",
    featureDesc: "Hot air temperature adjustable",
    label: "EF024846: Hot air temperature adjustable"
  },
  {
    featureId: "EF024847",
    featureDesc: "Drive mechanism",
    label: "EF024847: Drive mechanism"
  },
  {
    featureId: "EF024848",
    featureDesc: "Lining colour",
    label: "EF024848: Lining colour"
  },
  {
    featureId: "EF024850",
    featureDesc: "With wire baskets",
    label: "EF024850: With wire baskets"
  },
  {
    featureId: "EF024851",
    featureDesc: "Centre distance of mounting holes",
    label: "EF024851: Centre distance of mounting holes"
  },
  {
    featureId: "EF024852",
    featureDesc: "Suitable for seat with backrest",
    label: "EF024852: Suitable for seat with backrest"
  },
  {
    featureId: "EF024853",
    featureDesc: "Size of nut/thread ends",
    label: "EF024853: Size of nut/thread ends"
  },
  {
    featureId: "EF024854",
    featureDesc: "Thread end length",
    label: "EF024854: Thread end length"
  },
  {
    featureId: "EF024855",
    featureDesc: "Suitable for bath size",
    label: "EF024855: Suitable for bath size"
  },
  {
    featureId: "EF024856",
    featureDesc: "With end caps",
    label: "EF024856: With end caps"
  },
  {
    featureId: "EF024862",
    featureDesc: "Suitable for seat without backrest",
    label: "EF024862: Suitable for seat without backrest"
  },
  {
    featureId: "EF024863",
    featureDesc: "Access",
    label: "EF024863: Access"
  },
  {
    featureId: "EF024864",
    featureDesc: "Material panels",
    label: "EF024864: Material panels"
  },
  {
    featureId: "EF024865",
    featureDesc: "Profile colour",
    label: "EF024865: Profile colour"
  },
  {
    featureId: "EF024866",
    featureDesc: "Outer dimension 1 (largest dimension)",
    label: "EF024866: Outer dimension 1 (largest dimension)"
  },
  {
    featureId: "EF024867",
    featureDesc: "Outer dimension 2",
    label: "EF024867: Outer dimension 2"
  },
  {
    featureId: "EF024869",
    featureDesc: "System colour",
    label: "EF024869: System colour"
  },
  {
    featureId: "EF024870",
    featureDesc: "With shower-toilet",
    label: "EF024870: With shower-toilet"
  },
  {
    featureId: "EF024871",
    featureDesc: "With alarm control",
    label: "EF024871: With alarm control"
  },
  {
    featureId: "EF024872",
    featureDesc: "Suitable for extended wall-hung WC pan",
    label: "EF024872: Suitable for extended wall-hung WC pan"
  },
  {
    featureId: "EF024873",
    featureDesc: "Max. static load (at water pressure of 2 bars)",
    label: "EF024873: Max. static load (at water pressure of 2 bars)"
  },
  {
    featureId: "EF024874",
    featureDesc: "Straight wall",
    label: "EF024874: Straight wall"
  },
  {
    featureId: "EF024875",
    featureDesc: "Material cabin",
    label: "EF024875: Material cabin"
  },
  {
    featureId: "EF024876",
    featureDesc: "Profile gloss",
    label: "EF024876: Profile gloss"
  },
  {
    featureId: "EF024877",
    featureDesc: "Tap",
    label: "EF024877: Tap"
  },
  {
    featureId: "EF024878",
    featureDesc: "With steam",
    label: "EF024878: With steam"
  },
  {
    featureId: "EF024879",
    featureDesc: "With infrared",
    label: "EF024879: With infrared"
  },
  {
    featureId: "EF024880",
    featureDesc: "With light therapy",
    label: "EF024880: With light therapy"
  },
  {
    featureId: "EF024881",
    featureDesc: "With aroma therapy",
    label: "EF024881: With aroma therapy"
  },
  {
    featureId: "EF024882",
    featureDesc: "With radio",
    label: "EF024882: With radio"
  },
  {
    featureId: "EF024883",
    featureDesc: "With jets",
    label: "EF024883: With jets"
  },
  {
    featureId: "EF024884",
    featureDesc: "With solarium",
    label: "EF024884: With solarium"
  },
  {
    featureId: "EF024885",
    featureDesc: "With head shower",
    label: "EF024885: With head shower"
  },
  {
    featureId: "EF024886",
    featureDesc: "With seats",
    label: "EF024886: With seats"
  },
  {
    featureId: "EF024887",
    featureDesc: "Number of seats",
    label: "EF024887: Number of seats"
  },
  {
    featureId: "EF024888",
    featureDesc: "Built-in width of door for mounting in niche",
    label: "EF024888: Built-in width of door for mounting in niche"
  },
  {
    featureId: "EF024889",
    featureDesc: "Built-in width of door for mounting in line",
    label: "EF024889: Built-in width of door for mounting in line"
  },
  {
    featureId: "EF024890",
    featureDesc: "Built-in width of door for mounting with side wall",
    label: "EF024890: Built-in width of door for mounting with side wall"
  },
  {
    featureId: "EF024891",
    featureDesc: "Built-in length of door for corner entry",
    label: "EF024891: Built-in length of door for corner entry"
  },
  {
    featureId: "EF024892",
    featureDesc: "Built-in width of door for U mounting",
    label: "EF024892: Built-in width of door for U mounting"
  },
  {
    featureId: "EF024893",
    featureDesc: "Material door",
    label: "EF024893: Material door"
  },
  {
    featureId: "EF024894",
    featureDesc: "Anti lime scale treatment",
    label: "EF024894: Anti lime scale treatment"
  },
  {
    featureId: "EF024895",
    featureDesc: "Glass/plastic decor",
    label: "EF024895: Glass/plastic decor"
  },
  {
    featureId: "EF024896",
    featureDesc: "Standard colour",
    label: "EF024896: Standard colour"
  },
  {
    featureId: "EF024897",
    featureDesc: "Degree of gloss",
    label: "EF024897: Degree of gloss"
  },
  {
    featureId: "EF024903",
    featureDesc: "With cabinet",
    label: "EF024903: With cabinet"
  },
  {
    featureId: "EF024906",
    featureDesc: "Rosette length",
    label: "EF024906: Rosette length"
  },
  {
    featureId: "EF024907",
    featureDesc: "Rosette width",
    label: "EF024907: Rosette width"
  },
  {
    featureId: "EF024908",
    featureDesc: "Tube diameter in inches",
    label: "EF024908: Tube diameter in inches"
  },
  {
    featureId: "EF024909",
    featureDesc: "Material compression ring",
    label: "EF024909: Material compression ring"
  },
  {
    featureId: "EF024911",
    featureDesc: "Thread size nut",
    label: "EF024911: Thread size nut"
  },
  {
    featureId: "EF024918",
    featureDesc: "Angle of connection 3 (branch angle)",
    label: "EF024918: Angle of connection 3 (branch angle)"
  },
  {
    featureId: "EF024919",
    featureDesc: "Pressure-compensating",
    label: "EF024919: Pressure-compensating"
  },
  {
    featureId: "EF024920",
    featureDesc: "Sensor detection range",
    label: "EF024920: Sensor detection range"
  },
  {
    featureId: "EF024921",
    featureDesc: "Automatic cycle flushing",
    label: "EF024921: Automatic cycle flushing"
  },
  {
    featureId: "EF024922",
    featureDesc: "Frequency of cycle flushing",
    label: "EF024922: Frequency of cycle flushing"
  },
  {
    featureId: "EF024923",
    featureDesc: "Cleanliness",
    label: "EF024923: Cleanliness"
  },
  {
    featureId: "EF024928",
    featureDesc: "End fastening",
    label: "EF024928: End fastening"
  },
  {
    featureId: "EF024929",
    featureDesc: "Cable diameter",
    label: "EF024929: Cable diameter"
  },
  {
    featureId: "EF024930",
    featureDesc: "Cable material",
    label: "EF024930: Cable material"
  },
  {
    featureId: "EF024932",
    featureDesc: "Material locking device/tensioner",
    label: "EF024932: Material locking device/tensioner"
  },
  {
    featureId: "EF024938",
    featureDesc: "Time constant",
    label: "EF024938: Time constant"
  },
  {
    featureId: "EF024942",
    featureDesc: "Model (indoor unit)",
    label: "EF024942: Model (indoor unit)"
  },
  {
    featureId: "EF024943",
    featureDesc: "Condensing unit, water-cooled",
    label: "EF024943: Condensing unit, water-cooled"
  },
  {
    featureId: "EF024944",
    featureDesc: "Inverter-controlled",
    label: "EF024944: Inverter-controlled"
  },
  {
    featureId: "EF024945",
    featureDesc: "Type of refrigerant",
    label: "EF024945: Type of refrigerant"
  },
  {
    featureId: "EF024946",
    featureDesc: "Connection voltage (system)",
    label: "EF024946: Connection voltage (system)"
  },
  {
    featureId: "EF024947",
    featureDesc: "Fuse (slow)",
    label: "EF024947: Fuse (slow)"
  },
  {
    featureId: "EF024948",
    featureDesc: "Cooling input power",
    label: "EF024948: Cooling input power"
  },
  {
    featureId: "EF024949",
    featureDesc: "Cooling capacity",
    label: "EF024949: Cooling capacity"
  },
  {
    featureId: "EF024950",
    featureDesc: "Heating input power",
    label: "EF024950: Heating input power"
  },
  {
    featureId: "EF024952",
    featureDesc: "EER (cooling)",
    label: "EF024952: EER (cooling)"
  },
  {
    featureId: "EF024954",
    featureDesc: "COP (heating)",
    label: "EF024954: COP (heating)"
  },
  {
    featureId: "EF024955",
    featureDesc: "Energy efficiency class cooling",
    label: "EF024955: Energy efficiency class cooling"
  },
  {
    featureId: "EF024956",
    featureDesc: "Energy efficiency class heating",
    label: "EF024956: Energy efficiency class heating"
  },
  {
    featureId: "EF024958",
    featureDesc: "Colour indoor unit",
    label: "EF024958: Colour indoor unit"
  },
  {
    featureId: "EF024959",
    featureDesc: "Cooling air flow rate",
    label: "EF024959: Cooling air flow rate"
  },
  {
    featureId: "EF024960",
    featureDesc: "Heating air flow rate",
    label: "EF024960: Heating air flow rate"
  },
  {
    featureId: "EF024961",
    featureDesc: "Sound pressure level indoor unit for cooling",
    label: "EF024961: Sound pressure level indoor unit for cooling"
  },
  {
    featureId: "EF024962",
    featureDesc: "Sound pressure level indoor unit for heating",
    label: "EF024962: Sound pressure level indoor unit for heating"
  },
  {
    featureId: "EF024963",
    featureDesc: "Acoustic capacity level indoor unit",
    label: "EF024963: Acoustic capacity level indoor unit"
  },
  {
    featureId: "EF024964",
    featureDesc: "Sound pressure level outdoor unit for cooling",
    label: "EF024964: Sound pressure level outdoor unit for cooling"
  },
  {
    featureId: "EF024965",
    featureDesc: "Sound pressure level outdoor unit for heating",
    label: "EF024965: Sound pressure level outdoor unit for heating"
  },
  {
    featureId: "EF024966",
    featureDesc: "Acoustic capacity level outdoor unit",
    label: "EF024966: Acoustic capacity level outdoor unit"
  },
  {
    featureId: "EF024967",
    featureDesc: "Nominal diameter fluid pipe",
    label: "EF024967: Nominal diameter fluid pipe"
  },
  {
    featureId: "EF024968",
    featureDesc: "Outer pipe diameter of fluid pipe",
    label: "EF024968: Outer pipe diameter of fluid pipe"
  },
  {
    featureId: "EF024969",
    featureDesc: "Outer pipe diameter condensate drain",
    label: "EF024969: Outer pipe diameter condensate drain"
  },
  {
    featureId: "EF024970",
    featureDesc: "Operating temperature for cooling (Dry Bulb)",
    label: "EF024970: Operating temperature for cooling (Dry Bulb)"
  },
  {
    featureId: "EF024972",
    featureDesc: "Operating temperature for heating (Wet Bulb)",
    label: "EF024972: Operating temperature for heating (Wet Bulb)"
  },
  {
    featureId: "EF024973",
    featureDesc: "Min. pipe length",
    label: "EF024973: Min. pipe length"
  },
  {
    featureId: "EF024974",
    featureDesc: "Max. pipe length",
    label: "EF024974: Max. pipe length"
  },
  {
    featureId: "EF024975",
    featureDesc: "Max. height difference",
    label: "EF024975: Max. height difference"
  },
  {
    featureId: "EF024976",
    featureDesc: "With air cleaning filter",
    label: "EF024976: With air cleaning filter"
  },
  {
    featureId: "EF024977",
    featureDesc: "With odour filter",
    label: "EF024977: With odour filter"
  },
  {
    featureId: "EF024978",
    featureDesc: "With clock programme/timer",
    label: "EF024978: With clock programme/timer"
  },
  {
    featureId: "EF024979",
    featureDesc: "Expansion type",
    label: "EF024979: Expansion type"
  },
  {
    featureId: "EF024980",
    featureDesc: "With pipe set",
    label: "EF024980: With pipe set"
  },
  {
    featureId: "EF024981",
    featureDesc: "Pipe set with rapid couplings",
    label: "EF024981: Pipe set with rapid couplings"
  },
  {
    featureId: "EF024982",
    featureDesc: "Length of pipe set",
    label: "EF024982: Length of pipe set"
  },
  {
    featureId: "EF024983",
    featureDesc: "Indoor unit with outside air connection",
    label: "EF024983: Indoor unit with outside air connection"
  },
  {
    featureId: "EF024984",
    featureDesc: "Number of cooling circuits (compressors)",
    label: "EF024984: Number of cooling circuits (compressors)"
  },
  {
    featureId: "EF024985",
    featureDesc: "Total number of indoor units",
    label: "EF024985: Total number of indoor units"
  },
  {
    featureId: "EF024986",
    featureDesc: "Total cooling capacity",
    label: "EF024986: Total cooling capacity"
  },
  {
    featureId: "EF024987",
    featureDesc: "Min. pipe length per circuit",
    label: "EF024987: Min. pipe length per circuit"
  },
  {
    featureId: "EF024988",
    featureDesc: "Max. pipe length per circuit",
    label: "EF024988: Max. pipe length per circuit"
  },
  {
    featureId: "EF024989",
    featureDesc: "Max. total pipe length",
    label: "EF024989: Max. total pipe length"
  },
  {
    featureId: "EF024990",
    featureDesc: "VRF model",
    label: "EF024990: VRF model"
  },
  {
    featureId: "EF024991",
    featureDesc: "Suitable for number of indoor units",
    label: "EF024991: Suitable for number of indoor units"
  },
  {
    featureId: "EF024992",
    featureDesc: "VRF model (2-pipe)",
    label: "EF024992: VRF model (2-pipe)"
  },
  {
    featureId: "EF024993",
    featureDesc: "VRF model (3-pipe)",
    label: "EF024993: VRF model (3-pipe)"
  },
  {
    featureId: "EF024994",
    featureDesc: "Total heating capacity",
    label: "EF024994: Total heating capacity"
  },
  {
    featureId: "EF024995",
    featureDesc: "With water control valve",
    label: "EF024995: With water control valve"
  },
  {
    featureId: "EF024996",
    featureDesc: "Operating range of water temperature for cooling",
    label: "EF024996: Operating range of water temperature for cooling"
  },
  {
    featureId: "EF024997",
    featureDesc: "Operating range of water temperature for heating",
    label: "EF024997: Operating range of water temperature for heating"
  },
  {
    featureId: "EF024998",
    featureDesc: "Water flow rate",
    label: "EF024998: Water flow rate"
  },
  {
    featureId: "EF024999",
    featureDesc: "Min. water supply pressure",
    label: "EF024999: Min. water supply pressure"
  },
  {
    featureId: "EF025001",
    featureDesc: "Conversion kit required",
    label: "EF025001: Conversion kit required"
  },
  {
    featureId: "EF025002",
    featureDesc: "Tiltable mirror",
    label: "EF025002: Tiltable mirror"
  },
  {
    featureId: "EF025003",
    featureDesc: "Expansion with accessories possible",
    label: "EF025003: Expansion with accessories possible"
  },
  {
    featureId: "EF025004",
    featureDesc: "Width (top)",
    label: "EF025004: Width (top)"
  },
  {
    featureId: "EF025005",
    featureDesc: "Width (bottom)",
    label: "EF025005: Width (bottom)"
  },
  {
    featureId: "EF025006",
    featureDesc: "Reference length (ISO)",
    label: "EF025006: Reference length (ISO)"
  },
  {
    featureId: "EF025007",
    featureDesc: "Effective length (RMA)",
    label: "EF025007: Effective length (RMA)"
  },
  {
    featureId: "EF025008",
    featureDesc: "Sheathed",
    label: "EF025008: Sheathed"
  },
  {
    featureId: "EF025009",
    featureDesc: "With top layer",
    label: "EF025009: With top layer"
  },
  {
    featureId: "EF025010",
    featureDesc: "Statically conductive according to ISO 1813",
    label: "EF025010: Statically conductive according to ISO 1813"
  },
  {
    featureId: "EF025011",
    featureDesc: "Shape-toothed model",
    label: "EF025011: Shape-toothed model"
  },
  {
    featureId: "EF025012",
    featureDesc: "Double V-profile model",
    label: "EF025012: Double V-profile model"
  },
  {
    featureId: "EF025013",
    featureDesc: "Min. disc diameter",
    label: "EF025013: Min. disc diameter"
  },
  {
    featureId: "EF025014",
    featureDesc: "Max. bending frequency",
    label: "EF025014: Max. bending frequency"
  },
  {
    featureId: "EF025015",
    featureDesc: "Max. belt speed",
    label: "EF025015: Max. belt speed"
  },
  {
    featureId: "EF025016",
    featureDesc: "With perforation",
    label: "EF025016: With perforation"
  },
  {
    featureId: "EF025017",
    featureDesc: "Min. air volume",
    label: "EF025017: Min. air volume"
  },
  {
    featureId: "EF025018",
    featureDesc: "Temperature controller model",
    label: "EF025018: Temperature controller model"
  },
  {
    featureId: "EF025019",
    featureDesc: "Driving",
    label: "EF025019: Driving"
  },
  {
    featureId: "EF025020",
    featureDesc: "Reset overheating protection",
    label: "EF025020: Reset overheating protection"
  },
  {
    featureId: "EF025021",
    featureDesc: "Fault monitoring",
    label: "EF025021: Fault monitoring"
  },
  {
    featureId: "EF025025",
    featureDesc: "Nominal power at 80-60 °C, inlet 0 °C",
    label: "EF025025: Nominal power at 80-60 °C, inlet 0 °C"
  },
  {
    featureId: "EF025026",
    featureDesc: "Nominal power at 80-60 °C, inlet 15 °C",
    label: "EF025026: Nominal power at 80-60 °C, inlet 15 °C"
  },
  {
    featureId: "EF025027",
    featureDesc: "Nominal power at 60-40 °C, inlet 0 °C",
    label: "EF025027: Nominal power at 60-40 °C, inlet 0 °C"
  },
  {
    featureId: "EF025028",
    featureDesc: "Nominal power at 60-40 °C, inlet 15 °C",
    label: "EF025028: Nominal power at 60-40 °C, inlet 15 °C"
  },
  {
    featureId: "EF025029",
    featureDesc: "Nominal power at 55-45 °C, inlet 0 °C",
    label: "EF025029: Nominal power at 55-45 °C, inlet 0 °C"
  },
  {
    featureId: "EF025030",
    featureDesc: "Nominal power at 55-45 °C, inlet 15 °C",
    label: "EF025030: Nominal power at 55-45 °C, inlet 15 °C"
  },
  {
    featureId: "EF025032",
    featureDesc: "Water-side resistance",
    label: "EF025032: Water-side resistance"
  },
  {
    featureId: "EF025033",
    featureDesc: "Accessories/spare parts for duct heater, electric",
    label: "EF025033: Accessories/spare parts for duct heater, electric"
  },
  {
    featureId: "EF025034",
    featureDesc: "Accessories/spare parts for duct heater central heating",
    label: "EF025034: Accessories/spare parts for duct heater central heating"
  },
  {
    featureId: "EF025035",
    featureDesc: "Cooling pipe model",
    label: "EF025035: Cooling pipe model"
  },
  {
    featureId: "EF025036",
    featureDesc: "Sound pressure level (cooling)",
    label: "EF025036: Sound pressure level (cooling)"
  },
  {
    featureId: "EF025037",
    featureDesc: "Sound pressure level (heating)",
    label: "EF025037: Sound pressure level (heating)"
  },
  {
    featureId: "EF025047",
    featureDesc: "Exhaust temperature",
    label: "EF025047: Exhaust temperature"
  },
  {
    featureId: "EF025048",
    featureDesc: "Pressure loss at nominal air volume",
    label: "EF025048: Pressure loss at nominal air volume"
  },
  {
    featureId: "EF025049",
    featureDesc: "Number of boiler connections",
    label: "EF025049: Number of boiler connections"
  },
  {
    featureId: "EF025053",
    featureDesc: "Installation side connection model",
    label: "EF025053: Installation side connection model"
  },
  {
    featureId: "EF025054",
    featureDesc: "Nominal diameter shunt pipe",
    label: "EF025054: Nominal diameter shunt pipe"
  },
  {
    featureId: "EF025055",
    featureDesc: "Number of air-release connections",
    label: "EF025055: Number of air-release connections"
  },
  {
    featureId: "EF025056",
    featureDesc: "Nominal diameter air-release",
    label: "EF025056: Nominal diameter air-release"
  },
  {
    featureId: "EF025057",
    featureDesc: "Corner bath",
    label: "EF025057: Corner bath"
  },
  {
    featureId: "EF025058",
    featureDesc: "Semi-freestanding",
    label: "EF025058: Semi-freestanding"
  },
  {
    featureId: "EF025059",
    featureDesc: "Depth (internal to overflow)",
    label: "EF025059: Depth (internal to overflow)"
  },
  {
    featureId: "EF025060",
    featureDesc: "Legs adjustable",
    label: "EF025060: Legs adjustable"
  },
  {
    featureId: "EF025061",
    featureDesc: "Bottom length (lying-length)",
    label: "EF025061: Bottom length (lying-length)"
  },
  {
    featureId: "EF025062",
    featureDesc: "Drilled hole for handle optional",
    label: "EF025062: Drilled hole for handle optional"
  },
  {
    featureId: "EF025063",
    featureDesc: "Bath panels optional",
    label: "EF025063: Bath panels optional"
  },
  {
    featureId: "EF025064",
    featureDesc: "With bath panels",
    label: "EF025064: With bath panels"
  },
  {
    featureId: "EF025065",
    featureDesc: "With overflow hole",
    label: "EF025065: With overflow hole"
  },
  {
    featureId: "EF025066",
    featureDesc: "Location of drain hole",
    label: "EF025066: Location of drain hole"
  },
  {
    featureId: "EF025067",
    featureDesc: "Antislip facility optional",
    label: "EF025067: Antislip facility optional"
  },
  {
    featureId: "EF025068",
    featureDesc: "With antislip facility",
    label: "EF025068: With antislip facility"
  },
  {
    featureId: "EF025069",
    featureDesc: "Drilled tap hole optional",
    label: "EF025069: Drilled tap hole optional"
  },
  {
    featureId: "EF025070",
    featureDesc: "With drilled tap hole",
    label: "EF025070: With drilled tap hole"
  },
  {
    featureId: "EF025071",
    featureDesc: "Injection type",
    label: "EF025071: Injection type"
  },
  {
    featureId: "EF025073",
    featureDesc: "With polystyrene support",
    label: "EF025073: With polystyrene support"
  },
  {
    featureId: "EF025074",
    featureDesc: "Height including legs/support",
    label: "EF025074: Height including legs/support"
  },
  {
    featureId: "EF025075",
    featureDesc: "With shower tray panels",
    label: "EF025075: With shower tray panels"
  },
  {
    featureId: "EF025076",
    featureDesc: "With tiled edge",
    label: "EF025076: With tiled edge"
  },
  {
    featureId: "EF025078",
    featureDesc: "Suitable for panels",
    label: "EF025078: Suitable for panels"
  },
  {
    featureId: "EF025079",
    featureDesc: "With acoustic insulation",
    label: "EF025079: With acoustic insulation"
  },
  {
    featureId: "EF025081",
    featureDesc: "Connection 2 (outlet)",
    label: "EF025081: Connection 2 (outlet)"
  },
  {
    featureId: "EF025082",
    featureDesc: "Emergency shower controls",
    label: "EF025082: Emergency shower controls"
  },
  {
    featureId: "EF025083",
    featureDesc: "Eye-face shower controls",
    label: "EF025083: Eye-face shower controls"
  },
  {
    featureId: "EF025084",
    featureDesc: "Connection for hand shower",
    label: "EF025084: Connection for hand shower"
  },
  {
    featureId: "EF025085",
    featureDesc: "Internal parts self-cleaning",
    label: "EF025085: Internal parts self-cleaning"
  },
  {
    featureId: "EF025086",
    featureDesc: "With built-in installation box",
    label: "EF025086: With built-in installation box"
  },
  {
    featureId: "EF025087",
    featureDesc: "Frost-free model",
    label: "EF025087: Frost-free model"
  },
  {
    featureId: "EF025088",
    featureDesc: "Connection size of mixed water inlet",
    label: "EF025088: Connection size of mixed water inlet"
  },
  {
    featureId: "EF025089",
    featureDesc: "Designed as a shower station",
    label: "EF025089: Designed as a shower station"
  },
  {
    featureId: "EF025090",
    featureDesc: "With seat",
    label: "EF025090: With seat"
  },
  {
    featureId: "EF025091",
    featureDesc: "With side showers",
    label: "EF025091: With side showers"
  },
  {
    featureId: "EF025092",
    featureDesc: "Number of side showers",
    label: "EF025092: Number of side showers"
  },
  {
    featureId: "EF025093",
    featureDesc: "Side showers adjustable",
    label: "EF025093: Side showers adjustable"
  },
  {
    featureId: "EF025094",
    featureDesc: "Shower hose length",
    label: "EF025094: Shower hose length"
  },
  {
    featureId: "EF025095",
    featureDesc: "Side showers with adjustable flow",
    label: "EF025095: Side showers with adjustable flow"
  },
  {
    featureId: "EF025096",
    featureDesc: "Hand shower height-adjustable",
    label: "EF025096: Hand shower height-adjustable"
  },
  {
    featureId: "EF025097",
    featureDesc: "Min. flow (mixed water)",
    label: "EF025097: Min. flow (mixed water)"
  },
  {
    featureId: "EF025098",
    featureDesc: "Flushing time adjustable",
    label: "EF025098: Flushing time adjustable"
  },
  {
    featureId: "EF025099",
    featureDesc: "Flushing time",
    label: "EF025099: Flushing time"
  },
  {
    featureId: "EF025102",
    featureDesc: "Programming",
    label: "EF025102: Programming"
  },
  {
    featureId: "EF025103",
    featureDesc: "With intermediate flushing",
    label: "EF025103: With intermediate flushing"
  },
  {
    featureId: "EF025104",
    featureDesc: "CE mark",
    label: "EF025104: CE mark"
  },
  {
    featureId: "EF025105",
    featureDesc: "Washing-up shower overhang length",
    label: "EF025105: Washing-up shower overhang length"
  },
  {
    featureId: "EF025106",
    featureDesc: "Suitable for cabinet width",
    label: "EF025106: Suitable for cabinet width"
  },
  {
    featureId: "EF025108",
    featureDesc: "Suitable for floor mounting",
    label: "EF025108: Suitable for floor mounting"
  },
  {
    featureId: "EF025109",
    featureDesc: "Surface treatment",
    label: "EF025109: Surface treatment"
  },
  {
    featureId: "EF025110",
    featureDesc: "Number of floors",
    label: "EF025110: Number of floors"
  },
  {
    featureId: "EF025111",
    featureDesc: "With stick",
    label: "EF025111: With stick"
  },
  {
    featureId: "EF025112",
    featureDesc: "Brush material",
    label: "EF025112: Brush material"
  },
  {
    featureId: "EF025114",
    featureDesc: "Pipe distance",
    label: "EF025114: Pipe distance"
  },
  {
    featureId: "EF025115",
    featureDesc: "Insert depth",
    label: "EF025115: Insert depth"
  },
  {
    featureId: "EF025116",
    featureDesc: "Connection inlet side",
    label: "EF025116: Connection inlet side"
  },
  {
    featureId: "EF025117",
    featureDesc: "Nominal diameter connection inlet side",
    label: "EF025117: Nominal diameter connection inlet side"
  },
  {
    featureId: "EF025118",
    featureDesc: "Nominal diameter connection outlet side",
    label: "EF025118: Nominal diameter connection outlet side"
  },
  {
    featureId: "EF025120",
    featureDesc: "Suitable for sprinkling/irrigation",
    label: "EF025120: Suitable for sprinkling/irrigation"
  },
  {
    featureId: "EF025121",
    featureDesc: "Max. number of expansion groups",
    label: "EF025121: Max. number of expansion groups"
  },
  {
    featureId: "EF025123",
    featureDesc: "Seat height",
    label: "EF025123: Seat height"
  },
  {
    featureId: "EF025124",
    featureDesc: "Max. width toilet",
    label: "EF025124: Max. width toilet"
  },
  {
    featureId: "EF025125",
    featureDesc: "Suitable for toilet height",
    label: "EF025125: Suitable for toilet height"
  },
  {
    featureId: "EF025126",
    featureDesc: "With top flange direct mount according to ISO 5211",
    label: "EF025126: With top flange direct mount according to ISO 5211"
  },
  {
    featureId: "EF025127",
    featureDesc: "Number of nests",
    label: "EF025127: Number of nests"
  },
  {
    featureId: "EF025128",
    featureDesc: "Top flange connection",
    label: "EF025128: Top flange connection"
  },
  {
    featureId: "EF025129",
    featureDesc: "Mounting flange connection",
    label: "EF025129: Mounting flange connection"
  },
  {
    featureId: "EF025130",
    featureDesc: "Double-acting torque",
    label: "EF025130: Double-acting torque"
  },
  {
    featureId: "EF025131",
    featureDesc: "Number of welding torches",
    label: "EF025131: Number of welding torches"
  },
  {
    featureId: "EF025132",
    featureDesc: "Number of cutting torches",
    label: "EF025132: Number of cutting torches"
  },
  {
    featureId: "EF025133",
    featureDesc: "Diameter measuring head",
    label: "EF025133: Diameter measuring head"
  },
  {
    featureId: "EF025134",
    featureDesc: "Sling width",
    label: "EF025134: Sling width"
  },
  {
    featureId: "EF025135",
    featureDesc: "Sling length",
    label: "EF025135: Sling length"
  },
  {
    featureId: "EF025136",
    featureDesc: "Torque single acting start air",
    label: "EF025136: Torque single acting start air"
  },
  {
    featureId: "EF025137",
    featureDesc: "Torque single acting end spring",
    label: "EF025137: Torque single acting end spring"
  },
  {
    featureId: "EF025138",
    featureDesc: "Low noise",
    label: "EF025138: Low noise"
  },
  {
    featureId: "EF025140",
    featureDesc: "With float",
    label: "EF025140: With float"
  },
  {
    featureId: "EF025142",
    featureDesc: "Connection air extraction",
    label: "EF025142: Connection air extraction"
  },
  {
    featureId: "EF025143",
    featureDesc: "Diameter air extraction",
    label: "EF025143: Diameter air extraction"
  },
  {
    featureId: "EF025144",
    featureDesc: "For connection boiler to heat recovery unit",
    label: "EF025144: For connection boiler to heat recovery unit"
  },
  {
    featureId: "EF025145",
    featureDesc: "With built-in leak detection",
    label: "EF025145: With built-in leak detection"
  },
  {
    featureId: "EF025146",
    featureDesc: "With needle vent",
    label: "EF025146: With needle vent"
  },
  {
    featureId: "EF025149",
    featureDesc: "With hand pump",
    label: "EF025149: With hand pump"
  },
  {
    featureId: "EF025150",
    featureDesc: "With sampling probe",
    label: "EF025150: With sampling probe"
  },
  {
    featureId: "EF025151",
    featureDesc: "Length sampling hose",
    label: "EF025151: Length sampling hose"
  },
  {
    featureId: "EF025152",
    featureDesc: "Sensor class",
    label: "EF025152: Sensor class"
  },
  {
    featureId: "EF025153",
    featureDesc: "Filled",
    label: "EF025153: Filled"
  },
  {
    featureId: "EF025154",
    featureDesc: "Type of filling",
    label: "EF025154: Type of filling"
  },
  {
    featureId: "EF025155",
    featureDesc: "Net weight",
    label: "EF025155: Net weight"
  },
  {
    featureId: "EF025156",
    featureDesc: "With suspension bracket",
    label: "EF025156: With suspension bracket"
  },
  {
    featureId: "EF025157",
    featureDesc: "Min. die head size",
    label: "EF025157: Min. die head size"
  },
  {
    featureId: "EF025158",
    featureDesc: "Max. die head size",
    label: "EF025158: Max. die head size"
  },
  {
    featureId: "EF025159",
    featureDesc: "Suitable for min. pipe diameter",
    label: "EF025159: Suitable for min. pipe diameter"
  },
  {
    featureId: "EF025160",
    featureDesc: "Fibre rings",
    label: "EF025160: Fibre rings"
  },
  {
    featureId: "EF025161",
    featureDesc: "Membranes",
    label: "EF025161: Membranes"
  },
  {
    featureId: "EF025162",
    featureDesc: "O-rings",
    label: "EF025162: O-rings"
  },
  {
    featureId: "EF025163",
    featureDesc: "STAR number",
    label: "EF025163: STAR number"
  },
  {
    featureId: "EF025164",
    featureDesc: "Font height",
    label: "EF025164: Font height"
  },
  {
    featureId: "EF025167",
    featureDesc: "Type of bonnet",
    label: "EF025167: Type of bonnet"
  },
  {
    featureId: "EF025168",
    featureDesc: "Suitable for float-controlled trap",
    label: "EF025168: Suitable for float-controlled trap"
  },
  {
    featureId: "EF025169",
    featureDesc: "Suitable for thermostatic steam trap",
    label: "EF025169: Suitable for thermostatic steam trap"
  },
  {
    featureId: "EF025170",
    featureDesc: "Suitable for bell float-controlled trap",
    label: "EF025170: Suitable for bell float-controlled trap"
  },
  {
    featureId: "EF025171",
    featureDesc: "Suitable for bimetal steam trap",
    label: "EF025171: Suitable for bimetal steam trap"
  },
  {
    featureId: "EF025172",
    featureDesc: "Material insert",
    label: "EF025172: Material insert"
  },
  {
    featureId: "EF025173",
    featureDesc: "Single sided sealing",
    label: "EF025173: Single sided sealing"
  },
  {
    featureId: "EF025174",
    featureDesc: "Material valve",
    label: "EF025174: Material valve"
  },
  {
    featureId: "EF025175",
    featureDesc: "Material electrode bar",
    label: "EF025175: Material electrode bar"
  },
  {
    featureId: "EF025176",
    featureDesc: "Material quality bellows",
    label: "EF025176: Material quality bellows"
  },
  {
    featureId: "EF025177",
    featureDesc: "Accessories radiator coupling",
    label: "EF025177: Accessories radiator coupling"
  },
  {
    featureId: "EF025178",
    featureDesc: "Suitable for two-way control valve",
    label: "EF025178: Suitable for two-way control valve"
  },
  {
    featureId: "EF025179",
    featureDesc: "Suitable for three-way control valve",
    label: "EF025179: Suitable for three-way control valve"
  },
  {
    featureId: "EF025181",
    featureDesc: "Standard construction length",
    label: "EF025181: Standard construction length"
  },
  {
    featureId: "EF025182",
    featureDesc: "Material bonnet",
    label: "EF025182: Material bonnet"
  },
  {
    featureId: "EF025184",
    featureDesc: "Switch-on duration",
    label: "EF025184: Switch-on duration"
  },
  {
    featureId: "EF025185",
    featureDesc: "Max. differential pressure light oil",
    label: "EF025185: Max. differential pressure light oil"
  },
  {
    featureId: "EF025186",
    featureDesc: "Max. differential pressure air/gases",
    label: "EF025186: Max. differential pressure air/gases"
  },
  {
    featureId: "EF025187",
    featureDesc: "Max. differential pressure steam",
    label: "EF025187: Max. differential pressure steam"
  },
  {
    featureId: "EF025188",
    featureDesc: "Max. differential pressure water/liquids",
    label: "EF025188: Max. differential pressure water/liquids"
  },
  {
    featureId: "EF025189",
    featureDesc: "Emergency manual operation",
    label: "EF025189: Emergency manual operation"
  },
  {
    featureId: "EF025190",
    featureDesc: "Max. viscosity",
    label: "EF025190: Max. viscosity"
  },
  {
    featureId: "EF025192",
    featureDesc: "Creased",
    label: "EF025192: Creased"
  },
  {
    featureId: "EF025193",
    featureDesc: "Sintered",
    label: "EF025193: Sintered"
  },
  {
    featureId: "EF025194",
    featureDesc: "Approved according to EN 331",
    label: "EF025194: Approved according to EN 331"
  },
  {
    featureId: "EF025195",
    featureDesc: "Material ball seal",
    label: "EF025195: Material ball seal"
  },
  {
    featureId: "EF025196",
    featureDesc: "Material ball",
    label: "EF025196: Material ball"
  },
  {
    featureId: "EF025197",
    featureDesc: "Material primary spindle seal",
    label: "EF025197: Material primary spindle seal"
  },
  {
    featureId: "EF025198",
    featureDesc: "Material secondary spindle seal",
    label: "EF025198: Material secondary spindle seal"
  },
  {
    featureId: "EF025199",
    featureDesc: "Type of flow indicator",
    label: "EF025199: Type of flow indicator"
  },
  {
    featureId: "EF025200",
    featureDesc: "Suitable for mounting manometer",
    label: "EF025200: Suitable for mounting manometer"
  },
  {
    featureId: "EF025201",
    featureDesc: "With humidity sensor",
    label: "EF025201: With humidity sensor"
  },
  {
    featureId: "EF025202",
    featureDesc: "Connection operation",
    label: "EF025202: Connection operation"
  },
  {
    featureId: "EF025203",
    featureDesc: "Spring closing (NC)",
    label: "EF025203: Spring closing (NC)"
  },
  {
    featureId: "EF025204",
    featureDesc: "Spring opening (NO)",
    label: "EF025204: Spring opening (NO)"
  },
  {
    featureId: "EF025205",
    featureDesc: "Flat seal",
    label: "EF025205: Flat seal"
  },
  {
    featureId: "EF025206",
    featureDesc: "According to regulations maritime inspection",
    label: "EF025206: According to regulations maritime inspection"
  },
  {
    featureId: "EF025208",
    featureDesc: "For ship length",
    label: "EF025208: For ship length"
  },
  {
    featureId: "EF025212",
    featureDesc: "Retaining its shape",
    label: "EF025212: Retaining its shape"
  },
  {
    featureId: "EF025214",
    featureDesc: "With mantle pipe locking",
    label: "EF025214: With mantle pipe locking"
  },
  {
    featureId: "EF025215",
    featureDesc: "Colour inner pipe",
    label: "EF025215: Colour inner pipe"
  },
  {
    featureId: "EF025216",
    featureDesc: "Number of inner pipes",
    label: "EF025216: Number of inner pipes"
  },
  {
    featureId: "EF025217",
    featureDesc: "Diameter inner pipe",
    label: "EF025217: Diameter inner pipe"
  },
  {
    featureId: "EF025218",
    featureDesc: "Saddle piece pressed",
    label: "EF025218: Saddle piece pressed"
  },
  {
    featureId: "EF025219",
    featureDesc: "Sliding plate model",
    label: "EF025219: Sliding plate model"
  },
  {
    featureId: "EF025221",
    featureDesc: "In height-adjustable mounting points",
    label: "EF025221: In height-adjustable mounting points"
  },
  {
    featureId: "EF025222",
    featureDesc: "Symbol",
    label: "EF025222: Symbol"
  },
  {
    featureId: "EF025224",
    featureDesc: "Fast setting according to EN 12004 (F)",
    label: "EF025224: Fast setting according to EN 12004 (F)"
  },
  {
    featureId: "EF025227",
    featureDesc: "Elastic",
    label: "EF025227: Elastic"
  },
  {
    featureId: "EF025228",
    featureDesc: "Decorative",
    label: "EF025228: Decorative"
  },
  {
    featureId: "EF025229",
    featureDesc: "Suitable for radiator with round/sharp top",
    label: "EF025229: Suitable for radiator with round/sharp top"
  },
  {
    featureId: "EF025230",
    featureDesc: "Outer diameter inner pipes",
    label: "EF025230: Outer diameter inner pipes"
  },
  {
    featureId: "EF025231",
    featureDesc: "With guide",
    label: "EF025231: With guide"
  },
  {
    featureId: "EF025233",
    featureDesc: "With granule grain",
    label: "EF025233: With granule grain"
  },
  {
    featureId: "EF025237",
    featureDesc: "With discharge hose",
    label: "EF025237: With discharge hose"
  },
  {
    featureId: "EF025240",
    featureDesc: "Type of shut off valve",
    label: "EF025240: Type of shut off valve"
  },
  {
    featureId: "EF025241",
    featureDesc: "Load-carrying at pressure",
    label: "EF025241: Load-carrying at pressure"
  },
  {
    featureId: "EF025242",
    featureDesc: "Load-carrying at pull",
    label: "EF025242: Load-carrying at pull"
  },
  {
    featureId: "EF025247",
    featureDesc: "Suitable for boiling water",
    label: "EF025247: Suitable for boiling water"
  },
  {
    featureId: "EF025248",
    featureDesc: "With watercooler",
    label: "EF025248: With watercooler"
  },
  {
    featureId: "EF025249",
    featureDesc: "Plinth boiler",
    label: "EF025249: Plinth boiler"
  },
  {
    featureId: "EF025250",
    featureDesc: "Approved according to EN 1359",
    label: "EF025250: Approved according to EN 1359"
  },
  {
    featureId: "EF025251",
    featureDesc: "With connection coupling",
    label: "EF025251: With connection coupling"
  },
  {
    featureId: "EF025252",
    featureDesc: "Capacity",
    label: "EF025252: Capacity"
  },
  {
    featureId: "EF025253",
    featureDesc: "System-specific",
    label: "EF025253: System-specific"
  },
  {
    featureId: "EF025254",
    featureDesc: "Contour code",
    label: "EF025254: Contour code"
  },
  {
    featureId: "EF025255",
    featureDesc: "Handheld",
    label: "EF025255: Handheld"
  },
  {
    featureId: "EF025256",
    featureDesc: "Tube shape",
    label: "EF025256: Tube shape"
  },
  {
    featureId: "EF025257",
    featureDesc: "Material thickness grid",
    label: "EF025257: Material thickness grid"
  },
  {
    featureId: "EF025258",
    featureDesc: "Single edge",
    label: "EF025258: Single edge"
  },
  {
    featureId: "EF025259",
    featureDesc: "Adjustable attachment",
    label: "EF025259: Adjustable attachment"
  },
  {
    featureId: "EF025260",
    featureDesc: "Widened sealing flange",
    label: "EF025260: Widened sealing flange"
  },
  {
    featureId: "EF025261",
    featureDesc: "Shape of grid",
    label: "EF025261: Shape of grid"
  },
  {
    featureId: "EF025262",
    featureDesc: "Shape of perforation grid",
    label: "EF025262: Shape of perforation grid"
  },
  {
    featureId: "EF025263",
    featureDesc: "Height grid",
    label: "EF025263: Height grid"
  },
  {
    featureId: "EF025264",
    featureDesc: "Outer radius grille",
    label: "EF025264: Outer radius grille"
  },
  {
    featureId: "EF025265",
    featureDesc: "Inner radius grille",
    label: "EF025265: Inner radius grille"
  },
  {
    featureId: "EF025266",
    featureDesc: "Grid for tiles",
    label: "EF025266: Grid for tiles"
  },
  {
    featureId: "EF025267",
    featureDesc: "Grid for glass",
    label: "EF025267: Grid for glass"
  },
  {
    featureId: "EF025268",
    featureDesc: "Material quality grid",
    label: "EF025268: Material quality grid"
  },
  {
    featureId: "EF025269",
    featureDesc: "Suitable for pipe diameter roof flashing",
    label: "EF025269: Suitable for pipe diameter roof flashing"
  },
  {
    featureId: "EF025270",
    featureDesc: "Max. dehumidification capacity per 24 hours",
    label: "EF025270: Max. dehumidification capacity per 24 hours"
  },
  {
    featureId: "EF025272",
    featureDesc: "Suction pipe length",
    label: "EF025272: Suction pipe length"
  },
  {
    featureId: "EF025273",
    featureDesc: "Suction hose length",
    label: "EF025273: Suction hose length"
  },
  {
    featureId: "EF025275",
    featureDesc: "With dynamic power booster",
    label: "EF025275: With dynamic power booster"
  },
  {
    featureId: "EF025276",
    featureDesc: "Suitable for top cooling",
    label: "EF025276: Suitable for top cooling"
  },
  {
    featureId: "EF025277",
    featureDesc: "Total cooling capacity 26 °C - 17/19",
    label: "EF025277: Total cooling capacity 26 °C - 17/19"
  },
  {
    featureId: "EF025279",
    featureDesc: "Paintable",
    label: "EF025279: Paintable"
  },
  {
    featureId: "EF025282",
    featureDesc: "Resin content",
    label: "EF025282: Resin content"
  },
  {
    featureId: "EF025283",
    featureDesc: "Salt use per regeneration",
    label: "EF025283: Salt use per regeneration"
  },
  {
    featureId: "EF025284",
    featureDesc: "Capacity at 10°D",
    label: "EF025284: Capacity at 10°D"
  },
  {
    featureId: "EF025297",
    featureDesc: "Colour cover cap",
    label: "EF025297: Colour cover cap"
  },
  {
    featureId: "EF025303",
    featureDesc: "Mesh size",
    label: "EF025303: Mesh size"
  },
  {
    featureId: "EF025305",
    featureDesc: "Number of tiles",
    label: "EF025305: Number of tiles"
  },
  {
    featureId: "EF025306",
    featureDesc: "With ventilation grille",
    label: "EF025306: With ventilation grille"
  },
  {
    featureId: "EF025307",
    featureDesc: "With magnetic closure",
    label: "EF025307: With magnetic closure"
  },
  {
    featureId: "EF025308",
    featureDesc: "Tile size",
    label: "EF025308: Tile size"
  },
  {
    featureId: "EF025310",
    featureDesc: "With roof upstand",
    label: "EF025310: With roof upstand"
  },
  {
    featureId: "EF025311",
    featureDesc: "Length in the clear",
    label: "EF025311: Length in the clear"
  },
  {
    featureId: "EF025312",
    featureDesc: "Width in the clear",
    label: "EF025312: Width in the clear"
  },
  {
    featureId: "EF025314",
    featureDesc: "Load class according to EN 124",
    label: "EF025314: Load class according to EN 124"
  },
  {
    featureId: "EF025315",
    featureDesc: "Max. number of devices",
    label: "EF025315: Max. number of devices"
  },
  {
    featureId: "EF025316",
    featureDesc: "Monolithic gutter",
    label: "EF025316: Monolithic gutter"
  },
  {
    featureId: "EF025317",
    featureDesc: "Slot gutter",
    label: "EF025317: Slot gutter"
  },
  {
    featureId: "EF025318",
    featureDesc: "Buffer gutter",
    label: "EF025318: Buffer gutter"
  },
  {
    featureId: "EF025319",
    featureDesc: "Crinkled",
    label: "EF025319: Crinkled"
  },
  {
    featureId: "EF025320",
    featureDesc: "Type (pounds notation)",
    label: "EF025320: Type (pounds notation)"
  },
  {
    featureId: "EF025321",
    featureDesc: "Dome shape",
    label: "EF025321: Dome shape"
  },
  {
    featureId: "EF025322",
    featureDesc: "With foot pedal",
    label: "EF025322: With foot pedal"
  },
  {
    featureId: "EF025325",
    featureDesc: "Energy consumption per process (temperature 45-60 °C)",
    label: "EF025325: Energy consumption per process (temperature 45-60 °C)"
  },
  {
    featureId: "EF025326",
    featureDesc: "Tube distance",
    label: "EF025326: Tube distance"
  },
  {
    featureId: "EF025327",
    featureDesc: "Suitable for vessel contents",
    label: "EF025327: Suitable for vessel contents"
  },
  {
    featureId: "EF025328",
    featureDesc: "Pulling head diameter",
    label: "EF025328: Pulling head diameter"
  },
  {
    featureId: "EF025331",
    featureDesc: "With decorative fence",
    label: "EF025331: With decorative fence"
  },
  {
    featureId: "EF025332",
    featureDesc: "With ash pan",
    label: "EF025332: With ash pan"
  },
  {
    featureId: "EF025333",
    featureDesc: "Diameter pipe mouth",
    label: "EF025333: Diameter pipe mouth"
  },
  {
    featureId: "EF025334",
    featureDesc: "Cold water connection",
    label: "EF025334: Cold water connection"
  },
  {
    featureId: "EF025335",
    featureDesc: "Gas connection",
    label: "EF025335: Gas connection"
  },
  {
    featureId: "EF025336",
    featureDesc: "With rope",
    label: "EF025336: With rope"
  },
  {
    featureId: "EF025337",
    featureDesc: "Flow-/return connection",
    label: "EF025337: Flow-/return connection"
  },
  {
    featureId: "EF025338",
    featureDesc: "Diameter in the clear",
    label: "EF025338: Diameter in the clear"
  },
  {
    featureId: "EF025339",
    featureDesc: "Water use per regeneration",
    label: "EF025339: Water use per regeneration"
  },
  {
    featureId: "EF025340",
    featureDesc: "Min. mounting height",
    label: "EF025340: Min. mounting height"
  },
  {
    featureId: "EF025341",
    featureDesc: "Bottom width (lying-width)",
    label: "EF025341: Bottom width (lying-width)"
  },
  {
    featureId: "EF025342",
    featureDesc: "Studded panel",
    label: "EF025342: Studded panel"
  },
  {
    featureId: "EF025343",
    featureDesc: "Tacker plate",
    label: "EF025343: Tacker plate"
  },
  {
    featureId: "EF025344",
    featureDesc: "Inlay plate",
    label: "EF025344: Inlay plate"
  },
  {
    featureId: "EF025345",
    featureDesc: "With foil",
    label: "EF025345: With foil"
  },
  {
    featureId: "EF025346",
    featureDesc: "Heat resistance Rd",
    label: "EF025346: Heat resistance Rd"
  },
  {
    featureId: "EF025356",
    featureDesc: "Air tightness class according to EN 1751",
    label: "EF025356: Air tightness class according to EN 1751"
  },
  {
    featureId: "EF025361",
    featureDesc: "Exchangeable filter",
    label: "EF025361: Exchangeable filter"
  },
  {
    featureId: "EF025370",
    featureDesc: "Fungus resistance",
    label: "EF025370: Fungus resistance"
  },
  {
    featureId: "EF025375",
    featureDesc: "Suitable as a desanding pit",
    label: "EF025375: Suitable as a desanding pit"
  },
  {
    featureId: "EF025382",
    featureDesc: "Material mounting garniture",
    label: "EF025382: Material mounting garniture"
  },
  {
    featureId: "EF025383",
    featureDesc: "Surface treatment mounting garniture",
    label: "EF025383: Surface treatment mounting garniture"
  },
  {
    featureId: "EF025384",
    featureDesc: "Fluid",
    label: "EF025384: Fluid"
  },
  {
    featureId: "EF025385",
    featureDesc: "Rosette size",
    label: "EF025385: Rosette size"
  },
  {
    featureId: "EF025386",
    featureDesc: "With shaving set holder",
    label: "EF025386: With shaving set holder"
  },
  {
    featureId: "EF025387",
    featureDesc: "With toilet brush",
    label: "EF025387: With toilet brush"
  },
  {
    featureId: "EF025389",
    featureDesc: "With holder for hairdryer",
    label: "EF025389: With holder for hairdryer"
  },
  {
    featureId: "EF025390",
    featureDesc: "With medicine cabinet",
    label: "EF025390: With medicine cabinet"
  },
  {
    featureId: "EF025391",
    featureDesc: "With tissue holder",
    label: "EF025391: With tissue holder"
  },
  {
    featureId: "EF025392",
    featureDesc: "With trash can",
    label: "EF025392: With trash can"
  },
  {
    featureId: "EF025393",
    featureDesc: "With built-in frame",
    label: "EF025393: With built-in frame"
  },
  {
    featureId: "EF025395",
    featureDesc: "Wiping arc continuously adjustable",
    label: "EF025395: Wiping arc continuously adjustable"
  },
  {
    featureId: "EF025396",
    featureDesc: "Number of tabs",
    label: "EF025396: Number of tabs"
  },
  {
    featureId: "EF025397",
    featureDesc: "Sight height",
    label: "EF025397: Sight height"
  },
  {
    featureId: "EF025398",
    featureDesc: "Reel blade diameter",
    label: "EF025398: Reel blade diameter"
  },
  {
    featureId: "EF025399",
    featureDesc: "Length (inflated)",
    label: "EF025399: Length (inflated)"
  },
  {
    featureId: "EF025400",
    featureDesc: "Width (inflated)",
    label: "EF025400: Width (inflated)"
  },
  {
    featureId: "EF025401",
    featureDesc: "Height (inflated)",
    label: "EF025401: Height (inflated)"
  },
  {
    featureId: "EF025403",
    featureDesc: "With heat shield",
    label: "EF025403: With heat shield"
  },
  {
    featureId: "EF025406",
    featureDesc: "With brake",
    label: "EF025406: With brake"
  },
  {
    featureId: "EF025407",
    featureDesc: "With drawers",
    label: "EF025407: With drawers"
  },
  {
    featureId: "EF025408",
    featureDesc: "With insert ring",
    label: "EF025408: With insert ring"
  },
  {
    featureId: "EF025409",
    featureDesc: "Head height",
    label: "EF025409: Head height"
  },
  {
    featureId: "EF025411",
    featureDesc: "Wrench width",
    label: "EF025411: Wrench width"
  },
  {
    featureId: "EF025413",
    featureDesc: "Number of end cutting pliers",
    label: "EF025413: Number of end cutting pliers"
  },
  {
    featureId: "EF025414",
    featureDesc: "Number of washstand pliers",
    label: "EF025414: Number of washstand pliers"
  },
  {
    featureId: "EF025415",
    featureDesc: "Number of combination pliers",
    label: "EF025415: Number of combination pliers"
  },
  {
    featureId: "EF025416",
    featureDesc: "Number of flat nose pliers",
    label: "EF025416: Number of flat nose pliers"
  },
  {
    featureId: "EF025417",
    featureDesc: "Number of round nose pliers",
    label: "EF025417: Number of round nose pliers"
  },
  {
    featureId: "EF025418",
    featureDesc: "Number of telephone pliers",
    label: "EF025418: Number of telephone pliers"
  },
  {
    featureId: "EF025419",
    featureDesc: "Number of diagonal cutting nippers",
    label: "EF025419: Number of diagonal cutting nippers"
  },
  {
    featureId: "EF025420",
    featureDesc: "Number of wire stripper pliers",
    label: "EF025420: Number of wire stripper pliers"
  },
  {
    featureId: "EF025421",
    featureDesc: "Number of water pump pliers",
    label: "EF025421: Number of water pump pliers"
  },
  {
    featureId: "EF025422",
    featureDesc: "Number of pipe wrenches",
    label: "EF025422: Number of pipe wrenches"
  },
  {
    featureId: "EF025423",
    featureDesc: "Number of fencing pliers",
    label: "EF025423: Number of fencing pliers"
  },
  {
    featureId: "EF025424",
    featureDesc: "Number of pincers",
    label: "EF025424: Number of pincers"
  },
  {
    featureId: "EF025425",
    featureDesc: "Number of sealing pliers",
    label: "EF025425: Number of sealing pliers"
  },
  {
    featureId: "EF025426",
    featureDesc: "Number of circlip pliers",
    label: "EF025426: Number of circlip pliers"
  },
  {
    featureId: "EF025427",
    featureDesc: "Number of cable tie tools",
    label: "EF025427: Number of cable tie tools"
  },
  {
    featureId: "EF025428",
    featureDesc: "Number of grip pliers",
    label: "EF025428: Number of grip pliers"
  },
  {
    featureId: "EF025429",
    featureDesc: "Number of siphon pliers",
    label: "EF025429: Number of siphon pliers"
  },
  {
    featureId: "EF025430",
    featureDesc: "Suitable for double hex nuts",
    label: "EF025430: Suitable for double hex nuts"
  },
  {
    featureId: "EF025431",
    featureDesc: "Suitable for protruding torx screws",
    label: "EF025431: Suitable for protruding torx screws"
  },
  {
    featureId: "EF025432",
    featureDesc: "Suitable for recessed torx screws",
    label: "EF025432: Suitable for recessed torx screws"
  },
  {
    featureId: "EF025433",
    featureDesc: "Suitable for in-hex screws",
    label: "EF025433: Suitable for in-hex screws"
  },
  {
    featureId: "EF025434",
    featureDesc: "Water contacts",
    label: "EF025434: Water contacts"
  },
  {
    featureId: "EF025435",
    featureDesc: "With gauge",
    label: "EF025435: With gauge"
  },
  {
    featureId: "EF025437",
    featureDesc: "Number of drift punches",
    label: "EF025437: Number of drift punches"
  },
  {
    featureId: "EF025438",
    featureDesc: "Number of chisels straight",
    label: "EF025438: Number of chisels straight"
  },
  {
    featureId: "EF025439",
    featureDesc: "Number of chisels semi round",
    label: "EF025439: Number of chisels semi round"
  },
  {
    featureId: "EF025440",
    featureDesc: "Number of chisels V-shape",
    label: "EF025440: Number of chisels V-shape"
  },
  {
    featureId: "EF025441",
    featureDesc: "Number of chisels flat",
    label: "EF025441: Number of chisels flat"
  },
  {
    featureId: "EF025442",
    featureDesc: "Number of chisels pointed",
    label: "EF025442: Number of chisels pointed"
  },
  {
    featureId: "EF025443",
    featureDesc: "Rectangular mounting",
    label: "EF025443: Rectangular mounting"
  },
  {
    featureId: "EF025444",
    featureDesc: "With scale",
    label: "EF025444: With scale"
  },
  {
    featureId: "EF025445",
    featureDesc: "Number of pulley pullers",
    label: "EF025445: Number of pulley pullers"
  },
  {
    featureId: "EF025446",
    featureDesc: "Number of wheel-hub pullers",
    label: "EF025446: Number of wheel-hub pullers"
  },
  {
    featureId: "EF025447",
    featureDesc: "Number of oil filter hooks",
    label: "EF025447: Number of oil filter hooks"
  },
  {
    featureId: "EF025449",
    featureDesc: "Number of cartridge hooks",
    label: "EF025449: Number of cartridge hooks"
  },
  {
    featureId: "EF025450",
    featureDesc: "Number of drive shaft pullers",
    label: "EF025450: Number of drive shaft pullers"
  },
  {
    featureId: "EF025451",
    featureDesc: "Number of steering-wheel pullers",
    label: "EF025451: Number of steering-wheel pullers"
  },
  {
    featureId: "EF025452",
    featureDesc: "Insert",
    label: "EF025452: Insert"
  },
  {
    featureId: "EF025453",
    featureDesc: "Length folded",
    label: "EF025453: Length folded"
  },
  {
    featureId: "EF025454",
    featureDesc: "With knife",
    label: "EF025454: With knife"
  },
  {
    featureId: "EF025455",
    featureDesc: "With spanner",
    label: "EF025455: With spanner"
  },
  {
    featureId: "EF025456",
    featureDesc: "With screw driver",
    label: "EF025456: With screw driver"
  },
  {
    featureId: "EF025457",
    featureDesc: "With saw blade",
    label: "EF025457: With saw blade"
  },
  {
    featureId: "EF025458",
    featureDesc: "With file",
    label: "EF025458: With file"
  },
  {
    featureId: "EF025459",
    featureDesc: "With scissors",
    label: "EF025459: With scissors"
  },
  {
    featureId: "EF025460",
    featureDesc: "With bottle opener",
    label: "EF025460: With bottle opener"
  },
  {
    featureId: "EF025461",
    featureDesc: "With tin opener",
    label: "EF025461: With tin opener"
  },
  {
    featureId: "EF025462",
    featureDesc: "Number of block file",
    label: "EF025462: Number of block file"
  },
  {
    featureId: "EF025463",
    featureDesc: "Number of semi round file",
    label: "EF025463: Number of semi round file"
  },
  {
    featureId: "EF025464",
    featureDesc: "Number of round file",
    label: "EF025464: Number of round file"
  },
  {
    featureId: "EF025465",
    featureDesc: "Number of three-square file",
    label: "EF025465: Number of three-square file"
  },
  {
    featureId: "EF025466",
    featureDesc: "Number of square file",
    label: "EF025466: Number of square file"
  },
  {
    featureId: "EF025468",
    featureDesc: "Lug width",
    label: "EF025468: Lug width"
  },
  {
    featureId: "EF025470",
    featureDesc: "Countersink angle",
    label: "EF025470: Countersink angle"
  },
  {
    featureId: "EF025472",
    featureDesc: "Countersink diameter",
    label: "EF025472: Countersink diameter"
  },
  {
    featureId: "EF025474",
    featureDesc: "Number of countersinks",
    label: "EF025474: Number of countersinks"
  },
  {
    featureId: "EF025475",
    featureDesc: "Number of drills for concrete",
    label: "EF025475: Number of drills for concrete"
  },
  {
    featureId: "EF025476",
    featureDesc: "Number of drills for stainless steel",
    label: "EF025476: Number of drills for stainless steel"
  },
  {
    featureId: "EF025477",
    featureDesc: "Number of drills for wood",
    label: "EF025477: Number of drills for wood"
  },
  {
    featureId: "EF025478",
    featureDesc: "Number of drills for plastic",
    label: "EF025478: Number of drills for plastic"
  },
  {
    featureId: "EF025479",
    featureDesc: "Number of drills for nonferrous metals",
    label: "EF025479: Number of drills for nonferrous metals"
  },
  {
    featureId: "EF025480",
    featureDesc: "Number of drills for steel",
    label: "EF025480: Number of drills for steel"
  },
  {
    featureId: "EF025481",
    featureDesc: "Number of drills for stone",
    label: "EF025481: Number of drills for stone"
  },
  {
    featureId: "EF025482",
    featureDesc: "Number of nozzle holes",
    label: "EF025482: Number of nozzle holes"
  },
  {
    featureId: "EF025483",
    featureDesc: "Output left",
    label: "EF025483: Output left"
  },
  {
    featureId: "EF025484",
    featureDesc: "Output right",
    label: "EF025484: Output right"
  },
  {
    featureId: "EF025485",
    featureDesc: "Max. number of wipers",
    label: "EF025485: Max. number of wipers"
  },
  {
    featureId: "EF025486",
    featureDesc: "Continuously variable",
    label: "EF025486: Continuously variable"
  },
  {
    featureId: "EF025487",
    featureDesc: "Wash program",
    label: "EF025487: Wash program"
  },
  {
    featureId: "EF025488",
    featureDesc: "Diameter small",
    label: "EF025488: Diameter small"
  },
  {
    featureId: "EF025489",
    featureDesc: "Diameter large",
    label: "EF025489: Diameter large"
  },
  {
    featureId: "EF025490",
    featureDesc: "Nominal duct diameter on outlet side",
    label: "EF025490: Nominal duct diameter on outlet side"
  },
  {
    featureId: "EF025491",
    featureDesc: "Number of bolt extractors",
    label: "EF025491: Number of bolt extractors"
  },
  {
    featureId: "EF025492",
    featureDesc: "With slide rail",
    label: "EF025492: With slide rail"
  },
  {
    featureId: "EF025493",
    featureDesc: "Max. bending angle",
    label: "EF025493: Max. bending angle"
  },
  {
    featureId: "EF025494",
    featureDesc: "Bending force",
    label: "EF025494: Bending force"
  },
  {
    featureId: "EF025495",
    featureDesc: "Transparent bowl",
    label: "EF025495: Transparent bowl"
  },
  {
    featureId: "EF025496",
    featureDesc: "Connection pipe diameter",
    label: "EF025496: Connection pipe diameter"
  },
  {
    featureId: "EF025497",
    featureDesc: "Suitable for foam",
    label: "EF025497: Suitable for foam"
  },
  {
    featureId: "EF025498",
    featureDesc: "Operating pressure",
    label: "EF025498: Operating pressure"
  },
  {
    featureId: "EF025499",
    featureDesc: "Spray range",
    label: "EF025499: Spray range"
  },
  {
    featureId: "EF025500",
    featureDesc: "Spray time",
    label: "EF025500: Spray time"
  },
  {
    featureId: "EF025501",
    featureDesc: "With ratchet",
    label: "EF025501: With ratchet"
  },
  {
    featureId: "EF025502",
    featureDesc: "Number of blades",
    label: "EF025502: Number of blades"
  },
  {
    featureId: "EF025503",
    featureDesc: "Outer pipe diameter connection inlet side",
    label: "EF025503: Outer pipe diameter connection inlet side"
  },
  {
    featureId: "EF025504",
    featureDesc: "Outer pipe diameter connection outlet side",
    label: "EF025504: Outer pipe diameter connection outlet side"
  },
  {
    featureId: "EF025505",
    featureDesc: "Detect chloride (CI2)",
    label: "EF025505: Detect chloride (CI2)"
  },
  {
    featureId: "EF025506",
    featureDesc: "Detect chloride dioxide (CIO2)",
    label: "EF025506: Detect chloride dioxide (CIO2)"
  },
  {
    featureId: "EF025507",
    featureDesc: "Detect hydrogen cyanide (HCN)",
    label: "EF025507: Detect hydrogen cyanide (HCN)"
  },
  {
    featureId: "EF025508",
    featureDesc: "Detect hydrogen sulphide (H2S)",
    label: "EF025508: Detect hydrogen sulphide (H2S)"
  },
  {
    featureId: "EF025509",
    featureDesc: "Detect nitrogen dioxide (NO2)",
    label: "EF025509: Detect nitrogen dioxide (NO2)"
  },
  {
    featureId: "EF025510",
    featureDesc: "Detect oxygen (O2)",
    label: "EF025510: Detect oxygen (O2)"
  },
  {
    featureId: "EF025511",
    featureDesc: "Detect ozone (O3)",
    label: "EF025511: Detect ozone (O3)"
  },
  {
    featureId: "EF025512",
    featureDesc: "Detect phosphine (PH3)",
    label: "EF025512: Detect phosphine (PH3)"
  },
  {
    featureId: "EF025513",
    featureDesc: "Detect sulphur dioxide (SO2)",
    label: "EF025513: Detect sulphur dioxide (SO2)"
  },
  {
    featureId: "EF025514",
    featureDesc: "Detects volatile organic compounds",
    label: "EF025514: Detects volatile organic compounds"
  },
  {
    featureId: "EF025516",
    featureDesc: "Nominal flow",
    label: "EF025516: Nominal flow"
  },
  {
    featureId: "EF025517",
    featureDesc: "Connection size inlet",
    label: "EF025517: Connection size inlet"
  },
  {
    featureId: "EF025518",
    featureDesc: "Connection size outlet",
    label: "EF025518: Connection size outlet"
  },
  {
    featureId: "EF025521",
    featureDesc: "Back width",
    label: "EF025521: Back width"
  },
  {
    featureId: "EF025522",
    featureDesc: "With infrared sensor",
    label: "EF025522: With infrared sensor"
  },
  {
    featureId: "EF025523",
    featureDesc: "Buoyancy",
    label: "EF025523: Buoyancy"
  },
  {
    featureId: "EF025524",
    featureDesc: "Inflatable",
    label: "EF025524: Inflatable"
  },
  {
    featureId: "EF025525",
    featureDesc: "Automatically inflatable",
    label: "EF025525: Automatically inflatable"
  },
  {
    featureId: "EF025526",
    featureDesc: "Multiple inflatable chambers",
    label: "EF025526: Multiple inflatable chambers"
  },
  {
    featureId: "EF025527",
    featureDesc: "With reflective tape",
    label: "EF025527: With reflective tape"
  },
  {
    featureId: "EF025528",
    featureDesc: "With grab loop",
    label: "EF025528: With grab loop"
  },
  {
    featureId: "EF025529",
    featureDesc: "With harness",
    label: "EF025529: With harness"
  },
  {
    featureId: "EF025530",
    featureDesc: "With emergency light",
    label: "EF025530: With emergency light"
  },
  {
    featureId: "EF025531",
    featureDesc: "With buddy line",
    label: "EF025531: With buddy line"
  },
  {
    featureId: "EF025532",
    featureDesc: "Approved according to SOLAS",
    label: "EF025532: Approved according to SOLAS"
  },
  {
    featureId: "EF025533",
    featureDesc: "With whistle",
    label: "EF025533: With whistle"
  },
  {
    featureId: "EF025534",
    featureDesc: "With inflatable head support",
    label: "EF025534: With inflatable head support"
  },
  {
    featureId: "EF025535",
    featureDesc: "Fire resistant",
    label: "EF025535: Fire resistant"
  },
  {
    featureId: "EF025536",
    featureDesc: "Weight without motor and fuel",
    label: "EF025536: Weight without motor and fuel"
  },
  {
    featureId: "EF025537",
    featureDesc: "With engine",
    label: "EF025537: With engine"
  },
  {
    featureId: "EF025538",
    featureDesc: "Suitable for solid wood",
    label: "EF025538: Suitable for solid wood"
  },
  {
    featureId: "EF025539",
    featureDesc: "Diameter",
    label: "EF025539: Diameter"
  },
  {
    featureId: "EF025540",
    featureDesc: "Displacement",
    label: "EF025540: Displacement"
  },
  {
    featureId: "EF025541",
    featureDesc: "Bore",
    label: "EF025541: Bore"
  },
  {
    featureId: "EF025542",
    featureDesc: "Gear ratio",
    label: "EF025542: Gear ratio"
  },
  {
    featureId: "EF025543",
    featureDesc: "Max. revolutions",
    label: "EF025543: Max. revolutions"
  },
  {
    featureId: "EF025544",
    featureDesc: "Dry weight",
    label: "EF025544: Dry weight"
  },
  {
    featureId: "EF025545",
    featureDesc: "Capacity fuel tank",
    label: "EF025545: Capacity fuel tank"
  },
  {
    featureId: "EF025546",
    featureDesc: "Suitable for diesel",
    label: "EF025546: Suitable for diesel"
  },
  {
    featureId: "EF025547",
    featureDesc: "Manual starting system",
    label: "EF025547: Manual starting system"
  },
  {
    featureId: "EF025548",
    featureDesc: "Manual control system",
    label: "EF025548: Manual control system"
  },
  {
    featureId: "EF025549",
    featureDesc: "Manual trim system",
    label: "EF025549: Manual trim system"
  },
  {
    featureId: "EF025550",
    featureDesc: "Counter rotation",
    label: "EF025550: Counter rotation"
  },
  {
    featureId: "EF025552",
    featureDesc: "With trim indicator",
    label: "EF025552: With trim indicator"
  },
  {
    featureId: "EF025553",
    featureDesc: "With oil pressure control",
    label: "EF025553: With oil pressure control"
  },
  {
    featureId: "EF025554",
    featureDesc: "With overheat alarm",
    label: "EF025554: With overheat alarm"
  },
  {
    featureId: "EF025555",
    featureDesc: "With revolution limiter",
    label: "EF025555: With revolution limiter"
  },
  {
    featureId: "EF025556",
    featureDesc: "With man overboard switch",
    label: "EF025556: With man overboard switch"
  },
  {
    featureId: "EF025557",
    featureDesc: "With water in fuel sensor",
    label: "EF025557: With water in fuel sensor"
  },
  {
    featureId: "EF025558",
    featureDesc: "Suitable for propeller size",
    label: "EF025558: Suitable for propeller size"
  },
  {
    featureId: "EF025559",
    featureDesc: "With propeller",
    label: "EF025559: With propeller"
  },
  {
    featureId: "EF025561",
    featureDesc: "LED lamp",
    label: "EF025561: LED lamp"
  },
  {
    featureId: "EF025562",
    featureDesc: "Sight distance",
    label: "EF025562: Sight distance"
  },
  {
    featureId: "EF025564",
    featureDesc: "Max. push load",
    label: "EF025564: Max. push load"
  },
  {
    featureId: "EF025565",
    featureDesc: "Min. radius",
    label: "EF025565: Min. radius"
  },
  {
    featureId: "EF025566",
    featureDesc: "Min. stroke",
    label: "EF025566: Min. stroke"
  },
  {
    featureId: "EF025567",
    featureDesc: "With grab line",
    label: "EF025567: With grab line"
  },
  {
    featureId: "EF025568",
    featureDesc: "With lifeline",
    label: "EF025568: With lifeline"
  },
  {
    featureId: "EF025569",
    featureDesc: "Pressure",
    label: "EF025569: Pressure"
  },
  {
    featureId: "EF025570",
    featureDesc: "With fixed gauge",
    label: "EF025570: With fixed gauge"
  },
  {
    featureId: "EF025571",
    featureDesc: "Suitable for automatic operation",
    label: "EF025571: Suitable for automatic operation"
  },
  {
    featureId: "EF025574",
    featureDesc: "Roll diameter",
    label: "EF025574: Roll diameter"
  },
  {
    featureId: "EF025575",
    featureDesc: "EU Ecolabel",
    label: "EF025575: EU Ecolabel"
  },
  {
    featureId: "EF025577",
    featureDesc: "Suitable for bump check",
    label: "EF025577: Suitable for bump check"
  },
  {
    featureId: "EF025578",
    featureDesc: "Suitable for calibration",
    label: "EF025578: Suitable for calibration"
  },
  {
    featureId: "EF025579",
    featureDesc: "Suitable for configuration",
    label: "EF025579: Suitable for configuration"
  },
  {
    featureId: "EF025580",
    featureDesc: "Suitable for data transfer",
    label: "EF025580: Suitable for data transfer"
  },
  {
    featureId: "EF025581",
    featureDesc: "Suitable for charging",
    label: "EF025581: Suitable for charging"
  },
  {
    featureId: "EF025582",
    featureDesc: "Material quality clamp strip",
    label: "EF025582: Material quality clamp strip"
  },
  {
    featureId: "EF025583",
    featureDesc: "Half-round model",
    label: "EF025583: Half-round model"
  },
  {
    featureId: "EF025584",
    featureDesc: "With smoke catcher",
    label: "EF025584: With smoke catcher"
  },
  {
    featureId: "EF025586",
    featureDesc: "Cold crank amps according to EN",
    label: "EF025586: Cold crank amps according to EN"
  },
  {
    featureId: "EF025587",
    featureDesc: "Cold crank amps according to SAE",
    label: "EF025587: Cold crank amps according to SAE"
  },
  {
    featureId: "EF025588",
    featureDesc: "Capacity 20h",
    label: "EF025588: Capacity 20h"
  },
  {
    featureId: "EF025590",
    featureDesc: "Knitted",
    label: "EF025590: Knitted"
  },
  {
    featureId: "EF025591",
    featureDesc: "Woven",
    label: "EF025591: Woven"
  },
  {
    featureId: "EF025592",
    featureDesc: "Diamond plate",
    label: "EF025592: Diamond plate"
  },
  {
    featureId: "EF025593",
    featureDesc: "Surface single side",
    label: "EF025593: Surface single side"
  },
  {
    featureId: "EF025594",
    featureDesc: "Diameter rudder stock shaft",
    label: "EF025594: Diameter rudder stock shaft"
  },
  {
    featureId: "EF025595",
    featureDesc: "Length rudder stock shaft",
    label: "EF025595: Length rudder stock shaft"
  },
  {
    featureId: "EF025597",
    featureDesc: "With safety clamp",
    label: "EF025597: With safety clamp"
  },
  {
    featureId: "EF025598",
    featureDesc: "With washers",
    label: "EF025598: With washers"
  },
  {
    featureId: "EF025599",
    featureDesc: "With nuts",
    label: "EF025599: With nuts"
  },
  {
    featureId: "EF025600",
    featureDesc: "Length rudder trunk",
    label: "EF025600: Length rudder trunk"
  },
  {
    featureId: "EF025602",
    featureDesc: "Height rudder blade",
    label: "EF025602: Height rudder blade"
  },
  {
    featureId: "EF025605",
    featureDesc: "Built-in inlet combination",
    label: "EF025605: Built-in inlet combination"
  },
  {
    featureId: "EF025606",
    featureDesc: "Expansion connection",
    label: "EF025606: Expansion connection"
  },
  {
    featureId: "EF025607",
    featureDesc: "Inner diameter part above roof",
    label: "EF025607: Inner diameter part above roof"
  },
  {
    featureId: "EF025608",
    featureDesc: "Inner diameter, connection 1",
    label: "EF025608: Inner diameter, connection 1"
  },
  {
    featureId: "EF025609",
    featureDesc: "Inner diameter, connection 2",
    label: "EF025609: Inner diameter, connection 2"
  },
  {
    featureId: "EF025610",
    featureDesc: "Suitable for exhaust pipe",
    label: "EF025610: Suitable for exhaust pipe"
  },
  {
    featureId: "EF025613",
    featureDesc: "Number of cushions",
    label: "EF025613: Number of cushions"
  },
  {
    featureId: "EF025614",
    featureDesc: "Ribbed",
    label: "EF025614: Ribbed"
  },
  {
    featureId: "EF025616",
    featureDesc: "Material connection 1",
    label: "EF025616: Material connection 1"
  },
  {
    featureId: "EF025617",
    featureDesc: "Material connection 2",
    label: "EF025617: Material connection 2"
  },
  {
    featureId: "EF025618",
    featureDesc: "Material quality connection 1",
    label: "EF025618: Material quality connection 1"
  },
  {
    featureId: "EF025619",
    featureDesc: "Material quality connection 2",
    label: "EF025619: Material quality connection 2"
  },
  {
    featureId: "EF025620",
    featureDesc: "Nominal diameter connection",
    label: "EF025620: Nominal diameter connection"
  },
  {
    featureId: "EF025621",
    featureDesc: "Diameter connection (metric)",
    label: "EF025621: Diameter connection (metric)"
  },
  {
    featureId: "EF025622",
    featureDesc: "Kink angle",
    label: "EF025622: Kink angle"
  },
  {
    featureId: "EF025624",
    featureDesc: "Suitable for indoor use",
    label: "EF025624: Suitable for indoor use"
  },
  {
    featureId: "EF025625",
    featureDesc: "Suitable for all liquids",
    label: "EF025625: Suitable for all liquids"
  },
  {
    featureId: "EF025627",
    featureDesc: "Non slip",
    label: "EF025627: Non slip"
  },
  {
    featureId: "EF025628",
    featureDesc: "Grain size",
    label: "EF025628: Grain size"
  },
  {
    featureId: "EF025629",
    featureDesc: "Suitable for oil filter",
    label: "EF025629: Suitable for oil filter"
  },
  {
    featureId: "EF025630",
    featureDesc: "Outer diameter of double-walled system",
    label: "EF025630: Outer diameter of double-walled system"
  },
  {
    featureId: "EF025631",
    featureDesc: "Suitable for car",
    label: "EF025631: Suitable for car"
  },
  {
    featureId: "EF025632",
    featureDesc: "Suitable for truck",
    label: "EF025632: Suitable for truck"
  },
  {
    featureId: "EF025637",
    featureDesc: "Outside hexagon",
    label: "EF025637: Outside hexagon"
  },
  {
    featureId: "EF025638",
    featureDesc: "Internal hex drive",
    label: "EF025638: Internal hex drive"
  },
  {
    featureId: "EF025641",
    featureDesc: "Bulb thickness",
    label: "EF025641: Bulb thickness"
  },
  {
    featureId: "EF025643",
    featureDesc: "Annealed",
    label: "EF025643: Annealed"
  },
  {
    featureId: "EF025644",
    featureDesc: "European standard",
    label: "EF025644: European standard"
  },
  {
    featureId: "EF025653",
    featureDesc: "With flexible anvil",
    label: "EF025653: With flexible anvil"
  },
  {
    featureId: "EF025654",
    featureDesc: "With cutter for cutting and trimming lead weights",
    label: "EF025654: With cutter for cutting and trimming lead weights"
  },
  {
    featureId: "EF025655",
    featureDesc: "With adhesive foil",
    label: "EF025655: With adhesive foil"
  },
  {
    featureId: "EF025656",
    featureDesc: "With replaceable plastic jaws",
    label: "EF025656: With replaceable plastic jaws"
  },
  {
    featureId: "EF025659",
    featureDesc: "For hose diameter",
    label: "EF025659: For hose diameter"
  },
  {
    featureId: "EF025660",
    featureDesc: "Adjustable cutting edge",
    label: "EF025660: Adjustable cutting edge"
  },
  {
    featureId: "EF025661",
    featureDesc: "Adjustable jaw hole",
    label: "EF025661: Adjustable jaw hole"
  },
  {
    featureId: "EF025663",
    featureDesc: "Material sheet 1",
    label: "EF025663: Material sheet 1"
  },
  {
    featureId: "EF025664",
    featureDesc: "Material sheet 2",
    label: "EF025664: Material sheet 2"
  },
  {
    featureId: "EF025665",
    featureDesc: "Thickness sheet 1",
    label: "EF025665: Thickness sheet 1"
  },
  {
    featureId: "EF025666",
    featureDesc: "Thickness sheet 2",
    label: "EF025666: Thickness sheet 2"
  },
  {
    featureId: "EF025668",
    featureDesc: "Surface protection sheet 1",
    label: "EF025668: Surface protection sheet 1"
  },
  {
    featureId: "EF025669",
    featureDesc: "Surface protection sheet 2",
    label: "EF025669: Surface protection sheet 2"
  },
  {
    featureId: "EF025670",
    featureDesc: "RAL-number sheet 1",
    label: "EF025670: RAL-number sheet 1"
  },
  {
    featureId: "EF025671",
    featureDesc: "RAL-number panel 2",
    label: "EF025671: RAL-number panel 2"
  },
  {
    featureId: "EF025672",
    featureDesc: "Overall thickness",
    label: "EF025672: Overall thickness"
  },
  {
    featureId: "EF025674",
    featureDesc: "Without recoil",
    label: "EF025674: Without recoil"
  },
  {
    featureId: "EF025675",
    featureDesc: "With bearing",
    label: "EF025675: With bearing"
  },
  {
    featureId: "EF025676",
    featureDesc: "Adjustable impact force",
    label: "EF025676: Adjustable impact force"
  },
  {
    featureId: "EF025677",
    featureDesc: "Suitable for nails",
    label: "EF025677: Suitable for nails"
  },
  {
    featureId: "EF025678",
    featureDesc: "Suitable for staples",
    label: "EF025678: Suitable for staples"
  },
  {
    featureId: "EF025680",
    featureDesc: "Suitable for cable staples",
    label: "EF025680: Suitable for cable staples"
  },
  {
    featureId: "EF025691",
    featureDesc: "Sold in pairs",
    label: "EF025691: Sold in pairs"
  },
  {
    featureId: "EF025694",
    featureDesc: "Suitable for single arm",
    label: "EF025694: Suitable for single arm"
  },
  {
    featureId: "EF025695",
    featureDesc: "Suitable for double arm",
    label: "EF025695: Suitable for double arm"
  },
  {
    featureId: "EF025696",
    featureDesc: "Suitable for mounting through glass",
    label: "EF025696: Suitable for mounting through glass"
  },
  {
    featureId: "EF025697",
    featureDesc: "Suitable for mounting through bulkhead",
    label: "EF025697: Suitable for mounting through bulkhead"
  },
  {
    featureId: "EF025698",
    featureDesc: "Twisted drill pattern",
    label: "EF025698: Twisted drill pattern"
  },
  {
    featureId: "EF025699",
    featureDesc: "Thread size small (metric)",
    label: "EF025699: Thread size small (metric)"
  },
  {
    featureId: "EF025700",
    featureDesc: "Thread size large (metric)",
    label: "EF025700: Thread size large (metric)"
  },
  {
    featureId: "EF025701",
    featureDesc: "Suitable for dry use",
    label: "EF025701: Suitable for dry use"
  },
  {
    featureId: "EF025702",
    featureDesc: "Suitable for porcelain",
    label: "EF025702: Suitable for porcelain"
  },
  {
    featureId: "EF025703",
    featureDesc: "Suitable for filler",
    label: "EF025703: Suitable for filler"
  },
  {
    featureId: "EF025704",
    featureDesc: "With clamper",
    label: "EF025704: With clamper"
  },
  {
    featureId: "EF025705",
    featureDesc: "Can be washed out",
    label: "EF025705: Can be washed out"
  },
  {
    featureId: "EF025706",
    featureDesc: "Reusable",
    label: "EF025706: Reusable"
  },
  {
    featureId: "EF025707",
    featureDesc: "With shaft",
    label: "EF025707: With shaft"
  },
  {
    featureId: "EF025708",
    featureDesc: "Diameter cutter",
    label: "EF025708: Diameter cutter"
  },
  {
    featureId: "EF025710",
    featureDesc: "T-model",
    label: "EF025710: T-model"
  },
  {
    featureId: "EF025711",
    featureDesc: "Max. speed",
    label: "EF025711: Max. speed"
  },
  {
    featureId: "EF025714",
    featureDesc: "Automatic switch off",
    label: "EF025714: Automatic switch off"
  },
  {
    featureId: "EF025716",
    featureDesc: "With spare blades",
    label: "EF025716: With spare blades"
  },
  {
    featureId: "EF025717",
    featureDesc: "Cutting deduction",
    label: "EF025717: Cutting deduction"
  },
  {
    featureId: "EF025718",
    featureDesc: "Number of cutting wheels",
    label: "EF025718: Number of cutting wheels"
  },
  {
    featureId: "EF025719",
    featureDesc: "International shore connection",
    label: "EF025719: International shore connection"
  },
  {
    featureId: "EF025725",
    featureDesc: "Nominal torque",
    label: "EF025725: Nominal torque"
  },
  {
    featureId: "EF025727",
    featureDesc: "With mixing garde",
    label: "EF025727: With mixing garde"
  },
  {
    featureId: "EF025731",
    featureDesc: "Oval shape",
    label: "EF025731: Oval shape"
  },
  {
    featureId: "EF025732",
    featureDesc: "With several probes",
    label: "EF025732: With several probes"
  },
  {
    featureId: "EF025734",
    featureDesc: "With suction cup",
    label: "EF025734: With suction cup"
  },
  {
    featureId: "EF025736",
    featureDesc: "Suitable for number of batteries",
    label: "EF025736: Suitable for number of batteries"
  },
  {
    featureId: "EF025737",
    featureDesc: "With battery tester",
    label: "EF025737: With battery tester"
  },
  {
    featureId: "EF025739",
    featureDesc: "Recommended battery capacity",
    label: "EF025739: Recommended battery capacity"
  },
  {
    featureId: "EF025740",
    featureDesc: "Power factor",
    label: "EF025740: Power factor"
  },
  {
    featureId: "EF025742",
    featureDesc: "Float charger",
    label: "EF025742: Float charger"
  },
  {
    featureId: "EF025747",
    featureDesc: "Suitable for sockets",
    label: "EF025747: Suitable for sockets"
  },
  {
    featureId: "EF025748",
    featureDesc: "With cover plate",
    label: "EF025748: With cover plate"
  },
  {
    featureId: "EF025749",
    featureDesc: "With bayonet fitting",
    label: "EF025749: With bayonet fitting"
  },
  {
    featureId: "EF025752",
    featureDesc: "Max. angle",
    label: "EF025752: Max. angle"
  },
  {
    featureId: "EF025754",
    featureDesc: "With wheels",
    label: "EF025754: With wheels"
  },
  {
    featureId: "EF025757",
    featureDesc: "Angle cutting head",
    label: "EF025757: Angle cutting head"
  },
  {
    featureId: "EF025758",
    featureDesc: "Thread size nut left",
    label: "EF025758: Thread size nut left"
  },
  {
    featureId: "EF025759",
    featureDesc: "Thread size nut right",
    label: "EF025759: Thread size nut right"
  },
  {
    featureId: "EF025760",
    featureDesc: "With hose connection",
    label: "EF025760: With hose connection"
  },
  {
    featureId: "EF025761",
    featureDesc: "Diameter hose connection left",
    label: "EF025761: Diameter hose connection left"
  },
  {
    featureId: "EF025762",
    featureDesc: "Diameter hose connection right",
    label: "EF025762: Diameter hose connection right"
  },
  {
    featureId: "EF025763",
    featureDesc: "Max. load capacity at 35 % (SMAW)",
    label: "EF025763: Max. load capacity at 35 % (SMAW)"
  },
  {
    featureId: "EF025764",
    featureDesc: "Max. load capacity at 40 % (SMAW)",
    label: "EF025764: Max. load capacity at 40 % (SMAW)"
  },
  {
    featureId: "EF025765",
    featureDesc: "Max. load capacity at 60 % (SMAW)",
    label: "EF025765: Max. load capacity at 60 % (SMAW)"
  },
  {
    featureId: "EF025766",
    featureDesc: "Max. load capacity at 100 % (SMAW)",
    label: "EF025766: Max. load capacity at 100 % (SMAW)"
  },
  {
    featureId: "EF025767",
    featureDesc: "Max. load capacity at 35 % (GTAW)",
    label: "EF025767: Max. load capacity at 35 % (GTAW)"
  },
  {
    featureId: "EF025768",
    featureDesc: "Max. load capacity at 40 % (GTAW)",
    label: "EF025768: Max. load capacity at 40 % (GTAW)"
  },
  {
    featureId: "EF025769",
    featureDesc: "Max. load capacity at 60 % (GTAW)",
    label: "EF025769: Max. load capacity at 60 % (GTAW)"
  },
  {
    featureId: "EF025770",
    featureDesc: "Max. load capacity at 100 % (GTAW)",
    label: "EF025770: Max. load capacity at 100 % (GTAW)"
  },
  {
    featureId: "EF025771",
    featureDesc: "Open circuit voltage (VRD)",
    label: "EF025771: Open circuit voltage (VRD)"
  },
  {
    featureId: "EF025772",
    featureDesc: "Welding current (SMAW)",
    label: "EF025772: Welding current (SMAW)"
  },
  {
    featureId: "EF025773",
    featureDesc: "Welding voltage (SMAW)",
    label: "EF025773: Welding voltage (SMAW)"
  },
  {
    featureId: "EF025774",
    featureDesc: "Welding current (GTAW)",
    label: "EF025774: Welding current (GTAW)"
  },
  {
    featureId: "EF025775",
    featureDesc: "Welding voltage (GTAW)",
    label: "EF025775: Welding voltage (GTAW)"
  },
  {
    featureId: "EF025777",
    featureDesc: "Max. load capacity at 35 % (GMAW)",
    label: "EF025777: Max. load capacity at 35 % (GMAW)"
  },
  {
    featureId: "EF025778",
    featureDesc: "Max. load capacity at 40 % (GMAW)",
    label: "EF025778: Max. load capacity at 40 % (GMAW)"
  },
  {
    featureId: "EF025779",
    featureDesc: "Max. load capacity at 60 % (GMAW)",
    label: "EF025779: Max. load capacity at 60 % (GMAW)"
  },
  {
    featureId: "EF025780",
    featureDesc: "Max. load capacity at 100 % (GMAW)",
    label: "EF025780: Max. load capacity at 100 % (GMAW)"
  },
  {
    featureId: "EF025781",
    featureDesc: "Welding current (GMAW)",
    label: "EF025781: Welding current (GMAW)"
  },
  {
    featureId: "EF025782",
    featureDesc: "Welding voltage (GMAW)",
    label: "EF025782: Welding voltage (GMAW)"
  },
  {
    featureId: "EF025783",
    featureDesc: "Multi-part",
    label: "EF025783: Multi-part"
  },
  {
    featureId: "EF025784",
    featureDesc: "Duty cycle",
    label: "EF025784: Duty cycle"
  },
  {
    featureId: "EF025785",
    featureDesc: "Max. load capacity (CO2)",
    label: "EF025785: Max. load capacity (CO2)"
  },
  {
    featureId: "EF025786",
    featureDesc: "Max. load capacity (Mixed gas)",
    label: "EF025786: Max. load capacity (Mixed gas)"
  },
  {
    featureId: "EF025787",
    featureDesc: "Suitable for welding wire diameter",
    label: "EF025787: Suitable for welding wire diameter"
  },
  {
    featureId: "EF025788",
    featureDesc: "Max. load capacity (DC)",
    label: "EF025788: Max. load capacity (DC)"
  },
  {
    featureId: "EF025789",
    featureDesc: "Max. load capacity (AC)",
    label: "EF025789: Max. load capacity (AC)"
  },
  {
    featureId: "EF025790",
    featureDesc: "Suitable for tungsten electrode diameter",
    label: "EF025790: Suitable for tungsten electrode diameter"
  },
  {
    featureId: "EF025792",
    featureDesc: "Commercial weight",
    label: "EF025792: Commercial weight"
  },
  {
    featureId: "EF025794",
    featureDesc: "Paint surface",
    label: "EF025794: Paint surface"
  },
  {
    featureId: "EF025796",
    featureDesc: "Solid colour welding filter",
    label: "EF025796: Solid colour welding filter"
  },
  {
    featureId: "EF025797",
    featureDesc: "Width welding filter",
    label: "EF025797: Width welding filter"
  },
  {
    featureId: "EF025798",
    featureDesc: "Height welding filter",
    label: "EF025798: Height welding filter"
  },
  {
    featureId: "EF025799",
    featureDesc: "Foldable welding filter",
    label: "EF025799: Foldable welding filter"
  },
  {
    featureId: "EF025800",
    featureDesc: "Min. operating life",
    label: "EF025800: Min. operating life"
  },
  {
    featureId: "EF025802",
    featureDesc: "Position update frequency",
    label: "EF025802: Position update frequency"
  },
  {
    featureId: "EF025803",
    featureDesc: "With removable ends",
    label: "EF025803: With removable ends"
  },
  {
    featureId: "EF025804",
    featureDesc: "Number of frame parts",
    label: "EF025804: Number of frame parts"
  },
  {
    featureId: "EF025805",
    featureDesc: "Number of passages per frame part",
    label: "EF025805: Number of passages per frame part"
  },
  {
    featureId: "EF025806",
    featureDesc: "Total number of passages",
    label: "EF025806: Total number of passages"
  },
  {
    featureId: "EF025808",
    featureDesc: "Material door frame",
    label: "EF025808: Material door frame"
  },
  {
    featureId: "EF025809",
    featureDesc: "Number of hinges",
    label: "EF025809: Number of hinges"
  },
  {
    featureId: "EF025810",
    featureDesc: "Number of toggles",
    label: "EF025810: Number of toggles"
  },
  {
    featureId: "EF025813",
    featureDesc: "Hinged",
    label: "EF025813: Hinged"
  },
  {
    featureId: "EF025814",
    featureDesc: "Door height",
    label: "EF025814: Door height"
  },
  {
    featureId: "EF025815",
    featureDesc: "Door width",
    label: "EF025815: Door width"
  },
  {
    featureId: "EF025816",
    featureDesc: "Hollow ground scraper",
    label: "EF025816: Hollow ground scraper"
  },
  {
    featureId: "EF025817",
    featureDesc: "Hammer tacker",
    label: "EF025817: Hammer tacker"
  },
  {
    featureId: "EF025818",
    featureDesc: "Suitable for other type of refrigerant",
    label: "EF025818: Suitable for other type of refrigerant"
  },
  {
    featureId: "EF025819",
    featureDesc: "Pressure loss refrigerant",
    label: "EF025819: Pressure loss refrigerant"
  },
  {
    featureId: "EF025820",
    featureDesc: "Refrigerant flow rate",
    label: "EF025820: Refrigerant flow rate"
  },
  {
    featureId: "EF025821",
    featureDesc: "Granulation 2nd layer",
    label: "EF025821: Granulation 2nd layer"
  },
  {
    featureId: "EF025822",
    featureDesc: "For grinding machine",
    label: "EF025822: For grinding machine"
  },
  {
    featureId: "EF025823",
    featureDesc: "Grout width",
    label: "EF025823: Grout width"
  },
  {
    featureId: "EF025824",
    featureDesc: "Knurled knob",
    label: "EF025824: Knurled knob"
  },
  {
    featureId: "EF025825",
    featureDesc: "Spring force",
    label: "EF025825: Spring force"
  },
  {
    featureId: "EF025826",
    featureDesc: "Number of sections",
    label: "EF025826: Number of sections"
  },
  {
    featureId: "EF025831",
    featureDesc: "Suitable for propeller shaft diameter",
    label: "EF025831: Suitable for propeller shaft diameter"
  },
  {
    featureId: "EF025832",
    featureDesc: "Number of struts",
    label: "EF025832: Number of struts"
  },
  {
    featureId: "EF025833",
    featureDesc: "Propeller shaft diameter",
    label: "EF025833: Propeller shaft diameter"
  },
  {
    featureId: "EF025835",
    featureDesc: "Suitable for number of keys",
    label: "EF025835: Suitable for number of keys"
  },
  {
    featureId: "EF025836",
    featureDesc: "Emergency key locker",
    label: "EF025836: Emergency key locker"
  },
  {
    featureId: "EF025837",
    featureDesc: "With break glass",
    label: "EF025837: With break glass"
  },
  {
    featureId: "EF025838",
    featureDesc: "With alarm/buzzer",
    label: "EF025838: With alarm/buzzer"
  },
  {
    featureId: "EF025839",
    featureDesc: "Empty bottle",
    label: "EF025839: Empty bottle"
  },
  {
    featureId: "EF025841",
    featureDesc: "Bottle volume",
    label: "EF025841: Bottle volume"
  },
  {
    featureId: "EF025842",
    featureDesc: "Width holder",
    label: "EF025842: Width holder"
  },
  {
    featureId: "EF025843",
    featureDesc: "Height holder",
    label: "EF025843: Height holder"
  },
  {
    featureId: "EF025844",
    featureDesc: "Suitable for number of bottles",
    label: "EF025844: Suitable for number of bottles"
  },
  {
    featureId: "EF025845",
    featureDesc: "With eye bath cup",
    label: "EF025845: With eye bath cup"
  },
  {
    featureId: "EF025846",
    featureDesc: "Suitable for open well (groundwater)",
    label: "EF025846: Suitable for open well (groundwater)"
  },
  {
    featureId: "EF025847",
    featureDesc: "Suitable for closed circuit well (soil collector)",
    label: "EF025847: Suitable for closed circuit well (soil collector)"
  },
  {
    featureId: "EF025848",
    featureDesc: "Cooling integrated",
    label: "EF025848: Cooling integrated"
  },
  {
    featureId: "EF025849",
    featureDesc: "Hot tap water feature integrated",
    label: "EF025849: Hot tap water feature integrated"
  },
  {
    featureId: "EF025850",
    featureDesc: "Power electric heating element",
    label: "EF025850: Power electric heating element"
  },
  {
    featureId: "EF025852",
    featureDesc: "Passage type",
    label: "EF025852: Passage type"
  },
  {
    featureId: "EF025853",
    featureDesc: "With bush",
    label: "EF025853: With bush"
  },
  {
    featureId: "EF025854",
    featureDesc: "Suitable for exhaust pipe diameter",
    label: "EF025854: Suitable for exhaust pipe diameter"
  },
  {
    featureId: "EF025855",
    featureDesc: "Outer diameter propeller",
    label: "EF025855: Outer diameter propeller"
  },
  {
    featureId: "EF025856",
    featureDesc: "Length hub",
    label: "EF025856: Length hub"
  },
  {
    featureId: "EF025858",
    featureDesc: "Shape of hub",
    label: "EF025858: Shape of hub"
  },
  {
    featureId: "EF025859",
    featureDesc: "Direction of rotation",
    label: "EF025859: Direction of rotation"
  },
  {
    featureId: "EF025860",
    featureDesc: "Pitch diameter ratio",
    label: "EF025860: Pitch diameter ratio"
  },
  {
    featureId: "EF025861",
    featureDesc: "With nozzle",
    label: "EF025861: With nozzle"
  },
  {
    featureId: "EF025862",
    featureDesc: "Balancing",
    label: "EF025862: Balancing"
  },
  {
    featureId: "EF025863",
    featureDesc: "Movement sensing, radial +/-",
    label: "EF025863: Movement sensing, radial +/-"
  },
  {
    featureId: "EF025864",
    featureDesc: "Coloured",
    label: "EF025864: Coloured"
  },
  {
    featureId: "EF025865",
    featureDesc: "Black out",
    label: "EF025865: Black out"
  },
  {
    featureId: "EF025867",
    featureDesc: "Hole length",
    label: "EF025867: Hole length"
  },
  {
    featureId: "EF025868",
    featureDesc: "Acrylic thickness",
    label: "EF025868: Acrylic thickness"
  },
  {
    featureId: "EF025869",
    featureDesc: "With roll-down curtain",
    label: "EF025869: With roll-down curtain"
  },
  {
    featureId: "EF025870",
    featureDesc: "With fly screen",
    label: "EF025870: With fly screen"
  },
  {
    featureId: "EF025871",
    featureDesc: "Layer thickness surface protection",
    label: "EF025871: Layer thickness surface protection"
  },
  {
    featureId: "EF025872",
    featureDesc: "Number of side strips",
    label: "EF025872: Number of side strips"
  },
  {
    featureId: "EF025873",
    featureDesc: "Suitable for joint width",
    label: "EF025873: Suitable for joint width"
  },
  {
    featureId: "EF025874",
    featureDesc: "With filter element",
    label: "EF025874: With filter element"
  },
  {
    featureId: "EF025875",
    featureDesc: "Suitable for chain link diameter",
    label: "EF025875: Suitable for chain link diameter"
  },
  {
    featureId: "EF025876",
    featureDesc: "Fire resistance class according to EN 13501-2",
    label: "EF025876: Fire resistance class according to EN 13501-2"
  },
  {
    featureId: "EF025877",
    featureDesc: "Material quality connection",
    label: "EF025877: Material quality connection"
  },
  {
    featureId: "EF025879",
    featureDesc: "COP at 0/35 °C (B0/W35) according to EN 14511",
    label: "EF025879: COP at 0/35 °C (B0/W35) according to EN 14511"
  },
  {
    featureId: "EF025880",
    featureDesc: "Sound performance level according to EN 12102 at 0/35 °C",
    label: "EF025880: Sound performance level according to EN 12102 at 0/35 °C"
  },
  {
    featureId: "EF025881",
    featureDesc: "Operating temperature source",
    label: "EF025881: Operating temperature source"
  },
  {
    featureId: "EF025882",
    featureDesc: "Max. operating pressure source system",
    label: "EF025882: Max. operating pressure source system"
  },
  {
    featureId: "EF025883",
    featureDesc: "Nominal volume flow rate source system",
    label: "EF025883: Nominal volume flow rate source system"
  },
  {
    featureId: "EF025884",
    featureDesc: "Nominal volume flow rate output system",
    label: "EF025884: Nominal volume flow rate output system"
  },
  {
    featureId: "EF025885",
    featureDesc: "Max. operating pressure output system",
    label: "EF025885: Max. operating pressure output system"
  },
  {
    featureId: "EF025886",
    featureDesc: "Outer pipe diameter connection source system",
    label: "EF025886: Outer pipe diameter connection source system"
  },
  {
    featureId: "EF025887",
    featureDesc: "Outer pipe diameter connection output system",
    label: "EF025887: Outer pipe diameter connection output system"
  },
  {
    featureId: "EF025889",
    featureDesc: "Heating capacity at 7/35 °C according to EN 14511",
    label: "EF025889: Heating capacity at 7/35 °C according to EN 14511"
  },
  {
    featureId: "EF025890",
    featureDesc: "COP at 7/35 °C according to EN 14511",
    label: "EF025890: COP at 7/35 °C according to EN 14511"
  },
  {
    featureId: "EF025891",
    featureDesc: "Air temperature",
    label: "EF025891: Air temperature"
  },
  {
    featureId: "EF025892",
    featureDesc: "Curved spray pipe",
    label: "EF025892: Curved spray pipe"
  },
  {
    featureId: "EF025893",
    featureDesc: "With pulley",
    label: "EF025893: With pulley"
  },
  {
    featureId: "EF025895",
    featureDesc: "Work positioning",
    label: "EF025895: Work positioning"
  },
  {
    featureId: "EF025896",
    featureDesc: "Suitable for descent",
    label: "EF025896: Suitable for descent"
  },
  {
    featureId: "EF025897",
    featureDesc: "Suitable for rescue work",
    label: "EF025897: Suitable for rescue work"
  },
  {
    featureId: "EF025898",
    featureDesc: "Suitable for ladder climbing",
    label: "EF025898: Suitable for ladder climbing"
  },
  {
    featureId: "EF025899",
    featureDesc: "Number of D-rings front",
    label: "EF025899: Number of D-rings front"
  },
  {
    featureId: "EF025901",
    featureDesc: "Number of D-rings back",
    label: "EF025901: Number of D-rings back"
  },
  {
    featureId: "EF025902",
    featureDesc: "Number of D-rings side or hip",
    label: "EF025902: Number of D-rings side or hip"
  },
  {
    featureId: "EF025903",
    featureDesc: "Number of sternal loops",
    label: "EF025903: Number of sternal loops"
  },
  {
    featureId: "EF025904",
    featureDesc: "With extended D-ring",
    label: "EF025904: With extended D-ring"
  },
  {
    featureId: "EF025905",
    featureDesc: "Max. transferable capacity 80/10-45",
    label: "EF025905: Max. transferable capacity 80/10-45"
  },
  {
    featureId: "EF025906",
    featureDesc: "Dust free",
    label: "EF025906: Dust free"
  },
  {
    featureId: "EF025907",
    featureDesc: "With safety hook",
    label: "EF025907: With safety hook"
  },
  {
    featureId: "EF025908",
    featureDesc: "Positioning line",
    label: "EF025908: Positioning line"
  },
  {
    featureId: "EF025909",
    featureDesc: "With a bag of sand/gravel",
    label: "EF025909: With a bag of sand/gravel"
  },
  {
    featureId: "EF025910",
    featureDesc: "Suitable for lanyard diameter",
    label: "EF025910: Suitable for lanyard diameter"
  },
  {
    featureId: "EF025911",
    featureDesc: "Suitable for horizontal use",
    label: "EF025911: Suitable for horizontal use"
  },
  {
    featureId: "EF025912",
    featureDesc: "Suitable for vertical use",
    label: "EF025912: Suitable for vertical use"
  },
  {
    featureId: "EF025913",
    featureDesc: "Fall blocking",
    label: "EF025913: Fall blocking"
  },
  {
    featureId: "EF025914",
    featureDesc: "With connecting line",
    label: "EF025914: With connecting line"
  },
  {
    featureId: "EF025916",
    featureDesc: "With safety harness",
    label: "EF025916: With safety harness"
  },
  {
    featureId: "EF025917",
    featureDesc: "With fall arrest device",
    label: "EF025917: With fall arrest device"
  },
  {
    featureId: "EF025918",
    featureDesc: "With safety catch line",
    label: "EF025918: With safety catch line"
  },
  {
    featureId: "EF025919",
    featureDesc: "With line clamp",
    label: "EF025919: With line clamp"
  },
  {
    featureId: "EF025920",
    featureDesc: "Number of included batteries",
    label: "EF025920: Number of included batteries"
  },
  {
    featureId: "EF025921",
    featureDesc: "Forced process",
    label: "EF025921: Forced process"
  },
  {
    featureId: "EF025923",
    featureDesc: "With protective helmet",
    label: "EF025923: With protective helmet"
  },
  {
    featureId: "EF025924",
    featureDesc: "With facial shield",
    label: "EF025924: With facial shield"
  },
  {
    featureId: "EF025925",
    featureDesc: "With protective glasses",
    label: "EF025925: With protective glasses"
  },
  {
    featureId: "EF025926",
    featureDesc: "With earplug",
    label: "EF025926: With earplug"
  },
  {
    featureId: "EF025927",
    featureDesc: "With ear muff",
    label: "EF025927: With ear muff"
  },
  {
    featureId: "EF025928",
    featureDesc: "With protective glove",
    label: "EF025928: With protective glove"
  },
  {
    featureId: "EF025929",
    featureDesc: "With breathing mask",
    label: "EF025929: With breathing mask"
  },
  {
    featureId: "EF025930",
    featureDesc: "With eye wash bottle",
    label: "EF025930: With eye wash bottle"
  },
  {
    featureId: "EF025931",
    featureDesc: "With dust-mask",
    label: "EF025931: With dust-mask"
  },
  {
    featureId: "EF025932",
    featureDesc: "With first aid kit",
    label: "EF025932: With first aid kit"
  },
  {
    featureId: "EF025933",
    featureDesc: "Model blasting helmet",
    label: "EF025933: Model blasting helmet"
  },
  {
    featureId: "EF025934",
    featureDesc: "With cape/jack",
    label: "EF025934: With cape/jack"
  },
  {
    featureId: "EF025935",
    featureDesc: "Diameter hose connection",
    label: "EF025935: Diameter hose connection"
  },
  {
    featureId: "EF025936",
    featureDesc: "Length opening",
    label: "EF025936: Length opening"
  },
  {
    featureId: "EF025937",
    featureDesc: "With feed through spout",
    label: "EF025937: With feed through spout"
  },
  {
    featureId: "EF025939",
    featureDesc: "Number of reservoirs",
    label: "EF025939: Number of reservoirs"
  },
  {
    featureId: "EF025940",
    featureDesc: "Suitable for lubricant",
    label: "EF025940: Suitable for lubricant"
  },
  {
    featureId: "EF025941",
    featureDesc: "Suitable for wastewater",
    label: "EF025941: Suitable for wastewater"
  },
  {
    featureId: "EF025942",
    featureDesc: "Suitable for fresh water",
    label: "EF025942: Suitable for fresh water"
  },
  {
    featureId: "EF025943",
    featureDesc: "Suitable for chain link diameter",
    label: "EF025943: Suitable for chain link diameter"
  },
  {
    featureId: "EF025944",
    featureDesc: "Suitable for rudder stock diameter",
    label: "EF025944: Suitable for rudder stock diameter"
  },
  {
    featureId: "EF025945",
    featureDesc: "Thread size ball seat (metric)",
    label: "EF025945: Thread size ball seat (metric)"
  },
  {
    featureId: "EF025946",
    featureDesc: "Thread size metric ball head (metric)",
    label: "EF025946: Thread size metric ball head (metric)"
  },
  {
    featureId: "EF025947",
    featureDesc: "Left-handed thread ball seat",
    label: "EF025947: Left-handed thread ball seat"
  },
  {
    featureId: "EF025948",
    featureDesc: "Left-handed thread ball head",
    label: "EF025948: Left-handed thread ball head"
  },
  {
    featureId: "EF025950",
    featureDesc: "Pitch thread ball head",
    label: "EF025950: Pitch thread ball head"
  },
  {
    featureId: "EF025951",
    featureDesc: "Pitch thread ball seat",
    label: "EF025951: Pitch thread ball seat"
  },
  {
    featureId: "EF025952",
    featureDesc: "Connection ball seat",
    label: "EF025952: Connection ball seat"
  },
  {
    featureId: "EF025953",
    featureDesc: "Connection ball head",
    label: "EF025953: Connection ball head"
  },
  {
    featureId: "EF025955",
    featureDesc: "Objective diameter",
    label: "EF025955: Objective diameter"
  },
  {
    featureId: "EF025956",
    featureDesc: "Exit pupil",
    label: "EF025956: Exit pupil"
  },
  {
    featureId: "EF025957",
    featureDesc: "Field of view at 1000 metre",
    label: "EF025957: Field of view at 1000 metre"
  },
  {
    featureId: "EF025959",
    featureDesc: "Max. size opening",
    label: "EF025959: Max. size opening"
  },
  {
    featureId: "EF025960",
    featureDesc: "Suitable for H-profile",
    label: "EF025960: Suitable for H-profile"
  },
  {
    featureId: "EF025961",
    featureDesc: "Suitable for I-profile",
    label: "EF025961: Suitable for I-profile"
  },
  {
    featureId: "EF025962",
    featureDesc: "Suitable for HP-profile",
    label: "EF025962: Suitable for HP-profile"
  },
  {
    featureId: "EF025963",
    featureDesc: "Suitable for plate",
    label: "EF025963: Suitable for plate"
  },
  {
    featureId: "EF025964",
    featureDesc: "With screw clamp",
    label: "EF025964: With screw clamp"
  },
  {
    featureId: "EF025965",
    featureDesc: "Diameter, connection driving side",
    label: "EF025965: Diameter, connection driving side"
  },
  {
    featureId: "EF025966",
    featureDesc: "Diameter, connection suction side",
    label: "EF025966: Diameter, connection suction side"
  },
  {
    featureId: "EF025967",
    featureDesc: "Diameter, connection discharge side",
    label: "EF025967: Diameter, connection discharge side"
  },
  {
    featureId: "EF025968",
    featureDesc: "Suction capacity",
    label: "EF025968: Suction capacity"
  },
  {
    featureId: "EF025969",
    featureDesc: "Driving capacity",
    label: "EF025969: Driving capacity"
  },
  {
    featureId: "EF025970",
    featureDesc: "Driving head",
    label: "EF025970: Driving head"
  },
  {
    featureId: "EF025971",
    featureDesc: "Altitude",
    label: "EF025971: Altitude"
  },
  {
    featureId: "EF025972",
    featureDesc: "Cartridge model",
    label: "EF025972: Cartridge model"
  },
  {
    featureId: "EF025973",
    featureDesc: "Buoyant",
    label: "EF025973: Buoyant"
  },
  {
    featureId: "EF025974",
    featureDesc: "Nominal diameter inlet side",
    label: "EF025974: Nominal diameter inlet side"
  },
  {
    featureId: "EF025975",
    featureDesc: "Nominal diameter outlet side",
    label: "EF025975: Nominal diameter outlet side"
  },
  {
    featureId: "EF025976",
    featureDesc: "Connection inlet side",
    label: "EF025976: Connection inlet side"
  },
  {
    featureId: "EF025977",
    featureDesc: "Connection outlet side",
    label: "EF025977: Connection outlet side"
  },
  {
    featureId: "EF025978",
    featureDesc: "With spark arrestor",
    label: "EF025978: With spark arrestor"
  },
  {
    featureId: "EF025979",
    featureDesc: "Suitable for petrol",
    label: "EF025979: Suitable for petrol"
  },
  {
    featureId: "EF025981",
    featureDesc: "With automatic shut-off",
    label: "EF025981: With automatic shut-off"
  },
  {
    featureId: "EF025982",
    featureDesc: "Suitable for engine power",
    label: "EF025982: Suitable for engine power"
  },
  {
    featureId: "EF025984",
    featureDesc: "With loop",
    label: "EF025984: With loop"
  },
  {
    featureId: "EF025985",
    featureDesc: "Suitable for number of persons",
    label: "EF025985: Suitable for number of persons"
  },
  {
    featureId: "EF025986",
    featureDesc: "Grade stud link chain",
    label: "EF025986: Grade stud link chain"
  },
  {
    featureId: "EF025988",
    featureDesc: "Swivel chain",
    label: "EF025988: Swivel chain"
  },
  {
    featureId: "EF025989",
    featureDesc: "Connection piston rod",
    label: "EF025989: Connection piston rod"
  },
  {
    featureId: "EF025991",
    featureDesc: "Connection cylinder",
    label: "EF025991: Connection cylinder"
  },
  {
    featureId: "EF025993",
    featureDesc: "Stroke",
    label: "EF025993: Stroke"
  },
  {
    featureId: "EF025994",
    featureDesc: "Length cylinder",
    label: "EF025994: Length cylinder"
  },
  {
    featureId: "EF025995",
    featureDesc: "Outer diameter piston rod",
    label: "EF025995: Outer diameter piston rod"
  },
  {
    featureId: "EF025996",
    featureDesc: "Outer diameter cylinder",
    label: "EF025996: Outer diameter cylinder"
  },
  {
    featureId: "EF025997",
    featureDesc: "Suitable for ferrous metals",
    label: "EF025997: Suitable for ferrous metals"
  },
  {
    featureId: "EF025998",
    featureDesc: "Theoretical coverage",
    label: "EF025998: Theoretical coverage"
  },
  {
    featureId: "EF025999",
    featureDesc: "Application with airless spray",
    label: "EF025999: Application with airless spray"
  },
  {
    featureId: "EF026000",
    featureDesc: "Application with conventional spray",
    label: "EF026000: Application with conventional spray"
  },
  {
    featureId: "EF026001",
    featureDesc: "Application with brush",
    label: "EF026001: Application with brush"
  },
  {
    featureId: "EF026002",
    featureDesc: "Application with roller",
    label: "EF026002: Application with roller"
  },
  {
    featureId: "EF026003",
    featureDesc: "Lockable gas spring",
    label: "EF026003: Lockable gas spring"
  },
  {
    featureId: "EF026004",
    featureDesc: "Material lip/eyebolt",
    label: "EF026004: Material lip/eyebolt"
  },
  {
    featureId: "EF026005",
    featureDesc: "With eyebolt",
    label: "EF026005: With eyebolt"
  },
  {
    featureId: "EF026006",
    featureDesc: "Length lip/eyebolt",
    label: "EF026006: Length lip/eyebolt"
  },
  {
    featureId: "EF026007",
    featureDesc: "Curved lip",
    label: "EF026007: Curved lip"
  },
  {
    featureId: "EF026009",
    featureDesc: "Material hinge",
    label: "EF026009: Material hinge"
  },
  {
    featureId: "EF026010",
    featureDesc: "Height in the clear",
    label: "EF026010: Height in the clear"
  },
  {
    featureId: "EF026011",
    featureDesc: "Chain length",
    label: "EF026011: Chain length"
  },
  {
    featureId: "EF026012",
    featureDesc: "Flash point",
    label: "EF026012: Flash point"
  },
  {
    featureId: "EF026013",
    featureDesc: "Volatile organic compound (VOC)",
    label: "EF026013: Volatile organic compound (VOC)"
  },
  {
    featureId: "EF026014",
    featureDesc: "With eye",
    label: "EF026014: With eye"
  },
  {
    featureId: "EF026015",
    featureDesc: "Suitable for lifting",
    label: "EF026015: Suitable for lifting"
  },
  {
    featureId: "EF026016",
    featureDesc: "Suitable for lashing",
    label: "EF026016: Suitable for lashing"
  },
  {
    featureId: "EF026017",
    featureDesc: "Max. freeboard",
    label: "EF026017: Max. freeboard"
  },
  {
    featureId: "EF026018",
    featureDesc: "Max. range",
    label: "EF026018: Max. range"
  },
  {
    featureId: "EF026019",
    featureDesc: "Diameter line",
    label: "EF026019: Diameter line"
  },
  {
    featureId: "EF026020",
    featureDesc: "Width lock case",
    label: "EF026020: Width lock case"
  },
  {
    featureId: "EF026021",
    featureDesc: "Height lock case",
    label: "EF026021: Height lock case"
  },
  {
    featureId: "EF026022",
    featureDesc: "Thickness lock case",
    label: "EF026022: Thickness lock case"
  },
  {
    featureId: "EF026023",
    featureDesc: "Height lock shackle",
    label: "EF026023: Height lock shackle"
  },
  {
    featureId: "EF026024",
    featureDesc: "Thickness lock shackle",
    label: "EF026024: Thickness lock shackle"
  },
  {
    featureId: "EF026025",
    featureDesc: "Depth lock shackle",
    label: "EF026025: Depth lock shackle"
  },
  {
    featureId: "EF026026",
    featureDesc: "Height front plate",
    label: "EF026026: Height front plate"
  },
  {
    featureId: "EF026027",
    featureDesc: "Depth front plate",
    label: "EF026027: Depth front plate"
  },
  {
    featureId: "EF026028",
    featureDesc: "Width front plate",
    label: "EF026028: Width front plate"
  },
  {
    featureId: "EF026031",
    featureDesc: "Distance handle hole to keyhole",
    label: "EF026031: Distance handle hole to keyhole"
  },
  {
    featureId: "EF026032",
    featureDesc: "Distance handle hole to front plate",
    label: "EF026032: Distance handle hole to front plate"
  },
  {
    featureId: "EF026033",
    featureDesc: "With strike plate",
    label: "EF026033: With strike plate"
  },
  {
    featureId: "EF026034",
    featureDesc: "With handle hole",
    label: "EF026034: With handle hole"
  },
  {
    featureId: "EF026035",
    featureDesc: "Hole diameter handle",
    label: "EF026035: Hole diameter handle"
  },
  {
    featureId: "EF026037",
    featureDesc: "Glass diameter",
    label: "EF026037: Glass diameter"
  },
  {
    featureId: "EF026038",
    featureDesc: "Total diameter",
    label: "EF026038: Total diameter"
  },
  {
    featureId: "EF026039",
    featureDesc: "Model as time clock",
    label: "EF026039: Model as time clock"
  },
  {
    featureId: "EF026040",
    featureDesc: "With radio silence indication",
    label: "EF026040: With radio silence indication"
  },
  {
    featureId: "EF026041",
    featureDesc: "Model as barometer",
    label: "EF026041: Model as barometer"
  },
  {
    featureId: "EF026042",
    featureDesc: "Model as thermometer",
    label: "EF026042: Model as thermometer"
  },
  {
    featureId: "EF026043",
    featureDesc: "Model as hygrometer",
    label: "EF026043: Model as hygrometer"
  },
  {
    featureId: "EF026044",
    featureDesc: "Model as tide clock",
    label: "EF026044: Model as tide clock"
  },
  {
    featureId: "EF026045",
    featureDesc: "Suitable as chipping lens",
    label: "EF026045: Suitable as chipping lens"
  },
  {
    featureId: "EF026046",
    featureDesc: "Suitable as anti-spatter lens",
    label: "EF026046: Suitable as anti-spatter lens"
  },
  {
    featureId: "EF026047",
    featureDesc: "Material surface",
    label: "EF026047: Material surface"
  },
  {
    featureId: "EF026048",
    featureDesc: "Mesh length",
    label: "EF026048: Mesh length"
  },
  {
    featureId: "EF026050",
    featureDesc: "Width bearing bar",
    label: "EF026050: Width bearing bar"
  },
  {
    featureId: "EF026051",
    featureDesc: "Thickness bearing bar",
    label: "EF026051: Thickness bearing bar"
  },
  {
    featureId: "EF026052",
    featureDesc: "Width crossbar",
    label: "EF026052: Width crossbar"
  },
  {
    featureId: "EF026053",
    featureDesc: "Thickness crossbar",
    label: "EF026053: Thickness crossbar"
  },
  {
    featureId: "EF026055",
    featureDesc: "Burglary resistant",
    label: "EF026055: Burglary resistant"
  },
  {
    featureId: "EF026056",
    featureDesc: "With electronic lock",
    label: "EF026056: With electronic lock"
  },
  {
    featureId: "EF026057",
    featureDesc: "With key lock",
    label: "EF026057: With key lock"
  },
  {
    featureId: "EF026058",
    featureDesc: "With combination lock",
    label: "EF026058: With combination lock"
  },
  {
    featureId: "EF026059",
    featureDesc: "Outer depth",
    label: "EF026059: Outer depth"
  },
  {
    featureId: "EF026060",
    featureDesc: "Inner depth",
    label: "EF026060: Inner depth"
  },
  {
    featureId: "EF026061",
    featureDesc: "Extendable shelf",
    label: "EF026061: Extendable shelf"
  },
  {
    featureId: "EF026062",
    featureDesc: "Range at 1 lux",
    label: "EF026062: Range at 1 lux"
  },
  {
    featureId: "EF026063",
    featureDesc: "With hook plate",
    label: "EF026063: With hook plate"
  },
  {
    featureId: "EF026064",
    featureDesc: "Material quality hinge flap",
    label: "EF026064: Material quality hinge flap"
  },
  {
    featureId: "EF026065",
    featureDesc: "Material pin",
    label: "EF026065: Material pin"
  },
  {
    featureId: "EF026066",
    featureDesc: "Material quality pin",
    label: "EF026066: Material quality pin"
  },
  {
    featureId: "EF026067",
    featureDesc: "Diameter pin",
    label: "EF026067: Diameter pin"
  },
  {
    featureId: "EF026068",
    featureDesc: "Fixed pin",
    label: "EF026068: Fixed pin"
  },
  {
    featureId: "EF026069",
    featureDesc: "With intermediate ring",
    label: "EF026069: With intermediate ring"
  },
  {
    featureId: "EF026070",
    featureDesc: "Toggle length",
    label: "EF026070: Toggle length"
  },
  {
    featureId: "EF026071",
    featureDesc: "Outer diameter bush",
    label: "EF026071: Outer diameter bush"
  },
  {
    featureId: "EF026072",
    featureDesc: "Bush length",
    label: "EF026072: Bush length"
  },
  {
    featureId: "EF026073",
    featureDesc: "Suitable for grease nipple",
    label: "EF026073: Suitable for grease nipple"
  },
  {
    featureId: "EF026074",
    featureDesc: "Suitable for door thickness",
    label: "EF026074: Suitable for door thickness"
  },
  {
    featureId: "EF026075",
    featureDesc: "Distance door to wall",
    label: "EF026075: Distance door to wall"
  },
  {
    featureId: "EF026076",
    featureDesc: "Diameter mounting plate",
    label: "EF026076: Diameter mounting plate"
  },
  {
    featureId: "EF026077",
    featureDesc: "Length stop",
    label: "EF026077: Length stop"
  },
  {
    featureId: "EF026078",
    featureDesc: "Diameter eyebolt",
    label: "EF026078: Diameter eyebolt"
  },
  {
    featureId: "EF026079",
    featureDesc: "Length eyebolt",
    label: "EF026079: Length eyebolt"
  },
  {
    featureId: "EF026080",
    featureDesc: "Height hinge point",
    label: "EF026080: Height hinge point"
  },
  {
    featureId: "EF026081",
    featureDesc: "Width lip",
    label: "EF026081: Width lip"
  },
  {
    featureId: "EF026082",
    featureDesc: "Thickness lip",
    label: "EF026082: Thickness lip"
  },
  {
    featureId: "EF026083",
    featureDesc: "Material lip",
    label: "EF026083: Material lip"
  },
  {
    featureId: "EF026084",
    featureDesc: "Material eyebolt",
    label: "EF026084: Material eyebolt"
  },
  {
    featureId: "EF026085",
    featureDesc: "Length hook",
    label: "EF026085: Length hook"
  },
  {
    featureId: "EF026086",
    featureDesc: "Double hinged",
    label: "EF026086: Double hinged"
  },
  {
    featureId: "EF026087",
    featureDesc: "Height mounting plate",
    label: "EF026087: Height mounting plate"
  },
  {
    featureId: "EF026090",
    featureDesc: "With welding helmet",
    label: "EF026090: With welding helmet"
  },
  {
    featureId: "EF026091",
    featureDesc: "With air hood",
    label: "EF026091: With air hood"
  },
  {
    featureId: "EF026092",
    featureDesc: "With air blowing filter system",
    label: "EF026092: With air blowing filter system"
  },
  {
    featureId: "EF026095",
    featureDesc: "With rotating disc",
    label: "EF026095: With rotating disc"
  },
  {
    featureId: "EF026096",
    featureDesc: "Max. safe working load",
    label: "EF026096: Max. safe working load"
  },
  {
    featureId: "EF026097",
    featureDesc: "Test load",
    label: "EF026097: Test load"
  },
  {
    featureId: "EF026098",
    featureDesc: "Release load",
    label: "EF026098: Release load"
  },
  {
    featureId: "EF026100",
    featureDesc: "With fairlead",
    label: "EF026100: With fairlead"
  },
  {
    featureId: "EF026101",
    featureDesc: "With radial rail track",
    label: "EF026101: With radial rail track"
  },
  {
    featureId: "EF026102",
    featureDesc: "Housing material quality",
    label: "EF026102: Housing material quality"
  },
  {
    featureId: "EF026103",
    featureDesc: "Max. pressure at sliding plate",
    label: "EF026103: Max. pressure at sliding plate"
  },
  {
    featureId: "EF026104",
    featureDesc: "Material quality plate",
    label: "EF026104: Material quality plate"
  },
  {
    featureId: "EF026105",
    featureDesc: "Suitable for dredging",
    label: "EF026105: Suitable for dredging"
  },
  {
    featureId: "EF026107",
    featureDesc: "With cross-bar",
    label: "EF026107: With cross-bar"
  },
  {
    featureId: "EF026110",
    featureDesc: "Print width",
    label: "EF026110: Print width"
  },
  {
    featureId: "EF026111",
    featureDesc: "Print height",
    label: "EF026111: Print height"
  },
  {
    featureId: "EF026113",
    featureDesc: "Sight width",
    label: "EF026113: Sight width"
  },
  {
    featureId: "EF026114",
    featureDesc: "Paper format",
    label: "EF026114: Paper format"
  },
  {
    featureId: "EF026115",
    featureDesc: "Accessories for mounting",
    label: "EF026115: Accessories for mounting"
  },
  {
    featureId: "EF026116",
    featureDesc: "Ice protected",
    label: "EF026116: Ice protected"
  },
  {
    featureId: "EF026117",
    featureDesc: "With isolated mounting material",
    label: "EF026117: With isolated mounting material"
  },
  {
    featureId: "EF026118",
    featureDesc: "Energy Efficiency Index (EEI)",
    label: "EF026118: Energy Efficiency Index (EEI)"
  },
  {
    featureId: "EF026125",
    featureDesc: "Sound pressure level according to EN 12102 at 0/35 °C at 1 m distance",
    label: "EF026125: Sound pressure level according to EN 12102 at 0/35 °C at 1 m distance"
  },
  {
    featureId: "EF026126",
    featureDesc: "Data transfer via internet",
    label: "EF026126: Data transfer via internet"
  },
  {
    featureId: "EF026130",
    featureDesc: "Connection and control multiple output systems possible",
    label: "EF026130: Connection and control multiple output systems possible"
  },
  {
    featureId: "EF026131",
    featureDesc: "Colour code",
    label: "EF026131: Colour code"
  },
  {
    featureId: "EF026132",
    featureDesc: "Activation method",
    label: "EF026132: Activation method"
  },
  {
    featureId: "EF026133",
    featureDesc: "Weight filling",
    label: "EF026133: Weight filling"
  },
  {
    featureId: "EF026134",
    featureDesc: "Discharge time",
    label: "EF026134: Discharge time"
  },
  {
    featureId: "EF026135",
    featureDesc: "Nominal voltage DC inverter",
    label: "EF026135: Nominal voltage DC inverter"
  },
  {
    featureId: "EF026136",
    featureDesc: "Continuous power inverter",
    label: "EF026136: Continuous power inverter"
  },
  {
    featureId: "EF026137",
    featureDesc: "Nominal output voltage inverter",
    label: "EF026137: Nominal output voltage inverter"
  },
  {
    featureId: "EF026138",
    featureDesc: "Nominal input voltage charger",
    label: "EF026138: Nominal input voltage charger"
  },
  {
    featureId: "EF026139",
    featureDesc: "Material hook",
    label: "EF026139: Material hook"
  },
  {
    featureId: "EF026140",
    featureDesc: "Telescopic",
    label: "EF026140: Telescopic"
  },
  {
    featureId: "EF026141",
    featureDesc: "With cloth",
    label: "EF026141: With cloth"
  },
  {
    featureId: "EF026142",
    featureDesc: "With brush",
    label: "EF026142: With brush"
  },
  {
    featureId: "EF026143",
    featureDesc: "Flange compression stage connection inlet side",
    label: "EF026143: Flange compression stage connection inlet side"
  },
  {
    featureId: "EF026144",
    featureDesc: "Connection size overboard discharge",
    label: "EF026144: Connection size overboard discharge"
  },
  {
    featureId: "EF026145",
    featureDesc: "Connection overboard discharge",
    label: "EF026145: Connection overboard discharge"
  },
  {
    featureId: "EF026146",
    featureDesc: "Flange compression stage connection overboard discharge",
    label: "EF026146: Flange compression stage connection overboard discharge"
  },
  {
    featureId: "EF026147",
    featureDesc: "Connection size bilge discharge",
    label: "EF026147: Connection size bilge discharge"
  },
  {
    featureId: "EF026148",
    featureDesc: "Connection bilge discharge",
    label: "EF026148: Connection bilge discharge"
  },
  {
    featureId: "EF026149",
    featureDesc: "Flange compression stage connection bilge discharge",
    label: "EF026149: Flange compression stage connection bilge discharge"
  },
  {
    featureId: "EF026150",
    featureDesc: "Connection size oil discharge",
    label: "EF026150: Connection size oil discharge"
  },
  {
    featureId: "EF026151",
    featureDesc: "Connection oil discharge",
    label: "EF026151: Connection oil discharge"
  },
  {
    featureId: "EF026152",
    featureDesc: "Flange compression stage oil discharge",
    label: "EF026152: Flange compression stage oil discharge"
  },
  {
    featureId: "EF026153",
    featureDesc: "Inner diameter tunnel",
    label: "EF026153: Inner diameter tunnel"
  },
  {
    featureId: "EF026154",
    featureDesc: "Wall thickness tunnel",
    label: "EF026154: Wall thickness tunnel"
  },
  {
    featureId: "EF026155",
    featureDesc: "Thrust",
    label: "EF026155: Thrust"
  },
  {
    featureId: "EF026156",
    featureDesc: "Material upper layer",
    label: "EF026156: Material upper layer"
  },
  {
    featureId: "EF026157",
    featureDesc: "Material lower layer",
    label: "EF026157: Material lower layer"
  },
  {
    featureId: "EF026158",
    featureDesc: "Model warping head",
    label: "EF026158: Model warping head"
  },
  {
    featureId: "EF026160",
    featureDesc: "Back height",
    label: "EF026160: Back height"
  },
  {
    featureId: "EF026161",
    featureDesc: "With undercarriage",
    label: "EF026161: With undercarriage"
  },
  {
    featureId: "EF026162",
    featureDesc: "Model undercarriage",
    label: "EF026162: Model undercarriage"
  },
  {
    featureId: "EF026163",
    featureDesc: "Undercarriage with cross base",
    label: "EF026163: Undercarriage with cross base"
  },
  {
    featureId: "EF026164",
    featureDesc: "With shock absorber",
    label: "EF026164: With shock absorber"
  },
  {
    featureId: "EF026165",
    featureDesc: "Suitable for rails",
    label: "EF026165: Suitable for rails"
  },
  {
    featureId: "EF026166",
    featureDesc: "Adjustable to body weight",
    label: "EF026166: Adjustable to body weight"
  },
  {
    featureId: "EF026167",
    featureDesc: "With seat belt",
    label: "EF026167: With seat belt"
  },
  {
    featureId: "EF026168",
    featureDesc: "Foldable backrest",
    label: "EF026168: Foldable backrest"
  },
  {
    featureId: "EF026169",
    featureDesc: "With lumbar support",
    label: "EF026169: With lumbar support"
  },
  {
    featureId: "EF026170",
    featureDesc: "With side lower support",
    label: "EF026170: With side lower support"
  },
  {
    featureId: "EF026171",
    featureDesc: "Foldable arm supports",
    label: "EF026171: Foldable arm supports"
  },
  {
    featureId: "EF026172",
    featureDesc: "Seat adjustable",
    label: "EF026172: Seat adjustable"
  },
  {
    featureId: "EF026174",
    featureDesc: "Arm supports adjustable",
    label: "EF026174: Arm supports adjustable"
  },
  {
    featureId: "EF026175",
    featureDesc: "Headrest adjustable",
    label: "EF026175: Headrest adjustable"
  },
  {
    featureId: "EF026176",
    featureDesc: "Lumbar support adjustable",
    label: "EF026176: Lumbar support adjustable"
  },
  {
    featureId: "EF026177",
    featureDesc: "Side lower support adjustable",
    label: "EF026177: Side lower support adjustable"
  },
  {
    featureId: "EF026178",
    featureDesc: "With footrest",
    label: "EF026178: With footrest"
  },
  {
    featureId: "EF026179",
    featureDesc: "Footrest adjustable",
    label: "EF026179: Footrest adjustable"
  },
  {
    featureId: "EF026180",
    featureDesc: "Foldable footrest",
    label: "EF026180: Foldable footrest"
  },
  {
    featureId: "EF026181",
    featureDesc: "Material table",
    label: "EF026181: Material table"
  },
  {
    featureId: "EF026182",
    featureDesc: "Material top surface",
    label: "EF026182: Material top surface"
  },
  {
    featureId: "EF026183",
    featureDesc: "Reaction force",
    label: "EF026183: Reaction force"
  },
  {
    featureId: "EF026184",
    featureDesc: "Energy absorption",
    label: "EF026184: Energy absorption"
  },
  {
    featureId: "EF026185",
    featureDesc: "Bow/stern fender",
    label: "EF026185: Bow/stern fender"
  },
  {
    featureId: "EF026186",
    featureDesc: "With plate",
    label: "EF026186: With plate"
  },
  {
    featureId: "EF026187",
    featureDesc: "Pitch circle shore connection",
    label: "EF026187: Pitch circle shore connection"
  },
  {
    featureId: "EF026188",
    featureDesc: "Pitch circle head",
    label: "EF026188: Pitch circle head"
  },
  {
    featureId: "EF026189",
    featureDesc: "Number of bolt holes 1 connection",
    label: "EF026189: Number of bolt holes 1 connection"
  },
  {
    featureId: "EF026190",
    featureDesc: "Diameter bolt hole (metric)",
    label: "EF026190: Diameter bolt hole (metric)"
  },
  {
    featureId: "EF026191",
    featureDesc: "Donut model",
    label: "EF026191: Donut model"
  },
  {
    featureId: "EF026192",
    featureDesc: "With chain and tyre net",
    label: "EF026192: With chain and tyre net"
  },
  {
    featureId: "EF026193",
    featureDesc: "Outer diameter roller/wheel",
    label: "EF026193: Outer diameter roller/wheel"
  },
  {
    featureId: "EF026194",
    featureDesc: "Height roller/wheel",
    label: "EF026194: Height roller/wheel"
  },
  {
    featureId: "EF026195",
    featureDesc: "Deflection",
    label: "EF026195: Deflection"
  },
  {
    featureId: "EF026196",
    featureDesc: "Tread thickness",
    label: "EF026196: Tread thickness"
  },
  {
    featureId: "EF026198",
    featureDesc: "Power loudspeaker",
    label: "EF026198: Power loudspeaker"
  },
  {
    featureId: "EF026201",
    featureDesc: "With wristband",
    label: "EF026201: With wristband"
  },
  {
    featureId: "EF026202",
    featureDesc: "With belt clip",
    label: "EF026202: With belt clip"
  },
  {
    featureId: "EF026203",
    featureDesc: "With battery charger",
    label: "EF026203: With battery charger"
  },
  {
    featureId: "EF026204",
    featureDesc: "With antenna",
    label: "EF026204: With antenna"
  },
  {
    featureId: "EF026207",
    featureDesc: "Nominal input voltage 1",
    label: "EF026207: Nominal input voltage 1"
  },
  {
    featureId: "EF026208",
    featureDesc: "Nominal input voltage 2",
    label: "EF026208: Nominal input voltage 2"
  },
  {
    featureId: "EF026209",
    featureDesc: "Passage ventilation grille",
    label: "EF026209: Passage ventilation grille"
  },
  {
    featureId: "EF026210",
    featureDesc: "Sill height",
    label: "EF026210: Sill height"
  },
  {
    featureId: "EF026211",
    featureDesc: "With ajar hook",
    label: "EF026211: With ajar hook"
  },
  {
    featureId: "EF026212",
    featureDesc: "Model lock",
    label: "EF026212: Model lock"
  },
  {
    featureId: "EF026214",
    featureDesc: "Max. door weight",
    label: "EF026214: Max. door weight"
  },
  {
    featureId: "EF026215",
    featureDesc: "With opening brake",
    label: "EF026215: With opening brake"
  },
  {
    featureId: "EF026216",
    featureDesc: "With toggle",
    label: "EF026216: With toggle"
  },
  {
    featureId: "EF026217",
    featureDesc: "Material drawer",
    label: "EF026217: Material drawer"
  },
  {
    featureId: "EF026218",
    featureDesc: "Material drawer front",
    label: "EF026218: Material drawer front"
  },
  {
    featureId: "EF026219",
    featureDesc: "Max. angle sensor",
    label: "EF026219: Max. angle sensor"
  },
  {
    featureId: "EF026220",
    featureDesc: "Number of windows",
    label: "EF026220: Number of windows"
  },
  {
    featureId: "EF026221",
    featureDesc: "Diameter window 1",
    label: "EF026221: Diameter window 1"
  },
  {
    featureId: "EF026222",
    featureDesc: "Diameter window 2",
    label: "EF026222: Diameter window 2"
  },
  {
    featureId: "EF026223",
    featureDesc: "Width window 1",
    label: "EF026223: Width window 1"
  },
  {
    featureId: "EF026225",
    featureDesc: "Width window 2",
    label: "EF026225: Width window 2"
  },
  {
    featureId: "EF026226",
    featureDesc: "Height window 1",
    label: "EF026226: Height window 1"
  },
  {
    featureId: "EF026227",
    featureDesc: "Height window 2",
    label: "EF026227: Height window 2"
  },
  {
    featureId: "EF026228",
    featureDesc: "Double glazing",
    label: "EF026228: Double glazing"
  },
  {
    featureId: "EF026229",
    featureDesc: "With bearing bush",
    label: "EF026229: With bearing bush"
  },
  {
    featureId: "EF026230",
    featureDesc: "Toggle method",
    label: "EF026230: Toggle method"
  },
  {
    featureId: "EF026231",
    featureDesc: "Max. swivel range horizontal",
    label: "EF026231: Max. swivel range horizontal"
  },
  {
    featureId: "EF026232",
    featureDesc: "Max. swivel range vertical",
    label: "EF026232: Max. swivel range vertical"
  },
  {
    featureId: "EF026233",
    featureDesc: "Flange compression stage connection outlet side",
    label: "EF026233: Flange compression stage connection outlet side"
  },
  {
    featureId: "EF026234",
    featureDesc: "Rotatable lifting point",
    label: "EF026234: Rotatable lifting point"
  },
  {
    featureId: "EF026235",
    featureDesc: "Nominal diameter water inlet side",
    label: "EF026235: Nominal diameter water inlet side"
  },
  {
    featureId: "EF026236",
    featureDesc: "Connection water inlet side",
    label: "EF026236: Connection water inlet side"
  },
  {
    featureId: "EF026237",
    featureDesc: "Nominal diameter foam inlet side",
    label: "EF026237: Nominal diameter foam inlet side"
  },
  {
    featureId: "EF026238",
    featureDesc: "Connection foam inlet side",
    label: "EF026238: Connection foam inlet side"
  },
  {
    featureId: "EF026239",
    featureDesc: "Approved according to MED",
    label: "EF026239: Approved according to MED"
  },
  {
    featureId: "EF026240",
    featureDesc: "Flange compression stage connection water inlet side",
    label: "EF026240: Flange compression stage connection water inlet side"
  },
  {
    featureId: "EF026241",
    featureDesc: "Flange compression stage connection foam inlet side",
    label: "EF026241: Flange compression stage connection foam inlet side"
  },
  {
    featureId: "EF026243",
    featureDesc: "Number of stanchions per side",
    label: "EF026243: Number of stanchions per side"
  },
  {
    featureId: "EF026244",
    featureDesc: "Total number of stanchions",
    label: "EF026244: Total number of stanchions"
  },
  {
    featureId: "EF026245",
    featureDesc: "Max. load per m²",
    label: "EF026245: Max. load per m²"
  },
  {
    featureId: "EF026246",
    featureDesc: "With safety net",
    label: "EF026246: With safety net"
  },
  {
    featureId: "EF026247",
    featureDesc: "With rope railing",
    label: "EF026247: With rope railing"
  },
  {
    featureId: "EF026248",
    featureDesc: "Angle height",
    label: "EF026248: Angle height"
  },
  {
    featureId: "EF026249",
    featureDesc: "Pre-glued",
    label: "EF026249: Pre-glued"
  },
  {
    featureId: "EF026250",
    featureDesc: "With wardrobe rod",
    label: "EF026250: With wardrobe rod"
  },
  {
    featureId: "EF026251",
    featureDesc: "Entry side",
    label: "EF026251: Entry side"
  },
  {
    featureId: "EF026252",
    featureDesc: "With bottom",
    label: "EF026252: With bottom"
  },
  {
    featureId: "EF026253",
    featureDesc: "With end pieces",
    label: "EF026253: With end pieces"
  },
  {
    featureId: "EF026254",
    featureDesc: "Number of laths",
    label: "EF026254: Number of laths"
  },
  {
    featureId: "EF026255",
    featureDesc: "Material laths",
    label: "EF026255: Material laths"
  },
  {
    featureId: "EF026259",
    featureDesc: "Outer width small base",
    label: "EF026259: Outer width small base"
  },
  {
    featureId: "EF026260",
    featureDesc: "Outer width large base",
    label: "EF026260: Outer width large base"
  },
  {
    featureId: "EF026261",
    featureDesc: "With ring",
    label: "EF026261: With ring"
  },
  {
    featureId: "EF026262",
    featureDesc: "Hole depth",
    label: "EF026262: Hole depth"
  },
  {
    featureId: "EF026263",
    featureDesc: "Overlay adjustment",
    label: "EF026263: Overlay adjustment"
  },
  {
    featureId: "EF026264",
    featureDesc: "Depth adjustment",
    label: "EF026264: Depth adjustment"
  },
  {
    featureId: "EF026265",
    featureDesc: "Height adjustment",
    label: "EF026265: Height adjustment"
  },
  {
    featureId: "EF026266",
    featureDesc: "Width top base",
    label: "EF026266: Width top base"
  },
  {
    featureId: "EF026267",
    featureDesc: "Width low base",
    label: "EF026267: Width low base"
  },
  {
    featureId: "EF026269",
    featureDesc: "With antenna cable",
    label: "EF026269: With antenna cable"
  },
  {
    featureId: "EF026270",
    featureDesc: "Length antenna cable",
    label: "EF026270: Length antenna cable"
  },
  {
    featureId: "EF026271",
    featureDesc: "Suitable for WAAS",
    label: "EF026271: Suitable for WAAS"
  },
  {
    featureId: "EF026272",
    featureDesc: "Suitable for EGNOS",
    label: "EF026272: Suitable for EGNOS"
  },
  {
    featureId: "EF026273",
    featureDesc: "Suitable for MSAS",
    label: "EF026273: Suitable for MSAS"
  },
  {
    featureId: "EF026274",
    featureDesc: "Suitable for SBAS",
    label: "EF026274: Suitable for SBAS"
  },
  {
    featureId: "EF026275",
    featureDesc: "Suitable for DGPS",
    label: "EF026275: Suitable for DGPS"
  },
  {
    featureId: "EF026278",
    featureDesc: "With knob",
    label: "EF026278: With knob"
  },
  {
    featureId: "EF026279",
    featureDesc: "Keyed alike",
    label: "EF026279: Keyed alike"
  },
  {
    featureId: "EF026280",
    featureDesc: "Material inner plating",
    label: "EF026280: Material inner plating"
  },
  {
    featureId: "EF026281",
    featureDesc: "With rudder trunk",
    label: "EF026281: With rudder trunk"
  },
  {
    featureId: "EF026282",
    featureDesc: "Nominal number of revolutions",
    label: "EF026282: Nominal number of revolutions"
  },
  {
    featureId: "EF026283",
    featureDesc: "Length antenna",
    label: "EF026283: Length antenna"
  },
  {
    featureId: "EF026284",
    featureDesc: "Ambient temperature display unit",
    label: "EF026284: Ambient temperature display unit"
  },
  {
    featureId: "EF026285",
    featureDesc: "Ambient temperature antenna",
    label: "EF026285: Ambient temperature antenna"
  },
  {
    featureId: "EF026286",
    featureDesc: "Height display unit",
    label: "EF026286: Height display unit"
  },
  {
    featureId: "EF026287",
    featureDesc: "Width display unit",
    label: "EF026287: Width display unit"
  },
  {
    featureId: "EF026288",
    featureDesc: "Depth display unit",
    label: "EF026288: Depth display unit"
  },
  {
    featureId: "EF026289",
    featureDesc: "Housing material display unit",
    label: "EF026289: Housing material display unit"
  },
  {
    featureId: "EF026290",
    featureDesc: "Degree of protection (IP) display unit",
    label: "EF026290: Degree of protection (IP) display unit"
  },
  {
    featureId: "EF026291",
    featureDesc: "Degree of protection (IP) antenna",
    label: "EF026291: Degree of protection (IP) antenna"
  },
  {
    featureId: "EF026292",
    featureDesc: "Rotation speed antenna",
    label: "EF026292: Rotation speed antenna"
  },
  {
    featureId: "EF026293",
    featureDesc: "Model window",
    label: "EF026293: Model window"
  },
  {
    featureId: "EF026296",
    featureDesc: "Colour glass",
    label: "EF026296: Colour glass"
  },
  {
    featureId: "EF026297",
    featureDesc: "Suitable for number of pipes",
    label: "EF026297: Suitable for number of pipes"
  },
  {
    featureId: "EF026298",
    featureDesc: "Suitable for shaft diameter",
    label: "EF026298: Suitable for shaft diameter"
  },
  {
    featureId: "EF026300",
    featureDesc: "Seat height adjustable",
    label: "EF026300: Seat height adjustable"
  },
  {
    featureId: "EF026301",
    featureDesc: "Number of bolt holes connection 1",
    label: "EF026301: Number of bolt holes connection 1"
  },
  {
    featureId: "EF026302",
    featureDesc: "Number of bolt holes connection 2",
    label: "EF026302: Number of bolt holes connection 2"
  },
  {
    featureId: "EF026303",
    featureDesc: "Bolt dimension connection 1 (metric)",
    label: "EF026303: Bolt dimension connection 1 (metric)"
  },
  {
    featureId: "EF026304",
    featureDesc: "Bolt dimension connection 2 (metric)",
    label: "EF026304: Bolt dimension connection 2 (metric)"
  },
  {
    featureId: "EF026307",
    featureDesc: "With bucket",
    label: "EF026307: With bucket"
  },
  {
    featureId: "EF026308",
    featureDesc: "Bucket volume",
    label: "EF026308: Bucket volume"
  },
  {
    featureId: "EF026309",
    featureDesc: "Length stick",
    label: "EF026309: Length stick"
  },
  {
    featureId: "EF026310",
    featureDesc: "With screw cap",
    label: "EF026310: With screw cap"
  },
  {
    featureId: "EF026311",
    featureDesc: "With overall/safety suit",
    label: "EF026311: With overall/safety suit"
  },
  {
    featureId: "EF026312",
    featureDesc: "With oxygen bottle",
    label: "EF026312: With oxygen bottle"
  },
  {
    featureId: "EF026313",
    featureDesc: "With axe",
    label: "EF026313: With axe"
  },
  {
    featureId: "EF026314",
    featureDesc: "With lamp",
    label: "EF026314: With lamp"
  },
  {
    featureId: "EF026315",
    featureDesc: "With crowbar",
    label: "EF026315: With crowbar"
  },
  {
    featureId: "EF026316",
    featureDesc: "With ear handle",
    label: "EF026316: With ear handle"
  },
  {
    featureId: "EF026317",
    featureDesc: "Dishwasher safe",
    label: "EF026317: Dishwasher safe"
  },
  {
    featureId: "EF026318",
    featureDesc: "Microwave safe",
    label: "EF026318: Microwave safe"
  },
  {
    featureId: "EF026319",
    featureDesc: "Oven safe",
    label: "EF026319: Oven safe"
  },
  {
    featureId: "EF026320",
    featureDesc: "With drawstring",
    label: "EF026320: With drawstring"
  },
  {
    featureId: "EF026321",
    featureDesc: "Material tick",
    label: "EF026321: Material tick"
  },
  {
    featureId: "EF026322",
    featureDesc: "Door thickness",
    label: "EF026322: Door thickness"
  },
  {
    featureId: "EF026323",
    featureDesc: "Width left",
    label: "EF026323: Width left"
  },
  {
    featureId: "EF026324",
    featureDesc: "Width right",
    label: "EF026324: Width right"
  },
  {
    featureId: "EF026325",
    featureDesc: "Seat width left",
    label: "EF026325: Seat width left"
  },
  {
    featureId: "EF026326",
    featureDesc: "Seat width right",
    label: "EF026326: Seat width right"
  },
  {
    featureId: "EF026328",
    featureDesc: "Grid type",
    label: "EF026328: Grid type"
  },
  {
    featureId: "EF026329",
    featureDesc: "Suitable for fuel/oil",
    label: "EF026329: Suitable for fuel/oil"
  },
  {
    featureId: "EF026332",
    featureDesc: "Bending strength",
    label: "EF026332: Bending strength"
  },
  {
    featureId: "EF026334",
    featureDesc: "Shear strength",
    label: "EF026334: Shear strength"
  },
  {
    featureId: "EF026335",
    featureDesc: "Modulus of elasticity",
    label: "EF026335: Modulus of elasticity"
  },
  {
    featureId: "EF026338",
    featureDesc: "Euro fire class according to EN 13501-1",
    label: "EF026338: Euro fire class according to EN 13501-1"
  },
  {
    featureId: "EF026339",
    featureDesc: "Durability class",
    label: "EF026339: Durability class"
  },
  {
    featureId: "EF026341",
    featureDesc: "Format (size)",
    label: "EF026341: Format (size)"
  },
  {
    featureId: "EF026342",
    featureDesc: "Colour nuance",
    label: "EF026342: Colour nuance"
  },
  {
    featureId: "EF026343",
    featureDesc: "Number per square meter",
    label: "EF026343: Number per square meter"
  },
  {
    featureId: "EF026345",
    featureDesc: "Average normalized compressive strength",
    label: "EF026345: Average normalized compressive strength"
  },
  {
    featureId: "EF026348",
    featureDesc: "Water absorption",
    label: "EF026348: Water absorption"
  },
  {
    featureId: "EF026349",
    featureDesc: "Gross density",
    label: "EF026349: Gross density"
  },
  {
    featureId: "EF026350",
    featureDesc: "With bevelled edge (facet)",
    label: "EF026350: With bevelled edge (facet)"
  },
  {
    featureId: "EF026351",
    featureDesc: "Asbestos-containing",
    label: "EF026351: Asbestos-containing"
  },
  {
    featureId: "EF026352",
    featureDesc: "Frost-/icing salt resistance",
    label: "EF026352: Frost-/icing salt resistance"
  },
  {
    featureId: "EF026353",
    featureDesc: "Size tolerance (diagonal)",
    label: "EF026353: Size tolerance (diagonal)"
  },
  {
    featureId: "EF026354",
    featureDesc: "Size tolerances length/width/thickness",
    label: "EF026354: Size tolerances length/width/thickness"
  },
  {
    featureId: "EF026361",
    featureDesc: "Material rudder stock",
    label: "EF026361: Material rudder stock"
  },
  {
    featureId: "EF026362",
    featureDesc: "Material rudder blade",
    label: "EF026362: Material rudder blade"
  },
  {
    featureId: "EF026363",
    featureDesc: "Euro class smoke production according to EN 13501-1",
    label: "EF026363: Euro class smoke production according to EN 13501-1"
  },
  {
    featureId: "EF026364",
    featureDesc: "Euro class flaming droplets/particles according to EN 13501-1",
    label: "EF026364: Euro class flaming droplets/particles according to EN 13501-1"
  },
  {
    featureId: "EF026365",
    featureDesc: "With wearing sleeve",
    label: "EF026365: With wearing sleeve"
  },
  {
    featureId: "EF026366",
    featureDesc: "Shaft bore",
    label: "EF026366: Shaft bore"
  },
  {
    featureId: "EF026367",
    featureDesc: "Suitable for propeller diameter",
    label: "EF026367: Suitable for propeller diameter"
  },
  {
    featureId: "EF026369",
    featureDesc: "With gas spring",
    label: "EF026369: With gas spring"
  },
  {
    featureId: "EF026370",
    featureDesc: "Diameter stop",
    label: "EF026370: Diameter stop"
  },
  {
    featureId: "EF026373",
    featureDesc: "Propeller class",
    label: "EF026373: Propeller class"
  },
  {
    featureId: "EF026374",
    featureDesc: "Blade area ratio",
    label: "EF026374: Blade area ratio"
  },
  {
    featureId: "EF026375",
    featureDesc: "With tensioner",
    label: "EF026375: With tensioner"
  },
  {
    featureId: "EF026379",
    featureDesc: "Insert length connection 1",
    label: "EF026379: Insert length connection 1"
  },
  {
    featureId: "EF026380",
    featureDesc: "Insert length connection 2",
    label: "EF026380: Insert length connection 2"
  },
  {
    featureId: "EF026381",
    featureDesc: "Insert length connection 3",
    label: "EF026381: Insert length connection 3"
  },
  {
    featureId: "EF026383",
    featureDesc: "Outer diameter hub",
    label: "EF026383: Outer diameter hub"
  },
  {
    featureId: "EF026384",
    featureDesc: "Kind of optimization",
    label: "EF026384: Kind of optimization"
  },
  {
    featureId: "EF026387",
    featureDesc: "Modulating compressor",
    label: "EF026387: Modulating compressor"
  },
  {
    featureId: "EF026389",
    featureDesc: "Diameter bar",
    label: "EF026389: Diameter bar"
  },
  {
    featureId: "EF026390",
    featureDesc: "Width bar",
    label: "EF026390: Width bar"
  },
  {
    featureId: "EF026391",
    featureDesc: "Height bar",
    label: "EF026391: Height bar"
  },
  {
    featureId: "EF026392",
    featureDesc: "Grid lockable",
    label: "EF026392: Grid lockable"
  },
  {
    featureId: "EF026394",
    featureDesc: "Connection combi 72, side bottom-right/side bottom-left",
    label: "EF026394: Connection combi 72, side bottom-right/side bottom-left"
  },
  {
    featureId: "EF026395",
    featureDesc: "Degree of protection (IP) cabinet",
    label: "EF026395: Degree of protection (IP) cabinet"
  },
  {
    featureId: "EF026396",
    featureDesc: "Degree of protection (IP) pump",
    label: "EF026396: Degree of protection (IP) pump"
  },
  {
    featureId: "EF026397",
    featureDesc: "With sand filter",
    label: "EF026397: With sand filter"
  },
  {
    featureId: "EF026398",
    featureDesc: "Stiffness",
    label: "EF026398: Stiffness"
  },
  {
    featureId: "EF026399",
    featureDesc: "Diameter metrical",
    label: "EF026399: Diameter metrical"
  },
  {
    featureId: "EF026400",
    featureDesc: "Connecting direction",
    label: "EF026400: Connecting direction"
  },
  {
    featureId: "EF026401",
    featureDesc: "Max. vacuum without support ring",
    label: "EF026401: Max. vacuum without support ring"
  },
  {
    featureId: "EF026402",
    featureDesc: "Max. vacuum with support ring",
    label: "EF026402: Max. vacuum with support ring"
  },
  {
    featureId: "EF026403",
    featureDesc: "Thread size connection piston rod",
    label: "EF026403: Thread size connection piston rod"
  },
  {
    featureId: "EF026404",
    featureDesc: "Thread size connection cylinder",
    label: "EF026404: Thread size connection cylinder"
  },
  {
    featureId: "EF026405",
    featureDesc: "Max. blade length",
    label: "EF026405: Max. blade length"
  },
  {
    featureId: "EF026406",
    featureDesc: "Diameter drive shaft",
    label: "EF026406: Diameter drive shaft"
  },
  {
    featureId: "EF026407",
    featureDesc: "Diameter support shaft",
    label: "EF026407: Diameter support shaft"
  },
  {
    featureId: "EF026408",
    featureDesc: "With hydrostatic release unit",
    label: "EF026408: With hydrostatic release unit"
  },
  {
    featureId: "EF026409",
    featureDesc: "Fully balanced",
    label: "EF026409: Fully balanced"
  },
  {
    featureId: "EF026410",
    featureDesc: "With sole piece",
    label: "EF026410: With sole piece"
  },
  {
    featureId: "EF026411",
    featureDesc: "With power take-off (PTO)",
    label: "EF026411: With power take-off (PTO)"
  },
  {
    featureId: "EF026412",
    featureDesc: "Service side",
    label: "EF026412: Service side"
  },
  {
    featureId: "EF026413",
    featureDesc: "Two part with ratchet",
    label: "EF026413: Two part with ratchet"
  },
  {
    featureId: "EF026417",
    featureDesc: "For blade length",
    label: "EF026417: For blade length"
  },
  {
    featureId: "EF026420",
    featureDesc: "Number of drainage holes",
    label: "EF026420: Number of drainage holes"
  },
  {
    featureId: "EFAT0001",
    featureDesc: "Rated current of overload protection device with standard pin acc. to OVE R 21",
    label: "EFAT0001: Rated current of overload protection device with standard pin acc. to OVE R 21"
  },
  {
    featureId: "EFAT0002",
    featureDesc: "Rated current of overload protection device with system pin acc. to OVE R 21",
    label: "EFAT0002: Rated current of overload protection device with system pin acc. to OVE R 21"
  },
  {
    featureId: "EFAU0001",
    featureDesc: "Low smoke according to AS/NZS 1660.5.2",
    label: "EFAU0001: Low smoke according to AS/NZS 1660.5.2"
  },
  {
    featureId: "EFBE0001",
    featureDesc: "Certified according to Belgaqua",
    label: "EFBE0001: Certified according to Belgaqua"
  },
  {
    featureId: "EFCH0001",
    featureDesc: "SVGW certified",
    label: "EFCH0001: SVGW certified"
  },
  {
    featureId: "EFDE0001",
    featureDesc: "VDE tested",
    label: "EFDE0001: VDE tested"
  },
  {
    featureId: "EFDE0003",
    featureDesc: "VdS class",
    label: "EFDE0003: VdS class"
  },
  {
    featureId: "EFDE0004",
    featureDesc: "Compliant with TRBS 2121-2",
    label: "EFDE0004: Compliant with TRBS 2121-2"
  },
  {
    featureId: "EFDE0005",
    featureDesc: "For heavy conditions according to VDE",
    label: "EFDE0005: For heavy conditions according to VDE"
  },
  {
    featureId: "EFDE0006",
    featureDesc: "DIN-CERTCO certificate",
    label: "EFDE0006: DIN-CERTCO certificate"
  },
  {
    featureId: "EFDE0007",
    featureDesc: "Tested according to TÜV GS",
    label: "EFDE0007: Tested according to TÜV GS"
  },
  {
    featureId: "EFDE0008",
    featureDesc: "Socket according to DIN 3383-1",
    label: "EFDE0008: Socket according to DIN 3383-1"
  },
  {
    featureId: "EFDE0010",
    featureDesc: "VdS quality mark",
    label: "EFDE0010: VdS quality mark"
  },
  {
    featureId: "EFDE0011",
    featureDesc: "Zeta value branch fluent separation according to DVGW W 575",
    label: "EFDE0011: Zeta value branch fluent separation according to DVGW W 575"
  },
  {
    featureId: "EFDE0012",
    featureDesc: "Zeta value gangway current separation according to DVGW W 575",
    label: "EFDE0012: Zeta value gangway current separation according to DVGW W 575"
  },
  {
    featureId: "EFDE0013",
    featureDesc: "Zeta value counter current separation according to DVGW W 575",
    label: "EFDE0013: Zeta value counter current separation according to DVGW W 575"
  },
  {
    featureId: "EFDE0014",
    featureDesc: "Zeta value branch merging flow according to DVGW W 575",
    label: "EFDE0014: Zeta value branch merging flow according to DVGW W 575"
  },
  {
    featureId: "EFDE0015",
    featureDesc: "Zeta value passage merging flow according to DVGW W 575",
    label: "EFDE0015: Zeta value passage merging flow according to DVGW W 575"
  },
  {
    featureId: "EFDE0016",
    featureDesc: "Zeta value counter merging flow according to DVGW W 575",
    label: "EFDE0016: Zeta value counter merging flow according to DVGW W 575"
  },
  {
    featureId: "EFDE0017",
    featureDesc: "Head form according to DIN 8032",
    label: "EFDE0017: Head form according to DIN 8032"
  },
  {
    featureId: "EFDE0018",
    featureDesc: "Suitable for waste water containing faeces according to DIN 1986-100",
    label: "EFDE0018: Suitable for waste water containing faeces according to DIN 1986-100"
  },
  {
    featureId: "EFDE0019",
    featureDesc: "German approval DIBt",
    label: "EFDE0019: German approval DIBt"
  },
  {
    featureId: "EFDE0020",
    featureDesc: "KTW quality mark",
    label: "EFDE0020: KTW quality mark"
  },
  {
    featureId: "EFDE0024",
    featureDesc: "DIN-compatible",
    label: "EFDE0024: DIN-compatible"
  },
  {
    featureId: "EFDE0025",
    featureDesc: "Construction size according to DIN 43880",
    label: "EFDE0025: Construction size according to DIN 43880"
  },
  {
    featureId: "EFDE0026",
    featureDesc: "Approved according to DIN 5510",
    label: "EFDE0026: Approved according to DIN 5510"
  },
  {
    featureId: "EFDE0027",
    featureDesc: "Approved according to VdTÜV/GGVSEB",
    label: "EFDE0027: Approved according to VdTÜV/GGVSEB"
  },
  {
    featureId: "EFDE0028",
    featureDesc: "Approved in combination with protective conduit VdTÜV/GGVSEB",
    label: "EFDE0028: Approved in combination with protective conduit VdTÜV/GGVSEB"
  },
  {
    featureId: "EFDE0031",
    featureDesc: "With approval for TÜV",
    label: "EFDE0031: With approval for TÜV"
  },
  {
    featureId: "EFDE0032",
    featureDesc: "Forced segregation according to DIN VDE 0113",
    label: "EFDE0032: Forced segregation according to DIN VDE 0113"
  },
  {
    featureId: "EFDE0033",
    featureDesc: "VdS accepted",
    label: "EFDE0033: VdS accepted"
  },
  {
    featureId: "EFDE0034",
    featureDesc: "German approval EltVTR",
    label: "EFDE0034: German approval EltVTR"
  },
  {
    featureId: "EFDE0035",
    featureDesc: "Inflammability class according to DIN 4102",
    label: "EFDE0035: Inflammability class according to DIN 4102"
  },
  {
    featureId: "EFDE0036",
    featureDesc: "Load class (DIN 2353)",
    label: "EFDE0036: Load class (DIN 2353)"
  },
  {
    featureId: "EFDE0037",
    featureDesc: "Size of dairy thread connection DN (DIN 405)",
    label: "EFDE0037: Size of dairy thread connection DN (DIN 405)"
  },
  {
    featureId: "EFDE0038",
    featureDesc: "DIN standard",
    label: "EFDE0038: DIN standard"
  },
  {
    featureId: "EFDE0039",
    featureDesc: "Suitable according to DIN 4102",
    label: "EFDE0039: Suitable according to DIN 4102"
  },
  {
    featureId: "EFDE0040",
    featureDesc: "Suitable according to DIN EN 1634",
    label: "EFDE0040: Suitable according to DIN EN 1634"
  },
  {
    featureId: "EFDE0041",
    featureDesc: "Suitable for burglary-resistance according to DIN EN 1627",
    label: "EFDE0041: Suitable for burglary-resistance according to DIN EN 1627"
  },
  {
    featureId: "EFDE0042",
    featureDesc: "Application class according to DIN 18251-1",
    label: "EFDE0042: Application class according to DIN 18251-1"
  },
  {
    featureId: "EFDE0043",
    featureDesc: "According to DIN 16270",
    label: "EFDE0043: According to DIN 16270"
  },
  {
    featureId: "EFDE0044",
    featureDesc: "According to DIN 16271",
    label: "EFDE0044: According to DIN 16271"
  },
  {
    featureId: "EFDE0045",
    featureDesc: "Plug according to DIN 3383-1",
    label: "EFDE0045: Plug according to DIN 3383-1"
  },
  {
    featureId: "EFDE0047",
    featureDesc: "According to sealing standard DIN-18534",
    label: "EFDE0047: According to sealing standard DIN-18534"
  },
  {
    featureId: "EFDE0048",
    featureDesc: "DVGW quality mark for gas",
    label: "EFDE0048: DVGW quality mark for gas"
  },
  {
    featureId: "EFDE0049",
    featureDesc: "DVGW quality mark for water",
    label: "EFDE0049: DVGW quality mark for water"
  },
  {
    featureId: "EFDE0050",
    featureDesc: "Insulation integrity according to DIN VDE 0472-814",
    label: "EFDE0050: Insulation integrity according to DIN VDE 0472-814"
  },
  {
    featureId: "EFDE0051",
    featureDesc: "Circuit integrity according to DIN 4102-12",
    label: "EFDE0051: Circuit integrity according to DIN 4102-12"
  },
  {
    featureId: "EFDE0052",
    featureDesc: "According to DIN 30653",
    label: "EFDE0052: According to DIN 30653"
  },
  {
    featureId: "EFDE0053",
    featureDesc: "Compliant with TA Air Regulations",
    label: "EFDE0053: Compliant with TA Air Regulations"
  },
  {
    featureId: "EFDE0054",
    featureDesc: "According to elastomer guideline (ELL)",
    label: "EFDE0054: According to elastomer guideline (ELL)"
  },
  {
    featureId: "EFDE0055",
    featureDesc: "BAM tested",
    label: "EFDE0055: BAM tested"
  },
  {
    featureId: "EFDE0056",
    featureDesc: "EMICODE label",
    label: "EFDE0056: EMICODE label"
  },
  {
    featureId: "EFDE0057",
    featureDesc: "Suitable according to DIN 19266",
    label: "EFDE0057: Suitable according to DIN 19266"
  },
  {
    featureId: "EFDE0058",
    featureDesc: "Approved according to DAkkS",
    label: "EFDE0058: Approved according to DAkkS"
  },
  {
    featureId: "EFDE0060",
    featureDesc: "Fire resistance class according to DIN 4102-9",
    label: "EFDE0060: Fire resistance class according to DIN 4102-9"
  },
  {
    featureId: "EFDE0063",
    featureDesc: "Building sealing according to DIN 18533",
    label: "EFDE0063: Building sealing according to DIN 18533"
  },
  {
    featureId: "EFDE0064",
    featureDesc: "Building sealing according to DAfStb directive",
    label: "EFDE0064: Building sealing according to DAfStb directive"
  },
  {
    featureId: "EFDE0065",
    featureDesc: "Compliant with DIN 72553",
    label: "EFDE0065: Compliant with DIN 72553"
  },
  {
    featureId: "EFDE0066",
    featureDesc: "Calibration law compliant",
    label: "EFDE0066: Calibration law compliant"
  },
  {
    featureId: "EFDE0067",
    featureDesc: "Testing according to VDE 0701",
    label: "EFDE0067: Testing according to VDE 0701"
  },
  {
    featureId: "EFDE0068",
    featureDesc: "Testing according to VDE 0702",
    label: "EFDE0068: Testing according to VDE 0702"
  },
  {
    featureId: "EFDE0069",
    featureDesc: "Testing according to VDE 0701/0702",
    label: "EFDE0069: Testing according to VDE 0701/0702"
  },
  {
    featureId: "EFDE0070",
    featureDesc: "Testing according to VDE 0751",
    label: "EFDE0070: Testing according to VDE 0751"
  },
  {
    featureId: "EFDE0071",
    featureDesc: "Testing according to VDE 540-4",
    label: "EFDE0071: Testing according to VDE 540-4"
  },
  {
    featureId: "EFDE0072",
    featureDesc: "Min. fire resistance class according to DIN EN 13502-2",
    label: "EFDE0072: Min. fire resistance class according to DIN EN 13502-2"
  },
  {
    featureId: "EFDE0073",
    featureDesc: "Max. fire resistance class according to DIN EN 13502-2",
    label: "EFDE0073: Max. fire resistance class according to DIN EN 13502-2"
  },
  {
    featureId: "EFDE0074",
    featureDesc: "Barrier-free according to DIN 18404",
    label: "EFDE0074: Barrier-free according to DIN 18404"
  },
  {
    featureId: "EFDE0075",
    featureDesc: "Operating forces tested according to DIN 12217",
    label: "EFDE0075: Operating forces tested according to DIN 12217"
  },
  {
    featureId: "EFDE0076",
    featureDesc: "Tested according to DIN EN 16337",
    label: "EFDE0076: Tested according to DIN EN 16337"
  },
  {
    featureId: "EFDE0077",
    featureDesc: "Approved according to DIN 18650",
    label: "EFDE0077: Approved according to DIN 18650"
  },
  {
    featureId: "EFDE0080",
    featureDesc: "Durability class according to DIN 18650",
    label: "EFDE0080: Durability class according to DIN 18650"
  },
  {
    featureId: "EFDE0082",
    featureDesc: "Suitability class as a fire protection door according to DIN 18650",
    label: "EFDE0082: Suitability class as a fire protection door according to DIN 18650"
  },
  {
    featureId: "EFDE0086",
    featureDesc: "Special requirement class for the drive according to DIN 18650",
    label: "EFDE0086: Special requirement class for the drive according to DIN 18650"
  },
  {
    featureId: "EFDE0091",
    featureDesc: "Ambient temperature class according to DIN 18650",
    label: "EFDE0091: Ambient temperature class according to DIN 18650"
  },
  {
    featureId: "EFDE0092",
    featureDesc: "Endurance strength according to DIN EN 15338",
    label: "EFDE0092: Endurance strength according to DIN EN 15338"
  },
  {
    featureId: "EFDE0093",
    featureDesc: "Endurance strength according to DIN EN 14073",
    label: "EFDE0093: Endurance strength according to DIN EN 14073"
  },
  {
    featureId: "EFDE0094",
    featureDesc: "Endurance strength according to DIN EN 14749",
    label: "EFDE0094: Endurance strength according to DIN EN 14749"
  },
  {
    featureId: "EFDE0095",
    featureDesc: "Corrosion resistance according to DIN EN 1670",
    label: "EFDE0095: Corrosion resistance according to DIN EN 1670"
  },
  {
    featureId: "EFDE0096",
    featureDesc: "Corrosion resistance according to DIN EN 17737",
    label: "EFDE0096: Corrosion resistance according to DIN EN 17737"
  },
  {
    featureId: "EFDE0097",
    featureDesc: "Corrosion resistance according to DIN EN ISO 6270",
    label: "EFDE0097: Corrosion resistance according to DIN EN ISO 6270"
  },
  {
    featureId: "EFDE0098",
    featureDesc: "Corrosion resistance according to DIN EN ISO 9227",
    label: "EFDE0098: Corrosion resistance according to DIN EN ISO 9227"
  },
  {
    featureId: "EFDE0099",
    featureDesc: "Testing according to DIN VDE 0113",
    label: "EFDE0099: Testing according to DIN VDE 0113"
  },
  {
    featureId: "EFDE0102",
    featureDesc: "CCA operating range according to DIN",
    label: "EFDE0102: CCA operating range according to DIN"
  },
  {
    featureId: "EFDE0103",
    featureDesc: "Barrier-free according to DIN CERTCO DIN 18040",
    label: "EFDE0103: Barrier-free according to DIN CERTCO DIN 18040"
  },
  {
    featureId: "EFDE0104",
    featureDesc: "Calibration certificate according to DAkkS",
    label: "EFDE0104: Calibration certificate according to DAkkS"
  },
  {
    featureId: "EFDK0001",
    featureDesc: "Approved according to GDV",
    label: "EFDK0001: Approved according to GDV"
  },
  {
    featureId: "EFDK0002",
    featureDesc: "Approved according to VA",
    label: "EFDK0002: Approved according to VA"
  },
  {
    featureId: "EFFR0001",
    featureDesc: "Compatible with Grade2TV according to XP-C 90-483",
    label: "EFFR0001: Compatible with Grade2TV according to XP-C 90-483"
  },
  {
    featureId: "EFFR0002",
    featureDesc: "Compatible with Grade3TV according to XP-C 90-483",
    label: "EFFR0002: Compatible with Grade3TV according to XP-C 90-483"
  },
  {
    featureId: "EFFR0003",
    featureDesc: "Certified according to NF (hot water)",
    label: "EFFR0003: Certified according to NF (hot water)"
  },
  {
    featureId: "EFFR0004",
    featureDesc: "Certified according to NF (electricity)",
    label: "EFFR0004: Certified according to NF (electricity)"
  },
  {
    featureId: "EFFR0005",
    featureDesc: "Certified according to NF (electrical performance)",
    label: "EFFR0005: Certified according to NF (electrical performance)"
  },
  {
    featureId: "EFFR0008",
    featureDesc: "Approved according to ACS",
    label: "EFFR0008: Approved according to ACS"
  },
  {
    featureId: "EFFR0010",
    featureDesc: "COFRAC certified",
    label: "EFFR0010: COFRAC certified"
  },
  {
    featureId: "EFFR0011",
    featureDesc: "Compliant with NF EN 1916 (concrete pipes and fittings)",
    label: "EFFR0011: Compliant with NF EN 1916 (concrete pipes and fittings)"
  },
  {
    featureId: "EFFR0012",
    featureDesc: "Compliant with NF P16-346-2",
    label: "EFFR0012: Compliant with NF P16-346-2"
  },
  {
    featureId: "EFFR0014",
    featureDesc: "Compliant with NF 545",
    label: "EFFR0014: Compliant with NF 545"
  },
  {
    featureId: "EFFR0015",
    featureDesc: "Certified according to NF 545",
    label: "EFFR0015: Certified according to NF 545"
  },
  {
    featureId: "EFI00008",
    featureDesc: "Width",
    label: "EFI00008: Width"
  },
  {
    featureId: "EFI00014",
    featureDesc: "Sleeve length",
    label: "EFI00014: Sleeve length"
  },
  {
    featureId: "EFI00015",
    featureDesc: "Outer diameter",
    label: "EFI00015: Outer diameter"
  },
  {
    featureId: "EFI00016",
    featureDesc: "Suitable for max. cable diameter",
    label: "EFI00016: Suitable for max. cable diameter"
  },
  {
    featureId: "EFI00018",
    featureDesc: "Core cross section",
    label: "EFI00018: Core cross section"
  },
  {
    featureId: "EFI00026",
    featureDesc: "Duct width",
    label: "EFI00026: Duct width"
  },
  {
    featureId: "EFI00040",
    featureDesc: "Height",
    label: "EFI00040: Height"
  },
  {
    featureId: "EFI00049",
    featureDesc: "Depth",
    label: "EFI00049: Depth"
  },
  {
    featureId: "EFI00058",
    featureDesc: "Max. cable cross section",
    label: "EFI00058: Max. cable cross section"
  },
  {
    featureId: "EFI00060",
    featureDesc: "Nominal cross section conductor",
    label: "EFI00060: Nominal cross section conductor"
  },
  {
    featureId: "EFI00063",
    featureDesc: "Length belt/tape/tie",
    label: "EFI00063: Length belt/tape/tie"
  },
  {
    featureId: "EFI00065",
    featureDesc: "Inner diameter",
    label: "EFI00065: Inner diameter"
  },
  {
    featureId: "EFI00072",
    featureDesc: "Duct height",
    label: "EFI00072: Duct height"
  },
  {
    featureId: "EFI00076",
    featureDesc: "Power",
    label: "EFI00076: Power"
  },
  {
    featureId: "EFI00079",
    featureDesc: "Hole diameter",
    label: "EFI00079: Hole diameter"
  },
  {
    featureId: "EFI00085",
    featureDesc: "Conductor cross section",
    label: "EFI00085: Conductor cross section"
  },
  {
    featureId: "EFI00089",
    featureDesc: "Light centre height",
    label: "EFI00089: Light centre height"
  },
  {
    featureId: "EFI00090",
    featureDesc: "Mounting arm length",
    label: "EFI00090: Mounting arm length"
  },
  {
    featureId: "EFI00121",
    featureDesc: "Tape width",
    label: "EFI00121: Tape width"
  },
  {
    featureId: "EFI00125",
    featureDesc: "Thickness",
    label: "EFI00125: Thickness"
  },
  {
    featureId: "EFI00132",
    featureDesc: "Nominal dimension",
    label: "EFI00132: Nominal dimension"
  },
  {
    featureId: "EFI00154",
    featureDesc: "Length of blade",
    label: "EFI00154: Length of blade"
  },
  {
    featureId: "EFI00167",
    featureDesc: "Weight",
    label: "EFI00167: Weight"
  },
  {
    featureId: "EFI00174",
    featureDesc: "Stroke",
    label: "EFI00174: Stroke"
  },
  {
    featureId: "EFI00176",
    featureDesc: "Reservoir volume",
    label: "EFI00176: Reservoir volume"
  },
  {
    featureId: "EFI00177",
    featureDesc: "Wrench width",
    label: "EFI00177: Wrench width"
  },
  {
    featureId: "EFI00178",
    featureDesc: "Shaft length",
    label: "EFI00178: Shaft length"
  },
  {
    featureId: "EFI00199",
    featureDesc: "Suitable for duct cover height",
    label: "EFI00199: Suitable for duct cover height"
  },
  {
    featureId: "EFI00218",
    featureDesc: "Built-in depth",
    label: "EFI00218: Built-in depth"
  },
  {
    featureId: "EFI00229",
    featureDesc: "Wall thickness",
    label: "EFI00229: Wall thickness"
  },
  {
    featureId: "EFI00243",
    featureDesc: "Diameter openings",
    label: "EFI00243: Diameter openings"
  },
  {
    featureId: "EFI00258",
    featureDesc: "Max. load capacity",
    label: "EFI00258: Max. load capacity"
  },
  {
    featureId: "EFI00262",
    featureDesc: "Connection space",
    label: "EFI00262: Connection space"
  },
  {
    featureId: "EFI00267",
    featureDesc: "Max. cross section incoming cable",
    label: "EFI00267: Max. cross section incoming cable"
  },
  {
    featureId: "EFI00268",
    featureDesc: "Max. cross section connection cable",
    label: "EFI00268: Max. cross section connection cable"
  },
  {
    featureId: "EFI00303",
    featureDesc: "Overhang length",
    label: "EFI00303: Overhang length"
  },
  {
    featureId: "EFI00309",
    featureDesc: "Min. inner diameter",
    label: "EFI00309: Min. inner diameter"
  },
  {
    featureId: "EFI00326",
    featureDesc: "Suitable for enclosure building width",
    label: "EFI00326: Suitable for enclosure building width"
  },
  {
    featureId: "EFI00327",
    featureDesc: "Suitable for enclosure building depth",
    label: "EFI00327: Suitable for enclosure building depth"
  },
  {
    featureId: "EFI00332",
    featureDesc: "Built-in height",
    label: "EFI00332: Built-in height"
  },
  {
    featureId: "EFI00344",
    featureDesc: "Heating capacity",
    label: "EFI00344: Heating capacity"
  },
  {
    featureId: "EFI00352",
    featureDesc: "Max. door/gate width",
    label: "EFI00352: Max. door/gate width"
  },
  {
    featureId: "EFI00353",
    featureDesc: "Max. tensile force",
    label: "EFI00353: Max. tensile force"
  },
  {
    featureId: "EFI00354",
    featureDesc: "Belt thickness",
    label: "EFI00354: Belt thickness"
  },
  {
    featureId: "EFI00355",
    featureDesc: "Belt length",
    label: "EFI00355: Belt length"
  },
  {
    featureId: "EFI00356",
    featureDesc: "Max. weight of roller shutter",
    label: "EFI00356: Max. weight of roller shutter"
  },
  {
    featureId: "EFI00357",
    featureDesc: "Moment of torque",
    label: "EFI00357: Moment of torque"
  },
  {
    featureId: "EFI00358",
    featureDesc: "Length of power cord",
    label: "EFI00358: Length of power cord"
  },
  {
    featureId: "EFI00364",
    featureDesc: "Capacity",
    label: "EFI00364: Capacity"
  },
  {
    featureId: "EFI00368",
    featureDesc: "Thread length",
    label: "EFI00368: Thread length"
  },
  {
    featureId: "EFI00369",
    featureDesc: "Total length",
    label: "EFI00369: Total length"
  },
  {
    featureId: "EFI00371",
    featureDesc: "Nail length",
    label: "EFI00371: Nail length"
  },
  {
    featureId: "EFI00372",
    featureDesc: "Range",
    label: "EFI00372: Range"
  },
  {
    featureId: "EFI00382",
    featureDesc: "Length of anchor plug",
    label: "EFI00382: Length of anchor plug"
  },
  {
    featureId: "EFI00384",
    featureDesc: "Min. drill hole depth",
    label: "EFI00384: Min. drill hole depth"
  },
  {
    featureId: "EFI00386",
    featureDesc: "Material thickness",
    label: "EFI00386: Material thickness"
  },
  {
    featureId: "EFI00394",
    featureDesc: "Modular spacing",
    label: "EFI00394: Modular spacing"
  },
  {
    featureId: "EFI00419",
    featureDesc: "Jaw opening",
    label: "EFI00419: Jaw opening"
  },
  {
    featureId: "EFI00444",
    featureDesc: "Loading capacity",
    label: "EFI00444: Loading capacity"
  },
  {
    featureId: "EFI00445",
    featureDesc: "Height (from centre water connection)",
    label: "EFI00445: Height (from centre water connection)"
  },
  {
    featureId: "EFI00473",
    featureDesc: "Spiral length (retracted)",
    label: "EFI00473: Spiral length (retracted)"
  },
  {
    featureId: "EFI00474",
    featureDesc: "Spiral length (extended)",
    label: "EFI00474: Spiral length (extended)"
  },
  {
    featureId: "EFI00486",
    featureDesc: "Surface",
    label: "EFI00486: Surface"
  },
  {
    featureId: "EFI00493",
    featureDesc: "Immersing depth",
    label: "EFI00493: Immersing depth"
  },
  {
    featureId: "EFI00521",
    featureDesc: "Rib diameter",
    label: "EFI00521: Rib diameter"
  },
  {
    featureId: "EFI00524",
    featureDesc: "Heat exchanger surface",
    label: "EFI00524: Heat exchanger surface"
  },
  {
    featureId: "EFI00525",
    featureDesc: "Flange diameter",
    label: "EFI00525: Flange diameter"
  },
  {
    featureId: "EFI00526",
    featureDesc: "Max. immersion depth",
    label: "EFI00526: Max. immersion depth"
  },
  {
    featureId: "EFI00536",
    featureDesc: "Cable length",
    label: "EFI00536: Cable length"
  },
  {
    featureId: "EFI00542",
    featureDesc: "Diameter of dish antenna",
    label: "EFI00542: Diameter of dish antenna"
  },
  {
    featureId: "EFI00551",
    featureDesc: "Diameter",
    label: "EFI00551: Diameter"
  },
  {
    featureId: "EFI00553",
    featureDesc: "Suitable for cable tie width to",
    label: "EFI00553: Suitable for cable tie width to"
  },
  {
    featureId: "EFI00595",
    featureDesc: "Max. measurable conduit length",
    label: "EFI00595: Max. measurable conduit length"
  },
  {
    featureId: "EFI00602",
    featureDesc: "For cable duct height",
    label: "EFI00602: For cable duct height"
  },
  {
    featureId: "EFI00603",
    featureDesc: "For cable duct width",
    label: "EFI00603: For cable duct width"
  },
  {
    featureId: "EFI00633",
    featureDesc: "Tank volume",
    label: "EFI00633: Tank volume"
  },
  {
    featureId: "EFI00634",
    featureDesc: "Max. pressure",
    label: "EFI00634: Max. pressure"
  },
  {
    featureId: "EFI00637",
    featureDesc: "Bag volume",
    label: "EFI00637: Bag volume"
  },
  {
    featureId: "EFI00649",
    featureDesc: "Cross section",
    label: "EFI00649: Cross section"
  },
  {
    featureId: "EFI00660",
    featureDesc: "Volume of water tank",
    label: "EFI00660: Volume of water tank"
  },
  {
    featureId: "EFI00666",
    featureDesc: "Partition",
    label: "EFI00666: Partition"
  },
  {
    featureId: "EFI00668",
    featureDesc: "Cutting thickness adjustable up to",
    label: "EFI00668: Cutting thickness adjustable up to"
  },
  {
    featureId: "EFI00675",
    featureDesc: "Loading capacity flour",
    label: "EFI00675: Loading capacity flour"
  },
  {
    featureId: "EFI00688",
    featureDesc: "Capacity espresso part",
    label: "EFI00688: Capacity espresso part"
  },
  {
    featureId: "EFI00695",
    featureDesc: "Volume cavity",
    label: "EFI00695: Volume cavity"
  },
  {
    featureId: "EFI00708",
    featureDesc: "Roller diameter",
    label: "EFI00708: Roller diameter"
  },
  {
    featureId: "EFI00709",
    featureDesc: "Roller width",
    label: "EFI00709: Roller width"
  },
  {
    featureId: "EFI00717",
    featureDesc: "Diameter cooking field 1",
    label: "EFI00717: Diameter cooking field 1"
  },
  {
    featureId: "EFI00718",
    featureDesc: "Diameter cooking field 2",
    label: "EFI00718: Diameter cooking field 2"
  },
  {
    featureId: "EFI00738",
    featureDesc: "Visible diagonal screen size",
    label: "EFI00738: Visible diagonal screen size"
  },
  {
    featureId: "EFI00769",
    featureDesc: "Net capacity freezing compartment",
    label: "EFI00769: Net capacity freezing compartment"
  },
  {
    featureId: "EFI00788",
    featureDesc: "Suitable for surface to",
    label: "EFI00788: Suitable for surface to"
  },
  {
    featureId: "EFI00846",
    featureDesc: "Built-in width",
    label: "EFI00846: Built-in width"
  },
  {
    featureId: "EFI00852",
    featureDesc: "Diameter tap hole",
    label: "EFI00852: Diameter tap hole"
  },
  {
    featureId: "EFI00862",
    featureDesc: "Length of ironing table",
    label: "EFI00862: Length of ironing table"
  },
  {
    featureId: "EFI00863",
    featureDesc: "Width ironing surface",
    label: "EFI00863: Width ironing surface"
  },
  {
    featureId: "EFI00864",
    featureDesc: "Width indoor unit",
    label: "EFI00864: Width indoor unit"
  },
  {
    featureId: "EFI00865",
    featureDesc: "Height indoor unit",
    label: "EFI00865: Height indoor unit"
  },
  {
    featureId: "EFI00866",
    featureDesc: "Depth indoor unit",
    label: "EFI00866: Depth indoor unit"
  },
  {
    featureId: "EFI00867",
    featureDesc: "Weight indoor unit",
    label: "EFI00867: Weight indoor unit"
  },
  {
    featureId: "EFI00868",
    featureDesc: "Height outdoor unit",
    label: "EFI00868: Height outdoor unit"
  },
  {
    featureId: "EFI00869",
    featureDesc: "Width outdoor unit",
    label: "EFI00869: Width outdoor unit"
  },
  {
    featureId: "EFI00870",
    featureDesc: "Depth outdoor unit",
    label: "EFI00870: Depth outdoor unit"
  },
  {
    featureId: "EFI00871",
    featureDesc: "Weight outdoor unit",
    label: "EFI00871: Weight outdoor unit"
  },
  {
    featureId: "EFI00874",
    featureDesc: "Max. drill diameter in steel",
    label: "EFI00874: Max. drill diameter in steel"
  },
  {
    featureId: "EFI00880",
    featureDesc: "Volume",
    label: "EFI00880: Volume"
  },
  {
    featureId: "EFI00943",
    featureDesc: "Built-in diameter",
    label: "EFI00943: Built-in diameter"
  },
  {
    featureId: "EFI00991",
    featureDesc: "Max. discharge flow",
    label: "EFI00991: Max. discharge flow"
  },
  {
    featureId: "EFI00992",
    featureDesc: "Max. static height",
    label: "EFI00992: Max. static height"
  },
  {
    featureId: "EFI00995",
    featureDesc: "Loading capacity (max. flour weight)",
    label: "EFI00995: Loading capacity (max. flour weight)"
  },
  {
    featureId: "EFI01001",
    featureDesc: "Head diameter",
    label: "EFI01001: Head diameter"
  },
  {
    featureId: "EFI01044",
    featureDesc: "Dimension",
    label: "EFI01044: Dimension"
  },
  {
    featureId: "EFI01053",
    featureDesc: "Mounting thickness",
    label: "EFI01053: Mounting thickness"
  },
  {
    featureId: "EFI01059",
    featureDesc: "Height side wall",
    label: "EFI01059: Height side wall"
  },
  {
    featureId: "EFI01063",
    featureDesc: "Width branch",
    label: "EFI01063: Width branch"
  },
  {
    featureId: "EFI01072",
    featureDesc: "Diameter feed-through",
    label: "EFI01072: Diameter feed-through"
  },
  {
    featureId: "EFI01073",
    featureDesc: "Wall thickness after shrinking",
    label: "EFI01073: Wall thickness after shrinking"
  },
  {
    featureId: "EFI01080",
    featureDesc: "Length of signalling route",
    label: "EFI01080: Length of signalling route"
  },
  {
    featureId: "EFI01084",
    featureDesc: "Shaft diameter",
    label: "EFI01084: Shaft diameter"
  },
  {
    featureId: "EFI01085",
    featureDesc: "Inner diameter of eye",
    label: "EFI01085: Inner diameter of eye"
  },
  {
    featureId: "EFI01130",
    featureDesc: "Suitable for depth of wall duct",
    label: "EFI01130: Suitable for depth of wall duct"
  },
  {
    featureId: "EFI01131",
    featureDesc: "Inner depth",
    label: "EFI01131: Inner depth"
  },
  {
    featureId: "EFI01205",
    featureDesc: "Tube diameter",
    label: "EFI01205: Tube diameter"
  },
  {
    featureId: "EFI01285",
    featureDesc: "Top end diameter",
    label: "EFI01285: Top end diameter"
  },
  {
    featureId: "EFI01305",
    featureDesc: "Width (big)",
    label: "EFI01305: Width (big)"
  },
  {
    featureId: "EFI01306",
    featureDesc: "Width (small)",
    label: "EFI01306: Width (small)"
  },
  {
    featureId: "EFI01310",
    featureDesc: "Max. operating temperature",
    label: "EFI01310: Max. operating temperature"
  },
  {
    featureId: "EFI01322",
    featureDesc: "Max. reach frontally",
    label: "EFI01322: Max. reach frontally"
  },
  {
    featureId: "EFI01323",
    featureDesc: "Max. reach sideways",
    label: "EFI01323: Max. reach sideways"
  },
  {
    featureId: "EFI01327",
    featureDesc: "Max. transmission range",
    label: "EFI01327: Max. transmission range"
  },
  {
    featureId: "EFI01335",
    featureDesc: "Nominal cross section",
    label: "EFI01335: Nominal cross section"
  },
  {
    featureId: "EFI01341",
    featureDesc: "Reduced cross section",
    label: "EFI01341: Reduced cross section"
  },
  {
    featureId: "EFI01347",
    featureDesc: "Compressive strength",
    label: "EFI01347: Compressive strength"
  },
  {
    featureId: "EFI01370",
    featureDesc: "Height of housing",
    label: "EFI01370: Height of housing"
  },
  {
    featureId: "EFI01423",
    featureDesc: "Diameter cap",
    label: "EFI01423: Diameter cap"
  },
  {
    featureId: "EFI01438",
    featureDesc: "Length",
    label: "EFI01438: Length"
  },
  {
    featureId: "EFI01456",
    featureDesc: "Height/depth",
    label: "EFI01456: Height/depth"
  },
  {
    featureId: "EFI01458",
    featureDesc: "Nominal size",
    label: "EFI01458: Nominal size"
  },
  {
    featureId: "EFI01464",
    featureDesc: "Soldering tip diameter",
    label: "EFI01464: Soldering tip diameter"
  },
  {
    featureId: "EFI01465",
    featureDesc: "Max. soldering tip temperature",
    label: "EFI01465: Max. soldering tip temperature"
  },
  {
    featureId: "EFI01539",
    featureDesc: "Programme diameter",
    label: "EFI01539: Programme diameter"
  },
  {
    featureId: "EFI01564",
    featureDesc: "Diameter/side length",
    label: "EFI01564: Diameter/side length"
  },
  {
    featureId: "EFI01605",
    featureDesc: "Volume flow",
    label: "EFI01605: Volume flow"
  },
  {
    featureId: "EFI01607",
    featureDesc: "Nominal width",
    label: "EFI01607: Nominal width"
  },
  {
    featureId: "EFI01621",
    featureDesc: "Diameter conductor",
    label: "EFI01621: Diameter conductor"
  },
  {
    featureId: "EFI01629",
    featureDesc: "Diameter inner conductor",
    label: "EFI01629: Diameter inner conductor"
  },
  {
    featureId: "EFI01630",
    featureDesc: "Diameter outer conductor",
    label: "EFI01630: Diameter outer conductor"
  },
  {
    featureId: "EFI01643",
    featureDesc: "Diameter impeller",
    label: "EFI01643: Diameter impeller"
  },
  {
    featureId: "EFI01648",
    featureDesc: "Connection diameter",
    label: "EFI01648: Connection diameter"
  },
  {
    featureId: "EFI01652",
    featureDesc: "Built-in size width",
    label: "EFI01652: Built-in size width"
  },
  {
    featureId: "EFI01653",
    featureDesc: "Built-in size height",
    label: "EFI01653: Built-in size height"
  },
  {
    featureId: "EFI01654",
    featureDesc: "Built-in size depth",
    label: "EFI01654: Built-in size depth"
  },
  {
    featureId: "EFI01666",
    featureDesc: "Cross section height",
    label: "EFI01666: Cross section height"
  },
  {
    featureId: "EFI01667",
    featureDesc: "Cross section width",
    label: "EFI01667: Cross section width"
  },
  {
    featureId: "EFI01672",
    featureDesc: "Inner diameter desoldering tip",
    label: "EFI01672: Inner diameter desoldering tip"
  },
  {
    featureId: "EFI01685",
    featureDesc: "Permitted cable outer temperature during assembling/handling",
    label: "EFI01685: Permitted cable outer temperature during assembling/handling"
  },
  {
    featureId: "EFI01686",
    featureDesc: "Permitted cable outer temperature after assembling without vibration",
    label: "EFI01686: Permitted cable outer temperature after assembling without vibration"
  },
  {
    featureId: "EFI01696",
    featureDesc: "Nominal cross section pilot/reduced conductors",
    label: "EFI01696: Nominal cross section pilot/reduced conductors"
  },
  {
    featureId: "EFI01707",
    featureDesc: "Max. supply temperature",
    label: "EFI01707: Max. supply temperature"
  },
  {
    featureId: "EFI01712",
    featureDesc: "Temperature protected till",
    label: "EFI01712: Temperature protected till"
  },
  {
    featureId: "EFI01731",
    featureDesc: "Temperature",
    label: "EFI01731: Temperature"
  },
  {
    featureId: "EFI01742",
    featureDesc: "Operating temperature",
    label: "EFI01742: Operating temperature"
  },
  {
    featureId: "EFI01744",
    featureDesc: "Bending radius, static",
    label: "EFI01744: Bending radius, static"
  },
  {
    featureId: "EFI01760",
    featureDesc: "Lowest inner diameter hose",
    label: "EFI01760: Lowest inner diameter hose"
  },
  {
    featureId: "EFI01761",
    featureDesc: "Total height",
    label: "EFI01761: Total height"
  },
  {
    featureId: "EFI01796",
    featureDesc: "Blade length",
    label: "EFI01796: Blade length"
  },
  {
    featureId: "EFI01798",
    featureDesc: "Cutting edge width",
    label: "EFI01798: Cutting edge width"
  },
  {
    featureId: "EFI01800",
    featureDesc: "Nominal cross section concentric conductor",
    label: "EFI01800: Nominal cross section concentric conductor"
  },
  {
    featureId: "EFI01803",
    featureDesc: "Line length",
    label: "EFI01803: Line length"
  },
  {
    featureId: "EFI01804",
    featureDesc: "Spatula width",
    label: "EFI01804: Spatula width"
  },
  {
    featureId: "EFI01809",
    featureDesc: "Accuracy",
    label: "EFI01809: Accuracy"
  },
  {
    featureId: "EFI01810",
    featureDesc: "Blade width",
    label: "EFI01810: Blade width"
  },
  {
    featureId: "EFI01811",
    featureDesc: "Blade thickness",
    label: "EFI01811: Blade thickness"
  },
  {
    featureId: "EFI01821",
    featureDesc: "Cutting depth",
    label: "EFI01821: Cutting depth"
  },
  {
    featureId: "EFI01822",
    featureDesc: "Saw-blade length",
    label: "EFI01822: Saw-blade length"
  },
  {
    featureId: "EFI01825",
    featureDesc: "Max. diameter cable drum",
    label: "EFI01825: Max. diameter cable drum"
  },
  {
    featureId: "EFI01826",
    featureDesc: "Max. width cable drum",
    label: "EFI01826: Max. width cable drum"
  },
  {
    featureId: "EFI01836",
    featureDesc: "Measuring range",
    label: "EFI01836: Measuring range"
  },
  {
    featureId: "EFI01839",
    featureDesc: "Centre drill diameter",
    label: "EFI01839: Centre drill diameter"
  },
  {
    featureId: "EFI01856",
    featureDesc: "Max. search depth",
    label: "EFI01856: Max. search depth"
  },
  {
    featureId: "EFI01894",
    featureDesc: "Max. cross section overhead line",
    label: "EFI01894: Max. cross section overhead line"
  },
  {
    featureId: "EFI01897",
    featureDesc: "Cutting edge length",
    label: "EFI01897: Cutting edge length"
  },
  {
    featureId: "EFI01901",
    featureDesc: "Max. cutting diameter",
    label: "EFI01901: Max. cutting diameter"
  },
  {
    featureId: "EFI01951",
    featureDesc: "Machine weight",
    label: "EFI01951: Machine weight"
  },
  {
    featureId: "EFI01961",
    featureDesc: "Diameter mast pipe",
    label: "EFI01961: Diameter mast pipe"
  },
  {
    featureId: "EFI01980",
    featureDesc: "Outer diameter cable ring",
    label: "EFI01980: Outer diameter cable ring"
  },
  {
    featureId: "EFI01981",
    featureDesc: "Inner diameter cable ring",
    label: "EFI01981: Inner diameter cable ring"
  },
  {
    featureId: "EFI01989",
    featureDesc: "Disc diameter",
    label: "EFI01989: Disc diameter"
  },
  {
    featureId: "EFI02032",
    featureDesc: "Screen diagonal",
    label: "EFI02032: Screen diagonal"
  },
  {
    featureId: "EFI02042",
    featureDesc: "Screen size",
    label: "EFI02042: Screen size"
  },
  {
    featureId: "EFI02057",
    featureDesc: "Height with worktop",
    label: "EFI02057: Height with worktop"
  },
  {
    featureId: "EFI02061",
    featureDesc: "Cavity capacity",
    label: "EFI02061: Cavity capacity"
  },
  {
    featureId: "EFI02134",
    featureDesc: "Cartridge volume",
    label: "EFI02134: Cartridge volume"
  },
  {
    featureId: "EFI02143",
    featureDesc: "Nominal content",
    label: "EFI02143: Nominal content"
  },
  {
    featureId: "EFI02160",
    featureDesc: "Temperature selection",
    label: "EFI02160: Temperature selection"
  },
  {
    featureId: "EFI02177",
    featureDesc: "Max. water temperature",
    label: "EFI02177: Max. water temperature"
  },
  {
    featureId: "EFI02183",
    featureDesc: "Primary volume flow",
    label: "EFI02183: Primary volume flow"
  },
  {
    featureId: "EFI02184",
    featureDesc: "Secondary volume flow",
    label: "EFI02184: Secondary volume flow"
  },
  {
    featureId: "EFI02194",
    featureDesc: "Temperature adjustment",
    label: "EFI02194: Temperature adjustment"
  },
  {
    featureId: "EFI02197",
    featureDesc: "Heating conductor load",
    label: "EFI02197: Heating conductor load"
  },
  {
    featureId: "EFI02201",
    featureDesc: "Length of cold conductors",
    label: "EFI02201: Length of cold conductors"
  },
  {
    featureId: "EFI02226",
    featureDesc: "Max. flow rate",
    label: "EFI02226: Max. flow rate"
  },
  {
    featureId: "EFI02228",
    featureDesc: "Total surface area",
    label: "EFI02228: Total surface area"
  },
  {
    featureId: "EFI02233",
    featureDesc: "Nominal flow rate",
    label: "EFI02233: Nominal flow rate"
  },
  {
    featureId: "EFI02240",
    featureDesc: "Air through-put",
    label: "EFI02240: Air through-put"
  },
  {
    featureId: "EFI02243",
    featureDesc: "Operating pressure",
    label: "EFI02243: Operating pressure"
  },
  {
    featureId: "EFI02250",
    featureDesc: "Discharge head circulating pump",
    label: "EFI02250: Discharge head circulating pump"
  },
  {
    featureId: "EFI02276",
    featureDesc: "Water content",
    label: "EFI02276: Water content"
  },
  {
    featureId: "EFI02300",
    featureDesc: "Adjustment range",
    label: "EFI02300: Adjustment range"
  },
  {
    featureId: "EFI02305",
    featureDesc: "Applicable height",
    label: "EFI02305: Applicable height"
  },
  {
    featureId: "EFI02306",
    featureDesc: "Thickness of thermal insulation",
    label: "EFI02306: Thickness of thermal insulation"
  },
  {
    featureId: "EFI02311",
    featureDesc: "Max. volume flow",
    label: "EFI02311: Max. volume flow"
  },
  {
    featureId: "EFI02312",
    featureDesc: "Frost-proof to",
    label: "EFI02312: Frost-proof to"
  },
  {
    featureId: "EFI02313",
    featureDesc: "Resistant to a continuous temperature to",
    label: "EFI02313: Resistant to a continuous temperature to"
  },
  {
    featureId: "EFI02348",
    featureDesc: "Suitable for diameter",
    label: "EFI02348: Suitable for diameter"
  },
  {
    featureId: "EFI02356",
    featureDesc: "Cable diameter",
    label: "EFI02356: Cable diameter"
  },
  {
    featureId: "EFI02367",
    featureDesc: "Length of arc",
    label: "EFI02367: Length of arc"
  },
  {
    featureId: "EFI02369",
    featureDesc: "Filament length",
    label: "EFI02369: Filament length"
  },
  {
    featureId: "EFI02371",
    featureDesc: "Reflector diameter",
    label: "EFI02371: Reflector diameter"
  },
  {
    featureId: "EFI02386",
    featureDesc: "Ambient temperature",
    label: "EFI02386: Ambient temperature"
  },
  {
    featureId: "EFI02390",
    featureDesc: "Suitable for pipe diameter",
    label: "EFI02390: Suitable for pipe diameter"
  },
  {
    featureId: "EFI02391",
    featureDesc: "Chucking range",
    label: "EFI02391: Chucking range"
  },
  {
    featureId: "EFI02392",
    featureDesc: "Clamping range",
    label: "EFI02392: Clamping range"
  },
  {
    featureId: "EFI02393",
    featureDesc: "Operating temperature",
    label: "EFI02393: Operating temperature"
  },
  {
    featureId: "EFI02395",
    featureDesc: "Cross section of take-off cable",
    label: "EFI02395: Cross section of take-off cable"
  },
  {
    featureId: "EFI02398",
    featureDesc: "Screen size diagonal",
    label: "EFI02398: Screen size diagonal"
  },
  {
    featureId: "EFI02403",
    featureDesc: "Tube length",
    label: "EFI02403: Tube length"
  },
  {
    featureId: "EFI02424",
    featureDesc: "Centre mounting, hole diameter",
    label: "EFI02424: Centre mounting, hole diameter"
  },
  {
    featureId: "EFI02456",
    featureDesc: "Vacuum volume",
    label: "EFI02456: Vacuum volume"
  },
  {
    featureId: "EFI02460",
    featureDesc: "Max. temperature",
    label: "EFI02460: Max. temperature"
  },
  {
    featureId: "EFI02463",
    featureDesc: "Solder quantity",
    label: "EFI02463: Solder quantity"
  },
  {
    featureId: "EFI02464",
    featureDesc: "Volume flow",
    label: "EFI02464: Volume flow"
  },
  {
    featureId: "EFI02470",
    featureDesc: "Housing length",
    label: "EFI02470: Housing length"
  },
  {
    featureId: "EFI02471",
    featureDesc: "Housing diameter",
    label: "EFI02471: Housing diameter"
  },
  {
    featureId: "EFI02472",
    featureDesc: "Diameter suction cup 1",
    label: "EFI02472: Diameter suction cup 1"
  },
  {
    featureId: "EFI02473",
    featureDesc: "Diameter suction cup 2",
    label: "EFI02473: Diameter suction cup 2"
  },
  {
    featureId: "EFI02474",
    featureDesc: "Diameter suction cup 3",
    label: "EFI02474: Diameter suction cup 3"
  },
  {
    featureId: "EFI02503",
    featureDesc: "Outer diameter desoldering tip",
    label: "EFI02503: Outer diameter desoldering tip"
  },
  {
    featureId: "EFI02504",
    featureDesc: "Max. outer diameter hose",
    label: "EFI02504: Max. outer diameter hose"
  },
  {
    featureId: "EFI02522",
    featureDesc: "Width/grid dimension",
    label: "EFI02522: Width/grid dimension"
  },
  {
    featureId: "EFI02556",
    featureDesc: "Thickness of mounting plate",
    label: "EFI02556: Thickness of mounting plate"
  },
  {
    featureId: "EFI02575",
    featureDesc: "Quantity",
    label: "EFI02575: Quantity"
  },
  {
    featureId: "EFI02607",
    featureDesc: "Max. air volume",
    label: "EFI02607: Max. air volume"
  },
  {
    featureId: "EFI02610",
    featureDesc: "Transport length",
    label: "EFI02610: Transport length"
  },
  {
    featureId: "EFI02613",
    featureDesc: "Mounting length",
    label: "EFI02613: Mounting length"
  },
  {
    featureId: "EFI02618",
    featureDesc: "Air volume",
    label: "EFI02618: Air volume"
  },
  {
    featureId: "EFI02638",
    featureDesc: "Busbar distance",
    label: "EFI02638: Busbar distance"
  },
  {
    featureId: "EFI02639",
    featureDesc: "Busbar height",
    label: "EFI02639: Busbar height"
  },
  {
    featureId: "EFI02640",
    featureDesc: "Busbar thickness",
    label: "EFI02640: Busbar thickness"
  },
  {
    featureId: "EFI02662",
    featureDesc: "Dimension vertical to mounting plate inner side",
    label: "EFI02662: Dimension vertical to mounting plate inner side"
  },
  {
    featureId: "EFI02663",
    featureDesc: "Dimension parallel to mounting plate inner side",
    label: "EFI02663: Dimension parallel to mounting plate inner side"
  },
  {
    featureId: "EFI02664",
    featureDesc: "Dimension vertical to mounting plate outer side",
    label: "EFI02664: Dimension vertical to mounting plate outer side"
  },
  {
    featureId: "EFI02665",
    featureDesc: "Dimension parallel to mounting plate outer side",
    label: "EFI02665: Dimension parallel to mounting plate outer side"
  },
  {
    featureId: "EFI02677",
    featureDesc: "Drill hole distance centrically",
    label: "EFI02677: Drill hole distance centrically"
  },
  {
    featureId: "EFI02680",
    featureDesc: "Niche depth",
    label: "EFI02680: Niche depth"
  },
  {
    featureId: "EFI02682",
    featureDesc: "Cable diameter main cable",
    label: "EFI02682: Cable diameter main cable"
  },
  {
    featureId: "EFI02683",
    featureDesc: "Cable diameter branch cable",
    label: "EFI02683: Cable diameter branch cable"
  },
  {
    featureId: "EFI02690",
    featureDesc: "Stroke motion extendable to",
    label: "EFI02690: Stroke motion extendable to"
  },
  {
    featureId: "EFI02705",
    featureDesc: "Standard stroke motion",
    label: "EFI02705: Standard stroke motion"
  },
  {
    featureId: "EFI02714",
    featureDesc: "Diameter of grooved shaft",
    label: "EFI02714: Diameter of grooved shaft"
  },
  {
    featureId: "EFI02716",
    featureDesc: "Max. length of roller shutter",
    label: "EFI02716: Max. length of roller shutter"
  },
  {
    featureId: "EFI02724",
    featureDesc: "Diameter of bell shell",
    label: "EFI02724: Diameter of bell shell"
  },
  {
    featureId: "EFI02732",
    featureDesc: "Max. width of door/gate wing",
    label: "EFI02732: Max. width of door/gate wing"
  },
  {
    featureId: "EFI02733",
    featureDesc: "Max. weight of door/gate leaf",
    label: "EFI02733: Max. weight of door/gate leaf"
  },
  {
    featureId: "EFI02745",
    featureDesc: "Max. door/gate weight",
    label: "EFI02745: Max. door/gate weight"
  },
  {
    featureId: "EFI02747",
    featureDesc: "Length of rack",
    label: "EFI02747: Length of rack"
  },
  {
    featureId: "EFI02764",
    featureDesc: "Diameter optics",
    label: "EFI02764: Diameter optics"
  },
  {
    featureId: "EFI02776",
    featureDesc: "Tip diameter/tip width",
    label: "EFI02776: Tip diameter/tip width"
  },
  {
    featureId: "EFI02777",
    featureDesc: "Outer diameter shaft",
    label: "EFI02777: Outer diameter shaft"
  },
  {
    featureId: "EFI02778",
    featureDesc: "Inner diameter shaft",
    label: "EFI02778: Inner diameter shaft"
  },
  {
    featureId: "EFI02782",
    featureDesc: "Tensile strength",
    label: "EFI02782: Tensile strength"
  },
  {
    featureId: "EFI02811",
    featureDesc: "Cage diameter",
    label: "EFI02811: Cage diameter"
  },
  {
    featureId: "EFI02812",
    featureDesc: "Cutting range",
    label: "EFI02812: Cutting range"
  },
  {
    featureId: "EFI02814",
    featureDesc: "Arbor",
    label: "EFI02814: Arbor"
  },
  {
    featureId: "EFI02821",
    featureDesc: "Lowest radius",
    label: "EFI02821: Lowest radius"
  },
  {
    featureId: "EFI02826",
    featureDesc: "Weight including battery-pack",
    label: "EFI02826: Weight including battery-pack"
  },
  {
    featureId: "EFI02829",
    featureDesc: "Drill diameter concrete with core drill",
    label: "EFI02829: Drill diameter concrete with core drill"
  },
  {
    featureId: "EFI02830",
    featureDesc: "Drill diameter in concrete",
    label: "EFI02830: Drill diameter in concrete"
  },
  {
    featureId: "EFI02832",
    featureDesc: "Drill chuck span",
    label: "EFI02832: Drill chuck span"
  },
  {
    featureId: "EFI02835",
    featureDesc: "Drill diameter steel 2nd gear",
    label: "EFI02835: Drill diameter steel 2nd gear"
  },
  {
    featureId: "EFI02836",
    featureDesc: "Drill diameter steel 1st gear",
    label: "EFI02836: Drill diameter steel 1st gear"
  },
  {
    featureId: "EFI02837",
    featureDesc: "Drill diameter wood 2nd gear",
    label: "EFI02837: Drill diameter wood 2nd gear"
  },
  {
    featureId: "EFI02838",
    featureDesc: "Drill diameter wood 1st gear",
    label: "EFI02838: Drill diameter wood 1st gear"
  },
  {
    featureId: "EFI02842",
    featureDesc: "Max. torque",
    label: "EFI02842: Max. torque"
  },
  {
    featureId: "EFI02847",
    featureDesc: "Diameter of oscillating circuit",
    label: "EFI02847: Diameter of oscillating circuit"
  },
  {
    featureId: "EFI02848",
    featureDesc: "Base-plate corner jig",
    label: "EFI02848: Base-plate corner jig"
  },
  {
    featureId: "EFI02849",
    featureDesc: "Cutting depth (90°)",
    label: "EFI02849: Cutting depth (90°)"
  },
  {
    featureId: "EFI02850",
    featureDesc: "Cutting depth (45°)",
    label: "EFI02850: Cutting depth (45°)"
  },
  {
    featureId: "EFI02852",
    featureDesc: "Cutting length",
    label: "EFI02852: Cutting length"
  },
  {
    featureId: "EFI02855",
    featureDesc: "Saw blade diameter",
    label: "EFI02855: Saw blade diameter"
  },
  {
    featureId: "EFI02856",
    featureDesc: "Saw blade bore hole",
    label: "EFI02856: Saw blade bore hole"
  },
  {
    featureId: "EFI02858",
    featureDesc: "Max. stick length",
    label: "EFI02858: Max. stick length"
  },
  {
    featureId: "EFI02859",
    featureDesc: "Max. stick diameter",
    label: "EFI02859: Max. stick diameter"
  },
  {
    featureId: "EFI02864",
    featureDesc: "Length of base sole",
    label: "EFI02864: Length of base sole"
  },
  {
    featureId: "EFI02865",
    featureDesc: "Planing depth",
    label: "EFI02865: Planing depth"
  },
  {
    featureId: "EFI02866",
    featureDesc: "Planing width",
    label: "EFI02866: Planing width"
  },
  {
    featureId: "EFI02872",
    featureDesc: "Nominal diameter vacuum hose",
    label: "EFI02872: Nominal diameter vacuum hose"
  },
  {
    featureId: "EFI02873",
    featureDesc: "Drill diameter concrete 2nd gear",
    label: "EFI02873: Drill diameter concrete 2nd gear"
  },
  {
    featureId: "EFI02874",
    featureDesc: "Drill diameter concrete 1st gear",
    label: "EFI02874: Drill diameter concrete 1st gear"
  },
  {
    featureId: "EFI02882",
    featureDesc: "Cutting depth in wood",
    label: "EFI02882: Cutting depth in wood"
  },
  {
    featureId: "EFI02886",
    featureDesc: "Angular dimension",
    label: "EFI02886: Angular dimension"
  },
  {
    featureId: "EFI02887",
    featureDesc: "Drill diameter in steel",
    label: "EFI02887: Drill diameter in steel"
  },
  {
    featureId: "EFI02888",
    featureDesc: "Drill diameter in wood",
    label: "EFI02888: Drill diameter in wood"
  },
  {
    featureId: "EFI02918",
    featureDesc: "Load bearing capacity",
    label: "EFI02918: Load bearing capacity"
  },
  {
    featureId: "EFI03290",
    featureDesc: "Slot length",
    label: "EFI03290: Slot length"
  },
  {
    featureId: "EFI03291",
    featureDesc: "Slot width",
    label: "EFI03291: Slot width"
  },
  {
    featureId: "EFI03299",
    featureDesc: "Max. character height, display",
    label: "EFI03299: Max. character height, display"
  },
  {
    featureId: "EFI03306",
    featureDesc: "Max. fall height according to IEC 60068-2-32",
    label: "EFI03306: Max. fall height according to IEC 60068-2-32"
  },
  {
    featureId: "EFI03460",
    featureDesc: "Conductor cross section flexible (fine-strand) with cable end sleeve",
    label: "EFI03460: Conductor cross section flexible (fine-strand) with cable end sleeve"
  },
  {
    featureId: "EFI03462",
    featureDesc: "Conductor cross section solid (solid, stranded)",
    label: "EFI03462: Conductor cross section solid (solid, stranded)"
  },
  {
    featureId: "EFI03520",
    featureDesc: "Cross section of the connecting conductor",
    label: "EFI03520: Cross section of the connecting conductor"
  },
  {
    featureId: "EFI03578",
    featureDesc: "Max. glue capacity",
    label: "EFI03578: Max. glue capacity"
  },
  {
    featureId: "EFI03617",
    featureDesc: "Grinding disc diameter",
    label: "EFI03617: Grinding disc diameter"
  },
  {
    featureId: "EFI03635",
    featureDesc: "Mandrel size",
    label: "EFI03635: Mandrel size"
  },
  {
    featureId: "EFI03636",
    featureDesc: "Connection cable length",
    label: "EFI03636: Connection cable length"
  },
  {
    featureId: "EFI03647",
    featureDesc: "Switching distance",
    label: "EFI03647: Switching distance"
  },
  {
    featureId: "EFI03651",
    featureDesc: "Width of the front",
    label: "EFI03651: Width of the front"
  },
  {
    featureId: "EFI03652",
    featureDesc: "Height of the front",
    label: "EFI03652: Height of the front"
  },
  {
    featureId: "EFI03662",
    featureDesc: "Diameter punching insert",
    label: "EFI03662: Diameter punching insert"
  },
  {
    featureId: "EFI03675",
    featureDesc: "Total length after shrink",
    label: "EFI03675: Total length after shrink"
  },
  {
    featureId: "EFI03676",
    featureDesc: "Inner diameter before heat-shrink",
    label: "EFI03676: Inner diameter before heat-shrink"
  },
  {
    featureId: "EFI03677",
    featureDesc: "Inner diameter after heat-shrink",
    label: "EFI03677: Inner diameter after heat-shrink"
  },
  {
    featureId: "EFI03678",
    featureDesc: "Max. wall thickness",
    label: "EFI03678: Max. wall thickness"
  },
  {
    featureId: "EFI03702",
    featureDesc: "Max. busbar thickness",
    label: "EFI03702: Max. busbar thickness"
  },
  {
    featureId: "EFI03703",
    featureDesc: "Width of the adapter",
    label: "EFI03703: Width of the adapter"
  },
  {
    featureId: "EFI03704",
    featureDesc: "Rail width",
    label: "EFI03704: Rail width"
  },
  {
    featureId: "EFI03712",
    featureDesc: "Pitch thread",
    label: "EFI03712: Pitch thread"
  },
  {
    featureId: "EFI03721",
    featureDesc: "Pitch inner thread",
    label: "EFI03721: Pitch inner thread"
  },
  {
    featureId: "EFI03722",
    featureDesc: "Pitch outer thread",
    label: "EFI03722: Pitch outer thread"
  },
  {
    featureId: "EFI03788",
    featureDesc: "Temperature resistance",
    label: "EFI03788: Temperature resistance"
  },
  {
    featureId: "EFI03796",
    featureDesc: "Cable width approx.",
    label: "EFI03796: Cable width approx."
  },
  {
    featureId: "EFI03797",
    featureDesc: "Cable height approx.",
    label: "EFI03797: Cable height approx."
  },
  {
    featureId: "EFI03803",
    featureDesc: "Max. conductor temperature",
    label: "EFI03803: Max. conductor temperature"
  },
  {
    featureId: "EFI03838",
    featureDesc: "Hole diameter built-in",
    label: "EFI03838: Hole diameter built-in"
  },
  {
    featureId: "EFI03839",
    featureDesc: "Built-in wall thickness",
    label: "EFI03839: Built-in wall thickness"
  },
  {
    featureId: "EFI03843",
    featureDesc: "Diameter reach on floor",
    label: "EFI03843: Diameter reach on floor"
  },
  {
    featureId: "EFI03874",
    featureDesc: "Mounting height",
    label: "EFI03874: Mounting height"
  },
  {
    featureId: "EFI03900",
    featureDesc: "Temperature value adjustable",
    label: "EFI03900: Temperature value adjustable"
  },
  {
    featureId: "EFI03921",
    featureDesc: "Outer diameter approx.",
    label: "EFI03921: Outer diameter approx."
  },
  {
    featureId: "EFI03929",
    featureDesc: "Cross section",
    label: "EFI03929: Cross section"
  },
  {
    featureId: "EFI03934",
    featureDesc: "Nominal size",
    label: "EFI03934: Nominal size"
  },
  {
    featureId: "EFI03986",
    featureDesc: "Blind zone",
    label: "EFI03986: Blind zone"
  },
  {
    featureId: "EFI03991",
    featureDesc: "Detection capability for test bodies",
    label: "EFI03991: Detection capability for test bodies"
  },
  {
    featureId: "EFI04010",
    featureDesc: "Geometrical resolution",
    label: "EFI04010: Geometrical resolution"
  },
  {
    featureId: "EFI04011",
    featureDesc: "Protected switch distance OFF",
    label: "EFI04011: Protected switch distance OFF"
  },
  {
    featureId: "EFI04012",
    featureDesc: "Protected switch distance ON",
    label: "EFI04012: Protected switch distance ON"
  },
  {
    featureId: "EFI04030",
    featureDesc: "Light dot",
    label: "EFI04030: Light dot"
  },
  {
    featureId: "EFI04044",
    featureDesc: "Relative measurement accuracy",
    label: "EFI04044: Relative measurement accuracy"
  },
  {
    featureId: "EFI04046",
    featureDesc: "Min. reflector distance",
    label: "EFI04046: Min. reflector distance"
  },
  {
    featureId: "EFI04059",
    featureDesc: "Nominal operating distance",
    label: "EFI04059: Nominal operating distance"
  },
  {
    featureId: "EFI04097",
    featureDesc: "Transmission range of the measuring range",
    label: "EFI04097: Transmission range of the measuring range"
  },
  {
    featureId: "EFI04098",
    featureDesc: "Transmission range of the safety field",
    label: "EFI04098: Transmission range of the safety field"
  },
  {
    featureId: "EFI04099",
    featureDesc: "Transmission range of the alert field",
    label: "EFI04099: Transmission range of the alert field"
  },
  {
    featureId: "EFI04103",
    featureDesc: "Safety field height",
    label: "EFI04103: Safety field height"
  },
  {
    featureId: "EFI04109",
    featureDesc: "Cam distance",
    label: "EFI04109: Cam distance"
  },
  {
    featureId: "EFI04113",
    featureDesc: "Beam spacing",
    label: "EFI04113: Beam spacing"
  },
  {
    featureId: "EFI04121",
    featureDesc: "Operating distance",
    label: "EFI04121: Operating distance"
  },
  {
    featureId: "EFI04155",
    featureDesc: "Max. outer diameter",
    label: "EFI04155: Max. outer diameter"
  },
  {
    featureId: "EFI04166",
    featureDesc: "Connecting shaft",
    label: "EFI04166: Connecting shaft"
  },
  {
    featureId: "EFI04167",
    featureDesc: "Terminal lug length",
    label: "EFI04167: Terminal lug length"
  },
  {
    featureId: "EFI04168",
    featureDesc: "Connecting hole",
    label: "EFI04168: Connecting hole"
  },
  {
    featureId: "EFI04170",
    featureDesc: "Connection cross section",
    label: "EFI04170: Connection cross section"
  },
  {
    featureId: "EFI04186",
    featureDesc: "Mounting hole",
    label: "EFI04186: Mounting hole"
  },
  {
    featureId: "EFI04187",
    featureDesc: "Diameter connection plate",
    label: "EFI04187: Diameter connection plate"
  },
  {
    featureId: "EFI04188",
    featureDesc: "Diameter fibres",
    label: "EFI04188: Diameter fibres"
  },
  {
    featureId: "EFI04195",
    featureDesc: "Suitable for diameter round conductor",
    label: "EFI04195: Suitable for diameter round conductor"
  },
  {
    featureId: "EFI04201",
    featureDesc: "Clamping/chucking range",
    label: "EFI04201: Clamping/chucking range"
  },
  {
    featureId: "EFI04204",
    featureDesc: "Clamping range round conductor",
    label: "EFI04204: Clamping range round conductor"
  },
  {
    featureId: "EFI04208",
    featureDesc: "Length carrier",
    label: "EFI04208: Length carrier"
  },
  {
    featureId: "EFI04211",
    featureDesc: "Conductor diameter",
    label: "EFI04211: Conductor diameter"
  },
  {
    featureId: "EFI04232",
    featureDesc: "Pipe chucking range",
    label: "EFI04232: Pipe chucking range"
  },
  {
    featureId: "EFI04248",
    featureDesc: "Rod diameter",
    label: "EFI04248: Rod diameter"
  },
  {
    featureId: "EFI04265",
    featureDesc: "Built-in length",
    label: "EFI04265: Built-in length"
  },
  {
    featureId: "EFI04286",
    featureDesc: "Pendant length",
    label: "EFI04286: Pendant length"
  },
  {
    featureId: "EFI04287",
    featureDesc: "Cross section wiring cores",
    label: "EFI04287: Cross section wiring cores"
  },
  {
    featureId: "EFI04292",
    featureDesc: "Ring diameter",
    label: "EFI04292: Ring diameter"
  },
  {
    featureId: "EFI04328",
    featureDesc: "Max. length",
    label: "EFI04328: Max. length"
  },
  {
    featureId: "EFI04329",
    featureDesc: "Max. surface temperature",
    label: "EFI04329: Max. surface temperature"
  },
  {
    featureId: "EFI04331",
    featureDesc: "Connection cross section",
    label: "EFI04331: Connection cross section"
  },
  {
    featureId: "EFI04346",
    featureDesc: "Distance",
    label: "EFI04346: Distance"
  },
  {
    featureId: "EFI04364",
    featureDesc: "Sealing profile height",
    label: "EFI04364: Sealing profile height"
  },
  {
    featureId: "EFI04484",
    featureDesc: "Levelling height",
    label: "EFI04484: Levelling height"
  },
  {
    featureId: "EFI04486",
    featureDesc: "Outlet width",
    label: "EFI04486: Outlet width"
  },
  {
    featureId: "EFI04487",
    featureDesc: "Outlet diameter",
    label: "EFI04487: Outlet diameter"
  },
  {
    featureId: "EFI04488",
    featureDesc: "Outlet length",
    label: "EFI04488: Outlet length"
  },
  {
    featureId: "EFI04499",
    featureDesc: "Tie thickness",
    label: "EFI04499: Tie thickness"
  },
  {
    featureId: "EFI04500",
    featureDesc: "Belt speed",
    label: "EFI04500: Belt speed"
  },
  {
    featureId: "EFI04504",
    featureDesc: "Assembly height under carpeting",
    label: "EFI04504: Assembly height under carpeting"
  },
  {
    featureId: "EFI04507",
    featureDesc: "Mounting to max. wall thickness",
    label: "EFI04507: Mounting to max. wall thickness"
  },
  {
    featureId: "EFI04549",
    featureDesc: "Drill diameter soft wood",
    label: "EFI04549: Drill diameter soft wood"
  },
  {
    featureId: "EFI04552",
    featureDesc: "Drill diameter concrete 3rd gear",
    label: "EFI04552: Drill diameter concrete 3rd gear"
  },
  {
    featureId: "EFI04553",
    featureDesc: "Drill diameter concrete with hammer drill",
    label: "EFI04553: Drill diameter concrete with hammer drill"
  },
  {
    featureId: "EFI04554",
    featureDesc: "Drill diameter wood 3rd gear",
    label: "EFI04554: Drill diameter wood 3rd gear"
  },
  {
    featureId: "EFI04555",
    featureDesc: "Drill diameter wood 4th gear",
    label: "EFI04555: Drill diameter wood 4th gear"
  },
  {
    featureId: "EFI04556",
    featureDesc: "Drill diameter brickwork with core drill",
    label: "EFI04556: Drill diameter brickwork with core drill"
  },
  {
    featureId: "EFI04557",
    featureDesc: "Drill diameter steel 3rd gear",
    label: "EFI04557: Drill diameter steel 3rd gear"
  },
  {
    featureId: "EFI04558",
    featureDesc: "Drill diameter steel 4th gear",
    label: "EFI04558: Drill diameter steel 4th gear"
  },
  {
    featureId: "EFI04559",
    featureDesc: "Drill diameter stone 1st gear",
    label: "EFI04559: Drill diameter stone 1st gear"
  },
  {
    featureId: "EFI04560",
    featureDesc: "Drill diameter stone 2nd gear",
    label: "EFI04560: Drill diameter stone 2nd gear"
  },
  {
    featureId: "EFI04561",
    featureDesc: "Drill diameter stone 3rd gear",
    label: "EFI04561: Drill diameter stone 3rd gear"
  },
  {
    featureId: "EFI04562",
    featureDesc: "Drill hole diameter",
    label: "EFI04562: Drill hole diameter"
  },
  {
    featureId: "EFI04563",
    featureDesc: "Drill hole depth",
    label: "EFI04563: Drill hole depth"
  },
  {
    featureId: "EFI04564",
    featureDesc: "Width outside dimension",
    label: "EFI04564: Width outside dimension"
  },
  {
    featureId: "EFI04565",
    featureDesc: "Width opening",
    label: "EFI04565: Width opening"
  },
  {
    featureId: "EFI04566",
    featureDesc: "Width built-in dimension",
    label: "EFI04566: Width built-in dimension"
  },
  {
    featureId: "EFI04567",
    featureDesc: "Width/diameter",
    label: "EFI04567: Width/diameter"
  },
  {
    featureId: "EFI04569",
    featureDesc: "Focal size",
    label: "EFI04569: Focal size"
  },
  {
    featureId: "EFI04571",
    featureDesc: "Breaking load",
    label: "EFI04571: Breaking load"
  },
  {
    featureId: "EFI04572",
    featureDesc: "Gross capacity",
    label: "EFI04572: Gross capacity"
  },
  {
    featureId: "EFI04573",
    featureDesc: "Gross capacity total",
    label: "EFI04573: Gross capacity total"
  },
  {
    featureId: "EFI04593",
    featureDesc: "Sealing range",
    label: "EFI04593: Sealing range"
  },
  {
    featureId: "EFI04601",
    featureDesc: "Display diagonal",
    label: "EFI04601: Display diagonal"
  },
  {
    featureId: "EFI04603",
    featureDesc: "Wire diameter",
    label: "EFI04603: Wire diameter"
  },
  {
    featureId: "EFI04605",
    featureDesc: "Torque setting",
    label: "EFI04605: Torque setting"
  },
  {
    featureId: "EFI04617",
    featureDesc: "Diameter opening",
    label: "EFI04617: Diameter opening"
  },
  {
    featureId: "EFI04618",
    featureDesc: "Diameter wing nozzle",
    label: "EFI04618: Diameter wing nozzle"
  },
  {
    featureId: "EFI04619",
    featureDesc: "Diameter rotating tray",
    label: "EFI04619: Diameter rotating tray"
  },
  {
    featureId: "EFI04620",
    featureDesc: "Diameter",
    label: "EFI04620: Diameter"
  },
  {
    featureId: "EFI04651",
    featureDesc: "Adjustment range set-back",
    label: "EFI04651: Adjustment range set-back"
  },
  {
    featureId: "EFI04652",
    featureDesc: "Adjustment range frost protection",
    label: "EFI04652: Adjustment range frost protection"
  },
  {
    featureId: "EFI04653",
    featureDesc: "Adjustment range comfort",
    label: "EFI04653: Adjustment range comfort"
  },
  {
    featureId: "EFI04689",
    featureDesc: "Front plate width",
    label: "EFI04689: Front plate width"
  },
  {
    featureId: "EFI04690",
    featureDesc: "Front plate height",
    label: "EFI04690: Front plate height"
  },
  {
    featureId: "EFI04691",
    featureDesc: "Diameter panel opening",
    label: "EFI04691: Diameter panel opening"
  },
  {
    featureId: "EFI04738",
    featureDesc: "Freezing capacity",
    label: "EFI04738: Freezing capacity"
  },
  {
    featureId: "EFI04748",
    featureDesc: "Device stroke",
    label: "EFI04748: Device stroke"
  },
  {
    featureId: "EFI04752",
    featureDesc: "Total volume",
    label: "EFI04752: Total volume"
  },
  {
    featureId: "EFI04757",
    featureDesc: "Thread diameter",
    label: "EFI04757: Thread diameter"
  },
  {
    featureId: "EFI04764",
    featureDesc: "Width mesh cable tray",
    label: "EFI04764: Width mesh cable tray"
  },
  {
    featureId: "EFI04765",
    featureDesc: "Wire mesh tray height",
    label: "EFI04765: Wire mesh tray height"
  },
  {
    featureId: "EFI04778",
    featureDesc: "Height opening",
    label: "EFI04778: Height opening"
  },
  {
    featureId: "EFI04779",
    featureDesc: "Height without worktop",
    label: "EFI04779: Height without worktop"
  },
  {
    featureId: "EFI04781",
    featureDesc: "Suitable for side wall height",
    label: "EFI04781: Suitable for side wall height"
  },
  {
    featureId: "EFI04783",
    featureDesc: "Max. opening",
    label: "EFI04783: Max. opening"
  },
  {
    featureId: "EFI04793",
    featureDesc: "Inner radius",
    label: "EFI04793: Inner radius"
  },
  {
    featureId: "EFI04810",
    featureDesc: "Insulation thickness",
    label: "EFI04810: Insulation thickness"
  },
  {
    featureId: "EFI04811",
    featureDesc: "Insulation thickness",
    label: "EFI04811: Insulation thickness"
  },
  {
    featureId: "EFI04816",
    featureDesc: "Cable ladder branch width",
    label: "EFI04816: Cable ladder branch width"
  },
  {
    featureId: "EFI04817",
    featureDesc: "Cable ladder width",
    label: "EFI04817: Cable ladder width"
  },
  {
    featureId: "EFI04818",
    featureDesc: "Cable ladder height",
    label: "EFI04818: Cable ladder height"
  },
  {
    featureId: "EFI04819",
    featureDesc: "Cable tray branch width",
    label: "EFI04819: Cable tray branch width"
  },
  {
    featureId: "EFI04820",
    featureDesc: "Cable tray width",
    label: "EFI04820: Cable tray width"
  },
  {
    featureId: "EFI04822",
    featureDesc: "Cable tray height",
    label: "EFI04822: Cable tray height"
  },
  {
    featureId: "EFI04828",
    featureDesc: "Cassette height",
    label: "EFI04828: Cassette height"
  },
  {
    featureId: "EFI04834",
    featureDesc: "Staple length at 10 mm width",
    label: "EFI04834: Staple length at 10 mm width"
  },
  {
    featureId: "EFI04835",
    featureDesc: "Staple length at 4 mm width",
    label: "EFI04835: Staple length at 4 mm width"
  },
  {
    featureId: "EFI04836",
    featureDesc: "Staple length (flat staple) at 10 mm width",
    label: "EFI04836: Staple length (flat staple) at 10 mm width"
  },
  {
    featureId: "EFI04854",
    featureDesc: "Granulation",
    label: "EFI04854: Granulation"
  },
  {
    featureId: "EFI04874",
    featureDesc: "Length connection cord",
    label: "EFI04874: Length connection cord"
  },
  {
    featureId: "EFI04875",
    featureDesc: "Length built-in dimension",
    label: "EFI04875: Length built-in dimension"
  },
  {
    featureId: "EFI04909",
    featureDesc: "Air volume per suction motor",
    label: "EFI04909: Air volume per suction motor"
  },
  {
    featureId: "EFI04910",
    featureDesc: "Air circulation",
    label: "EFI04910: Air circulation"
  },
  {
    featureId: "EFI04936",
    featureDesc: "Max. drill diameter",
    label: "EFI04936: Max. drill diameter"
  },
  {
    featureId: "EFI04937",
    featureDesc: "Max. diameter core drill wet in concrete with suction",
    label: "EFI04937: Max. diameter core drill wet in concrete with suction"
  },
  {
    featureId: "EFI04938",
    featureDesc: "Max. diameter core drill wet in concrete without suction",
    label: "EFI04938: Max. diameter core drill wet in concrete without suction"
  },
  {
    featureId: "EFI04939",
    featureDesc: "Max. diameter core drill dry in concrete",
    label: "EFI04939: Max. diameter core drill dry in concrete"
  },
  {
    featureId: "EFI04940",
    featureDesc: "Max. diameter core drill dry in brickwork",
    label: "EFI04940: Max. diameter core drill dry in brickwork"
  },
  {
    featureId: "EFI04946",
    featureDesc: "Max. rebate depth",
    label: "EFI04946: Max. rebate depth"
  },
  {
    featureId: "EFI04948",
    featureDesc: "Max. air volume air extraction (according to EN 61591)",
    label: "EFI04948: Max. air volume air extraction (according to EN 61591)"
  },
  {
    featureId: "EFI04949",
    featureDesc: "Max. blower output recirculation function",
    label: "EFI04949: Max. blower output recirculation function"
  },
  {
    featureId: "EFI04954",
    featureDesc: "Max. stroke",
    label: "EFI04954: Max. stroke"
  },
  {
    featureId: "EFI04969",
    featureDesc: "Max. conductor cross section",
    label: "EFI04969: Max. conductor cross section"
  },
  {
    featureId: "EFI04970",
    featureDesc: "Max. conductor length light sensor",
    label: "EFI04970: Max. conductor length light sensor"
  },
  {
    featureId: "EFI04974",
    featureDesc: "Max. material thickness in aluminium 250 N/mm²",
    label: "EFI04974: Max. material thickness in aluminium 250 N/mm²"
  },
  {
    featureId: "EFI04975",
    featureDesc: "Max. material thickness in foam",
    label: "EFI04975: Max. material thickness in foam"
  },
  {
    featureId: "EFI04976",
    featureDesc: "Max. material thickness in steel 400 N/mm²",
    label: "EFI04976: Max. material thickness in steel 400 N/mm²"
  },
  {
    featureId: "EFI04977",
    featureDesc: "Max. material thickness in steel 600 N/mm²",
    label: "EFI04977: Max. material thickness in steel 600 N/mm²"
  },
  {
    featureId: "EFI04978",
    featureDesc: "Max. material thickness in steel 800 N/mm²",
    label: "EFI04978: Max. material thickness in steel 800 N/mm²"
  },
  {
    featureId: "EFI04979",
    featureDesc: "Max. plate thickness",
    label: "EFI04979: Max. plate thickness"
  },
  {
    featureId: "EFI04983",
    featureDesc: "Max. disc diameter",
    label: "EFI04983: Max. disc diameter"
  },
  {
    featureId: "EFI04987",
    featureDesc: "Max. screw diameter",
    label: "EFI04987: Max. screw diameter"
  },
  {
    featureId: "EFI04998",
    featureDesc: "Max. effective range",
    label: "EFI04998: Max. effective range"
  },
  {
    featureId: "EFI05018",
    featureDesc: "Min. drill diameter",
    label: "EFI05018: Min. drill diameter"
  },
  {
    featureId: "EFI05022",
    featureDesc: "Min. hollow depth",
    label: "EFI05022: Min. hollow depth"
  },
  {
    featureId: "EFI05027",
    featureDesc: "Min. built-in depth",
    label: "EFI05027: Min. built-in depth"
  },
  {
    featureId: "EFI05028",
    featureDesc: "Min. height",
    label: "EFI05028: Min. height"
  },
  {
    featureId: "EFI05089",
    featureDesc: "Mounting surface",
    label: "EFI05089: Mounting surface"
  },
  {
    featureId: "EFI05107",
    featureDesc: "Nail diameter",
    label: "EFI05107: Nail diameter"
  },
  {
    featureId: "EFI05108",
    featureDesc: "Nail length",
    label: "EFI05108: Nail length"
  },
  {
    featureId: "EFI05114",
    featureDesc: "Nominal diameter",
    label: "EFI05114: Nominal diameter"
  },
  {
    featureId: "EFI05124",
    featureDesc: "Nominal cross section",
    label: "EFI05124: Nominal cross section"
  },
  {
    featureId: "EFI05129",
    featureDesc: "Net capacity total unit",
    label: "EFI05129: Net capacity total unit"
  },
  {
    featureId: "EFI05136",
    featureDesc: "Levelling range",
    label: "EFI05136: Levelling range"
  },
  {
    featureId: "EFI05139",
    featureDesc: "Groove width",
    label: "EFI05139: Groove width"
  },
  {
    featureId: "EFI05140",
    featureDesc: "Useful cross section",
    label: "EFI05140: Useful cross section"
  },
  {
    featureId: "EFI05146",
    featureDesc: "Cover width",
    label: "EFI05146: Cover width"
  },
  {
    featureId: "EFI05147",
    featureDesc: "Cover width 1",
    label: "EFI05147: Cover width 1"
  },
  {
    featureId: "EFI05148",
    featureDesc: "Cover width 2",
    label: "EFI05148: Cover width 2"
  },
  {
    featureId: "EFI05149",
    featureDesc: "Cover width 3",
    label: "EFI05149: Cover width 3"
  },
  {
    featureId: "EFI05153",
    featureDesc: "Opening diameter",
    label: "EFI05153: Opening diameter"
  },
  {
    featureId: "EFI05164",
    featureDesc: "Suitable for mesh cable tray filament",
    label: "EFI05164: Suitable for mesh cable tray filament"
  },
  {
    featureId: "EFI05172",
    featureDesc: "Perforation diameter",
    label: "EFI05172: Perforation diameter"
  },
  {
    featureId: "EFI05173",
    featureDesc: "Perforation width",
    label: "EFI05173: Perforation width"
  },
  {
    featureId: "EFI05174",
    featureDesc: "Perforation length",
    label: "EFI05174: Perforation length"
  },
  {
    featureId: "EFI05175",
    featureDesc: "Plate width",
    label: "EFI05175: Plate width"
  },
  {
    featureId: "EFI05176",
    featureDesc: "Plate height",
    label: "EFI05176: Plate height"
  },
  {
    featureId: "EFI05193",
    featureDesc: "Cross section connection cable",
    label: "EFI05193: Cross section connection cable"
  },
  {
    featureId: "EFI05214",
    featureDesc: "Tube/cable diameter",
    label: "EFI05214: Tube/cable diameter"
  },
  {
    featureId: "EFI05215",
    featureDesc: "Tube diameter",
    label: "EFI05215: Tube diameter"
  },
  {
    featureId: "EFI05222",
    featureDesc: "Column height",
    label: "EFI05222: Column height"
  },
  {
    featureId: "EFI05244",
    featureDesc: "Base-plate width",
    label: "EFI05244: Base-plate width"
  },
  {
    featureId: "EFI05245",
    featureDesc: "Base-plate length",
    label: "EFI05245: Base-plate length"
  },
  {
    featureId: "EFI05246",
    featureDesc: "Distance of slots",
    label: "EFI05246: Distance of slots"
  },
  {
    featureId: "EFI05247",
    featureDesc: "Slit width",
    label: "EFI05247: Slit width"
  },
  {
    featureId: "EFI05248",
    featureDesc: "Cutting speed",
    label: "EFI05248: Cutting speed"
  },
  {
    featureId: "EFI05249",
    featureDesc: "Cutting trail width",
    label: "EFI05249: Cutting trail width"
  },
  {
    featureId: "EFI05250",
    featureDesc: "Cutting range at 45°/90°",
    label: "EFI05250: Cutting range at 45°/90°"
  },
  {
    featureId: "EFI05251",
    featureDesc: "Cutting range at 90°/45°",
    label: "EFI05251: Cutting range at 90°/45°"
  },
  {
    featureId: "EFI05252",
    featureDesc: "Cutting range at 90°/90°",
    label: "EFI05252: Cutting range at 90°/90°"
  },
  {
    featureId: "EFI05255",
    featureDesc: "Cutting depth adjustable",
    label: "EFI05255: Cutting depth adjustable"
  },
  {
    featureId: "EFI05257",
    featureDesc: "Cutting depth in brick work",
    label: "EFI05257: Cutting depth in brick work"
  },
  {
    featureId: "EFI05258",
    featureDesc: "Cutting depth in sheet-metal (400 N/mm²)",
    label: "EFI05258: Cutting depth in sheet-metal (400 N/mm²)"
  },
  {
    featureId: "EFI05259",
    featureDesc: "Cutting depth",
    label: "EFI05259: Cutting depth"
  },
  {
    featureId: "EFI05261",
    featureDesc: "Suitable for screw diameter",
    label: "EFI05261: Suitable for screw diameter"
  },
  {
    featureId: "EFI05262",
    featureDesc: "Screw diameter",
    label: "EFI05262: Screw diameter"
  },
  {
    featureId: "EFI05268",
    featureDesc: "Hexagon angular dimension",
    label: "EFI05268: Hexagon angular dimension"
  },
  {
    featureId: "EFI05288",
    featureDesc: "Clamping neck diameter",
    label: "EFI05288: Clamping neck diameter"
  },
  {
    featureId: "EFI05289",
    featureDesc: "Clamping cable diameter",
    label: "EFI05289: Clamping cable diameter"
  },
  {
    featureId: "EFI05306",
    featureDesc: "Start hole diameter",
    label: "EFI05306: Start hole diameter"
  },
  {
    featureId: "EFI05356",
    featureDesc: "Depth of flooring recess",
    label: "EFI05356: Depth of flooring recess"
  },
  {
    featureId: "EFI05361",
    featureDesc: "Partition width",
    label: "EFI05361: Partition width"
  },
  {
    featureId: "EFI05362",
    featureDesc: "Partition height",
    label: "EFI05362: Partition height"
  },
  {
    featureId: "EFI05366",
    featureDesc: "Drum volume",
    label: "EFI05366: Drum volume"
  },
  {
    featureId: "EFI05370",
    featureDesc: "Monitoring radius",
    label: "EFI05370: Monitoring radius"
  },
  {
    featureId: "EFI05445",
    featureDesc: "Size of figures",
    label: "EFI05445: Size of figures"
  },
  {
    featureId: "EFI05452",
    featureDesc: "Width",
    label: "EFI05452: Width"
  },
  {
    featureId: "EFI05454",
    featureDesc: "Height",
    label: "EFI05454: Height"
  },
  {
    featureId: "EFI05457",
    featureDesc: "Depth",
    label: "EFI05457: Depth"
  },
  {
    featureId: "EFI05459",
    featureDesc: "Duct height",
    label: "EFI05459: Duct height"
  },
  {
    featureId: "EFI05464",
    featureDesc: "Built-in depth",
    label: "EFI05464: Built-in depth"
  },
  {
    featureId: "EFI05466",
    featureDesc: "Suitable for cable diameter",
    label: "EFI05466: Suitable for cable diameter"
  },
  {
    featureId: "EFI05476",
    featureDesc: "Dimension",
    label: "EFI05476: Dimension"
  },
  {
    featureId: "EFI05483",
    featureDesc: "Length",
    label: "EFI05483: Length"
  },
  {
    featureId: "EFI05492",
    featureDesc: "Wall distance",
    label: "EFI05492: Wall distance"
  },
  {
    featureId: "EFI05495",
    featureDesc: "Suitable for pipe diameter",
    label: "EFI05495: Suitable for pipe diameter"
  },
  {
    featureId: "EFI05497",
    featureDesc: "Core diameter",
    label: "EFI05497: Core diameter"
  },
  {
    featureId: "EFI05520",
    featureDesc: "Tightening moment",
    label: "EFI05520: Tightening moment"
  },
  {
    featureId: "EFI05631",
    featureDesc: "Length long leg",
    label: "EFI05631: Length long leg"
  },
  {
    featureId: "EFI05637",
    featureDesc: "Jaw width",
    label: "EFI05637: Jaw width"
  },
  {
    featureId: "EFI05640",
    featureDesc: "Shackle width",
    label: "EFI05640: Shackle width"
  },
  {
    featureId: "EFI05648",
    featureDesc: "Blade width",
    label: "EFI05648: Blade width"
  },
  {
    featureId: "EFI05650",
    featureDesc: "Blade length",
    label: "EFI05650: Blade length"
  },
  {
    featureId: "EFI05654",
    featureDesc: "Bolt length",
    label: "EFI05654: Bolt length"
  },
  {
    featureId: "EFI05655",
    featureDesc: "Width branch/opening",
    label: "EFI05655: Width branch/opening"
  },
  {
    featureId: "EFI05658",
    featureDesc: "Width meter scale",
    label: "EFI05658: Width meter scale"
  },
  {
    featureId: "EFI05663",
    featureDesc: "Outer diameter of ring",
    label: "EFI05663: Outer diameter of ring"
  },
  {
    featureId: "EFI05678",
    featureDesc: "Diameter drain",
    label: "EFI05678: Diameter drain"
  },
  {
    featureId: "EFI05681",
    featureDesc: "Shackle diameter",
    label: "EFI05681: Shackle diameter"
  },
  {
    featureId: "EFI05684",
    featureDesc: "Diameter tip",
    label: "EFI05684: Diameter tip"
  },
  {
    featureId: "EFI05685",
    featureDesc: "Diameter grinding stone",
    label: "EFI05685: Diameter grinding stone"
  },
  {
    featureId: "EFI05686",
    featureDesc: "Depth branch/opening",
    label: "EFI05686: Depth branch/opening"
  },
  {
    featureId: "EFI05742",
    featureDesc: "Hole width",
    label: "EFI05742: Hole width"
  },
  {
    featureId: "EFI05743",
    featureDesc: "Hole height",
    label: "EFI05743: Hole height"
  },
  {
    featureId: "EFI05872",
    featureDesc: "Tray width in horizontal direction",
    label: "EFI05872: Tray width in horizontal direction"
  },
  {
    featureId: "EFI05873",
    featureDesc: "Tray width in vertical direction",
    label: "EFI05873: Tray width in vertical direction"
  },
  {
    featureId: "EFI05874",
    featureDesc: "Gutter depth",
    label: "EFI05874: Gutter depth"
  },
  {
    featureId: "EFI05887",
    featureDesc: "Dual spacing screw thread",
    label: "EFI05887: Dual spacing screw thread"
  },
  {
    featureId: "EFI05888",
    featureDesc: "Lift height",
    label: "EFI05888: Lift height"
  },
  {
    featureId: "EFI05889",
    featureDesc: "Hoisting capacity",
    label: "EFI05889: Hoisting capacity"
  },
  {
    featureId: "EFI05894",
    featureDesc: "Height including pole",
    label: "EFI05894: Height including pole"
  },
  {
    featureId: "EFI05896",
    featureDesc: "Height meter scale",
    label: "EFI05896: Height meter scale"
  },
  {
    featureId: "EFI05930",
    featureDesc: "Lock width",
    label: "EFI05930: Lock width"
  },
  {
    featureId: "EFI05935",
    featureDesc: "Clamping width",
    label: "EFI05935: Clamping width"
  },
  {
    featureId: "EFI05936",
    featureDesc: "Clamping height",
    label: "EFI05936: Clamping height"
  },
  {
    featureId: "EFI05953",
    featureDesc: "Jaw length",
    label: "EFI05953: Jaw length"
  },
  {
    featureId: "EFI05954",
    featureDesc: "Wire length connecting cable",
    label: "EFI05954: Wire length connecting cable"
  },
  {
    featureId: "EFI05976",
    featureDesc: "Max. cheeks opening",
    label: "EFI05976: Max. cheeks opening"
  },
  {
    featureId: "EFI05977",
    featureDesc: "Max. load",
    label: "EFI05977: Max. load"
  },
  {
    featureId: "EFI05979",
    featureDesc: "Max. hold temperature",
    label: "EFI05979: Max. hold temperature"
  },
  {
    featureId: "EFI05980",
    featureDesc: "Max. length of pull cord",
    label: "EFI05980: Max. length of pull cord"
  },
  {
    featureId: "EFI05981",
    featureDesc: "Max. material thickness",
    label: "EFI05981: Max. material thickness"
  },
  {
    featureId: "EFI05983",
    featureDesc: "Max. platform height",
    label: "EFI05983: Max. platform height"
  },
  {
    featureId: "EFI05986",
    featureDesc: "Max. spanner opening",
    label: "EFI05986: Max. spanner opening"
  },
  {
    featureId: "EFI05998",
    featureDesc: "Counter measuring range",
    label: "EFI05998: Counter measuring range"
  },
  {
    featureId: "EFI06108",
    featureDesc: "Pin diameter",
    label: "EFI06108: Pin diameter"
  },
  {
    featureId: "EFI06112",
    featureDesc: "Plate thickness",
    label: "EFI06112: Plate thickness"
  },
  {
    featureId: "EFI06116",
    featureDesc: "Handle length",
    label: "EFI06116: Handle length"
  },
  {
    featureId: "EFI06158",
    featureDesc: "Profile width",
    label: "EFI06158: Profile width"
  },
  {
    featureId: "EFI06159",
    featureDesc: "Profile height",
    label: "EFI06159: Profile height"
  },
  {
    featureId: "EFI06180",
    featureDesc: "Chain link diameter",
    label: "EFI06180: Chain link diameter"
  },
  {
    featureId: "EFI06190",
    featureDesc: "Wrench size large",
    label: "EFI06190: Wrench size large"
  },
  {
    featureId: "EFI06191",
    featureDesc: "Wrench size small",
    label: "EFI06191: Wrench size small"
  },
  {
    featureId: "EFI06201",
    featureDesc: "Clamping reach",
    label: "EFI06201: Clamping reach"
  },
  {
    featureId: "EFI06203",
    featureDesc: "Clamping spread",
    label: "EFI06203: Clamping spread"
  },
  {
    featureId: "EFI06210",
    featureDesc: "Standing height",
    label: "EFI06210: Standing height"
  },
  {
    featureId: "EFI06213",
    featureDesc: "Pitch measure",
    label: "EFI06213: Pitch measure"
  },
  {
    featureId: "EFI06218",
    featureDesc: "Stamp diameter",
    label: "EFI06218: Stamp diameter"
  },
  {
    featureId: "EFI06224",
    featureDesc: "Tooth distance",
    label: "EFI06224: Tooth distance"
  },
  {
    featureId: "EFI06235",
    featureDesc: "Permitted net load",
    label: "EFI06235: Permitted net load"
  },
  {
    featureId: "EFI06247",
    featureDesc: "Pull power",
    label: "EFI06247: Pull power"
  },
  {
    featureId: "EFI06272",
    featureDesc: "Outer pipe diameter",
    label: "EFI06272: Outer pipe diameter"
  },
  {
    featureId: "EFI06273",
    featureDesc: "Outer pipe diameter",
    label: "EFI06273: Outer pipe diameter"
  },
  {
    featureId: "EFI06302",
    featureDesc: "For nominal tube diameter",
    label: "EFI06302: For nominal tube diameter"
  },
  {
    featureId: "EFI06318",
    featureDesc: "Working length",
    label: "EFI06318: Working length"
  },
  {
    featureId: "EFI06333",
    featureDesc: "Max. heating water supply temperature",
    label: "EFI06333: Max. heating water supply temperature"
  },
  {
    featureId: "EFI06338",
    featureDesc: "Max. mounting height",
    label: "EFI06338: Max. mounting height"
  },
  {
    featureId: "EFI06339",
    featureDesc: "Max. door width",
    label: "EFI06339: Max. door width"
  },
  {
    featureId: "EFI06340",
    featureDesc: "Air volume",
    label: "EFI06340: Air volume"
  },
  {
    featureId: "EFI06398",
    featureDesc: "Suitable for duct depth",
    label: "EFI06398: Suitable for duct depth"
  },
  {
    featureId: "EFI06403",
    featureDesc: "Height baseboard duct",
    label: "EFI06403: Height baseboard duct"
  },
  {
    featureId: "EFI06404",
    featureDesc: "Depth plinth skirting duct",
    label: "EFI06404: Depth plinth skirting duct"
  },
  {
    featureId: "EFI06434",
    featureDesc: "Max. diameter luminaire",
    label: "EFI06434: Max. diameter luminaire"
  },
  {
    featureId: "EFI06435",
    featureDesc: "Min. ceiling thickness",
    label: "EFI06435: Min. ceiling thickness"
  },
  {
    featureId: "EFI06454",
    featureDesc: "Diameter shaft before shrink",
    label: "EFI06454: Diameter shaft before shrink"
  },
  {
    featureId: "EFI06455",
    featureDesc: "Diameter shaft after shrink",
    label: "EFI06455: Diameter shaft after shrink"
  },
  {
    featureId: "EFI06466",
    featureDesc: "Inner width",
    label: "EFI06466: Inner width"
  },
  {
    featureId: "EFI06480",
    featureDesc: "Suitable for plate thickness",
    label: "EFI06480: Suitable for plate thickness"
  },
  {
    featureId: "EFI06482",
    featureDesc: "Height hook",
    label: "EFI06482: Height hook"
  },
  {
    featureId: "EFI06492",
    featureDesc: "Sizes",
    label: "EFI06492: Sizes"
  },
  {
    featureId: "EFI06494",
    featureDesc: "For lamp diameter",
    label: "EFI06494: For lamp diameter"
  },
  {
    featureId: "EFI06495",
    featureDesc: "Span",
    label: "EFI06495: Span"
  },
  {
    featureId: "EFI06498",
    featureDesc: "Suitable for hole saw",
    label: "EFI06498: Suitable for hole saw"
  },
  {
    featureId: "EFI06502",
    featureDesc: "Max. cord diameter",
    label: "EFI06502: Max. cord diameter"
  },
  {
    featureId: "EFI06508",
    featureDesc: "Collar size",
    label: "EFI06508: Collar size"
  },
  {
    featureId: "EFI06518",
    featureDesc: "Rope length",
    label: "EFI06518: Rope length"
  },
  {
    featureId: "EFI06519",
    featureDesc: "Outer diameter spout",
    label: "EFI06519: Outer diameter spout"
  },
  {
    featureId: "EFI06524",
    featureDesc: "Joy stick length",
    label: "EFI06524: Joy stick length"
  },
  {
    featureId: "EFI06536",
    featureDesc: "Min. busbar thickness",
    label: "EFI06536: Min. busbar thickness"
  },
  {
    featureId: "EFI06537",
    featureDesc: "Lamella thickness",
    label: "EFI06537: Lamella thickness"
  },
  {
    featureId: "EFI06540",
    featureDesc: "Suitable for shackle diameter",
    label: "EFI06540: Suitable for shackle diameter"
  },
  {
    featureId: "EFI06588",
    featureDesc: "Inner height",
    label: "EFI06588: Inner height"
  },
  {
    featureId: "EFI06596",
    featureDesc: "Suitable for flange thickness",
    label: "EFI06596: Suitable for flange thickness"
  },
  {
    featureId: "EFI06601",
    featureDesc: "Pin dimension",
    label: "EFI06601: Pin dimension"
  },
  {
    featureId: "EFI06615",
    featureDesc: "Bit size",
    label: "EFI06615: Bit size"
  },
  {
    featureId: "EFI06645",
    featureDesc: "Head width",
    label: "EFI06645: Head width"
  },
  {
    featureId: "EFI06685",
    featureDesc: "Height chimney",
    label: "EFI06685: Height chimney"
  },
  {
    featureId: "EFI06686",
    featureDesc: "Height cap",
    label: "EFI06686: Height cap"
  },
  {
    featureId: "EFI06687",
    featureDesc: "Total height",
    label: "EFI06687: Total height"
  },
  {
    featureId: "EFI06700",
    featureDesc: "For thread diameter",
    label: "EFI06700: For thread diameter"
  },
  {
    featureId: "EFI06712",
    featureDesc: "Assembly depth",
    label: "EFI06712: Assembly depth"
  },
  {
    featureId: "EFI06719",
    featureDesc: "Wind sensor",
    label: "EFI06719: Wind sensor"
  },
  {
    featureId: "EFI06721",
    featureDesc: "Temperature sensor",
    label: "EFI06721: Temperature sensor"
  },
  {
    featureId: "EFI06784",
    featureDesc: "Resolution of the light curtain",
    label: "EFI06784: Resolution of the light curtain"
  },
  {
    featureId: "EFI06823",
    featureDesc: "Diameter connection pin",
    label: "EFI06823: Diameter connection pin"
  },
  {
    featureId: "EFI06824",
    featureDesc: "Strip length",
    label: "EFI06824: Strip length"
  },
  {
    featureId: "EFI06825",
    featureDesc: "Length pin",
    label: "EFI06825: Length pin"
  },
  {
    featureId: "EFI06827",
    featureDesc: "Modular spacing connections",
    label: "EFI06827: Modular spacing connections"
  },
  {
    featureId: "EFI07009",
    featureDesc: "Adjustable switch off temperature",
    label: "EFI07009: Adjustable switch off temperature"
  },
  {
    featureId: "EFI07074",
    featureDesc: "Rated surrounding temperature without derating",
    label: "EFI07074: Rated surrounding temperature without derating"
  },
  {
    featureId: "EFI07092",
    featureDesc: "Width clamp",
    label: "EFI07092: Width clamp"
  },
  {
    featureId: "EFI07094",
    featureDesc: "Width sensor",
    label: "EFI07094: Width sensor"
  },
  {
    featureId: "EFI07095",
    featureDesc: "Width amplifier",
    label: "EFI07095: Width amplifier"
  },
  {
    featureId: "EFI07110",
    featureDesc: "Diameter axle",
    label: "EFI07110: Diameter axle"
  },
  {
    featureId: "EFI07111",
    featureDesc: "Diameter sensor",
    label: "EFI07111: Diameter sensor"
  },
  {
    featureId: "EFI07125",
    featureDesc: "Adjustable switch on temperature",
    label: "EFI07125: Adjustable switch on temperature"
  },
  {
    featureId: "EFI07127",
    featureDesc: "Adjustable setting value for flow for liquids",
    label: "EFI07127: Adjustable setting value for flow for liquids"
  },
  {
    featureId: "EFI07128",
    featureDesc: "Adjustable setting value for flow for gases",
    label: "EFI07128: Adjustable setting value for flow for gases"
  },
  {
    featureId: "EFI07173",
    featureDesc: "Volume flow, freeblowing",
    label: "EFI07173: Volume flow, freeblowing"
  },
  {
    featureId: "EFI07219",
    featureDesc: "Busbar width",
    label: "EFI07219: Busbar width"
  },
  {
    featureId: "EFI07220",
    featureDesc: "Busbar thickness",
    label: "EFI07220: Busbar thickness"
  },
  {
    featureId: "EFI07253",
    featureDesc: "Suitable for PCB thickness",
    label: "EFI07253: Suitable for PCB thickness"
  },
  {
    featureId: "EFI07283",
    featureDesc: "Height of sensor",
    label: "EFI07283: Height of sensor"
  },
  {
    featureId: "EFI07284",
    featureDesc: "Height amplifier",
    label: "EFI07284: Height amplifier"
  },
  {
    featureId: "EFI07300",
    featureDesc: "Length of the preassembled cable",
    label: "EFI07300: Length of the preassembled cable"
  },
  {
    featureId: "EFI07302",
    featureDesc: "Cable outer diameter for cable gland",
    label: "EFI07302: Cable outer diameter for cable gland"
  },
  {
    featureId: "EFI07319",
    featureDesc: "Diameter contact female",
    label: "EFI07319: Diameter contact female"
  },
  {
    featureId: "EFI07320",
    featureDesc: "Diameter plug-in contact",
    label: "EFI07320: Diameter plug-in contact"
  },
  {
    featureId: "EFI07326",
    featureDesc: "Modular spacing contacts",
    label: "EFI07326: Modular spacing contacts"
  },
  {
    featureId: "EFI07327",
    featureDesc: "Diameter contact pin",
    label: "EFI07327: Diameter contact pin"
  },
  {
    featureId: "EFI07334",
    featureDesc: "Length of sensor",
    label: "EFI07334: Length of sensor"
  },
  {
    featureId: "EFI07335",
    featureDesc: "Length amplifier",
    label: "EFI07335: Length amplifier"
  },
  {
    featureId: "EFI07337",
    featureDesc: "Connectable conductor cross section solid-core",
    label: "EFI07337: Connectable conductor cross section solid-core"
  },
  {
    featureId: "EFI07339",
    featureDesc: "Connectable conductor cross section fine-strand with cable end sleeve",
    label: "EFI07339: Connectable conductor cross section fine-strand with cable end sleeve"
  },
  {
    featureId: "EFI07341",
    featureDesc: "Connectable conductor cross section fine-strand without cable end sleeve",
    label: "EFI07341: Connectable conductor cross section fine-strand without cable end sleeve"
  },
  {
    featureId: "EFI07343",
    featureDesc: "Connectable conductor cross section multi-wired",
    label: "EFI07343: Connectable conductor cross section multi-wired"
  },
  {
    featureId: "EFI07367",
    featureDesc: "Max. switching distance",
    label: "EFI07367: Max. switching distance"
  },
  {
    featureId: "EFI07369",
    featureDesc: "Medium temperature",
    label: "EFI07369: Medium temperature"
  },
  {
    featureId: "EFI07373",
    featureDesc: "Differential gap, temperature",
    label: "EFI07373: Differential gap, temperature"
  },
  {
    featureId: "EFI07389",
    featureDesc: "Nominal volume flow rate",
    label: "EFI07389: Nominal volume flow rate"
  },
  {
    featureId: "EFI07496",
    featureDesc: "Diameter connector",
    label: "EFI07496: Diameter connector"
  },
  {
    featureId: "EFI07504",
    featureDesc: "Module width",
    label: "EFI07504: Module width"
  },
  {
    featureId: "EFI07505",
    featureDesc: "Ambient temperature for evaluation electronic",
    label: "EFI07505: Ambient temperature for evaluation electronic"
  },
  {
    featureId: "EFI07515",
    featureDesc: "Ambient temperature during operating",
    label: "EFI07515: Ambient temperature during operating"
  },
  {
    featureId: "EFI07552",
    featureDesc: "Guard locking force",
    label: "EFI07552: Guard locking force"
  },
  {
    featureId: "EFI07559",
    featureDesc: "Suitable for spigot size",
    label: "EFI07559: Suitable for spigot size"
  },
  {
    featureId: "EFI07561",
    featureDesc: "Height levelling",
    label: "EFI07561: Height levelling"
  },
  {
    featureId: "EFI07564",
    featureDesc: "Outgoing width",
    label: "EFI07564: Outgoing width"
  },
  {
    featureId: "EFI07565",
    featureDesc: "Outgoing height",
    label: "EFI07565: Outgoing height"
  },
  {
    featureId: "EFI07567",
    featureDesc: "Strip range diameter",
    label: "EFI07567: Strip range diameter"
  },
  {
    featureId: "EFI07568",
    featureDesc: "Strip range cross-section",
    label: "EFI07568: Strip range cross-section"
  },
  {
    featureId: "EFI07570",
    featureDesc: "Suction power",
    label: "EFI07570: Suction power"
  },
  {
    featureId: "EFI07572",
    featureDesc: "Core cross section",
    label: "EFI07572: Core cross section"
  },
  {
    featureId: "EFI07668",
    featureDesc: "Outer diameter sleeve",
    label: "EFI07668: Outer diameter sleeve"
  },
  {
    featureId: "EFI07690",
    featureDesc: "Operating temperature, short-run",
    label: "EFI07690: Operating temperature, short-run"
  },
  {
    featureId: "EFI07701",
    featureDesc: "Floor distance",
    label: "EFI07701: Floor distance"
  },
  {
    featureId: "EFI07703",
    featureDesc: "Bore diameter",
    label: "EFI07703: Bore diameter"
  },
  {
    featureId: "EFI07708",
    featureDesc: "Width grill surface",
    label: "EFI07708: Width grill surface"
  },
  {
    featureId: "EFI07709",
    featureDesc: "Width of the tread",
    label: "EFI07709: Width of the tread"
  },
  {
    featureId: "EFI07710",
    featureDesc: "Suitable for wall duct width",
    label: "EFI07710: Suitable for wall duct width"
  },
  {
    featureId: "EFI07711",
    featureDesc: "Suitable for wall duct width",
    label: "EFI07711: Suitable for wall duct width"
  },
  {
    featureId: "EFI07712",
    featureDesc: "Width installation duct",
    label: "EFI07712: Width installation duct"
  },
  {
    featureId: "EFI07713",
    featureDesc: "Width corner duct",
    label: "EFI07713: Width corner duct"
  },
  {
    featureId: "EFI07716",
    featureDesc: "Width duct connection",
    label: "EFI07716: Width duct connection"
  },
  {
    featureId: "EFI07719",
    featureDesc: "Bundle diameter",
    label: "EFI07719: Bundle diameter"
  },
  {
    featureId: "EFI07724",
    featureDesc: "Nominal cross section Cu",
    label: "EFI07724: Nominal cross section Cu"
  },
  {
    featureId: "EFI07734",
    featureDesc: "Diameter tube",
    label: "EFI07734: Diameter tube"
  },
  {
    featureId: "EFI07740",
    featureDesc: "Ice-cube quantity",
    label: "EFI07740: Ice-cube quantity"
  },
  {
    featureId: "EFI07747",
    featureDesc: "Desoldering tip diameter",
    label: "EFI07747: Desoldering tip diameter"
  },
  {
    featureId: "EFI07762",
    featureDesc: "Loading capacity bean reservoir",
    label: "EFI07762: Loading capacity bean reservoir"
  },
  {
    featureId: "EFI07763",
    featureDesc: "Capacity deep frying good",
    label: "EFI07763: Capacity deep frying good"
  },
  {
    featureId: "EFI07771",
    featureDesc: "Filling quantity",
    label: "EFI07771: Filling quantity"
  },
  {
    featureId: "EFI07772",
    featureDesc: "Filling quantity oil",
    label: "EFI07772: Filling quantity oil"
  },
  {
    featureId: "EFI07774",
    featureDesc: "For box-/housing width",
    label: "EFI07774: For box-/housing width"
  },
  {
    featureId: "EFI07775",
    featureDesc: "For box-/housing diameter",
    label: "EFI07775: For box-/housing diameter"
  },
  {
    featureId: "EFI07776",
    featureDesc: "For box-/housing length",
    label: "EFI07776: For box-/housing length"
  },
  {
    featureId: "EFI07778",
    featureDesc: "For nominal duct width",
    label: "EFI07778: For nominal duct width"
  },
  {
    featureId: "EFI07785",
    featureDesc: "For tube diameter, large",
    label: "EFI07785: For tube diameter, large"
  },
  {
    featureId: "EFI07786",
    featureDesc: "For tube diameter, small",
    label: "EFI07786: For tube diameter, small"
  },
  {
    featureId: "EFI07789",
    featureDesc: "For wall thickness",
    label: "EFI07789: For wall thickness"
  },
  {
    featureId: "EFI07814",
    featureDesc: "Suitable for duct width",
    label: "EFI07814: Suitable for duct width"
  },
  {
    featureId: "EFI07817",
    featureDesc: "Suitable for max. bolt diameter",
    label: "EFI07817: Suitable for max. bolt diameter"
  },
  {
    featureId: "EFI07835",
    featureDesc: "Thread size punch side",
    label: "EFI07835: Thread size punch side"
  },
  {
    featureId: "EFI07836",
    featureDesc: "Thread size actuator side",
    label: "EFI07836: Thread size actuator side"
  },
  {
    featureId: "EFI07859",
    featureDesc: "Height installation duct",
    label: "EFI07859: Height installation duct"
  },
  {
    featureId: "EFI07860",
    featureDesc: "Height side joist",
    label: "EFI07860: Height side joist"
  },
  {
    featureId: "EFI07861",
    featureDesc: "Height corner duct",
    label: "EFI07861: Height corner duct"
  },
  {
    featureId: "EFI07863",
    featureDesc: "Height duct connection",
    label: "EFI07863: Height duct connection"
  },
  {
    featureId: "EFI07871",
    featureDesc: "Volume can/cartouche",
    label: "EFI07871: Volume can/cartouche"
  },
  {
    featureId: "EFI07872",
    featureDesc: "Volume bin/bag",
    label: "EFI07872: Volume bin/bag"
  },
  {
    featureId: "EFI07873",
    featureDesc: "Inner diameter sleeve",
    label: "EFI07873: Inner diameter sleeve"
  },
  {
    featureId: "EFI07874",
    featureDesc: "Inner diameter finger after shrink",
    label: "EFI07874: Inner diameter finger after shrink"
  },
  {
    featureId: "EFI07875",
    featureDesc: "Inner diameter finger before shrink",
    label: "EFI07875: Inner diameter finger before shrink"
  },
  {
    featureId: "EFI07883",
    featureDesc: "Duct width inward",
    label: "EFI07883: Duct width inward"
  },
  {
    featureId: "EFI07884",
    featureDesc: "Duct width",
    label: "EFI07884: Duct width"
  },
  {
    featureId: "EFI07885",
    featureDesc: "Duct height inward",
    label: "EFI07885: Duct height inward"
  },
  {
    featureId: "EFI07887",
    featureDesc: "Lowest bending radius horizontal",
    label: "EFI07887: Lowest bending radius horizontal"
  },
  {
    featureId: "EFI07888",
    featureDesc: "Lowest bending radius vertical",
    label: "EFI07888: Lowest bending radius vertical"
  },
  {
    featureId: "EFI07894",
    featureDesc: "Creepage path",
    label: "EFI07894: Creepage path"
  },
  {
    featureId: "EFI07902",
    featureDesc: "Cover plate length",
    label: "EFI07902: Cover plate length"
  },
  {
    featureId: "EFI07907",
    featureDesc: "Length suction hose",
    label: "EFI07907: Length suction hose"
  },
  {
    featureId: "EFI07908",
    featureDesc: "Length distributor",
    label: "EFI07908: Length distributor"
  },
  {
    featureId: "EFI07912",
    featureDesc: "Nominal cross section conductor branch cable",
    label: "EFI07912: Nominal cross section conductor branch cable"
  },
  {
    featureId: "EFI07913",
    featureDesc: "Nominal cross section conductor main cable",
    label: "EFI07913: Nominal cross section conductor main cable"
  },
  {
    featureId: "EFI07914",
    featureDesc: "Nominal cross section conductor",
    label: "EFI07914: Nominal cross section conductor"
  },
  {
    featureId: "EFI07922",
    featureDesc: "Material thickness cover",
    label: "EFI07922: Material thickness cover"
  },
  {
    featureId: "EFI07928",
    featureDesc: "Max. foil width",
    label: "EFI07928: Max. foil width"
  },
  {
    featureId: "EFI07929",
    featureDesc: "Max. yogurt quantity",
    label: "EFI07929: Max. yogurt quantity"
  },
  {
    featureId: "EFI07930",
    featureDesc: "Knife diameter",
    label: "EFI07930: Knife diameter"
  },
  {
    featureId: "EFI07935",
    featureDesc: "Min. height finishing layer",
    label: "EFI07935: Min. height finishing layer"
  },
  {
    featureId: "EFI07987",
    featureDesc: "Nominal pressure",
    label: "EFI07987: Nominal pressure"
  },
  {
    featureId: "EFI07989",
    featureDesc: "Nominal height",
    label: "EFI07989: Nominal height"
  },
  {
    featureId: "EFI07991",
    featureDesc: "Nominal cross section connection cable",
    label: "EFI07991: Nominal cross section connection cable"
  },
  {
    featureId: "EFI07999",
    featureDesc: "Levelling height",
    label: "EFI07999: Levelling height"
  },
  {
    featureId: "EFI08002",
    featureDesc: "Net capacity",
    label: "EFI08002: Net capacity"
  },
  {
    featureId: "EFI08004",
    featureDesc: "Net capacity fridge compartment",
    label: "EFI08004: Net capacity fridge compartment"
  },
  {
    featureId: "EFI08011",
    featureDesc: "Optimum mounting height",
    label: "EFI08011: Optimum mounting height"
  },
  {
    featureId: "EFI08015",
    featureDesc: "Plate thickness/product length",
    label: "EFI08015: Plate thickness/product length"
  },
  {
    featureId: "EFI08029",
    featureDesc: "Reduced Al nominal cross section",
    label: "EFI08029: Reduced Al nominal cross section"
  },
  {
    featureId: "EFI08030",
    featureDesc: "Row distance",
    label: "EFI08030: Row distance"
  },
  {
    featureId: "EFI08039",
    featureDesc: "Leg width",
    label: "EFI08039: Leg width"
  },
  {
    featureId: "EFI08040",
    featureDesc: "Leg length",
    label: "EFI08040: Leg length"
  },
  {
    featureId: "EFI08041",
    featureDesc: "Screen diameter",
    label: "EFI08041: Screen diameter"
  },
  {
    featureId: "EFI08042",
    featureDesc: "Hose length",
    label: "EFI08042: Hose length"
  },
  {
    featureId: "EFI08073",
    featureDesc: "St nominal cross section",
    label: "EFI08073: St nominal cross section"
  },
  {
    featureId: "EFI08079",
    featureDesc: "Depth grill surface",
    label: "EFI08079: Depth grill surface"
  },
  {
    featureId: "EFI08080",
    featureDesc: "Depth of the tread",
    label: "EFI08080: Depth of the tread"
  },
  {
    featureId: "EFI08081",
    featureDesc: "Width crucible",
    label: "EFI08081: Width crucible"
  },
  {
    featureId: "EFI08082",
    featureDesc: "Length crucible",
    label: "EFI08082: Length crucible"
  },
  {
    featureId: "EFI08083",
    featureDesc: "Depth crucible",
    label: "EFI08083: Depth crucible"
  },
  {
    featureId: "EFI08117",
    featureDesc: "Filament width",
    label: "EFI08117: Filament width"
  },
  {
    featureId: "EFI08139",
    featureDesc: "Top end diameter, luminaire side",
    label: "EFI08139: Top end diameter, luminaire side"
  },
  {
    featureId: "EFI08140",
    featureDesc: "Top end diameter, pole side",
    label: "EFI08140: Top end diameter, pole side"
  },
  {
    featureId: "EFI08160",
    featureDesc: "Band width",
    label: "EFI08160: Band width"
  },
  {
    featureId: "EFI08163",
    featureDesc: "Cooling performance",
    label: "EFI08163: Cooling performance"
  },
  {
    featureId: "EFI08171",
    featureDesc: "Air power",
    label: "EFI08171: Air power"
  },
  {
    featureId: "EFI08185",
    featureDesc: "Rung centre spacing",
    label: "EFI08185: Rung centre spacing"
  },
  {
    featureId: "EFI08200",
    featureDesc: "Connectable conductor cross section",
    label: "EFI08200: Connectable conductor cross section"
  },
  {
    featureId: "EFI08209",
    featureDesc: "Adjustable distance",
    label: "EFI08209: Adjustable distance"
  },
  {
    featureId: "EFI08213",
    featureDesc: "Measuring range length",
    label: "EFI08213: Measuring range length"
  },
  {
    featureId: "EFI08217",
    featureDesc: "Permitted cable diameter",
    label: "EFI08217: Permitted cable diameter"
  },
  {
    featureId: "EFI08254",
    featureDesc: "Inner diameter opening",
    label: "EFI08254: Inner diameter opening"
  },
  {
    featureId: "EFI08269",
    featureDesc: "Profile height busbar",
    label: "EFI08269: Profile height busbar"
  },
  {
    featureId: "EFI08270",
    featureDesc: "Specific paper mass (grammage)",
    label: "EFI08270: Specific paper mass (grammage)"
  },
  {
    featureId: "EFI08280",
    featureDesc: "Insulating section",
    label: "EFI08280: Insulating section"
  },
  {
    featureId: "EFI08289",
    featureDesc: "Rated switching distance",
    label: "EFI08289: Rated switching distance"
  },
  {
    featureId: "EFI08290",
    featureDesc: "Fork depth",
    label: "EFI08290: Fork depth"
  },
  {
    featureId: "EFI08291",
    featureDesc: "Fork width",
    label: "EFI08291: Fork width"
  },
  {
    featureId: "EFI08292",
    featureDesc: "Min. object size",
    label: "EFI08292: Min. object size"
  },
  {
    featureId: "EFI08293",
    featureDesc: "Frame opening lengthwise",
    label: "EFI08293: Frame opening lengthwise"
  },
  {
    featureId: "EFI08294",
    featureDesc: "Frame opening cross",
    label: "EFI08294: Frame opening cross"
  },
  {
    featureId: "EFI08297",
    featureDesc: "Dismantle length",
    label: "EFI08297: Dismantle length"
  },
  {
    featureId: "EFI08308",
    featureDesc: "Diameter mounting hole",
    label: "EFI08308: Diameter mounting hole"
  },
  {
    featureId: "EFI08310",
    featureDesc: "Diameter lamp",
    label: "EFI08310: Diameter lamp"
  },
  {
    featureId: "EFI08315",
    featureDesc: "For max. bundle diameter",
    label: "EFI08315: For max. bundle diameter"
  },
  {
    featureId: "EFI08317",
    featureDesc: "Insulating piece permanent temperature-resistant",
    label: "EFI08317: Insulating piece permanent temperature-resistant"
  },
  {
    featureId: "EFI08318",
    featureDesc: "Lowest bending radius",
    label: "EFI08318: Lowest bending radius"
  },
  {
    featureId: "EFI08320",
    featureDesc: "Length of particular segments",
    label: "EFI08320: Length of particular segments"
  },
  {
    featureId: "EFI08323",
    featureDesc: "Cable permanent temperature-resistant to",
    label: "EFI08323: Cable permanent temperature-resistant to"
  },
  {
    featureId: "EFI08326",
    featureDesc: "Mounting temperature",
    label: "EFI08326: Mounting temperature"
  },
  {
    featureId: "EFI08333",
    featureDesc: "Niche width",
    label: "EFI08333: Niche width"
  },
  {
    featureId: "EFI08334",
    featureDesc: "Niche height",
    label: "EFI08334: Niche height"
  },
  {
    featureId: "EFI08339",
    featureDesc: "Suitable for cable management system width",
    label: "EFI08339: Suitable for cable management system width"
  },
  {
    featureId: "EFI08355",
    featureDesc: "Height flanged sides",
    label: "EFI08355: Height flanged sides"
  },
  {
    featureId: "EFI08361",
    featureDesc: "Length recess",
    label: "EFI08361: Length recess"
  },
  {
    featureId: "EFI08362",
    featureDesc: "Width recess",
    label: "EFI08362: Width recess"
  },
  {
    featureId: "EFI08363",
    featureDesc: "Diameter recess",
    label: "EFI08363: Diameter recess"
  },
  {
    featureId: "EFI08380",
    featureDesc: "Width cable tie",
    label: "EFI08380: Width cable tie"
  },
  {
    featureId: "EFI08405",
    featureDesc: "Drill hole distance vertical",
    label: "EFI08405: Drill hole distance vertical"
  },
  {
    featureId: "EFI08406",
    featureDesc: "Drill hole distance horizontal",
    label: "EFI08406: Drill hole distance horizontal"
  },
  {
    featureId: "EFI08407",
    featureDesc: "Flange size vertical",
    label: "EFI08407: Flange size vertical"
  },
  {
    featureId: "EFI08408",
    featureDesc: "Flange size horizontal",
    label: "EFI08408: Flange size horizontal"
  },
  {
    featureId: "EFI08447",
    featureDesc: "Delivery quantity",
    label: "EFI08447: Delivery quantity"
  },
  {
    featureId: "EFI08488",
    featureDesc: "Outer width",
    label: "EFI08488: Outer width"
  },
  {
    featureId: "EFI08489",
    featureDesc: "Outer height",
    label: "EFI08489: Outer height"
  },
  {
    featureId: "EFI08490",
    featureDesc: "Max. cantilever length",
    label: "EFI08490: Max. cantilever length"
  },
  {
    featureId: "EFI08528",
    featureDesc: "Lowering height",
    label: "EFI08528: Lowering height"
  },
  {
    featureId: "EFI08530",
    featureDesc: "Hoisting load",
    label: "EFI08530: Hoisting load"
  },
  {
    featureId: "EFI08531",
    featureDesc: "Hoisting speed",
    label: "EFI08531: Hoisting speed"
  },
  {
    featureId: "EFI08539",
    featureDesc: "Max. material throughput",
    label: "EFI08539: Max. material throughput"
  },
  {
    featureId: "EFI08540",
    featureDesc: "Max. branch thickness",
    label: "EFI08540: Max. branch thickness"
  },
  {
    featureId: "EFI08542",
    featureDesc: "Branch thickness",
    label: "EFI08542: Branch thickness"
  },
  {
    featureId: "EFI08543",
    featureDesc: "Distance of blades",
    label: "EFI08543: Distance of blades"
  },
  {
    featureId: "EFI08544",
    featureDesc: "Cutting width",
    label: "EFI08544: Cutting width"
  },
  {
    featureId: "EFI08546",
    featureDesc: "Sword length",
    label: "EFI08546: Sword length"
  },
  {
    featureId: "EFI08547",
    featureDesc: "Utilizable knife length",
    label: "EFI08547: Utilizable knife length"
  },
  {
    featureId: "EFI08548",
    featureDesc: "Adjustable conductor cross section",
    label: "EFI08548: Adjustable conductor cross section"
  },
  {
    featureId: "EFI08554",
    featureDesc: "Utilizable length of cut",
    label: "EFI08554: Utilizable length of cut"
  },
  {
    featureId: "EFI08555",
    featureDesc: "Max. head load",
    label: "EFI08555: Max. head load"
  },
  {
    featureId: "EFI08560",
    featureDesc: "Wire cross section, phase-side",
    label: "EFI08560: Wire cross section, phase-side"
  },
  {
    featureId: "EFI08561",
    featureDesc: "Wire cross section, earth-side",
    label: "EFI08561: Wire cross section, earth-side"
  },
  {
    featureId: "EFI08562",
    featureDesc: "Length 1-pole rope",
    label: "EFI08562: Length 1-pole rope"
  },
  {
    featureId: "EFI08563",
    featureDesc: "Length phaseline a",
    label: "EFI08563: Length phaseline a"
  },
  {
    featureId: "EFI08564",
    featureDesc: "Length phaseline b",
    label: "EFI08564: Length phaseline b"
  },
  {
    featureId: "EFI08565",
    featureDesc: "Length phaseline c",
    label: "EFI08565: Length phaseline c"
  },
  {
    featureId: "EFI08566",
    featureDesc: "Length earth line",
    label: "EFI08566: Length earth line"
  },
  {
    featureId: "EFI08570",
    featureDesc: "Max. chain speed",
    label: "EFI08570: Max. chain speed"
  },
  {
    featureId: "EFI08571",
    featureDesc: "Smallest possible mouth",
    label: "EFI08571: Smallest possible mouth"
  },
  {
    featureId: "EFI08573",
    featureDesc: "For wire cross section",
    label: "EFI08573: For wire cross section"
  },
  {
    featureId: "EFI08576",
    featureDesc: "Earthing cable cross section",
    label: "EFI08576: Earthing cable cross section"
  },
  {
    featureId: "EFI08582",
    featureDesc: "Cutting height",
    label: "EFI08582: Cutting height"
  },
  {
    featureId: "EFI08583",
    featureDesc: "Volume catcher",
    label: "EFI08583: Volume catcher"
  },
  {
    featureId: "EFI08584",
    featureDesc: "Steam capacity",
    label: "EFI08584: Steam capacity"
  },
  {
    featureId: "EFI08585",
    featureDesc: "Mower cut circle diameter",
    label: "EFI08585: Mower cut circle diameter"
  },
  {
    featureId: "EFI08586",
    featureDesc: "Length of telescopic handle",
    label: "EFI08586: Length of telescopic handle"
  },
  {
    featureId: "EFI08587",
    featureDesc: "Working width",
    label: "EFI08587: Working width"
  },
  {
    featureId: "EFI08591",
    featureDesc: "Milling basket stroke",
    label: "EFI08591: Milling basket stroke"
  },
  {
    featureId: "EFI08592",
    featureDesc: "Collet chuck diameter",
    label: "EFI08592: Collet chuck diameter"
  },
  {
    featureId: "EFI08597",
    featureDesc: "Width of bearing surface",
    label: "EFI08597: Width of bearing surface"
  },
  {
    featureId: "EFI08598",
    featureDesc: "Length of bearing surface",
    label: "EFI08598: Length of bearing surface"
  },
  {
    featureId: "EFI08599",
    featureDesc: "Cutting width 90°/90°",
    label: "EFI08599: Cutting width 90°/90°"
  },
  {
    featureId: "EFI08600",
    featureDesc: "Cutting depth 90°/90°",
    label: "EFI08600: Cutting depth 90°/90°"
  },
  {
    featureId: "EFI08601",
    featureDesc: "Cutting width 90°/45°",
    label: "EFI08601: Cutting width 90°/45°"
  },
  {
    featureId: "EFI08602",
    featureDesc: "Cutting depth 90°/45°",
    label: "EFI08602: Cutting depth 90°/45°"
  },
  {
    featureId: "EFI08603",
    featureDesc: "Cutting width 45°/90°",
    label: "EFI08603: Cutting width 45°/90°"
  },
  {
    featureId: "EFI08604",
    featureDesc: "Cutting depth 45°/90°",
    label: "EFI08604: Cutting depth 45°/90°"
  },
  {
    featureId: "EFI08605",
    featureDesc: "Cutting width 45°/45°",
    label: "EFI08605: Cutting width 45°/45°"
  },
  {
    featureId: "EFI08606",
    featureDesc: "Cutting depth 45°/45°",
    label: "EFI08606: Cutting depth 45°/45°"
  },
  {
    featureId: "EFI08607",
    featureDesc: "Cutting width chop operation 90°/90°",
    label: "EFI08607: Cutting width chop operation 90°/90°"
  },
  {
    featureId: "EFI08608",
    featureDesc: "Cutting depth chop operation 90°/90°",
    label: "EFI08608: Cutting depth chop operation 90°/90°"
  },
  {
    featureId: "EFI08609",
    featureDesc: "Cutting width chop operation 90°/45°",
    label: "EFI08609: Cutting width chop operation 90°/45°"
  },
  {
    featureId: "EFI08610",
    featureDesc: "Cutting depth chop operation 90°/45°",
    label: "EFI08610: Cutting depth chop operation 90°/45°"
  },
  {
    featureId: "EFI08611",
    featureDesc: "Cutting width chop operation 45°/90°",
    label: "EFI08611: Cutting width chop operation 45°/90°"
  },
  {
    featureId: "EFI08612",
    featureDesc: "Cutting depth chop operation 45°/90°",
    label: "EFI08612: Cutting depth chop operation 45°/90°"
  },
  {
    featureId: "EFI08613",
    featureDesc: "Cutting width chop operation 45°/45°",
    label: "EFI08613: Cutting width chop operation 45°/45°"
  },
  {
    featureId: "EFI08614",
    featureDesc: "Cutting depth chop operation 45°/45°",
    label: "EFI08614: Cutting depth chop operation 45°/45°"
  },
  {
    featureId: "EFI08615",
    featureDesc: "Cutting height table operation 90°",
    label: "EFI08615: Cutting height table operation 90°"
  },
  {
    featureId: "EFI08616",
    featureDesc: "Cutting height table operation 45°",
    label: "EFI08616: Cutting height table operation 45°"
  },
  {
    featureId: "EFI08627",
    featureDesc: "Vibrations",
    label: "EFI08627: Vibrations"
  },
  {
    featureId: "EFI08628",
    featureDesc: "Diameter of vibrator",
    label: "EFI08628: Diameter of vibrator"
  },
  {
    featureId: "EFI08629",
    featureDesc: "Length of rubber hose",
    label: "EFI08629: Length of rubber hose"
  },
  {
    featureId: "EFI08655",
    featureDesc: "Lifting speed",
    label: "EFI08655: Lifting speed"
  },
  {
    featureId: "EFI08691",
    featureDesc: "Max. total height",
    label: "EFI08691: Max. total height"
  },
  {
    featureId: "EFI08709",
    featureDesc: "Max. weight luminaire",
    label: "EFI08709: Max. weight luminaire"
  },
  {
    featureId: "EFI08732",
    featureDesc: "Recognition distance",
    label: "EFI08732: Recognition distance"
  },
  {
    featureId: "EFI08752",
    featureDesc: "Capacity 'cotton'",
    label: "EFI08752: Capacity 'cotton'"
  },
  {
    featureId: "EFI08789",
    featureDesc: "Max. surface temperature T dust",
    label: "EFI08789: Max. surface temperature T dust"
  },
  {
    featureId: "EFI08808",
    featureDesc: "Device width",
    label: "EFI08808: Device width"
  },
  {
    featureId: "EFI08809",
    featureDesc: "Device height",
    label: "EFI08809: Device height"
  },
  {
    featureId: "EFI08810",
    featureDesc: "Device depth",
    label: "EFI08810: Device depth"
  },
  {
    featureId: "EFI08827",
    featureDesc: "Max. conductor cross section solid (solid, stranded)",
    label: "EFI08827: Max. conductor cross section solid (solid, stranded)"
  },
  {
    featureId: "EFI08828",
    featureDesc: "Max. conductor cross section flexible (fine-strand)",
    label: "EFI08828: Max. conductor cross section flexible (fine-strand)"
  },
  {
    featureId: "EFI08886",
    featureDesc: "Tank capacity",
    label: "EFI08886: Tank capacity"
  },
  {
    featureId: "EFI08890",
    featureDesc: "Piston displacement per minute",
    label: "EFI08890: Piston displacement per minute"
  },
  {
    featureId: "EFI08915",
    featureDesc: "Axis length",
    label: "EFI08915: Axis length"
  },
  {
    featureId: "EFI08916",
    featureDesc: "Max. arm length",
    label: "EFI08916: Max. arm length"
  },
  {
    featureId: "EFI08923",
    featureDesc: "Tip length",
    label: "EFI08923: Tip length"
  },
  {
    featureId: "EFI08926",
    featureDesc: "Width of hooked foot",
    label: "EFI08926: Width of hooked foot"
  },
  {
    featureId: "EFI08928",
    featureDesc: "Depth of hooked foot",
    label: "EFI08928: Depth of hooked foot"
  },
  {
    featureId: "EFI08929",
    featureDesc: "Thickness of hooked foot at tip",
    label: "EFI08929: Thickness of hooked foot at tip"
  },
  {
    featureId: "EFI08933",
    featureDesc: "Max. output torque",
    label: "EFI08933: Max. output torque"
  },
  {
    featureId: "EFI08934",
    featureDesc: "Max. input torque",
    label: "EFI08934: Max. input torque"
  },
  {
    featureId: "EFI08940",
    featureDesc: "Height of head small",
    label: "EFI08940: Height of head small"
  },
  {
    featureId: "EFI08941",
    featureDesc: "Height of head large",
    label: "EFI08941: Height of head large"
  },
  {
    featureId: "EFI08942",
    featureDesc: "Max. reaction measure",
    label: "EFI08942: Max. reaction measure"
  },
  {
    featureId: "EFI08943",
    featureDesc: "Min. reaction measure",
    label: "EFI08943: Min. reaction measure"
  },
  {
    featureId: "EFI08945",
    featureDesc: "Blade diameter",
    label: "EFI08945: Blade diameter"
  },
  {
    featureId: "EFI08946",
    featureDesc: "Wrench width (hexagon drive)",
    label: "EFI08946: Wrench width (hexagon drive)"
  },
  {
    featureId: "EFI08947",
    featureDesc: "Connection size",
    label: "EFI08947: Connection size"
  },
  {
    featureId: "EFI08948",
    featureDesc: "Clamping spread (internal pulling)",
    label: "EFI08948: Clamping spread (internal pulling)"
  },
  {
    featureId: "EFI08949",
    featureDesc: "Max. clamping spread (external pulling)",
    label: "EFI08949: Max. clamping spread (external pulling)"
  },
  {
    featureId: "EFI08966",
    featureDesc: "Spatula length",
    label: "EFI08966: Spatula length"
  },
  {
    featureId: "EFI08969",
    featureDesc: "Head width small",
    label: "EFI08969: Head width small"
  },
  {
    featureId: "EFI08970",
    featureDesc: "Head width large",
    label: "EFI08970: Head width large"
  },
  {
    featureId: "EFI08971",
    featureDesc: "Wrench depth small",
    label: "EFI08971: Wrench depth small"
  },
  {
    featureId: "EFI08972",
    featureDesc: "Wrench depth large",
    label: "EFI08972: Wrench depth large"
  },
  {
    featureId: "EFI08978",
    featureDesc: "Wrench depth",
    label: "EFI08978: Wrench depth"
  },
  {
    featureId: "EFI08995",
    featureDesc: "Step size adjustment",
    label: "EFI08995: Step size adjustment"
  },
  {
    featureId: "EFI08996",
    featureDesc: "Accuracy scale division",
    label: "EFI08996: Accuracy scale division"
  },
  {
    featureId: "EFI08999",
    featureDesc: "Length extension",
    label: "EFI08999: Length extension"
  },
  {
    featureId: "EFI09000",
    featureDesc: "Weight head",
    label: "EFI09000: Weight head"
  },
  {
    featureId: "EFI09001",
    featureDesc: "Weight total",
    label: "EFI09001: Weight total"
  },
  {
    featureId: "EFI09003",
    featureDesc: "Blade height",
    label: "EFI09003: Blade height"
  },
  {
    featureId: "EFI09004",
    featureDesc: "Wrench width open end",
    label: "EFI09004: Wrench width open end"
  },
  {
    featureId: "EFI09006",
    featureDesc: "Wrench width socket/ring/ratchet",
    label: "EFI09006: Wrench width socket/ring/ratchet"
  },
  {
    featureId: "EFI09007",
    featureDesc: "Head width open end",
    label: "EFI09007: Head width open end"
  },
  {
    featureId: "EFI09008",
    featureDesc: "Head width socket/ring/ratchet",
    label: "EFI09008: Head width socket/ring/ratchet"
  },
  {
    featureId: "EFI09009",
    featureDesc: "Head height open end",
    label: "EFI09009: Head height open end"
  },
  {
    featureId: "EFI09010",
    featureDesc: "Head height socket/ring/ratchet",
    label: "EFI09010: Head height socket/ring/ratchet"
  },
  {
    featureId: "EFI09012",
    featureDesc: "Scale",
    label: "EFI09012: Scale"
  },
  {
    featureId: "EFI09013",
    featureDesc: "Cutting edge length",
    label: "EFI09013: Cutting edge length"
  },
  {
    featureId: "EFI09019",
    featureDesc: "Size bit insertion",
    label: "EFI09019: Size bit insertion"
  },
  {
    featureId: "EFI09021",
    featureDesc: "Size machine insertion",
    label: "EFI09021: Size machine insertion"
  },
  {
    featureId: "EFI09023",
    featureDesc: "Shaft width",
    label: "EFI09023: Shaft width"
  },
  {
    featureId: "EFI09024",
    featureDesc: "Shaft height",
    label: "EFI09024: Shaft height"
  },
  {
    featureId: "EFI09025",
    featureDesc: "Connection size socket side",
    label: "EFI09025: Connection size socket side"
  },
  {
    featureId: "EFI09031",
    featureDesc: "Thickness saw blade",
    label: "EFI09031: Thickness saw blade"
  },
  {
    featureId: "EFI09034",
    featureDesc: "Tooth distance",
    label: "EFI09034: Tooth distance"
  },
  {
    featureId: "EFI09039",
    featureDesc: "Diameter drill",
    label: "EFI09039: Diameter drill"
  },
  {
    featureId: "EFI09059",
    featureDesc: "Max. clamping width",
    label: "EFI09059: Max. clamping width"
  },
  {
    featureId: "EFI09072",
    featureDesc: "Length probe",
    label: "EFI09072: Length probe"
  },
  {
    featureId: "EFI09073",
    featureDesc: "Bow diameter",
    label: "EFI09073: Bow diameter"
  },
  {
    featureId: "EFI09074",
    featureDesc: "Inner width opening",
    label: "EFI09074: Inner width opening"
  },
  {
    featureId: "EFI09076",
    featureDesc: "Cutting diameter",
    label: "EFI09076: Cutting diameter"
  },
  {
    featureId: "EFI09110",
    featureDesc: "Max. load at 60° angle",
    label: "EFI09110: Max. load at 60° angle"
  },
  {
    featureId: "EFI09114",
    featureDesc: "Size opening hook",
    label: "EFI09114: Size opening hook"
  },
  {
    featureId: "EFI09119",
    featureDesc: "Master link diameter",
    label: "EFI09119: Master link diameter"
  },
  {
    featureId: "EFI09129",
    featureDesc: "Max. cartridge diameter",
    label: "EFI09129: Max. cartridge diameter"
  },
  {
    featureId: "EFI09130",
    featureDesc: "Max. cartridge length",
    label: "EFI09130: Max. cartridge length"
  },
  {
    featureId: "EFI09149",
    featureDesc: "Breaking torque",
    label: "EFI09149: Breaking torque"
  },
  {
    featureId: "EFI09170",
    featureDesc: "Material plate thickness cabinet",
    label: "EFI09170: Material plate thickness cabinet"
  },
  {
    featureId: "EFI09171",
    featureDesc: "Material plate thickness door/cover",
    label: "EFI09171: Material plate thickness door/cover"
  },
  {
    featureId: "EFI09174",
    featureDesc: "Thickness body",
    label: "EFI09174: Thickness body"
  },
  {
    featureId: "EFI09175",
    featureDesc: "Max. temperature measuring range",
    label: "EFI09175: Max. temperature measuring range"
  },
  {
    featureId: "EFI09197",
    featureDesc: "Bracket height",
    label: "EFI09197: Bracket height"
  },
  {
    featureId: "EFI09230",
    featureDesc: "Suitable for wire/chain link diameter",
    label: "EFI09230: Suitable for wire/chain link diameter"
  },
  {
    featureId: "EFI09231",
    featureDesc: "Brake holding load",
    label: "EFI09231: Brake holding load"
  },
  {
    featureId: "EFI09232",
    featureDesc: "Hauling speed",
    label: "EFI09232: Hauling speed"
  },
  {
    featureId: "EFI09233",
    featureDesc: "Reel capacity",
    label: "EFI09233: Reel capacity"
  },
  {
    featureId: "EFI09243",
    featureDesc: "Frame size according to IEC",
    label: "EFI09243: Frame size according to IEC"
  },
  {
    featureId: "EFI09262",
    featureDesc: "Throughflow capacity (min. 10 kPa)",
    label: "EFI09262: Throughflow capacity (min. 10 kPa)"
  },
  {
    featureId: "EFI09263",
    featureDesc: "Throughflow capacity (min. 15 kPa)",
    label: "EFI09263: Throughflow capacity (min. 15 kPa)"
  },
  {
    featureId: "EFI09277",
    featureDesc: "Diameter bore hole (opening)",
    label: "EFI09277: Diameter bore hole (opening)"
  },
  {
    featureId: "EFI09282",
    featureDesc: "Wireless range",
    label: "EFI09282: Wireless range"
  },
  {
    featureId: "EFI09327",
    featureDesc: "Length of the fixed connection cable",
    label: "EFI09327: Length of the fixed connection cable"
  },
  {
    featureId: "EFI09329",
    featureDesc: "Cross section of the power contacts",
    label: "EFI09329: Cross section of the power contacts"
  },
  {
    featureId: "EFI09366",
    featureDesc: "Diameter without insulation",
    label: "EFI09366: Diameter without insulation"
  },
  {
    featureId: "EFI09368",
    featureDesc: "Diameter immersion tube",
    label: "EFI09368: Diameter immersion tube"
  },
  {
    featureId: "EFI09379",
    featureDesc: "Primary supply temperature",
    label: "EFI09379: Primary supply temperature"
  },
  {
    featureId: "EFI09384",
    featureDesc: "Adjustment range hot water temperature",
    label: "EFI09384: Adjustment range hot water temperature"
  },
  {
    featureId: "EFI09468",
    featureDesc: "Diameter of coil/ring",
    label: "EFI09468: Diameter of coil/ring"
  },
  {
    featureId: "EFI09475",
    featureDesc: "For cable outer diameter",
    label: "EFI09475: For cable outer diameter"
  },
  {
    featureId: "EFI09478",
    featureDesc: "Diameter wall opening",
    label: "EFI09478: Diameter wall opening"
  },
  {
    featureId: "EFI09485",
    featureDesc: "Length of chain/band loop",
    label: "EFI09485: Length of chain/band loop"
  },
  {
    featureId: "EFI09494",
    featureDesc: "Diameter at base",
    label: "EFI09494: Diameter at base"
  },
  {
    featureId: "EFI09504",
    featureDesc: "Engaging wind speed",
    label: "EFI09504: Engaging wind speed"
  },
  {
    featureId: "EFI09505",
    featureDesc: "Shutdown wind speed",
    label: "EFI09505: Shutdown wind speed"
  },
  {
    featureId: "EFI09506",
    featureDesc: "Length of module",
    label: "EFI09506: Length of module"
  },
  {
    featureId: "EFI09507",
    featureDesc: "Width of module",
    label: "EFI09507: Width of module"
  },
  {
    featureId: "EFI09508",
    featureDesc: "Height at top-edge",
    label: "EFI09508: Height at top-edge"
  },
  {
    featureId: "EFI09515",
    featureDesc: "Punch sizes",
    label: "EFI09515: Punch sizes"
  },
  {
    featureId: "EFI09583",
    featureDesc: "Suitable for enclosure building height",
    label: "EFI09583: Suitable for enclosure building height"
  },
  {
    featureId: "EFI09603",
    featureDesc: "Ambient temperature, upper operating limit",
    label: "EFI09603: Ambient temperature, upper operating limit"
  },
  {
    featureId: "EFI09619",
    featureDesc: "Measuring range vibration acceleration (gravity g)",
    label: "EFI09619: Measuring range vibration acceleration (gravity g)"
  },
  {
    featureId: "EFI09629",
    featureDesc: "Mercury content",
    label: "EFI09629: Mercury content"
  },
  {
    featureId: "EFI09638",
    featureDesc: "Max. equivalent separation distance s (in air)",
    label: "EFI09638: Max. equivalent separation distance s (in air)"
  },
  {
    featureId: "EFI09639",
    featureDesc: "Max. equivalent separation distance s (solid building materials)",
    label: "EFI09639: Max. equivalent separation distance s (solid building materials)"
  },
  {
    featureId: "EFI09657",
    featureDesc: "Nominal cross section main conductor, SE",
    label: "EFI09657: Nominal cross section main conductor, SE"
  },
  {
    featureId: "EFI09658",
    featureDesc: "Nominal cross section main conductor, SM",
    label: "EFI09658: Nominal cross section main conductor, SM"
  },
  {
    featureId: "EFI09659",
    featureDesc: "Nominal cross section branch conductor, SM/RM",
    label: "EFI09659: Nominal cross section branch conductor, SM/RM"
  },
  {
    featureId: "EFI09660",
    featureDesc: "Nominal cross section branch conductor, SE/RE",
    label: "EFI09660: Nominal cross section branch conductor, SE/RE"
  },
  {
    featureId: "EFI09669",
    featureDesc: "Nominal cross section branch conductor, SE",
    label: "EFI09669: Nominal cross section branch conductor, SE"
  },
  {
    featureId: "EFI09670",
    featureDesc: "Nominal cross section branch conductor, RE",
    label: "EFI09670: Nominal cross section branch conductor, RE"
  },
  {
    featureId: "EFI09671",
    featureDesc: "Nominal cross section branch conductor, SM",
    label: "EFI09671: Nominal cross section branch conductor, SM"
  },
  {
    featureId: "EFI09672",
    featureDesc: "Nominal cross section branch conductor, RM",
    label: "EFI09672: Nominal cross section branch conductor, RM"
  },
  {
    featureId: "EFI09673",
    featureDesc: "Nominal cross section copper, RM",
    label: "EFI09673: Nominal cross section copper, RM"
  },
  {
    featureId: "EFI09674",
    featureDesc: "Nominal cross section copper, RE",
    label: "EFI09674: Nominal cross section copper, RE"
  },
  {
    featureId: "EFI09675",
    featureDesc: "Nominal cross section copper, SM",
    label: "EFI09675: Nominal cross section copper, SM"
  },
  {
    featureId: "EFI09676",
    featureDesc: "Nominal cross section aluminium, RM",
    label: "EFI09676: Nominal cross section aluminium, RM"
  },
  {
    featureId: "EFI09677",
    featureDesc: "Nominal cross section aluminium, RE",
    label: "EFI09677: Nominal cross section aluminium, RE"
  },
  {
    featureId: "EFI09678",
    featureDesc: "Nominal cross section aluminium, SM",
    label: "EFI09678: Nominal cross section aluminium, SM"
  },
  {
    featureId: "EFI09679",
    featureDesc: "Nominal cross section aluminium, SE",
    label: "EFI09679: Nominal cross section aluminium, SE"
  },
  {
    featureId: "EFI09683",
    featureDesc: "Nominal cross section aluminium, RM/SM",
    label: "EFI09683: Nominal cross section aluminium, RM/SM"
  },
  {
    featureId: "EFI09689",
    featureDesc: "Bending radius, dynamic",
    label: "EFI09689: Bending radius, dynamic"
  },
  {
    featureId: "EFI09690",
    featureDesc: "Connection cross section (fine-strand)",
    label: "EFI09690: Connection cross section (fine-strand)"
  },
  {
    featureId: "EFI09691",
    featureDesc: "Connection cross section (solid-core)",
    label: "EFI09691: Connection cross section (solid-core)"
  },
  {
    featureId: "EFI09692",
    featureDesc: "Max. continuous temperature resistance contact part",
    label: "EFI09692: Max. continuous temperature resistance contact part"
  },
  {
    featureId: "EFI09693",
    featureDesc: "Max. continuous temperature resistance strain relief",
    label: "EFI09693: Max. continuous temperature resistance strain relief"
  },
  {
    featureId: "EFI09695",
    featureDesc: "Length soldering pin",
    label: "EFI09695: Length soldering pin"
  },
  {
    featureId: "EFI09697",
    featureDesc: "Wall thickness housing",
    label: "EFI09697: Wall thickness housing"
  },
  {
    featureId: "EFI09698",
    featureDesc: "Max. operating temperature at conductor",
    label: "EFI09698: Max. operating temperature at conductor"
  },
  {
    featureId: "EFI09731",
    featureDesc: "Wrench width (square drive)",
    label: "EFI09731: Wrench width (square drive)"
  },
  {
    featureId: "EFI09735",
    featureDesc: "Length short leg",
    label: "EFI09735: Length short leg"
  },
  {
    featureId: "EFI09745",
    featureDesc: "Width/diameter draw-in wire",
    label: "EFI09745: Width/diameter draw-in wire"
  },
  {
    featureId: "EFI09746",
    featureDesc: "Material thickness draw-in wire",
    label: "EFI09746: Material thickness draw-in wire"
  },
  {
    featureId: "EFI09750",
    featureDesc: "Insulated neck diameter",
    label: "EFI09750: Insulated neck diameter"
  },
  {
    featureId: "EFI09759",
    featureDesc: "Max. localisation/reading depth",
    label: "EFI09759: Max. localisation/reading depth"
  },
  {
    featureId: "EFI09772",
    featureDesc: "Earthing cable length",
    label: "EFI09772: Earthing cable length"
  },
  {
    featureId: "EFI09788",
    featureDesc: "Temperature measuring range",
    label: "EFI09788: Temperature measuring range"
  },
  {
    featureId: "EFI09801",
    featureDesc: "Saw band length",
    label: "EFI09801: Saw band length"
  },
  {
    featureId: "EFI09802",
    featureDesc: "Saw band width",
    label: "EFI09802: Saw band width"
  },
  {
    featureId: "EFI09813",
    featureDesc: "Process temperature",
    label: "EFI09813: Process temperature"
  },
  {
    featureId: "EFI09826",
    featureDesc: "Measuring range volume flux",
    label: "EFI09826: Measuring range volume flux"
  },
  {
    featureId: "EFI09828",
    featureDesc: "Measuring range mass flux",
    label: "EFI09828: Measuring range mass flux"
  },
  {
    featureId: "EFI09829",
    featureDesc: "Medium density",
    label: "EFI09829: Medium density"
  },
  {
    featureId: "EFI09833",
    featureDesc: "Outer diameter thermowell top",
    label: "EFI09833: Outer diameter thermowell top"
  },
  {
    featureId: "EFI09834",
    featureDesc: "Outer diameter thermowell",
    label: "EFI09834: Outer diameter thermowell"
  },
  {
    featureId: "EFI09836",
    featureDesc: "Diameter measuring insert",
    label: "EFI09836: Diameter measuring insert"
  },
  {
    featureId: "EFI09838",
    featureDesc: "Built-in length thermowell",
    label: "EFI09838: Built-in length thermowell"
  },
  {
    featureId: "EFI09839",
    featureDesc: "Length thermowell",
    label: "EFI09839: Length thermowell"
  },
  {
    featureId: "EFI09840",
    featureDesc: "Length measuring insert",
    label: "EFI09840: Length measuring insert"
  },
  {
    featureId: "EFI09890",
    featureDesc: "Milling diameter",
    label: "EFI09890: Milling diameter"
  },
  {
    featureId: "EFI09891",
    featureDesc: "Milling depth",
    label: "EFI09891: Milling depth"
  },
  {
    featureId: "EFI09894",
    featureDesc: "Nail diameter",
    label: "EFI09894: Nail diameter"
  },
  {
    featureId: "EFI09909",
    featureDesc: "Cartridge volume",
    label: "EFI09909: Cartridge volume"
  },
  {
    featureId: "EFI09912",
    featureDesc: "Connection size at tool side",
    label: "EFI09912: Connection size at tool side"
  },
  {
    featureId: "EFI09918",
    featureDesc: "For nominal tube diameter",
    label: "EFI09918: For nominal tube diameter"
  },
  {
    featureId: "EFI09945",
    featureDesc: "Max. roller width",
    label: "EFI09945: Max. roller width"
  },
  {
    featureId: "EFI09951",
    featureDesc: "Length line",
    label: "EFI09951: Length line"
  },
  {
    featureId: "EFI09969",
    featureDesc: "Flange width",
    label: "EFI09969: Flange width"
  },
  {
    featureId: "EFI09971",
    featureDesc: "Clear opening",
    label: "EFI09971: Clear opening"
  },
  {
    featureId: "EFI09975",
    featureDesc: "Diameter connection 1",
    label: "EFI09975: Diameter connection 1"
  },
  {
    featureId: "EFI09979",
    featureDesc: "Sand reception capacity",
    label: "EFI09979: Sand reception capacity"
  },
  {
    featureId: "EFI09980",
    featureDesc: "Wet surface",
    label: "EFI09980: Wet surface"
  },
  {
    featureId: "EFI09981",
    featureDesc: "Perforated surface",
    label: "EFI09981: Perforated surface"
  },
  {
    featureId: "EFI09997",
    featureDesc: "Diameter connection 2",
    label: "EFI09997: Diameter connection 2"
  },
  {
    featureId: "EFI10005",
    featureDesc: "Height 1",
    label: "EFI10005: Height 1"
  },
  {
    featureId: "EFI10006",
    featureDesc: "Height 2",
    label: "EFI10006: Height 2"
  },
  {
    featureId: "EFI10007",
    featureDesc: "Inner height 1",
    label: "EFI10007: Inner height 1"
  },
  {
    featureId: "EFI10008",
    featureDesc: "Inner height 2",
    label: "EFI10008: Inner height 2"
  },
  {
    featureId: "EFI10015",
    featureDesc: "Hole diameter connection",
    label: "EFI10015: Hole diameter connection"
  },
  {
    featureId: "EFI10045",
    featureDesc: "Suitable for radiator length",
    label: "EFI10045: Suitable for radiator length"
  },
  {
    featureId: "EFI10049",
    featureDesc: "Outer diameter steel cable",
    label: "EFI10049: Outer diameter steel cable"
  },
  {
    featureId: "EFI10058",
    featureDesc: "Specific weight",
    label: "EFI10058: Specific weight"
  },
  {
    featureId: "EFI10060",
    featureDesc: "Distance between both sides",
    label: "EFI10060: Distance between both sides"
  },
  {
    featureId: "EFI10065",
    featureDesc: "Diameter burner head",
    label: "EFI10065: Diameter burner head"
  },
  {
    featureId: "EFI10066",
    featureDesc: "Length extension pipe",
    label: "EFI10066: Length extension pipe"
  },
  {
    featureId: "EFI10067",
    featureDesc: "Length gas hose",
    label: "EFI10067: Length gas hose"
  },
  {
    featureId: "EFI10069",
    featureDesc: "Flame temperature",
    label: "EFI10069: Flame temperature"
  },
  {
    featureId: "EFI10083",
    featureDesc: "Thermal transmittance value (U)",
    label: "EFI10083: Thermal transmittance value (U)"
  },
  {
    featureId: "EFI10084",
    featureDesc: "Insulation value edge timber (U)",
    label: "EFI10084: Insulation value edge timber (U)"
  },
  {
    featureId: "EFI10085",
    featureDesc: "Insulation value isolated part (U)",
    label: "EFI10085: Insulation value isolated part (U)"
  },
  {
    featureId: "EFI10087",
    featureDesc: "Suitable to mill into (max.)",
    label: "EFI10087: Suitable to mill into (max.)"
  },
  {
    featureId: "EFI10090",
    featureDesc: "Layer thickness primer",
    label: "EFI10090: Layer thickness primer"
  },
  {
    featureId: "EFI10091",
    featureDesc: "Layer thickness paint foil",
    label: "EFI10091: Layer thickness paint foil"
  },
  {
    featureId: "EFI10098",
    featureDesc: "Layer thickness",
    label: "EFI10098: Layer thickness"
  },
  {
    featureId: "EFI10110",
    featureDesc: "Height visible surface",
    label: "EFI10110: Height visible surface"
  },
  {
    featureId: "EFI10117",
    featureDesc: "Length tile",
    label: "EFI10117: Length tile"
  },
  {
    featureId: "EFI10118",
    featureDesc: "Width tile",
    label: "EFI10118: Width tile"
  },
  {
    featureId: "EFI10119",
    featureDesc: "Tile thickness",
    label: "EFI10119: Tile thickness"
  },
  {
    featureId: "EFI10120",
    featureDesc: "Length package",
    label: "EFI10120: Length package"
  },
  {
    featureId: "EFI10121",
    featureDesc: "Width package",
    label: "EFI10121: Width package"
  },
  {
    featureId: "EFI10123",
    featureDesc: "Bevel height",
    label: "EFI10123: Bevel height"
  },
  {
    featureId: "EFI10124",
    featureDesc: "Bevel width",
    label: "EFI10124: Bevel width"
  },
  {
    featureId: "EFI10127",
    featureDesc: "Insulation value (R)",
    label: "EFI10127: Insulation value (R)"
  },
  {
    featureId: "EFI10134",
    featureDesc: "Height front",
    label: "EFI10134: Height front"
  },
  {
    featureId: "EFI10139",
    featureDesc: "Flow width",
    label: "EFI10139: Flow width"
  },
  {
    featureId: "EFI10140",
    featureDesc: "Flow depth",
    label: "EFI10140: Flow depth"
  },
  {
    featureId: "EFI10143",
    featureDesc: "Barrier",
    label: "EFI10143: Barrier"
  },
  {
    featureId: "EFI10151",
    featureDesc: "Length stone",
    label: "EFI10151: Length stone"
  },
  {
    featureId: "EFI10152",
    featureDesc: "Width stone",
    label: "EFI10152: Width stone"
  },
  {
    featureId: "EFI10153",
    featureDesc: "Thickness stone",
    label: "EFI10153: Thickness stone"
  },
  {
    featureId: "EFI10161",
    featureDesc: "Height (excl. Fanlight)",
    label: "EFI10161: Height (excl. Fanlight)"
  },
  {
    featureId: "EFI10181",
    featureDesc: "Nominal duct width connection 1",
    label: "EFI10181: Nominal duct width connection 1"
  },
  {
    featureId: "EFI10182",
    featureDesc: "Nominal duct height connection 1",
    label: "EFI10182: Nominal duct height connection 1"
  },
  {
    featureId: "EFI10183",
    featureDesc: "Nominal duct width connection 2",
    label: "EFI10183: Nominal duct width connection 2"
  },
  {
    featureId: "EFI10184",
    featureDesc: "Nominal duct height connection 2",
    label: "EFI10184: Nominal duct height connection 2"
  },
  {
    featureId: "EFI10185",
    featureDesc: "Working length connection 6",
    label: "EFI10185: Working length connection 6"
  },
  {
    featureId: "EFI10193",
    featureDesc: "Length front plate",
    label: "EFI10193: Length front plate"
  },
  {
    featureId: "EFI10194",
    featureDesc: "Thickness front plate",
    label: "EFI10194: Thickness front plate"
  },
  {
    featureId: "EFI10200",
    featureDesc: "Depth lock case",
    label: "EFI10200: Depth lock case"
  },
  {
    featureId: "EFI10202",
    featureDesc: "Mandrel size",
    label: "EFI10202: Mandrel size"
  },
  {
    featureId: "EFI10205",
    featureDesc: "Max. vertical height",
    label: "EFI10205: Max. vertical height"
  },
  {
    featureId: "EFI10232",
    featureDesc: "Dynamic stiffness according to EN 29052-1",
    label: "EFI10232: Dynamic stiffness according to EN 29052-1"
  },
  {
    featureId: "EFI10233",
    featureDesc: "Max. moist absorption",
    label: "EFI10233: Max. moist absorption"
  },
  {
    featureId: "EFI10241",
    featureDesc: "Gutter overhang",
    label: "EFI10241: Gutter overhang"
  },
  {
    featureId: "EFI10242",
    featureDesc: "Span",
    label: "EFI10242: Span"
  },
  {
    featureId: "EFI10248",
    featureDesc: "Voluminous mass",
    label: "EFI10248: Voluminous mass"
  },
  {
    featureId: "EFI10258",
    featureDesc: "Specific mass",
    label: "EFI10258: Specific mass"
  },
  {
    featureId: "EFI10264",
    featureDesc: "Width window frame",
    label: "EFI10264: Width window frame"
  },
  {
    featureId: "EFI10265",
    featureDesc: "Height upper part window frame",
    label: "EFI10265: Height upper part window frame"
  },
  {
    featureId: "EFI10266",
    featureDesc: "Height lower part window frame",
    label: "EFI10266: Height lower part window frame"
  },
  {
    featureId: "EFI10273",
    featureDesc: "Ug-value",
    label: "EFI10273: Ug-value"
  },
  {
    featureId: "EFI10274",
    featureDesc: "Uw-value",
    label: "EFI10274: Uw-value"
  },
  {
    featureId: "EFI10277",
    featureDesc: "Air volume flow",
    label: "EFI10277: Air volume flow"
  },
  {
    featureId: "EFI10283",
    featureDesc: "Height window frame",
    label: "EFI10283: Height window frame"
  },
  {
    featureId: "EFI10284",
    featureDesc: "Max. illuminated surface",
    label: "EFI10284: Max. illuminated surface"
  },
  {
    featureId: "EFI10285",
    featureDesc: "Max. extension",
    label: "EFI10285: Max. extension"
  },
  {
    featureId: "EFI10286",
    featureDesc: "Max. thickness roofing",
    label: "EFI10286: Max. thickness roofing"
  },
  {
    featureId: "EFI10290",
    featureDesc: "Width throw-in",
    label: "EFI10290: Width throw-in"
  },
  {
    featureId: "EFI10293",
    featureDesc: "Height throw-in",
    label: "EFI10293: Height throw-in"
  },
  {
    featureId: "EFI10314",
    featureDesc: "Width base plate",
    label: "EFI10314: Width base plate"
  },
  {
    featureId: "EFI10315",
    featureDesc: "Length base plate",
    label: "EFI10315: Length base plate"
  },
  {
    featureId: "EFI10328",
    featureDesc: "Length saw blade",
    label: "EFI10328: Length saw blade"
  },
  {
    featureId: "EFI10330",
    featureDesc: "Saw depth",
    label: "EFI10330: Saw depth"
  },
  {
    featureId: "EFI10335",
    featureDesc: "Cut width",
    label: "EFI10335: Cut width"
  },
  {
    featureId: "EFI10338",
    featureDesc: "Thermal conductivity according to EN 1745 (lambda-10, dry, P=90 %)",
    label: "EFI10338: Thermal conductivity according to EN 1745 (lambda-10, dry, P=90 %)"
  },
  {
    featureId: "EFI10342",
    featureDesc: "Fall",
    label: "EFI10342: Fall"
  },
  {
    featureId: "EFI10363",
    featureDesc: "Covering width",
    label: "EFI10363: Covering width"
  },
  {
    featureId: "EFI10364",
    featureDesc: "Batten spacing",
    label: "EFI10364: Batten spacing"
  },
  {
    featureId: "EFI10373",
    featureDesc: "For gypsum board thickness",
    label: "EFI10373: For gypsum board thickness"
  },
  {
    featureId: "EFI10374",
    featureDesc: "For plaster thickness",
    label: "EFI10374: For plaster thickness"
  },
  {
    featureId: "EFI10380",
    featureDesc: "Door thickness with standard plate",
    label: "EFI10380: Door thickness with standard plate"
  },
  {
    featureId: "EFI10381",
    featureDesc: "Door thickness with SKG plate",
    label: "EFI10381: Door thickness with SKG plate"
  },
  {
    featureId: "EFI10383",
    featureDesc: "Diameter button",
    label: "EFI10383: Diameter button"
  },
  {
    featureId: "EFI10385",
    featureDesc: "Knot diameter",
    label: "EFI10385: Knot diameter"
  },
  {
    featureId: "EFI10386",
    featureDesc: "Carrying capacity per 2",
    label: "EFI10386: Carrying capacity per 2"
  },
  {
    featureId: "EFI10387",
    featureDesc: "Carrying capacity per 3",
    label: "EFI10387: Carrying capacity per 3"
  },
  {
    featureId: "EFI10388",
    featureDesc: "Carrying capacity per 4",
    label: "EFI10388: Carrying capacity per 4"
  },
  {
    featureId: "EFI10403",
    featureDesc: "Max. exterior door width",
    label: "EFI10403: Max. exterior door width"
  },
  {
    featureId: "EFI10404",
    featureDesc: "Closing speed",
    label: "EFI10404: Closing speed"
  },
  {
    featureId: "EFI10407",
    featureDesc: "Max. interior door width",
    label: "EFI10407: Max. interior door width"
  },
  {
    featureId: "EFI10411",
    featureDesc: "Thickness interior backplate",
    label: "EFI10411: Thickness interior backplate"
  },
  {
    featureId: "EFI10412",
    featureDesc: "Thickness exterior backplate",
    label: "EFI10412: Thickness exterior backplate"
  },
  {
    featureId: "EFI10439",
    featureDesc: "Proceeds",
    label: "EFI10439: Proceeds"
  },
  {
    featureId: "EFI10441",
    featureDesc: "Max. grain size",
    label: "EFI10441: Max. grain size"
  },
  {
    featureId: "EFI10449",
    featureDesc: "Layer thickness",
    label: "EFI10449: Layer thickness"
  },
  {
    featureId: "EFI10465",
    featureDesc: "Nominal duct width branch",
    label: "EFI10465: Nominal duct width branch"
  },
  {
    featureId: "EFI10466",
    featureDesc: "Nominal duct height branch",
    label: "EFI10466: Nominal duct height branch"
  },
  {
    featureId: "EFI10474",
    featureDesc: "Height knob",
    label: "EFI10474: Height knob"
  },
  {
    featureId: "EFI10475",
    featureDesc: "Width knob",
    label: "EFI10475: Width knob"
  },
  {
    featureId: "EFI10483",
    featureDesc: "Width gearbox",
    label: "EFI10483: Width gearbox"
  },
  {
    featureId: "EFI10484",
    featureDesc: "Length operating element",
    label: "EFI10484: Length operating element"
  },
  {
    featureId: "EFI10486",
    featureDesc: "Height gearbox",
    label: "EFI10486: Height gearbox"
  },
  {
    featureId: "EFI10491",
    featureDesc: "Insulation value (U)",
    label: "EFI10491: Insulation value (U)"
  },
  {
    featureId: "EFI10493",
    featureDesc: "Temperature resistance longtime",
    label: "EFI10493: Temperature resistance longtime"
  },
  {
    featureId: "EFI10494",
    featureDesc: "Temperature resistance shorttime",
    label: "EFI10494: Temperature resistance shorttime"
  },
  {
    featureId: "EFI10496",
    featureDesc: "Width upper flange",
    label: "EFI10496: Width upper flange"
  },
  {
    featureId: "EFI10497",
    featureDesc: "Width bottom flange",
    label: "EFI10497: Width bottom flange"
  },
  {
    featureId: "EFI10498",
    featureDesc: "Diameter upper thread",
    label: "EFI10498: Diameter upper thread"
  },
  {
    featureId: "EFI10499",
    featureDesc: "Diameter diagonal thread",
    label: "EFI10499: Diameter diagonal thread"
  },
  {
    featureId: "EFI10500",
    featureDesc: "Diameter under thread",
    label: "EFI10500: Diameter under thread"
  },
  {
    featureId: "EFI10506",
    featureDesc: "Width roof opening",
    label: "EFI10506: Width roof opening"
  },
  {
    featureId: "EFI10507",
    featureDesc: "Length roof opening",
    label: "EFI10507: Length roof opening"
  },
  {
    featureId: "EFI10508",
    featureDesc: "Height roof upstand",
    label: "EFI10508: Height roof upstand"
  },
  {
    featureId: "EFI10515",
    featureDesc: "Diameter hose tulle",
    label: "EFI10515: Diameter hose tulle"
  },
  {
    featureId: "EFI10530",
    featureDesc: "Diameter roof opening",
    label: "EFI10530: Diameter roof opening"
  },
  {
    featureId: "EFI10548",
    featureDesc: "Max. expansion",
    label: "EFI10548: Max. expansion"
  },
  {
    featureId: "EFI10555",
    featureDesc: "Cavity size",
    label: "EFI10555: Cavity size"
  },
  {
    featureId: "EFI10561",
    featureDesc: "Diameter tripod head",
    label: "EFI10561: Diameter tripod head"
  },
  {
    featureId: "EFI10575",
    featureDesc: "Max. temperature resistance at normal load",
    label: "EFI10575: Max. temperature resistance at normal load"
  },
  {
    featureId: "EFI10576",
    featureDesc: "Max. temperature resistance at peak load",
    label: "EFI10576: Max. temperature resistance at peak load"
  },
  {
    featureId: "EFI10577",
    featureDesc: "Suitable for max. pipe diameter",
    label: "EFI10577: Suitable for max. pipe diameter"
  },
  {
    featureId: "EFI10579",
    featureDesc: "Gap fitting",
    label: "EFI10579: Gap fitting"
  },
  {
    featureId: "EFI10580",
    featureDesc: "Press fitting",
    label: "EFI10580: Press fitting"
  },
  {
    featureId: "EFI10604",
    featureDesc: "Max. width paint roller",
    label: "EFI10604: Max. width paint roller"
  },
  {
    featureId: "EFI10608",
    featureDesc: "Diameter handle",
    label: "EFI10608: Diameter handle"
  },
  {
    featureId: "EFI10610",
    featureDesc: "Distance facade to shoring prop",
    label: "EFI10610: Distance facade to shoring prop"
  },
  {
    featureId: "EFI10613",
    featureDesc: "Working height",
    label: "EFI10613: Working height"
  },
  {
    featureId: "EFI10616",
    featureDesc: "Foot width",
    label: "EFI10616: Foot width"
  },
  {
    featureId: "EFI10617",
    featureDesc: "End bearing steel",
    label: "EFI10617: End bearing steel"
  },
  {
    featureId: "EFI10618",
    featureDesc: "End bearing masonry",
    label: "EFI10618: End bearing masonry"
  },
  {
    featureId: "EFI10619",
    featureDesc: "End bearing concrete",
    label: "EFI10619: End bearing concrete"
  },
  {
    featureId: "EFI10627",
    featureDesc: "Height under strip",
    label: "EFI10627: Height under strip"
  },
  {
    featureId: "EFI10637",
    featureDesc: "Consumption",
    label: "EFI10637: Consumption"
  },
  {
    featureId: "EFI10639",
    featureDesc: "Width below left",
    label: "EFI10639: Width below left"
  },
  {
    featureId: "EFI10640",
    featureDesc: "Width above left",
    label: "EFI10640: Width above left"
  },
  {
    featureId: "EFI10641",
    featureDesc: "Width below right",
    label: "EFI10641: Width below right"
  },
  {
    featureId: "EFI10642",
    featureDesc: "Width above right",
    label: "EFI10642: Width above right"
  },
  {
    featureId: "EFI10643",
    featureDesc: "Total height links",
    label: "EFI10643: Total height links"
  },
  {
    featureId: "EFI10644",
    featureDesc: "Total height right",
    label: "EFI10644: Total height right"
  },
  {
    featureId: "EFI10645",
    featureDesc: "Height visible surface left",
    label: "EFI10645: Height visible surface left"
  },
  {
    featureId: "EFI10646",
    featureDesc: "Height visible surface right",
    label: "EFI10646: Height visible surface right"
  },
  {
    featureId: "EFI10679",
    featureDesc: "Wall thickness, connection 5",
    label: "EFI10679: Wall thickness, connection 5"
  },
  {
    featureId: "EFI10680",
    featureDesc: "Wall thickness, connection 6",
    label: "EFI10680: Wall thickness, connection 6"
  },
  {
    featureId: "EFI10682",
    featureDesc: "Diameter handwheel",
    label: "EFI10682: Diameter handwheel"
  },
  {
    featureId: "EFI10685",
    featureDesc: "For inner pipe diameter",
    label: "EFI10685: For inner pipe diameter"
  },
  {
    featureId: "EFI10738",
    featureDesc: "Repeatability (+/-) according to ISO 9283",
    label: "EFI10738: Repeatability (+/-) according to ISO 9283"
  },
  {
    featureId: "EFI10767",
    featureDesc: "Max. shorten length",
    label: "EFI10767: Max. shorten length"
  },
  {
    featureId: "EFI10795",
    featureDesc: "Built-in height",
    label: "EFI10795: Built-in height"
  },
  {
    featureId: "EFI10797",
    featureDesc: "Nominal duct diameter, indoor connection",
    label: "EFI10797: Nominal duct diameter, indoor connection"
  },
  {
    featureId: "EFI10798",
    featureDesc: "Nominal duct diameter, outdoor connection",
    label: "EFI10798: Nominal duct diameter, outdoor connection"
  },
  {
    featureId: "EFI10799",
    featureDesc: "1st order heat loss coefficient (a1)",
    label: "EFI10799: 1st order heat loss coefficient (a1)"
  },
  {
    featureId: "EFI10800",
    featureDesc: "2nd order heat loss coefficient (a2)",
    label: "EFI10800: 2nd order heat loss coefficient (a2)"
  },
  {
    featureId: "EFI10807",
    featureDesc: "Wrench width 1",
    label: "EFI10807: Wrench width 1"
  },
  {
    featureId: "EFI10808",
    featureDesc: "Wrench width 2",
    label: "EFI10808: Wrench width 2"
  },
  {
    featureId: "EFI10815",
    featureDesc: "Max. heated space",
    label: "EFI10815: Max. heated space"
  },
  {
    featureId: "EFI10816",
    featureDesc: "Content storage tank",
    label: "EFI10816: Content storage tank"
  },
  {
    featureId: "EFI10827",
    featureDesc: "Centre distance connection",
    label: "EFI10827: Centre distance connection"
  },
  {
    featureId: "EFI10829",
    featureDesc: "Outer radius",
    label: "EFI10829: Outer radius"
  },
  {
    featureId: "EFI10835",
    featureDesc: "Width bottom side",
    label: "EFI10835: Width bottom side"
  },
  {
    featureId: "EFI10846",
    featureDesc: "Lamella distance",
    label: "EFI10846: Lamella distance"
  },
  {
    featureId: "EFI10852",
    featureDesc: "Max. label width",
    label: "EFI10852: Max. label width"
  },
  {
    featureId: "EFI10853",
    featureDesc: "Max. label length",
    label: "EFI10853: Max. label length"
  },
  {
    featureId: "EFI10871",
    featureDesc: "Flushing water quantity",
    label: "EFI10871: Flushing water quantity"
  },
  {
    featureId: "EFI10900",
    featureDesc: "Diameter floor drain",
    label: "EFI10900: Diameter floor drain"
  },
  {
    featureId: "EFI10935",
    featureDesc: "Rail length",
    label: "EFI10935: Rail length"
  },
  {
    featureId: "EFI10966",
    featureDesc: "Width grid frame",
    label: "EFI10966: Width grid frame"
  },
  {
    featureId: "EFI10967",
    featureDesc: "Length grid frame",
    label: "EFI10967: Length grid frame"
  },
  {
    featureId: "EFI10968",
    featureDesc: "Height grid frame",
    label: "EFI10968: Height grid frame"
  },
  {
    featureId: "EFI10978",
    featureDesc: "Flow radius",
    label: "EFI10978: Flow radius"
  },
  {
    featureId: "EFI11020",
    featureDesc: "Eye width",
    label: "EFI11020: Eye width"
  },
  {
    featureId: "EFI11062",
    featureDesc: "Height ground level - inner bottom side pipe",
    label: "EFI11062: Height ground level - inner bottom side pipe"
  },
  {
    featureId: "EFI11066",
    featureDesc: "Inner diameter pit floor",
    label: "EFI11066: Inner diameter pit floor"
  },
  {
    featureId: "EFI11067",
    featureDesc: "Outer diameter pit floor",
    label: "EFI11067: Outer diameter pit floor"
  },
  {
    featureId: "EFI11074",
    featureDesc: "Freezing point",
    label: "EFI11074: Freezing point"
  },
  {
    featureId: "EFI11075",
    featureDesc: "Boiling point",
    label: "EFI11075: Boiling point"
  },
  {
    featureId: "EFI11100",
    featureDesc: "Torque spring",
    label: "EFI11100: Torque spring"
  },
  {
    featureId: "EFI11103",
    featureDesc: "Size of hollow spindle",
    label: "EFI11103: Size of hollow spindle"
  },
  {
    featureId: "EFI11107",
    featureDesc: "Hollow spindle size shaft adapter",
    label: "EFI11107: Hollow spindle size shaft adapter"
  },
  {
    featureId: "EFI11121",
    featureDesc: "Weight without battery-pack",
    label: "EFI11121: Weight without battery-pack"
  },
  {
    featureId: "EFI11130",
    featureDesc: "Max. milling diameter",
    label: "EFI11130: Max. milling diameter"
  },
  {
    featureId: "EFI11131",
    featureDesc: "Milling depth adjustment",
    label: "EFI11131: Milling depth adjustment"
  },
  {
    featureId: "EFI11136",
    featureDesc: "Min. cutting length",
    label: "EFI11136: Min. cutting length"
  },
  {
    featureId: "EFI11142",
    featureDesc: "Max. bevel height steel 400 N/mm²",
    label: "EFI11142: Max. bevel height steel 400 N/mm²"
  },
  {
    featureId: "EFI11143",
    featureDesc: "Max. bevel height steel 600 N/mm²",
    label: "EFI11143: Max. bevel height steel 600 N/mm²"
  },
  {
    featureId: "EFI11144",
    featureDesc: "Max. bevel height steel 800 N/mm²",
    label: "EFI11144: Max. bevel height steel 800 N/mm²"
  },
  {
    featureId: "EFI11145",
    featureDesc: "Max. bevel height aluminium 250 N/mm²",
    label: "EFI11145: Max. bevel height aluminium 250 N/mm²"
  },
  {
    featureId: "EFI11147",
    featureDesc: "Min. inner radius",
    label: "EFI11147: Min. inner radius"
  },
  {
    featureId: "EFI11149",
    featureDesc: "Blow speed",
    label: "EFI11149: Blow speed"
  },
  {
    featureId: "EFI11157",
    featureDesc: "Max. work piece width",
    label: "EFI11157: Max. work piece width"
  },
  {
    featureId: "EFI11158",
    featureDesc: "Max. work piece diameter",
    label: "EFI11158: Max. work piece diameter"
  },
  {
    featureId: "EFI11160",
    featureDesc: "Brush width",
    label: "EFI11160: Brush width"
  },
  {
    featureId: "EFI11161",
    featureDesc: "Tool insertion",
    label: "EFI11161: Tool insertion"
  },
  {
    featureId: "EFI11162",
    featureDesc: "Drill diameter",
    label: "EFI11162: Drill diameter"
  },
  {
    featureId: "EFI11163",
    featureDesc: "Max. drill depth",
    label: "EFI11163: Max. drill depth"
  },
  {
    featureId: "EFI11166",
    featureDesc: "Diameter of oscillating circuit 1",
    label: "EFI11166: Diameter of oscillating circuit 1"
  },
  {
    featureId: "EFI11167",
    featureDesc: "Diameter of oscillating circuit 2",
    label: "EFI11167: Diameter of oscillating circuit 2"
  },
  {
    featureId: "EFI11168",
    featureDesc: "Rim height",
    label: "EFI11168: Rim height"
  },
  {
    featureId: "EFI11169",
    featureDesc: "Fold height",
    label: "EFI11169: Fold height"
  },
  {
    featureId: "EFI11175",
    featureDesc: "Max. grinding stone diameter",
    label: "EFI11175: Max. grinding stone diameter"
  },
  {
    featureId: "EFI11176",
    featureDesc: "Min. plate thickness",
    label: "EFI11176: Min. plate thickness"
  },
  {
    featureId: "EFI11179",
    featureDesc: "Rim gap",
    label: "EFI11179: Rim gap"
  },
  {
    featureId: "EFI11180",
    featureDesc: "Max. rim height",
    label: "EFI11180: Max. rim height"
  },
  {
    featureId: "EFI11188",
    featureDesc: "Fine setting",
    label: "EFI11188: Fine setting"
  },
  {
    featureId: "EFI11207",
    featureDesc: "Milling width",
    label: "EFI11207: Milling width"
  },
  {
    featureId: "EFI11209",
    featureDesc: "Rebate depth",
    label: "EFI11209: Rebate depth"
  },
  {
    featureId: "EFI11210",
    featureDesc: "Max. axial milling depth",
    label: "EFI11210: Max. axial milling depth"
  },
  {
    featureId: "EFI11211",
    featureDesc: "Max. radial milling depth",
    label: "EFI11211: Max. radial milling depth"
  },
  {
    featureId: "EFI11212",
    featureDesc: "Cutting circle diameter",
    label: "EFI11212: Cutting circle diameter"
  },
  {
    featureId: "EFI11213",
    featureDesc: "Lateral milling height",
    label: "EFI11213: Lateral milling height"
  },
  {
    featureId: "EFI11214",
    featureDesc: "Max. diameter of driver pad",
    label: "EFI11214: Max. diameter of driver pad"
  },
  {
    featureId: "EFI11217",
    featureDesc: "Max. diameter of mixing garde",
    label: "EFI11217: Max. diameter of mixing garde"
  },
  {
    featureId: "EFI11219",
    featureDesc: "Milling depth",
    label: "EFI11219: Milling depth"
  },
  {
    featureId: "EFI11233",
    featureDesc: "Max. discharge efficiency",
    label: "EFI11233: Max. discharge efficiency"
  },
  {
    featureId: "EFI11237",
    featureDesc: "Milling path across",
    label: "EFI11237: Milling path across"
  },
  {
    featureId: "EFI11238",
    featureDesc: "Milling path along",
    label: "EFI11238: Milling path along"
  },
  {
    featureId: "EFI11271",
    featureDesc: "Thickness worktop",
    label: "EFI11271: Thickness worktop"
  },
  {
    featureId: "EFI11274",
    featureDesc: "Min. contact area",
    label: "EFI11274: Min. contact area"
  },
  {
    featureId: "EFI11276",
    featureDesc: "Bandwidth",
    label: "EFI11276: Bandwidth"
  },
  {
    featureId: "EFI11279",
    featureDesc: "Container volume waste water",
    label: "EFI11279: Container volume waste water"
  },
  {
    featureId: "EFI11290",
    featureDesc: "Cutting range",
    label: "EFI11290: Cutting range"
  },
  {
    featureId: "EFI11311",
    featureDesc: "Shank diameter",
    label: "EFI11311: Shank diameter"
  },
  {
    featureId: "EFI11318",
    featureDesc: "Outer hexagon socket (AF)",
    label: "EFI11318: Outer hexagon socket (AF)"
  },
  {
    featureId: "EFI11326",
    featureDesc: "Diameter of plug-in peg",
    label: "EFI11326: Diameter of plug-in peg"
  },
  {
    featureId: "EFI11339",
    featureDesc: "Length inserted",
    label: "EFI11339: Length inserted"
  },
  {
    featureId: "EFI11340",
    featureDesc: "Extended length",
    label: "EFI11340: Extended length"
  },
  {
    featureId: "EFI11363",
    featureDesc: "Volume of dust catch container",
    label: "EFI11363: Volume of dust catch container"
  },
  {
    featureId: "EFI11364",
    featureDesc: "Head length",
    label: "EFI11364: Head length"
  },
  {
    featureId: "EFI11372",
    featureDesc: "Basket diameter",
    label: "EFI11372: Basket diameter"
  },
  {
    featureId: "EFI11385",
    featureDesc: "Countersink diameter",
    label: "EFI11385: Countersink diameter"
  },
  {
    featureId: "EFI11389",
    featureDesc: "Diameter guiding pin",
    label: "EFI11389: Diameter guiding pin"
  },
  {
    featureId: "EFI11414",
    featureDesc: "Max. loosening torque",
    label: "EFI11414: Max. loosening torque"
  },
  {
    featureId: "EFI11415",
    featureDesc: "Max. tightening torque",
    label: "EFI11415: Max. tightening torque"
  },
  {
    featureId: "EFI11456",
    featureDesc: "Inlet area per meter",
    label: "EFI11456: Inlet area per meter"
  },
  {
    featureId: "EFI11485",
    featureDesc: "Dimension free opening",
    label: "EFI11485: Dimension free opening"
  },
  {
    featureId: "EFI11502",
    featureDesc: "Volume class",
    label: "EFI11502: Volume class"
  },
  {
    featureId: "EFI11504",
    featureDesc: "Wet volume",
    label: "EFI11504: Wet volume"
  },
  {
    featureId: "EFI11533",
    featureDesc: "Volume sand trap",
    label: "EFI11533: Volume sand trap"
  },
  {
    featureId: "EFI11537",
    featureDesc: "Difference between inlet and outlet invert level",
    label: "EFI11537: Difference between inlet and outlet invert level"
  },
  {
    featureId: "EFI11544",
    featureDesc: "Nominal duct width connection 3",
    label: "EFI11544: Nominal duct width connection 3"
  },
  {
    featureId: "EFI11545",
    featureDesc: "Nominal duct height connection 3",
    label: "EFI11545: Nominal duct height connection 3"
  },
  {
    featureId: "EFI11589",
    featureDesc: "Suitable for wall thickness",
    label: "EFI11589: Suitable for wall thickness"
  },
  {
    featureId: "EFI11613",
    featureDesc: "Melting temperature",
    label: "EFI11613: Melting temperature"
  },
  {
    featureId: "EFI11644",
    featureDesc: "Width (effective dimension)",
    label: "EFI11644: Width (effective dimension)"
  },
  {
    featureId: "EFI11645",
    featureDesc: "Height (effective dimension)",
    label: "EFI11645: Height (effective dimension)"
  },
  {
    featureId: "EFI11646",
    featureDesc: "Height external dimension",
    label: "EFI11646: Height external dimension"
  },
  {
    featureId: "EFI11666",
    featureDesc: "Centre distance bolt holes",
    label: "EFI11666: Centre distance bolt holes"
  },
  {
    featureId: "EFI11715",
    featureDesc: "Raised seat",
    label: "EFI11715: Raised seat"
  },
  {
    featureId: "EFI11721",
    featureDesc: "Microphone length",
    label: "EFI11721: Microphone length"
  },
  {
    featureId: "EFI11726",
    featureDesc: "Suitable for console length",
    label: "EFI11726: Suitable for console length"
  },
  {
    featureId: "EFI11739",
    featureDesc: "Thermal sensitivity at 30 °C",
    label: "EFI11739: Thermal sensitivity at 30 °C"
  },
  {
    featureId: "EFI11751",
    featureDesc: "Outer diameter thermal insulation",
    label: "EFI11751: Outer diameter thermal insulation"
  },
  {
    featureId: "EFI11752",
    featureDesc: "Lambda value",
    label: "EFI11752: Lambda value"
  },
  {
    featureId: "EFI11769",
    featureDesc: "Suitable for rebar diameter",
    label: "EFI11769: Suitable for rebar diameter"
  },
  {
    featureId: "EFI11794",
    featureDesc: "Height carpeting",
    label: "EFI11794: Height carpeting"
  },
  {
    featureId: "EFI11801",
    featureDesc: "Min. permitted bending radius, moving application with forced guidance",
    label: "EFI11801: Min. permitted bending radius, moving application with forced guidance"
  },
  {
    featureId: "EFI11802",
    featureDesc: "Min. permitted bending radius, moving application/free movement",
    label: "EFI11802: Min. permitted bending radius, moving application/free movement"
  },
  {
    featureId: "EFI11803",
    featureDesc: "Min. permitted bending radius, stationary application/permanent installation",
    label: "EFI11803: Min. permitted bending radius, stationary application/permanent installation"
  },
  {
    featureId: "EFI11812",
    featureDesc: "Door leaf width",
    label: "EFI11812: Door leaf width"
  },
  {
    featureId: "EFI11813",
    featureDesc: "Door leaf height",
    label: "EFI11813: Door leaf height"
  },
  {
    featureId: "EFI11814",
    featureDesc: "Door leaf thickness",
    label: "EFI11814: Door leaf thickness"
  },
  {
    featureId: "EFI11817",
    featureDesc: "Doorframe width",
    label: "EFI11817: Doorframe width"
  },
  {
    featureId: "EFI11818",
    featureDesc: "Doorframe height",
    label: "EFI11818: Doorframe height"
  },
  {
    featureId: "EFI11819",
    featureDesc: "Doorframe depth",
    label: "EFI11819: Doorframe depth"
  },
  {
    featureId: "EFI11842",
    featureDesc: "Window frame depth",
    label: "EFI11842: Window frame depth"
  },
  {
    featureId: "EFI11843",
    featureDesc: "Window width",
    label: "EFI11843: Window width"
  },
  {
    featureId: "EFI11845",
    featureDesc: "Window height",
    label: "EFI11845: Window height"
  },
  {
    featureId: "EFI11883",
    featureDesc: "Volume pressure tank",
    label: "EFI11883: Volume pressure tank"
  },
  {
    featureId: "EFI11889",
    featureDesc: "Max. medium temperature (momentarily)",
    label: "EFI11889: Max. medium temperature (momentarily)"
  },
  {
    featureId: "EFI11918",
    featureDesc: "Wave band",
    label: "EFI11918: Wave band"
  },
  {
    featureId: "EFI11933",
    featureDesc: "Built-in width",
    label: "EFI11933: Built-in width"
  },
  {
    featureId: "EFI11973",
    featureDesc: "Clearance from back plate",
    label: "EFI11973: Clearance from back plate"
  },
  {
    featureId: "EFI12002",
    featureDesc: "Step width",
    label: "EFI12002: Step width"
  },
  {
    featureId: "EFI12003",
    featureDesc: "Upper truss head height",
    label: "EFI12003: Upper truss head height"
  },
  {
    featureId: "EFI12004",
    featureDesc: "Lower truss head height",
    label: "EFI12004: Lower truss head height"
  },
  {
    featureId: "EFI12005",
    featureDesc: "Inclined truss section height",
    label: "EFI12005: Inclined truss section height"
  },
  {
    featureId: "EFI12007",
    featureDesc: "Nominal speed",
    label: "EFI12007: Nominal speed"
  },
  {
    featureId: "EFI12008",
    featureDesc: "Vertical rise",
    label: "EFI12008: Vertical rise"
  },
  {
    featureId: "EFI12009",
    featureDesc: "Balustrade height",
    label: "EFI12009: Balustrade height"
  },
  {
    featureId: "EFI12012",
    featureDesc: "Balustrade extension",
    label: "EFI12012: Balustrade extension"
  },
  {
    featureId: "EFI12020",
    featureDesc: "Clear width door front side",
    label: "EFI12020: Clear width door front side"
  },
  {
    featureId: "EFI12021",
    featureDesc: "Clear height door front side",
    label: "EFI12021: Clear height door front side"
  },
  {
    featureId: "EFI12022",
    featureDesc: "Clear width door back side",
    label: "EFI12022: Clear width door back side"
  },
  {
    featureId: "EFI12023",
    featureDesc: "Clear height door back side",
    label: "EFI12023: Clear height door back side"
  },
  {
    featureId: "EFI12024",
    featureDesc: "Inner width elevator car",
    label: "EFI12024: Inner width elevator car"
  },
  {
    featureId: "EFI12025",
    featureDesc: "Inner depth elevator car",
    label: "EFI12025: Inner depth elevator car"
  },
  {
    featureId: "EFI12026",
    featureDesc: "Inner height elevator car",
    label: "EFI12026: Inner height elevator car"
  },
  {
    featureId: "EFI12067",
    featureDesc: "Outer diameter sheath single fibre",
    label: "EFI12067: Outer diameter sheath single fibre"
  },
  {
    featureId: "EFI12090",
    featureDesc: "Rated ambient temperature according to IEC 62722-2-1",
    label: "EFI12090: Rated ambient temperature according to IEC 62722-2-1"
  },
  {
    featureId: "EFI12128",
    featureDesc: "Platform height",
    label: "EFI12128: Platform height"
  },
  {
    featureId: "EFI12175",
    featureDesc: "Compression force",
    label: "EFI12175: Compression force"
  },
  {
    featureId: "EFI12187",
    featureDesc: "Diameter single fibre",
    label: "EFI12187: Diameter single fibre"
  },
  {
    featureId: "EFI12218",
    featureDesc: "Column depth",
    label: "EFI12218: Column depth"
  },
  {
    featureId: "EFI12219",
    featureDesc: "Cover depth",
    label: "EFI12219: Cover depth"
  },
  {
    featureId: "EFI12224",
    featureDesc: "Coverage volume",
    label: "EFI12224: Coverage volume"
  },
  {
    featureId: "EFI12226",
    featureDesc: "Max. infrared reach",
    label: "EFI12226: Max. infrared reach"
  },
  {
    featureId: "EFI12267",
    featureDesc: "Assembly height conductor holder",
    label: "EFI12267: Assembly height conductor holder"
  },
  {
    featureId: "EFI12268",
    featureDesc: "Suitable for width flat conductor",
    label: "EFI12268: Suitable for width flat conductor"
  },
  {
    featureId: "EFI12269",
    featureDesc: "Rod diameter",
    label: "EFI12269: Rod diameter"
  },
  {
    featureId: "EFI12272",
    featureDesc: "Diameter holding interception rod",
    label: "EFI12272: Diameter holding interception rod"
  },
  {
    featureId: "EFI12277",
    featureDesc: "Clamping range cable",
    label: "EFI12277: Clamping range cable"
  },
  {
    featureId: "EFI12278",
    featureDesc: "Min. depth of built-in installation box",
    label: "EFI12278: Min. depth of built-in installation box"
  },
  {
    featureId: "EFI12282",
    featureDesc: "Construction size",
    label: "EFI12282: Construction size"
  },
  {
    featureId: "EFI12283",
    featureDesc: "Size",
    label: "EFI12283: Size"
  },
  {
    featureId: "EFI12284",
    featureDesc: "Direct incident energy",
    label: "EFI12284: Direct incident energy"
  },
  {
    featureId: "EFI12288",
    featureDesc: "Total length",
    label: "EFI12288: Total length"
  },
  {
    featureId: "EFI12294",
    featureDesc: "Max. wire cross section",
    label: "EFI12294: Max. wire cross section"
  },
  {
    featureId: "EFI12296",
    featureDesc: "Max. wire cross section copper",
    label: "EFI12296: Max. wire cross section copper"
  },
  {
    featureId: "EFI12297",
    featureDesc: "Diameter fixed point",
    label: "EFI12297: Diameter fixed point"
  },
  {
    featureId: "EFI12311",
    featureDesc: "Length inlet hose",
    label: "EFI12311: Length inlet hose"
  },
  {
    featureId: "EFI12312",
    featureDesc: "Length outlet hose",
    label: "EFI12312: Length outlet hose"
  },
  {
    featureId: "EFI12339",
    featureDesc: "Effective anchorage depth",
    label: "EFI12339: Effective anchorage depth"
  },
  {
    featureId: "EFI12367",
    featureDesc: "Height ironing table",
    label: "EFI12367: Height ironing table"
  },
  {
    featureId: "EFI12397",
    featureDesc: "Diameter earthing pin",
    label: "EFI12397: Diameter earthing pin"
  },
  {
    featureId: "EFI12398",
    featureDesc: "Opening clearance",
    label: "EFI12398: Opening clearance"
  },
  {
    featureId: "EFI12403",
    featureDesc: "Head circumference",
    label: "EFI12403: Head circumference"
  },
  {
    featureId: "EFI12418",
    featureDesc: "Sheet length",
    label: "EFI12418: Sheet length"
  },
  {
    featureId: "EFI12419",
    featureDesc: "Sheet width",
    label: "EFI12419: Sheet width"
  },
  {
    featureId: "EFI12461",
    featureDesc: "Column width",
    label: "EFI12461: Column width"
  },
  {
    featureId: "EFI12476",
    featureDesc: "Container volume fresh water",
    label: "EFI12476: Container volume fresh water"
  },
  {
    featureId: "EFI12503",
    featureDesc: "Moment of resistance Wy",
    label: "EFI12503: Moment of resistance Wy"
  },
  {
    featureId: "EFI12504",
    featureDesc: "Moment of resistance Wz",
    label: "EFI12504: Moment of resistance Wz"
  },
  {
    featureId: "EFI12509",
    featureDesc: "Belt width",
    label: "EFI12509: Belt width"
  },
  {
    featureId: "EFI12513",
    featureDesc: "Area moment of inertia Iy",
    label: "EFI12513: Area moment of inertia Iy"
  },
  {
    featureId: "EFI12514",
    featureDesc: "Area of inertia Iz",
    label: "EFI12514: Area of inertia Iz"
  },
  {
    featureId: "EFI12523",
    featureDesc: "Diameter round conductor 1",
    label: "EFI12523: Diameter round conductor 1"
  },
  {
    featureId: "EFI12524",
    featureDesc: "Width flat conductor 1",
    label: "EFI12524: Width flat conductor 1"
  },
  {
    featureId: "EFI12525",
    featureDesc: "Diameter round conductor 2",
    label: "EFI12525: Diameter round conductor 2"
  },
  {
    featureId: "EFI12526",
    featureDesc: "Width flat conductor 2",
    label: "EFI12526: Width flat conductor 2"
  },
  {
    featureId: "EFI12535",
    featureDesc: "Diameter earthing connection",
    label: "EFI12535: Diameter earthing connection"
  },
  {
    featureId: "EFI12544",
    featureDesc: "Vibration according to ISO 866211",
    label: "EFI12544: Vibration according to ISO 866211"
  },
  {
    featureId: "EFI12546",
    featureDesc: "Vibration according to EN 60745",
    label: "EFI12546: Vibration according to EN 60745"
  },
  {
    featureId: "EFI12569",
    featureDesc: "Max. presence range",
    label: "EFI12569: Max. presence range"
  },
  {
    featureId: "EFI12590",
    featureDesc: "Diameter fibre bundle",
    label: "EFI12590: Diameter fibre bundle"
  },
  {
    featureId: "EFI12647",
    featureDesc: "Moment of resistance Wx",
    label: "EFI12647: Moment of resistance Wx"
  },
  {
    featureId: "EFI12648",
    featureDesc: "For frame height",
    label: "EFI12648: For frame height"
  },
  {
    featureId: "EFI12649",
    featureDesc: "Hook width",
    label: "EFI12649: Hook width"
  },
  {
    featureId: "EFI12650",
    featureDesc: "Material thickness hook",
    label: "EFI12650: Material thickness hook"
  },
  {
    featureId: "EFI12720",
    featureDesc: "Length bottom side",
    label: "EFI12720: Length bottom side"
  },
  {
    featureId: "EFI12721",
    featureDesc: "Diameter bottom side",
    label: "EFI12721: Diameter bottom side"
  },
  {
    featureId: "EFI12722",
    featureDesc: "Length top side",
    label: "EFI12722: Length top side"
  },
  {
    featureId: "EFI12723",
    featureDesc: "Width top side",
    label: "EFI12723: Width top side"
  },
  {
    featureId: "EFI12724",
    featureDesc: "Diameter top side",
    label: "EFI12724: Diameter top side"
  },
  {
    featureId: "EFI12725",
    featureDesc: "Suitable for radiator depth",
    label: "EFI12725: Suitable for radiator depth"
  },
  {
    featureId: "EFI12726",
    featureDesc: "Compressive strength short-term load",
    label: "EFI12726: Compressive strength short-term load"
  },
  {
    featureId: "EFI12727",
    featureDesc: "Compressive strength long-term load",
    label: "EFI12727: Compressive strength long-term load"
  },
  {
    featureId: "EFI12730",
    featureDesc: "Dimensional thermal conductivity in moisture protected building part",
    label: "EFI12730: Dimensional thermal conductivity in moisture protected building part"
  },
  {
    featureId: "EFI12731",
    featureDesc: "Dimensional thermal conductivity on external wall, towards ground",
    label: "EFI12731: Dimensional thermal conductivity on external wall, towards ground"
  },
  {
    featureId: "EFI12732",
    featureDesc: "Dimensional thermal conductivity horizontally in the ground",
    label: "EFI12732: Dimensional thermal conductivity horizontally in the ground"
  },
  {
    featureId: "EFI12742",
    featureDesc: "Drop test height",
    label: "EFI12742: Drop test height"
  },
  {
    featureId: "EFI12752",
    featureDesc: "Max. bearing capacity",
    label: "EFI12752: Max. bearing capacity"
  },
  {
    featureId: "EFI12753",
    featureDesc: "Max. tap capacity (at 300 kPa)",
    label: "EFI12753: Max. tap capacity (at 300 kPa)"
  },
  {
    featureId: "EFI12757",
    featureDesc: "Height opening",
    label: "EFI12757: Height opening"
  },
  {
    featureId: "EFI12758",
    featureDesc: "Width opening",
    label: "EFI12758: Width opening"
  },
  {
    featureId: "EFI12774",
    featureDesc: "For knuckle diameter",
    label: "EFI12774: For knuckle diameter"
  },
  {
    featureId: "EFI12788",
    featureDesc: "Suitable for sliding rod diameter",
    label: "EFI12788: Suitable for sliding rod diameter"
  },
  {
    featureId: "EFI12790",
    featureDesc: "Width sealing profile",
    label: "EFI12790: Width sealing profile"
  },
  {
    featureId: "EFI12791",
    featureDesc: "Height sealing profile",
    label: "EFI12791: Height sealing profile"
  },
  {
    featureId: "EFI12804",
    featureDesc: "Distance deadbolt to recess of latch",
    label: "EFI12804: Distance deadbolt to recess of latch"
  },
  {
    featureId: "EFI12810",
    featureDesc: "Leaf width recommended to",
    label: "EFI12810: Leaf width recommended to"
  },
  {
    featureId: "EFI12811",
    featureDesc: "Leaf weight recommended to",
    label: "EFI12811: Leaf weight recommended to"
  },
  {
    featureId: "EFI12812",
    featureDesc: "Min. leaf thickness",
    label: "EFI12812: Min. leaf thickness"
  },
  {
    featureId: "EFI12815",
    featureDesc: "Cement box adjustment range length",
    label: "EFI12815: Cement box adjustment range length"
  },
  {
    featureId: "EFI12816",
    featureDesc: "Cement box adjustment range width",
    label: "EFI12816: Cement box adjustment range width"
  },
  {
    featureId: "EFI12817",
    featureDesc: "Cement box adjustment range height",
    label: "EFI12817: Cement box adjustment range height"
  },
  {
    featureId: "EFI12848",
    featureDesc: "Assembly height",
    label: "EFI12848: Assembly height"
  },
  {
    featureId: "EFI12861",
    featureDesc: "Axis extension",
    label: "EFI12861: Axis extension"
  },
  {
    featureId: "EFI12864",
    featureDesc: "Min. hinge distance",
    label: "EFI12864: Min. hinge distance"
  },
  {
    featureId: "EFI12865",
    featureDesc: "Min. width of fixed leaf",
    label: "EFI12865: Min. width of fixed leaf"
  },
  {
    featureId: "EFI12866",
    featureDesc: "Rebate space for guide rail",
    label: "EFI12866: Rebate space for guide rail"
  },
  {
    featureId: "EFI12877",
    featureDesc: "Max. leaf protrusion",
    label: "EFI12877: Max. leaf protrusion"
  },
  {
    featureId: "EFI12897",
    featureDesc: "Groove width",
    label: "EFI12897: Groove width"
  },
  {
    featureId: "EFI12898",
    featureDesc: "Groove height",
    label: "EFI12898: Groove height"
  },
  {
    featureId: "EFI12901",
    featureDesc: "Sealing height",
    label: "EFI12901: Sealing height"
  },
  {
    featureId: "EFI12904",
    featureDesc: "Square rod length",
    label: "EFI12904: Square rod length"
  },
  {
    featureId: "EFI12921",
    featureDesc: "Height of outer backplate/escutcheon",
    label: "EFI12921: Height of outer backplate/escutcheon"
  },
  {
    featureId: "EFI12922",
    featureDesc: "Width of outer backplate/escutcheon",
    label: "EFI12922: Width of outer backplate/escutcheon"
  },
  {
    featureId: "EFI12923",
    featureDesc: "Thickness of outer backplate/escutcheon",
    label: "EFI12923: Thickness of outer backplate/escutcheon"
  },
  {
    featureId: "EFI12940",
    featureDesc: "Height backplate",
    label: "EFI12940: Height backplate"
  },
  {
    featureId: "EFI12941",
    featureDesc: "Width backplate",
    label: "EFI12941: Width backplate"
  },
  {
    featureId: "EFI12950",
    featureDesc: "Deadbolt throw (size)",
    label: "EFI12950: Deadbolt throw (size)"
  },
  {
    featureId: "EFI12963",
    featureDesc: "Latch throw",
    label: "EFI12963: Latch throw"
  },
  {
    featureId: "EFI12971",
    featureDesc: "Width forend",
    label: "EFI12971: Width forend"
  },
  {
    featureId: "EFI12973",
    featureDesc: "Thickness forend",
    label: "EFI12973: Thickness forend"
  },
  {
    featureId: "EFI12974",
    featureDesc: "Length forend",
    label: "EFI12974: Length forend"
  },
  {
    featureId: "EFI13051",
    featureDesc: "Max. weight of door for 2 hinges",
    label: "EFI13051: Max. weight of door for 2 hinges"
  },
  {
    featureId: "EFI13054",
    featureDesc: "Length roller",
    label: "EFI13054: Length roller"
  },
  {
    featureId: "EFI13068",
    featureDesc: "Max. weight of door leaf",
    label: "EFI13068: Max. weight of door leaf"
  },
  {
    featureId: "EFI13069",
    featureDesc: "Max. thickness of door leaf",
    label: "EFI13069: Max. thickness of door leaf"
  },
  {
    featureId: "EFI13075",
    featureDesc: "Suitable for glass thickness",
    label: "EFI13075: Suitable for glass thickness"
  },
  {
    featureId: "EFI13096",
    featureDesc: "Drilling dimension",
    label: "EFI13096: Drilling dimension"
  },
  {
    featureId: "EFI13097",
    featureDesc: "Height of flange",
    label: "EFI13097: Height of flange"
  },
  {
    featureId: "EFI13100",
    featureDesc: "Integrated axis extension",
    label: "EFI13100: Integrated axis extension"
  },
  {
    featureId: "EFI13106",
    featureDesc: "Conversion from",
    label: "EFI13106: Conversion from"
  },
  {
    featureId: "EFI13107",
    featureDesc: "Conversion to",
    label: "EFI13107: Conversion to"
  },
  {
    featureId: "EFI13109",
    featureDesc: "Length of stepped part",
    label: "EFI13109: Length of stepped part"
  },
  {
    featureId: "EFI13145",
    featureDesc: "Outer construction length",
    label: "EFI13145: Outer construction length"
  },
  {
    featureId: "EFI13146",
    featureDesc: "Inner construction length",
    label: "EFI13146: Inner construction length"
  },
  {
    featureId: "EFI13164",
    featureDesc: "Max. leaf height",
    label: "EFI13164: Max. leaf height"
  },
  {
    featureId: "EFI13166",
    featureDesc: "Height of cutout",
    label: "EFI13166: Height of cutout"
  },
  {
    featureId: "EFI13167",
    featureDesc: "Width of cutout",
    label: "EFI13167: Width of cutout"
  },
  {
    featureId: "EFI13168",
    featureDesc: "Air passage",
    label: "EFI13168: Air passage"
  },
  {
    featureId: "EFI13184",
    featureDesc: "Oarlock length",
    label: "EFI13184: Oarlock length"
  },
  {
    featureId: "EFI13185",
    featureDesc: "Oarlock distance",
    label: "EFI13185: Oarlock distance"
  },
  {
    featureId: "EFI13193",
    featureDesc: "Length of running rail",
    label: "EFI13193: Length of running rail"
  },
  {
    featureId: "EFI13195",
    featureDesc: "Length of guide rail",
    label: "EFI13195: Length of guide rail"
  },
  {
    featureId: "EFI13202",
    featureDesc: "Min. door leaf width",
    label: "EFI13202: Min. door leaf width"
  },
  {
    featureId: "EFI13216",
    featureDesc: "Width of centre strike patch",
    label: "EFI13216: Width of centre strike patch"
  },
  {
    featureId: "EFI13217",
    featureDesc: "Height of centre strike patch",
    label: "EFI13217: Height of centre strike patch"
  },
  {
    featureId: "EFI13218",
    featureDesc: "Depth of centre strike patch",
    label: "EFI13218: Depth of centre strike patch"
  },
  {
    featureId: "EFI13221",
    featureDesc: "Depth of hinge",
    label: "EFI13221: Depth of hinge"
  },
  {
    featureId: "EFI13222",
    featureDesc: "Height of hinge",
    label: "EFI13222: Height of hinge"
  },
  {
    featureId: "EFI13229",
    featureDesc: "Door handle length",
    label: "EFI13229: Door handle length"
  },
  {
    featureId: "EFI13230",
    featureDesc: "Door handle depth",
    label: "EFI13230: Door handle depth"
  },
  {
    featureId: "EFI13239",
    featureDesc: "Screw length",
    label: "EFI13239: Screw length"
  },
  {
    featureId: "EFI13246",
    featureDesc: "Fan diameter",
    label: "EFI13246: Fan diameter"
  },
  {
    featureId: "EFI13251",
    featureDesc: "Gap size",
    label: "EFI13251: Gap size"
  },
  {
    featureId: "EFI13252",
    featureDesc: "Max. fold thickness",
    label: "EFI13252: Max. fold thickness"
  },
  {
    featureId: "EFI13257",
    featureDesc: "Max. door leaf width",
    label: "EFI13257: Max. door leaf width"
  },
  {
    featureId: "EFI13265",
    featureDesc: "Suction gas temperature",
    label: "EFI13265: Suction gas temperature"
  },
  {
    featureId: "EFI13268",
    featureDesc: "Discharge of bolt",
    label: "EFI13268: Discharge of bolt"
  },
  {
    featureId: "EFI13269",
    featureDesc: "Bolt width",
    label: "EFI13269: Bolt width"
  },
  {
    featureId: "EFI13271",
    featureDesc: "Loop width",
    label: "EFI13271: Loop width"
  },
  {
    featureId: "EFI13272",
    featureDesc: "Loop length",
    label: "EFI13272: Loop length"
  },
  {
    featureId: "EFI13273",
    featureDesc: "Diameter cylinder",
    label: "EFI13273: Diameter cylinder"
  },
  {
    featureId: "EFI13281",
    featureDesc: "Lock thickness",
    label: "EFI13281: Lock thickness"
  },
  {
    featureId: "EFI13298",
    featureDesc: "Axial dimension",
    label: "EFI13298: Axial dimension"
  },
  {
    featureId: "EFI13303",
    featureDesc: "Width of handle bar",
    label: "EFI13303: Width of handle bar"
  },
  {
    featureId: "EFI13304",
    featureDesc: "Thickness of handle bar",
    label: "EFI13304: Thickness of handle bar"
  },
  {
    featureId: "EFI13335",
    featureDesc: "Holding force",
    label: "EFI13335: Holding force"
  },
  {
    featureId: "EFI13355",
    featureDesc: "Drill depth",
    label: "EFI13355: Drill depth"
  },
  {
    featureId: "EFI13356",
    featureDesc: "Groove depth",
    label: "EFI13356: Groove depth"
  },
  {
    featureId: "EFI13357",
    featureDesc: "Length of hollow",
    label: "EFI13357: Length of hollow"
  },
  {
    featureId: "EFI13358",
    featureDesc: "Width of hollow",
    label: "EFI13358: Width of hollow"
  },
  {
    featureId: "EFI13364",
    featureDesc: "Width door cut-out",
    label: "EFI13364: Width door cut-out"
  },
  {
    featureId: "EFI13365",
    featureDesc: "Height door cut-out",
    label: "EFI13365: Height door cut-out"
  },
  {
    featureId: "EFI13367",
    featureDesc: "Width of case",
    label: "EFI13367: Width of case"
  },
  {
    featureId: "EFI13368",
    featureDesc: "Depth of case",
    label: "EFI13368: Depth of case"
  },
  {
    featureId: "EFI13369",
    featureDesc: "Box height",
    label: "EFI13369: Box height"
  },
  {
    featureId: "EFI13370",
    featureDesc: "Volume of case",
    label: "EFI13370: Volume of case"
  },
  {
    featureId: "EFI13383",
    featureDesc: "For max. crack of the door",
    label: "EFI13383: For max. crack of the door"
  },
  {
    featureId: "EFI13400",
    featureDesc: "Depth of tunnel",
    label: "EFI13400: Depth of tunnel"
  },
  {
    featureId: "EFI13401",
    featureDesc: "Width of flap",
    label: "EFI13401: Width of flap"
  },
  {
    featureId: "EFI13402",
    featureDesc: "Height of flap",
    label: "EFI13402: Height of flap"
  },
  {
    featureId: "EFI13409",
    featureDesc: "Pull-off force",
    label: "EFI13409: Pull-off force"
  },
  {
    featureId: "EFI13442",
    featureDesc: "Nominal equivalent cross section of reinforcement",
    label: "EFI13442: Nominal equivalent cross section of reinforcement"
  },
  {
    featureId: "EFI13444",
    featureDesc: "Min. upper door frame gap",
    label: "EFI13444: Min. upper door frame gap"
  },
  {
    featureId: "EFI13446",
    featureDesc: "Max. leaf weight",
    label: "EFI13446: Max. leaf weight"
  },
  {
    featureId: "EFI13467",
    featureDesc: "Length of strike plate",
    label: "EFI13467: Length of strike plate"
  },
  {
    featureId: "EFI13468",
    featureDesc: "Width of strike plate",
    label: "EFI13468: Width of strike plate"
  },
  {
    featureId: "EFI13469",
    featureDesc: "Thickness of strike plate",
    label: "EFI13469: Thickness of strike plate"
  },
  {
    featureId: "EFI13496",
    featureDesc: "Condensation temperature",
    label: "EFI13496: Condensation temperature"
  },
  {
    featureId: "EFI13499",
    featureDesc: "Oil content",
    label: "EFI13499: Oil content"
  },
  {
    featureId: "EFI13500",
    featureDesc: "Outer pipe diameter delivery side",
    label: "EFI13500: Outer pipe diameter delivery side"
  },
  {
    featureId: "EFI13540",
    featureDesc: "Min. processing temperature",
    label: "EFI13540: Min. processing temperature"
  },
  {
    featureId: "EFI13545",
    featureDesc: "Recommended consumption even surface",
    label: "EFI13545: Recommended consumption even surface"
  },
  {
    featureId: "EFI13546",
    featureDesc: "Recommended consumption uneven surface",
    label: "EFI13546: Recommended consumption uneven surface"
  },
  {
    featureId: "EFI13551",
    featureDesc: "Conductor resistance at 20 °C",
    label: "EFI13551: Conductor resistance at 20 °C"
  },
  {
    featureId: "EFI13566",
    featureDesc: "Max. total length",
    label: "EFI13566: Max. total length"
  },
  {
    featureId: "EFI13609",
    featureDesc: "Min. mounting height (exhaust height)",
    label: "EFI13609: Min. mounting height (exhaust height)"
  },
  {
    featureId: "EFI13613",
    featureDesc: "Thickness including studs",
    label: "EFI13613: Thickness including studs"
  },
  {
    featureId: "EFI13615",
    featureDesc: "Foil thickness",
    label: "EFI13615: Foil thickness"
  },
  {
    featureId: "EFI13623",
    featureDesc: "Length to bottom side head",
    label: "EFI13623: Length to bottom side head"
  },
  {
    featureId: "EFI13642",
    featureDesc: "Fibre length input",
    label: "EFI13642: Fibre length input"
  },
  {
    featureId: "EFI13643",
    featureDesc: "Fibre length output",
    label: "EFI13643: Fibre length output"
  },
  {
    featureId: "EFI13650",
    featureDesc: "Diameter camera probe",
    label: "EFI13650: Diameter camera probe"
  },
  {
    featureId: "EFI13660",
    featureDesc: "Max. operating temperature output system",
    label: "EFI13660: Max. operating temperature output system"
  },
  {
    featureId: "EFI13661",
    featureDesc: "Max. flue gas temperature",
    label: "EFI13661: Max. flue gas temperature"
  },
  {
    featureId: "EFI13672",
    featureDesc: "Anchor clip length",
    label: "EFI13672: Anchor clip length"
  },
  {
    featureId: "EFI13691",
    featureDesc: "Max. inlay depth",
    label: "EFI13691: Max. inlay depth"
  },
  {
    featureId: "EFI13692",
    featureDesc: "Suitable for staple width",
    label: "EFI13692: Suitable for staple width"
  },
  {
    featureId: "EFI13693",
    featureDesc: "Suitable for staple height",
    label: "EFI13693: Suitable for staple height"
  },
  {
    featureId: "EFI13704",
    featureDesc: "Pattern repeat length",
    label: "EFI13704: Pattern repeat length"
  },
  {
    featureId: "EFI13711",
    featureDesc: "Suitable for pipe length",
    label: "EFI13711: Suitable for pipe length"
  },
  {
    featureId: "EFI13732",
    featureDesc: "Reference groove width",
    label: "EFI13732: Reference groove width"
  },
  {
    featureId: "EFI13733",
    featureDesc: "Distance between grooves",
    label: "EFI13733: Distance between grooves"
  },
  {
    featureId: "EFI13739",
    featureDesc: "Material weight",
    label: "EFI13739: Material weight"
  },
  {
    featureId: "EFI13750",
    featureDesc: "Outer pipe diameter connection 1",
    label: "EFI13750: Outer pipe diameter connection 1"
  },
  {
    featureId: "EFI13752",
    featureDesc: "Outer pipe diameter connection 2",
    label: "EFI13752: Outer pipe diameter connection 2"
  },
  {
    featureId: "EFI13753",
    featureDesc: "Min. wall thickness",
    label: "EFI13753: Min. wall thickness"
  },
  {
    featureId: "EFI13754",
    featureDesc: "Diameter outer neck",
    label: "EFI13754: Diameter outer neck"
  },
  {
    featureId: "EFI13778",
    featureDesc: "Charging temperature",
    label: "EFI13778: Charging temperature"
  },
  {
    featureId: "EFI13779",
    featureDesc: "Temperature of discharge",
    label: "EFI13779: Temperature of discharge"
  },
  {
    featureId: "EFI13785",
    featureDesc: "Max. emission heat",
    label: "EFI13785: Max. emission heat"
  },
  {
    featureId: "EFI13787",
    featureDesc: "Max. laminating width",
    label: "EFI13787: Max. laminating width"
  },
  {
    featureId: "EFI13797",
    featureDesc: "Max. heat protection",
    label: "EFI13797: Max. heat protection"
  },
  {
    featureId: "EFI13800",
    featureDesc: "Jaw height",
    label: "EFI13800: Jaw height"
  },
  {
    featureId: "EFI13801",
    featureDesc: "Max. tensile strength during installation",
    label: "EFI13801: Max. tensile strength during installation"
  },
  {
    featureId: "EFI13805",
    featureDesc: "Bridge length",
    label: "EFI13805: Bridge length"
  },
  {
    featureId: "EFI13806",
    featureDesc: "Bracket depth",
    label: "EFI13806: Bracket depth"
  },
  {
    featureId: "EFI13807",
    featureDesc: "Outer ring diameter",
    label: "EFI13807: Outer ring diameter"
  },
  {
    featureId: "EFI13809",
    featureDesc: "Diameter of chuck shank",
    label: "EFI13809: Diameter of chuck shank"
  },
  {
    featureId: "EFI13810",
    featureDesc: "Reading",
    label: "EFI13810: Reading"
  },
  {
    featureId: "EFI13811",
    featureDesc: "Max. error",
    label: "EFI13811: Max. error"
  },
  {
    featureId: "EFI13833",
    featureDesc: "Refrigerant pre-charge",
    label: "EFI13833: Refrigerant pre-charge"
  },
  {
    featureId: "EFI13873",
    featureDesc: "Max. cutting speed",
    label: "EFI13873: Max. cutting speed"
  },
  {
    featureId: "EFI13880",
    featureDesc: "Thickness of wear layer",
    label: "EFI13880: Thickness of wear layer"
  },
  {
    featureId: "EFI13881",
    featureDesc: "Thermal conductivity according to EN 12664",
    label: "EFI13881: Thermal conductivity according to EN 12664"
  },
  {
    featureId: "EFI13911",
    featureDesc: "Volume fireplace",
    label: "EFI13911: Volume fireplace"
  },
  {
    featureId: "EFI13912",
    featureDesc: "Max. firewood length",
    label: "EFI13912: Max. firewood length"
  },
  {
    featureId: "EFI13919",
    featureDesc: "Max. throw horizontal",
    label: "EFI13919: Max. throw horizontal"
  },
  {
    featureId: "EFI13920",
    featureDesc: "Max. throw vertical",
    label: "EFI13920: Max. throw vertical"
  },
  {
    featureId: "EFI13921",
    featureDesc: "Min. mounting height (horizontal air distribution)",
    label: "EFI13921: Min. mounting height (horizontal air distribution)"
  },
  {
    featureId: "EFI13922",
    featureDesc: "Min. mounting height (vertical air distribution)",
    label: "EFI13922: Min. mounting height (vertical air distribution)"
  },
  {
    featureId: "EFI13923",
    featureDesc: "Gas consumption low-calorific natural gas (G25)",
    label: "EFI13923: Gas consumption low-calorific natural gas (G25)"
  },
  {
    featureId: "EFI13924",
    featureDesc: "Gas consumption propane (G31)",
    label: "EFI13924: Gas consumption propane (G31)"
  },
  {
    featureId: "EFI13927",
    featureDesc: "Inner diameter pipe sleeve",
    label: "EFI13927: Inner diameter pipe sleeve"
  },
  {
    featureId: "EFI13928",
    featureDesc: "Weight including filling",
    label: "EFI13928: Weight including filling"
  },
  {
    featureId: "EFI13929",
    featureDesc: "Gas consumption high-calorific natural gas (G20)",
    label: "EFI13929: Gas consumption high-calorific natural gas (G20)"
  },
  {
    featureId: "EFI13953",
    featureDesc: "Outer pipe diameter connection 3",
    label: "EFI13953: Outer pipe diameter connection 3"
  },
  {
    featureId: "EFI13959",
    featureDesc: "Equivalent duct diameter, connection 3",
    label: "EFI13959: Equivalent duct diameter, connection 3"
  },
  {
    featureId: "EFI13960",
    featureDesc: "Equivalent duct diameter, connection 4",
    label: "EFI13960: Equivalent duct diameter, connection 4"
  },
  {
    featureId: "EFI13961",
    featureDesc: "Nominal duct width connection 4",
    label: "EFI13961: Nominal duct width connection 4"
  },
  {
    featureId: "EFI13962",
    featureDesc: "Nominal duct height connection 4",
    label: "EFI13962: Nominal duct height connection 4"
  },
  {
    featureId: "EFI13981",
    featureDesc: "Suitable for inner diameter",
    label: "EFI13981: Suitable for inner diameter"
  },
  {
    featureId: "EFI13993",
    featureDesc: "Equivalent duct diameter, branches",
    label: "EFI13993: Equivalent duct diameter, branches"
  },
  {
    featureId: "EFI13994",
    featureDesc: "Nominal duct height branches",
    label: "EFI13994: Nominal duct height branches"
  },
  {
    featureId: "EFI13995",
    featureDesc: "Nominal duct width branches",
    label: "EFI13995: Nominal duct width branches"
  },
  {
    featureId: "EFI14010",
    featureDesc: "Filter grade",
    label: "EFI14010: Filter grade"
  },
  {
    featureId: "EFI14016",
    featureDesc: "Wall thickness duct",
    label: "EFI14016: Wall thickness duct"
  },
  {
    featureId: "EFI14019",
    featureDesc: "Equivalent diameter air passage",
    label: "EFI14019: Equivalent diameter air passage"
  },
  {
    featureId: "EFI14020",
    featureDesc: "Diameter air passage",
    label: "EFI14020: Diameter air passage"
  },
  {
    featureId: "EFI14081",
    featureDesc: "Min. bending radius without tools",
    label: "EFI14081: Min. bending radius without tools"
  },
  {
    featureId: "EFI14109",
    featureDesc: "Outer width rectangular insulation",
    label: "EFI14109: Outer width rectangular insulation"
  },
  {
    featureId: "EFI14157",
    featureDesc: "Visible surface horizontal",
    label: "EFI14157: Visible surface horizontal"
  },
  {
    featureId: "EFI14158",
    featureDesc: "Visible surface vertical",
    label: "EFI14158: Visible surface vertical"
  },
  {
    featureId: "EFI14170",
    featureDesc: "Suitable for water meter length",
    label: "EFI14170: Suitable for water meter length"
  },
  {
    featureId: "EFI14173",
    featureDesc: "Passage width",
    label: "EFI14173: Passage width"
  },
  {
    featureId: "EFI14174",
    featureDesc: "Passage height",
    label: "EFI14174: Passage height"
  },
  {
    featureId: "EFI14177",
    featureDesc: "Cleaning performance per battery charge",
    label: "EFI14177: Cleaning performance per battery charge"
  },
  {
    featureId: "EFI14186",
    featureDesc: "Impact weight",
    label: "EFI14186: Impact weight"
  },
  {
    featureId: "EFI14192",
    featureDesc: "Width of foil",
    label: "EFI14192: Width of foil"
  },
  {
    featureId: "EFI14193",
    featureDesc: "Length of foil",
    label: "EFI14193: Length of foil"
  },
  {
    featureId: "EFI14195",
    featureDesc: "Table height",
    label: "EFI14195: Table height"
  },
  {
    featureId: "EFI14196",
    featureDesc: "Return speed",
    label: "EFI14196: Return speed"
  },
  {
    featureId: "EFI14197",
    featureDesc: "Split force",
    label: "EFI14197: Split force"
  },
  {
    featureId: "EFI14198",
    featureDesc: "Max. log length",
    label: "EFI14198: Max. log length"
  },
  {
    featureId: "EFI14199",
    featureDesc: "Max. log diameter",
    label: "EFI14199: Max. log diameter"
  },
  {
    featureId: "EFI14200",
    featureDesc: "Forward speed",
    label: "EFI14200: Forward speed"
  },
  {
    featureId: "EFI14202",
    featureDesc: "Edge height",
    label: "EFI14202: Edge height"
  },
  {
    featureId: "EFI14204",
    featureDesc: "Melting temperature",
    label: "EFI14204: Melting temperature"
  },
  {
    featureId: "EFI14215",
    featureDesc: "Material thickness",
    label: "EFI14215: Material thickness"
  },
  {
    featureId: "EFI14220",
    featureDesc: "Measuring range absolute air humidity",
    label: "EFI14220: Measuring range absolute air humidity"
  },
  {
    featureId: "EFI14221",
    featureDesc: "Enthalpy measuring range",
    label: "EFI14221: Enthalpy measuring range"
  },
  {
    featureId: "EFI14223",
    featureDesc: "Particulate matter measuring range (PM)",
    label: "EFI14223: Particulate matter measuring range (PM)"
  },
  {
    featureId: "EFI14225",
    featureDesc: "Wheel gap",
    label: "EFI14225: Wheel gap"
  },
  {
    featureId: "EFI14226",
    featureDesc: "Operating range",
    label: "EFI14226: Operating range"
  },
  {
    featureId: "EFI14227",
    featureDesc: "Wrench width 3",
    label: "EFI14227: Wrench width 3"
  },
  {
    featureId: "EFI14228",
    featureDesc: "Wrench width 4",
    label: "EFI14228: Wrench width 4"
  },
  {
    featureId: "EFI14230",
    featureDesc: "Scope of application, flow speed medium",
    label: "EFI14230: Scope of application, flow speed medium"
  },
  {
    featureId: "EFI14233",
    featureDesc: "Spray volume",
    label: "EFI14233: Spray volume"
  },
  {
    featureId: "EFI14237",
    featureDesc: "Working width",
    label: "EFI14237: Working width"
  },
  {
    featureId: "EFI14238",
    featureDesc: "Cylinder brush length",
    label: "EFI14238: Cylinder brush length"
  },
  {
    featureId: "EFI14239",
    featureDesc: "Suction nozzle width",
    label: "EFI14239: Suction nozzle width"
  },
  {
    featureId: "EFI14240",
    featureDesc: "Operating speed",
    label: "EFI14240: Operating speed"
  },
  {
    featureId: "EFI14241",
    featureDesc: "Area efficiency",
    label: "EFI14241: Area efficiency"
  },
  {
    featureId: "EFI14243",
    featureDesc: "Surface pressure",
    label: "EFI14243: Surface pressure"
  },
  {
    featureId: "EFI14245",
    featureDesc: "Working width sweep equipment",
    label: "EFI14245: Working width sweep equipment"
  },
  {
    featureId: "EFI14248",
    featureDesc: "Tip width",
    label: "EFI14248: Tip width"
  },
  {
    featureId: "EFI14249",
    featureDesc: "Circulation diameter",
    label: "EFI14249: Circulation diameter"
  },
  {
    featureId: "EFI14250",
    featureDesc: "Tip height",
    label: "EFI14250: Tip height"
  },
  {
    featureId: "EFI14251",
    featureDesc: "Swivel length",
    label: "EFI14251: Swivel length"
  },
  {
    featureId: "EFI14252",
    featureDesc: "Cutting height",
    label: "EFI14252: Cutting height"
  },
  {
    featureId: "EFI14253",
    featureDesc: "Fence length",
    label: "EFI14253: Fence length"
  },
  {
    featureId: "EFI14254",
    featureDesc: "Table width",
    label: "EFI14254: Table width"
  },
  {
    featureId: "EFI14255",
    featureDesc: "Table length",
    label: "EFI14255: Table length"
  },
  {
    featureId: "EFI14256",
    featureDesc: "Dimension coupling sleeve (for spindle)",
    label: "EFI14256: Dimension coupling sleeve (for spindle)"
  },
  {
    featureId: "EFI14257",
    featureDesc: "Throat",
    label: "EFI14257: Throat"
  },
  {
    featureId: "EFI14258",
    featureDesc: "Max. cutting height",
    label: "EFI14258: Max. cutting height"
  },
  {
    featureId: "EFI14259",
    featureDesc: "Max. cutting width",
    label: "EFI14259: Max. cutting width"
  },
  {
    featureId: "EFI14260",
    featureDesc: "Max. cutting length",
    label: "EFI14260: Max. cutting length"
  },
  {
    featureId: "EFI14262",
    featureDesc: "Max. cutting width at 90°",
    label: "EFI14262: Max. cutting width at 90°"
  },
  {
    featureId: "EFI14263",
    featureDesc: "Max. cutting height at 90°",
    label: "EFI14263: Max. cutting height at 90°"
  },
  {
    featureId: "EFI14264",
    featureDesc: "Max. compressor power",
    label: "EFI14264: Max. compressor power"
  },
  {
    featureId: "EFI14266",
    featureDesc: "Depositional particle size",
    label: "EFI14266: Depositional particle size"
  },
  {
    featureId: "EFI14267",
    featureDesc: "Residual oil aerosol content",
    label: "EFI14267: Residual oil aerosol content"
  },
  {
    featureId: "EFI14268",
    featureDesc: "Residual oil vapor content",
    label: "EFI14268: Residual oil vapor content"
  },
  {
    featureId: "EFI14281",
    featureDesc: "Max. sheet thickness steel 400 N/mm²",
    label: "EFI14281: Max. sheet thickness steel 400 N/mm²"
  },
  {
    featureId: "EFI14282",
    featureDesc: "Fold width",
    label: "EFI14282: Fold width"
  },
  {
    featureId: "EFI14297",
    featureDesc: "Volume catch basin",
    label: "EFI14297: Volume catch basin"
  },
  {
    featureId: "EFI14301",
    featureDesc: "Horizontal load",
    label: "EFI14301: Horizontal load"
  },
  {
    featureId: "EFI14302",
    featureDesc: "Shelf width",
    label: "EFI14302: Shelf width"
  },
  {
    featureId: "EFI14303",
    featureDesc: "Load capacity per panel",
    label: "EFI14303: Load capacity per panel"
  },
  {
    featureId: "EFI14305",
    featureDesc: "Hole thickness",
    label: "EFI14305: Hole thickness"
  },
  {
    featureId: "EFI14311",
    featureDesc: "Stand height",
    label: "EFI14311: Stand height"
  },
  {
    featureId: "EFI14320",
    featureDesc: "Max. opening front vise",
    label: "EFI14320: Max. opening front vise"
  },
  {
    featureId: "EFI14321",
    featureDesc: "Max. opening tail vise",
    label: "EFI14321: Max. opening tail vise"
  },
  {
    featureId: "EFI14327",
    featureDesc: "Water output cooled",
    label: "EFI14327: Water output cooled"
  },
  {
    featureId: "EFI14329",
    featureDesc: "Water output not cooled",
    label: "EFI14329: Water output not cooled"
  },
  {
    featureId: "EFI14330",
    featureDesc: "Water output hot",
    label: "EFI14330: Water output hot"
  },
  {
    featureId: "EFI14331",
    featureDesc: "Base area",
    label: "EFI14331: Base area"
  },
  {
    featureId: "EFI14334",
    featureDesc: "Collecting volume",
    label: "EFI14334: Collecting volume"
  },
  {
    featureId: "EFI14342",
    featureDesc: "Spacing between the fork brackets/-bags",
    label: "EFI14342: Spacing between the fork brackets/-bags"
  },
  {
    featureId: "EFI14343",
    featureDesc: "Clear height bracket/pocket",
    label: "EFI14343: Clear height bracket/pocket"
  },
  {
    featureId: "EFI14348",
    featureDesc: "Air pressure",
    label: "EFI14348: Air pressure"
  },
  {
    featureId: "EFI14349",
    featureDesc: "Air consumption",
    label: "EFI14349: Air consumption"
  },
  {
    featureId: "EFI14350",
    featureDesc: "Water quantity",
    label: "EFI14350: Water quantity"
  },
  {
    featureId: "EFI14351",
    featureDesc: "Blasting agent quantity",
    label: "EFI14351: Blasting agent quantity"
  },
  {
    featureId: "EFI14366",
    featureDesc: "Dry ice capacity",
    label: "EFI14366: Dry ice capacity"
  },
  {
    featureId: "EFI14367",
    featureDesc: "Dry ice consumption",
    label: "EFI14367: Dry ice consumption"
  },
  {
    featureId: "EFI14375",
    featureDesc: "Outer height rectangular insulation",
    label: "EFI14375: Outer height rectangular insulation"
  },
  {
    featureId: "EFI14376",
    featureDesc: "Cross section branch core",
    label: "EFI14376: Cross section branch core"
  },
  {
    featureId: "EFI14377",
    featureDesc: "Cross section through-wiring core",
    label: "EFI14377: Cross section through-wiring core"
  },
  {
    featureId: "EFI14391",
    featureDesc: "Max. cohesiveness",
    label: "EFI14391: Max. cohesiveness"
  },
  {
    featureId: "EFI14392",
    featureDesc: "Low temperature resistance",
    label: "EFI14392: Low temperature resistance"
  },
  {
    featureId: "EFI14446",
    featureDesc: "Screw hole distance",
    label: "EFI14446: Screw hole distance"
  },
  {
    featureId: "EFI14454",
    featureDesc: "Consumption per m²",
    label: "EFI14454: Consumption per m²"
  },
  {
    featureId: "EFI14456",
    featureDesc: "Working width main sweep expander",
    label: "EFI14456: Working width main sweep expander"
  },
  {
    featureId: "EFI14457",
    featureDesc: "Working width with one side broom",
    label: "EFI14457: Working width with one side broom"
  },
  {
    featureId: "EFI14458",
    featureDesc: "Working width with two side brooms",
    label: "EFI14458: Working width with two side brooms"
  },
  {
    featureId: "EFI14464",
    featureDesc: "Weighing range",
    label: "EFI14464: Weighing range"
  },
  {
    featureId: "EFI14465",
    featureDesc: "Measurement subdivision",
    label: "EFI14465: Measurement subdivision"
  },
  {
    featureId: "EFI14466",
    featureDesc: "Length of weighing area",
    label: "EFI14466: Length of weighing area"
  },
  {
    featureId: "EFI14467",
    featureDesc: "Width of weighing area",
    label: "EFI14467: Width of weighing area"
  },
  {
    featureId: "EFI14471",
    featureDesc: "Worktop width",
    label: "EFI14471: Worktop width"
  },
  {
    featureId: "EFI14472",
    featureDesc: "Worktop depth",
    label: "EFI14472: Worktop depth"
  },
  {
    featureId: "EFI14473",
    featureDesc: "Max. additional load (when stacking)",
    label: "EFI14473: Max. additional load (when stacking)"
  },
  {
    featureId: "EFI14480",
    featureDesc: "Nozzle bore",
    label: "EFI14480: Nozzle bore"
  },
  {
    featureId: "EFI14493",
    featureDesc: "Lead size",
    label: "EFI14493: Lead size"
  },
  {
    featureId: "EFI14495",
    featureDesc: "Fold height",
    label: "EFI14495: Fold height"
  },
  {
    featureId: "EFI14496",
    featureDesc: "Stroke air",
    label: "EFI14496: Stroke air"
  },
  {
    featureId: "EFI14504",
    featureDesc: "Cam diameter",
    label: "EFI14504: Cam diameter"
  },
  {
    featureId: "EFI14505",
    featureDesc: "Pin length",
    label: "EFI14505: Pin length"
  },
  {
    featureId: "EFI14506",
    featureDesc: "Rod protrusion",
    label: "EFI14506: Rod protrusion"
  },
  {
    featureId: "EFI14577",
    featureDesc: "Tile height",
    label: "EFI14577: Tile height"
  },
  {
    featureId: "EFI14600",
    featureDesc: "Wall thickness mantle pipe",
    label: "EFI14600: Wall thickness mantle pipe"
  },
  {
    featureId: "EFI14659",
    featureDesc: "Thermal conductivity according to EN 1745 (lambda-10, dry, P=50 %)",
    label: "EFI14659: Thermal conductivity according to EN 1745 (lambda-10, dry, P=50 %)"
  },
  {
    featureId: "EFI14664",
    featureDesc: "Height (nominal)",
    label: "EFI14664: Height (nominal)"
  },
  {
    featureId: "EFI14665",
    featureDesc: "Height (production size)",
    label: "EFI14665: Height (production size)"
  },
  {
    featureId: "EFI14688",
    featureDesc: "Net content dust",
    label: "EFI14688: Net content dust"
  },
  {
    featureId: "EFI14689",
    featureDesc: "Net content water",
    label: "EFI14689: Net content water"
  },
  {
    featureId: "EFI14691",
    featureDesc: "Max. free drillspace",
    label: "EFI14691: Max. free drillspace"
  },
  {
    featureId: "EFI14723",
    featureDesc: "Continuous volume flow (Q3)",
    label: "EFI14723: Continuous volume flow (Q3)"
  },
  {
    featureId: "EFI14724",
    featureDesc: "Height (large)",
    label: "EFI14724: Height (large)"
  },
  {
    featureId: "EFI14725",
    featureDesc: "Height (small)",
    label: "EFI14725: Height (small)"
  },
  {
    featureId: "EFI14727",
    featureDesc: "Diameter sensor element",
    label: "EFI14727: Diameter sensor element"
  },
  {
    featureId: "EFI14756",
    featureDesc: "Max. centre distance of floor joists",
    label: "EFI14756: Max. centre distance of floor joists"
  },
  {
    featureId: "EFI14774",
    featureDesc: "Flow rate per stroke of piston",
    label: "EFI14774: Flow rate per stroke of piston"
  },
  {
    featureId: "EFI14777",
    featureDesc: "Width of keyway",
    label: "EFI14777: Width of keyway"
  },
  {
    featureId: "EFI14779",
    featureDesc: "Functional diameter",
    label: "EFI14779: Functional diameter"
  },
  {
    featureId: "EFI14780",
    featureDesc: "Body diameter",
    label: "EFI14780: Body diameter"
  },
  {
    featureId: "EFI14781",
    featureDesc: "Cone length",
    label: "EFI14781: Cone length"
  },
  {
    featureId: "EFI14783",
    featureDesc: "Length of collet chuck",
    label: "EFI14783: Length of collet chuck"
  },
  {
    featureId: "EFI14784",
    featureDesc: "Clamping length",
    label: "EFI14784: Clamping length"
  },
  {
    featureId: "EFI14785",
    featureDesc: "Connection bore diameter",
    label: "EFI14785: Connection bore diameter"
  },
  {
    featureId: "EFI14787",
    featureDesc: "Brush width",
    label: "EFI14787: Brush width"
  },
  {
    featureId: "EFI14788",
    featureDesc: "Spring deflection of rolls",
    label: "EFI14788: Spring deflection of rolls"
  },
  {
    featureId: "EFI14796",
    featureDesc: "Usable length",
    label: "EFI14796: Usable length"
  },
  {
    featureId: "EFI14799",
    featureDesc: "Diameter of x-axis",
    label: "EFI14799: Diameter of x-axis"
  },
  {
    featureId: "EFI14800",
    featureDesc: "Max. diameter of z-axis",
    label: "EFI14800: Max. diameter of z-axis"
  },
  {
    featureId: "EFI14801",
    featureDesc: "Measuring range of bore",
    label: "EFI14801: Measuring range of bore"
  },
  {
    featureId: "EFI14802",
    featureDesc: "Measuring range of shaft",
    label: "EFI14802: Measuring range of shaft"
  },
  {
    featureId: "EFI14804",
    featureDesc: "Max. centre cut",
    label: "EFI14804: Max. centre cut"
  },
  {
    featureId: "EFI14806",
    featureDesc: "Max. quality cut",
    label: "EFI14806: Max. quality cut"
  },
  {
    featureId: "EFI14833",
    featureDesc: "Diameter of active surface",
    label: "EFI14833: Diameter of active surface"
  },
  {
    featureId: "EFI14837",
    featureDesc: "Radius of active surface",
    label: "EFI14837: Radius of active surface"
  },
  {
    featureId: "EFI14846",
    featureDesc: "Tile thickness",
    label: "EFI14846: Tile thickness"
  },
  {
    featureId: "EFI14851",
    featureDesc: "Max. air volume at 50 Pa",
    label: "EFI14851: Max. air volume at 50 Pa"
  },
  {
    featureId: "EFI14854",
    featureDesc: "Phase distance",
    label: "EFI14854: Phase distance"
  },
  {
    featureId: "EFI14856",
    featureDesc: "Pole distance",
    label: "EFI14856: Pole distance"
  },
  {
    featureId: "EFI14859",
    featureDesc: "Covering thickness",
    label: "EFI14859: Covering thickness"
  },
  {
    featureId: "EFI14863",
    featureDesc: "Standard pellet size",
    label: "EFI14863: Standard pellet size"
  },
  {
    featureId: "EFI14864",
    featureDesc: "Suitable for pellet size",
    label: "EFI14864: Suitable for pellet size"
  },
  {
    featureId: "EFI14871",
    featureDesc: "Suction disc diameter",
    label: "EFI14871: Suction disc diameter"
  },
  {
    featureId: "EFI14875",
    featureDesc: "Height underside tollgate",
    label: "EFI14875: Height underside tollgate"
  },
  {
    featureId: "EFI14877",
    featureDesc: "Processing temperature",
    label: "EFI14877: Processing temperature"
  },
  {
    featureId: "EFI14892",
    featureDesc: "Amount of material that can be agitated",
    label: "EFI14892: Amount of material that can be agitated"
  },
  {
    featureId: "EFI14895",
    featureDesc: "Length of charging cable",
    label: "EFI14895: Length of charging cable"
  },
  {
    featureId: "EFI14898",
    featureDesc: "Diameter cutting wheel",
    label: "EFI14898: Diameter cutting wheel"
  },
  {
    featureId: "EFI14904",
    featureDesc: "Volume flow rate (BEP)",
    label: "EFI14904: Volume flow rate (BEP)"
  },
  {
    featureId: "EFI14910",
    featureDesc: "Length delivery hose",
    label: "EFI14910: Length delivery hose"
  },
  {
    featureId: "EFI14911",
    featureDesc: "Diameter ball",
    label: "EFI14911: Diameter ball"
  },
  {
    featureId: "EFI14912",
    featureDesc: "Track width",
    label: "EFI14912: Track width"
  },
  {
    featureId: "EFI14913",
    featureDesc: "Max. bonding gap",
    label: "EFI14913: Max. bonding gap"
  },
  {
    featureId: "EFI14915",
    featureDesc: "Webbing width",
    label: "EFI14915: Webbing width"
  },
  {
    featureId: "EFI14917",
    featureDesc: "Door thickness",
    label: "EFI14917: Door thickness"
  },
  {
    featureId: "EFI14919",
    featureDesc: "Fold size",
    label: "EFI14919: Fold size"
  },
  {
    featureId: "EFI14920",
    featureDesc: "Assembly depth",
    label: "EFI14920: Assembly depth"
  },
  {
    featureId: "EFI14921",
    featureDesc: "Nominal diameter of branches",
    label: "EFI14921: Nominal diameter of branches"
  },
  {
    featureId: "EFI14924",
    featureDesc: "Max. weld seam width",
    label: "EFI14924: Max. weld seam width"
  },
  {
    featureId: "EFI14928",
    featureDesc: "Thickness intermediate layer",
    label: "EFI14928: Thickness intermediate layer"
  },
  {
    featureId: "EFI14935",
    featureDesc: "Square drive",
    label: "EFI14935: Square drive"
  },
  {
    featureId: "EFI14944",
    featureDesc: "Wheel pitch",
    label: "EFI14944: Wheel pitch"
  },
  {
    featureId: "EFI14946",
    featureDesc: "Diameter wheels",
    label: "EFI14946: Diameter wheels"
  },
  {
    featureId: "EFI14950",
    featureDesc: "Cleaning performance per battery charge",
    label: "EFI14950: Cleaning performance per battery charge"
  },
  {
    featureId: "EFI14959",
    featureDesc: "Max. air volume at 100 Pa",
    label: "EFI14959: Max. air volume at 100 Pa"
  },
  {
    featureId: "EFI14960",
    featureDesc: "Max. air volume at 150 Pa",
    label: "EFI14960: Max. air volume at 150 Pa"
  },
  {
    featureId: "EFI14961",
    featureDesc: "Max. air volume at 200 Pa",
    label: "EFI14961: Max. air volume at 200 Pa"
  },
  {
    featureId: "EFI14962",
    featureDesc: "Max. air volume at 250 Pa",
    label: "EFI14962: Max. air volume at 250 Pa"
  },
  {
    featureId: "EFI14963",
    featureDesc: "Max. air volume at 300 Pa",
    label: "EFI14963: Max. air volume at 300 Pa"
  },
  {
    featureId: "EFI14964",
    featureDesc: "Section height",
    label: "EFI14964: Section height"
  },
  {
    featureId: "EFI14965",
    featureDesc: "Max. air volume at 400 Pa",
    label: "EFI14965: Max. air volume at 400 Pa"
  },
  {
    featureId: "EFI14966",
    featureDesc: "Max. air volume at 500 Pa",
    label: "EFI14966: Max. air volume at 500 Pa"
  },
  {
    featureId: "EFI14967",
    featureDesc: "Max. air volume at 600 Pa",
    label: "EFI14967: Max. air volume at 600 Pa"
  },
  {
    featureId: "EFI14970",
    featureDesc: "Weight soldering iron",
    label: "EFI14970: Weight soldering iron"
  },
  {
    featureId: "EFI14977",
    featureDesc: "For window width",
    label: "EFI14977: For window width"
  },
  {
    featureId: "EFI14978",
    featureDesc: "Strip width",
    label: "EFI14978: Strip width"
  },
  {
    featureId: "EFI14990",
    featureDesc: "Max. steam temperature",
    label: "EFI14990: Max. steam temperature"
  },
  {
    featureId: "EFI14993",
    featureDesc: "Overall height retracted",
    label: "EFI14993: Overall height retracted"
  },
  {
    featureId: "EFI14994",
    featureDesc: "Clear height",
    label: "EFI14994: Clear height"
  },
  {
    featureId: "EFI15000",
    featureDesc: "Door leaf width",
    label: "EFI15000: Door leaf width"
  },
  {
    featureId: "EFI15006",
    featureDesc: "Max. clamping thickness",
    label: "EFI15006: Max. clamping thickness"
  },
  {
    featureId: "EFI15008",
    featureDesc: "Air consumption per stroke",
    label: "EFI15008: Air consumption per stroke"
  },
  {
    featureId: "EFI15026",
    featureDesc: "Concentricity",
    label: "EFI15026: Concentricity"
  },
  {
    featureId: "EFI15027",
    featureDesc: "Projection length",
    label: "EFI15027: Projection length"
  },
  {
    featureId: "EFI15045",
    featureDesc: "Length bristles",
    label: "EFI15045: Length bristles"
  },
  {
    featureId: "EFI15052",
    featureDesc: "Max. detection depth wood",
    label: "EFI15052: Max. detection depth wood"
  },
  {
    featureId: "EFI15053",
    featureDesc: "Max. detection depth metal",
    label: "EFI15053: Max. detection depth metal"
  },
  {
    featureId: "EFI15054",
    featureDesc: "Max. detection depth live wire",
    label: "EFI15054: Max. detection depth live wire"
  },
  {
    featureId: "EFI15059",
    featureDesc: "Leaf thickness",
    label: "EFI15059: Leaf thickness"
  },
  {
    featureId: "EFI15065",
    featureDesc: "For square rod size",
    label: "EFI15065: For square rod size"
  },
  {
    featureId: "EFI15067",
    featureDesc: "Nozzle diameter",
    label: "EFI15067: Nozzle diameter"
  },
  {
    featureId: "EFI15076",
    featureDesc: "Barrel diameter",
    label: "EFI15076: Barrel diameter"
  },
  {
    featureId: "EFI15077",
    featureDesc: "Spindle pitch",
    label: "EFI15077: Spindle pitch"
  },
  {
    featureId: "EFI15094",
    featureDesc: "Door height",
    label: "EFI15094: Door height"
  },
  {
    featureId: "EFI15108",
    featureDesc: "For hinge diameter",
    label: "EFI15108: For hinge diameter"
  },
  {
    featureId: "EFI15116",
    featureDesc: "Suitable for wall/board thickness",
    label: "EFI15116: Suitable for wall/board thickness"
  },
  {
    featureId: "EFI15135",
    featureDesc: "Diameter rollers",
    label: "EFI15135: Diameter rollers"
  },
  {
    featureId: "EFI15142",
    featureDesc: "Max. permitted load capacity flat material",
    label: "EFI15142: Max. permitted load capacity flat material"
  },
  {
    featureId: "EFI15143",
    featureDesc: "Max. permitted load capacity round material",
    label: "EFI15143: Max. permitted load capacity round material"
  },
  {
    featureId: "EFI15145",
    featureDesc: "Max. work piece height",
    label: "EFI15145: Max. work piece height"
  },
  {
    featureId: "EFI15149",
    featureDesc: "Jaw connection",
    label: "EFI15149: Jaw connection"
  },
  {
    featureId: "EFI15150",
    featureDesc: "Jaw stroke",
    label: "EFI15150: Jaw stroke"
  },
  {
    featureId: "EFI15152",
    featureDesc: "Overall height elevated",
    label: "EFI15152: Overall height elevated"
  },
  {
    featureId: "EFI15153",
    featureDesc: "Fork length",
    label: "EFI15153: Fork length"
  },
  {
    featureId: "EFI15156",
    featureDesc: "Turning radius",
    label: "EFI15156: Turning radius"
  },
  {
    featureId: "EFI15160",
    featureDesc: "Usable depth",
    label: "EFI15160: Usable depth"
  },
  {
    featureId: "EFI15163",
    featureDesc: "Gripping range",
    label: "EFI15163: Gripping range"
  },
  {
    featureId: "EFI15171",
    featureDesc: "Max. rivet diameter",
    label: "EFI15171: Max. rivet diameter"
  },
  {
    featureId: "EFI15178",
    featureDesc: "Spray range",
    label: "EFI15178: Spray range"
  },
  {
    featureId: "EFI15184",
    featureDesc: "Suitable for size of canister",
    label: "EFI15184: Suitable for size of canister"
  },
  {
    featureId: "EFI15202",
    featureDesc: "Height difference",
    label: "EFI15202: Height difference"
  },
  {
    featureId: "EFI15216",
    featureDesc: "Length of head",
    label: "EFI15216: Length of head"
  },
  {
    featureId: "EFI15222",
    featureDesc: "Length ty-rap",
    label: "EFI15222: Length ty-rap"
  },
  {
    featureId: "EFI15228",
    featureDesc: "Width sensor housing",
    label: "EFI15228: Width sensor housing"
  },
  {
    featureId: "EFI15229",
    featureDesc: "Height sensor housing",
    label: "EFI15229: Height sensor housing"
  },
  {
    featureId: "EFI15230",
    featureDesc: "Depth sensor housing",
    label: "EFI15230: Depth sensor housing"
  },
  {
    featureId: "EFI15259",
    featureDesc: "Min. tensile strength",
    label: "EFI15259: Min. tensile strength"
  },
  {
    featureId: "EFI15304",
    featureDesc: "Fitting length with screw connection",
    label: "EFI15304: Fitting length with screw connection"
  },
  {
    featureId: "EFI15309",
    featureDesc: "Length shower arm",
    label: "EFI15309: Length shower arm"
  },
  {
    featureId: "EFI15342",
    featureDesc: "Diameter inner pipe 2",
    label: "EFI15342: Diameter inner pipe 2"
  },
  {
    featureId: "EFI15343",
    featureDesc: "Diameter inner pipe 3",
    label: "EFI15343: Diameter inner pipe 3"
  },
  {
    featureId: "EFI15344",
    featureDesc: "Diameter inner pipe 4",
    label: "EFI15344: Diameter inner pipe 4"
  },
  {
    featureId: "EFI15345",
    featureDesc: "Wall thickness inner pipe 2",
    label: "EFI15345: Wall thickness inner pipe 2"
  },
  {
    featureId: "EFI15346",
    featureDesc: "Wall thickness inner pipe 3",
    label: "EFI15346: Wall thickness inner pipe 3"
  },
  {
    featureId: "EFI15347",
    featureDesc: "Wall thickness inner pipe 4",
    label: "EFI15347: Wall thickness inner pipe 4"
  },
  {
    featureId: "EFI15351",
    featureDesc: "Outer pipe diameter secondary",
    label: "EFI15351: Outer pipe diameter secondary"
  },
  {
    featureId: "EFI15382",
    featureDesc: "Length immersion tube",
    label: "EFI15382: Length immersion tube"
  },
  {
    featureId: "EFI15420",
    featureDesc: "Mesh width of filter element",
    label: "EFI15420: Mesh width of filter element"
  },
  {
    featureId: "EFI15530",
    featureDesc: "Overlapping gasket",
    label: "EFI15530: Overlapping gasket"
  },
  {
    featureId: "EFI15551",
    featureDesc: "Extension length of cover",
    label: "EFI15551: Extension length of cover"
  },
  {
    featureId: "EFI15556",
    featureDesc: "Max. temperature at the inlet",
    label: "EFI15556: Max. temperature at the inlet"
  },
  {
    featureId: "EFI15557",
    featureDesc: "Max. temperature at the outlet",
    label: "EFI15557: Max. temperature at the outlet"
  },
  {
    featureId: "EFI15588",
    featureDesc: "Dimension square neck bolt",
    label: "EFI15588: Dimension square neck bolt"
  },
  {
    featureId: "EFI15590",
    featureDesc: "Segment width",
    label: "EFI15590: Segment width"
  },
  {
    featureId: "EFI15591",
    featureDesc: "Segment height",
    label: "EFI15591: Segment height"
  },
  {
    featureId: "EFI15593",
    featureDesc: "Segment length",
    label: "EFI15593: Segment length"
  },
  {
    featureId: "EFI15613",
    featureDesc: "Outer diameter incl. sheat/cover",
    label: "EFI15613: Outer diameter incl. sheat/cover"
  },
  {
    featureId: "EFI15656",
    featureDesc: "Opening pressure non return valve",
    label: "EFI15656: Opening pressure non return valve"
  },
  {
    featureId: "EFI15659",
    featureDesc: "Flow adjustment range",
    label: "EFI15659: Flow adjustment range"
  },
  {
    featureId: "EFI15672",
    featureDesc: "Height washbasin cabinet",
    label: "EFI15672: Height washbasin cabinet"
  },
  {
    featureId: "EFI15673",
    featureDesc: "Width washbasin cabinet",
    label: "EFI15673: Width washbasin cabinet"
  },
  {
    featureId: "EFI15674",
    featureDesc: "Depth washbasin cabinet",
    label: "EFI15674: Depth washbasin cabinet"
  },
  {
    featureId: "EFI15675",
    featureDesc: "Height bath unit cabinet",
    label: "EFI15675: Height bath unit cabinet"
  },
  {
    featureId: "EFI15676",
    featureDesc: "Width bath unit cabinet",
    label: "EFI15676: Width bath unit cabinet"
  },
  {
    featureId: "EFI15677",
    featureDesc: "Depth bath unit cabinet",
    label: "EFI15677: Depth bath unit cabinet"
  },
  {
    featureId: "EFI15678",
    featureDesc: "Height washbasin top cabinet",
    label: "EFI15678: Height washbasin top cabinet"
  },
  {
    featureId: "EFI15679",
    featureDesc: "Width washbasin top cabinet",
    label: "EFI15679: Width washbasin top cabinet"
  },
  {
    featureId: "EFI15680",
    featureDesc: "Depth washbasin top cabinet",
    label: "EFI15680: Depth washbasin top cabinet"
  },
  {
    featureId: "EFI15682",
    featureDesc: "Flange length",
    label: "EFI15682: Flange length"
  },
  {
    featureId: "EFI15683",
    featureDesc: "Pin width (flat-pin shape terminal)",
    label: "EFI15683: Pin width (flat-pin shape terminal)"
  },
  {
    featureId: "EFI15684",
    featureDesc: "Pin diameter (pin shape terminal)",
    label: "EFI15684: Pin diameter (pin shape terminal)"
  },
  {
    featureId: "EFI15685",
    featureDesc: "Suitable for diameter core drill bit",
    label: "EFI15685: Suitable for diameter core drill bit"
  },
  {
    featureId: "EFI15698",
    featureDesc: "Suitable for cable management system height",
    label: "EFI15698: Suitable for cable management system height"
  },
  {
    featureId: "EFI15706",
    featureDesc: "Max. output temperature",
    label: "EFI15706: Max. output temperature"
  },
  {
    featureId: "EFI15707",
    featureDesc: "Free axial space for protective tube/waterproofing membrane (depth)",
    label: "EFI15707: Free axial space for protective tube/waterproofing membrane (depth)"
  },
  {
    featureId: "EFI15708",
    featureDesc: "Free radial space for protective tube/waterproofing membrane (width)",
    label: "EFI15708: Free radial space for protective tube/waterproofing membrane (width)"
  },
  {
    featureId: "EFI15720",
    featureDesc: "Centre-to-centre distance of stud bolts",
    label: "EFI15720: Centre-to-centre distance of stud bolts"
  },
  {
    featureId: "EFI15748",
    featureDesc: "Filter capacity",
    label: "EFI15748: Filter capacity"
  },
  {
    featureId: "EFI15773",
    featureDesc: "Height below pipe",
    label: "EFI15773: Height below pipe"
  },
  {
    featureId: "EFI15778",
    featureDesc: "Length of flushing pipe",
    label: "EFI15778: Length of flushing pipe"
  },
  {
    featureId: "EFI15779",
    featureDesc: "Length of drainage pipe",
    label: "EFI15779: Length of drainage pipe"
  },
  {
    featureId: "EFI15792",
    featureDesc: "Sideways adjustment range",
    label: "EFI15792: Sideways adjustment range"
  },
  {
    featureId: "EFI15803",
    featureDesc: "Min. drill hole diameter",
    label: "EFI15803: Min. drill hole diameter"
  },
  {
    featureId: "EFI15805",
    featureDesc: "Max. sand content",
    label: "EFI15805: Max. sand content"
  },
  {
    featureId: "EFI15868",
    featureDesc: "Length exhaust air hose",
    label: "EFI15868: Length exhaust air hose"
  },
  {
    featureId: "EFI15869",
    featureDesc: "Diameter exhaust air hose",
    label: "EFI15869: Diameter exhaust air hose"
  },
  {
    featureId: "EFI15879",
    featureDesc: "Length weld-on nozzles",
    label: "EFI15879: Length weld-on nozzles"
  },
  {
    featureId: "EFI15898",
    featureDesc: "Opening pressure safety valve",
    label: "EFI15898: Opening pressure safety valve"
  },
  {
    featureId: "EFI15900",
    featureDesc: "Size of cartridge",
    label: "EFI15900: Size of cartridge"
  },
  {
    featureId: "EFI15931",
    featureDesc: "ATPV value of protection against arc fault",
    label: "EFI15931: ATPV value of protection against arc fault"
  },
  {
    featureId: "EFI15932",
    featureDesc: "Value of protection against arc fault",
    label: "EFI15932: Value of protection against arc fault"
  },
  {
    featureId: "EFI15933",
    featureDesc: "ELIM value of protection against arc fault",
    label: "EFI15933: ELIM value of protection against arc fault"
  },
  {
    featureId: "EFI15952",
    featureDesc: "Flow Domestic Hot Water (DHW) according to EN 13203-1",
    label: "EFI15952: Flow Domestic Hot Water (DHW) according to EN 13203-1"
  },
  {
    featureId: "EFI15969",
    featureDesc: "Absorber volume",
    label: "EFI15969: Absorber volume"
  },
  {
    featureId: "EFI15970",
    featureDesc: "Arm rest length",
    label: "EFI15970: Arm rest length"
  },
  {
    featureId: "EFI15985",
    featureDesc: "Coil windings temperature rise",
    label: "EFI15985: Coil windings temperature rise"
  },
  {
    featureId: "EFI16005",
    featureDesc: "Assembly width",
    label: "EFI16005: Assembly width"
  },
  {
    featureId: "EFI16012",
    featureDesc: "Diameter inlets",
    label: "EFI16012: Diameter inlets"
  },
  {
    featureId: "EFI16014",
    featureDesc: "Hole distance connection 3",
    label: "EFI16014: Hole distance connection 3"
  },
  {
    featureId: "EFI16018",
    featureDesc: "Display area thermometer",
    label: "EFI16018: Display area thermometer"
  },
  {
    featureId: "EFI16100",
    featureDesc: "Seal width (depth)",
    label: "EFI16100: Seal width (depth)"
  },
  {
    featureId: "EFI16143",
    featureDesc: "Vibration according to EN 50144",
    label: "EFI16143: Vibration according to EN 50144"
  },
  {
    featureId: "EFI16161",
    featureDesc: "Diameter of outer escutcheon",
    label: "EFI16161: Diameter of outer escutcheon"
  },
  {
    featureId: "EFI16162",
    featureDesc: "Height of inner plate/escutcheon",
    label: "EFI16162: Height of inner plate/escutcheon"
  },
  {
    featureId: "EFI16164",
    featureDesc: "Width of inner plate/escutcheon",
    label: "EFI16164: Width of inner plate/escutcheon"
  },
  {
    featureId: "EFI16165",
    featureDesc: "Thickness of inner plate/escutcheon",
    label: "EFI16165: Thickness of inner plate/escutcheon"
  },
  {
    featureId: "EFI16167",
    featureDesc: "Diameter of inner escutcheon",
    label: "EFI16167: Diameter of inner escutcheon"
  },
  {
    featureId: "EFI16234",
    featureDesc: "Heat conduction coefficient insulation material (ʎ)",
    label: "EFI16234: Heat conduction coefficient insulation material (ʎ)"
  },
  {
    featureId: "EFI16236",
    featureDesc: "Inner diameter installation tube",
    label: "EFI16236: Inner diameter installation tube"
  },
  {
    featureId: "EFI16247",
    featureDesc: "Pipe diameter compression set",
    label: "EFI16247: Pipe diameter compression set"
  },
  {
    featureId: "EFI16250",
    featureDesc: "Propeller tip speed",
    label: "EFI16250: Propeller tip speed"
  },
  {
    featureId: "EFI16282",
    featureDesc: "Diameter connection outlet side",
    label: "EFI16282: Diameter connection outlet side"
  },
  {
    featureId: "EFI16283",
    featureDesc: "Diameter connection inlet side",
    label: "EFI16283: Diameter connection inlet side"
  },
  {
    featureId: "EFI16292",
    featureDesc: "Diameter cover",
    label: "EFI16292: Diameter cover"
  },
  {
    featureId: "EFI16295",
    featureDesc: "For coupling size",
    label: "EFI16295: For coupling size"
  },
  {
    featureId: "EFI16305",
    featureDesc: "Safe working load according to IEC 61537",
    label: "EFI16305: Safe working load according to IEC 61537"
  },
  {
    featureId: "EFI16325",
    featureDesc: "Net capacity fresh-keeping zone / 0 °C-zone",
    label: "EFI16325: Net capacity fresh-keeping zone / 0 °C-zone"
  },
  {
    featureId: "EFI16330",
    featureDesc: "Conductor cross section, input",
    label: "EFI16330: Conductor cross section, input"
  },
  {
    featureId: "EFI16331",
    featureDesc: "Conductor cross section, output",
    label: "EFI16331: Conductor cross section, output"
  },
  {
    featureId: "EFI16343",
    featureDesc: "Technical flow range according to EN 1434",
    label: "EFI16343: Technical flow range according to EN 1434"
  },
  {
    featureId: "EFI16345",
    featureDesc: "Nominal flow rate according to EN 1434 (qp)",
    label: "EFI16345: Nominal flow rate according to EN 1434 (qp)"
  },
  {
    featureId: "EFI16347",
    featureDesc: "Approved flow range according to EN 1434 (qi - qs)",
    label: "EFI16347: Approved flow range according to EN 1434 (qi - qs)"
  },
  {
    featureId: "EFI16366",
    featureDesc: "Diameter temperature sensor",
    label: "EFI16366: Diameter temperature sensor"
  },
  {
    featureId: "EFI16367",
    featureDesc: "Length temperature sensor",
    label: "EFI16367: Length temperature sensor"
  },
  {
    featureId: "EFI16371",
    featureDesc: "Built-in length pump",
    label: "EFI16371: Built-in length pump"
  },
  {
    featureId: "EFI16380",
    featureDesc: "Built-in diameter",
    label: "EFI16380: Built-in diameter"
  },
  {
    featureId: "EFI16381",
    featureDesc: "Feed speed",
    label: "EFI16381: Feed speed"
  },
  {
    featureId: "EFI16399",
    featureDesc: "Mean flow velocity",
    label: "EFI16399: Mean flow velocity"
  },
  {
    featureId: "EFI16402",
    featureDesc: "Centre distance of toilet seat fastener",
    label: "EFI16402: Centre distance of toilet seat fastener"
  },
  {
    featureId: "EFI16414",
    featureDesc: "Link length",
    label: "EFI16414: Link length"
  },
  {
    featureId: "EFI16422",
    featureDesc: "Link width",
    label: "EFI16422: Link width"
  },
  {
    featureId: "EFI16427",
    featureDesc: "Pin height",
    label: "EFI16427: Pin height"
  },
  {
    featureId: "EFI16450",
    featureDesc: "Device diameter",
    label: "EFI16450: Device diameter"
  },
  {
    featureId: "EFI16457",
    featureDesc: "Pitch measure",
    label: "EFI16457: Pitch measure"
  },
  {
    featureId: "EFI16485",
    featureDesc: "Length of legs",
    label: "EFI16485: Length of legs"
  },
  {
    featureId: "EFI16520",
    featureDesc: "Diameter of core insulation",
    label: "EFI16520: Diameter of core insulation"
  },
  {
    featureId: "EFI16523",
    featureDesc: "Suitable for feedthrough diameter",
    label: "EFI16523: Suitable for feedthrough diameter"
  },
  {
    featureId: "EFI16541",
    featureDesc: "Centre distance pipe connections",
    label: "EFI16541: Centre distance pipe connections"
  },
  {
    featureId: "EFI16551",
    featureDesc: "Centre distance, radiator connections",
    label: "EFI16551: Centre distance, radiator connections"
  },
  {
    featureId: "EFI16566",
    featureDesc: "Holding capacity condensation water",
    label: "EFI16566: Holding capacity condensation water"
  },
  {
    featureId: "EFI16574",
    featureDesc: "Fuse length",
    label: "EFI16574: Fuse length"
  },
  {
    featureId: "EFI16590",
    featureDesc: "Fastening depth",
    label: "EFI16590: Fastening depth"
  },
  {
    featureId: "EFI16591",
    featureDesc: "Cable length pressure sensor",
    label: "EFI16591: Cable length pressure sensor"
  },
  {
    featureId: "EFI16607",
    featureDesc: "Screw diameter",
    label: "EFI16607: Screw diameter"
  },
  {
    featureId: "EFI16622",
    featureDesc: "Square size 1",
    label: "EFI16622: Square size 1"
  },
  {
    featureId: "EFI16623",
    featureDesc: "Square size 2",
    label: "EFI16623: Square size 2"
  },
  {
    featureId: "EFI16624",
    featureDesc: "Square size 3",
    label: "EFI16624: Square size 3"
  },
  {
    featureId: "EFI16625",
    featureDesc: "Square size 4",
    label: "EFI16625: Square size 4"
  },
  {
    featureId: "EFI16626",
    featureDesc: "Wire thickness",
    label: "EFI16626: Wire thickness"
  },
  {
    featureId: "EFI16627",
    featureDesc: "Wire width",
    label: "EFI16627: Wire width"
  },
  {
    featureId: "EFI16628",
    featureDesc: "Cable length temperature sensor",
    label: "EFI16628: Cable length temperature sensor"
  },
  {
    featureId: "EFI16674",
    featureDesc: "Nominal air load",
    label: "EFI16674: Nominal air load"
  },
  {
    featureId: "EFI16685",
    featureDesc: "Water volume of central heating water",
    label: "EFI16685: Water volume of central heating water"
  },
  {
    featureId: "EFI16693",
    featureDesc: "Temperature resolution",
    label: "EFI16693: Temperature resolution"
  },
  {
    featureId: "EFI16701",
    featureDesc: "Usable volume",
    label: "EFI16701: Usable volume"
  },
  {
    featureId: "EFI16725",
    featureDesc: "Diameter grid connection",
    label: "EFI16725: Diameter grid connection"
  },
  {
    featureId: "EFI16726",
    featureDesc: "Width grid connection",
    label: "EFI16726: Width grid connection"
  },
  {
    featureId: "EFI16727",
    featureDesc: "Height grid connection",
    label: "EFI16727: Height grid connection"
  },
  {
    featureId: "EFI16737",
    featureDesc: "Measuring range irradiance",
    label: "EFI16737: Measuring range irradiance"
  },
  {
    featureId: "EFI16782",
    featureDesc: "Fuse diameter",
    label: "EFI16782: Fuse diameter"
  },
  {
    featureId: "EFI16801",
    featureDesc: "Wall thickness connecting pipe, connection 1",
    label: "EFI16801: Wall thickness connecting pipe, connection 1"
  },
  {
    featureId: "EFI16802",
    featureDesc: "Wall thickness connecting pipe, connection 2",
    label: "EFI16802: Wall thickness connecting pipe, connection 2"
  },
  {
    featureId: "EFI16859",
    featureDesc: "Abrasion resistance according to EN 12808-2",
    label: "EFI16859: Abrasion resistance according to EN 12808-2"
  },
  {
    featureId: "EFI16860",
    featureDesc: "Flexural strength according to EN 12808-3",
    label: "EFI16860: Flexural strength according to EN 12808-3"
  },
  {
    featureId: "EFI16861",
    featureDesc: "Compressive strength according to EN 12808-3",
    label: "EFI16861: Compressive strength according to EN 12808-3"
  },
  {
    featureId: "EFI16862",
    featureDesc: "Shrinkage according to EN 12808-4",
    label: "EFI16862: Shrinkage according to EN 12808-4"
  },
  {
    featureId: "EFI16863",
    featureDesc: "Water absorption according to EN 12808-5",
    label: "EFI16863: Water absorption according to EN 12808-5"
  },
  {
    featureId: "EFI16864",
    featureDesc: "Suitable for joint width",
    label: "EFI16864: Suitable for joint width"
  },
  {
    featureId: "EFI16901",
    featureDesc: "Flexural strength according to EN 998-1",
    label: "EFI16901: Flexural strength according to EN 998-1"
  },
  {
    featureId: "EFI16906",
    featureDesc: "Paper weight",
    label: "EFI16906: Paper weight"
  },
  {
    featureId: "EFI16908",
    featureDesc: "Adhesive strength according to EN 998-1",
    label: "EFI16908: Adhesive strength according to EN 998-1"
  },
  {
    featureId: "EFI16909",
    featureDesc: "Max. grain size according to EN 998-1",
    label: "EFI16909: Max. grain size according to EN 998-1"
  },
  {
    featureId: "EFI16910",
    featureDesc: "Material required per 1 mm layer thickness",
    label: "EFI16910: Material required per 1 mm layer thickness"
  },
  {
    featureId: "EFI16911",
    featureDesc: "Compressive strength according to EN 998-1",
    label: "EFI16911: Compressive strength according to EN 998-1"
  },
  {
    featureId: "EFI16942",
    featureDesc: "Handle length",
    label: "EFI16942: Handle length"
  },
  {
    featureId: "EFI16961",
    featureDesc: "Cutting/pressing force",
    label: "EFI16961: Cutting/pressing force"
  },
  {
    featureId: "EFI16962",
    featureDesc: "Spreading force",
    label: "EFI16962: Spreading force"
  },
  {
    featureId: "EFI16968",
    featureDesc: "Horizontal distance centre siphon to wall",
    label: "EFI16968: Horizontal distance centre siphon to wall"
  },
  {
    featureId: "EFI16969",
    featureDesc: "Vertical distance centre wall pipe to top side drain plug",
    label: "EFI16969: Vertical distance centre wall pipe to top side drain plug"
  },
  {
    featureId: "EFI16970",
    featureDesc: "Outer pipe diameter drainage",
    label: "EFI16970: Outer pipe diameter drainage"
  },
  {
    featureId: "EFI16984",
    featureDesc: "Inner pipe diameter",
    label: "EFI16984: Inner pipe diameter"
  },
  {
    featureId: "EFI16986",
    featureDesc: "Adhesive consumption",
    label: "EFI16986: Adhesive consumption"
  },
  {
    featureId: "EFI16987",
    featureDesc: "Morter consumption",
    label: "EFI16987: Morter consumption"
  },
  {
    featureId: "EFI16991",
    featureDesc: "Suitable for handle length",
    label: "EFI16991: Suitable for handle length"
  },
  {
    featureId: "EFI17050",
    featureDesc: "Insulator neck diameter",
    label: "EFI17050: Insulator neck diameter"
  },
  {
    featureId: "EFI17051",
    featureDesc: "Suitable for conductor cross section",
    label: "EFI17051: Suitable for conductor cross section"
  },
  {
    featureId: "EFI17154",
    featureDesc: "Range",
    label: "EFI17154: Range"
  },
  {
    featureId: "EFI17161",
    featureDesc: "Ball/bolt diameter",
    label: "EFI17161: Ball/bolt diameter"
  },
  {
    featureId: "EFI17162",
    featureDesc: "Max. trailer weight",
    label: "EFI17162: Max. trailer weight"
  },
  {
    featureId: "EFI17195",
    featureDesc: "Cover weight",
    label: "EFI17195: Cover weight"
  },
  {
    featureId: "EFI17205",
    featureDesc: "Air intake temperature",
    label: "EFI17205: Air intake temperature"
  },
  {
    featureId: "EFI17206",
    featureDesc: "Load bearing capacity",
    label: "EFI17206: Load bearing capacity"
  },
  {
    featureId: "EFI17214",
    featureDesc: "Panel thickness",
    label: "EFI17214: Panel thickness"
  },
  {
    featureId: "EFI17220",
    featureDesc: "Cable/chain length",
    label: "EFI17220: Cable/chain length"
  },
  {
    featureId: "EFI17222",
    featureDesc: "Suitable for beam width",
    label: "EFI17222: Suitable for beam width"
  },
  {
    featureId: "EFI17228",
    featureDesc: "Max. lifting capacity",
    label: "EFI17228: Max. lifting capacity"
  },
  {
    featureId: "EFI17241",
    featureDesc: "Diameter supply air connection",
    label: "EFI17241: Diameter supply air connection"
  },
  {
    featureId: "EFI17247",
    featureDesc: "Diameter longitudinal wire",
    label: "EFI17247: Diameter longitudinal wire"
  },
  {
    featureId: "EFI17261",
    featureDesc: "Possible support spacing",
    label: "EFI17261: Possible support spacing"
  },
  {
    featureId: "EFI17262",
    featureDesc: "Safe working load according to IEC 61537 with support spacing 0.5 m",
    label: "EFI17262: Safe working load according to IEC 61537 with support spacing 0.5 m"
  },
  {
    featureId: "EFI17263",
    featureDesc: "Safe working load according to IEC 61537 with support spacing 1.0 m",
    label: "EFI17263: Safe working load according to IEC 61537 with support spacing 1.0 m"
  },
  {
    featureId: "EFI17264",
    featureDesc: "Safe working load according to IEC 61537 with support spacing 1.5 m",
    label: "EFI17264: Safe working load according to IEC 61537 with support spacing 1.5 m"
  },
  {
    featureId: "EFI17265",
    featureDesc: "Safe working load according to IEC 61537 with support spacing 2.0 m",
    label: "EFI17265: Safe working load according to IEC 61537 with support spacing 2.0 m"
  },
  {
    featureId: "EFI17266",
    featureDesc: "Safe working load according to IEC 61537 with support spacing 2.5 m",
    label: "EFI17266: Safe working load according to IEC 61537 with support spacing 2.5 m"
  },
  {
    featureId: "EFI17267",
    featureDesc: "Safe working load according to IEC 61537 with support spacing 3.0 m",
    label: "EFI17267: Safe working load according to IEC 61537 with support spacing 3.0 m"
  },
  {
    featureId: "EFI17268",
    featureDesc: "Safe working load according to IEC 61537 with support spacing 4.0 m",
    label: "EFI17268: Safe working load according to IEC 61537 with support spacing 4.0 m"
  },
  {
    featureId: "EFI17269",
    featureDesc: "Safe working load according to IEC 61537 with support spacing 5.0 m",
    label: "EFI17269: Safe working load according to IEC 61537 with support spacing 5.0 m"
  },
  {
    featureId: "EFI17270",
    featureDesc: "Safe working load according to IEC 61537 with support spacing 6.0 m",
    label: "EFI17270: Safe working load according to IEC 61537 with support spacing 6.0 m"
  },
  {
    featureId: "EFI17271",
    featureDesc: "Safe working load according to IEC 61537 with support spacing 7.0 m",
    label: "EFI17271: Safe working load according to IEC 61537 with support spacing 7.0 m"
  },
  {
    featureId: "EFI17272",
    featureDesc: "Safe working load according to IEC 61537 with support spacing 8.0 m",
    label: "EFI17272: Safe working load according to IEC 61537 with support spacing 8.0 m"
  },
  {
    featureId: "EFI17273",
    featureDesc: "Safe working load according to IEC 61537 with support spacing 10.0 m",
    label: "EFI17273: Safe working load according to IEC 61537 with support spacing 10.0 m"
  },
  {
    featureId: "EFI17275",
    featureDesc: "Crack bridging ability according to EN 14891",
    label: "EFI17275: Crack bridging ability according to EN 14891"
  },
  {
    featureId: "EFI17285",
    featureDesc: "Thickness roof element (without battens)",
    label: "EFI17285: Thickness roof element (without battens)"
  },
  {
    featureId: "EFI17309",
    featureDesc: "Tilting height",
    label: "EFI17309: Tilting height"
  },
  {
    featureId: "EFI17315",
    featureDesc: "Min. distance maintenance door",
    label: "EFI17315: Min. distance maintenance door"
  },
  {
    featureId: "EFI17316",
    featureDesc: "Sound insulation thickness",
    label: "EFI17316: Sound insulation thickness"
  },
  {
    featureId: "EFI17326",
    featureDesc: "Inside dimension 1",
    label: "EFI17326: Inside dimension 1"
  },
  {
    featureId: "EFI17328",
    featureDesc: "Inside dimension 2",
    label: "EFI17328: Inside dimension 2"
  },
  {
    featureId: "EFI17330",
    featureDesc: "Centre distance fastening provision for cover plate",
    label: "EFI17330: Centre distance fastening provision for cover plate"
  },
  {
    featureId: "EFI17358",
    featureDesc: "Strand diameter",
    label: "EFI17358: Strand diameter"
  },
  {
    featureId: "EFI17366",
    featureDesc: "Welding thickness on steel (SMAW)",
    label: "EFI17366: Welding thickness on steel (SMAW)"
  },
  {
    featureId: "EFI17367",
    featureDesc: "Welding thickness on aluminium (SMAW)",
    label: "EFI17367: Welding thickness on aluminium (SMAW)"
  },
  {
    featureId: "EFI17368",
    featureDesc: "Welding thickness on aluminium (GTAW)",
    label: "EFI17368: Welding thickness on aluminium (GTAW)"
  },
  {
    featureId: "EFI17369",
    featureDesc: "Welding thickness on steel (GMAW)",
    label: "EFI17369: Welding thickness on steel (GMAW)"
  },
  {
    featureId: "EFI17370",
    featureDesc: "Welding thickness on aluminium (GMAW)",
    label: "EFI17370: Welding thickness on aluminium (GMAW)"
  },
  {
    featureId: "EFI17371",
    featureDesc: "Coated electrode diameter (SMAW)",
    label: "EFI17371: Coated electrode diameter (SMAW)"
  },
  {
    featureId: "EFI17376",
    featureDesc: "Welding thickness on steel (GTAW)",
    label: "EFI17376: Welding thickness on steel (GTAW)"
  },
  {
    featureId: "EFI17380",
    featureDesc: "Diameter transverse wire",
    label: "EFI17380: Diameter transverse wire"
  },
  {
    featureId: "EFI17388",
    featureDesc: "Air flow/pressure input",
    label: "EFI17388: Air flow/pressure input"
  },
  {
    featureId: "EFI17392",
    featureDesc: "Torch length",
    label: "EFI17392: Torch length"
  },
  {
    featureId: "EFI17403",
    featureDesc: "Max. indicative span",
    label: "EFI17403: Max. indicative span"
  },
  {
    featureId: "EFI17453",
    featureDesc: "Height wall opening",
    label: "EFI17453: Height wall opening"
  },
  {
    featureId: "EFI17454",
    featureDesc: "Width wall opening",
    label: "EFI17454: Width wall opening"
  },
  {
    featureId: "EFI17460",
    featureDesc: "Profile length",
    label: "EFI17460: Profile length"
  },
  {
    featureId: "EFI17470",
    featureDesc: "Bottom clearance (max. acoustic insulation value)",
    label: "EFI17470: Bottom clearance (max. acoustic insulation value)"
  },
  {
    featureId: "EFI17484",
    featureDesc: "Water consumption eco program per operating cycle",
    label: "EFI17484: Water consumption eco program per operating cycle"
  },
  {
    featureId: "EFI17490",
    featureDesc: "Water consumption per washing cycle program eco 40-60",
    label: "EFI17490: Water consumption per washing cycle program eco 40-60"
  },
  {
    featureId: "EFI17491",
    featureDesc: "Rated capacity program eco 40-60",
    label: "EFI17491: Rated capacity program eco 40-60"
  },
  {
    featureId: "EFI17494",
    featureDesc: "Rated capacity in operating cycle washing/drying",
    label: "EFI17494: Rated capacity in operating cycle washing/drying"
  },
  {
    featureId: "EFI17496",
    featureDesc: "Water consumption per operating cycle washing and drying",
    label: "EFI17496: Water consumption per operating cycle washing and drying"
  },
  {
    featureId: "EFI17497",
    featureDesc: "Radius of action",
    label: "EFI17497: Radius of action"
  },
  {
    featureId: "EFI17506",
    featureDesc: "Wire length",
    label: "EFI17506: Wire length"
  },
  {
    featureId: "EFI17520",
    featureDesc: "Brush length",
    label: "EFI17520: Brush length"
  },
  {
    featureId: "EFI17530",
    featureDesc: "Diameter bore of the spigot",
    label: "EFI17530: Diameter bore of the spigot"
  },
  {
    featureId: "EFI17532",
    featureDesc: "Groove axis",
    label: "EFI17532: Groove axis"
  },
  {
    featureId: "EFI17533",
    featureDesc: "Airgap",
    label: "EFI17533: Airgap"
  },
  {
    featureId: "EFI17535",
    featureDesc: "Overlap width",
    label: "EFI17535: Overlap width"
  },
  {
    featureId: "EFI17536",
    featureDesc: "Length supporting peg",
    label: "EFI17536: Length supporting peg"
  },
  {
    featureId: "EFI17594",
    featureDesc: "Recommended min. clearance",
    label: "EFI17594: Recommended min. clearance"
  },
  {
    featureId: "EFI17604",
    featureDesc: "Resistance to vertical load applied in a small surface according IEC 61084-2-2",
    label: "EFI17604: Resistance to vertical load applied in a small surface according IEC 61084-2-2"
  },
  {
    featureId: "EFI17605",
    featureDesc: "Resistance to vertical load applied in a large surface according IEC 61084-2-2",
    label: "EFI17605: Resistance to vertical load applied in a large surface according IEC 61084-2-2"
  },
  {
    featureId: "EFI17653",
    featureDesc: "Min. distance between different transits",
    label: "EFI17653: Min. distance between different transits"
  },
  {
    featureId: "EFI17703",
    featureDesc: "Max. illuminated surface area",
    label: "EFI17703: Max. illuminated surface area"
  },
  {
    featureId: "EFI17704",
    featureDesc: "Mast height",
    label: "EFI17704: Mast height"
  },
  {
    featureId: "EFI17709",
    featureDesc: "Built-in width of wall for U mounting",
    label: "EFI17709: Built-in width of wall for U mounting"
  },
  {
    featureId: "EFI17710",
    featureDesc: "Stroke length actuator",
    label: "EFI17710: Stroke length actuator"
  },
  {
    featureId: "EFI17711",
    featureDesc: "Built-in width of wall for corner mounting",
    label: "EFI17711: Built-in width of wall for corner mounting"
  },
  {
    featureId: "EFI17716",
    featureDesc: "Vibration emission value ah (drilling in metal)",
    label: "EFI17716: Vibration emission value ah (drilling in metal)"
  },
  {
    featureId: "EFI17717",
    featureDesc: "Vibration uncertainty K (drilling in metal)",
    label: "EFI17717: Vibration uncertainty K (drilling in metal)"
  },
  {
    featureId: "EFI17720",
    featureDesc: "Sound pressure uncertainty K",
    label: "EFI17720: Sound pressure uncertainty K"
  },
  {
    featureId: "EFI17722",
    featureDesc: "Vibration emission value ah (impact drilling in masonry)",
    label: "EFI17722: Vibration emission value ah (impact drilling in masonry)"
  },
  {
    featureId: "EFI17723",
    featureDesc: "Vibration uncertainty K (impact drilling in masonry)",
    label: "EFI17723: Vibration uncertainty K (impact drilling in masonry)"
  },
  {
    featureId: "EFI17739",
    featureDesc: "Distance between phases",
    label: "EFI17739: Distance between phases"
  },
  {
    featureId: "EFI17777",
    featureDesc: "Adjustable height for floor covering",
    label: "EFI17777: Adjustable height for floor covering"
  },
  {
    featureId: "EFI17798",
    featureDesc: "Recommended mounting height",
    label: "EFI17798: Recommended mounting height"
  },
  {
    featureId: "EFI17813",
    featureDesc: "Vibration emission value ah (sawing wood)",
    label: "EFI17813: Vibration emission value ah (sawing wood)"
  },
  {
    featureId: "EFI17815",
    featureDesc: "Vibration uncertainty K (sawing wood)",
    label: "EFI17815: Vibration uncertainty K (sawing wood)"
  },
  {
    featureId: "EFI17817",
    featureDesc: "Vibration emission value ah (cutting sheet metal)",
    label: "EFI17817: Vibration emission value ah (cutting sheet metal)"
  },
  {
    featureId: "EFI17818",
    featureDesc: "Vibration uncertainty K (cutting sheet metal)",
    label: "EFI17818: Vibration uncertainty K (cutting sheet metal)"
  },
  {
    featureId: "EFI17819",
    featureDesc: "Rear backset",
    label: "EFI17819: Rear backset"
  },
  {
    featureId: "EFI17820",
    featureDesc: "Vibration emission value ah",
    label: "EFI17820: Vibration emission value ah"
  },
  {
    featureId: "EFI17821",
    featureDesc: "Vibration uncertainty K",
    label: "EFI17821: Vibration uncertainty K"
  },
  {
    featureId: "EFI17822",
    featureDesc: "Vibration emission value ah (hammer drilling in concrete)",
    label: "EFI17822: Vibration emission value ah (hammer drilling in concrete)"
  },
  {
    featureId: "EFI17823",
    featureDesc: "Vibration uncertainty K (hammer drilling in concrete)",
    label: "EFI17823: Vibration uncertainty K (hammer drilling in concrete)"
  },
  {
    featureId: "EFI17825",
    featureDesc: "Vibration emission value ah (chiseling)",
    label: "EFI17825: Vibration emission value ah (chiseling)"
  },
  {
    featureId: "EFI17827",
    featureDesc: "Vibrations uncertainty K (chiseling)",
    label: "EFI17827: Vibrations uncertainty K (chiseling)"
  },
  {
    featureId: "EFI17837",
    featureDesc: "Vibration emission value ah (screw driving)",
    label: "EFI17837: Vibration emission value ah (screw driving)"
  },
  {
    featureId: "EFI17838",
    featureDesc: "Vibration uncertainty K (screw driving)",
    label: "EFI17838: Vibration uncertainty K (screw driving)"
  },
  {
    featureId: "EFI17839",
    featureDesc: "Vibration emission value ah (sanding solid wood)",
    label: "EFI17839: Vibration emission value ah (sanding solid wood)"
  },
  {
    featureId: "EFI17840",
    featureDesc: "Vibration uncertainty K (sanding solid wood)",
    label: "EFI17840: Vibration uncertainty K (sanding solid wood)"
  },
  {
    featureId: "EFI17847",
    featureDesc: "Max. jamb depth",
    label: "EFI17847: Max. jamb depth"
  },
  {
    featureId: "EFI17856",
    featureDesc: "Vibration emission value ah (impact drilling in concrete)",
    label: "EFI17856: Vibration emission value ah (impact drilling in concrete)"
  },
  {
    featureId: "EFI17857",
    featureDesc: "Vibration uncertainty K (Impact drilling in concrete)",
    label: "EFI17857: Vibration uncertainty K (Impact drilling in concrete)"
  },
  {
    featureId: "EFI17858",
    featureDesc: "Vibration emission value ah (tightening screws and nuts of max. size)",
    label: "EFI17858: Vibration emission value ah (tightening screws and nuts of max. size)"
  },
  {
    featureId: "EFI17859",
    featureDesc: "Vibration uncertainty K (tightening screws and nuts of max. size)",
    label: "EFI17859: Vibration uncertainty K (tightening screws and nuts of max. size)"
  },
  {
    featureId: "EFI17862",
    featureDesc: "Vibration emission value ah (sawing sheet metal)",
    label: "EFI17862: Vibration emission value ah (sawing sheet metal)"
  },
  {
    featureId: "EFI17863",
    featureDesc: "Vibration uncertainty K (sawing sheet metal)",
    label: "EFI17863: Vibration uncertainty K (sawing sheet metal)"
  },
  {
    featureId: "EFI17864",
    featureDesc: "Vibration emission value ah (fastening in wood)",
    label: "EFI17864: Vibration emission value ah (fastening in wood)"
  },
  {
    featureId: "EFI17865",
    featureDesc: "Vibration uncertainty K (fastening in wood)",
    label: "EFI17865: Vibration uncertainty K (fastening in wood)"
  },
  {
    featureId: "EFI17866",
    featureDesc: "Vibration emission value ah (surface grinding (roughing))",
    label: "EFI17866: Vibration emission value ah (surface grinding (roughing))"
  },
  {
    featureId: "EFI17867",
    featureDesc: "Vibration uncertainty K (surface grinding (roughing))",
    label: "EFI17867: Vibration uncertainty K (surface grinding (roughing))"
  },
  {
    featureId: "EFI17868",
    featureDesc: "Vibration emission value ah (sanding with sanding sheet)",
    label: "EFI17868: Vibration emission value ah (sanding with sanding sheet)"
  },
  {
    featureId: "EFI17869",
    featureDesc: "Vibration uncertainty K (sanding with sanding sheet)",
    label: "EFI17869: Vibration uncertainty K (sanding with sanding sheet)"
  },
  {
    featureId: "EFI17879",
    featureDesc: "Vibration emission value ah (routing edges)",
    label: "EFI17879: Vibration emission value ah (routing edges)"
  },
  {
    featureId: "EFI17880",
    featureDesc: "Vibration uncertainty K (routing edges)",
    label: "EFI17880: Vibration uncertainty K (routing edges)"
  },
  {
    featureId: "EFI17881",
    featureDesc: "Vibration emission value ah (surface grinding, grinding tool diameter of 25 mm)",
    label: "EFI17881: Vibration emission value ah (surface grinding, grinding tool diameter of 25 mm)"
  },
  {
    featureId: "EFI17882",
    featureDesc: "Vibration uncertainty K (surface grinding, grinding tool diameter of 25 mm)",
    label: "EFI17882: Vibration uncertainty K (surface grinding, grinding tool diameter of 25 mm)"
  },
  {
    featureId: "EFI17884",
    featureDesc: "Vibration emission value ah (surface grinding up to diameter of 50 mm)",
    label: "EFI17884: Vibration emission value ah (surface grinding up to diameter of 50 mm)"
  },
  {
    featureId: "EFI17886",
    featureDesc: "Vibration uncertainty K (surface grinding up to diameter of 50 mm)",
    label: "EFI17886: Vibration uncertainty K (surface grinding up to diameter of 50 mm)"
  },
  {
    featureId: "EFI17887",
    featureDesc: "Vibration emission value ah (sawing metal)",
    label: "EFI17887: Vibration emission value ah (sawing metal)"
  },
  {
    featureId: "EFI17888",
    featureDesc: "Vibration uncertainty K (sawing metal)",
    label: "EFI17888: Vibration uncertainty K (sawing metal)"
  },
  {
    featureId: "EFI17890",
    featureDesc: "Height adjustment range",
    label: "EFI17890: Height adjustment range"
  },
  {
    featureId: "EFI17891",
    featureDesc: "Side adjustment range",
    label: "EFI17891: Side adjustment range"
  },
  {
    featureId: "EFI17892",
    featureDesc: "Tilt adjustment range",
    label: "EFI17892: Tilt adjustment range"
  },
  {
    featureId: "EFI17893",
    featureDesc: "Drawer length",
    label: "EFI17893: Drawer length"
  },
  {
    featureId: "EFI17894",
    featureDesc: "Travel length",
    label: "EFI17894: Travel length"
  },
  {
    featureId: "EFI17897",
    featureDesc: "Max. shelf thickness",
    label: "EFI17897: Max. shelf thickness"
  },
  {
    featureId: "EFI17899",
    featureDesc: "Load bearing capacity per piece",
    label: "EFI17899: Load bearing capacity per piece"
  },
  {
    featureId: "EFI17901",
    featureDesc: "Vibration emission value ah (sawing with plunge-cutting saw blade)",
    label: "EFI17901: Vibration emission value ah (sawing with plunge-cutting saw blade)"
  },
  {
    featureId: "EFI17902",
    featureDesc: "Vibration uncertainty K (sawing with plunge-cutting saw blade)",
    label: "EFI17902: Vibration uncertainty K (sawing with plunge-cutting saw blade)"
  },
  {
    featureId: "EFI17903",
    featureDesc: "Vibration emission value ah (sawing with segment saw blade)",
    label: "EFI17903: Vibration emission value ah (sawing with segment saw blade)"
  },
  {
    featureId: "EFI17904",
    featureDesc: "Vibration uncertainty K (sawing with segment saw blade)",
    label: "EFI17904: Vibration uncertainty K (sawing with segment saw blade)"
  },
  {
    featureId: "EFI17905",
    featureDesc: "Vibration uncertainty K (grinding)",
    label: "EFI17905: Vibration uncertainty K (grinding)"
  },
  {
    featureId: "EFI17906",
    featureDesc: "Vibration emission value ah (grinding)",
    label: "EFI17906: Vibration emission value ah (grinding)"
  },
  {
    featureId: "EFI17907",
    featureDesc: "Vibration emission value an (polishing)",
    label: "EFI17907: Vibration emission value an (polishing)"
  },
  {
    featureId: "EFI17908",
    featureDesc: "Vibration uncertainty K (polishing)",
    label: "EFI17908: Vibration uncertainty K (polishing)"
  },
  {
    featureId: "EFI17918",
    featureDesc: "Max. cable length to push buttons",
    label: "EFI17918: Max. cable length to push buttons"
  },
  {
    featureId: "EFI17919",
    featureDesc: "Max. cable length to window",
    label: "EFI17919: Max. cable length to window"
  },
  {
    featureId: "EFI17926",
    featureDesc: "Opening speed",
    label: "EFI17926: Opening speed"
  },
  {
    featureId: "EFI17934",
    featureDesc: "Opening speed",
    label: "EFI17934: Opening speed"
  },
  {
    featureId: "EFI17936",
    featureDesc: "Min. door leaf height",
    label: "EFI17936: Min. door leaf height"
  },
  {
    featureId: "EFI18061",
    featureDesc: "Drill capacity in steel",
    label: "EFI18061: Drill capacity in steel"
  },
  {
    featureId: "EFI18069",
    featureDesc: "Min. screwing depth in wood",
    label: "EFI18069: Min. screwing depth in wood"
  },
  {
    featureId: "EFI18074",
    featureDesc: "Max. petrol cylinder capacity",
    label: "EFI18074: Max. petrol cylinder capacity"
  },
  {
    featureId: "EFI18075",
    featureDesc: "Max. diesel cylinder capacity",
    label: "EFI18075: Max. diesel cylinder capacity"
  },
  {
    featureId: "EFI18094",
    featureDesc: "Gas flow rate",
    label: "EFI18094: Gas flow rate"
  },
  {
    featureId: "EFI18099",
    featureDesc: "Safe working load according to IEC 61537 with support spacing 9.0 m",
    label: "EFI18099: Safe working load according to IEC 61537 with support spacing 9.0 m"
  },
  {
    featureId: "EFI18100",
    featureDesc: "Safe working load according to IEC 61537 with support spacing 11.0 m",
    label: "EFI18100: Safe working load according to IEC 61537 with support spacing 11.0 m"
  },
  {
    featureId: "EFI18101",
    featureDesc: "Safe working load according to IEC 61537 with support spacing 12.0 m",
    label: "EFI18101: Safe working load according to IEC 61537 with support spacing 12.0 m"
  },
  {
    featureId: "EFI18111",
    featureDesc: "Cable guide head diameter",
    label: "EFI18111: Cable guide head diameter"
  },
  {
    featureId: "EFI18129",
    featureDesc: "Centre distance tap connection",
    label: "EFI18129: Centre distance tap connection"
  },
  {
    featureId: "EFI18135",
    featureDesc: "Shelf thickness",
    label: "EFI18135: Shelf thickness"
  },
  {
    featureId: "EFI18143",
    featureDesc: "Overhang length",
    label: "EFI18143: Overhang length"
  },
  {
    featureId: "EFI18152",
    featureDesc: "Max. side profile thickness drawer",
    label: "EFI18152: Max. side profile thickness drawer"
  },
  {
    featureId: "EFI18163",
    featureDesc: "Leaf width",
    label: "EFI18163: Leaf width"
  },
  {
    featureId: "EFI18181",
    featureDesc: "Core diameter",
    label: "EFI18181: Core diameter"
  },
  {
    featureId: "EFI20000",
    featureDesc: "Density",
    label: "EFI20000: Density"
  },
  {
    featureId: "EFI20004",
    featureDesc: "Roof pitch",
    label: "EFI20004: Roof pitch"
  },
  {
    featureId: "EFI20009",
    featureDesc: "Length above roof (including cover)",
    label: "EFI20009: Length above roof (including cover)"
  },
  {
    featureId: "EFI20017",
    featureDesc: "Thermal conductivity",
    label: "EFI20017: Thermal conductivity"
  },
  {
    featureId: "EFI20020",
    featureDesc: "Nominal diameter",
    label: "EFI20020: Nominal diameter"
  },
  {
    featureId: "EFI20021",
    featureDesc: "Construction length",
    label: "EFI20021: Construction length"
  },
  {
    featureId: "EFI20023",
    featureDesc: "Max. medium temperature (continuous)",
    label: "EFI20023: Max. medium temperature (continuous)"
  },
  {
    featureId: "EFI20029",
    featureDesc: "Wall/partition/floor thickness",
    label: "EFI20029: Wall/partition/floor thickness"
  },
  {
    featureId: "EFI20034",
    featureDesc: "Tap threshold",
    label: "EFI20034: Tap threshold"
  },
  {
    featureId: "EFI20042",
    featureDesc: "Bend radius",
    label: "EFI20042: Bend radius"
  },
  {
    featureId: "EFI20058",
    featureDesc: "Outer connection diameter",
    label: "EFI20058: Outer connection diameter"
  },
  {
    featureId: "EFI20064",
    featureDesc: "Outer diameter, flanged bush",
    label: "EFI20064: Outer diameter, flanged bush"
  },
  {
    featureId: "EFI20089",
    featureDesc: "Min. return temperature",
    label: "EFI20089: Min. return temperature"
  },
  {
    featureId: "EFI20092",
    featureDesc: "Hot tap water capacity at 60 °C",
    label: "EFI20092: Hot tap water capacity at 60 °C"
  },
  {
    featureId: "EFI20114",
    featureDesc: "Length of capillary",
    label: "EFI20114: Length of capillary"
  },
  {
    featureId: "EFI20122",
    featureDesc: "Outer pipe diameter connection 1",
    label: "EFI20122: Outer pipe diameter connection 1"
  },
  {
    featureId: "EFI20123",
    featureDesc: "Outer pipe diameter connection 2",
    label: "EFI20123: Outer pipe diameter connection 2"
  },
  {
    featureId: "EFI20124",
    featureDesc: "Outer pipe diameter connection 3",
    label: "EFI20124: Outer pipe diameter connection 3"
  },
  {
    featureId: "EFI20125",
    featureDesc: "Outer pipe diameter connection 4",
    label: "EFI20125: Outer pipe diameter connection 4"
  },
  {
    featureId: "EFI20126",
    featureDesc: "Outer pipe diameter all connections",
    label: "EFI20126: Outer pipe diameter all connections"
  },
  {
    featureId: "EFI20130",
    featureDesc: "Dehumidification capacity",
    label: "EFI20130: Dehumidification capacity"
  },
  {
    featureId: "EFI20139",
    featureDesc: "Outer pipe diameter suction pipe",
    label: "EFI20139: Outer pipe diameter suction pipe"
  },
  {
    featureId: "EFI20142",
    featureDesc: "Outer pipe diameter heating",
    label: "EFI20142: Outer pipe diameter heating"
  },
  {
    featureId: "EFI20151",
    featureDesc: "Length of connection 1",
    label: "EFI20151: Length of connection 1"
  },
  {
    featureId: "EFI20152",
    featureDesc: "Length of connection 2",
    label: "EFI20152: Length of connection 2"
  },
  {
    featureId: "EFI20153",
    featureDesc: "Working length connection 1",
    label: "EFI20153: Working length connection 1"
  },
  {
    featureId: "EFI20154",
    featureDesc: "Working length connection 2",
    label: "EFI20154: Working length connection 2"
  },
  {
    featureId: "EFI20155",
    featureDesc: "Handle/hand wheel height",
    label: "EFI20155: Handle/hand wheel height"
  },
  {
    featureId: "EFI20156",
    featureDesc: "Length of handle/diameter of hand wheel",
    label: "EFI20156: Length of handle/diameter of hand wheel"
  },
  {
    featureId: "EFI20159",
    featureDesc: "Length of connection 3",
    label: "EFI20159: Length of connection 3"
  },
  {
    featureId: "EFI20160",
    featureDesc: "Working length connection 3",
    label: "EFI20160: Working length connection 3"
  },
  {
    featureId: "EFI20164",
    featureDesc: "Handle height",
    label: "EFI20164: Handle height"
  },
  {
    featureId: "EFI20165",
    featureDesc: "Handgrip length",
    label: "EFI20165: Handgrip length"
  },
  {
    featureId: "EFI20168",
    featureDesc: "Working length connection 4",
    label: "EFI20168: Working length connection 4"
  },
  {
    featureId: "EFI20169",
    featureDesc: "Length of connection 4",
    label: "EFI20169: Length of connection 4"
  },
  {
    featureId: "EFI20173",
    featureDesc: "Passage",
    label: "EFI20173: Passage"
  },
  {
    featureId: "EFI20175",
    featureDesc: "Handle/spindle height",
    label: "EFI20175: Handle/spindle height"
  },
  {
    featureId: "EFI20176",
    featureDesc: "Length of key/handle",
    label: "EFI20176: Length of key/handle"
  },
  {
    featureId: "EFI20181",
    featureDesc: "Ground cover",
    label: "EFI20181: Ground cover"
  },
  {
    featureId: "EFI20187",
    featureDesc: "Column base diameter",
    label: "EFI20187: Column base diameter"
  },
  {
    featureId: "EFI20188",
    featureDesc: "Max. ambient temperature",
    label: "EFI20188: Max. ambient temperature"
  },
  {
    featureId: "EFI20195",
    featureDesc: "Axle height",
    label: "EFI20195: Axle height"
  },
  {
    featureId: "EFI20197",
    featureDesc: "Column height",
    label: "EFI20197: Column height"
  },
  {
    featureId: "EFI20200",
    featureDesc: "Distance centre-to-centre",
    label: "EFI20200: Distance centre-to-centre"
  },
  {
    featureId: "EFI20202",
    featureDesc: "Outer pipe diameter connection 5",
    label: "EFI20202: Outer pipe diameter connection 5"
  },
  {
    featureId: "EFI20203",
    featureDesc: "Length of connection 5",
    label: "EFI20203: Length of connection 5"
  },
  {
    featureId: "EFI20204",
    featureDesc: "Working length connection 5",
    label: "EFI20204: Working length connection 5"
  },
  {
    featureId: "EFI20206",
    featureDesc: "Response temperature",
    label: "EFI20206: Response temperature"
  },
  {
    featureId: "EFI20208",
    featureDesc: "Outer pipe diameter connection",
    label: "EFI20208: Outer pipe diameter connection"
  },
  {
    featureId: "EFI20223",
    featureDesc: "Wall thickness pipe",
    label: "EFI20223: Wall thickness pipe"
  },
  {
    featureId: "EFI20225",
    featureDesc: "Thickness flange",
    label: "EFI20225: Thickness flange"
  },
  {
    featureId: "EFI20226",
    featureDesc: "Inner diameter of push-on flange",
    label: "EFI20226: Inner diameter of push-on flange"
  },
  {
    featureId: "EFI20228",
    featureDesc: "Outer diameter, flange edge",
    label: "EFI20228: Outer diameter, flange edge"
  },
  {
    featureId: "EFI20260",
    featureDesc: "Min. flushing water quantity",
    label: "EFI20260: Min. flushing water quantity"
  },
  {
    featureId: "EFI20279",
    featureDesc: "Insert length",
    label: "EFI20279: Insert length"
  },
  {
    featureId: "EFI20280",
    featureDesc: "Housing height",
    label: "EFI20280: Housing height"
  },
  {
    featureId: "EFI20281",
    featureDesc: "Housing width",
    label: "EFI20281: Housing width"
  },
  {
    featureId: "EFI20282",
    featureDesc: "Housing depth",
    label: "EFI20282: Housing depth"
  },
  {
    featureId: "EFI20289",
    featureDesc: "Pump diameter",
    label: "EFI20289: Pump diameter"
  },
  {
    featureId: "EFI20290",
    featureDesc: "Pump and motor length",
    label: "EFI20290: Pump and motor length"
  },
  {
    featureId: "EFI20298",
    featureDesc: "Combination height",
    label: "EFI20298: Combination height"
  },
  {
    featureId: "EFI20299",
    featureDesc: "Toilet height",
    label: "EFI20299: Toilet height"
  },
  {
    featureId: "EFI20311",
    featureDesc: "Nominal duct diameter",
    label: "EFI20311: Nominal duct diameter"
  },
  {
    featureId: "EFI20312",
    featureDesc: "Nominal duct width",
    label: "EFI20312: Nominal duct width"
  },
  {
    featureId: "EFI20313",
    featureDesc: "Nominal duct height",
    label: "EFI20313: Nominal duct height"
  },
  {
    featureId: "EFI20314",
    featureDesc: "Free passage",
    label: "EFI20314: Free passage"
  },
  {
    featureId: "EFI20331",
    featureDesc: "Lever length",
    label: "EFI20331: Lever length"
  },
  {
    featureId: "EFI20332",
    featureDesc: "Weight diameter",
    label: "EFI20332: Weight diameter"
  },
  {
    featureId: "EFI20351",
    featureDesc: "Tap capacity at 60 °C, continuous",
    label: "EFI20351: Tap capacity at 60 °C, continuous"
  },
  {
    featureId: "EFI20364",
    featureDesc: "Min. headroom",
    label: "EFI20364: Min. headroom"
  },
  {
    featureId: "EFI20365",
    featureDesc: "Mesh width of filter element",
    label: "EFI20365: Mesh width of filter element"
  },
  {
    featureId: "EFI20368",
    featureDesc: "Height above pipe",
    label: "EFI20368: Height above pipe"
  },
  {
    featureId: "EFI20369",
    featureDesc: "Height below pipe",
    label: "EFI20369: Height below pipe"
  },
  {
    featureId: "EFI20382",
    featureDesc: "Min. bending radius",
    label: "EFI20382: Min. bending radius"
  },
  {
    featureId: "EFI20383",
    featureDesc: "Expansion coefficient",
    label: "EFI20383: Expansion coefficient"
  },
  {
    featureId: "EFI20392",
    featureDesc: "Outer pipe diameter connections 2 and 3",
    label: "EFI20392: Outer pipe diameter connections 2 and 3"
  },
  {
    featureId: "EFI20394",
    featureDesc: "Connection offset",
    label: "EFI20394: Connection offset"
  },
  {
    featureId: "EFI20395",
    featureDesc: "Length of connections 2 and 3",
    label: "EFI20395: Length of connections 2 and 3"
  },
  {
    featureId: "EFI20396",
    featureDesc: "Working length connection 2 and 3",
    label: "EFI20396: Working length connection 2 and 3"
  },
  {
    featureId: "EFI20397",
    featureDesc: "Offset",
    label: "EFI20397: Offset"
  },
  {
    featureId: "EFI20403",
    featureDesc: "Outer pipe diameter connection 6",
    label: "EFI20403: Outer pipe diameter connection 6"
  },
  {
    featureId: "EFI20405",
    featureDesc: "Length of connection 6",
    label: "EFI20405: Length of connection 6"
  },
  {
    featureId: "EFI20409",
    featureDesc: "Outer pipe diameter connection 7",
    label: "EFI20409: Outer pipe diameter connection 7"
  },
  {
    featureId: "EFI20410",
    featureDesc: "Outer pipe diameter connection 8",
    label: "EFI20410: Outer pipe diameter connection 8"
  },
  {
    featureId: "EFI20411",
    featureDesc: "Fall pipe offset",
    label: "EFI20411: Fall pipe offset"
  },
  {
    featureId: "EFI20412",
    featureDesc: "Length of connection 7",
    label: "EFI20412: Length of connection 7"
  },
  {
    featureId: "EFI20413",
    featureDesc: "Length of connection 8",
    label: "EFI20413: Length of connection 8"
  },
  {
    featureId: "EFI20417",
    featureDesc: "Length from wall plate",
    label: "EFI20417: Length from wall plate"
  },
  {
    featureId: "EFI20421",
    featureDesc: "Nominal duct diameter, connection 1",
    label: "EFI20421: Nominal duct diameter, connection 1"
  },
  {
    featureId: "EFI20422",
    featureDesc: "Nominal duct diameter, connection 2",
    label: "EFI20422: Nominal duct diameter, connection 2"
  },
  {
    featureId: "EFI20424",
    featureDesc: "Nominal duct diameter, connection 3",
    label: "EFI20424: Nominal duct diameter, connection 3"
  },
  {
    featureId: "EFI20425",
    featureDesc: "Nominal duct diameter, connection 4",
    label: "EFI20425: Nominal duct diameter, connection 4"
  },
  {
    featureId: "EFI20426",
    featureDesc: "Nominal duct diameter, main duct",
    label: "EFI20426: Nominal duct diameter, main duct"
  },
  {
    featureId: "EFI20429",
    featureDesc: "Outer diameter mantle pipe",
    label: "EFI20429: Outer diameter mantle pipe"
  },
  {
    featureId: "EFI20432",
    featureDesc: "Min. ambient temperature",
    label: "EFI20432: Min. ambient temperature"
  },
  {
    featureId: "EFI20440",
    featureDesc: "Max. gas testing volume",
    label: "EFI20440: Max. gas testing volume"
  },
  {
    featureId: "EFI20448",
    featureDesc: "Max. supply temperature (operating temperature)",
    label: "EFI20448: Max. supply temperature (operating temperature)"
  },
  {
    featureId: "EFI20453",
    featureDesc: "Collector surface area",
    label: "EFI20453: Collector surface area"
  },
  {
    featureId: "EFI20462",
    featureDesc: "Length of collector",
    label: "EFI20462: Length of collector"
  },
  {
    featureId: "EFI20463",
    featureDesc: "Width, collector",
    label: "EFI20463: Width, collector"
  },
  {
    featureId: "EFI20464",
    featureDesc: "Height, collector",
    label: "EFI20464: Height, collector"
  },
  {
    featureId: "EFI20465",
    featureDesc: "Length of boiler",
    label: "EFI20465: Length of boiler"
  },
  {
    featureId: "EFI20466",
    featureDesc: "Width, boiler",
    label: "EFI20466: Width, boiler"
  },
  {
    featureId: "EFI20467",
    featureDesc: "Height, boiler",
    label: "EFI20467: Height, boiler"
  },
  {
    featureId: "EFI20469",
    featureDesc: "Outer pipe diameter supply",
    label: "EFI20469: Outer pipe diameter supply"
  },
  {
    featureId: "EFI20475",
    featureDesc: "Outer pipe diameter return",
    label: "EFI20475: Outer pipe diameter return"
  },
  {
    featureId: "EFI20482",
    featureDesc: "Outer pipe diameter fuel",
    label: "EFI20482: Outer pipe diameter fuel"
  },
  {
    featureId: "EFI20489",
    featureDesc: "Nominal duct width, air supply",
    label: "EFI20489: Nominal duct width, air supply"
  },
  {
    featureId: "EFI20499",
    featureDesc: "Outer pipe diameter cold tap water",
    label: "EFI20499: Outer pipe diameter cold tap water"
  },
  {
    featureId: "EFI20504",
    featureDesc: "Outer pipe diameter hot tap water",
    label: "EFI20504: Outer pipe diameter hot tap water"
  },
  {
    featureId: "EFI20511",
    featureDesc: "Outer pipe diameter condensate",
    label: "EFI20511: Outer pipe diameter condensate"
  },
  {
    featureId: "EFI20525",
    featureDesc: "Centre distance, supply/return",
    label: "EFI20525: Centre distance, supply/return"
  },
  {
    featureId: "EFI20526",
    featureDesc: "Mounted length",
    label: "EFI20526: Mounted length"
  },
  {
    featureId: "EFI20527",
    featureDesc: "Length of tail piece connection",
    label: "EFI20527: Length of tail piece connection"
  },
  {
    featureId: "EFI20528",
    featureDesc: "Working length of tail piece connection",
    label: "EFI20528: Working length of tail piece connection"
  },
  {
    featureId: "EFI20529",
    featureDesc: "Tap connection length",
    label: "EFI20529: Tap connection length"
  },
  {
    featureId: "EFI20530",
    featureDesc: "Working length of tap connection",
    label: "EFI20530: Working length of tap connection"
  },
  {
    featureId: "EFI20531",
    featureDesc: "Working length of supply and return connection",
    label: "EFI20531: Working length of supply and return connection"
  },
  {
    featureId: "EFI20532",
    featureDesc: "Centre distance of feed/tap connection",
    label: "EFI20532: Centre distance of feed/tap connection"
  },
  {
    featureId: "EFI20535",
    featureDesc: "Length of pipe insert",
    label: "EFI20535: Length of pipe insert"
  },
  {
    featureId: "EFI20539",
    featureDesc: "Flame head extension",
    label: "EFI20539: Flame head extension"
  },
  {
    featureId: "EFI20544",
    featureDesc: "Outer pipe diameter, gas connection",
    label: "EFI20544: Outer pipe diameter, gas connection"
  },
  {
    featureId: "EFI20545",
    featureDesc: "Outer duct diameter, air supply",
    label: "EFI20545: Outer duct diameter, air supply"
  },
  {
    featureId: "EFI20551",
    featureDesc: "Lug distance, connection 2",
    label: "EFI20551: Lug distance, connection 2"
  },
  {
    featureId: "EFI20557",
    featureDesc: "Diameter, connecting pipes",
    label: "EFI20557: Diameter, connecting pipes"
  },
  {
    featureId: "EFI20559",
    featureDesc: "Min. centre-to-centre distance",
    label: "EFI20559: Min. centre-to-centre distance"
  },
  {
    featureId: "EFI20563",
    featureDesc: "Medium density",
    label: "EFI20563: Medium density"
  },
  {
    featureId: "EFI20589",
    featureDesc: "Diameter, connection level gauge/body",
    label: "EFI20589: Diameter, connection level gauge/body"
  },
  {
    featureId: "EFI20593",
    featureDesc: "Flow rate at delta p = 1 bar",
    label: "EFI20593: Flow rate at delta p = 1 bar"
  },
  {
    featureId: "EFI20596",
    featureDesc: "Diameter housing",
    label: "EFI20596: Diameter housing"
  },
  {
    featureId: "EFI20607",
    featureDesc: "Max. suction height",
    label: "EFI20607: Max. suction height"
  },
  {
    featureId: "EFI20609",
    featureDesc: "Air volume required",
    label: "EFI20609: Air volume required"
  },
  {
    featureId: "EFI20616",
    featureDesc: "Built-in width of wall for mounting with door",
    label: "EFI20616: Built-in width of wall for mounting with door"
  },
  {
    featureId: "EFI20617",
    featureDesc: "Built-in width of wall for mounting in line",
    label: "EFI20617: Built-in width of wall for mounting in line"
  },
  {
    featureId: "EFI20619",
    featureDesc: "Largest width",
    label: "EFI20619: Largest width"
  },
  {
    featureId: "EFI20620",
    featureDesc: "Smallest width",
    label: "EFI20620: Smallest width"
  },
  {
    featureId: "EFI20621",
    featureDesc: "Radius",
    label: "EFI20621: Radius"
  },
  {
    featureId: "EFI20624",
    featureDesc: "Short straight side",
    label: "EFI20624: Short straight side"
  },
  {
    featureId: "EFI20625",
    featureDesc: "Slanting side",
    label: "EFI20625: Slanting side"
  },
  {
    featureId: "EFI20629",
    featureDesc: "Built-in width for mounting in niche",
    label: "EFI20629: Built-in width for mounting in niche"
  },
  {
    featureId: "EFI20630",
    featureDesc: "Built-in width for mounting in line",
    label: "EFI20630: Built-in width for mounting in line"
  },
  {
    featureId: "EFI20631",
    featureDesc: "Built-in width for mounting with side wall",
    label: "EFI20631: Built-in width for mounting with side wall"
  },
  {
    featureId: "EFI20632",
    featureDesc: "Overall height from bath edge",
    label: "EFI20632: Overall height from bath edge"
  },
  {
    featureId: "EFI20710",
    featureDesc: "Diameter of drain hole",
    label: "EFI20710: Diameter of drain hole"
  },
  {
    featureId: "EFI20750",
    featureDesc: "Max. sheet thickness",
    label: "EFI20750: Max. sheet thickness"
  },
  {
    featureId: "EFI20751",
    featureDesc: "Mounting size",
    label: "EFI20751: Mounting size"
  },
  {
    featureId: "EFI20752",
    featureDesc: "Height above sheet",
    label: "EFI20752: Height above sheet"
  },
  {
    featureId: "EFI20759",
    featureDesc: "Thickness of thermal insulation",
    label: "EFI20759: Thickness of thermal insulation"
  },
  {
    featureId: "EFI20764",
    featureDesc: "Connection size of filling pipe",
    label: "EFI20764: Connection size of filling pipe"
  },
  {
    featureId: "EFI20765",
    featureDesc: "Filling capacity",
    label: "EFI20765: Filling capacity"
  },
  {
    featureId: "EFI20767",
    featureDesc: "Max. installation contents",
    label: "EFI20767: Max. installation contents"
  },
  {
    featureId: "EFI20769",
    featureDesc: "Inlet/outlet offset distance",
    label: "EFI20769: Inlet/outlet offset distance"
  },
  {
    featureId: "EFI20772",
    featureDesc: "Filter volume",
    label: "EFI20772: Filter volume"
  },
  {
    featureId: "EFI20773",
    featureDesc: "Filter mesh density",
    label: "EFI20773: Filter mesh density"
  },
  {
    featureId: "EFI20789",
    featureDesc: "Outlet overhang length",
    label: "EFI20789: Outlet overhang length"
  },
  {
    featureId: "EFI20796",
    featureDesc: "Total construction height",
    label: "EFI20796: Total construction height"
  },
  {
    featureId: "EFI20797",
    featureDesc: "Construction height, underside of tap nozzle",
    label: "EFI20797: Construction height, underside of tap nozzle"
  },
  {
    featureId: "EFI20806",
    featureDesc: "Suitable for nominal duct diameter",
    label: "EFI20806: Suitable for nominal duct diameter"
  },
  {
    featureId: "EFI20809",
    featureDesc: "Exhaust end duct width",
    label: "EFI20809: Exhaust end duct width"
  },
  {
    featureId: "EFI20810",
    featureDesc: "Suction end duct height",
    label: "EFI20810: Suction end duct height"
  },
  {
    featureId: "EFI20811",
    featureDesc: "Suction end duct width",
    label: "EFI20811: Suction end duct width"
  },
  {
    featureId: "EFI20819",
    featureDesc: "Outer pipe diameter tap water",
    label: "EFI20819: Outer pipe diameter tap water"
  },
  {
    featureId: "EFI20823",
    featureDesc: "Outer pipe diameter reservoir side",
    label: "EFI20823: Outer pipe diameter reservoir side"
  },
  {
    featureId: "EFI20848",
    featureDesc: "Length (nominal)",
    label: "EFI20848: Length (nominal)"
  },
  {
    featureId: "EFI20849",
    featureDesc: "Width (nominal)",
    label: "EFI20849: Width (nominal)"
  },
  {
    featureId: "EFI20850",
    featureDesc: "Thickness (nominal)",
    label: "EFI20850: Thickness (nominal)"
  },
  {
    featureId: "EFI20852",
    featureDesc: "Length of tile on mat",
    label: "EFI20852: Length of tile on mat"
  },
  {
    featureId: "EFI20853",
    featureDesc: "Width of tile on mat",
    label: "EFI20853: Width of tile on mat"
  },
  {
    featureId: "EFI20855",
    featureDesc: "Length (production size)",
    label: "EFI20855: Length (production size)"
  },
  {
    featureId: "EFI20856",
    featureDesc: "Width (production size)",
    label: "EFI20856: Width (production size)"
  },
  {
    featureId: "EFI20858",
    featureDesc: "Outer pipe diameter 1 supply",
    label: "EFI20858: Outer pipe diameter 1 supply"
  },
  {
    featureId: "EFI20859",
    featureDesc: "Outer pipe diameter 2 supply",
    label: "EFI20859: Outer pipe diameter 2 supply"
  },
  {
    featureId: "EFI20860",
    featureDesc: "Outer pipe diameter 1 return",
    label: "EFI20860: Outer pipe diameter 1 return"
  },
  {
    featureId: "EFI20861",
    featureDesc: "Outer pipe diameter 2 return",
    label: "EFI20861: Outer pipe diameter 2 return"
  },
  {
    featureId: "EFI20879",
    featureDesc: "Volume of cylinder/package",
    label: "EFI20879: Volume of cylinder/package"
  },
  {
    featureId: "EFI20880",
    featureDesc: "Volume of gas at 15 °C and 1 bar",
    label: "EFI20880: Volume of gas at 15 °C and 1 bar"
  },
  {
    featureId: "EFI20893",
    featureDesc: "Mass of gas",
    label: "EFI20893: Mass of gas"
  },
  {
    featureId: "EFI20899",
    featureDesc: "Volume of cylinder/package tank",
    label: "EFI20899: Volume of cylinder/package tank"
  },
  {
    featureId: "EFI20910",
    featureDesc: "Diameter water connection",
    label: "EFI20910: Diameter water connection"
  },
  {
    featureId: "EFI20911",
    featureDesc: "Max. horizontal movement",
    label: "EFI20911: Max. horizontal movement"
  },
  {
    featureId: "EFI20912",
    featureDesc: "Horizontal taper height",
    label: "EFI20912: Horizontal taper height"
  },
  {
    featureId: "EFI20929",
    featureDesc: "Duct width",
    label: "EFI20929: Duct width"
  },
  {
    featureId: "EFI20930",
    featureDesc: "Duct depth",
    label: "EFI20930: Duct depth"
  },
  {
    featureId: "EFI20954",
    featureDesc: "Max. through flow capacity (150 kPa)",
    label: "EFI20954: Max. through flow capacity (150 kPa)"
  },
  {
    featureId: "EFI20978",
    featureDesc: "Length of ceiling fitting bottom in mm",
    label: "EFI20978: Length of ceiling fitting bottom in mm"
  },
  {
    featureId: "EFI20985",
    featureDesc: "Wall thickness",
    label: "EFI20985: Wall thickness"
  },
  {
    featureId: "EFI20991",
    featureDesc: "Overall depth",
    label: "EFI20991: Overall depth"
  },
  {
    featureId: "EFI21031",
    featureDesc: "Height/thickness",
    label: "EFI21031: Height/thickness"
  },
  {
    featureId: "EFI21037",
    featureDesc: "Raised section",
    label: "EFI21037: Raised section"
  },
  {
    featureId: "EFI21038",
    featureDesc: "Min. mixing water use of draw-off point",
    label: "EFI21038: Min. mixing water use of draw-off point"
  },
  {
    featureId: "EFI21041",
    featureDesc: "Shower head diameter",
    label: "EFI21041: Shower head diameter"
  },
  {
    featureId: "EFI21046",
    featureDesc: "Height of back wall",
    label: "EFI21046: Height of back wall"
  },
  {
    featureId: "EFI21061",
    featureDesc: "Outer pipe diameter gas",
    label: "EFI21061: Outer pipe diameter gas"
  },
  {
    featureId: "EFI21084",
    featureDesc: "Outer pipe diameter tap water",
    label: "EFI21084: Outer pipe diameter tap water"
  },
  {
    featureId: "EFI21091",
    featureDesc: "Secondary temperature",
    label: "EFI21091: Secondary temperature"
  },
  {
    featureId: "EFI21095",
    featureDesc: "Outer pipe diameter secondary connection",
    label: "EFI21095: Outer pipe diameter secondary connection"
  },
  {
    featureId: "EFI21102",
    featureDesc: "Inner diameter hose",
    label: "EFI21102: Inner diameter hose"
  },
  {
    featureId: "EFI21115",
    featureDesc: "Boiler water volume",
    label: "EFI21115: Boiler water volume"
  },
  {
    featureId: "EFI21116",
    featureDesc: "Expansion tank water volume",
    label: "EFI21116: Expansion tank water volume"
  },
  {
    featureId: "EFI21121",
    featureDesc: "Water volume of hot tap water",
    label: "EFI21121: Water volume of hot tap water"
  },
  {
    featureId: "EFI21122",
    featureDesc: "Max. control temperature",
    label: "EFI21122: Max. control temperature"
  },
  {
    featureId: "EFI21147",
    featureDesc: "Drain connection height",
    label: "EFI21147: Drain connection height"
  },
  {
    featureId: "EFI21148",
    featureDesc: "Drain connection width",
    label: "EFI21148: Drain connection width"
  },
  {
    featureId: "EFI21151",
    featureDesc: "Centre-to-centre distance of connections",
    label: "EFI21151: Centre-to-centre distance of connections"
  },
  {
    featureId: "EFI21153",
    featureDesc: "Max. depth of exchanger",
    label: "EFI21153: Max. depth of exchanger"
  },
  {
    featureId: "EFI21155",
    featureDesc: "Hot tap water capacity at 40 °C, continuous",
    label: "EFI21155: Hot tap water capacity at 40 °C, continuous"
  },
  {
    featureId: "EFI21159",
    featureDesc: "Max. tap water temperature",
    label: "EFI21159: Max. tap water temperature"
  },
  {
    featureId: "EFI21161",
    featureDesc: "Peak consumption 10 min / 40 °C",
    label: "EFI21161: Peak consumption 10 min / 40 °C"
  },
  {
    featureId: "EFI21164",
    featureDesc: "Outer pipe diameter gas pipe",
    label: "EFI21164: Outer pipe diameter gas pipe"
  },
  {
    featureId: "EFI21174",
    featureDesc: "Nominal butterfly valve bore",
    label: "EFI21174: Nominal butterfly valve bore"
  },
  {
    featureId: "EFI21176",
    featureDesc: "Lowest medium temperature",
    label: "EFI21176: Lowest medium temperature"
  },
  {
    featureId: "EFI21200",
    featureDesc: "Centre distance of wall-hung toilet fastener",
    label: "EFI21200: Centre distance of wall-hung toilet fastener"
  },
  {
    featureId: "EFI21216",
    featureDesc: "Rosette height",
    label: "EFI21216: Rosette height"
  },
  {
    featureId: "EFI21224",
    featureDesc: "Nominal diameter, connection 1",
    label: "EFI21224: Nominal diameter, connection 1"
  },
  {
    featureId: "EFI21225",
    featureDesc: "Nominal diameter, connection 2",
    label: "EFI21225: Nominal diameter, connection 2"
  },
  {
    featureId: "EFI21227",
    featureDesc: "Angled piece diameter",
    label: "EFI21227: Angled piece diameter"
  },
  {
    featureId: "EFI21228",
    featureDesc: "Outer diameter of lip ring",
    label: "EFI21228: Outer diameter of lip ring"
  },
  {
    featureId: "EFI21233",
    featureDesc: "Nominal diameter of insert end",
    label: "EFI21233: Nominal diameter of insert end"
  },
  {
    featureId: "EFI21234",
    featureDesc: "Nominal diameter of push-on end",
    label: "EFI21234: Nominal diameter of push-on end"
  },
  {
    featureId: "EFI21235",
    featureDesc: "Working depth",
    label: "EFI21235: Working depth"
  },
  {
    featureId: "EFI21241",
    featureDesc: "Diameter of air supply",
    label: "EFI21241: Diameter of air supply"
  },
  {
    featureId: "EFI21242",
    featureDesc: "Diameter of mechanical ventilation/heat recovery",
    label: "EFI21242: Diameter of mechanical ventilation/heat recovery"
  },
  {
    featureId: "EFI21243",
    featureDesc: "Diameter of sewer de-aeration",
    label: "EFI21243: Diameter of sewer de-aeration"
  },
  {
    featureId: "EFI21257",
    featureDesc: "Length of cable/capillary tube to remote sensor",
    label: "EFI21257: Length of cable/capillary tube to remote sensor"
  },
  {
    featureId: "EFI21258",
    featureDesc: "Max. length of cable/capillary tube to remote control element",
    label: "EFI21258: Max. length of cable/capillary tube to remote control element"
  },
  {
    featureId: "EFI21259",
    featureDesc: "Diameter remote sensor",
    label: "EFI21259: Diameter remote sensor"
  },
  {
    featureId: "EFI21260",
    featureDesc: "Length remote sensor",
    label: "EFI21260: Length remote sensor"
  },
  {
    featureId: "EFI21294",
    featureDesc: "Max. mounting height (exhaust height)",
    label: "EFI21294: Max. mounting height (exhaust height)"
  },
  {
    featureId: "EFI21337",
    featureDesc: "Length of spindle extension",
    label: "EFI21337: Length of spindle extension"
  },
  {
    featureId: "EFI21339",
    featureDesc: "Length of extended tailpiece",
    label: "EFI21339: Length of extended tailpiece"
  },
  {
    featureId: "EFI21341",
    featureDesc: "Temperature setting range",
    label: "EFI21341: Temperature setting range"
  },
  {
    featureId: "EFI21353",
    featureDesc: "Wall thickness, connection 3",
    label: "EFI21353: Wall thickness, connection 3"
  },
  {
    featureId: "EFI21354",
    featureDesc: "Wall thickness, connection 4",
    label: "EFI21354: Wall thickness, connection 4"
  },
  {
    featureId: "EFI21357",
    featureDesc: "Max. capacity",
    label: "EFI21357: Max. capacity"
  },
  {
    featureId: "EFI21367",
    featureDesc: "Max. airspeed",
    label: "EFI21367: Max. airspeed"
  },
  {
    featureId: "EFI21379",
    featureDesc: "Outer pipe diameter oil connection",
    label: "EFI21379: Outer pipe diameter oil connection"
  },
  {
    featureId: "EFI21388",
    featureDesc: "Outer pipe diameter circulation pipe",
    label: "EFI21388: Outer pipe diameter circulation pipe"
  },
  {
    featureId: "EFI21390",
    featureDesc: "Outer pipe diameter drainage connection",
    label: "EFI21390: Outer pipe diameter drainage connection"
  },
  {
    featureId: "EFI21393",
    featureDesc: "Sensor length",
    label: "EFI21393: Sensor length"
  },
  {
    featureId: "EFI21413",
    featureDesc: "Burner opening diameter",
    label: "EFI21413: Burner opening diameter"
  },
  {
    featureId: "EFI21419",
    featureDesc: "Differential between switch steps",
    label: "EFI21419: Differential between switch steps"
  },
  {
    featureId: "EFI21422",
    featureDesc: "Vane length",
    label: "EFI21422: Vane length"
  },
  {
    featureId: "EFI21423",
    featureDesc: "Vane width",
    label: "EFI21423: Vane width"
  },
  {
    featureId: "EFI21424",
    featureDesc: "Vane thickness",
    label: "EFI21424: Vane thickness"
  },
  {
    featureId: "EFI21431",
    featureDesc: "Float rod length",
    label: "EFI21431: Float rod length"
  },
  {
    featureId: "EFI21432",
    featureDesc: "Float diameter",
    label: "EFI21432: Float diameter"
  },
  {
    featureId: "EFI21435",
    featureDesc: "Waste stopper diameter",
    label: "EFI21435: Waste stopper diameter"
  },
  {
    featureId: "EFI21474",
    featureDesc: "Cover plate width",
    label: "EFI21474: Cover plate width"
  },
  {
    featureId: "EFI21475",
    featureDesc: "Built-in box length",
    label: "EFI21475: Built-in box length"
  },
  {
    featureId: "EFI21476",
    featureDesc: "Built-in box width",
    label: "EFI21476: Built-in box width"
  },
  {
    featureId: "EFI21477",
    featureDesc: "Built-in box depth",
    label: "EFI21477: Built-in box depth"
  },
  {
    featureId: "EFI21478",
    featureDesc: "Drainage capacity",
    label: "EFI21478: Drainage capacity"
  },
  {
    featureId: "EFI21479",
    featureDesc: "Water trap height",
    label: "EFI21479: Water trap height"
  },
  {
    featureId: "EFI21481",
    featureDesc: "Min. length",
    label: "EFI21481: Min. length"
  },
  {
    featureId: "EFI21484",
    featureDesc: "Length of funnel inlet",
    label: "EFI21484: Length of funnel inlet"
  },
  {
    featureId: "EFI21485",
    featureDesc: "Width, funnel inlet",
    label: "EFI21485: Width, funnel inlet"
  },
  {
    featureId: "EFI21486",
    featureDesc: "Height, funnel inlet",
    label: "EFI21486: Height, funnel inlet"
  },
  {
    featureId: "EFI21493",
    featureDesc: "Width roof flashing",
    label: "EFI21493: Width roof flashing"
  },
  {
    featureId: "EFI21494",
    featureDesc: "Length roof flashing",
    label: "EFI21494: Length roof flashing"
  },
  {
    featureId: "EFI21495",
    featureDesc: "Diameter, leaf catcher",
    label: "EFI21495: Diameter, leaf catcher"
  },
  {
    featureId: "EFI21499",
    featureDesc: "Max. wheel pressure",
    label: "EFI21499: Max. wheel pressure"
  },
  {
    featureId: "EFI21500",
    featureDesc: "Width, plate",
    label: "EFI21500: Width, plate"
  },
  {
    featureId: "EFI21501",
    featureDesc: "Plate length",
    label: "EFI21501: Plate length"
  },
  {
    featureId: "EFI21511",
    featureDesc: "Edge height",
    label: "EFI21511: Edge height"
  },
  {
    featureId: "EFI21512",
    featureDesc: "Sheet diameter",
    label: "EFI21512: Sheet diameter"
  },
  {
    featureId: "EFI21519",
    featureDesc: "Length of block",
    label: "EFI21519: Length of block"
  },
  {
    featureId: "EFI21520",
    featureDesc: "Width, block",
    label: "EFI21520: Width, block"
  },
  {
    featureId: "EFI21521",
    featureDesc: "Thickness, block",
    label: "EFI21521: Thickness, block"
  },
  {
    featureId: "EFI21522",
    featureDesc: "Diameter, block",
    label: "EFI21522: Diameter, block"
  },
  {
    featureId: "EFI21523",
    featureDesc: "Inner diameter of O-ring 1",
    label: "EFI21523: Inner diameter of O-ring 1"
  },
  {
    featureId: "EFI21524",
    featureDesc: "Inner diameter of O-ring 2",
    label: "EFI21524: Inner diameter of O-ring 2"
  },
  {
    featureId: "EFI21525",
    featureDesc: "Thickness, O-ring 1",
    label: "EFI21525: Thickness, O-ring 1"
  },
  {
    featureId: "EFI21526",
    featureDesc: "Thickness, O-ring 2",
    label: "EFI21526: Thickness, O-ring 2"
  },
  {
    featureId: "EFI21529",
    featureDesc: "Max. vapour inhibition layer thickness",
    label: "EFI21529: Max. vapour inhibition layer thickness"
  },
  {
    featureId: "EFI21530",
    featureDesc: "Inner diameter of flange",
    label: "EFI21530: Inner diameter of flange"
  },
  {
    featureId: "EFI21531",
    featureDesc: "Outer diameter of flange",
    label: "EFI21531: Outer diameter of flange"
  },
  {
    featureId: "EFI21537",
    featureDesc: "Diameter roof flashing",
    label: "EFI21537: Diameter roof flashing"
  },
  {
    featureId: "EFI21541",
    featureDesc: "Standard section length",
    label: "EFI21541: Standard section length"
  },
  {
    featureId: "EFI21545",
    featureDesc: "Length of connector",
    label: "EFI21545: Length of connector"
  },
  {
    featureId: "EFI21546",
    featureDesc: "Working length of connector",
    label: "EFI21546: Working length of connector"
  },
  {
    featureId: "EFI21550",
    featureDesc: "Width, support",
    label: "EFI21550: Width, support"
  },
  {
    featureId: "EFI21551",
    featureDesc: "Thickness, strip",
    label: "EFI21551: Thickness, strip"
  },
  {
    featureId: "EFI21554",
    featureDesc: "Length 1",
    label: "EFI21554: Length 1"
  },
  {
    featureId: "EFI21555",
    featureDesc: "Length 2",
    label: "EFI21555: Length 2"
  },
  {
    featureId: "EFI21557",
    featureDesc: "Max. distance between spacers",
    label: "EFI21557: Max. distance between spacers"
  },
  {
    featureId: "EFI21558",
    featureDesc: "Diameter, slotted hole",
    label: "EFI21558: Diameter, slotted hole"
  },
  {
    featureId: "EFI21559",
    featureDesc: "Max. distance between connecting strips",
    label: "EFI21559: Max. distance between connecting strips"
  },
  {
    featureId: "EFI21560",
    featureDesc: "Length of strip 1",
    label: "EFI21560: Length of strip 1"
  },
  {
    featureId: "EFI21561",
    featureDesc: "Length of strip 2",
    label: "EFI21561: Length of strip 2"
  },
  {
    featureId: "EFI21562",
    featureDesc: "Width, strip",
    label: "EFI21562: Width, strip"
  },
  {
    featureId: "EFI21564",
    featureDesc: "Max. width",
    label: "EFI21564: Max. width"
  },
  {
    featureId: "EFI21565",
    featureDesc: "Min. width",
    label: "EFI21565: Min. width"
  },
  {
    featureId: "EFI21566",
    featureDesc: "Height reinforcement",
    label: "EFI21566: Height reinforcement"
  },
  {
    featureId: "EFI21570",
    featureDesc: "Frame height",
    label: "EFI21570: Frame height"
  },
  {
    featureId: "EFI21571",
    featureDesc: "Frame width",
    label: "EFI21571: Frame width"
  },
  {
    featureId: "EFI21572",
    featureDesc: "Frame depth",
    label: "EFI21572: Frame depth"
  },
  {
    featureId: "EFI21595",
    featureDesc: "Diameter, drainage hose tulle/hose connection",
    label: "EFI21595: Diameter, drainage hose tulle/hose connection"
  },
  {
    featureId: "EFI21596",
    featureDesc: "Max. clamping force",
    label: "EFI21596: Max. clamping force"
  },
  {
    featureId: "EFI21598",
    featureDesc: "Diameter, threaded rod",
    label: "EFI21598: Diameter, threaded rod"
  },
  {
    featureId: "EFI21599",
    featureDesc: "Length of pipe from attachment centre",
    label: "EFI21599: Length of pipe from attachment centre"
  },
  {
    featureId: "EFI21600",
    featureDesc: "Length of pipe clip",
    label: "EFI21600: Length of pipe clip"
  },
  {
    featureId: "EFI21602",
    featureDesc: "Length of sleeve",
    label: "EFI21602: Length of sleeve"
  },
  {
    featureId: "EFI21603",
    featureDesc: "Wall thickness, connection 1",
    label: "EFI21603: Wall thickness, connection 1"
  },
  {
    featureId: "EFI21604",
    featureDesc: "Wall thickness, connection 2",
    label: "EFI21604: Wall thickness, connection 2"
  },
  {
    featureId: "EFI21610",
    featureDesc: "Depth, spacer block",
    label: "EFI21610: Depth, spacer block"
  },
  {
    featureId: "EFI21611",
    featureDesc: "Length of mantle pipe",
    label: "EFI21611: Length of mantle pipe"
  },
  {
    featureId: "EFI21624",
    featureDesc: "Outer pipe diameter branches",
    label: "EFI21624: Outer pipe diameter branches"
  },
  {
    featureId: "EFI21625",
    featureDesc: "Distributor working length",
    label: "EFI21625: Distributor working length"
  },
  {
    featureId: "EFI21626",
    featureDesc: "Length of branch",
    label: "EFI21626: Length of branch"
  },
  {
    featureId: "EFI21627",
    featureDesc: "Branch working length",
    label: "EFI21627: Branch working length"
  },
  {
    featureId: "EFI21628",
    featureDesc: "Distance between offset",
    label: "EFI21628: Distance between offset"
  },
  {
    featureId: "EFI21637",
    featureDesc: "Axle diameter",
    label: "EFI21637: Axle diameter"
  },
  {
    featureId: "EFI21640",
    featureDesc: "Length of outside part",
    label: "EFI21640: Length of outside part"
  },
  {
    featureId: "EFI21643",
    featureDesc: "Pitch circle",
    label: "EFI21643: Pitch circle"
  },
  {
    featureId: "EFI21651",
    featureDesc: "Length of spout",
    label: "EFI21651: Length of spout"
  },
  {
    featureId: "EFI21654",
    featureDesc: "Nominal duct diameter, flue",
    label: "EFI21654: Nominal duct diameter, flue"
  },
  {
    featureId: "EFI21665",
    featureDesc: "Connecting flange pitch circle",
    label: "EFI21665: Connecting flange pitch circle"
  },
  {
    featureId: "EFI21667",
    featureDesc: "Key square",
    label: "EFI21667: Key square"
  },
  {
    featureId: "EFI21682",
    featureDesc: "Filter housing height",
    label: "EFI21682: Filter housing height"
  },
  {
    featureId: "EFI21690",
    featureDesc: "Thickness, centring ring",
    label: "EFI21690: Thickness, centring ring"
  },
  {
    featureId: "EFI21691",
    featureDesc: "Outer diameter of spiral",
    label: "EFI21691: Outer diameter of spiral"
  },
  {
    featureId: "EFI21692",
    featureDesc: "Inner diameter spiral",
    label: "EFI21692: Inner diameter spiral"
  },
  {
    featureId: "EFI21703",
    featureDesc: "Outer pipe diameter drainage",
    label: "EFI21703: Outer pipe diameter drainage"
  },
  {
    featureId: "EFI21704",
    featureDesc: "Outer pipe diameter flushing",
    label: "EFI21704: Outer pipe diameter flushing"
  },
  {
    featureId: "EFI21709",
    featureDesc: "Centre distance, inlets",
    label: "EFI21709: Centre distance, inlets"
  },
  {
    featureId: "EFI21721",
    featureDesc: "Min. density of liquid",
    label: "EFI21721: Min. density of liquid"
  },
  {
    featureId: "EFI21726",
    featureDesc: "Shaft connection, servomotor",
    label: "EFI21726: Shaft connection, servomotor"
  },
  {
    featureId: "EFI21727",
    featureDesc: "Torque motor",
    label: "EFI21727: Torque motor"
  },
  {
    featureId: "EFI21741",
    featureDesc: "Diameter, connections",
    label: "EFI21741: Diameter, connections"
  },
  {
    featureId: "EFI21742",
    featureDesc: "Width mounting plate",
    label: "EFI21742: Width mounting plate"
  },
  {
    featureId: "EFI21743",
    featureDesc: "Length of mounting plate",
    label: "EFI21743: Length of mounting plate"
  },
  {
    featureId: "EFI21744",
    featureDesc: "Depth mounting plate",
    label: "EFI21744: Depth mounting plate"
  },
  {
    featureId: "EFI21761",
    featureDesc: "Dryer capacity",
    label: "EFI21761: Dryer capacity"
  },
  {
    featureId: "EFI21769",
    featureDesc: "Hose size",
    label: "EFI21769: Hose size"
  },
  {
    featureId: "EFI21780",
    featureDesc: "Diameter bolt hole",
    label: "EFI21780: Diameter bolt hole"
  },
  {
    featureId: "EFI21792",
    featureDesc: "Movement sensing, axial +",
    label: "EFI21792: Movement sensing, axial +"
  },
  {
    featureId: "EFI21793",
    featureDesc: "Movement sensing, axial -",
    label: "EFI21793: Movement sensing, axial -"
  },
  {
    featureId: "EFI21794",
    featureDesc: "Movement sensing, lateral +/-",
    label: "EFI21794: Movement sensing, lateral +/-"
  },
  {
    featureId: "EFI21799",
    featureDesc: "Movement sensing, axial +/-",
    label: "EFI21799: Movement sensing, axial +/-"
  },
  {
    featureId: "EFI21803",
    featureDesc: "Reaction temperature",
    label: "EFI21803: Reaction temperature"
  },
  {
    featureId: "EFI21805",
    featureDesc: "Width, sleeve",
    label: "EFI21805: Width, sleeve"
  },
  {
    featureId: "EFI21806",
    featureDesc: "Outer diameter, sleeve",
    label: "EFI21806: Outer diameter, sleeve"
  },
  {
    featureId: "EFI21811",
    featureDesc: "Length of pipe section",
    label: "EFI21811: Length of pipe section"
  },
  {
    featureId: "EFI21835",
    featureDesc: "Centre distance",
    label: "EFI21835: Centre distance"
  },
  {
    featureId: "EFI21841",
    featureDesc: "Distance centre-to-centre",
    label: "EFI21841: Distance centre-to-centre"
  },
  {
    featureId: "EFI21846",
    featureDesc: "Drain capacity",
    label: "EFI21846: Drain capacity"
  },
  {
    featureId: "EFI21850",
    featureDesc: "Suitable for radiator height",
    label: "EFI21850: Suitable for radiator height"
  },
  {
    featureId: "EFI21856",
    featureDesc: "Shaft diameter",
    label: "EFI21856: Shaft diameter"
  },
  {
    featureId: "EFI21864",
    featureDesc: "Torque",
    label: "EFI21864: Torque"
  },
  {
    featureId: "EFI21866",
    featureDesc: "Stroke length",
    label: "EFI21866: Stroke length"
  },
  {
    featureId: "EFI21889",
    featureDesc: "Air volume, unloaded",
    label: "EFI21889: Air volume, unloaded"
  },
  {
    featureId: "EFI21890",
    featureDesc: "Air volume at pressure 2",
    label: "EFI21890: Air volume at pressure 2"
  },
  {
    featureId: "EFI21891",
    featureDesc: "Air volume at pressure 3",
    label: "EFI21891: Air volume at pressure 3"
  },
  {
    featureId: "EFI21896",
    featureDesc: "Duct diameter",
    label: "EFI21896: Duct diameter"
  },
  {
    featureId: "EFI21897",
    featureDesc: "Max. air temperature",
    label: "EFI21897: Max. air temperature"
  },
  {
    featureId: "EFI21903",
    featureDesc: "Outer diameter connection 1",
    label: "EFI21903: Outer diameter connection 1"
  },
  {
    featureId: "EFI21904",
    featureDesc: "Outer diameter connection 2",
    label: "EFI21904: Outer diameter connection 2"
  },
  {
    featureId: "EFI21916",
    featureDesc: "Diameter, funnel inlet",
    label: "EFI21916: Diameter, funnel inlet"
  },
  {
    featureId: "EFI21919",
    featureDesc: "Height, sleeve",
    label: "EFI21919: Height, sleeve"
  },
  {
    featureId: "EFI21925",
    featureDesc: "Developed tray width",
    label: "EFI21925: Developed tray width"
  },
  {
    featureId: "EFI21926",
    featureDesc: "Wedge width",
    label: "EFI21926: Wedge width"
  },
  {
    featureId: "EFI21927",
    featureDesc: "Wedge length",
    label: "EFI21927: Wedge length"
  },
  {
    featureId: "EFI21929",
    featureDesc: "Thickness, wedge material",
    label: "EFI21929: Thickness, wedge material"
  },
  {
    featureId: "EFI21930",
    featureDesc: "Width, bracket",
    label: "EFI21930: Width, bracket"
  },
  {
    featureId: "EFI21931",
    featureDesc: "Height, bracket",
    label: "EFI21931: Height, bracket"
  },
  {
    featureId: "EFI21932",
    featureDesc: "Length of bracket",
    label: "EFI21932: Length of bracket"
  },
  {
    featureId: "EFI21933",
    featureDesc: "Thickness, bracket material",
    label: "EFI21933: Thickness, bracket material"
  },
  {
    featureId: "EFI21937",
    featureDesc: "Max. bracket width",
    label: "EFI21937: Max. bracket width"
  },
  {
    featureId: "EFI21940",
    featureDesc: "Inner diameter of fire hose",
    label: "EFI21940: Inner diameter of fire hose"
  },
  {
    featureId: "EFI21943",
    featureDesc: "Reel blade distance",
    label: "EFI21943: Reel blade distance"
  },
  {
    featureId: "EFI21948",
    featureDesc: "Length of filling hose",
    label: "EFI21948: Length of filling hose"
  },
  {
    featureId: "EFI21958",
    featureDesc: "Max. reel blade diameter",
    label: "EFI21958: Max. reel blade diameter"
  },
  {
    featureId: "EFI21959",
    featureDesc: "Heating capacity",
    label: "EFI21959: Heating capacity"
  },
  {
    featureId: "EFI21964",
    featureDesc: "Gas consumption",
    label: "EFI21964: Gas consumption"
  },
  {
    featureId: "EFI21965",
    featureDesc: "Oil consumption",
    label: "EFI21965: Oil consumption"
  },
  {
    featureId: "EFI21972",
    featureDesc: "Diameter exhaust",
    label: "EFI21972: Diameter exhaust"
  },
  {
    featureId: "EFI21984",
    featureDesc: "Wall lead-through diameter",
    label: "EFI21984: Wall lead-through diameter"
  },
  {
    featureId: "EFI21985",
    featureDesc: "Wall lead-through height/width",
    label: "EFI21985: Wall lead-through height/width"
  },
  {
    featureId: "EFI21986",
    featureDesc: "Min. height of wall lead-through centre",
    label: "EFI21986: Min. height of wall lead-through centre"
  },
  {
    featureId: "EFI21988",
    featureDesc: "Hot tap water capacity at 45 °C",
    label: "EFI21988: Hot tap water capacity at 45 °C"
  },
  {
    featureId: "EFI22025",
    featureDesc: "Outer pipe diameter primary connection",
    label: "EFI22025: Outer pipe diameter primary connection"
  },
  {
    featureId: "EFI22026",
    featureDesc: "Volume, discharge tank",
    label: "EFI22026: Volume, discharge tank"
  },
  {
    featureId: "EFI22046",
    featureDesc: "Length of tail piece",
    label: "EFI22046: Length of tail piece"
  },
  {
    featureId: "EFI22061",
    featureDesc: "Outer duct diameter",
    label: "EFI22061: Outer duct diameter"
  },
  {
    featureId: "EFI22065",
    featureDesc: "Operating height",
    label: "EFI22065: Operating height"
  },
  {
    featureId: "EFI22069",
    featureDesc: "Diameter, duct",
    label: "EFI22069: Diameter, duct"
  },
  {
    featureId: "EFI22082",
    featureDesc: "Max. differential gap",
    label: "EFI22082: Max. differential gap"
  },
  {
    featureId: "EFI22083",
    featureDesc: "Min. differential gap",
    label: "EFI22083: Min. differential gap"
  },
  {
    featureId: "EFI22086",
    featureDesc: "Float housing diameter",
    label: "EFI22086: Float housing diameter"
  },
  {
    featureId: "EFI22087",
    featureDesc: "Centre distance, connections 1 and 2",
    label: "EFI22087: Centre distance, connections 1 and 2"
  },
  {
    featureId: "EFI22113",
    featureDesc: "Detector height",
    label: "EFI22113: Detector height"
  },
  {
    featureId: "EFI22114",
    featureDesc: "Detector diameter",
    label: "EFI22114: Detector diameter"
  },
  {
    featureId: "EFI22119",
    featureDesc: "Max. temperature setting",
    label: "EFI22119: Max. temperature setting"
  },
  {
    featureId: "EFI22126",
    featureDesc: "Cover plate height",
    label: "EFI22126: Cover plate height"
  },
  {
    featureId: "EFI22129",
    featureDesc: "Max. through flow capacity (300 kPa)",
    label: "EFI22129: Max. through flow capacity (300 kPa)"
  },
  {
    featureId: "EFI22139",
    featureDesc: "Diameter, push button",
    label: "EFI22139: Diameter, push button"
  },
  {
    featureId: "EFI22176",
    featureDesc: "Insulation length",
    label: "EFI22176: Insulation length"
  },
  {
    featureId: "EFI22198",
    featureDesc: "Nominal duct height of suction end",
    label: "EFI22198: Nominal duct height of suction end"
  },
  {
    featureId: "EFI22199",
    featureDesc: "Nominal duct width of suction end",
    label: "EFI22199: Nominal duct width of suction end"
  },
  {
    featureId: "EFI22226",
    featureDesc: "Outer diameter of rosette",
    label: "EFI22226: Outer diameter of rosette"
  },
  {
    featureId: "EFI22227",
    featureDesc: "Inner diameter of rosette",
    label: "EFI22227: Inner diameter of rosette"
  },
  {
    featureId: "EFI22232",
    featureDesc: "Cover plate diameter",
    label: "EFI22232: Cover plate diameter"
  },
  {
    featureId: "EFI22244",
    featureDesc: "Drive section length",
    label: "EFI22244: Drive section length"
  },
  {
    featureId: "EFI22245",
    featureDesc: "Built-in section length",
    label: "EFI22245: Built-in section length"
  },
  {
    featureId: "EFI22246",
    featureDesc: "Min. operating space required",
    label: "EFI22246: Min. operating space required"
  },
  {
    featureId: "EFI22247",
    featureDesc: "Fire damper diameter",
    label: "EFI22247: Fire damper diameter"
  },
  {
    featureId: "EFI22259",
    featureDesc: "Wrench width union nut",
    label: "EFI22259: Wrench width union nut"
  },
  {
    featureId: "EFI22273",
    featureDesc: "Height operating element",
    label: "EFI22273: Height operating element"
  },
  {
    featureId: "EFI22301",
    featureDesc: "Throw",
    label: "EFI22301: Throw"
  },
  {
    featureId: "EFI22305",
    featureDesc: "Connection side duct height",
    label: "EFI22305: Connection side duct height"
  },
  {
    featureId: "EFI22306",
    featureDesc: "Connection side duct width",
    label: "EFI22306: Connection side duct width"
  },
  {
    featureId: "EFI22314",
    featureDesc: "Exhaust side duct height",
    label: "EFI22314: Exhaust side duct height"
  },
  {
    featureId: "EFI22315",
    featureDesc: "Exhaust sides duct width",
    label: "EFI22315: Exhaust sides duct width"
  },
  {
    featureId: "EFI22316",
    featureDesc: "Outer pipe diameter combustion air",
    label: "EFI22316: Outer pipe diameter combustion air"
  },
  {
    featureId: "EFI22319",
    featureDesc: "Air flow rate",
    label: "EFI22319: Air flow rate"
  },
  {
    featureId: "EFI22325",
    featureDesc: "Density, insulation",
    label: "EFI22325: Density, insulation"
  },
  {
    featureId: "EFI22332",
    featureDesc: "Width grid",
    label: "EFI22332: Width grid"
  },
  {
    featureId: "EFI22333",
    featureDesc: "Length grid",
    label: "EFI22333: Length grid"
  },
  {
    featureId: "EFI22334",
    featureDesc: "Diameter grid",
    label: "EFI22334: Diameter grid"
  },
  {
    featureId: "EFI22335",
    featureDesc: "Load grid",
    label: "EFI22335: Load grid"
  },
  {
    featureId: "EFI22339",
    featureDesc: "Outer pipe diameter outlet",
    label: "EFI22339: Outer pipe diameter outlet"
  },
  {
    featureId: "EFI22341",
    featureDesc: "Outer pipe diameter inlet",
    label: "EFI22341: Outer pipe diameter inlet"
  },
  {
    featureId: "EFI22349",
    featureDesc: "Flow-through capacity",
    label: "EFI22349: Flow-through capacity"
  },
  {
    featureId: "EFI22352",
    featureDesc: "Storage capacity of separator",
    label: "EFI22352: Storage capacity of separator"
  },
  {
    featureId: "EFI22355",
    featureDesc: "Collection capacity, mud trap",
    label: "EFI22355: Collection capacity, mud trap"
  },
  {
    featureId: "EFI22357",
    featureDesc: "Gutter diameter",
    label: "EFI22357: Gutter diameter"
  },
  {
    featureId: "EFI22358",
    featureDesc: "Tail length",
    label: "EFI22358: Tail length"
  },
  {
    featureId: "EFI22360",
    featureDesc: "Outer pipe diameter, expansion connection",
    label: "EFI22360: Outer pipe diameter, expansion connection"
  },
  {
    featureId: "EFI22376",
    featureDesc: "Tank size",
    label: "EFI22376: Tank size"
  },
  {
    featureId: "EFI22382",
    featureDesc: "Outer section height",
    label: "EFI22382: Outer section height"
  },
  {
    featureId: "EFI22383",
    featureDesc: "Outer section width",
    label: "EFI22383: Outer section width"
  },
  {
    featureId: "EFI22403",
    featureDesc: "Max. cooling power (dT)",
    label: "EFI22403: Max. cooling power (dT)"
  },
  {
    featureId: "EFI22406",
    featureDesc: "Nominal duct diameter, branch",
    label: "EFI22406: Nominal duct diameter, branch"
  },
  {
    featureId: "EFI22412",
    featureDesc: "Control panel width",
    label: "EFI22412: Control panel width"
  },
  {
    featureId: "EFI22413",
    featureDesc: "Control panel height",
    label: "EFI22413: Control panel height"
  },
  {
    featureId: "EFI22414",
    featureDesc: "Cord thickness",
    label: "EFI22414: Cord thickness"
  },
  {
    featureId: "EFI22431",
    featureDesc: "Min. volume flow",
    label: "EFI22431: Min. volume flow"
  },
  {
    featureId: "EFI22438",
    featureDesc: "Outer duct diameter, connection 1",
    label: "EFI22438: Outer duct diameter, connection 1"
  },
  {
    featureId: "EFI22439",
    featureDesc: "Outer duct diameter, connection 2",
    label: "EFI22439: Outer duct diameter, connection 2"
  },
  {
    featureId: "EFI22440",
    featureDesc: "Outer duct diameter, connection 3",
    label: "EFI22440: Outer duct diameter, connection 3"
  },
  {
    featureId: "EFI22441",
    featureDesc: "Diameter jacket",
    label: "EFI22441: Diameter jacket"
  },
  {
    featureId: "EFI22442",
    featureDesc: "Jacket plate thickness",
    label: "EFI22442: Jacket plate thickness"
  },
  {
    featureId: "EFI22444",
    featureDesc: "Outer diameter, cone",
    label: "EFI22444: Outer diameter, cone"
  },
  {
    featureId: "EFI22445",
    featureDesc: "Width inspection opening",
    label: "EFI22445: Width inspection opening"
  },
  {
    featureId: "EFI22446",
    featureDesc: "Height inspection opening",
    label: "EFI22446: Height inspection opening"
  },
  {
    featureId: "EFI22448",
    featureDesc: "Nominal duct diameter, branches",
    label: "EFI22448: Nominal duct diameter, branches"
  },
  {
    featureId: "EFI22458",
    featureDesc: "Wall roughness",
    label: "EFI22458: Wall roughness"
  },
  {
    featureId: "EFI22478",
    featureDesc: "Length of support arm",
    label: "EFI22478: Length of support arm"
  },
  {
    featureId: "EFI22491",
    featureDesc: "Outer pipe diameter sleeveless",
    label: "EFI22491: Outer pipe diameter sleeveless"
  },
  {
    featureId: "EFI22492",
    featureDesc: "Outer pipe diameter transition",
    label: "EFI22492: Outer pipe diameter transition"
  },
  {
    featureId: "EFI22510",
    featureDesc: "Length per element",
    label: "EFI22510: Length per element"
  },
  {
    featureId: "EFI22511",
    featureDesc: "Working length per element",
    label: "EFI22511: Working length per element"
  },
  {
    featureId: "EFI22512",
    featureDesc: "Burner element length",
    label: "EFI22512: Burner element length"
  },
  {
    featureId: "EFI22513",
    featureDesc: "Burner element width",
    label: "EFI22513: Burner element width"
  },
  {
    featureId: "EFI22514",
    featureDesc: "Burner element height",
    label: "EFI22514: Burner element height"
  },
  {
    featureId: "EFI22515",
    featureDesc: "Element width",
    label: "EFI22515: Element width"
  },
  {
    featureId: "EFI22516",
    featureDesc: "Element height",
    label: "EFI22516: Element height"
  },
  {
    featureId: "EFI22519",
    featureDesc: "Connection length",
    label: "EFI22519: Connection length"
  },
  {
    featureId: "EFI22528",
    featureDesc: "Medium volume",
    label: "EFI22528: Medium volume"
  },
  {
    featureId: "EFI22535",
    featureDesc: "Min. switching flow rate",
    label: "EFI22535: Min. switching flow rate"
  },
  {
    featureId: "EFI22536",
    featureDesc: "Max. switching flow rate",
    label: "EFI22536: Max. switching flow rate"
  },
  {
    featureId: "EFI22537",
    featureDesc: "Switching section height",
    label: "EFI22537: Switching section height"
  },
  {
    featureId: "EFI22538",
    featureDesc: "Switching section diameter",
    label: "EFI22538: Switching section diameter"
  },
  {
    featureId: "EFI22540",
    featureDesc: "Min. pipe diameter",
    label: "EFI22540: Min. pipe diameter"
  },
  {
    featureId: "EFI22542",
    featureDesc: "Filter element length",
    label: "EFI22542: Filter element length"
  },
  {
    featureId: "EFI22543",
    featureDesc: "Suitable for outer diameter",
    label: "EFI22543: Suitable for outer diameter"
  },
  {
    featureId: "EFI22544",
    featureDesc: "Length of inspection opening",
    label: "EFI22544: Length of inspection opening"
  },
  {
    featureId: "EFI22549",
    featureDesc: "Equivalent duct diameter",
    label: "EFI22549: Equivalent duct diameter"
  },
  {
    featureId: "EFI22550",
    featureDesc: "Height, branch",
    label: "EFI22550: Height, branch"
  },
  {
    featureId: "EFI22557",
    featureDesc: "Equivalent duct diameter, connection 1",
    label: "EFI22557: Equivalent duct diameter, connection 1"
  },
  {
    featureId: "EFI22558",
    featureDesc: "Equivalent duct diameter, connection 2",
    label: "EFI22558: Equivalent duct diameter, connection 2"
  },
  {
    featureId: "EFI22561",
    featureDesc: "Connection height",
    label: "EFI22561: Connection height"
  },
  {
    featureId: "EFI22562",
    featureDesc: "Connection width",
    label: "EFI22562: Connection width"
  },
  {
    featureId: "EFI22563",
    featureDesc: "Height, oval duct",
    label: "EFI22563: Height, oval duct"
  },
  {
    featureId: "EFI22564",
    featureDesc: "Width, oval duct",
    label: "EFI22564: Width, oval duct"
  },
  {
    featureId: "EFI22573",
    featureDesc: "Outer pipe diameter connections 2, 3, 4",
    label: "EFI22573: Outer pipe diameter connections 2, 3, 4"
  },
  {
    featureId: "EFI22575",
    featureDesc: "Valve bore",
    label: "EFI22575: Valve bore"
  },
  {
    featureId: "EFI22578",
    featureDesc: "Total width",
    label: "EFI22578: Total width"
  },
  {
    featureId: "EFI22586",
    featureDesc: "Inner diameter of guide ring",
    label: "EFI22586: Inner diameter of guide ring"
  },
  {
    featureId: "EFI22611",
    featureDesc: "Outer pipe diameter, instrument end",
    label: "EFI22611: Outer pipe diameter, instrument end"
  },
  {
    featureId: "EFI22614",
    featureDesc: "Height above ground level",
    label: "EFI22614: Height above ground level"
  },
  {
    featureId: "EFI22622",
    featureDesc: "Diameter insulation coupling",
    label: "EFI22622: Diameter insulation coupling"
  },
  {
    featureId: "EFI22624",
    featureDesc: "Valve piece length",
    label: "EFI22624: Valve piece length"
  },
  {
    featureId: "EFI22633",
    featureDesc: "Outer diameter, in/outlet",
    label: "EFI22633: Outer diameter, in/outlet"
  },
  {
    featureId: "EFI22634",
    featureDesc: "Outer diameter, connecting pipe",
    label: "EFI22634: Outer diameter, connecting pipe"
  },
  {
    featureId: "EFI22635",
    featureDesc: "Outer diameter, de-aerator",
    label: "EFI22635: Outer diameter, de-aerator"
  },
  {
    featureId: "EFI22639",
    featureDesc: "Diameter, hose connector",
    label: "EFI22639: Diameter, hose connector"
  },
  {
    featureId: "EFI22648",
    featureDesc: "Outer diameter, inlay part",
    label: "EFI22648: Outer diameter, inlay part"
  },
  {
    featureId: "EFI22650",
    featureDesc: "Collar thickness",
    label: "EFI22650: Collar thickness"
  },
  {
    featureId: "EFI22682",
    featureDesc: "Rail width",
    label: "EFI22682: Rail width"
  },
  {
    featureId: "EFI22691",
    featureDesc: "Differential value",
    label: "EFI22691: Differential value"
  },
  {
    featureId: "EFI22692",
    featureDesc: "Flow-through capacity",
    label: "EFI22692: Flow-through capacity"
  },
  {
    featureId: "EFI22705",
    featureDesc: "Max. thickness",
    label: "EFI22705: Max. thickness"
  },
  {
    featureId: "EFI22715",
    featureDesc: "Suitable for outer pipe diameter",
    label: "EFI22715: Suitable for outer pipe diameter"
  },
  {
    featureId: "EFI22716",
    featureDesc: "Glass thickness",
    label: "EFI22716: Glass thickness"
  },
  {
    featureId: "EFI22717",
    featureDesc: "Groove width (mm)",
    label: "EFI22717: Groove width (mm)"
  },
  {
    featureId: "EFI22731",
    featureDesc: "Welding range",
    label: "EFI22731: Welding range"
  },
  {
    featureId: "EFI22741",
    featureDesc: "Inner diameter of ring",
    label: "EFI22741: Inner diameter of ring"
  },
  {
    featureId: "EFI22743",
    featureDesc: "Thickness ring",
    label: "EFI22743: Thickness ring"
  },
  {
    featureId: "EFI22749",
    featureDesc: "Height, overflow brim",
    label: "EFI22749: Height, overflow brim"
  },
  {
    featureId: "EFI22756",
    featureDesc: "Rosette diameter",
    label: "EFI22756: Rosette diameter"
  },
  {
    featureId: "EFI22764",
    featureDesc: "Front width",
    label: "EFI22764: Front width"
  },
  {
    featureId: "EFI22765",
    featureDesc: "Front diameter",
    label: "EFI22765: Front diameter"
  },
  {
    featureId: "EFI22768",
    featureDesc: "Front height",
    label: "EFI22768: Front height"
  },
  {
    featureId: "EFI22772",
    featureDesc: "Front length",
    label: "EFI22772: Front length"
  },
  {
    featureId: "EFI22775",
    featureDesc: "Length of plenum",
    label: "EFI22775: Length of plenum"
  },
  {
    featureId: "EFI22781",
    featureDesc: "Recess width",
    label: "EFI22781: Recess width"
  },
  {
    featureId: "EFI22782",
    featureDesc: "Recess height",
    label: "EFI22782: Recess height"
  },
  {
    featureId: "EFI22787",
    featureDesc: "Outer pipe diameter cooler",
    label: "EFI22787: Outer pipe diameter cooler"
  },
  {
    featureId: "EFI22820",
    featureDesc: "Outer diameter of sliding ring",
    label: "EFI22820: Outer diameter of sliding ring"
  },
  {
    featureId: "EFI22831",
    featureDesc: "Built-in box height",
    label: "EFI22831: Built-in box height"
  },
  {
    featureId: "EFI22837",
    featureDesc: "Base width",
    label: "EFI22837: Base width"
  },
  {
    featureId: "EFI22838",
    featureDesc: "Base thickness",
    label: "EFI22838: Base thickness"
  },
  {
    featureId: "EFI22839",
    featureDesc: "Post thickness",
    label: "EFI22839: Post thickness"
  },
  {
    featureId: "EFI22841",
    featureDesc: "Bottom thickness",
    label: "EFI22841: Bottom thickness"
  },
  {
    featureId: "EFI22843",
    featureDesc: "Bead height",
    label: "EFI22843: Bead height"
  },
  {
    featureId: "EFI22846",
    featureDesc: "Min. clamping range",
    label: "EFI22846: Min. clamping range"
  },
  {
    featureId: "EFI22847",
    featureDesc: "Max. clamping range",
    label: "EFI22847: Max. clamping range"
  },
  {
    featureId: "EFI22848",
    featureDesc: "Top width",
    label: "EFI22848: Top width"
  },
  {
    featureId: "EFI22851",
    featureDesc: "Rebate size 1",
    label: "EFI22851: Rebate size 1"
  },
  {
    featureId: "EFI22852",
    featureDesc: "Rebate size 2",
    label: "EFI22852: Rebate size 2"
  },
  {
    featureId: "EFI22853",
    featureDesc: "Distance between rebates",
    label: "EFI22853: Distance between rebates"
  },
  {
    featureId: "EFI22862",
    featureDesc: "Retaining ring width",
    label: "EFI22862: Retaining ring width"
  },
  {
    featureId: "EFI22875",
    featureDesc: "Chamber diameter, secondary seal",
    label: "EFI22875: Chamber diameter, secondary seal"
  },
  {
    featureId: "EFI22884",
    featureDesc: "Outer diameter, rotor",
    label: "EFI22884: Outer diameter, rotor"
  },
  {
    featureId: "EFI22887",
    featureDesc: "Built-in length, unmounted",
    label: "EFI22887: Built-in length, unmounted"
  },
  {
    featureId: "EFI22888",
    featureDesc: "Built-in length, mounted",
    label: "EFI22888: Built-in length, mounted"
  },
  {
    featureId: "EFI22890",
    featureDesc: "Min. built-in diameter",
    label: "EFI22890: Min. built-in diameter"
  },
  {
    featureId: "EFI22891",
    featureDesc: "Max. built-in diameter",
    label: "EFI22891: Max. built-in diameter"
  },
  {
    featureId: "EFI22892",
    featureDesc: "Min. pitch circle of flange",
    label: "EFI22892: Min. pitch circle of flange"
  },
  {
    featureId: "EFI22913",
    featureDesc: "Spindle diameter",
    label: "EFI22913: Spindle diameter"
  },
  {
    featureId: "EFI22914",
    featureDesc: "Set height",
    label: "EFI22914: Set height"
  },
  {
    featureId: "EFI22920",
    featureDesc: "Roller length",
    label: "EFI22920: Roller length"
  },
  {
    featureId: "EFI22923",
    featureDesc: "Nominal duct diameter connection",
    label: "EFI22923: Nominal duct diameter connection"
  },
  {
    featureId: "EFI22925",
    featureDesc: "Effective length of inlet piece",
    label: "EFI22925: Effective length of inlet piece"
  },
  {
    featureId: "EFI22928",
    featureDesc: "Nominal approach flow rate",
    label: "EFI22928: Nominal approach flow rate"
  },
  {
    featureId: "EFI22962",
    featureDesc: "Graphite density",
    label: "EFI22962: Graphite density"
  },
  {
    featureId: "EFI22965",
    featureDesc: "Inner diameter of cog",
    label: "EFI22965: Inner diameter of cog"
  },
  {
    featureId: "EFI22966",
    featureDesc: "Outer diameter of cog",
    label: "EFI22966: Outer diameter of cog"
  },
  {
    featureId: "EFI22968",
    featureDesc: "Cog thickness",
    label: "EFI22968: Cog thickness"
  },
  {
    featureId: "EFI22969",
    featureDesc: "Inlay thickness",
    label: "EFI22969: Inlay thickness"
  },
  {
    featureId: "EFI22970",
    featureDesc: "Inner length",
    label: "EFI22970: Inner length"
  },
  {
    featureId: "EFI22971",
    featureDesc: "Gasket width",
    label: "EFI22971: Gasket width"
  },
  {
    featureId: "EFI22973",
    featureDesc: "Seating thickness",
    label: "EFI22973: Seating thickness"
  },
  {
    featureId: "EFI22974",
    featureDesc: "Inner diameter inner ring",
    label: "EFI22974: Inner diameter inner ring"
  },
  {
    featureId: "EFI22975",
    featureDesc: "Spiral ring thickness",
    label: "EFI22975: Spiral ring thickness"
  },
  {
    featureId: "EFI22976",
    featureDesc: "Outer ring thickness",
    label: "EFI22976: Outer ring thickness"
  },
  {
    featureId: "EFI22977",
    featureDesc: "Inner ring thickness",
    label: "EFI22977: Inner ring thickness"
  },
  {
    featureId: "EFI22985",
    featureDesc: "Trough height",
    label: "EFI22985: Trough height"
  },
  {
    featureId: "EFI22989",
    featureDesc: "Recess diameter",
    label: "EFI22989: Recess diameter"
  },
  {
    featureId: "EFI22995",
    featureDesc: "Unit width",
    label: "EFI22995: Unit width"
  },
  {
    featureId: "EFI22996",
    featureDesc: "Unit height",
    label: "EFI22996: Unit height"
  },
  {
    featureId: "EFI22997",
    featureDesc: "Unit depth",
    label: "EFI22997: Unit depth"
  },
  {
    featureId: "EFI23006",
    featureDesc: "Tensioning travel",
    label: "EFI23006: Tensioning travel"
  },
  {
    featureId: "EFI23008",
    featureDesc: "Roll width",
    label: "EFI23008: Roll width"
  },
  {
    featureId: "EFI23011",
    featureDesc: "Hub length",
    label: "EFI23011: Hub length"
  },
  {
    featureId: "EFI23015",
    featureDesc: "Bead wall thickness",
    label: "EFI23015: Bead wall thickness"
  },
  {
    featureId: "EFI23017",
    featureDesc: "Mounting width 1",
    label: "EFI23017: Mounting width 1"
  },
  {
    featureId: "EFI23018",
    featureDesc: "Mounting width 2",
    label: "EFI23018: Mounting width 2"
  },
  {
    featureId: "EFI23021",
    featureDesc: "Groove length",
    label: "EFI23021: Groove length"
  },
  {
    featureId: "EFI23069",
    featureDesc: "Wheel diameter",
    label: "EFI23069: Wheel diameter"
  },
  {
    featureId: "EFI23070",
    featureDesc: "Wheel rim diameter",
    label: "EFI23070: Wheel rim diameter"
  },
  {
    featureId: "EFI23079",
    featureDesc: "Tread width",
    label: "EFI23079: Tread width"
  },
  {
    featureId: "EFI23081",
    featureDesc: "Min. shelf height",
    label: "EFI23081: Min. shelf height"
  },
  {
    featureId: "EFI23086",
    featureDesc: "Pump height",
    label: "EFI23086: Pump height"
  },
  {
    featureId: "EFI23094",
    featureDesc: "Height of suction end",
    label: "EFI23094: Height of suction end"
  },
  {
    featureId: "EFI23095",
    featureDesc: "Height of pressure end",
    label: "EFI23095: Height of pressure end"
  },
  {
    featureId: "EFI23096",
    featureDesc: "Pump length",
    label: "EFI23096: Pump length"
  },
  {
    featureId: "EFI23097",
    featureDesc: "Foundation plate length",
    label: "EFI23097: Foundation plate length"
  },
  {
    featureId: "EFI23098",
    featureDesc: "Foundation plate width",
    label: "EFI23098: Foundation plate width"
  },
  {
    featureId: "EFI23099",
    featureDesc: "Length of centre pressure/suction connection",
    label: "EFI23099: Length of centre pressure/suction connection"
  },
  {
    featureId: "EFI23102",
    featureDesc: "Centre size of pressure flange",
    label: "EFI23102: Centre size of pressure flange"
  },
  {
    featureId: "EFI23105",
    featureDesc: "Max. dimension of fixed parts",
    label: "EFI23105: Max. dimension of fixed parts"
  },
  {
    featureId: "EFI23114",
    featureDesc: "Bending radius",
    label: "EFI23114: Bending radius"
  },
  {
    featureId: "EFI23117",
    featureDesc: "Static bending radius at 20 °C",
    label: "EFI23117: Static bending radius at 20 °C"
  },
  {
    featureId: "EFI23118",
    featureDesc: "Dynamic bending radius at 20 °C",
    label: "EFI23118: Dynamic bending radius at 20 °C"
  },
  {
    featureId: "EFI23131",
    featureDesc: "Coupling size",
    label: "EFI23131: Coupling size"
  },
  {
    featureId: "EFI23134",
    featureDesc: "For hose diameter",
    label: "EFI23134: For hose diameter"
  },
  {
    featureId: "EFI23138",
    featureDesc: "Length of hose connector",
    label: "EFI23138: Length of hose connector"
  },
  {
    featureId: "EFI23159",
    featureDesc: "Centre distance of screw threads",
    label: "EFI23159: Centre distance of screw threads"
  },
  {
    featureId: "EFI23161",
    featureDesc: "Flange width",
    label: "EFI23161: Flange width"
  },
  {
    featureId: "EFI23164",
    featureDesc: "Height including legs",
    label: "EFI23164: Height including legs"
  },
  {
    featureId: "EFI23166",
    featureDesc: "Rail height",
    label: "EFI23166: Rail height"
  },
  {
    featureId: "EFI23170",
    featureDesc: "Inner diameter",
    label: "EFI23170: Inner diameter"
  },
  {
    featureId: "EFI23172",
    featureDesc: "Lifting capacity",
    label: "EFI23172: Lifting capacity"
  },
  {
    featureId: "EFI23174",
    featureDesc: "Max. lifting height",
    label: "EFI23174: Max. lifting height"
  },
  {
    featureId: "EFI23180",
    featureDesc: "Discharge head",
    label: "EFI23180: Discharge head"
  },
  {
    featureId: "EFI23182",
    featureDesc: "Medium temperature (continuous)",
    label: "EFI23182: Medium temperature (continuous)"
  },
  {
    featureId: "EFI23189",
    featureDesc: "Nominal flow rate",
    label: "EFI23189: Nominal flow rate"
  },
  {
    featureId: "EFI23195",
    featureDesc: "Nominal duct diameter",
    label: "EFI23195: Nominal duct diameter"
  },
  {
    featureId: "EFI23219",
    featureDesc: "Nominal duct diameter, connection",
    label: "EFI23219: Nominal duct diameter, connection"
  },
  {
    featureId: "EFI23418",
    featureDesc: "Outer hose diameter 1",
    label: "EFI23418: Outer hose diameter 1"
  },
  {
    featureId: "EFI23419",
    featureDesc: "Outer hose diameter 2",
    label: "EFI23419: Outer hose diameter 2"
  },
  {
    featureId: "EFI23420",
    featureDesc: "Outer hose diameter 3",
    label: "EFI23420: Outer hose diameter 3"
  },
  {
    featureId: "EFI23421",
    featureDesc: "Outer hose diameter",
    label: "EFI23421: Outer hose diameter"
  },
  {
    featureId: "EFI23426",
    featureDesc: "Max. working length",
    label: "EFI23426: Max. working length"
  },
  {
    featureId: "EFI23427",
    featureDesc: "For inner diameter hose",
    label: "EFI23427: For inner diameter hose"
  },
  {
    featureId: "EFI23449",
    featureDesc: "Stroke volume",
    label: "EFI23449: Stroke volume"
  },
  {
    featureId: "EFI23450",
    featureDesc: "Length of legs",
    label: "EFI23450: Length of legs"
  },
  {
    featureId: "EFI23453",
    featureDesc: "Max. hose length",
    label: "EFI23453: Max. hose length"
  },
  {
    featureId: "EFI23466",
    featureDesc: "Inner diameter 1",
    label: "EFI23466: Inner diameter 1"
  },
  {
    featureId: "EFI23470",
    featureDesc: "Size of threaded connection",
    label: "EFI23470: Size of threaded connection"
  },
  {
    featureId: "EFI23473",
    featureDesc: "Inner diameter 2",
    label: "EFI23473: Inner diameter 2"
  },
  {
    featureId: "EFI23475",
    featureDesc: "Lead-through height",
    label: "EFI23475: Lead-through height"
  },
  {
    featureId: "EFI23476",
    featureDesc: "Wall thickness 1",
    label: "EFI23476: Wall thickness 1"
  },
  {
    featureId: "EFI23477",
    featureDesc: "Wall thickness 2",
    label: "EFI23477: Wall thickness 2"
  },
  {
    featureId: "EFI23497",
    featureDesc: "Size of thread connection 1",
    label: "EFI23497: Size of thread connection 1"
  },
  {
    featureId: "EFI23500",
    featureDesc: "Size of thread connection 2",
    label: "EFI23500: Size of thread connection 2"
  },
  {
    featureId: "EFI23518",
    featureDesc: "Built-in height/depth",
    label: "EFI23518: Built-in height/depth"
  },
  {
    featureId: "EFI23519",
    featureDesc: "Distance between cover straps",
    label: "EFI23519: Distance between cover straps"
  },
  {
    featureId: "EFI23523",
    featureDesc: "Diameter of cleaning eye",
    label: "EFI23523: Diameter of cleaning eye"
  },
  {
    featureId: "EFI23524",
    featureDesc: "Length of cleaning eye",
    label: "EFI23524: Length of cleaning eye"
  },
  {
    featureId: "EFI23525",
    featureDesc: "Width of cleaning eye",
    label: "EFI23525: Width of cleaning eye"
  },
  {
    featureId: "EFI23546",
    featureDesc: "Link thickness",
    label: "EFI23546: Link thickness"
  },
  {
    featureId: "EFI23547",
    featureDesc: "Gap width",
    label: "EFI23547: Gap width"
  },
  {
    featureId: "EFI23550",
    featureDesc: "Suitable for outer pipe diameter",
    label: "EFI23550: Suitable for outer pipe diameter"
  },
  {
    featureId: "EFI23551",
    featureDesc: "Max. bracket strip width",
    label: "EFI23551: Max. bracket strip width"
  },
  {
    featureId: "EFI23552",
    featureDesc: "Max. bracket height",
    label: "EFI23552: Max. bracket height"
  },
  {
    featureId: "EFI23554",
    featureDesc: "Size of inlet thread connection",
    label: "EFI23554: Size of inlet thread connection"
  },
  {
    featureId: "EFI23556",
    featureDesc: "Size of drum thread connection",
    label: "EFI23556: Size of drum thread connection"
  },
  {
    featureId: "EFI23571",
    featureDesc: "Neutral length",
    label: "EFI23571: Neutral length"
  },
  {
    featureId: "EFI23572",
    featureDesc: "Inspection opening working height",
    label: "EFI23572: Inspection opening working height"
  },
  {
    featureId: "EFI23574",
    featureDesc: "Height (duct top)",
    label: "EFI23574: Height (duct top)"
  },
  {
    featureId: "EFI23575",
    featureDesc: "Working height (duct top)",
    label: "EFI23575: Working height (duct top)"
  },
  {
    featureId: "EFI23577",
    featureDesc: "For duct diameter",
    label: "EFI23577: For duct diameter"
  },
  {
    featureId: "EFI23586",
    featureDesc: "Size of thread connection 3",
    label: "EFI23586: Size of thread connection 3"
  },
  {
    featureId: "EFI23592",
    featureDesc: "Size of thread connection 4",
    label: "EFI23592: Size of thread connection 4"
  },
  {
    featureId: "EFI23611",
    featureDesc: "Spindle thread length",
    label: "EFI23611: Spindle thread length"
  },
  {
    featureId: "EFI23623",
    featureDesc: "Outer pipe diameter reservoir side flushing bend",
    label: "EFI23623: Outer pipe diameter reservoir side flushing bend"
  },
  {
    featureId: "EFI23631",
    featureDesc: "Length of raised edge roof flashing",
    label: "EFI23631: Length of raised edge roof flashing"
  },
  {
    featureId: "EFI23633",
    featureDesc: "Tray height",
    label: "EFI23633: Tray height"
  },
  {
    featureId: "EFI23634",
    featureDesc: "Tray width",
    label: "EFI23634: Tray width"
  },
  {
    featureId: "EFI23635",
    featureDesc: "Expansion length",
    label: "EFI23635: Expansion length"
  },
  {
    featureId: "EFI23636",
    featureDesc: "Fixed point bracket diameter",
    label: "EFI23636: Fixed point bracket diameter"
  },
  {
    featureId: "EFI23638",
    featureDesc: "Leg thickness",
    label: "EFI23638: Leg thickness"
  },
  {
    featureId: "EFI23639",
    featureDesc: "Beam thickness",
    label: "EFI23639: Beam thickness"
  },
  {
    featureId: "EFI23640",
    featureDesc: "Slide length",
    label: "EFI23640: Slide length"
  },
  {
    featureId: "EFI23642",
    featureDesc: "Adjustment range",
    label: "EFI23642: Adjustment range"
  },
  {
    featureId: "EFI23653",
    featureDesc: "Diameter connection facility",
    label: "EFI23653: Diameter connection facility"
  },
  {
    featureId: "EFI23654",
    featureDesc: "Connection size duct attachment",
    label: "EFI23654: Connection size duct attachment"
  },
  {
    featureId: "EFI23659",
    featureDesc: "Centre distance of connection facilities",
    label: "EFI23659: Centre distance of connection facilities"
  },
  {
    featureId: "EFI23700",
    featureDesc: "Glass thickness",
    label: "EFI23700: Glass thickness"
  },
  {
    featureId: "EFI23702",
    featureDesc: "Thread size process connection (mm)",
    label: "EFI23702: Thread size process connection (mm)"
  },
  {
    featureId: "EFI23706",
    featureDesc: "Thread size nipple (mm)",
    label: "EFI23706: Thread size nipple (mm)"
  },
  {
    featureId: "EFI23712",
    featureDesc: "Size of hot water inlet thread connection",
    label: "EFI23712: Size of hot water inlet thread connection"
  },
  {
    featureId: "EFI23743",
    featureDesc: "Max. height of flushing bend",
    label: "EFI23743: Max. height of flushing bend"
  },
  {
    featureId: "EFI23744",
    featureDesc: "Min. height of flush bend",
    label: "EFI23744: Min. height of flush bend"
  },
  {
    featureId: "EFI23754",
    featureDesc: "Length of control panel",
    label: "EFI23754: Length of control panel"
  },
  {
    featureId: "EFI23762",
    featureDesc: "Console length",
    label: "EFI23762: Console length"
  },
  {
    featureId: "EFI23765",
    featureDesc: "Suitable for rail width",
    label: "EFI23765: Suitable for rail width"
  },
  {
    featureId: "EFI23774",
    featureDesc: "Length of threaded connection",
    label: "EFI23774: Length of threaded connection"
  },
  {
    featureId: "EFI23792",
    featureDesc: "Max. flushing water quantity",
    label: "EFI23792: Max. flushing water quantity"
  },
  {
    featureId: "EFI23806",
    featureDesc: "Suitable for door/wall thickness",
    label: "EFI23806: Suitable for door/wall thickness"
  },
  {
    featureId: "EFI23817",
    featureDesc: "Outer pipe diameter collection unit flushing bend",
    label: "EFI23817: Outer pipe diameter collection unit flushing bend"
  },
  {
    featureId: "EFI23854",
    featureDesc: "Slit length",
    label: "EFI23854: Slit length"
  },
  {
    featureId: "EFI23868",
    featureDesc: "Raised part, front",
    label: "EFI23868: Raised part, front"
  },
  {
    featureId: "EFI23869",
    featureDesc: "Raised part, rear",
    label: "EFI23869: Raised part, rear"
  },
  {
    featureId: "EFI23890",
    featureDesc: "Hot tap water storage",
    label: "EFI23890: Hot tap water storage"
  },
  {
    featureId: "EFI23938",
    featureDesc: "Outer pipe diameter feed collector",
    label: "EFI23938: Outer pipe diameter feed collector"
  },
  {
    featureId: "EFI23939",
    featureDesc: "Outer pipe diameter return collector",
    label: "EFI23939: Outer pipe diameter return collector"
  },
  {
    featureId: "EFI23942",
    featureDesc: "Tap water boiler volume",
    label: "EFI23942: Tap water boiler volume"
  },
  {
    featureId: "EFI23955",
    featureDesc: "Nominal duct diameter, main duct",
    label: "EFI23955: Nominal duct diameter, main duct"
  },
  {
    featureId: "EFI23958",
    featureDesc: "Developed tray width",
    label: "EFI23958: Developed tray width"
  },
  {
    featureId: "EFI23966",
    featureDesc: "Outer connection diameter",
    label: "EFI23966: Outer connection diameter"
  },
  {
    featureId: "EFI23968",
    featureDesc: "Height adjusting range",
    label: "EFI23968: Height adjusting range"
  },
  {
    featureId: "EFI23975",
    featureDesc: "Working length",
    label: "EFI23975: Working length"
  },
  {
    featureId: "EFI23992",
    featureDesc: "Diameter of outlets",
    label: "EFI23992: Diameter of outlets"
  },
  {
    featureId: "EFI23993",
    featureDesc: "Width of fire damper",
    label: "EFI23993: Width of fire damper"
  },
  {
    featureId: "EFI23994",
    featureDesc: "Height of fire damper",
    label: "EFI23994: Height of fire damper"
  },
  {
    featureId: "EFI23997",
    featureDesc: "Nominal duct diameter on inlet side",
    label: "EFI23997: Nominal duct diameter on inlet side"
  },
  {
    featureId: "EFI24025",
    featureDesc: "Max. pipe diameter",
    label: "EFI24025: Max. pipe diameter"
  },
  {
    featureId: "EFI24026",
    featureDesc: "Sensor insert length",
    label: "EFI24026: Sensor insert length"
  },
  {
    featureId: "EFI24027",
    featureDesc: "Sensor insert diameter",
    label: "EFI24027: Sensor insert diameter"
  },
  {
    featureId: "EFI24047",
    featureDesc: "Length of slider rail",
    label: "EFI24047: Length of slider rail"
  },
  {
    featureId: "EFI24059",
    featureDesc: "Wall thickness inner pipe",
    label: "EFI24059: Wall thickness inner pipe"
  },
  {
    featureId: "EFI24060",
    featureDesc: "Wall thickness outer pipe",
    label: "EFI24060: Wall thickness outer pipe"
  },
  {
    featureId: "EFI24093",
    featureDesc: "Min. specific density of liquid",
    label: "EFI24093: Min. specific density of liquid"
  },
  {
    featureId: "EFI24106",
    featureDesc: "Outer diameter connection 3",
    label: "EFI24106: Outer diameter connection 3"
  },
  {
    featureId: "EFI24132",
    featureDesc: "Diameter of single-walled system",
    label: "EFI24132: Diameter of single-walled system"
  },
  {
    featureId: "EFI24135",
    featureDesc: "Inner diameter of double-walled system",
    label: "EFI24135: Inner diameter of double-walled system"
  },
  {
    featureId: "EFI24138",
    featureDesc: "Diameter, mechanical ventilation connection",
    label: "EFI24138: Diameter, mechanical ventilation connection"
  },
  {
    featureId: "EFI24192",
    featureDesc: "Effective filter surface area",
    label: "EFI24192: Effective filter surface area"
  },
  {
    featureId: "EFI24202",
    featureDesc: "Outer size of adhesive edge",
    label: "EFI24202: Outer size of adhesive edge"
  },
  {
    featureId: "EFI24210",
    featureDesc: "Max. depth",
    label: "EFI24210: Max. depth"
  },
  {
    featureId: "EFI24211",
    featureDesc: "Height of filter element",
    label: "EFI24211: Height of filter element"
  },
  {
    featureId: "EFI24212",
    featureDesc: "Width of filter element",
    label: "EFI24212: Width of filter element"
  },
  {
    featureId: "EFI24213",
    featureDesc: "Depth of filter element",
    label: "EFI24213: Depth of filter element"
  },
  {
    featureId: "EFI24216",
    featureDesc: "Diameter of cartridges",
    label: "EFI24216: Diameter of cartridges"
  },
  {
    featureId: "EFI24219",
    featureDesc: "Base size, internal, square",
    label: "EFI24219: Base size, internal, square"
  },
  {
    featureId: "EFI24220",
    featureDesc: "Base size, internal, round",
    label: "EFI24220: Base size, internal, round"
  },
  {
    featureId: "EFI24224",
    featureDesc: "Opening dimension",
    label: "EFI24224: Opening dimension"
  },
  {
    featureId: "EFI24231",
    featureDesc: "Specific fan power at Qmax",
    label: "EFI24231: Specific fan power at Qmax"
  },
  {
    featureId: "EFI24238",
    featureDesc: "Cover outer diameter",
    label: "EFI24238: Cover outer diameter"
  },
  {
    featureId: "EFI24246",
    featureDesc: "Air supply volume at 50 Pa",
    label: "EFI24246: Air supply volume at 50 Pa"
  },
  {
    featureId: "EFI24247",
    featureDesc: "Air supply volume at 100 Pa",
    label: "EFI24247: Air supply volume at 100 Pa"
  },
  {
    featureId: "EFI24248",
    featureDesc: "Air supply volume at 150 Pa",
    label: "EFI24248: Air supply volume at 150 Pa"
  },
  {
    featureId: "EFI24249",
    featureDesc: "Air extraction volume at 50 Pa",
    label: "EFI24249: Air extraction volume at 50 Pa"
  },
  {
    featureId: "EFI24250",
    featureDesc: "Air extraction volume at 100 Pa",
    label: "EFI24250: Air extraction volume at 100 Pa"
  },
  {
    featureId: "EFI24251",
    featureDesc: "Air extraction volume at 150 Pa",
    label: "EFI24251: Air extraction volume at 150 Pa"
  },
  {
    featureId: "EFI24255",
    featureDesc: "Roof fan pitch measure",
    label: "EFI24255: Roof fan pitch measure"
  },
  {
    featureId: "EFI24259",
    featureDesc: "Height of inspection opening (duct top)",
    label: "EFI24259: Height of inspection opening (duct top)"
  },
  {
    featureId: "EFI24261",
    featureDesc: "Working height of saddle (from duct)",
    label: "EFI24261: Working height of saddle (from duct)"
  },
  {
    featureId: "EFI24270",
    featureDesc: "Head size, internal",
    label: "EFI24270: Head size, internal"
  },
  {
    featureId: "EFI24271",
    featureDesc: "Head size, external",
    label: "EFI24271: Head size, external"
  },
  {
    featureId: "EFI24274",
    featureDesc: "Diameter duct connection",
    label: "EFI24274: Diameter duct connection"
  },
  {
    featureId: "EFI24275",
    featureDesc: "Air volume at 50 Pa pressure loss",
    label: "EFI24275: Air volume at 50 Pa pressure loss"
  },
  {
    featureId: "EFI24276",
    featureDesc: "Outer size of sound absorber",
    label: "EFI24276: Outer size of sound absorber"
  },
  {
    featureId: "EFI24277",
    featureDesc: "Outer dimensions",
    label: "EFI24277: Outer dimensions"
  },
  {
    featureId: "EFI24281",
    featureDesc: "Differential range/switch hysteresis",
    label: "EFI24281: Differential range/switch hysteresis"
  },
  {
    featureId: "EFI24292",
    featureDesc: "Diaphragm valve outer diameter",
    label: "EFI24292: Diaphragm valve outer diameter"
  },
  {
    featureId: "EFI24301",
    featureDesc: "Nominal pipe diameter",
    label: "EFI24301: Nominal pipe diameter"
  },
  {
    featureId: "EFI24303",
    featureDesc: "Height of filling duct",
    label: "EFI24303: Height of filling duct"
  },
  {
    featureId: "EFI24322",
    featureDesc: "Façade side length",
    label: "EFI24322: Façade side length"
  },
  {
    featureId: "EFI24323",
    featureDesc: "Flue diameter",
    label: "EFI24323: Flue diameter"
  },
  {
    featureId: "EFI24327",
    featureDesc: "System diameter",
    label: "EFI24327: System diameter"
  },
  {
    featureId: "EFI24341",
    featureDesc: "Duct size, branch 1",
    label: "EFI24341: Duct size, branch 1"
  },
  {
    featureId: "EFI24342",
    featureDesc: "Duct size, branch 2",
    label: "EFI24342: Duct size, branch 2"
  },
  {
    featureId: "EFI24343",
    featureDesc: "Pot height",
    label: "EFI24343: Pot height"
  },
  {
    featureId: "EFI24344",
    featureDesc: "Underside of pot/underside of branch",
    label: "EFI24344: Underside of pot/underside of branch"
  },
  {
    featureId: "EFI24346",
    featureDesc: "Bush height",
    label: "EFI24346: Bush height"
  },
  {
    featureId: "EFI24374",
    featureDesc: "Underside bush/centre branch",
    label: "EFI24374: Underside bush/centre branch"
  },
  {
    featureId: "EFI24379",
    featureDesc: "Nominal duct width indoor connection",
    label: "EFI24379: Nominal duct width indoor connection"
  },
  {
    featureId: "EFI24380",
    featureDesc: "Nominal duct height indoor connection",
    label: "EFI24380: Nominal duct height indoor connection"
  },
  {
    featureId: "EFI24381",
    featureDesc: "Nominal duct width, outdoor connection",
    label: "EFI24381: Nominal duct width, outdoor connection"
  },
  {
    featureId: "EFI24382",
    featureDesc: "Nominal duct height, outdoor connection",
    label: "EFI24382: Nominal duct height, outdoor connection"
  },
  {
    featureId: "EFI24395",
    featureDesc: "Underside pot/centre branch",
    label: "EFI24395: Underside pot/centre branch"
  },
  {
    featureId: "EFI24416",
    featureDesc: "Pipe diameter of air relief cover",
    label: "EFI24416: Pipe diameter of air relief cover"
  },
  {
    featureId: "EFI24421",
    featureDesc: "Distance of mounting holes",
    label: "EFI24421: Distance of mounting holes"
  },
  {
    featureId: "EFI24425",
    featureDesc: "Suitable for chimney diameter",
    label: "EFI24425: Suitable for chimney diameter"
  },
  {
    featureId: "EFI24426",
    featureDesc: "Square size",
    label: "EFI24426: Square size"
  },
  {
    featureId: "EFI24427",
    featureDesc: "Bracket clamp diameter",
    label: "EFI24427: Bracket clamp diameter"
  },
  {
    featureId: "EFI24428",
    featureDesc: "Max. bracket distance for wind support",
    label: "EFI24428: Max. bracket distance for wind support"
  },
  {
    featureId: "EFI24429",
    featureDesc: "Adjustment range for wall/rear of bracket",
    label: "EFI24429: Adjustment range for wall/rear of bracket"
  },
  {
    featureId: "EFI24433",
    featureDesc: "Adjustment range for wall/top",
    label: "EFI24433: Adjustment range for wall/top"
  },
  {
    featureId: "EFI24434",
    featureDesc: "Distance of bracket mounting holes",
    label: "EFI24434: Distance of bracket mounting holes"
  },
  {
    featureId: "EFI24436",
    featureDesc: "Flue tube diameter",
    label: "EFI24436: Flue tube diameter"
  },
  {
    featureId: "EFI24438",
    featureDesc: "Adjustment range for wall/rear of flue",
    label: "EFI24438: Adjustment range for wall/rear of flue"
  },
  {
    featureId: "EFI24439",
    featureDesc: "Air volume at 200 Pa (static)",
    label: "EFI24439: Air volume at 200 Pa (static)"
  },
  {
    featureId: "EFI24440",
    featureDesc: "Air volume at 400 Pa (static)",
    label: "EFI24440: Air volume at 400 Pa (static)"
  },
  {
    featureId: "EFI24441",
    featureDesc: "Air volume at 600 Pa (static)",
    label: "EFI24441: Air volume at 600 Pa (static)"
  },
  {
    featureId: "EFI24442",
    featureDesc: "Air volume at 800 Pa (static)",
    label: "EFI24442: Air volume at 800 Pa (static)"
  },
  {
    featureId: "EFI24443",
    featureDesc: "Air volume at 1000 Pa (static)",
    label: "EFI24443: Air volume at 1000 Pa (static)"
  },
  {
    featureId: "EFI24444",
    featureDesc: "Air volume at 1500 Pa (static)",
    label: "EFI24444: Air volume at 1500 Pa (static)"
  },
  {
    featureId: "EFI24445",
    featureDesc: "Air volume at 2000 Pa (static)",
    label: "EFI24445: Air volume at 2000 Pa (static)"
  },
  {
    featureId: "EFI24446",
    featureDesc: "Air volume at 2500 Pa (static)",
    label: "EFI24446: Air volume at 2500 Pa (static)"
  },
  {
    featureId: "EFI24447",
    featureDesc: "Air volume at 3000 Pa (static)",
    label: "EFI24447: Air volume at 3000 Pa (static)"
  },
  {
    featureId: "EFI24448",
    featureDesc: "Air volume at 3500 Pa (static)",
    label: "EFI24448: Air volume at 3500 Pa (static)"
  },
  {
    featureId: "EFI24449",
    featureDesc: "Air volume at 4000 Pa (static)",
    label: "EFI24449: Air volume at 4000 Pa (static)"
  },
  {
    featureId: "EFI24450",
    featureDesc: "Air volume at 4500 Pa (static)",
    label: "EFI24450: Air volume at 4500 Pa (static)"
  },
  {
    featureId: "EFI24451",
    featureDesc: "Air volume at 5000 Pa (static)",
    label: "EFI24451: Air volume at 5000 Pa (static)"
  },
  {
    featureId: "EFI24452",
    featureDesc: "Air volume at 6000 Pa (static)",
    label: "EFI24452: Air volume at 6000 Pa (static)"
  },
  {
    featureId: "EFI24453",
    featureDesc: "Air volume at 8000 Pa (static)",
    label: "EFI24453: Air volume at 8000 Pa (static)"
  },
  {
    featureId: "EFI24454",
    featureDesc: "Air volume at 10000 Pa (static)",
    label: "EFI24454: Air volume at 10000 Pa (static)"
  },
  {
    featureId: "EFI24461",
    featureDesc: "Centre distance bracket",
    label: "EFI24461: Centre distance bracket"
  },
  {
    featureId: "EFI24483",
    featureDesc: "Max. flue diameter",
    label: "EFI24483: Max. flue diameter"
  },
  {
    featureId: "EFI24485",
    featureDesc: "Key length",
    label: "EFI24485: Key length"
  },
  {
    featureId: "EFI24498",
    featureDesc: "Lead/depth",
    label: "EFI24498: Lead/depth"
  },
  {
    featureId: "EFI24506",
    featureDesc: "Length of washing line",
    label: "EFI24506: Length of washing line"
  },
  {
    featureId: "EFI24507",
    featureDesc: "Air volume at 14000 Pa (static, 20 °C)",
    label: "EFI24507: Air volume at 14000 Pa (static, 20 °C)"
  },
  {
    featureId: "EFI24508",
    featureDesc: "Air volume at 18000 Pa (static, 20 °C)",
    label: "EFI24508: Air volume at 18000 Pa (static, 20 °C)"
  },
  {
    featureId: "EFI24510",
    featureDesc: "Nominal duct diameter, suction side",
    label: "EFI24510: Nominal duct diameter, suction side"
  },
  {
    featureId: "EFI24511",
    featureDesc: "Nominal duct diameter, delivery side",
    label: "EFI24511: Nominal duct diameter, delivery side"
  },
  {
    featureId: "EFI24512",
    featureDesc: "Nominal duct width, delivery side",
    label: "EFI24512: Nominal duct width, delivery side"
  },
  {
    featureId: "EFI24513",
    featureDesc: "Nominal duct height, delivery side",
    label: "EFI24513: Nominal duct height, delivery side"
  },
  {
    featureId: "EFI24529",
    featureDesc: "Brush diameter",
    label: "EFI24529: Brush diameter"
  },
  {
    featureId: "EFI24530",
    featureDesc: "Suitable for glass thickness",
    label: "EFI24530: Suitable for glass thickness"
  },
  {
    featureId: "EFI24557",
    featureDesc: "Impeller diameter",
    label: "EFI24557: Impeller diameter"
  },
  {
    featureId: "EFI24558",
    featureDesc: "Duct width, suction",
    label: "EFI24558: Duct width, suction"
  },
  {
    featureId: "EFI24559",
    featureDesc: "Duct height, suction",
    label: "EFI24559: Duct height, suction"
  },
  {
    featureId: "EFI24560",
    featureDesc: "Duct diameter, suction",
    label: "EFI24560: Duct diameter, suction"
  },
  {
    featureId: "EFI24561",
    featureDesc: "Duct diameter, exhaust",
    label: "EFI24561: Duct diameter, exhaust"
  },
  {
    featureId: "EFI24563",
    featureDesc: "Duct width, exhaust",
    label: "EFI24563: Duct width, exhaust"
  },
  {
    featureId: "EFI24564",
    featureDesc: "Duct height, exhaust",
    label: "EFI24564: Duct height, exhaust"
  },
  {
    featureId: "EFI24594",
    featureDesc: "Interior inner diameter",
    label: "EFI24594: Interior inner diameter"
  },
  {
    featureId: "EFI24598",
    featureDesc: "Wall thickness inner hose",
    label: "EFI24598: Wall thickness inner hose"
  },
  {
    featureId: "EFI24638",
    featureDesc: "Depth from wall to front side",
    label: "EFI24638: Depth from wall to front side"
  },
  {
    featureId: "EFI24659",
    featureDesc: "Base height",
    label: "EFI24659: Base height"
  },
  {
    featureId: "EFI24664",
    featureDesc: "Height, bracket/separating partition",
    label: "EFI24664: Height, bracket/separating partition"
  },
  {
    featureId: "EFI24665",
    featureDesc: "Shaft height above convector",
    label: "EFI24665: Shaft height above convector"
  },
  {
    featureId: "EFI24666",
    featureDesc: "Height, convector element",
    label: "EFI24666: Height, convector element"
  },
  {
    featureId: "EFI24679",
    featureDesc: "Outer length",
    label: "EFI24679: Outer length"
  },
  {
    featureId: "EFI24680",
    featureDesc: "Outer width",
    label: "EFI24680: Outer width"
  },
  {
    featureId: "EFI24681",
    featureDesc: "Outer depth",
    label: "EFI24681: Outer depth"
  },
  {
    featureId: "EFI24705",
    featureDesc: "Seat width",
    label: "EFI24705: Seat width"
  },
  {
    featureId: "EFI24706",
    featureDesc: "Seat height",
    label: "EFI24706: Seat height"
  },
  {
    featureId: "EFI24709",
    featureDesc: "Height adjustment range",
    label: "EFI24709: Height adjustment range"
  },
  {
    featureId: "EFI24718",
    featureDesc: "Max. static load",
    label: "EFI24718: Max. static load"
  },
  {
    featureId: "EFI24732",
    featureDesc: "Length of part 1 (largest part)",
    label: "EFI24732: Length of part 1 (largest part)"
  },
  {
    featureId: "EFI24733",
    featureDesc: "Length of part 2",
    label: "EFI24733: Length of part 2"
  },
  {
    featureId: "EFI24734",
    featureDesc: "Length of part 3 (vertical part)",
    label: "EFI24734: Length of part 3 (vertical part)"
  },
  {
    featureId: "EFI24754",
    featureDesc: "Seat depth",
    label: "EFI24754: Seat depth"
  },
  {
    featureId: "EFI24757",
    featureDesc: "Suitable for bath inner dimension",
    label: "EFI24757: Suitable for bath inner dimension"
  },
  {
    featureId: "EFI24790",
    featureDesc: "Depth adjustment range",
    label: "EFI24790: Depth adjustment range"
  },
  {
    featureId: "EFI24809",
    featureDesc: "Air volume high",
    label: "EFI24809: Air volume high"
  },
  {
    featureId: "EFI24810",
    featureDesc: "Air volume medium",
    label: "EFI24810: Air volume medium"
  },
  {
    featureId: "EFI24811",
    featureDesc: "Air volume low",
    label: "EFI24811: Air volume low"
  },
  {
    featureId: "EFI24818",
    featureDesc: "Suitable for cabinet depth/height",
    label: "EFI24818: Suitable for cabinet depth/height"
  },
  {
    featureId: "EFI24826",
    featureDesc: "Bath length",
    label: "EFI24826: Bath length"
  },
  {
    featureId: "EFI24827",
    featureDesc: "Bath width",
    label: "EFI24827: Bath width"
  },
  {
    featureId: "EFI24828",
    featureDesc: "Bath height",
    label: "EFI24828: Bath height"
  },
  {
    featureId: "EFI24829",
    featureDesc: "Casing length",
    label: "EFI24829: Casing length"
  },
  {
    featureId: "EFI24830",
    featureDesc: "Casing width",
    label: "EFI24830: Casing width"
  },
  {
    featureId: "EFI24831",
    featureDesc: "Casing height",
    label: "EFI24831: Casing height"
  },
  {
    featureId: "EFI24845",
    featureDesc: "Water temperature",
    label: "EFI24845: Water temperature"
  },
  {
    featureId: "EFI24851",
    featureDesc: "Centre distance of mounting holes",
    label: "EFI24851: Centre distance of mounting holes"
  },
  {
    featureId: "EFI24854",
    featureDesc: "Thread end length",
    label: "EFI24854: Thread end length"
  },
  {
    featureId: "EFI24855",
    featureDesc: "Suitable for bath size",
    label: "EFI24855: Suitable for bath size"
  },
  {
    featureId: "EFI24866",
    featureDesc: "Outer dimension 1 (largest dimension)",
    label: "EFI24866: Outer dimension 1 (largest dimension)"
  },
  {
    featureId: "EFI24867",
    featureDesc: "Outer dimension 2",
    label: "EFI24867: Outer dimension 2"
  },
  {
    featureId: "EFI24873",
    featureDesc: "Max. static load (at water pressure of 2 bars)",
    label: "EFI24873: Max. static load (at water pressure of 2 bars)"
  },
  {
    featureId: "EFI24888",
    featureDesc: "Built-in width of door for mounting in niche",
    label: "EFI24888: Built-in width of door for mounting in niche"
  },
  {
    featureId: "EFI24889",
    featureDesc: "Built-in width of door for mounting in line",
    label: "EFI24889: Built-in width of door for mounting in line"
  },
  {
    featureId: "EFI24890",
    featureDesc: "Built-in width of door for mounting with side wall",
    label: "EFI24890: Built-in width of door for mounting with side wall"
  },
  {
    featureId: "EFI24891",
    featureDesc: "Built-in length of door for corner entry",
    label: "EFI24891: Built-in length of door for corner entry"
  },
  {
    featureId: "EFI24892",
    featureDesc: "Built-in width of door for U mounting",
    label: "EFI24892: Built-in width of door for U mounting"
  },
  {
    featureId: "EFI24906",
    featureDesc: "Rosette length",
    label: "EFI24906: Rosette length"
  },
  {
    featureId: "EFI24907",
    featureDesc: "Rosette width",
    label: "EFI24907: Rosette width"
  },
  {
    featureId: "EFI24920",
    featureDesc: "Sensor detection range",
    label: "EFI24920: Sensor detection range"
  },
  {
    featureId: "EFI24929",
    featureDesc: "Cable diameter",
    label: "EFI24929: Cable diameter"
  },
  {
    featureId: "EFI24959",
    featureDesc: "Cooling air flow rate",
    label: "EFI24959: Cooling air flow rate"
  },
  {
    featureId: "EFI24960",
    featureDesc: "Heating air flow rate",
    label: "EFI24960: Heating air flow rate"
  },
  {
    featureId: "EFI24968",
    featureDesc: "Outer pipe diameter of fluid pipe",
    label: "EFI24968: Outer pipe diameter of fluid pipe"
  },
  {
    featureId: "EFI24969",
    featureDesc: "Outer pipe diameter condensate drain",
    label: "EFI24969: Outer pipe diameter condensate drain"
  },
  {
    featureId: "EFI24970",
    featureDesc: "Operating temperature for cooling (Dry Bulb)",
    label: "EFI24970: Operating temperature for cooling (Dry Bulb)"
  },
  {
    featureId: "EFI24972",
    featureDesc: "Operating temperature for heating (Wet Bulb)",
    label: "EFI24972: Operating temperature for heating (Wet Bulb)"
  },
  {
    featureId: "EFI24973",
    featureDesc: "Min. pipe length",
    label: "EFI24973: Min. pipe length"
  },
  {
    featureId: "EFI24974",
    featureDesc: "Max. pipe length",
    label: "EFI24974: Max. pipe length"
  },
  {
    featureId: "EFI24975",
    featureDesc: "Max. height difference",
    label: "EFI24975: Max. height difference"
  },
  {
    featureId: "EFI24982",
    featureDesc: "Length of pipe set",
    label: "EFI24982: Length of pipe set"
  },
  {
    featureId: "EFI24987",
    featureDesc: "Min. pipe length per circuit",
    label: "EFI24987: Min. pipe length per circuit"
  },
  {
    featureId: "EFI24988",
    featureDesc: "Max. pipe length per circuit",
    label: "EFI24988: Max. pipe length per circuit"
  },
  {
    featureId: "EFI24989",
    featureDesc: "Max. total pipe length",
    label: "EFI24989: Max. total pipe length"
  },
  {
    featureId: "EFI24996",
    featureDesc: "Operating range of water temperature for cooling",
    label: "EFI24996: Operating range of water temperature for cooling"
  },
  {
    featureId: "EFI24997",
    featureDesc: "Operating range of water temperature for heating",
    label: "EFI24997: Operating range of water temperature for heating"
  },
  {
    featureId: "EFI24998",
    featureDesc: "Water flow rate",
    label: "EFI24998: Water flow rate"
  },
  {
    featureId: "EFI25004",
    featureDesc: "Width (top)",
    label: "EFI25004: Width (top)"
  },
  {
    featureId: "EFI25005",
    featureDesc: "Width (bottom)",
    label: "EFI25005: Width (bottom)"
  },
  {
    featureId: "EFI25006",
    featureDesc: "Reference length (ISO)",
    label: "EFI25006: Reference length (ISO)"
  },
  {
    featureId: "EFI25007",
    featureDesc: "Effective length (RMA)",
    label: "EFI25007: Effective length (RMA)"
  },
  {
    featureId: "EFI25013",
    featureDesc: "Min. disc diameter",
    label: "EFI25013: Min. disc diameter"
  },
  {
    featureId: "EFI25015",
    featureDesc: "Max. belt speed",
    label: "EFI25015: Max. belt speed"
  },
  {
    featureId: "EFI25017",
    featureDesc: "Min. air volume",
    label: "EFI25017: Min. air volume"
  },
  {
    featureId: "EFI25047",
    featureDesc: "Exhaust temperature",
    label: "EFI25047: Exhaust temperature"
  },
  {
    featureId: "EFI25059",
    featureDesc: "Depth (internal to overflow)",
    label: "EFI25059: Depth (internal to overflow)"
  },
  {
    featureId: "EFI25061",
    featureDesc: "Bottom length (lying-length)",
    label: "EFI25061: Bottom length (lying-length)"
  },
  {
    featureId: "EFI25074",
    featureDesc: "Height including legs/support",
    label: "EFI25074: Height including legs/support"
  },
  {
    featureId: "EFI25094",
    featureDesc: "Shower hose length",
    label: "EFI25094: Shower hose length"
  },
  {
    featureId: "EFI25097",
    featureDesc: "Min. flow (mixed water)",
    label: "EFI25097: Min. flow (mixed water)"
  },
  {
    featureId: "EFI25105",
    featureDesc: "Washing-up shower overhang length",
    label: "EFI25105: Washing-up shower overhang length"
  },
  {
    featureId: "EFI25106",
    featureDesc: "Suitable for cabinet width",
    label: "EFI25106: Suitable for cabinet width"
  },
  {
    featureId: "EFI25114",
    featureDesc: "Pipe distance",
    label: "EFI25114: Pipe distance"
  },
  {
    featureId: "EFI25115",
    featureDesc: "Insert depth",
    label: "EFI25115: Insert depth"
  },
  {
    featureId: "EFI25123",
    featureDesc: "Seat height",
    label: "EFI25123: Seat height"
  },
  {
    featureId: "EFI25124",
    featureDesc: "Max. width toilet",
    label: "EFI25124: Max. width toilet"
  },
  {
    featureId: "EFI25125",
    featureDesc: "Suitable for toilet height",
    label: "EFI25125: Suitable for toilet height"
  },
  {
    featureId: "EFI25130",
    featureDesc: "Double-acting torque",
    label: "EFI25130: Double-acting torque"
  },
  {
    featureId: "EFI25133",
    featureDesc: "Diameter measuring head",
    label: "EFI25133: Diameter measuring head"
  },
  {
    featureId: "EFI25134",
    featureDesc: "Sling width",
    label: "EFI25134: Sling width"
  },
  {
    featureId: "EFI25135",
    featureDesc: "Sling length",
    label: "EFI25135: Sling length"
  },
  {
    featureId: "EFI25136",
    featureDesc: "Torque single acting start air",
    label: "EFI25136: Torque single acting start air"
  },
  {
    featureId: "EFI25137",
    featureDesc: "Torque single acting end spring",
    label: "EFI25137: Torque single acting end spring"
  },
  {
    featureId: "EFI25143",
    featureDesc: "Diameter air extraction",
    label: "EFI25143: Diameter air extraction"
  },
  {
    featureId: "EFI25151",
    featureDesc: "Length sampling hose",
    label: "EFI25151: Length sampling hose"
  },
  {
    featureId: "EFI25155",
    featureDesc: "Net weight",
    label: "EFI25155: Net weight"
  },
  {
    featureId: "EFI25164",
    featureDesc: "Font height",
    label: "EFI25164: Font height"
  },
  {
    featureId: "EFI25208",
    featureDesc: "For ship length",
    label: "EFI25208: For ship length"
  },
  {
    featureId: "EFI25217",
    featureDesc: "Diameter inner pipe",
    label: "EFI25217: Diameter inner pipe"
  },
  {
    featureId: "EFI25230",
    featureDesc: "Outer diameter inner pipes",
    label: "EFI25230: Outer diameter inner pipes"
  },
  {
    featureId: "EFI25252",
    featureDesc: "Capacity",
    label: "EFI25252: Capacity"
  },
  {
    featureId: "EFI25257",
    featureDesc: "Material thickness grid",
    label: "EFI25257: Material thickness grid"
  },
  {
    featureId: "EFI25263",
    featureDesc: "Height grid",
    label: "EFI25263: Height grid"
  },
  {
    featureId: "EFI25264",
    featureDesc: "Outer radius grille",
    label: "EFI25264: Outer radius grille"
  },
  {
    featureId: "EFI25265",
    featureDesc: "Inner radius grille",
    label: "EFI25265: Inner radius grille"
  },
  {
    featureId: "EFI25269",
    featureDesc: "Suitable for pipe diameter roof flashing",
    label: "EFI25269: Suitable for pipe diameter roof flashing"
  },
  {
    featureId: "EFI25270",
    featureDesc: "Max. dehumidification capacity per 24 hours",
    label: "EFI25270: Max. dehumidification capacity per 24 hours"
  },
  {
    featureId: "EFI25272",
    featureDesc: "Suction pipe length",
    label: "EFI25272: Suction pipe length"
  },
  {
    featureId: "EFI25273",
    featureDesc: "Suction hose length",
    label: "EFI25273: Suction hose length"
  },
  {
    featureId: "EFI25282",
    featureDesc: "Resin content",
    label: "EFI25282: Resin content"
  },
  {
    featureId: "EFI25283",
    featureDesc: "Salt use per regeneration",
    label: "EFI25283: Salt use per regeneration"
  },
  {
    featureId: "EFI25284",
    featureDesc: "Capacity at 10°D",
    label: "EFI25284: Capacity at 10°D"
  },
  {
    featureId: "EFI25303",
    featureDesc: "Mesh size",
    label: "EFI25303: Mesh size"
  },
  {
    featureId: "EFI25311",
    featureDesc: "Length in the clear",
    label: "EFI25311: Length in the clear"
  },
  {
    featureId: "EFI25312",
    featureDesc: "Width in the clear",
    label: "EFI25312: Width in the clear"
  },
  {
    featureId: "EFI25326",
    featureDesc: "Tube distance",
    label: "EFI25326: Tube distance"
  },
  {
    featureId: "EFI25327",
    featureDesc: "Suitable for vessel contents",
    label: "EFI25327: Suitable for vessel contents"
  },
  {
    featureId: "EFI25328",
    featureDesc: "Pulling head diameter",
    label: "EFI25328: Pulling head diameter"
  },
  {
    featureId: "EFI25333",
    featureDesc: "Diameter pipe mouth",
    label: "EFI25333: Diameter pipe mouth"
  },
  {
    featureId: "EFI25338",
    featureDesc: "Diameter in the clear",
    label: "EFI25338: Diameter in the clear"
  },
  {
    featureId: "EFI25339",
    featureDesc: "Water use per regeneration",
    label: "EFI25339: Water use per regeneration"
  },
  {
    featureId: "EFI25340",
    featureDesc: "Min. mounting height",
    label: "EFI25340: Min. mounting height"
  },
  {
    featureId: "EFI25341",
    featureDesc: "Bottom width (lying-width)",
    label: "EFI25341: Bottom width (lying-width)"
  },
  {
    featureId: "EFI25346",
    featureDesc: "Heat resistance Rd",
    label: "EFI25346: Heat resistance Rd"
  },
  {
    featureId: "EFI25385",
    featureDesc: "Rosette size",
    label: "EFI25385: Rosette size"
  },
  {
    featureId: "EFI25397",
    featureDesc: "Sight height",
    label: "EFI25397: Sight height"
  },
  {
    featureId: "EFI25398",
    featureDesc: "Reel blade diameter",
    label: "EFI25398: Reel blade diameter"
  },
  {
    featureId: "EFI25399",
    featureDesc: "Length (inflated)",
    label: "EFI25399: Length (inflated)"
  },
  {
    featureId: "EFI25400",
    featureDesc: "Width (inflated)",
    label: "EFI25400: Width (inflated)"
  },
  {
    featureId: "EFI25401",
    featureDesc: "Height (inflated)",
    label: "EFI25401: Height (inflated)"
  },
  {
    featureId: "EFI25409",
    featureDesc: "Head height",
    label: "EFI25409: Head height"
  },
  {
    featureId: "EFI25411",
    featureDesc: "Wrench width",
    label: "EFI25411: Wrench width"
  },
  {
    featureId: "EFI25453",
    featureDesc: "Length folded",
    label: "EFI25453: Length folded"
  },
  {
    featureId: "EFI25468",
    featureDesc: "Lug width",
    label: "EFI25468: Lug width"
  },
  {
    featureId: "EFI25472",
    featureDesc: "Countersink diameter",
    label: "EFI25472: Countersink diameter"
  },
  {
    featureId: "EFI25488",
    featureDesc: "Diameter small",
    label: "EFI25488: Diameter small"
  },
  {
    featureId: "EFI25489",
    featureDesc: "Diameter large",
    label: "EFI25489: Diameter large"
  },
  {
    featureId: "EFI25490",
    featureDesc: "Nominal duct diameter on outlet side",
    label: "EFI25490: Nominal duct diameter on outlet side"
  },
  {
    featureId: "EFI25496",
    featureDesc: "Connection pipe diameter",
    label: "EFI25496: Connection pipe diameter"
  },
  {
    featureId: "EFI25498",
    featureDesc: "Operating pressure",
    label: "EFI25498: Operating pressure"
  },
  {
    featureId: "EFI25499",
    featureDesc: "Spray range",
    label: "EFI25499: Spray range"
  },
  {
    featureId: "EFI25503",
    featureDesc: "Outer pipe diameter connection inlet side",
    label: "EFI25503: Outer pipe diameter connection inlet side"
  },
  {
    featureId: "EFI25504",
    featureDesc: "Outer pipe diameter connection outlet side",
    label: "EFI25504: Outer pipe diameter connection outlet side"
  },
  {
    featureId: "EFI25516",
    featureDesc: "Nominal flow",
    label: "EFI25516: Nominal flow"
  },
  {
    featureId: "EFI25521",
    featureDesc: "Back width",
    label: "EFI25521: Back width"
  },
  {
    featureId: "EFI25536",
    featureDesc: "Weight without motor and fuel",
    label: "EFI25536: Weight without motor and fuel"
  },
  {
    featureId: "EFI25540",
    featureDesc: "Displacement",
    label: "EFI25540: Displacement"
  },
  {
    featureId: "EFI25541",
    featureDesc: "Bore",
    label: "EFI25541: Bore"
  },
  {
    featureId: "EFI25544",
    featureDesc: "Dry weight",
    label: "EFI25544: Dry weight"
  },
  {
    featureId: "EFI25545",
    featureDesc: "Capacity fuel tank",
    label: "EFI25545: Capacity fuel tank"
  },
  {
    featureId: "EFI25564",
    featureDesc: "Max. push load",
    label: "EFI25564: Max. push load"
  },
  {
    featureId: "EFI25565",
    featureDesc: "Min. radius",
    label: "EFI25565: Min. radius"
  },
  {
    featureId: "EFI25566",
    featureDesc: "Min. stroke",
    label: "EFI25566: Min. stroke"
  },
  {
    featureId: "EFI25569",
    featureDesc: "Pressure",
    label: "EFI25569: Pressure"
  },
  {
    featureId: "EFI25574",
    featureDesc: "Roll diameter",
    label: "EFI25574: Roll diameter"
  },
  {
    featureId: "EFI25593",
    featureDesc: "Surface single side",
    label: "EFI25593: Surface single side"
  },
  {
    featureId: "EFI25594",
    featureDesc: "Diameter rudder stock shaft",
    label: "EFI25594: Diameter rudder stock shaft"
  },
  {
    featureId: "EFI25595",
    featureDesc: "Length rudder stock shaft",
    label: "EFI25595: Length rudder stock shaft"
  },
  {
    featureId: "EFI25600",
    featureDesc: "Length rudder trunk",
    label: "EFI25600: Length rudder trunk"
  },
  {
    featureId: "EFI25602",
    featureDesc: "Height rudder blade",
    label: "EFI25602: Height rudder blade"
  },
  {
    featureId: "EFI25607",
    featureDesc: "Inner diameter part above roof",
    label: "EFI25607: Inner diameter part above roof"
  },
  {
    featureId: "EFI25608",
    featureDesc: "Inner diameter, connection 1",
    label: "EFI25608: Inner diameter, connection 1"
  },
  {
    featureId: "EFI25609",
    featureDesc: "Inner diameter, connection 2",
    label: "EFI25609: Inner diameter, connection 2"
  },
  {
    featureId: "EFI25628",
    featureDesc: "Grain size",
    label: "EFI25628: Grain size"
  },
  {
    featureId: "EFI25630",
    featureDesc: "Outer diameter of double-walled system",
    label: "EFI25630: Outer diameter of double-walled system"
  },
  {
    featureId: "EFI25637",
    featureDesc: "Outside hexagon",
    label: "EFI25637: Outside hexagon"
  },
  {
    featureId: "EFI25638",
    featureDesc: "Internal hex drive",
    label: "EFI25638: Internal hex drive"
  },
  {
    featureId: "EFI25641",
    featureDesc: "Bulb thickness",
    label: "EFI25641: Bulb thickness"
  },
  {
    featureId: "EFI25659",
    featureDesc: "For hose diameter",
    label: "EFI25659: For hose diameter"
  },
  {
    featureId: "EFI25665",
    featureDesc: "Thickness sheet 1",
    label: "EFI25665: Thickness sheet 1"
  },
  {
    featureId: "EFI25666",
    featureDesc: "Thickness sheet 2",
    label: "EFI25666: Thickness sheet 2"
  },
  {
    featureId: "EFI25672",
    featureDesc: "Overall thickness",
    label: "EFI25672: Overall thickness"
  },
  {
    featureId: "EFI25708",
    featureDesc: "Diameter cutter",
    label: "EFI25708: Diameter cutter"
  },
  {
    featureId: "EFI25711",
    featureDesc: "Max. speed",
    label: "EFI25711: Max. speed"
  },
  {
    featureId: "EFI25717",
    featureDesc: "Cutting deduction",
    label: "EFI25717: Cutting deduction"
  },
  {
    featureId: "EFI25725",
    featureDesc: "Nominal torque",
    label: "EFI25725: Nominal torque"
  },
  {
    featureId: "EFI25761",
    featureDesc: "Diameter hose connection left",
    label: "EFI25761: Diameter hose connection left"
  },
  {
    featureId: "EFI25762",
    featureDesc: "Diameter hose connection right",
    label: "EFI25762: Diameter hose connection right"
  },
  {
    featureId: "EFI25787",
    featureDesc: "Suitable for welding wire diameter",
    label: "EFI25787: Suitable for welding wire diameter"
  },
  {
    featureId: "EFI25790",
    featureDesc: "Suitable for tungsten electrode diameter",
    label: "EFI25790: Suitable for tungsten electrode diameter"
  },
  {
    featureId: "EFI25792",
    featureDesc: "Commercial weight",
    label: "EFI25792: Commercial weight"
  },
  {
    featureId: "EFI25794",
    featureDesc: "Paint surface",
    label: "EFI25794: Paint surface"
  },
  {
    featureId: "EFI25797",
    featureDesc: "Width welding filter",
    label: "EFI25797: Width welding filter"
  },
  {
    featureId: "EFI25798",
    featureDesc: "Height welding filter",
    label: "EFI25798: Height welding filter"
  },
  {
    featureId: "EFI25814",
    featureDesc: "Door height",
    label: "EFI25814: Door height"
  },
  {
    featureId: "EFI25815",
    featureDesc: "Door width",
    label: "EFI25815: Door width"
  },
  {
    featureId: "EFI25820",
    featureDesc: "Refrigerant flow rate",
    label: "EFI25820: Refrigerant flow rate"
  },
  {
    featureId: "EFI25823",
    featureDesc: "Grout width",
    label: "EFI25823: Grout width"
  },
  {
    featureId: "EFI25831",
    featureDesc: "Suitable for propeller shaft diameter",
    label: "EFI25831: Suitable for propeller shaft diameter"
  },
  {
    featureId: "EFI25833",
    featureDesc: "Propeller shaft diameter",
    label: "EFI25833: Propeller shaft diameter"
  },
  {
    featureId: "EFI25841",
    featureDesc: "Bottle volume",
    label: "EFI25841: Bottle volume"
  },
  {
    featureId: "EFI25842",
    featureDesc: "Width holder",
    label: "EFI25842: Width holder"
  },
  {
    featureId: "EFI25843",
    featureDesc: "Height holder",
    label: "EFI25843: Height holder"
  },
  {
    featureId: "EFI25855",
    featureDesc: "Outer diameter propeller",
    label: "EFI25855: Outer diameter propeller"
  },
  {
    featureId: "EFI25856",
    featureDesc: "Length hub",
    label: "EFI25856: Length hub"
  },
  {
    featureId: "EFI25863",
    featureDesc: "Movement sensing, radial +/-",
    label: "EFI25863: Movement sensing, radial +/-"
  },
  {
    featureId: "EFI25867",
    featureDesc: "Hole length",
    label: "EFI25867: Hole length"
  },
  {
    featureId: "EFI25868",
    featureDesc: "Acrylic thickness",
    label: "EFI25868: Acrylic thickness"
  },
  {
    featureId: "EFI25871",
    featureDesc: "Layer thickness surface protection",
    label: "EFI25871: Layer thickness surface protection"
  },
  {
    featureId: "EFI25873",
    featureDesc: "Suitable for joint width",
    label: "EFI25873: Suitable for joint width"
  },
  {
    featureId: "EFI25875",
    featureDesc: "Suitable for chain link diameter",
    label: "EFI25875: Suitable for chain link diameter"
  },
  {
    featureId: "EFI25881",
    featureDesc: "Operating temperature source",
    label: "EFI25881: Operating temperature source"
  },
  {
    featureId: "EFI25883",
    featureDesc: "Nominal volume flow rate source system",
    label: "EFI25883: Nominal volume flow rate source system"
  },
  {
    featureId: "EFI25884",
    featureDesc: "Nominal volume flow rate output system",
    label: "EFI25884: Nominal volume flow rate output system"
  },
  {
    featureId: "EFI25886",
    featureDesc: "Outer pipe diameter connection source system",
    label: "EFI25886: Outer pipe diameter connection source system"
  },
  {
    featureId: "EFI25887",
    featureDesc: "Outer pipe diameter connection output system",
    label: "EFI25887: Outer pipe diameter connection output system"
  },
  {
    featureId: "EFI25891",
    featureDesc: "Air temperature",
    label: "EFI25891: Air temperature"
  },
  {
    featureId: "EFI25910",
    featureDesc: "Suitable for lanyard diameter",
    label: "EFI25910: Suitable for lanyard diameter"
  },
  {
    featureId: "EFI25935",
    featureDesc: "Diameter hose connection",
    label: "EFI25935: Diameter hose connection"
  },
  {
    featureId: "EFI25936",
    featureDesc: "Length opening",
    label: "EFI25936: Length opening"
  },
  {
    featureId: "EFI25943",
    featureDesc: "Suitable for chain link diameter",
    label: "EFI25943: Suitable for chain link diameter"
  },
  {
    featureId: "EFI25944",
    featureDesc: "Suitable for rudder stock diameter",
    label: "EFI25944: Suitable for rudder stock diameter"
  },
  {
    featureId: "EFI25950",
    featureDesc: "Pitch thread ball head",
    label: "EFI25950: Pitch thread ball head"
  },
  {
    featureId: "EFI25951",
    featureDesc: "Pitch thread ball seat",
    label: "EFI25951: Pitch thread ball seat"
  },
  {
    featureId: "EFI25955",
    featureDesc: "Objective diameter",
    label: "EFI25955: Objective diameter"
  },
  {
    featureId: "EFI25956",
    featureDesc: "Exit pupil",
    label: "EFI25956: Exit pupil"
  },
  {
    featureId: "EFI25957",
    featureDesc: "Field of view at 1000 metre",
    label: "EFI25957: Field of view at 1000 metre"
  },
  {
    featureId: "EFI25959",
    featureDesc: "Max. size opening",
    label: "EFI25959: Max. size opening"
  },
  {
    featureId: "EFI25965",
    featureDesc: "Diameter, connection driving side",
    label: "EFI25965: Diameter, connection driving side"
  },
  {
    featureId: "EFI25966",
    featureDesc: "Diameter, connection suction side",
    label: "EFI25966: Diameter, connection suction side"
  },
  {
    featureId: "EFI25967",
    featureDesc: "Diameter, connection discharge side",
    label: "EFI25967: Diameter, connection discharge side"
  },
  {
    featureId: "EFI25968",
    featureDesc: "Suction capacity",
    label: "EFI25968: Suction capacity"
  },
  {
    featureId: "EFI25969",
    featureDesc: "Driving capacity",
    label: "EFI25969: Driving capacity"
  },
  {
    featureId: "EFI25970",
    featureDesc: "Driving head",
    label: "EFI25970: Driving head"
  },
  {
    featureId: "EFI25971",
    featureDesc: "Altitude",
    label: "EFI25971: Altitude"
  },
  {
    featureId: "EFI25993",
    featureDesc: "Stroke",
    label: "EFI25993: Stroke"
  },
  {
    featureId: "EFI25994",
    featureDesc: "Length cylinder",
    label: "EFI25994: Length cylinder"
  },
  {
    featureId: "EFI25995",
    featureDesc: "Outer diameter piston rod",
    label: "EFI25995: Outer diameter piston rod"
  },
  {
    featureId: "EFI25996",
    featureDesc: "Outer diameter cylinder",
    label: "EFI25996: Outer diameter cylinder"
  },
  {
    featureId: "EFI25998",
    featureDesc: "Theoretical coverage",
    label: "EFI25998: Theoretical coverage"
  },
  {
    featureId: "EFI26006",
    featureDesc: "Length lip/eyebolt",
    label: "EFI26006: Length lip/eyebolt"
  },
  {
    featureId: "EFI26010",
    featureDesc: "Height in the clear",
    label: "EFI26010: Height in the clear"
  },
  {
    featureId: "EFI26011",
    featureDesc: "Chain length",
    label: "EFI26011: Chain length"
  },
  {
    featureId: "EFI26012",
    featureDesc: "Flash point",
    label: "EFI26012: Flash point"
  },
  {
    featureId: "EFI26013",
    featureDesc: "Volatile organic compound (VOC)",
    label: "EFI26013: Volatile organic compound (VOC)"
  },
  {
    featureId: "EFI26017",
    featureDesc: "Max. freeboard",
    label: "EFI26017: Max. freeboard"
  },
  {
    featureId: "EFI26018",
    featureDesc: "Max. range",
    label: "EFI26018: Max. range"
  },
  {
    featureId: "EFI26019",
    featureDesc: "Diameter line",
    label: "EFI26019: Diameter line"
  },
  {
    featureId: "EFI26020",
    featureDesc: "Width lock case",
    label: "EFI26020: Width lock case"
  },
  {
    featureId: "EFI26021",
    featureDesc: "Height lock case",
    label: "EFI26021: Height lock case"
  },
  {
    featureId: "EFI26022",
    featureDesc: "Thickness lock case",
    label: "EFI26022: Thickness lock case"
  },
  {
    featureId: "EFI26023",
    featureDesc: "Height lock shackle",
    label: "EFI26023: Height lock shackle"
  },
  {
    featureId: "EFI26024",
    featureDesc: "Thickness lock shackle",
    label: "EFI26024: Thickness lock shackle"
  },
  {
    featureId: "EFI26025",
    featureDesc: "Depth lock shackle",
    label: "EFI26025: Depth lock shackle"
  },
  {
    featureId: "EFI26026",
    featureDesc: "Height front plate",
    label: "EFI26026: Height front plate"
  },
  {
    featureId: "EFI26027",
    featureDesc: "Depth front plate",
    label: "EFI26027: Depth front plate"
  },
  {
    featureId: "EFI26028",
    featureDesc: "Width front plate",
    label: "EFI26028: Width front plate"
  },
  {
    featureId: "EFI26031",
    featureDesc: "Distance handle hole to keyhole",
    label: "EFI26031: Distance handle hole to keyhole"
  },
  {
    featureId: "EFI26032",
    featureDesc: "Distance handle hole to front plate",
    label: "EFI26032: Distance handle hole to front plate"
  },
  {
    featureId: "EFI26035",
    featureDesc: "Hole diameter handle",
    label: "EFI26035: Hole diameter handle"
  },
  {
    featureId: "EFI26037",
    featureDesc: "Glass diameter",
    label: "EFI26037: Glass diameter"
  },
  {
    featureId: "EFI26038",
    featureDesc: "Total diameter",
    label: "EFI26038: Total diameter"
  },
  {
    featureId: "EFI26048",
    featureDesc: "Mesh length",
    label: "EFI26048: Mesh length"
  },
  {
    featureId: "EFI26050",
    featureDesc: "Width bearing bar",
    label: "EFI26050: Width bearing bar"
  },
  {
    featureId: "EFI26051",
    featureDesc: "Thickness bearing bar",
    label: "EFI26051: Thickness bearing bar"
  },
  {
    featureId: "EFI26052",
    featureDesc: "Width crossbar",
    label: "EFI26052: Width crossbar"
  },
  {
    featureId: "EFI26053",
    featureDesc: "Thickness crossbar",
    label: "EFI26053: Thickness crossbar"
  },
  {
    featureId: "EFI26059",
    featureDesc: "Outer depth",
    label: "EFI26059: Outer depth"
  },
  {
    featureId: "EFI26060",
    featureDesc: "Inner depth",
    label: "EFI26060: Inner depth"
  },
  {
    featureId: "EFI26062",
    featureDesc: "Range at 1 lux",
    label: "EFI26062: Range at 1 lux"
  },
  {
    featureId: "EFI26067",
    featureDesc: "Diameter pin",
    label: "EFI26067: Diameter pin"
  },
  {
    featureId: "EFI26070",
    featureDesc: "Toggle length",
    label: "EFI26070: Toggle length"
  },
  {
    featureId: "EFI26071",
    featureDesc: "Outer diameter bush",
    label: "EFI26071: Outer diameter bush"
  },
  {
    featureId: "EFI26072",
    featureDesc: "Bush length",
    label: "EFI26072: Bush length"
  },
  {
    featureId: "EFI26074",
    featureDesc: "Suitable for door thickness",
    label: "EFI26074: Suitable for door thickness"
  },
  {
    featureId: "EFI26075",
    featureDesc: "Distance door to wall",
    label: "EFI26075: Distance door to wall"
  },
  {
    featureId: "EFI26076",
    featureDesc: "Diameter mounting plate",
    label: "EFI26076: Diameter mounting plate"
  },
  {
    featureId: "EFI26077",
    featureDesc: "Length stop",
    label: "EFI26077: Length stop"
  },
  {
    featureId: "EFI26078",
    featureDesc: "Diameter eyebolt",
    label: "EFI26078: Diameter eyebolt"
  },
  {
    featureId: "EFI26079",
    featureDesc: "Length eyebolt",
    label: "EFI26079: Length eyebolt"
  },
  {
    featureId: "EFI26080",
    featureDesc: "Height hinge point",
    label: "EFI26080: Height hinge point"
  },
  {
    featureId: "EFI26081",
    featureDesc: "Width lip",
    label: "EFI26081: Width lip"
  },
  {
    featureId: "EFI26082",
    featureDesc: "Thickness lip",
    label: "EFI26082: Thickness lip"
  },
  {
    featureId: "EFI26085",
    featureDesc: "Length hook",
    label: "EFI26085: Length hook"
  },
  {
    featureId: "EFI26087",
    featureDesc: "Height mounting plate",
    label: "EFI26087: Height mounting plate"
  },
  {
    featureId: "EFI26110",
    featureDesc: "Print width",
    label: "EFI26110: Print width"
  },
  {
    featureId: "EFI26111",
    featureDesc: "Print height",
    label: "EFI26111: Print height"
  },
  {
    featureId: "EFI26113",
    featureDesc: "Sight width",
    label: "EFI26113: Sight width"
  },
  {
    featureId: "EFI26133",
    featureDesc: "Weight filling",
    label: "EFI26133: Weight filling"
  },
  {
    featureId: "EFI26153",
    featureDesc: "Inner diameter tunnel",
    label: "EFI26153: Inner diameter tunnel"
  },
  {
    featureId: "EFI26154",
    featureDesc: "Wall thickness tunnel",
    label: "EFI26154: Wall thickness tunnel"
  },
  {
    featureId: "EFI26155",
    featureDesc: "Thrust",
    label: "EFI26155: Thrust"
  },
  {
    featureId: "EFI26160",
    featureDesc: "Back height",
    label: "EFI26160: Back height"
  },
  {
    featureId: "EFI26187",
    featureDesc: "Pitch circle shore connection",
    label: "EFI26187: Pitch circle shore connection"
  },
  {
    featureId: "EFI26188",
    featureDesc: "Pitch circle head",
    label: "EFI26188: Pitch circle head"
  },
  {
    featureId: "EFI26193",
    featureDesc: "Outer diameter roller/wheel",
    label: "EFI26193: Outer diameter roller/wheel"
  },
  {
    featureId: "EFI26194",
    featureDesc: "Height roller/wheel",
    label: "EFI26194: Height roller/wheel"
  },
  {
    featureId: "EFI26195",
    featureDesc: "Deflection",
    label: "EFI26195: Deflection"
  },
  {
    featureId: "EFI26196",
    featureDesc: "Tread thickness",
    label: "EFI26196: Tread thickness"
  },
  {
    featureId: "EFI26209",
    featureDesc: "Passage ventilation grille",
    label: "EFI26209: Passage ventilation grille"
  },
  {
    featureId: "EFI26210",
    featureDesc: "Sill height",
    label: "EFI26210: Sill height"
  },
  {
    featureId: "EFI26214",
    featureDesc: "Max. door weight",
    label: "EFI26214: Max. door weight"
  },
  {
    featureId: "EFI26221",
    featureDesc: "Diameter window 1",
    label: "EFI26221: Diameter window 1"
  },
  {
    featureId: "EFI26222",
    featureDesc: "Diameter window 2",
    label: "EFI26222: Diameter window 2"
  },
  {
    featureId: "EFI26223",
    featureDesc: "Width window 1",
    label: "EFI26223: Width window 1"
  },
  {
    featureId: "EFI26225",
    featureDesc: "Width window 2",
    label: "EFI26225: Width window 2"
  },
  {
    featureId: "EFI26226",
    featureDesc: "Height window 1",
    label: "EFI26226: Height window 1"
  },
  {
    featureId: "EFI26227",
    featureDesc: "Height window 2",
    label: "EFI26227: Height window 2"
  },
  {
    featureId: "EFI26245",
    featureDesc: "Max. load per m²",
    label: "EFI26245: Max. load per m²"
  },
  {
    featureId: "EFI26248",
    featureDesc: "Angle height",
    label: "EFI26248: Angle height"
  },
  {
    featureId: "EFI26259",
    featureDesc: "Outer width small base",
    label: "EFI26259: Outer width small base"
  },
  {
    featureId: "EFI26260",
    featureDesc: "Outer width large base",
    label: "EFI26260: Outer width large base"
  },
  {
    featureId: "EFI26262",
    featureDesc: "Hole depth",
    label: "EFI26262: Hole depth"
  },
  {
    featureId: "EFI26263",
    featureDesc: "Overlay adjustment",
    label: "EFI26263: Overlay adjustment"
  },
  {
    featureId: "EFI26264",
    featureDesc: "Depth adjustment",
    label: "EFI26264: Depth adjustment"
  },
  {
    featureId: "EFI26265",
    featureDesc: "Height adjustment",
    label: "EFI26265: Height adjustment"
  },
  {
    featureId: "EFI26266",
    featureDesc: "Width top base",
    label: "EFI26266: Width top base"
  },
  {
    featureId: "EFI26267",
    featureDesc: "Width low base",
    label: "EFI26267: Width low base"
  },
  {
    featureId: "EFI26270",
    featureDesc: "Length antenna cable",
    label: "EFI26270: Length antenna cable"
  },
  {
    featureId: "EFI26283",
    featureDesc: "Length antenna",
    label: "EFI26283: Length antenna"
  },
  {
    featureId: "EFI26284",
    featureDesc: "Ambient temperature display unit",
    label: "EFI26284: Ambient temperature display unit"
  },
  {
    featureId: "EFI26285",
    featureDesc: "Ambient temperature antenna",
    label: "EFI26285: Ambient temperature antenna"
  },
  {
    featureId: "EFI26286",
    featureDesc: "Height display unit",
    label: "EFI26286: Height display unit"
  },
  {
    featureId: "EFI26287",
    featureDesc: "Width display unit",
    label: "EFI26287: Width display unit"
  },
  {
    featureId: "EFI26288",
    featureDesc: "Depth display unit",
    label: "EFI26288: Depth display unit"
  },
  {
    featureId: "EFI26298",
    featureDesc: "Suitable for shaft diameter",
    label: "EFI26298: Suitable for shaft diameter"
  },
  {
    featureId: "EFI26308",
    featureDesc: "Bucket volume",
    label: "EFI26308: Bucket volume"
  },
  {
    featureId: "EFI26309",
    featureDesc: "Length stick",
    label: "EFI26309: Length stick"
  },
  {
    featureId: "EFI26322",
    featureDesc: "Door thickness",
    label: "EFI26322: Door thickness"
  },
  {
    featureId: "EFI26323",
    featureDesc: "Width left",
    label: "EFI26323: Width left"
  },
  {
    featureId: "EFI26324",
    featureDesc: "Width right",
    label: "EFI26324: Width right"
  },
  {
    featureId: "EFI26325",
    featureDesc: "Seat width left",
    label: "EFI26325: Seat width left"
  },
  {
    featureId: "EFI26326",
    featureDesc: "Seat width right",
    label: "EFI26326: Seat width right"
  },
  {
    featureId: "EFI26332",
    featureDesc: "Bending strength",
    label: "EFI26332: Bending strength"
  },
  {
    featureId: "EFI26335",
    featureDesc: "Modulus of elasticity",
    label: "EFI26335: Modulus of elasticity"
  },
  {
    featureId: "EFI26345",
    featureDesc: "Average normalized compressive strength",
    label: "EFI26345: Average normalized compressive strength"
  },
  {
    featureId: "EFI26349",
    featureDesc: "Gross density",
    label: "EFI26349: Gross density"
  },
  {
    featureId: "EFI26352",
    featureDesc: "Frost-/icing salt resistance",
    label: "EFI26352: Frost-/icing salt resistance"
  },
  {
    featureId: "EFI26353",
    featureDesc: "Size tolerance (diagonal)",
    label: "EFI26353: Size tolerance (diagonal)"
  },
  {
    featureId: "EFI26354",
    featureDesc: "Size tolerances length/width/thickness",
    label: "EFI26354: Size tolerances length/width/thickness"
  },
  {
    featureId: "EFI26367",
    featureDesc: "Suitable for propeller diameter",
    label: "EFI26367: Suitable for propeller diameter"
  },
  {
    featureId: "EFI26370",
    featureDesc: "Diameter stop",
    label: "EFI26370: Diameter stop"
  },
  {
    featureId: "EFI26379",
    featureDesc: "Insert length connection 1",
    label: "EFI26379: Insert length connection 1"
  },
  {
    featureId: "EFI26380",
    featureDesc: "Insert length connection 2",
    label: "EFI26380: Insert length connection 2"
  },
  {
    featureId: "EFI26381",
    featureDesc: "Insert length connection 3",
    label: "EFI26381: Insert length connection 3"
  },
  {
    featureId: "EFI26383",
    featureDesc: "Outer diameter hub",
    label: "EFI26383: Outer diameter hub"
  },
  {
    featureId: "EFI26389",
    featureDesc: "Diameter bar",
    label: "EFI26389: Diameter bar"
  },
  {
    featureId: "EFI26390",
    featureDesc: "Width bar",
    label: "EFI26390: Width bar"
  },
  {
    featureId: "EFI26391",
    featureDesc: "Height bar",
    label: "EFI26391: Height bar"
  },
  {
    featureId: "EFI26398",
    featureDesc: "Stiffness",
    label: "EFI26398: Stiffness"
  },
  {
    featureId: "EFI26405",
    featureDesc: "Max. blade length",
    label: "EFI26405: Max. blade length"
  },
  {
    featureId: "EFI26406",
    featureDesc: "Diameter drive shaft",
    label: "EFI26406: Diameter drive shaft"
  },
  {
    featureId: "EFI26407",
    featureDesc: "Diameter support shaft",
    label: "EFI26407: Diameter support shaft"
  },
  {
    featureId: "EFI26417",
    featureDesc: "For blade length",
    label: "EFI26417: For blade length"
  },
  {
    featureId: "EFNA0003",
    featureDesc: "Compliant with NIST",
    label: "EFNA0003: Compliant with NIST"
  },
  {
    featureId: "EFNA0005",
    featureDesc: "According to CSA C22.2",
    label: "EFNA0005: According to CSA C22.2"
  },
  {
    featureId: "EFNA0006",
    featureDesc: "According to UL 347 6th edition",
    label: "EFNA0006: According to UL 347 6th edition"
  },
  {
    featureId: "EFNA0007",
    featureDesc: "According to UL 347 7th edition",
    label: "EFNA0007: According to UL 347 7th edition"
  },
  {
    featureId: "EFNL0001",
    featureDesc: "KIWA certified",
    label: "EFNL0001: KIWA certified"
  },
  {
    featureId: "EFNL0002",
    featureDesc: "Gastec QA mark",
    label: "EFNL0002: Gastec QA mark"
  },
  {
    featureId: "EFNL0003",
    featureDesc: "KOMO certified",
    label: "EFNL0003: KOMO certified"
  },
  {
    featureId: "EFNL0004",
    featureDesc: "Burglar resistance class according to NEN 5089",
    label: "EFNL0004: Burglar resistance class according to NEN 5089"
  },
  {
    featureId: "EFNL0005",
    featureDesc: "CW label",
    label: "EFNL0005: CW label"
  },
  {
    featureId: "EFNL0006",
    featureDesc: "Ventilation quantity according to NEN 1087",
    label: "EFNL0006: Ventilation quantity according to NEN 1087"
  },
  {
    featureId: "EFNL0007",
    featureDesc: "Burglar resistance class according to NEN 5096",
    label: "EFNL0007: Burglar resistance class according to NEN 5096"
  },
  {
    featureId: "EFNL0008",
    featureDesc: "SKH quality mark",
    label: "EFNL0008: SKH quality mark"
  },
  {
    featureId: "EFNL0009",
    featureDesc: "Gas quality mark HR",
    label: "EFNL0009: Gas quality mark HR"
  },
  {
    featureId: "EFNL0010",
    featureDesc: "Gas quality mark NZ",
    label: "EFNL0010: Gas quality mark NZ"
  },
  {
    featureId: "EFNL0012",
    featureDesc: "Suitable for surface with initial water absorption according to BRL 1007",
    label: "EFNL0012: Suitable for surface with initial water absorption according to BRL 1007"
  },
  {
    featureId: "EFNL0013",
    featureDesc: "Initial water absorption class according to BRL 1007",
    label: "EFNL0013: Initial water absorption class according to BRL 1007"
  },
  {
    featureId: "EFNL0014",
    featureDesc: "Meets min. EIA service life criterion L90 (at 50,000 hours at tq = 25°C)",
    label: "EFNL0014: Meets min. EIA service life criterion L90 (at 50,000 hours at tq = 25°C)"
  },
  {
    featureId: "EFNL0015",
    featureDesc: "Connection according to NEN 3268",
    label: "EFNL0015: Connection according to NEN 3268"
  },
  {
    featureId: "EFSE0002",
    featureDesc: "Type approval according to BBR/EKS",
    label: "EFSE0002: Type approval according to BBR/EKS"
  },
  {
    featureId: "EFSE0003",
    featureDesc: "Type of perforation according to SS 3520",
    label: "EFSE0003: Type of perforation according to SS 3520"
  },
  {
    featureId: "EFSE0004",
    featureDesc: "Application profile according to NorGeoSpec",
    label: "EFSE0004: Application profile according to NorGeoSpec"
  },
  {
    featureId: "EFSE0005",
    featureDesc: "Fibre optic cable colour coding according to S-12 Skanova standard",
    label: "EFSE0005: Fibre optic cable colour coding according to S-12 Skanova standard"
  },
  {
    featureId: "EFSE0006",
    featureDesc: "Gland plate type according to SEN 280901",
    label: "EFSE0006: Gland plate type according to SEN 280901"
  },
  {
    featureId: "EFUK0001",
    featureDesc: "Circuit integrity according to BS 6387 CWZ",
    label: "EFUK0001: Circuit integrity according to BS 6387 CWZ"
  },
  {
    featureId: "EFUK0004",
    featureDesc: "Circuit integrity according to BS 8434-2",
    label: "EFUK0004: Circuit integrity according to BS 8434-2"
  },
  {
    featureId: "EFUK0005",
    featureDesc: "Circuit integrity according to BS 8491",
    label: "EFUK0005: Circuit integrity according to BS 8491"
  },
  {
    featureId: "EFUK0017",
    featureDesc: "Compliant with UK Building Regulations Part M",
    label: "EFUK0017: Compliant with UK Building Regulations Part M"
  },
  {
    featureId: "EFUK0018",
    featureDesc: "Approved according to WRAS",
    label: "EFUK0018: Approved according to WRAS"
  },
  {
    featureId: "EFUK0019",
    featureDesc: "Compliant with BS 4662",
    label: "EFUK0019: Compliant with BS 4662"
  },
  {
    featureId: "EFUK0020",
    featureDesc: "Compliant with BS 6220",
    label: "EFUK0020: Compliant with BS 6220"
  },
  {
    featureId: "EFUK0021",
    featureDesc: "Maintenance free in accordance with BS 5733",
    label: "EFUK0021: Maintenance free in accordance with BS 5733"
  },
  {
    featureId: "EFUK0022",
    featureDesc: "Cable type according to BS 4737-3.30",
    label: "EFUK0022: Cable type according to BS 4737-3.30"
  },
  {
    featureId: "EFUK0023",
    featureDesc: "Rip cord colour according to BS 4737-3.30",
    label: "EFUK0023: Rip cord colour according to BS 4737-3.30"
  },
  {
    featureId: "EFUK0024",
    featureDesc: "Compliant with BT (British Telecom) specification",
    label: "EFUK0024: Compliant with BT (British Telecom) specification"
  },
  {
    featureId: "EFUK0025",
    featureDesc: "Compliant with IET code of practice: in-service inspection & testing 5th edition",
    label: "EFUK0025: Compliant with IET code of practice: in-service inspection & testing 5th edition"
  }
];

export { etimClassification, etimValues, etimFeatures };