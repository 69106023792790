import { useEffect, useState } from 'react';
// import NextLink from 'next/link';
//import numeral from 'numeral';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { ArrowRight as ArrowRightIcon } from '../../icons/arrow-right';
import { PencilAlt as PencilAltIcon } from '../../icons/pencil-alt';
// import { getInitials } from '../../../utils/get-initials';
import { Scrollbar } from '../../scrollbar';
import { getInitColorSchemeScript } from '@mui/system';
import { format } from 'date-fns';

export const SubscriptionListTable = (props) => {
  const {
    customers,
    customersCount,
    onPageChange,
    onRowsPerPageChange,
    page,
    rowsPerPage,
    ...other
  } = props;
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const navigate = useNavigate();

  // Reset selected customers when customers change
  useEffect(() => {
      if (selectedCustomers.length) {
        setSelectedCustomers([]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customers]);

  const handleSelectAllCustomers = (event) => {
    setSelectedCustomers(event.target.checked
      ? customers.map((customer) => customer.id)
      : []);
  };

  const handleSelectOneCustomer = (event, customerId) => {
    if (!selectedCustomers.includes(customerId)) {
      setSelectedCustomers((prevSelected) => [...prevSelected, customerId]);
    } else {
      setSelectedCustomers((prevSelected) => prevSelected.filter((id) => id !== customerId));
    }
  };

  const goToProduct = (id) => {
    navigate("/contacting/" + id);
  }

  const checkDate = (date) => {
    try {
      const newDate = format(date, 'd.M.yyyy');
      return (newDate);
    }
    catch(err) {
      return ("");
    }
  }

  const getColor = (status) => {

    if (status === "subscribed") {
      return "success.main";
    } else if (["contacted","subscription expired"].includes(status)) {
      return "warning.main";
    } else if (["not interested"].includes(status)) {
      return "error.main";
    } else {
      return "#000";
    }
  }

  const enableBulkActions = selectedCustomers.length > 0;
  const selectedSomeCustomers = selectedCustomers.length > 0
    && selectedCustomers.length < customers.length;
  const selectedAllCustomers = selectedCustomers.length === customers.length;

  return (
    <div {...other}>
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.mode === 'dark'
            ? 'neutral.800'
            : 'neutral.100',
          display: enableBulkActions ? 'block' : 'none',
          px: 2,
          py: 0.5
        }}
      >
        <Checkbox
          checked={selectedAllCustomers}
          indeterminate={selectedSomeCustomers}
          onChange={handleSelectAllCustomers}
        />
        <Button
          size="small"
          sx={{ ml: 2 }}
        >
          Delete
        </Button>
        <Button
          size="small"
          sx={{ ml: 2 }}
        >
          Edit
        </Button>
      </Box>
      <Scrollbar>
        <Table sx={{ minWidth: 700 }}>
          <TableHead sx={{ visibility: enableBulkActions ? 'collapse' : 'visible' }}>
            <TableRow>
              <TableCell>
                Company Name
              </TableCell>
              <TableCell>
                Default contact
              </TableCell>
              <TableCell>
                Latest Contact
              </TableCell>
              <TableCell>
                Next Task
              </TableCell>
              <TableCell>
                Interest
              </TableCell>
              <TableCell align="right">
                Quick add
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customers.map((customer) => {
              const isCustomerSelected = selectedCustomers.includes(customer.id);

              return (
                <TableRow
                  hover
                  key={customer.id}
                  selected={isCustomerSelected}
                  sx={{cursor: "pointer"}}
                >
                  <TableCell onClick={() => goToProduct(customer.id)}>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex'
                      }}
                    >
                      <Box sx={{ ml: 1 }}>
                        <Link
                          color="inherit"
                          variant="subtitle2"
                        >
                          {customer?.companyName}
                        </Link>
                        <Typography
                          color="textSecondary"
                          variant="body2"
                        >
                          {customer?.slug}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell onClick={() => goToProduct(customer.id)}>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex'
                      }}
                    >
                      <Box sx={{ ml: 1 }}>
                        <Typography
                          color="textSecondary"
                          variant="subtitle2"
                        >
                          {customer?.defaultContact?.title} {customer?.defaultContact?.name}
                        </Typography>
                        <Typography
                          color="textSecondary"
                          variant="body2"
                        >
                         {customer?.defaultContact?.email}
                        </Typography>
                        <Typography
                          color="textSecondary"
                          variant="body2"
                        >
                         {customer?.defaultContact?.phone}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell onClick={() => goToProduct(customer.id)}>
                    <Box sx={{ ml: 1 }}>
                      <Typography
                        color="textSecondary"
                        variant="body2"
                      >
                        { checkDate(customer?.latestContact?.contactDate) }
                      </Typography>
                      <Typography
                        color="textSecondary"
                        variant="body2"
                      >
                        {customer?.latestContact?.contactType}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell onClick={() => goToProduct(customer.id)}>
                    <Box sx={{ ml: 1 }}>
                      <Typography
                        color="textSecondary"
                        variant="body2"
                      >
                        { checkDate(customer?.latestContact?.nextTaskDate) }
                        {/* { format(customer?.latestContact?.nextTaskDate, 'dd.MM.yyyy') } */}
                      </Typography>
                      <Typography
                        color="textSecondary"
                        variant="body2"
                      >
                        {customer?.latestContact?.nextTask}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell onClick={() => goToProduct(customer.id)}>
                    <Typography
                      color={getColor(customer.status)}
                      variant="subtitle2"
                    >
                      {customer?.latestContact?.interest}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <IconButton component="a">
                      <PencilAltIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Scrollbar>
      <TablePagination
        component="div"
        count={customersCount}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
      />
    </div>
  );
};

// CustomerListTable.propTypes = {
//   customers: PropTypes.array.isRequired,
//   customersCount: PropTypes.number.isRequired,
//   onPageChange: PropTypes.func.isRequired,
//   onRowsPerPageChange: PropTypes.func,
//   page: PropTypes.number.isRequired,
//   rowsPerPage: PropTypes.number.isRequired
// };
