import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // query: '',
  // next: [],
  // previous: [],
  // status: [],
  // priority: [],
  filters: {
    query: '',
    status: []
  },
  filterItems: []
};

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {

    setFilters: (state, action) => {
      state.filters = action.payload;
    },

    setFilterItems: (state, action) => {
      state.filterItems = action.payload;
    },

    resetAllFilters: (state) => {
      state.filters = {
        query: '',
        status: []
      }
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  setFilters,
  setFilterItems,
  resetAllFilters,
} = productSlice.actions;

export default productSlice.reducer;
