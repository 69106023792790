import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Container,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { useSearchParams } from 'react-router-dom';

const getJsonParameters = (json) => {
  try {
    const object = JSON.parse(json);
    let temp = [];
    for (const property in object) {
      //console.log(`${property}: ${object[property][0]}`);
      //temp.push(`${property}: ${object[property][0]}`)
      temp.push({
        test: property,
        result: object[property][0]
      })
    }
    return temp;
  } catch (error) {
    console.error(error);
    return [];
    // Expected output: ReferenceError: nonExistentFunction is not defined
    // (Note: the exact output may be browser-dependent)
  }
}

const QcCheck = () => {
  const [parameters] = useSearchParams();
  const [ objects, setObjects ] = useState([]);
  const [ currentPhase, setCurrentPhase ] = useState("No phase selected");
  const [ productName, setProductName ] = useState("No product name found");
  
  useEffect(() => {
    const name = parameters.get("name");
    if (name) {
      setProductName(name);
    }
    const phase = parameters.get("phase");
    if (phase) {
      setCurrentPhase(phase);
    }
    const json = parameters.get("json");
    if (json) {
      const parameterList = getJsonParameters(json)
      setObjects(parameterList)
    }
  }, [])
  

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8
        }}
      >
        <Container maxWidth="xl">
          <p>Product: <strong>{productName}</strong></p>
          <p>Phase: <strong>{currentPhase}</strong></p>
          {objects.map((object) => (
            <p>{object?.test}: <strong>{object?.result}</strong></p>
          ))}
        </Container>
      </Box>
    </>
  );
};

// CustomerList.getLayout = (page) => (
//   <AuthGuard>
//     <DashboardLayout>
//       {page}
//     </DashboardLayout>
//   </AuthGuard>
// );

export default QcCheck;