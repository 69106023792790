import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Box, Divider, TextField, Typography, Tooltip, IconButton, Modal, Grid, MenuItem, Tabs, Tab } from '@mui/material';
//import { Add } from '@mui/icons-material';
import { Plus, Plus as PlusIcon } from '../../icons/plus';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { useFormik } from 'formik';
//import { uuid } from 'uuidv4';
import {v4 as uuidv4} from 'uuid';
import { FileDropzoneField } from '../file-dropzone-field';
import { FilesUploadedField } from '../files-uploaded-field';
import { ContentState, Editor, EditorState, convertFromHTML, RichUtils} from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { categoryOptions, getCategoryIndex, getSubcategoryIndex, convertCategory } from '../../data/categories';
import { FileUploader } from './file-uploader';
import { JsonViewerAndEditor } from './json-viewer-and-editor';
import ProductSummary from '../ProductSummary';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: 800,
  width: "80%",
  height: "80%",
  bgcolor: 'background.paper',
  border: '1px solid #313133',
  borderRadius: '8px',
  boxShadow: 24,
  overflowY:'scroll',
  p: 4,
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const ProductEditModal = (props) => {
  const { 
    brands, 
    product, 
    open, 
    close, 
    edit, 
    refresh 
  } = props;
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  return (
    <>
      <Modal
        open={open}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {/* <Box sx={style}>
          <FileUploader product={product} close={close}/>
        </Box> */}
        <Box sx={style}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} variant="fullWidth" onChange={handleChange} aria-label="basic tabs example">
              <Tab label="File uploader" {...a11yProps(0)} />
              <Tab label="JSON" {...a11yProps(1)} />
              <Tab label="Summary" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <FileUploader product={product} close={close} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <JsonViewerAndEditor product={product} close={close} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <ProductSummary productData={product} />
          </CustomTabPanel>
        </Box>
      </Modal>
    </>
  );
};
