import { Grid, Typography } from "@mui/material";

import { ParameterField } from "./parameter-field";

export const ParameterGroup = (props) => {  
  const { 
    dataSchema,
    formik,
    parameterClass,
    showTitle
  } = props;
  //const [ isExactValue, setIsExactValue ] = useState(true);
  
  return (
    <Grid container spacing={1} sx={{mb: 1}}>
      {dataSchema({formik: formik, parameterClass: parameterClass, unitSystem: formik.values.unitSystem}).map((data, index) => (
        <>
          {index === 0 && (showTitle &&
            <Grid item lg={12} md={12} sm={12} xs={12} sx={{mb: 0.5}}>
              <Typography
                color="textSecondary"
                variant="subtitle2"
              >
                  {parameterClass}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <ParameterField 
              formik={formik}
              key={`${parameterClass}-${index}`}
              type={data?.type}
              menuOptions={data?.menuOptions}
              label={data?.label}
              name={data?.name}
              value={data?.value}
              values={data?.values}
              unit={data?.unit}
              unitValue={data?.unitValue}
              unitName={data?.unitName}
              hint={data?.description}
              standardOptions={data?.standardOptions}
              standardValue={data?.standardValue}
              standardName={data?.standardName}
              multipleUnits={data?.multipleUnits}
              unitOptions={data?.unitOptions}
              minMaxOption={data?.minMaxOption}
              minValue={data?.minValue}
              maxValue={data?.maxValue}
              minName={data?.minName}
              maxName={data?.maxName}
              rangeName={data?.rangeName}
              rangeValue={data?.rangeValue}
            />
          </Grid>
        </>
      ))}
    </Grid>
  )
};