import React from 'react';
import { 
  Card, 
  CardContent, 
  Table, 
  TableRow, 
  TableCell, 
  Grid, 
  Typography, 
  TableBody,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableHead
} from '@mui/material';

import parse from 'html-react-parser';
import { VariantAccordion } from '../../variant-accordion';

const camelCaseToText = (text) => {
  const result = text.replace(/([A-Z])/g, " $1");
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
}

const dashedToText = (text) => {
  const result = text.replace(/-/g, ' ');;
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
}



export default function ReviewForm(props) {
  const { formik } = props;


  return (
    
    <>
      <Card sx={{ mt: 2 }}>
        <CardContent>
          {/* <Typography component="h1" align="center" variant="h6" sx={{mb:2}}>Review product</Typography> */}
          <Grid container spacing={3}>
            <Grid item md={3} xs={12}>
              <Typography variant="h6">General</Typography>
            </Grid>
            <Grid item md={9} xs={12} >
              <Table>
                <TableBody>
                  <TableRow key="product-id">
                    <TableCell sx={{width: "50%"}}>
                      <Typography
                          variant="subtitle2"
                      >
                        Product identification
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">{formik.values.manufacturerProductId}</Typography>
                      <Typography variant="subtitle2">{formik.values.model}</Typography>
                      <Typography variant="subtitle2">{formik.values.gtin}</Typography>
                      {/* <Typography variant="body2">{formik.values.id}</Typography> */}
                    </TableCell>
                  </TableRow>
                  <TableRow key="product-name">
                    <TableCell sx={{width: "50%"}}>
                      <Typography
                          variant="subtitle2"
                      > 
                        Product name
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">{formik.values.name}</Typography>
                      <Typography variant="body2">{formik.values.nameSpecification}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow key="product-brand">
                    <TableCell sx={{width: "50%"}}>
                      <Typography
                          variant="subtitle2"
                      >
                        Brand
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">{formik.values.brandName}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow key="country-of-manufacture">
                    <TableCell sx={{width: "50%"}}>
                      <Typography
                          variant="subtitle2"
                      >
                        Country of manufacture
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">{formik.values.countryOfManufacture}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow key="product-description">
                    <TableCell sx={{width: "50%"}}>
                      <Typography
                          variant="subtitle2"
                      >
                        Descriptions
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {/* <Typography variant="body2">{parse(formik.values.description)}</Typography> */}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table> 
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card sx={{mt: 2}}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={3} xs={12}>
              <Typography variant="h6">Category</Typography>
            </Grid>
            <Grid item md={9} xs={12} >
              <Table>
                <TableBody>
                  <TableRow key="categories">
                    <TableCell sx={{width: "50%"}}>
                      <Typography
                          variant="subtitle2"
                      > 
                        Category
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {formik.values.categories.map((category, index) => {
                        return (
                          <Typography variant="body2">{`${dashedToText(category.category)}${category.subcategory1 !== "" ? " > " + dashedToText(category.subcategory1) : ""} ${category.subcategory2 !== "" ? " > " + dashedToText(category.subcategory2) : ""}`}</Typography>
                        )
                      })}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{width: "50%"}}>
                      <Typography
                          variant="subtitle2"
                      >
                        Room
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {formik.values.room.map((room, index) => {
                        return (
                          <Typography key={"room." + index} variant="body2">{dashedToText(room)}</Typography>
                        )
                      })}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{width: "50%"}}>
                      <Typography
                          variant="subtitle2"
                      >
                        Usage
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {formik.values.usage.map((usage, index) => {
                        return (
                          <Typography key={"usage." + index} variant="body2">{dashedToText(usage)}</Typography>
                        )
                      }
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{width: "50%"}}>
                      <Typography
                          variant="subtitle2"
                      >
                        Classification
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {formik.values.classification.map((classification, index) => {
                        if (classification?.value !== "") {
                          return (
                            <Typography key={"classification." + index} variant="body2">{`${classification.label}: ${classification.value}`}</Typography>
                          )
                        }
                      })}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table> 
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card sx={{mt: 2}}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid sx={{paddingBottom: 2}} item md={3} xs={12}>
              <Typography variant="h6">Attributes</Typography>
            </Grid>
            <Grid item md={9} xs={12} >
              <Table>
                <TableBody>
                  {formik.values.attributes.map((attribute, index) => {
                    return (
                      <TableRow
                        // hover
                        key={`attribute-${index}`}
                      >
                        <TableCell sx={{width: "50%"}}>
                          <Typography
                              variant="subtitle2"
                          >
                            {attribute.label}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {attribute.range ? `${attribute.min} - ${attribute.max} ${attribute.unit}` : attribute.values.join(', ')}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )
                  }
                  )}
                </TableBody> 
              </Table> 
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {formik.values.variants.length > 0 && <Card sx={{mt: 2}}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item sx={{paddingBottom: 2}} md={3} xs={12}>
              <Typography variant="h6">Product variants</Typography>
            </Grid>
            <Grid item md={9} xs={12} >
              <Table>
                <TableBody>
                  {formik.values.variants.map((variant, index) => {
                    return (
                      <TableRow
                        // hover
                        key={`variant-${index}`}
                      >
                        <TableCell sx={{width: "50%"}}>
                          <Typography
                              variant="subtitle2"
                          >
                            {variant.variantName}
                          </Typography>
                          <Typography variant="body2">{variant.variantNameSpecification}</Typography>
                        </TableCell>
                        <TableCell>
                          {variant.attributes.map((attribute, attributeIndex) => {
                            return (
                              <Typography key={`variant-${index}-attribute-${attributeIndex}`} variant="body2">{`${attribute.label}: ${attribute.values.join(', ')}`}</Typography>
                            )
                          })}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>

            </Grid>
          </Grid>
        </CardContent>
      </Card>}
      <Card sx={{mt: 2}}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={3} xs={12}>
              <Typography variant="h6">Files</Typography>
            </Grid>
            <Grid item md={9} xs={12} >
              <Table>
                <TableBody>
                   <TableRow
                      // hover
                      key="image-files"
                    >
                      <TableCell>
                        <Typography
                            variant="subtitle2"
                        >
                          Images
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {formik.values.images.map((key) => {
                          return (
                            <Typography variant="body2">{!key.deleted && key.name}</Typography>
                          )
                        })}
                      </TableCell>
                    </TableRow>
                    <TableRow
                      // hover
                      key="revit-files"
                    >
                      <TableCell>
                        <Typography
                            variant="subtitle2"
                        >
                          Revit files
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {formik.values.revitFiles.map((key) => {
                          return (
                            <Typography variant="body2">{!key.deleted && key.name}</Typography>
                          )
                        })}
                      </TableCell>
                    </TableRow>
                    <TableRow
                      // hover
                      key="other-bim-files"
                    >
                      <TableCell>
                        <Typography
                            variant="subtitle2"
                        >
                          Other BIM formats
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {formik.values.otherBimFormats.map((key) => {
                          return (
                            <Typography variant="body2">{!key.deleted && key.name}</Typography>
                          )
                        })}
                      </TableCell>
                    </TableRow>
                    <TableRow
                      // hover
                      key="documentation-files"
                    >
                      <TableCell>
                        <Typography
                            variant="subtitle2"
                        >
                          Documentation
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {formik.values.documentation.map((key) => {
                          return (
                            <Typography variant="body2">{!key.deleted && key.name}</Typography>
                          )
                        })}
                      </TableCell>
                    </TableRow>
                </TableBody> 
              </Table> 
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
