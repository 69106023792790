import { useRef, useState } from 'react';
import {  Tooltip, IconButton } from '@mui/material';
//import { Add } from '@mui/icons-material';
import { PencilAlt as PencilAltIcon } from './icons/pencil-alt';
import { ContactModal } from './contact-modal';

// const exampleData = {
//   id: "wmimaiwmdiamdiawmidma",
//   name: "olli-pekka",
//   email: "ollipekka.aaltonen@gmail.com"
// }

export const ModifyContact = (props) => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  //console.log(props.type)

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Edit">
        <IconButton
          onClick={handleOpen}>
          <PencilAltIcon />
        </IconButton>
      </Tooltip>
      <ContactModal open={open} close={handleClose} companyid={props.companyid} contact={props.contact} edited="true" refresh={props.refresh} />
    </>
  );
};
