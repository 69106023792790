import { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Modal, TextField, FormControl, InputLabel, Select, Stack, MenuItem, Button } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { Plus } from './icons/plus';
import dayjs, { Dayjs} from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import * as Yup from 'yup';
import { useFormik } from 'formik';
//import { uuid } from 'uuidv4';
import {v4 as uuidv4} from 'uuid';
import { addPortalProfile, addPortalUser, addPortalDocument, addPortalBrand, addPortalSubscription } from './api';
import toast from 'react-hot-toast';
import { wait } from './utils/wait';
import { useMsal } from '@azure/msal-react';

// const datePlusTwoWeeks = () => {
//   const twoWeeks = Date.now() + (6.048e+8 * 2);
//   if(twoWeeks) {
//     return twoWeeks;
//   }
// }

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '1px solid #313133',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
};

export const AddMainProfileModal = (props) => {
  const { close, refresh, edited, demodata } = props;
  const { accounts } = useMsal();
  // const [ demoStatus, setDemoStatus ] = useState([]);

  //console.log((new Date().getMonth() + 1) + "/" + new Date().getFullYear() + "-")

  const formik = useFormik({
    initialValues: {
      id: "",
      companyName: '',
      companySlug: '',
      azureId: '',
      email: '',
      submit: null
    },
    validationSchema: Yup.object({
      id: Yup.string().max(255),
      companyName: Yup.string().max(255).required("Company name is a required field"),
      companySlug: Yup.string().max(255).required("Company slug is a required field"),
      azureId: Yup.string().max(255).required("Azure ID is a required field"),
      email: Yup.string().max(255),
      //contactDate: Yup.string().max(255),
    }),
    onSubmit: async (values, helpers) => {
      try {
        // NOTE: Make API request

        const mainProfileId = uuidv4();
        const brandId = uuidv4();
    
        // create user

        //const createdUser = await createPortalUser(brandData);

        // create user

        await addPortalUser({
          id: values.azureId,
          brands: [
            {
              brandId: brandId,
              brandName: values.companyName,
              brandSlug: values.companySlug
            }
          ],
          role: "main"
        });
        
        // Create main profile

        await addPortalProfile({
          id: mainProfileId,
          companyName: values.companyName,
          companySlug: values.companySlug,
          adminUserId: values.azureId,
          email: values.email,
          recoveryEmail: "",
          billingInformation: [],
          subscription: "Free hosting",
          cla: ""
        });

        // create brand

        await addPortalBrand({
          brandId: brandId,
          mainProfileId: mainProfileId,
          brandName: values.companyName,
          brandSlug: values.companySlug,
          brandUrl: '',
          description: '',
          logoUrl: '',
          contactPerson: {
            name: '',
            address: '',
            phoneNumber: '',
            email: ''
          },
          publicContacts: [],
          country: ''
        });

        // add cla

        await addPortalDocument({
          id: uuidv4(),
          mainProfileId: mainProfileId,
          documentType: "Copyright license agreement",
          status: "Sign document"
        });
      
        // })

        // add subscription

        await addPortalSubscription({
          id: uuidv4(),
          type: "Free hosting",
          mainProfileId: mainProfileId,
          status: "active",
          timespan: (new Date().getMonth() + 1) + "/" + new Date().getFullYear() + "-"
        })
       
        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);
        toast.success('Event updated!');
        await wait(500);
        helpers.resetForm();
        //refresh();
        close();
      } catch (err) {
        console.error(err);
        toast.error('Something went wrong!');
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    }
  });



  return (
    <>
      <Modal
        open={props.open}
        onClose={props.close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form
        onSubmit={formik.handleSubmit}
        // {...other}
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{mb: 2, width: "100%"}}>
              Add a main profile
            </Typography>
            <TextField
              margin="dense"
              id="companyName"
              label="Company name"
              name="companyName"
              fullWidth
              required
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.companyName}
            />
            <TextField
              margin="dense"
              id="companySlug"
              label="Slug"
              name="companySlug"
              fullWidth
              required
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.companySlug}
            />
            <TextField
              margin="dense"
              id="email"
              label="Email"
              // multiline
              // minRows={4}
              fullWidth
              name="email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.email}
            />
            <TextField
              margin="dense"
              id="azureId"
              label="Azure ID"
              name="azureId"
              fullWidth
              required
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.azureId}
            />
            <Button
              sx={{mt: 2}}
              disabled={formik.isSubmitting}
              type="submit"
              variant="contained"
              startIcon={<Plus />}
            >
              {/* <Plus /> */}
              Save
            </ Button>
          </Box>
        </form>
      </Modal>
    </>
  );
};
