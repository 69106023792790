import { 
  useEffect, 
  useState 
} from 'react';
import * as Yup from 'yup';
import { toast } from 'react-hot-toast';
import {
  Box,
  Button,
  Grid,
  Typography,
  Modal,
  TextField,
  Card,
  CardContent,
  Autocomplete,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  createFilterOptions,
  Chip,
  styled,
  Switch,
  FormGroup,
  FormControlLabel
  // Slide,
  // Paper
} from '@mui/material';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import { dataSchema } from '../Schema/data-schema';
import { Plus } from '../../../icons/plus';
//import { initialData } from '../Schema/initial-data';
import { variantData } from '../Schema/variant-data';
import { v4 as uuidv4 } from 'uuid';
import { ParameterGroup } from '../../parameter-group';
import { CustomParameterField } from '../../custom-parameter-field';
const merge = require('deepmerge');

const CustomSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase + .MuiSwitch-track': {
    backgroundColor: "#d1d1d1",
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: "#8badc1",
  },
}));

export const VariantModal = (props) => {
  const { open, handleClose, addVariant, category, selectedVariant, edit } = props;
  const filter = createFilterOptions();
  const [unitSystem, setUnitSystem] = useState(false);

  const handleChangeUnitSystem = (event, newValue) => {
    setUnitSystem(newValue);
    if (newValue) {
      formik.setFieldValue("unitSystem", "imperial")
    } else {
      formik.setFieldValue("unitSystem", "metric")
    }
  };

  useEffect(() => {
    if (open && edit) {
      if (Object.keys(selectedVariant).length !== 0) {
        const template = new getVariantData();
        const mergedForm = merge(template, selectedVariant)
        mergedForm["category"] = category
        formik.setValues(mergedForm);
      }
    } else if (open && !edit) {
      const newObject = new getVariantData();
      newObject["category"] = category
      formik.resetForm();
      formik.setValues(newObject);
    }
  }, [open])

  function getVariantData() {
   // console.log(variantData);
    const output = Object.assign({}, variantData());
    return output;
  }

  const cancel = () => {
    handleClose();
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: "85vw",
    bgcolor: 'background.paper',
    borderRadius: 1,
    boxShadow: 24,
    //p: 4,
    py: 4,
    paddingLeft: 4,
    paddingRight: 1,
    maxHeight: "90vh",
    overflowY: "auto"
  };

  const formik = useFormik({
    initialValues: new getVariantData(),
    //validationSchema: currentValidation,
    enableReinitialize: true,
    onSubmit: async (values, helpers) => {
      try {
        if (values.id === "") {
          values.id = uuidv4();
        }
        let o = Object.fromEntries(Object.entries(values.details).filter(([_, v]) => (v.values.length > 0 || v.min !== "" || v.max !== "")));
        values.details = o;
        addVariant(values);
        helpers.resetForm();
        handleClose();
      } catch(err) {
        console.log(err);
      }
    }
  });

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <form
      onSubmit={formik.handleSubmit}
      // {...other}
      >
        <Box sx={style}>
          <Grid container spacing={3} sx={{mb: 1, border: "solid 1px #175c831A", borderRadius: 1}}>
            <Grid item md={3} xs={12}>
              <Typography variant="h6">Variant specs</Typography>
            </Grid>
            <Grid item md={9} xs={12}>
              <Grid container spacing={1}>
                <TextField
                  error={Boolean(formik.touched.variant && formik.errors.variant)}
                  fullWidth
                  label="Variant name"
                  name="variant"
                  size="small"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  sx={{ m: 1 }}
                  value={formik.values.variant}
                />
              </Grid>  
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{my: 1, border: "solid 1px #175c831a", borderRadius: 1}}>
            <Grid item md={3} xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={7} md={12}>
                  <Typography variant="h6">Dimensions</Typography>
                </Grid>
                <Grid item xs={5} md={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <CustomSwitch
                          checked={unitSystem}
                          onChange={handleChangeUnitSystem}
                          inputProps={{ 'aria-label': 'unit-controller' }}
                          // color="secondary"
                        />
                      }
                      label={<Typography sx={{fontSize: 12, fontWeight: 700, color: "#175c83"}}>Imperial/US Customary Units</Typography>}
                      labelPlacement='end'
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={9} xs={12} sx={{paddingRight: 1}}>
              <ParameterGroup key={`parameter-group-Dimensions`} dataSchema={dataSchema} formik={formik} parameterClass="Dimensions" showTitle={false} /> 
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{my: 1, border: "solid 1px #175c831a", borderRadius: 1}}>
            <Grid item md={3} xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={7} md={12}>
                  <Typography variant="h6">Technical details</Typography>
                </Grid>
                <Grid item xs={5} md={12}>
                  {/* <Box sx={{backgroundColor: "#d1d1d1", borderRadius: 1}}> */}
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <CustomSwitch
                            checked={unitSystem}
                            onChange={handleChangeUnitSystem}
                            inputProps={{ 'aria-label': 'unit-controller' }}
                            // color="secondary"
                          />
                        }
                        label={<Typography sx={{fontSize: 12, fontWeight: 700, color: "#175c83"}}>Imperial/US Customary Units</Typography>}
                        labelPlacement='end'
                      />
                    </FormGroup>
                  {/* </Box> */}
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={9} xs={12} sx={{paddingRight: 1}}>
              {["Appearance", "Technical features", "Construction", "Lighting features", "Acoustic", "Quantities", "Fire properties", "Electrical", "Materials", "Approvals"].map((paramClass, index) => 
                <ParameterGroup key={`parameter-group-${paramClass}`} dataSchema={dataSchema} formik={formik} parameterClass={paramClass} showTitle={true} />
              )}
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{my: 1, paddingBottom: 1, border: "solid 1px #175c831a", borderRadius: 1}}>
            <Grid item md={3} xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={7} md={12}>
                  <Typography variant="h6">Sustainability, Energy Efficiency & Certifications</Typography>
                </Grid>
                <Grid item xs={5} md={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <CustomSwitch
                          checked={unitSystem}
                          onChange={handleChangeUnitSystem}
                          inputProps={{ 'aria-label': 'unit-controller' }}
                          // color="secondary"
                        />
                      }
                      label={<Typography sx={{fontSize: 12, fontWeight: 700, color: "#175c83"}}>Imperial/US Customary Units</Typography>}
                      labelPlacement='end'
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={9} xs={12} sx={{paddingRight: 1}}>
              {["Energy efficiency", "Sustainability"].map((paramClass, index) => 
                <ParameterGroup key={`parameter-group-${paramClass}`} dataSchema={dataSchema} formik={formik} parameterClass={paramClass} showTitle={true} />
              )}
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{mt: 1, mb:2, paddingBottom: 1, border: "solid 1px #175c831a", borderRadius: 1}}>
            <Grid item md={3} xs={12}>
              <Typography variant="h6">Custom parameters</Typography>
            </Grid>
            <Grid item md={9} xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} sx={{paddingRight: 1}}> 
                  <CustomParameterField formik={formik} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item>
              <Button onClick={cancel} variant="outlined">Cancel</Button>
            </Grid>
            <Grid item>
              <Button type="submit" variant="contained">Save variant</Button>
            </Grid>
          </Grid>
        </Box>
      </form>
    </Modal>
  )
}
