import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Box, Divider, TextField, Typography, Tooltip, IconButton, Modal, Grid, MenuItem } from '@mui/material';
//import { Add } from '@mui/icons-material';
import { Plus, Plus as PlusIcon } from '../../icons/plus';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { useFormik } from 'formik';
//import { uuid } from 'uuidv4';
import {v4 as uuidv4} from 'uuid';
import { FileDropzoneField } from '../file-dropzone-field';
import { FilesUploadedField } from '../files-uploaded-field';
import { ContentState, Editor, EditorState, convertFromHTML, RichUtils} from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { newDemoProduct, saveToIndex, updateDemoProduct, updateIndex, upload } from '../../api';
import { categoryOptions, getCategoryIndex, getSubcategoryIndex, convertCategory } from '../../data/categories';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: 800,
  width: "80%",
  maxHeight: "90%",
  bgcolor: 'background.paper',
  border: '1px solid #313133',
  borderRadius: '8px',
  boxShadow: 24,
  overflowY:'scroll',
  p: 4,
};

const numericConversion = (str) => {
  const converted = str.replace(/[^\d.,-]/g, '')
  if (converted !== "") {
    const output = [];
    const commaSplit = converted.split(',');
    for (let i = 0; i < commaSplit.length; i++) {
      if (commaSplit[i].indexOf('-') > 0) {
        const dashSplit = commaSplit[i].split('-');
        let min;
        let max;
        if (commaSplit[i].indexOf('-') === 0) {
          min = parseFloat("-" + dashSplit[1]);
          max = parseFloat(dashSplit[2]);
        } else {
          min = parseFloat(dashSplit[0]);
          max = parseFloat(dashSplit[1]);
        }
        output.push(min);
        output.push(max);
      } else {
        output.push(parseFloat(commaSplit[i]));
      }
    }
    return output;
  } else {
    return [];
  }
};

const arrayConversion = (str) => {
   const output = str.replace("up to ", "");
   
   const splitOutput = output.split(', ');
   return splitOutput;
 };
 


export const DemoProductModal = (props) => {
  const { 
    brands, 
    product, 
    open, 
    close, 
    edit, 
    refresh 
  } = props;
  const [productImages, setProductImages] = useState([]);
  const [renderImages, setRenderImages] = useState([]);
  const [glbFiles, setGlbFiles] = useState([]);
  const [revitFiles, setRevitFiles] = useState([]);
  // const [algoliaId, setAlgoliaId] = useState("");
  const [editorState, setEditorState] = useState(
    EditorState.createEmpty()
  );

  const formik = useFormik({
    initialValues: {
      id: product?.id || uuidv4(),
      objectID: product?.objectID || uuidv4(),
      name: product?.name || '',
      sku: product?.sku || '',
      slug: product?.slug || '',
      brandName: product?.brandName || '',
      brandSlug: product?.brandSlug || '',
      brandLogo: product?.brandLogo || '',
      description: product?.description || '',
      category: convertCategory(product?.categories?.lvl0, 0) || '',
      subcategory1: convertCategory(product?.categories?.lvl1, 1) || '',
      subcategory2: convertCategory(product?.categories?.lvl2, 2) || '',
      categories: {
        lvl0: product?.categories?.lvl0 || '',
        lvl1: product?.categories?.lvl1 || '',
        lvl2: product?.categories?.lvl2 || ''
      },
      general: {
        material: product?.general?.material || '',
        materialSecondary: product?.general?.materialSecondary || '',
        model: product?.general?.model || '',
        type: product?.general?.type || '',
        country: product?.general?.country || ''
      },
      dimensions: {
        diameter: product?.dimensions?.diameter || '',
        thickness: product?.dimensions?.thickness || '',
        width: product?.dimensions?.width || '',
        height: product?.dimensions?.height || '',
        depth: product?.dimensions?.depth || '',
        length: product?.dimensions?.length || '',     
      },
      classification: {
        ifcClassification: product?.classification?.ifcClassification || '',
        omniclassNumber: product?.classification?.omniclassNumber || '',
        omniclassTitle: product?.classification?.omniclassTitle || '',
        uniclass2015Code: product?.classification?.uniclass2015Code || '',
        uniclass2015Description: product?.classification?.uniclass2015Description || '',
        omniclassCode: product?.classification?.omniclassCode || '',
        omniclassDescription: product?.classification?.omniclassDescription || '',
        ifcClass: product?.classification?.ifcClass || '',
        uniclass2015Desc: product?.classification?.uniclass2015Desc || '',
        uniclass2Code: product?.classification?.uniclass2Code || '',
        uniclass2Desc: product?.classification?.uniclass2Desc || '',
        nbsRefCode: product?.classification?.nbsRefCode || '',
        nbsRefDesc: product?.classification?.nbsRefDesc || ''
      },
      technicalDetails: {
        weight: product?.technicalDetails?.weight || '',
        soundReductionIndex: product?.technicalDetails?.soundReductionIndex || '',
        fireRating: product?.technicalDetails?.fireRating || '',
        operatingPressure: product?.technicalDetails?.operatingPressure || '',
        securityClass: product?.technicalDetails?.securityClass || '',
        thermalTransmittance: product?.technicalDetails?.thermalTransmittance || '',
        watertightness: product?.technicalDetails?.watertightness || '',
        resistanceToWindLoad: product?.technicalDetails?.resistanceToWindLoad || '',
        mechanicalStress: product?.technicalDetails?.mechanicalStress || '',
        thermalResistance: product?.technicalDetails?.thermalResistance || '',
        density: product?.technicalDetails?.density || '',
        compressiveStrength: product?.technicalDetails?.compressiveStrength || '',
        shearStrength: product?.technicalDetails?.shearStrength || '',
        colourRenderingIndex: product?.technicalDetails?.colourRenderingIndex || '',
        protectionClass: product?.technicalDetails?.protectionClass || '',
        protectionRating: product?.technicalDetails?.protectionRating || '',
        airPermeability: product?.technicalDetails?.airPermeability || '',
        tensileStrength: product?.technicalDetails?.tensileStrength || '',
        voltage: product?.technicalDetails?.voltage || '',
        noiseLevel: product?.technicalDetails?.noiseLevel || '',
        pressure: product?.technicalDetails?.pressure || '',
        colourTemperature: product?.technicalDetails?.colourTemperature || '',
        operatingTemperature: product?.technicalDetails?.operatingTemperature || '',
        luminousFlux: product?.technicalDetails?.luminousFlux || '',
        power: product?.technicalDetails?.power || ''
      },
      glb: product?.glb || [],
      ifc: product?.ifc || [],
      pImage: product?.pImage || [],
      rImage: product?.rImage || [],
      revit: product?.revit || [],
      rfa: product?.rfa || [],
      rvt: product?.rvt || [],
      visible_by: product?.visible_by || [],
      submit: null
    },
    //enableReinitialize: true,
    validationSchema: Yup.object({
      id: Yup.string().max(255),
      brandName: Yup.string().max(255),
      brandSlug: Yup.string().max(255)
    }),
    onSubmit: async (values, helpers) => {
      try {
        // NOTE: Make API request
        //const responseMessage = await addCompany(values);

        uploadPendingFiles();
        
        const algoliaOutput = {
          objects: [{
            id: formik.values.id,
            objectID: formik.values.objectID,
            name: formik.values.name,
            slug: formik.values.slug,
            sku: formik.values.sku,
            brandName: formik.values.brandName,
            brandSlug: formik.values.brandSlug,
            brandLogo: formik.values.brandLogo,
            pImage: formik.values.pImage,
            rImage: formik.values.rImage,
            description: formik.values.description,
            attributes: {
              thickness: numericConversion(formik.values.dimensions.thickness),
              height: numericConversion(formik.values.dimensions.height),
              width: numericConversion(formik.values.dimensions.width),
              depth: numericConversion(formik.values.dimensions.depth),
              length: numericConversion(formik.values.dimensions.length),
              diameter: numericConversion(formik.values.dimensions.diameter),
              soundReductionIndex: numericConversion(formik.values.technicalDetails.soundReductionIndex),
              fireRating: arrayConversion(formik.values.technicalDetails.fireRating),
              thermalTransmittance: numericConversion(formik.values.technicalDetails.thermalTransmittance),
              thermalResistance: numericConversion(formik.values.technicalDetails.thermalResistance)
            },
            categories: formik.values.categories,
            visible_by: formik.values.visible_by
          }]
        };

        if (edit) {
          await updateIndex(algoliaOutput);
          await updateDemoProduct(formik.values);
        } else {
          await saveToIndex(algoliaOutput);
          await newDemoProduct(formik.values);
        }
      
        //const responseMessage = await addDemo(values);

        //close();
        //console.log(responseMessage);
        console.log(formik.values);
        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);
        helpers.resetForm();
        setNewIds();
        toast.success('Contact updated!');
        //refresh();
        close();
      } catch (err) {
        console.error(err);
        toast.error('Something went wrong!');
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    }
  });

  // NEW CODE STARTS

  const editor = useRef(null);
  
  useEffect(() => {
    //console.log(convertToRaw(editorState.getCurrentContent()));
    let html = stateToHTML(editorState.getCurrentContent());
    formik.setFieldValue('description', html);
  }, [editorState]);

  useEffect(() => {
    // html text to editor
    const blocksFromHTML = convertFromHTML(formik.values.description);
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    )
    setEditorState( EditorState.createWithContent(state));
  }, []);

  const uploadPendingFiles = () => {
    // check render images

    if (renderImages.length > 0) {
      handleFileUpload2();
    }

    // check product images

    if (productImages.length > 0) {
      handleFileUpload3();
    }

    // check glbs

    if (glbFiles.length > 0) {
      handleFileUpload4();
    }

    // check revit files

    if (revitFiles.length > 0) {
      handleFileUpload5();
    }
  };

  const handleDrop2 = (newFiles) => {
    setRenderImages((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleRemove2 = (file) => {
    // setRenderImages((prevFiles) => prevFiles.filter((_file) => _file.path !== file.path));
    setRenderImages((prevFiles) => prevFiles.filter((_file) => _file !== file));
  };

  const handleRemoveAll2 = () => {
    setRenderImages([]);
  };

  const handleRemoveAdded2 = (file) => {
    let oldFiles = formik.values.rImage;
    // const fileRemoved = (prevFiles) => prevFiles.filter((_file) => _file.path !== file.path);
    const fileRemoved = (prevFiles) => prevFiles.filter((_file) => _file !== file);
    const newFiles = fileRemoved(oldFiles);
    //console.log(newFiles);
    formik.setFieldValue("rImage", newFiles);
  };

  const handleRemoveAddedAll2 = () => {
    formik.setFieldValue("rImage", []);
  };

  const handleFileUpload2 = () => {
    renderImages.map((file) => {
      let oldArray = formik.values.rImage;
      oldArray.push(file?.name);
      uploadFileToBlob(file, "images");
      formik.setFieldValue("rImage", oldArray);
    });
    setRenderImages([]);
  };

  const handleDrop3 = (newFiles) => {
    setProductImages((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleRemove3 = (file) => {
    // setProductImages((prevFiles) => prevFiles.filter((_file) => _file.path !== file.path));
    setProductImages((prevFiles) => prevFiles.filter((_file) => _file !== file));
  };

  const handleRemoveAll3 = () => {
    setProductImages([]);
  };

  const handleRemoveAdded3 = (file) => {
    let oldFiles = formik.values.pImage;
    // const fileRemoved = (prevFiles) => prevFiles.filter((_file) => _file.path !== file.path);
    const fileRemoved = (prevFiles) => prevFiles.filter((_file) => _file !== file);
    const newFiles = fileRemoved(oldFiles);
    //console.log(newFiles);
    formik.setFieldValue("pImage", newFiles);
  };

  const handleRemoveAddedAll3 = () => {
    formik.setFieldValue("pImage", []);
  };

  const handleFileUpload3 = () => {
    productImages.map((file) => {
      let oldArray = formik.values.pImage;
      oldArray.push(file?.name);
      uploadFileToBlob(file, "images");
      formik.setFieldValue("pImage", oldArray);
    });
    setProductImages([]);
  };

  const handleDrop4 = (newFiles) => {
    setGlbFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleRemove4 = (file) => {
    // setGlbFiles((prevFiles) => prevFiles.filter((_file) => _file.path !== file.path));
    setGlbFiles((prevFiles) => prevFiles.filter((_file) => _file !== file));
  };

  const handleRemoveAll4 = () => {
    setGlbFiles([]);
  };

  const handleRemoveAdded4 = (file) => {
    let oldFiles = formik.values.glb;
    // const fileRemoved = (prevFiles) => prevFiles.filter((_file) => _file.path !== file.path);
    const fileRemoved = (prevFiles) => prevFiles.filter((_file) => _file !== file);
    const newFiles = fileRemoved(oldFiles);
    //console.log(newFiles);
    formik.setFieldValue("glb", newFiles);
  };

  const handleRemoveAddedAll4 = () => {
    formik.setFieldValue("glb", []);
  };

  const handleFileUpload4 = () => {
    glbFiles.map((file) => {
      let oldArray = formik.values.glb;
      oldArray.push(file?.name);
      uploadFileToBlob(file, "glb");
      formik.setFieldValue("glb", oldArray);
    });
    
    setGlbFiles([]);
  };


  const handleDrop5 = (newFiles) => {
    setRevitFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleRemove5 = (file) => {
    // setGlbFiles((prevFiles) => prevFiles.filter((_file) => _file.path !== file.path));
    setRevitFiles((prevFiles) => prevFiles.filter((_file) => _file !== file));
  };

  const handleRemoveAll5 = () => {
    setRevitFiles([]);
  };

  const handleRemoveAdded5 = (file) => {
    let oldFiles = formik.values.revit;
    // const fileRemoved = (prevFiles) => prevFiles.filter((_file) => _file.path !== file.path);
    const fileRemoved = (prevFiles) => prevFiles.filter((_file) => _file !== file);
    const newFiles = fileRemoved(oldFiles);
    //console.log(newFiles);
    formik.setFieldValue("revit", newFiles);
  };

  const handleRemoveAddedAll5 = () => {
    formik.setFieldValue("revit", []);
  };

  const handleFileUpload5 = () => {
    revitFiles.map((file) => {
      let oldArray = formik.values.revit;
      oldArray.push(file?.name);
      uploadFileToBlob(file, "revit");
      formik.setFieldValue("revit", oldArray);
    });
    
    setRevitFiles([]);
  };


  const uploadFileToBlob = async (file, id, folder) => {
    // const newFile = new File([file], id, {
    //   type: file?.type,
    // });
    const newFile = await file.arrayBuffer();

    const response = await upload(newFile, id, folder);
    return response;
  };

  const handleChangeBrand = (event) => {
    formik.setFieldValue("brandSlug", event.target.value);
    
    const brand = brands.filter(brand => brand.brandSlug === event.target.value);
    
    if (brand.length > 0) {
      formik.setFieldValue("brandName", brand[0].brandName);
      if (brand[0].algoliaId) {
        let visibleBy = [];
        visibleBy.push(brand[0].algoliaId);
        formik.setFieldValue("visible_by", visibleBy);
      }
      if (brand[0].brandLogo) {
        formik.setFieldValue("brandLogo", brand[0].brandLogo);
      }
    }
  }

  // CATEGORY CHECKS

  function showSubcategory() {
    let length = categoryOptions[getCategoryIndex(formik.values.category)].subcategory.length;
    if (length === 1) {
      return 'none';
    } else {
      return 'block';
    }
  }

  function showSubcategory2() {
    let length = categoryOptions[getCategoryIndex(formik.values.category)].subcategory[getSubcategoryIndex(formik.values.category,formik.values.subcategory1)].subcategory.length;
    if (length === 1) {
      return 'none';
    } else {
      return 'block';
    }
  }

  function checkSubcategory(level, value) {
    if (level === 1) {
      let indexNumber = categoryOptions[getCategoryIndex(formik.values.category)].subcategory.findIndex(obj => obj.value === value);
      if (indexNumber > 0) {
        return formik.values.subcategory1;
      } else {
        return formik.values.subcategory1;
      }
    } else if (level === 2) {
      let indexNumber = categoryOptions[getCategoryIndex(formik.values.category)].subcategory[getSubcategoryIndex(formik.values.category,formik.values.subcategory1)].subcategory.findIndex(obj => obj.value === value);
      if (indexNumber > 0) {
        return formik.values.subcategory2;
      } else {
        return formik.values.subcategory2;
      }
    } else {
      return value;
    }
  }

  const handleChangeCategory = (event) => {
    formik.setFieldValue("category", event.target.value);
    formik.setFieldValue("subcategory1", "");
    formik.setFieldValue("subcategory2", "");

    if (event.target.value) {
      const output = [];
      const result = categoryOptions.filter(category => category.value === event.target.value);
      if (result[0]) {
        output.push(result[0].label);
      }
      formik.setFieldValue("categories.lvl0", output);
      formik.setFieldValue("categories.lvl1", []);
      formik.setFieldValue("categories.lvl2", []);
    }
  }

  const handleChangeSubCategory1 = (event) => {
    formik.setFieldValue("subcategory1", event.target.value);
    formik.setFieldValue("subcategory2", "");

    if (event.target.value) {
      const output = [];
      const topCategory = categoryOptions.filter(category => category.value === formik.values.category);
      if (topCategory[0]) {
        const subcategory = topCategory[0]?.subcategory?.filter(category => category.value === event.target.value);
        if (subcategory[0]) {
          output.push(topCategory[0].label + " > " + subcategory[0].label);
        }
      }
      formik.setFieldValue("categories.lvl1", output);
      formik.setFieldValue("categories.lvl2", []);
    }
  }

  const handleChangeSubCategory2 = (event) => {
    formik.setFieldValue("subcategory2", event.target.value);

    if (event.target.value) {
      const output = [];
      const topCategory = categoryOptions.filter(category => category.value === formik.values.category);
      if (topCategory[0]) {
        const subcategory1 = topCategory[0]?.subcategory?.filter(category => category.value === formik.values.subcategory1);
        if (subcategory1[0]) {
          const subcategory2 = subcategory1[0]?.subcategory?.filter(category => category.value === event.target.value);
          if (subcategory2[0]) {
            output.push(topCategory[0].label + " > " + subcategory1[0].label + " > " + subcategory2[0].label);
          }
        }
      }
      formik.setFieldValue("categories.lvl2", output);
    }
  }

  // SET NEW IDS

  const setNewIds = () => {
    formik.setFieldValue('id', uuidv4());
    formik.setFieldValue('objectID', uuidv4());
  }

  // NEW CODE ENDS




  return (
    <>
      <Modal
        open={open}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form
        onSubmit={formik.handleSubmit}
        // {...other}
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{mb: 3, width: "100%"}}>
              {edit ? "Modify product" : "New product"}
            </Typography>
            <Grid container spacing={3} sx={{mb: 2}}>
              <Grid item md={4} xs={12}>
                <Typography id="modal-modal-subtitle" variant="subtitle2" component="h2" sx={{mb: 1, width: "100%"}}>
                  Basics
                </Typography>
              </Grid>
              <Grid item md={8} xs={12}>
                <TextField
                  id="outlined-multiline-flexible"
                  label="Product ID"
                  name="id"
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.id}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="Object ID"
                  name="objectID"
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.objectID}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="Product name"
                  name="name"
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.name}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="URL-friendly name (no spaces or special characters)"
                  name="slug"
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.slug}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="SKU"
                  name="sku"
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.sku}
                />
                <TextField
                  fullWidth
                  label="Brand"
                  name="brandSlug"
                  onChange={handleChangeBrand}
                  sx={{mb: 2, width: "100%"}}
                  select
                  value={formik.values.brandSlug}
                >
                  {brands?.map((option) => (
                    <MenuItem key={option.brandSlug} value={option.brandSlug}>
                      {option.brandName}
                    </MenuItem>
                  ))}
                </TextField>
                {/* <TextField
                  fullWidth
                  label="Algolia ID"
                  name="visible_by"
                  disabled
                  onChange={formik.handleChange}
                  sx={{mb: 2, width: "100%"}}
                  value={formik.values.visible_by}
                /> */}
                {formik?.values?.visible_by?.length > 0 && <Typography>{formik?.values?.visible_by[0]}</Typography>}
              </Grid>
            </Grid>
            <Grid container spacing={3}>
            <Grid item md={4} xs={12}>
              <Typography variant="h6">Category</Typography>
            </Grid>
            <Grid item md={8} xs={12}>
              <TextField
                error={Boolean(
                  formik.touched.category && formik.errors.category
                )}
                fullWidth
                label="Category"
                name="category"
                // onBlur={formik.handleBlur}
                // onChange={formik.handleChange}
                onChange={handleChangeCategory}
                select
                sx={{mb: 2}}
                value={formik.values.category}
              >
                {categoryOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                error={Boolean(
                  formik.touched.subcategory1 && formik.errors.subcategory1
                )}
                fullWidth
                label="Subcategory 1"
                name="subcategory1"
                // onBlur={formik.handleBlur}
                // onChange={formik.handleChange}
                onChange={handleChangeSubCategory1}
                sx={{ mb: 2, display: showSubcategory() }}
                select
                value={checkSubcategory(1, formik.values.subcategory1)}
              >
                {categoryOptions[
                  getCategoryIndex(formik.values.category)
                ].subcategory.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                error={Boolean(
                  formik.touched.subcategory2 && formik.errors.subcategory2
                )}
                fullWidth
                label="Subcategory 2"
                name="subcategory2"
                // onBlur={formik.handleBlur}
                // onChange={formik.handleChange}
                onChange={handleChangeSubCategory2}
                sx={{ mb: 2, display: showSubcategory2() }}
                select
                value={checkSubcategory(2, formik.values.subcategory2)}
              >
                {categoryOptions[
                  getCategoryIndex(formik.values.category)
                ].subcategory[
                  getSubcategoryIndex(formik.values.category, formik.values.subcategory1)
                ].subcategory.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
            <Grid container spacing={3} sx={{mb: 2}}>
              <Grid item md={4} xs={12}>
                <Typography id="modal-modal-subtitle" variant="subtitle2" component="h2" sx={{mb: 1, width: "100%"}}>
                  Description
                </Typography>
              </Grid>
              <Grid item md={8} xs={12}>
                <Box sx={{
                  border: "solid 1px #E6E8F0", 
                  borderRadius: "8px", 
                  p: 2, 
                  mb: 2,
                  "&:hover": {
                    border: "solid 1px #000"
                  }
                }}>
                  <Editor
                    ref={editor}
                    editorState={editorState}
                    onChange={editorState => setEditorState(editorState)}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{mb: 2}}>
              <Grid item md={4} xs={12}>
                <Typography id="modal-modal-subtitle" variant="subtitle2" component="h2" sx={{mb: 1, width: "100%"}}>
                  General
                </Typography>
              </Grid>
              <Grid item md={8} xs={12}>
                <TextField
                  id="outlined-multiline-flexible"
                  label="Material"
                  name="general.material"
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.general.material}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="Material (secondary)"
                  name="general.materialSecondary"
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.general.materialSecondary}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="Model"
                  name="general.model"
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.general.model}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="Type"
                  name="general.type"
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.general.type}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="Country of manufacture"
                  name="general.country"
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.general.country}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{mb: 2}}>
              <Grid item md={4} xs={12}>
                <Typography id="modal-modal-subtitle" variant="subtitle2" component="h2" sx={{mb: 1, width: "100%"}}>
                  Dimensions
                </Typography>
              </Grid>
              <Grid item md={8} xs={12}>
                <TextField
                  id="outlined-multiline-flexible"
                  label="Diameter"
                  name="dimensions.diameter"
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.dimensions.diameter}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="Thickness"
                  name="dimensions.thickness"
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.dimensions.thickness}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="Width"
                  name="dimensions.width"
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.dimensions.width}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="Length"
                  name="dimensions.length"
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.dimensions.length}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="Height"
                  name="dimensions.height"
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.dimensions.height}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="Depth"
                  name="dimensions.depth"
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.dimensions.depth}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{mb: 2}}>
              <Grid item md={4} xs={12}>
                <Typography id="modal-modal-subtitle" variant="subtitle2" component="h2" sx={{mb: 1, width: "100%"}}>
                  Technical details
                </Typography>
              </Grid>
              <Grid item md={8} xs={12}>
                <TextField
                  id="outlined-multiline-flexible"
                  label="Weight"
                  name="technicalDetails.weight"
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.technicalDetails.weight}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="Sound reduction index"
                  name="technicalDetails.soundReductionIndex"
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.technicalDetails.soundReductionIndex}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="Fire rating"
                  name="technicalDetails.fireRating"
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.technicalDetails.fireRating}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="Operating pressure"
                  name="technicalDetails.operatingPressure"
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.technicalDetails.operatingPressure}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="Security Class"
                  name="technicalDetails.securityClass"
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.technicalDetails.securityClass}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="Watertightness"
                  name="technicalDetails.watertightness"
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.technicalDetails.watertightness}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="Resistance to wind load"
                  name="technicalDetails.resistanceToWindLoad"
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.technicalDetails.resistanceToWindLoad}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="Mechanical stress"
                  name="technicalDetails.mechanicalStress"
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.technicalDetails.mechanicalStress}
                />
                {/* <TextField
                  id="outlined-multiline-flexible"
                  label=""
                  name=""
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.dimensions.}
                /> */}
                <TextField
                  id="outlined-multiline-flexible"
                  label="Thermal resistance"
                  name="technicalDetails.thermalResistance"
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.technicalDetails.thermalResistance}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="Density"
                  name="technicalDetails.density"
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.technicalDetails.density}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="Shear strength"
                  name="technicalDetails.shearStrength"
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.technicalDetails.shearStrength}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="Compressive strength"
                  name="technicalDetails.compressiveStrength"
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.technicalDetails.compressiveStrength}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="Colour rendering index"
                  name="technicalDetails.colourRenderingIndex"
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.technicalDetails.colourRenderingIndex}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="Protection class"
                  name="technicalDetails.protectionClass"
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.technicalDetails.protectionClass}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="Protection rating"
                  name="technicalDetails.protectionRating"
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.technicalDetails.protectionRating}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="Air permeability"
                  name="technicalDetails.airPermeability"
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.technicalDetails.airPermeability}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="Tensile strength"
                  name="technicalDetails.tensileStrength"
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.technicalDetails.tensileStrength}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="Voltage"
                  name="technicalDetails.voltage"
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.technicalDetails.voltage}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="Noise level"
                  name="technicalDetails.noiseLevel"
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.technicalDetails.noiseLevel}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="Pressure"
                  name="technicalDetails.pressure"
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.technicalDetails.pressure}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="Colour temperature"
                  name="technicalDetails.colourTemperature"
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.technicalDetails.colourTemperature}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="Operating temperature"
                  name="technicalDetails.operatingTemperature"
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.technicalDetails.operatingTemperature}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="Luminous flux"
                  name="technicalDetails.luminousFlux"
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.technicalDetails.luminousFlux}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="Power"
                  name="technicalDetails.power"
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.technicalDetails.power}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{mb: 2}}>
              <Grid item md={4} xs={12}>
                <Typography id="modal-modal-subtitle" variant="subtitle2" component="h2" sx={{mb: 1, width: "100%"}}>
                  Classification
                </Typography>
              </Grid>
              <Grid item md={8} xs={12}>
                <TextField
                  id="outlined-multiline-flexible"
                  label="IFC classification"
                  name="classification.ifcClassification"
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.classification.ifcClassification}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="Omniclass number"
                  name="classification.omniclassNumber"
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.classification.omniclassNumber}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="Omniclass title"
                  name="classification.omniclassTitle"
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.classification.omniclassTitle}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="Uniclass 2015 code"
                  name="classification.uniclass2015Code"
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.classification.uniclass2015Code}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="Uniclass 2015 description"
                  name="classification.uniclass2015Description"
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.classification.uniclass2015Description}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="Omniclass code"
                  name="classification.omniclassCode"
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.classification.omniclassCode}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="Omniclass description"
                  name="classification.omniclassDescription"
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.classification.omniclassDescription}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="Uniclass 2.0 code"
                  name="classification.uniclass2Code"
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.classification.uniclass2Code}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="Uniclass 2.0 description"
                  name="classification.uniclass2Desc"
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.classification.uniclass2Desc}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="NBS Ref. code"
                  name="classification.nbsRefCode"
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.classification.nbsRefCode}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="NBS Ref. description"
                  name="classification.nbsRefDesc"
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.classification.nbsRefDesc}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={3}
              sx={{mt: 1}}
            >
              <Grid item md={4}  xs={12}>
                <Typography color="textSecondary" variant="subtitle2" sx={{ mt: 1 }}>
                  Upload render images
                </Typography>
              </Grid>
              <Grid item md={8} xs={12}>
                <FileDropzoneField
                  files={renderImages}
                  onDrop={handleDrop2}
                  onRemove={handleRemove2}
                  onRemoveAll={handleRemoveAll2}
                  onUpload={handleFileUpload2}
                />
                <FilesUploadedField data={formik.values.rImage} onRemove={handleRemoveAdded2} />
              </Grid>
              <Grid item md={4}  xs={12}>
                <Typography color="textSecondary" variant="subtitle2" sx={{ mt: 1 }}>
                  Upload product images
                </Typography>
              </Grid>
              <Grid item md={8} xs={12}>
                <FileDropzoneField
                  files={productImages}
                  onDrop={handleDrop3}
                  onRemove={handleRemove3}
                  onRemoveAll={handleRemoveAll3}
                  onUpload={handleFileUpload3}
                />
                <FilesUploadedField data={formik.values.pImage} onRemove={handleRemoveAdded3} />
              </Grid>
              <Grid item md={4}  xs={12}>
                <Typography color="textSecondary" variant="subtitle2" sx={{ mt: 1 }}>
                  Upload glb/gltf files
                </Typography>
              </Grid>
              <Grid item md={8} xs={12}>
                <FileDropzoneField
                  files={glbFiles}
                  onDrop={handleDrop4}
                  onRemove={handleRemove4}
                  onRemoveAll={handleRemoveAll4}
                  onUpload={handleFileUpload4}
                />
                <FilesUploadedField data={formik.values.glb} onRemove={handleRemoveAdded4} />
              </Grid>
              <Grid item md={4}  xs={12}>
                <Typography color="textSecondary" variant="subtitle2" sx={{ mt: 1 }}>
                  Upload rfa/rvt files
                </Typography>
              </Grid>
              <Grid item md={8} xs={12}>
                <FileDropzoneField
                  files={revitFiles}
                  onDrop={handleDrop5}
                  onRemove={handleRemove5}
                  onRemoveAll={handleRemoveAll5}
                  onUpload={handleFileUpload5}
                />
                <FilesUploadedField data={formik.values.revit} onRemove={handleRemoveAdded5} />
              </Grid>
            </Grid>             
            <IconButton 
              disabled={formik.isSubmitting}
              type="submit"
            >
            <PlusIcon />
              <Typography>Save</Typography>
            </IconButton> 
          </Box>
        </form>
      </Modal>
    </>
  );
};
