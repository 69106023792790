import { useCallback, useEffect, useState } from 'react';
// import NextLink from 'next/link';
//import numeral from 'numeral';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
// import { getInitials } from '../../../utils/get-initials';
import { Scrollbar } from '../../scrollbar';
import { format } from 'date-fns';
import { PencilAlt as PencilAltIcon } from '../../icons/pencil-alt';
import { Eye as EyeIcon } from '../../icons/eye';

import { useMounted } from '../../hooks/use-mounted';
import { ProductEditModal } from './product-edit-modal';



export const ProductList = (props) => {
  const {
    brands,
    products,
    productsCount,
    onPageChange,
    onRowsPerPageChange,
    page,
    rowsPerPage,
    refresh,
    ...other
  } = props;
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [open, setOpen] = useState(false);
  // const [selectedId, setSelectedId] = useState("");
  const [product, setProduct] = useState({});
  const navigate = useNavigate();

  const navigateToEdit = (product) => {
    if (product?.formId === "bon.0.1") {
      navigate("/products/v1/new?mode=edit&id=" + product.productId);
    } else if (product?.formId === "bon.0.2") {
      navigate("/products/form/v2?mode=edit&id=" + product.id);
    }
  }

  
  // Reset selected products when products change
  // useEffect(() => {
  //     if (selectedProducts.length) {
  //       setSelectedProducts([]);
  //     }
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [products]);

  const handleSelectAllProducts = (event) => {
    setSelectedProducts(event.target.checked
      ? products.map((product) => product.id)
      : []);
  };

  const handleSelectOneProduct = (event, productId) => {
    if (!selectedProducts.includes(productId)) {
      setSelectedProducts((prevSelected) => [...prevSelected, productId]);
    } else {
      setSelectedProducts((prevSelected) => prevSelected.filter((id) => id !== productId));
    }
  };


  // const goToProduct = (id) => {
  //   navigate("/demo/" + id);
  // }

  // const openDemo = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenModal = (product) => {
    setProduct(product);
    setOpen(true);
  }


  const enableBulkActions = selectedProducts.length > 0;
  const selectedSomeProducts = selectedProducts.length > 0
    && selectedProducts.length < products.length;
  const selectedAllProducts = selectedProducts.length === products.length;

  return (
    <div {...other}>
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.mode === 'dark'
            ? 'neutral.800'
            : 'neutral.100',
          display: enableBulkActions ? 'block' : 'none',
          // px: 2,
          // py: 0.5
        }}
      >
        <Checkbox
          checked={selectedAllProducts}
          indeterminate={selectedSomeProducts}
          onChange={handleSelectAllProducts}
        />
        <Button
          size="small"
          sx={{ ml: 2 }}
        >
          Delete
        </Button>
        <Button
          size="small"
          sx={{ ml: 2 }}
        >
          Edit
        </Button>
      </Box>
      <Scrollbar>
        <Table sx={{ minWidth: 700 }}>
          <TableHead sx={{ visibility: enableBulkActions ? 'collapse' : 'visible' }}>
            <TableRow>
              <TableCell>
                Company Name
              </TableCell>
              <TableCell>
                Product Name
              </TableCell>
              <TableCell>
                Status
              </TableCell>
              <TableCell align="right">
                Actions
              </TableCell>
              {/* <TableCell align="right">
                Edit
              </TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((product) => {
              // const isProductSelected = selectedProducts.includes(product.id);
              return (
                <TableRow
                  // hover
                  key={product.id}
                  // selected={isProductSelected}
                  // sx={{cursor: "pointer"}}
                  sx={[
                    {
                      '&:hover': {
                        boxShadow: '0 0 2px rgba(33,33,33,.2); '
                      },
                    }
                  ]}
                >
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex'
                      }}
                    >
                      <Box>
                        <Typography
                          color="inherit"
                          variant="subtitle2"
                        >
                          {product?.brandName}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell >
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex'
                      }}
                    >
                      <Box>
                        <Typography
                          color="inherit"
                          variant="body2"
                        >
                          {product?.name}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box>
                      <Typography
                        color="textSecondary"
                        variant="subtitle"
                      >
                        { product?.status }
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    <IconButton onClick={() => navigateToEdit(product)}>
                      <PencilAltIcon />
                    </IconButton>
                    <IconButton onClick={() => handleOpenModal(product)}>
                      <EyeIcon />
                    </IconButton>
                  </TableCell>
                  
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Scrollbar>
      <TablePagination
        component="div"
        count={productsCount}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
      />
      <ProductEditModal open={open} close={handleClose} product={product} refresh={refresh}/>
    </div>
  );
};

// ProductListTable.propTypes = {
//   products: PropTypes.array.isRequired,
//   productsCount: PropTypes.number.isRequired,
//   onPageChange: PropTypes.func.isRequired,
//   onRowsPerPageChange: PropTypes.func,
//   page: PropTypes.number.isRequired,
//   rowsPerPage: PropTypes.number.isRequired
// };
