export const initialData = {
      // barcode: '',
      id: '',
      formId: 'bon.0.1',
      
      // basic details
      manufacturerProductId: '',
      productId: '',
      descriptionLang: 'en',
      description: '',
      sku:  '',
      slug: '',
      status: 'pending',      
      name: '',
      brandName: '',
      brandId: '',
      countryOfManufacture: "",
      model: '',
      gtin: '',
      variableProduct: false,
  
      // category
      category: '',
      subcategory1: '',
      subcategory2: '',  
      room: [],
      usage: [],
      
      // etim
  
      etimFeatures: [],
      etimClassification: "",
  
      // market area
      availableEurope: false,
      availableAfrica: false,
      availableAntarctica: false,
      availableAsia: false,
      availableNorthAmerica: false,
      availableSouthAmerica: false,
      availableOceania: false,
      europe: [],
      africa: [],
      antarctica: [],
      asia: [],
      northAmerica: [],
      southAmerica: [],
      oceania: [],
      allCountries: [],

      europeAlpha3: [],
      africaAlpha3: [],
      antarcticaAlpha3: [],
      asiaAlpha3: [],
      northAmericaAlpha3: [],
      southAmericaAlpha3: [],
      oceaniaAlpha3: [],
      allCountriesAlpha3: [],

      europeFullNames: [],
      africaFullNames: [],
      antarcticaFullNames: [],
      asiaFullNames: [],
      northAmericaFullNames: [],
      southAmericaFullNames: [],
      oceaniaFullNames: [],
      allCountriesFullNames: [],

      geoBlockInclude: [],
      geoBlockIncludeFullNames: [],
      geoBlockIncludeAlpha3: [],
      geoBlockExclude: [],
      geoBlockIncludeFullNames: [],
      geoBlockIncludeAlpha3: [],
    
      // dimensions

      // technical details

      details: {
        soundReductionIndex: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'dB', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        colour: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: '', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        certificates: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: '', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        testing: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: '', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        numberOfLeafs: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: '', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        muntins: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: '', 
          standard: '', 
          range: false, multipleValues: false
        },
        verticalMuntinsMax: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: '', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        horizontalMuntinsMax: {
          value: '',
          values: [],
          min: '',
          max: '',
          unit: '',
          standard: '',
          range: false, 
          multipleValues: false
        },
        numberOfSeats: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: '', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        extendable: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: '', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        foldable: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: '', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        stackable: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: '', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        weatherproof: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: '', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        numberOfSashes: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: '', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        depthWithLidFullyOpen: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'mm', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        daylightWidth: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'mm', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        daylightHeight: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'mm', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        frameWidth: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'mm', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        frameHeight: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'mm', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        leafThickness: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'mm', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        depthWithLidFullyOpenImp: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'in', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        daylightWidthImp: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'in', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        daylightHeightImp: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'in', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        frameWidthImp: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'in', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        frameHeightImp: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'in', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        leafThicknessImp: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'in', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        nominalSizeDn: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'mm', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        length: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'mm', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        width: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'mm', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        height: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'mm', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        thickness: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'mm', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        diameter: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'mm', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        radius: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'mm', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        area: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'mm²', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        volume: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'm³', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        depth: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'mm', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        lengthImp: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'in', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        widthImp: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'in', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        heightImp: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'in', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        thicknessImp: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'in', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        diameterImp: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'in', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        radiusImp: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'in', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        areaImp: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'sq ft', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        volumeImp: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'cu in', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        depthImp: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'in', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        frequence: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'Hz', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        voltage: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'V', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        fuse: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'A', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        load: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'W', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        ratedPower: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'W', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        connectionType: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: '', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        energyConsumptionInConvectionMode: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'kWh', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        energyConsumptionInRecirculationMode: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'kWh', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        energySource: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: '', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        energyEfficiencyClass: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: '', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        thermalTransmittance: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'W/m²K', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        powerConsumption: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: '', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        fireRating: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: '', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        fireRatingMaterials: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: '', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        bulbType: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: '', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        luminousFlux: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'lm', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        lightOutput: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: '', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        colourTemperature: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'K', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        colourRenderingIndex: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: '', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        colourOfLight: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: '', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        televisionLightingConsistencyIndex: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: '', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        dimmingRange: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: '%', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        averageRatedLife: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'h', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        material: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: '', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        netVolume: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'm³', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        weight: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'kg', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        flowCoefficient: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'm³/h', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        flowCoefficientImp: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'cu ft/h', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        operatingTemperatureMax: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: '°C', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        operatingPressureMax: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'kPa', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        operatingPressure: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'kPa', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        nominalPressure: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'bar', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        protectionClass: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'IP rating', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        impactResistance: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'IK rating', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        nominalSizeNps: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'inches', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        frequency: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'Hz', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        energyConsumption: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'kWh', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        luminousEfficacy: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'lm/W', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        operatingTemperature: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: '°C', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        installationType: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: '', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        permissibleHumidity: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: '%', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        resistanceToRepeatedOpeningAndClosing: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: '', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        burglaryProtection: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: '', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        airPermeability: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: '', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        watertightness: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: '', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        windResistance: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: '', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        shearStrength: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'kPa', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        tensileStrength: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'kPa', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        compressiveStrength: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'kPa', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        density: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'kg/m³', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        current: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'A', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        thermalResistance: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'm²K/W', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        mechanicalStress: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'kPa', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        carbonFootprint: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: 'kg CO₂e', 
          standard: '', 
          range: false, 
          multipleValues: false
        },
        passiveHouseEfficiencyClass: {
          value: '',
          values: [],
          min: '', 
          max: '', 
          unit: '', 
          standard: '', 
          range: false, 
          multipleValues: false
        }
    }, 

      customDetails: [],


      // variants
      variants: [],
      
      // classifications
      classification: {
        ifcClassification: '',
        uniclass2015Code: '',
        uniclass2015Desc: '',
        omniClassNumber: '',
        omniClassTitle: '',
        uniclass20Code: '',
        uniclass20Desc: '',
        nbsReferenceCode: '',
        nbsReferenceDesc: ''
      },

      // certifications
  
      certifications: [],
      
      // files
      images: [],
      revitFiles: [],
      otherBimFormats: [],
      documentation: [],

      imagesFilenames: [],
      revitFilenames: [],
      otherBimFormatsFilenames: [],
      otherFilesFilenames: [],

      // metadata

      unitSystem: "metric",

      submit: null
  };