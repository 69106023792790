import { useRef, useState } from 'react';
import { Tooltip, IconButton } from '@mui/material';

import { PencilAlt as PencilAltIcon } from './icons/pencil-alt';
import { EventModal } from './event-modal';

export const ModifyEvent = (props) => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  //const type = props.type;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Edit">
        <IconButton
          onClick={handleOpen}
          // ref={anchorRef}
          // {...props}
          >
          <PencilAltIcon />
        </IconButton>
      </Tooltip>
      <EventModal open={open} close={handleClose} eventdata={props.eventdata} edited="true" companyid={props.companyid} refresh={props.refresh} />
    </>
  );
};
