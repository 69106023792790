import {  useState } from 'react';
import {  Tooltip, IconButton, Typography } from '@mui/material';
//import { Add } from '@mui/icons-material';
import { PencilAlt as PencilAltIcon } from '../../icons/pencil-alt';
import { DemoProductModal } from './demo-product-modal';
import { Plus } from '../../icons/plus';

export const OpenDemo = (props) => {
  const [ open, setOpen ] = useState(false);
  const { edit, brands, product, refresh } = props;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title={edit ? "Edit" : "Add new product"}>
        <IconButton onClick={handleOpen}>
          { edit
            ? <PencilAltIcon /> 
            : <Plus />
          }
          { 
            !edit && <Typography>Add new</Typography>
          }
        </IconButton>
      </Tooltip>
      {edit
        ? <DemoProductModal brands={brands} open={open} close={handleClose} edit product={product} refresh={refresh} />
        : <DemoProductModal brands={brands} open={open} close={handleClose} edit={false} refresh={refresh} />
      }
    </>
  );
};