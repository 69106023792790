import { v4 as uuidv4 } from 'uuid';

export const variantData = () => {
      
    const data = {
    // barcode: '',
      id: "",
      variantName: '',
      variantNameSpecification: '',
      unitSystem: "metric",

      attributes: [],

      // classification
      classification: [],

      // certifications
      certifications: [],
      
      // files
      images: [],
      revitFiles: [],
      otherBimFormats: [],
      documentation: [],

    }
    return data;
  };