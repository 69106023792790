import { useState, useEffect, useCallback } from 'react';
import { format } from 'date-fns';
import {
  Button,
  Card,
  CardHeader,
  Divider,
  IconButton,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip
} from '@mui/material';
import { useMounted } from '../../hooks/use-mounted';
import { AddContact } from '../../add-contact';
import { Scrollbar } from '../../scrollbar';
import { Check } from '../../icons/check';
import { ModifyContact } from '../../modify-contact';
import { Refresh as RefreshIcon} from '../../icons/refresh';


export const ContractTab = (props) => {
  const isMounted = useMounted();
  const { refresh, contracts } = props;
  // const [contacts, setContacts] = useState([]);

  const checkDate = (date) => {
    try {
      const newDate = format(date, 'd.M.yyyy');
      return (newDate);
    }
    catch(err) {
      return ("");
    }
  }



  return (
    // <Card {...props}>
    <Card>
      <CardHeader
        action={
        <div>            
          <Tooltip title="Refresh">
            <IconButton
              onClick={refresh}
            >
            <RefreshIcon />
          </IconButton>
        </Tooltip> 
        </div>
      }
        title="Contracts"
      />
      <Divider />
      <Scrollbar>
        <Table sx={{ minWidth: 700 }}>
          <TableHead>
            <TableRow>
            <TableCell>
                Type
              </TableCell>
              <TableCell>
                Name
              </TableCell>
              <TableCell>
                Email
              </TableCell>
              <TableCell>
                Added
              </TableCell>
              <TableCell>
                Status
              </TableCell>
              <TableCell align="right">
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contracts.map((data) => (
              <TableRow key={data.id}>
                <TableCell>
                  {/* {getDefault(data?.default)} */}
                  {data.documentType}
                </TableCell>
                <TableCell>
                  <Typography
                    color="textSecondary"
                    variant="subtitle2"
                  >
                    {data?.name}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                  >
                    {data?.email} 
                  </Typography>
                </TableCell>
                <TableCell>
                <Typography
                    color="textSecondary"
                    variant="body2"
                  >
                    {checkDate(data?._ts)} 
                  </Typography>
                </TableCell>
                <TableCell>
                <Typography
                    color="textSecondary"
                    variant="body2"
                  >
                    {data?.status} 
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  {/* <ModifyContact companyid={props.companyid} contact={data} refresh={refresh} /> */}
                  {/* <ModifyContact companyid={props.companyid} /> */}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
    </Card>
  );
};
