import { Autocomplete, Box, Button, Chip, Grid, IconButton, InputAdornment, InputLabel, MenuItem, TextField, Tooltip, Typography, createFilterOptions } from "@mui/material";
import { useState } from "react";
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Plus } from "../icons/plus";
import { OutlinedBox } from "./outlined-box";

const typeOptions = [
  {
    value: 'single',
    label: 'Single value',
  },
  {
    value: 'range',
    label: 'Range',
  }
];

export const ParameterField = (props) => {  
  const { 
    formik,
    type,
    label, 
    name, 
    value,
    values,
    unit,
    standardOptions, 
    standardName, 
    standardValue,
    unitOptions,
    unitName,
    unitValue,
    hint,
    multipleUnits,
    menuOptions,
    minMaxOption,
    minValue,
    maxValue,
    minName,
    maxName,
    rangeValue,
    rangeName
  } = props;
  const filter = createFilterOptions();
  //const [ isExactValue, setIsExactValue ] = useState(true);

  const handleStandardChange = (e, value) => {
    formik.setFieldValue(standardName, value);
  }

  const handleSetSingleValue = () => {
    formik.setFieldValue(minName, '')
    formik.setFieldValue(maxName, '')
    formik.setFieldValue(rangeName, false);
    //setIsExactValue(true)
  }

  const handleSetRange = () => {
    formik.setFieldValue(name, '')
    formik.setFieldValue(rangeName, true);
    //setIsExactValue(false)
  }

  // const handleSetValue = (event) => {
  //   const value = event.target.value;
  //   if (value === "range") {
  //     handleSetMinMaxValue()
  //   } else if (value === "single") {
  //     handleSetExactValue()
  //   }
  // }

  const handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      const values_name = name + "s";
      const new_array = []
      new_array.push(e.target.value)
      const array = values.concat(new_array)
      formik.setFieldValue(values_name, array)
      formik.setFieldValue(name, "")
    }
  }
  
  const handleBlur = (e) => {
    formik.handleBlur(e)
    if (e.target.value !== "") {
      const values_name = name + "s";
      const new_array = []
      new_array.push(e.target.value)
      const array = values.concat(new_array)
      formik.setFieldValue(values_name, array)
      formik.setFieldValue(name, "")
    }
  }

  const handleDelete = (item, index) => {
    const array = values;
    array.splice(index, 1)
    const values_name = name + "s";
    formik.setFieldValue(values_name, array)
  }
  
  return (
    <OutlinedBox label={label}>
      <Grid container spacing={1}>
        {!rangeValue ? 
          <Grid item xs={9 - (multipleUnits ? 2 : 0) - (minMaxOption ? 2 : 0)}>
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  // error={Boolean(formik.touched.thermalTransmittance && formik.errors.thermalTransmittance)}
                  fullWidth
                  size="small"
                  variant="outlined"
                  select={type === "Menu" ? true : false}
                  label={`Add ${label.toLowerCase()} value`}
                  name={name}
                  type={type === "Number" || type === "Integer" ? "number" : "text"}
                  //onBlur={formik.handleBlur}
                  onBlur={handleBlur}
                  onChange={formik.handleChange}
                  onKeyDown={handleKeyUp}
                  
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
                  }}
                  // InputLabelProps={{
                  //   sx: {fontWeight: 700, fontSize: 12, textAlign: "center", lineHeight: 1.9, "&.MuiOutlinedInput-notchedOutline": { fontSize: 12 }}
                  // }}
                  value={value}
                >
                  {type === "Menu" && menuOptions.map((option,index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
                {/* {values.map((value, index) => (<Chip sx={{borderRadius: 1, mr: 0.5}} size="small" key={`${name}-chip-values-${index}`} onDelete={()=>handleDelete(value,index)} label={value}/>))} */}
              </Grid>
              {/* ADD FUNCTIONALITY HERE
              <Grid item xs={2} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <IconButton>
                  <Plus />
                </IconButton>
              </Grid> */}
            </Grid>
          </Grid> :
          <Grid item xs={9 - (multipleUnits ? 2 : 0) - (minMaxOption ? 2 : 0)}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextField
                  // error={Boolean(formik.touched.thermalTransmittance && formik.errors.thermalTransmittance)}
                  fullWidth
                  size="small"
                  //variant="standard"
                  type={type === "Number" || type === "Integer" ? "number" : "text"}
                  label={"Min. " + label.toLowerCase()}
                  name={minName}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
                  }}
                  // InputLabelProps={{
                  //   sx: {fontWeight: 700, fontSize: 12, lineHeight: 1.9}
                  // }}
                  value={minValue}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  // error={Boolean(formik.touched.thermalTransmittance && formik.errors.thermalTransmittance)}
                  fullWidth
                  size="small"
                  //variant="standard"
                  type={type === "Number" || type === "Integer" ? "number" : "text"}
                  label={"Max. " + label.toLowerCase()}
                  name={maxName}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
                  }}
                  // InputLabelProps={{
                  //   sx: {fontWeight: 700, fontSize: 12, textAlign: "center", lineHeight: 1.9}
                  // }}
                  value={maxValue}
                />
              </Grid>
            </Grid>
          </Grid>
        }
        {multipleUnits &&
          <Grid item xs={2}>
            <TextField
              fullWidth
              select
              size="small"
              //label={unitLabel}
              label="unit"
              name={unitName}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={unitValue}
              // InputLabelProps={{
              //   sx: {fontWeight: 700, fontSize: 12, textAlign: "center", lineHeight: 1.9}
              // }}
            >
              {unitOptions.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        }
        {minMaxOption && 
          (!rangeValue ? 
            <Grid item xs={2}>
              <Button sx={{p: 0}} size="small" onClick={handleSetRange} fullWidth>
                <Typography variant="caption">
                  <b>Values</b><br/>
                  Switch to range
                </Typography>
              </Button>
            </Grid>
          :
            <Grid item xs={2}>
              <Button sx={{p: 0}} size="small" onClick={handleSetSingleValue} fullWidth>
                <Typography variant="caption">
                  <b>Range</b><br/>
                  Switch to values
                </Typography>
              </Button>
            </Grid>
          )
          // <Grid item xs={2}>
          //   <TextField
          //     fullWidth
          //     select
          //     size="small"
          //     //label={unitLabel}
          //     label="type"
          //     //name={unitName}
          //     defaultValue="single"
          //     onChange={(e) => handleSetValue(e)}
          //   >
          //     {typeOptions.map((option) => (
          //       <MenuItem key={option.value} value={option.value}>
          //         {option.label}
          //       </MenuItem>
          //     ))}
          //   </TextField>
          // </Grid>
        }
        <Grid item xs={3}>
          <Grid container spacing={1}>
            <Grid item xs={9}>
              <Autocomplete
                //disableClearable
                id="combo-box-demo"
                freeSolo
                selectOnFocus
                clearOnBlur
                //defaultValue={defaultValue}
                size="small"
                options={standardOptions}
                onChange={handleStandardChange}
                value={standardValue}
                name={standardName}
                renderInput={(params) => 
                  <TextField {...params} 
                    label="Standard" 
                    // InputLabelProps={{
                    //   sx: {fontWeight: 700, fontSize: 12, textAlign: "center", lineHeight: 1.9}
                    // }} 
                  />
                }

                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  const isExisting = options.some((option) => inputValue === option);
                  
                  if (inputValue !== '' && !isExisting) {
                    filtered.push(inputValue);
                  }

                  return filtered;
                }}
              />
            </Grid>
            <Grid item xs={3} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
              <Tooltip title={
                <Box>
                  <Typography variant="subtitle2">{hint && hint}</Typography>
                  <Typography variant="body2">{multipleUnits && "- Select the correct unit"}</Typography>
                  <Typography variant="body2">{minMaxOption && "- Please choose either specified values or a range (with minimum and maximum values) for the parameter."}</Typography>
                  <Typography variant="body2">- Standard: According to which standard (if applicable)</Typography>
                  <Typography variant="body2">- Please note: Use only one unit per parameter! All values will be displayed under the unit specified in the unit field.</Typography>
                </Box>
              }>
                <InfoIcon style={{color: '#175c83', cursor: "pointer"}} />
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        {!rangeValue && (values.length > 0 && <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item>
              <Typography variant="caption">Added values:</Typography>
            </Grid>
            <Grid item>
              {values.map((value, index) => (<Chip sx={{borderRadius: 1, mr: 0.5, mb: 0.5}} size="small" key={`${name}-chip-values-${index}`} onDelete={()=>handleDelete(value,index)} label={`${value}${unitValue === "" ? "" : (" " + unitValue)}`}/>))}
            </Grid>
          </Grid>
        </Grid>)}
      </Grid>
    </OutlinedBox>
  )
};

