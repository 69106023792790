import React from "react";

import "./unauthenticatedAccess.scss";
//import background from "../../assets/images/login-theme.png";
import background from "../../assets/images/login-background.svg";
//import bimroomlogoorange from '../../assets/images/bimroomlogoorange.svg';
import { Logo } from "../logo";

import { loginRequest } from "../../authConfig";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";

const UnauthenticatedAccess = () => {
  const { instance, accounts } = useMsal();

  function handleLogin(instance) {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.error(e);
    });
  }

  return (
    <div style={{width: '100vw', height: '100vh', backgroundSize: 'cover', backgroundImage: `url(${background})`}}>    
      <div className="unauthenticated-section">
        <div className="unauthenticated-container">
          <div className="broom-logo">
            {/* <img src={bimroomlogoorange} alt="" /> */}
            <Logo />
          </div>
          <div className="welcome-text">
            <br />
            {/* <p>Sign in to access Bimroom demo</p>
            <br /> */}
          </div>
          <div className="signin-btn" onClick={() => handleLogin(instance)}>
            Sign in
          </div>
        </div>
      </div>
    </div>

  );
};

export default UnauthenticatedAccess;
