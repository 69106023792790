import { useEffect, useRef, useState } from 'react';
import { Button, Box, Divider, TextField, Typography, Tooltip, IconButton, Modal, Grid } from '@mui/material';
//import { Add } from '@mui/icons-material';
import { Plus as PlusIcon } from './icons/plus';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { useFormik } from 'formik';
//import { uuid } from 'uuidv4';
import {v4 as uuidv4} from 'uuid';
import { addDemoBrand, upload } from './api';
import { wait } from './utils/wait';
import { FileDropzoneField } from './components/file-dropzone-field';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '1px solid #313133',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
};

export const AddDemo = (props) => {
  const anchorRef = useRef(null);
  const { refresh } = props;
  const [open, setOpen] = useState(false);
  const [brandLogos, setBrandLogos] = useState([]);
  //const [currentLogo, setCurrentLogo] = useState("");



  const handleDrop = (newFiles) => {
    setBrandLogos((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleRemove = (file) => {
    // setGlbFiles((prevFiles) => prevFiles.filter((_file) => _file.path !== file.path));
    setBrandLogos((prevFiles) => prevFiles.filter((_file) => _file !== file));
  };

  const handleRemoveAll = () => {
    setBrandLogos([]);
  };

  const handleFileUpload = () => {
    brandLogos.map((file) => {
      uploadFileToBlob(file, "brand-images");
      // formik.setFieldValue("brandLogo", file?.name);
      wait(1000);
      // setCurrentLogo(file?.name);
      formik.setFieldValue("brandLogo", file?.name);
    });
    
    setBrandLogos([]);
  };


  const uploadFileToBlob = async (file, id, folder) => {
    // const newFile = new File([file], id, {
    //   type: file?.type,
    // });
    const newFile = await file.arrayBuffer();

    const response = await upload(newFile, id, folder);
    return response;
  };

  const setNewIds = () => {
    formik.setFieldValue('id', uuidv4());
  }


  const formik = useFormik({
    initialValues: {
      id: uuidv4(),
      brandName: '',
      brandSlug: '',
      contactingId: '',
      demoEmail: '',
      demoUserName: '',
      algoliaId: '',
      brandLogo: '',
      email: '',
      submit: null
    },
    validationSchema: Yup.object({
      id: Yup.string().max(255),
      brandName: Yup.string().max(255).required("brand name is mandatory"),
      brandSlug: Yup.string().max(255).required("brand name is mandatory"),
      contactingId: Yup.string().max(255),
      demoEmail: Yup.string().max(255),
      demoUserName: Yup.string().max(255),
      algoliaId: Yup.string().max(255),
      brandLogo: Yup.string().max(255),
      email: Yup.string().max(255)
    }),
    onSubmit: async (values, helpers) => {
      try {
        // NOTE: Make API request
        
        const responseMessage = await addDemoBrand(values);

        //close();
        console.log(responseMessage);
        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);
        toast.success('Contact updated!');
        helpers.resetForm();
        setNewIds();
        await wait(500);
        refresh();
        handleClose();
      } catch (err) {
        console.error(err);
        toast.error('Something went wrong!');
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    }
  });


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Add demo">
        <Button
          startIcon={<PlusIcon fontSize="small" />}
          variant="contained"
          onClick={handleOpen}
          ref={anchorRef}
          {...props}
        >
          Add demo
        </Button>
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form
        onSubmit={formik.handleSubmit}
        // {...other}
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{mb: 3, width: "100%"}}>
              Add demo brand
            </Typography>
            <Grid container spacing={3} sx={{mb: 2}}>
              <Grid item md={4} xs={12}>
                <Typography id="modal-modal-subtitle" variant="subtitle2" component="h2" sx={{mb: 1, width: "100%"}}>
                  Basics
                </Typography>
              </Grid>
              <Grid item md={8} xs={12}>
                <TextField
                  id="outlined-multiline-flexible"
                  label="Brand name"
                  name="brandName"
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.brandName}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="Unique slug (no spaces or special characters)"
                  name="brandSlug"
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.brandSlug}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="Contacting ID"
                  name="contactingId"
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.contactingId}
                />
                <TextField
                  id="outlined-multiline-flexible"
                  label="Demo username (email address)"
                  name="demoEmail"
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.demoEmail}
                />
                {/* <TextField
                  id="outlined-multiline-flexible"
                  label="Demo name"
                  name="demoUserName"
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.demoEmail}
                /> */}
                <TextField
                  id="outlined-multiline-flexible"
                  label="Algolia ID (Azure object ID)"
                  name="algoliaId"
                  sx={{mb: 2, width: "100%"}}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.algoliaId}
                />
              </Grid>
              <Grid item md={4}  xs={12}>
                <Typography color="textSecondary" variant="subtitle2" sx={{ mt: 1 }}>
                  Logo
                </Typography>
              </Grid>
              <Grid item md={8} xs={12}>
                {formik.values.brandLogo !== "" && <img style={{ maxHeight: "100%", maxWidth: "100%", margin: "auto" }} src={`https://cdn.bimroom.com/brand-images/${formik.values.brandLogo}`} alt=""/>}
                <FileDropzoneField
                  files={brandLogos}
                  onDrop={handleDrop}
                  onRemove={handleRemove}
                  onRemoveAll={handleRemoveAll}
                  onUpload={handleFileUpload}
                />
                {/* <FilesUploadedField data={formik.values.brandLogos} onRemove={handleRemoveAdded} /> */}
              </Grid>
            </Grid>
            <IconButton 
              disabled={formik.isSubmitting}
              type="submit"
            >
              <PlusIcon />
              <Typography>Save</Typography>
            </ IconButton> 
          </Box>
        </form>
      </Modal>
    </>
  );
};
