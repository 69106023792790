//import NextLink from 'next/link';
import { useState } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import { wait } from '../../utils/wait';
import { FileDropzone } from '../file-dropzone';
import { FileDropzoneSmall } from '../file-dropzone-small';

export const CustomerEditForm = (props) => {
  const { customer, ...other } = props;
  const [files, setFiles] = useState([]);
  const formik = useFormik({
    initialValues: {
      name: customer?.name || '',
      email: customer?.email || '',
      phone: customer?.phone || '',
      submit: null
    },
    validationSchema: Yup.object({
      name: Yup.string().max(255),
      email: Yup.string().max(255),
      phone: Yup.string().max(255)
    }),
    onSubmit: async (values, helpers) => {
      try {
        // NOTE: Make API request
        await wait(500);
        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);
        toast.success('Customer updated!');
      } catch (err) {
        console.error(err);
        toast.error('Something went wrong!');
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    }
  });

  const handleDrop = (newFiles) => {
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleRemove = (file) => {
    setFiles((prevFiles) => prevFiles.filter((_file) => _file.path !== file.path));
  };

  const handleRemoveAll = () => {
    setFiles([]);
  };

  const uploadFileToBlob = (file) => {
    //upload(file);
    console.log(file);
  };

  const handleFileUpload = () => {
    files.map((file) =>
    // AZURE FILE SHARE API CALL HERE
    // sendFile(file);
    uploadFileToBlob(file)
    );
    console.log(formik.values.files);
  };

  return (
    <form
      onSubmit={formik.handleSubmit}
      {...other}>
      <Card>
        <CardHeader title="Edit demo account" />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                error={Boolean(formik.touched.name && formik.errors.name)}
                fullWidth
                helperText={formik.touched.name && formik.errors.name}
                label="Company name"
                name="name"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                required
                value={formik.values.name}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                error={Boolean(formik.touched.slug && formik.errors.slug)}
                fullWidth
                helperText={formik.touched.slug && formik.errors.slug}
                label="Slug"
                name="slug"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                required
                value={formik.values.slug}
                
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                error={Boolean(formik.touched.userId && formik.errors.userId)}
                fullWidth
                helperText={formik.touched.userId && formik.errors.userId}
                label="User ID"
                name="userId"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                required
                value={formik.values.userId}
                disabled
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                error={Boolean(formik.touched.username && formik.errors.username)}
                fullWidth
                helperText={formik.touched.username && formik.errors.username}
                label="Username"
                name="username"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                required
                value={formik.values.username}
                disabled
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                type="password"
                error={Boolean(formik.touched.password && formik.errors.password)}
                fullWidth
                helperText={formik.touched.password && formik.errors.password}
                label="Password"
                name="password"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                required
                value={formik.values.password}
                disabled
              />
            </Grid>
            
          </Grid>
          <Grid
            container
            spacing={3}
            sx={{mt: 1}}
          >
            <Grid item md={4}  xs={12}>
              <Typography variant="h6">Logo</Typography>
              <Typography color="textSecondary" variant="body2" sx={{ mt: 1 }}>
                Upload new logo.
              </Typography>
            </Grid>
            <Grid item md={8} xs={12}>
              <FileDropzoneSmall
                files={files}
                onDrop={handleDrop}
                onRemove={handleRemove}
                onRemoveAll={handleRemoveAll}
                onUpload={handleFileUpload}
                maxFiles={1}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions
          sx={{
            flexWrap: 'wrap',
            m: -1
          }}
        >
          <Button
            disabled={formik.isSubmitting}
            type="submit"
            sx={{ m: 1 }}
            variant="contained"
          >
            Update
          </Button>

          <Button
            component="a"
            disabled={formik.isSubmitting}
            sx={{
              m: 1,
              mr: 'auto'
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          {/* <Button
            color="error"
            disabled={formik.isSubmitting}
          >
            Delete demo
          </Button> */}
        </CardActions>
      </Card>
    </form>
  );
};

CustomerEditForm.propTypes = {
    customer: PropTypes.object.isRequired
};
