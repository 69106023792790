import PropTypes from 'prop-types';
import { Accordion, AccordionDetails, AccordionSummary, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const camelCaseToText = (text) => {
  const result = text.replace(/([A-Z])/g, " $1");
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
}

export const VariantAccordion = (props) => {
  const { product } = props;

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{product?.variant}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Table>
          <TableHead>
            <TableCell colSpan={2}>
              BON variables
            </TableCell>
          </TableHead>
          <TableBody>
            {Object.keys(product.details).map((key, index) => {
            const object = product.details[key];
            
            return (
                object.min !== "" || object.max !== "" || object.value !== "" || object.values.length > 0 ? 
                <TableRow
                  // hover
                  key={index}
                >
                  <TableCell sx={{width: "30%"}}>
                    <Typography
                      variant="subtitle2"
                    >
                      {camelCaseToText(key)}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{width: "70%"}}>
                    <Typography
                      variant="body2"
                    >
                      {object.range ? object.min + "-" + object.max + (object.unit !== "" && (" " + object.unit)) : object.values.map((value, index) => (index === 0 ? value : " " + value)) + (object.unit && (" " + object.unit))}
                    </Typography>
                  </TableCell>
                </TableRow>
              : 
              <></>
              );
            })}
          </TableBody> 
        </Table>
        <Table>
          <TableHead>
            <TableCell colSpan={2}>
              Custom variables
            </TableCell>
          </TableHead>
          <TableBody>
            {product.customDetails.map((object, index) => {

            return (
                object.min !== "" || object.max !== "" || object.value !== "" || object.values.length > 0 ? 
                  <TableRow
                  // hover
                    key={`custom-detail-${index}`}
                  >
                    <TableCell sx={{width: "30%"}}>
                      <Typography
                        variant="subtitle2"
                      >
                        {object.name}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{width: "70%"}}>
                      <Typography
                        variant="body2"
                      >
                        {object.range ? object.min + "-" + object.max + (object.unit !== "" && (" " + object.unit)) : object.values.map((value, index) => (index === 0 ? value : " " + value)) + (object.unit && (" " + object.unit))}
                      </Typography>
                    </TableCell>
                  </TableRow>
                : 
                  <></>
              );
            })}
          </TableBody> 
        </Table> 
      </AccordionDetails>
    </Accordion>
  )
}

// PropertyList.propTypes = {
//   children: PropTypes.node
// };
