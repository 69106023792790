import { useEffect, useState } from 'react';
// import NextLink from 'next/link';
//import numeral from 'numeral';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { ArrowRight as ArrowRightIcon } from '../../icons/arrow-right';
import { PencilAlt as PencilAltIcon } from '../../icons/pencil-alt';
// import { getInitials } from '../../../utils/get-initials';
import { Scrollbar } from '../../scrollbar';
import { useNavigate } from 'react-router-dom';
// import { AddMainProfileModal } from '../../add-main-profile-modal';

export const CustomerListTable = (props) => {
  const {
    customers,
    customersCount,
    onPageChange,
    onRowsPerPageChange,
    page,
    rowsPerPage,
    ...other
  } = props;
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const navigate = useNavigate();

  // Reset selected customers when customers change
  useEffect(() => {
      if (selectedCustomers.length) {
        setSelectedCustomers([]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customers]);

  const goToCustomerDetails = (id) => {
    navigate("/clients/" + id);
  }

  const handleSelectAllCustomers = (event) => {
    setSelectedCustomers(event.target.checked
      ? customers.map((customer) => customer.id)
      : []);
  };

  const handleSelectOneCustomer = (event, customerId) => {
    if (!selectedCustomers.includes(customerId)) {
      setSelectedCustomers((prevSelected) => [...prevSelected, customerId]);
    } else {
      setSelectedCustomers((prevSelected) => prevSelected.filter((id) => id !== customerId));
    }
  };

  const enableBulkActions = selectedCustomers.length > 0;
  const selectedSomeCustomers = selectedCustomers.length > 0
    && selectedCustomers.length < customers.length;
  const selectedAllCustomers = selectedCustomers.length === customers.length;

  return (
    <div {...other}>
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.mode === 'dark'
            ? 'neutral.800'
            : 'neutral.100',
          display: enableBulkActions ? 'block' : 'none',
          px: 2,
          py: 0.5
        }}
      >
        <Checkbox
          checked={selectedAllCustomers}
          indeterminate={selectedSomeCustomers}
          onChange={handleSelectAllCustomers}
        />
        <Button
          size="small"
          sx={{ ml: 2 }}
        >
          Delete
        </Button>
        <Button
          size="small"
          sx={{ ml: 2 }}
        >
          Edit
        </Button>
      </Box>
      <Scrollbar>
        <Table sx={{ minWidth: 700 }}>
          <TableHead sx={{ visibility: enableBulkActions ? 'collapse' : 'visible' }}>
            <TableRow>
              {/* <TableCell width="25%">
              </TableCell> */}
              <TableCell width="30%">
                Name
              </TableCell>
              <TableCell width="30%">
                CLA
              </TableCell >
              {/* <TableCell>
                Products
              </TableCell> */}
              <TableCell width="30%">
                Subscription
              </TableCell>
              <TableCell width="10%" align="right">
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customers.map((customer) => {
              const isCustomerSelected = selectedCustomers.includes(customer.id);

              return (
                <TableRow
                  hover
                  key={customer.id}
                  selected={isCustomerSelected}
                  onClick={() => goToCustomerDetails(customer.id)}
                  sx={{cursor: "pointer"}}
                >

                  <TableCell>
                    <Typography variant="subtitle2">
                      {customer?.companyName}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {/* {`${customer.city}, ${customer.state}, ${customer.country}`} */}
                    {customer?.cla}
                  </TableCell>
                  {/* <TableCell>
                    {customer?.totalProducts}
                  </TableCell> */}
                  <TableCell>
                    {/* <Typography
                      color="success.main"
                      variant="subtitle2"
                    >
                      {numeral(customer.totalAmountSpent).format(`${customer.currency}0,0.00`)}
                    </Typography> */}
                    {customer?.subscription}
                  </TableCell>
                  <TableCell align="right">
                    {/* <NextLink
                      href="/dashboard/customers/1/edit"
                      passHref
                    > */}
                      {/* <IconButton component="a">
                        <PencilAltIcon fontSize="small" />
                      </IconButton> */}
                    {/* </NextLink>
                    <NextLink 
                      href="/dashboard/customers/1"
                      passHref
                    >
                      <IconButton component="a">
                        <ArrowRightIcon fontSize="small" />
                      </IconButton>
                     </NextLink> */}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Scrollbar>
      <TablePagination
        component="div"
        count={customersCount}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
      />
    </div>
  );
};

CustomerListTable.propTypes = {
  customers: PropTypes.array.isRequired,
  customersCount: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};
