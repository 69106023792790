//import NextLink from 'next/link';
//import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
// import toast from 'react-hot-toast';
import {
  Avatar,
  Box,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography
} from '@mui/material';
//import LogoutIcon from '@mui/icons-material/Logout';
//import { useAuth } from '../../hooks/use-auth';
import { Cog as CogIcon } from '../icons/cog';
import { Users as UsersIcon } from '../icons/users';
import { UserCircle as UserCircleIcon } from '../icons/user-circle';
import { SwitchHorizontalOutlined as SwitchHorizontalOutlinedIcon } from '../icons/switch-horizontal-outlined';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";

export const AccountPopover = (props) => {
  const { anchorEl, onClose, open, ...other } = props;
  //const router = useRouter();
  //const { logout } = useAuth();
  const { instance, accounts } = useMsal();

  // To get the user from the authContext, you can use
  // `const { user } = useAuth();`
  let company = "";
  //console.log(accounts[0])

  if ( accounts[0].idTokenClaims.extension_CompanyID !== undefined) {
    company = accounts[0].idTokenClaims.extension_CompanyID;
  }

  
  const user = {
    avatar: UserCircleIcon,
    name: accounts[0].name,
    company: company
  };

  const handleLogout = async () => {
    try {
      onClose?.();
      //await logout();
      await instance.logoutRedirect({ postLogoutRedirectUri: "/" });
      //{() => instance.logoutRedirect({ postLogoutRedirectUri: "/" })}
      //router.push('/').catch(console.error);
    } catch (err) {
      console.error(err);
      //toast.error('Unable to logout.');
    }
  };


  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom'
      }}
      keepMounted
      onClose={onClose}
      open={!!open}
      PaperProps={{ sx: { width: 300 } }}
      transitionDuration={0}
      {...other}>
      <Box
        sx={{
          alignItems: 'center',
          p: 2,
          display: 'flex'
        }}
      >
        <Avatar
          //src={user.avatar}
          sx={{
            height: 40,
            width: 40
          }}
        >
          <UserCircleIcon fontSize="small" />
        </Avatar>
        <Box
          sx={{
            ml: 1
          }}
        >
          <Typography variant="body1">
            {user.name}
          </Typography>
          <Typography
            color="textSecondary"
            variant="body2"
          >
            {user.company}
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box sx={{ my: 1 }}>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
          </ListItemIcon>
          <ListItemText
            primary={(
              <Typography variant="body1">
                Logout
              </Typography>
            )}
          />
        </MenuItem>
      </Box>
    </Popover>
  );
};

// AccountPopover.propTypes = {
//   anchorEl: PropTypes.any,
//   onClose: PropTypes.func,
//   open: PropTypes.bool
// };
